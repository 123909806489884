import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useFetchCreditTransactions } from '../../Hook';
import { PaginatedTable, TableCell, TableRow } from '../../../Components';
import { DateTime } from '../../../Common/Component';
import { TextFormatter } from '../../../Utility';
import { useQueryAsState } from '../../../Hooks';
import { QueryParser } from '../../../Common/Utility';

type Query = {
  pageNum?: string;
};

type Props = {
  transactionSavedAt: number;
};

export const TransactionTable: FunctionComponent<Props> = ({ transactionSavedAt }) => {
  const [query, setQuery] = useQueryAsState<Query>(defaultQuery);
  const fetchCreditTransactionParams = useMemo(() => ({ pageNum: QueryParser.pageNum(query) }), [query]);
  const [fetchTransactionsResult, fetchTransactionsRequestState, retryFetchTransactions] = useFetchCreditTransactions(fetchCreditTransactionParams);
  const transactions = fetchTransactionsResult?.content?.transactions || [];
  const pagination = fetchTransactionsResult?.content?.pagination;
  const [lastRefreshedAt, setLastRefreshedAt] = useState<number>(0);

  useEffect(() => {
    if (transactionSavedAt > lastRefreshedAt) {
      retryFetchTransactions();
      setLastRefreshedAt(transactionSavedAt);
    }
  }, [lastRefreshedAt, retryFetchTransactions, transactionSavedAt]);

  const whenPageNumChanged = useCallback(
    (pageNum: number) => setQuery({ pageNum: `${ pageNum }` }),
    [setQuery],
  );

  return (
    <PaginatedTable
      rows={ transactions }
      rowRender={ transaction => (
        <TableRow key={ transaction.id }>
          <TableCell>
            <DateTime dateTime={ transaction.created.at }/>
          </TableCell>
          <TableCell>
            { TextFormatter.spaceSeparated(transaction.created.by.firstName, transaction.created.by.lastName || '') }
          </TableCell>
          <TableCell>
            { transaction.creditCount }
          </TableCell>
          <TableCell
            maxwidth="15rem"
            ellipsisOverflow
          >
            { transaction.note }
          </TableCell>
          <TableCell>
            {
              transaction.refund && (
                <FormattedMessage
                  id="credit.refund"
                  description="Text in right-most column of transaction table if the transaction is a refund"
                  defaultMessage="Refund"
                />
              )
            }
          </TableCell>
        </TableRow>
      ) }
      pageNum={ fetchCreditTransactionParams.pageNum }
      onPageChanged={ whenPageNumChanged }
      headerRow={
        <TableRow>
          <TableCell>
            <FormattedMessage
              id="credit.dateColumn"
              defaultMessage="Date"
            />
          </TableCell>
          <TableCell>
            <FormattedMessage
              id="credit.createdByColumn"
              defaultMessage="Created by"
            />
          </TableCell>
          <TableCell>
            <FormattedMessage
              id="credit.amountColumn"
              defaultMessage="Amount"
            />
          </TableCell>
          <TableCell>
            <FormattedMessage
              id="credit.noteColumn"
              defaultMessage="Note"
            />
          </TableCell>
          <TableCell/>
        </TableRow>
      }
      requestState={ fetchTransactionsRequestState }
      onRetryClicked={ retryFetchTransactions }
      pagination={ pagination }
    />
  );
};

const defaultQuery = {
  pageNum: '1',
};
