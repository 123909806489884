import styled from '@emotion/styled';
import { rgba } from 'polished';

import { TableRow } from '../../../Components';
import { PropsWithTheme } from '../../../Styling/PropsWithTheme';

type StyledReviewTableRowProps = {
  $error?: boolean;
  $skipped?: boolean;
}

export const StyledReviewTableRow = styled(
  (props: PropsWithTheme<StyledReviewTableRowProps>) => <TableRow {...props} />
)`
  ${ props => props.$error && `
    background-color: ${ props.theme.palette.error.contrastText };
    color: ${ props.theme.palette.error.main };
    font-weight: 500;
  ` }

  ${ props => props.$skipped && `
    background-color: ${ props.theme.palette.grey[100] };
    color: ${ props.theme.palette.grey[500] };
  ` }
`;

type StyledSwitchContainerProps = {
  modified: boolean;
}

export const StyledSwitchContainer = styled.div<StyledSwitchContainerProps>`
  border-radius: 0.35rem;
  padding: 0.5rem;
  ${ props => props.modified && `background-color: ${ rgba(props.theme.palette.secondary.main, 0.1) };` }
`;
