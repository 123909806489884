import styled from '@emotion/styled';

export const TagLimitInformation = styled.div`
  margin-bottom: 1rem;

  p {
    margin: 0;
  }
`;

export const TagNameCellContents = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 1rem;
  }

  a {
    display: block;
  }
`;
