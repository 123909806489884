import {FC, PropsWithChildren} from 'react';
import {History, LocationDescriptor} from 'history';
import {Redirect, Route, RouteProps} from 'react-router-dom';

import {usePermissions} from '../../../Security/Hook';
import {ELEVATE_PATH} from "../../../Components";

type Props = RouteProps & {
  permissions: string[];
  permissionCheck?: 'any' | 'all';
  redirect?: LocationDescriptor<History.PoorMansUnknown>;
}

export const PermissionRoute: FC<PropsWithChildren<Props>> = ({
  permissions,
  permissionCheck = 'all',
  redirect = '/',
  ...props
}) => {
  const { anyPermissionAvailable, permissionsAvailable, permissionRestricted, permissionsGranted } = usePermissions();
  const available: boolean = permissionCheck === 'all'
    ? permissionsAvailable(permissions)
    : anyPermissionAvailable(permissions);
  const restricted: boolean = available && !permissionsGranted(permissions) && !!permissions.find(permissionRestricted);

  if (restricted && permissions.length) {
    return <Redirect to={ELEVATE_PATH}/>;
  }

  if (!available && permissions.length) {
    console.debug('Route redirect due to unavailable permission.', { redirect });
    return <Redirect to={ redirect }/>;
  }

  return <Route { ...props }/>;
};
