import styled from '@emotion/styled';
import { Button } from '@mui/material';
import { darken } from 'polished';

import { PresentationIcon } from '../PresentationIcon/PresentationIcon';
import { CloseButton } from '../CloseButton/CloseButton';

export const StyledCloseButton = styled(CloseButton)`
  margin: ${ props => props.theme.spacing(-1) };
`;

export const StyledExpandingWidget = styled.div`
  background: ${ props => props.theme.palette.background.paper };
  position: fixed;
  right: ${ props => props.theme.spacing(2) };
  bottom: -1px;
  width: 400px;
  z-index: 1;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  overflow: hidden;
  box-shadow: ${ props => props.theme.shadows[2] };
`;

export const StyledTitleBar = styled(Button)`
  padding: ${ props => props.theme.spacing(2) };
  width: 100%;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;

  &:not(:active):not(:hover) {
    background-color: ${ props => props.theme.palette.secondary.dark };
  }

  &:hover {
    background-color: ${ props => darken(0.02, props.theme.palette.secondary.dark) };
  }

  &:active {
    background-color: ${ props => darken(0.04, props.theme.palette.secondary.dark) };
  }

  > span > div {
    width: 100%;
  }
`;

export const StyledBody = styled.div<{ expanded: boolean }>`
  width: 100%;
  border-top: 1px solid ${ props => props.theme.palette.grey[300] };
  transition: height 0.3s, padding 0.3s;

  ${
    props => props.expanded
      ? `
      height: 45vh;
      padding: ${ props.theme.spacing(2) };
      overflow: auto;
    `
    : `
      height: 0;
      padding: 0 ${ props.theme.spacing(2) };
      overflow: hidden;
    `
  }
`;

export const StyledToggleIcon = styled(PresentationIcon)<{ expanded: boolean }>`
  transition: transform 0.3s;
  ${ props => !props.expanded && 'transform: rotate(180deg);' }
`;
