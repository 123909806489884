import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { AutocompleteCommonProps, AutocompleteFetchRequest, AutocompleteSelectionChanged } from '@ourpeople/shared/Core/Component/Input/Autocomplete/Autocomplete';

import { Role } from '../../../Security/Model';
import { useLocalisedRoleNames } from '../../../Security/Hook';
import { DebouncedAutocomplete } from '../../../Common/Component';

export interface RoleAutocompleteProps extends AutocompleteCommonProps {
  selectedIds: string[];
  allowedIds?: string[];
  onSelectionChanged: AutocompleteSelectionChanged<Role>;
}

export const RoleAutocomplete = ({
  selectedIds,
  allowedIds,
  onSelectionChanged,
  ...props
}: RoleAutocompleteProps): JSX.Element => {
  const intl = useIntl();
  const { availableRoles } = useLocalisedRoleNames();

  const whenFetch: AutocompleteFetchRequest<Role> = () => {
    return Promise.resolve({
      options: availableRoles
        .filter((role) => !allowedIds || allowedIds.indexOf(role.id) > -1),
      pageCount: 1,
    });
  }

  return <DebouncedAutocomplete<Role>
    {...props}
    getOptionLabel={(person) => person.name}
    fetchOptions={useCallback(whenFetch, [allowedIds, availableRoles])}
    selectedIds={selectedIds}
    onSelectionChanged={onSelectionChanged}
    label={
      props.label !== undefined
        ? props.label
        : intl.formatMessage(
          {
            id: 'roleAutocomplete.label',
            defaultMessage: 'Select {limit, plural, one {role} other {roles}}'
          },
          {
            limit: props.multiple ? Infinity : 1,
          }
        )
    }
    placeholder={
      props.placeholder ||
      intl.formatMessage(
        {
          id: 'roleAutocomplete.placeholder',
          defaultMessage: 'Search for {limit, plural, one {role} other {role}}'
        },
        {
          limit: props.multiple ? Infinity : 1,
        },
      )
    }
  />;
};
