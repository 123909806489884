import styled from '@emotion/styled';

export const StyledSegmentContainer = styled.div`
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 10px;
`;

export const StyledAudienceSize = styled.span`
  a {
    color: ${ props => props.theme.palette.secondary.main };
  }
`;

export const StyledBulbIconContainer = styled.div`
  svg {
    width: ${ props => props.theme.spacing(4) };
    height: ${ props => props.theme.spacing(4) };
  }
`;
