import styled from '@emotion/styled';

import { css } from '../../../Styling/css';

type StyledDropzoneProps = {
  isDragActive?: boolean;
};

const BaseDropzone = css`
  text-align: center;
  padding: ${props => props.theme.spacing(6)};
  border: 3px dashed ${props => props.theme.palette.grey[300]};
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${props => props.theme.spacing(2)};
`;

export const StyledDropzone = styled.div<StyledDropzoneProps>`
  ${BaseDropzone}

  ${props => props.isDragActive && `
    border-color: ${props.theme.palette.grey[400]};
  ` }
`;

export const StyledProgressContainer = styled.div`
  width: 300px;

  .MuiLinearProgress-root {
    border-radius: 1rem;
    height: 10px;
  }
`;

export const StyledFileName = styled.span`
  font-size: 1rem;
  color: ${props => props.theme.palette.secondary.main};
`;
