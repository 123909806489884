import { useContext } from 'react';

import { AuthContext } from '../../Contexts';
import { AuthDescription } from '../../Models/AuthDescription';

export const useLoggedInAuthDescription = (): AuthDescription => {
  const { authDescription } = useContext(AuthContext);

  if (authDescription === null) {
    throw new Error('useLoggedInAuthDescription must be used within AuthContextProvider.');
  }

  return authDescription;
};
