import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';

import { StyledPercentageChange } from './style';

interface Props {
  previousValue: number;
  currentValue: number;
}

export const PercentageChange: FunctionComponent<Props> = ({
  previousValue = 0,
  currentValue,
}) => {
  const intl = useIntl();
  const changePercent = (currentValue - previousValue) / (previousValue || 1);
  const negative = changePercent < 0;

  return (
    <StyledPercentageChange negative={ negative }>
      { intl.formatNumber(
        changePercent,
        { style: 'percent', maximumFractionDigits: 0, signDisplay: 'exceptZero' },
      ) }
    </StyledPercentageChange>
  )
}
