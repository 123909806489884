import { ComponentProps, FC, useCallback, useMemo } from 'react';

import { RoleCondition } from '../../../../Audiences/Model';
import { useLocalisedRoleNames } from '../../../../Security/Hook';
import { BadgeCloud } from '../BadgeCloud';
import { RoleId } from '../../../../Security/Model';

type Props = {
  condition: RoleCondition;
  onChange?: (condition: RoleCondition) => void;
};

export const RoleCloud: FC<Props> = ({
  condition,
  onChange,
}) => {
  const { availableRoles } = useLocalisedRoleNames();
  const items = useMemo(() => (
    availableRoles.filter(availableRole => condition.roleIds.includes(availableRole.id)).map(role => ({
      label: role.name,
      id: role.id,
    }))
  ), [availableRoles, condition.roleIds]);

  const whenRemoveClicked = useCallback<NonNullable<ComponentProps<typeof BadgeCloud>['onRemoveClicked']>>(badgeProps => {
    onChange && onChange({
      ...condition,
      roleIds: condition.roleIds.filter(roleId => roleId !== badgeProps.id as RoleId),
    });
  }, [condition, onChange]);

  return (
    <BadgeCloud
      value={ items }
      { ...onChange ? { onRemoveClicked: whenRemoveClicked } : {} }
    />
  );
};
