import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import CompleteIcon from '../../../Assets/img/icons/circle/success.svg';
import FailedIcon from '../../../Assets/img/icons/circle/error.svg';
import { ImportDetail } from '../../Model';
import { StyledImportStatus } from './style';
import { Box } from '../../../Common/Component';

type Props = {
  importDetail: ImportDetail;
}

export const CompleteStep: FunctionComponent<Props> = ({
  importDetail,
}) => (
  <Box margin={ false }>
    {
      importDetail.status === 'completed'
        ? (
          <StyledImportStatus>
            <CompleteIcon role="presentation" />
            <p>
              <FormattedMessage
                id="imports.completeStep.successMessage"
                description="Message displayed when import is completed successfully."
                defaultMessage="Import completed"
              />
            </p>
          </StyledImportStatus>
        )
        : (
          <StyledImportStatus failed>
            <FailedIcon role="presentation" />
            <p>
              <FormattedMessage
                id="imports.completeStep.failedMessage"
                description="Message displayed when import fails."
                defaultMessage="Import failed"
              />
            </p>
            <span>
              <FormattedMessage
                id="imports.retry"
                description="Retry CTA when import fails."
                defaultMessage="Please correct any issues with earlier steps and try again."
              />
            </span>
          </StyledImportStatus>
        )
    }
  </Box>
);
