import { createContext, Dispatch, FC, PropsWithChildren, ReactNode, SetStateAction, useMemo, useState } from 'react';

type FormNavigationContextValue = {
  footerContents: ReactNode;
  setFooterContents: (contents: ReactNode) => void;
  displayingValidation: boolean;
  setDisplayingValidation: (displayingValidation: boolean) => void;
  progressBlocked: boolean;
  setProgressBlocked: Dispatch<SetStateAction<boolean>>;
};

export const FormNavigationContext = createContext<FormNavigationContextValue | null>(null);

export const FormNavigationProvider: FC<PropsWithChildren> = ({ children }) => {
  const [footerContents, setFooterContents] = useState<ReactNode>();
  const [displayingValidation, setDisplayingValidation] = useState<boolean>(false);
  const [progressBlocked, setProgressBlocked] = useState<boolean>(false);
  const value = useMemo<FormNavigationContextValue>(() => ({
    footerContents,
    setFooterContents,
    displayingValidation,
    setDisplayingValidation,
    progressBlocked,
    setProgressBlocked,
  }), [displayingValidation, footerContents, progressBlocked]);

  return (
    <FormNavigationContext.Provider
      value={ value }
    >
      { children }
    </FormNavigationContext.Provider>
  );
};
