import { FC, ReactNode } from 'react';

import { ImageBlock } from '../../../Service/Blocks/ImageBlockDefinition';

type Props = {
  caption: NonNullable<ImageBlock['attributes']['caption']>;
  children?: ReactNode;
};

export const ImageCaptionContainer: FC<Props> = ({
  caption,
  children,
}) => (
  <div
    css={ theme => ({
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      display: 'flex',
      padding: theme.new.spacing[2],
    }) }
  >
    <div
      css={ {
        display: 'flex',
        ...['topLeft', 'topCenter', 'topRight'].includes(caption.position)
          ? { marginBottom: 'auto' }
          : {},
        ...['bottomLeft', 'bottomCenter', 'bottomRight'].includes(caption.position)
          ? { marginTop: 'auto' }
          : {},
        ...['topLeft', 'bottomLeft'].includes(caption.position)
          ? { justifyContent: 'flex-start', textAlign: 'left' }
          : {},
        ...['topCenter', 'bottomCenter'].includes(caption.position)
          ? { justifyContent: 'center', textAlign: 'center' }
          : {},
        ...['topRight', 'bottomRight'].includes(caption.position)
          ? { justifyContent: 'flex-end', textAlign: 'right' }
          : {},
        width: '100%',
      } }
    >
      { children }
    </div>
  </div>
);
