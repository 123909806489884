import { FC, useEffect, useMemo, useState } from 'react';
import { Tooltip } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { parseISO } from 'date-fns';
import { LineClamp } from 'op-storybook/stories/components/LineClamp/LineClamp';

import { TableCell, TableRow } from '../../../Components';
import { BroadcastIcon } from '../BroadcastIcon/BroadcastIcon';
import { BroadcastsPermission } from '../../Model';
import { StyledDisabledLink } from '../DraftsTable/style';
import { GuardedLink } from '../../../Security/Component';
import { NonWrappingCell } from '../../../Forms/Component';
import { TableRowContextMenu } from '../../../Common/Component/TableRowContextMenu/TableRowContextMenu';
import { usePermissions } from '../../../Security/Hook';
import { useAuthDescription } from '../../../Core/Hook';
import { useArchiveBroadcastAction, useDuplicateBroadcastAction, useResourceLockMessage } from '../../Hook';
import { ShareAudience } from '../ShareAudience/ShareAudience';
import { useEditBroadcastAction } from '../../Hook/TableActions/useEditBroadcastAction';
import { useShareDeliveryAction } from '../../Hook/TableActions/useShareDeliveryAction';
import { useDeleteBroadcastAction } from '../../Hook/TableActions/useDeleteBroadcastAction';
import { SharedDelivery } from '../../Hook/useFetchSharedDeliveries';
import {
  InlinePersonAvatarAndName
} from '../../../Common/Component/InlinePersonAvatarAndName/InlinePersonAvatarAndName';

type Props = {
  sharedDelivery: SharedDelivery;
  onReloadRequired: () => void;
};

export const DraftsTableRow: FC<Props> = ({
  sharedDelivery,
  onReloadRequired,
}) => {
  const authDescription = useAuthDescription();
  const { permissionAvailable } = usePermissions();
  const [performingAction, setPerformingAction] = useState<boolean>(false);
  const resourceLockMessage = useResourceLockMessage(sharedDelivery);
  const {
    archiveConfirmationDialog,
    archiveAction,
    archiving,
  } = useArchiveBroadcastAction(
    sharedDelivery.broadcast.id,
    resourceLockMessage,
    performingAction,
  );
  const {
    sharing,
    shareConfirmationDialog,
    shareAction,
  } = useShareDeliveryAction(
    sharedDelivery,
    resourceLockMessage,
    performingAction,
    onReloadRequired,
  );
  const {
    deleting,
    deleteConfirmationDialog,
    deleteAction,
  } = useDeleteBroadcastAction(
    sharedDelivery.broadcast.id,
    resourceLockMessage,
    performingAction,
    onReloadRequired,
  );
  const editBroadcastAction = useEditBroadcastAction(
    sharedDelivery.broadcast,
    resourceLockMessage,
  );
  const {
    duplicateAction,
    duplicating,
  } = useDuplicateBroadcastAction(
    sharedDelivery.broadcast.id,
    performingAction,
    onReloadRequired,
  );

  useEffect(() => (
    setPerformingAction(
      sharing || deleting || duplicating || archiving
    )
  ), [archiving, deleting, duplicating, sharing]);

  const actions = useMemo(() => [
    ...(permissionAvailable(BroadcastsPermission.UPDATE) ? [editBroadcastAction] : []),
    ...(permissionAvailable(BroadcastsPermission.UPDATE) ? [shareAction] : []),
    ...(permissionAvailable(BroadcastsPermission.CREATE) ? [duplicateAction] : []),
    ...(permissionAvailable(BroadcastsPermission.UPDATE) ? [archiveAction] : []),
    ...(permissionAvailable(BroadcastsPermission.DELETE) ? [deleteAction] : []),
  ], [permissionAvailable, editBroadcastAction, shareAction, duplicateAction, archiveAction, deleteAction]);

  return (
    <>
      <TableRow key={ sharedDelivery.broadcast.id }>
        <TableCell>
          <BroadcastIcon
            broadcast={ sharedDelivery.broadcast }
          />
        </TableCell>
        <TableCell>
          <LineClamp minWidth={ 200 }>
            {
              permissionAvailable(BroadcastsPermission.UPDATE)
                ? (
                  resourceLockMessage
                    ? (
                      <Tooltip title={ resourceLockMessage }>
                        <StyledDisabledLink>
                          { sharedDelivery.broadcast.name }
                        </StyledDisabledLink>
                      </Tooltip>
                    )
                    : (
                      <GuardedLink
                        to={ `/broadcasts/${ sharedDelivery.broadcast.id }/edit` }
                        permissions={ [BroadcastsPermission.UPDATE] }
                      >
                        { sharedDelivery.broadcast.name }
                      </GuardedLink>
                    )
                )
                : sharedDelivery.broadcast.name
            }
          </LineClamp>
        </TableCell>
        <TableCell minwidth="160px">
          { sharedDelivery.resolvedShareAudienceSnippet && (
            <ShareAudience
              audienceSnippet={ sharedDelivery.resolvedShareAudienceSnippet }
              authenticatedPersonId={ authDescription?.user.id.toString() }
            />
          ) }
        </TableCell>
        <TableCell>
          <LineClamp minWidth={ 200 }>
            <FormattedMessage
              id="draftsTable.modified"
              description="Contents of modified cell in broadcast drafts table."
              defaultMessage="<span>{ date }</span> <span>by { nameAndAvatar }</span>"
              values={ {
                date: parseISO(sharedDelivery.broadcast.created.at)
                  .toLocaleString(
                    [...navigator.languages],
                    { weekday: 'short', day: 'numeric', month: 'short', hour: 'numeric', minute: 'numeric' },
                  ),
                nameAndAvatar: <InlinePersonAvatarAndName person={ sharedDelivery.broadcast.created.by }/>,
              } }
            />
          </LineClamp>
        </TableCell>
        <NonWrappingCell>
          <TableRowContextMenu
            id={ `${ sharedDelivery.broadcast.id }-menu` }
            actions={ actions }
          />
        </NonWrappingCell>
      </TableRow>
      { archiveConfirmationDialog }
      { deleteConfirmationDialog }
      { shareConfirmationDialog }
    </>
  );
};
