import { FC } from 'react';

import { LiveComponentProps } from '../../../../Content/Model';
import { TextContent } from '../../../Model/ContentTypes';
import { RichTextContentPreview } from '../../../../Content/Component';

export const LiveTextContent: FC<LiveComponentProps<TextContent>> = ({
  card,
}) => {
  return (
    <RichTextContentPreview text={ card.content.html }/>
  );
};
