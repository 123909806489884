import { FC } from 'react';
import { useIntl } from 'react-intl';

import { CenteredMessageWithLoadingCard } from '../CenteredMessageWithLoadingCard/CenteredMessageWithLoadingCard';

export const CenteredGenericLoadingMessage: FC = () => {
  const intl = useIntl();

  return (
    <CenteredMessageWithLoadingCard
      heading={ intl.formatMessage({
        id: 'genericLoadingMessage.heading',
        description: 'Generic loading message heading.',
        defaultMessage: 'Loading…',
      }) }
    />
  );
};
