import { FC, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';

import { ContentPreviewProps } from '../../../../Content/Model';
import { CoverEditorContent } from '../../../Service';
import { CardTitle, ContentWithType, ScrollableContent } from '..';
import { Flex, VerticallySpaced } from '../../../../Common/Component';
import { StyledComment } from './style';
import { EventDateAndLocation } from '..';

export const CoverPreview: FC<ContentPreviewProps<CoverEditorContent>> = ({
  editorContent,
  onReady,
}) => {
  const intl = useIntl();

  useEffect(() => {
    onReady();
  }, [onReady]);

  return (
    <ScrollableContent>
      <ContentWithType
        localisedContentType={ intl.formatMessage({
          id: 'broadcasts.content.preview.cover',
          description: 'Label for cover type used in preview.',
          defaultMessage: 'Cover',
        }) }
      >
        <VerticallySpaced gap={ 2 }>
          <CardTitle>{ editorContent.card.content.text }</CardTitle>
          { editorContent.card.content.comment && (
            <StyledComment>{ editorContent.card.content.comment }</StyledComment>
          ) }
          <EventDateAndLocation
            event={ editorContent.card.content }
          />
          <VerticallySpaced gap={ 0 } align="center">
            <Flex gap={ 2 }>
              <Button>
                <FormattedMessage
                  id="broadcasts.content.preview.cover.decline"
                  description="Decline button label on cover card."
                  defaultMessage="Decline"
                />
              </Button>
              <Button variant="primary">
                <FormattedMessage
                  id="broadcasts.content.preview.cover.request"
                  description="Request button label on cover card."
                  defaultMessage="Request"
                />
              </Button>
            </Flex>
          </VerticallySpaced>
        </VerticallySpaced>
      </ContentWithType>
    </ScrollableContent>
  );
};
