import { ListItemIcon, ListItemText } from '@mui/material';
import { FunctionComponent, ReactNode } from 'react';

import { StyledListItem } from './style';

interface Props {
  input: ReactNode;
  title: string;
  onClick: () => void;
  disabled?: boolean;
}

export const AppChoice: FunctionComponent<Props> = ({
  input,
  title,
  onClick,
  disabled = false,
}) => {
  return (
    <StyledListItem
      dense
      button
      disabled={ disabled }
      onClick={ onClick }
    >
      <ListItemIcon>
        { input }
      </ListItemIcon>
      <ListItemText>{ title }</ListItemText>
    </StyledListItem>
  );
};
