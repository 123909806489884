import styled from '@emotion/styled';
import { Chip } from '@mui/material';
import { ChipProps } from '@mui/material/Chip/Chip';

import { Wiggle } from '../../Style';
import CloseIcon from '../../../Assets/img/icons/monochrome/close.svg';

export const StyledChip = styled((props: ChipProps) => (
  <Chip
    variant="outlined"
    color="primary"
    clickable={ false }
    deleteIcon={ <CloseIcon/> }
    {...props}
  />
))`
  border-radius: 0.25rem;
  animation: ${ Wiggle } 0.25s linear;
  animation-iteration-count: 2;
  animation-fill-mode: both;

  .MuiChip-deleteIcon {
    color: inherit;
    width: 1rem;
    height: 1rem;
  }
`;
