import * as TWEEN from '@tweenjs/tween.js';
import { FunctionComponent, useEffect, useState } from 'react';
import { FormattedNumber } from 'react-intl';
import { FormatNumberOptions } from '@formatjs/intl/src/types';

import { StyledAnimatedNumber } from './style';
import { FeedbackSeverity } from '../Notice/Notice';

type Props = {
  targetValue: number;
  id?: string;
  initialValue?: number;
  formattingOptions?: FormatNumberOptions;
  color?: FeedbackSeverity;
}

export const AnimatedNumber: FunctionComponent<Props> = ({
  id,
  targetValue,
  initialValue = 0,
  formattingOptions = {
    maximumFractionDigits: 0,
  },
  color = 'info',
}) => {
  const [tweenedNumber, setTweenedNumber] = useState<number>(initialValue);

  useEffect(() => {
    const tween = new TWEEN.Tween({ value: initialValue })
      .to({ value: targetValue }, 500)
      .easing(TWEEN.Easing.Exponential.Out)
      .onUpdate((tweenObject) => {
        setTweenedNumber(tweenObject.value);
      })
      .start();

    let requestAnimationFrameId: number;
    const updateTween = (time?: number) => {
      requestAnimationFrameId = requestAnimationFrame(updateTween)
      tween.update(time);
    }

    updateTween();

    return () => {
      cancelAnimationFrame(requestAnimationFrameId);
      tween.stop();
    }
  }, [initialValue, targetValue]);

  return (
    <StyledAnimatedNumber
      data-testid="animated-number"
      id={ id }
      color={ color }
    >
      <FormattedNumber value={ tweenedNumber } { ...formattingOptions } />
    </StyledAnimatedNumber>
  )
}
