import { FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { CenteredGenericLoadingMessage } from '@ourpeople/shared/Core/Component/Content';

import { Box, LoadingContainer, RouterTabs } from '../../../Common/Component';
import { useFetchSettings } from '../../../Sections/Settings/System/Hooks';
import { Setting, SettingCategory, SettingSubCategory, SettingType } from '../../../Sections/Settings/System/Types';
import { AuthContext } from '../../../Contexts';
import { StyledTabContent } from './style';
import { SettingsCategoryForm } from '../SettingsCategoryForm/SettingsCategoryForm';
import { CategoryFormProps } from '../../Model';

type AdditionalTab = {
  value: string;
  label: string;
  content: ReactNode;
};

type Props = {
  path: string;
  updatePermission: string;
  additionalTabs?: AdditionalTab[];
  customRendering?: Record<string, FC<CategoryFormProps>>;
};

export const TabbedSettings: FC<Props> = ({
  path,
  updatePermission,
  additionalTabs = [],
  customRendering = {},
}) => {
  const [fetchedSettings] = useFetchSettings();
  const filteredCategories = useMemo(() => (
    fetchedSettings?.content?.categories.reduce<SettingCategory[]>(
      (filteredCategories, category) => {
        const subCategories = category.subCategories.reduce<SettingSubCategory[]>(
          (filteredSubCategories, subCategory) => {
            const filteredSettings = subCategory.settings.filter(setting => setting.writePermission === updatePermission);
            return filteredSettings.length
              ? filteredSubCategories.concat({
                ...subCategory,
                settings: filteredSettings
              })
              : filteredSubCategories;
          },
          [],
        );

        return subCategories.length
          ? filteredCategories.concat({
            ...category,
            subCategories,
          })
          : filteredCategories
      },
      [],
    ) || []
  ), [fetchedSettings?.content?.categories, updatePermission]);
  const { refreshAuthDescription } = useContext(AuthContext);
  const [settingCategories, setSettingCategories] = useState<SettingCategory[]>([]);

  useEffect(() => {
    setSettingCategories(filteredCategories);
  }, [filteredCategories]);

  const whenCategorySaved = useCallback((originalCategory: SettingCategory) => (
    (savedSettings: Map<string, Setting<SettingType>>): void => {
      const updatedCategory = replaceSettingsInCategory(originalCategory, savedSettings);
      setSettingCategories(
        settingCategories
          .map(category => (
            category.key === updatedCategory.key
              ? updatedCategory
              : category
          ))
      );
      refreshAuthDescription();
    }
  ), [refreshAuthDescription, settingCategories]);

  const replaceSettingsInCategory = (
    category: SettingCategory,
    settings: Map<string, Setting<SettingType>>
  ): SettingCategory => {
    return {
      ...category,
      subCategories: category.subCategories
        .map(subCategory => ({
          ...subCategory,
          settings: subCategory.settings
            .map(setting => settings.get(setting.key) || setting)
        }))
    };
  };

  const tabs = useMemo(() => [
    ...settingCategories.map(settingCategory => ({
      link: `${ path }/${ settingCategory.key }`,
      title: settingCategory.name,
      render: () => {
        const CategoryFormComponent = customRendering[settingCategory.key] || SettingsCategoryForm;
        return (
          <CategoryFormComponent
            category={ settingCategory }
            onSaved={ whenCategorySaved(settingCategory) }
          />
        );
      },
    })),
    ...(settingCategories.length ? additionalTabs.map(additionalTab => ({
        link: `${ path }/${ additionalTab.value }`,
        title: additionalTab.label,
        render: () => additionalTab.content,
      })) : []),
  ], [additionalTabs, customRendering, path, settingCategories, whenCategorySaved]);

  return (
    tabs.length
      ? <RouterTabs useNewThemeVariant="section" tabs={ tabs }/>
      : <LoadingContainer><CenteredGenericLoadingMessage/></LoadingContainer>
  );
};
