import styled from '@emotion/styled';
import { BoxProps } from '@mui/material';

import { Box, Flex } from '../../../Common/Component';

export const EditorContainer = styled(
  (props: BoxProps) => <Box padded={ false } margin={ false } { ...props }/>
)`
  display: flex;
  flex-direction: column;
`;

export const EditorNav = styled(Flex)`
  padding: ${ props => `${ props.theme.spacing(1) }` };
  border-bottom: 1px solid ${props => props.theme.palette.grey[300]};
`;

export const StyledEditorContent = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(auto, 1fr) 20rem;

  ${ props => props.theme.new.breakpoints.md.down } {
    grid-template-columns: 1fr 20rem;
  }

  ${ props => props.theme.new.breakpoints.sm.down } {
    grid-template-columns: 1fr;
  }
`;

export const CardErrors = styled.div`
  display: flex;
  gap: 0.5rem;
  color: ${ props => props.theme.palette.error.main };
  font-weight: 500;
  align-items: center;
`
