import { useIntl } from 'react-intl';
import { useMemo } from 'react';

import { ChatStatus } from './useFetchChatAudienceMembers';

export const useLocalisedChatStatuses = (): Record<ChatStatus, string> => {
  const intl = useIntl();
  return useMemo<Record<ChatStatus, string>>(() => ({
    banned: intl.formatMessage({
      id: 'chatStatus.banned',
      description: 'Chat status for audience members who are banned from a topic.',
      defaultMessage: 'Banned',
    }),
    removed: intl.formatMessage({
      id: 'chatStatus.removed',
      description: 'Chat status for audience members who are removed from a topic.',
      defaultMessage: 'Removed',
    }),
    pending: intl.formatMessage({
      id: 'chatStatus.pending',
      description: 'Chat status for audience members who are pending.',
      defaultMessage: 'Never joined',
    }),
    left: intl.formatMessage({
      id: 'chatStatus.left',
      description: 'Chat status for audience members who left a topic.',
      defaultMessage: 'Left',
    }),
    joined: intl.formatMessage({
      id: 'chatStatus.joined',
      description: 'Chat status for audience members who are members.',
      defaultMessage: 'Member',
    }),
  }), [intl]);
};
