import { ElementType, forwardRef, MouseEventHandler, ReactChild, ReactElement, Ref } from 'react';
import { Button as MuiButton, ButtonProps as MuiButtonProps, CircularProgress, PropTypes } from '@mui/material';
import { useTheme } from '@emotion/react';
import { ButtonTypeMap } from '@mui/material/Button/Button';
import { Link } from 'react-router-dom';

import { ConditionalTooltip } from '../../../../../src/react/Common/Component/ConditionalTooltip/ConditionalTooltip';
import { Flex } from '../../../../../src/react/Common/Component/Flex/Flex';
import { ProgressContainer, StyledButton } from './style';
import { SvgComponent } from '../../../../../src/react/Common/Model/SvgComponent';

export type ButtonProps<
  C extends ElementType = ButtonTypeMap['defaultComponent']
> = Omit<MuiButtonProps<C, { component?: C }>, 'variant' | 'color'> & {
  variant?: 'primary' | 'secondary' | 'tertiary';
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabledTooltip?: ReactChild;
  IconComponent?: SvgComponent;
  busy?: boolean;
  inline?: boolean;
  className?: string;
  color?: PropTypes.Color | 'error';
};

export type AnyButtonProps = ButtonProps | ButtonProps<typeof Link>;

type ButtonType = <C extends ElementType>(
  props: ButtonProps<C>,
  ref?: Ref<HTMLButtonElement>
) => ReactElement;

const UnforwardedButton: ButtonType = ({
  variant = 'tertiary',
  IconComponent,
  disabledTooltip,
  busy = false,
  className,
  disabled,
  children,
  inline = false,
  fullWidth,
  color,
  ...props
}, ref) => {
  const theme = useTheme();

  return (
    <ConditionalTooltip
      active={ !!(disabled && disabledTooltip) }
      title={ disabledTooltip || '' }
    >
      <StyledButton
        { ...color === 'error' ? { color: 'error' } : {} }
        className={ className }
        inline={ inline }
        fullWidth={ fullWidth }
      >
        <MuiButton
          disableElevation={ variant === 'primary' }
          variant={ variant === 'primary' ? 'contained' : variant === 'secondary' ? 'outlined' : 'text' }
          disabled={ busy || disabled }
          ref={ ref }
          fullWidth={ fullWidth }
          { ...(color === 'error' ? {} : { color: color || 'primary' }) }
          { ...props }
        >
          <Flex gap={ 1 }>
            { IconComponent && (
              <IconComponent
                role="presentation"
                width={ `${ theme.spacing(3) }` }
                height={ `${ theme.spacing(3) }` }
              />
            ) }
            <span>{ children }</span>
          </Flex>
        </MuiButton>
        { busy && (
          <ProgressContainer>
            <CircularProgress size={ `${ theme.spacing(3) }` }/>
          </ProgressContainer>
        ) }
      </StyledButton>
    </ConditionalTooltip>
  );
};

/** @deprecated Prefer Button from op-storybook project */
export const Button = forwardRef(UnforwardedButton) as ButtonType;
