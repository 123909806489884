import styled from '@emotion/styled';

export const StyledTemplatePrompts = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  gap: ${ props => props.theme.spacing(1) };
  flex-wrap: wrap;
`;
