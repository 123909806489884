import {ValidationError} from '../../model/Validation/ValidationError';

type CheckFunction<E extends ValidationError> = (error: ValidationError) => error is E;
type HandleFunction<E extends ValidationError> = (error: E) => string;

export type ValidationErrorHandler<E extends ValidationError = ValidationError> = {
  assert: CheckFunction<E>;
  handle: HandleFunction<E>;
};

export class ValidationErrorHandlerFactory {
  public static createHandler = <E extends ValidationError = ValidationError>(
    assert: CheckFunction<E>,
    handle: HandleFunction<E>,
  ): ValidationErrorHandler<E> => ({
    assert,
    handle,
  });

  public static defaultHandler = <E extends ValidationError = ValidationError>(): ValidationErrorHandler<E> => ({
    assert: (error): error is E => true,
    handle: (error) => error.type,
  });
}
