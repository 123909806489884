import styled from '@emotion/styled';
import { Paper } from '@mui/material';

import { StyledDropzone } from '../Dropzone/style';

export const StyledDialog = styled(Paper)`
  height: 100%;

  .MuiDialogContent-root {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export const StyledTabContainer = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  gap: ${props => props.theme.spacing(2)};
`;

export const StyledImageAssetPickerContainer = styled.div`
  overflow-y: auto;

  ${ props => props.theme.breakpoints.down('lg') } {
    width: 100%;
  }
`;

export const StyledDropzoneContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  > ${ StyledDropzone } {
    width: 50%;
    height: 75%;
    justify-content: center;

    ${ props => props.theme.breakpoints.down('lg') } {
      width: 100%;
    }
  }
`;
