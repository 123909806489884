import { ChartData, ChartDataset } from 'chart.js';
import { FunctionComponent, ReactNode } from 'react';

import { StyledLegend, LegendSwatch, LegendLabel } from './style';

interface Props {
  data: Required<ChartData>;
  useDatasets?: boolean;
}

export const Legend: FunctionComponent<Props> = ({ data, useDatasets = false }) => {
  const renderLegendItem = (label: unknown, index: number): ReactNode => {
    if (typeof label !== 'string') {
      return '';
    }

    const colours = data.datasets[0]?.backgroundColor;

    if (!Array.isArray(colours) || !colours[index] || typeof colours[index] !== 'string') {
      return '';
    }

    return (
      <LegendLabel key={ label }>
        <LegendSwatch colour={ colours[index] as string }/><span>{ label }</span>
      </LegendLabel>
    )
  };

  const renderDatasetLegendItem = (dataset: ChartDataset): ReactNode => {
    return (
      <LegendLabel key={dataset.label}>
        <LegendSwatch colour={ dataset.backgroundColor as string }/><span>{ dataset.label }</span>
      </LegendLabel>
    );
  };

  return (
    <StyledLegend>
      { useDatasets && data.datasets.map(renderDatasetLegendItem) }
      { !useDatasets && data.labels.map(renderLegendItem) }
    </StyledLegend>
  )
}
