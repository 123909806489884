import { ValidationTree, Validator } from '../../../../Common/Model';
import { Validate } from '../../../../Common/Utility';
import { DraftContent } from '../../../../Content/Model';

type DraftMediaContent = DraftContent & {
  text: string;
  media: string;
};

export class MediaContentValidator {
  public static validate = <T extends DraftMediaContent>(content: T): ValidationTree<T> => ({
    errors: [],
    children: {
      text: MediaContentValidator.validateText(content.text),
      media: MediaContentValidator.validateMedia(content.media),
    },
  });

  public static validateText = (text: string): ValidationTree<string> => ({
    errors: textValidator.validate(text),
    children: {},
  });

  public static validateMedia = (media: string): ValidationTree<string> => ({
    errors: mediaValidator.validate(media),
    children: {},
  });
}

export const MEDIA_TEXT_MIN_LENGTH = 1;
export const MEDIA_TEXT_MAX_LENGTH = 80;

const mediaValidator = new Validator<string>([
  Validate.notBlank,
]);

const textValidator = new Validator<string>([
  Validate.notBlank,
  text => Validate.minLength(text, MEDIA_TEXT_MIN_LENGTH),
  text => Validate.maxLength(text, MEDIA_TEXT_MAX_LENGTH),
]);
