import { FC, ReactNode, useMemo } from 'react';

import { ContentDefinitionRegistryContext } from '../Context';
import { ContentDefinitionRegistry } from '../Service';
import { ContentDefinition } from '../Model';

type Props = {
  definitions: ContentDefinition[];
  children?: ReactNode;
};

export const ContentDefinitionRegistryProvider: FC<Props> = ({
  definitions,
  children,
}) => {
  const registry = useMemo<ContentDefinitionRegistry>(
    () => new ContentDefinitionRegistry(definitions),
    [definitions],
  );

  return (
    <ContentDefinitionRegistryContext.Provider value={ registry }>
      { children }
    </ContentDefinitionRegistryContext.Provider>
  );
};
