import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { LoadingCard } from '@ourpeople/shared/Core/Component/Feedback';
import { CenteredGenericErrorMessage, CenteredMessageWithButton } from '@ourpeople/shared/Core/Component/Content';
import { toZonedTime } from 'date-fns-tz';

import { Fullscreen, Padded } from '../../../Components';
import { GenericConfirmNavigationDialog, PageHeader } from '../../../Common/Component';
import { useFetchDraftBroadcast } from '../../Hook';
import { DeprecatedBroadcast, DraftBroadcast } from '../../Model';
import { BroadcastTransformer, ContentTransformer } from '../../Utility';
import { useContentDefinitionRegistry } from '../../../Content/Hook';
import { EditorContent } from '../../../Content/Model';
import { BroadcastEditForm } from '../../Component';
import { useApi, useContextOrThrow } from '../../../Core/Hook';
import { ToastContext, WebsocketContext } from '../../../Core/Context';
import { UserInteraction } from '../../../Models/UserInteraction';
import { Audience } from '../../../Audiences/Model';
import { BroadcastErrorResponseReader } from '../../Utility/BroadcastErrorResponseReader';
import { useUneditableBroadcastMessage } from '../../Hook/useUneditableBroadcastMessage';
import { StyledErrorMessageContainer } from './style';
import { Schedule } from '../../Model/BroadcastSchedule';
import { RRuleParser } from '../../../Utility/RRuleParser';

type Params = {
  broadcastId: string;
};

type ResourceLockRequestResult = {
  type: 'resourceLockRequestResult';
  payload: {
    resourceLock: {
      reference: string;
      created: UserInteraction;
      expiresAt: string;
    };
    successful: boolean;
  };
};

export const EditBroadcastPage: FC = () => {
  const [touched, setTouched] = useState<boolean>(false);
  const { sendMessage, lastMessage } = useContextOrThrow(WebsocketContext);
  const uneditableBroadcastTooltipMessage = useUneditableBroadcastMessage();
  const intl = useIntl();
  const api = useApi();
  const { broadcastId } = useParams<Params>();
  const [fetchBroadcastResult, , reloadBroadcast] = useFetchDraftBroadcast(broadcastId);
  const savedBroadcast = fetchBroadcastResult?.content;
  const [broadcast, setBroadcast] = useState<DraftBroadcast>();
  const [lockReference, setLockReference] = useState<string>();
  const { transformCard } = useContentDefinitionRegistry();
  const [editorContents, setEditorContents] = useState<EditorContent[]>([]);
  const lockRequestInterval = useRef<number>();
  const { addErrorToast } = useContextOrThrow(ToastContext);
  const history = useHistory();
  const [shareAudience, setShareAudience] = useState<Audience>();
  const [shareAudienceError, setShareAudienceError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [schedule, setSchedule] = useState<Schedule>({
    localStartDate: null,
    recurrence: null,
  });

  useEffect(() => {
    if (!savedBroadcast) {
      return;
    }

    api.get<Audience>(`/audiences/${ savedBroadcast.broadcast.shareAudienceId }`)
      .then((response) => {
        setShareAudience(response.data);
        setLoading(false);
      })
      .catch(() => {
        setShareAudienceError(true);
        setLoading(false);
      });
  }, [api, savedBroadcast]);

  useEffect(() => {
    if (!savedBroadcast || !!lockReference) {
      return;
    }

    setLockReference(savedBroadcast.broadcast.lockReference);
  }, [savedBroadcast, lockReference]);

  useEffect(() => {
    if (!lockReference) {
      return;
    }

    const submitLockRequest = () => sendMessage({
      type: 'resourceLockRequest',
      payload: {
        lockReference,
      },
    });

    submitLockRequest();
    lockRequestInterval.current = window.setInterval(
      () => {
        submitLockRequest();
      },
      20000
    );

    return () => {
      lockRequestInterval.current && clearInterval(lockRequestInterval.current);
    };
  }, [lockReference, sendMessage]);

  useEffect(() => {
    if (lastMessage?.type !== 'resourceLockRequestResult' || !savedBroadcast) {
      return;
    }

    const resourceLockRequestResult = lastMessage as ResourceLockRequestResult;
    const referencesMatch = resourceLockRequestResult.payload.resourceLock.reference === savedBroadcast.broadcast.lockReference;
    if (!referencesMatch || resourceLockRequestResult.payload.successful) {
      return;
    }

    addErrorToast(
      intl.formatMessage({
        id: 'broadcasts.edit.lockRequestFailed',
        description: 'Toast message presented to user when attempting to edit a locked broadcast.',
        defaultMessage: 'That broadcast is currently being edited by another user.',
      })
    );

    history.replace('/broadcasts?tab=drafts');
  }, [addErrorToast, history, intl, lastMessage, savedBroadcast]);

  useEffect(() => {
    if (!fetchBroadcastResult?.content) {
      return;
    }

    const { broadcast, delivery } = fetchBroadcastResult?.content;

    setSchedule(
      broadcast.recurrenceRule
        ? RRuleParser.schedule(broadcast.recurrenceRule)
        : {
          recurrence: null,
          localStartDate: delivery.deliverAt
            ? toZonedTime(delivery.deliverAt.dt, Intl.DateTimeFormat().resolvedOptions().timeZone)
            : null,
        }
    );
  }, [fetchBroadcastResult?.content]);

  useEffect(() => {
    if (loading && fetchBroadcastResult?.error) {
      setLoading(false);
    }
  }, [fetchBroadcastResult?.error, loading]);

  useEffect(() => {
    if (!shareAudience || !savedBroadcast) {
      return;
    }

    setBroadcast(
      BroadcastTransformer.toDraft(
        savedBroadcast.broadcast,
        savedBroadcast.recipientAudience,
        savedBroadcast.delivery,
        shareAudience,
      ),
    );
    setEditorContents(savedBroadcast.contents.map(content => transformCard(ContentTransformer.toCard(content))));
  }, [savedBroadcast, shareAudience, transformCard]);

  const whenEditorContentsChanged = useCallback(
    (callbackOrContents: ((editorContents: EditorContent[]) => EditorContent[]) | EditorContent[]) => {
      setEditorContents(callbackOrContents);
      setTouched(true);
    },
    [],
  );

  const whenBroadcastChanged = useCallback(
    (callbackOrBroadcast: ((broadcast: DraftBroadcast) => DraftBroadcast) | DraftBroadcast) => {
      setBroadcast(broadcast => {
        if (!broadcast) {
          return broadcast;
        }

        return typeof callbackOrBroadcast === 'function'
          ? callbackOrBroadcast(broadcast)
          : callbackOrBroadcast;
      });
      setTouched(true);
    },
    [],
  );

  const whenBroadcastSaved = useCallback((broadcast: DeprecatedBroadcast) => {
    setTouched(false);
    setLockReference(broadcast.lockReference)
  }, [setLockReference]);

  return (
    <>
      <Padded>
        <PageHeader
          items={ [
            {
              link: '/broadcasts',
              title: intl.formatMessage({
                id: 'section.broadcasts',
                description: 'Heading label for Broadcasts',
                defaultMessage: 'Broadcasts',
              })
            },
            ...(
              broadcast
                ? [
                  {
                    title: broadcast.name,
                  },
                  {
                    title: intl.formatMessage({
                      id: 'sections.broadcasts.edit',
                      description: 'Title displayed over the broadcasts edit page',
                      defaultMessage: 'Edit'
                    }),
                  },
                ]
                : []
            ),
          ] }
        />
        {
          loading
            ? <Fullscreen><LoadingCard/></Fullscreen>
            : broadcast && broadcastId
              ? (
                <BroadcastEditForm
                  broadcast={ broadcast }
                  editorContents={ editorContents }
                  setBroadcast={ whenBroadcastChanged }
                  setEditorContents={ whenEditorContentsChanged }
                  saveEndpoint={ `broadcasts/${ broadcastId }` }
                  onSaveComplete={ whenBroadcastSaved }
                  onPublished={ () => setTouched(false) }
                  schedule={ schedule }
                  setSchedule={ setSchedule }
                />
              )
              : (fetchBroadcastResult?.error || shareAudienceError) && (
                fetchBroadcastResult?.error && BroadcastErrorResponseReader.isNotEditableError(fetchBroadcastResult.error)
                ? (
                  <StyledErrorMessageContainer>
                    <CenteredMessageWithButton
                      heading={ uneditableBroadcastTooltipMessage }
                      onButtonClicked={ () => history.push('../') }
                      buttonVariant="secondary"
                      buttonLabel={ intl.formatMessage({
                        description: 'Return to broadcast list button when loading a broadcast fails because it\'s no longer editable',
                        defaultMessage: 'Return to broadcast list',
                      }) }
                    />
                  </StyledErrorMessageContainer>
                )
                : <CenteredGenericErrorMessage onRetryClicked={ reloadBroadcast }/>
              )
        }
      </Padded>
      <GenericConfirmNavigationDialog
        active={ touched }
        ignorePattern={ new RegExp(`^/broadcasts/${ broadcastId }/edit`) }
      />
    </>
  );
};
