import { FunctionComponent, useMemo } from 'react';

import { CardReport } from '../Hooks/useFetchBroadcastReportSummary';
import { Box } from '../../../../Common/Component';
import { ContentReader } from '../../../../Readers';
import { ContentPreview } from './ContentPreview';
import {
  CommentContentReportTabs,
  ContentWithOptionsReportTabs,
  CoverContentReportTabs,
  DefaultContentReportTabs,
  EventContentReportTabs,
  RatingContentReportTabs,
  UploadImageContentReportTabs,
} from './Tabs';

interface Props {
  broadcastId: string;
  deliveryId: string;
  card: CardReport;
  pendingCount: number;
  initialSearch?: string;
}

export const ContentSummary: FunctionComponent<Props> = ({
  card,
  broadcastId,
  deliveryId,
  pendingCount,
  initialSearch,
}) => {
  const tabs = useMemo(() => {
    switch (ContentReader.contentTypeFromString(card.content.type)) {
      case 'comment':
        return (
          <CommentContentReportTabs
            card={ card }
            broadcastId={ broadcastId }
            deliveryId={ deliveryId }
            pendingCount={ pendingCount }
            initialSearch={ initialSearch }
          />
        );
      case 'rating':
        return (
          <RatingContentReportTabs
            card={ card }
            broadcastId={ broadcastId }
            deliveryId={ deliveryId }
            pendingCount={ pendingCount }
            initialSearch={ initialSearch }
          />
        );
      case 'meeting':
      case 'training':
        return (
          <EventContentReportTabs
            card={ card }
            broadcastId={ broadcastId }
            deliveryId={ deliveryId }
            pendingCount={ pendingCount }
            initialSearch={ initialSearch }
          />
        );
      case 'cover':
        return (
          <CoverContentReportTabs
            card={ card }
            broadcastId={ broadcastId }
            deliveryId={ deliveryId }
            pendingCount={ pendingCount }
            initialSearch={ initialSearch }
          />
        );
      case 'quiz':
      case 'singlechoice':
      case 'multiplechoice':
        return (
          <ContentWithOptionsReportTabs
            card={ card }
            broadcastId={ broadcastId }
            deliveryId={ deliveryId }
            pendingCount={ pendingCount }
            initialSearch={ initialSearch }
          />
        );
      case 'upload_image':
        return (
          <UploadImageContentReportTabs
            card={ card }
            broadcastId={ broadcastId }
            deliveryId={ deliveryId }
            pendingCount={ pendingCount }
            initialSearch={ initialSearch }
          />
        );
      default:
        return (
          <DefaultContentReportTabs
            card={ card }
            broadcastId={ broadcastId }
            deliveryId={ deliveryId }
            pendingCount={ pendingCount }
            initialSearch={ initialSearch }
          />
        );
    }
  }, [card, broadcastId, deliveryId, pendingCount, initialSearch]);

  return (
    <Box padded={ false }>
      <ContentPreview content={ card.content } status={ card.status }/>
      { tabs }
    </Box>
  );
};
