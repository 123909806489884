import { FC } from 'react';

import { ContentEditorProps } from '../../../../Content/Model';
import { MeetingEditorContent } from '../../../Service';
import { VerticallySpaced } from '../../../../Common/Component';
import { SharedMeetingAndTrainingFields } from '..';

export const MeetingEditor: FC<ContentEditorProps<MeetingEditorContent>> = ({
  editorContent,
  onEditorContentChanged,
  validation,
  onValidationChanged,
}) => (
  <VerticallySpaced gap={ 2 }>
    <SharedMeetingAndTrainingFields
      editorContent={ editorContent }
      onEditorContentChanged={ onEditorContentChanged }
      validation={ validation }
      onValidationChanged={ onValidationChanged }
    />
  </VerticallySpaced>
);
