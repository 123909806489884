import { FC, PropsWithChildren, useCallback } from 'react';
import { FunctionInterpolation } from '@emotion/react';

import { SizeKey, ThemeSystemPaletteKey } from '../../types/Theme/Theme';
import { defaultTheme } from '../../const/defaultTheme';

type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  colour?: ThemeSystemPaletteKey;
  variant?: 'light-circle' | 'light-circle-outline' | 'outline' | 'modern' | 'modern-borderless';
  className?: string;
};

export const FeaturedGraphic: FC<PropsWithChildren<Props>> = ({
  size = 'sm',
  colour = 'primary',
  variant = 'modern',
  className,
  children,
}) => {
  const featuredGraphicCss: FunctionInterpolation<typeof defaultTheme> = useCallback(theme => {
    const pxSize = size === 'sm'
      ? theme.new.spacing[8]
      : size === 'md'
        ? theme.new.spacing[10]
        : size === 'lg'
          ? theme.new.spacing[12]
          : theme.new.spacing[16];

    return {
      position: 'relative',
      flexShrink: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: pxSize,
      height: pxSize,
      ...['light-circle', 'outline', 'light-circle-outline'].includes(variant)
        ? { borderRadius: theme.new.borderRadius.max }
        : {},
      ...['light-circle', 'light-circle-outline'].includes(variant)
        ? { backgroundColor: theme.new.palette[colour][100].main }
        : {},
      ...variant === 'light-circle-outline'
        ? {
          outline: `${ getOutlineWidth(size) }px solid ${ theme.new.palette[colour][50].main }`,
          outlineOffset: `${ getOutlineWidth(size) / -2 }px`,
        }
        : {},
      ...['modern', 'modern-borderless'].includes(variant)
        ? {
          borderRadius: theme.new.borderRadius.standard,
          boxShadow: theme.new.shadow.xs,
          overflow: 'hidden',
          backgroundColor: theme.new.basePalette.white.main,
        }
        : {},
      ...variant === 'outline'
        ? {
          outline: `${ getOutlineWidth(size) }px solid ${ theme.new.palette[colour][200].main }`,
          outlineOffset: `-${ getOutlineWidth(size) / 2 }px`,

          '&::after': {
            content: '""',
            display: 'block',
            position: 'absolute',
            width: '100%',
            height: '100%',
            borderRadius: theme.new.borderRadius.max,
            outlineOffset: `${ getOutlineWidth(size) }px`,
            outline: `${ getOutlineWidth(size) }px solid ${ theme.new.palette[colour][100].main }`,
          }
        }
        : {},
      ...variant === 'modern'
        ? { border: theme.new.border.standard }
        : {}
    };
  }, [size, colour, variant]);

  return (
    <div
      css={ featuredGraphicCss }
      className={ className }
    >
      { children }
    </div>
  );
};

const getOutlineWidth = (size: Omit<SizeKey, 'xs'>): number => (
  size === 'xl'
    ? 10
    : size === 'lg'
      ? 8
      : size === 'md'
        ? 6
        : 4
);
