import { useCallback, useMemo } from 'react';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { useValidationErrors } from 'op-storybook/lib/hooks/useValidationErrors';
import { useIntl } from 'react-intl';

import { InlineFormComponent } from '../../../Model/BroadcastBlockDefinition';
import { TextBlock } from '../../../Service/Blocks/TextBlockDefinition';
import { BroadcastEditorErrors } from '../../BroadcastEditorErrors';
import { useContextOrThrow } from '../../../../../Core/Hook';
import { BroadcastBlockDefinitionRegistryContext } from '../../../Context/BroadcastBlockDefinitionRegistry';
import { TextBlockInput } from './TextBlockInput';

export const TextBlockInlineForm: InlineFormComponent<TextBlock> = ({
  block,
  onChange,
  validationTree,
  onRemove,
}) => {
  const intl = useIntl();
  const { getBlockErrorDefinitions } = useContextOrThrow(BroadcastBlockDefinitionRegistryContext);
  const blockErrorDefinitions = useMemo(() => getBlockErrorDefinitions(block), [block, getBlockErrorDefinitions]);
  const { formatValidationTreeWithDefinitions } = useValidationErrors();
  const formattedHtmlErrors = useMemo(() => formatValidationTreeWithDefinitions(
    validationTree.children.attributes?.children.html,
    blockErrorDefinitions.html,
  ), [blockErrorDefinitions.html, formatValidationTreeWithDefinitions, validationTree.children.attributes?.children.html]);
  const whenHtmlChanged = useCallback((html: string) => {
    onChange({
      ...block,
      attributes: {
        ...block.attributes,
        html,
      },
    });
  }, [block, onChange]);

  return (
    <Stack direction="column">
      <div>
        <TextBlockInput
          value={ block.attributes.html }
          onChange={ value => whenHtmlChanged(value) }
          autofocus={ !block.attributes.html }
          placeholder={ intl.formatMessage({
            description: 'Placeholder for broadcast text block input.',
            defaultMessage: 'Add text…',
          }) }
          onDelete={ onRemove }
        />
      </div>
      { !!formattedHtmlErrors.length && (
        <BroadcastEditorErrors errors={ formattedHtmlErrors }/>
      ) }
    </Stack>
  );
};
