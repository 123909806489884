import { FC, useEffect } from 'react';

import { ContentPreviewProps } from '../../../../Content/Model';
import { ScrollableContent } from '..';
import { VideoEditorContent } from '../../../Service';
import { VideoContentWithoutFooter, VideoPlayButton, VideoThumbnail } from '../../../../Content/Component';
import { StyledThumbnailPlaceholder, StyledVideoContentPreview } from './style';
import { useOpenUpload } from '../../../../Files/Hook/useOpenUpload';

export const VideoContentPreview: FC<ContentPreviewProps<VideoEditorContent>> = ({
  editorContent,
  onReady,
}) => {
  const [open, opening] = useOpenUpload();

  useEffect(() => {
    onReady();
  }, [onReady]);

  return (
    <ScrollableContent>
      <StyledVideoContentPreview>
        <VideoContentWithoutFooter
          title={ editorContent.card.content.text }
          description={ editorContent.card.content.description || undefined }
          thumbnail={
            editorContent.card.content.media && editorContent.card.content.thumbnailKey
              ? (
                <VideoThumbnail
                  uploadId={ editorContent.card.content.media }
                  thumbnailKey={ editorContent.card.content.thumbnailKey }
                  busy={ opening }
                  duration={ editorContent.media?.video?.duration || undefined }
                  onClick={ () => open(editorContent.card.content.media) }
                />
              )
              : (
                <StyledThumbnailPlaceholder>
                  <VideoPlayButton
                    busy={ false }
                    duration={ undefined}
                  />
                </StyledThumbnailPlaceholder>
              )
          }
        />
      </StyledVideoContentPreview>
    </ScrollableContent>
  );
};
