import { FC } from 'react';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { ModularCard } from 'op-storybook/lib/components/ModularCard/ModularCard';
import { SectionHeader } from '@ourpeople/shared/Core/Component/Content/SectionHeader/SectionHeader';
import { PercentageChangeMetric } from 'op-storybook/stories/components/PercentageChangeMetric/PercentageChangeMetric';

import { useIntl } from 'react-intl';
import { UsagePeriod } from '../../Model/UsagePeriod';

type Props = {
  heading: string;
  usage: UsagePeriod['usage'];
  previousUsage?: UsagePeriod['usage'];
};

export const Usage: FC<Props> = ({
  heading,
  usage,
  previousUsage,
}) => {
  const intl = useIntl();

  return (
    <ModularCard
      headerContent={ <SectionHeader seamless text={ heading }/> }
      elevation="xs"
      inset
      seamless
    >
      <Stack direction="column">
        <PercentageChangeMetric
          label={ intl.formatMessage({
            description: 'Label for recipients stat in unlimited SMS usage report.',
            defaultMessage: 'Recipients',
          }) }
          value={ usage.recipientCount }
          previousValue={ previousUsage?.recipientCount }
        />
        <PercentageChangeMetric
          label={ intl.formatMessage({
            description: 'Label for sms sent stat in unlimited SMS usage report.',
            defaultMessage: 'SMS sent',
          }) }
          value={ usage.smsCount }
          previousValue={ previousUsage?.smsCount }
        />
        <PercentageChangeMetric
          label={ intl.formatMessage({
            description: 'Label for credits spent stat in unlimited SMS usage report.',
            defaultMessage: 'Credits spent',
          }) }
          value={ usage.smsCount }
          previousValue={ previousUsage?.smsCount }
        />
      </Stack>
    </ModularCard>
  );
};
