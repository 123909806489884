import { Dataset, Metric } from '../Model';

export class MetricReader {
  public static getMetricDataset = (metrics: Metric[], metricKey: string, datasetKey: string): Dataset | undefined => {
    const metric = MetricReader.getMetric(metrics, metricKey);

    if (!metric) {
      return;
    }

    const datasets = metric.datasets || [];
    return MetricReader.getDataset(
      datasets,
      datasetKey,
    );
  };

  public static getMetric = (metrics: Metric[], key: string): Metric | undefined => findObjectWithKeyInArray(metrics, key);
  public static getDataset = (datasets: Dataset[], key: string): Dataset | undefined => findObjectWithKeyInArray(datasets, key);
}

const findObjectWithKeyInArray = <T extends { key: string }>(arr: T[], key: string): T | undefined => arr.find(obj => obj.key === key);
