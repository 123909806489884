import { ChangeEventHandler, FC, useCallback } from 'react';
import { FormControl, InputLabel, ListItemText, MenuItem, Select, SelectProps } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';

import { StyledCheckbox } from '../../../Components/Input/styles';
import { StyledWeekdaySelect } from './style';
import { DayIndex } from '../../Model/BroadcastSchedule';

type LocalisedDay = {
  name: string;
  index: number;
};

type Props = Omit<SelectProps, 'onChange' | 'multiple'> & {
  dayIndices: DayIndex[];
  onChange: (dayIndices: DayIndex[]) => void;
  multiple?: boolean;
};

export const WeekdaySelect: FC<Props> = ({
  dayIndices,
  onChange,
  multiple,
  ...props
}) => {
  const intl = useIntl();
  const localisedDays: LocalisedDay[] = new Array(7)
    .fill(null)
    .map((emptyValue: null, index: number) => {
      // Offset index by 4 to begin at first Sunday of epoch.
      const weekday = new Date(1970, 0, index + 4);
      return {
        name: intl.formatDate(weekday, { weekday: 'long' }),
        index,
      };
    });

  const whenWeekdayChanged: ChangeEventHandler<{ value: unknown }> = useCallback(event => {
    const value = Array.isArray(event.target.value) ? event.target.value : [event.target.value];
    onChange(value.map(value => +value));
  }, [onChange]);

  const getLocalisedWeekdaysFromIndices = useCallback((selectedDayIndices: number[]): string[] => (
    selectedDayIndices.reduce<LocalisedDay[]>((localisedSelection, selectedDayIndex) => {
        const validDay = localisedDays.find((localisedDay) => localisedDay.index === selectedDayIndex);
        return validDay ? localisedSelection.concat(validDay) : localisedSelection;
      },
      [],
    ).sort((dayA, dayB) => {
      if (dayA.index !== dayB.index) {
        return dayA.index < dayB.index
          ? -1
          : 1;
      }
      return 0;
    }).map((day) => day.name)
  ), [localisedDays]);

  const renderDays = useCallback((selection: unknown): string => {
    if (Array.isArray(selection)) {
      return selection.length === 5 && !selection.includes(DayIndex.SATURDAY) && !selection.includes(DayIndex.SUNDAY)
        ? intl.formatMessage({
          defaultMessage: 'Weekdays',
          description: 'Label for scheduling day select displayed when all weekdays are selected.'
        })
        : intl.formatList(getLocalisedWeekdaysFromIndices(selection as number[]), { type: 'conjunction' });
    } else if (typeof selection === 'number') {
      return localisedDays.find(localisedDay => localisedDay.index === +selection)?.name || '';
    }
    return '';
  }, [getLocalisedWeekdaysFromIndices, intl, localisedDays]);

  return (
    <StyledWeekdaySelect>
      <FormControl
        fullWidth
        variant="outlined"
        margin="dense"
      >
        <InputLabel
          shrink={false}
        >
          <FormattedMessage
            id="scheduling.recurring.selectDays"
            defaultMessage="Select days"
            description="placeholder value for daily select when no days are selected."
          />
        </InputLabel>
        <Select
          id="recurring-weekdays"
          value={ multiple ? dayIndices : dayIndices[0] }
          autoWidth={ true }
          fullWidth
          required
          onChange={ whenWeekdayChanged }
          multiple={ multiple }
          renderValue={ renderDays }
          MenuProps={ {
            anchorOrigin: {
              horizontal: 'left',
              vertical: 'bottom',
            },
          } }
          { ...props }
        >
          {
            localisedDays.map((localisedDay) => (
              <MenuItem key={ localisedDay.index } value={ localisedDay.index }>
                { multiple && <StyledCheckbox checked={ dayIndices.indexOf(localisedDay.index) !== -1 }/> }
                <ListItemText primary={ localisedDay.name }/>
              </MenuItem>
            ))
          }
        </Select>
      </FormControl>
    </StyledWeekdaySelect>
  )
};
