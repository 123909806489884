import styled from '@emotion/styled';

import { css } from '../../../../../Styling/css';

const baseIcon = css`
  display: inline-block;
  margin-right: 0.4rem;
  svg {
    width: 1.1rem;
    height: 1.1rem;
  }
`;

export const StyledCorrectIcon = styled.div`
  ${baseIcon}
  color: ${props => props.theme.palette.success.main};
`;

export const StyledIncorrectIcon = styled.div`
  ${baseIcon}
  color: ${props => props.theme.palette.error.main};
`;

export const StyledStarIcon = styled.div`
  display: inline-block;
  margin: 0 0 0 .2rem;
  svg {
    margin-top: -.2rem;
    width: 1rem;
    height: 1rem;
  }
`;
