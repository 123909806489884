import { FC, PropsWithChildren } from 'react';
import { FunctionInterpolation } from '@emotion/react';

import { AuthFormPane } from '../AuthFormPane/AuthFormPane';
import { AuthImagePane } from '../AuthImagePane/AuthImagePane';
import { defaultTheme } from '../../const/defaultTheme';

export const AuthPaneLayout: FC<PropsWithChildren<Record<string, unknown>>> = ({ children }) => (
  <div css={ authPaneLayoutCss }>
    <AuthFormPane>
      { children }
    </AuthFormPane>
    <AuthImagePane/>
  </div>
);

const authPaneLayoutCss: FunctionInterpolation<typeof defaultTheme> = theme => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  height: '100%',
  [theme.new.breakpoints.sm.down]: {
    gridTemplateColumns: '1fr 0',
  },
});
