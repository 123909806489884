import { IntlShape } from 'react-intl';

import { RoleId } from '../Model';

export class RoleLocaliser {
  public static localiseRoleId = (intl: IntlShape, roleId: RoleId): string => {
    switch (roleId) {
      case 'ROLE_FAKE_USER':
        return intl.formatMessage({
          id: 'roleId.ROLE_FAKE_USER',
          defaultMessage: 'Fake User',
        });
      case 'ROLE_USER':
        return intl.formatMessage({
          id: 'roleId.ROLE_USER',
          defaultMessage: 'App User',
        });
      case 'ROLE_APP_BROADCAST':
        return intl.formatMessage({
          id: 'roleId.ROLE_APP_BROADCAST',
          defaultMessage: 'Cover Sender',
        });
      case 'ROLE_APP_ADMIN':
        return intl.formatMessage({
          id: 'roleId.ROLE_APP_ADMIN',
          defaultMessage: 'Cover Assigner',
        });
      case 'ROLE_BROADCAST_ADMIN':
        return intl.formatMessage({
          id: 'roleId.ROLE_BROADCAST_ADMIN',
          defaultMessage: 'Broadcast Admin',
        });
      case 'ROLE_ADMIN':
        return intl.formatMessage({
          id: 'roleId.ROLE_ADMIN',
          defaultMessage: 'Team Admin',
        });
      case 'ROLE_SUPER_ADMIN':
        return intl.formatMessage({
          id: 'roleId.ROLE_SUPER_ADMIN',
          defaultMessage: 'Global Admin',
        });
      case 'ROLE_DEVELOPER':
        return intl.formatMessage({
          id: 'roleId.ROLE_DEVELOPER',
          defaultMessage: 'Developer (OP Staff)',
        });
      case 'ROLE_API':
        return intl.formatMessage({
          id: 'roleId.ROLE_API',
          defaultMessage: 'API',
        });
      case 'ROLE_SYSTEM':
        return intl.formatMessage({
          id: 'roleId.ROLE_SYSTEM',
          defaultMessage: 'System',
        });
    }

    return intl.formatMessage({
      id: 'roleId.unknown',
      defaultMessage: 'Unknown',
    });
  }
}

