import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import AlertIcon from '../../../Assets/img/icons/monochrome/alert.svg';
import { ValidationTree } from '../../../Common/Model';
import { DraftSingleContentCard, EditorContent } from '../../Model';
import { StyledContentNavButton, StyledContentNavItem } from './style';
import { useDescendantErrors } from '../../../Common/Hook';
import { useContentDefinitionRegistry } from '../../Hook';

type Props = {
  content: EditorContent;
  index: number;
  active: boolean;
  onContentAtIndexClicked: (index: number) => void;
  validation?: ValidationTree<DraftSingleContentCard>;
};

export const ContentNavItem: FC<Props> = ({
  content,
  index,
  active,
  onContentAtIndexClicked,
  validation,
}) => {
  const intl = useIntl();
  const errors = useDescendantErrors(validation);
  const { getIconComponent, getDraftContentTitle } = useContentDefinitionRegistry();
  const ContentIcon = getIconComponent(content.card.content.type);

  return (
    <StyledContentNavItem>
      <StyledContentNavButton
        error={ !!errors.length }
        active={ active }
        onClick={ () => onContentAtIndexClicked(index) }
        role="button"
      >
        <ContentIcon />
        { !!errors.length && <AlertIcon/> }
        <span>{ getDraftContentTitle(intl, content.card.content) }</span>
      </StyledContentNavButton>
    </StyledContentNavItem>
  );
};
