import { FC, ReactNode, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { parseISO } from 'date-fns';

import { Feedback, VerticallySpaced } from '../../../../../Common/Component';
import { EventInformation, EventStatus } from '..';
import { ScheduledDate } from '../../../../../Models/ScheduledDate';
import { BroadcastNudge, PersonalStatus } from '../../../../../Broadcasts/Model';
import { ScrollableContent } from '../../../../../Broadcasts/Component/Content';
import { NudgeBanner } from '../../NudgeBanner/NudgeBanner';

type Props = {
  startAt: ScheduledDate;
  endAt: ScheduledDate;
  location: string;
  title: string;
  personalStatus: PersonalStatus;
  type: string;
  showTimezone?: boolean;
  nudge?: BroadcastNudge;
  backButton: ReactNode | undefined;
};

export const EventStatusAndDetails: FC<Props> = ({
  startAt,
  endAt,
  location,
  title,
  personalStatus,
  showTimezone = false,
  type,
  children,
  nudge,
  backButton,
}) => {
  const intl = useIntl();
  const status = useMemo<Feedback>(() => {
    switch (personalStatus.status) {
      case 'assigned':
        return personalStatus.metadata.assignedToSelf
          ? {
            severity: 'success',
            message: intl.formatMessage({
              id: 'content.cover.assignedToUser',
              description: 'Cover status when assigned to user.',
              defaultMessage: 'Assigned to you',
            }),
          }
          : {
            severity: 'error',
            message: intl.formatMessage({
              id: 'content.cover.assignedToOther',
              description: 'Cover status when assigned to another user.',
              defaultMessage: 'Assigned to someone else',
            }),
          };
      case 'attending':
        return {
          severity: 'success',
          message: intl.formatMessage({
            id: 'content.meeting.attending',
            description: 'Meeting and training status when attending.',
            defaultMessage: 'Attending',
          }),
        };
      case 'full':
        return {
          severity: 'warning',
          message: intl.formatMessage({
            id: 'content.meeting.full',
            description: 'Meeting and training status when full.',
            defaultMessage: 'Join waiting list',
          }),
        };
      case 'waiting':
        return {
          severity: 'warning',
          message: intl.formatMessage({
            id: 'content.meeting.waiting',
            description: 'Meeting status when user is on the waiting list.',
            defaultMessage: 'On waiting list',
          }),
        };
      case 'cancelled':
        return {
          severity: 'none',
          message: intl.formatMessage({
            id: 'content.event.cancelled',
            description: 'Event status when cancelled.',
            defaultMessage: 'Cancelled',
          }),
        };
      case 'expired':
        return {
          severity: 'none',
          message: intl.formatMessage({
            id: 'content.event.expired',
            description: 'Event status when expired.',
            defaultMessage: 'Expired',
          }),
        };
      case 'requested':
        return {
          severity: 'warning',
          message: intl.formatMessage({
            id: 'content.cover.requested',
            description: 'Cover status when requested.',
            defaultMessage: 'Requested',
          }),
        };
      case 'declined':
        return {
          severity: 'error',
          message: intl.formatMessage({
            id: 'content.event.declined',
            description: 'Event status when declined.',
            defaultMessage: 'Declined',
          }),
        };
      case 'attended':
        return {
          severity: 'success',
          message: intl.formatMessage({
            id: 'content.meeting.attended',
            description: 'Meeting status when attended.',
            defaultMessage: 'Attended',
          })
        }
      case 'passed':
        return {
          severity: 'success',
          message: intl.formatMessage({
            id: 'content.training.passed',
            description: 'Training status when passed.',
            defaultMessage: 'Passed',
          })
        }
      case 'failed':
        return {
          severity: 'error',
          message: intl.formatMessage({
            id: 'content.training.failed',
            description: 'Training status when failed.',
            defaultMessage: 'Failed',
          })
        }
      default:
        return {
          severity: 'success',
          message: intl.formatMessage({
            id: 'content.event.available',
            description: 'Event status when available.',
            defaultMessage: 'Available',
          }),
        };
    }
  }, [intl, personalStatus.metadata.assignedToSelf, personalStatus.status]);

  return (
    <ScrollableContent>
      {
        nudge && (
          <NudgeBanner
            message={ nudge.message }
            nudgedAt={ parseISO(nudge.at) }
          />
        )
      }
      <VerticallySpaced gap={ 6 }>
        <EventStatus
          title={ title }
          status={ status }
          type={ type }
          backButton={ backButton }
        />
        { children }
        <EventInformation
          startAt={ startAt }
          endAt={ endAt }
          location={ location }
          showTimezone={ showTimezone }
        />
      </VerticallySpaced>
    </ScrollableContent>
  );
}
