import styled from '@emotion/styled';

import CheckIcon from '../../../Assets/img/icons/circle/success.svg';
import CrossIcon from '../../../Assets/img/icons/circle/cross.svg';

export const StyledCheckIcon = styled(CheckIcon)`
  color: ${props => props.theme.palette.success.main};
`;

export const StyledCrossIcon = styled(CrossIcon)`
  color: ${props => props.theme.palette.error.main};
`;
