import { ValidationTree, Validator } from '../../../../Common/Model';
import { RichTextSanitiser, Validate } from '../../../../Common/Utility';
import {
  DraftImageContent,
  IMAGE_CAPTION_MAX_LENGTH,
  IMAGE_CAPTION_MIN_LENGTH,
  IMAGE_TEXT_MAX_LENGTH,
  IMAGE_TEXT_MIN_LENGTH
} from '../../ContentDefinition';
import { LinkValidator } from '../LinkValidator/LinkValidator';

export class ImageContentValidator {
  public static validate = (content: DraftImageContent): ValidationTree<DraftImageContent> => ({
    errors: [],
    children: {
      ...(
        content.caption !== null
          ? {
            caption: {
              errors: [],
              children: {
                text: ImageContentValidator.validateCaptionText(content.caption.text),
                backgroundColour: ImageContentValidator.validateColour(content.caption.backgroundColour),
                textColour: ImageContentValidator.validateColour(content.caption.textColour),
              },
            }
          }
          : {}
      ),
      ...(
        content.imageHeight !== 'full'
          ? {
            text: ImageContentValidator.validateText(content.text),
          }
          : {}
      ),
      imageId: ImageContentValidator.validateImageId(content.imageId),
      sourceImageId: ImageContentValidator.validateImageId(content.sourceImageId),
      backgroundColour: ImageContentValidator.validateColour(content.backgroundColour),
      ...LinkValidator.validate(content),
    },
  });

  public static validateCaptionText = (captionText: string): ValidationTree<string> => ({
    errors: captionTextValidator.validate(captionText),
    children: {},
  });

  public static validateText = (text: string | null): ValidationTree<string> => ({
    errors: textValidator.validate(text),
    children: {},
  });

  public static validateColour = (colour: string): ValidationTree<string> => ({
    errors: hexColourValidator.validate(colour),
    children: {},
  });

  public static validateImageId = (imageId: string): ValidationTree<string> => ({
    errors: imageIdValidator.validate(imageId),
    children: {},
  });
}

const imageIdValidator = new Validator<string>([
  Validate.notBlank,
]);

const hexColourValidator = new Validator<string>([
  Validate.notBlank,
  Validate.hexColour,
]);

const captionTextValidator = new Validator<string>([
  Validate.notBlank,
  captionText => Validate.minLength(captionText, IMAGE_CAPTION_MIN_LENGTH),
  captionText => Validate.maxLength(captionText, IMAGE_CAPTION_MAX_LENGTH),
]);

const textValidator = new Validator<string>([
  Validate.notBlank,
  Validate.utf8,
  text => Validate.minLength(RichTextSanitiser.stripTags(text), IMAGE_TEXT_MIN_LENGTH),
  text => Validate.maxLength(RichTextSanitiser.stripTags(text), IMAGE_TEXT_MAX_LENGTH),
]);
