import { FunctionComponent, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { TagType } from '../../../../Tags/Model';
import { TagAutocompleteWithLabel } from '..';
import { useLoggedInAuthDescription } from '../../../../Core/Hook';

type Props = {
  action: 'add' | 'replace';
  tagType: TagType;
  tagIds: string[];
  onTagIdsChanged: (tagIds: string[]) => void;
  disabled: boolean;
};

export const AddProfileTagInput: FunctionComponent<Props> = ({
  action,
  tagType,
  ...props
}) => {
  const intl = useIntl();
  const { administratedTags } = useLoggedInAuthDescription();
  const localisedAction = useMemo(
    () => (
      action === 'replace'
        ? intl.formatMessage({
          id: 'people.addProfileTagInput.replace',
          description: 'Label for tag to add input when performing replace tag action.',
          defaultMessage: 'With',
        })
        : intl.formatMessage({
          id: 'people.addProfileTagInput.add',
          description: 'Label for tag to add input when performing add tag action.',
          defaultMessage: 'Add',
        })
    ),
    [action, intl],
  );

  return (
    <TagAutocompleteWithLabel
      label={ localisedAction }
      { ...tagType === 'team' ? { allowedIds: administratedTags } : {} }
      tagType={ tagType }
      { ...props }
    />
  );
};
