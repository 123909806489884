import { useMemo } from 'react';

import { FetchResponse, useGet } from '../../Hooks';
import { Paginated } from '../../Models';
import { ValidationTree } from '../../Common/Model';

export type ImportRow = {
  id: string;
  importId: string;
  index: number;
  values: string[];
  errors: ValidationTree<string[]> | null;
  ignored: boolean;
}

export type FetchImportRowsParams = {
  pageNum: number;
  search?: string;
  noWithErrors?: 0 | 1;
  noWithoutErrors?: 0 | 1;
  noBlank?: 0 | 1;
  noHeaders?: 0 | 1;
  noIgnored?: 0 | 1;
}

export const useFetchImportRows = (
  importId: string,
  params: FetchImportRowsParams,
): FetchResponse<Paginated<'rows', ImportRow>> => {
  const completeParams = useMemo(() => ({
    ...params,
    noHeaders: 1,
    noBlank: 1,
  }), [params])
  return useGet(`/imports/${importId}/rows`, completeParams);
}
