import { FC, MouseEventHandler, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  ButtonRow,
  Description,
  NextButton,
  PreviousButton,
  StartButton,
  Progress,
  ScrollArea,
  StyledFormContentPreview,
  StyledFormHeader,
  Title,
  StyledPager,
  AnonymousMessageHint,
} from './style';
import { HeaderImage, Pager } from '../../../Content/Component';
import ChevronIcon from '../../../Assets/img/icons/monochrome/chevron.svg';
import LockIcon from '../../../Assets/img/icons/streamline/shield-lock.svg';
import { EditorContent } from '../../../Content/Model';
import { useContentDefinitionRegistry } from '../../../Content/Hook';
import { DraftFormRequest } from '../../Model';

type Props = {
  name: string;
  content: EditorContent;
  currentContentIndex: number;
  contentCount: number;
  onPreviousClicked: MouseEventHandler;
  onNextClicked: MouseEventHandler;
  previousButtonDisabled?: boolean;
  nextButtonDisabled?: boolean;
  startButtonHidden?: boolean;
  anonymousSubmissionsEnabled?: boolean;
};

export const FormContentPreview: FC<Props> = ({
  name,
  content,
  currentContentIndex,
  contentCount,
  onPreviousClicked,
  onNextClicked,
  previousButtonDisabled,
  nextButtonDisabled,
  startButtonHidden,
  anonymousSubmissionsEnabled,
}) => {
  const intl = useIntl();
  const { getDraftContentTitle, getPreviewComponent } = useContentDefinitionRegistry();
  const PreviewComponent = getPreviewComponent(content);
  const description = useMemo<string | null>(() => {
    return (content.card.content as DraftFormRequest).text;
  }, [content.card.content]);

  return (
    <StyledFormContentPreview>
      {
        content && currentContentIndex !== undefined && (
          <>
            <StyledFormHeader>
              <h3>{ name }</h3>
              {
                anonymousSubmissionsEnabled &&
                (
                  <AnonymousMessageHint>
                    <LockIcon/>
                    <div>
                      <div>
                        <FormattedMessage
                          id="content.preview.anonymous.message"
                          description="Anonymous responses reminder"
                          defaultMessage="Responses are anonymous"
                        />
                      </div>
                      <div>
                        <FormattedMessage
                          id="content.preview.anonymous.message.info"
                          description="Anonymous responses reminder"
                          defaultMessage="Do not provide personal information"
                        />
                      </div>
                    </div>
                  </AnonymousMessageHint>
                )
              }
            </StyledFormHeader>
            <ScrollArea>
              <div>
                {
                  content.card.image?.uploadId && (
                    <HeaderImage
                      uploadId={ content.card.image.uploadId }
                      fit={ content.card.image.fit }
                    />
                  )
                }
                <Progress
                  hidden={ currentContentIndex < 1 }
                >
                  { currentContentIndex } of { contentCount - 1 }
                </Progress>
                <Title>{ getDraftContentTitle(intl, content.card.content) }</Title>
                { description && <Description>{ description }</Description> }
                <PreviewComponent
                  editorContent={ content }
                  onReady={ () => null }
                />
              </div>
            </ScrollArea>
            <div>
              <ButtonRow>
                <div>
                  <PreviousButton
                    onClick={ onPreviousClicked }
                    color="primary"
                    disabled={ previousButtonDisabled }
                  >
                    <ChevronIcon/>
                    <FormattedMessage
                      id="content.preview.previous"
                      description="Previous card button label"
                      defaultMessage="Previous"
                    />
                  </PreviousButton>
                </div>

                <div>
                  <StartButton
                    hidden={ startButtonHidden }
                    onClick={ onNextClicked }
                    color="primary"
                    variant={'contained'}
                  >
                    <FormattedMessage
                      id="content.preview.start"
                      description="Start button"
                      defaultMessage="Start"
                    />
                  </StartButton>
                </div>

                <div>
                  <NextButton
                    onClick={ onNextClicked }
                    color="primary"
                    disabled={ nextButtonDisabled }
                  >
                    <FormattedMessage
                      id="content.preview.next"
                      description="Next card button label"
                      defaultMessage="Next"
                    /><ChevronIcon/>
                  </NextButton>
                </div>
              </ButtonRow>
              <StyledPager>
                <Pager progress={ currentContentIndex / contentCount }/>
              </StyledPager>
            </div>
          </>
        )
      }
    </StyledFormContentPreview>
  );
};
