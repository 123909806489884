import {
  CSSProperties,
  FC,
  MouseEventHandler,
  RefAttributes,
  RefCallback,
  useCallback,
  useMemo,
  useRef,
  useState
} from 'react';

type Props = {
  ToggleComponent: FC<{
    popovertarget: string;
    onClick: MouseEventHandler
  } & RefAttributes<HTMLButtonElement>>;
  ContentComponent: FC<{
    id: string;
    popover: 'auto' | 'manual';
    className?: string;
    style?: CSSProperties;
  } & RefAttributes<HTMLElement>>;
};

export const ToggledPopover: FC<Props> = ({
  ToggleComponent,
  ContentComponent,
}) => {
  const removeEventCallback = useRef<() => void>();
  const [positionInitialised, setPositionInitialised] = useState<boolean>(false);
  const [contentHeight, setContentHeight] = useState<number>(0);
  const [position, setPosition] = useState<'above' | 'below'>('below');
  const [toggleRectangle, setToggleRectangle] = useState<Omit<DOMRect, 'toJSON'>>({
    top: 0,
    left: 0,
    height: 0,
    width: 0,
    x: 0,
    y: 0,
    bottom: 0,
    right: 0,
  });
  const toggleRef = useRef<HTMLButtonElement>(null);
  const popoverRef = useRef<HTMLElement>();
  const id = useMemo(() => String(Math.random()), []);

  const onToggleClicked = useCallback(() => {
    const clientRectangle = toggleRef.current?.getBoundingClientRect();
    clientRectangle && setToggleRectangle(clientRectangle);
  }, []);

  const contentRef: RefCallback<HTMLElement> = useCallback(node => {
    if (!node) {
      return;
    }

    popoverRef.current = node;
    const onToggle = (event: Event) => {
      const toggleEvent = event as ToggleEvent;

      if (toggleEvent.newState === toggleEvent.oldState) {
        return;
      }

      if (toggleEvent.newState === 'closed') {
        setPositionInitialised(false);
        setPosition('below');
        return;
      }

      const eventTarget = (event.target as HTMLElement);
      const targetRect = eventTarget.getBoundingClientRect();
      setContentHeight(targetRect.height);
      if (targetRect.bottom > document.documentElement.clientHeight) {
        setPosition('above');
      }

      setPositionInitialised(true);
    };

    removeEventCallback.current && removeEventCallback.current();
    node.addEventListener('toggle', onToggle);
    removeEventCallback.current = () => node?.removeEventListener('toggle', onToggle);
  }, [removeEventCallback]);

  return (
    <>
      <ToggleComponent
        ref={ toggleRef }
        onClick={ onToggleClicked }
        popovertarget={ id }
      />
      <ContentComponent
        id={ id }
        ref={ contentRef }
        popover="auto"
        style={ {
          '--toggle-left': `${ toggleRectangle.left }px`,
          '--toggle-top': `${ toggleRectangle.top }px`,
          '--toggle-right': `${ toggleRectangle.right }px`,
          '--toggle-bottom': `${ toggleRectangle.bottom }px`,
          '--toggle-width': `${ toggleRectangle.width }px`,
          '--toggle-height': `${ toggleRectangle.height }px`,
          '--available-menu-height': `${ position === 'below' ? `${ window.innerHeight - toggleRectangle.bottom - (2 * MENU_OFFSET) }px` : `${ toggleRectangle.top - (2 * MENU_OFFSET) }px` }`,
        } }
        css={ {
          border: 'none',
          padding: 0,
          opacity: positionInitialised ? 1 : 0,
          margin: `calc(${ position === 'below' ? 'var(--toggle-bottom) +' : `-${ contentHeight }px + var(--toggle-top) -` } ${ MENU_OFFSET }px) auto auto var(--toggle-left)`,
          minWidth: 'max-content',
          width: 'var(--toggle-width)',
          maxHeight: `min(var(--available-menu-height), ${ MAX_MENU_HEIGHT })`,
        } }
      />
    </>
  );
};

const MENU_OFFSET = 8;
const MAX_MENU_HEIGHT = '400px';
