import { FC, useMemo } from 'react';
import FileIcon from 'op-storybook/lib/assets/icon/figma/file-06.svg';
import FolderIcon from 'op-storybook/lib/assets/icon/figma/folder.svg';
import { FeaturedIcon } from 'op-storybook/stories/components/FeaturedIcon/FeaturedIcon';
import { SecondaryInformation } from 'op-storybook/stories/components/Typography/SecondaryInformation';
import { PrimaryInformation } from 'op-storybook/stories/components/Typography/PrimaryInformation';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { FormattedMessage } from 'react-intl';

import { StorageNodeBlock as StorageNodeBlockModel } from '../../../Service/Blocks/StorageNodeBlockDefinition';
import { BroadcastAttachments } from '../../../Model/BroadcastAttachments';
import { FileNode, NodeAndAncestors } from '../../../../../Files/Model';

type Props = {
  block: StorageNodeBlockModel;
  attachments: BroadcastAttachments;
} & (
  {
    functionalLink: true;
    onClick?: never;
  } |
  {
    functionalLink?: false | never;
    onClick: () => void;
  }
  )

export const StorageNodeBlock: FC<Props> = ({
  block,
  attachments,
  functionalLink = false,
  onClick,
}) => {
  const nodeAndAncestors = useMemo<NodeAndAncestors | null>(() => {
    if (!block.attributes.nodeId) {
      return null;
    }

    return attachments.storageNodes.find(nodeAndAncestors => nodeAndAncestors.node.id === block.attributes.nodeId) || null;
  }, [attachments.storageNodes, block.attributes.nodeId]);

  return (
    <a
      onClick={ onClick }
      {
        ...nodeAndAncestors && functionalLink
          ? {
            href: nodeAndAncestors.node.type === 'folder'
              ? `/me/files/${ nodeAndAncestors.node.id }`
              : `/api/uploads/${ (nodeAndAncestors.node as FileNode).upload.id }/download`
            ,
            target: '_blank',
            rel: 'noreferrer',
          }
          : {}
      }
      css={ theme => ({
        cursor: 'pointer',
        userSelect: 'none',
        width: '100%',
        display: 'flex',
        boxShadow: theme.new.shadow.xs,
        borderRadius: theme.new.borderRadius.standard,
        background: theme.new.basePalette.white.main,
        textAlign: 'left',
        margin: 0,
        padding: 0,
        border: theme.new.border.standard,
        position: 'relative',
      }) }
    >
      <div
        css={ {
          width: '64px',
          height: '64px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        } }
      >
        <FeaturedIcon
          IconComponent={
            nodeAndAncestors?.node.type === 'folder'
              ? FolderIcon
              : FileIcon
          }
          size="lg"
          variant="light-circle-outline"
          colour="primary"
        />
      </div>
      <Stack
        direction="column"
        gap={ 1 }
        justify="center"
        css={ theme => ({
          padding: `${ theme.new.spacing[2] } ${ theme.new.spacing[2] } ${ theme.new.spacing[2] } 0`,
        }) }
      >
        <PrimaryInformation>{ nodeAndAncestors?.node.name }</PrimaryInformation>
        <SecondaryInformation>
          {
            nodeAndAncestors?.node.type === 'folder'
              ? (
                <FormattedMessage
                  description="Supporting text in folder link button"
                  defaultMessage="{ touchDevice, select, true {Tap} other {Click} } to view folder"
                  values={ {
                    touchDevice: window.matchMedia('(hover: none)').matches,
                  } }
                />
              )
              : (
                <FormattedMessage
                  description="Supporting text in file download button"
                  defaultMessage="{ touchDevice, select, true {Tap} other {Click} } to download file"
                  values={ {
                    touchDevice: window.matchMedia('(hover: none)').matches,
                  } }
                />
              )
          }
        </SecondaryInformation>
      </Stack>
    </a>
  );
};
