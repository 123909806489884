import React, { FC, FormEventHandler, useCallback, useMemo, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl'
import { TransitionProps } from '@mui/material/transitions';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';

import { Flex, FlexPullRight, IconButton, VerticallySpaced } from '../../../../Common/Component';
import CloseIcon from '../../../../Assets/img/icons/streamline/close.svg';
import { CategoryAutocomplete } from '..';
import { BroadcastCategory } from '../../../Model';
import { StyledInputContainer } from './style';
import { TextMatcher } from '../../../../Utility';

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit: (newCategoryId: string) => void;
  category: BroadcastCategory;
  TransitionProps: TransitionProps;
};

export const CategoryDeleteDialog: FC<Props> = ({
  open,
  onClose,
  onSubmit,
  category,
  TransitionProps,
}) => {
  const intl = useIntl();
  const [selectedNewCategoryIds, setSelectedNewCategoryIds] = useState<string[]>([]);
  const [deletePromptValue, setDeletePromptValue] = useState<string>('');

  const whenDeleteConfirmed: FormEventHandler = useCallback(event => {
    event.preventDefault();
    onSubmit(selectedNewCategoryIds[0]);
  }, [onSubmit, selectedNewCategoryIds]);

  const cancelLabel = useMemo(() => (
    intl.formatMessage({
      id: 'categoriesTableRow.delete.cancel',
      description: 'Label for cancel delete button in broadcast categories table.',
      defaultMessage: 'Cancel',
    })
  ), [intl]);

  const deletePrompt = useMemo(() => (
    intl.formatMessage({
      id: 'categoriesTableRow.delete.prompt',
      description: 'Text that user must type in order to delete a broadcast category.',
      defaultMessage: 'permanently delete',
    })
  ), [intl]);

  return (
    <Dialog
      open={ open }
      onClose={ onClose }
      TransitionProps={ TransitionProps }
    >
      <DialogTitle>
        <Flex
          noWrap
          align="flex-start"
        >
          <span>
            <FormattedMessage
              id="categoriesTableRow.delete.heading"
              description="Heading in delete confirmation dialog of broadcast categories page."
              defaultMessage="Are you sure you want to permanently delete the { categoryName } category?"
              values={ { categoryName: category.name } }
            />
          </span>
          <FlexPullRight>
            <IconButton
              IconComponent={ CloseIcon }
              onClick={ onClose }
              label={ cancelLabel }
              size="small"
            />
          </FlexPullRight>
        </Flex>
      </DialogTitle>
      <DialogContent>
        <form
          id="deleteForm"
          onSubmit={ whenDeleteConfirmed }
        >
          <VerticallySpaced gap={ 4 }>
            <VerticallySpaced gap={ 1 }>
              <span id="transferCategory">
                <FormattedMessage
                  id="categoryDeleteDialog.transfer.description"
                  description="Description for input for choosing category to transfer broadcasts to in category delete dialog."
                  defaultMessage="
                Please <strong>select a category to transfer all linked broadcasts to</strong>. This will prevent missing
                data from reporting. Alternatively you can unpublish the category.
              "
                />
              </span>
              <StyledInputContainer>
                <CategoryAutocomplete
                  fullWidth
                  aria-describedby="transferCategory"
                  selectedIds={ selectedNewCategoryIds }
                  onSelectionChanged={ selection => setSelectedNewCategoryIds(selection.options.map(option => option.id)) }
                  excludeIds={ [category.id] }
                  disableClearable
                />
              </StyledInputContainer>
            </VerticallySpaced>
            <VerticallySpaced gap={ 1 }>
              <span id="deletePrompt">
                <FormattedMessage
                  id="categoryDeleteDialog.transfer.description"
                  description="Description for input for choosing category to transfer broadcasts to in category delete dialog."
                  defaultMessage="To delete the <strong>{ categoryName }</strong> category, please type <strong>{ deletePrompt }</strong> below."
                  values={ {
                    categoryName: category.name,
                    deletePrompt,
                  } }
                />
              </span>
              <StyledInputContainer>
                <TextField
                  aria-describedby="deletePrompt"
                  value={ deletePromptValue }
                  onChange={ event => setDeletePromptValue(event.currentTarget.value) }
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
              </StyledInputContainer>
            </VerticallySpaced>
          </VerticallySpaced>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={ onClose }
        >
          { cancelLabel }
        </Button>
        <Button
          variant="primary"
          type="submit"
          disabled={ !TextMatcher.caseInsensitive(deletePromptValue, deletePrompt) || !selectedNewCategoryIds.length }
          form="deleteForm"
        >
          <FormattedMessage
            id="categoriesTableRow.delete.confirm"
            description="Label for button to confirm deleting a category."
            defaultMessage="Delete"
          />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
