import { useEffect } from 'react';

import { SubmittedComponent } from '../../../../Content/Model';
import { NumberRequest as NumberRequestModel } from '../../../Model/ContentTypes';
import { NumberResponse } from '../../../Service';
import { NoResponseMessage } from '../NoResponseMessage/NoResponseMessage';
import {SubmittedText} from '../SubmittedText/SubmittedText';

export const SubmittedNumberRequest: SubmittedComponent<NumberResponse, NumberRequestModel> = ({
  response,
  onFinish,
}) => {
  useEffect(() => {
    if (!onFinish) {
      return;
    }

    onFinish();
  }, [onFinish]);

  return (
    response
      ? <SubmittedText text={ response.number.toString() }/>
      : <NoResponseMessage/>
  );
};
