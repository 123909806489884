import { FunctionComponent, ReactNode } from 'react';

import { FeedbackSeverity } from '..';
import {
  StyledHighlightedText,
  StyledHighlightedTextIcon,
  StyledHighlightedTextValue
} from './style';

type Props = {
  color?: FeedbackSeverity;
  icon?: ReactNode;
  iconPosition?: 'start' | 'end';
  onClick?: () => void;
  className?: string;
}

export const HighlightedText: FunctionComponent<Props> = ({
  color = 'success',
  icon,
  iconPosition = 'start',
  onClick,
  className,
  children,
}) => {
  const wrappedIcon = (
    <StyledHighlightedTextIcon>
      { icon }
    </StyledHighlightedTextIcon>
  );

  return (
  <StyledHighlightedText
    clickable={ !!onClick }
    color={ color }
    onClick={ onClick }
    className={ className }
    role={ onClick ? 'button' : undefined }
  >
    { icon && iconPosition === 'start' && wrappedIcon }
    <StyledHighlightedTextValue>{ children }</StyledHighlightedTextValue>
    { icon && iconPosition === 'end' && wrappedIcon }
  </StyledHighlightedText>
  )
};
