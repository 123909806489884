import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';

import { CsvExportButtonWithDefaultErrorHandling } from '../../../Common/Component';
import { PeopleTable } from '../PeopleTable/PeopleTable';
import { PeopleTableFilterSelection } from '../PeopleTableFilters/PeopleTableFilters';
import { useQueryAsState } from '../../../Hooks';
import { useFetchLicenceCounts } from '../../Hook';
import { PeoplePageAlerts } from '../PeoplePageAlerts/PeoplePageAlerts';
import { Notices } from '../../../Common/Model';
import { QueryParser } from '../../../Common/Utility';
import { PersonStatusHelper } from '../../Utility';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { ImportsPermission } from '../../../Imports/Model';
import { PeoplePermission } from '../../Model';
import { Button } from '@mui/material';
import { GuardedLink } from '../../../Security/Component';
import { FormattedMessage, useIntl } from 'react-intl';
import { StyledIconButton } from '../../Routing/PeoplePage/styles';
import AddPersonIcon from '../../../Assets/img/icons/streamline/single-neutral-actions-add.svg';
import { Link, useHistory } from 'react-router-dom';
import PersonImportedIcon from '../../../Assets/img/icons/streamline/single-neutral-actions-download.svg';
import { StackEnd } from 'op-storybook/lib/components/StackEnd/StackEnd';
import { usePermissions } from '../../../Security/Hook';
import { useEnvironmentSettings } from '../../../Common/Hook';
import { CenteredNoticeDialog } from '../../../Components';

type Props = {
  notices?: Notices;
};

export const PeopleList: FunctionComponent<Props> = ({ notices }) => {
  const [query, setQuery] = useQueryAsState();
  const [pageNum, setPageNum] = useState<number>(QueryParser.pageNum(query));
  const [initialFiltersApplied, setInitialFiltersApplied] = useState<boolean>(false);
  const [filterSelection, setFilterSelection] = useState<PeopleTableFilterSelection>({
    search: query.search || '',
    teamIds: (query.teams || '').split(',').filter((value) => value),
    roleIds: (query.roles || '').split(',').filter((value) => value),
    statusIds: statusIdsParser(query),
    profiles: query.profiles || '',
  });
  const { permissionAvailable, permissionsAvailable } = usePermissions();
  const { allowUserSyncDecisions } = useEnvironmentSettings();
  const [licenceCounts] = useFetchLicenceCounts();
  const history = useHistory();
  const [userLimitDialogOpen, setUserLimitDialogOpen] = useState<boolean>(false);
  const intl = useIntl();

  useEffect(
    () => {
      setQuery({
        ...(pageNum > 1 ? { pageNum: pageNum.toString() } : {}),
        ...(filterSelection.search ? { search: filterSelection.search } : {}),
        ...(filterSelection.profiles ? { profiles: filterSelection.profiles } : {}),
        ...(filterSelection.teamIds.length ? { teams: filterSelection.teamIds.join(',') } : {}),
        ...(filterSelection.roleIds.length ? { roles: filterSelection.roleIds.join(',') } : {}),
        ...(filterSelection.statusIds.length ? { statuses: filterSelection.statusIds.join(',') } : {}),
      });
    },
    [setQuery, pageNum, filterSelection],
  );

  useEffect(() => {
    if (initialFiltersApplied) {
      setPageNum(1);
    }

    setInitialFiltersApplied(true);
  }, [initialFiltersApplied]);

  const whenInvitePeopleClicked = (): void => {
    if (licenceCounts?.content && licenceCounts.content.limit && !licenceCounts.content.available) {
      setUserLimitDialogOpen(true);
    } else {
      history.push('/people/create');
    }
  };

  const whenUserLimitDialogDismissed = (): void => {
    setUserLimitDialogOpen(false);
  };

  return (
    <Stack direction="column">
      <PeoplePageAlerts
        notices={ notices }
        licenceCounts={ licenceCounts?.content }
      />
      <Stack>
        <StackEnd>
          <Stack>
            { permissionsAvailable([ImportsPermission.ALL, PeoplePermission.IMPORT]) && (
              <Button
                color="primary"
                component={ GuardedLink }
                to="/people/import"
                permissions={ [ImportsPermission.ALL, PeoplePermission.IMPORT] }
              >
                <FormattedMessage
                  id="people.import.buttonLabel"
                  description="Label for import button"
                  defaultMessage="Import people"
                />
              </Button>
            ) }
            <StyledIconButton
              color="primary"
              variant={ allowUserSyncDecisions ? 'text' : 'contained' }
              disableElevation
              startIcon={ <AddPersonIcon/> }
              onClick={ whenInvitePeopleClicked }
            >
              <FormattedMessage
                id="people.addCta"
                description="CTA for add people button"
                defaultMessage="Add people"
              />
            </StyledIconButton>
            {
              !!allowUserSyncDecisions && (
                <Link to="/people/third-party-import">
                  <StyledIconButton
                    color="primary"
                    variant="contained"
                    startIcon={ <PersonImportedIcon/> }
                    disableElevation
                  >
                    <FormattedMessage
                      id="people.manageCta"
                      description="CTA for manage import button"
                      defaultMessage="Manage third party import"
                    />
                  </StyledIconButton>
                </Link>
              )
            }
          </Stack>
        </StackEnd>
      </Stack>
      <div>
        <PeopleTable
          pageNum={ pageNum }
          onPageNumChanged={ setPageNum }
          filterSelection={ filterSelection }
          setFilterSelection={ setFilterSelection }
        />
      </div>
      { permissionAvailable(PeoplePermission.REPORT) && (
        <CsvExportButtonWithDefaultErrorHandling
          endpoint="/report/user_export"
          cta={ intl.formatMessage({
            id: 'people.export.cta',
            description: 'CTA for CSV download button on people page',
            defaultMessage: 'Export all people',
          }) }
          fileNamePrefix={ intl.formatMessage({
            id: 'people.export.fileNamePrefix',
            defaultMessage: 'people_export',
            description: 'People export file name prefix',
          }) }
          permissions={ [PeoplePermission.REPORT] }
        />
      ) }
      <CenteredNoticeDialog
        dismissCta={
          intl.formatMessage({
            id: 'people.limitDialog.cta',
            description: 'CTA for notice when trying to invite users when at user limit',
            defaultMessage: 'OK'
          })
        }
        onDismiss={ whenUserLimitDialogDismissed }
        open={ userLimitDialogOpen }
        heading={
          intl.formatMessage({
            id: 'people.limitDialog.title',
            description: 'Title for notice when trying to invite users when at user limit',
            defaultMessage: 'Sorry you don\'t have any invites left'
          })
        }
        content={
          <FormattedMessage
            id="people.limitDialog.content"
            description="Content for notice when trying to invite users when at user limit"
            defaultMessage="Contact our <a>support team</a> to change your people limit."
            values={ {
              a: (chunks: (string | ReactNode)[]) => (
                <a href="mailto:support@ourpeople.com">
                  { chunks }
                </a>
              ),
            } }
          />
        }
      />
    </Stack>
  );
};

const statusIdsParser = QueryParser.getCsvParseFn('statuses', PersonStatusHelper.removeInvalid);
