import styled from '@emotion/styled';

export const StyledIconContainer = styled.div`
  position: relative;
`;

export const StyledLockIconContainer = styled.div`
  position: absolute;
  top: ${ props => props.theme.spacing(-0.5) };
  right: ${ props => props.theme.spacing(-0.5) };
  width: ${ props => props.theme.spacing(2) };
  height: calc(${ props => props.theme.spacing(2) } + 2px );
  border-top-left-radius: ${ props => props.theme.borderRadius.lg };
  border-top-right-radius: ${ props => props.theme.borderRadius.lg };
  border-bottom-left-radius: ${ props => props.theme.borderRadius.sm };
  border-bottom-right-radius: ${ props => props.theme.borderRadius.sm };
  background-color: ${ props => props.theme.palette.background.paper };

  svg {
    width: ${ props => props.theme.spacing(2) };
    height: ${ props => props.theme.spacing(2) };
  }
`;
