import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { ContextMenuAction } from '../../../Common/Component/ContextMenu/ContextMenu';
import { BroadcastsPermission } from '../../Model';
import { GuardedLink } from '../../../Security/Component';
import ReportIcon from '../../../Assets/img/icons/streamline/graph-stats-ascend.svg';

export const useViewReportBroadcastAction = (
  broadcastId: string,
  delivered: boolean,
  deliveryId?: string,
): ContextMenuAction => {
  const intl = useIntl();

  return useMemo(() => ({
    id: 'report',
    component: GuardedLink,
    permissions: [BroadcastsPermission.REPORT],
    to: `/broadcasts/${ broadcastId }/report${ deliveryId ? `?delivery=${ deliveryId }` : '' }`,
    IconComponent: ReportIcon,
    label: intl.formatMessage({
      description: 'Label for view report action in recent deliveries action menu.',
      defaultMessage: 'View report',
    }),
    ...(delivered ? {} : {
        disabled: true,
        disabledTooltip: intl.formatMessage({
          description: 'Label for view report action in broadcast tables when report isn\'t available',
          defaultMessage: 'Reporting is not available for undelivered broadcasts.',
        })
      })
  }), [broadcastId, delivered, deliveryId, intl]);
};
