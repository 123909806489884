import { FunctionComponent, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { LoadingCard } from '@ourpeople/shared/Core/Component/Feedback';

import { useFetchCreditSummary, CreditSummary as CreditSummaryType } from '../../Hook';
import { AnimatedNumber } from '../../../Common/Component';
import { RequestState } from '../../../Models';
import { SummaryNumberWithLabel, StyledCreditSummary, LoadingContainer } from './style';

type Props = {
  transactionSavedAt: number;
}

export const CreditSummary: FunctionComponent<Props> = ({ transactionSavedAt }) => {
  const [fetchSummaryResult, fetchSummaryRequestState, refreshSummary] = useFetchCreditSummary();
  const creditSummary = fetchSummaryResult?.content?.summary;
  const [lastRefreshedAt, setLastRefreshedAt] = useState<number>(0);
  const [previousSummary, setPreviousSummary] = useState<CreditSummaryType>({
    spent: 0,
    reserved: 0,
    available: 0,
    refunded: 0,
  });

  useEffect(() => {
    if (transactionSavedAt > lastRefreshedAt) {
      if (creditSummary) {
        setPreviousSummary(creditSummary);
      }

      refreshSummary();
      setLastRefreshedAt(transactionSavedAt);
    }
  }, [creditSummary, lastRefreshedAt, refreshSummary, transactionSavedAt]);

  return (
    <div>
      {
        fetchSummaryRequestState < RequestState.COMPLETE
          ? (
            <LoadingContainer><LoadingCard/></LoadingContainer>
          )
          : (
            creditSummary
              ? (
                <StyledCreditSummary>
                  <SummaryNumberWithLabel htmlFor="credit-summary-available">
                    <FormattedMessage
                      id="credit.summary.available"
                      description="Label for credit summary available figure."
                      defaultMessage="Available"
                    />
                    <AnimatedNumber
                      id="credit-summary-available"
                      targetValue={ creditSummary.available }
                      initialValue={ previousSummary.available }
                    />
                  </SummaryNumberWithLabel>
                  <SummaryNumberWithLabel htmlFor="credit-summary-refunded">
                    <FormattedMessage
                      id="credit.summary.refunded"
                      description="Label for credit summary refunded figure."
                      defaultMessage="Refunded"
                    />
                    <AnimatedNumber
                      id="credit-summary-refunded"
                      targetValue={ creditSummary.refunded }
                      initialValue={ previousSummary.refunded }
                    />
                  </SummaryNumberWithLabel>
                  <SummaryNumberWithLabel htmlFor="credit-summary-reserved">
                    <FormattedMessage
                      id="credit.summary.reserved"
                      description="Label for credit summary reserved figure."
                      defaultMessage="Reserved"
                    />
                    <AnimatedNumber
                      id="credit-summary-reserved"
                      targetValue={ creditSummary.reserved }
                      initialValue={ previousSummary.reserved }
                    />
                  </SummaryNumberWithLabel>
                  <SummaryNumberWithLabel htmlFor="credit-summary-spent">
                    <FormattedMessage
                      id="credit.summary.spent"
                      description="Label for credit summary spent figure."
                      defaultMessage="Spent"
                    />
                    <AnimatedNumber
                      id="credit-summary-spent"
                      targetValue={ creditSummary.spent }
                      initialValue={ previousSummary.spent }
                    />
                  </SummaryNumberWithLabel>
                </StyledCreditSummary>
              )
              : <div>Error</div>
          )
      }
    </div>
  );
};
