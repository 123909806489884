import { ComponentProps, FC, ReactNode } from 'react';

import { AuthFormPane } from '../AuthFormPane/AuthFormPane';
import { PolicyLinks } from '../PolicyLinks/PolicyLinks';

type Props = {
  policyLinkProps?: ComponentProps<typeof PolicyLinks>;
  helpLink?: string;
  children?: ReactNode;
};

export const AuthFullscreenLayout: FC<Props> = ({
  policyLinkProps,
  helpLink,
  children,
}) => (
  <AuthFormPane
    css={ {
      width: '100%',
      height: '100%',
    } }
    helpLink={ helpLink }
    policyLinkProps={ policyLinkProps }
  >
    { children }
  </AuthFormPane>
);
