import { CSSProperties, PropsWithChildren } from 'react';
import { defaultTheme } from 'op-storybook';
import { FontWeights } from 'op-storybook/lib/types/Theme/Theme';

import { StyledTypography } from './style';
import { SizeKey } from '../../../Model/SizeKey';

type Props<T extends keyof typeof defaultTheme.new['typography']> = {
  type?: T;
  size?: SizeKey | '2xl';
  weight?: keyof FontWeights;
  color?: string;
  transition?: boolean;
  align?: CSSProperties['textAlign'];
};

/** @deprecated Prefer Typography from op-storybook project */
export const Typography = <T extends keyof typeof defaultTheme.new['typography']>({
  type = 'text' as T,
  size = 'md',
  weight = 'medium',
  color = 'primary.text',
  transition = false,
  align = 'left',
  children,
}: PropsWithChildren<Props<T>>): JSX.Element => (
  <StyledTypography
    type={ type }
    weight={ weight }
    size={ size }
    color={ color }
    transition={ transition }
    align={ align }
  >
    { children }
  </StyledTypography>
);
