import { useIntl } from 'react-intl';
import { FunctionComponent } from 'react';

import { BasicAutocomplete, BasicAutocompleteProps } from '../../../Common/Component';
import { TagType } from '../../Model';
import { useLocalisedTagTypes } from '../../Hook/useLocalisedTagTypes';

type Props = Omit<BasicAutocompleteProps<TagType>, 'options'>;

export const TagImportTypeAutocomplete: FunctionComponent<Props> = props => {
  const intl = useIntl();
  const options = useLocalisedTagTypes();

  return (
    <BasicAutocomplete
      options={ options }
      label={ intl.formatMessage({
        id: 'tagImportType.label',
        description: 'Label for tag import type autocomplete.',
        defaultMessage: 'Types',
      }) }
      { ...props }
    />
  );
};
