import styled from '@emotion/styled';

export const StyledPadded = styled.section<{ useNewTheme: boolean }>`
  padding: ${ props => `${ props.theme.spacing(3) } ${ props.theme.spacing(4) }` };

  ${ props => props.theme.breakpoints.down('lg') } {
    padding: ${ props => `${ props.theme.spacing(2) }` };
  }

  ${ props => props.theme.breakpoints.down('sm') } {
    padding: ${ props => `${ props.theme.spacing(1) }` } 0;
  }

  padding-bottom: ${ props => `${ props.theme.spacing(10) }` };

  ${ props => props.useNewTheme && `
    background-color: ${ props.theme.new.basePalette.white.main };
    width: 100%;
    min-height: 100%;
    padding: ${ props.theme.new.spacing[8] } ${ props.theme.new.spacing[16] };

    ${ props.theme.breakpoints.down('lg') } {
      padding: ${ props.theme.new.spacing[4] } ${ props.theme.new.spacing[8] };
    }

    ${ props.theme.breakpoints.down('md') } {
      padding: ${ props.theme.new.spacing[2] } ${ props.theme.new.spacing[6] };
    }

    ${ props.theme.breakpoints.down('sm') } {
      padding: ${ props.theme.new.spacing[2] } ${ props.theme.new.spacing[4] };
    }
  ` }
`;
