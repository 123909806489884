import { FC, ReactNode } from 'react';
import { IntlShape } from 'react-intl';

import {
  SingleContentCard,
  Content,
  ContentDefinition,
  ContentEditorProps,
  ContentPreviewProps,
  DraftContent,
  EditorContent,
} from '../Model';
import { SvgComponent } from '../../Common/Model/SvgComponent';
import { ValidationTree } from '../../Common/Model/ValidationTree';
import { UniqueIdGenerator } from '../../Common/Utility/UniqueIdGenerator';

export class ContentDefinitionRegistry {
  constructor(
    public readonly contentDefinitions: ContentDefinition[] = [],
  ) {}

  public get = (id: string): ContentDefinition | undefined => (
    this.contentDefinitions.find(contentDefinition => contentDefinition.id === id)
  );

  public cloningDisabled = (editorContent: EditorContent): boolean => (
    !!this.getEditorContentDefinition(editorContent)?.cloningDisabled
  );

  public getDraftContentTitle = (intl: IntlShape, draftContent: DraftContent): string => (
    this.getContentDefinition(draftContent.type).getDraftContentTitle(intl, draftContent)
  );

  public getContentTitle = (intl: IntlShape, content: Content): ReactNode => (
    this.getContentDefinition(content.type).getContentTitle(intl, content)
  );

  public transformCard = <C extends SingleContentCard>(card: C): EditorContent => (
    (this.getContentDefinition(card.content.type) as unknown as ContentDefinition<C['content']>).transformCard(card)
  );

  public createEditorContentWithType = (type: string): EditorContent => (
    this.getContentDefinition(type).createEditorContent()
  );

  public cloneEditorContent = <T extends EditorContent>(editorContent: T): T => {
    const clone = this.getEditorContentDefinition(editorContent).cloneEditorContent;
    const { id: cardId, ...card } = editorContent.card;
    const { id: contentId, ...content } = card.content;

    return clone
      ? clone(editorContent)
      : {
        ...editorContent,
        id: UniqueIdGenerator.generate(),
        card: {
          ...card,
          content,
        },
      };
  };

  public validate = <T extends EditorContent>(editorContent: T): ValidationTree<T['card']> => ({
    errors: [],
    children: {
      content: this.getEditorContentDefinition(editorContent).validate(editorContent.card.content),
    } as ValidationTree<T['card']>['children'],
  });

  public getIconComponent = (type: string): SvgComponent => (
    this.getContentDefinition(type).IconComponent
  );

  public getPreviewComponent = <E extends EditorContent>(content: E): FC<ContentPreviewProps<E>> => (
    this.getEditorContentDefinition(content).PreviewComponent
  );

  public getEditorComponent = <E extends EditorContent>(content: E): FC<ContentEditorProps<E>> => (
    this.getEditorContentDefinition(content).EditorComponent
  );

  public getContentDefinition = (contentType: string): ContentDefinition => {
    const definition = this.contentDefinitions.find(contentDefinition => contentDefinition.definesContentType(contentType));

    if (!definition) {
      throw new Error(`Content definition not registered. ${ contentType }`);
    }

    return definition;
  };

  private getEditorContentDefinition = <E extends EditorContent>(editorContent: E): ContentDefinition<Content, E> => (
    this.getContentDefinition(editorContent.card.content.type) as unknown as ContentDefinition<Content, E>
  );
}
