import { ContextCreator } from '@ourpeople/shared/Core/Utility/ContextCreator';

import { MessageLogEntry } from '../Model/PushMessage';

export interface PushMessageContextValue {
  messageHistory: MessageLogEntry[];
  dismissMessages: (predicate: (messageLogEntry: MessageLogEntry) => boolean) => void;
}

export const PushMessageContext = ContextCreator.withDisplayName<PushMessageContextValue>(
  'PushMessageContext',
  null,
);
