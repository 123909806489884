import { ComponentProps, DOMAttributes, FC, useCallback, useMemo } from 'react';

import { AvatarFallback } from '../AvatarFallback/AvatarFallback';
import { AvatarFrame } from '../AvatarFrame/AvatarFrame';
import { Tooltip } from '../Tooltip/Tooltip';

type Props = {
  name: string | null;
  fallbackSeed: string | undefined;
  src: string | null;
  size: ComponentProps<typeof AvatarFrame>['size'];
  elevate?: boolean;
  onLoad?: DOMAttributes<HTMLImageElement>['onLoad'];
  onError?: DOMAttributes<HTMLImageElement>['onError'];
  className?: string;
  tooltip?: boolean;
};

export const Avatar: FC<Props> = ({
  name,
  fallbackSeed,
  src,
  elevate = false,
  size,
  onLoad,
  onError,
  className,
  tooltip = false,
  children,
}) => {
  const renderAvatar = useCallback((size: Props['size']) => (
    <AvatarFrame
      elevate={ elevate }
      size={ size }
      className={ className }
    >
      {
        src
          ? (
            <img
              alt={ name }
              src={ src }
              css={ {
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
              } }
              onLoad={ onLoad }
              onError={ onError }
            />
          )
          : (
            <AvatarFallback
              name={ name }
              seed={ fallbackSeed }
            />
          )
      }
      { children }
    </AvatarFrame>
  ), [elevate, name, fallbackSeed, onLoad, onError, src, className, children]);

  const avatar = useMemo(() => renderAvatar(size), [size, renderAvatar]);

  return (
    tooltip
      ? (
        <Tooltip
          contents={ renderAvatar('lg') }
          className={ className }
          css={ { background: 'transparent' } }
          side="top"
          noBackdrop
        >
          <div>
            { avatar }
          </div>
        </Tooltip>
      )
      : avatar
  );
}
