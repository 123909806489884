import { FunctionComponent, ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ChartData } from 'chart.js';
import { useTheme } from '@emotion/react';

import { Metric } from '../../Model';
import { DoughnutChartMetric } from '../DoughnutChartMetric/DoughnutChartMetric';
import { MetricTransformer } from '../../Utility';
import { MetricContainer } from '../MetricContainer/MetricContainer';
import { MissingDataMessage } from '../MissingDataMessage/MissingDataMessage';
import { DeprecatedLink } from '../../../Common/Component';

interface Props {
  previousMetric?: Metric;
  currentMetric: Metric;
}

export const EventsSentMetric: FunctionComponent<Props> = ({
  previousMetric,
  currentMetric,
}) => {
  const intl = useIntl();
  const theme = useTheme();
  const currentPeriodDataPoints = consolidateEventDatasets(currentMetric);
  const previousPeriodDataPoints = previousMetric ? consolidateEventDatasets(previousMetric) : [];
  const currentTotal = sumDataPoints(currentPeriodDataPoints);
  const previousTotal = sumDataPoints(previousPeriodDataPoints);
  const chartData: Required<ChartData<'doughnut'>> = {
    datasets: [{
      data: currentPeriodDataPoints,
      backgroundColor: [
        theme.palette.secondary.dark,
        theme.palette.secondary.main,
        theme.palette.secondary.light,
      ],
      borderWidth: 0,
    }],
    labels: [
      intl.formatMessage({
        id: 'dashboard.eventsSent.coverLabel',
        description: 'Label for cover in events sent dashboard metric',
        defaultMessage: 'Cover',
      }),
      intl.formatMessage({
        id: 'dashboard.eventsSent.meetingLabel',
        description: 'Label for meeting in events sent dashboard metric',
        defaultMessage: 'Meeting',
      }),
      intl.formatMessage({
        id: 'dashboard.eventsSent.trainingLabel',
        description: 'Label for training in events sent dashboard metric',
        defaultMessage: 'Training',
      }),
    ],
  };

  return (
    <MetricContainer
      title={ intl.formatMessage({
        id: 'dashboard.eventsSent.heading',
        description: 'Heading for events sent stat block on dashboard.',
        defaultMessage: 'Events sent',
      }) }
    >
      {
        currentTotal
          ? (
            <DoughnutChartMetric
              previousValue={ previousTotal }
              currentValue={ currentTotal }
              chartData={ chartData }
            />
          )
          : (
            <MissingDataMessage>
              <FormattedMessage
                id="dashboard.eventsSent.noData"
                defaultMessage="<p>There were no events sent in this period.</p><p><a>Learn more about events.</a></p>"
                values={{
                  a: (chunks: ReactNode) => (
                    <DeprecatedLink to={intl.formatMessage({
                      id: 'dashboard.eventsSent.helpDeskLink',
                      defaultMessage: 'https://get.ourpeople.help/hc/sections/360003813393-Events',
                    })}>
                      { chunks }
                    </DeprecatedLink>
                  )
                }}
              />
            </MissingDataMessage>
          )
      }
    </MetricContainer>
  );
}

const sumDataPoints = (dataPoints: number[]): number => dataPoints.reduce(
  (sum, value) => sum + value,
  0,
);

const consolidateEventDatasets = (metric: Metric): number[] => MetricTransformer.sumDatasets(
  metric,
  [
    'covercontent',
    'meetingcontent',
    'trainingcontent',
  ],
);
