import RRule from 'rrule';

import { Audience } from '../../Audiences/Model';
import { Broadcast, DraftBroadcast } from '../Model';
import { TemplateBroadcast } from '../Hook';
import { UniqueIdGenerator } from '../../Common/Utility';
import { ContentDefinitionRegistry } from '../../Content/Service';
import { EditorContent } from '../../Content/Model';
import { AudienceReader } from '../../Audiences/Utility';
import { Delivery } from '../../Models';
import { RRuleParser } from '../../Utility/RRuleParser';

export class BroadcastTransformer {
  public static toDraft = (
    broadcast: Broadcast,
    recipientAudience?: Audience,
    delivery?: Delivery,
    shareAudience?: Audience,
  ): DraftBroadcast => {
    const rRule = broadcast.recurrenceRule ? RRule.fromString(broadcast.recurrenceRule) : null;
    const rRuleOptions = rRule?.options;
    const offsetDifference = rRule ? RRuleParser.localeOffsetInMinutes(rRule) : 0;
    return {
      name: broadcast.name,
      recurrenceRule: rRuleOptions
        ? RRule.optionsToString({
          ...rRuleOptions,
          tzid: Intl.DateTimeFormat().resolvedOptions().timeZone,
          byhour: rRuleOptions.byhour[0] + offsetDifference / 60,
        })
        : null,
      sendNudge: !!broadcast.nudge,
      nudgeDelay: broadcast.nudge?.delay || null,
      sendPush: broadcast.sendPush,
      content: [],
      tags: [],
      categoryId: broadcast.categoryId,
      recipientAudience: recipientAudience || null,
      notification: broadcast.notification,
      shareAudiencePersonIds: shareAudience ? AudienceReader.personIds(shareAudience) : [],
      ...(delivery?.deliverAt ? { scheduleFor: delivery.deliverAt.dt } : {}),
      ...(
        broadcast.sendEmail !== undefined || broadcast.sendSms !== undefined
          ? {
            sendEmail: broadcast.sendEmail === true,
            sendSms: broadcast.sendSms === true,
          }
          : {}
      ),
      ...(
        broadcast.summarySms
          ? {
            summarySms: broadcast.summarySms.message,
            sendInactiveUsersSummarySms: broadcast.summarySms.includeInactive,
          }
          : {}
      ),
    };
  };

  public static templateBroadcastToDraft = (
    { contents, ...broadcast }: TemplateBroadcast,
    contentDefinitionRegistry: ContentDefinitionRegistry,
  ): [DraftBroadcast, EditorContent[]] => {
    const editorContents = contents.map(
      content => contentDefinitionRegistry.transformCard({
        id: UniqueIdGenerator.generate(),
        content,
        image: null,
        index: 0,
        mandatory: false,
      })
    );

    const draftBroadcast = {
      ...broadcast,
      content: editorContents.map(editorContent => editorContent.card.content),
    };

    return [
      draftBroadcast,
      editorContents,
    ];
  };
}
