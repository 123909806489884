import { FC, useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';
import { CenteredGenericErrorMessage, CenteredGenericLoadingMessage } from '@ourpeople/shared/Core/Component/Content';

import { Padded, PaginatedTable, SortableHeaderCell, TableCell, TableRow } from '../../../../Components';
import {
  Box,
  DeletePrompt,
  FlexPullRight,
  LoadingContainer,
  PageHeader,
  TableFilters,
  TruncatingContent
} from '../../../../Common/Component';
import { usePermissions } from '../../../../Security/Hook';
import { ChatsPermission, ListedTopic } from '../../../Model';
import { GuardedLink } from '../../../../Security/Component';
import { FetchTopicsParams, FetchTopicsSort, useFetchTopics } from '../../../Hook';
import { useManageTopicsTitle } from '../../../Hook/useManageTopicsTitle';
import { useQueryAsState } from '../../../../Hooks';
import { QueryParser, QueryWithKeys } from '../../../../Common/Utility';
import { TableRowContextMenu } from '../../../../Common/Component/TableRowContextMenu/TableRowContextMenu';
import EditIcon from '../../../../Assets/img/icons/streamline/common-file-edit.svg';
import DeleteIcon from '../../../../Assets/img/icons/streamline/bin-1.svg';
import { useApi, useContextOrThrow } from '../../../../Core/Hook';
import { useMounted } from '../../../../Common/Hook';
import { ChatAudienceMemberDialog } from '../../../Component';
import { NonWrappingCell } from '../../../../Forms/Component';
import { ToastContext } from '../../../../Core/Context';

type Query = QueryWithKeys<'pageNum' | 'search' | 'sort'>;

export const TopicsListPage: FC = () => {
  const intl = useIntl();
  const [query, setQuery] = useQueryAsState<Query>();
  const api = useApi();
  const mounted = useMounted();
  const manageTopicsTitle = useManageTopicsTitle();
  const params = useMemo<FetchTopicsParams>(() => ({
    pageNum: QueryParser.pageNum(query),
    sort: parseFetchTopicsSort(query),
    ...(query.search ? { search: query.search } : {}),
  }), [query]);
  const [fetchTopicsResult, fetchTopicsState, reloadTopics] = useFetchTopics(params);
  const { permissionAvailable, guardedCallback } = usePermissions();
  const [deletingTopic, setDeletingTopic] = useState<ListedTopic>();
  const { addSuccessToast, addErrorToast } = useContextOrThrow(ToastContext);
  const [viewingTopicId, setViewingTopicId] = useState<string>();

  const whenPageNumChanged = useCallback((pageNum: number) => (
    setQuery(({ pageNum: discardedPageNum, ...query }) => ({
      ...query,
      ...(pageNum ? { pageNum: `${ pageNum }` } : {}),
    }))
  ), [setQuery]);

  const whenSearchChanged = useCallback((search: string) => (
    setQuery(({ search: discardedSearch, ...query }) => ({
      ...query,
      ...(search ? { search } : {}),
    }))
  ), [setQuery]);

  const whenSortChanged = useCallback((sort: FetchTopicsSort) => (
    setQuery(({ sort: discardedSort, ...query }) => ({
      ...query,
      ...(sort ? { sort } : {}),
    }))
  ), [setQuery]);

  const deleteTopic = useCallback(() => {
    if (!deletingTopic) {
      return;
    }

    setDeletingTopic(undefined);
    api.delete(`/chats/${ deletingTopic.topic.id }`)
      .then(() => {
        setTimeout(() => {
          if (!mounted.current) {
            return;
          }

          addSuccessToast(
            intl.formatMessage({
              id: 'chat.topics.delete.success',
              description: 'Success message when deleting a topic from the list page.',
              defaultMessage: '{ name } deleted successfully.',
            }, {
              name: deletingTopic.topic.name,
            })
          );
          setDeletingTopic(undefined);
          reloadTopics();
        }, 50);
      })
      .catch(() => {
        if (!mounted.current) {
          return;
        }

        addErrorToast(
          intl.formatMessage({
            id: 'chat.topics.delete.failure',
            description: 'Failure message when deleting a topic from the list page.',
            defaultMessage: '{ name } could not be deleted.',
          }, {
            name: deletingTopic.topic.name,
          })
        );
        setDeletingTopic(undefined);
      });
  }, [addErrorToast, addSuccessToast, api, deletingTopic, intl, mounted, reloadTopics]);

  return <>
    <Padded>
      <PageHeader
        items={ [
          {
            link: '/chats/topics',
            title: manageTopicsTitle,
          },
        ] }
      >
        <FlexPullRight>
          { permissionAvailable(ChatsPermission.TOPICS_CREATE) && (
            <GuardedLink
              permissions={ [ChatsPermission.TOPICS_CREATE] }
              to={ '/chats/topics/create' }
            >
              <Button variant="primary">
                <FormattedMessage
                  id="chat.topics.list.create"
                  description="Label for create button on list topics page."
                  defaultMessage="Add topic"
                />
              </Button>
            </GuardedLink>
          ) }
        </FlexPullRight>
      </PageHeader>
      <Box>
        <TableFilters
          onClearFiltersClicked={ () => setQuery({}) }
          searchValue={ query.search || '' }
          onSearchChanged={ whenSearchChanged }
        />
        {
          fetchTopicsResult
            ? fetchTopicsResult.error
              ? <CenteredGenericErrorMessage onRetryClicked={ reloadTopics }/>
              : (
                <PaginatedTable
                  rows={ fetchTopicsResult.content?.topics || [] }
                  headerRow={
                    <TableRow>
                      <SortableHeaderCell
                        sort={ params.sort }
                        onSort={ whenSortChanged }
                        ascValue="name_asc"
                        descValue="name_desc"
                      >
                        <FormattedMessage
                          id="chat.topics.columns.name"
                          description="Heading for name column in topics table."
                          defaultMessage="Name"
                        />
                      </SortableHeaderCell>
                      <TableCell>
                        <FormattedMessage
                          id="chat.topics.columns.description"
                          description="Heading for description column in topics table."
                          defaultMessage="Description"
                        />
                      </TableCell>
                      <TableCell>
                        <FormattedMessage
                          id="chat.topics.columns.type"
                          description="Heading for type column in topics table."
                          defaultMessage="Type"
                        />
                      </TableCell>
                      <TableCell>
                        <FormattedMessage
                          id="chat.topics.columns.members"
                          description="Heading for members column in topics table."
                          defaultMessage="Members"
                        />
                      </TableCell>
                      <TableCell>
                        <FormattedMessage
                          id="chat.topics.columns.actions"
                          description="Heading for actions column in topics table."
                          defaultMessage="Actions"
                        />
                      </TableCell>
                    </TableRow>
                  }
                  rowRender={ listedTopic => (
                    <TableRow key={ listedTopic.topic.id }>
                      <TableCell>
                        <TruncatingContent>
                          {
                            permissionAvailable(ChatsPermission.TOPICS_UPDATE)
                              ? (
                                <GuardedLink
                                  to={ `/chats/topics/${ listedTopic.topic.id }/edit` }
                                  permissions={ [ChatsPermission.TOPICS_UPDATE] }
                                >
                                  { listedTopic.topic.name }
                                </GuardedLink>
                              )
                              : listedTopic.topic.name
                          }
                        </TruncatingContent>
                      </TableCell>
                      <TableCell>
                        <TruncatingContent>
                          { listedTopic.topic.description }
                        </TruncatingContent>
                      </TableCell>
                      <NonWrappingCell>
                        {
                          listedTopic.topic.autoJoin
                            ? (
                              <FormattedMessage
                                id="chat.topics.autoJoin"
                                description="Label for topics with automatic join type."
                                defaultMessage="Auto join"
                              />
                            )
                            : (
                              <FormattedMessage
                                id="chat.topics.manualJoin"
                                description="Label for topics with manual join type."
                                defaultMessage="Join manually"
                              />
                            )
                        }
                      </NonWrappingCell>
                      <NonWrappingCell>
                        <Button
                          color="secondary"
                          onClick={ () => setViewingTopicId(listedTopic.topic.id) }
                        >
                          { listedTopic.counts.members.joined }
                        </Button>
                      </NonWrappingCell>
                      <NonWrappingCell>
                        <TableRowContextMenu
                          id={ `menu-${ listedTopic.topic.id }` }
                          actions={ [
                            ...(permissionAvailable(ChatsPermission.TOPICS_UPDATE) ? [
                                {
                                  id: 'edit',
                                  label: intl.formatMessage({
                                    id: 'chat.topics.edit',
                                    description: 'Label for row edit button in topics list.',
                                    defaultMessage: 'Edit',
                                  }),
                                  IconComponent: EditIcon,
                                  component: GuardedLink,
                                  permissions: [ChatsPermission.TOPICS_UPDATE],
                                  to: `/chats/topics/${ listedTopic.topic.id }/edit`,
                                }
                              ] : []),
                            ...(permissionAvailable(ChatsPermission.TOPICS_DELETE) ? [
                                {
                                  id: 'delete',
                                  label: intl.formatMessage({
                                    id: 'chat.topics.delete',
                                    description: 'Label for row delete button in topics list.',
                                    defaultMessage: 'Delete',
                                  }),
                                  busy: deletingTopic?.topic.id === listedTopic.topic.id,
                                  IconComponent: DeleteIcon,
                                  onClick: guardedCallback(() => setDeletingTopic(listedTopic), [ChatsPermission.TOPICS_DELETE]),
                                }
                              ] : []),
                          ] }
                        />
                      </NonWrappingCell>
                    </TableRow>
                  ) }
                  pageNum={ params.pageNum }
                  onPageChanged={ whenPageNumChanged }
                  requestState={ fetchTopicsState }
                  onRetryClicked={ reloadTopics }
                  pagination={ fetchTopicsResult.content?.pagination }
                />
              )
            : <LoadingContainer><CenteredGenericLoadingMessage/></LoadingContainer>
        }
      </Box>
    </Padded>
    <DeletePrompt
      open={ !!deletingTopic }
      onConfirm={ () => deleteTopic() }
      onCancel={ () => setDeletingTopic(undefined) }
    />
    { viewingTopicId && (
      <ChatAudienceMemberDialog
        chatId={ viewingTopicId }
        onClose={ () => setViewingTopicId(undefined) }
      />
    ) }
  </>;
};

const parseFetchTopicsSort = QueryParser.getValueParser(
  'sort',
  ['created_at_asc', 'created_at_desc', 'name_asc', 'name_desc'],
  'name_asc',
);
