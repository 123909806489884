import {
  Condition,
  EveryoneCondition,
  NestCondition,
  NobodyCondition,
  PersonCondition,
  RoleCondition,
  StatusCondition,
  TagCondition
} from '../Model';

export class ConditionIdentifier {
  public static conditionIsRoleCondition = (condition: Condition): condition is RoleCondition => condition.type === 'role';
  public static conditionIsTagCondition = (condition: Condition): condition is TagCondition => condition.type === 'tag';
  public static conditionIsPersonCondition = (condition: Condition): condition is PersonCondition => condition.type === 'person';
  public static conditionIsStatusCondition = (condition: Condition): condition is StatusCondition => condition.type === 'status';
  public static conditionIsEveryoneCondition = (condition: Condition): condition is EveryoneCondition => condition.type === 'everyone';
  public static conditionIsNobodyCondition = (condition: Condition): condition is NobodyCondition => condition.type === 'nobody';
  public static conditionIsNestCondition = (condition: Condition): condition is NestCondition => condition.type === 'nest';
}
