import styled from '@emotion/styled';

export const ButtonRow = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  padding: ${ props => props.theme.spacing(1) };
  gap: ${ props => props.theme.spacing(1) };

  > div:first-of-type .MuiButton-label {
    justify-content: flex-start;
  }

  > div:last-child .MuiButton-label {
    justify-content: flex-end;
  }
`;

export const StyledPager = styled.div`
  margin: ${ props => props.theme.spacing(1) };
`;
