import styled from '@emotion/styled';

export const StyledInputWrapper = styled.div`
  margin-bottom: ${props => props.theme.spacing(1)};
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 0.5rem;
  align-items: flex-start;

  .MuiFormHelperText-root {
    color: ${ props => props.theme.palette.error.main };
  }

  input[type=color] {
    padding: 0;
    margin: 0;
    width: 2rem;
    height: 2rem;
    border: 1px solid ${ props => props.theme.palette.grey[300] };
    border-radius: 0.25rem;
    overflow: hidden;

    &::-webkit-color-swatch-wrapper {
      padding: 0;
    }

    &::-webkit-color-swatch {
      border: none;
    }

    &::-moz-color-swatch {
      border: none;
    }
  }

  .MuiInputBase-root {
    border: 1px solid ${ props => props.theme.palette.grey[300] };

    input {
      padding-top: 5.5px;
      padding-bottom: 5.5px;
    }
  }
`;
