import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { LineClamp } from 'op-storybook/stories/components/LineClamp/LineClamp';

import { TableCell, TableRow } from '../../../Components';
import { BroadcastIcon } from '../BroadcastIcon/BroadcastIcon';
import { StyledBroadcastTitle, StyledRecurringLabel, } from '../../../Sections/Broadcasts/List/styles';
import { DateTime } from '../../../Common/Component';
import { BroadcastMenu, BroadcastTable, LegacyBroadcastTableFilters } from '..';
import { BroadcastsPermission, TableProps } from '../../Model';
import { usePermissions } from '../../../Security/Hook';
import {
  InlinePersonAvatarAndName
} from '../../../Common/Component/InlinePersonAvatarAndName/InlinePersonAvatarAndName';

export const ArchivedTable: FunctionComponent<TableProps> = ({
  pageNum,
  onPageChanged,
  contentTypes,
  teamIds,
  search,
  onActionConfirmed,
  lastAction,
  actionError,
  actionInProgress,
}) => {
  const { permissionsAvailable, permissionAvailable } = usePermissions();

  return (
    <div>
      <LegacyBroadcastTableFilters
        lastAction={ lastAction }
        actionError={ actionError }
        actionInProgress={ actionInProgress }
      />
      <BroadcastTable
        pageNum={ pageNum }
        contentTypes={ contentTypes }
        onPageChanged={ onPageChanged }
        teamIds={ teamIds }
        search={ search }
        noUnarchived={ true }
        sort="updated_at_desc"
        headerRow={
          <TableRow>
            <TableCell>
              <FormattedMessage
                id="broadcasts.list.type"
                defaultMessage="Type"
              />
            </TableCell>
            <TableCell minwidth="11rem">
              <FormattedMessage
                id="broadcasts.list.title"
                defaultMessage="Broadcast title"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                id="broadcasts.list.sentBy"
                defaultMessage="Created by"
              />
            </TableCell>
            <TableCell minwidth="8rem">
              <FormattedMessage
                id="broadcasts.list.type"
                defaultMessage="Sent"
              />
            </TableCell>
            <TableCell/>
          </TableRow>
        }
        rowRender={ (broadcast) => {
          return (
            <TableRow key={ broadcast.broadcast.id }>
              <TableCell><BroadcastIcon broadcast={ broadcast.broadcast }/></TableCell>
              <TableCell>
                <LineClamp minWidth={ 200 }>
                  {
                    broadcast.lastDelivery === null
                      ? (
                        <p>
                          { broadcast.broadcast.name }
                        </p>
                      )
                      : (
                        <StyledBroadcastTitle
                          to={ `/broadcasts/${ broadcast.broadcast.id }/report` }
                        >
                          { broadcast.broadcast.name }
                        </StyledBroadcastTitle>
                      )
                  }
                  {
                    broadcast.broadcast.recurrenceRule && (
                      <StyledRecurringLabel>
                        <FormattedMessage
                          id="broadcasts.list.recurring"
                          defaultMessage="Recurring"
                          description="Label displayed next to broadcast title to indicate recurring schedule."
                        />
                      </StyledRecurringLabel>
                    )
                  }
                </LineClamp>
              </TableCell>
              <TableCell>
                <LineClamp minWidth={ 200 }>
                  <InlinePersonAvatarAndName person={ broadcast.broadcast.created.by }/>
                </LineClamp>
              </TableCell>
              <TableCell>
                <LineClamp minWidth={ 100 }>
                  <DateTime dateTime={ broadcast.lastDelivery?.deliveredAt }/>
                </LineClamp>
              </TableCell>
              <TableCell>
                <BroadcastMenu
                  broadcastId={ broadcast.broadcast.id }
                  showReport={ permissionsAvailable([
                    BroadcastsPermission.REPORT,
                    BroadcastsPermission.READ,
                  ]) }
                  showUnarchive={ permissionAvailable(
                    BroadcastsPermission.UPDATE,
                  ) }
                  onActionConfirmed={ onActionConfirmed }
                />
              </TableCell>
            </TableRow>
          );
        } }
      />
    </div>
  );
};
