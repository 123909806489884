import styled from '@emotion/styled';

import { FeedbackSeverity } from '../Notice/Notice';

type StyledAnimatedNumberProps = {
  color: FeedbackSeverity;
};

export const StyledAnimatedNumber = styled.div<StyledAnimatedNumberProps>`
  color: ${props => props.theme.palette[props.color].main};
  font-size: 4rem;
  line-height: 1;
  font-weight: 500;
  margin-top: 0.5rem;
`;
