import { FunctionComponent, MouseEvent, ReactNode } from 'react';

import {
  StyledExternalLink
} from './styles';

interface ButtonProps {
  href: string;
  target?: string;
  bordered?: boolean;
  primary?: boolean;
  disabled?: boolean;
  onClick?: (event: MouseEvent) => void;
  children?: ReactNode;
}

export const ExternalLinkButton: FunctionComponent<ButtonProps> = (props) => {
  const {
    href,
    children,
    target = '_blank',
    bordered = true,
    primary,
    disabled,
    onClick,
  } = props;

  const localOnClick = (event: MouseEvent): void => {
    if (disabled) {
      event.preventDefault();
      return;
    }
    if (onClick && typeof onClick === 'function') {
      onClick(event);
    }
  };

  return (
    <StyledExternalLink
      href={href}
      target={target}
      disabled={disabled || false}
      primary={primary ? 'true' : null}
      bordered={bordered ? 'true' : null}
      onClick={localOnClick}
      >
        {children}
    </StyledExternalLink>
  );
}
