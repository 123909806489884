import { FC, ReactNode } from 'react';

import { StyledContentPreview } from './style';

type Props = {
  aspectRatio: number;
  children?: ReactNode;
};

export const ContentPreview: FC<Props> = ({
  aspectRatio,
  children
}) => (
  <StyledContentPreview aspectRatio={ aspectRatio }>
    <div>
      <div>
        <div>
          <div>
            { children }
          </div>
        </div>
      </div>
    </div>
  </StyledContentPreview>
);
