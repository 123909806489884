import styled from '@emotion/styled';

import { PaletteLookup } from '../../../lib/model/PaletteLookup/PaletteLookup';

type Props = {
  palette: PaletteLookup;
};

export const StyledDivider = styled.hr<Props>`
  background-color: ${ props => props.theme.new.palette[props.palette.colour][props.palette.intensity][props.palette.contrast ? 'contrast' : 'main'] };
  margin: 0;
  height: 1px;
  border: none;
  width: 100%;
`;
