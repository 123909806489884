import { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';

import { CsvDropzone } from '../../../Imports/Component';

type Props = {
  onComplete: (importId: string) => void;
}

export const PeopleImportDropzone: FunctionComponent<Props> = ({ onComplete }) => {
  const history = useHistory();

  const whenComplete = (importId: string) => {
    onComplete(importId);
    history.push(`/people/import/${ importId }/map-columns`, { status: 'pending' });
  };

  return (
    <CsvDropzone
      authoriseEndpoint={ '/people/imports/authorise-upload' }
      onComplete={ whenComplete }
    />
  );
};
