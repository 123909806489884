import { FormattedMessage, useIntl } from 'react-intl';
import { FC } from 'react';
import {
  ValidationErrorHandlerFactory
} from 'op-storybook/lib/utility/ValidationErrorHandlerFactory/ValidationErrorHandlerFactory';
import {
  useLocalisedValidationErrors
} from 'op-storybook/lib/hooks/useLocalisedValidationHandlers/useLocalisedValidationHandlers';

import { SelectionRange } from '../SelectionRange/SelectionRange';
import { VerticallySpaced } from '../../../../Common/Component';
import { UploadRequest as UploadRequestModel } from '../../../Model/ContentTypes';
import { Upload } from '../../../../Content/Component/BroadcastContent/Upload';
import { StyledUploads } from '../../../../Content/Component/BroadcastContent/Upload/UploadBroadcastContent/style';
import { UploadState } from '../../../../Common/Context';
import { ValidationTree } from '../../../../Common/Model';
import { DraftUploadResponse } from '../../../Service';
import { FormResponseErrors } from '../../FormResponseErrors/FormResponseErrors';
import { useDescendantErrors, useValidationErrorHandlers } from '../../../../Common/Hook';
import { ValidationErrorIdentifier } from '../../../../Common/Utility/ValidationErrorIdentifier';

type Props = {
  content: UploadRequestModel;
  validation?: ValidationTree<DraftUploadResponse>;
  displayValidation: boolean;
  uploadStates: Map<string, UploadState>;
  onRemoveClicked: (uploadId: string) => void;
};

export const UploadRequest: FC<Props> = ({
  content,
  validation,
  displayValidation,
  uploadStates,
  onRemoveClicked,
}) => {
  const intl = useIntl();
  const errors = useDescendantErrors(validation);
  const localisedErrors = useLocalisedValidationErrors(
    useValidationErrorHandlers(
      intl.formatMessage({
        description: 'Form number request input name used in error messages.',
        defaultMessage: 'your response',
      }),
      undefined,
      undefined,
      [
        ValidationErrorHandlerFactory.createHandler(
          ValidationErrorIdentifier.isTooFewError,
          error => intl.formatMessage({
            description: 'Error when upload request response in form is under min upload count.',
            defaultMessage: 'Please upload at least { min } images.',
          }, { min: error.metadata.minCount }),
        ),
        ValidationErrorHandlerFactory.createHandler(
          ValidationErrorIdentifier.isTooManyError,
          error => intl.formatMessage({
            description: 'Error when upload request response in form exceeds max upload count.',
            defaultMessage: 'Please upload no more than { max } images.',
          }, { max: error.metadata.maxCount }),
        ),
      ],
    ),
    errors,
  );

  return (
    <>
      <SelectionRange
        min={ content.minUploads > 1 ? content.minUploads : null }
        max={ content.maxUploads }
        singular={
          <FormattedMessage
            id="form.uploadRequest.preview.upload.singular"
            defaultMessage="upload"
          />
        }
        plural={
          <FormattedMessage
            id="form.uploadRequest.preview.upload.plural"
            defaultMessage="uploads"
          />
        }
      />
      <VerticallySpaced gap={ 2 }>
        <StyledUploads>
          { Array.from(uploadStates.entries()).map(([id, uploadState]) => (
            <Upload
              key={ id }
              uploadState={ uploadState }
              onRemoveClicked={ () => onRemoveClicked(id) }
              saved={ false }
            />
          )) }
        </StyledUploads>
      </VerticallySpaced>
      { displayValidation && <FormResponseErrors localisedErrors={ localisedErrors }/> }
    </>
  );
};
