import {
  ChangeEvent,
  forwardRef,
  InputHTMLAttributes,
  PropsWithChildren,
  RefAttributes,
  useCallback,
  useMemo,
  useState
} from 'react';
import PhoneInput from 'react-phone-number-input';
import { TextField } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { Heading } from '@ourpeople/shared/Core/Component/Content';
import {
  ValidationErrorHandler,
  ValidationErrorHandlerFactory
} from 'op-storybook/lib/utility/ValidationErrorHandlerFactory/ValidationErrorHandlerFactory';

import { PersonWithSensitiveData, VerifiableEmailAddress } from '../../../Models';
import { NoNumberWarning, StyledCountryCodeNote, StyledDetailsForm } from './styles';
import AlertIcon from '../../../Assets/img/icons/monochrome/alert.svg';
import { FieldValidationErrors } from '../../../Common/Component';
import { NotUniqueError, ValidationTree } from '../../../Common/Model';
import { PersonForCreating } from '../../Model';
import { CustomField } from '../../Hook';
import { ArrayHelper } from '../../../Common/Utility';
import { CustomFieldInput } from '../CustomFieldInput/CustomFieldInput';
import { ValidationErrorIdentifier } from '../../../Common/Utility/ValidationErrorIdentifier';
import { VerifiableEmailField } from '../VerifiableEmailField/VerifiableEmailField';

interface Props {
  defaultCountryCode: string;
  person: Partial<PersonWithSensitiveData>;
  onChange: (person: Partial<PersonWithSensitiveData>) => void;
  customFields: CustomField[];
  onCustomFieldsChange: (customFields: CustomField[]) => void;
  validation?: ValidationTree<PersonForCreating>;
}

const MAX_EMAIL_ADDRESSES = 2;

export const DetailsFormFields = ({
  defaultCountryCode,
  person,
  onChange,
  customFields = [],
  onCustomFieldsChange,
  validation,
}: PropsWithChildren<Props>): JSX.Element => {
  const intl = useIntl();
  const [localCountryCode, setLocalCountryCode] = useState<string>();
  const personHasNonBlankEmailAddress = (person.emailAddresses || []).find(emailAddress => !!emailAddress.emailAddress);
  const firstNameLabel = intl.formatMessage({
    description: 'Label for first name field in person edit form.',
    defaultMessage: 'First name',
  });
  const lastNameLabel = intl.formatMessage({
    description: 'Label for last name field in person edit form.',
    defaultMessage: 'Last name',
  });
  const mobileNumberLabel = intl.formatMessage({
    description: 'Label for mobile number field in person edit form.',
    defaultMessage: 'Mobile number',
  });
  const emailAddresses: (undefined|VerifiableEmailAddress)[] = (person.emailAddresses || []).length < MAX_EMAIL_ADDRESSES
    ? [
      ...(person.emailAddresses || []),
      ...(Array(MAX_EMAIL_ADDRESSES - (person.emailAddresses || []).length)
        .fill(undefined)) as undefined[],
    ]
    : person.emailAddresses || [];
  const getCustomUserNameErrorHandlers = useCallback((subjectName: string) => [
    ValidationErrorHandlerFactory.createHandler(
      (error): error is NotUniqueError => (
        ValidationErrorIdentifier.isNotUniqueError(error)
      ),
      () => intl.formatMessage({
        description: 'Validation message on people page when mobile number or email address is not unique.',
        defaultMessage: '{ subjectName } is already in use.',
      }, {
        subjectName,
      }),
    )
  ] as ValidationErrorHandler[], [intl]);
  const phoneNumberComponent = useMemo(() => {
    return forwardRef((props: InputHTMLAttributes<HTMLInputElement> & RefAttributes<unknown>, ref) => (
      <TextField
        autoComplete={ props.autoComplete }
        inputProps={ {
          id: props.id,
          type: props.type
        } }
        inputRef={ ref }
        variant="outlined"
        size="small"
        disabled={ props.disabled }
        fullWidth={ true }
        onChange={ props.onChange }
        value={ props.value || '' }
      />
    ))
  }, []);

  const whenFirstNameChanged = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    const { value } = event.target;
    if (person.firstName !== value) {
      onChange({ ...person, firstName: value });
    }
  };

  const whenLastNameChanged = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    const { value } = event.target;
    const preparedValue = value.trim() ? value.trim() : null;
    if (person.lastName !== preparedValue) {
      onChange({ ...person, lastName: preparedValue });
    }
  };

  const whenEmailAddressChanged = (updatedEmailAddress: VerifiableEmailAddress, emailAddressIndex: number): void => (
    onChange({
      ...person,
      emailAddresses: ArrayHelper.replace(person.emailAddresses || [], emailAddressIndex, updatedEmailAddress),
    })
  );

  const whenMobileNumberChanged = (value: string | undefined): void => {
    const preparedValue = value?.trim() ? value.trim() : null;
    if (person.mobileNumber !== preparedValue) {
      onChange({ ...person, mobileNumber: preparedValue });
    }
  };

  const whenCountryChanged = (value?: string): void => {
    setLocalCountryCode(value);
  };

  const whenCustomFieldChanged = useCallback((customField: CustomField) => {
    const customFieldIndex = customFields.findIndex(existingCustomField => existingCustomField.key === customField.key);

    onCustomFieldsChange(
      customFieldIndex === -1
        ? customFields.concat(customField)
        : ArrayHelper.replace(customFields, customFieldIndex, customField)
    );
  }, [customFields, onCustomFieldsChange]);

  return (
    <StyledDetailsForm>
      <div>
        <Heading type="h4">
          <label htmlFor={ FIRST_NAME_FIELD_ID }>
            { firstNameLabel }
          </label>
        </Heading>
        <TextField
          id={ FIRST_NAME_FIELD_ID }
          name="firstName"
          variant="outlined"
          size="small"
          disabled={ person.externallyManaged }
          fullWidth={ true }
          onChange={ whenFirstNameChanged }
          value={ person.firstName || '' }
        />
        <FieldValidationErrors
          fieldName={ firstNameLabel }
          validationErrors={ validation?.children.firstName?.errors || [] }
        />
      </div>
      <div>
        <Heading type="h4">
          <label htmlFor={ LAST_NAME_FIELD_ID }>
            { lastNameLabel }
          </label>
        </Heading>
        <TextField
          id={ LAST_NAME_FIELD_ID }
          variant="outlined"
          size="small"
          disabled={ person.externallyManaged }
          fullWidth={ true }
          onChange={ whenLastNameChanged }
          value={ person.lastName || '' }
        />
        <FieldValidationErrors
          fieldName={ lastNameLabel }
          validationErrors={ validation?.children.lastName?.errors || [] }
        />
      </div>
      <div>
        <Heading type="h4">
          <label htmlFor={ MOBILE_NUMBER_FIELD_ID }>
            { mobileNumberLabel }
          </label>
        </Heading>
        <PhoneInput
          id={ MOBILE_NUMBER_FIELD_ID }
          defaultCountry={ defaultCountryCode }
          countryOptionsOrder={ [
            'GB',
            'US',
            'CA',
            '|'
          ] }
          flagUrl="/assets/flags/{XX}.svg"
          displayInitialValueAsLocalNumber={ true }
          inputComponent={ phoneNumberComponent }
          disabled={ person.externallyManaged }
          onCountryChange={ whenCountryChanged }
          onChange={ whenMobileNumberChanged }
          value={ person.mobileNumber || '' }
        />
        { localCountryCode && ['GB', 'US', 'CA'].indexOf(localCountryCode) === -1 && (
          <StyledCountryCodeNote>
            <FormattedMessage
              id="person.edit.countryCodeNote"
              defaultMessage="Note: OurPeople will currently only send SMS to UK, US and Canadian numbers."
            />
          </StyledCountryCodeNote>
        ) }
        <FieldValidationErrors
          fieldName={ mobileNumberLabel }
          customHandlers={ getCustomUserNameErrorHandlers(mobileNumberLabel) }
          validationErrors={ validation?.children.mobileNumber?.errors || [] }
        />
      </div>
      { emailAddresses.map((emailAddress, emailAddressIndex) => (
        <div>
          <Heading type="h4">
            <label htmlFor={ 'emailAddress' + emailAddressIndex.toString() }>
              {
                intl.formatMessage(
                {
                  description: 'Label for email field in person edit form.',
                  defaultMessage: 'Email address { emailAddressNumber, plural, =1 {} other {#} }',
                },
                {
                  emailAddressNumber: emailAddressIndex + 1,
                })
              }
            </label>
          </Heading>
          <VerifiableEmailField
            id={ 'emailAddress' + emailAddressIndex.toString() }
            key={ emailAddressIndex }
            emailAddress={ emailAddress }
            disableSendVerification={ true }
            onChange={ (updatedEmailAddress) => whenEmailAddressChanged(updatedEmailAddress, emailAddressIndex) }
            disabled={ !!person.externallyManaged }
            validation={ validation?.children.emailAddresses?.children[emailAddressIndex] }
            variant="outlined"
            size="small"
          />
        </div>
      )) }
      { !person.mobileNumber && !personHasNonBlankEmailAddress && (
        <NoNumberWarning>
          <AlertIcon/>
          <span>
            <FormattedMessage
              id="person.edit.mobileNumberAndEmailAddressWarning"
              description="Warning when user is submitting a blank phone number and email address"
              defaultMessage="People without a mobile number or email address will be unable to log in or receive invitations."
            />
          </span>
        </NoNumberWarning>
      ) }
      <CustomFieldInput
        label={ intl.formatMessage({
          description: 'Label for employee id field',
          defaultMessage: 'Employee ID',
        }) }
        customField={ customFields.find(customField => customField.key === 'employeeId') || employeeIdDefaultValue }
        onChange={ whenCustomFieldChanged }
        disabled={ person.externallyManaged }
      />
    </StyledDetailsForm>
  );
};

const employeeIdDefaultValue = {
  key: 'employeeId',
  value: '',
};

const MOBILE_NUMBER_FIELD_ID = 'phone-number';
const FIRST_NAME_FIELD_ID = 'first-name';
const LAST_NAME_FIELD_ID = 'last-name';
