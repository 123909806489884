import { Box } from '@mui/material';
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';

export const StyledPresentationIcon = styled(Box, { shouldForwardProp: isPropValid })<{ spacingMultiplier: number }>`
  > svg {
    width: ${ props => props.theme.spacing(props.spacingMultiplier) };
    height: ${ props => props.theme.spacing(props.spacingMultiplier) };
  }
`;
