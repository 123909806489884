import { FC } from 'react';
import { useIntl } from 'react-intl';

import { CardDivider, EventInformation } from '..';
import CalendarIcon from '../../../../Assets/img/icons/coloured/calendar.svg';
import ClockIcon from '../../../../Assets/img/icons/coloured/clock.svg';
import PinIcon from '../../../../Assets/img/icons/coloured/pin.svg';
import { VerticallySpaced } from '../../../../Common/Component';
import { DraftEvent } from '../../../Model';

type Props = {
  event: DraftEvent;
};

export const EventDateAndLocation: FC<Props> = ({
  event,
}) => {
  const intl = useIntl();
  const startDate = new Date(event.start_at.dt).toLocaleDateString(navigator.language, { weekday: 'short', day: 'numeric', month: 'short' });
  const timeRange = `
    ${ new Date(event.start_at.dt).toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' }) }
    -
    ${ new Date(event.end_at.dt).toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' }) }
  `;

  return (
    <VerticallySpaced gap={ 1 }>
      <CardDivider/>
      <EventInformation
        label={ intl.formatMessage({
          id: 'broadcasts.editor.preview.event.date',
          description: 'Label for date field in event card preview.',
          defaultMessage: 'Date',
        }) }
        IconComponent={ CalendarIcon }
      >
        { startDate }
      </EventInformation>
      <EventInformation
        label={ intl.formatMessage({
          id: 'broadcasts.editor.preview.event.time',
          description: 'Label for time field in event card preview.',
          defaultMessage: 'Time',
        }) }
        IconComponent={ ClockIcon }
      >
        { timeRange }
      </EventInformation>
      <EventInformation
        label={ intl.formatMessage({
          id: 'broadcasts.editor.preview.event.location',
          description: 'Label for location field in event card preview.',
          defaultMessage: 'Location',
        }) }
        IconComponent={ PinIcon }
      >
        { event.location }
      </EventInformation>
      <CardDivider/>
    </VerticallySpaced>
  );
};
