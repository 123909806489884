import styled from '@emotion/styled';
import { rgba } from 'polished';

import AddIcon from '../../../../Assets/img/icons/streamline/add.svg';
import { QuizCardState } from './QuizRequestPreview';
import { css } from '../../../../Styling/css';

type StyledQuizCheckboxProps = {
  $revealed: boolean;
}

const checkboxBase = css<StyledQuizCheckboxProps>`
  ${ props => !props.$revealed && 'opacity: 0;' }
  border-radius: 999rem;
  width: ${ props => props.theme.spacing(3) };
  height: ${ props => props.theme.spacing(3) };
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${ props => props.theme.palette.text.primary };

  svg {
    width: ${ props => props.theme.spacing(1.5) };
    height: ${ props => props.theme.spacing(1.5) };
  }
`;

export const StyledCorrectCheckbox = styled.div<StyledQuizCheckboxProps>`
  ${ checkboxBase };
  background-color: ${ props => props.theme.palette.success.main };
  color: ${ props => props.theme.palette.success.contrastText };
`;

export const StyledIncorrectCheckbox = styled.div<StyledQuizCheckboxProps>`
  ${ checkboxBase };
  background-color: ${ props => props.theme.palette.error.main };
  color: ${ props => props.theme.palette.error.contrastText };
`;

export const StyledQuizRequestBackground = styled.div<{ state: QuizCardState }>`
  width: 100%;
  height: 100%;
  position: absolute;

  background-color: ${ props => props.state === QuizCardState.CORRECT_SELECTED
    ? rgba(props.theme.palette.success.light, 0.2)
    : props.state === QuizCardState.INCORRECT_SELECTED
      ? rgba(props.theme.palette.error.main, 0.2)
      : 'transparent'
  };
`;

export const StyledCrossIcon = styled(AddIcon)`
  transform: rotate(45deg);
`;
