import { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { LocalisedString } from 'op-storybook/lib/model/LocalisedString/LocalisedString';

import { BasicAutocomplete, BasicAutocompleteProps } from '../../../Common/Component';
import { ChatStatus, useLocalisedChatStatuses } from '../../Hook';

type Props = Omit<BasicAutocompleteProps<ChatStatus>, 'options' | 'placeholder' | 'label'>;

export const ChatStatusAutocomplete: FC<Props> = props => {
  const intl = useIntl();
  const localisedStatuses = useLocalisedChatStatuses();
  const options: LocalisedString<ChatStatus>[] = useMemo(
    () => (Object.entries(localisedStatuses) as [ChatStatus, string][])
      .map(([status, localisedStatus]) => ({
        id: status,
        localisation: localisedStatus,
      })),
    [localisedStatuses],
  );

  return (
    <BasicAutocomplete
      options={ options }
      label={ intl.formatMessage({
        id: 'chatStatusAutocomplete.label',
        description: 'Label for chat status autocomplete',
        defaultMessage: 'Status',
      }) }
      placeholder={ intl.formatMessage({
        id: 'chatStatusAutocomplete.placeholder',
        description: 'Placeholder for chat status autocomplete',
        defaultMessage: 'Search…',
      }) }
      { ...props }
    />
  );
};
