import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { ApiContext } from '../Contexts';

export type UseCancellableRequestReturnValue<U = undefined> = [
  (data?: U) => void,
  boolean,
];

export const useCancellableRequest = <T, U = unknown>(
  request: AxiosRequestConfig,
  onSuccess?: (response: AxiosResponse<T>) => void,
  onFail?: (error: AxiosError<unknown>) => void,
): UseCancellableRequestReturnValue<U> => {
  const api = useContext(ApiContext);
  const [willRequest, setWillRequest] = useState<boolean>(false);
  const [data, setData] = useState<U | undefined>(undefined);
  const requestWithData = useMemo(() => ({
    ...request,
    data,
  }), [request, data]);

  useEffect(() => {
    if (!api || !willRequest) {
      return;
    }

    let cancelled = false;
    api.request<T>(requestWithData)
      .then(response => {
        if (cancelled) {
          return;
        }

        setWillRequest(false);
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((error: AxiosError) => {
        if (cancelled) {
          return;
        }

        setWillRequest(false);
        if (onFail) {
          onFail(error);
        }
      });

    return () => {
      cancelled = true;
    };
  }, [api, onFail, onSuccess, requestWithData, willRequest]);

  const makeRequest = useCallback(
    (data?: U) => {
      if (data) {
        setData(data);
      }
      setWillRequest(true);
    },
    [],
  );

  return useMemo<UseCancellableRequestReturnValue<U>>(
    () => [
      makeRequest,
      willRequest,
    ],
    [makeRequest, willRequest],
  );
};
