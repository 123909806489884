import { IntlShape } from 'react-intl';

import { BroadcastContentDefinition, DraftMediaContent, MediaContent, MediaEditorContent } from '../../../Model';
import { SingleContentCard } from '../../../../Content/Model';
import AudioIcon from '../../../../Assets/img/icons/monochrome/content/audio.svg';
import { AudioContentEditor, AudioContentPreview } from '../../../Component';
import { MediaContentValidator } from '../../Validators';
import { MediaContentHelper } from '../../../Utility';
import { LiveAudioContent } from '../../../../Inbox/Component';
import { ViewResponse } from '../../../../Inbox/Model';
import { EnvironmentSettings } from '../../../../Models';

export type DraftAudioContent = DraftMediaContent<'audiocontent'>;
export type AudioContent = MediaContent<'audiocontent'>
export type AudioEditorContent = MediaEditorContent<'audiocontent'>

const contentType = 'audiocontent';
export class AudioContentDefinition implements BroadcastContentDefinition<AudioContent, AudioEditorContent, ViewResponse> {
  public readonly id = 'audio';
  public readonly availableToCreate = true;
  public readonly new = false;
  public readonly richText = false;
  public readonly contentTypes = [contentType];
  public readonly categoryId = 'content';
  public readonly IconComponent = AudioIcon;
  public readonly EditorComponent = AudioContentEditor;
  public readonly PreviewComponent = AudioContentPreview;
  public readonly LiveComponent = LiveAudioContent;
  public readonly SubmittedComponent = (): null => null;
  public validate = MediaContentValidator.validate;

  public definesContentType = (type: string): boolean => (
    this.contentTypes.includes(type)
  );

  public getContentTitle = (intl: IntlShape, content: AudioContent): string => (
    content.text || this.getDefaultTitle(intl)
  );

  public getLocalisedType = (intl: IntlShape): string => (
    intl.formatMessage({
      id: 'broadcasts.content.audio.type',
      description: 'Localised name for audio content type.',
      defaultMessage: 'Audio',
    })
  );

  public getDraftContentTitle = (intl: IntlShape, draftContent: DraftAudioContent): string => (
    draftContent.text || this.getDefaultTitle(intl)
  );

  public hasRequiredFeatureFlags = (environmentSettings: EnvironmentSettings): boolean => (
    !!environmentSettings.audioTypeEnabled
  );

  public createEditorContent = (): AudioEditorContent => MediaContentHelper.createEditorContent(contentType);

  public transformCard = (card: SingleContentCard<AudioContent>): AudioEditorContent => MediaContentHelper.transformCard(contentType, card);

  private getDefaultTitle = (intl: IntlShape): string => (
    intl.formatMessage({
      id: 'broadcasts.content.audio.defaultTitle',
      description: 'Default title for audio content.',
      defaultMessage: 'Untitled audio card',
    })
  );
}
