import { FormattedMessage } from 'react-intl';
import { FC } from 'react';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';

import { PagingMenuHeader } from '../PagingMenuHeader/PagingMenuHeader';
import { PagingMenuBackButton } from '../PagingMenuBackButton/PagingMenuBackButton';
import { PagingMenuContent } from '../PagingMenuContent/PagingMenuContent';
import { TemplatePrompt } from '../AiUserPrompt/AiUserPrompt';
import { AiTemplatePrompts } from '../AiTemplatePrompts/AiTemplatePrompts';
import { AiBetaButton } from '../AiBetaButton/AiBetaButton';

type Props = {
  templatePrompts: TemplatePrompt[];
  onBackClicked: () => void;
  onBetaButtonClicked: () => void;
  onTemplatePromptSelected: (templatePrompt: TemplatePrompt) => void;
};

export const AiTemplatePromptMenu: FC<Props> = ({
  templatePrompts,
  onBackClicked,
  onBetaButtonClicked,
  onTemplatePromptSelected,
}) => (
  <div>
    <PagingMenuHeader>
      <Stack gap={ 4 }>
        <PagingMenuBackButton
          onClick={ onBackClicked }
        />
        <FormattedMessage
          description="Title for OurProple AI templates sub-menu."
          defaultMessage="OurPeople AI Templates"
        />
      </Stack>
    </PagingMenuHeader>
    <PagingMenuContent>
      <AiTemplatePrompts
        templatePrompts={ templatePrompts }
        onTemplatePromptSelected={ onTemplatePromptSelected }
      />
      <AiBetaButton onClick={ onBetaButtonClicked }/>
    </PagingMenuContent>
  </div>
);
