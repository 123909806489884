import { FC, useMemo } from 'react';

import { StyleBuilder } from '../../../lib/model/StyleBuilder/StyleBuilder';

type Props = {
  size: 'xl' | 'lg' | 'md' | 'sm';
  elevate: boolean;
  className?: string;
};

export const AvatarFrame: FC<Props> = ({
  size,
  elevate,
  className,
  children,
}) => {
  const styles = useMemo(() => buildStyles({ size, elevate }), [elevate, size]);

  return (
    <div
      css={ styles.outerFrame }
      className={ className }
    >
      <div
        css={ styles.innerFrame }
      >
        { children }
        <div css={ styles.innerBorder }/>
      </div>
    </div>
  );
};

const buildStyles: StyleBuilder<Props> = ({
  size,
  elevate,
}) => {
  const outerFrameDimension = size === 'xl'
    ? '144px'
    : size === 'lg'
      ? '112px'
      : size === 'md'
        ? '44px'
        : '20px'
  const innerFrameDimension = elevate ? `calc(${ outerFrameDimension } - 8px)` : outerFrameDimension;

  return {
    outerFrame: theme => ({
      flexShrink: 0,
      width: outerFrameDimension,
      height: outerFrameDimension,
      fontSize: size === 'xl' ? theme.new.spacing[12] : size === 'lg' ? theme.new.spacing[10] : size === 'md' ? theme.new.spacing[5] : theme.new.spacing[2],
      borderRadius: theme.new.borderRadius.max,
      overflow: 'hidden',
      backgroundColor: elevate ? theme.new.basePalette.white.main : 'transparent',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ...elevate
        ? {
          boxShadow: theme.new.shadow.lg,
        }
        : {},
    }),
    innerFrame: theme => ({
      position: 'relative',
      width: innerFrameDimension,
      height: innerFrameDimension,
      borderRadius: theme.new.borderRadius.max,
      overflow: 'hidden',
      margin: 'auto',
    }),
    innerBorder: theme => ({
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      opacity: 0.08,
      borderRadius: theme.new.borderRadius.max,
      border: `1px solid ${ theme.new.palette.grey[900].main }`,
      pointerEvents: 'none',
    }),
  };
};
