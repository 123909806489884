import React, { FunctionComponent } from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { MetricContainer, MissingBroadcastMetricMessage, TableMetric } from '..';
import { TopEngagedBroadcastsMetric } from '../../Model';
import { TableCell, TableRow } from '../../../Components';

interface Props {
  metric: TopEngagedBroadcastsMetric;
}

export const TopPerformingBroadcastsMetric: FunctionComponent<Props> = ({
  metric,
}) => {
  const intl = useIntl();

  return (
    <MetricContainer
      title={ intl.formatMessage({
        id: 'dashboard.topPerformingBroadcasts.heading',
        defaultMessage: 'Top performing broadcasts'
      }) }
    >
      {
        metric.datasets.length
          ? (
            <TableMetric
              headerRow={
                <TableRow>
                  <TableCell>
                    <FormattedMessage
                      id="dashboard.topPerformingBroadcasts.broadcast"
                      description="Heading for broadcast column in top performing broadcasts table on dashboard."
                      defaultMessage="Broadcast"
                    />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage
                      id="dashboard.topPerformingBroadcasts.engagement"
                      description="Heading for engagement column in top performing broadcasts table on dashboard."
                      defaultMessage="Engagement"
                    />
                  </TableCell>
                </TableRow>
              }
              rows={ metric.datasets }
              rowRender={ dataset => (
                <TableRow key={ dataset.key }>
                  <TableCell>
                    <Link to={ `/broadcasts/${ dataset.metadata.broadcast.id }/report?delivery=${ dataset.key }` }>
                      { dataset.metadata.broadcast.name }
                    </Link>
                  </TableCell>
                  <TableCell>
                    {/* eslint-disable react/style-prop-object */ }
                    <FormattedNumber
                      value={ dataset.data[0]?.value || 0 }
                      style="percent"
                    />
                    {/* eslint-enable react/style-prop-object */ }
                  </TableCell>
                </TableRow>
              ) }
            />
          )
          : <MissingBroadcastMetricMessage/>
      }
    </MetricContainer>
  );
};
