import { ContextCreator } from '@ourpeople/shared/Core/Utility/ContextCreator';

import { Upload } from '../../Types';
import { FileUploadError } from '../Component';
import { RequestState } from '../../Models';
import { AuthoriseData } from '../Hook';

export interface UploadState {
  ref: string;
  progress: number;
  file: {
    name: string;
    size?: number | null;
    width?: number | null;
    height?: number | null;
  };
  upload?: Upload;
  error?: FileUploadError;
  globalUpload?: boolean;
  metadata?: Record<string, unknown>;
}

export type UploadCompleteCallback = (
  upload: Upload,
  onCallbackComplete: () => void,
  onCallbackFailed: () => void,
) => void;

export type FileUploader = {
  upload: (
    ref: string,
    authoriseEndpoint: string,
    file: File,
    authoriseData?: AuthoriseData,
  ) => () => void;
  startGlobalUpload: (
    ref: string,
    authoriseEndpoint: string,
    file: File,
    onComplete: UploadCompleteCallback,
    metadata?: Record<string, unknown>,
  ) => () => void;
  uploadStates: Map<string, UploadState>;
  remove: (ref: string) => void;
  addCompletedUpload: (ref: string, upload: Upload) => void;
  getCallbackState: (ref: string) => RequestState | undefined;
};

export const FileUploaderContext = ContextCreator.withDisplayName<FileUploader | null>('FileUploaderContext', null);
