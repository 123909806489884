import { FunctionComponent } from 'react';
import { AutocompleteSelectionChanged } from '@ourpeople/shared/Core/Component/Input/Autocomplete/Autocomplete';

import { TagAutocomplete } from '../../../Tags/Component';
import { Tag, TagType } from '../../../Tags/Model';

type Props = {
  disabled?: boolean;
  allowedTeamIds: string[];
  teamIds: string[];
  onTeamsChange: AutocompleteSelectionChanged<Tag<TagType.Team>>;
}

export const AdministratedTeamsFields: FunctionComponent<Props> = ({
  allowedTeamIds,
  teamIds,
  onTeamsChange,
  disabled,
}) => {
  return (
    <>
      <TagAutocomplete
        type={TagType.Team}
        showDisallowed
        allowedIds={allowedTeamIds}
        selectedIds={teamIds}
        onSelectionChanged={onTeamsChange}
        multiple={true}
        disabled={disabled}
      />
    </>
  );
}
