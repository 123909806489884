import { CSSProperties, FC } from 'react';
import { FunctionInterpolation } from '@emotion/react';

import radialMask from '../../../lib/assets/ImageMask/radial.svg?url';
import spotlightMask from '../../../lib/assets/ImageMask/spotlight.svg?url';
import { defaultTheme } from '../../const/defaultTheme';

type Props = {
  imageUrl: string;
  mask: 'spotlight' | 'radial';
  className?: string;
  style?: CSSProperties;
};

export const MaskedBackgroundImage: FC<Props> = ({
  imageUrl,
  mask,
  className,
  style,
}) => (
  <div
    className={ className }
    style={ {
      ...style,
      '--background-image': `url(${ imageUrl })`,
      '--mask-image': `url(${ mask === 'radial' ? radialMask : spotlightMask })`,
    } }
    css={ backgroundCss }
  />
);

const backgroundCss: FunctionInterpolation<typeof defaultTheme> = () => ({
  position: 'absolute',
  backgroundImage: 'var(--background-image)',
  maskImage: 'var(--mask-image)',
  maskSize: '100%',
  maskRepeat: 'no-repeat',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100%',
  width: '100%',
  height: '100%',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%)',
});
