import { FC, useMemo } from 'react';
import { ChartData } from 'chart.js';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme } from '@emotion/react';
import { Heading } from '@ourpeople/shared/Core/Component/Content';

import { BarChartMetric } from '../../../../Reporting/Component';
import {
  BroadcastReceivedNotification
} from '../../../../Sections/Broadcasts/Report/Hooks/useFetchBroadcastReportSummary';
import { StyledNotificationDeliveryChart } from './style';

type Props = {
  notificationCounts: BroadcastReceivedNotification[];
};

export const NotificationDeliveryChart: FC<Props> = ({
  notificationCounts,
}) => {
  const intl = useIntl();
  const theme = useTheme();
  const localisedLabels = useMemo(() => new Map([
    [
      'sms', intl.formatMessage({
      id: 'broadcasts.report.notificationChannel.sms',
      description: 'Label for SMS notification channel in notification delivery chart.',
      defaultMessage: 'Text',
    })
    ],
    [
      'email', intl.formatMessage({
      id: 'broadcasts.report.notificationChannel.email',
      description: 'Label for email notification channel in notification delivery chart.',
      defaultMessage: 'Email',
    })
    ],
  ]), [intl]);

  const chartData = useMemo<Required<ChartData<'bar'>>>(() => {
    return notificationCounts
      .filter(notificationChannelCounts => notificationChannelCounts.service !== 'push')
      .reduce<Required<ChartData<'bar'>>>(
        (accumulatedChartData: Required<ChartData<'bar'>>, notificationChannelCounts) => ({
          datasets: [
            {
              ...accumulatedChartData.datasets[0],
              data: accumulatedChartData.datasets[0].data.concat(notificationChannelCounts.delivered)
            }, {
              ...accumulatedChartData.datasets[1],
              data: accumulatedChartData.datasets[1].data.concat(notificationChannelCounts.total - notificationChannelCounts.delivered)
            }
          ],
          labels: accumulatedChartData.labels.concat(localisedLabels.get(notificationChannelCounts.service) || notificationChannelCounts.service),
        }),
        {
          datasets: [
            {
              label: intl.formatMessage({
                id: 'broadcasts.report.notificationChannel.delivered',
                description: 'Label for delivered count in notification delivery chart.',
                defaultMessage: 'Delivered',
              }),
              barThickness: 20,
              backgroundColor: theme.palette.secondary.main,
              data: [],
            },
            {
              label: intl.formatMessage({
                id: 'broadcasts.report.notificationChannel.notDelivered',
                description: 'Label for not delivered count in notification delivery chart.',
                defaultMessage: 'Not delivered',
              }),
              barThickness: 20,
              backgroundColor: theme.palette.secondary.light,
              data: [],
            },
          ],
          labels: [],
        },
      );
  }, [intl, localisedLabels, notificationCounts, theme.palette.secondary.light, theme.palette.secondary.main]);

  return (
    <StyledNotificationDeliveryChart>
      <Heading type="h3">
        <FormattedMessage
          id="broadcasts.report.notificationChannel.heading"
          description="Heading for notification channel report."
          defaultMessage="Delivery success by channel"
        />
      </Heading>
      <div>
        <BarChartMetric
          chartData={ chartData }
          ungroupBars
          horizontal
          ignoreAspectRatio
        />
      </div>
    </StyledNotificationDeliveryChart>
  );
};
