import rehype from 'rehype';
import { Node, Literal, Parent } from 'unist';
import { sanitize } from 'dompurify';

export class RichTextSanitiser {
  public static stripTags = (input: string): string => {
    return RichTextSanitiser.stripTagsFromAst(rehype().parse(input));
  };

  public static stripTagsFromAst = (tree: Node, hasSibling?: boolean, parentTag?: string): string => {
    if ((tree as unknown as Element).tagName === 'br') {
      return '\n';
    }

    if ((tree as Parent).children) {
      return (tree as Parent).children.map((node, index) => (
        RichTextSanitiser.stripTagsFromAst(
          node,
          hasSibling || index !== (tree as Parent).children.length - 1,
          tree.type === 'element'
            ? (tree as unknown as Element).tagName
            : undefined
        )
      )).join('');
    } else if (tree.type === 'text') {
      return `${(tree as Literal).value as string}${ parentTag && ['h1', 'p'].includes(parentTag) && hasSibling ? '\n' : ''}`;
    }
    return '';
  };

  public static sanitise = (value: string): string => (
    sanitize(
      value,
      {
        ALLOWED_ATTR: ['href'],
        ALLOWED_TAGS: ['ul', 'li', 'ol', 'strong', 'em', 'a', 'p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'br', 'b', 'i'],
      }
    )
  );
}
