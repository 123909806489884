import React, { FC, ReactChildren, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { TableCell, TableRow } from '../../../../Components';
import { BroadcastCategory, BroadcastsPermission } from '../../../Model';
import { GuardedLink } from '../../../../Security/Component';
import { NonWrappingCell } from '../../../../Forms/Component';
import { TableRowContextMenu } from '../../../../Common/Component/TableRowContextMenu/TableRowContextMenu';
import EditIcon from '../../../../Assets/img/icons/streamline/common-file-edit.svg';
import UnpublishIcon from '../../../../Assets/img/icons/streamline/button-stop.svg';
import PublishIcon from '../../../../Assets/img/icons/streamline/button-play-1.svg';
import DeleteIcon from '../../../../Assets/img/icons/streamline/bin-1.svg';
import { usePermissions } from '../../../../Security/Hook';
import { useApi, useContextOrThrow } from '../../../../Core/Hook';
import { useMounted } from '../../../../Common/Hook';
import { CategoryDeleteDialog } from '..';
import { ToastContext } from '../../../../Core/Context';

type Props = {
  category: BroadcastCategory;
  onUpdate: () => void;
};

export const CategoriesTableRow: FC<Props> = ({
  category,
  onUpdate,
}) => {
  const intl = useIntl();
  const api = useApi();
  const mounted = useMounted();
  const { addSuccessToast, addErrorToast } = useContextOrThrow(ToastContext);
  const { permissionAvailable } = usePermissions();
  const [publishing, setPublishing] = useState<boolean>(false);
  const [unpublishing, setUnpublishing] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [deleteConfirmationMounted, setDeleteConfirmationMounted] = useState<boolean>(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState<boolean>(false);

  const whenPublishClicked = useCallback(() => {
    setPublishing(true);
    api.post(`/broadcasts/categories/${ category.id }/publish`)
      .then(() => {
        setTimeout(() => {
          if (!mounted.current) {
            return;
          }

          addSuccessToast(
            intl.formatMessage({
              id: 'categoriesTableRow.publish.success',
              description: 'Message when category is published successfully.',
              defaultMessage: '{ categoryName } has been published',
            }, {
              categoryName: category.name,
            })
          );
          setPublishing(false);
          onUpdate();
        }, 50);
      })
      .catch(() => {
        if (!mounted.current) {
          return;
        }

        addErrorToast(
          intl.formatMessage({
            id: 'categoriesTableRow.publis.error',
            description: 'Message when category cannot be published.',
            defaultMessage: '{ categoryName } could not be published',
          }, {
            categoryName: category.name,
          })
        );
        setPublishing(false);
      });
  }, [api, category.id, category.name, mounted, addSuccessToast, intl, onUpdate, addErrorToast]);

  const whenUnpublishClicked = useCallback(() => {
    setUnpublishing(true);
    api.post(`/broadcasts/categories/${ category.id }/unpublish`)
      .then(() => {
        setTimeout(() => {
          if (!mounted.current) {
            return;
          }

          addSuccessToast(
            intl.formatMessage({
              id: 'categoriesTableRow.unpublish.success',
              description: 'Message when category is unpublished successfully.',
              defaultMessage: '{ categoryName } has been unpublished',
            }, {
              categoryName: category.name,
            })
          );
          setUnpublishing(false);
          onUpdate();
        }, 50);
      })
      .catch(() => {
        if (!mounted.current) {
          return;
        }

        addErrorToast(
          intl.formatMessage({
            id: 'categoriesTableRow.unpublish.error',
            description: 'Message when category cannot be unpublished.',
            defaultMessage: '{ categoryName } could not be unpublished',
          }, {
            categoryName: category.name,
          })
        );
        setUnpublishing(false);
      });
  }, [api, category.id, category.name, mounted, addSuccessToast, intl, onUpdate, addErrorToast]);

  const whenDeleteConfirmed = useCallback((newCategoryId: string) => {
    setDeleteConfirmationOpen(false);
    setDeleting(true);

    api.post(
      '/broadcasts/categories/reassign',
      { oldCategoryId: category.id, newCategoryId },
    )
      .then(() => api.delete(`/broadcasts/categories/${ category.id }`))
      .then(() => {
        setTimeout(() => {
          if (!mounted.current) {
            return;
          }

          addSuccessToast(
            intl.formatMessage({
              id: 'categoriesTableRow.delete.success',
              description: 'Message when category is deleted successfully.',
              defaultMessage: '{ categoryName } has been deleted',
            }, {
              categoryName: category.name,
            })
          );
          setDeleting(false);
          onUpdate();
        }, 50);
      })
      .catch(() => {
        if (!mounted.current) {
          return;
        }

        addErrorToast(
          intl.formatMessage({
            id: 'categoriesTableRow.delete.error',
            description: 'Message when category cannot be deleted.',
            defaultMessage: '{ categoryName } could not be deleted',
          }, {
            categoryName: category.name,
          })
        );
        setDeleting(false);
        onUpdate();
      });
  }, [addErrorToast, addSuccessToast, api, category.id, category.name, intl, mounted, onUpdate]);

  return <>
    <TableRow>
      <TableCell>
        {
          permissionAvailable(BroadcastsPermission.CATEGORIES_UPDATE)
            ? (
              <GuardedLink
                to={ `/broadcasts/categories/${ category.id }/edit` }
                permissions={ [BroadcastsPermission.CATEGORIES_UPDATE] }
              >
                { category.name }
              </GuardedLink>
            )
            : category.name
        }
      </TableCell>
      <TableCell>
        {
          category.contentBroadcastDefault
            ? category.eventBroadcastDefault
              ? (
                <FormattedMessage
                  id="broadcasts.listCategories.allDefault"
                  description="Text displayed alongside default category for both event and non-event broadcasts in broadcast category table."
                  defaultMessage="Default category for all broadcasts. <a>Manage default categories</a>"
                  values={ {
                    a: (chunks: ReactChildren) => <a href="#defaults">{ chunks }</a>
                  } }
                />
              )
              : (
                <FormattedMessage
                  id="broadcasts.listCategories.nonEventDefaultFlag"
                  description="Text displayed alongside non-event default category in broadcast category table."
                  defaultMessage="Default category for any broadcast with no event cards. <a>Manage default categories</a>"
                  values={ {
                    a: (chunks: ReactChildren) => <a href="#defaults">{ chunks }</a>
                  } }
                />
              )
            : category.eventBroadcastDefault && (
            <FormattedMessage
              id="broadcasts.listCategories.eventDefaultFlag"
              description="Text displayed alongside event default category in broadcast category table."
              defaultMessage="Default category for any broadcast with at least one event card. <a>Manage default categories</a>"
              values={ {
                a: (chunks: ReactChildren) => <a href="#defaults">{ chunks }</a>
              } }
            />
          )
        }
      </TableCell>
      <NonWrappingCell>
        { permissionAvailable(BroadcastsPermission.CATEGORIES_UPDATE) && (
          <TableRowContextMenu
            id={ `${ category.id }_menu` }
            inlineActionCount={ 1 }
            actions={ [
              {
                id: 'edit',
                IconComponent: EditIcon,
                label: intl.formatMessage({
                  id: 'broadcasts.listCategories.edit',
                  description: 'Label for edit button in broadcast categories table.',
                  defaultMessage: 'Edit',
                }),
                component: GuardedLink,
                to: `/broadcasts/categories/${ category.id }/edit`,
                permissions: [BroadcastsPermission.CATEGORIES_UPDATE],
              },
              ...(!category.contentBroadcastDefault && !category.eventBroadcastDefault ? [
                  ...(category.published ? [
                      {
                        id: 'unpublish',
                        IconComponent: UnpublishIcon,
                        label: intl.formatMessage({
                          id: 'categoriesTable.actions.unpublish',
                          description: 'Label for unpublish action in broadcast categories table.',
                          defaultMessage: 'Unpublish',
                        }),
                        onClick: whenUnpublishClicked,
                        disabled: publishing || deleting,
                        busy: unpublishing,
                      }
                    ] : [
                      {
                        id: 'publish',
                        IconComponent: PublishIcon,
                        label: intl.formatMessage({
                          id: 'categoriesTable.actions.publish',
                          description: 'Label for publish action in broadcast categories table.',
                          defaultMessage: 'Publish',
                        }),
                        onClick: whenPublishClicked,
                        disabled: unpublishing || deleting,
                        busy: publishing,
                      }
                    ]),
                  {
                    id: 'delete',
                    IconComponent: DeleteIcon,
                    label: intl.formatMessage({
                      id: 'categoriesTable.actions.delete',
                      description: 'Label for delete action in broadcast categories table.',
                      defaultMessage: 'Delete',
                    }),
                    onClick: () => {
                      setDeleteConfirmationOpen(true);
                      setDeleteConfirmationMounted(true);
                    },
                    disabled: publishing || unpublishing,
                    busy: deleting,
                  },
                ] : []),
            ] }
          />
        ) }
      </NonWrappingCell>
    </TableRow>
    { deleteConfirmationMounted && (
      <CategoryDeleteDialog
        open={ deleteConfirmationOpen }
        onClose={ () => setDeleteConfirmationOpen(false) }
        onSubmit={ whenDeleteConfirmed }
        category={ category }
        TransitionProps={ {
          onExited: () => setDeleteConfirmationMounted(false),
        } }
      />
    ) }
  </>;
};
