import { Audience, TagCondition } from '../Model';
import { TagType } from '../../Tags/Model';
import { ConditionIdentifier } from './ConditionIdentifier';

export class AudienceManipulator {
  public static removeTagId = (audience: Audience, tagType: TagType, id: string): Audience => {
    const audienceConditions = audience.segments[0]?.conditions || [];
    const tagConditions = audienceConditions
      .filter(condition => ConditionIdentifier.conditionIsTagCondition(condition) && condition.tagType === tagType)
      .map(condition => condition as TagCondition);

    if (!tagConditions.length) {
      return audience;
    }

    const updatedTagConditions: TagCondition[] = tagConditions.map(tagCondition => ({
      ...tagCondition,
      tagIds: tagCondition.tagIds.filter(tagId => tagId !== id),
    }));

    return ({
      segments: [{
        conditions: [
          ...audienceConditions.filter(condition => !ConditionIdentifier.conditionIsTagCondition(condition) || condition.tagType !== tagType),
          ...updatedTagConditions,
        ],
      }]
    })
  };
}
