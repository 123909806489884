import { FC } from 'react';

import { ContentWithType, MediaContentPreviewWithoutButton, ScrollableContent } from '..';
import { VerticallySpaced } from '../../../../Common/Component';
import { OpenFileButton } from '../../../../Files/Component';
import { MediaEditorContent } from '../../../Model';
import { SvgComponent } from '../../../../Common/Model';

type Props = {
  editorContent: MediaEditorContent<string>;
  localisedContentType: string;
  localisedButtonLabel: string;
  IconComponent: SvgComponent;
};

export const MediaContentPreview: FC<Props> = ({
  editorContent,
  localisedContentType,
  localisedButtonLabel,
  IconComponent,
}) => (
  <ScrollableContent>
    <ContentWithType
      localisedContentType={ localisedContentType }
    >
      <VerticallySpaced
        gap={ 2 }
        align="center"
      >
        <MediaContentPreviewWithoutButton
          text={ editorContent.card.content.text }
          media={ editorContent.media || undefined }
          IconComponent={ IconComponent }
        />
        <OpenFileButton
          fileId={ editorContent.media?.id.toString() }
          variant="primary"
        >
          { localisedButtonLabel }
        </OpenFileButton>
      </VerticallySpaced>
    </ContentWithType>
  </ScrollableContent>
);
