import { FC, ReactNode, useMemo } from 'react';
import { StyleBuilder } from 'op-storybook/lib/model/StyleBuilder/StyleBuilder';

type Props = {
  desktopColumns?: number;
  className?: string;
  children?: ReactNode;
};

export const ResponsiveGrid: FC<Props> = ({
  desktopColumns = 3,
  className,
  children,
}) => {
  const styles = useMemo(() => buildStyles({ desktopColumns }), [desktopColumns]);

  return (
    <div
      css={ styles.gridContainer }
      className={ className }
    >
      { children }
    </div>
  );
};

const buildStyles: StyleBuilder<Required<Omit<Props, 'className'>>> = ({ desktopColumns }) => ({
  gridContainer: theme => ({
    display: 'grid',
    gridTemplateColumns: Array.from({ length: desktopColumns }, () => '1fr').join(' '),
    gap: theme.new.spacing[4],

    [theme.new.breakpoints.sm.down]: {
      gridTemplateColumns: '1fr',
    },
  }),
});
