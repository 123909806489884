import { useIntl } from 'react-intl';
import { LocalisedString } from 'op-storybook/lib/model/LocalisedString/LocalisedString';
import {
  useLocalisedValidationErrors
} from 'op-storybook/lib/hooks/useLocalisedValidationHandlers/useLocalisedValidationHandlers';

import { ValidationError } from '../../Common/Model';
import { useValidationErrorHandlers } from '../../Common/Hook';

export const useLocalisedResponseErrors = (
  errors: ValidationError[],
): LocalisedString[] => {
  const intl = useIntl();
  const subjectName = intl.formatMessage({
    description: 'Form response input name used in error messages.',
    defaultMessage: 'your response',
  });

  return useLocalisedValidationErrors(
    useValidationErrorHandlers(
      subjectName,
    ),
    errors,
  );
};
