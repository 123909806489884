import styled from '@emotion/styled';

export const StyledThumbnailList = styled.ol<{ loaded: boolean }>`
  margin: 0;
  padding: 0 0 ${ props => props.theme.spacing(2) } 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: ${ props => props.theme.spacing(1) };

  ${ props => !props.loaded && `
      opacity: 0;
      pointer-events: none;
      position: absolute;
  ` }
`;

export const StyledThumbnail = styled.li<{ selected: boolean }>`
  border: 3px solid transparent;
  ${ props => props.selected && `
    border-color: ${ props.theme.palette.primary.main };
  ` }
  position: relative;

  >div {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    max-width: 250px;
    max-height: 250px;
    border: 1px solid ${ props => props.theme.palette.background.paper };
  }
`;

export const StyledThumbnailSelector = styled.div`
  margin: 0;
`;
