import { FunctionComponent } from 'react';
import {
  StepConnectorProps
} from '@mui/material';

import AlertIcon from '../../Assets/img/icons/monochrome/alert.svg';
import {
  StyledStepper,
  StyledStep,
  StyledStepButton,
  StyledStepConnector,
  StyledStepLabel,
  StyledStepIcon,
  StyledStepIconProps,
} from './styles';

interface StepperProps {
  steps: string[];
  activeStep: number;
  erroredStepIndices?: number[];
  validStepIndices?: number[];
  handleCompleted?: (index: number, activeStep: number) => boolean;
  nonLinear?: boolean;
  onStepClick?: (step: number) => void;
}

interface StepConnectorWithActiveStepProps extends StepConnectorProps {
  activeStep: number;
  erroredStepIndices?: number[];
  validStepIndices?: number[];
  index?: number;
}

const StepConnector: FunctionComponent<StepConnectorWithActiveStepProps> = ({
  activeStep,
  erroredStepIndices = [],
  validStepIndices = [],
  ...props
}) => {
  const index = props.index || 0;
  return (
    <StyledStepConnector
      { ...props }
      completed={ activeStep >= index }
      $error={ erroredStepIndices.indexOf(index) !== -1 }
      previousStepCompleted={ index - 1 < activeStep }
      previousStepActive={ activeStep === index - 1 }
      previousStepValid={ validStepIndices.indexOf(index - 1) !== -1 }
      previousStepError={ erroredStepIndices.indexOf(index - 1) !== -1 }
    />
  );
};

export const StepIcon: FunctionComponent<StyledStepIconProps> = (
  { ref, icon, ...props }
) => {
  return (
    <StyledStepIcon icon={ icon } { ...props }>
      { icon }
    </StyledStepIcon>
  );
};

export const DeprecatedStepper: FunctionComponent<StepperProps> = ({
  activeStep,
  erroredStepIndices = [],
  validStepIndices = [],
  handleCompleted,
  nonLinear,
  onStepClick,
  steps
}) => {
  const internalHandleCompleted = (index: number, activeStep: number): boolean => {
    return (
      handleCompleted
        ? handleCompleted(index, activeStep)
        : activeStep >= index
    );
  };

  return (
    <StyledStepper
      alternativeLabel
      activeStep={ activeStep }
      connector={ (
        <StepConnector
          activeStep={ activeStep }
          erroredStepIndices={ erroredStepIndices }
          validStepIndices={ validStepIndices }
        />
      ) }
      nonLinear={ nonLinear }
    >
      {
        steps.map((label, index) => {
          const extraStepLabelProps: { error?: boolean, valid?: boolean } = {};

          if (erroredStepIndices.indexOf(index) !== -1) {
            extraStepLabelProps.error = true;
          } else if (validStepIndices.indexOf(index) !== -1) {
            extraStepLabelProps.valid = true;
          }

          return (
            <StyledStep key={ label }>
              {
                onStepClick
                  ? (
                    <StyledStepButton
                      icon={
                        <StepIcon
                          icon={ index + 1 }
                          completed={ internalHandleCompleted(index, activeStep) }
                          { ...extraStepLabelProps }
                        />
                      }
                      completed={ internalHandleCompleted(index, activeStep) }
                      { ...extraStepLabelProps }
                      onClick={ () => onStepClick(index) }
                    >
                      { erroredStepIndices.indexOf(index) !== -1 && <AlertIcon /> } { label }
                    </StyledStepButton>
                  )
                  : (
                    <StyledStepLabel
                      StepIconComponent={ StepIcon }
                      StepIconProps={ extraStepLabelProps }
                      { ...extraStepLabelProps }
                    >
                      { erroredStepIndices.indexOf(index) !== -1 && <AlertIcon /> } { label }
                    </StyledStepLabel>
                  )
              }
            </StyledStep>
          );
        })
      }
    </StyledStepper>
  );
};
