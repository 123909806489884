import styled from '@emotion/styled';
import { rgba } from 'polished';

export const StyledSidebar = styled.nav<{ slim: boolean; mobile: boolean }>`
  background-color: ${ props => props.theme.new.palette.teal[700].main };
  flex-shrink: 0;
  width: var(--sidebar-width);

  ${ props => props.theme.new.breakpoints.sm.down } {
    width: calc(100vw - ${ props => props.theme.new.spacing[16] });
  }
  
  position: fixed;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

export const StyledMainNavItemContainer = styled.div`
  overflow-y: auto;

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${ props => rgba(props.theme.new.palette.grey[300].main, 0.5) };
    border-radius: 100px;
    height: 10px;
    width: 10px;
    background-clip: padding-box;
    border-bottom: 2px solid transparent;
    border-right: 2px solid transparent;
    border-top: 2px solid transparent;
  }
`;

export const StyledSidebarContentAndFooter = styled.div`
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledNavSectionPadding = styled.div`
  padding: 0 ${ props => props.theme.new.spacing[4] };
`;

export const StyledSidebarContainer = styled.div<{ slim: boolean; mobile: boolean }>`
  z-index: 999;
  height: 100%;
  flex-shrink: 0;
  width: var(--sidebar-width);
`;

export const StyledSidebarAndSubMenuContainer = styled.div`
  height: 100%;
  z-index: 1;
  position: relative;
`;

export const StyledFooterContainer = styled.div<{ scrollingRequired: boolean }>`
  position: relative;
  padding-top: ${ props => props.theme.new.spacing[2] };
  ${ props => props.scrollingRequired && `
    border-top: 1px solid ${ props.theme.new.palette.teal[500].main };
    box-shadow: 0 -${ props.theme.new.spacing[2] } ${ props.theme.new.spacing[3] } ${ props.theme.new.spacing[2] } ${ rgba(props.theme.new.palette.teal[900].main, 0.2) };
  ` }
`;
