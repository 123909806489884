import { FC, useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';

import { StyledSubCategoryRoot } from '../../../Sections/Settings/System/Components/styles';
import { Flex, FlexPullRight, GenericConfirmNavigationDialog } from '../../../Common/Component';
import { MappedSettingTypes, Setting, SettingCategory, SettingType } from '../../../Sections/Settings/System/Types';
import { ApiContext } from '../../../Contexts';
import {
  DefaultSettingsSubCategoryForm,
  SettingsSubCategoryFormProps
} from '../DefaultSettingsSubCategoryForm/DefaultSettingsSubCategoryForm';
import { useContextOrThrow } from '../../../Core/Hook';
import { ToastContext } from '../../../Core/Context';

type RequestBody = {
  delete: string[];
  update: Record<string, MappedSettingTypes[SettingType]['value']>;
};

export type SettingsCategoryFormProps = {
  category: SettingCategory;
  onSaved: (settings: Map<string, Setting<SettingType>>) => void;
  customRendering?: Record<string, FC<SettingsSubCategoryFormProps>>;
};

export const SettingsCategoryForm: FC<SettingsCategoryFormProps> = ({
  category,
  onSaved,
  customRendering = {},
}) => {
  const intl = useIntl();
  const api = useContext(ApiContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [changedSettings, setChangedSettings] = useState<Map<string, Setting<SettingType>>>(new Map([]));
  const { addSuccessToast, addErrorToast } = useContextOrThrow(ToastContext);

  const whenValueChanged = <T extends SettingType>(setting: Setting<T>, value: MappedSettingTypes[T]['value']) => {
    const changedSetting = {
      ...setting,
      usingDefault: false,
      value: value,
    };

    setChangedSettings(
      new Map(changedSettings).set(setting.key, changedSetting)
    );
  }

  const whenValueReset = <T extends SettingType>(setting: Setting<T>) => {
    const changedSetting = {
      ...setting,
      usingDefault: true,
      value: setting.defaultValue,
    };

    setChangedSettings(
      new Map(changedSettings).set(setting.key, changedSetting)
    );
  }

  const whenSaveButtonClicked = async () => {
    if (!api || changedSettings.size < 1) {
      return;
    }

    try {
      setLoading(true);
      const requestBody = buildRequestBody(Array.from(changedSettings.values()));
      await api.post('/settings', requestBody);
      whenSaveSuccessful();
    } catch (error) {
      whenSaveFailed();
    }
  }

  const whenSaveSuccessful = (): void => {
    addSuccessToast(
      intl.formatMessage({
        description: 'Successful toast message when setting category is saved.',
        defaultMessage: 'Saved',
      })
    );
    setLoading(false);
    onSaved(changedSettings);
    setChangedSettings(new Map([]));
  }

  const whenSaveFailed = (): void => {
    addErrorToast(
      intl.formatMessage({
        description: 'Error toast message when setting category is saved.',
        defaultMessage: 'Save failed',
      }),
    );
    setLoading(false);
  }

  const buildRequestBody = (settings: Setting<SettingType>[]): RequestBody => ({
    delete: settings
      .filter(setting => setting.usingDefault)
      .map(setting => setting.key),
    update: settings
      .filter(setting => !setting.usingDefault)
      .reduce(
        (reduced, setting) => ({
          ...reduced,
          [setting.key]: setting.value,
        }),
        {},
      ),
  });

  return (
    <>
      { category.subCategories.map((subCategory) => {
        const SubCategoryFormComponent = customRendering[subCategory.key] || DefaultSettingsSubCategoryForm;
        return (
          <StyledSubCategoryRoot key={ subCategory.key }>
            <SubCategoryFormComponent
              subCategory={ subCategory }
              changedSettings={ changedSettings }
              onChange={ whenValueChanged }
              onReset={ whenValueReset }
            />
          </StyledSubCategoryRoot>
        );
      }) }
      <Flex>
        <FlexPullRight>
          <Button
            variant="primary"
            disabled={ !api || changedSettings.size < 1 }
            busy={ loading }
            onClick={ whenSaveButtonClicked }
          >
            <FormattedMessage
              id="settings.saveCta"
              defaultMessage="Save"
            />
          </Button>
        </FlexPullRight>
      </Flex>
      <GenericConfirmNavigationDialog
        active={ !!changedSettings.size }
      />
    </>
  );
};
