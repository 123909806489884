import { ComponentProps, JSX, useCallback, useMemo, useState } from 'react';
import { SearchField } from '@ourpeople/shared/Core/Component/Input/SearchField/SearchField';
import { DropdownItemCheck } from 'op-storybook/stories/components/DropdownItemCheck/DropdownItemCheck';
import { QueryKey } from '@tanstack/react-query';

import { useContextOrThrow } from '../../../../Core/Hook';
import { PersonCondition } from '../../../../Audiences/Model';
import { ApiContext } from '../../../../Contexts';
import { InfiniteList } from '../../../Common/Component/Content/InfiniteList';
import { MinimalPerson } from '../../../../Models';
import { StandardListItem } from '../../../Common/Component/Content/StandardListItem';
import { PersonParser } from '../../../../Utility';
import { PersonAvatar } from '../../../../Common/Component/PersonAvatar/PersonAvatar';
import { MinimumInset } from '../../../Common/Component/Layout/MinimumInset';
import { ConditionListComponent } from '../../Model/AudienceConditionDefinition';
import * as ListPeopleRequest from '../../../People/HttpRequest/ListPeopleRequest';

const queryKey: QueryKey = ['people'];

export const PersonList: ConditionListComponent<PersonCondition> = ({
  condition,
  onChange,
  scrollAreaRef,
}): JSX.Element => {
  const api = useContextOrThrow(ApiContext);
  const [search, setSearch] = useState<string>('');
  const filters = useMemo(() => ({
    ...search ? { search } : {},
  }), [search]);

  const queryFn = useCallback<ComponentProps<typeof InfiniteList<MinimalPerson, typeof filters>>['queryFn']>(async (filters, page) => {
    const response = await ListPeopleRequest.send(api, {
      ...filters,
      pageNum: page,
      sort: [
        ListPeopleRequest.SortKey.FirstNameAsc,
        ListPeopleRequest.SortKey.LastNameAsc,
        ListPeopleRequest.SortKey.IdAsc,
      ],
    });
    return {
      pagination: response.data.pagination,
      items: response.data.people,
    };
  }, [api]);

  const whenPersonClicked = useCallback((person: MinimalPerson) => {
    const selected = condition.personIds.includes(person.id);
    onChange({
      ...condition,
      personIds: selected
        ? condition.personIds.filter(personId => person.id !== personId)
        : condition.personIds.concat(person.id),
    })
  }, [condition, onChange]);

  const renderItem = useCallback((person: MinimalPerson) => {
    return (
      <StandardListItem
        key={ person.id }
        text={ PersonParser.fullName(person) }
        onClick={ event => {
          event.stopPropagation();
          event.preventDefault();
          whenPersonClicked(person);
        } }
        icon={
          <PersonAvatar
            person={ person }
            size="md"
          />
        }
        selected={ condition.personIds.includes(person.id) }
        { ...condition.personIds.includes(person.id) ? { endAdornment: <DropdownItemCheck/> } : {} }
      />
    )
  }, [condition.personIds, whenPersonClicked]);

  return (
    <>
      <MinimumInset
        top={ 2 }
        right={ 1 }
        bottom={ 2 }
        left={ 1 }
      >
        <SearchField
          value={ filters.search || '' }
          onChange={ setSearch }
        />
      </MinimumInset>
      <InfiniteList
        filters={ filters }
        queryKey={queryKey}
        queryFn={ queryFn }
        renderItem={ renderItem }
        scrollAreaRef={ scrollAreaRef }
      />
    </>
  );
};
