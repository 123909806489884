import { ContentType, MinimalContent } from '../../Models';
import { ContentReader } from '../../Readers/ContentReader';

export class BroadcastTypeHelper
{
  static typeFromContents(contents: MinimalContent[]): ContentType {
    if (contents.length === 0) {
      return 'unknown';
    }
    if (contents.length > 1) {
      return 'stack';
    }
    // Defend against bug in the API, where when filtering, it
    // will not return any other content types. If [0] is undefined,
    // it must mean it is a timeline card, and therefore a stack
    if (
      contents[0] === undefined
      && Object.values(contents).length > 0
    ) {
      return 'stack';
    }
    return ContentReader.contentType(contents[0]);
  }
}
