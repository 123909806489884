import styled from '@emotion/styled';
import { ButtonGroup, Tooltip } from '@mui/material';

import shapeUrl from '../../../Assets/img/shapes/graphic_teal2.svg?url';

export const PreviewPane = styled.div`
  background: ${ props => props.theme.palette.editorBackground.main };
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
`;

export const StyledButtonGroup = styled(ButtonGroup)`
  background: rgba(255, 255, 255, 0.8);
  z-index: 0;
`;

export const StyledAspectRatioControls = styled.div`
  position: relative;
`;

export const StyledTooltip = styled(Tooltip)`
  padding: ${ props => props.theme.spacing(0.5) };
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  color: ${ props => props.theme.palette.secondary.main };
  top: ${ props => props.theme.spacing(0.5) };
  right: -${ props => props.theme.spacing(5) };
  border-radius: 100rem;

  svg {
    width: ${ props => props.theme.spacing(3) };
    height: ${ props => props.theme.spacing(3) };
  }
`;

export const StyledStickyContainer = styled.div`
  position: sticky;
  top: ${ props => props.theme.spacing(8) };
  left: 0;
  padding: ${ props => props.theme.spacing(2) };
  display: flex;
  flex-direction: column;
  gap: ${ props => props.theme.spacing(2) };
  align-items: center;
`;

export const StyledBackgroundContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;

  ::before {
    content: '';
    background-image: url(${ shapeUrl });
    position: absolute;
    width: 400px;
    height: 500px;
    background-position: bottom right;
    background-repeat: no-repeat;
    bottom: -50px;
    right: -50px;
    background-size: contain;
  }
`;
