import { FC, ReactNode, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { parseISO } from 'date-fns';

import { ChoiceOption } from '../../../../../Broadcasts/Service';
import { ContentPadding, NudgeBanner, RespondButton, RespondButtonProps } from '../..';
import { BroadcastContentFooter, StackNavigationProps } from '../../Footer/BroadcastContentFooter';
import { CardTitle, ScrollableContent } from '../../../../../Broadcasts/Component/Content';
import { VerticallySpaced } from '../../../../../Common/Component';
import { ChoiceContentCheckboxes } from '..';
import { BroadcastNudge } from '../../../../../Broadcasts/Model';
import { AbsoluteBackButtonContainer } from '../../AbsoluteBackButtonContainer/AbsoluteBackButtonContainer';

type Props = {
  title: string;
  multipleChoice?: boolean;
  selectedOptionIds: string[];
  onSelectedOptionIdsChanged: (selectedOptionIds: string[]) => void;
  options: ChoiceOption[];
  submitProps: RespondButtonProps;
  responded?: boolean;
  navigationProps: StackNavigationProps;
  nudge?: BroadcastNudge;
  backButton: ReactNode | undefined;
};

export const ChoiceBroadcastContent: FC<Props> = ({
  title,
  multipleChoice,
  selectedOptionIds,
  onSelectedOptionIdsChanged,
  options,
  submitProps,
  responded = false,
  navigationProps,
  nudge,
  backButton,
}) => {
  const intl = useIntl();
  const normalisedOptions = useMemo(() => (
    options.map(option => ({
      id: option.id.toString(),
      title: option.text,
    }))
  ), [options]);

  return (
    <>
      { backButton && <AbsoluteBackButtonContainer>{ backButton }</AbsoluteBackButtonContainer> }
      <ScrollableContent>
        {
          nudge && (
            <NudgeBanner
              message={ nudge.message }
              nudgedAt={ parseISO(nudge.at) }
            />
          )
        }
        <ContentPadding>
          <VerticallySpaced gap={ 2 }>
            <CardTitle>{ title }</CardTitle>
            <ChoiceContentCheckboxes
              options={ normalisedOptions }
              disabled={ responded }
              selectedIds={ selectedOptionIds }
              onChange={ onSelectedOptionIdsChanged }
              maxSelections={ multipleChoice ? Infinity : 1 }
            />
          </VerticallySpaced>
        </ContentPadding>
      </ScrollableContent>
      <BroadcastContentFooter { ...navigationProps }>
        { !!selectedOptionIds.length && <RespondButton
          { ...submitProps }
          variant="primary"
          notRespondedLabel={ intl.formatMessage({
            id: 'content.broadcast.choice.notResponded',
            description: 'Label for choice submit button when unread.',
            defaultMessage: 'Submit { multipleChoice, select, false { choice } other { choices } }',
          }, {
            multipleChoice,
          }) }
          respondedLabel={ intl.formatMessage({
            id: 'content.broadcast.choice.responded',
            description: 'Label for choice submit button when unread.',
            defaultMessage: '{ multipleChoice, select, false { Choice } other { Choices } } submitted',
          }, {
            multipleChoice,
          }) }
          rejectedLabel={ intl.formatMessage({
            id: 'content.broadcast.choice.rejected',
            description: 'Label for choice submit button when response was rejected.',
            defaultMessage: 'Try again',
          }) }
        /> }
      </BroadcastContentFooter>
    </>
  );
};
