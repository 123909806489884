import { FC } from 'react';
import MediaIcon from 'op-storybook/lib/assets/icon/figma/video-recorder.svg';

import { BlockPlaceholder } from '../../BlockPlaceholder';

type Props = {
  loading: boolean;
  className?: string;
};

export const VideoPlaceholder: FC<Props> = ({
  loading,
  className,
}) => (
  <BlockPlaceholder
    loading={ loading }
    className={ className }
    IconComponent={ MediaIcon }
  />
);
