import { FetchResponse, useGet } from '../../Hooks';
import { TagType } from '../Model';
import { Paginated } from '../../Models';
import { ImportOperation } from '../../Imports/Model';

type TagParentTypeString = 'new' | 'existing';

export type TagParent = {
  id: string;
  type: TagParentTypeString;
  name: string;
};

export type TagImportOperation = ImportOperation & {
  tagId: string;
  tagName: string;
  tagType: TagType;
  tagParents: TagParent[];
};

export type FetchTagImportOperationsParams = {
  pageNum: number;
  recordTypes?: string;
  operationTypes?: string;
  search?: string;
}

export const useFetchTagImportOperations = (importId: string, params: FetchTagImportOperationsParams): FetchResponse<Paginated<'operations', TagImportOperation>> => useGet(
  `/imports/${ importId }/operations`,
  params,
);
