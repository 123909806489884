import { FunctionComponent, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { SortableHeaderCell, TableCell, TableRow } from '../../../../../Components';
import { DateTime, FilterContainer, FilterRow } from '../../../../../Common/Component';
import { StyledResponseTable } from '../styles';
import { StandardReportTableInput } from './StandardReportTableInput';
import { ReportRecipient, ResponseTable } from '../../../../../Broadcasts/Component';

export interface CommentRespondedTableProps {
  broadcastId: string;
  deliveryId?: string;
  contentId?: string;
  initialSearch?: string;
}

export const CommentRespondedTable: FunctionComponent<CommentRespondedTableProps> = ({
  broadcastId,
  deliveryId,
  contentId,
  initialSearch = '',
}) => {
  const [sort, setSort] = useState<string>('firstNameAsc');
  const [search, setSearch] = useState<string>(initialSearch);
  const params = useMemo(() => ({
    deliveryId: deliveryId || null,
    contentId: contentId || null,
    optionId: null,
    rating: null,
    responseType: 'responded',
    sort,
    relativeStatus: 'active,inactive,frozen',
    search: search || null,
  }), [contentId, search, deliveryId, sort]);

  return (
    <StyledResponseTable>
      <FilterContainer>
        <FilterRow>
          <StandardReportTableInput
            searchValue={search}
            onSearchChanged={setSearch}
          />
        </FilterRow>
      </FilterContainer>
      <ResponseTable
        reportRecipientsParams={params}
        broadcastId={ broadcastId }
        headerRow={
          <TableRow>
            <SortableHeaderCell
              sort={sort}
              ascValue="firstNameAsc"
              descValue="firstNameDesc"
              onSort={setSort}
            >
              <FormattedMessage
                id="broadcast.report.recipient.firstName"
                defaultMessage="First name"
                />
            </SortableHeaderCell>
            <SortableHeaderCell
              sort={sort}
              ascValue="lastNameAsc"
              descValue="lastNameDesc"
              onSort={setSort}
            >
              <FormattedMessage
                id="broadcast.report.recipient.lastName"
                defaultMessage="Last name"
                />
            </SortableHeaderCell>
            <SortableHeaderCell
              sort={sort}
              ascValue="dateAsc"
              descValue="dateDesc"
              onSort={setSort}
            >
              <FormattedMessage
                id="broadcast.report.recipient.date"
                defaultMessage="Date"
                />
            </SortableHeaderCell>
            <TableCell>
              <FormattedMessage
                id="broadcast.report.recipient.comment"
                defaultMessage="Comment"
                />
            </TableCell>
          </TableRow>
        }
        rowRender={ (recipient: ReportRecipient<Record<string, unknown>>) => (
          <TableRow key={ recipient.id }>
            <TableCell>
              <Link to={`/people/${ recipient.user.id }/account`}>
                { recipient.user.firstName }
              </Link>
            </TableCell>
            <TableCell>
              <Link to={`/people/${ recipient.user.id }/account`}>
                { recipient.user.lastName }
              </Link>
            </TableCell>
            <TableCell>
              <DateTime dateTime={ recipient.date }/>
            </TableCell>
            <TableCell>
              { recipient.metadata.comment as string || '' }
            </TableCell>
          </TableRow>
        ) }
      />
    </StyledResponseTable>
  );
}
