import { FunctionComponent, useState } from 'react';

import { ContentPreviewProps } from '../../../../Content/Model';
import { ChoiceRequestEditorContent } from '../../../Service';
import { ChoiceRequest } from '../ChoiceRequest/ChoiceRequest';

export const ChoiceRequestPreview: FunctionComponent<ContentPreviewProps<ChoiceRequestEditorContent>> = ({
  editorContent,
}) => {
  const [selection, setSelection] = useState<string[]>([]);

  return (
    <ChoiceRequest
      content={ editorContent.card.content }
      selection={ selection }
      onChange={ setSelection }
    />
  );
};
