import { FunctionComponent, useEffect, useMemo, useState } from 'react';

import { ImageContainer, LoadingContainer, StyledHeaderImage } from './style';
import { LoadingSpinner } from '../../../Components';
import { useFetchUploadUrls } from '../../../Common/Hook';

interface Props {
  uploadId: string;
  fit: 'contain' | 'cover';
  useThumbnail?: boolean;
  onFinish?: () => void;
}

export const HeaderImage: FunctionComponent<Props> = ({
  uploadId,
  fit,
  useThumbnail = false,
  onFinish,
}) => {
  const [fetchUploadUrlsResult] = useFetchUploadUrls(uploadId);
  const imageSource = useMemo<string | undefined>(
    () => useThumbnail
      ? fetchUploadUrlsResult?.content?.thumbnail
      : fetchUploadUrlsResult?.content?.original,
    [useThumbnail, fetchUploadUrlsResult?.content],
    );
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);

  useEffect(() => {
    setImageLoaded(false);
  }, [uploadId]);

  return (
    <ImageContainer>
      { imageSource && (
        <StyledHeaderImage
          visible={ imageLoaded }
          src={ imageSource }
          onLoad={ () => {
            onFinish && onFinish();
            setImageLoaded(true)
          } }
          onError={ onFinish }
          fit={ fit }
        />
      ) }
      { !imageLoaded && (
        <LoadingContainer>
          <LoadingSpinner/>
        </LoadingContainer>
      ) }
    </ImageContainer>
  );
};
