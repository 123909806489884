import { IntlShape } from 'react-intl';

import { ArrayHelper } from '../../Common/Utility';
import { PersonStatus } from '../../Models';

const validPersonStatuses: PersonStatus[] = [
  'active',
  'inactive',
  'added',
  'pending',
  'frozen',
  'deleted',
];

export class PersonStatusHelper {
  public static isValid = ArrayHelper.createTypeGuard(validPersonStatuses);
  public static removeInvalid = ArrayHelper.createTypeFilter(PersonStatusHelper.isValid);

  public static localiseStatus(intl: IntlShape, status: PersonStatus): string {
    switch (status) {
      case 'pending':
        return intl.formatMessage({
          id: 'personStatus.pending',
          defaultMessage: 'Invited',
        });
      case 'added':
        return intl.formatMessage({
          id: 'personStatus.added',
          defaultMessage: 'Not invited',
        });
      case 'active':
        return intl.formatMessage({
          id: 'personStatus.active',
          defaultMessage: 'Active',
        });
      case 'inactive':
        return intl.formatMessage({
          id: 'personStatus.inactive',
          defaultMessage: 'Inactive',
        });
      case 'frozen':
        return intl.formatMessage({
          id: 'personStatus.frozen',
          defaultMessage: 'Frozen',
        });
      case 'deleted':
        return intl.formatMessage({
          id: 'personStatus.deleted',
          defaultMessage: 'Deleted',
        });
    }

    return intl.formatMessage({
      id: 'personStatus.unknown',
      defaultMessage: 'Unknown',
    });
  }
}
