import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { AutocompleteCommonProps, AutocompleteFetchRequest, AutocompleteSelectionChanged } from '@ourpeople/shared/Core/Component/Input/Autocomplete/Autocomplete';
import { LocalisedString } from 'op-storybook/lib/model/LocalisedString/LocalisedString';

import { DebouncedAutocomplete } from '../../../Common/Component';

interface MeetingsAndTrainingsStatusAutocompleteProps extends AutocompleteCommonProps {
  selectedIds: string[];
  allowedIds?: string[];
  onSelectionChanged: AutocompleteSelectionChanged<LocalisedString>;
}

export const MeetingsAndTrainingsStatusAutocomplete = ({
  selectedIds,
  allowedIds,
  onSelectionChanged,
  ...props
}: MeetingsAndTrainingsStatusAutocompleteProps): JSX.Element => {
  const intl = useIntl();
  const availableStatuses: LocalisedString[] = useMemo(
    () => [
      {
        id: 'full',
        localisation: intl.formatMessage({
          id: 'meetingsAndTrainingsStatusAutocomplete.statusFull',
          defaultMessage: 'Full',
        }),
      },
      {
        id: 'cancelled',
        localisation: intl.formatMessage({
          id: 'meetingsAndTrainingsStatusAutocomplete.statusCancelled',
          defaultMessage: 'Cancelled',
        }),
      },
      {
        id: 'completed',
        localisation: intl.formatMessage({
          id: 'meetingsAndTrainingsStatusAutocomplete.statusCompleted',
          defaultMessage: 'Completed',
        }),
      },
      {
        id: 'available',
        localisation: intl.formatMessage({
          id: 'meetingsAndTrainingsStatusAutocomplete.statusAvailable',
          defaultMessage: 'Available',
        }),
      },
    ],
    [intl],
  );

  const whenFetch: AutocompleteFetchRequest<LocalisedString> = () => {
    return Promise.resolve({
      options: availableStatuses
        .filter((status) => !allowedIds || allowedIds.indexOf(status.id) > -1),
      pageCount: 1,
    });
  }

  return (
    <DebouncedAutocomplete<LocalisedString>
      { ...props }
      getOptionLabel={ status => status.localisation }
      fetchOptions={ useCallback(whenFetch, [allowedIds, availableStatuses]) }
      selectedIds={ selectedIds }
      onSelectionChanged={ onSelectionChanged }
      label={
        props.label !== undefined
          ? props.label
          : intl.formatMessage({
              id: 'meetingsAndTrainingsStatusAutocomplete.label',
              defaultMessage: 'Select {limit, plural, one {status} other {statuses}}'
            },
            {
              limit: props.multiple ? Infinity : 1,
            })
      }
      placeholder={
        props.placeholder ||
        intl.formatMessage(
          {
            id: 'meetingsAndTrainingsStatusAutocomplete.placeholder',
            defaultMessage: 'Search for {limit, plural, one {status} other {status}}'
          },
          {
            limit: props.multiple ? Infinity : 1,
          }
        )
      }
    />
  );
};
