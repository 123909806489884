import { FC, FormEventHandler, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { AuthFullscreenLayout } from 'op-storybook/lib/components/AuthFullscreenLayout/AuthFullscreenLayout';
import { PasswordField } from 'op-storybook/stories/components/PasswordField/PasswordField';
import { Form } from 'op-storybook/lib/components/Form/Form';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { SsoButtons } from 'op-storybook/lib/components/SsoButtons/SsoButtons';
import { AuthHeader } from 'op-storybook/lib/components/AuthHeader/AuthHeader';
import LockIcon from 'op-storybook/lib/assets/icon/figma/lock-01.svg';
import { Button } from 'op-storybook/stories/components/Button/Button';

import { AuthContext } from '../../../Contexts';
import { AnonymousSettings } from '../../Hook';
import { useElevate } from '../../Hook/useElevate';
import { ForgotPasswordLink } from '../../../Login/Component/ForgotPasswordLink/ForgotPasswordLink';
import { RequestState } from '../../../Models';

type Props = {
  anonymousSettings: AnonymousSettings;
  loginOriginUrl: string;
  loginTargetUrl: string;
};

export const ElevateLoginPage: FC<Props> = ({
  anonymousSettings,
  loginOriginUrl,
  loginTargetUrl,
}) => {
  const intl = useIntl();
  const { authDescription } = useContext(AuthContext);
  const { elevate, elevateRequest } = useElevate();
  const returnToLinkProps = useMemo(() => ({
    to: loginOriginUrl,
    children: intl.formatMessage({
      description: 'Label for link to return to previous page from elevate page.',
      defaultMessage: 'Go back',
    }),
  }), [intl, loginOriginUrl]);
  const errorMessages = useMemo(() => (
    elevateRequest.state === RequestState.FAILED
      ? [
        elevateRequest.result.error === 'INVALID'
          ? intl.formatMessage({
            description: 'Error when submitted password is invalid on elevate login page',
            defaultMessage: 'Password is incorrect',
          })
          : intl.formatMessage({
            description: 'Error when submitted password fails for unknown reason on elevate login page',
            defaultMessage: 'Password could not be submitted, please try again',
          })
      ]
      : []
  ), [elevateRequest, intl]);

  const history = useHistory();
  const [password, setPassword] = useState('');
  const ssoProviders = useMemo(() => (
    anonymousSettings.ssoOptions.map(ssoOption => ({
      issuer: ssoOption.issuer,
      url: `${ ssoOption.authUrl }&targetUri=${ loginTargetUrl }`,
    }))
  ), [anonymousSettings.ssoOptions, loginTargetUrl]);

  // Redirect when elevated
  useEffect(() => {
    if (authDescription && authDescription.permissions.restricted.length < 1) {
      const target: string = loginTargetUrl || '';
      history.replace(target);
    }
  }, [history, authDescription, loginTargetUrl]);

  const whenSubmitted: FormEventHandler = useCallback(event => {
    event.preventDefault();
    elevate(password);
  }, [elevate, password]);

  if (!authDescription) {
    return <Redirect to="/login"/>;
  }

  return (
    <AuthFullscreenLayout>
      <AuthHeader
        header={
          authDescription.user.hasPassword
            ? intl.formatMessage({
              description: 'Page header text used in elevate login page when user has a password.',
              defaultMessage: 'Confirm access',
            })
            : intl.formatMessage({
              description: 'Page header text used in elevate login page when user does not have a password.',
              defaultMessage: 'Create a password to continue',
            })
        }
        supportingText={
          authDescription.user.hasPassword
            ? intl.formatMessage({
              description: 'Page header supporting text used in elevate login page when user has a password.',
              defaultMessage: 'We need to confirm your access before you can use some features.',
            })
            : intl.formatMessage({
              description: 'Page header supporting text used in elevate login page when user does not have a password.',
              defaultMessage: 'We need you to create a password for extra security in order to use some features.',
            })
        }
        graphic={ {
          type: 'icon',
          props: {
            IconComponent: LockIcon,
            size: 'lg',
          },
        } }
      />
      {
        authDescription.user.hasPassword
          ? (
            <Form onSubmit={ whenSubmitted }>
              <Stack
                direction="column"
                gap={ 4 }
              >
                <PasswordField
                  value={ password }
                  onChange={ setPassword }
                  errorMessages={ errorMessages }
                />
                <Stack
                  direction="column"
                  align="flex-end"
                >
                  <ForgotPasswordLink
                    returnToLinkProps={ returnToLinkProps }
                  />
                </Stack>
                <Button
                  type="submit"
                  variant="primary"
                  disabled={ !password }
                  busy={ elevateRequest.state === RequestState.FETCHING }
                >
                  <FormattedMessage
                    description="Label for submit button on elevate permission page."
                    defaultMessage="Submit"
                  />
                </Button>
              </Stack>
            </Form>
          )
          : (
            <Button
              component={ Link }
              to={ {
                pathname: '/set-password',
                state: { returnToLinkProps },
              } }
              variant="primary"
            >
              <FormattedMessage
                description="Label for set password button when user without password is directed to the elevate page."
                defaultMessage="Set password now"
              />
            </Button>
          )
      }
      <SsoButtons
        providers={ ssoProviders }
      />
      <Stack
        direction="column"
        align="center"
      >
        <Link { ...returnToLinkProps }/>
      </Stack>
    </AuthFullscreenLayout>
  );
};
