import { ComponentProps, FC, FormEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { AuthFullscreenLayout } from 'op-storybook/lib/components/AuthFullscreenLayout/AuthFullscreenLayout';
import { useIntl } from 'react-intl';
import { Username } from 'op-storybook/stories/components/UsernameField/UsernameField';
import { useCountdown } from 'op-storybook/lib/hooks/useCountdown';
import { CountryCode } from 'libphonenumber-js';
import { useUsernameValidationErrors } from 'op-storybook/lib/hooks/useUsernameValidationErrors';
import { UsernameValidator } from 'op-storybook/lib/utility/UsernameValidator/UsernameValidator';

import { GuardedRoute } from '../../../Common/Component';
import { CodeRequestForm } from '../../Component/CodeRequestForm/CodeRequestForm';
import { useTokenRequest } from '../../Hook/useSecurityTokenRequest';
import { RequestState } from '../../../Models';
import { CodeEntryForm } from '../../Component/CodeEntryForm/CodeEntryForm';
import { usePasswordAuthRequest } from '../../Hook/usePasswordAuthRequest';
import { PasswordUpdateForm } from '../../Component/PasswordUpdateForm/PasswordUpdateForm';
import { useUpdatePasswordRequest } from '../../Hook/useUpdatePasswordRequest';
import { useLoginRequest } from '../../Hook/useLoginRequest';
import { useContextOrThrow } from '../../../Core/Hook';
import { ToastContext } from '../../../Core/Context';
import { ValidationError } from '../../../Common/Model';

interface LocationState {
  returnToLinkProps?: ComponentProps<typeof Link>;
  username?: Username;
}

enum FormState {
  INITIAL,
  CODE_REQUESTED,
  CODE_SUBMITTED,
}

type Props = {
  initialPageHeaderText: string;
  initialPageSupportingText: string;
  basePath: string;
  loginTargetUrl: string;
  defaultCountryCode: CountryCode;
};

export const SetPasswordFlow: FC<Props> = ({
  initialPageHeaderText,
  initialPageSupportingText,
  basePath,
  loginTargetUrl,
  defaultCountryCode,
}) => {
  const intl = useIntl();
  const history = useHistory<LocationState | undefined>();
  const [code, setCode] = useState<string>('');
  const [username, setUsername] = useState<Username>(
    history.location.state?.username || {value: ''}
  );
  const [formState, setFormState] = useState<FormState>(FormState.INITIAL);
  const { requestToken, tokenRequest } = useTokenRequest();
  const { requestPasswordAuth, passwordAuthRequest } = usePasswordAuthRequest();
  const remainingDurationInSeconds = useCountdown(
    (tokenRequest.state === RequestState.FAILED && tokenRequest.result.retryAfter) || 0
  );
  const { addSuccessToast } = useContextOrThrow(ToastContext);
  const [newPassword, setNewPassword] = useState<string>('');
  const { updatePassword, updatePasswordRequest } = useUpdatePasswordRequest();
  const [submittedPassword, setSubmittedPassword] = useState<string>('');
  const { login, loginRequest } = useLoginRequest();
  const [usernameErrors, setUsernameErrors] = useState<ValidationError[]>([]);
  const localisedUsernameErrors = useUsernameValidationErrors().formatErrorMessages(usernameErrors);

  const submitPassword = useCallback(() => {
    if (passwordAuthRequest.state !== RequestState.COMPLETE) {
      return;
    }

    setSubmittedPassword(newPassword);
    updatePassword(passwordAuthRequest.result.token, newPassword);
  }, [updatePassword, passwordAuthRequest, newPassword]);

  const whenPasswordSubmitted = useCallback<FormEventHandler>(event => {
    event.preventDefault();

    submitPassword();
  }, [submitPassword]);

  // Clear username errors when field is changed
  useEffect(() => {
    setUsernameErrors([]);
  }, [username]);

  const submitUsername = useCallback(() => {
    const usernameErrors = UsernameValidator.validate(username);

    if (usernameErrors.length) {
      setUsernameErrors(usernameErrors);
      return;
    }

    const data = username.parsedPhone?.e164Value
      ? {
        method: 'MOBILE_NUMBER',
        mobileNumber: username.parsedPhone.e164Value,
      }
      : {
        method: 'EMAIL_ADDRESS',
        emailAddress: username.value,
      };

    requestToken(data);
  }, [username, requestToken]);

  const whenUsernameSubmitted: FormEventHandler = useCallback((event) => {
    event.preventDefault();

    submitUsername();
  }, [submitUsername]);

  const whenCodeSubmitted = useCallback((code: string) => {
    setCode(code);
    const details = username.parsedPhone?.e164Value
      ? {
        mobileNumber: username.parsedPhone.e164Value,
        token: code,
      }
      : {
        emailAddress: username.value,
        token: code,
      };

    requestPasswordAuth(details);
  }, [requestPasswordAuth, username]);

  // Navigate to code entry page when token request completes
  useEffect(() => {
    if (tokenRequest.state !== RequestState.COMPLETE) {
      return;
    }

    setFormState(FormState.CODE_REQUESTED);
    history.push(`${ basePath }/code`)
  }, [basePath, history, tokenRequest]);

  // Navigate to password update page when auth request completes
  useEffect(() => {
    if (passwordAuthRequest.state !== RequestState.COMPLETE) {
      return;
    }

    setFormState(FormState.CODE_SUBMITTED);
    history.push(`${ basePath }/update`);
  }, [basePath, history, passwordAuthRequest]);

  // Log in when password has been updated
  useEffect(() => {
    if (updatePasswordRequest.state !== RequestState.COMPLETE) {
      return;
    }

    const loginDetails = username.parsedPhone?.e164Value
      ? {
        mobileNumber: username.parsedPhone.e164Value,
        password: submittedPassword,
      }
      : {
        emailAddress: username.value,
        password: submittedPassword,
      };

    addSuccessToast(intl.formatMessage({
      description: 'Success toast when updating password.',
      defaultMessage: 'Password updated',
    }));
    login(loginDetails, false);
  }, [updatePasswordRequest, username, submittedPassword, login, intl, addSuccessToast]);

  // Redirect when logged in
  useEffect(() => {
    if (loginRequest.state !== RequestState.COMPLETE) {
      return;
    }

    history.replace(loginTargetUrl || '/');
  }, [history, loginRequest, loginTargetUrl]);

  // Redirect to login page if automatic login fails
  useEffect(() => {
    if (loginRequest.state !== RequestState.FAILED) {
      return;
    }

    history.replace('/login');
  }, [history, loginRequest]);

  const codeExpiryUrl = useMemo(() => ({
    pathname: basePath,
    state: {
      codeExpired: true,
    },
  }), [basePath]);

  const returnLinkProps: ComponentProps<typeof Link> = useMemo(() => (
    history.location.state?.returnToLinkProps || {
      children: intl.formatMessage({
        description: 'Label for link to return to login page from update password flow.',
        defaultMessage: 'Return to login',
      }),
      to: '/login',
    }
  ), [history.location.state?.returnToLinkProps, intl]);

  return (
    <AuthFullscreenLayout>
      <Switch>
        <Route
          path={ basePath }
          exact
        >
          <CodeRequestForm
            defaultCountryCode={ defaultCountryCode }
            username={ username }
            localisedUsernameErrors={ localisedUsernameErrors }
            onChange={ setUsername }
            onSubmit={ whenUsernameSubmitted }
            header={ initialPageHeaderText }
            supportingText={ initialPageSupportingText }
            tokenRequest={ tokenRequest }
            remainingDurationInSeconds={ remainingDurationInSeconds }
            returnLinkProps={ returnLinkProps }
          />
        </Route>
        <GuardedRoute
          path={ `${ basePath }/code` }
          exact
          condition={ formState >= FormState.CODE_REQUESTED }
          redirect={ basePath }
        >
          <CodeEntryForm
            username={ username }
            remainingDurationInSeconds={ remainingDurationInSeconds }
            code={ code }
            onChange={ setCode }
            onSubmit={ whenCodeSubmitted }
            submitRequest={ passwordAuthRequest }
            onResendCodeClicked={ submitUsername }
            returnLinkProps={ returnLinkProps }
          />
        </GuardedRoute>
        <GuardedRoute
          path={ `${ basePath }/update` }
          exact
          condition={ formState >= FormState.CODE_SUBMITTED }
          redirect={ `${ basePath }/code` }
        >
          <PasswordUpdateForm
            password={ newPassword }
            onChange={ setNewPassword }
            onSubmit={ whenPasswordSubmitted }
            updatePasswordRequest={ updatePasswordRequest }
            codeExpiryLink={ codeExpiryUrl }
            returnLinkProps={ returnLinkProps }
          />
        </GuardedRoute>
        <Redirect to={ basePath } />
      </Switch>
    </AuthFullscreenLayout>
  );
};
