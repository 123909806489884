import { FC } from 'react';

import { LiveComponentProps } from '../../../../Content/Model';
import { RevealContent } from '../../../../Broadcasts/Service';
import { ReadResponse } from '../../../Model';
import { BroadcastContentContext } from '../../../../Broadcasts/Model';
import { useRecallErrorHandler, useRespondButtonProps } from '../../../Hook';
import { RevealBroadcastContent } from '../../../../Content/Component';

export const LiveRevealContent: FC<LiveComponentProps<RevealContent, ReadResponse, BroadcastContentContext>> = ({
  card: { content },
  response,
  context,
  onResponseChange,
  onReloadRequired,
}) => {
  const markAsReadProps = useRespondButtonProps({
      contentId: content.id.toString(),
      deliveryId: context.deliveryId,
      type: 'read',
    },
    onResponseChange,
    useRecallErrorHandler(onReloadRequired),
    response,
  );

  return (
    <RevealBroadcastContent
      backButton={ context.closeButton }
      title={ content.title }
      description={ content.text }
      options={ content.options }
      markAsReadProps={ markAsReadProps }
      navigationProps={ context.navigationProps }
      responded={ !!response }
      hideRespondButton={ context.contentCount > 1 }
      nudge={ context.nudge }
    />
  );
};
