import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { ChatStatus, useLocalisedChatStatuses } from '../../Hook';

type Props = {
  status: ChatStatus;
  note: string | null;
};

export const AudienceMemberChatStatus: FC<Props> = ({
  status,
  note,
}) => {
  const localisedStatuses = useLocalisedChatStatuses();
  const localisedStatus = localisedStatuses[status];

  return (
    <>
      <FormattedMessage
        id="audienceMemberChatStatus.status"
        description="Chat status with optional note"
        defaultMessage="{ localisedStatus }{ note, select, null { } other { - { note } } }"
        values={{
          localisedStatus,
          note,
        }}
      />
    </>
  );
};
