import styled from '@emotion/styled';
import { DialogContent, Dialog, DialogActions, DialogTitle } from '@mui/material';

import { Button } from '../../Input/Button/Button';

export const StyledDialogTitleRow = styled.div<{ destructive?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${ props => props.theme.spacing(2) };

  ${ props => props.theme.breakpoints.down('md') } {
    flex-direction: column;
    gap: ${ props => props.theme.spacing(1) };
  }

  ${ props => props.destructive && `
    color: ${ props.theme.palette.error.main }
  ` }
`;

export const StyledDialogTitle = styled(DialogTitle)`
  padding: 0;

  h2 {
    line-height: 1;
  }
`;

export const StyledDialogContent = styled(DialogContent)`
  padding: 0;

  ${ props => props.theme.breakpoints.down('md') } {
    text-align: center;
  }
`;

export const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    padding: ${ props => props.theme.spacing(2) };
    display: flex;
    flex-direction: column;
    gap: 16px;

    ${ props => props.theme.breakpoints.down('md') } {
      width: calc(100% - ${ props => props.theme.spacing(4) });
      margin: ${ props => props.theme.spacing(2) };
    }
  }
`;

export const StyledDialogActions = styled(DialogActions)`
  padding: 0;
  gap: ${ props => props.theme.spacing(2) };

  >*:not(:first-child) {
    margin-left: initial;
  }

  ${ props => props.theme.breakpoints.down('md') } {
    gap: ${ props => props.theme.spacing(1) };
    flex-direction: column-reverse;
    align-items: stretch;
  }
`;

export const StyledButton = styled(Button)`
  ${ props => props.theme.breakpoints.down('md') } {
    button {
      width: 100%;
    }
  }
`;
