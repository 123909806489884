import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { CircularProgress } from '@mui/material';

import { Box, Flex } from '../../../Common/Component';
import { StyledFailedIcon, StyledSuccessIcon } from '../BroadcastEditorTranscodingProgress/style';
import SuccessIcon from '../../../Assets/img/icons/streamline/success.svg';
import FailedIcon from '../../../Assets/img/icons/streamline/cross.svg';
import { RequestState } from '../../../Models';

type Props = {
  videosMissingThumbnails: number;
  state: RequestState;
};

export const TranscodingProgress: FC<Props> = ({
  videosMissingThumbnails,
  state,
}) => (
  <>
    { state !== RequestState.PENDING && (
      <Box margin={ false }>
        <Flex gap={ 2 }>
          {
            state === RequestState.FETCHING
              ? (
                <>
                  <CircularProgress
                    variant="indeterminate"
                    size={ 24 }
                  />
                  <span>
                    <FormattedMessage
                      description="Message while video transcoding is in progress."
                      defaultMessage="<strong>Processing video files.</strong> You can continue creating while you wait."
                    />
                  </span>
                </>
              )
              : state === RequestState.COMPLETE
                ? (
                  <>
                    <StyledSuccessIcon IconComponent={ SuccessIcon }/>
                    <span>
                      <FormattedMessage
                        description="Message when video transcode succeeded."
                        defaultMessage="<strong>Video processing complete.</strong>{ missingThumbnailCount, plural, =0 {} one { A video is missing a thumbnail.} other { # videos are missing thumbnails.} }"
                        values={ {
                          missingThumbnailCount: videosMissingThumbnails,
                        } }
                      />
                    </span>
                  </>
                )
                : (
                  <>
                    <StyledFailedIcon IconComponent={ FailedIcon }/>
                    <span>
                      <FormattedMessage
                        description="Message when video transcode failed."
                        defaultMessage="<strong>One or more of your videos could not be processed.</strong> Remove the affected videos and try again."
                      />
                    </span>
                  </>
                )
          }
        </Flex>
      </Box>
    ) }
  </>
);
