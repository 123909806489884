import { FC, useState } from 'react';

import { LiveComponentProps } from '../../../../Content/Model';
import { CommentRequest } from '../../../../Broadcasts/Service';
import { TextResponse } from '../../../Model';
import { BroadcastContentContext } from '../../../../Broadcasts/Model';
import { useRecallErrorHandler, useRespondButtonProps } from '../../../Hook';
import { CommentBroadcastContent } from '../../../../Content/Component';

export const LiveCommentContent: FC<LiveComponentProps<CommentRequest, TextResponse, BroadcastContentContext>> = ({
  card: { content },
  response,
  context,
  onResponseChange,
  onReloadRequired,
}) => {
  const [partialResponse, setPartialResponse] = useState<Omit<TextResponse, 'createdAt'>>({
    contentId: content.id.toString(),
    deliveryId: context.deliveryId,
    type: 'text',
    input: {
      text: response?.input.text || '',
    },
  });

  const submitProps = useRespondButtonProps(
    partialResponse,
    onResponseChange,
    useRecallErrorHandler(onReloadRequired),
    response,
  );

  const whenInputChanged = (value: string) => {
    setPartialResponse({
      ...partialResponse,
      input: {
        text: value,
      },
    });
  };

  return (
    <>
      <CommentBroadcastContent
        backButton={ context.closeButton }
        submitProps={ submitProps }
        navigationProps={ context.navigationProps }
        question={ content.title }
        input={ partialResponse.input.text }
        submitted={ !!response }
        onChange={ whenInputChanged }
        nudge={ context.nudge }
      />
    </>
  );
};
