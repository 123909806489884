import { addDays } from 'date-fns';

import { RRuleOrdinal } from '../../Utility/RRuleParser';

export class DateHelper {
  public static getOrdinal = (date: Date): RRuleOrdinal => (
    (
      addDays(date, 7).getMonth() === date.getMonth()
        ? Math.ceil(date.getDate() / 7)
        : -1
    ) as RRuleOrdinal
  );
}
