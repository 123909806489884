export class PercentageCalculator {
  public static percentage = (value: number, total: number): number => (
    total > 0 ? (value / total) : 0
  );

  public static percentage100 = (value: number, total: number): number => (
    this.percentage(value, total) * 100
  );

  public static roundedConditionally100 = (value: number, total: number): number => {
    const percentage = this.percentage100(value, total);
    return percentage > 99
      ? Math.floor(percentage)
      : percentage < 1
        ? Math.ceil(percentage)
        : Math.round(percentage);
  }
}
