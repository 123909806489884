import { ChangeEventHandler, FunctionComponent, useCallback } from 'react';
import { useIntl } from 'react-intl';

import {
  DraftRatingRequest,
  RATING_REQUEST_MAX_RATING_MAX,
  RATING_REQUEST_MAX_RATING_MIN
} from '../../../Model/ContentTypes';
import { RequestFields } from '..';
import { FieldValidationErrors, NumberInput } from '../../../../Common/Component';
import { constrain } from '../../../../Common/Utility/constrain';
import { RatingRequestValidator } from '../../../Utility/Validation';
import { ContentEditorProps } from '../../../../Content/Model';
import { ValidationTree } from '../../../../Common/Model';
import { CardValidationMerger } from '../../../../Content/Utility';
import { RatingRequestEditorContent } from '../../../Service';

export const RatingRequestFields: FunctionComponent<ContentEditorProps<RatingRequestEditorContent>> = ({
  editorContent,
  onEditorContentChanged,
  validation,
  onValidationChanged,
}) => {
  const intl = useIntl();
  const maxErrors = validation?.children.content?.children.maxRating?.errors || [];

  const whenMaxChanged: ChangeEventHandler<HTMLInputElement> = (event) => {
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          maxRating: constrain(+event.target.value, RATING_REQUEST_MAX_RATING_MIN, RATING_REQUEST_MAX_RATING_MAX),
        },
      },
    });
  };

  const whenContentChildrenValidationChanged = useCallback(
    (children: ValidationTree<DraftRatingRequest>['children']): void => {
      onValidationChanged(
        CardValidationMerger.addContentValidationChildrenToCardValidation(children, validation)
      );
    },
    [validation, onValidationChanged],
  );

  const whenMaxBlurred = () => whenContentChildrenValidationChanged({
    maxRating: RatingRequestValidator.validateMaxRating(editorContent.card.content),
  });

  return (
    <>
      <RequestFields
        editorContent={ editorContent }
        onEditorContentChanged={ onEditorContentChanged }
        validation={ validation }
        onValidationChanged={ onValidationChanged }
      />
      <NumberInput
        id="max"
        inputProps={ {
          min: RATING_REQUEST_MAX_RATING_MIN,
          max: RATING_REQUEST_MAX_RATING_MAX,
        } }
        label={ intl.formatMessage({
          id: 'form.fields.ratingRequest.maxRating.label',
          defaultMessage: 'Max rating *',
        }) }
        value={ editorContent.card.content.maxRating }
        onChange={ whenMaxChanged }
        onBlur={ whenMaxBlurred }
        error={ !!maxErrors.length }
        fullWidth
      />
      <FieldValidationErrors
        fieldName={ intl.formatMessage({
          id: 'form.fields.ratingRequest.maxRating.name',
          defaultMessage: 'max rating',
        }) }
        validationErrors={ maxErrors }
      />
    </>
  )
};
