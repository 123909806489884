import React, { FC, ReactChildren, useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { DebouncedSearchInput } from '@ourpeople/shared/Core/Component/Input/DebouncedSearchInput/DebouncedSearchInput';

import { PaginatedTable, SortableHeaderCell, TableCell, TableRow } from '../../../../Components';
import { useQueryAsState } from '../../../../Hooks';
import { QueryParser, QueryWithKeys } from '../../../../Common/Utility';
import {
  FetchBroadcastCategoriesParams,
  FetchBroadcastCategoriesSort,
  useFetchBroadcastCategories
} from '../../../Hook';
import { Flex, FlexPullRight, PresentationIcon, VerticallySpaced } from '../../../../Common/Component';
import BulbIcon from '../../../../Assets/img/icons/streamline/bulb-1.svg';
import { StyledHint } from './style';
import { CategoriesTableRow } from '../CategoriesTableRow/CategoriesTableRow';

type Query = QueryWithKeys<'pageNum' | 'search' | 'sort' | 'status'>;

type Props = {
  stale: boolean;
  onUpdate: () => void;
};

export const CategoriesTable: FC<Props> = ({
  stale,
  onUpdate,
}) => {
  const [query, setQuery] = useQueryAsState<Query>();
  const status = useMemo(() => parseStatus(query), [query]);
  const params = useMemo<FetchBroadcastCategoriesParams>(() => ({
    pageNum: QueryParser.pageNum(query),
    sort: parseSort(query),
    ...(status === 'published' ? { noUnpublished: 1 } : {}),
    ...(status === 'unpublished' ? { noPublished: 1 } : {}),
    ...(query.search ? { searchTerm: query.search } : {}),
  }), [query, status]);
  const [fetchCategoriesResult, fetchCategoriesState, reloadCategories] = useFetchBroadcastCategories(params);
  const categories = useMemo(() => fetchCategoriesResult?.content?.categories || [], [fetchCategoriesResult?.content?.categories]);
  const pagination = useMemo(() => fetchCategoriesResult?.content?.pagination, [fetchCategoriesResult?.content?.pagination]);

  useEffect(() => {
    if (!stale) {
      return;
    }

    reloadCategories();
  }, [reloadCategories, stale]);

  useEffect(() => {
    if (!fetchCategoriesResult) {
      return;
    }

    onUpdate();
  }, [fetchCategoriesResult, onUpdate]);

  const whenPageNumChanged = useCallback((pageNum: number) => (
    setQuery(query => ({
      ...query,
      pageNum: `${ pageNum }`,
    }))
  ), [setQuery]);

  const whenSortChanged = useCallback((sort: FetchBroadcastCategoriesSort) => (
    setQuery(query => ({
      ...query,
      sort,
      pageNum: '1',
    }))
  ), [setQuery]);

  const whenSearchChanged = useCallback((search: string) => (
    setQuery(({ search: previousSearch, ...query }) => ({
      ...query,
      ...(search ? { search } : {}),
      pageNum: '1',
    }))
  ), [setQuery]);

  return (
    <VerticallySpaced gap={ 2 }>
      <Flex gap={ 2 }>
        <Flex
          gap={ 2 }
          align="flex-start"
          noWrap
        >
          <PresentationIcon
            IconComponent={ BulbIcon }
            size="medium"
          />
          <StyledHint>
            <FormattedMessage
              id="broadcasts.listCategories.helpText"
              description="Help text explaining default categories."
              defaultMessage="
                Use default categories to auto populate the category field. This will reduce the number of steps for
                your team when creating a broadcast. <a>Manage default categories</a>
              "
              values={ {
                a: (chunks: ReactChildren) => <a href="#defaults">{ chunks }</a>
              } }
            />
          </StyledHint>
        </Flex>
        <FlexPullRight>
          <DebouncedSearchInput
            value={ query.search || '' }
            onChange={ whenSearchChanged }
          />
        </FlexPullRight>
      </Flex>
      <PaginatedTable
        rows={ categories }
        headerRow={
          <TableRow>
            <SortableHeaderCell
              sort={ params.sort }
              ascValue="name_asc"
              descValue="name_desc"
              onSort={ whenSortChanged }
            >
              <FormattedMessage
                id="broadcasts.listCategories.name"
                description="Table column heading for broadcast category name."
                defaultMessage="Name"
              />
            </SortableHeaderCell>
            <TableCell/>
            <TableCell/>
          </TableRow>
        }
        rowRender={ category => (
          <CategoriesTableRow
            key={ category.id }
            category={ category }
            onUpdate={ reloadCategories }
          />
        ) }
        pageNum={ params.pageNum }
        onPageChanged={ whenPageNumChanged }
        requestState={ fetchCategoriesState }
        onRetryClicked={ reloadCategories }
        pagination={ pagination }
      />
    </VerticallySpaced>
  );
};

const parseSort = QueryParser.getValueParser(
  'sort',
  ['name_asc', 'name_desc'],
  'name_asc',
);

const parseStatus = QueryParser.getValueParser(
  'status',
  ['published', 'unpublished'],
  'published',
);

