import styled from '@emotion/styled';

import { TableCell } from '../../../Components';
import { StyledTableCellProps } from '../../../Components/Content/styles';
import { PropsWithTheme } from '../../../Styling/PropsWithTheme';

export type StickyCellProps = StyledTableCellProps & {
  justify?: 'start' | 'end';
  offset?: string;
  hideBorder?: boolean;
};

export const StickyCell = styled(
  ({children, hideBorder, ...props }: PropsWithTheme<StickyCellProps>) => <TableCell {...props}>{ children }</TableCell>
)`
  position: sticky;
  background-color: inherit;
  color: inherit;
  z-index: 1;
  ${ props => `
    box-shadow: ${ (props.hideBorder ? '0' : props.justify === 'end' ? '1px' : '-1px') } 0 0 0 ${ props.theme.palette.grey[300] } inset;
  ` }

  ${ props => props.justify === 'end' && `
    right: ${ props.offset || 0};
  ` }
  ${ props => props.justify !== 'end' && `
    left: ${ props.offset || 0};
  ` }
`;
