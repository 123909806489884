import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { Message, MessageProps } from '../../Message/Message';
import { Button, ButtonProps } from '../../../Input/Button/Button';
import { CenteredContents } from '../CenteredContents/CenteredContents';

type Props = MessageProps & {
  buttonLabel?: string;
  onButtonClicked?: () => void;
  buttonVariant?: ButtonProps['variant'];
};

export const CenteredMessageWithButton: FC<Props> = ({
  buttonLabel,
  onButtonClicked,
  buttonVariant = 'primary',
  ...message
}) => (
  <CenteredContents>
    <Message { ...message } />
    { onButtonClicked && (
      <Button
        variant={ buttonVariant }
        onClick={ onButtonClicked }
      >
        { buttonLabel || (
          <FormattedMessage
            description="Default button label for CenteredMessageWithButton component."
            defaultMessage="Retry"
          />
        ) }
      </Button>
    ) }
  </CenteredContents>
);
