import { FC, useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

type Props = {
  editable: boolean;
}

export const EditablePlugin: FC<Props> = ({
  editable,
}) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    editor.setEditable(editable)
  }, [editable, editor]);

  return null;
};
