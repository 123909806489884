import { FunctionComponent } from 'react';

import { ContentReader } from '../../../../../Readers';
import { CardReport, RespondedRatingCount } from '../../Hooks/useFetchBroadcastReportSummary';
import { useNotRespondedLabelForContentType } from '../../Hooks/useNotRespondedLabelForContentType';
import { DeliveryReportTab, DeliveryReportTabs, DeliveryReportTabContainer, DeliveryReportTabContent } from './';
import { NotRespondedTable } from '../Tables/NotRespondedTable';
import { RespondedTable } from '../Tables/RespondedTable';
import { StyledStarIcon } from './styles';
import StarIcon from '../../../../../Assets/img/icons/monochrome/star_filled.svg';

interface Props {
  broadcastId: string;
  deliveryId: string;
  card: CardReport;
  pendingCount: number;
  initialSearch?: string;
}

export const RatingContentReportTabs: FunctionComponent<Props> = ({
  card,
  broadcastId,
  deliveryId,
  pendingCount,
  initialSearch,
}) => (
  <DeliveryReportTabContainer
    tabs={
      <DeliveryReportTabs>
        {
          [1, 2, 3, 4, 5].map((rating) => (
            <DeliveryReportTab
              key={`tabRatingOption${card.content.id || ''}_${rating}`}
              label={<>{`${rating}`}<StyledStarIcon><StarIcon /></StyledStarIcon></>}
              value={
                ((card.counts.responded as RespondedRatingCount).ratings || []).find(
                  (option) => option.rating === rating
                )?.count || 0
              }
              />
          ))
        }
        <DeliveryReportTab
          label={useNotRespondedLabelForContentType(
            ContentReader.contentTypeFromString(card.content.type)
          )}
          value={(card.counts.notResponded?.total || 0) - pendingCount}
          lowLight={true}
        />
      </DeliveryReportTabs>
    }>
    {
      [1, 2, 3, 4, 5].map((rating) => (
        <DeliveryReportTabContent key={`tableOption${card.content.id || ''}_${rating}`}>
          <RespondedTable
            deliveryId={deliveryId}
            broadcastId={broadcastId}
            rating={rating}
            contentId={card.content.id}
            responseType="responded"
            initialSearch={ initialSearch }
          />
        </DeliveryReportTabContent>
      ))
    }
    <DeliveryReportTabContent>
      <NotRespondedTable
        deliveryId={deliveryId}
        broadcastId={broadcastId}
        contentId={card.content.id}
        responseType="notResponded"
        initialSearch={ initialSearch }
      />
    </DeliveryReportTabContent>
  </DeliveryReportTabContainer>
);
