import { FC } from 'react';
import { ProgressCircle } from 'op-storybook/stories/components/ProgressCircle/ProgressCircle';
import { PresentationIcon } from 'op-storybook/lib/components/PresentationIcon/PresentationIcon';

import { SvgComponent } from '../../../Common/Model';

type Props = {
  IconComponent: SvgComponent;
  loading: boolean;
  className?: string;
};

export const BlockPlaceholder: FC<Props> = ({
  IconComponent,
  loading,
  className,
}) => {

  return (
    <div
      css={ theme => ({
        width: '100%',
        paddingTop: '100%',
        background: `linear-gradient(45deg, ${ theme.new.palette.grey[200].main }, ${ theme.new.palette.grey[100].main })`,
        position: 'relative',
      }) }
      className={ className }
    >
      <div
        css={ {
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        } }
      >
        {
          loading
            ? (
              <ProgressCircle
                size="lg"
                delayMs={ 300 }
                palette={ {
                  colour: 'teal',
                  intensity: 700,
                } }
              />
            )
            : (
              <PresentationIcon
                IconComponent={ IconComponent }
                size={ 16 }
                palette={ {
                  colour: 'teal',
                  intensity: 700,
                } }
              />
            )
        }
      </div>
    </div>
  );
};
