import { ComponentProps, FC, useCallback, useMemo } from 'react';

import { StatusCondition } from '../../../../Audiences/Model';
import { BadgeCloud } from '../BadgeCloud';
import { useLocalisedStatuses } from '../../../../People/Hook/useLocalisedStatuses';
import { RoleId } from '../../../../Security/Model';

type Props = {
  condition: StatusCondition;
  onChange?: (condition: StatusCondition) => void;
};

export const StatusCloud: FC<Props> = ({
  condition,
  onChange,
}) => {
  const localisedStatuses = useLocalisedStatuses();
  const items = useMemo(() => (
    localisedStatuses.filter(availableStatus => condition.statuses.includes(availableStatus.id)).map(status => ({
      label: status.localisation,
      id: status.id,
    }))
  ), [condition.statuses, localisedStatuses]);

  const whenRemoveClicked = useCallback<NonNullable<ComponentProps<typeof BadgeCloud>['onRemoveClicked']>>(badgeProps => {
    onChange && onChange({
      ...condition,
      roleIds: condition.statuses.filter(status => status !== badgeProps.id),
    });
  }, [condition, onChange]);

  return (
    <BadgeCloud
      value={ items }
      { ...onChange ? { onRemoveClicked: whenRemoveClicked } : {} }
    />
  );
};
