import { FC, ReactNode } from 'react';
import { Typography } from '@ourpeople/shared/Core/Component/Content/Typography/Typography';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { StackEnd } from 'op-storybook/lib/components/StackEnd/StackEnd';
import { Divider } from 'op-storybook/stories/components/Divider/Divider';

type Props = {
  text: string;
  supportingText?: string;
  sectionActions?: ReactNode;
  seamless?: boolean;
  children?: ReactNode;
};

/** @deprecated Prefer SectionHeader from op-storybook project */
export const SectionHeader: FC<Props> = ({
  text,
  supportingText,
  sectionActions,
  seamless = false,
  children,
}) => (
  <Stack
    gap={ 5 }
    direction="column"
  >
    <Stack
      gap={ 4 }
      align="flex-end"
    >
      <Stack
        gap={ 1 }
        direction="column"
      >
        <Typography
          size="lg"
          color="grey.900"
          weight="semibold"
        >
          { text }
        </Typography>
        { supportingText && (
          <Typography
            size="sm"
            color="grey.600"
            weight="regular"
          >
            { supportingText }
          </Typography>
        ) }
      </Stack>
      { sectionActions && (
        <StackEnd>
          { sectionActions }
        </StackEnd>
      ) }
    </Stack>
    { children }
    { !seamless && <Divider/> }
  </Stack>
);
