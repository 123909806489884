import { PropsWithChildren } from 'react';

import { TableCell } from './Table';
import { SortableHeaderButton, SortableHeaderButtonProps } from '../../Common/Component';

export const SortableHeaderCell = <AscValue extends string, DescValue extends string>({ children, ...props }: PropsWithChildren<SortableHeaderButtonProps<AscValue, DescValue>>): JSX.Element => (
  <TableCell>
    <SortableHeaderButton
      {...props}
    >
      { children }
    </SortableHeaderButton>
  </TableCell>
);
