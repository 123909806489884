import { FunctionComponent } from 'react';
import {
  CircularProgress,
  Dialog, DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@mui/material';

import {
  StyledDialogProgressContainer,
} from './styles';

type ProgressDialogProps = {
  description: string,
  disableBackdropClick?: boolean,
  disableEscapeKeyDown?: boolean,
  onClose?: (event: unknown, reason: 'backdropClick' | 'escapeKeyDown') => void,
  open: boolean,
  title: string,
};

export const ProgressDialog: FunctionComponent<ProgressDialogProps> = ({
  description, disableBackdropClick = true, disableEscapeKeyDown = true, onClose, open, title
}) => {
  return (
    <Dialog
      disableEscapeKeyDown={disableEscapeKeyDown}
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
      scroll="body"
      aria-labelledby="progress-dialog-title"
      aria-describedby="progress-dialog-description">
      <DialogTitle id="progress-dialog-title">{title}</DialogTitle>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        >
        <Grid item>
          <StyledDialogProgressContainer>
            <CircularProgress />
          </StyledDialogProgressContainer>
        </Grid>
        <Grid item>
          <DialogContent>
            <DialogContentText id="progress-dialog-description">
              {description}
            </DialogContentText>
          </DialogContent>
        </Grid>
      </Grid>
    </Dialog>
  );
};
