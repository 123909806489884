import { FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { parseISO } from 'date-fns';

import {
  CardDescription,
  CardTitle,
  RevealContentPreviewOption,
  ScrollableContent
} from '../../../../Broadcasts/Component/Content';
import { ContentPadding, NudgeBanner, RespondButton, RespondButtonProps } from '..';
import { BroadcastContentFooter, StackNavigationProps } from '../Footer/BroadcastContentFooter';
import { VerticallySpaced } from '../../../../Common/Component';
import { RevealOption } from '../../../../Broadcasts/Service';
import { BroadcastNudge } from '../../../../Broadcasts/Model';
import { AbsoluteBackButtonContainer } from '../AbsoluteBackButtonContainer/AbsoluteBackButtonContainer';

type Props = {
  title: string;
  description: string;
  options: RevealOption[];
  markAsReadProps: RespondButtonProps;
  navigationProps: StackNavigationProps;
  responded: boolean;
  hideRespondButton: boolean;
  nudge?: BroadcastNudge;
  backButton: ReactNode | undefined;
};

export const RevealBroadcastContent: FC<Props> = ({
  title,
  description,
  options,
  markAsReadProps,
  navigationProps,
  responded,
  hideRespondButton = false,
  nudge,
  backButton,
}) => {
  const intl = useIntl();

  return (
    <>
      { backButton && <AbsoluteBackButtonContainer>{ backButton }</AbsoluteBackButtonContainer> }
      <ScrollableContent>
        {
          nudge && (
            <NudgeBanner
              message={ nudge.message }
              nudgedAt={ parseISO(nudge.at) }
            />
          )
        }
        <ContentPadding>
          <VerticallySpaced gap={ 2 }>
            <CardTitle>{ title }</CardTitle>
            <CardDescription>{ description }</CardDescription>
            { options.map(option => (
              <RevealContentPreviewOption
                key={ option.id }
                previewText={ option.preview_text }
                text={ option.text }
              />
            )) }
          </VerticallySpaced>
        </ContentPadding>
      </ScrollableContent>
      <BroadcastContentFooter
        { ...navigationProps }
        respondProps={ markAsReadProps }
        responded={ responded }
      >
        { !hideRespondButton && (
          <RespondButton
            { ...markAsReadProps }
            variant="primary"
            notRespondedLabel={ intl.formatMessage({
              id: 'content.broadcast.reveal.notResponded',
              description: 'Label for mark as read button when unread.',
              defaultMessage: 'Mark as read',
            }) }
            respondedLabel={ intl.formatMessage({
              id: 'content.broadcast.reveal.responded',
              description: 'Label for mark as read button when unread.',
              defaultMessage: 'Read',
            }) }
            rejectedLabel={ intl.formatMessage({
              id: 'content.broadcast.reveal.rejected',
              description: 'Label for mark as read button when response was rejected.',
              defaultMessage: 'Try again',
            }) }
          />
        ) }
      </BroadcastContentFooter>
    </>
  );
};
