import styled from '@emotion/styled';

export const StyledSpaceIcon = styled.div`
  width: ${ props => props.theme.spacing(5) };
  height: ${ props => props.theme.spacing(5) };
  border-radius: ${ props => props.theme.borderRadius.xs };
  overflow: hidden;
  background-color: ${ props => props.theme.palette.grey[300] };
  box-shadow: ${ props => props.theme.shadows[3] };

  img {
    max-width: 100%;
  }
`;
