import styled from '@emotion/styled';
import { TextField } from '@mui/material';
import { rgba } from 'polished';

export const StyledTextField = styled(TextField)`
  margin: 0;
  width: 100%;

  .MuiInputBase-input {
    font-size: 1rem;
    padding: 0.65rem 0.75rem;
  }

  fieldset {
    padding: 0 !important;
  }
`;

export const StyledContent = styled.div<{ editable?: boolean, edited?: boolean }>`
  ${ props => !!props.editable && `
    padding: 0.65rem 0.75rem;
    min-height: 2.725rem;
    border-radius: 0.35rem;
    flex-grow: 1;

    &[role="button"] {
      cursor: text;
    }

    ${ props.edited ? `background-color: ${ rgba(props.theme.palette.secondary.main, 0.1) }; color: ${ props.theme.palette.secondary.dark };`  : ''}

    &:hover {
      background-color: ${ rgba(props.theme.palette.grey[900], 0.1) };
    }
  ` }
`;
