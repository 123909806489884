import { NavigationButton, NavigationGroup, NavigationItem } from '../Model';

export class NavigationItemIdentifier {
  public static navigationItemIsGroup = (navigationItem: NavigationItem): navigationItem is NavigationGroup => (
    !!(navigationItem as NavigationGroup).subItems
  );

  public static navigationItemIsButton = (navigationItem: NavigationItem): navigationItem is NavigationButton => (
    !!(navigationItem as NavigationButton).onClick
  );
}
