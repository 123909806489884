import { FC, ReactNode } from 'react';

import { SecondaryInformation } from '../../../stories/components/Typography/SecondaryInformation';

type Props = {
  children?: ReactNode;
};

export const FieldLabel: FC<Props> = ({ children }) => (
  <SecondaryInformation>
    { children }
  </SecondaryInformation>
);
