import { useIntl } from 'react-intl';
import {
  ValidationErrorHandler,
  ValidationErrorHandlerFactory
} from 'op-storybook/lib/utility/ValidationErrorHandlerFactory/ValidationErrorHandlerFactory';

import { MissingOneOf } from '../../../src/react/Common/Model';

export const useMissingContactMethodErrorHandler = (): ValidationErrorHandler<MissingOneOf> => {
  const intl = useIntl();

  return ValidationErrorHandlerFactory.createHandler(
    (error): error is MissingOneOf => (
      error.type === 'missingOneOf'
    ),
    () => intl.formatMessage({
      description: 'Error message when an email address or phone number are required but both are left blank',
      defaultMessage: 'An email address or phone number is required.',
    }),
  );
};
