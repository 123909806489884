import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Heading } from '@ourpeople/shared/Core/Component/Content';

import {
  BroadcastReceivedNotification,
  RespondedCounts,
} from '../../../../Sections/Broadcasts/Report/Hooks/useFetchBroadcastReportSummary';
import { Box } from '../../../../Common/Component';
import { ChannelEngagementChart, NotificationEngagementChart } from '..';
import { StyledChartContainer } from './style';
import {NotificationDeliveryChart} from '../NotificationDeliveryChart/NotificationDeliveryChart';
import {useEnvironmentSettings} from '../../../../Common/Hook';

type Props = {
  notificationCounts: BroadcastReceivedNotification[];
  respondedCounts: RespondedCounts;
};

export const ChannelReport: FC<Props> = ({
  notificationCounts,
  respondedCounts,
}) => {
  const { broadcastDeliverySuccessStatsEnabled } = useEnvironmentSettings();

  return (
    <Box>
      <Heading type="h1">
        <FormattedMessage
          id="broadcasts.report.channelReport.heading"
          description="Heading for broadcast channel report."
          defaultMessage="Channels"
        />
      </Heading>
      <StyledChartContainer>
        { broadcastDeliverySuccessStatsEnabled && (
          <NotificationDeliveryChart notificationCounts={notificationCounts} />
        ) }
        { !broadcastDeliverySuccessStatsEnabled && (
          <>
            <NotificationEngagementChart notificationCounts={ notificationCounts } />
            <ChannelEngagementChart respondedCounts={ respondedCounts } />
          </>
        ) }
      </StyledChartContainer>
    </Box>
  );
};
