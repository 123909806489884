import { FunctionComponent, useCallback, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { Audience } from '../../Model';
import { AudienceBuilder } from '../../../Audiences/Component';
import { AudienceCleaner } from '../../Utility';

type Props = {
  open: boolean;
  audience: Audience;
  onAudienceSaved: (audience: Audience) => void;
  onDismissed: () => void;
};

export const AudienceModal: FunctionComponent<Props> = ({
  open,
  audience,
  onAudienceSaved,
  onDismissed,
}) => {
  const [unsavedAudience, setUnsavedAudience] = useState<Audience>(audience);

  const whenAudienceSaved = () => {
    onAudienceSaved(AudienceCleaner.cleanAudience(unsavedAudience));
    onDismissed();
  };

  return (
    <Dialog
      open={ open }
      onClose={ onDismissed }
      TransitionProps={ {
        onEntering: useCallback(() => {
          setUnsavedAudience(audience);
        }, [audience]),
      } }
      maxWidth="md"
      fullWidth
    >
      <DialogContent>
        <AudienceBuilder
          audience={ unsavedAudience }
          onChange={ setUnsavedAudience }
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={ onDismissed }
        >
          <FormattedMessage
            id="audiences.modal.cancel"
            description="Label for cancel button in audience modal."
            defaultMessage="Cancel"
          />
        </Button>
        <Button
          color="primary"
          variant="contained"
          disableElevation
          onClick={ whenAudienceSaved }
        >
          <FormattedMessage
            id="audiences.modal.save"
            description="Label for save button in audience modal."
            defaultMessage="Apply"
          />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
