import { useMemo } from 'react';
import { AxiosRequestConfig } from 'axios';

import { FetchResponse, useRequest } from '.';

export const usePost = <ResultType>(
  endpoint: string,
  data?: Record<string, unknown>,
  serializer?: (params: unknown) => string,
  disabled?: boolean,
): FetchResponse<ResultType> => {
  const config = useMemo<AxiosRequestConfig>(() => ({
    method: 'post',
    url: endpoint,
    data,
    paramsSerializer: serializer,
  }), [endpoint, data, serializer]);

  return useRequest(
    config,
    disabled,
  );
}
