import { FunctionComponent } from 'react';

import { MeetingTableMenu } from './MeetingTableMenu';
import { TrainingTableMenu } from './TrainingTableMenu';
import { RespondedType, RespondedTable } from './RespondedTable';
import { ContentWithEvent } from '../../../../../Models';
import { ContentReader } from '../../../../../Readers';
import { ReportRecipient } from '../../../../../Broadcasts/Component';

interface Props {
  broadcastId: string;
  respondedType?: RespondedType;
  deliveryId?: string;
  content: ContentWithEvent;
  initialSearch?: string;
}

export const EventRespondedTable: FunctionComponent<Props> = ({
  broadcastId,
  respondedType,
  deliveryId,
  content,
  initialSearch,
}) => {
  const menuRender = (
    ContentReader.contentTypeFromString(content.type) === 'meeting'
    ? (recipient: ReportRecipient<Record<string, unknown>>) => (
      <MeetingTableMenu
        content={content}
        recipient={recipient}
        showRemoveAttendance={respondedType !== 'declined'}
        />
    )
    : (recipient: ReportRecipient<Record<string, unknown>>) => (
      <TrainingTableMenu
        content={content}
        recipient={recipient}
        showRemoveAttendance={respondedType !== 'declined'}
        />
    )
  );
  return (
    <RespondedTable
      broadcastId={broadcastId}
      responseType="responded"
      respondedType={respondedType}
      deliveryId={deliveryId}
      contentId={content.id}
      initialSearch={ initialSearch }
      {...(
        respondedType !== 'attended'
        ? {menuRender}
        : {}
      )}
      />
  );
}
