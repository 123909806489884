import { FunctionComponent } from 'react';

import { StyledCharacterCount } from './styles';

type CharacterCountProps = {
  value?: number;
}

/**
 * @deprecated Prefer /Content/Component/CharacterCount.tsx
 */
export const CharacterCount: FunctionComponent<CharacterCountProps> = (
  { value }
) => {
  return value !== undefined
    ? (
      <StyledCharacterCount error={ value < 0 }>{ value }</StyledCharacterCount>
    )
    : null;
};
