import { useEffect } from 'react';

import { SubmittedComponent } from '../../../../Content/Model';
import { TextContent } from '../../../Model/ContentTypes';
import { RichTextContentPreview } from '../../../../Content/Component';

export const SubmittedTextContent: SubmittedComponent<undefined, TextContent> = ({
  card,
  onFinish,
}) => {
  useEffect(() => {
    if (!onFinish) {
      return;
    }

    onFinish();
  }, [onFinish]);

  return (
    <RichTextContentPreview
      text={ card.content.html }
      placeholderBehaviour="badge"
    />
  );
};
