import { FC } from 'react';
import {FormattedList, FormattedMessage} from 'react-intl';

import { MappedSettingTypes } from '../../Types';

type Props = {
  config: MappedSettingTypes['choice']['config'];
  value: MappedSettingTypes['choice']['value'];
};

export const ChoiceSettingValue: FC<Props> = ({
  config,
  value,
}) => (
  <p>
    {
      value && value.length > 0
        ? (
          <FormattedList
            value={
              value
                .map((choiceKey) => config.choices[choiceKey])
                .filter((choiceValue) => !!choiceValue)
            }
          />
        )
        : (
          <FormattedMessage
            description="Choice setting value when unset."
            defaultMessage="None"
          />
        )
    }
  </p>
);
