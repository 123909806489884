import { ChangeEventHandler, FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';

import { SingleContentCard, LiveComponentProps } from '../../../../Content/Model';
import { UploadRequest as UploadRequestModel } from '../../../Model/ContentTypes';
import { DraftUploadResponse } from '../../../Service';
import { useContextOrThrow } from '../../../../Core/Hook';
import { FormNavigationContext } from '../../../Provider/FormNavigationProvider';
import { ValidationMerger } from '../../../../Common/Utility/ValidationMerger';
import { UploadRequest } from '../../Content';
import { ValidationTree, Validator } from '../../../../Common/Model';
import { UniqueIdGenerator, Validate } from '../../../../Common/Utility';
import { useFileUploader } from '../../../../Common/Hook';
import { useFileSelect } from '../../../../Common/Hook/useFileSelect';
import { Upload } from '../../../../Types';

export const LiveUploadRequest: FC<LiveComponentProps<UploadRequestModel, DraftUploadResponse>> = ({
  card,
  response,
  onResponseChange,
  onResponseClear,
  validation,
  onValidationChange,
}) => {
  const selectFile = useFileSelect('image/*');
  const { setProgressBlocked, setFooterContents } = useContextOrThrow(FormNavigationContext);
  const { uploadStates, upload, remove } = useFileUploader();
  const responseUploadStates = useMemo(() => (
    new Map(
      Array.from(uploadStates.entries())
        .filter(([id]) => id.includes(card.content.id))
    )
  ), [card.content.id, uploadStates]);
  const cardHasIncompleteUploads = useMemo(() => (
    !!Array.from(uploadStates.values())
      .find(uploadState => (
        !uploadState.upload
      ))
  ), [uploadStates]);
  const completedUploadIds = useMemo<string[]>(() => (
    Array.from(responseUploadStates.values())
      .filter(uploadState => !!uploadState.upload)
      .map(uploadState => (uploadState.upload as Upload).id.toString())
  ), [responseUploadStates]);
  const { displayingValidation } = useContextOrThrow(FormNavigationContext);
  const validatedResponse = useRef<DraftUploadResponse>();

  useEffect(() => {
    if (!cardHasIncompleteUploads) {
      return;
    }

    setProgressBlocked(true);
    return () => setProgressBlocked(false);
  }, [cardHasIncompleteUploads, setProgressBlocked]);

  useEffect(() => {
    if (response?.uploadIds.sort().join(',') === validatedResponse.current?.uploadIds.join(',')) {
      return;
    }

    validatedResponse.current = response;
    if (!response || !response.uploadIds.length) {
      onValidationChange({
        errors: [],
        children: {},
      });
      return;
    }

    const newValidation = validateResponse(card, response);
    onValidationChange(
      validation
        ? ValidationMerger.overwriteMerge(validation, newValidation)
        : newValidation
    );
  }, [card, onValidationChange, response, validation, validatedResponse]);

  useEffect(() => {
    if (completedUploadIds.sort().join(',') === (response?.uploadIds || []).sort().join(',')) {
      return;
    }

    if (!completedUploadIds.length) {
      onResponseClear();
      return;
    }

    const updatedResponse: DraftUploadResponse = {
      contentId: card.content.id,
      contentType: 'uploadRequest',
      uploadIds: completedUploadIds,
    };
    onResponseChange(updatedResponse);
  }, [card.content.id, completedUploadIds, onResponseChange, onResponseClear, response?.uploadIds]);

  const whenFileSelected: ChangeEventHandler<HTMLInputElement> = useCallback(event => {
    const file = event.currentTarget.files?.[0];

    if (!file) {
      return;
    }

    upload(
      `${ card.content.id }-${ UniqueIdGenerator.generate() }`,
      '/broadcasts/responses/authorise-upload',
      file
    );
  }, [card.content.id, upload]);

  useEffect(() => {
    if (uploadStates.size >= card.content.maxUploads) {
      return;
    }

    setFooterContents(
      <Button
        variant="primary"
        onClick={ () => selectFile(whenFileSelected) }
      >
        <FormattedMessage
          description="Upload button label in forms upload request."
          defaultMessage="Upload"
        />
      </Button>
    );

    return () => setFooterContents(undefined);
  }, [card.content.maxUploads, selectFile, setFooterContents, uploadStates.size, whenFileSelected]);

  return (
    <UploadRequest
      uploadStates={ responseUploadStates }
      validation={ validation }
      displayValidation={ displayingValidation }
      content={ card.content }
      onRemoveClicked={ remove }
    />
  );
};

const validateResponse = (card: SingleContentCard<UploadRequestModel>, response: DraftUploadResponse): ValidationTree<DraftUploadResponse> => ({
  errors: [],
  children: {
    uploadIds: {
      errors: new Validator<string[]>([
        uploadIds => Validate.countLessThanEq(uploadIds, card.content.maxUploads),
        uploadIds => Validate.countGreaterThanEq(uploadIds, card.content.minUploads),
      ]).validate(response.uploadIds),
      children: {},
    },
  },
});
