import { FormControl, FormLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { ChangeEventHandler, ReactNode, useCallback } from 'react';
import { AutocompleteSelectionChanged } from '@ourpeople/shared/Core/Component/Input/Autocomplete/Autocomplete';

import { StyledFormControlLabel } from '../TextContentEditor/style';
import { CharacterCount } from '../../../../Content/Component';
import { FieldValidationErrors, Notice } from '../../../../Common/Component';
import { FileAutocomplete } from '../FileAutocomplete/FileAutocomplete';
import { FormAutocomplete } from '../../../../Forms/Component';
import { Form } from '../../../../Forms/Model';
import { ValidationTree } from '../../../../Common/Model';
import { ArrayHelper } from '../../../../Common/Utility';
import {
  LINK_LABEL_MAX_LENGTH,
  LINK_LABEL_MIN_LENGTH,
  LinkValidator
} from '../../../Service/Validators/LinkValidator/LinkValidator';
import { LinkType } from '../../../Model/ContentLink';
import { MinimalFileEntry } from '../../../../Files/Model/FileEntry';

export interface DraftLink {
  linkLabel?: string;
  linkTarget?: null | string;
  linkType?: LinkType;
}

type Props<T extends DraftLink> = {
  allowFile: boolean;
  allowForm: boolean;
  draftLink: T;
  validation: ValidationTree<T>;
  form: Form | null;
  file: MinimalFileEntry | null;
  onChange: (draftLink: T, form: Form | null, file: MinimalFileEntry | null) => void;
  onValidationChange: (validation: ValidationTree<T>) => void;
};

export const LinkEditor = <T extends DraftLink>({
  allowFile,
  allowForm,
  draftLink,
  validation,
  form,
  file,
  onChange,
  onValidationChange,
}: Props<T>): JSX.Element => {
  const intl = useIntl();

  const buttonLabelName = intl.formatMessage({
    description: 'Name for text content button label field.',
    defaultMessage: 'Button label',
  });

  const urlName = intl.formatMessage({
    description: 'Name for text content url field.',
    defaultMessage: 'URL',
  });

  const whenButtonLabelBlurred = useCallback((): void => (
    onValidationChange({
      ...validation,
      children: {
        ...validation.children,
        linkLabel: LinkValidator.validateButtonLabel(draftLink.linkLabel || ''),
      },
    })
  ), [draftLink.linkLabel, onValidationChange, validation]);

  const whenUrlBlurred = useCallback((): void => (
    onValidationChange({
      ...validation,
      children: {
        ...validation.children,
        linkTarget: LinkValidator.validateUrl(draftLink.linkTarget || ''),
      },
    })
  ), [draftLink.linkTarget, onValidationChange, validation]);

  const whenTargetIdBlurred = useCallback(() => (
    onValidationChange({
      ...validation,
      children: {
        ...validation.children,
        linkTarget: LinkValidator.validateId(draftLink.linkTarget || ''),
      },
    })
  ), [draftLink.linkTarget, onValidationChange, validation]);

  const whenButtonLabelChanged: ChangeEventHandler<HTMLInputElement> = useCallback(event => (
    onChange(
      {
        ...draftLink,
        linkLabel: event.currentTarget.value,
      },
      form,
      file,
    )
  ), [draftLink, file, form, onChange]);

  const whenUrlChanged: ChangeEventHandler<HTMLInputElement> = useCallback(event => (
    onChange(
      {
        ...draftLink,
        linkTarget: event.currentTarget.value,
      },
      form,
      file,
    )
  ), [draftLink, file, form, onChange]);

  const whenLinkTypeChanged: ChangeEventHandler<HTMLInputElement> = useCallback(event => {
    const linkType = event.currentTarget.value;

    if (!linkTypeIsValid(linkType)) {
      return;
    }

    onChange(
      {
        ...draftLink,
        linkType: linkType === 'none' ? undefined : linkType,
        linkLabel: linkType === 'none' ? undefined : draftLink.linkLabel || '',
        linkTarget: linkType === 'form'
          ? form?.id
          : linkType === 'fileEntry'
            ? file?.id
            : undefined,
      },
      draftLink.linkType === 'form' ? form : null,
      draftLink.linkType === 'fileEntry' ? file : null,
    );
    onValidationChange({
      errors: validation.errors || [],
      children: {
        ...validation.children,
        linkTarget: {
          errors: [],
          children: {},
        },
        ...linkType === 'none'
          ? {
            linkType: {
              errors: [],
              children: {},
            },
            linkLabel: {
              errors: [],
              children: {},
            },
          }
          : {},
      },
    });
  }, [draftLink, file, form, onChange, onValidationChange, validation.children, validation.errors]);

  const whenFileChanged = useCallback((fileEntry: MinimalFileEntry) => (
    onChange(
      {
        ...draftLink,
        linkTarget: fileEntry.id,
      },
      null,
      fileEntry,
    )
  ), [draftLink, onChange]);

  const whenFormChanged: AutocompleteSelectionChanged<Form> = useCallback((selection) => {
    const form = selection.options[0];

    if (!form) {
      return;
    }

    return onChange(
      {
        ...draftLink,
        linkTarget: form.id,
      },
      form,
      null,
    );
  }, [draftLink, onChange]);

  return (
    <>
      <FormControl component="fieldset">
        <FormLabel component="legend">
          <FormattedMessage
            id="broadcasts.content.text.fields.buttonWithLink.legend"
            description="Legend for button with link fieldset."
            defaultMessage="Include link"
          />
        </FormLabel>
        <RadioGroup
          aria-label="link-type"
          defaultValue="none"
          value={
            draftLink.linkType === 'fileEntry'
              ? allowFile
                ? 'fileEntry'
                : 'none'
              : draftLink.linkType === 'form' && allowForm
                ? 'form'
                : draftLink.linkType || 'none'
          }
          onChange={ whenLinkTypeChanged }
        >
          <StyledFormControlLabel
            value="none"
            control={ <Radio size="small"/> }
            label={ intl.formatMessage({
              description: 'Label for no link option.',
              defaultMessage: 'None',
            }) }
          />
          <StyledFormControlLabel
            value="url"
            control={ <Radio size="small"/> }
            label={ intl.formatMessage({
              description: 'Label for url link type option.',
              defaultMessage: 'Url',
            }) }
          />
          { allowFile && (
            <StyledFormControlLabel
              value="fileEntry"
              control={ <Radio size="small"/> }
              label={ intl.formatMessage({
                description: 'Label for file link type option.',
                defaultMessage: 'File',
              }) }
            />
          ) }
          { allowForm && (
            <StyledFormControlLabel
              value="form"
              control={ <Radio size="small"/> }
              label={ intl.formatMessage({
                description: 'Label for form link type option.',
                defaultMessage: 'Form',
              }) }
            />
          ) }
        </RadioGroup>
      </FormControl>
      { draftLink.linkType && (
        <div>
          <TextField
            margin="dense"
            label={ buttonLabelName }
            fullWidth
            value={ draftLink.linkLabel }
            onChange={ whenButtonLabelChanged }
            onBlur={ whenButtonLabelBlurred }
          />
          <CharacterCount
            current={ draftLink.linkLabel?.length || 0 }
            minimum={ LINK_LABEL_MIN_LENGTH }
            maximum={ LINK_LABEL_MAX_LENGTH }
          />
          <FieldValidationErrors
            fieldName={ buttonLabelName }
            validationErrors={ validation?.children.linkLabel?.errors || [] }
          />
        </div>
      ) }
      { draftLink.linkType === 'url' && (
        <div>
          <TextField
            margin="dense"
            label={ urlName }
            fullWidth
            value={ draftLink.linkTarget }
            onChange={ whenUrlChanged }
            onBlur={ whenUrlBlurred }
          />
          <FieldValidationErrors
            fieldName={ urlName }
            validationErrors={ validation?.children.linkTarget?.errors || [] }
          />
        </div>
      ) }
      { draftLink.linkType === 'fileEntry' && (
        <>
          <FileAutocomplete
            fileEntry={ file || undefined }
            onChange={ whenFileChanged }
            errors={ validation?.children.linkTarget?.errors || [] }
            onBlur={ whenTargetIdBlurred }
          />
          <Notice
            variant="outlined"
            feedback={ {
              severity: 'none',
              message: intl.formatMessage<ReactNode>({
                description: 'Notice displayed regarding file permissions and visibility.',
                defaultMessage: 'Files may not be visible to everyone if they are restricted by permissions. <a>Go to files</a> to view permissions.'
              }, {
                a: chunks => (
                  <a
                    href="/files"
                    target="_blank"
                    rel="noreferrer"
                  >
                    { chunks }
                  </a>
                ),
              })
            } }
          />
        </>
      ) }
      { draftLink.linkType === 'form' && (
        <>
          <FormAutocomplete
            selectedIds={ form ? [form.id] : [] }
            onSelectionChanged={ whenFormChanged }
            onBlur={ whenTargetIdBlurred }
            variant="standard"
          />
          <Notice
            variant="outlined"
            feedback={ {
              severity: 'none',
              message: intl.formatMessage<ReactNode>({
                description: 'Notice displayed regarding file permissions and visibility.',
                defaultMessage: 'Forms may not be visible to everyone if they are restricted by permissions. <a>Go to forms</a> to view permissions.'
              }, {
                a: chunks => (
                  <a
                    href="/forms"
                    target="_blank"
                    rel="noreferrer"
                  >
                    { chunks }
                  </a>
                ),
              })
            } }
          />
        </>
      ) }
    </>
  );
};

const linkTypeIsValid = ArrayHelper.createTypeGuard(['none', 'url', 'fileEntry', 'form']);
