import { IconButton } from '@mui/material';
import { FunctionComponent, ReactNode, useMemo, useState } from 'react';
import * as React from 'react';

import MoreVertIcon from '../../Assets/img/icons/monochrome/more-vertical.svg';
import { StyledTableMenuContainer, StyledTableMenu } from './styles';
import { LoadingSpinner } from '../Loading';

type Props = {
  rowKey: number | string;
  disabled?: boolean;
  busy?: boolean;
  children?: ReactNode;
}

/** @deprecated Prefer ContextMenu **/
export const TableMenu: FunctionComponent<Props> = ({
  children,
  rowKey,
  disabled = false,
  busy = false,
}) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const whenClicked = (event: React.MouseEvent<HTMLElement>): void => {
    if (!disabled) {
      setAnchorEl(event.currentTarget);
    }
  };

  const whenClosed = (): void => {
    setAnchorEl(null);
  };

  return (
    <StyledTableMenuContainer>
      {
        busy
          ? (
            <LoadingSpinner/>
          )
          : (
            <IconButton
              disabled={disabled}
              aria-label="more"
              aria-controls={ `table-menu-${ rowKey }` }
              aria-haspopup="true"
              onClick={ whenClicked }
              size="large">
              <MoreVertIcon/>
            </IconButton>
          )
      }
      <StyledTableMenu
        id={ `table-menu-${ rowKey }` }
        anchorEl={ anchorEl }
        anchorOrigin={ {
          horizontal: 'center',
          vertical: 'bottom',
        } }
        transformOrigin={ {
          horizontal: 'center',
          vertical: 'top',
        } }
        keepMounted
        open={ open }
        onClose={ whenClosed }
        onClick={ whenClosed }
      >
        { children }
      </StyledTableMenu>
    </StyledTableMenuContainer>
  );
}
