import { FunctionComponent } from 'react';
import { Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { LinearCellGraph, SimpleTable, TableCell, TableRow } from '../../../../../Components';
import { CardReport, BroadcastReportSummary } from '../../Hooks/useFetchBroadcastReportSummary';
import { ContentIcon } from '../../../Common/ContentIcon';
import { EventStatus } from '../../../../../Events/Component';
import { ContentReader } from '../../../../../Readers';

interface Props {
  summary: BroadcastReportSummary;
}

export const EventSummaryTable: FunctionComponent<Props> = ({
  summary
}) => {
  return (
    <SimpleTable
      headerRow={(
        <TableRow>
          <TableCell width="5%">
            <FormattedMessage
              id="broadcast.report.card"
              defaultMessage="Card"
              />
          </TableCell>
          <TableCell>
            <FormattedMessage
              id="broadcast.report.cardName"
              defaultMessage="Card Name"
              />
          </TableCell>
          <TableCell width="12%">
            <FormattedMessage
              id="broadcast.report.status"
              defaultMessage="Status"
              />
          </TableCell>
          <TableCell width="12%">
            <FormattedMessage
              id="broadcast.report.seen"
              defaultMessage="Seen"
              />
          </TableCell>
          <TableCell width="12%">
            <FormattedMessage
              id="broadcast.report.responded"
              defaultMessage="Responded"
              />
          </TableCell>
          <TableCell width="10%"></TableCell>
        </TableRow>
      )}
      rowRender={ (card: CardReport) => {
        const recipients = (
          (card.counts.seen?.total || 0) + (card.counts.notSeen?.total || 0)
        );
        return (
          <TableRow key={ card.content.id }>
            <TableCell>
              <ContentIcon
                contentType={
                  ContentReader.contentTypeFromString(card.content.type)
                }
                />
            </TableCell>
            <TableCell maxwidth="0" ellipsisOverflow={true}>
              {ContentReader.titleForContent(card.content) || ''}
            </TableCell>
            <TableCell>
              {
                card.status !== null && (
                  <EventStatus
                    contentType={ContentReader.contentTypeFromString(card.content.type)}
                    status={card.status}
                    withName={false}
                    />
                )
              }
            </TableCell>
            <TableCell>
              <LinearCellGraph
                label={`${card.counts.seen?.total || 0}`}
                percentage={
                  recipients > 0
                  ? (card.counts.seen?.total || 0) / recipients
                  : 0
                }
                />
            </TableCell>
            <TableCell>
            <LinearCellGraph
              label={`${card.counts.responded?.total || 0}`}
              percentage={
                recipients > 0
                  ? (card.counts.responded?.total || 0) / recipients
                  : 0
              }
            />
            </TableCell>
            <TableCell>
              {
                card.content.id && (
                  <Button
                    color="secondary"
                    href={`#content${card.content.id}`}
                    >
                      <FormattedMessage
                        id="broadcast.report.viewCard"
                        defaultMessage="View Card"
                        />
                    </Button>
                )
              }
            </TableCell>
          </TableRow>
        );
      }}
      rows={summary.cards.filter(card => ContentReader.contentTypeFromString(card.content.type) !== 'stack')}
      />
  );
};
