import { FC } from 'react';
import { parseISO } from 'date-fns';

import { MappedSettingTypes } from '../../Types';
import { DateTimeFormatter } from '../../../../../Utility/DateTimeFormatter';

type Props = {
  value: MappedSettingTypes['datetime']['value'],
};

export const DateTimeSettingValue: FC<Props> = ({
  value,
}) => (
  <span>{ value && DateTimeFormatter.internationalised(parseISO(value), navigator.language) }</span>
);
