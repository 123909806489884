import { ComponentProps, useMemo } from 'react';

import { useOpenUpload } from '../../Files/Hook';
import { LinkContent } from '../Component/BroadcastContent/LinkContent/LinkContent';
import { useEnvironmentSettings } from '../../Common/Hook';
import { ContentLink } from '../../Broadcasts/Model/ContentLink';
import { FileEntry } from '../../Files/Model/FileEntry';

export const useLinkProps = (
  link: ContentLink | null,
  loading: boolean,
  insufficientPermission: boolean,
  fileEntry: FileEntry | null,
  disableFilePath: boolean,
): ComponentProps<typeof LinkContent> | null => {
  const [openUpload] = useOpenUpload();
  const { formsEnabled = false, filesEnabled = false } = useEnvironmentSettings();
  const linkIsFileDirectory = link?.type === 'fileEntry' && fileEntry && fileEntry.type !== 'file';
  const linkIsUnavailableFile = !filesEnabled && link?.type === 'fileEntry';
  const linkIsUnavailableForm = !formsEnabled && link?.type === 'form';
  return useMemo<ComponentProps<typeof LinkContent> | null>(() => (
    link
      ? {
        onFileLinkClick: fileEntry => fileEntry.upload?.id && openUpload(fileEntry.upload.id),
        showMissingContentError:
          linkIsFileDirectory
          || linkIsUnavailableFile
          || linkIsUnavailableForm,
        showPermissionError: insufficientPermission,
        showLoadingIndicator: loading,
        linkType: link.type,
        linkLabel: link.label,
        linkTarget: link.url || link.fileEntry?.id || link.formId,
        fileEntry: fileEntry || undefined,
        disableFilePath,
      }
      : null
  ), [
    disableFilePath,
    fileEntry,
    insufficientPermission,
    link,
    linkIsFileDirectory,
    linkIsUnavailableFile,
    linkIsUnavailableForm,
    loading,
    openUpload
  ]);
};
