import styled from '@emotion/styled';
import { Grid, RadioGroup } from '@mui/material';

export const StyledRadioGroup = styled(RadioGroup)`
  ${({ theme }) => theme.breakpoints.down('lg')} {
    width: auto;
  }
`;

export const StyledGrid = styled(Grid)`
  display: flex;
  justify-content: center;
`;

export const StyledGridDescription = styled(Grid)`
  display: flex;
  justify-content: center;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: none;
  }
`;

export const StyledRoleLabel = styled.div`

`;

export const StyledRoleDescription = styled.div`
  color: ${({ theme }) => theme.palette.grey[600]};
  font-size: 1rem;
`;
