import { default as React, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { TextField } from '@mui/material';

import { DraftRevealOption } from '../../../Service/ContentDefinition/RevealContentDefinition/RevealContentDefinition';
import {
    REVEAL_OPTION_TEXT_MAX_LENGTH,
    REVEAL_OPTION_TEXT_MIN_LENGTH,
    REVEAL_OPTION_TITLE_MAX_LENGTH,
    REVEAL_OPTION_TITLE_MIN_LENGTH,
    RevealContentValidator,
} from '../../../Service/Validators/RevealContentValidator/RevealContentValidator';
import { VerticallySpaced } from '../../../../Common/Component/VerticallySpaced/VerticallySpaced';
import { IconButton } from '../../../../Common/Component/IconButton/IconButton';
import { FieldValidationErrors } from '../../../../Common/Component/FieldValidationErrors/FieldValidationErrors';
import DeleteIcon from '../../../../Assets/img/icons/streamline/bin-1.svg';
import { useInputChangeEventHandler } from '../../../../Common/Hook/useInputChangeEventHandler';
import { CharacterCount } from '../../../../Content/Component/CharacterCount/CharacterCount';
import { StyledTitleInputRow } from './style';
import { OptionEditorComponent } from '../OptionsEditor/OptionsEditor';

export const RevealContentOptionEditor: OptionEditorComponent<DraftRevealOption> = ({
  option,
  onChange,
  removeDisabled,
  validation,
  onValidationChange,
  onRemoveClicked,
}) => {
  const intl = useIntl();
  const titleFieldLabel = intl.formatMessage({
    id: 'broadcasts.content.reveal.fields.optionTitle.label',
    description: 'Label for reveal content option title',
    defaultMessage: 'Title',
  });
  const textFieldLabel = intl.formatMessage({
    id: 'broadcasts.content.reveal.fields.optionText.label',
    description: 'Label for reveal content option text',
    defaultMessage: 'Content',
  });

  const whenTitleChanged = useInputChangeEventHandler(useCallback((title: string) => (
    onChange({
      ...option,
      preview_text: title
    })
  ), [onChange, option]));

  const whenTextChanged = useInputChangeEventHandler(useCallback((text: string) => (
    onChange({
      ...option,
      text,
    })
  ), [onChange, option]));

  const whenTitleBlurred = useCallback(() => (
    onValidationChange({
      errors: validation?.errors || [],
      children: {
        ...validation?.children,
        preview_text: RevealContentValidator.validateOptionTitle(option.preview_text),
      },
    })
  ), [onValidationChange, option.preview_text, validation]);

  const whenTextBlurred = useCallback(() => (
    onValidationChange({
      errors: validation?.errors || [],
      children: {
        ...validation?.children,
        text: RevealContentValidator.validateOptionText(option.text),
      },
    })
  ), [onValidationChange, option.text, validation]);

  return (
    <VerticallySpaced gap={ 1 }>
      <StyledTitleInputRow>
        <VerticallySpaced gap={ 1 }>
          <TextField
            fullWidth
            id={ `title-${ option.id }` }
            value={ option.preview_text }
            onChange={ whenTitleChanged }
            label={ titleFieldLabel }
            onBlur={ whenTitleBlurred }
          />
          <CharacterCount
            current={ option.preview_text.length }
            minimum={ REVEAL_OPTION_TITLE_MIN_LENGTH }
            maximum={ REVEAL_OPTION_TITLE_MAX_LENGTH }
          />
          <FieldValidationErrors
            fieldName={ titleFieldLabel }
            validationErrors={ validation?.children.preview_text?.errors || [] }
          />
        </VerticallySpaced>
        <IconButton
          IconComponent={ DeleteIcon }
          disabled={ removeDisabled }
          label={intl.formatMessage({
            id: 'broadcasts.content.reveal.deleteOptionLabel',
            description: 'Delete reveal content option button label visible only to screen-readers.',
            defaultMessage: 'Delete',
          })}
          onClick={ onRemoveClicked }
          size="small"
        />
      </StyledTitleInputRow>
      <TextField
        fullWidth
        id={ `content-${ option.id }` }
        value={ option.text }
        onChange={ whenTextChanged }
        label={ textFieldLabel }
        onBlur={ whenTextBlurred }
      />
      <CharacterCount
        current={ option.text.length }
        minimum={ REVEAL_OPTION_TEXT_MIN_LENGTH }
        maximum={ REVEAL_OPTION_TEXT_MAX_LENGTH }
      />
      <FieldValidationErrors
        fieldName={ textFieldLabel }
        validationErrors={ validation?.children.text?.errors || [] }
      />
    </VerticallySpaced>
  );
}
