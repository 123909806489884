import { useMemo, useState, VFC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';

import {
  StyledCenteredButton,
  StyledExpandedIcon,
  StyledFileIcon,
  StyledFilePath,
  StyledFolderIcon,
  StyledLoadingIndicator,
  StyledPathSegment
} from './style';
import { LoadingSpinner } from '../../../../Components';
import { Flex, PresentationIcon, ValidationErrorMessage, VerticallySpaced } from '../../../../Common/Component';
import ChevronIcon from '../../../../Assets/img/icons/monochrome/chevron.svg';
import { useEnvironmentSettings } from '../../../../Common/Hook';
import { FileEntry, MinimalFileEntry } from '../../../../Files/Model/FileEntry';
import { LinkType } from '../../../../Broadcasts/Model/ContentLink';

type Props = {
  onFileLinkClick: (fileEntry: MinimalFileEntry) => void;
  showPermissionError?: boolean;
  showMissingContentError?: boolean;
  showLoadingIndicator?: boolean;
  linkLabel?: string;
  linkType?: LinkType;
  linkTarget?: null | string;
  fileEntry?: FileEntry;
  disableFilePath?: boolean;
};

export const LinkContent: VFC<Props> = ({
  onFileLinkClick,
  showPermissionError = false,
  showMissingContentError = false,
  showLoadingIndicator = false,
  linkLabel,
  linkType,
  linkTarget,
  fileEntry,
  disableFilePath = false,
}) => {
  const { filesEnabled = false, formsEnabled = false } = useEnvironmentSettings();
  const [showingFilePath, setShowingFilePath] = useState<boolean>(false);
  const pathParts = useMemo(() => (
    fileEntry && (fileEntry.path.split('/').slice(1) || []).filter(Boolean).concat(fileEntry.name)
  ), [fileEntry]);
  const linkIsAvailableFile = linkType === 'fileEntry' && filesEnabled && !!fileEntry;
  const linkEnabled = linkType === 'url' || linkIsAvailableFile || formsEnabled;
  const showButton = !!linkLabel && !!linkTarget && linkEnabled;

  return showLoadingIndicator
    ? <StyledLoadingIndicator><LoadingSpinner/></StyledLoadingIndicator>
    : (
      <VerticallySpaced
        gap={ 1 }
        align="center"
      >
        {
          showMissingContentError
            ? (
              <ValidationErrorMessage>
                <FormattedMessage
                  id="broadcasts.content.preview.text.unsupportedContent"
                  description="Error message displayed in text card when it contains content not supported in the inbox."
                  defaultMessage="This card contains content that is currently only supported in the app. Please view this card in the app to see all content."
                />
              </ValidationErrorMessage>
            )
            : (
              <>
                { showButton && (
                  linkType === 'fileEntry' && fileEntry
                    ? (
                      <Button
                        variant="primary"
                        onClick={ () => onFileLinkClick(fileEntry) }
                      >
                        { linkLabel }
                      </Button>
                    )
                    : (
                      <a
                        href={ linkType === 'url' ? linkTarget : `/my-forms/${ linkTarget }` }
                        rel="noreferrer"
                        target="_blank"
                      >
                        <StyledCenteredButton
                          variant="primary"
                        >
                          { linkLabel }
                        </StyledCenteredButton>
                      </a>
                    )
                ) }
                {
                  linkType === 'fileEntry' && (
                    fileEntry && !showPermissionError
                      ? !disableFilePath && (
                        <>
                          <StyledCenteredButton
                            variant="tertiary"
                            onClick={ () => setShowingFilePath(showingFilePath => !showingFilePath) }
                          >
                            <Flex gap={ 2 }>
                              <FormattedMessage
                                id="broadcasts.content.preview.text.toggleFilePath"
                                description="Label for button to toggle showing file path in preview."
                                defaultMessage="Tap to { showing, select, true { hide } other { show } } file path"
                                values={ {
                                  showing: showingFilePath,
                                } }
                              />
                              <StyledExpandedIcon $showing={ showingFilePath }>
                                <ChevronIcon/>
                              </StyledExpandedIcon>
                            </Flex>
                          </StyledCenteredButton>
                          { showingFilePath && pathParts && (
                            <StyledFilePath>
                              { pathParts.map((segment, index) => (
                                <StyledPathSegment key={ index }>
                                  <PresentationIcon
                                    IconComponent={
                                      index >= pathParts.length - 1 && fileEntry?.type !== 'folder'
                                        ? StyledFileIcon
                                        : StyledFolderIcon
                                    }
                                  />
                                  <span>
                                    { segment }
                                  </span>
                                </StyledPathSegment>
                              )) }
                            </StyledFilePath>
                          ) }
                        </>
                      )
                      : showPermissionError && (
                      <ValidationErrorMessage>
                        <FormattedMessage
                          id="broadcasts.content.preview.text.insufficientPermissionError"
                          description="Error message displayed in text card when user has insufficient permissions to view the file."
                          defaultMessage="You don't have permission to view this file"
                        />
                      </ValidationErrorMessage>
                    )
                  )
                }
              </>
            )
        }
      </VerticallySpaced>
    );
};
