import styled from '@emotion/styled';

export const StyledCardOutline = styled.div`
  padding-top: 178%;
`;

export const StyledCard = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const StyledContainer = styled.div`
  z-index: 1;
  display: flex;
  height: 100%;
  align-items: center;

  ${ props => props.theme.new.breakpoints['sm'].down } {
    padding: ${ props => props.theme.new.spacing[2] };
  }
`;

export const StyledCardContainer = styled.div`
  width: 42vh;
  margin: auto;
    
  ${ props => props.theme.new.breakpoints['sm'].down } {
    width: 100%;
    height: 100%;
    margin: 0;
  }
    
  min-width: 250px;
  background-color: ${ props => props.theme.palette.background.paper };
  box-shadow: 0 5px 10px rgba(0,0,0,0.05);
  position: relative;
  display: flex;
  justify-content: center;
`;
