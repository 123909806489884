import { FunctionComponent, PropsWithChildren } from 'react';
import { Heading } from '@ourpeople/shared/Core/Component/Content';

import {
  Content, CoverContent,
  CoverStatusDetail,
  MeetingStatusDetail, TrainingContent,
  TrainingStatusDetail
} from '../../../../../Models';
import { ContentReader } from '../../../../../Readers';
import { ContentIcon } from '../../../Common/ContentIcon';
import { EventContentPreview } from './EventContentPreview';
import { FileContentPreview } from './FileContentPreview';
import { ImageContentPreview } from './ImageContentPreview';
import { RevealContentPreview } from './RevealContentPreview';
import { TextContentPreview } from './TextContentPreview';
import { ContentSummaryHeading } from './styles';

interface ContentPreviewProps {
  content: Content;
  status: CoverStatusDetail | MeetingStatusDetail | TrainingStatusDetail | null;
}

export const ContentPreview: FunctionComponent<ContentPreviewProps> = ({
  content,
  status
}) => {
  const contentType = ContentReader.contentTypeFromString(content.type);
  switch (contentType) {
    case 'reveal':
      return (
        <ContentPreviewWithIcon content={content}>
          <RevealContentPreview content={content} />
        </ContentPreviewWithIcon>
      );
    case 'cover':
    case 'meeting':
    case 'training':
      return status && (
        <ContentPreviewWithIcon content={content}>
          <EventContentPreview
            content={content}
            status={status}
            comment={ contentType === 'cover' ? (content as CoverContent).comment : undefined }
            successTags={ contentType === 'training' ? (content as TrainingContent).successTags : undefined }
          />
        </ContentPreviewWithIcon>
      );
    case 'audio':
    case 'file':
    case 'video':
      return (
        <ContentPreviewWithIcon content={content}>
          <FileContentPreview content={content} />
        </ContentPreviewWithIcon>
      );
    case 'image':
    case 'styled_image':
      return (
        <ImageContentPreview content={content} />
      );
    case 'text':
      return (
        <ContentPreviewWithIcon content={content}>
          <TextContentPreview content={content} />
        </ContentPreviewWithIcon>
      );
    default:
      return (
        <ContentPreviewWithIcon content={content}>
          <Heading type="h3">{ContentReader.titleForContent(content) || ''}</Heading>
        </ContentPreviewWithIcon>
      );
  }
};

interface ContentPreviewContainerProps {
  content: Content;
}

const ContentPreviewWithIcon: FunctionComponent<PropsWithChildren<ContentPreviewContainerProps>> = ({
  children,
  content,
}) => (
  <ContentSummaryHeading id={`content${content.id || ''}`}>
    <ContentIcon
      contentType={
        ContentReader.contentTypeFromString(content.type)
      }
      size="3vw"
      />
    {children}
  </ContentSummaryHeading>
);
