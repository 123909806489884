type ArrayTypeGuard<S extends string | number> = (value: string | number) => value is S;
type ArrayTypeFilter<S extends string | number> = (arrayToFilter: (string | number)[]) => S[];

export class ArrayHelper {
  public static createTypeGuard = <S extends string | number>(arr: S[]): ArrayTypeGuard<S> => (value): value is S => containsValue(arr, value);
  public static createTypeFilter = <S extends string | number>(guard: ArrayTypeGuard<S>): ArrayTypeFilter<S> => {
    return (arrayToFilter: (string | number)[]) => arrayToFilter.filter(guard);
  };

  public static replaceOrAddItemWithId = <T extends { id: string }>(
    arr: T[],
    newItem: T,
  ): T[] => (
    replaceOrAdd(
      arr,
      compareId,
      newItem,
    )
  );

  public static replace = <T>(arr: T[], index: number, item: T): T[] => {
    const arrClone = [
      ...arr,
    ];
    arrClone[index] = item;
    return arrClone;
  };

  public static findAndReplace = <T>(arr: T[], find: (item: T) => boolean, replaceWith: T, addIfMissing: boolean = false): T[] => {
    const index = arr.findIndex(find);

    if (index === -1) {
      if (addIfMissing) {
        return arr.concat(replaceWith);
      }

      return arr;
    }

    return ArrayHelper.replace(arr, index, replaceWith);
  };

  public static findAndRemove = <T>(arr: T[], find: (item: T) => boolean): T[] => {
    const index = arr.findIndex(find);
    return ArrayHelper.remove(arr, index);
  };

  public static findAndMove = <T>(arr: T[], find: (item: T) => boolean, destinationOffset: number): T[] => {
    const index = arr.findIndex(find);
    return ArrayHelper.move(
      arr,
      index,
      Math.max(0, Math.min(index + destinationOffset, arr.length - 1)),
    );
  };

  public static remove = <T>(arr: T[], index: number): T[] => {
    return arr
      .slice(0, index)
      .concat(
        arr
          .slice(index + 1, arr.length)
      );
  };

  public static insert = <T>(arr: T[], index: number, item: T): T[] => {
    return arr.slice(0, index)
      .concat(item)
      .concat(arr.slice(index, arr.length));
  };

  public static move = <T>(arr: T[], sourceIndex: number, destinationIndex: number): T[] => (
    ArrayHelper.insert(
      ArrayHelper.remove(arr, sourceIndex),
      destinationIndex,
      arr[sourceIndex],
    )
  );

  public static shuffle = <T>(arr: T[]): T[] => shuffleInto([], arr);

  public static containSameValues = <T>(arr1: T[], arr2: T[]): boolean => {
    if (arr1.length !== arr2.length) {
      return false;
    }

    return arr1.every((item) => arr2.includes(item));
  };

  public static objectify = <T>(arr: T[]): Record<number, T> => ({
    ...arr,
  });

  public static coalesceEmpty<T, R>(arr: T[], emptyValue: R): T[] | R {
    return arr.length ? arr : emptyValue;
  }
}

const shuffleInto = <T>(arr: T[], fromArray: T[]): T[] => {
  if (!fromArray.length) {
    return arr;
  }

  const srcIndex = Math.floor(Math.random() * fromArray.length);
  return shuffleInto(
    arr.concat(fromArray[srcIndex]),
    ArrayHelper.remove(fromArray, srcIndex),
  );
};

const containsValue = <T extends string | number, S extends T>(arr: S[], value: T): value is S => {
  return arr.indexOf(value as S) !== -1;
};

const replaceOrAdd = <T, >(arr: T[], compare: (item: T, newItem: T) => boolean, newItem: T): T[] => {
  const index = arr.findIndex(item => compare(item, newItem));

  return index === -1
    ? arr.concat(newItem)
    : arr.slice(0, index)
      .concat(newItem)
      .concat(arr.slice(index + 1));
};

const compareId = <T extends { id: string }>(a: T, b: T) => a.id === b.id;
