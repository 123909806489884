import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CircularProgress } from '@mui/material';
import { Typography } from 'op-storybook/stories/components/Typography/Typography';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';

import { FieldValidationErrors, Flex } from '../../../Common/Component';
import { ThumbnailSelector } from '../../../Broadcasts/Component';
import { ValidationError } from '../../../Common/Model';

type Props = {
  errors: ValidationError[];
  busy: boolean;
  uploadId: string | undefined;
  selectedKey: string | undefined;
  onChange: (selectedKey: string) => void;
};

export const ThumbnailSelectorField: FC<Props> = ({
  uploadId,
  selectedKey,
  onChange,
  errors,
  busy,
}) => {
  const intl = useIntl();

  const label = intl.formatMessage({
    description: 'Label for thumbnail selector in form and broadcast editors',
    defaultMessage: 'Thumbnail',
  });

  return (
    <Stack direction="column" gap={ 1 }>
      <Typography
        size="lg"
        weight="bold"
      >
        <FormattedMessage
          description="Label for thumbnail field in video upload content."
          defaultMessage="Thumbnail"
        />
      </Typography>
      {
        !!uploadId && !busy
          ? (
            <ThumbnailSelector
              uploadId={ uploadId }
              onChange={ onChange }
              selectedThumbnailKey={ selectedKey }
            />
          )
          : (
            <Flex noWrap gap={ 2 }>
              { busy && (
                <CircularProgress
                  variant="indeterminate"
                  size={ 24 }
                />
              ) }
              <FormattedMessage
                description="Loading message for thumbnail section of video content editor while video is transcoding."
                defaultMessage="Thumbnails will be available when video processing is complete"
              />
            </Flex>
          )
      }
      <FieldValidationErrors
        fieldName={ label }
        validationErrors={ errors }
      />
    </Stack>
  )
};
