import { FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Page, PagingDirection, PagingMenu } from '../PagingMenu/PagingMenu';
import { PromiseHelper } from '../../../../Core/Utility/PromiseHelper';
import { FetchResult } from '../../../../../src/react/Models/FetchResult';
import { AiPromptList } from '../AiPromptList/AiPromptList';
import { useMounted } from '../../../../../src/react/Common/Hook/useMounted';
import { AiPromptResult } from '../AiPromptResult/AiPromptResult';
import { AiUserPrompt, TemplatePrompt } from '../AiUserPrompt/AiUserPrompt';
import { AiTemplatePromptMenu } from '../AiTemplatePromptMenu/AiTemplatePromptMenu';
import { AiBetaDisclaimer } from '../AiBetaDisclaimer/AiBetaDisclaimer';

export type Prompt = {
  value: string;
  label: string;
  type: 'generate' | 'edit';
  perform: (input?: string) => Promise<string>;
};

export type PromptWithState = {
  prompt: Prompt;
  fetchResult: FetchResult<string> | null;
};

type Props = {
  open: boolean;
  onToggle: (open: boolean) => void;
  beginOnUserPrompt: boolean;
  headerText: string;
  buttonLabel: ReactNode;
  prompts: Prompt[];
  input: string;
  onChange: (value: string) => void;
  mobile?: boolean;
  userPrompt: Prompt;
  templateOptions: TemplatePrompt[];
  onTemplatePromptSelected?: (templatePrompt: TemplatePrompt) => void;
  onNavigate?: (to: string) => void;
  onUseClicked?: () => void;
};

export const AiPromptMenu: FC<Props> = ({
  open,
  onToggle,
  beginOnUserPrompt,
  headerText,
  buttonLabel,
  prompts,
  input,
  onChange,
  mobile,
  userPrompt,
  templateOptions,
  onTemplatePromptSelected,
  onNavigate,
  onUseClicked,
}) => {
  const [exited, setExited] = useState<boolean>(true);
  const [userPromptSelected, setUserPromptSelected] = useState<boolean>(beginOnUserPrompt || !input);
  const rootPageKey = useMemo<string>(() => !input || beginOnUserPrompt ? 'userPrompt' : 'prompts', [beginOnUserPrompt, input]);
  const [pageKeyHistory, setPageKeyHistory] = useState<string[]>([]);
  const [pageKey, setPageKey] = useState<string>(rootPageKey);
  const [direction, setDirection] = useState<PagingDirection>(PagingDirection.FORWARDS);
  const mounted = useMounted();
  const [activePrompt, setActivePrompt] = useState<PromptWithState>();
  const completePrompt = useRef<string>();
  const [userPromptInput, setUserPromptInput] = useState<string>('');
  const performPromptOnInputChange = useRef<boolean>(false);

  const resetToRoot = useCallback(() => {
    setPageKey(rootPageKey);
  }, [rootPageKey]);

  const navigate = useCallback((to: string) => {
    setPageKey(to);
    onNavigate && onNavigate(to);
  }, [onNavigate]);

  const navigateForwards = useCallback((to: string) => {
    setDirection(PagingDirection.FORWARDS);
    setPageKeyHistory(pageKeyHistory => [pageKey].concat(pageKeyHistory));
    navigate(to);
  }, [navigate, pageKey]);

  const navigateBackwards = useCallback((to?: string) => {
    setDirection(PagingDirection.BACKWARDS);
    setPageKeyHistory(pageKeyHistory => pageKeyHistory.slice(1));

    if (to) {
      return navigate(to);
    }

    const previousPageKey: string | undefined = pageKeyHistory[0];

    if (!previousPageKey) {
      return;
    }

    navigate(previousPageKey);
  }, [navigate, pageKeyHistory]);

  const reset = useCallback(() => {
    setActivePrompt(undefined);
    resetToRoot();
    setPageKeyHistory([]);
    setUserPromptSelected(!input || beginOnUserPrompt);
    setUserPromptInput('');
  }, [beginOnUserPrompt, input, resetToRoot]);

  // Set exited to false when menu is opened
  useEffect(() => {
    if (!open) {
      return;
    }

    setExited(false);
  }, [open, resetToRoot]);

  // Update root page key while menu is closed and exit transition is complete
  useEffect(() => {
    if (!exited) {
      return;
    }

    resetToRoot();
  }, [exited, resetToRoot]);

  useEffect(() => {
    if (!activePrompt) {
      return;
    }

    completePrompt.current = activePrompt.prompt.value;
  }, [activePrompt]);

  const performPrompt = useCallback((prompt: Prompt) => {
    if (activePrompt && activePrompt.fetchResult === null) {
      return;
    }

    setActivePrompt({
      prompt,
      fetchResult: null,
    });
    navigateForwards('result');
    PromiseHelper.minimumDuration(prompt.perform(prompt.type === 'generate' ? userPromptInput : input))
      .then(result => {
        if (!mounted.current || completePrompt.current !== prompt.value) {
          return;
        }

        setActivePrompt({
          prompt,
          fetchResult: FetchResult.fromContent(result),
        });
      })
      .catch(error => {
        if (!mounted.current || completePrompt.current !== prompt.value) {
          return;
        }

        setActivePrompt({
          prompt: prompt,
          fetchResult: FetchResult.fromError(error),
        });
      });
  }, [activePrompt, input, mounted, navigateForwards, userPromptInput, userPromptSelected]);

  const whenUserPromptSelected = useCallback(() => {
    setUserPromptSelected(true);
    navigateForwards('userPrompt');
  }, [navigateForwards]);

  useEffect(() => {
    if (!performPromptOnInputChange.current) {
      return;
    }

    performPrompt(userPrompt);
    performPromptOnInputChange.current = false;
  }, [performPrompt, userPrompt, userPromptInput]);

  const applyTemplatePrompt = useCallback((templatePrompt: TemplatePrompt) => {
    onTemplatePromptSelected && onTemplatePromptSelected(templatePrompt);
    setUserPromptSelected(true);
    setUserPromptInput(templatePrompt.input);
    performPromptOnInputChange.current = true;
  }, [onTemplatePromptSelected]);

  const pages = useMemo<Page[]>(() => ([
    {
      key: 'prompts',
      maxWidth: 300,
      render: () => (
        <AiPromptList
          headerText={ headerText }
          prompts={ prompts }
          onPromptSelected={ prompt => {
            setUserPromptSelected(false);
            performPrompt(prompt);
          } }
          userPromptLabel={ userPrompt.label }
          onUserPromptSelected={ whenUserPromptSelected }
        />
      ),
    },
    {
      key: 'userPrompt',
      maxWidth: 530,
      render: () => (
        <AiUserPrompt
          userPromptLabel={ userPrompt.label }
          value={ userPromptInput }
          onChange={ setUserPromptInput }
          onSubmit={ () => performPrompt(userPrompt) }
          templatePrompts={ templateOptions }
          onBetaButtonClicked={ () => navigateForwards('disclaimer') }
          onMoreClicked={ () => navigateForwards('templates') }
          onTemplatePromptSelected={ applyTemplatePrompt }
          {
            ...input
              ? {
                onBackClicked: () => {
                  navigateBackwards('prompts');
                },
              }
              : {}
          }
        />
      ),
    },
    {
      key: 'disclaimer',
      maxWidth: 530,
      render: () => <AiBetaDisclaimer onBackClicked={ () => navigateBackwards() } />,
    },
    {
      key: 'templates',
      maxWidth: 530,
      render: () => (
        <AiTemplatePromptMenu
          onBackClicked={ () => navigateBackwards('userPrompt') }
          onBetaButtonClicked={ () => navigateForwards('disclaimer') }
          onTemplatePromptSelected={ templatePrompt => {
            applyTemplatePrompt(templatePrompt);
            navigateBackwards('userPrompt')
          } }
          templatePrompts={ templateOptions }
        />
      ),
    },
    {
      key: 'result',
      maxWidth: 450,
      render: () => (
        activePrompt
          ? (
            <AiPromptResult
              onBackClicked={ () => navigateBackwards() }
              onBetaButtonClicked={ () => navigateForwards('disclaimer') }
              promptWithState={ activePrompt }
              onRetryClicked={ () => performPrompt(activePrompt.prompt) }
              onUseClicked={ value => {
                onChange(value);
                onToggle(false);
                onUseClicked && onUseClicked();
              } }
            />
          )
          : <></>
      ),
    },
  ]), [
    headerText,
    prompts,
    userPrompt,
    whenUserPromptSelected,
    performPrompt,
    userPromptInput,
    templateOptions,
    applyTemplatePrompt,
    input,
    navigateForwards,
    navigateBackwards,
    activePrompt,
    onChange,
    onToggle,
    onUseClicked
  ]);

  return (
    <PagingMenu
      open={ open }
      onToggle={ onToggle }
      onExited={ () => {
        reset();
        setExited(true);
      } }
      direction={ direction }
      pages={ pages }
      anchor="right"
      mobile={ mobile }
      buttonLabel={ buttonLabel }
      pageKey={ pageKey }
    />
  );
};
