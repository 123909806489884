import {FC, useMemo, useState} from 'react';
import {useQuery} from '@tanstack/react-query';
import audioVisualSrc from 'op-storybook/lib/assets/BroadcastEditor/audio-visual.svg?url';

import {useContextOrThrow} from '../../../../../Core/Hook';
import {ApiContext} from '../../../../../Contexts';
import {UploadUrls} from '../../../../../Common/Hook';
import {VideoPlaceholder} from './VideoPlaceholder';

type Props = {
  id: string;
  videoThumbnailKey?: string;
  videoDimensions?: {
    width: number;
    height: number;
  };
  readOnly?: boolean;
  originalMimeType: string;
  className?: string;
};

export const VideoUpload: FC<Props> = ({
  id,
  videoThumbnailKey,
  videoDimensions,
  readOnly = false,
  originalMimeType,
  className,
}) => {
  const [reveal, setReveal] = useState(false);
  const api = useContextOrThrow(ApiContext);
  const queryKey = useMemo(() => (
    ['urls', { uploadId: id, videoThumbnailKey }] as const
  ), [id, videoThumbnailKey]);
  const isAudio = !!/^audio\//.exec(originalMimeType);
  const dimensions = useMemo(() => (
    isAudio
      ? { width: 1920, height: 1080 }
      : videoDimensions
  ), [isAudio, videoDimensions]);

  const { data: urls, status } = useQuery({
    queryFn: async ({ queryKey }) => {
      const response = await api.get<UploadUrls>(`/uploads/${ queryKey[1].uploadId }/urls`, { params: { videoThumbnailKey: queryKey[1].videoThumbnailKey } });
      return response.data;
    },
    queryKey,
  });

  /**
   * Seemingly, Safari does not load the first frame of the video if the timestamp is 0. Applying a small offset
   * fixes this. See https://developer.apple.com/forums/thread/129377
   */
  const timestampString = !isAudio && !urls?.thumbnail ? '#t=0.001' : '';

  return (
    status === 'success'
      ? (
        <div
          css={ {
            width: '100%',
            lineHeight: 0,
            ...dimensions ? {aspectRatio: `${dimensions.width / dimensions.height}`} : {},
          } }
          className={ className }
        >
          <video
            css={ {
              ...isAudio ? { height: '100%' } : {},
              width: '100%',
              opacity: reveal ? 1 : 0,
              transition: 'opacity 200ms',
            } }
            { ...readOnly ? {} : { controls: true } }
            preload="metadata"
            onLoadedMetadata={ () => setReveal(true) }
            onError={ () => setReveal(false) }
            {
              ...isAudio
                ? { poster: audioVisualSrc }
                : urls.thumbnail
                  ? { poster: urls.thumbnail }
                  : { }
            }
          >
            { (urls.streamable || []).map(streamableUrl => (
              <source
                key={ streamableUrl.url }
                src={ `${streamableUrl.url}${timestampString}` }
                type={ `video/${ streamableUrl.name.toLowerCase() }` }
              />
            )) }
            <source
              key={ urls.original }
              src={ `${urls.original}${timestampString}` }
              type={ originalMimeType }
            />
          </video>
        </div>
      )
      : (
        <VideoPlaceholder
          loading={ status === 'pending' }
          css={ {
            padding: 0,
            height: '100%',
            ...dimensions ? {aspectRatio: `${dimensions.width / dimensions.height}`} : {},
          } }
        />
      )
  );
};
