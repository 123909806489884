import { FunctionComponent } from 'react';
import {
  SwitchProps,
} from '@mui/material';

import { StyledSwitch } from './styles';

/** @deprecated prefer Switch from op-storybook project */
export const Switch: FunctionComponent<SwitchProps> = ({children, ...props}) => {
  return <StyledSwitch {...props} />;
}
