import { DraftFormRequest, FormRequest } from '../Content';

export const TEXT_REQUEST_MIN_LENGTH_MIN = 1;
export const TEXT_REQUEST_MIN_LENGTH_MAX = 4096;
export const TEXT_REQUEST_MAX_LENGTH_MIN = 1;
export const TEXT_REQUEST_MAX_LENGTH_MAX = 4096;

export type TextRequest = FormRequest & {
  type: 'textRequest';
  multiline: boolean;
  minLength: number;
  maxLength: number;
};

export type DraftTextRequest = DraftFormRequest & {
  type: 'textRequest';
  multiline: boolean;
  minLength: number;
  maxLength: number;
};
