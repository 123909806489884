import { ChangeEventHandler, Dispatch, FC, ReactNode, SetStateAction, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { format, parse } from 'date-fns';

import { StyledScheduleMonthlySettings } from '../ScheduleMonthlySettings/style';
import { NoMarginTextField } from '../../../Common/Component';
import { InterFieldText } from '../InterFieldText/InterFieldText';
import { DateTimeFormatter } from '../../../Utility';
import { AnnualSchedule } from '../../Model/BroadcastSchedule';

type Props = {
  schedule: AnnualSchedule;
  setSchedule: Dispatch<SetStateAction<AnnualSchedule>>;
};

export const ScheduleAnnuallySettings: FC<Props> = ({
  schedule,
  setSchedule,
}) => {
  const todaysDate = useMemo(() => format(new Date(), 'yyyy-MM-dd'), []);
  const timeInputString = useMemo(() => (
    DateTimeFormatter.timeInput(
      new Date(
        0,
        0,
        1,
        schedule.recurrence.repetition.localHour,
        schedule.recurrence.repetition.minutes,
        schedule.recurrence.repetition.seconds,
      ),
    )
  ), [
    schedule.recurrence.repetition.localHour,
    schedule.recurrence.repetition.minutes,
    schedule.recurrence.repetition.seconds
  ]);
  const dateInputString = useMemo(() => (
    DateTimeFormatter.dateInput(schedule.localStartDate)
  ), [schedule.localStartDate]);

  const whenTimeChanged: ChangeEventHandler<HTMLInputElement> = useCallback(event => {
    if (!event.currentTarget.value) {
      return;
    }

    const time = parse(event.currentTarget.value, 'HH:mm', new Date());
    setSchedule(schedule => ({
      ...schedule,
      recurrence: {
        ...schedule.recurrence,
        repetition: {
          ...schedule.recurrence.repetition,
          localHour: time.getHours(),
          minutes: time.getMinutes(),
        },
      },
    }));
  }, [setSchedule]);

  const whenStartDateChanged: ChangeEventHandler<HTMLInputElement> = useCallback(event => {
    if (!event.currentTarget.value) {
      return;
    }

    const time = new Date(
      0,
      0,
      1,
      schedule.recurrence.repetition.localHour,
      schedule.recurrence.repetition.minutes,
      schedule.recurrence.repetition.seconds,
    );
    const dateTime = parse(event.currentTarget.value, 'yyyy-MM-dd', time);
    setSchedule(schedule => ({
      ...schedule,
      localStartDate: dateTime,
      recurrence: {
        ...schedule.recurrence,
        repetition: {
          ...schedule.recurrence.repetition,
          localHour: dateTime.getHours(),
          minutes: dateTime.getMinutes(),
          seconds: dateTime.getSeconds(),
        }
      }
    }));
  }, [
    schedule.recurrence.repetition.localHour,
    schedule.recurrence.repetition.minutes,
    schedule.recurrence.repetition.seconds,
    setSchedule
  ]);

  return (
    <StyledScheduleMonthlySettings>
      <FormattedMessage
        id="scheduleRecurring.fields"
        description="Layout of form fields for daily and weekly recurring broadcast schedule."
        defaultMessage="<text>starting on</text>{date}<text>at</text>{time}"
        values={ {
          date: (
            <NoMarginTextField
              id="broadcast-scheduling-start-on"
              variant="outlined"
              type="date"
              required
              name="scheduledDate"
              InputProps={ {
                inputProps: {
                  min: todaysDate,
                }
              } }
              onChange={ whenStartDateChanged }
              value={ dateInputString }
            />
          ),
          time: (
            <NoMarginTextField
              id="broadcast-repeating-at"
              variant="outlined"
              type="time"
              required
              name="scheduledTime"
              onChange={ whenTimeChanged }
              value={ timeInputString }
            />
          ),
          text: (chunks: (string | ReactNode)[]) => (
            <InterFieldText>{ chunks }</InterFieldText>
          )
        } }
      />
    </StyledScheduleMonthlySettings>
  );
};
