import styled from '@emotion/styled';
import { DialogContent } from '@mui/material';

export const StyledDialogContent = styled(DialogContent)`
  &.MuiDialogContent-root {
    padding: 0;
    background-color: #000000;
    overflow: hidden;

    video, iframe {
      max-height: 80vh;
      margin-bottom: -8px;
    }

    iframe {
      min-height: 42vmin;
    }
  }
`;

export const StyledCloseButton = styled.div`
  position: absolute;
  top: ${ props => props.theme.spacing(1) };
  right: ${ props => props.theme.spacing(1) };
  filter: invert(1);
  z-index: 1;
`;
