import {PreviewComponent} from '../../../Model/BroadcastBlockDefinition';
import {VideoBlock} from '../../../Service/Blocks/VideoBlockDefinition';
import {VideoUpload} from './VideoUpload';
import {VideoPlaceholder} from './VideoPlaceholder';

export const VideoBlockPreview: PreviewComponent<VideoBlock> = ({
  attachments,
  block,
}) => {
  const upload = attachments.uploads.find(upload => upload.uuid === block.attributes.uploadId);
  const dimensions = upload?.video?.width && upload?.video?.height
    ? {
      width: upload.video.width,
      height: upload.video.height,
    }
    : undefined;

  return (
    <div
      css={ theme => ({
        width: '100%',
        background: theme.new.palette.grey[300].main,
      }) }
    >
      {
        upload?.id
          ? (
            <VideoUpload
              id={ String(upload.id) }
              videoThumbnailKey={ block.attributes.videoThumbnailKey }
              originalMimeType={ upload.mimeType }
              videoDimensions={ dimensions }
            />
          )
          : <VideoPlaceholder loading={ false }/>
      }
    </div>
  );
};
