import { ComponentProps, FC, useCallback, useEffect, useMemo, useState } from 'react';
import * as RadTabs from '@radix-ui/react-tabs';

import { StyledActiveTabIndicator, StyledTabList, StyledTabWrapper } from './style';
import { Tab } from '../../../lib/components/Tab/Tab';

type TabData = {
  value: string;
  label: string;
  newBadge?: boolean;
  count?: number;
};

type Props = {
  tabData: TabData[];
  context: TabContext;
  value: string;
  getTabProps?: (tabData: TabData) => Partial<ComponentProps<typeof Tab>>;
  onChange?: (value: string) => void;
  spaceEvenly?: boolean;
  fitContent?: boolean;
};

export type TabContext = 'page' | 'section';

export const TabBar: FC<Props> = ({
  tabData,
  context,
  value,
  getTabProps,
  onChange,
  spaceEvenly = false,
  fitContent = false,
}) => {
  const [activeTabProperties, setActiveTabProperties] = useState({
    width: 0,
    translateX: 0,
  });

  const activeTabRef = useCallback((node: HTMLButtonElement | null) => {
    if (!node) {
      return;
    }

    setActiveTabProperties({
      width: node.clientWidth,
      translateX: node.offsetLeft,
    });
  }, []);

  const renderTab = useCallback((tabData: TabData) => {
    const active = value === tabData.value;

    return (
      <Tab
        key={ tabData.value }
        { ...getTabProps ? getTabProps(tabData) : {} }
        { ...active ? { innerRef: activeTabRef } : {} }
        context={ context }
        active={ active }
        value={ tabData.value }
        label={ tabData.label }
        newBadge={ tabData.newBadge }
        count={ tabData.count }
        center={ spaceEvenly }
      />
    );
  }, [activeTabRef, context, getTabProps, value]);

  const renderedTabs = useMemo(() => tabData.map(renderTab), [renderTab, tabData]);

  return (
    <RadTabs.Root
      value={ value }
      onValueChange={ onChange }
    >
      <StyledTabList
        context={ context }
        fitContent={ fitContent }
      >
        <StyledTabWrapper
          context={ context }
          spaceEvenly={ spaceEvenly }
        >
          <StyledActiveTabIndicator
            context={ context }
            style={ {
              transform: `translateX(${ activeTabProperties.translateX }px)`,
              width: `${ activeTabProperties.width }px`,
            } }
          />
          { renderedTabs }
        </StyledTabWrapper>
      </StyledTabList>
    </RadTabs.Root>
  );
};
