import { createContext, PropsWithChildren, useContext } from 'react';
import { FC, useEffect, useMemo, useState } from 'react';
import { ThemeContext } from '@emotion/react';

import { defaultTheme } from '../../const/defaultTheme';

export type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export type BreakpointValues = Record<Breakpoint, boolean>;
export type BreakpointContextValue = {
  lessThan: BreakpointValues;
};

export const BreakpointContext = createContext<BreakpointContextValue | null>(null);
BreakpointContext.displayName = 'BreakpointContext';

export const BreakpointProvider: FC<PropsWithChildren<Record<string, unknown>>> = ({
  children,
}) => {
  const { new: { breakpoints } } = useContext(ThemeContext) as typeof defaultTheme;
  const [activeBreakpoints, setActiveBreakpoints] = useState<Breakpoint[]>([]);

  useEffect(() => {
    const initialActiveBreakpoints: Breakpoint[] = [];
    const cleanupFns = breakpointKeys.map<() => void>(breakpointKey => {
      const whenBreakpointTriggered = (event: MediaQueryListEvent): void => (
        setActiveBreakpoints(activeBreakpoints => (
          event.matches
            ? activeBreakpoints.includes(breakpointKey)
              ? activeBreakpoints
              : activeBreakpoints.concat(breakpointKey)
            : activeBreakpoints.includes(breakpointKey)
              ? activeBreakpoints.filter(activeBreakpointKey => activeBreakpointKey !== breakpointKey)
              : activeBreakpoints
        ))
      );
      const matchMedia = window.matchMedia(breakpoints[breakpointKey].down.replace('@media ', ''));

      if (matchMedia.matches) {
        initialActiveBreakpoints.push(breakpointKey);
      }

      matchMedia.addEventListener
        ? matchMedia.addEventListener('change', whenBreakpointTriggered)
        : matchMedia.addListener(whenBreakpointTriggered);

      return () => matchMedia.removeEventListener
        ? matchMedia.removeEventListener('change', whenBreakpointTriggered)
        : matchMedia.removeListener(whenBreakpointTriggered);
    }, []);

    setActiveBreakpoints(initialActiveBreakpoints);

    return () => {
      cleanupFns.forEach(cleanupFn => cleanupFn());
    };
  }, [breakpoints]);

  const value = useMemo<BreakpointContextValue>(() => ({
    lessThan: breakpointKeys.reduce<Partial<BreakpointValues>>(
      (partialReturnValue, breakpoint) => ({
        ...partialReturnValue,
        [breakpoint]: activeBreakpoints.includes(breakpoint),
      }),
      {},
    ) as BreakpointValues,
  }), [activeBreakpoints]);

  return (
    <BreakpointContext.Provider value={ value }>
      { children }
    </BreakpointContext.Provider>
  );
};

const breakpointKeys: Breakpoint[] = [
  'xs',
  'sm',
  'md',
  'lg',
  'xl',
];
