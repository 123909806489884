import { FC, useMemo } from 'react';

import { SimpleTable } from '../../../Components';
import { useLoggedInAuthDescription } from '../../../Core/Hook';
import { SingleContentSubmission } from '../../Model';
import { useSubmissionTableRowRenderFunction } from '../../Hook';
import { SubmissionTableHeaderRow } from '..';

type Props = {
  anonymous?: boolean;
};

export const SubmissionTablePreview: FC<Props> = ({ anonymous = false }) => {
  const date = useMemo(() => new Date().toISOString(), [])
  const { user } = useLoggedInAuthDescription();
  const rows: SingleContentSubmission[] = [
    {
      formSubmission: anonymous
        ? {
          id: '0',
          formId: '0',
          created: null,
          anonymous: true,
          closed: null,
        }
        : {
          id: '0',
          formId: '0',
          created: {
            at: date,
            by: {
              id: user.id,
              firstName: user.forename || 'Creator name',
              lastName: user.lastname || null,
            },
          },
          anonymous: false,
          closed: null,
        },
      cardSubmissions: [],
    }
  ];

  return (
    <SimpleTable
      rows={ rows }
      headerRow={
        <SubmissionTableHeaderRow
          sort="created_at_asc"
          onSort={ () => null }
          statusTrackerEnabled={ true }
          anonymous={ anonymous }
        />
      }
      rowRender={
        useSubmissionTableRowRenderFunction(
          true,
          [],
          () => null,
          true,
        )
      }
    />
  )
}
