import { FC } from 'react';

import { DateOffsetInput } from '../../../Core/Component/Input/DateOffsetInput/DateOffsetInput';
import { DateOffset } from '../../../Core/Model/DateOffset';
import { useReportDateRangeInputOptions } from '../../Hook/useReportDateRangeInputOptions';

type Props = {
  value: DateOffset | null;
  onChange: (value: DateOffset | null) => void;
  displayEmpty?: boolean;
};

export const ReportDateOffsetInput: FC<Props> = ({
  value,
  onChange,
  displayEmpty = false,
}) => {
  const options = useReportDateRangeInputOptions(displayEmpty);

  return (
    <DateOffsetInput
      options={ options }
      value={ value }
      onChange={ onChange }
    />
  );
};
