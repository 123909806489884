import styled from '@emotion/styled';

import { Flex } from '..';

export const StyledPageHeader = styled.div<{ useNewTheme: boolean }>`
  ${ props => !props.useNewTheme && `
    margin-bottom: ${ props.theme.spacing(3) };

    ${ Flex.toString() } {
      gap: 1rem;
    }

    ${ props.theme.breakpoints.down('lg') } {
      margin-bottom: ${ props.theme.spacing(2) };
    }

    ${ props.theme.breakpoints.down('sm') } {
      padding: var(--header-height) ${ props.theme.spacing(1) } 0;
      margin-bottom: ${ props.theme.spacing(1) };
    }
  ` }
`;

export const StyledBreadcrumb = styled.div`
  text-decoration: none;
  display: flex;
  align-items: center;

  a {
    text-decoration: none;
  }

  h1 {
    color: ${props => props.theme.palette.primary.main};
  }
`;

interface BreadcrumbSubPageProps {
  last?: boolean;
}

export const StyledBreadcrumbSubPage = styled.div<BreadcrumbSubPageProps>`
  &::before {
    content: "›";
    font-size: 2rem;
    line-height: 1em;
    color: ${props => props.theme.palette.text.primary};
    margin: 0 1rem;
  }

  display: flex;
  align-items: center;

  ${props => props.last && 'overflow: hidden;'}

  a, h1 {
    color: ${props => props.theme.palette.text.primary};
    font-weight: normal;
    overflow: hidden;
    white-space: nowrap;
    max-width: 30vw;
    text-overflow: ellipsis;
  }
`;
