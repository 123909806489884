import styled from '@emotion/styled';

export const StyledContentWithType = styled.div`
  padding-top: ${ props => props.theme.spacing(4) };
  display: flex;
  flex-direction: column;
  gap: ${ props => props.theme.spacing(4) };
  align-items: flex-start;
`;

export const StyledContentType = styled.span`
  padding: ${ props => `${ props.theme.spacing(0.5) } ${ props.theme.spacing(2) }` };
  background-color: ${ props => props.theme.palette.secondary.main };
  color: ${ props => props.theme.palette.secondary.contrastText };
  line-height: 1;
`;

export const StyledContent = styled.div`
  padding: ${ props => `0 ${ props.theme.spacing(2) }` };
  width: 100%;
`;
