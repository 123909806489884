import { ComponentProps, FC } from 'react';

import { FeaturedGraphic } from '../../../lib/components/FeaturedGraphic/FeaturedGraphic';
import { PaletteKey } from '../../../lib/types/Theme/Theme';
import { StyleBuilder } from '../../../lib/model/StyleBuilder/StyleBuilder';

type Props = {
  src: string;
  alt: string;
  backgroundColour?: PaletteKey;
  borderless?: boolean;
  size?: ComponentProps<typeof FeaturedGraphic>['size'];
  className?: string;
};

export const LogoMark: FC<Props> = ({
  size = 'lg',
  className,
  ...props
}) => {
  const {
    src,
    alt,
    borderless,
  } = props;
  const styles = buildStyles(props);
  return (
    <FeaturedGraphic
      variant={ borderless ? 'modern-borderless' : 'modern' }
      size={ size }
      colour="grey"
      className={ className }
    >
      <img
        css={ styles.image }
        src={ src }
        alt={ alt }
      />
    </FeaturedGraphic>
  )
};

const buildStyles: StyleBuilder<Props> = (props) => ({
  image: theme => ({
    width: '100%',
    height: '100%',
    backgroundColor: props.backgroundColour
      ? theme.new.palette[props.backgroundColour][700].main
      : 'transparent',
  }),
});
