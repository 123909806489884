import styled from '@emotion/styled';

export const StyledChoiceSettingInput = styled.div`
  margin-bottom: ${ props => props.theme.spacing(1) };
`;

export const StyledRenderedValue = styled.span`
  p {
    margin-bottom: 0;
  }
`;
