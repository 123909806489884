import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { useIntl } from 'react-intl';
import { ConfirmationDialog } from '@ourpeople/shared/Core/Component/Feedback';
import { LineClamp } from 'op-storybook/stories/components/LineClamp/LineClamp';

import { TableCell, TableRow } from '../../../Components';
import { BroadcastIcon } from '../BroadcastIcon/BroadcastIcon';
import {
  ReadableRecurrenceRule
} from '../../../Sections/Broadcasts/Common/ReadableRecurrenceRule/ReadableRecurrenceRule';
import { DateTime, Flex, DeprecatedTooltip, VerticallySpaced } from '../../../Common/Component';
import { BroadcastsPermission } from '../../Model';
import { usePermissions } from '../../../Security/Hook';
import { InsufficientCreditIndicator } from '../InsufficientCreditIndicator/InsufficientCreditIndicator';
import { TableRowContextMenu } from '../../../Common/Component/TableRowContextMenu/TableRowContextMenu';
import {
  useArchiveBroadcastAction,
  useDuplicateBroadcastAction,
  useResourceLockMessage,
  useViewReportBroadcastAction
} from '../../Hook';
import { PopOverState } from '../../../Common/Model';
import { useApi, useContextOrThrow, useLoggedInAuthDescription } from '../../../Core/Hook';
import { useMounted } from '../../../Common/Hook';
import { ToastContext } from '../../../Core/Context';
import StopIcon from '../../../Assets/img/icons/streamline/button-stop.svg';
import { UpcomingSpend } from '../../../Credit/Model';
import { useShareDeliveryAction } from '../../Hook/TableActions/useShareDeliveryAction';
import { useEditBroadcastAction } from '../../Hook/TableActions/useEditBroadcastAction';
import { SharedDelivery } from '../../Hook/useFetchSharedDeliveries';
import { PersonParser } from '../../../Utility';
import { ShareAudience } from '../ShareAudience/ShareAudience';
import { StyledDisabledLink } from '../DraftsTable/style';
import { GuardedLink } from '../../../Security/Component';
import {
  InlinePersonAvatarAndName
} from '../../../Common/Component/InlinePersonAvatarAndName/InlinePersonAvatarAndName';

type Props = {
  calculatingCredit: boolean;
  delivery: SharedDelivery;
  onReloadRequired: () => void;
  upcomingSpend?: UpcomingSpend;
};

export const ScheduledTableRow: FC<Props> = ({
  calculatingCredit,
  delivery,
  onReloadRequired,
  upcomingSpend,
}) => {
  const intl = useIntl();
  const api = useApi();
  const mounted = useMounted();
  const creditDeficit = useMemo(() => (
    !upcomingSpend || upcomingSpend.availableCreditCount > upcomingSpend.spend.creditCount
      ? 0
      : Math.abs(upcomingSpend.availableCreditCount - upcomingSpend.spend.creditCount)
  ), [upcomingSpend]);
  const { addSuccessToast, addErrorToast } = useContextOrThrow(ToastContext);
  const { permissionAvailable, guardedCallback } = usePermissions();
  const [performingAction, setPerformingAction] = useState<boolean>(false);
  const [stopDialogState, setStopDialogState] = useState<PopOverState>(PopOverState.CLOSED);
  const [stopping, setStopping] = useState<boolean>(false);
  const resourceLockMessage = useResourceLockMessage(delivery);
  const { user: { id: userId } } = useLoggedInAuthDescription();
  const {
    archiveConfirmationDialog,
    archiveAction,
    archiving,
  } = useArchiveBroadcastAction(
    delivery.broadcast.id,
    resourceLockMessage,
    performingAction,
  );
  const {
    duplicateAction,
    duplicating,
  } = useDuplicateBroadcastAction(
    delivery.broadcast.id,
    performingAction,
  );
  const {
    shareAction,
    shareConfirmationDialog,
    sharing,
  } = useShareDeliveryAction(
    delivery,
    resourceLockMessage,
    performingAction,
    onReloadRequired,
  );
  const editAction = useEditBroadcastAction(
    delivery.broadcast,
    resourceLockMessage,
  );
  const viewReportAction = useViewReportBroadcastAction(
    delivery.broadcast.id,
    delivery.delivery.status === 'delivered',
  );
  const actions = useMemo(() => ([
    ...(permissionAvailable(BroadcastsPermission.UPDATE) ? [editAction] : []),
    ...(permissionAvailable(BroadcastsPermission.UPDATE) ? [shareAction] : []),
    ...(permissionAvailable(BroadcastsPermission.CREATE) ? [duplicateAction] : []),
    ...(permissionAvailable(BroadcastsPermission.UPDATE) ? [archiveAction] : []),
    ...(permissionAvailable(BroadcastsPermission.REPORT) ? [viewReportAction] : []),
    ...(permissionAvailable(BroadcastsPermission.UPDATE) ? [
        {
          id: 'archive',
          IconComponent: StopIcon,
          label: intl.formatMessage({
            description: 'Label for stop action in recent deliveries action menu.',
            defaultMessage: 'Stop',
          }),
          busy: archiving,
          disabled: performingAction,
          onClick: guardedCallback(() => setStopDialogState(PopOverState.OPEN), [BroadcastsPermission.UPDATE]),
        }
      ] : []),
  ]), [
    permissionAvailable,
    editAction,
    shareAction,
    duplicateAction,
    archiveAction,
    viewReportAction,
    intl,
    archiving,
    performingAction,
    guardedCallback
  ]);

  const whenStopConfirmed = useCallback(() => {
    setStopDialogState(PopOverState.WILL_CLOSE);
    setStopping(true);
    api.post(`/broadcasts/${ delivery.broadcast.id }/stop`)
      .then(() => (
        setTimeout(() => {
          if (!mounted.current) {
            return;
          }

          addSuccessToast(
            intl.formatMessage({
              description: 'Toast message when broadcast is stopped successfully.',
              defaultMessage: 'Broadcast stopped successfully.',
            })
          );
          setStopping(false);
          onReloadRequired();
        }, 50)
      ))
      .catch(() => {
        if (!mounted.current) {
          return;
        }

        addErrorToast(
          intl.formatMessage({
            description: 'Toast message when broadcast cannot be stopped.',
            defaultMessage: 'Broadcast could not be stopped.',
          })
        );
        setStopping(false);
      });
  }, [addErrorToast, addSuccessToast, api, intl, delivery.broadcast.id, mounted, onReloadRequired]);

  useEffect(() => (
    setPerformingAction(duplicating || archiving || stopping || sharing)
  ), [archiving, duplicating, sharing, stopping]);

  return (
    <>
      <TableRow>
        <TableCell><BroadcastIcon broadcast={ delivery.broadcast }/></TableCell>
        <TableCell>
          <LineClamp minWidth={ 200 }>
            {
              permissionAvailable(BroadcastsPermission.UPDATE)
                ? (
                  resourceLockMessage
                    ? (
                      <DeprecatedTooltip title={ resourceLockMessage }>
                        <StyledDisabledLink>
                          { delivery.broadcast.name }
                        </StyledDisabledLink>
                      </DeprecatedTooltip>
                    )
                    : (
                      <GuardedLink
                        to={
                          delivery.delivery.status === 'delivered'
                            ? `/broadcasts/${ delivery.broadcast.id }/report`
                            : `/broadcasts/${ delivery.broadcast.id }/edit`
                        }
                        permissions={ [BroadcastsPermission.UPDATE] }
                      >
                        { delivery.broadcast.name }
                      </GuardedLink>
                    )
                )
                : delivery.broadcast.name
            }
          </LineClamp>
        </TableCell>
        <TableCell minwidth="160px">
          { delivery.resolvedShareAudienceSnippet && (
            <ShareAudience
              audienceSnippet={ delivery.resolvedShareAudienceSnippet }
              authenticatedPersonId={ String(userId) }
            />
          ) }
        </TableCell>
        <TableCell>
          <LineClamp minWidth={ 200 }>
            <InlinePersonAvatarAndName person={ delivery.broadcast.created.by }/>
          </LineClamp>
        </TableCell>
        <TableCell>
          <LineClamp minWidth={ 100 }>
            <ReadableRecurrenceRule recurrenceRule={ delivery.broadcast.recurrenceRule }/>
          </LineClamp>
        </TableCell>
        <TableCell>
          <VerticallySpaced gap={ 0.5 }>
            <Flex gap={ 1 }>
              { calculatingCredit && <CircularProgress size={ 20 } color="secondary"/> }
              <DateTime dateTime={ delivery.delivery.deliverAt?.dt }/>
            </Flex>
            { !calculatingCredit && !!creditDeficit && (
              <InsufficientCreditIndicator requiredCredit={ creditDeficit }/>
            ) }
          </VerticallySpaced>
        </TableCell>
        <TableCell>
          <TableRowContextMenu
            id="scheduled-table"
            actions={ actions }
          />
        </TableCell>
      </TableRow>
      { archiveConfirmationDialog }
      { stopDialogState !== PopOverState.CLOSED && (
        <ConfirmationDialog
          open={ stopDialogState === PopOverState.OPEN }
          TransitionProps={ {
            onExited: () => setStopDialogState(PopOverState.CLOSED),
          } }
          title={
            intl.formatMessage({
              description: 'Confirmation dialog title when archiving broadcast.',
              defaultMessage: 'Are you sure you want to stop this broadcast?',
            })
          }
          description={
            intl.formatMessage({
              description: 'Confirmation dialog message when archiving broadcast.',
              defaultMessage: 'This will delete the next and any future scheduled deliveries. Previous deliveries will be available on the recent broadcasts tab.',
            })
          }
          onCancel={ () => setStopDialogState(PopOverState.WILL_CLOSE) }
          onClose={ () => setStopDialogState(PopOverState.WILL_CLOSE) }
          onConfirm={ whenStopConfirmed }
        />
      ) }
      { shareConfirmationDialog }
    </>
  );
};
