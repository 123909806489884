import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { DurationParser } from '../../Utility/DurationParser';

export interface PasswordCheck {
  type: string;
  metadata: {
    min?: number;
    max?: number;
    checkPeriod?: number;
    phrasesCount?: number;
  };
  passed: boolean;
}

type LocalisedCheck = {
  passed: boolean;
  message: string;
};

export const useLocalisedPasswordErrors = (passwordChecks: PasswordCheck[]): LocalisedCheck[] => {
  const intl = useIntl();

  const relevantChecks = useMemo(() => {
    return passwordChecks.filter(
      (check) => {
        switch (check.type) {
          case 'minUppercaseChars':
            return check.metadata.min;
          case 'minLowercaseChars':
            return check.metadata.min;
          case 'minNumbers':
            return check.metadata.min;
          case 'minSpecialChars':
            return check.metadata.min;
          case 'maxConsecutiveRepeatedChars':
            return check.metadata.max;
          case 'disallowedPhrases':
            return check.metadata.phrasesCount;
          case 'reusedPassword':
            return check.metadata.checkPeriod;
        }
        return true;
      }
    );
  }, [passwordChecks]);

  const localiseCheck = useCallback((check: PasswordCheck): string => {
    switch (check.type) {
      case 'minLength':
        return intl.formatMessage({
          defaultMessage: 'Your password must be at least {min, plural, one {# character} other {# characters}} long',
        }, {
          min: check.metadata.min || 0,
        });
      case 'maxLength':
        return intl.formatMessage({
          defaultMessage: 'Your password must be at most {max, plural, one {# character} other {# characters}} characters long',
        }, {
          max: check.metadata.max || 0,
        });
      case 'minUniqueChars':
        return intl.formatMessage({
          defaultMessage: 'Your password must use at least {min, plural, one {# unique character} other {# unique characters}}',
        }, {
          min: check.metadata.min || 0,
        });
      case 'minUppercaseChars':
        return intl.formatMessage({
          defaultMessage: 'Your password must use at least {min, plural, one {# uppercase letter} other {# uppercase letters}}',
        }, {
          min: check.metadata.min || 0,
        });
      case 'minLowercaseChars':
        return intl.formatMessage({
          defaultMessage: 'Your password must use at least {min, plural, one {# lowercase letter} other {# lowercase letters}}',
        }, {
          min: check.metadata.min || 0,
        });
      case 'minNumbers':
        return intl.formatMessage({
          defaultMessage: 'Your password must use at least {min, plural, one {# number} other {# numbers}}',
        }, {
          min: check.metadata.min || 0,
        });
      case 'minSpecialChars':
        return intl.formatMessage({
          defaultMessage: 'Your password must use at least {min, plural, one {# special character} other {# special characters}}',
        }, {
          min: check.metadata.min || 0,
        });
      case 'maxConsecutiveRepeatedChars':
        return intl.formatMessage({
          defaultMessage: 'Your password must not repeat a character consecutively more than {max, plural, one {# time} other {# times}}',
        }, {
          max: check.metadata.max || 0,
        });
      case 'disallowedPhrases':
        return intl.formatMessage({
          defaultMessage: 'Your password must not contain your phone number or email address',
        });
      case 'maxBreachOccurrences':
        return intl.formatMessage({
          defaultMessage: 'Your password must not have been found in a known breach',
        });
      case 'reusedPassword':
        return intl.formatMessage({
          defaultMessage: 'Your password must not have been used in the past {period}',
        }, {
          period: DurationParser.formatSecondsRoughlyToLargestUnit(check.metadata.checkPeriod || 0, intl)
        })
      default:
        return check.type;
    }
  }, [intl]);

  return useMemo(() => (
    relevantChecks.map(relevantCheck => ({
      passed: relevantCheck.passed,
      message: localiseCheck(relevantCheck),
    }))
  ), [localiseCheck, relevantChecks]);
};
