import RRule, { Frequency } from 'rrule';
import { addYears, subMilliseconds } from 'date-fns';
import { getTimezoneOffset } from 'date-fns-tz';
import { ParsedOptions } from 'rrule/dist/esm/src/types';

import { DayIndex, RecurringSchedule, Schedule } from '../Broadcasts/Model/BroadcastSchedule';

export type RRuleOrdinal = 1 | 2 | 3 | 4 | -1;

export class RRuleParser {
  public static localeOffsetInMinutes = (rrule: RRule, locale?: string): number => {
    const rRuleOffsetInMinutes = utcOffsetInMinutes(rrule, locale);
    return rRuleOffsetInMinutes - new Date().getTimezoneOffset();
  };

  public static moveForwardToFirstOccurrence = (rRule: RRule): RRule => {
    const firstOccurrence = getFirstOccurrenceDate(rRule);
    if (firstOccurrence) {
      return new RRule({
        ...rRule.options,
        dtstart: firstOccurrence,
      });
    }
    return rRule;
  };

  public static withZ = (rRuleString: string): string => (
    rRuleString.replace(/UNTIL=([0-9T]{15})Z?;?/, 'UNTIL=$1Z;')
  );

  public static schedule = (rRuleString: string): RecurringSchedule => {
    const rRuleOptions = RRule.fromString(rRuleString).options;

    return getSchedule(rRuleOptions);
  };
}

const getScheduleEnd = (rRuleOptions: ParsedOptions): NonNullable<Schedule['recurrence']>['end'] => (
  rRuleOptions.until
    ? {
      type: 'onDate',
      localDate: rRuleOptions.until,
    }
    : rRuleOptions.count
      ? {
        type: 'afterOccurrences',
        count: rRuleOptions.count
      }
      : null
);

const getRecurrenceRuleToLocalOffset = (rRuleOptions: ParsedOptions): number => {
  const localOffset = rRuleOptions.dtstart.getTimezoneOffset() / MINUTES_IN_HOUR;
  const recurrenceRuleOffset = getTimezoneOffset(rRuleOptions.tzid || 'UTC') / MS_IN_SECOND / SECONDS_IN_MINUTE / MINUTES_IN_HOUR * -1;
  return recurrenceRuleOffset - localOffset;
};

type RepetitionTime = Pick<NonNullable<Schedule['recurrence']>['repetition'], 'minutes' | 'localHour' | 'seconds'>;
const getRepetitionTime = (rRuleOptions: ParsedOptions): RepetitionTime => {
  if (rRuleOptions.byhour[0] === undefined || rRuleOptions.byminute[0] === undefined) {
    throw new Error('Missing repetition time');
  }

  const unconstrainedLocalHour = rRuleOptions.byhour[0] + getRecurrenceRuleToLocalOffset(rRuleOptions);
  const localHour = unconstrainedLocalHour < 0
    ? 24 + unconstrainedLocalHour
    : unconstrainedLocalHour % 24;

  return {
    localHour,
    minutes: rRuleOptions.byminute[0],
    seconds: 0,
  };
};

const getOffsetDayIndex = (rRuleOptions: ParsedOptions, dayIndex: number): DayIndex => {
  const dayIndexMap: {[key: number]: number} = {
    0: 1,
    1: 2,
    2: 3,
    3: 4,
    4: 5,
    5: 6,
    6: 0,
  };
  const offset = getRecurrenceRuleToLocalOffset(rRuleOptions);
  const result = rRuleOptions.byhour[0] + offset > 24
    ? dayIndex + 2
    : rRuleOptions.byhour[0] + offset < 0
      ? dayIndex
      : dayIndexMap[dayIndex];
  return result;
};

const getSchedule = (rRuleOptions: ParsedOptions): RecurringSchedule => {
  switch (rRuleOptions.freq) {
    case Frequency.YEARLY:
      return {
        localStartDate: rRuleOptions.dtstart,
        recurrence: {
          repetition: {
            ...getRepetitionTime(rRuleOptions),
            frequency: Frequency.YEARLY,
          },
          end: getScheduleEnd(rRuleOptions),
        },
      };
    case Frequency.MONTHLY:
      return {
        localStartDate: rRuleOptions.dtstart,
        recurrence: {
          repetition: {
            ...getRepetitionTime(rRuleOptions),
            frequency: Frequency.MONTHLY,
            ordinal: rRuleOptions.bysetpos[0] as RRuleOrdinal,
            localDayIndex: getOffsetDayIndex(rRuleOptions, rRuleOptions.byweekday[0]),
          },
          end: getScheduleEnd(rRuleOptions),
        },
      };
    case Frequency.WEEKLY:
      return {
        localStartDate: rRuleOptions.dtstart,
        recurrence: {
          repetition: {
            ...getRepetitionTime(rRuleOptions),
            frequency: Frequency.WEEKLY,
            localDayIndex: getOffsetDayIndex(rRuleOptions, rRuleOptions.byweekday[0]),
          },
          end: getScheduleEnd(rRuleOptions),
        },
      };
    default:
      return {
        localStartDate: rRuleOptions.dtstart,
        recurrence: {
          repetition: {
            ...getRepetitionTime(rRuleOptions),
            frequency: Frequency.DAILY,
            localDayIndices: rRuleOptions.byweekday.map(weekday => getOffsetDayIndex(rRuleOptions, weekday)),
          },
          end: getScheduleEnd(rRuleOptions),
        },
      };
  }
};

const getFirstOccurrenceDate = (rRule: RRule): Date | undefined => {
  // Subtract 1 ms because the comparison isn't inclusive. We need it to be inclusive so that r rules that have
  // already been forwarded to their first occurrence still return their start date as an occurrence.
  const searchStart = subMilliseconds(rRule.options.dtstart || new Date(), 1);
  const searchEnd = rRule.options.until || addYears(new Date(), 1);
  const dates = rRule.between(searchStart, searchEnd);
  if (!dates.length) {
    return;
  }
  return dates[0];
};

const utcOffsetInMinutes = (rrule: RRule, locale?: string): number => {
  const rRuleOffset = rrule.options.tzid
    ? getTimezoneOffset(
      Intl.DateTimeFormat(
        locale,
        { timeZone: rrule.options.tzid },
      ).resolvedOptions().timeZone,
    )
    : 0;
  return rRuleOffset / MS_IN_SECOND / SECONDS_IN_MINUTE * -1;
};

const MS_IN_SECOND = 1000;
const SECONDS_IN_MINUTE = 60;
const MINUTES_IN_HOUR = 60;
