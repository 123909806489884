import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@mui/material';
import {FunctionComponent, ReactNode, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Heading } from '@ourpeople/shared/Core/Component/Content';
import { LocalisedString } from 'op-storybook/lib/model/LocalisedString/LocalisedString';

import {
  Box,
  Feedback,
  Flex,
  FlexPullRight,
  LoadingButton,
  Notice,
  NoticeProps,
  StickyCell,
  VerticallySpaced, TableFilters, Chip
} from '../../../Common/Component';
import { PaginatedTable, TableCell, TableRow } from '../../../Components';
import { ImportDetail, ImportErrorPresentationComponent, MappedColumn } from '../../Model';
import { FetchImportRowsParams, ImportRow, useFetchImportRows } from '../../Hook';
import {
  ErrorStatusAutocomplete,
  ImportErrorStatus,
  ReviewTableRow,
  SkippedStatus,
  SkippedStatusAutocomplete
} from '..';
import { ArrayHelper, ChipHelper, QueryParser, QueryWithKeys } from '../../../Common/Utility';
import { StyledColumnMappingType, StyledTableContainer } from './style';
import { ApiContext } from '../../../Contexts';
import { useQueryAsState } from '../../../Hooks';
import { useContextOrThrow } from '../../../Core/Hook';
import { ToastContext } from '../../../Core/Context';

type ModifiedRow = {
  id: string;
  values?: string[];
  ignored: boolean;
}

type CellDetail = {
  value: string;
  mappedColumn: MappedColumn;
};

export type RowDetail = ImportRow & {
  cells: CellDetail[]
};

type Props = {
  importDetail: ImportDetail;
  onReUploadButtonClicked: () => void;
  recordTypes: LocalisedString[];
  additionalNotices?: (NoticeProps & { key: string })[];
  RootErrorComponent: ImportErrorPresentationComponent;
  RecordErrorComponent: ImportErrorPresentationComponent;
  onSaveRowsComplete: () => void;
  onContinueButtonClicked: () => void;
  onReCheckClicked: () => void;
  parsing: boolean;
};

type Query = QueryWithKeys<'skippedStatuses'
  | 'errorStatuses'
  | 'search'
  | 'pageNum'>;

export const ReviewStep: FunctionComponent<Props> = ({
  importDetail,
  onReUploadButtonClicked,
  recordTypes,
  additionalNotices = [],
  RootErrorComponent,
  RecordErrorComponent,
  onContinueButtonClicked,
  onReCheckClicked,
  parsing,
  onSaveRowsComplete,
}) => {
  const [query, setQuery] = useQueryAsState<Query>();
  const intl = useIntl();
  const { path } = useRouteMatch();
  const errorStatus = useMemo<ImportErrorStatus | undefined>(() => parseImportErrorStatus(query)[0], [query]);
  const skippedStatuses = useMemo(() => parseSkippedStatus(query), [query]);
  const params = useMemo<FetchImportRowsParams>(() => ({
    pageNum: QueryParser.pageNum(query),
    search: query.search,
    noWithoutErrors: errorStatus === 'noWithout' ? 1 : 0,
    noWithErrors: errorStatus === 'noWith' ? 1 : 0,
    noIgnored: skippedStatuses.includes('noIgnored') ? 1 : 0,
  }), [errorStatus, query, skippedStatuses]);
  const { addSuccessToast, addErrorToast } = useContextOrThrow(ToastContext);
  const api = useContext(ApiContext);
  const [saving, setSaving] = useState<boolean>(false);
  const [fetchImportRowsResult, fetchImportRowsState, reloadImportRows] = useFetchImportRows(importDetail.id, params);
  const pagination = fetchImportRowsResult?.content?.pagination;
  const headingCells = useMemo<CellDetail[]>(() => {
    const headingRowValues = importDetail.parseResult?.snippet[0] || [];
    return (importDetail.parseResult?.columnMap || [])
      .filter(columnMap => !columnMap.ignoreReason)
      .map((columnMap) => ({
        value: headingRowValues[columnMap.index] || '',
        mappedColumn: columnMap,
      }));
  }, [importDetail.parseResult?.columnMap, importDetail.parseResult?.snippet]);
  const rows = useMemo<RowDetail[]>(
    () => {
      const importRows = fetchImportRowsResult?.content?.rows || [];

      return importRows.map(importRow => ({
        ...importRow,
        cells: (importDetail.parseResult?.columnMap || [])
          .filter(columnMap => !columnMap.ignoreReason)
          .map((columnMap) => ({
            value: importRow.values[columnMap.index] || '',
            mappedColumn: columnMap,
          })),
      }));
    },
    [fetchImportRowsResult?.content?.rows, importDetail.parseResult?.columnMap],
  );
  const maxRowLength = headingCells.length || 0;
  const errorCount = importDetail.parseResult?.prepareResult?.errorCounts.total || 0;
  const [rowsWithModifiedValuesMap, setRowsWithModifiedValuesMap] = useState<Map<string, string[]>>(new Map());
  const [rowsWithModifiedIgnoreStateMap, setRowsWithModifiedIgnoreStateMap] = useState<Map<string, boolean>>(new Map());
  const [chips, setChips] = useState<Chip[]>([]);
  const unsavedChanges = !!rowsWithModifiedValuesMap.size || !!rowsWithModifiedIgnoreStateMap.size;

  const whenRowValuesChanged = useCallback((rowId: string, values: string[]) => (
    setRowsWithModifiedValuesMap(rowsWithModifiedValuesMap => {
      const newMap = new Map(rowsWithModifiedValuesMap);
      return newMap.set(rowId, values);
    })
  ), []);

  const whenSaveRowsSuccessful = useCallback(() => {
    addSuccessToast(
      intl.formatMessage({
        description: 'Message when rows are saved successfully.',
        defaultMessage: 'Rows updated successfully',
      })
    );
  }, [addSuccessToast, intl]);

  const whenSaveRowsFailed = useCallback(() => {
    addErrorToast(
      intl.formatMessage({
        description: 'Message when saving rows fails.',
        defaultMessage: 'Rows could not be updated',
      })
    );
  }, [addErrorToast, intl]);

  useEffect(() => {
    if (!saving) {
      return;
    }

    if (!api) {
      throw new Error('Api not available.');
    }

    let cancelled = false;

    const rowsWithModifiedValuesArray: ModifiedRow[] = Array.from(rowsWithModifiedValuesMap.entries())
      .map(rowsWithModifiedValuesMapEntry => ({
        id: rowsWithModifiedValuesMapEntry[0],
        values: rowsWithModifiedValuesMapEntry[1],
        ignored: rowsWithModifiedIgnoreStateMap.has(rowsWithModifiedValuesMapEntry[0])
          ? rowsWithModifiedIgnoreStateMap.get(rowsWithModifiedValuesMapEntry[0]) as boolean
          : !!rows.find(row => row.id === rowsWithModifiedValuesMapEntry[0])?.ignored,
      }));
    const rowsWithModifiedIgnoreStateArray: ModifiedRow[] = Array.from(rowsWithModifiedIgnoreStateMap.entries())
      .filter(rowsWithModifiedIgnoreStateMapEntry => !rowsWithModifiedValuesArray.find(row => row.id === rowsWithModifiedIgnoreStateMapEntry[0]))
      .map(entry => ({
        id: entry[0],
        ignored: entry[1],
      }));

    api.post(
      `/imports/${ importDetail.id }/rows`,
      {
        rows: rowsWithModifiedValuesArray
          .concat(rowsWithModifiedIgnoreStateArray),
      },
    )
      .then(() => {
        if (cancelled) {
          return;
        }

        setSaving(false);
        whenSaveRowsSuccessful();
        onSaveRowsComplete();
      })
      .catch(() => {
        if (cancelled) {
          return;
        }

        setSaving(false);
        whenSaveRowsFailed();
      });

    return () => void (cancelled = true);
  }, [
    api,
    importDetail.id,
    rows,
    onSaveRowsComplete,
    saving,
    whenSaveRowsFailed,
    whenSaveRowsSuccessful,
    rowsWithModifiedValuesMap,
    rowsWithModifiedIgnoreStateMap
  ]);

  const feedback = useMemo<Feedback>(() => ({
    message: intl.formatMessage<ReactNode>({
      id: 'imports.reviewSteps.issueSummary',
      description: 'Summary of issues above table on review step of imports',
      defaultMessage: '{ errorCount, plural, one { # issue was } other { # issues were } } found with your import. <a>Show rows with errors</a>',
    }, {
      errorCount,
      a: parts => (
        <Link
          to={{
            pathname: path,
            search: 'pageNum=1&errorStatuses=noWithout',
          }}
        >
          { parts }
        </Link>
      )
    }),
    severity: 'error',
  }), [intl, errorCount, path]);

  const whenSearchChanged = useCallback((newSearch: string) => {
    setQuery(({ search, ...query }) => ({
      ...query,
      ...(newSearch ? { search: newSearch } : {}),
      pageNum: '1',
    }));
  }, [setQuery]);

  const whenPageNumChanged = useCallback((pageNum: number) => {
    setQuery(query => ({
      ...query,
      pageNum: `${ pageNum }`,
    }));
  }, [setQuery]);

  const whenRemoveChipClicked = (chip: Chip) => {
    if (chip.type === 'errorStatus') {
      setQuery(({ errorStatuses: previousErrorStatuses, ...query }) => ({
        ...query,
        pageNum: '1',
      }));
    } else if (chip.type === 'skippedStatus') {
      setQuery(({ skippedStatuses: previousSkippedStatuses, ...query }) => ({
        ...query,
        ...(skippedStatuses.length ? { skippedStatuses: skippedStatuses.filter(currentStatus => currentStatus !== chip.id).join(',') } : {}),
        pageNum: '1',
      }));
    }

    setChips(chips => chips.filter(currentChip => currentChip.type !== chip.type || currentChip.id !== chip.id));
  };

  const whenClearFiltersClicked = () => {
    setChips([]);
    setQuery({
      pageNum: '1',
    });
  };

  const whenSkippedStatusesChanged = (selection: LocalisedString<SkippedStatus>[]) => {
    const skippedStatuses = selection.map(option => option.id);

    setQuery(({ skippedStatuses: previousSkippedStatuses, ...query }) => ({
      ...query,
      ...(skippedStatuses.length ? { skippedStatuses: skippedStatuses.join(',') } : {}),
      pageNum: '1',
    }));

    setChips(chips => (
      ChipHelper.updateChipsWithBasicAutocompleteSelection(
        'skippedStatus',
        chips,
        selection,
        option => intl.formatMessage({
          id: 'import.skippedStatusChip',
          description: 'Label for skipped status chip.',
          defaultMessage: 'Skipped rows: { type }',
        }, {
          type: option.localisation,
        }),
      )
    ));
  };

  const whenErrorStatusesChanged = (selection: LocalisedString<ImportErrorStatus>[]) => {
    const errorStatuses = selection.map(option => option.id);

    setQuery(({ errorStatuses: previousErrorStatuses, ...query }) => ({
      ...query,
      ...(errorStatuses.length ? { errorStatuses: errorStatuses[0] } : {}),
      pageNum: '1',
    }));

    setChips(chips => (
      ChipHelper.updateChipsWithBasicAutocompleteSelection(
        'errorStatus',
        chips,
        selection,
        option => intl.formatMessage({
          id: 'import.errorStatusChip',
          description: 'Label for error status chip.',
          defaultMessage: 'Errors: { type }',
        }, {
          type: option.localisation,
        }),
      )
    ));
  };

  const whenChecked = useCallback((rowId: string, checked: boolean) => {
    setRowsWithModifiedIgnoreStateMap(rowsWithModifiedIgnoreStateMap => {
      const newMap = new Map(rowsWithModifiedIgnoreStateMap);
      if (newMap.has(rowId)) {
        newMap.delete(rowId);
        return newMap;
      } else {
        return newMap.set(rowId, checked);
      }
    });
  }, []);

  return (
    <Box margin={ false }>
      <VerticallySpaced gap={ 2 }>
        <Heading>
          <Flex>
            <FormattedMessage
              id="imports.reviewStep.title"
              description="Title displayed over the review step of imports."
              defaultMessage="Review"
            />
            <FlexPullRight gap={ 2 }>
              <Button
                color="primary"
                variant="outlined"
                onClick={ onReUploadButtonClicked }
              >
                <FormattedMessage
                  id="imports.re-upload"
                  description="Label for re-upload button."
                  defaultMessage="Re-upload"
                />
              </Button>
              {
                unsavedChanges
                  ? (
                    <LoadingButton
                      color="primary"
                      variant="contained"
                      disableElevation
                      busy={ saving || parsing }
                      onClick={ () => setSaving(true) }
                    >
                      <FormattedMessage
                        id="imports.save"
                        description="Label for saving edited values in review step of import."
                        defaultMessage="Save changes"
                      />
                    </LoadingButton>
                  )
                  : (
                    <LoadingButton
                      color="primary"
                      variant="outlined"
                      disableElevation
                      busy={ parsing }
                      onClick={ onReCheckClicked }
                    >
                      <FormattedMessage
                        id="imports.review.re-check"
                        description="Label for re-check button."
                        defaultMessage="Re-check"
                      />
                    </LoadingButton>
                  )
              }
            </FlexPullRight>
          </Flex>
        </Heading>
        { !!additionalNotices.length && additionalNotices.map(noticeProps => <Notice { ...noticeProps }/>) }
        { importDetail.status !== 'invalid_records' && (
          <Notice
            variant="outlined"
            feedback={ {
              message: (
                <FormattedMessage
                  id="imports.review.success"
                  description="Success notice when review step has no errors."
                  defaultMessage="Your data looks great to us! Check and make changes to the data below, or continue to see a summary of your import."
                />
              ),
              severity: 'success',
            } }
            buttons={ [
              {
                id: 'continue',
                props: {
                  variant: 'primary',
                  onClick: onContinueButtonClicked,
                },
                label: intl.formatMessage({
                  id: 'imports.review.continue',
                  description: 'Label for continue button in review step success notice.',
                  defaultMessage: 'Continue',
                }),
              },
            ] }
          />
        ) }
        {
          !!errorCount && (
            <Notice
              feedback={ feedback }
              variant="outlined"
            />
          )
        }
        { !!importDetail.parseResult?.prepareResult && (
          <RootErrorComponent errors={ importDetail.parseResult?.prepareResult.rootErrors.errors }/>
        ) }
        <StyledTableContainer>
          <TableFilters
            searchValue={ params.search || '' }
            onSearchChanged={ whenSearchChanged }
            chips={ chips }
            onRemoveChipClicked={ whenRemoveChipClicked }
            onClearFiltersClicked={ whenClearFiltersClicked }
          >
            <ErrorStatusAutocomplete
              selectedIds={ errorStatus ? [errorStatus] : [] }
              onSelectionChanged={ whenErrorStatusesChanged }
              multiple={ false }
            />
            <SkippedStatusAutocomplete
              selectedIds={ skippedStatuses }
              onSelectionChanged={ whenSkippedStatusesChanged }
            />
          </TableFilters>
          <PaginatedTable
            rows={ rows }
            headerRow={
              <TableRow>
                <TableCell/>
                { headingCells.map((headingCell) => (
                  <TableCell key={ headingCell.mappedColumn.index }>
                    <Flex
                      direction="column"
                      align="flex-start"
                    >
                      <span>{ headingCell.value }</span>
                      <StyledColumnMappingType>{ recordTypes.find(recordType => recordType.id === headingCell.mappedColumn.mapping?.type)?.localisation || '' }</StyledColumnMappingType>
                    </Flex>
                  </TableCell>
                )) }
                <StickyCell justify="end">
                  <FormattedMessage
                    id="imports.skipped"
                    description="Label for skipped column on review step of imports."
                    defaultMessage="Skipped"
                  />
                </StickyCell>
              </TableRow>
            }
            rowRender={ (row: RowDetail) => (
              <ReviewTableRow
                key={ row.id }
                row={ row }
                checked={
                  rowsWithModifiedIgnoreStateMap.has(row.id)
                    ? rowsWithModifiedIgnoreStateMap.get(row.id) as boolean
                    : row.ignored
                }
                onChecked={ whenChecked }
                modifiedValues={ rowsWithModifiedValuesMap.get(row.id) }
                onChange={ whenRowValuesChanged }
                maxRowLength={ maxRowLength }
                RecordErrorComponent={ RecordErrorComponent }
              />
            ) }
            pageNum={ params.pageNum }
            onPageChanged={ whenPageNumChanged }
            requestState={ fetchImportRowsState }
            onRetryClicked={ reloadImportRows }
            pagination={ pagination }
          />
        </StyledTableContainer>
      </VerticallySpaced>
    </Box>
  );
};

const valueIsImportErrorStatus = ArrayHelper.createTypeGuard<ImportErrorStatus>(['noWith', 'noWithout']);
const valueIsSkippedStatus = ArrayHelper.createTypeGuard<SkippedStatus>(['noIgnored']);
const importErrorStatusFilter = ArrayHelper.createTypeFilter(valueIsImportErrorStatus);
const skippedStatusFilter = ArrayHelper.createTypeFilter(valueIsSkippedStatus);
const parseImportErrorStatus = QueryParser.getCsvParseFn('errorStatuses', importErrorStatusFilter);
const parseSkippedStatus = QueryParser.getCsvParseFn('skippedStatuses', skippedStatusFilter);
