import { FunctionComponent, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { ListItemIcon, ListItemText, MenuItem, } from '@mui/material';
import { ConfirmationDialog } from '@ourpeople/shared/Core/Component/Feedback';

import CopyIcon from '../../../Assets/img/icons/streamline/layers-back.svg';
import EditIcon from '../../../Assets/img/icons/streamline/common-file-edit.svg';
import FolderIcon from '../../../Assets/img/icons/streamline/archive-content.svg';
import MoveBackIcon from '../../../Assets/img/icons/streamline/move-back.svg';
import PaperPlaneIcon from '../../../Assets/img/icons/streamline/send-email-1-emails.svg';
import TrashIcon from '../../../Assets/img/icons/streamline/bin-1.svg';
import TrendingUpIcon from '../../../Assets/img/icons/streamline/graph-stats-ascend.svg';
import StopIcon from '../../../Assets/img/icons/streamline/button-stop.svg';
import { TableMenu } from '../../../Components/Content/TableMenu';
import { BroadcastAction, BroadcastsPermission } from '../../Model';
import { usePermissions } from '../../../Security/Hook';
import { GuardedLink } from '../../../Security/Component';
import { DeprecatedLink } from '../../../Common/Component';

type BroadcastMenuProps = {
  broadcastId: string,
  deliveryId?: string,
  onActionConfirmed: (action: BroadcastAction, broadcastId: string) => void,
  showDuplicate?: boolean,
  showStop?: boolean,
  showArchive?: boolean,
  showUnarchive?: boolean,
  showDelete?: boolean,
  showEdit?: boolean,
  showReport?: boolean,
  showSendToNew?: boolean,
};

/**
 * @deprecated Prefer ContextMenu
 */
export const BroadcastMenu: FunctionComponent<BroadcastMenuProps> = ({
  broadcastId,
  deliveryId,
  onActionConfirmed,
  showDuplicate,
  showStop,
  showArchive,
  showUnarchive,
  showEdit,
  showDelete,
  showReport,
  showSendToNew,
}) => {
  const intl = useIntl();
  const [archiveConfirmOpen, setArchiveConfirmOpen] = useState<boolean>(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);
  const [stopConfirmOpen, setStopConfirmOpen] = useState<boolean>(false);
  const [unarchiveConfirmOpen, setUnarchiveConfirmOpen] = useState<boolean>(false);
  const { guardedCallback } = usePermissions();

  const whenArchiveClicked = (): void => {
    setArchiveConfirmOpen(true);
  };

  const whenArchiveCancelled = (): void => {
    setArchiveConfirmOpen(false);
  };

  const whenArchiveConfirmed = useCallback(() => {
    setArchiveConfirmOpen(false);
    onActionConfirmed(BroadcastAction.ARCHIVE, broadcastId);
  }, [broadcastId, onActionConfirmed]);

  const whenUnarchiveClicked = (): void => {
    setUnarchiveConfirmOpen(true);
  };

  const whenUnarchiveCancelled = (): void => {
    setUnarchiveConfirmOpen(false);
  };

  const whenUnarchiveConfirmed = useCallback(() => {
    setUnarchiveConfirmOpen(false);
    onActionConfirmed(BroadcastAction.UNARCHIVE, broadcastId);
  }, [broadcastId, onActionConfirmed]);

  const whenStopClicked = (): void => {
    setStopConfirmOpen(true);
  };

  const whenStopCancelled = () => {
    setStopConfirmOpen(false);
  };

  const whenStopConfirmed = useCallback(() => {
    setStopConfirmOpen(false);
    onActionConfirmed(BroadcastAction.STOP, broadcastId);
  }, [broadcastId, onActionConfirmed]);

  const whenDeleteClicked = () => {
    setDeleteConfirmOpen(true);
  };

  const whenDeleteCancelled = () => {
    setDeleteConfirmOpen(false);
  };

  const whenDeleteConfirmed = useCallback(() => {
    setDeleteConfirmOpen(false);
    onActionConfirmed(BroadcastAction.DELETE, broadcastId);
  }, [broadcastId, onActionConfirmed]);

  const whenDuplicateClicked = (): void => {
    onActionConfirmed(BroadcastAction.DUPLICATE, broadcastId);
  };

  const whenSendToNewClicked = (): void => {
    onActionConfirmed(BroadcastAction.SEND_TO_NEW, broadcastId);
  };

  return (
    <>
      <TableMenu
        rowKey={ broadcastId }
      >
        {
          showStop && (
            <MenuItem
              key="stopBroadcast"
              onClick={ guardedCallback(
                whenStopClicked,
                [
                  BroadcastsPermission.UPDATE,
                ],
              )}
            >
              <ListItemIcon><StopIcon/></ListItemIcon>
              <ListItemText primary={
                intl.formatMessage({
                  id: 'broadcasts.list.stopBroadcast',
                  defaultMessage: 'Stop broadcast',
                })
              }
              />
            </MenuItem>
          )
        }
        {
          showEdit && (
            <MenuItem
              key="edit"
              component={GuardedLink}
              to={ `/broadcasts/${ broadcastId }/edit` }
              permissions={[
                BroadcastsPermission.UPDATE,
                BroadcastsPermission.READ,
              ]}
            >
              <ListItemIcon><EditIcon/></ListItemIcon>
              <ListItemText primary={
                intl.formatMessage({
                  id: 'broadcasts.list.edit',
                  defaultMessage: 'Edit',
                })
              }
              />
            </MenuItem>
          )
        }
        {
          showDuplicate && (
            <MenuItem
              key="copy"
              onClick={ guardedCallback(
                whenDuplicateClicked,
                [
                  BroadcastsPermission.CREATE,
                  BroadcastsPermission.READ,
                ],
              )}
            >
              <ListItemIcon><CopyIcon/></ListItemIcon>
              <ListItemText primary={
                intl.formatMessage({
                  id: 'broadcasts.list.copy',
                  defaultMessage: 'Make a copy',
                })
              }
              />
            </MenuItem>
          )
        }
        {
          showReport && (
            <MenuItem
              key="report"
              component={DeprecatedLink}
              to={
                deliveryId
                  ? `/broadcasts/${ broadcastId }/report?delivery=${ deliveryId }`
                  : `/broadcasts/${ broadcastId }/report`
              }
            >
              <ListItemIcon><TrendingUpIcon/></ListItemIcon>
              <ListItemText primary={
                intl.formatMessage({
                  id: 'broadcasts.list.report',
                  defaultMessage: 'View report',
                })
              }
              />
            </MenuItem>
          )
        }
        {
          showArchive && (
            <MenuItem
              key="archive"
              onClick={ guardedCallback(
                whenArchiveClicked,
                [
                  BroadcastsPermission.UPDATE,
                ],
              )}
            >
              <ListItemIcon><FolderIcon/></ListItemIcon>
              <ListItemText primary={
                intl.formatMessage({
                  defaultMessage: 'Archive',
                })
              }
              />
            </MenuItem>
          )
        }
        {
          showUnarchive && (
            <MenuItem
              key="unarchive"
              onClick={ guardedCallback(
                whenUnarchiveClicked,
                [
                  BroadcastsPermission.UPDATE,
                ],
              )}
            >
              <ListItemIcon><MoveBackIcon/></ListItemIcon>
              <ListItemText primary={
                intl.formatMessage({
                  defaultMessage: 'Unarchive',
                })
              }
              />
            </MenuItem>
          )
        }
        {
          showDelete && (
            <MenuItem
              key="delete"
              onClick={ guardedCallback(
                whenDeleteClicked,
                [
                  BroadcastsPermission.DELETE,
                ],
              )}
            >
              <ListItemIcon><TrashIcon/></ListItemIcon>
              <ListItemText primary={
                intl.formatMessage({
                  id: 'broadcasts.list.delete',
                  defaultMessage: 'Delete',
                })
              }
              />
            </MenuItem>
          )
        }
        {
          showSendToNew && (
            <MenuItem
              key="sendToNew"
              onClick={ guardedCallback(
                whenSendToNewClicked,
                [
                  BroadcastsPermission.CREATE,
                ],
              )}
            >
              <ListItemIcon><PaperPlaneIcon/></ListItemIcon>
              <ListItemText primary={
                intl.formatMessage({
                  id: 'broadcasts.list.sendToNew',
                  defaultMessage: 'Send to new',
                })
              }
              />
            </MenuItem>
          )
        }
      </TableMenu>
      <ConfirmationDialog
        open={ archiveConfirmOpen }
        title={
          intl.formatMessage({
            id: 'broadcasts.archiving.confirmTitle',
            defaultMessage: 'Archive broadcast',
          })
        }
        description={
          intl.formatMessage({
            id: 'broadcasts.archiving.confirmDescription',
            defaultMessage: 'Do you really want to archive this broadcast?',
          })
        }
        onCancel={ whenArchiveCancelled }
        onClose={ whenArchiveCancelled }
        onConfirm={ whenArchiveConfirmed }
      />
      <ConfirmationDialog
        open={ deleteConfirmOpen }
        title={
          intl.formatMessage({
            id: 'broadcasts.deleting.confirmTitle',
            defaultMessage: 'Delete broadcast',
          })
        }
        description={
          intl.formatMessage({
            id: 'broadcasts.deleting.confirmDescription',
            defaultMessage: 'Do you really want to delete this broadcast?',
          })
        }
        onCancel={ whenDeleteCancelled }
        onClose={ whenDeleteCancelled }
        onConfirm={ whenDeleteConfirmed }
      />
      <ConfirmationDialog
        open={ stopConfirmOpen }
        title={
          intl.formatMessage({
            id: 'broadcasts.stopping.stopTitle',
            defaultMessage: 'Are you sure you want to stop this broadcast?',
          })
        }
        description={
          intl.formatMessage({
            id: 'broadcasts.stopping.stopDescription',
            defaultMessage: 'This will delete the next and any future scheduled deliveries. Previous deliveries will be available on the recent broadcasts tab.',
          })
        }
        onCancel={ whenStopCancelled }
        onClose={ whenStopCancelled }
        onConfirm={ whenStopConfirmed }
      />
      <ConfirmationDialog
        open={ unarchiveConfirmOpen }
        title={
          intl.formatMessage({
            defaultMessage: 'Unarchive broadcast',
          })
        }
        description={
          intl.formatMessage({
            defaultMessage: 'Do you really want to unarchive this broadcast?',
          })
        }
        onCancel={ whenUnarchiveCancelled }
        onClose={ whenUnarchiveCancelled }
        onConfirm={ whenUnarchiveConfirmed }
      />
    </>
  );
};
