import { FunctionComponent } from 'react';

interface Props {
  active?: boolean;
}

export const DeliveryReportTabContent: FunctionComponent<Props> = ({
  children,
  active = false,
}) => {
  return (
    <>
      {
        active && children
      }
    </>
  )
}
