import { JSX, useCallback, useMemo, useState } from 'react';
import { SearchField } from '@ourpeople/shared/Core/Component/Input/SearchField/SearchField';
import { LocalisedString } from 'op-storybook/lib/model/LocalisedString/LocalisedString';
import { DropdownItemCheck } from 'op-storybook/stories/components/DropdownItemCheck/DropdownItemCheck';

import { StatusCondition } from '../../../../Audiences/Model';
import { useLocalisedStatuses } from '../../../../People/Hook/useLocalisedStatuses';
import { PersonStatus } from '../../../../Models';
import { StandardListItem } from '../../../Common/Component/Content/StandardListItem';
import { MinimumInset } from '../../../Common/Component/Layout/MinimumInset';

type Props = {
  condition: StatusCondition;
  onChange: (condition: StatusCondition) => void;
};

export const StatusList = ({
  condition,
  onChange,
}: Props): JSX.Element => {
  const [search, setSearch] = useState<string>('');
  const filters = useMemo(() => ({
    ...search ? { search } : {},
  }), [search]);
  const localisedStatuses = useLocalisedStatuses();
  const filteredStatuses = useMemo(() => {
    return localisedStatuses.filter(status => (
      !filters.search || status.localisation.toUpperCase().includes(filters.search.toUpperCase())
    ));
  }, [filters.search, localisedStatuses]);

  const whenStatusClicked = useCallback((status: LocalisedString<PersonStatus>) => {
    const selected = condition.statuses.includes(status.id);
    onChange({
      ...condition,
      statuses: selected
        ? condition.statuses.filter(statusId => status.id !== statusId)
        : condition.statuses.concat(status.id),
    })
  }, [condition, onChange]);

  const renderItem = useCallback((status: LocalisedString<PersonStatus>) => {
    return (
      <StandardListItem
        key={ status.id }
        text={ status.localisation }
        onClick={ event => {
          event.stopPropagation();
          event.preventDefault();
          whenStatusClicked(status);
        } }
        selected={ condition.statuses.includes(status.id) }
        { ...condition.statuses.includes(status.id) ? { endAdornment: <DropdownItemCheck/> } : {} }
      />
    )
  }, [condition.statuses, whenStatusClicked]);

  return (
    <>
      <MinimumInset
        top={ 2 }
        right={ 1 }
        bottom={ 2 }
        left={ 1 }
      >
        <SearchField
          value={ filters.search || '' }
          onChange={ setSearch }
        />
      </MinimumInset>
      <ul
        css={ {
          margin: 0,
          padding: 0,
          listStyle: 'none',
        } }
      >
        { filteredStatuses.map(renderItem) }
      </ul>
    </>
  );
};
