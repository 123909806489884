import { FC, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { ContentPreviewProps } from '../../../../Content/Model';
import { TextEditorContent } from '../../../Service';
import { ContentWithType, ScrollableContent } from '..';
import { TextContentWithoutFooter } from '../../../../Content/Component';

export const TextContentPreview: FC<ContentPreviewProps<TextEditorContent>> = ({
  editorContent,
  onReady,
}) => {
  const intl = useIntl();
  const { card } = useMemo(() => editorContent, [editorContent]);
  const linkProps = useMemo(() => ({
    onFileLinkClick: () => { /* Do nothing */ },
    fileEntry: editorContent.fileEntry || undefined,
    linkType: card.content.linkType,
    linkLabel: card.content.linkLabel,
    linkTarget: card.content.linkTarget,
  }), [card.content.linkLabel, card.content.linkTarget, card.content.linkType, editorContent.fileEntry]);

  useEffect(() => {
    onReady();
  }, [onReady]);

  return (
    <ScrollableContent>
      <ContentWithType
        localisedContentType={ intl.formatMessage({
          id: 'broadcasts.content.preview.text',
          description: 'Label for text content type used in preview.',
          defaultMessage: 'Text',
        }) }
      >
        <TextContentWithoutFooter
          html={ card.content.text }
          linkProps={ linkProps }
        />
      </ContentWithType>
    </ScrollableContent>
  );
};
