import styled from '@emotion/styled';
import { Chip, Autocomplete, AutocompleteProps } from '@mui/material';

export interface StyledListboxProps {
  querying?: boolean;
  queryingText?: string;
  listStyle?: 'grid' | 'list';
}

export const StyledListbox = styled.ul<StyledListboxProps>`
  ${ props => props.listStyle === 'grid' && `
    max-width: 850px;
    width: 95vw;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 0.5rem;
    padding: 0.5rem;

    li {
      border-radius: 0.25rem;
      padding: 0;
    }
  ` }

  li > div {
    padding: ${ props => props.theme.spacing(0.25) };
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  ::after {
    display: block;
    padding: 14px 16px 0;
    color: ${ props => props.theme.palette.text.secondary };
    ${ props => props.querying && `
      content: "${ props.queryingText || 'Loading...' }";
    ` };
  }
`;

export const StyledRemoveButton = styled.button`
  border: none;
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: 0;
`;

export const StyledChip = styled(Chip)<{ readOnly?: boolean }>`
  border-radius: 0.25rem;

  ${ props => props.readOnly && `
    background: ${ props.theme.palette.grey[300] };
    border-color: transparent;
    color: ${ props.theme.palette.text.primary };
  ` }

  .MuiChip-deleteIcon {
    color: inherit;
    width: 1rem;
    height: 1rem;
  }
`;

type StyledAutocompleteProps<T> = AutocompleteProps<T, boolean, boolean, undefined> & {
  applyDisabledStyling?: boolean;
}

export const StyledAutocomplete = styled(
  Autocomplete,
  { shouldForwardProp: propName => propName !== 'applyDisabledStyling'},
)<StyledAutocompleteProps<unknown>>`
  ${ props => props.applyDisabledStyling && `
    .Mui-disabled {
      background: ${ props.theme.palette.grey[300] };
    }
  `}
` as <T>(props: StyledAutocompleteProps<T>) => JSX.Element;
