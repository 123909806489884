import { ComponentProps, FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { generatePath } from 'react-router-dom';
import SmileyHouseIcon from '@ourpeople/shared/Asset/Icon/figma/home-smile.svg';
import FormsIcon from 'op-storybook/lib/assets/icon/figma/file-06.svg';
import FilesIcon from 'op-storybook/lib/assets/icon/figma/folder.svg';
import ChatIcon from 'op-storybook/lib/assets/icon/figma/message-circle-01.svg';
import MoreIcon from 'op-storybook/lib/assets/icon/figma/dots-horizontal.svg'
import { FormattedMessage, useIntl } from 'react-intl';
import { SectionHeading } from 'op-storybook/stories/components/Typography/SectionHeading';
import BroadcastsIcon from '@ourpeople/shared/Asset/Icon/figma/layers-three-02.svg';
import LogoutIcon from '@ourpeople/shared/Asset/Icon/figma/log-out-01.svg';
import ManageFilesIcon from 'op-storybook/lib/assets/icon/figma/folder-lock.svg';
import TopicsIcon from '@ourpeople/shared/Asset/Icon/figma/message-chat-square.svg';
import PeopleIcon from '@ourpeople/shared/Asset/Icon/figma/users-01.svg';
import ReportsIcon from 'op-storybook/lib/assets/icon/figma/line-chart-up-02.svg';
import HelpdeskIcon from '@ourpeople/shared/Asset/Icon/figma/life-buoy-01.svg';
import SettingsIcon from '@ourpeople/shared/Asset/Icon/figma/settings-01.svg';
import OnboardingIcon from 'op-storybook/lib/assets/icon/figma/user-plus-01.svg';
import EventsIcon from '@ourpeople/shared/Asset/Icon/figma/calendar.svg';
import DashboardIcon from '@ourpeople/shared/Asset/Icon/figma/bar-chart-square-02.svg';
import AnnouncementIcon from 'op-storybook/lib/assets/icon/figma/announcement-02.svg';
import { PresentationIcon } from 'op-storybook/lib/components/PresentationIcon/PresentationIcon';
import { LineClamp } from 'op-storybook/stories/components/LineClamp/LineClamp';
import { BreakpointContext } from 'op-storybook/lib/providers/BreakpointProvider/BreakpointProvider';

import { Toolbar } from './Toolbar';
import { Tab } from './Tab';
import { paths } from '../../../Core/Routing/paths';
import { ShortList } from '../Content/ShortList';
import { TitleBar } from './TitleBar';
import { broadcastPaths } from '../../../Broadcasts/Routing/broadcastPaths';
import { ToolbarClearance } from '../Layout/ToolbarClearance';
import { PersonUuidAvatar } from '../../../../Common/Component/PersonUuidAvatar/PersonUuidAvatar';
import { useContextOrThrow, useLoggedInAuthDescription } from '../../../../Core/Hook';
import { PersonParser } from '../../../../Utility';
import { PageLayoutContext } from '../../../Core/Provider/PageLayoutProvider';
import { useEnvironmentSettings } from '../../../../Common/Hook';
import { EnvironmentSettings } from '../../../../Models';
import { StandardListItem } from '../Content/StandardListItem';
import { MetricsPermission } from '../../../../Reporting/Model';
import { usePermissions } from '../../../../Security/Hook';
import { BroadcastsPermission } from '../../../../Broadcasts/Model';
import { FormsPermission } from '../../../../Forms/Model';
import { FilesPermission } from '../../../../Files/Model';
import { ChatsPermission } from '../../../../Chat/Model';
import { PeoplePermission } from '../../../../People/Model';
import { EventsPermission } from '../../../../Events/Model';
import { ReportsPermission } from '../../../../Reporting/Model/ReportsPermission';
import { SystemPermission } from '../../../../System/Model';
import { Sheet } from '../Layout/Sheet';

type ConditionalListItem = ComponentProps<typeof StandardListItem> & {
  disallow?: (environmentSettings: EnvironmentSettings) => boolean;
};

export const TabBar: FC<PropsWithChildren> = ({
  children,
}) => {
  const intl = useIntl();
  const [moreOpen, setMoreOpen] = useState<boolean>(false);
  const authDescription = useLoggedInAuthDescription();
  const environmentSettings = useEnvironmentSettings();
  const screenWidth = useContextOrThrow(BreakpointContext);
  const { permissionAvailable } = usePermissions();
  const { modal, viewportBoundaries } = useContextOrThrow(PageLayoutContext);
  const secondaryPanel = !modal && viewportBoundaries.right && !viewportBoundaries.left;
  const showNavigation = !modal && !secondaryPanel;

  const filterListItems = useCallback((listItems: ConditionalListItem[]) => (
    listItems.filter(listItem => !listItem.disallow || !listItem.disallow(environmentSettings))
  ), [environmentSettings]);

  const adminItems = useMemo(() => filterListItems([
    {
      id: 'dashboard',
      text: intl.formatMessage({
        defaultMessage: 'Dashboard',
        description: 'Label for dashboard page in more navigation menu.',
      }),
      location: '/dashboard',
      icon: <PresentationIcon IconComponent={ DashboardIcon } size={ 5 }/>,
      disallow: () => !permissionAvailable(MetricsPermission.ALL),
    },
    {
      id: 'broadcasts',
      text: intl.formatMessage({
        defaultMessage: 'Broadcasts',
        description: 'Label for admin broadcasts page in more navigation menu.',
      }),
      location: generatePath(broadcastPaths.ADMIN_LIST),
      icon: <PresentationIcon IconComponent={ BroadcastsIcon } size={ 5 }/>,
      disallow: () => !permissionAvailable(BroadcastsPermission.DELETE),
    },
    {
      id: 'forms',
      text: intl.formatMessage({
        defaultMessage: 'Forms',
        description: 'Label for admin forms page in more navigation menu.',
      }),
      location: '/forms',
      icon: <PresentationIcon IconComponent={ FormsIcon } size={ 5 }/>,
      disallow: (environmentSettings) => (
        !environmentSettings.formsEnabled
        || !permissionAvailable(FormsPermission.REPORT)
      ),
    },
    {
      id: 'files',
      text: intl.formatMessage({
        defaultMessage: 'Files',
        description: 'Label for admin files page in more navigation menu.',
      }),
      location: '/files',
      icon: <PresentationIcon IconComponent={ ManageFilesIcon } size={ 5 }/>,
      disallow: (environmentSettings) => (
        !environmentSettings.filesEnabled
        || !permissionAvailable(FilesPermission.CREATE)
      ),
    },
    {
      id: 'topics',
      text: intl.formatMessage({
        defaultMessage: 'Topics',
        description: 'Label for admin topics page in more navigation menu.',
      }),
      location: '/topics',
      icon: <PresentationIcon IconComponent={ TopicsIcon } size={ 5 }/>,
      disallow: (environmentSettings) => (
        !environmentSettings.streamChatEnabled
        || !permissionAvailable(ChatsPermission.TOPICS_CREATE)
      ),
    },
    {
      id: 'people',
      text: intl.formatMessage({
        defaultMessage: 'People',
        description: 'Label for admin people page in more navigation menu.',
      }),
      location: '/people',
      icon: <PresentationIcon IconComponent={ PeopleIcon } size={ 5 }/>,
      disallow: () => !permissionAvailable(PeoplePermission.CREATE),
    },
    {
      id: 'onboarding',
      text: intl.formatMessage({
        defaultMessage: 'Onboarding',
        description: 'Label for admin onboarding page in more navigation menu.',
      }),
      location: '/onboarding',
      icon: <PresentationIcon IconComponent={ OnboardingIcon } size={ 5 }/>,
      disallow: (environmentSettings) => (
        !!environmentSettings.broadcastsV2Enabled
        || !permissionAvailable(PeoplePermission.READ)
      ),
    },
    {
      id: 'events',
      text: intl.formatMessage({
        defaultMessage: 'Events',
        description: 'Label for admin events page in more navigation menu.',
      }),
      location: '/events',
      icon: <PresentationIcon IconComponent={ EventsIcon } size={ 5 }/>,
      disallow: (environmentSettings) => (
        !permissionAvailable(EventsPermission.COVER_REPORT)
        || !environmentSettings.eventsEnabled
        || (
          !!environmentSettings.broadcastsV2Enabled
          && !environmentSettings.broadcastsV1EventsEnabled
        )
      ),
    },
    {
      id: 'reports',
      text: intl.formatMessage({
        defaultMessage: 'Reports',
        description: 'Label for admin reports page in more navigation menu.',
      }),
      location: '/reports',
      icon: <PresentationIcon IconComponent={ ReportsIcon } size={ 5 }/>,
      disallow: () => !permissionAvailable(ReportsPermission.ALL),
    },
    {
      id: 'settings',
      text: intl.formatMessage({
        defaultMessage: 'Settings',
        description: 'Label for admin settings page in more navigation menu.',
      }),
      location: '/settings',
      icon: <PresentationIcon IconComponent={ SettingsIcon } size={ 5 }/>,
      disallow: () => !permissionAvailable(SystemPermission.SETTINGS_UPDATE),
    },
  ]), [filterListItems, intl, permissionAvailable]);

  return (
    <>
      { (children || (showNavigation && screenWidth.lessThan.sm)) && (
        <>
          <Toolbar
            verticalAlign="end"
          >
            { children || (
              <Stack
                justify="space-between"
                align="flex-end"
                fillParent
              >
                <Tab
                  label={ intl.formatMessage({
                    defaultMessage: 'Home',
                    description: 'Label for Home link in tab bar.',
                  }) }
                  IconComponent={ SmileyHouseIcon }
                  pathname={ generatePath(paths.broadcasts.USER_FEED) }
                  onRepeatClick={ () => document.scrollingElement?.scrollTo({ top: 0, behavior: 'smooth' }) }
                />
                <Tab
                  label={ intl.formatMessage({
                    defaultMessage: 'Forms',
                    description: 'Label for Forms link in tab bar.',
                  }) }
                  IconComponent={ FormsIcon }
                  pathname="/my-forms"
                />
                <Tab
                  label={ intl.formatMessage({
                    defaultMessage: 'Files',
                    description: 'Label for Files link in tab bar.',
                  }) }
                  IconComponent={ FilesIcon }
                  pathname="/me/files"
                />
                <Tab
                  label={ intl.formatMessage({
                    defaultMessage: 'Chat',
                    description: 'Label for Chat link in tab bar.',
                  }) }
                  IconComponent={ ChatIcon }
                  pathname="/me/chat"
                />
                <Tab
                  label={ intl.formatMessage({
                    defaultMessage: 'More',
                    description: 'Label for more button in tab bar.',
                  }) }
                  IconComponent={ MoreIcon }
                  onClick={ () => setMoreOpen(true) }
                />
              </Stack>
            ) }
          </Toolbar>
          { !children && (
            <Sheet
              open={ moreOpen }
              onOpenChange={ setMoreOpen }
              fitContent
            >
              <ToolbarClearance
                clear={ ['top'] }
              >
                <Stack
                  direction="column"
                  css={ theme => ({
                    padding: theme.new.spacing[2],
                  }) }
                >
                  <ShortList
                    items={ [
                      {
                        id: 'account',
                        text: (
                          <LineClamp lines={ 1 }>
                            <span translate="no">
                              { PersonParser.fullName(authDescription.person) }
                            </span>
                          </LineClamp>
                        ),
                        secondaryText: intl.formatMessage({
                          defaultMessage: 'Edit account',
                          description: 'Label for edit account page in more navigation menu.',
                        }),
                        onClick: () => window.location.replace('/account/details'),
                        icon: (
                          <PersonUuidAvatar
                            personName={ PersonParser.fullName(authDescription.person) }
                            personUuid={ authDescription.person.uuid }
                            size="md"
                            waitForSrc={ true }
                          />
                        ),
                      },
                      {
                        id: 'beamer',
                        text: intl.formatMessage({
                          description: 'Label for Announcements item in more navigation menu.',
                          defaultMessage: 'Announcements',
                        }),
                        onClick: () => {
                          setMoreOpen(false)
                        },
                        icon: <PresentationIcon IconComponent={ AnnouncementIcon } size={ 5 }/>,
                      },
                      {
                        id: 'support',
                        text: intl.formatMessage({
                          defaultMessage: 'Get help',
                          description: 'Label for support page in more navigation menu.',
                        }),
                        onClick: () => window.open('https://get.ourpeople.help', '_blank', 'rel=noreferrer'),
                        icon: <PresentationIcon IconComponent={ HelpdeskIcon } size={ 5 }/>,
                      },
                      {
                        id: 'logout',
                        text: intl.formatMessage({
                          defaultMessage: 'Log out',
                          description: 'Label for logout item in more navigation menu.',
                        }),
                        onClick: () => window.location.replace('/logout'),
                        icon: <PresentationIcon IconComponent={ LogoutIcon } size={ 5 }/>,
                      },
                    ] }
                  />
                  { adminItems.length > 0 && (
                    <>
                      <SectionHeading>
                        <FormattedMessage
                          description="Label for admin subsection of more navigation sheet."
                          defaultMessage="Admin"
                        />
                      </SectionHeading>
                      <ShortList
                        items={ adminItems }
                      />
                    </>
                  ) }
                </Stack>
              </ToolbarClearance>
              <TitleBar
                title={ intl.formatMessage({
                  description: 'Title for sheet containing more navigation options',
                  defaultMessage: 'More',
                }) }
              />
            </Sheet>
          ) }
        </>
      ) }
    </>
  );
};
