import { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { LocalisedString } from 'op-storybook/lib/model/LocalisedString/LocalisedString';

import { useContentDefinitionRegistry } from '../../../Content/Hook';
import { BasicAutocomplete, BasicAutocompleteProps } from '../../../Common/Component';

type Props = Omit<BasicAutocompleteProps, 'options' | 'placeholder' | 'label'>;

export const ContentTypeAutocomplete: FC<Props> = props => {
  const intl = useIntl();
  const { contentDefinitions } = useContentDefinitionRegistry();
  const options: LocalisedString[] = useMemo(() => (contentDefinitions || [])
      .filter(contentDefinition => contentDefinition.categoryId !== null)
      .map(contentDefinition => ({
        id: contentDefinition.id,
        localisation: contentDefinition.getLocalisedType(intl),
      }))
      .sort((a, b) => +(a.localisation > b.localisation)),
    [contentDefinitions, intl],
  );

  return (
    <BasicAutocomplete
      options={ options }
      label={ intl.formatMessage({
        id: 'chatStatusAutocomplete.label',
        description: 'Label for content type autocomplete',
        defaultMessage: 'Types',
      }) }
      placeholder={ intl.formatMessage({
        id: 'chatStatusAutocomplete.placeholder',
        description: 'Placeholder for content type autocomplete',
        defaultMessage: 'Search…',
      }) }
      { ...props }
    />
  );
};
