import React, { ComponentProps, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { max, min, parseISO } from 'date-fns';
import { Link, useLocation } from 'react-router-dom';
import { IconButton } from 'op-storybook/stories/components/IconButton/IconButton';
import { Button } from 'op-storybook/stories/components/Button/Button';
import BackIcon from 'op-storybook/lib/assets/icon/figma/flip-backward.svg';
import { useIntl } from 'react-intl';

import {
  FetchPersonalDeliveryContentsParams,
  useFetchPersonalDeliveryContents,
  useRecallErrorHandler
} from '../../Hook';
import { PersonalContent, PersonalDelivery } from '../../Model';
import { ArrayHelper } from '../../../Common/Utility';
import { TimelineDefinition } from '../../../Broadcasts/Service';
import { Content, InboxLoadingCard, StackEndCard } from '..';
import { useCancellablePost } from '../../../Hooks';
import { CardError } from '../CardError/CardError';
import { StackNavigationProps } from '../../../Content/Component';
import { BroadcastContentContext } from '../../../Broadcasts/Model';

type Props = {
  delivery: PersonalDelivery;
  onChange: (delivery: PersonalDelivery) => void;
  onReloadRequired: () => void;
};

export const ContentsContainer: FC<Props> = ({
  delivery,
  onChange,
  onReloadRequired,
}) => {
  const deliveryIsStack = useMemo(() => {
    const timelineDefinition = new TimelineDefinition();
    return delivery.broadcast.contents[0] && timelineDefinition.definesContentType(delivery.broadcast.contents[0].type);
  }, [delivery.broadcast.contents]);
  const [activeContentIndex, setActiveContentIndex] = useState<number>(+deliveryIsStack);
  const [pageNum, setPageNum] = useState<number>(1);
  const fetchPersonalDeliveryContentsParams = useMemo<FetchPersonalDeliveryContentsParams>(() => ({
    pageNum,
    sort: 'index_asc',
  }), [pageNum]);
  const [fetchPersonalDeliveryContentsResult] = useFetchPersonalDeliveryContents(delivery.delivery.id, fetchPersonalDeliveryContentsParams);
  const [contents, setContents] = useState<PersonalContent[]>([]);
  const pagination = fetchPersonalDeliveryContentsResult?.content?.pagination;
  const activeContent = contents[activeContentIndex] as PersonalContent | undefined;
  const [maxIndex] = useState<number>(delivery.broadcast.contents.length - +!deliveryIsStack);
  const location = useLocation();
  const closeButtonProps: ComponentProps<typeof Button> = useMemo(() => ({
    component: Link,
    to: {
      pathname: '/inbox',
      search: location.search,
    },
  }), [location.search]);
  const navigationProps = useMemo<StackNavigationProps>(() => ({
    onNextButtonClicked: () => setActiveContentIndex(activeContentIndex => Math.min(maxIndex, activeContentIndex + 1)),
    nextButtonDisabled: !!(activeContent?.content.mandatory && !activeContent.response),
    nextButtonHidden: maxIndex <= 1 || activeContentIndex >= maxIndex,
    progress: maxIndex <= 1 ? undefined : activeContentIndex / maxIndex,
    previousButtonProps: activeContentIndex === 0
      ? closeButtonProps
      : {
        onClick: () => (
          setActiveContentIndex(activeContentIndex => Math.max(0, activeContentIndex - 1))
        ),
      },
  }), [activeContent?.content.mandatory, activeContent?.response, activeContentIndex, maxIndex]);
  const onStackEndCard = maxIndex > 1 && activeContentIndex === maxIndex;
  const whenFetchContentsError = useRecallErrorHandler(onReloadRequired);
  const intl = useIntl();

  useEffect(() => {
    if (!fetchPersonalDeliveryContentsResult?.error) {
      return;
    }

    whenFetchContentsError(fetchPersonalDeliveryContentsResult.error);
  }, [fetchPersonalDeliveryContentsResult?.error, onReloadRequired, whenFetchContentsError]);

  useEffect(() => {
    const content = fetchPersonalDeliveryContentsResult?.content;

    if (!content) {
      return;
    }

    setContents(contents => contents.concat(content.contents || []));
  }, [deliveryIsStack, fetchPersonalDeliveryContentsResult?.content]);

  const context: BroadcastContentContext = useMemo(() => ({
    deliveryId: delivery.delivery.id,
    contentCount: (pagination?.itemCount ?? contents.length) - 1 || 1,
    nudge: delivery.nudge || undefined,
    closeButton: (
      <IconButton
        { ...closeButtonProps }
        size="md"
        IconComponent={ BackIcon }
        label={ intl.formatMessage({
          description: 'Label for back button in inbox when viewing a broadcast at mobile resolutions',
          defaultMessage: 'Return to inbox',
        }) }
        variant="secondary"
      />
    ),
    navigationProps,
  }), [
    contents.length,
    delivery.delivery.id,
    delivery.nudge,
    navigationProps,
    closeButtonProps,
    pagination?.itemCount,
    intl,
  ]);

  useEffect(() => {
    const pageSize = fetchPersonalDeliveryContentsResult?.content?.pagination.pageSize || 20;
    setPageNum(pageNum => Math.max(pageNum, Math.ceil((activeContentIndex + 1 + CONTENT_PAGINATION_THRESHOLD) / pageSize)));
  }, [activeContentIndex, fetchPersonalDeliveryContentsResult]);

  const whenDeliveryDismissed = useCallback(() => (
    onChange({
      ...delivery,
      dismissedAt: new Date().toISOString(),
    })
  ), [delivery, onChange]);

  const [dismiss] = useCancellablePost(
    '/me/broadcasts/deliveries/dismiss',
    whenDeliveryDismissed,
    useCallback(() => { /* Do nothing */
    }, []),
  );

  useEffect(() => {
    if (delivery.respondedAt && delivery.completedAt) {
      return;
    }

    const responseDates = contents
      .map(content => content.response?.createdAt)
      .filter(Boolean)
      .map(at => parseISO(at as string));

    const respondedAt = responseDates.length > 0 ? min(responseDates) : undefined;
    if (!respondedAt) {
      return;
    }

    const mandatoryContents = contents.filter(content => content.content.mandatory);

    const mandatoryResponseDates = mandatoryContents
      .map(content => content.response?.createdAt)
      .filter(Boolean)
      .map(at => parseISO(at as string));

    const completed = mandatoryResponseDates.length >= mandatoryContents.length;
    const completedAt = completed
      ? mandatoryResponseDates.length > 0
        ? max(mandatoryResponseDates)
        : respondedAt
      : undefined;

    if (!!respondedAt === !!delivery.respondedAt && !!completedAt === !!delivery.completedAt) {
      return;
    }

    onChange({
      ...delivery,
      respondedAt: delivery.respondedAt ?? respondedAt?.toISOString() ?? null,
      completedAt: delivery.completedAt ?? completedAt?.toISOString() ?? null,
    });
  }, [contents, delivery, onChange]);

  useEffect(() => {
    if (delivery.seenAt) {
      return;
    }

    const seenDates = contents.map(content => content.seenAt)
      .filter(Boolean)
      .map(seenAt => seenAt as string)
      .map(seenAt => parseISO(seenAt));

    const seenAt = min(seenDates);

    if (isNaN(seenAt.getTime())) {
      return;
    }

    onChange({
      ...delivery,
      seenAt: seenAt.toISOString(),
    });
  }, [contents, delivery, onChange]);

  useEffect(() => {
    delivery.completedAt
    && activeContentIndex >= maxIndex - 1
    && !delivery.dismissedAt
    && dismiss({ deliveryIds: [delivery.delivery.id] });
  }, [activeContentIndex, delivery, dismiss, maxIndex]);

  const whenChanged = useCallback((content: PersonalContent) => (
    setContents(contents => ArrayHelper.replace(contents, activeContentIndex, content))
  ), [activeContentIndex]);

  return onStackEndCard
    ? (
      <StackEndCard
        navigationProps={ navigationProps }
      />
    )
    : activeContent
      ? (
        <Content
          content={ activeContent }
          onChange={ whenChanged }
          context={ context }
          deliveryId={ delivery.delivery.id }
          onReloadRequired={ onReloadRequired }
        />
      )
      : fetchPersonalDeliveryContentsResult === null
        ? <InboxLoadingCard/>
        : <CardError/>
};

const CONTENT_PAGINATION_THRESHOLD = 3;
