import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';
import { FormattedMessage } from 'react-intl';
import { rgba } from 'polished';
import { Typography } from 'op-storybook/stories/components/Typography/Typography';
import { StyleBuilder } from 'op-storybook/lib/model/StyleBuilder/StyleBuilder';

import { useQuery } from '../../../Hooks/useQuery';
import { useContextOrThrow } from '../../../Core/Hook/useContextOrThrow';
import { ApiContext } from '../../../Contexts';
import { DeprecatedBroadcast } from '../../Model/DeprecatedBroadcast';
import { ContentForExport } from '../ContentForExport/ContentForExport';
import { ContentPreview } from '../../../Content/Component/ContentPreview/ContentPreview';
import { StyledContentPadding, StyledLiveCardContainerBackground } from './style';
import { useContentDefinitionRegistry } from '../../../Content/Hook/useContentDefinitionRegistry';
import { Content } from '../../../Models/Content/Content';
import { AuthProvider } from '../../../Components/App/AuthProvider';
import { LoggedInAuthProvider } from '../../../Security/Provider';
import { LoginStateProvider } from '../../../Core/Provider/LoginStateProvider';
import { IdentityProvider } from '../../../Core/Provider/IdentityProvider/IdentityProvider';
import {
  RichTextPlaceholderDefinitionRegistryProvider
} from '../../../Common/Provider/RichTextPlaceholderDefinitionRegistryProvider';

type ErrorWithContext = {
  context: string;
  error: unknown;
};

type Params = {
  broadcastId?: string;
};

export const BroadcastPreviewForImageExport: FC = () => {
  const query = useQuery();
  const token = query.get('authToken');
  const api = useContextOrThrow(ApiContext);
  const { broadcastId } = useParams<Params>();
  const [content, setContent] = useState<Content>();
  const { getContentDefinition } = useContentDefinitionRegistry();
  const [error, setError] = useState<ErrorWithContext>();
  const styles = useMemo(buildStyles, []);

  useEffect(() => {
    if (!token) {
      return;
    }

    api.setJwtToken(token);
  }, [api, token]);

  useEffect(() => {
    if (!api.authorized || !broadcastId) {
      return;
    }

    api.get<{ broadcast: DeprecatedBroadcast }>(`/broadcasts/${ broadcastId }`)
      .then(response => response.data.broadcast)
      .then(broadcast => {
        const firstContent = broadcast.content[0];

        if (!firstContent) {
          return;
        }

        const firstContentIsStack = getContentDefinition(firstContent.type)?.id === 'stack';

        if (firstContentIsStack && !broadcast.content[1]) {
          return;
        }

        setContent(
          firstContentIsStack
            ? broadcast.content[1]
            : firstContent
        );
      })
      .catch(error => {
        setError({
          context: `Failed to retrieve broadcast with ID ${ broadcastId }:`,
          error: error,
        });
      });
  }, [api, broadcastId, getContentDefinition]);

  const whenReady = useCallback(() => {
    const contentReadyEvent = new CustomEvent('opContentReady');
    document.dispatchEvent(contentReadyEvent);
  }, []);

  const getErrorOutput = useCallback((error: ErrorWithContext) => (
    <pre id="error">
      { `${ error.context }\n` }
      {
        error.error instanceof Error
          ? `${ error.error.toString() }\n${ error.error.stack || '' }`
          : JSON.stringify(error)
      }
    </pre>
  ), []);

  return (
    error
      ? getErrorOutput(error)
      : api.authorized
        ? (
          <ErrorBoundary
            beforeCapture={ (_scope, error) => setError({
              context: 'Error rendering <BroadcastPreviewForImageExport />:',
              error,
            }) }
            fallback={ error && getErrorOutput(error) }
          >
            <LoginStateProvider>
              <IdentityProvider>
                <AuthProvider>
                  <LoggedInAuthProvider>
                    <RichTextPlaceholderDefinitionRegistryProvider>
                      <StyledLiveCardContainerBackground id="content">
                        <StyledContentPadding>
                          <ContentPreview aspectRatio={ 16 / 9 }>
                            { content && (
                              <ContentForExport
                                content={ content }
                                onReady={ whenReady }
                              />
                            ) }
                            <div
                              css={ styles.previewOverlayContainer }
                            >
                              <div
                                css={ styles.previewOverlay }
                              >
                                <Typography
                                  css={ { textTransform: 'uppercase' } }
                                  palette={ {
                                    colour: 'grey',
                                    intensity: 900,
                                    contrast: true,
                                  } }
                                  weight="semibold"
                                  size="xs"
                                  type="display"
                                >
                                  <FormattedMessage
                                    description="Header for broadcast preview overlay used in email notification"
                                    defaultMessage="Preview"
                                  />
                                </Typography>
                                <Typography
                                  palette={ {
                                    colour: 'grey',
                                    intensity: 900,
                                    contrast: true,
                                  } }
                                  size="md"
                                  align="center"
                                >
                                  <FormattedMessage
                                    description="Supporting text for broadcast preview overlay used in email notification"
                                    defaultMessage="Tap to view"
                                  />
                                </Typography>
                              </div>
                            </div>
                          </ContentPreview>
                        </StyledContentPadding>
                      </StyledLiveCardContainerBackground>
                    </RichTextPlaceholderDefinitionRegistryProvider>
                  </LoggedInAuthProvider>
                </AuthProvider>
              </IdentityProvider>
            </LoginStateProvider>
          </ErrorBoundary>
        )
        : null
  );
};

const buildStyles: StyleBuilder = () => ({
  previewOverlayContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  previewOverlay: theme => ({
    position: 'relative',
    backgroundColor: `${ rgba(theme.new.palette.teal[900].main, 0.5) }`,
    zIndex: 1,
    width: '150px',
    height: '150px',
    borderRadius: theme.new.borderRadius.large,
    padding: theme.new.spacing[8],
    display: 'flex',
    flexDirection: 'column',
    gap: theme.new.spacing[2],
    alignItems: 'center',
    justifyContent: 'center',
  }),
});
