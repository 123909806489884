import styled from '@emotion/styled';

import { AiBetaButton } from '../AiBetaButton/AiBetaButton';

export const StyledLoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${ props => props.theme.palette.grey[300] };
`;

export const StyledInputLabel = styled.label`
  width: 100%;
`;

export const StyledButtonContainer = styled.div`
  ${ props => props.theme.new.breakpoints.md.up } {
    min-height: ${ props => props.theme.new.spacing[10] };
  }
  
  position: relative;
`;

export const StyledBetaButton = styled(AiBetaButton)`
  ${ props => props.theme.new.breakpoints.md.up } {
    position: absolute;
  }
  
  bottom: ${ props => props.theme.new.spacing[2] };
  left: ${ props => props.theme.new.spacing[2] };
`;
