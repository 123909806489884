import { useMemo } from 'react';

import { FetchResponse, useGet } from '../../Hooks';
import { AccessRequest, Paginated } from '../../Models';

export const useFetchAccessRequests = (): FetchResponse<Paginated<'accessRequests', AccessRequest>> => {
  const params = useMemo(() => ({
    noExpired: 1,
    noInvalidated: 1,
    noRedeemed: 1,
  }), []);
  return useGet('/access-requests', params);
}
