import {useCallback, useMemo} from 'react';
import {TextField} from 'op-storybook/stories/components/TextField/TextField';
import {Form} from 'op-storybook/lib/components/Form/Form';
import {FormattedMessage, useIntl} from 'react-intl';
import {Stack} from 'op-storybook/lib/components/Stack/Stack';
import {useValidationErrors} from 'op-storybook/lib/hooks/useValidationErrors';
import {RoundedTextarea} from '@ourpeople/shared/Core/Component/Input/RoundedTextarea/RoundedTextarea';
import {useDateTimeFormatter} from '@ourpeople/shared/Core/Hook/useDateFormatter';
import {parseISO} from 'date-fns/parseISO';
import { isValid } from 'date-fns/isValid';

import {FormComponent} from '../../../Model/BroadcastBlockDefinition';
import {EventBlock} from '../../../Service/Blocks/EventBlockDefinition';
import {useContextOrThrow} from '../../../../../Core/Hook';
import {MinimumInset} from '../../../../Common/Component/Layout/MinimumInset';
import {BroadcastBlockDefinitionRegistryContext} from '../../../Context/BroadcastBlockDefinitionRegistry';
import {Label} from '../../../../../Components/Content/Label';

export const EventBlockForm: FormComponent<EventBlock> = ({
  block,
  validationTree,
  onChange,
}) => {
  const intl = useIntl();
  const { inputDateTime } = useDateTimeFormatter();
  const { getBlockErrorDefinitions } = useContextOrThrow(BroadcastBlockDefinitionRegistryContext);
  const startDate = useMemo<Date|undefined>(
    () => block.attributes.date.start ? parseISO(block.attributes.date.start) : undefined,
    [block.attributes.date.start],
  );
  const endDate = useMemo<Date|undefined>(
    () => block.attributes.date.end ? parseISO(block.attributes.date.end) : undefined,
    [block.attributes.date.end],
  );
  const blockErrorDefinitions = useMemo(() => getBlockErrorDefinitions(block), [block, getBlockErrorDefinitions]);
  const { formatValidationTreeWithDefinitions } = useValidationErrors();
  const formattedNameErrors = useMemo(() => formatValidationTreeWithDefinitions(
    validationTree.children.attributes?.children.name,
    blockErrorDefinitions.name,
  ), [blockErrorDefinitions.name, formatValidationTreeWithDefinitions, validationTree.children.attributes?.children.name]);

  const whenNameChanged = useCallback((name: string) => {
    onChange({
      ...block,
      attributes: {
        ...block.attributes,
        name,
      },
    });
  }, [block, onChange]);
  
  const whenLocationChanged = useCallback((location: string) => {
    onChange({
      ...block,
      attributes: {
        ...block.attributes,
        location,
      },
    });
  }, [block, onChange]);

  const whenDescriptionChanged = useCallback((description: string) => {
    onChange({
      ...block,
      attributes: {
        ...block.attributes,
        description,
      },
    });
  }, [block, onChange]);

  const whenStartDateTimeChanged = useCallback((dateTime: string) => {
    onChange({
      ...block,
      attributes: {
        ...block.attributes,
        date: {
          ...block.attributes.date,
          start: dateTime,
        },
      },
    });
  }, [block, onChange]);

  const whenEndDateTimeChanged = useCallback((dateTime: string) => {
    onChange({
      ...block,
      attributes: {
        ...block.attributes,
        date: {
          ...block.attributes.date,
          end: dateTime,
        },
      },
    });
  }, [block, onChange]);

  return (
    <MinimumInset
      left={ 2 }
      right={ 2 }
    >
      <Form>
        <Stack
          direction="column"
          gap={ 2 }
        >
          <TextField
            value={ block.attributes.name }
            onChange={ value => whenNameChanged(value) }
            label={ intl.formatMessage({
              description: 'Label for broadcast event block name input.',
              defaultMessage: 'Name',
            }) }
            errorMessages={ formattedNameErrors }
            placeholder={ intl.formatMessage({
              description: 'Placeholder for broadcast event block name input.',
              defaultMessage: 'Add name…',
            }) }
          />

          <TextField
            label={ intl.formatMessage({
              defaultMessage: 'Start date',
              description: 'Label for broadcast event block start date input.',
            }) }
            inputProps={ {
              type: 'datetime-local',
            } }
            value={ startDate && isValid(startDate) ? inputDateTime(startDate.toISOString()) : '' }
            onChange={ whenStartDateTimeChanged }
          />

          <TextField
            label={ intl.formatMessage({
              defaultMessage: 'End date',
              description: 'Label for broadcast event block end date input.',
            }) }
            inputProps={ {
              type: 'datetime-local',
            } }
            value={ endDate && isValid(endDate) ? inputDateTime(endDate.toISOString()) : '' }
            onChange={ whenEndDateTimeChanged }
          />

          <TextField
            value={ block.attributes.location ?? '' }
            onChange={ value => whenLocationChanged(value) }
            label={ intl.formatMessage({
              description: 'Label for broadcast event block location input.',
              defaultMessage: 'Location',
            }) }
            errorMessages={ formattedNameErrors }
            placeholder={ intl.formatMessage({
              description: 'Placeholder for broadcast event block name input.',
              defaultMessage: 'Optional location…',
            }) }
          />
          
          <Label><FormattedMessage
            description="Label for broadcast event block description input."
            defaultMessage="Description"
          /></Label>
          <RoundedTextarea
            placeholder={ intl.formatMessage({
              description: 'Placeholder for broadcast event block description input.',
              defaultMessage: 'Optional description…',
            }) }
            value={ block.attributes.description ?? '' }
            onChange={ event => whenDescriptionChanged(event.currentTarget.value) }
          />
        </Stack>
      </Form>
    </MinimumInset>
  );
};
