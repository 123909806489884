import React, { FunctionComponent, ReactElement, ReactNode, useMemo } from 'react';
import rehype from 'rehype';
import { Literal, Node, Parent } from 'unist';
import { Element } from 'hast';
import { Heading, List } from 'mdast';
import { Badge } from 'op-storybook/stories/components/Badge/Badge';

import { useContextOrThrow } from '../../../Core/Hook';
import {
  RichTextPlaceholderDefinitionRegistryContext
} from '../../Provider/RichTextPlaceholderDefinitionRegistryProvider';

export type NodeHandler = {
  handlesNode: (node: Node) => boolean;
  handle: (
    node: Node,
    children: ReactNode,
  ) => ReactElement;
};

export interface SafeRichTextContentProps {
  text: string;
  handlers?: NodeHandler[];
  placeholderBehaviour?: 'substitute' | 'badge';
}

export const SafeRichTextContent: FunctionComponent<SafeRichTextContentProps> = ({
  text,
  handlers = defaultHandlers,
  placeholderBehaviour = 'substitute',
}) => {
  const { getDefinitions } = useContextOrThrow(RichTextPlaceholderDefinitionRegistryContext);
  const placeholderDefinitions = useMemo(() => getDefinitions(), [getDefinitions]);
  const textWithPlaceholderSubstitutions = useMemo(() => (
    placeholderDefinitions.reduce(
      (partiallySubstitutedText, placeholderDefinition) => {
        return partiallySubstitutedText.replaceAll(
          `[${ placeholderDefinition.placeholderString }]`,
          placeholderBehaviour === 'substitute'
            ? placeholderDefinition.substitution
            : `<placeholder>${ placeholderDefinition.localisedString }</placeholder>`
        );
      },
      text,
    )
  ), [placeholderDefinitions, text]);

  const finalHandlers = useMemo<NodeHandler[]>(() => (
    placeholderBehaviour === 'badge'
      ? handlers.concat([
        {
          handlesNode: node => node.type === 'element' && node.tagName === 'placeholder',
          handle: (_node, children) => (
            <span css={ { display: 'inline-block' } }>
              <Badge
                variant="badge-modern"
                colour="grey"
                label={ children }
              />
            </span>
          )
        } as NodeHandler
      ])
      : handlers
  ), [handlers, placeholderBehaviour]);

  const ast = useMemo(() => (
    rehype().parse(textWithPlaceholderSubstitutions)
  ), [textWithPlaceholderSubstitutions]);

  return (
    <SafeRichTextFromAst
      tree={ ast }
      handlers={ finalHandlers }
    />
  );
};

interface SafeRichTextFromAstProps {
  tree: Node;
  handlers: NodeHandler[],
}

const SafeRichTextFromAst: FunctionComponent<SafeRichTextFromAstProps> = ({
  tree,
  handlers,
}) => {
  const children = useMemo(() => {
    if ((tree as Parent).children) {
      return (tree as Parent).children.map((node, index) => (
        <SafeRichTextFromAst
          key={ index }
          tree={ node }
          handlers={ handlers }
        />
      ));
    } else if (tree.type === 'text') {
      return <>{ (tree as Literal).value }</>;
    }
    return '';
  }, [handlers, tree]);

  const handler = handlers.find(handler => handler.handlesNode(tree));
  if (!handler) {
    return <>{ children }</>;
  }

  return handler.handle(tree, children);
};

const defaultHandlers: NodeHandler[] = [
  {
    handlesNode: (node: Node) => (
      node.type === 'element' && [
        'p',
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'strong',
        'em',
        'ol',
        'ul',
        'li',
        'b',
        'i',
      ].includes((node as Element).tagName)
    ),
    handle: (node, children) => React.createElement(
      (node as Element).tagName,
      {},
      children,
    )
  },
  {
    handlesNode: (node: Node) => (node.type === 'element' && (node as Element).tagName === 'br') || node.type === 'break',
    handle: () => <br/>
  },
  {
    handlesNode: (node: Node) => node.type === 'paragraph',
    handle: (_node, children) => <p>{ children }</p>
  },
  {
    handlesNode: (node: Node) => node.type === 'heading',
    handle: (node, children) => (
      React.createElement(
        `h${ (node as Heading).depth }`,
        {},
        children,
      )
    ),
  },
  {
    handlesNode: (node: Node) => node.type === 'list',
    handle: (node, children) => (node as List).ordered ? <ol>{ children }</ol> : <ul>{ children }</ul>
  },
  {
    handlesNode: (node: Node) => node.type === 'listItem',
    handle: (_node, children) => <li>{ children }</li>
  },
  {
    handlesNode: (node: Node) => node.type === 'emphasis',
    handle: (_node, children) => <em>{ children }</em>
  },
  {
    handlesNode: (node: Node) => node.type === 'strong',
    handle: (_node, children) => <strong>{ children }</strong>
  },
];
