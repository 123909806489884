import { FunctionComponent } from 'react';

import { RoleId } from '../../Security/Model';
import { useLocalisedRoleNames } from '../../Security/Hook';

type Props = {
  role: RoleId
}

export const UserRole: FunctionComponent<Props> = (
  { role }
) => {
  const { getRoleById } = useLocalisedRoleNames();
  const roleName = getRoleById(role)?.name;

  return <>{roleName}</>;
};
