import { FunctionComponent, ReactNode } from 'react';

import {
  StyledHeadingOne,
  StyledHeadingTwo,
  StyledHeadingThree,
  StyledHeadingFour,
  StyledHeadingFive,
  StyledHeadingSix,
} from './style';

type HeadingProps = {
  type?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  className?: string;
  children?: ReactNode;
};

export const Heading: FunctionComponent<HeadingProps> = ({ children, type, className }) => {
  switch (type) {
    case 'h6':
      return <StyledHeadingSix className={ className }>{children}</StyledHeadingSix>;
    case 'h5':
      return <StyledHeadingFive className={ className }>{children}</StyledHeadingFive>;
    case 'h4':
      return <StyledHeadingFour className={ className }>{children}</StyledHeadingFour>;
    case 'h3':
      return <StyledHeadingThree className={ className }>{children}</StyledHeadingThree>;
    case 'h2':
      return <StyledHeadingTwo className={ className }>{children}</StyledHeadingTwo>;
    case 'h1':
    default:
      return <StyledHeadingOne className={ className }>{children}</StyledHeadingOne>;
  }
};
