import { Redirect, Route, Switch } from 'react-router-dom';
import { FC } from 'react';
import { LoadingCard } from '@ourpeople/shared/Core/Component/Feedback';
import { CenteredGenericErrorMessage } from '@ourpeople/shared/Core/Component/Content';
import { useIntl } from 'react-intl';

import { GuardedRoute } from '../../../Common/Component';
import { RegistrationPage } from '../../../Security/Routing/RegistrationPage/RegistrationPage';
import { LoginPage } from '../../../Security/Routing';
import { useFetchAnonymousSettings } from '../../../Security/Hook';
import {ELEVATE_PATH, Fullscreen} from '../../../Components';
import { LoginStateContext } from '../../Provider/LoginStateProvider';
import { useContextOrThrow } from '../../Hook';
import { LoginOtpPage } from '../../../Security/Routing/LoginOtpPage/LoginOtpPage';
import { SetPasswordFlow } from '../../../Security/Routing/SetPasswordFlow/SetPasswordFlow';
import { ElevateLoginPage } from '../../../Security/Routing/ElevateLoginPage/ElevateLoginPage';
import { AcceptInvitationPage } from '../../../Security/Routing/AcceptInvitationPage/AcceptInvitationPage';
import {
  RichTextPlaceholderDefinitionRegistryProvider
} from '../../../Common/Provider/RichTextPlaceholderDefinitionRegistryProvider';
import {
  IdentifiedUserOtpRequestPage
} from '../../../Security/Routing/IdentifiedUserOtpRequestPage/IdentifiedUserOtpRequestPage';

export const AnonymousSettingsRouter: FC = () => {
  const intl = useIntl();
  const fetchAnonymousSettingsResult = useFetchAnonymousSettings();
  const { loginTargetUrl, loggedIn, loginOriginUrl } = useContextOrThrow(LoginStateContext);

  return (
    fetchAnonymousSettingsResult
      ? fetchAnonymousSettingsResult.content
        ? (
          <Switch>
            <GuardedRoute
              exact
              path="/register"
              condition={ fetchAnonymousSettingsResult.content.userSelfRegistration[0] !== 'disabled' }
            >
              <RegistrationPage
                anonymousSettings={ fetchAnonymousSettingsResult.content }
              />
            </GuardedRoute>
            <Route path="/forgot-password">
              <SetPasswordFlow
                basePath="/forgot-password"
                loginTargetUrl={ loginTargetUrl }
                initialPageHeaderText={ intl.formatMessage({
                  description: 'Heading text used on forgot password page.',
                  defaultMessage: 'Forgot password?',
                }) }
                initialPageSupportingText={ intl.formatMessage({
                  description: 'Supporting text used on forgot password page.',
                  defaultMessage: 'No worries, we’ll send you reset instructions.',
                }) }
                defaultCountryCode={ fetchAnonymousSettingsResult.content.defaultCountryCode }
              />
            </Route>
            <Route path="/set-password">
              <SetPasswordFlow
                basePath="/set-password"
                loginTargetUrl={ loginTargetUrl }
                initialPageHeaderText={ intl.formatMessage({
                  description: 'Heading text used on set password page.',
                  defaultMessage: 'Set password',
                }) }
                initialPageSupportingText={ intl.formatMessage({
                  description: 'Supporting text used on set password page.',
                  defaultMessage: 'Before you can use the OurPeople web app you\'ll need a password. To start, we\'ll need your phone number or email address.',
                }) }
                defaultCountryCode={ fetchAnonymousSettingsResult.content.defaultCountryCode }
              />
            </Route>
            <Route
              path="/invitation"
              exact
            >
              <RichTextPlaceholderDefinitionRegistryProvider>
                <AcceptInvitationPage anonymousSettings={ fetchAnonymousSettingsResult.content }/>
              </RichTextPlaceholderDefinitionRegistryProvider>
            </Route>
            <GuardedRoute
              path="/login"
              exact
              condition={ !loggedIn }
              redirect={ loginTargetUrl || '/' }
            >
              <LoginPage
                anonymousSettings={ fetchAnonymousSettingsResult.content }
                loginTargetUrl={ loginTargetUrl }
              />
            </GuardedRoute>
            <GuardedRoute
              path="/login/otp"
              condition={ !loggedIn }
              redirect={ loginTargetUrl || '/' }
            >
              <LoginOtpPage
                anonymousSettings={ fetchAnonymousSettingsResult.content }
                loginTargetUrl={ loginTargetUrl }
                defaultCountryCode={ fetchAnonymousSettingsResult.content.defaultCountryCode }
              />
            </GuardedRoute>
            <Route exact path={ELEVATE_PATH}>
              <ElevateLoginPage
                anonymousSettings={ fetchAnonymousSettingsResult.content }
                loginTargetUrl={ loginTargetUrl }
                loginOriginUrl={ loginOriginUrl }
              />
            </Route>
            <Redirect to="/login"/>
          </Switch>
        )
        : <CenteredGenericErrorMessage/>
      : <Fullscreen><LoadingCard/></Fullscreen>
  );
};
