import { useIntl } from 'react-intl';
import { FC } from 'react';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { StackEnd } from 'op-storybook/lib/components/StackEnd/StackEnd';

import { ConditionalTooltip, VerticallySpaced } from '../../../Common/Component';
import { ContentDefinition, EditorContent } from '../../../Content/Model';
import { StyledHeading, StyledButton, StyledChildren } from './style';
import { useLoggedInAuthDescription } from '../../../Core/Hook';
import { AiFeatureBadge } from '../../../Common/Component/AiFeatureBadge/AiFeatureBadge';

type Props = {
  heading: string;
  contentDefinitions: ContentDefinition[];
  onContentCreated: (editorContent: EditorContent) => void;
  disabled?: boolean;
};

export const BroadcastEditorContentMenuCategory: FC<Props> = ({
  heading,
  contentDefinitions,
  onContentCreated,
  disabled = false,
  children,
}) => {
  const intl = useIntl();
  const authDescription = useLoggedInAuthDescription();
  const availableDefinitions = contentDefinitions.filter(contentDefinition => contentDefinition.availableToCreate);

  return (
    <VerticallySpaced gap={ 0 } align="flex-start">
      <StyledHeading role="menuitem">{ heading }</StyledHeading>
      { availableDefinitions.map((contentDefinition, index) => (
        <ConditionalTooltip
          key={ index }
          title={ intl.formatMessage({
            description: 'Message displayed when a content type is disabled.',
            defaultMessage: 'This content is not available on your current plan 🙁',
          })}
          active={ !contentDefinition.hasRequiredFeatureFlags(authDescription.env_settings) }
          >
          <StyledButton
            disabled={ disabled || !contentDefinition.hasRequiredFeatureFlags(authDescription.env_settings) }
            onClick={ () => onContentCreated(contentDefinition.createEditorContent()) }
          >
            <Stack fillParent>
              <contentDefinition.IconComponent/>
              <span>{ contentDefinition.getLocalisedType(intl) }</span>
              { contentDefinition.new
                ? (
                  <StackEnd>
                    <AiFeatureBadge/>
                  </StackEnd>
                )
                : contentDefinition.richText && (
                  <StackEnd>
                    <AiFeatureBadge/>
                  </StackEnd>
                )
              }
            </Stack>
          </StyledButton>
        </ConditionalTooltip>
      )) }
      <StyledChildren>
        { children }
      </StyledChildren>
    </VerticallySpaced>
  );
};
