import { FetchResponse, useGet } from '../../Hooks';
import { Paginated } from '../../Models';
import { Category } from '../Model';

export type FetchCategoriesSort = 'name_asc' | 'name_desc';

export type FetchCategoriesParams = {
  pageNum: number;
  sort: FetchCategoriesSort;
  ids?: string | null;
  search?: string | null;
}

export const useFetchCategories = (params: FetchCategoriesParams): FetchResponse<Paginated<'categories', Category>> => {
  const endpoint = '/forms/categories';
  return useGet<Paginated<'categories', Category>>(endpoint, params);
};
