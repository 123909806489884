import { FC, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ContextCreator } from '@ourpeople/shared/Core/Utility/ContextCreator';
import { ApiRequest } from '@ourpeople/shared/Core/Model/ApiRequest';

import { Identity } from '../../Model/Identity/Identity';
import { Api } from '../../../Services';
import { RequestState } from '../../../Models';
import { useMounted } from '../../../Common/Hook';
import { ApiContext } from '../../../Contexts';
import { useContextOrThrow } from '../../Hook';
import { LoginStateContext } from '../LoginStateProvider';
import { ErrorResponseReader } from '../../../Common/Utility';

type IdentityContextValue = {
  identityRequest: ApiRequest<Identity>;
};

export const IdentityContext = ContextCreator.withDisplayName<IdentityContextValue>(
  'IdentityContext',
  null,
);

export const IdentityProvider: FC<PropsWithChildren> = ({ children }) => {
  const { setLoggedIn } = useContextOrThrow(LoginStateContext);
  const mounted = useMounted();
  const api = useContext(ApiContext);

  const [
    identityRequest,
    setIdentityRequest,
  ] = useState<ApiRequest<Identity>>({
    result: null,
    state: RequestState.PENDING,
  });

  const identify = useCallback((api: Api) => {
    setIdentityRequest({
      result: null,
      state: RequestState.FETCHING,
    });

    api.get<Identity>('/auth/identity')
      .then(response => response.data)
      .then(identity => {
        if (!mounted.current) {
          return;
        }

        setIdentityRequest({
          result: identity,
          state: RequestState.COMPLETE,
        });
      })
      .catch(error => {
        if (!mounted.current) {
          return;
        }

        if (ErrorResponseReader.isApiError(error) && error.response.data.error.status === 401) {
          setLoggedIn(false);
        }

        setIdentityRequest({
          result: error,
          state: RequestState.FAILED,
        });
      });
  }, [mounted, setLoggedIn]);

  useEffect(() => {
    if (!api) {
      return;
    }

    identify(api);
  }, [identify, api]);

  const providerValue = useMemo(() => ({
    identityRequest,
  }), [identityRequest]);

  return (
    <IdentityContext.Provider value={ providerValue }>
      { children }
    </IdentityContext.Provider>
  );
}
