import { FormattedMessage, useIntl } from 'react-intl';
import { FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { LoadingCard } from '@ourpeople/shared/Core/Component/Feedback';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';

import { ImportStatus } from '../../Model';
import { ApiContext } from '../../../Contexts';
import { usePolling } from '../../../Hooks';
import { useFetchImportDetail } from '../../Hook';
import { Box, Flex, DeprecatedLink, LoadingContainer, StepperHeader } from '../../../Common/Component';
import { ColumnMapStep, CompleteStep, ImportProps, ReUploadDialog, ReviewStep, SummaryStep, UploadStep } from '..';
import { ImportDetailParser } from '../../Utility';
import { StyledLoadingMessage } from './style';

export const ImportSteps: FunctionComponent<Required<ImportProps>> = ({
  importId,
  Dropzone,
  additionalNotices,
  SummaryTable,
  ColumnMapErrors,
  ColumnErrors,
  PrepareErrors,
  RecordErrors,
  recordTypes,
}) => {
  const { url, path } = useRouteMatch();
  const intl = useIntl();
  const history = useHistory<{ status?: ImportStatus; }>();
  const api = useContext(ApiContext);
  const [importStatus, setImportStatus] = useState<ImportStatus | undefined>(history.location.state?.status);
  const [fetchImportDetailResponse, , reloadImportDetail] = usePolling(
    useFetchImportDetail(importId),
    2000,
    useMemo(() => fetchResult => {
      const importStatus = fetchResult?.content?.status;

      if (!importStatus) {
        return true;
      }

      setImportStatus(importStatus);
      return !['pending', 'run_requested', 'running'].includes(importStatus);
    }, []),
  );
  const [reUploadDialogOpen, setReUploadDialogOpen] = useState<boolean>(false);
  const importDetail = fetchImportDetailResponse?.content;
  const [willParse, setWillParse] = useState(false);
  const importing = Boolean(['run_requested', 'running'].includes(importStatus || ''));
  const processing = Boolean(importStatus === 'pending');
  const maxStepIndex = importStatus ? ImportDetailParser.determineMaxStepIndex(importStatus) : 1;

  useEffect(() => {
    if (!willParse) {
      return;
    }

    if (!api) {
      throw new Error('Api unavailable.');
    }

    let cancelled = false;

    api.post(`/imports/${ importId }/parse`)
      .then(() => {
        if (cancelled) {
          return;
        }

        reloadImportDetail();
        setWillParse(false);
      })
      .catch(() => {
        if (cancelled) {
          return;
        }

        reloadImportDetail();
        setWillParse(false);
      });

    return () => {
      cancelled = true;
    }
  }, [api, importId, reloadImportDetail, willParse])

  const whenImportButtonClicked = useCallback(() => {
    if (!api) {
      return;
    }

    api.post(`/imports/${ importId }/perform`)
      .finally(() => {
        history.push(`${ path.replace(/:importId\??/, importId || '') }/complete`);
        reloadImportDetail();
      });
  }, [api, history, importId, path, reloadImportDetail]);

  const uploadStep = useMemo(() => ({
    link: `${ url }`,
    title: intl.formatMessage({
      id: 'import.steps.upload',
      description: 'Upload step label in import stepper.',
      defaultMessage: 'Upload',
    }),
    render: () => (
      <UploadStep>
        <Dropzone
          onComplete={ (importId: string) => history.replace(`${ url }/${ importId }`) }
        />
      </UploadStep>
    ),
  }), [Dropzone, history, intl, url]);

  const columnMapStep = useMemo(() => ({
    link: `${ url }/map-columns`,
    title: intl.formatMessage({
      id: 'import.steps.mapColumns',
      description: 'Map columns step label in import stepper.',
      defaultMessage: 'Map columns',
    }),
    error: importStatus === 'invalid_mapping',
    disabled: maxStepIndex < 1,
    render: () => (
      !!importDetail && !processing && !importing &&
      <ColumnMapStep
        importDetail={ importDetail }
        onColumnMappingComplete={ reloadImportDetail }
        onReUploadButtonClicked={ () => setReUploadDialogOpen(true) }
        onContinueButtonClicked={ () => history.push(`${ url }/review`) }
        mappingTypes={ recordTypes }
        RootErrorComponent={ ColumnMapErrors }
        ColumnErrorComponent={ ColumnErrors }
        onReCheckClicked={ () => setWillParse(true) }
        parsing={ willParse }
      />
    ),
  }), [
    ColumnErrors,
    ColumnMapErrors,
    history,
    importDetail,
    importStatus,
    importing,
    intl,
    maxStepIndex,
    processing,
    recordTypes,
    reloadImportDetail,
    url,
    willParse
  ]);

  const reviewStep = useMemo(() => ({
    link: `${ url }/review`,
    title: intl.formatMessage({
      id: 'import.steps.review',
      description: 'Review step label in import stepper.',
      defaultMessage: 'Review',
    }),
    disabled: maxStepIndex < 2,
    error: importStatus === 'invalid_records',
    render: () => (
      !!importDetail && !processing && !importing &&
      <ReviewStep
        importDetail={ importDetail }
        onReUploadButtonClicked={ () => setReUploadDialogOpen(true) }
        additionalNotices={ additionalNotices }
        recordTypes={ recordTypes }
        RootErrorComponent={ PrepareErrors }
        RecordErrorComponent={ RecordErrors }
        onSaveRowsComplete={ () => setWillParse(true) }
        onContinueButtonClicked={ () => history.push(`${ url }/summary`) }
        onReCheckClicked={ () => setWillParse(true) }
        parsing={ willParse }
      />
    ),
  }), [
    PrepareErrors,
    RecordErrors,
    additionalNotices,
    history,
    importDetail,
    importStatus,
    importing,
    intl,
    maxStepIndex,
    processing,
    recordTypes,
    url,
    willParse
  ]);

  const summaryStep = useMemo(() => ({
    link: `${ url }/summary`,
    title: intl.formatMessage({
      id: 'import.steps.summary',
      description: 'Summary step label in import stepper.',
      defaultMessage: 'Summary',
    }),
    disabled: maxStepIndex < 3,
    render: () => (
      !!importDetail && !processing && !importing &&
      <SummaryStep
        importDetail={ importDetail }
        additionalNotices={ additionalNotices }
        onReUploadButtonClicked={ () => setReUploadDialogOpen(true) }
        onImportButtonClicked={ whenImportButtonClicked }
        SummaryTable={ SummaryTable }
      />
    ),
  }), [
    SummaryTable,
    additionalNotices,
    importDetail,
    importing,
    intl,
    maxStepIndex,
    processing,
    url,
    whenImportButtonClicked
  ]);

  const completeStep = useMemo(() => ({
    link: `${ url }/complete`,
    title: intl.formatMessage({
      id: 'import.stepper.complete',
      description: 'Complete step label in import stepper.',
      defaultMessage: 'Complete',
    }),
    disabled: maxStepIndex < 4,
    render: () => (
      !!importDetail && !processing && !importing &&
      <CompleteStep
        importDetail={ importDetail }
      />
    ),
  }), [importDetail, importing, intl, maxStepIndex, processing, url]);

  const steps = useMemo(() => [
    uploadStep,
    columnMapStep,
    reviewStep,
    summaryStep,
    completeStep,
  ], [columnMapStep, completeStep, reviewStep, summaryStep, uploadStep]);

  const activeStepIndex = useMemo(() => (
    Math.min(
      Math.max(
        steps.findIndex(step => step.link !== '' && history.location.pathname === step.link),
        0,
      ),
      maxStepIndex,
    )
  ), [steps, maxStepIndex, history.location.pathname]);

  const nextStepButton = useMemo(() => (
    <Button
      variant="primary"
      disabled={ activeStepIndex >= maxStepIndex }
    >
      <FormattedMessage
        id="import.stepper.next"
        description="Label for next step button in import stepper."
        defaultMessage="Next"
      />
    </Button>
  ), [activeStepIndex, maxStepIndex]);

  const previousStepButton = useMemo(() => (
    <Button
      disabled={ activeStepIndex === 0 }
    >
      <FormattedMessage
        id="import.stepper.previous"
        description="Label for previous step button in import stepper."
        defaultMessage="Previous"
      />
    </Button>
  ), [activeStepIndex]);

  const importStarted = importStatus && ['running', 'run_requested', 'completed', 'failed'].includes(importStatus);

  return importStatus === undefined
    ? <LoadingContainer><LoadingCard/></LoadingContainer>
    : (
      <>
        <StepperHeader
          steps={ steps }
          actions={
            <>
              {
                activeStepIndex > 0
                  ? (
                    <DeprecatedLink to={ steps[activeStepIndex - 1].link }>
                      { previousStepButton }
                    </DeprecatedLink>
                  )
                  : previousStepButton
              }
              { activeStepIndex === 3 && !importStarted
                ? (
                  <Button
                    variant="primary"
                    disabled={ ['invalid_mapping', 'invalid_records'].indexOf(importStatus) !== -1 }
                    onClick={ whenImportButtonClicked }
                  >
                    <FormattedMessage
                      id="import.stepper.startImport"
                      description="Label for start import button in import stepper."
                      defaultMessage="Start import"
                    />
                  </Button>
                )
                : activeStepIndex < 4 && (
                activeStepIndex < maxStepIndex
                  ? (
                    <DeprecatedLink to={ steps[activeStepIndex + 1].link }>
                      { nextStepButton }
                    </DeprecatedLink>
                  )
                  : nextStepButton
              ) }
            </>
          }
        />
        { (processing || importing) && history.location.pathname !== url && (
          <Box margin={ false }>
            <LoadingContainer>
              <Flex
                direction="column"
                gap={ 2 }
              >
                <LoadingCard/>
                <StyledLoadingMessage>
                  {
                    importing
                      ? (
                        <FormattedMessage
                          id="import.importing"
                          description="Loading spinner label while import is being performed."
                          defaultMessage="<p>We're performing your import.</p><p>This should just take a few seconds&hellip;</p>"
                        />
                      )
                      : (
                        <FormattedMessage
                          id="import.processing"
                          description="Loading spinner label while import is being processed."
                          defaultMessage="<p>We're checking your data.</p><p>This should just take a few seconds&hellip;</p>"
                        />
                      )
                  }
                </StyledLoadingMessage>
              </Flex>
            </LoadingContainer>
          </Box>
        ) }
        <ReUploadDialog
          open={ reUploadDialogOpen }
          onDismiss={ () => setReUploadDialogOpen(false) }
          Dropzone={ Dropzone }
        />
      </>
    );
};
