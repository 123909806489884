import { Paginated } from '../../Models';
import { MultiContentSubmission, SingleContentSubmission } from '../Model';
import { FetchResponse, useGet, useTransformedFetchResponse } from '../../Hooks';
import { SubmissionTransformer } from '../Utility';

export type FetchSubmissionsSort = 'created_at_asc'
  | 'created_at_desc'
  | 'created_by_first_name_asc'
  | 'created_by_first_name_desc'
  | 'created_by_last_name_asc'
  | 'created_by_last_name_desc'
  | 'closed_at_asc'
  | 'closed_at_desc';

export type FetchSubmissionsParams = {
  pageNum: number;
  sort: FetchSubmissionsSort;
  ids?: string | null;
  createdByIds?: string | null;
  closedByIds?: string | null
  noOpen?: 0 | 1;
  noClosed?: 0 | 1;
  createdSince?: string | null;
  createdUntil?: string | null;
  closedSince?: string | null;
  closedUntil?: string | null;
}

export const useFetchSubmissions = (
  formId: string,
  params: FetchSubmissionsParams,
): FetchResponse<Paginated<'submissions', SingleContentSubmission>> => (
  useTransformedFetchResponse(
    useGet<Paginated<'submissions', MultiContentSubmission>>(`/forms/${ formId }/submissions`, params),
    ({ submissions, pagination }) => ({
      pagination,
      submissions: submissions.map(SubmissionTransformer.consolidateSubmissionContent),
    }),
  )
);
