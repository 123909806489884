import { FC } from 'react';

import { StyledUnlockedItemIndex, StyledUnlockedItemCircle } from './style';

type Props = {
  index: number;
};

export const UnlockedItemCircle: FC<Props> = ({
  index,
}) => (
  <StyledUnlockedItemCircle>
    <StyledUnlockedItemIndex>
      { index }
    </StyledUnlockedItemIndex>
  </StyledUnlockedItemCircle>
);
