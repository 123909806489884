import { ContentResponse, ContentResponseWithInput, DraftResponse } from '../Model';

export class ResponseTransformer {
  public static transformForSubmission = <R extends ContentResponse>(response: R): DraftResponse<R['type']> => ({
    type: response.type,
    content: response.contentId,
    deliveryId: response.deliveryId,
    time_created: response.createdAt,
    ...( responseHasInput(response) ? response.input : {}),
  });
}

const responseHasInput = (response: ContentResponse): response is ContentResponseWithInput => (
  !!(response as ContentResponseWithInput).input
);
