import { FC } from 'react';
import { useDropzone } from 'react-dropzone';
import { FormattedMessage, useIntl } from 'react-intl';
import { LinearProgress } from '@mui/material';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';

import CloudIcon from '../../../Assets/img/icons/cloud-upload.svg';
import UploadIcon from '../../../Assets/img/icons/circle/upload.svg';
import ErrorIcon from '../../../Assets/img/icons/circle/error.svg';
import SuccessIcon from '../../../Assets/img/icons/circle/success.svg';
import { UploadState } from '../../Context';
import { StyledDropzone, StyledFileName, StyledProgressContainer } from './style';
import { PresentationIcon } from '..';

export type Props = {
  uploadState: UploadState | undefined;
  accept: string | string[];
  disabled?: boolean;
  multiple?: boolean;
  maxSize?: number;
  prompt?: string;
  onDrop: (files: File[]) => void;
  onReset: () => void;
};

export const Dropzone: FC<Props> = ({
  uploadState,
  accept,
  maxSize = Infinity,
  prompt,
  disabled = false,
  multiple = false,
  onDrop,
  onReset,
}) => {
  const intl = useIntl();
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open,
  } = useDropzone({
    accept,
    disabled,
    maxSize,
    multiple,
    noClick: true,
    onDrop,
  });

  const replaceLabel = intl.formatMessage({
    id: 'dropzone.replace',
    description: 'Label for replace button in dropzone component.',
    defaultMessage: 'Select another file',
  });

  return (
    <StyledDropzone
      {...getRootProps({ isDragActive })}
    >
      <input {...getInputProps()} />
      {
        uploadState
          ? uploadState.upload
            ? (
              <CompleteStep
                uploadState={uploadState}
                replaceLabel={replaceLabel}
                onReplaceClicked={open}
              />
            )
            : uploadState.error
              ? (
                <ErrorStep
                  uploadState={uploadState}
                  replaceLabel={replaceLabel}
                  onReplaceClicked={open}
                />
              )
              : (
                <UploadingStep
                  uploadState={uploadState}
                  onCancelClicked={onReset}
                  replaceLabel={replaceLabel}
                  onReplaceClicked={open}
                />
              )
          : (
            <PendingStep
              onBrowseClicked={open}
              prompt={prompt}
            />
          )
      }
    </StyledDropzone>
  );
};

type ErrorStepProps = {
  uploadState: UploadState;
  onReplaceClicked: () => void;
  replaceLabel: string;
};

const ErrorStep: FC<ErrorStepProps> = ({
  uploadState,
  onReplaceClicked,
  replaceLabel,
}) => (
  <>
    <PresentationIcon
      IconComponent={ErrorIcon}
      size="large"
      color="error.main"
    />
    <FormattedMessage
      id="dropzone.error"
      description="Generic error message for uploads in dropzone component."
      defaultMessage="Upload failed! Please try a different file."
    />
    <StyledFileName>{uploadState.file.name}</StyledFileName>
    <Button
      variant="secondary"
      onClick={onReplaceClicked}
    >
      {replaceLabel}
    </Button>
  </>
);

type CompleteStepProps = {
  uploadState: UploadState;
  onReplaceClicked: () => void;
  replaceLabel: string;
};

const CompleteStep: FC<CompleteStepProps> = ({
  uploadState,
  replaceLabel,
  onReplaceClicked,
}) => (
  <>
    <PresentationIcon
      IconComponent={SuccessIcon}
      size="large"
      color="success.main"
    />
    <FormattedMessage
      id="dropzone.success"
      description="Generic success message for uploads in dropzone component."
      defaultMessage="Upload successful!"
    />
    <StyledFileName>{uploadState.file.name}</StyledFileName>
    <Button
      variant="secondary"
      onClick={onReplaceClicked}
    >
      {replaceLabel}
    </Button>
  </>
);

type UploadingStepProps = {
  uploadState: UploadState;
  onCancelClicked: () => void;
  onReplaceClicked: () => void;
  replaceLabel: string;
};

const UploadingStep: FC<UploadingStepProps> = ({ uploadState, onCancelClicked, replaceLabel, onReplaceClicked }) => (
  <>
    <PresentationIcon
      IconComponent={UploadIcon}
      size="large"
      color="primary.main"
    />
    {
      uploadState.progress === 1
        ? (
          <FormattedMessage
            id="dropzone.processing"
            description="Generic message for processing uploads in dropzone component."
            defaultMessage="Processing..."
          />
        )
        : uploadState.progress === 0
          ? (
            <FormattedMessage
              id="dropzone.ready"
              description="Generic message for pending uploads in dropzone component."
              defaultMessage="Ready to upload!"
            />
          )
          : (
            <FormattedMessage
              id="dropzone.uploading"
              description="Generic message for uploads in progress in dropzone component."
              defaultMessage="Uploading..."
            />
          )
    }
    <StyledFileName>{uploadState.file.name}</StyledFileName>
    {uploadState.progress > 0 && (
      <StyledProgressContainer>
        <LinearProgress
          value={uploadState.progress * 100}
          variant={uploadState.progress < 1 ? 'determinate' : 'indeterminate'}
          color="secondary"
        />
      </StyledProgressContainer>
    )}
    {
      uploadState.progress > 0
        ? (
          <Button
            variant="secondary"
            onClick={onCancelClicked}
          >
            <FormattedMessage
              id="dropzone.cancel"
              description="Cancel upload button label in dropzone component."
              defaultMessage="Cancel upload"
            />
          </Button>
        )
        : (
          <Button
            variant="secondary"
            onClick={onReplaceClicked}
          >
            {replaceLabel}
          </Button>
        )
    }

  </>
);

type PendingStepProps = {
  onBrowseClicked: () => void;
  dragging?: boolean;
  prompt?: string;
};

const PendingStep: FC<PendingStepProps> = ({
  onBrowseClicked,
  dragging,
  prompt,
}) => (
  <>
    <PresentationIcon
      IconComponent={CloudIcon}
      size="large"
      color="secondary.main"
    />
    {
      dragging
        ? (
          <FormattedMessage
            id="dropzone.drop"
            description="Drop prompt while dragging in dropzone component."
            defaultMessage="Drop your file(s) here..."
          />
        )
        : prompt ?? (
          <FormattedMessage
            id="dropzone.prompt"
            description="Drag and drop prompt in dropzone component."
            defaultMessage="Drag &amp; drop your file(s) here..."
          />
        )
    }
    <Button
      variant="primary"
      disabled={dragging}
      onClick={onBrowseClicked}
    >
      <FormattedMessage
        id="dropzone.browse"
        description="Copy for browse button on upload form"
        defaultMessage="Browse to upload"
      />
    </Button>
  </>
);
