import { FunctionComponent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Tooltip } from '@mui/material';

import { EmailNotificationIcon, PushNotificationIcon, SmsNotificationIcon } from '../../Icons';
import { Flex } from '../../../../Common/Component';
import { ReportRecipientNotification } from '../../ResponseTable/ResponseTable';

interface Props {
  notifications: ReportRecipientNotification[];
}

export const RecipientNotifications: FunctionComponent<Props> = ({
  notifications
}) => {
  const intl = useIntl();

  const readableReason = (reason: string): string => {
    switch (reason) {
      case 'failed':
      case 'undelivered':
      case 'unknownError':
      case 'unknown':
        return intl.formatMessage({
          id: 'broadcasts.recipient.notification.reason.failed',
          defaultMessage: 'Failed',
        });
      case 'unknownHandset':
      case 'unreachableHandset':
        return intl.formatMessage({
          id: 'broadcasts.recipient.notification.reason.smsUnreachableHandset',
          defaultMessage: 'Handset could not be reached',
        });
      case 'unreachableNumber':
        return intl.formatMessage({
          id: 'broadcasts.recipient.notification.reason.smsUnreachableNumber',
          defaultMessage: 'Unreachable number',
        });
      case 'unsupportedRegion':
        return intl.formatMessage({
          id: 'broadcasts.recipient.notification.reason.smsUnsupportedRegion',
          defaultMessage: 'Region is not supported',
        });
      case 'unreachableCarrier':
        return intl.formatMessage({
          id: 'broadcasts.recipient.notification.reason.smsUnreachableCarrier',
          defaultMessage: 'Messaging is not supported by number',
        });
      case 'filtered':
        return intl.formatMessage({
          id: 'broadcasts.recipient.notification.reason.smsFiltered',
          defaultMessage: 'Message blocked by carrier',
        });
      case 'unsubscribed':
        return intl.formatMessage({
          id: 'broadcasts.recipient.notification.reason.unsubscribed',
          defaultMessage: 'Recipient was unsubscribed',
        });
      case 'insufficientCredits':
        return intl.formatMessage({
          id: 'broadcasts.recipient.notification.reason.insufficientCredits',
          defaultMessage: 'Insufficient credits',
        });
      case 'rateLimitReached':
        return intl.formatMessage({
          id: 'broadcasts.recipient.notification.reason.rateLimitReached',
          defaultMessage: 'Rate limit reached',
        });
      case 'notificationDisabled':
        return intl.formatMessage({
          id: 'broadcasts.recipient.notification.reason.notificationDisabled',
          defaultMessage: 'Recipient disabled this notification',
        });
      case 'notificationSnoozed':
        return intl.formatMessage({
          id: 'broadcasts.recipient.notification.reason.notificationSnoozed',
          defaultMessage: 'Recipient snoozed their notifications',
        });
      case 'frozen':
        return intl.formatMessage({
          id: 'broadcasts.recipient.notification.reason.frozen',
          defaultMessage: 'Recipient frozen',
        });
    }

    return reason;
  };

  const readableStatus = (status: ReportRecipientNotification['status'], reason?: null|string): string => {
    switch (status) {
      case 'unknown':
        return intl.formatMessage({
          id: 'broadcasts.recipient.notification.status.unknown',
          defaultMessage: 'Unknown',
        });
      case 'pending':
      case 'submitted':
        return intl.formatMessage({
          id: 'broadcasts.recipient.notification.status.pending',
          defaultMessage: 'Pending',
        });
      case 'delivered':
        return intl.formatMessage({
          id: 'broadcasts.recipient.notification.status.delivered',
          defaultMessage: 'Delivered',
        });
      case 'failed':
      case 'error':
      case 'rejected':
        return reason
          ? intl.formatMessage({
              id: 'broadcasts.recipient.notification.status.failed',
              defaultMessage: 'Failed - {reason}',
            }, {
              reason: readableReason(reason),
            })
          : intl.formatMessage({
              id: 'broadcasts.recipient.notification.status.failed',
              defaultMessage: 'Failed',
            });
    }

    return status;
  }

  return (
    <Flex gap={ 1 } noWrap>
      {
        notifications
          .map(
            (notification, index) => {
              switch (notification.service) {
                case 'push':
                  return (
                    <Tooltip
                      key={ index }
                      title={
                        <FormattedMessage
                          id="broadcasts.recipient.notification.push.tooltip"
                          defaultMessage="Push message ({ status })"
                          values={{
                            status: readableStatus(notification.status, notification.statusReason)
                          }}
                        />
                      }
                    >
                      <span><PushNotificationIcon /></span>
                    </Tooltip>
                  );
                case 'sms':
                  return (
                    <Tooltip
                      key={ index }
                      title={
                        <FormattedMessage
                          id="broadcasts.recipient.notification.sms.tooltip"
                          defaultMessage="Text ({ status })"
                          values={{
                            status: readableStatus(notification.status, notification.statusReason)
                          }}
                        />
                      }
                    >
                      <span><SmsNotificationIcon /></span>
                    </Tooltip>
                  );
                case 'email':
                  return (
                    <Tooltip
                      key={ index }
                      title={
                        <FormattedMessage
                          id="broadcasts.recipient.notification.email.tooltip"
                          defaultMessage="Email ({ status })"
                          values={{
                            status: readableStatus(notification.status, notification.statusReason)
                          }}
                        />
                      }
                    >
                      <span><EmailNotificationIcon /></span>
                    </Tooltip>
                  );
              }

              return null;
            }
          )
          .filter(Boolean)
      }
    </Flex>
  );
};
