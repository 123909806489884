import styled from '@emotion/styled';
import { Button } from 'op-storybook/stories/components/Button/Button';

export const StyledSpaceLogo = styled.img`
  width: ${ props => props.theme.spacing(8) };
  height: ${ props => props.theme.spacing(8) };
`;

export const StyledButton = styled(Button)`
  width: 100%;
`;

export const StyledContentContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const StyledLoginLink = styled.span`
  a {
    color: ${ props => props.theme.palette.primary.main };
  }
`;
