import styled from '@emotion/styled';

export const StyledDropzoneContainer = styled.div`
  display: block;
  margin: 0 auto;

  ${ props => props.theme.breakpoints.up('md') } {
    width: 50%;
  }
`;
