import styled from '@emotion/styled';

interface PercentageChangeProps {
  negative: boolean;
}

export const StyledPercentageChange = styled.div<PercentageChangeProps>`
  color: ${ props => props.negative ? props.theme.palette.error.main : props.theme.palette.success.main };
  font-weight: 400;
  margin: 0;
  font-size: 1.5rem;
  line-height: 1;
`;
