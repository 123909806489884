import { FetchResponse, useGet, useTransformedFetchResponse } from '../../Hooks';
import { FormAndMultiContentCards, SingleContentFormAndCards } from '../Model';
import { FormTransformer } from '../Utility';

export const useFetchPersonalForm = (
  formId: string,
): FetchResponse<SingleContentFormAndCards> => (
  useTransformedFetchResponse(
    useGet<FormAndMultiContentCards>(`/me/forms/${ formId }`),
    FormTransformer.consolidateFormAndCardsContent,
  )
);
