import styled from '@emotion/styled';

export const StyledTimelineBroadcastContent = styled.div`
  background-color: ${ props => props.theme.palette.background.paper };
  color: ${ props => props.theme.palette.text.primary };
  height: 100%;
`;

export const StyledTimelineBroadcastContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${ props => props.theme.spacing(2) };
  align-items: center;
  width: 100%;
`;
