import { ComponentProps, JSX, RefObject, useCallback, useMemo, useState } from 'react';
import { SearchField } from '@ourpeople/shared/Core/Component/Input/SearchField/SearchField';
import { QueryKey } from '@tanstack/react-query';
import { DropdownItemCheck } from 'op-storybook/stories/components/DropdownItemCheck/DropdownItemCheck';

import { Tag, TagType } from '../../../Tags/Model';
import { TagCondition } from '../../../Audiences/Model';
import { MinimumInset } from '../../Common/Component/Layout/MinimumInset';
import { InfiniteList } from '../../Common/Component/Content/InfiniteList';
import { StandardListItem } from '../../Common/Component/Content/StandardListItem';
import { useContextOrThrow } from '../../../Core/Hook';
import { ApiContext } from '../../../Contexts';
import { Paginated } from '../../../Models';

type Props<T extends TagType> = {
  tagType: T;
  condition: TagCondition<T>;
  onChange: (condition: TagCondition<T>) => void;
  scrollAreaRef: RefObject<HTMLDivElement>;
};

export const TagList = <T extends TagType>({
  tagType,
  condition,
  onChange,
  scrollAreaRef,
}: Props<T>): JSX.Element => {
  const api = useContextOrThrow(ApiContext);
  const [search, setSearch] = useState<string>('');
  const filters = useMemo(() => ({
    ...search ? { search } : {},
    type: tagType,
    sort: 'name_asc,id_asc',
  }), [search, tagType]);
  const queryKey = useMemo<QueryKey>(() => ['tags', tagType], [tagType]);

  const queryFn = useCallback<ComponentProps<typeof InfiniteList<Tag<T>, typeof filters>>['queryFn']>(async (filters, page) => {
    const response = await api.get<Paginated<'tags', Tag<T>>>('/tags', {
      params: {
        ...filters,
        pageNum: page,
      },
    });

    return {
      pagination: response.data.pagination,
      items: response.data.tags,
    };
  }, [api]);

  const whenTagClicked = useCallback((tag: Tag<T>) => {
    const selected = condition.tagIds.includes(tag.id);
    onChange({
      ...condition,
      tagIds: selected
        ? condition.tagIds.filter(tagId => tag.id !== tagId)
        : condition.tagIds.concat(tag.id),
    })
  }, [condition, onChange]);

  const renderItem = useCallback((tag: Tag<T>) => {
    return (
      <StandardListItem
        key={ tag.id }
        text={ tag.name }
        onClick={ event => {
          event.stopPropagation();
          event.preventDefault();
          whenTagClicked(tag);
        } }
        selected={ condition.tagIds.includes(tag.id) }
        { ...condition.tagIds.includes(tag.id) ? { endAdornment: <DropdownItemCheck/> } : {} }
      />
    )
  }, [condition.tagIds, whenTagClicked]);

  return (
    <>
      <MinimumInset
        top={ 2 }
        right={ 1 }
        bottom={ 2 }
        left={ 1 }
      >
        <SearchField
          value={ filters.search || '' }
          onChange={ setSearch }
        />
      </MinimumInset>
      <InfiniteList
        filters={ filters }
        queryKey={ queryKey }
        queryFn={ queryFn }
        renderItem={ renderItem }
        scrollAreaRef={ scrollAreaRef }
      />
    </>
  );
};
