import { FunctionComponent, ReactElement } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { parseISO } from 'date-fns';
import { LinearProgress } from '@mui/material';

import { ListedDelivery } from '../../../../Models';
import { TableBody, TableCell, TableHead, TableRow } from '../../../../Components';
import { StyledSeenCount, StyledSeenCountGraph, StyledSeenCountPercentage } from '../../List/styles';
import { PercentageCalculator } from '../../../../Utility/PercentageCalculator';
import { DateTimeFormatter } from '../../../../Utility/DateTimeFormatter';
import { StyledRecentDeliveriesTable } from './styles';
import { RecalledLabelWithTooltip } from '../../../../Broadcasts/Component';
import { Flex } from '../../../../Common/Component';

type Props = {
  deliveries: ListedDelivery[],
};

export const RecentDeliveriesTable: FunctionComponent<Props> = (props) => {
  const tableRows = props.deliveries.map(buildDeliveryRow);

  return (
    <StyledRecentDeliveriesTable size="small" rowLines={false}>
      <TableHead>
        <TableRow>
          <TableCell>
            <FormattedMessage
              id="broadcasts.report.recentDeliveries.table.delivered"
              defaultMessage="Delivered"
            />
          </TableCell>
          <TableCell>
            <FormattedMessage
              id="broadcasts.report.recentDeliveries.table.audience"
              defaultMessage="Audience"
            />
          </TableCell>
          <TableCell>
            <FormattedMessage
              id="broadcasts.report.recentDeliveries.table.percentageSeen"
              defaultMessage="% Seen"
            />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {tableRows}
      </TableBody>
    </StyledRecentDeliveriesTable>
  );
};

const buildDeliveryRow = (delivery: ListedDelivery): ReactElement<typeof TableRow> => {
  const seenPercentage = PercentageCalculator.percentage(
    delivery.report.counts.seen.total,
    delivery.report.counts.recipients.active,
  );
  return (
    <TableRow key={delivery.id}>
      <TableCell>
        <Flex gap={ 1 }>
          {
            delivery.deliveredAt !== null
              ? DateTimeFormatter.standard(parseISO(delivery.deliveredAt))
              : ''
          }
          { delivery.status === 'recalled' && <RecalledLabelWithTooltip recall={ delivery.recall }/> }
        </Flex>
      </TableCell>
      <TableCell>{delivery.report.counts.recipients.total}</TableCell>
      <TableCell>
        <StyledSeenCount>
          <StyledSeenCountPercentage>
            {/* eslint-disable-next-line react/style-prop-object */}
            <FormattedNumber value={seenPercentage} style="percent" />
          </StyledSeenCountPercentage>
          <StyledSeenCountGraph>
            <LinearProgress variant="determinate" value={seenPercentage * 100} color="secondary" />
          </StyledSeenCountGraph>
        </StyledSeenCount>
      </TableCell>
    </TableRow>
  );
};
