import { createContext } from 'react';

import { Notices } from '../../Common/Model';

interface NoticeContextValue {
  getNotices: () => Promise<Notices>;
}

export const NoticeContext = createContext<NoticeContextValue | null>(null);
NoticeContext.displayName = 'NoticeContext';
