import { FC, PropsWithChildren, useCallback, useContext, useState } from 'react';
import { createLocalStorageStateHook } from 'use-local-storage-state';

import { LocalStorageHook, LocalStorageHookContext } from '../Context';
import { SpaceContext } from '../../Core/Context';
import { useLoggedInAuthDescription } from '../../Core/Hook';

export const LocalStorageHookProvider: FC<PropsWithChildren> = ({ children }) => {
  const [hooks, setHooks] = useState<Map<string, LocalStorageHook<unknown>>>(new Map());
  const { spaceId } = useContext(SpaceContext);
  const { user: { id } } = useLoggedInAuthDescription();
  const namespace = `${ spaceId }.${ id }`;

  const getStorageHook = useCallback(<T,>(key: string, defaultValue: T | (() => T)) => {
    const namespacedKey = `${ namespace }.${key}`;
    const registeredHook = hooks.get(namespacedKey) as LocalStorageHook<T> | undefined;
    const hook = registeredHook || createLocalStorageStateHook(namespacedKey, defaultValue);

    if (!registeredHook) {
      setHooks(hooks => hooks.set(namespacedKey, hook as LocalStorageHook<unknown>));
    }

    return hook;
  }, [hooks, namespace]);

  return (
    <LocalStorageHookContext.Provider value={ getStorageHook }>
      { children }
    </LocalStorageHookContext.Provider>
  );
};
