import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { PeopleRequestParams } from '../../Hook';
import { PersonAutocomplete, PersonAutocompleteProps } from '../PersonAutocomplete/PersonAutocomplete';

type Props = PersonAutocompleteProps & {
  excludeBroadcastAdmin?: boolean;
  excludeGlobalAdmin?: boolean;
};

export const AdminAutocomplete = ({
  baseQuery,
  excludeBroadcastAdmin = false,
  excludeGlobalAdmin = false,
  ...props
}: Props): JSX.Element => {
  const intl = useIntl();
  const [preparedBaseQuery, setPreparedBaseQuery] = useState(
    prepareBaseQuery(
      baseQuery,
      excludeBroadcastAdmin,
      excludeGlobalAdmin,
    ),
  );
  const preparedBaseQueryRef = useRef(preparedBaseQuery);

  /**
   * When `baseQuery` is changed, "prepare" it so that it will still correctly filter the people
   * to administrators only.
   */
  useEffect(() => {
    const updatedPreparedBaseQuery = prepareBaseQuery(baseQuery, excludeBroadcastAdmin, excludeGlobalAdmin);

    if (queriesEqual(preparedBaseQueryRef.current, updatedPreparedBaseQuery)) {
      return;
    }

    preparedBaseQueryRef.current = updatedPreparedBaseQuery;
    setPreparedBaseQuery(preparedBaseQueryRef.current);
  }, [baseQuery, excludeBroadcastAdmin, excludeGlobalAdmin, preparedBaseQueryRef]);

  return <PersonAutocomplete
    {...props}
    baseQuery={preparedBaseQuery}
    label={
      props.label ||
      intl.formatMessage(
        {
          id: 'adminAutocomplete.label',
          defaultMessage: 'Select {limit, plural, one {administrator} other {administrators}}'
        },
        {
          limit: props.multiple ? Infinity : 1,
        }
      )
    }
    placeholder={
      props.placeholder ||
      intl.formatMessage(
        {
          id: 'adminAutocomplete.placeholder',
          defaultMessage: 'Search for {limit, plural, one {administrator} other {administrators}}'
        },
        {
          limit: props.multiple ? Infinity : 1,
        }
      )
    }
  />;
};

const prepareBaseQuery = (
  baseQuery?: PeopleRequestParams,
  excludeBroadcastAdmin: boolean = false,
  excludeGlobalAdmin: boolean = false,
): PeopleRequestParams => ({
  sort: ['first_name_asc'],
  ...baseQuery,
  roleIds: [
    [
      ...(excludeGlobalAdmin ? [] : ['ROLE_SUPER_ADMIN', 'ROLE_DEVELOPER']),
      ...(excludeBroadcastAdmin ? [] : ['ROLE_BROADCAST_ADMIN']),
      'ROLE_ADMIN',
    ].join(','),
  ],
});

const queriesEqual = (a: PeopleRequestParams, b: PeopleRequestParams): boolean => {
  return JSON.stringify(a) === JSON.stringify(b);
};
