import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AxiosError } from 'axios';
import { ButtonProps } from '@mui/material';

import { FetchProfilesParams, useFetchProfiles } from '../../../Hook';
import { Checkbox, PaginatedTable, TableCell, TableRow } from '../../../../Components';
import { ProfileRow, TagActionDialog } from '..';
import { useCancellableDelete, useCancellablePost, useQueryAsState } from '../../../../Hooks';
import { ErrorResponseReader, QueryParser } from '../../../../Common/Utility';
import { AudienceFilters } from '../../../../Audiences/Component';
import { Audience } from '../../../../Audiences/Model';
import { AudienceCreator } from '../../../../Audiences/Utility';
import { DeletePrompt, Flex } from '../../../../Common/Component';
import AddTagIcon from '../../../../Assets/img/icons/streamline/tags-add.svg';
import RemoveTagIcon from '../../../../Assets/img/icons/streamline/tags-minus.svg';
import ReplaceTagIcon from '../../../../Assets/img/icons/streamline/tags-refresh.svg';
import DeleteProfileIcon from '../../../../Assets/img/icons/streamline/single-neutral-actions-subtract.svg';
import { useContextOrThrow } from '../../../../Core/Hook';
import { ToastContext } from '../../../../Core/Context';
import { useUserRoles } from '../../../../Common/Hook';

export type ProfileAction = {
  type: 'delete' | 'remove' | 'add' | 'replace';
  query: {
    ids?: string[];
    audience?: Audience;
    search?: string;
    noExternallyManaged: boolean;
  };
};

type FetchProfilesQuery = {
  pageNum: string;
  search?: string;
};

interface DeletePayload {
  query: {
    ids: string[];
    noExternallyManaged: boolean;
  };
}

export const ProfilesTab: FunctionComponent = () => {
  const intl = useIntl();
  const [audience, setAudience] = useState<Audience>(AudienceCreator.createAudienceWithCondition({ type: 'everyone' }));
  const [fetchProfilesQuery, setFetchProfilesQuery] = useQueryAsState<FetchProfilesQuery>(initialFetchProfilesQuery);
  const { addSuccessToast, addErrorToast } = useContextOrThrow(ToastContext);
  const { userIsSuperAdmin } = useUserRoles();
  const fetchProfilesParams = useMemo<FetchProfilesParams>(() => ({
    pageNum: QueryParser.pageNum(fetchProfilesQuery),
    ...(fetchProfilesQuery.search ? { search: fetchProfilesQuery.search } : {}),
    audience,
    sort: 'created_at_asc',
  }), [fetchProfilesQuery, audience]);
  const fetchNonIntegrationProfilesParams = useMemo(() => ({
    ...fetchProfilesParams,
    noExternallyManaged: true,
  }), [fetchProfilesParams]);
  const [selectedProfileIds, setSelectedProfileIds] = useState<string[]>([]);
  const [selectionExpanded, setSelectionExpanded] = useState<boolean>(false);
  const [fetchNonIntegrationProfilesResult] = useFetchProfiles(fetchNonIntegrationProfilesParams, !selectedProfileIds.length);
  const totalNonIntegrationProfileCount = fetchNonIntegrationProfilesResult?.content?.pagination.itemCount || null;
  const [fetchProfilesResult, fetchProfilesRequestState, reloadProfiles] = useFetchProfiles(fetchProfilesParams);
  const pagination = fetchProfilesResult?.content?.pagination;
  const profileTagsAndPersons = useMemo(() => (
    fetchProfilesResult?.content?.profiles || []
  ), [fetchProfilesResult?.content?.profiles]);
  const currentPageSize = profileTagsAndPersons.length;
  const [willPerformAction, setWillPerformAction] = useState<ProfileAction>();
  const [willReload, setWillReload] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const externallyManagedCount = useMemo(() => (
    profileTagsAndPersons.filter(profileTagsAndPerson => profileTagsAndPerson.profile.externallyManaged).length
  ), [profileTagsAndPersons]);
  const selectableRowCount = currentPageSize - externallyManagedCount;

  const whenPageChanged = (pageNum: number) => (
    setFetchProfilesQuery(fetchProfilesQuery => ({
      ...fetchProfilesQuery,
      pageNum: pageNum.toString(),
    }))
  );

  const whenSearchChanged = (search: string) => {
    setFetchProfilesQuery(({ search: oldSearch, ...query }) => ({
      ...query,
      ...(search ? { search } : {}),
    }))
  };

  const whenClearSelectionClicked = () => {
    setSelectionExpanded(false);
    setSelectedProfileIds([]);
  };

  const whenExpandSelectionClicked = () => {
    setSelectionExpanded(true);
    setSelectedProfileIds([]);
  };

  const whenSelectAllClicked = () => {
    if (selectionExpanded || selectedProfileIds.length === selectableRowCount) {
      whenClearSelectionClicked();
    } else {
      setSelectedProfileIds(
        profileTagsAndPersons.filter(profileTagsAndPersons => !profileTagsAndPersons.profile.externallyManaged)
          .map(profileTagsAndPerson => profileTagsAndPerson.profile.id.toString())
      );
    }
  };

  const whenProfileChecked = (profileId: string, checked: boolean) => {
    setSelectedProfileIds(selectedProfileIds => {
      const selectedProfileIdsWithoutProfile = selectedProfileIds.filter(selectedProfileId => selectedProfileId !== profileId);
      return checked
        ? selectedProfileIdsWithoutProfile.concat(profileId)
        : selectedProfileIdsWithoutProfile;
    });
  };

  useEffect(() => {
    if (!willReload) {
      return;
    }

    const timeout = setTimeout(() => {
      addSuccessToast(
        intl.formatMessage({
          id: 'people.profiles.updateSuccessful',
          description: 'Toast message when profiles are updated successfully.',
          defaultMessage: 'Profiles updated successfully.',
        })
      );
      setWillPerformAction(undefined);
      setSelectedProfileIds([]);
      reloadProfiles();
      setWillReload(false);
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [addSuccessToast, intl, reloadProfiles, willReload]);

  const whenActionComplete = useCallback(() => {
    setWillReload(true);
  }, []);

  const whenActionFailed = useCallback((error: AxiosError<unknown>) => {
    if (
      ErrorResponseReader.isApiError(error)
      && error.response.data.error.message === 'Profile must have at least one team'
    ) {
      setErrors([
        intl.formatMessage({
          id: 'people.profiles.onlyTeamError',
          description: 'Error message when user attempts to remove a profiles only team.',
          defaultMessage: 'You can\'t remove a profile\'s only team.',
        }),
      ]);
    }

    addErrorToast(
      intl.formatMessage({
        id: 'people.profiles.updateSuccessful',
        description: 'Toast message when profiles are updated successfully.',
        defaultMessage: 'Profiles could not be updated.',
      })
    );
  }, [addErrorToast, intl]);

  const [deleteProfiles, deletingProfiles] = useCancellableDelete<unknown, DeletePayload>(
    '/profiles',
    whenActionComplete,
    whenActionFailed,
  );

  const [addTagToProfiles, addingTagToProfiles] = useCancellablePost(
    '/profiles/add-tag',
    whenActionComplete,
    whenActionFailed,
  );

  const [removeTagFromProfiles, removingTagFromProfiles] = useCancellablePost(
    '/profiles/remove-tag',
    whenActionComplete,
    whenActionFailed,
  );

  const [replaceTagOnProfiles, replacingTagOnProfiles] = useCancellablePost(
    '/profiles/replace-tag',
    whenActionComplete,
    whenActionFailed,
  );

  const whenDeleteConfirmed = useCallback(() => {
    if (!willPerformAction || !willPerformAction.query.ids) {
      return;
    }

    deleteProfiles({
      query: {
        ids: willPerformAction.query.ids,
        noExternallyManaged: true,
      },
    });
  }, [deleteProfiles, willPerformAction]);

  const whenActionConfirmed = useCallback((
    tagToAdd?: string,
    tagToRemove?: string,
  ) => {
    if (!willPerformAction) {
      return;
    }

    tagToAdd
      ? tagToRemove
        ? replaceTagOnProfiles({
          query: willPerformAction.query,
          removeTagId: tagToRemove,
          addTagId: tagToAdd,
        })
        : addTagToProfiles({
          query: willPerformAction.query,
          tagId: tagToAdd,
        })
      : removeTagFromProfiles({
        query: willPerformAction.query,
        tagId: tagToRemove,
      })
  }, [
    addTagToProfiles,
    removeTagFromProfiles,
    replaceTagOnProfiles,
    willPerformAction,
  ]);

  useEffect(() => {
    if (willPerformAction) {
      setErrors([]);
    }
  }, [willPerformAction]);

  return <>
    <AudienceFilters
      audience={ audience }
      setAudience={ setAudience }
      searchValue={ fetchProfilesQuery.search || '' }
      onSearchChanged={ whenSearchChanged }
      selectedRows={ selectionExpanded ? totalNonIntegrationProfileCount || 0 : selectedProfileIds.length }
      currentPageRows={ currentPageSize }
      totalRows={ totalNonIntegrationProfileCount }
      onClearSelectionClicked={ whenClearSelectionClicked }
      onExpandSelectionClicked={ whenExpandSelectionClicked }
      actions={ [
        ...(userIsSuperAdmin ? [{
            key: 'delete',
            color: 'primary',
            variant: 'outlined',
            disabled: selectionExpanded,
            onClick: () => setWillPerformAction({
              type: 'delete',
              query: {
                ids: selectedProfileIds,
                noExternallyManaged: true,
              },
            }),
            children: (
              <Flex gap={ 1 }>
                <DeleteProfileIcon
                  width="24px"
                  height="24px"
                  role="presentation"
                />
                <FormattedMessage
                  id="people.profiles.delete"
                  description="Label for delete bulk action button."
                  defaultMessage="Delete profiles"
                />
              </Flex>
            )
          } as ButtonProps & { key: string }] : []),
        {
          key: 'addTag',
          color: 'primary',
          variant: 'outlined',
          onClick: useCallback(() => setWillPerformAction({
            type: 'add',
            query: selectionExpanded
              ? {
                audience,
                noExternallyManaged: true,
                ...(fetchProfilesQuery.search ? { search: fetchProfilesQuery.search } : {}),
              }
              : {
                ids: selectedProfileIds,
                noExternallyManaged: true,
              },
          }), [selectedProfileIds, selectionExpanded, audience, fetchProfilesQuery.search]),
          children: (
            <Flex gap={ 1 }>
              <AddTagIcon
                width="24px"
                height="24px"
                role="presentation"
              />
              <FormattedMessage
                id="people.profiles.addTag"
                description="Label for add tag bulk action button."
                defaultMessage="Add tag"
              />
            </Flex>
          )
        },
        {
          key: 'removeTag',
          color: 'primary',
          variant: 'outlined',
          onClick: useCallback(() => setWillPerformAction({
            type: 'remove',
            query: selectionExpanded
              ? {
                audience,
                noExternallyManaged: true,
                ...(fetchProfilesQuery.search ? { search: fetchProfilesQuery.search } : {}),
              }
              : {
                ids: selectedProfileIds,
                noExternallyManaged: true,
              },
          }), [selectedProfileIds, selectionExpanded, audience, fetchProfilesQuery.search]),
          children: (
            <Flex gap={ 1 }>
              <RemoveTagIcon
                width="24px"
                height="24px"
                role="presentation"
              />
              <FormattedMessage
                id="people.profiles.removeTag"
                description="Label for remove tag bulk action button."
                defaultMessage="Remove tag"
              />
            </Flex>
          )
        },
        {
          key: 'replaceTag',
          color: 'primary',
          variant: 'outlined',
          onClick: useCallback(() => setWillPerformAction({
            type: 'replace',
            query: selectionExpanded
              ? {
                audience,
                noExternallyManaged: true,
                ...(fetchProfilesQuery.search ? { search: fetchProfilesQuery.search } : {}),
              }
              : {
                ids: selectedProfileIds,
                noExternallyManaged: true,
              },
          }), [selectedProfileIds, selectionExpanded, audience, fetchProfilesQuery.search]),
          children: (
            <Flex gap={ 1 }>
              <ReplaceTagIcon
                width="24px"
                height="24px"
                role="presentation"
              />
              <FormattedMessage
                id="people.profiles.replaceTag"
                description="Label for replace tag bulk action button."
                defaultMessage="Replace tag"
              />
            </Flex>
          )
        },
      ] }
    />
    <PaginatedTable
      rows={ profileTagsAndPersons }
      headerRow={
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              checked={ selectionExpanded || (selectedProfileIds.length > 0 && selectedProfileIds.length >= selectableRowCount) }
              indeterminate={ selectedProfileIds.length > 0 && selectedProfileIds.length < selectableRowCount }
              onChange={ whenSelectAllClicked }
            />
          </TableCell>
          <TableCell>
            <FormattedMessage
              id="people.profiles.person"
              description="Person column header"
              defaultMessage="Person"
            />
          </TableCell>
          <TableCell>
            <FormattedMessage
              id="people.profiles.teams"
              description="Teams column header"
              defaultMessage="Teams"
            />
          </TableCell>
          <TableCell>
            <FormattedMessage
              id="people.profiles.jobTitles"
              description="Job titles column header"
              defaultMessage="Job titles"
            />
          </TableCell>
          <TableCell>
            <FormattedMessage
              id="people.profiles.skills"
              description="Skills column header"
              defaultMessage="Skills"
            />
          </TableCell>
          <TableCell/>
        </TableRow>
      }
      rowRender={ profileTagsAndPerson => (
        <ProfileRow
          key={ profileTagsAndPerson.profile.id }
          profileTagsAndPerson={ profileTagsAndPerson }
          checked={ selectedProfileIds.includes(profileTagsAndPerson.profile.id.toString()) || selectionExpanded }
          onCheckedChange={ checked => whenProfileChecked(profileTagsAndPerson.profile.id.toString(), checked) }
          onActionClicked={ setWillPerformAction }
        />
      ) }
      pageNum={ fetchProfilesParams.pageNum }
      onPageChanged={ whenPageChanged }
      requestState={ fetchProfilesRequestState }
      onRetryClicked={ reloadProfiles }
      pagination={ pagination }
    />
    <DeletePrompt
      open={ willPerformAction?.type === 'delete' }
      onConfirm={ whenDeleteConfirmed }
      onCancel={ () => setWillPerformAction(undefined) }
      busy={ deletingProfiles || willReload }
      renderContent={ deletePromptString => (
        <FormattedMessage
          id="people.profile.deletePrompt"
          description="Content of delete confirmation prompt on profiles tab of people page."
          defaultMessage="Type {deletePromptString} to permanently delete { profileCount, plural, one {# profile} other {# profiles} }."
          values={ {
            deletePromptString: <strong>{ deletePromptString }</strong>,
            profileCount: willPerformAction?.query.ids?.length || 0,
          } }
        />
      ) }
    />
    <TagActionDialog
      action="add"
      open={ willPerformAction?.type === 'add' }
      onConfirm={ whenActionConfirmed }
      onDismiss={ () => setWillPerformAction(undefined) }
      busy={ addingTagToProfiles || willReload }
    />
    <TagActionDialog
      action="remove"
      open={ willPerformAction?.type === 'remove' }
      onConfirm={ whenActionConfirmed }
      onDismiss={ () => setWillPerformAction(undefined) }
      busy={ removingTagFromProfiles || willReload }
      errors={ errors }
    />
    <TagActionDialog
      action="replace"
      open={ willPerformAction?.type === 'replace' }
      onConfirm={ whenActionConfirmed }
      onDismiss={ () => setWillPerformAction(undefined) }
      busy={ replacingTagOnProfiles || willReload }
    />
  </>;
};

const initialFetchProfilesQuery: FetchProfilesQuery = {
  pageNum: '1',
};
