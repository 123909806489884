import { ChangeEventHandler, FC, FormEventHandler, useCallback, useEffect } from 'react';
import { TextField } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Heading } from '@ourpeople/shared/Core/Component/Content';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';
import { getDescendantErrors } from 'op-storybook/lib/utility/getDescendantErrors';

import { Flex, FlexPullRight, ValidationErrorMessage, VerticallySpaced } from '../../../../Common/Component';
import { ValidationTree, Validator } from '../../../../Common/Model';
import { ValidationErrorIdentifier } from '../../../../Common/Utility/ValidationErrorIdentifier';
import { Validate } from '../../../../Common/Utility';
import { StyledForm } from './style';

export type EditBroadcastCategoryFormState = {
  name: string;
};

type Props = {
  formState: EditBroadcastCategoryFormState;
  onChange: (formState: EditBroadcastCategoryFormState) => void;
  validation: ValidationTree<EditBroadcastCategoryFormState>;
  onValidationChange: (validation: ValidationTree<EditBroadcastCategoryFormState>) => void;
  onSubmit: () => void;
  submitting: boolean;
};

export const CategoryEditForm: FC<Props> = ({
  formState,
  onChange,
  validation,
  onValidationChange,
  onSubmit,
  submitting,
}) => {
  const nameErrors = validation.children.name?.errors || [];
  const whenSubmitted: FormEventHandler = useCallback(
    event => {
      event.preventDefault();
      const validation = validateFormState(formState);
      const validationErrors = getDescendantErrors(validation);
      onValidationChange(validation);
      if (validationErrors.length) {
        return;
      }

      onSubmit();
    },
    [formState, onSubmit, onValidationChange],
  );

  const whenNameBlurred = useCallback(() => (
    onValidationChange(validateFormState(formState))
  ), [formState, onValidationChange]);

  const whenNameChanged: ChangeEventHandler<HTMLInputElement> = useCallback(event => (
    onChange({
      name: event.currentTarget.value,
    })
  ), [onChange]);

  useEffect(() => {
    onValidationChange({
      errors: [],
      children: {},
    });
  }, [formState, onValidationChange]);

  return (
    <form
      onSubmit={ whenSubmitted }
    >
      <VerticallySpaced gap={ 2 }>
        <StyledForm>
          <div>
            <Heading type="h4">
              <FormattedMessage
                id="broadcastCategory.edit.name"
                description="Label for name field in broadcast category edit form."
                defaultMessage="Category name"
              />
            </Heading>
            <TextField
              value={ formState.name }
              onChange={ whenNameChanged }
              onBlur={ whenNameBlurred }
              fullWidth
            />
          </div>
          { nameErrors.map(error => (
            <ValidationErrorMessage key={ error.type }>
              {
                ValidationErrorIdentifier.isNotUniqueError(error)
                  ? (
                    <FormattedMessage
                      id="broadcastCategory.notUniqueError"
                      description="Error message when broadcast category name is not unique"
                      defaultMessage="A category with this name already exists, please choose a unique name"
                    />
                  )
                  : ValidationErrorIdentifier.isBlankError(error)
                    ? (
                      <FormattedMessage
                        id="broadcastCategory.blankError"
                        description="Error message when broadcast category name is blank"
                        defaultMessage="Category name cannot be blank."
                      />
                    )
                    : (
                      <FormattedMessage
                        id="broadcastCategory.blankError"
                        description="Error message when broadcast category name is blank"
                        defaultMessage="There is a problem with your category name."
                      />
                    )
              }
            </ValidationErrorMessage>
          )) }
        </StyledForm>
        <Flex>
          <FlexPullRight>
            <Button
              type="submit"
              variant="primary"
              disabled={ !!nameErrors.length }
              busy={ submitting }
            >
              <FormattedMessage
                id="broadcastCategory.save"
                description="Broadcast category save button label."
                defaultMessage="Save"
              />
            </Button>
          </FlexPullRight>
        </Flex>
      </VerticallySpaced>
    </form>
  );
};

const validateFormState = (formState: EditBroadcastCategoryFormState): ValidationTree<EditBroadcastCategoryFormState> => ({
  errors: [],
  children: {
    name: {
      errors: nameValidator.validate(formState.name),
      children: {},
    },
  },
});
const nameValidator = new Validator([Validate.notBlank]);
