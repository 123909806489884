import { format } from 'date-fns';

export class CsvDownloader {
  public static download = (csv: string, fileNamePrefix: string): void => {
    const fileName = `${fileNamePrefix}_${format(new Date(), 'yyyyMMddHHmmss')}.csv`;
    const options = {
      type: 'text/csv;charset=utf-8;'
    };
    const blob = new Blob([csv], options);
    const downloadLink = document.createElement('a');
    if (typeof downloadLink.download !== 'undefined') {
      const url = URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.setAttribute('download', fileName);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      setTimeout(function() {
        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(url);
      }, 250);
    }
  }
}
