import { FC, ReactNode, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { parseISO } from 'date-fns';
import { Link } from 'react-router-dom';
import { BreakpointContext } from 'op-storybook/lib/providers/BreakpointProvider/BreakpointProvider';

import { DateTimeFormatter } from '../../../Utility';
import { FileSizeFormatter, PopOverState } from '../../../Common/Model';
import { CenteredCell, TableCell } from '../../../Components';
import FolderIcon from '../../../Assets/img/icons/streamline/folder-empty.svg';
import FileIcon from '../../../Assets/img/icons/streamline/common-file-empty.svg';
import { TableRowContextMenu } from '../../../Common/Component/TableRowContextMenu/TableRowContextMenu';
import { useDeleteNodeAction, useNodeActions } from '../../Hook';
import { useApi, useContextOrThrow } from '../../../Core/Hook';
import { ToastContext } from '../../../Core/Context';
import { StyledActionCell, StyledActiveRow, StyledItemName } from './style';
import { FileNode, FolderNode, Node, NodeAndUpload, UpdateNodeRequestData } from '../../Model';
import { NodePermissionsDialog } from '../NodePermissionsDialog/NodePermissionsDialog';
import { Audience } from '../../../Audiences/Model';
import { useFileSelect, useMounted } from '../../../Common/Hook';
import { RenamePrompt } from '../../../Common/Component';
import { FileUploaderContext } from '../../../Common/Context';
import { UniqueIdGenerator } from '../../../Common/Utility';
import { Upload } from '../../../Types';
import { RequestState } from '../../../Models';
import {
  InlinePersonAvatarAndName
} from '../../../Common/Component/InlinePersonAvatarAndName/InlinePersonAvatarAndName';

interface Props {
  nodeAndUpload: NodeAndUpload;
  containingNode: FolderNode | undefined;
  downloadFile: (id: string) => void;
  onReloadRequired: () => void;
}

export const FilesRow: FC<Props> = ({
  nodeAndUpload,
  containingNode,
  downloadFile,
  onReloadRequired,
}) => {
  const intl = useIntl();
  const api = useApi();
  const [updatingNodeWithAudience, setUpdatingNodeWithAudience] = useState<boolean>(false);
  const { addSuccessToast, addErrorToast } = useContextOrThrow(ToastContext);
  const [active, setActive] = useState<boolean>(false);
  const { lessThan } = useContextOrThrow(BreakpointContext);
  const { startGlobalUpload } = useContextOrThrow(FileUploaderContext);
  const openFileSelect = useFileSelect();
  const [filePermissionsModalState, setFilePermissionsModalState] = useState<PopOverState>(PopOverState.CLOSED);
  const [renameDialogState, setRenameDialogState] = useState<PopOverState>(PopOverState.CLOSED);
  const [updatingNode, setUpdatingNode] = useState<boolean>(false);
  const [deleteConfirmationModal, deleteAction, deleteNodeState] = useDeleteNodeAction(
    nodeAndUpload.node,
    onReloadRequired,
    updatingNode,
  );
  const isFolder = nodeAndUpload.node.type === 'folder';
  const size: string = nodeAndUpload.node.type === 'file' && nodeAndUpload.upload.size
    ? FileSizeFormatter.format(nodeAndUpload.upload.size)
    : '-';
  const icon = isFolder ? <FolderIcon/> : <FileIcon/>;
  const ISODate = parseISO(nodeAndUpload.node.updated.at);
  const user = <InlinePersonAvatarAndName person={ nodeAndUpload.node.updated.by }/>;
  const date = getDate(lessThan.sm, ISODate, intl.locale, user);
  const mounted = useMounted();

  const updateFolder = useCallback((data: UpdateNodeRequestData, onSuccess?: (node: FolderNode) => void, onFail?: (error: unknown) => void) => {
    api.post<{ folder: FolderNode }>(
      `/files/folders/${ nodeAndUpload.node.id }`,
      data,
    )
      .then(response => {
        setTimeout(() => {
          onSuccess && onSuccess(response.data.folder);
          addSuccessToast(intl.formatMessage({
            description: 'Toast message displayed when a folder is updated successfully.',
            defaultMessage: 'Folder updated',
          }, {
            nodeType: nodeAndUpload.node.type,
          }));

          if (!mounted.current) {
            return;
          }

          setUpdatingNode(false);
          onReloadRequired();
        }, 50);
      })
      .catch(error => {
        onFail && onFail(error);
        addErrorToast(intl.formatMessage({
          description: 'Toast message displayed when a folder cannot be updated.',
          defaultMessage: 'Folder could not be updated',
        }, {
          nodeType: nodeAndUpload.node.type,
        }));

        if (!mounted.current) {
          return;
        }

        setUpdatingNode(false);
      })
  }, [
    addErrorToast,
    addSuccessToast,
    api,
    intl,
    mounted,
    nodeAndUpload.node.id,
    nodeAndUpload.node.type,
    onReloadRequired
  ]);

  const updateFile = useCallback((data: UpdateNodeRequestData, onSuccess?: (node: Node) => void, onFail?: (error: unknown) => void) => {
    api.post<{ file: FileNode }>(
      `/files/files/${ nodeAndUpload.node.id }`,
      data,
    )
      .then(response => {
        setTimeout(() => {
          onSuccess && onSuccess(response.data.file);
          addSuccessToast(intl.formatMessage({
            description: 'Toast message displayed when a file is updated successfully.',
            defaultMessage: 'File updated',
          }, {
            nodeType: nodeAndUpload.node.type,
          }));

          if (!mounted.current) {
            return;
          }

          onReloadRequired();
          setUpdatingNode(false);
        }, 50);
      })
      .catch(error => {
        onFail && onFail(error);
        addErrorToast(intl.formatMessage({
          description: 'Toast message displayed when a file cannot be updated.',
          defaultMessage: 'File could not be updated',
        }, {
          nodeType: nodeAndUpload.node.type,
        }));

        if (!mounted.current) {
          return;
        }

        setUpdatingNode(false);
      })
  }, [
    addErrorToast,
    addSuccessToast,
    api,
    intl,
    mounted,
    nodeAndUpload.node.id,
    nodeAndUpload.node.type,
    onReloadRequired
  ]);

  const updateNode = useCallback((
    data: UpdateNodeRequestData,
    onSuccess?: (node: Node) => void,
    onFail?: (error: unknown) => void,
  ) => {
    setUpdatingNode(true);
    nodeAndUpload.node.type === 'folder'
      ? updateFolder(data, onSuccess, onFail)
      : updateFile(data, onSuccess, onFail);
  }, [nodeAndUpload.node.type, updateFile, updateFolder]);

  const fetchAudienceAndUpdateNode = useCallback(
    (
      data: Omit<UpdateNodeRequestData, 'viewAudience'>,
      onSuccess?: (node: Node) => void,
      onFail?: (error: unknown) => void,
    ) => {
      setUpdatingNodeWithAudience(true);
      api.get<Audience>(`/audiences/${ nodeAndUpload.node.viewAudience.id }`)
        .then(response => (
          updateNode(
            {
              ...data,
              viewAudience: response.data,
            },
            onSuccess,
            onFail,
          )
        ))
        .catch(error => {
          onFail && onFail(error);
          if (!mounted.current) {
            return;
          }

          setUpdatingNodeWithAudience(false);
        })
    },
    [api, mounted, nodeAndUpload.node.viewAudience.id, updateNode],
  );

  const whenRenameClicked = useCallback((name: string) => {
    setRenameDialogState(PopOverState.WILL_CLOSE);

    fetchAudienceAndUpdateNode({
      name,
      parentFolderId: nodeAndUpload.node.parentFolderId,
      ...(nodeAndUpload.node.type === 'file' ? { uploadId: `${ nodeAndUpload.upload.id }` } : {}),
    });
  }, [
    fetchAudienceAndUpdateNode,
    nodeAndUpload.node.parentFolderId,
    nodeAndUpload.node.type,
    nodeAndUpload.upload?.id
  ]);

  const whenSaveAudienceClicked = useCallback((viewAudience: Audience) => {
    setFilePermissionsModalState(PopOverState.WILL_CLOSE);

    updateNode({
      viewAudience,
      name: nodeAndUpload.node.name,
      parentFolderId: nodeAndUpload.node.parentFolderId,
      ...(nodeAndUpload.node.type === 'file' ? { uploadId: `${ nodeAndUpload.upload.id }` } : {}),
    });
  }, [
    nodeAndUpload.node.name,
    nodeAndUpload.node.parentFolderId,
    nodeAndUpload.node.type,
    nodeAndUpload.upload?.id,
    updateNode
  ]);

  const replaceFile = useCallback(() => {
    setUpdatingNodeWithAudience(true);

    openFileSelect(
      event => {
        if (!event.currentTarget.files?.length) {
          setUpdatingNodeWithAudience(false);
          return;
        }

        const newFileRef = UniqueIdGenerator.generate();
        startGlobalUpload(
          newFileRef,
          '/files/authorise-upload',
          (event.currentTarget.files || [])[0],
          (upload: Upload, onCallbackComplete, onCallbackFailed) => (
            fetchAudienceAndUpdateNode({
              uploadId: `${ upload.id }`,
              name: upload.name,
              parentFolderId: nodeAndUpload.node.parentFolderId,
            }, onCallbackComplete, onCallbackFailed)
          ),
          {
            type: 'files',
            parentFolderId: nodeAndUpload.node.parentFolderId,
          },
        );
      },
      () => setUpdatingNodeWithAudience(false),
    )
  }, [fetchAudienceAndUpdateNode, nodeAndUpload.node.parentFolderId, openFileSelect, startGlobalUpload]);

  const actions = useNodeActions(
    nodeAndUpload,
    deleteAction,
    downloadFile,
    () => setFilePermissionsModalState(PopOverState.OPEN),
    () => setRenameDialogState(PopOverState.OPEN),
    replaceFile,
    updatingNode || updatingNodeWithAudience || deleteNodeState === RequestState.FETCHING,
  );

  return (
    <StyledActiveRow
      active={ active }
      onMouseEnter={ () => setActive(true) }
      onMouseLeave={ () => setActive(false) }
      data-testid={ `node-${ nodeAndUpload.node.id }` }
    >
      <CenteredCell>{ icon }</CenteredCell>
      <TableCell>
        {
          nodeAndUpload.node.type === 'file'
            ? (
              <a
                href={ `/api/uploads/${ nodeAndUpload.upload.id }/download` }
                target="_blank"
                rel="noreferrer"
              >
                <StyledItemName $isMobile={ lessThan.xs }>{ nodeAndUpload.node.name }</StyledItemName>
              </a>
            )
            : (
              <Link to={ `/files/${ nodeAndUpload.node.id }` }>
                <StyledItemName $isMobile={ lessThan.xs }>{ nodeAndUpload.node.name }</StyledItemName>
              </Link>
            )
        }
        { lessThan.xs && <div>{ date }</div> }
      </TableCell>
      { !lessThan.xs && <TableCell>{ date }</TableCell> }
      { !lessThan.sm && <TableCell>{ size }</TableCell> }
      { lessThan.sm
        ? <TableCell>
          <TableRowContextMenu
            id={ nodeAndUpload.node.id }
            actions={ actions }
            inlineActionCount={ 0 }
          />
        </TableCell>
        : <StyledActionCell>
          { active &&
            <TableRowContextMenu
              id={ nodeAndUpload.node.id }
              actions={ actions }
              inlineActionCount={ 2 }
            />
          }
        </StyledActionCell>
      }
      { filePermissionsModalState !== PopOverState.CLOSED && (
        <NodePermissionsDialog
          dialogProps={ {
            open: filePermissionsModalState === PopOverState.OPEN,
            onClose: () => setFilePermissionsModalState(PopOverState.WILL_CLOSE),
            TransitionProps: {
              onExited: () => setFilePermissionsModalState(PopOverState.CLOSED),
            },
          } }
          node={ nodeAndUpload.node }
          containingNode={ containingNode }
          onSaveClicked={ whenSaveAudienceClicked }
        />
      ) }
      { renameDialogState !== PopOverState.CLOSED && (
        <RenamePrompt
          busy={ updatingNode }
          initialValue={ nodeAndUpload.node.name }
          open={ renameDialogState === PopOverState.OPEN }
          onConfirm={ whenRenameClicked }
          onCancel={ () => setRenameDialogState(PopOverState.WILL_CLOSE) }
          TransitionProps={ {
            onExited: () => setRenameDialogState(PopOverState.CLOSED),
          } }
        />
      ) }
      { deleteConfirmationModal }
    </StyledActiveRow>
  );
};

const getDate = (isTablet: boolean, ISODate: Date, locale: string, user: ReactNode): ReactNode => {
  if (isTablet) {
    return DateTimeFormatter.short(ISODate, locale);
  }

  const longDate: string = DateTimeFormatter.internationalised(ISODate, locale);

  return (
    <>
      { longDate } by { user }
    </>
  );
};
