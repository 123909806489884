import { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';

import { FileUploaderContext, UploadCompleteCallback, UploadState } from '../Context';
import { MapHelper } from '../Utility';
import { AuthoriseData, useAuthoriseAndUpload } from '../Hook';
import { Upload } from '../../Types';
import { RequestState } from '../../Models';

export const FileUploaderProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [uploadStates, setUploadStates] = useState<Map<string, UploadState>>(new Map());
  const [abortFunctions, setAbortFunctions] = useState<Map<string, () => void>>(new Map());
  const authoriseAndUpload = useAuthoriseAndUpload();
  const [callbackStates, setCallbackStates] = useState<Map<string, RequestState>>(new Map());

  const getCallbackState = useCallback((ref: string) => (
    callbackStates.get(ref)
  ), [callbackStates]);

  const addCompletedUpload = useCallback((ref: string, upload: Upload) => (
    setUploadStates(uploadStates => {
      const mapClone = new Map(uploadStates);
      mapClone.set(ref, {
        ref,
        upload,
        file: {
          name: upload.name,
          size: upload.size,
          width: upload.image?.width,
          height: upload.image?.height,
        },
        progress: 1,
      });
      return mapClone;
    })
  ), []);

  const upload = useCallback((
    ref: string,
    authoriseEndpoint: string,
    file: File,
    globalUpload: boolean = false,
    onComplete?: UploadCompleteCallback,
    metadata?: Record<string, unknown>,
    authoriseData?: AuthoriseData,
  ) => {
    const abort = authoriseAndUpload(
      authoriseEndpoint,
      file,
      upload => {
        setUploadStates(uploadStates => MapHelper.set(uploadStates, ref, {
          ref,
          file: {
            ...file,
            width: upload.image?.width,
            height: upload.image?.height,
          },
          upload,
          progress: 1,
          metadata,
          globalUpload,
        }));
        onComplete && onComplete(
          upload,
          () => setCallbackStates(callbackStates => new Map(
            callbackStates.set(ref, RequestState.COMPLETE),
          )),
          () => setCallbackStates(callbackStates => new Map(
            callbackStates.set(ref, RequestState.FAILED),
          )),
        );
      },
      error => setUploadStates(uploadStates => MapHelper.set(uploadStates, ref, {
        ref,
        file,
        error,
        progress: 0,
        metadata,
        globalUpload,
      })),
      progress => setUploadStates(uploadStates => MapHelper.set(uploadStates, ref, {
        ref,
        file,
        progress,
        metadata,
        globalUpload,
      })),
      authoriseData,
    );
    setUploadStates(uploadStates => MapHelper.set(uploadStates, ref, {
      ref,
      file,
      progress: 0,
      metadata,
      globalUpload,
    }));
    onComplete && setCallbackStates(callbackStates => new Map(callbackStates.set(ref, RequestState.FETCHING)));
    setAbortFunctions(abortFunctions => MapHelper.set(abortFunctions, ref, abort));
    return abort;
  }, [authoriseAndUpload]);

  const startLocalUpload = useCallback((
    ref: string,
    authoriseEndpoint: string,
    file: File,
    authoriseData?: AuthoriseData,
  ) => upload(ref, authoriseEndpoint, file, undefined, undefined, undefined, authoriseData), [upload]);

  const startGlobalUpload = useCallback((
    ref: string,
    authoriseEndpoint: string,
    file: File,
    onComplete?: UploadCompleteCallback,
    metadata?: Record<string, unknown>,
  ) => {
    setCallbackStates(callbackStates => new Map(callbackStates.set(ref, RequestState.PENDING)));
    return upload(ref, authoriseEndpoint, file, true, onComplete, metadata);
  }, [upload]);

  const remove = useCallback((ref: string) => {
    const abort = abortFunctions.get(ref);
    abort && abort();
    setAbortFunctions(abortFunctions => MapHelper.delete(abortFunctions, ref));
    setUploadStates(uploadStates => MapHelper.delete(uploadStates, ref));
  }, [abortFunctions]);

  return (
    <FileUploaderContext.Provider
      value={
        useMemo(
          () => ({
            upload: startLocalUpload,
            startGlobalUpload,
            uploadStates,
            remove,
            addCompletedUpload,
            getCallbackState,
          }),
          [addCompletedUpload, getCallbackState, remove, startGlobalUpload, startLocalUpload, uploadStates],
        )
      }
    >
      { children }
    </FileUploaderContext.Provider>
  );
};

