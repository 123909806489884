import { FC } from 'react';
import { useIntl } from 'react-intl';

import { AppNavigationSettingsTab, BrandingSettingsCategoryForm, TabbedSettings } from '../../Component';
import { SystemPermission } from '../../../System/Model';
import { useEnvironmentSettings } from '../../../Common/Hook';

export const GeneralSettingsPage: FC = () => {
  const intl = useIntl();
  const {
    formsEnabled,
    filesEnabled,
    streamChatEnabled,
    eventsEnabled,
    broadcastsV2Enabled,
  } = useEnvironmentSettings();
  const configurableNavigationItemAvailable = [
    formsEnabled,
    filesEnabled,
    streamChatEnabled,
    eventsEnabled,
  ].find(Boolean);
  const configurableNavigationAvailable = configurableNavigationItemAvailable && !broadcastsV2Enabled;

  return (
    <TabbedSettings
      path="/settings/general"
      updatePermission={ SystemPermission.SETTINGS_UPDATE }
      {
        ...configurableNavigationAvailable
          ? {
            additionalTabs: [
              {
                value: 'appNavigation',
                label: intl.formatMessage({
                  description: 'Label for app navigation settings tab.',
                  defaultMessage: 'App Navigation'
                }),
                content: <AppNavigationSettingsTab/>,
              },
            ],
          }
          : {}

      }
      customRendering={ {
        branding: BrandingSettingsCategoryForm,
      } }
    />
  );
};
