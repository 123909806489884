import styled from '@emotion/styled';
import { Checkbox, LinearProgress, Switch, } from '@mui/material';

import ErrorIcon from '../../Assets/img/icons/circle/error.svg';
import SuccessIcon from '../../Assets/img/icons/circle/success.svg';
import { css } from '../../Styling/css';

export interface StyledDropzoneProps {
  isDragActive?: boolean;
}

const BaseDropzone = css`
  text-align: center;
  padding: 3rem;
  border: 3px dashed ${props => props.theme.palette.grey[300]};
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  img,
  svg {
    height: 5rem;
    width: 5rem;
    margin-bottom: 1rem;
  }

  &:focus {
    outline: none;
  }

  button {
    margin-top: 2rem;
    font-size: 1rem;
  }
`;

export const StyledSuccessIcon = styled(SuccessIcon)`
  color: ${ props => props.theme.palette.success.main };
`;

export const StyledErrorIcon = styled(ErrorIcon)`
  color: ${ props => props.theme.palette.error.main };
`;

export const StyledDropzone = styled.div<StyledDropzoneProps>`
  ${BaseDropzone}

  ${props => props.isDragActive && `
      border-color: ${props.theme.palette.grey[400]};
  `}

  button {
    margin-top: 2rem;
    font-size: 1.2rem;
  }
`;

export const StyledUploadingDropzone = styled.div`
  ${BaseDropzone}
`;

export const StyledLinearProgress = styled(LinearProgress)`
  width: 60%;
  height: 10px;
  margin-top: 2rem;
  border-radius: 5px;
`;

type StyledCharacterCountProps = {
  error?: boolean;
};

export const StyledCharacterCount = styled.span<StyledCharacterCountProps>`
  background-color: ${props => (
    props.error
    ? props.theme.palette.error.main
    : props.theme.palette.info.main
  )};
  color: ${props => props.theme.palette.getContrastText(
    props.error
    ? props.theme.palette.error.main
    : props.theme.palette.info.main
  )};

  border-radius: 5px;
  padding: 0.2rem;
  height: 1.4rem;
  font-size: 1rem;
  line-height: 1rem;
  vertical-align: center;
  text-align: center;
  min-width: 4ch;
  display: inline-block;
`;

export const StyledSwitch = styled(Switch)`
  width: 2rem;
  height: 1.25rem;
  padding: 0;
  display: flex;
  margin: 0.25rem;

  .MuiSwitch-switchBase {
    padding: 0.25rem;
    color: ${props => props.theme.palette.grey[500]};

    &.Mui-checked {
      transform: translateX(0.825rem);
      color: ${props => props.theme.palette.common.white};

      & + .MuiSwitch-track {
        opacity: 1;
        background-color: ${props => props.theme.palette.primary.main};
        border-color: ${props => props.theme.palette.primary.main};
      }
    }
  }

  .MuiSwitch-thumb {
    width: 0.75rem;
    height: 0.75rem;
    box-shadow: none;
  }

  .MuiSwitch-track {
    border: 1px solid  ${props => props.theme.palette.grey[500]};
    border-radius: 0.625rem;
    opacity: 1;
    background-color: ${props => props.theme.palette.common.white};
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  &.Mui-checked,
  &.MuiCheckbox-indeterminate,
  &.Mui-focusVisible {
    color: ${props => props.theme.palette.primary.main};
  }

  &.MuiCheckbox-root:not(.Mui-checked):not(.MuiCheckbox-indeterminate):not(.Mui-focusVisible) {
    color: ${props => props.theme.palette.grey[400]};
  }
`;
