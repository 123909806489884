import { IntlShape } from 'react-intl';

import { Condition, TagCondition } from '../../../../Audiences/Model';
import { AudienceConditionDefinition, ConditionRestriction } from '../../Model/AudienceConditionDefinition';
import { TagType } from '../../../../Tags/Model';
import { TeamList } from '../../Component/TeamCondition/TeamList';
import { TeamConditionComponent } from '../../Component/TeamCondition/TeamConditionComponent';

export class TeamConditionDefinition implements AudienceConditionDefinition<TagCondition<TagType.Team>> {
  public readonly TYPE = 'tag';
  public readonly restriction = ConditionRestriction.SOME;
  public readonly name;

  constructor(intl: IntlShape) {
    this.name = intl.formatMessage({
      description: 'Name for team condition, used in audience builder.',
      defaultMessage: 'Team',
    });
  }
  public definesCondition(condition: Condition): condition is TagCondition<TagType.Team> {
    return condition.type === this.TYPE && (condition as TagCondition).tagType === TagType.Team;
  }

  isValid(condition: TagCondition<TagType.Team>): boolean {
    return !!condition.tagIds.length;
  }

  public Component = TeamConditionComponent;

  public build = {
    Component: TeamList,
    initialise: () => ({
      type: 'tag' as const,
      tagType: TagType.Team,
      tagIds: [],
      not: false,
      new: true,
    }) as TagCondition<TagType.Team>,
  };
}
