import { keyframes } from '@emotion/react';

export const Wiggle = keyframes`
  0% {
    transform: rotate(0);
  }

  25% {
    transform: rotate(-2deg);
  }

  75% {
    transform: rotate(2deg);
  }

  100% {
    transform: translateX(0);
  }
`;
