import { default as React, useCallback } from 'react';
import { TextField } from '@mui/material';

import { OptionEditorComponentProps } from '..';
import { DraftChoiceOption } from '../../../Service';
import { useInputChangeEventHandler } from '../../../../Common/Hook';
import { FieldValidationErrors, IconButton, VerticallySpaced } from '../../../../Common/Component';
import { CharacterCount } from '../../../../Content/Component';
import DeleteIcon from '../../../../Assets/img/icons/streamline/bin-1.svg';
import { ValidationTree } from '../../../../Common/Model';
import { StyledChoiceOptionEditor } from './style';

type Props<O extends DraftChoiceOption> = OptionEditorComponentProps<O> & {
  localisedTextFieldLabel: string;
  localisedDeleteButtonLabel: string;
  validateText: (text: string) => ValidationTree<string>;
  minLength: number;
  maxLength: number;
};

export const ChoiceOptionEditor = <O extends DraftChoiceOption>({
  option,
  validation,
  onChange,
  onValidationChange,
  removeDisabled,
  onRemoveClicked,
  localisedTextFieldLabel,
  localisedDeleteButtonLabel,
  validateText,
  minLength,
  maxLength,
}: Props<O>): JSX.Element => {
  const whenTextChanged = useInputChangeEventHandler(useCallback((text: string) => (
    onChange({
      ...option,
      text,
    })
  ), [onChange, option]));

  const whenTextBlurred = useCallback(() => (
    onValidationChange({
      errors: validation?.errors || [],
      children: {
        ...validation?.children,
        text: validateText(option.text),
      } as ValidationTree<O>['children'],
    })
  ), [onValidationChange, option.text, validateText, validation]);

  return (
    <StyledChoiceOptionEditor>
      <VerticallySpaced gap={ 1 }>
        <TextField
          fullWidth
          value={ option.text }
          onChange={ whenTextChanged }
          label={ localisedTextFieldLabel }
          onBlur={ whenTextBlurred }
        />
        <CharacterCount
          current={ option.text.length }
          minimum={ minLength }
          maximum={ maxLength }
        />
        <FieldValidationErrors
          fieldName={ localisedTextFieldLabel }
          validationErrors={ validation?.children.text?.errors || [] }
        />
      </VerticallySpaced>
      <IconButton
        IconComponent={ DeleteIcon }
        disabled={ removeDisabled }
        label={ localisedDeleteButtonLabel }
        onClick={ onRemoveClicked }
        size="small"
      />
    </StyledChoiceOptionEditor>
  );
}
