import { DeprecatedValidationTree, ValidationError, ValidationTree } from '../../Common/Model';
import { DraftBroadcast } from './DraftBroadcast';
import { DraftContent } from '../../Content/Model';

export class LegacyValidationTransformer {
  public static transform = (
    legacyValidation: DeprecatedValidationTree<DraftBroadcast>,
  ): ValidationTree<DraftBroadcast> => {
    const children = legacyValidation.children.content?.children || [];
    return {
      errors: [],
      children: {
        content: {
          errors: [],
          children: Object.entries(children).map(child => {
            if (child[1] === undefined) {
              return {
                errors: [],
                children: {},
              };
            }

            const children = Object.entries(child[1].children || {})
              .reduce<ValidationTree<DraftContent>['children']>(
                (validationChildren, entry) => (
                  entry[0] === 'default'
                    ? validationChildren
                    : {
                      ...validationChildren,
                      [entry[0]]: {
                        errors: transformLegacyErrors(entry[1].errors || []),
                        children: {},
                      },
                    }
                  ),
                {},
              );

            return {
              errors: [],
              children,
            };
          }),
        },
        summarySms: {
          errors: transformLegacyErrors(
            ((legacyValidation.children.content?.children?.[0]?.children as {default: {errors: string[]}})?.['default']?.errors || [])
              .filter(legacyError => legacyError.includes('SmsMessage'))
          ),
          children: {},
        }
      },
    };
  };
}

class LegacyErrorTransformer {
  constructor(
    private readonly includesStrings: string[],
    public readonly validationError: ValidationError,
  ) {}

  public transformsError = (legacyError: string) => (
    !!this.includesStrings.find(includesString => legacyError.includes(includesString))
  );
}

const transformLegacyErrors = (legacyErrors: string[]): ValidationError[] => {
  const errorTransformers = [
    new LegacyErrorTransformer(
      ['must not be blank', 'to be a non-blank string', 'value should not be empty', 'to be included', 'to be FileUpload entity'],
      {
        type: 'blank',
        metadata: null,
      },
    ),
    new LegacyErrorTransformer(
      ['must be formatted correctly'],
      {
        type: 'notUrl',
        metadata: null
      },
    )
  ];

  return legacyErrors.reduce<ValidationError[]>((validationErrors, legacyError) => {
    const errorTransformer = errorTransformers.find(errorTransformer => errorTransformer.transformsError(legacyError));
    const error = errorTransformer?.validationError || {
      type: 'unknown',
      metadata: null,
    };

    if (!validationErrors.find(validationError => validationError.type === error.type)) {
      return validationErrors.concat(error);
    }

    return validationErrors;
  }, []);
}
