import styled from '@emotion/styled';
import { rgba } from 'polished';

export const StyledButton = styled.button<{ active: boolean }>`
  border: none;
  background-color: transparent;
  color: inherit;
  transition: background-color 0.2s, color 0.2s;
  border-radius: ${ props => props.theme.borderRadius.xs };

  ${ props => props.active && `
    background-color: ${ rgba(props.theme.palette.secondary.main, 0.1) };
    color: ${ props.theme.palette.secondary.main };
  ` }

  ${ props => props.disabled && `
    color: ${ props.theme.palette.grey[300] };
  ` }
`;
