import styled from '@emotion/styled';

export const StyledUploadRequestButtonContainer = styled.div`
  text-align: center;
  left: 0;
  bottom: 0;
  position: absolute;
  width: 100%;
`;

export const UploadContainer = styled.div`
  font-size: 0.75em;
  display: grid;
  grid-template-columns: auto auto 1fr auto;
  grid-column-gap: ${props => props.theme.spacing(1)};
  align-items: center;
  margin: 0 0 ${props => props.theme.spacing(2)};
`;

export const UploadedImageContainer = styled.div`
  height: 4em;
  width: 4em;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 100%;
    width: 100%;
    max-width: initial;
    overflow: hidden;
    object-fit: cover;
  }
`;

export const StyledSuccessIcon = styled.div`
  color: ${ props => props.theme.palette.secondary.main };
`;
