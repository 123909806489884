import { FC, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { AutocompleteSelection } from '@ourpeople/shared/Core/Component/Input/Autocomplete/Autocomplete';

import { BroadcastsPermission, MinimalBroadcastCategory } from '../../../Model';
import { CategoryAutocomplete } from '../CategoriesAutocomplete/CategoryAutocomplete';
import { useMounted } from '../../../../Common/Hook';
import { useApi, useContextOrThrow } from '../../../../Core/Hook';
import { StyledCategoryName } from './style';
import { usePermissions } from '../../../../Security/Hook';
import { ToastContext } from '../../../../Core/Context';

type Props = {
  containerActive: boolean;
  broadcastId: string;
  selectedCategory: MinimalBroadcastCategory;
  onSelectedCategoryChange: (category: MinimalBroadcastCategory) => void;
  onClose?: () => void;
  userCreatedBroadcast?: boolean;
};

export const CategoryInput: FC<Props> = ({
  containerActive,
  broadcastId,
  selectedCategory,
  onSelectedCategoryChange,
  onClose,
  userCreatedBroadcast = false,
}) => {
  const selectedCategories = useMemo(() => [selectedCategory], [selectedCategory]);
  const { permissionAvailable, guardedCallback } = usePermissions();
  const api = useApi();
  const intl = useIntl();
  const mounted = useMounted();
  const [error, setError] = useState<boolean>(false);
  const { addSuccessToast, addErrorToast } = useContextOrThrow(ToastContext);
  const [active, setActive] = useState<boolean>(false);
  const userCanAssignCategory = useMemo(() => (
    permissionAvailable(BroadcastsPermission.CATEGORIES_ASSIGN_ALL)
    || (permissionAvailable(BroadcastsPermission.CATEGORIES_ASSIGN_OWN) && userCreatedBroadcast)
  ), [permissionAvailable, userCreatedBroadcast]);

  const whenSelectionChanged = useCallback((selection: AutocompleteSelection<MinimalBroadcastCategory>) => {
    if (!selection.options.length) {
      return;
    }

    const previousCategory = selectedCategory;
    const newCategory = selection.options[0];

    if (newCategory.id === previousCategory.id) {
      return;
    }

    onSelectedCategoryChange(newCategory);
    setError(false);
    setActive(false);
    onClose && onClose();
    api.post(`/broadcasts/${ broadcastId }/category`, { categoryId: newCategory.id })
      .then(() => {
        if (!mounted.current) {
          return;
        }

        addSuccessToast(
          intl.formatMessage({
            description: 'Success message when broadcast category updated.',
            defaultMessage: 'Category updated.'
          })
        );
      })
      .catch(() => {
        if (!mounted.current) {
          return;
        }

        addErrorToast(
          intl.formatMessage({
            description: 'Failure message when broadcast category updated.',
            defaultMessage: 'Could not update category.'
          })
        );
        onSelectedCategoryChange(previousCategory);
        setError(true);
      });
  }, [
    addErrorToast,
    addSuccessToast,
    api,
    broadcastId,
    intl,
    mounted,
    onClose,
    onSelectedCategoryChange,
    selectedCategory
  ]);

  const whenBlurred = useCallback(() => {
    setActive(false);
    onClose && onClose();
  }, [onClose]);

  return (
    (containerActive || active) && userCanAssignCategory
      ? (
        <CategoryAutocomplete
          selectedIds={ [selectedCategory.id] }
          disableClearable={ true }
          onSelectionChanged={ selection => guardedCallback(
            () => whenSelectionChanged(selection),
            userCreatedBroadcast
              ? [BroadcastsPermission.CATEGORIES_ASSIGN_OWN]
              : [BroadcastsPermission.CATEGORIES_ASSIGN_ALL],
          )() }
          initialSelection={ selectedCategories }
          onBlur={ whenBlurred }
          onFocus={ () => setActive(true) }
          error={ error }
          fullWidth
        />
      )
      : (
        <StyledCategoryName
          error={ error }
        >
          { selectedCategory.name }
        </StyledCategoryName>
      )
  );
};
