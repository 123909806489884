import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { parseISO } from 'date-fns';
import { useIntl } from 'react-intl';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import * as Sentry from '@sentry/react';
import { ApiRequest } from '@ourpeople/shared/Core/Model/ApiRequest';

import { useBroadcastGoogleEvents } from '../../Hook';
import { AudienceCleaner } from '../../../Audiences/Utility';
import { ContentTransformer } from '../../Utility';
import {
  CalculatedRecipients,
  DeprecatedBroadcast,
  DraftBroadcast,
  FetchCalculatedRecipientsData,
  SchedulingValue
} from '../../Model';
import { LegacyValidationTransformer } from '../../Model/LegacyValidationTransformer';
import { EditorContent } from '../../../Content/Model';
import { EditBroadcastSteps } from '../EditBroadcastSteps/EditBroadcastSteps';
import { useApi, useContextOrThrow } from '../../../Core/Hook';
import {
  DeprecatedLegacyValidationErrorResponse,
  DeprecatedValidationTree,
  ErrorResponse,
  ValidationTree
} from '../../../Common/Model';
import { useDescendantErrors, useMounted, useUserRoles } from '../../../Common/Hook';
import {
  BroadcastPublishedConfirmationDialog
} from '../../../Sections/Broadcasts/Create/BroadcastPublishedConfirmationDialog/BroadcastPublishedConfirmationDialog';
import { ShareDraftDialog } from '../ShareDraftDialog/ShareDraftDialog';
import { Audience } from '../../../Audiences/Model';
import { ToastContext } from '../../../Core/Context';
import { FetchResponse } from '../../../Hooks';
import { FetchResult, RequestState } from '../../../Models';
import { useUneditableBroadcastMessage } from '../../Hook/useUneditableBroadcastMessage';
import { BroadcastErrorResponseReader } from '../../Utility/BroadcastErrorResponseReader';
import { Schedule } from '../../Model/BroadcastSchedule';
import { ScheduleTransformer } from '../../Utility/ScheduleTransformer';
import { BroadcastScheduleIdentifier } from '../../Utility/BroadcastScheduleIdentifier';
import { ErrorResponseReader } from '../../../Common/Utility';

type Props = {
  broadcast: DraftBroadcast;
  editorContents: EditorContent[];
  setBroadcast: Dispatch<SetStateAction<DraftBroadcast>>;
  setEditorContents: Dispatch<SetStateAction<EditorContent[]>>;
  saveEndpoint: string;
  onPublished: () => void;
  onSaveComplete?: (broadcast: DeprecatedBroadcast) => void;
  onPostSaveError?: (error: unknown, broadcastId: string) => void;
  schedule: Schedule;
  setSchedule: Dispatch<SetStateAction<Schedule>>;
};

export type FetchCalculatedRecipientsResponse = [
  FetchResponse<CalculatedRecipients>[0],
  FetchResponse<CalculatedRecipients>[1],
  (force: boolean) => void,
];

export const BroadcastEditForm: FC<Props> = ({
  broadcast,
  editorContents,
  setBroadcast,
  setEditorContents,
  saveEndpoint,
  onPublished,
  onSaveComplete,
  onPostSaveError,
  schedule,
  setSchedule,
}) => {
  const uneditableBroadcastMessage = useUneditableBroadcastMessage();
  const [saving, setSaving] = useState(false);
  const [publishing, setPublishing] = useState(false);
  const [sendPreviewRequest, setSendPreviewRequest] = useState<ApiRequest<void>>({
    state: RequestState.PENDING,
    result: null,
  });
  const [editingAudience, setEditingAudience] = useState<boolean>(false);
  const broadcastForSubmission = useMemo<Omit<DraftBroadcast, 'tags'>>(() => {
    const broadcastWithContent = {
      ...broadcast,
      recipientAudience: AudienceCleaner.removeInvalidConditions(broadcast.recipientAudience),
      content: editorContents.map(editorContent => ContentTransformer.fromCard(editorContent.card)),
      ...(BroadcastScheduleIdentifier.scheduleIsOneTime(schedule) ? { scheduleFor: schedule.localStartDate ? schedule.localStartDate.toISOString() : undefined } : { recurrenceRule: ScheduleTransformer.toRecurrenceRule(schedule) }),
    };

    const { tags, ...broadcastWithoutTags } = broadcastWithContent;
    return broadcastWithoutTags;
  }, [broadcast, editorContents, schedule]);
  const [published, setPublished] = useState(false);
  const { trackPublish, trackSave } = useBroadcastGoogleEvents();
  const intl = useIntl();
  const api = useApi();
  const history = useHistory();
  const [validation, setValidation] = useState<ValidationTree<DraftBroadcast>>({
    errors: [],
    children: {},
  });
  const [shareDraftDialogOpen, setShareDraftDialogOpen] = useState<boolean>(false);
  const [sharing, setSharing] = useState<boolean>(false);
  const hasErrors = !!useDescendantErrors(validation).length;
  const { addSuccessToast, addErrorToast } = useContextOrThrow(ToastContext);
  const schedulingValue = useMemo<SchedulingValue>(
    () => ({
      when: broadcastForSubmission?.recurrenceRule ? 'daily' : (broadcastForSubmission?.scheduleFor ? 'later' : 'now'),
      scheduleFor: broadcastForSubmission?.scheduleFor ? parseISO(broadcastForSubmission.scheduleFor) : null,
      recurrenceRule: broadcastForSubmission?.recurrenceRule || null,
    }),
    [broadcastForSubmission],
  );
  const { path } = useRouteMatch();
  const { userIsSuperAdmin } = useUserRoles();
  const alwaysEditAudience = path === '/broadcasts/create' || userIsSuperAdmin;
  const [savedAudience, setSavedAudience] = useState<Audience>(broadcast.recipientAudience);
  const [audienceModified, setAudienceModified] = useState<boolean>(false);
  const lastFetchCalculatedRecipientsData = useRef<FetchCalculatedRecipientsData>();
  const fetchCalculatedRecipientsData = useMemo<FetchCalculatedRecipientsData | undefined>(() => ({
    notification: {
      push: {
        send: false,
      },
      email: {
        send: false,
      },
      sms: {
        send: false,
      },
    },
    audience: AudienceCleaner.removeInvalidConditions(broadcast.recipientAudience),
    applyReach: editingAudience || audienceModified || alwaysEditAudience,
  }), [broadcast.notification, broadcast.recipientAudience, editingAudience, audienceModified, alwaysEditAudience]);
  const mounted = useMounted();

  const fetchCalculatedRecipients = useCallback(() => {
    if (!fetchCalculatedRecipientsData) {
      return;
    }

    const filtersUnchanged = lastFetchCalculatedRecipientsData.current
      && JSON.stringify(lastFetchCalculatedRecipientsData.current.audience) === JSON.stringify(fetchCalculatedRecipientsData.audience)
      && lastFetchCalculatedRecipientsData.current.notification.sms.send === fetchCalculatedRecipientsData.notification.sms.send
      && lastFetchCalculatedRecipientsData.current.notification.email.send === fetchCalculatedRecipientsData.notification.email.send;

    if (filtersUnchanged) {
      return;
    }

    lastFetchCalculatedRecipientsData.current = fetchCalculatedRecipientsData;
    setFetchCalculatedRecipientsResponse([
      null,
      RequestState.FETCHING,
      fetchCalculatedRecipients,
    ]);

    api.post<CalculatedRecipients>('/broadcasts/calculate-recipients', fetchCalculatedRecipientsData)
      .then(response => {
        if (!mounted.current) {
          return;
        }

        setFetchCalculatedRecipientsResponse([
          FetchResult.fromContent(response.data),
          RequestState.COMPLETE,
          fetchCalculatedRecipients,
        ]);
      })
      .catch((error: AxiosError) => {
        if (!mounted.current) {
          return;
        }

        setFetchCalculatedRecipientsResponse([
          FetchResult.fromError(error),
          RequestState.FAILED,
          fetchCalculatedRecipients,
        ]);
      })
  }, [api, fetchCalculatedRecipientsData, mounted]);
  const [
    fetchCalculatedRecipientsResponse,
    setFetchCalculatedRecipientsResponse,
  ] = useState<FetchCalculatedRecipientsResponse>([
    null,
    RequestState.PENDING,
    fetchCalculatedRecipients,
  ]);

  useEffect(() => {
    fetchCalculatedRecipients();
  }, [fetchCalculatedRecipients]);

  const whenSaveSuccessful = useCallback((response: AxiosResponse<DeprecatedBroadcast>) => {
    setSaving(false);
    addSuccessToast(
      intl.formatMessage({
        id: 'broadcasts.edit.saveSuccessful',
        description: 'Toast message when saving a broadcast is successful.',
        defaultMessage: 'Broadcast saved successfully',
      })
    );

    if (JSON.stringify(broadcastForSubmission.recipientAudience) !== JSON.stringify(savedAudience) && !audienceModified) {
      setAudienceModified(true);
    }

    setSavedAudience(broadcastForSubmission.recipientAudience);
    onSaveComplete && onSaveComplete(response.data);
  }, [
    addSuccessToast,
    intl,
    broadcastForSubmission.recipientAudience,
    savedAudience,
    audienceModified,
    onSaveComplete
  ]);

  const whenShareSuccessful = useCallback((response: AxiosResponse<DeprecatedBroadcast>) => {
    setSharing(false);
    addSuccessToast(
      intl.formatMessage({
        id: 'broadcasts.edit.shareSuccessful',
        description: 'Toast message when sharing a broadcast is successful.',
        defaultMessage: 'Broadcast shared successfully',
      })
    );
    onSaveComplete && onSaveComplete(response.data);
  }, [addSuccessToast, intl, onSaveComplete]);

  const whenSaveFailed = useCallback((error: AxiosError<DeprecatedLegacyValidationErrorResponse<DraftBroadcast> | ErrorResponse>) => {
    setSaving(false);

    if (BroadcastErrorResponseReader.isNotEditableError(error)) {
      addErrorToast(uneditableBroadcastMessage);
      return;
    }

    addErrorToast(
      intl.formatMessage({
        id: 'broadcasts.edit.saveFailed',
        description: 'Toast message when saving a broadcast is unsuccessful.',
        defaultMessage: 'Broadcast could not be saved',
      })
    );

    const data = error.response?.data;

    if (data === undefined) {
      return;
    }

    Sentry.captureException(new Error('Broadcast save failed'), {
      contexts: {
        error: {
          ...(
            isDeprecatedValidationResponse(data)
              ? {
                code: data.code,
                errors: JSON.stringify(data.errors),
                message: data.message,
              }
              : {
                code: data.error.code,
                data: JSON.stringify(data.error.data),
                message: data.error.message,
                status: data.error.status,
              }
          )
        },
      }
    });

    if (isDeprecatedValidationResponse(data)) {
      setValidation(LegacyValidationTransformer.transform(data.errors));
    }
  }, [addErrorToast, intl, uneditableBroadcastMessage]);

  const whenShareFailed = useCallback((error: AxiosError) => {
    setSharing(false);
    addErrorToast(
      intl.formatMessage({
        id: 'broadcasts.edit.shareFailed',
        description: 'Toast message when sharing a broadcast is unsuccessful.',
        defaultMessage: 'Broadcast could not be shared',
      })
    );

    if (axiosErrorHasLegacyValidation(error)) {
      setValidation(LegacyValidationTransformer.transform(error.response.data));
    }
  }, [addErrorToast, intl]);

  const whenPublishSuccessful = useCallback(
    () => {
      setPublishing(false);
      setPublished(true);
      onPublished();
    },
    [onPublished],
  );

  const whenSendPreviewFailed = useCallback((error: AxiosError) => {
    setSendPreviewRequest({
      state: RequestState.FAILED,
      result: error,
    });
    addErrorToast(
      intl.formatMessage({
        id: 'broadcasts.edit.sendPreviewFailed',
        description: 'Toast message when publishing a broadcast is unsuccessful.',
        defaultMessage: 'Broadcast preview could not be sent',
      }),
    );

    if (axiosErrorHasLegacyValidation(error)) {
      setValidation(LegacyValidationTransformer.transform(error.response.data));
    }
  }, [addErrorToast, intl]);

  const whenSendPreviewSuccessful = useCallback((response: AxiosResponse<DeprecatedBroadcast>) => {
    onSaveComplete && onSaveComplete(response.data);
    setSendPreviewRequest({
      state: RequestState.COMPLETE,
      result: undefined,
    });
    addSuccessToast(
      intl.formatMessage({
        id: 'broadcasts.edit.sendPreviewSuccessful',
        description: 'Toast message when publishing a broadcast is successful.',
        defaultMessage: 'Broadcast preview sent',
      })
    );
  }, [addSuccessToast, intl, onSaveComplete]);

  const whenPublishFailed = useCallback((error: AxiosError) => {
    setPublishing(false);
    addErrorToast(
      intl.formatMessage({
        id: 'broadcasts.edit.publishFailed',
        description: 'Toast message when publishing a broadcast is unsuccessful.',
        defaultMessage: 'Broadcast could not be published',
      })
    );

    if (axiosErrorHasLegacyValidation(error)) {
      setValidation(LegacyValidationTransformer.transform(error.response.data));
    }
  }, [addErrorToast, intl]);

  const save = useCallback(
    () => {
      api.post<DeprecatedBroadcast>(saveEndpoint, broadcastForSubmission)
        .then(whenSaveSuccessful)
        .catch(whenSaveFailed)
    },
    [api, saveEndpoint, broadcastForSubmission, whenSaveSuccessful, whenSaveFailed],
  );

  const publish = useCallback(
    () => {
      api.post<DeprecatedBroadcast>(saveEndpoint, broadcastForSubmission)
        .then((response) =>
          api.post(`broadcasts/${ response.data.id }/publish`)
            .catch(error => {
              onPostSaveError && onPostSaveError(error, response.data.id)
            })
        )
        .then(whenPublishSuccessful)
        .catch(whenPublishFailed)
    },
    [api, saveEndpoint, broadcastForSubmission, whenPublishSuccessful, whenPublishFailed, onPostSaveError],
  );

  const share = useCallback(
    (personIds: string[]) => {
      setSharing(true);
      setShareDraftDialogOpen(false);
      const {
        shareAudiencePersonIds: previousShareAudiencePersonIds,
        ...broadcast
      } = broadcastForSubmission;

      api.post<DeprecatedBroadcast>(saveEndpoint, { ...broadcast, ...(personIds.length ? { shareAudiencePersonIds: personIds } : {}) })
        .then(whenShareSuccessful)
        .catch(whenShareFailed)
    },
    [api, broadcastForSubmission, saveEndpoint, whenShareFailed, whenShareSuccessful],
  );

  const sendPreview = useCallback(
    () => {
      api.post<DeprecatedBroadcast>(saveEndpoint, broadcastForSubmission)
        .then((response) => api.post(`broadcasts/${ response.data.id }/send-preview`)
          .then(() => response)
          .catch(error => {
            onPostSaveError && onPostSaveError(error, response.data.id);
            throw error;
          })
        )
        .then(response => {
          if (!response) {
            return;
          }

          whenSendPreviewSuccessful(response);
        })
        .catch(whenSendPreviewFailed)
    },
    [api, saveEndpoint, broadcastForSubmission, whenSendPreviewSuccessful, whenSendPreviewFailed, onPostSaveError],
  );

  const whenSaveButtonClicked = useCallback(() => {
    if (!broadcast) {
      return;
    }

    setSaving(true);
    save();
    trackSave();
  }, [broadcast, save, trackSave]);

  const whenPublishButtonClicked = useCallback(
    () => {
      if (!broadcast) {
        return;
      }

      setPublishing(true);
      publish();
      trackPublish();
    },
    [trackPublish, broadcast, publish],
  );

  const whenShareConfirmed = useCallback(
    (personIds: string[]) => {
      if (!broadcast) {
        return;
      }

      setBroadcast(({ shareAudiencePersonIds: previousShareAudiencePersonIds, ...broadcast }) => ({
        ...broadcast,
        ...(personIds.length ? { shareAudiencePersonIds: personIds } : {}),
      }));
      setSharing(true);
      share(personIds);
    },
    [broadcast, setBroadcast, share],
  );

  const whenSendPreviewButtonClicked = useCallback(
    () => {
      if (!broadcast) {
        return;
      }

      setSendPreviewRequest({
        state: RequestState.FETCHING,
        result: null,
      });
      sendPreview();
    },
    [broadcast, sendPreview],
  );

  const audienceSize = useMemo(
    () => {
      return fetchCalculatedRecipientsResponse[0]?.content?.counts?.total || 0;
    },
    [fetchCalculatedRecipientsResponse],
  );

  const whenPublishedModalDismissed = useCallback(
    () => {
      schedule.localStartDate || schedule.recurrence
        ? history.push('/broadcasts?tab=scheduled')
        : history.push('/broadcasts');
    },
    [schedule, history],
  );

  return (
    <>
      <EditBroadcastSteps
        broadcast={ broadcast }
        savedAudience={ savedAudience }
        audienceModified={ audienceModified }
        setBroadcast={ setBroadcast }
        editorContents={ editorContents }
        setEditorContents={ setEditorContents }
        validation={ validation }
        setValidation={ setValidation }
        saveDisabled={ hasErrors }
        publishDisabled={ hasErrors || !audienceSize }
        onSaveButtonClicked={ whenSaveButtonClicked }
        onPublishButtonClicked={ whenPublishButtonClicked }
        saving={ saving }
        sharing={ sharing }
        publishing={ publishing }
        preview={ {
          request: sendPreviewRequest,
          onClick: whenSendPreviewButtonClicked,
        } }
        onShareButtonClicked={ () => setShareDraftDialogOpen(true) }
        fetchCalculatedRecipientsResponse={ fetchCalculatedRecipientsResponse }
        schedule={ schedule }
        setSchedule={ setSchedule }
        editingAudience={ editingAudience }
        onEditingAudienceChange={ setEditingAudience }
        alwaysEditAudience={ alwaysEditAudience }
      />
      <BroadcastPublishedConfirmationDialog
        open={ published }
        audienceCount={ audienceSize }
        onDismissed={ whenPublishedModalDismissed }
        schedule={ schedulingValue }
      />
      { shareDraftDialogOpen && (
        <ShareDraftDialog
          title={ broadcast.name }
          open={ shareDraftDialogOpen }
          personIds={ broadcast.shareAudiencePersonIds || [] }
          onShare={ whenShareConfirmed }
          onCancel={ () => setShareDraftDialogOpen(false) }
        />
      ) }
    </>
  );
};

const isDeprecatedValidationResponse = (
  data: DeprecatedLegacyValidationErrorResponse<DraftBroadcast> | ErrorResponse
): data is DeprecatedLegacyValidationErrorResponse<DraftBroadcast> => (
  !(data as unknown as Record<string, unknown>)?.error
);

const axiosErrorHasLegacyValidation = (
  axiosError: AxiosError,
): axiosError is Omit<AxiosError<DeprecatedValidationTree<DraftBroadcast>>, 'response'> & { response: AxiosResponse<Required<DeprecatedValidationTree<DraftBroadcast>>> } => (
  ErrorResponseReader.isApiError(axiosError) && !!(axiosError.response.data.error.data as DeprecatedValidationTree<DraftBroadcast>)?.children
);
