import { FC, useMemo } from 'react';
import { addMonths, parseISO, startOfDay, subMonths } from 'date-fns';
import { useIntl } from 'react-intl';

import { DateOffsetProvider } from '../../../Core/Utility/DateOffsetProvider';
import {
  DateOffsetRangeInput,
  DateOffsetRangeInputProps
} from '../../../Core/Component/Input/DateOffsetRangeInput/DateOffsetRangeInput';
import { DateOffsetParser } from '../../../Core/Utility/DateOffsetParser';
import { DateTimeFormatter } from '../../../../src/react/Utility/DateTimeFormatter';
import { DateOffsetRangeHelper } from '../../../Core/Utility/DateOffsetRangeHelper';
import { useReportDateRangeInputOptions } from '../../Hook/useReportDateRangeInputOptions';

type Props = {
  value: DateOffsetRangeInputProps['value'];
  onChange: DateOffsetRangeInputProps['onChange'];
  limitRange?: boolean;
  displayEmpty?: boolean;
};

export const ReportDateRangeInput: FC<Props> = ({
  value,
  onChange,
  limitRange = false,
  displayEmpty = false,
}) => {
  const intl = useIntl();
  const options = useReportDateRangeInputOptions(displayEmpty);
  const customConfig = useMemo(() => ({
    enabled: true,
    ...(limitRange ? {
        min: DateTimeFormatter.dateTimeInput(subMonths(startOfDay(new Date()), 3)),
      } : {}),
    ...(DateOffsetRangeHelper.valueIsDateRange(value) && value.end?.type === 'fixed' ? {
        max: DateTimeFormatter.dateTimeInput(parseISO(value.end.date)),
      } : {}),
    initialValue: options[0].value || DateOffsetProvider.now(),
    inputLabel: intl.formatMessage({
      description: 'Label for custom start date input in reports pages.',
      defaultMessage: 'From date',
    }),
  }), [intl, limitRange, options, value]);

  const endDateConfig = useMemo(() => {
    const startIso = value.start && DateOffsetParser.toIsoString(value.start);

    return {
      ...(limitRange ? {
          max: DateTimeFormatter.dateTimeInput(addMonths(startOfDay(new Date()), 3)),
        } : {}),
      ...(startIso ? { min: DateTimeFormatter.dateTimeInput(parseISO(startIso)) } : {}),
      initialValue: DateOffsetProvider.now(),
      inputLabel: intl.formatMessage({
        description: 'Label for custom end date input in reports pages.',
        defaultMessage: 'Until date',
      }),
    };
  }, [intl, limitRange, value]);

  return (
    <DateOffsetRangeInput
      options={ options }
      value={ value }
      onChange={ onChange }
      customOption={ customConfig }
      endDate={ endDateConfig }
    />
  );
};
