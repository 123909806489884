import { DraftSignRequest } from '../../ContentDefinition';
import { ValidationTree, Validator } from '../../../../Common/Model';
import { Validate } from '../../../../Common/Utility';

export class SignRequestValidator {
  public static validate = (content: DraftSignRequest): ValidationTree<DraftSignRequest> => ({
    errors: [],
    children: {
      text: SignRequestValidator.validateText(content.text),
    },
  });

  public static validateText = (text: string): ValidationTree<string> => ({
    errors: textValidator.validate(text),
    children: {},
  });
}

export const SIGN_TEXT_MIN_LENGTH = 1;
export const SIGN_TEXT_MAX_LENGTH = 300;

const textValidator = new Validator<string>([
  Validate.notBlank,
  title => Validate.minLength(title, SIGN_TEXT_MIN_LENGTH),
  title => Validate.maxLength(title, SIGN_TEXT_MAX_LENGTH),
]);
