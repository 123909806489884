import { IntlShape } from 'react-intl';

import { CHOICE_REQUEST_MIN_SELECTIONS_MIN, ChoiceRequest, DraftChoiceRequest, } from '../../Model/ContentTypes';
import { ChoiceRequestFields, ChoiceRequestPreview } from '../../Component/Content';
import { ChoiceRequestValidator } from '../../Utility/Validation';
import { SingleContentCard, EditorContent } from '../../../Content/Model';
import ChoiceRequestIcon from '../../../Assets/img/icons/monochrome/content/choice.svg';
import { UniqueIdGenerator } from '../../../Common/Utility';
import { CardFactory, CardTransformer } from '../../../Content/Utility';
import { DraftResponse, FormContentDefinition, Response } from '../../Model';
import { EnvironmentSettings } from '../../../Models';
import { LiveChoiceRequest } from '../../Component/LiveContent/LiveChoiceRequest/LiveChoiceRequest';
import { SubmittedChoiceRequest } from '../../Component/SubmittedContent';

export type ChoiceRequestEditorContent = EditorContent<DraftChoiceRequest>;

export type DraftChoiceResponse = DraftResponse<{
  contentType: 'choiceRequest';
  optionIds: string[];
}>;
export type ChoiceResponse = Response<{
  contentType: 'choiceRequest';
  selections: {
    id: string;
    title: string;
  }[];
}>;

export class ChoiceRequestDefinition implements FormContentDefinition<
  ChoiceRequest,
  ChoiceRequestEditorContent,
  DraftChoiceResponse,
  ChoiceResponse
> {
  public readonly id = 'choiceRequest';
  public readonly availableToCreate = true;
  public readonly new = false;
  public readonly richText = false;
  public readonly contentTypes = ['choiceRequest'];
  public readonly EditorComponent = ChoiceRequestFields;
  public readonly PreviewComponent = ChoiceRequestPreview;
  public readonly LiveComponent = LiveChoiceRequest;
  public readonly SubmittedComponent = SubmittedChoiceRequest;
  public readonly IconComponent = ChoiceRequestIcon;
  public readonly categoryId = 'input';

  public definesContentType = (type: string): boolean => (
    this.contentTypes.includes(type)
  );

  public createEditorContent = (): ChoiceRequestEditorContent => ({
    id: UniqueIdGenerator.generate(),
    card: CardFactory.fromDraftContent({
      type: 'choiceRequest',
      title: '',
      text: null,
      minSelections: CHOICE_REQUEST_MIN_SELECTIONS_MIN,
      maxSelections: 2,
      style: 'checkbox',
      options: [
        {
          title: '',
        },
        {
          title: '',
        },
      ],
    }),
  });

  public getContentTitle = (intl: IntlShape, content: ChoiceRequest): string => (
    content.title || this.getDefaultTitle(intl)
  );

  public getDraftContentTitle = (intl: IntlShape, draftContent: DraftChoiceRequest): string => (
    draftContent.title || this.getDefaultTitle(intl)
  );

  public hasRequiredFeatureFlags = (environmentSettings: EnvironmentSettings): boolean => (
    !!environmentSettings.formsEnabled
  );

  public getLocalisedType = (intl: IntlShape): string => intl.formatMessage({
    id: 'forms.content.choiceRequest.type',
    description: 'Localised name for choice request type.',
    defaultMessage: 'Choice',
  });

  public transformCard = (card: SingleContentCard<ChoiceRequest>): ChoiceRequestEditorContent => ({
    id: card.id,
    card: CardTransformer.transformCard(card, {
      ...card.content,
      options: card.content.options,
    }),
  });

  public validate = ChoiceRequestValidator.validate;

  public cloneEditorContent = (editorContent: ChoiceRequestEditorContent): ChoiceRequestEditorContent => {
    const { id: cardId, ...card } = editorContent.card;
    const { id: contentId, ...content } = card.content;

    return ({
      ...editorContent,
      id: UniqueIdGenerator.generate(),
      card: {
        ...card,
        content: {
          ...content,
          options: editorContent.card.content.options.map(({ id, ...option }) => ({
            ...option,
          })),
        },
      },
    });
  };

  private getDefaultTitle = (intl: IntlShape): string => intl.formatMessage({
    id: 'forms.content.choiceRequest.defaultTitle',
    description: 'Default title for choice request.',
    defaultMessage: 'Untitled choice',
  });
}
