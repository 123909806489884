import styled from '@emotion/styled';

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  >*:not(:first-of-type) {
    margin-left: 1rem;
  }
`;
