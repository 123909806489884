import { PropsWithChildren, useMemo } from 'react';
import { TableSortLabel } from '@mui/material';

export interface SortableHeaderButtonProps<AscValue extends string, DescValue extends string> {
  sort: AscValue | DescValue | string;
  ascValue: AscValue;
  descValue: DescValue;
  onSort: (sort: AscValue | DescValue) => void;
}

export const SortableHeaderButton = <AscValue extends string, DescValue extends string>({
  sort,
  ascValue,
  descValue,
  onSort,
  children,
}: PropsWithChildren<SortableHeaderButtonProps<AscValue, DescValue>>): JSX.Element => {
  const sortDirection = useMemo(
    () => (sort === ascValue && 'asc') || (sort === descValue && 'desc'),
    [ascValue, descValue, sort],
  );

  return (
    <TableSortLabel
      active={ !!sortDirection }
      direction={ sortDirection || 'asc' }
      onClick={ () => onSort(sortDirection === 'asc' ? descValue : ascValue) }
    >
      { children }
    </TableSortLabel>
  )
};
