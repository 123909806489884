import { FetchResponse, useGet } from '../../Hooks';

type StreamingSource = {
  name: string;
  url: string;
};

export type UploadUrls = {
  original: string;
  thumbnail: string;
  streamable?: StreamingSource[];
};

export const useFetchUploadUrls = (
  uploadId: string,
  params?: { videoThumbnailKey: string },
): FetchResponse<UploadUrls> => useGet(`/uploads/${ uploadId }/urls`, params);
