import { FC, useContext } from 'react';
import { BreakpointContext } from 'op-storybook/lib/providers/BreakpointProvider/BreakpointProvider';

import { useEnvironmentSettings } from '../../Hook/useEnvironmentSettings';
import { useContextOrThrow } from '../../../Core/Hook/useContextOrThrow';
import { useLoggedInAuthDescription } from '../../../Core/Hook/useLoggedInAuthDescription';
import { TextFormatter } from '../../../Utility/TextFormatter/TextFormatter';
import { useSidebarConfiguration } from '../../Hook/useSidebarConfiguration';
import { Sidebar } from '../Navigation/Sidebar/Sidebar';
import { AuthContext } from '../../../Contexts';

type Props = {
  expanded: boolean;
  onExpandedChange: (expanded: boolean) => void;
  mobile?: boolean;
};

export const ConfiguredSidebar: FC<Props> = ({
  expanded,
  onExpandedChange,
  mobile = false,
}) => {
  const { lastDescribed } = useContext(AuthContext);
  const screenWidth = useContextOrThrow(BreakpointContext);
  const slim = !mobile && (!expanded || screenWidth.lessThan.md);
  const {
    smallLogoUrl = '',
    name: spaceName = '',
  } = useEnvironmentSettings();
  const { user } = useLoggedInAuthDescription();
  const userName = TextFormatter.spaceSeparated(user.forename, user.lastname || '');
  const { mainItems, footerItems } = useSidebarConfiguration();

  return (
    <Sidebar
      mobile={ mobile }
      toggleable={ !mobile && !screenWidth.lessThan.md }
      spaceName={ spaceName }
      logoUrl={ smallLogoUrl }
      slim={ slim }
      mainItems={ mainItems }
      footerItems={ footerItems }
      onToggle={ onExpandedChange }
      userName={ userName }
      userUuid={ user.uuid }
      userHasAvatar={ !!user.avatarUrl }
      accountPath="/account"
      logoutPath="/logout"
      userLastDescribed={ lastDescribed }
    />
  );
};
