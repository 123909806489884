import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { LineClamp } from 'op-storybook/stories/components/LineClamp/LineClamp';

import { PersonAvatarWithText } from '../PersonAvatarWithText/PersonAvatarWithText';
import { MinimalPerson } from '../../../Models';
import { PersonParser } from '../../../Utility';

type Props = {
  person: MinimalPerson;
  startAdornment?: ReactNode;
  supportingText?: ReactNode;
};

export const PersonAvatarWithName: FC<Props> = ({
  person,
  startAdornment,
  supportingText,
}) => (
  <PersonAvatarWithText
    person={ person }
    text={
      <span css={ { display: 'inline-flex' } }>
        { startAdornment }&nbsp;
        <Link to={ `/people/${ person.id }` }>
          <LineClamp lines={ 2 }>
            { PersonParser.fullName(person) }
          </LineClamp>
        </Link>
      </span>
    }
    supportingText={ supportingText }
  />
);
