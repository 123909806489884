import { FC, useEffect, useMemo, useRef } from 'react';
import { parseISO } from 'date-fns';
import { useParams } from 'react-router-dom';
import { MenuItem } from 'op-storybook/stories/components/MenuItem/MenuItem';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { StackEnd } from 'op-storybook/lib/components/StackEnd/StackEnd';
import { Typography } from 'op-storybook/stories/components/Typography/Typography';
import { LineClamp } from 'op-storybook/stories/components/LineClamp/LineClamp';

import { PersonalDelivery } from '../../Model';
import { PersonParser } from '../../../Utility';
import { MandatoryIndicator } from '../MandatoryIndicator/MandatoryIndicator';
import { NudgeIndicator } from '../NudgeIndicator/NudgeIndicator';
import { DeliveryDate } from '../DeliveryDate/DeliveryDate';
import { PersonAvatar } from '../../../Common/Component/PersonAvatar/PersonAvatar';

type Props = {
  delivery: PersonalDelivery;
  onClick: () => void;
  initialScrollPerformed?: boolean;
  onInitialScrollPerformed: () => void;
  breakpoint: 'desktop' | 'mobile';
};

type Params = {
  deliveryId?: string;
};

export const PersonalDeliveryListItem: FC<Props> = ({
  delivery,
  onClick,
  initialScrollPerformed,
  onInitialScrollPerformed,
  breakpoint,
}) => {
  const deliveredDate = useMemo(
    () => parseISO(delivery.bumpedAt ?? delivery.receivedAt),
    [delivery.bumpedAt, delivery.receivedAt],
  );
  const { deliveryId: activeDeliveryId } = useParams<Params>();
  const active = delivery.delivery.id === activeDeliveryId;
  const deliveryRef = useRef<HTMLLIElement | null>(null);
  const unseen = !delivery.completedAt && delivery.delivery.status !== 'recalled';

  useEffect(() => {
    if (!active || initialScrollPerformed) {
      return;
    }

    deliveryRef.current?.scrollIntoView();
    onInitialScrollPerformed();
  }, [active, initialScrollPerformed, onInitialScrollPerformed]);

  return (
    <li>
      <MenuItem
        selected={ activeDeliveryId === delivery.delivery.id }
        onClick={ onClick }
        css={ {
          minHeight: '120px',
        } }
        padding={ breakpoint === 'desktop' ? 'md' : 'sm' }
      >
        <Stack align="flex-start">
          <PersonAvatar
            size="md"
            person={ delivery.delivery.createdBy }
            css={ {
              flexShrink: 0,
            } }
          />
          <Stack direction="column" css={ { flexGrow: 1 } }>
            <Stack align="flex-start">
              <LineClamp lines={ 1 }>
                <Typography
                  palette={ { colour: 'teal', intensity: 900 } }
                  weight={ unseen ? 'semibold' : 'medium' }
                >
                  { PersonParser.fullName(delivery.delivery.createdBy) }
                </Typography>
              </LineClamp>
              <StackEnd>
                <StackEnd>
                  <DeliveryDate date={ deliveredDate } unseen={ unseen }/>
                </StackEnd>
              </StackEnd>
            </Stack>
            <Stack align="flex-start">
              <LineClamp lines={ 2 }>
                <Typography
                  palette={ { colour: 'teal', intensity: 900 } }
                  weight={ unseen ? 'semibold' : 'regular' }
                >
                  { delivery.broadcast.name }
                </Typography>
              </LineClamp>
              { (delivery.nudge || delivery.broadcast.mandatory) && (
                <StackEnd>
                  <Stack>
                    { delivery.nudge && <NudgeIndicator nudge={ delivery.nudge }/> }
                    { delivery.broadcast.mandatory && <MandatoryIndicator/> }
                  </Stack>
                </StackEnd>
              ) }
            </Stack>
          </Stack>
        </Stack>
      </MenuItem>
    </li>
  );
};
