import styled from '@emotion/styled';

export const ValidationErrorMessage = styled.p`
  color: ${ props => props.theme.palette.error.main };
  font-size: 0.9rem;
  margin-bottom: 0;

  &:not(:last-of-type) {
    margin-bottom: 0.35rem;
  }
`;

ValidationErrorMessage.defaultProps = { 'role': 'alert' };
