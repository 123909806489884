import { FunctionComponent } from 'react';
import { AutocompleteSelectionChanged } from '@ourpeople/shared/Core/Component/Input/Autocomplete/Autocomplete';

import { Flex } from '../../../../Common/Component';
import { StyledAutocompleteContainer } from './style';
import { TagAutocomplete } from '../../../../Tags/Component';
import { TagType } from '../../../../Tags/Model';
import { Tag } from '../../../../Types';

type Props = {
  label: string;
  tagType: TagType;
  allowedIds?: string[];
  tagIds: string[];
  onTagIdsChanged: (tagIds: string[]) => void;
  disabled: boolean;
};

export const TagAutocompleteWithLabel: FunctionComponent<Props> = ({
  label,
  tagType,
  allowedIds,
  tagIds,
  onTagIdsChanged,
  disabled,
}) => {
  const whenTagSelectionChanged: AutocompleteSelectionChanged<Tag> = selection => {
    onTagIdsChanged(selection.options.map(option => option.id).concat(selection.unknownIds));
  };

  return (
    <Flex
      gap={ 2 }
      noWrap
    >
      <span>{ label }</span>
      <StyledAutocompleteContainer>
        <TagAutocomplete
          type={ tagType }
          allowedIds={ allowedIds }
          selectedIds={ tagIds }
          onSelectionChanged={ whenTagSelectionChanged }
          fullWidth
          dense
          disabled={ disabled }
        />
      </StyledAutocompleteContainer>
    </Flex>
  );
};
