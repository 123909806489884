import React, { FunctionComponent } from 'react';
import { InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';

import { RoleConditionBuilder, TagConditionBuilder, PersonConditionBuilder } from '../..';
import { StyledConditionContainer, StyledFilterTypeSelect } from './style';
import { Condition, PersonCondition, RoleCondition, StatusCondition, TagCondition } from '../../../Model';
import { ValidationTree } from '../../../../Common/Model';
import { StatusConditionBuilder } from '../StatusConditionBuilder/StatusConditionBuilder';

interface Props {
  condition: Condition;
  onChange: (condition: Condition) => void;
  validation?: ValidationTree<Condition>;
  readonly?: boolean;
  autoFocus?: boolean;
}

interface ConditionType {
  type: string;
  name: string;
  defaultCondition: Condition;
}

export const ConditionBuilder: FunctionComponent<Props> = ({
  condition,
  onChange,
  validation,
  readonly = false,
  autoFocus,
}) => {
  const intl = useIntl();
  const availableConditionTypes: ConditionType[] = [
    {
      type: 'status',
      name: intl.formatMessage({
        id: 'audiences.builder.conditionType.status',
        defaultMessage: 'Status',
      }),
      defaultCondition: { type: 'status', statuses: [] },
    },
    {
      type: 'role',
      name: intl.formatMessage({
        id: 'audiences.builder.conditionType.role',
        defaultMessage: 'User Role',
      }),
      defaultCondition: { type: 'role', roleIds: [] },
    },
    {
      type: 'tag',
      name: intl.formatMessage({
        id: 'audiences.builder.conditionType.tag',
        defaultMessage: 'Tag',
      }),
      defaultCondition: { type: 'tag', tagType: 'team', tagIds: [] },
    },
    {
      type: 'person',
      name: intl.formatMessage({
        id: 'audiences.builder.conditionType.person',
        defaultMessage: 'Person',
      }),
      defaultCondition: { type: 'person', personIds: [] },
    },
  ];

  const whenTypeChanged = (event: React.ChangeEvent<{ value: unknown }>): void => {
    const conditionType = availableConditionTypes
      .find((availableType) => availableType.type === event.target.value);

    if (!conditionType) {
      return;
    }

    onChange(conditionType.defaultCondition);
  };

  const whenConditionChanged = (condition: Condition): void => {
    onChange(condition);
  };

  return <StyledConditionContainer>
    <StyledFilterTypeSelect>
      <InputLabel id="condition-filter-label">
        <FormattedMessage
          id="audiences.builder.condition.type"
          description="Label for the condition type dropdown"
          defaultMessage="Filter by"
        />
      </InputLabel>
      <Select
        labelId="condition-filter-label"
        input={ (
          <OutlinedInput
            margin="dense"
          />
        ) }
        variant="outlined"
        value={ availableConditionTypes.find((conditionType) => condition.type === conditionType.type)?.type || '' }
        onChange={ whenTypeChanged }
        disabled={ readonly }
        { ...(readonly ? { IconComponent: () => null } : {}) }
      >
        {
          availableConditionTypes.map((conditionType) => (
            <MenuItem
              key={ conditionType.type }
              value={ conditionType.type }
            >
              { conditionType.name }
            </MenuItem>
          ))
        }
      </Select>
    </StyledFilterTypeSelect>
    { condition.type === 'status' && (
      <StatusConditionBuilder
        condition={ condition as StatusCondition }
        onChange={ whenConditionChanged }
        validation={ validation as ValidationTree<StatusCondition> | undefined }
        readonly={ readonly }
        autoFocus={ autoFocus }
      />
    ) }
    { condition.type === 'role' && (
      <RoleConditionBuilder
        condition={ condition as RoleCondition }
        onChange={ whenConditionChanged }
        validation={ validation as ValidationTree<RoleCondition> | undefined }
        readonly={ readonly }
        autoFocus={ autoFocus }
      />
    ) }
    { condition.type === 'tag' && (
      <TagConditionBuilder
        autoFocus={ autoFocus }
        condition={ condition as TagCondition }
        onChange={ whenConditionChanged }
        validation={ validation as ValidationTree<TagCondition> | undefined }
        readonly={ readonly }
      />
    ) }
    { condition.type === 'person' && (
      <PersonConditionBuilder
        condition={ condition as PersonCondition }
        onChange={ whenConditionChanged }
        validation={ validation as ValidationTree<PersonCondition> | undefined }
        readonly={ readonly }
        autoFocus={ autoFocus }
      />
    ) }
  </StyledConditionContainer>;
};
