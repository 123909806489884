import { ComponentProps, FC, RefObject, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { DropdownItem } from 'op-storybook/stories/components/DropdownItem/DropdownItem';

import { useContextOrThrow } from '../../../../../Core/Hook';
import { ApiContext } from '../../../../../Contexts';
import { Form } from '../../../../../Forms/Model';
import { Paginated } from '../../../../../Models';
import { ComboBox } from '../../../../Common/Component/Input/ComboBox';

type Props = {
  value: Form | null;
  onChange: (value: Form) => void;
  containerRef?: RefObject<HTMLElement>;
};

export const FormComboBox: FC<Props> = ({
  value,
  onChange,
  containerRef,
}) => {
  const api = useContextOrThrow(ApiContext);
  const intl = useIntl();

  const queryFn: ComponentProps<typeof ComboBox<Form>>['queryFn'] = useCallback(async (filters, page) => {
    const response = await api.get<Paginated<'forms', Form>>('/me/forms', {
      params: {
        pageNum: page,
        ...filters.search ? { search: filters.search } : {},
      }
    });

    return {
      items: response.data.forms,
      pagination: response.data.pagination,
    };
  }, [api]);

  return (
    <ComboBox
      queryKey={ queryKey }
      queryFn={ queryFn }
      renderItemContents={ form => form.name }
      value={ value }
      onChange={ onChange }
      getItemLabel={ form => form.name }
      itemsAreEquivalent={ (formA, formB) => formA.id === formB.id }
      label={ intl.formatMessage({
        description: 'Label for form autocomplete',
        defaultMessage: 'Form',
      }) }
      placeholder={ intl.formatMessage({
        description: 'Placeholder for form autocomplete',
        defaultMessage: 'Search for form…',
      }) }
      containerRef={ containerRef }
    />
  );
};

const queryKey = ['my-forms'];
