import { ComponentProps, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { NavItem } from '@ourpeople/shared/Core/Component/Navigation/NavItem/NavItem';
import { StackEnd } from 'op-storybook/lib/components/StackEnd/StackEnd';
import { Overlay } from 'op-storybook/lib/components/Overlay/Overlay';
import { Divider } from 'op-storybook/stories/components/Divider/Divider';

import {
  StyledMainNavItemContainer,
  StyledNavSectionPadding,
  StyledSidebar,
  StyledSidebarAndSubMenuContainer,
  StyledSidebarContainer,
  StyledSidebarContentAndFooter,
  StyledFooterContainer,
} from './style';
import { SidebarHeader } from '../SidebarHeader/SidebarHeader';
import { FooterVariant, SidebarFooter } from '../SidebarFooter/SidebarFooter';
import { NavGroup, NavItemGroup } from '../NavItemGroup/NavItemGroup';
import { SubMenu } from '../SubMenu/SubMenu';
import { useScrollLock } from '../../../../New/Common/Hook/useScrollLock';

type ItemProps = NavGroup | Omit<ComponentProps<typeof NavItem>, 'iconOnly'>;

type Props = {
  mobile: boolean;
  toggleable: boolean;
  spaceName: string;
  logoUrl: string;
  slim?: boolean;
  onToggle: (expanded: boolean) => void;
  mainItems: ItemProps[];
  footerItems: ItemProps[];
  accountPath: string;
  logoutPath: string;
  userUuid: string;
  userName: string;
  userHasAvatar: boolean;
  userLastDescribed: number;
};

type SubMenuState = {
  active: boolean;
  groupId: string | null;
};

export const Sidebar: FC<Props> = ({
  mobile,
  toggleable,
  spaceName,
  logoUrl,
  slim = false,
  onToggle,
  mainItems,
  footerItems,
  accountPath,
  logoutPath,
  userUuid,
  userName,
  userHasAvatar,
  userLastDescribed,
}) => {
  const firstGroup = useMemo<NavGroup | undefined>(
    () => mainItems.find(item => !!item.children) as NavGroup | undefined,
    [mainItems],
  );
  const [subMenuState, setSubMenuState] = useState<SubMenuState>({
    active: false,
    groupId: null,
  });
  const [closingSubMenu, setClosingSubMenu] = useState<boolean>(false);
  const { pathname } = useLocation();
  const [scrollingRequired, setScrollingRequired] = useState<boolean>(false);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const subMenuOpen = subMenuState.active && slim && !closingSubMenu;
  useScrollLock(subMenuOpen);

  useEffect(() => {
    if (!closingSubMenu) {
      return;
    }

    const timeout = setTimeout(() => {
      setSubMenuState({
        active: false,
        groupId: null,
      });
      setClosingSubMenu(false);
    }, 200);

    return () => {
      clearTimeout(timeout);
      setClosingSubMenu(false);
    };
  }, [closingSubMenu]);

  const openSubMenu = useCallback((groupId: string | null) => {
    setSubMenuState({ active: true, groupId })
  }, []);

  const openDefaultSubMenu = useCallback(() => {
    openSubMenu(firstGroup?.id || null)
  }, [firstGroup?.id, openSubMenu]);

  const closeSubMenu = useCallback(() => {
    setClosingSubMenu(true);
  }, []);

  useEffect(() => {
    if (!slim || !subMenuState.active) {
      return;
    }

    setTimeout(() => {
      document.addEventListener('click', closeSubMenu);
    });

    return () => {
      document.removeEventListener('click', closeSubMenu);
    }
  }, [closeSubMenu, slim, subMenuState.active]);

  useEffect(() => {
    closeSubMenu();
  }, [closeSubMenu, pathname]);

  useEffect(() => {
    if (!slim) {
      return;
    }

    setSubMenuState({
      active: false,
      groupId: null,
    });
  }, [slim]);

  const toggleSubMenu = useCallback((groupId: string) => {
    if (subMenuState.groupId === groupId) {
      closeSubMenu();
    }

    openSubMenu(groupId);
  }, [closeSubMenu, openSubMenu, subMenuState.groupId]);

  const whenWindowResized = useCallback((): void => {
    const node = scrollContainerRef.current;

    if (!node) {
      return;
    }

    setScrollingRequired(node.scrollHeight > node.clientHeight);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', whenWindowResized);
    whenWindowResized();
    return () => window.removeEventListener('resize', whenWindowResized);
  }, [whenWindowResized]);

  const whenAvatarClicked = useCallback(
    () => subMenuState.active
      ? setSubMenuState({ active: false, groupId: null })
      : openDefaultSubMenu(),
    [openDefaultSubMenu, subMenuState.active],
  );

  return (
    <StyledSidebarContainer
      slim={ slim }
      mobile={ mobile }
    >
      <Overlay
        open={ subMenuOpen }
        onClick={ closeSubMenu }
        css={ {
          position: 'fixed',
        } }
      />
      <StyledSidebarAndSubMenuContainer>
        <StyledSidebar
          slim={ slim }
          mobile={ mobile }
          data-testid="main-menu"
        >
          <SidebarHeader
            spaceName={ spaceName }
            slim={ slim }
            logoUrl={ logoUrl }
            onToggle={ onToggle }
            toggleable={ toggleable }
          />
          <StyledSidebarContentAndFooter>
            <StyledMainNavItemContainer
              ref={ scrollContainerRef }
            >
              <StyledNavSectionPadding>
                <Stack
                  direction="column"
                  gap={ 2 }
                  align={ slim ? 'center' : 'flex-start' }
                >
                  { mainItems.map(itemProps => (
                    <NavRow
                      key={ itemProps.id }
                      itemProps={ itemProps }
                      slim={ slim }
                      onToggleSubMenu={ () => itemProps.id && toggleSubMenu(itemProps.id) }
                    />
                  )) }
                </Stack>
              </StyledNavSectionPadding>
            </StyledMainNavItemContainer>
            <StackEnd direction="column">
              <StyledFooterContainer scrollingRequired={ scrollingRequired }>
                <Stack
                  direction="column"
                  gap={ 4 }
                >
                  <StyledNavSectionPadding>
                    <Stack
                      direction="column"
                      gap={ 2 }
                    >
                      { footerItems.map(itemProps => (
                        <NavRow
                          key={ itemProps.id }
                          itemProps={ itemProps }
                          slim={ slim }
                          onToggleSubMenu={ () => itemProps.id && toggleSubMenu(itemProps.id) }
                        />
                      )) }
                    </Stack>
                  </StyledNavSectionPadding>
                  { !slim && (
                    <StyledNavSectionPadding>
                      <Divider
                        palette={ {
                          colour: 'teal',
                          intensity: 600,
                        } }
                      />
                    </StyledNavSectionPadding>
                  ) }
                  <SidebarFooter
                    accountPath={ accountPath }
                    logoutPath={ logoutPath }
                    userUuid={ userUuid }
                    userName={ userName }
                    variant={ slim ? FooterVariant.AVATAR_ONLY : FooterVariant.STANDARD }
                    onAvatarClick={ whenAvatarClicked }
                    userHasAvatar={ userHasAvatar }
                    userLastDescribed={ userLastDescribed }
                  />
                </Stack>
              </StyledFooterContainer>
            </StackEnd>
          </StyledSidebarContentAndFooter>
        </StyledSidebar>
        { subMenuState.active && slim && (
          <SubMenu
            open={ !closingSubMenu }
            accountPath={ accountPath }
            logoutPath={ logoutPath }
            navGroup={
              subMenuState.groupId
                ? mainItems.find(item => item.id === subMenuState.groupId) as NavGroup
                : undefined
            }
            userUuid={ userUuid }
            userName={ userName }
            userHasAvatar={ userHasAvatar }
            userLastDescribed={ userLastDescribed }
          />
        ) }
      </StyledSidebarAndSubMenuContainer>
    </StyledSidebarContainer>
  );
};

type NavRowProps = {
  slim: boolean;
  itemProps: NavGroup | ComponentProps<typeof NavItem>;
  onToggleSubMenu: () => void;
};

const NavRow: FC<NavRowProps> = ({
  slim,
  itemProps,
  onToggleSubMenu,
}) => (
  (itemProps as NavGroup).children
    ? (
      <NavItemGroup
        navGroup={ itemProps as NavGroup }
        iconOnly={ slim }
        onToggleSubMenu={ onToggleSubMenu }
      />
    )
    : (
      <NavItem
        { ...itemProps as ComponentProps<typeof NavItem> }
        iconOnly={ slim }
      />
    )
);
