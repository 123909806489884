import { FC } from 'react';
import { useIntl } from 'react-intl';

import { CenteredMessage } from '../CenteredMessage/CenteredMessage';

export const CenteredGenericEmptyMessage: FC = () => {
  const intl = useIntl();

  return (
    <CenteredMessage
      heading={ intl.formatMessage({
        id: 'genericEmptyMessage.heading',
        description: 'Generic empty message heading.',
        defaultMessage: 'No results…',
      }) }
    />
  );
};
