import { ComponentProps, FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'op-storybook/stories/components/Button/Button';

import {
  StyledPadding,
  StyledTemplate,
  StyledTemplateButtons,
  StyledTemplateContent,
  StyledTemplateDescription,
  StyledTemplateDetail,
  StyledTemplateName
} from './style';
import { Template } from '../../../Model';

type Props = {
  template: Template;
  selected: boolean;
  onSelect: () => void;
  onPreviewClick: () => void;
  useButtonProps: ComponentProps<typeof Button>;
};

export const TemplateTile: FC<Props> = ({
  template,
  selected,
  onSelect,
  onPreviewClick,
  useButtonProps,
}) => (
  <StyledTemplate
    backgroundUrl={ template.imageUrl }
    selected={ selected }
    onClick={ onSelect }
  >
    <StyledPadding/>
    <StyledTemplateContent { ...!selected ? { role: 'button' } : {} }>
      {
        selected
          ? (
            <StyledTemplateDetail>
              <StyledTemplateDescription>{ template.shortDescription }</StyledTemplateDescription>
              <StyledTemplateButtons>
                <Button
                  { ...useButtonProps }
                  variant="primary"
                  css={ {
                    width: '100%',
                  } }
                >
                  <FormattedMessage
                    id="templateTile.use"
                    description="Label for use button on tile used in template galleries."
                    defaultMessage="Use"
                  />
                </Button>
                <Button
                  variant="secondary"
                  onClick={ onPreviewClick }
                >
                  <FormattedMessage
                    id="templateTile.preview"
                    description="Label for preview button on tile used in template galleries."
                    defaultMessage="Preview"
                  />
                </Button>
              </StyledTemplateButtons>
            </StyledTemplateDetail>
          )
          : <StyledTemplateName>{ template.name }</StyledTemplateName>
      }
    </StyledTemplateContent>
  </StyledTemplate>
);
