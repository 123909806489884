import { FunctionComponent, ReactNode, useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { ListItemText, MenuItem } from '@mui/material';

import { RefreshDeliverySummaryContext } from '../BroadcastReportPage';
import { TrainingFeedbackDialog } from './TrainingFeedbackDialog';
import { Dialog } from '../../../../../Components';
import { TableMenu } from '../../../../../Components/Content/TableMenu';
import { ApiContext } from '../../../../../Contexts';
import { ContentWithEvent } from '../../../../../Models';
import { PersonParser } from '../../../../../Utility';
import { RefreshContext, ReportRecipient } from '../../../../../Broadcasts/Component';

interface Props {
  content: ContentWithEvent;
  recipient: ReportRecipient<Record<string, unknown>>;
  showRemoveAttendance: boolean;
}

export const TrainingTableMenu: FunctionComponent<Props> = ({
  content,
  recipient,
  showRemoveAttendance,
}) => {
  const intl = useIntl();
  const api = useContext(ApiContext);
  const reload = useContext(RefreshContext);
  const refreshDeliverySummary = useContext(RefreshDeliverySummaryContext);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);
  const [feedbackDialogHeading, setFeedbackDialogHeading] = useState<string|ReactNode>();
  const [feedbackDialogDescription, setFeedbackDialogDescription] = useState<string|ReactNode>();
  const [feedback, setFeedback] = useState('');
  const [feedbackType, setFeedbackType] = useState<'pass' | 'fail'>();

  const actionHeading = intl.formatMessage({
    id: 'removeAttendance.heading',
    defaultMessage: 'Remove Attendance'
  });
  const [actionDescription, setActionDescription] = useState<string|ReactNode>();

  const onMarkSuccessful = () => {
    setFeedbackDialogOpen(true);
    setFeedback('');
    setFeedbackType('pass');
    setFeedbackDialogHeading(
      intl.formatMessage({
        id: 'markSuccessful.heading',
        defaultMessage: 'Mark as Successful'
      })
    );
    setFeedbackDialogDescription(
      intl.formatMessage({
        id: 'markSuccessful.description',
        defaultMessage: 'Add any feedback for <b>{name}</b> below.'
      }, {
        name: PersonParser.fullName(recipient.user),
      })
    );
  };

  const onMarkUnsuccessful = () => {
    setFeedbackDialogOpen(true);
    setFeedback('');
    setFeedbackType('fail');
    setFeedbackDialogHeading(
      intl.formatMessage({
        id: 'markUnsuccessful.heading',
        defaultMessage: 'Mark as Unsuccessful'
      })
    );
    setFeedbackDialogDescription(
      intl.formatMessage({
        id: 'markSuccessful.description',
        defaultMessage: 'Add any feedback for <b>{name}</b> below.'
      }, {
        name: PersonParser.fullName(recipient.user),
      })
    );
  };

  const feedbackConfirmed = () => {
    if (api && content.id && feedbackType) {
      api.post(
        `/trainings/${content.id}/${feedbackType}`,
        {
          userId: recipient.user.id,
          text: feedback,
        }
      ).then(() => {
        setFeedbackDialogOpen(false);
        reload && reload();
        refreshDeliverySummary && refreshDeliverySummary();
      }).catch(() => {
        setFeedbackDialogOpen(false);
        // show a toast?
      });
    }
  };

  const onRemoveAttendance = () => {
    setActionDescription(
      intl.formatMessage({
        id: 'removeAttendance.description',
        defaultMessage: 'Are you sure you want to remove the attendance of <b>{name}</b>?'
      }, {
        name: PersonParser.fullName(recipient.user),
      })
    );
    setConfirmDialogOpen(true);
  };

  const onActionConfirmed = () => {
    if (api) {
      api.put(
        `/trainings/attendees/${recipient.metadata.traineeId as string}/remove`
      ).then(() => {
        setConfirmDialogOpen(false);
        reload && reload();
        refreshDeliverySummary && refreshDeliverySummary();
      }).catch(() => {
        // do nothing
      });
    }
  };

  return (
    <>
      <TableMenu
        rowKey={ content.id || ''}
      >
        <MenuItem
          onClick={onMarkSuccessful}
        >
          <ListItemText primary={
            intl.formatMessage({
              id: 'event.markSuccessful',
              defaultMessage: 'Mark successful',
            })
          }
          />
        </MenuItem>
        <MenuItem
          onClick={onMarkUnsuccessful}
        >
          <ListItemText primary={
            intl.formatMessage({
              id: 'event.markUnsuccessful',
              defaultMessage: 'Mark unsuccessful',
            })
          }
          />
        </MenuItem>
        {
          showRemoveAttendance && (
            <MenuItem
              onClick={onRemoveAttendance}
            >
              <ListItemText primary={
                intl.formatMessage({
                  id: 'event.removeAttendance',
                  defaultMessage: 'Remove attendance',
                })
              }
              />
            </MenuItem>
          )
        }
      </TableMenu>
      <TrainingFeedbackDialog
        cta={
          intl.formatMessage({
            id: 'trainingFeedbackDialog.cta',
            defaultMessage: 'Give feedback',
          })
        }
        description={feedbackDialogDescription}
        heading={feedbackDialogHeading}
        open={feedbackDialogOpen}
        onConfirmed={feedbackConfirmed}
        onDismissed={() => setFeedbackDialogOpen(false)}
        feedback={feedback}
        onFeedbackChanged={(feedback) => setFeedback(feedback)}
        />
      <Dialog
        cta={
          intl.formatMessage({
            id: 'trainingConfirmDialog.cta',
            defaultMessage: 'Yep',
          })
        }
        cancel={
          intl.formatMessage({
            id: 'trainingConfirmDialog.cancel',
            defaultMessage: 'Nope',
          })
        }
        headingSize="small"
        headingTextAlign="left"
        open={confirmDialogOpen}
        onConfirmed={onActionConfirmed}
        onDismissed={() => setConfirmDialogOpen(false)}
        heading={actionHeading}
        >
          {actionDescription}
        </Dialog>
    </>
  )
}
