import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

export const NobodyConditionComponent: FC = () => {
  return (
    <div>
      <FormattedMessage
        description="Audience condition summary that applies to nobody."
        defaultMessage="Nobody"
      />
    </div>
  );
};
