import { FC, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';
import { CenteredGenericErrorMessage, CenteredGenericLoadingMessage } from '@ourpeople/shared/Core/Component/Content';

import { Audience } from '../../../Audiences/Model';
import { FetchAudienceComparisonParams, useFetchAudienceComparison } from '../../../Audiences/Hook';

type Props = {
  previousAudience: Audience;
  audience: Audience;
  onConfirm: () => void;
  onCancel: () => void;
  autoJoin: boolean;
};

export const TopicAudienceComparisonDialog: FC<Props> = ({
  previousAudience,
  audience,
  onConfirm,
  onCancel,
  autoJoin,
}) => {
  const fetchAudienceComparisonParams = useMemo<FetchAudienceComparisonParams>(() => ({
    context: 'default',
    previousAudience,
    previousApplyReach: false,
    audience,
    applyReach: false,
  }), [audience, previousAudience]);
  const [fetchAudienceComparisonResult, , reloadAudienceComparison] = useFetchAudienceComparison(fetchAudienceComparisonParams);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={ true }
      onClose={ onCancel }
    >
      <DialogTitle>
        <FormattedMessage
          id="topicAudienceComparisonDialog.title"
          description="Title in topic audience comparison dialog."
          defaultMessage="Update audience filters"
        />
      </DialogTitle>
      <DialogContent>
        {
          fetchAudienceComparisonResult !== null
            ? fetchAudienceComparisonResult.content
              ? (
                <>
                  <p>
                    <FormattedMessage
                      id="topicAudienceComparisonDialog.changeText"
                      description="Text describing audience change in topic audience comparison dialog."
                      defaultMessage="<strong>{ removed, plural, =0 { Nobody } one { # person } other { # people } } will be removed</strong> and <strong>{ added, plural, =0 { nobody } one { # person } other { # people } } will be added</strong>."
                      values={ {
                        added: fetchAudienceComparisonResult?.content?.difference.counts.members.added || 0,
                        removed: fetchAudienceComparisonResult?.content?.difference.counts.members.removed || 0,
                      } }
                    />
                  </p>
                  <p>
                    {
                      autoJoin
                        ? (
                          <FormattedMessage
                            id="topicAudienceComparisonDialog.autoJoinDescription"
                            description="Description explaining audience change for auto-join topics."
                            defaultMessage="New members will be instantly added to this topic and will start receiving notifications."
                          />
                        )
                        : (
                          <FormattedMessage
                            id="topicAudienceComparisonDialog.manualJoinDescription"
                            description="Description explaining audience change for manual-join topics."
                            defaultMessage={ 'New members will be able to join via "Join chat".' }
                          />
                        )
                    }
                  </p>
                </>
              )
              : fetchAudienceComparisonResult.error && (
                <CenteredGenericErrorMessage
                  onRetryClicked={ reloadAudienceComparison }
                />
              )
            : <CenteredGenericLoadingMessage/>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={ onCancel }>
          <FormattedMessage
            id="topicAudienceComparisonDialog.cancel"
            description="Label for cancel button in topic audience comparison dialog."
            defaultMessage="Cancel"
          />
        </Button>
        <Button
          variant="primary"
          onClick={ onConfirm }
        >
          <FormattedMessage
            id="topicAudienceComparisonDialog.confirm"
            description="Label for confirm button in topic audience comparison dialog."
            defaultMessage="Save"
          />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
