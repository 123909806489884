import { FC, useCallback } from 'react';

import { SingleContentCard, LiveComponentProps } from '../../../../Content/Model';
import { ChoiceRequest as ChoiceRequestModel } from '../../../Model/ContentTypes';
import { ChoiceRequest } from '../../Content';
import { ValidationMerger } from '../../../../Common/Utility/ValidationMerger';
import { ValidationTree, Validator } from '../../../../Common/Model';
import { Validate } from '../../../../Common/Utility';
import { useContextOrThrow } from '../../../../Core/Hook';
import { FormNavigationContext } from '../../../Provider/FormNavigationProvider';
import { DraftChoiceResponse } from '../../../Service';

export const LiveChoiceRequest: FC<LiveComponentProps<ChoiceRequestModel, DraftChoiceResponse>> = ({
  card,
  response,
  onResponseChange,
  onResponseClear,
  validation,
  onValidationChange,
}) => {
  const { displayingValidation } = useContextOrThrow(FormNavigationContext);
  const whenChoiceChanged = useCallback((optionIds: string[]) => {
    if (!optionIds.length) {
      onResponseClear();
      onValidationChange({
        errors: [],
        children: {},
      });
      return;
    }

    const response: DraftChoiceResponse = {
      contentId: card.content.id,
      contentType: 'choiceRequest',
      optionIds,
    };

    const newValidation = validateResponse(card, response);
    onResponseChange(response);
    onValidationChange(
      validation
        ? ValidationMerger.overwriteMerge(validation, newValidation)
        : newValidation
    );
  }, [card, onResponseChange, onResponseClear, onValidationChange, validation]);

  return (
    <ChoiceRequest
      selection={ response?.optionIds }
      onChange={ whenChoiceChanged }
      validation={ validation }
      displayValidation={ displayingValidation }
      content={ card.content }
    />
  );
};

const validateResponse = (card: SingleContentCard<ChoiceRequestModel>, response: DraftChoiceResponse): ValidationTree<DraftChoiceResponse> => ({
  errors: [],
  children: {
    optionIds: {
      errors: new Validator<string[]>([
        optionIds => Validate.countGreaterThanEq(optionIds, card.content.minSelections),
        optionIds => Validate.countLessThanEq(optionIds, card.content.maxSelections),
      ]).validate(response.optionIds),
      children: {},
    },
  },
});
