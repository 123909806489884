import { DraftFormContent, FormContent } from '../Content';

export const TEXT_CONTENT_HTML_MAX_LENGTH = 4096;

export type TextContent = FormContent & {
  type: 'textContent';
  html: string;
};

export type DraftTextContent = DraftFormContent & {
  type: 'textContent';
  html: string;
};
