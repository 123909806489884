import { ComponentProps, FC, forwardRef, MouseEventHandler, ReactNode, useMemo } from 'react';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { StackEnd } from 'op-storybook/lib/components/StackEnd/StackEnd';
import { StyleBuilder } from 'op-storybook/lib/model/StyleBuilder/StyleBuilder';
import { LocationDescriptor } from 'history';
import { PrimaryInformation } from 'op-storybook/stories/components/Typography/PrimaryInformation';
import { SecondaryInformation } from 'op-storybook/stories/components/Typography/SecondaryInformation';

import { ContextMenu } from './ContextMenu';
import { LayoutLink } from '../Navigation/LayoutLink';

type Props = {
  id?: string;
  text: ReactNode;
  actions?: ComponentProps<typeof ContextMenu>['actions'];
  secondaryText?: ReactNode;
  timestamp?: string;
  icon?: ReactNode;
  padding?: 'sm' | 'md';
  disabled?: boolean;
  selected?: boolean;
  destructive?: boolean;
  endAdornment?: ReactNode;
} & ({ onClick: MouseEventHandler; location?: never } | { location: LocationDescriptor; onClick?: never });

export const StandardListItem: FC<Props> = forwardRef(({
  id,
  location,
  onClick,
  text,
  actions = [],
  secondaryText,
  timestamp,
  icon,
  padding = 'md',
  disabled = false,
  selected = false,
  destructive = false,
  endAdornment,
}: Props, ref) => {
  const styles = useMemo(() => buildStyles({ disabled, selected, padding, destructive }), [
    disabled,
    selected,
    padding,
    destructive
  ]);
  const multiLine = !!secondaryText;
  const listItemContents = useMemo(() => (
    <Stack
      align={ multiLine ? 'flex-start' : 'center' }
      ref={ ref }
    >
      { icon }
      <Stack
        direction="column"
        gap={ 1 }
      >
        <PrimaryInformation palette="inherit">
          { text }
        </PrimaryInformation>
        { secondaryText && (
          <SecondaryInformation palette="inherit">{ secondaryText }</SecondaryInformation>
        ) }
      </Stack>
      <StackEnd>
        <Stack>
          { timestamp && <SecondaryInformation>{ timestamp }</SecondaryInformation> }
          { endAdornment || (
            <ContextMenu
              actions={ actions }
              maxInlineActionCount={ 0 }
              alwaysUseMenu
            />
          ) }
        </Stack>
      </StackEnd>
    </Stack>
  ), [actions, endAdornment, icon, multiLine, ref, secondaryText, text, timestamp]);

  return (
    <li
      id={ id }
      css={ styles.menuItem }
    >
      {
        location
          ? (
            <LayoutLink css={ styles.menuItemContents } to={ location }>
              { listItemContents }
            </LayoutLink>
          )
          : (
            <button
              type="button"
              onClick={ onClick }
              css={ styles.menuItemContents }
            >
              { listItemContents }
            </button>
          )
      }
    </li>
  );
});

type StyleBuilderProps = {
  disabled: boolean;
  selected: boolean;
  padding: 'md' | 'sm';
  destructive: boolean;
};

const buildStyles: StyleBuilder<StyleBuilderProps> = ({ disabled, selected, padding, destructive }) => ({
  menuItem: theme => ({
    color: disabled ? theme.new.palette.grey[300].main : destructive ? theme.new.palette.error[700].main : theme.new.palette.grey[900].main,
    width: '100%',
    background: 'transparent',
    border: 'none',
    padding: padding === 'sm'
      ? `${ theme.new.spacing[1] } ${ theme.new.spacing[2] }`
      : `0 ${ theme.new.spacing[1] } 0`,
    display: 'flex',
    flexDirection: 'column',

    '>a:hover': {
      textDecoration: 'none',
    },

    ...selected
      ? {
        '>a, >button': {
          background: theme.new.palette.grey[100].main,
        },
      }
      : {
        ...disabled
          ? {
            pointerEvents: 'none',
          }
          : {
            ':hover, :focus-visible': {
              outline: 'none',

              '>a, >button': {
                background: theme.new.palette.grey[50].main,
              },
            },
          },
      },
  }),
  menuItemContents: theme => ({
    color: 'inherit',
    padding: padding === 'sm' ? theme.new.spacing[2] : theme.new.spacing[2],
    borderRadius: padding === 'sm' ? theme.new.borderRadius.standard : theme.new.borderRadius.large,
    flexGrow: 1,
    width: '100%',
    transition: 'background 100ms',
    border: 'none',
    background: 'transparent',
    textAlign: 'left',
  }),
});
