import { FC } from 'react';
import { useIntl } from 'react-intl';

import { EditorContent } from '../../../Content/Model';
import { useContentDefinitionRegistry } from '../../../Content/Hook';
import { StyledBroadcastCreateMenu } from './style';
import { BroadcastEditorContentMenuCategory } from '..';
import { Notice } from '../../../Common/Component';

export enum DefinitionCategoryAvailabilityType {
  HIDDEN,
  DISABLED,
}

type DefinitionCategoryAvailability = {
  type: DefinitionCategoryAvailabilityType.HIDDEN;
} | {
  type: DefinitionCategoryAvailabilityType.DISABLED;
  reason: string;
};

export type DefinitionCategoryConfig = {
  categoryId: string;
  availability: DefinitionCategoryAvailability;
};

type Props = {
  onContentCreated: (editorContent: EditorContent) => void;
  eventCategoryConfig?: DefinitionCategoryConfig;
};

export const BroadcastEditorContentMenu: FC<Props> = ({
  onContentCreated,
  eventCategoryConfig,
}) => {
  const intl = useIntl();
  const { contentDefinitions: uncategorisedContentDefinitions } = useContentDefinitionRegistry();
  const contentDefinitions = uncategorisedContentDefinitions.filter(contentDefinition => contentDefinition.categoryId === 'content');
  const feedbackDefinitions = uncategorisedContentDefinitions.filter(contentDefinition => contentDefinition.categoryId === 'feedback');
  const eventDefinitions = uncategorisedContentDefinitions.filter(contentDefinition => contentDefinition.categoryId === 'event');

  return (
    <StyledBroadcastCreateMenu>
      <BroadcastEditorContentMenuCategory
        heading={ intl.formatMessage({
          id: 'broadcasts.createMenu.content.label',
          description: 'Content category heading.',
          defaultMessage: 'Content',
        }) }
        contentDefinitions={ contentDefinitions }
        onContentCreated={ onContentCreated }
      />
      <BroadcastEditorContentMenuCategory
        heading={ intl.formatMessage({
          id: 'broadcasts.createMenu.feedback.label',
          description: 'Feedback category heading.',
          defaultMessage: 'Feedback',
        }) }
        contentDefinitions={ feedbackDefinitions }
        onContentCreated={ onContentCreated }
      />
      { eventCategoryConfig?.availability.type !== DefinitionCategoryAvailabilityType.HIDDEN && (
        <BroadcastEditorContentMenuCategory
          heading={ intl.formatMessage({
            id: 'broadcasts.createMenu.event.label',
            description: 'Event category heading.',
            defaultMessage: 'Event',
          }) }
          contentDefinitions={ eventDefinitions }
          onContentCreated={ onContentCreated }
          disabled={ eventCategoryConfig?.availability.type === DefinitionCategoryAvailabilityType.DISABLED }
        >
          { eventCategoryConfig?.availability.type === DefinitionCategoryAvailabilityType.DISABLED && (
            <Notice
              variant="outlined"
              feedback={ {
                severity: 'info',
                message: intl.formatMessage({
                  id: 'broadcasts.createMenu.event.disabledNotice',
                  description: 'Notice for when adding event content is disabled because of a recurring schedule.',
                  defaultMessage: 'Event cards are not available for recurring delivery settings.',
                }),
              } }
            />
          ) }
        </BroadcastEditorContentMenuCategory>
      ) }
    </StyledBroadcastCreateMenu>
  );
};
