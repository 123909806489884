import { ComponentProps, FC, ReactNode } from 'react';
import { PageHeader } from 'op-storybook/stories/components/PageHeader/PageHeader';
import { BreakpointContext } from 'op-storybook/lib/providers/BreakpointProvider/BreakpointProvider';

import { useContextOrThrow } from '../../../Core/Hook';
import { BreadcrumbItem } from '../PageHeader/PageHeader';
import { Breadcrumb } from '../Breadcrumb/Breadcrumb';

type Props = {
  title: string;
  supportingText?: string;
  variant: ComponentProps<typeof PageHeader>['variant'];
  Avatar?: ComponentProps<typeof PageHeader>['Avatar'];
  actions?: ReactNode;
  breadcrumbItems: (BreadcrumbItem | null)[];
  children?: ReactNode;
};

export const ResponsivePageHeader: FC<Props> = ({
  title,
  supportingText,
  variant,
  Avatar,
  actions,
  breadcrumbItems,
  children
}) => {
  const screenWidth = useContextOrThrow(BreakpointContext);

  return (
    <PageHeader
      Avatar={ Avatar }
      breakpoint={ screenWidth.lessThan.sm ? 'mobile' : 'desktop' }
      title={ title }
      supportingText={ supportingText }
      variant={ variant }
      actions={ actions }
      breadcrumb={
        <Breadcrumb
          items={ breadcrumbItems }
          includeBackground={ variant === 'banner-avatar' }
        />
      }
    >
      { children }
    </PageHeader>
  );
};
