import { FC, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { TextFieldProps } from '@mui/material';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import {
  ValidationErrorHandler
} from 'op-storybook/lib/utility/ValidationErrorHandlerFactory/ValidationErrorHandlerFactory';

import { LabelledField } from '../../../../Core/Component/Input/LabelledField/LabelledField';
import { ValidationError } from '../../../../../src/react/Common/Model';
import EmailIcon from '../../../../Asset/Icon/Streamline/email-action-unread.svg';
import {
  EmailAddressVerificationButton,
  VerificationState
} from '../EmailAddressVerificationButton/EmailAddressVerificationButton';
import { NoMarginTextField } from '../../../../../src/react/Common/Component/NoMarginTextField/NoMarginTextField';

type Props = {
  value: string;
  onChange: TextFieldProps['onChange'];
  verification?: {
    state: VerificationState;
    onClick: () => void;
  };
  errors?: ValidationError[];
  customHandlers?: ValidationErrorHandler<any>[]; // eslint-disable-line @typescript-eslint/no-explicit-any
  disabled?: boolean;
};

export const EmailAddressField: FC<Props> = ({
  value,
  onChange,
  verification,
  errors,
  customHandlers,
  disabled = false,
}) => {
  const intl = useIntl();

  const renderField = useCallback(props => (
    <Stack align="flex-end">
      <NoMarginTextField { ...props }/>
      { verification && (
        <EmailAddressVerificationButton
          emailVerificationEnabled={ !!verification }
          verifiableEmailAddress={ verification.state.verifiableEmailAddress }
          disableSendVerification={ !verification.state.canRequest }
          busy={ verification.state.sending }
          onClick={ verification.onClick }
        />
      ) }
    </Stack>
  ), [verification]);

  return (
    <LabelledField
      label={ intl.formatMessage({
        description: 'Label for email address field used in account registration and updating.',
        defaultMessage: 'Email address',
      }) }
      fullWidth
      value={ value }
      errors={ errors }
      onChange={ onChange }
      startAdornment={ {
        type: 'icon',
        IconComponent: EmailIcon,
      } }
      type="email"
      autoComplete="email"
      customHandlers={ customHandlers }
      renderField={ renderField }
      disabled={ disabled }
    />
  );
};

