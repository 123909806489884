import { FunctionComponent } from 'react';
import { useIntl, FormattedDate, FormattedTime } from 'react-intl';
import { parseISO } from 'date-fns';
import { Heading } from '@ourpeople/shared/Core/Component/Content';

import {
  Content,
  ContentWithEvent,
  CoverStatusDetail,
  MeetingStatusDetail,
  TrainingStatusDetail
} from '../../../../../Models';
import { EventStatus } from '../../../../../Events/Component';
import { StyledEventDetails, StyledEventHeading } from './styles';
import PinIcon from '../../../../../Assets/img/icons/monochrome/pin.svg';
import CalendarIcon from '../../../../../Assets/img/icons/monochrome/calendar.svg';
import ClockIcon from '../../../../../Assets/img/icons/monochrome/clock.svg';
import CommentIcon from '../../../../../Assets/img/icons/streamline/messages-bubble.svg';
import TagIcon from '../../../../../Assets/img/icons/streamline/tags-double.svg';
import { ContentReader } from '../../../../../Readers';
import { Flex } from '../../../../../Common/Component';
import { MinimalTag } from '../../../../../Tags/Model';

interface Props {
  content: Content;
  status: CoverStatusDetail | MeetingStatusDetail | TrainingStatusDetail;
  comment?: string;
  successTags?: MinimalTag[];
}

export const EventContentPreview: FunctionComponent<Props> = ({
  content,
  status,
  comment,
  successTags,
}) => {
  const intl = useIntl();
  const eventContent = content as ContentWithEvent;
  return (
    <div>
      <StyledEventHeading>
        <Heading type="h3">{ content.text || '' }</Heading>
        <EventStatus
          contentType={ ContentReader.contentTypeFromString(content.type) }
          status={ status }
          withName={ true }
        />
      </StyledEventHeading>
      <StyledEventDetails>
        <Flex
          noWrap
          gap={ 1 }
        >
          <dt>
            <PinIcon
              title={
                intl.formatMessage({
                  id: 'event.location',
                  defaultMessage: 'Location'
                })
              }
            />
          </dt>
          <dd>{ eventContent.location }</dd>
        </Flex>
        <Flex
          noWrap
          gap={ 1 }
        >
          <dt>
            <CalendarIcon
              title={
                intl.formatMessage({
                  id: 'event.date',
                  defaultMessage: 'Date'
                })
              }
            />
          </dt>
          <dd>
            <FormattedDate
              value={ parseISO(eventContent.start_time) }
              year="numeric"
              month="short"
              day="2-digit"
              weekday="short"
            />
          </dd>
        </Flex>
        <Flex
          noWrap
          gap={ 1 }
        >
          <dt>
            <ClockIcon
              title={
                intl.formatMessage({
                  id: 'event.times',
                  defaultMessage: 'Start and end time'
                })
              }
            />
          </dt>
          <dd>
            <FormattedTime
              value={ parseISO(eventContent.start_time) }
            />
            &nbsp;-&nbsp;
            <FormattedTime
              value={ parseISO(eventContent.end_time) }
            />
          </dd>
        </Flex>
        { !!successTags?.length && (
          <Flex
            noWrap
            gap={ 1 }
          >
            <dt>
              <TagIcon
                aria-label={ intl.formatMessage({
                  description: 'Label for success tags in broadcast report event content summary.',
                  defaultMessage: 'Success tags',
                }) }
              />
            </dt>
            <dd>
              { successTags.map(tag => tag.name).join(', ') }
            </dd>
          </Flex>
        ) }
        { comment && (
          <Flex
            noWrap
            gap={ 1 }
          >
            <dt>
              <CommentIcon
                aria-label={
                  intl.formatMessage({
                    description: 'Label for comment in broadcast report event content summary.',
                    defaultMessage: 'Comment',
                  })
                }
              />
            </dt>
            <dd>
              { comment }
            </dd>
          </Flex>
        ) }
      </StyledEventDetails>
    </div>
  );
};
