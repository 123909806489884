import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { Alert, } from '../../../Components';
import { StyledAlertContainer, } from '../../../Sections/Broadcasts/List/styles';
import { BroadcastAction } from '../../Model';

type ActionErrorsProps = {
  action?: BroadcastAction;
  error: boolean;
};

export const ActionErrors: FunctionComponent<ActionErrorsProps> = ({
  action,
  error,
}) => (
  <>
    {
      error && (
        <>
          {
            action === BroadcastAction.ARCHIVE && (
              <StyledAlertContainer>
                <Alert severity="error">
                  <FormattedMessage
                    id="broadcasts.archiving.error"
                    defaultMessage="An error occurred whilst archiving your broadcast"
                  />
                </Alert>
              </StyledAlertContainer>
            )
          }
          {
            action === BroadcastAction.UNARCHIVE && (
              <StyledAlertContainer>
                <Alert severity="error">
                  <FormattedMessage
                    defaultMessage="An error occurred whilst unarchiving your broadcast"
                  />
                </Alert>
              </StyledAlertContainer>
            )
          }
          {
            action === BroadcastAction.STOP && (
              <StyledAlertContainer>
                <Alert severity="error">
                  <FormattedMessage
                    id="broadcasts.stopping.error"
                    defaultMessage="An error occurred whilst stopping your broadcast"
                  />
                </Alert>
              </StyledAlertContainer>
            )
          }
          {
            action === BroadcastAction.DELETE && (
              <StyledAlertContainer>
                <Alert severity="error">
                  <FormattedMessage
                    id="broadcasts.deleting.error"
                    defaultMessage="An error occurred whilst deleting your broadcast"
                  />
                </Alert>
              </StyledAlertContainer>
            )
          }
          {
            action === BroadcastAction.DUPLICATE && (
              <StyledAlertContainer>
                <Alert severity="error">
                  <FormattedMessage
                    id="broadcasts.duplicating.error"
                    defaultMessage="An error occurred whilst duplicating your broadcast"
                  />
                </Alert>
              </StyledAlertContainer>
            )
          }
          {
            action === BroadcastAction.SEND_TO_NEW && (
              <StyledAlertContainer>
                <Alert severity="error">
                  <FormattedMessage
                    id="broadcasts.sendingToNew.error"
                    defaultMessage="An error occurred whilst sending your broadcast to new recipients"
                  />
                </Alert>
              </StyledAlertContainer>
            )
          }
        </>
      )
    }
  </>
);
