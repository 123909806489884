import { FC } from 'react';
import { ValidationErrorDefinition } from 'op-storybook/lib/model/Validation/Definition/ValidationErrorDefinition';
import { ValidationTree } from 'op-storybook/lib/model/Validation/ValidationTree';

import { BroadcastBlock } from './Broadcast';
import { SvgComponent } from '../../../Common/Model';
import { BroadcastAttachments } from './BroadcastAttachments';
import { NonNullableObject } from '../../Core/Type/NonNullableObject';

type BaseFormComponentProps<T extends BroadcastBlock> = {
  block: T;
  onChange: (block: T) => void;
  validationTree: ValidationTree<T>;
  attachments: BroadcastAttachments;
  onAttachmentsChange: (attachments: BroadcastAttachments) => void;
}

export type FormComponent<T extends BroadcastBlock> = FC<BaseFormComponentProps<T> & { isTemporaryBlock: boolean }>;
export type InlineFormComponent<T extends BroadcastBlock> = FC<BaseFormComponentProps<T> & { onRemove: () => void; activateBlock: () => void }>;
export type PreviewComponent<T extends BroadcastBlock> = FC<{ block: T; attachments: BroadcastAttachments }>;

type ObjectKeyErrorDefinitions<T extends Record<string, unknown>> = {
  [K in keyof T]: T[K] extends Record<string, unknown>
    ? ObjectKeyErrorDefinitions<NonNullableObject<T[K]>>
    : ValidationErrorDefinition<any, string>[];
}

export type BlockErrorDefinitions<T extends BroadcastBlock> = ObjectKeyErrorDefinitions<NonNullableObject<T['attributes']>>;

export enum PreviewSlot {
  TEXT,
  MEDIA,
}

export interface BroadcastBlockDefinition<T extends BroadcastBlock> {
  prioritise: boolean;
  localisedKind: string;
  kind: T['kind'];
  definesBlock: (block: BroadcastBlock) => block is T;
  initialiseBlock: () => T;
  FormComponent: FormComponent<T> | null;
  InlineFormComponent: InlineFormComponent<T>;
  IconComponent: SvgComponent;
  ExpandedPreviewComponent: PreviewComponent<T>;
  errorDefinitions: BlockErrorDefinitions<T>;
  prepareBlockForSubmission?: (block: T) => T;
  preview?: {
    slot: PreviewSlot;
    Component: PreviewComponent<T>;
  };
}
