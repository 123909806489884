import { createContext } from 'react';
import { ReadyState } from 'react-use-websocket';

import { StructuredMessage } from '../Provider';

export interface WebsocketContextValue {
  readyState: ReadyState;
  lastMessage: StructuredMessage | null;
  sendMessage: (message: StructuredMessage, onError?: (error: unknown) => void) => void;
}

export const WebsocketContext = createContext<WebsocketContextValue | null>(null);
WebsocketContext.displayName = 'WebsocketContext';
