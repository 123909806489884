import styled from '@emotion/styled';
import { lighten } from 'polished';

type RevealableProps = {
  revealed: boolean;
};

export const StyledRevealOption = styled.div<RevealableProps>`
  background-color: ${ props => props.theme.palette.primary.main };
  min-height: ${ props => props.theme.spacing(6) };
  border-radius: ${ props => props.theme.spacing(3) };
  padding: ${ props => props.theme.spacing(2) };
  color: ${ props => props.theme.palette.primary.contrastText };
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: ${ props => props.revealed
    ? `${ props.theme.spacing(2) }`
    : '0'
  };
  transition: gap 0.3s, background-color 0.1s;
  user-select: none;

  &:hover {
    background-color: ${ props => lighten(0.02, props.theme.palette.primary.main) };
  }
`;

export const StyledOptionHeading = styled.span`
  font-weight: 500;
`;

export const StyledOptionContent = styled.span<RevealableProps>`
  font-size: 0.95rem;
  transition: font-size 0.2s, opacity 0.1s 0.2s;
  ${ props => !props.revealed && `
    opacity: 0;
    font-size: 0;
    transition: opacity 0.1s, font-size 0.2s 0.1s;
  ` }
`;
