import {TemplateWithContent} from '../../Common/Model';
import {FetchResponse, useGet, useTransformedFetchResponse} from '../../Hooks';
import {Paginated} from '../../Models';
import {MinimalFormWithMultiContentCards, MinimalFormWithSingleContentCards} from '../Model';
import {FormTransformer} from '../Utility';

export type FormTemplate = TemplateWithContent<'form', MinimalFormWithSingleContentCards>;
type MultiContentFormTemplate = TemplateWithContent<'form', MinimalFormWithMultiContentCards>

export const useFetchFormsTemplates = (disabled?: boolean): FetchResponse<Paginated<'templates', FormTemplate>> => (
  useTransformedFetchResponse(
    useGet<Paginated<'templates', MultiContentFormTemplate>>('/forms/templates', undefined, undefined, disabled),
    ({ pagination, templates }) => ({
      pagination,
      templates: templates.map(formAndTemplate => ({
        template: formAndTemplate.template,
        form: FormTransformer.consolidateMinimalFormContent(formAndTemplate.form),
      })),
    })
  )
)
