import styled from '@emotion/styled';

export const StyledLegend = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style-type: none;
`;

export const LegendLabel = styled.li`
  display: flex;
  align-items: center;
  margin-right: 1rem;
`;

interface LegendSwatchProps {
  colour: string;
}

export const LegendSwatch = styled.div<LegendSwatchProps>`
  background-color: ${ props => props.colour };
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 100rem;
  margin-right: 0.5rem;
`;
