import { Frequency } from 'rrule';

import {
  AnnualSchedule,
  DailySchedule,
  MonthlySchedule,
  OneTimeSchedule,
  Schedule,
  WeeklySchedule
} from '../Model/BroadcastSchedule';

export class BroadcastScheduleIdentifier {
  public static scheduleIsDaily = (schedule: Schedule): schedule is DailySchedule => (
    schedule.recurrence?.repetition.frequency === Frequency.DAILY
  );

  public static scheduleIsWeekly = (schedule: Schedule): schedule is WeeklySchedule => (
    schedule.recurrence?.repetition.frequency === Frequency.WEEKLY
  );

  public static scheduleIsMonthly = (schedule: Schedule): schedule is MonthlySchedule => (
    schedule.recurrence?.repetition.frequency === Frequency.MONTHLY
  );

  public static scheduleIsAnnual = (schedule: Schedule): schedule is AnnualSchedule => (
    schedule.recurrence?.repetition.frequency === Frequency.YEARLY
  );

  public static scheduleIsOneTime = (schedule: Schedule): schedule is OneTimeSchedule => !schedule.recurrence;
}
