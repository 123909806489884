import { FC } from 'react';
import { useIntl } from 'react-intl';
import { Collapse } from '@mui/material';
import MinimiseIcon from '@ourpeople/shared/Asset/Icon/figma/minimize-01.svg';
import ExpandIcon from '@ourpeople/shared/Asset/Icon/figma/maximize-01.svg';
import { NavItem } from '@ourpeople/shared/Core/Component/Navigation/NavItem/NavItem';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { StackEnd } from 'op-storybook/lib/components/StackEnd/StackEnd';
import { Typography } from '@ourpeople/shared/Core/Component/Content/Typography/Typography';
import { LineClamp } from 'op-storybook/stories/components/LineClamp/LineClamp';

import { StyledLogo, StyledSidebarHeader } from './style';

type Props = {
  toggleable: boolean
  spaceName: string;
  slim: boolean;
  logoUrl: string;
  onToggle: (expanded: boolean) => void;
};

export const SidebarHeader: FC<Props> = ({
  toggleable,
  spaceName,
  slim,
  logoUrl,
  onToggle,
}) => {
  const intl = useIntl();

  return (
    <StyledSidebarHeader
      slim={ slim }
    >
      <Stack
        gap={ 4 }
        direction={ slim ? 'column' : 'row' }
      >
        <StyledLogo
          src={ logoUrl }
          role="presentation"
          alt={ spaceName }
        />
        { !slim && (
          <>
            <Typography
              type="text"
              size="lg"
              color="teal.700.contrast"
              weight="semibold"
            >
              <LineClamp lines={ 2 }>
                { spaceName }
              </LineClamp>
            </Typography>
            { toggleable && (
              <StackEnd>
                <NavItem
                  onClick={ () => onToggle(false) }
                  iconOnly
                  labelTooltip
                  IconComponent={ MinimiseIcon }
                  label={ intl.formatMessage({
                    description: 'Label for minimise button in sidebar navigation.',
                    defaultMessage: 'Minimise menu',
                  }) }
                />
              </StackEnd>
            ) }
          </>
        ) }
        { toggleable && slim && (
          <Collapse
            in={ slim }
            unmountOnExit
            mountOnEnter
          >
            <NavItem
              iconOnly
              label={ intl.formatMessage({
                description: 'Label for expand button in sidebar navigation.',
                defaultMessage: 'Expand menu',
              }) }
              labelTooltip
              IconComponent={ ExpandIcon }
              onClick={ () => onToggle(true) }
            />
          </Collapse>
        ) }
      </Stack>
    </StyledSidebarHeader>
  );
};
