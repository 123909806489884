import { FC } from 'react';

import { Audience, Condition, Segment } from '../../../../Audiences/Model';
import {
  AudienceConditionDefinition,
  ConditionListComponent,
  ConditionRestriction
} from '../../Model/AudienceConditionDefinition';

export class AudienceConditionDefinitionRegistry {
  constructor(
    public readonly definitions: AudienceConditionDefinition<any>[],
  ) {}

  public getComponent = <C extends Condition>(condition: C): FC<{ condition: C; onEditClicked: () => void; onChange: (condition: C) => void }> => {
    const definition = this.getConditionDefinition(condition);

    return definition.Component;
  }

  public removeAudienceRedundancy = (audience: Audience): Audience => {
    const necessarySegments: Segment[] = [];

    for (const segment of audience.segments) {
      const necessaryConditions = this.removeRedundantConditions(segment.conditions);

      if (!necessaryConditions.length) {
        break;
      }

      necessarySegments.push({
        ...segment,
        conditions: necessaryConditions,
      });
    }

    return {
      ...audience,
      segments: necessarySegments,
    };
  }

  public getInitialisableDefinitions = () => {
    return this.definitions.filter(definition => !!definition.build);
  }

  private getConditionDefinition<C extends Condition>(condition: C): AudienceConditionDefinition<C> {
    const definition = this.definitions
      .find(definition => (
        definition.definesCondition(condition)
      )) as AudienceConditionDefinition<C> | undefined;

    if (!definition) {
      throw new Error(`No definition found for condition with type ${ condition.type }.`);
    }

    return definition;
  }

  private removeInvalidConditions(conditions: Condition[]): Condition[] {
    const validConditions: Condition[] = [];

    for (const condition of conditions) {
      const conditionDefinition = this.getConditionDefinition(condition);

      if (!conditionDefinition.isValid(condition)) {
        continue;
      }

      validConditions.push(condition);
    }

    return validConditions;
  }

  private segmentIsUnrestricted = (segment: Segment) => {
    const conditions = this.removeRedundantConditions(segment.conditions);

    if (!conditions.length) {
      return true;
    }

    if (conditions.length > 1) {
      return false;
    }

    const definition = this.getConditionDefinition(conditions[0]);
    return definition.restriction === ConditionRestriction.NONE;
  }

  public removeRedundantConditions = (conditions: Condition[]): Condition[] => {
    const validConditions = this.removeInvalidConditions(conditions);
    const necessaryConditions: Condition[] = [];
    let mostRestrictiveCondition: ConditionRestriction = ConditionRestriction.NONE;

    for (const condition of validConditions) {
      const definition = this.getConditionDefinition(condition);

      if (definition.restriction > mostRestrictiveCondition) {
        mostRestrictiveCondition = definition.restriction;
      }
    }

    for (const condition of validConditions) {
      const definition = this.getConditionDefinition(condition);

      if (definition.restriction >= mostRestrictiveCondition) {
        necessaryConditions.push(condition);
      }
    }

    return necessaryConditions;
  }

  public getLocalisedConditionType = (condition: Condition): string => {
    const definition = this.getConditionDefinition(condition);

    return definition.name;
  }

  public getConditionEditComponent = <C extends Condition>(condition: C): ConditionListComponent<C> | undefined => {
    const definition = this.getConditionDefinition(condition);

    return definition.build?.Component;
  }

  public resetCondition = <C extends Condition>(condition: C): C => {
    const definition = this.getConditionDefinition(condition);

    return definition.build?.initialise() || condition;
  }

  public conditionIsValid = <C extends Condition>(condition: C) => {
    const definition = this.getConditionDefinition(condition);

    return definition.isValid(condition);
  }

  public audienceIsUnrestricted = (audience: Audience) => {
    return audience.segments.every(this.segmentIsUnrestricted);
  };
}
