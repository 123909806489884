import styled from '@emotion/styled';

type StyledAlertProps = {
  severity?: 'error' | 'warning' | 'info' | 'success';
  variant?: 'contained' | 'uncontained';
};
type StyledAlertTextProps = {
  size?: 'small' | 'medium';
};

export const StyledAlert = styled.div<StyledAlertProps>`
  border-radius: 5px;
  padding: 1rem;
  margin: 1rem 0;
  display: flex;
  align-items: center;
  background: ${props => {
    return props.variant === 'uncontained'
      ? props.theme.palette.grey[50]
      : props.theme.palette.grey[200];
  }};
`;

export const StyledAlertIcon = styled.div<StyledAlertProps>`
  width: 2rem;
  height: 2rem;
  svg {
    width: 2rem;
    height: 2rem;
  }
  margin-right: 1rem;
  color: ${props => props.theme.palette.secondary.main};
  ${props => (
    props.severity === 'error'
    && `color: ${props.theme.palette.error.main};`
  )}
  ${props => (
    props.severity === 'warning'
    && `color: ${props.theme.palette.warning.main};`
  )}
  ${props => (
    props.severity === 'success'
    && `color: ${props.theme.palette.success.main};`
  )}
`;

export const StyledAlertSpinner = styled.div`
  margin-right: 1rem;
`;

export const StyledAlertText = styled.div<StyledAlertTextProps>`
  font-size: ${props => props.size === 'small' ? '0.9rem' : '1.1rem'};
  color: ${props => props.theme.palette.text.primary};
  strong, & ::slotted(strong) {
    font-weight: bold;
    color: ${props => props.theme.palette.text.primary};
  }

  p {
    margin: 0;
  }

  a {
    color: ${props => props.theme.palette.secondary.main};
  }
`;

export const StyledAlertButton = styled.div`
  margin-left: auto;
`

