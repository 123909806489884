import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { NavItem } from '@ourpeople/shared/Core/Component/Navigation/NavItem/NavItem';
import { ButtonBase } from '@ourpeople/shared/Core/Component/Input/ButtonBase/ButtonBase';
import LogOutIcon from '@ourpeople/shared/Asset/Icon/figma/log-out-01.svg';
import { StackEnd } from 'op-storybook/lib/components/StackEnd/StackEnd';
import { LineClamp } from 'op-storybook/stories/components/LineClamp/LineClamp';
import { Typography } from '@ourpeople/shared/Core/Component/Content/Typography/Typography';

import { StyledSidebarFooter } from './style';
import { PersonUuidAvatar } from '../../PersonUuidAvatar/PersonUuidAvatar';

export enum FooterVariant {
  STANDARD,
  AVATAR_ONLY,
  NO_AVATAR,
}

type Props = {
  userUuid: string;
  userName: string;
  accountPath: string;
  logoutPath: string;
  variant: FooterVariant;
  onAvatarClick: () => void;
  userHasAvatar: boolean;
  userLastDescribed: number;
};

export const SidebarFooter: FC<Props> = ({
  userUuid,
  userName,
  accountPath,
  logoutPath,
  variant,
  onAvatarClick,
  userHasAvatar,
  userLastDescribed,
}) => {
  const intl = useIntl();

  return (
    <StyledSidebarFooter
      slim={ variant === FooterVariant.AVATAR_ONLY }
    >
      <Stack
        fillParent
        align="flex-end"
        justify="center"
      >
        <ButtonBase
          variant={ variant === FooterVariant.NO_AVATAR ? 'tertiary-light' : 'filled-dark' }
          fillParent={ variant !== FooterVariant.AVATAR_ONLY }
          colour="teal"
          padding={ variant === FooterVariant.AVATAR_ONLY ? 'uniform' : 'standard' }
          {
            ...variant === FooterVariant.AVATAR_ONLY
              ? {
                onClick: onAvatarClick,
              }
              : {
                component: 'link',
                linkProps: { to: accountPath },
              }
          }
        >
          <Stack
            fillParent
            align="flex-start"
            gap={ 4 }
          >
            { variant !== FooterVariant.NO_AVATAR && (
              <PersonUuidAvatar
                size="md"
                personUuid={ userUuid }
                personName={ userName }
                waitForSrc={ userHasAvatar }
                versionString={ String(userLastDescribed) }
              />
            ) }
            { variant !== FooterVariant.AVATAR_ONLY && (
              <Stack
                direction="column"
                align="flex-start"
                gap={ 0 }
              >
                <LineClamp lines={ 2 }>
                  { userName }
                </LineClamp>
                <Typography
                  size="sm"
                  color="teal.500.main"
                >
                  <FormattedMessage
                    description="Label for view account button in side menu"
                    defaultMessage="View account"
                  />
                </Typography>
              </Stack>
            ) }
          </Stack>
        </ButtonBase>
        { variant !== FooterVariant.AVATAR_ONLY && (
          <StackEnd>
            <NavItem
              variant={ variant === FooterVariant.NO_AVATAR ? 'transparent' : 'filled' }
              label={ intl.formatMessage({
                description: 'Label for logout button in sidebar',
                defaultMessage: 'Log out',
              }) }
              labelTooltip
              IconComponent={ LogOutIcon }
              iconOnly
              component="link"
              linkProps={ { to: logoutPath } }
            />
          </StackEnd>
        ) }
      </Stack>
    </StyledSidebarFooter>
  );
};
