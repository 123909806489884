import { FC } from 'react';
import { useIntl } from 'react-intl';
import {
  useLocalisedValidationErrors
} from 'op-storybook/lib/hooks/useLocalisedValidationHandlers/useLocalisedValidationHandlers';
import { TextField } from 'op-storybook/stories/components/TextField/TextField';

import { ValidationError } from '../../../../../src/react/Common/Model/ValidationError';
import {
  useDefaultValidationErrorHandlers
} from '../../../../../src/react/Common/Hook/useDefaultValidationErrorHandlers';

type Props = {
  value: string;
  onChange: (value: string) => void;
  errors?: ValidationError[];
  disabled?: boolean;
};

export const LastNameField: FC<Props> = ({
  value,
  onChange,
  errors,
  disabled = false,
}) => {
  const intl = useIntl();
  const fieldName = intl.formatMessage({
    description: 'Label for last name field used in account registration and updating.',
    defaultMessage: 'Last name',
  });
  const errorHandlers = useDefaultValidationErrorHandlers(fieldName);
  const localisedErrors = useLocalisedValidationErrors(errorHandlers, errors || []);

  return (
    <TextField
      name="last-name"
      label={ fieldName }
      value={ value }
      errorMessages={ localisedErrors.map(localisedError => localisedError.localisation) }
      onChange={ onChange }
      disabled={ disabled }
      inputProps={ {
        autoComplete: 'family-name',
      } }
    />
  );
};
