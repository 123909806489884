import {FC, PropsWithChildren, useEffect, useMemo, useState} from 'react';
import FingerprintJS, {BuiltinComponents} from "@fingerprintjs/fingerprintjs";
import {createLocalStorageStateHook} from "use-local-storage-state";

import {DeviceFingerprintContext} from "../Context/DeviceFingerprintContext";

export const DeviceFingerprintProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const useStoredDeviceToken = createLocalStorageStateHook<string | undefined>('deviceToken', undefined);
  const [deviceToken, setDeviceToken] = useStoredDeviceToken();
  const [fingerprintComponents, setFingerprintComponents] = useState<BuiltinComponents>();

  useEffect(() => {
    if (fingerprintComponents) {
      return;
    }

    FingerprintJS.load()
      .then(agent => agent.get())
      .then(result => {
        setDeviceToken(prev => prev ?? result.visitorId);
        setFingerprintComponents(result.components);
      });
  }, [fingerprintComponents]);

  const contextValue = useMemo(() => ({
    ready: !!fingerprintComponents,
    deviceToken,
    platform: fingerprintComponents?.platform.value,
    manufacturer: (fingerprintComponents?.vendor.value || fingerprintComponents?.vendorFlavors.value?.length)
      ? `${
        fingerprintComponents?.vendor.value
          ? `${ fingerprintComponents.vendor.value } `
          : ''
      }${
        fingerprintComponents?.vendorFlavors.value?.length
          ? fingerprintComponents.vendorFlavors.value.join(', ')
          : ''
      }`
      : undefined,
  }), [deviceToken, fingerprintComponents]);

  return (
    <DeviceFingerprintContext.Provider
      value={ contextValue }
    >
      { contextValue.ready && children }
    </DeviceFingerprintContext.Provider>
  );
};
