import styled from '@emotion/styled';

interface PaddedLoadingProps {
  height?: string;
}

export const PaddedLoading = styled.div<PaddedLoadingProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  ${props => (
    props.height
    ? `height: ${props.height}`
    : `height: 50%;
    height: 50vh;`
  )}
`;
