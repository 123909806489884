import { useIntl } from 'react-intl';
import { FC } from 'react';

import VideoIcon from '../../../../../Assets/img/icons/monochrome/content/video.svg';
import { MediaBroadcastContent } from '../../Media/MediaBroadcastContent';
import { MediaPresentationComponentProps } from '../../../../Model';

export const LegacyVideoBroadcastContent: FC<MediaPresentationComponentProps> = ({
  text,
  media,
  navigationProps,
  onOpenFileClicked,
  openFileBusy,
  nudge,
}) => {
  const intl = useIntl();
  return (
    <MediaBroadcastContent
      text={ text }
      media={ media }
      nudge={ nudge }
      IconComponent={ VideoIcon }
      openFileBusy={ openFileBusy }
      navigationProps={ navigationProps }
      onOpenFileClicked={ onOpenFileClicked }
      openFileLabel={ intl.formatMessage({
        id: 'content.broadcasts.video.open',
        description: 'Video content open file button label.',
        defaultMessage: 'Play video',
      }) }
    />
  );
};
