import { FunctionComponent, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { ExternalLinkButton } from '../../../Components/Button';
import { FullscreenError } from '../../../Components/Error';

interface Props {
  onRetry: () => void;
}

export const AuthError: FunctionComponent<Props> = ({
  onRetry,
}) => (
  <FullscreenError onCtaClicked={ onRetry }>
    <p>
      <FormattedMessage
        id="error.authProvider"
        description="Error message that appears when the logged in user's details fail to load"
        defaultMessage="We're having some trouble loading your details. Are you <cta>logged in?</cta>"
        values={ {
          cta: (chunks: (string | ReactNode)[]) => (
            <ExternalLinkButton bordered={ false } href="/">
              { chunks }
            </ExternalLinkButton>
          )
        } }
      />
    </p>
    <p>
      <FormattedMessage
        id="error.contactCustomerSuccess"
        description="Explanation to contact customer success following an error"
        defaultMessage="If the problem persists, <cta>try contacting customer success</cta>."
        values={ {
          cta: (chunks: (string | ReactNode)[]) => (
            <ExternalLinkButton bordered={ false } href="https://get.ourpeople.help">
              { chunks }
            </ExternalLinkButton>
          )
        } }
      />
    </p>
  </FullscreenError>
);
