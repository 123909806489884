import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Tooltip } from '@mui/material';
import { LocalisedString } from 'op-storybook/lib/model/LocalisedString/LocalisedString';

import { TagType } from '../../Model';
import { FetchTagImportOperationsParams, useFetchTagImportOperations } from '../../Hook';
import { PaginatedTable, TableCell, TableRow } from '../../../Components';
import { TagParser } from '../../Utility';
import { Chip, TableFilters, TruncatingContent } from '../../../Common/Component';
import { ImportOperationAutocomplete, ImportOperationChip } from '../../../Imports/Component';
import NewTagIcon from '../../../Assets/img/icons/streamline/tags-add.svg';
import { TagImportTypeAutocomplete, TagOperationSummaryNotice } from '../../Component';
import { ImportDetail, ImportOperationTypeString } from '../../../Imports/Model';
import { ArrayHelper, ChipHelper, QueryParser, QueryWithKeys } from '../../../Common/Utility';
import { StyledTag } from './style';
import { useQueryAsState } from '../../../Hooks';

type Props = {
  importDetail: ImportDetail;
  onImportButtonClicked: () => void;
};

type Query = QueryWithKeys<'search' | 'pageNum' | 'recordTypes' | 'actions'>;

export const TagImportSummaryTable: FunctionComponent<Props> = ({
  importDetail,
  onImportButtonClicked,
}) => {
  const intl = useIntl();
  const [query, setQuery] = useQueryAsState<Query>();
  const importOperationTypes = useMemo(() => parseOperationTypes(query), [query]);
  const tagImportTypes = useMemo(() => parseTagTypes(query), [query]);
  const fetchTagImportParams = useMemo<FetchTagImportOperationsParams>(() => ({
    pageNum: QueryParser.pageNum(query),
    ...( tagImportTypes.length ? { recordTypes: tagImportTypes.join(',') } : {} ),
    ...( importOperationTypes.length ? { operationTypes: importOperationTypes.join(',') } : {} ),
    ...( query.search ? { search: query.search } : {} ),
  }), [importOperationTypes, query, tagImportTypes]);
  const [
    fetchTagImportOperationsResult,
    fetchTagImportOperationsState,
    reloadFetchTagImportOperations,
  ] = useFetchTagImportOperations(importDetail.id, fetchTagImportParams);
  const operations = fetchTagImportOperationsResult?.content?.operations || [];
  const pagination = fetchTagImportOperationsResult?.content?.pagination;
  const [chips, setChips] = useState<Chip[]>([]);

  const whenTagImportTypesChanged = (selection: LocalisedString<TagType>[]) => {
    setQuery(({ recordTypes, ...query }) => ({
      ...query,
      ...( selection.length ? { recordTypes: selection.map(option => option.id).join(',') } : {} ),
      pageNum: '1',
    }));

    setChips(chips => (
      ChipHelper.updateChipsWithBasicAutocompleteSelection(
        'importType',
        chips,
        selection,
        option => intl.formatMessage({
          id: 'tags.import.importTypeChip',
          description: 'Label for tag import type chip.',
          defaultMessage: 'Type: { type }',
        }, {
          type: option.localisation,
        }),
      )
    ));
  };

  const whenImportOperationTypesChanged = (selection: LocalisedString<ImportOperationTypeString>[]) => {
    setQuery(({ actions, ...query}) => ({
      ...query,
      ...( selection.length ? { actions: selection.map(option => option.id).join(',') } : {} ),
      pageNum: '1',
    }));

    setChips(chips => (
      ChipHelper.updateChipsWithBasicAutocompleteSelection(
        'importOperation',
        chips,
        selection,
        option => intl.formatMessage({
          id: 'tags.import.importOperationChip',
          description: 'Label for import operation chip.',
          defaultMessage: 'Action: { operation }',
        }, {
          operation: option.localisation,
        }),
      )
    ));
  };

  const whenRemoveChipClicked = (chipToRemove: Chip): void => {
    switch (chipToRemove.type) {
      case 'importOperation':
        setChips(chips => chips.filter(chip => chip.type !== 'importOperation' || chipToRemove.id !== chip.id));
        setQuery(({ actions, ...query }) => {
          const newActions = importOperationTypes.filter(type => type !== chipToRemove.id);
          return {
            ...query,
            ...(newActions.length ? { actions: newActions.join(',') } : {}),
            pageNum: '1',
          };
        });
        break;
      case 'importType':
        setChips(chips => chips.filter(chip => chip.type !== 'importType' || chipToRemove.id !== chip.id));
        setQuery(({ recordTypes, ...query }) => {
          const newTypes = tagImportTypes.filter(type => type !== chipToRemove.id);
          return {
            ...query,
            ...(newTypes.length ? { recordTypes: newTypes.join(',') } : {}),
            pageNum: '1',
          };
        });
        break;
    }
  };

  const whenClearFiltersClicked = (): void => {
    setChips([]);
    setQuery({ pageNum: '1' });
  };

  const whenSearchChanged = useCallback((search: string) => {
    setQuery(({ search: oldSearch, ...query }) => ({
      ...query,
      ...(search ? { search } : {}),
      pageNum: '1',
    }));
  }, [setQuery]);

  const whenPageNumChanged = useCallback((pageNum: number) => {
    setQuery(query => ({
      ...query,
      pageNum: `${ pageNum }`,
    }));
  }, [setQuery]);

  return (
    <>
      { importDetail.parseResult?.prepareResult && importDetail.status !== 'completed' && (
        <TagOperationSummaryNotice
          operations={ importDetail.parseResult.prepareResult.operations }
          onImportButtonClicked={ onImportButtonClicked }
        />
      ) }
      <div>
        <TableFilters
          searchValue={ fetchTagImportParams.search || '' }
          onSearchChanged={ whenSearchChanged }
          chips={ chips }
          onRemoveChipClicked={ whenRemoveChipClicked }
          onClearFiltersClicked={ whenClearFiltersClicked }
        >
          <TagImportTypeAutocomplete
            selectedIds={ tagImportTypes }
            onSelectionChanged={ whenTagImportTypesChanged }
          />
          <ImportOperationAutocomplete
            selectedIds={ importOperationTypes }
            onSelectionChanged={ whenImportOperationTypesChanged }
          />
        </TableFilters>
        <PaginatedTable
          rows={ operations }
          headerRow={
            <TableRow>
              <TableCell>
                <FormattedMessage
                  id="tags.import.nameColumn"
                  description="Name column in import summary table."
                  defaultMessage="Name"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  id="tags.import.typeColumn"
                  description="Type column in import summary table."
                  defaultMessage="Type"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  id="tags.import.actionColumn"
                  description="Action column in import summary table."
                  defaultMessage="Action"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  id="tags.import.parentsColumn"
                  description="Parents column in import summary table."
                  defaultMessage="Parents"
                />
              </TableCell>
            </TableRow>
          }
          rowRender={ operation => (
            <TableRow key={ operation.id }>
              <TableCell>
                { operation.tagName }
              </TableCell>
              <TableCell>
              <span
                style={ { textTransform: 'capitalize' } }
              >
                { TagParser.localisedTagTypeFromInternalTagType(operation.tagType, intl, 1) }
              </span>
              </TableCell>
              <TableCell>
                <ImportOperationChip operationType={ operation.operationType }/>
              </TableCell>
              <TableCell maxwidth="20rem">
                <TruncatingContent>
                  {
                    operation.tagParents
                      .sort((a, b) => a.type === 'new' ? b.type === 'new' ? 0 : -1 : 1)
                      .map((parent, index) => (
                        parent.type === 'new'
                          ? (
                            <Tooltip
                              key={ index }
                              title={
                                <FormattedMessage
                                  id="tags.import.newTag"
                                  description="Tooltip for tags that are indicated as new on summary step of tag import."
                                  defaultMessage="This parent tag connection will be created."
                                />
                              }
                            >
                            <span>
                              <StyledTag icon={ <NewTagIcon/> }>
                                { parent.name }
                              </StyledTag>
                            </span>
                            </Tooltip>
                          )
                          : (
                            <StyledTag
                              key={ index }
                              color="none"
                            >
                              { parent.name }
                            </StyledTag>
                          )
                      ))
                  }
                </TruncatingContent>
              </TableCell>
            </TableRow>
          ) }
          pageNum={ fetchTagImportParams.pageNum }
          onPageChanged={ whenPageNumChanged }
          requestState={ fetchTagImportOperationsState }
          onRetryClicked={ reloadFetchTagImportOperations }
          pagination={ pagination }
        />
      </div>
    </>
  );
};

const valueIsOperationType = ArrayHelper.createTypeGuard<ImportOperationTypeString>(['create', 'update']);
const valueIsTagType = ArrayHelper.createTypeGuard<TagType>(['region', 'department', 'team', 'jobtitle', 'skill']);
const operationTypeFilter = ArrayHelper.createTypeFilter(valueIsOperationType);
const tagTypeFilter = ArrayHelper.createTypeFilter(valueIsTagType);
const parseOperationTypes = QueryParser.getCsvParseFn('actions', operationTypeFilter);
const parseTagTypes = QueryParser.getCsvParseFn('recordTypes', tagTypeFilter);

