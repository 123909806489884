import { ChangeEvent, FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { TextField } from '@mui/material';
import { useDebounce } from '@ourpeople/shared/Core/Hook/useDebounce';

import { StyledInputWrapper } from './styles';

type Props = {
  value: string | null;
  disabled?: boolean;
  onChange?: (value: string) => void;
  onBlur?: () => void;
  error?: string | null;
  focusId?: string;
}

export const ColorPicker: FunctionComponent<Props> = ({
  value,
  disabled = false,
  onChange,
  onBlur,
  error,
  focusId,
}) => {
  const [lastValidValue, setLastValidValue] = useState<string>(value && colourIsValid(value) ? value : '#000000');
  const [displayedValue, setDisplayedValue] = useState<string | null>(value);
  const debouncedValue = useDebounce<string | null>(displayedValue, 150);
  const preventEmitRef = useRef(true);

  useEffect(() => {
    setDisplayedValue(value);

    if (value && colourIsValid(value)) {
      setLastValidValue(value);
    }

    preventEmitRef.current = true;
  }, [value, preventEmitRef]);

  useEffect(() => {
    if (!preventEmitRef.current && onChange && debouncedValue && colourIsValid(debouncedValue)) {
      onChange(debouncedValue);
    }
  }, [debouncedValue, preventEmitRef, onChange]);

  const whenInputChanged = (event: ChangeEvent<HTMLInputElement>): void => {
    setDisplayedValue(event.target.value);
    if (colourIsValid(event.target.value)) {
      setLastValidValue(event.target.value);
    }
    preventEmitRef.current = false;
  };

  const whenBlurred = useCallback(() => {
    if (!displayedValue || !colourIsValid(displayedValue)) {
      preventEmitRef.current = true;
      setDisplayedValue(lastValidValue);
    }

    onBlur && onBlur();
  }, [displayedValue, lastValidValue, onBlur]);

  return (
    <div className="colour-picker">
      <StyledInputWrapper>
        <input
          onChange={ whenInputChanged }
          type="color"
          disabled={ disabled }
          value={ lastValidValue }
        />
        <TextField
          id={ focusId }
          error={ !!error }
          size="small"
          color="primary"
          variant="outlined"
          value={ displayedValue || '' }
          disabled={ disabled }
          onChange={ whenInputChanged }
          onBlur={ whenBlurred }
          helperText={ error }
        />
      </StyledInputWrapper>
    </div>
  )
};

const colourIsValid = (colour: string): boolean => !!/^#[A-Fa-f0-9]{6}$/.exec(colour)?.length;
