import { TagCondition } from '../../../../Audiences/Model';
import { TagType } from '../../../../Tags/Model';
import { TagList } from '../TagList';
import { ConditionListComponent } from '../../Model/AudienceConditionDefinition';

export const RegionList: ConditionListComponent<TagCondition<TagType.Region>> = props => (
  <TagList
    tagType={ TagType.Region }
    { ...props }
  />
);
