import { FC, ReactNode } from 'react';

import { useContextOrThrow } from '../../../../Core/Hook';
import { PageLayoutContext } from '../../../Core/Provider/PageLayoutProvider';

type Props = {
  verticalAlign: 'start' | 'end';
  hideOnDesktop?: boolean;
  children?: ReactNode;
  className?: string;
};

export const Toolbar: FC<Props> = ({
  verticalAlign,
  hideOnDesktop,
  className,
  children,
}) => {
  const { viewportBoundaries, modal } = useContextOrThrow(PageLayoutContext)

  return (
    <div
      className={ className }
      css={ theme => ({
        display: 'flex',
        height: '52px',
        backgroundColor: 'rgba(252,252,252,0.85)',
        ...verticalAlign === 'start'
          ? {
            paddingTop: 'max(env(safe-area-inset-top, 0), 4px)',
            paddingBottom: '4px',
            borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
            ...modal
              ? {
                borderTopLeftRadius: theme.new.borderRadius.large,
                borderTopRightRadius: theme.new.borderRadius.large,
                overflow: 'hidden',
              }
              : {},
          }
          : {
            paddingTop: '0',
            paddingBottom: 'max(env(safe-area-inset-bottom, 0), 8px)',
            borderTop: '1px solid rgba(0, 0, 0, 0.05)',
          },
        backdropFilter: 'blur(2px)',
        boxSizing: 'content-box',
        [theme.new.breakpoints.md.up]: {
          ...hideOnDesktop
            ? {
              display: 'none',
              ...verticalAlign === 'start'
                ? { transform: 'translateY(-100%)' }
                : { transform: 'translateY(100%)' },
            }
            : {},
          ...verticalAlign === 'end' && modal
            ? {
              borderBottomLeftRadius: theme.new.borderRadius.large,
              borderBottomRightRadius: theme.new.borderRadius.large,
              overflow: 'hidden',
            }
            : {},
        },
        ...verticalAlign === 'start'
          ? { top: 0 }
          : { bottom: 0 },
        position: verticalAlign === 'start'
          ? viewportBoundaries.top ? 'fixed' : 'absolute'
          : verticalAlign === 'end'
            ? viewportBoundaries.bottom && !modal ? 'fixed' : 'absolute'
            : 'fixed',
        left: 'var(--toolbar-offset, var(--sidebar-width))',
        width: 'var(--toolbar-width, calc(100% - var(--sidebar-width)))',
      }) }
    >
      <div
        css={ {
          width: '100%',
          paddingLeft: 'max(env(safe-area-inset-left, 0), 8px)',
          paddingRight: 'max(env(safe-area-inset-right, 0), 8px)',
          display: 'flex',
          ...verticalAlign === 'start'
            ? { alignItems: 'center' }
            : { alignItems: 'flex-end' }
        } }
      >
        { children }
      </div>
    </div>
  );
};
