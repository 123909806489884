import { ChangeEventHandler, FunctionComponent, useCallback, useState } from 'react';

import { StyledContent, StyledTextField } from './style';

type Props = {
  value: string;
  onChange: (value: string) => void;
  edited?: boolean;
}

export const EditableContent: FunctionComponent<Props> = ({
  value,
  onChange,
  edited,
  children,
}) => {
  const [editing, setEditing] = useState<boolean>(false);

  const whenChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (onChange) {
      onChange(event.currentTarget.value);
    }
  };

  const whenContentClicked = useCallback(() => {
    setEditing(true);
  }, []);

  return (
    <>
      {
        editing
          ? (
            <StyledTextField
              autoFocus
              value={ value }
              onChange={ whenChange }
              onBlur={ () => setEditing(false) }
              variant="outlined"
              margin="dense"
            />
          )
          : (
            <StyledContent
              edited={ edited }
              editable={ !!onChange }
              role={ !!onChange ? 'button' : '' }
              onClick={ whenContentClicked }
            >
              { children }
            </StyledContent>
          )
      }
    </>
  );
};
