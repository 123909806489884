import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';

import { StatusChip } from '../../../Common/Component';
import {
  ContentType,
  CoverStatusDetail,
  CoverAssignedStatusDetail,
  MeetingAvailableStatusDetail,
  MeetingEmptyStatusDetail,
  MeetingFullStatusDetail,
  MeetingStatusDetail,
  TrainingAvailableStatusDetail,
  TrainingEmptyStatusDetail,
  TrainingFullStatusDetail,
  TrainingStatusDetail
} from '../../../Models';

interface EventStatusProps {
  contentType: ContentType;
  status: CoverStatusDetail | MeetingStatusDetail | TrainingStatusDetail;
  withName: boolean;
}

interface CoverEventStatusProps {
  status: CoverStatusDetail;
  withName: boolean;
}

interface MeetingEventStatusProps {
  status: MeetingStatusDetail;
}

interface TrainingEventStatusProps {
  status: TrainingStatusDetail;
}

export const EventStatus: FunctionComponent<EventStatusProps> = ({contentType, status, withName}) => {
  switch (contentType) {
    case 'cover':
      return <CoverEventStatus withName={withName} status={status as CoverStatusDetail} />;
    case 'meeting':
      return <MeetingEventStatus status={status as MeetingStatusDetail} />;
    case 'training':
      return <TrainingEventStatus status={status as TrainingStatusDetail} />;
    default:
      return <></>;
  }
};

export const CoverEventStatus: FunctionComponent<CoverEventStatusProps> = ({status, withName}) => {
  const intl = useIntl();
  switch (status.status) {
    case 'assigned':
      return (
        <StatusChip
          status="success"
          label={
            withName
            ? (
              intl.formatMessage({
                id: 'coverStatus.assigned',
                defaultMessage: 'Assigned to {name}',
              }, {
                name: (status as CoverAssignedStatusDetail).metadata.assignedTo.name
              })
            )
            : (
              intl.formatMessage({
                id: 'coverStatus.assigned',
                defaultMessage: 'Assigned',
              })
            )
          } />
      );
    case 'cancelled':
      return (
        <StatusChip
          status="none"
          label={
            intl.formatMessage({
              id: 'coverStatus.cancelled',
              defaultMessage: 'Cancelled',
            })
          } />
      );
    case 'expired':
      return (
        <StatusChip
          status="none"
          label={
            intl.formatMessage({
              id: 'coverStatus.expired',
              defaultMessage: 'Expired',
            })
          } />
      );
    case 'pending':
      return (
        <StatusChip
          status="error"
          label={
            intl.formatMessage({
              id: 'coverStatus.pending',
              defaultMessage: 'No Requests',
            })
          } />
      );
    case 'requested':
      return (
        <StatusChip
          status="warning"
          label={
            intl.formatMessage({
              id: 'coverStatus.requested',
              defaultMessage: 'Requests Pending',
            })
          } />
      );
    default:
      return <></>;
  }
};

const MeetingEventStatus: FunctionComponent<MeetingEventStatusProps> = ({status}) => {
  const intl = useIntl();
  switch (status.status) {
    case 'available':
      return (
        <StatusChip
          status="success"
          label={
            intl.formatMessage({
              id: 'meetingStatus.available',
              defaultMessage: '{remainingSpaces, plural, one {# space} other {# spaces}} left',
            }, {
              remainingSpaces: (status as MeetingAvailableStatusDetail).metadata.remainingSpaces,
            })
          } />
      );
    case 'cancelled':
      return (
        <StatusChip
          status="none"
          label={
            intl.formatMessage({
              id: 'meetingStatus.cancelled',
              defaultMessage: 'Cancelled',
            })
          } />
      );
    case 'completed':
      return (
        <StatusChip
          status="success"
          label={
            intl.formatMessage({
              id: 'meetingStatus.completed',
              defaultMessage: 'Completed',
            })
          } />
      );
    case 'empty':
      return (
        <StatusChip
          status="success"
          label={
            intl.formatMessage({
              id: 'meetingStatus.empty',
              defaultMessage: '{remainingSpaces, plural, one {# space} other {# spaces}} left',
            }, {
              remainingSpaces: (status as MeetingEmptyStatusDetail).metadata.remainingSpaces,
            })
          } />
      );
    case 'full':
      return (
        <StatusChip
          status="warning"
          label={
            intl.formatMessage({
              id: 'meetingStatus.full',
              defaultMessage: 'Full, {waitingCount} on waitlist',
            }, {
              waitingCount: (status as MeetingFullStatusDetail).metadata.waitingCount,
            })
          } />
      );
    default:
      return <></>;
  }
};

const TrainingEventStatus: FunctionComponent<TrainingEventStatusProps> = ({status}) => {
  const intl = useIntl();
  switch (status.status) {
    case 'available':
      return (
        <StatusChip
          status="success"
          label={
            intl.formatMessage({
              id: 'trainingStatus.available',
              defaultMessage: '{remainingSpaces, plural, one {# space} other {# spaces}} left',
            }, {
              remainingSpaces: (status as TrainingAvailableStatusDetail).metadata.remainingSpaces,
            })
          } />
      );
    case 'cancelled':
      return (
        <StatusChip
          status="none"
          label={
            intl.formatMessage({
              id: 'trainingStatus.cancelled',
              defaultMessage: 'Cancelled',
            })
          } />
      );
    case 'completed':
      return (
        <StatusChip
          status="success"
          label={
            intl.formatMessage({
              id: 'trainingStatus.completed',
              defaultMessage: 'Completed',
            })
          } />
      );
    case 'empty':
      return (
        <StatusChip
          status="success"
          label={
            intl.formatMessage({
              id: 'trainingStatus.empty',
              defaultMessage: '{remainingSpaces, plural, one {# space} other {# spaces}} left',
            }, {
              remainingSpaces: (status as TrainingEmptyStatusDetail).metadata.remainingSpaces,
            })
          } />
      );
    case 'full':
      return (
        <StatusChip
          status="warning"
          label={
            intl.formatMessage({
              id: 'trainingStatus.full',
              defaultMessage: 'Full, {waitingCount} on waitlist',
            }, {
              waitingCount: (status as TrainingFullStatusDetail).metadata.waitingCount,
            })
          } />
      );
    default:
      return <></>;
  }
};
