import { useMemo } from 'react';

import { EnvironmentSettings } from '../../Models';
import { useLoggedInAuthDescription } from '../../Core/Hook';

export const useEnvironmentSettings = (): EnvironmentSettings => {
  const authDescription = useLoggedInAuthDescription();

  return useMemo(() => authDescription.env_settings, [authDescription.env_settings]);
};
