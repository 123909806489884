import { Node, Literal, Parent } from 'unist';
import rehype from 'rehype';
import { PlaceholderFinder } from '@ourpeople/shared/Core/Utility/PlaceholderFinder';

export class RichTextParser {
  public static getFirstNonEmptyNodeTextContent = (text: string, keepPlaceholders: boolean = false): string | undefined => {
    const node = rehype().parse(text);
    return getFirstTextNodeValue(node, keepPlaceholders);
  };

  public static getDocumentFromString = (value: string): Document => {
    const parser = new DOMParser();
    const dom = parser.parseFromString(value, 'text/html');
    const body = dom.body as HTMLBodyElement | undefined;

    const wrapTextNode = (textNode: Text): HTMLParagraphElement => {
      const wrapper = dom.createElement('p');
      wrapper.appendChild(textNode);
      return wrapper;
    };

    const rootNodes = Array.from(body?.childNodes || []).map(node => (
      node.nodeName === '#text' && node.textContent && !/^\n$/.exec(node.textContent)
        ? wrapTextNode(node as Text)
        : node
    ));

    if (body) {
      body.innerText = '';
      rootNodes.forEach(node => (
        body.appendChild(node)
      ));
    }

    return dom;
  }
}

const getFirstTextNodeValue = (tree: Node, keepPlaceholders: boolean = false): string | undefined => {
  const literal = tree as Literal;
  const parent = tree as Parent;
  if (tree.type === 'text' && literal.value && typeof literal.value === 'string' && literal.value.trim()) {
    return literal.value.trim();
  } else if (parent.children) {
    for (const child of parent.children) {
      const childValue = keepPlaceholders
        ? getFirstTextNodeValue(child, keepPlaceholders)
        : PlaceholderFinder.strip(getFirstTextNodeValue(child) || '');

      if (childValue) {
        return childValue;
      }
    }
  }
};
