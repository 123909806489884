import { ReactNode } from 'react';
import { FormattedList, FormattedMessage, useIntl } from 'react-intl';

import { ValidationError } from '../../../Common/Model';
import { ValidationErrorMessage } from '../../../Common/Component';
import { IllegalTagMappingTypeError } from '../../Model';
import { TagParser } from '../../Utility';
import { ImportErrorPresentationComponent } from '../../../Imports/Model';

export const TagImportColumnErrors: ImportErrorPresentationComponent = ({ errors }) => {
  const intl = useIntl();

  const getErrorMessage = (error: ValidationError): ReactNode => {
    switch (error.type) {
      case 'notMapped':
        return (
          <FormattedMessage
            id="tags.import.notMappedError"
            description="Error when import column could not be mapped to a tag type."
            defaultMessage="A tag type could not be determined for this column, please update it manually."
          />
        );
      case 'illegalMappingType':
        return (
          <FormattedMessage
            id="tags.import.illegalMappingTypeError"
            description="Error when column has a tag type not available on users plan."
            defaultMessage="Your current plan does not include { illegalType } tags. Please make sure your import data only contains { allowedTypeCount, plural, one { a column } other { columns } } for { allowedTypes }."
            values={ {
              illegalType: TagParser.localisedTagTypeFromInternalTagType((error as IllegalTagMappingTypeError).metadata.type, intl, 1),
              allowedTypeCount: (error as IllegalTagMappingTypeError).metadata.allowedTypes.length,
              allowedTypes: (
                <FormattedList
                  type="conjunction"
                  value={ (error as IllegalTagMappingTypeError).metadata.allowedTypes.map(tagType => (
                    TagParser.localisedTagTypeFromInternalTagType(tagType, intl, Infinity)
                  )) }
                />
              )
            } }
          />
        );
      default:
        return (
          <FormattedMessage
            id="tags.import.fallbackColumnError"
            description="Fallback column error when error can not be identified."
            defaultMessage="There is a problem with this column."
          />
        );
    }
  };

  return (
    <>
      { errors.map(error => (
        <ValidationErrorMessage
          key={ error.type }
        >
          { getErrorMessage(error) }
        </ValidationErrorMessage>
      )) }
    </>
  );
};
