import { FetchResponse, usePost } from '../../Hooks';
import { Audience } from '../../Audiences/Model';
import { Paginated, PersonWithStatus } from '../../Models';
import { FetchAudienceMembersSort } from '../../Audiences/Hook';

export type ChatStatus = 'pending' | 'joined' | 'banned' | 'removed' | 'left';

export type FetchChatAudienceMembersParams = {
  audience?: Audience | null;
  query: {
    pageNum: number;
    sort: FetchAudienceMembersSort[];
    chatStatuses?: ChatStatus[];
    search?: string;
  };
}

type ChatAudienceMember = {
  person: PersonWithStatus;
  chatStatus: ChatStatus;
  note: string | null;
};

type FetchChatAudienceMembersResponse = FetchResponse<Paginated<'members',
  ChatAudienceMember> & {
  audienceSize: number;
  audienceChatStatusCounts: {
    pending: number;
    joined: number;
    banned: number;
    removed: number;
    left: number;
  };
}>

export const useFetchChatAudienceMembers = (
  chatId: string,
  params: FetchChatAudienceMembersParams,
): FetchChatAudienceMembersResponse => usePost(
  `/chats/${ chatId }/list-audience-members`,
  params,
);
