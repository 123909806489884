import styled from '@emotion/styled';

export const StyledHeadingOne = styled.h1`
  color: ${props => props.theme.palette.text.primary};
  font-size: 1.6rem;
  font-weight: 500;
  margin: 0;
`;

export const StyledHeadingTwo = styled.h2`
  color: ${props => props.theme.palette.text.primary};
  font-size: 1.4rem;
  font-weight: 500;
  margin: 0;
`;

export const StyledHeadingThree = styled.h3`
  color: ${props => props.theme.palette.text.primary};
  font-size: 1.2rem;
  font-weight: 500;
  margin: 0;
`;

export const StyledHeadingFour = styled.h4`
  color: ${props => props.theme.palette.text.primary};
  font-size: 1.1rem;
  font-weight: 500;
  margin: 0;
`;

export const StyledHeadingFive = styled.h5`
  color: ${props => props.theme.palette.text.primary};
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
`;

export const StyledHeadingSix = styled.h6`
  color: ${props => props.theme.palette.text.primary};
  font-size: 0.9rem;
  font-weight: 500;
  margin: 0;
`;
