import { Literal, Node, Parent } from 'unist';
import rehype from 'rehype';

import { MinimalContent, Content, ContentType, StyledImageContent, TextContent } from '../Models';

/**
 * @deprecated See individual method deprecation notices
 */
export class ContentReader {
  /**
   * @deprecated Prefer identifying content using ContentDefinition.definesType(), then return ContentDefinition.id
   */
  public static contentTypeFromString = (contentTypeString: string): ContentType => {
    switch (contentTypeString) {
      case 'audio':
      case 'audiocontent':
        return 'audio';
      case 'multiple_choice_question':
      case 'multiplechoicequestion':
        return 'multiplechoice';
      case 'single_choice_question':
      case 'singlechoicequestion':
        return 'singlechoice';
      case 'textquestion':
      case 'text_question':
        return 'comment';
      case 'cover':
      case 'covercontent':
      case 'shiftcontent':
        return 'cover';
      case 'file':
      case 'filecontent':
        return 'file';
      case 'image':
      case 'imagecontent':
        return 'image';
      case 'event':
      case 'eventcontent':
      case 'meeting':
      case 'meetingcontent':
        return 'meeting';
      case 'quizquestion':
      case 'quiz_question':
        return 'quiz';
      case 'ratingquestion':
      case 'rating_question':
        return 'rating';
      case 'reveal':
      case 'revealcontent':
        return 'reveal';
      case 'sign':
      case 'signcontent':
        return 'sign';
      case 'styled_image':
      case 'styledimage':
      case 'styledimagecontent':
        return 'styled_image';
      case 'quickmessage':
      case 'quickmessagecontent':
      case 'quick_message':
      case 'text':
      case 'textcontent':
        return 'text';
      case 'timelinecontent':
        return 'stack';
      case 'training':
      case 'trainingcontent':
        return 'training';
      case 'upload_image':
      case 'uploadimage':
      case 'uploadimagecontent':
        return 'upload_image';
      case 'video':
      case 'videocontent':
        return 'video';
      default:
        return 'unknown';
    }
  }

  /**
   * @deprecated Prefer identifying content using ContentDefinition.definesContentType(), then return ContentDefinition.id
   */
  public static contentType(content: MinimalContent): ContentType {
    return ContentReader.contentTypeFromString(content.type);
  }

  /**
   * @deprecated Prefer ContentDefinitionRegistry.getContentTitle()
   */
  public static titleForContent(content: MinimalContent): string|null {
    const type = ContentReader.contentType(content);
    switch (type) {
      case 'styled_image':
        const possibleTitle = ((content as StyledImageContent).caption || {}).text;
        return possibleTitle
          ? possibleTitle.replace(/&nbsp;/gm, ' ')
          : null;
      case 'text':
        return (content as TextContent).title || extractTitleFromHTML((content as TextContent).text || '');
      case 'comment':
      case 'cover':
      case 'upload_image':
      case 'audio':
      case 'file':
      case 'image':
      case 'meeting':
      case 'reveal':
      case 'sign':
      case 'training':
      case 'video':
      case 'multiplechoice':
      case 'quiz':
      case 'rating':
      case 'singlechoice':
      default:
        return ((content as Content).text || (content as Content).title) || null;
    }
  }
}

const extractTitleFromHTML = (text: string): string => {
  // Use rehype to parse the HTML into a syntax tree,
  // then recursively search for the first text node
  const ast = rehype().parse(text);
  return extractTitleFromAst(ast);
};

const extractTitleFromAst = (ast: Node): string => {
  const findTextNode = (tree: Node): string => {
    if (!tree) {
      return '';
    }
    if (tree.type === 'text' && tree.value) {
      const text = typeof (tree as Literal).value === 'string'
        ? (tree as Literal).value as string
        : '';
      return text.split(/\n/)[0] || '';
    }
    if (tree.type === 'html' && tree.value) {
      return typeof (tree as Literal).value === 'string'
        ? extractTitleFromHTML((tree as Literal).value as string)
        : '';
    }
    if ((tree as Parent).children) {
      for (const child of (tree as Parent).children) {
        const childValue = findTextNode(child);
        if (childValue) {
          return childValue;
        }
      }
    }
    return '';
  };
  return findTextNode(ast) || '';
};
