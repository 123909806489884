import { FunctionComponent, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { ContentWithOptions, ContentOption } from '../../../../../Models';
import { ContentReader } from '../../../../../Readers';
import { CardReport, RespondedCoverCount } from '../../Hooks/useFetchBroadcastReportSummary';
import { useNotRespondedLabelForContentType } from '../../Hooks/useNotRespondedLabelForContentType';
import { DeliveryReportTab, DeliveryReportTabs, DeliveryReportTabContainer, DeliveryReportTabContent } from './';
import { NotRespondedTable } from '../Tables/NotRespondedTable';
import { CoverRespondedTable } from '../Tables/CoverRespondedTable';

interface Props {
  broadcastId: string;
  deliveryId: string;
  card: CardReport;
  pendingCount: number;
  initialSearch?: string;
}

export const CoverContentReportTabs: FunctionComponent<Props> = ({
  card,
  broadcastId,
  deliveryId,
  pendingCount,
  initialSearch,
}) => {
  const intl = useIntl();

  const labelForOption = useCallback((option: ContentOption): string => {
    switch (keyForOption(option)) {
      case 'accepted':
        return intl.formatMessage({
          id: 'cover.requested',
          defaultMessage: 'Requested'
        });
      case 'declined':
        return intl.formatMessage({
          id: 'cover.declined',
          defaultMessage: 'Declined'
        });
      default:
        return option.text;
    }
  }, [intl]);

  const keyForOption = (option: ContentOption): 'accepted' | 'declined' => {
    return (
      option.text.toLowerCase() === 'accept'
        ? 'accepted'
        : 'declined'
    );
  };

  const countForOption = useCallback((option: ContentOption): number => {
    const key = keyForOption(option);
    return (card.counts.responded as RespondedCoverCount)[key] || 0;
  }, [card.counts.responded]);

  return (
    <DeliveryReportTabContainer
      tabs={
        <DeliveryReportTabs>
          {
            (card.content as ContentWithOptions).options.map((option) => (
              <DeliveryReportTab
                key={ `tabOption${ option.id }` }
                label={ labelForOption(option) }
                value={ countForOption(option) }
              />
            ))
          }
          <DeliveryReportTab
            label={ useNotRespondedLabelForContentType(
              ContentReader.contentTypeFromString(card.content.type)
            ) }
            value={ (card.counts.notResponded?.total || 0) - pendingCount }
            lowLight={ true }
          />
        </DeliveryReportTabs>
      }
    >
      {
        (card.content as ContentWithOptions).options.map((option) => (
          <DeliveryReportTabContent key={ `tableOption${ option.id }` }>
            <CoverRespondedTable
              deliveryId={ deliveryId }
              broadcastId={ broadcastId }
              optionId={ option.id }
              optionType={ keyForOption(option) }
              content={ card.content }
              showMenu={ card.status?.status !== 'assigned' }
              initialSearch={ initialSearch }
            />
          </DeliveryReportTabContent>
        ))
      }
      <DeliveryReportTabContent>
        <NotRespondedTable
          deliveryId={ deliveryId }
          broadcastId={ broadcastId }
          contentId={ card.content.id }
          responseType="notResponded"
          initialSearch={ initialSearch }
        />
      </DeliveryReportTabContent>
    </DeliveryReportTabContainer>
  );
};
