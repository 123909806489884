import styled from '@emotion/styled';
import { rgba } from 'polished';

export const StyledMenuContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
`;

export const StyledMobileNavigation = styled.div<{ open: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;

  ${ props => props.open
    ? ''
    : 'pointer-events: none'
  };
`;

export const StyledMenuBar = styled.div`
  height: ${ props => props.theme.new.spacing[16] };
  padding: ${ props => props.theme.new.spacing[2] };
  width: 100%;
  background-color: ${ props => props.theme.new.palette.teal[700].main };
`;

export const StyledLogo = styled.img`
  width: ${ props => props.theme.new.spacing[10] };
  height: ${ props => props.theme.new.spacing[10] };
  border-radius: ${ props => props.theme.new.borderRadius.standard };
`;

export const StyledCloseButton = styled.button`
  background-color: transparent;
  border: none;
  border-radius: ${ props => props.theme.new.borderRadius.standard };
  color: ${ props => props.theme.new.basePalette.white.main };
  pointer-events: all;
  padding: ${ props => props.theme.new.spacing[4] };

  &:active, &:hover {
    background-color: ${ props => rgba(props.theme.new.basePalette.white.main, 0.1) };
  }
`;

export const StyledCloseButtonContainer = styled.div`
  position: absolute;
  z-index: 1;
  top: ${ props => props.theme.new.spacing[1] };
  right: ${ props => props.theme.new.spacing[1] };
`;
