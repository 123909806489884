import { IntlShape } from 'react-intl';

import {
  DraftUploadRequest,
  UPLOAD_REQUEST_MAX_UPLOADS_DEFAULT,
  UPLOAD_REQUEST_MIN_UPLOADS_MIN,
  UploadRequest
} from '../../Model/ContentTypes';
import { SingleContentCard, EditorContent } from '../../../Content/Model';
import { CardFactory, CardTransformer } from '../../../Content/Utility';
import { UniqueIdGenerator } from '../../../Common/Utility';
import { UploadRequestFields, UploadRequestPreview } from '../../Component/Content';
import UploadRequestIcon from '../../../Assets/img/icons/monochrome/content/upload-image.svg';
import { UploadRequestValidator } from '../../Utility/Validation';
import { DraftResponse, FormContentDefinition, Response } from '../../Model';
import { EnvironmentSettings } from '../../../Models';
import { LiveUploadRequest } from '../../Component';
import { SubmittedUploadRequest } from '../../Component/SubmittedContent';

export type UploadRequestEditorContent = EditorContent<DraftUploadRequest>;

export type DraftUploadResponse = DraftResponse<{
  contentType: 'uploadRequest';
  uploadIds: string[];
}>;
export type UploadResponse = Response<{
  contentType: 'uploadRequest';
  uploads: string[];
}>;

export class UploadRequestDefinition implements FormContentDefinition<
  UploadRequest,
  UploadRequestEditorContent,
  DraftUploadResponse,
  UploadResponse
> {
  public readonly id = 'uploadRequest';
  public readonly availableToCreate = true;
  public readonly new = false;
  public readonly richText = false;
  public readonly contentTypes = ['uploadRequest'];
  public readonly EditorComponent = UploadRequestFields;
  public readonly PreviewComponent = UploadRequestPreview;
  public readonly LiveComponent = LiveUploadRequest;
  public readonly SubmittedComponent = SubmittedUploadRequest;
  public readonly IconComponent = UploadRequestIcon;
  public readonly categoryId = 'input';

  public definesContentType = (type: string): boolean => (
    this.contentTypes.includes(type)
  );

  public createEditorContent = (): UploadRequestEditorContent => ({
    id: UniqueIdGenerator.generate(),
    card: CardFactory.fromDraftContent({
      type: this.id,
      title: '',
      text: null,
      accept: 'image/!*',
      minUploads: UPLOAD_REQUEST_MIN_UPLOADS_MIN,
      maxUploads: UPLOAD_REQUEST_MAX_UPLOADS_DEFAULT,
    }),
  });

  public getContentTitle = (intl: IntlShape, content: UploadRequest): string => (
    content.title || this.getDefaultTitle(intl)
  );

  public getDraftContentTitle = (intl: IntlShape, draftContent: DraftUploadRequest): string => (
    draftContent.title || this.getDefaultTitle(intl)
  );

  public hasRequiredFeatureFlags = (environmentSettings: EnvironmentSettings): boolean => (
    !!environmentSettings.formsEnabled
  );

  public getLocalisedType = (intl: IntlShape): string => intl.formatMessage({
    id: 'forms.content.uploadRequest.type',
    description: 'Localised name for upload request type.',
    defaultMessage: 'Upload',
  });

  public transformCard = (card: SingleContentCard<UploadRequest>): UploadRequestEditorContent => ({
    id: card.id,
    card: CardTransformer.transformCard(card, card.content),
  });

  public validate = UploadRequestValidator.validate;

  private getDefaultTitle = (intl: IntlShape): string => intl.formatMessage({
    id: 'forms.content.uploadRequest.defaultTitle',
    description: 'Default title for upload request.',
    defaultMessage: 'Untitled upload',
  });
}
