import { parseISO } from 'date-fns';

import { ValidationTree, Validator } from '../../../../Common/Model';
import { DraftDate, DraftEvent } from '../../../Model';
import { Validate } from '../../../../Common/Utility';

export class EventValidator {
  public static validate = (event: DraftEvent, minTextLength: number, maxTextLength: number): ValidationTree<DraftEvent> => ({
    errors: [],
    children: {
      text: EventValidator.validateText(event.text, minTextLength, maxTextLength),
      start_at: EventValidator.validateStartDate(event.start_at.dt),
      end_at: EventValidator.validateEndDate(event.end_at.dt, event.start_at.dt),
    },
  });

  public static validateStartDate = (startDate: string): ValidationTree<DraftDate> => ({
    errors: startDateValidator.validate(startDate),
    children: {},
  });

  public static validateEndDate = (endDate: string, startDate: string): ValidationTree<DraftDate> => {
    const endDateValidator = new Validator<string>([
      Validate.notBlank,
      date => Validate.dateAfter(parseISO(date), parseISO(startDate)),
    ]);

    return {
      errors: endDateValidator.validate(endDate),
      children: {},
    };
  };

  public static validateText = (text: string, minLength: number, maxLength: number): ValidationTree<string> => {
    const textValidator = new Validator<string>([
      Validate.notBlank,
      text => Validate.minLength(text, minLength),
      text => Validate.maxLength(text, maxLength),
    ]);

    return {
      errors: textValidator.validate(text),
      children: {},
    };
  }
}

const startDateValidator = new Validator<string>([
  Validate.notBlank,
  date => Validate.dateAfter(parseISO(date), new Date()),
]);
