import styled from '@emotion/styled';

export const StyledDraggableNavItem = styled.div<{ dragging: boolean }>`
  display: flex;
  gap: ${ props => props.theme.spacing(2) };
  padding: 0 ${ props => props.theme.spacing(2) };
  transition: border 0.2s;
  border: 2px solid ${ props => props.theme.palette.grey[300] };
  border-radius: ${ props => props.theme.spacing(1) };
  align-items: center;
  user-select: none;
  background-color: ${ props => props.theme.palette.background.paper };
  cursor: grab;

  svg {
    margin-left: auto;
  }

  &:hover {
    border: 2px solid ${ props => props.theme.palette.primary.main };

    svg {
      color: ${ props => props.theme.palette.primary.main };
    }
  }

  ${ props => props.dragging && `
    cursor: grabbing;
    border: 2px solid ${ props.theme.palette.primary.main };

    svg {
      color: ${ props.theme.palette.primary.main };
    }
  ` }
`;

export const StyledInputColumn = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    height: ${ props => props.theme.spacing(8) };
    margin-bottom: ${ props => props.theme.spacing(2) };
  }
`;

export const StyledIndexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${ props => props.theme.spacing(2) };

  > * {
    height: ${ props => props.theme.spacing(8) };
    display: flex;
    justify-content: center;
    align-items: center;

    > div {
      width: ${ props => props.theme.spacing(3) };
      height: ${ props => props.theme.spacing(3) };
      border-radius: 99rem;
      background-color: ${ props => props.theme.palette.primary.main };
      color: ${ props => props.theme.palette.primary.contrastText };
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const StyledInput = styled.div`
  display: grid;
  grid-template-columns: ${ props => props.theme.spacing(3) } 1fr;
  gap: ${ props => props.theme.spacing(2) };
`;

export const StyledTabBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
`;

export const StyledPreviewContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${ props => props.theme.spacing(2) };
  justify-content: space-around;
  flex: 1 1 720px;
  flex-wrap: wrap;
`;

export const StyledForm = styled.div`
  min-width: ${ props => props.theme.spacing(40) };
  display: flex;
  flex-direction: column;
  gap: ${ props => props.theme.spacing(2) };
  flex: 20 1 0;

  ${ props => props.theme.breakpoints.down('md') } {
    width: 100%;
  }
`;

export const StyledEmptySlotLabel = styled.span`
  font-style: italic;
  color: ${ props => props.theme.palette.grey[500] };
`;

