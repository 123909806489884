import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';

import { StyledSuccessIcon, UploadContainer, UploadedImageContainer } from './style';
import SuccessIcon from '../../../Assets/img/icons/streamline/success.svg';
import RemoveIcon from '../../../Assets/img/icons/streamline/cross.svg';

type Props = {
  src: string;
  alt: string;
  onRemoveClicked: () => void;
};

export const UploadPreview: FC<Props> = ({
  src,
  alt,
  onRemoveClicked,
}) => (
  <UploadContainer>
    <UploadedImageContainer>
      <img
        src={ src }
        alt={ alt }
      />
    </UploadedImageContainer>
    <StyledSuccessIcon>
      <SuccessIcon />
    </StyledSuccessIcon>
    <span>
      <FormattedMessage
        id="form.uploadRequest.preview.uploadComplete"
        defaultMessage="Upload complete"
      />
    </span>
    <Button onClick={ onRemoveClicked }>
      <RemoveIcon />
    </Button>
  </UploadContainer>
);
