import { DraftMeeting } from '../../ContentDefinition';
import { ValidationTree, Validator } from '../../../../Common/Model';
import { EventValidator } from '../EventValidator/EventValidator';
import { Validate } from '../../../../Common/Utility';

export class MeetingValidator {
  public static validate = (meeting: DraftMeeting): ValidationTree<DraftMeeting> => ({
    errors: [],
    children: {
      ...EventValidator.validate(meeting, MEETING_TEXT_MIN_LENGTH, MEETING_TEXT_MAX_LENGTH).children,
      max_attendees: MeetingValidator.validateMaxAttendees(meeting.max_attendees),
      location: MeetingValidator.validateLocation(meeting.location),
    },
  });

  public static validateMaxAttendees = (maxAttendees: number): ValidationTree<number> => ({
    errors: maxAttendeesValidator.validate(maxAttendees),
    children: {},
  });

  public static validateLocation = (location: string): ValidationTree<string> => ({
    errors: locationValidator.validate(location),
    children: {},
  });
}

export const MEETING_TEXT_MIN_LENGTH = 1;
export const MEETING_TEXT_MAX_LENGTH = 40;
export const MEETING_LOCATION_MIN_LENGTH = 1;
export const MEETING_LOCATION_MAX_LENGTH = 40;
export const MEETING_MAX_ATTENDEES_MIN = 1;

const maxAttendeesValidator = new Validator<number>([
  maxAttendees => Validate.greaterThanEq(maxAttendees, MEETING_MAX_ATTENDEES_MIN),
]);

const locationValidator = new Validator<string>([
  Validate.notBlank,
  location => Validate.minLength(location, MEETING_LOCATION_MIN_LENGTH),
  location => Validate.maxLength(location, MEETING_LOCATION_MAX_LENGTH),
]);

