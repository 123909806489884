import styled from '@emotion/styled';

import { MetricContainer } from '../MetricContainer/MetricContainer';

export const StyledMetricCategory = styled.div<{ itemCount: number }>`
  display: flex;
  flex-direction: column;
  gap: ${ props => props.theme.spacing(2) };
  flex-grow: ${ props => props.itemCount };
`;

export const StyledMetricContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  box-shadow: ${ props => props.theme.shadows[3] };
`;

export const StyledHeading = styled.h2`
  margin: 0;
  padding: 0;
  font-size: ${ props => props.theme.spacing(3) };
  font-weight: 500;
`;

export const StyledMetric = styled(MetricContainer)`
  box-shadow: none;
  border-bottom: 1px solid ${ props => props.theme.palette.grey[200] };

  &:not(:last-of-type) {
    border-right: 1px solid ${ props => props.theme.palette.grey[200] };
  }
`;
