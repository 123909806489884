import { FormattedMessage } from 'react-intl';
import { FunctionComponent, useEffect, useState } from 'react';

import { DeprecatedButton } from '../../../../Components';
import { StyledUploadRequestButtonContainer, } from './style';
import DogImage from '../../../../Assets/img/sample/dog.jpg';
import CatImage from '../../../../Assets/img/sample/cat.jpg';
import FrogImage from '../../../../Assets/img/sample/frog.jpg';
import { SelectionRange } from '../SelectionRange/SelectionRange';
import { ArrayHelper } from '../../../../Common/Utility';
import { ContentPreviewProps } from '../../../../Content/Model';
import { UploadRequestEditorContent } from '../../../Service';
import { UploadPreview } from '../../../../Content/Component';
import { VerticallySpaced } from '../../../../Common/Component';

interface SampleImage {
  src: string;
  alt: string;
}

const sampleImages: SampleImage[] = [
  {
    src: DogImage,
    alt: 'Dog wearing sun glasses',
  },
  {
    src: CatImage,
    alt: 'Cat looking over its shoulder',
  },
  {
    src: FrogImage,
    alt: 'Frog on a leaf',
  },
];

export const UploadRequestPreview: FunctionComponent<ContentPreviewProps<UploadRequestEditorContent>> = ({
  editorContent,
}) => {
  const [uploads, setUploads] = useState<SampleImage[]>([]);

  useEffect(
    () => {
      if (uploads.length <= editorContent.card.content.maxUploads) {
        return;
      }

      setUploads(uploads.slice(0, editorContent.card.content.maxUploads));
    },
    [editorContent.card, uploads],
  );

  const whenSelectFileButtonClicked = (): void => {
    setUploads([
      ...uploads,
      sampleImages[Math.floor(Math.random() * sampleImages.length)],
    ]);
  }

  const whenRemoveUploadButtonClicked = (index: number): void => {
    const updatedUploads = ArrayHelper.remove(uploads, index);
    setUploads(updatedUploads);
  }

  return <>
    <SelectionRange
      min={ editorContent.card.content.minUploads > 1 ? editorContent.card.content.minUploads : null }
      max={ editorContent.card.content.maxUploads }
      singular={
        <FormattedMessage
          id="form.uploadRequest.preview.upload.singular"
          defaultMessage="upload"
        />
      }
      plural={
        <FormattedMessage
          id="form.uploadRequest.preview.upload.plural"
          defaultMessage="uploads"
        />
      }
    />
    <VerticallySpaced gap={ 2 }>
      { uploads.map((upload, index) => (
        <UploadPreview
          key={ index }
          src={ upload.src }
          alt={ upload.alt }
          onRemoveClicked={ () => whenRemoveUploadButtonClicked(index) }
        />
      )) }
    </VerticallySpaced>
    <StyledUploadRequestButtonContainer>
      <DeprecatedButton
        primary={ true }
        onClick={ whenSelectFileButtonClicked }
        disabled={ uploads.length >= editorContent.card.content.maxUploads }
      >
        <FormattedMessage
          id="form.uploadRequest.preview.button"
          defaultMessage="Select file"
        />
      </DeprecatedButton>
    </StyledUploadRequestButtonContainer>
  </>;
};
