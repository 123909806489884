import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { MaintenanceModal } from './styles';
import { FullscreenError } from '../../../Components/Error/FullscreenError';

interface Props {
  onDismiss: () => void;
}

export const MaintenanceError: FunctionComponent<Props> = ({
  onDismiss,
}) => {
  return (
    <MaintenanceModal
    >
      <FullscreenError
        cta={
          <FormattedMessage
            id="error.maintenance.cta"
            defaultMessage="Okay"
          />
        }
        onCtaClicked={ onDismiss }
      >
        <FormattedMessage
          id="error.maintenance.body"
          description="Error message that appears when the API is in maintenance mode"
          defaultMessage="<p>OurPeople is currently undergoing maintenance, we should be back shortly.</p><p>Thank you for your patience.</p>"
        />
      </FullscreenError>
    </MaintenanceModal>
  );
}
