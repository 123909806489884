import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { StyledNoResponseMessage } from './style';

export const NoResponseMessage: FC = () => (
  <StyledNoResponseMessage>
    <FormattedMessage
      description="Message displayed in form submission when there is no response for a content."
      defaultMessage="No response"
    />
  </StyledNoResponseMessage>
)
