import styled from '@emotion/styled';

export const StyledInnerNavigationButton = styled.div<{ active: boolean }>`
  display: flex;
  column-gap: ${ props => props.theme.spacing(1) };
  font-size: ${ props => props.theme.fontSize.md };
  padding: ${ props => `${ props.theme.spacing(1.5) } ${ props.theme.spacing(4) }` };
  align-items: center;
  transition: color 0.1s;
  width: 100%;

  ${ props => props.active && `
    color: ${ props.theme.palette.primary.main };
  ` }
`;

export const StyledIconContainer = styled.div`
  position: relative;
  min-height: 1.5em;
`;

export const StyledCircleContainer = styled.div`
  position: absolute;
  top: ${ props => props.theme.spacing(-2) };
  left: ${ props => props.theme.spacing(-2) };
`;
