import { DraftSingleContentCard, DraftContent } from '../Model';
import { ValidationTree } from '../../Common/Model';

export class CardValidationMerger {
  public static addContentValidationChildrenToCardValidation = <T extends DraftContent>(
    children?: ValidationTree<T>['children'],
    cardValidation?: ValidationTree<DraftSingleContentCard<T>>,
  ): ValidationTree<DraftSingleContentCard<T>> => ({
    errors: cardValidation?.errors || [],
    children: {
      ...cardValidation?.children,
      content: {
        errors: cardValidation?.children.content?.errors || [],
        children: {
          ...cardValidation?.children.content?.children,
          ...children,
        } as ValidationTree<T>['children'],
      },
    },
  });
}
