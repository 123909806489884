import { ButtonBase } from 'op-storybook/stories/components/ButtonBase/ButtonBase';
import { darken } from 'polished';

import { PreviewComponent } from '../../../Model/BroadcastBlockDefinition';
import { ButtonBlock } from '../../../Service/Blocks/ButtonBlockDefinition';

export const ButtonBlockExpandedPreview: PreviewComponent<ButtonBlock> = ({
  block
}) => {
  const {
    label,
    url,
    backgroundColour,
    textColour,
  } = block.attributes;

  return (
    <ButtonBase
      css={ theme => ({
        backgroundColor: backgroundColour,
        color: textColour,
        border: 'none',
        minHeight: '40px',
        fontWeight: 500,
        boxShadow: theme.new.shadow.sm,
        width: '100%',

        '&:active, &:hover': {
          backgroundColor: backgroundColour ? darken(0.05, backgroundColour) : undefined,
        },
      }) }
      component="a"
      href={ url }
      target="_blank"
      rel="no-referrer"
    >
      { label || ' ' }
    </ButtonBase>
  );
};
