function wait(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const importRetry = async<T = unknown>(
  importFn: () => Promise<T>,
  retries = 2,
  interval = 1000,
  reload = () => window.location.reload(),
): Promise<T> => {
  try {
    return await importFn();
  } catch (error) {
    if (retries) {
      await wait(interval);
      return importRetry<T>(importFn, retries - 1, interval, reload);
    } else {
      throw error;
    }
  }
}
