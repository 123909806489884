import {FunctionComponent, useEffect, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';

import {ImmutableMap} from '../../../Models/ImmutableMap';
import {Person} from '../../../Models';
import {PeopleRequestParams, useFetchPeople} from '../../Hook';
import {InviteDialog, InviteRequestBody} from '..';

export interface InviteRequestBodyWithFilters extends InviteRequestBody {
  query: PeopleRequestParams;
}

interface Props {
  selectedPeople: ImmutableMap<string, Person>;
  filters: PeopleRequestParams;
  open: boolean;
  onConfirmed: (inviteRequestBody: InviteRequestBodyWithFilters) => void;
  onDismissed: () => void;
}

export const BulkInviteDialog: FunctionComponent<Props> = ({
  selectedPeople,
  filters,
  open,
  onConfirmed,
  onDismissed,
}) => {
  const intl = useIntl();
  const [invitablePeople] = useFetchPeople(
    useMemo(() => {
      const invitableFilters = {
        ...filters,
        pageNum: 1,
      };
      if (selectedPeople.count > 0) {
        invitableFilters.ids = selectedPeople.values.map(person => person.id);
      }
      if ((filters.statuses || '') === '') {
        invitableFilters.statuses = ['added', 'pending'];
      }
      return invitableFilters;
    }, [selectedPeople, filters]),
    !open,
  );
  const [invitablePeopleCount, setInvitablePeopleCount] = useState<number>();

  useEffect(() => {
    setInvitablePeopleCount(invitablePeople?.content?.pagination.itemCount);
  }, [invitablePeople, selectedPeople]);

  const whenConfirmClicked = (inviteRequestBody: InviteRequestBody) => {
    onConfirmed({
      ...inviteRequestBody,
      query: filters,
    });
  }

  return (
    <InviteDialog
      open={ open && invitablePeopleCount !== undefined }
      heading={
        intl.formatMessage({
          id: 'invitePeopleDialog.heading',
          defaultMessage: 'Invite people',
        })
      }
      onConfirmed={ whenConfirmClicked }
      onDismissed={ onDismissed }
    />
  );
};
