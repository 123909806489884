import { FunctionComponent, useState } from 'react';

import { ContentPreviewProps } from '../../../../Content/Model';
import { NumberRequestEditorContent } from '../../../Service';
import { NumberRequest } from '../NumberRequest/NumberRequest';

export const NumberRequestPreview: FunctionComponent<ContentPreviewProps<NumberRequestEditorContent>> = () => {
  const [value, setValue] = useState<number>();

  return (
    <NumberRequest
      value={ value }
      onChange={ setValue }
    />
  );
};
