import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { ContentPreviewProps } from '../../../../Content/Model';
import { SignEditorContent } from '../../../Service';
import { ContentWithType, ScrollableContent } from '..';
import { StyledSignRequestText } from './style';
import { VerticallySpaced } from '../../../../Common/Component';
import { Signature } from '../../../../Content/Component';
import { useLoggedInAuthDescription } from '../../../../Core/Hook';
import { TextFormatter } from '../../../../Utility';

export const SignRequestPreview: FC<ContentPreviewProps<SignEditorContent>> = ({
  editorContent,
  onReady,
}) => {
  const intl = useIntl();
  const { user } = useLoggedInAuthDescription();
  const [signedAt, setSignedAt] = useState<Date | null>(null);

  useEffect(() => {
    onReady();
  }, [onReady]);

  return (
    <ScrollableContent>
      <ContentWithType
        localisedContentType={ intl.formatMessage({
          id: 'broadcasts.content.preview.sign',
          description: 'Label for sign content type used in preview.',
          defaultMessage: 'Sign',
        }) }
      >
        <VerticallySpaced gap={ 8 }>
          <StyledSignRequestText>{ editorContent.card.content.text }</StyledSignRequestText>
          <Signature
            signedAt={ signedAt }
            signature={ TextFormatter.spaceSeparated(user.forename || '', user.lastname || '') }
            onChange={ setSignedAt }
          />
        </VerticallySpaced>
      </ContentWithType>
    </ScrollableContent>
  );
};
