import { PersonWithSensitiveData } from '../../Models';
import { PersonForEditing } from '../Model';
import { RoleReader } from '../../Readers';

export class PersonTypeConverter {
  static convertPersonWithSensitiveDataToPersonForEditing(
    personWithSensitiveData: PersonWithSensitiveData,
  ): PersonForEditing {
    const {
      id,
      firstName,
      lastName,
      emailAddresses,
      fake,
      secret,
      externallyManaged,
      timezone,
    } = personWithSensitiveData;
    return {
      id,
      firstName,
      lastName,
      emailAddresses,
      fake,
      secret,
      externallyManaged,
      mobileNumber: personWithSensitiveData.mobileNumber,
      roleId: (
        personWithSensitiveData.roleId
        || RoleReader.getMostPermissiveRole(personWithSensitiveData.roleIds)
        || 'ROLE_USER'
      ),
      timezone,
    };
  }
}
