import { useMemo } from 'react';

import { RoleReader } from '../../Readers';
import { useAuthDescription } from '../../Core/Hook';
import { RoleId } from '../../Security/Model';

interface UserRoles {
  userRoles: RoleId[];
  mostPermissiveRole: RoleId;
  userIsSuperAdmin: boolean;
  userIsDeveloper: boolean;
  userIsTeamAdmin: boolean;
  userIsBroadcastAdmin: boolean;
}

export const useUserRoles = (): UserRoles => {
  const authDescription = useAuthDescription();
  const userRoles = useMemo(() => authDescription?.user.roles || [], [authDescription?.user.roles]);

  return {
    userRoles,
    mostPermissiveRole: RoleReader.getMostPermissiveRole(userRoles) || 'ROLE_USER',
    userIsSuperAdmin: RoleReader.isSuperAdmin(userRoles),
    userIsDeveloper: RoleReader.isDeveloper(userRoles),
    userIsTeamAdmin: RoleReader.isTeamAdmin(userRoles),
    userIsBroadcastAdmin: RoleReader.isBroadcastAdmin(userRoles),
  };
};
