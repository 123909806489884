import { SingleContentCard, DraftSingleContentCard, Content, DraftContent } from '../../Content/Model';
import { UniqueIdGenerator } from '../../Common/Utility';
import {
  CommentRequest,
  DraftCommentRequest,
  DraftFileContent,
  FileContent,
  LegacyImageContent,
  StyledImageContent
} from '../Service';

export class ContentTransformer {
  public static legacyImageToStyledImage = (content: LegacyImageContent): StyledImageContent => ({
    id: content.id,
    type: 'styled_image',
    backgroundColour: '#FFFFFF',
    mandatory: content.mandatory,
    caption: content.text
      ? {
        text: content.text,
        textColour: '#FFFFFF',
        backgroundColour: '#E00669',
        position: 'bottom_middle',
      }
      : null,
    imageFit: 'cover',
    downloadable: true,
    imageHeight: 'full',
    sourceImage: content.image,
    upload: content.image,
    imageTransform: null,
    text: null,
    link: null,
  });

  public static toCard = (content: Content): SingleContentCard => {
    switch(content.type) {
      case 'imagecontent':
        return {
          id: UniqueIdGenerator.generate(),
          index: 0,
          image: null,
          mandatory: false,
          content: ContentTransformer.legacyImageToStyledImage(content as LegacyImageContent),
        } as SingleContentCard<StyledImageContent>;
      case 'filecontent':
        return {
          id: UniqueIdGenerator.generate(),
          index: 0,
          image: null,
          mandatory: (content as FileContent).mandatory,
          content,
        };
      case 'textquestion':
        return {
          id: UniqueIdGenerator.generate(),
          index: 0,
          image: null,
          mandatory: (content as CommentRequest).mandatory,
          content,
        };
      default:
        return {
          id: UniqueIdGenerator.generate(),
          index: 0,
          image: null,
          mandatory: false,
          content,
        };
    }
  };

  public static fromCard = (card: DraftSingleContentCard): DraftContent => {
    switch (card.content.type) {
      case 'filecontent':
        return {
          ...card.content,
          mandatory: card.mandatory,
        } as DraftFileContent;
      case 'textquestion':
        return {
          ...card.content,
          mandatory: card.mandatory,
        } as DraftCommentRequest;
      default:
        return card.content;
    }
  }
}
