import styled from '@emotion/styled';

import shapeUrl from '../../../Assets/img/shapes/graphic_green2.svg?url';

export const LiveCardContainerBackground = styled.div`
  background-color: ${ props => props.theme.palette.inboxBackground.main };
  position: relative;
  overflow: hidden;
  print-color-adjust: exact;
  -webkit-print-color-adjust: exact;

  ::before {
    content: '';
    background-image: url(${ shapeUrl });
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 500px 500px;
    background-position: left -100px bottom -100px;
  }
`;
