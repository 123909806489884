import { FC } from 'react';

import { StyledButtonContainer, StyledButtonRow } from './style';

type Props = {
  align?: 'start' | 'end';
};

export const ResponsiveButtonContainer: FC<Props> = ({ align = 'end', children }) => (
  <StyledButtonRow>
    <StyledButtonContainer align={ align }>
      { children }
    </StyledButtonContainer>
  </StyledButtonRow>
);
