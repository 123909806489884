import { ComponentProps, FC } from 'react';

import { List } from './List';
import { StandardListItem } from './StandardListItem';

export const ShortList: FC<Omit<
  ComponentProps<typeof List<ComponentProps<typeof StandardListItem>>>,
  'renderItem'
>> = props => (
  <List
    css={ {
      border: '1px solid rgba(0,0,0,0.05)',
      borderRadius: '8px',
      overflow: 'hidden',
      padding: '4px 0',
    } }
    renderItem={ (item) => <StandardListItem {...item} /> }
    { ...props }
  />
);
