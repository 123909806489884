import { FormattedMessage } from 'react-intl';
import { FC } from 'react';

import InfoIcon from '../../../Assets/img/icons/streamline/information-circle.svg';
import { PresentationIcon } from '../../../Common/Component';
import { Flex } from '../../../Common/Component';

export const GlobalAdminNotice: FC = () => (
  <Flex
    gap={ 1 }
    align="flex-start"
    noWrap
  >
    <PresentationIcon
      IconComponent={ InfoIcon }
    />
    <div>
      <FormattedMessage
        id="forms.globalAdminNotice.message"
        description="Message explaining that global admins are automatically included in a forms admin list."
        defaultMessage="<strong>Note</strong>: If you can't see the user you are looking for please check they have team admin permissions. Global admins automatically have access to all forms, so are not selectable in this list."
      />
    </div>
  </Flex>
);
