import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { ContextMenuAction } from '../../../Common/Component/ContextMenu/ContextMenu';
import { useApi, useContextOrThrow } from '../../../Core/Hook';
import { useMounted } from '../../../Common/Hook';
import { ToastContext } from '../../../Core/Context';
import { BroadcastsPermission } from '../../Model';
import { usePermissions } from '../../../Security/Hook';
import CopyIcon from '../../../Assets/img/icons/streamline/layers-back.svg';

type ReturnValue = {
  duplicateAction: ContextMenuAction;
  duplicating: boolean;
};

export const useDuplicateBroadcastAction = (
  broadcastId: string,
  performingOtherAction: boolean,
  onComplete?: () => void,
): ReturnValue => {
  const intl = useIntl();
  const api = useApi();
  const [duplicating, setDuplicating] = useState<boolean>(false);
  const mounted = useMounted();
  const { addSuccessToast, addErrorToast } = useContextOrThrow(ToastContext);
  const { guardedCallback } = usePermissions();
  const history = useHistory();

  const navigateToDrafts = useCallback(() => (
    history.push('/broadcasts?tab=drafts&pageNum=1')
  ), [history]);

  const whenDuplicateClicked = useCallback(() => {
    setDuplicating(true);
    api.post(`/broadcasts/${ broadcastId }/duplicate`)
      .then(() => (
        setTimeout(() => {
          if (!mounted.current) {
            return;
          }

          addSuccessToast(
            intl.formatMessage({
              description: 'Toast message when broadcast is duplicated successfully.',
              defaultMessage: 'Broadcast copied successfully.',
            })
          );
          setDuplicating(false);
          onComplete ? onComplete() : navigateToDrafts();
        }, 50)
      ))
      .catch(() => {
        if (!mounted.current) {
          return;
        }

        addErrorToast(
          intl.formatMessage({
            description: 'Toast message when broadcast cannot be duplicated.',
            defaultMessage: 'Broadcast could not be copied.',
          })
        );
        setDuplicating(false);
      });
  }, [api, broadcastId, mounted, addSuccessToast, intl, onComplete, navigateToDrafts, addErrorToast]);

  return useMemo(() => ({
    duplicateAction: {
      id: 'copy',
      IconComponent: CopyIcon,
      label: intl.formatMessage({
        description: 'Label for copy action in recent deliveries action menu.',
        defaultMessage: 'Make a copy',
      }),
      busy: duplicating,
      disabled: performingOtherAction,
      onClick: guardedCallback(whenDuplicateClicked, [BroadcastsPermission.CREATE]),
    },
    duplicating,
  }), [duplicating, guardedCallback, intl, performingOtherAction, whenDuplicateClicked]);
};
