import { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';

import { FailureToast, SuccessToast, Toast, ToastMessage } from '../../Components';
import { ToastContext } from '../Context';

export const ToastProvider: FC<PropsWithChildren> = (
  { children }
) => {
  const [messages, setMessages] = useState<ToastMessage[]>([]);

  const addToast = useCallback((message: ToastMessage) => (
    setMessages(currentMessages => [
      message,
      ...currentMessages,
    ])
  ), []);

  const addSuccessToast = useCallback((message: string) => addToast(new SuccessToast(message)), [addToast]);
  const addErrorToast = useCallback((message: string) => addToast(new FailureToast(message)), [addToast]);

  return (
    <ToastContext.Provider
      value={
        useMemo(() => ({
          addSuccessToast,
          addErrorToast,
          addToast,
        }), [addSuccessToast, addErrorToast, addToast])
      }
    >
      { children }
      <Toast
        messages={ messages }
        onMessagesChanged={ setMessages }
      />
    </ToastContext.Provider>
  );
};
