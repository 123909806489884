import {JSX, ReactNode} from 'react';

type Props<I> = {
  renderItem: (item: I, index: number) => ReactNode;
  renderLeadingItems?: () => ReactNode;
  items: I[];
  className?: string;
};

export const List = <I,>({
  renderItem,
  renderLeadingItems,
  items,
  className,
}: Props<I>): JSX.Element => {
  return (
    <ul
      className={ className }
      css={ {
        margin: 0,
        padding: 0,
        listStyle: 'none',
      } }
    >
      { renderLeadingItems && renderLeadingItems() }
      { items.map(renderItem) }
    </ul>
  )
};
