import { FC } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import { Dialog } from '@mui/material';
import ReactPlayer from 'react-player';

import { StyledCloseButton, StyledDialogContent } from './style';
import { CloseButton } from '..';

type Props = {
  open: boolean;
  url: string;
  onClose: () => void;
  TransitionProps?: TransitionProps;
};

export const VideoDialog: FC<Props> = ({
  open,
  url,
  onClose,
  TransitionProps,
}) => (
  <Dialog
    TransitionProps={ TransitionProps }
    open={ open }
    maxWidth="md"
    fullWidth
    closeAfterTransition
    onClose={ onClose }
  >
    <StyledDialogContent>
      <StyledCloseButton>
        <CloseButton
          onClick={ onClose }
        />
      </StyledCloseButton>
      <ReactPlayer
        url={ url }
        controls
        playing
        width="100%"
        height="100%"
      />
    </StyledDialogContent>
  </Dialog>
);
