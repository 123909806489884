import styled from '@emotion/styled';
import { Select, TextField } from '@mui/material';

export const StyledSelect = styled(Select)`
  margin-bottom: -4px;
`;

export const StyledTextField = styled(TextField)`
  margin-bottom: -4px;
`;
