import styled from '@emotion/styled';

export const StyledTextFieldLeadingText = styled.div<{ disabled: boolean }>`
  border-right: ${ props => props.theme.new.border.light };
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${ props => `${ props.theme.new.spacing[2] } ${ props.theme.new.spacing[3] }` };
  cursor: text;
  border-bottom-left-radius: ${ props => props.theme.new.borderRadius.standard };
  border-top-left-radius: ${ props => props.theme.new.borderRadius.standard };

  ${ props => props.disabled && `
    background-color: ${ props.theme.new.palette.grey[50].main };
  ` }
`;
