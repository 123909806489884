import { FetchResponse } from '../../Hooks';
import { Paginated } from '../../Models';
import { Audience, AudienceMember } from '../Model';
import { usePost } from '../../Hooks';

export interface FetchAudienceMembersQuery {
  search?: string | null;
  pageNum: number;
  sort?: FetchAudienceMembersSort[] | null;
}

export type FetchAudienceMembersParams = {
  query: FetchAudienceMembersQuery;
  audience: Audience;
  context: string;
  applyReach: boolean;
}

export type FetchAudienceMembersSort = 'first_name_asc'
  | 'first_name_desc'
  | 'last_name_asc'
  | 'last_name_desc'
  | 'status_asc'
  | 'status_desc';

export type FetchAudienceMembersResult = Paginated<'members', AudienceMember> & { audienceSize: number };

export const useFetchAudienceMembers = (
  params: FetchAudienceMembersParams,
  disabled?: boolean,
): FetchResponse<FetchAudienceMembersResult> => usePost(
  '/audiences/list-members',
  params,
  undefined,
  disabled,
);
