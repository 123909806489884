import { FC } from 'react';
import ImageIcon from 'op-storybook/lib/assets/icon/figma/image-01.svg';

import { BlockPlaceholder } from '../../BlockPlaceholder';

type Props = {
  loading: boolean;
  className?: string;
};

export const ImagePlaceholder: FC<Props> = ({
  loading,
  className,
}) => (
  <BlockPlaceholder
    loading={ loading }
    className={ className }
    IconComponent={ ImageIcon }
  />
);
