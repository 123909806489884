import { FunctionComponent } from 'react';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { DateTimeFormatter } from '../../../../Utility/DateTimeFormatter';
import { CenteredConfirmationDialog } from '../../../../Components/Dialog/CenteredConfirmationDialog';

type Props = {
  container?: Element | React.Component | (() => Element | React.Component | null) | null;
  scheduleFor: Date;
  open: boolean;
  onDismissed: () => void;
}

export const ScheduledBroadcastPublishedConfirmationDialog: FunctionComponent<Props> = ({
  scheduleFor,
  open,
  onDismissed,
  ...other
}) => {
  const intl = useIntl();

  return (
    <CenteredConfirmationDialog
      heading={<>&#128079;</>}
      cta={
        intl.formatMessage({
          id: 'deliveryConfirmation.scheduled.cta',
          defaultMessage: 'Go to scheduled broadcasts',
        })
      }
      open={open}
      onConfirmed={onDismissed}
      onDismissed={onDismissed}
      {...other}
    >
      <FormattedMessage
        id="deliveryConfirmation.later.body"
        defaultMessage="Your broadcast has been successfully scheduled for{date}"
        values={{
          date: <><br/><strong>{DateTimeFormatter.internationalised(scheduleFor, intl.locale)}</strong></>,
        }}
      />
    </CenteredConfirmationDialog>
  )
}
