import { useCallback } from 'react';
import { AxiosError } from 'axios';

import { ErrorResponseReader } from '../../Common/Utility';

export const useRecallErrorHandler = (onCatch: () => void): (error: AxiosError) => void => {
  return useCallback((error: AxiosError) => {
    if (!ErrorResponseReader.isApiError(error) || error.response.data.error.code !== 'BROADCAST_DELIVERY_RECALLED') {
      return;
    }

    onCatch();
  }, [onCatch]);
}
