import { FunctionComponent } from 'react';

import { StyledLabeledInformation } from './styles';

interface Props {
  id: string;
  label: string;
}

export const LabeledInformation: FunctionComponent<Props> = ({
  id,
  label,
  children,
}) => {
  return (
    <StyledLabeledInformation>
      <label id={ `labelled-information-${ id }` }><h4>{label}</h4></label>
      <div aria-labelledby={ `labelled-information-${ id }` }>
        {children}
      </div>
    </StyledLabeledInformation>
  )
};
