import { FC } from 'react';

import { ContentEditorProps } from '../../../../Content/Model';
import { SignRequestEditorContent } from '../../../Service/ContentDefinitions/SignRequestDefinition';
import { RequestFields } from '../RequestFields/RequestFields';

export const SignRequestFields: FC<ContentEditorProps<SignRequestEditorContent>> = ({
  editorContent,
  onEditorContentChanged,
  validation,
  onValidationChanged,
}) => (
  <RequestFields
    editorContent={ editorContent }
    onEditorContentChanged={ onEditorContentChanged }
    validation={ validation }
    onValidationChanged={ onValidationChanged }
  />
);
