import { FetchResponse, useGet } from '../../Hooks';
import { Paginated } from '../../Models';
import { FetchDeliveriesParams } from '../../Broadcasts/Hook';
import { PersonalDelivery } from '../Model';

type FetchPersonalDeliveriesSortMethod = 'affected_at_asc'
  | 'affected_at_desc'
  | 'bumped_at_asc'
  | 'bumped_at_desc'
  | 'received_at_asc'
  | 'received_at_desc'
  | 'coalesced_bumped_at_desc'
  | 'coalesced_bumped_at_asc';

export type FetchPersonalDeliveriesParams = Omit<FetchDeliveriesParams, 'sort' | 'statuses'> & {
  recipientStatuses?: string;
  noPreview: 1 | 0;
  sort: FetchPersonalDeliveriesSortMethod;
};

export const useFetchPersonalDeliveries = (params: FetchPersonalDeliveriesParams): FetchResponse<Paginated<'deliveries', PersonalDelivery>> => {
  return useGet('/me/broadcasts/deliveries', params);
};
