import { ReactNode, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { ContextMenuAction } from '../../../Common/Component/ContextMenu/ContextMenu';
import { PopOverState } from '../../../Common/Model';
import { useApi, useContextOrThrow } from '../../../Core/Hook';
import { useMounted } from '../../../Common/Hook';
import { ToastContext } from '../../../Core/Context';
import ShareIcon from '../../../Assets/img/icons/streamline/single-neutral-actions-share-2-users.svg';
import { BroadcastsPermission } from '../../Model';
import { usePermissions } from '../../../Security/Hook';
import { FetchDraftBroadcastResponse } from '../useFetchDraftBroadcast';
import { BroadcastTransformer } from '../../Utility';
import { ListShareDraftDialog } from '../../Component';
import { SharedDelivery } from '../useFetchSharedDeliveries';
import { useUneditableBroadcastMessage } from '../useUneditableBroadcastMessage';

type ReturnValue = {
  shareConfirmationDialog: ReactNode;
  shareAction: ContextMenuAction;
  sharing: boolean;
};

export const useShareDeliveryAction = (
  delivery: SharedDelivery,
  resourceLockMessage: string | null,
  performingOtherAction: boolean,
  onReloadRequired: () => void,
): ReturnValue => {
  const intl = useIntl();
  const api = useApi();
  const [sharing, setSharing] = useState<boolean>(false);
  const [shareDialogState, setShareDialogState] = useState<PopOverState>(PopOverState.CLOSED);
  const mounted = useMounted();
  const { addSuccessToast, addErrorToast } = useContextOrThrow(ToastContext);
  const { guardedCallback } = usePermissions();
  const uneditableBroadcastTooltipMessage = useUneditableBroadcastMessage();

  const whenShareConfirmed = useCallback((shareAudiencePersonIds: string[]) => {
    setShareDialogState(PopOverState.WILL_CLOSE);
    setSharing(true);
    api.get<FetchDraftBroadcastResponse>(`/broadcasts/drafts/${ delivery.broadcast.id }`)
      .then((response) => {
        const {
          tags,
          shareAudiencePersonIds: previousShareAudiencePersonIds,
          ...draftBroadcast
        } = BroadcastTransformer.toDraft(
          response.data.broadcast,
          response.data.recipientAudience,
          response.data.delivery,
        );

        return api.post(
          `/broadcasts/${ response.data.broadcast.id }`,
          {
            ...draftBroadcast,
            ...(shareAudiencePersonIds.length ? { shareAudiencePersonIds } : {}),
          },
        );
      })
      .then(() => {
        setTimeout(() => {
          if (!mounted) {
            return;
          }

          addSuccessToast(
            intl.formatMessage({
              id: 'draftsTable.share.success',
              description: 'Toast message when broadcast is shared successfully.',
              defaultMessage: 'Broadcast shared successfully.',
            })
          );
          setSharing(false);
          onReloadRequired();
        }, 50);
      })
      .catch(() => {
        if (!mounted) {
          return;
        }

        addErrorToast(
          intl.formatMessage({
            id: 'draftsTable.share.fail',
            description: 'Toast message when broadcast can not be shared.',
            defaultMessage: 'Could not share broadcast.',
          })
        );
        setSharing(false);
      });
  }, [api, delivery.broadcast.id, mounted, addSuccessToast, intl, onReloadRequired, addErrorToast]);

  const shareConfirmationDialog = useMemo(() => (
    <>
      { shareDialogState !== PopOverState.CLOSED && (
        <ListShareDraftDialog
          audienceId={ delivery.broadcast.shareAudienceId }
          open={ shareDialogState === PopOverState.OPEN }
          onShare={ whenShareConfirmed }
          onCancel={ () => setShareDialogState(PopOverState.WILL_CLOSE) }
          title={ delivery.broadcast.name }
        />
      ) }
    </>
  ), [delivery.broadcast.name, delivery.broadcast.shareAudienceId, shareDialogState, whenShareConfirmed]);

  return useMemo(() => ({
    shareAction: {
      id: 'share',
      IconComponent: ShareIcon,
      label: intl.formatMessage({
        description: 'Label for share action in recent deliveries action menu.',
        defaultMessage: 'Share',
      }),
      busy: sharing,
      disabled: performingOtherAction || !delivery.broadcast.editable,
      ...(delivery.broadcast.editable ? {} : { disabledTooltip: uneditableBroadcastTooltipMessage }),
      onClick: guardedCallback(() => setShareDialogState(PopOverState.OPEN), [BroadcastsPermission.UPDATE]),
    },
    shareConfirmationDialog,
    sharing,
  }), [
    intl,
    sharing,
    performingOtherAction,
    delivery.broadcast.editable,
    uneditableBroadcastTooltipMessage,
    guardedCallback,
    shareConfirmationDialog
  ]);
};
