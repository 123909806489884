import {
  FC,
  FormEventHandler,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import LockIcon from 'op-storybook/lib/assets/icon/figma/lock-01.svg';
import { AuthFullscreenLayout } from 'op-storybook/lib/components/AuthFullscreenLayout/AuthFullscreenLayout';
import { BaseAuthHeader } from 'op-storybook/lib/components/BaseAuthHeader/BaseAuthHeader';
import { Button } from 'op-storybook/stories/components/Button/Button';
import { differenceInMilliseconds, parseISO } from 'date-fns';
import { Typography } from 'op-storybook/stories/components/Typography/Typography';
import { FormattedMessage, useIntl } from 'react-intl';
import { Checkbox } from 'op-storybook/stories/components/Checkbox/Checkbox';
import { Form } from 'op-storybook/lib/components/Form/Form';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { ApiRequest } from '@ourpeople/shared/Core/Model/ApiRequest';
import { LoadingState } from 'op-storybook/stories/components/LoadingState/LoadingState';

import { useEnvironmentSettings, useLocalStorageHookContext } from '../../../Common/Hook';
import { BlockingOverlay } from '../../../Common/Component/BlockingOverlay/BlockingOverlay';
import { useContextOrThrow } from '../../Hook';
import { ApiContext, AuthContext } from '../../../Contexts';
import { DurationParser } from '../../../Utility/DurationParser';
import { RequestState, WorkStatusConfirmation } from '../../../Models';
import { AuthLayoutRichText } from '../../../Security/Component/AuthLayoutRichText/AuthLayoutRichText';

export const ConfirmWorkStatus: FC<PropsWithChildren> = ({ children }) => {
  const {
    workStatusCheckEnabled,
    workStatusCheckMessage,
    workStatusCheckConfirmOptionText,
    workStatusCheckElseMessage,
    workStatusCheckHelpLink,
    workStatusCheckConfirmationDuration,
    workStatusCheckConfirmationMaxDuration,
  } = useEnvironmentSettings();
  const { authDescription, refreshAuthDescription } = useContext(AuthContext);
  const getStorageHook = useLocalStorageHookContext();
  const useWorkStatusConfirmation = getStorageHook<WorkStatusConfirmation | undefined>('workStatusConfirmation', authDescription?.workStatusConfirmation);
  const [workStatusConfirmation, setWorkStatusConfirmation] = useWorkStatusConfirmation();
  const api = useContextOrThrow(ApiContext);
  const intl = useIntl();
  const [confirmationExpired, setConfirmationExpired] = useState<boolean>(false);
  const [useMaxDuration, setUseMaxDuration] = useState<boolean>(true);
  const checkTimeout = useRef<number>();
  const [loading, setLoading] = useState<boolean>(true);
  const checkIntervalMs = DurationParser.secondsToMs(DurationParser.durationInSeconds(
    useMaxDuration
      ? workStatusCheckConfirmationMaxDuration
      : workStatusCheckConfirmationDuration
  ));
  const [confirmRequest, setConfirmRequest] = useState<ApiRequest<null>>({
    state: RequestState.PENDING,
    result: null,
  });
  const timeSinceConfirmationMs = useMemo(() => {
    let timeSinceConfirmationMs = Infinity;
    try {
      timeSinceConfirmationMs = workStatusConfirmation
        ? differenceInMilliseconds(new Date(), parseISO(workStatusConfirmation.confirmedAt))
        : Infinity
    } catch { /* Ignore malformed timestamp, overlay will be presented as if no confirmation is present */ }
    return timeSinceConfirmationMs;
  }, [workStatusConfirmation]);

  // Update localstorage with api work status confirmation
  useEffect(() => {
    setWorkStatusConfirmation(prev => {
      // We must check if the confirmation is newer than the one we have in localstorage otherwise we may
      // show the confirmation overlay again even if the user has already confirmed it. This is due to the
      // auth description we have, potentially being stale.
      if (
        !authDescription?.workStatusConfirmation
        || (
          prev
          && parseISO(prev.confirmedAt) > parseISO(authDescription.workStatusConfirmation.confirmedAt)
        )
      ) {
        return prev;
      }

      return authDescription.workStatusConfirmation;
    });
  }, [authDescription?.workStatusConfirmation]);

  const updateRemainingTime = useCallback(() => {
    if (!authDescription) {
      return;
    }

    const workCheckIntervalMs = workStatusConfirmation ? DurationParser.secondsToMs(workStatusConfirmation.duration) : 0;
    const remainingTimeMs = Math.max(0, workCheckIntervalMs - timeSinceConfirmationMs);

    checkTimeout.current && clearTimeout(checkTimeout.current);

    setLoading(false);
    setConfirmationExpired(remainingTimeMs <= 0);

    if (remainingTimeMs > 0) {
      checkTimeout.current = window.setTimeout(refreshAuthDescription, remainingTimeMs + 1);
    }
  }, [authDescription, refreshAuthDescription, timeSinceConfirmationMs, workStatusConfirmation])

  useEffect(() => {
    updateRemainingTime();
  }, [updateRemainingTime]);

  const whenSubmitted: FormEventHandler = (event) => {
    event.preventDefault();

    setConfirmRequest({
      state: RequestState.FETCHING,
      result: null,
    });

    const checkIntervalSeconds = DurationParser.msToSeconds(checkIntervalMs);
    api.post<{ workStatusConfirmation: WorkStatusConfirmation }>('/work-status/confirm', { duration: checkIntervalSeconds })
      .then(response => response.data)
      .then(result => {
        setConfirmRequest({
          state: RequestState.COMPLETE,
          result: null,
        });

        setConfirmationExpired(false);
        setWorkStatusConfirmation(result.workStatusConfirmation);
      })
      .catch(() => {
        setConfirmRequest({
          state: RequestState.FAILED,
          result: null,
        });
      });
  };

  return (
    <BlockingOverlay
      block={ loading || (confirmationExpired && workStatusCheckEnabled) }
      overlay={
        loading
          ? <LoadingState pad/>
          : (
          <AuthFullscreenLayout
            helpLink={ workStatusCheckHelpLink }
          >
            <BaseAuthHeader
              graphic={ {
                type: 'icon',
                props: {
                  IconComponent: LockIcon,
                  size: 'lg',
                },
              } }
            >
              <AuthLayoutRichText
                text={ workStatusCheckMessage }
              />
              { workStatusCheckElseMessage && (
                <Typography
                  align="center"
                  palette={ {
                    colour: 'grey',
                    intensity: 500,
                  } }
                >
                  { workStatusCheckElseMessage }
                </Typography>
              ) }
            </BaseAuthHeader>
            <Form
              css={ { width: '100%' } }
              onSubmit={ whenSubmitted }
            >
              <Stack direction="column">
                <label htmlFor={ CHECKBOX_ID }>
                  <Stack align="center">
                    <Checkbox
                      id={ CHECKBOX_ID }
                      checked={ useMaxDuration }
                      onCheckedChange={ checkedState => setUseMaxDuration(checkedState !== false) }
                      name="use-long-work-status-duration"
                    />
                    <Typography
                      size="sm"
                      palette={ {
                        colour: 'grey',
                        intensity: 500,
                      } }
                    >
                      <FormattedMessage
                        defaultMessage="Don't ask again for { duration }."
                        values={ {
                          duration: DurationParser.localise(workStatusCheckConfirmationMaxDuration, intl),
                        } }
                      />
                    </Typography>
                  </Stack>
                </label>
                <Button
                  type="sumbit"
                  busy={ confirmRequest.state === RequestState.FETCHING }
                  attention={ confirmRequest.state === RequestState.FAILED }
                >
                  { workStatusCheckConfirmOptionText }
                </Button>
              </Stack>
            </Form>
          </AuthFullscreenLayout>
          )
      }
    >
      { children }
    </BlockingOverlay>
  );
};

const CHECKBOX_ID = 'work-confirmation-use-max';
