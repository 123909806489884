import { FunctionComponent, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AutocompleteSelectionChanged } from '@ourpeople/shared/Core/Component/Input/Autocomplete/Autocomplete';
import { LocalisedString } from 'op-storybook/lib/model/LocalisedString/LocalisedString';

import { FilterContainer, FilterRow, FilterChips, Chip } from '../../../Common/Component';
import { StyledAutocompleteFilter } from '../PeopleTableFilters/styles';
import { useQueryAsState } from '../../../Hooks';
import { InvitationStatusAutocomplete } from '../InvitationStatusAutocomplete/InvitationStatusAutocomplete';
import { InvitationStatus } from '../../Hook';
import { ArrayHelper, QueryWithKeys } from '../../../Common/Utility';
import { InvitationTypeAutocomplete } from '../InvitationTypeAutocomplete/InvitationTypeAutocomplete';

type Query = QueryWithKeys<'types' | 'statuses'>;

export const InvitationTableFilters: FunctionComponent = () => {
  const intl = useIntl();
  const [query, setQuery] = useQueryAsState<Query>();
  const statusIds = useMemo(() => {
    return query.statuses
      ? query.statuses.split(',')
      : [];
  }, [query]);
  const typeIds = useMemo(() => {
    return query.types
      ? query.types.split(',')
      : [];
  }, [query]);
  const [chips, setChips] = useState<Chip[]>([]);

  const whenStatusesChanged: AutocompleteSelectionChanged<LocalisedString<InvitationStatus>> = selection => {
    const statusIds = [
      ...selection.options.map(option => option.id),
      ...selection.unknownIds,
    ];

    setQuery(({ statuses, ...query }) => ({
      ...query,
      ...(
        statusIds.length
          ? { statuses: statusIds.join(',') }
          : {}
      ),
      pageNum: '1',
    }));

    setChips(chips => [
      ...chips.filter(chip => chip.type !== 'status'),
      ...selection.options.map(option => ({
        id: option.id,
        label: intl.formatMessage({
          id: 'people.invitations.statusChip.label',
          defaultMessage: 'Status: {status}',
        }, {
          status: option.localisation,
        }),
        type: 'status',
      })),
    ]);
  };

  const whenTypesChanged: AutocompleteSelectionChanged<LocalisedString> = selection => {
    const typeIds = [
      ...selection.options.map(option => option.id),
      ...selection.unknownIds,
    ];

    setQuery(({ types, ...query }) => ({
      ...query,
      ...(
        typeIds.length
          ? { types: typeIds.join(',') }
          : {}
      ),
      pageNum: '1',
    }));

    setChips(chips => [
      ...chips.filter(chip => chip.type !== 'type'),
      ...selection.options.map(option => ({
        id: option.id,
        label: intl.formatMessage({
          id: 'people.invitations.typeChip.label',
          defaultMessage: 'Type: {type}',
        }, {
          type: option.localisation,
        }),
        type: 'type',
      })),
    ]);
  };

  const removeStatusWithId = (id: string): void => {
    setQuery(({ statuses: currentStatuses, ...query }) => {
      const statuses: string[] = (currentStatuses || '').split(',');
      const index = statuses.indexOf(id);
      const newStatuses = ArrayHelper.remove(statuses, index).join(',');
      return !newStatuses
        ? query
        : {
          ...query,
          statuses: newStatuses,
          pageNum: '1',
        };
    });
  };

  const removeTypeWithId = (id: string): void => {
    setQuery(({ types: currentTypes, ...query }) => {
      const types: string[] = (currentTypes || '').split(',');
      const index = types.indexOf(id);
      const newTypes = ArrayHelper.remove(types, index).join(',');
      return !newTypes
        ? query
        : {
          ...query,
          types: newTypes,
          pageNum: '1',
        };
    });
  };

  const whenRemoveChipClicked = (chip: Chip): void => {
    if (chip.type === 'status') {
      removeStatusWithId(chip.id);
    }

    if (chip.type === 'type') {
      removeTypeWithId(chip.id);
    }

    setChips(chips => {
      const index = chips.findIndex(existingChip => existingChip.id === chip.id);
      return ArrayHelper.remove(chips, index)
    });
  };

  const whenClearFiltersClicked = (): void => {
    setQuery(({ statuses, types, ...query }) => ({
      ...query,
    }));

    setChips([]);
  };

  return (
    <FilterContainer>
      <FilterRow>
        <span>
          <FormattedMessage
            id="people.invitations.filterResults"
            defaultMessage="Filter results"
          />
        </span>
        <StyledAutocompleteFilter>
          <InvitationStatusAutocomplete
            selectedIds={ statusIds }
            onSelectionChanged={ whenStatusesChanged }
            showChips={false}
            dense
            multiple
            label={
              intl.formatMessage({
                id: 'people.invitations.statusFilter.label',
                defaultMessage: 'Statuses',
              })
            }
            placeholder={
              intl.formatMessage({
                id: 'people.invitations.statusFilter.placeholder',
                defaultMessage: 'Search...',
              })
            }
          />
        </StyledAutocompleteFilter>
        <StyledAutocompleteFilter>
          <InvitationTypeAutocomplete
            selectedIds={ typeIds }
            onSelectionChanged={ whenTypesChanged }
            showChips={false}
            dense
            multiple
            label={
              intl.formatMessage({
                id: 'people.invitations.typeFilter.label',
                defaultMessage: 'Types',
              })
            }
            placeholder={
              intl.formatMessage({
                id: 'people.invitations.typeFilter.placeholder',
                defaultMessage: 'Search...',
              })
            }
          />
        </StyledAutocompleteFilter>
      </FilterRow>
      { !!chips.length && (
        <FilterChips
          chips={ chips }
          onRemoveChipClicked={ whenRemoveChipClicked }
          onClearFiltersClicked={ whenClearFiltersClicked }
        />
      ) }
    </FilterContainer>
  );
};
