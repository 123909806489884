import { FunctionComponent, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { FormGroup } from '@mui/material';

import { RoleId } from '../../../Security/Model';
import { useEnvironmentSettings } from '../../../Common/Hook';
import { StyledCheckbox, StyledCheckboxLabel, StyledDescriptionContainer } from './styles';

export const RoleDescription: FunctionComponent<{ roleId: RoleId }> = ({ roleId }) => {
  const {
    streamChatEnabled,
    formsEnabled,
    filesEnabled,
  } = useEnvironmentSettings();

  const permissions = useMemo(() => {
    switch (roleId) {
      case 'ROLE_DEVELOPER':
        return {
          administrateAll: true,
          createForms: true,
          administratePeople: true,
          administrateForms: true,
          invitePeople: true,
          accessConsole: true,
          sendFullBroadcasts: true,
          administrateFiles: true,
          assignCoverBroadcasts: true,
          sendCoverBroadcasts: true,
        };
      case 'ROLE_SUPER_ADMIN':
        return {
          administrateAll: true,
          createForms: true,
          administratePeople: true,
          administrateForms: true,
          invitePeople: true,
          accessConsole: true,
          sendFullBroadcasts: true,
          administrateFiles: true,
          assignCoverBroadcasts: true,
          sendCoverBroadcasts: true,
        };
      case 'ROLE_ADMIN':
        return {
          administrateAll: false,
          createForms: true,
          administratePeople: true,
          administrateForms: true,
          invitePeople: true,
          accessConsole: true,
          sendFullBroadcasts: true,
          administrateFiles: true,
          assignCoverBroadcasts: true,
          sendCoverBroadcasts: true,
        };
      case 'ROLE_BROADCAST_ADMIN':
        return {
          administrateAll: false,
          createForms: false,
          administratePeople: false,
          administrateForms: false,
          invitePeople: false,
          accessConsole: true,
          sendFullBroadcasts: true,
          administrateFiles: true,
          assignCoverBroadcasts: true,
          sendCoverBroadcasts: true,
        };
      case 'ROLE_APP_ADMIN':
        return {
          administrateAll: false,
          createForms: false,
          administratePeople: false,
          administrateForms: false,
          invitePeople: false,
          accessConsole: false,
          sendFullBroadcasts: false,
          administrateFiles: false,
          assignCoverBroadcasts: true,
          sendCoverBroadcasts: true,
        };
      case 'ROLE_APP_BROADCAST':
        return {
          administrateAll: false,
          createForms: false,
          administratePeople: false,
          administrateForms: false,
          invitePeople: false,
          accessConsole: false,
          sendFullBroadcasts: false,
          administrateFiles: false,
          assignCoverBroadcasts: false,
          sendCoverBroadcasts: true,
        };
      default:
        return {
          administrateAll: false,
          createForms: false,
          administratePeople: false,
          administrateForms: false,
          invitePeople: false,
          accessConsole: false,
          sendFullBroadcasts: false,
          administrateFiles: false,
          assignCoverBroadcasts: false,
          sendCoverBroadcasts: false,
        };
    }
  }, [roleId]);

  return (
    <StyledDescriptionContainer>
      <FormGroup>
        <StyledCheckboxLabel
          control={ <StyledCheckbox size="small" disabled checked={ permissions.administrateAll }/> }
          label={
            <FormattedMessage
              id="role.description.administrateAll"
              defaultMessage="Administrate all features"
            />
          }
        />
        <StyledCheckboxLabel
          control={ <StyledCheckbox size="small" disabled checked={ permissions.invitePeople }/> }
          label={
            <FormattedMessage
              id="role.description.invitePeople"
              defaultMessage="Invite people to their teams"
            />
          }
        />
        <StyledCheckboxLabel
          control={ <StyledCheckbox size="small" disabled checked={ permissions.administratePeople }/> }
          label={
            <FormattedMessage
              id="role.description.administratePeople"
              defaultMessage="Administrate their team members"
            />
          }
        />
        {
          formsEnabled && (
            <StyledCheckboxLabel
              control={ <StyledCheckbox size="small" disabled checked={ permissions.administrateForms }/> }
              label={
                <FormattedMessage
                  id="role.description.administrateForms"
                  defaultMessage="Administrate smart forms for their teams"
                />
              }
            />
          )
        }
        <StyledCheckboxLabel
          control={ <StyledCheckbox size="small" disabled checked={ permissions.sendFullBroadcasts }/> }
          label={
            <FormattedMessage
              id="role.description.sendFullBroadcasts"
              defaultMessage="Send broadcasts of all content types to their teams"
            />
          }
        />
        {
          filesEnabled && (
            <StyledCheckboxLabel
              control={ <StyledCheckbox size="small" disabled checked={ permissions.administrateFiles }/> }
              label={
                <FormattedMessage
                  id="role.description.administrateFiles"
                  defaultMessage="Administrate files for their teams"
                />
              }
            />
          )
        }
        <StyledCheckboxLabel
          control={ <StyledCheckbox size="small" disabled checked={ permissions.accessConsole }/> }
          label={
            <FormattedMessage
              id="role.description.accessAdminMenu"
              defaultMessage="Access the Admin Menu"
            />
          }
        />
        <StyledCheckboxLabel
          control={ <StyledCheckbox size="small" disabled checked={ permissions.assignCoverBroadcasts }/> }
          label={
            <FormattedMessage
              id="role.description.assignCoverBroadcasts"
              defaultMessage="Assign cover broadcasts to their teams"
            />
          }
        />
        <StyledCheckboxLabel
          control={ <StyledCheckbox size="small" disabled checked={ permissions.sendCoverBroadcasts }/> }
          label={
            <FormattedMessage
              id="role.description.sendCoverBroadcasts"
              defaultMessage="Send cover broadcasts to their teams"
            />
          }
        />
        {
          filesEnabled && (
            <StyledCheckboxLabel
              control={ <StyledCheckbox size="small" disabled checked={ true }/> }
              label={
                <FormattedMessage
                  id="role.description.viewFiles"
                  defaultMessage="View files"
                />
              }
            />
          )
        }
        { streamChatEnabled && (
          <StyledCheckboxLabel
            control={ <StyledCheckbox size="small" disabled checked={ true }/> }
            label={
              <FormattedMessage
                id="role.description.useChat"
                defaultMessage="Start and join conversations"
              />
            }
          />
        ) }
        <StyledCheckboxLabel
          control={ <StyledCheckbox size="small" disabled checked={ true }/> }
          label={
            <FormattedMessage
              id="role.description.receiveBroadcasts"
              defaultMessage="Receive broadcasts"
            />
          }
        />
        <StyledCheckboxLabel
          control={ <StyledCheckbox size="small" disabled checked={ true }/> }
          label={
            <FormattedMessage
              id="role.description.appAccess"
              defaultMessage="Access the mobile app and web app"
            />
          }
        />
      </FormGroup>
    </StyledDescriptionContainer>
  );
};
