import styled from '@emotion/styled';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';

import { css } from '../../../../../../src/react/Styling/css';

export const editorStyles = css`
  line-height: 1.25;

  h1 {
    margin-bottom: ${ props => props.theme.spacing(2) };
    font-size: 1.5em;
  }

  h2 {
    margin-bottom: ${ props => props.theme.spacing(1.5) };
    font-size: 1.25em;
  }

  p {
    margin-bottom: ${ props => props.theme.spacing(1) };
  }

  b, strong, .editor-text-bold {
    font-weight: bolder;
  }

  i, em, .editor-text-italic {
    font-style: italic;
  }
`;

export const StyledContentEditable = styled(ContentEditable)<{ hideToolbar?: boolean; fixedHeight: boolean }>`
  border: 1px solid ${ props => props.theme.palette.grey[300] };
  border-bottom-left-radius: ${ props => props.theme.borderRadius.sm };
  border-bottom-right-radius: ${ props => props.theme.borderRadius.sm };
  padding: ${ props => props.theme.spacing(1) };
  max-height: 400px;
  overflow-y: auto;
  min-height: 150px;
  ${ props => props.fixedHeight && 'height: 100%' };

  ${ props => props.hideToolbar && `
    border-top-left-radius: ${ props.theme.borderRadius.sm };
    border-top-right-radius: ${ props.theme.borderRadius.sm };
  ` }
  
  ${ props => props.disabled && `
    background-color: ${ props.theme.new.palette.grey[200].main };
    color: ${ props.theme.new.palette.grey[500].main };
  ` }

  ${ editorStyles };
`;

export const StyledStack = styled(Stack)<{ fixedHeight: boolean }>`
  ${ props => props.fixedHeight && 'height: 100%' };
`;

export const StyledEditorContainer = styled.div<{ fixedHeight: boolean }>`
  ${ props => props.fixedHeight && 'height: 100%' };
`;

export const StyledCtaBanner = styled.div`
  border-radius: ${ props => props.theme.borderRadius.sm };
  border: 1px solid ${ props => props.theme.palette.grey[300] };
  background-color: ${ props => props.theme.palette.grey[100] };
  padding: ${ props => props.theme.spacing(1) };

  .MuiButton-label > div > span {
    display: flex;
    flex-wrap: nowrap;
    gap: ${ props => props.theme.spacing(1) };
  }
`;
