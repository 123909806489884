import { FC } from 'react';
import { TooltipProps, Tooltip as MuiTooltip } from '@mui/material';

/** @deprecated Prefer Tooltip from op-storybook project */
export const DeprecatedTooltip: FC<TooltipProps> = ({ children, ...props }) => (
  <MuiTooltip
    arrow
    css={ theme => ({
      '.MuiTooltip-tooltip': {
        background: theme.new.palette.teal[900].main,
        color: theme.new.palette.teal[900].contrast,
        fontSize: theme.spacing(1.75),
        padding: theme.spacing(1.5),
        margin: 0,
        borderRadius: theme.new.borderRadius.standard,
        '& a': {
          color: theme.palette.secondary.light,
        },
        boxShadow: theme.new.shadow.sm,
      },
      '.MuiTooltip-arrow': {
        color: theme.new.palette.teal[900].main,
      },
    }) }
    { ...props }
  >
    { children }
  </MuiTooltip>
);
