import { FC, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import seedRandom from 'seedrandom';

import { StyleBuilder } from '../../../lib/model/StyleBuilder/StyleBuilder';
import { defaultTheme } from '../../../lib/const/defaultTheme';

type Props = {
  seed?: string;
  name: string;
};

export const AvatarFallback: FC<Props> = ({
  seed,
  name,
}) => {
  const initials = (name || '').split(/\s/).map(part => part.slice(0, 1));
  const rng = useMemo(() => seedRandom(seed || name), [seed, name]);
  const gradient = useMemo(() => gradientArray[Math.floor(rng() * gradientArray.length)], [rng]);
  const styles = useMemo(() => buildStyles({ gradient }), [gradient]);

  return (
    <div
      css={ styles.container }
    >
      <span
        css={ styles.text }
      >
        <FormattedMessage
          description="Initials representing name in default avatar."
          defaultMessage="{firstInitial}{secondInitial}"
          values={ {
            firstInitial: initials[0] || '',
            secondInitial: initials[1] || '',
          } }
        />
      </span>
    </div>
  );
};

const gradientArray = Array.from(Object.values(defaultTheme.new.gradients));

const buildStyles: StyleBuilder<{ gradient: string }> = ({ gradient }) => ({
  container: theme => ({
    backgroundImage: gradient,
    color: theme.new.basePalette.white.main,
    height: '100%',
    width: '100%',
    textTransform: 'uppercase',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1em',
    userSelect: 'none',
  }),
  text: {
    lineHeight: 0,
    marginTop: '0.15em',
  },
});
