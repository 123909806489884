import { FC, MouseEventHandler, ReactNode } from 'react';

import { Stack } from '../../../lib/components/Stack/Stack';
import { Typography } from '../Typography/Typography';
import { StackEnd } from '../../../lib/components/StackEnd/StackEnd';
import { MenuItem } from '../MenuItem/MenuItem';

type Props = {
  text: string;
  supportingText?: string;
  disabled?: boolean;
  selected?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
};

export const DropdownItem: FC<Props> = ({
  text,
  supportingText,
  disabled = false,
  selected = false,
  onClick,
  startAdornment,
  endAdornment,
}) => (
  <MenuItem
    disabled={ disabled }
    selected={ selected }
    onClick={ onClick }
    padding="sm"
  >
    <Stack
      noWrap
      gap={ 2 }
    >
      { startAdornment }
      <Stack gap={ 2 }>
        <Typography
          palette={ {
            colour: 'grey',
            intensity: disabled ? 300 : 900,
          } }
          size="md"
          weight="medium"
        >
          { text }
        </Typography>
        { supportingText && (
          <Typography
            palette={ {
              colour: 'grey',
              intensity: disabled ? 300 : 600,
            } }
            size="md"
            weight="regular"
          >
            { supportingText }
          </Typography>
        ) }
      </Stack>
      { endAdornment && (
        <StackEnd>
          { endAdornment }
        </StackEnd>
      ) }
    </Stack>
  </MenuItem>
);
