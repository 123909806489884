import { ChangeEvent, Dispatch, FC, ReactNode, SetStateAction, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { isToday, parse, parseISO } from 'date-fns';

import { InterFieldText } from '..';
import { StyledScheduleOnceSettings } from './style';
import { NoMarginTextField } from '../../../Common/Component';
import { DateTimeFormatter } from '../../../Utility/DateTimeFormatter';
import { Schedule } from '../../Model/BroadcastSchedule';

type Props = {
  localStartDate: Date;
  setSchedule: Dispatch<SetStateAction<Schedule>>;
};

export const ScheduleOnceSettings: FC<Props> = ({
  localStartDate,
  setSchedule,
}) => {
  const minDate = DateTimeFormatter.dateInput(new Date());
  const date = DateTimeFormatter.dateInput(localStartDate);
  const time = DateTimeFormatter.timeInput(localStartDate);
  const minTime = isToday(parse(date, 'yyyy-MM-dd', new Date()))
    ? DateTimeFormatter.timeInput(new Date())
    : undefined;

  const whenDateTimeChanged = useCallback((date: string, time: string) => {
    const localStartDate = parse(time, 'HH:mm', parseISO(date));
    setSchedule({
      localStartDate: localStartDate,
      recurrence: null,
    });
  }, [setSchedule]);

  const whenDateChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const date = event.currentTarget.value;
    if (!date) {
      return;
    }

    whenDateTimeChanged(date, time);
  }, [time, whenDateTimeChanged]);

  const whenTimeChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const time = event.currentTarget.value;
    if (!time) {
      return;
    }

    whenDateTimeChanged(date, time);
  }, [date, whenDateTimeChanged]);

  const dateInput = useMemo(() => (
    <NoMarginTextField
      id="broadcast-scheduling-on"
      variant="outlined"
      type="date"
      required
      name="scheduledDate"
      InputProps={ {
        inputProps: {
          min: minDate,
          role: 'textbox',
        }
      } }
      onChange={ whenDateChanged }
      value={ date }
    />
  ), [minDate, date, whenDateChanged]);

  const timeInput = useMemo(() => (
    <NoMarginTextField
      id="broadcast-scheduling-at"
      variant="outlined"
      type="time"
      required
      name="scheduledTime"
      InputProps={ {
        inputProps: {
          min: minTime,
          role: 'textbox',
        },
      } }
      onChange={ whenTimeChanged }
      value={ time }
    />
  ), [minTime, time, whenTimeChanged]);

  return (
    <StyledScheduleOnceSettings>
      <FormattedMessage
        id="scheduleOnce.fields"
        description="Layout of form fields for broadcast schedule once"
        defaultMessage="<text>on</text>{dateInput}<text>at</text>{timeInput}"
        values={ {
          dateInput,
          timeInput,
          text: (chunks: ReactNode[]) => <InterFieldText>{ chunks }</InterFieldText>,
        } }
      />
    </StyledScheduleOnceSettings>
  );
};
