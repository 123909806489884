import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { StyledRecalledLabel } from './style';

export const RecalledLabel: FC = () => (
  <StyledRecalledLabel>
    <FormattedMessage
      defaultMessage="Recalled"
      description="Label displayed next to broadcast title to indicate it has been recalled."
    />
  </StyledRecalledLabel>
);
