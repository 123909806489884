import { useMemo } from 'react';

import {
  Content,
  CoverStatusDetail,
  Delivery,
  DeprecatedMinimalPerson,
  MeetingStatusDetail,
  TrainingStatusDetail
} from '../../../../Models';
import { FetchResponse, useGet } from '../../../../Hooks';
import {
  BroadcastNotificationSettings,
  MinimalBroadcastCategory,
} from '../../../../Broadcasts/Model';
import { UserInteraction } from '../../../../Models/UserInteraction';

export interface CardReport {
  content: Content;
  counts: {
    notResponded: MinimalCount;
    notSeen: MinimalCount;
    responded: MinimalCount | RespondedEventCount | RespondedOptionCount | RespondedRatingCount;
    seen: MinimalCount;
  };
  status: CoverStatusDetail | MeetingStatusDetail | TrainingStatusDetail | null;
}

interface MinimalCount {
  total: number;
}

interface ActiveCount extends MinimalCount {
  active: number;
  notificationDelivered: number;
  notificationAttempted: number;
}

export interface RespondedCoverCount extends MinimalCount {
  accepted: number;
  declined: number;
}

export interface RespondedEventCount extends MinimalCount {
  attended: number;
  attending: number;
  declined: number;
  waiting: number;
}

export interface RespondedOptionCount extends MinimalCount {
  correct?: number;
  incorrect?: number;
  options: {
    id: string;
    count: number;
  }[];
}

export interface RespondedRatingCount extends MinimalCount {
  ratings: {
    rating: number;
    count: number;
  }[];
}

export type BroadcastReceivedNotification = {
  service: string;
  total: number;
  responded: number;
  delivered: number;
};

type ResponseSourceCounts = {
  source: string;
  total: number;
};

export type RespondedCounts = {
  total: number;
  sources: ResponseSourceCounts[];
}

export interface BroadcastReportSummary {
  broadcast: {
    createdBy: DeprecatedMinimalPerson;
    id: string;
    name: string;
    nudge: {
      delay: number;
      message: string;
    } | null;
    silent: boolean;
    summarySms: string | null;
    timeCreated: string;
    timeSent: string | null;
    published: UserInteraction | null;
    recipientAudienceId: string;
    category: MinimalBroadcastCategory;
    notification: BroadcastNotificationSettings;
  };
  cards: CardReport[];
  counts: {
    notResponded: ActiveCount;
    notSeen: ActiveCount;
    recipients: ActiveCount & { pending: number; inactive: number; };
    responded: RespondedCounts;
    seen: MinimalCount;
    smsRecipients: MinimalCount & { sent: number; };
    broadcastReceivedNotifications: BroadcastReceivedNotification[];
  };
  delivery: Delivery;
}

export const useFetchBroadcastReportSummary = (
  broadcastId: string,
  deliveryId?: string,
): FetchResponse<BroadcastReportSummary> => {
  const params = useMemo(() => deliveryId ? { deliveryId } : {}, [deliveryId]);
  return useGet<BroadcastReportSummary>(`/broadcasts/${ broadcastId }/report-summary`, params);
};
