import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { FormatNumberOptions } from '@formatjs/intl/src/types';
import { Link } from 'react-router-dom';

import { AnimatedNumber, Flex, FlexPullRight, PercentageChange } from '../../../Common/Component';
import { StyledNumericMetric } from './style';

interface Props {
  viewMoreCta?: string;
  viewMoreLink?: string;
  previousValue: number;
  currentValue: number;
  numberFormattingOptions?: FormatNumberOptions;
  displayChangePercent?: boolean;
}

export const NumericMetric: FunctionComponent<Props> = ({
  viewMoreCta,
  viewMoreLink,
  previousValue,
  currentValue,
  numberFormattingOptions,
  displayChangePercent = true,
}) => {
  const viewMore = viewMoreCta || (
    <FormattedMessage
      id="reporting.numericBlock.viewMoreDefault"
      description="Default value for view more CTA text in a numeric report block."
      defaultMessage="View more"
    />
  );

  return (
    <StyledNumericMetric>
      <AnimatedNumber
        initialValue={ previousValue }
        targetValue={ currentValue }
        formattingOptions={ numberFormattingOptions }
      />
      <Flex align="flex-end">
        { displayChangePercent && (
          <PercentageChange
            previousValue={ previousValue }
            currentValue={ currentValue }
          />
        ) }
        {
          viewMoreLink && (
            <FlexPullRight>
              <Link to={ viewMoreLink }>{ viewMore }&nbsp;&gt;</Link>
            </FlexPullRight>
          )
        }
      </Flex>
    </StyledNumericMetric>
  );
};
