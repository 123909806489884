import { FunctionComponent, useState } from 'react';

import { ContentPreviewProps } from '../../../../Content/Model';
import { TextRequestEditorContent } from '../../../Service';
import { TextRequest } from '../TextRequest/TextRequest';

export const TextRequestPreview: FunctionComponent<ContentPreviewProps<TextRequestEditorContent>> = ({
  editorContent,
}) => {
  const [value, setValue] = useState<string>('');

  return (
    <TextRequest
      content={ editorContent.card.content }
      value={ value }
      onChange={ setValue }
      mandatory={ editorContent.card.mandatory }
    />
  );
};
