import { ComponentProps, FC, useState } from 'react';
import { Slider } from 'op-storybook/stories/components/Slider/Slider';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { IconButton } from 'op-storybook/stories/components/IconButton/IconButton';
import ZoomOutIcon from 'op-storybook/lib/assets/icon/figma/zoom-out.svg';
import ZoomInIcon from 'op-storybook/lib/assets/icon/figma/zoom-in.svg';
import RotateIcon from 'op-storybook/lib/assets/icon/figma/corner-right-down.svg';
import FlipIcon from 'op-storybook/lib/assets/icon/figma/reflect-01.svg';
import { BreakpointContext } from 'op-storybook/lib/providers/BreakpointProvider/BreakpointProvider';

import { Cropper } from '../Cropper/Cropper';
import { useContextOrThrow } from '../../../Core/Hook';

type Props = Omit<ComponentProps<typeof Cropper>, 'zoom' | 'flipVertical' | 'flipHorizontal' | 'rotation'>;

export const CropperWithControls: FC<Props> = ({
  ...cropperProps
}) => {
  const [zoom, setZoom] = useState<number>(0);
  const [flipHorizontal, setFlipHorizontal] = useState<boolean>(false);
  const [rotations, setRotations] = useState<ComponentProps<typeof Cropper>['rotations']>(0)
  const screenWidth = useContextOrThrow(BreakpointContext);

  return (
    <Stack direction="column">
      <Cropper
        { ...cropperProps }
        flipHorizontal={ flipHorizontal }
        rotations={ rotations }
        zoom={ {
          value: zoom,
          onChange: setZoom,
        } }
        css={ theme => ({
          width: '100%',
          height: '500px',
          borderRadius: theme.new.borderRadius.large,

          '.advanced-cropper-circle-stencil__preview, .advanced-cropper-rectangle-stencil__preview': {
            border: `2px solid ${ theme.new.palette.primary[600].main }`,
          },
        }) }
      />
      <Stack justify="center" gap={ 0 }>
        <IconButton
          variant="tertiary-grey"
          IconComponent={ FlipIcon }
          label="Flip"
          onClick={ () => setFlipHorizontal(flipHorizontal => !flipHorizontal) }
          size="small"
        />
        { !screenWidth.lessThan.sm && (
          <Stack fillParent>
            <IconButton
              variant="tertiary-grey"
              IconComponent={ ZoomOutIcon }
              label="Zoom out"
              onClick={ () => setZoom(zoom => Math.max(ZOOM_MIN, zoom - ZOOM_STEP)) }
              size="small"
            />
            <Slider
              min={ ZOOM_MIN }
              max={ ZOOM_MAX }
              step={ ZOOM_STEP }
              value={ zoom }
              onChange={ setZoom }
            />
            <IconButton
              variant="tertiary-grey"
              IconComponent={ ZoomInIcon }
              label="Zoom in"
              onClick={ () => setZoom(zoom => Math.min(ZOOM_MAX, zoom + ZOOM_STEP)) }
              size="small"
            />
          </Stack>
        ) }
        <IconButton
          variant="tertiary-grey"
          IconComponent={ RotateIcon }
          label="Rotate"
          onClick={ () => setRotations(rotations => (rotations || 0) + 1) }
          size="small"
        />
      </Stack>
    </Stack>
  );
};

const ZOOM_MIN = 0;
const ZOOM_MAX = 1;
const ZOOM_STEP = 0.01;
