import { FC, useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getSelection, COMMAND_PRIORITY_LOW, FOCUS_COMMAND } from 'lexical';

type Props = {
  onFocus: (selectionOffset: number | null) => void;
};

export const OnFocusPlugin: FC<Props> = ({ onFocus }) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerCommand(
      FOCUS_COMMAND,
      (event) => {
        onFocus($getSelection()?.getStartEndPoints()?.[0]?.offset || null);
        return true;
      },
      COMMAND_PRIORITY_LOW,
    );
  }, [editor, onFocus]);

  return null;
};
