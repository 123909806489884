import { FunctionComponent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Button } from '@mui/material';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';

import { FlexPullRight } from '../../../Common/Component';
import { CreateDialog, ListTables } from '../../Component';
import { ApiClientWithSecret } from '../../Model';
import { useUserRoles } from '../../../Common/Hook';

export const ApiClientsPage: FunctionComponent = () => {
  const match = useRouteMatch();
  const { userIsDeveloper } = useUserRoles();
  const [createDialogOpen, setCreateDialogOpen] = useState<boolean>(false);
  const [addedClients, setAddedClients] = useState<ApiClientWithSecret[]>([]);

  const whenSaved = (client: ApiClientWithSecret): void => {
    setAddedClients(addedClients => addedClients.concat(client));
  };

  return (
    <>
      <Stack direction="column">
        {
          userIsDeveloper && (
            <FlexPullRight>
              <Button
                color="primary"
                variant="contained"
                disableElevation
                onClick={ () => setCreateDialogOpen(true) }
              >
                <FormattedMessage
                  id="apiClients.addClient"
                  description="Add client button cta"
                  defaultMessage="Add client"
                />
              </Button>
            </FlexPullRight>
          )
        }
        <Switch>
          <Route exact path={ match.path }>
            <ListTables
              onAddButtonClicked={ () => setCreateDialogOpen(true) }
              addedClients={ addedClients }
            />
          </Route>
        </Switch>
      </Stack>
      <CreateDialog
        open={ createDialogOpen }
        onClosed={ () => setCreateDialogOpen(false) }
        onSaved={ whenSaved }
      />
    </>
  );
};
