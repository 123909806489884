import { ComponentProps, FC, PropsWithChildren } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { InterpolationPrimitive } from '@emotion/serialize';
import { FunctionInterpolation } from '@emotion/react';

import { DecorativePattern } from '../../../stories/components/DecorativePattern/DecorativePattern';
import { Typography } from '../../../stories/components/Typography/Typography';
import { Stack } from '../Stack/Stack';
import { PresentationIcon } from '../PresentationIcon/PresentationIcon';
import HelpIcon from '../../assets/icon/figma/help-circle.svg?react';
import { defaultTheme } from '../../const/defaultTheme';
import { PolicyLinks } from '../PolicyLinks/PolicyLinks';

type Props = {
  helpLink?: string;
  className?: string;
  policyLinkProps?: ComponentProps<typeof PolicyLinks>;
};

export const AuthFormPane: FC<PropsWithChildren<Props>> = ({
  helpLink = 'https://get.ourpeople.help',
  policyLinkProps,
  className,
  children,
}) => {
  const intl = useIntl();

  return (
    <div
      className={ className }
      css={ paneCss }
    >
      <div css={ formContainerCss }>
        <div css={ patternContainerCss }>
          <DecorativePattern pattern="grid" size="lg"/>
        </div>
        { children }
      </div>
      <div css={ authPaneFooterCss }>
        <Typography
          size="sm"
          palette={ { colour: 'grey', intensity: 600 } }
          css={ { marginRight: 'auto' } }
        >
          <FormattedMessage
            description="Copyright message on auth pages"
            defaultMessage="© { year } OurPeople"
            values={ {
              year: new Date().toLocaleDateString(intl.locale, { year: 'numeric' }),
            } }
          />
        </Typography>
        { policyLinkProps && <PolicyLinks { ...policyLinkProps }/> }
        <a
          href={ helpLink }
          target="_blank"
          rel="noreferrer"
          css={ {
            marginLeft: 'auto',
          } }
        >
          <Stack gap={ 2 }>
            <PresentationIcon IconComponent={ HelpIcon }/>
            <FormattedMessage
              description="Helpdesk link label on auth pages"
              defaultMessage="Need help?"
            />
          </Stack>
        </a>
      </div>
    </div>
  );
}

const authPaneFooterCss: InterpolationPrimitive = {
  padding: '2rem',
  width: '100%',
  display: 'flex',
  alignItems: 'flex-end',
};

const paneCss: InterpolationPrimitive = {
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  boxShadow: '-50px 0 25px 50px rgba(0, 0, 0, 0.05)',
  overflowX: 'hidden',
};

const patternContainerCss: FunctionInterpolation<typeof defaultTheme> = () => ({
  position: 'absolute',
  width: '100%',
  transform: 'translateY(-400px)',
  zIndex: -1,
  pointerEvents: 'none',
  userSelect: 'none',
});

const formContainerCss: FunctionInterpolation<typeof defaultTheme> = theme => ({
  padding: theme.new.spacing[4],
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  gap: theme.new.spacing[10],
  margin: 'auto',
  minWidth: '300px',
  width: '50%',
  maxWidth: '500px',

  [theme.new.breakpoints.lg.down]: {
    width: '100%',
  },
});
