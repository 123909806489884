import { DraftFormContent, FormContent } from '../Content';
import { Upload } from '../../../Types';

export const VIDEO_CONTENT_TEXT_MAX_LENGTH = 4096;

export type VideoContent = FormContent & {
  type: 'videoContent';
  text: string;
  thumbnailKey: string;
  upload: Upload;
};

export type DraftVideoContent = DraftFormContent & {
  type: 'videoContent';
  text: string;
  thumbnailKey: string | null;
  uploadId: string | null;
};
