import { Link } from 'react-router-dom';
import { FunctionComponent, useMemo } from 'react';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';

import { StepperItem } from '..';
import { StepStatus } from '..';

type Props = {
  step: StepperItem;
  active?: boolean;
}

export const StepLink: FunctionComponent<Props> = ({
  step,
  active = false,
}) => {
  const button = useMemo(() => (
    <Button
      color="inherit"
      value={ step.link }
      hidden={ step.condition !== undefined && !step.condition }
      disabled={ step.disabled }
    >
      { step.title }
    </Button>
  ), [step]);

  return (
    <StepStatus
      key={ step.link }
      active={ active }
      error={ step.error }
    >
      { step.disabled
        ? button
        : (
          <Link to={ step.link }>
            { button }
          </Link>
        ) }
    </StepStatus>
  )
};
