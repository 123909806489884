import { FC, useCallback, useMemo, useState } from 'react';
import { FormattedList, FormattedMessage, useIntl } from 'react-intl';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Tooltip } from '@mui/material';
import { Heading } from '@ourpeople/shared/Core/Component/Content';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';
import { LocalisedString } from 'op-storybook/lib/model/LocalisedString/LocalisedString';

import {
  ChatStatus,
  FetchChatAudienceMembersParams,
  useFetchChatAudienceMembers,
  useLocalisedChatStatuses
} from '../../Hook';
import { PaginatedTable, SortableHeaderCell, TableCell, TableRow } from '../../../Components';
import { Audience } from '../../../Audiences/Model';
import { FetchAudienceMembersSort } from '../../../Audiences/Hook';
import {
  Flex,
  PresentationIcon,
  VerticallySpaced,
  TableFilters,
  Chip
} from '../../../Common/Component';
import InfoIcon from '../../../Assets/img/icons/streamline/information-circle.svg';
import BanIcon from '../../../Assets/img/icons/streamline/single-neutral-actions-block.svg';
import RemoveIcon from '../../../Assets/img/icons/streamline/single-neutral-actions-subtract.svg';
import { TableRowContextMenu } from '../../../Common/Component/TableRowContextMenu/TableRowContextMenu';
import { AudienceMemberChatStatus, ChatStatusAutocomplete } from '..';
import { StyledStatusTooltip, StyledStatusSummary } from './style';
import { useApi, useContextOrThrow } from '../../../Core/Hook';
import { useMounted } from '../../../Common/Hook';
import { MinimalPerson } from '../../../Models';
import { PersonParser } from '../../../Utility';
import { usePermissions } from '../../../Security/Hook';
import { ChatsPermission } from '../../Model';
import { FilteredCount } from '../../../Audiences/Component/AudienceMemberList/style';
import { NonWrappingCell } from '../../../Forms/Component';
import { ToastContext } from '../../../Core/Context';

type Props = {
  chatId: string;
  audience?: Audience;
  initialQuery?: FetchChatAudienceMembersParams['query'];
};

export const ChatAudienceMemberList: FC<Props> = ({
  chatId,
  audience,
  initialQuery,
}) => {
  const intl = useIntl();
  const api = useApi();
  const { permissionAvailable, guardedCallback } = usePermissions();
  const localisedChatStatuses = useLocalisedChatStatuses();
  const [query, setQuery] = useState<FetchChatAudienceMembersParams['query']>(initialQuery ?? defaultQuery);
  const mounted = useMounted();
  const [removeDialogOpen, setRemoveDialogOpen] = useState<boolean>(false);
  const [banDialogOpen, setBanDialogOpen] = useState<boolean>(false);
  const [banNote, setBanNote] = useState<string>('');
  const fetchParams = useMemo<FetchChatAudienceMembersParams>(
    () => ({
      audience,
      query,
    }),
    [audience, query],
  );
  const [fetchChatAudienceMembersResult, fetchChatAudienceMembersState, reloadChatAudienceMembers] = useFetchChatAudienceMembers(chatId, fetchParams);
  const audienceMembers = fetchChatAudienceMembersResult?.content?.members || [];
  const pagination = fetchChatAudienceMembersResult?.content?.pagination;
  const [confirmingBanPerson, setConfirmingBanPerson] = useState<MinimalPerson>();
  const [confirmingRemovePerson, setConfirmingRemovePerson] = useState<MinimalPerson>();
  const [banningPerson, setBanningPerson] = useState<MinimalPerson>();
  const [removingPerson, setRemovingPerson] = useState<MinimalPerson>();
  const { addSuccessToast, addErrorToast } = useContextOrThrow(ToastContext);
  const filteredMemberCount = (fetchChatAudienceMembersResult?.content?.audienceSize || 0) - (pagination?.itemCount || 0);

  const whenSortChanged = (sort: FetchAudienceMembersSort) => (
    setQuery(query => ({
      ...query,
      sort: [sort],
    }))
  );

  const whenPageNumChanged = (pageNum: number) => (
    setQuery(query => ({
      ...query,
      pageNum,
    }))
  );

  const whenClearFiltersClicked = () => (
    setQuery(defaultQuery)
  );

  const whenSearchChanged = (search: string) => (
    setQuery(query => ({
      ...query,
      pageNum: 1,
      search: search.trim().length > 0
        ? search
        : undefined,
    }))
  );

  const whenChatStatusesChanged = (selection: LocalisedString<ChatStatus>[]) => {
    setQuery(query => ({
      ...query,
      chatStatuses: selection.length > 0
        ? selection.map(status => status.id)
        : undefined,
    }));
  };

  const chips = useMemo<Chip[]>(() => (
    (query.chatStatuses || []).map(status => ({
      id: status,
      label: intl.formatMessage({
        id: 'chatAudienceMemberList.chip.status',
        description: 'Label for chat status filter chip in chat audience member list.',
        defaultMessage: 'Status: { localisedStatus }',
      }, {
        localisedStatus: localisedChatStatuses[status],
      }),
      type: 'status',
      data: status,
    }))
  ), [intl, localisedChatStatuses, query.chatStatuses]);

  const whenRemoveChipClicked = useCallback((chip: Chip) => (
    setQuery(query => {
      const chatStatuses = (query.chatStatuses || []).filter(chatStatus => chatStatus !== chip.id);
      return {
        ...query,
        chatStatuses: chatStatuses.length > 0
          ? chatStatuses
          : undefined,
      };
    })
  ), []);

  const whenBanPersonConfirmed = useCallback((person: MinimalPerson) => {
    setBanDialogOpen(false);
    setBanningPerson(person);
    api.post(`/chats/topics/${ chatId }/members/ban`, {
      personId: person.id,
      ...(banNote ? { note: banNote } : {}),
    })
      .then(() => {
        setTimeout(() => {
          if (!mounted.current) {
            return;
          }

          addSuccessToast(
            intl.formatMessage({
              id: 'chatAudienceMemberList.ban.success',
              description: 'Success message when person is banned from audience.',
              defaultMessage: '{ name } banned successfully.',
            }, {
              name: PersonParser.fullName(person),
            })
          );
          setBanningPerson(undefined);
          reloadChatAudienceMembers();
        }, 50);
      })
      .catch(() => {
        if (!mounted.current) {
          return;
        }

        addErrorToast(
          intl.formatMessage({
            id: 'chatAudienceMemberList.ban.failure',
            description: 'Failure message when person is banned from audience.',
            defaultMessage: '{ name } could not be banned, please try again.',
          }, {
            name: PersonParser.fullName(person),
          })
        );
        setBanningPerson(undefined);
      });
  }, [addErrorToast, addSuccessToast, api, banNote, chatId, intl, mounted, reloadChatAudienceMembers]);

  const whenRemovePersonConfirmed = useCallback((person: MinimalPerson) => {
    setRemoveDialogOpen(false);
    setRemovingPerson(person);
    api.post(`/chats/topics/${ chatId }/members/remove`, { personId: person.id })
      .then(() => {
        setTimeout(() => {
          if (!mounted.current) {
            return;
          }

          addSuccessToast(
            intl.formatMessage({
              id: 'chatAudienceMemberList.remove.success',
              description: 'Success message when person is removed from audience.',
              defaultMessage: '{ name } removed successfully.',
            }, {
              name: PersonParser.fullName(person),
            })
          );
          setRemovingPerson(undefined);
          reloadChatAudienceMembers();
        }, 50);
      })
      .catch(() => {
        if (!mounted.current) {
          return;
        }

        addErrorToast(
          intl.formatMessage({
            id: 'chatAudienceMemberList.remove.failure',
            description: 'Failure message when person is removed from audience.',
            defaultMessage: '{ name } could not be removed, please try again.',
          }, {
            name: PersonParser.fullName(person),
          })
        )
        setRemovingPerson(undefined);
      });
  }, [addErrorToast, addSuccessToast, api, chatId, intl, mounted, reloadChatAudienceMembers]);

  return <>
    <VerticallySpaced gap={ 1 }>
      <div>
        <Heading type="h2">
          <FormattedMessage
            id="chatAudienceMemberList.heading"
            description="Chat audience member table heading with current audience member count."
            defaultMessage="{ memberCount, plural, one { # person } other { # people } } in audience preview"
            values={ {
              memberCount: fetchChatAudienceMembersResult?.content?.audienceSize || 0,
            } }
          />
          {
            !!filteredMemberCount && (
              <FilteredCount>
                <FormattedMessage
                  id="chatAudienceMemberList.filteredCount"
                  description="Section of chat audience member heading showing how many members matching the audience are currently filtered from the view"
                  defaultMessage="{ filteredMemberCount } filtered from view"
                  values={ {
                    filteredMemberCount,
                  } }
                />
              </FilteredCount>
            )
          }
        </Heading>
        <StyledStatusSummary>
          <FormattedList
            value={ [
              ...(!fetchChatAudienceMembersResult ? ['...'] : []),
              ...(fetchChatAudienceMembersResult?.content?.audienceChatStatusCounts.joined ? [
                  intl.formatMessage({
                    id: 'chatAudienceMemberList.joined',
                    description: 'Joined summary in chat audience member list summary.',
                    defaultMessage: '{ joinedCount, plural, one {# member} other {# members} }',
                  }, {
                    joinedCount: fetchChatAudienceMembersResult.content.audienceChatStatusCounts.joined,
                  }),
                ] : []),
              ...(fetchChatAudienceMembersResult?.content?.audienceChatStatusCounts.pending ? [
                  intl.formatMessage({
                    id: 'chatAudienceMemberList.pending',
                    description: 'Pending summary in chat audience member list summary.',
                    defaultMessage: '{ pendingCount } never joined',
                  }, {
                    pendingCount: fetchChatAudienceMembersResult.content.audienceChatStatusCounts.pending,
                  }),
                ] : []),
              ...(fetchChatAudienceMembersResult?.content?.audienceChatStatusCounts.left || fetchChatAudienceMembersResult?.content?.audienceChatStatusCounts.removed ? [
                  intl.formatMessage({
                    id: 'chatAudienceMemberList.leftOrRemoved',
                    description: 'Left or removed summary in chat audience member list summary.',
                    defaultMessage: '{ leftOrRemovedCount } left or removed',
                  }, {
                    leftOrRemovedCount: (fetchChatAudienceMembersResult?.content?.audienceChatStatusCounts.left || 0)
                      + (fetchChatAudienceMembersResult?.content?.audienceChatStatusCounts.removed || 0),
                  }),
                ] : []),
              ...(fetchChatAudienceMembersResult?.content?.audienceChatStatusCounts.banned ? [
                  intl.formatMessage({
                    id: 'chatAudienceMemberList.banned',
                    description: 'Banned summary in chat audience member list summary.',
                    defaultMessage: '{ bannedCount } banned',
                  }, {
                    bannedCount: fetchChatAudienceMembersResult.content.audienceChatStatusCounts.banned
                  }),
                ] : []),
            ] }
          />
        </StyledStatusSummary>
      </div>
      <span>
        <FormattedMessage
          id="chatAudienceMemberList.helpText"
          description="Help text explaining remove and ban in chat audience member table."
          defaultMessage="If you would like to remove or ban individuals without changing the audience filters, select people in the list below."
        />
      </span>
      <div>
        <TableFilters
          searchValue={ query.search || '' }
          onSearchChanged={ whenSearchChanged }
          onClearFiltersClicked={ whenClearFiltersClicked }
          chips={ chips }
          onRemoveChipClicked={ whenRemoveChipClicked }
        >
          <ChatStatusAutocomplete
            selectedIds={ query.chatStatuses || [] }
            onSelectionChanged={ whenChatStatusesChanged }
          />
        </TableFilters>
        <PaginatedTable
          headerRow={
            <TableRow>
              <SortableHeaderCell
                sort={ query.sort[0] }
                ascValue={ 'first_name_asc' }
                descValue={ 'first_name_desc' }
                onSort={ whenSortChanged }
              >
                <FormattedMessage
                  id="chatAudienceMemberList.firstName"
                  description="Label for first name column in chat audience member table."
                  defaultMessage="First name"
                />
              </SortableHeaderCell>
              <SortableHeaderCell
                sort={ query.sort[0] }
                ascValue={ 'last_name_asc' }
                descValue={ 'last_name_desc' }
                onSort={ whenSortChanged }
              >
                <FormattedMessage
                  id="chatAudienceMemberList.lastName"
                  description="Label for last name column in chat audience member table."
                  defaultMessage="Last name"
                />
              </SortableHeaderCell>
              <TableCell>
                <Flex gap={ 2 } noWrap>
                  <FormattedMessage
                    id="chatAudienceMemberList.memberStatus"
                    description="Label for member status column in chat audience member table."
                    defaultMessage="Member status"
                  />
                  <Tooltip
                    title={
                      <StyledStatusTooltip>
                        <FormattedMessage
                          id="chatAudienceMemberList.memberStatus"
                          description="Label for member status column in chat audience member table."
                          defaultMessage={ `
                            <li><strong>Member</strong> - Topic member</li>
                            <li><strong>Left</strong> - Person left but is able to rejoin via "join chat"</li>
                            <li><strong>Removed</strong> - Removed by admin but is able to rejoin via "join chat"</li>
                            <li><strong>Banned</strong> - Removed by admin, unable to rejoin</li>
                            <li><strong>Never joined</strong> - Able to join via "join chat"</li>
                          ` }
                        />
                      </StyledStatusTooltip>
                    }
                  >
                    <div>
                      <PresentationIcon
                        IconComponent={ InfoIcon }
                        color="secondary.main"
                      />
                    </div>
                  </Tooltip>
                </Flex>
              </TableCell>
              { permissionAvailable(ChatsPermission.TOPICS_UPDATE) && (
                <NonWrappingCell>
                  <FormattedMessage
                    id="chatAudienceMemberList.actions"
                    description="Label for actions column in chat audience member table."
                    defaultMessage="Actions"
                  />
                </NonWrappingCell>
              ) }
            </TableRow>
          }
          rows={ audienceMembers }
          rowRender={ audienceMember => (
            <TableRow key={ audienceMember.person.id }>
              <TableCell>
                { audienceMember.person.firstName }
              </TableCell>
              <TableCell>
                { audienceMember.person.lastName }
              </TableCell>
              <TableCell>
                <AudienceMemberChatStatus
                  status={ audienceMember.chatStatus }
                  note={ audienceMember.note }
                />
              </TableCell>
              { permissionAvailable(ChatsPermission.TOPICS_UPDATE) && (
                <NonWrappingCell>
                  <TableRowContextMenu
                    id={ `${ audienceMember.person.id }-context-menu` }
                    actions={ [
                      {
                        id: 'remove',
                        IconComponent: RemoveIcon,
                        label: intl.formatMessage({
                          id: 'chatAudienceMemberList.remove',
                          description: 'Label for remove action in chat audience member list.',
                          defaultMessage: 'Remove',
                        }),
                        busy: removingPerson?.id === audienceMember.person.id,
                        disabled: ['banned', 'removed'].indexOf(audienceMember.chatStatus) !== -1,
                        onClick: guardedCallback(
                          () => {
                            setConfirmingRemovePerson(audienceMember.person)
                            setRemoveDialogOpen(true);
                          },
                          [ChatsPermission.TOPICS_UPDATE],
                        ),
                      },
                      {
                        id: 'ban',
                        IconComponent: BanIcon,
                        label: intl.formatMessage({
                          id: 'chatAudienceMemberList.ban',
                          description: 'Label for ban action in chat audience member list.',
                          defaultMessage: 'Ban',
                        }),
                        busy: banningPerson?.id === audienceMember.person.id,
                        disabled: audienceMember.chatStatus === 'banned',
                        onClick: guardedCallback(
                          () => {
                            setConfirmingBanPerson(audienceMember.person);
                            setBanDialogOpen(true);
                          },
                          [ChatsPermission.TOPICS_UPDATE],
                        ),
                      },
                    ] }
                  />
                </NonWrappingCell>
              ) }
            </TableRow>
          ) }
          pageNum={ query.pageNum }
          onPageChanged={ whenPageNumChanged }
          requestState={ fetchChatAudienceMembersState }
          onRetryClicked={ reloadChatAudienceMembers }
          pagination={ pagination }
        />
      </div>
    </VerticallySpaced>
    { !!confirmingRemovePerson && (
      <Dialog
        open={ removeDialogOpen }
        onClose={ () => setRemoveDialogOpen(false) }
        TransitionProps={ {
          onExited: () => setConfirmingRemovePerson(undefined),
        } }
      >
        <DialogTitle>
          <FormattedMessage
            id="chatAudienceMemberList.remove.title"
            description="Confirmation title when attempting to remove topic audience member."
            defaultMessage="Remove { name }?"
            values={ {
              name: PersonParser.fullName(confirmingRemovePerson),
            } }
          />
        </DialogTitle>
        <DialogContent>
          <FormattedMessage
            id="chatAudienceMemberList.remove.message"
            description="Confirmation message when attempting to remove topic audience member."
            defaultMessage="<p>Are you sure you want to remove { name } from this topic? As an audience member they will be able to rejoin at anytime.</p><p>To prevent them from rejoining please use ban.</p>"
            values={ {
              name: PersonParser.fullName(confirmingRemovePerson),
            } }
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={ () => setRemoveDialogOpen(false) }
          >
            <FormattedMessage
              id="chatAudienceMemberList.remove.cancel"
              description="Label for button to cancel removing audience member from topic."
              defaultMessage="Cancel"
            />
          </Button>
          <Button
            variant="secondary"
            onClick={ () => whenRemovePersonConfirmed(confirmingRemovePerson) }
          >
            <FormattedMessage
              id="chatAudienceMemberList.remove.confirm"
              description="Label for button to confirm removing audience member from topic."
              defaultMessage="Remove"
            />
          </Button>
        </DialogActions>
      </Dialog>
    ) }
    { !!confirmingBanPerson && (
      <Dialog
        open={ banDialogOpen }
        onClose={ () => setBanDialogOpen(false) }
        TransitionProps={ {
          onExited: () => {
            setBanNote('');
            setConfirmingBanPerson(undefined);
          },
        } }
      >
        <DialogTitle>
          <FormattedMessage
            id="chatAudienceMemberList.ban.title"
            description="Confirmation title when attempting to ban topic audience member."
            defaultMessage="Ban { name }?"
            values={ {
              name: PersonParser.fullName(confirmingBanPerson),
            } }
          />
        </DialogTitle>
        <DialogContent>
          <FormattedMessage
            id="chatAudienceMemberList.ban.confirm"
            description="Confirmation message when attempting to ban topic audience member."
            defaultMessage="<p>Are you sure you want to ban { name } from this topic? This action cannot be undone.</p><p>To allow them to rejoin please use remove.</p>"
            values={ {
              name: PersonParser.fullName(confirmingBanPerson),
            } }
          />
          <TextField
            multiline
            fullWidth
            value={ banNote }
            onChange={ event => setBanNote(event.currentTarget.value) }
            placeholder={ intl.formatMessage({
              id: 'chatAudienceMemberList.ban.placeholder',
              description: 'Placeholder for optional note when banning chat audience member.',
              defaultMessage: 'Optional note',
            }) }
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={ () => setBanDialogOpen(false) }
          >
            <FormattedMessage
              id="chatAudienceMemberList.ban.cancel"
              description="Label for button to cancel banning audience member from topic."
              defaultMessage="Cancel"
            />
          </Button>
          <Button
            variant="secondary"
            onClick={ () => whenBanPersonConfirmed(confirmingBanPerson) }
          >
            <FormattedMessage
              id="chatAudienceMemberList.ban.confirm"
              description="Label for button to confirm banning audience member from topic."
              defaultMessage="Ban"
            />
          </Button>
        </DialogActions>
      </Dialog>
    ) }
  </>;
};

const defaultQuery: FetchChatAudienceMembersParams['query'] = {
  pageNum: 1,
  sort: ['first_name_asc'],
  chatStatuses: undefined,
};
