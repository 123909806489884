import styled from '@emotion/styled';

export const StyledCreditSummary = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 1rem;
`;

export const SummaryNumberWithLabel = styled.label`
  ${ props => props.theme.breakpoints.down('md') } {
    grid-column-end: span 2;
  }

  ${ props => props.theme.breakpoints.down('sm') } {
    grid-column-end: span 4;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem;
`;
