import { FC, PropsWithChildren, useMemo } from 'react';

import { ContentDefinition } from '../../Content/Model';
import { ContentDefinitionRegistryProvider } from '../../Content/Provider';
import {
  AudioContentDefinition,
  ChoiceRequestDefinition,
  CommentRequestDefinition,
  CoverDefinition,
  FileContentDefinition,
  ImageContentDefinition,
  MeetingDefinition,
  QuizRequestDefinition,
  RatingRequestDefinition,
  RevealContentDefinition,
  SignRequestDefinition,
  TextContentDefinition,
  TimelineDefinition,
  TrainingDefinition,
  UploadRequestDefinition,
  VideoContentDefinition
} from '../Service';

export const BroadcastContentDefinitionRegistryProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const definitions = useMemo<ContentDefinition[]>(
    () => [
      new AudioContentDefinition() as unknown as ContentDefinition,
      new ChoiceRequestDefinition() as unknown as ContentDefinition,
      new CommentRequestDefinition() as unknown as ContentDefinition,
      new CoverDefinition() as unknown as ContentDefinition,
      new FileContentDefinition() as unknown as ContentDefinition,
      new ImageContentDefinition() as unknown as ContentDefinition,
      new MeetingDefinition() as unknown as ContentDefinition,
      new QuizRequestDefinition() as unknown as ContentDefinition,
      new RatingRequestDefinition() as unknown as ContentDefinition,
      new RevealContentDefinition() as unknown as ContentDefinition,
      new SignRequestDefinition() as unknown as ContentDefinition,
      new TextContentDefinition() as unknown as ContentDefinition,
      new TrainingDefinition() as unknown as ContentDefinition,
      new UploadRequestDefinition() as unknown as ContentDefinition,
      new VideoContentDefinition() as unknown as ContentDefinition,
      new TimelineDefinition() as unknown as ContentDefinition,
    ],
    [],
  );

  return (
    <ContentDefinitionRegistryProvider definitions={ definitions }>
      { children }
    </ContentDefinitionRegistryProvider>
  );
};
