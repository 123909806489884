import { FunctionComponent } from 'react';

import { MinimalForm } from '../../Model';
import { useFormReader } from '../../Hook';

interface Props {
  form: MinimalForm;
}

export const FormName: FunctionComponent<Props> = ({ form }) => <>{ useFormReader(form).name }</>;
