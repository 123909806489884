import { FC, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { AxiosError } from 'axios';

import { Box, PageHeader } from '../../../Common/Component';
import { Padded } from '../../../Components';
import { CategoryEditForm, EditBroadcastCategoryFormState } from '../../Component';
import { useApi, useContextOrThrow } from '../../../Core/Hook';
import { BroadcastCategory } from '../../Model';
import { useMounted } from '../../../Common/Hook';
import { ValidationTree } from '../../../Common/Model';
import { ErrorResponseReader } from '../../../Common/Utility';
import { ToastContext } from '../../../Core/Context';

export const CreateCategoryPage: FC = () => {
  const intl = useIntl();
  const api = useApi();
  const history = useHistory();
  const { addSuccessToast, addErrorToast } = useContextOrThrow(ToastContext);
  const mounted = useMounted();
  const [validation, setValidation] = useState<ValidationTree<EditBroadcastCategoryFormState>>({
    errors: [],
    children: {},
  });
  const [saving, setSaving] = useState<boolean>(false);
  const [formState, setFormState] = useState<EditBroadcastCategoryFormState>({ name: '' });

  const whenSubmitted = useCallback(() => {
    setSaving(true);
    api.post<{ category: BroadcastCategory }>('/broadcasts/categories', formState)
      .then(response => (
        setTimeout(() => {
          if (!mounted.current) {
            return;
          }

          setSaving(false);
          addSuccessToast(
            intl.formatMessage({
              id: 'broadcasts.createCategory.success',
              description: 'Success message when category could be created.',
              defaultMessage: '{ categoryName } created successfully.',
            }, {
              categoryName: response.data.category.name,
            })
          );
          history.replace(`/broadcasts/categories/${ response.data.category.id }`);
        }, 50))
      )
      .catch((error: AxiosError<unknown>) => {
        if (!mounted.current) {
          return;
        }

        setSaving(false);
        addErrorToast(
          intl.formatMessage({
            id: 'broadcasts.createCategory.error',
            description: 'Error message when category could not be created.',
            defaultMessage: '{ categoryName, select, undefined {Category} other {{categoryName}} } could not be created.',
          }, {
            categoryName: formState.name || undefined,
          })
        );
        error.response && ErrorResponseReader.isValidationErrorResponse(error.response) && setValidation(error.response.data.error.data.root);
      })
  }, [addErrorToast, addSuccessToast, api, formState, history, intl, mounted]);

  return (
    <Padded>
      <PageHeader
        items={ [
          {
            link: '/broadcasts',
            title: intl.formatMessage({
              id: 'section.broadcasts',
              description: 'Heading label for Broadcasts',
              defaultMessage: 'Broadcasts'
            })
          },
          {
            link: '/broadcasts/categories',
            title: intl.formatMessage({
              id: 'sections.broadcasts.categories',
              description: 'Title displayed over the broadcasts categories page',
              defaultMessage: 'Categories'
            }),
          },
          {
            title: intl.formatMessage({
              id: 'sections.broadcasts.categories.create',
              description: 'Title displayed over the create broadcast category page',
              defaultMessage: 'Create'
            }),
          },
        ] }
      />
      <Box>
        <CategoryEditForm
          formState={ formState }
          onChange={ setFormState }
          validation={ validation }
          onValidationChange={ setValidation }
          onSubmit={ whenSubmitted }
          submitting={ saving }
        />
      </Box>
    </Padded>
  );
};
