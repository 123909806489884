import { FC } from 'react';

import { StyledBoxDialog, StyledBoxPage } from './style';

export const BoxPageLayout: FC = ({
  children,
}) => {
  return (
    <StyledBoxPage>
      <StyledBoxDialog>
        { children }
      </StyledBoxDialog>
    </StyledBoxPage>
  );
};
