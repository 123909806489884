import styled from '@emotion/styled';
import { rgba } from 'polished';

export const StyledOverlay = styled.div<{ open: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(0px);
  pointer-events: none;
  background-color: ${ props => rgba(props.theme.new.palette.grey[900].main, 0.7) };
  opacity: 0;
  transition: backdrop-filter 0.2s, opacity 0.2s;
  z-index: 1;

  ${ props => props.open && `
    pointer-events: all;
    backdrop-filter: blur(1px);
    opacity: 1;
  ` };
`;
