import { FunctionComponent, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { PersonStatusHelper } from '../../People/Utility';
import { PersonStatus } from '../../Models';

type Props = {
  status?: PersonStatus
}

export const UserStatus: FunctionComponent<Props> = ({
  status,
}) => {
  const intl = useIntl();
  const localisedStatus = useMemo(
    () => status
      ? PersonStatusHelper.localiseStatus(intl, status)
      : '',
    [status, intl],
  );

  return (
    <span>{ localisedStatus }</span>
  )
};
