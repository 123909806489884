import { FunctionComponent, MouseEvent, MouseEventHandler, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Menu, MenuItem } from '@mui/material';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';
import { FilesFab } from '@ourpeople/shared/Files/Component/FilesFab/FilesFab';
import { BreakpointContext } from 'op-storybook/lib/providers/BreakpointProvider/BreakpointProvider';

import { BreadcrumbItem, Flex, FlexPullRight, PageHeader, PresentationIcon } from '../../../Common/Component';
import { useContextOrThrow } from '../../../Core/Hook';
import FolderIcon from '../../../Assets/img/icons/streamline/folder-empty.svg';
import FileIcon from '../../../Assets/img/icons/streamline/common-file-empty.svg';
import { ContextMenuAction } from '../../../Common/Component/ContextMenu/ContextMenu';

type Props = {
  breadcrumbItems: BreadcrumbItem[];
  onCreateFileClicked: () => void;
  onCreateFolderClicked: () => void;
  busy: boolean;
};

export const FilesHeader: FunctionComponent<Props> = ({
  breadcrumbItems,
  onCreateFileClicked,
  onCreateFolderClicked,
  busy,
}) => {
  const intl = useIntl();
  const { lessThan } = useContextOrThrow(BreakpointContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  const menuItems: ContextMenuAction[] = useMemo(() => [
    {
      id: 'folder',
      IconComponent: FolderIcon,
      label: intl.formatMessage({
        description: 'Label for create folder button on files page.',
        defaultMessage: 'New folder',
      }),
      onClick: onCreateFolderClicked,
      busy,
      disabled: false,
    },
    {
      id: 'file',
      IconComponent: FileIcon,
      label: intl.formatMessage({
        description: 'Label for create file button on files page.',
        defaultMessage: 'New file',
      }),
      onClick: onCreateFileClicked,
      busy,
      disabled: false,
    },
  ], [busy, intl, onCreateFileClicked, onCreateFolderClicked]);

  return (
    <PageHeader
      items={ [
        {
          link: '/files',
          title: intl.formatMessage({
            id: 'section.files',
            description: 'Heading label for Files',
            defaultMessage: 'Files',
          }),
        },
        ...(breadcrumbItems || []),
      ] }
    >
      <FlexPullRight>
        { lessThan.xs
          ? (
            <FilesFab
              onNewFolderClicked={ onCreateFolderClicked }
              onNewFileClicked={ onCreateFileClicked }
            />
          )
          : <>
            <Button
              variant="primary"
              onClick={ handleClick }
              busy={ busy }
            >
              <FormattedMessage
                id="files.new"
                description="Label for new item button."
                defaultMessage="New"
              />
            </Button>
            <Menu
              anchorEl={ anchorEl }
              keepMounted
              open={ Boolean(anchorEl) }
              onClose={ handleClose }
              onClick={ handleClose }
              anchorOrigin={ {
                vertical: 'bottom',
                horizontal: 'right',
              } }
              transformOrigin={ {
                vertical: 'top',
                horizontal: 'right',
              } }
            >
              { menuItems.map(menuItem => (
                <MenuItem
                  key={ menuItem.id }
                  onClick={ menuItem.onClick as MouseEventHandler }
                >
                  <Flex gap={ 1 }>
                    <PresentationIcon IconComponent={ menuItem.IconComponent }/>
                    { menuItem.label }
                  </Flex>
                </MenuItem>
              )) }
            </Menu>
          </>
        }
      </FlexPullRight>
    </PageHeader>
  );
};
