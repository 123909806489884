import styled from '@emotion/styled';
import { ButtonGroup } from '@mui/material';

export const StyledButtonGroup = styled(ButtonGroup)`
  display: flex;

  > * {
    width: 100%;
  }

  .MuiButton-root {
    padding: 0.25rem;
    line-height: 1;
    border-radius: 0;
    width: 100%;
    height: 34px;
  }

  .MuiButton-outlined {
    color: ${props => props.theme.palette.text.primary};
    border-color: ${props => props.theme.palette.text.primary};
  }
`;
