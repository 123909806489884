import TextFit from 'react-textfit';
import {
  ComponentProps,
  default as React,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { parseISO } from 'date-fns';
import { StyleBuilder } from 'op-storybook/lib/model/StyleBuilder/StyleBuilder';

import { ArrayHelper } from '../../../../../Common/Utility';
import { ImageCaption } from '../../../../../Broadcasts/Service';
import { Upload } from '../../../../../Types';
import { StyledImageCaption, StyledImageContentPreview, StyledSubCopy } from './style';
import { ScrollableContent } from '../../../../../Broadcasts/Component/Content';
import { BroadcastNudge } from '../../../../../Broadcasts/Model';
import { NudgeBanner } from '../..';
import { RichTextContentPreview } from '../../..';
import { LinkContent } from '../../LinkContent/LinkContent';
import { AbsoluteBackButtonContainer } from '../../AbsoluteBackButtonContainer/AbsoluteBackButtonContainer';

type VerticalAlignment = 'top' | 'bottom';
type HorizontalAlignment = 'left' | 'middle' | 'right';
export type CaptionAlignment = {
  vertical: VerticalAlignment;
  horizontal: HorizontalAlignment;
};

type Props = {
  imageHeight: 'half' | 'majority' | 'full';
  backgroundColour: string;
  imageFit: 'cover' | 'contain';
  caption?: ImageCaption;
  text?: string;
  image?: Upload;
  nudge?: BroadcastNudge;
  onReady?: () => void;
  linkProps: ComponentProps<typeof LinkContent> | null;
  backButton: ReactNode | undefined;
  children?: ReactNode;
};

export const ImageContentWithoutFooter: FC<Props> = ({
  imageHeight,
  backgroundColour,
  imageFit,
  caption,
  text,
  image,
  nudge,
  onReady,
  linkProps,
  backButton,
}) => {
  const [captionHeight, setCaptionHeight] = useState<number>();
  const [captionText, setCaptionText] = useState<string>();
  const captionContainerRef = useRef<HTMLDivElement>(null);
  const captionAlignment = useMemo<CaptionAlignment>(() => {
    const parts = caption?.position.split('_') || [];

    return {
      vertical: verticalAlignmentIsValid(parts[0]) ? parts[0] : 'bottom',
      horizontal: horizontalAlignmentIsValid(parts[1]) ? parts[1] : 'middle',
    };
  }, [caption?.position]);
  const styles = useMemo(
    () => buildStyles({
      imageHeight,
      imageFit,
      backgroundColour,
    }),
    [backgroundColour, imageFit, imageHeight],
  );

  useEffect(() => {
    setCaptionHeight(undefined);
    setCaptionText(caption?.text);
  }, [caption?.text]);

  const whenCaptionReady = useCallback(() => {
    setCaptionHeight(captionContainerRef.current?.children[0]?.children[0]?.clientHeight);
  }, []);

  const textFit = useMemo(() => (
    <TextFit
      mode="multi"
      onReady={ whenCaptionReady }
    >
      <p>{ captionText }</p>
    </TextFit>
  ), [captionText, whenCaptionReady]);

  return (
    <StyledImageContentPreview>
      <ScrollableContent>
        { nudge && (
          <NudgeBanner
            message={ nudge.message }
            nudgedAt={ parseISO(nudge.at) }
          />
        ) }
        <div
          css={ styles.imageContainer }
        >
          { image && (
            <img
              key={ image.id }
              src={ `/api/uploads/${ image.id }/download` }
              css={ styles.image }
              onLoad={ onReady }
            />
          ) }
        </div>
        { (imageHeight === 'full' || (captionAlignment.vertical === 'top' && caption && captionText)) && (
          <div css={ styles.fullCardImageContainer }>
            { caption && captionText && (
              <StyledImageCaption
                captionHeight={ captionHeight }
                alignment={ captionAlignment }
                backgroundColour={ caption.backgroundColour || '#E80D6D' }
                textColour={ caption.textColour || '#FFFFFF' }
                ref={ captionContainerRef }
              >
                { textFit }
              </StyledImageCaption>
            ) }
            { linkProps && imageHeight === 'full' && (
              <div
                {
                  ...!caption || captionAlignment.vertical === 'top'
                    ? { css: styles.buttonContainer }
                    : {}
                }
              >
                <LinkContent
                  { ...linkProps }
                />
              </div>
            ) }
          </div>
        ) }

        { imageHeight !== 'full' && (
          <>
            { captionAlignment.vertical === 'bottom' && caption && captionText && (
              <StyledImageCaption
                captionHeight={ captionHeight }
                alignment={ captionAlignment }
                backgroundColour={ caption.backgroundColour || '#E80D6D' }
                textColour={ caption.textColour || '#FFFFFF' }
                ref={ captionContainerRef }
                overlapBottom
              >
                { textFit }
              </StyledImageCaption>
            ) }
            <StyledSubCopy>
              { text && <RichTextContentPreview text={ text }/> }
              { linkProps && (
                <LinkContent
                  { ...linkProps }
                />
              ) }
            </StyledSubCopy>
          </>
        ) }
      </ScrollableContent>
      { backButton && <AbsoluteBackButtonContainer>{ backButton }</AbsoluteBackButtonContainer> }
    </StyledImageContentPreview>
  );
};

const verticalAlignmentIsValid = ArrayHelper.createTypeGuard<VerticalAlignment>(['top', 'bottom']);
const horizontalAlignmentIsValid = ArrayHelper.createTypeGuard<HorizontalAlignment>(['left', 'middle', 'right']);

type StyleProps = {
  imageHeight: Props['imageHeight'];
  imageFit: Props['imageFit'];
  backgroundColour: Props['backgroundColour'];
};

const buildStyles: StyleBuilder<StyleProps> = ({ imageHeight, imageFit, backgroundColour }) => ({
  fullCardImageContainer: {
    position: 'absolute',
    height: '100%',
    top: 0,
    width: '100%',
    padding: '2rem 0 6rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
  },
  imageContainer: {
    height: imageHeight === 'half'
      ? '50%'
      : imageHeight === 'majority'
        ? '70%'
        : '100%'
  },
  image: {
    objectFit: imageFit,
    width: '100%',
    height: '100%',
    backgroundColor: backgroundColour,
  },
  buttonContainer: { marginTop: 'auto' },
});
