import { FC } from 'react';
import { useIntl } from 'react-intl';

import { DeprecatedPresentationIcon } from '../../../../Core/Component/Content/PresentationIcon/DeprecatedPresentationIcon';
import { DeprecatedTooltip } from '../../../../../src/react/Common/Component/Tooltip/DeprecatedTooltip';
import VerifiedIcon from '../../../../Asset/Icon/Streamline/email-action-check.svg';
import SentIcon from '../../../../Asset/Icon/Streamline/email-action-send-2.svg';
import UnverifiedIcon from '../../../../Asset/Icon/Streamline/email-action-remove.svg';
import { StyledIcon } from './style';
import { useDateTimeFormatter } from '../../../../Core/Hook/useDateFormatter';
import { IconButton } from '../../../../../src/react/Common/Component/IconButton/IconButton';
import { VerifiableEmailAddress } from '../../../../../src/react/Models/Person';

export type VerificationState = {
  canRequest: boolean;
  verifiableEmailAddress?: VerifiableEmailAddress;
  sending: boolean;
};

type Props = {
  emailVerificationEnabled: boolean;
  verifiableEmailAddress: VerifiableEmailAddress | undefined;
  disableSendVerification: boolean;
  onClick?: () => void;
  busy: boolean;
};

export const EmailAddressVerificationButton: FC<Props> = ({
  emailVerificationEnabled,
  verifiableEmailAddress,
  disableSendVerification,
  onClick,
  busy,
}) => {
  const intl = useIntl();
  const { uniformDateTime } = useDateTimeFormatter();
  const unverifiable = emailVerificationEnabled && !verifiableEmailAddress?.id;
  const verified = emailVerificationEnabled && verifiableEmailAddress?.id && verifiableEmailAddress?.verifiedAt;
  const unverified = emailVerificationEnabled && verifiableEmailAddress?.id && !verifiableEmailAddress?.verifiedAt;
  const unverifiedAndNonInteractive = unverified && disableSendVerification;
  const unverifiedAndInteractive = unverified && !disableSendVerification;

  return <>
    { unverifiable && (
      <DeprecatedTooltip
        title={
          intl.formatMessage({
            description: 'Verify button tooltip when email verification cannot be sent because the address is new or blank.',
            defaultMessage: 'Email address is unverified.',
          })
        }
      >
        <StyledIcon>
          <DeprecatedPresentationIcon
            IconComponent={ UnverifiedIcon }
            color="error.600.main"
          />
        </StyledIcon>
      </DeprecatedTooltip>
    ) }
    { verified && (
      <DeprecatedTooltip
        title={
          intl.formatMessage({
            description: 'Tooltip when an email address is verified',
            defaultMessage: 'This email address has been verified.',
          })
        }
      >
        <StyledIcon>
          <DeprecatedPresentationIcon
            IconComponent={ VerifiedIcon }
            color="success.600.main"
          />
        </StyledIcon>
      </DeprecatedTooltip>
    ) }
    { unverifiedAndNonInteractive && (
      <DeprecatedTooltip
        title={
          verifiableEmailAddress?.verificationSentAt
            ? intl.formatMessage(
              {
                description: 'Tooltip to indicate email address is pending verification.',
                defaultMessage: 'Verification email sent { date }.',
              },
              {
                date: uniformDateTime(verifiableEmailAddress.verificationSentAt).toLowerCase(),
              }
            )
            : intl.formatMessage({
              description: 'Tooltip to indicate email address is unverified.',
              defaultMessage: 'Email address is unverified.',
            })
        }
      >
        <StyledIcon>
          <DeprecatedPresentationIcon
            IconComponent={
              verifiableEmailAddress?.verificationSentAt
                ? SentIcon
                : UnverifiedIcon
            }
            color={
              verifiableEmailAddress?.verificationSentAt
                ? 'teal.600.main'
                : 'error.600.main'
            }
          />
        </StyledIcon>
      </DeprecatedTooltip>
    ) }
    { unverifiedAndInteractive && (
      <IconButton
        IconComponent={
          verifiableEmailAddress?.verificationSentAt
            ? SentIcon
            : UnverifiedIcon
        }
        label={
          verifiableEmailAddress?.verificationSentAt
            ? intl.formatMessage(
              {
                description: 'Tooltip when an email address is pending verification',
                defaultMessage: 'Verification email sent { date }. Click to send another verification email.',
              },
              {
                date: uniformDateTime(verifiableEmailAddress.verificationSentAt).toLowerCase(),
              }
            )
            : intl.formatMessage({
              description: 'Label for button to verify an unverified email address.',
              defaultMessage: 'Email address is unverified. Click to send a verification email.',
            })
        }
        showTooltip
        onClick={ onClick }
        busy={ busy }
        color={
          verifiableEmailAddress?.verificationSentAt
            ? 'secondary.main'
            : 'error.main'
        }
        size="small"
      />
    ) }
  </>;
};
