import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { ListItemIcon, ListItemText, MenuItem, Tooltip } from '@mui/material';
import { Heading } from '@ourpeople/shared/Core/Component/Content';
import { DebouncedSearchInput } from '@ourpeople/shared/Core/Component/Input/DebouncedSearchInput/DebouncedSearchInput';

import { TextFormatter } from '../../../Utility';
import { TagParser } from '../../Utility';
import { LoadingSpinner, PaginatedTable, SortableHeaderCell, TableCell, TableRow, } from '../../../Components';
import { TableMenu } from '../../../Components/Content/TableMenu';
import { FilterContainer, FilterRow, FlexPullRight, StyledChip } from '../../../Common/Component';
import { FetchTagsSortString, useFetchTags } from '../../Hook';
import { TagDeletionDialog, TagPicker } from '..';
import { Tag, TagType } from '../../Model';
import { TagLimitInformation, TagNameCellContents } from './style';
import EditIcon from '../../../Assets/img/icons/monochrome/edit.svg';
import DeleteIcon from '../../../Assets/img/icons/monochrome/trash.svg';
import TagImportedIcon from '../../../Assets/img/icons/streamline/tags-download.svg';

interface Props<T extends TagType, P extends TagType, C extends TagType> {
  type: T;
  parentType?: P;
  childType?: C;
  maximumTags?: number;
}

export const TagTable = <T extends TagType, P extends TagType, C extends TagType>({
  type,
  parentType,
  childType,
  maximumTags,
}: PropsWithChildren<Props<T, P, C>>): JSX.Element => {
  const intl = useIntl();
  const [deleteTag, setDeleteTag] = useState<Tag<T> | null>(null);
  const [pageNum, setPageNum] = useState<number>(1);
  const [search, setSearch] = useState<string>();
  const [sort, setSort] = useState<FetchTagsSortString>('name_asc');
  const [parentTags, setParentTags] = useState<Tag<P>[]>([]);
  const [childTags, setChildTags] = useState<Tag<C>[]>([]);
  const parentTagIds = useMemo(() => parentTags.map(tag => tag.id).join(','), [parentTags]);
  const childTagIds = useMemo(() => childTags.map(tag => tag.id).join(','), [childTags]);
  const [fetchTagsResult, fetchState, reloadTags] = useFetchTags(type, pageNum, search, sort, childTagIds, parentTagIds);
  const pagination = useMemo(() => fetchTagsResult?.content?.pagination, [fetchTagsResult?.content?.pagination]);
  const tags = useMemo(() => fetchTagsResult?.content?.tags || [], [fetchTagsResult?.content?.tags]);
  const [tagFilters, setTagFilters] = useState<Tag<P | C>[]>([]);

  const getLocalisedTagType = (tagType: TagType, plural = false) => {
    return TextFormatter.capitalise(TagParser.localisedTagTypeFromInternalTagType(tagType, intl, plural ? Infinity : 1));
  };

  const reconcileChips = useCallback(<T extends C | P>(type: T, tags: Tag<T>[]): void => {
    setTagFilters(tagFilters => {
      const unrepresentedTags = tags.filter(tag => !tagFilters.find(tagFilter => tagFilter.id === tag.id ));
      const tagsToRetain = tagFilters.filter(tagFilter => tagFilter.type !== type || tags.find(tag => tag.id === tagFilter.id));
      return [
        ...tagsToRetain,
        ...unrepresentedTags,
      ];
    });
  }, []);

  useEffect(() => {
    childType && reconcileChips(childType, childTags);
  }, [childTags, childType, reconcileChips]);

  useEffect(() => {
    parentType && reconcileChips(parentType, parentTags);
  }, [parentTags, parentType, reconcileChips]);

  const removeTagFilter = (tagFilter: Tag<C | P>): void => {
    const reducer = <T extends C | P>(tags: Tag<T>[]) => tags.filter(tag => tag.id !== tagFilter.id);
    switch (tagFilter.type) {
      case parentType:
        setParentTags(reducer);
        break;
      case childType:
        setChildTags(reducer);
        break;
    }
  };

  const whenDeleteMenuItemClicked = (tag: Tag<T>): void => {
    setDeleteTag(tag);
  };

  const whenDeleteComplete = (): void => {
    setTimeout(() => {
      setDeleteTag(null);
      reloadTags();
    }, 50);
  };

  const whenDeleteCancelled = (): void => {
    setDeleteTag(null);
  };

  const getTagFilterLabel = (tagFilter: Tag<C | P>): string => {
    const localisedTagType = getLocalisedTagType(tagFilter.type);
    return intl.formatMessage({
      id: 'tags.filter',
      description: 'Name for tags when represented as chips.',
      defaultMessage: '{tagType}: {tagName}',
    }, {
      tagType: localisedTagType,
      tagName: tagFilter.name,
    });
  };

  return (
    <>
      {
        !!maximumTags && (
          <TagLimitInformation>
            {
              pagination
                ? (
                  <div>
                    <Heading type="h4">
                      <FormattedMessage
                        id="tags.teamLimit.heading"
                        defaultMessage="Team limit - {count}"
                        values={{
                          count: maximumTags,
                        }}
                      />
                    </Heading>
                    <p>
                      <FormattedMessage
                        id="tags.teamLimit.body"
                        defaultMessage="{usedCount, plural, one {# team} other {# teams}} used, {remainingCount} remaining"
                        values={{
                          remainingCount: maximumTags - pagination.itemCount,
                          usedCount: pagination.itemCount,
                        }}
                      />
                    </p>
                  </div>
                )
                : <LoadingSpinner/>
            }
          </TagLimitInformation>
        )
      }
      <FilterContainer>
        <FilterRow>
          {
            parentType && (
              <TagPicker
                selectedTags={parentTags}
                onSelectedTagsChanged={setParentTags}
                type={parentType}
              />
            )
          }
          {
            childType && (
              <TagPicker
                selectedTags={childTags}
                onSelectedTagsChanged={setChildTags}
                type={childType}
              />
            )
          }
          <FlexPullRight>
            <DebouncedSearchInput
              onChange={ setSearch }
              value={ search }
            />
          </FlexPullRight>
        </FilterRow>
        {
          tagFilters && tagFilters.length > 0 && (
            <FilterRow>
              {
                tagFilters.map(tagFilter => (
                  <StyledChip
                    onDelete={ () => removeTagFilter(tagFilter) }
                    key={ `${tagFilter.id}` }
                    label={ getTagFilterLabel(tagFilter) }
                  />
                ))
              }
            </FilterRow>
          )
        }
      </FilterContainer>
      <PaginatedTable
        rows={tags}
        rowRender={(tag) => {
          const canEditTag = tag.type === 'team' || tag.externallyManaged === false;
          return (
            <TableRow key={tag.id}>
              <TableCell>
                <TagNameCellContents>
                  {
                    tag.externallyManaged && (
                      <Tooltip
                        title={ intl.formatMessage({
                          id: 'tag.externallyManaged',
                          description: 'Tooltip when hovering over icon indicating that a tag is externally managed.',
                          defaultMessage: 'This tag is managed by an external system or integration.',
                        }) }
                      >
                        <span><TagImportedIcon/></span>
                      </Tooltip>
                    )
                  }
                  {
                    canEditTag
                      ? (
                        <Link to={ `/settings/tags/${ tag.id }/edit` }>
                          { tag.name }
                        </Link>
                      )
                      : (
                        tag.name
                      )
                  }
                </TagNameCellContents>
              </TableCell>
              {
                parentType && (
                  <TableCell>
                    { tag.parentTagIds.length }
                  </TableCell>
                )
              }
              {
                childType && (
                  <TableCell>
                    { tag.childTagIds.length }
                  </TableCell>
                )
              }
              <TableCell>
                <TableMenu disabled={!canEditTag} rowKey={tag.id}>
                  <MenuItem
                    component={ Link }
                    to={ `/settings/tags/${tag.id}/edit` }
                  >
                    <ListItemIcon><EditIcon/></ListItemIcon>
                    <ListItemText primary={
                      intl.formatMessage({
                        id: 'tags.edit',
                        defaultMessage: 'Edit',
                      })
                    } />
                  </MenuItem>
                  {
                    tag.externallyManaged === false && (
                      <MenuItem onClick={() => whenDeleteMenuItemClicked(tag)}>
                        <ListItemIcon><DeleteIcon /></ListItemIcon>
                        <ListItemText primary={
                          intl.formatMessage({
                            id: 'tags.delete',
                            defaultMessage: 'Delete',
                          })
                        } />
                      </MenuItem>
                    )
                  }
                </TableMenu>
              </TableCell>
            </TableRow>
          );
        }}
        pageNum={pageNum}
        onPageChanged={setPageNum}
        headerRow={
          <TableRow>
            <SortableHeaderCell
              sort={sort}
              ascValue="name_asc"
              descValue="name_desc"
              onSort={setSort}
            >
              { getLocalisedTagType(type) }
            </SortableHeaderCell>
            {
              parentType && (
                <TableCell>
                  { getLocalisedTagType(parentType, true) }
                </TableCell>
              )
            }
            {
              childType && (
                <TableCell>
                  { getLocalisedTagType(childType, true) }
                </TableCell>
              )
            }
            <TableCell width="2rem" />
          </TableRow>
        }
        requestState={fetchState}
        onRetryClicked={reloadTags}
        pagination={pagination}
      />
      {
        !!deleteTag && (
          <TagDeletionDialog
            tag={deleteTag}
            onDelete={whenDeleteComplete}
            onDismiss={whenDeleteCancelled}
          />
        )
      }
    </>
  )
};
