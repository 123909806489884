import { FunctionComponent } from 'react';

import { StyledLabel } from './styles';

export const Label: FunctionComponent = ({ children }) => {
  return (
    <StyledLabel>
      {children}
    </StyledLabel>
  );
};
