import { useCallback, useMemo } from 'react';

import { TemplateGoogleEvents } from '../../Common/Model';
import { useGoogleAnalytics } from '../../Core/Hook';

const baseGoogleEvent = {
  category: 'Form Templates',
};

export const useFormTemplatesGoogleEvents = (): TemplateGoogleEvents => {
  const { trackEvent } = useGoogleAnalytics();

  const trackViewTemplates = useCallback((source: string) => trackEvent({
    ...baseGoogleEvent,
    action: 'View Templates',
    label: `Source ${ source }`,
  }), [trackEvent]);

  const trackRate = useCallback((templateName: string, rating: string) => trackEvent({
    ...baseGoogleEvent,
    action: 'Rate template',
    label: `${ templateName } ${ rating }`,
  }), [trackEvent]);

  const trackPreview = useCallback((templateName: string) => trackEvent({
    ...baseGoogleEvent,
    action: 'Preview',
    label: templateName,
  }), [trackEvent]);

  const trackUse = useCallback((templateName: string) => trackEvent({
    ...baseGoogleEvent,
    action: 'Use',
    label: templateName,
  }), [trackEvent]);

  return useMemo(() => ({
    trackRate,
    trackPreview,
    trackUse,
    trackViewTemplates,
  }), [trackPreview, trackRate, trackUse, trackViewTemplates]);
};
