import { useIntl } from 'react-intl';
import {FC, useCallback, useMemo, useState} from 'react';
import { AutocompleteSelectionChanged } from '@ourpeople/shared/Core/Component/Input/Autocomplete/Autocomplete';
import { LocalisedString } from 'op-storybook/lib/model/LocalisedString/LocalisedString';

import { StyledAutocompleteFilter } from './style';
import { TeamAutocomplete } from '../../../Tags/Component';
import { ProgressDialogs } from '../ProgressDialogs/ProgressDialogs';
import { ActionErrors } from '../ActionErrors/ActionErrors';
import { Tag } from '../../../Tags/Model';
import { BroadcastAction } from '../../Model';
import { Chip, TableFilters } from '../../../Common/Component';
import { ContentTypeAutocomplete } from '../ContentTypeAutocomplete/ContentTypeAutocomplete';
import { useQueryAsState } from '../../../Hooks';
import { QueryParser, QueryWithKeys } from '../../../Common/Utility';

type Query = QueryWithKeys<'teamIds' | 'contentTypes' | 'search' | 'tab'>;

export type Props = {
  lastAction: BroadcastAction | undefined;
  actionError: boolean;
  actionInProgress: boolean;
}

/**
 * @deprecated Prefer conveying action state through ContextMenu component.
 */
export const LegacyBroadcastTableFilters: FC<Props> = ({
  lastAction,
  actionError,
  actionInProgress,
}) => {
  const [query, setQuery] = useQueryAsState<Query>();
  const intl = useIntl();
  const [chips, setChips] = useState<Chip[]>([]);
  const teamIds = useMemo(() => query.teamIds?.split(',') || [], [query.teamIds]);

  const whenContentTypesChanged = useCallback((contentTypes: LocalisedString[]) => {
    const newChips = contentTypes.map(contentType => ({
      id: contentType.id,
      type: 'contentTypes',
      label: intl.formatMessage({
        description: 'Chip label in scheduled and archived broadcasts table when filtered by type.',
        defaultMessage: 'Type: { type }',
      }, {
        type: contentType.localisation,
      }),
    }));
    setChips(chips => chips.filter(chip => chip.type !== 'contentTypes').concat(newChips));
    setQuery(QueryParser.csvValueUpdater('contentTypes', contentTypes.map(contentType => contentType.id)));
  }, [intl, setQuery]);

  const whenTeamsChanged: AutocompleteSelectionChanged<Tag<'team'>> = useCallback(selection => {
    const newChips = selection.options.map(team => ({
      id: team.id,
      type: 'teamIds',
      label: intl.formatMessage({
        description: 'Chip label in scheduled and archived broadcasts table when filtered by team.',
        defaultMessage: 'Team: { team }',
      }, {
        team: team.name,
      }),
    }));
    setChips(chips => chips.filter(chip => chip.type !== 'teamIds').concat(newChips));
    setQuery(QueryParser.csvValueUpdater('teamIds', selection.options.map(option => option.id).concat(selection.badIds)));
  }, [intl, setQuery]);

  const whenSearchChanged = useCallback((search: string) => {
    setQuery(({ search: previousSearch, ...query }) => ({
      ...query,
      ...(search ? { search } : {}),
    }));
  }, [setQuery]);

  const clearFilters = useCallback(() => {
    setQuery(({ tab }) => ({
      ...(tab ? { tab } : {}),
    }));
    setChips([]);
  }, [setQuery]);

  const whenRemoveChipClicked = useCallback((chipToRemove: Chip) => {
    setChips(chips => chips.filter(chip => chip.type !== chipToRemove.type || chip.id !== chipToRemove.id));
    setQuery(QueryParser.csvValueRemover(chipToRemove.type, chipToRemove.id));
  }, [setQuery]);

  return (
    <div>
      <TableFilters
        searchValue={ query.search || '' }
        onSearchChanged={ whenSearchChanged }
        onClearFiltersClicked={ clearFilters }
        onRemoveChipClicked={ whenRemoveChipClicked }
        chips={ chips }
      >
        <StyledAutocompleteFilter>
          <TeamAutocomplete
            teamIds={ teamIds }
            onSelectionChanged={ whenTeamsChanged }
            multiple
            dense
            fullWidth
            showChips={ false }
            label={ intl.formatMessage({
              id: 'broadcasts.sentToTeams.label',
              description: 'Placeholder for sent to teams filter',
              defaultMessage: 'Sent to team',
            }) }
            placeholder={ intl.formatMessage({
              id: 'broadcasts.sentToTeams.placeholder',
              description: 'Placeholder for sent to teams filter',
              defaultMessage: 'Search…',
            }) }
          />
        </StyledAutocompleteFilter>
        <StyledAutocompleteFilter>
          <ContentTypeAutocomplete
            selectedIds={ (query.contentTypes || '').split(',') }
            onSelectionChanged={ whenContentTypesChanged }
            fullWidth
          />
        </StyledAutocompleteFilter>
      </TableFilters>
      <ProgressDialogs
        action={ lastAction }
        inProgress={ actionInProgress }
      />
      <ActionErrors
        action={ lastAction }
        error={ actionError }
      />
    </div>
  );
};
