import { Validator } from '../../../src/react/Common/Model/Validator';
import { ValidationError } from '../../../src/react/Common/Model/ValidationError';
import { Validate } from '../../../src/react/Common/Utility/Validate';

export class FirstNameValidator {
  public static validate = (firstName: string): ValidationError[] => firstNameValidator.validate(firstName);
}

const firstNameValidator = new Validator<string>([
  Validate.notBlank,
  firstName => Validate.maxLength(firstName, 191),
]);
