import { MappedSettingTypes } from '../../Types';
import { MappedSettingValue } from './MappedSettingValue';
import { StyledCollectionValueItem } from './styles';

type Props = {
  value: MappedSettingTypes['collection']['value'],
  config: MappedSettingTypes['collection']['config'],
};

export const CollectionSettingValue = ({
  value,
  config,
}: Props): JSX.Element => {
  return (
    <>
      {
        value.map((item, index) => (
          <StyledCollectionValueItem key={index}>
            <MappedSettingValue
              type={config.itemType}
              value={item}
              config={config.itemConfig} />
          </StyledCollectionValueItem>
        ))
      }
    </>
  );
};
