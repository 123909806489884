import { IntlShape } from 'react-intl';

import { SingleContentCard, EditorContent } from '../../../../Content/Model';
import CommentIcon from '../../../../Assets/img/icons/monochrome/content/training.svg';
import { TrainingEditor, TrainingPreview } from '../../../Component';
import { TrainingValidator } from '../../Validators';
import { UniqueIdGenerator } from '../../../../Common/Utility';
import { EventCreator, EventTransformer } from '../../../Utility';
import { CardTransformer } from '../../../../Content/Utility';
import { DraftMeeting, Meeting } from '..';
import { BroadcastContentDefinition } from '../../../Model';
import { LiveTrainingContent } from '../../../../Inbox/Component';
import { AcceptResponse, DeclineResponse } from '../../../../Inbox/Model';
import { EventParser } from '../../../Utility/EventParser';
import { EnvironmentSettings } from '../../../../Models';

export type Training = Meeting<'trainingcontent'> & {
  on_success: string[];
  result?: {
    feedback: string;
  };
};

export type DraftTraining = DraftMeeting<'trainingcontent'> & {
  on_success: string[];
};

export type TrainingEditorContent = EditorContent<DraftTraining>;

export class TrainingDefinition implements BroadcastContentDefinition<Training, TrainingEditorContent, AcceptResponse | DeclineResponse> {
  public readonly id = 'training';
  public readonly availableToCreate = true;
  public readonly new = false;
  public readonly richText = false;
  public readonly contentTypes = ['trainingcontent'];
  public readonly categoryId = 'event';
  public readonly IconComponent = CommentIcon;
  public readonly EditorComponent = TrainingEditor;
  public readonly PreviewComponent = TrainingPreview;
  public readonly LiveComponent = LiveTrainingContent;
  public readonly SubmittedComponent = (): null => null;
  public validate = TrainingValidator.validate;

  public definesContentType = (type: string): boolean => (
    this.contentTypes.includes(type)
  );

  public getContentTitle = (intl: IntlShape, content: Training): string => EventParser.title(content);

  public getLocalisedType = (intl: IntlShape): string => (
    intl.formatMessage({
      id: 'broadcasts.content.Training.type',
      description: 'Localised name for Training content type.',
      defaultMessage: 'Training',
    })
  );

  public getDraftContentTitle = (intl: IntlShape, draftContent: DraftTraining): string => EventParser.title(draftContent);

  public hasRequiredFeatureFlags = (environmentSettings: EnvironmentSettings): boolean => (
    !!environmentSettings.eventsEnabled
  );

  public createEditorContent = (): TrainingEditorContent => ({
    id: UniqueIdGenerator.generate(),
    card: {
      content: {
        ...EventCreator.createEventWithType('trainingcontent'),
        max_attendees: 3,
        on_success: [],
      },
      mandatory: false,
      image: null,
    },
  });

  public transformCard = (card: SingleContentCard<Training>): TrainingEditorContent => ({
    id: card.id,
    card: CardTransformer.transformCard(card, {
      ...EventTransformer.toDraft(card.content),
      max_attendees: card.content.max_attendees,
      on_success: card.content.on_success,
    }),
  });
}
