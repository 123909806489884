import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { CenteredGenericErrorMessage, CenteredMessageWithLoadingCard } from '@ourpeople/shared/Core/Component/Content';

import { useFetchAudience } from '../../../Audiences/Hook';
import { LoadingContainer } from '../../../Common/Component';
import { AudienceBuilder } from '../../../Audiences/Component';
import { Node } from '../../Model';

type Props = {
  node: Node;
};

export const ReadonlyNodeAudience: FC<Props> = ({
  node,
}) => {
  const intl = useIntl();
  const [fetchAudienceResult, , reloadAudience] = useFetchAudience(node.viewAudience.id);

  return (
    fetchAudienceResult
      ? fetchAudienceResult.content
        ? (
          <AudienceBuilder
            audience={ fetchAudienceResult.content }
            readonly
            multipleSegments
          />
        )
        : (
          <LoadingContainer>
            <CenteredGenericErrorMessage onRetryClicked={ reloadAudience }/>
          </LoadingContainer>
        )
      : (
        <LoadingContainer>
          <CenteredMessageWithLoadingCard
            heading={
              intl.formatMessage({
                description: 'Loading message for directory permissions audience.',
                defaultMessage: 'Loading permissions for { directoryName }…',
              }, {
                directoryName: node.name,
              })
            }
          />
        </LoadingContainer>
      )
  );
};
