import { useIntl } from 'react-intl';

import { MappedSettingTypes } from '../../Types';
import { TextSettingInput } from './TextSettingInput';
import { UrlSettingInput } from './UrlSettingInput';
import { StyledLinkSettingInput } from './styles';

type Props = {
  config: MappedSettingTypes['link']['config'],
  value: MappedSettingTypes['link']['value'],
  onValueChanged: (value: MappedSettingTypes['link']['value']) => void,
  focusId?: string,
};

export const LinkSettingInput = ({
  config,
  value,
  onValueChanged,
  focusId,
}: Props): JSX.Element => {
  const intl = useIntl();

  const whenLabelChanged = (label: MappedSettingTypes['text']['value']): void => {
    onValueChanged({
      label,
      url: value?.url || '',
    });
  }

  const whenUrlChanged = (url: MappedSettingTypes['url']['value']): void => {
    onValueChanged({
      label: value?.label || '',
      url,
    });
  }

  return (
    <StyledLinkSettingInput>
      <TextSettingInput
        config={config.label}
        value={value?.label || ''}
        onValueChanged={whenLabelChanged}
        label={
          intl.formatMessage({
            id: 'settings.linkSetting.labelLabel',
            defaultMessage: 'Label',
          })
        }
        focusId={focusId}
      />
      <UrlSettingInput
        config={config.url}
        value={value?.url || ''}
        onValueChanged={whenUrlChanged}
        label={
          intl.formatMessage({
            id: 'settings.linkSetting.urlLabel',
            defaultMessage: 'URL',
          })
        }
      />
    </StyledLinkSettingInput>
  );
};
