import { PreviewComponent } from '../../../Model/BroadcastBlockDefinition';
import { FormBlock } from '../../../Service/Blocks/FormBlockDefinition';
import { FormBlock as FormBlockComponent } from './FormBlock';

export const FormBlockExpandedPreview: PreviewComponent<FormBlock> = props => {
  return (
    <FormBlockComponent
      { ...props }
      functionalLink
    />
  );
}
