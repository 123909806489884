import styled from '@emotion/styled';

export type StyledBoxProps = {
  padded?: boolean;
  margin?: boolean;
  fullWidth?: boolean;
}

export const StyledBox = styled.div<StyledBoxProps>`
  ${props => props.padded !== false && (`padding: ${props.theme.spacing(2)};`)}
  background-color: ${props => props.theme.palette.background.paper};
  box-shadow: ${ props => props.theme.shadows[3] };
  ${ props => props.margin !== false && 'margin: 1rem 0;' }
  ${ props => props.fullWidth && 'width: 100%;' }
`;
