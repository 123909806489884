import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { DraftSingleContentForm, Form } from '../../Model';

interface Props {
  form: Form | DraftSingleContentForm;
}

export const FormCategory: FunctionComponent<Props> = ({ form }) => {
  const formIsDraft = (
    form: Form | DraftSingleContentForm,
  ): form is DraftSingleContentForm => !form.hasOwnProperty('category');

  return (
    <>
      {
        (!formIsDraft(form) && form.category?.name) || (
          <FormattedMessage
            id="form.category.noCategory"
            description="Fallback category text in list table if null"
            defaultMessage="No category"
          />
        )
      }
    </>
  );
};
