import { FC, useMemo } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';

import FilledStarIcon from '../../../Assets/img/icons/monochrome/star_filled.svg';
import StarIcon from '../../../Assets/img/icons/monochrome/star.svg';
import { StyledRatingContainer, StyledRatingIconButton, StyledValue } from './style';
import { Flex, FlexPullRight, VerticallySpaced } from '../../../Common/Component';

type Props = {
  maxRating: number;
  value: number | null;
  onValueChanged: (value: number) => void;
  disabled?: boolean;
  showValues?: boolean;
  onValueCleared?: () => void;
};

export const StarRating: FC<Props> = ({
  maxRating,
  value,
  onValueChanged,
  disabled,
  showValues = false,
  onValueCleared,
}) => {
  const stars = useMemo<undefined[]>(() => {
    return Array.from({ length: maxRating });
  }, [maxRating]);

  return (
    <VerticallySpaced gap={ 1 }>
      <StyledRatingContainer>
        { stars.map((_star, index) => {
          const starValue = index + 1;
          const filled = value !== null && starValue <= value;
          return (
            <VerticallySpaced
              key={ index }
              gap={ 1 }
              align="center"
            >
              <StyledRatingIconButton
                onClick={ () => onValueChanged(starValue) }
                disabled={ disabled }
                filled={ filled }
              >
                {
                  filled
                    ? <FilledStarIcon/>
                    : <StarIcon/>
                }
              </StyledRatingIconButton>
              { showValues && <StyledValue><FormattedNumber value={ starValue }/></StyledValue> }
            </VerticallySpaced>
          );
        }) }
      </StyledRatingContainer>
      { onValueCleared && (
        <Flex>
          <FlexPullRight>
            <Button
              onClick={ onValueCleared }
            >
              <FormattedMessage
                description="Label for clear selection button in star rating"
                defaultMessage="Clear selection"
              />
            </Button>
          </FlexPullRight>
        </Flex>
      ) }
    </VerticallySpaced>
  );
};
