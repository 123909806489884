import { createContext } from 'react';

import { Api } from '../Services/Api';
import { AuthDescription, } from '../Models/AuthDescription';

export const ApiContext = createContext<Api | null>(null);
export const AuthContext = createContext<{
  authDescription: AuthDescription | null,
  refreshAuthDescription: () => void,
  lastDescribed: number,
}>({
  authDescription: null,
  refreshAuthDescription: () => { console.debug('refreshAuthDescription not implemented.') },
  lastDescribed: 0,
});
