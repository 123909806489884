import styled from '@emotion/styled';

export const StyledRecalledCard = styled.div`
  display: flex;
  padding: ${ props => props.theme.spacing(3) };
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${ props => props.theme.spacing(2) };
  height: 100%;
  text-align: center;

  > span {
    font-size: 1.2rem;
  }
`;
