import { FC } from 'react';

import { CategorySetting } from '../../../Sections/Settings/System/Components';
import { MappedSettingTypes, Setting, SettingSubCategory, SettingType } from '../../../Sections/Settings/System/Types';
import { SettingsSubCategoryHeader } from '../SettingsSubCategoryHeader/SettingsSubCategoryHeader';

export type SettingsSubCategoryFormProps = {
  subCategory: SettingSubCategory;
  changedSettings: Map<string, Setting<SettingType>>;
  onChange: (setting: Setting<SettingType>, value: MappedSettingTypes[SettingType]['value']) => void;
  onReset: (setting: Setting<SettingType>) => void;
};

export const DefaultSettingsSubCategoryForm: FC<SettingsSubCategoryFormProps> = ({
  subCategory,
  changedSettings,
  onChange,
  onReset,
}) => (
  <>
    <SettingsSubCategoryHeader>
      { subCategory.name }
    </SettingsSubCategoryHeader>
    { subCategory.settings.map(setting => (
      <CategorySetting
        key={ setting.key }
        setting={ changedSettings.get(setting.key) || setting }
        onValueChanged={ value => onChange(setting, value) }
        onValueReset={ () => onReset(setting) }
      />
    )) }
  </>
);
