import { AxiosError, AxiosRequestConfig } from 'axios';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { RequestState } from '../Models/RequestState';
import { FetchResult } from '../Models/FetchResult';
import { ApiContext } from '../Contexts';

export type Refresh = () => void;

export type FetchResponse<ResultType> = [
  null | FetchResult<ResultType>,
  RequestState,
  Refresh,
];

export const useRequest = <ResultType>(
  config: AxiosRequestConfig,
  disabled?: boolean,
): FetchResponse<ResultType> => {
  const api = useContext(ApiContext);
  const [requestCount, setRequestCount] = useState<number>(0);
  const [result, setResult] = useState<FetchResponse<ResultType>[0]>(null);
  const [requestState, setRequestState] = useState<RequestState>(RequestState.PENDING);

  useEffect(
    () => {
      setRequestState(RequestState.PENDING);
      if (api && !disabled) {
        setResult(null);
        setRequestState(RequestState.FETCHING);
        let cancelled = false;
        api.request<ResultType>(config)
          .then(response => response.data)
          .then(result => {
            if (!cancelled) {
              setResult(FetchResult.fromContent(result));
              setRequestState(RequestState.COMPLETE);
            }
          })
          .catch((error: AxiosError) => {
            if (!cancelled) {
              setResult(FetchResult.fromError(error));
              setRequestState(RequestState.FAILED);
            }
          });
        return () => { cancelled = true };
      }
    },
    [api, requestCount, config, disabled],
  );

  const refresh = useCallback(() => {
    setResult(null);
    setRequestCount(requestCount => requestCount + 1);
  }, [setResult]);

  return useMemo(() => [result, requestState, refresh], [refresh, requestState, result]);
}
