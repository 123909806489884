import { MappedSettingTypes } from '../../Types';

type Props = {
  value: MappedSettingTypes['number']['value'],
};

export const NumberSettingValue = ({
  value,
}: Props): JSX.Element => {
  return (
    <p>{value}</p>
  );
};
