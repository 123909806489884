import { FormGroup, RadioGroup } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';

import { AppChoice } from '../../../../../Forms/Component/Content/AppChoice/AppChoice';
import { StyledCheckbox, StyledRadio } from '../../../../../Forms/Component/Content/ChoiceRequestPreview/style';

type Option = {
  id?: string;
  title: string;
};

type Props = {
  options: Option[];
  selectedIds: string[];
  onChange: (selectedIds: string[]) => void;
  maxSelections: number;
  disabled?: boolean;
};

export const ChoiceContentCheckboxes: FC<Props> = ({
  options,
  selectedIds,
  onChange,
  maxSelections,
  disabled = false,
}) => {
  const singleOptionSelection = useMemo(() => selectedIds[0] || null, [selectedIds]);

  const whenSingleOptionChanged = useCallback((optionId: string) => (
    onChange([optionId])
  ), [onChange]);

  const whenMultipleOptionChanged = useCallback((optionId: string) => {
    const checked = selectedIds.includes(optionId);
    checked
      ? onChange(selectedIds.filter(selectedId => selectedId !== optionId))
      : onChange(selectedIds.concat(optionId));
  }, [onChange, selectedIds]);

  return (
    maxSelections === 1
      ? (
        <RadioGroup
          value={ singleOptionSelection }
        >
          { options.map((option, index) => (
            <AppChoice
              key={ option.id || index }
              disabled={ disabled }
              onClick={ () => whenSingleOptionChanged(option.id || index.toString()) }
              input={
                <StyledRadio
                  color="primary"
                  checked={ (option.id || index.toString()) === singleOptionSelection }
                />
              }
              title={ option.title }
            />
          )) }
        </RadioGroup>
      )
      : (
        <FormGroup>
          {
            options.map((option, index) => (
              <AppChoice
                key={ option.id || index }
                disabled={ disabled }
                onClick={ () => whenMultipleOptionChanged(option.id || index.toString()) }
                input={
                  <StyledCheckbox
                    color="primary"
                    checked={ selectedIds.includes(option.id || index.toString()) }
                    name={ option.id || index.toString() }
                  />
                }
                title={ option.title }
              />
            ))
          }
        </FormGroup>
      )
  );
};
