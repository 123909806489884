import { FC } from 'react';

import { Timeline } from '../../../../Broadcasts/Service';
import { TimelineBroadcastContent } from '../../../../Content/Component';
import { BroadcastContentContext } from '../../../../Broadcasts/Model';
import { LiveComponentProps } from '../../../../Content/Model';

export const LiveTimelineContent: FC<LiveComponentProps<Timeline, undefined, BroadcastContentContext>> = ({
  card: { content },
  context,
}) => (
  <TimelineBroadcastContent
    backButton={ context.closeButton }
    title={ content.text }
    cardCount={ context.contentCount || 0 }
    navigationProps={ context.navigationProps }
  />
);
