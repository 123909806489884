import { FC, ReactNode } from 'react';
import { keyframes } from '@emotion/react';

import { StyledProgressBar } from './style';
import { Typography } from '../Typography/Typography';
import { rgba } from 'polished';

type Props = {
  progress: number | 'indeterminate';
  showNumericValue: boolean;
  className?: string;
  children?: ReactNode;
};

export const ProgressBar: FC<Props> = ({
  progress = 'indeterminate',
  showNumericValue,
  className,
  children,
}) => {
  return (
    <StyledProgressBar>
      <div
        className={ className }
        css={ theme => ({
          height: theme.new.spacing[2],
          width: '100%',
          borderRadius: theme.new.borderRadius.max,
          backgroundColor: theme.new.palette.grey[200].main,
          overflow: 'hidden',
          backfaceVisibility: 'hidden',
          transform: 'translate3d(0, 0, 0)',
          ...progress === 'indeterminate'
            ? {
              backgroundImage: `
                linear-gradient(135deg, ${ theme.new.palette.primary[600].main } 25%,
                ${ theme.new.palette.primary[500].main } 25%,
                ${ theme.new.palette.primary[500].main } 50%,
                ${ theme.new.palette.primary[600].main } 50%,
                ${ theme.new.palette.primary[600].main } 75%,
                ${ theme.new.palette.primary[500].main } 75%,
                ${ theme.new.palette.primary[500].main } 100%)
              `,
              backgroundSize: '125px 125px',
              animation: `${ backgroundScrollAnimation } 2s linear infinite`,
            }
            : {},

          '::after': {
            content: '""',
            display: 'block',
            backgroundColor: theme.new.palette.primary[600].main,
            width: '100%',
            height: '100%',
            transformOrigin: 'left',
            transform: 'translateX(-100%)',
            ...progress !== 'indeterminate'
              ? {
                transform: `translateX(${ (100 * progress) - 100 }%)`,
                transition: 'transform cubic-bezier(0, 0.5, 0.5, 1) 0.3s',
              }
              : {},
            borderRadius: 'inherit',
          }
        }) }
      />
      { children && (
        <div
          css={ {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          } }
        >
          <div
            css={ theme => ({
              color: theme.new.palette.grey[25].main,
              fontWeight: 300,
              fontSize: '14px',
              textShadow: `
                1px 1px 1px ${ rgba(theme.new.palette.grey[500].main, 0.3) },
                0px 1px 1px ${ rgba(theme.new.palette.grey[500].main, 0.3) },
                1px 0px 1px ${ rgba(theme.new.palette.grey[500].main, 0.3) },
                -1px -1px 1px ${ rgba(theme.new.palette.grey[500].main, 0.3) },
                0px -1px 1px ${ rgba(theme.new.palette.grey[500].main, 0.3) },
                -1px 0px 1px ${ rgba(theme.new.palette.grey[500].main, 0.3) }
              `,
            }) }
          >
            { children }
          </div>
        </div>
      ) }
      { showNumericValue && progress !== 'indeterminate' && (
        <Typography
          size="sm"
          weight="medium"
          palette={ {
            colour: 'grey',
            intensity: 700,
          } }
        >
          { progress * 100 }%
        </Typography>
      ) }
    </StyledProgressBar>
  );
};

const backgroundScrollAnimation = keyframes({
  '0%': {
    backgroundPosition: '0%',
  },

  '100%': {
    backgroundPosition: '100%',
  },
});
