import { useIntl } from 'react-intl';
import { useMemo } from 'react';

import { ValidationErrorIdentifier } from '../Utility/ValidationErrorIdentifier';
import { ValidationError } from '../Model';

export const useLocalisedDateErrors = (
  errors: ValidationError[],
  localisedMinDate?: string,
  localisedMaxDate?: string,
  fieldName?: string,
): string[] => {
  const intl = useIntl();
  const localisedFieldName = fieldName || intl.formatMessage({
    id: 'dateValidationErrors.fieldName',
    description: 'Default name for a date field used in validation errors.',
    defaultMessage: 'Date',
  });

  return useMemo(() => (
    errors.reduce<string[]>((localisedErrors, error) => (
      ValidationErrorIdentifier.isTooEarlyError(error)
        ? localisedErrors.concat(intl.formatMessage(
          {
            id: 'dateValidationErrors.isTooEarly',
            description: 'Date validation error when date is before minimum date.',
            defaultMessage: '{ localisedFieldName } must be after { localisedMinDate }.',
          }, {
            localisedFieldName,
            localisedMinDate: localisedMinDate || new Date(error.metadata.minDate).toLocaleDateString(),
          }
        ))
        : ValidationErrorIdentifier.isTooLateError(error)
          ? localisedErrors.concat(intl.formatMessage(
            {
              id: 'dateValidationErrors.isTooLate',
              description: 'Date validation error when date is after maximum date.',
              defaultMessage: '{ localisedFieldName } must be before { localisedMaxDate }.',
            }, {
              localisedFieldName,
              localisedMaxDate: localisedMaxDate || new Date(error.metadata.maxDate).toLocaleDateString(),
            }
          ))
          : localisedErrors
    ), [])
  ), [errors, intl, localisedFieldName, localisedMaxDate, localisedMinDate]);
};
