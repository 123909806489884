import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import {formatISO, max, parseISO} from 'date-fns';
import {FormattedMessage, useIntl} from 'react-intl';
import {Tooltip} from '@mui/material';
import {useDebounce} from '@ourpeople/shared/Core/Hook/useDebounce';
import ImportedPersonIcon from 'op-storybook/lib/assets/icon/figma/user-down-01.svg'
import { PresentationIcon } from 'op-storybook/lib/components/PresentationIcon/PresentationIcon';
import { Badge } from 'op-storybook';

import {Checkbox, PaginatedTable, TableCell, TableRow, UserRole, UserStatus} from '../../../Components';
import AlertIcon from '../../../Assets/img/icons/monochrome/alert.svg';
import {PersonParser, TextMatcher} from '../../../Utility';
import {RoleReader} from '../../../Readers';
import {DateTime} from '../../../Common/Component';
import {Person, RequestState} from '../../../Models';
import {ImmutableMap} from '../../../Models/ImmutableMap';
import {ApiContext} from '../../../Contexts';
import {
  IntegrationSyncingStatus,
  PeopleTableActions,
  PeopleTableFilters,
  PeopleTableFilterSelection,
  PeopleTableMenu
} from '..';
import {PeopleRequestParams, useFetchPeople} from '../../Hook';
import {useContextOrThrow, useDistinct} from '../../../Core/Hook';
import {useUserRoles} from '../../../Common/Hook';
import {ProfileCountCell} from './styles';
import {RoleId} from '../../../Security/Model';
import {ToastContext} from '../../../Core/Context';
import { PersonAvatarWithName } from '../../../Common/Component/PersonAvatarWithName/PersonAvatarWithName';

type Props = {
  pageNum: number;
  onPageNumChanged: (pageNum: number) => void;
  filterSelection: PeopleTableFilterSelection;
  setFilterSelection: Dispatch<SetStateAction<PeopleTableFilterSelection>>,
}

export const PeopleTable: FunctionComponent<Props> = ({
  pageNum,
  onPageNumChanged,
  filterSelection,
  setFilterSelection,
}) => {
  const intl = useIntl();
  const { userIsSuperAdmin } = useUserRoles();
  const api = useContext(ApiContext);
  const [people, setPeople] = useState<ImmutableMap<string, Person>>(ImmutableMap.create());
  const [selectedPeople, setSelectedPeople] = useState<ImmutableMap<string, Person>>(ImmutableMap.create());
  const [selectionExpanded, setSelectionExpanded] = useState<boolean>(false);
  const [deletedPeopleIds, setDeletedPeopleIds] = useState<string[]>([]);
  const { addSuccessToast, addErrorToast } = useContextOrThrow(ToastContext);
  const [togglingFrozenIds, setTogglingFrozenIds] = useState<string[]>([]);
  const [deletingIds, setDeletingIds] = useState<string[]>([]);

  const peopleRequestParams = useMemo<PeopleRequestParams>(
    () => ({
      pageNum,
      sort: ['first_name_asc', 'last_name_asc'],
      teamIds: filterSelection.teamIds.length
        ? filterSelection.teamIds
        : undefined,
      roleIds: filterSelection.roleIds.length
        ? filterSelection.roleIds
        : undefined,
      statuses: filterSelection.statusIds.length
        ? filterSelection.statusIds
        : undefined,
      search: filterSelection.search || undefined,
      noWithProfile: filterSelection.profiles === 'without',
      noWithoutProfile: filterSelection.profiles === 'with',
    }),
    [pageNum, filterSelection],
  );
  const bulkActionFilters = useMemo<PeopleRequestParams>(
    () => {
      const {pageNum, ...remainingParams} = peopleRequestParams;
      return {
        ...remainingParams,
        ids: selectedPeople.count
          ? selectedPeople.values.map(person => person.id)
          : undefined,
      };
    },
    [peopleRequestParams, selectedPeople],
  );
  const debouncedParams = useDistinct<PeopleRequestParams>(
    useDebounce(peopleRequestParams, 250),
    peopleRequestParamsEqual,
  );
  const [paginatedPeople, peopleRequestState, reloadPeople] = useFetchPeople(debouncedParams);
  const shouldEnableInvite = useMemo((): boolean => (
    (
      selectionExpanded
      && (
        debouncedParams.statuses?.includes('added')
        || debouncedParams.statuses?.includes('pending')
        || (debouncedParams.statuses || '') === ''
      )
    ) || (
      !selectionExpanded
      && selectedPeople.filter((_key, person) => (
        person.status === 'added'
        || person.status === 'pending'
      )).count > 0
    )
  ), [debouncedParams, selectedPeople, selectionExpanded]);
  const [bulkActionState, setBulkActionState] = useState<RequestState>(RequestState.PENDING);
  const pagination = useMemo(() => paginatedPeople?.content?.pagination, [paginatedPeople?.content?.pagination]);
  let currentPageSize = 0;

  if (pagination) {
    if (pageNum === pagination.pageCount) {
      currentPageSize = people.count - deletedPeopleIds.length;
    } else {
      currentPageSize = pagination.pageSize - deletedPeopleIds.length;
    }
  }

  useEffect(() => {
    setDeletedPeopleIds([]);
    setPeople(
      ImmutableMap.fromArray(
        (paginatedPeople?.content?.people || []).map(person => [person.id, person]),
      ),
    );
    setSelectedPeople(prev => prev.clear());
    setSelectionExpanded(false);
  }, [paginatedPeople]);

  const getLastActivity = (person: Person): string => {
    const activityDates = (
      [
        person.lastLoginAt,
        person.firstInvitedAt,
      ].filter(isoDate => !!isoDate) as string[]
    ).map(isoDate => parseISO(isoDate));

    return activityDates.length
      ? formatISO(max(activityDates))
      : '';
  }

  const whenIntegrationSyncComplete = useCallback((): void => reloadPeople(), [reloadPeople]);

  const deletePerson = (person: Person): void => {
    if (!api) {
      return;
    }

    const confirmation = prompt(
      intl.formatMessage({
        id: 'people.delete.message',
        description: 'Prompt presented to user when deleting a single person',
        defaultMessage: 'Type "permanently delete" to delete {name}'
      }, {
        name: PersonParser.fullName(person),
      })
    ) || '';

    if (TextMatcher.caseInsensitive(confirmation, 'permanently delete')) {
      setDeletingIds(deletingIds => deletingIds.concat(person.id));
      api.delete(`/people/${ person.id }`)
        .then(() => {
          deselectPerson(person);
          setDeletedPeopleIds((prev) => prev.concat(person.id));
          addSuccessToast(
            intl.formatMessage({
              id: 'person.delete.success',
              description: 'Success message when deleting person',
              defaultMessage: '{name} deleted successfully.'
            }, {
              name: PersonParser.fullName(person),
            })
          );
          setDeletingIds(deletingIds => deletingIds.filter(deletingId => deletingId !== person.id));
        })
        .catch(() => {
          addErrorToast(
            intl.formatMessage({
              id: 'person.delete.fail',
              description: 'Failure message when deleting person',
              defaultMessage: '{name} could not be deleted.'
            }, {
              name: PersonParser.fullName(person),
            })
          );
          setDeletingIds(deletingIds => deletingIds.filter(deletingId => deletingId !== person.id));
        })
    }
  }

  const freezePerson = (person: Person): void => {
    if (api) {
      setTogglingFrozenIds(togglingFrozenIds => togglingFrozenIds.concat(person.id));
      api.put<Person>(`/people/${person.id}/freeze`)
        .then(response => response.data)
        .then(updatedPerson => {
          setPeople(previousPeople => previousPeople.set(
            person.id,
            updatedPerson,
          ));
          addSuccessToast(
            intl.formatMessage({
              id: 'person.freeze.success',
              description: 'Success message when freezing person',
              defaultMessage: '{name} frozen successfully.'
            }, {
              name: PersonParser.fullName(person),
            })
          );
          setTogglingFrozenIds(togglingFrozenIds => togglingFrozenIds.filter(togglingFrozenId => togglingFrozenId !== person.id));
        })
        .catch(() => {
          addErrorToast(
            intl.formatMessage({
              id: 'person.freeze.fail',
              description: 'Failure message when freezing person',
              defaultMessage: '{name} could not be frozen.'
            }, {
              name: PersonParser.fullName(person),
            })
          );
          setTogglingFrozenIds(togglingFrozenIds => togglingFrozenIds.filter(togglingFrozenId => togglingFrozenId !== person.id));
        });
    }
  }

  const unfreezePerson = (person: Person): void => {
    if (api) {
      setTogglingFrozenIds(togglingFrozenIds => togglingFrozenIds.concat(person.id));
      api.put<Person>(`/people/${person.id}/unfreeze`)
        .then(response => response.data)
        .then(updatedPerson => {
          setPeople(previousPeople => previousPeople.set(
            person.id,
            updatedPerson,
          ));
          addSuccessToast(
            intl.formatMessage({
              id: 'person.unfreeze.success',
              description: 'Success message when unfreezing person',
              defaultMessage: '{name} unfrozen successfully.'
            }, {
              name: PersonParser.fullName(person),
            })
          );
          setTogglingFrozenIds(togglingFrozenIds => togglingFrozenIds.filter(togglingFrozenId => togglingFrozenId !== person.id));
        })
        .catch(() => {
          addErrorToast(
            intl.formatMessage({
              id: 'person.unfreeze.fail',
              description: 'Failure message when unfreezing person',
              defaultMessage: '{name} could not be unfrozen.'
            }, {
              name: PersonParser.fullName(person),
            })
          );
          setTogglingFrozenIds(togglingFrozenIds => togglingFrozenIds.filter(togglingFrozenId => togglingFrozenId !== person.id));
        });
    }
  }

  const togglePersonFrozen = (person: Person): void => {
    if (api) {
      if (person.status !== 'frozen') {
        freezePerson(person);
      } else {
        unfreezePerson(person);
      }
    }
  }

  const whenSelectAllClicked = (): void => {
    if (selectionExpanded || selectedPeople.count >= currentPageSize) {
      return clearSelection();
    }

    selectPage();
  };

  const clearSelection = (): void => {
    setSelectedPeople((prev) => prev.clear());
    setSelectionExpanded(false);
  }

  const selectPage = (): void => {
    setSelectedPeople(
      people
        .filter(personId => deletedPeopleIds.indexOf(personId) === -1)
    );
    setSelectionExpanded(false);
  }

  const deselectPerson = (person: Person): void => {
    if (selectionExpanded) {
      setSelectedPeople(
        people
          .filter(personId => deletedPeopleIds.indexOf(personId) === -1 && personId !== person.id)
      );
    } else {
      setSelectedPeople(prev => prev.remove(person.id));
    }
    setSelectionExpanded(false);
  }

  const selectPerson = (person: Person): void => {
    setSelectedPeople((prev) => prev.set(person.id, person));
  }

  const togglePersonSelected = (person: Person): void => {
    if (selectionExpanded || selectedPeople.contains(person.id)) {
      deselectPerson(person);
    } else {
      selectPerson(person);
    }
  }

  const reloadPeopleOnCompletion = (
    request: Promise<unknown>,
    successMessage: string,
    failureMessage: string,
  ): void => {
    setBulkActionState(RequestState.FETCHING);
    request
      .then(() => new Promise(resolve => setTimeout(resolve, 50)))
      .then(reloadPeople)
      .then(() => {
        setSelectionExpanded(false);
        setSelectedPeople((prev) => prev.clear());
        setBulkActionState(RequestState.COMPLETE);
        addSuccessToast(successMessage);
      })
      .catch(() => {
        setBulkActionState(RequestState.FAILED);
        addErrorToast(failureMessage);
      });
  }

  const whenFilterSelectionCleared = (): void => {
    setFilterSelection({
      search: '',
      profiles: '',
      teamIds: [],
      roleIds: [],
      statusIds: [],
    });
  }

  const whenExpandSelectionClicked = (): void => {
    setSelectedPeople(prev => prev.clear());
    setSelectionExpanded(true);
  }

  const whenClearSelectionClicked = (): void => {
    clearSelection();
  }

  return (
    <>
      {
        userIsSuperAdmin && (
          <IntegrationSyncingStatus onSyncComplete={ whenIntegrationSyncComplete }/>
        )
      }
      <div>
        <PeopleTableFilters
          filterSelection={filterSelection}
          setFilterSelection={setFilterSelection}
          onClearFiltersClicked={whenFilterSelectionCleared}
          currentPageSize={currentPageSize}
          showActions={selectedPeople.count > 0 || selectionExpanded}
          actions={
            <PeopleTableActions
              people={selectedPeople}
              showDelete={userIsSuperAdmin}
              showInvite={userIsSuperAdmin}
              enableInvite={shouldEnableInvite}
              bulkActionFilters={bulkActionFilters}
              onBulkActionInitiated={reloadPeopleOnCompletion}
            />
          }
          onClearSelectionClicked={whenClearSelectionClicked}
          onExpandSelectionClicked={whenExpandSelectionClicked}
          selectedPersonCount={ selectionExpanded ? pagination?.itemCount || 0 : selectedPeople.count }
          totalPersonCount={pagination?.itemCount || 0}
        />
      </div>
      <PaginatedTable
        headerRow={
          <TableRow>
            {
              userIsSuperAdmin && (
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={ selectionExpanded || selectedPeople.count >= currentPageSize }
                    indeterminate={ selectedPeople.count > 0 && selectedPeople.count < currentPageSize }
                    onChange={ whenSelectAllClicked }
                  />
                </TableCell>
              )
            }
            <TableCell>
              <FormattedMessage
                id="people.table.name"
                description="Name header in people table"
                defaultMessage="Name"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                id="people.table.status"
                description="Status header in people table"
                defaultMessage="Status"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                id="people.table.profiles"
                description="Profile header in people table"
                defaultMessage="Profiles"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                id="people.table.role"
                description="Role header in people table"
                defaultMessage="Role"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                id="people.table.activity"
                description="Activity header in people table"
                defaultMessage="Last activity"
              />
            </TableCell>
            <TableCell/>
          </TableRow>
        }
        rows={ people.values }
        rowRender={ person => (
          <TableRow disabled={ deletedPeopleIds.indexOf(person.id) !== -1 } key={ person.id }>
            {
              userIsSuperAdmin && (
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    checked={
                      (selectionExpanded || selectedPeople.contains(person.id))
                      && deletedPeopleIds.indexOf(person.id) < 0
                    }
                    onChange={ () => togglePersonSelected(person) }
                    disabled={ deletedPeopleIds.indexOf(person.id) !== -1 }
                  />
                </TableCell>
              )
            }
            <TableCell>
              <PersonAvatarWithName
                person={ person }
                {
                  ...person.externallyManaged
                    ? {
                      startAdornment: (
                        <Tooltip
                          title={ intl.formatMessage({
                            id: 'people.externallyManaged',
                            description: 'Tooltip when hovering over icon indicating that a person is managed externally.',
                            defaultMessage: 'This person is managed by an external system or integration.',
                          }) }
                        >
                          <div>
                            <PresentationIcon
                              IconComponent={ ImportedPersonIcon }
                              size={ 4 }
                            />
                          </div>
                        </Tooltip>
                      )
                    }
                    : {}
                }
                supportingText={
                  <>
                    { person.secret && (
                      <Badge
                        variant="badge-modern"
                        label={ intl.formatMessage({
                          description: 'Label for secret person in people table',
                          defaultMessage: 'Secret',
                        }) }
                        colour="grey"
                      />
                    ) }
                  </>
                }
              />
            </TableCell>
            <TableCell>
              <UserStatus status={ person.status }/>
            </TableCell>
            <ProfileCountCell>
              <div>
                { person.profileCount }
                {
                  person.profileCount === 0 && (
                    <Tooltip
                      title={ intl.formatMessage({
                        id: 'people.hasNoProfile',
                        description: 'Tooltip when hovering over icon indicating that a person has no profiles.',
                        defaultMessage: 'This person has no profiles.',
                      }) }
                    >
                      <span><AlertIcon/></span>
                    </Tooltip>
                  )
                }
              </div>
            </ProfileCountCell>
            <TableCell>
              <UserRole role={ RoleReader.getMostPermissiveRole(person.roleIds) || 'ROLE_USER' as RoleId }/>
            </TableCell>
            <TableCell>
              <DateTime dateTime={ getLastActivity(person) }/>
            </TableCell>
            <TableCell>
              <PeopleTableMenu
                deleted={ deletedPeopleIds.indexOf(person.id) !== -1 }
                person={ person }
                onFreezeClicked={ togglePersonFrozen }
                togglingFrozen={ togglingFrozenIds.includes(person.id) }
                onDeleteClicked={ deletePerson }
                deleting={ deletingIds.includes(person.id) }
              />
            </TableCell>
          </TableRow>
        ) }
        pageNum={ pageNum }
        onPageChanged={ onPageNumChanged }
        requestState={ Math.min(peopleRequestState || bulkActionState) }
        onRetryClicked={ reloadPeople }
        pagination={ pagination }
      />
    </>
  );
};

const peopleRequestParamsEqual = (a: PeopleRequestParams, b: PeopleRequestParams): boolean => {
  return JSON.stringify(a) === JSON.stringify(b);
}
