import { FC, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { SwitchWithIcon } from '../SwitchWithIcon/SwitchWithIcon';
import EmailIcon from '../../../Assets/img/icons/streamline/email.svg';
import { Flex, VerticallySpaced } from '../../../Common/Component';
import { MinimalNotificationChannelSettings } from '../../Model';

type Props = {
  notificationSettings: MinimalNotificationChannelSettings;
  onChange: (notificationSettings: MinimalNotificationChannelSettings) => void;
};

export const EmailInput: FC<Props> = ({
  notificationSettings,
  onChange,
}) => {
  const whenSendChanged = useCallback((send: boolean) => (
    onChange({
      ...notificationSettings,
      send,
      ...(send ? { noStatuses: null } : {}),
    })
  ), [notificationSettings, onChange]);

  return (
    <VerticallySpaced gap={ 2 }>
      <Flex gap={ 2 }>
        <SwitchWithIcon
          IconComponent={ EmailIcon }
          checked={ notificationSettings.send }
          onChange={ whenSendChanged }
        />
        <FormattedMessage
          description="Message beside email switch in delivery settings."
          defaultMessage="Send an email notification to recipients with email enabled"
        />
      </Flex>
    </VerticallySpaced>
  );
};
