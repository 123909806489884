import { ChangeEventHandler, FC, useCallback } from 'react';
import { TextField } from '@mui/material';
import { Heading } from '@ourpeople/shared/Core/Component/Content';

import { CustomField } from '../../Hook';
import { FieldValidationErrors } from '../../../Common/Component';
import { ValidationTree } from '../../../Common/Model';
import { useDescendantErrors } from '../../../Common/Hook';

type Props = {
  label: string;
  customField: CustomField;
  onChange: (customField: CustomField) => void;
  validation?: ValidationTree<CustomField>;
  disabled?: boolean;
};

export const CustomFieldInput: FC<Props> = ({
  label,
  customField,
  onChange,
  validation,
  disabled = false,
}) => {
  const validationErrors = useDescendantErrors(validation);

  const whenChanged: ChangeEventHandler<HTMLInputElement> = useCallback(event => (
    onChange({
      ...customField,
      value: event.currentTarget.value,
    })
  ), [customField, onChange]);

  return (
    <div>
      <label htmlFor={ customField.key }>
        <Heading type="h4">
          { label }
        </Heading>
      </label>
      <TextField
        id={ customField.key }
        variant="outlined"
        size="small"
        onChange={ whenChanged }
        value={ customField.value }
        disabled={ disabled }
        fullWidth
      />
      <FieldValidationErrors
        fieldName={ label }
        validationErrors={ validationErrors }
      />
    </div>
  );
};
