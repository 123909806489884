import { IntlShape } from 'react-intl';

import { FileUploadError } from '../Component';
import { FileSizeFormatter } from '../Model';

interface FileTooLargeError extends FileUploadError {
  code: 'FILE_TOO_LARGE';
  metadata: {
    maxSize: number;
    fileSize: number;
  };
}

export class FileUploadErrorLocaliser {
  public static localise = (fileUploadError: FileUploadError, intl: IntlShape): string => {
    switch (fileUploadError.code) {
      case 'INVALID_FILE_EXTENSION':
        return intl.formatMessage({
          id: 'input.upload.unsupported',
          description: 'Error when a file type is unsupported.',
          defaultMessage: 'Could not complete the upload, this file type is not supported',
        });
      case 'FAILED_MIME_TYPE':
        return intl.formatMessage({
          id: 'input.upload.unknown',
          description: 'Error when a file\'s MIME type could not be detected.',
          defaultMessage: 'Could not complete the upload, unable to determine the type of file',
        });
      case 'FAILED_IMAGE_DIMENSIONS':
        return intl.formatMessage({
          id: 'input.upload.dimensions',
          description: 'Error when an image could not have its dimensions detected.',
          defaultMessage: 'Could not complete the upload, unable to determine the image dimensions',
        });
      case 'INFECTION_SCAN_FAILED':
        return intl.formatMessage({
          id: 'input.upload.noScan',
          description: 'Error when an infection scan fails to run.',
          defaultMessage: 'Could not complete the upload, unable to scan the file for infections',
        });
      case 'FILE_TOO_LARGE':
        const fileTooLargeError = fileUploadError as FileTooLargeError;
        return intl.formatMessage({
          id: 'input.upload.size',
          description: 'Error when a file is too big.',
          defaultMessage: 'Could not complete the upload, provided file is { actualSize } but the maximum allowed is { maxSize }',
        }, {
          actualSize: FileSizeFormatter.format(fileTooLargeError.metadata.fileSize),
          maxSize: FileSizeFormatter.format(fileTooLargeError.metadata.maxSize),
        });
      case 'INCORRECT_FILE_EXTENSION':
      case 'UNSUITABLE_MIME_TYPE':
        return intl.formatMessage({
          id: 'input.upload.mimeType',
          description: 'Error when the MIME type doesn\'t match the file extension.',
          defaultMessage: 'Could not complete the upload, file extension does not match the detected file type',
        });
      case 'INFECTION_FOUND':
        return intl.formatMessage({
          id: 'input.upload.infected',
          description: 'Error when an infection is found.',
          defaultMessage: 'Could not complete the upload, virus detected in provided file',
        });
      default:
        return intl.formatMessage({
          id: 'input.upload.unknown',
          description: 'Error when an unrecognized error occurs.',
          defaultMessage: 'Could not complete the upload, an unknown error occurred.',
        });
    }
  };
}
