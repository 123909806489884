import { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { IntlShape } from 'react-intl/src/types';

import { MappedSettingTypes, SettingType } from '../../Types';
import { MappedSettingInput } from './MappedSettingInput';
import { StyledCollectionItem, StyledCollectionItemInput } from './styles';
import { ValidationErrorMessage } from '../../../../../Common/Component';

type Props = {
  config: MappedSettingTypes['collection']['config'],
  value: MappedSettingTypes['collection']['value'],
  onValueChanged: (value: MappedSettingTypes['collection']['value']) => void,
  focusId: string,
};

export const CollectionSettingInput = ({
  config,
  value,
  onValueChanged,
  focusId,
}: Props): JSX.Element => {
  const intl = useIntl();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const error = validate(value, config, intl);
    setError(error);
  }, [value, config, intl]);

  const whenItemChanged = (itemIndex: number) => {
    return (item: MappedSettingTypes[SettingType]['value']): void => {
      const updatedItems = [...value];
      updatedItems[itemIndex] = item;
      onValueChanged(updatedItems);
    }
  }

  const whenRemoveButtonClicked = (itemIndex: number) => {
    return (): void => {
      const updatedItems = [...value];
      updatedItems.splice(itemIndex, 1);
      onValueChanged(updatedItems);
    }
  }

  const whenAddButtonClicked = (): void => {
    onValueChanged([
      ...value,
      null,
    ]);
  }

  return (
    <>
      {
        value.map((item, index) => (
          <StyledCollectionItem key={index}>
            <StyledCollectionItemInput>
              <MappedSettingInput
                type={config.itemType}
                value={item}
                config={config.itemConfig}
                onValueChanged={whenItemChanged(index)}
                focusId={index === 0 ? focusId : `${ focusId }_${ index }`}
              />
            </StyledCollectionItemInput>
            <Button
              color="primary"
              onClick={whenRemoveButtonClicked(index)}
            >
              {
                <FormattedMessage
                  id="settings.collectionSetting.removeCta"
                  description="Button to remove an item from collection setting"
                  defaultMessage="Remove"
                />
              }
            </Button>
          </StyledCollectionItem>
        ))
      }
      <Button
        color="primary"
        disabled={config.maxCount !== null && value.length >= config.maxCount}
        onClick={whenAddButtonClicked}
      >
        {
          <FormattedMessage
            id="settings.collectionSetting.addCta"
            description="Button to add an item to collection setting"
            defaultMessage="Add"
          />
        }
      </Button>
      {
        error
        && (
          <ValidationErrorMessage>{error}</ValidationErrorMessage>
        )
      }
    </>
  );
};

const validate = (
  value: MappedSettingTypes['collection']['value'],
  config: MappedSettingTypes['collection']['config'],
  intl: IntlShape,
): string | null => {
  if (config.minCount !== null && value.length < config.minCount) {
    return intl.formatMessage(
      {
        id: 'settings.collectionSetting.minCountError',
        defaultMessage: 'Must have at least {minCount} items',
      },
      {
        minCount: config.minCount,
      }
    );
  }

  if (config.maxCount !== null && value.length > config.maxCount) {
    return intl.formatMessage(
      {
        id: 'settings.collectionSetting.maxCountError',
        defaultMessage: 'Must have no more than {maxCount} items',
      },
      {
        maxCount: config.maxCount,
      }
    );
  }

  return null;
}
