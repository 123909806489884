import { useIntl } from 'react-intl';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { ExpandingWidget } from '../ExpandingWidget/ExpandingWidget';
import { useContextOrThrow } from '../../../Core/Hook';
import { FileUploaderContext } from '../../Context';
import { StyledUploadList } from './style';
import { useLocalStorageHookContext } from '../../Hook';
import { UploadWidgetListItem } from '../UploadWidgetListItem/UploadWidgetListItem';
import { UploadStateHelper, UploadStatus } from '../../Utility/UploadStateHelper';
import { RequestState } from '../../../Models';

export const UploadWidget: FC = () => {
  const intl = useIntl();
  const { uploadStates, getCallbackState } = useContextOrThrow(FileUploaderContext);
  const getStorageHook = useLocalStorageHookContext();
  const useUploadWidgetExpanded = getStorageHook<boolean>('uploads.widget.expanded', true);
  const [expanded, setExpanded] = useUploadWidgetExpanded();
  const [dismissedUploadIds, setDismissedUploadIds] = useState<string[]>([]);
  const notDismissedUploadStates = useMemo(() => (
    (Array.from(uploadStates.values()))
      .filter(
        uploadState => uploadState.globalUpload,
      )
      .filter((uploadState) => !dismissedUploadIds.includes(uploadState.ref))
      .reverse()
  ), [dismissedUploadIds, uploadStates]);

  const incompleteUploadCount = useMemo<number>(() => (
    Array.from(uploadStates.values()).filter(uploadState => !UploadStateHelper.uploadIsComplete(uploadState)).length
  ), [uploadStates]);

  const dismissCurrentUploads = useCallback(() => (
    setDismissedUploadIds(
      dismissedUploadIds => dismissedUploadIds.concat(notDismissedUploadStates.map(uploadState => uploadState.ref)),
    )
  ), [notDismissedUploadStates]);

  useEffect(() => {
    if (incompleteUploadCount > 0) {
      return;
    }

    const timeout = setTimeout(dismissCurrentUploads, 30000);

    return () => clearTimeout(timeout);
  }, [dismissCurrentUploads, incompleteUploadCount, notDismissedUploadStates]);

  const uploadCounts = useMemo<Record<UploadStatus | 'ongoing', number>>(() => (
    notDismissedUploadStates.reduce(
      (counts, uploadState) => {
        const callbackState = getCallbackState(uploadState.ref);
        const uploadStatus = UploadStateHelper.determineUploadStatus(uploadState);
        const uploadAndCallbackStatus = uploadStatus === UploadStatus.COMPLETE && callbackState !== undefined
          ? callbackState === RequestState.COMPLETE
            ? UploadStatus.COMPLETE
            : callbackState === RequestState.FAILED
              ? UploadStatus.FAILED
              : UploadStatus.PROCESSING
          : uploadStatus;
        const callbackIsOngoing = callbackState !== undefined
          && callbackState !== RequestState.FAILED
          && callbackState !== RequestState.COMPLETE;

        return {
          ...counts,
          [uploadAndCallbackStatus]: counts[uploadAndCallbackStatus] + 1,
          ongoing: UploadStateHelper.uploadIsOngoing(uploadState)
            ? counts.ongoing + 1
            : UploadStateHelper.uploadIsComplete(uploadState) && callbackIsOngoing
              ? counts.ongoing + 1
              : counts.ongoing,
        };
      },
      {
        [UploadStatus.PROCESSING]: 0,
        [UploadStatus.IN_PROGRESS]: 0,
        [UploadStatus.SCANNING]: 0,
        [UploadStatus.COMPLETE]: 0,
        [UploadStatus.FAILED]: 0,
        ongoing: 0,
      },
    )
  ), [getCallbackState, notDismissedUploadStates]);

  const uploadSummary = uploadCounts[UploadStatus.FAILED]
    ? intl.formatMessage({
      description: 'Label for upload widget summary when one or more uploads have failed.',
      defaultMessage: '{ failedUploadCount, plural, =1 {Upload} other {Uploads} } failed',
    }, { failedUploadCount: uploadCounts[UploadStatus.FAILED] })
    : uploadCounts[UploadStatus.PROCESSING]
      ? intl.formatMessage({
        description: 'Label for upload widget summary when one or more uploads are being processed before upload.',
        defaultMessage: 'Processing { processingUploadCount, plural, =1 {upload} other {uploads} }',
      }, { processingUploadCount: uploadCounts.ongoing })
      : uploadCounts[UploadStatus.IN_PROGRESS]
        ? intl.formatMessage({
          description: 'Label for upload widget summary when one or more uploads are in progress.',
          defaultMessage: '{ inProgressUploadCount, plural, =1 {Upload} other {Uploads} } in progress',
        }, { inProgressUploadCount: uploadCounts.ongoing })
        : uploadCounts[UploadStatus.SCANNING]
          ? intl.formatMessage({
            description: 'Label for upload widget summary when one or more uploads are being scanned for viruses.',
            defaultMessage: 'Scanning for viruses',
          })
          : uploadCounts[UploadStatus.COMPLETE]
            ? intl.formatMessage({
              description: 'Label for upload widget summary when one or more uploads are complete.',
              defaultMessage: '{ completeUploadCount, plural, =1 {Upload} other {Uploads} } complete',
            }, { completeUploadCount: uploadCounts[UploadStatus.COMPLETE] })
            : intl.formatMessage({
              description: 'Generic upload state in upload widget summary.',
              defaultMessage: 'Uploading',
            });

  return notDismissedUploadStates.length
    ? (
      <ExpandingWidget
        title={ uploadSummary }
        expanded={ expanded }
        onToggle={ () => setExpanded(expanded => !expanded) }
        onClose={ dismissCurrentUploads }
      >
        <StyledUploadList>
          { notDismissedUploadStates.map(uploadState => (
            <UploadWidgetListItem
              key={ uploadState.ref }
              uploadState={ uploadState }
              callbackState={ getCallbackState(uploadState.ref) }
            />
          )) }
        </StyledUploadList>
      </ExpandingWidget>
    )
    : null;
};
