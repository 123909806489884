import { IntlShape } from 'react-intl';

import { AudienceConditionDefinition, ConditionContentsSummary, NobodyCondition } from '../../Model';

/** @deprecated Used in old audience builder */
export class NobodyConditionDefinition implements AudienceConditionDefinition<
  NobodyCondition,
  null
> {
  public readonly TYPE = 'nobody';

  public definesConditionType(conditionType: string): boolean {
    return conditionType === this.TYPE;
  }

  public contentsSummary(
    intl: IntlShape,
    condition: NobodyCondition,
    conditionContents: null,
  ): ConditionContentsSummary {
    return {
      exclusive: true,
      soloSummary: intl.formatMessage({
        id: 'audience.conditionDefinition.nobody.contentsSummary.solo',
        defaultMessage: 'Nobody',
      }),
      andSummary: intl.formatMessage({
        id: 'audience.conditionDefinition.nobody.contentsSummary.and',
        defaultMessage: 'Nobody',
      }),
    };
  }
}
