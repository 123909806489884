import { useIntl } from 'react-intl';
import { FunctionComponent, useEffect, useMemo, useRef } from 'react';
import { ListItem, ListItemText } from '@mui/material';

import { Picker } from '../../../Components/Input/Picker/Picker';

type Props = {
  selection: string;
  onSelectionChanged: (selection: ProfileFilterOption | undefined) => void;
}

export interface ProfileFilterOption {
  value: string;
  label: string;
}

export const ProfileFilter: FunctionComponent<Props> = ({
  selection,
  onSelectionChanged,
}) => {
  const intl = useIntl();
  const availableOptions: ProfileFilterOption[] = useMemo(
    () => ([
      {
        value: 'with',
        label: intl.formatMessage({
          id: 'people.filters.with',
          description: 'Profile filter: with',
          defaultMessage: 'With',
        }),
      },
      {
        value: 'without',
        label: intl.formatMessage({
          id: 'people.filters.without',
          description: 'Profile filter: without',
          defaultMessage: 'Without',
        }),
      },
    ]),
    [intl],
  );
  const selectionRef = useRef<string>('');

  useEffect(
    () => {
      if (selectionRef.current === selection) {
        return;
      }

      selectionRef.current = selection;

      const selectedOption = availableOptions
        .find((option) => option.value === selection);

      onSelectionChanged(selectedOption);
    },
    [selection, selectionRef, onSelectionChanged, availableOptions],
  );

  return (
    <Picker
      closeOnSelect={ true }
      buttonLabel={
        intl.formatMessage({
          id: 'people.filters.profile',
          defaultMessage: 'Profiles',
          description: 'Label for profile filter dropdown',
        })
      }
      items={ availableOptions }
      selectedItems={ availableOptions.filter((option) => option.value === selection) }
      itemRenderFn={ (option, selected) => (
        <ListItem
          dense
          key={ option.value }
          selected={ selected }
          onClick={ () => onSelectionChanged(option) }
        >
          <ListItemText
            primary={ option.label }
          />
        </ListItem>
      ) }
      itemCompareFn={ (a, b) => a.value === b.value }
    />
  )
}
