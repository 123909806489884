import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

export const EveryoneConditionComponent: FC = () => {
  return (
    <div>
      <FormattedMessage
        description="Audience condition summary that applies to everyone."
        defaultMessage="Everyone"
      />
    </div>
  );
};
