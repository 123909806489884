import {
  AutocompleteProps as MuiAutocompleteProps,
  Checkbox,
  Popper,
  PopperProps,
  TextField
} from '@mui/material';
import { useIntl } from 'react-intl';
import {
  FocusEventHandler,
  ReactNode,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { ChipTypeMap } from '@mui/material/Chip/Chip';
import { DropdownItem } from 'op-storybook/stories/components/DropdownItem/DropdownItem';
import { DropdownItemCheck } from 'op-storybook/stories/components/DropdownItemCheck/DropdownItemCheck';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';

import { StyledAutocomplete, StyledChip, StyledListbox, StyledRemoveButton } from './styles';
import CloseIcon from '../../../../../src/react/Assets/img/icons/monochrome/close.svg';
import { LabelledField } from '../LabelledField/LabelledField';
import { Chip, ChipState } from '../Chip/Chip';
import { AutocompletePopper } from '../../Content/AutocompletePopper/AutocompletePopper';

interface Option {
  id: string;
}

type ChipProps = ChipTypeMap['props'];

export interface AutocompleteQuery {
  search: string;
  pageNum: number;
}

export interface AutocompleteSelection<T> {
  options: T[];
  unknownIds: string[];
  badIds: string[];
}

interface Fetched<T> {
  badIds: string[];
  specificOptions: T[];
  queried: {
    options: T[];
    query: AutocompleteQuery,
    pageCount: number;
  };
}

export type AutocompleteFetchRequest<T> = (ids: string[], search: string, pageNum: number) => Promise<FetchResult<T>>;

export type AutocompleteSelectionChanged<T> = (selection: AutocompleteSelection<T>) => void;

interface FetchResult<T> {
  options: T[];
  pageCount: number;
}

export interface AutocompleteCommonProps {
  debug?: boolean;
  readOnlyIds?: string[];
  multiple?: boolean;
  showCheckboxes?: boolean;
  disableClearable?: boolean;
  disableCloseOnSelect?: boolean;
  clearOnBlur?: boolean;
  onBlur?: FocusEventHandler<HTMLDivElement>;
  onFocus?: FocusEventHandler<HTMLDivElement>;
  autoFocus?: boolean;
  label?: string;
  placeholder?: string;
  loadingText?: string;
  noOptionsText?: string;
  debounceDuration?: number;
  inputAdornment?: ReactNode;
  showChips?: boolean;
  dense?: boolean;
  error?: boolean;
  className?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  readonly?: boolean;
  variant?: 'outlined' | 'standard' | 'filled';
  noMargin?: boolean;
  listStyle?: 'grid' | 'list';
  id?: string;
  useNewTheme?: boolean;
}

export interface AutocompleteProps<T> extends AutocompleteCommonProps {
  prefetchedOptions?: T[];
  selectedIds: string[];
  onSelectionChanged: AutocompleteSelectionChanged<T>;
  fetchOptions: AutocompleteFetchRequest<T>;
  getOptionLabel: (option: T) => string;
  getOptionStartAdornment?: (option: T, context: 'chip' | 'menu') => ReactNode;
  resetToken?: string;
  useDebounce: (
    query: AutocompleteQuery,
    compareFn: (queryA: AutocompleteQuery, queryB: AutocompleteQuery) => boolean,
    duration: number,
  ) => AutocompleteQuery;
}

const getCustomPopper = (props: PopperProps, useNewTheme: boolean = false) => (
  useNewTheme
    ? (
      <AutocompletePopper
        { ...props }
      >
        { props.children }
      </AutocompletePopper>
    )
    : (
      <Popper
        { ...props }
        style={ { width: 'auto' } }
        placement="bottom-start"
      >
        { props.children }
      </Popper>
    )
);

const SCROLL_LOAD_THRESHOLD = 0.95;

export const Autocomplete = <T extends Option>({
  prefetchedOptions,
  readOnlyIds,
  selectedIds,
  onSelectionChanged,
  fetchOptions,
  getOptionLabel,
  getOptionStartAdornment,
  resetToken = '',
  multiple = false,
  showCheckboxes = multiple,
  disableCloseOnSelect = multiple,
  disableClearable = false,
  clearOnBlur = true,
  onBlur,
  onFocus,
  autoFocus = false,
  label,
  placeholder,
  loadingText,
  noOptionsText,
  debounceDuration = 200,
  inputAdornment,
  showChips = true,
  dense = false,
  error = false,
  className,
  disabled,
  fullWidth = false,
  readonly = false,
  variant = 'outlined',
  noMargin = false,
  listStyle = 'list',
  useDebounce,
  id,
  useNewTheme = false,
}: AutocompleteProps<T>): JSX.Element => {
  const intl = useIntl();
  const [inputText, setInputText] = useState<string>('');
  const [query, setQuery] = useState<AutocompleteQuery>({
    search: inputText,
    pageNum: 1,
  });
  const debouncedQuery = useDebounce(query, queriesEqual, debounceDuration);
  const [querying, setQuerying] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [loadMore, setLoadMore] = useState(false);
  const [selection, setSelection] = useState<AutocompleteSelection<T>>({
    options: prefetchedOptions
      ? prefetchedOptions.filter((initialValue) => selectedIds.includes(initialValue.id))
      : [],
    unknownIds: selectedIds.filter(selectedId => !(prefetchedOptions || []).find(initialValue => initialValue.id === selectedId)),
    badIds: [],
  });
  const selectedIdsRef = useRef<string[]>(selectedIds);
  const [fetched, setFetched] = useState<Fetched<T>>({
    specificOptions: prefetchedOptions || [],
    queried: {
      options: [],
      query: {
        search: '',
        pageNum: -1,
      },
      pageCount: 0,
    },
    badIds: [],
  });
  const fetchedRef = useRef<Fetched<T>>(fetched);

  /**
   * When `fetched` is changed, apply it to `fetchedRef` so that the fetch result can be used in hooks
   * without triggering an update.
   */
  useEffect(() => {
    fetchedRef.current = fetched;
  }, [fetched, fetchedRef]);

  /**
   * When `selectedIds` is changed, apply it to `selectedIdsRef` so that the selection can be used in hooks
   * without triggering an update.
   */
  useEffect(() => {
    selectedIdsRef.current = selectedIds;
  }, [selectedIds, selectedIdsRef]);

  /**
   * When `resetToken` is changed, reset/remove all previously fetched data.
   */
  useEffect(() => {
    if (fetchedRef.current.queried.query.pageNum < 0) {
      return;
    }

    setFetched({
      specificOptions: [],
      queried: {
        options: [],
        query: {
          search: '',
          pageNum: -1,
        },
        pageCount: 0,
      },
      badIds: [],
    });
    setSelection({
      options: [],
      unknownIds: selectedIdsRef.current,
      badIds: [],
    });
  }, [resetToken, selectedIdsRef, fetchedRef]);

  /**
   * When either `selectedIds` or `fetched` is changed, update and emit `selection` so that given IDs
   * are represented by their fetched options.
   */
  useEffect(() => {
    const knownOptions = [
      ...fetched.specificOptions,
      ...fetched.queried.options,
      ...selection.options,
    ];
    const knownIds = knownOptions.map(getOptionId);
    const selectedBadIds = selectedIds.filter((id) => fetched.badIds.includes(id));
    const selectedUnknownIds = selectedIds
      .filter((id) => knownIds.indexOf(id) < 0)
      .filter((id) => fetched.badIds.indexOf(id) < 0);
    const selectedOptions = selectedIds
      .map((id) => knownIds.indexOf(id))
      .filter((index) => index > -1)
      .map((index) => knownOptions[index])
      .slice(0, multiple ? undefined : 1);

    const updatedSelection = {
      options: selectedOptions,
      unknownIds: selectedUnknownIds,
      badIds: selectedBadIds,
    };

    // Avoid infinite effect loop...
    if (selectionsEqual(selection, updatedSelection)) {
      return;
    }

    setSelection(updatedSelection);
    onSelectionChanged(updatedSelection);
  }, [selectedIds, fetched, selection, multiple, onSelectionChanged]);

  /**
   * When `selection` is changed and contains unknown IDs, fetch those IDs so that they can be represented
   * as options. IDs that cannot be found are marked as "bad".
   */
  useEffect(() => {
    if (selection.unknownIds.length < 1) {
      return;
    }

    let cancelled = false;
    void (async () => {
      const fetchUnknownIds = async (
        accumulatedOptions: T[],
        unknownIds: string[],
      ): Promise<FetchResult<T>> => {
        const fetchResult = await fetchOptions(unknownIds, '', 1);
        const options = accumulatedOptions.concat(fetchResult.options);
        const knownIds = options.map(option => option.id);
        const currentUnknownIds = unknownIds.filter(unknownId => knownIds.indexOf(unknownId) < 0);

        return currentUnknownIds.length && fetchResult.pageCount > 1 && currentUnknownIds.join(',') !== unknownIds.join(',')
          ? fetchUnknownIds(options, currentUnknownIds)
          : {
            options,
            pageCount: fetchResult.pageCount,
          };
      };

      const result = await fetchUnknownIds(
        [],
        selection.unknownIds,
      );
      if (cancelled) {
        return;
      }
      const fetchedIds = result.options.map(getOptionId);
      const updatedBadIds = result.pageCount < 2
        ? [
          ...fetchedRef.current.badIds,
          ...selection.unknownIds.filter((id) => fetchedIds.indexOf(id) < 0),
        ]
        : fetchedRef.current.badIds;

      setFetched({
        ...fetchedRef.current,
        specificOptions: result.options,
        badIds: updatedBadIds,
      });
    })();

    return () => {
      cancelled = true;
    };
  }, [fetchOptions, selection, fetchedRef]);

  /**
   * When `query` is changed, fetch the options that match it so that they can be used to populate the
   * dropdown list. Only populates the dropdown whilst it is open.
   */
  useEffect(() => {
    if (
      !open
      || queriesEqual(debouncedQuery, fetchedRef.current.queried.query)
    ) {
      return;
    }

    let cancelled = false;
    setQuerying(true);
    void (async () => {
      const result = await fetchOptions([], debouncedQuery.search, debouncedQuery.pageNum);
      if (cancelled) {
        return;
      }
      const updatedQueriedOptions = debouncedQuery.pageNum > 1
        ? [
          ...fetchedRef.current.queried.options,
          ...result.options,
        ]
        : result.options;

      setLoadMore(false);
      setFetched({
        ...fetchedRef.current,
        queried: {
          options: updatedQueriedOptions,
          query: debouncedQuery,
          pageCount: result.pageCount,
        },
      });
      setQuerying(false);
      if (debouncedQuery.pageNum === 1 && result.pageCount > 1 && listStyle === 'grid') {
        setQuery((query) => (
          query.pageNum === (debouncedQuery.pageNum + 1)
            ? query
            : {
              ...query,
              pageNum: debouncedQuery.pageNum + 1,
            }
        ));
      }
    })();

    return () => {
      cancelled = true;
    };
  }, [fetchOptions, debouncedQuery, fetchedRef, open, listStyle, resetToken]);

  const whenInputChanged = useCallback<NonNullable<MuiAutocompleteProps<T, boolean, boolean, undefined>['onInputChange']>>(
    (event, value, reason): void => {
      const eventType = event?.nativeEvent.type;
      const newQuery = {
        ...query,
        pageNum: 1,
        search: value,
      };
      const multiSelectReset = reason === 'reset' && eventType === 'focusout' && multiple;
      const inputChanged = reason === 'input' || reason === 'clear';
      const updateQuery = !queriesEqual(query, newQuery) && (inputChanged || multiSelectReset);
      const updateInputText = updateQuery
        || (
          reason === 'reset'
          && !multiple
        );
      const clearPreviousResults = updateQuery
        && value.indexOf(query.search) !== 0;

      if (updateInputText) {
        setInputText(value);
      }
      if (clearPreviousResults) {
        setFetched({
          ...fetched,
          queried: {
            options: [],
            query: {
              search: '',
              pageNum: -1,
            },
            pageCount: 0,
          },
        });
      }
      if (updateQuery) {
        setQuery(newQuery);
      }
    },
    [
      query,
      fetched,
      multiple,
      setInputText,
      setQuery,
    ]
  );

  const whenSelectionChanged = useCallback<NonNullable<MuiAutocompleteProps<T, boolean, boolean, undefined>['onChange']>>(
    (_event, value) => {
      const valueArray = value
        ? Array.isArray(value) ? value : [value]
        : [];

      const readOnlyOptions = selection.options.filter(option => !!readOnlyIds?.includes(option.id));
      const selectedOptions = valueArray.filter(option => !readOnlyIds?.includes(option.id))
        .concat(readOnlyOptions);

      onSelectionChanged({
        ...selection,
        options: selectedOptions,
      });
    },
    [selection, readOnlyIds, onSelectionChanged],
  );

  useEffect(() => {
    if (loadMore && !querying && fetched.queried.query.pageNum < fetched.queried.pageCount) {
      setQuery((query) => (
        query.pageNum === Math.max(1, (fetched.queried.query.pageNum + 1))
          ? query
          : {
            ...query,
            pageNum: Math.max(1, (fetched.queried.query.pageNum + 1)),
          }
      ));
    }
  }, [fetched, querying, loadMore]);

  const whenOptionsScrolled = useCallback((event: SyntheticEvent) => {
    const listboxNode = event.currentTarget;
    if ((listboxNode.scrollTop + listboxNode.clientHeight) >= (SCROLL_LOAD_THRESHOLD * listboxNode.scrollHeight)) {
      setLoadMore(true);
    }
  }, []);

  const PopperComponent = useCallback<NonNullable<MuiAutocompleteProps<T, boolean, boolean, undefined>['PopperComponent']>>(
    props => getCustomPopper(props, useNewTheme),
    [useNewTheme]
  );

  const ListboxComponent = useCallback<NonNullable<MuiAutocompleteProps<T, boolean, boolean, undefined>['ListboxComponent']>>(
    props => (
      <StyledListbox
        role="listbox"
        listStyle={ listStyle }
        onScroll={ whenOptionsScrolled }
        queryingText={ loadingText || intl.formatMessage({
          id: 'autocomplete.loadingText',
          defaultMessage: 'Loading...',
        }) }
        { ...props }
      />
    ),
  [whenOptionsScrolled, listStyle, intl, loadingText],
  );

  const renderInput = useCallback<MuiAutocompleteProps<T, boolean, boolean, undefined>['renderInput']>(
    params => {
      const InputComponent = noMargin || useNewTheme ? LabelledField : TextField;
      return (
        <InputComponent
          autoFocus={ autoFocus }
          { ...params }
          InputProps={ {
            ...params.InputProps,
            ...(inputAdornment ? { inputAdornment } : {}),
            ...(id ? { id } : {}),
          } }
          { ...inputAdornment
            ? {
              startAdornment: {
                type: 'node',
                node: (
                  <>
                    { inputAdornment }
                    { params.InputProps.startAdornment }
                  </>
                ),
              }
            }
            : {}
          }
          label={ String(label) }
          margin={ dense || noMargin ? 'dense' : 'normal' }
          placeholder={ readonly ? '' : placeholder }
          error={ error }
          variant={ variant }
          { ...noMargin ? {} : { label: readonly ? '' : label } }
        />
      );
    },
    [
      autoFocus,
      inputAdornment,
      id,
      label,
      dense,
      noMargin,
      placeholder,
      readonly,
      error,
      variant,
      useNewTheme,
    ]
  );

  const renderOption = useCallback<NonNullable<MuiAutocompleteProps<T, boolean, boolean, undefined>['renderOption']>>(
    (props, option, { selected }) => (
      useNewTheme
        ? (
          <li
            { ...props }
            key={ props.id }
          >
            <DropdownItem
              text={ getOptionLabel(option) }
              { ...selected ? { endAdornment: <DropdownItemCheck/> } : {} }
              selected={ selected }
              disabled={ !!readOnlyIds?.includes(option.id) }
              { ...getOptionStartAdornment ? { startAdornment: getOptionStartAdornment(option, 'menu') } : {} }
            />
          </li>
        )
        : (
          <li
            { ...props }
            key={ props.id }
          >
            <div>
              <Stack css={ { width: '100%' } } noWrap gap={ 2 }>
                { showCheckboxes && (
                  <Checkbox
                    disabled={ !!readOnlyIds?.includes(option.id) }
                    checked={ selected }
                  />
                ) }
                <div css={ { whiteSpace: 'break-spaces' } }>
                  { getOptionLabel(option) }
                </div>
              </Stack>
            </div>
          </li>

        )
    ),
    [
      getOptionLabel,
      showCheckboxes,
      readOnlyIds,
      useNewTheme,
      getOptionStartAdornment,
    ],
  );

  const renderTags = useCallback<NonNullable<MuiAutocompleteProps<T, boolean, boolean, undefined>['renderTags']>>(
    (tags, getTagProps) => (
      showChips && tags.map((tag, index) => {
        const chipProps = getTagProps({ index }) as ChipProps;
        const chipIsReadOnly = !!readOnlyIds?.includes(tag.id) || readonly || !!disabled;
        const chipIsLocked = !!readOnlyIds?.includes(tag.id);
        const onRemoveClicked = () => chipProps.onDelete && chipProps.onDelete(null);
        const chipState = chipIsLocked ? ChipState.LOCKED : chipIsReadOnly ? ChipState.READ_ONLY : ChipState.EDITABLE;

        return (
          useNewTheme
            ? (
              <Chip
                { ...getOptionStartAdornment ? { startAdornment: getOptionStartAdornment(tag, 'chip') } : {} }
                {
                  ...chipState === ChipState.EDITABLE
                    ? {
                      state: chipState,
                      onRemoveClicked,
                    }
                    : { state: chipState }
                }
                label={ getOptionLabel(tag) }
              />
            )
            : (
              <StyledChip
                { ...chipProps }
                { ...(readonly || !!readOnlyIds?.includes(tag.id) ? { onDelete: undefined, disabled: false } : {}) }
                readOnly={ !!readOnlyIds?.includes(tag.id) }
                clickable={ false }
                variant="outlined"
                color="primary"
                deleteIcon={ (
                  <StyledRemoveButton role="button" type="button">
                    <CloseIcon/>
                  </StyledRemoveButton>
                ) }
                label={ getOptionLabel(tag) }
              />
            )
        )
      })
    ),
    [
      showChips,
      readOnlyIds,
      readonly,
      disabled,
      useNewTheme,
      getOptionStartAdornment,
      getOptionLabel,
    ],
  );

  const onOpen = useCallback<NonNullable<MuiAutocompleteProps<T, boolean, boolean, undefined>['onOpen']>>(
    () => setOpen(true),
    []
  );

  const onClose = useCallback<NonNullable<MuiAutocompleteProps<T, boolean, boolean, undefined>['onClose']>>(
    () => setOpen(false),
    []
  );

  const getOptionKey = useCallback<NonNullable<MuiAutocompleteProps<T, boolean, boolean, undefined>['getOptionKey']>>(
    option => option.id,
    []
  );

  const isOptionEqualToValue = useCallback<NonNullable<MuiAutocompleteProps<T, boolean, boolean, undefined>['isOptionEqualToValue']>>(
    (option, value) => option.id === value.id,
    []
  );

  return (
    <StyledAutocomplete
      id={ id }
      disabled={ disabled || readonly }
      multiple={ multiple }
      disableCloseOnSelect={ disableCloseOnSelect }
      disableClearable={ disableClearable || readonly || !!readOnlyIds?.length }
      openOnFocus={ autoFocus }
      onBlur={ onBlur }
      onFocus={ onFocus }
      clearOnBlur={ clearOnBlur }
      blurOnSelect={ false }
      autoHighlight={ true }
      open={ open }
      onOpen={ onOpen }
      onClose={ onClose }
      inputValue={ inputText }
      loading={
        querying
        && fetched.queried.options.length < 1
      }
      loadingText={ loadingText || intl.formatMessage({
        id: 'autocomplete.loadingText',
        defaultMessage: 'Loading...',
      }) }
      noOptionsText={ noOptionsText || intl.formatMessage({
        id: 'autocomplete.noOptionsText',
        defaultMessage: 'No matches',
      }) }
      options={ fetched.queried.options }
      value={ multiple ? selection.options : (selection.options[0] || null) }
      onInputChange={ whenInputChanged }
      onChange={ whenSelectionChanged }
      PopperComponent={ PopperComponent }
      ListboxComponent={ ListboxComponent }
      getOptionKey={ getOptionKey }
      getOptionLabel={ getOptionLabel }
      isOptionEqualToValue={ isOptionEqualToValue }
      renderOption={ renderOption }
      renderTags={ renderTags }
      renderInput={ renderInput }
      className={ className }
      fullWidth={ fullWidth }
      applyDisabledStyling={ disabled }
    />
  );
};

const getOptionId = (option: Option): string => option.id;

const idsEqual = (a: string[], b: string[]): boolean => {
  return [...a].sort().join(',') === [...b].sort().join(',');
};

const queriesEqual = (a: AutocompleteQuery, b: AutocompleteQuery): boolean => {
  return JSON.stringify(a) === JSON.stringify(b);
};

const selectionsEqual = <T extends Option>(a: AutocompleteSelection<T>, b: AutocompleteSelection<T>): boolean => {
  return idsEqual(a.unknownIds, b.unknownIds)
    && idsEqual(a.badIds, b.badIds)
    && idsEqual(
      a.options.map(getOptionId),
      b.options.map(getOptionId),
    );
};
