import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';

import { LinearCellGraph } from './LinearCellGraph';
interface Props {
  percentage: number;
}

export const LinearCellPercentageGraph: FunctionComponent<Props> = ({
  percentage
}): JSX.Element => {
  const intl = useIntl();
  return (
    <LinearCellGraph
      label={
        intl.formatNumber(
          percentage,
          {
            style: 'percent',
          }
        )
      }
      percentage={percentage}
      />
  );
};
