import React, { FunctionComponent, ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { DeprecatedLink } from '../../../Common/Component';
import { MissingDataMessage } from '..';
import { BroadcastsPermission } from '../../../Broadcasts/Model';
import { GuardedLink } from '../../../Security/Component';

export const MissingBroadcastMetricMessage: FunctionComponent = () => {
  const intl = useIntl();

  return (
    <MissingDataMessage>
      <FormattedMessage
        id="dashboard.broadcastMetrics.noData"
        defaultMessage="<p>Keep your team in the know and <BroadcastsLink>send some broadcasts</BroadcastsLink>!</p><p>Learn more about broadcasts from our <HelpdeskLink>useful primer</HelpdeskLink>.</p>"
        values={ {
          BroadcastsLink: (chunks: ReactNode) => (
            <GuardedLink
              permissions={[BroadcastsPermission.CREATE]}
              to="/broadcasts/create"
            >
              { chunks }
            </GuardedLink>
          ),
          HelpdeskLink: (chunks: ReactNode) => (
            <DeprecatedLink to={ intl.formatMessage({
              id: 'dashboard.broadcastMetrics.helpDeskLink',
              defaultMessage: 'https://get.ourpeople.help/hc/articles/360013537674-Broadcast-Overview',
            }) }>
              { chunks }
            </DeprecatedLink>
          )
        } }
      />
    </MissingDataMessage>
  );
};
