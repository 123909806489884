import { ComponentProps, FC, useState } from 'react';
import { Button } from 'op-storybook/stories/components/Button/Button';

import { Template } from '../../../Model';
import { TemplateTile, VerticallySpaced } from '../..';
import { StyledTemplates } from './style';

type Props = {
  templates: Template[];
  getUseButtonProps: (templateId: string) => ComponentProps<typeof Button>;
  onPreviewClick: (templateId: string) => void;
}

export const TemplateGallery: FC<Props> = ({
  templates,
  getUseButtonProps,
  onPreviewClick,
}) => {
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>();

  return (
    <VerticallySpaced gap={ 2 }>
      <StyledTemplates>
        { templates.map(template => (
          <TemplateTile
            key={ template.id }
            template={ template }
            selected={ selectedTemplateId === template.id }
            onSelect={ () => setSelectedTemplateId(template.id) }
            onPreviewClick={ () => onPreviewClick(template.id) }
            useButtonProps={ getUseButtonProps(template.id) }
          />
        )) }
      </StyledTemplates>
    </VerticallySpaced>
  );
};
