import { ReactNode, useCallback } from 'react';

import { FormContent, SingleContentSubmission } from '../Model';
import { SubmissionTableRow } from '../Component';
import { SingleContentCard } from '../../Content/Model';

export const useSubmissionTableRowRenderFunction = (
  statusTrackerEnabled: boolean,
  cards: SingleContentCard<FormContent>[],
  onActionComplete: () => void,
  disableActions?: boolean,
  hideActions?: boolean,
): (submission: SingleContentSubmission) => ReactNode => (
  useCallback((submission: SingleContentSubmission) => (
    <SubmissionTableRow
      key={ submission.formSubmission.id }
      statusTrackerEnabled={ statusTrackerEnabled }
      cards={ cards }
      submission={ submission }
      onActionComplete={ onActionComplete }
      disableActions={ disableActions }
      hideActions={ hideActions }
    />
  ), [cards, disableActions, hideActions, onActionComplete, statusTrackerEnabled])
);
