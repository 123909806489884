import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { FormatNumberOptions } from '@formatjs/intl/src/types';

import { DeliveryReportTabLabelRow, StyledDeliveryReportTab, StyledInvalidNumber, StyledTooltip } from '../styles';
import { AnimatedNumber } from '../../../../../Common/Component';

interface Props {
  label: string | JSX.Element;
  value: number;
  active?: boolean;
  valueFormattingOptions?: FormatNumberOptions;
  tooltip?: string;
  lowLight?: boolean;
  onClick?: () => void;
  zeroValue?: string;
}

export const DeliveryReportTab: FunctionComponent<Props> = ({
  label,
  value,
  active = false,
  valueFormattingOptions,
  tooltip,
  lowLight,
  onClick,
  zeroValue,
}) => {
  return (
    <StyledDeliveryReportTab
      active={active}
      lowLight={lowLight}
      onClick={onClick}
    >
      <DeliveryReportTabLabelRow>
        <span>{ label }</span>
        {
          tooltip && (
            <StyledTooltip
              title={ tooltip }
            />
          )
        }
      </DeliveryReportTabLabelRow>
      {
        isNaN(value)
        ? (
          <StyledInvalidNumber>
            <FormattedMessage
              id="broadcastReport.stat.notApplicable"
              defaultMessage="N/A"
              />
          </StyledInvalidNumber>
        )
        : (
          value === 0 && zeroValue !== undefined
            ? zeroValue
            : (
              <AnimatedNumber
                targetValue={value}
                formattingOptions={valueFormattingOptions}
              />
            )
        )
      }
    </StyledDeliveryReportTab>
  )
}
