import styled from '@emotion/styled';
import {
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableProps,
  TableRow,
  TableRowProps
} from '@mui/material';

export const StyledTableBody = styled(TableBody)`
  background-color: ${ props => props.theme.palette.background.paper };
`;

export interface StyledTableCellProps extends TableCellProps {
  minwidth?: string;
  maxwidth?: string;
  width?: string;
  verticalAlign?: 'top' | 'middle' | 'bottom';
  ellipsisOverflow?: boolean;
}

export const StyledTableCell = styled(
  ({width, ellipsisOverflow, verticalAlign, ...props}: StyledTableCellProps) => (
    <TableCell {...props} />
  )
)`
  padding: 0.5rem;
  ${props => props.minwidth && `min-width: ${props.minwidth};`}
  ${props => props.maxwidth && `max-width: ${props.maxwidth};`}
  ${props => props.width && `width: ${props.width};`}
  ${props => props.ellipsisOverflow && `
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `}
  ${ props => props.verticalAlign && `vertical-align: ${ props.verticalAlign };` }
  a {
    color: ${props => props.theme.palette.secondary.main};
  }
`;

export const StyledTableContainer = styled(TableContainer)``;

export const StyledTableHead = styled(TableHead)`
  background-color: ${props => props.theme.palette.grey[100]};

  tr {
    background-color: inherit;
  }

  ${StyledTableCell} {
    white-space: nowrap;
  }
`;

export interface StyledTableRowProps extends TableRowProps {
  disabled?: boolean;
}

export const StyledTableRow = styled(
  ({disabled = false, ...props}: StyledTableRowProps) => <TableRow {...props} />
)`
  background-color: inherit;
  ${props => props.disabled && `background-color: ${props.theme.palette.grey[300]}; opacity: 0.5;`}
`;

export type StyledTableProps = TableProps & {
  rowLines?: boolean,
};

export const StyledTable = styled(
  (
    {
      rowLines = true,
      ...props
    }: StyledTableProps
  ) => <Table {...props} />
)`
  ${ StyledTableCell } {
    ${ props => props.rowLines === false
      ? 'border-bottom: none;'
      : 'border-bottom: 1px solid #dddddd;'
    }
  }
`;
