import { FC } from 'react';
import { IntlShape } from 'react-intl/src/types';

import { MappedSettingTypes } from '../../../Types';
import { ImageUploadField } from '../../../../../../Common/Component/ImageUploadField/ImageUploadField';

type Props = {
  config: MappedSettingTypes['image']['config'],
  value: MappedSettingTypes['image']['value'],
  onValueChanged: (value: MappedSettingTypes['image']['value']) => void,
  label?: string,
  focusId: string,
};

export const ImageSettingInput: FC<Props> = ({
  config,
  value,
  onValueChanged,
  focusId,
}) => (
  <ImageUploadField
    previewSize="md"
    imageKey={ focusId }
    upload={ value }
    onComplete={ upload => onValueChanged({
      uuid: upload.uuid,
    }) }
    cropRequired={ true }
    authoriseEndpoint="/settings/authorise-upload"
    mode="fixed-square"
    outputDimensions={ {
      width: config.width,
      height: config.height,
    } }
    {
      ...config.nullable
        ? { onRemoveClicked: () => onValueChanged(null) }
        : {}
    }
  />
);
