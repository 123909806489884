import { FC, ReactNode } from 'react';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { BreakpointContext } from 'op-storybook/lib/providers/BreakpointProvider/BreakpointProvider';

import { PersonAvatar } from '../PersonAvatar/PersonAvatar';
import { MinimalPerson } from '../../../Models';
import { useContextOrThrow } from '../../../Core/Hook';

type Props = {
  person: MinimalPerson;
  text: ReactNode;
  supportingText?: ReactNode;
}

export const PersonAvatarWithText: FC<Props> = ({
  person,
  text,
  supportingText,
}) => {
  const screenWidth = useContextOrThrow(BreakpointContext);

  return (
    <Stack gap={ 4 }>
      { !screenWidth.lessThan.sm && (
        <PersonAvatar
          person={ person }
          size="md"
        />
      ) }
      <Stack
        direction="column"
        gap={ 0 }
      >
        <span>{ text }</span>
        { supportingText }
      </Stack>
    </Stack>
  );
};
