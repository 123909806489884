import {
  FC,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import useWebSocket from 'react-use-websocket';
import { WebSocketHook } from 'react-use-websocket/dist/lib/types';
import { CircularProgress, Slide, Snackbar } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Alert } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

import { SpaceContext, WebsocketContext } from '../Context';
import { Flex } from '../../Common/Component';

const WEBSOCKET_PATH = process.env.OP_WEBSOCKET_PATH || '';

enum ConnectionState {
  CONNECTING,
  CONNECTED,
  RECONNECTING,
}

export type StructuredMessage = {
  type: string;
  payload: Record<string, unknown>;
};

export const WebsocketProvider: FC<PropsWithChildren> = ({ children }) => {
  const spaceContext = useContext(SpaceContext);
  const consoleHost = new URL(spaceContext.space?.console_url || '').host;
  const [socketUrl] = useState(`wss://${ consoleHost }${ WEBSOCKET_PATH }`);
  const [, setMessageHistory] = useState<StructuredMessage[]>([]);
  const {
    readyState,
    lastMessage,
    sendJsonMessage,
    getWebSocket,
  } = useWebSocket(
    socketUrl,
    {
      shouldReconnect: () => true,
    },
  ) as WebSocketHook<MessageEvent<string>>;
  const [connectionState, setConnectionState] = useState<ConnectionState>(ConnectionState.CONNECTING);
  const serverPingedAt = useRef<number>();
  const [reconnectSnackbarOpen, setReconnectSnackbarOpen] = useState<boolean>(false);
  const [reconnectSnackbarMounted, setReconnectSnackbarMounted] = useState<boolean>(false);

  useEffect(() => {
    if (connectionState === ConnectionState.RECONNECTING) {
      return;
    }

    setReconnectSnackbarOpen(false);
  }, [connectionState]);

  const lastStructuredMessage = useMemo<StructuredMessage | null>(
    () => lastMessage?.data ? (JSON.parse(lastMessage.data) as StructuredMessage) : null,
    [lastMessage],
  );

  const sendMessage = useCallback((message: StructuredMessage, onError?: (error: unknown) => void) => {
    if (connectionState !== ConnectionState.CONNECTED) {
      onError && onError(new Error('No connection.'));
      return;
    }

    sendJsonMessage(message);
  }, [connectionState, sendJsonMessage]);

  useEffect(() => {
    if (lastStructuredMessage?.type !== 'pong' || !serverPingedAt.current) {
      return;
    }

    serverPingedAt.current = undefined;
  }, [lastStructuredMessage]);

  useEffect(() => {
    if (connectionState !== ConnectionState.CONNECTED) {
      return;
    }

    const closeConnection = () => {
      serverPingedAt.current = undefined;
      getWebSocket()?.close(3000, 'No response from server received within specified window.');
      setConnectionState(ConnectionState.RECONNECTING);
      setReconnectSnackbarOpen(true);
      setReconnectSnackbarMounted(true);
    };

    const intervalId = setInterval(() => {
      if (serverPingedAt.current) {
        const elapsed = Date.now() - serverPingedAt.current;
        if (elapsed >= PONG_TIMEOUT) {
          closeConnection();
        }
      } else {
        serverPingedAt.current = Date.now();
        sendMessage(
          {
            type: 'ping',
            payload: {},
          },
          closeConnection,
        );
      }
    }, PING_INTERVAL);

    return () => {
      serverPingedAt.current = undefined
      clearInterval(intervalId);
    };
  }, [connectionState, getWebSocket, sendMessage]);

  useEffect(() => {
    if (!lastStructuredMessage || lastStructuredMessage.type !== 'connected') {
      return;
    }

    setConnectionState(ConnectionState.CONNECTED);
  }, [lastStructuredMessage]);

  useEffect(() => {
    if (!lastStructuredMessage) {
      return;
    }

    setMessageHistory(prev => prev.concat(lastStructuredMessage));
  }, [lastStructuredMessage]);

  return (
    <WebsocketContext.Provider
      value={ {
        readyState,
        lastMessage: lastStructuredMessage,
        sendMessage,
      } }
    >
      { reconnectSnackbarMounted && (
        <Snackbar
          anchorOrigin={ { vertical: 'bottom', horizontal: 'center' } }
          open={ reconnectSnackbarOpen }
          TransitionComponent={ TransitionComponent }
          TransitionProps={ {
            onExited: () => {
              setReconnectSnackbarMounted(false);
            },
          } }
        >
          <Alert
            variant="filled"
            severity="info"
            icon={ false }
          >
            <Flex gap={ 1.5 }>
              <CircularProgress
                variant="indeterminate"
                color="inherit"
                size={ 20 }
              />
              <FormattedMessage
                id="websocketProvider.reconnect"
                description="Message displayed when connection to websocket server is lost."
                defaultMessage="Connection lost. Reconnecting…"
              />
            </Flex>
          </Alert>
        </Snackbar>
      ) }
      { children }
    </WebsocketContext.Provider>
  );
};

const TransitionComponent = (props: TransitionProps & { children: ReactElement }) => (
  <Slide direction="up" { ...props } children={ props.children }/>
);

const PING_INTERVAL = 3000;
const PONG_TIMEOUT = 1000;
