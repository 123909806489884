import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { Badge } from '../MaterialBadge/Badge';

type Props = {
  value: number;
  max?: number;
  inverted?: boolean;
  className?: string;
};

export const NumericBadge: FC<Props> = ({
  value,
  max = 999,
  inverted = false,
  className,
}) => (
  <Badge
    className={ className }
    inverted={ inverted }
  >
    {
      value <= max
        ? value
        : (
          <FormattedMessage
            description="Number displayed in badge when above maximum value."
            defaultMessage="{ max }+"
            values={ {
              max,
            } }
          />
        )
    }
  </Badge>
);
