import { FunctionComponent, ReactNode, useMemo } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { BreakpointContext } from 'op-storybook/lib/providers/BreakpointProvider/BreakpointProvider';

import {
  StyledActionsContainer,
  StyledContainer,
  StyledStepContainer,
  StyledStepperBox,
  StyledStepperContainer,
  StyledStepperHeader,
  StyledTitleContainer
} from './style';
import { DesktopStepper, MobileStepper, StepperItem } from '..';
import { Flex, FlexPullRight } from '../..';
import { useContextOrThrow } from '../../../../Core/Hook';

type Props = {
  title?: ReactNode;
  preStep?: ReactNode;
  steps: StepperItem[];
  actions?: ReactNode;
  displayBottomNav?: boolean;
}

export const StepperHeader: FunctionComponent<Props> = ({
  title,
  preStep,
  steps,
  actions,
  displayBottomNav = false,
}) => {
  const { url } = useRouteMatch();
  const screenWidth = useContextOrThrow(BreakpointContext);

  const stepper = useMemo(() => (
    <StyledStepperContainer>
      {
        screenWidth.lessThan.xs
          ? <MobileStepper steps={ steps }/>
          : <DesktopStepper steps={ steps }/>
      }
    </StyledStepperContainer>
  ), [screenWidth.lessThan.xs, steps]);

  return (
    <StyledContainer>
      <StyledStepperHeader>
        { ((screenWidth.lessThan.xs && title) || (!screenWidth.lessThan.xs && screenWidth.lessThan.md && (title || actions)) || !screenWidth.lessThan.md) && (
          <StyledStepperBox>
            <StyledTitleContainer>
              { title }
            </StyledTitleContainer>
            { !screenWidth.lessThan.md && stepper }
            <FlexPullRight gap={ 2 }>
              { !screenWidth.lessThan.xs && actions }
            </FlexPullRight>
          </StyledStepperBox>
        ) }
        { screenWidth.lessThan.md && stepper }
      </StyledStepperHeader>
      { preStep }
      <StyledStepContainer>
        <Switch>
          { steps.map(step => (
            <Route
              key={ step.link }
              path={ step.link }
              render={
                step.condition === undefined || step.condition
                  ? step.render
                  : () => <Redirect to={ step.redirect || url }/>
              }
              exact
            />
          )) }
        </Switch>
      </StyledStepContainer>
      { (displayBottomNav || screenWidth.lessThan.xs) && actions && (
        <StyledActionsContainer margin={ false }>
          <Flex>
            <FlexPullRight gap={ 2 }>
              { actions }
            </FlexPullRight>
          </Flex>
        </StyledActionsContainer>
      ) }
    </StyledContainer>
  );
};
