import { FC, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { ContentPreviewProps } from '../../../../Content/Model';
import { QuizEditorContent } from '../../../Service';
import { CardTitle, ContentWithType, ScrollableContent } from '..';
import { FlexPullRight, VerticallySpaced } from '../../../../Common/Component';
import { StyledChoiceRequestOption, StyledChoiceRequestOptions } from '../ChoiceRequestPreview/style';
import { ArrayHelper } from '../../../../Common/Utility';
import CorrectIcon from '../../../../Assets/img/icons/streamline/check-1.svg';
import { StyledQuizRequestBackground, StyledCorrectCheckbox, StyledIncorrectCheckbox, StyledCrossIcon} from './style';

export enum QuizCardState {
  UNSELECTED,
  CORRECT_SELECTED,
  INCORRECT_SELECTED,
}

export const QuizRequestPreview: FC<ContentPreviewProps<QuizEditorContent>> = ({
  editorContent,
  onReady,
}) => {
  const intl = useIntl();
  const [cardState, setCardState] = useState(QuizCardState.UNSELECTED);
  const shuffledIndices = useMemo(
    () => (
      ArrayHelper.shuffle(Array.from(
        { length: editorContent.card.content.options.length },
        (_value, index) => index,
      ))
    ),
    [editorContent.card.content.options.length],
  );
  const shuffledOptions = useMemo(
    () => shuffledIndices.map(index => editorContent.card.content.options[index]),
    [editorContent.card.content.options, shuffledIndices],
  );

  useEffect(() => {
    onReady();
  }, [onReady]);

  return (
    <ScrollableContent>
      <StyledQuizRequestBackground state={cardState}>
        <ContentWithType
          localisedContentType={ intl.formatMessage({
            id: 'broadcasts.content.preview.quiz',
            description: 'Label for quiz request type used in preview.',
            defaultMessage: 'Quiz',
          }) }
        >
          <VerticallySpaced gap={ 2 }>
            <CardTitle>{ editorContent.card.content.title }</CardTitle>
            <StyledChoiceRequestOptions>
              { shuffledOptions.map(option => (
                <StyledChoiceRequestOption
                  key={ option.id }
                >
                  <label htmlFor={ option.id }>
                    { option.text }
                    <FlexPullRight>
                      {
                        option.type === 'correctchoiceoption'
                          ? (
                            <StyledCorrectCheckbox
                              $revealed={ cardState > QuizCardState.UNSELECTED }
                            >
                              <CorrectIcon/>
                            </StyledCorrectCheckbox>
                          )
                          : (
                            <StyledIncorrectCheckbox
                              $revealed={ cardState > QuizCardState.UNSELECTED }
                            >
                              <StyledCrossIcon/>
                            </StyledIncorrectCheckbox>
                          )
                      }

                    </FlexPullRight>
                    <input
                      id={ option.id }
                      type="radio"
                      checked={ cardState > QuizCardState.UNSELECTED }
                      onChange={ () => setCardState(option.type === 'correctchoiceoption' ? QuizCardState.CORRECT_SELECTED : QuizCardState.INCORRECT_SELECTED) }
                    />
                  </label>
                </StyledChoiceRequestOption>
              )) }
            </StyledChoiceRequestOptions>
          </VerticallySpaced>
        </ContentWithType>
      </StyledQuizRequestBackground>
    </ScrollableContent>
  );
};
