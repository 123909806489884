import { FC, useCallback, useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Collapse } from '@mui/material';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { PhoneNumberValidator } from '@ourpeople/shared/Account/Utility/PhoneNumberValidator';
import { ContactMethodValidator } from '@ourpeople/shared/Account/Utility/ContactMethodValidator';
import { EmailValidator } from '@ourpeople/shared/Account/Utility/EmailValidator';
import { LastNameValidator } from '@ourpeople/shared/Account/Utility/LastNameValidator';
import { FirstNameValidator } from '@ourpeople/shared/Account/Utility/FirstNameValidator';
import { ApiRequest } from '@ourpeople/shared/Core/Model/ApiRequest';
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';
import { Link } from 'react-router-dom';

import { RegistrationTemplate } from '../../Component/RegistrationTemplate/RegistrationTemplate';
import { AnonymousSettings } from '../../Hook';
import { StyledButton, StyledContentContainer, StyledLoginLink } from './style';
import { RegistrationForm, RegistrationFormState } from '../../Component/RegistrationForm/RegistrationForm';
import { useContextOrThrow } from '../../../Core/Hook';
import { ValidationError, ValidationTree } from '../../../Common/Model';
import { RequestState } from '../../../Models/RequestState';
import { useMounted } from '../../../Common/Hook/useMounted';
import { ErrorResponseReader } from '../../../Common/Utility/ErrorResponseReader';
import { RegistrationConfirmation } from '../../Component/RegistrationConfirmation/RegistrationConfirmation';
import { ToastContext } from '../../../Core/Context';
import { useQueryAsState } from '../../../Hooks';
import { ApiContext } from '../../../Contexts';

type Props = {
  anonymousSettings: AnonymousSettings;
};

export const RegistrationPage: FC<Props> = ({ anonymousSettings }) => {
  const api = useContext(ApiContext);
  const [saveRegistrationState, setSaveRegistrationState] = useState<ApiRequest<{ id: string }>>({
    state: RequestState.PENDING,
    result: null,
  });
  const intl = useIntl();
  const { addErrorToast } = useContextOrThrow(ToastContext);
  const mounted = useMounted();
  const [{ code }] = useQueryAsState<{ code?: string }>();
  const [formState, setFormState] = useState<RegistrationFormState>({
    firstName: {
      value: '',
      validation: {
        errors: [],
        children: {},
      },
    },
    lastName: {
      value: null,
      validation: {
        errors: [],
        children: {},
      },
    },
    email: {
      value: '',
      validation: {
        errors: [],
        children: {},
      },
    },
    phone: {
      value: {
        phoneNumber: '',
        countryCode: anonymousSettings.defaultCountryCode,
      },
      validation: {
        errors: [],
        children: {},
      },
    },
  });
  const [started, setStarted] = useState<boolean>(false);

  const validate = useCallback(() => {
    const firstNameErrors = FirstNameValidator.validate(formState.firstName.value);
    const lastNameErrors = formState.lastName.value !== null ? LastNameValidator.validate(formState.lastName.value) : [];
    const communicationMethodErrors = ContactMethodValidator.validate([
      formState.phone.value.phoneNumber,
      formState.email.value,
    ]);
    const emailErrors = EmailValidator.validate(formState.email.value).concat(communicationMethodErrors);
    const phoneErrors = PhoneNumberValidator.validate(formState.phone.value).concat(communicationMethodErrors);
    const invalid = !![firstNameErrors, lastNameErrors, emailErrors, phoneErrors]
      .find(errors => !!errors.length);

    if (invalid) {
      setFormState(
        formState => (
          validationToFormState(
            fieldErrorsToValidationTree(
              firstNameErrors,
              lastNameErrors,
              emailErrors,
              phoneErrors,
            ),
            formState,
          )
        ),
      );
    }

    return invalid;
  }, [formState]);

  const submit = useCallback(() => {
    if (saveRegistrationState.state === RequestState.FETCHING || !api) {
      return;
    }

    const saveRegistrationData: SaveRegistrationData = {
      firstName: formState.firstName.value,
      lastName: formState.lastName.value,
      ...(formState.email.value ? { emailAddresses: [formState.email.value] } : {}),
      ...(formState.phone.value.phoneNumber ? {
          phoneNumber: parsePhoneNumber(
            formState.phone.value.phoneNumber,
            formState.phone.value.countryCode as CountryCode,
          ).format('E.164')
        } : {}),
      ...(code ? { registrationCode: code } : {}),
    };

    setSaveRegistrationState({
      result: null,
      state: RequestState.FETCHING,
    });

    api.post<{ id: string }>('/anon/register', saveRegistrationData)
      .then(response => response.data)
      .then(minimalRegistration => {
        if (!mounted.current) {
          return;
        }

        setSaveRegistrationState({
          state: RequestState.COMPLETE,
          result: minimalRegistration,
        });
      })
      .catch((error: unknown) => {
        if (!mounted.current) {
          return;
        }

        setSaveRegistrationState({
          state: RequestState.FAILED,
          result: error,
        });

        if (!ErrorResponseReader.isApiError(error)) {
          return;
        }

        if (ErrorResponseReader.isValidationErrorResponse(error.response)) {
          setFormState(validationToFormState(
            error.response.data.error.data.root,
            formState,
          ));
        }

        addErrorToast(intl.formatMessage({
          description: 'Error toast message when registration fails for unknown reason',
          defaultMessage: 'Registration failed',
        }));
      });
  }, [addErrorToast, api, code, formState, intl, mounted, saveRegistrationState.state]);

  const validateAndSubmit = useCallback(() => {
    setFormState(validationToFormState({
      errors: [],
      children: {}
    }, formState));

    const invalid = validate();

    if (invalid) {
      return;
    }

    submit();
  }, [formState, submit, validate]);

  return (
    saveRegistrationState.state === RequestState.COMPLETE
      ? (
        <RegistrationConfirmation
          emailAddress={ formState.email.value }
          phoneNumber={ formState.phone.value.phoneNumber }
          anonymousSettings={ anonymousSettings }
          pending={ anonymousSettings.userSelfRegistration?.length && anonymousSettings.userSelfRegistration[0] === 'requireApproval' }
        />
      )
      : (
        <RegistrationTemplate
          anonymousSettings={ anonymousSettings }
          title={ intl.formatMessage({
            description: 'Heading for registration page',
            defaultMessage: '{ spaceName } registration',
          }, {
            spaceName: anonymousSettings.name,
          }) }
        >
          <StyledContentContainer>
            <Collapse
              mountOnEnter={ true }
              in={ started }
              collapsedSize={ 125 }
            >
              <RegistrationForm
                formState={ formState }
                onChange={ setFormState }
                onSubmit={ validateAndSubmit }
                busy={ saveRegistrationState.state === RequestState.FETCHING }
              />
            </Collapse>
            <Stack
              { ...started ? { style: { display: 'none' } } : {} }
              direction="column"
              gap={ 4 }
            >
              <span>
                <FormattedMessage
                  description="Heading for registration page"
                  defaultMessage="Sign up and register with { spaceName } to get access to OurPeople, where everything is in one place and everyone is connected."
                  values={ {
                    spaceName: anonymousSettings.name,
                  } }
                />
              </span>
              <StyledButton
                variant="primary"
                onClick={ () => setStarted(true) }
              >
                <FormattedMessage
                  description="CTA on registration page"
                  defaultMessage="Get started"
                />
              </StyledButton>
            </Stack>
          </StyledContentContainer>
          <StyledLoginLink>
            <FormattedMessage
              description="Link to login page from registration pages"
              defaultMessage="Already have an account? <link>Log in</link>"
              values={ {
                link: chunks => <Link to="/login">{ chunks }</Link>
              } }
            />
          </StyledLoginLink>
        </RegistrationTemplate>
      )
  );
};

type SaveRegistrationData = {
  firstName: string;
  lastName?: string | null;
  phoneNumber?: string;
  emailAddresses?: string[];
  registrationCode?: string;
};

const validationToFormState = (validation: ValidationTree<SaveRegistrationData>, currentState: RegistrationFormState): RegistrationFormState => ({
  firstName: {
    ...currentState.firstName,
    validation: validation.children.firstName,
  },
  lastName: {
    ...currentState.lastName,
    validation: validation.children.lastName,
  },
  email: {
    ...currentState.email,
    validation: validation.children.emailAddresses?.children[0],
  },
  phone: {
    ...currentState.phone,
    validation: validation?.children.phoneNumber,
  },
});

const fieldErrorsToValidationTree = (
  firstNameErrors: ValidationError[],
  lastNameErrors: ValidationError[],
  emailErrors: ValidationError[],
  phoneErrors: ValidationError[],
): ValidationTree<SaveRegistrationData> => ({
  errors: [],
  children: {
    firstName: {
      errors: firstNameErrors,
      children: {},
    },
    lastName: {
      errors: lastNameErrors,
      children: {},
    },
    emailAddresses: {
      errors: [],
      children: {
        0: {
          errors: emailErrors,
          children: {},
        },
      },
    },
    phoneNumber: {
      errors: phoneErrors,
      children: {},
    },
  },
});

