import { addHours, roundToNearestMinutes } from 'date-fns';
import RRule, { Frequency } from 'rrule';

import {
  AnnualSchedule,
  DailySchedule,
  MonthlySchedule,
  OneTimeSchedule,
  RecurringSchedule,
  Schedule,
  WeeklySchedule
} from '../Model/BroadcastSchedule';
import { DateHelper } from '../../Common/Utility/DateHelper';
import { BroadcastScheduleIdentifier } from './BroadcastScheduleIdentifier';
import { RRuleParser } from '../../Utility/RRuleParser';

export class ScheduleTransformer {
  public static toDaily = (schedule: Schedule): DailySchedule => {
    if (BroadcastScheduleIdentifier.scheduleIsDaily(schedule)) {
      return schedule;
    }

    const localStartDate = schedule.localStartDate || getOffsetRepetitionDate();

    return ({
      ...schedule,
      recurrence: {
        ...schedule.recurrence,
        repetition: {
          frequency: Frequency.DAILY,
          localDayIndices: [
            BroadcastScheduleIdentifier.scheduleIsWeekly(schedule) || BroadcastScheduleIdentifier.scheduleIsMonthly(schedule)
              ? schedule.recurrence.repetition.localDayIndex
              : localStartDate.getDay()
          ],
          ...getRepetitionTime(schedule),
        },
        end: schedule.recurrence?.end || null,
      },
    });
  };

  public static toWeekly = (schedule: Schedule): WeeklySchedule => {
    if (BroadcastScheduleIdentifier.scheduleIsWeekly(schedule)) {
      return schedule;
    }

    const localStartDate = schedule.localStartDate || getOffsetRepetitionDate();

    return ({
      ...schedule,
      recurrence: {
        ...schedule.recurrence,
        repetition: {
          frequency: Frequency.WEEKLY,
          localDayIndex: BroadcastScheduleIdentifier.scheduleIsMonthly(schedule)
            ? schedule.recurrence.repetition.localDayIndex
            : BroadcastScheduleIdentifier.scheduleIsDaily(schedule)
              ? schedule.recurrence.repetition.localDayIndices[0] || localStartDate.getDay()
              : localStartDate.getDay(),
          ...getRepetitionTime(schedule),
        },
        end: schedule.recurrence?.end || null,
      },
    });
  };

  public static toMonthly = (schedule: Schedule): MonthlySchedule => {
    if (BroadcastScheduleIdentifier.scheduleIsMonthly(schedule)) {
      return schedule;
    }

    const localStartDate = schedule.localStartDate || getOffsetRepetitionDate();

    return ({
      ...schedule,
      recurrence: {
        ...schedule.recurrence,
        repetition: {
          frequency: Frequency.MONTHLY,
          ordinal: DateHelper.getOrdinal(localStartDate),
          localDayIndex: BroadcastScheduleIdentifier.scheduleIsWeekly(schedule)
            ? schedule.recurrence.repetition.localDayIndex
            : BroadcastScheduleIdentifier.scheduleIsDaily(schedule)
              ? schedule.recurrence.repetition.localDayIndices[0] || localStartDate.getDay()
              : localStartDate.getDay(),
          ...getRepetitionTime(schedule),
        },
        end: schedule.recurrence?.end || null,
      },
    });
  };

  public static toAnnual = (schedule: Schedule): AnnualSchedule => {
    if (BroadcastScheduleIdentifier.scheduleIsAnnual(schedule)) {
      return schedule;
    }

    const localStartDate = schedule.localStartDate || getOffsetRepetitionDate();

    return ({
      ...schedule,
      recurrence: {
        ...schedule.recurrence,
        repetition: {
          frequency: Frequency.YEARLY,
          localHour: schedule.recurrence?.repetition.localHour || localStartDate.getHours(),
          minutes: schedule.recurrence?.repetition.minutes || localStartDate.getMinutes(),
          seconds: schedule.recurrence?.repetition.seconds || localStartDate.getSeconds(),
        },
        end: schedule.recurrence?.end || null,
      },
      localStartDate,
    });
  };

  public static toOneTime = (schedule: Schedule): OneTimeSchedule => {
    if (BroadcastScheduleIdentifier.scheduleIsOneTime(schedule) && schedule.localStartDate) {
      return schedule;
    }

    return ({
      ...schedule,
      recurrence: null,
      localStartDate: schedule.localStartDate || getOffsetRepetitionDate(),
    });
  };

  public static toRecurrenceRule = (schedule: RecurringSchedule): string => {
    const dayIndexMap: {[key: number]: number} = {
      0: 6,
      1: 0,
      2: 1,
      3: 2,
      4: 3,
      5: 4,
      6: 5,
    };

    return RRuleParser.withZ(
      RRuleParser.moveForwardToFirstOccurrence(new RRule({
        freq: schedule.recurrence.repetition.frequency,
        dtstart: schedule.localStartDate,
        ...(schedule.recurrence.end ? schedule.recurrence.end.type === 'onDate'
            ? { until: schedule.recurrence.end.localDate }
            : { count: schedule.recurrence.end.count } : {}),
        tzid: Intl.DateTimeFormat().resolvedOptions().timeZone,
        byhour: schedule.recurrence.repetition.localHour,
        byminute: schedule.recurrence.repetition.minutes,
        bysecond: schedule.recurrence.repetition.seconds,
        ...(BroadcastScheduleIdentifier.scheduleIsAnnual(schedule) ? {} : {
            byweekday: BroadcastScheduleIdentifier.scheduleIsDaily(schedule)
              ? schedule.recurrence.repetition.localDayIndices.map(dayIndex => dayIndexMap[dayIndex])
              : dayIndexMap[schedule.recurrence.repetition.localDayIndex]
          }),
        ...(BroadcastScheduleIdentifier.scheduleIsMonthly(schedule) ? { bysetpos: schedule.recurrence.repetition.ordinal } : {}),
      })).toString()
    );
  };
}

const getRepetitionTime = (schedule: Schedule): Pick<NonNullable<Schedule['recurrence']>['repetition'], 'localHour' | 'minutes' | 'seconds'> => {
  const repetitionDate = schedule.recurrence
    ? new Date(
      0,
      1,
      0,
      schedule.recurrence.repetition.localHour,
      schedule.recurrence.repetition.minutes,
      schedule.recurrence.repetition.seconds
    )
    : schedule.localStartDate || getOffsetRepetitionDate();

  return {
    localHour: repetitionDate.getHours(),
    minutes: repetitionDate.getMinutes(),
    seconds: repetitionDate.getSeconds(),
  };
};

const getOffsetRepetitionDate = () => (
  roundToNearestMinutes(
    addHours(new Date(), 1),
    { nearestTo: 5, roundingMethod: 'ceil' },
  )
);
