import { FunctionComponent } from 'react';

import { Box } from '../../../Common/Component';
import { StyledDropzoneContainer } from './style';

export const UploadStep: FunctionComponent = ({ children }) => (
  <Box margin={ false }>
    <StyledDropzoneContainer>
      { children }
    </StyledDropzoneContainer>
  </Box>
);
