import styled from '@emotion/styled';

export const StyledPagingMenuList = styled.ul`
  list-style: none;
  margin: 0;
  padding: ${ props => props.theme.spacing(1) } 0;
`;

export const StyledFooterButton = styled.button`
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  width: 100%;
`;
