import { ComponentProps, FC, useCallback } from 'react';
import { $getRoot, EditorState, LexicalEditor } from 'lexical';
import { $generateHtmlFromNodes } from '@lexical/html';
import { OnChangePlugin as LexicalOnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';

import { RichTextSanitiser } from '../../../../../../src/react/Common/Utility/RichTextSanitiser';
import { RichTextComparer } from '../../../../Utility/RichTextComparer';
import { Editor } from '../Editor/Editor';

type Props = {
  value: string;
  onChange: (value: string) => void;
  emptyValue: string;
  mode?: ComponentProps<typeof Editor>['mode'];
};

export const OnChangePlugin: FC<Props> = ({
  value,
  onChange,
  emptyValue,
  mode,
}) => {
  const whenChanged = useCallback((editorState: EditorState, editor: LexicalEditor) => {
    editorState.read(() => {
      const newValue = mode === 'richText'
        ? RichTextSanitiser.sanitise(
          $generateHtmlFromNodes(editor),
        )
        : $getRoot().getTextContent()

      if (mode === 'richText' ? RichTextComparer.htmlStringsAreEquivalent(newValue, value, emptyValue) : newValue === value) {
        return;
      }

      onChange(newValue === emptyValue ? '' : newValue);
    });
  }, [mode, value, emptyValue, onChange]);

  return (
    <LexicalOnChangePlugin
      onChange={ whenChanged }
      ignoreSelectionChange
    />
  );
};

