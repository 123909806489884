import { FormEventHandler, FunctionComponent, ReactNode, useCallback, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { TransitionProps } from '@mui/material/transitions';

import { useInputChangeEventHandler } from '../../Hook';
import { LoadingButton } from '../LoadingButton/LoadingButton';

type Props = {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  confirmationString?: string;
  renderContent?: (confirmationString: string) => ReactNode;
  busy?: boolean;
  TransitionProps?: TransitionProps;
}

export const DeletePrompt: FunctionComponent<Props> = ({
  open,
  onConfirm,
  onCancel,
  confirmationString,
  renderContent,
  busy = false,
  TransitionProps,
}) => {
  const intl = useIntl();
  const [inputValue, setInputValue] = useState<string>('');
  const whenInputChanged = useInputChangeEventHandler(setInputValue);

  confirmationString = confirmationString || intl.formatMessage({
    id: 'prompt.confirmationString',
    description: 'Default confirmation string for delete prompt',
    defaultMessage: 'permanently delete',
  });

  const deleteDisabled = inputValue !== confirmationString;

  renderContent = renderContent || ((confirmationString: string) => (
    <FormattedMessage
      id="deletePrompt.content.default"
      defaultMessage="Type <strong>{ confirmationString }</strong> to delete."
      values={ {
        confirmationString,
      } }
    />
  ));

  const whenSubmitted: FormEventHandler = useCallback(event => {
    event.preventDefault();
    onConfirm();
  }, [onConfirm]);

  return (
    <Dialog
      open={ open }
      onClose={ onCancel }
      aria-labelledby="prompt-dialog-text"
      TransitionProps={ {
        ...TransitionProps,
        onExited: node => {
          setInputValue('');
          TransitionProps?.onExited && TransitionProps.onExited(node);
        },
      } }
    >
      <DialogTitle>
        <FormattedMessage
          id="deletePrompt.title"
          defaultMessage="Are you sure?"
        />
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="prompt-dialog-text">
          { renderContent(confirmationString) }
        </DialogContentText>
        <form id="confirmation" onSubmit={ whenSubmitted }>
          <TextField
            autoFocus
            value={ inputValue }
            onChange={ whenInputChanged }
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={ onCancel }
          color="primary"
        >
          <FormattedMessage
            id="deletePrompt.cancel.label"
            defaultMessage="Cancel"
          />
        </Button>
        <LoadingButton
          color="primary"
          disableElevation
          disabled={ deleteDisabled }
          variant="contained"
          busy={ busy }
          type="submit"
          form="confirmation"
        >
          <FormattedMessage
            id="deletePrompt.delete.label"
            defaultMessage="Delete"
          />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
