import { FunctionComponent, useState } from 'react';

import { ContentPreviewProps } from '../../../../Content/Model';
import { RatingRequestEditorContent } from '../../../Service';
import { RatingRequest } from '..';

export const RatingRequestPreview: FunctionComponent<ContentPreviewProps<RatingRequestEditorContent>> = ({
  editorContent,
}) => {
  const [value, setValue] = useState<number | null>(null);

  return (
    <RatingRequest
      content={ editorContent.card.content }
      value={ value }
      onChange={ setValue }
    />
  );
};
