import styled from '@emotion/styled';

export const MaintenanceModal = styled.div`
  background: green;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: ${ props => props.theme.palette.grey[200] }
`;
