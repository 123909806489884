import { RelativeDateOffset } from '../Model/DateOffset';

export class DateOffsetProvider {
  public static now = (): RelativeDateOffset => DATE_OFFSET_NOW;
}

const DATE_OFFSET_NOW: RelativeDateOffset = {
  type: 'relative',
  offsetInSeconds: 0,
  round: null,
};
