import { FetchResponse, useGet } from '../../Hooks';

export type CreditSummary = {
   spent: number;
   reserved: number;
   available: number;
   refunded: number;
}

export const useFetchCreditSummary = (): FetchResponse<{ summary: CreditSummary }> => useGet('/sms/credit-summary');
