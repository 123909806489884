import { IntlShape } from 'react-intl';

import {
  AudienceConditionDefinition,
  ConditionContentsSummary,
  StatusCondition,
  StatusConditionContents
} from '../../Model';
import { PersonStatusHelper } from '../../../People/Utility';

/** @deprecated Used in old audience builder */
export class StatusConditionDefinition implements AudienceConditionDefinition<
  StatusCondition,
  StatusConditionContents
> {
  public readonly TYPE = 'status';

  public definesConditionType(conditionType: string): boolean {
    return conditionType === this.TYPE;
  }

  public contentsSummary(
    intl: IntlShape,
    condition: StatusCondition,
    conditionContents: StatusConditionContents,
  ): ConditionContentsSummary {
    const singleSummary = intl.formatMessage(
      {
        id: 'audience.conditionDefinition.status.contentsSummary.single',
        defaultMessage: '{ not, select, true {not } other {} }{status}',
      },
      {
        not: condition.not,
        status: PersonStatusHelper.localiseStatus(intl, conditionContents.statuses[0]),
      }
    );

    const countSummary = intl.formatMessage(
      {
        id: 'audience.conditionDefinition.status.contentsSummary.count',
        defaultMessage: '{ not, select, true {not } other {} }{ count, plural, one {# status} other {# statuses} }',
      },
      {
        not: condition.not,
        count: conditionContents.statuses.length,
      }
    );

    const preferredSummary = conditionContents.statuses.length > 0
      ? (
        conditionContents.statuses.length === 1
          ? singleSummary
          : countSummary
      )
      : null;

    return {
      exclusive: false,
      soloSummary: preferredSummary,
      andSummary: preferredSummary,
    };
  }
}
