import { IntlShape } from 'react-intl';

import { ValidationError } from '../ValidationError';
import { ValidationErrorDefinition } from './ValidationErrorDefinition';

export interface IdNotFoundValidationError extends ValidationError {
  type: 'idNotFound',
  metadata: {
    id: string;
  };
}

export class IdNotFoundValidationErrorDefinition implements ValidationErrorDefinition<IdNotFoundValidationError> {
  public static TYPE = 'idNotFound' as const;
  public type = IdNotFoundValidationErrorDefinition.TYPE;

  public formatMessage = (intl: IntlShape) => intl.formatMessage({
    description: 'Validation error message when id is not found.',
    defaultMessage: 'Not found',
  });
}
