import styled from '@emotion/styled';

export const StyledSubmitFormCard = styled.div`
  height: 100%;
  display: flex;
  padding: ${ props => props.theme.spacing(1) };
  flex-direction: column;
  justify-content: center;
  gap: ${ props => props.theme.spacing(1) };
`;
