import { FC, ReactNode } from 'react';

import { Flex, FlexAlign } from '../Flex/Flex';

type Props = {
  gap?: number;
  align?: FlexAlign;
  children?: ReactNode;
};

export const VerticallySpaced: FC<Props> = ({
  gap = 0,
  align = 'stretch',
  children,
}) => (
  <Flex
    direction="column"
    align={ align }
    gap={ gap }
    noWrap
  >
    { children }
  </Flex>
);
