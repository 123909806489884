import { FC } from 'react';

import { StyledTextFieldLeadingText } from './style';
import { Typography } from '../../Content/Typography/Typography';

type Props = {
  onClick?: () => void;
  disabled?: boolean;
};

export const TextFieldLeadingText: FC<Props> = ({
  onClick,
  disabled,
  children,
}) => (
  <StyledTextFieldLeadingText
    onClick={ onClick }
    disabled={ !!disabled }
  >
    <Typography
      color="grey.600.main"
      weight="regular"
    >
      { children }
    </Typography>
  </StyledTextFieldLeadingText>
);
