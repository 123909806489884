import { TransitionProps } from '@mui/material/transitions';
import { FunctionComponent as FC, useMemo } from 'react';
import { Dialog, DialogTitle } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { CenteredGenericErrorMessage, CenteredGenericLoadingMessage } from '@ourpeople/shared/Core/Component/Content';

import { useFetchAudience } from '../../../../../Audiences/Hook';
import { AudienceBuilder } from '../../../../../Audiences/Component';
import {
  Flex,
  FlexPullRight,
  IconButton
} from '../../../../../Common/Component';
import { StyledDialogContent } from './style';
import CloseIcon from '../../../../../Assets/img/icons/streamline/close.svg';

type Props = {
  open: boolean;
  onClose: () => void;
  TransitionProps: TransitionProps;
  recipientAudienceId: string;
};

export const FetchAudienceDialog: FC<Props> = ({
  open,
  onClose,
  TransitionProps,
  recipientAudienceId,
}) => {
  const intl = useIntl();
  const [fetchAudienceResult, , reloadAudience] = useFetchAudience(recipientAudienceId);
  const audience = useMemo(() => fetchAudienceResult?.content, [fetchAudienceResult?.content]);
  const cancelLabel = useMemo(() => (
    intl.formatMessage({
      description: 'Label for closing the view audience modal',
      defaultMessage: 'Close',
    })
  ), [intl]);

  return (
    <Dialog
      open={ open }
      onClose={ onClose }
      TransitionProps={ TransitionProps }
      maxWidth={ 'lg' }
      fullWidth
    >
      <StyledDialogContent>
        <DialogTitle>
          <Flex
            noWrap
            align="flex-start"
          >
            <span>
              <FormattedMessage
                description="View audience criteria heading"
                defaultMessage="Audience criteria"
              />
            </span>
            <FlexPullRight>
              <IconButton
                IconComponent={ CloseIcon }
                onClick={ onClose }
                label={ cancelLabel }
                size="small"
              />
            </FlexPullRight>
          </Flex>
        </DialogTitle>
        {
          audience
            ? (
              <AudienceBuilder
                audience={ audience }
                readonly
              />
            )
            : fetchAudienceResult?.error
              ? <CenteredGenericErrorMessage onRetryClicked={ reloadAudience }/>
              : <CenteredGenericLoadingMessage/>
        }
      </StyledDialogContent>
    </Dialog>
  );
};
