import { FunctionComponent, useContext, useState } from 'react';
import { MenuItem } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';

import { TableMenu } from '../../../Components/Content/TableMenu';
import { ApiContext } from '../../../Contexts';
import CancelIcon from '../../../Assets/img/icons/streamline/delete.svg';
import ReportIcon from '../../../Assets/img/icons/streamline/graph-stats-ascend.svg';
import { Flex, DeprecatedLink } from '../../../Common/Component';
import { useContextOrThrow } from '../../../Core/Hook';
import { ToastContext } from '../../../Core/Context';

type Props = {
  invitationId: string;
  onInvitationCancelled: () => void;
  showCancel?: boolean;
}

export const InvitationMenu: FunctionComponent<Props> = ({ invitationId, onInvitationCancelled, showCancel = false }) => {
  const api = useContext(ApiContext);
  const intl = useIntl();
  const { addSuccessToast, addErrorToast } = useContextOrThrow(ToastContext);
  const [cancelling, setCancelling] = useState<boolean>(false);

  const cancelInvitation = (invitationId: string): void => {
    if (!api) {
      return;
    }

    setCancelling(true);
    api.post(`/invitations/${ invitationId }/cancel`)
      .then(() => {
        setTimeout(() => {
          onInvitationCancelled();
          addSuccessToast(
            intl.formatMessage({
              description: 'Success message when cancelling invitation.',
              defaultMessage: 'Invitation cancelled successfully.',
            })
          );
        }, 50);
        setCancelling(false);
      })
      .catch(() => {
        addErrorToast(
          intl.formatMessage({
            description: 'Error message when cancelling invitation.',
            defaultMessage: 'Invitation could not be cancelled.',
          })
        );
        setCancelling(false);
      });
  };

  return (
    <TableMenu
      rowKey={ invitationId }
      busy={ cancelling }
    >
      <DeprecatedLink to={ `/people/invitations/${ invitationId }/report` }>
        <MenuItem>
          <Flex gap={ 1 }>
            <ReportIcon role="presentation"/>
            <FormattedMessage
              id="people.invitations.viewReport"
              defaultMessage="View report"
            />
          </Flex>
        </MenuItem>
      </DeprecatedLink>
      { showCancel && (
        <MenuItem
          onClick={ () => cancelInvitation(invitationId) }
        >
          <Flex gap={ 1 }>
            <CancelIcon role="presentation"/>
            <FormattedMessage
              id="people.invitations.cancelInvitation"
              defaultMessage="Cancel invitation"
            />
          </Flex>
        </MenuItem>
      ) }
    </TableMenu>
  )
};
