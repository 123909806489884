import { ChangeEvent, FC, ReactNode, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { FormControl, FormControlLabel, Radio } from '@mui/material';
import { parseISO } from 'date-fns';

import { QuizOption } from '../../../../Broadcasts/Service';
import {
  BroadcastContentFooter,
  ContentPadding,
  NudgeBanner,
  RespondButton,
  RespondButtonProps,
  StackNavigationProps
} from '..';
import { CardTitle, ScrollableContent } from '../../../../Broadcasts/Component/Content';
import { PresentationIcon, VerticallySpaced } from '../../../../Common/Component';
import CorrectIcon from '../../../../Assets/img/icons/monochrome/success.svg';
import IncorrectIcon from '../../../../Assets/img/icons/monochrome/close.svg';
import { StyledRadioGroup, StyledRadioIcon } from './style';
import { ArrayHelper } from '../../../../Common/Utility';
import { BroadcastNudge } from '../../../../Broadcasts/Model';
import { AbsoluteBackButtonContainer } from '../AbsoluteBackButtonContainer/AbsoluteBackButtonContainer';

type Props = {
  title: string;
  answerId: string | null;
  onAnswerIdChanged: (answerId: string) => void;
  options: QuizOption[];
  submitProps: RespondButtonProps;
  responded?: boolean;
  navigationProps: StackNavigationProps;
  nudge?: BroadcastNudge;
  backButton: ReactNode | undefined;
};

export const QuizBroadcastContent: FC<Props> = ({
  title,
  answerId,
  onAnswerIdChanged,
  options,
  submitProps,
  responded = false,
  navigationProps,
  nudge,
  backButton,
}) => {
  const intl = useIntl();
  const whenAnswerIdChanged = useCallback((event: ChangeEvent<HTMLInputElement>, value: string) => {
    onAnswerIdChanged(value);
  }, [onAnswerIdChanged]);
  const shuffledOptions = useMemo(
    () => ArrayHelper.shuffle(options),
    [options],
  );

  const correctOptionIcon = useMemo(() => <StyledRadioIcon><PresentationIcon
    color="success.main"
    IconComponent={ CorrectIcon }
  /></StyledRadioIcon>, []);
  const incorrectOptionIcon = useMemo(() => <StyledRadioIcon><PresentationIcon
    color="error.main"
    IconComponent={ IncorrectIcon }
  /></StyledRadioIcon>, []);

  return (
    <>
      { backButton && <AbsoluteBackButtonContainer>{ backButton }</AbsoluteBackButtonContainer> }
      <ScrollableContent>
        {
          nudge && (
            <NudgeBanner
              message={ nudge.message }
              nudgedAt={ parseISO(nudge.at) }
            />
          )
        }
        <ContentPadding>
          <VerticallySpaced gap={ 2 }>
            <CardTitle>{ title }</CardTitle>
            <FormControl component="fieldset">
              <StyledRadioGroup
                name="quiz-options"
                value={ answerId }
                onChange={ whenAnswerIdChanged }
              >
                { shuffledOptions.map((option) => (
                  <FormControlLabel
                    key={ option.id }
                    checked={ answerId === option.id.toString() }
                    value={ option.id }
                    control={ (

                      <Radio
                        color="primary"
                        { ...(responded && (
                          option.correct_option
                            ? {
                              icon: correctOptionIcon,
                              checkedIcon: correctOptionIcon,
                            }
                            : {
                              icon: incorrectOptionIcon,
                              checkedIcon: incorrectOptionIcon,
                            }
                        )) }
                        disabled={ responded }
                      />
                    ) }
                    label={ option.text }
                  />
                )) }
              </StyledRadioGroup>
            </FormControl>
          </VerticallySpaced>
        </ContentPadding>
      </ScrollableContent>
      <BroadcastContentFooter { ...navigationProps }>
        { !!answerId && (
          <RespondButton
            { ...submitProps }
            variant="primary"
            notRespondedLabel={ intl.formatMessage({
              id: 'content.broadcast.quiz.notResponded',
              description: 'Label for quiz submit button when unread.',
              defaultMessage: 'Submit answer',
            }) }
            respondedLabel={ intl.formatMessage({
              id: 'content.broadcast.quiz.responded',
              description: 'Label for quiz submit button when unread.',
              defaultMessage: 'Answer submitted',
            }) }
            rejectedLabel={ intl.formatMessage({
              id: 'content.broadcast.quiz.rejected',
              description: 'Label for quiz submit button when response was rejected.',
              defaultMessage: 'Try again',
            }) }
          />
        ) }
      </BroadcastContentFooter>
    </>
  );
};
