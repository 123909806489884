import { MultiContentCard } from '../Model/MultiContentCard';
import { SingleContentCard } from '../../Content/Model';
import { FormContent } from '../Model';
import { UniqueIdGenerator } from '../../Common/Utility';

export class FormCardTransformer {
  public static consolidateCardContent = ({ contents, card }: MultiContentCard): SingleContentCard<FormContent> => ({
    ...card,
    mandatory: contents[0]?.mandatory || false,
    content: contents[0]?.content || {
      id: UniqueIdGenerator.generate(),
      type: 'unknown',
    },
  });
}
