import { IntlShape } from 'react-intl';

import { Condition, TagCondition } from '../../../../Audiences/Model';
import { AudienceConditionDefinition, ConditionRestriction } from '../../Model/AudienceConditionDefinition';
import { TagType } from '../../../../Tags/Model';
import { SkillConditionComponent } from '../../Component/SkillCondition/SkillConditionComponent';
import { SkillList } from '../../Component/SkillCondition/SkillList';

export class SkillConditionDefinition implements AudienceConditionDefinition<TagCondition<TagType.Skill>> {
  public readonly TYPE = 'tag';
  public readonly restriction = ConditionRestriction.SOME;
  public readonly name;

  constructor(intl: IntlShape) {
    this.name = intl.formatMessage({
      description: 'Name for skill condition, used in audience builder.',
      defaultMessage: 'Skill',
    });
  }
  public definesCondition(condition: Condition): condition is TagCondition<TagType.Skill> {
    return condition.type === this.TYPE && (condition as TagCondition).tagType === TagType.Skill;
  }

  isValid(condition: TagCondition<TagType.Skill>): boolean {
    return !!condition.tagIds.length;
  }

  public Component = SkillConditionComponent;

  public build = {
    Component: SkillList,
    initialise: () => ({
      type: 'tag' as const,
      tagType: TagType.Skill,
      tagIds: [],
      not: false,
      new: true,
    }) as TagCondition<TagType.Skill>,
  };
}
