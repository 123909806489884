import { FC } from 'react';

import { ViewResponse } from '../../../Model';
import { FileContent } from '../../../../Broadcasts/Service';
import { FileBroadcastContent } from '../../../../Content/Component';
import { LiveMediaContent } from '..';
import { BroadcastContentContext } from '../../../../Broadcasts/Model';
import { LiveComponentProps } from '../../../../Content/Model';

export const LiveFileContent: FC<LiveComponentProps<FileContent, ViewResponse, BroadcastContentContext>> = (props) => (
  <LiveMediaContent
    PresentationComponent={ FileBroadcastContent }
    { ...props }
  />
);
