import { FetchResponse, useGet } from '../../../../Hooks';
import { SettingCategory } from '../Types';

type Response = {
  categories: SettingCategory[],
}

export const useFetchSettings = (): FetchResponse<Response> => {
  const endpoint = '/settings';
  return useGet<Response>(endpoint);
}
