import { FC, PropsWithChildren } from 'react';

import { CardContent } from '../CardContent/CardContent';
import { StyledCardHeader } from './style';

type Props = {
  seamless?: boolean;
};

export const CardHeader: FC<PropsWithChildren<Props>> = ({ seamless = false, children }) => (
  <StyledCardHeader seamless={ seamless }>
    <CardContent insetBottom={ !seamless } insetTop>
      { children }
    </CardContent>
  </StyledCardHeader>
);
