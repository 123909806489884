import { Theme } from '@emotion/react';

type PrimitiveArg = string | number | false | undefined | null;
type ThemeConsumer<AdditionalProps> = (props: AdditionalProps & { theme: Theme }) => PrimitiveArg;

export const css = <AdditionalProps>(
  css: TemplateStringsArray,
  ...args: (ThemeConsumer<AdditionalProps> | PrimitiveArg)[]
): ThemeConsumer<AdditionalProps> => props => css.reduce(
  (partialInterpolatedCss, segment, index) => {
    const correspondingArg = args[index];

    return partialInterpolatedCss.concat(
      segment.concat(
        correspondingArg
          ? typeof correspondingArg === 'function'
            ? `${ correspondingArg(props) || '' }`
            : `${ correspondingArg || '' }`
          : '',
      ),
    );
  },
  '',
);
