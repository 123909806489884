import { FC } from 'react';

import { MinimalPerson } from '../../../Models';
import { PersonAvatar } from '../PersonAvatar/PersonAvatar';
import { PersonParser } from '../../../Utility';

type Props = {
  person: MinimalPerson;
};

export const InlinePersonAvatarAndName: FC<Props> = ({ person }) => (
  <span>
    <>
      <PersonAvatar
        css={ {
          display: 'inline-block',
          verticalAlign: 'middle',
        } }
        person={ person }
        size="sm"
        tooltip
      />{ ' ' }
    </>
    { PersonParser.fullName(person) }
  </span>
);
