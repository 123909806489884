import { FC, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { ContentEditorProps } from '../../../../Content/Model';
import {
  TIMELINE_TEXT_MAX_LENGTH,
  TIMELINE_TEXT_MIN_LENGTH,
  TimelineEditorContent,
  TimelineValidator
} from '../../../Service';
import { useInputChangeEventHandler } from '../../../../Common/Hook';
import { FieldValidationErrors, NoMarginTextField, VerticallySpaced } from '../../../../Common/Component';
import { CharacterCount } from '../../../../Content/Component';

export const TimelineEditor: FC<ContentEditorProps<TimelineEditorContent>> = ({
  editorContent,
  onEditorContentChanged,
  validation,
  onValidationChanged,
}) => {
  const intl = useIntl();
  const textFieldLabel = intl.formatMessage({
    id: 'broadcasts.content.timeline.fields.text.label',
    description: 'Label for text field of broadcasts timeline editor.',
    defaultMessage: 'Title',
  });

  const whenTextChanged = useInputChangeEventHandler(useCallback(text => (
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          text,
        },
      },
    })
  ), [editorContent, onEditorContentChanged]));

  const whenTextBlurred = useCallback(() => {
    onValidationChanged({
      errors: [],
      children: {
        content: {
          errors: [],
          children: {
            ...validation?.children.content?.children,
            text: TimelineValidator.validateText(editorContent.card.content.text),
          },
        },
      },
    })
  }, [editorContent.card.content.text, onValidationChanged, validation?.children.content?.children]);

  return (
    <VerticallySpaced gap={ 2 }>
      <VerticallySpaced gap={ 1 }>
        <h4>{ textFieldLabel }</h4>
        <NoMarginTextField
          fullWidth
          value={ editorContent.card.content.text }
          onChange={ whenTextChanged }
          multiline
          onBlur={ whenTextBlurred }
        />
        <CharacterCount
          current={ editorContent.card.content.text.length || 0 }
          minimum={ TIMELINE_TEXT_MIN_LENGTH }
          maximum={ TIMELINE_TEXT_MAX_LENGTH }
        />
        <FieldValidationErrors
          fieldName={ textFieldLabel }
          validationErrors={ validation?.children.content?.children.text?.errors || [] }
        />
      </VerticallySpaced>
    </VerticallySpaced>
  );
}
