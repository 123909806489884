import { RoleId } from '../Security/Model';

export class RoleReader {
  public static getMostPermissiveRole = (givenRoles: RoleId[]): RoleId | undefined => {
    const highestPriority = givenRoles.reduce(
      (currentHighest, givenRole) => Math.max(rolePriorities.indexOf(givenRole), currentHighest),
      -1,
    );

    return rolePriorities[highestPriority];
  };

  public static isDeveloper = (givenRoles: RoleId[]): boolean => isAtLeast('ROLE_DEVELOPER', givenRoles);
  public static isSuperAdmin = (givenRoles: RoleId[]): boolean => isAtLeast('ROLE_SUPER_ADMIN', givenRoles);
  public static isTeamAdmin = (givenRoles: RoleId[]): boolean => isAtLeast('ROLE_ADMIN', givenRoles);
  public static isBroadcastAdmin = (givenRoles: RoleId[]): boolean => isAtLeast('ROLE_BROADCAST_ADMIN', givenRoles);
  public static priority = (roleId: RoleId): number => rolePriorities.indexOf(roleId);
}

const isAtLeast = (role: RoleId, givenRoles: RoleId[]): boolean => {
  const mostPermissiveRole = RoleReader.getMostPermissiveRole(givenRoles);
  const mostPermissiveRolePriority = mostPermissiveRole ? rolePriorities.indexOf(mostPermissiveRole) : -1;
  const rolePriority = rolePriorities.indexOf(role);

  return mostPermissiveRolePriority >= rolePriority;
};

const rolePriorities: RoleId[] = [
  'ROLE_USER',
  'ROLE_APP_BROADCAST',
  'ROLE_APP_ADMIN',
  'ROLE_BROADCAST_ADMIN',
  'ROLE_ADMIN',
  'ROLE_SUPER_ADMIN',
  'ROLE_DEVELOPER',
];
