import { DraftChoiceOption, DraftQuizOption, DraftQuizRequest } from '../../ContentDefinition';
import { ValidationTree, Validator } from '../../../../Common/Model';
import { Validate } from '../../../../Common/Utility';

export class QuizRequestValidator {
  public static validate = (content: DraftQuizRequest): ValidationTree<DraftQuizRequest> => ({
    errors: [],
    children: {
      title: QuizRequestValidator.validateTitle(content.title),
      options: {
        errors: optionsValidator.validate(content.options),
        children: content.options.map(option => ({
          errors: [],
          children: {
            text: QuizRequestValidator.validateOptionText(option.text),
          },
        })),
      },
    },
  });

  public static validateOptionText = (optionText: string): ValidationTree<string> => ({
    errors: optionTextValidator.validate(optionText),
    children: {},
  });

  public static validateTitle = (title: string): ValidationTree<string> => ({
    errors: titleValidator.validate(title),
    children: {},
  });
}

export const QUIZ_TITLE_MIN_LENGTH = 1;
export const QUIZ_TITLE_MAX_LENGTH = 90;
export const QUIZ_OPTION_TEXT_MIN_LENGTH = 1;
export const QUIZ_OPTION_TEXT_MAX_LENGTH = 100;
export const QUIZ_INCORRECT_OPTIONS_MIN_COUNT = 1;
export const QUIZ_INCORRECT_OPTIONS_MAX_COUNT = 3;

const titleValidator = new Validator<string>([
  Validate.notBlank,
  title => Validate.minLength(title, QUIZ_TITLE_MIN_LENGTH),
  title => Validate.maxLength(title, QUIZ_TITLE_MAX_LENGTH),
]);

const filterCorrectAnswer = (options: DraftQuizOption[]): DraftChoiceOption[] => (
  options.filter(option => option.type !== 'correctchoiceoption') as DraftChoiceOption[]
);

const optionsValidator = new Validator<DraftQuizOption[]>([
  options => Validate.countGreaterThanEq(filterCorrectAnswer(options), QUIZ_INCORRECT_OPTIONS_MIN_COUNT),
  options => Validate.countLessThanEq(filterCorrectAnswer(options), QUIZ_INCORRECT_OPTIONS_MAX_COUNT),
]);

const optionTextValidator = new Validator<string>([
  Validate.notBlank,
  optionText => Validate.minLength(optionText, QUIZ_OPTION_TEXT_MIN_LENGTH),
  optionText => Validate.maxLength(optionText, QUIZ_OPTION_TEXT_MAX_LENGTH),
]);
