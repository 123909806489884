import { FC, ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { Heading } from '@ourpeople/shared/Core/Component/Content';

import { BoxPageLayout } from '../../../Common/Component/BoxPageLayout/BoxPageLayout';
import { StyledSpaceLogo } from '../../Routing/RegistrationPage/style';
import { AnonymousSettings } from '../../Hook';
import ourPeopleLogoUrl from '../../../Assets/img/logo/op-logo-09092019.svg?url';
import { StyledOurPeopleLogo, StyledRegistrationContainer } from './style';

type Props = {
  anonymousSettings: AnonymousSettings;
  title: string;
  icon?: ReactNode;
};

export const RegistrationTemplate: FC<Props> = ({
  anonymousSettings,
  title,
  icon,
  children
}) => {
  const intl = useIntl();

  return (
    <BoxPageLayout>
      <StyledRegistrationContainer>
        <Stack
          direction="column"
          align="center"
          gap={ 6 }
        >
          <StyledSpaceLogo
            src={ anonymousSettings.smallLogoUrl }
            alt={ anonymousSettings.name }
          />
          { icon }
          <Heading type="h1">
            { title }
          </Heading>
          { children }
          <StyledOurPeopleLogo>
            <FormattedMessage
              description="OurPeople logo on registration pages"
              defaultMessage="<span>Powered by</span>{ ourPeopleLogo }"
              values={ {
                ourPeopleLogo: (
                  <img
                    src={ ourPeopleLogoUrl }
                    alt={ intl.formatMessage({
                      description: 'Alt text for OurPeople logo used on registration pages.',
                      defaultMessage: 'OurPeople',
                    }) }
                  />
                ),
              } }
            />
          </StyledOurPeopleLogo>
        </Stack>
      </StyledRegistrationContainer>
    </BoxPageLayout>
  );
};
