import styled from '@emotion/styled';
import { FormControl } from '@mui/material';

export const SearchFormControl = styled(FormControl)`
  width: 100%;

  .MuiInputAdornment-root {
    width: 1.75rem;
  }

  .MuiInputBase-root {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`
