import { useMemo } from 'react';

import { Paginated, MinimalPerson } from '../../Models';
import { FetchResponse, usePost } from '../../Hooks';
import { MinimalTag } from '../../Tags/Model';
import { Profile } from '../../Profiles/Model';

export type ProfileTagsAndPerson = {
  tags: MinimalTag[];
  profile: Profile;
  person: MinimalPerson;
}

export type FetchProfilesParams = {
  pageNum: number;
};

type Response = Paginated<'profiles', ProfileTagsAndPerson>;

export const useFetchProfiles = (
  params: FetchProfilesParams,
  disabled?: boolean,
): FetchResponse<Response> => {
  const data = useMemo(() => ({
    query: params,
  }), [params]);

  return usePost<Response>(
    '/profiles',
    data,
    undefined,
    disabled,
  );
}
