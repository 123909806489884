import styled from '@emotion/styled';

interface StyledContentIconProps {
  size?: string;
}

export const StyledContentIcon = styled.div<StyledContentIconProps>`
  width: ${props => props.size ? props.size : '1.5rem'};
  height: ${props => props.size ? props.size : '1.5rem'};
  margin-left: 0.3rem;
  svg {
    width: ${props => props.size ? props.size : '1.5rem'};
    height: ${props => props.size ? props.size : '1.5rem'};
    fill: ${props => props.theme.palette.text.primary};
    rect {
      stroke: ${props => props.theme.palette.text.primary};
    }
  }
`;
