import { useMemo } from 'react';

import { FetchResponse, useGet } from '../../Hooks';
import { MinimalPerson, Paginated } from '../../Models';

type FetchInvitationRecipientsParams = {
  pageNum: number;
  sort?: string;
  ids?: string;
  personIds?: string;
  statuses?: string;
  search?: string;
}

export type InvitationRecipientStatus = 'pending' | 'included' | 'ignored';
export type InvitationDeliverableRecipientStatus = 'pending' | 'delivered' | 'failed';

type DeliverableRecipient = {
  id: string;
  status: InvitationDeliverableRecipientStatus;
  statusReason: string;
};

type InvitationRecipients = {
  invitationRecipient: {
    id: string;
    status: InvitationRecipientStatus;
    person: MinimalPerson;
  };
  emailRecipient: DeliverableRecipient;
  smsRecipient: DeliverableRecipient;
}

export const useFetchInvitationRecipients = (invitationId: string, pageNum: number): FetchResponse<Paginated<'recipients', InvitationRecipients>> => {
  const params = useMemo<FetchInvitationRecipientsParams>(() => ({
    pageNum,
  }), [pageNum]);
  return useGet<Paginated<'recipients', InvitationRecipients>>(`/invitations/${ invitationId }/recipients`, params);
}
