import { ChangeEvent, FunctionComponent, useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box as MaterialBox, FormControl, FormLabel, RadioGroup, } from '@mui/material';

import { ImportTable } from '..';
import { Alert, StyledFormControlLabel, StyledRadio } from '../../../Components';
import { RequestState } from '../../../Models';
import { ApiContext } from '../../../Contexts';
import { LicenceInformation } from '../LicenceInformation/LicenceInformation';
import { H1, H2 } from '../../../Components/Layout/headings';
import { useFetchLicenceCounts } from '../../Hook';
import { RemoteIntegration } from '../../../Types';
import { Box, VerticallySpaced } from '../../../Common/Component';
import { useContextOrThrow } from '../../../Core/Hook';
import { ToastContext } from '../../../Core/Context';

type SyncDecision = 'allow' | 'deny' | 'pending';

type Props = {
  integration?: RemoteIntegration | null,
  onBulkActionCompleted: () => void;
  onRequestStateChanged: (requestState: RequestState) => void;
  defaultUserSyncDecision: SyncDecision;
}

export const ManageSection: FunctionComponent<Props> = ({
  integration,
  onBulkActionCompleted,
  onRequestStateChanged,
  defaultUserSyncDecision,
}) => {
  const intl = useIntl();
  const api = useContext(ApiContext);
  const [management, setManagement] = useState<SyncDecision>(defaultUserSyncDecision);
  const [licenceCounts] = useFetchLicenceCounts();
  const [userCount, setUserCount] = useState<number>(0);
  const { addSuccessToast } = useContextOrThrow(ToastContext);

  const valueIsSyncDecision = (value: string): value is SyncDecision => {
    return ['allow', 'deny', 'pending'].indexOf(value) !== -1;
  }

  const updateManagement = (defaultUserSyncDecision: SyncDecision) => {
    if (api) {
      api.post(
        '/remote-integration',
        {
          defaultUserSyncDecision,
        },
      )
        .then(() => {
          addSuccessToast(
            defaultUserSyncDecision === 'allow'
              ? intl.formatMessage({
                id: 'thirdPartyImport.management.automaticToast',
                description: 'Message in toast when setting import management to automatic',
                defaultMessage: 'People will be automatically imported via your third party integration',
              })
              : intl.formatMessage({
                id: 'thirdPartyImport.management.manualToast',
                description: 'Message in toast when setting import management to manual',
                defaultMessage: 'You will be prompted to manually import people from your third party integration',
              })
          );
        })
        .catch(() => {/* Silent */})
    }
  }

  const whenManagementChanged = (event: ChangeEvent<HTMLInputElement>, value: string): void => {
    if(valueIsSyncDecision(value)) {
      setManagement(value);
      updateManagement(value)
    }
  }

  const whenUsersCounted = (userCount: number): void => {
    setUserCount((previous) => Math.max(userCount, previous));
  }

  return (
    <Box>
      <H1>
        <FormattedMessage
          id="thirdPartyImport.manage.heading"
          description="Heading label for manage section of third party import"
          defaultMessage="Manage people import"
        />
      </H1>
      <VerticallySpaced gap={ 2 }>
        {
          licenceCounts?.content && licenceCounts.content.limit && (
            <LicenceInformation
              limit={licenceCounts.content.limit}
              live={licenceCounts.content.live}
            />
          )
        }
        <p>
          <FormattedMessage
            id="thirdPartyImport.manage.description"
            description="Description for manage section of third party import"
            defaultMessage="This list includes all the employees available via your third party integration. You can allow or deny access to individual employees below."
          />
        </p>
      </VerticallySpaced>
      <FormControl
        component="fieldset"
      >
        <FormLabel
          component="legend"
        >
          <H2>
            <FormattedMessage
              id="thirdPartyImport.manage.formLabel"
              description="Label for on-going update management setting in the manage section of third party imports"
              defaultMessage="How would you like to manage on-going updates?"
            />
          </H2>
        </FormLabel>
        <RadioGroup
          aria-label="update-management"
          name="update-management"
          value={ management }
          onChange={ whenManagementChanged }
        >
          <StyledFormControlLabel
            value={ 'allow' }
            control={ <StyledRadio color="primary"/> }
            label={ intl.formatMessage({
              id: 'thirdPartyImport.manage.automatic',
              description: 'Label for automatic import option',
              defaultMessage: 'Automatically import people',
            }) }
          />
          <StyledFormControlLabel
            value={ 'pending' }
            control={ <StyledRadio color="primary"/> }
            label={ intl.formatMessage({
              id: 'thirdPartyImport.manage.manual',
              description: 'Label for manual import option',
              defaultMessage: 'Manually approve access',
            }) }
          />
        </RadioGroup>
      </FormControl>
      {
        !!userCount && (
          <>
            <H2>
              <FormattedMessage
                id="thirdPartyImport.manage.pendingMessage"
                description=""
                defaultMessage="{pendingCount, plural, one {# person} other {# people}} pending confirmation"
                values={ {
                  pendingCount: userCount,
                } }
              />
            </H2>
            {
              !!licenceCounts?.content && !!licenceCounts.content.limit && licenceCounts.content.available < userCount && (
                <Alert severity="error">
                  <FormattedMessage
                    id="thirdPartyImport.notice.limitReached"
                    defaultMessage="You have <strong>{pendingCount}</strong> {pendingCount, plural, one {person} other {people}} pending approval, but only <strong>{remainingLicencesCount, plural, one {# licence is} other {# licences are}} available</strong>. To increase your licence access your third party account, or deny people access below."
                    values={ {
                      pendingCount: userCount,
                      remainingLicencesCount: licenceCounts.content.available,
                    } }
                  />
                </Alert>
              )
            }
          </>
        )
      }
      <MaterialBox hidden={(!integration || !!integration.integration.state.lastFullSyncFinishedAt) && !userCount}>
        <ImportTable
          showIntegrationSpinner={!integration?.integration.state.lastFullSyncFinishedAt}
          imported={false}
          onBulkActionCompleted={onBulkActionCompleted}
          onRequestStateChanged={onRequestStateChanged}
          onUsersCounted={whenUsersCounted}
        />
      </MaterialBox>
    </Box>
  );
};
