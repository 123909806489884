import styled from '@emotion/styled';
import { ComponentProps } from 'react';

import { Stack } from './Stack';

type StyledStackProps = Omit<ComponentProps<typeof Stack>, 'className'>;

export const StyledStack = styled.div<StyledStackProps>`
  display: ${ props => props.inline ? 'inline-flex' : 'flex' };
  flex-direction: ${ props => props.direction };
  ${ props => props.gap !== 0 && `gap: ${ props.theme.new.spacing[props.gap] };` };
  justify-content: ${ props => props.justify };
  align-items: ${ props => props.align };
  flex-wrap: ${ props => props.flexWrap };
  ${ props => props.noWrap && 'white-space: nowrap;' };
  ${ props => props.fillParent && `
    ${
      ['row', 'row-reverse'].includes(props.direction)
        ? 'width: 100%;'
        : ''
    }

    ${
      ['column', 'column-reverse'].includes(props.direction)
        ? 'height: 100%;'
        : ''
    }
  ` }
`;
