import { FC, useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';
import { getDescendantErrors } from 'op-storybook/lib/utility/getDescendantErrors';

import { useContextOrThrow } from '../../../Core/Hook';
import { FormNavigationContext } from '../../Provider/FormNavigationProvider';
import { ButtonRow, StyledPager } from './style';
import { Pager } from '../../../Content/Component';
import { Flex, PresentationIcon } from '../../../Common/Component';
import RightArrow from '../../../Assets/img/icons/streamline/arrow-right-1.svg';
import LeftArrow from '../../../Assets/img/icons/streamline/arrow-left-1.svg';
import { ValidationTree } from '../../../Common/Model';
import { DraftFormSubmission } from '../FormCards/FormCards';

type Props = {
  currentIndex: number;
  cardCount: number;
  onIndexChanged: (index: number) => void;
  validation: ValidationTree<DraftFormSubmission>;
  submitted: boolean;
};

export const FormFooter: FC<Props> = ({
  currentIndex,
  cardCount,
  onIndexChanged,
  validation,
  submitted,
}) => {
  const {
    footerContents,
    displayingValidation,
    setDisplayingValidation,
    progressBlocked,
  } = useContextOrThrow(FormNavigationContext);

  const currentCardHasErrors = useMemo(
    () => {
      const currentCardValidation = validation.children.cards?.children[currentIndex];

      return currentCardValidation && !!getDescendantErrors(currentCardValidation).length
    },
    [currentIndex, validation.children.cards?.children],
  );

  const actions = useMemo(() => (
    currentIndex === 0
      ? (
        <Button
          variant="primary"
          onClick={ () => onIndexChanged(1) }
        >
          <FormattedMessage
            description="Start button label in form navigation"
            defaultMessage="Start"
          />
        </Button>
      )
      : footerContents
  ), [currentIndex, footerContents, onIndexChanged]);

  useEffect(() => {
    if (!displayingValidation) {
      return;
    }

    setDisplayingValidation(validation && !!getDescendantErrors(validation).length);
  }, [displayingValidation, setDisplayingValidation, validation]);

  const advance = useCallback(() => {
    if (currentCardHasErrors) {
      return setDisplayingValidation(true);
    }

    onIndexChanged(currentIndex + 1);
  }, [currentCardHasErrors, currentIndex, onIndexChanged, setDisplayingValidation]);

  return (
    <div>
      <ButtonRow>
        <div>
          { currentIndex !== 0 && !submitted && (
            <Button
              fullWidth
              onClick={ () => onIndexChanged(currentIndex - 1) }
            >
              <Flex fullWidth gap={ 1 }>
                <PresentationIcon IconComponent={ LeftArrow }/>
                { !actions && (
                  <FormattedMessage
                    description="Previous card button label"
                    defaultMessage="Previous"
                  />
                ) }
              </Flex>
            </Button>
          ) }
        </div>
        <div>
          { actions }
        </div>
        <div>
          { currentIndex !== cardCount && !submitted && (
            <Button
              fullWidth
              onClick={ advance }
              disabled={ progressBlocked || (displayingValidation && currentCardHasErrors) }
            >
              <Flex fullWidth gap={ 1 }>
                { !actions && (
                  <FormattedMessage
                    description="Next card button label"
                    defaultMessage="Next"
                  />
                ) }
                <PresentationIcon IconComponent={ RightArrow }/>
              </Flex>
            </Button>
          ) }
        </div>
      </ButtonRow>
      <StyledPager>
        <Pager progress={ currentIndex / cardCount }/>
      </StyledPager>
    </div>
  );
};
