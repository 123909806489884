import { FunctionComponent, ReactNode, useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { ListItemText, MenuItem } from '@mui/material';

import { RefreshDeliverySummaryContext } from '../BroadcastReportPage';
import { TableMenu } from '../../../../../Components/Content/TableMenu';
import { Dialog } from '../../../../../Components';
import { ApiContext } from '../../../../../Contexts';
import { Content } from '../../../../../Models';
import { PersonParser } from '../../../../../Utility';
import { RefreshContext, ReportRecipient } from '../../../../../Broadcasts/Component';

interface Props {
  content: Content;
  recipient: ReportRecipient<Record<string, unknown>>;
}

export const CoverTableMenu: FunctionComponent<Props> = ({
  content,
  recipient,
}) => {
  const intl = useIntl();
  const api = useContext(ApiContext);
  const reload = useContext(RefreshContext);
  const refreshDeliverySummary = useContext(RefreshDeliverySummaryContext);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [actionDescription, setActionDescription] = useState<string|ReactNode>();

  const onAssign = () => {
    setActionDescription(
      intl.formatMessage({
        id: 'assignCover.description',
        defaultMessage: 'Are you sure you want to assign this cover to <b>{name}</b>?'
      }, {
        name: PersonParser.fullName(recipient.user),
      })
    );
    setConfirmDialogOpen(true);
  }

  const onActionConfirmed = () => {
    if (api && content.id) {
      api.put(
        `/covers/responses/${recipient.metadata.responseId as string}/assign`
      ).then(() => {
        setConfirmDialogOpen(false);
        reload && reload();
        refreshDeliverySummary && refreshDeliverySummary();
      }).catch(() => {
        // do nothing
      });
    }
  };

  return (
    <>
      <TableMenu
        rowKey={ recipient.user.id || ''}
      >
        <MenuItem
          onClick={onAssign}
        >
          <ListItemText primary={
            intl.formatMessage({
              id: 'event.assign',
              defaultMessage: 'Assign',
            })
          }
          />
        </MenuItem>
      </TableMenu>
      <Dialog
        cta={
          intl.formatMessage({
            id: 'trainingConfirmDialog.cta',
            defaultMessage: 'Yep',
          })
        }
        cancel={
          intl.formatMessage({
            id: 'trainingConfirmDialog.cancel',
            defaultMessage: 'Nope',
          })
        }
        headingSize="small"
        headingTextAlign="left"
        open={confirmDialogOpen}
        onConfirmed={onActionConfirmed}
        onDismissed={() => setConfirmDialogOpen(false)}
        heading={
          intl.formatMessage({
            id: 'assignCover.heading',
            defaultMessage: 'Assign Cover',
          })
        }
        >
          {actionDescription}
        </Dialog>
    </>
  )
}
