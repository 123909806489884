import { DraftCommentRequest } from '../../ContentDefinition';
import { ValidationTree, Validator } from '../../../../Common/Model';
import { Validate } from '../../../../Common/Utility';

export class CommentRequestValidator {
  public static validate = (content: DraftCommentRequest): ValidationTree<DraftCommentRequest> => ({
    errors: [],
    children: {
      title: CommentRequestValidator.validateTitle(content.title),
    },
  });

  public static validateTitle = (title: string): ValidationTree<string> => ({
    errors: titleValidator.validate(title),
    children: {},
  });
}

export const COMMENT_TITLE_MIN_LENGTH = 1;
export const COMMENT_TITLE_MAX_LENGTH = 90;

const titleValidator = new Validator<string>([
  Validate.notBlank,
  title => Validate.minLength(title, COMMENT_TITLE_MIN_LENGTH),
  title => Validate.maxLength(title, COMMENT_TITLE_MAX_LENGTH),
]);
