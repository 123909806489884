import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';

import { LocalisedString } from '../../../Common/Model';
import { BasicAutocomplete, BasicAutocompleteProps } from '../../../Common/Component';
import { ImportOperationTypeString } from '../../Model';

type Props = Omit<BasicAutocompleteProps<ImportOperationTypeString>, 'options'>;

export const ImportOperationAutocomplete: FunctionComponent<Props> = props => {
  const intl = useIntl();
  const options: LocalisedString<ImportOperationTypeString>[] = [
    {
      id: 'create',
      localisation: intl.formatMessage({
        id: 'importOperationAutocomplete.create',
        description: 'Create operation label in autocomplete',
        defaultMessage: 'Create',
      }),
    },
    {
      id: 'update',
      localisation: intl.formatMessage({
        id: 'importOperationAutocomplete.update',
        description: 'Update operation label in autocomplete',
        defaultMessage: 'Update',
      }),
    },
  ];

  return (
    <BasicAutocomplete
      options={ options }
      label={ intl.formatMessage({
        id: 'importOperationAutocomplete.label',
        description: 'Label for import operation autocomplete.',
        defaultMessage: 'Actions',
      }) }
      { ...props }
    />
  );
};
