import styled from '@emotion/styled';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';

import CrossIcon from '../../../../Assets/img/icons/streamline/cross.svg';
import { Flex } from '../../../../Common/Component';

export const StyledAddIcon = styled(CrossIcon)`
  transform: rotate(45deg);
`;

export const StyledCenteredButton = styled(Button)`
  margin: 0 auto;
`;

export const StyledSquareUploadButton = styled(Button)`
  button {
    width: ${ props => props.theme.spacing(8) };
    height: ${ props => props.theme.spacing(8) };
    padding: ${ props => props.theme.spacing(0.5) };
    min-width: initial;
    line-height: 1;

    ${ Flex } {
      font-size: 0.75rem;

      svg {
        width: ${ props => props.theme.spacing(2.5) };
        height: ${ props => props.theme.spacing(2.5) };
      }
    }
  }
`;

export const StyledDescription = styled.span`
  text-align: center;
`;

export const StyledHelpText = styled.span`
  text-align: center;
  color: ${ props => props.theme.palette.secondary.main };
`;
