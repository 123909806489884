import styled from '@emotion/styled';

import { Box } from '../..';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${ props => `${ props.theme.spacing(4) }` };

  ${ props => props.theme.breakpoints.down('lg') } {
    gap: ${ props => `${ props.theme.spacing(2) }` };
  }

  ${ props => props.theme.breakpoints.down('sm') } {
    gap: ${ props => `${ props.theme.spacing(1) }` };
  }
`;

export const StyledStepperHeader = styled.div`
  display: grid;
  grid-template-rows: repeat(2, auto);
  box-shadow: ${ props => props.theme.shadows[3] };
`;

export const StyledStepperBox = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: flex-start;
  padding: ${ props => `${ props.theme.spacing(2) }` };
  background-color: ${ props => props.theme.palette.background.paper };

  ${ props => props.theme.breakpoints.down('lg') } {
    grid-template-columns: repeat(2, auto);
  }

  ${ props => props.theme.breakpoints.down('sm') } {
    grid-template-columns: auto;
    padding: ${ props => `${ props.theme.spacing(1) }` };
  }
`;

export const StyledStepperContainer = styled.div`
  display: flex;
  justify-content: center;

  ${ props => props.theme.breakpoints.down('lg') } {
    padding: ${ props => `${ props.theme.spacing(1) }` };
    background: ${ props => props.theme.palette.grey[100] };
  }
`;

export const StyledTitleContainer = styled.div`
  ${ props => props.theme.breakpoints.down('sm') } {
    width: 100%;
  }
`;

export const StyledActionsContainer = styled(Box)`
  border-top: 1px solid ${ props => props.theme.palette.grey[300] };
  padding: ${ props => `${ props.theme.spacing(1) }` };

  ${ props => props.theme.breakpoints.down('sm') } {
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100vw;
  }
`;

export const StyledStepContainer = styled.div`
`;
