import { addHours, addMinutes } from 'date-fns';

import { DraftEvent } from '../Model';

export class EventCreator {
  public static createEventWithType = <T extends string>(type: T): DraftEvent<T> => ({
    type,
    text: '',
    start_at: {
      dt: addMinutes(new Date(), 5).toISOString(),
      tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    end_at: {
      dt: addHours(addMinutes(new Date(), 5), 4).toISOString(),
      tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    location: '',
  });
}
