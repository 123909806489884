export enum FormsPermission {
  ALL = 'forms',
  CREATE = 'forms.create',
  UPDATE = 'forms.update',
  DELETE = 'forms.delete',
  READ = 'forms.read',
  VIEW = 'forms.view',
  REPORT = 'forms.report',
  ME = 'forms.me',
  METRICS = 'forms.metrics',

  CATEGORIES = 'forms.categories',
  CATEGORIES_CREATE = 'forms.categories.create',
  CATEGORIES_UPDATE = 'forms.categories.update',
  CATEGORIES_DELETE = 'forms.categories.delete',
  CATEGORIES_READ = 'forms.categories.read',
}
