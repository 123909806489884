import { FC, ReactNode, RefCallback, useCallback, useEffect, useRef, useState } from 'react';

import { useScrollLock } from '../../Hook/useScrollLock';

type TransitionHandlers = {
  onWillOpen?: () => void;
  onWillClose?: () => void;
  onDidOpen?: () => void;
  onDidClose?: () => void;
};

type Props = {
  open: boolean;
  onOpenChange?: (open: boolean) => void;
  transitionHandlers?: TransitionHandlers;
  className?: string;
  children?: ReactNode;
};

enum Phase {
  DID_CLOSE,
  WILL_OPEN,
  DID_OPEN,
  WILL_CLOSE,
}

export const ModalDialog: FC<Props> = ({
  open,
  onOpenChange,
  transitionHandlers,
  className,
  children,
}) => {
  const dialogRefObject = useRef<HTMLDialogElement>();
  const [phase, setPhase] = useState<Phase>(Phase.DID_CLOSE);
  const stablePhase = useRef<Phase>(phase);
  const transitionTimeout = useRef<number>();
  const [initialised, setInitialised] = useState(false);
  useScrollLock(![Phase.WILL_CLOSE, Phase.DID_CLOSE].includes(phase));

  useEffect(() => {
    if (!initialised) {
      return;
    }

    stablePhase.current = phase;
  }, [initialised, phase]);

  const onDidOpen = useCallback(() => {
    setPhase(Phase.DID_OPEN);
    transitionHandlers?.onDidOpen && transitionHandlers.onDidOpen();
  }, [transitionHandlers]);

  const onWillOpen = useCallback(() => {
    onOpenChange && onOpenChange(true);
    setPhase(Phase.WILL_OPEN);
    !dialogRefObject.current?.open && dialogRefObject.current?.showModal();
    transitionHandlers?.onWillOpen && transitionHandlers.onWillOpen();
  }, [onOpenChange, transitionHandlers]);

  const onDidClose = useCallback(() => {
    onOpenChange && onOpenChange(false);
    dialogRefObject.current?.close();
    setPhase(Phase.DID_CLOSE);
    transitionHandlers?.onDidClose && transitionHandlers.onDidClose();
  }, [onOpenChange, transitionHandlers]);

  const onWillClose = useCallback(() => {
    setPhase(Phase.WILL_CLOSE);
    transitionHandlers?.onWillClose && transitionHandlers.onWillClose();
  }, [transitionHandlers]);

  useEffect(() => {
    open && stablePhase.current === Phase.DID_CLOSE
      ? onWillOpen()
      : !open && stablePhase.current === Phase.DID_OPEN && onWillClose();
  }, [onDidClose, onWillClose, onWillOpen, open]);

  const dialogRef = useCallback<RefCallback<HTMLDialogElement>>((node: HTMLDialogElement) => {
    if (!node) {
      return;
    }

    setInitialised(true);
    const onTransitionEnd = (event: TransitionEvent) => {
      transitionTimeout.current && window.clearTimeout(transitionTimeout.current);
      transitionTimeout.current = window.setTimeout(() => {
        if (event.target !== node) {
          return;
        }

        return (
          stablePhase.current === Phase.WILL_OPEN
            ? onDidOpen()
            : stablePhase.current === Phase.WILL_CLOSE && onDidClose()
        );
      });
    };

    dialogRefObject.current?.removeEventListener('transitionend', onTransitionEnd);
    dialogRefObject.current = node;

    node.addEventListener('transitionend', onTransitionEnd);
  }, [onDidClose, onDidOpen]);

  return (
    <dialog
      onClick={ event => {
        if (event.target !== dialogRefObject.current) {
          return;
        }

        onWillClose();
      } }
      ref={ dialogRef }
      css={ {
        border: 'none',
        padding: 0,
        margin: 0,
        outline: 'none',
        height: '100%',
        width: '100%',
        maxWidth: 'initial',
        maxHeight: 'initial',
        opacity: 0,
        transform: 'translateY(100%)',
        transition: `transform ${ TRANSITION_DURATION_MS }ms, opacity ${ TRANSITION_DURATION_MS }ms`,
        transitionTimingFunction: 'ease-in-out',
        background: 'transparent',
        display: 'flex',

        '&:not([open]), &[open="false"]' : {
          height: 0,
          overflow: 'hidden',
          pointerEvents: 'none',
        },

        '::backdrop': {
          margin: 0,
          padding: 0,
          backgroundColor: 'transparent',
          transition: `background-color ${ TRANSITION_DURATION_MS }ms`,

          ...[Phase.DID_OPEN, Phase.WILL_OPEN].includes(phase) && {
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
          },
        },

        ...[Phase.DID_OPEN, Phase.WILL_OPEN].includes(phase) && {
          opacity: 1,
          transform: 'translateY(0)',
        },
      } }
      className={ className }
    >
      { children }
    </dialog>
  );
};

const TRANSITION_DURATION_MS = 150;
