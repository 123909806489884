import { FC } from 'react';

import { Message, MessageProps } from '../../Message/Message';
import { CenteredContents } from '../CenteredContents/CenteredContents';

export const CenteredMessage: FC<MessageProps> = (props) => (
  <CenteredContents>
    <Message { ...props }/>
  </CenteredContents>
);
