import { FetchResponse, useGet } from '../../Hooks';
import { PersonalContent } from '../Model';
import { Paginated } from '../../Models';

type FetchPersonalDeliveryContentsSortMethod = 'index_asc' | 'index_desc';

export type FetchPersonalDeliveryContentsParams = {
  pageNum?: number;
  ids?: string;
  sort?: FetchPersonalDeliveryContentsSortMethod;
};

export const useFetchPersonalDeliveryContents = (
  deliveryId: string,
  params: FetchPersonalDeliveryContentsParams,
): FetchResponse<Paginated<'contents', PersonalContent>> => (
  useGet(`/me/broadcasts/deliveries/${ deliveryId }/contents`, params)
);
