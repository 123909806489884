import styled from '@emotion/styled';

export const StyledStatusTooltip = styled.ul`
  margin: 0;
  padding: 0 1rem;
`;

export const StyledStatusSummary = styled.span`
  color: ${ props => props.theme.palette.grey[600] };
`;
