import { JSX, ReactNode, RefObject, useEffect, useMemo, useState } from 'react';
import { BreakpointContext } from 'op-storybook/lib/providers/BreakpointProvider/BreakpointProvider';
import { LoadingState } from 'op-storybook/stories/components/LoadingState/LoadingState';
import {
  ListLoadingIndicator
} from '@ourpeople/shared/Core/Component/Feedback/ListLoadingIndicator/ListLoadingIndicator';
import { QueryFunction, QueryKey, useInfiniteQuery } from '@tanstack/react-query';
import { EmptyState } from 'op-storybook/stories/components/EmptyState/EmptyState';
import CrossIcon from 'op-storybook/lib/assets/icon/figma/x-close.svg';
import { useIntl } from 'react-intl';

import { useContextOrThrow } from '../../../../Core/Hook';
import { Paginated, RequestState } from '../../../../Models';
import { PageLayoutContext } from '../../../Core/Provider/PageLayoutProvider';
import { List } from './List';

type QueryFnData<I> = Paginated<'items', I>;

export type InfiniteListProps<
  I,
  F = unknown,
> = {
  filters: F,
  queryKey: QueryKey;
  queryFn: (filters: F, page: number) => Promise<QueryFnData<I>>;
  renderLeadingItems?: () => ReactNode;
  renderItem: (item: I, index: number) => ReactNode;
  renderErrorState?: (retry: () => void) => ReactNode;
  renderEmptyState?: () => ReactNode;
  renderLoadingState?: () => ReactNode;
  className?: string;
  scrollAreaRef?: RefObject<HTMLElement>;
  onPageFetched?: (data: QueryFnData<I>) => void;
  onStateChanged?: (loadingFirstPage: boolean, error?: Error | null) => void;
};

export const InfiniteList = <
  I,
  F = unknown,
>({
  filters,
  queryFn,
  queryKey,
  renderItem,
  renderErrorState,
  renderEmptyState,
  renderLoadingState,
  renderLeadingItems,
  className,
  scrollAreaRef,
  onPageFetched,
  onStateChanged,
}: InfiniteListProps<I, F>): JSX.Element => {
  const intl = useIntl();
  const { active } = useContextOrThrow(PageLayoutContext);
  const screenWidth = useContextOrThrow(BreakpointContext);
  const [scrollThresholdCrossed, setScrollThresholdCrossed] = useState(false);
  const preparedQueryKey = useMemo<QueryKey>(() => [...queryKey, filters], [queryKey, filters]);
  const preparedQueryFn = useMemo<QueryFunction<QueryFnData<I>, typeof preparedQueryKey, number|null>>(
    () => ({pageParam}) => queryFn(filters, pageParam ?? 1), [queryFn, filters]
  );
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, status, refetch, error } = useInfiniteQuery({
    queryKey: preparedQueryKey,
    queryFn: preparedQueryFn,
    initialPageParam: 1,
    getNextPageParam: (lastPage, _pages, lastPageParam) => (
      lastPage.pagination.pageCount <= lastPageParam
        ? null
        : lastPageParam + 1
    ),
  });

  useEffect(() => {
    if (!scrollThresholdCrossed || isFetchingNextPage || !hasNextPage) {
      return;
    }

    void fetchNextPage();
    setScrollThresholdCrossed(false);
  }, [scrollThresholdCrossed, isFetchingNextPage, hasNextPage, fetchNextPage]);

  useEffect(() => {
    if (isFetchingNextPage) {
      return;
    }

    const shouldScrollDocument = active && screenWidth.lessThan.sm;
    const scrollEventOwner = shouldScrollDocument ? document : scrollAreaRef?.current || document;

    if (!scrollEventOwner) {
      return;
    }

    const onScroll = (event: Event) => {
      const eventTarget = event.currentTarget as HTMLDivElement | Document | null;

      if (!eventTarget) {
        return;
      }

      const scrollingElement = eventTarget instanceof Document ? eventTarget.scrollingElement : eventTarget;

      if (!scrollingElement) {
        return;
      }

      const scrollBottom = scrollingElement.scrollHeight - (
        scrollingElement.scrollTop + scrollingElement.clientHeight
      );

      setScrollThresholdCrossed(
        scrollingElement.scrollHeight > scrollingElement.clientHeight
        && scrollBottom < SCROLL_THRESHOLD_PX,
      );
    };

    scrollEventOwner.addEventListener('scroll', onScroll);

    return () => scrollEventOwner.removeEventListener('scroll', onScroll);
  }, [active, scrollAreaRef, screenWidth.lessThan.sm, isFetchingNextPage]);

  useEffect(() => {
    if (!data?.pages.length || !onPageFetched) {
      return;
    }
    onPageFetched(data.pages[data.pages.length - 1]);
  }, [data, onPageFetched]);

  useEffect(() => {
    if (!onStateChanged) {
      return;
    }
    onStateChanged(status === 'pending', error);
  }, [status, error, onStateChanged]);

  const items = useMemo(() => (
      data?.pages.flatMap(({ items }) => items) ?? []
    ),
    [data?.pages],
  );

  return (
    <>
      {
        status === 'error'
          ? renderErrorState
            ? renderErrorState(refetch)
            : (
              <EmptyState
                IconComponent={ CrossIcon }
                text={ intl.formatMessage({
                  description: 'Default error message in infinite loading list when no more specific error is provided.',
                  defaultMessage: 'Something went wrong',
                }) }
                breakpoint="mobile"
                buttonProps={ [
                  {
                    variant: 'secondary',
                    onClick: () => refetch(),
                    children: intl.formatMessage({
                      description: 'Default error message retry button label in infinite loading list when no more specific label is provided.',
                      defaultMessage: 'Retry',
                    })
                  },
                ] }
                pad={true}
              />
            )
          : status === 'success'
            ? (
              <>
                {
                  data.pages[0]?.items.length
                    ? (
                      <List
                        renderLeadingItems={ renderLeadingItems }
                        renderItem={ renderItem }
                        items={ items }
                        className={ className }
                      />
                    )
                    : renderEmptyState
                      ? renderEmptyState()
                      : (
                        <EmptyState
                          IconComponent={ CrossIcon }
                          text={ intl.formatMessage({
                            description: 'Default empty state message in infinite loading list when no more specific message is provided.',
                            defaultMessage: 'Nothing found',
                          }) }
                          breakpoint="mobile"
                          buttonProps={ [] }
                          pad={true}
                        />
                      )
                }
                { isFetchingNextPage && (
                  <ListLoadingIndicator
                    fetchState={ status === 'success' ? RequestState.FETCHING : RequestState.FAILED }
                    onClick={ refetch }
                  />
                ) }
              </>
            )
            : renderLoadingState ? renderLoadingState() : <LoadingState pad={ true }/>
      }
    </>
  );
};

const SCROLL_THRESHOLD_PX = 200;
