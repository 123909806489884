import styled from '@emotion/styled';

export const StyledActionContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  gap: ${ props => props.theme.new.spacing[2] };

  ${ props => props.theme.breakpoints.down('md') } {
    flex-direction: column;
  }
`;
