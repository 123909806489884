import {FC, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import { getMessaging, getToken, isSupported } from 'firebase/messaging';
import { isBefore, parseISO, subHours } from 'date-fns';
import * as Sentry from '@sentry/browser';
import { MessagingService } from '@firebase/messaging/dist/src/messaging-service';

import { useContextOrThrow } from '../Hook/useContextOrThrow';
import { ApiContext } from '../../Contexts';
import { useFcmTokenRegistrationState } from '../Hook/useFcmTokenRegistrationState';
import { useFirebase } from '../Hook/useFirebase';
import {
  PushDeviceRegistrationContext,
  PushDeviceRegistrationContextValue
} from '../Context/PushDeviceRegistrationContext';
import {DeviceFingerprintContext} from '../Context/DeviceFingerprintContext';

enum RefreshState {
  PENDING,
  REFRESHING,
  COMPLETE,
}

export const PushDeviceRegistrationProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const firebaseApp = useFirebase();
  const [firebaseMessaging, setFirebaseMessaging] = useState<MessagingService>();
  const api = useContextOrThrow(ApiContext);
  const { deviceToken } = useContext(DeviceFingerprintContext);
  const [fcmTokenRegistration, setFcmTokenRegistration] = useFcmTokenRegistrationState();
  const [refreshState, setRefreshState] = useState(RefreshState.PENDING);

  // Asynchronous call to Firebase's isSupported() function to determine if firebase messaging is supported.
  useEffect(
    () => {
      let cancelled = false;
      void isSupported()
        .then((supported) => {
          if (cancelled || !firebaseApp || !!firebaseMessaging) {
            return;
          }
          if (!supported) {
            console.debug('Push messaging is not supported.');
            return;
          }
          setFirebaseMessaging(getMessaging(firebaseApp) as MessagingService);
        });

      return () => {
        cancelled = true
      };
    },
    [firebaseApp, setFirebaseMessaging, firebaseMessaging],
  );

  const registerFcmToken = useCallback((fcmToken: string) => {
    if (!deviceToken) {
      return;
    }

    return api.put(
      `/auth/register-push-device/${ deviceToken }`,
      { fcm_token: fcmToken },
      {
        headers: {
          'X-OP-Device-Manufacturer': 'Web',
        },
      },
    )
    .then(() => (
      setFcmTokenRegistration({
        created: {
          at: new Date().toISOString(),
        },
        fcmToken,
      })
    ));
  }, [api, deviceToken, setFcmTokenRegistration]);

  const maybeRegisterFcmToken = useCallback((fcmToken: string) => {
    const tokenMismatch = fcmTokenRegistration?.fcmToken !== fcmToken;
    const registrationExpired = fcmTokenRegistration && isBefore(
      parseISO(fcmTokenRegistration.created.at),
      subHours(new Date(), 1),
    );
    const registrationRequired = tokenMismatch || registrationExpired;

    return registrationRequired
      ? registerFcmToken(fcmToken)
      : Promise.resolve();
  }, [fcmTokenRegistration, registerFcmToken]);

  const obtainPermission = useCallback((onGranted: () => void) => {
    Notification.requestPermission()
      .then((permission) => {
        if (permission !== 'granted') {
          return;
        }

        onGranted();
      })
      .catch(() => { /* Permission denied */ });
  }, []);

  const refreshToken = useCallback(() => {
    if (!firebaseMessaging) {
      return;
    }

    if (Notification.permission !== 'granted') {
      obtainPermission(refreshToken);
      return;
    }

    setRefreshState(RefreshState.REFRESHING);
    getToken(
      firebaseMessaging,
      { vapidKey: process.env.OP_FIREBASE_VAPID_KEY },
    )
      .then(fcmToken => {
        if (fcmToken) {
          return maybeRegisterFcmToken(fcmToken);
        }
      })
      .then(() => {
        setRefreshState(RefreshState.COMPLETE);
      })
      .catch(error => {
        Sentry.captureException(error);
        setRefreshState(RefreshState.COMPLETE);
      })
  }, [maybeRegisterFcmToken, firebaseMessaging, obtainPermission]);

  useEffect(() => {
    if (refreshState !== RefreshState.PENDING) {
      return;
    }

    refreshToken();
  }, [refreshState, refreshToken]);

  const contextValue = useMemo<PushDeviceRegistrationContextValue>(() => ({
    refreshToken,
    firebaseMessaging,
  }), [firebaseMessaging, refreshToken]);

  return (
    <PushDeviceRegistrationContext.Provider
      value={ contextValue }
    >
      { children }
    </PushDeviceRegistrationContext.Provider>
  );
};

