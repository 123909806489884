import { useCallback, useMemo, useState } from 'react';
import axios from 'axios';
import { ApiRequest } from '@ourpeople/shared/Core/Model/ApiRequest';

import { PasswordCheck } from '../Component';
import { useMounted } from '../../Common/Hook';
import { RequestState } from '../../Models';
import { AxiosErrorWithResponse } from '../../Common/Model';

type FailedChecksError = {
  error: 'FAILED_CHECKS';
  checks: PasswordCheck[];
};

type PasswordError = {
  error: 'ACCESS_DENIED' | 'INSUFFICIENT_PERMISSION' | 'UNKNOWN_ERROR';
  checks?: never;
} | FailedChecksError;

type UpdatePasswordRequest = ApiRequest<void, PasswordError>;
type ReturnValue = {
  updatePassword: (
    authToken: string,
    newPassword: string,
  ) => void,
  updatePasswordRequest: UpdatePasswordRequest;
};

export const useUpdatePasswordRequest = (): ReturnValue => {
  const mounted = useMounted();
  const [updatePasswordRequest, setUpdatePasswordRequest] = useState<UpdatePasswordRequest>({
    state: RequestState.PENDING,
    result: null,
  });
  const updatePassword = useCallback((
    authToken: string,
    newPassword: string,
  ) => {
    setUpdatePasswordRequest({
      state: RequestState.FETCHING,
      result: null,
    });
    axios.put<void>(
      '/api/me/password',
      { password: newPassword },
      {
        headers: {
          'Authorization': `Bearer ${ authToken }`,
          'X-Requested-With': 'XMLHttpRequest',
        },
      },
    )
      .then(response => response.data)
      .then(result => {
        if (!mounted.current) {
          return;
        }

        setUpdatePasswordRequest({
          state: RequestState.COMPLETE,
          result,
        });
      })
      .catch((error: AxiosErrorWithResponse<unknown>) => {
        if (!mounted.current) {
          return;
        }

        setUpdatePasswordRequest({
          state: RequestState.FAILED,
          result: getPasswordError(error),
        });
      });
  }, [mounted]);

  return useMemo(() => ({
    updatePassword,
    updatePasswordRequest,
  }), [updatePassword, updatePasswordRequest]);
};

const getPasswordError = (error: AxiosErrorWithResponse<unknown>): PasswordError => {
  if (!error.response.data) {
    return {
      error: 'UNKNOWN_ERROR',
    };
  }

  return (error as AxiosErrorWithResponse<FailedChecksError>).response.data.checks
    ? {
      error: 'FAILED_CHECKS',
      checks: (error as AxiosErrorWithResponse<FailedChecksError>).response.data.checks,
    }
    : {
      error: error.response?.status === 401
        ? 'ACCESS_DENIED'
        : error.response?.status === 403
          ? 'INSUFFICIENT_PERMISSION'
          : 'UNKNOWN_ERROR',
    };
};
