import styled from '@emotion/styled';

import { TableRow, TableCell } from '../../../Components';

export const StyledActiveRow = styled(TableRow, { shouldForwardProp: propName => propName !== 'active' })<{ active: boolean }>`
  ${props => props.active && `background-color: ${props.theme.palette.grey[100]};`}
`;

export const StyledItemName = styled.span<{ $isMobile: boolean }>`
  ${props => props.$isMobile && 'font-weight: 500'}
`;

export const StyledActionCell = styled(TableCell)`
  min-width: 11rem;
  height: 4rem;
`;

