import { Audience } from '../Model';
import { ConditionIdentifier } from './ConditionIdentifier';

export class AudienceReader {
  public static personIds = (audience: Audience): string[] => (
    audience.segments.reduce<string[]>(
      (personIds, segment) => (
        personIds.concat(
          segment.conditions
            .sort((a, b) => a.not ? b.not ? 0 : 1 : -1)
            .reduce<string[]>((personIds, condition) => {
              if (!ConditionIdentifier.conditionIsPersonCondition(condition)) {
                return personIds;
              }

              return condition.not
                ? personIds.filter(personId => !condition.personIds.includes(personId))
                : personIds.length === 0
                  ? condition.personIds.filter(personId => !personIds.includes(personId))
                  : personIds.filter(personId => condition.personIds.includes(personId));
            },
            [],
          ),
        )
      ),
      [],
    )
  );
}
