import { Tooltip } from '@mui/material';
import { FunctionComponent } from 'react';

import AlertIcon from '../../../Assets/img/icons/circle/error.svg';
import { ValidationError } from '../../Model';
import { StyledErrorCell } from './style';
import { ImportErrorPresentationComponent, MappedColumn } from '../../../Imports/Model';

type Props = {
  errors: ValidationError[];
  ErrorComponent: ImportErrorPresentationComponent;
  columnMapping?: MappedColumn;
  value?: string;
  minwidth?: string;
  maxwidth?: string;
}

export const ErrorCell: FunctionComponent<Props> = ({
  errors,
  ErrorComponent,
  columnMapping,
  value,
  minwidth = '25rem',
  maxwidth = '25rem',
  children,
}) => (
  <StyledErrorCell
    $error={ !!errors.length }
    minwidth={ minwidth }
    maxwidth={ maxwidth }
  >
    <div>
      { !!errors.length && (
        <Tooltip title={
          <ErrorComponent
            errors={ errors }
            columnMapping={ columnMapping }
            value={ value }
          />
        }>
          <AlertIcon role="presentation"/>
        </Tooltip>
      ) }
      { children }
    </div>
  </StyledErrorCell>
);
