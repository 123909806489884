import styled from '@emotion/styled';
import { Backdrop, Popover } from '@mui/material';
import { Button } from 'op-storybook/stories/components/Button/Button';

export const StyledPopover = styled(Popover)<{ align: 'center' | 'flex-start' }>`
  pointer-events: none;

  .MuiPaper-root {
    box-shadow: none;
    background: transparent;
    border: none;
    overflow: visible;
    justify-content: center;
    margin: ${ props => props.theme.spacing(1) } 0;
    display: flex;
    flex-direction: row;
    align-items: ${ props => props.align };
    height: 460px;
    pointer-events: all;
    text-align: initial;
  }
`;

export const StyledMenuSlide = styled.div<{
  maxWidth?: number;
  anchor: 'left' | 'center' | 'right';
}>`
  border-radius: ${ props => props.theme.borderRadius.sm };
  border: 1px solid ${ props => props.theme.palette.grey[300] };
  ${ props => props.maxWidth && `
    max-width: calc(100vw - ${ props.theme.spacing(4) });
    width: ${ props.maxWidth }px;
  ` }
  ${ props => props.theme.breakpoints.down('md') } {
    width: calc(100vw - ${ props => props.theme.spacing(4) });
    max-width: 500px;
  }
  position: absolute;
  ${ props => props.anchor === 'right' && `
    right: ${ props.theme.spacing(-0.5) };
  ` }
  ${ props => props.anchor === 'left' && `
    left: ${ props.theme.spacing(-0.5) };
  ` }
  overflow: hidden;
  background-color: ${ props => props.theme.palette.background.paper };
`;

export const StyledBackdrop = styled(Backdrop)`
  z-index: 1300; // Display at same index as popover container
`;
