import { FunctionComponent, useMemo } from 'react';
import { Button } from '@mui/material';

import { Content, ExternalTextContentLink, TextContent } from '../../../../../Models';
import { StyledTextContentPreview } from './styles';
import { SafeRichTextContent } from '../../../../../Common/Component/SafeRichTextContent/SafeRichTextContent';
interface Props {
  content: Content;
}

export const TextContentPreview: FunctionComponent<Props> = ({
  content
}) => {
  const placeHolderStrippedText = useMemo(() => {
    if (!!(content as TextContent).link) {
      return (content.text || '').replace(
        (content as TextContent).link?.placeholderText || '',
        ''
      );
    }
    return content.text;
  }, [content]);

  return (
    <div>
      <StyledTextContentPreview>
        <SafeRichTextContent
          text={placeHolderStrippedText || ''}
          placeholderBehaviour="badge"
        />
      </StyledTextContentPreview>
      {
        (content as TextContent).link
        && ((content as TextContent).link as ExternalTextContentLink).type === 'url'
        && (
          <Button
            color="primary"
            disableElevation={true}
            variant="contained"
            href={((content as TextContent).link as ExternalTextContentLink).url}
          >{((content as TextContent).link as ExternalTextContentLink).label}</Button>
        )
      }
    </div>
  );
};
