import { FC, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { GeolocationRequest } from '../../Content';
import { LiveComponentProps } from '../../../../Content/Model';
import { DraftGeolocationResponse } from '../../../Service';
import { GeolocationRequest as GeolocationRequestModel } from '../../../Model/ContentTypes';
import { useMounted } from '../../../../Common/Hook';
import { useContextOrThrow } from '../../../../Core/Hook';
import { FormNavigationContext } from '../../../Provider/FormNavigationProvider';
import { ToastContext } from '../../../../Core/Context';

export const LiveGeolocationRequest: FC<LiveComponentProps<GeolocationRequestModel, DraftGeolocationResponse>> = ({
  card,
  response,
  onResponseChange,
  onResponseClear,
  onValidationChange,
  validation,
}) => {
  const intl = useIntl();
  const { displayingValidation } = useContextOrThrow(FormNavigationContext);
  const mounted = useMounted();
  const { addErrorToast } = useContextOrThrow(ToastContext);
  const [obtainingLocation, setObtainingLocation] = useState<boolean>(false);
  const whenGeolocationChanged = useCallback((position: GeolocationPosition | undefined) => {
    if (position === undefined) {
      onResponseClear();
      onValidationChange({
        errors: [],
        children: {},
      });
      return;
    }

    const response: DraftGeolocationResponse = {
      contentId: card.content.id,
      contentType: 'geolocationRequest',
      longitude: position.coords.longitude,
      latitude: position.coords.latitude,
    };

    onResponseChange(response);
    onValidationChange({
      errors: [],
      children: {},
    });
  }, [card, onResponseChange, onResponseClear, onValidationChange]);

  const whenInputClicked = useCallback(() => {
    setObtainingLocation(true);
    navigator.geolocation.getCurrentPosition(
      position => {
        if (!mounted.current) {
          return;
        }

        setObtainingLocation(false);
        whenGeolocationChanged(position);
      },
      positionError => {
        if (!mounted.current) {
          return;
        }

        setObtainingLocation(false);
        addErrorToast(
          positionError.code === positionError.PERMISSION_DENIED
            ? intl.formatMessage({
              description: 'Geolocation form card error message when failed to obtain location due to being denied by the user.',
              defaultMessage: 'Location could not be obtained. Make sure location is enabled in your browser settings.'
            })
            : intl.formatMessage({
              description: 'General geolocation form card error message when location could not be obtained.',
              defaultMessage: 'Location could not be obtained.',
            })
        );
      },
    );
  }, [addErrorToast, intl, mounted, whenGeolocationChanged]);

  return (
    <GeolocationRequest
      value={ response ? `${ response.latitude }, ${ response.longitude}` : '' }
      onInputClick={ whenInputClicked }
      busy={ obtainingLocation }
      onClear={ () => whenGeolocationChanged(undefined) }
      validation={ validation }
      displayValidation={ displayingValidation }
    />
  );
};
