import { ValidationTree } from '../model/Validation/ValidationTree';
import { ValidationError } from '../model/Validation/ValidationError';

export const getDescendantErrors = <T>(validationTree: ValidationTree<T>): ValidationError[] => ([
  ...validationTree.errors,
  ...Object.values(validationTree.children).reduce<ValidationError[]>(
    (childErrors, child) => {
      return childErrors.concat(child ? getDescendantErrors(child as ValidationTree<unknown>) : [])
    },
    [],
  ),
]);
