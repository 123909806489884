import { useMemo } from 'react';

import { ValidationError } from '../../model/Validation/ValidationError';
import { LocalisedString } from '../../model/LocalisedString/LocalisedString';
import {
    ValidationErrorHandler,
    ValidationErrorHandlerFactory
} from '../../utility/ValidationErrorHandlerFactory/ValidationErrorHandlerFactory';

export const useLocalisedValidationErrors = (
  handlers: ValidationErrorHandler<any>[] = [], // eslint-disable-line @typescript-eslint/no-explicit-any
  errors: ValidationError[],
): LocalisedString[] => {
  return useMemo(
    () => errors.map(error => {
      const handler = handlers.find(handler => handler.assert(error))
          ?? ValidationErrorHandlerFactory.defaultHandler();
      return {
        id: error.type,
        localisation: handler.handle(error),
      };
    }),
    [handlers, errors],
  );
};
