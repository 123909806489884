import { ComponentProps, FC, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Username } from 'op-storybook/stories/components/UsernameField/UsernameField';

type Props = {
  returnToLinkProps?: ComponentProps<typeof Link>;
  username?: Username;
};

export const ForgotPasswordLink: FC<Props> = ({
  returnToLinkProps,
  username,
}) => {
  const locationDescriptor = useMemo(() => ({
    pathname: '/forgot-password',
    state: {
      returnToLinkProps,
      username,
    },
  }), [returnToLinkProps, username]);

  return (
    <Link to={ locationDescriptor }>
      <FormattedMessage
        description="Link to forgot password page from login page."
        defaultMessage="Forgot password"
      />
    </Link>
  );
};
