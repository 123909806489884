import { FunctionComponent } from 'react';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import { isThisYear, parseISO } from 'date-fns';

type DateTimeProps = {
  dateTime?: string | Date | null;
  dateOnly?: boolean;
  timezone?: string;
}

export const DateTime: FunctionComponent<DateTimeProps> = ({
  dateTime,
  dateOnly = false,
  timezone,
}) => {
  const date = dateTime
    ? dateTime instanceof Date
      ? dateTime
      : parseISO(dateTime)
    : null;
  const options = {
    month: 'short',
    day: '2-digit',
    weekday: 'short',
    ...(!date || !isThisYear(date) ? {
        year: 'numeric'
      } : {}),
  } as Intl.DateTimeFormatOptions;

  const localisedDate = date
    ? (
      dateOnly
        ? (
          <FormattedDate
            value={ date }
            { ...options }
          />
        )
        : (
          <FormattedTime
            value={ date }
            { ...options }
          />
        )
    )
    : null;

  return localisedDate
    ? timezone
      ? (
        <FormattedMessage
          id="formatting.dateWithTimezone"
          defaultMessage="{date} ({timezone})"
          values={ {
            date: localisedDate,
            timezone: timezone,
          } }
        />
      )
      : localisedDate
    : (
      <FormattedMessage
        id="date.never"
        defaultMessage="Never"
      />
    );
};
