import { FC } from 'react';

import { StyledOverlay } from './style';

type BackdropProps = {
  onClick?: () => void;
};

type Props = BackdropProps & {
  open: boolean;
  Component?: FC<BackdropProps>;
  className?: string;
};

export const Overlay: FC<Props> = ({
  open,
  onClick,
  className,
  children,
}) => (
  <StyledOverlay
    open={ open }
    onClick={ onClick }
    className={ className }
  >
    { children }
  </StyledOverlay>
);
