import { Dispatch, FC, SetStateAction, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Heading } from '@ourpeople/shared/Core/Component/Content';
import { ApiRequest } from '@ourpeople/shared/Core/Model/ApiRequest';

import { SchedulingSettings } from '../SchedulingSettings/SchedulingSettings';
import {
  BroadcastNotificationSettings,
  DraftBroadcast,
  MinimalNotificationChannelSettings,
} from '../../Model';
import { ValidationTree } from '../../../Common/Model';
import { VerticallySpaced } from '../../../Common/Component';
import { NudgeInput } from '../NudgeInput/NudgeInput';
import { PushInput } from '../PushInput/PushInput';
import { SmsInput } from '../SmsInput/SmsInput';
import { EmailInput } from '../EmailInput/EmailInput';
import { Schedule } from '../../Model/BroadcastSchedule';
import { SmsPreview } from '../../Model/SmsPreview';
import { FetchCalculatedRecipientsResponse } from '../BroadcastEditForm/BroadcastEditForm';
import { RequestState } from '../../../Models';

type Props = {
  broadcast: DraftBroadcast;
  setBroadcast: Dispatch<SetStateAction<DraftBroadcast>>;
  validation?: ValidationTree<DraftBroadcast>;
  setValidation: Dispatch<SetStateAction<ValidationTree<DraftBroadcast>>>;
  containsEvent: boolean;
  containsCover: boolean;
  showNudgeEventWarning: boolean;
  fetchCalculatedRecipientsResponse: FetchCalculatedRecipientsResponse;
  schedule: Schedule;
  setSchedule: Dispatch<SetStateAction<Schedule>>;
  smsPreviewRequest: ApiRequest<SmsPreview>;
  onRetrySmsPreviewRequestClicked: () => void;
  valid: boolean;
};

export const DeliveryForm: FC<Props> = ({
  broadcast,
  setBroadcast,
  validation,
  setValidation,
  containsEvent,
  containsCover,
  showNudgeEventWarning,
  fetchCalculatedRecipientsResponse,
  schedule,
  setSchedule,
  smsPreviewRequest,
  onRetrySmsPreviewRequestClicked,
  valid,
}) => {
  const whenNudgeDelayChanged = useCallback((nudgeDelay: number | null) => (
    setBroadcast(broadcast => ({
      ...broadcast,
      sendNudge: nudgeDelay !== null,
      nudgeDelay,
    }))
  ), [setBroadcast]);

  const whenNotificationSettingsChanged = useCallback((setNotification: (prev: BroadcastNotificationSettings) => BroadcastNotificationSettings) => (
    setBroadcast(broadcast => ({
      ...broadcast,
      notification: setNotification(broadcast.notification),
    }))
  ), [setBroadcast]);

  const whenPushSettingsChanged = useCallback((push: MinimalNotificationChannelSettings) => (
    whenNotificationSettingsChanged(notification => ({
      ...notification,
      push,
    }))
  ), [whenNotificationSettingsChanged]);

  const whenSmsSettingsChanged = useCallback((sms: BroadcastNotificationSettings['sms']) => (
    whenNotificationSettingsChanged(notification => ({
      ...notification,
      sms,
    }))
  ), [whenNotificationSettingsChanged]);

  const whenEmailSettingsChanged = useCallback((email: MinimalNotificationChannelSettings) => (
    whenNotificationSettingsChanged(notification => ({
      ...notification,
      email,
    }))
  ), [whenNotificationSettingsChanged]);

  return (
    <VerticallySpaced gap={ 2 }>
      <Heading>
        <FormattedMessage
          description="Heading for broadcast delivery settings."
          defaultMessage="Delivery settings"
        />
      </Heading>
      <SchedulingSettings
        recipientCount={
          fetchCalculatedRecipientsResponse[0]?.content
            ? fetchCalculatedRecipientsResponse[0].content.counts.total
            : null
        }
        validation={ validation }
        setValidation={ setValidation }
        schedule={ schedule }
        setSchedule={ setSchedule }
        containsEvent={ containsEvent }
      />
      <NudgeInput
        nudgeDelay={ broadcast.nudgeDelay || null }
        onChange={ whenNudgeDelayChanged }
        containsCover={ containsCover }
        showNudgeEventWarning={ showNudgeEventWarning }
      />
      <PushInput
        notificationSettings={ broadcast.notification.push }
        onChange={ whenPushSettingsChanged }
      />
      <EmailInput
        notificationSettings={ broadcast.notification.email }
        onChange={ whenEmailSettingsChanged }
      />
      <SmsInput
        scheduled={ !!(broadcast.scheduleFor || broadcast.recurrenceRule) }
        notificationSettings={ broadcast.notification.sms }
        onChange={ whenSmsSettingsChanged }
        smsPreviewRequest={ smsPreviewRequest }
        onRetrySmsPreviewRequestClicked={ onRetrySmsPreviewRequestClicked }
        valid={ valid }
        template={ smsPreviewRequest.state === RequestState.COMPLETE ? smsPreviewRequest.result.sms.template : '' }
        placeholders={ smsPreviewRequest.state === RequestState.COMPLETE ? smsPreviewRequest.result.sms.placeholders : {} }
      />
    </VerticallySpaced>
  );
};
