import styled from '@emotion/styled';

export const StyledChannelEngagementChart = styled.div`
  display: grid;
  grid-template-rows: auto auto 1fr;

  >div:last-child {
    max-width: 100%;
    min-height: 300px;
    overflow: hidden;
  }
`;
