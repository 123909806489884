import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';

import { BasicAutocomplete, BasicAutocompleteProps } from '../../../Common/Component';
import { LocalisedString } from '../../../Common/Model';

export type ImportErrorStatus = 'noWith' | 'noWithout'

type Props = Omit<BasicAutocompleteProps<ImportErrorStatus>, 'options'>;

export const ErrorStatusAutocomplete: FunctionComponent<Props> = props => {
  const intl = useIntl();
  const options: LocalisedString<ImportErrorStatus>[] = [
    {
      id: 'noWithout',
      localisation: intl.formatMessage({
        id: 'import.errorStatusAutocomplete.noWithout',
        description: 'Label for records only with errors in import error status autocomplete',
        defaultMessage: 'Only rows with errors',
      }),
    },
    {
      id: 'noWith',
      localisation: intl.formatMessage({
        id: 'import.errorStatusAutocomplete.noWith',
        description: 'Label for records only without errors in import error status autocomplete',
        defaultMessage: 'Only rows without errors',
      }),
    },
  ];

  return (
    <BasicAutocomplete
      options={ options }
      label={ intl.formatMessage({
        id: 'import.errorStatusAutocomplete.label',
        description: 'Label for import error status autocomplete.',
        defaultMessage: 'Error status',
      }) }
      { ...props }
    />
  );
};
