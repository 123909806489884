import { FC } from 'react';
import { CircularProgress } from '@mui/material';

import { StyledLoadingFacade } from './style';

type Props = {
  className?: string;
};

export const LoadingFacade: FC<Props> = ({
  className,
}) => (
  <StyledLoadingFacade className={ className }>
    <CircularProgress
      size={ 24 }
      color="secondary"
    />
  </StyledLoadingFacade>
);
