import { ChangeEventHandler, FunctionComponent } from 'react';
import { IconButton, TextField } from '@mui/material';
import { useIntl } from 'react-intl';

import { DraftChoiceOption } from '../../../Model/ContentTypes';
import { ValidationTree } from '../../../../Common/Model';
import { FieldValidationErrors } from '../../../../Common/Component';
import DeleteIcon from '../../../../Assets/img/icons/monochrome/trash.svg';
import { InputRow } from './style';
import { ChoiceOptionValidator } from '../../../Utility/Validation';

interface Props {
  index: number;
  option: DraftChoiceOption;
  validation?: ValidationTree<DraftChoiceOption>;
  onValidationChanged: (validation: ValidationTree<DraftChoiceOption>) => void;
  onOptionChanged: (index: number, option: DraftChoiceOption) => void;
  onOptionDeleted: (index: number) => void;
  deleteDisabled: boolean;
}

export const ChoiceRequestOptionField: FunctionComponent<Props> = ({
  index,
  option,
  validation,
  onOptionChanged,
  onOptionDeleted,
  deleteDisabled,
  onValidationChanged,
}) => {
  const intl = useIntl();
  const titleErrors = validation?.children.title?.errors || [];

  const whenTitleChanged: ChangeEventHandler<HTMLInputElement> = (event) => {
    onOptionChanged(
      index,
      {
        ...option,
        title: event.currentTarget.value,
      },
    );
  };

  const whenTitleBlurred = (): void => onValidationChanged({
    errors: validation?.errors || [],
    children: {
      ...validation?.children,
      title: ChoiceOptionValidator.validateTitle(option),
    },
  });

  return <>
    <InputRow>
      <TextField
        id={ `option_${ index }` }
        label={ intl.formatMessage({
          id: 'form.fields.shared.option.label',
          defaultMessage: 'Option {index} *',
        }, {
          index: index + 1,
        }) }
        value={ option.title }
        onChange={ whenTitleChanged }
        onBlur={ whenTitleBlurred }
        error={ !!titleErrors.length }
        fullWidth
      />
      <IconButton
        onClick={() => onOptionDeleted(index)}
        disabled={ deleteDisabled }
        size="large">
        <DeleteIcon />
      </IconButton>
    </InputRow>
    <FieldValidationErrors
      fieldName={ intl.formatMessage({
        id: 'form.fields.shared.option.name',
        defaultMessage: 'option {index}',
      }, {
        index: index + 1,
      }) }
      validationErrors={ titleErrors }
    />
  </>;
};
