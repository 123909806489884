import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { ContentPreviewProps } from '../../../../Content/Model';
import { RatingEditorContent } from '../../../Service';
import { CardTitle, ContentWithType, ScrollableContent } from '..';
import { VerticallySpaced } from '../../../../Common/Component';
import { StarRating } from '../../../../Content/Component';

export const RatingRequestPreview: FC<ContentPreviewProps<RatingEditorContent>> = ({
  editorContent,
  onReady,
}) => {
  const intl = useIntl();
  const [value, setValue] = useState<number | null>(null);

  useEffect(() => {
    onReady();
  }, [onReady]);

  return (
    <ScrollableContent>
      <ContentWithType
        localisedContentType={ intl.formatMessage({
          id: 'broadcasts.content.preview.rating',
          description: 'Label for rating content type used in preview.',
          defaultMessage: 'Rating',
        }) }
      >
        <VerticallySpaced gap={ 2 }>
          <CardTitle>{ editorContent.card.content.title }</CardTitle>
          <StarRating
            maxRating={ 5 }
            value={ value }
            onValueChanged={ setValue }
          />
        </VerticallySpaced>
      </ContentWithType>
    </ScrollableContent>
  );
}
