import { FunctionComponent, ReactNode, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { createLocalStorageStateHook } from 'use-local-storage-state';

import { Notices } from '../../../Common/Model';
import { Notice } from '../../../Common/Component';
import { LicenceInformation } from '..';
import { AuthDescription } from '../../../Models';
import { useLoggedInAuthDescription } from '../../../Core/Hook';
import { LicenceCounts } from '../../Hook';

type Props = {
  notices?: Notices;
  licenceCounts?: LicenceCounts;
}

interface UserState {
  noProfileCount?: number;
  noProfileChecksum?: string;
  noProfileDate?: null | string;
  noProfileShowFlash?: boolean;
  noProfileShowBanner?: boolean;
}

export const PeoplePageAlerts: FunctionComponent<Props> = ({
  notices,
  licenceCounts,
}) => {
  const authDescription: AuthDescription = useLoggedInAuthDescription();
  const useUserState = createLocalStorageStateHook<UserState>(`ngStorage-${ authDescription.user.id }`, {});
  const [userState, setUserState] = useUserState();
  const promptAddTeam = useMemo(() => {
    if (!notices?.peopleSyncDecisions?.counts) {
      return false;
    }

    const counts = notices.peopleSyncDecisions.counts;

    return counts.nonPendingDecisions < 10
      || (counts.nonPendingDecisions / counts.pendingDecisions) < 0.1;
  }, [notices?.peopleSyncDecisions?.counts]);

  const whenDismissNoProfileBannerButtonClicked = () => {
    setUserState(previous => ({
      ...(previous || {}),
      noProfileShowBanner: false,
    }));
  };

  return (
    <>
      {
        licenceCounts?.limit && (
          <LicenceInformation
            limit={ licenceCounts.limit }
            live={ licenceCounts.live }
          />
        )
      }
      { !!userState.noProfileShowBanner && (
        <Notice
          variant="outlined"
          feedback={ {
            severity: 'error',
            message: (
              <div>
                <h4>
                  <FormattedMessage
                    id="notices.missingProfiles.header"
                    defaultMessage="Some people don't have profiles"
                  />
                </h4>
                <p>
                  <FormattedMessage
                    id="notices.missingProfiles.body"
                    defaultMessage="We've highlighted them with this icon. You can also use the filter to show people without a profile."
                  />
                </p>
              </div>
            ),
          } }
          onDismiss={ whenDismissNoProfileBannerButtonClicked }
        />
      ) }
      {
        promptAddTeam && (
          <Notice
            variant="outlined"
            feedback={{
              severity: 'error',
              message: (
                <FormattedMessage
                  id="people.addTeamPrompt"
                  description="Prompt displayed when user hasn't added their team."
                  defaultMessage="Don't forget to add your team. <a>Manage third party import</a>"
                  values={ {
                    a: (chunks: (string | ReactNode)[]) => (
                      <Link to="/people/third-party-import">{ chunks }</Link>
                    )
                  } }
                />
              )
            }}
          />
        )
      }
      {
        !promptAddTeam && !!notices?.peopleSyncDecisions && notices.peopleSyncDecisions.counts.pendingDecisions > 0 && (
          <Notice
            variant="outlined"
            feedback={{
              severity: 'error',
              message: (
                <FormattedMessage
                  id="notices.pendingDecisions.body"
                  defaultMessage="<b>{pendingCount}</b> {pendingCount, plural, one {person} other {people}} pending confirmation to access OurPeople via your third party integration. <a>Manage third party import</a>"
                  values={ {
                    pendingCount: notices.peopleSyncDecisions.counts.pendingDecisions,
                    a: (chunks: (string | ReactNode)[]) => (
                      <Link to="/people/third-party-import">{ chunks }</Link>
                    )
                  } }
                />
              )
            }}
          />
        )
      }
    </>
  )
}
