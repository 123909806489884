import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import {
  AutocompleteCommonProps,
  AutocompleteFetchRequest,
  AutocompleteSelectionChanged
} from '@ourpeople/shared/Core/Component/Input/Autocomplete/Autocomplete';

import { ApiContext } from '../../../Contexts';
import { Paginated } from '../../../Models';
import { Tag, TagType } from '../../Model';
import { TagParser } from '../../Utility';
import { FetchTagsQuery } from '../../Hook';
import { useContextOrThrow } from '../../../Core/Hook';
import { DebouncedAutocomplete } from '../../../Common/Component';

interface Props<T extends TagType> extends AutocompleteCommonProps {
  type: T,
  prefetchedTags?: Tag<T>[],
  selectedIds: string[];
  allowedIds?: string[];
  showDisallowed?: boolean;
  onSelectionChanged: AutocompleteSelectionChanged<Tag<T>>;
  baseQuery?: FetchTagsQuery,
}

export const TagAutocomplete = <T extends TagType>({
  type,
  selectedIds,
  prefetchedTags,
  allowedIds,
  showDisallowed = false,
  onSelectionChanged,
  baseQuery,
  ...props
}: Props<T>): JSX.Element => {
  const intl = useIntl();
  const api = useContextOrThrow(ApiContext);
  const readonlyIds = useMemo(() => (
    showDisallowed
      ? selectedIds.filter(selectedId => !(allowedIds || []).includes(selectedId))
      : []
  ), [allowedIds, showDisallowed, selectedIds]);

  const whenFetch: AutocompleteFetchRequest<Tag<T>> = useCallback(async (
    ids: string[],
    search: string,
    pageNum: number,
  ) => {
    if (allowedIds !== undefined) {
      const permittedIds = allowedIds.concat(readonlyIds);
      ids = ids.length > 0
        ? permittedIds.filter((id) => ids.indexOf(id) > -1)
        : permittedIds;
      if (ids.length < 1) {
        return {
          options: [],
          pageCount: 0,
        };
      }
    }

    const params: FetchTagsQuery = {
      sort: 'name_asc,id_asc',
      ...baseQuery,
      type,
      pageNum,
      ids: ids.join(',') || undefined,
      search: search.trim() || undefined,
    };
    const response = await api.get<Paginated<'tags', Tag<T>>>('/tags', { params });
    return {
      options: response.data.tags,
      pageCount: response.data.pagination.pageCount,
    };
  }, [allowedIds, readonlyIds, api, baseQuery, type]);

  const whenSelectionChanged: AutocompleteSelectionChanged<Tag<T>> = useCallback((selection) => {
    onSelectionChanged({
      ...selection,
      options: selection.options.filter((tag) => tag.type === type),
    });
  }, [onSelectionChanged, type]);

  return <DebouncedAutocomplete<Tag<T>>
    { ...props }
    getOptionLabel={ (tag) => tag.name }
    fetchOptions={ whenFetch }
    selectedIds={ selectedIds }
    prefetchedOptions={ prefetchedTags }
    onSelectionChanged={ whenSelectionChanged }
    resetToken={ type }
    readOnlyIds={ readonlyIds }
    label={
      props.label === undefined
        ? intl.formatMessage(
          {
            id: 'tagAutocomplete.label',
            defaultMessage: 'Select {tagName}',
          },
          {
            tagName: TagParser.localisedTagTypeFromInternalTagType(
              type,
              intl,
              props.multiple ? Infinity : 1,
            ),
          },
        )
        : props.label
    }
    placeholder={
      props.placeholder ||
      intl.formatMessage(
        {
          id: 'tagAutocomplete.placeholder',
          defaultMessage: 'Search for {tagName}',
        },
        {
          tagName: TagParser.localisedTagTypeFromInternalTagType(
            type,
            intl,
            props.multiple ? Infinity : 1,
          ),
        },
      )
    }
  />;
};
