import { ComponentProps, JSX, useCallback, useEffect, useMemo, useState } from 'react';

import { Tag, TagType } from '../../../Tags/Model';
import { TagCondition } from '../../../Audiences/Model';
import { Paginated } from '../../../Models';
import { useContextOrThrow } from '../../../Core/Hook';
import { ApiContext } from '../../../Contexts';
import { AsyncBadgeCloud } from './AsyncBadgeCloud';
import { RoleId } from '../../../Security/Model';
import { BadgeCloud } from './BadgeCloud';
import { InvalidConditionError } from './InvalidConditionError';

type Props<T extends TagType> = {
  tagType: T;
  condition: TagCondition<T>;
  onChange?: (condition: TagCondition<T>) => void;
};

export const TagCloud = <T extends TagType>({
  tagType,
  condition,
  onChange,
}: Props<T>): JSX.Element => {
  const api = useContextOrThrow(ApiContext);
  const filters = useMemo(() => ({
    ids: condition.tagIds.join(','),
    sort: 'name_asc,id_asc',
  }), [condition.tagIds]);
  const [missingOnlyTag, setMissingOnlyTag] = useState<boolean>();

  const queryFn = useCallback<ComponentProps<typeof AsyncBadgeCloud>['queryFn']>(async ({ queryKey: [_key, filters] }) => {
    const response = await api.get<Paginated<'tags', Tag<T>>>('/tags', { params: filters });

    return {
      pagination: response.data.pagination,
      items: response.data.tags.map(tag => ({
        id: tag.id,
        label: tag.name,
      })),
    };
  }, [api]);

  const whenRemoveClicked = useCallback<NonNullable<ComponentProps<typeof BadgeCloud>['onRemoveClicked']>>(badgeProps => {
    onChange && onChange({
      ...condition,
      tagIds: condition.tagIds.filter(tagId => tagId !== badgeProps.id as RoleId),
    });
  }, [condition, onChange]);

  useEffect(() => {
    setMissingOnlyTag(false);
  }, [condition]);

  return (
    missingOnlyTag
      ? <InvalidConditionError/>
      : (
        <AsyncBadgeCloud
          queryFn={ queryFn }
          queryKey={ [tagType, filters] }
          onIdsMissing={ () => {
            if (condition.tagIds.length > 1) {
              return;
            }

            setMissingOnlyTag(true);
          } }
          { ...onChange ? { onRemoveClicked: whenRemoveClicked } : {} }
        />
      )
  );
};
