import { FunctionComponent, MouseEventHandler, useEffect, useRef, useState } from 'react';

import ExpandIcon from '../../../Assets/img/icons/monochrome/expand.svg';
import ContractIcon from '../../../Assets/img/icons/monochrome/contract.svg';
import { StyledTruncatingContent, FloatButton, ResponseWrapper } from './style';

export type TruncatingContentProps = {
  className?: string;
  minWidth?: number;
}

export const TruncatingContent: FunctionComponent<TruncatingContentProps> = ({
  className,
  minWidth,
  children,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [truncateContent, setTruncateContent] = useState<boolean>(true);
  const [contentRequiresTruncation, setContentRequiresTruncation] = useState<boolean>(true);
  const [contentHasBeenTruncated, setContentHasBeenTruncated] = useState<boolean>(false);

  useEffect(() => {
    if (truncateContent) {
      setContentRequiresTruncation(contentHasBeenTruncated)
    }
  }, [contentHasBeenTruncated, truncateContent]);

  const updateElementTruncated = () => {
    const wrapper = wrapperRef.current;
    if (!wrapper) {
      return;
    }
    setContentHasBeenTruncated(wrapper.scrollHeight > wrapper.clientHeight);
  };

  const whenExpandButtonClicked: MouseEventHandler = event => {
    event.stopPropagation();
    setTruncateContent(!truncateContent);
  };

  useEffect(updateElementTruncated,[truncateContent]);

  return (
    <StyledTruncatingContent
      className={ className }
      minWidth={ minWidth }
    >
      {
        contentRequiresTruncation && (
          <FloatButton
            color="secondary"
            onClick={ whenExpandButtonClicked }
          >
            {
              truncateContent
                ? <ExpandIcon />
                : <ContractIcon />
            }
          </FloatButton>
        )
      }
      <ResponseWrapper
        truncate={ truncateContent }
        ref={ wrapperRef }
      >
        { children }
      </ResponseWrapper>
    </StyledTruncatingContent>
  );
};
