export enum SmsPermission {
  ALL = 'sms',
  CREDITS = 'sms.credits',
  CREDITS_CREATE = 'sms.credits.create',
  CREDITS_UPDATE = 'sms.credits.update',
  CREDITS_REPORT = 'sms.credits.report',
  METRICS = 'sms.metrics',
  REPORT = 'sms.report',
  SEND = 'sms.send',
}
