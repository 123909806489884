import { FC, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { SectionHeader } from '@ourpeople/shared/Core/Component/Content/SectionHeader/SectionHeader';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { useDateTimeFormatter } from '@ourpeople/shared/Core/Hook/useDateFormatter';
import { parseISO } from 'date-fns';
import { ApiRequest } from '@ourpeople/shared/Core/Model/ApiRequest';

import { useContextOrThrow } from '../../../Core/Hook';
import { ApiContext } from '../../../Contexts';
import { UnlimitedSmsUsage, UsagePeriod as UsagePeriodModel } from '../../Model/UsagePeriod';
import { RequestState } from '../../../Models';
import { UsagePeriod } from '../../Component/UsagePeriod/UsagePeriod';
import { Usage } from '../../Component/Usage/Usage';
import { ResponsiveGrid } from '../../../People/Component/ResponsiveGrid/ResponsiveGrid';

interface UnlimitedSmsUsageResult {
  mode: 'unlimited';
  unlimited: {
    enabled: boolean;
    softLimit: number;
    hardLimit: number;
    remainingCreditCount: number;
    softLimitReached: boolean;
    hardLimitReached: boolean;
    allowance: {
      period: UsagePeriodModel;
      projectedUsage: UnlimitedSmsUsage;
    };
    recentPeriods: UsagePeriodModel[];
  };
  purchased: never;
}

interface PurchasedSmsUsageResult {
  mode: 'purchased';
  purchased: {
    allowance: {
      lowCreditLimit: number;
      lowCreditLimitReached: boolean;
      remainingCreditCount: number;
      usage: {
        spentCreditCount: number;
      };
    };
  };
  unlimited: never;
}

type SmsUsageResult = UnlimitedSmsUsageResult | PurchasedSmsUsageResult;

export const SmsUsagePage: FC = () => {
  const api = useContextOrThrow(ApiContext);
  const intl = useIntl();
  const { minimalDate } = useDateTimeFormatter();
  const [smsUsageRequest, setSmsUsageRequest] = useState<ApiRequest<UnlimitedSmsUsageResult>>({
    result: null,
    state: RequestState.PENDING,
  });

  const fetchSmsUsage = useCallback(() => {
    api.get<SmsUsageResult>('/sms/usage')
      .then(response => response.data)
      .then(result => {
        if (result.mode === 'purchased') {
          throw new Error('Expected unlimited SMS usage plan.');
        }

        setSmsUsageRequest({
          state: RequestState.COMPLETE,
          result,
        });
      })
      .catch((error) => {
        setSmsUsageRequest({
          state: RequestState.FAILED,
          result: error,
        });
      });
  }, [api]);

  useEffect(() => {
    fetchSmsUsage();
  }, [fetchSmsUsage]);

  return (
    <div>
      { smsUsageRequest.state === RequestState.COMPLETE && (
        <Stack direction="column" gap={ 4 }>
          <SectionHeader
            text={ intl.formatMessage({
              description: 'Heading for section containing current and predicted SMS usage in unlimited SMS reporting page.',
              defaultMessage: 'Current period',
            }) }
            supportingText={ intl.formatMessage({
              description: 'Date range for current period used in heading in unlimited SMS reporting page.',
              defaultMessage: '{ startDate } to { endDate }',
            }, {
              startDate: minimalDate(smsUsageRequest.result.unlimited.allowance.period.startAt),
              endDate: minimalDate(smsUsageRequest.result.unlimited.allowance.period.endAt),
            }) }
          />
          <ResponsiveGrid
            desktopColumns={ 3 }
            css={ { maxWidth: '1400px' } }
          >
            <Usage
              heading={ intl.formatMessage({
                description: 'Label for current period usage stats in unlimited credit usage report.',
                defaultMessage: 'To date',
              }) }
              usage={ smsUsageRequest.result.unlimited.allowance.period.usage }
            />
            <Usage
              heading={ intl.formatMessage({
                description: 'Label for projected usage stats in unlimited credit usage report.',
                defaultMessage: 'Projected',
              }) }
              usage={ smsUsageRequest.result.unlimited.allowance.projectedUsage }
              previousUsage={ smsUsageRequest.result.unlimited.recentPeriods[0]?.usage }
            />
          </ResponsiveGrid>
          <SectionHeader
            text="Previous periods"
            supportingText={ intl.formatMessage({
              defaultMessage: '{ startMonth } to { endMonth }',
              description: 'Range of previous SMS usage periods used in heading in unlimited credit usage report.',
            }, {
              startMonth: parseISO(smsUsageRequest.result.unlimited.recentPeriods.slice(-1)[0].startAt).toLocaleString(
                intl.locale,
                { month: 'long', year: 'numeric' },
              ),
              endMonth: parseISO(smsUsageRequest.result.unlimited.recentPeriods[0].endAt).toLocaleString(
                intl.locale,
                { month: 'long', year: 'numeric' },
              ),
            }) }
          />
          <ResponsiveGrid
            desktopColumns={ 3 }
            css={ { maxWidth: '1400px' } }
          >
            { smsUsageRequest.result.unlimited.recentPeriods.map((period, index) => (
              <UsagePeriod
                usagePeriod={ period }
                previousPeriod={ smsUsageRequest.result.unlimited.recentPeriods[index + 1] }
              />
            )) }
          </ResponsiveGrid>
        </Stack>
      ) }
    </div>
  );
};
