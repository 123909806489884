import { FC, useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AutocompleteSelectionChanged } from '@ourpeople/shared/Core/Component/Input/Autocomplete/Autocomplete';
import { LocalisedString } from 'op-storybook/lib/model/LocalisedString/LocalisedString';

import { FetchDeliveriesParams } from '../../Hook';
import { useQueryAsState } from '../../../Hooks';
import { QueryParser, QueryWithKeys } from '../../../Common/Utility';
import { PaginatedTable, TableCell, TableRow } from '../../../Components';
import { Chip, TableFilters } from '../../../Common/Component';
import { CategoryAutocomplete, ContentTypeAutocomplete, DraftsTableRow } from '..';
import { StyledAutocompleteFilter } from '../LegacyBroadcastTableFilters/style';
import { MinimalPerson } from '../../../Models';
import { PersonAutocomplete } from '../../../People/Component';
import { PersonParser } from '../../../Utility';
import { MinimalBroadcastCategory } from '../../Model';
import { useContentDefinitionRegistry } from '../../../Content/Hook';
import { SharedDelivery, useFetchSharedDeliveries } from '../../Hook/useFetchSharedDeliveries';

type Query = QueryWithKeys<'createdByIds' | 'pageNum' | 'sort' | 'contentTypes' | 'search' | 'tab' | 'categoryIds'>;

export const DraftsTable: FC = () => {
  const [query, setQuery] = useQueryAsState<Query>();
  const intl = useIntl();
  const { contentDefinitions } = useContentDefinitionRegistry();
  const createdByIds = useMemo<string[]>(() => query.createdByIds ? query.createdByIds.split(',') : [], [query.createdByIds]);
  const params = useMemo<FetchDeliveriesParams>(() => ({
    pageNum: QueryParser.pageNum(query),
    sort: parseSort(query),
    noPublished: 1,
    noArchived: 1,
    ...(createdByIds.length ? { createdByIds: createdByIds.join(',') } : {}),
    ...(query.contentTypes ? {
        contentTypes: query.contentTypes
          .split(',')
          .map(contentType => contentDefinitions.find(definition => definition.id === contentType)?.contentTypes || [])
          .join(',')
      } : {}),
    ...(query.search ? { search: query.search } : {}),
    ...(query.categoryIds ? { categoryIds: query.categoryIds } : {}),
    statuses: 'pending',
  }), [contentDefinitions, createdByIds, query]);
  const [fetchDeliveriesResult, requestState, reloadDeliveries] = useFetchSharedDeliveries(params);
  const sharedDeliveries = useMemo<SharedDelivery[]>(
    () => fetchDeliveriesResult?.content?.deliveries || [],
    [fetchDeliveriesResult?.content],
  );
  const [chips, setChips] = useState<Chip[]>([]);

  const whenPageNumChanged = useCallback((pageNum: number) => (
    setQuery(query => ({
      ...query,
      pageNum: `${ pageNum }`,
    }))
  ), [setQuery]);

  const whenSearchChanged = useCallback((search: string) => (
    setQuery(({ search: previousSearch, ...query }) => ({
      ...query,
      pageNum: '1',
      ...(search ? { search } : {}),
    }))
  ), [setQuery]);

  const whenContentTypesChanged = useCallback((contentTypes: LocalisedString[]) => {
    setQuery(({ contentTypes: previousContentTypes, ...query }) => ({
      ...query,
      pageNum: '1',
      ...(contentTypes.length ? { contentTypes: contentTypes.map(contentType => contentType.id).join(',') } : {}),
    }));
    setChips(chips => [
      ...chips.filter(chip => chip.type !== 'contentType'),
      ...contentTypes.map(contentType => ({
        type: 'contentType',
        label: intl.formatMessage({
          id: 'draftsTable.type.chip',
          description: 'Label for chip for type filter in drafts table.',
          defaultMessage: 'Type: { type }',
        }, {
          type: contentType.localisation,
        }),
        id: contentType.id,
      }))
    ]);
  }, [intl, setQuery]);

  const whenCategoriesChanged: AutocompleteSelectionChanged<MinimalBroadcastCategory> = useCallback(
    selection => {
      setQuery(({ categoryIds: previousCategoryIds, ...query }) => ({
        ...query,
        pageNum: '1',
        ...(selection.options.length ? { categoryIds: selection.options.map(category => category.id).join(',') } : {}),
      }));
      setChips(chips => [
        ...chips.filter(chip => chip.type !== 'category'),
        ...selection.options.map(category => ({
          type: 'category',
          label: intl.formatMessage({
            description: 'Label for chip for category filter in drafts table.',
            defaultMessage: 'Category: { name }',
          }, {
            name: category.name,
          }),
          id: category.id,
        }))
      ]);
    },
    [intl, setQuery],
  );

  const whenCreatedByChanged: AutocompleteSelectionChanged<MinimalPerson> = useCallback(selection => {
    const createdByIds = selection.options.map(option => option.id).concat(selection.unknownIds);
    setQuery(({ createdByIds: previousCreatedByIds, ...query }) => ({
      ...query,
      pageNum: '1',
      ...(createdByIds.length ? { createdByIds: createdByIds.join(',') } : {}),
    }));
    setChips(chips => [
      ...chips.filter(chip => chip.type !== 'createdBy'),
      ...selection.options.map(option => ({
        type: 'createdBy',
        label: intl.formatMessage({
          id: 'draftsTable.createdBy.chip',
          description: 'Label for chip for created by filter in drafts table.',
          defaultMessage: 'Created by: { name }',
        }, {
          name: PersonParser.fullName(option),
        }),
        id: option.id,
      }))
    ]);
  }, [intl, setQuery]);

  const clearFilters = useCallback(() => {
    setQuery({
      pageNum: '1',
      tab: 'drafts',
    });
    setChips([]);
  }, [setQuery]);

  const whenRemoveChipClicked = useCallback((chipToRemove: Chip) => {
    setChips(chips => chips.filter(chip => chip.type !== chipToRemove.type || chip.id !== chipToRemove.id));

    switch (chipToRemove.type) {
      case 'createdBy':
        return setQuery(({ createdByIds: previousCreatedByIds, ...query }) => {
          const newCreatedAtIds: string[] = (previousCreatedByIds || '').split(',').filter(createdById => createdById !== chipToRemove.id);
          return {
            ...query,
            ...(newCreatedAtIds.length ? { createdByIds: newCreatedAtIds.join(',') } : {}),
          };
        });
      case 'contentType':
        return setQuery(({ contentTypes: previousContentTypes, ...query }) => {
          const newContentTypes: string[] = (previousContentTypes || '').split(',').filter(contentType => contentType !== chipToRemove.id);
          return {
            ...query,
            ...(newContentTypes.length ? { contentTypes: newContentTypes.join(',') } : {}),
          };
        });
      case 'category':
        return setQuery(({ categoryIds: previousCategoryIds, ...query }) => {
          const newCategoryIds: string[] = (previousCategoryIds || '').split(',').filter(category => category !== chipToRemove.id);
          return {
            ...query,
            ...(newCategoryIds.length ? { categoryIds: newCategoryIds.join(',') } : {}),
          };
        });
    }
  }, [setQuery]);

  return (
    <>
      <div>
        <TableFilters
          searchValue={ query.search || '' }
          onSearchChanged={ whenSearchChanged }
          onClearFiltersClicked={ clearFilters }
          onRemoveChipClicked={ whenRemoveChipClicked }
          chips={ chips }
        >
          <StyledAutocompleteFilter>
            <PersonAutocomplete
              selectedIds={ createdByIds }
              onSelectionChanged={ whenCreatedByChanged }
              multiple
              showChips={ false }
              label={ intl.formatMessage({
                id: 'draftsTable.createdBy.label',
                description: 'Label for created by filter in drafts table.',
                defaultMessage: 'Created by',
              }) }
              dense
            />
          </StyledAutocompleteFilter>
          <StyledAutocompleteFilter>
            <ContentTypeAutocomplete
              selectedIds={ (query.contentTypes || '').split(',').filter(id => !!id) }
              onSelectionChanged={ whenContentTypesChanged }
              fullWidth
            />
          </StyledAutocompleteFilter>
          <StyledAutocompleteFilter>
            <CategoryAutocomplete
              id="category-filter"
              selectedIds={ (query.categoryIds || '').split(',').filter(id => !!id) }
              onSelectionChanged={ whenCategoriesChanged }
              showChips={ false }
              label={ intl.formatMessage({
                description: 'Label for category filter in drafts table.',
                defaultMessage: 'Category',
              }) }
              placeholder={ intl.formatMessage({
                description: 'Placeholder for category filter in drafts table.',
                defaultMessage: 'Search…',
              }) }
              multiple
              fullWidth
            />
          </StyledAutocompleteFilter>
        </TableFilters>
        <PaginatedTable
          rows={ sharedDeliveries }
          headerRow={
            <TableRow>
              <TableCell>
                <FormattedMessage
                  id="draftsTable.columnHeaders.type"
                  description="Column header for type column in broadcast draft table."
                  defaultMessage="Type"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  id="draftsTable.columnHeaders.title"
                  description="Column header for title column in broadcast draft table."
                  defaultMessage="Title"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  id="draftsTable.columnHeaders.sharedWith"
                  description="Column header for shared with column in broadcast draft table."
                  defaultMessage="Shared with"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  id="draftsTable.columnHeaders.modified"
                  description="Column header for modified column in broadcast draft table."
                  defaultMessage="Modified"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  id="draftsTable.columnHeaders.actions"
                  description="Column header for actions column in broadcast draft table."
                  defaultMessage="Actions"
                />
              </TableCell>
            </TableRow>
          }
          rowRender={ sharedDelivery => (
            <DraftsTableRow
              key={ sharedDelivery.broadcast.id }
              sharedDelivery={ sharedDelivery }
              onReloadRequired={ reloadDeliveries }
            />
          ) }
          pageNum={ params.pageNum }
          onPageChanged={ whenPageNumChanged }
          requestState={ requestState }
          onRetryClicked={ reloadDeliveries }
          pagination={ fetchDeliveriesResult?.content?.pagination }
        />
      </div>
    </>
  );
};

const parseSort = QueryParser.getValueParser(
  'sort',
  ['coalesced_delivered_at_asc', 'coalesced_delivered_at_desc'],
  'coalesced_delivered_at_desc',
);
