import {FunctionComponent, PropsWithChildren, useContext, useEffect, useMemo, useState} from 'react';

import { ApiContext } from '../../Contexts';
import { Api } from '../../Services/Api';
import { MaintenanceError } from '../../Core/Component/MaintenanceError/MaintenanceError';
import { SpaceContext } from '../../Core/Context/SpaceContext';
import {DeviceFingerprintContext} from '../../Core/Context/DeviceFingerprintContext';

export const ApiProvider: FunctionComponent<PropsWithChildren> = (
  { children }
) => {
  const { space } = useContext(SpaceContext);
  const [underMaintenance, setUnderMaintenance] = useState<boolean>(false);
  const { deviceToken, platform, manufacturer } = useContext(DeviceFingerprintContext);
  const api = useMemo(
    () => space ? createApi(deviceToken, platform, manufacturer) : null,
    [space, deviceToken, platform, manufacturer]
  );

  useEffect(() => {
    if (api) {
      const errorSubscription = api.onMaintenanceError()
        .subscribe(() => setUnderMaintenance(true));
      return () => errorSubscription.unsubscribe();
    }
  }, [api]);

  return (
    <ApiContext.Provider value={api}>
      {children}
      {
        underMaintenance && (
          <MaintenanceError onDismiss={() => setUnderMaintenance(false)} />
        )
      }
    </ApiContext.Provider>
  );
};

const createApi = (deviceToken?: string, model?: string, manufacturer?: string): Api => {
  const api = new Api();
  api.applyDeviceHeaders(deviceToken, 'Web', model, manufacturer);
  return api;
};
