import { Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { FunctionComponent } from 'react';

import { Flex } from '..';

type Props = {
  onRetry: () => void;
};

export const ErrorWithRetry: FunctionComponent<Props> = ({
  onRetry,
  children,
}) => (
  <Flex
    direction="column"
    gap={ 1 }
  >
    <span>
      { children }
    </span>
    <Button
      color="primary"
      disableElevation
      onClick={ onRetry }
    >
      <FormattedMessage
        id="error.retryLabel"
        description="Label for retry button"
        defaultMessage="Try again"
      />
    </Button>
  </Flex>
);
