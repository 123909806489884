import { FC, ReactNode } from 'react';

import { StackEnd } from '../StackEnd/StackEnd';
import { Stack } from '../Stack/Stack';

type Props = {
  direction?: 'column' | 'row';
  fullWidth?: boolean;
  children?: ReactNode;
};

export const ModalActions: FC<Props> = ({
  direction = 'row',
  fullWidth = false,
  children,
}) => (
  <div
    { ...fullWidth && { css: { width: '100%' } } }
  >
    <Stack
      direction={ direction === 'column' ? 'column-reverse' : 'row' }
      gap={ 3 }
    >
      {
        direction === 'row' && !fullWidth
          ? (
            <StackEnd>
              <Stack gap={ 3 }>
                { children }
              </Stack>
            </StackEnd>
          )
          : children
      }
    </Stack>
  </div>
);
