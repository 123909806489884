// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.sentry-error-embed-wrapper {
    display: flex;
    align-items: center;
}

div.sentry-error-embed {
    width: 700px;
}

div.sentry-error-embed * {
    font-family: 'MuseoSansRounded',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    "Fira Sans",
    "Droid Sans",
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"';
}

.sentry-error-embed .form-submit button.btn  {
    background-color: #e00669;
    border: none;
}

.sentry-error-embed .form-submit button.btn:hover  {
    background-color: #a8003f;
    border: none;
}

.sentry-error-embed .form-submit button.close {
    color: #e00669;
}

.sentry-error-embed .form-submit button.close:hover {
    color: #a8003f;
}
`, "",{"version":3,"sources":["webpack://./src/react/sentry-feedback.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI;;;;;;;;;;;;;;;uBAemB;AACvB;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB","sourcesContent":["div.sentry-error-embed-wrapper {\n    display: flex;\n    align-items: center;\n}\n\ndiv.sentry-error-embed {\n    width: 700px;\n}\n\ndiv.sentry-error-embed * {\n    font-family: 'MuseoSansRounded',\n    '-apple-system',\n    'BlinkMacSystemFont',\n    '\"Segoe UI\"',\n    'Roboto',\n    'Oxygen',\n    'Ubuntu',\n    'Cantarell',\n    \"Fira Sans\",\n    \"Droid Sans\",\n    '\"Helvetica Neue\"',\n    'Arial',\n    'sans-serif',\n    '\"Apple Color Emoji\"',\n    '\"Segoe UI Emoji\"',\n    '\"Segoe UI Symbol\"';\n}\n\n.sentry-error-embed .form-submit button.btn  {\n    background-color: #e00669;\n    border: none;\n}\n\n.sentry-error-embed .form-submit button.btn:hover  {\n    background-color: #a8003f;\n    border: none;\n}\n\n.sentry-error-embed .form-submit button.close {\n    color: #e00669;\n}\n\n.sentry-error-embed .form-submit button.close:hover {\n    color: #a8003f;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
