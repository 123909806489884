import styled from '@emotion/styled';

import { OrientationString } from '../CropDialog/CropDialog';

type StyledImageUploadProps = {
  imageWidth: number;
  imageHeight: number;
  containerWidth?: number;
  containerHeight?: number;
  orientation: OrientationString;
};

export const StyledImageUpload = styled.div<StyledImageUploadProps>`
  ${ props => props.orientation === 'portrait'
    ? props.imageHeight && `height: ${ props.imageHeight }px;`
    : props.imageWidth && `width: ${ props.imageWidth }px;`
  }

  > img {
    ${ props => {
      const aspectRatio = props.imageWidth / props.imageHeight;

      return props.orientation === 'portrait'
        ? props.containerHeight && `width: ${ props.containerHeight * aspectRatio }px;`
        : props.containerWidth && `height: ${ props.containerWidth / aspectRatio }px;`
      }
    }
  }

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const StyledImagePlaceholder = styled.div`
  height: 100%;
  background-color: ${ props => props.theme.palette.grey[300] };
`;
