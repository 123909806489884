import { FC, useEffect, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { Editor } from '@ourpeople/shared/Core/Component/Input/RichTextEditor/Editor/Editor';

import { StyledCharacterCountContainer } from './style';
import { MappedSettingTypes } from '../../../Types';
import { Flex, ValidationErrorMessage } from '../../../../../../Common/Component';
import { CharacterCount } from '../../../../../../Content/Component';
import { RichTextSanitiser } from '../../../../../../Common/Utility';

type Props = {
  config: MappedSettingTypes['html']['config'];
  value: MappedSettingTypes['html']['value'];
  onValueChanged: (value: MappedSettingTypes['html']['value']) => void;
};

export const HtmlSettingInput: FC<Props> = ({
  config,
  value,
  onValueChanged,
}) => {
  const intl = useIntl();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const error = validate(value, config, intl);
    setError(error);
  }, [value, config, intl]);

  return (
    <div>
      { config.maxLength && (
        <Flex>
          <StyledCharacterCountContainer>
            <CharacterCount
              { ...config.minLength ? { minimum: config.minLength } : {} }
              maximum={ config.maxLength }
              current={ RichTextSanitiser.stripTags(value || '').length }
            />
          </StyledCharacterCountContainer>
        </Flex>
      ) }
      <Editor
        value={ value || '' }
        onChange={ onValueChanged }
      />
      { error && <ValidationErrorMessage>{ error }</ValidationErrorMessage> }
    </div>
  );
};

const validate = (
  value: MappedSettingTypes['html']['value'],
  config: MappedSettingTypes['html']['config'],
  intl: IntlShape,
): string | null => {
  if (value === null && config.nullable) {
    return null;
  }

  if (value === null) {
    return intl.formatMessage({
      id: 'settings.htmlSetting.nullError',
      defaultMessage: 'Missing value',
    });
  }

  if (
    config.minLength !== null
    && value.length < config.minLength
  ) {
    return intl.formatMessage(
      {
        id: 'settings.htmlSetting.minLengthError',
        defaultMessage: 'Must be no less than {minLength} characters',
      },
      {
        minLength: config.minLength,
      }
    );
  }

  if (
    config.maxLength !== null
    && RichTextSanitiser.stripTags(value).length > config.maxLength
  ) {
    return intl.formatMessage(
      {
        id: 'settings.htmlSetting.maxLengthError',
        defaultMessage: 'Must be no more than {maxLength} characters',
      },
      {
        maxLength: config.maxLength,
      }
    );
  }

  return null;
}
