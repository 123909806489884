import { FunctionComponent, ReactNode, useEffect, useMemo, useState } from 'react';

import { FetchBroadcastsParams, useFetchBroadcasts } from '../../Hook';
import { PaginatedTable } from '../../../Components';
import { Pagination } from '../../../Models';
import { ListedBroadcast } from '../../Model';
import { useContentDefinitionRegistry } from '../../../Content/Hook';

interface Props {
  pageNum: number;
  contentTypes: string[];
  teamIds: string[];
  createdByIds?: string[];
  search: string;
  onPageChanged: (page: number) => void;
  rowRender: (broadcast: ListedBroadcast) => ReactNode;
  headerRow: ReactNode;
  noDrafts?: boolean;
  noArchived?: boolean;
  noUnarchived?: boolean;
  noScheduled?: boolean;
  noPublished?: boolean;
  noDelivered?: boolean;
  noFinished?: boolean;
  sort: string;
  lastActionComplete?: Date;
}

/**
 * @deprecated Prefer distinct tables for each broadcast page tab
 */
export const BroadcastTable: FunctionComponent<Props> = ({
  pageNum,
  contentTypes,
  teamIds,
  createdByIds,
  search,
  headerRow,
  onPageChanged,
  rowRender,
  noDrafts,
  noArchived,
  noUnarchived,
  noScheduled,
  noPublished,
  noDelivered,
  noFinished,
  sort,
  lastActionComplete,
}) => {
  const { contentDefinitions } = useContentDefinitionRegistry();
  const [localPageNum, setLocalPageNum] = useState<number>(pageNum);
  const params = useMemo<FetchBroadcastsParams>(
    () => ({
      pageNum,
      ...(search ? { search } : {}),
      ...(
        createdByIds !== undefined && createdByIds.length > 0
          ? { createdByIds: createdByIds.join(',') }
          : {}
      ),
      ...(
        !!teamIds && teamIds.length > 0
          ? { tagIds: teamIds.join(',') }
          : {}
      ),
      types: 'content,event',
      pageSize: 25,
      sort,
      ...(contentTypes && contentTypes.length ? {
          contentTypes: contentTypes
            .map(contentType => contentDefinitions.find(definition => definition.id === contentType)?.contentTypes || [])
            .join(',')
        } : {}),
      ...(
        noDrafts === true
          ? { noDrafts: 1 }
          : {}
      ),
      ...(
        noArchived === true
          ? { noArchived: 1 }
          : {}
      ),
      ...(
        noUnarchived === true
          ? { noUnarchived: 1 }
          : {}
      ),
      ...(
        noScheduled === true
          ? { noScheduled: 1 }
          : {}
      ),
      ...(
        noPublished === true
          ? { noPublished: 1 }
          : {}
      ),
      ...(
        noDelivered === true
          ? { noDelivered: 1 }
          : {}
      ),
      ...(
        noFinished === true
          ? { noFinished: 1 }
          : {}
      ),
    }),
    [
      pageNum,
      search,
      createdByIds,
      teamIds,
      sort,
      contentTypes,
      noDrafts,
      noArchived,
      noUnarchived,
      noScheduled,
      noPublished,
      noDelivered,
      noFinished,
      contentDefinitions
    ],
  );
  const [fetchBroadcastsResponse, fetchBroadcastsState, fetchBroadcastsRefresh] = useFetchBroadcasts(params);
  const broadcasts = useMemo(
    () => fetchBroadcastsResponse?.content?.broadcasts || [],
    [fetchBroadcastsResponse?.content?.broadcasts],
  );
  const pagination = useMemo<Pagination | undefined>(
    () => {
      const deprecatedPagination = fetchBroadcastsResponse?.content?.pagination;

      if (!deprecatedPagination) {
        return;
      }

      return fetchBroadcastsResponse?.content?.pagination;
    },
    [fetchBroadcastsResponse?.content?.pagination],
  );

  useEffect(() => {
    fetchBroadcastsRefresh();
  }, [fetchBroadcastsRefresh, lastActionComplete]);

  useEffect(() => {
    onPageChanged(localPageNum);
  }, [onPageChanged, localPageNum]);

  return (
    <PaginatedTable
      rows={ broadcasts }
      rowRender={ rowRender }
      pageNum={ pageNum }
      onPageChanged={ setLocalPageNum }
      headerRow={ headerRow }
      requestState={ fetchBroadcastsState }
      onRetryClicked={ fetchBroadcastsRefresh }
      pagination={ pagination }
    />
  )
};
