import { FC, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { Checkbox } from 'op-storybook/stories/components/Checkbox/Checkbox';
import { StackEnd } from 'op-storybook/lib/components/StackEnd/StackEnd';
import { Button } from 'op-storybook/stories/components/Button/Button';
import { Typography } from 'op-storybook/stories/components/Typography/Typography';
import { Username } from 'op-storybook/stories/components/UsernameField/UsernameField';

import { ForgotPasswordLink } from '../ForgotPasswordLink/ForgotPasswordLink';

type Props = {
  label?: string;
  showRememberMe?: boolean;
  rememberMe: boolean;
  onRememberMeChange: (rememberMe: boolean) => void;
  disabled: boolean;
  submitting: boolean;
  error: boolean;
  forgotPasswordLink?: {
    enabled: boolean;
    username?: Username;
  };
};

export const PasswordLoginSubmitButton: FC<Props> = ({
  rememberMe,
  onRememberMeChange,
  disabled,
  submitting,
  error,
  forgotPasswordLink,
  label,
  showRememberMe,
}) => {
  const intl = useIntl();
  const whenRememberMeChanged = useCallback(() => (
    onRememberMeChange(!rememberMe)
  ), [onRememberMeChange, rememberMe]);

  return (
    <Stack direction="column" gap={ 2 }>
      <Stack gap={ 2 }>
        { showRememberMe && (
          <Checkbox
            id="remember-me"
            name="remember-me"
            variant="beside"
            checked={ rememberMe }
            onCheckedChange={ whenRememberMeChanged }
            label={ intl.formatMessage({
              description: 'Label for keep signed in checkbox by login button.',
              defaultMessage: 'Keep me signed in',
            }) }
          />
        ) }
        { forgotPasswordLink?.enabled && (
          <StackEnd>
            <ForgotPasswordLink username={ forgotPasswordLink.username }/>
          </StackEnd>
        ) }
      </Stack>
      <Button
        type="submit"
        variant="primary"
        disabled={ disabled }
        busy={ submitting }
        attention={ error }
      >
        { !label && (
          <FormattedMessage
            description="Label for submit button on login page where user is unidentified."
            defaultMessage="Log in"
          />
        ) }
        { label }
      </Button>
      { error && (
        <Typography
          palette={ {
            colour: 'error',
            intensity: 500,
          } }
          size="sm"
          weight="regular"
        >
          <FormattedMessage
            description="Generic error message when failing to log in on login page."
            defaultMessage="Could not log in, try again."
          />
        </Typography>
      ) }
    </Stack>
  );
};
