import { FC } from 'react';

export enum DisabledBehaviour {
  ALLOW,
  DISABLE,
  HIDE,
}

interface BaseNavigationItem {
  ref: string;
  label: string;
  Icon: FC;
  disabledBehaviour: DisabledBehaviour;
  shortLabel: string | null;
}

export interface NavigationGroup extends BaseNavigationItem {
  subItems: {
    label: string;
    onClick: () => void;
    active: boolean;
    count: null | number | true;
  }[];
}

export interface NavigationButton extends BaseNavigationItem {
  onClick: () => void;
  active: boolean;
  count: null | number | true;
}

export type NavigationItem = NavigationGroup | NavigationButton;

interface BaseConfiguredNavigationItem extends BaseNavigationItem {
  disabled: boolean;
  unlockedIndex: number | null;
}

interface ConfiguredNavigationGroup extends NavigationGroup, BaseConfiguredNavigationItem {}

interface ConfiguredNavigationButton extends NavigationButton, BaseConfiguredNavigationItem {}

export type ConfiguredNavigationItem = ConfiguredNavigationGroup | ConfiguredNavigationButton;
