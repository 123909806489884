import { default as React, FC, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { ContentEditorProps } from '../../../../Content/Model/ContentEditorProps';
import { REVEAL_OPTIONS_MAX_COUNT,
    REVEAL_OPTIONS_MIN_COUNT,
    REVEAL_TEXT_MAX_LENGTH,
    REVEAL_TEXT_MIN_LENGTH,
    REVEAL_TITLE_MAX_LENGTH,
    REVEAL_TITLE_MIN_LENGTH,
    RevealContentValidator,
} from '../../../Service/Validators/RevealContentValidator/RevealContentValidator';
import { DraftRevealOption, RevealEditorContent } from '../../../Service/ContentDefinition/RevealContentDefinition/RevealContentDefinition';
import { FieldValidationErrors } from '../../../../Common/Component/FieldValidationErrors/FieldValidationErrors';
import { VerticallySpaced } from '../../../../Common/Component/VerticallySpaced/VerticallySpaced';
import { NoMarginTextField } from '../../../../Common/Component/NoMarginTextField/NoMarginTextField';
import { CharacterCount } from '../../../../Content/Component/CharacterCount/CharacterCount';
import { useInputChangeEventHandler } from '../../../../Common/Hook/useInputChangeEventHandler';
import { UniqueIdGenerator } from '../../../../Common/Utility/UniqueIdGenerator';
import { ValidationTree } from '../../../../Common/Model/ValidationTree';
import { OptionsEditor } from '../OptionsEditor/OptionsEditor';
import { RevealContentOptionEditor } from '../RevealContentOptionEditor/RevealContentOptionEditor';

export const RevealContentEditor: FC<ContentEditorProps<RevealEditorContent>> = ({
  editorContent,
  onEditorContentChanged,
  validation,
  onValidationChanged,
}) => {
  const intl = useIntl();
  const textFieldLabel = intl.formatMessage({
    id: 'broadcasts.content.reveal.fields.text.label',
    description: 'Label for text field in reveal card editor.',
    defaultMessage: 'Description',
  });
  const titleFieldLabel = intl.formatMessage({
    id: 'broadcasts.content.reveal.fields.title.label',
    description: 'Label for title field in reveal card editor.',
    defaultMessage: 'Title',
  });

  const whenTitleChanged = useInputChangeEventHandler(useCallback((title: string) => (
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          title,
        },
      },
    })
  ), [editorContent, onEditorContentChanged]));

  const whenTextChanged = useInputChangeEventHandler(useCallback((text: string) => (
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          text,
        },
      },
    })
  ), [editorContent, onEditorContentChanged]));

  const createOption = (): DraftRevealOption => ({
    id: UniqueIdGenerator.generate(),
    type: 'reveal_option',
    preview_text: '',
    text: '',
  });

  const whenTitleBlurred = useCallback(() => (
    onValidationChanged({
      errors: validation?.errors || [],
      children: {
        content: {
          errors: validation?.children.content?.errors || [],
          children: {
            ...validation?.children.content?.children,
            title: RevealContentValidator.validateTitle(editorContent.card.content.title),
          },
        },
      },
    })
  ), [validation, onValidationChanged, editorContent]);

  const whenTextBlurred = useCallback(() => (
    onValidationChanged({
      errors: validation?.errors || [],
      children: {
        content: {
          errors: validation?.children.content?.errors || [],
          children: {
            ...validation?.children.content?.children,
            text: RevealContentValidator.validateText(editorContent.card.content.text),
          },
        },
      },
    })
  ), [validation, onValidationChanged, editorContent]);

  const whenOptionsChanged = useCallback((options: DraftRevealOption[]) => (
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          options,
        },
      },
    })
  ), [editorContent, onEditorContentChanged]);

  const whenOptionsValidationChanged = useCallback((optionsValidation: ValidationTree<DraftRevealOption[]>) => (
    onValidationChanged({
      errors: validation?.errors || [],
      children: {
        content: {
          errors: validation?.children.content?.errors || [],
          children: {
            ...validation?.children.content?.children,
            options: optionsValidation,
          },
        },
      },
    })
  ), [validation, onValidationChanged]);

  return (
    <VerticallySpaced gap={ 2 }>
      <VerticallySpaced gap={ 1 }>
        <h4><label htmlFor={ TITLE_FIELD_ID }>{ titleFieldLabel }</label></h4>
        <NoMarginTextField
          id={ TITLE_FIELD_ID }
          fullWidth
          value={ editorContent.card.content.title }
          onChange={ whenTitleChanged }
          onBlur={ whenTitleBlurred }
        />
        <CharacterCount
          current={ editorContent.card.content.title.length || 0 }
          minimum={ REVEAL_TITLE_MIN_LENGTH }
          maximum={ REVEAL_TITLE_MAX_LENGTH }
        />
        <FieldValidationErrors
          fieldName={ titleFieldLabel }
          validationErrors={ validation?.children.content?.children.title?.errors || [] }
        />
      </VerticallySpaced>
      <VerticallySpaced gap={ 1 }>
        <h4><label htmlFor={ TEXT_FIELD_ID }>{ textFieldLabel }</label></h4>
        <NoMarginTextField
          id={ TEXT_FIELD_ID }
          fullWidth
          value={ editorContent.card.content.text }
          onChange={ whenTextChanged }
          onBlur={ whenTextBlurred }
        />
        <CharacterCount
          current={ editorContent.card.content.text.length || 0 }
          minimum={ REVEAL_TEXT_MIN_LENGTH }
          maximum={ REVEAL_TEXT_MAX_LENGTH }
        />
        <FieldValidationErrors
          fieldName={ textFieldLabel }
          validationErrors={ validation?.children.content?.children.text?.errors || [] }
        />
      </VerticallySpaced>
      <OptionsEditor
        options={ editorContent.card.content.options }
        onChange={ whenOptionsChanged }
        validation={ validation?.children.content?.children.options }
        onValidationChange={ whenOptionsValidationChanged }
        OptionEditorComponent={ RevealContentOptionEditor }
        minOptions={ REVEAL_OPTIONS_MIN_COUNT }
        maxOptions={ REVEAL_OPTIONS_MAX_COUNT }
        createOption={ createOption }
        localisedFieldName={ intl.formatMessage({
          id: 'broadcasts.content.reveal.fields.options.label',
          description: 'Label for options in reveal content',
          defaultMessage: 'Items',
        }) }
        localisedButtonLabel={ intl.formatMessage({
          id: 'broadcasts.content.reveal.fields.addOption.label',
          description: 'Label for add option button in reveal content',
          defaultMessage: 'Add item',
        }) }
      />
    </VerticallySpaced>
  );
};

const TITLE_FIELD_ID = 'title';
const TEXT_FIELD_ID = 'text';
