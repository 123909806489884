import { FunctionComponent } from 'react';

import { ContentReader } from '../../../../../Readers';
import { CardReport } from '../../Hooks/useFetchBroadcastReportSummary';
import { useRespondedLabelForContentType } from '../../Hooks/useRespondedLabelForContentType';
import { useNotRespondedLabelForContentType } from '../../Hooks/useNotRespondedLabelForContentType';
import { DeliveryReportTab, DeliveryReportTabContainer, DeliveryReportTabContent, DeliveryReportTabs } from './';
import { NotRespondedTable } from '../Tables/NotRespondedTable';
import { CommentRespondedTable } from '../Tables/CommentRespondedTable';

interface Props {
  broadcastId: string;
  deliveryId: string;
  card: CardReport;
  pendingCount: number;
  initialSearch?: string;
}

export const CommentContentReportTabs: FunctionComponent<Props> = ({
  card,
  broadcastId,
  deliveryId,
  pendingCount,
  initialSearch,
}) => (
  <DeliveryReportTabContainer
    tabs={
      <DeliveryReportTabs>
        <DeliveryReportTab
          label={useRespondedLabelForContentType(
            ContentReader.contentTypeFromString(card.content.type)
          )}
          value={card.counts.responded?.total || 0}
        />
        <DeliveryReportTab
          label={useNotRespondedLabelForContentType(
            ContentReader.contentTypeFromString(card.content.type)
          )}
          value={(card.counts.notResponded?.total || 0) - pendingCount}
          lowLight={true}
        />
      </DeliveryReportTabs>
    }>
    <DeliveryReportTabContent>
      <CommentRespondedTable
        deliveryId={deliveryId}
        broadcastId={broadcastId}
        contentId={card.content.id}
        initialSearch={ initialSearch }
      />
    </DeliveryReportTabContent>
    <DeliveryReportTabContent>
      <NotRespondedTable
        deliveryId={deliveryId}
        broadcastId={broadcastId}
        contentId={card.content.id}
        responseType="notResponded"
        initialSearch={ initialSearch }
      />
    </DeliveryReportTabContent>
  </DeliveryReportTabContainer>
);
