import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { DateOffsetRange } from '@ourpeople/shared/Core/Component/Input/DateOffsetRangeInput/DateOffsetRangeInput';
import { useReportDateRangeInputOptions } from '@ourpeople/shared/Reporting/Hook/useReportDateRangeInputOptions';
import { DateOffsetParser } from '@ourpeople/shared/Core/Utility/DateOffsetParser';
import { DateOffsetRangeParser, FixedDateOffsetRange } from '@ourpeople/shared/Core/Utility/DateOffsetRangeParser';
import { LocalisedString } from 'op-storybook/lib/model/LocalisedString/LocalisedString';

import {
  FetchSubmissionsParams,
  FetchSubmissionsSort,
  useFetchSubmissions,
  useSubmissionTableRowRenderFunction,
} from '../../Hook';
import { PaginatedTable } from '../../../Components';
import { SubmissionStatusAutocomplete, SubmissionStatusString, SubmissionTableHeaderRow } from '..';
import { Chip, Flex, PresentationIcon, TableFilters, VerticallySpaced } from '../../../Common/Component';
import { SingleContentCardSubmission, SingleContentFormAndCards, SingleContentSubmission, } from '../../Model';
import { RequestState } from '../../../Models';
import LockIcon from '../../../Assets/img/icons/streamline/shield-lock.svg';
import { ReportDateRangeInput } from '../../../Reporting/Component';
import { FormErrorResponseReader } from '../../Utility/FormErrorResponseReader';
import { ChipHelper, UniqueIdGenerator } from '../../../Common/Utility';
import { useContentDefinitionRegistry } from '../../../Content/Hook';

interface Props {
  submissionCount: number;
  formAndCards: SingleContentFormAndCards;
  params: FetchSubmissionsParams;
  onChange: (params: FetchSubmissionsParams) => void;
}

export const SubmissionTable: FunctionComponent<Props> = ({
  submissionCount,
  formAndCards,
  params,
  onChange,
}) => {
  const intl = useIntl();
  const [chips, setChips] = useState<Chip[]>([]);
  const dateRangeInputOptions = useReportDateRangeInputOptions(true);
  const defaultDateRange = useMemo(() => ({
    start: dateRangeInputOptions[0].value,
    end: null,
  }), [dateRangeInputOptions]);
  const [dateRange, setDateRange] = useState<DateOffsetRange>(defaultDateRange);
  const { getContentDefinition } = useContentDefinitionRegistry();
  const selectedSubmissionStatuses = useMemo<SubmissionStatusString[]>(() => [
    ...(params.noOpen ? ['noOpen'] : []),
    ...(params.noClosed ? ['noClosed'] : []),
  ] as SubmissionStatusString[], [params.noClosed, params.noOpen]);
  const [
    fetchSubmissionsResponse,
    fetchSubmissionsRequestState,
    fetchSubmissionsRefresh,
  ] = useFetchSubmissions(formAndCards.form.id, params);
  const unmetSubmissionThreshold = useMemo(() => {
    const error = fetchSubmissionsResponse?.error;

    if (
      !error
      || !FormErrorResponseReader.isInsufficientAnonymousSubmissionsError(error)
      || !formAndCards.form.anonymousSubmissionsEnabled
    ) {
      return 0;
    }

    return error.response.data.error.data.minCount;
  }, [fetchSubmissionsResponse, formAndCards.form.anonymousSubmissionsEnabled]);
  const nonContentCards = useMemo(
    () => formAndCards.cards.filter(card => {
      const contentDefinition = getContentDefinition(card.content.type);

      return !(!contentDefinition || contentDefinition.categoryId === 'content');
    }),
    [formAndCards.cards, getContentDefinition],
  );
  const submissions = useMemo<SingleContentSubmission[]>(() => (
    unmetSubmissionThreshold
      ? Array.from({ length: submissionCount }, () => ({
        formSubmission: {
          id: UniqueIdGenerator.generate(),
          formId: formAndCards.form.id,
          created: null,
          closed: null,
          anonymous: true,
        },
        cardSubmissions: nonContentCards.map<SingleContentCardSubmission>(card => ({
          id: UniqueIdGenerator.generate(),
          cardId: card.id,
          response: {
            id: UniqueIdGenerator.generate(),
            contentId: `${ card.content.id }`,
            contentType: 'anonymous',
            created: null,
          },
        })),
      }))
      : fetchSubmissionsResponse?.content?.submissions || []
  ), [
    fetchSubmissionsResponse?.content?.submissions,
    formAndCards.form.id,
    unmetSubmissionThreshold,
    nonContentCards,
    submissionCount
  ]);
  const pagination = useMemo(() => (
    unmetSubmissionThreshold
      ? {
        pageCount: 1,
        itemCount: submissionCount,
        pageSize: 20,
      }
      : fetchSubmissionsResponse?.content?.pagination
  ), [fetchSubmissionsResponse?.content?.pagination, unmetSubmissionThreshold, submissionCount]);

  const whenSelectedSubmissionStatusesChanged = useCallback((selection: LocalisedString<SubmissionStatusString>[]) => {
    const { noOpen, noClosed, ...fetchSubmissionsParams } = params;
    onChange({
      ...fetchSubmissionsParams,
      ...(selection.find(option => option.id === 'noOpen') ? { noOpen: 1 } : {}),
      ...(selection.find(option => option.id === 'noClosed') ? { noClosed: 1 } : {}),
      pageNum: 1,
    });

    setChips(chips => (
      ChipHelper.updateChipsWithBasicAutocompleteSelection(
        'submissionStatus',
        chips,
        selection,
        option => intl.formatMessage({
          id: 'form.report.submissionStatusChip',
          description: 'Label for submission status filter chip.',
          defaultMessage: 'Status: { type }',
        }, {
          type: option.localisation,
        }),
      )
    ));
  }, [intl, onChange, params]);

  const whenPageNumChanged = useCallback((pageNum: number) => {
    onChange({
      ...params,
      pageNum,
    });
  }, [onChange, params]);

  const whenSort = useCallback((sort: FetchSubmissionsSort) => {
    onChange({
      ...params,
      sort,
    });
  }, [onChange, params]);

  const updateDateRange = useCallback((dateRange: DateOffsetRange) => {
    setDateRange(dateRange);
    const {
      createdSince: previousCreatedSince,
      createdUntil: previousCreatedUntil,
      ...fetchSubmissionsParams
    } = params;
    onChange({
      ...fetchSubmissionsParams,
      ...(dateRange.start ? { createdSince: DateOffsetParser.toIsoString(dateRange.start) } : {}),
      ...(dateRange.end ? { createdUntil: DateOffsetParser.toIsoString(dateRange.end) } : {}),
    });
  }, [onChange, params]);

  const whenDateRangeChanged = useCallback((dateRange: DateOffsetRange) => {
    updateDateRange(dateRange);

    const localisedPeriod = dateRange.start?.type === 'fixed' && dateRange.end?.type === 'fixed'
      ? DateOffsetRangeParser.fixedOffsetRangeToReadableString(dateRange as FixedDateOffsetRange, intl)
      : (
        dateRangeInputOptions.find(option => {
          const valuesAreNull = option.value === null && dateRange.start === null;
          const valuesHaveSameOffset = option.value?.type === 'relative'
            && dateRange.start?.type === 'relative'
            && option.value.offsetInSeconds === dateRange.start.offsetInSeconds;
          return valuesAreNull || valuesHaveSameOffset;
        })?.label || ''
      );

    setChips(chips => ([
      ...chips.filter(chip => chip.type !== 'dateRange'),
      {
        id: 'dateRange',
        type: 'dateRange',
        label: intl.formatMessage({
          description: 'Format for date period chip when filtering form submissions table by time period.',
          defaultMessage: 'Period: { localisedPeriod }',
        }, {
          localisedPeriod,
        }),
      },
    ]))
  }, [dateRangeInputOptions, intl, updateDateRange]);

  const whenClearFiltersClicked = useCallback(() => {
    onChange(defaultFetchParams);
    updateDateRange(defaultDateRange);
    setChips([]);
  }, [defaultDateRange, onChange, updateDateRange]);

  const whenRemoveChipClicked = useCallback((chipToRemove: Chip) => {
    setChips(chips => chips.filter(chip => chip.type !== chipToRemove.type || chip.id !== chipToRemove.id));

    if (chipToRemove.type === 'submissionStatus') {
      const { noOpen, noClosed, ...fetchSubmissionsParams } = params;
      onChange({
        ...fetchSubmissionsParams,
        ...(chipToRemove.id === 'noOpen' ? {} : { noOpen }),
        ...(chipToRemove.id === 'noClosed' ? {} : { noClosed }),
        pageNum: 1,
      });
    }

    if (chipToRemove.type === 'dateRange') {
      updateDateRange(defaultDateRange);
    }
  }, [defaultDateRange, onChange, params, updateDateRange]);

  return (
    <VerticallySpaced gap={ 2 }>
      { formAndCards.form.anonymousSubmissionsEnabled && !!unmetSubmissionThreshold && (
        <Flex gap={ 1 }>
          <PresentationIcon
            color="primary.main"
            size="medium"
            IconComponent={ LockIcon }
          />
          <FormattedMessage
            id="forms.report.anonymousNotice"
            description="Notice on anonymous form report explaining minimum submissions requirement."
            defaultMessage="<span><strong>Anonymous forms</strong> require a minimum of { minimum, plural, one {# response} other {# responses} } before submissions are displayed.</span>"
            values={ { minimum: unmetSubmissionThreshold } }
          />
        </Flex>
      ) }
      <div>
        { !unmetSubmissionThreshold && (
          <TableFilters
            chips={ chips }
            onRemoveChipClicked={ whenRemoveChipClicked }
            onClearFiltersClicked={ whenClearFiltersClicked }
          >
            { formAndCards.form.statusTrackerEnabled && (
              <SubmissionStatusAutocomplete
                selectedIds={ selectedSubmissionStatuses }
                onSelectionChanged={ whenSelectedSubmissionStatusesChanged }
              />
            ) }
            <ReportDateRangeInput
              value={ dateRange }
              onChange={ whenDateRangeChanged }
              displayEmpty
            />
          </TableFilters>
        ) }
        <PaginatedTable
          rows={ submissions }
          rowRender={
            useSubmissionTableRowRenderFunction(
              formAndCards.form.statusTrackerEnabled,
              nonContentCards,
              fetchSubmissionsRefresh,
              false,
              !!unmetSubmissionThreshold,
            )
          }
          pageNum={ params.pageNum }
          onPageChanged={ whenPageNumChanged }
          headerRow={ (
            <SubmissionTableHeaderRow
              statusTrackerEnabled={ formAndCards.form.statusTrackerEnabled }
              sort={ params.sort }
              onSort={ whenSort }
              cards={ nonContentCards }
              anonymous={ formAndCards.form.anonymousSubmissionsEnabled }
              hideActions={ !!unmetSubmissionThreshold }
            />
          ) }
          requestState={ unmetSubmissionThreshold ? RequestState.COMPLETE : fetchSubmissionsRequestState }
          onRetryClicked={ fetchSubmissionsRefresh }
          pagination={ pagination }
        />
      </div>
    </VerticallySpaced>
  );
};

const defaultFetchParams: FetchSubmissionsParams = {
  pageNum: 1,
  sort: 'created_at_desc',
};
