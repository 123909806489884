import { FormEventHandler, FunctionComponent, useCallback, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { TransitionProps } from '@mui/material/transitions';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';

import RenameIcon from '../../../Assets/img/icons/streamline/rename.svg';
import { useInputChangeEventHandler } from '../../Hook';
import { ValidationErrorMessage, VerticallySpaced } from '..';
import { Flex } from '..';

type Props = {
  title?: string;
  initialValue?: string;
  errors?: string[];
  open: boolean;
  onConfirm: (name: string) => void;
  onCancel: () => void;
  busy?: boolean;
  TransitionProps?: TransitionProps;
};

export const RenamePrompt: FunctionComponent<Props> = ({
  title,
  initialValue = '',
  errors = [],
  open,
  onConfirm,
  onCancel,
  busy = false,
  TransitionProps,
}) => {
  const intl = useIntl();
  const [inputValue, setInputValue] = useState<string>(initialValue);
  const whenInputChanged = useInputChangeEventHandler(setInputValue);

  const inputRef = useCallback((node: HTMLInputElement | undefined) => (
    node?.select()
  ), []);

  const renameDialogText = intl.formatMessage({
    description: 'Label for name field in rename dialog.',
    defaultMessage: 'Name',
  });

  const defaultTitle = intl.formatMessage({
    description: 'Rename dialog default title.',
    defaultMessage: 'Rename',
  });

  const whenSubmitted: FormEventHandler = useCallback(event => {
    event.preventDefault();
    onConfirm(inputValue);
  }, [onConfirm, inputValue]);

  return (
    <Dialog
      open={ open }
      fullWidth
      maxWidth="sm"
      onClose={ onCancel }
      aria-labelledby="prompt-dialog-text"
      TransitionProps={ {
        ...TransitionProps,
        onExited: node => {
          setInputValue('');
          TransitionProps?.onExited && TransitionProps.onExited(node);
        },
      } }
    >
      <DialogTitle>
        <Flex gap={ 2 }>
          <RenameIcon/>
          { title || defaultTitle }
        </Flex>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="prompt-dialog-text">
          { renameDialogText }
        </DialogContentText>
        <VerticallySpaced gap={ 2 }>
          <form id="confirmation" onSubmit={ whenSubmitted }>
            <TextField
              inputRef={ inputRef }
              autoFocus
              value={ inputValue }
              onChange={ whenInputChanged }
              variant="outlined"
              margin="dense"
              fullWidth
            />
          </form>
          { !!errors.length && errors.map((error, index) => (
            <ValidationErrorMessage key={ index }>
              { errors }
            </ValidationErrorMessage>
          )) }
        </VerticallySpaced>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={ onCancel }
        >
          <FormattedMessage
            description="Cancel button label used in rename dialog."
            defaultMessage="Cancel"
          />
        </Button>
        <Button
          variant="primary"
          busy={ busy }
          type="submit"
          form="confirmation"
        >
          <FormattedMessage
            description="Save button label used in rename dialog."
            defaultMessage="Save"
          />
        </Button>
      </DialogActions>
    </Dialog>
  )
}
