import { ValidationTree, Validator } from '../../../../Common/Model';
import { DraftChoiceOption, DraftChoiceRequest } from '../../ContentDefinition';
import { Validate } from '../../../../Common/Utility';

export class ChoiceRequestValidator {
  public static validate = (content: DraftChoiceRequest): ValidationTree<DraftChoiceRequest> => ({
    errors: [],
    children: {
      title: ChoiceRequestValidator.validateTitle(content.title),
      options: {
        errors: optionsValidator.validate(content.options),
        children: content.options.map(option => ({
          errors: [],
          children: {
            text: ChoiceRequestValidator.validateOptionText(option.text),
          },
        })),
      },
    },
  });

  public static validateOptionText = (optionText: string): ValidationTree<string> => ({
    errors: optionTextValidator.validate(optionText),
    children: {},
  });

  public static validateTitle = (title: string): ValidationTree<string> => ({
    errors: titleValidator.validate(title),
    children: {},
  });
}

export const CHOICE_TITLE_MIN_LENGTH = 1;
export const CHOICE_TITLE_MAX_LENGTH = 90;
export const CHOICE_OPTION_TEXT_MIN_LENGTH = 1;
export const CHOICE_OPTION_TEXT_MAX_LENGTH = 100;
export const CHOICE_OPTIONS_MIN_COUNT = 2;
export const CHOICE_OPTIONS_MAX_COUNT = 20;

const titleValidator = new Validator<string>([
  Validate.notBlank,
  title => Validate.minLength(title, CHOICE_TITLE_MIN_LENGTH),
  title => Validate.maxLength(title, CHOICE_TITLE_MAX_LENGTH),
]);

const optionsValidator = new Validator<DraftChoiceOption[]>([
  options => Validate.countGreaterThanEq(options, CHOICE_OPTIONS_MIN_COUNT),
  options => Validate.countLessThanEq(options, CHOICE_OPTIONS_MAX_COUNT),
]);

const optionTextValidator = new Validator<string>([
  Validate.notBlank,
  optionText => Validate.minLength(optionText, CHOICE_OPTION_TEXT_MIN_LENGTH),
  optionText => Validate.maxLength(optionText, CHOICE_OPTION_TEXT_MAX_LENGTH),
]);
