import { FC } from 'react';
import { Box } from '@mui/material';
import { LocalisedString } from 'op-storybook/lib/model/LocalisedString/LocalisedString';

import { PresentationIcon, VerticallySpaced } from '../../../Common/Component';
import AlertIcon from '../../../Assets/img/icons/streamline/alert-triangle.svg';
import { StyledFormResponseError } from './style';

type Props = {
  localisedErrors: LocalisedString[];
};

export const FormResponseErrors: FC<Props> = ({
  localisedErrors,
}) => (
  <VerticallySpaced gap={ 1 }>
    { localisedErrors.map(error => (
      <StyledFormResponseError key={ error.id }>
        <Box color="error.main">
          <div>
            <PresentationIcon
              IconComponent={ AlertIcon }
              size="xs"
            />
            { error.localisation }
          </div>
        </Box>
      </StyledFormResponseError>
    )) }
  </VerticallySpaced>
);
