export class SpaceDeducer {
  public static deduceSpaceId(): string {
    const host = window.location.hostname;

    if (host && this.hostnameIsLocal(host)) {
      return 'local';
    }
    return host.split('.')[0].replace(/[^a-z0-9-]/g, '');
  }

  private static hostnameIsLocal(host: string): boolean {
    return ['localhost', '10.0.2.2', 'op-app-web-web', 'op-app-web-http'].indexOf(host) > -1
      || host.startsWith('192.168')
      || host.includes('ngrok-free.app');
  }
}
