import { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { ContextCreator } from '@ourpeople/shared/Core/Utility/ContextCreator';

interface UnmodifiedImageSrcContextValue {
  assign: (key: string, file: File) => void;
  revoke: (key: string) => void;
  get: (key: string) => string | undefined;
}

export const UnmodifiedImageSrcContext = ContextCreator.withDisplayName<UnmodifiedImageSrcContextValue>('UnmodifiedImageSrcProvider', null);

export const UnmodifiedImageSrcProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [urls, setUrls] = useState<Map<string, string>>(new Map());

  const assign = useCallback((key: string, file: File) => {
    const url = URL.createObjectURL(file);
    setUrls(previousUrls => {
      const urls = new Map(previousUrls);
      urls.set(key, url);
      return urls;
    });
  }, []);

  const revoke = useCallback((key: string) => {
    const url = urls.get(key);

    if (!url) {
      return;
    }

    URL.revokeObjectURL(url);
    setUrls(previousUrls => {
      const urls = new Map(previousUrls);
      urls.delete(key);
      return urls;
    });
  }, [urls]);

  const get = useCallback((key: string) => {
    return urls.get(key);
  }, [urls]);

  const contextValue = useMemo(() => ({
    assign,
    revoke,
    get,
  }), [assign, revoke, get]);

  return (
    <UnmodifiedImageSrcContext.Provider value={ contextValue }>
      { children }
    </UnmodifiedImageSrcContext.Provider>
  );
};
