import { FetchResponse, useGet } from '../../Hooks';
import { Broadcast } from '../Model';

interface FetchBroadcastResponse {
  broadcast: Broadcast;
}

export const useFetchBroadcast = (broadcastId: string): FetchResponse<FetchBroadcastResponse> => (
  useGet(`/broadcasts/${ broadcastId }`)
);
