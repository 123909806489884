import {ChangeEventHandler, FC, useCallback, useEffect, useMemo, useState} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { IntlShape } from 'react-intl/src/types';
import { MenuItem, Select } from '@mui/material';

import { MappedSettingTypes } from '../../../Types';
import { Flex, ValidationErrorMessage, VerticallySpaced } from '../../../../../../Common/Component';
import {StyledChoiceSettingInput, StyledRenderedValue} from './style';
import { StyledCheckbox } from '../../../../../../Components/Input/styles';
import { ChoiceSettingValue } from '../ChoiceSettingValue';

type Props = {
  config: MappedSettingTypes['choice']['config'];
  value: MappedSettingTypes['choice']['value'];
  onValueChanged: (value: MappedSettingTypes['choice']['value']) => void;
  focusId?: string;
};

export const ChoiceSettingInput: FC<Props> = ({
  config,
  value,
  onValueChanged,
  focusId,
}) => {
  const intl = useIntl();
  const [error, setError] = useState<string | null>(null);
  const multiple = useMemo(
    () => config.maxSelections > 1,
    [config.maxSelections],
  );

  useEffect(() => {
    const error = validate(value, config, intl);
    setError(error);
  }, [value, config, intl]);

  const whenValueChanged: ChangeEventHandler<{ value: unknown }> = useCallback(event => {
    let selectedValue = event.target.value;

    if (typeof selectedValue === 'string') {
      selectedValue = [selectedValue];
    }

    onValueChanged(selectedValue as string[]);
  }, [onValueChanged]);

  const renderSelection = useCallback(
    () => (
      <StyledRenderedValue>
        <ChoiceSettingValue config={ config } value={ value } />
      </StyledRenderedValue>
    ),
    [value, config],
  );

  return (
    <StyledChoiceSettingInput>
      <VerticallySpaced gap={ 1 }>
        <Flex gap={ 1 }>
          <Select
            id={ focusId }
            variant="outlined"
            onChange={ whenValueChanged }
            value={ value }
            margin="dense"
            multiple={ multiple }
            displayEmpty
            renderValue={ renderSelection }
          >
            { (config.nullable || !value) && (
              <MenuItem
                disabled={ !config.nullable }
                value=""
              >
                <em>
                  <FormattedMessage
                    description="Label for choice setting with no value selected"
                    defaultMessage="No selection"
                  />
                </em>
              </MenuItem>
            ) }
            { Array
              .from(Object.entries(config.choices))
              .map(([choiceKey, choiceValue]) => (
                <MenuItem
                  key={ choiceKey }
                  value={ choiceKey }
                >
                  { multiple && (
                    <StyledCheckbox checked={ (value ?? []).indexOf(choiceKey) > -1 }/>
                  ) }
                  { choiceValue }
                </MenuItem>
              )) }
          </Select>
        </Flex>
        { error && <ValidationErrorMessage>{ error }</ValidationErrorMessage> }
      </VerticallySpaced>
    </StyledChoiceSettingInput>
  );
};

const validate = (
  value: MappedSettingTypes['choice']['value'],
  config: MappedSettingTypes['choice']['config'],
  intl: IntlShape,
): string | null => {
  if (value === null && config.nullable) {
    return null;
  }

  if (value === null) {
    return intl.formatMessage({
      id: 'settings.choiceSetting.nullError',
      defaultMessage: 'Missing value',
    });
  }

  if (value.length < config.minSelections) {
    return intl.formatMessage(
      {
        id: 'settings.choiceSetting.minSelectionsError',
        defaultMessage: 'Requires at least { minSelections, plural, one { # selection } other { # selections } }',
      },
      {
        minSelections: config.minSelections,
      },
    );
  }

  if (value.length > config.maxSelections) {
    return intl.formatMessage(
      {
        id: 'settings.choiceSetting.maxSelectionsError',
        defaultMessage: 'Requires at most { maxSelections, plural, one { # selection } other { # selections } }',
      },
      {
        maxSelections: config.maxSelections,
      },
    );
  }

  return null;
}
