import { Audience } from '../../Audiences/Model/Audience';
import { AudienceCreator, AudienceReader } from '../../Audiences/Utility';
import {
  DraftFormContent,
  DraftSingleContentForm,
  MinimalFormWithSingleContentCards,
  SingleContentFormAndCards,
} from '../Model';
import { ContentDefinitionRegistry } from '../../Content/Service';
import { DraftSingleContentCard } from '../../Content/Model';

export class FormFactory {
  public static createForm = (userId?: string): DraftSingleContentForm => ({
    name: null,
    categoryId: null,
    reportingAudiencePersonIds: userId ? [userId] : [],
    adminAudiencePersonIds: userId ? [userId] : [],
    notificationAudiencePersonIds: userId ? [userId] : [],
    viewAudience: AudienceCreator.createBlankAudience(),
    cards: [],
    anonymousSubmissionsEnabled: false,
    statusTrackerEnabled: false,
  });

  public static createFormFromSaved = (
    savedFormAndCards: SingleContentFormAndCards,
    adminAudience: Audience,
    reportingAudience: Audience,
    viewAudience: Audience,
    notificationAudience: Audience,
  ): DraftSingleContentForm => {
    const reportingAudiencePersonIds = AudienceReader.personIds(reportingAudience);
    const adminAudiencePersonIds = AudienceReader.personIds(adminAudience);
    const notificationAudiencePersonIds = AudienceReader.personIds(notificationAudience);
    return {
      name: savedFormAndCards.form.name,
      reportingAudiencePersonIds: reportingAudiencePersonIds.length ? reportingAudiencePersonIds : null,
      adminAudiencePersonIds: adminAudiencePersonIds.length ? adminAudiencePersonIds : null,
      notificationAudiencePersonIds: notificationAudiencePersonIds.length ? notificationAudiencePersonIds : null,
      viewAudience,
      categoryId: savedFormAndCards.form.category ? savedFormAndCards.form.category.id : null,
      cards: [],
      statusTrackerEnabled: savedFormAndCards.form.statusTrackerEnabled,
      anonymousSubmissionsEnabled: savedFormAndCards.form.anonymousSubmissionsEnabled || false,
    };
  };

  public static createDraftFormFromMinimalForm = (
    registry: ContentDefinitionRegistry,
    { name, statusTrackerEnabled, cards }: MinimalFormWithSingleContentCards,
  ): DraftSingleContentForm => ({
    name,
    statusTrackerEnabled,
    reportingAudiencePersonIds: null,
    adminAudiencePersonIds: null,
    notificationAudiencePersonIds: null,
    viewAudience: AudienceCreator.createBlankAudience(),
    cards: cards.map(card => registry.transformCard(card).card as DraftSingleContentCard<DraftFormContent>),
    anonymousSubmissionsEnabled: false,
    categoryId: null,
  });
}
