export const broadcastPaths = {
  // User paths
  USER_FEED: '/new/feed/:broadcastId?',

  // Admin paths
  ADMIN_LIST: '/new/admin/broadcasts',
  ADMIN_VIEW: '/new/admin/broadcasts/:broadcastId',
  ADMIN_LIST_RECIPIENTS: '/new/admin/broadcasts/:broadcastId/recipients',
  ADMIN_EDIT: '/new/admin/broadcasts/:broadcastId/edit',
  ADMIN_CREATE: '/new/admin/broadcasts/create',
};
