import { ComponentType, FunctionComponent, lazy, ReactNode, Suspense, useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@mui/material';
import { Link, Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';
import { LoadingCard } from '@ourpeople/shared/Core/Component/Feedback';
import {
  EmailVerificationPromptContainer
} from '@ourpeople/shared/Core/Component/Content/EmailVerificationPromptContainer/EmailVerificationPromptContainer';
import { Location } from 'history';
import { BreakpointContext } from 'op-storybook/lib/providers/BreakpointProvider/BreakpointProvider';

import { ExternalLinkButton, Fullscreen, FullscreenError, Padded } from '../../../Components';
import { Settings } from '../../../Sections';
import { useEnvironmentSettings, useMounted } from '../../../Common/Hook';
import { usePermissions } from '../../../Security/Hook';
import { Box, GuardedRoute, LoadingContainer, NotFoundError, UploadWidget } from '../../../Common/Component';
import { Notices } from '../../../Common/Model';
import { BroadcastsRouter } from '../../../Broadcasts/Routing';
import { InboxRouter } from '../../../Inbox/Routing';
import { BroadcastsPermission } from '../../../Broadcasts/Model';
import { LocalStorageHookProvider } from '../../../Common/Provider/LocalStorageHookProvider';
import { ChatsRouter as AdminChatRouter } from '../../../Chat/Routing';
import { NoticeContext, SpaceContext } from '../../../Core/Context';
import { importRetry } from '../../../Core/Utility';
import { PersonalFormsRouter } from '../../../Forms/Routing/PersonalFormsRouter/PersonalFormsRouter';
import { useContextOrThrow } from '../../../Core/Hook';
import { FileUploaderProvider } from '../../../Common/Provider';
import { PushMessageProvider } from '../../../Core/Provider/PushMessageProvider';
import { PushMessageDefinitionRegistryProvider } from '../../../Core/Provider/PushMessageDefinitionRegistryProvider';
import { PushMessageDefinition } from '../../../Core/Model/PushMessage';
import { PushDeviceRegistrationProvider } from '../../../Core/Provider/PushDeviceRegistrationProvider';
import {
  BroadcastReceivedPushMessageDefinition
} from '../../../Notifications/Service/BroadcastReceivedPushMessageDefinition';
import {
  ChatMessageReceivedPushMessageDefinition
} from '../../../Notifications/Service/ChatMessageReceivedPushMessageDefinition';
import { ConfirmWorkStatus } from '../../../Core/Component/ConfirmWorkStatus/ConfirmWorkStatus';
import { FallbackPushMessageDefinition } from '../../../Notifications/Service/FallbackPushMessageDefinition';
import { UnmodifiedImageSrcProvider } from '../../../Common/Provider/UnmodifiedImageSrcProvider';
import {
  RichTextPlaceholderDefinitionRegistryProvider
} from '../../../Common/Provider/RichTextPlaceholderDefinitionRegistryProvider';
import { Root } from '../../../New/Core/Component/Root';
import { TextBlockDefinition } from '../../../New/Broadcasts/Service/Blocks/TextBlockDefinition';
import { BroadcastBlockDefinitionRegistry } from '../../../New/Broadcasts/Context/BroadcastBlockDefinitionRegistry';
import { ButtonBlockDefinition } from '../../../New/Broadcasts/Service/Blocks/ButtonBlockDefinition';
import { ImageBlockDefinition } from '../../../New/Broadcasts/Service/Blocks/ImageBlockDefinition';
import { EventBlockDefinition } from '../../../New/Broadcasts/Service/Blocks/EventBlockDefinition';
import { FormBlockDefinition } from '../../../New/Broadcasts/Service/Blocks/FormBlockDefinition';
import { broadcastPaths } from '../../../New/Broadcasts/Routing/broadcastPaths';
import { VideoBlockDefinition } from '../../../New/Broadcasts/Service/Blocks/VideoBlockDefinition';
import {
  AudienceConditionDefinitionRegistryProvider
} from '../../../New/Audience/Provider/AudienceConditionDefinitionRegistryProvider';
import { StorageNodeBlockDefinition } from '../../../New/Broadcasts/Service/Blocks/StorageNodeBlockDefinition';
import { MobileNavigation } from '../../../Common/Component/MobileNavigation/MobileNavigation';
import { SidebarLayout } from '../../../New/Core/Component/SidebarLayout';
import { useBeamer } from '@ourpeople/shared/Core/Hook/useBeamer';

const UserChatRouter = lazy(() => importRetry<{
  default: ComponentType
}>(() => import('../../../Chat/Routing/User/ChatRouter/ChatRouter')));
const DashboardRouter = lazy(() => importRetry<{
  default: ComponentType;
}>(() => import('../../../Reporting/Routing/Dashboard')));
const FormsRouter = lazy(() => importRetry<{ default: ComponentType; }>(() => import('../../../Forms/Routing')));
const EventsRouter = lazy(() => importRetry<{ default: ComponentType; }>(() => import('../../../Events/Routing')));
const ManageFilesRouter = lazy(() => importRetry<{
  default: ComponentType;
}>(() => import('../../../Files/Routing/ManageFilesRouter/ManageFilesRouter')));
const FilesRouter = lazy(() => importRetry<{
  default: ComponentType;
}>(() => import('../../../Files/Routing/FilesRouter/FilesRouter')));
const AccountRouter = lazy(() => importRetry<{
  default: ComponentType;
}>(() => import('../../../Account/Routing/AccountRouter/AccountRouter')));
const PeopleRouter = lazy(
  () => importRetry<{ default: ComponentType<{ notices?: Notices }>; }>(
    () => import('../../../People/Routing')
  )
);
const ReportsRouter = lazy(() => importRetry<{
  default: ComponentType;
}>(() => import('../../../Reporting/Routing/Reports')));
const OnboardingRouter = lazy(() => importRetry<{
  default: ComponentType;
}>(() => import('../../../Onboarding/Routing/OnboardingRouter/OnboardingRouter')));
const IntegrationsRouter = lazy(() => importRetry<{
  default: ComponentType;
}>(() => import('../../../Integrations/Routing/IntegrationsRouter')));

const pushMessageDefinitions: PushMessageDefinition<any>[] = [
  new BroadcastReceivedPushMessageDefinition(),
  new ChatMessageReceivedPushMessageDefinition(),
  new FallbackPushMessageDefinition(),
];

export const AuthorisedLayout: FunctionComponent = () => {
  const { space } = useContext(SpaceContext);
  const { permissionAvailable } = usePermissions();
  const match = useRouteMatch<{ section: string }>('/:section');
  const section = match?.params.section ?? '';
  const [notices, setNotices] = useState<Notices>();
  const { getNotices } = useContextOrThrow(NoticeContext);
  const mounted = useMounted();
  const { broadcastsV2Enabled = false } = useEnvironmentSettings();
  const intl = useIntl();
  const screenWidth = useContextOrThrow(BreakpointContext);
  useBeamer();

  useEffect(() => {
    getNotices()
      .then(notices => {
        if (!mounted.current) {
          return;
        }

        setNotices(notices);
      })
      .catch(() => { /* Don't show notices */
      })
  }, [getNotices, mounted]);

  const rootRedirect = permissionAvailable(BroadcastsPermission.ME)
    ? '/inbox'
    : '';
  const userCanUseConsole = rootRedirect !== '';

  return space
    ? (
      userCanUseConsole
        ? (
          <LocalStorageHookProvider>
            <RichTextPlaceholderDefinitionRegistryProvider>
              <ConfirmWorkStatus>
                <PushMessageDefinitionRegistryProvider
                  pushMessageDefinitions={ pushMessageDefinitions }
                >
                  <PushDeviceRegistrationProvider>
                    <PushMessageProvider>
                      <UnmodifiedImageSrcProvider>
                        <FileUploaderProvider>
                          <AudienceConditionDefinitionRegistryProvider>
                            <EmailVerificationPromptContainer/>
                            <SidebarLayout>
                              <Suspense fallback={ <LoadingContainer><LoadingCard/></LoadingContainer> }>
                                <Switch>
                                  <GuardedRoute
                                    condition={ broadcastsV2Enabled }
                                    path="/new"
                                  >
                                    <BroadcastBlockDefinitionRegistry
                                      definitions={ [
                                        new StorageNodeBlockDefinition(intl),
                                        new VideoBlockDefinition(intl),
                                        new EventBlockDefinition(intl),
                                        new FormBlockDefinition(intl),
                                        new TextBlockDefinition(intl),
                                        new ImageBlockDefinition(intl),
                                        new ButtonBlockDefinition(intl),
                                      ] }
                                      defaultBlockKind="text"
                                    >
                                      <Root/>
                                    </BroadcastBlockDefinitionRegistry>
                                  </GuardedRoute>
                                  <ErrorBoundary
                                    key={ section }
                                    fallback={ (
                                      <FullscreenError>
                                        <p>
                                          <FormattedMessage
                                            id="error.genericErrorBoundary.message"
                                            description="Error message for when the error boundary is triggered."
                                            defaultMessage="An unexpected error has occurred. Please try refreshing the page."
                                          />
                                        </p>
                                        <p>
                                          <FormattedMessage
                                            id="error.genericErrorBoundary.contactCustomerSuccess"
                                            description="Explanation to contact customer success if you can't access console"
                                            defaultMessage="If the problem persists, <cta>try contacting customer success</cta>."
                                            values={ {
                                              cta: (chunks: (string | ReactNode)[]) => (
                                                <ExternalLinkButton
                                                  bordered={ false }
                                                  href="https://get.ourpeople.help"
                                                >
                                                  { chunks }
                                                </ExternalLinkButton>
                                              )
                                            } }
                                          />
                                        </p>
                                      </FullscreenError>
                                    ) }
                                  >
                                    <Switch>
                                      <Redirect exact from="/" to={ rootRedirect }/>
                                      <Route
                                        path={ [
                                          ...broadcastsV2Enabled ? [] : ['/inbox'],
                                          '/my-forms',
                                          '/me/chat',
                                        ] }
                                      >
                                        <main css={ { flexGrow: 1 } }>
                                          <Route path="/my-forms" component={ PersonalFormsRouter }/>
                                          <Route path="/me/chat" component={ UserChatRouter }/>
                                          <Route path="/inbox" component={ InboxRouter }/>
                                        </main>
                                      </Route>
                                      <Route>
                                        <main
                                          css={ {
                                            height: '100%',
                                            flexShrink: 1,
                                            flexGrow: 1,
                                            minWidth: 0,
                                          } }
                                        >
                                          <Switch>
                                            <Route path="/people" render={ () => <PeopleRouter notices={ notices }/> }/>
                                            {
                                              broadcastsV2Enabled
                                                ? <Redirect from="/broadcasts" to={ `${ broadcastPaths.ADMIN_LIST }` }/>
                                                : <Route path="/broadcasts" component={ BroadcastsRouter }/>
                                            }
                                            <Redirect from="/inbox" to={ `${ broadcastPaths.USER_FEED }` }/>
                                            <Route path="/forms" component={ FormsRouter }/>
                                            <Route path="/chats" component={ AdminChatRouter }/>
                                            <Route path="/account" component={ AccountRouter }/>
                                            <Route path="/tags">
                                              { (({ location }) => (
                                                <Redirect
                                                  to={ {
                                                    ...location,
                                                    pathname: location.pathname.replace('/tags', '/settings/tags'),
                                                  } }
                                                />
                                              )) as (props: { location: Location }) => React.JSX.Element }
                                            </Route>
                                            <Route path="/dashboard" component={ DashboardRouter }/>
                                            <Route path="/reports" component={ ReportsRouter }/>
                                            <Route path="/settings" component={ Settings }/>
                                            <Route path="/events" component={ EventsRouter }/>
                                            <Route path="/files" component={ ManageFilesRouter }/>
                                            <Route path="/me/files" component={ FilesRouter }/>
                                            <Route path="/onboarding" component={ OnboardingRouter }/>
                                            <Route path="/integrations" component={ IntegrationsRouter }/>
                                            <Route>
                                              <Padded>
                                                <Box>
                                                  <NotFoundError>
                                                    <FormattedMessage
                                                      id="pageNotFound"
                                                      defaultMessage="Sorry, the page you requested could not be found."
                                                    />
                                                  </NotFoundError>
                                                </Box>
                                              </Padded>
                                            </Route>
                                          </Switch>
                                        </main>
                                      </Route>
                                    </Switch>
                                    { screenWidth.lessThan.sm && (
                                      <div
                                        css={ {
                                          position: 'fixed',
                                          top: 0,
                                          width: '100%',
                                          zIndex: 1,
                                        } }
                                      >
                                        <MobileNavigation/>
                                      </div>
                                    ) }
                                  </ErrorBoundary>
                                </Switch>
                              </Suspense>
                            </SidebarLayout>
                            <UploadWidget/>
                          </AudienceConditionDefinitionRegistryProvider>
                        </FileUploaderProvider>
                      </UnmodifiedImageSrcProvider>
                    </PushMessageProvider>
                  </PushDeviceRegistrationProvider>
                </PushMessageDefinitionRegistryProvider>
              </ConfirmWorkStatus>
            </RichTextPlaceholderDefinitionRegistryProvider>
          </LocalStorageHookProvider>
        )
        : (
          <FullscreenError>
            <p>
              <FormattedMessage
                id="error.cannotUseConsole"
                description="Error message for when the currently logged in user doesn't have permission to view console"
                defaultMessage="You do not currently have permission to access console."
              />
            </p>
            <p>
              <FormattedMessage
                id="error.cannotUseConsole.contactCustomerSuccess"
                description="Explanation to contact customer success if you can't access console"
                defaultMessage="If you believe this to be an error, <cta>try contacting customer success</cta>."
                values={ {
                  cta: (chunks: (string | ReactNode)[]) => (
                    <ExternalLinkButton bordered={ false } href="https://get.ourpeople.help">
                      { chunks }
                    </ExternalLinkButton>
                  )
                } }
              />
            </p>
            <p>
              <FormattedMessage
                id="action.logout"
                description="Navigation label for Logout"
                defaultMessage="<cta>Logout</cta>"
                values={ {
                  cta: (chunks: (string | ReactNode)[]) => (
                    <Button variant="contained" color="primary" component={ Link } to="/logout">
                      { chunks }
                    </Button>
                  )
                } }
              />
            </p>
          </FullscreenError>
        )
    )
    : (
      <Fullscreen>
        <LoadingCard/>
      </Fullscreen>
    );
};
