import { useIntl } from 'react-intl';
import { default as React, FC, useCallback } from 'react';

import { ContentEditorProps } from '../../../../Content/Model';
import { FieldValidationErrors, NoMarginTextField, VerticallySpaced } from '../../../../Common/Component';
import {
  RATING_TITLE_MAX_LENGTH,
  RATING_TITLE_MIN_LENGTH,
  RatingEditorContent,
  RatingRequestValidator
} from '../../../Service';
import { CharacterCount } from '../../../../Content/Component';
import { useInputChangeEventHandler } from '../../../../Common/Hook';

export const RatingRequestEditor: FC<ContentEditorProps<RatingEditorContent>> = ({
  editorContent,
  onEditorContentChanged,
  validation,
  onValidationChanged,
}) => {
  const intl = useIntl();
  const titleFieldLabel = intl.formatMessage({
    id: 'broadcasts.content.rating.fields.title.label',
    description: 'Label for title field of broadcasts rating request editor.',
    defaultMessage: 'Title',
  });

  const whenTitleChanged = useInputChangeEventHandler(useCallback(title => (
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          title,
        },
      },
    })
  ), [editorContent, onEditorContentChanged]));

  const whenTitleBlurred = useCallback(() => {
    onValidationChanged({
      errors: [],
      children: {
        content: {
          errors: [],
          children: {
            ...validation?.children.content?.children,
            title: RatingRequestValidator.validateTitle(editorContent.card.content.title),
          },
        },
      },
    })
  }, [editorContent.card.content.title, onValidationChanged, validation?.children.content?.children]);

  return (
    <VerticallySpaced gap={ 2 }>
      <VerticallySpaced gap={ 1 }>
        <h4>{ titleFieldLabel }</h4>
        <NoMarginTextField
          fullWidth
          value={ editorContent.card.content.title }
          onChange={ whenTitleChanged }
          onBlur={ whenTitleBlurred }
        />
        <CharacterCount
          current={ editorContent.card.content.title.length || 0 }
          minimum={ RATING_TITLE_MIN_LENGTH }
          maximum={ RATING_TITLE_MAX_LENGTH }
        />
        <FieldValidationErrors
          fieldName={ titleFieldLabel }
          validationErrors={ validation?.children.content?.children.title?.errors || [] }
        />
      </VerticallySpaced>
    </VerticallySpaced>
  );
};
