import { IntlShape } from 'react-intl';

import { SingleContentCard, DraftContent, EditorContent } from '../../../../Content/Model';
import QuizRequestIcon from '../../../../Assets/img/icons/monochrome/content/quiz.svg';
import { UniqueIdGenerator } from '../../../../Common/Utility';
import { CardTransformer } from '../../../../Content/Utility';
import { QuizRequestEditor, QuizRequestPreview } from '../../../Component';
import { QuizRequestValidator } from '../../Validators';
import { BroadcastContent, BroadcastContentDefinition } from '../../../Model';
import { LiveQuizContent } from '../../../../Inbox/Component/LiveContent/LiveQuizContent/LiveQuizContent';
import { ChoiceResponse } from '../../../../Inbox/Model';
import { EnvironmentSettings } from '../../../../Models';

type QuizOptionType = 'correctchoiceoption' | 'choiceoption';

type CorrectQuizOption = {
  id: number | string;
  type: 'correctchoiceoption';
  correct_option: true;
  text: string;
};

type IncorrectQuizOption = {
  id: number | string;
  type: 'choiceoption';
  correct_option: false;
  text: string;
};

export type QuizOption = CorrectQuizOption | IncorrectQuizOption;

export type DraftQuizOption = {
  id: string;
  type: QuizOptionType;
  text: string;
};

export type DraftQuizRequest = DraftContent & {
  type: 'quizquestion';
  title: string;
  options: DraftQuizOption[];
};

export type QuizRequest = BroadcastContent & {
  type: 'quizquestion';
  title: string;
  options: QuizOption[];
};

export type QuizEditorContent = EditorContent<DraftQuizRequest>

export class QuizRequestDefinition implements BroadcastContentDefinition<QuizRequest, QuizEditorContent, ChoiceResponse> {
  public readonly id = 'quiz';
  public readonly availableToCreate = true;
  public readonly new = false;
  public readonly richText = false;
  public readonly contentTypes = ['quizquestion'];
  public readonly categoryId = 'feedback';
  public readonly IconComponent = QuizRequestIcon;
  public readonly EditorComponent = QuizRequestEditor;
  public readonly PreviewComponent = QuizRequestPreview;
  public readonly LiveComponent = LiveQuizContent;
  public readonly SubmittedComponent = (): null => null;
  public validate = QuizRequestValidator.validate;

  public definesContentType = (type: string): boolean => (
    this.contentTypes.includes(type)
  );

  public getContentTitle = (intl: IntlShape, content: QuizRequest): string => (
    content.title || this.getDefaultTitle(intl)
  );

  public getLocalisedType = (intl: IntlShape): string => (
    intl.formatMessage({
      id: 'broadcasts.content.choice.type',
      description: 'Localised name for choice request type.',
      defaultMessage: 'Quiz',
    })
  );

  public getDraftContentTitle = (intl: IntlShape, draftContent: DraftQuizRequest): string => (
    draftContent.title || this.getDefaultTitle(intl)
  );

  public hasRequiredFeatureFlags = (environmentSettings: EnvironmentSettings): boolean => (
    !!environmentSettings.quizTypeEnabled
  );

  public createEditorContent = (): QuizEditorContent => ({
    id: UniqueIdGenerator.generate(),
    card: {
      content: {
        type: 'quizquestion',
        title: '',
        options: [
          {
            id: UniqueIdGenerator.generate(),
            type: 'correctchoiceoption',
            text: '',
          },
          {
            id: UniqueIdGenerator.generate(),
            type: 'choiceoption',
            text: '',
          },
        ],
      },
      mandatory: false,
      image: null,
    },
  });

  public transformCard = (card: SingleContentCard<QuizRequest>): QuizEditorContent => ({
    id: card.id,
    card: CardTransformer.transformCard(card, {
      type: card.content.type,
      title: card.content.title,
      options: card.content.options.map(({ id, correct_option, ...option }) => ({
        id: id?.toString() || UniqueIdGenerator.generate(),
        ...option,
      })),
    }),
  });

  public cloneEditorContent = (editorContent: QuizEditorContent): QuizEditorContent => ({
    ...editorContent,
    id: UniqueIdGenerator.generate(),
    card: {
      ...editorContent.card,
      content: {
        ...editorContent.card.content,
        options: editorContent.card.content.options.map(option => ({
          ...option,
          id: UniqueIdGenerator.generate(),
        })),
      },
    },
  });

  private getDefaultTitle = (intl: IntlShape): string => (
    intl.formatMessage({
      id: 'broadcasts.content.quiz.defaultTitle',
      description: 'Default title for quiz content.',
      defaultMessage: 'Untitled quiz card',
    })
  );
}
