import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { LocalisedString } from 'op-storybook/lib/model/LocalisedString/LocalisedString';

import { TagType } from '../Model';

export const useLocalisedTagTypes = () => {
  const intl = useIntl();
  return useMemo<LocalisedString<TagType>[]>(() => [
    {
      id: TagType.Region,
      localisation: intl.formatMessage({
        description: 'Label for region tag type.',
        defaultMessage: 'Region',
      }),
    },
    {
      id: TagType.Department,
      localisation: intl.formatMessage({
        description: 'Label for department tag type.',
        defaultMessage: 'Department',
      }),
    },
    {
      id: TagType.Team,
      localisation: intl.formatMessage({
        id: 'tags.import.autocompleteTeam',
        description: 'Label for team tag type.',
        defaultMessage: 'Team',
      }),
    },
    {
      id: TagType.JobTitle,
      localisation: intl.formatMessage({
        description: 'Label for job title tag type.',
        defaultMessage: 'Job title',
      }),
    },
    {
      id: TagType.Skill,
      localisation: intl.formatMessage({
        description: 'Label for skill tag type.',
        defaultMessage: 'Skill',
      }),
    },
  ], [intl]);
};
