import { FC, PropsWithChildren } from 'react'

import { StyledCard, StyledCardContainer, StyledCardOutline, StyledContainer } from './style';

export const LiveCardContainer: FC<PropsWithChildren> = ({ children }) => (
  <StyledContainer>
    <StyledCardContainer
      data-testid="inbox-preview-body"
    >
      <StyledCardOutline>
        <StyledCard>
          { children }
        </StyledCard>
      </StyledCardOutline>
    </StyledCardContainer>
  </StyledContainer>
);
