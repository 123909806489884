import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { FilesPermission, NodeAndUpload } from '../Model';
import { usePermissions } from '../../Security/Hook';
import LockIcon from '../../Assets/img/icons/streamline/lock.svg';
import ReplaceIcon from '../../Assets/img/icons/streamline/refresh.svg';
import RenameIcon from '../../Assets/img/icons/streamline/rename.svg';
import DownloadIcon from '../../Assets/img/icons/streamline/download.svg';
import { ContextMenuAction } from '../../Common/Component/ContextMenu/ContextMenu';

export const useNodeActions = (
  nodeAndUpload: NodeAndUpload,
  deleteAction: ContextMenuAction,
  downloadFile: (id: string) => void,
  onPermissionsActionClicked: () => void,
  onRenameActionClicked: () => void,
  onReplaceActionClicked: () => void,
  performingAction: boolean,
): ContextMenuAction[] => {
  const intl = useIntl();
  const { permissionAvailable, guardedCallback } = usePermissions();
  const isFile = nodeAndUpload.node.type === 'file';

  return useMemo(() => ([
    ...(permissionAvailable(FilesPermission.UPDATE)
      ? [
        {
          id: 'edit-permissions',
          IconComponent: LockIcon,
          onClick: guardedCallback(onPermissionsActionClicked, [FilesPermission.UPDATE]),
          label: intl.formatMessage({
            description: 'Label for edit permissions action',
            defaultMessage: 'Permissions',
          }),
          busy: performingAction,
          disabled: performingAction,
        },
        ...(isFile
          ? [
            {
              id: 'replace',
              IconComponent: ReplaceIcon,
              onClick: guardedCallback(onReplaceActionClicked, [FilesPermission.UPDATE]),
              label: intl.formatMessage({
                description: 'Label for file replace action',
                defaultMessage: 'Replace',
              }),
              busy: performingAction,
              disabled: performingAction,
            }
          ]
          : []),
        {
          id: 'rename',
          IconComponent: RenameIcon,
          onClick: guardedCallback(onRenameActionClicked, [FilesPermission.UPDATE]),
          label: intl.formatMessage({
            description: 'Label for rename action',
            defaultMessage: 'Rename',
          }),
          busy: performingAction,
          disabled: performingAction,
        },
      ]
      : []),
    ...(permissionAvailable(FilesPermission.READ) && nodeAndUpload.node.type === 'file'
      ? [
        {
          id: 'download',
          component: 'a',
          href: `/api/uploads/${ nodeAndUpload.upload.id }/download`,
          target: '_blank',
          rel: 'noreferrer',
          IconComponent: DownloadIcon,
          onClick: guardedCallback(
            () => (
              nodeAndUpload.node.type === 'file' && downloadFile(`${ nodeAndUpload.upload.id }`)
            ),
            [FilesPermission.READ],
          ),
          label: intl.formatMessage({
            description: 'Label for file download action',
            defaultMessage: 'Download',
          }),
          busy: performingAction,
          disabled: performingAction,
        }
      ]
      : []),
    ...(permissionAvailable(FilesPermission.DELETE) ? [deleteAction] : []),
  ]), [
    downloadFile,
    guardedCallback,
    intl,
    isFile,
    nodeAndUpload.node.type,
    nodeAndUpload.upload?.id,
    onPermissionsActionClicked,
    onRenameActionClicked,
    performingAction,
    permissionAvailable,
    deleteAction,
    onReplaceActionClicked
  ]);
};
