import { ComponentProps, FC, FocusEventHandler, ReactNode, useCallback, useRef } from 'react';

import { StyledFieldContainer, StyledFieldInner, StyledFormControl } from './style';
import { TextFieldLeadingText } from '../../../lib/components/TextFieldLeadingText/TextFieldLeadingText';
import { TextFieldTrailingButton } from '../../../lib/components/TextFieldTrailingButton/TextFieldTrailingButton';
import { LabelledFormField } from '../../../lib/components/input/LabelledFormField/LabelledFormField';

type Props = {
  name?: string;
  label?: string;
  value: string;
  onChange?: (value: string) => void;
  leadingText?: string;
  trailingButton?: {
    content: ReactNode;
    onClick: () => void;
  };
  errorMessages?: string[];
  tip?: string;
  disabled?: boolean;
  placeholder?: string;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  inputProps?: ComponentProps<'input'>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  className?: string;
};

export const TextField: FC<Props> = ({
  name = '',
  label,
  value,
  leadingText,
  trailingButton,
  errorMessages = [],
  tip,
  disabled,
  onChange,
  placeholder,
  startAdornment,
  endAdornment,
  inputProps,
  onBlur,
  className,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const focusInput = useCallback(() => {
    if (document.activeElement === inputRef.current) {
      return;
    }

    inputRef.current?.focus()
  }, [inputRef]);

  const whenBlurred: FocusEventHandler<HTMLInputElement> = useCallback((event) => {
    onBlur && onBlur(event);
  }, [onBlur]);

  return (
    <LabelledFormField
      label={ label }
      name={ name }
      className={ className }
      errorMessages={ errorMessages }
      tip={ tip }
    >
      <StyledFieldContainer>
        { leadingText && (
          <TextFieldLeadingText
            onClick={ focusInput }
            disabled={ disabled }
          >
            { leadingText }
          </TextFieldLeadingText>
        ) }
        <StyledFieldInner
          error={ !!errorMessages.length }
          onClick={ focusInput }
          roundStart={ !leadingText }
          roundEnd={ !trailingButton }
          disabled={ !!disabled }
          padStart={ !!startAdornment }
          padEnd={ !!endAdornment }
        >
          { startAdornment }
          <StyledFormControl
            { ...inputProps }
            readOnly={ !onChange }
            hasEndAdornment={ !!endAdornment }
            hasStartAdornment={ !!startAdornment }
            value={ value || '' }
            onChange={ event => onChange && onChange(event.currentTarget.value) }
            onBlur={ whenBlurred }
            ref={ inputRef }
            disabled={ disabled }
            placeholder={ placeholder }
          />
          { endAdornment }
        </StyledFieldInner>
        { trailingButton && (
          <TextFieldTrailingButton
            onClick={ trailingButton.onClick }
            type="button"
          >
            { trailingButton.content }
          </TextFieldTrailingButton>
        ) }
      </StyledFieldContainer>
    </LabelledFormField>
  );
};
