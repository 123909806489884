import { useIntl } from 'react-intl';
import { useMemo } from 'react';
import { LocalisedString } from 'op-storybook/lib/model/LocalisedString/LocalisedString';

import { SvgComponent } from '../../../../../../src/react/Common/Model';
import ParagraphIcon from '../../../../../../src/react/Assets/img/icons/streamline/text-style.svg';
import HeadingIcon from '../../../../../../src/react/Assets/img/icons/streamline/small-caps.svg';
import UlIcon from '../../../../../../src/react/Assets/img/icons/streamline/list-bullets.svg';
import OlIcon from '../../../../../../src/react/Assets/img/icons/streamline/list-numbers.svg';

export type BlockType = 'h1' | 'h2' | 'ol' | 'paragraph' | 'ul';

export type FormattingOption = LocalisedString<BlockType> & { IconComponent: SvgComponent };

export const useLocalisedBlockFormattingOptions = (): FormattingOption[] => {
  const intl = useIntl();
  return useMemo<FormattingOption[]>(() => [
    {
      id: 'paragraph',
      localisation: intl.formatMessage({
        description: 'Label for paragraph text style in rich text editor.',
        defaultMessage: 'Normal',
      }),
      IconComponent: ParagraphIcon,
    },
    {
      id: 'h1',
      localisation: intl.formatMessage({
        description: 'Label for heading text style in rich text editor.',
        defaultMessage: 'Heading',
      }),
      IconComponent: HeadingIcon,
    },
    {
      id: 'h2',
      localisation: intl.formatMessage({
        description: 'Label for subheading text style in rich text editor.',
        defaultMessage: 'Subheading',
      }),
      IconComponent: HeadingIcon,
    },
    {
      id: 'ul',
      localisation: intl.formatMessage({
        description: 'Label for bulleted list text style in rich text editor.',
        defaultMessage: 'Bulleted list',
      }),
      IconComponent: UlIcon,
    },
    {
      id: 'ol',
      localisation: intl.formatMessage({
        description: 'Label for numbered list text style in rich text editor.',
        defaultMessage: 'Numbered list',
      }),
      IconComponent: OlIcon,
    },
  ], [intl]);
};
