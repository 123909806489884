import { FC, useMemo } from 'react';
import { TransitionProps } from '@mui/material/transitions';

import { ShareDraftDialog } from '..';
import { useFetchAudience } from '../../../Audiences/Hook';
import { AudienceReader } from '../../../Audiences/Utility';

type Props = {
  audienceId: string;
  title: string;
  open: boolean;
  onShare: (personIds: string[]) => void;
  onCancel: () => void;
  TransitionProps?: TransitionProps;
}
export const ListShareDraftDialog: FC<Props> = ({
  audienceId,
  title,
  onShare,
  onCancel,
  open,
  TransitionProps,
}) => {
  const [fetchAudienceResult] = useFetchAudience(audienceId);
  const personIds = useMemo(() => fetchAudienceResult?.content && AudienceReader.personIds(fetchAudienceResult.content), [fetchAudienceResult?.content]);

  return (
    <ShareDraftDialog
      TransitionProps={ TransitionProps }
      title={ title }
      error={ !!fetchAudienceResult?.error }
      personIds={ personIds }
      onCancel={ onCancel }
      onShare={ onShare }
      open={ open }
    />
  );
};
