import { addDays, subDays } from 'date-fns';

import { DateRangePickerOffset } from '../Model/DateRangePicker';
import { DateRange } from '../../Reporting/Model';

export const transformOffsetToRange = (value: DateRangePickerOffset): DateRange => {
  const now = new Date();
  return value.from === 'start'
    ? {
      start: now.toISOString(),
      end: value.offset === null ? '' : addDays(now, value.offset).toISOString(),
    }
    : {
      start: value.offset === null ? '' : subDays(now, value.offset).toISOString(),
      end: now.toISOString(),
    };
};
