import { TransitionProps } from '@mui/material/transitions';
import { FunctionComponent as FC, useMemo } from 'react';
import { Dialog, DialogTitle } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  Flex,
  FlexPullRight,
  IconButton
} from '../../../../../Common/Component';
import CloseIcon from '../../../../../Assets/img/icons/streamline/close.svg';
import { EmailNotificationIcon, PushNotificationIcon, SmsNotificationIcon } from '../../../../../Broadcasts/Component';
import { BroadcastNotificationSettings } from '../../../../../Broadcasts/Model';
import { StyledDialogContent, StyledNotificationDetails } from './style';

type Props = {
  open: boolean;
  onClose: () => void;
  TransitionProps: TransitionProps;
  notificationSettings: BroadcastNotificationSettings,
};

export const NotificationsDialog: FC<Props> = ({
  open,
  onClose,
  TransitionProps,
  notificationSettings,
}) => {
  const intl = useIntl();
  const cancelLabel = useMemo(() => (
    intl.formatMessage({
      description: 'Label for closing the broadcast report notification dialog',
      defaultMessage: 'Close',
    })
  ), [intl]);
  const defaultNotificationMessage = useMemo(() => (
    intl.formatMessage({
      description: 'Message displayed for a notification channel in a broadcast report when the notification was sent using the default message.',
      defaultMessage: 'This notification was sent using the default message.',
    })
  ), [intl]);

  return (
    <Dialog
      open={ open }
      onClose={ onClose }
      TransitionProps={ TransitionProps }
      maxWidth={ 'lg' }
      fullWidth
    >
      <StyledDialogContent>
        <DialogTitle>
          <Flex
            noWrap
            align="flex-start"
          >
            <span>
              <FormattedMessage
                description="Broadcast report notifications dialog title"
                defaultMessage="Notifications"
              />
            </span>
            <FlexPullRight>
              <IconButton
                IconComponent={ CloseIcon }
                onClick={ onClose }
                label={ cancelLabel }
                size="small"
              />
            </FlexPullRight>
          </Flex>
        </DialogTitle>
        {
          notificationSettings.push.send && (
            <StyledNotificationDetails>
              <PushNotificationIcon/>
              { defaultNotificationMessage }
            </StyledNotificationDetails>
          )
        }
        {
          notificationSettings.sms.send && (
            <StyledNotificationDetails>
              <SmsNotificationIcon/>
              { defaultNotificationMessage }
            </StyledNotificationDetails>
          )
        }
        {
          notificationSettings.email.send && (
            <StyledNotificationDetails>
              <EmailNotificationIcon/>
              { defaultNotificationMessage }
            </StyledNotificationDetails>
          )
        }
      </StyledDialogContent>
    </Dialog>
  );
}
