import styled from '@emotion/styled';

import { TableCell } from '../../../Components';
import { StyledTableCellProps } from '../../../Components/Content/styles';

type StyledErrorCellProps = StyledTableCellProps & {
  $error: boolean;
}

export const StyledErrorCell = styled(
  (props: StyledErrorCellProps) => <TableCell {...props}/>
)`
  color: inherit;
  font-weight: inherit;

  > div {
    display: flex;
    gap: 0.25rem;
    align-items: center;

    > svg {
      flex-shrink: 0;
    }
  }

  ${ props => props.$error && `
    background-color: ${ props.theme.palette.error.contrastText };
    color: ${ props.theme.palette.error.main };
    font-weight: 500;
  ` }
`;
