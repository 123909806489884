import {FunctionComponent, useCallback, useMemo} from 'react';
import { useIntl } from 'react-intl';

import GoogleLogo from '../assets/ssoClients/Google.svg?react';
import AdpLogo from '../assets/ssoClients/ADP.svg?react';
import {SsoProvider} from '../model/SsoProvider';

interface SsoProviderDetails {
  issuer: SsoIssuerDetails;
  variant?: string;
  name: string;
}

interface SsoIssuerDetails {
  issuer: string;
  name: string;
  colour?: string;
  logo: FunctionComponent;
}

export const useSsoProviderDetails = () => {
  const intl = useIntl();

  const ssoIssuers: Record<string, SsoIssuerDetails> = useMemo(() => ({
    'https://accounts.adp.com': {
      issuer: 'https://accounts.adp.com',
      name: intl.formatMessage({
        description: 'ADP issuer name',
        defaultMessage: 'ADP',
      }),
      colour: '#EE2722',
      logo: AdpLogo,
    },
    'https://accounts.google.com': {
      issuer: 'https://accounts.google.com',
      name: intl.formatMessage({
        description: 'Google issuer name',
        defaultMessage: 'Google',
      }),
      logo: GoogleLogo,
    },
  }), [intl]);

  const ssoProviders: SsoProviderDetails[] = useMemo(() => [
    {
      issuer: ssoIssuers['https://accounts.adp.com'],
      name: intl.formatMessage({
        description: 'ADP SSO provider name',
        defaultMessage: 'ADP',
      }),
    },
    {
      issuer: ssoIssuers['https://accounts.adp.com'],
      variant: 'workforceNow',
      name: intl.formatMessage({
        description: 'ADP WorkforceNow SSO provider name',
        defaultMessage: 'ADP WorkforceNow',
      }),
    },
    {
      issuer: ssoIssuers['https://accounts.adp.com'],
      variant: 'workforceNowNextGen',
      name: intl.formatMessage({
        description: 'ADP WorkforceNow NextGen SSO provider name',
        defaultMessage: 'ADP WorkforceNow NextGen',
      }),
    },
    {
      issuer: ssoIssuers['https://accounts.adp.com'],
      variant: 'enterprise',
      name: intl.formatMessage({
        description: 'ADP Enterprise SSO provider name',
        defaultMessage: 'ADP Enterprise',
      }),
    },
    {
      issuer: ssoIssuers['https://accounts.adp.com'],
      variant: 'vantage',
      name: intl.formatMessage({
        description: 'ADP Vantage SSO provider name',
        defaultMessage: 'ADP Vantage',
      }),
    },
    {
      issuer: ssoIssuers['https://accounts.google.com'],
      name: intl.formatMessage({
        description: 'Google SSO provider name',
        defaultMessage: 'Google',
      }),
      logo: GoogleLogo,
    },
  ], [intl, ssoIssuers]);

  const lookupProviderDetails = useCallback(
      (provider: SsoProvider): undefined|SsoProviderDetails => (
        ssoProviders.find(providerDetails => (
            providerDetails.issuer.issuer === provider.issuer
            && (providerDetails.variant ?? null) === (provider.variant ?? null)
        ))
    ),
    [ssoProviders],
  );

  return {
    groupProvidersByIssuer,
    lookupProviderDetails,
  };
};

const groupProvidersByIssuer = (providers: SsoProvider[]): Record<string, SsoProvider[]> => {
  return providers.reduce(
    (reduced, provider) => {
      reduced[provider.issuer] = reduced[provider.issuer] ?? [];
      reduced[provider.issuer].push(provider);

      return reduced;
    },
    {},
  );
};
