import { FormattedMessage } from 'react-intl';
import { FC } from 'react';

import { ConditionalTooltip, DateTime } from '../../../Common/Component';
import { RecalledLabel } from '..';
import { DeliveryRecall } from '../../../Models';
import { PersonParser } from '../../../Utility';
import { StyledRecalledLabelWithTooltip } from './style';

type Props = {
  recall: DeliveryRecall;
};

export const RecalledLabelWithTooltip: FC<Props> = ({
  recall,
}) => (
  <StyledRecalledLabelWithTooltip>
    <ConditionalTooltip
      active={ !!recall.reason }
      title={
        <FormattedMessage
          defaultMessage="{ recalledBy }, { recallDate } - { reason }"
          values={ {
            recalledBy: PersonParser.fullName(recall.interaction.by),
            recallDate: <DateTime dateTime={ recall.interaction.at }/>,
            reason: recall.reason,
          } }
        />
      }
    >
      <RecalledLabel/>
    </ConditionalTooltip>
  </StyledRecalledLabelWithTooltip>
);
