import { FetchResponse, usePost } from '../../Hooks';
import { Audience } from '../Model';

type AudienceComparison = {
  difference: {
    counts: {
      members: {
        added: number;
        removed: number;
      };
    };
  };
  previousAudience: {
    counts: {
      members: {
        total: number;
      };
    };
  };
  audience: {
    counts: {
      members: {
        total: number;
      };
    };
  };
};

export type FetchAudienceComparisonParams = {
  context: string;
  previousAudience: Audience;
  previousApplyReach: boolean;
  audience: Audience;
  applyReach: boolean;
};

export const useFetchAudienceComparison = (
  params: FetchAudienceComparisonParams,
): FetchResponse<AudienceComparison> => usePost('/audiences/compare', params);
