import styled from '@emotion/styled';

import { Flex } from '..';

export const StyledTableFilters = styled(Flex)`
  padding: 0.5rem 1rem;
  border: 1px solid ${props => props.theme.palette.grey[300]};
  border-bottom: none;
  border-radius: 0.25rem 0.25rem 0 0;
`;

export const StyledFilterLabel = styled.span`
  font-weight: 500;
`;
