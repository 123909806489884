import { FC, FormEventHandler, useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { Heading } from '@ourpeople/shared/Core/Component/Content';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';

import { PopOverState, ValidationError, Validator } from '../../../Common/Model';
import { FieldValidationErrors, FlexPullRight, VerticallySpaced } from '../../../Common/Component';
import { CharacterCount } from '../../../Content/Component';
import { Validate } from '../../../Common/Utility';

type Props = {
  state: PopOverState;
  onStateChange: (state: PopOverState) => void;
  onSubmit: (message: string) => void;
};

export const RecallDialog: FC<Props> = ({
  state,
  onStateChange,
  onSubmit,
}) => {
  const intl = useIntl();
  const [message, setMessage] = useState<string>('');
  const [errors, setErrors] = useState<ValidationError[]>([]);

  useEffect(() => {
    if (message.length > MAX_MESSAGE_LENGTH) {
      return;
    }

    setErrors([]);
  }, [message]);

  const whenSubmitted: FormEventHandler = useCallback(event => {
    event.preventDefault();

    const errors = messageValidator.validate(message);
    if (errors.length) {
      setErrors(errors);
      return;
    }

    onSubmit(message);
  }, [message, onSubmit]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={ state === PopOverState.OPEN }
      TransitionProps={ {
        onExited: () => {
          onStateChange(PopOverState.CLOSED);
          setMessage('');
        },
      } }
      onClose={ () => onStateChange(PopOverState.WILL_CLOSE) }
    >
      <DialogTitle>
        <FormattedMessage
          description="Title for recall broadcast dialog"
          defaultMessage="Recall broadcast"
        />
      </DialogTitle>
      <DialogContent>
        <form
          id="recall-form"
          onSubmit={ whenSubmitted }
        >
          <VerticallySpaced gap={ 4 }>
            <span>
              <FormattedMessage
                description="Description of recall in recall broadcast dialog."
                defaultMessage="This broadcast will be replaced with a recall message. The events will be automatically cancelled and the report will remain for administrators."
              />
            </span>
            <VerticallySpaced gap={ 2 }>
              <Heading type="h4">
                <FormattedMessage
                  description="Subheading for custom recall message in recall broadcast dialog."
                  defaultMessage="Would you like to provide a note for recipients?"
                />
              </Heading>
              <span>
                <FormattedMessage
                  description="Description for custom recall message in recall broadcast dialog."
                  defaultMessage="This note will be displayed for recipients in place of the original broadcast content."
                />
              </span>
              <VerticallySpaced gap={ 1 }>
                <FlexPullRight>
                  <CharacterCount
                    maximum={ MAX_MESSAGE_LENGTH }
                    current={ message.length }
                  />
                </FlexPullRight>
                <TextField
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={ 4 }
                  value={ message }
                  onChange={ event => setMessage(event.currentTarget.value) }
                  placeholder={ intl.formatMessage({
                    description: 'Placeholder for recall message input in recall broadcast dialog.',
                    defaultMessage: 'Optional recall message',
                  }) }
                />
                <FieldValidationErrors
                  fieldName={ intl.formatMessage({
                    description: 'Recall reason field name used in error messages.',
                    defaultMessage: 'reason',
                  }) }
                  validationErrors={ errors }
                />
              </VerticallySpaced>
            </VerticallySpaced>
          </VerticallySpaced>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={ () => onStateChange(PopOverState.WILL_CLOSE) }>
          <FormattedMessage
            description="Label for cancel button in recall broadcast dialog."
            defaultMessage="Cancel"
          />
        </Button>
        <Button
          variant="primary"
          type="submit"
          form="recall-form"
        >
          <FormattedMessage
            description="Label for recall button in recall broadcast dialog."
            defaultMessage="Recall"
          />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const MAX_MESSAGE_LENGTH = 255;
const messageValidator = new Validator<string>([message => Validate.maxLength(message, MAX_MESSAGE_LENGTH)]);

