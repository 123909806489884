import React, { FC } from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { DraftSingleContentCard, EditorContent } from '../../Model';
import { ValidationTree } from '../../../Common/Model';
import { ContentNavItem } from '..';

type Props = {
  id: string;
  content: EditorContent;
  index: number;
  active: boolean;
  onContentAtIndexClicked: (index: number) => void;
  validation?: ValidationTree<DraftSingleContentCard>;
};

export const DraggableContentNavItem: FC<Props> = ({
  id,
  content,
  index,
  active,
  onContentAtIndexClicked,
  validation,
}) => (
  <Draggable
    key={ id }
    draggableId={ id }
    index={ index }
  >
    { draggable => (
      <div
        ref={ draggable.innerRef }
        { ...draggable.draggableProps }
        { ...draggable.dragHandleProps }
      >
        <ContentNavItem
          content={ content }
          index={ index }
          active={ active }
          onContentAtIndexClicked={ onContentAtIndexClicked }
          validation={ validation }
        />
      </div>
    ) }
  </Draggable>
);
