import styled from '@emotion/styled';

import { css } from '../../../Styling/css';

export const StyledMyFormsPage = styled.div`
  display: grid;
  grid-template-columns: 400px 1fr;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  ${ props => props.theme.breakpoints.down('md') } {
    grid-template-columns: 1fr;
  }
`;

export const StyledFormList = styled.div`
  display: grid;
  height: 100%;
  overflow-y: auto;
  grid-template-rows: 1fr auto;
  gap: ${ props => props.theme.spacing(2) };

  > div > ul,
  > div > ul ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  > div > ul {
    padding-top: ${ props => props.theme.spacing(1) };
  }
`;

const StyledListItem = css`
  border-bottom: 1px solid ${ props => props.theme.palette.grey[300] };
  padding: ${ props => props.theme.spacing(1.5) } 0;
`;

export const StyledCategoryName = styled.div`
  padding: 0 ${ props => props.theme.spacing(2) };
  color: ${ props => props.theme.palette.secondary.main };
  font-weight: 500;
  font-size: 1.1rem;

  > div {
    ${ StyledListItem };
  }
`;

export const StyledFormButton = styled.li<{ active: boolean }>`
  .MuiButton-root {
    border-radius: 0;
    width: 100%;
    padding: 0 ${ props => props.theme.spacing(2) };

    ${ props => props.active && `
      background: ${ props.theme.palette.grey[200] };
    ` }
    
    > span {
      ${ StyledListItem };

      width: 100%;
      font-weight: 400;
      font-size: 1.1rem;
      display: flex;
      gap: ${ props => props.theme.spacing(1) };
    }
  }
`;
