import {
  DraftRatingRequest,
  RATING_REQUEST_MAX_RATING_MAX,
  RATING_REQUEST_MAX_RATING_MIN
} from '../../Model/ContentTypes';
import { ValidationTree, Validator } from '../../../Common/Model';
import { Validate } from '../../../Common/Utility';
import { RequestValidator } from './RequestValidator';

export class RatingRequestValidator {
  public static validateMaxRating = (content: DraftRatingRequest): ValidationTree<number> => ({
    errors: maxRatingValidator.validate(content.maxRating),
    children: {},
  });

  public static validate = (content: DraftRatingRequest): ValidationTree<DraftRatingRequest> => ({
    errors: [
      ...RequestValidator.validate(content).errors,
    ],
    children: {
      ...RequestValidator.validate(content).children,
      maxRating: RatingRequestValidator.validateMaxRating(content),
    }
  });
}

const maxRatingValidator = new Validator<number>([
  Validate.isNumeric,
  maxRating => Validate.greaterThanEq(maxRating, RATING_REQUEST_MAX_RATING_MIN),
  maxRating => Validate.lessThanEq(maxRating, RATING_REQUEST_MAX_RATING_MAX),
]);
