import { FunctionComponent, useEffect } from 'react';
import { Redirect, Route, RouteProps, useLocation, useRouteMatch } from 'react-router-dom';
import { History, LocationDescriptor } from 'history';

import { useContextOrThrow } from '../../../Core/Hook';
import { LoginStateContext } from '../../../Core/Provider/LoginStateProvider';
import { PermissionRoute } from '../PermissionRoute/PermissionRoute';

interface CommonProps extends RouteProps {
  redirect?: LocationDescriptor<History.PoorMansUnknown>;
  recordTargetUrl?: boolean;
}

interface PermissionGuardProps {
  permissions: string[];
  permissionCheck?: 'any' | 'all';
  condition?: boolean;
}

interface ConditionGuardProps {
  permissions?: never;
  permissionCheck?: never;
  condition: boolean;
}

type Props = CommonProps & (PermissionGuardProps | ConditionGuardProps);

export const GuardedRoute: FunctionComponent<Props> = ({
  condition = true,
  permissions = [],
  permissionCheck = 'all',
  redirect = '',
  recordTargetUrl = false,
  ...props
}) => {
  const { setLoginTarget } = useContextOrThrow(LoginStateContext);
  const match = useRouteMatch({ path: props.path, exact: props.exact });
  const location = useLocation();

  useEffect(
    () => {
      if (recordTargetUrl && match) {
        const searchParams = new URLSearchParams(location.search);
        searchParams.delete('otpLogin');
        setLoginTarget(location.pathname, searchParams);
      }
    },
    [setLoginTarget, match, condition, location, recordTargetUrl],
  );

  if (!match) {
    return null;
  }

  if (!condition) {
    console.debug('Route redirect due to condition.', { redirect });
  }

  return condition
    ? (
      permissions.length
        ? (
          <PermissionRoute
            permissions={ permissions }
            permissionCheck={ permissionCheck }
            redirect={ redirect }
            { ...props }
          />
        )
        : <Route { ...props }/>
    )
    : <Redirect to={ redirect }/>
};
