import styled from '@emotion/styled';

import { Wiggle } from '../../../Common/Style';

export const StyledFormResponseError = styled.div`
  .MuiBox-root {
    > div {
      animation: ${ Wiggle } 0.25s linear;
      display: inline-flex;
      gap: ${ props => props.theme.spacing(1) };
      align-items: center;

      .MuiBox-root {
        margin-bottom: 2px;
      }
    }
  }
`;
