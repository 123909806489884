import { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AutocompleteSelectionChanged } from '@ourpeople/shared/Core/Component/Input/Autocomplete/Autocomplete';
import { LocalisedString } from 'op-storybook/lib/model/LocalisedString/LocalisedString';

import { Api } from '../../../Services';
import { ListedDelivery, Paginated, Person } from '../../../Models';
import { FetchDeliveriesParams, FetchDeliveriesSort, useFetchDeliveries } from '../../Hook';
import { PaginatedTable, SortableHeaderCell, TableCell, TableRow } from '../../../Components';
import { CategoryAutocomplete, ContentTypeAutocomplete, RecentDeliveryRow } from '..';
import { ApiContext } from '../../../Contexts';
import { EventAndMetadata, MinimalBroadcastCategory, TableProps } from '../../Model';
import { useEnvironmentSettings } from '../../../Common/Hook';
import { Chip, TableFilters } from '../../../Common/Component';
import { Tag } from '../../../Tags/Model';
import { TeamAutocomplete } from '../../../Tags/Component';
import { useQueryAsState } from '../../../Hooks';
import { ArrayHelper, QueryParser, QueryWithKeys } from '../../../Common/Utility';
import { PersonAutocomplete } from '../../../People/Component';
import { PersonParser } from '../../../Utility';
import { useContentDefinitionRegistry } from '../../../Content/Hook';
import { StyledInputContainer } from './style';

type Query = QueryWithKeys<'contentTypes' | 'sort' | 'pageNum' | 'search' | 'publishedByIds' | 'categoryIds' | 'teamIds' | 'tab'>;

export const RecentTable: FC<TableProps> = () => {
  const [query, setQuery] = useQueryAsState<Query>();
  const intl = useIntl();
  const api = useContext(ApiContext);
  const { contentDefinitions } = useContentDefinitionRegistry();
  const params = useMemo<FetchDeliveriesParams>(
    () => ({
      pageNum: QueryParser.pageNum(query),
      noScheduled: 0,
      noDrafts: 1,
      noArchived: 1,
      publishedByIds: query.publishedByIds || null,
      ...(query.search ? { search: query.search } : {}),
      ...(query.contentTypes ? {
          contentTypes: query.contentTypes
            .split(',')
            .map(contentType => contentDefinitions.find(definition => definition.id === contentType)?.contentTypes || [])
            .join(',')
        } : {}),
      sort: query.sort as FetchDeliveriesSort || 'coalesced_delivered_at_desc',
      categoryIds: query.categoryIds || null,
      teamIds: query.teamIds || null,
      statuses: 'delivered,queued,failed,recalled',
      broadcastTypes: 'content,event',
    }),
    [contentDefinitions, query],
  );
  const teamIds = useMemo(() => query.teamIds?.split(',') || [], [query.teamIds]);
  const { eventsEnabled, broadcastDeliverySuccessStatsEnabled } = useEnvironmentSettings();
  const [fetchDeliveriesResponse, fetchDeliveriesState, fetchDeliveriesRefresh] = useFetchDeliveries(params);
  const pagination = useMemo(
    () => fetchDeliveriesResponse?.content?.pagination,
    [fetchDeliveriesResponse?.content?.pagination],
  );
  const [deliveries, setDeliveries] = useState<ListedDelivery[]>([]);
  const [events, setEvents] = useState<Map<string, EventAndMetadata | null>>(new Map());
  const syncQueuedDeliveriesIntervalRef = useRef<number | undefined>();
  const [chips, setChips] = useState<Chip[]>([]);

  const whenContentTypesChanged = useCallback((contentTypes: LocalisedString[]) => {
    const newChips = contentTypes.map(contentType => ({
      id: contentType.id,
      type: 'contentTypes',
      label: intl.formatMessage({
        id: 'recentBroadcasts.type.chip',
        description: 'Chip label in recent broadcasts table when filtered by type.',
        defaultMessage: 'Type: { type }',
      }, {
        type: contentType.localisation,
      }),
    }));
    setChips(chips => chips.filter(chip => chip.type !== 'contentTypes').concat(newChips));
    setQuery(QueryParser.csvValueUpdater('contentTypes', contentTypes.map(contentType => contentType.id)));
  }, [intl, setQuery]);

  const whenTeamsChanged: AutocompleteSelectionChanged<Tag<'team'>> = useCallback(selection => {
    const newChips = selection.options.map(team => ({
      id: team.id,
      type: 'teamIds',
      label: intl.formatMessage({
        id: 'recentBroadcasts.team.chip',
        description: 'Chip label in recent broadcasts table when filtered by team.',
        defaultMessage: 'Team: { team }',
      }, {
        team: team.name,
      }),
    }));
    setChips(chips => chips.filter(chip => chip.type !== 'teamIds').concat(newChips));
    setQuery(QueryParser.csvValueUpdater('teamIds', selection.options.map(option => option.id).concat(selection.badIds)));
  }, [intl, setQuery]);

  const whenSentByPeopleChanged: AutocompleteSelectionChanged<Person> = useCallback(selection => {
    const newChips = selection.options.map(person => ({
      id: person.id,
      type: 'publishedByIds',
      label: intl.formatMessage({
        id: 'recentBroadcasts.sentBy.chip',
        description: 'Chip label in recent broadcasts table when filtered by sender.',
        defaultMessage: 'Sent by: { sentBy }',
      }, {
        sentBy: PersonParser.fullName(person),
      }),
    }));
    setChips(chips => chips.filter(chip => chip.type !== 'publishedByIds').concat(newChips));
    setQuery(QueryParser.csvValueUpdater('publishedByIds', selection.options.map(option => option.id).concat(selection.badIds)));
  }, [intl, setQuery]);

  const whenCategoriesChanged: AutocompleteSelectionChanged<MinimalBroadcastCategory> = useCallback(selection => {
    const newChips = selection.options.map(category => ({
      id: category.id,
      type: 'categoryIds',
      label: intl.formatMessage({
        id: 'recentBroadcasts.category.chip',
        description: 'Chip label in recent broadcasts table when filtered by category.',
        defaultMessage: 'Category: { category }',
      }, {
        category: category.name,
      }),
    }));
    setChips(chips => chips.filter(chip => chip.type !== 'categoryIds').concat(newChips));
    setQuery(QueryParser.csvValueUpdater('categoryIds', selection.options.map(option => option.id).concat(selection.badIds)));
  }, [intl, setQuery]);

  const resetFilters = useCallback(() => {
    setChips([]);
    setQuery(({ tab }) => ({
      ...(tab ? { tab } : {}),
    }));
  }, [setQuery]);

  const whenChipRemoved = useCallback((chipToRemove: Chip) => {
    setChips(chips => chips.filter(chip => chip.type !== chipToRemove.type || chip.id !== chipToRemove.id));
    setQuery(QueryParser.csvValueRemover(chipToRemove.type, chipToRemove.id));
  }, [setQuery]);

  const syncQueuedDeliveries = (
    api: Api,
    queuedDeliveryIds: string[]
  ): Promise<ListedDelivery[]> => {
    if (queuedDeliveryIds.length < 1) {
      return Promise.resolve([]);
    }

    return api.get<Paginated<'deliveries', ListedDelivery>>(
      '/broadcasts/deliveries',
      {
        params: {
          ids: queuedDeliveryIds.slice(0, 10).join(','),
          statuses: 'delivered,failed',
        }
      }
    )
      .then(response => response.data.deliveries);
  };

  const pairEventsWithDeliveries = (eventsAndMetadata: EventAndMetadata[], deliveries: ListedDelivery[]) => {
    const events = new Map(
      deliveries.reduce<[string, EventAndMetadata][]>(
        (accumulatedEvents, delivery) => {
          const correspondingEventAndMetadata = eventsAndMetadata.find(
            eventAndMetadata => eventAndMetadata.event.id?.toString() === delivery.broadcast.contents[0].id,
          );

          if (!correspondingEventAndMetadata) {
            return accumulatedEvents;
          }

          return accumulatedEvents.concat([
            [
              delivery.id,
              correspondingEventAndMetadata,
            ]
          ]);
        },
        [],
      )
    );
    setEvents(events);
  };

  useEffect(() => {
    if (!api || !deliveries.length || !eventsEnabled) {
      return;
    }

    const eventDeliveries = deliveries.reduce<ListedDelivery[]>(
      (accumulatedEventDeliveries, delivery) => {
        const deliveryIsDelivered = delivery.status === 'delivered';
        const firstContent = delivery.broadcast.contents[0];
        const firstContentIsEvent = [
          'meetingcontent',
          'eventcontent',
          'trainingcontent',
          'covercontent',
          'shiftcontent',
        ].indexOf(firstContent.type) !== -1;
        return deliveryIsDelivered && firstContentIsEvent ? accumulatedEventDeliveries.concat(delivery) : accumulatedEventDeliveries;
      },
      [],
    );

    if (Array.from(events.keys()).sort().join(',') === eventDeliveries.map(eventDelivery => eventDelivery.id).sort().join(',')) {
      return;
    }

    setEvents(new Map(eventDeliveries.map(eventDelivery => [eventDelivery.id, null])));
    const eventContentIds = eventDeliveries.map(delivery => delivery.broadcast.contents[0].id);
    if (!eventContentIds.length) {
      return;
    }

    api.get<EventAndMetadata[]>('/events', { params: { ids: eventContentIds.join(',') } })
      .then(response => {
        pairEventsWithDeliveries(response.data, eventDeliveries);
      })
      .catch(() => {
        setEvents(new Map());
      });
  }, [api, deliveries, events, eventsEnabled]);

  const whenPageNumChanged = useCallback((pageNum: number) => (
    setQuery(query => ({
      ...query,
      pageNum: `${ pageNum }`,
    }))
  ), [setQuery]);

  useEffect(() => {
    setDeliveries(fetchDeliveriesResponse?.content?.deliveries || []);
  }, [fetchDeliveriesResponse?.content?.deliveries]);

  useEffect(() => {
    let cancelled = false;
    const queuedDeliveryIds = (deliveries || [])
      .filter(delivery => delivery.status === 'queued')
      .map(delivery => delivery.id);

    if (!api || !deliveries || queuedDeliveryIds.length < 1) {
      return;
    }

    syncQueuedDeliveriesIntervalRef.current = window.setInterval(
      () => {
        void syncQueuedDeliveries(api, queuedDeliveryIds)
          .then((syncedDeliveries) => {
            return cancelled
              ? deliveries
              : deliveries
                .map(
                  (delivery) =>
                    syncedDeliveries.find(syncedDelivery => syncedDelivery.id === delivery.id)
                    || delivery
                );
          })
          .then(setDeliveries)
          .catch(() => { /* do nothing - this is a background operation */
          });
      },
      10000
    );
    return () => {
      cancelled = true;
      clearInterval(syncQueuedDeliveriesIntervalRef.current);
    };
  }, [deliveries, api]);

  const whenSearchChanged = useCallback((search: string) => {
    setQuery(({ search: previousSearch, ...query }) => ({
      ...query,
      ...(search ? { search } : {}),
    }));
  }, [setQuery]);

  const whenSortChanged = useCallback((sort: string) => {
    setQuery(query => ({
      ...query,
      sort,
    }));
  }, [setQuery]);

  const whenDeliveryChanged = useCallback((delivery: ListedDelivery) => (
    setDeliveries(deliveries => {
      const currentDeliveryIndex = deliveries.findIndex(currentDelivery => currentDelivery.id === delivery.id);
      return ArrayHelper.replace(deliveries, currentDeliveryIndex, delivery);
    })
  ), []);

  return (
    <div>
      <TableFilters
        onClearFiltersClicked={ resetFilters }
        chips={ chips }
        onRemoveChipClicked={ whenChipRemoved }
        searchValue={ query.search || '' }
        onSearchChanged={ whenSearchChanged }
      >
        <StyledInputContainer>
          <PersonAutocomplete
            selectedIds={ query.publishedByIds?.split(',') || [] }
            onSelectionChanged={ whenSentByPeopleChanged }
            multiple
            dense
            fullWidth
            showChips={ false }
            label={ intl.formatMessage({
              id: 'broadcasts.sentBy.label',
              description: 'Placeholder for sent by filter',
              defaultMessage: 'Sent by',
            }) }
            placeholder={ intl.formatMessage({
              id: 'broadcasts.sentBy.placeholder',
              description: 'Placeholder for sent by filter',
              defaultMessage: 'Search…',
            }) }
          />
        </StyledInputContainer>
        <StyledInputContainer>
          <ContentTypeAutocomplete
            selectedIds={ query.contentTypes?.split(',') || [] }
            onSelectionChanged={ whenContentTypesChanged }
            multiple
            dense
            fullWidth
          />
        </StyledInputContainer>
        <StyledInputContainer>
          <CategoryAutocomplete
            selectedIds={ query.categoryIds?.split(',') || [] }
            onSelectionChanged={ whenCategoriesChanged }
            multiple
            dense
            fullWidth
            showChips={ false }
            label={ intl.formatMessage({
              id: 'broadcasts.categories.label',
              description: 'Placeholder for categories filter',
              defaultMessage: 'Category',
            }) }
            placeholder={ intl.formatMessage({
              id: 'broadcasts.sentBy.placeholder',
              description: 'Placeholder for categories filter',
              defaultMessage: 'Search…',
            }) }
          />
        </StyledInputContainer>
        <StyledInputContainer>
          <TeamAutocomplete
            teamIds={ teamIds }
            onSelectionChanged={ whenTeamsChanged }
            multiple
            dense
            fullWidth
            showChips={ false }
            label={ intl.formatMessage({
              id: 'broadcasts.sentToTeams.label',
              description: 'Placeholder for sent to teams filter',
              defaultMessage: 'Sent to team',
            }) }
            placeholder={ intl.formatMessage({
              id: 'broadcasts.sentToTeams.placeholder',
              description: 'Placeholder for sent to teams filter',
              defaultMessage: 'Search…',
            }) }
          />
        </StyledInputContainer>
      </TableFilters>
      <PaginatedTable
        headerRow={
          <TableRow>
            <TableCell>
              <FormattedMessage
                id="broadcasts.list.type"
                defaultMessage="Type"
              />
            </TableCell>
            <TableCell minwidth="11rem">
              <FormattedMessage
                id="broadcasts.list.title"
                defaultMessage="Broadcast title"
              />
            </TableCell>
            <TableCell minwidth="11rem">
              <FormattedMessage
                description="Heading for category column in recent broadcast table"
                defaultMessage="Category"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                id="broadcasts.list.sentBy"
                defaultMessage="Sent by"
              />
            </TableCell>
            <SortableHeaderCell
              ascValue="coalesced_delivered_at_asc"
              descValue="coalesced_delivered_at_desc"
              sort={ query.sort || 'coalesced_delivered_at_asc' }
              onSort={ whenSortChanged }
            >
              <FormattedMessage
                id="broadcasts.list.type"
                defaultMessage="Sent"
              />
            </SortableHeaderCell>
            <TableCell>
              <FormattedMessage
                id="broadcasts.list.recipients"
                defaultMessage="Recipients"
              />
            </TableCell>
            <TableCell>
              { !broadcastDeliverySuccessStatsEnabled && (
                <FormattedMessage
                  id="broadcasts.list.engagement"
                  defaultMessage="Engagement"
                />
              ) }
              { broadcastDeliverySuccessStatsEnabled && (
                <FormattedMessage
                  id="broadcasts.list.delivered"
                  defaultMessage="Delivery"
                />
              ) }
            </TableCell>
            <TableCell/>
          </TableRow>
        }
        requestState={ fetchDeliveriesState }
        onRetryClicked={ fetchDeliveriesRefresh }
        pagination={ pagination }
        onPageChanged={ whenPageNumChanged }
        rows={ deliveries }
        rowRender={ delivery => {
          const correspondingEventAndMetadata = events.get(delivery.id);
          return (
            <RecentDeliveryRow
              key={ delivery.id }
              delivery={ delivery }
              eventAndMetadata={ correspondingEventAndMetadata || undefined }
              onReloadRequired={ fetchDeliveriesRefresh }
              onChange={ whenDeliveryChanged }
            />
          )
        } }
        pageNum={ params.pageNum }
      />
    </div>
  );
};
