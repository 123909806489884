import styled from '@emotion/styled';

import FailIcon from '../../../Assets/img/icons/streamline/cross.svg';

export const StyledFailIcon = styled(FailIcon)`
  color: ${ props => props.theme.palette.error.main };
`;

export const StyledInputContainer = styled.div`
  width: 11rem;
`;
