import styled from '@emotion/styled';

export const StyledAggregateSettingLabel = styled.label`
  font-weight: 500;
`;

export const StyledAggregateSettingInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${ props => props.theme.spacing(2) };
  padding-bottom: ${ props => props.theme.spacing(2) };
`;
