import { IntlShape } from 'react-intl';

import { AudienceConditionDefinition, ConditionRestriction } from '../../Model/AudienceConditionDefinition';
import { Condition, NobodyCondition } from '../../../../Audiences/Model';
import { NobodyConditionComponent } from '../../Component/NobodyConditionComponent';

export class NobodyConditionDefinition implements AudienceConditionDefinition<NobodyCondition> {
  public readonly TYPE = 'nobody';
  public readonly restriction = ConditionRestriction.FULL;
  public readonly name;

  constructor(intl: IntlShape) {
    this.name = intl.formatMessage({
      description: 'Name for nobody condition, used in audience builder.',
      defaultMessage: 'Nobody',
    });
  }
  public definesCondition(condition: Condition): condition is NobodyCondition {
    return condition.type === this.TYPE;
  }

  public Component = NobodyConditionComponent;

  isValid(): boolean {
    return true;
  }
}
