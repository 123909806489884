import { ComponentProps, FC, HTMLAttributes, useEffect, useState } from 'react';
import { Autocomplete } from '@mui/material';
import { TextField } from '@mui/material';
import { useIntl } from 'react-intl';
import { useDebounce } from '@ourpeople/shared/Core/Hook/useDebounce';

import { useInputChangeEventHandler } from '../../../../Common/Hook';
import FolderIcon from '../../../../Assets/img/icons/streamline/folder-empty-1.svg';
import FileIcon from '../../../../Assets/img/icons/streamline/common-file-empty.svg';
import { FieldValidationErrors, VerticallySpaced } from '../../../../Common/Component';
import { StyledFileName, StyledFileNameRow, StyledFilePath } from './style';
import { ValidationError } from '../../../../Common/Model';
import { useApi } from '../../../../Core/Hook';
import { FileEntry, MinimalFileEntry } from '../../../../Files/Model/FileEntry';

type Props = {
  fileEntry?: MinimalFileEntry;
  onChange: (fileEntry: MinimalFileEntry) => void;
  onBlur: () => void;
  errors?: ValidationError[];
};

export const FileAutocomplete: FC<Props> = ({
  fileEntry,
  onChange,
  onBlur,
  errors = [],
}) => {
  const intl = useIntl();
  const [searching, setSearching] = useState<boolean>(false);
  const [searchError, setSearchError] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce(search, 250);
  const [options, setOptions] = useState<MinimalFileEntry[]>([]);
  const api = useApi();

  useEffect(() => {
    setSearchError(false);
  }, [search]);

  useEffect(() => {
    if (!debouncedSearch) {
      return;
    }

    if (!api) {
      throw new Error('Api used outside of provider.');
    }

    setSearching(true);
    api.get<{ results: FileEntry[] }>('/files/search', { params: { term: debouncedSearch } })
      .then(response => {
        setOptions(response.data.results);
        setSearching(false);
      })
      .catch(() => {
        setSearchError(true);
        setSearching(false);
      });
  }, [debouncedSearch, api]);

  useEffect(() => {
    if (fileEntry?.id && (fileEntry.id !== fileEntry?.id || fileEntryIsMinimal(fileEntry))) {
      onChange(fileEntry);
    }
  }, [fileEntry, onChange]);

  const whenSearchChanged = useInputChangeEventHandler(setSearch);

  const whenChanged: ComponentProps<typeof Autocomplete<MinimalFileEntry>>['onChange'] = (_event, fileEntry) => {
    if (!fileEntry) {
      return;
    }

    onChange(fileEntry);
  };

  const fileFieldLabel = intl.formatMessage({
    id: 'fileAutocomplete.search.label',
    description: 'Label for file autocomplete search field.',
    defaultMessage: 'File',
  });

  return (
    <VerticallySpaced gap={ 1 }>
      <Autocomplete
        value={ fileEntry || null }
        options={ options }
        renderOption={ renderOption }
        getOptionLabel={ option => option.name }
        loading={ searching }
        onChange={ whenChanged }
        onBlur={ onBlur }
        noOptionsText={
          searchError
            ? intl.formatMessage({
              id: 'fileAutocomplete.search.error',
              description: 'Text displayed when search request fails.',
              defaultMessage: 'Files could not be retrieved at this time, please try again.',
            })
            : search
              ? intl.formatMessage({
                id: 'fileAutocomplete.search.noOptions',
                description: 'Text displayed when no files match the search term.',
                defaultMessage: 'No files or folders matching your search.',
              })
              : intl.formatMessage({
                id: 'fileAutocomplete.search.noSearch',
                description: 'Text displayed in results menu when no search term has been provided.',
                defaultMessage: 'Search for a file or folder.',
              })
        }
        renderInput={ params => (
          <TextField
            { ...params }
            value={ search }
            onChange={ whenSearchChanged }
            InputProps={ {
              ...params.InputProps,
              endAdornment: params.InputProps.endAdornment,
            } }
            label={ fileFieldLabel }
            placeholder={ intl.formatMessage({
              id: 'fileAutocomplete.search.placeholder',
              description: 'Placeholder for file autocomplete search field.',
              defaultMessage: 'Search…',
            }) }
          />
        ) }
      />
      { !!errors.length && (
        <FieldValidationErrors
          fieldName={ fileFieldLabel }
          validationErrors={ errors }
        />
      ) }
    </VerticallySpaced>
  );
};

const fileEntryIsMinimal = (fileEntry: MinimalFileEntry): fileEntry is MinimalFileEntry => !fileEntry.hasOwnProperty('name');

const renderOption = (liProps: HTMLAttributes<HTMLLIElement>, option: MinimalFileEntry) => (
  <li {...liProps}>
    <VerticallySpaced gap={ 0.5 }>
      <StyledFileNameRow>
        { option.type === 'file' ? <FileIcon/> : <FolderIcon/> }
        <StyledFileName>{ option.name }</StyledFileName>
      </StyledFileNameRow>
      <StyledFilePath>{ option.path }</StyledFilePath>
    </VerticallySpaced>
  </li>
);
