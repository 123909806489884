import { ContextCreator } from '@ourpeople/shared/Core/Utility/ContextCreator';
import { FC, ReactNode, useCallback, useMemo } from 'react';

import {
  BlockErrorDefinitions,
  BroadcastBlockDefinition,
  FormComponent, InlineFormComponent,
  PreviewComponent,
  PreviewSlot
} from '../Model/BroadcastBlockDefinition';
import { BroadcastBlock, BroadcastStructure } from '../Model/Broadcast';
import { SvgComponent } from '../../../Common/Model';
import { BroadcastAttachments } from '../Model/BroadcastAttachments';

type Props = {
  // "unknown", "never" or "BroadcastBlock" here do not give the desired behaviour and only complicate casting the
  // definition later. I believe this is a typescript limitation and a valid use-case for "any".
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  definitions: BroadcastBlockDefinition<any>[];
  defaultBlockKind: string;
  children?: ReactNode;
};

export const BroadcastBlockDefinitionRegistry: FC<Props> = ({
  definitions,
  defaultBlockKind,
  children,
}) => {
  const getDefinitionFromBlock = useCallback(<T extends BroadcastBlock>(block: T): BroadcastBlockDefinition<T> => {
    const definition = definitions.find(
      definition => (definition as BroadcastBlockDefinition<T>).definesBlock(block)
    );

    if (!definition) {
      return throwMissingBlockError(block.kind);
    }

    return definition as BroadcastBlockDefinition<T>;
  }, [definitions]);

  const getFormComponent = useCallback(<T extends BroadcastBlock>(block: T): FormComponent<T> | undefined => {
    const definition = getDefinitionFromBlock(block);

    return definition.FormComponent || undefined;
  }, [getDefinitionFromBlock]);

  const getInlineFormComponent = useCallback(<T extends BroadcastBlock>(block: T): InlineFormComponent<T> => {
    const definition = getDefinitionFromBlock(block);

    return definition.InlineFormComponent;
  }, [getDefinitionFromBlock]);

  const getExpandedPreviewComponent = useCallback(<T extends BroadcastBlock>(block: T): PreviewComponent<T> => {
    const definition = getDefinitionFromBlock(block);

    return definition.ExpandedPreviewComponent;
  }, [getDefinitionFromBlock]);

  const getCreateButtonProps = useCallback(() => {
    return definitions.map(anyDefinition => {
      const definition = anyDefinition as BroadcastBlockDefinition<BroadcastBlock>;
      return ({
        prioritise: definition.prioritise,
        localisedKind: definition.localisedKind,
        initialiseBlock: () => definition.initialiseBlock(),
        IconComponent: definition.IconComponent,
        openSheet: !!definition.FormComponent,
      });
    });
  }, [definitions]);

  const getLocalisedKind = useCallback(<T extends BroadcastBlock>(block: T): string | undefined => {
    const definition = getDefinitionFromBlock(block);

    return definition.localisedKind;
  }, [getDefinitionFromBlock]);

  const initialiseDefaultBlock = useCallback(() => {
    const defaultBlockDefinition = definitions.find(
      definition => definition.kind === defaultBlockKind
    ) as BroadcastBlockDefinition<BroadcastBlock>;

    if (!defaultBlockDefinition) {
      throwMissingBlockError(defaultBlockKind);
    }

    return defaultBlockDefinition.initialiseBlock();
  }, [defaultBlockKind, definitions]);

  const getBlockErrorDefinitions = useCallback(<T extends BroadcastBlock>(block: T) => {
    const definition = getDefinitionFromBlock(block);

    return definition.errorDefinitions;
  }, [getDefinitionFromBlock]);

  const prepareBlockForSubmission = useCallback(<T extends BroadcastBlock>(block: T) => {
    const definition = getDefinitionFromBlock(block);

    return definition.prepareBlockForSubmission ? definition.prepareBlockForSubmission(block) : block;
  }, [getDefinitionFromBlock]);

  const getTextPreview = useCallback((structure: BroadcastStructure, attachments: BroadcastAttachments) => {
    let textPreview: ReactNode | undefined = undefined;

    for (const block of structure.blocks) {
      const definition = getDefinitionFromBlock(block);

      if (!definition || definition.preview?.slot !== PreviewSlot.TEXT) {
        continue;
      }

      textPreview = (
        <definition.preview.Component
          attachments={ attachments }
          block={ block }
        />
      );
      break;
    }

    return textPreview;
  }, [getDefinitionFromBlock]);

  const getMediaPreview = useCallback((structure: BroadcastStructure, attachments: BroadcastAttachments) => {
    let mediaPreview: ReactNode | undefined = undefined;

    for (const block of structure.blocks) {
      const definition = getDefinitionFromBlock(block);

      if (!definition || definition.preview?.slot !== PreviewSlot.MEDIA) {
        continue;
      }

      mediaPreview = (
        <definition.preview.Component
          attachments={ attachments }
          block={ block }
        />
      );
      break;
    }

    return mediaPreview;
  }, [getDefinitionFromBlock]);

  const contextValue = useMemo(() => ({
    getFormComponent,
    getExpandedPreviewComponent,
    getCreateButtonProps,
    getInlineFormComponent,
    getLocalisedKind,
    initialiseDefaultBlock,
    getBlockErrorDefinitions,
    prepareBlockForSubmission,
    getTextPreview,
    getMediaPreview,
  }), [
    getFormComponent,
    getCreateButtonProps,
    getInlineFormComponent,
    getExpandedPreviewComponent,
    getLocalisedKind,
    initialiseDefaultBlock,
    getBlockErrorDefinitions,
    prepareBlockForSubmission,
    getTextPreview,
    getMediaPreview,
  ]);

  return (
    <BroadcastBlockDefinitionRegistryContext.Provider
      value={ contextValue }
    >
      { children }
    </BroadcastBlockDefinitionRegistryContext.Provider>
  );
};

export type CreateButtonProps = {
  prioritise: boolean;
  localisedKind: string;
  initialiseBlock: () => BroadcastBlock,
  IconComponent: SvgComponent,
  openSheet: boolean
};

const throwMissingBlockError = (kind: string) => {
  throw new Error(`Could not find definition matching kind '${ kind }'.`);
};

export const BroadcastBlockDefinitionRegistryContext = ContextCreator.withDisplayName<BroadcastBlockDefinitionRegistryContextValue>('BroadcastBlockDefinitionRegistry', null);

interface BroadcastBlockDefinitionRegistryContextValue {
  getInlineFormComponent: <T extends BroadcastBlock>(block: T) => InlineFormComponent<T> | undefined;
  getFormComponent: <T extends BroadcastBlock>(block: T) => FormComponent<T> | undefined;
  getExpandedPreviewComponent: <T extends BroadcastBlock>(block: T) => PreviewComponent<T> | undefined;
  getTextPreview: (structure: BroadcastStructure, attachments: BroadcastAttachments) => ReactNode;
  getMediaPreview: (structure: BroadcastStructure, attachments: BroadcastAttachments) => ReactNode;
  getCreateButtonProps: () => CreateButtonProps[];
  getLocalisedKind: (block: BroadcastBlock) => string | undefined;
  initialiseDefaultBlock: () => BroadcastBlock;
  getBlockErrorDefinitions: <T extends BroadcastBlock>(block: T) => BlockErrorDefinitions<T>;
  prepareBlockForSubmission: <T extends BroadcastBlock>(block: T) => T;
}
