import { IntlShape } from 'react-intl/src/types';

import { DurationSetting, DurationSettingUnit } from '../Models/DurationSetting';

enum UnitAsSeconds {
  'second' = 1,
  'minute' = 60,
  'hour' = 3600,
  'day' = 86400,
  'week' = 604800,
}

export class DurationParser {
  public static getBestFitDurationUnit(durationInSeconds: number): DurationSettingUnit {
    return durationInSeconds % UnitAsSeconds.week === 0
      ? 'week'
      : durationInSeconds % UnitAsSeconds.day === 0
        ? 'day'
        : durationInSeconds % UnitAsSeconds.hour === 0
          ? 'hour'
          : 'second';
  }

  public static getBestFitDurationAmount(durationInSeconds: number): number {
    return Math.round(
      durationInSeconds / UnitAsSeconds[DurationParser.getBestFitDurationUnit(durationInSeconds)]
    );
  }

  public static secondsAsDuration = (durationInSeconds: number): DurationSetting => ({
    unit: DurationParser.getBestFitDurationUnit(durationInSeconds),
    amount: DurationParser.getBestFitDurationAmount(durationInSeconds),
  });

  public static secondsToMs = (durationInSeconds: number): number => (
    durationInSeconds * MS_IN_SECOND
  );

  public static msToSeconds = (durationInMs: number): number => (
    durationInMs / MS_IN_SECOND
  );

  public static unitIsValid(unit: unknown): unit is DurationSettingUnit {
    return UnitAsSeconds[unit as DurationSettingUnit] !== undefined;
  }

  public static durationInSeconds(duration: DurationSetting): number {
    return duration.amount * UnitAsSeconds[duration.unit];
  }

  public static localise = (setting: DurationSetting, intl: IntlShape): string => {
    switch(setting.unit) {
      case 'second':
        return intl.formatMessage({
          description: 'Duration in seconds.',
          defaultMessage: '{ seconds, plural, =1 {# second} other {# seconds} }',
        }, {
          seconds: setting.amount,
        });
      case 'minute':
        return intl.formatMessage({
          description: 'Duration in minutes.',
          defaultMessage: '{ minutes, plural, =1 {# minute} other {# minutes} }',
        }, {
          minutes: setting.amount,
        });
      case 'hour':
        return intl.formatMessage({
          description: 'Duration in hours.',
          defaultMessage: '{ hours, plural, =1 {# hour} other {# hours} }',
        }, {
          hours: setting.amount,
        });
      case 'day':
        return intl.formatMessage({
          description: 'Duration in days.',
          defaultMessage: '{ days, plural, =1 {# day} other {# days} }',
        }, {
          days: setting.amount,
        });
      case 'week':
        return intl.formatMessage({
          description: 'Duration in weeks.',
          defaultMessage: '{ weeks, plural, =1 {# week} other {# weeks} }',
        }, {
          weeks: setting.amount,
        });
    }
  }

  public static localiseUnit(unit: DurationSettingUnit, intl: IntlShape): string {
    switch(unit) {
      case 'second':
        return intl.formatMessage({
          id: 'durationSetting.secondUnit',
          defaultMessage: 'Seconds',
        });
      case 'minute':
        return intl.formatMessage({
          id: 'durationSetting.minuteUnit',
          defaultMessage: 'Minutes',
        });
      case 'hour':
        return intl.formatMessage({
          id: 'durationSetting.hourUnit',
          defaultMessage:  'Hours',
        });
      case 'day':
        return intl.formatMessage({
          id: 'durationSetting.dayUnit',
          defaultMessage: 'Days',
        });
      case 'week':
        return intl.formatMessage({
          id: 'durationSetting.weekUnit',
          defaultMessage: 'Weeks',
        });
    }

    return unit;
  }

  public static formatSecondsRoughlyToLargestUnit(seconds: number, intl: IntlShape): string {
    if (seconds < 60) {
      return intl.formatMessage({
        id: 'formattedDuration.seconds',
        defaultMessage: '{seconds, plural, one {# second} other {# seconds}}',
      }, {
        seconds,
      });
    }

    const minutes = Math.ceil(seconds / 60);

    if (minutes < 60) {
      return intl.formatMessage({
        id: 'formattedDuration.minutes',
        defaultMessage: '{minutes, plural, one {# minute} other {# minutes}}',
      }, {
        minutes,
      });
    }

    const hours = Math.ceil(minutes / 60);

    if (hours < 24) {
      return intl.formatMessage({
        id: 'formattedDuration.hours',
        defaultMessage: '{hours, plural, one {# hour} other {# hours}}',
      }, {
        hours,
      });
    }

    const days = Math.ceil(hours / 24);

    return intl.formatMessage({
      id: 'formattedDuration.days',
      defaultMessage: '{days, plural, one {# day} other {# days}}',
    }, {
      days,
    });
  }
}

const MS_IN_SECOND = 1000;
