/* eslint-disable @typescript-eslint/restrict-template-expressions */

import styled from '@emotion/styled';

import { FeedbackSeverity } from '..';

export const StyledHighlightedTextValue = styled.span`
  padding: 0.25rem 0.5rem 0.15rem;
  line-height: 1rem;
  font-size: 1rem;
`;

export const StyledHighlightedTextIcon = styled.span`
  transition: background-color 0.1s;
  height: 100%;
  display: block;
  padding: 0.15rem;
  line-height: 1;

  svg {
    height: 18px;
    width: 18px;
  }
`;

type StyledHighlightedTextProps = {
  clickable: boolean;
  color: FeedbackSeverity;
}

export const StyledHighlightedText = styled.div<StyledHighlightedTextProps>`
  border: 1px solid ${ props => props.theme.palette[props.color].main };
  transition: border-color 0.1s;
  border-radius: 0.25rem;
  grid-template-columns: 1fr auto;
  display: inline-grid;
  align-items: center;

  ${ StyledHighlightedTextIcon } {
    background-color: ${ props => props.theme.palette[props.color].main };
    color: ${ props => props.theme.palette[props.color].contrastText };
  }

  ${ props => props.clickable && `
    cursor: pointer;

    :hover {
      border-color: ${ props.theme.palette[props.color].light };

      ${ StyledHighlightedTextIcon } {
        background-color: ${ props.theme.palette[props.color].light };
      }
    }

    :active {
      border-color: ${ props.theme.palette[props.color].dark };

      ${ StyledHighlightedTextIcon } {
        background-color: ${ props.theme.palette[props.color].dark };
      }
    }
  ` }
`;
