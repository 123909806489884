import { useIntl } from 'react-intl';
import { FC } from 'react';

import { CenteredMessage } from '../CenteredMessage/CenteredMessage';
import { CenteredMessageWithButton } from '../CenteredMessageWithButton/CenteredMessageWithButton';

type Props = {
  onRetryClicked?: () => void;
};

export const CenteredGenericErrorMessage: FC<Props> = ({
  onRetryClicked,
}) => {
  const intl = useIntl();

  const heading = intl.formatMessage({
    id: 'genericErrorMessage.heading',
    description: 'Generic error message heading.',
    defaultMessage: 'Yikes!',
  });
  const body = intl.formatMessage({
    id: 'genericErrorMessage.heading',
    description: 'Generic error message heading.',
    defaultMessage: 'Something is broken. We\'ll get that fixed soon. { touchDevice, select, true { Tap } other { Click } } to try again.',
  }, {
    touchDevice: 'ontouchstart' in window,
  });

  return (
    onRetryClicked
      ? (
        <CenteredMessageWithButton
          heading={ heading }
          body={ body }
          onButtonClicked={ onRetryClicked }
        />
      )
      : (
        <CenteredMessage
          heading={ heading }
          body={ body }
        />
      )
  );
};
