import { FunctionComponent, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { InvitationDeliverableRecipientStatus } from '../../Hook';
import { FeedbackSeverity, StatusChip } from '../../../Common/Component';

type Props = {
  status: InvitationDeliverableRecipientStatus;
}

export const InvitationDeliverableRecipientStatusChip: FunctionComponent<Props> = ({ status }) => {
  const label = useMemo(() => {
    switch (status) {
      case 'pending':
        return (
          <FormattedMessage
            id="InvitationDeliverableRecipientStatusChip.pending"
            defaultMessage="Pending"
          />
        );
      case 'delivered':
        return (
          <FormattedMessage
            id="InvitationDeliverableRecipientStatusChip.delivered"
            defaultMessage="Delivered"
          />
        );
      case 'failed':
        return (
          <FormattedMessage
            id="InvitationDeliverableRecipientStatusChip.failed"
            defaultMessage="Failed"
          />
        );
      default:
        return (
          <FormattedMessage
            id="InvitationDeliverableRecipientStatusChip.unknown"
            defaultMessage="Unknown"
          />
        );
    }
  }, [status]);

  return (
    <StatusChip
      status={ getStatusSeverity(status) }
      label={ label }
    />
  );
};

const getStatusSeverity = (status: InvitationDeliverableRecipientStatus): FeedbackSeverity => {
  switch (status) {
    case 'delivered':
      return 'success';
    case 'failed':
      return 'error';
    default:
      return 'none';
  }
};

