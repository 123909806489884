import { FC } from 'react';

import { StyledSpaceIcon } from './style';

type Props = {
  path: string;
  spaceName: string;
};

export const SpaceIcon: FC<Props> = ({
  path,
  spaceName,
}) => (
  <StyledSpaceIcon>
    <img
      alt={ spaceName }
      src={ path }
    />
  </StyledSpaceIcon>
);
