import React, { FunctionComponent, MouseEvent, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Menu, MenuItem } from '@mui/material';
import { parseISO } from 'date-fns';

import { StyledContainer, StyledItem, StyledItemDetails, LoadingSpinnerContainer } from './style';
import Icon from '../../../Assets/img/icons/streamline/multiple-actions-time-users.svg';
import { DropdownButton, HintTooltip } from '../../../Common/Component';
import { useApi } from '../../../Core/Hook';
import { RecentBroadcastAudience } from '../../Model';
import { Paginated } from '../../../Models';
import { LoadingSpinner } from '../../../Components';
import { DateTimeFormatter } from '../../../Utility';
import { useAudienceConditionDefinitionRegistry } from '../../../Audiences/Hook';

interface Props {
  onSelected: (recentAudience: RecentBroadcastAudience) => void;
  onShown?: () => void;
}

export const RecentAudiencesDropdown: FunctionComponent<Props> = ({
  onSelected,
  onShown,
}) => {
  const api = useApi();
  const intl = useIntl();
  const audienceConditionDefinitionRegistry = useAudienceConditionDefinitionRegistry();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [recentAudiences, setRecentAudiences] = useState<RecentBroadcastAudience[] | null>(null);

  useEffect(
    () => {
      if (!api || recentAudiences || !anchorEl) {
        return;
      }

      let cancelled = false;
      api.get<Paginated<'audiences', RecentBroadcastAudience>>('/me/broadcasts/recent-audiences')
        .then((paginatedRecentAudiences) => {
          if (cancelled) {
            return;
          }

          setRecentAudiences(paginatedRecentAudiences.data.audiences);
        })
        .catch((error) => {
          if (cancelled) {
            return;
          }

          throw error;
        })

      return () => { cancelled = true };
    },
    [
      api,
      recentAudiences,
      setRecentAudiences,
      anchorEl,
    ],
  );

  const whenAudienceSelected = (recentAudience: RecentBroadcastAudience) => {
    setAnchorEl(null);
    onSelected(recentAudience);
  };

  const whenButtonClicked = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    onShown && onShown();
  };

  return (
    <StyledContainer>
      <HintTooltip
        title={ intl.formatMessage({
          id: 'broadcasts.recentAudiences.dropdownHint',
          description: 'Tooltip explaining the recent broadcast audiences dropdown.',
          defaultMessage: 'Recent audiences allows you to select an audience from your most recent broadcasts.',
        }) }
      />
      <DropdownButton
        onClick={ whenButtonClicked }
        IconComponent={ Icon }
      >
        <FormattedMessage
          id="broadcasts.recentAudiences.buttonText"
          description="Text for the recent audiences button"
          defaultMessage="Recent audiences"
        />
      </DropdownButton>
      <Menu
        open={ Boolean(anchorEl) }
        anchorEl={ anchorEl }
        onClose={ () => setAnchorEl(null) }
      >
        { recentAudiences === null && (
          <LoadingSpinnerContainer>
            <LoadingSpinner />
          </LoadingSpinnerContainer>
        ) }
        { (recentAudiences ?? []).map((recentAudience, index) => (
          <MenuItem
            key={ index }
            onClick={ () => whenAudienceSelected(recentAudience) }
          >
            <StyledItem>
              <span>
                { audienceConditionDefinitionRegistry.audienceContentsSummary(
                  intl,
                  recentAudience.audience,
                  recentAudience.contents,
                ) }
              </span>
              <StyledItemDetails>
                <FormattedMessage
                  id="broadcasts.recentAudiences.usageText"
                  description="Text for the recent audiences list items usage text"
                  defaultMessage="Used {date} in {name}"
                  values={ {
                    date: DateTimeFormatter.internationalisedDate(parseISO(recentAudience.usage.usedAt), intl.locale),
                    name: recentAudience.usage.broadcast.name,
                  } }
                />
              </StyledItemDetails>
            </StyledItem>
          </MenuItem>
        )) }
      </Menu>
    </StyledContainer>
  )
};
