import { useEffect } from 'react';
import { FormControl, MenuItem, Select } from '@mui/material';

import { ChoiceResponse } from '../../../Service';
import { ChoiceRequest as ChoiceRequestModel } from '../../../Model/ContentTypes';
import { SubmittedComponent } from '../../../../Content/Model';
import { ChoiceRequest } from '../../Content';
import { NoResponseMessage } from '../NoResponseMessage/NoResponseMessage';
import { VerticallySpaced } from '../../../../Common/Component';
import { AppFormInput, DropdownChevronIcon } from '../../Content/ChoiceRequestPreview/style';

export const SubmittedChoiceRequest: SubmittedComponent<ChoiceResponse, ChoiceRequestModel> = ({
  card,
  response,
  onFinish,
}) => {
  useEffect(() => {
    if (!onFinish) {
      return;
    }

    onFinish();
  }, [onFinish]);

  return (
    response
      ? (
        <ChoiceRequest
          content={ card.content }
          selection={ response?.selections.map(selection => selection.id) }
          readOnly
        />
      )
      : card.content.style === 'dropdown'
        ? (
          <FormControl
            variant="outlined"
            fullWidth
          >
            <Select
              value=""
              displayEmpty
              input={ <AppFormInput/> }
              disabled={ true }
              IconComponent={ DropdownChevronIcon }
            >
              <MenuItem value="" disabled>
                <NoResponseMessage/>
              </MenuItem>
            </Select>
          </FormControl>
        )
        : (
          <VerticallySpaced gap={ 1 }>
            <NoResponseMessage/>
            <ChoiceRequest
              content={ card.content }
              selection={ undefined }
              readOnly
            />
          </VerticallySpaced>
        )
  );
};
