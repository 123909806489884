import { ValidationTree, Validator } from '../../../Common/Model';
import { Validate } from '../../../Common/Utility';
import { ContentValidator } from './ContentValidator';
import { DraftVideoContent, VIDEO_CONTENT_TEXT_MAX_LENGTH } from '../../Model/ContentTypes/VideoContent';

export class VideoContentValidator {
  public static validateText = (content: DraftVideoContent): ValidationTree<string> => ({
    errors: textValidator.validate(content.text),
    children: {},
  });

  public static validateUploadId = (content: DraftVideoContent): ValidationTree<string> => ({
    errors: uploadIdValidator.validate(content.uploadId),
    children: {},
  });

  public static validateThumbnailKey = (content: DraftVideoContent): ValidationTree<string> => ({
    errors: thumbnailKeyValidator.validate(content.thumbnailKey),
    children: {},
  });

  public static validate = (content: DraftVideoContent): ValidationTree<DraftVideoContent> => ({
    errors: [
      ...ContentValidator.validate(content).errors,
    ],
    children: {
      ...ContentValidator.validate(content).children,
      text: VideoContentValidator.validateText(content),
      uploadId: VideoContentValidator.validateUploadId(content),
      thumbnailKey: VideoContentValidator.validateThumbnailKey(content),
    },
  });
}

const textValidator = new Validator<string>([
  Validate.notBlank,
  text => Validate.maxLength(text, VIDEO_CONTENT_TEXT_MAX_LENGTH),
]);

const thumbnailKeyValidator = new Validator<string>([Validate.notBlank]);
const uploadIdValidator = new Validator<string>([Validate.notBlank]);
