import { FC, PropsWithChildren, ReactNode, useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';

import { BroadcastContentContext, BroadcastNudge } from '../../../../Broadcasts/Model';
import { SingleContentCard, EventPresentationComponentProps, LiveComponentProps } from '../../../../Content/Model';
import { AcceptResponse, DeclineResponse } from '../../../Model';
import { Meeting } from '../../../../Broadcasts/Service';
import { useCancellablePut } from '../../../../Hooks';
import { useRecallErrorHandler } from '../../../Hook';
import { ErrorResponseReader } from '../../../../Common/Utility';

type DeclineRequestResponse = { spaces_remaining: number };
type AcceptRequestResponse = DeclineRequestResponse & { waiting: boolean };

type Props<C extends SingleContentCard<Meeting<string>>> = LiveComponentProps<C['content'], AcceptResponse | DeclineResponse, BroadcastContentContext> & {
  PresentationComponent: FC<EventPresentationComponentProps>;
  respondEndpointBroadcastType: string;
  feedback?: string;
  nudge?: BroadcastNudge;
  onChange: (card: C) => void;
};

export const LiveEventContent = <C extends SingleContentCard<Meeting<string>>>({
  PresentationComponent,
  card,
  onChange,
  response,
  context,
  onResponseChange,
  onReloadRequired,
  respondEndpointBroadcastType,
  feedback,
  nudge,
}: PropsWithChildren<Props<C>>): JSX.Element => {
  if (!card.content.personalStatus) {
    throw new Error(`Status missing for event with ID ${ card.content.id }.`);
  }
  const onResponseRejected = useRecallErrorHandler(onReloadRequired);
  const [responded, setResponded] = useState<boolean>(false);
  const [waiting, setWaiting] = useState<boolean>(card.content.personalStatus.status === 'waiting');
  const userCanRespond = card.content.personalStatus && ['full', 'attending', 'waiting', 'available', 'declined'].includes(card.content.personalStatus.status);
  const whenAttendSuccessful = useCallback((response: AxiosResponse<AcceptRequestResponse>) => {
    onResponseChange({
      type: 'accept',
      createdAt: new Date().toISOString(),
      deliveryId: context.deliveryId,
      contentId: card.content.id.toString(),
    });
    setWaiting(response.data.waiting);
    setResponded(true);
  }, [card.content, context.deliveryId, onResponseChange]);

  const whenDeclineSuccessful = useCallback(() => {
    onResponseChange({
      type: 'decline',
      createdAt: new Date().toISOString(),
      deliveryId: context.deliveryId,
      contentId: card.content.id.toString(),
    });
    setResponded(true);
  }, [card.content, context.deliveryId, onResponseChange]);

  useEffect(() => {
    if (!response || !responded) {
      return;
    }

    const status = response?.type === 'accept'
        ? waiting
          ? 'waiting'
          : 'attending'
        : 'declined';

    if (status === card.content.personalStatus?.status) {
      return;
    }

    onChange({
      ...card,
      content: {
        ...card.content,
        personalStatus: {
          status,
          metadata: {},
        },
      },
    });
  }, [card, onChange, responded, response, response?.type, waiting]);

  const whenResponseFailed = useCallback((error: unknown) => {
    if (!ErrorResponseReader.isApiError(error)) {
      return;
    }

    onResponseRejected(error);
  }, [onResponseRejected]);

  const [attend, attending] = useCancellablePut<AcceptRequestResponse>(
    `/${ respondEndpointBroadcastType }/${ card.content.id }/request`,
    whenAttendSuccessful,
    whenResponseFailed,
  );

  const [decline, declining] = useCancellablePut<DeclineRequestResponse>(
    `/${ respondEndpointBroadcastType }/${ card.content.id }/decline`,
    whenDeclineSuccessful,
    whenResponseFailed,
  );

  const responding = attending || declining;

  return (
    <PresentationComponent
      backButton={ context.closeButton }
      title={ card.content.text }
      startAt={ card.content.start_at }
      endAt={ card.content.end_at }
      location={ card.content.location }
      personalStatus={ card.content.personalStatus }
      navigationProps={ context.navigationProps }
      requestHidden={ !userCanRespond || (response && response.type === 'accept') }
      requestButtonProps={ {
        onClick: () => attend(),
        busy: responding,
      } }
      declineHidden={ !userCanRespond || (response && response.type === 'decline') }
      declineButtonProps={ {
        onClick: () => decline(),
        busy: responding,
      } }
      feedback={ feedback }
      nudge={ nudge }
    />
  );
};
