import { defaultTheme } from 'op-storybook';

export class ThemeHelper {
  public static lookUpColour = (stringPath: string, theme: typeof defaultTheme.new): string => {
    if (stringPath === 'inherit') {
      return stringPath;
    }

    const pathParts = stringPath.split('.');
    return recursiveLookup<string>(pathParts, theme.palette);
  }
}

const recursiveLookup = <T>(pathParts: string[], obj: Record<string, unknown>): T => (
  pathParts.length === 1
    ? obj[pathParts[0]] as T
    : recursiveLookup(pathParts.slice(1), obj[pathParts[0]] as Record<string, unknown>)
);
