import { FC } from 'react';
import { Button, ButtonProps } from '@ourpeople/shared/Core/Component/Input/Button/Button';

import { RequestState } from '../../../../Models';
import TickIcon from '../../../../Assets/img/icons/streamline/check-2.svg';
import { StyledRespondedText } from './style';

export type RespondButtonProps = {
  state: RequestState;
  onClick: () => void;
};

type Props = RespondButtonProps & Omit<ButtonProps, 'busy'> & {
  notRespondedLabel: string;
  respondedLabel: string;
  rejectedLabel: string;
};

export const RespondButton: FC<Props> = ({
  state,
  notRespondedLabel,
  respondedLabel,
  rejectedLabel,
  children,
  ...props
}) => (
  state !== RequestState.COMPLETE
    ? (
      <Button
        { ...props }
        busy={ state === RequestState.FETCHING }
      >
        {
          state === RequestState.FAILED
            ? rejectedLabel
            : notRespondedLabel
        }
      </Button>
    )
    : (
      <StyledRespondedText>
        { respondedLabel }
        <TickIcon role="presentation"/>
      </StyledRespondedText>
    )
);
