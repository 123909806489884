import { FunctionComponent, useState } from 'react';

import { ContentPreviewProps } from '../../../../Content/Model';
import { GeolocationRequestEditorContent } from '../../../Service';
import { GeolocationRequest } from '..';

export const GeolocationRequestPreview: FunctionComponent<ContentPreviewProps<GeolocationRequestEditorContent>> = () => {
  const [value, setValue] = useState<string>('');

  const whenInputClicked = (): void => {
    const latitude = randomCoordinate(-90, 90);
    const longitude = randomCoordinate(-180, 180);
    setValue(`${ latitude.toFixed(4) }, ${ longitude.toFixed(4) }`);
  }

  const randomCoordinate = (min: number, max: number): number =>
    Math.random() * (max - min + 1) + min;

  return (
    <GeolocationRequest
      busy={ false }
      onClear={ () => setValue('') }
      onInputClick={ whenInputClicked }
      value={ value }
    />
  );
};
