import { FC } from 'react';

import {
  StyledDeviceFooter,
  StyledDeviceFooterNotch,
  StyledDeviceHeader,
  StyledDeviceHeaderNotch,
  StyledDeviceInner,
  StyledDeviceOutline,
} from './style';

export const DeviceOutline: FC = ({ children }) => (
  <StyledDeviceOutline>
    <StyledDeviceInner>
      <StyledDeviceHeader>
        <StyledDeviceHeaderNotch/>
      </StyledDeviceHeader>
      <div>
        { children }
      </div>
      <StyledDeviceFooter>
        <StyledDeviceFooterNotch/>
      </StyledDeviceFooter>
    </StyledDeviceInner>
  </StyledDeviceOutline>
);
