import {
  ChoiceResponse,
  DateResponse,
  GeolocationResponse,
  NumberResponse,
  RatingResponse,
  TextResponse,
  UploadResponse,
} from '../Service';
import { Response } from '../Model';
import { SignResponse } from '../Service/ContentDefinitions/SignRequestDefinition';

export class ContentIdentifier {
  public static responseIsDateResponse = (response: Response): response is DateResponse =>
    response.contentType === 'dateRequest';

  public static responseIsNumberResponse = (response: Response): response is NumberResponse =>
    response.contentType === 'numberRequest';

  public static responseIsTextResponse = (response: Response): response is TextResponse =>
    response.contentType === 'textRequest';

  public static responseIsGeolocationResponse = (response: Response): response is GeolocationResponse =>
    response.contentType === 'geolocationRequest';

  public static responseIsChoiceResponse = (response: Response): response is ChoiceResponse =>
    response.contentType === 'choiceRequest';

  public static responseIsRatingResponse = (response: Response): response is RatingResponse =>
    response.contentType === 'ratingRequest';

  public static responseIsUploadResponse = (response: Response): response is UploadResponse =>
    response.contentType === 'uploadRequest';

  public static responseIsSignResponse = (response: Response): response is SignResponse =>
    response.contentType === 'signatureRequest';
}
