import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { TagType } from '../../../../Tags/Model';
import { Flex, LoadingButton, ValidationErrorMessage, VerticallySpaced } from '../../../../Common/Component';
import { TagTypeSelect } from '../../../../Tags/Component';
import { AddProfileTagInput, RemoveProfileTagInput } from '..';
import { ArrayHelper } from '../../../../Common/Utility';

type TagAction = 'remove' | 'add' | 'replace';

type Props = {
  open: boolean;
  action: TagAction;
  onDismiss: () => void;
  onConfirm: (
    tagToAdd?: string,
    tagToRemove?: string,
  ) => void;
  busy: boolean;
  errors?: string[];
};

export const TagActionDialog: FunctionComponent<Props> = ({
  open,
  action,
  onDismiss,
  onConfirm,
  busy,
  errors = [],
}) => {
  const intl = useIntl();
  const localisedHeading = (() => {
    switch (action) {
      case 'add':
        return intl.formatMessage({
          id: 'people.tagActionDialog.addTagHeading',
          description: 'Heading used in dialog when performing add tag action.',
          defaultMessage: 'Add tag',
        });
      case 'remove':
        return intl.formatMessage({
          id: 'people.tagActionDialog.removeTagHeading',
          description: 'Heading used in dialog when performing remove tag action.',
          defaultMessage: 'Remove tag',
        });
      default:
        return intl.formatMessage({
          id: 'people.tagActionDialog.replaceTagHeading',
          description: 'Heading used in dialog when performing replace tag action.',
          defaultMessage: 'Replace tag',
        });
    }
  })();
  const [tagType, setTagType] = useState<TagType>('team');
  const [removeTagIds, setRemoveTagIds] = useState<string[]>([]);
  const [addTagIds, setAddTagIds] = useState<string[]>([]);

  useEffect(() => {
    setRemoveTagIds([]);
    setAddTagIds([]);
  }, [tagType]);

  const whenConfirmClicked = useCallback(() => onConfirm(
    addTagIds[0],
    removeTagIds[0],
  ), [addTagIds, onConfirm, removeTagIds]);

  return (
    <Dialog
      open={ open }
      fullWidth
      maxWidth="sm"
      onClose={ onDismiss }
      TransitionProps={{
        onExited: () => {
          setTagType('team');
          setRemoveTagIds([]);
          setAddTagIds([]);
        },
      }}
    >
      <DialogTitle>{ localisedHeading }</DialogTitle>
      <DialogContent>
        <VerticallySpaced gap={ 2 }>
          <Flex gap={ 2 }>
            <span>Type</span>
            <TagTypeSelect
              availableTypes={[
                'team',
                'jobtitle',
                'skill',
              ]}
              readonly={ busy }
              tagType={ tagType }
              onChange={ setTagType }
            />
          </Flex>
          { actionIsRemoveOrReplace(action) && (
            <RemoveProfileTagInput
              disabled={ busy }
              action={ action }
              tagType={ tagType }
              tagIds={ removeTagIds }
              onTagIdsChanged={ setRemoveTagIds }
            />
          ) }
          { actionIsAddOrReplace(action) && (
            <AddProfileTagInput
              disabled={ busy }
              action={ action }
              tagType={ tagType }
              tagIds={ addTagIds }
              onTagIdsChanged={ setAddTagIds }
            />
          ) }
        </VerticallySpaced>
        { errors.map(error => (
          <ValidationErrorMessage key={ error }>
            { error }
          </ValidationErrorMessage>
        )) }
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={ onDismiss }
          disabled={ busy }
        >
          <FormattedMessage
            id="people.tagActionDialog.cancel"
            description="Cancel button label in tag action dialog."
            defaultMessage="Cancel"
          />
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          disableElevation
          busy={ busy }
          onClick={ whenConfirmClicked }
        >
          <FormattedMessage
            id="people.tagActionDialog.confirm"
            description="Confirm button label in tag action dialog."
            defaultMessage="Confirm"
          />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const actionIsRemoveOrReplace = ArrayHelper.createTypeGuard(['remove', 'replace']);
const actionIsAddOrReplace = ArrayHelper.createTypeGuard(['add', 'replace']);
