import { Action, Location } from 'history';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

type NavigationEvent = {
  location: Location;
  action: Action;
};

type ReturnType = {
  blockedNavigationEvent: NavigationEvent | undefined;
  resumeNavigation: () => void;
  resetNavigation: () => void;
};

export const useConfirmNavigation = (
  active: boolean,
  ignorePattern?: RegExp,
): ReturnType => {
  const [blockedNavigationEvent, setBlockedNavigationEvent] = useState<NavigationEvent>();
  const history = useHistory();

  useEffect(() => {
    if (blockedNavigationEvent || !active) {
      return;
    }

    const currentLocation = history.location;
    return history.block((location, action) => {
      const ignoreChange = ignorePattern
        ? !!ignorePattern.exec(location.pathname)
        : location.pathname === currentLocation.pathname;

      if (ignoreChange) {
        return;
      }

      setBlockedNavigationEvent({
        location,
        action,
      });

      return false;
    });
  }, [active, blockedNavigationEvent, history, history.location, ignorePattern]);

  const resumeNavigation = useCallback(() => {
    if (!blockedNavigationEvent) {
      return;
    }

    const path = `${ blockedNavigationEvent.location.pathname }${ blockedNavigationEvent.location.search }`;
    blockedNavigationEvent.action === 'POP'
      ? history.goBack()
      : blockedNavigationEvent.action === 'PUSH'
        ? history.push(path, blockedNavigationEvent.location.state)
        : history.replace(path, blockedNavigationEvent.location.state);
  }, [blockedNavigationEvent, history]);

  const resetNavigation = useCallback(() => (
    setBlockedNavigationEvent(undefined)
  ), []);

  return useMemo(() => ({
    blockedNavigationEvent,
    resumeNavigation,
    resetNavigation,
  }), [blockedNavigationEvent, resetNavigation, resumeNavigation]);
};
