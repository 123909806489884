import { ChangeEvent, ChangeEventHandler, useCallback } from 'react';

export const useFileSelect = (mimeType?: string): (onChange: ChangeEventHandler<HTMLInputElement>, onCancel?: () => void) => void => {
  return useCallback((onChange, onCancel) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = mimeType || acceptedFormats.map(format => `.${ format }`).join(',');

    const removeCancelListeners = () => {
      input.removeEventListener('cancel', whenCancelled);
      window.removeEventListener('focus', whenCancelled);
    };

    const whenCancelled = () => {
      removeCancelListeners();
      onCancel && onCancel();
    };

    const whenChanged = (event: ChangeEvent<HTMLInputElement>) => {
      removeCancelListeners();
      onChange(event);
    }

    input.addEventListener('cancel', whenCancelled); // Firefox
    window.addEventListener('focus', whenCancelled); // Antiquated browsers

    input.addEventListener('change', whenChanged as unknown as (event: Event) => void);

    input.click();
  }, [mimeType]);
}

const acceptedFormats = [
  // Archive
  'zip',

  // Audio
  'ogg',
  'opus',
  'mp3',
  'm4a',
  'wav',

  // Plain
  'csv',
  'txt',

  // Rich
  'pdf',
  'rtf',

  // Microsoft Office
  'docx',
  'xlsx',
  'pptx',

  // Apple
  'key',
  'numbers',
  'pages',

  // OpenOffice
  'fodt',
  'odt',
  'fods',
  'ods',
  'fodp',
  'odp',

  // Image
  'bmp',
  'eps',
  'gif',
  'jpg',
  'jpeg',
  'png',
  'svg',
  'webp',

  // Video
  '3gp',
  'avi',
  'mpg',
  'mpeg',
  'mp4',
  'm4v',
  'mov',
  'webm',
  'wmv',
  'mkv',
];
