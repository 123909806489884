import { FormattedMessage, useIntl } from 'react-intl';
import { ReactNode } from 'react';

import { ValidationError } from '../../../Common/Model';
import { TagLicenseLimitReachedError } from '../../Model';
import { Notice } from '../../../Common/Component';
import { TagParser } from '../../Utility';
import { ImportErrorPresentationComponent } from '../../../Imports/Model';

export const TagImportPrepareErrors: ImportErrorPresentationComponent = ({ errors }) => {
  const intl = useIntl();

  const getErrorMessage = (error: ValidationError): ReactNode => {
    switch (error.type) {
      case 'licenseLimitReached':
        return (
          <FormattedMessage
            id="tags.import.licenseLimitReachedError"
            description="Error when import would put the environment over the team limit."
            defaultMessage="This import exceeds your { singularTagType } limit by { over }. Please make sure your import contains { available, plural, =0 {no new {pluralTagType}} one {no more than # new {singularTagType}} other {no more than # new {pluralTagType}} }."
            values={ {
              available: (error as TagLicenseLimitReachedError).metadata.available,
              over: (error as TagLicenseLimitReachedError).metadata.needed - (error as TagLicenseLimitReachedError).metadata.available,
              singularTagType: TagParser.localisedTagTypeFromInternalTagType((error as TagLicenseLimitReachedError).metadata.recordType, intl, 1),
              pluralTagType: TagParser.localisedTagTypeFromInternalTagType((error as TagLicenseLimitReachedError).metadata.recordType, intl, Infinity),
            } }
          />
        );
      default:
        return (
          <FormattedMessage
            id="tags.import.fallbackPrepareError"
            description="Fallback prepare error when error can not be identified."
            defaultMessage="There is a problem with your import data."
          />
        );
    }
  };

  return (
    <>
      { errors.map(error => (
        <Notice
          key={ error.type }
          variant="outlined"
          feedback={ {
            severity: 'error',
            message: getErrorMessage(error),
          } }
        />
      )) }
    </>
  );
};
