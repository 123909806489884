import styled from '@emotion/styled';

export const StyledTabBar = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;

  li {
    flex: 1 1 0;
  }
`;

export const StyledTabButton = styled.button<{ active: boolean }>`
  border: none;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: ${ props => props.theme.spacing(1) };
  padding: ${ props => props.theme.spacing(1) } 0;
  background-color: transparent;
  transition: background-color 0.2s, color 0.1s;
  color: ${ props => props.theme.palette.text.primary };
  font-size: ${ props => props.theme.fontSize.sm };

  ${ props => props.active && `
    color: ${ props.theme.palette.primary.main };
  ` }

  &:active {
    background-color: ${ props => props.theme.palette.transparent.active };
  }
`;
