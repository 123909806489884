import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { TabContext, TabPanel } from '@mui/lab';
import { Tabs } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';

import { Box, FlexPullRight, PageHeader } from '../../../Common/Component';
import { Padded, Tab } from '../../../Components';
import { GuardedLink } from '../../../Security/Component';
import { BroadcastsPermission } from '../../Model';
import { usePermissions } from '../../../Security/Hook';
import { CategoriesTable, DefaultCategoriesForm } from '../../Component';
import { useLocalStorageHookContext } from '../../../Common/Hook';
import { QueryWithKeys } from '../../../Common/Utility';
import { useQueryAsState } from '../../../Hooks';

type Query = QueryWithKeys<'status' | 'pageNum'>;

export const ListCategoriesPage: FC = () => {
  const intl = useIntl();
  const [query, setQuery] = useQueryAsState<Query>();
  const { permissionAvailable } = usePermissions();
  const tab = useMemo(() => query.status || 'published', [query.status]);
  const [tableStale, setTableStale] = useState<boolean>(false);
  const getStorageHook = useLocalStorageHookContext();
  const useDefaultCategoriesFormExpanded = getStorageHook<boolean>('broadcastCategories.defaultCategoriesFormExpanded', true);
  const [defaultCategoriesFormExpanded, setDefaultCategoriesFormExpanded] = useDefaultCategoriesFormExpanded();
  const history = useHistory();

  useEffect(() => {
    if (history.location.hash !== '#defaults') {
      return;
    }

    setDefaultCategoriesFormExpanded(true);
  }, [history.location.hash, setDefaultCategoriesFormExpanded]);

  const whenFormToggled = useCallback(() => {
    if (defaultCategoriesFormExpanded) {
      const { pathname, search } = history.location;
      history.replace({
        pathname,
        search,
      });
    }

    setDefaultCategoriesFormExpanded(expanded => !expanded);
  }, [defaultCategoriesFormExpanded, history, setDefaultCategoriesFormExpanded]);

  const whenTabChanged = useCallback((_event, status: string) => (
    setQuery(query => ({
      ...query,
      status,
      pageNum: '1',
    }))
  ), [setQuery]);

  return (
    <Padded>
      <PageHeader
        items={ [
          {
            link: '/broadcasts',
            title: intl.formatMessage({
              id: 'section.broadcasts',
              description: 'Heading label for Broadcasts',
              defaultMessage: 'Broadcasts'
            })
          },
          {
            title: intl.formatMessage({
              id: 'sections.broadcasts.categories',
              description: 'Title displayed over the broadcasts categories page',
              defaultMessage: 'Categories'
            }),
          },
        ] }
      >
        <FlexPullRight>
          { permissionAvailable(BroadcastsPermission.READ) && (
            <Button
              permissions={ [BroadcastsPermission.READ] }
              component={ GuardedLink }
              to="/broadcasts"
            >
              <FormattedMessage
                id="broadcasts.broadcasts.list"
                description="Broadcasts list page button CTA."
                defaultMessage="Manage broadcasts"
              />
            </Button>
          ) }
          { permissionAvailable(BroadcastsPermission.CATEGORIES_CREATE) && (
            <Button
              permissions={ [BroadcastsPermission.CATEGORIES_CREATE] }
              component={ GuardedLink }
              to="/broadcasts/categories/create"
              variant="primary"
            >
              <FormattedMessage
                id="broadcasts.categories.create"
                description="Broadcast categories page create button CTA."
                defaultMessage="Create Category"
              />
            </Button>
          ) }
        </FlexPullRight>
      </PageHeader>
      { permissionAvailable(BroadcastsPermission.CATEGORIES_UPDATE) && (
        <div id="defaults">
          <DefaultCategoriesForm
            expanded={ defaultCategoriesFormExpanded }
            onSave={ () => setTableStale(true) }
            onToggle={ whenFormToggled }
          />
        </div>
      ) }
      <Box padded={ false }>
        <TabContext value={ tab }>
          <Tabs
            onChange={ whenTabChanged }
            value={ tab }
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab
              value="published"
              label={ intl.formatMessage({
                id: 'listCategoriesPage.published.label',
                description: 'Label for published tab in broadcast categories page',
                defaultMessage: 'Published',
              }) }
            />
            <Tab
              value="unpublished"
              label={ intl.formatMessage({
                id: 'listCategoriesPage.unpublished.label',
                description: 'Label for unpublished tab in broadcast categories page.',
                defaultMessage: 'Unpublished',
              }) }
            />
          </Tabs>
          <TabPanel
            value="published"
          >
            <CategoriesTable
              stale={ tableStale }
              onUpdate={ () => setTableStale(false) }
            />
          </TabPanel>
          <TabPanel
            value="unpublished"
          >
            <CategoriesTable
              stale={ tableStale }
              onUpdate={ () => setTableStale(false) }
            />
          </TabPanel>
        </TabContext>
      </Box>
    </Padded>
  );
};
