import { default as React, FC, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { AutocompleteSelectionChanged } from '@ourpeople/shared/Core/Component/Input/Autocomplete/Autocomplete';

import { ContentEditorProps } from '../../../../Content/Model';
import { TrainingEditorContent } from '../../../Service';
import { FieldValidationErrors, Notice, VerticallySpaced } from '../../../../Common/Component';
import { SharedMeetingAndTrainingFields, TrainingSuccessTagInput } from '..';
import { Tag } from '../../../../Tags/Model';

export const TrainingEditor: FC<ContentEditorProps<TrainingEditorContent>> = ({
  editorContent,
  onEditorContentChanged,
  validation,
  onValidationChanged,
}) => {
  const intl = useIntl();
  const onSuccessFieldLabel = intl.formatMessage({
    id: 'broadcasts.content.training.fields.onSuccess.label',
    description: 'Label for onSuccess autocomplete of broadcasts meeting editor.',
    defaultMessage: 'Success tags',
  });

  const whenOnSuccessTagsChanged: AutocompleteSelectionChanged<Tag<'skill'> | Tag<'jobtitle'>> = useCallback(
    (selection) => (
      onEditorContentChanged({
        ...editorContent,
        card: {
          ...editorContent.card,
          content: {
            ...editorContent.card.content,
            on_success: selection.options.map(option => option.id).concat(selection.unknownIds),
          }
        }
      })
    ),
    [editorContent, onEditorContentChanged],
  );

  return (
    <VerticallySpaced gap={ 2 }>
      <SharedMeetingAndTrainingFields
        editorContent={ editorContent }
        onEditorContentChanged={ onEditorContentChanged }
        validation={ validation }
        onValidationChanged={ onValidationChanged }
      />
      <VerticallySpaced
        gap={ 1 }
      >
        <h4>{ onSuccessFieldLabel }</h4>
        <TrainingSuccessTagInput
          selectedIds={ editorContent.card.content.on_success }
          onSelectionChanged={ whenOnSuccessTagsChanged }
        />
        <FieldValidationErrors
          fieldName={ onSuccessFieldLabel }
          validationErrors={ validation?.children.content?.children.on_success?.errors || [] }
        />
        <Notice
          feedback={ {
            severity: 'info',
            message: intl.formatMessage({
              id: 'broadcasts.editor.training.successTagsNotice',
              description: 'Notice explaining purpose of success tags in the training card editor.',
              defaultMessage: 'Selected tags will be applied to successful people.',
            }),
          } }
          variant="outlined"
        />
      </VerticallySpaced>
    </VerticallySpaced>
  );
}
