import { FC } from 'react';
import { useIntl } from 'react-intl';

import { CenteredMessageWithButton } from '../CenteredMessageWithButton/CenteredMessageWithButton';

type Props = {
  onRetryClicked?: () => void;
};

export const CenteredConnectionErrorMessage: FC<Props> = ({
  onRetryClicked,
}) => {
  const intl = useIntl();

  return (
    <CenteredMessageWithButton
      heading={ intl.formatMessage({
        description: 'Default connection error message heading.',
        defaultMessage: 'Connection problem',
      }) }
      body={ intl.formatMessage({
        description: 'Default connection error message body.',
        defaultMessage: 'A connection could not be established.',
      }) }
      onButtonClicked={ onRetryClicked }
    />
  );
};
