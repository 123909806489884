import { DraftFormRequest, FormRequest } from '../Content';

export const UPLOAD_REQUEST_MIN_UPLOADS_MIN = 1;
export const UPLOAD_REQUEST_MIN_UPLOADS_MAX = 25;
export const UPLOAD_REQUEST_MAX_UPLOADS_MIN = 1;
export const UPLOAD_REQUEST_MAX_UPLOADS_MAX = 25;
export const UPLOAD_REQUEST_MAX_UPLOADS_DEFAULT = 10;

export type UploadRequest = FormRequest & {
  type: 'uploadRequest';
  accept: string;
  maxSize?: number;
  minUploads: number;
  maxUploads: number;
};

export type DraftUploadRequest = DraftFormRequest & {
  type: 'uploadRequest';
  accept: string;
  maxSize?: number;
  minUploads: number;
  maxUploads: number;
};
