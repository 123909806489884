import { FC, ReactNode } from 'react';

import { ModalDialog } from '../../../New/Common/Component/Layout/ModalDialog';

type Props = {
  overlay: ReactNode;
  block: boolean;
  children?: ReactNode;
};

export const BlockingOverlay: FC<Props> = ({
  overlay,
  block,
  children,
}) => (
  <>
    { children }
    { block && (
      <ModalDialog
        open={ true }
        onOpenChange={ () => null }
      >
        <div
          css={ theme => ({
            width: '100%',
            backgroundColor: theme.new.basePalette.white.main,
          }) }
        >
          { overlay }
        </div>
      </ModalDialog>
    ) }
  </>
);
