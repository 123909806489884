import { Autocomplete, AutocompleteProps, AutocompleteQuery } from '@ourpeople/shared/Core/Component/Input/Autocomplete/Autocomplete';
import { useDebounce } from '@ourpeople/shared/Core/Hook/useDebounce';

import { useDistinct } from '../../../Core/Hook';

export type DebouncedAutocompleteProps<T extends { id: string }> = Omit<AutocompleteProps<T>, 'useDebounce'>;

export const DebouncedAutocomplete = <T extends { id: string }>({ ...autocompleteProps }: DebouncedAutocompleteProps<T>): JSX.Element => {
  const useDebouncedQuery = (
    query: AutocompleteQuery,
    queriesEqual: (queryA: AutocompleteQuery, queryB: AutocompleteQuery) => boolean,
    debounceDuration: number,
  ) => (
    useDistinct<AutocompleteQuery>(
      useDebounce<AutocompleteQuery>(query, debounceDuration),
      queriesEqual,
    )
  );

  return (
    <Autocomplete
      { ...autocompleteProps }
      useDebounce={ useDebouncedQuery }
    />
  );
};
