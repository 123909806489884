import { default as React, FC, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Heading } from '@ourpeople/shared/Core/Component/Content';
import { defaultPlaceholders, Editor } from '@ourpeople/shared/Core/Component/Input/RichTextEditor/Editor/Editor';

import { ContentEditorProps } from '../../../../Content/Model';
import { FieldValidationErrors, Flex, FlexPullRight } from '../../../../Common/Component';
import {
  DraftTextContent,
  TEXT_CONTENT_HTML_MAX_LENGTH,
  TextContentValidator,
  TextEditorContent
} from '../../../Service';
import { useEnvironmentSettings, useUserRoles } from '../../../../Common/Hook';
import { RichTextSanitiser } from '../../../../Common/Utility';
import { CharacterCount } from '../../../../Content/Component';
import { Form } from '../../../../Forms/Model';
import { LinkEditor } from '../LinkEditor/LinkEditor';
import { ValidationTree } from '../../../../Common/Model';
import { MinimalFileEntry } from '../../../../Files/Model/FileEntry';

export const TextContentEditor: FC<ContentEditorProps<TextEditorContent>> = ({
  editorContent,
  onEditorContentChanged,
  validation,
  onValidationChanged,
}) => {
  const intl = useIntl();
  const { card } = useMemo(() => editorContent, [editorContent]);
  const {
    filesEnabled = false,
    formsEnabled = false,
  } = useEnvironmentSettings();
  const { userIsSuperAdmin } = useUserRoles();

  const whenTextChanged = useCallback((text: string): void => {
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          text,
        },
      },
    });
  }, [onEditorContentChanged, editorContent]);

  const whenTextBlurred = useCallback((): void => onValidationChanged({
    errors: [],
    children: {
      content: {
        errors: [],
        children: {
          ...validation?.children.content?.children,
          text: TextContentValidator.validateText(card.content.text),
        },
      },
    },
  }), [card.content.text, onValidationChanged, validation?.children.content?.children]);

  const whenLinkChanged = useCallback((
    draftLink: DraftTextContent,
    form: Form | null,
    file: MinimalFileEntry | null,
  ) => {
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: draftLink,
      },
      form,
      fileEntry: file,
    })
  }, [editorContent, onEditorContentChanged]);

  const whenValidationChanged = useCallback(
    (contentValidation: ValidationTree<DraftTextContent>) => (
      onValidationChanged({
        errors: validation?.errors || [],
        children: {
          ...validation?.children,
          content: contentValidation,
        },
      })
    ),
    [onValidationChanged, validation?.children, validation?.errors],
  );

  return (
    <>
      <Flex>
        <Heading type="h4">
          <FormattedMessage
            id="broadcasts.content.text.fields.text.label"
            description="Label for html input in broadcasts text content."
            defaultMessage="Text"
          />
        </Heading>
        <FlexPullRight>
          <CharacterCount
            minimum={ 1 }
            maximum={ TEXT_CONTENT_HTML_MAX_LENGTH }
            current={ RichTextSanitiser.stripTags(card.content.text).length }
          />
        </FlexPullRight>
      </Flex>
      <Editor
        availablePlaceholders={ defaultPlaceholders }
        value={ card.content.text }
        onChange={ whenTextChanged }
        onBlur={ whenTextBlurred }
        analyticsContext="Broadcast text content"
        aiPromptOptions={ {
          maxLength: TEXT_CONTENT_HTML_MAX_LENGTH,
        } }
      />
      <FieldValidationErrors
        fieldName={ intl.formatMessage({
          id: 'broadcasts.content.text.fields.text.name',
          defaultMessage: 'text',
        }) }
        validationErrors={ validation?.children.content?.children.text?.errors || [] }
      />
      <LinkEditor
        allowFile={ userIsSuperAdmin && filesEnabled }
        allowForm={ formsEnabled }
        draftLink={ card.content }
        onChange={ whenLinkChanged }
        file={ editorContent.fileEntry }
        form={ editorContent.form }
        validation={ validation?.children.content || { errors: [], children: {} } }
        onValidationChange={ whenValidationChanged }
      />
    </>
  );
};
