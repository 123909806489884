import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import * as RadDialog from '@radix-ui/react-dialog';

import { ModalHeader } from '../../../lib/components/ModalHeader/ModalHeader';
import { ModalActions } from '../../../lib/components/ModalActions/ModalActions';
import { ModularCard } from '../../../lib/components/ModularCard/ModularCard';
import { CardContent } from '../../../lib/components/CardContent/CardContent';
import { SvgComponent } from '../../../lib/model/SvgComponent';
import { StyleBuilder } from '../../../lib/model/StyleBuilder/StyleBuilder';
import { Overlay } from '../../../lib/components/Overlay/Overlay';

type Props = {
  open: boolean;
  onClose?: () => void;
  title: string;
  IconComponent?: SvgComponent;
  description?: string;
  seamless?: boolean;
  direction?: 'row' | 'column';
  footerContent?: ReactNode;
  maxWidth?: string;
  onExited?: () => void;
  className?: string;
  children?: ReactNode;
};

export const Modal: FC<Props> = ({
  open,
  onClose,
  title,
  IconComponent,
  description,
  seamless,
  footerContent,
  direction,
  maxWidth = '50%',
  onExited,
  className,
  children,
}) => {
  const [mounted, setMounted] = useState<boolean>(open);
  const styles = useMemo(() => buildStyles({ open, maxWidth }), [open, maxWidth]);

  useEffect(() => {
    if (open) {
      setMounted(true);
      return;
    }

    const timeout = setTimeout(() => {
      setMounted(false);
      onExited && onExited();
    }, 200);

    return () => {
      clearTimeout(timeout)
    };
  }, [onExited, open]);

  return (
    <RadDialog.Root
      open={ mounted }
    >
      <RadDialog.Portal>
        <RadDialog.Overlay asChild>
          <Overlay
            open={ open }
            { ...onClose ? { onClick: onClose } : {} }
            css={ {
              zIndex: 1000,
            } }
          />
        </RadDialog.Overlay>
        <RadDialog.Content
          css={ styles.content }
          className={ className }
        >
          <div
            css={ styles.dialog }
          >
            <ModularCard
              elevation="xl"
              seamless={ seamless }
              headerContent={
                <ModalHeader
                  title={ title }
                  description={ description }
                  IconComponent={ IconComponent }
                  { ...onClose ? { onClose } : {} }
                />
              }
              {
                ...footerContent
                  ? {
                    footerContent: (
                      <ModalActions
                        direction={ direction }
                        fullWidth={ direction === 'column' }
                      >
                        { footerContent }
                      </ModalActions>
                    )
                  }
                  : {}
              }
              inset
            >
              { children }
            </ModularCard>
          </div>
        </RadDialog.Content>
      </RadDialog.Portal>
    </RadDialog.Root>
  );
};

type StyleProps = {
  open: boolean;
  maxWidth: string;
};

const buildStyles: StyleBuilder<StyleProps> = ({ open, maxWidth }) => ({
  content: theme => ({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.new.spacing[4],
    opacity: 0,
    pointerEvents: 'none',
    transition: 'opacity 0.2s',

    ...open
      ? {
        opacity: 1,
        pointerEvents: 'all',
      }
      : {},
  }),
  dialog: theme => ({
    position: 'relative',
    width: '100%',
    zIndex: 1000,

    [theme.new.breakpoints.sm.up]: {
      maxWidth,
    },
  })
})
