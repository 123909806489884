import { Radio, RadioGroup, TextField } from '@mui/material';
import {
  ChangeEventHandler,
  default as React,
  FC,
  FormEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Heading } from '@ourpeople/shared/Core/Component/Content';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';
import { getDescendantErrors } from 'op-storybook/lib/utility/getDescendantErrors';

import {
  Divider,
  FieldValidationErrors,
  Flex,
  FlexPullRight,
  ValidationErrorMessage,
  VerticallySpaced
} from '../../../Common/Component';
import { StyledFormControlLabel, StyledTextFieldContainer } from './style';
import { DraftTopic } from '../../Model';
import { AudienceBuilder } from '../../../Audiences/Component';
import { Audience } from '../../../Audiences/Model';
import { DESCRIPTION_MAX_LENGTH, NAME_MAX_LENGTH, NAME_MIN_LENGTH, TopicValidator } from '../../Utility';
import { ValidationTree } from '../../../Common/Model';
import { useDescendantErrors } from '../../../Common/Hook';
import { AudienceCleaner } from '../../../Audiences/Utility';
import { useContextOrThrow } from '../../../Core/Hook';
import { ToastContext } from '../../../Core/Context';

type Props = {
  initialState: DraftTopic;
  validation: ValidationTree<DraftTopic> | undefined;
  submitting: boolean;
  onSubmit: (topic: DraftTopic) => void;
  renderAudienceMembers: (audience: Audience) => JSX.Element;
};

export const TopicEditForm: FC<Props> = ({
  initialState,
  validation,
  submitting,
  onSubmit,
  renderAudienceMembers,
}) => {
  const intl = useIntl();
  const [topic, setTopic] = useState<DraftTopic>(initialState);
  const [localValidation, setLocalValidation] = useState<ValidationTree<DraftTopic> | undefined>(validation);
  const joinStyle = topic.autoJoin ? 'auto' : 'manual';
  const { addErrorToast } = useContextOrThrow(ToastContext);
  const audienceErrors = useDescendantErrors(localValidation?.children.audience);
  const cleanAudience = useMemo(() => AudienceCleaner.cleanAudience(topic.audience), [topic.audience]);

  useEffect(() => {
    setLocalValidation(localValidation => validation || localValidation);
  }, [validation]);

  const whenSubmitted: FormEventHandler = useCallback((event) => {
    event.preventDefault();
    const validation = TopicValidator.validate(topic);
    const hasErrors = !!getDescendantErrors(validation).length;
    setLocalValidation(validation);

    if (hasErrors) {
      return addErrorToast(
        intl.formatMessage({
          id: 'topicEditForm.saveError',
          description: 'Toast message when user attempts to save topic with validation errors.',
          defaultMessage: 'Your topic has errors, please correct them and try again.'
        })
      );
    }

    onSubmit(topic);
  }, [addErrorToast, intl, onSubmit, topic]);

  const whenJoinStyleChanged: ChangeEventHandler<HTMLInputElement> = event => (
    setTopic(topic => ({
      ...topic,
      autoJoin: event.target.value === 'auto',
    }))
  );

  const whenNameChanged: ChangeEventHandler<HTMLInputElement> = event => (
    setTopic(topic => ({
      ...topic,
      name: event.target.value,
    }))
  );

  const whenDescriptionChanged: ChangeEventHandler<HTMLInputElement> = event => (
    setTopic(topic => ({
      ...topic,
      description: event.target.value,
    }))
  );

  const whenAudienceChanged = (audience: Audience) => (
    setTopic(topic => ({
      ...topic,
      audience,
    }))
  );

  const nameFieldLabel = useMemo(() => intl.formatMessage({
    id: 'topicEditForm.name.label',
    description: 'Label for name field in topic edit form.',
    defaultMessage: 'Topic name',
  }), [intl]);

  const descriptionFieldLabel = useMemo(() => intl.formatMessage({
    id: 'topicEditForm.description.label',
    description: 'Label for description field in topic edit form.',
    defaultMessage: 'Description',
  }), [intl]);

  const whenNameBlurred = useCallback(() => (
    setLocalValidation(localValidation => ({
      errors: localValidation?.errors || [],
      children: {
        ...localValidation?.children,
        name: TopicValidator.validateName(topic.name),
      },
    }))
  ), [topic.name]);

  const whenDescriptionBlurred = useCallback(() => (
    setLocalValidation(localValidation => ({
      errors: localValidation?.errors || [],
      children: {
        ...localValidation?.children,
        description: topic.description
          ? TopicValidator.validateDescription(topic.description)
          : {
            errors: [],
            children: {},
          },
      },
    }))
  ), [topic.description]);

  return (
    <form onSubmit={ whenSubmitted }>
      <VerticallySpaced gap={ 4 }>
        <StyledTextFieldContainer>
          <VerticallySpaced gap={ 1 }>
            <TextField
              label={ nameFieldLabel }
              inputProps={ {
                required: true,
                min: NAME_MIN_LENGTH,
                max: NAME_MAX_LENGTH,
              } }
              onBlur={ whenNameBlurred }
              error={ !!localValidation?.children.name?.errors.length }
              placeholder={ intl.formatMessage({
                id: 'topicEditForm.name.placeholder',
                description: 'Placeholder for name field in topic edit form.',
                defaultMessage: 'Enter topic name',
              }) }
              value={ topic.name }
              onChange={ whenNameChanged }
            />
            { !!localValidation?.children.name?.errors.length && (
              <FieldValidationErrors
                fieldName={ nameFieldLabel }
                validationErrors={ localValidation.children.name.errors }
              />
            ) }
          </VerticallySpaced>
          <VerticallySpaced gap={ 1 }>
            <TextField
              label={ descriptionFieldLabel }
              inputProps={ {
                max: DESCRIPTION_MAX_LENGTH,
              } }
              onBlur={ whenDescriptionBlurred }
              error={ !!localValidation?.children.description?.errors.length }
              placeholder={ intl.formatMessage({
                id: 'topicEditForm.description.placeholder',
                description: 'Placeholder for description field in topic edit form.',
                defaultMessage: 'Optional description',
              }) }
              value={ topic.description || '' }
              onChange={ whenDescriptionChanged }
            />
            { !!localValidation?.children.description?.errors.length && (
              <FieldValidationErrors
                fieldName={ descriptionFieldLabel }
                validationErrors={ localValidation?.children.description.errors }
              />
            ) }
          </VerticallySpaced>
        </StyledTextFieldContainer>
        <Divider/>
        <VerticallySpaced gap={ 1 }>
          <Heading type="h4">
            <FormattedMessage
              id="topicEditForm.joinStyle"
              description="Description for topic join style in topic edit form."
              defaultMessage="How would you like to create and manage your audience?"
            />
          </Heading>
          <RadioGroup
            value={ joinStyle }
            onChange={ whenJoinStyleChanged }
          >
            <Flex gap={ 2 } noWrap>
              <StyledFormControlLabel
                value="auto"
                control={ <Radio size="small"/> }
                labelPlacement="end"
                label={ intl.formatMessage({
                  id: 'topicEditForm.autoJoin.label',
                  description: 'Label for auto join option when editing a topic.',
                  defaultMessage: 'Auto join',
                }) }
              />
              <FormattedMessage
                id="topicEditForm.autoJoin.description"
                description="More detailed description of the auto join topic option"
                defaultMessage="Audience members will be instantly added to this topic and will start receiving notifications."
              />
            </Flex>
            <Flex gap={ 2 } noWrap>
              <StyledFormControlLabel
                value="manual"
                control={ <Radio size="small"/> }
                labelPlacement="end"
                label={ intl.formatMessage({
                  id: 'topicEditForm.manualJoin.label',
                  description: 'Label for manual join option when editing a topic.',
                  defaultMessage: 'Join manually',
                }) }
              />
              <FormattedMessage
                id="topicEditForm.autoJoin.description"
                description="More detailed description of the auto join topic option"
                defaultMessage={ 'Creates an empty topic that audience members join via "Join a Chat" before receiving notifications.' }
              />
            </Flex>
          </RadioGroup>
        </VerticallySpaced>
        <Divider/>
        <VerticallySpaced gap={ 1 }>
          <Heading type="h4">
            <FormattedMessage
              id="topicEditForm.audienceFilters.heading"
              description="Heading for audience filter section in topic edit form."
              defaultMessage="Audience filters"
            />
          </Heading>
          <span>
            <FormattedMessage
              id="topicEditForm.audienceFilters.description"
              description="Description for audience filter section in topic edit form."
              defaultMessage="People will { autoJoin, select, true { be automatically added or removed from this topic } other { have access to this topic granted } } based on the following audience filters."
              values={ { autoJoin: topic.autoJoin } }
            />
          </span>
        </VerticallySpaced>
        <AudienceBuilder
          audience={ topic.audience }
          onChange={ whenAudienceChanged }
          multipleSegments
        />
        { !!audienceErrors.length && (
          <ValidationErrorMessage>
            <FormattedMessage
              id="topicEditForm.audienceValidationError"
              description="Message displayed when there is a validation error in the topics audience."
              defaultMessage="There is a problem with your audience"
            />
          </ValidationErrorMessage>
        ) }
        <Divider/>
        { renderAudienceMembers(cleanAudience) }
        <Flex>
          <FlexPullRight>
            <Button
              type="submit"
              variant="primary"
              busy={ submitting }
            >
              <FormattedMessage
                id="topicEditForm.save"
                description="Label for save button in topic edit form."
                defaultMessage="Save"
              />
            </Button>
          </FlexPullRight>
        </Flex>
      </VerticallySpaced>
    </form>
  );
};
