import styled from '@emotion/styled';
import { rgba } from 'polished';
import { ButtonBase } from '@mui/material';

import templatesGraphicUrl from '../../../Assets/img/templates/templates-graphic.svg?url';
import ChevronIcon from '../../../Assets/img/icons/monochrome/chevron.svg';

export const StyledHomePanels = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${ props => props.theme.spacing(4) };
  padding: ${ props => props.theme.spacing(2) } 0;

  ${ props => props.theme.breakpoints.down('lg') } {
    gap: ${ props => props.theme.spacing(2) };
  }

  ${ props => props.theme.breakpoints.down('md') } {
    grid-template-columns: 1fr;
  }

  ${ props => props.theme.breakpoints.down('sm') } {
    gap: ${ props => props.theme.spacing(1) };
    padding: ${ props => props.theme.spacing(1) } 0;
  }
`;

export const StyledTemplates = styled.div`
  width: 100%;
  height: 250px;
  background: url(${ templatesGraphicUrl }) no-repeat bottom;
  background-size: contain;
  background-color: ${ props => props.theme.palette.navy.main };
  position: relative;
  padding: ${ props => props.theme.spacing(2) };
  color: ${ props => props.theme.palette.navy.contrastText };

  h3 {
    font-weight: 400;
  }
`;

export const StyledQuickActions = styled.div`
  width: 100%;
  min-height: 250px;
  padding: ${ props => props.theme.spacing(2) };
  display: flex;
  flex-direction: column;

  h3 {
    font-weight: 400;
    margin: 0;
  }

  > div {
    display: flex;
    margin: auto 0;
    gap: ${ props => props.theme.spacing(2) };

    ${ props => props.theme.breakpoints.down('sm') } {
      flex-wrap: wrap;
    }
  }
`;

export const StyledTemplatesLink = styled.div`
  position: absolute;
  padding: ${ props => props.theme.spacing(2) };
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: ${ props => rgba(props.theme.palette.navy.contrastText, 0.8) };
  display: flex;
  gap: ${ props => props.theme.spacing(2) };

  button {
    margin-left: auto;
    display: flex;
    gap: ${ props => props.theme.spacing(1) };
    color: ${ props => props.theme.palette.primary.main };
    align-items: center;
    background: transparent;
    border: none;
  }
`;

export const StyledArrow = styled(ChevronIcon)`
  transform: rotate(-90deg);
`;

export const StyledQuickAction = styled(ButtonBase)`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  gap: ${ props => props.theme.spacing(2) };
  flex-grow: 1;
  text-align: center;
  color: ${ props => props.theme.palette.primary.main };
`;
