import styled from '@emotion/styled';
import { ListItem } from '@mui/material';

export const StyledListItem = styled(ListItem)`
  border-radius: 0.35rem;
  margin-bottom: 0.5rem;

  .MuiListItemIcon-root {
    min-width: initial;
  }
`;
