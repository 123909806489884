import { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ContentPreviewProps } from '../../../../Content/Model';
import { ChoiceEditorContent } from '../../../Service';
import { ContentWithType, ScrollableContent } from '..';
import { StyledCheckbox, StyledChoiceRequestOption, StyledChoiceRequestOptions, StyledHelpText } from './style';
import { FlexPullRight, VerticallySpaced } from '../../../../Common/Component';
import { CardTitle } from '..';

export const ChoiceRequestPreview: FC<ContentPreviewProps<ChoiceEditorContent>> = ({
  editorContent,
  onReady,
}) => {
  const intl = useIntl();
  const [checked, setChecked] = useState<string[]>([]);
  const multiple = editorContent.card.content.type === 'multiplechoicequestion';

  useEffect(() => {
    onReady();
  }, [onReady]);

  const whenChange = (id: string) => {
    multiple
      ? setChecked(checked => (
        checked.includes(id)
          ? checked.filter(checkedId => checkedId !== id)
          : checked.concat(id)
      ))
      : setChecked([id]);
  };

  useEffect(() => {
    if (multiple) {
      return;
    }

    setChecked(checked => checked.slice(0, 1));
  }, [multiple]);

  return (
    <ScrollableContent>
      <ContentWithType
        localisedContentType={ intl.formatMessage({
          id: 'broadcasts.content.preview.choice',
          description: 'Label for choice request type used in preview.',
          defaultMessage: 'Choice',
        }) }
      >
        <VerticallySpaced gap={ 2 }>
          <CardTitle>{ editorContent.card.content.title }</CardTitle>
          <StyledHelpText>
            {
              multiple
                ? (
                  <FormattedMessage
                    id="broadcasts.content.preview.multiple"
                    description="Help text when user may select more than one option in a choice card."
                    defaultMessage="You can choose more than one."
                  />
                )
                : (
                  <FormattedMessage
                    id="broadcasts.content.preview.single"
                    description="Help text when user may only select one option in a choice card."
                    defaultMessage="You can only choose one."
                  />
                )
            }
          </StyledHelpText>
          <StyledChoiceRequestOptions>
            { editorContent.card.content.options.map((option) => (
              <StyledChoiceRequestOption
                key={ option.id }
              >
                <label
                  htmlFor={ option.id }
                >
                  { option.text }
                  <FlexPullRight>
                    <StyledCheckbox
                      $checked={ checked.includes(option.id) }
                    >
                      <div/>
                    </StyledCheckbox>
                  </FlexPullRight>
                  <input
                    id={ option.id }
                    type={ multiple ? 'checkbox' : 'radio' }
                    checked={ checked.includes(option.id) }
                    onChange={ () => whenChange(option.id) }
                  />
                </label>
              </StyledChoiceRequestOption>
            )) }
          </StyledChoiceRequestOptions>
        </VerticallySpaced>
      </ContentWithType>
    </ScrollableContent>
  );
};
