import { FC } from 'react';
import { Redirect, Switch, useRouteMatch } from 'react-router-dom';

import { useEnvironmentSettings } from '../../../../Common/Hook';
import { GuardedRoute } from '../../../../Common/Component';
import { TopicCreatePage, TopicsListPage } from '../../index';
import { ChatsPermission } from '../../../Model';
import { TopicEditPage } from '../TopicEditPage/TopicEditPage';

export const ChatsRouter: FC = () => {
  const match = useRouteMatch();
  const { streamChatEnabled = false } = useEnvironmentSettings();

  return (
    <>
      {
        streamChatEnabled
          ? (
            <Switch>
              <GuardedRoute
                path={ `${ match.path }/topics` }
                exact
                permissions={ [ ChatsPermission.TOPICS_CREATE ] }
                redirect="/"
              >
                <TopicsListPage/>
              </GuardedRoute>
              <GuardedRoute
                path={ `${ match.path }/topics/create` }
                permissions={ [ ChatsPermission.TOPICS_CREATE ] }
                redirect="/"
              >
                <TopicCreatePage/>
              </GuardedRoute>
              <GuardedRoute
                path={ `${ match.path }/topics/:topicId/edit` }
                permissions={ [ ChatsPermission.TOPICS_UPDATE ] }
                redirect="/"
              >
                <TopicEditPage/>
              </GuardedRoute>
              <Redirect
                to={ `${ match.path }/topics/:topicId/edit` }
                from={ `${ match.path }/topics/:topicId` }
                exact
              />
              <Redirect
                to={ `${ match.path }/topics` }
                from={ match.path }
                exact
              />
            </Switch>
          )
          : <Redirect to="/"/>
      }
    </>
  );
};
