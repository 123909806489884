import { FC, ReactNode } from 'react';
import { PaletteKey } from 'op-storybook/lib/types/Theme/Theme';

import { StyledAdornment, StyledBadge } from './style';
import { Typography } from '../Typography/Typography';

export type BadgeVariant = 'pill-outline' | 'pill-colour' | 'badge-colour' | 'badge-modern';

type Props = {
  variant: BadgeVariant;
  colour: PaletteKey;
  label?: ReactNode;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
};

export const Badge: FC<Props> = ({
  variant = 'badge-colour',
  colour = 'grey',
  label,
  startAdornment,
  endAdornment,
}) => {

  return (
    <StyledBadge
      variant={ variant }
      colour={ colour }
      noLabel={ !label }
    >
      { startAdornment && (
        <StyledAdornment>
          { startAdornment }
        </StyledAdornment>
      ) }
      { label && (
        <Typography
          size="xs"
          weight="medium"
          color="inherit"
        >
          { label }
        </Typography>
      ) }
      { endAdornment && (
        <StyledAdornment>
          { endAdornment }
        </StyledAdornment>
      ) }
    </StyledBadge>
  );
};
