import { FC, useCallback, useRef, useState } from 'react';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { PlaceholderNode, } from '@ourpeople/shared/Core/Component/Input/RichTextEditor/Plugins/PlaceholderNodePlugin';
import { ListItemNode, ListNode } from '@lexical/list';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { OnChangePlugin } from '@ourpeople/shared/Core/Component/Input/RichTextEditor/Plugins/OnChangePlugin';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import {
  ValueReconciliationPlugin
} from '@ourpeople/shared/Core/Component/Input/RichTextEditor/Plugins/ValueReconciliationPlugin';
import { HeadingNode } from '@lexical/rich-text';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { BaseSelection, LexicalEditor, RangeSelection, TextNode } from 'lexical';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { OnFocusPlugin } from '@ourpeople/shared/Core/Component/Input/RichTextEditor/Plugins/OnFocusPlugin';
import { OnBlurPlugin } from '@ourpeople/shared/Core/Component/Input/RichTextEditor/Plugins/OnBlurPlugin';
import {
  OnSelectionChangePlugin
} from '@ourpeople/shared/Core/Component/Input/RichTextEditor/Plugins/OnSelectionChangePlugin';
import {
  OnEmptyBackspacePlugin
} from '@ourpeople/shared/Core/Component/Input/RichTextEditor/Plugins/OnEmptyBackspacePlugin';
import { OnPastePlugin } from '@ourpeople/shared/Core/Component/Input/RichTextEditor/Plugins/OnPastePlugin';
import he from 'he';

import { EditorNodeInserter, RichTextParser } from '../../../../../Common/Utility';
import { FloatingToolbarPlugin } from './FloatingToolbarPlugin';

type Props = {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  autofocus?: boolean;
  onDelete: () => void;
};

export const TextBlockInput: FC<Props> = ({
  value,
  onChange,
  placeholder = undefined,
  autofocus = false,
  onDelete,
}) => {
  const [focused, setFocused] = useState<boolean>(false);
  const toolbarRef = useRef<HTMLDivElement>(null);
  const editorValueRef = useRef<string>();
  const [selection, setSelection] = useState<BaseSelection | null>(null);
  const [initialConfig] = useState({
    namespace: '',
    onError: () => null,
    nodes: [
      PlaceholderNode,
      HeadingNode,
      ListNode,
      ListItemNode,
      {
        replace: HeadingNode,
        with: (node: HeadingNode) => {
          const clonedNode = HeadingNode.clone(node);
          clonedNode.__tag = 'h3';
          return clonedNode;
        },
      },
      {
        replace: TextNode,
        with: (node: TextNode) => {
          return node.getParent()
            ? node
            : new TextNode(node.getTextContent());
        },
      },
    ],
    theme: editorTheme,
    editorState: (editor: LexicalEditor) => {
      const dom = RichTextParser.getDocumentFromString(value || EMPTY_EDITOR_HTML_STRING);
      EditorNodeInserter.insertNodesFromDocument(editor, dom);
    },
  });

  const whenEditorValueChanged = useCallback(
    (newValue: string) => {
      editorValueRef.current = newValue;
      onChange(newValue);
    },
    [onChange, editorValueRef],
  );

  const whenEditorValuePasted = useCallback(
    (pastedValue: string): string => he.decode(pastedValue),
    [],
  );

  const whenBlurred = useCallback((event: FocusEvent) => {
    if (event.relatedTarget && toolbarRef?.current?.contains(event.relatedTarget as HTMLElement)) {
      event.preventDefault();
      return;
    }

    setFocused(false);
  }, [toolbarRef]);

  const whenFocused = useCallback(() => {
    setFocused(true);
  }, []);

  return (
    <div
      css={ { position: 'relative' } }
    >
      <LexicalComposer
        initialConfig={ initialConfig }
      >
        <FloatingToolbarPlugin
          open={ focused && !!selection && (selection as RangeSelection)?.anchor.offset !== (selection as RangeSelection)?.focus.offset }
          innerRef={ toolbarRef }
        />
        <OnBlurPlugin
          onBlur={ whenBlurred }
        />
        <OnFocusPlugin
          onFocus={ whenFocused }
        />
        <OnEmptyBackspacePlugin
          onBackspace={ onDelete }
        />
        <OnPastePlugin onPaste={whenEditorValuePasted} />
        <RichTextPlugin
          contentEditable={
            <ContentEditable
              css={ theme => ({
                outline: 'none',
                'p, h3, ul, li': {
                  marginBottom: theme.new.spacing[1],
                },

                'h3': {
                  fontSize: '18px',
                  fontWeight: 600,
                },

                'p': {
                  lineHeight: '1',
                  fontSize: '16px',
                },

                'emp, .editor-text-italic': {
                  fontStyle: 'italic',
                },

                'strong, .editor-text-bold': {
                  fontWeight: 500,
                },
              }) }
            />
          }
          placeholder={ (
            <div
              css={ theme => ({
                position: 'absolute',
                top: 0,
                left: 0,
                color: theme.new.palette.grey[400].main,
                zIndex: -1,
                pointerEvents: 'none',
                fontWeight: 300,
              }) }
            >
              { placeholder }
            </div>
          ) }
          ErrorBoundary={ LexicalErrorBoundary }
        />
        <OnSelectionChangePlugin
          onSelectionChange={ setSelection }
        />
        <OnChangePlugin
          value={ value }
          onChange={ whenEditorValueChanged }
          emptyValue={ EMPTY_EDITOR_HTML_STRING }
          mode="richText"
        />
        <HistoryPlugin/>
        { autofocus && <AutoFocusPlugin/> }
        <ValueReconciliationPlugin
          enabled={ editorValueRef.current !== value }
          value={ value }
          emptyValue={ EMPTY_EDITOR_HTML_STRING }
          mode="richText"
        />
        <ListPlugin/>
      </LexicalComposer>
    </div>
  );
};

const editorTheme = {
  text: {
    bold: 'editor-text-bold',
    italic: 'editor-text-italic',
  },
};

const EMPTY_EDITOR_HTML_STRING = '<p><br></p>';
