import { default as React, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { ContentEditorProps } from '../../../../Content/Model';
import { MediaEditorContent } from '../../../Model';
import { Upload } from '../../../../Types';
import { useInputChangeEventHandler } from '../../../../Common/Hook';
import { MEDIA_TEXT_MAX_LENGTH, MEDIA_TEXT_MIN_LENGTH, MediaContentValidator } from '../../../Service';
import { FieldValidationErrors, NoMarginTextField, VerticallySpaced } from '../../../../Common/Component';
import { FileUploadInput } from '../../../../Files/Component';
import { CharacterCount } from '../../../../Content/Component';

type Props<T extends string> = ContentEditorProps<MediaEditorContent<T>> & {
  mimeType: string;
};

export const MediaContentEditor = <T extends string>({
  mimeType,
  editorContent,
  onEditorContentChanged,
  validation,
  onValidationChanged,
}: Props<T>): JSX.Element => {
  const intl = useIntl();

  const whenFileUploaded = useCallback((upload: Upload) => {
    onEditorContentChanged({
      ...editorContent,
      media: upload,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          media: upload.id.toString(),
        },
      },
    });
    onValidationChanged({
      errors: [],
      children: {
        content: {
          errors: [],
          children: {
            ...validation?.children.content?.children,
            media: MediaContentValidator.validateMedia(upload.id.toString()),
          },
        },
      },
    })
  }, [editorContent, onEditorContentChanged, onValidationChanged, validation?.children.content?.children]);

  const whenFileRemoved = useCallback(() => (
    onEditorContentChanged({
      ...editorContent,
      media: null,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          media: '',
        },
      },
    })
  ), [editorContent, onEditorContentChanged]);

  const whenTextChanged = useInputChangeEventHandler(useCallback(text => (
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          text,
        },
      },
    })
  ), [editorContent, onEditorContentChanged]));

  const whenTextBlurred = useCallback(() => {
    onValidationChanged({
      errors: [],
      children: {
        content: {
          errors: [],
          children: {
            ...validation?.children.content?.children,
            text: MediaContentValidator.validateText(editorContent.card.content.text),
          },
        },
      },
    })
  }, [editorContent.card.content.text, onValidationChanged, validation?.children.content?.children]);

  const textFieldLabel = intl.formatMessage({
    id: 'broadcasts.content.file.fields.text.label',
    description: 'Label for text field of broadcasts file content editor.',
    defaultMessage: 'Title',
  });

  const fileUploadLabel = intl.formatMessage({
    id: 'broadcasts.content.file.fields.upload.label',
    description: 'Label for upload field of broadcasts file content editor.',
    defaultMessage: 'File',
  });

  return (
    <>
      <VerticallySpaced gap={ 1 }>
        <h4>{ fileUploadLabel }</h4>
        <FileUploadInput
          id={ editorContent.id }
          mimeType={ mimeType }
          fileUpload={ editorContent.media || undefined }
          onFileUploaded={ whenFileUploaded }
          onFileRemoved={ whenFileRemoved }
        />
        <FieldValidationErrors
          fieldName={ fileUploadLabel }
          validationErrors={ validation?.children.content?.children.media?.errors || [] }
        />
      </VerticallySpaced>
      <VerticallySpaced gap={ 1 }>
        <h4>{ textFieldLabel }</h4>
        <NoMarginTextField
          fullWidth
          value={ editorContent.card.content.text }
          onChange={ whenTextChanged }
          onBlur={ whenTextBlurred }
        />
        <CharacterCount
          current={ editorContent.card.content.text.length || 0 }
          minimum={ MEDIA_TEXT_MIN_LENGTH }
          maximum={ MEDIA_TEXT_MAX_LENGTH }
        />
        <FieldValidationErrors
          fieldName={ textFieldLabel }
          validationErrors={ validation?.children.content?.children.text?.errors || [] }
        />
      </VerticallySpaced>
    </>
  );
}
