import { FunctionComponent } from 'react';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { FormattedMessage } from 'react-intl';

type Props = {
  open: boolean;
  onDismiss: () => void;
}

export const PreviewDialog: FunctionComponent<Props> = ({
  children,
  open,
  onDismiss,
}) => {
  return (
    <Dialog
      open={ open }
      onClose={ onDismiss }
    >
      <DialogContent>
        { children }
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={onDismiss}
        >
          <FormattedMessage
            id="people.invitations.previewDialog"
            defaultMessage="Close"
          />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
