export const applyV2GlobalStyles = () => {
  const globalStyle = document.createElement('style');
  globalStyle.innerText = `
    a { color: inherit }
  `;

  document.head.appendChild(globalStyle);

  const nonPrototypeThemeColor = document.head.querySelector('[name=theme-color]');
  nonPrototypeThemeColor && document.head.removeChild(nonPrototypeThemeColor);

  return () => {
    document.head.removeChild(globalStyle);
    nonPrototypeThemeColor && document.head.appendChild(nonPrototypeThemeColor);
  };
};
