import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FeaturedIcon } from 'op-storybook/stories/components/FeaturedIcon/FeaturedIcon';
import MessageIcon from '@ourpeople/shared/Asset/Icon/figma/send-01.svg';
import { Typography } from '@ourpeople/shared/Core/Component/Content/Typography/Typography';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';

import { AnonymousSettings } from '../../Hook/useFetchAnonymousSettings';
import { RegistrationConfirmationTemplate } from '../RegistrationConfirmationTemplate/RegistrationConfirmationTemplate';

type Props = {
  anonymousSettings: AnonymousSettings;
  phoneNumber: string;
  emailAddress: string;
};

export const SmsAndEmailRegistrationConfirmation: FC<Props> = ({
  anonymousSettings,
  phoneNumber,
  emailAddress,
}) => {
  const intl = useIntl();

  return (
    <RegistrationConfirmationTemplate
      anonymousSettings={ anonymousSettings }
      title={ intl.formatMessage({
        description: 'Title for registration confirmation page when approval is automatic and phone number was provided',
        defaultMessage: 'Check your devices',
      }) }
      icon={
        <FeaturedIcon
          colour="grey"
          size="xl"
          variant="modern"
          IconComponent={ MessageIcon }
        />
      }
    >
      <Typography align="center">
        <FormattedMessage
          description="Registration confirmation page body when approval is automatic and phone number and email address were both provided."
          defaultMessage="We sent a welcome invite with everything you need to get started to the following:"
        />
      </Typography>
      <Stack direction="column">
        <Typography>
          <FormattedMessage
            description="Line confirming email address on registration confirmation page when approval is automatic and phone number and email address were both provided."
            defaultMessage="Email: <strong>{ emailAddress }</strong>"
            values={ { emailAddress } }
          />
        </Typography>
        <Typography>
          <FormattedMessage
            description="Line confirming phone number on registration confirmation page when approval is automatic and phone number and email address were both provided."
            defaultMessage="Text message: <strong>{ phoneNumber }</strong>"
            values={ { phoneNumber } }
          />
        </Typography>
      </Stack>
    </RegistrationConfirmationTemplate>
  );
};
