import styled from '@emotion/styled';

type StyledStepContainerProps = {
  active?: boolean;
  error?: boolean;
}

export const StyledStepContainer = styled.div<StyledStepContainerProps>`
  &,
  a {
    ${ props => `
      color: ${
        props.error
          ? props.theme.palette.error.main
          : props.active
            ? props.theme.palette.secondary.main
            : props.theme.palette.grey[500]
      };
    ` }
  }
`;
