import {FC} from 'react';

import DownArrowIcon from '../../assets/icon/figma/chevron-down.svg?react';
import { PresentationIcon } from '../PresentationIcon/PresentationIcon';

export const DropdownItemChevron: FC = () => {
  return (
    <PresentationIcon
      IconComponent={ DownArrowIcon }
      size={ 5 }
      css={ {
        right: 0,
        transition: 'transform 0.1s',
        transform: 'rotate(-90deg)',
      } }
    />
  );
};
