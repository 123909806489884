import { default as React, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { addDays, differenceInMinutes, format, formatDuration, isBefore, parse, parseISO } from 'date-fns';

import { FieldValidationErrors, NoMarginTextField, Notice, VerticallySpaced } from '../../../../Common/Component';
import { CharacterCount } from '../../../../Content/Component';
import { useInputChangeEventHandler } from '../../../../Common/Hook';
import { DraftSingleContentCard, EditorContent } from '../../../../Content/Model';
import { ValidationTree } from '../../../../Common/Model';
import { DraftEvent } from '../../../Model';
import { EventValidator } from '../../../Service';

type Props<D extends DraftEvent, E extends EditorContent<D>> = {
  editorContent: E;
  onEditorContentChanged: (editorContent: E) => void;
  validation?: ValidationTree<DraftSingleContentCard<D>>;
  onValidationChanged: (validation: ValidationTree<DraftSingleContentCard<D>>) => void;
  minTitleLength: number;
  maxTitleLength: number;
};

export const SharedEventFields = <D extends DraftEvent, E extends EditorContent<D>>({
  editorContent,
  onEditorContentChanged,
  validation,
  onValidationChanged,
  minTitleLength,
  maxTitleLength,
}: Props<D, E>): JSX.Element => {
  const intl = useIntl();
  const textFieldLabel = intl.formatMessage({
    id: 'broadcasts.content.cover.fields.text.label',
    description: 'Label for text field of broadcasts cover request editor.',
    defaultMessage: 'Title',
  });
  const startDateFieldLabel = intl.formatMessage({
    id: 'broadcasts.content.cover.fields.startAt.label',
    description: 'Label for startAt field of broadcasts cover request editor.',
    defaultMessage: 'Start at',
  });
  const endDateFieldLabel = intl.formatMessage({
    id: 'broadcasts.content.cover.fields.endAt.label',
    description: 'Label for endAt field of broadcasts cover request editor.',
    defaultMessage: 'End at',
  });

  const startDateString = format(parseISO(editorContent.card.content.start_at.dt), 'yyyy-MM-dd\'T\'HH:mm');
  const endTimeString = format(parseISO(editorContent.card.content.end_at.dt), 'HH:mm');

  const whenTextChanged = useInputChangeEventHandler(useCallback(text => (
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          text,
        },
      },
    })
  ), [editorContent, onEditorContentChanged]));

  const whenTextBlurred = useCallback(() => {
    onValidationChanged({
      errors: [],
      children: {
        content: {
          errors: [],
          children: {
            ...validation?.children.content?.children,
            text: EventValidator.validateText(editorContent.card.content.text, minTitleLength, maxTitleLength),
          } as ValidationTree<D>['children'],
        },
      },
    })
  }, [
    editorContent.card.content.text,
    maxTitleLength,
    minTitleLength,
    onValidationChanged,
    validation?.children.content?.children
  ]);

  const whenStartDateChanged = useInputChangeEventHandler(useCallback(startDate => {
    const endDate = parse(
      `${ startDate.split('T')[0] }T${ endTimeString }`,
      'yyyy-MM-dd\'T\'HH:mm',
      new Date(),
    );
    const startDateTime = parse(startDate, 'yyyy-MM-dd\'T\'HH:mm', new Date());

    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          start_at: {
            dt: startDateTime.toISOString(),
            tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
          end_at: {
            dt: isBefore(startDateTime, endDate) ? endDate.toISOString() : addDays(endDate, 1).toISOString(),
            tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        },
      },
    })
  }, [editorContent, endTimeString, onEditorContentChanged]));

  const whenEndTimeChanged = useInputChangeEventHandler(useCallback(endTime => {
    const endDate = parse(
      `${ startDateString.split('T')[0] }T${ endTime }`,
      'yyyy-MM-dd\'T\'HH:mm',
      new Date(),
    );
    const startDateTime = parse(startDateString, 'yyyy-MM-dd\'T\'HH:mm', new Date());

    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          end_at: {
            dt: isBefore(startDateTime, endDate) ? endDate.toISOString() : addDays(endDate, 1).toISOString(),
            tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        },
      },
    });
  }, [editorContent, onEditorContentChanged, startDateString]));

  const durationInMinutes = differenceInMinutes(parseISO(editorContent.card.content.end_at.dt), parseISO(editorContent.card.content.start_at.dt));
  const localisedDuration = intl.formatList(
    formatDuration({
      hours: Math.floor(durationInMinutes / 60),
      minutes: durationInMinutes % 60,
    }, {
      delimiter: ','
    }).split(','),
  );

  const whenStartDateBlurred = useCallback(() => (
    onValidationChanged({
      errors: [],
      children: {
        content: {
          errors: [],
          children: {
            ...validation?.children.content?.children,
            start_at: EventValidator.validateStartDate(editorContent.card.content.start_at.dt),
          } as ValidationTree<D>['children'],
        },
      },
    })
  ), [editorContent.card.content.start_at.dt, onValidationChanged, validation?.children.content?.children]);

  const whenEndTimeBlurred = useCallback(() => (
    onValidationChanged({
      errors: [],
      children: {
        content: {
          errors: [],
          children: {
            ...validation?.children.content?.children,
            end_at: EventValidator.validateEndDate(editorContent.card.content.end_at.dt, editorContent.card.content.start_at.dt),
          } as ValidationTree<D>['children'],
        },
      },
    })
  ), [
    editorContent.card.content.end_at.dt,
    editorContent.card.content.start_at.dt,
    onValidationChanged,
    validation?.children.content?.children,
  ]);

  return (
    <>
      <VerticallySpaced gap={ 1 }>
        <h4>{ textFieldLabel }</h4>
        <NoMarginTextField
          fullWidth
          value={ editorContent.card.content.text }
          onChange={ whenTextChanged }
          multiline
          onBlur={ whenTextBlurred }
        />
        <CharacterCount
          current={ editorContent.card.content.text.length || 0 }
          minimum={ minTitleLength }
          maximum={ maxTitleLength }
        />
        <FieldValidationErrors
          fieldName={ textFieldLabel }
          validationErrors={ validation?.children.content?.children.text?.errors || [] }
        />
      </VerticallySpaced>
      <VerticallySpaced gap={ 1 }>
        <h4>{ startDateFieldLabel }</h4>
        <NoMarginTextField
          type="datetime-local"
          required
          value={ startDateString }
          onChange={ whenStartDateChanged }
          onBlur={ whenStartDateBlurred }
        />
        <FieldValidationErrors
          fieldName={ startDateFieldLabel }
          validationErrors={ validation?.children.content?.children.start_at?.errors || [] }
        />
      </VerticallySpaced>
      <VerticallySpaced gap={ 1 }>
        <h4>{ endDateFieldLabel }</h4>
        <NoMarginTextField
          type="time"
          required
          value={ endTimeString }
          onChange={ whenEndTimeChanged }
          onBlur={ whenEndTimeBlurred }
        />
        <FieldValidationErrors
          fieldName={ endDateFieldLabel }
          validationErrors={ validation?.children.content?.children.end_at?.errors || [] }
        />
      </VerticallySpaced>
      <Notice
        variant="outlined"
        feedback={ {
          message: intl.formatMessage({
            id: 'broadcasts.content.cover.duration',
            description: 'Notice with event duration',
            defaultMessage: 'This event will last { duration }.'
          }, {
            duration: localisedDuration,
          }),
          severity: 'info',
        } }
      />
    </>
  );
};
