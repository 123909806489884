import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const StyledListItem = styled.li`
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: flex-start;
  gap: ${ props => props.theme.spacing(2) };
  border-bottom: 1px solid ${ props => props.theme.palette.grey[200] };
  padding: ${ props => props.theme.spacing(1) } 0 ${ props => props.theme.spacing(2) };
`;

export const StyledRemoveButtonContainer = styled.div`
  margin: ${ props => props.theme.spacing(-1) };
`;

export const StyledFileNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const StyledFileName = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
`;

export const StyledProgress = styled.span`
  font-size: ${ props => props.theme.fontSize.md };
  color: ${ props => props.theme.palette.secondary.main };
`;

export const StyledLink = styled(Link)`
  font-size: ${ props => props.theme.fontSize.md };
  color: ${ props => props.theme.palette.secondary.main };
`;
