import { NonNullableObject } from '../../New/Core/Type/NonNullableObject';

export class ObjectHelper {
  public static clean<
    T extends Record<string, unknown>,
    R = NonNullableObject<T>
  >(obj: T): R {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, value]) => value !== undefined && value !== null),
    ) as R;
  }

  public static mapKeys<T extends Record<string, unknown>>(obj: T, mapFn: (key: string) => string) {
    return Object.fromEntries(
      Array.from(Object.entries(obj))
        .map(([key, value]) => [mapFn(key), value])
    );
  }

  public static filterKeys<T extends Record<string, unknown>>(obj: T, predicate: (key: string) => boolean): Partial<T> {
    return Object.fromEntries(
      Array.from(Object.entries(obj))
        .filter(([key]) => predicate(key))
    ) as Partial<T>
  }
}
