import styled from '@emotion/styled';
import { Checkbox, FormControlLabel } from '@mui/material';

export const StyledDescriptionContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.grey[100]};
  padding: 1rem;
  border-radius: 0.5rem;
  width: 50%;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    width: 75%;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: auto;
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  &.Mui-disabled {
    &.Mui-checked {
      color: ${({ theme }) => theme.palette.primary.main};
    }
    padding-bottom: 3px;
    padding-top: 3px;
  }
`;

export const StyledCheckboxLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label.Mui-disabled {
    color: ${({ theme }) => theme.palette.text.disabled};
    font-size: 0.9rem;
  }
  .Mui-checked + .MuiFormControlLabel-label.Mui-disabled {
    color: ${({ theme }) => theme.palette.text.primary};
  }
  margin-bottom: 0;
`;
