import { useCallback, useMemo } from 'react';

import { VideoUpload } from './VideoUpload';
import { InlineFormComponent } from '../../../Model/BroadcastBlockDefinition';
import { VideoBlock } from '../../../Service/Blocks/VideoBlockDefinition';
import { useContextOrThrow } from '../../../../../Core/Hook';
import { FileUploaderContext } from '../../../../../Common/Context';
import { TranscodingProgressContext } from '../../../../Common/Provider/TranscodingProcessProvider';
import { VideoPlaceholder } from './VideoPlaceholder';
import { VideoProgressBar } from './VideoProgressBar';

export const VideoBlockInlineForm: InlineFormComponent<VideoBlock> = ({
  block,
  attachments,
  activateBlock,
}) => {
  const { uploadStates } = useContextOrThrow(FileUploaderContext);
  const uploadState = useMemo(() => {
    return uploadStates.get(block.localId);
  }, [block.localId, uploadStates]);
  const upload = uploadState?.upload ?? attachments.uploads.find(upload => upload.uuid === block.attributes.uploadId);
  const { getStatus } = useContextOrThrow(TranscodingProgressContext);
  const status = useMemo(() => upload ? getStatus([String(upload.id)]) : 'complete', [getStatus, upload]);
  const progress = (uploadState?.progress || 0);
  const uploadInProgress = useMemo(() => !!uploadState && (!uploadState.upload || status !== 'complete'), [uploadState, status]);

  const containerRef = useCallback((node: HTMLDivElement) => {
    if (!node || !upload?.video) {
      return;
    }

    const aspectRatio = upload.video.width && upload.video.height
      ? upload.video.width / upload.video.height
      : undefined;

    node.style.height = aspectRatio
      ? `${ parseInt(window.getComputedStyle(node).width) / aspectRatio }px`
      : 'auto';
  }, [upload?.video]);

  return (
    <div
      ref={ containerRef }
      css={ theme => ({
        borderRadius: theme.new.borderRadius.standard,
        position: 'relative',
        overflow: 'hidden',
      }) }
      onClick={ activateBlock }
    >
      {
        upload?.id
          ? (
            <div>
              <VideoUpload
                id={ String(upload.id) }
                videoThumbnailKey={ block.attributes.videoThumbnailKey || undefined }
                videoDimensions={ {
                  width: upload.video?.width || 1,
                  height: upload.video?.height || 1,
                } }
                readOnly
                originalMimeType={ upload.mimeType }
              />
            </div>
          )
          : <VideoPlaceholder loading={ false }/>
      }
      { uploadInProgress && (
        <div
          css={ theme => ({
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            padding: theme.new.spacing[4],
            display: 'flex',
          }) }
        >
          <div
            css={ {
              marginTop: 'auto',
              width: '100%',
            } }
          >
            <VideoProgressBar progress={ progress }/>
          </div>
        </div>
      ) }
    </div>
  );
};
