import { default as React, FC, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { ContentEditorProps } from '../../../../Content/Model';
import {
  COVER_COMMENT_MAX_LENGTH,
  COVER_COMMENT_MIN_LENGTH,
  COVER_TEXT_MAX_LENGTH,
  COVER_TEXT_MIN_LENGTH,
  CoverEditorContent,
  CoverValidator
} from '../../../Service';
import { useInputChangeEventHandler } from '../../../../Common/Hook';
import { FieldValidationErrors, NoMarginTextField, VerticallySpaced } from '../../../../Common/Component';
import { CharacterCount } from '../../../../Content/Component';
import { Tag } from '../../../../Tags/Model';
import { LocationInput, SharedEventFields } from '..';

export const CoverEditor: FC<ContentEditorProps<CoverEditorContent>> = ({
  editorContent,
  onEditorContentChanged,
  validation,
  onValidationChanged,
}) => {
  const intl = useIntl();
  const commentFieldLabel = intl.formatMessage({
    id: 'broadcasts.content.cover.fields.comment.label',
    description: 'Label for comment field of broadcasts cover request editor.',
    defaultMessage: 'Comment',
  });
  const locationFieldLabel = intl.formatMessage({
    id: 'broadcasts.content.cover.fields.location.label',
    description: 'Label for location field of broadcasts cover request editor.',
    defaultMessage: 'Location',
  });

  const whenCommentChanged = useInputChangeEventHandler(useCallback(comment => (
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          comment,
        },
      },
    })
  ), [editorContent, onEditorContentChanged]));

  const whenCommentBlurred = useCallback(() => (
    onValidationChanged({
      errors: [],
      children: {
        content: {
          errors: [],
          children: {
            ...validation?.children.content?.children,
            comment: CoverValidator.validateComment(editorContent.card.content.comment || ''),
          },
        },
      },
    })
  ), [editorContent.card.content.comment, onValidationChanged, validation?.children.content?.children]);

  const whenLocationChanged = useCallback((location: Tag<'team'> | null) => (
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          location: location?.name || '',
        },
      },
      location,
    })
  ), [editorContent, onEditorContentChanged]);

  const whenLocationBlurred = useCallback(() => (
    onValidationChanged({
      errors: [],
      children: {
        content: {
          errors: [],
          children: {
            ...validation?.children.content?.children,
            location: CoverValidator.validateLocation(editorContent.card.content.location),
          },
        },
      },
    })
  ), [editorContent.card.content.location, onValidationChanged, validation?.children.content?.children]);

  return (
    <VerticallySpaced gap={ 2 }>
      <SharedEventFields
        editorContent={ editorContent }
        onEditorContentChanged={ onEditorContentChanged }
        validation={ validation }
        onValidationChanged={ onValidationChanged }
        minTitleLength={ COVER_TEXT_MIN_LENGTH }
        maxTitleLength={ COVER_TEXT_MAX_LENGTH }
      />
      <VerticallySpaced gap={ 1 }>
        <h4>{ locationFieldLabel }</h4>
        <LocationInput
          locationName={ editorContent.card.content.location }
          location={ editorContent.location }
          onChange={ whenLocationChanged }
          onBlur={ whenLocationBlurred }
        />
        <FieldValidationErrors
          fieldName={ locationFieldLabel }
          validationErrors={ validation?.children.content?.children.location?.errors || [] }
        />
      </VerticallySpaced>
      <VerticallySpaced gap={ 1 }>
        <h4>{ commentFieldLabel }</h4>
        <NoMarginTextField
          fullWidth
          value={ editorContent.card.content.comment }
          onChange={ whenCommentChanged }
          multiline
          onBlur={ whenCommentBlurred }
        />
        <CharacterCount
          current={ editorContent.card.content.comment?.length || 0 }
          minimum={ COVER_COMMENT_MIN_LENGTH }
          maximum={ COVER_COMMENT_MAX_LENGTH }
          allowBlank
        />
        <FieldValidationErrors
          fieldName={ commentFieldLabel }
          validationErrors={ validation?.children.content?.children.comment?.errors || [] }
        />
      </VerticallySpaced>
    </VerticallySpaced>
  );
};
