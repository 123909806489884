import {FunctionComponent, PropsWithChildren, useMemo, useState} from 'react';
import * as React from 'react';

interface Props {
  tabs: React.ReactNode;
}

export const DeliveryReportTabContainer: FunctionComponent<PropsWithChildren<Props>> = ({
  children,
  tabs,
}) => {
  const [activeTabIndex, setActiveTabIndex] = useState<number | null>(null);

  const newTabs = useMemo(() => {
    if (!React.isValidElement(tabs)) {
      return null;
    }

    return React.cloneElement(tabs, {
      activeTabIndex,
      onTabClicked: (index: number) => {
        setActiveTabIndex(activeTabIndex => index === activeTabIndex ? null : index);
      }
    });
  }, [activeTabIndex, tabs]);

  const newChildren = useMemo(() => {
    return React.Children.map(children, (panel, index) => {
      if (!React.isValidElement(panel)) {
        return null;
      }

      return React.cloneElement(panel, {
        active: activeTabIndex === index,
      });
    });
  }, [activeTabIndex, children]);

  return (
    <>
      { newTabs }
      { newChildren }
    </>
  );
};
