import { TextareaAutosize, TextField } from '@mui/material';
import { ChangeEventHandler, FC, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { CharacterCount } from '../../../../Content/Component';
import { DraftTextRequest, TextRequest as TextRequestModel } from '../../../Model/ContentTypes';
import { ValidationTree } from '../../../../Common/Model';
import { useDescendantErrors } from '../../../../Common/Hook';
import { StyledTextRequest } from './style';
import { DraftTextResponse } from '../../../Service';
import { FormResponseErrors } from '../..';
import { useLocalisedResponseErrors } from '../../../Hook';

type Props = {
  content: DraftTextRequest | TextRequestModel;
  value: string;
};

type ReadOnlyProps = Props & {
  readOnly: true;
  onChange?: never;
  mandatory?: never;
  validation?: never;
  displayValidation?: false;
};

type EditableProps = Props & {
  readOnly?: false;
  onChange: (value: string) => void;
  validation?: ValidationTree<DraftTextResponse>;
  mandatory?: boolean;
  displayValidation?: boolean;
};

export const TextRequest: FC<ReadOnlyProps | EditableProps> = ({
  content,
  value,
  onChange,
  mandatory = false,
  validation,
  displayValidation,
  readOnly,
}) => {
  const errors = useDescendantErrors(validation);
  const localisedErrors = useLocalisedResponseErrors(errors);
  const intl = useIntl();
  const inputPlaceholder = useMemo(() => intl.formatMessage({
    description: 'Form text request placeholder.',
    defaultMessage: 'Type your answer here…',
  }), [intl]);

  const whenChanged: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = useCallback(event => {
    !readOnly && onChange(event.currentTarget.value);
  }, [onChange, readOnly]);

  return (
    <StyledTextRequest>
      {
        content.multiline
          ? (
            <TextareaAutosize
              placeholder={ inputPlaceholder }
              value={ value }
              onChange={ whenChanged }
              disabled={ readOnly }
            />
          )
          : (
            <TextField
              color="secondary"
              placeholder={ inputPlaceholder }
              value={ value }
              onChange={ whenChanged }
              fullWidth
              disabled={ readOnly }
              error={ displayValidation && !!errors.length }
            />
          )
      }
      { !readOnly && (
        <CharacterCount
          current={ value.length }
          minimum={ mandatory || value.length ? content.minLength : 0 }
          maximum={ content.maxLength }
        />
      ) }
      { displayValidation && <FormResponseErrors localisedErrors={ localisedErrors }/> }
    </StyledTextRequest>
  );
};
