import { FC } from 'react';

import { BaseIconProps, Icon } from '../Icon/Icon';

export const PresentationIcon: FC<BaseIconProps> = props => (
  <Icon
    { ...props }
    role="presentation"
  />
);
