import styled from '@emotion/styled';

import { SizeKey } from '../../types/Theme/Theme';

export const StyledCard = styled.div<{ elevation: SizeKey }>`
  border: ${ props => props.theme.new.border.light };
  box-shadow: ${ props => props.theme.new.shadow[props.elevation] };
  border-radius: ${ props => props.theme.new.borderRadius.large };
  overflow: hidden;
  background-color: ${ props => props.theme.new.basePalette.white.main };
  position: relative;
`;
