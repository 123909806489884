import { FC, useMemo } from 'react';
import { StyleBuilder } from 'op-storybook/lib/model/StyleBuilder/StyleBuilder';
import { SecondaryInformation } from 'op-storybook/stories/components/Typography/SecondaryInformation';

type Props = {
  errors: string[];
};

export const BroadcastEditorErrors: FC<Props> = ({
  errors,
}) => {
  const styles = useMemo(() => buildStyles(), []);

  return (
    <ul
      css={ styles.list }
    >
      { errors.map(error => (
        <li
          key={ error }
          css={ styles.listItem }
        >
          <SecondaryInformation palette="inherit">
            { error }
          </SecondaryInformation>
        </li>
      )) }
    </ul>
  );
};

const buildStyles: StyleBuilder = () => ({
  list: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
  },
  listItem: theme => ({
    color: theme.new.palette.error['600'].main,
  }),
});
