import { useCallback, useMemo, useState } from 'react';
import axios, { AxiosError } from 'axios';
import { ApiRequest } from '@ourpeople/shared/Core/Model/ApiRequest';

import { RequestState } from '../../Models';
import { useMounted } from '../../Common/Hook';
import { LoginErrorResponse } from './useLoginRequest';

type PasswordAuthRequest = ApiRequest<{ token: string }, LoginErrorResponse>;

type Response = {
  requestPasswordAuth: (details: PasswordAuthRequestDetails) => void;
  passwordAuthRequest: PasswordAuthRequest;
};

type BasePasswordAuthRequestDetails = {
  token: string;
}

type MobileNumberTokenRequestDetails = {
  mobileNumber: string;
  emailAddress?: never;
};

type EmailTokenRequestDetails = {
  mobileNumber?: never;
  emailAddress: string;
};

type PasswordAuthRequestDetails = BasePasswordAuthRequestDetails & (EmailTokenRequestDetails | MobileNumberTokenRequestDetails);

export const usePasswordAuthRequest = (): Response => {
  const [passwordAuthRequest, setPasswordAuthRequest] = useState<PasswordAuthRequest>({
    state: RequestState.PENDING,
    result: null,
  });
  const mounted = useMounted();

  const requestPasswordAuth = useCallback(
    (details: PasswordAuthRequestDetails) => {
      setPasswordAuthRequest({
        state: RequestState.FETCHING,
        result: null,
      });

      const requestMethod = details.mobileNumber
        ? 'MOBILE_NUMBER_AND_SECURITY_TOKEN'
        : 'EMAIL_ADDRESS_AND_SECURITY_TOKEN'

      const passwordAuthRequestDetailsWithMethod = {
        method: requestMethod,
        ...details,
      };

      axios.post<{ token: string }>(
        '/api/auth/restricted/password',
        passwordAuthRequestDetailsWithMethod,
      )
        .then(response => response.data)
        .then(result => {
          if (!mounted.current) {
            return;
          }

          setPasswordAuthRequest({
            state: RequestState.COMPLETE,
            result: result,
          });
        })
        .catch((error: AxiosError<unknown>) => {
          if (!mounted.current) {
            return;
          }

          let errorResponse: LoginErrorResponse;
          switch (error.response?.status || 0) {
            case 429:
              const headers = error.response?.headers as Record<string, string>;
              const retryAfterHeader = headers && headers['retry-after'];
              errorResponse = {
                error: 'RATE_LIMIT',
                retryAfter: !isNaN(+retryAfterHeader) ? +retryAfterHeader : 0,
              };
              break;
            case 404:
              if (requestMethod === 'MOBILE_NUMBER_AND_SECURITY_TOKEN') {
                errorResponse = {
                  error: 'PHONE_NUMBER_NOT_FOUND',
                };
              } else {
                errorResponse = {
                  error: 'EMAIL_ADDRESS_NOT_FOUND',
                };
              }
              break;
            default:
              errorResponse = {
                error: 'GENERIC',
              };
          }

          setPasswordAuthRequest({
            result: errorResponse,
            state: RequestState.FAILED,
          });
        });
    },
    [mounted],
  );

  return useMemo(() => ({
    requestPasswordAuth,
    passwordAuthRequest,
  }), [requestPasswordAuth, passwordAuthRequest]);
};
