import { FC, useCallback, useEffect, useState } from 'react';
import { ApiRequest } from '@ourpeople/shared/Core/Model/ApiRequest';
import { CenteredGenericErrorMessage, CenteredGenericLoadingMessage } from '@ourpeople/shared/Core/Component/Content';

import { ReachSegment } from '../ReachSegment/ReachSegment';
import { useContextOrThrow } from '../../../Core/Hook';
import { ApiContext } from '../../../Contexts';
import { Reach } from '../../Model';
import { useMounted } from '../../../Common/Hook';
import { RequestState } from '../../../Models';

export const HotReachSegment: FC = () => {
  const api = useContextOrThrow(ApiContext);
  const [reachRequest, setReachRequest] = useState<ApiRequest<Reach>>({
    result: null,
    state: RequestState.PENDING,
  });
  const mounted = useMounted();

  const fetchReach = useCallback(() => {
    setReachRequest({
      result: null,
      state: RequestState.PENDING,
    });

    api.get<Reach>('/me/audiences/reach')
      .then(response => response.data)
      .then(result => {
        if (!mounted.current) {
          return;
        }

        setReachRequest({
          result,
          state: RequestState.COMPLETE,
        });
      })
      .catch(error => {
        if (!mounted.current) {
          return;
        }

        setReachRequest({
          result: error,
          state: RequestState.FAILED,
        });
      });
  }, [api, mounted]);

  useEffect(() => {
    fetchReach();
  }, [fetchReach]);

  return (
    reachRequest.state === RequestState.COMPLETE
      ? <ReachSegment segment={ reachRequest.result }/>
      : reachRequest.state === RequestState.FAILED
        ? <CenteredGenericErrorMessage onRetryClicked={ fetchReach }/>
        : <CenteredGenericLoadingMessage/>
  );
};
