import { useCallback, useMemo, useState } from 'react';
import { AxiosError } from 'axios';
import { ApiRequest } from '@ourpeople/shared/Core/Model/ApiRequest';

import { useContextOrThrow } from '../../Core/Hook/useContextOrThrow';
import { ApiContext, AuthContext } from '../../Contexts';
import { RequestState } from '../../Models/RequestState';

type Response = {
  elevate: (password: string) => void;
  elevateRequest: ApiRequest<void, ErrorResponse>;
};

type ElevateError = 'GENERIC' | 'INVALID' | 'RATE_LIMIT';

type ErrorResponse = {
  error: ElevateError;
  retryAfter?: number;
};

export const useElevate = (): Response => {
  const api = useContextOrThrow(ApiContext);
  const { refreshAuthDescription } = useContextOrThrow(AuthContext);
  const [elevateRequest, setElevateRequest] = useState<ApiRequest<void, ErrorResponse>>({
    state: RequestState.PENDING,
    result: null,
  });

  const elevate = useCallback(
    (password: string) => {
      if (!api || !password) {
        return;
      }

      let cancelled = false;
      setElevateRequest({
        state: RequestState.FETCHING,
        result: null,
      });

      api.post('/auth/session/elevate', {password})
        .then(() => {
          if (!cancelled) {
            setElevateRequest({
              state: RequestState.COMPLETE,
              result: undefined,
            });
            refreshAuthDescription();
          }
        })
        .catch((error: AxiosError<unknown>) => {
          if (!cancelled) {
            switch (error.response?.status || 0) {
              case 401:
                setElevateRequest({
                  state: RequestState.FAILED,
                  result: {
                    error: 'INVALID'
                  },
                });
                break;
              case 429:
                const headers = error.response?.headers as Record<string, string>;
                const retryAfterHeader = headers && headers['retry-after'];
                setElevateRequest({
                  state: RequestState.FAILED,
                  result: {
                    error: 'RATE_LIMIT',
                    retryAfter: isNaN(+retryAfterHeader) ? 0 : +retryAfterHeader,
                  },
                });
                break;
              default:
                setElevateRequest({
                  state: RequestState.FAILED,
                  result: {
                    error: 'GENERIC',
                  },
                });
            }
          }
        });

      return () => { cancelled = true };
    },
    [api, refreshAuthDescription],
  );

  return useMemo(() => ({
    elevate,
    elevateRequest: elevateRequest,
  }), [elevate, elevateRequest]);
};
