export enum IntegrationsPermission {
  ALL = 'integrations',
  CREATE = 'integrations.create',
  UPDATE = 'integrations.update',
  READ = 'integrations.read',
  ACCESS_REQUESTS = 'integrations.accessRequests',
  ACCESS_REQUESTS_CREATE = 'integrations.accessRequests.create',
  ACCESS_REQUESTS_UPDATE = 'integrations.accessRequests.update',
  ACCESS_REQUESTS_READ = 'integrations.accessRequests.read',
  CLIENTS = 'integrations.clients',
  CLIENTS_CREATE = 'integrations.clients.create',
  CLIENTS_READ = 'integrations.clients.read',
  USERS = 'integrations.users',
  USERS_READ = 'integrations.users.read',
  USERS_UPDATE = 'integrations.users.update',
}
