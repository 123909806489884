import { ChangeEventHandler, Dispatch, FunctionComponent, SetStateAction, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { AutocompleteSelection } from '@ourpeople/shared/Core/Component/Input/Autocomplete/Autocomplete';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';
import { Heading } from '@ourpeople/shared/Core/Component/Content';

import { AdministratedTeamsFields, DetailsFormFields, FlagsFormFields, RoleFormFields } from '..';
import { useEnvironmentSettings, useUserRoles } from '../../../Common/Hook';
import { PersonForCreating, PersonForEditing } from '../../Model';
import { StyledDivider, StyledFormSection, StyledNoProfilesMessage, StyledProfileForms } from './styles';
import { RoleId } from '../../../Security/Model';
import { Tag, TagType } from '../../../Tags/Model';
import { ValidationTree } from '../../../Common/Model';
import { Flex, FlexPullRight } from '../../../Common/Component';
import { DraftProfile, ProfileForm } from '../ProfileForm/ProfileForm';
import { CustomField } from '../../Hook';
import { useLoggedInAuthDescription } from '../../../Core/Hook';
import { ArrayHelper } from '../../../Common/Utility';
import { RoleReader } from '../../../Readers';

type Props = {
  person: PersonForCreating;
  onPersonChange: Dispatch<SetStateAction<PersonForCreating>>;
  customFields: CustomField[];
  onCustomFieldsChange: (customFields: CustomField[]) => void;
  profiles: DraftProfile[];
  onProfilesChanged: (profiles: DraftProfile[]) => void;
  validation?: ValidationTree<PersonForCreating & {profiles: DraftProfile[]}>;
};

export const SharedPersonFormFields: FunctionComponent<Props> = ({
  person,
  onPersonChange,
  customFields,
  onCustomFieldsChange,
  profiles,
  onProfilesChanged,
  validation,
}) => {
  const { administratedTags: userAdministratedTeamIds } = useLoggedInAuthDescription();
  const { defaultCountryCode } = useEnvironmentSettings();
  const { mostPermissiveRole, userIsDeveloper } = useUserRoles();
  const allowedTeamIds = useMemo(
    () => RoleReader.priority(mostPermissiveRole) <= RoleReader.priority(person.roleId)
      ? []
      : userAdministratedTeamIds,
    [userAdministratedTeamIds, mostPermissiveRole, person.roleId]
  );

  const addProfile = useCallback(() => {
    onProfilesChanged([
      {
        tags: [],
        externallyManaged: false,
      },
      ...profiles,
    ])
  }, [profiles, onProfilesChanged]);

  const handleSwitchChange: ChangeEventHandler<HTMLInputElement> = useCallback(event => {
    onPersonChange((currentPerson) => ({ ...currentPerson, [event.target.name]: event.target.checked }));
  }, [onPersonChange]);

  const handleRoleChange: ChangeEventHandler<HTMLInputElement> = useCallback(event => {
    const roleId = event.target.value as RoleId;
    onPersonChange((currentPerson) => ({
      ...currentPerson,
      roleId,
      administratedTeamIds: ['ROLE_ADMIN', 'ROLE_BROADCAST_ADMIN'].includes(roleId)
        ? currentPerson.administratedTeamIds
        : [],
    }));
  }, [onPersonChange]);

  const handleDetailsChange = useCallback((partialPerson: Partial<PersonForEditing>) => {
    onPersonChange((currentPerson) => ({
      ...currentPerson,
      ...partialPerson,
    }));
  }, [onPersonChange]);

  const whenAdministratedTeamsChange = useCallback((selection: AutocompleteSelection<Tag<TagType.Team>>) => {
    onPersonChange({
      ...person,
      administratedTeamIds: selection.options.map((team) => team.id),
    });
  }, [onPersonChange, person]);

  const personDetails = useMemo(() => (
    <DetailsFormFields
      defaultCountryCode={ defaultCountryCode || 'GB' }
      person={ person }
      customFields={ customFields }
      onCustomFieldsChange={ onCustomFieldsChange }
      onChange={ handleDetailsChange }
      validation={ validation }
    />
  ), [
    defaultCountryCode,
    person,
    customFields,
    onCustomFieldsChange,
    handleDetailsChange,
    validation
  ]);

  const showAdministratedTeams = (
    ['ROLE_ADMIN', 'ROLE_BROADCAST_ADMIN'].indexOf(person.roleId) !== -1
  );

  const whenProfileRemoved = useCallback(
    (profileIndex: number) => {
      onProfilesChanged(ArrayHelper.remove(profiles, profileIndex));
    },
    [onProfilesChanged, profiles],
  );

  const whenProfileChanged = useCallback(
    (profileIndex: number) => (
      (updatedProfile: DraftProfile) => {
        onProfilesChanged(ArrayHelper.replace(profiles, profileIndex, updatedProfile));
      }
    ),
    [onProfilesChanged, profiles],
  );

  const addProfileButton = useMemo(() => (
    <Button
      variant="secondary"
      onClick={ addProfile }
    >
      <FormattedMessage
        description="Add profile button label on edit account page"
        defaultMessage="Add profile"
      />
    </Button>
  ), [addProfile]);

  return (
    <>
      <Heading type="h2">
        <FormattedMessage
          id="people.edit.details"
          description="Heading for person account details"
          defaultMessage="Details"
        />
      </Heading>
      <StyledFormSection>
        { personDetails }
      </StyledFormSection>
      <>
        <StyledDivider/>
        <Flex>
          <Heading type="h2">
            <FormattedMessage
              description="Edit account page heading for profiles"
              defaultMessage="Profiles"
            />
          </Heading>
          { !!profiles.length && (
            <FlexPullRight>
              { addProfileButton }
            </FlexPullRight>
          ) }
        </Flex>
        <StyledFormSection>
          {
            profiles.length > 0
              ? (
                <StyledProfileForms>
                  { profiles.map((profile, profileIndex) => (
                    <ProfileForm
                      key={ profile.id }
                      profile={ profile }
                      onRemove={ () => whenProfileRemoved(profileIndex) }
                      onChange={ whenProfileChanged(profileIndex) }
                      validation={ validation?.children.profiles?.children[profileIndex] }
                    />
                  )) }
                </StyledProfileForms>
              )
              : (
                <StyledNoProfilesMessage>
                    <span>
                      <FormattedMessage
                        description="Message displayed when a person has no profiles on the person edit page."
                        defaultMessage="This person has no profiles."
                      />
                    </span>
                  { addProfileButton }
                </StyledNoProfilesMessage>
              )
          }
        </StyledFormSection>
        <StyledDivider/>
        <Heading type="h2">
          <FormattedMessage
            id="people.edit.accessLevel"
            description="Heading for person access level"
            defaultMessage="Access Level"
          />
        </Heading>
        <StyledFormSection>
          <RoleFormFields
            selectedRoleId={ person.roleId }
            onRoleChange={ handleRoleChange }
          />
        </StyledFormSection>
      </>
      { showAdministratedTeams && (
        <>
          <StyledDivider/>
          <Heading type="h2">
            <FormattedMessage
              id="people.edit.administratedTeams"
              description="Heading for administrated teams"
              defaultMessage="Administrated teams"
            />
          </Heading>
          <StyledFormSection>
            <AdministratedTeamsFields
              allowedTeamIds={ allowedTeamIds }
              teamIds={ person.administratedTeamIds }
              onTeamsChange={ whenAdministratedTeamsChange }
            />
          </StyledFormSection>
        </>
      ) }
      { userIsDeveloper && (
        <>
          <StyledDivider/>
          <Heading type="h2">
            <FormattedMessage
              id="people.edit.flags"
              description="Heading for person flags"
              defaultMessage="Flags"
            />
          </Heading>
          <StyledFormSection>
            <FlagsFormFields
              person={ person }
              onFlagChange={ handleSwitchChange }
            />
          </StyledFormSection>
        </>
      ) }
    </>
  );
};
