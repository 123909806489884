import { FunctionComponent, PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';
import { Heading } from '@ourpeople/shared/Core/Component/Content';

import { StyledNotFoundError } from './style';

export const NotFoundError: FunctionComponent<PropsWithChildren> = ({ children }) => (
  <StyledNotFoundError>
    <Heading type="h1">
      <FormattedMessage
        id="notFound"
        defaultMessage="Not found"
        />
    </Heading>
    {typeof children === 'string' ? <p>{children}</p> : children}
  </StyledNotFoundError>
);
