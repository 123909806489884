import { FC, forwardRef, Ref, SVGProps } from 'react';
import { FormattedMessage } from 'react-intl';

import { Stack } from '../Stack/Stack';
import { PresentationIcon } from '../PresentationIcon/PresentationIcon';
import { StyledSsoButton } from './style';
import { ElementOrComponent } from '../../types/ElementOrComponent';
import { OverrideComponentProps } from '../../types/OverrideComponentProps';

type SsoButtonProps<C extends ElementOrComponent> = {
  component: C;
  name: string;
  Logo: FC<SVGProps<SVGSVGElement>>;
  disabled?: boolean;
  colour?: string;
};

type Props<C extends ElementOrComponent> = OverrideComponentProps<C, SsoButtonProps<C>>;

export const SsoButton = forwardRef(<C extends ElementOrComponent>({
  name,
  Logo,
  colour,
  ...buttonProps
}: Props<C>, ref: Ref<HTMLButtonElement>) => (
  <StyledSsoButton
    variant="secondary-grey"
    color={ colour }
    ref={ ref }
    { ...buttonProps }
  >
    <Stack gap={ 4 }>
      <PresentationIcon IconComponent={ Logo }/>
      <FormattedMessage
        description="Label for SSO sign-in button."
        defaultMessage="Sign in with { ssoClient }"
        values={ { ssoClient: name } }
      />
    </Stack>
  </StyledSsoButton>
));
