import styled from '@emotion/styled';

export const StyledNumericMetric = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  row-gap: 0.5rem;

  h2 {
    font-weight: 400;
    margin: 0;
    font-size: 1.5rem;
    line-height: 1;
  }

  a {
    color: ${ props => props.theme.palette.secondary.main }
  }
`;
