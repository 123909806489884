import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';

import { ProgressDialog } from '../../../Sections/Broadcasts/List/ProgressDialog';
import { BroadcastAction } from '../../Model';

type ProgressDialogsProps = {
  action?: BroadcastAction;
  inProgress: boolean;
};

export const ProgressDialogs: FunctionComponent<ProgressDialogsProps> = ({
  action,
  inProgress,
}) => {
  const intl = useIntl();
  return (
    <>
      {
        inProgress && (
          <>
            <ProgressDialog
              open={action === BroadcastAction.ARCHIVE}
              title={
                intl.formatMessage({
                  id: 'broadcasts.archiving.modalTitle',
                  defaultMessage: 'Archiving...',
                })
              }
              description={
                intl.formatMessage({
                  id: 'broadcasts.archiving.modalDescription',
                  defaultMessage: 'Your broadcast is being archived',
                })
              }
            />
            <ProgressDialog
              open={action === BroadcastAction.DELETE}
              title={
                intl.formatMessage({
                  id: 'broadcasts.deleting.modalTitle',
                  defaultMessage: 'Deleting...',
                })
              }
              description={
                intl.formatMessage({
                  id: 'broadcasts.deleting.modalDescription',
                  defaultMessage: 'Your broadcast is being deleted',
                })
              }
            />
            <ProgressDialog
              open={action === BroadcastAction.DUPLICATE}
              title={
                intl.formatMessage({
                  id: 'broadcasts.duplicating.modalTitle',
                  defaultMessage: 'Duplicating...',
                })
              }
              description={
                intl.formatMessage({
                  id: 'broadcasts.duplicating.modalDescription',
                  defaultMessage: 'Your broadcast is being duplicated',
                })
              }
            />
            <ProgressDialog
              open={action === BroadcastAction.SEND_TO_NEW}
              title={
                intl.formatMessage({
                  id: 'broadcasts.sendingToNew.modalTitle',
                  defaultMessage: 'Sending to new...',
                })
              }
              description={
                intl.formatMessage({
                  id: 'broadcasts.sendingToNew.modalDescription',
                  defaultMessage: 'Your broadcast is being sent to new recipients',
                })
              }
            />
            <ProgressDialog
              open={action === BroadcastAction.STOP}
              title={
                intl.formatMessage({
                  id: 'broadcasts.stopping.modalTitle',
                  defaultMessage: 'Stopping...',
                })
              }
              description={
                intl.formatMessage({
                  id: 'broadcasts.sendingToNew.modalDescription',
                  defaultMessage: 'Your broadcast is being stopped',
                })
              }
            />
            <ProgressDialog
              open={action === BroadcastAction.UNARCHIVE}
              title={
                intl.formatMessage({
                  defaultMessage: 'Unarchiving...',
                })
              }
              description={
                intl.formatMessage({
                  defaultMessage: 'Your broadcast is being unarchived',
                })
              }
            />
          </>
        )
      }
    </>
  );
};
