import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios';
import { Observable, Subject } from 'rxjs';

import { ApiError } from '../Models';
import { Logger } from './Logger';

interface AxiosDefaultHeaders {
  common: Record<string, unknown>
}

const CSRF_TOKEN_HEADER = 'X-OP-CSRF-Token';
const SESSION_TTL_HEADER = 'X-OP-Session-TTL';
const OP_SOURCE_HEADER = 'X-OP-Source';
const OP_DEVICE_TOKEN_HEADER = 'X-OP-Device-Token';
const OP_DEVICE_PLATFORM_HEADER = 'X-OP-Device-Platform';
const OP_DEVICE_MODEL_HEADER = 'X-OP-Device-Model';
const OP_DEVICE_MANUFACTURER_HEADER = 'X-OP-Device-Manufacturer';

export class Api {
  private axios: AxiosInstance;
  private _authError: Subject<AxiosError> = new Subject();
  private _maintenanceError: Subject<AxiosError<ApiError>> = new Subject();
  private _permissionError: Subject<AxiosError<ApiError>> = new Subject();
  private _sessionExpiry: Subject<number> = new Subject();
  private csrfToken: string | null = null;
  private jwtToken: string | null = null;

  constructor() {
    this.axios = this.axiosFactory();
    this.addDefaultHeader(OP_SOURCE_HEADER, 'Console');
  }

  public get authorized(): boolean {
    return !!(this.csrfToken || this.jwtToken);
  }

  public onAuthError(): Observable<AxiosError> {
    return this._authError.asObservable();
  }

  public onMaintenanceError(): Observable<AxiosError<ApiError>> {
    return this._maintenanceError.asObservable();
  }

  public onPermissionError(): Observable<AxiosError<ApiError>> {
    return this._permissionError.asObservable();
  }

  public onSessionExpiryUpdated(): Observable<number> {
    return this._sessionExpiry.asObservable();
  }

  public request<T = unknown>(config: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.axios.request(config);
  }

  public get<T = unknown>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.axios.get(url, config);
  }

  public delete<T = unknown>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.axios.delete(url, config);
  }

  public head<T = unknown>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.axios.head(url, config);
  }

  public post<T = unknown>(url: string, data?: unknown, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.axios.post(url, data, config);
  }

  public put<T = unknown>(url: string, data?: unknown, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.axios.put(url, data, config);
  }

  public patch<T = unknown>(url: string, data?: unknown, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.axios.patch(url, data, config);
  }

  public setJwtToken = (token: string | null): void => {
    this.jwtToken = token;
  };

  public applyDeviceHeaders = (
    deviceToken?: string,
    platform?: string,
    model?: string,
    manufacturer?: string,
  ): void => {
    if (deviceToken) {
      this.addDefaultHeader(OP_DEVICE_TOKEN_HEADER, deviceToken);
    }
    if (model) {
      this.addDefaultHeader(OP_DEVICE_MODEL_HEADER, model);
    }
    if (platform) {
      this.addDefaultHeader(OP_DEVICE_PLATFORM_HEADER, platform);
    }
    if (manufacturer) {
      this.addDefaultHeader(OP_DEVICE_MANUFACTURER_HEADER, manufacturer);
    }
  }

  private axiosFactory(): AxiosInstance {
    const axiosInstance = axios.create({
      baseURL: '/api',
      withCredentials: true,
    });
    axiosInstance.interceptors.request.use(
      config => {
        this.logRequest(config);

        if (config.method === undefined) {
          return config;
        }

        if (['POST','PUT','DELETE','PATCH'].indexOf(config.method.toUpperCase()) !== -1) {
          (config.headers as Record<string, unknown>)['X-Requested-With'] = 'XMLHttpRequest';
          if (this.csrfToken) {
            (config.headers as Record<string, unknown>)[CSRF_TOKEN_HEADER] = this.csrfToken;
          }
        }

        if (this.jwtToken) {
          (config.headers as Record<string, unknown>)['Authorization'] = `Bearer ${ this.jwtToken }`;
        }

        return config;
      },
      error => {
        Logger.error('request error', error);
        return Promise.reject(error);
      }
    );
    axiosInstance.interceptors.response.use(
      response => {
        this.logResponse(response);
        const sessionExpiry = (response.headers as Record<string, unknown>)[SESSION_TTL_HEADER.toLowerCase()] as string;
        if (sessionExpiry !== undefined && +sessionExpiry >= 0) {
          this._sessionExpiry.next(+sessionExpiry);
        }
        const csrfToken = (response.headers as Record<string, unknown>)[CSRF_TOKEN_HEADER.toLowerCase()] as string;
        if (csrfToken) {
          this.csrfToken = csrfToken;
        }
        return response;
      },
      (error: AxiosError<ApiError>) => {
        if (error.response) {
          this.logError(error.response);
          if (error.response.status === 401) {
            this._authError.next(error);
          }
          if (error?.response?.data?.error?.code === 'MAINTENANCE_IN_PROGRESS') {
            this._maintenanceError.next(error)
          }
          if (error?.response?.data?.error?.code === 'MISSING_PERMISSIONS') {
            this._permissionError.next(error)
          }
        }
        return Promise.reject(error);
      }
    );

    return axiosInstance;
  }

  private addDefaultHeader(key: string, value: string): void {
    (
      this.axios.defaults.headers as AxiosDefaultHeaders
    ).common[key] = value;
  }

  private logRequest(config: AxiosRequestConfig): void {
    Logger.warn(
      `${(config.method || '').toUpperCase()} ${config.url || ''}`,
      config
    );
  }

  private logResponse(response: AxiosResponse<unknown>): void {
    Logger.success(
      `${response.status} ${response.config.url || ''}`,
      response
    );
  }

  private logError(response: AxiosResponse<unknown>): void {
    Logger.error(
      `${response.status} ${response.config.url || ''}`,
      response
    );
  }
}
