import { useMemo } from 'react';

import { FetchResponse, useGet } from '../../../../Hooks';
import { Paginated } from '../../../../Models';
import { ReportRecipient } from '../../../../Broadcasts/Component';

export interface FetchReportRecipientsParams extends Record<string, unknown> {
  deliveryId: string | null;
  contentId: string | null;
  optionId: string | null;
  rating: number | null;
  responseType: string;
  sort: string;
  relativeStatus: string;
  broadcastReceivedNotificationMethods?: null | string,
  broadcastReceivedNotificationStatuses?: null | string,
  search: string | null;
}

type FetchReportRecipientsResponse<RecipientMetadataType extends Record<string, unknown>> = Paginated<'items', ReportRecipient<RecipientMetadataType>>;

export const useFetchReportRecipients = <RecipientMetadataType extends Record<string, unknown>>(
  broadcastId: string,
  pageNum: number,
  params: FetchReportRecipientsParams,
): FetchResponse<FetchReportRecipientsResponse<RecipientMetadataType>> => {
  const endpoint = `/broadcasts/${broadcastId}/report-recipients`;
  const paramsWithPageNum: FetchReportRecipientsParams & {pageNum: number} = useMemo(() => ({
    ...params,
    pageNum,
  }), [pageNum, params]);

  return useGet<FetchReportRecipientsResponse<RecipientMetadataType>>(
    endpoint,
    paramsWithPageNum,
  );
}
