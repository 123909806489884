import styled from '@emotion/styled';

export const StyledTagConditionContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: ${props => props.theme.spacing(2)};

  .MuiFormControl-root {
    margin-top: 0;
  }
`;
