import { ValidationError } from './ValidationError';

type Assertion<T> = (value: T) => ValidationError | void;

export class Validator<T> {
  constructor(private readonly assertions: Assertion<T>[]) {}

  public validate = (value: T | null | undefined): ValidationError[] => {
    if (value === undefined || value === null) {
      return [];
    }

    return this.assertions.reduce<ValidationError[]>((errors, assertion) => {
      const error = assertion(value);
      return error ? errors.concat(error): errors;
    }, []);
  };
}
