import styled from '@emotion/styled';

export const StyledBoxPage = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  background: ${ props => props.theme.palette.background.default };

  > svg {
    margin: auto;
  }
`;

export const StyledBoxDialog = styled.div`
  background-color: ${ props => props.theme.palette.background.paper };
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
  overflow: hidden;

  ${ props => props.theme.breakpoints.up('md') } {
    width: 700px;
    margin: auto;
    border-radius: 0.25rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    padding: 3rem 7rem 1.5rem;
  }

  ${ props => props.theme.breakpoints.down('md') } {
    width: 100%;
    height: 100%;
    padding: 2rem 1rem 1rem;
  }
`;
