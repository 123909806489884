import styled from '@emotion/styled';

import { CaptionAlignment } from './ImageContentWithoutFooter';

type StyledImageCaptionProps = {
  captionHeight: number | undefined;
  overlapBottom?: boolean;
  alignment: CaptionAlignment;
  backgroundColour: string;
  textColour: string;
};

export const StyledImageCaption = styled.div<StyledImageCaptionProps>`
  display: flex;
  align-items: center;
  height: ${ props => props.captionHeight ? `${ props.captionHeight }px` : '12rem' };
  width: 100%;
  ${ props => props.overlapBottom && `margin-top: ${ props.captionHeight ? `${ -0.5 * props.captionHeight }px` : '-6rem' }` };
  ${ props => !props.overlapBottom && props.alignment.vertical === 'bottom' && 'margin-top: auto;' };

  > div {
    height: 100%;
    width: 100%;
    padding: 0 1rem;
    ${ props => `
      text-align: ${ props.alignment.horizontal === 'middle' ? 'center' : props.alignment.horizontal };
      justify-content: ${ props.alignment.horizontal === 'middle' ? 'center' : props.alignment.horizontal };
    ` }
    display: flex;
    align-items: center;

    > div {
      transform: rotate(-3deg);
      align-items: center;
      display: flex;
    }
  }

  p {
    line-height: 1.5;
    padding: 0.25em;
    display: inline;
    outline: none;
    ${ (props: StyledImageCaptionProps) => `
      background-color: ${ props.backgroundColour };
      color: ${ props.textColour };
    ` }
    margin: 0;
    box-decoration-break: clone;
  }
`;

export const StyledSubCopy = styled.div`
  padding: ${ props => `${ props.theme.new.spacing[4] } ${ props.theme.new.spacing[4] } ${ props.theme.new.spacing[16] }` };
`;

export const StyledImageContentPreview = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;
