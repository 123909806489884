import { IntlShape } from 'react-intl';

import { DraftGeolocationRequest, GeolocationRequest } from '../../Model/ContentTypes';
import { GeolocationRequestFields, GeolocationRequestPreview } from '../../Component/Content';
import { ValidationTree } from '../../../Common/Model';
import { RequestValidator } from '../../Utility/Validation';
import { SingleContentCard, EditorContent } from '../../../Content/Model';
import GeolocationRequestIcon from '../../../Assets/img/icons/monochrome/content/geolocation.svg';
import { UniqueIdGenerator } from '../../../Common/Utility';
import { CardFactory, CardTransformer } from '../../../Content/Utility';
import { DraftResponse, FormContentDefinition, Response } from '../../Model';
import { EnvironmentSettings } from '../../../Models';
import { LiveGeolocationRequest } from '../../Component/LiveContent/LiveGeolocationRequest/LiveGeolocationRequest';
import { SubmittedGeolocationRequest } from '../../Component/SubmittedContent';

export type GeolocationRequestEditorContent = EditorContent<DraftGeolocationRequest>;

type BaseGeolocationResponse = {
  contentType: 'geolocationRequest';
  latitude: number;
  longitude: number;
};
export type DraftGeolocationResponse = DraftResponse<BaseGeolocationResponse>;
export type GeolocationResponse = Response<BaseGeolocationResponse>;

export class GeolocationRequestDefinition implements FormContentDefinition<
  GeolocationRequest,
  GeolocationRequestEditorContent,
  DraftGeolocationResponse,
  GeolocationResponse
> {
  public readonly id = 'geolocationRequest';
  public readonly availableToCreate = true;
  public readonly new = false;
  public readonly richText = false;
  public readonly contentTypes = ['geolocationRequest'];
  public readonly EditorComponent = GeolocationRequestFields;
  public readonly PreviewComponent = GeolocationRequestPreview;
  public readonly LiveComponent = LiveGeolocationRequest;
  public readonly SubmittedComponent = SubmittedGeolocationRequest;
  public readonly IconComponent = GeolocationRequestIcon;
  public readonly categoryId = 'input';
  public readonly identifiesUser = true;

  public definesContentType = (type: string): boolean => (
    this.contentTypes.includes(type)
  );

  public createEditorContent = (): GeolocationRequestEditorContent => ({
    id: UniqueIdGenerator.generate(),
    card: CardFactory.fromDraftContent({
      type: 'geolocationRequest',
      title: '',
      text: null,
    }),
  });

  public getContentTitle = (intl: IntlShape, content: GeolocationRequest): string => (
    content.title || this.getDefaultTitle(intl)
  );

  public getDraftContentTitle = (intl: IntlShape, draftContent: DraftGeolocationRequest): string => (
    draftContent.title || this.getDefaultTitle(intl)
  );

  public hasRequiredFeatureFlags = (environmentSettings: EnvironmentSettings): boolean => (
    !!environmentSettings.formsEnabled
  );

  public getLocalisedType = (intl: IntlShape): string => intl.formatMessage({
    id: 'forms.content.geolocationRequest.type',
    description: 'Localised name for geolocation request type.',
    defaultMessage: 'Location',
  });

  public transformCard = (card: SingleContentCard<GeolocationRequest>): GeolocationRequestEditorContent => ({
    id: card.id,
    card: CardTransformer.transformCard(card, card.content),
  });

  public validate = (draftContent: DraftGeolocationRequest): ValidationTree<DraftGeolocationRequest> =>
    RequestValidator.validate(draftContent);

  private getDefaultTitle = (intl: IntlShape): string => intl.formatMessage({
    id: 'forms.content.geolocationRequest.defaultTitle',
    description: 'Default title for geolocation request.',
    defaultMessage: 'Untitled location',
  });
}
