import {
  CountryCode,
  getCountries,
  getCountryCallingCode,
  parsePhoneNumber
} from 'libphonenumber-js';
import { CountryInformation } from '@ourpeople/shared/Account/Component/Input/PhoneNumberField/PhoneNumberField';

export class PhoneNumberHelper {
  public static getCountryFromPhoneNumber = (e164Number: string): CountryInformation | undefined => {
    try {
      const parsedNumber = parsePhoneNumber(e164Number);
      return countryInformation.find(countryInformation => countryInformation.code === parsedNumber.country);
    } catch {
      return undefined
    }
  }

  public static formatInternational = (e164Number: string): string => {
    try {
      const parsedNumber = parsePhoneNumber(e164Number);
      return parsedNumber.formatInternational();
    } catch {
      return e164Number;
    }
  }
}

const getCountryInformation = () => {
  const displayNames = new Intl.DisplayNames(navigator.language, { type: 'region' });
  const getCountryInformation = (countryCode: CountryCode) => ({
    code: countryCode,
    callingCode: getCountryCallingCode(countryCode),
    name: displayNames.of(countryCode) || countryCode,
  });
  return getCountries().map(getCountryInformation)
    .sort((a, b) => a.name < b.name ? -1 : a.name === b.name ? 0 : 1);
};

const countryInformation = getCountryInformation();
