import styled from '@emotion/styled';
import { rgba } from 'polished';

export const StyledPadding = styled.div`
  width: 100%;
  padding-top: 67%;
  transition: background-color 0.1s;
`;

export const StyledTemplate = styled.div<{ backgroundUrl: string, selected: boolean }>`
  background-image: url("${ props => props.backgroundUrl }");
  background-repeat: no-repeat;
  position: relative;
  background-size: 100%;
  background-position: center;
  width: 100%;
  transition: background-size 0.3s;
  user-select: none;
  cursor: pointer;

  &:hover {
    background-size: 110%;
  }

  ${ props => props.selected && `
    cursor: default;
    background-size: 110%;

    >div:first-of-type {
      background-color: rgba(228, 228, 228, 0.9);
    }
  ` }
`;

export const StyledTemplateContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const StyledTemplateName = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: ${ props => rgba(props.theme.palette.background.paper, 0.8) };
  padding: ${ props => props.theme.spacing(2) };
`;

export const StyledTemplateDetail = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  padding: ${ props => props.theme.spacing(2) };
`;

export const StyledTemplateButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  margin-top: auto;
  gap: ${ props => props.theme.spacing(2) };
`;

export const StyledTemplateDescription = styled.div`
  font-size: 0.9rem;
  overflow-y: auto;
  margin-bottom: ${ props => props.theme.spacing(2) };
`;
