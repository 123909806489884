import { IntlShape } from 'react-intl';

import { SingleContentCard, DraftContent, EditorContent } from '../../../../Content/Model';
import ChoiceContentIcon from '../../../../Assets/img/icons/monochrome/content/choice.svg';
import { UniqueIdGenerator } from '../../../../Common/Utility';
import { CardTransformer } from '../../../../Content/Utility';
import { ChoiceRequestEditor, ChoiceRequestPreview } from '../../../Component';
import { ChoiceRequestValidator } from '../../Validators';
import { BroadcastContent, BroadcastContentDefinition } from '../../../Model';
import { ChoiceResponse } from '../../../../Inbox/Model';
import { LiveChoiceContent } from '../../../../Inbox/Component';
import { EnvironmentSettings } from '../../../../Models';

export type ChoiceOption = {
  id: number | string;
  type: 'choiceoption';
  text: string;
};

export type DraftChoiceOption = {
  id: string;
  type: 'choiceoption';
  text: string;
};

export type DraftChoiceRequest = DraftContent & {
  type: 'multiplechoicequestion' | 'singlechoicequestion';
  title: string;
  options: DraftChoiceOption[];
};

export type ChoiceRequest = BroadcastContent & {
  type: 'multiplechoicequestion' | 'singlechoicequestion';
  title: string;
  options: ChoiceOption[];
};

export type ChoiceEditorContent = EditorContent<DraftChoiceRequest>

export class ChoiceRequestDefinition implements BroadcastContentDefinition<ChoiceRequest, ChoiceEditorContent, ChoiceResponse> {
  public readonly id = 'choice';
  public readonly availableToCreate = true;
  public readonly new = false;
  public readonly richText = false;
  public readonly contentTypes = ['multiplechoicequestion', 'singlechoicequestion'];
  public readonly categoryId = 'feedback';
  public readonly IconComponent = ChoiceContentIcon;
  public readonly EditorComponent = ChoiceRequestEditor;
  public readonly PreviewComponent = ChoiceRequestPreview;
  public readonly LiveComponent = LiveChoiceContent;
  public readonly SubmittedComponent = (): null => null;
  public validate = ChoiceRequestValidator.validate;

  public definesContentType = (type: string): boolean => (
    this.contentTypes.includes(type)
  );

  public getContentTitle = (intl: IntlShape, content: ChoiceRequest): string => (
    content.title || this.getDefaultTitle(intl)
  );

  public getLocalisedType = (intl: IntlShape): string => (
    intl.formatMessage({
      id: 'broadcasts.content.choice.type',
      description: 'Localised name for choice request type.',
      defaultMessage: 'Choice',
    })
  );

  public getDraftContentTitle = (intl: IntlShape, draftContent: DraftChoiceRequest): string => (
    draftContent.title || this.getDefaultTitle(intl)
  );

  public hasRequiredFeatureFlags = (environmentSettings: EnvironmentSettings): boolean => (
    !!environmentSettings.choiceTypeEnabled
  );

  public createEditorContent = (): ChoiceEditorContent => ({
    id: UniqueIdGenerator.generate(),
    card: {
      content: {
        type: 'multiplechoicequestion',
        title: '',
        options: [
          {
            id: UniqueIdGenerator.generate(),
            type: 'choiceoption',
            text: '',
          },
          {
            id: UniqueIdGenerator.generate(),
            type: 'choiceoption',
            text: '',
          },
        ],
      },
      mandatory: false,
      image: null,
    },
  });

  public transformCard = (card: SingleContentCard<ChoiceRequest>): ChoiceEditorContent => ({
    id: card.id,
    card: CardTransformer.transformCard(card, {
      type: card.content.type,
      title: card.content.title,
      options: card.content.options.map(({ id, ...option }) => ({
        ...option,
        id: id?.toString() || UniqueIdGenerator.generate(),
      })),
    }),
  });

  public cloneEditorContent = (editorContent: ChoiceEditorContent): ChoiceEditorContent => ({
    ...editorContent,
    id: UniqueIdGenerator.generate(),
    card: {
      ...editorContent.card,
      content: {
        ...editorContent.card.content,
        options: editorContent.card.content.options.map(option => ({
          ...option,
          id: UniqueIdGenerator.generate(),
        })),
      },
    },
  });

  private getDefaultTitle = (intl: IntlShape): string => (
    intl.formatMessage({
      id: 'broadcasts.content.reveal.defaultTitle',
      description: 'Default title for reveal content.',
      defaultMessage: 'Untitled choice card',
    })
  );
}
