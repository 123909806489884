import { FC, ReactNode, useEffect } from 'react';

import { HeaderImage } from '../../../Content/Component';
import { Description, Progress, Title } from './style';
import { VerticallySpaced } from '../../../Common/Component';

type Props = {
  imageId?: string;
  imageFit?: 'contain' | 'cover';
  currentIndex: number;
  cardCount: number;
  contentTitle: ReactNode;
  contentDescription?: string;
  useThumbnail?: boolean;
  onFinish?: () => void;
};

export const FormContentDetail: FC<Props> = ({
  imageId,
  imageFit = 'cover',
  currentIndex,
  cardCount,
  contentTitle,
  contentDescription,
  useThumbnail = false,
  onFinish,
  children,
}) => {
  useEffect(() => {
    if (imageId || !onFinish) {
      return;
    }

    onFinish();
  }, [imageId, onFinish]);

  return (
    <>
      <VerticallySpaced gap={ 1 }>
        { imageId && (
          <HeaderImage
            uploadId={ imageId }
            useThumbnail={ useThumbnail }
            fit={ imageFit }
            onFinish={ onFinish }
          />
        ) }
        { currentIndex !== 0 && (
          <Progress>
            { currentIndex } of { cardCount - 1 }
          </Progress>
        ) }
        <Title>{ contentTitle }</Title>
      </VerticallySpaced>
      { contentDescription && <Description>{ contentDescription }</Description> }
      { children }
    </>
  );
}
