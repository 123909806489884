import styled from '@emotion/styled';
import { Menu } from '@mui/material';

export const StyledMobileStepper = styled.nav`
  display: grid;
  width: 100%;
  grid-template-columns: ${ props => `${ props.theme.spacing(5) } 1fr ${ props.theme.spacing(5) }` };
`;

export const StyledStepMenu = styled(Menu)`
  a:hover {
    text-decoration: none;
  }
`;
