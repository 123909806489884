import { useIntl } from 'react-intl';

import EditIcon from '../../../Assets/img/icons/streamline/common-file-edit.svg';
import { GuardedLink } from '../../../Security/Component';
import { Broadcast, BroadcastsPermission } from '../../Model';
import { ContextMenuAction } from '../../../Common/Component/ContextMenu/ContextMenu';
import { useUneditableBroadcastMessage } from '../useUneditableBroadcastMessage';

export const useEditBroadcastAction = (
  broadcast: Broadcast,
  resourceLockMessage: string | null,
): ContextMenuAction => {
  const intl = useIntl();
  const uneditableBroadcastTooltipMessage = useUneditableBroadcastMessage();

  return {
    id: 'edit',
    IconComponent: EditIcon,
    label: intl.formatMessage({
      description: 'Label for edit action in scheduled and draft broadcast action menus.',
      defaultMessage: 'Edit',
    }),
    component: GuardedLink,
    to: `/broadcasts/${ broadcast.id }/edit`,
    permissions: [BroadcastsPermission.UPDATE],
    ...(resourceLockMessage ? {
        disabled: true,
        disabledTooltip: resourceLockMessage,
      } : broadcast.editable
        ? {}
        : {
          disabled: true,
          disabledTooltip: uneditableBroadcastTooltipMessage,
        }),
  };
};
