export class TextMatcher {
  public static caseInsensitive(textA: string, textB: string): boolean {
    return textA.toUpperCase() === textB.toUpperCase();
  }

  public static similar(textA: string, textB: string): boolean {
    return textA.replace(/\s+/g, '').toUpperCase().includes(
      textB.replace(/\s+/g, '').toUpperCase(),
    );
  }
}
