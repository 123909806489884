import { FC, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { TextField } from '@mui/material';
import { PrimaryInformation } from 'op-storybook/stories/components/Typography/PrimaryInformation';
import { SecondaryInformation } from 'op-storybook/stories/components/Typography/SecondaryInformation';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';

import { RequestState } from '../../../../../src/react/Models/RequestState';
import { Button, ButtonProps } from '../../Input/Button/Button';
import { VerticallySpaced } from '../../../../../src/react/Common/Component/VerticallySpaced/VerticallySpaced';
import { Sheet } from '../../../../../src/react/New/Common/Component/Layout/Sheet';
import { TabBar } from '../../../../../src/react/New/Common/Component/Navigation/TabBar';
import { MinimumInset } from '../../../../../src/react/New/Common/Component/Layout/MinimumInset';
import { ToolbarClearance } from '../../../../../src/react/New/Common/Component/Layout/ToolbarClearance';

type Props = {
  submitState: RequestState;
  inputDisabled: boolean;
  submitDisabled: boolean;
  emailAddress: string;
  onChange: (value: string) => void;
  onSubmit: () => void;
  onCancel: () => void;
}

export const EmailVerificationPrompt: FC<Props> = ({
  submitState,
  inputDisabled,
  submitDisabled,
  emailAddress,
  onChange,
  onSubmit,
  onCancel,
}) => {
  const intl = useIntl();
  const [lastNonFetchingSubmitState, setLastNonFetchingSubmitState] = useState<RequestState>(submitState);

  useEffect(() => {
    if (submitState === RequestState.FETCHING) {
      return;
    }

    setLastNonFetchingSubmitState(submitState);
  }, [submitState]);

  const actions = useMemo<ButtonProps[]>(() => [
    ...(lastNonFetchingSubmitState === RequestState.COMPLETE
      ? [
        {
          children: intl.formatMessage({
            description: 'Label for close button in dialog displayed when user needs to add a verified email address when email address has been successfully submitted.',
            defaultMessage: 'Okay',
          }),
          onClick: onCancel,
        }
      ]
      : [
        {
          children: intl.formatMessage({
            description: 'Label for cancel button in dialog displayed when user needs to add a verified email address.',
            defaultMessage: 'Later',
          }),
          onClick: onCancel,
        },
        {
          children: lastNonFetchingSubmitState === RequestState.FAILED
            ? intl.formatMessage({
              description: 'Label for submit button in email verification dialog when submitting preferred email address fails.',
              defaultMessage: 'Try again',
            })
            : intl.formatMessage({
              description: 'Label for submit button in dialog displayed when user needs to add a verified email address.',
              defaultMessage: 'Verify now',
            }),
          busy: submitState === RequestState.FETCHING,
          variant: 'primary',
          onClick: onSubmit,
          disabled: submitDisabled,
        } as ButtonProps,
      ]),
  ], [intl, lastNonFetchingSubmitState, onCancel, onSubmit, submitDisabled, submitState]);

  return (
    <Sheet
      open={ true }
    >
      <ToolbarClearance clear={ ['bottom'] }>
        <MinimumInset
          top={ 4 }
          left={ 2 }
          right={ 2 }
          bottom={ 4 }
        >
          <Stack
            direction="column"
            gap={ 4 }
          >
            <PrimaryInformation>
              {
                lastNonFetchingSubmitState === RequestState.COMPLETE
                  ? intl.formatMessage({
                    description: 'Title for dialog displayed when user needs to add a verified email address when email address has been submitted.',
                    defaultMessage: 'Thank you',
                  })
                  : lastNonFetchingSubmitState === RequestState.FAILED
                    ? intl.formatMessage({
                      description: 'Title for dialog displayed when user needs to add a verified email address when email address failed to submit.',
                      defaultMessage: 'Something went wrong',
                    })
                    : intl.formatMessage({
                      description: 'Title for dialog displayed when user needs to add a verified email address.',
                      defaultMessage: 'We\'d like to update your contact details',
                    })
              }
            </PrimaryInformation>
            <SecondaryInformation>
              {
                lastNonFetchingSubmitState === RequestState.COMPLETE
                  ? (
                    <FormattedMessage
                      description="Dialog content for dialog displayed when user needs to add a verified email address when submitting email address succeeds."
                      defaultMessage="A verification email has been sent to your preferred email address."
                    />
                  )
                  : lastNonFetchingSubmitState === RequestState.FAILED
                    ? (
                      <VerticallySpaced gap={ 1 }>
                        <FormattedMessage
                          description="Dialog content for dialog displayed when user needs to add a verified email address when submitting email address fails."
                          defaultMessage="Please check you preferred email address is correct and try again"
                        />
                        <TextField
                          label={ intl.formatMessage({
                            description: 'Label for email field in dialog displayed when user needs to add a verified email address.',
                            defaultMessage: 'Preferred email address',
                          }) }
                          disabled={ inputDisabled }
                          value={ emailAddress }
                          onChange={ event => onChange(event.currentTarget.value.trim()) }
                        />
                      </VerticallySpaced>
                    )
                    : (
                      <VerticallySpaced>
                        <FormattedMessage
                          description="Dialog content for dialog displayed when user needs to add a verified email address."
                          defaultMessage="
                          <p>We're working on some exciting updates that will require a verified email address. This includes email alerts for smart forms so you know when something needs your attention.</p>
                          <p>If you'd like to use this and other exciting features please provide your preferred email address.</p>
                        "
                        />
                        <TextField
                          label={ intl.formatMessage({
                            description: 'Label for email field in dialog displayed when user needs to add a verified email address.',
                            defaultMessage: 'Preferred email address',
                          }) }
                          disabled={ inputDisabled }
                          value={ emailAddress }
                          onChange={ event => onChange(event.currentTarget.value.trim()) }
                        />
                      </VerticallySpaced>
                    )
              }
            </SecondaryInformation>
          </Stack>
        </MinimumInset>
      </ToolbarClearance>
      <TabBar>
        <Stack fillParent justify="space-between">
          { actions.map((buttonProps, buttonIndex) => (
            <Button
              key={ buttonProps.key || buttonProps.id || buttonIndex }
              { ...buttonProps }
            />
          )) }
        </Stack>
      </TabBar>
    </Sheet>
  )
};
