import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useEmailVerificationDialogDismissed } from '@ourpeople/shared/Core/Hook/useEmailVerificationDialogDismissed';

import { ReloadRedirect } from '../../../Components';
import { SpaceContext } from '../../../Core/Context';

export const LogoutPage: FunctionComponent = () => {
  const intl = useIntl();
  const { space } = useContext(SpaceContext);
  const [loggedOutLocally, setLoggedOutLocally] = useState<boolean>(false);
  const [, setEmailVerificationPromptDismissed] = useEmailVerificationDialogDismissed();

  useEffect(() => {
    document.title = intl.formatMessage({
      id: 'security.logout.pageTitle',
      description: 'Page title for the logout page',
      defaultMessage: 'Logging out...',
    });
  }, [intl]);

  useEffect(() => {
    setEmailVerificationPromptDismissed(false);
    setLoggedOutLocally(true);
  }, [setEmailVerificationPromptDismissed, space]);

  return <>{ loggedOutLocally && <ReloadRedirect href="/api/auth/logout"/> }</>;
};
