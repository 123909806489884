import { useIntl } from 'react-intl';
import { LocalisedString } from 'op-storybook/lib/model/LocalisedString/LocalisedString';

import { PeopleImportRecordType } from '../Model';

export const useLocalisedPeopleRecordTypes = (): LocalisedString<PeopleImportRecordType>[] => {
  const intl = useIntl();

  return [
    {
      id: 'name',
      localisation: intl.formatMessage({
        id: 'people.import.fullNameRecordType',
        description: 'Label for full name record type',
        defaultMessage: 'Full name',
      }),
    },
    {
      id: 'firstName',
      localisation: intl.formatMessage({
        id: 'people.import.firstNameRecordType',
        description: 'Label for first name record type',
        defaultMessage: 'First name',
      }),
    },
    {
      id: 'lastName',
      localisation: intl.formatMessage({
        id: 'people.import.lastNameRecordType',
        description: 'Label for last name record type',
        defaultMessage: 'Last name',
      }),
    },
    {
      id: 'mobileNumber',
      localisation: intl.formatMessage({
        id: 'people.import.mobileNumberRecordType',
        description: 'Label for mobile number record type',
        defaultMessage: 'Phone number',
      }),
    },
    {
      id: 'emailAddress',
      localisation: intl.formatMessage({
        id: 'people.import.emailAddressRecordType',
        description: 'Label for email address record type',
        defaultMessage: 'Email address',
      }),
    },
    {
      id: 'id',
      localisation: intl.formatMessage({
        id: 'people.import.personIdRecordType',
        description: 'Label for person ID record type',
        defaultMessage: 'Person ID',
      }),
    },
    {
      id: 'team',
      localisation: intl.formatMessage({
        id: 'people.import.teamRecordType',
        description: 'Label for team record type',
        defaultMessage: 'Team',
      }),
    },
    {
      id: 'jobtitle',
      localisation: intl.formatMessage({
        id: 'people.import.jobTitleRecordType',
        description: 'Label for job title record type',
        defaultMessage: 'Job title',
      }),
    },
    {
      id: 'skill',
      localisation: intl.formatMessage({
        id: 'people.import.skillRecordType',
        description: 'Label for skill record type',
        defaultMessage: 'Skill',
      }),
    },
    {
      id: 'role',
      localisation: intl.formatMessage({
        id: 'people.import.roleRecordType',
        description: 'Label for role record type',
        defaultMessage: 'Role',
      }),
    },
    {
      id: 'profileId',
      localisation: intl.formatMessage({
        id: 'people.import.profileIdRecordType',
        description: 'Label for profile ID record type',
        defaultMessage: 'Profile ID',
      }),
    },
    {
      id: 'teamAdmin',
      localisation: intl.formatMessage({
        id: 'people.import.administratedTeamsRecordType',
        description: 'Label for administrated teams record type',
        defaultMessage: 'Administrated teams',
      }),
    },
    {
      id: 'locale',
      localisation: intl.formatMessage({
        id: 'people.import.localeRecordType',
        description: 'Label for locale record type',
        defaultMessage: 'Locale',
      }),
    },
  ];
};
