import styled from '@emotion/styled';
import { IconButton } from '@mui/material';

export const StyledIconButton = styled(IconButton)`
  padding: ${ props => `${ props.theme.spacing(1) }` };
  width: ${ props => `${ props.theme.spacing(5) }` };
  height: ${ props => `${ props.theme.spacing(5) }` };
  ${ props => props.disabled && `
    opacity: 0.5;
  ` }

  .MuiBox-root {
    line-height: 0;
  }
`;
