import { FC, SVGProps } from 'react';
import { FunctionInterpolation } from '@emotion/react';

import { defaultTheme } from '../../const/defaultTheme';
import { PaletteLookup } from '../../model/PaletteLookup/PaletteLookup';

type SvgComponent = FC<SVGProps<SVGSVGElement>>;

type PresentationalProps = {
  role: 'presentation';
  label?: never;
};

type NonPresentationalProps = {
  role?: never;
  label: string;
};

export type BaseIconProps = {
  IconComponent: SvgComponent;
  size?: keyof typeof defaultTheme['new']['spacing'];
  className?: string;
  palette?: PaletteLookup;
  strokeWidth?: number;
};

type Props = (PresentationalProps | NonPresentationalProps) & BaseIconProps;

export const Icon: FC<Props> = ({
  IconComponent,
  label,
  size = 6,
  palette,
  role,
  strokeWidth,
  className,
}) => {
  const containerCss: FunctionInterpolation<typeof defaultTheme> = theme => ({
    lineHeight: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    ...palette
      ? { color: theme.new.palette[palette.colour][palette.intensity || 600][palette.contrast ? 'contrast' : 'main'] }
      : {},
    svg: {
      width: theme.new.spacing[size],
      height: theme.new.spacing[size],
      ...strokeWidth !== undefined ? { 'path': { strokeWidth } } : {},
    },
  });

  return (
    <div
      className={ className }
      css={ containerCss }
    >
      <IconComponent
        {
          ...role === 'presentation'
            ? { role: 'presentation' }
            : { 'aria-label': label }
        }
      />
    </div>
  );
};
