import styled from '@emotion/styled';

export const StyledPreviewOverlay = styled.div`
  width: 100%;
  ${ props => props.theme.new.breakpoints.md.up } {
    width: 50%;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${ props => props.theme.new.spacing[4] };
  background-color: ${ props => props.theme.palette.grey[300] };
  border-radius: ${ props => props.theme.borderRadius.sm };
  overflow: hidden;
  height: 150px;
`;
