import { AxiosError, AxiosResponse } from 'axios';

import { ErrorResponse, ValidationErrorResponse, ValidationTree } from '../Model';
import { ApiError } from '../../Models';
import { FileUploadError } from '../Component';

export class ErrorResponseReader {
  public static isApiError = (error: unknown): error is Omit<AxiosError<ApiError>, 'response'> & { response: AxiosResponse<Required<ApiError>> } => {
    if (!(error as AxiosError)?.response?.data) {
      return false;
    }

    const apiError = (error as AxiosError<ApiError>).response?.data as ApiError;
    return !!(
      apiError.error
      && apiError.error.message
      && apiError.error.status
    );
  };

  public static isAxiosErrorWithData = (error: unknown): error is Omit<AxiosError, 'response'> & { response: AxiosResponse<unknown> } => (
    !!(error as AxiosError)?.response?.data
  );

  public static isErrorResponse = (
    response: AxiosResponse<unknown>
  ): response is AxiosResponse<ErrorResponse> => {
    const data = response.data;
    const error = (data as Record<string, unknown>).error as Record<string, unknown> | undefined;

    return !!(error && error.message && error.status);
  };

  public static isValidationErrorResponse = <T>(
    response: AxiosResponse<unknown>
  ): response is AxiosResponse<ValidationErrorResponse<T>> => (
    ErrorResponseReader.isErrorResponse(response) && response.data.error.code === 'VALIDATION_ERRORS'
  );

  public static determineFileUploadError = (error: unknown): FileUploadError => {
    if (error instanceof XMLHttpRequest) {
      const response = JSON.parse(error.responseText) as ApiError;
      if (typeof response !== 'object') {
        return { code: 'UNKNOWN' };
      }
      return { code: (response || {})?.error?.code || 'UNKNOWN' };
    }

    if (!!(error as FileUploadError).code) {
      return error as FileUploadError;
    }

    return { code: (error as AxiosError<ApiError>)?.response?.data?.error?.code || 'UNKNOWN' };
  };

  public static getValidationErrorRoot = <T>(
    error: unknown,
  ): ValidationTree<T> | undefined => (
    ErrorResponseReader.isApiError(error) && ErrorResponseReader.isValidationErrorResponse<T>(error.response)
      ? error.response.data.error.data.root
      : undefined
  );
}

