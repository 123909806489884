import { FetchResponse, useGet } from '../../Hooks';
import { Paginated } from '../../Models';
import { ListedDelivery } from '../../Models';

export type FetchDeliveriesParams = {
  pageNum: number;
  ids?: string;
  broadcastIds?: string;
  createdByIds?: string;
  statuses: string;
  search?: string;
  deliveredSince?: string;
  deliveredUntil?: string;
  noPublished?: 0 | 1;
  noDrafts?: 0 | 1;
  noScheduled?: 0 | 1;
  noArchived?: 0 | 1;
  noMandatory?: 0 | 1;
  noNotMandatory?: 0 | 1;
  noUnarchived?: 0 | 1;
  noDelivered?: 0 | 1;
  noUndelivered?: 0 | 1;
  noFinished?: 0 | 1;
  contentTypes?: string;
  broadcastTypes?: string;
  sort: FetchDeliveriesSort;
};

export type FetchDeliveriesSort = 'coalesced_delivered_at_asc'
  | 'coalesced_delivered_at_desc'

export const useFetchDeliveries = (params: FetchDeliveriesParams): FetchResponse<Paginated<'deliveries', ListedDelivery>> => {
  return useGet('/broadcasts/deliveries', params);
};
