import { useCallback } from 'react';

import { useGoogleAnalytics } from '../../Core/Hook';

const baseGoogleEvent = {
  category: 'Broadcast Editor Beta',
};

export interface BroadcastGoogleEvents {
  trackSave: () => void,
  trackPublish: () => void,
}

export const useBroadcastGoogleEvents = (): BroadcastGoogleEvents => {
  const { trackEvent } = useGoogleAnalytics();

  const trackSave = useCallback(
    () => trackEvent({
      ...baseGoogleEvent,
      action: 'Save',
    }),
    [trackEvent],
  );

  const trackPublish = useCallback(
    () => trackEvent({
      ...baseGoogleEvent,
      action: 'Publish',
    }),
    [trackEvent],
  );

  return {
    trackSave,
    trackPublish,
  };
}
