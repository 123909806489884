import styled from '@emotion/styled';

import { Flex } from '../../../Common/Component';

export const StyledTagForm = styled.div`
  padding: 1rem;

  width: max(50%, min(40rem, 100%));

  h4 {
    margin-bottom: 0.75rem;
  }

  .MuiFormControl-root {
    margin-bottom: 1.5rem;
  }

  ${Flex} {
    margin-top: 2rem;
  }
`;
