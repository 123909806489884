import { Link as ReactRouterLink } from 'react-router-dom';
import { ComponentProps, FC } from 'react';

import { useLocationWithRelevantParameters } from '../../Hook/useLocationWithRelevantParameters';

export const LayoutLink: FC<ComponentProps<typeof ReactRouterLink>> = ({ to, ...props }) => {
  const locationDescriptor = useLocationWithRelevantParameters(to);

  return (
    <ReactRouterLink
      { ...props }
      to={ locationDescriptor }
    />
  );
};
