import { FC, FormEvent, useCallback } from 'react';
import { AuthHeader } from 'op-storybook/lib/components/AuthHeader/AuthHeader';
import { Form } from 'op-storybook/lib/components/Form/Form';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Button } from 'op-storybook/stories/components/Button/Button';
import { UsernameParser } from 'op-storybook/lib/utility/UsernameParser/UsernameParser';

import { UserIdentity } from '../../../Forms/Component/UserIdentity/UserIdentity';
import { AnonymousSettings } from '../../Hook';
import { IdentityAuthenticationIdentity } from '../../../Core/Model/Identity/Identity';
import { RequestState } from '../../../Models';
import { TokenRequestDetails, useTokenRequest } from '../../Hook/useSecurityTokenRequest';
import { CodeRateLimitMessage } from '../../Component/CodeRateLimitMessage/CodeRateLimitMessage';
import { Checkbox } from 'op-storybook/stories/components/Checkbox/Checkbox';

type Props = {
  identity: IdentityAuthenticationIdentity;
  anonymousSettings: AnonymousSettings;
  onSubmit: (requestDetails: TokenRequestDetails) => void;
  tokenRequest: ReturnType<typeof useTokenRequest>['tokenRequest'];
  remainingDurationInSeconds: number | null;
  rememberMe: boolean;
  onRememberMeChange: (rememberMe: boolean) => void;
};

export const IdentifiedUserOtpRequestPage: FC<Props> = ({
  anonymousSettings,
  identity,
  onSubmit,
  tokenRequest,
  remainingDurationInSeconds,
  rememberMe,
  onRememberMeChange,
}) => {
  const intl = useIntl();

  const whenFormSubmitted = useCallback((event: FormEvent) => {
    event.preventDefault();
    const usernameValue = identity?.authentication.method.username || '';
    onSubmit({
      ...(UsernameParser.usernameValueIsEmailAddress(usernameValue) ? { emailAddress: usernameValue } : { mobileNumber: usernameValue })
    });
  }, [identity?.authentication.method.username, onSubmit]);

  return (
    <>
      <AuthHeader
        header={ intl.formatMessage({
          description: 'Heading used on OTP request page when user has been identified after following a link from SMS or email.',
          defaultMessage: 'Hi { personFirstName }',
        }, {
          personFirstName: identity.person.firstName,
        }) }
        supportingText={ intl.formatMessage({
          description: 'Supporting text used on login page when user has been identified.',
          defaultMessage: 'Your message is waiting but we first need to confirm your identity. { touchDevice, select, true {Tap} other {Click} } the button below to receive a login code.',
        }, {
          touchDevice: 'ontouchstart' in window,
        }) }
        graphic={ {
          type: 'logo',
          props: {
            src: anonymousSettings.smallLogoUrl,
            alt: anonymousSettings.name,
          },
        } }
      />
      <Stack
        direction="column"
        align="center"
      >
        <UserIdentity
          person={ identity.person }
          username={ identity.authentication.method.username }
          css={ {
            margin: '0 auto',
          } }
        />
        <Link
          to={ '/logout' }
          replace={ true }
          css={ { margin: '0 auto' } }
        >
          <FormattedMessage
            description="Link on OTP request page when user has been identified to return to unidentified login"
            defaultMessage="Not { personFirstName }? Return to login"
            values={ {
              personFirstName: identity.person.firstName,
            } }
          />
        </Link>
      </Stack>
      <Form onSubmit={ whenFormSubmitted }>
        <Stack direction="column" gap={ 8 }>
          <div
            css={ theme => ({
              display: 'flex',
              justifyContent: 'center',
            }) }
          >
            <Checkbox
              id="remember-me"
              name="remember-me"
              variant="beside"
              checked={ rememberMe }
              onCheckedChange={ onRememberMeChange }
              label={ intl.formatMessage({
                description: 'Label for keep signed in checkbox by login button.',
                defaultMessage: 'Keep me signed in',
              }) }
              size="md"
            />
          </div>
          <Button
            type="submit"
            busy={ tokenRequest.state === RequestState.FETCHING }
            attention={ tokenRequest.state === RequestState.FAILED }
          >
            <FormattedMessage
              description="Label for send code button on OTP request page when user has been identified after following a link from SMS or email."
              defaultMessage="Send code"
            />
          </Button>
        </Stack>
      </Form>
      { remainingDurationInSeconds !== null && (
        <CodeRateLimitMessage
          remainingDurationInSeconds={ remainingDurationInSeconds }
        />
      ) }
    </>
  );
};
