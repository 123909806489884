import { FunctionComponent, useEffect, useMemo, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@mui/material';

import { Flex, StyledChip } from '..';

export type Chip = {
  id: string;
  type: string;
  label: string;
};

type Props = {
  chips: Chip[];
  onClearFiltersClicked: () => void;
  onRemoveChipClicked: (chip: Chip) => void;
};

export const FilterChips: FunctionComponent<Props> = ({
  chips,
  onClearFiltersClicked,
  onRemoveChipClicked,
}) => {
  const chipOrder = useRef<string[]>([]);
  const sortedChips = useMemo(() => (
    chips.sort((chipA, chipB) => {
      const chipAIndex = chipOrder.current.indexOf(chipId(chipA));
      const chipBIndex = chipOrder.current.indexOf(chipId(chipB));

      if (chipAIndex === -1) {
        return 1;
      }

      if (chipBIndex === -1) {
        return -1;
      }

      return chipAIndex - chipBIndex;
    })
  ), [chips]);

  useEffect(() => {
    chipOrder.current = sortedChips.map(chipId);
  }, [sortedChips]);

  return (
    <Flex gap={ 1 }>
      { sortedChips.map(chip => (
        <StyledChip
          key={ chipId(chip) }
          label={ chip.label }
          onDelete={ () => onRemoveChipClicked(chip) }
        />
      )) }
      { !!sortedChips.length && (
        <Button
          color="primary"
          onClick={ onClearFiltersClicked }
        >
          <FormattedMessage
            id="table.filters.clear"
            defaultMessage="Clear all filters"
            description="Label for button that clears all filter chips"
          />
        </Button>
      ) }
    </Flex>
  );
}

const chipId = (chip: Chip): string => `${ chip.type }_${ chip.id }`;
