import { FC, useCallback } from 'react';
import { useIntl } from 'react-intl';
import {
  AutocompleteCommonProps,
  AutocompleteFetchRequest,
  AutocompleteSelectionChanged
} from '@ourpeople/shared/Core/Component/Input/Autocomplete/Autocomplete';

import { Form, PublishedForm } from '../../Model';
import { FetchFormsParams } from '../../Hook';
import { Paginated } from '../../../Models';
import { useApi } from '../../../Core/Hook';
import { DebouncedAutocomplete } from '../../../Common/Component';

export interface FormAutocompleteProps extends AutocompleteCommonProps {
  selectedIds: string[];
  onSelectionChanged: AutocompleteSelectionChanged<Form>;
  baseQuery?: FetchFormsParams,
}

export const FormAutocomplete: FC<FormAutocompleteProps> = ({
  selectedIds,
  onSelectionChanged,
  baseQuery,
  ...props
}) => {
  const intl = useIntl();
  const api = useApi();

  const whenFetch: AutocompleteFetchRequest<Form> = async (
    ids: string[],
    search: string,
    pageNum: number,
  ) => {
    const params: FetchFormsParams = {
      sort: 'name_asc',
      ...baseQuery,
      pageNum,
      ids: ids.join(',') || undefined,
      search: search.trim() || null,
      publishStatuses: 'published',
    };
    const response = await api.get<Paginated<'forms', PublishedForm>>('/me/forms', { params });
    return {
      options: response.data.forms,
      pageCount: response.data.pagination.pageCount,
    };
  };

  return (
    <DebouncedAutocomplete<Form>
      { ...props }
      getOptionLabel={ form => form.name }
      fetchOptions={ useCallback(whenFetch, [api, baseQuery]) }
      selectedIds={ selectedIds }
      onSelectionChanged={ onSelectionChanged }
      dense={ true }
      label={
        props.label || (
          props.multiple && selectedIds.length
            ? intl.formatMessage(
              {
                id: 'formAutoComplete.activeSelectionLabel',
                description: 'Form autocomplete label when a selection is active',
                defaultMessage: '{count, plural, one {# form} other {# forms}} selected'
              },
              {
                count: selectedIds.length,
              }
            )
            : intl.formatMessage(
              {
                id: 'formAutoComplete.label',
                defaultMessage: 'Select {limit, plural, one {form} other {forms}}'
              },
              {
                limit: props.multiple ? Infinity : 1,
              }
            )
        )
      }
      placeholder={
        props.placeholder ||
        intl.formatMessage(
          {
            id: 'formAutoComplete.placeholder',
            defaultMessage: 'Search for {limit, plural, one {form} other {forms}}'
          },
          {
            limit: props.multiple ? Infinity : 1,
          }
        )
      }
    />
  );
};
