import styled from '@emotion/styled';

export const DividerContainer = styled.div`
  position: relative;

  span {
    position: absolute;
    left: ${props => props.theme.spacing(1)};
    transform: translateY(-50%);
    padding: 0 ${props => props.theme.spacing(1)};
    background: white;
  }
`;
