import { ComponentType, FunctionComponent, lazy, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { SystemPage } from './System/Components';
import { ApiClientsPage } from '../../ApiClients/Routing';
import { CreditPage } from '../../Credit/Routing';
import { RouterTabs } from '../../Common/Component';
import { TagsPermission } from '../../Tags/Model';
import { importRetry } from '../../Core/Utility';
import { SystemPermission } from '../../System/Model';
import { SmsPermission } from '../../Credit/Model';
import { IntegrationsPermission } from '../../ApiClients/Model';
import { GeneralSettingsPage } from '../../Settings/Routing/GeneralSettingsPage/GeneralSettingsPage';
import { useEnvironmentSettings } from '../../Common/Hook';
import { SmsUsagePage } from '../../Credit/Routing/SmsUsagePage/SmsUsagePage';
import { ResponsivePageHeader } from '../../Common/Component/ResponsivePageHeader/ResponsivePageHeader';
import { ResponsivePageContent } from '../../Common/Component/ResponsivePageContent/ResponsivePageContent';
import { usePermissions } from '../../Security/Hook';
import { IntegrationsPage } from '../../Integrations/Routing/Integration/IntegrationsPage';

const TagsRouter = lazy(() => importRetry<{ default: ComponentType; }>(() => import('../../Tags/Routing')));

export const Settings: FunctionComponent = () => {
  const intl = useIntl();
  const { unlimitedSmsEnabled = false, improvedIntegrationsEnabled = false } = useEnvironmentSettings();
  const { permissionAvailable } = usePermissions();

  const tabs = useMemo(() => [
    ...(permissionAvailable(SystemPermission.SETTINGS_UPDATE) ? [
        {
          title: intl.formatMessage({
            description: 'Settings > General page title.',
            defaultMessage: 'General',
          }),
          link: '/settings/general',
          render: () => <GeneralSettingsPage/>,
          permissions: [SystemPermission.SETTINGS_UPDATE],
        }
      ] : []),
    ...(permissionAvailable(SmsPermission.CREDITS) ? [
        {
          title: intl.formatMessage({
            description: 'Settings > Credits page title.',
            defaultMessage: 'Credits',
          }),
          link: '/settings/credits',
          render: () => unlimitedSmsEnabled ? <SmsUsagePage/> : <CreditPage/>,
          permissions: [SmsPermission.CREDITS],
        }
      ] : []),
    ...(permissionAvailable(IntegrationsPermission.CLIENTS_READ) ? [
        {
          title: intl.formatMessage({
            description: 'Settings > Integrations page title.',
            defaultMessage: 'Integrations',
          }),
          link: improvedIntegrationsEnabled ? '/integrations' : '/settings/api-clients',
          render: () => improvedIntegrationsEnabled ? null : <ApiClientsPage/>,
          permissions: [IntegrationsPermission.CLIENTS_READ],
        }
      ] : []),
    ...(permissionAvailable(SystemPermission.SETTINGS_SECRET) ? [
        {
          title: intl.formatMessage({
            description: 'Settings > System page title.',
            defaultMessage: 'System',
          }),
          link: '/settings/system',
          render: () => <SystemPage/>,
          permissions: [SystemPermission.SETTINGS_SECRET],
        }
      ] : []),
    ...(permissionAvailable(TagsPermission.UPDATE) ? [
        {
          title: intl.formatMessage({
            description: 'Settings > Tags page title.',
            defaultMessage: 'Tags',
          }),
          link: '/settings/tags',
          render: () => <TagsRouter/>,
          permissions: [TagsPermission.UPDATE],
        }
      ] : []),
  ], [intl, permissionAvailable, unlimitedSmsEnabled]);

  return (
    <div>
      <ResponsivePageHeader
        title=""
        variant="simple"
        breadcrumbItems={ [
          {
            title: 'Settings',
            link: '/settings'
          },
        ] }
      />
      <ResponsivePageContent>
        <RouterTabs
          useNewThemeVariant="page"
          tabs={ tabs }
        />
      </ResponsivePageContent>
    </div>
  );
};
