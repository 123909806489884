import { useCallback, useContext, useEffect, useState } from 'react';
import { AxiosError } from 'axios';

import { RemoteIntegration } from '../Types';
import { ApiContext } from '../Contexts';
import { FetchResult, RequestState } from '../Models';

type Refresh = () => void;

type Result = [
  null|FetchResult<RemoteIntegration>,
  RequestState,
  Refresh,
];

interface ResponseContent {
  remoteIntegration: RemoteIntegration;
}

export const useFetchRemoteIntegration = (): Result => {
  const api = useContext(ApiContext);
  const [requestCount, setRequestCount] = useState<number>(0);
  const [result, setResult] = useState<Result[0]>(null);
  const [requestState, setRequestState] = useState<RequestState>(RequestState.PENDING);

  useEffect(
    () => {
      if (api) {
        setRequestState(RequestState.FETCHING);
        let cancelled = false;
        api.get<ResponseContent>('/remote-integration')
          .then(response => response.data.remoteIntegration)
          .then(receivedIntegration => {
            if (!cancelled) {
              setResult(FetchResult.fromContent(receivedIntegration));
              setRequestState(RequestState.COMPLETE);
            }
          })
          .catch((error: AxiosError) => {
            if (!cancelled) {
              setResult(FetchResult.fromError(error));
              setRequestState(RequestState.FAILED);
            }
          });
        return () => { cancelled = true };
      }
    },
    [api, requestCount]
  );

  const refresh = useCallback(() => setRequestCount(requestCount + 1), [requestCount]);

  return [result, requestState, refresh];
}
