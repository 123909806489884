import styled from '@emotion/styled';

export const StyledHeaderImage = styled.img<{ visible: boolean; fit: 'contain' | 'cover' }>`
  object-fit: ${ props => props.fit };
  object-position: center;
  height: 100%;
  width: 100%;

  ${ props => !props.visible &&`
    position: absolute;
    opacity: 0;
    pointer-events: none;
  ` }
`;

export const ImageContainer = styled.div`
  height: 15.575vh;
  width: 100%;
`;

export const LoadingContainer = styled.div`
  background-color: ${ props => props.theme.palette.grey[200] };
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
