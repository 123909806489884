import { FC } from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';

import { FormsPermission } from '../../Model';
import { PersonalFormsPage } from '../PersonalFormsPage/PersonalFormsPage';
import { GuardedRoute } from '../../../Common/Component';
import { FormContentDefinitionRegistryProvider } from '../../Provider';
import { useEnvironmentSettings } from '../../../Common/Hook';

export const PersonalFormsRouter: FC = () => {
  const match = useRouteMatch();
  const { formsEnabled } = useEnvironmentSettings();

  return (
    <Switch>
      <GuardedRoute
        path={ `${ match.path }/:formId?` }
        condition={ formsEnabled }
        redirect={ '/' }
        permissions={ [FormsPermission.ME] }
      >
        <FormContentDefinitionRegistryProvider>
          <PersonalFormsPage/>
        </FormContentDefinitionRegistryProvider>
      </GuardedRoute>
    </Switch>
  );
};
