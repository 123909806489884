import { ChangeEventHandler, FC, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { StyledDateInput } from '../DateRequestPreview/style';
import { DateRequest as DateRequestModel, DraftDateRequest } from '../../../Model/ContentTypes';
import { ValidationTree } from '../../../../Common/Model';
import { DraftDateResponse } from '../../../Service';
import { useDescendantErrors } from '../../../../Common/Hook';
import { useLocalisedResponseErrors } from '../../../Hook';
import { FormResponseErrors } from '../..';

type Props = {
  value: string | undefined;
  content: DateRequestModel | DraftDateRequest;
};

type ReadOnlyProps = Props & {
  readOnly: true;
  onChange?: never;
  validation?: never;
  displayValidation?: false;
};

type EditableProps = Props & {
  readOnly?: false;
  onChange: (value: string | undefined) => void;
  validation?: ValidationTree<DraftDateResponse>;
  displayValidation?: boolean;
};
export const DateRequest: FC<ReadOnlyProps | EditableProps> = ({
  value,
  onChange,
  content,
  validation,
  displayValidation = false,
  readOnly,
}) => {
  const intl = useIntl();
  const errors = useDescendantErrors(validation);
  const localisedErrors = useLocalisedResponseErrors(errors);

  const inputPlaceholderDateAndTime = useMemo(() => {
    return intl.formatMessage({
      description: 'Form date & time picker placeholder.',
      defaultMessage: 'Select a date and time',
    })
  }, [intl]);

  const inputPlaceholderDateOnly = useMemo(() => {
    return intl.formatMessage({
      description: 'Form date only picker placeholder.',
      defaultMessage: 'Select a date',
    })
  }, [intl]);

  const whenChanged: ChangeEventHandler<HTMLInputElement> = useCallback(event => {
    !readOnly && onChange(event.currentTarget.value);
  }, [onChange, readOnly]);

  return (
    <>
      <StyledDateInput
        color="secondary"
        disabled={ readOnly }
        type={ content.includeTime ? 'datetime-local' : 'date' }
        onChange={ whenChanged }
        placeholder={ content.includeTime ? inputPlaceholderDateAndTime : inputPlaceholderDateOnly }
        value={ value || '' }
        variant={ 'outlined' }
        margin={ 'dense' }
        required={ readOnly }
        fullWidth
      />
      { displayValidation && <FormResponseErrors localisedErrors={ localisedErrors }/> }
    </>
  );
};
