import { FormControl, MenuItem, Select, TextField } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { IntlShape } from 'react-intl/src/types';

import { MappedSettingTypes } from '../../Types';
import { StyledDurationSettingInput } from './styles';
import { DurationParser } from '../../../../../Utility/DurationParser';
import { DurationSettingUnit } from '../../../../../Models/DurationSetting';
import { ValidationErrorMessage } from '../../../../../Common/Component';

type Props = {
  config: MappedSettingTypes['duration']['config'],
  value: MappedSettingTypes['duration']['value'],
  onValueChanged: (value: MappedSettingTypes['duration']['value']) => void,
  focusId?: string,
};

export const DurationSettingInput = ({
  config,
  value,
  onValueChanged,
  focusId,
}: Props): JSX.Element => {
  const intl = useIntl();
  const [error, setError] = useState<string | null>(null);
  const durationUnits: DurationSettingUnit[] = ['second', 'minute', 'hour', 'day', 'week'];

  useEffect(() => {
    const error = validate(value, config, intl);
    setError(error);
  }, [value, config, intl]);

  const whenAmountChanged = (event: ChangeEvent<HTMLInputElement>): void => {
    onValueChanged({
      unit: value?.unit || 'second',
      amount: +event.target.value,
    });
  }

  const whenUnitChanged = (event: ChangeEvent<{ name?: string | undefined; value: unknown; }>): void => {
    if (DurationParser.unitIsValid(event.target.value)) {
      onValueChanged({
        unit: event.target.value,
        amount: value?.amount || 1,
      });
    }
  }

  return (
    <div>
      <StyledDurationSettingInput>
        <TextField
          type="number"
          id={focusId}
          variant="outlined"
          fullWidth={true}
          value={value?.amount || ''}
          label={
            intl.formatMessage({
              id: 'settings.durationSetting.amountLabel',
              defaultMessage: 'Amount',
            })
          }
          onChange={whenAmountChanged}
          inputProps={{
            step: 1,
            min: 0,
            max: config.maxSeconds,
          }}
        />
        <FormControl variant="outlined">
          <Select
            value={value?.unit || durationUnits[0]}
            autoWidth={true}
            onChange={whenUnitChanged}
            MenuProps={{
              anchorOrigin: {
                horizontal: 'left',
                vertical: 'bottom',
              },
            }}
          >
            {
              durationUnits.map((unit) => (
                <MenuItem key={unit} value={unit}>
                  { DurationParser.localiseUnit(unit, intl) }
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </StyledDurationSettingInput>
      {
        error
        && (
          <ValidationErrorMessage>{error}</ValidationErrorMessage>
        )
      }
    </div>
  );
};

const validate = (
  value: MappedSettingTypes['duration']['value'],
  config: MappedSettingTypes['duration']['config'],
  intl: IntlShape,
): string | null => {
  if (value === null && config.nullable) {
    return null;
  }

  if (value === null) {
    return intl.formatMessage({
      id: 'settings.durationSetting.nullError',
      defaultMessage: 'Missing value',
    });
  }

  const durationInSeconds = DurationParser.durationInSeconds(value);

  if (config.minSeconds !== null && durationInSeconds < config.minSeconds) {
    return intl.formatMessage(
      {
        id: 'settings.durationSetting.minSecondsError',
        defaultMessage: 'Must be at least {minSeconds} seconds, {actualSeconds} given',
      },
      {
        minSeconds: config.minSeconds,
        actualSeconds: durationInSeconds,
      }
    );
  }

  if (config.maxSeconds !== null && durationInSeconds > config.maxSeconds) {
    return intl.formatMessage(
      {
        id: 'settings.durationSetting.maxSecondsError',
        defaultMessage: 'Must be no more than {maxSeconds} seconds, {actualSeconds} given',
      },
      {
        maxSeconds: config.maxSeconds,
        actualSeconds: durationInSeconds,
      }
    );
  }

  return null;
}
