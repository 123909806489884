import { FC } from 'react';

import { StyledAttribution, StyledAttributionLinks, StyledImageAsset } from '../ImageAssetPicker/style';
import { Flex } from '..';
import { ImageAsset as ImageAssetModel } from '../../Hook';

type Props = {
  imageAsset: ImageAssetModel['image'];
  onImageAssetSelected: () => void;
  selected?: boolean;
};

export const ImageAsset: FC<Props> = ({
  imageAsset,
  onImageAssetSelected,
  selected = false,
}) => {
  return (
    <StyledImageAsset
      selected={ selected }
      aspectRatio={ imageAsset.width / imageAsset.height }
      onClick={ onImageAssetSelected }
    >
      <img
        id={ imageAsset.id }
        src={ imageAsset.urls.thumbs.m.url }
        alt={ imageAsset.description }
      />
      <StyledAttribution>
        <ul>
          { imageAsset.attributions.map((attribution, index) => (
            <li key={ index }>
              <span>
                { attribution.name }
              </span>
              <StyledAttributionLinks>
                { attribution.links.map((link, index) => (
                  <li key={ index }>
                    <Flex gap={ 0.5 }>
                      <a
                        onClick={ event => event.stopPropagation() }
                        href={ link.url }
                        target="_blank"
                        rel="noreferrer"
                      >
                        { link.label }
                      </a>
                      { index !== attribution.links.length - 1 && (
                        <span>•</span>
                      ) }
                    </Flex>
                  </li>
                )) }
              </StyledAttributionLinks>
            </li>
          )) }
        </ul>
      </StyledAttribution>
    </StyledImageAsset>
  );
};
