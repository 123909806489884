import { FC, Fragment, useCallback, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Popover } from '@mui/material';
import { BreakpointContext } from 'op-storybook/lib/providers/BreakpointProvider/BreakpointProvider';
import { ApiRequest } from '@ourpeople/shared/Core/Model/ApiRequest';
import { Button } from 'op-storybook/stories/components/Button/Button';
import { PresentationIcon } from 'op-storybook/lib/components/PresentationIcon/PresentationIcon';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { getDescendantErrors } from 'op-storybook/lib/utility/getDescendantErrors';

import { CardErrors, EditorContainer, EditorNav, StyledEditorContent, } from './style';
import { ConditionalTooltip, Flex, FlexPullRight } from '../../../Common/Component';
import { DraftSingleContentCard, EditorContent } from '../../Model';
import AlertIcon from '../../../Assets/img/icons/monochrome/alert.svg';
import { ValidationTree } from '../../../Common/Model';
import { ContentNav } from '../ContentNav/ContentNav';
import ShowIcon from '../../../Assets/img/icons/streamline/view-1.svg';
import HideIcon from '../../../Assets/img/icons/streamline/view-off.svg';
import { AspectRatioPreview, ContentFields } from '..';
import { useContentDefinitionRegistry } from '../../Hook';
import { ValidationErrorIdentifier } from '../../../Common/Utility/ValidationErrorIdentifier';
import { useContextOrThrow } from '../../../Core/Hook';
import { RequestState } from '../../../Models';

type Props<T extends EditorContent> = {
  activeContentIndex: number,
  lockFirstIndex: boolean;
  editorContents: T[];
  onContentAdded: (content: T, atIndex: number) => void;
  onContentUpdated: (content: T, atIndex: number) => void;
  onContentMoved: (fromIndex: number, toIndex: number) => void;
  onContentRemoved: (index: number) => void;
  onContentSelected: (index: number) => void;
  validation?: ValidationTree<DraftSingleContentCard[]>;
  onValidationChanged: (validation: ValidationTree<DraftSingleContentCard[]>) => void;
  renderContent: (activeCardIndex: number) => JSX.Element;
  ContentMenu: FC<{ onContentCreated: (editorContent: T) => void }>;
  renderTooFewError: (min: number) => void;
  renderTooManyError: (max: number) => void;
  maxContentCount?: number;
  contentMenuInitiallyOpen?: boolean;
  preview?: {
    request: ApiRequest<void>;
    onClick: () => void;
  };
};

export const ContentEditor = <T extends EditorContent>({
  activeContentIndex,
  lockFirstIndex,
  editorContents,
  onContentAdded,
  onContentUpdated,
  onContentMoved,
  onContentRemoved,
  onContentSelected,
  validation,
  onValidationChanged,
  renderContent,
  ContentMenu,
  renderTooFewError,
  renderTooManyError,
  maxContentCount,
  contentMenuInitiallyOpen = false,
  preview,
}: Props<T>): JSX.Element => {
  const intl = useIntl();
  const screenWidth = useContextOrThrow(BreakpointContext);
  const contentDefinitionRegistry = useContentDefinitionRegistry();
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [showFields, setShowFields] = useState<boolean>(false);
  const activeContent = useMemo(() => editorContents[activeContentIndex], [activeContentIndex, editorContents]);
  const cardsWithErrorsCount = useMemo(() => (
    Object.values(validation?.children || {}).reduce<number>(
      (total, cardValidation) => (
        total + ((cardValidation ? getDescendantErrors(cardValidation).length > 0 : false) ? 1 : 0)
      ),
      0,
    )
  ), [validation]);
  const [contentMenuOpen, setContentMenuOpen] = useState<boolean>(contentMenuInitiallyOpen);
  const addButtonRef = useRef<HTMLButtonElement>(null);
  const contentCloningDisabled = useMemo(
    () => !activeContent || contentDefinitionRegistry.cloningDisabled(activeContent),
    [activeContent, contentDefinitionRegistry],
  );

  const whenAddButtonClicked = (): void => {
    setContentMenuOpen(true);
  };

  const whenContentCreated = (content: T): void => {
    if (!contentDefinitionRegistry) {
      return;
    }

    const newContentIndex = editorContents.length ? activeContentIndex + 1 : 0;
    onContentAdded(content, newContentIndex);
    setShowFields(true);
    setContentMenuOpen(false);
  };

  const whenCopyButtonClicked = (): void => {
    if (!activeContent || !contentDefinitionRegistry || (maxContentCount && editorContents.length >= maxContentCount)) {
      return;
    }

    onContentAdded(contentDefinitionRegistry.cloneEditorContent(activeContent), activeContentIndex + 1);
  };

  const whenDeleteButtonClicked = (): void => {
    onContentRemoved(activeContentIndex);
  };

  const whenActiveContentUpdated = useCallback((updatedContent: T): void => {
    onContentUpdated(
      updatedContent,
      activeContentIndex,
    );
  }, [onContentUpdated, activeContentIndex]);

  const whenIndexMoved = (source: number, destination: number): void => {
    onContentMoved(source, destination);
  };

  const whenTogglePreviewClicked = (): void => {
    setShowPreview(showPreview => !showPreview);
  };

  const whenCardAtIndexClicked = useCallback(
    (index: number): void => {
      onContentSelected(index);
      setShowFields(true);
    },
    [onContentSelected],
  );

  const whenCardValidationChanged = useCallback((index: number, cardValidation: ValidationTree<DraftSingleContentCard>) => {
    return (
      onValidationChanged({
        errors: validation?.errors || [],
        children: {
          ...validation?.children,
          [index]: cardValidation,
        },
      })
    )
  }, [onValidationChanged, validation]);

  return (
    <EditorContainer>
      <EditorNav>
        { screenWidth.lessThan.sm && (
          <Button
            variant="tertiary"
            onClick={ whenTogglePreviewClicked }
          >
            <Stack>
              <PresentationIcon IconComponent={ showPreview ? HideIcon : ShowIcon }/>
              {
                showPreview
                  ? (
                    <FormattedMessage
                      id="content.hidePreviewButtonLabel"
                      description="Label for hide preview button in editor."
                      defaultMessage="Hide preview"
                    />
                  )
                  : (
                    <FormattedMessage
                      id="content.showPreviewButtonLabel"
                      description="Label for show preview button in editor."
                      defaultMessage="Show preview"
                    />
                  )
              }
            </Stack>
          </Button>
        ) }
        { !screenWidth.lessThan.sm && preview && (
          <Button
            variant="tertiary"
            onClick={ preview.onClick }
            busy={ preview.request.state === RequestState.FETCHING }
            attention={ preview.request.state === RequestState.FAILED }
          >
            <Stack>
              <PresentationIcon
                IconComponent={ ShowIcon }
              />
              <FormattedMessage
                id="content.sendPreviewButtonLabel"
                description="Label for send preview button in editor."
                defaultMessage="Send preview"
              />
            </Stack>
          </Button>
        ) }
        { !!(cardsWithErrorsCount || (validation?.errors || []).length) && (
          <CardErrors>
            <AlertIcon/>
            {
              !!(validation?.errors || []).length
                ? (
                  validation?.errors.map(error => (
                    <Fragment key={ error.type }>
                      <>
                        {
                          ValidationErrorIdentifier.isTooFewError(error)
                            ? renderTooFewError(error.metadata.minCount)
                            : ValidationErrorIdentifier.isTooManyError(error)
                              ? renderTooManyError(error.metadata.maxCount)
                              : (
                                <FormattedMessage
                                  id="contentEditor.generalContentError"
                                  description="Error in editor when content has a general error."
                                  defaultMessage="There is a problem with your content."
                                />
                              )
                        }
                      </>
                    </Fragment>
                  ))
                )
                : (
                  <span>
                    <FormattedMessage
                      id="content.cardsHaveErrors"
                      description="Error message when one or more cards have validation errors"
                      defaultMessage="{count, plural, one {# invalid card} other {# invalid cards}}"
                      values={ {
                        count: cardsWithErrorsCount,
                      } }
                    />
                  </span>
                )
            }
          </CardErrors>
        ) }
        <FlexPullRight>
          <Flex gap={ 2 }>
            <span>
              <FormattedMessage
                id="content.editor.cardCount"
                description="Card count next to create button in content editor."
                defaultMessage="{ cardCount, plural, =0 {No cards} one {# card} other {# cards} }"
                values={ { cardCount: editorContents.length } }
              />
            </span>
            <ConditionalTooltip
              title={ intl.formatMessage({
                id: 'content.editor.atCardLimit',
                description: 'Notice when at maximum cards in editor',
                defaultMessage: 'A maximum of { max, plural, one {# card is} other {# cards are} } can be added.'
              }, {
                max: maxContentCount,
              }) }
              active={ !!maxContentCount && editorContents.length >= maxContentCount }
            >
              <Button
                onClick={ whenAddButtonClicked }
                variant="primary"
                ref={ addButtonRef }
                disabled={ !!maxContentCount && editorContents.length >= maxContentCount }
              >
                <FormattedMessage
                  id="content.addContentButtonLabel"
                  description="Label for add content button in editor."
                  defaultMessage="Add content"
                />
              </Button>
            </ConditionalTooltip>
          </Flex>
        </FlexPullRight>
      </EditorNav>
      <StyledEditorContent>
        { (!screenWidth.lessThan.sm || showPreview) && (
          <AspectRatioPreview>
            { renderContent(activeContentIndex) }
          </AspectRatioPreview>
        ) }
        { !!activeContent && (!screenWidth.lessThan.md || (showFields && !showPreview)) && (
          <ContentFields
            removeButtonDisabled={ activeContentIndex === 0 && lockFirstIndex }
            content={ activeContent }
            onContentChanged={ whenActiveContentUpdated }
            validation={ validation?.children[activeContentIndex] }
            onValidationChanged={ validation => whenCardValidationChanged(activeContentIndex, validation) }
            onBackButtonClicked={ () => setShowFields(false) }
            onDuplicateButtonClicked={ whenCopyButtonClicked }
            duplicateButtonDisabled={ contentCloningDisabled || (!!maxContentCount && editorContents.length >= maxContentCount) }
            onRemoveButtonClicked={ whenDeleteButtonClicked }
          />
        ) }
        { (!screenWidth.lessThan.md || (!showPreview && !showFields)) && (
          <ContentNav
            contents={ editorContents }
            validation={ validation }
            onContentAtIndexClicked={ whenCardAtIndexClicked }
            onIndexMoved={ whenIndexMoved }
            activeCardIndex={ activeContentIndex }
            lockFirstIndex={ lockFirstIndex }
          />
        ) }
      </StyledEditorContent>
      { addButtonRef.current && (
        <Popover
          open={ contentMenuOpen }
          onClose={ () => setContentMenuOpen(false) }
          anchorOrigin={ {
            vertical: 'bottom',
            horizontal: 'right',
          } }
          transformOrigin={ {
            vertical: 'top',
            horizontal: 'right',
          } }
          anchorEl={ addButtonRef.current }
        >
          <ContentMenu onContentCreated={ whenContentCreated }/>
        </Popover>
      ) }
    </EditorContainer>
  );
};

