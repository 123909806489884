import styled from '@emotion/styled';
import { IconButton } from '@mui/material';

export const StyledFieldButton = styled(IconButton)`
  font-size: ${ props => props.theme.fontSize.md };
  margin-inline-start: -${ props => props.theme.new.spacing[4] };
  border-radius: 0;
  margin-inline-end: -${ props => props.theme.new.spacing[2] };

  &:focus {
    background-color: ${ props => props.theme.new.palette.grey[50].main };
  }
`;
