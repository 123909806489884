import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ListItem } from '@mui/material';
import { Link } from 'react-router-dom';

import { SortableHeaderCell, TableCell, TableRow, UserStatus } from '../../../../../Components';
import { StyledResponseTable } from '../styles';
import { Picker } from '../../../../../Components/Input/Picker/Picker';
import { useFetchReportStatusCounts } from '../../Hooks/useFetchReportStatusCounts';
import { RequestState } from '../../../../../Models';
import { StandardReportTableInput } from './StandardReportTableInput';
import { FilterContainer, FilterRow } from '../../../../../Common/Component';
import {
  RecipientNotifications
} from '../../../../../Broadcasts/Component/Reporting/RecipientNotifications/RecipientNotifications';
import {
  NotificationMethodFilter, NotificationMethodFilterValue
} from '../../../../../Broadcasts/Component/Reporting/NotificationMethodFilter/NotificationMethodFilter';
import { ReportRecipient, ResponseTable } from '../../../../../Broadcasts/Component';

interface Props {
  broadcastId: string;
  responseType: 'notSeen' | 'notResponded';
  deliveryId?: string;
  contentId?: string;
  initialSearch?: string;
}

type StatusFilterValue = null | 'active' | 'inactive' | 'frozen';

export const NotRespondedTable: FunctionComponent<Props> = ({
  broadcastId,
  deliveryId,
  responseType,
  contentId,
  initialSearch = '',
}) => {
  const intl = useIntl();
  const [sort, setSort] = useState<string>('firstNameAsc');
  const [search, setSearch] = useState<string>(initialSearch);
  const [statusFilter, setStatusFilter] = useState<StatusFilterValue>(null);
  const [notificationMethodFilter, setNotificationMethodFilter] = useState<NotificationMethodFilterValue>(null);
  const params = useMemo(() => ({
    deliveryId: deliveryId || null,
    contentId: contentId || null,
    optionId: null,
    rating: null,
    responseType,
    sort,
    relativeStatus: statusFilter || 'active,inactive,frozen',
    broadcastReceivedNotificationMethods: notificationMethodFilter,
    search: search || null,
  }), [contentId, search, deliveryId, responseType, sort, statusFilter, notificationMethodFilter]);
  const statusFilterOptions: StatusFilterValue[] = [null, 'active', 'inactive', 'frozen'];
  const [reportCountsResult, fetchState] = useFetchReportStatusCounts(
    broadcastId,
    responseType,
    contentId,
    deliveryId,
    search,
  );
  const reportCounts = useMemo(() => reportCountsResult?.content, [reportCountsResult?.content]);

  const getLocalisedStatusFilter = useCallback(
    (value: StatusFilterValue): string => {
      const inactiveCount = reportCounts?.counts.relativeStatus.inactive || 0;
      const activeCount = reportCounts?.counts.relativeStatus.active || 0;
      const frozenCount = reportCounts?.counts.relativeStatus.frozen || 0;
      const loading = fetchState < RequestState.COMPLETE;

      switch (value) {
        case null:
          return intl.formatMessage({
            id: 'broadcastReport.status.all',
            description: 'Default value for status filter in report table',
            defaultMessage: 'Show all statuses {loading, select, false {({count})} other {}}',
          }, {
            count: activeCount + inactiveCount + frozenCount,
            loading,
          });
        case 'active':
          return intl.formatMessage({
            id: 'broadcastReport.status.active',
            description: 'Value for active status filter in report table',
            defaultMessage: 'Show active {loading, select, false {({count})} other {}}',
          }, {
            count: activeCount,
            loading,
          });
        case 'inactive':
          return intl.formatMessage({
            id: 'broadcastReport.status.inactive',
            description: 'Value for inactive status filter in report table',
            defaultMessage: 'Show inactive {loading, select, false {({count})} other {}}',
          }, {
            count: inactiveCount,
            loading,
          });
        case 'frozen':
          return intl.formatMessage({
            id: 'broadcastReport.status.frozen',
            description: 'Value for frozen status filter in report table',
            defaultMessage: 'Show frozen {loading, select, false {({count})} other {}}',
          }, {
            count: frozenCount,
            loading,
          });
      }
      return '';
    },
    [
      intl,
      fetchState,
      reportCounts?.counts?.relativeStatus?.active,
      reportCounts?.counts?.relativeStatus?.inactive,
      reportCounts?.counts?.relativeStatus?.frozen,
    ],
  );

  return (
    <StyledResponseTable>
      <FilterContainer>
        <FilterRow>
          <Picker
            buttonLabel={ getLocalisedStatusFilter(statusFilter) }
            items={ statusFilterOptions }
            selectedItems={ [statusFilter] }
            closeOnSelect
            allowEmptySelection={ false }
            itemRenderFn={ (statusFilterValue, selected) => (
              <ListItem
                dense
                key={ statusFilterValue || 'all' }
                selected={ selected }
                onClick={ () => setStatusFilter(statusFilterValue) }
              >
                { getLocalisedStatusFilter(statusFilterValue) }
              </ListItem>
            ) }
            itemCompareFn={ (valueA, valueB) => valueA === valueB }
          />
          <NotificationMethodFilter value={notificationMethodFilter} onChanged={setNotificationMethodFilter} />
          <StandardReportTableInput
            searchValue={search}
            onSearchChanged={setSearch}
          />
        </FilterRow>
      </FilterContainer>
      <ResponseTable
        broadcastId={ broadcastId }
        reportRecipientsParams={params}
        headerRow={
          <TableRow>
            <SortableHeaderCell
              sort={sort}
              ascValue="firstNameAsc"
              descValue="firstNameDesc"
              onSort={setSort}
            >
              <FormattedMessage
                id="broadcastReport.recipientsTable.headers.firstName"
                defaultMessage="First name"
              />
            </SortableHeaderCell>
            <SortableHeaderCell
              sort={sort}
              ascValue="lastNameAsc"
              descValue="lastNameDesc"
              onSort={setSort}
            >
              <FormattedMessage
                id="broadcastReport.recipientsTable.headers.lastName"
                defaultMessage="Last name"
              />
            </SortableHeaderCell>
            <SortableHeaderCell
              sort={sort}
              ascValue="statusAsc"
              descValue="statusDesc"
              onSort={setSort}
            >
              <FormattedMessage
                id="broadcastReport.recipientsTable.headers.status"
                defaultMessage="Status"
              />
            </SortableHeaderCell>
            <TableCell>
              <FormattedMessage
                id="broadcastReport.recipientsTable.headers.notifications"
                defaultMessage="Notification channel"
              />
            </TableCell>
          </TableRow>
        }
        rowRender={ (recipient: ReportRecipient<Record<string, unknown>>) => (
          <TableRow key={ recipient.id }>
            <TableCell>
              <Link to={`/people/${ recipient.user.id }/account`}>
                { recipient.user.firstName }
              </Link>
            </TableCell>
            <TableCell>
              <Link to={`/people/${ recipient.user.id }/account`}>
                { recipient.user.lastName }
              </Link>
            </TableCell>
            <TableCell>
              <UserStatus status={ recipient.relativeStatus }/>
            </TableCell>
            <TableCell>
              <RecipientNotifications notifications={recipient.broadcastReceivedNotifications} />
            </TableCell>
          </TableRow>
        ) }
      />
    </StyledResponseTable>
  );
}
