import { ComponentProps, forwardRef, PropsWithChildren, Ref, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { PaletteKey } from 'op-storybook/lib/types/Theme/Theme';

import { StyledAnchorBase, StyledButtonBase, StyledLinkBase } from './style';

export type ButtonBaseVariant = 'filled-light' | 'filled-dark' | 'secondary-grey' | 'secondary-colour' | 'tertiary-light' | 'tertiary-dark';

type Props = {
  component?: 'button' | 'a' | 'link';
  colour?: PaletteKey;
  fillParent?: boolean;
  variant?: ButtonBaseVariant;
  active?: boolean;
  padding?: 'none' | 'standard' | 'uniform';
  ref?: Ref<HTMLElement>;
  disabled?: boolean;
  linkProps?: ComponentProps<typeof Link>;
  anchorProps?: ComponentProps<'a'>;
} & ComponentProps<'button'>;

/** @deprecated Prefer ButtonBase from op-storybook project */
export const ButtonBase = forwardRef<HTMLElement, PropsWithChildren<Props>>(({
  colour = 'primary',
  variant = 'filled-light',
  fillParent = false,
  active = false,
  padding = 'standard',
  disabled = false,
  component = 'button',
  linkProps = { to: '' },
  anchorProps,
  children,
  ...props
}, ref) => {
  const sharedProps = useMemo(() => ({
    colour,
    variant,
    fillParent,
    active,
    padding,
    disabled,
    children,
  }), [active, children, colour, disabled, fillParent, padding, variant]);

  return (
    component === 'button'
      ? (
        <StyledButtonBase
          { ...sharedProps }
          { ...props }
          ref={ ref as Ref<HTMLButtonElement> }
        />
      )
      : component === 'a'
        ? (
          <StyledAnchorBase
            { ...sharedProps }
            { ...anchorProps }
            ref={ ref as Ref<HTMLAnchorElement> }
          />
        )
        : (
          <StyledLinkBase
            { ...sharedProps }
            { ...linkProps }
            ref={ ref as Ref<HTMLAnchorElement> }
          />
        )
  );
});
