import { FC, useState } from 'react';
import { parseISO } from 'date-fns';

import { LiveComponentProps } from '../../../../Content/Model';
import { SignRequest } from '../../../../Broadcasts/Service';
import { SignResponse } from '../../../Model';
import { BroadcastContentContext } from '../../../../Broadcasts/Model';
import { useRecallErrorHandler, useRespondButtonProps } from '../../../Hook';
import { useLoggedInAuthDescription } from '../../../../Core/Hook';
import { TextFormatter } from '../../../../Utility';
import { SignBroadcastContent } from '../../../../Content/Component';

export const LiveSignContent: FC<LiveComponentProps<SignRequest, SignResponse, BroadcastContentContext>> = ({
  card: { content },
  response,
  context,
  onResponseChange,
  onReloadRequired,
}) => {
  const [signedAt, setSignedAt] = useState<Date | null>(response ? parseISO(response.createdAt) : null);
  const { user } = useLoggedInAuthDescription();
  const submitProps = useRespondButtonProps({
      contentId: content.id.toString(),
      deliveryId: context.deliveryId,
      type: 'sign',
    },
    onResponseChange,
    useRecallErrorHandler(onReloadRequired),
    response,
  );

  return (
    <SignBroadcastContent
      backButton={ context.closeButton }
      title={ content.text }
      signature={ TextFormatter.spaceSeparated(user.forename || '', user.lastname || '') }
      signedAt={ signedAt }
      submitProps={ submitProps }
      navigationProps={ context.navigationProps }
      onSignedAtChanged={ setSignedAt }
      responded={ !!response }
      nudge={ context.nudge }
    />
  );
};
