import { FunctionComponent, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { FetchInvitationsParams, useFetchInvitations } from '../../Hook';
import { PaginatedTable, TableCell, TableRow } from '../../../Components';
import { useQueryAsState } from '../../../Hooks';
import { DateTime, DeprecatedLink } from '../../../Common/Component';
import { InvitationMenu, InvitationStatusChip, InvitationTableFilters } from '..';
import { StyledCheckIcon, StyledCrossIcon } from './style';
import { PersonParser } from '../../../Utility';

export const InvitationList: FunctionComponent = () => {
  const intl = useIntl();
  const [query, setQuery] = useQueryAsState();
  const pageNum = query.pageNum && !isNaN(+query.pageNum) ? +query.pageNum : 1;
  const params = useMemo<FetchInvitationsParams>(() => ({
    pageNum,
    statuses: query.statuses,
    noSms: query.types && query.types.includes('email') && !query.types.includes('sms') ? 1 : 0,
    noEmail: query.types && query.types.includes('sms') && !query.types.includes('email') ? 1 : 0,
  }), [pageNum, query.statuses, query.types]);
  const [fetchInvitationsResponse, fetchInvitationsRequestState, refreshInvitations] = useFetchInvitations(params);
  const invitationAndRecipientCounts = fetchInvitationsResponse?.content?.invitations || [];
  const pagination = fetchInvitationsResponse?.content?.pagination;

  const whenPageChanged = (pageNum: number) => {
    setQuery(query => ({
      ...query,
      pageNum: pageNum.toString(),
    }));
  };

  return (
    <>
      <InvitationTableFilters/>
      <PaginatedTable
        rows={ invitationAndRecipientCounts }
        rowRender={ invitationAndRecipientCount => (
          <TableRow key={ invitationAndRecipientCount.invitation.id }>
            <TableCell>
              <DeprecatedLink to={`/people/invitations/${invitationAndRecipientCount.invitation.id}/report`}>
                { invitationAndRecipientCount.recipientCount }
              </DeprecatedLink>
            </TableCell>
            <TableCell>
              <DeprecatedLink to={`/people/${invitationAndRecipientCount.invitation.created.by.id}`}>
                { PersonParser.fullName(invitationAndRecipientCount.invitation.created.by) }
              </DeprecatedLink>
            </TableCell>
            <TableCell>
              <DateTime dateTime={ invitationAndRecipientCount.invitation.created.at }/>
            </TableCell>
            <TableCell><InvitationStatusChip status={ invitationAndRecipientCount.invitation.status }/></TableCell>
            <TableCell>
              {
                invitationAndRecipientCount.invitation.smsId
                  ? (
                    <StyledCheckIcon
                      title={ intl.formatMessage({
                        id: 'people.invitations.smsSent',
                        defaultMessage: 'SMS invite sent',
                      }) }
                    />
                  )
                  : (
                    <StyledCrossIcon
                      title={ intl.formatMessage({
                        id: 'people.invitations.smsNotSent',
                        defaultMessage: 'SMS invite not sent',
                      }) }
                    />
                  )
              }
            </TableCell>
            <TableCell>
              {
                invitationAndRecipientCount.invitation.emailId
                  ? (
                    <StyledCheckIcon
                      title={ intl.formatMessage({
                        id: 'people.invitations.emailSent',
                        defaultMessage: 'Email invite sent',
                      }) }
                    />
                  )
                  : (
                    <StyledCrossIcon
                      title={ intl.formatMessage({
                        id: 'people.invitations.emailNotSent',
                        defaultMessage: 'Email invite not sent',
                      }) }
                    />
                  )
              }
            </TableCell>
            <TableCell>
              <InvitationMenu
                showCancel={ invitationAndRecipientCount.invitation.status === 'pending' }
                invitationId={ invitationAndRecipientCount.invitation.id }
                onInvitationCancelled={ refreshInvitations }
              />
            </TableCell>
          </TableRow>
        ) }
        pageNum={ pageNum }
        onPageChanged={ whenPageChanged }
        headerRow={
          <TableRow>
            <TableCell>
              <FormattedMessage
                id="people.invitations.recipientCount"
                defaultMessage="Recipient count"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                id="people.invitations.createdBy"
                defaultMessage="Created by"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                id="people.invitations.createdAt"
                defaultMessage="Created at"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                id="people.invitations.status"
                defaultMessage="Status"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                id="people.invitations.sms"
                defaultMessage="SMS"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                id="people.invitations.email"
                defaultMessage="Email"
              />
            </TableCell>
            <TableCell/>
          </TableRow>
        }
        requestState={ fetchInvitationsRequestState }
        onRetryClicked={ refreshInvitations }
        pagination={ pagination }
      />
    </>
  );
};
