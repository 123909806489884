import { FC, useCallback, useState } from 'react';

import { LiveComponentProps } from '../../../../Content/Model';
import { RatingRequest } from '../../../../Broadcasts/Service';
import { BroadcastContentContext } from '../../../../Broadcasts/Model';
import { RatingResponse } from '../../../Model';
import { useRecallErrorHandler, useRespondButtonProps } from '../../../Hook';
import { RatingBroadcastContent } from '../../../../Content/Component';

export const LiveRatingContent: FC<LiveComponentProps<RatingRequest, RatingResponse, BroadcastContentContext>> = ({
  card: { content },
  response,
  context,
  onResponseChange,
  onReloadRequired,
}) => {
  const [partialResponse, setPartialResponse] = useState<Omit<RatingResponse, 'createdAt'>>({
    contentId: content.id.toString(),
    deliveryId: context.deliveryId,
    type: 'rating',
    input: {
      rating: response?.input.rating || NaN,
    },
  });

  const submitButtonProps = useRespondButtonProps(
    partialResponse,
    onResponseChange,
    useRecallErrorHandler(onReloadRequired),
    response,
    useCallback((response: RatingResponse) => ({
      type: response.type,
      content: response.contentId,
      deliveryId: response.deliveryId,
      time_created: response.createdAt,
      value: response.input.rating,
    }), []),
  );

  const whenRatingChanged = useCallback((rating: number) => (
    setPartialResponse(partialResponse => ({
      ...partialResponse,
      input: {
        rating: rating,
      },
    }))
  ), []);

  return (
    <RatingBroadcastContent
      backButton={ context.closeButton }
      title={ content.title }
      responded={ !!response }
      rating={ partialResponse.input.rating }
      onRate={ whenRatingChanged }
      submitButtonProps={ submitButtonProps }
      navigationProps={ context.navigationProps }
      submitHidden={ isNaN(partialResponse.input.rating) }
      nudge={ context.nudge }
    />
  );
};
