import { FunctionComponent, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { CircularPercentage, Notice } from '../../../Common/Component';

type Props = {
  limit: number;
  live: number;
}

export const LicenceInformation: FunctionComponent<Props> = ({
  limit,
  live,
}) => {
  return (
    <Notice
      variant="outlined"
      icon={ <CircularPercentage value={ Math.min(100, Math.round(live / limit * 100)) }/> }
      feedback={ {
        severity: 'info',
        message: (
          <FormattedMessage
            id="people.licence.remaining"
            defaultMessage="<strong>{live}</strong> of <strong>{limit}</strong> people added. Contact our <a>support team</a> to add more people."
            values={ {
              live,
              limit,
              a: (chunks: (string | ReactNode)[]) => <a href="mailto:support@ourpeople.com">{ chunks }</a>,
            } }
          />
        )
      } }
    />
  );
}
