import { rgba } from 'polished';

import { Theme } from '../types/Theme/Theme';

const PALETTE_BASE_WHITE = '#FFFFFF';
const PALETTE_BASE_BLACK = '#000000';
const PALETTE_PRIMARY_100 = '#FFE3F0';
const PALETTE_PRIMARY_200 = '#FFBFDC';
const PALETTE_PRIMARY_300 = '#F69EC5';
const PALETTE_PRIMARY_600 = '#E80D6D';
const PALETTE_PRIMARY_700 = '#BA0A57';
const PALETTE_SUCCESS_700 = '#067647';
const PALETTE_WARNING_700 = '#B54708';
const PALETTE_TEAL_700 = '#055A67';
const PALETTE_BLUE_700 = '#175CD3';
const PALETTE_PINK_700 = '#C11574';
const PALETTE_GREY_100 = '#F2F4F7';
const PALETTE_GREY_200 = '#EAECF0';
const PALETTE_GREY_300 = '#D0D5DD';
const PALETTE_GREY_500 = '#667085';
const PALETTE_GREY_600 = '#475467';
const PALETTE_GREY_900 = '#101828';
const PALETTE_ERROR_100 = '#FEE4E2';
const PALETTE_ERROR_300 = '#FDA29B';
const PALETTE_ERROR_700 = '#B42318';
const PALETTE_ORANGE_700 = '#B93815';

const placeholderColour: Theme['new']['palette']['primary'][25] = {
  main: '#FF00FF',
  contrast: '#00FF00',
};

const placeholderColourSet: Theme['new']['palette']['primary'] = {
  25: placeholderColour,
  50: placeholderColour,
  100: placeholderColour,
  200: placeholderColour,
  300: placeholderColour,
  400: placeholderColour,
  500: placeholderColour,
  600: placeholderColour,
  700: placeholderColour,
  800: placeholderColour,
  900: placeholderColour,
  950: placeholderColour,
};

const SPACING_1 = '0.25rem';
const SPACING_2 = '0.5rem';
const SPACING_3 = '0.75rem';

const TOOLBAR_HEIGHT = '61px';

export const defaultTheme: Theme = {
  new: {
    toolbar: {
      height: TOOLBAR_HEIGHT,
      offset: {
        top: `calc(${ TOOLBAR_HEIGHT } + env(safe-area-inset-top, 0px))`,
        bottom: `calc(${ TOOLBAR_HEIGHT } + env(safe-area-inset-bottom, 0px))`,
      },
    },
    breakpoints: {
      xs: {
        down: '@media (max-width: 599.99px)',
        up: '@media (min-width: 0px)',
      },
      sm: {
        down: '@media (max-width: 959.99px)',
        up: '@media (min-width: 600px)',
      },
      md: {
        down: '@media (max-width: 1279.99px)',
        up: '@media (min-width: 960px)',
      },
      lg: {
        down: '@media (max-width: 1919.99px)',
        up: '@media (min-width: 1280px)',
      },
      xl: {
        down: '@media (min-width: 0px)',
        up: '@media (min-width: 1920px)',
      },
    },
    border: {
      light: `1px solid ${ PALETTE_GREY_200 }`,
      standard: `1px solid ${ PALETTE_GREY_300 }`,
      focus: `1px solid ${ PALETTE_PRIMARY_300 }`,
    },
    borderRadius: {
      small: SPACING_1,
      standard: SPACING_2,
      large: SPACING_3,
      max: '99rem',
    },
    spacing: {
      1: SPACING_1,
      2: SPACING_2,
      3: '0.75rem',
      4: '1rem',
      5: '1.25rem',
      6: '1.5rem',
      8: '2rem',
      10: '2.5rem',
      12: '3rem',
      16: '4rem',
      20: '5rem',
      24: '6rem',
      32: '8rem',
      40: '10rem',
      48: '12rem',
      56: '14rem',
      64: '16rem',
    },
    animationEasing: {
      elastic: 'cubic-bezier(0.22, 0, 0.16, 1.19)',
      bounce: 'cubic-bezier(0.1, -1, 0.9, 2)',
    },
    basePalette: {
      text: '#284B52',
      white: {
        main: PALETTE_BASE_WHITE,
        contrast: PALETTE_BASE_BLACK,
      },
      black: {
        main: PALETTE_BASE_BLACK,
        contrast: PALETTE_BASE_WHITE,
      },
    },
    palette: {
      teal: {
        25: {
          main: '#EDFDFF',
          contrast: PALETTE_TEAL_700,
        },
        50: {
          main: '#D6FAFF',
          contrast: PALETTE_TEAL_700,
        },
        100: {
          main: '#BDF5FD',
          contrast: PALETTE_TEAL_700,
        },
        200: {
          main: '#A8E2EA',
          contrast: PALETTE_TEAL_700,
        },
        300: {
          main: '#9CD5DD',
          contrast: PALETTE_BASE_WHITE,
        },
        400: {
          main: '#2ED3B7',
          contrast: PALETTE_BASE_WHITE,
        },
        500: {
          main: '#39ABBC',
          contrast: PALETTE_BASE_WHITE,
        },
        600: {
          main: '#0896AB',
          contrast: PALETTE_BASE_WHITE,
        },
        700: {
          main: PALETTE_TEAL_700,
          contrast: PALETTE_BASE_WHITE,
        },
        800: {
          main: '#054852',
          contrast: PALETTE_BASE_WHITE,
        },
        900: {
          main: '#033C44',
          contrast: PALETTE_BASE_WHITE,
        },
        950: {
          main: '#03292F',
          contrast: PALETTE_BASE_WHITE,
        },
      },
      blue: {
        25: {
          main: '#F5FAFF',
          contrast: PALETTE_BLUE_700,
        },
        50: {
          main: '#EFF8FF',
          contrast: PALETTE_BLUE_700,
        },
        100: {
          main: '#D1E9FF',
          contrast: PALETTE_BLUE_700,
        },
        200: {
          main: '#B2DDFF',
          contrast: PALETTE_BLUE_700,
        },
        300: {
          main: '#84CAFF',
          contrast: PALETTE_BASE_WHITE,
        },
        400: {
          main: '#53B1FD',
          contrast: PALETTE_BASE_WHITE,
        },
        500: {
          main: '#2E90FA',
          contrast: PALETTE_BASE_WHITE,
        },
        600: {
          main: '#1570EF',
          contrast: PALETTE_BASE_WHITE,
        },
        700: {
          main: PALETTE_BLUE_700,
          contrast: PALETTE_BASE_WHITE,
        },
        800: {
          main: '#1849A9',
          contrast: PALETTE_BASE_WHITE,
        },
        900: {
          main: '#194185',
          contrast: PALETTE_BASE_WHITE,
        },
        950: {
          main: '#102A56',
          contrast: PALETTE_BASE_WHITE,
        },
      },
      pink: {
        25: {
          main: '#FEF6FB',
          contrast: PALETTE_PINK_700,
        },
        50: {
          main: '#FDF2FA',
          contrast: PALETTE_PINK_700,
        },
        100: {
          main: '#FCE7F6',
          contrast: PALETTE_PINK_700,
        },
        200: {
          main: '#FCCEEE',
          contrast: PALETTE_PINK_700,
        },
        300: {
          main: '#FAA7E0',
          contrast: PALETTE_BASE_WHITE,
        },
        400: {
          main: '#F670C7',
          contrast: PALETTE_BASE_WHITE,
        },
        500: {
          main: '#EE46BC',
          contrast: PALETTE_BASE_WHITE,
        },
        600: {
          main: '#DD2590',
          contrast: PALETTE_BASE_WHITE,
        },
        700: {
          main: PALETTE_PINK_700,
          contrast: PALETTE_BASE_WHITE,
        },
        800: {
          main: '#9E165F',
          contrast: PALETTE_BASE_WHITE,
        },
        900: {
          main: '#851651',
          contrast: PALETTE_BASE_WHITE,
        },
        950: {
          main: '#4E0D30',
          contrast: PALETTE_BASE_WHITE,
        },
      },
      success: {
        25: {
          main: '#F6FEF9',
          contrast: PALETTE_SUCCESS_700,
        },
        50: {
          main: '#ECFDF3',
          contrast: PALETTE_SUCCESS_700,
        },
        100: {
          main: '#DCFAE6',
          contrast: PALETTE_SUCCESS_700,
        },
        200: {
          main: '#ABEFC6',
          contrast: PALETTE_SUCCESS_700,
        },
        300: {
          main: '#75E0A7',
          contrast: PALETTE_BASE_WHITE,
        },
        400: {
          main: '#47CD89',
          contrast: PALETTE_BASE_WHITE,
        },
        500: {
          main: '#17B26A',
          contrast: PALETTE_BASE_WHITE,
        },
        600: {
          main: '#079455',
          contrast: PALETTE_BASE_WHITE,
        },
        700: {
          main: PALETTE_SUCCESS_700,
          contrast: PALETTE_BASE_WHITE,
        },
        800: {
          main: '#085D3A',
          contrast: PALETTE_BASE_WHITE,
        },
        900: {
          main: '#074D31',
          contrast: PALETTE_BASE_WHITE,
        },
        950: {
          main: '#053321',
          contrast: PALETTE_BASE_WHITE,
        },
      },
      warning: {
        25: {
          main: '#FFFCF5',
          contrast: PALETTE_WARNING_700,
        },
        50: {
          main: '#FFFAEB',
          contrast: PALETTE_WARNING_700,
        },
        100: {
          main: '#FEF0C7',
          contrast: PALETTE_WARNING_700,
        },
        200: {
          main: '#FEDF89',
          contrast: PALETTE_WARNING_700,
        },
        300: {
          main: '#FEC84B',
          contrast: PALETTE_BASE_WHITE,
        },
        400: {
          main: '#FDB022',
          contrast: PALETTE_BASE_WHITE,
        },
        500: {
          main: '#F79009',
          contrast: PALETTE_BASE_WHITE,
        },
        600: {
          main: '#DC6803',
          contrast: PALETTE_BASE_WHITE,
        },
        700: {
          main: PALETTE_WARNING_700,
          contrast: PALETTE_BASE_WHITE,
        },
        800: {
          main: '#93370D',
          contrast: PALETTE_BASE_WHITE,
        },
        900: {
          main: '#7A2E0E',
          contrast: PALETTE_BASE_WHITE,
        },
        950: {
          main: '#4E1D09',
          contrast: PALETTE_BASE_WHITE,
        },
      },
      primary: {
        ...placeholderColourSet,
        25: {
          main: '#FFFBFD',
          contrast: PALETTE_PRIMARY_700,
        },
        50: {
          ...placeholderColour,
          main: '#FFF2F8',
        },
        100: {
          ...placeholderColour,
          main: PALETTE_PRIMARY_100,
        },
        200: {
          main: PALETTE_PRIMARY_200,
          contrast: PALETTE_PRIMARY_700,
        },
        300: {
          ...placeholderColour,
          main: PALETTE_PRIMARY_300,
        },
        400: {
          main: '#F16EA7',
          contrast: PALETTE_BASE_WHITE,
        },
        500: {
          main: '#ED3D8A',
          contrast: PALETTE_BASE_WHITE,
        },
        600: {
          main: PALETTE_PRIMARY_600,
          contrast: PALETTE_BASE_WHITE,
        },
        700: {
          main: PALETTE_PRIMARY_700,
          contrast: PALETTE_BASE_WHITE,
        },
      },
      error: {
        ...placeholderColourSet,
        25: {
          main: '#FFFBFA',
          contrast: PALETTE_ERROR_700,
        },
        50: {
          main: '#FEF3F2',
          contrast: PALETTE_ERROR_700,
        },
        100: {
          main: PALETTE_ERROR_100,
          contrast: PALETTE_ERROR_700,
        },
        200: {
          main: '#FECDCA',
          contrast: PALETTE_ERROR_700,
        },
        300: {
          main: PALETTE_ERROR_300,
          contrast: PALETTE_BASE_WHITE,
        },
        500: {
          main: '#F04438',
          contrast: PALETTE_BASE_WHITE,
        },
        600: {
          main: '#D92D20',
          contrast: PALETTE_BASE_WHITE,
        },
        700: {
          main: PALETTE_ERROR_700,
          contrast: PALETTE_BASE_WHITE,
        },
      },
      grey: {
        ...placeholderColourSet,
        25: {
          main: '#FCFCFD',
          contrast: PALETTE_GREY_500,
        },
        50: {
          main: '#F9FAFB',
          contrast: PALETTE_GREY_500,
        },
        100: {
          main: PALETTE_GREY_100,
          contrast: PALETTE_GREY_500,
        },
        200: {
          main: PALETTE_GREY_200,
          contrast: PALETTE_GREY_500,
        },
        300: {
          ...placeholderColour,
          main: PALETTE_GREY_300,
        },
        400: {
          ...placeholderColour,
          main: '#98A2B3',
        },
        500: {
          ...placeholderColour,
          main: PALETTE_GREY_500,
        },
        600: {
          ...placeholderColour,
          main: PALETTE_GREY_600,
        },
        700: {
          ...placeholderColour,
          main: '#344054',
        },
        800: {
          ...placeholderColour,
          main: '#1D2939',
        },
        900: {
          main: PALETTE_GREY_900,
          contrast: PALETTE_BASE_WHITE,
        },
      },
      orange: {
        25: {
          main: '#FEFAF5',
          contrast: PALETTE_ORANGE_700,
        },
        50: {
          main: '#FEF6EE',
          contrast: PALETTE_ORANGE_700,
        },
        100: {
          main: '#FDEAD7',
          contrast: PALETTE_ORANGE_700,
        },
        200: {
          main: '#F9DBAF',
          contrast: PALETTE_ORANGE_700,
        },
        300: {
          main: '#F7B27A',
          contrast: PALETTE_BASE_WHITE,
        },
        400: {
          main: '#F38744',
          contrast: PALETTE_BASE_WHITE,
        },
        500: {
          main: '#EF6820',
          contrast: PALETTE_BASE_WHITE,
        },
        600: {
          main: '#E04F16',
          contrast: PALETTE_BASE_WHITE,
        },
        700: {
          main: '#B93815',
          contrast: PALETTE_BASE_WHITE,
        },
        800: {
          main: '#932F19',
          contrast: PALETTE_BASE_WHITE,
        },
        900: {
          main: '#932F19',
          contrast: PALETTE_BASE_WHITE,
        },
        950: {
          main: '#511C10',
          contrast: PALETTE_BASE_WHITE,
        },
      },
    },
    gradients: {
      '01': `linear-gradient(135deg, ${ rgba('#0896AB', 0.85)} 0%, ${ rgba(PALETTE_TEAL_700, 0.85) } 100%)`,
      '02': `linear-gradient(135deg, ${ '#ad9ecb' } 0%, ${ '#676283' } 100%)`,
      '05': 'linear-gradient(270deg, #FCB69F 0%, #FFECD2 100%)',
      '11': 'linear-gradient(135deg, #FCCB90 0%, #D57EEB 100%)',
      '14': 'linear-gradient(0deg, #FED6E3 0%, #A8EDEA 100%)',
      '18': 'linear-gradient(45deg, #FF7EC7 0%, #FFED46 100%)',
      '26': 'linear-gradient(45deg, #39A0FF 0%, #8FFF85 100%)',
      '37': 'linear-gradient(45deg, #FFA4F6 0%, #B7DCFF 100%)',
      '63': 'linear-gradient(0deg, #009EFD 0%, #2AF598 100%)',
      '71': 'linear-gradient(45deg, #FF6C6C 0%, #DD7BFF 100%)',
    },
    shadow: {
      xs: `0px 1px 2px 0px ${ rgba(PALETTE_GREY_900, 0.05) }`,
      sm: `0px 1px 2px 0px ${ rgba(PALETTE_GREY_900, 0.06) }, 0px 1px 3px 0px ${ rgba(PALETTE_GREY_900, 0.1) }`,
      md: `0px 3px 10px 3px ${ rgba(PALETTE_BASE_BLACK, 0.1) }`,
      lg: `0px 4px 6px -2px ${ rgba(PALETTE_GREY_900, 0.03) }, 0px 12px 16px -4px ${ rgba(PALETTE_GREY_900, 0.08) }`,
      xl: `0px 8px 8px -4px ${ rgba(PALETTE_GREY_900, 0.03) }, 0px 20px 24px -4px ${ rgba(PALETTE_GREY_900, 0.08) }`,
      focusRingNoColour: '0px 0px 0px 4px',
      focusRing: `0px 0px 0px 4px ${ PALETTE_PRIMARY_100 }`,
      focusRingGrey: `0px 0px 0px 4px #F2F4F7, 0px 1px 2px 0px ${ rgba(PALETTE_GREY_900, 0.05) }`,
    },
    typography: {
      display: {
        family: 'MuseoSansRounded',
        weight: {
          regular: 200,
          medium: 300,
          semibold: 500,
          bold: 600,
        },
        size: {
          '2xl': {
            fontSize: '4.5rem',
            tracking: '-0.02em',
            lineHeight: '5.625rem',
          },
          xl: {
            fontSize: '3.75rem',
            tracking: '-0.02em',
            lineHeight: '4.5rem',
          },
          lg: {
            fontSize: '3rem',
            tracking: '-0.02em',
            lineHeight: '3.75rem',
          },
          md: {
            fontSize: '2.25rem',
            tracking: '-0.02em',
            lineHeight: '2.75rem',
          },
          sm: {
            fontSize: '1.875rem',
            lineHeight: '2.375rem',
          },
          xs: {
            fontSize: '1.5rem',
            lineHeight: '2rem',
          },
        },
      },
      text: {
        family: 'MuseoSansRounded',
        weight: {
          regular: 200,
          medium: 300,
          semibold: 500,
          bold: 600,
        },
        size: {
          '2xl': {
            fontSize: '1.25rem',
            lineHeight: '1.875rem',
          },
          xl: {
            fontSize: '1.25rem',
            lineHeight: '1.875rem',
          },
          lg: {
            fontSize: '1.125rem',
            lineHeight: '1.75rem',
          },
          md: {
            fontSize: '1rem',
            lineHeight: '1.5rem',
          },
          sm: {
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
          },
          xs: {
            fontSize: '0.75rem',
            lineHeight: '1.125rem',
          },
        },
      }
    },
  }
};
