import React, { ChangeEventHandler, FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { FormattedMessage, FormattedPlural, useIntl } from 'react-intl';
import { MenuItem, Select } from '@mui/material';

import { SwitchWithIcon } from '../SwitchWithIcon/SwitchWithIcon';
import { Flex, NoMarginTextField, Notice } from '../../../Common/Component';
import NudgeIcon from '../../../Assets/img/icons/streamline/touch-finger-1.svg';
import { useEnvironmentSettings } from '../../../Common/Hook';
import { DurationParser } from '../../../Utility/DurationParser';
import { DurationSetting } from '../../../Models/DurationSetting';
import { ArrayHelper } from '../../../Common/Utility';
import { StyledNudgeInput } from './style';

type Props = {
  nudgeDelay: number | null;
  onChange: (nudgeDelay: number | null) => void;
  containsCover: boolean;
  showNudgeEventWarning: boolean;
};

export const NudgeInput: FC<Props> = ({
  nudgeDelay,
  onChange,
  containsCover,
  showNudgeEventWarning,
}) => {
  const intl = useIntl();
  const { broadcastNudgesDefaultDelay, broadcastNudgesAllowEdit } = useEnvironmentSettings();
  const broadcastNudgeDelay = nudgeDelay ? DurationParser.secondsAsDuration(nudgeDelay) : undefined;
  const [localNudgeDelay, setLocalNudgeDelay] = useState<DurationSetting>(broadcastNudgeDelay || broadcastNudgesDefaultDelay || {
    unit: 'day',
    amount: 2,
  });
  const checked = nudgeDelay !== null;

  useEffect(() => {
    if (nudgeDelay !== null) {
      onChange(DurationParser.durationInSeconds(localNudgeDelay));
    }
  }, [nudgeDelay, localNudgeDelay, onChange]);

  useEffect(() => {
    if (localNudgeDelay.amount !== 0) {
      return;
    }

    setLocalNudgeDelay({
      unit: 'day',
      amount: 2,
    });
  }, [localNudgeDelay.amount]);

  const whenCheckedChanged = useCallback((checked: boolean) => (
    onChange(
      checked
        ? DurationParser.durationInSeconds(localNudgeDelay)
        : null
    )
  ), [localNudgeDelay, onChange]);

  const whenDurationUnitChanged: ChangeEventHandler<{ value: unknown }> = event => {
    const unit = event.target.value;

    if (typeof unit !== 'string' || !unitIsValid(unit)) {
      return;
    }

    setLocalNudgeDelay(duration => ({
      ...duration,
      unit,
    }))
  };

  const whenDurationAmountChanged: ChangeEventHandler<HTMLInputElement> = event => (
    setLocalNudgeDelay(duration => ({
      ...duration,
      amount: +event.currentTarget.value,
    }))
  );

  return (
    <StyledNudgeInput>
      <Flex gap={ 2 }>
        <SwitchWithIcon
          IconComponent={ NudgeIcon }
          checked={ !!nudgeDelay }
          onChange={ whenCheckedChanged }
        />
        <FormattedMessage
          description="Fields and accompanying text when inputting a nudge offset"
          defaultMessage="<text>Nudge people who haven't responded after</text>{amount}{unit}{containsCover, select, true { <text>if cover is not assigned</text>} other {}}"
          values={ {
            text: (chunks: (string | ReactNode)[]) => <span>{ chunks }</span>,
            containsCover,
            amount: (
              <NoMarginTextField
                variant="outlined"
                type="number"
                name="scheduledDate"
                disabled={ !checked || !broadcastNudgesAllowEdit }
                InputProps={ {
                  inputProps: {
                    min: 1,
                  },
                } }
                onChange={ whenDurationAmountChanged }
                value={ localNudgeDelay.amount }
              />
            ),
            unit: (
              <Select
                margin="dense"
                value={ localNudgeDelay.unit }
                autoWidth
                variant="outlined"
                onChange={ whenDurationUnitChanged }
                disabled={ !checked || !broadcastNudgesAllowEdit }
                MenuProps={ {
                  anchorOrigin: {
                    horizontal: 'left',
                    vertical: 'bottom',
                  },
                } }
              >
                <MenuItem value="hour">
                  <FormattedPlural
                    value={ localNudgeDelay.amount }
                    one="hour"
                    other="hours"
                  />
                </MenuItem>
                <MenuItem value="day">
                  <FormattedPlural
                    value={ localNudgeDelay.amount }
                    one="day"
                    other="days"
                  />
                </MenuItem>
                <MenuItem value="week">
                  <FormattedPlural
                    value={ localNudgeDelay.amount }
                    one="week"
                    other="weeks"
                  />
                </MenuItem>
              </Select>
            ),
          } }
        />
      </Flex>
      { showNudgeEventWarning && (
        <Notice
          feedback={ {
            severity: 'error',
            message: intl.formatMessage({
              id: 'broadcasts.nudgeSettings.nudgeAfterEvent',
              description: 'Notice displayed when nudge would be sent after an event has began.',
              defaultMessage: 'This nudge is currently scheduled to occur after an event has began, disable nudge or update your settings to send the nudge earlier.',
            })
          } }
          variant="outlined"
        />
      ) }
    </StyledNudgeInput>
  );
};

const unitIsValid = ArrayHelper.createTypeGuard(['hour', 'day', 'week']);
