import { ComponentProps, FC, PropsWithChildren } from 'react';

import { Stack } from '../Stack/Stack';
import { LogoMark } from '../../../stories/components/LogoMark/LogoMark';
import { FeaturedIcon } from '../../../stories/components/FeaturedIcon/FeaturedIcon';

type LogoMarkProps = {
  graphic: {
    type: 'logo';
    props: ComponentProps<typeof LogoMark>;
  };
};

type FeaturedIconProps = {
  graphic: {
    type: 'icon';
    props: ComponentProps<typeof FeaturedIcon>;
  };
};

type Props = LogoMarkProps | FeaturedIconProps;

export const BaseAuthHeader: FC<PropsWithChildren<Props>> = ({
  graphic,
  children
}) => (
  <Stack
    direction="column"
    gap={ 4 }
    align="center"
  >
    { graphic.type === 'logo' && <LogoMark {...graphic.props} /> }
    { graphic.type === 'icon' && <FeaturedIcon {...graphic.props} /> }
    { children }
  </Stack>
);
