import { rgba } from 'polished';
import styled from '@emotion/styled';

export const StyledCategoryName = styled.div<{ error: boolean }>`
  width: 100%;
  height: 43px;
  border-radius: 0.25rem;
  padding: 0.5rem;
  ${ props => props.error && `
    background-color: ${ rgba(props.theme.palette.error.main, 0.2) };
  ` }
  display: flex;
  align-items: center;
  margin-top: 1px;
`;
