import { FunctionComponent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { MetricContainer, MissingDataMessage, TableMetric } from '..';
import { TopSubmittedFormsMetric } from '../../Model';
import { TableCell, TableRow } from '../../../Components';

interface Props {
  metric: TopSubmittedFormsMetric;
}

export const TopPerformingFormsMetric: FunctionComponent<Props> = ({
  metric,
}) => {
  const intl = useIntl();

  return (
    <MetricContainer
      title={ intl.formatMessage({
        id: 'dashboard.topPerformingForms.heading',
        defaultMessage: 'Popular Smart Forms'
      }) }
    >
      {
        metric.datasets.length
          ? (
            <TableMetric
              headerRow={
                <TableRow>
                  <TableCell>
                    <FormattedMessage
                      id="dashboard.topPerformingForms.broadcast"
                      description="Heading for form name column in top performing forms table on dashboard."
                      defaultMessage="Smart Form"
                    />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage
                      id="dashboard.topPerformingForms.submissions"
                      description="Heading for submissions column in top performing forms table on dashboard."
                      defaultMessage="Submissions"
                    />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage
                      id="dashboard.topPerformingForms.submissions"
                      description="Heading for open column in top performing forms table on dashboard."
                      defaultMessage="Open"
                    />
                  </TableCell>
                </TableRow>
              }
              rows={ metric.datasets }
              rowRender={ dataset => (
                <TableRow key={ dataset.key }>
                  <TableCell>
                    <Link to={ `/forms/${ dataset.metadata.form.id }/report` }>
                      { dataset.metadata.form.name }
                    </Link>
                  </TableCell>
                  <TableCell>
                    { dataset.data[0]?.value || 0 }
                  </TableCell>
                  <TableCell>
                    {
                      dataset.metadata.form.statusTrackerEnabled === true
                      ? (dataset.metadata.openCount || 0)
                      : ''
                    }
                  </TableCell>
                </TableRow>
              ) }
            />
          )
          : (
            <MissingDataMessage>
              <FormattedMessage
                id="dashboard.topPerformingForms.noData"
                defaultMessage="<p>No smart form submissions were made in this period.</p>"
              />
            </MissingDataMessage>
          )
      }
    </MetricContainer>
  );
};
