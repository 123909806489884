export class PlaceholderFinder {
  public static find(text: string) {
    return !!regex.exec(text);
  }

  public static strip(text: string) {
    return text.replaceAll(regex, '');
  }

  public static replace(text: string, placeholders: Record<string, string>) {
    return Array.from(Object.entries(placeholders)).reduce(
      (substitutedText, [placeholder, substitution]) => (
        substitutedText.replaceAll(placeholder, substitution)
      ),
      text,
    );
  }
}

const regex = /\[([A-Za-z._]+)]/gm;
