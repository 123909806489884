import { MappedSettingTypes } from '../../../Types';
import { StyledTextSettingValue } from './style';

type Props = {
  value: MappedSettingTypes['text']['value'],
};

export const TextSettingValue = ({
  value,
}: Props): JSX.Element => {
  return (
    <StyledTextSettingValue>
      {value}
    </StyledTextSettingValue>
  );
};
