import styled from '@emotion/styled';

export const StyledMenuSectionDivider = styled.hr`
  background-color: ${ props => props.theme.palette.grey[300] };
  margin: 0;
  color: transparent;
  border-bottom: none;
  border-left: none;
  border-right: none;
`;
