import { DetailedHTMLProps, FC, TextareaHTMLAttributes } from 'react';

import { StyledRoundedTextarea } from './style';

type Props = DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>;

export const RoundedTextarea: FC<Props> = props => (
  <StyledRoundedTextarea
    {...props }
  />
);
