import { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Tooltip } from 'op-storybook/stories/components/Tooltip/Tooltip';
import { FeaturedIcon } from 'op-storybook/stories/components/FeaturedIcon/FeaturedIcon';
import { useDateTimeFormatter } from '@ourpeople/shared/Core/Hook/useDateFormatter';
import NudgeIcon from 'op-storybook/lib/assets/icon/figma/bell-04.svg';

import { BroadcastNudge } from '../../../Broadcasts/Model';

type Props = {
  nudge: BroadcastNudge;
};

export const NudgeIndicator: FC<Props> = ({ nudge }) => {
  const intl = useIntl();
  const { uniformDateTime } = useDateTimeFormatter();
  const localisedNudgeDate = useMemo(() => (
    uniformDateTime(nudge.at)
  ), [uniformDateTime, nudge.at]);

  return (
    <Tooltip
      contents={ intl.formatMessage({
        description: 'Tooltip for nudge indicator icon used on inbox page.',
        defaultMessage: 'Nudge received { localisedNudgeDate }',
      }, {
        localisedNudgeDate,
      }) }
    >
      <div>
        <FeaturedIcon
          IconComponent={ NudgeIcon }
          variant="light-circle"
          colour="primary"
        />
      </div>
    </Tooltip>
  );
};
