import { FC, PropsWithChildren, ReactNode, useMemo } from 'react';
import * as RadForm from '@radix-ui/react-form';

import { FieldLabel } from '../../FieldLabel/FieldLabel';
import { Stack } from '../../Stack/Stack';
import { Typography } from '../../../../stories/components/Typography/Typography';
import { SvgComponent } from '../../../model/SvgComponent';
import { PresentationIcon } from '../../PresentationIcon/PresentationIcon';

type Props = {
  id?: string;
  variant?: 'standard' | 'beside';
  FieldIcon?: SvgComponent;
  label?: string;
  name?: string;
  className?: string;
  tip?: string;
  errorMessages?: string[];
  size?: 'sm' | 'md';
  children?: ReactNode;
};

export const LabelledFormField: FC<PropsWithChildren<Props>> = ({
  id,
  variant = 'standard',
  FieldIcon,
  label,
  name,
  className,
  tip,
  errorMessages,
  size,
  children,
}) => {
  const tipOrError = useMemo(() => (
    errorMessages?.length
      ? (
        <RadForm.ValidityState
          css={ { lineHeight: 1 } }
          children={ () => (
            <>
              { errorMessages.map((errorMessage, index) => (
                <Typography
                  key={ index }
                  palette={ {
                    colour: 'error',
                    intensity: 500,
                  } }
                  size={ size }
                  weight="regular"
                >
                  { errorMessage }
                </Typography>
              )) }
            </>
          ) }
        />
      )
      : tip
        ? (
          <RadForm.Message
            css={ { lineHeight: 1 } }
          >
            <Typography
              size={ size }
              weight="regular"
              palette={ {
                colour: 'grey',
                intensity: 600,
              } }
            >
              { tip }
            </Typography>
          </RadForm.Message>
        )
        : null
  ), [errorMessages, size, tip]);

  return (
    <RadForm.FormField
      name={ name }
      className={ className }
    >
      <Stack
        direction="row"
        gap={ 2 }
        align={ variant === 'beside' ? 'center' : 'flex-start' }
      >
        {
          variant === 'standard'
            ? (
              <>
                { FieldIcon && <PresentationIcon IconComponent={ FieldIcon }/> }
                <Stack
                  direction="column"
                  gap={ 1 }
                  css={ { width: '100%' } }
                >
                  {
                    label
                      ? (
                        <label
                          css={ { width: '100%' } }
                          htmlFor={ id }
                        >
                          <Stack
                            direction="column"
                            gap={ 1 }
                          >
                            <FieldLabel>
                              { label }
                            </FieldLabel>
                            <div>
                              { children }
                            </div>
                          </Stack>
                        </label>
                      )
                      : children
                  }
                  { tipOrError }
                </Stack>
              </>
            )
            : (
              <>
                { FieldIcon && <PresentationIcon IconComponent={ FieldIcon }/> }
                { children }
                <Stack
                  direction="column"
                  gap={ 0 }
                >
                  <label
                    css={ { lineHeight: 1 } }
                    htmlFor={ id }
                  >
                    <FieldLabel>
                      { label }
                    </FieldLabel>
                  </label>
                  { tipOrError }
                </Stack>
              </>
            )
        }
      </Stack>
    </RadForm.FormField>
  );
}
