import { IntlShape } from 'react-intl';

import UploadIcon from '../../../../Assets/img/icons/monochrome/content/upload-image.svg';
import { UploadRequestValidator } from '../../Validators';
import { UniqueIdGenerator } from '../../../../Common/Utility';
import { SingleContentCard, DraftContent, EditorContent } from '../../../../Content/Model';
import { CardTransformer } from '../../../../Content/Utility';
import { UploadRequestEditor, UploadRequestPreview } from '../../../Component';
import { BroadcastContent, BroadcastContentDefinition } from '../../../Model';
import { UploadResponse } from '../../../../Inbox/Model';
import { LiveUploadContent } from '../../../../Inbox/Component';
import { EnvironmentSettings } from '../../../../Models';

export type UploadRequest = BroadcastContent & {
  type: 'upload_image';
  title: string;
  description: string;
  upload_limit: number;
};

export type DraftUploadRequest = DraftContent & {
  type: 'upload_image';
  title: string;
  description: string;
};

export type UploadEditorContent = EditorContent<DraftUploadRequest>;

export class UploadRequestDefinition implements BroadcastContentDefinition<UploadRequest, UploadEditorContent, UploadResponse> {
  public readonly id = 'upload';
  public readonly availableToCreate = true;
  public readonly new = false;
  public readonly richText = false;
  public readonly contentTypes = ['uploadimagecontent'];
  public readonly categoryId = 'feedback';
  public readonly IconComponent = UploadIcon;
  public readonly EditorComponent = UploadRequestEditor;
  public readonly PreviewComponent = UploadRequestPreview;
  public readonly LiveComponent = LiveUploadContent;
  public readonly SubmittedComponent = (): null => null;
  public validate = UploadRequestValidator.validate;

  public definesContentType = (type: string): boolean => (
    this.contentTypes.concat('upload_image').includes(type)
  );

  public getContentTitle = (intl: IntlShape, content: UploadRequest): string => (
    content.title || this.getDefaultTitle(intl)
  );

  public getLocalisedType = (intl: IntlShape): string => (
    intl.formatMessage({
      id: 'broadcasts.content.file.type',
      description: 'Localised name for file content type.',
      defaultMessage: 'Upload',
    })
  );

  public getDraftContentTitle = (intl: IntlShape, draftContent: DraftUploadRequest): string => (
    draftContent.title || this.getDefaultTitle(intl)
  );

  public hasRequiredFeatureFlags = (environmentSettings: EnvironmentSettings): boolean => (
    !!environmentSettings.uploadImageTypeEnabled
  );

  public createEditorContent = (): UploadEditorContent => ({
    id: UniqueIdGenerator.generate(),
    card: {
      content: {
        type: 'upload_image',
        title: '',
        description: '',
      },
      mandatory: false,
      image: null,
    },
  });

  public transformCard = (card: SingleContentCard<UploadRequest>): UploadEditorContent => ({
    id: card.id,
    card: CardTransformer.transformCard(card, {
      type: 'upload_image',
      title: card.content.title,
      description: card.content.description,
    })
  });

  private getDefaultTitle = (intl: IntlShape): string => (
    intl.formatMessage({
      id: 'broadcasts.content.upload.defaultTitle',
      description: 'Default title for upload request.',
      defaultMessage: 'Untitled upload card',
    })
  );
}

export const UPLOAD_IMAGE_LIMIT = 3;
