import { FunctionComponent, ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { ConnectionType, TagConnection } from '../../Model';
import { StyledConnectionList } from './styles';

interface Props {
  connections: TagConnection[];
}

export const ConnectionList: FunctionComponent<Props> = ({
  connections,
}) => {
  const intl = useIntl();

  const getConnectionType = (type: ConnectionType): string => {
    switch (type) {
      case 'broadcast':
        return intl.formatMessage({
          id: 'tag.deleteModal.broadcastConnection',
          description: 'Broadcast connection type.',
          defaultMessage: 'broadcast',
        });
      case 'training':
        return intl.formatMessage({
          id: 'tag.deleteModal.trainingConnection',
          description: 'Training connection type.',
          defaultMessage: 'training',
        });
      case 'chat':
        return intl.formatMessage({
          id: 'tag.deleteModal.chatConnection',
          description: 'Chat connection type.',
          defaultMessage: 'chat',
        });
      case 'file':
        return intl.formatMessage({
          id: 'tag.deleteModal.fileConnection',
          description: 'File connection type.',
          defaultMessage: 'file',
        });
      case 'profile':
        return intl.formatMessage({
          id: 'tag.deleteModal.profileConnection',
          description: 'Profile connection type.',
          defaultMessage: 'profile',
        });
      case 'form':
        return intl.formatMessage({
          id: 'tag.deleteModal.formConnection',
          description: 'Form connection type.',
          defaultMessage: 'form',
        });
      default:
        return intl.formatMessage({
          id: 'tag.deleteModal.unrecognisedConnection',
          description: 'Unrecognised connection type.',
          defaultMessage: '',
        });
    }
  };

  const getConnectionDescription = (type: ConnectionType, count: number): ReactNode => {
    return intl.formatMessage(
      {
        id: 'tag.deleteModal.connectionDescription',
        description: 'Message when listing connections.',
        defaultMessage: '<strong>{ count }</strong> { type } {count, plural, one {connection} other {connections}}',
      },
      {
        count,
        type: getConnectionType(type),
      },
    );
  };

  return (
    <StyledConnectionList>
      {
        connections.map(connection => (
          <li key={ connection.type }>
            { getConnectionDescription(connection.type, connection.count) }
          </li>
        ))
      }
    </StyledConnectionList>
  )
};
