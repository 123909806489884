import { FunctionComponent, useState } from 'react';

import ClipBoardText from '../../../Assets/img/icons/streamline/task-list-text-1.svg';
import ClipBoardCheck from '../../../Assets/img/icons/streamline/task-list-check-1.svg';
import { HighlightedText } from '../../../Common/Component';

type Props = {
  value: string;
}

export const ClipboardButton: FunctionComponent<Props> = ({
  value,
}) => {
  const [copied, setCopied] = useState<boolean>(false);

  const whenClipboardButtonClicked = () => {
    navigator.clipboard.writeText(value)
      .then(() => setCopied(true))
      .catch(() => { /* Too bad, you'll have to do it the old-fashioned way */
      })
  }

  return (
    <HighlightedText
      onClick={ whenClipboardButtonClicked }
      icon={
        copied
          ? <ClipBoardCheck/>
          : <ClipBoardText/>
      }
      iconPosition="end"
    >
      { value }
    </HighlightedText>
  );
}
