import styled from '@emotion/styled';

import { StyledImageUpload } from '../../../../../../Common/Component/ImageUploadWithLoadingIndicator/style';

export const StyledImageContainer = styled.div<{ fit: 'cover' | 'contain' }>`
  width: 100px;
  height: 100px;
  background-color: ${ props => props.theme.palette.grey[100] };
  border-radius: ${ props => props.theme.borderRadius.sm };
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    object-fit: ${ props => props.fit };
    width: 100%;
    height: 100%;
  }

  ${ StyledImageUpload } {
    width: 100%;
    height: 100%;
  }
`;

export const StyledImagePlaceholder = styled.div`
  height: 100px;
  background-color: ${ props => props.theme.palette.grey[100] };
  border-radius: ${ props => props.theme.borderRadius.sm };
`;
