import styled from '@emotion/styled';

import SwitchAccountIcon from '../../../../Assets/img/icons/streamline/common-file-rotate.svg';

export const StyledMainMenuHeader = styled.div`
  padding: ${ props => `${ props.theme.spacing(4) } ${ props.theme.spacing(4) } ${ props.theme.spacing(2) }` };
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto;
  grid-gap: ${ props => props.theme.spacing(1) };
`;

export const StyledAvatarContainer = styled.div`
  width: ${ props => props.theme.spacing(8) };
  height: ${ props => props.theme.spacing(9) };
  position: relative;
`;

export const StyledSpaceIconContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
`;

export const StyledUserName = styled.span`
  font-size: ${ props => props.theme.fontSize.lg };
  font-weight: 500;
`;

export const StyledSpaceName = styled.span`
  font-size: ${ props => props.theme.fontSize.md };
`;

export const StyledAccountInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  row-gap: ${ props => props.theme.spacing(1) };
`;

export const StyledEditProfileButton = styled.button`
  background-color: ${ props => props.theme.palette.transparent.main };
  color: ${ props => props.theme.palette.secondary.main };
  transition: background-color 0.2s;
  border: none;
  margin: ${ props => props.theme.spacing(-1) };
  padding: ${ props => props.theme.spacing(1) };
  text-align: left;
  font-size: ${ props => props.theme.fontSize.sm };
  border-radius: ${ props => props.theme.borderRadius.sm };
  outline: none;

  &:active {
    background-color: ${ props => props.theme.palette.grey[300] };
  }
`;

export const StyledAccountSwitchButton = styled.button`
  background-color: ${ props => props.theme.palette.transparent.main };
  color: ${ props => props.theme.palette.grey[400] };
  border: none;
  transition: background-color 0.2s;
  margin: ${ props => `${ props.theme.spacing(-1) } ${ props.theme.spacing(-4) }` };
  padding: ${ props => props.theme.spacing(1) };
  text-align: left;
  font-size: ${ props => props.theme.fontSize.sm };
  border-radius: ${ props => props.theme.borderRadius.sm };
  display: flex;
  column-gap: ${ props => props.theme.spacing(1) };
  align-items: center;

  &:active {
    background-color: ${ props => props.theme.palette.grey[300] };
  }
`;

export const StyledSwitchAccountIcon = styled(SwitchAccountIcon)`
  width: ${ props => props.theme.spacing(2) };
  height: ${ props => props.theme.spacing(2) };
`;
