import { useIntl } from 'react-intl';
import { FC } from 'react';

import { ConfirmNavigationDialog } from '../ConfirmNavigationDialog/ConfirmNavigationDialog';

type Props = {
  active: boolean;
  ignorePattern?: RegExp;
};

export const GenericConfirmNavigationDialog: FC<Props> = ({
  active,
  ignorePattern,
}) => {
  const intl = useIntl();

  return (
    <ConfirmNavigationDialog
      active={ active }
      ignorePattern={ ignorePattern }
      title={ intl.formatMessage({
        description: 'Title for navigation confirmation modal used throughout console.',
        defaultMessage: 'Are you sure?',
      }) }
      description={ intl.formatMessage({
        description: 'Body for navigation confirmation modal used throughout console.',
        defaultMessage: 'You have unsaved changes. If you leave now your changes will be lost.',
      }) }
      confirmCta={ intl.formatMessage({
        description: 'Label for button to discard unsaved changes in navigation confirmation modal used throughout console.',
        defaultMessage: 'Discard changes',
      }) }
    />
  );
};
