import { FormattedMessage, useIntl } from 'react-intl';
import { Button, MenuItem, OutlinedInput, Select } from '@mui/material';
import { PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Heading } from '@ourpeople/shared/Core/Component/Content';
import { LocalisedString } from 'op-storybook/lib/model/LocalisedString/LocalisedString';

import {
  Box,
  Flex,
  FlexPullRight,
  LoadingButton,
  NoResultsError,
  Notice,
  TruncatingContent,
  VerticallySpaced
} from '../../../Common/Component';
import { Table, TableBody, TableCell, TableRow } from '../../../Components';
import { StyledArrowContainer, StyledColumnHeader, StyledTableContainer } from './style';
import ImportArrow from '../../../Assets/img/icons/monochrome/import-arrow.svg';
import { ApiContext } from '../../../Contexts';
import { ImportDetail, ImportErrorPresentationComponent } from '../../Model';

type SkippableMapping<T extends string> = T | 'skipped';

type Props<T extends string> = {
  importDetail: ImportDetail;
  onColumnMappingComplete: () => void;
  onReUploadButtonClicked: () => void;
  onContinueButtonClicked: () => void;
  onReCheckClicked: () => void;
  parsing: boolean;
  mappingTypes: LocalisedString<T>[];
  RootErrorComponent: ImportErrorPresentationComponent;
  ColumnErrorComponent: ImportErrorPresentationComponent;
}

export const ColumnMapStep = <T extends string>({
  importDetail,
  onColumnMappingComplete,
  onReUploadButtonClicked,
  onContinueButtonClicked,
  onReCheckClicked,
  parsing,
  mappingTypes,
  RootErrorComponent,
  ColumnErrorComponent,
}: PropsWithChildren<Props<T>>): JSX.Element => {
  const intl = useIntl();
  const mappingTypesWithSkipped: LocalisedString<SkippableMapping<T>>[] = useMemo(() => [
    {
      id: 'skipped',
      localisation: intl.formatMessage({
        id: 'import.columnMap.skipped',
        description: 'Label for skipped option in column mapping dropdown in import.',
        defaultMessage: 'Skipped',
      }),
    },
    ...mappingTypes,
  ], [intl, mappingTypes]);
  const api = useContext(ApiContext);
  const [updateMappingRequestedAt, setUpdateMappingRequestedAt] = useState<Date>();
  const [touchedColumns, setTouchedColumns] = useState<number[]>([]);
  const [mappedColumns, setMappedColumns] = useState<(SkippableMapping<T> | '')[]>(importDetail.parseResult?.columnMap.map(
    mappedColumn => !!mappedColumn.ignoreReason
      ? 'skipped'
      : !mappedColumn.mapping?.type
        ? ''
        : mappedColumn.mapping.type as T
  ) || []);
  const [remapping, setRemapping] = useState<boolean>(false);
  const [remappingError, setRemappingError] = useState<boolean>(false);
  const columnMappingChanged = Boolean(touchedColumns.length);
  const allColumnsMapped = !mappedColumns.includes('');

  const whenColumnMappingChanged = (type: SkippableMapping<T>, index: number) => {
    setTouchedColumns(touchedColumns => touchedColumns.concat(index));
    setMappedColumns(existing => [
      ...existing.slice(0, index),
      type,
      ...existing.slice(index + 1)
    ]);
  };

  const whenColumnMappingRequested = () => {
    setUpdateMappingRequestedAt(new Date());
  };

  const updateColumnMappings = useCallback(async () => {
    if (!api) {
      return;
    }

    return api.post(`/imports/${ importDetail.id }/map-columns`, {
      columns: mappedColumns.map(mappedColumn => {
        return mappedColumn === 'skipped'
          ? {
            ignore: true,
          }
          : {
            ignore: false,
            mapping: {
              type: mappedColumn,
            },
          };
      }),
    });
  }, [api, mappedColumns, importDetail.id]);

  useEffect(() => {
    let timer: number;
    let cancelled = false;
    if (!updateColumnMappings || !updateMappingRequestedAt) {
      return;
    }

    setRemapping(true);
    updateColumnMappings()
      .then(() => {
        if (cancelled) {
          return;
        }
        setRemapping(false);
        setRemappingError(false);
        timer = window.setTimeout(() => {
          setTouchedColumns([]);
          setUpdateMappingRequestedAt(undefined);
          onColumnMappingComplete();
        }, 100);
      })
      .catch(() => {
        if (cancelled) {
          return;
        }

        setRemapping(false);
        setRemappingError(true);
        setUpdateMappingRequestedAt(undefined);
      });

    return () => {
      cancelled = true;
      if (timer) {
        clearTimeout(timer);
      }
    }
  }, [onColumnMappingComplete, updateMappingRequestedAt, updateColumnMappings]);

  return (
    <Box margin={ false }>
      <VerticallySpaced gap={ 2 }>
        <Heading>
          <Flex>
            <FormattedMessage
              id="imports.columnMap.heading"
              description="Title displayed over the column map step of imports."
              defaultMessage="Map columns"
            />
            <FlexPullRight gap={ 2 }>
              <Button
                color="primary"
                variant="outlined"
                onClick={ onReUploadButtonClicked }
              >
                <FormattedMessage
                  id="imports.re-upload"
                  description="Label for re-upload button."
                  defaultMessage="Re-upload"
                />
              </Button>
              {
                columnMappingChanged
                  ? (
                    <LoadingButton
                      onClick={ whenColumnMappingRequested }
                      color="primary"
                      disableElevation
                      variant="contained"
                      busy={ remapping }
                      disabled={ !allColumnsMapped }
                    >
                      <FormattedMessage
                        id="imports.columnMap.remap"
                        description="Label for button that updates column mappings."
                        defaultMessage="Update"
                      />
                    </LoadingButton>
                  )
                  : (
                    <LoadingButton
                      color="primary"
                      variant="outlined"
                      disableElevation
                      busy={ parsing }
                      onClick={ onReCheckClicked }
                    >
                      <FormattedMessage
                        id="imports.columnMap.re-check"
                        description="Label for re-check button."
                        defaultMessage="Re-check"
                      />
                    </LoadingButton>
                  )
              }
            </FlexPullRight>
          </Flex>
        </Heading>
        {
          remappingError
            ? (
              <NoResultsError onRetry={ whenColumnMappingRequested }>
                <FormattedMessage
                  id="imports.columnMap.remappingError"
                  description="Error when remap request fails."
                  defaultMessage="Your columns could not be updated because something went wrong."
                />
              </NoResultsError>
            )
            : (
              <>
                { importDetail.status !== 'invalid_mapping' && (
                  <Notice
                    variant="outlined"
                    feedback={ {
                      message: (
                        <FormattedMessage
                          id="imports.columnMap.success"
                          description="Success notice when column map has no errors."
                          defaultMessage="Your columns look good to us! Check the mapping below, or continue to review your data."
                        />
                      ),
                      severity: 'success',
                    } }
                    buttons={ [
                      {
                        id: 'continue',
                        label: intl.formatMessage({
                          id: 'imports.columnMap.continue',
                          description: 'Label for continue button in column map success notice.',
                          defaultMessage: 'Continue',
                        }),
                        props: {
                          variant: 'primary',
                          onClick: onContinueButtonClicked,
                        },
                      }
                    ] }
                  />
                ) }
                {
                  importDetail.parseResult?.columnMapErrors.errors && (
                    <RootErrorComponent
                      errors={ importDetail.parseResult.columnMapErrors.errors }
                    />
                  )
                }
                <StyledTableContainer>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          variant="head"
                          component="th"
                          scope="row"
                        >
                          <FormattedMessage
                            id="imports.columnMap.columnHeaders"
                            description="Label for headers row of column map table."
                            defaultMessage="Your column headers"
                          />
                        </TableCell>
                        {
                          importDetail.parseResult?.columnMap.map(mappedColumn => (
                            <TableCell
                              key={ mappedColumn.index }
                              maxwidth="20rem"
                            >
                              <StyledColumnHeader>
                                {
                                  importDetail.parseResult?.snippet[0][mappedColumn.index]
                                }
                                <StyledArrowContainer>
                                  <ImportArrow/>
                                </StyledArrowContainer>
                              </StyledColumnHeader>
                            </TableCell>
                          ))
                        }
                      </TableRow>
                      <TableRow>
                        <TableCell variant="head" component="th" scope="row">
                          <FormattedMessage
                            id="imports.columnMap.mappedToLabel"
                            description="Label for 'Mapped to' row of column map table."
                            defaultMessage="Mapped to type"
                          />
                        </TableCell>
                        {
                          importDetail.parseResult?.columnMap.map((mappedColumn, index) => (
                            <TableCell
                              key={ mappedColumn.index }
                              maxwidth="20rem"
                              verticalAlign="top"
                            >
                              <Flex
                                direction="column"
                                gap={ 1 }
                                align="flex-start"
                              >
                                <Select
                                  input={ (
                                    <OutlinedInput
                                      margin="dense"
                                    />
                                  ) }
                                  data-index={ index }
                                  variant="outlined"
                                  value={ mappedColumns[index] }
                                  onChange={ event => whenColumnMappingChanged(event.target.value as SkippableMapping<T>, index) }
                                  error={
                                    !!importDetail.parseResult?.columnMapErrors.children[index]?.errors.length
                                    && !touchedColumns.includes(index)
                                  }
                                >
                                  {
                                    mappingTypesWithSkipped.map(mappingType => (
                                      <MenuItem
                                        key={ mappingType.id }
                                        value={ mappingType.id }
                                      >
                                        { mappingType.localisation }
                                      </MenuItem>
                                    ))
                                  }
                                </Select>
                                {
                                  ((!touchedColumns.includes(index) && mappedColumn.ignoreReason) || mappedColumns[index] === 'skipped') && (
                                    <Notice
                                      variant="outlined"
                                      feedback={ {
                                        severity: 'info',
                                        message: (
                                          mappedColumn.ignoreReason === 'blank' && !touchedColumns.includes(index)
                                            ? (
                                              <FormattedMessage
                                                id="imports.columnMap.skippedReason.blank"
                                                description="Label for 'Mapped to' row of column map table."
                                                defaultMessage="This column has been automatically skipped because it is empty."
                                              />
                                            )
                                            : (
                                              <FormattedMessage
                                                id="imports.columnMap.skippedReason.manual"
                                                description="Label for 'Mapped to' row of column map table."
                                                defaultMessage="This column has been marked as skipped by you."
                                              />
                                            )
                                        )
                                      } }
                                    />
                                  )
                                }
                                {
                                  importDetail.parseResult?.columnMapErrors.children[index] && !touchedColumns.includes(index) && (
                                    <ColumnErrorComponent
                                      errors={ importDetail.parseResult.columnMapErrors.children[index]?.errors || [] }
                                    />
                                  )
                                }
                              </Flex>
                            </TableCell>
                          ))
                        }
                      </TableRow>
                      {
                        importDetail.parseResult?.snippet.slice(1, 10).map((row, index) => (
                            <TableRow key={ `snippetRow${ index }` }>
                              {
                                index === 0 && (
                                  <TableCell
                                    variant="head"
                                    component="th"
                                    scope="row"
                                    rowSpan={ importDetail.parseResult?.snippet.length }
                                  >
                                    <FormattedMessage
                                      id="imports.columnMap.yourDataRowHeading"
                                      description="Label for row containing user data snippet in column map table."
                                      defaultMessage="Your data"
                                    />
                                  </TableCell>
                                )
                              }
                              {
                                importDetail.parseResult?.columnMap.map(mappedColumn => (
                                  <TableCell
                                    key={ mappedColumn.index }
                                    minwidth="5rem"
                                    maxwidth="10rem"
                                  >
                                    <TruncatingContent>
                                      { row[mappedColumn.index] || '' }
                                    </TruncatingContent>
                                  </TableCell>
                                ))
                              }
                            </TableRow>
                          )
                        )
                      }
                    </TableBody>
                  </Table>
                </StyledTableContainer>
              </>
            )
        }
      </VerticallySpaced>
    </Box>
  );
};
