import styled from '@emotion/styled';

export const NoResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.2rem;
  width: 100%;
  height: 50%;
  height: 50vh;
`;
