import { IntlShape } from 'react-intl';
import ButtonBlockIcon from 'op-storybook/lib/assets/icon/figma/cursor-click-01.svg';
import {
  BlankValidationErrorDefinition
} from 'op-storybook/lib/model/Validation/Definition/BlankValidationErrorDefinition';

import { BlockErrorDefinitions, BroadcastBlockDefinition } from '../../Model/BroadcastBlockDefinition';
import { BroadcastBlock } from '../../Model/Broadcast';
import { UniqueIdGenerator } from '../../../../Common/Utility';
import { ButtonBlockExpandedPreview } from '../../Component/Blocks/ButtonBlock/ButtonBlockExpandedPreview';
import { ButtonBlockInlineForm } from '../../Component/Blocks/ButtonBlock/ButtonBlockInlineForm';
import { ButtonBlockForm } from '../../Component/Blocks/ButtonBlock/ButtonBlockForm';

export type ButtonBlock = {
  localId: string;
  kind: 'button';
  attributes: {
    label: string;
    url: string;
    backgroundColour?: string;
    textColour?: string;
  };
};

export class ButtonBlockDefinition implements BroadcastBlockDefinition<ButtonBlock> {
  public readonly prioritise = true;
  public readonly kind = 'button';
  public readonly localisedKind: string;
  public readonly errorDefinitions: BlockErrorDefinitions<ButtonBlock>;

  constructor(intl: IntlShape) {
    this.localisedKind = intl.formatMessage({
      description: 'Name for button block used in broadcast editor',
      defaultMessage: 'Button',
    });

    this.errorDefinitions = {
      label: [
        new BlankValidationErrorDefinition(intl => intl.formatMessage({
          description: 'Error message when broadcast button block label input is left blank.',
          defaultMessage: 'Label can not be blank.',
        })),
      ],
      url: [
        new BlankValidationErrorDefinition(intl => intl.formatMessage({
          description: 'Error message when broadcast button block link input is left blank.',
          defaultMessage: 'Link can not be blank.',
        })),
        {
          type: 'notUrl',
          formatMessage: intl => intl.formatMessage({
            description: 'Error message when broadcast button block link is formatted incorrectly.',
            defaultMessage: 'Invalid link.',
          }),
        }
      ],
      backgroundColour: [],
      textColour: [],
    };
  }

  public definesBlock(block: BroadcastBlock): block is ButtonBlock {
    return block.kind === 'button';
  }

  public initialiseBlock(): ButtonBlock {
    return {
      localId: UniqueIdGenerator.generate(),
      kind: 'button',
      attributes: {
        label: this.localisedKind,
        url: '',
      },
    };
  }

  public IconComponent = ButtonBlockIcon;
  public ExpandedPreviewComponent = ButtonBlockExpandedPreview;
  public InlineFormComponent = ButtonBlockInlineForm;
  public FormComponent = ButtonBlockForm;
}
