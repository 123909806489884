import {
  CHOICE_REQUEST_MAX_SELECTIONS_MAX,
  CHOICE_REQUEST_MAX_SELECTIONS_MIN,
  CHOICE_REQUEST_MIN_SELECTIONS_MAX,
  CHOICE_REQUEST_MIN_SELECTIONS_MIN, DraftChoiceOption,
  DraftChoiceRequest
} from '../../Model/ContentTypes';
import { ValidationTree, Validator } from '../../../Common/Model';
import { RequestValidator } from './RequestValidator';
import { Validate } from '../../../Common/Utility';
import { ChoiceOptionValidator } from './ChoiceOptionValidator';

export class ChoiceRequestValidator {
  public static validateMinSelections = (content: DraftChoiceRequest): ValidationTree<number> => ({
    errors: new Validator<number>([
      Validate.isNumeric,
      minSelections => Validate.greaterThanEq(minSelections, CHOICE_REQUEST_MIN_SELECTIONS_MIN),
      minSelections => Validate.lessThanEq(minSelections, Math.min(content.maxSelections, content.options.length, CHOICE_REQUEST_MIN_SELECTIONS_MAX)),
    ]).validate(content.minSelections),
    children: {},
  });

  public static validateMaxSelections = (content: DraftChoiceRequest): ValidationTree<number> => ({
    errors: new Validator<number>([
      Validate.isNumeric,
      maxSelections => Validate.greaterThanEq(maxSelections, Math.max(content.minSelections, CHOICE_REQUEST_MAX_SELECTIONS_MIN)),
      maxSelections => Validate.lessThanEq(maxSelections, Math.min(CHOICE_REQUEST_MAX_SELECTIONS_MAX, content.options.length)),
    ]).validate(content.maxSelections),
    children: {},
  });

  public static validateOptions = (content: DraftChoiceRequest): ValidationTree<DraftChoiceOption[]> => ({
    errors: [],
    children: content.options.map(ChoiceOptionValidator.validate),
  });

  public static validate = (content: DraftChoiceRequest): ValidationTree<DraftChoiceRequest> => ({
    errors: [
      ...RequestValidator.validate(content).errors,
    ],
    children: {
      ...RequestValidator.validate(content).children,
      minSelections: ChoiceRequestValidator.validateMinSelections(content),
      maxSelections: ChoiceRequestValidator.validateMaxSelections(content),
      options: ChoiceRequestValidator.validateOptions(content),
    },
  });
}
