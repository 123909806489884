import styled from '@emotion/styled';

export const StyledChartContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 400px;

  ${ props => props.theme.breakpoints.down('lg') } {
    grid-template-columns: none;
    grid-template-rows: auto auto;
    height: auto;
  }

  gap: ${ props => props.theme.spacing(4) };

  >* {
    height: 100%;
    overflow: hidden;
  }
`;
