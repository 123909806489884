import { default as React } from 'react';
import { useIntl } from 'react-intl';

import { OptionEditorComponent } from '../OptionsEditor/OptionsEditor';
import {
  QUIZ_OPTION_TEXT_MAX_LENGTH,
  QUIZ_OPTION_TEXT_MIN_LENGTH,
  QuizRequestValidator,
  DraftChoiceOption
} from '../../../Service';
import { ChoiceOptionEditor } from '..';

export const QuizRequestIncorrectOptionEditor: OptionEditorComponent<DraftChoiceOption> = ({
  option,
  validation,
  onChange,
  onValidationChange,
  removeDisabled,
  onRemoveClicked,
}) => {
  const intl = useIntl();

  return (
    <ChoiceOptionEditor
      option={ option }
      onChange={ onChange }
      validation={ validation }
      onValidationChange={ onValidationChange }
      removeDisabled={ removeDisabled }
      onRemoveClicked={ onRemoveClicked }
      localisedTextFieldLabel={ intl.formatMessage({
        id: 'broadcasts.content.quiz.fields.incorrectOptionText.label',
        description: 'Label for quiz incorrect option text',
        defaultMessage: 'Incorrect answer',
      }) }
      localisedDeleteButtonLabel={ intl.formatMessage({
        id: 'broadcasts.content.quiz.deleteOptionLabel',
        description: 'Delete quiz incorrect option button label visible only to screen-readers.',
        defaultMessage: 'Delete incorrect answer',
      }) }
      validateText={ QuizRequestValidator.validateOptionText }
      minLength={ QUIZ_OPTION_TEXT_MIN_LENGTH }
      maxLength={ QUIZ_OPTION_TEXT_MAX_LENGTH }
    />
  );
}
