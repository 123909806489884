import { useContext, useMemo } from 'react';
import { LocationDescriptor, LocationDescriptorObject } from 'history';

import { LayoutLinkStrategy, PageLayoutContext } from '../../Core/Provider/PageLayoutProvider';
import { useLocationQueryObject } from './useLocationQueryObject';
import { QueryObjectHelper } from '../Utility/QueryObjectHelper';
import { LocationHelper } from '../../../Common/Utility/LocationHelper';

export const useLocationWithRelevantParameters = (location: LocationDescriptor): LocationDescriptor => {
  const layoutContext = useContext(PageLayoutContext);
  const queryPrefix = layoutContext?.queryPrefix;
  const linkStrategy = layoutContext?.linkStrategy;
  const currentQuery = useLocationQueryObject();
  const relevantQuery = useMemo(() => (
    queryPrefix
      ? linkStrategy === LayoutLinkStrategy.PRESERVE_LAYOUT_QUERY
        ? QueryObjectHelper.getPrefixedKeys(currentQuery, queryPrefix) as Record<string, string>
        : QueryObjectHelper.getUnprefixedKeys(currentQuery, queryPrefix) as Record<string, string>
      : {}
  ), [queryPrefix, linkStrategy, currentQuery]);
  const searchParams = useMemo(() => new URLSearchParams(relevantQuery), [relevantQuery]);
  const locationWithPrefixedQueryParams: LocationDescriptor = typeof (location as LocationDescriptorObject)?.search === 'string' && queryPrefix
    ? {
      ...(location as LocationDescriptorObject),
      search: (
        new URLSearchParams(
          QueryObjectHelper.prefixKeys(
            QueryObjectHelper.fromString((location as LocationDescriptorObject).search || ''),
            queryPrefix,
          )
        ).toString()
      ),
    }
    : location;

  return useMemo(() => LocationHelper.addParams(locationWithPrefixedQueryParams, searchParams), [
    locationWithPrefixedQueryParams,
    searchParams
  ]);
};
