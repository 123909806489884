import { PropsWithChildren, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { DebouncedSearchInput } from '@ourpeople/shared/Core/Component/Input/DebouncedSearchInput/DebouncedSearchInput';

import { Tag, TagType } from '../../Model';
import { TagParser } from '../../Utility';
import { TextFormatter } from '../../../Utility';
import { RequestState } from '../../../Models';
import { Picker } from '../../../Components/Input/Picker/Picker';
import { useFetchTags } from '../../Hook';
import { Checkbox } from '../../../Components';
import { SearchFormControl } from '../../../Components/Input/SearchPicker/styles';

interface Props<T extends TagType> {
  selectedTags: Tag<T>[];
  onSelectedTagsChanged: (tags: Tag<T>[]) => void;
  type: T;
}

export const TagPicker = <T extends TagType>({
  selectedTags,
  onSelectedTagsChanged,
  type,
}: PropsWithChildren<Props<T>>): JSX.Element => {
  const intl = useIntl();
  const [search, setSearch] = useState<string>();
  const [fetchTagsResult, fetchState] = useFetchTags(type, 1, search);
  const tags = useMemo(() => {
    const currentPageTags = fetchTagsResult?.content?.tags || [];

    if (search) {
      return currentPageTags;
    }

    const selectedTagsNotInCurrentPage = selectedTags.filter(
      selectedTag => !currentPageTags.find(
        currentPageTag => currentPageTag.id === selectedTag.id,
      ),
    );

    return selectedTagsNotInCurrentPage.concat(currentPageTags);
  }, [search, fetchTagsResult?.content?.tags, selectedTags]);
  const localisedTagType = useMemo(
    () => TagParser.localisedTagTypeFromInternalTagType(type, intl),
    [intl, type],
  );
  const buttonLabel = useMemo(() => TextFormatter.capitalise(localisedTagType), [localisedTagType]);
  const placeholder = useMemo(() => intl.formatMessage(
    {
      id: 'tagFilter.placeholder',
      description: 'Placeholder message for search box in tag filter',
      defaultMessage: 'Search for {tagType}',
    },
    {
      tagType: localisedTagType,
    }
  ), [intl, localisedTagType]);
  const listAriaLabel = useMemo(() => intl.formatMessage(
    {
      id: 'tagFilter.listAriaLabel',
      description: 'Label to describe list of tag options',
      defaultMessage: 'List of {tagType} options',
    },
    {
      tagType: localisedTagType,
    }
  ), [intl, localisedTagType]);

  const whenTagClicked = (clickedTag: Tag<T>) => {
    onSelectedTagsChanged(
      selectedTags.find(tag => tag.id === clickedTag.id)
        ? selectedTags.filter(tag => tag.id !== clickedTag.id)
        : selectedTags.concat(clickedTag),
    );
  };

  return (
    <Picker
      buttonLabel={buttonLabel}
      items={tags}
      listAriaLabel={listAriaLabel}
      selectedItems={selectedTags}
      loading={fetchState < RequestState.COMPLETE}
      itemRenderFn={(tag, selected) => (
        <ListItem
          role="option"
          aria-selected={selected ? 'true' : 'false'}
          key={ tag.id }
          dense
          button
          onClick={ () => whenTagClicked(tag) }
        >
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={ selected }
              disableRipple
            />
          </ListItemIcon>
          <ListItemText
            primary={ tag.name }
          />
        </ListItem>
      )}
      itemCompareFn={(tagA, tagB) => tagA.id === tagB.id}
      preList={
        <SearchFormControl variant="outlined">
          <DebouncedSearchInput
            onChange={setSearch}
            value={search}
            placeholder={placeholder}
            autofocus
          />
        </SearchFormControl>
      }
    />
  );
};
