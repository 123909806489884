import { FC, useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { Button } from 'op-storybook/stories/components/Button/Button';

import { RoundedTextarea } from '../../Input/RoundedTextarea/RoundedTextarea';
import { PagingMenuHeader } from '../PagingMenuHeader/PagingMenuHeader';
import { PagingMenuBackButton } from '../PagingMenuBackButton/PagingMenuBackButton';
import StarsIcon from 'op-storybook/lib/assets/icon/figma/stars-02.svg';
import { DeprecatedPresentationIcon } from '../PresentationIcon/DeprecatedPresentationIcon';
import { PagingMenuContent } from '../PagingMenuContent/PagingMenuContent';
import { AiPromptTextareaContainer } from '../AiPromptTextareaContainer/AiPromptTextareaContainer';
import { AiBetaButton } from '../AiBetaButton/AiBetaButton';
import { ResponsiveButtonContainer } from '../../Layout/ResponsiveButtonContainer/ResponsiveButtonContainer';
import { AiTemplatePrompts } from '../AiTemplatePrompts/AiTemplatePrompts';
import { StyledActionContainer } from './style';

export type TemplatePrompt = {
  key: string;
  label: string;
  input: string;
};

type Props = {
  userPromptLabel: string;
  onBackClicked?: () => void;
  value: string;
  onChange: (value: string) => void;
  onSubmit: () => void;
  templatePrompts: TemplatePrompt[];
  onBetaButtonClicked: () => void;
  onTemplatePromptSelected: (templatePrompt: TemplatePrompt) => void;
  onMoreClicked: () => void;
};

export const AiUserPrompt: FC<Props> = ({
  userPromptLabel,
  onBackClicked,
  value,
  onChange,
  onSubmit,
  templatePrompts,
  onBetaButtonClicked,
  onTemplatePromptSelected,
  onMoreClicked,
}) => {
  const intl = useIntl();
  const waitForValue = useRef<string>();

  useEffect(() => {
    if (!waitForValue.current || value !== waitForValue.current) {
      return;
    }

    onSubmit();
    waitForValue.current = undefined;
  }, [onSubmit, value]);

  return (
    <div>
      <PagingMenuHeader>
        <Stack gap={ 4 }>
          {
            onBackClicked
              ? (
                <PagingMenuBackButton
                  onClick={ onBackClicked }
                />
              )
              : <DeprecatedPresentationIcon IconComponent={ StarsIcon }/>
          }
          <span>
            { userPromptLabel }
          </span>
        </Stack>
      </PagingMenuHeader>
      <PagingMenuContent>
        <form
          id="user-prompt-form"
          onSubmit={ event => {
            event.preventDefault();
            onSubmit();
          } }
        >
          <AiPromptTextareaContainer>
            <RoundedTextarea
              placeholder={ intl.formatMessage({
                description: 'Placeholder text for user prompted AI generation.',
                defaultMessage: 'Need inspiration? Describe what you\'d like to send out and I\'ll help!',
              }) }
              value={ value }
              onChange={ event => onChange(event.currentTarget.value) }
            />
          </AiPromptTextareaContainer>
        </form>
        <span>
          <FormattedMessage
            description="Text presenting temlate options below user prompted AI generation textarea."
            defaultMessage="or create something from a template:"
          />
        </span>
        <AiTemplatePrompts
          templatePrompts={ templatePrompts }
          onTemplatePromptSelected={ onTemplatePromptSelected }
          truncate={ { maxTemplates: 4, onShowMoreClicked: onMoreClicked } }
        />
        <StyledActionContainer>
          <ResponsiveButtonContainer>
            <Button
              disabled={ !value }
              variant="primary"
              type="submit"
              form="user-prompt-form"
            >
              <FormattedMessage
                description="Label for button to submit users AI generation prompt."
                defaultMessage="Submit"
              />
            </Button>
          </ResponsiveButtonContainer>
          <AiBetaButton onClick={ onBetaButtonClicked }/>
        </StyledActionContainer>
      </PagingMenuContent>
    </div>
  );
};
