import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useRef } from 'react';

import { DraftVideoContent, VideoContentDefinition, VideoEditorContent } from '../../Service';
import { ValidationTree } from '../../../Common/Model';
import { DraftSingleContentCard, EditorContent } from '../../../Content/Model';
import { UploadPrepareStatus } from '../../../Files/Hook';
import { useApi } from '../../../Core/Hook';
import { TranscodingProgress } from '../TranscodingProgress/TranscodingProgress';
import { RequestState } from '../../../Models';

type Props = {
  editorContents: EditorContent[];
  setEditorContents: Dispatch<SetStateAction<EditorContent[]>>;
  setCardValidation: Dispatch<SetStateAction<ValidationTree<DraftSingleContentCard[]>>>;
};

export const BroadcastEditorTranscodingProgress: FC<Props> = ({
  editorContents,
  setEditorContents,
  setCardValidation,
}) => {
  const api = useApi();
  const videoDefinition = useMemo(() => new VideoContentDefinition(), []);
  const videoContent = useMemo(
    () => editorContents.filter(
      editorContent => videoDefinition.definesContentType(editorContent.card.content.type)
    ) as VideoEditorContent[],
    [editorContents, videoDefinition],
  );
  const missingThumbnailCount = useMemo(() => (
    videoContent.reduce(
      (total, videoContent) => (
        videoContent.media && !videoContent.card.content.thumbnailKey
          ? total + 1
          : total
      ),
      0,
    )
  ), [videoContent]);
  const uploads = useMemo<UploadPrepareStatus[]>(
    () => videoContent.reduce<UploadPrepareStatus[]>(
      (uploads, content) => content.media?.id
        ? uploads.concat({
          id: content.media.id.toString(),
          prepareStatus: content.prepareStatus,
        })
        : uploads,
      [],
    ),
    [videoContent],
  );
  const processingUploads = useMemo(() => uploads.filter(upload => (
    ['running', 'pending'].includes(upload.prepareStatus)
  )), [uploads]);
  const failedUploadIds = useMemo(() => uploads.filter(upload => (
    upload.prepareStatus === 'failed'
  )).map(upload => upload.id), [uploads]);
  const pendingUploadIds = useMemo(() => processingUploads.map(upload => upload.id), [processingUploads]);
  const transcoding = useMemo(() => !!processingUploads.length, [processingUploads]);
  const transcodeFailed = useMemo(() => uploads.find(upload => upload.prepareStatus === 'failed'), [uploads]);
  const transcodeSuccessful = useMemo(() => uploads.find(upload => upload.prepareStatus === 'complete'), [uploads]);
  const pendingUploadIdsRef = useRef<string[]>(pendingUploadIds);

  useEffect(() => {
    pendingUploadIdsRef.current = pendingUploadIds;
  }, [pendingUploadIds]);

  const updateEditorContentWithPrepareStatuses = useCallback((uploadPrepareStatuses: UploadPrepareStatus[]) => {
    setEditorContents(
      editorContents => editorContents.map<EditorContent>(
        editorContent => {
          if (!videoDefinition.definesContentType(editorContent.card.content.type)) {
            return editorContent;
          }

          const videoContent = editorContent as VideoEditorContent;

          if (!videoContent.media?.id) {
            return editorContent;
          }

          const uploadId = videoContent.media.id;
          const uploadPrepareStatus = uploadPrepareStatuses.find(
            uploadPrepareStatus => uploadPrepareStatus.id === uploadId
          );

          if (!uploadPrepareStatus) {
            return videoContent;
          }

          return {
            ...videoContent,
            prepareStatus: uploadPrepareStatus.prepareStatus,
          } as VideoEditorContent;
        },
      ),
    );
  }, [setEditorContents, videoDefinition]);

  useEffect(() => {
    let timeout: number;
    let cancelled: boolean = false;

    const checkUploadStatuses = () => {
      if (!pendingUploadIdsRef.current.length) {
        timeout = window.setTimeout(
          checkUploadStatuses,
          5000,
        );
        return;
      }

      api.get<UploadPrepareStatus[]>(
        '/uploads/prepare-status',
        { params: { uploadIds: pendingUploadIdsRef.current.join(',') } }
      )
        .then(response => {
          if (cancelled) {
            return;
          }

          updateEditorContentWithPrepareStatuses(response.data);
        })
        .catch(() => {
          // Do nothing, check again after interval
        })
        .finally(() => {
          if (cancelled) {
            return;
          }

          timeout = window.setTimeout(
            checkUploadStatuses,
            5000,
          );
        });
    }

    checkUploadStatuses();

    return () => {
      cancelled = true;
      clearTimeout(timeout);
    };
  }, [api, updateEditorContentWithPrepareStatuses]);

  useEffect(() => (
    setCardValidation(cardValidations => ({
      errors: cardValidations.errors,
      children: editorContents.map((editorContent, index) => {
        if (editorContent.card.content.type !== 'videocontent') {
          return cardValidations.children[index] || {
            errors: [],
            children: {},
          };
        }

        const videoContent = editorContent as VideoEditorContent;
        const videoContentValidation: ValidationTree<DraftSingleContentCard<DraftVideoContent>> = cardValidations.children[index] || {
          errors: [],
          children: {},
        };

        return {
          errors: videoContentValidation.errors || [],
          children: {
            ...videoContentValidation.children,
            content: {
              errors: videoContentValidation.children.content?.errors || [],
              children: {
                ...videoContentValidation.children.content?.children,
                media: {
                  errors: failedUploadIds.includes(videoContent.card.content.media.toString())
                    ? (videoContentValidation.children.content?.children.media?.errors || []).filter(error => error.type !== 'transcodingFailed').concat({
                      type: 'transcodingFailed',
                      metadata: null,
                    })
                    : (videoContentValidation.children.content?.children.media?.errors || []).filter(error => error.type !== 'transcodingFailed'),
                  children: {},
                },
              },
            },
          },
        } as ValidationTree<DraftSingleContentCard<DraftVideoContent>>;
      })
    }))
  ), [editorContents, failedUploadIds, setCardValidation]);

  return (
    <TranscodingProgress
      videosMissingThumbnails={ missingThumbnailCount }
      state={
        transcodeSuccessful
          ? RequestState.COMPLETE
          : transcodeFailed
            ? RequestState.FAILED
            : transcoding
              ? RequestState.FETCHING
              : RequestState.PENDING
      }
    />
  );
};
