import { FC, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from 'op-storybook/stories/components/Typography/Typography';

type Props = {
  remainingDurationInSeconds: number;
};

export const CodeRateLimitMessage: FC<Props> = ({ remainingDurationInSeconds }) => (
  <Typography
    align="center"
    palette={ {
      colour: 'grey',
      intensity: 500,
    } }
  >
    <FormattedMessage
      defaultMessage="A code has recently been sent. Try again in <strong>{ remainingDurationInSeconds }</strong> seconds"
      description="Try again message when OTP request has been rate-limited."
      values={ {
        remainingDurationInSeconds,
        strong: (chunks: ReactNode) => (
          <Typography
            weight="semibold"
            palette={ {
              colour: 'grey',
              intensity: 500,
            } }
          >
            { chunks }
          </Typography>
        ),
      } }
    />
  </Typography>
);
