import { FunctionComponent, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';

import { HotAudienceMemberList } from '../../../Audiences/Component';
import { Audience } from '../../../Audiences/Model';
import { H2 } from '../../../Components/Layout/headings';
import { Box, Flex, FlexPullRight, VerticallySpaced } from '../../../Common/Component';
import { ValidationTree } from '../../../Common/Model';
import { useResponsiveSpacing } from '../../../Common/Hook';
import { AudienceEditor } from '../../../Audiences/Component/AudienceEditor/AudienceEditor';
import { RecentAudiencesDropdown } from '..';
import { StyledAudienceCriteriaHeaderContainer } from './style';
import { RecentBroadcastAudience } from '../../Model';
import {AudienceConditionDefinitionRegistryProvider} from '../../../Audiences/Provider';

interface Props {
  audience: Audience;
  onChange: (audience: Audience) => void;
  validation?: ValidationTree<Audience>;
  onNextButtonClicked?: () => void;
  alwaysEdit: boolean;
  editing: boolean;
  onEditButtonClicked: () => void;
  onRevertButtonClicked: () => void;
  audienceModified: boolean;
  onRecentAudienceSelected: (recentAudience: RecentBroadcastAudience) => void;
  onRecentAudiencesShown?: () => void;
}

export const RecipientAudienceEditor: FunctionComponent<Props> = ({
  audience,
  onChange,
  validation,
  onNextButtonClicked,
  alwaysEdit,
  editing,
  onEditButtonClicked,
  onRevertButtonClicked,
  audienceModified,
  onRecentAudienceSelected,
  onRecentAudiencesShown,
}) => {
  const [audienceSize, setAudienceSize] = useState<number>();

  useEffect(() => {
    setAudienceSize(undefined);
  }, [audience]);

  return (
    <VerticallySpaced gap={ useResponsiveSpacing() }>
      <Box margin={ false }>
        <StyledAudienceCriteriaHeaderContainer>
          <H2>
            <FormattedMessage
              id="broadcasts.audience.heading"
              description="Heading shown on the audience step of the broadcasts builder"
              defaultMessage="Audience Criteria"
            />
          </H2>
          <AudienceConditionDefinitionRegistryProvider>
            <RecentAudiencesDropdown
              onShown={ onRecentAudiencesShown }
              onSelected={ onRecentAudienceSelected }
            />
          </AudienceConditionDefinitionRegistryProvider>
        </StyledAudienceCriteriaHeaderContainer>
        <AudienceEditor
          audienceSize={ audienceSize }
          audienceSizeLinkUrl="#recipients"
          audience={ audience }
          onChange={ onChange }
          validation={ validation }
          editing={ editing || alwaysEdit || audienceModified }
          showReach={ !alwaysEdit }
          multipleSegments={ true }
          onEditButtonClicked={ onEditButtonClicked }
          onRevertButtonClicked={ onRevertButtonClicked }
          alwaysEdit={ alwaysEdit }
        />
      </Box>
      <Box margin={ false }>
        <HotAudienceMemberList
          id="recipients"
          audience={ audience }
          applyReach={ alwaysEdit || editing || audienceModified }
          context="live"
          onSizeCalculated={ setAudienceSize }
        />
        { onNextButtonClicked && (
          <Flex>
            <FlexPullRight>
              <Button
                variant="primary"
                onClick={ onNextButtonClicked }
              >
                <FormattedMessage
                  id="broadcasts.create.next"
                  description="Label for button to advance to next section of broadcast creation"
                  defaultMessage="Next"
                />
              </Button>
            </FlexPullRight>
          </Flex>
        ) }
      </Box>
    </VerticallySpaced>
  );
};
