import { ErrorResponseReader } from '../../Common/Utility';
import { AxiosErrorWithResponse, ErrorResponse } from '../../Common/Model';

type InsufficientAnonymousSubmissionsError = {
  minCount: number;
};

export class FormErrorResponseReader {
  public static isInsufficientAnonymousSubmissionsError = (
    error: unknown,
  ): error is AxiosErrorWithResponse<ErrorResponse<InsufficientAnonymousSubmissionsError>> => (
    ErrorResponseReader.isApiError(error) && error.response.data.error.code === 'INSUFFICIENT_ANON_SUBMISSIONS'
  );
}
