import { useIntl } from 'react-intl';
import { FC, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AxiosError } from 'axios';

import { Box, PageHeader } from '../../../../Common/Component';
import { Padded } from '../../../../Components';
import { TopicEditForm } from '../../../Component';
import { DraftTopic, Topic } from '../../../Model';
import { AudienceProvider } from '../../../../Audiences/Test';
import { useMounted } from '../../../../Common/Hook';
import { useApi, useContextOrThrow } from '../../../../Core/Hook';
import { Audience } from '../../../../Audiences/Model';
import { HotAudienceMemberList } from '../../../../Audiences/Component';
import { ErrorResponseReader } from '../../../../Common/Utility';
import { ValidationTree } from '../../../../Common/Model';
import { AudienceCleaner } from '../../../../Audiences/Utility';
import { ToastContext } from '../../../../Core/Context';
import { useManageTopicsTitle } from '../../../Hook/useManageTopicsTitle';

export const TopicCreatePage: FC = () => {
  const intl = useIntl();
  const { addSuccessToast, addErrorToast } = useContextOrThrow(ToastContext);
  const api = useApi();
  const mounted = useMounted();
  const [saving, setSaving] = useState<boolean>(false);
  const [apiValidation, setApiValidation] = useState<ValidationTree<DraftTopic>>();
  const history = useHistory();
  const manageTopicsTitle = useManageTopicsTitle();

  const save = useCallback(({ description, ...topic }: DraftTopic) => {
    setSaving(true);
    setApiValidation(undefined);
    api.post<{ topic: Topic }>('/chats/topics', {
      ...topic,
      ...(description ? { description } : {}),
      audience: AudienceCleaner.cleanAudience(topic.audience),
    })
      .then(response => {
        setTimeout(() => {
          if (!mounted.current) {
            return;
          }

          addSuccessToast(
            intl.formatMessage({
              id: 'topics.create.success',
              description: 'Toast message displayed when topic is created successfully.',
              defaultMessage: '{ name } created successfully.'
            }, { name: response.data.topic.name })
          );
          history.replace(`/chats/topics/${ response.data.topic.id }/edit`);
        }, 50);
      })
      .catch((error: AxiosError<unknown>) => {
        if (!mounted.current) {
          return;
        }

        ErrorResponseReader.isApiError(error) && ErrorResponseReader.isValidationErrorResponse(error.response) && setApiValidation(error.response.data.error.data.root);

        addErrorToast(
          intl.formatMessage({
            id: 'topics.create.error',
            description: 'Toast message displayed when topic can not be created.',
            defaultMessage: '{ name } could not be created.'
          }, {
            name: topic.name || intl.formatMessage({
              id: 'topics.create.error.name',
              description: 'Fallback topic name used in create error if topic has no name.',
              defaultMessage: 'Topic',
            }),
          })
        );
        setSaving(false);
      })
  }, [addErrorToast, addSuccessToast, api, history, intl, mounted]);

  const renderAudienceMembers = useCallback((audience: Audience) => (
    <HotAudienceMemberList
      audience={ audience }
      context="default"
      showStatus={ false }
      applyReach={ false }
    />
  ), []);

  return (
    <>
      <Padded>
        <PageHeader
          items={ [
            {
              link: '/chats/topics',
              title: manageTopicsTitle,
            },
            {
              link: '/chats/topics/create',
              title: intl.formatMessage({
                id: 'section.chat.topics.create',
                description: 'Heading label for topics create page',
                defaultMessage: 'Create',
              }),
            },
          ] }
        >
        </PageHeader>
        <Box>
          <TopicEditForm
            initialState={ defaultTopic }
            validation={ apiValidation }
            onSubmit={ save }
            submitting={ saving }
            renderAudienceMembers={ renderAudienceMembers }
          />
        </Box>
      </Padded>
    </>
  );
};

const defaultTopic: DraftTopic = {
  name: '',
  description: '',
  autoJoin: true,
  audience: AudienceProvider.everyone(),
};
