import { ComponentProps, FC, PropsWithChildren, useEffect, useMemo } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { ApiRequest } from '@ourpeople/shared/Core/Model/ApiRequest';

import { LogoutPage } from '../../../Security/Routing';
import { GuardedRoute } from '../../../Common/Component';
import { useContextOrThrow } from '../../Hook';
import { LoginStateContext } from '../../Provider/LoginStateProvider';
import { AnonymousSettingsRouter } from '../AnonymousSettingsRouter/AnonymousSettingsRouter';
import { IdentityContext } from '../../Provider/IdentityProvider/IdentityProvider';
import { RequestState } from '../../../Models';
import { IdentityReader } from '../../Utility/IdentityReader/IdentityReader';
import { Identity } from '../../Model/Identity/Identity';

export const LoginRouter: FC<PropsWithChildren> = ({
  children,
}) => {
  const { loggedIn } = useContextOrThrow(LoginStateContext);
  const { identityRequest } = useContextOrThrow(IdentityContext);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const useOtpLogin = searchParams.get('otpLogin') === '1';
  const redirect = useMemo(() => getRedirect(identityRequest, useOtpLogin), [identityRequest, useOtpLogin]);

  return (
    <Switch>
      <Route exact path="/logout">
        <LogoutPage/>
      </Route>
      <Route
        path={ [
          '/login',
          '/register',
          '/set-password',
          '/forgot-password',
          '/invitation',
        ] }
      >
        <AnonymousSettingsRouter/>
      </Route>
      { loggedIn !== null && (
        <GuardedRoute
          path="/"
          condition={ loggedIn }
          recordTargetUrl={ true }
          redirect={ redirect }
        >
          { children }
        </GuardedRoute>
      ) }
    </Switch>
  );
};

const getRedirect = (identityRequest: ApiRequest<Identity>, useOtpLogin?: boolean): ComponentProps<typeof GuardedRoute>['redirect'] => {
  if (identityRequest.state !== RequestState.COMPLETE || !IdentityReader.identityHasIdentityAuthentication(identityRequest.result) || !useOtpLogin) {
    return '/login';
  }

  return {
    pathname: '/login/otp/request',
    state: {
      identity: identityRequest.result,
    },
  };
};
