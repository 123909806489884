import { useIntl } from 'react-intl';
import { FC } from 'react';

import { MediaBroadcastContent } from '..';
import FileIcon from '../../../../Assets/img/icons/monochrome/content/file.svg';
import { MediaPresentationComponentProps } from '../../../Model';

export const FileBroadcastContent: FC<MediaPresentationComponentProps> = ({
  text,
  media,
  openFileBusy,
  onOpenFileClicked,
  navigationProps,
  nudge,
  backButton,
}) => {
  const intl = useIntl();
  return (
    <MediaBroadcastContent
      text={ text }
      media={ media }
      nudge={ nudge }
      IconComponent={ FileIcon }
      navigationProps={ navigationProps }
      openFileBusy={ openFileBusy }
      onOpenFileClicked={ onOpenFileClicked }
      openFileLabel={ intl.formatMessage({
        id: 'content.broadcasts.file.open',
        description: 'File content open file button label.',
        defaultMessage: 'Open file',
      }) }
      backButton={ backButton }
    />
  );
};
