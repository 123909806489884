import { Dispatch, FunctionComponent, SetStateAction, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Heading } from '@ourpeople/shared/Core/Component/Content';
import { AutocompleteSelectionChanged } from '@ourpeople/shared/Core/Component/Input/Autocomplete/Autocomplete';

import { InputContainer } from './style';
import { Box, Divider, LabelledSwitch, VerticallySpaced } from '../../../Common/Component';
import { AnonymousResponsesSwitch, GlobalAdminNotice, SubmissionTablePreview } from '..';
import { DraftSingleContentForm, PublishStatus } from '../../Model';
import { AdminAutocomplete } from '../../../People/Component';
import { Person } from '../../../Models';

type Props = {
  publishStatus: PublishStatus;
  form: DraftSingleContentForm;
  setForm: Dispatch<SetStateAction<DraftSingleContentForm>>;
};

export const ReportingSettings: FunctionComponent<Props> = ({
  publishStatus,
  form,
  setForm,
}) => {
  const whenStatusTrackerEnabledChanged = useCallback((statusTrackerEnabled: boolean) => {
    setForm(form => ({
      ...form,
      statusTrackerEnabled,
    }));
  }, [setForm]);

  const whenReportingAudiencePersonSelectionChanged: AutocompleteSelectionChanged<Person> = useCallback(selection => {
    setForm(form => ({
      ...form,
      reportingAudiencePersonIds: selection.options.map(option => option.id).concat(selection.unknownIds),
    }));
  }, [setForm]);

  const whenNotificationAudiencePersonSelectionChanged: AutocompleteSelectionChanged<Person> = useCallback(selection => {
    setForm(form => ({
      ...form,
      notificationAudiencePersonIds: selection.options.map(option => option.id).concat(selection.unknownIds),
    }));
  }, [setForm]);

  const whenAdminAudiencePersonSelectionChanged: AutocompleteSelectionChanged<Person> = useCallback(selection => {
    setForm(form => ({
      ...form,
      adminAudiencePersonIds: selection.options.map(option => option.id).concat(selection.unknownIds),
    }));
  }, [setForm]);

  const whenAnonymousSubmissionsEnabledChanged = useCallback((checked: boolean) => (
    setForm(form => ({
      ...form,
      anonymousSubmissionsEnabled: checked,
    }))
  ), [setForm]);

  return (
    <Box margin={ false }>
      <VerticallySpaced gap={ 2 }>
        <Heading type="h1">
          <FormattedMessage
            id="forms.audience.anonymity.heading"
            description="Heading shown on the anonymity step of the form builder"
            defaultMessage="Anonymity"
          />
        </Heading>
        { (publishStatus === 'draft' || form.anonymousSubmissionsEnabled) && (
          <AnonymousResponsesSwitch
            disabled={ publishStatus !== 'draft' }
            checked={ form.anonymousSubmissionsEnabled }
            onChange={ whenAnonymousSubmissionsEnabledChanged }
          />
        ) }
        <span>
              <FormattedMessage
                id="forms.audience.anonymity.description"
                description="Description for anonymity step of the form builder"
                defaultMessage="Anonymous responses { anonymous, select, true {will} other {will not} } be collected. This setting cannot be changed { draft, select, true {after this form has been published for the first time.} other {as this form has been previously published.} }"
                values={ {
                  anonymous: form.anonymousSubmissionsEnabled,
                  draft: publishStatus === 'draft',
                } }
              />
            </span>
        <Divider/>
        <Heading type="h1">
          <FormattedMessage
            id="forms.audience.reporting.heading"
            description="Heading shown on the reporting step of the form builder"
            defaultMessage="Reporting settings"
          />
        </Heading>
        <Heading type="h2">
          <FormattedMessage
            id="forms.audience.reporting.subheading"
            description="Sub heading shown on the reporting step of the form builder, explaining what the input is for."
            defaultMessage="Who should see responses?"
          />
        </Heading>
        <InputContainer>
          <VerticallySpaced gap={ 1 }>
            <AdminAutocomplete
              excludeBroadcastAdmin
              excludeGlobalAdmin
              selectedIds={ form.reportingAudiencePersonIds || [] }
              onSelectionChanged={ whenReportingAudiencePersonSelectionChanged }
              multiple
            />
            <GlobalAdminNotice />
          </VerticallySpaced>
        </InputContainer>
        <Heading type="h2">
          <FormattedMessage
            id="forms.audience.reporting.adminAudience"
            description="Description for admin audience people autocomplete."
            defaultMessage="Who should be able to make changes to this form?"
          />
        </Heading>
        <InputContainer>
          <VerticallySpaced gap={ 1 }>
            <AdminAutocomplete
              excludeBroadcastAdmin
              excludeGlobalAdmin
              selectedIds={ form.adminAudiencePersonIds || [] }
              onSelectionChanged={ whenAdminAudiencePersonSelectionChanged }
              multiple
            />
            <GlobalAdminNotice />
          </VerticallySpaced>
        </InputContainer>
        <Heading type="h2">
          <FormattedMessage
            description="Description for form submission notification audience people autocomplete."
            defaultMessage="Who should receive notifications about form submissions?"
          />
        </Heading>
        <InputContainer>
          <VerticallySpaced gap={ 1 }>
            <AdminAutocomplete
              excludeBroadcastAdmin
              selectedIds={ form.notificationAudiencePersonIds || [] }
              onSelectionChanged={ whenNotificationAudiencePersonSelectionChanged }
              multiple
            />
          </VerticallySpaced>
        </InputContainer>
        <Divider/>
        <Heading type="h1">
          <FormattedMessage
            id="forms.audience.reporting.statusTracker"
            description="Heading for status tracker section"
            defaultMessage="Status tracker"
          />
        </Heading>
        <LabelledSwitch
          id="statusTracker"
          label={
            <FormattedMessage
              id="forms.audience.reporting.enableStatusTracker"
              description="Label for status tracker input"
              defaultMessage="Enable status tracker"
            />
          }
          onChange={ whenStatusTrackerEnabledChanged }
          checked={ form.statusTrackerEnabled }
        />
        <p>
          <FormattedMessage
            id="forms.reporting.statusTrackerDescription"
            description="Description of status tracker setting."
            defaultMessage="Allow administrators to mark submissions as closed via the action log on console."
          />
        </p>
        { form.statusTrackerEnabled && <SubmissionTablePreview anonymous={ form.anonymousSubmissionsEnabled }/> }
      </VerticallySpaced>
    </Box>
  );
}
