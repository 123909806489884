import { ContextCreator } from '@ourpeople/shared/Core/Utility/ContextCreator';
import { FC, PropsWithChildren } from 'react';

export enum LayoutLinkStrategy {
  PRESERVE_LAYOUT_QUERY,
  DISCARD_LAYOUT_QUERY,
}

export interface PageLayoutContextValue {
  queryPrefix?: string;
  linkStrategy?: LayoutLinkStrategy;
  active: boolean;
  modal: boolean;
  controlOverflow: boolean;
  viewportBoundaries: {
    left: boolean;
    right: boolean;
    top: boolean;
    bottom: boolean;
  };
}

export const PageLayoutContext = ContextCreator.withDisplayName<PageLayoutContextValue>('PageLayoutContext', null);

export const PageLayoutProvider: FC<PropsWithChildren<PageLayoutContextValue>> = ({
  children,
  ...contextValue
}) => (
  <PageLayoutContext.Provider
    value={ contextValue }
  >
    { children }
  </PageLayoutContext.Provider>
);
