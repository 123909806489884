import { FunctionComponent } from 'react';

import { ValidationError } from '../../../Common/Model';
import { EditableContent, ErrorCell, TruncatingContent } from '../../../Common/Component';
import { ImportErrorPresentationComponent, MappedColumn } from '../../Model';

type Props = {
  errors: ValidationError[];
  value: string;
  columnMapping?: MappedColumn;
  index: number;
  onChange: (index: number, value: string) => void;
  ErrorComponent: ImportErrorPresentationComponent;
  edited?: boolean;
  minWidth?: string;
}

export const ReviewCell: FunctionComponent<Props> = ({
  errors,
  value,
  columnMapping,
  index,
  onChange,
  ErrorComponent,
  edited,
  minWidth = '20rem',
}) => (
  <ErrorCell
    errors={ errors }
    ErrorComponent={ ErrorComponent }
    columnMapping={ columnMapping }
    value={ value }
    minwidth={ minWidth }
  >
    <EditableContent
      value={ value }
      onChange={ value => onChange(index, value) }
      edited={ edited }
    >
      <TruncatingContent>
        { value }
      </TruncatingContent>
    </EditableContent>
  </ErrorCell>
);
