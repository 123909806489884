import { useEnvironmentSettings } from './useEnvironmentSettings';

export enum IntegrationDeactivateBehaviour {
  IGNORE,
  FREEZE,
}

export const useIntegrationDeactivationBehaviour = (): IntegrationDeactivateBehaviour => {
  const { integrationManageFrozen } = useEnvironmentSettings();

  return integrationManageFrozen
      ? IntegrationDeactivateBehaviour.FREEZE
      : IntegrationDeactivateBehaviour.IGNORE
};
