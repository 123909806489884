import { Menu } from '@mui/material';
import styled from '@emotion/styled';

export const StyledMenu = styled(Menu)`
  max-height: 450px;
`;

export const StyledCountryName = styled.span`
  font-weight: 500;
`;

export const StyledCallingCode = styled.span`
  color: ${ props => props.theme.new.palette.grey[600].main };
  font-weight: 300;
`;
