import { ObjectHelper } from '../../../Common/Utility/ObjectHelper';

export class QueryObjectHelper {
  public static prefixKeys<T extends Record<string, unknown>, P extends string>(obj: T, prefix: P): T {
    return Object.fromEntries(
      Array.from(Object.entries(obj))
        .map(([key, value]) => [prefix ? `${ prefix }_${ key }` : key, value])
    ) as T;
  }

  public static unprefixKeys<T extends Record<string, unknown>, P extends string>(obj: T, prefix: P): T {
    return Object.fromEntries(
      Array.from(Object.entries(obj))
        .map(([key, value]) => [key.replace(new RegExp(`^${ prefix }_`), ''), value])
    ) as T;
  }

  public static getUnprefixedKeys<T extends Record<string, unknown>, P extends string>(obj: T, prefix: P): Partial<T> {
    return ObjectHelper.filterKeys(obj, key => !stringBeginsWithSubstr(key, prefix));
  }

  public static getPrefixedKeys<T extends Record<string, unknown>, P extends string>(obj: T, prefix: P): Partial<T> {
    return ObjectHelper.filterKeys(obj, key => stringBeginsWithSubstr(key, prefix));
  }

  public static fromString(queryString: string) {
    return Object.fromEntries(new URLSearchParams(queryString).entries());
  }
}

const stringBeginsWithSubstr = (str: string, substr: string): boolean => !!new RegExp(`^${ substr }`).exec(str)

