import styled from '@emotion/styled';
import { Menu } from '@mui/material';

export const StyledTableMenuContainer = styled.div`
  svg {
    fill: ${props => props.theme.palette.secondary.main};
  }
  .MuiIconButton-root {
    &.Mui-disabled {
      svg {
        fill: ${props => props.theme.palette.grey[400]};
      }
    }
  }
`;

export const StyledTableMenu = styled(Menu)`
  a {
    color: ${props => props.theme.palette.text.primary};
  }
  .MuiListItemIcon-root {
    color: ${props => props.theme.palette.text.primary};
    min-width: 2rem;
    svg {
      width: 1.35rem;
      height: 1.35rem;
      fill: ${props => props.theme.palette.text.primary};
    }
  }
  .MuiTypography-body1 {
    font-size: 1.1rem;
  }
`;
