import { useEffect, useState } from 'react';
import axios, { AxiosError } from 'axios';
import { CountryCode } from 'libphonenumber-js';
import { AppUsagePolicyLink } from 'op-storybook/lib/components/PolicyLinks/PolicyLinks';

import { FetchResult } from '../../Models';

export interface AnonymousSettings {
  name: string;
  appPasswordLoginEnabled: boolean;
  appUsagePolicyLinks: AppUsagePolicyLink[];
  defaultCountryCode: CountryCode;
  ssoOptions: SsoOption[];
  smallLogoUrl: string;
  userSelfRegistration: [string];
  invitationEmailMessage: string;
}

export interface SsoOption {
  authUrl: string;
  issuer: string;
}

export const useFetchAnonymousSettings = (): FetchResult<AnonymousSettings> | null => {
  const [result, setResult] = useState<FetchResult<AnonymousSettings> | null>(null);

  useEffect(() => {
    let cancelled = false;

    axios.get<AnonymousSettings>(
      '/api/anon/settings'
    )
      .then(response => {
        if (!cancelled) {
          setResult(FetchResult.fromContent(response.data))
        }
      })
      .catch((error: AxiosError<unknown>) => {
        if (!cancelled) {
          setResult(FetchResult.fromError(error))
        }
      });

    return () => { cancelled = true };
  }, []);

  return result;
};
