import { useMemo } from 'react';

import { Tag, TagType } from '../Model';
import { Paginated } from '../../Models';
import { FetchResponse, useGet } from '../../Hooks';

export type FetchTagsSortString = 'created_at_asc' | 'created_at_desc' | 'name_asc' | 'name_desc' | 'id_asc' | 'id_desc';

export type FetchTagsQuery = {
  pageNum?: number,
  sort?: string;
  search?: string;
  type?: string;
  ids?: string;
  childTagIds?: string;
  parentTagIds?: string;
}

export const useFetchTags = <T extends TagType>(
  type: T,
  pageNum: number,
  search?: string,
  sort: FetchTagsSortString = 'name_asc',
  childTagIds?: string,
  parentTagIds?: string,
  ids?: string,
): FetchResponse<Paginated<'tags', Tag<T>>> => {
  const endpoint = '/tags';
  const params = useMemo<FetchTagsQuery>(() => ({
    sort,
    search: search || undefined,
    type,
    ids: ids || undefined,
    childTagIds: childTagIds || undefined,
    parentTagIds: parentTagIds || undefined,
    pageNum,
  }), [sort, search, type, ids, childTagIds, parentTagIds, pageNum]);
  return useGet<Paginated<'tags', Tag<T>>>(endpoint, params);
};
