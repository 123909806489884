import { MessagePayload, OpPushMessageData, PushMessageDefinition } from '../../Core/Model/PushMessage';

export interface BroadcastReceivedPushMessageData extends OpPushMessageData {
  opBroadcastId: string;
  opBroadcastDeliveryId: string;
}

export class BroadcastReceivedPushMessageDefinition implements PushMessageDefinition<MessagePayload<BroadcastReceivedPushMessageData>> {
  public defines = (message: MessagePayload): message is MessagePayload<BroadcastReceivedPushMessageData> => (
    definedTypes.includes(message.data.opType)
  );

  public discard = (message: MessagePayload<BroadcastReceivedPushMessageData>): boolean => {
    return window.location.pathname.includes(`/inbox/${ message.data.opBroadcastDeliveryId }`);
  };

  public onClick: PushMessageDefinition<MessagePayload<BroadcastReceivedPushMessageData>>['onClick'] = (message, onDismiss) => {
    onDismiss();
    window.location.href = `/inbox/${ message.data.opBroadcastDeliveryId }`;
  };
}

const definedTypes = [
  'broadcastReceived',
  'broadcastNudge',
  'coverAssigned',
  'coverRequestedAuthor',
  'coverAssignedAuthor',
  'eventCancelled',
  'eventAttending',
  'eventAttendanceRemoved',
  'trainingFeedback',
];
