import { FC, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AutocompleteSelection } from '@ourpeople/shared/Core/Component/Input/Autocomplete/Autocomplete';

import { TagAutocomplete } from '../../../Tags/Component';
import { StyledHeaderRow, StyledProfileForm } from './style';
import { IconButton, ValidationErrorMessage, VerticallySpaced } from '../../../Common/Component';
import RemoveIcon from '../../../Assets/img/icons/streamline/bin-1.svg';
import { MinimalTag, Tag, TagType } from '../../../Tags/Model';
import { useLoggedInAuthDescription } from '../../../Core/Hook';
import { ValidationTree } from '../../../Common/Model';

export interface DraftProfile {
  id?: string;
  tags: MinimalTag[],
  externallyManaged: boolean,
}

type Props = {
  profile: DraftProfile;
  onChange: (profile: DraftProfile) => void;
  onRemove?: () => void;
  modified?: boolean;
  validation?: ValidationTree<DraftProfile>;
};

export const ProfileForm: FC<Props> = ({
  profile,
  validation,
  onChange,
  onRemove,
  modified = false,
}) => {
  const { administratedTags } = useLoggedInAuthDescription();
  const canRemoveProfile = useMemo(() => (
    !profile.externallyManaged
    && profile.tags
      .filter(tag => tag.type === 'team')
      .every(tag => administratedTags.includes(tag.id))
  ), [administratedTags, profile.tags, profile.externallyManaged]);
  const intl = useIntl();
  const teams = useMemo(() => (
    profile.tags.filter(tag => tag.type === 'team') as Tag<'team'>[]
  ), [profile.tags]);
  const skills = useMemo(() => (
    profile.tags.filter(tag => tag.type === 'skill') as Tag<'skill'>[]
  ), [profile.tags]);
  const jobTitles = useMemo(() => (
    profile.tags.filter(tag => tag.type === 'jobtitle') as Tag<'jobtitle'>[]
  ), [profile.tags]);
  const teamIds = useMemo(() => teams.map(team => team.id), [teams]);
  const skillIds = useMemo(() => skills.map(skill => skill.id), [skills]);
  const jobTitleIds = useMemo(() => jobTitles.map(jobTitle => jobTitle.id), [jobTitles]);
  const hasError = !!(validation?.errors || validation?.children);

  const whenSelectionChanged = useCallback(<T extends TagType>(tagType: T, selection: AutocompleteSelection<Tag<T>>) => {
    onChange({
      ...profile,
      tags: profile.tags.filter(tag => tag.type !== tagType)
        .concat(selection.options.map(tag => ({
          id: tag.id,
          type: tagType,
          name: tag.name,
        }))),
    });
  }, [onChange, profile]);

  return (
    <StyledProfileForm
      modified={ modified }
      error={ hasError }
    >
      <StyledHeaderRow>
        <VerticallySpaced>
          <TagAutocomplete
            type="team"
            selectedIds={ teamIds }
            allowedIds={ administratedTags }
            onSelectionChanged={ selection => whenSelectionChanged('team', selection) }
            listStyle="grid"
            prefetchedTags={ teams }
            error={ hasError }
            showDisallowed
            multiple
            readonly={ profile.externallyManaged }
          />
          { hasError && (
            <ValidationErrorMessage>
              <FormattedMessage
                description="Error message when profile doesn't have a team."
                defaultMessage="Profiles must include at least one team."
              />
            </ValidationErrorMessage>
          ) }
        </VerticallySpaced>
        { onRemove && (
          <IconButton
            IconComponent={ RemoveIcon }
            label={ intl.formatMessage({
              description: 'Label for remove button on edit account page.',
              defaultMessage: 'Remove profile',
            }) }
            onClick={ onRemove }
            showTooltip
            disabled={ !canRemoveProfile }
            disabledTooltip={ intl.formatMessage({
              description: 'Label for remove button on edit account page when disabled.',
              defaultMessage: 'You are not allowed to remove this profile.',
            }) }
            size="small"
          />
        ) }
      </StyledHeaderRow>
      { !!teams.length && (
        <>
          <TagAutocomplete
            type="jobtitle"
            selectedIds={ jobTitleIds }
            onSelectionChanged={ selection => whenSelectionChanged('jobtitle', selection) }
            listStyle="grid"
            prefetchedTags={ jobTitles }
            multiple
            readonly={ profile.externallyManaged }
          />
          <TagAutocomplete
            type="skill"
            selectedIds={ skillIds }
            onSelectionChanged={ selection => whenSelectionChanged('skill', selection) }
            listStyle="grid"
            prefetchedTags={ skills }
            multiple
            readonly={ profile.externallyManaged }
          />
        </>
      ) }
    </StyledProfileForm>
  );
};
