import { keyframes } from '@emotion/react';
import { FC, useMemo } from 'react';

import { StyleBuilder } from '../../../lib/model/StyleBuilder/StyleBuilder';
import { PaletteLookup } from '../../../lib/model/PaletteLookup/PaletteLookup';
import { defaultTheme } from '../../../lib/const/defaultTheme';

type Props = {
  size?: 'xs' | 'sm' | 'md' | 'lg';
  palette?: PaletteLookup;
  className?: string;
  delayMs?: number;
};

export const ProgressCircle: FC<Props> = ({
  size = 'sm',
  palette,
  className,
  delayMs = 0,
}) => {
  const sizeNumeric = size === 'lg'
    ? 16
    : size === 'md'
      ? 12
      : size === 'sm'
        ? 8
        : 4
  const styles = useMemo(() => buildStyles({ size: sizeNumeric, palette, delayMs }), [sizeNumeric, palette, delayMs]);

  return (
    <div
      role="status"
      css={ styles.container }
      className={ className }
    >
      <svg
        viewBox="0 0 28 28"
        css={ styles.svg }
      >
        <circle
          cx="14"
          cy="14"
          r="13"
          fill="none"
          strokeWidth="2"
          css={ styles.circle }
        ></circle>
      </svg>
    </div>
  );
};

const fadeIn = keyframes({
  '0%': {
    opacity: 0,
  },

  '100%': {
    opacity: 1,
  },
});

const rotate = keyframes`
    100% {
        transform: rotate(360deg);
    }
`;

const dash = keyframes`
    0% {
        stroke-dasharray: 4, 100;
        stroke-dashoffset: 0;
    }
    60% {
        stroke-dasharray: 100, 100;
        stroke-dashoffset: -25;
    }
    100% {
        stroke-dasharray: 100, 100;
        stroke-dashoffset: -78;
    }
`;

type StyleProps = {
  palette: PaletteLookup;
  size: keyof typeof defaultTheme.new.spacing;
  delayMs: number;
};

const buildStyles: StyleBuilder<StyleProps> = ({ size, palette, delayMs }) => ({
  container: theme => ({
    ...(delayMs > 0 && {
      animation: `${ fadeIn } 300ms ${ delayMs }ms both linear`,
    }),
    width: theme.new.spacing[size],
    height: theme.new.spacing[size],
    position: 'relative',
  }),
  svg: {
    animation: `${ rotate } 1s linear infinite`,
    zIndex: 2,
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  circle: theme => ({
    stroke: palette
      ? theme.new.palette[palette.colour][palette.intensity || 600][palette.contrast ? 'contrast' : 'main']
      : 'currentColor',
    strokeLinecap: 'round',
    animation: `${ dash } 2s ease-in-out infinite`,
  }),
});

