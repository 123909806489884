import { FetchResponse, useGet } from '../../Hooks';
import { Paginated } from '../../Models';

type Thumbnail = {
  thumbnail: {
    key: string;
    url: string;
  };
};

export const useFetchThumbnails = (uploadId: string): FetchResponse<Paginated<'thumbnails', Thumbnail>> => (
  useGet(`/uploads/videos/${ uploadId }/thumbnails`)
);
