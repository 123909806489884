import { useCallback, useEffect, useMemo, useRef } from 'react';

import { useContextOrThrow } from '../../Core/Hook';
import { ApiContext } from '../../Contexts';

export type MinimalUploadWithStatus = {
  id: string;
  prepareStatus: string;
};

export const useUploadStatusPolling = (
  uploads: MinimalUploadWithStatus[],
  onChange: (uploads: MinimalUploadWithStatus[]) => void,
) => {
  const api = useContextOrThrow(ApiContext);
  const processingUploads = useMemo(() => uploads.filter(upload => (
    ['running', 'pending'].includes(upload.prepareStatus)
  )), [uploads]);
  const pendingUploadIds = useMemo(() => processingUploads.map(upload => upload.id), [processingUploads]);
  const pendingUploadIdsRef = useRef<string[]>(pendingUploadIds);
  const timeout = useRef<number>();

  const checkPendingUploads = useCallback(() => {
    if (!pendingUploadIdsRef.current?.length) {
      timeout.current = undefined;
      return;
    }

    api.get<MinimalUploadWithStatus[]>(
      '/uploads/prepare-status',
      { params: { uploadIds: pendingUploadIdsRef.current.join(',') } }
    )
      .then(response => response.data)
      .then(uploads => {
        onChange(uploads);
      })
      .catch(() => {
        // Do nothing, check again after interval
      })
      .finally(() => {
        timeout.current && window.clearTimeout(timeout.current);
        timeout.current = window.setTimeout(
          checkPendingUploads,
          5000,
        );
      });
  }, [api, onChange]);

  useEffect(() => {
    pendingUploadIdsRef.current = pendingUploadIds;

    if (timeout.current) {
      if (pendingUploadIdsRef.current.length) {
        window.clearTimeout(timeout.current)
        timeout.current = undefined;
      }

      return;
    }

    checkPendingUploads();

    return () => {
      if (pendingUploadIdsRef.current === pendingUploadIds) {
        return;
      }

      timeout.current && window.clearTimeout(timeout.current);
      timeout.current = undefined;
    };
  }, [checkPendingUploads, pendingUploadIds, pendingUploadIdsRef, timeout]);
};
