import { FC, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import { CenteredGenericErrorMessage, CenteredGenericLoadingMessage } from '@ourpeople/shared/Core/Component/Content';

import { Box, PageHeader } from '../../../Common/Component';
import { Padded } from '../../../Components';
import { CategoryEditForm, EditBroadcastCategoryFormState } from '../../Component';
import { useFetchBroadcastCategory } from '../../Hook';
import { BroadcastCategory } from '../../Model';
import { useApi, useContextOrThrow } from '../../../Core/Hook';
import { useMounted } from '../../../Common/Hook';
import { ErrorResponseReader } from '../../../Common/Utility';
import { ValidationTree } from '../../../Common/Model';
import { ToastContext } from '../../../Core/Context';

export const EditCategoryPage: FC = () => {
  const api = useApi();
  const { addSuccessToast, addErrorToast } = useContextOrThrow(ToastContext);
  const { categoryId } = useParams<{ categoryId: string }>();
  const [fetchBroadcastCategoryResult, , reloadCategory] = useFetchBroadcastCategory(categoryId);
  const [formState, setFormState] = useState<EditBroadcastCategoryFormState | undefined>();
  const mounted = useMounted();
  const intl = useIntl();
  const [validation, setValidation] = useState<ValidationTree<EditBroadcastCategoryFormState>>({
    errors: [],
    children: {},
  });
  const [saving, setSaving] = useState<boolean>(false);
  const [savedName, setSavedName] = useState<string>();

  useEffect(() => {
    if (!fetchBroadcastCategoryResult?.content?.category.name) {
      return;
    }

    setFormState({ name: fetchBroadcastCategoryResult.content.category.name });
    setSavedName(fetchBroadcastCategoryResult.content.category.name);
  }, [fetchBroadcastCategoryResult?.content?.category.name]);

  const whenSubmitted = useCallback(() => {
    setSaving(true);
    api.post<{ category: BroadcastCategory }>(`/broadcasts/categories/${ categoryId }`, formState)
      .then(response => {
        if (!mounted.current) {
          return;
        }

        setSaving(false);
        addSuccessToast(
          intl.formatMessage({
            id: 'broadcasts.editCategory.success',
            description: 'Success message when broadcast category could be saved.',
            defaultMessage: '{ categoryName } saved.',
          }, {
            categoryName: response.data.category.name,
          })
        );
        setSavedName(response.data.category.name);
      })
      .catch((error: AxiosError) => {
        if (!mounted.current) {
          return;
        }

        setSaving(false);
        addErrorToast(
          intl.formatMessage({
            id: 'broadcasts.createCategory.error',
            description: 'Error message when broadcast category could not be saved.',
            defaultMessage: '{ categoryName, select, undefined {Category} other {{categoryName}} } could not be saved.',
          }, {
            categoryName: savedName,
          })
        );
        error.response && ErrorResponseReader.isValidationErrorResponse(error.response) && setValidation(error.response.data.error.data.root);
      })
  }, [api, categoryId, formState, mounted, addSuccessToast, intl, addErrorToast, savedName]);

  return (
    <Padded>
      <PageHeader
        items={ [
          {
            link: '/broadcasts',
            title: intl.formatMessage({
              id: 'section.broadcasts',
              description: 'Heading label for Broadcasts',
              defaultMessage: 'Broadcasts'
            })
          },
          {
            link: '/broadcasts/categories',
            title: intl.formatMessage({
              id: 'sections.broadcasts.categories',
              description: 'Title displayed over the broadcasts categories page',
              defaultMessage: 'Categories'
            }),
          },
          ...(savedName ? [
              {
                link: `/broadcasts/categories/${ categoryId }`,
                title: savedName,
              }
            ] : []),
          {
            title: intl.formatMessage({
              id: 'sections.broadcasts.categories.edit',
              description: 'Title displayed over the edit broadcast category page',
              defaultMessage: 'Edit'
            }),
          },
        ] }
      />
      <Box>
{
  fetchBroadcastCategoryResult?.content && formState
    ? (
      <CategoryEditForm
        formState={ formState }
        onChange={ setFormState }
        validation={ validation }
        onValidationChange={ setValidation }
        onSubmit={ whenSubmitted }
        submitting={ saving }
      />
    )
    : fetchBroadcastCategoryResult?.error
      ? <CenteredGenericErrorMessage onRetryClicked={ reloadCategory }/>
      : <CenteredGenericLoadingMessage/>
}
      </Box>
    </Padded>
  );
};
