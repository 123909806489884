import { IntlShape } from 'react-intl';

import {
  AudienceConditionDefinition,
  ConditionContentsSummary,
  RoleCondition,
  RoleConditionContents
} from '../../Model';
import { RoleLocaliser } from '../../../Security/Utility';

/** @deprecated Used in old audience builder */
export class RoleConditionDefinition implements AudienceConditionDefinition<
  RoleCondition,
  RoleConditionContents
> {
  public readonly TYPE = 'role';

  public definesConditionType(conditionType: string): boolean {
    return conditionType === this.TYPE;
  }

  public contentsSummary(
    intl: IntlShape,
    condition: RoleCondition,
    conditionContents: RoleConditionContents,
  ): ConditionContentsSummary {
    const singleSummary = intl.formatMessage(
      {
        id: 'audience.conditionDefinition.role.contentsSummary.single',
        defaultMessage: '{ not, select, true {not } other {} }{role}',
      },
      {
        not: condition.not,
        role: RoleLocaliser.localiseRoleId(intl, conditionContents.roleIds[0]),
      }
    );

    const countSummary = intl.formatMessage(
      {
        id: 'audience.conditionDefinition.role.contentsSummary.count',
        defaultMessage: '{ not, select, true {not } other {} }{ count, plural, one {# role} other {# roles} }',
      },
      {
        not: condition.not,
        count: conditionContents.roleIds.length,
      }
    );

    const preferredSummary = conditionContents.roleIds.length > 0
      ? (
        conditionContents.roleIds.length === 1
          ? singleSummary
          : countSummary
      )
      : null;

    return {
      exclusive: false,
      soloSummary: preferredSummary,
      andSummary: preferredSummary,
    };
  }
}
