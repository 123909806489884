import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { CircularProgress } from '@mui/material';

import { StyledPlayButton } from './style';
import { PresentationIcon } from '../../../../../Common/Component';
import PlayIcon from '../../../../../Assets/img/icons/streamline-bold/icon-controls-play.svg';

type Props = {
  duration: number | undefined;
  busy: boolean;
};

export const VideoPlayButton: FC<Props> = ({
  duration,
  busy,
}) => (
  <StyledPlayButton>
    {
      busy
        ? (
          <CircularProgress
            size={ 18 }
            variant="indeterminate"
            color="inherit"
          />
        )
        : (
          <PresentationIcon
            IconComponent={ PlayIcon }
            size="xs"
            color="white"
          />
        )
    }
    {
      duration
        ? (
          <FormattedMessage
            id="videoPlayButton.duration"
            description="Duration displayed in thumbnail for videos"
            defaultMessage="{ minutes }:{ seconds }"
            values={ {
              minutes: `${ Math.floor(duration / 60) }`.padStart(2, '0'),
              seconds: `${ Math.ceil(duration) % 60 }`.padStart(2, '0'),
            } }
          />
        )
        : (
          <FormattedMessage
            id="videoPlayButton.noDuration"
            description="Duration displayed in thumbnail for videos when video duration is not available"
            defaultMessage="--:--"
          />
        )
    }
  </StyledPlayButton>
);
