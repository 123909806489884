import styled from '@emotion/styled';

import { TableCell } from '../../../Components';
import { StyledTableCellProps } from '../../../Components/Content/styles';
import { PropsWithTheme } from '../../../Styling/PropsWithTheme';

export const ProfileCountCell = styled(
  (props: PropsWithTheme<StyledTableCellProps>) => <TableCell {...props} />
)`
  >div {
    display: grid;
    grid-template-columns: minmax(0, max-content) minmax(0, max-content);
    align-items: center;
    column-gap: 1rem;

    svg {
      color: ${(props) => props.theme.palette.secondary.main};
    }
  }
`;
