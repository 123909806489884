import { FunctionComponent, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import * as Sentry from '@sentry/react';
import { useHistory } from 'react-router-dom';
import { LoadingCard } from '@ourpeople/shared/Core/Component/Feedback';

import { Fullscreen } from '../Layout';
import { ApiContext, AuthContext } from '../../Contexts';
import { ApiError, AuthDescription, RequestState } from '../../Models';
import { AuthError } from '../../Core/Component';
import { useContextOrThrow, useGoogleAnalytics } from '../../Core/Hook';
import { LoginStateContext } from '../../Core/Provider/LoginStateProvider';
import { Api } from '../../Services';
import { useMounted } from '../../Common/Hook';
import { IdentityContext } from '../../Core/Provider/IdentityProvider/IdentityProvider';

type SessionExpiry = {
  expiry: number;
};

export type FetchDescriptionResponse = Omit<AuthDescription, 'administratedTags'> & {
  administrated_tags: number[];
};

export const AuthProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const api = useContext(ApiContext);
  const history = useHistory();
  const { setLoginTarget, setLoggedIn } = useContextOrThrow(LoginStateContext);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [expiry, setExpiry] = useState<SessionExpiry>();
  const { setUserId } = useGoogleAnalytics();
  const mounted = useMounted();
  const { identityRequest } = useContextOrThrow(IdentityContext);
  const [authDescription, setAuthDescription] = useState<AuthDescription | null>(null);
  const [lastDescribed, setLastDescribed] = useState<number>(0);

  const describe = useCallback((api: Api) => {
    api.get<FetchDescriptionResponse>('/me/describe').then((response) => {
      const { administrated_tags, ...fetchDescriptionResponse } = response.data;
      const newAuthDescription: AuthDescription = {
        ...fetchDescriptionResponse,
        administratedTags: administrated_tags.map(String),
      };
      setUserId(newAuthDescription.user.id);
      Sentry.setUser({
        id: String(newAuthDescription.user.id),
        roles: newAuthDescription.user.roles || [],
      });
      if (!mounted.current) {
        return;
      }

      setLastDescribed(Date.now());
      setAuthDescription(newAuthDescription);
      setLoading(false);
      setLoggedIn(true);
    }).catch((error: AxiosError<ApiError>) => {
      Sentry.setUser({});
      if (!mounted.current) {
        return;
      }

      if (error?.response?.status === 401) {
        setLoggedIn(false);
        setAuthDescription(null);
      } else {
        setError(true);
      }
      setLoading(false);
    });
  }, [mounted, setLoggedIn, setUserId]);

  /**
   * Load the auth description on mount. Wait for the identity to be known and then describe the user.
   */
  useEffect(() => {
    if (
      !loading
      || !api
      || ![RequestState.COMPLETE, RequestState.FAILED].includes(identityRequest.state)
    ) {
      return;
    }

    if (
      identityRequest.state === RequestState.FAILED
      || (
        identityRequest.state === RequestState.COMPLETE
        && identityRequest.result.authentication.type === 'identity'
      )
    ) {
      setLoggedIn(false);
      setLoading(false);
      return;
    }

    describe(api);
  }, [api, describe, identityRequest, loading, setLoggedIn]);

  const refreshAuthDescription = useCallback(() => {
    api && describe(api);
  }, [api, describe]);

  const onAuthError = useCallback((error: AxiosError) => {
    // Do not refresh auth if the auth failure came from an auth endpoint. Without this the app will
    // reload on failure, causing the auth component to lose state and enter a redirect loop.
    if (
      error.config?.url === '/me/describe' // This is the first endpoint called after an auth error
      || error.config?.url?.substr(0, 6) === '/auth/'
    ) {
      return;
    }

    api && describe(api);
  }, [api, describe]);

  const onPermissionError = useCallback((error: AxiosError<ApiError>) => {
    if (error.response?.data?.error?.data?.permissionsRestricted) {
      if (history.location.pathname === ELEVATE_PATH) {
        return;
      }

      setLoginTarget(
        history.location.pathname,
        new URLSearchParams(history.location.search),
      );
      console.debug(`Auth redirect to ${ELEVATE_PATH} due to API error.`, { ...error.response?.data?.error?.data });
      history.push(ELEVATE_PATH);
    }
  }, [history, setLoginTarget]);

  useEffect(() => {
    if (api) {
      const errorSubscription = api.onAuthError().subscribe(onAuthError);
      const permissionErrorSubscription = api.onPermissionError().subscribe(onPermissionError);
      return () => {
        errorSubscription.unsubscribe();
        permissionErrorSubscription.unsubscribe();
      };
    }
  }, [api, onAuthError, onPermissionError]);

  const onSessionExpiryUpdated = useCallback((expiry: number) => {
    if (expiry >= 0) {
      setExpiry({ expiry }); // Use an object to ensure change detection
    }
  }, []);

  useEffect(() => {
    if (api) {
      const updateSubscription = api.onSessionExpiryUpdated().subscribe(onSessionExpiryUpdated);
      return () => updateSubscription.unsubscribe();
    }
  }, [api, onSessionExpiryUpdated]);

  useEffect(() => {
    if (expiry) {
      const timeout = setTimeout(() => {
        api && describe(api);
      }, (expiry.expiry + 1) * 1000);
      return () => clearTimeout(timeout);
    }
  }, [api, describe, expiry]);

  return loading
    ? (
      <Fullscreen>
        <LoadingCard/>
      </Fullscreen>
    )
    : (
      error
        ? (
          <AuthError
            onRetry={ () => {
              setError(false);
              setLoading(true);
            } }
          />
        )
        : (
          <AuthContext.Provider
            value={ {
              authDescription,
              refreshAuthDescription,
              lastDescribed,
            } }
          >
            { children }
          </AuthContext.Provider>
        )
    );
};

export const ELEVATE_PATH = '/login/elevate';
