import styled from '@emotion/styled';

export const StyledUpload = styled.li`
  display: flex;
  gap: ${ props => props.theme.spacing(2) };
  flex-direction: column;
`;

export const StyledTitleAndProgress = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: nowrap;
  flex-grow: 1;
`;

export const StyledImageContainer = styled.div`
  width: ${ props => props.theme.spacing(8) };
  height: ${ props => props.theme.spacing(8) };
  background-color: ${ props => props.theme.palette.grey[300] };
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  color: transparent;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
