import * as Sentry from '@sentry/browser';
import wretch from 'wretch';

import {Space} from '../Models/Space';

const LOCAL_API_URL = process.env.OP_LOCAL_API_URL;
const LOCAL_CONSOLE_URL = process.env.OP_LOCAL_CONSOLE_URL;
const NAMESPACE_RESOLVER_URL = process.env.OP_NAMESPACE_RESOLVER_URL;

export class SpaceResolver {
  private resolvePromise?: Promise<Space>;

  private resolveLocally(): Promise<Space> {
    console.debug('Resolving space locally...');
    return new Promise<Space>(resolve => {
      // Add artificial delay to simulate network request
      setTimeout(
        () => resolve(
          {
            name: 'local',
            api_url: LOCAL_API_URL ?? '',
            console_url: LOCAL_CONSOLE_URL ?? '',
            environment: 'dev',
            aliases: ['localhost'],
          }
        ),
        400,
      )
    })
      .then(space => {
        console.debug('Resolved space locally', space);
        return space;
      });
  }

  public resolve(spaceId: string): Promise<Space> {
    if (this.resolvePromise === undefined) {
      if (['local', 'op-console-app'].indexOf(spaceId) > -1) {
        return this.resolveLocally();
      }

      this.resolvePromise = wretch(`${NAMESPACE_RESOLVER_URL ?? ''}/resolve/${spaceId}`)
        .get()
        .json<Space>()
        .catch(error => {
          Sentry.captureException(
            error,
            {
              tags: {
                spaceId,
              },
            },
          );
          throw error;
        });
    }

    return this.resolvePromise;
  }
}
