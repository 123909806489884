import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { formatDistanceToNow } from 'date-fns';

import { StyledNudgeBanner, StyledDate, StyledMessage } from './style';
import NudgeIcon from '../../../../Assets/img/icons/monochrome/nudge.svg';

interface Props {
  message: string,
  nudgedAt: Date,
}

export const NudgeBanner: FC<Props> = ({
  message,
  nudgedAt,
}) => (
  <StyledNudgeBanner>
    <NudgeIcon />
    <StyledMessage>
      { message }
    </StyledMessage>
    <StyledDate>
      <FormattedMessage
        id="broadcasts.nudgeBanner.nudgedDate"
        defaultMessage="Nudged {date}"
        values={ {
          date: formatDistanceToNow(nudgedAt, { addSuffix: true }),
        } }
      />
    </StyledDate>
  </StyledNudgeBanner>
);
