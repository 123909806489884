import { FunctionComponent, ReactNode, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import ArrowIcon from '../../../../Assets/img/icons/streamline/navigation-right-circle-1.svg';
import { StyledStepContainer } from './style';
import { RouterItem } from '../../../Model';
import { Flex, PresentationIcon, StepLink } from '../..';

export type StepperItem = RouterItem & {
  error?: boolean;
  disabled?: boolean;
};

type Props = {
  steps: StepperItem[];
};

export const DesktopStepper: FunctionComponent<Props> = ({
  steps = [],
}) => {
  const { pathname } = useLocation();
  const activeIndex = useMemo(() => (
    steps.findIndex(step => step.link === pathname)
  ), [pathname, steps]);

  return (
    <nav>
      <Flex
        gap={ 2 }
        noWrap
      >
        { steps.reduce<ReactNode[]>((nodes, step, index) => (
          nodes.concat(
            <StepLink
              key={ step.link }
              step={ step }
              active={ index <= activeIndex }
            />,
            ...(
              index < steps.length - 1
                ? [(
                  <StyledStepContainer
                    key={ `${ step.link }_arrow` }
                    active={ index < activeIndex }
                    error={ step.error }
                  >
                    <PresentationIcon IconComponent={ ArrowIcon } />
                  </StyledStepContainer>
                )]
                : []
            ),
          )
        ), []) }
      </Flex>
    </nav>
  );
};
