import { MappedSettingTypes } from '../../Types';
import { ColorPicker } from '../../../../../Components';
import { StyledColourSettingValue } from './styles';

type Props = {
  value: MappedSettingTypes['colour']['value'],
};

export const ColourSettingValue = ({
  value,
}: Props): JSX.Element => {
  return (
    <>
      {
        value !== null
          && (
            <StyledColourSettingValue>
              <ColorPicker
                value={value}
                disabled={true}
              />
            </StyledColourSettingValue>
          )
      }
    </>
  );
};
