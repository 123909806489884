import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { FeedbackSeverity, StatusChip } from '../../../Common/Component';
import { ImportOperationTypeString } from '../../Model';

type Props = {
  operationType: ImportOperationTypeString;
}

export const ImportOperationChip: FunctionComponent<Props> = ({ operationType }) => {
  const localiseType = (type: ImportOperationTypeString) => {
    switch (type) {
      case 'create':
        return (
          <FormattedMessage
            id="imports.operationChip.create"
            description="Label for create import operation."
            defaultMessage="Create"
          />
        );
      case 'update':
        return (
          <FormattedMessage
            id="imports.operationChip.update"
            description="Label for update import operation."
            defaultMessage="Update"
          />
        );
      default:
        return (
          <FormattedMessage
            id="imports.operationChip.unknown"
            description="Label for unknown import operation."
            defaultMessage="Unknown"
          />
        );
    }
  }

  return (
    <StatusChip
      status={ getOperationTypeSeverity(operationType) }
      label={ localiseType(operationType) }
    />
  );
};

const getOperationTypeSeverity = (type: ImportOperationTypeString): FeedbackSeverity => {
  switch (type) {
    case 'create':
      return 'success';
    case 'update':
      return 'warning';
    default:
      return 'none';
  }
};
