import styled from '@emotion/styled';
import { Divider } from '@mui/material';

export const StyledFormSection = styled.div`
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: ${ props => props.theme.spacing(2) };
`;

export const StyledDivider = styled(Divider)`
  margin-bottom: 1rem;
`;

export const StyledProfileForms = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${ props => props.theme.spacing(2) };

  ${ props => props.theme.breakpoints.down('lg') } {
    grid-template-columns: 1fr;
  }
`;

export const StyledNoProfilesMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: ${ props => props.theme.spacing(2) };
  background-color: ${({ theme }) => theme.palette.grey[100]};
  padding: ${ props => props.theme.spacing(2) };
  margin: ${ props => props.theme.spacing(2) } auto;
  border-radius: ${ props => props.theme.borderRadius.sm };
  width: 50%;
`;
