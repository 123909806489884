import { ChangeEventHandler, FC, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { Typography } from 'op-storybook/stories/components/Typography/Typography';
import { TextField } from '@mui/material';

import { FieldValidationErrors } from '../../../../Common/Component';
import { ContentEditorProps } from '../../../../Content/Model';
import { ValidationTree } from '../../../../Common/Model';
import { CardValidationMerger } from '../../../../Content/Utility';
import { useFileUploader } from '../../../../Common/Hook';
import { VideoEditorContent } from '../../../Service/ContentDefinitions/VideoContentDefinition';
import { VideoContentValidator } from '../../../Utility/Validation/VideoContentValidator';
import { DraftVideoContent } from '../../../Model/ContentTypes/VideoContent';
import { FileUploadInput } from '../../../../Files/Component';
import { UploadState } from '../../../../Common/Context';
import { Upload } from '../../../../Types';
import { ContentValidator } from '../../../Utility/Validation';
import { ThumbnailSelectorField } from '../../../../Content/Component/ThumbnailSelectorField/ThumbnailSelectorField';

export const VideoContentFields: FC<ContentEditorProps<VideoEditorContent>> = ({
  editorContent,
  onEditorContentChanged,
  validation,
  onValidationChanged,
}) => {
  const intl = useIntl();
  const textErrors = validation?.children.content?.children.text?.errors || [];
  const titleErrors = validation?.children.content?.children.title?.errors || [];
  const { uploadStates, remove } = useFileUploader();
  const upload = useMemo<UploadState | undefined>(
    () => uploadStates.get(`${ editorContent.id }_video`),
    [editorContent.id, uploadStates],
  );

  const whenTextChanged: ChangeEventHandler<HTMLInputElement> = useCallback(event => {
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          text: event.currentTarget.value,
        },
      },
    });
  }, [onEditorContentChanged, editorContent]);

  const whenTextBlurred = () => whenContentChildrenValidationChanged({
    text: VideoContentValidator.validateText(editorContent.card.content),
  });

  const whenContentChildrenValidationChanged = useCallback(
    (children: ValidationTree<DraftVideoContent>['children']): void => {
      onValidationChanged(
        CardValidationMerger.addContentValidationChildrenToCardValidation(children, validation)
      );
    },
    [validation, onValidationChanged],
  );

  const whenThumbnailChanged = useCallback((thumbnailKey: string) => (
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          thumbnailKey,
        },
      },
    })
  ), [editorContent, onEditorContentChanged]);

  const whenFileUploaded = useCallback((upload: Upload) => (
    onEditorContentChanged({
      ...editorContent,
      upload,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          uploadId: String(upload.id),
        },
      },
    })
  ), [editorContent, onEditorContentChanged]);

  const whenUploadStarted = useCallback(() => (
    onEditorContentChanged({
      ...editorContent,
      prepareStatus: 'pending',
    })
  ), [editorContent, onEditorContentChanged]);

  const whenTitleChanged: ChangeEventHandler<HTMLInputElement> = (event) => {
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          title: event.target.value,
        },
      },
    });
  };

  const whenTitleBlurred = useCallback((): void => {
    onValidationChanged({
      errors: validation?.errors || [],
      children: {
        content: {
          errors: [
            ...ContentValidator.validate(editorContent.card.content).errors,
          ],
          children: {
            ...validation?.children.content?.children,
            ...ContentValidator.validate(editorContent.card.content).children,
          }
        },
      },
    });
  }, [onValidationChanged, validation, editorContent.card.content]);

  const whenFileRemoved = useCallback(() => {
    if (!upload) {
      return;
    }

    remove(upload.ref);
    onEditorContentChanged({
      ...editorContent,
      upload: null,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          uploadId: null,
        },
      },
      prepareStatus: null,
    });
    const { uploadId: _uploadIdValidation, ...contentValidationChildren } = (validation?.children.content?.children as ValidationTree<DraftVideoContent>['children'] | undefined) || {};
    onValidationChanged({
      ...validation,
      errors: validation?.errors || [],
      children: {
        ...validation?.children,
        content: {
          errors: validation?.children.content?.errors || [],
          children: contentValidationChildren,
        },
      },
    });
  }, [upload, remove, onEditorContentChanged, editorContent, validation, onValidationChanged]);

  const videoFieldLabel = intl.formatMessage({
    description: 'Label for video filed in video form card editor',
    defaultMessage: 'Video',
  });

  return (
    <>
      <FieldValidationErrors
        fieldName={ intl.formatMessage({
          description: 'Name used in general content errors.',
          defaultMessage: 'Content type',
        }) }
        validationErrors={ validation?.children.content?.children.type?.errors || [] }
      />
      <TextField
        id="title"
        label={ intl.formatMessage({
          defaultMessage: 'Title *',
        }) }
        value={ editorContent.card.content.title }
        onChange={ whenTitleChanged }
        onBlur={ whenTitleBlurred }
        error={ !!titleErrors.length }
        fullWidth
      />
      <FieldValidationErrors
        fieldName={ intl.formatMessage({
          defaultMessage: 'title',
        }) }
        validationErrors={ titleErrors }
      />
      <Stack direction="column" gap={ 1 }>
        <TextField
          id="description"
          label={ intl.formatMessage({
            defaultMessage: 'Description',
          }) }
          value={ editorContent.card.content.text }
          onChange={ whenTextChanged }
          onBlur={ whenTextBlurred }
          error={ !!textErrors.length }
          fullWidth
          multiline
        />
        <FieldValidationErrors
          fieldName={ intl.formatMessage({
            defaultMessage: 'description',
          }) }
          validationErrors={ textErrors }
        />
      </Stack>
      <Stack direction="column" gap={ 1 }>
        <Typography
          size="lg"
          weight="bold"
        >
          { videoFieldLabel }
        </Typography>
        <FileUploadInput
          id={ `${ editorContent.id }_video` }
          mimeType="video/*"
          fileUpload={ editorContent.upload || undefined }
          onFileUploaded={ whenFileUploaded }
          onFileRemoved={ whenFileRemoved }
          onUploadStarted={ whenUploadStarted }
        />
        <FieldValidationErrors
          fieldName={ videoFieldLabel }
          validationErrors={ validation?.children.content?.children.uploadId?.errors || [] }
        />
      </Stack>
      <ThumbnailSelectorField
        busy={ !!editorContent.prepareStatus && ['pending', 'running'].includes(editorContent.prepareStatus) }
        errors={ validation?.children.content?.children.thumbnailKey?.errors || [] }
        onChange={ whenThumbnailChanged }
        selectedKey={ editorContent.card.content.thumbnailKey || undefined }
        uploadId={ editorContent.upload?.id ? String(editorContent.upload.id) : undefined }
      />
    </>
  )
};
