import { useCallback, useEffect, useState } from 'react';

import { FetchResponse } from './useRequest';
import { FetchResult, RequestState } from '../Models';

export const usePolling = <ResultType>(
  [fetchResult, fetchState, restartFetch]: FetchResponse<ResultType>,
  interval: number,
  isComplete: (result: FetchResult<ResultType> | null) => boolean,
): FetchResponse<ResultType> => {
  const retryPoll = useCallback(() => {
    setResponse([null, RequestState.FETCHING, retryPoll]);
    restartFetch();
  }, [restartFetch]);
  const [response, setResponse] = useState<FetchResponse<ResultType>>([null, RequestState.FETCHING, retryPoll]);

  useEffect(() => {
    let cancelled = false;
    if (!isComplete(fetchResult)) {
      const timeout = setTimeout(() => {
        if (cancelled) {
          return;
        }

        restartFetch();
      }, interval);

      return () => {
        clearTimeout(timeout);
        cancelled = true;
      };
    }

    if (fetchState < RequestState.COMPLETE) {
      return;
    }

    setResponse(([,,restartPolling]) => [
      fetchResult,
      fetchState,
      restartPolling,
    ]);
  }, [fetchResult, fetchState, interval, isComplete, restartFetch]);

  return response;
};
