import { DraftTimeline } from '../../ContentDefinition';
import { ValidationTree, Validator } from '../../../../Common/Model';
import { Validate } from '../../../../Common/Utility';

export class TimelineValidator {
  public static validate = (content: DraftTimeline): ValidationTree<DraftTimeline> => ({
    errors: [],
    children: {
      text: TimelineValidator.validateText(content.text),
    },
  });

  public static validateText = (text: string): ValidationTree<string> => ({
    errors: textValidator.validate(text),
    children: {},
  });
}

export const TIMELINE_TEXT_MIN_LENGTH = 1;
export const TIMELINE_TEXT_MAX_LENGTH = 140;

const textValidator = new Validator<string>([
  Validate.notBlank,
  Validate.utf8,
  text => Validate.minLength(text, TIMELINE_TEXT_MIN_LENGTH),
  text => Validate.maxLength(text, TIMELINE_TEXT_MAX_LENGTH),
]);
