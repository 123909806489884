import styled from '@emotion/styled';

export const StyledBroadcastCreateMenu = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${ props => props.theme.spacing(2) };
  padding: ${ props => props.theme.spacing(1) } 0;
  width: 700px;
  max-width: 100%;

  > * {
    flex-grow: 1;
    width: 200px;
  }
`;
