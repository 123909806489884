import { FC, ReactNode } from 'react';

type Props = {
  palette?: 'inherit' | 'default';
  children?: ReactNode;
  className?: string;
};

export const SecondaryInformation: FC<Props> = ({
  palette = 'default',
  className,
  children,
}) => (
  <div
    css={ theme => ({
      color: palette === 'inherit' ? 'inherit' : theme.new.palette.grey[600].main,
      fontSize: '16px',
      fontWeight: '200',
      lineHeight: '18px',
    }) }
    className={ className }
  >
    { children }
  </div>
);
