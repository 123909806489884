import { LocationDescriptor, LocationDescriptorObject } from 'history';

export class LocationHelper {
  public static replaceSearchKey = <T>(location: LocationDescriptorObject<T>, key: string, value: string | undefined): LocationDescriptorObject<T> => ({
    pathname: location.pathname,
    search: reorderSearch(
      location.search
        ? location.search.includes(key)
          ? location.search.replace(RegExp(`([&?]?)${ key }=[^&\n]+`), value ? `$1${ key }=${ value }` : '')
          : value ? location.search.concat(`&${ key }=${ value }`) : location.search
        : value ? `?${ key }=${ value }` : '',
    )
  });

  public static replaceSearchKeys = <T>(location: LocationDescriptorObject<T>, entries: { key: string; value: string | undefined }[]): LocationDescriptorObject<T> => (
    entries.reduce(
      (locationDescriptor, { key, value }) => (
        LocationHelper.replaceSearchKey(locationDescriptor, key, value)
      ),
      location,
    )
  );

  public static addParams = (location: LocationDescriptor, params: URLSearchParams) => {
    return typeof location === 'string'
      ? {
        pathname: location,
        search: params.toString(),
      }
      : {
        ...location,
        search: location.search
          ? getCombinedParams(new URLSearchParams(location.search), params).toString()
          : params.toString(),
      };
  }
}

const reorderSearch = (search: string) => (
  search.startsWith('&')
    ? search.replace('&', '?')
    : search
);

const getCombinedParams = (paramsA: URLSearchParams, paramsB: URLSearchParams): URLSearchParams => {
  const combinedParams = new URLSearchParams();

  Array.from(paramsA.entries()).forEach(([key, value]) => {
    combinedParams.append(key, value);
  });

  Array.from(paramsB.entries()).forEach(([key, value]) => {
    combinedParams.append(key, value);
  });

  return combinedParams;
};
