import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';

import { PagingMenuHeader } from '../PagingMenuHeader/PagingMenuHeader';
import { PagingMenuBackButton } from '../PagingMenuBackButton/PagingMenuBackButton';
import { PagingMenuContent } from '../PagingMenuContent/PagingMenuContent';
import { StyledHelpdeskLink } from './style';

type Props = {
  onBackClicked: () => void;
};

export const AiBetaDisclaimer: FC<Props> = ({
  onBackClicked,
}) => (
  <div>
    <PagingMenuHeader>
      <Stack gap={ 4 }>
        <PagingMenuBackButton
          onClick={ onBackClicked }
        />
        <FormattedMessage
          description="Title for OurProple AI beta disclaimer sub-menu."
          defaultMessage="OurPeople AI Disclaimer"
        />
      </Stack>
    </PagingMenuHeader>
    <PagingMenuContent>
      <FormattedMessage
        defaultMessage={ `
          <p>Welcome to OurPeople AI, powered by ChatGPT and currently in Beta Mode. As the system continues to evolve and generate more responses over time, the results may vary. We kindly request your understanding and patience during this phase.</p>
          <p>For the enhancement of our services, please be informed that both responses and questions may be recorded for training purposes.</p>
          <p>Thank you for being a part of OurPeople AI's development journey. We look forward to continuously improving and providing you with the best possible experience.</p>
          <p>For more information visit our help desk article <a>here</a></p>
        ` }
        values={ {
          a: chunks => (
            <StyledHelpdeskLink
              href="https://get.ourpeople.help/hc/en-us/articles/12431716713629"
              target="_blank"
              rel="noreferrer"
            >
              { chunks }
            </StyledHelpdeskLink>
          ),
        } }
      />
    </PagingMenuContent>
  </div>
);
