import { ComponentProps, forwardRef, HTMLAttributes, JSX, ReactNode } from 'react';

import { ToggledPopover } from './ToggledPopover';
import { Button } from '../Button/Button';
import DownArrowIcon from '../../../lib/assets/icon/figma/chevron-down.svg?react';
import { Stack } from '../../../lib/components/Stack/Stack';
import { StackEnd } from '../../../lib/components/StackEnd/StackEnd';
import { PresentationIcon } from '../../../lib/components/PresentationIcon/PresentationIcon';

type Props<T> = {
  buttonContents: ReactNode;
  buttonVariant?: ComponentProps<typeof Button>['variant'];
  items: T[];
  renderItem: (item: T) => ReactNode;
  getItemKey?: (item: T) => string | number;
  buttonProps?: HTMLAttributes<HTMLButtonElement>;
};

export const ButtonToggledPopover = <T, >({
  buttonContents,
  buttonVariant = 'secondary',
  items,
  renderItem,
  getItemKey,
  buttonProps,
}: Props<T>): JSX.Element => (
  <ToggledPopover
    ToggleComponent={ forwardRef((props, ref) => (
      <Button
        ref={ ref }
        variant={ buttonVariant }
        type="button"
        fillParent
        { ...buttonProps }
        { ...props }
      >
        <Stack fillParent>
          { buttonContents }
          <StackEnd>
            <PresentationIcon
              IconComponent={ DownArrowIcon }
            />
          </StackEnd>
        </Stack>
      </Button>
    )) }
    ContentComponent={ forwardRef((props, ref) => (
      <menu
        ref={ ref }
        { ...props }
        css={ theme => ({
          boxShadow: theme.new.shadow.md,
          border: theme.new.border.standard,
          borderRadius: theme.new.borderRadius.standard,
          listStyle: 'none',
        }) }
      >
        { items.map((item, itemIndex) => (
          <li
            key={ getItemKey ? getItemKey(item) : itemIndex }
            css={ {
              position: 'relative',
              margin: 0,
              padding: 0,
            } }
          >
            { renderItem(item) }
          </li>
        )) }
      </menu>
    )) }
  />
);
