import { FC, useMemo } from 'react';
import { ButtonToggledPopover } from 'op-storybook/stories/components/content/ButtonToggledPopover';
import { DropdownItem } from 'op-storybook/stories/components/DropdownItem/DropdownItem';
import { useIntl } from 'react-intl';

import { useContextOrThrow } from '../../../../Core/Hook';
import {
  RichTextPlaceholderDefinitionRegistryContext
} from '../../../../Common/Provider/RichTextPlaceholderDefinitionRegistryProvider';
import { RichTextPlaceholderDefinition } from '../../../../Common/Model/RichTextPlaceholderDefinition';

type Props = {
  availablePlaceholders: string[];
  onPlaceholderClicked: (placeholderDefinition: RichTextPlaceholderDefinition) => void;
};

export const PlaceholderMenu: FC<Props> = ({
  availablePlaceholders,
  onPlaceholderClicked,
}) => {
  const intl = useIntl();
  const { getDefinitions } = useContextOrThrow(RichTextPlaceholderDefinitionRegistryContext);
  const placeholderDefinitions = useMemo(
    () => (
      getDefinitions()
        .filter(definition => availablePlaceholders.includes(definition.placeholderString))
    ),
    [getDefinitions, availablePlaceholders],
  );

  return (
    <ButtonToggledPopover
      renderItem={ placeholderDefinition => (
        <DropdownItem
          key={ placeholderDefinition.placeholderString }
          text={ placeholderDefinition.localisedString }
          onClick={ () => onPlaceholderClicked(placeholderDefinition) }
        />
      ) }
      items={ placeholderDefinitions }
      buttonContents={ intl.formatMessage({
        description: 'Label for placeholder menu option in rich text editor.',
        defaultMessage: 'Add placeholder',
      }) }
      buttonVariant="tertiary-grey"
    />
  );
};
