import { FunctionComponent } from 'react';
import {
  useLocalisedValidationErrors
} from 'op-storybook/lib/hooks/useLocalisedValidationHandlers/useLocalisedValidationHandlers';
import {
  ValidationErrorHandler
} from 'op-storybook/lib/utility/ValidationErrorHandlerFactory/ValidationErrorHandlerFactory';

import { ValidationError } from '../../Model/ValidationError';
import { LocalisedErrors } from '../../../Common/Component/LocalisedErrors/LocalisedErrors';
import { useValidationErrorHandlers } from '../../Hook/useValidationErrorHandlers';

interface Props {
  fieldName: string;
  validationErrors: ValidationError[];
  customHandlers?: ValidationErrorHandler[];
  selectionSingular?: string;
  selectionPlural?: string;
}

export const FieldValidationErrors: FunctionComponent<Props> = ({
  fieldName,
  validationErrors,
  customHandlers = [],
  selectionSingular,
  selectionPlural,
}) => {
  const localisedErrors = useLocalisedValidationErrors(
    useValidationErrorHandlers(
      fieldName,
      selectionSingular,
      selectionPlural,
      customHandlers,
    ),
    validationErrors,
  );

  return <LocalisedErrors localisedErrors={ localisedErrors }/>;
};

