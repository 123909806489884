import { FetchResponse, useGet } from '../../Hooks';
import { Paginated } from '../../Models';
import { Event } from '../Model';
import { ArrayHelper } from '../../Common/Utility';

export type FetchEventsSortString = 'created_at_asc' | 'created_at_desc' | 'start_at_asc' | 'start_at_desc';

export type FetchEventsParams = {
  ids?: string | null;
  teamIds?: string | null;
  createdByIds?: string | null;
  statuses?: string | null;
  search?: string | null;
  startSince?: string | null;
  endUntil?: string | null;
  pageNum: number;
  sort: string;
  types: string;
}

export const useFetchEvents = (params: FetchEventsParams): FetchResponse<Paginated<'events', Event>> => {
  return useGet('/events-2', params);
}

export const sortStringIsValid = ArrayHelper.createTypeGuard<FetchEventsSortString>([
  'created_at_asc',
  'created_at_desc',
  'start_at_asc',
  'start_at_desc',
]);
