import styled from '@emotion/styled';

export const StyledOurPeopleLogo = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${ props => props.theme.fontSize.xs };

  img {
    width: ${ props => props.theme.spacing(16) };
  }
`;

export const StyledRegistrationContainer = styled.div`
  ${ props => props.theme.breakpoints.up('md') } {
    padding: ${ props => `0 ${ props.theme.spacing(8) }` };
  }
`;
