import { FunctionComponent, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { defaultPlaceholders, Editor } from '@ourpeople/shared/Core/Component/Input/RichTextEditor/Editor/Editor';

import { DraftTextContent, TEXT_CONTENT_HTML_MAX_LENGTH } from '../../../Model/ContentTypes';
import { FieldValidationErrors } from '../../../../Common/Component';
import { ContentFields } from '..';
import { TextContentValidator } from '../../../Utility/Validation';
import { ContentEditorProps } from '../../../../Content/Model';
import { ValidationTree } from '../../../../Common/Model';
import { CardValidationMerger } from '../../../../Content/Utility';
import { TextContentEditorContent } from '../../../Service';

export const TextContentFields: FunctionComponent<ContentEditorProps<TextContentEditorContent>> = ({
  editorContent,
  onEditorContentChanged,
  validation,
  onValidationChanged,
}) => {
  const intl = useIntl();
  const htmlErrors = validation?.children.content?.children.html?.errors || [];

  const whenHtmlChanged = useCallback((html: string): void => {
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          html,
        },
      },
    });
  }, [onEditorContentChanged, editorContent]);

  const whenHtmlBlurred = () => whenContentChildrenValidationChanged({
    html: TextContentValidator.validateHtml(editorContent.card.content),
  });

  const whenContentChildrenValidationChanged = useCallback(
    (children: ValidationTree<DraftTextContent>['children']): void => {
      onValidationChanged(
        CardValidationMerger.addContentValidationChildrenToCardValidation(children, validation)
      );
    },
    [validation, onValidationChanged],
  );

  return (
    <>
      <ContentFields
        editorContent={ editorContent }
        onEditorContentChanged={ onEditorContentChanged }
        validation={ validation }
        onValidationChanged={ onValidationChanged }
      />
      <Editor
        availablePlaceholders={ defaultPlaceholders }
        value={ editorContent.card.content.html }
        onChange={ whenHtmlChanged }
        onBlur={ whenHtmlBlurred }
        analyticsContext="Form text content"
        aiPromptOptions={ {
          maxLength: TEXT_CONTENT_HTML_MAX_LENGTH,
        } }
      />
      <FieldValidationErrors
        fieldName={ intl.formatMessage({
          id: 'form.fields.textContent.html.name',
          defaultMessage: 'content',
        }) }
        validationErrors={ htmlErrors }
      />
    </>
  )
};
