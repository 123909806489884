import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Stack } from '../Stack/Stack';
import { Typography } from '../../../stories/components/Typography/Typography';
import { LineClamp } from '../../../stories/components/LineClamp/LineClamp';

export type AppUsagePolicyLink = {
  label: string;
  url: string;
};

type Props = {
  policyLinks?: AppUsagePolicyLink[];
  spaceName?: string;
};

export const PolicyLinks: FC<Props> = ({
  policyLinks = [],
  spaceName,
}) => {
  const intl = useIntl();

  return (
    <Stack
      direction="column"
      align="center"
      gap={ 2 }
      css={ {
        width: '300px',
        margin: '0 auto',
      } }
    >
      <Typography
        size="sm"
        palette={ {
          colour: 'grey',
          intensity: 600,
        } }
        weight="regular"
        align="center"
      >
        {
          spaceName
            ? (
              <FormattedMessage
                description="Description for app usage policy links on space login page"
                defaultMessage="This app is provided by OurPeople on behalf of {spaceName}. By signing in you are agreeing to:"
                values={ {
                  spaceName,
                } }
              />
            )
            : (
              <FormattedMessage
                description="Description for app usage policy links on non-space-specific login page"
                defaultMessage="By signing in you are agreeing to:"
              />
            )
        }
      </Typography>
      <PolicyLink
        url={ `https://www.ourpeople.com/${ navigator.language === 'en-GB' ? 'en-gb' : 'en-us' }/legal/acceptable-use-policy` }
        label={ intl.formatMessage({
          description: 'Label for link to OurPeople acceptable use policy.',
          defaultMessage: 'OurPeople Acceptable Use Policy',
        }) }
      />
      <PolicyLink
        url={ `https://www.ourpeople.com/${ navigator.language === 'en-GB' ? 'en-gb' : 'en-us' }/legal/app-privacy` }
        label={ intl.formatMessage({
          description: 'Label for link to OurPeople privacy policy.',
          defaultMessage: 'OurPeople App Privacy Policy',
        }) }
      />
      { policyLinks.map(policyLink => (
        <PolicyLink
          key={ policyLink.url }
          { ...policyLink }
        />
      )) }
    </Stack>
  );
}

const PolicyLink: FC<{ url: string; label: string }> = ({
  url,
  label,
}) => (
  <a
    href={ url }
    rel="noreferrer"
    target="_blank"
    css={ {
      lineBreak: 'anywhere',
    } }
  >
    <LineClamp>
      <Typography
        size="sm"
        weight="medium"
        align="center"
      >
        { label }
      </Typography>
    </LineClamp>
  </a>
);
