import { FC, FormEventHandler, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { AuthFullscreenLayout } from 'op-storybook/lib/components/AuthFullscreenLayout/AuthFullscreenLayout';
import { Form } from 'op-storybook/lib/components/Form/Form';
import InviteIcon from 'op-storybook/lib/assets/icon/figma/mail-x.svg';
import { Redirect } from 'react-router-dom';
import { LoadingState } from 'op-storybook/stories/components/LoadingState/LoadingState';
import { EmptyState } from 'op-storybook/stories/components/EmptyState/EmptyState';
import { BaseAuthHeader } from 'op-storybook/lib/components/BaseAuthHeader/BaseAuthHeader';
import { Typography } from 'op-storybook/stories/components/Typography/Typography';

import { AnonymousSettings } from '../../Hook';
import {
  PasswordLoginSubmitButton
} from '../../../Login/Component/PasswordLoginSubmitButton/PasswordLoginSubmitButton';
import { useLoginRequest } from '../../Hook/useLoginRequest';
import { RequestState } from '../../../Models';
import { useContextOrThrow } from '../../../Core/Hook';
import { IdentityReader } from '../../../Core/Utility/IdentityReader/IdentityReader';
import { ErrorResponseReader } from '../../../Common/Utility';
import { LoginStateContext } from '../../../Core/Provider/LoginStateProvider';
import { AuthLayoutRichText } from '../../Component/AuthLayoutRichText/AuthLayoutRichText';
import { IdentityContext } from '../../../Core/Provider/IdentityProvider/IdentityProvider';

type Props = {
  anonymousSettings: AnonymousSettings;
};

export const AcceptInvitationPage: FC<Props> = ({ anonymousSettings }) => {
  const intl = useIntl();
  const { identityRequest } = useContextOrThrow(IdentityContext);
  const policyLinkProps = useMemo(() => ({
    policyLinks: anonymousSettings.appUsagePolicyLinks,
    spaceName: anonymousSettings.name
  }), [anonymousSettings]);
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const { login, loginRequest } = useLoginRequest();
  const { loggedIn } = useContextOrThrow(LoginStateContext);

  const whenFormSubmitted: FormEventHandler = useCallback(event => {
    event.preventDefault();

    if (identityRequest.state !== RequestState.COMPLETE || !IdentityReader.identityHasIdentityAuthentication(identityRequest.result)) {
      return;
    }

    login({ invitationRecipientId: identityRequest.result.authentication.method.username }, rememberMe);
  }, [identityRequest, login, rememberMe]);

  if (loggedIn) {
    return <Redirect to="/"/>
  }

  return (
    <AuthFullscreenLayout
      { ...identityRequest.state === RequestState.COMPLETE ? { policyLinkProps } : {} }
    >
      {
        identityRequest.state === RequestState.COMPLETE
          ? (
            !IdentityReader.identityHasIdentityAuthentication(identityRequest.result) || identityRequest.result.authentication.method.name !== 'INVITATION'
              ? <Redirect to="/login"/>
              : (
                <>
                  <BaseAuthHeader
                    graphic={ {
                      type: 'logo',
                      props: {
                        src: anonymousSettings.smallLogoUrl,
                        alt: anonymousSettings.name,
                      },
                    } }
                  >
                    <Typography
                      type="display"
                      size="sm"
                      weight="semibold"
                      align="center"
                    >
                      { intl.formatMessage({
                        description: 'Heading used on accept invitation page.',
                        defaultMessage: 'Hi { firstName }! <strong>{ spaceName }</strong> uses OurPeople to communicate with you',
                      }, {
                        firstName: identityRequest.result.person.firstName,
                        lastName: identityRequest.result.person.lastName,
                        spaceName: anonymousSettings.name,
                      }) }
                    </Typography>
                    <AuthLayoutRichText text={ anonymousSettings.invitationEmailMessage }/>
                  </BaseAuthHeader>
                  <Form onSubmit={ whenFormSubmitted }>
                    <PasswordLoginSubmitButton
                      disabled={ false }
                      error={ loginRequest.state === RequestState.FAILED }
                      showRememberMe={true}
                      onRememberMeChange={ setRememberMe }
                      rememberMe={ rememberMe }
                      submitting={ loginRequest.state === RequestState.FETCHING }
                    />
                  </Form>
                </>
              )
          )
          : identityRequest.state === RequestState.FAILED
            ? ErrorResponseReader.isAxiosErrorWithData(identityRequest.result) && identityRequest.result.response.status === 401
              ? <Redirect to="/login"/>
              : (
                <EmptyState
                  text={ intl.formatMessage({
                    description: 'Error message header when user attempts to accept invite but can not be identified.',
                    defaultMessage: 'Something went wrong',
                  }) }
                  supportingText={ intl.formatMessage({
                    description: 'Error message supporting text when user attempts to accept invite but can not be identified.',
                    defaultMessage: 'Please try again later or request a new invitation from a manager if the problem persists.',
                  }) }
                  IconComponent={ InviteIcon }
                  buttonProps={ [] }
                  pad
                  breakpoint="mobile"
                />
              )
            : <LoadingState pad/>
      }
    </AuthFullscreenLayout>
  );
};
