import styled from '@emotion/styled';

import GlobeIcon from '../../../../Assets/img/icons/streamline/earth-1.svg';

export const StyledGlobeIcon = styled(GlobeIcon)`
  color: ${ props => props.theme.palette.secondary.main };
`;

export const StyledEveryoneCondition = styled.div`
  display: flex;
  gap: 1rem;
  padding: 1rem;
`;

export const StyledNobodyCondition = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: ${ props => props.theme.spacing(2) };
  padding: 1rem;
`;

export const StyledCondition = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  column-gap: ${props => props.theme.spacing(2)};
`;

export const StyledConditionShortcuts = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${ props => props.theme.spacing(1) };
  padding: 0 ${ props => props.theme.spacing(2) } ${ props => props.theme.spacing(1) };
  align-items: center;
`;
