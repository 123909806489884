import React, { FC, useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ButtonProps } from '@mui/material';
import { ButtonTypeMap } from '@mui/material/Button/Button';
import { add, formatISO, roundToNearestMinutes } from 'date-fns';

import { Box, PresentationIcon } from '../../../Common/Component';
import {
  StyledArrow,
  StyledHomePanels,
  StyledQuickAction,
  StyledQuickActions,
  StyledTemplates,
  StyledTemplatesLink
} from './style';
import { SvgComponent } from '../../../Common/Model';
import { GuardedLink } from '../../../Security/Component';
import AddPeopleIcon from '../../../Assets/img/icons/streamline/single-neutral-actions-add.svg';
import BroadcastsIcon from '../../../Assets/img/icons/streamline/layers.svg';
import FormsIcon from '../../../Assets/img/icons/streamline/task-checklist-write.svg';
import CoverIcon from '../../../Assets/img/icons/monochrome/content/cover.svg';
import FilesIcon from '../../../Assets/img/icons/streamline/folder-empty.svg';
import BroadcastEngagementIcon from '../../../Assets/img/icons/streamline/analytics-bars.svg';
import { PeoplePermission } from '../../../People/Model';
import { usePermissions } from '../../../Security/Hook';
import { BroadcastsPermission, DraftBroadcast } from '../../../Broadcasts/Model';
import { FormsPermission } from '../../../Forms/Model';
import { FilesPermission } from '../../../Files/Model';
import { useEnvironmentSettings } from '../../../Common/Hook';
import { useBroadcastTemplateGoogleEvents } from '../../../Broadcasts/Hook';
import { BroadcastTemplateDialog } from '../../../Broadcasts/Component';
import { BroadcastContentDefinitionRegistryProvider } from '../../../Broadcasts/Provider';
import { BroadcastNotificationSettingsFactory } from '../../../Broadcasts/Utility';

export const HomePanels: FC = () => {
  const intl = useIntl();
  const [templateDialogOpen, setTemplateDialogOpen] = useState<boolean>(false);
  const {
    allowUserSyncDecisions: environmentHasIntegration,
    formsEnabled,
    filesEnabled,
    eventsEnabled,
    broadcastsV2Enabled,
    broadcastsV1EventsEnabled,
    ...spaceSettings
  } = useEnvironmentSettings();
  const { trackViewTemplates } = useBroadcastTemplateGoogleEvents();
  const { permissionAvailable } = usePermissions();
  const showAddPeople = useMemo(() => permissionAvailable(PeoplePermission.CREATE) && !environmentHasIntegration, [
    environmentHasIntegration,
    permissionAvailable
  ]);
  const showCreateBroadcast = useMemo(() => permissionAvailable(BroadcastsPermission.CREATE), [permissionAvailable]);
  const showCreateForm = useMemo(() => formsEnabled && permissionAvailable(FormsPermission.CREATE), [
    formsEnabled,
    permissionAvailable
  ]);
  const showCreateCover = useMemo(
    () => (
      eventsEnabled
      && permissionAvailable(BroadcastsPermission.CREATE)
      && (
        !broadcastsV2Enabled
        || !!broadcastsV1EventsEnabled
      )
    ),
    [eventsEnabled, broadcastsV1EventsEnabled, broadcastsV2Enabled, permissionAvailable],
  );
  const showManageFiles = useMemo(() => filesEnabled && permissionAvailable(FilesPermission.CREATE) && [
    showAddPeople,
    showCreateBroadcast,
    showCreateForm,
    showCreateCover
  ].filter(Boolean).length < 4, [
    filesEnabled,
    permissionAvailable,
    showAddPeople,
    showCreateBroadcast,
    showCreateCover,
    showCreateForm
  ]);
  const showBroadcasts = useMemo(() => permissionAvailable(BroadcastsPermission.REPORT) && [
    showAddPeople,
    showCreateBroadcast,
    showCreateForm,
    showCreateCover,
    showManageFiles
  ].filter(Boolean).length < 4, [
    permissionAvailable,
    showAddPeople,
    showCreateBroadcast,
    showCreateCover,
    showCreateForm,
    showManageFiles
  ]);
  const coverTemplate = useMemo<DraftBroadcast>(
    () => {
      const startDate = roundToNearestMinutes(
        add(new Date(), { days: 1 }),
        { nearestTo: 30 },
      );
      const endDate = add(startDate, { hours: 4 });
      return {
        name: 'Cover',
        sendNudge: false,
        sendPush: true,
        notification: BroadcastNotificationSettingsFactory.fromSpaceSettings(spaceSettings),
        content: [
          {
            type: 'covercontent',
            text: 'Cover',
            start_at: {
              tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
              dt: formatISO(startDate),
            },
            end_at: {
              tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
              dt: formatISO(endDate),
            },
            location: '',
          },
        ],
        recipientAudience: {
          segments: [
            {
              conditions: [
                {
                  type: 'everyone',
                }
              ]
            }
          ]
        },
      };
    },
    [spaceSettings],
  );
  const quickActionProps: QuickActionProps[] = useMemo(() => [
    ...(
      showAddPeople
        ? [
          {
            key: 'addPeople',
            label: intl.formatMessage({
              id: 'dashboard.quickActions.addPeople',
              description: 'Label for add people quick action on dashboard.',
              defaultMessage: 'Add people',
            }),
            IconComponent: AddPeopleIcon,
            component: GuardedLink,
            to: '/people',
            permissions: [PeoplePermission.CREATE],
          }
        ]
        : []
    ),
    ...(
      showCreateBroadcast
        ? [
          {
            key: 'createBroadcast',
            label: intl.formatMessage({
              id: 'dashboard.quickActions.createBroadcast',
              description: 'Label for create broadcast quick action on dashboard.',
              defaultMessage: 'Create broadcast',
            }),
            IconComponent: BroadcastsIcon,
            component: GuardedLink,
            to: '/broadcasts/create',
            permissions: [BroadcastsPermission.CREATE],
          }
        ]
        : []
    ),
    ...(
      showCreateForm
        ? [
          {
            key: 'createForm',
            label: intl.formatMessage({
              id: 'dashboard.quickActions.createForm',
              description: 'Label for create form quick action on dashboard.',
              defaultMessage: 'Create form',
            }),
            IconComponent: FormsIcon,
            component: GuardedLink,
            to: '/forms/create',
            permissions: [FormsPermission.CREATE],
          }
        ]
        : []
    ),
    ...(
      showCreateCover
        ? [
          {
            key: 'createCover',
            label: intl.formatMessage({
              id: 'dashboard.quickActions.createCover',
              description: 'Label for create cover quick action on dashboard.',
              defaultMessage: 'Create cover',
            }),
            IconComponent: CoverIcon,
            component: GuardedLink,
            to: {
              pathname: '/broadcasts/build-template',
              state: {
                template: coverTemplate,
              },
            },
            permissions: [BroadcastsPermission.CREATE],
          }
        ]
        : []
    ),
    ...(
      showManageFiles
        ? [
          {
            key: 'manageFiles',
            label: intl.formatMessage({
              id: 'dashboard.quickActions.manageFiles',
              description: 'Label for manage files quick action on dashboard.',
              defaultMessage: 'Manage files',
            }),
            IconComponent: FilesIcon,
            component: GuardedLink,
            to: '/files/',
            permissions: [FilesPermission.CREATE],
          }
        ]
        : []
    ),
    ...(
      showBroadcasts
        ? [
          {
            key: 'broadcasts',
            label: intl.formatMessage({
              id: 'dashboard.quickActions.broadcasts',
              description: 'Label for broadcasts quick action on dashboard.',
              defaultMessage: 'Broadcasts',
            }),
            IconComponent: BroadcastEngagementIcon,
            component: GuardedLink,
            to: '/broadcasts',
            permissions: [BroadcastsPermission.REPORT],
          }
        ]
        : []
    ),
  ], [
    intl,
    showAddPeople,
    showBroadcasts,
    showCreateBroadcast,
    showCreateCover,
    showCreateForm,
    showManageFiles,
    coverTemplate,
  ]);

  const whenTemplateButtonClicked = useCallback(() => {
    trackViewTemplates('Dashboard');
    setTemplateDialogOpen(true);
  }, [trackViewTemplates]);

  return (
    <>
      <StyledHomePanels>
        { !broadcastsV2Enabled && (
          <Box margin={ false } padded={ false }>
            <StyledTemplates>
              <h3>
                <FormattedMessage
                  id="dashboard.templatesLink.heading"
                  description="Heading in template link panel on dashboard page."
                  defaultMessage="Looking for content ideas?"
                />
              </h3>
              <StyledTemplatesLink>
                <button onClick={ whenTemplateButtonClicked }>
                  <FormattedMessage
                    id="dashboard.templatesLink.label"
                    description="Link label in template link panel on dashboard page."
                    defaultMessage="Explore template gallery"
                  />
                  <StyledArrow role="presentation"/>
                </button>
              </StyledTemplatesLink>
            </StyledTemplates>
          </Box>
        ) }
        <Box margin={ false } padded={ false }>
          <StyledQuickActions>
            <h3>
              <FormattedMessage
                id="dashboard.quickActions.heading"
                description="Heading in quick actions panel on dashboard page."
                defaultMessage="Quick actions"
              />
            </h3>
            <div>
              { quickActionProps.map(({ key, ...quickActionProps }) => (
                <QuickActionComponent
                  key={ key }
                  { ...quickActionProps }
                />
              )) }
            </div>
          </StyledQuickActions>
        </Box>
      </StyledHomePanels>
      <BroadcastContentDefinitionRegistryProvider>
        <BroadcastTemplateDialog
          open={ templateDialogOpen }
          onClose={ () => setTemplateDialogOpen(false) }
        />
      </BroadcastContentDefinitionRegistryProvider>
    </>
  );
};

type QuickActionProps<D extends React.ElementType = ButtonTypeMap['defaultComponent'],
  P = Record<string, unknown>> = ButtonProps<D, P> & {
  IconComponent: SvgComponent;
  label: string;
}

const QuickActionComponent: FC<QuickActionProps> = ({ IconComponent, label, ...buttonProps }) => (
  <StyledQuickAction
    { ...buttonProps }
  >
    <PresentationIcon
      IconComponent={ IconComponent }
      size="large"
      color="primary.main"
    />
    <span>{ label }</span>
  </StyledQuickAction>
);
