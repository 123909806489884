import { useCallback, useContext } from 'react';
import { useIntl } from 'react-intl';
import { AutocompleteCommonProps, AutocompleteFetchRequest, AutocompleteSelectionChanged } from '@ourpeople/shared/Core/Component/Input/Autocomplete/Autocomplete';

import { ApiContext } from '../../../Contexts';
import { Paginated, Person } from '../../../Models';
import { buildPeopleRequestQuery, PeopleRequestParams } from '../../Hook';
import { PersonParser } from '../../../Utility';
import { DebouncedAutocomplete } from '../../../Common/Component';

export interface PersonAutocompleteProps extends AutocompleteCommonProps {
  selectedIds: string[];
  onSelectionChanged: AutocompleteSelectionChanged<Person>;
  baseQuery?: PeopleRequestParams,
}

export const PersonAutocomplete = ({
  selectedIds,
  onSelectionChanged,
  baseQuery,
  ...props
}: PersonAutocompleteProps): JSX.Element => {
  const intl = useIntl();
  const api = useContext(ApiContext);

  const whenFetch: AutocompleteFetchRequest<Person> = async (
    ids: string[],
    search: string,
    pageNum: number,
  ) => {
    if (!api) {
      throw new Error('api not ready');
    }

    const query = buildPeopleRequestQuery({
      sort: ['first_name_asc'],
      ...baseQuery,
      pageNum,
      ids: ids.length ? ids : undefined,
      search: search.trim() || undefined,
    });
    const response = await api.get<Paginated<'people', Person>>('/people', { params: query });
    return {
      options: response.data.people,
      pageCount: response.data.pagination.pageCount,
    };
  }

  return <DebouncedAutocomplete<Person>
    {...props}
    getOptionLabel={ (person) => PersonParser.fullName(person) }
    fetchOptions={useCallback(whenFetch, [api, baseQuery])}
    selectedIds={selectedIds}
    onSelectionChanged={onSelectionChanged}
    label={
      props.label !== undefined
        ? props.label
        : intl.formatMessage(
          {
            id: 'personAutocomplete.label',
            defaultMessage: 'Select {limit, plural, one {person} other {people}}'
          },
          {
            limit: props.multiple ? Infinity : 1,
          }
        )
    }
    placeholder={
      props.placeholder ||
      intl.formatMessage(
        {
          id: 'personAutocomplete.placeholder',
          defaultMessage: 'Search for {limit, plural, one {person} other {people}}'
        },
        {
          limit: props.multiple ? Infinity : 1,
        }
      )
    }
  />;
};
