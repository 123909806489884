import { FC, useCallback, useState } from 'react';
import { Dialog, DialogActions, DialogTitle } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';
import { BreakpointContext } from 'op-storybook/lib/providers/BreakpointProvider/BreakpointProvider';

import { Flex, FlexPullRight, IconButton, MultiDropzone, } from '..';
import CloseIcon from '../../../Assets/img/icons/streamline/close.svg';
import {
  StyledDialog,
  StyledDialogContainer,
  StyledDialogContent,
  StyledDropzoneContainer,
  StyledFileName,
  StyledFileRow
} from './style';
import UploadIcon from '../../../Assets/img/icons/streamline/upload.svg';
import FileIcon from '../../../Assets/img/icons/streamline/common-file-empty.svg';
import RemoveIcon from '../../../Assets/img/icons/monochrome/close.svg';
import { ArrayHelper, UniqueIdGenerator } from '../../Utility';
import { useFileUploader } from '../../Hook';
import { useContextOrThrow } from '../../../Core/Hook';
import { UploadCompleteCallback } from '../../Context';

type Props = {
  open: boolean;
  onClose: () => void;
  onComplete: UploadCompleteCallback;
  parentFolderId: string;
};

export const FileUploadDialog: FC<Props> = ({
  open,
  onClose,
  onComplete,
  parentFolderId,
}) => {
  const { startGlobalUpload } = useFileUploader();
  const intl = useIntl();
  const [pendingFiles, setPendingFiles] = useState<File[]>([]);
  const { lessThan } = useContextOrThrow(BreakpointContext);

  const whenConfirmClicked = useCallback(() => {
    if (!pendingFiles) {
      return;
    }

    pendingFiles.forEach(file => {
      const fileRef = UniqueIdGenerator.generate();
      startGlobalUpload(
        fileRef,
        'files/authorise-upload',
        file,
        onComplete,
        {
          type: 'files',
          parentFolderId,
        },
      );
      return fileRef;
    });

    onClose();
    setPendingFiles([]);
  }, [onClose, onComplete, parentFolderId, pendingFiles, startGlobalUpload]);

  const whenDropped = useCallback((files: File[]) => {
    if (!files.length) {
      return;
    }

    setPendingFiles(files);
  }, []);

  const cancelMessage = intl.formatMessage({
    id: 'FileUploadDialog.cancel',
    description: 'Label for upload cancel button in file upload dialog.',
    defaultMessage: 'Cancel',
  });

  const whenCancelled = useCallback(() => {
    onClose();
  }, [onClose]);

  const uploadFileRow = (file: File, fileIndex: number) => {
    return <StyledFileRow key={ `${ file.name }-${ file.lastModified }` }>
      <Flex gap={ 3 }>
        <FileIcon/>
        <StyledFileName>{ file.name }</StyledFileName>
      </Flex>
      <Button onClick={ () => whenFileRemoved(fileIndex) } IconComponent={ RemoveIcon }>
        { !lessThan.xs && <FormattedMessage id="FileUploadDialog.removeUploadFile" defaultMessage="Remove"/> }
      </Button>
    </StyledFileRow>
  };

  const whenFileRemoved = useCallback((fileIndex: number) => {
    setPendingFiles(ArrayHelper.remove(pendingFiles, fileIndex));
  }, [pendingFiles, setPendingFiles]);

  return (
    <StyledDialogContainer>
      <Dialog
        open={ open }
        onClose={ whenCancelled }
        maxWidth="lg"
        fullWidth
        PaperComponent={ StyledDialog }
      >
        <DialogTitle>
          <Flex gap={ 2 }>
            <UploadIcon/>
            <FormattedMessage
              description="Title for upload dialog."
              defaultMessage="Upload"
            />
            <FlexPullRight>
              <IconButton
                IconComponent={ CloseIcon }
                label={ cancelMessage }
                onClick={ whenCancelled }
                size="small"
              />
            </FlexPullRight>
          </Flex>
        </DialogTitle>
        <StyledDialogContent>
          <StyledDropzoneContainer>
            <MultiDropzone
              onDrop={ whenDropped }
            >
              { pendingFiles.map((file, fileIndex) => uploadFileRow(file, fileIndex)) }
            </MultiDropzone>
          </StyledDropzoneContainer>
        </StyledDialogContent>

        <DialogActions>
          <Button
            onClick={ onClose }
          >
            { cancelMessage }
          </Button>
          <Button
            onClick={ whenConfirmClicked }
            variant="primary"
          >
            <FormattedMessage
              description="Label for upload button."
              defaultMessage="Upload"
            />
          </Button>
        </DialogActions>
      </Dialog>
    </StyledDialogContainer>
  );
};

