import React, { FunctionComponent, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { InputLabel } from '@mui/material';
import { AutocompleteSelectionChanged } from '@ourpeople/shared/Core/Component/Input/Autocomplete/Autocomplete';

import { StyledPersonConditionContainer } from './style';
import { LabelModifier } from '../..';
import { PersonAutocomplete } from '../../../../People/Component';
import { PersonCondition } from '../../../Model';
import { Person } from '../../../../Models';
import { FieldValidationErrors } from '../../../../Common/Component';
import { ValidationTree } from '../../../../Common/Model';
import { useDescendantErrors } from '../../../../Common/Hook';
import { PeopleRequestParams } from '../../../../People/Hook';
import { useUserRoles } from '../../../../Common/Hook';
import { useAuthDescription } from '../../../../Core/Hook';
import { ArrayHelper } from '../../../../Common/Utility';

interface Props {
  condition: PersonCondition;
  onChange: (condition: PersonCondition) => void;
  validation?: ValidationTree<PersonCondition>;
  readonly?: boolean;
  autoFocus?: boolean;
}

export const PersonConditionBuilder: FunctionComponent<Props> = ({
  condition,
  onChange,
  validation,
  readonly = false,
  autoFocus,
}) => {
  const intl = useIntl();
  const { userIsSuperAdmin } = useUserRoles();
  const authDescription = useAuthDescription();
  const personErrors = useDescendantErrors(validation);
  const modifierOptions = [
    intl.formatMessage({
      id: 'audiences.builder.personCondition.modifier.are',
      defaultMessage: 'are',
    }),
    intl.formatMessage({
      id: 'audiences.builder.personCondition.modifier.areNot',
      defaultMessage: 'are not',
    }),
  ];

  const baseQuery = useMemo<PeopleRequestParams>(
    () => {
      if (userIsSuperAdmin || !condition.new) {
        return {};
      }

      return {
        teamIds: authDescription?.administratedTags.map(id => id.toString()) || []
      }
    },
    [userIsSuperAdmin, authDescription, condition],
  );

  const whenPeopleChanged: AutocompleteSelectionChanged<Person> = (selection) => {
    const newPersonIds = [
      ...selection.options.map((person) => person.id),
      ...selection.unknownIds,
    ];

    if (ArrayHelper.containSameValues<string>(newPersonIds, condition.personIds) === false) {
      onChange({
        ...condition,
        personIds: newPersonIds,
      });
    }
  };

  const whenModifierChanged = (modifierIndex: number): void => {
    onChange({
      ...condition,
      not: modifierIndex === 1,
    });
  };

  return <StyledPersonConditionContainer>
    <InputLabel>
      <FormattedMessage
        id="audiences.builder.personCondition.peopleLabel"
        description="Label for the person condition people select"
        defaultMessage="People who"
      />
      <LabelModifier
        options={modifierOptions}
        activeIndex={condition.not ? 1 : 0}
        onChange={whenModifierChanged}
        readonly={ readonly }
      />
    </InputLabel>
    <PersonAutocomplete
      multiple={true}
      selectedIds={condition.personIds}
      onSelectionChanged={whenPeopleChanged}
      label={''}
      dense
      readonly={ readonly }
      baseQuery={ baseQuery }
      autoFocus={ autoFocus }
      listStyle="grid"
    />
    <FieldValidationErrors
      fieldName={ intl.formatMessage({
        id: 'audiences.builder.fields.personCondition.name',
        description: 'Field name for person condition builder',
        defaultMessage: 'person condition'
      }) }
      validationErrors={ personErrors }
      selectionSingular={ intl.formatMessage({
        id: 'audiences.builder.fields.personCondition.singular',
        description: 'Singular selection name in person condition builder',
        defaultMessage: 'person'
      }) }
      selectionPlural={ intl.formatMessage({
        id: 'audiences.builder.fields.personCondition.plural',
        description: 'Plural selection name in person condition builder',
        defaultMessage: 'people'
      }) }
    />
  </StyledPersonConditionContainer>;
};
