import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { rgba } from 'polished';
import { PaletteKey } from 'op-storybook/lib/types/Theme/Theme';

import { ButtonBaseVariant } from './ButtonBase';
import { css } from '../../../../../src/react/Styling/css';

type Props = {
  colour: PaletteKey;
  fillParent: boolean;
  variant: ButtonBaseVariant;
  active: boolean;
  padding: 'standard' | 'uniform' | 'none';
  disabled: boolean;
};

export const styles = css<Props>`
  position: relative;
  border-radius: ${ props => props.theme.new.borderRadius.standard };
  text-align: center;
  touch-action: manipulation;
  justify-content: center;
  padding: ${ props => props.padding === 'none'
    ? '0'
    : `
      ${
        props.padding === 'uniform'
          ? props.theme.new.spacing[3]
          : props.theme.new.spacing[2]
      } ${ props.theme.new.spacing[3] }
    `
  };
  transition: box-shadow 0.2s, background-color 0.2s, border 0.2s, color 0.2s, padding 0.2s;
  width: ${ props => props.fillParent ? '100%' : 'auto' };
  display: flex;
  gap: ${ props => props.theme.new.spacing[2] };
  background-color: transparent;
  border: none;

  &:focus-visible {
    outline: none;
  }

  &:hover {
    text-decoration: none;
  }

  ${ props => props.variant === 'secondary-grey' && `
    background-color: ${ props.theme.new.basePalette.white.main };
    border: ${
      props.disabled
        ? props.theme.new.border.light
        : props.theme.new.border.standard
    };
    box-shadow: ${ props.theme.new.shadow.xs };
    color: ${
      props.disabled
        ? props.theme.new.palette.grey[300].main
        : props.theme.new.palette.grey[700].main
    };

    ${
      props.disabled
        ? ''
        : `
          &:focus-visible {
            box-shadow: ${ props.theme.new.shadow.focusRingGrey };
          }

          &:hover, &:active {
            background-color: ${ props.theme.new.palette.grey[50].main };
          }
        `
    }
  ` }

  ${ props => props.variant === 'secondary-colour' && `
    background-color: ${ props.theme.new.basePalette.white.main };
    border: ${
      props.disabled
        ? props.theme.new.border.light
        : `1px solid ${ props.theme.new.palette[props.colour][300].main }`
    };
    box-shadow: ${ props.theme.new.shadow.xs };
    color: ${
      props.disabled
        ? props.theme.new.palette.grey[300].main
        : props.theme.new.palette[props.colour][700].main
    };

    ${
      props.disabled
        ? ''
        : `
          &:focus-visible {
            box-shadow: ${ props.theme.new.shadow.focusRingNoColour } ${ props.theme.new.palette[props.colour][100].main };
          }

          &:hover, &:active {
            background-color: ${ props.theme.new.palette[props.colour][50].main };
          }
        `
   }
  ` }

  ${ props => props.variant === 'tertiary-light' && `
    background-color: transparent;
    color: ${
      props.disabled
        ? props.theme.new.palette.grey[300].main
        : props.theme.new.palette[props.colour][300].main
    };

    ${
      props.disabled
        ? ''
        : `
          &:focus-visible {
            box-shadow: ${ props.theme.new.shadow.focusRingNoColour } ${ rgba(props.theme.new.palette[props.colour][300].main, 0.2) };
          }

          &:hover, &:active {
            background-color: ${ rgba(props.theme.new.palette[props.colour][300].main, 0.2) };
          }
        `
    }
  ` }

  ${ props => props.variant === 'tertiary-dark' && `
    background-color: transparent;
    color: ${
      props.disabled
        ? props.theme.new.palette.grey[300].main
        : props.theme.new.palette[props.colour][600].main
    };

    ${
      props.disabled
        ? ''
        : `
          &:focus-visible {
            box-shadow: ${ props.theme.new.shadow.focusRingNoColour } ${ rgba(props.theme.new.palette[props.colour][600].main, 0.1) };
          }

          &:hover, &:active {
            background-color: ${ rgba(props.theme.new.palette[props.colour][600].main, 0.1) };
          }
        `
    }
  ` }

  ${ props => props.variant === 'filled-light' && `
    background-color: ${
      props.disabled
        ? props.theme.new.palette[props.colour][200].main
        : props.theme.new.palette[props.colour][600].main
    };
    color: ${ props.theme.new.palette[props.colour][600].contrast };
    border: 1px solid ${
      props.disabled
        ? props.theme.new.palette[props.colour][200].main
        : props.theme.new.palette[props.colour][600].main
    };

    ${
      props.disabled
        ? ''
        : `
          &:focus-visible {
            box-shadow: ${ props.theme.new.shadow.focusRingNoColour } ${ props.theme.new.palette[props.colour][100].main };
          }

          &:hover, &:active {
            background-color: ${ props.theme.new.palette[props.colour][700].main };
            border-color: ${ props.theme.new.palette[props.colour][700].main };
          }
        `
    }
  ` }

  ${ props => props.variant === 'filled-dark' && `
    background-color: ${ props.theme.new.palette[props.colour][700].main };
    ${ props.disabled ? 'pointer-events: none;' : '' }
    color: ${
      props.disabled
        ? props.theme.new.palette[props.colour][600].main
        : props.theme.new.palette[props.colour][100].main
    };
    border: 1px solid ${ props.theme.new.palette[props.colour][700].main };

    ${
      props.disabled
        ? ''
        : `
          &:focus-visible {
            box-shadow: ${ props.theme.new.shadow.focusRingNoColour } ${ props.theme.new.palette[props.colour][500].main };
          }

          &:hover, &:active {
            background-color: ${ props.theme.new.palette[props.colour][600].main };
            border-color: ${ props.theme.new.palette[props.colour][600].main };
            color: ${ props.theme.new.palette[props.colour][600].contrast };
          }

          ${
            props.active
              ? `
                background-color: ${ props.theme.new.palette[props.colour][600].main };
                border-color: ${ props.theme.new.palette[props.colour][600].main };
                color: ${ props.theme.new.palette[props.colour][600].contrast };
              `
              : ''
          }
        `
    }
  ` }
`;

export const StyledButtonBase = styled.button<Props>`
  ${ styles };
`;

export const StyledAnchorBase = styled.a<Props>`
  ${ styles };
`;

export const StyledLinkBase = styled(Link, {
  shouldForwardProp: prop => !['active', 'fillParent'].includes(prop)
})<Props>`
  ${ styles };
`;
