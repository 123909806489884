import { useGet } from '../../Hooks/useGet';
import { FetchResponse } from '../../Hooks/useRequest';
import { PersonWithSensitiveData } from '../../Models/Person';
import { ProfileAndMinimalTags } from '../../Profiles/Model/Profile';

export type CustomField = {
  key: string;
  value: string;
};

type FetchPersonResponse = {
  person: PersonWithSensitiveData;
  customFields: CustomField[];
  profiles: ProfileAndMinimalTags[],
};

export const useFetchPerson = (
  personId: string,
): FetchResponse<FetchPersonResponse> => useGet(`/people/${personId}`);
