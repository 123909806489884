import { ChangeEventHandler, FunctionComponent, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { FieldValidationErrors, LabelledSwitch, NumberInput } from '../../../../Common/Component';
import {
  DraftTextRequest,
  TEXT_REQUEST_MAX_LENGTH_MAX,
  TEXT_REQUEST_MAX_LENGTH_MIN,
  TEXT_REQUEST_MIN_LENGTH_MAX,
  TEXT_REQUEST_MIN_LENGTH_MIN
} from '../../../Model/ContentTypes';
import { RequestFields } from '..';
import { TextRequestValidator } from '../../../Utility/Validation';
import { ValidationTree } from '../../../../Common/Model';
import { TextRequestEditorContent } from '../../../Service';
import { ContentEditorProps } from '../../../../Content/Model';
import { CardValidationMerger } from '../../../../Content/Utility';

export const TextRequestFields: FunctionComponent<ContentEditorProps<TextRequestEditorContent>> = ({
  editorContent,
  onEditorContentChanged,
  validation,
  onValidationChanged,
}) => {
  const intl = useIntl();
  const minLengthErrors = validation?.children.content?.children.minLength?.errors || [];
  const maxLengthErrors = validation?.children.content?.children.maxLength?.errors || [];

  const whenMultilineChanged = (checked: boolean): void => {
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          multiline: checked,
        },
      },
    });
  };

  const whenMinLengthChanged: ChangeEventHandler<HTMLInputElement> = (event) => {
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          minLength: +event.target.value,
        },
      },
    });
  };

  const whenMaxLengthChanged: ChangeEventHandler<HTMLInputElement> = (event) => {
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          maxLength: +event.target.value,
        },
      },
    });
  };

  const whenMinLengthBlurred = () => whenContentChildrenValidationChanged({
    minLength: TextRequestValidator.validateMinLength(editorContent.card.content),
  });
  const whenMaxLengthBlurred = () => whenContentChildrenValidationChanged({
    maxLength: TextRequestValidator.validateMaxLength(editorContent.card.content),
  });

  const whenContentChildrenValidationChanged = useCallback(
    (children: ValidationTree<DraftTextRequest>['children']): void => {
      onValidationChanged(
        CardValidationMerger.addContentValidationChildrenToCardValidation(children, validation)
      );
    },
    [validation, onValidationChanged],
  );

  return (
    <>
      <RequestFields
        editorContent={ editorContent }
        onEditorContentChanged={ onEditorContentChanged }
        validation={ validation }
        onValidationChanged={ onValidationChanged }
      />
      <LabelledSwitch
        id="multiLine"
        checked={ editorContent.card.content.multiline }
        onChange={ whenMultilineChanged }
        label={ intl.formatMessage({
          id: 'form.fields.textRequest.multiLine.label',
          defaultMessage: 'Multi-line',
        }) }
      />
      <NumberInput
        id="minLength"
        inputProps={ {
          min: TEXT_REQUEST_MIN_LENGTH_MIN,
          max: Math.min(editorContent.card.content.maxLength, TEXT_REQUEST_MIN_LENGTH_MAX),
        } }
        label={ intl.formatMessage({
          id: 'form.fields.textRequest.minLength.label',
          defaultMessage: 'Min length *',
        }) }
        value={ editorContent.card.content.minLength }
        onChange={ whenMinLengthChanged }
        onBlur={ whenMinLengthBlurred }
        error={ !!minLengthErrors.length }
        fullWidth
      />
      <FieldValidationErrors
        fieldName={ intl.formatMessage({
          id: 'form.fields.textRequest.minLength.name',
          defaultMessage: 'min length',
        }) }
        validationErrors={ minLengthErrors }
      />
      <NumberInput
        id="maxLength"
        inputProps={ {
          min: Math.max(editorContent.card.content.minLength, TEXT_REQUEST_MAX_LENGTH_MIN),
          max: TEXT_REQUEST_MAX_LENGTH_MAX,
        } }
        label={ intl.formatMessage({
          id: 'form.fields.textRequest.maxLength.label',
          defaultMessage: 'Max length *',
        }) }
        value={ editorContent.card.content.maxLength }
        onChange={ whenMaxLengthChanged }
        onBlur={ whenMaxLengthBlurred }
        error={ !!maxLengthErrors.length }
        fullWidth
      />
      <FieldValidationErrors
        fieldName={ intl.formatMessage({
          id: 'form.fields.textRequest.maxLength.name',
          defaultMessage: 'max length',
        }) }
        validationErrors={ maxLengthErrors }
      />
    </>
  );
};
