import { ChangeEvent, Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';
import { MenuItem, Select } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { endOfDay, parse, startOfDay } from 'date-fns';

import { InterFieldText } from '..';
import { NoMarginTextField } from '../../../Common/Component';
import { StyledSchedulePeriod } from './style';
import { useInputChangeEventHandler } from '../../../Common/Hook';
import { DateTimeFormatter } from '../../../Utility/DateTimeFormatter';
import { RecurringSchedule } from '../../Model/BroadcastSchedule';
import { BroadcastScheduleIdentifier } from '../../Utility/BroadcastScheduleIdentifier';

type Props<S extends RecurringSchedule> = {
  schedule: S;
  setSchedule: Dispatch<SetStateAction<S>>;
};

export const SchedulePeriod = <S extends RecurringSchedule>({
  schedule,
  setSchedule,
}: Props<S>): JSX.Element => {
  const [start, setStart] = useState<'now' | Date>(
    schedule.localStartDate || 'now'
  );
  const [end, setEnd] = useState<'never' | number | Date>(
    schedule.recurrence.end
      ? schedule.recurrence.end.type === 'afterOccurrences'
        ? schedule.recurrence.end.count
        : schedule.recurrence.end.localDate
      : 'never'
  );
  const todaysDate = DateTimeFormatter.dateInput(new Date());
  const startType = start === 'now' ? 'now' : 'date';
  const startDate = start instanceof Date ? DateTimeFormatter.dateInput(start) : undefined;
  const endType = end === 'never' ? 'never' : end instanceof Date ? 'date' : 'after';
  const endDate = end instanceof Date ? DateTimeFormatter.dateInput(end) : undefined;
  const endAfter = typeof end === 'number' ? end : 2;

  useEffect(() => {
    setSchedule(schedule => ({
      ...schedule,
      localStartDate: start === 'now'
        ? null
        : startOfDay(start),
      recurrence: {
        ...schedule.recurrence,
        end: end === 'never'
          ? null
          : end instanceof Date
            ? {
              type: 'onDate',
              localDate: endOfDay(end),
            }
            : {
              type: 'afterOccurrences',
              count: end,
            },
      }
    }))
  }, [end, setSchedule, start]);

  const whenStartTypeChanged = (event: ChangeEvent<{ value: unknown }>) => {
    if (event.target.value === 'date') {
      setStart(new Date());
    } else {
      setStart('now');
    }
  };

  const whenEndTypeChanged = (event: ChangeEvent<{ value: unknown }>) => {
    if (event.target.value === 'date') {
      setEnd(new Date());
    } else if (event.target.value === 'after') {
      setEnd(2);
    } else {
      setEnd('never')
    }
  };

  const whenEndAfterChanged = useInputChangeEventHandler(endAfter => {
    setEnd(+endAfter);
  });

  const whenStartDateChanged = useInputChangeEventHandler(date => {
    if (!date) {
      return;
    }

    setStart(parse(date, 'yyyy-MM-dd', new Date()));
  });

  const whenEndDateChanged = useInputChangeEventHandler(date => {
    if (!date) {
      return;
    }

    setEnd(parse(date, 'yyyy-MM-dd', new Date()));
  });

  return (
    <StyledSchedulePeriod>
      <FormattedMessage
        id="scheduleRecurring.recurrence-fields"
        description="Layout of form fields for selecting start and end of recurrence."
        defaultMessage="{ hideStart, select, true {} other { <text>starting</text>{startType}{startDate} } }<text>ending</text>{endType}{endDate}{endAmount}{amountLabel}"
        values={ {
          hideStart: BroadcastScheduleIdentifier.scheduleIsAnnual(schedule),
          startType: (
            <Select
              id="broadcast-repeating-on"
              value={ startType }
              autoWidth={ true }
              variant="outlined"
              required
              onChange={ whenStartTypeChanged }
              name="repeat"
              margin="dense"
              MenuProps={ {
                anchorOrigin: {
                  horizontal: 'left',
                  vertical: 'bottom',
                },
              } }
            >
              <MenuItem value="now">
                <FormattedMessage
                  id="broadcasts.scheduling.now"
                  description="Label for sending a broadcast now."
                  defaultMessage="now"
                />
              </MenuItem>
              <MenuItem value="date">
                <FormattedMessage
                  id="broadcasts.scheduling.specific-date"
                  description="Label for selecting specific date option for start or end of recurrence."
                  defaultMessage="specific date"
                />
              </MenuItem>
            </Select>
          ),
          startDate: startType === 'date' && startDate && (
            <NoMarginTextField
              id="broadcast-scheduling-start-on"
              variant="outlined"
              type="date"
              required
              name="scheduledDate"
              InputProps={ {
                inputProps: {
                  min: todaysDate,
                }
              } }
              onChange={ whenStartDateChanged }
              value={ startDate }
            />
          ),
          endType: (
            <Select
              id="recurring-end-type"
              value={ endType }
              autoWidth={ true }
              variant="outlined"
              required
              onChange={ whenEndTypeChanged }
              margin="dense"
              MenuProps={ {
                anchorOrigin: {
                  horizontal: 'left',
                  vertical: 'bottom',
                },
              } }
            >
              <MenuItem value="never">
                <FormattedMessage
                  id="broadcasts.scheduling.never"
                  description="Label for ending a recurring broadcast never."
                  defaultMessage="never"
                />
              </MenuItem>
              <MenuItem value="date">
                <FormattedMessage
                  id="broadcasts.scheduling.specific-date"
                  description="Label for selecting specific date option for start or end of recurrence."
                  defaultMessage="specific date"
                />
              </MenuItem>
              <MenuItem value="after">
                <FormattedMessage
                  id="broadcasts.scheduling.after"
                  description="Label for ending a recurring broadcast after n deliveries."
                  defaultMessage="after"
                />
              </MenuItem>
            </Select>
          ),
          endDate: endType === 'date' && endDate && (
            <NoMarginTextField
              id="broadcast-scheduling-end-on"
              variant="outlined"
              type="date"
              name="endDate"
              required
              InputProps={ {
                inputProps: {
                  min: startDate || todaysDate,
                }
              } }
              onChange={ whenEndDateChanged }
              value={ endDate }
            />
          ),
          endAmount: endType === 'after' && (
            <NoMarginTextField
              id="broadcast-scheduling-end-after"
              variant="outlined"
              type="number"
              required
              name="scheduledDate"
              InputProps={ {
                inputProps: {
                  min: 2
                }
              } }
              onChange={ whenEndAfterChanged }
              value={ endAfter }
            />
          ),
          text: (chunks: (string | ReactNode)[]) => (
            <InterFieldText>{ chunks }</InterFieldText>
          ),
          amountLabel: endType === 'after' && (
            <InterFieldText>
              <FormattedMessage
                id="broadcasts.scheduling.ending-after-occurrences"
                description="Inter-field label when specifying end number of occurrences to end after."
                defaultMessage="occurrences"
              />
            </InterFieldText>
          ),
        } }
      />
    </StyledSchedulePeriod>
  );
};
