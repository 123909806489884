import styled from '@emotion/styled';

type StyledFormGridCellProps = {
  fullWidth?: boolean;
};

export const StyledFormGridCell = styled.div<StyledFormGridCellProps>`
  padding-bottom: 1rem;

  ${props => props.fullWidth && `
    .MuiTextField-root {
      width: 100%;
    }
  `};
`;

export const StyledFormGrid = styled.div`
  display: grid;
  grid-template-columns: 10ch auto;
  margin: 1rem 0;

  ${StyledFormGridCell}:first-of-type {
    grid-row: 1 / 3;
  }
`;

export const StyledUploadImageImage = styled.div`
  display: inline-block;
  max-width: 20%;

  img {
    max-width: 100%;
  }

  .MuiButtonBase-root {
    min-width: auto;
    max-width: 100%;
  }
`;
