import { ComponentProps, FC, PropsWithChildren, useEffect } from 'react';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { useHistory } from 'react-router-dom';
import { StackEnd } from 'op-storybook/lib/components/StackEnd/StackEnd';
import ChevronIcon from 'op-storybook/lib/assets/icon/figma/chevron-left.svg';
import { PresentationIcon } from 'op-storybook/lib/components/PresentationIcon/PresentationIcon';
import { LocationDescriptor } from 'history';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from 'op-storybook/stories/components/Button/Button';
import { SecondaryInformation } from 'op-storybook/stories/components/Typography/SecondaryInformation';
import { ViewportHeading } from 'op-storybook/stories/components/Typography/ViewportHeading';

import { useContextOrThrow } from '../../../../Core/Hook';
import { Toolbar } from './Toolbar';
import { ContextMenu } from '../Content/ContextMenu';
import { PageLayoutContext } from '../../../Core/Provider/PageLayoutProvider';
import { LayoutLink } from './LayoutLink';

type Props = {
  title: string;
  sensitiveTitle?: string | null;
  contextMenuProps?: ComponentProps<typeof ContextMenu>;
  backButtonLabel?: string;
  className?: string;
} & (
  { returnLocation: LocationDescriptor; onClose?: never }
  | { onClose: () => void; returnLocation?: never }
  | { onClose?: never; returnLocation?: never }
  | { onClose?: never; returnLocation: null }
);

export const TitleBar: FC<PropsWithChildren<Props>> = ({
  title,
  returnLocation,
  onClose,
  children,
  sensitiveTitle,
  contextMenuProps,
  backButtonLabel,
  className,
}) => {
  const intl = useIntl();
  const history = useHistory();
  const { active, modal } = useContextOrThrow(PageLayoutContext);

  useEffect(() => {
    if (!active || !title || modal) {
      return;
    }

    document.title = intl.formatMessage({
      description: 'Format of page title used in browser history.',
      defaultMessage: '{ title } · OurPeople App',
    }, {
      title,
    });
  }, [active, intl, modal, title]);

  return (
    <Toolbar
      verticalAlign="start"
      hideOnDesktop={ false }
      className={ className }
    >
      { children || (
        <div
          css={ {
            width: '100%',
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            alignItems: 'center',
          } }
        >
          {
            returnLocation !== undefined || onClose
              ? (
                <div css={ { marginRight: 'auto' } }>
                  <Button
                    padding="uniform"
                    variant="tertiary"
                    {
                      ...returnLocation
                        ? ({
                          component: LayoutLink,
                          to: returnLocation,
                        })
                        : onClose
                          ? ({
                            component: 'button',
                            onClick: onClose,
                          })
                          : ({
                            component: 'button',
                            onClick: () => history.goBack(),
                          })
                    }
                  >
                    <Stack
                      align="center"
                      gap={ 0 }
                      css={ theme => ({
                        color: theme.new.palette.primary[700].main,
                      }) }
                    >
                      { returnLocation && (
                        <PresentationIcon
                          IconComponent={ ChevronIcon }
                          size={ 6 }
                        />
                      ) }
                      <SecondaryInformation palette="inherit">
                        {
                          backButtonLabel || (
                            returnLocation
                              ? (
                                <FormattedMessage
                                  description="Lable for back button in page title bar."
                                  defaultMessage="Back"
                                />
                              )
                              : (
                                <FormattedMessage
                                  description="Label for close button in page title bar."
                                  defaultMessage="Close"
                                />
                              )
                          )
                        }
                      </SecondaryInformation>
                    </Stack>
                  </Button>
                </div>
              )
              : <span/>
          }
          <ViewportHeading
            css={ {
              textAlign: 'center',
              whiteSpace: 'nowrap'
            } }
          >{ sensitiveTitle || title }</ViewportHeading>
          <Stack>
            { contextMenuProps && (
              <StackEnd>
                <ContextMenu { ...contextMenuProps } />
              </StackEnd>
            ) }
          </Stack>
        </div>
      ) }
    </Toolbar>
  );
};
