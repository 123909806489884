import styled from '@emotion/styled';
import { Button } from '@mui/material';

export const StyledFormCreateMenu = styled.div`
  padding: ${ props => props.theme.spacing(1) } 0;
  width: 300px;

  h4 {
    margin: 0;
    font-weight: 500;
    padding: 0 ${ props => props.theme.spacing(1) };
  }
`;

export const CreateContentButton = styled(Button)`
  font-size: 1.1rem;
  font-weight: 400;
  padding: ${ props => `${ props.theme.spacing(1.1) } ${ props.theme.spacing(2) }` };
  width: 100%;
  justify-content: flex-start;
`;
CreateContentButton.defaultProps = { color: 'inherit' };
