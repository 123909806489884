import styled from '@emotion/styled';

export const StyledCircularPercentage = styled.div`
  position: relative;

  .MuiCircularProgress-root {
    display: block;
    width: auto !important;
    height: auto !important;

    &:first-of-type {
      opacity: 0.25;
    }

    &:last-of-type {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
`;
