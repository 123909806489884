import { forwardRef, MouseEventHandler, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import * as H from 'history';

import { DeprecatedLink, LinkProps } from '../../../Common/Component';
import { usePermissions } from '../../Hook';
import { useContextOrThrow } from '../../../Core/Hook';
import { LoginStateContext } from '../../../Core/Provider/LoginStateProvider';
import {ELEVATE_PATH} from "../../../Components";

interface Props<S = H.LocationState> extends LinkProps<S> {
  permissions?: string[];
}

export const GuardedLink = forwardRef<HTMLAnchorElement, Props>(({
  permissions = [],
  children,
  onClick,
  ...props
}, ref) => {
  const history = useHistory();
  const { setLoginTargetUrl } = useContextOrThrow(LoginStateContext);
  const { permissionsAvailable, permissionRestricted, permissionsGranted } = usePermissions();

  const whenLinkClicked = useCallback<MouseEventHandler<HTMLAnchorElement>>(
    (event) => {
      const available = permissions.length < 1 || permissionsAvailable(permissions);
      const restricted = available && !permissionsGranted(permissions) && !!permissions.find(permissionRestricted);
      const pathName = typeof props.to === 'string'
        ? props.to
        : typeof props.to === 'object'
          ? props.to.pathname || ''
          : '';

      if (!available) {
        event.preventDefault();
      }

      if (restricted) {
        event.preventDefault();
        setLoginTargetUrl(pathName);
        console.debug('Link redirect to /login/elevate due to missing permission.', {permissions});
        history.push(ELEVATE_PATH);
      } else {
        if (onClick) {
          onClick(event);
        }
      }
    },
    [
      permissions,
      props,
      history,
      onClick,
      permissionsGranted,
      permissionRestricted,
      permissionsAvailable,
      setLoginTargetUrl,
    ],
  );

  return (
    <DeprecatedLink {...props} onClick={whenLinkClicked} ref={ref}>
      { children }
    </DeprecatedLink>
  )
});
