import { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CircularProgress } from '@mui/material';

import { StyledImageContainer, StyledTitleAndProgress, StyledUpload } from './style';
import { UploadState } from '../../../../../Common/Context';
import { Pager } from '../../../Pager/Pager';
import { IconButton, LocalisedFileUploadErrors, PresentationIcon } from '../../../../../Common/Component';
import { useApi } from '../../../../../Core/Hook';
import CrossIcon from '../../../../../Assets/img/icons/monochrome/close.svg';
import CompleteIcon from '../../../../../Assets/img/icons/monochrome/success.svg';
import { Flex } from '../../../../../Common/Component';
import { UploadUrls } from '../../../../../Common/Hook';

type Props = {
  uploadState: UploadState
  onRemoveClicked: () => void;
  saved?: boolean;
};

export const Upload: FC<Props> = ({
  uploadState,
  onRemoveClicked,
  saved = false,
}) => {
  const api = useApi();
  const intl = useIntl();
  const [error, setError] = useState<boolean>(false);
  const [src, setSrc] = useState<string>();

  useEffect(() => {
    if (!uploadState.upload?.id) {
      return;
    }

    let cancelled = false;

    api.get<UploadUrls>(`/uploads/${ uploadState.upload.id }/urls`)
      .then(response => response.data)
      .then(uploadUrls => {
        if (cancelled) {
          return;
        }

        setSrc(uploadUrls.original)
      })
      .catch(() => {
        if (cancelled) {
          return;
        }

        setError(true);
      });
    return () => {
      cancelled = true;
    };
  }, [api, uploadState.upload?.id]);

  return (
    <StyledUpload>
      <Flex gap={ 2 } noWrap>
        <StyledImageContainer>
          {
            src
              ? (
                <img
                  src={ src }
                  alt={ uploadState.file.name }
                />
              )
              : !error && <CircularProgress variant="indeterminate"/>
          }
        </StyledImageContainer>
        <StyledTitleAndProgress>
          <Flex gap={ 2 }>
            { uploadState.upload && <PresentationIcon color="secondary.main" IconComponent={ CompleteIcon }/> }
            {
              saved
                ? (
                  <FormattedMessage
                    id="content.upload.submitted"
                    description="Message when upload card upload has been submitted."
                    defaultMessage="Saved"
                  />
                )
                : uploadState.upload
                  ? (
                    <FormattedMessage
                      id="content.upload.complete"
                      description="Message when upload card upload is complete."
                      defaultMessage="Upload complete"
                    />
                  )
                  : (
                    <FormattedMessage
                      id="content.upload.progress"
                      description="Message indicating upload card upload progress."
                      defaultMessage="{ progress }% uploaded…"
                      values={ {
                        progress: Math.floor(uploadState.progress * 100),
                      } }
                    />
                  )
            }
          </Flex>
          { !uploadState.upload && <Pager progress={ uploadState.progress }/> }
        </StyledTitleAndProgress>
        { !saved && (
          <IconButton
            color="primary.main"
            IconComponent={ CrossIcon }
            onClick={ onRemoveClicked }
            label={ intl.formatMessage({
              id: 'content.upload.remove',
              description: 'Label for image upload remove button visible to screen-readers.',
              defaultMessage: 'Remove upload',
            }) }
            size="small"
          />
        ) }
      </Flex>
      { uploadState.error && <LocalisedFileUploadErrors errors={ [uploadState.error] }/> }
    </StyledUpload>
  );
};
