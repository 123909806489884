import styled from '@emotion/styled';

import { Flex } from '../../../Common/Component';

export const StyledPeriodInput = styled.div`
  display: flex;
  flex-direction: column;

  ${Flex} {
    > *:not(:first-of-type) {
      margin-left: 1rem;
    }
  }

  >*:first-of-type {
    margin-bottom: 0.5rem;
  }
`;
