import { ChangeEvent, FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { MenuItem, OutlinedInput, Select } from '@mui/material';

import { TextFormatter } from '../../../Utility';
import { TagType } from '../../Model';
import { TagParser } from '../../Utility';

const defaultAvailableTypes: TagType[] = [
  'region',
  'team',
  'department',
  'jobtitle',
  'skill',
];

interface Props {
  availableTypes?: TagType[];
  tagType: TagType;
  onChange: (tagType: TagType) => void;
  readonly?: boolean;
}

export const TagTypeSelect: FunctionComponent<Props> = ({
  availableTypes = defaultAvailableTypes,
  tagType,
  onChange,
  readonly = false,
}) => {
  const intl = useIntl();

  const whenChanged = (event: ChangeEvent<{ value: unknown }>) => {
    onChange(event.target.value as TagType);
  };

  return (
    <Select
      input={(
        <OutlinedInput
          margin="dense"
        />
      )}
      variant="outlined"
      value={tagType}
      onChange={whenChanged}
      disabled={ readonly }
      { ...(readonly ? { IconComponent: () => null } : {}) }
    >
      {
        availableTypes.map((availableType) => (
          <MenuItem
            key={availableType}
            value={availableType}
          >
            {
              TextFormatter.capitalise(
                TagParser.localisedTagTypeFromInternalTagType(availableType, intl, 1)
              )
            }
          </MenuItem>
        ))
      }
    </Select>
  );
}
