import { CSSProperties, FC, ReactNode } from 'react';

import { StyledLineClamp } from './style';

type Props = {
  lines?: number;
  minWidth?: number;
  wordBreak?: CSSProperties['wordBreak'];
  children?: ReactNode;
};

export const LineClamp: FC<Props> = ({
  lines = 2,
  minWidth,
  wordBreak,
  children,
}) => (
  <StyledLineClamp
    lines={ lines }
    minWidth={ minWidth }
    wordBreak={ wordBreak }
  >
    { children }
  </StyledLineClamp>
);
