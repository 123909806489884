import { MenuItem, Select } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { ChangeEventHandler, FunctionComponent } from 'react';

import { Flex } from '../../../Common/Component';
import { StyledPeriodInput } from './style';
import { ArrayHelper } from '../../../Common/Utility';

export type ValidPeriod = 7 | 14 | 28;

interface Props {
  period?: ValidPeriod;
  onChange: (period: ValidPeriod) => void;
}

export const PeriodInput: FunctionComponent<Props> = ({
  period = 7,
  onChange,
}) => {
  const whenPeriodChanged: ChangeEventHandler<{ value: unknown }> = (event) => {
    if (typeof event.target.value === 'number' && periodIsValid(event.target.value)) {
      onChange(event.target.value);
    }
  };

  return (
    <StyledPeriodInput>
      <Flex>
        <Select
          variant="outlined"
          margin="dense"
          value={ period }
          onChange={ whenPeriodChanged }
        >
          <MenuItem value={ 7 }>
            <FormattedMessage
              id="input.period.offset7"
              defaultMessage="Last 7 days"
            />
          </MenuItem>
          <MenuItem value={ 14 }>
            <FormattedMessage
              id="input.period.offset14"
              defaultMessage="Last 14 days"
            />
          </MenuItem>
          <MenuItem value={ 28 }>
            <FormattedMessage
              id="input.period.offset28"
              defaultMessage="Last 28 days"
            />
          </MenuItem>
        </Select>
      </Flex>
    </StyledPeriodInput>
  );
};

export const periodIsValid = ArrayHelper.createTypeGuard<ValidPeriod>([
  7,
  14,
  28,
]);
