import { isValidNumberForRegion } from 'libphonenumber-js';

import { Username } from '../../../stories/components/UsernameField/UsernameField';
import { ValidationError } from '../../model/Validation/ValidationError';

export class UsernameValidator {
  public static validate = (username: Username): ValidationError[] => {
    try {
      if (username.selectedType !== 'email' && !isValidNumberForRegion(username.value, username.parsedPhone?.determinedCountryCode)) {
        return [{
          type: 'invalidPhoneNumberForRegion',
          metadata: {
            countryCode: username.parsedPhone?.determinedCountryCode,
          },
        }];
      }
    } catch {
      return [{
        type: 'invalidPhoneNumber',
        metadata: {},
      }];
    }

    return [];
  };
}
