import styled from '@emotion/styled';
import * as RadTabs from '@radix-ui/react-tabs';

import { TabContext } from './TabBar';

export const StyledTabList = styled(RadTabs.List)<{ context: TabContext; fitContent: boolean }>`
  ${ props => props.context === 'page' && `
    border-bottom: ${ props.theme.new.border.light };
  ` }
  
  ${ props => props.fitContent && `
    width: max-content;
  ` }

  ${ props => props.context === 'section' && `
    padding: ${ props.theme.new.spacing[1] };
    border: ${ props.theme.new.border.standard };
    border-radius: ${ props.theme.new.borderRadius.standard };
    background-color: ${ props.theme.new.palette.grey[50].main };
  ` }
`;

export const StyledTabWrapper = styled.div<{ context: TabContext, spaceEvenly: boolean }>`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  z-index: 0;
    
  ${ props => props.spaceEvenly && `
    >* {
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: 10%;
    }
  ` }

  ${ props => props.context === 'page' && `
    gap: ${ props.theme.new.spacing[4] };
  ` }

  ${ props => props.context === 'section' && `
    gap: ${ props.theme.new.spacing[2] };
  ` }
`;

export const StyledActiveTabIndicator = styled.div<{ context: TabContext }>`
  position: absolute;
  height: 100%;
  width: 200px;
  z-index: -1;
  transition:
    width 0.3s ${ props => props.theme.new.animationEasing.elastic },
    transform 0.3s ${ props => props.theme.new.animationEasing.elastic };

  ${ props => props.context === 'page' && `
    border-bottom: 2px solid ${ props.theme.new.palette.primary[700].main };
  ` }

  ${ props => props.context === 'section' && `
    box-shadow: ${ props.theme.new.shadow.sm };
    border-radius: ${ props.theme.new.borderRadius.small };
    background-color: ${ props.theme.new.basePalette.white.main };
  ` }
`;
