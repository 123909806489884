import { FunctionComponent, ReactNode } from 'react';
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';

import { GlobalStyle } from './GlobalStyle';
import { getTheme, MuiTheme } from '../../Themes';

type ThemeProviderProps = {
  insertionPoint?: HTMLElement
  disablePortal?: boolean;
  children?: ReactNode;
};

export const ThemeProvider: FunctionComponent<ThemeProviderProps> = (
  { children, disablePortal }
) => {
  const theme: MuiTheme = getTheme({ disablePortal });
  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={ theme }>
        <EmotionThemeProvider theme={ theme }>
          <GlobalStyle
            baseElement={ disablePortal ? ':host' : 'body' }
          />
          { children }
        </EmotionThemeProvider>
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
};
