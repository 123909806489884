import { Dispatch, FC, PropsWithChildren, SetStateAction, useCallback, useMemo, useState } from 'react';
import { ContextCreator } from '@ourpeople/shared/Core/Utility/ContextCreator';

interface LoginStateContextValue {
  loginTargetUrl: string;
  setLoginTargetUrl: Dispatch<SetStateAction<string>>;
  setLoginTarget: (path: string, query?: URLSearchParams) => void;
  loginOriginUrl: string;
  setLoginOriginUrl: Dispatch<SetStateAction<string>>;
  loggedIn: boolean | null;
  setLoggedIn: Dispatch<SetStateAction<boolean | null>>;
}

export const LoginStateContext = ContextCreator.withDisplayName<LoginStateContextValue>(
  'LoginStateContext',
  null,
);

export const LoginStateProvider: FC<PropsWithChildren> = ({ children }) => {
  const [loginOriginUrl, setLoginOriginUrl] = useState<string>('/');
  const [loginTargetUrl, setLoginTargetUrl] = useState<string>('/');
  const [loggedIn, setLoggedIn] = useState<boolean | null >(null);

  const whenLoggedInChanged: Dispatch<SetStateAction<boolean | null>> = useCallback(action => (
    setLoggedIn(
      typeof action === 'function'
        ? loggedIn => action(loggedIn)
        : action
    )
  ), [setLoggedIn]);

  const setLoginTarget = useCallback((path: string, query?: URLSearchParams) => {
    setLoginTargetUrl(`${path}${query?.size ? `?${query.toString()}` : ''}`);
  }, [setLoginTargetUrl]);

  const contextValue = useMemo<LoginStateContextValue>(() => ({
    loginTargetUrl,
    setLoginTargetUrl,
    setLoginTarget,
    loggedIn,
    setLoggedIn: whenLoggedInChanged,
    loginOriginUrl,
    setLoginOriginUrl,
  }), [loggedIn, loginTargetUrl, whenLoggedInChanged, loginOriginUrl, setLoginOriginUrl, setLoginTarget]);

  return (
    <LoginStateContext.Provider value={ contextValue }>
      { children }
    </LoginStateContext.Provider>
  );
};
