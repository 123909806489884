import { parseISO } from 'date-fns';

import { ValidationTree, Validator } from '../../../../Common/Model';
import { Validate } from '../../../../Common/Utility';
import { EditorContent } from '../../../../Content/Model';
import { DraftBroadcast } from '../../../Model';

type EditorContentValidator = <T extends EditorContent>(editorContent: T) => ValidationTree<T['card']>

export class BroadcastValidator {
  public static validate = (broadcast: DraftBroadcast, editorContents: EditorContent[], validateContent: EditorContentValidator): ValidationTree<DraftBroadcast> => ({
    errors: [],
    children: {
      name: BroadcastValidator.validateTitle(broadcast.name),
      content: {
        errors: contentValidator.validate(editorContents),
        children: editorContents.map(editorContent => validateContent(editorContent).children.content),
      },
      ...(broadcast.scheduleFor ? { scheduleFor: BroadcastValidator.validateScheduleFor(parseISO(broadcast.scheduleFor)) } : {}),
    },
  });

  public static validateTitle = (title: string): ValidationTree<string> => ({
    errors: titleValidator.validate(title),
    children: {},
  });

  public static validateScheduleFor = (scheduleFor: Date): ValidationTree<string> => ({
    errors: scheduleForValidator.validate(scheduleFor),
    children: {},
  })
}

export const BROADCAST_TITLE_MAX_LENGTH = 170;
export const BROADCAST_CONTENT_MAX_COUNT = 30;

const titleValidator = new Validator<string>([
  Validate.notBlank,
  Validate.utf8,
  title => Validate.maxLength(title, BROADCAST_TITLE_MAX_LENGTH),
]);

const contentValidator = new Validator<EditorContent[]>([
  content => Validate.countGreaterThanEq(content, 1),
  content => Validate.countLessThanEq(content, BROADCAST_CONTENT_MAX_COUNT),
]);

const scheduleForValidator = new Validator<Date>([
  scheduleFor => Validate.dateAfter(scheduleFor, new Date()),
]);
