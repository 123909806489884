import { IntlShape } from 'react-intl';
import {
  BlankValidationErrorDefinition
} from 'op-storybook/lib/model/Validation/Definition/BlankValidationErrorDefinition';
import StorageBlockIcon from 'op-storybook/lib/assets/icon/figma/file-attachment-05.svg';

import { BlockErrorDefinitions, BroadcastBlockDefinition } from '../../Model/BroadcastBlockDefinition';
import { BroadcastBlock } from '../../Model/Broadcast';
import { UniqueIdGenerator } from '../../../../Common/Utility';
import { StorageNodeBlockInlineForm } from '../../Component/Blocks/StorageNodeBlock/StorageNodeBlockInlineForm';
import { StorageNodeBlockForm } from '../../Component/Blocks/StorageNodeBlock/StorageNodeBlockForm';
import {
  StorageNodeBlockExpandedPreview
} from '../../Component/Blocks/StorageNodeBlock/StorageNodeBlockExpandedPreview';

export type StorageNodeBlock = {
  localId: string;
  kind: 'storageNode';
  attributes: {
    nodeId: string;
  };
};

export class StorageNodeBlockDefinition implements BroadcastBlockDefinition<StorageNodeBlock> {
  public readonly prioritise = false;
  public readonly kind = 'storageNode';
  public readonly localisedKind: string;
  public readonly errorDefinitions: BlockErrorDefinitions<StorageNodeBlock>;

  constructor(intl: IntlShape) {
    this.localisedKind = intl.formatMessage({
      description: 'Name for file block used in broadcast editor',
      defaultMessage: 'File',
    });

    this.errorDefinitions = {
      nodeId: [
        new BlankValidationErrorDefinition(intl => intl.formatMessage({
          description: 'Error message when broadcast file block file ID is left blank.',
          defaultMessage: 'No file selected.',
        })),
      ],
    };
  }

  public definesBlock(block: BroadcastBlock): block is StorageNodeBlock {
    return block.kind === 'storageNode';
  }

  public initialiseBlock(): StorageNodeBlock {
    return {
      localId: UniqueIdGenerator.generate(),
      kind: 'storageNode',
      attributes: {
        nodeId: '',
      },
    };
  }

  public IconComponent = StorageBlockIcon;
  public ExpandedPreviewComponent = StorageNodeBlockExpandedPreview;
  public InlineFormComponent = StorageNodeBlockInlineForm;
  public FormComponent = StorageNodeBlockForm;
}
