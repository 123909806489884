import { FC, forwardRef, useState } from 'react';

import { useSsoProviderDetails } from '../../hooks/useSsoProviderDetails';
import { SsoProvider } from '../../model/SsoProvider';
import { SsoButton } from '../SsoButton/SsoButton';
import { DropdownItem } from '../../../stories/components/DropdownItem/DropdownItem';
import { DropdownItemChevron } from '../DropdownItemChevron/DropdownItemChevron';
import { ToggledPopover } from '../../../stories/components/content/ToggledPopover';

type Props = {
  issuerProviders: SsoProvider[];
};

export const SsoIssuerButton: FC<Props> = ({
  issuerProviders,
}) => {
  const { lookupProviderDetails } = useSsoProviderDetails();
  const issuerDetails = lookupProviderDetails(issuerProviders[0])?.issuer;
  const [navigating, setNavigating] = useState<boolean>(false);

  if (issuerProviders.length < 1) {
    return <></>;
  }

  return issuerProviders.length === 1
    ? (
      <SsoButton
        key={ issuerDetails.issuer }
        onClick={ () => {
          setNavigating(true);
        } }
        disabled={ navigating }
        component="a"
        href={ issuerProviders[0].url }
        rel="noreferrer"
        name={ issuerDetails.name }
        Logo={ issuerDetails.logo }
        colour={ issuerDetails.colour }
      />
    )
    : (
      <ToggledPopover
        ToggleComponent={ forwardRef((props, ref) => (
          <SsoButton
            ref={ ref }
            key={ issuerDetails.issuer }
            name={ issuerDetails.name }
            Logo={ issuerDetails.logo }
            colour={ issuerDetails.colour }
            component="button"
            { ...props }
          />
        )) }
        ContentComponent={ forwardRef((props, ref) => (
          <menu
            ref={ ref }
            { ...props }
            css={ theme => ({
              boxShadow: theme.new.shadow.md,
              border: theme.new.border.standard,
              borderRadius: theme.new.borderRadius.standard,
              listStyle: 'none',
            }) }
          >
            { issuerProviders.map(provider => {
              const details = lookupProviderDetails(provider);
              return (
                <li
                  key={ provider.issuer }
                  css={ {
                    position: 'relative',
                    margin: 0,
                    padding: 0,
                  } }
                >
                  <a
                    data-disabled={ navigating }
                    href={ provider.url }
                    onClick={ () => {
                      setNavigating(true);
                    } }
                  >
                    <DropdownItem
                      text={ details.name }
                      endAdornment={ <DropdownItemChevron/> }
                    />
                  </a>
                </li>
              );
            }) }
          </menu>
        )) }
      />
    );
};
