import { format, parseISO } from 'date-fns';

import { DraftEvent, Event } from '../Model';

export class EventParser {
  public static title = (event: DraftEvent | Event): string => {
    const title = event.text || '';
    const location = event.location || '';
    const date = `${ format(parseISO(event.start_at.dt), 'EEE d MMM, yyyy, HH:mm') }-${ format(parseISO(event.end_at.dt), 'HH:mm') }`
    return [title, location, date].filter(titlePart => !!titlePart).join(', ');
  };
}
