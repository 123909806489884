import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button, ListItem, ListItemText } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { Heading } from '@ourpeople/shared/Core/Component/Content';
import { AutocompleteSelectionChanged } from '@ourpeople/shared/Core/Component/Input/Autocomplete/Autocomplete';

import { TextFormatter } from '../../../Utility';
import { LoadingSpinner } from '../../../Components';
import { useEnvironmentSettings } from '../../../Common/Hook';
import { Person, RequestState } from '../../../Models';
import { ApiContext } from '../../../Contexts';
import { Picker } from '../../../Components/Input/Picker/Picker';
import { SharedFormFields, StyledTagForm } from '..';
import { Tag, TagType } from '../../Model';
import { Flex, FlexPullRight } from '../../../Common/Component';
import { TagParser } from '../../Utility';
import { useContextOrThrow } from '../../../Core/Hook';
import { ToastContext } from '../../../Core/Context';

interface Props {
  type: TagType;
  onSaved: (tag: Tag<TagType>) => void;
  onTypeChanged: (type: TagType) => void;
}

export const CreateForm = ({
  type,
  onSaved,
  onTypeChanged,
}: Props): JSX.Element => {
  const intl = useIntl();
  const api = useContext(ApiContext);
  const { nonTeamTagsEnabled } = useEnvironmentSettings();
  const tagTypes: TagType[] = useMemo(() => {
    const allTypes: TagType[] = ['region', 'team', 'department', 'jobtitle', 'skill'];

    return nonTeamTagsEnabled === true
      ? allTypes
      : ['team'];
  }, [nonTeamTagsEnabled]);
  const defaultTag: Tag<typeof type> = useMemo(() => ({
    id: '',
    name: '',
    externallyManaged: false,
    type,
    parentTagIds: [],
    childTagIds: [],
    administratorIds: [],
    createdAt: '',
  }), [type]);
  const [tagToSave, setTagToSave] = useState<Tag<TagType>>(defaultTag);
  const parentType = useMemo(() => TagParser.determineParentType(tagToSave.type), [tagToSave.type]);
  const childType = useMemo(() => TagParser.determineChildType(tagToSave.type), [tagToSave.type]);
  const [saveState, setSaveState] = useState<RequestState>(RequestState.PENDING);
  const { addErrorToast } = useContextOrThrow(ToastContext);

  const getLocalisedTagType = (type: TagType): string => {
    return TextFormatter.capitalise(TagParser.localisedTagTypeFromInternalTagType(type, intl));
  };

  const whenTagNameChanged = useCallback((name: string): void => {
    setTagToSave((prev) => ({
      ...prev,
      name,
    }));
  }, [setTagToSave]);

  const whenChildTagSelectionChanged: AutocompleteSelectionChanged<Tag<TagType>> = (selection) => {
    const childTagIds = [
      ...selection.unknownIds,
      ...selection.options.map((tag) => tag.id),
    ];
    setTagToSave((prev) => ({
      ...prev,
      childTagIds,
    }));
  };

  const whenParentTagSelectionChanged: AutocompleteSelectionChanged<Tag<TagType>> = (selection) => {
    const parentTagIds = [
      ...selection.unknownIds,
      ...selection.options.map((tag) => tag.id),
    ];
    setTagToSave((prev) => ({
      ...prev,
      parentTagIds,
    }));
  };

  const whenTypeChanged = useCallback((type: TagType) => {
    setTagToSave((prev) => (
      type === 'team'
        ? {
          ...prev,
          type,
          administratorIds: [],
        }
        : {
          ...prev,
          type,
        }
    ));
    onTypeChanged(type);
  }, [onTypeChanged, setTagToSave]);

  const valid = useMemo(() => {
    return !!tagToSave.name;
  }, [tagToSave.name]);

  const save = async () => {
    if (api) {
      setSaveState(RequestState.FETCHING);
      try {
        const savedTag = (await api.post<Tag<TagType>>('/tags', tagToSave)).data;
        onSaved(savedTag);
        setSaveState(RequestState.COMPLETE);
      } catch (error) {
        addErrorToast(
          intl.formatMessage({
            id: 'tag.create.saveFailedMessage',
            defaultMessage: 'Failed to create "{tagName}".'
          }, {
            tagName: tagToSave.name,
          })
        );
        setSaveState(RequestState.FAILED);
      }
    }
  };

  const whenAdminSelectionChanged: AutocompleteSelectionChanged<Person> = (selection) => {
    setTagToSave((prev) => {
      const administratorIds = [
        ...selection.options.map(administrator => administrator.id),
        ...selection.unknownIds,
      ];
      return TagParser.tagIsTeamTag(prev)
        ? {
          ...prev,
          administratorIds,
        }
        : prev;
    });
  };

  useEffect(() => {
    setTagToSave((prev) => {
      if (prev.type !== 'team' && !!(prev as unknown as Tag<'team'>).administratorIds) {
        const { administratorIds, ...tag } = prev as unknown as Tag<'team'>;
        return {
          ...tag as Tag<TagType>,
        };
      }
      return prev;
    });
  }, [tagToSave.type]);

  return (
    <div>
      <StyledTagForm>
        <Heading type="h4">
          <FormattedMessage
            id="tags.create.typeHeading"
            defaultMessage="Tag category"
          />
        </Heading>
        <Picker
          buttonLabel={getLocalisedTagType(tagToSave.type)}
          items={tagTypes}
          selectedItems={[tagToSave.type]}
          itemRenderFn={(type, selected) => (
            <ListItem
              key={type}
              onClick={() => whenTypeChanged(type)}
              selected={selected}>
              <ListItemText>
                {getLocalisedTagType(type)}
              </ListItemText>
            </ListItem>
          )}
          itemCompareFn={(typeA, typeB) => typeA === typeB}
          allowEmptySelection={false}
          closeOnSelect
        />
        <SharedFormFields
          tagToSave={tagToSave}
          onNameChanged={whenTagNameChanged}
          parentType={parentType}
          childType={childType}
          onParentTagSelectionChanged={useCallback(whenParentTagSelectionChanged, [setTagToSave])}
          onChildTagSelectionChanged={useCallback(whenChildTagSelectionChanged, [setTagToSave])}
          onAdminSelectionChanged={useCallback(whenAdminSelectionChanged, [setTagToSave])}
        />
      </StyledTagForm>
      <Flex>
        <FlexPullRight>
          {
            saveState === RequestState.FETCHING
              ? (
                <>
                  <FormattedMessage
                    id="tags.create.saving"
                    defaultMessage="Saving"
                  />
                  <LoadingSpinner/>
                </>
              )
              : (
                <Button
                  color="primary"
                  variant="contained"
                  disableElevation
                  disabled={!valid}
                  onClick={ () => { void save() } }
                >

                  <FormattedMessage
                    id="tags.create.saveCta"
                    defaultMessage="Save"
                  />
                </Button>
              )
          }
        </FlexPullRight>
      </Flex>
    </div>
  )
}
