import { FunctionComponent, useMemo } from 'react';

import { StyledCharacterCount } from './style';

interface Props {
  minimum?: number;
  maximum: number;
  current: number;
  allowBlank?: boolean;
}

export const CharacterCount: FunctionComponent<Props> = ({
  minimum = 0,
  maximum,
  current,
  allowBlank = false,
}) => {
  const characterCount = useMemo<number>(
    () => {
      return current <= maximum
        ? current
        : maximum - current;
    },
    [current, maximum],
  );

  return (
    <StyledCharacterCount
      error={ current > maximum || (allowBlank ? current < minimum && current !== 0 : current < minimum ) }
    >
      { characterCount }/{ maximum }
    </StyledCharacterCount>
  );
};
