import {ValidationErrorDefinitionRegistry} from '../model/Validation/Definition/ValidationErrorDefinitionRegistry';
import {ValidationError} from '../model/Validation/ValidationError';
import {useValidationErrors} from './useValidationErrors';
import {ValidationErrorDefinitionFactory} from '../factory/ValidationErrorDefinitionFactory';

interface InvalidPhoneNumberForRegionError extends ValidationError {
  type: 'invalidPhoneNumberForRegion',
  metadata: {
    countryCode: string;
  };
}

interface InvalidPhoneNumberError extends ValidationError {
    type: 'invalidPhoneNumber',
    metadata: {};
}

export const useUsernameValidationErrors = () => {
  return useValidationErrors(
    new ValidationErrorDefinitionRegistry([
      ValidationErrorDefinitionFactory.build<InvalidPhoneNumberForRegionError>(
        'invalidPhoneNumberForRegion',
        (intl, error) => {
          const displayNames = new Intl.DisplayNames(intl.locale, { type: 'region' });
          return intl.formatMessage(
            {
              description: 'Validation error message when phone number does not match region.',
              defaultMessage: 'Phone number is not a valid { region } phone number',
            },
            {
              region: displayNames.of(error.metadata.countryCode) || error.metadata.countryCode,
            }
          );
        },
      ),
      ValidationErrorDefinitionFactory.build<InvalidPhoneNumberError>(
        'invalidPhoneNumber',
        (intl, error) => {
          return intl.formatMessage(
            {
              description: 'Validation error message when phone number is not valid.',
              defaultMessage: 'Phone number is not valid',
            },
          );
        },
      ),
    ])
  );
}
