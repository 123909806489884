import { default as React, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import { CenteredGenericErrorMessage, CenteredGenericLoadingMessage } from '@ourpeople/shared/Core/Component/Content';

import { useMounted } from '../../../../Common/Hook';
import { useApi, useContextOrThrow } from '../../../../Core/Hook';
import { DraftTopic, Topic } from '../../../Model';
import { Padded } from '../../../../Components';
import {
  Box,
  PageHeader,
  LoadingContainer
} from '../../../../Common/Component';
import { ChatAudienceMemberList, TopicAudienceComparisonDialog, TopicEditForm } from '../../../Component';
import { Audience } from '../../../../Audiences/Model';
import { ErrorResponseReader } from '../../../../Common/Utility';
import { ValidationTree } from '../../../../Common/Model';
import { AudienceCleaner } from '../../../../Audiences/Utility';
import { ToastContext } from '../../../../Core/Context';
import { useManageTopicsTitle } from '../../../Hook/useManageTopicsTitle';

export const TopicEditPage: FC = () => {
  const intl = useIntl();
  const { addSuccessToast, addErrorToast } = useContextOrThrow(ToastContext);
  const api = useApi();
  const mounted = useMounted();
  const [fetching, setFetching] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const { topicId } = useParams<{ topicId: string }>();
  const [topic, setTopic] = useState<DraftTopic>();
  const [apiValidation, setApiValidation] = useState<ValidationTree<DraftTopic>>();
  const [fetchError, setFetchError] = useState<boolean>(false);
  const [fetchIndex, setFetchIndex] = useState<number>(0);
  const [savingTopic, setSavingTopic] = useState<DraftTopic>();
  const cleanAudience = useMemo(() => topic && AudienceCleaner.cleanAudience(topic.audience), [topic]);
  const cleanSavingAudience = useMemo(() => savingTopic && AudienceCleaner.cleanAudience(savingTopic.audience), [savingTopic]);
  const manageTopicsTitle = useManageTopicsTitle();

  useEffect(() => {
    setFetching(true);
    api.get<{topic: Topic}>('/chats/topics/' + topicId)
      .then(response => {
        const topic = response.data.topic;

        if (!topic) {
          return;
        }

        const { id, audience, description, ...draftTopic } = topic;
        return api.get<Audience>(`/audiences/${ audience.id }`)
          .then(response => {
            if (!mounted.current) {
              return;
            }

            setTopic({
              ...draftTopic,
              description: description || '',
              audience: response.data,
            });
            setFetching(false);
          });
      })
      .catch(() => {
        if (!mounted.current) {
          return;
        }

        setFetchError(true);
        setFetching(false);
      });
  }, [api, mounted, topicId, fetchIndex]);

  const save = useCallback(({ description, ...topic }: DraftTopic) => {
    setSaving(true);
    setApiValidation(undefined);
    api.post<{ topic: Topic }>(`/chats/topics/${ topicId }`, {
      ...topic,
      ...(description ? { description } : {}),
      audience: AudienceCleaner.cleanAudience(topic.audience),
    })
      .then(response => {
        if (!mounted.current) {
          return;
        }

        setTopic({ description, ...topic });
        addSuccessToast(
          intl.formatMessage({
            id: 'topics.edit.success',
            description: 'Toast message displayed when topic is saved successfully.',
            defaultMessage: '{ name } saved successfully.'
          }, { name: response.data.topic.name })
        );
        setSaving(false);
      })
      .catch((error: AxiosError<unknown>) => {
        if (!mounted.current) {
          return;
        }

        ErrorResponseReader.isApiError(error) && ErrorResponseReader.isValidationErrorResponse(error.response) && setApiValidation(error.response.data.error.data.root);

        addErrorToast(
          intl.formatMessage({
            id: 'topics.edit.error',
            description: 'Toast message displayed when topic can not be saved.',
            defaultMessage: '{ name } could not be saved.'
          }, { name: topic.name })
        );
        setSaving(false);
      });
  }, [addErrorToast, addSuccessToast, api, intl, mounted, topicId]);

  const renderAudienceMembers = useCallback((audience: Audience) => (
    <ChatAudienceMemberList
      chatId={ topicId }
      audience={ audience }
    />
  ), [topicId]);

  const whenSubmitted = useCallback((topic: DraftTopic) => {
    const audienceChanged = JSON.stringify(cleanAudience) !== JSON.stringify(AudienceCleaner.cleanAudience(topic.audience));

    if (audienceChanged) {
      setSavingTopic(topic);
      return;
    }

    save(topic);
  }, [cleanAudience, save]);

  return (
    <>
      <Padded>
        <PageHeader
          items={ [
            {
              link: '/chats/topics',
              title: manageTopicsTitle,
            },
            {
              link: `/chats/topics/${ topicId }`,
              title: topic?.name || '',
            },
            {
              link: `/chats/topics/${ topicId }/edit`,
              title: intl.formatMessage({
                id: 'section.chat.topics.edit',
                description: 'Heading label for topics edit page',
                defaultMessage: 'Edit',
              }),
            },
          ] }
        >
        </PageHeader>
        <Box>
          {
            fetching
              ? <LoadingContainer><CenteredGenericLoadingMessage/></LoadingContainer>
              : topic
                ? (
                  <TopicEditForm
                    initialState={ topic }
                    onSubmit={ whenSubmitted }
                    submitting={ saving }
                    renderAudienceMembers={ renderAudienceMembers }
                    validation={ apiValidation }
                  />
                )
                : fetchError && (
                <CenteredGenericErrorMessage
                  onRetryClicked={ () => setFetchIndex(fetchIndex => fetchIndex + 1) }
                />
              )
          }
        </Box>
      </Padded>
      { cleanAudience && cleanSavingAudience && savingTopic && (
        <TopicAudienceComparisonDialog
          previousAudience={ cleanAudience }
          audience={ cleanSavingAudience }
          onConfirm={ () => {
            setSavingTopic(undefined);
            save(savingTopic);
          } }
          onCancel={ () => setSavingTopic(undefined) }
          autoJoin={ savingTopic.autoJoin }
        />
      ) }
    </>
  );
};
