import { format, isBefore, isToday, parseISO, subYears } from 'date-fns';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

interface DateFormatter {
  minimalDateTime: (isoString: string) => string;
  minimalDate: (isoString: string) => string;
  uniformTime: (isoString: string) => string;
  uniformDateTime: (isoString: string) => string;
  inputDate: (isoString: string) => string;
  inputTime: (isoString: string) => string;
  inputDateTime: (isoString: string) => string;
}

export const useDateTimeFormatter = (): DateFormatter => {
  const intl = useIntl();

  /** Prefer in cases where all date parts are relevant */
  const uniformDateTime = useCallback((isoString: string) => (
    intl.formatDate(
      parseISO(isoString),
      {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      },
    )
  ), [intl]);

  /** Prefer in cases where precise time is not relevant */
  const minimalDate = useCallback((isoString: string) => (
    intl.formatDate(
      parseISO(isoString),
      {
        ...(yearIsRelevant(isoString) ? {
            year: '2-digit',
            month: 'numeric',
          } : {
            month: 'short',
          }),
        day: 'numeric',
      },
    )
  ), [intl]);

  /** Prefer in cases where only time is required as date context is provided elsewhere */
  const uniformTime = useCallback((isoString: string) => (
    intl.formatDate(
      parseISO(isoString),
      {
        hour: 'numeric',
        minute: 'numeric',
      },
    )
  ), [intl]);

  /** Displays only the most relevant parts of the datetime relative to the current date.
   * Prefer in cases where all dates are in the past and ordered from latest to oldest */
  const minimalDateTime = useCallback((isoString: string) => {
    const parsedDate = parseISO(isoString);

    return isToday(parsedDate)
      ? uniformTime(isoString)
      : minimalDate(isoString);
  }, [minimalDate, uniformTime]);

  /** For use in inputs with type="date" */
  const inputDate = useCallback((isoString: string) => (
    format(parseISO(isoString), 'yyyy-MM-dd')
  ), []);

  /** For use in inputs with type="time" */
  const inputTime = useCallback((isoString: string) => (
    format(parseISO(isoString), 'HH:mm')
  ), []);

  /** For use in inputs with type="datetime-local" */
  const inputDateTime = useCallback((isoString: string) => (
    format(parseISO(isoString), 'yyyy-MM-dd HH:mm')
  ), []);

  return useMemo(() => ({
    uniformDateTime,
    minimalDate,
    uniformTime,
    minimalDateTime,
    inputDate,
    inputTime,
    inputDateTime,
  }), [
    minimalDateTime,
    uniformDateTime,
    minimalDate,
    inputDateTime,
    uniformTime,
    inputDate,
    inputTime
  ]);
};

const yearIsRelevant = (isoString: string): boolean => (
  !isBefore(subYears(new Date(), 1), parseISO(isoString))
);
