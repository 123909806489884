import { FC, ReactNode } from 'react';

import { Typography } from '../../../stories/components/Typography/Typography';
import { Stack } from '../Stack/Stack';
import { StackEnd } from '../StackEnd/StackEnd';

type Props = {
  label: string;
  value: number;
  change?: ReactNode;
}

export const Metric: FC<Props> = ({
  label,
  value,
  change,
}) => (
  <Stack direction="column" gap={ 0 }>
    <Typography
      size="sm"
      weight="medium"
      palette={ {
        colour: 'grey',
        intensity: 600,
      } }
    >
      { label }
    </Typography>
    <Stack>
      <Typography
        type="display"
        size="md"
        weight="semibold"
        palette={ {
          colour: 'grey',
          intensity: 900,
        } }
      >
        { value }
      </Typography>
      { change && (
        <StackEnd>
          { change }
        </StackEnd>
      ) }
    </Stack>
  </Stack>
);