import { FunctionComponent } from 'react';
import { parseISO } from 'date-fns';
import { FormattedMessage, FormattedTime } from 'react-intl';

interface Props {
  start: string | Date | null;
  end: string | Date | null;
}

export const TimeSpan: FunctionComponent<Props> = ({
  start,
  end,
}) => {
  const options = {
    hour: 'numeric',
    minute: 'numeric',
  } as Intl.DateTimeFormatOptions;

  const localiseDate = (date: string | Date | null, options: Intl.DateTimeFormatOptions) => (
    date && (
      <FormattedTime
        value={ date instanceof Date ? date : parseISO(date) }
        { ...options }
      />
    )
  );

  const localisedStart = localiseDate(start, options);
  const localisedEnd = localiseDate(end, options);

  return (
    <FormattedMessage
      id="formatting.timeSpan"
      description="Time span formatting"
      defaultMessage="{start} - {end}"
      values={{
        start: localisedStart,
        end: localisedEnd,
      }}
    />
  )
}
