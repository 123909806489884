import { default as React, FC, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { ContentEditorProps } from '../../../../Content/Model';
import {
  DraftChoiceOption,
  QUIZ_INCORRECT_OPTIONS_MAX_COUNT,
  QUIZ_INCORRECT_OPTIONS_MIN_COUNT,
  QUIZ_OPTION_TEXT_MAX_LENGTH,
  QUIZ_OPTION_TEXT_MIN_LENGTH,
  QUIZ_TITLE_MAX_LENGTH,
  QUIZ_TITLE_MIN_LENGTH,
  QuizEditorContent,
  QuizRequestValidator
} from '../../../Service';
import { useInputChangeEventHandler } from '../../../../Common/Hook';
import { ArrayHelper, UniqueIdGenerator } from '../../../../Common/Utility';
import { ValidationTree } from '../../../../Common/Model';
import { FieldValidationErrors, NoMarginTextField, Notice, VerticallySpaced } from '../../../../Common/Component';
import { CharacterCount } from '../../../../Content/Component';
import { OptionsEditor, QuizRequestIncorrectOptionEditor } from '..';

export const QuizRequestEditor: FC<ContentEditorProps<QuizEditorContent>> = ({
  editorContent,
  onEditorContentChanged,
  validation,
  onValidationChanged,
}) => {
  const intl = useIntl();
  const correctAnswerIndex = editorContent.card.content.options.findIndex(option => option.type === 'correctchoiceoption');
  const incorrectAnswers = useMemo(
    () => ArrayHelper.remove(editorContent.card.content.options, correctAnswerIndex) as DraftChoiceOption[],
    [correctAnswerIndex, editorContent.card.content.options],
  );
  const incorrectAnswerValidation = useMemo(
    () => ({
      errors: [],
      children: ArrayHelper.remove(
        Object.values(validation?.children.content?.children.options?.children || {}),
        correctAnswerIndex
      ),
    }),
    [correctAnswerIndex, validation?.children.content?.children.options?.children],
  );
  const titleFieldLabel = intl.formatMessage({
    id: 'broadcasts.content.quiz.fields.title.label',
    description: 'Label for title field in quiz card editor.',
    defaultMessage: 'Question',
  });
  const correctAnswerFieldLabel = intl.formatMessage({
    id: 'broadcasts.content.quiz.fields.correctAnswer.label',
    description: 'Label for correct answer field in quiz card editor.',
    defaultMessage: 'Correct answer',
  });

  const whenTitleChanged = useInputChangeEventHandler(useCallback((title: string) => (
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          title,
        },
      },
    })
  ), [editorContent, onEditorContentChanged]));

  const whenCorrectAnswerTextChanged = useInputChangeEventHandler(useCallback((text: string) => (
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          options: ArrayHelper.replace(
            editorContent.card.content.options,
            correctAnswerIndex,
            {
              ...editorContent.card.content.options[correctAnswerIndex],
              text,
            },
          ),
        },
      },
    })
  ), [correctAnswerIndex, editorContent, onEditorContentChanged]));

  const whenCorrectAnswerTextBlurred = useCallback(() => (
    onValidationChanged({
      errors: validation?.errors || [],
      children: {
        content: {
          errors: validation?.children.content?.errors || [],
          children: {
            ...validation?.children.content?.children,
            options: {
              errors: validation?.children.content?.children.options?.errors || [],
              children: ArrayHelper.objectify(
                ArrayHelper.replace(
                  Object.values(validation?.children.content?.children.options?.children || {}),
                  correctAnswerIndex,
                  QuizRequestValidator.validateOptionText(editorContent.card.content.options[correctAnswerIndex].text)
                )
              ),
            }
          },
        },
      },
    })
  ), [correctAnswerIndex, editorContent.card.content.options, onValidationChanged, validation]);

  const createOption = (): DraftChoiceOption => ({
    id: UniqueIdGenerator.generate(),
    type: 'choiceoption',
    text: '',
  });

  const whenTitleBlurred = useCallback(() => (
    onValidationChanged({
      errors: validation?.errors || [],
      children: {
        content: {
          errors: validation?.children.content?.errors || [],
          children: {
            ...validation?.children.content?.children,
            title: QuizRequestValidator.validateTitle(editorContent.card.content.title),
          },
        },
      },
    })
  ), [validation, onValidationChanged, editorContent]);

  const whenIncorrectOptionsChanged = useCallback((incorrectOptions: DraftChoiceOption[]) => (
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          options: ArrayHelper.insert(
            incorrectOptions,
            correctAnswerIndex,
            editorContent.card.content.options[correctAnswerIndex],
          ),
        },
      },
    })
  ), [correctAnswerIndex, editorContent, onEditorContentChanged]);

  const whenIncorrectOptionsValidationChanged = useCallback((incorrectOptionsValidation: ValidationTree<DraftChoiceOption[]>) => (
    onValidationChanged({
      errors: validation?.errors || [],
      children: {
        content: {
          errors: validation?.children.content?.errors || [],
          children: {
            ...validation?.children.content?.children,
            options: {
              errors: incorrectOptionsValidation.errors,
              children: ArrayHelper.objectify(
                ArrayHelper.insert(
                  Object.values(incorrectOptionsValidation.children),
                  correctAnswerIndex,
                  validation?.children.content?.children.options?.children[correctAnswerIndex],
                )
              ),
            }
          },
        },
      },
    })
  ), [onValidationChanged, validation, correctAnswerIndex]);

  return (
    <VerticallySpaced gap={ 2 }>
      <VerticallySpaced gap={ 1 }>
        <h4>{ titleFieldLabel }</h4>
        <NoMarginTextField
          fullWidth
          value={ editorContent.card.content.title }
          onChange={ whenTitleChanged }
          onBlur={ whenTitleBlurred }
        />
        <CharacterCount
          current={ editorContent.card.content.title.length || 0 }
          minimum={ QUIZ_TITLE_MIN_LENGTH }
          maximum={ QUIZ_TITLE_MAX_LENGTH }
        />
        <FieldValidationErrors
          fieldName={ titleFieldLabel }
          validationErrors={ validation?.children.content?.children.title?.errors || [] }
        />
      </VerticallySpaced>
      <VerticallySpaced gap={ 1 }>
        <h4>{ correctAnswerFieldLabel }</h4>
        <NoMarginTextField
          fullWidth
          value={ editorContent.card.content.options[correctAnswerIndex].text }
          onChange={ whenCorrectAnswerTextChanged }
          onBlur={ whenCorrectAnswerTextBlurred }
        />
        <CharacterCount
          current={ editorContent.card.content.options[correctAnswerIndex].text.length || 0 }
          minimum={ QUIZ_OPTION_TEXT_MIN_LENGTH }
          maximum={ QUIZ_OPTION_TEXT_MAX_LENGTH }
        />
        <FieldValidationErrors
          fieldName={ correctAnswerFieldLabel }
          validationErrors={ validation?.children.content?.children.options?.children[correctAnswerIndex]?.errors || [] }
        />
      </VerticallySpaced>
      <Notice
        variant="outlined"
        feedback={ {
          severity: 'none',
          message: intl.formatMessage({
            id: 'broadcasts.content.quiz.answerOrderNotice',
            description: 'Notice displayed regarding quiz answer order randomisation.',
            defaultMessage: 'Answer order will be randomised.'
          })
        } }
      />
      <OptionsEditor
        options={ incorrectAnswers }
        onChange={ whenIncorrectOptionsChanged }
        validation={ incorrectAnswerValidation }
        onValidationChange={ whenIncorrectOptionsValidationChanged }
        OptionEditorComponent={ QuizRequestIncorrectOptionEditor }
        minOptions={ QUIZ_INCORRECT_OPTIONS_MIN_COUNT }
        maxOptions={ QUIZ_INCORRECT_OPTIONS_MAX_COUNT }
        createOption={ createOption }
        localisedFieldName={ intl.formatMessage({
          id: 'broadcasts.content.quiz.fields.options.label',
          description: 'Label for incorrect options in quiz request',
          defaultMessage: 'Incorrect answers',
        }) }
        localisedButtonLabel={ intl.formatMessage({
          id: 'broadcasts.content.quiz.fields.addOption.label',
          description: 'Label for add incorrect option button in quiz request',
          defaultMessage: 'Add incorrect answer',
        }) }
      />
    </VerticallySpaced>
  );
}
