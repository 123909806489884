import { FC, useCallback } from 'react';

import { StyledBody, StyledCloseButton, StyledExpandingWidget, StyledTitleBar, StyledToggleIcon } from './style';
import { Flex, FlexPullRight } from '..';
import DownArrowIcon from '../../../Assets/img/icons/streamline/arrow-down-1.svg';

type Props = {
  title: string;
  expanded: boolean;
  onToggle: (expanded: boolean) => void;
  onClose?: () => void;
};

export const ExpandingWidget: FC<Props> = ({
  title,
  expanded,
  onToggle,
  onClose,
  children,
}) => {
  const whenToggled = useCallback(() => onToggle(!expanded), [expanded, onToggle]);

  return (
    <StyledExpandingWidget>
      <StyledTitleBar
        color="secondary"
        disableElevation
        onClick={ whenToggled }
        variant="contained"
      >
        <Flex>
          { title }
          <FlexPullRight gap={ 3 }>
            <StyledToggleIcon
              expanded={ expanded}
              IconComponent={ DownArrowIcon }
            />
            { onClose && (
              <StyledCloseButton
                onClick={ onClose }
                color="secondary.contrastText"
              />
            ) }
          </FlexPullRight>
        </Flex>
      </StyledTitleBar>
      <StyledBody expanded={ expanded }>
        { children }
      </StyledBody>
    </StyledExpandingWidget>
  );
};
