import { FC } from 'react';
import { useIntl } from 'react-intl';

import { IconButton, IconButtonProps } from '../IconButton/IconButton';
import CloseIcon from '../../../Assets/img/icons/streamline/close.svg';

type Props = {
  onClick: () => void;
  color?: IconButtonProps['color'];
  className?: string;
};

export const CloseButton: FC<Props> = ({ onClick, color, className }) => {
  const intl = useIntl();

  return (
    <IconButton
      IconComponent={ CloseIcon }
      color={ color }
      label={ intl.formatMessage({
        description: 'Label for close button used throughout the console.',
        defaultMessage: 'Close',
      }) }
      onClick={ onClick }
      className={ className }
      size="small"
    />
  );
};
