import { FunctionComponent, ReactNode, useMemo } from 'react';
import { FormattedList, FormattedMessage, useIntl } from 'react-intl';

import { Notice } from '../../../Common/Component';
import { TagType } from '../../Model';
import { TagParser } from '../../Utility';
import { StyledOperationList } from './style';
import { OperationSummary } from '../../../Imports/Model';

type Props = {
  operations: OperationSummary[];
  onImportButtonClicked: () => void;
}

export const TagOperationSummaryNotice: FunctionComponent<Props> = ({
  operations,
  onImportButtonClicked,
}) => {
  const intl = useIntl();
  const [creationStrings, updateStrings] = useMemo(() => {
    const localiseOperation = (operation: OperationSummary): ReactNode => (
      <FormattedMessage
        key={ `${ operation.operationType }_${ operation.recordType }` }
        id="tagOperationSummary.tagWithCount"
        description="Tag name with count, listed in creation and update summaries."
        defaultMessage="{ count, plural, one {# { singular }} other {# { plural }} }"
        values={ {
          count: operation.count,
          singular: TagParser.localisedTagTypeFromInternalTagType(operation.recordType as TagType, intl, 1),
          plural: TagParser.localisedTagTypeFromInternalTagType(operation.recordType as TagType, intl, Infinity),
        } }
      />
    );

    return [
      operations.filter(operation => operation.operationType === 'create')
        .map(localiseOperation),
      operations.filter(operation => operation.operationType === 'update')
        .map(localiseOperation),
    ];
  }, [intl, operations]);

  return (
    <Notice
      feedback={ {
        message: (
          <FormattedMessage
            id="tags.import.summary"
            description="Import summary message above table in summary step of tag import."
            defaultMessage="This import will: <ul>{ creations }{ updates }</ul>"
            values={ {
              ul: (chunks: ReactNode[]) => <StyledOperationList>{ chunks }</StyledOperationList>,
              li: (chunks: ReactNode[]) => <li>{ chunks }</li>,
              creations: !!creationStrings.length && (
                <li>
                  <FormattedMessage
                    id="tags.import.creationSummary"
                    description="Import summary creation bullet point."
                    defaultMessage="<strong>Create</strong> { creationList }"
                    values={ {
                      creationList: <FormattedList value={ creationStrings }/>,
                    } }
                  />
                </li>
              ),
              updates: !!updateStrings.length && (
                <li>
                  <FormattedMessage
                    id="tags.import.updateSummary"
                    description="Import summary update bullet point."
                    defaultMessage="<strong>Add connections to</strong> { updateList }"
                    values={ {
                      updateList: <FormattedList value={ updateStrings }/>,
                    } }
                  />
                </li>
              )
            } }
          />
        ),
        severity: 'info',
      } }
      buttons={ [
        {
          id: 'perform',
          props: {
            variant: 'primary',
            onClick: onImportButtonClicked,
          },
          label: intl.formatMessage({
            id: 'tags.import.summary.preformImport',
            description: 'Preform import button in operation summary of summary step of tag import.',
            defaultMessage: 'Start import',
          }),
        }
      ] }
      variant="outlined"
    />
  );
};
