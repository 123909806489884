import { FC, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { TextField } from '../TextField/TextField';
import { Form } from '../Form/Form';
import { DeprecatedPresentationIcon } from '../../Content/PresentationIcon/DeprecatedPresentationIcon';
import SearchIcon from '../../../../Asset/Icon/figma/search-lg.svg';
import { useDebounce } from '../../../Hook/useDebounce';
import { BadgeCloseX } from 'op-storybook/lib/components/BadgeCloseX/BadgeCloseX';

type Props = {
  value: string;
  onChange: (value: string) => void;
  debounce?: number;
};

export const SearchField: FC<Props> = ({
  value,
  onChange,
  debounce = 500,
}) => {
  const intl = useIntl();
  const [localValue, setLocalValue] = useState<string>(value);
  const debouncedLocalValue = useDebounce(localValue, debounce);
  const [awaitingDebouncedValue, setAwaitingDebouncedValue] = useState<boolean>(false);

  useEffect(() => {
    if (value === debouncedLocalValue || !awaitingDebouncedValue) {
      return;
    }

    setAwaitingDebouncedValue(false);
    onChange(debouncedLocalValue);
  }, [awaitingDebouncedValue, debouncedLocalValue, onChange, value]);

  useEffect(() => {
    if (localValue === value || awaitingDebouncedValue) {
      return;
    }

    setLocalValue(value);
  }, [awaitingDebouncedValue, localValue, value]);

  const whenChanged = useCallback((value: string) => {
    setAwaitingDebouncedValue(true);
    setLocalValue(value);
  }, []);

  return (
    <Form
      onSubmit={ event => event.preventDefault() }
    >
      <TextField
        name={ intl.formatMessage({
          description: 'Name for search field',
          defaultMessage: 'search',
        }) }
        startAdornment={
          <DeprecatedPresentationIcon
            IconComponent={ SearchIcon }
            size={ 5 }
            color="grey.500.main"
          />
        }
        value={ localValue }
        onChange={ whenChanged }
        placeholder={ intl.formatMessage({
          description: 'Placeholder for search field',
          defaultMessage: 'Search',
        }) }
        {
          ...localValue
            ? {
              endAdornment: (
                <BadgeCloseX
                  onClick={ () => whenChanged('') }
                />
              )
            }
            : {}
        }
      />
    </Form>
  );
};
