import styled from '@emotion/styled';

export const StyledContentNav = styled.div`
  display: flex;
  background-color: ${ props => props.theme.palette.grey[100] };
  padding: ${ props => `${ props.theme.spacing(2) }` };
  flex-direction: column;
  gap: ${ props => `${ props.theme.spacing(1) }` };
  z-index: 1;
  width: 100%;
  overflow: auto;
  position: relative;
`;

export const StyledDroppable = styled.div`
  gap: ${ props => `${ props.theme.spacing(1) }` };
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
`;

export const StyledNavDivider = styled.hr`
  margin: 0;
  border-top-color: rgba(0, 0, 0, 0.1);
  border-bottom-color: rgba(255, 255, 255, 0.5);
  border-left: none;
  border-right: none;
`;
