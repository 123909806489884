import { FC, useCallback, useMemo, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';

import { PresentationIcon } from '../PresentationIcon/PresentationIcon';
import FileIcon from '../../../Assets/img/icons/streamline/common-file-empty.svg';
import { LocalisedFileUploadErrors } from '../LocalisedFileUploadErrors/LocalisedFileUploadErrors';
import { FlexPullRight } from '../FlexPullRight/FlexPullRight';
import {
  StyledFileName,
  StyledFileNameContainer,
  StyledListItem,
  StyledProgress,
  StyledRemoveButtonContainer,
  StyledLink,
} from './style';
import { FileUploaderContext, UploadState } from '../../Context';
import BoldTickIcon from '../../../Assets/img/icons/streamline-bold/check-circle-1.svg';
import BoldCrossIcon from '../../../Assets/img/icons/streamline-bold/remove-circle.svg';
import CrossIcon from '../../../Assets/img/icons/streamline/cross.svg';
import { IconButton } from '../IconButton/IconButton';
import { useContextOrThrow } from '../../../Core/Hook';
import { Flex } from '../Flex/Flex';
import { UploadStateHelper } from '../../Utility/UploadStateHelper';
import { RequestState } from '../../../Models';
import { VerticallySpaced } from '../VerticallySpaced/VerticallySpaced';

interface FilesUploadState extends UploadState {
  metadata: {
    type: 'files',
    parentFolderId: string;
  };
}

const uploadStateIsFilesUploadState = (uploadState: UploadState): uploadState is FilesUploadState => (
  (uploadState as FilesUploadState).metadata?.type === 'files'
);

type Props = {
  uploadState: UploadState;
  callbackState: RequestState | undefined;
};

export const UploadWidgetListItem: FC<Props> = ({
  uploadState,
  callbackState,
}) => {
  const intl = useIntl();
  const [mouseOver, setMouseOver] = useState<boolean>(false);
  const { remove } = useContextOrThrow(FileUploaderContext);
  const uploadComplete = UploadStateHelper.uploadIsComplete(uploadState) && (callbackState === RequestState.COMPLETE || callbackState === undefined);
  const uploadFailed = UploadStateHelper.uploadIsFailed(uploadState) || callbackState === RequestState.FAILED;
  const performingCallback = UploadStateHelper.uploadIsComplete(uploadState) && callbackState !== undefined && callbackState < RequestState.COMPLETE;

  const whenRemoveClicked = useCallback(() => (
    remove(uploadState.ref)
  ), [remove, uploadState.ref]);

  const progress = useMemo(() => (
    uploadComplete
      ? intl.formatMessage({
        description: 'Progress string beside completed upload file name in upload widget.',
        defaultMessage: 'Complete',
      })
      : uploadFailed
        ? intl.formatMessage({
          description: 'Progress string beside failed upload file name in upload widget.',
          defaultMessage: 'Failed',
        })
        : UploadStateHelper.uploadIsScanning(uploadState)
          ? intl.formatMessage({
            description: 'Progress string beside scanning upload file name in upload widget.',
            defaultMessage: 'Scanning…',
          })
          : UploadStateHelper.uploadIsProcessing(uploadState) || performingCallback
            ? intl.formatMessage({
              description: 'Progress string beside processing upload file name in upload widget.',
              defaultMessage: 'Processing…',
            })
            : UploadStateHelper.uploadIsInProgress(uploadState)
              ? intl.formatMessage({
                description: 'Progress string beside in-progress upload file name in upload widget.',
                defaultMessage: '{ progress }%',
              }, { progress: Math.floor(uploadState.progress * 100) })
              : intl.formatMessage({
                description: 'Progress string beside upload file name in upload widget when upload status is unknown.',
                defaultMessage: 'Uploading…',
              })
  ), [intl, performingCallback, uploadComplete, uploadFailed, uploadState]);

  return (
    <StyledListItem
      onMouseEnter={ () => setMouseOver(true) }
      onMouseLeave={ () => setMouseOver(false) }
    >
      <PresentationIcon
        IconComponent={ FileIcon }
      />
      <StyledFileNameContainer>
        <VerticallySpaced gap={ 1 }>
          <Flex gap={ 1 } noWrap>
            <StyledFileName>{ uploadState.file.name }</StyledFileName>
            <StyledProgress>{ progress }</StyledProgress>
          </Flex>
          { uploadState.error && (
            <LocalisedFileUploadErrors errors={ [uploadState.error] }/>
          ) }
        </VerticallySpaced>
        { uploadStateIsFilesUploadState(uploadState) && mouseOver && (
          <StyledLink to={ `/files/${ uploadState.metadata.parentFolderId }` }>
            <FormattedMessage
              description="Link back to files section from upload in upload widget."
              defaultMessage="View in files"
            />
          </StyledLink>
        ) }
      </StyledFileNameContainer>
      <FlexPullRight>
        {
          mouseOver && !uploadState.upload
            ? (
              <StyledRemoveButtonContainer>
                <IconButton
                  showTooltip
                  IconComponent={ CrossIcon }
                  label={ intl.formatMessage({
                    description: 'Label for remove button in upload widget when upload has failed or is in progress.',
                    defaultMessage: 'Cancel',
                  }) }
                  onClick={ whenRemoveClicked }
                  size="small"
                />
              </StyledRemoveButtonContainer>
            )
            : uploadFailed
              ? (
                <PresentationIcon
                  IconComponent={ BoldCrossIcon }
                  color="error.main"
                  size="small"
                />
              )
              : uploadComplete
                ? (
                  <PresentationIcon
                    IconComponent={ BoldTickIcon }
                    color="success.main"
                    size="small"
                  />
                )
                : (
                  <CircularProgress
                    {
                      ...UploadStateHelper.uploadIsInProgress(uploadState)
                        ? {
                          value: uploadState.progress * 100,
                          variant: 'determinate',
                        }
                        : {
                          variant: 'indeterminate',
                        }
                    }
                    color="primary"
                    size={ 24 }
                  />
                )
        }
      </FlexPullRight>
    </StyledListItem>
  );
};
