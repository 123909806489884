import { ChangeEvent, ChangeEventHandler, FunctionComponent, useCallback, useState } from 'react';
import { Button, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';

import { FieldValidationErrors, NumberInput } from '../../../../Common/Component';
import {
  CHOICE_REQUEST_MAX_SELECTIONS_MAX,
  CHOICE_REQUEST_MAX_SELECTIONS_MIN,
  CHOICE_REQUEST_MIN_SELECTIONS_MAX,
  CHOICE_REQUEST_MIN_SELECTIONS_MIN,
  DraftChoiceOption,
  DraftChoiceRequest
} from '../../../Model/ContentTypes';
import { RequestFields } from '..';
import { ArrayHelper } from '../../../../Common/Utility';
import { ChoiceRequestOptionField } from '../ChoiceRequestOptionField/ChoiceRequestOptionField';
import { StyledFormControl, StyledFormLabel } from './style';
import { ChoiceRequestValidator } from '../../../Utility/Validation';
import { ContentEditorProps } from '../../../../Content/Model';
import { ValidationTree } from '../../../../Common/Model';
import { CardValidationMerger } from '../../../../Content/Utility';
import { ChoiceRequestEditorContent } from '../../../Service';

export const ChoiceRequestFields: FunctionComponent<ContentEditorProps<ChoiceRequestEditorContent>> = ({
  editorContent,
  onEditorContentChanged,
  validation,
  onValidationChanged,
}) => {
  const intl = useIntl();
  const minSelectionsErrors = validation?.children.content?.children.minSelections?.errors || [];
  const maxSelectionsErrors = validation?.children.content?.children.maxSelections?.errors || [];
  const [cachedMinSelections, setCachedMinSelections] = useState<number | null>(null);
  const [cachedMaxSelections, setCachedMaxSelections] = useState<number | null>(null);

  const whenMinSelectionsChanged: ChangeEventHandler<HTMLInputElement> = (event) => {
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          minSelections: +event.target.value,
        },
      },
    });
  };

  const whenMaxSelectionsChanged: ChangeEventHandler<HTMLInputElement> = (event) => {
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          maxSelections: +event.target.value,
        },
      },
    });
  };

  const whenStyleChanged = (event: ChangeEvent<HTMLInputElement>, value: string): void => {
    if (!isValidStyle(value)) {
      throw new Error('Invalid choice content style selected.');
    }

    value === 'dropdown'
      ? setStyleToDropdown()
      : setStyleToCheckbox();
  };

  const setStyleToDropdown = (): void => {
    setCachedMinSelections(editorContent.card.content.minSelections);
    setCachedMaxSelections(editorContent.card.content.maxSelections);
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          minSelections: 1,
          maxSelections: 1,
          style: 'dropdown',
        },
      },
    });
  };

  const setStyleToCheckbox = (): void => {
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          minSelections: cachedMinSelections || 1,
          maxSelections: cachedMaxSelections || 1,
          style: 'checkbox',
        },
      },
    });
    setCachedMinSelections(null);
    setCachedMaxSelections(null);
  };

  const whenOptionChanged = (index: number, option: DraftChoiceOption): void => {
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          options: ArrayHelper.replace(editorContent.card.content.options, index, option),
        },
      },
    });
  };

  const whenOptionDeleted = (index: number): void => {
    const newOptions = ArrayHelper.remove(editorContent.card.content.options, index);
    const newMax = Math.min(editorContent.card.content.maxSelections, newOptions.length);
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          maxSelections: newMax,
          minSelections: Math.min(editorContent.card.content.minSelections, newMax),
          options: newOptions,
        },
      },
    });
  };

  const whenAddOptionClicked = (): void => {
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          options: editorContent.card.content.options.concat({ title: '' }),
        },
      },
    });
  };

  const whenMinSelectionsBlurred = () => whenContentChildrenValidationChanged({
    minSelections: ChoiceRequestValidator.validateMinSelections(editorContent.card.content),
  });
  const whenMaxSelectionsBlurred = () => whenContentChildrenValidationChanged({
    maxSelections: ChoiceRequestValidator.validateMaxSelections(editorContent.card.content),
  });

  const whenOptionValidationChanged = (index: number, optionValidation: ValidationTree<DraftChoiceOption>): void => {
    whenContentChildrenValidationChanged({
      options: {
        errors: [],
        children: {
          ...validation?.children.content?.children.options?.children,
          [index]: optionValidation,
        },
      },
    });
  };

  const whenContentChildrenValidationChanged = useCallback(
    (children: ValidationTree<DraftChoiceRequest>['children']): void => {
      onValidationChanged(
        CardValidationMerger.addContentValidationChildrenToCardValidation(children, validation)
      );
    },
    [validation, onValidationChanged],
  );

  return (
    <>
      <RequestFields
        editorContent={ editorContent }
        onEditorContentChanged={ onEditorContentChanged }
        validation={ validation }
        onValidationChanged={ onValidationChanged }
      />
      <StyledFormControl>
        <FormControl component="fieldset">
          <StyledFormLabel>
            <FormattedMessage
              id="form.fields.choiceRequest.style.label"
              defaultMessage="Style"
            />
          </StyledFormLabel>
          <RadioGroup
            value={ editorContent.card.content.style }
            onChange={ whenStyleChanged }
          >
            <FormControlLabel
              value="checkbox"
              control={ <Radio name="checkbox"/> }
              label={ intl.formatMessage({
                id: 'form.fields.choiceRequest.style.checkbox',
                defaultMessage: 'Checkbox',
              }) }
            />
            <FormControlLabel
              value="dropdown"
              control={ <Radio name="dropdown"/> }
              label={ intl.formatMessage({
                id: 'form.fields.choiceRequest.style.dropdown',
                defaultMessage: 'Dropdown',
              }) }
            />
          </RadioGroup>
        </FormControl>
      </StyledFormControl>
      {
        editorContent.card.content.style === 'checkbox' && (
          <>
            <NumberInput
              id="minSelections"
              inputProps={ {
                min: CHOICE_REQUEST_MIN_SELECTIONS_MIN,
                max: Math.min(CHOICE_REQUEST_MIN_SELECTIONS_MAX, editorContent.card.content.maxSelections),
              } }
              label={ intl.formatMessage({
                id: 'form.fields.choiceRequest.minSelections.label',
                defaultMessage: 'Min selections *',
              }) }
              value={ editorContent.card.content.minSelections }
              onChange={ whenMinSelectionsChanged }
              onBlur={ whenMinSelectionsBlurred }
              error={ !!minSelectionsErrors.length }
              fullWidth
            />
            <FieldValidationErrors
              fieldName={ intl.formatMessage({
                id: 'form.fields.textRequest.minSelections.name',
                defaultMessage: 'min selections',
              }) }
              validationErrors={ minSelectionsErrors }
            />
            <NumberInput
              id="maxSelections"
              inputProps={ {
                min: Math.max(CHOICE_REQUEST_MAX_SELECTIONS_MIN, editorContent.card.content.minSelections),
                max: Math.min(CHOICE_REQUEST_MAX_SELECTIONS_MAX, editorContent.card.content.options.length),
              } }
              label={ intl.formatMessage({
                id: 'form.fields.textRequest.maxSelections.label',
                defaultMessage: 'Max selections *',
              }) }
              value={ editorContent.card.content.maxSelections }
              onChange={ whenMaxSelectionsChanged }
              onBlur={ whenMaxSelectionsBlurred }
              error={ !!maxSelectionsErrors.length }
              fullWidth
            />
            <FieldValidationErrors
              fieldName={ intl.formatMessage({
                id: 'form.fields.textRequest.maxSelections.name',
                defaultMessage: 'max selections',
              }) }
              validationErrors={ maxSelectionsErrors }
            />
          </>
        )
      }
      <StyledFormControl>
        <FormControl
          component="fieldset"
          fullWidth
        >
          <StyledFormLabel>
            <FormattedMessage
              id="form.fields.textRequest.options.name"
              defaultMessage="Options"
            />
          </StyledFormLabel>
          {
            editorContent.card.content.options.map((option, index) => (
              <ChoiceRequestOptionField
                key={ option.id || index }
                index={ index }
                option={ option }
                validation={ validation?.children.content?.children.options?.children[index] }
                onValidationChanged={ children => whenOptionValidationChanged(index, children) }
                onOptionChanged={ whenOptionChanged }
                onOptionDeleted={ whenOptionDeleted }
                deleteDisabled={ editorContent.card.content.options.length <= 2 }
              />
            ))
          }
          <Button
            color="primary"
            onClick={ whenAddOptionClicked }
          >
            <FormattedMessage
              id="form.fields.textRequest.addOption"
              defaultMessage="Add option"
            />
          </Button>
        </FormControl>
      </StyledFormControl>
    </>
  )
};

const isValidStyle = (style: string): style is 'dropdown' | 'checkbox' => {
  return ['dropdown', 'checkbox'].indexOf(style) !== -1
};
