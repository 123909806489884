import styled from '@emotion/styled';
import { ButtonGroup, Button as MuiButton } from '@mui/material';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';

export const StyledButton = styled(Button)`
  .MuiButtonBase-root {
    width: 100%;
    height: 100%;
  }

  svg {
    width: 31px;
    height: 24px;
  }
`;

export const StyledButtonGroup = styled(ButtonGroup)`
  display: flex;
  > * {
    flex-grow: 1;
  }
  .MuiButton-root {
    padding: 0.25rem;
    line-height: 1;
    border-radius: 0;
  }
  .MuiButton-outlined {
    color: ${props => props.theme.palette.text.primary};
    border-color: ${props => props.theme.palette.text.primary};
  }
`;

export const SizeButton = styled(MuiButton)`
  &.MuiButtonBase-root {
    padding: 0;
    display: flex;
    flex-direction: column;
    ${ props => props.color === 'inherit' ? `color: ${ props.theme.palette.text.primary }` : `color: ${ props.theme.palette.primary.main }` };

    > span {
      ${ props => props.color === 'inherit' ? `color: ${ props.theme.palette.text.primary }` : `color: ${ props.theme.palette.primary.contrastText }` };

      padding: 0.25rem;
    }
  }
`;

export const StyledBadgeContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: ${ props => props.theme.new.spacing[1] };
`;

export const StyledIconContainer = styled.div`
  background: ${props => props.theme.palette.background.paper};
  width: calc(100% - 2px);
  padding: 0.5rem;

  > svg {
    width: 38px;
    height: 67px;
  }
`;
