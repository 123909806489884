import { FC, ReactNode } from 'react';

import { RichTextContentPreview } from '../../..';
import { CardTitle } from '../../../../../Broadcasts/Component/Content';
import { VerticallySpaced } from '../../../../../Common/Component';

type Props = {
  description?: string;
  thumbnail: ReactNode;
  title: string;
};

export const VideoContentWithoutFooter: FC<Props> = ({
  description,
  thumbnail,
  title,
}) => (
  <VerticallySpaced gap={ 4 }>
    <CardTitle>
      { title }
    </CardTitle>
    <VerticallySpaced align="center">
      { thumbnail }
    </VerticallySpaced>
    { description && (
      <RichTextContentPreview
        text={ description }
      />
    ) }
  </VerticallySpaced>
);
