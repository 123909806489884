import styled from '@emotion/styled';
import { Dialog } from '@mui/material';

export const StyledDialog = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    min-width: 25rem;
    max-width: 50rem;
    width: 100%;
  }

  .MuiDialogContent-root {
    display: grid;
    margin: 1rem;
    grid-template-rows: auto 1fr;
    grid-row-gap: 1.5rem;
    text-align: center;
  }

  .MuiDialogActions-root {
    margin: 0 0.5rem 0.5rem;
  }
`;

export const StyledPaddedDialog = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    min-width: 25rem;
    max-width: 50rem;
    width: 40vw;
  }

  .MuiDialogContent-root {
    display: grid;
    margin: 4rem 4rem 3rem;
    grid-template-rows: auto 1fr;
    grid-row-gap: 3rem;
    text-align: center;
  }

  .MuiDialogActions-root {
    margin: 0 4rem 4rem;
  }
`;

export const StyledCenteredDialog = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    min-width: 25rem;
    max-width: 50rem;
    width: 40vw;
  }

  .MuiDialogContent-root {
    display: grid;
    margin: 4rem 4rem 3rem;
    grid-template-rows: auto 1fr;
    grid-row-gap: 3rem;
    text-align: center;
  }

  .MuiDialogActions-root {
    margin: 0 4rem 4rem;
    display: grid;
    justify-content: center;
  }
`;

type StyledHeadingProps = {
  size: 'small' | 'medium' | 'large';
  textAlign?: 'left' | 'center' | 'right' | 'justify';
}

export const StyledHeading = styled.h1<StyledHeadingProps>`
  font-size: ${(props) => {
    switch(props.size) {
      case 'small':
        return '1.5rem';
      case 'medium':
        return '2.5rem';
      case 'large':
      default:
        return '3.5rem';
    }
  }};
  text-align: ${props => props.textAlign || 'center'};
`;

type StyledContentProps = {
  textAlign: 'left' | 'center' | 'right' | 'justify';
}

export const StyledContent = styled.div<StyledContentProps>`
  font-size: 1.2rem;
  text-align: ${props => props.textAlign};
`;
