import { FunctionComponent } from 'react';
import { FormatNumberOptions } from '@formatjs/intl/src/types';
import { Doughnut } from 'react-chartjs-2';
import { ChartData, ChartOptions } from 'chart.js';

import { AnimatedNumber, PercentageChange } from '../../../Common/Component';
import { ChartContainer, FigureContainer, StyledDoughnutChartMetric } from './style';
import { Legend } from '..';

interface Props {
  previousValue: number;
  currentValue: number;
  chartData: Required<ChartData<'doughnut'>>;
  numberFormattingOptions?: FormatNumberOptions;
}

export const DoughnutChartMetric: FunctionComponent<Props> = ({
  previousValue,
  currentValue,
  chartData,
  numberFormattingOptions,
}) => {
  const options: ChartOptions<'doughnut'> = {
    plugins: {
      legend: {
        display: false,
      },
    },
    maintainAspectRatio: true,
    cutout: '50%',
  };

  return (
    <StyledDoughnutChartMetric>
      <FigureContainer>
        <AnimatedNumber
          initialValue={ previousValue }
          targetValue={ currentValue }
          formattingOptions={ numberFormattingOptions }
        />
        <PercentageChange
          previousValue={ previousValue }
          currentValue={ currentValue }
        />
        <Legend data={ chartData }/>
      </FigureContainer>
      <ChartContainer>
        <Doughnut
          options={ options }
          data={ chartData }
        />
      </ChartContainer>
    </StyledDoughnutChartMetric>
  );
};
