import { ChangeEvent, FunctionComponent, useMemo } from 'react';
import { FormattedList, FormattedMessage } from 'react-intl';
import { MenuItem } from '@mui/material';

import { Checkbox, TableCell, TableRow } from '../../../../Components';
import { ProfileTagsAndPerson } from '../../../Hook';
import { MinimalTag, TagType } from '../../../../Tags/Model';
import { Flex, TruncatingContent } from '../../../../Common/Component';
import { ProfileAction } from '..';
import { TableMenu } from '../../../../Components/Content/TableMenu';
import AddTagIcon from '../../../../Assets/img/icons/streamline/tags-add.svg';
import RemoveTagIcon from '../../../../Assets/img/icons/streamline/tags-minus.svg';
import ReplaceTagIcon from '../../../../Assets/img/icons/streamline/tags-refresh.svg';
import DeleteProfileIcon from '../../../../Assets/img/icons/streamline/single-neutral-actions-subtract.svg';
import { IntegrationProfileTooltip } from '..';
import { useLoggedInAuthDescription } from '../../../../Core/Hook';
import { PersonAvatarWithName } from '../../../../Common/Component/PersonAvatarWithName/PersonAvatarWithName';

type TagTuple = [MinimalTag<TagType.Team>[], MinimalTag<TagType.JobTitle>[], MinimalTag<TagType.Skill>[]];

type Props = {
  profileTagsAndPerson: ProfileTagsAndPerson;
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
  onActionClicked: (action: ProfileAction) => void;
};

export const ProfileRow: FunctionComponent<Props> = ({
  profileTagsAndPerson,
  checked,
  onCheckedChange,
  onActionClicked,
}) => {
  const { administratedTags } = useLoggedInAuthDescription();
  const [
    teams,
    jobTitles,
    skills,
  ] = useMemo(() => (
    profileTagsAndPerson.tags.reduce<TagTuple>(
      (tagTuple, tag) => {
        switch (tag.type) {
          case 'team':
            return [
              tagTuple[0].concat(tag as MinimalTag<TagType.Team>),
              tagTuple[1],
              tagTuple[2],
            ];
          case 'jobtitle':
            return [
              tagTuple[0],
              tagTuple[1].concat(tag as MinimalTag<TagType.JobTitle>),
              tagTuple[2],
            ];
          case 'skill':
            return [
              tagTuple[0],
              tagTuple[1],
              tagTuple[2].concat(tag as MinimalTag<TagType.Skill>),
            ];
          default:
            return tagTuple;
        }
      },
      [[], [], []],
    )
  ), [profileTagsAndPerson.tags]);

  const whenCheckboxChanged = (_event: ChangeEvent<HTMLInputElement>, checked: boolean): void => {
    onCheckedChange(checked);
  };

  return (
    <TableRow>
      <TableCell
        padding="checkbox"
      >
        {
          profileTagsAndPerson.profile.externallyManaged
            ? <IntegrationProfileTooltip/>
            : (
              <Checkbox
                checked={ checked }
                onChange={ whenCheckboxChanged }
              />
            )
        }
      </TableCell>
      <TableCell minwidth="15rem">
        <PersonAvatarWithName person={ profileTagsAndPerson.person }/>
      </TableCell>
      <TableCell minwidth="15rem">
        <TruncatingContent>
          <FormattedList
            value={ teams.map(tag => tag.name) }
          />
        </TruncatingContent>
      </TableCell>
      <TableCell minwidth="15rem">
        <TruncatingContent>
          <FormattedList
            value={ jobTitles.map(tag => tag.name) }
          />
        </TruncatingContent>
      </TableCell>
      <TableCell minwidth="15rem">
        <TruncatingContent>
          <FormattedList
            value={ skills.map(tag => tag.name) }
          />
        </TruncatingContent>
      </TableCell>
      <TableCell>
        { !profileTagsAndPerson.profile.externallyManaged && (
          <TableMenu rowKey={ profileTagsAndPerson.profile.id }>
            <MenuItem
              disabled={
                !!profileTagsAndPerson.tags
                  .filter(tag => tag.type === 'team')
                  .find(tag => !administratedTags.includes(tag.id))
              }
              onClick={ () => onActionClicked({
                type: 'delete',
                query: {
                  ids: [profileTagsAndPerson.profile.id.toString()],
                  noExternallyManaged: true,
                },
              }) }
            >
              <Flex gap={ 1 }>
                <DeleteProfileIcon
                  width="24px"
                  height="24px"
                  role="presentation"
                />
                <FormattedMessage
                  description="Label for delete profile button in table row menu."
                  defaultMessage="Delete profile"
                />
              </Flex>
            </MenuItem>
            <MenuItem
              onClick={ () => onActionClicked({
                type: 'add',
                query: {
                  ids: [profileTagsAndPerson.profile.id.toString()],
                  noExternallyManaged: true,
                },
              }) }
            >
              <Flex gap={ 1 }>
                <AddTagIcon
                  width="24px"
                  height="24px"
                  role="presentation"
                />
                <FormattedMessage
                  id="people.profileRow.addTag"
                  description="Label for add tag button in table row menu."
                  defaultMessage="Add tag"
                />
              </Flex>
            </MenuItem>
            <MenuItem
              onClick={ () => onActionClicked({
                type: 'remove',
                query: {
                  ids: [profileTagsAndPerson.profile.id.toString()],
                  noExternallyManaged: true,
                },
              }) }
            >
              <Flex gap={ 1 }>
                <RemoveTagIcon
                  width="24px"
                  height="24px"
                  role="presentation"
                />
                <FormattedMessage
                  id="people.profileRow.removeTag"
                  description="Label for remove tag button in table row menu."
                  defaultMessage="Remove tag"
                />
              </Flex>
            </MenuItem>
            <MenuItem
              onClick={ () => onActionClicked({
                type: 'replace',
                query: {
                  ids: [profileTagsAndPerson.profile.id.toString()],
                  noExternallyManaged: true,
                },
              }) }
            >
              <Flex gap={ 1 }>
                <ReplaceTagIcon
                  width="24px"
                  height="24px"
                  role="presentation"
                />
                <FormattedMessage
                  id="people.profileRow.replaceTag"
                  description="Label for replace tag button in table row menu."
                  defaultMessage="Replace tag"
                />
              </Flex>
            </MenuItem>
          </TableMenu>
        ) }
      </TableCell>
    </TableRow>
  );
};
