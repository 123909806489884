import styled from '@emotion/styled';

type StyledContentPreviewProps = {
  aspectRatio: number;
};

export const StyledContentPreview = styled.div<StyledContentPreviewProps>`
  position: relative;
  width: 35vh;
  margin: 0 auto;

  > div {
    padding-top: 178%;

    > div {
      position: absolute;
      width: 100%;
      top: 0;
      height: 100%;
      display: flex;

      > div {
        width: 100%;
        margin: auto;
        transition:
          padding-top 0.2s,
          margin 0.2s;
        padding-top: ${ props => Math.round(props.aspectRatio * 100) }%;
        position: relative;
        background: white;
        box-shadow: ${ props => props.theme.shadows[2] };

        > div {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }
    }
  }
`;
