import { IntlShape } from 'react-intl';

import { Condition, TagCondition } from '../../../../Audiences/Model';
import { AudienceConditionDefinition, ConditionRestriction } from '../../Model/AudienceConditionDefinition';
import { TagType } from '../../../../Tags/Model';
import { RegionConditionComponent } from '../../Component/RegionCondition/RegionConditionComponent';
import { RegionList } from '../../Component/RegionCondition/RegionList';

export class RegionConditionDefinition implements AudienceConditionDefinition<TagCondition<TagType.Region>> {
  public readonly TYPE = 'tag';
  public readonly restriction = ConditionRestriction.SOME;
  public readonly name;

  constructor(intl: IntlShape) {
    this.name = intl.formatMessage({
      description: 'Name for region condition, used in audience builder.',
      defaultMessage: 'Region',
    });
  }
  public definesCondition(condition: Condition): condition is TagCondition<TagType.Region> {
    return condition.type === this.TYPE && (condition as TagCondition).tagType === TagType.Region;
  }

  isValid(condition: TagCondition<TagType.Region>): boolean {
    return !!condition.tagIds.length;
  }

  public Component = RegionConditionComponent;

  public build = {
    Component: RegionList,
    initialise: () => ({
      type: 'tag' as const,
      tagType: TagType.Region,
      tagIds: [],
      not: false,
      new: true,
    }) as TagCondition<TagType.Region>,
  };
}
