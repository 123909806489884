import { createContext } from 'react';

import { Space } from '../../Models';
import { SpaceDeducer } from '../Utility';

export interface SpaceContextValue {
  spaceId: string;
  space: null|Space;
}

export const SpaceContext = createContext<SpaceContextValue>({
  spaceId: SpaceDeducer.deduceSpaceId(),
  space: null,
});
