import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { Button, FormControl, ListItem, ListItemText, Popover, } from '@mui/material';
import { useIntl } from 'react-intl';
import { LoadingCard } from '@ourpeople/shared/Core/Component/Feedback';

import ChevronIcon from '../../../Assets/img/icons/streamline/arrow-down-1.svg';
import { StyledList } from './styles';

type Props<T> = {
  buttonLabel: string;
  loading?: boolean;
  listAriaLabel?: string;
  items: T[];
  selectedItems: T[];
  itemRenderFn: (item: T, selected: boolean) => JSX.Element | string;
  itemCompareFn: (itemA: T, itemB: T) => boolean;
  closeOnSelect?: boolean;
  preList?: JSX.Element | string;
  postList?: JSX.Element;
  allowEmptySelection?: boolean,
}

export const Picker = <T, >({
  buttonLabel,
  loading = false,
  listAriaLabel,
  items,
  selectedItems,
  itemRenderFn,
  itemCompareFn,
  preList,
  closeOnSelect = false,
  allowEmptySelection = true,
}: PropsWithChildren<Props<T>>): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const intl = useIntl();
  const triggerButtonRef = useRef(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (closeOnSelect) {
      whenClosed();
    }
  }, [closeOnSelect, selectedItems])

  const whenButtonClicked = (): void => {
    if (triggerButtonRef.current) {
      setAnchorEl(triggerButtonRef.current);
    }
  }

  const whenClosed = (): void => {
    setAnchorEl(null);
  }

  const renderFn = (item: T): JSX.Element | string => {
    return itemRenderFn(
      item,
      !!selectedItems.find(
        (selectedItem: T) => itemCompareFn(selectedItem, item),
      ),
    );
  }

  return (
    <div
      css={  theme => ({ color: theme.new.palette.grey[600].main }) }
    >
      <FormControl variant="outlined">
        <Button
          ref={ triggerButtonRef }
          onClick={ whenButtonClicked }
          variant="outlined"
          color={ allowEmptySelection && selectedItems.length ? 'primary' : 'inherit' }
          endIcon={ <ChevronIcon css={ { width: '12px', height: '12px' } }/> }
          title={ buttonLabel }
        >
          <span css={ { fontWeight: 300, fontSize: '18px' } }>{ buttonLabel }</span>
        </Button>
        <Popover
          open={ open }
          anchorEl={ anchorEl }
          onClose={ whenClosed }
        >
          { preList }
          {
            loading
              ? <LoadingCard/>
              : (
                <StyledList dense role="listbox" aria-label={ listAriaLabel }>
                  {
                    items.length
                      ? items.map(renderFn)
                      : (
                        <ListItem>
                          <ListItemText
                            primary={ intl.formatMessage({
                              id: 'tagPicker.noResults',
                              description: 'Message when search query in tag picker returns no results.',
                              defaultMessage: 'No results found',
                            }) }
                          />
                        </ListItem>
                      )
                  }
                </StyledList>
              )
          }
        </Popover>
      </FormControl>
    </div>
  );
};
