import styled from '@emotion/styled';

import { LiveCardContainerBackground } from '../../../Content/Component';

export const StyledContentPadding = styled.div`
  padding: ${ props => props.theme.new.spacing[8] };
`;

export const StyledLiveCardContainerBackground = styled(LiveCardContainerBackground)`
  width: max-content;
`;
