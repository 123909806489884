import { FC } from 'react';
import { useIntl } from 'react-intl';
import { Tooltip } from '@mui/material';

import { StyledIntegrationProfileTooltip } from './style';
import PersonImportedIcon from '../../../../Assets/img/icons/streamline/single-neutral-actions-download.svg';

export const IntegrationProfileTooltip: FC = () => {
  const intl = useIntl();

  return (
    <StyledIntegrationProfileTooltip>
      <Tooltip
        title={ intl.formatMessage({
          id: 'people.profile.externallyManaged',
          description: 'Tooltip when hovering over icon indicating that a profile is managed by an external system.',
          defaultMessage: 'This profile is managed by an external system or integration.',
        }) }
      >
        <span><PersonImportedIcon/></span>
      </Tooltip>
    </StyledIntegrationProfileTooltip>
  );
};
