import { StorageNodeBlock as StorageNodeBlockComponent } from './StorageNodeBlock';
import { StorageNodeBlock } from '../../../Service/Blocks/StorageNodeBlockDefinition';
import { InlineFormComponent } from '../../../Model/BroadcastBlockDefinition';

export const StorageNodeBlockInlineForm: InlineFormComponent<StorageNodeBlock> = ({ activateBlock, ...props }) => (
  <StorageNodeBlockComponent
    { ...props }
    onClick={ activateBlock }
  />
);
