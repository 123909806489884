import styled from '@emotion/styled';

export const StyledBrandingSettingsSubCategoryForm = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${ props => props.theme.spacing(2) };
`;

export const StyledSettingColumn = styled.div`
  flex: 1 1 min-content;

  ${ props => props.theme.breakpoints.down('lg') } {
    flex: 1 1 auto;
  }
`;

export const StyledDeviceContainer = styled.div`
  margin: auto;
`;

export const StyledBrandPreview = styled.div`
  background-color: ${ props => props.theme.palette.background.paper };
  color: ${ props => props.theme.palette.text.primary };
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  padding-top: ${ props => props.theme.spacing(16) };
  flex-direction: column;
  gap: ${ props => props.theme.spacing(2) };
  transition: color 0.2s, background-color 0.2s;
`;

export const StyledDivider = styled.hr`
  border: none;
  border-bottom: 1px solid ${ props => props.theme.palette.text.primary };
  transition: border-color 0.2s;
  width: 40%;
`;

export const StyledPreviewHeader = styled.span`
  font-size: ${ props => props.theme.spacing(3.75) };
`;

export const StyledBrandImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: contain;
`;
