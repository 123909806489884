import { FunctionComponent, MouseEventHandler, useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { MenuItem } from '@mui/material';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';

import RightArrowIcon from '../../../../Assets/img/icons/streamline/navigation-right-circle-1.svg';
import LeftArrowIcon from '../../../../Assets/img/icons/streamline/navigation-left-circle-1.svg';
import { StyledMobileStepper, StyledStepMenu } from './style';
import { StepperItem, StepStatus } from '..';
import { Flex, IconButton, DeprecatedLink } from '../..';

type Props = {
  steps: StepperItem[];
};

export const MobileStepper: FunctionComponent<Props> = ({
  steps = [],
}) => {
  const { pathname } = useLocation();
  const [stepMenuAnchorEl, setStepMenuAnchorEl] = useState<HTMLButtonElement>();
  const activeIndex = useMemo(() => (
    steps.findIndex(step => step.link === pathname)
  ), [pathname, steps]);
  const activeStep: StepperItem | undefined = steps[activeIndex];
  const previousStep: StepperItem | undefined = steps[activeIndex - 1];
  const nextStep: StepperItem | undefined = steps[activeIndex + 1];
  const previousStepsHaveError = useMemo(() => (
    !!steps.slice(0, activeIndex).find(step => step.error === true)
  ), [activeIndex, steps]);
  const nextStepsHaveError = useMemo(() => (
    !!steps.slice(activeIndex + 1, steps.length).find(step => step.error === true)
  ), [activeIndex, steps]);

  const previousButton = useMemo(() => (
    <IconButton
      color="inherit"
      disabled={ !!previousStep?.disabled }
      IconComponent={ LeftArrowIcon }
      label={ previousStep?.title || '' }
      size="small"
    />
  ), [previousStep?.disabled, previousStep?.title]);

  const nextButton = useMemo(() => (
    <IconButton
      color="inherit"
      disabled={ !!nextStep?.disabled }
      IconComponent={ RightArrowIcon }
      label={ nextStep?.title || '' }
      size="small"
    />
  ), [nextStep?.disabled, nextStep?.title]);

  const whenActiveStepClicked: MouseEventHandler<HTMLButtonElement> = event => {
    setStepMenuAnchorEl(event.currentTarget);
  };

  const whenStepMenuClosed = () => {
    setStepMenuAnchorEl(undefined);
  };

  const renderStep = useCallback((step: StepperItem, index: number) => (
    <MenuItem
      onClick={ whenStepMenuClosed }
      disabled={ step.disabled }
    >
      <StepStatus
        active={ index === activeIndex }
        error={ step.error }
      >
        { step.title }
      </StepStatus>
    </MenuItem>
  ), [activeIndex]);

  return (
    <StyledMobileStepper>
      <StepStatus
        active={ !previousStep?.disabled }
        error={ previousStepsHaveError }
      >
        { previousStep && (
          previousStep.disabled
            ? previousButton
            : (
              <DeprecatedLink to={ previousStep.link }>
                { previousButton }
              </DeprecatedLink>
            )
        ) }
      </StepStatus>
      <Flex
        direction="column"
        align="center"
      >
        { activeStep && (
          <StepStatus
            key={ activeStep.link }
            active={ true }
            error={ activeStep.error }
          >
            <Button
              id="step-menu-button"
              color="inherit"
              aria-controls="step-menu"
              aria-haspopup="true"
              aria-expanded={ stepMenuAnchorEl ? 'true' : undefined }
              onClick={ whenActiveStepClicked }
            >
              { activeStep.title }
            </Button>
          </StepStatus>
        ) }
        <StyledStepMenu
          id="step-menu"
          anchorEl={ stepMenuAnchorEl }
          open={ !!stepMenuAnchorEl }
          onClose={ whenStepMenuClosed }
          anchorOrigin={ {
            horizontal: 'center',
            vertical: 'top',
          } }
          transformOrigin={ {
            horizontal: 'center',
            vertical: 'top',
          } }
          MenuListProps={ {
            'aria-labelledby': 'step-menu-button',
          } }
        >
          { steps.map((step, index) => (
            <div key={ step.link }>
              {
                step.disabled
                  ? renderStep(step, index)
                  : (
                    <DeprecatedLink to={ step.link }>
                      { renderStep(step, index) }
                    </DeprecatedLink>
                  )
              }
            </div>
          )) }
        </StyledStepMenu>
      </Flex>
      <StepStatus
        active={ !nextStep?.disabled }
        error={ nextStepsHaveError }
      >
        { nextStep && (
          nextStep.disabled
            ? nextButton
            : (
              <DeprecatedLink to={ nextStep.link }>
                { nextButton }
              </DeprecatedLink>
            )
        ) }
      </StepStatus>
    </StyledMobileStepper>
  );
};
