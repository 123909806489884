import { FunctionComponent, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { AutocompleteCommonProps } from '@ourpeople/shared/Core/Component/Input/Autocomplete/Autocomplete';
import { LocalisedString } from 'op-storybook/lib/model/LocalisedString/LocalisedString';

import { PersonStatus } from '../../../Models';
import { useLocalisedStatuses } from '../../Hook/useLocalisedStatuses';
import { BasicAutocomplete } from '../../../Common/Component';

interface Props extends AutocompleteCommonProps {
  selectedIds: PersonStatus[];
  allowedIds?: PersonStatus[];
  onSelectionChanged: (selection: LocalisedString<PersonStatus>[]) => void;
}

export const PersonStatusAutocomplete: FunctionComponent<Props> = ({
  selectedIds,
  allowedIds = [],
  onSelectionChanged,
  ...props
}) => {
  const intl = useIntl();
  const statuses = useLocalisedStatuses();
  const allowedStatuses = useMemo(
    () => allowedIds.length ? statuses.filter(status => allowedIds.includes(status.id)) : statuses,
    [statuses, allowedIds],
  );

  return <BasicAutocomplete<PersonStatus>
    selectedIds={ selectedIds }
    onSelectionChanged={ onSelectionChanged }
    options={ allowedStatuses }
    label={
      props.label !== undefined
        ? props.label
        : intl.formatMessage(
          {
            id: 'statusAutocomplete.label',
            defaultMessage: 'Select {limit, plural, one {status} other {statuses}}'
          },
          {
            limit: props.multiple ? Infinity : 1,
          }
        )
    }
    placeholder={
      props.placeholder ||
      intl.formatMessage(
        {
          id: 'statusAutocomplete.placeholder',
          defaultMessage: 'Search for {limit, plural, one {status} other {status}}'
        },
        {
          limit: props.multiple ? Infinity : 1,
        }
      )
    }
    { ...props }
  />;
};
