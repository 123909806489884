import { FC, PropsWithChildren } from 'react';

export const AbsoluteBackButtonContainer: FC<PropsWithChildren> = ({ children }) => (
  <div
    css={ theme => ({
      position: 'absolute',
      top: theme.new.spacing[2],
      left: theme.new.spacing[2],
    }) }
  >
    { children }
  </div>
);
