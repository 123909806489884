import { ComponentProps, FC } from 'react';

import { StyledTextFieldTrailingButton } from './style';
import { Typography } from '../../../stories/components/Typography/Typography';

export const TextFieldTrailingButton: FC<ComponentProps<'button'>> = ({
  children,
  ...props
}) => (
  <StyledTextFieldTrailingButton
    type="button"
    { ...props }
  >
    <Typography
      { ...props.disabled
        ? { palette: { colour: 'grey', intensity: 300 } }
        : {}
      }
    >
      { children }
    </Typography>
  </StyledTextFieldTrailingButton >
);
