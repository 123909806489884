import React, { FC, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { GenericConfirmNavigationDialog, PageHeader } from '../../../Common/Component';
import { Padded } from '../../../Components';
import { BroadcastEditForm, BroadcastTemplateDialog } from '../../Component';
import { DeprecatedBroadcast, DraftBroadcast } from '../../Model';
import { useEnvironmentSettings, useLocalStorageHookContext, } from '../../../Common/Hook';
import { EditorContent } from '../../../Content/Model';
import { AudienceCreator } from '../../../Audiences/Utility';
import { BroadcastNotificationSettingsFactory } from '../../Utility';
import { DurationParser } from '../../../Utility/DurationParser';
import { Schedule } from '../../Model/BroadcastSchedule';

type State = {
  hideTemplates?: boolean;
};

export const CreateBroadcastPage: FC = () => {
  const intl = useIntl();
  const history = useHistory<State>();
  const [touched, setTouched] = useState<boolean>(false);
  const getStorageHook = useLocalStorageHookContext();
  const spaceSettings = useEnvironmentSettings();
  const useShowModalWhenCreating = getStorageHook<boolean>('broadcastTemplates.showModalWhenCreating', false);
  const [showModalWhenCreating] = useShowModalWhenCreating();
  const [templateSelectDialogOpen, setTemplateSelectDialogOpen] = useState<boolean>(!history.location.state?.hideTemplates && showModalWhenCreating);
  const [broadcast, setBroadcast] = useState<DraftBroadcast>({
    name: '',
    recurrenceRule: null,
    sendNudge: !!spaceSettings.broadcastNudgesDefaultSend,
    sendPush: true,
    nudgeDelay: spaceSettings.broadcastNudgesDefaultSend && spaceSettings.broadcastNudgesDefaultDelay
      ? DurationParser.durationInSeconds(spaceSettings.broadcastNudgesDefaultDelay)
      : null,
    content: [],
    tags: [],
    recipientAudience: AudienceCreator.createBlankAudience(),
    notification: BroadcastNotificationSettingsFactory.fromSpaceSettings(spaceSettings),
  });
  const [schedule, setSchedule] = useState<Schedule>({
    localStartDate: null,
    recurrence: null,
  });
  const [editorContents, setEditorContents] = useState<EditorContent[]>([]);

  const navigateToEditPage = useCallback((broadcastId: string) => (
    history.replace(history.location.pathname.replace('create', `${ broadcastId }/edit`))
  ), [history]);

  const whenBroadcastSaved = useCallback((broadcast: DeprecatedBroadcast) => {
    setTouched(false);
    setTimeout(() => navigateToEditPage(broadcast.id));
  }, [navigateToEditPage]);

  const whenPostSaveError = useCallback((error: unknown, broadcastId: string) => {
    navigateToEditPage(broadcastId);
    throw error;
  }, [navigateToEditPage]);

  const whenEditorContentsChanged = useCallback(
    (callbackOrContents: ((editorContents: EditorContent[]) => EditorContent[]) | EditorContent[]) => {
      setEditorContents(callbackOrContents);
      setTouched(true);
    },
    [],
  );

  const whenBroadcastChanged = useCallback(
    (callbackOrBroadcast: ((broadcast: DraftBroadcast) => DraftBroadcast) | DraftBroadcast) => {
      setBroadcast(callbackOrBroadcast);
      setTouched(true);
    },
    [],
  );

  return <>
    <Padded>
      <PageHeader
        items={ [
          {
            link: '/broadcasts',
            title: intl.formatMessage({
              id: 'section.broadcasts',
              description: 'Heading label for Broadcasts',
              defaultMessage: 'Broadcasts',
            })
          },
          {
            title: intl.formatMessage({
              id: 'people.broadcasts.create',
              description: 'Title displayed over the create broadcast page',
              defaultMessage: 'Create',
            }),
          }
        ] }
      />
      <BroadcastEditForm
        broadcast={ broadcast }
        editorContents={ editorContents }
        setBroadcast={ whenBroadcastChanged }
        setEditorContents={ whenEditorContentsChanged }
        saveEndpoint="broadcasts"
        onPostSaveError={ whenPostSaveError }
        onSaveComplete={ whenBroadcastSaved }
        onPublished={ () => setTouched(false) }
        schedule={ schedule }
        setSchedule={ setSchedule }
      />
    </Padded>
    <BroadcastTemplateDialog
      open={ templateSelectDialogOpen }
      onClose={ () => setTemplateSelectDialogOpen(false) }
    />
    <GenericConfirmNavigationDialog
      active={ touched }
      ignorePattern={ /^\/broadcasts\/create/ }
    />
  </>;
};
