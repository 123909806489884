import { FunctionComponent, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { ContentWithEvent } from '../../../../../Models';
import { ContentReader } from '../../../../../Readers';
import { CardReport, RespondedEventCount } from '../../Hooks/useFetchBroadcastReportSummary';
import { useNotRespondedLabelForContentType } from '../../Hooks/useNotRespondedLabelForContentType';
import { DeliveryReportTab, DeliveryReportTabs, DeliveryReportTabContainer, DeliveryReportTabContent } from '.';
import { NotRespondedTable } from '../Tables/NotRespondedTable';
import { RespondedType } from '../Tables/RespondedTable';
import { EventRespondedTable } from '../Tables/EventRespondedTable';
import { TrainingAttendedTable } from '../Tables/TrainingAttendedTable';
interface Props {
  broadcastId: string;
  deliveryId: string;
  card: CardReport;
  pendingCount: number;
  initialSearch?: string;
}

export const EventContentReportTabs: FunctionComponent<Props> = ({
  card,
  broadcastId,
  deliveryId,
  pendingCount,
  initialSearch,
}) => {
  const intl = useIntl();
  const eventResponseTypes: {key: RespondedType, label: string}[] = useMemo(() => ([
    {
      key: 'attended',
      label: intl.formatMessage({
        id: 'responded.attended',
        defaultMessage: 'Attended',
      }),
    },
    {
      key: 'attending',
      label: intl.formatMessage({
        id: 'responded.attending',
        defaultMessage: 'Attending',
      }),
    },
    {
      key: 'waiting',
      label: intl.formatMessage({
        id: 'responded.waiting',
        defaultMessage: 'Waiting',
      }),
    },
    {
      key: 'declined',
      label: intl.formatMessage({
        id: 'responded.declined',
        defaultMessage: 'Declined',
      }),
    },
  ]), [intl]);
  return (
    <DeliveryReportTabContainer
      tabs={
        <DeliveryReportTabs>
          {
            eventResponseTypes.map((tab) => (
              <DeliveryReportTab
                key={`tabEventOption${card.content.id || ''}_${tab.key}`}
                label={tab.label}
                value={(card.counts.responded as RespondedEventCount)[tab.key]}
              />
            ))
          }
          <DeliveryReportTab
            label={useNotRespondedLabelForContentType(
              ContentReader.contentTypeFromString(card.content.type)
            )}
            value={(card.counts.notResponded?.total || 0) - pendingCount}
            lowLight={true}
          />
        </DeliveryReportTabs>
      }>
      {
        eventResponseTypes.map((tab) => (
          <DeliveryReportTabContent key={`tableOption${card.content.id || ''}_${tab.key}`}>
            {
              tab.key === 'attended' && ContentReader.contentType(card.content) === 'training'
              ? (
                <TrainingAttendedTable
                  deliveryId={deliveryId}
                  broadcastId={broadcastId}
                  content={card.content as ContentWithEvent}
                  initialSearch={ initialSearch }
                />
              )
              : (
                <EventRespondedTable
                  deliveryId={deliveryId}
                  broadcastId={broadcastId}
                  content={card.content as ContentWithEvent}
                  respondedType={tab.key}
                  initialSearch={ initialSearch }
               />
              )
            }
          </DeliveryReportTabContent>
        ))
      }
      <DeliveryReportTabContent>
        <NotRespondedTable
          deliveryId={deliveryId}
          broadcastId={broadcastId}
          contentId={card.content.id}
          responseType="notResponded"
          initialSearch={ initialSearch }
        />
      </DeliveryReportTabContent>
    </DeliveryReportTabContainer>
  );
}
