import { MappedSettingTypes } from '../../Types';

type Props = {
  value: MappedSettingTypes['link']['value'],
};

export const LinkSettingValue = ({
  value,
}: Props): JSX.Element => {
  return (
    <>
      {
        value !== null
          && (
            <a
              href={value.url || ''}
              rel="noreferrer"
              target="_blank"
            >
              {value.label || ''}
            </a>
        )
      }
    </>
  );
};
