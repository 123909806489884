import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedList, FormattedMessage, useIntl } from 'react-intl';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';
import { CenteredGenericErrorMessage, CenteredGenericLoadingMessage } from '@ourpeople/shared/Core/Component/Content';

import {
  StyledDraggableNavItem,
  StyledEmptySlotLabel,
  StyledForm,
  StyledIndexColumn,
  StyledInput,
  StyledInputColumn,
  StyledPreviewContainer,
  StyledTabBarContainer,
} from './style';
import { NavigationItemConfiguration, useAppNavigationItems, useFetchAppNavigationSettings } from '../../Hook';
import { Flex, FlexPullRight, VerticallySpaced } from '../../../Common/Component';
import DragIcon from '../../../Assets/img/icons/streamline/move-expand-vertical.svg';
import { useApi, useContextOrThrow, useLoggedInAuthDescription } from '../../../Core/Hook';
import { useMounted } from '../../../Common/Hook';
import { MainMenu, TabBar } from '../AppMenuPreview';
import { ConfiguredNavigationItem, DisabledBehaviour, NavigationItem } from '../../Model';
import { DeviceOutline } from '../DeviceOutline/DeviceOutline';
import { MenuDrawer } from '../MenuDrawer/MenuDrawer';
import { ArrayHelper } from '../../../Common/Utility';
import { ToastContext } from '../../../Core/Context';

export const AppNavigationSettingsTab: FC = () => {
  const api = useApi();
  const { env_settings: { name: spaceName, smallLogoUrl }, user } = useLoggedInAuthDescription();
  const intl = useIntl();
  const emptySlotLabel = intl.formatMessage({
    description: 'Label for an empty slot in the app navigation settings, when a feature is disabled',
    defaultMessage: 'Empty slot',
  });
  const mounted = useMounted();
  const [saving, setSaving] = useState<boolean>(false);
  const { addSuccessToast, addErrorToast } = useContextOrThrow(ToastContext);
  const [fetchAppNavigationSettingsResult, , reloadAppNavigationSettings] = useFetchAppNavigationSettings();
  const navigationItems = useAppNavigationItems();
  const [configurations, setConfigurations] = useState<NavigationItemConfiguration[]>([]);
  const configuredItems = useMemo<ConfiguredNavigationItem[]>(() => (
    configureNavigationItems(
      navigationItems,
      configurations,
    )
  ), [configurations, navigationItems]);
  const defaultConfiguredItems = useMemo(() => (
    configureNavigationItems(
      navigationItems,
      configurations,
      true,
    )
  ), [configurations, navigationItems]);
  const configuredTabBarItems = useMemo(() => (
    configuredItems
      .filter(configuredItem => (
        configuredItem.disabledBehaviour !== DisabledBehaviour.HIDE || !configuredItem.disabled
      ))
      .slice(0, 5) || []
  ), [configuredItems]);
  const configuredMenuItems = useMemo(() => (
    configuredItems
      .filter(configuredItem => (
        configuredItem.disabledBehaviour !== DisabledBehaviour.HIDE || !configuredItem.disabled
      ))
      .slice(5) || []
  ), [configuredItems]);
  const unlockedConfigurations = useMemo(() => (
    configuredItems.reduce<NavigationItemConfiguration[]>(
      (unlockedItemConfigurations, configuredItem) => {
        const configuration = configurations.find(
          configuration => configuration.ref === configuredItem.ref && !configuration.locked
        );
        return configuration ? unlockedItemConfigurations.concat(configuration) : unlockedItemConfigurations;
      },
      [],
    )
  ), [configurations, configuredItems]);

  useEffect(() => {
    if (!fetchAppNavigationSettingsResult?.content?.items) {
      return;
    }

    setConfigurations(fetchAppNavigationSettingsResult.content.items);
  }, [fetchAppNavigationSettingsResult?.content?.items]);

  const getData = useCallback((configuration: NavigationItemConfiguration): NavigationItem | undefined => (
    navigationItems.find(appNavigationItem => appNavigationItem.ref === configuration.ref)
  ), [navigationItems]);

  const getLabel = useCallback((configuration: NavigationItemConfiguration): string => {
    const data = getData(configuration);
    return data ? data.label : configuration.ref;
  }, [getData]);

  const whenDragEnd = useCallback((result: DropResult) => {
    const unlockedConfigurationPositions = unlockedConfigurations.map(configuration => configuration.position);
    const positionedUnlockedConfigurations = ArrayHelper.move(
      unlockedConfigurations,
      result.source.index,
      result.destination?.index || 0,
    ).map((unlockedItem, index) => ({
      ...unlockedItem,
      position: unlockedConfigurationPositions[index],
    }));

    setConfigurations(
      configurations => (
        configurations.map(
          configuration => {
            const updatedConfiguration = positionedUnlockedConfigurations.find(
              positionedUnlockedConfiguration => positionedUnlockedConfiguration.position === configuration.position
            );

            return updatedConfiguration || configuration;
          }
        ).sort()
      )
    )
  }, [unlockedConfigurations]);

  const resetToDefaults = useCallback(() => {
    setConfigurations(configurations => (
      configurations.map(configuration => ({
        ...configuration,
        position: configuration.defaultPosition,
      }))
    ));
  }, []);

  const save = useCallback(() => {
    setSaving(true);
    api.post('/settings/app-navigation', {
      items: configurations,
    })
      .then(() => {
        if (!mounted.current) {
          return;
        }

        setSaving(false);
        addSuccessToast(
          intl.formatMessage({
            description: 'Success message when saving app navigation settings.',
            defaultMessage: 'Settings saved.'
          })
        );
      })
      .catch(() => {
        if (!mounted.current) {
          return;
        }

        setSaving(false);
        addErrorToast(
          intl.formatMessage({
            description: 'Error message when failing to save app navigation settings.',
            defaultMessage: 'Settings could not be saved.'
          })
        );
      });
  }, [addErrorToast, addSuccessToast, api, configurations, intl, mounted]);

  return (
    fetchAppNavigationSettingsResult?.content
      ? (
        <VerticallySpaced gap={ 2 }>
          <Flex
            gap={ 2 }
            align="flex-start"
          >
            <StyledForm>
              <FormattedMessage
                description="Help text for drag and drop inputs on app navigation settings tab."
                defaultMessage="Customise which features are displayed in the bottom navigation of your OurPeople app."
              />
              <StyledInput>
                <StyledIndexColumn>
                  { unlockedConfigurations.map((unlockedConfiguration, index) => (
                    <div key={ unlockedConfiguration.position }>
                      <div>{ index + 1 }</div>
                    </div>
                  )) }
                </StyledIndexColumn>
                <DragDropContext
                  onDragEnd={ whenDragEnd }
                >
                  <Droppable
                    droppableId="navItems"
                  >
                    { droppable => (
                      <StyledInputColumn ref={ droppable.innerRef }>
                        { unlockedConfigurations.map((navigationConfiguration, index) => (
                          <Draggable
                            key={ navigationConfiguration.position }
                            draggableId={ `${ navigationConfiguration.position }` }
                            index={ index }
                          >
                            { (draggable, snapshot) => (
                              <StyledDraggableNavItem
                                ref={ draggable.innerRef }
                                { ...draggable.draggableProps }
                                { ...draggable.dragHandleProps }
                                dragging={ snapshot.isDragging && !snapshot.isDropAnimating }
                              >
                                {
                                  navigationConfiguration.disabled
                                    ? (
                                      <StyledEmptySlotLabel>
                                        { emptySlotLabel }
                                      </StyledEmptySlotLabel>
                                    )
                                    : getLabel(navigationConfiguration) }
                                <DragIcon/>
                              </StyledDraggableNavItem>
                            ) }
                          </Draggable>
                        )) }
                        { droppable.placeholder }
                      </StyledInputColumn>
                    ) }
                  </Droppable>
                </DragDropContext>
              </StyledInput>
              <Button
                onClick={ resetToDefaults }
              >
                <FormattedMessage
                  description="Label for reset button for app navigation settings."
                  defaultMessage="Reset to defaults"
                />
              </Button>
              <span>
                <FormattedList
                  value={
                    defaultConfiguredItems
                      .filter(configuredItem => !configurations.find(configuration => configuredItem.ref === configuration.ref)?.locked)
                      .map(configuredItem => (
                        configuredItem.disabled
                          ? <StyledEmptySlotLabel>{ emptySlotLabel }</StyledEmptySlotLabel>
                          : configuredItem.label
                      ))
                  }
                />
              </span>
            </StyledForm>
            <StyledPreviewContainer>
              <DeviceOutline>
                <StyledTabBarContainer>
                  <TabBar
                    navigationItems={ configuredTabBarItems }
                  />
                </StyledTabBarContainer>
              </DeviceOutline>
              <DeviceOutline>
                <StyledTabBarContainer>
                  <TabBar navigationItems={ configuredTabBarItems }/>
                </StyledTabBarContainer>
                <MenuDrawer>
                  <MainMenu
                    personUuid={ user.uuid }
                    spaceName={ spaceName || '' }
                    userName={ `${ (user.forename || '').trim() } ${ (user.lastname || '').trim() }`.trim() }
                    navigationItems={ configuredMenuItems }
                    spaceLogoSrc={ smallLogoUrl || '' }
                    onEditProfileClicked={ () => null }
                    onSnoozeNotificationsClicked={ () => null }
                    onSupportClicked={ () => null }
                    onSettingsClicked={ () => null }
                    onOpenAccountMenuClicked={ () => null }
                    userHasAvatar={ !!user.avatarUrl }
                  />
                </MenuDrawer>
              </DeviceOutline>
            </StyledPreviewContainer>
          </Flex>
          <Flex>
            <FlexPullRight>
              <Button
                variant="primary"
                onClick={ save }
                busy={ saving }
              >
                <FormattedMessage
                  description="Label for save button for app navigation settings."
                  defaultMessage="Save"
                />
              </Button>
            </FlexPullRight>
          </Flex>
        </VerticallySpaced>
      )
      : fetchAppNavigationSettingsResult?.error
        ? <CenteredGenericErrorMessage onRetryClicked={ reloadAppNavigationSettings }/>
        : <CenteredGenericLoadingMessage/>
  );
};

const configureNavigationItems = (
  navigationItems: NavigationItem[],
  navigationItemConfigurations: NavigationItemConfiguration[],
  useDefaults: boolean = false,
): ConfiguredNavigationItem[] => (
  navigationItems.sort((navigationItemA, navigationItemB) => {
    const navigationItemAPosition = navigationItemConfigurations.find(
      navigationItemConfiguration => navigationItemConfiguration.ref === navigationItemA.ref,
    )?.[useDefaults ? 'defaultPosition' : 'position'] || -1;
    const navigationItemBPosition = navigationItemConfigurations.find(
      navigationItemConfiguration => navigationItemConfiguration.ref === navigationItemB.ref,
    )?.[useDefaults ? 'defaultPosition' : 'position'] || -1;

    return navigationItemAPosition === navigationItemBPosition
      ? 0
      : navigationItemAPosition > navigationItemBPosition
        ? 1
        : -1;
  })
    .map(navigationItem => {
      const configuration = navigationItemConfigurations.find(
        navigationItemConfiguration => navigationItemConfiguration.ref === navigationItem.ref,
      );
      const unlockedConfigurations = navigationItemConfigurations.filter(
        configuration => !configuration.locked
      );
      const unlockedIndex = unlockedConfigurations.findIndex(unlockedConfiguration => unlockedConfiguration.ref === configuration?.ref);
      return ({
        ...navigationItem,
        disabled: !!configuration?.disabled,
        unlockedIndex: configuration?.locked ? null : unlockedIndex === -1 ? null : unlockedIndex + 1,
      });
    })
);
