import { BreakpointContext } from 'op-storybook/lib/providers/BreakpointProvider/BreakpointProvider';

import { useContextOrThrow } from '../../Core/Hook';

export const useResponsiveSpacing = (): 1 | 2 | 4 => {
  const screenWidth = useContextOrThrow(BreakpointContext);
  return screenWidth.lessThan.xs
    ? 1
    : screenWidth.lessThan.md
      ? 2
      : 4;
};
