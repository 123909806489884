import styled from '@emotion/styled';

export const StyledOptions = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: ${ props => props.theme.spacing(2) };
`;

export const StyledOption = styled.li`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: ${ props => props.theme.spacing(1) };
`;

export const StyledOptionIndex = styled.div`
  padding-top: ${ props => props.theme.spacing(3) };

  > div {
    background-color: ${ props => props.theme.palette.secondary.main };
    color: ${ props => props.theme.palette.secondary.contrastText };
    border-radius: ${ props => props.theme.spacing(0.5) };
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.75rem;
    width: ${ props => props.theme.spacing(2.5) };
    height: ${ props => props.theme.spacing(2.5) };
  }
`;
