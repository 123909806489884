import { ChangeEvent, FunctionComponent, ReactNode } from 'react';

import { Switch } from '../../../Components/Input';
import { StyledFormControlLabel } from './style';

interface Props {
  id: string;
  label: ReactNode;
  onChange: (checked: boolean) => void;
  checked: boolean;
}

export const LabelledSwitch: FunctionComponent<Props> = ({
  id,
  label,
  checked,
  onChange,
}) => {
  const whenChanged = (event: ChangeEvent<HTMLInputElement>, checked: boolean): void => {
    onChange(checked);
  };

  return (
    <StyledFormControlLabel
      control={
        <Switch
          id={id}
          checked={ checked }
          onChange={ whenChanged }
        />
      }
      label={ label }
      labelPlacement="top"
    />
  )
};
