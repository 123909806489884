import { IntlShape } from 'react-intl';

import {
  DraftNumberRequest,
  NUMBER_REQUEST_MAX_DEFAULT,
  NUMBER_REQUEST_MIN_DEFAULT,
  NumberRequest
} from '../../Model/ContentTypes';
import { NumberRequestFields, NumberRequestPreview } from '../../Component/Content';
import { NumberRequestValidator } from '../../Utility/Validation';
import { SingleContentCard, EditorContent } from '../../../Content/Model';
import NumberRequestIcon from '../../../Assets/img/icons/monochrome/content/text.svg';
import { UniqueIdGenerator } from '../../../Common/Utility';
import { CardFactory, CardTransformer } from '../../../Content/Utility';
import { DraftResponse, FormContentDefinition, Response } from '../../Model';
import { EnvironmentSettings } from '../../../Models';
import { LiveNumberRequest } from '../../Component';
import { SubmittedNumberRequest } from '../../Component/SubmittedContent';

export type NumberRequestEditorContent = EditorContent<DraftNumberRequest>;

type BaseNumberResponse = {
  contentType: 'numberRequest';
  number: number;
};
export type DraftNumberResponse = DraftResponse<BaseNumberResponse>;
export type NumberResponse = Response<BaseNumberResponse>;

export class NumberRequestDefinition implements FormContentDefinition<
  NumberRequest,
  NumberRequestEditorContent,
  DraftNumberResponse,
  NumberResponse
> {
  public readonly id = 'numberRequest';
  public readonly availableToCreate = true;
  public readonly new = false;
  public readonly richText = false;
  public readonly contentTypes = ['numberRequest'];
  public readonly EditorComponent = NumberRequestFields;
  public readonly PreviewComponent = NumberRequestPreview;
  public readonly LiveComponent = LiveNumberRequest;
  public readonly SubmittedComponent = SubmittedNumberRequest;
  public readonly IconComponent = NumberRequestIcon;
  public readonly categoryId = 'input';

  public definesContentType = (type: string): boolean => (
    this.contentTypes.includes(type)
  );

  public createEditorContent = (): NumberRequestEditorContent => ({
    id: UniqueIdGenerator.generate(),
    card: CardFactory.fromDraftContent({
      type: 'numberRequest',
      title: '',
      text: null,
      precision: 3,
      step: 1,
      min: NUMBER_REQUEST_MIN_DEFAULT,
      max: NUMBER_REQUEST_MAX_DEFAULT,
    }),
  });

  public getContentTitle = (intl: IntlShape, content: NumberRequest): string => (
    content.title || this.getDefaultTitle(intl)
  );

  public getDraftContentTitle = (intl: IntlShape, draftContent: DraftNumberRequest): string => (
    draftContent.title || this.getDefaultTitle(intl)
  );

  public hasRequiredFeatureFlags = (environmentSettings: EnvironmentSettings): boolean => (
    !!environmentSettings.formsEnabled
  );

  public getLocalisedType = (intl: IntlShape): string => intl.formatMessage({
    id: 'forms.content.numberRequest.type',
    description: 'Localised name for number request type.',
    defaultMessage: 'Number',
  });

  public transformCard = (card: SingleContentCard<NumberRequest>): NumberRequestEditorContent => ({
    id: card.id,
    card: CardTransformer.transformCard(card, card.content),
  });

  public validate = NumberRequestValidator.validate;

  private getDefaultTitle = (intl: IntlShape): string => intl.formatMessage({
    id: 'forms.content.numberRequest.defaultTitle',
    description: 'Default title for number request.',
    defaultMessage: 'Untitled number',
  });
}
