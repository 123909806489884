import { FunctionComponent, useMemo } from 'react';

import { Content, ContentWithMedia, ContentWithUpload, RequestState } from '../../../../../Models';
import { ContentReader } from '../../../../../Readers';
import { LoadingSpinner } from '../../../../../Components';
import { StyledImageContentPreview, StyledImageContentCaption, StyledImageContentLoading, StyledImageContentText, StyledTextContentPreview } from './styles';
import { SafeRichTextContent } from '../../../../../Common/Component/SafeRichTextContent/SafeRichTextContent';
import { useFetchUploadUrls } from '../../../../../Common/Hook';

interface Props {
  content: Content;
}

export const ImageContentPreview: FunctionComponent<Props> = ({
  content
}) => {
  const [imageResult, requestState] = useFetchUploadUrls(
    content.type === 'styled_image'
      ? (content as ContentWithUpload).upload.id
      : (content as ContentWithMedia).media.id
  );
  const caption = useMemo(() => (
    ContentReader.titleForContent(content) || ''
  ), [content]);
  const captionSize = useMemo(() => {
    const length = caption.length;
    if (length < 20) {
      return 'large';
    }
    if (length < 50) {
      return 'medium';
    }
    return 'small';
  }, [caption]);

  return requestState === RequestState.COMPLETE
  ? (
    <>
      <StyledImageContentPreview id={`content${content.id || ''}`} imageUrl={imageResult?.content?.original || ''}>
        {
          caption && (
            <StyledImageContentCaption size={captionSize}>
              {caption}
            </StyledImageContentCaption>
          )
        }
      </StyledImageContentPreview>
      {
        content.text && (
          <StyledImageContentText>
            <StyledTextContentPreview>
              <SafeRichTextContent
                placeholderBehaviour="badge"
                text={ content.text }
              />
            </StyledTextContentPreview>
          </StyledImageContentText>
        )
      }
    </>
  )
  : <StyledImageContentLoading id={`content${content.id || ''}`}><LoadingSpinner /></StyledImageContentLoading>
};

