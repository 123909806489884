import { IntlShape } from 'react-intl';

import TextContentIcon from '../../../Assets/img/icons/monochrome/content/text.svg';
import { TextContentFields, TextContentPreview } from '../../Component/Content';
import { DraftTextContent, TextContent } from '../../Model/ContentTypes';
import { TextContentValidator } from '../../Utility/Validation';
import { SingleContentCard, EditorContent } from '../../../Content/Model';
import { UniqueIdGenerator } from '../../../Common/Utility';
import { CardFactory, CardTransformer } from '../../../Content/Utility';
import { FormContentDefinition } from '../../Model';
import { EnvironmentSettings } from '../../../Models';
import { LiveTextContent } from '../../Component';
import { SubmittedTextContent } from '../../Component/SubmittedContent';

export type TextContentEditorContent = EditorContent<DraftTextContent>;

export class TextContentDefinition implements FormContentDefinition<TextContent, TextContentEditorContent> {
  public readonly id = 'textContent';
  public readonly availableToCreate = true;
  public readonly new = false;
  public readonly richText = true;
  public readonly contentTypes = ['textContent'];
  public readonly EditorComponent = TextContentFields;
  public readonly PreviewComponent = TextContentPreview;
  public readonly LiveComponent = LiveTextContent;
  public readonly SubmittedComponent = SubmittedTextContent;
  public readonly IconComponent = TextContentIcon;
  public readonly categoryId = 'content';

  public definesContentType = (type: string): boolean => (
    this.contentTypes.includes(type)
  );

  public createEditorContent = (): TextContentEditorContent => ({
    id: UniqueIdGenerator.generate(),
    card: CardFactory.fromDraftContent({
      type: 'textContent',
      title: '',
      html: '',
    }),
  });

  public getContentTitle = (intl: IntlShape, content: TextContent): string => (
    content.title || this.getDefaultTitle(intl)
  );

  public getDraftContentTitle = (intl: IntlShape, draftContent: DraftTextContent): string => (
    draftContent.title || this.getDefaultTitle(intl)
  );

  public hasRequiredFeatureFlags = (environmentSettings: EnvironmentSettings): boolean => (
    !!environmentSettings.formsEnabled
  );

  public getLocalisedType = (intl: IntlShape): string => intl.formatMessage({
    id: 'forms.content.TextContent.type',
    description: 'Localised name for text content type.',
    defaultMessage: 'Text',
  });

  public transformCard = (card: SingleContentCard<TextContent>): TextContentEditorContent => ({
    id: card.id,
    card: CardTransformer.transformCard(card, card.content),
  });

  public validate = TextContentValidator.validate;

  private getDefaultTitle = (intl: IntlShape): string => intl.formatMessage({
    id: 'forms.content.TextContent.defaultTitle',
    description: 'Default title for content request.',
    defaultMessage: 'Untitled text',
  });
}
