import { FC } from 'react';
import { TooltipProps } from '@mui/material';

import HelpIcon from '../../../Assets/img/icons/monochrome/help.svg';
import { DeprecatedTooltip } from '../Tooltip/DeprecatedTooltip';

export const HelpTooltip: FC<Omit<TooltipProps, 'children'>> = props => (
  <DeprecatedTooltip
    arrow
    placement="top"
    { ...props }
  >
    <span><HelpIcon/></span>
  </DeprecatedTooltip>
);
