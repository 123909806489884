import { useIntl } from 'react-intl';
import { useMemo } from 'react';
import { fromUnixTime } from 'date-fns';
import {
  ValidationErrorHandler,
  ValidationErrorHandlerFactory
} from 'op-storybook/lib/utility/ValidationErrorHandlerFactory/ValidationErrorHandlerFactory';

import { FileSizeFormatter } from '../Model/FileSizeFormatter';
import { ValidationErrorIdentifier } from '../Utility/ValidationErrorIdentifier';
import { InvalidArgumentError, NotAllowedError, NotUniqueError, ValidationError } from '../Model/ValidationError';

export const useDefaultValidationErrorHandlers = (
  subjectName: string,
  selectionSingular?: string,
  selectionPlural?: string,
): ValidationErrorHandler<any>[] => { // eslint-disable-line @typescript-eslint/no-explicit-any
  const intl = useIntl();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useMemo<ValidationErrorHandler<any>[]>(() => [
    ValidationErrorHandlerFactory.createHandler(
      ValidationErrorIdentifier.isBlankError,
      () => intl.formatMessage({
        description: 'Validation message when a mandatory field is left blank',
        defaultMessage: '{subjectName} can not be blank.',
      }, {
        subjectName,
      }),
    ),
    ValidationErrorHandlerFactory.createHandler(
      ValidationErrorIdentifier.isNotUtf8Error,
      () => intl.formatMessage({
        description: 'Validation message when field contains UTF-8 substitution character.',
        defaultMessage: '{ subjectName } contains invalid characters.',
      }, {
        subjectName,
      }),
    ),
    ValidationErrorHandlerFactory.createHandler(
      ValidationErrorIdentifier.isFileTooLargeError,
      error => intl.formatMessage({
        description: 'Error message when file is too large.',
        defaultMessage: '{subjectName} must not be more than {fileSize}.',
      }, {
        fileSize: FileSizeFormatter.format(error.metadata.maxBytes),
        subjectName,
      }),
    ),
    ValidationErrorHandlerFactory.createHandler(
      ValidationErrorIdentifier.isNotNumberError,
      () => intl.formatMessage({
        description: 'Validation message when a number field contains a non-numeric value.',
        defaultMessage: '{subjectName} must be a number.',
      }, {
        subjectName,
      }),
    ),
    ValidationErrorHandlerFactory.createHandler(
      ValidationErrorIdentifier.isNotPhoneNumberError,
      () => intl.formatMessage({
        description: 'Validation message when input is not a valid phone number',
        defaultMessage: '{subjectName} is not a valid phone number.',
      }, {
        subjectName,
      }),
    ),
    ValidationErrorHandlerFactory.createHandler(
      ValidationErrorIdentifier.isNotEmailAddressError,
      () => intl.formatMessage({
        description: 'Validation message when input is not a valid email address',
        defaultMessage: '{subjectName} is not a valid email address.',
      }, {
        subjectName,
      }),
    ),
    ValidationErrorHandlerFactory.createHandler(
      ValidationErrorIdentifier.isTooEarlyError,
      error => intl.formatMessage({
        description: 'Validation message when date is too early.',
        defaultMessage: '{subjectName} must be after {date}.',
      }, {
        subjectName,
        date: fromUnixTime(error.metadata.minDate).toLocaleDateString()
      }),
    ),
    ValidationErrorHandlerFactory.createHandler(
      ValidationErrorIdentifier.isTooFewError,
      error => intl.formatMessage({
        description: 'Validation message when too few items are selected.',
        defaultMessage: '{subjectName} requires at least {min, plural, one {# {selectionSingular}} other {# {selectionPlural}}}.',
      }, {
        subjectName,
        min: error.metadata.minCount,
        selectionSingular,
        selectionPlural,
      }),
    ),
    ValidationErrorHandlerFactory.createHandler(
      ValidationErrorIdentifier.isTooLargeError,
      error => intl.formatMessage({
        description: 'Validation message when number is too large',
        defaultMessage: '{subjectName} must not be more than {max}.',
      }, {
        subjectName,
        max: error.metadata.max,
      }),
    ),
    ValidationErrorHandlerFactory.createHandler(
      ValidationErrorIdentifier.isTooLateError,
      error => intl.formatMessage({
        description: 'Validation message when date is too late.',
        defaultMessage: '{subjectName} must be before {date}.',
      }, {
        subjectName,
        date: fromUnixTime(error.metadata.maxDate).toLocaleDateString(),
      }),
    ),
    ValidationErrorHandlerFactory.createHandler(
      ValidationErrorIdentifier.isTooLongError,
      error => intl.formatMessage({
        description: 'Validation message when text is too long.',
        defaultMessage: '{subjectName} must not be more than {max} characters.',
      }, {
        subjectName,
        max: error.metadata.maxLength,
      }),
    ),
    ValidationErrorHandlerFactory.createHandler(
      ValidationErrorIdentifier.isTooManyError,
      error => intl.formatMessage({
        description: 'Validation message when too many items are selected.',
        defaultMessage: '{subjectName} must not have more than {max, plural, one {# {selectionSingular}} other {# {selectionPlural}}}.',
      }, {
        subjectName,
        max: error.metadata.maxCount,
        selectionSingular,
        selectionPlural,
      }),
    ),
    ValidationErrorHandlerFactory.createHandler(
      ValidationErrorIdentifier.isTooShortError,
      error => intl.formatMessage({
        description: 'Validation message when text is too short.',
        defaultMessage: '{subjectName} must be at least {min} characters.',
      }, {
        subjectName,
        min: error.metadata.minLength,
      }),
    ),
    ValidationErrorHandlerFactory.createHandler(
      ValidationErrorIdentifier.isTooSmallError,
      error => intl.formatMessage({
        description: 'Validation message when number is too small',
        defaultMessage: '{subjectName} must be at least {min}.',
      }, {
        subjectName,
        min: error.metadata.min,
      }),
    ),
    ValidationErrorHandlerFactory.createHandler(
      ValidationErrorIdentifier.isNotUrlError,
      () => intl.formatMessage({
        description: 'Validation message when url is invalid.',
        defaultMessage: 'Url must be formatted correctly.',
      }, {
        subjectName,
      }),
    ),
    ValidationErrorHandlerFactory.createHandler(
      ValidationErrorIdentifier.isNotHexColourError,
      () => intl.formatMessage({
        description: 'Validation message when hex colour is invalid.',
        defaultMessage: 'Colour must be a valid hexadecimal colour.',
      }, {
        subjectName,
      }),
    ),
    ValidationErrorHandlerFactory.createHandler(
      ValidationErrorIdentifier.isTranscodingError,
      () => intl.formatMessage({
        description: 'Validation message when video transcoding fails.',
        defaultMessage: 'Video processing failed, please try a different video.',
      }, {
        subjectName,
      }),
    ),
    ValidationErrorHandlerFactory.createHandler(
      (error): error is NotUniqueError => (
        ValidationErrorIdentifier.isNotUniqueError(error)
      ),
      () => intl.formatMessage({
        description: 'Generic validation message when value is not unique.',
        defaultMessage: '{ subjectName } must be unique.',
      }, {
        subjectName,
      }),
    ),
    ValidationErrorHandlerFactory.createHandler(
      (error): error is NotAllowedError => {
        return ValidationErrorIdentifier.isNotAllowedError(error) && error.metadata.reason === 'Not allowed with anonymous submissions';
      },
      () => intl.formatMessage({
        description: 'Validation message when content is not allowed with anonymous submissions.',
        defaultMessage: '{ subjectName } is not allowed with anonymous submissions.',
      }, {
        subjectName,
      }),
    ),
    ValidationErrorHandlerFactory.createHandler(
      (error): error is InvalidArgumentError => error.type === 'invalidArgument',
      () => intl.formatMessage({
        description: 'Validation message when field contains invalid characters',
        defaultMessage: '{subjectName} contains invalid characters.',
      }, {
        subjectName,
      }),
    ),
    ValidationErrorHandlerFactory.createHandler(
      (error): error is ValidationError => true,
      () => intl.formatMessage({
        description: 'Validation message when error is unrecognised',
        defaultMessage: 'There is a problem with {subjectName}.',
      }, {
        subjectName,
      }),
    ),
  ], [subjectName, intl, selectionPlural, selectionSingular]);
};
