import { default as React, FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';

import { Flex } from '..';
import ContainIcon from '../../../Assets/img/icons/streamline/app-window-minimize-1.svg';
import CoverIcon from '../../../Assets/img/icons/streamline/app-window-expand-1.svg';
import { StyledButtonGroup } from './style';

type Props = {
  imageFit: 'contain' | 'cover';
  onChange: (imageFit: 'contain' | 'cover') => void;
};

export const ImageFitButtons: FC<Props> = ({
  imageFit,
  onChange,
}) => (
  <StyledButtonGroup
    disableElevation
    color="primary"
  >
    <Button
      fullWidth
      variant={ imageFit === 'contain' ? 'primary' : 'secondary' }
      onClick={ () => onChange('contain') }
    >
      <Flex gap={ 2 }>
        <ContainIcon/>
        <span>
          <FormattedMessage
            id="imageFitButtons.contain"
            description="Label for contain image fit button when editing an image."
            defaultMessage="Contain"
          />
        </span>
      </Flex>
    </Button>
    <Button
      fullWidth
      variant={ imageFit === 'cover' ? 'primary' : 'secondary' }
      onClick={ () => onChange('cover') }
    >
      <Flex gap={ 2 }>
        <CoverIcon/>
        <span>
          <FormattedMessage
            id="imageFitButtons.cover"
            description="Label for cover image fit button when editing an image."
            defaultMessage="Cover"
          />
        </span>
      </Flex>
    </Button>
  </StyledButtonGroup>
);
