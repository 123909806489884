import { Frequency } from 'rrule/dist/esm/src/types';

import { RRuleOrdinal } from '../../Utility/RRuleParser';

export enum DayIndex {
  SUNDAY,
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
}

export interface Repetition {
  frequency: Frequency;
  localHour: number;
  minutes: number;
  seconds: number;
}

interface DailyRepetition extends Repetition {
  frequency: Frequency.DAILY;
  localDayIndices: number[];
}

interface WeeklyRepetition extends Repetition {
  frequency: Frequency.WEEKLY;
  localDayIndex: number;
}

interface MonthlyRepetition extends Repetition {
  frequency: Frequency.MONTHLY;
  ordinal: RRuleOrdinal;
  localDayIndex: number;
}

interface AnnualRepetition extends Repetition {
  frequency: Frequency.YEARLY;
}

interface RecurrenceEnd {
  type: string;
}

interface RecurrenceEndDate extends RecurrenceEnd {
  type: 'onDate';
  localDate: Date;
}

interface RecurrenceEndCount extends RecurrenceEnd {
  type: 'afterOccurrences';
  count: number;
}

type AnyRepetition = DailyRepetition | WeeklyRepetition | MonthlyRepetition | AnnualRepetition;

interface Recurrence<R extends Repetition = AnyRepetition> {
  repetition: R;
  end: RecurrenceEndDate | RecurrenceEndCount | null;
}

interface BaseSchedule {
  localStartDate: Date | null;
  recurrence: Recurrence | null;
}

export interface OneTimeSchedule extends BaseSchedule {
  recurrence: null;
}

export interface DailySchedule extends BaseSchedule {
  recurrence: Recurrence<DailyRepetition>;
}

export interface WeeklySchedule extends BaseSchedule {
  recurrence: Recurrence<WeeklyRepetition>;
}

export interface MonthlySchedule extends BaseSchedule {
  recurrence: Recurrence<MonthlyRepetition>;
}

export interface AnnualSchedule extends BaseSchedule {
  recurrence: Recurrence<AnnualRepetition>;
  localStartDate: Date;
}

export type RecurringSchedule = DailySchedule | WeeklySchedule | MonthlySchedule | AnnualSchedule;

export type Schedule = OneTimeSchedule | RecurringSchedule;

