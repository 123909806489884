import { FetchResponse, useGet } from '../../Hooks';
import { DraftBroadcast } from '../Model';
import { Paginated } from '../../Models';
import { Content } from '../../Content/Model';
import { TemplateWithContent } from '../../Common/Model';

export type TemplateBroadcast = Omit<DraftBroadcast, 'content'> & {
  contents: Content[];
};

export type BroadcastTemplate = TemplateWithContent<'broadcast', TemplateBroadcast>;

export const useFetchBroadcastTemplates = (disabled?: boolean): FetchResponse<Paginated<'templates', BroadcastTemplate>> => (
  useGet('/broadcasts/templates', undefined, undefined, disabled)
);
