import styled from '@emotion/styled';

export const StyledRoundedTextarea = styled.textarea`
  width: 100%;
  height: 100%;
  border-radius: ${ props => props.theme.borderRadius.sm };
  border: 1px solid ${ props => props.theme.new.palette.grey[300].main };
  padding: ${ props => props.theme.spacing(1) };
  resize: none;
  
  &[disabled] {
    color: ${ props => props.theme.new.palette.grey[500].main };
    background-color: ${ props => props.theme.new.palette.grey[50].main };
  }
`;
