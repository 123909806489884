import { AxiosError } from 'axios';

export class FetchResult<T> {
  private constructor(
    readonly content?: T,
    readonly error?: AxiosError<unknown, unknown>,
  ) { }

  public static fromError<T>(error: AxiosError<unknown, unknown>): FetchResult<T> {
    return new FetchResult<T>(undefined, error);
  }

  public static fromContent<T>(content: T): FetchResult<T> {
    return new FetchResult<T>(content, undefined);
  }

  public isError(): boolean {
    return this.error !== undefined;
  }
}
