import { Dispatch, FunctionComponent, SetStateAction, useCallback, useContext, useMemo } from 'react';

import { FetchCoversParams, sortStringIsValid, useFetchCovers } from '../../Hook';
import { ToastMessage } from '../../../Components';
import { CoversTableFilters, EventsTable } from '..';
import { dateOffsetFromIsValid, transformOffsetToRange } from '../../../Common/Utility';
import { ApiContext } from '../../../Contexts';

interface Props {
  query: Record<string, string>;
  setQuery: Dispatch<SetStateAction<Record<string, string>>>;
  addToastMessage: (message: ToastMessage) => void;
}

export const CoversTable: FunctionComponent<Props> = ({
  query,
  setQuery,
  addToastMessage,
}) => {
  const api = useContext(ApiContext);
  const fetchCoversParams = useMemo<FetchCoversParams>(() => {
    const dateRange = query.start
      ? {
        start: query.start,
        end: query.end,
      }
      : transformOffsetToRange({
        type: 'offset',
        from: dateOffsetFromIsValid(query.from) ? query.from : 'start',
        offset: null,
      });
    return {
      pageNum: +query.pageNum || 1,
      sort: sortStringIsValid(query.sort) ? query.sort : (dateRange.end ? 'start_at_desc' : 'start_at_asc'),
      statuses: query.statuses,
      teamIds: query.teamIds,
      search: query.search,
      ...(query.coverAssignedToIds ? { coverAssignedToIds: query.coverAssignedToIds } : {}),
      ...(
        dateRange.start && dateRange.end
          ? {
            startSince: dateRange.start,
            endUntil: dateRange.end,
          }
          : (
            (dateOffsetFromIsValid(query.from) ? query.from : 'start') === 'start'
              ? { endSince: 'now' }
              : { endUntil: 'now' }
          )
      ),
    };
  }, [query]);
  const [fetchCoversResult, fetchCoversRequestState, retryFetchCovers] = useFetchCovers(fetchCoversParams);
  const covers = useMemo(() => fetchCoversResult?.content?.events || [], [fetchCoversResult?.content]);
  const pagination = fetchCoversResult?.content?.pagination;

  const cancelCover = useCallback(
    (id: string) => {
      if (!api) {
        return Promise.reject();
      }
      return api.put(`/covers/${ id }/cancel`)
    },
    [api],
  );

  return (
    <>
      <CoversTableFilters
        query={ query }
        setQuery={ setQuery }
      />
      <EventsTable
        events={ covers }
        pagination={ pagination }
        requestState={ fetchCoversRequestState }
        query={ query }
        setQuery={ setQuery }
        addToastMessage={ addToastMessage }
        reload={ retryFetchCovers }
        cancel={ cancelCover }
      />
    </>
  );
};
