import { IntlShape } from 'react-intl';

import { SingleContentCard, DraftSingleContentCard, DraftContent, EditorContent } from '../../../../Content/Model';
import RevealContentIcon from '../../../../Assets/img/icons/monochrome/content/reveal.svg';
import { RevealContentEditor, RevealContentPreview } from '../../../Component';
import { UniqueIdGenerator } from '../../../../Common/Utility';
import { CardTransformer } from '../../../../Content/Utility';
import { RevealContentValidator } from '../../Validators';
import { BroadcastContent, BroadcastContentDefinition } from '../../../Model';
import { ReadResponse } from '../../../../Inbox/Model';
import { LiveRevealContent } from '../../../../Inbox/Component/LiveContent/LiveRevealContent/LiveRevealContent';
import { EnvironmentSettings } from '../../../../Models';

export type RevealOption = {
  id: number | string;
  preview_text: string;
  text: string;
  type: 'reveal_option';
};

export type DraftRevealOption = {
  id: string;
  preview_text: string;
  text: string;
  type: 'reveal_option';
};

export type DraftRevealContent = DraftContent & {
  type: 'revealcontent';
  text: string;
  title: string;
  options: DraftRevealOption[];
};

export type RevealContent = BroadcastContent & {
  type: 'revealcontent';
  text: string;
  title: string;
  options: RevealOption[];
};

export type RevealEditorContent = EditorContent & {
  card: DraftSingleContentCard<DraftRevealContent>;
};

export class RevealContentDefinition implements BroadcastContentDefinition<RevealContent, RevealEditorContent, ReadResponse> {
  public readonly id = 'reveal';
  public readonly availableToCreate = true;
  public readonly new = false;
  public readonly richText = false;
  public readonly contentTypes = ['revealcontent'];
  public readonly categoryId = 'content';
  public readonly IconComponent = RevealContentIcon;
  public readonly EditorComponent = RevealContentEditor;
  public readonly PreviewComponent = RevealContentPreview;
  public readonly LiveComponent = LiveRevealContent;
  public readonly SubmittedComponent = (): null => null;
  public validate = RevealContentValidator.validate;

  public definesContentType = (type: string): boolean => (
    this.contentTypes.includes(type)
  );

  public getContentTitle = (intl: IntlShape, content: RevealContent): string => (
    content.title || this.getDefaultTitle(intl)
  );

  public getLocalisedType = (intl: IntlShape): string => (
    intl.formatMessage({
      id: 'broadcasts.content.reveal.type',
      description: 'Localised name for reveal content type.',
      defaultMessage: 'Reveal',
    })
  );

  public getDraftContentTitle = (intl: IntlShape, draftContent: DraftRevealContent): string => (
    draftContent.title || this.getDefaultTitle(intl)
  );

  public hasRequiredFeatureFlags = (environmentSettings: EnvironmentSettings): boolean => (
    !!environmentSettings.revealTypeEnabled
  );

  public createEditorContent = (): RevealEditorContent => ({
    id: UniqueIdGenerator.generate(),
    card: {
      content: {
        type: 'revealcontent',
        title: '',
        text: '',
        options: [
          {
            id: UniqueIdGenerator.generate(),
            type: 'reveal_option',
            index: 0,
            preview_text: '',
            text: '',
          }
        ],
      },
      mandatory: false,
      image: null,
    },
  });

  public transformCard = (card: SingleContentCard<RevealContent>): RevealEditorContent => ({
    id: card.id,
    card: CardTransformer.transformCard(card, {
      type: 'revealcontent',
      title: card.content.title,
      text: card.content.text,
      options: card.content.options.map(option => ({
        ...option,
        id: option.id.toString(),
      })),
    }),
  });

  public cloneEditorContent = (editorContent: RevealEditorContent): RevealEditorContent => ({
    ...editorContent,
    id: UniqueIdGenerator.generate(),
    card: {
      ...editorContent.card,
      content: {
        ...editorContent.card.content,
        options: editorContent.card.content.options.map(option => ({
          ...option,
          id: UniqueIdGenerator.generate(),
        })),
      },
    },
  });

  private getDefaultTitle = (intl: IntlShape): string => (
    intl.formatMessage({
      id: 'broadcasts.content.reveal.defaultTitle',
      description: 'Default title for reveal content.',
      defaultMessage: 'Untitled reveal card',
    })
  );
}
