import { FC } from 'react';

import { Message, MessageProps } from '../../Message/Message';
import { LoadingCard } from '../../../Feedback/LoadingCard/LoadingCard';
import { CenteredContents } from '../CenteredContents/CenteredContents';

export const CenteredMessageWithLoadingCard: FC<MessageProps> = (props) => (
  <CenteredContents>
    <LoadingCard/>
    <Message { ...props } />
  </CenteredContents>
);
