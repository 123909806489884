import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';

import { ValidationErrorMessage } from '..';
import { FileUploadErrorLocaliser } from '../../Utility/FileUploadErrorLocaliser';

export interface FileUploadError {
  code: string;
  metadata?: Record<string, unknown>;
}

type Props = {
  errors: FileUploadError[];
}

export const LocalisedFileUploadErrors: FunctionComponent<Props> = ({ errors }) => {
  const intl = useIntl();

  return (
    <>
      { errors.map(error => (
        <ValidationErrorMessage key={error.code}>
          { FileUploadErrorLocaliser.localise(error, intl) }
        </ValidationErrorMessage>)
      ) }
    </>
  );
};
