import {
  ChangeEventHandler, ComponentProps,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { matchPath, useHistory, useRouteMatch } from 'react-router-dom';
import { isBefore, parseISO } from 'date-fns';
import { TextField } from '@mui/material';
import { AutocompleteSelectionChanged } from '@ourpeople/shared/Core/Component/Input/Autocomplete/Autocomplete';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';
import { ApiRequest } from '@ourpeople/shared/Core/Model/ApiRequest';
import { useDebounce } from '@ourpeople/shared/Core/Hook/useDebounce';
import { getDescendantErrors } from 'op-storybook/lib/utility/getDescendantErrors';

import { FieldValidationErrors, IconButton, StepperHeader, VerticallySpaced } from '../../../Common/Component';
import { StyledNameAndCategoryContainer } from './style';
import SaveIcon from '../../../Assets/img/icons/streamline/floppy-disk-1.svg';
import ShareIcon from '../../../Assets/img/icons/streamline/single-neutral-actions-share-2-users.svg';
import {
  BroadcastDeliveryStep,
  BroadcastEditor,
  CategoryAutocomplete,
  FetchCalculatedRecipientsResponse,
  RecipientAudienceEditor,
  BroadcastEditorTranscodingProgress, DefinitionCategoryAvailabilityType
} from '..';
import { ValidationTree } from '../../../Common/Model';
import { Audience } from '../../../Audiences/Model';
import { DraftSingleContentCard, EditorContent } from '../../../Content/Model';
import { useConfirmAction, useEnvironmentSettings } from '../../../Common/Hook';
import { DraftBroadcast, DraftEvent, MinimalBroadcastCategory, RecentBroadcastAudience } from '../../Model';
import { useContentDefinitionRegistry } from '../../../Content/Hook';
import { BROADCAST_TITLE_MAX_LENGTH, BroadcastValidator, CoverDefinition, VideoContentDefinition } from '../../Service';
import { AudienceCleaner, AudienceComparer, ConditionIdentifier } from '../../../Audiences/Utility';
import { useContextOrThrow, useGoogleAnalytics } from '../../../Core/Hook';
import { RequestState } from '../../../Models';
import { Schedule } from '../../Model/BroadcastSchedule';
import { SmsPreview } from '../../Model/SmsPreview';
import { ApiContext } from '../../../Contexts';
import { ContentTransformer } from '../../Utility';

export type SmsPreviewRequestData = {
  broadcast: {
    name: string;
    contents: DraftBroadcast['content'];
  };
  audience: Audience | undefined;
  applyReach: boolean;
  customMessage?: string;
};

type Props = {
  broadcast: DraftBroadcast
  savedAudience: Audience;
  audienceModified: boolean;
  setBroadcast: Dispatch<SetStateAction<DraftBroadcast>>;
  editorContents: EditorContent[];
  setEditorContents: Dispatch<SetStateAction<EditorContent[]>>;
  validation: ValidationTree<DraftBroadcast>;
  setValidation: Dispatch<SetStateAction<ValidationTree<DraftBroadcast>>>;
  saveDisabled: boolean;
  publishDisabled: boolean;
  saving: boolean;
  publishing: boolean;
  sharing: boolean;
  onSaveButtonClicked: () => void;
  preview: ComponentProps<typeof BroadcastEditor>['preview'];
  onPublishButtonClicked: () => void;
  onShareButtonClicked: () => void;
  fetchCalculatedRecipientsResponse: FetchCalculatedRecipientsResponse;
  schedule: Schedule;
  setSchedule: Dispatch<SetStateAction<Schedule>>;
  editingAudience: boolean;
  onEditingAudienceChange: (editingAudience: boolean) => void;
  alwaysEditAudience: boolean;
};

const baseRecentAudiencesGoogleAnalyticsEvent = {
  category: 'Recent Broadcast Audiences',
};

export const EditBroadcastSteps: FC<Props> = ({
  broadcast,
  savedAudience,
  audienceModified,
  setBroadcast,
  editorContents,
  setEditorContents,
  validation,
  setValidation,
  saveDisabled,
  publishDisabled,
  saving,
  publishing,
  sharing,
  onSaveButtonClicked,
  preview,
  onPublishButtonClicked,
  onShareButtonClicked,
  fetchCalculatedRecipientsResponse,
  schedule,
  setSchedule,
  editingAudience,
  onEditingAudienceChange,
  alwaysEditAudience,
}) => {
  const selectedCategoryIds = useMemo(() => broadcast.categoryId ? [broadcast.categoryId] : [], [broadcast.categoryId]);
  const { trackEvent } = useGoogleAnalytics();
  const intl = useIntl();
  const { url } = useRouteMatch();
  const history = useHistory();
  const api = useContextOrThrow(ApiContext);
  const [cleanedAudience, setCleanedAudience] = useState<Audience | undefined>(undefined);
  const { unlimitedSmsEnabled = false } = useEnvironmentSettings();
  const smsPreviewRequestData = useMemo<SmsPreviewRequestData>(() => ({
    broadcast: {
      name: broadcast.name,
      contents: editorContents.map(editorContent => ContentTransformer.fromCard(editorContent.card))
    },
    audience: cleanedAudience,
    applyReach: editingAudience || alwaysEditAudience,
    ...(broadcast.notification.sms.customMessage ? { customMessage: broadcast.notification.sms.customMessage } : {}),
  }), [
    broadcast.name,
    editorContents,
    cleanedAudience,
    editingAudience,
    alwaysEditAudience,
    broadcast.notification.sms.customMessage
  ]);
  const debouncedSmsPreviewRequestData = useDebounce(smsPreviewRequestData, 500);
  const [
    smsPreviewRequest,
    setSmsPreviewRequest,
  ] = useState<ApiRequest<SmsPreview>>({
    result: null,
    state: RequestState.PENDING,
  });
  const valid = useMemo(() => !getDescendantErrors(validation).length, [validation]);
  const eventCategoryConfig = useMemo(() => (
    broadcast.recurrenceRule
      ? {
        categoryId: 'event',
        availability: {
          type: DefinitionCategoryAvailabilityType.DISABLED,
          reason: intl.formatMessage({
            description: 'Notice for when adding event content is disabled because of a recurring schedule.',
            defaultMessage: 'Event cards are not available for recurring delivery settings.',
          }),
        },
      }
      : undefined
  ), [broadcast.recurrenceRule, intl]);

  const fetchSmsPreview = useCallback(() => {
    setSmsPreviewRequest({
      result: null,
      state: RequestState.FETCHING,
    });

    api.post<SmsPreview>('/broadcasts/sms/preview', debouncedSmsPreviewRequestData)
      .then(response => response.data)
      .then(result => {
        setSmsPreviewRequest({
          result,
          state: RequestState.COMPLETE,
        });
      })
      .catch(error => {
        setSmsPreviewRequest({
          result: error,
          state: RequestState.FAILED,
        });
      });
  }, [api, debouncedSmsPreviewRequestData]);

  useEffect(() => {
    if (!broadcast.recipientAudience || (cleanedAudience && AudienceComparer.cleanedAudiencesAreEqual(cleanedAudience, broadcast.recipientAudience))) {
      return;
    }

    setCleanedAudience(AudienceCleaner.cleanAudience(broadcast.recipientAudience));
  }, [broadcast.recipientAudience, cleanedAudience]);

  const sufficientCredit = useMemo(() => {
    const broadcastIsScheduledOrRecurring = broadcast.scheduleFor || broadcast.recurrenceRule;
    const shouldSendSms = broadcast.notification.sms.send || broadcast.sendSms;
    const creditIsIrrelevant = broadcastIsScheduledOrRecurring || !shouldSendSms || unlimitedSmsEnabled;

    if (creditIsIrrelevant) {
      return true;
    }

    if (smsPreviewRequest.state !== RequestState.COMPLETE) {
      return false;
    }

    return smsPreviewRequest.result.smsCredits
      && smsPreviewRequest.result.smsCredits.available
      >= smsPreviewRequest.result.smsCredits.required;
  }, [
    broadcast.notification.sms.send,
    broadcast.recurrenceRule,
    broadcast.scheduleFor,
    broadcast.sendSms,
    smsPreviewRequest,
    unlimitedSmsEnabled,
  ]);
  const { contentDefinitions, getDraftContentTitle, validate: validateContent } = useContentDefinitionRegistry();
  const [broadcastTitleModified, setBroadcastTitleModified] = useState<boolean>(
    editorContents[0] && getDraftContentTitle(intl, editorContents[0].card.content) !== broadcast.name
  );
  const lastActiveStepIndex = useRef<number | null>(null);
  const [cardValidation, setCardValidation] = useState<ValidationTree<DraftSingleContentCard[]>>({
    errors: validation.children.content?.errors || [],
    children: Object.values(validation.children.content?.children || {}).map(contentValidation => ({
      errors: [],
      children: {
        content: contentValidation,
      },
    })) || [],
  });
  const eventContents = useMemo<EditorContent<DraftEvent>[]>(() => {
    const eventDefinitions = (contentDefinitions || []).filter(contentDefinition => contentDefinition.categoryId === 'event');
    return editorContents.filter(
      content => eventDefinitions.find(
        eventDefinition => eventDefinition.definesContentType(content.card.content.type)
      )
    ) as EditorContent<DraftEvent>[];
  }, [contentDefinitions, editorContents]);
  const broadcastContainsVideo = useMemo(() => {
    const videoDefinition = new VideoContentDefinition();
    return editorContents.find(
      editorContent => videoDefinition.definesContentType(editorContent.card.content.type)
    );
  }, [editorContents]);
  const firstEventDate = useMemo(() => (
    eventContents.reduce<Date | null>(
      (firstEventDate, eventContent) => {
        const eventDate = parseISO(eventContent.card.content.start_at.dt);
        return firstEventDate === null || isBefore(eventDate, firstEventDate)
          ? eventDate
          : firstEventDate
      },
      null,
    )
  ), [eventContents]);
  const containsCover = useMemo<boolean>(() => {
    const coverDefinition = new CoverDefinition();
    return !!eventContents.find(eventContent => coverDefinition.definesContentType(eventContent.card.content.type));
  }, [eventContents]);
  const [recentAudienceId, setRecentAudienceId] = useState<string>();

  const validate = useCallback(() => {
    const validation = BroadcastValidator.validate(broadcast, editorContents, validateContent);
    const valid = !getDescendantErrors(validation)?.length;
    setValidation(validation);

    return valid;
  }, [broadcast, editorContents, setValidation, validateContent]);

  const whenShareButtonClicked = useCallback(() => {
    const valid = validate();

    if (!valid) {
      return;
    }

    onShareButtonClicked();
  }, [onShareButtonClicked, validate]);

  const whenSaveClicked = useCallback(() => {
    const valid = validate();

    if (!valid) {
      return;
    }

    onSaveButtonClicked();

    recentAudienceId && trackEvent({
      ...baseRecentAudiencesGoogleAnalyticsEvent,
      action: 'Broadcast saved with recent audience',
      label: recentAudienceId,
    });
  }, [onSaveButtonClicked, validate, trackEvent, recentAudienceId]);

  const validateTitle = useCallback((title: string) => (
    setValidation(validation => ({
      ...validation,
      children: {
        ...validation.children,
        name: BroadcastValidator.validateTitle(title),
      },
    }))
  ), [setValidation]);

  useEffect(() => {
    if (broadcastTitleModified || editorContents.length === 0) {
      return;
    }

    const title = getDraftContentTitle(intl, editorContents[0].card.content).substr(0, BROADCAST_TITLE_MAX_LENGTH);

    if (title === broadcast.name) {
      return;
    }

    setBroadcast(broadcast => ({
      ...broadcast,
      name: title,
    }));
    validateTitle(title);
  }, [broadcastTitleModified, broadcast.name, editorContents, getDraftContentTitle, intl, setBroadcast, validateTitle]);

  useEffect(() => {
    setCardValidation({
      errors: validation.children.content?.errors || [],
      children: Object.values(validation.children.content?.children || {}).map(contentValidation => ({
        errors: [],
        children: {
          content: contentValidation,
        },
      })) || [],
    });
  }, [validation.children.content]);

  const whenBroadcastNameChanged: ChangeEventHandler<HTMLInputElement> = event => {
    setBroadcast(broadcast => ({
      ...broadcast,
      name: event.target.value,
    }));
    setBroadcastTitleModified(true);
  };

  const whenBroadcastCategoryChanged: AutocompleteSelectionChanged<MinimalBroadcastCategory> = useCallback(selection => {
    const newCategoryId = selection.options[0]?.id || undefined;

    if (!newCategoryId || newCategoryId === broadcast.categoryId) {
      return;
    }

    setBroadcast(({ categoryId, ...broadcast }) => ({
      ...broadcast,
      ...(selection.options.length ? { categoryId: selection.options[0].id } : {}),
    }));
  }, [broadcast.categoryId, setBroadcast]);

  const whenRecipientAudienceChanged = useCallback((recipientAudience: Audience) => {
    setBroadcast(broadcast => ({
      ...broadcast,
      recipientAudience,
    }));
  }, [setBroadcast]);

  const whenCardValidationChanged = useCallback((cardsValidation: ValidationTree<DraftSingleContentCard[]>) => (
    setValidation(validation => ({
      ...validation,
      children: {
        ...validation.children,
        content: {
          errors: [],
          children: Object.values(cardsValidation.children).map(
            (cardValidation) => cardValidation?.children.content || { errors: [], children: {} }
          ),
        },
      },
    }))
  ), [setValidation]);

  const whenRevertAudienceButtonClicked = useCallback(() => {
    setBroadcast(broadcast => ({
      ...broadcast,
      recipientAudience: savedAudience,
    }));
    onEditingAudienceChange(false);
  }, [onEditingAudienceChange, savedAudience, setBroadcast]);

  const whenRecentAudiencesShown = useCallback(
    () => {
      trackEvent({
        ...baseRecentAudiencesGoogleAnalyticsEvent,
        action: 'Recent audiences shown',
      });
    },
    [trackEvent],
  );

  const whenRecentAudienceConfirmed = useCallback(
    (recentAudience: RecentBroadcastAudience) => {
      setBroadcast(broadcast => ({
        ...broadcast,
        recipientAudience: recentAudience.audience,
      }));
      setRecentAudienceId(recentAudience.audience.id);
      trackEvent({
        ...baseRecentAudiencesGoogleAnalyticsEvent,
        action: 'Recent audience selected',
        label: recentAudience.audience.id,
      });
    },
    [
      trackEvent,
      setRecentAudienceId,
      setBroadcast,
    ],
  );

  const [whenRecentAudienceSelected, confirmChangeRecentAudienceDialog] = useConfirmAction(
    whenRecentAudienceConfirmed,
    useMemo(() => ({
      title: intl.formatMessage({
        description: 'Title for recent audience replace confirmation modal.',
        defaultMessage: 'Are you sure you want to replace your audience?',
      }),
      description: intl.formatMessage({
        description: 'Body for recent audience replace confirmation modal.',
        defaultMessage: 'This will replace your current audience selection. If you wish to continue, select update. If you wish to keep your current audience please use cancel.',
      }),
      confirmCta: intl.formatMessage({
        description: 'Confirm CTA for recent audience replace confirmation modal.',
        defaultMessage: 'Update',
      }),
    }), [intl]),
    useCallback(
      (audience: RecentBroadcastAudience) => {
        const firstSegment = broadcast.recipientAudience.segments[0];
        const firstCondition = firstSegment?.conditions[0];
        const audienceIsDefault = broadcast.recipientAudience.segments.length === 1
          && firstSegment.conditions.length === 1
          && firstCondition
          && ConditionIdentifier.conditionIsEveryoneCondition(firstCondition);
        return !audienceIsDefault && JSON.stringify(broadcast.recipientAudience.segments) !== JSON.stringify(audience.audience.segments);
      },
      [broadcast.recipientAudience],
    ),
  );

  const audienceStep = useMemo(() => (
    <>
      <RecipientAudienceEditor
        audience={ broadcast.recipientAudience }
        onChange={ whenRecipientAudienceChanged }
        validation={ validation?.children.recipientAudience }
        editing={ editingAudience }
        onRevertButtonClicked={ whenRevertAudienceButtonClicked }
        onEditButtonClicked={ () => onEditingAudienceChange(true) }
        alwaysEdit={ alwaysEditAudience }
        audienceModified={ audienceModified }
        onRecentAudienceSelected={ whenRecentAudienceSelected }
        onRecentAudiencesShown={ whenRecentAudiencesShown }
      />
      { confirmChangeRecentAudienceDialog }
    </>
  ), [
    broadcast.recipientAudience,
    whenRecipientAudienceChanged,
    validation?.children.recipientAudience,
    editingAudience,
    whenRevertAudienceButtonClicked,
    alwaysEditAudience,
    audienceModified,
    whenRecentAudienceSelected,
    whenRecentAudiencesShown,
    confirmChangeRecentAudienceDialog,
    onEditingAudienceChange
  ]);

  const steps = useMemo(() => [
    {
      link: `${ url }`,
      title: intl.formatMessage({
        id: 'broadcasts.form.buildTitle',
        description: 'Heading for broadcast content building',
        defaultMessage: 'Build broadcast',
      }),
      error: !!(validation.children.content && getDescendantErrors(validation.children.content).length),
      render: () => (
        <BroadcastEditor
          contents={ editorContents }
          setContents={ setEditorContents }
          validation={ cardValidation }
          onValidationChanged={ whenCardValidationChanged }
          eventCategoryConfig={ eventCategoryConfig }
          broadcastTitle={ broadcast.name }
          preview={ preview }
        />
      ),
    },
    {
      link: `${ url }/audience`,
      title: intl.formatMessage({
        id: 'broadcasts.form.audienceTitle',
        description: 'Heading for broadcast audience definitions',
        defaultMessage: 'Define audience',
      }),
      render: () => audienceStep,
    },
    {
      link: `${ url }/delivery`,
      title: intl.formatMessage({
        id: 'broadcasts.form.deliveryTitle',
        description: 'Heading for broadcast delivery settings',
        defaultMessage: 'Delivery settings',
      }),
      error: !!(validation.children.summarySms?.errors || validation.children.scheduleFor?.errors || []).length,
      render: () => (
        <BroadcastDeliveryStep
          broadcast={ broadcast }
          setBroadcast={ setBroadcast }
          smsPreviewRequest={ smsPreviewRequest }
          onRetrySmsPreviewRequestClicked={ fetchSmsPreview }
          fetchCalculatedRecipientsResponse={ fetchCalculatedRecipientsResponse }
          validation={ validation }
          setValidation={ setValidation }
          containsCover={ containsCover }
          firstEventDate={ firstEventDate }
          schedule={ schedule }
          setSchedule={ setSchedule }
          valid={ valid }
        />
      ),
    },
  ], [
    url,
    intl,
    validation,
    editorContents,
    setEditorContents,
    cardValidation,
    whenCardValidationChanged,
    eventCategoryConfig,
    broadcast,
    preview,
    audienceStep,
    setBroadcast,
    smsPreviewRequest,
    fetchSmsPreview,
    fetchCalculatedRecipientsResponse,
    setValidation,
    containsCover,
    firstEventDate,
    schedule,
    setSchedule,
    valid
  ]);

  const activeStepIndex = useMemo(() => (
    steps.findIndex(
      step => !!matchPath(step.link, { path: history.location.pathname, exact: true })
    )
  ), [history.location.pathname, steps]);

  useEffect(() => {
    if (!debouncedSmsPreviewRequestData.broadcast.contents.length || activeStepIndex !== 2 || !broadcast.notification.sms.send) {
      return;
    }

    fetchSmsPreview();
  }, [
    fetchSmsPreview,
    debouncedSmsPreviewRequestData.broadcast.contents,
    activeStepIndex,
    broadcast.notification.sms.send
  ]);

  useEffect(() => {
    if ((lastActiveStepIndex.current === null && activeStepIndex !== 0) || (lastActiveStepIndex.current !== null && lastActiveStepIndex.current !== activeStepIndex)) {
      const validation = BroadcastValidator.validate(broadcast, editorContents, validateContent);
      setValidation(validation);
    }

    lastActiveStepIndex.current = activeStepIndex;
  }, [activeStepIndex, broadcast, editorContents, setValidation, validateContent])

  const whenNextButtonClicked = useCallback(
    () => history.push(steps[activeStepIndex + 1].link),
    [activeStepIndex, history, steps],
  );

  return (
    <div>
      <StepperHeader
        steps={ steps }
        preStep={ broadcastContainsVideo && (
          <BroadcastEditorTranscodingProgress
            editorContents={ editorContents }
            setEditorContents={ setEditorContents }
            setCardValidation={ setCardValidation }
          />
        ) }
        displayBottomNav
        title={
          <StyledNameAndCategoryContainer>
            <VerticallySpaced gap={ 1 }>
              <TextField
                value={ broadcast.name }
                onChange={ whenBroadcastNameChanged }
                fullWidth
                onBlur={ event => validateTitle(event.currentTarget.value) }
              />
              <FieldValidationErrors
                fieldName={ intl.formatMessage({
                  id: 'broadcasts.editor.title.fieldName',
                  description: 'Field name for broadcast title',
                  defaultMessage: 'Broadcast name',
                }) }
                validationErrors={ validation.children.name?.errors || [] }
              />
            </VerticallySpaced>
            <CategoryAutocomplete
              fullWidth
              selectedIds={ selectedCategoryIds }
              onSelectionChanged={ whenBroadcastCategoryChanged }
            />
          </StyledNameAndCategoryContainer>
        }
        actions={
          <>
            <IconButton
              color="primary.main"
              IconComponent={ ShareIcon }
              label={ intl.formatMessage({
                id: 'broadcasts.create.share',
                description: 'Label for share button.',
                defaultMessage: 'Share',
              }) }
              onClick={ whenShareButtonClicked }
              disabled={ saveDisabled || saving }
              busy={ sharing }
              showTooltip
              size="small"
            />
            <IconButton
              color="primary.main"
              IconComponent={ SaveIcon }
              label={ intl.formatMessage({
                id: 'broadcasts.create.save',
                description: 'Label for save button.',
                defaultMessage: 'Save',
              }) }
              onClick={ whenSaveClicked }
              disabled={ saveDisabled || sharing }
              busy={ saving }
              showTooltip
              size="small"
            />
            {
              activeStepIndex < 2
                ? (
                  <Button variant="primary" onClick={ whenNextButtonClicked }>
                    <FormattedMessage
                      id="broadcasts.create.continue"
                      description="Label for continue button."
                      defaultMessage="Continue"
                    />
                  </Button>
                )
                : (
                  <Button
                    variant="primary"
                    disabled={ publishDisabled || !sufficientCredit }
                    busy={ publishing }
                    onClick={ onPublishButtonClicked }
                  >
                    {
                      broadcast.scheduleFor || broadcast.recurrenceRule
                        ? (
                          <FormattedMessage
                            id="broadcasts.create.schedule"
                            description="Label for schedule button."
                            defaultMessage="Schedule"
                          />
                        )
                        : (
                          <FormattedMessage
                            id="broadcasts.create.send"
                            description="Label for send button."
                            defaultMessage="Send"
                          />
                        )
                    }
                  </Button>
                )
            }
          </>
        }
      />
    </div>
  );
};
