import { FunctionComponent, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { useInvitationStatuses } from '../../Hook';
import { FeedbackSeverity, StatusChip } from '../../../Common/Component';
import { InvitationStatus } from '../../Hook';

type Props = {
  status: InvitationStatus;
}

export const InvitationStatusChip: FunctionComponent<Props> = ({ status }) => {
  const localisedStatuses = useInvitationStatuses();
  const label = useMemo(() => {
    return localisedStatuses.find(option => option.id === status)?.localisation
      || (
        <FormattedMessage
          id="people.invitations.unknownStatus"
          defaultMessage="Unknown"
        />
      );
  }, [localisedStatuses, status]);

  return (
    <StatusChip
      status={ getInvitationStatusSeverity(status) }
      label={ label }
    />
  );
};

const getInvitationStatusSeverity = (status: InvitationStatus): FeedbackSeverity => {
  switch (status) {
    case 'complete':
      return 'success';
    case 'queued':
      return 'warning';
    case 'cancelled':
      return 'error';
    default:
      return 'none';
  }
};
