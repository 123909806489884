export class MapHelper {
  public static set = <K, V>(map: Map<K, V>, key: K, value: V): Map<K, V> => {
    const copy = new Map(map);
    copy.set(key, value);
    return copy;
  };

  public static delete = <K, V>(map: Map<K, V>, key: K): Map<K, V> => {
    const copy = new Map(map);
    copy.delete(key);
    return copy;
  };
}
