import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { DebouncedSearchInput } from '@ourpeople/shared/Core/Component/Input/DebouncedSearchInput/DebouncedSearchInput';

import { PaginatedTable, SortableHeaderCell, TableCell, TableRow, ToastMessage } from '../../../Components';
import { FetchCategoriesParams, FetchCategoriesSort, useFetchCategories } from '../../Hook';
import { FilterContainer, FilterRow, FlexPullRight } from '../../../Common/Component';
import { FormCategoriesTableMenu } from '../FormCategoriesTableMenu/FormCategoriesTableMenu';
import { useUserRoles } from '../../../Common/Hook';
import { RightAlignedCell } from './styles';

export interface FormCategoriesTableParams {
  pageNum: number;
  search: string;
  sort: FetchCategoriesSort;
  onActionPerformed: (message: ToastMessage) => void;
}

export interface FormCategoriesTableProps extends FormCategoriesTableParams {
  onParamChange: (params: Partial<FormCategoriesTableParams>) => void;
}

export const FormCategoriesTable: FunctionComponent<FormCategoriesTableProps> = ({
  onParamChange,
  pageNum,
  search,
  sort,
  onActionPerformed,
}) => {
  const [localSearch, setLocalSearch] = useState<string>(search || '');
  const [localPageNum, setLocalPageNum] = useState<number>(pageNum || 1);
  const [localSort, setLocalSort] = useState<FetchCategoriesSort>(sort);
  const fetchCategoriesParams = useMemo<FetchCategoriesParams>(() => ({
      pageNum,
      sort,
      search: search || null,
    }),
    [pageNum, sort, search],
  );
  const [
    fetchCategoriesResponse,
    fetchCategoriesRequestState,
    fetchCategoriesRefresh,
  ] = useFetchCategories(fetchCategoriesParams);
  const pagination = useMemo(() => fetchCategoriesResponse?.content?.pagination, [fetchCategoriesResponse]);
  const categories = useMemo(() => fetchCategoriesResponse?.content?.categories, [fetchCategoriesResponse]);
  const { userIsSuperAdmin } = useUserRoles();

  const whenActionPerformed = (reload: boolean, message: ToastMessage): void => {
    if (reload) {
      fetchCategoriesRefresh();
    }

    onActionPerformed(message);
  };

  useEffect(() => {
    if (
      pageNum !== localPageNum
      || search !== localSearch
      || sort !== localSort
    ) {
      onParamChange({
        pageNum: localPageNum,
        search: localSearch,
        sort: localSort,
      });
    }
  }, [onParamChange, localSort, sort, localPageNum, pageNum, search, localSearch]);

  return (
    <>
      <FilterContainer>
        <FilterRow>
          <FlexPullRight>
            <DebouncedSearchInput
              onChange={ setLocalSearch }
              value={ localSearch }
            />
          </FlexPullRight>
        </FilterRow>
      </FilterContainer>
      <PaginatedTable
        rows={ categories || [] }
        rowRender={ (category) => (
          <TableRow key={ category.id }>
            <TableCell>
              {
                userIsSuperAdmin
                  ? <Link to={ `/forms/categories/${ category.id }/edit` }>{category.name}</Link>
                  : category.name
              }
            </TableCell>
            {
              userIsSuperAdmin && (
                <RightAlignedCell>
                  <FormCategoriesTableMenu
                    category={category}
                    onActionPerformed={whenActionPerformed}
                  />
                </RightAlignedCell>
              )
            }
          </TableRow>
        ) }
        pageNum={ pageNum }
        onPageChanged={ setLocalPageNum }
        headerRow={ (
          <TableRow>
            <SortableHeaderCell
              sort={ sort }
              ascValue="name_asc"
              descValue="name_desc"
              onSort={ setLocalSort }
            >
              <FormattedMessage
                id="forms.tableHeader.name"
                defaultMessage="Name"
              />
            </SortableHeaderCell>
            { userIsSuperAdmin && <TableCell/> }
          </TableRow>
        ) }
        requestState={ fetchCategoriesRequestState }
        onRetryClicked={ fetchCategoriesRefresh }
        pagination={ pagination }
      />
    </>
  );
};

