import { FunctionComponent, useCallback, useContext, useState } from 'react';
import { FormattedMessage, FormattedRelativeTime } from 'react-intl';
import { parseISO } from 'date-fns';
import { Button } from '@mui/material';
import { Heading } from '@ourpeople/shared/Core/Component/Content';

import { Alert, TableCell, TableRow } from '../../../Components';
import { AccessRequest } from '../../../Models';
import { ClipboardButton } from '..';
import { ApiContext } from '../../../Contexts';
import { StyledAlertContainer, StyledParagraph } from './style';
import { AsyncTable } from '../../../Common/Component';
import { useFetchAccessRequests } from '../../Hook';
import { Flex } from '../../../Common/Component';

export const AccessRequestsTable: FunctionComponent = () => {
  const api = useContext(ApiContext);
  const [fetchAccessRequestsResponse, fetchAccessRequestsRequestState, retryFetchAccessRequests] = useFetchAccessRequests();
  const accessRequests: AccessRequest[] = fetchAccessRequestsResponse?.content?.accessRequests || [];
  const [creating, setCreating] = useState<boolean>(false);
  const [creatingError, setCreatingError] = useState<boolean>(false);
  const [invalidating, setInvalidating] = useState<boolean>(false);
  const [invalidatingError, setInvalidatingError] = useState<boolean>(false);

  const handleCreate = useCallback(() => {
    if (api && !creating) {
      let cancelled = false;
      setCreating(true);
      setCreatingError(false);
      api.post(
        '/access-requests',
      ).then(() => {
        if (cancelled) {
          return;
        }
        setCreating(false);
        retryFetchAccessRequests();
      }).catch(() => {
        if (cancelled) {
          return;
        }
        setCreating(false);
        setCreatingError(true);
      });
      return () => {
        cancelled = true;
      };
    }
  }, [creating, api, retryFetchAccessRequests]);

  const handleInvalidate = useCallback((requestId: string) => {
    if (api && !invalidating) {
      let cancelled = false;
      setInvalidating(true);
      setInvalidatingError(false);
      api.post(
        `/access-requests/${requestId}/invalidate`,
      ).then(() => {
        if (cancelled) {
          return;
        }
        setInvalidating(false);
        retryFetchAccessRequests();
      }).catch(() => {
        if (cancelled) {
          return;
        }
        setInvalidating(false);
        setInvalidatingError(true);
      });
      return () => {
        cancelled = true;
      };
    }
  }, [invalidating, api, retryFetchAccessRequests]);

  return (
    <>
      <Heading>
        <FormattedMessage
          id="settings.accessRequests.heading"
          description="Heading that appears above the list of access requests"
          defaultMessage="Access Requests"
          />
      </Heading>
      {
        creatingError && (
          <StyledAlertContainer>
            <Alert severity="error">
              <FormattedMessage
                id="settings.accessRequest.creating.error"
                defaultMessage="An error occurred whilst creating your access request"
              />
            </Alert>
          </StyledAlertContainer>
        )
      }
      {
        invalidatingError && (
          <StyledAlertContainer>
            <Alert severity="error">
              <FormattedMessage
                id="settings.accessRequest.invalidating.error"
                defaultMessage="An error occurred whilst invalidating your access request"
              />
            </Alert>
          </StyledAlertContainer>
        )
      }
      <StyledParagraph>
        <FormattedMessage
          id="settings.accessRequest.description"
          defaultMessage="Access requests can be used to grant 3rd party systems access to your data. If you have any questions about this feature, please contact our customer success team."
          />
      </StyledParagraph>
      <AsyncTable
        rows={ accessRequests }
        rowRender={ accessRequest => (
          <TableRow key={accessRequest.id}>
            <TableCell><ClipboardButton value={accessRequest.tokenWithSpaceId}/></TableCell>
            <TableCell>
              <FormattedRelativeTime
                value={(parseISO(accessRequest.expiresAt).getTime() - Date.now()) / 60000}
                unit="minute"
                numeric="auto"
                updateIntervalInSeconds={1}
              />
            </TableCell>
            <TableCell>
              {
                accessRequest.invalidatedAt === null
                && accessRequest.redeemedAt === null
                && (
                  <Button
                    onClick={() => handleInvalidate(accessRequest.id)}
                    variant="outlined"
                    color="primary"
                    disabled={invalidating}
                  >
                    <FormattedMessage
                      id="accessRequests.list.invalidate"
                      defaultMessage="Invalidate"
                    />
                  </Button>
                )
              }
            </TableCell>
          </TableRow>
        ) }
        headerRow={
          <TableRow>
            <TableCell>
              <FormattedMessage
                id="accessRequests.list.token"
                defaultMessage="Token"
              />
            </TableCell>
            <TableCell width="35%">
              <FormattedMessage
                id="accessRequests.list.expiry"
                defaultMessage="Expires"
              />
            </TableCell>
            <TableCell/>
          </TableRow>
        }
        requestState={ fetchAccessRequestsRequestState }
        onRetryClicked={ retryFetchAccessRequests }
        noResultsMessage={
          <Flex direction="column" gap={ 1 }>
            <span>
              <FormattedMessage
                id="accessRequests.list.noResults"
                defaultMessage="You have no active access requests!"
              />
            </span>
            <Button
              onClick={handleCreate}
              disabled={creating}
              variant="contained"
              color="primary"
              disableElevation
            >
              {
                creating
                  ? (
                    <FormattedMessage
                      id="accessRequests.list.creating"
                      defaultMessage="Creating..."
                    />
                  )
                  : (
                    <FormattedMessage
                      id="accessRequests.list.create"
                      defaultMessage="Create One"
                    />
                  )
              }
            </Button>
          </Flex>
        }
      />
    </>
  );
};
