import { FormBlock as FormBlockComponent } from './FormBlock';
import { InlineFormComponent } from '../../../Model/BroadcastBlockDefinition';
import { FormBlock } from '../../../Service/Blocks/FormBlockDefinition';

export const FormBlockInlineForm: InlineFormComponent<FormBlock> = ({ activateBlock, ...props }) => (
  <FormBlockComponent
    { ...props }
    onClick={ activateBlock }
  />
);
