import { FC } from 'react';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';

import { ContentPreviewProps } from '../../../../Content/Model';
import { VideoPlayButton, VideoThumbnail } from '../../../../Content/Component';
import { VideoEditorContent } from '../../../Service/ContentDefinitions/VideoContentDefinition';
import { StyledThumbnailPlaceholder } from '../../../../Broadcasts/Component/Content/VideoContentPreview/style';

export const VideoContentPreview: FC<ContentPreviewProps<VideoEditorContent>> = ({ editorContent }) => (
  editorContent.upload && editorContent.card.content.thumbnailKey
    ? (
      <Stack direction="column" align="center">
        <VideoThumbnail
          uploadId={ String(editorContent.upload.id) }
          thumbnailKey={ editorContent.card.content.thumbnailKey }
          duration={ editorContent.upload.video?.duration || 0 }
          busy={ false }
          onClick={ () => null }
        />
      </Stack>
    )
    : (
      <StyledThumbnailPlaceholder>
        <VideoPlayButton
          busy={ false }
          duration={ undefined }
        />
      </StyledThumbnailPlaceholder>
    )
);
