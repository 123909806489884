export const firebaseConfig = {
  apiKey: process.env.OP_FIREBASE_API_KEY,
  authDomain: 'our-people-182009.firebaseapp.com',
  databaseURL: 'https://our-people-182009.firebaseio.com',
  projectId: 'our-people-182009',
  storageBucket: 'our-people-182009.appspot.com',
  messagingSenderId: '86879118394',
  appId: '1:86879118394:web:8830a98b7662c089e30103',
  measurementId: 'G-F2H1MK3RPG'
};
