import { ComponentProps, FC, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { BreakpointContext } from 'op-storybook/lib/providers/BreakpointProvider/BreakpointProvider';
import { LoadingState } from 'op-storybook/stories/components/LoadingState/LoadingState';
import { EmptyState } from 'op-storybook/stories/components/EmptyState/EmptyState';
import TickIcon from 'op-storybook/lib/assets/icon/figma/check.svg';
import MailIcon from 'op-storybook/lib/assets/icon/figma/mail-01.svg';
import { Typography } from 'op-storybook/stories/components/Typography/Typography';
import { Button } from 'op-storybook/stories/components/Button/Button';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';

import { StyledPersonalDeliveryList } from './style';
import { PersonalDeliveryListItem } from '..';
import { PersonalDelivery } from '../../Model';
import { useContextOrThrow } from '../../../Core/Hook';
import { RequestState } from '../../../Models';

type Props = {
  deliveries: PersonalDelivery[] | undefined;
  onDeliverySelected: (delivery: PersonalDelivery) => void;
  requestState: RequestState;
  onRetryClicked: () => void;
};

export const PersonalDeliveryList: FC<Props> = ({
  deliveries,
  onDeliverySelected,
  requestState,
  onRetryClicked,
}) => {
  const firstLoadComplete = deliveries !== undefined;
  const intl = useIntl();
  const [initialScrollPerformed, setInitialScrollPerformed] = useState<boolean>(false);
  const screenWidth = useContextOrThrow(BreakpointContext);

  const tryAgainButtonProps = useMemo<ComponentProps<typeof Button>>(() => ({
    variant: 'secondary',
    onClick: onRetryClicked,
    children: intl.formatMessage({
      description: 'Label for button to reload broadcasts after an error on the inbox page.',
      defaultMessage: 'Try again',
    }),
  }), [intl, onRetryClicked]);

  return (
    requestState < RequestState.COMPLETE && !firstLoadComplete
      ? (
        <LoadingState
          pad
          text={ intl.formatMessage({
            description: 'Loading initial items message in inbox list',
            defaultMessage: 'Loading broadcasts…'
          }) }
        />
      )
      : (
        deliveries !== undefined && deliveries.length
          ? (
            <StyledPersonalDeliveryList>
              { deliveries.map(delivery => (
                <PersonalDeliveryListItem
                  key={ delivery.delivery.id }
                  delivery={ delivery }
                  onClick={ () => onDeliverySelected(delivery) }
                  initialScrollPerformed={ initialScrollPerformed }
                  onInitialScrollPerformed={ () => setInitialScrollPerformed(true) }
                  breakpoint={ screenWidth.lessThan.sm ? 'mobile' : 'desktop' }
                />
              )) }
              { requestState < RequestState.COMPLETE && (
                <li>
                  <LoadingState
                    pad={ false }
                    text={ intl.formatMessage({
                      description: 'Loading more items message in inbox list',
                      defaultMessage: 'Loading more…'
                    }) }
                  />
                </li>
              ) }
              { requestState === RequestState.FAILED && (
                <li>
                  <Stack
                    direction="column"
                    align="center"
                    css={ theme => ({ padding: theme.new.spacing[4] }) }
                  >
                    <Stack
                      direction="column"
                      align="center"
                      css={ theme => ({ padding: theme.new.spacing[1] }) }
                    >
                      <Typography
                        weight="medium"
                      >
                        <FormattedMessage
                          description="Error text when loading additional broadcasts after first page fails on inbox page."
                          defaultMessage="Something went wrong"
                        />
                      </Typography>
                      <Typography
                        weight="regular"
                      >
                        <FormattedMessage
                          description="Error supporting text when loading additional broadcasts after first page fails on inbox page."
                          defaultMessage="Could not load remaining broadcasts"
                        />
                      </Typography>
                    </Stack>
                    <Button { ...tryAgainButtonProps }/>
                  </Stack>
                </li>
              ) }
            </StyledPersonalDeliveryList>
          )
          : (
            requestState === RequestState.COMPLETE
              ? (
                <EmptyState
                  pad
                  text={ intl.formatMessage({
                    description: 'Heading for message when no deliveries are available on inbox page.',
                    defaultMessage: 'You don\'t have any new messages today.',
                  }) }
                  IconComponent={ TickIcon }
                  breakpoint="mobile"
                  buttonProps={ [] }
                />
              )
              : requestState === RequestState.FAILED
                ? (
                  <EmptyState
                    pad
                    IconComponent={ MailIcon }
                    breakpoint="desktop"
                    buttonProps={ [tryAgainButtonProps] }
                    text={ intl.formatMessage({
                      description: 'Error message text when initial delivery request fails on inbox page.',
                      defaultMessage: 'Something went wrong',
                    }) }
                    supportingText={ intl.formatMessage({
                      description: 'Error message supporting text when initial delivery request fails on inbox page.',
                      defaultMessage: 'We could not retrieve your broadcasts right now',
                    }) }
                  />
                )
                : null
          )
      )
  );
};
