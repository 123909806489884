import { ComponentProps, JSX, useMemo } from 'react';
import { QueryFunction, useInfiniteQuery } from '@tanstack/react-query';

import { BadgeCloud } from './BadgeCloud';
import { Paginated } from '../../../Models';

type BadgeProps = ComponentProps<typeof BadgeCloud>['value'][0];

type QueryFnData = Paginated<'items', BadgeProps>;

type Props<K extends string> = {
  queryFn: QueryFunction<QueryFnData, [K, { ids: string }], number>;
  queryKey: [K, { ids: string }];
  onRemoveClicked?: (badgeProps: BadgeProps) => void;
  onIdsMissing: (ids: string[]) => void;
};

export const AsyncBadgeCloud = <K extends string>({
  queryFn,
  queryKey,
  onRemoveClicked,
  onIdsMissing,
}: Props<K>): JSX.Element => {
  const { data } = useInfiniteQuery({
    queryFn: async (params) => {
      const result = await queryFn(params);
      const { queryKey: [_key, filters] } = params;
      const ids = filters.ids.split(',');

      if (result.pagination.itemCount < ids.length) {
        onIdsMissing(ids.filter(id => !result.items.find(item => item.id === id)));
      }

      return result;
    },
    queryKey: queryKey,
    initialPageParam: 1,
    getNextPageParam: (lastPage, _pages, lastPageParam) => (
      lastPage.pagination.pageCount <= lastPageParam
        ? null
        : lastPageParam + 1
    ),
  });

  const badgeProps = useMemo(() => (
    data
      ? data.pages.flatMap(page => page.items)
      : []
  ), [data]);

  return (
    <BadgeCloud
      value={ badgeProps }
      onRemoveClicked={ onRemoveClicked }
    />
  );
};
