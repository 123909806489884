import styled from '@emotion/styled';
import { TextField } from '@mui/material';

export const StyledNoMarginTextField = styled(
    TextField,
    {
      shouldForwardProp: prop => !['useNewTheme'].includes(prop),
    }
)<{ useNewTheme: boolean }>`
  margin: 0;
  background-color: ${ props => props.theme.palette.background.paper };

  ${ props => props.useNewTheme && `
    fieldset {
      border: ${ props.theme.new.border.standard };
      box-shadow: ${ props.theme.new.shadow.xs };
      border-radius: ${ props.theme.new.borderRadius.standard };
    }
  ` };
`;
