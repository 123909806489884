import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { useLoggedInAuthDescription } from '../../../../../src/react/Core/Hook/useLoggedInAuthDescription';
import { VerifiableEmailAddress } from '../../../../../src/react/Models/Person';
import { useMounted } from '../../../../../src/react/Common/Hook/useMounted';
import { UniqueIdGenerator } from '../../../../../src/react/Common/Utility/UniqueIdGenerator';
import { ApiContext } from '../../../../../src/react/Contexts';
import { useContextOrThrow } from '../../../../../src/react/Core/Hook/useContextOrThrow';
import { ToastContext } from '../../../../../src/react/Core/Context/ToastContext';
import { useEmailVerificationDialogDismissed } from '../../../Hook/useEmailVerificationDialogDismissed';
import { RequestState } from '../../../../../src/react/Models/RequestState';
import { EmailVerificationPrompt } from '../EmailVerificationPrompt/EmailVerificationPrompt';
import { Account } from '../../../../../src/react/Models/Account';
import { useFetchAccount } from '../../../../../src/react/Security/Hook/useFetchAccount';

export const EmailVerificationPromptContainer: FC = () => {
  const intl = useIntl();
  const api = useContextOrThrow(ApiContext);
  const { addSuccessToast, addErrorToast } = useContextOrThrow(ToastContext);
  const [emailVerificationPromptDismissed, setEmailVerificationPromptDismissed] = useEmailVerificationDialogDismissed();
  const { user: { id: userId } } = useLoggedInAuthDescription();
  const [account, setAccount] = useState<Account>();
  const [fetchAccountResult] = useFetchAccount();
  const verificationRequired = useMemo(() => account && !account.person.emailAddresses.find(
    verifiableEmailAddress => verifiableEmailAddress.verifiedAt || verifiableEmailAddress.verificationSentAt
  ), [account]);
  const [emailAddress, setEmailAddress] = useState<VerifiableEmailAddress>({
    id: UniqueIdGenerator.generate(),
    verifiedAt: null,
    verificationSentAt: null,
    createdAt: new Date().toISOString(),
    emailAddress: '',
  });
  const existingAddressChanged = useMemo(() => {
    const correspondingAddress = account?.person.emailAddresses.find(
      savedEmailAddress => savedEmailAddress.id === emailAddress.id
    );
    return correspondingAddress && correspondingAddress.emailAddress !== emailAddress.emailAddress;
  }, [emailAddress.emailAddress, emailAddress.id, account?.person.emailAddresses]);
  const userHasNoEmailAddress = (account?.person.emailAddresses || []).length === 0;
  const shouldAddNewEmailAddress = useMemo(() => (
    userHasNoEmailAddress || existingAddressChanged
  ), [existingAddressChanged, userHasNoEmailAddress]);
  const mounted = useMounted();
  const [initialisationDelayElapsed, setInitialisationDelayElapsed] = useState<boolean>(false);
  const [verificationState, setVerificationState] = useState<RequestState>(RequestState.PENDING);
  const shouldPresentDialog = account
    && verificationRequired
    && initialisationDelayElapsed
    && !emailVerificationPromptDismissed;

  useEffect(() => {
    if (!fetchAccountResult?.content?.person.emailAddresses) {
      return;
    }

    setAccount(fetchAccountResult.content);
  }, [fetchAccountResult?.content]);

  useEffect(() => {
    setTimeout(() => {
      if (!mounted.current) {
        return;
      }

      setInitialisationDelayElapsed(true);
    }, INITIALISATION_DELAY)
  }, [mounted, userId]);

  useEffect(() => {
    if (!account?.person.emailAddresses[0]) {
      return;
    }

    setEmailAddress(account.person.emailAddresses[0]);
  }, [account?.person.emailAddresses]);

  const sendVerification = useCallback(() => (
    api.post(`/me/account/email-addresses/${ emailAddress.id }/send-verification`)
  ), [api, emailAddress.id]);

  const addEmailAddress = useCallback(() => {
    if (!account) {
      throw new Error('Attempted to save person before it was fetched.');
    }

    return (
      api.post<Account>(
        '/me/account',
        {
          ...account.person,
          notificationPreferences: account.notificationPreferences,
          emailAddresses: account.person.emailAddresses
            .map(emailAddress => emailAddress.emailAddress)
            .concat(emailAddress.emailAddress),
        },
      )
        .then(response => response.data)
    );
  }, [api, emailAddress.emailAddress, account]);

  const whenSubmitClicked = useCallback(() => {
    setVerificationState(RequestState.FETCHING);
    (
      shouldAddNewEmailAddress
        ? addEmailAddress()
        : sendVerification()
    )
      .then(() => {
        if (!mounted.current) {
          return;
        }

        setVerificationState(RequestState.COMPLETE);
        addSuccessToast(intl.formatMessage({
          description: 'Success message when email verification is re-sent.',
          defaultMessage: 'Verification email sent'
        }));
      })
      .catch(() => {
        if (!mounted.current) {
          return;
        }

        setVerificationState(RequestState.FAILED);
        addErrorToast(intl.formatMessage({
          description: 'Error message when email verification can not be re-sent.',
          defaultMessage: 'Verification email could not be sent'
        }))
      });
  }, [addEmailAddress, addErrorToast, addSuccessToast, intl, mounted, sendVerification, shouldAddNewEmailAddress]);

  const whenEmailAddressChanged = useCallback((emailAddress: string) => (
    setEmailAddress(currentEmailAddress => ({
      ...currentEmailAddress,
      emailAddress,
    }))
  ), []);

  const whenPromptDismissed = useCallback(
    () => setEmailVerificationPromptDismissed(true),
    [setEmailVerificationPromptDismissed],
  );

  return shouldPresentDialog
    ? (
      <EmailVerificationPrompt
        submitState={ verificationState }
        inputDisabled={ !!account?.person.externallyManaged }
        submitDisabled={ !emailAddress.emailAddress.trim() }
        emailAddress={ emailAddress.emailAddress }
        onChange={ whenEmailAddressChanged }
        onSubmit={ whenSubmitClicked }
        onCancel={ whenPromptDismissed }
      />
    )
    : null;
};

const INITIALISATION_DELAY = 1000;
