import { useMemo } from 'react';
import { useValidationErrors } from 'op-storybook/lib/hooks/useValidationErrors';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';

import { InlineFormComponent } from '../../../Model/BroadcastBlockDefinition';
import { EventBlock } from '../../../Service/Blocks/EventBlockDefinition';
import { useContextOrThrow } from '../../../../../Core/Hook';
import { BroadcastBlockDefinitionRegistryContext } from '../../../Context/BroadcastBlockDefinitionRegistry';
import { BroadcastEditorErrors } from '../../BroadcastEditorErrors';
import { EventBlockExpandedPreview } from './EventBlockExpandedPreview';

export const EventBlockInlineForm: InlineFormComponent<EventBlock> = ({
  block,
  validationTree,
  activateBlock,
  attachments,
}) => {
  const { getBlockErrorDefinitions } = useContextOrThrow(BroadcastBlockDefinitionRegistryContext);
  const blockErrorDefinitions = useMemo(() => getBlockErrorDefinitions(block), [block, getBlockErrorDefinitions]);
  const { formatValidationTreeWithDefinitions } = useValidationErrors();
  const formattedNameErrors = useMemo(() => formatValidationTreeWithDefinitions(
    validationTree.children.attributes?.children.name,
    blockErrorDefinitions.name,
  ), [
    blockErrorDefinitions.name,
    formatValidationTreeWithDefinitions,
    validationTree.children.attributes?.children.name
  ]);

  const errors = useMemo(() => [...formattedNameErrors], [
    formattedNameErrors,
  ])

  return (
    <Stack
      direction="column"
      gap={ 1 }
    >
      <button
        onClick={ activateBlock }
        css={ {
          textAlign: 'left',
          margin: 0,
          padding: 0,
          outline: 'none',
          border: 'none',
          background: 'transparent',
        } }
      >
        <EventBlockExpandedPreview
          block={ block }
          attachments={ attachments }
        />
      </button>
      <BroadcastEditorErrors errors={ errors }/>
    </Stack>
  );
};
