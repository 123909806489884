import styled from '@emotion/styled';

export const StyledWeekdaySelect = styled.div`
  min-width: 10rem;

  .MuiFormControl-root {
    margin: 0;
  }

  .MuiInputLabel-root {
    &.Mui-focused {
      color: ${props => props.theme.palette.text.secondary}
    }

    &.MuiFormLabel-filled {
      opacity: 0;
    }
  }
`;
