import { default as React, FC } from 'react';

import { ContentEditorProps } from '../../../../Content/Model';
import { AudioEditorContent } from '../../../Service';
import { MediaContentEditor } from '../MediaContentEditor/MediaContentEditor';

export const AudioContentEditor: FC<ContentEditorProps<AudioEditorContent>> = ({
  editorContent,
  onEditorContentChanged,
  validation,
  onValidationChanged,
}) => (
  <MediaContentEditor
    mimeType="audio/*"
    editorContent={ editorContent }
    onEditorContentChanged={ onEditorContentChanged }
    validation={ validation }
    onValidationChanged={ onValidationChanged }
  />
);
