import styled from '@emotion/styled';

interface Props {
  progress: number;
}

export const Pager = styled.div<Props>`
  height: 0.75rem;
  border-radius: 999rem;
  background-color: ${props => props.theme.palette.grey[200]};
  overflow: hidden;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);

  ::after {
    content: '';
    display: block;
    background-color: ${props => props.theme.palette.secondary.main};
    width: 100%;
    height: 100%;
    transform-origin: left;
    transform: translateX(${props => (100 * props.progress) - 100}%);
    transition: transform cubic-bezier(0, 0.5, 0.5, 1) 0.3s;
    border-radius: inherit;
  }
`;
