import { CircularProgress, FormControl, MenuItem, Select } from '@mui/material';
import { ChangeEventHandler, ReactNode, UIEventHandler, useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { StyledLoadingIndicator } from './style';

export type ProgressiveSelectProps<T> = {
  options: T[];
  renderOption: (option: T, selected: boolean) => ReactNode;
  getOptionId: (option: T) => string;
  selection: string[];
  canLoadMore: boolean;
  onLoadMoreTriggered: () => void;
  onSelect: (id: string[]) => void;
  className?: string;
};

export const ProgressiveSelect = <T, >({
  options,
  renderOption,
  getOptionId,
  selection,
  canLoadMore,
  onLoadMoreTriggered,
  onSelect,
  className,
}: ProgressiveSelectProps<T>): JSX.Element => {
  const [loadTriggered, setLoadTrigerred] = useState<boolean>(false);
  const whenChanged: ChangeEventHandler<{ value: unknown }> = useCallback(event => {
    const value = event.target.value;

    if (!Array.isArray(value) && typeof value !== 'string') {
      return;
    }

    onSelect(
      typeof event.target.value === 'string'
        ? [event.target.value]
        : event.target.value as string[]
    );
  }, [onSelect]);

  const whenScrolled: UIEventHandler<HTMLDivElement> = useCallback(event => {
    const target = event.target as HTMLDivElement;
    const thresholdCrossed = target.scrollTop / target.scrollHeight >= 0.5;

    if (!thresholdCrossed || loadTriggered || !canLoadMore) {
      return;
    }

    onLoadMoreTriggered();
    setLoadTrigerred(true);
  }, [canLoadMore, loadTriggered, onLoadMoreTriggered]);

  useEffect(() => {
    setLoadTrigerred(false);
  }, [options]);

  return (
    <FormControl variant="outlined">
      <Select
        value={ selection }
        margin="dense"
        onChange={ whenChanged }
        MenuProps={ {
          ...(className ? { className } : {}),
          PaperProps: {
            onScroll: whenScrolled,
          },
          anchorOrigin: {
            horizontal: 'left',
            vertical: 'bottom',
          },
        } }
      >
        { options.map(option => {
          const optionId = getOptionId(option);
          return (
            <MenuItem
              key={ optionId }
              value={ optionId }
            >
              { renderOption(option, selection.includes(optionId)) }
            </MenuItem>
          );
        }) }
        { canLoadMore && (
          <StyledLoadingIndicator>
            <CircularProgress
              variant="indeterminate"
              color="secondary"
              size={ 24 }
            />
            <FormattedMessage
              description="Loading message in progressively loading select component."
              defaultMessage="Loading…"
            />
          </StyledLoadingIndicator>
        ) }
      </Select>
    </FormControl>
  );
};
