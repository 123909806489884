import styled from '@emotion/styled';
import { IconButton } from '@mui/material';

export const FloatButton = styled(IconButton)`
  float: right;
`;

interface ResponseWrapperProps {
  truncate: boolean;
}

export const ResponseWrapper = styled.div<ResponseWrapperProps>`
  p {
    margin: 0;
  }

  ${props => props.truncate && `
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  `}
`;

export const StyledTruncatingContent = styled.div<{ minWidth?: number }>`
  width: 100%;
  ${ props => props.minWidth && `min-width: ${ props.minWidth }px;` };
`;
