import { FC, MouseEventHandler, ReactNode } from 'react';

type Props = {
  onClick: MouseEventHandler;
  children?: ReactNode;
};

export const ConditionToggleButton: FC<Props> = ({
  onClick,
  children,
}) => {
  return (
    <button
      onClick={ onClick }
      css={ theme => ({
        color: theme.new.palette.primary['500'].main,
        fontWeight: 'regular',
        padding: 0,
        margin: 0,
        background: 'transparent',
        border: 'none',
        outline: 'none',
        display: 'inline',
      }) }
    >
      { children }
    </button>
  );
};
