import { default as React, FC, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { ContentEditorProps } from '../../../../Content/Model';
import { FileEditorContent } from '../../../Service';
import { VerticallySpaced } from '../../../../Common/Component';
import { useCheckboxChangeEventHandler } from '../../../../Common/Hook/useCheckboxChangeEventHandler';
import { Switch } from '../../../../Components';
import { MediaContentEditor } from '..';

export const FileContentEditor: FC<ContentEditorProps<FileEditorContent>> = ({
  editorContent,
  onEditorContentChanged,
  validation,
  onValidationChanged,
}) => {
  const whenMandatoryChanged = useCheckboxChangeEventHandler(useCallback(checked => (
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        mandatory: checked,
      },
    })
  ), [editorContent, onEditorContentChanged]));

  return (
    <VerticallySpaced gap={ 2 }>
      <MediaContentEditor
        mimeType="*"
        editorContent={ editorContent }
        onEditorContentChanged={ onEditorContentChanged }
        validation={ validation }
        onValidationChanged={ onValidationChanged }
      />
      <VerticallySpaced gap={ 1 }>
        <h4>
          <FormattedMessage
            id="broadcasts.content.file.fields.mandatory.label"
            description="Label for files mandatory checkbox in broadcast editor."
            defaultMessage="Mandatory"
          />
        </h4>
        <Switch
          checked={ editorContent.card.mandatory }
          onChange={ whenMandatoryChanged }
        />
      </VerticallySpaced>
    </VerticallySpaced>
  );
};
