import { FC, PropsWithChildren } from 'react';

import { NavigationIcon } from '..';
import { StyledInnerNavigationButton, StyledIconContainer, StyledCircleContainer } from './style';
import { UnlockedItemCircle } from '../../UnlockedItemCircle/UnlockedItemCircle';

type Props = {
  Icon: FC;
  active?: boolean;
  className?: string;
  hide?: boolean;
  unlockedIndex?: null | number;
};

export const InnerNavigationButton: FC<PropsWithChildren<Props>> = ({
  Icon,
  children,
  active = false,
  hide = false,
  unlockedIndex = null,
  className,
}) => (
  <StyledInnerNavigationButton
    className={ className }
    active={ active }
  >
    <StyledIconContainer>
      { !hide && (
        <NavigationIcon
          Icon={ Icon }
          showLock={ false }
        />
      ) }
      { unlockedIndex !== null && (
        <StyledCircleContainer>
          <UnlockedItemCircle index={ unlockedIndex }/>
        </StyledCircleContainer>
      ) }
    </StyledIconContainer>
    <span>{ children }</span>
  </StyledInnerNavigationButton>
);
