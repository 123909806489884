import { FC } from 'react';

import { SettingsCategoryForm, SettingsCategoryFormProps } from '../SettingsCategoryForm/SettingsCategoryForm';
import { BrandingSettingsSubCategoryForm } from '../BrandingSettingsSubCategoryForm/BrandingSettingsSubCategoryForm';

export const BrandingSettingsCategoryForm: FC<SettingsCategoryFormProps> = ({
  category,
  onSaved,
}) => (
  <SettingsCategoryForm
    category={ category }
    onSaved={ onSaved }
    customRendering={ {
      broadcastStackCover: BrandingSettingsSubCategoryForm
    } }
  />
);
