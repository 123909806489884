import { Theme as CoreMuiTheme, unstable_createMuiStrictModeTheme, Shadows } from '@mui/material/styles';
import { rgba } from 'polished';
import { Size } from '@ourpeople/shared/Core/Model/SizeKey';
import { defaultTheme } from 'op-storybook';

type BorderRadiusSizes = Size<string> & {
  max: string;
};

interface ExtendedMuiThemeProperties {
  new: typeof defaultTheme.new;
  borderRadius: BorderRadiusSizes;
  fontSize: Size<string>;
}

export interface MuiTheme extends CoreMuiTheme, ExtendedMuiThemeProperties {}

declare module '@mui/material/styles/createTheme' {
  type Theme = ExtendedMuiThemeProperties

  interface DeprecatedThemeOptions {
    new: typeof defaultTheme.new;
    borderRadius?: BorderRadiusSizes;
    fontSize?: Size<string>;
  }
}

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    navy: Palette['primary'];
    none: Palette['primary'];
    editorBackground: Palette['primary'];
    inboxBackground: Palette['primary'];
    transparent: {
      main: string;
      active: string;
      contrastText: string;
    };
  }

  interface PaletteOptions {
    navy: PaletteOptions['primary'];
    none: PaletteOptions['primary'];
    editorBackground: PaletteOptions['primary'];
    inboxBackground: PaletteOptions['primary'];
    transparent: {
      main: string;
      active: string;
      contrastText: string;
    };
  }
}

interface GetThemeProps {
  darkMode?: boolean;
  disablePortal?: boolean;
}

// We're using the unstable_createMuiStrictModeTheme method
// because of https://github.com/mui-org/material-ui/issues/13394
export const getTheme = (
  { darkMode, disablePortal }: GetThemeProps
): MuiTheme => {
  const defaultMuiTheme = unstable_createMuiStrictModeTheme();

  return ({
    new: defaultTheme.new,
    borderRadius: {
      xs: `${ defaultMuiTheme.spacing(0.5) }`,
      sm: `${ defaultMuiTheme.spacing(1) }`,
      md: `${ defaultMuiTheme.spacing(2) }`,
      lg: `${ defaultMuiTheme.spacing(3.25) }`,
      xl: `${ defaultMuiTheme.spacing(4.5) }`,
      max: '99rem',
    },
    fontSize: {
      xs: `${ defaultMuiTheme.spacing(1.75) }`,
      sm: `${ defaultMuiTheme.spacing(2) }`,
      md: `${ defaultMuiTheme.spacing(2.25) }`,
      lg: `${ defaultMuiTheme.spacing(2.5) }`,
      xl: `${ defaultMuiTheme.spacing(2.75) }`,
    },
    ...unstable_createMuiStrictModeTheme({

      // The primary brand colour on brand.ourpeople.com doesn't offer
      // good contrast for white from an accessibility standpoint
      // So, it's been darkened slightly. See below URL for detail
      // https://material.io/resources/color/#!/?view.left=1&view.right=0&primary.color=e00669&secondary.color=0896AB
      palette: {
        // Colours that differ depending on dark/light mode
        ...(
          darkMode
            ? {
              type: 'dark',
            }
            : {
              text: {
                primary: '#284B52',
              },
              background: {
                default: '#ECECEC',
                paper: '#FFFFFF',
              }
            }
        ),
        // Colours that apply to both dark and light mode
        navy: {
          light: '#A7CAD1',
          main: '#055A67',
          dark: '#0F3239',
          contrastText: '#fff',
        },
        primary: {
          light: '#ff5797',
          main: '#e00669',
          dark: '#a8003f',
          contrastText: '#fff',
        },
        secondary: {
          light: '#58c7dd',
          main: '#0896ab',
          dark: '#00687c',
          contrastText: '#fff',
        },
        transparent: {
          main: 'transparent',
          active: rgba('#e00669', 0.1),
          contrastText: '#e00669',
        },
        info: {
          light: '#58c7dd',
          main: '#0896ab',
          dark: '#00687c',
          contrastText: '#000',
        },
        error: {
          light: '#ff5a6d',
          main: '#df1642',
          dark: '#a5001c',
          contrastText: '#F8E8EC',
        },
        warning: {
          light: '#ffed6b',
          main: '#ffbb38',
          dark: '#ea7433',
          contrastText: '#fff',
        },
        success: {
          light: '#25b966',
          main: '#18a957',
          dark: '#00792c',
          contrastText: '#fff',
        },
        none: {
          main: defaultMuiTheme.palette.grey[500],
          dark: defaultMuiTheme.palette.grey[600],
          light: defaultMuiTheme.palette.grey[200],
          contrastText: defaultMuiTheme.palette.grey[100],
        },
        editorBackground: {
          main: '#9CD5DD',
        },
        inboxBackground: {
          main: '#D6E279',
        },
      },
      typography: {
        fontFamily: [
          'MuseoSansRounded',
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          'Oxygen',
          'Ubuntu',
          'Cantarell',
          'Fira Sans',
          'Droid Sans',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),
        fontSize: 16,
        button: {
          textTransform: 'none',
        },
      },
      shadows: [
        ...defaultMuiTheme.shadows.slice(0, 3),
        '0px 2px 4px 0px rgba(0, 0, 0, 0.2)',
        ...defaultMuiTheme.shadows.slice(4),
        //   box-shadow: 0 0 10px 0 rgba(0,0,0,0.15);
      ] as Shadows,
      components: {
        MuiButtonBase: {
          defaultProps: {
            disableRipple: true,
          },
        },
        MuiTextField: {
          defaultProps: {
            size: 'small',
          },
        },
        MuiSelect: {
          defaultProps: {
            size: 'small',
          },
        },
        MuiAutocomplete: {
          defaultProps: {
            size: 'small',
          },
        },
        ...disablePortal
          ? {
            MuiModal: {
              defaultProps: {
                disablePortal: true,
              },
            },
          }
          : {},
      },
    }),
  });
};
