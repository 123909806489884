import { FormattedMessage } from 'react-intl';
import { FC, ReactNode } from 'react';

import { AnonymousMessageHint, StyledFormContent } from './style';
import LockIcon from '../../../Assets/img/icons/streamline/shield-lock.svg';
import { FormContentDetail } from '../FormContentDetail/FormContentDetail';
import { ScrollArea } from '../FormContentDetail/style';

type Props = {
  currentIndex: number;
  cardCount: number;
  imageId?: string;
  imageFit?: 'cover' | 'contain';
  contentTitle: string;
  contentDescription?: string;
  anonymousSubmissionsEnabled: boolean;
  children?: ReactNode;
};

export const FormContent: FC<Props> = ({
  anonymousSubmissionsEnabled,
  ...props
}) => (
  <StyledFormContent>
    { anonymousSubmissionsEnabled && (
      <AnonymousMessageHint>
        <LockIcon/>
        <div>
          <div>
            <FormattedMessage
              description="Anonymous responses reminder"
              defaultMessage="Responses are anonymous"
            />
          </div>
          <div>
            <FormattedMessage
              description="Anonymous responses reminder"
              defaultMessage="Do not provide personal information"
            />
          </div>
        </div>
      </AnonymousMessageHint>
    ) }
    <ScrollArea center>
      <FormContentDetail
        { ...props }
      />
    </ScrollArea>
  </StyledFormContent>
);
