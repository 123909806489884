import { ChangeEvent, FC, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { TextField } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import {
  EmailAddressVerificationButton
} from '@ourpeople/shared/Account/Component/Input/EmailAddressVerificationButton/EmailAddressVerificationButton';
import {
  ValidationErrorHandler,
  ValidationErrorHandlerFactory
} from 'op-storybook/lib/utility/ValidationErrorHandlerFactory/ValidationErrorHandlerFactory';

import { VerifiableEmailAddress } from '../../../Models';
import { FieldValidationErrors, Flex } from '../../../Common/Component';
import { NotUniqueError, ValidationTree } from '../../../Common/Model';
import { ValidationErrorIdentifier } from '../../../Common/Utility/ValidationErrorIdentifier';

type Props = Omit<TextFieldProps, 'onChange'> & {
  emailAddress?: VerifiableEmailAddress;
  disabled: boolean;
  disableSendVerification?: boolean;
  busy?: boolean;
  onChange: (updatedEmailAddress: VerifiableEmailAddress) => void;
  onVerifyClicked?: () => void;
  validation?: ValidationTree<string> | undefined;
};

export const VerifiableEmailField: FC<Props> = ({
  emailAddress,
  disabled,
  disableSendVerification = false,
  busy = false,
  onChange,
  onVerifyClicked,
  validation,
  ...props
}) => {
  const intl = useIntl();

  const getCustomErrorHandlers = useCallback(() => [
    ValidationErrorHandlerFactory.createHandler(
      (error): error is NotUniqueError => (
        ValidationErrorIdentifier.isNotUniqueError(error)
      ),
      () => intl.formatMessage({
        description: 'Validation message when email address is not unique.',
        defaultMessage: 'Email address is already in use.',
      }),
    )
  ] as ValidationErrorHandler[], [intl]);

  const whenEmailAddressChanged = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => onChange({
      id: '',
      emailAddress: event.currentTarget.value || '',
      createdAt: '',
    }),
    [onChange],
  );

  return (
    <>
      <Flex
        gap={ 1 }
        noWrap
        fullWidth
      >
        <TextField
          { ...props }
          disabled={ disabled }
          onChange={ whenEmailAddressChanged }
          value={ emailAddress?.emailAddress || '' }
          fullWidth={ true }
        />
        <EmailAddressVerificationButton
          emailVerificationEnabled
          verifiableEmailAddress={ emailAddress }
          disableSendVerification={ disableSendVerification }
          onClick={ onVerifyClicked }
          busy={ busy }
        />
      </Flex>
      <FieldValidationErrors
        fieldName={ 'Email address' }
        customHandlers={ getCustomErrorHandlers() }
        validationErrors={ validation?.errors || [] }
      />
    </>
  )
};
