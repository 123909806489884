import { ChangeEvent, FunctionComponent, useCallback, useContext, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { TabContext, TabPanel } from '@mui/lab';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';

import { ArchivedTable, BroadcastTemplateDialog, DraftsTable, RecentTable, ScheduledTable } from '../../Component';
import { Padded, Tab } from '../../../Components';
import StackIcon from '../../../Assets/img/icons/streamline/layers.svg';
import ClockIcon from '../../../Assets/img/icons/streamline/time-clock-circle.svg';
import EditIcon from '../../../Assets/img/icons/streamline/common-file-edit.svg';
import FolderIcon from '../../../Assets/img/icons/streamline/archive-content.svg';
import { useQueryAsState } from '../../../Hooks';
import { StyledTabs } from '../../../Components/Navigation/styles';
import { ApiContext } from '../../../Contexts';
import { BroadcastAction, BroadcastsPermission, TableProps } from '../../Model';
import { ContentType } from '../../../Models';
import { Box, PageHeader } from '../../../Common/Component';
import { ArrayHelper, QueryParser } from '../../../Common/Utility';
import { usePermissions } from '../../../Security/Hook';
import { GuardedLink } from '../../../Security/Component';
import { useBroadcastTemplateGoogleEvents } from '../../Hook';
import { StyledTabContents } from './styles';
import { useContentDefinitionRegistry } from '../../../Content/Hook';

type BroadcastTab = 'recent' | 'drafts' | 'archived' | 'scheduled';

const defaultTeamIds: string[] = [];
const defaultContentTypes: ContentType[] = [];

export const ListPage: FunctionComponent = () => {
  const intl = useIntl();
  const api = useContext(ApiContext);
  const contentDefinitionRegistry = useContentDefinitionRegistry();
  const { trackViewTemplates } = useBroadcastTemplateGoogleEvents();
  const [templateSelectDialogOpen, setTemplateSelectDialogOpen] = useState<boolean>(false);
  const { permissionAvailable } = usePermissions();
  const [query, setQuery] = useQueryAsState();
  const teamIds = useMemo(() => query.teamIds?.split(',') || defaultTeamIds, [query]);
  const contentTypes = useMemo<string[]>(
    () => {
      return query.contentTypes?.split(',').reduce<string[]>(
        (validTypes, contentType) => {
          return !!contentDefinitionRegistry.get(contentType)
            ? validTypes.concat(contentType)
            : validTypes;
        },
        [],
      ) || defaultContentTypes;
    },
    [contentDefinitionRegistry, query.contentTypes],
  );
  const tab = query.tab && valueIsValidTab(query.tab) ? query.tab : 'recent';
  const [lastAction, setLastAction] = useState<BroadcastAction>();
  const [actionInProgress, setActionInProgress] = useState<boolean>(false);
  const [actionError, setActionError] = useState<boolean>(false);
  const [lastActionComplete, setLastActionComplete] = useState<Date>();

  const performAction = useCallback((
    request: () => Promise<unknown>,
  ) => {
    return request()
      .then(() => {
        return new Promise<void>(resolve => setTimeout(() => {
          setLastActionComplete(new Date());
          setActionInProgress(false);
          resolve();
        }, 50));
      })
      .catch(() => {
        setActionError(true);
        setActionInProgress(false);
      });
  }, [setLastActionComplete]);

  const setTab = useCallback((tab: string): void => {
    setQuery(query => ({
      ...query,
      tab,
    }));
  }, [setQuery]);

  const setPageNum = useCallback((pageNum: number): void => {
    if (pageNum.toString() !== query.pageNum) {
      setQuery(query => ({
        ...query,
        pageNum: pageNum.toString(),
      }));
    }
  }, [setQuery, query.pageNum]);

  const whenActionConfirmed = useCallback((action: BroadcastAction, broadcastId: string): Promise<unknown> => {
    if (!api || actionInProgress) {
      throw new Error();
    }

    setLastAction(action);
    setActionInProgress(true);
    setActionError(false);

    switch (action) {
      case BroadcastAction.DELETE:
        return performAction(() => api.delete(`/broadcasts/${ broadcastId }`));
      case BroadcastAction.STOP:
        return performAction(() => api.post(`/broadcasts/${ broadcastId }/stop`));
      case BroadcastAction.ARCHIVE:
        return performAction(() => api.post(`/broadcasts/${ broadcastId }/archive`))
          .then(() => setTab('archived'));
      case BroadcastAction.UNARCHIVE:
        return performAction(() => api.post(`/broadcasts/${ broadcastId }/unarchive`))
          .then(() => setTab('recent'));
      case BroadcastAction.SEND_TO_NEW:
        return performAction(() => api.post(`/broadcasts/${ broadcastId }/send-to-new`));
      case BroadcastAction.DUPLICATE:
        return performAction(() => api.post(`/broadcasts/${ broadcastId }/duplicate`))
          .then(() => setTab('drafts'));
    }
  }, [setTab, actionInProgress, performAction, api]);

  const tableProps: TableProps = useMemo(() => ({
    pageNum: QueryParser.pageNum(query),
    search: query.search || '',
    teamIds: teamIds,
    contentTypes,
    onActionConfirmed: whenActionConfirmed,
    onPageChanged: setPageNum,
    lastAction,
    actionError,
    actionInProgress,
  }), [
    query,
    teamIds,
    contentTypes,
    whenActionConfirmed,
    setPageNum,
    lastAction,
    actionError,
    actionInProgress,
  ]);

  const whenTabChanged = (event: ChangeEvent<unknown>, value: unknown) => {
    if (typeof value === 'string' && valueIsValidTab(value)) {
      setTab(value);
    }
  };

  const whenTemplateButtonClicked = useCallback(() => {
    trackViewTemplates('Broadcast');
    setTemplateSelectDialogOpen(true);
  }, [trackViewTemplates]);

  return (
    <Padded>
      <PageHeader
        items={ [
          {
            title: intl.formatMessage({
              id: 'section.broadcasts',
              description: 'Heading label for Broadcasts',
              defaultMessage: 'Broadcasts',
            })
          }
        ] }
      >
        { permissionAvailable(BroadcastsPermission.CREATE) && (
          <>
            { permissionAvailable(BroadcastsPermission.CATEGORIES_CREATE) && (
              <Button
                component={ GuardedLink }
                to="/broadcasts/categories"
                permissions={ [
                  BroadcastsPermission.CATEGORIES_CREATE
                ] }
              >
                <FormattedMessage
                  id="broadcasts.list.manageCategories"
                  description="Label for manage categories button"
                  defaultMessage="Manage categories"
                />
              </Button>
            ) }
            <Button
              variant="secondary"
              onClick={ whenTemplateButtonClicked }
            >
              <FormattedMessage
                id="broadcasts.list.viewTemplates"
                description="Label for view templates button on broadcast list page."
                defaultMessage="View templates"
              />
            </Button>
            <GuardedLink
              to="/broadcasts/create"
              permissions={ [
                BroadcastsPermission.CREATE,
              ] }
            >
              <Button variant="primary">
                <FormattedMessage
                  id="broadcasts.list.createLabel"
                  description="Label for create button on broadcast list page."
                  defaultMessage="Create"
                />
              </Button>
            </GuardedLink>
          </>
        ) }
      </PageHeader>
      <Box padded={ false }>
        <TabContext value={ tab.toString() }>
          <StyledTabs
            value={ tab }
            scrollButtons="auto"
            variant="scrollable"
            onChange={ whenTabChanged }
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab
              icon={ <StackIcon/> }
              label={ intl.formatMessage({
                id: 'broadcasts.recent',
                defaultMessage: 'Recent',
              }) }
              value="recent"
            />
            <Tab
              icon={ <ClockIcon/> }
              label={ intl.formatMessage({
                id: 'broadcasts.scheduled',
                defaultMessage: 'Scheduled',
              }) }
              value="scheduled"
            />
            <Tab
              icon={ <EditIcon/> }
              label={ intl.formatMessage({
                id: 'broadcasts.drafts',
                defaultMessage: 'Drafts',
              }) }
              value="drafts"
            />
            <Tab
              icon={ <FolderIcon/> }
              label={ intl.formatMessage({
                id: 'broadcasts.archived',
                defaultMessage: 'Archived',
              }) }
              value="archived"
            />
          </StyledTabs>
          <StyledTabContents>
            <TabPanel value="recent">
              <RecentTable
                { ...tableProps }
              />
            </TabPanel>
            <TabPanel value="drafts">
              <DraftsTable/>
            </TabPanel>
            <TabPanel value="archived">
              <ArchivedTable
                { ...tableProps }
              />
            </TabPanel>
            <TabPanel value="scheduled">
              <ScheduledTable
                lastActionComplete={ lastActionComplete }
                { ...tableProps }
              />
            </TabPanel>
          </StyledTabContents>
        </TabContext>
      </Box>
      <BroadcastTemplateDialog
        open={ templateSelectDialogOpen }
        onClose={ () => setTemplateSelectDialogOpen(false) }
      />
    </Padded>
  );
};

const valueIsValidTab = ArrayHelper.createTypeGuard<BroadcastTab>([
  'recent',
  'drafts',
  'scheduled',
  'archived',
]);
