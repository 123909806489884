import styled from '@emotion/styled';

const SELECTED_IMAGE_BORDER_WIDTH = '3px';

export const StyledImageAssetPicker = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  gap: ${props => props.theme.spacing(2)};
`;

export const StyledImageAssetList = styled.ul`
  margin: 0;
  padding: ${ SELECTED_IMAGE_BORDER_WIDTH };
  list-style: none;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-rows: 45px;
  overflow-y: auto;

  ${ props => props.theme.breakpoints.down('lg') } {
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: 4.5vw;
  }

  ${ props => props.theme.breakpoints.down('md') } {
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 7vw;
  }

  ${ props => props.theme.breakpoints.down('sm') } {
    grid-template-columns: 1fr;
    grid-auto-rows: 15vw;
  }
`;

export const StyledAttributionLinks = styled.ul`
  display: flex;
  column-gap: ${ props => props.theme.spacing(0.5) };
  flex-wrap: wrap;
`;

export const StyledAttribution = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: ${ props => `${ props.theme.spacing(0.5) } ${ props.theme.spacing(1) }` };
  background: linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%);
  transition: opacity 0.2s;
  padding-top: ${ props => props.theme.spacing(2) };

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  > ul > li {
    display: flex;
    flex-direction: column;

    > span {
      font-weight: bold;
    }
  }

  &,
  a {
    color: white;
  }
`;

export const StyledImageAsset = styled.li<{ selected: boolean; aspectRatio: number | undefined }>`
  overflow: hidden;
  outline: ${ SELECTED_IMAGE_BORDER_WIDTH } solid transparent;
  transition: outline-color 0.1s;
  position: relative;

  ${ props => props.selected && `outline: ${ SELECTED_IMAGE_BORDER_WIDTH } solid ${ props.theme.palette.primary.main };` }
  grid-row: span ${ props => getSpan(props.aspectRatio) };

  > img {
    width: 100%;
    object-fit: cover;
    object-position: center;
    height: 100%;
  }

  ${ StyledAttribution } {
    opacity: 0;
  }

  :hover ${ StyledAttribution } {
    opacity: 1;
  }
`;

const getSpan = (aspectRatio: number | undefined): number => {
  if (!aspectRatio) {
    return 5;
  }

  if (aspectRatio <= 0.7) {
    return 7;
  }

  if (aspectRatio <= 0.9) {
    return 6;
  }

  if (aspectRatio >= 1.6) {
    return 3;
  }

  if (aspectRatio >= 1.15) {
    return 4;
  }

  return 5;
};
