import { IntlShape } from 'react-intl';

import { SingleContentCard, EditorContent } from '../../../../Content/Model';
import CommentIcon from '../../../../Assets/img/icons/monochrome/content/meeting.svg';
import { MeetingEditor, MeetingPreview } from '../../../Component';
import { UniqueIdGenerator } from '../../../../Common/Utility';
import { CardTransformer } from '../../../../Content/Utility';
import { BroadcastContentDefinition, DraftEvent, Event } from '../../../Model';
import { EventCreator, EventTransformer } from '../../../Utility';
import { MeetingValidator } from '../../Validators';
import { AcceptResponse, DeclineResponse } from '../../../../Inbox/Model';
import { LiveMeetingContent } from '../../../../Inbox/Component';
import { EventParser } from '../../../Utility/EventParser';
import { EnvironmentSettings } from '../../../../Models';

export type Meeting<T extends string = 'eventcontent'> = Event<T> & {
  max_attendees: number;
};

export type DraftMeeting<T extends string = 'meetingcontent'> = DraftEvent<T> & {
  max_attendees: number;
};

export type MeetingEditorContent = EditorContent<DraftMeeting>;

export class MeetingDefinition implements BroadcastContentDefinition<Meeting, MeetingEditorContent, AcceptResponse | DeclineResponse> {
  public readonly id = 'meeting';
  public readonly availableToCreate = true;
  public readonly new = false;
  public readonly richText = false;
  public readonly contentTypes = ['eventcontent', 'meetingcontent'];
  public readonly categoryId = 'event';
  public readonly IconComponent = CommentIcon;
  public readonly EditorComponent = MeetingEditor;
  public readonly PreviewComponent = MeetingPreview;
  public readonly LiveComponent = LiveMeetingContent;
  public readonly SubmittedComponent = (): null => null;
  public validate = MeetingValidator.validate;

  public definesContentType = (type: string): boolean => (
    this.contentTypes.includes(type)
  );

  public getContentTitle = (intl: IntlShape, content: Meeting): string => EventParser.title(content);

  public getLocalisedType = (intl: IntlShape): string => (
    intl.formatMessage({
      id: 'broadcasts.content.Meeting.type',
      description: 'Localised name for Meeting content type.',
      defaultMessage: 'Meeting',
    })
  );

  public getDraftContentTitle = (intl: IntlShape, draftContent: DraftMeeting): string => EventParser.title(draftContent);

  public hasRequiredFeatureFlags = (environmentSettings: EnvironmentSettings): boolean => (
    !!environmentSettings.eventsEnabled
  );

  public createEditorContent = (): MeetingEditorContent => ({
    id: UniqueIdGenerator.generate(),
    card: {
      content: {
        ...EventCreator.createEventWithType('meetingcontent'),
        max_attendees: 3,
      },
      mandatory: false,
      image: null,
    },
  });

  public transformCard = (card: SingleContentCard<Meeting>): MeetingEditorContent => ({
    id: card.id,
    card: CardTransformer.transformCard(card, {
      ...EventTransformer.toDraft(card.content),
      type: 'meetingcontent',
      max_attendees: card.content.max_attendees,
    }),
  });
}
