import { IntlShape } from 'react-intl';

import { RichTextPlaceholderDefinition } from '../../Model/RichTextPlaceholderDefinition';
import { AuthDescription } from '../../../Models';

export class FirstNameRichTextPlaceholderDefinition implements RichTextPlaceholderDefinition {
  public static readonly placeholderString: string = 'recipient.firstName';
  public readonly placeholderString: string = FirstNameRichTextPlaceholderDefinition.placeholderString;
  public readonly localisedString: string = '';
  public readonly substitution: string = '';

  constructor(
    authDescription: AuthDescription,
    intl: IntlShape,
    public readonly substituteOnly: boolean = false,
  ) {
    this.localisedString = intl.formatMessage({
      description: 'Label for recipient first name placeholder in rich text editor.',
      defaultMessage: 'Recipient first name',
    });
    this.substitution = authDescription.user.forename;
  }
}
