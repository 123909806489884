import { useMemo } from 'react';

import { BroadcastReport } from '../../../../Models';
import { FetchResponse, useGet } from '../../../../Hooks';

export const useFetchBroadcastReport = (
  broadcastId: string,
): FetchResponse<BroadcastReport> => {
  const endpoint = useMemo(() => `/broadcasts/${broadcastId}/report`, [broadcastId]);
  return useGet<BroadcastReport>(endpoint);
}
