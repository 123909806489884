import { IntlShape } from 'react-intl';

import { SingleContentCard, DraftContent, EditorContent } from '../../../../Content/Model';
import CommentIcon from '../../../../Assets/img/icons/monochrome/content/comment.svg';
import { UniqueIdGenerator } from '../../../../Common/Utility';
import { CommentRequestValidator } from '../../Validators';
import { CardTransformer } from '../../../../Content/Utility';
import { CommentRequestEditor, CommentRequestPreview } from '../../../Component';
import { BroadcastContent, BroadcastContentDefinition } from '../../../Model';
import { LiveCommentContent } from '../../../../Inbox/Component/LiveContent/LiveCommentContent/LiveCommentContent';
import { TextResponse } from '../../../../Inbox/Model';
import { EnvironmentSettings } from '../../../../Models';

export type CommentRequest = BroadcastContent & {
  title: string;
  type: 'textquestion';
};

export type DraftCommentRequest = DraftContent & {
  title: string;
  type: 'textquestion';
  mandatory: boolean;
};

export type CommentEditorContent = EditorContent<DraftCommentRequest>;

export class CommentRequestDefinition implements BroadcastContentDefinition<CommentRequest, CommentEditorContent, TextResponse> {
  public readonly id = 'comment';
  public readonly availableToCreate = true;
  public readonly new = false;
  public readonly richText = false;
  public readonly contentTypes = ['textquestion'];
  public readonly categoryId = 'feedback';
  public readonly IconComponent = CommentIcon;
  public readonly EditorComponent = CommentRequestEditor;
  public readonly PreviewComponent = CommentRequestPreview;
  public readonly LiveComponent = LiveCommentContent;
  public readonly SubmittedComponent = (): null => null;
  public validate = CommentRequestValidator.validate;

  public definesContentType = (type: string): boolean => (
    this.contentTypes.includes(type)
  );

  public getContentTitle = (intl: IntlShape, content: CommentRequest): string => (
    content.title || this.getDefaultTitle(intl)
  );

  public getLocalisedType = (intl: IntlShape): string => (
    intl.formatMessage({
      id: 'broadcasts.content.file.type',
      description: 'Localised name for file content type.',
      defaultMessage: 'Comment',
    })
  );

  public getDraftContentTitle = (intl: IntlShape, draftContent: DraftCommentRequest): string => (
    draftContent.title || this.getDefaultTitle(intl)
  );

  public hasRequiredFeatureFlags = (environmentSettings: EnvironmentSettings): boolean => (
    !!environmentSettings.commentTypeEnabled
  );

  public createEditorContent = (): CommentEditorContent => ({
    id: UniqueIdGenerator.generate(),
    card: {
      content: {
        type: 'textquestion',
        title: '',
        mandatory: false,
      },
      mandatory: false,
      image: null,
    },
  });

  public transformCard = (card: SingleContentCard<CommentRequest>): CommentEditorContent => ({
    id: card.id,
    card: CardTransformer.transformCard(card, {
      type: 'textquestion',
      title: card.content.title,
      mandatory: card.mandatory,
    })
  });

  private getDefaultTitle = (intl: IntlShape): string => (
    intl.formatMessage({
      id: 'broadcasts.content.comment.defaultTitle',
      description: 'Default title for comment request.',
      defaultMessage: 'Untitled comment card',
    })
  );
}
