import { DraftFormRequest, FormRequest } from '../Content';

export const CHOICE_REQUEST_MIN_SELECTIONS_MIN = 1;
export const CHOICE_REQUEST_MIN_SELECTIONS_MAX = 100;
export const CHOICE_REQUEST_MAX_SELECTIONS_MIN = 1;
export const CHOICE_REQUEST_MAX_SELECTIONS_MAX = 100;

export const CHOICE_OPTION_TITLE_MAX_LENGTH = 4096;

type ChoiceOption = {
  id: string;
  title: string;
  colour?: string;
  index: number;
};

export type ChoiceRequest = FormRequest & {
  type: 'choiceRequest';
  style: 'dropdown' | 'checkbox';
  minSelections: number;
  maxSelections: number;
  options: ChoiceOption[];
};

export type DraftChoiceOption = {
  id?: string;
  title: string;
  colour?: string;
  index?: number;
};

export type DraftChoiceRequest = DraftFormRequest & {
  type: 'choiceRequest';
  style: 'dropdown' | 'checkbox';
  minSelections: number;
  maxSelections: number;
  options: DraftChoiceOption[];
};
