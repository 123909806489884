import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { AutocompleteCommonProps, AutocompleteFetchRequest, AutocompleteSelectionChanged } from '@ourpeople/shared/Core/Component/Input/Autocomplete/Autocomplete';
import { LocalisedString } from 'op-storybook/lib/model/LocalisedString/LocalisedString';

import { DebouncedAutocomplete } from '../../../Common/Component';

export interface InvitationTypeAutocompleteProps extends AutocompleteCommonProps {
  selectedIds: string[];
  allowedIds?: string[];
  onSelectionChanged: AutocompleteSelectionChanged<LocalisedString>;
}

export const InvitationTypeAutocomplete = ({
  selectedIds,
  allowedIds,
  onSelectionChanged,
  ...props
}: InvitationTypeAutocompleteProps): JSX.Element => {
  const intl = useIntl();
  const availableTypes = useMemo(() => [{
    id: 'sms',
    localisation: intl.formatMessage({
      id: 'invitationTypeAutocomplete.sms',
      defaultMessage: 'SMS',
    }),
  }, {
    id: 'email',
    localisation: intl.formatMessage({
      id: 'invitationTypeAutocomplete.email',
      defaultMessage: 'Email',
    }),
  }], [intl]);

  const whenFetch: AutocompleteFetchRequest<LocalisedString> = () => {
    return Promise.resolve({
      options: availableTypes
        .filter(type => !allowedIds || allowedIds.indexOf(type.id) > -1),
      pageCount: 1,
    });
  }

  return <DebouncedAutocomplete<LocalisedString>
    {...props}
    getOptionLabel={option => option.localisation}
    fetchOptions={useCallback(whenFetch, [allowedIds, availableTypes])}
    selectedIds={selectedIds}
    onSelectionChanged={onSelectionChanged}
    label={
      props.label !== undefined
        ? props.label
        : intl.formatMessage(
          {
            id: 'invitationTypeAutocomplete.label',
            defaultMessage: 'Select {limit, plural, one {type} other {types}}'
          },
          {
            limit: props.multiple ? Infinity : 1,
          }
        )
    }
    placeholder={
      props.placeholder ||
      intl.formatMessage(
        {
          id: 'invitationTypeAutocomplete.placeholder',
          defaultMessage: 'Search for {limit, plural, one {type} other {types}}'
        },
        {
          limit: props.multiple ? Infinity : 1,
        }
      )
    }
  />;
};
