import {AsYouType, CountryCode} from 'libphonenumber-js';

import {Username} from '../../../stories/components/UsernameField/UsernameField';

export class UsernameParser {
  public static usernameValueIsEmailAddress = (username: string): boolean => username.includes('@');

  public static parseUsername = (
    username: Username,
    defaultCountryCode: CountryCode,
  ): Username => {
    const asYouType = new AsYouType({
      defaultCountry: username.selectedType === 'email'
        ? defaultCountryCode
        : username.selectedType ?? defaultCountryCode,
    });
    asYouType.input(username.value.trim());
    const parsedPhoneNumber = asYouType.getNumber();

    return {
      ...username,
      selectedType: UsernameParser.usernameValueIsEmailAddress(username.value)
        ? 'email'
        : (parsedPhoneNumber?.country ?? username.selectedType ?? 'email'),
      parsedPhone: parsedPhoneNumber?.isValid()
        ? {
          determinedCountryCode: asYouType.country,
          nationalValue: parsedPhoneNumber.formatNational(),
          e164Value: parsedPhoneNumber.format('E.164')
        }
        : undefined
    };
  };
}
