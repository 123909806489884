import { ContextCreator } from '@ourpeople/shared/Core/Utility/ContextCreator';
import { FC, PropsWithChildren, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { RichTextPlaceholderDefinition } from '../Model/RichTextPlaceholderDefinition';
import {
  FirstNameRichTextPlaceholderDefinition
} from '../Service/RichTextPlaceholderDefinitions/FirstNameRichTextPlaceholderDefinition';
import { useAuthDescription } from '../../Core/Hook';
import {
  FullNameRichTextPlaceholderDefinition
} from '../Service/RichTextPlaceholderDefinitions/FullNameRichTextPlaceholderDefinition';
import {
  BroadcastLinkRichTextPlaceholderDefinition
} from '../Service/RichTextPlaceholderDefinitions/LinkPlaceholderDefinition';

type RichTextPlaceholderDefinitionRegistryContextValue = {
  getSubstitutionFromPlaceholder: (placeholder: string) => string | null;
  getLabelFromPlaceholder: (placeholder: string) => string | null;
  getDefinitions: () => RichTextPlaceholderDefinition[];
};

export const RichTextPlaceholderDefinitionRegistryContext = ContextCreator.withDisplayName<RichTextPlaceholderDefinitionRegistryContextValue>(
  'RichTextPlaceholderDefinitionRegistryContext',
  null,
);

export const RichTextPlaceholderDefinitionRegistryProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const intl = useIntl();
  const authDescription = useAuthDescription();
  const definitions: RichTextPlaceholderDefinition[] = useMemo(() => [
    ...(
      authDescription
        ? [
          new FirstNameRichTextPlaceholderDefinition(authDescription, intl),
          new FullNameRichTextPlaceholderDefinition(authDescription, intl),
          new BroadcastLinkRichTextPlaceholderDefinition(intl),
        ]
        : []
    ),
  ], [authDescription, intl]);

  const getSubstitutionFromPlaceholder = useCallback((placeholder: string): string | null => {
    const definition = definitions.find(definition => definition.placeholderString === placeholder);
    return definition?.placeholderString || null;
  }, [definitions]);

  const getLabelFromPlaceholder = useCallback((placeholder: string): string | null => {
    const definition = definitions.find(definition => definition.placeholderString === placeholder);

    if (!definition || definition.substituteOnly) {
      return null;
    }

    return definition.localisedString;
  }, [definitions]);

  const getDefinitions = useCallback(() => definitions, [definitions]);

  const providerValue: RichTextPlaceholderDefinitionRegistryContextValue = useMemo(() => ({
    getSubstitutionFromPlaceholder,
    getLabelFromPlaceholder,
    getDefinitions,
  }), [getSubstitutionFromPlaceholder, getLabelFromPlaceholder, getDefinitions]);

  return (
    <RichTextPlaceholderDefinitionRegistryContext.Provider
      value={ providerValue }
    >
      { children }
    </RichTextPlaceholderDefinitionRegistryContext.Provider>
  );
};
