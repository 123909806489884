import { Route, Switch } from 'react-router-dom';
import { FC, PropsWithChildren } from 'react';

import { VerifyEmailResultPage } from '../../../People/Routing/VerifyEmailResultPage/VerifyEmailResultPage';
import { SpaceContext } from '../../Context';
import { ApiContext } from '../../../Contexts';
import {
  FormSubmissionsForPdfExport
} from '../../../Forms/Component/FormSubmissionsForPdfExport/FormSubmissionsForPdfExport';
import {
  BroadcastPreviewForImageExport
} from '../../../Broadcasts/Component/BroadcastPreviewForImageExport/BroadcastPreviewForImageExport';
import { BroadcastContentDefinitionRegistryProvider } from '../../../Broadcasts/Provider';
import { VersionPage } from '../VersionPage/VersionPage';
import { DeactivatedCodePage } from '../../../Registrations/Component/DeactivatedCodePage/DeactivatedCodePage';

export const AnonymousRouter: FC<PropsWithChildren> = ({ children }) => (
  <Switch>
    <Route exact path="/version">
      <VersionPage/>
    </Route>
    <Route exact path="/verify-email/success">
      <VerifyEmailResultPage success={ true }/>
    </Route>
    <Route exact path="/verify-email/fail">
      <VerifyEmailResultPage success={ false }/>
    </Route>
    <Route exact path="/deactivated-code">
      <DeactivatedCodePage/>
    </Route>
    <Route
      exact
      path="/jpeg/broadcasts/:broadcastId"
    >
      <SpaceContext.Consumer>
        { ({ space }) => space && (
          <ApiContext.Consumer>
            { api => api && (
              <BroadcastContentDefinitionRegistryProvider>
                <BroadcastPreviewForImageExport/>
              </BroadcastContentDefinitionRegistryProvider>
            ) }
          </ApiContext.Consumer>
        ) }
      </SpaceContext.Consumer>
    </Route>
    <Route
      exact
      path="/pdf/forms/:formId/submissions"
    >
      <SpaceContext.Consumer>
        { ({ space }) => space && (
          <ApiContext.Consumer>
            { api => api && (
              <FormSubmissionsForPdfExport/>
            ) }
          </ApiContext.Consumer>
        ) }
      </SpaceContext.Consumer>
    </Route>
    <Route path="/">
      { children }
    </Route>
  </Switch>
);
