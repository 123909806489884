import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { DateRangeError } from '../../Model';

interface Props {
  error: DateRangeError;
}

export const LocalisedDateRangeError: FunctionComponent<Props> = ({ error }) => {
  return (
    <>
      { error === DateRangeError.RANGE_TOO_LARGE && (
        <FormattedMessage
          id="input.period.error.rangeTooLarge"
          description="Error when range between 2 dates is greater than 28 days"
          defaultMessage="Date range must be under 28 days"
        />
      ) }
      { error === DateRangeError.START_INVALID && (
        <FormattedMessage
          id="input.period.error.startInvalid"
          description="Error when start date is invalid"
          defaultMessage="Start date invalid"
        />
      ) }
      { error === DateRangeError.END_INVALID && (
        <FormattedMessage
          id="input.period.error.endInvalid"
          description="Error when end date is invalid"
          defaultMessage="End date invalid"
        />
      ) }
      { error === DateRangeError.END_BEFORE_START && (
        <FormattedMessage
          id="input.period.error.endBeforeStart"
          description="Error when end date is before start date"
          defaultMessage="End date must be after start date"
        />
      ) }
    </>
  );
}
