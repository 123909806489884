import { FunctionComponent, useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@mui/material';

import { ApiContext } from '../../../Contexts';
import { TextMatcher } from '../../../Utility';
import { ImmutableMap } from '../../../Models/ImmutableMap';
import { Person } from '../../../Models';
import { PeopleRequestParams } from '../../Hook';
import { BulkInviteDialog, InviteRequestBodyWithFilters } from '..';
import { IntegrationDeactivateBehaviour, useIntegrationDeactivationBehaviour } from '../../../Common/Hook';

type Props = {
  people: ImmutableMap<string, Person>;
  showDelete: boolean;
  showInvite: boolean;
  enableInvite: boolean;
  bulkActionFilters: PeopleRequestParams;
  onBulkActionInitiated: (
    promise: Promise<unknown>,
    successMessage: string,
    failureMessage: string,
  ) => void;
}

export const PeopleTableActions: FunctionComponent<Props> = ({
  people,
  showDelete,
  showInvite,
  enableInvite,
  bulkActionFilters,
  onBulkActionInitiated,
}) => {
  const api = useContext(ApiContext);
  const intl = useIntl();
  const [showInvitePeopleDialog, setShowInvitePeopleDialog] = useState(false);
  const integrationDeactivationBehaviour = useIntegrationDeactivationBehaviour();

  const deleteSelectedPeople = () => {
    if (api) {
      const promptMessage = intl.formatMessage({
        id: 'people.actions.deletePrompt',
        description: 'Prompt presented to user when bulk deleting people',
        defaultMessage: 'Type "permanently delete" to delete {selectedPeople, plural, one {# person} other {# people}}'
      }, {
        selectedPeople: people.count,
      })?.toString() || '';

      const confirmation = prompt(promptMessage) || '';
      if (TextMatcher.caseInsensitive(confirmation, 'permanently delete')) {
        const filters = {
          ids: people.values.map(person => person.id)
        };
        onBulkActionInitiated(
          api.delete('/people', {
            data: {
              filters,
            },
          }),
          intl.formatMessage({
            id: 'people.actions.deleteSuccess',
            description: 'Message on successful bulk delete action.',
            defaultMessage: 'Selected {selectedPeople, plural, one {person} other {people}} deleted successfully.',
          }, {
            selectedPeople: people.count,
          }),
          intl.formatMessage({
            id: 'people.actions.deleteFailure',
            description: 'Message on failed bulk delete action.',
            defaultMessage: 'Could not delete selected {selectedPeople, plural, one {person} other {people}}.',
          }, {
            selectedPeople: people.count,
          }),
        );
      }
    }
  }

  const freezeSelectedPeople = (): void => {
    if (api) {
      onBulkActionInitiated(
        api.post('/people/freeze', {
          filters: {
            ...bulkActionFilters,
            ...(integrationDeactivationBehaviour === IntegrationDeactivateBehaviour.FREEZE ? { noExternallyManaged: true } : {}),
          },
        }),
        intl.formatMessage({
          id: 'people.actions.freezeSuccess',
          description: 'Message on successful bulk freeze action.',
          defaultMessage: 'Selected {selectedPeople, plural, one {person} other {people}} frozen successfully.',
        }, {
          selectedPeople: people.count,
        }),
        intl.formatMessage({
          id: 'people.actions.freezeFailure',
          description: 'Message on failed bulk freeze action.',
          defaultMessage: 'Could not freeze selected {selectedPeople, plural, one {person} other {people}}.',
        }, {
          selectedPeople: people.count,
        }),
      );
    }
  }

  const defrostSelectedPeople = (): void => {
    if (api) {
      onBulkActionInitiated(
        api.post('/people/unfreeze', {
          ...bulkActionFilters,
          ...(integrationDeactivationBehaviour === IntegrationDeactivateBehaviour.FREEZE ? { noExternallyManaged: true } : {}),
        }),
        intl.formatMessage({
          id: 'people.actions.unfreezeSuccess',
          description: 'Message on successful bulk unfreeze action.',
          defaultMessage: 'Selected {selectedPeople, plural, one {person} other {people}} unfrozen successfully.',
        }, {
          selectedPeople: people.count,
        }),
        intl.formatMessage({
          id: 'people.actions.unfreezeFailure',
          description: 'Message on failed bulk unfreeze action.',
          defaultMessage: 'Could not unfreeze selected {selectedPeople, plural, one {person} other {people}}.',
        }, {
          selectedPeople: people.count,
        }),
      );
    }
  }

  const whenInviteConfirmed = (inviteRequestBody: InviteRequestBodyWithFilters): void => {
    if (api) {
      setShowInvitePeopleDialog(false);
      onBulkActionInitiated(
        api.post('/people/invite', inviteRequestBody),
        intl.formatMessage({
          id: 'people.actions.inviteSuccess',
          description: 'Message on successful bulk invite action.',
          defaultMessage: 'Selected {selectedPeople, plural, one {person} other {people}} queued for invitation successfully.',
        }, {
          selectedPeople: people.count,
        }),
        intl.formatMessage({
          id: 'people.actions.inviteFailure',
          description: 'Message on failed bulk invite action.',
          defaultMessage: 'Could not queue invitation for selected {selectedPeople, plural, one {person} other {people}}.',
        }, {
          selectedPeople: people.count,
        }),
      );
    }
  };

  return (
    <>
      <p>
        <FormattedMessage
          id="people.actions.actionsLabel"
          description="Text preceding bulk actions in import table"
          defaultMessage="Actions"
        />
      </p>
      <Button
        color="primary"
        variant="outlined"
        onClick={ freezeSelectedPeople }
      >
        <FormattedMessage
          id="people.actions.freeze"
          description="Freeze bulk action CTA"
          defaultMessage="Freeze"
        />
      </Button>
      <Button
        color="primary"
        variant="outlined"
        onClick={ defrostSelectedPeople }
      >
        <FormattedMessage
          id="people.actions.unfreeze"
          description="Unfreeze bulk action CTA"
          defaultMessage="Unfreeze"
        />
      </Button>
      {
        showDelete && (
          <Button
            disabled={ !people.count }
            color="primary"
            variant="outlined"
            onClick={ deleteSelectedPeople }
          >
            <FormattedMessage
              id="people.actions.delete"
              description="Delete bulk action CTA"
              defaultMessage="Delete"
            />
          </Button>
        )
      }
      {
        showInvite && (
          <>
            <Button
              disabled={ !enableInvite }
              color="primary"
              variant="outlined"
              onClick={ () => setShowInvitePeopleDialog(true) }
            >
              <FormattedMessage
                id="people.actions.invite"
                description="Invite bulk action CTA"
                defaultMessage="Invite"
              />
            </Button>
            {
              showInvitePeopleDialog && (
                <BulkInviteDialog
                  selectedPeople={ people }
                  filters={ bulkActionFilters }
                  open={showInvitePeopleDialog}
                  onConfirmed={ whenInviteConfirmed }
                  onDismissed={ () => setShowInvitePeopleDialog(false) }
                />
              )
            }
          </>
        )
      }
    </>
  )
}
