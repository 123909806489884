import { FormattedMessage } from 'react-intl';
import { Button } from '@mui/material';

import { MappedSettingTypes, Setting, SettingType } from '../Types';
import {
  StyledResetButton,
  StyledSettingDefaultValue,
  StyledSettingDescription, StyledSettingDetails, StyledSettingInput,
  StyledSettingName,
  StyledSettingRoot
} from './styles';
import { MappedSettingInput, MappedSettingValue } from './Settings';

type Props<T extends SettingType> = {
  setting: Setting<T>,
  onValueChanged: (value: MappedSettingTypes[T]['value']) => void,
  onValueReset: () => void,
};

export const CategorySetting = <T extends SettingType>({
  setting,
  onValueChanged,
  onValueReset,
}: Props<T>): JSX.Element => {
  return (
    <StyledSettingRoot>
      <StyledSettingDetails>
        <StyledSettingName>
          <label htmlFor={'setting-' + setting.key}>{setting.name}</label>
        </StyledSettingName>
        <StyledSettingInput>
          <MappedSettingInput
            type={setting.type}
            config={setting.config}
            value={setting.value}
            onValueChanged={onValueChanged}
            focusId={'setting-' + setting.key}
          />
        </StyledSettingInput>
        <StyledSettingDescription>
          {setting.description}
        </StyledSettingDescription>
      </StyledSettingDetails>
      <StyledSettingDefaultValue>
        <MappedSettingValue
          type={setting.type}
          config={setting.config}
          value={setting.defaultValue}
        />
        <StyledResetButton>
          <Button
            color="primary"
            disabled={setting.usingDefault}
            onClick={onValueReset}
          >
            {
              <FormattedMessage
                id="settings.resetToDefault"
                description="Reset to default value button"
                defaultMessage="Reset"
              />
            }
          </Button>
        </StyledResetButton>
      </StyledSettingDefaultValue>
    </StyledSettingRoot>
  );
};

