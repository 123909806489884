import { FunctionComponent, ReactNode } from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';

import { TopBroadcastEngagementByPersonMetric } from '../../Model';
import { MetricContainer, MissingDataMessage, TableMetric } from '..';
import { TableCell, TableRow } from '../../../Components';
import { PersonParser } from '../../../Utility';
import { DeprecatedLink } from '../../../Common/Component';
import { GuardedLink } from '../../../Security/Component';
import { BroadcastsPermission } from '../../../Broadcasts/Model';

interface Props {
  metric: TopBroadcastEngagementByPersonMetric;
}

export const MostActiveAppUsersMetric: FunctionComponent<Props> = ({
  metric,
}) => {
  const intl = useIntl();

  return (
    <MetricContainer
      title={intl.formatMessage({
        id: 'dashboard.mostActiveAppUsers.heading',
        defaultMessage: 'Most active app users'
      })}
    >
      {
        metric.datasets.length
          ? (
            <TableMetric
              headerRow={
                <TableRow>
                  <TableCell>
                    <FormattedMessage
                      id="dashboard.mostActiveAppUsers.broadcast"
                      description="Heading for name column in most active app users table on dashboard."
                      defaultMessage="Name"
                    />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage
                      id="dashboard.mostActiveAppUsers.engagement"
                      description="Heading for engagement column in most active app users table on dashboard."
                      defaultMessage="Engagement"
                    />
                  </TableCell>
                </TableRow>
              }
              rows={ metric.datasets }
              rowRender={ dataset => (
                <TableRow key={ dataset.key }>
                  <TableCell>
                    <DeprecatedLink to={ `/people/${ dataset.metadata.person.id }/account` }>
                      { PersonParser.fullName(dataset.metadata.person) }
                    </DeprecatedLink>
                  </TableCell>
                  <TableCell>
                    {/* eslint-disable react/style-prop-object */}
                    <FormattedNumber
                      value={ dataset.data[0]?.value || 0 }
                      style="percent"
                    />
                    {/* eslint-enable react/style-prop-object */}
                  </TableCell>
                </TableRow>
              ) }
            />
          )
          : (
            <MissingDataMessage>
              <FormattedMessage
                id="dashboard.mostActiveAppUsers.noData"
                defaultMessage="<p>No people were active in this period.</p><p>Try <BroadcastsLink>sending more broadcasts</BroadcastsLink> or <InviteLink>inviting more people</InviteLink>.</p>"
                values={{
                  BroadcastsLink: (chunks: ReactNode) => (
                    <GuardedLink
                      permissions={[BroadcastsPermission.CREATE]}
                      to="/broadcasts/create"
                    >
                      { chunks }
                    </GuardedLink>
                  ),
                  InviteLink: (chunks: ReactNode) => (
                    <DeprecatedLink to="/people/create">
                      { chunks }
                    </DeprecatedLink>
                  ),
                }}
              />
            </MissingDataMessage>
          )
      }
    </MetricContainer>
  );
};
