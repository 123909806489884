import { FC, PropsWithChildren } from 'react';

import { PageLayoutProvider } from '../../../Core/Provider/PageLayoutProvider';

export const FullPageLayoutTemplate: FC<PropsWithChildren> = ({ children }) => (
  <PageLayoutProvider
    desktopWidth="100%"
    controlOverflow={ false }
    active={ true }
    modal={ false }
    viewportBoundaries={ {
      top: true,
      left: true,
      right: true,
      bottom: true,
    } }
  >
    { children }
  </PageLayoutProvider>
);
