import { PropsWithChildren, useCallback, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import {
  AutocompleteCommonProps,
  AutocompleteFetchRequest,
  AutocompleteSelectionChanged
} from '@ourpeople/shared/Core/Component/Input/Autocomplete/Autocomplete';
import { LocalisedString } from 'op-storybook/lib/model/LocalisedString/LocalisedString';

import { StyledAutocomplete } from './style';

export type BasicAutocompleteProps<T extends string = string> = AutocompleteCommonProps & {
  options: LocalisedString<T>[];
  selectedIds: T[];
  allowedIds?: T[];
  onSelectionChanged: (selection: LocalisedString<T>[]) => void;
};

export const BasicAutocomplete = <T extends string>({
  options,
  selectedIds,
  allowedIds,
  onSelectionChanged,
  ...props
}: PropsWithChildren<BasicAutocompleteProps<T>>): JSX.Element => {
  const intl = useIntl();
  const whenFetch: AutocompleteFetchRequest<LocalisedString<T>> = () => {
    return Promise.resolve({
      options: options
        .filter((role) => !allowedIds || allowedIds.indexOf(role.id) > -1),
      pageCount: 1,
    });
  };

  const selectionRef = useRef<T[]>([]);

  useEffect(
    () => {
      if (selectionRef.current.join(',') === selectedIds.join(',')) {
        return;
      }

      selectionRef.current = selectedIds;

      const selectedOptions = options
        .filter(option => selectedIds.includes(option.id));

      onSelectionChanged(selectedOptions);
    },
    [selectionRef, onSelectionChanged, selectedIds, options],
  );

  const whenOptionsChanged: AutocompleteSelectionChanged<LocalisedString<T>> = useCallback(optionSelection => {
    if (optionSelection.options.map(option => option.id).join(',') !== selectedIds.join(',')) {
      onSelectionChanged(optionSelection.options);
    }
  }, [onSelectionChanged, selectedIds]);

  return (
    <StyledAutocomplete<LocalisedString<T>>
      multiple
      dense
      getOptionLabel={ option => option.localisation }
      fetchOptions={ useCallback(whenFetch, [allowedIds, options]) }
      selectedIds={ selectedIds }
      onSelectionChanged={ whenOptionsChanged }
      placeholder={ intl.formatMessage({
        id: 'basicAutocomplete.placeholder',
        description: 'Basic autocomplete placeholder',
        defaultMessage: 'Search...',
      }) }
      showChips={ false }
      { ...props }
    />
  );
};
