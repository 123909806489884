import { useIntl } from 'react-intl';

import { ContentType } from '../../../../Models';

export const useNotRespondedLabelForContentType = (contentType: ContentType): string => {
  const intl = useIntl();
  switch (contentType) {
    case 'image':
    case 'file':
    case 'styled_image':
      return intl.formatMessage({
        id: 'contentReport.notRespondedTab.fileContent.label',
        description: 'Label for file/image content not responded tab in content report summary',
        defaultMessage: 'Not viewed'
      });
    case 'audio':
      return intl.formatMessage({
        id: 'contentReport.notRespondedTab.audioContent.label',
        description: 'Label for audio content not responded tab in content report summary',
        defaultMessage: 'Not listened'
      });
    case 'video':
      return intl.formatMessage({
        id: 'contentReport.notRespondedTab.videoContent.label',
        description: 'Label for video content not responded tab in content report summary',
        defaultMessage: 'Not watched'
      });
    case 'text':
      return intl.formatMessage({
        id: 'contentReport.notRespondedTab.textContent.label',
        description: 'Label for text content not responded tab in content report summary',
        defaultMessage: 'Not read'
      });
    case 'sign':
      return intl.formatMessage({
        id: 'contentReport.notRespondedTab.signContent.label',
        description: 'Label for sign content not responded tab in content report summary',
        defaultMessage: 'Not signed'
      });
    case 'rating':
      return intl.formatMessage({
        id: 'contentReport.notRespondedTab.ratingContent.label',
        description: 'Label for rating content not responded tab in content report summary',
        defaultMessage: 'Not rated'
      });
    default:
      return intl.formatMessage({
        id: 'contentReport.notRespondedTab.label',
        description: 'Default label for not responded tab in content report summary',
        defaultMessage: 'Not responded'
      });
  }
};
