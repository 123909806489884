import styled from '@emotion/styled';

export const StyledLogo = styled.img`
  width: ${ props => props.theme.new.spacing[12] };
  height: ${ props => props.theme.new.spacing[12] };
  border-radius: ${ props => props.theme.new.borderRadius.standard };
`;

export const StyledSidebarHeader = styled.div<{ slim: boolean }>`
  transition: padding 0.2s;
  overflow: hidden;

  ${ props => props.slim
    ? `
      padding: ${ props.theme.new.spacing[4] } 0;
      display: flex;
      justify-content: center;
      align-items: center;
    `
    : `padding: ${ props.theme.new.spacing[4] };`
  }
`;
