import { ContextCreator } from '@ourpeople/shared/Core/Utility/ContextCreator';
import { FC, PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';

import { useContextOrThrow } from '../../../Core/Hook';
import { ApiContext } from '../../../Contexts';
import { PrepareStatus, UploadPrepareStatus } from '../../../Files/Hook';

type TranscodingProgressContextValue = {
  add: (uploadId: string) => void;
  getStatus: (uploadIds?: string[]) => PrepareStatus;
};

export const TranscodingProgressContext = ContextCreator.withDisplayName<TranscodingProgressContextValue>('TranscodingProgressContext', null);
export const TranscodingProgressProvider: FC<PropsWithChildren> = ({ children }) => {
  const api = useContextOrThrow(ApiContext);
  const [statuses, setStatuses] = useState<UploadPrepareStatus[]>([]);
  const timeoutRef = useRef<number>();
  const pendingUploadIdsRef = useRef<string[]>([]);

  useEffect(() => {
    pendingUploadIdsRef.current = statuses
      .filter(status => !['complete', 'failed'].includes(status.prepareStatus))
      .map(status => String(status.id))
  }, [pendingUploadIdsRef, statuses]);

  const beginPolling = useCallback(() => {
    if (timeoutRef.current) {
      return;
    }

    const poll = () => {
      if (!pendingUploadIdsRef.current.length) {
        timeoutRef.current && window.clearTimeout(timeoutRef.current);
        timeoutRef.current = undefined;
        return;
      }

      api.get<UploadPrepareStatus[]>(
        '/uploads/prepare-status',
        { params: { uploadIds: pendingUploadIdsRef.current.join(',') } }
      )
        .then(response => {
          setStatuses(statuses => (
            statuses
              .filter(status => !response.data.find(newStatus => status.id === newStatus.id))
              .concat(response.data)
          ));
        })
        .catch(() => {
          // Do nothing, check again after interval
        })
        .finally(() => {
          timeoutRef.current && window.clearTimeout(timeoutRef.current);
          if (!pendingUploadIdsRef.current.length) {
            return;
          }

          timeoutRef.current = window.setTimeout(poll, 5000);
        });
    };

    poll();
  }, [api, pendingUploadIdsRef]);

  useEffect(() => {
    beginPolling();
  }, [beginPolling, statuses]);

  const add = useCallback((uploadId: string) => {
    setStatuses(statuses => (
      statuses
        .filter(status => status.id !== uploadId)
        .concat({
          id: uploadId,
          prepareStatus: 'pending',
        })
    ));
  }, []);

  const getStatus = useCallback((uploadIds?: string[]) => {
    const statusSubset = uploadIds
      ? statuses.filter(status => uploadIds.includes(status.id))
      : statuses;

    if (!statusSubset.length) {
      return 'complete';
    }

    statusSubset.sort((statusA, statusB) => {
      return prioritisedStatuses.indexOf(statusB.prepareStatus) - prioritisedStatuses.indexOf(statusA.prepareStatus)
    });

    return statusSubset[0].prepareStatus;
  }, [statuses]);

  return (
    <TranscodingProgressContext.Provider
      value={ { add, getStatus } }
    >
      { children }
    </TranscodingProgressContext.Provider>
  );
};

const prioritisedStatuses = [
  'pending',
  'running',
  'failed',
  'complete',
];
