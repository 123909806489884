import styled from '@emotion/styled';
import { Chip, ChipProps } from '@mui/material';

import { FeedbackSeverity } from '../Notice/Notice';

interface StyledStatusChipProps extends ChipProps {
  status: FeedbackSeverity;
}

export const StatusChip = styled(
  ({status, color, ...props}: StyledStatusChipProps) => (
    <Chip {...props} />
  )
)`
  ${ props => props.size === 'small'
    ? `
      font-size: 0.8rem;
      padding: 0.25rem 0.35rem 0.15rem;
      margin-bottom: 0.1rem;
    `
    : 'padding: 0.35rem 0.75rem;'
  }
  height: initial;
  line-height: 1;
  ${props => {
    const backgroundColor = (
      props.status === 'none'
      ? props.theme.palette.grey[300]
      : props.theme.palette[props.status].main
    );

    return `
      background-color: ${backgroundColor};
      color: ${props.theme.palette.getContrastText(backgroundColor)};
    `;
  }}

  .MuiChip-label {
    padding: 0;
  }
`;
