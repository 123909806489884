import { ComponentProps, FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { keyframes } from '@emotion/react';

import { Avatar } from '../Avatar/Avatar';
import { StyleBuilder } from '../../../lib/model/StyleBuilder/StyleBuilder';

type Props = ComponentProps<typeof Avatar> & {
  waitForName?: boolean;
  waitForSrc?: boolean;
};

export const AsynchronousAvatar: FC<PropsWithChildren<Props>> = ({
  waitForName = false,
  waitForSrc = false,
  src,
  name,
  onLoad,
  onError,
  children,
  ...avatarProps
}) => {
  const nameReady = !waitForName || !!name;
  const [imageError, setImageError] = useState(false);
  const waitForImage = waitForSrc && !imageError;
  const [imageReady, setImageReady] = useState<boolean>(!waitForImage);
  const ready = imageReady && nameReady;
  const styles = useMemo(() => buildStyles({ ready }), [ready]);

  useEffect(() => {
    setImageReady(!waitForImage || imageError);
  }, [waitForImage, imageError]);

  useEffect(() => {
    setImageError(false);
  }, [src]);

  return (
    <Avatar
      { ...avatarProps }
      src={ waitForImage ? src : null }
      name={ name }
      onLoad={ event => {
        setImageReady(true);
        onLoad && onLoad(event);
      } }
      onError={ event => {
        setImageError(true);
        onError && onError(event);
      } }
    >
      <div css={ styles.loadingFacade }/>
      { children }
    </Avatar>
  );
};

const skeletonAnimation = keyframes({
  '0%': {
    backgroundPosition: '-100%',
  },
  '40%, 100%': {
    backgroundPosition: '100%',
  },
});

const buildStyles: StyleBuilder<{ ready: boolean }> = ({ ready }) => ({
  loadingFacade: theme => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: theme.new.palette.grey[300].main,
    backgroundImage: `linear-gradient(90deg, ${ theme.new.palette.grey[300].main } 0%, ${ theme.new.palette.grey[200].main } 20%, ${ theme.new.palette.grey[200].main } 30%, ${ theme.new.palette.grey[300].main } 50%)`,
    backgroundSize: '200%',
    animation: `${ skeletonAnimation } 1.5s infinite linear`,
    opacity: ready ? 0 : 1,
    pointerEvents: ready ? 'none': 'initial',
    transition: 'opacity 200ms',
  }),
});
