import styled from '@emotion/styled';

import { css } from '../../../Styling/css';

type StyledDropzoneProps = {
  isDragActive?: boolean;
};

const BaseDropzone = css`
  text-align: center;
  padding: ${ props => props.theme.spacing(6) };
  border: 3px dashed ${props => props.theme.palette.grey[300]};
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${props => props.theme.spacing(2)};
  min-height: ${props => props.theme.spacing(32)};

  ${props => props.theme.breakpoints.down('sm')} {
    padding: ${props => props.theme.spacing(1)};
    gap: ${props => props.theme.spacing(1)};
  }
`;

export const StyledDropzone = styled.div<StyledDropzoneProps>`
  ${BaseDropzone}

  ${props => props.isDragActive && `border-color: ${props.theme.palette.grey[400]};`}
`;
