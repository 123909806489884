import { ComponentProps, FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Heading } from '@ourpeople/shared/Core/Component/Content';
import { BreakpointContext } from 'op-storybook/lib/providers/BreakpointProvider/BreakpointProvider';
import { Button } from 'op-storybook/stories/components/Button/Button';

import { StyledDetailPane, StyledTemplatePreview } from './style';
import { Flex, VerticallySpaced } from '../..';
import { Template } from '../../../Model';
import { useContextOrThrow } from '../../../../Core/Hook';

type Props = {
  template: Template;
  useButtonProps: ComponentProps<typeof Button>;
  previewPane: ReactNode;
};

export const TemplatePreview: FC<Props> = ({
  template,
  useButtonProps,
  previewPane,
}) => {
  const screenWidth = useContextOrThrow(BreakpointContext);
  const [showMobilePreviewPane, setShowMobilePreviewPane] = useState<boolean>(true);
  const togglePreviewPane = useCallback(() => setShowMobilePreviewPane(showMobilePreviewPane => !showMobilePreviewPane), []);

  useEffect(() => {
    if (!screenWidth.lessThan.sm) {
      setShowMobilePreviewPane(true);
    }
  }, [screenWidth.lessThan.sm]);

  return (
    <StyledTemplatePreview>
      <StyledDetailPane>
        <VerticallySpaced gap={ 2 }>
          <Heading type="h1">
            { template.name }
          </Heading>
          <Flex gap={ 2 }>
            <Button
              { ...useButtonProps }
              role="button"
              variant="primary"
            >
              <FormattedMessage
                id="templateSelectDialog.useTemplate"
                description="Label for use button of a template in the template preview dialog."
                defaultMessage="Use template"
              />
            </Button>
            { screenWidth.lessThan.sm && (
              <Button
                variant="secondary"
                onClick={ togglePreviewPane }
              >
                {
                  showMobilePreviewPane
                    ? (
                      <FormattedMessage
                        id="templateSelectDialog.readMore"
                        description="Label for read more button in the template preview dialog when description is not visible on mobile."
                        defaultMessage="Read more"
                      />
                    )
                    : (
                      <FormattedMessage
                        id="templateSelectDialog.showPreview"
                        description="Label for show preview button in the template preview dialog when preview is not visible on mobile."
                        defaultMessage="Show preview"
                      />
                    )
                }
              </Button>
            ) }
          </Flex>
          {
            screenWidth.lessThan.sm && showMobilePreviewPane
              ? previewPane
              : (
                <p>
                  { template.description }
                </p>
              )
          }
        </VerticallySpaced>
      </StyledDetailPane>
      { !screenWidth.lessThan.sm && previewPane }
    </StyledTemplatePreview>
  );
}
