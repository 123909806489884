import { ChangeEventHandler, useCallback, useMemo } from 'react';
import { LocalisedString } from 'op-storybook/lib/model/LocalisedString/LocalisedString';

import { ArrayHelper } from '../Utility';

type SelectChangeEventHandler = ChangeEventHandler<{ name?: string, value: unknown }>;

export const useSelectChangeEventHandler = <T extends string | number, M extends boolean>(
  handler: M extends true ? (value: T[]) => void : (value: T) => void,
  options: LocalisedString<T>[],
  multiple?: M,
): SelectChangeEventHandler => {
  const ids = useMemo(() => options.map(option => option.id), [options]);
  const isValidValue = useMemo(() => ArrayHelper.createTypeGuard(ids), [ids]);
  const filterInvalid = useMemo(() => ArrayHelper.createTypeFilter(isValidValue), [isValidValue]);

  return useCallback(event => {
    if (!multiple && typeof ['number', 'string'].includes(typeof event.target.value) && isValidValue(event.target.value as T)) {
      return (handler as (value: T) => void)(event.target.value as T);
    } else if (multiple && Array.isArray(event.target.value)) {
      return (handler as (value: T[]) => void)(filterInvalid(event.target.value as string[]));
    }
  }, [filterInvalid, handler, isValidValue, multiple]);
};
