import { PersonName } from '../../Models';
import { TextFormatter } from '..';

export class PersonParser {
  public static fullName(person: PersonName): string {
    return TextFormatter.spaceSeparated(person.firstName, person.lastName || '');
  }

  public static initials(person: PersonName): string {
    return (person.firstName.slice(0, 1) ?? '') + (person.lastName?.slice(0, 1) ?? '');
  }
}
