import styled from '@emotion/styled';

export type StyledPaginationContainerProps = {
  noPadding?: boolean;
}

export const StyledPaginationContainer = styled.div<StyledPaginationContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => props.noPadding === false && ('padding: 2rem 1rem;')}
`;
