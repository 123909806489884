import { FetchResponse, useGet } from '../../Hooks';
import { BroadcastCategory } from '../Model';
import { Paginated } from '../../Models';

export type FetchBroadcastCategoriesSort = 'name_asc' | 'name_desc';

export type FetchBroadcastCategoriesParams = {
  pageNum: number;
  sort: FetchBroadcastCategoriesSort;
  searchTerm?: string;
  noPublished?: 0 | 1;
  noUnpublished?: 0 | 1;
  noNotDefault?: 0 | 1;
  ids?: string;
}

export const useFetchBroadcastCategories = (
  params?: FetchBroadcastCategoriesParams,
  disabled?: boolean,
): FetchResponse<Paginated<'categories', BroadcastCategory>> => (
  useGet('/broadcasts/categories', params, undefined, disabled)
);
