import { ChangeEvent, FC, FormEvent, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { Button } from 'op-storybook/stories/components/Button/Button';
import { FirstNameField } from '@ourpeople/shared/Account/Component/Input/FirstNameField/FirstNameField';
import { LastNameField } from '@ourpeople/shared/Account/Component/Input/LastNameField/LastNameField';
import { EmailAddressField } from '@ourpeople/shared/Account/Component/Input/EmailAddressField/EmailAddressField';
import {
  PhoneNumberField,
  PhoneNumberFieldValue
} from '@ourpeople/shared/Account/Component/Input/PhoneNumberField/PhoneNumberField';
import {
  useMissingContactMethodErrorHandler
} from '@ourpeople/shared/Account/Hook/useMissingContactMethodErrorHandler';
import { FieldState, TextFieldState } from '@ourpeople/shared/Core/Model/Form';

import { StyledForm } from './style';

export interface RegistrationFormState {
  firstName: TextFieldState;
  lastName: FieldState<string | null>;
  email: TextFieldState;
  phone: FieldState<PhoneNumberFieldValue>;
}

type Props = {
  formState: RegistrationFormState;
  onChange: (formState: RegistrationFormState) => void;
  onSubmit: () => void;
  busy: boolean;
};

export const RegistrationForm: FC<Props> = ({
  formState,
  onChange,
  onSubmit,
  busy,
}) => {
  const missingContactMethodErrorHandler = useMissingContactMethodErrorHandler();
  const contactMethodErrorHandlers = useMemo(() => [missingContactMethodErrorHandler], [missingContactMethodErrorHandler]);
  const whenSubmitted = useCallback((event: FormEvent) => {
    event.preventDefault();
    onSubmit();
  }, [onSubmit]);

  const getFieldChangeHandler = useCallback((fieldName: keyof RegistrationFormState) => (
    (event: ChangeEvent<HTMLInputElement>) => (
      onChange({
        ...formState,
        [fieldName]: {
          value: event.currentTarget.value,
          errors: [],
        },
      })
    )
  ), [formState, onChange]);

  const whenFirstNameChanged = useCallback((value: string) => (
    onChange({
      ...formState,
      firstName: {
        ...formState.firstName,
        value,
      },
    })
  ), [formState, onChange]);

  const whenLastNameChanged = useCallback((value: string) => (
    onChange({
      ...formState,
      lastName: {
        ...formState.lastName,
        value: value || null,
      },
    })
  ), [formState, onChange]);

  return (
    <StyledForm
      id="registration-form"
      onSubmit={ whenSubmitted }
      noValidate
    >
      <Stack
        direction="column"
        gap={ 6 }
      >
        <FirstNameField
          value={ formState.firstName.value }
          onChange={ whenFirstNameChanged }
          errors={ formState.firstName.validation?.errors }
        />
        <LastNameField
          value={ formState.lastName.value || '' }
          onChange={ whenLastNameChanged }
          errors={ formState.lastName.validation?.errors }
        />
        <EmailAddressField
          value={ formState.email.value }
          onChange={ getFieldChangeHandler('email') }
          errors={ formState.email.validation?.errors }
          customHandlers={ contactMethodErrorHandlers }
        />
        <PhoneNumberField
          value={ formState.phone.value }
          onChange={ value => (
            onChange({
              ...formState,
              phone: {
                value,
                validation: {
                  errors: [],
                  children: {},
                },
              },
            })
          ) }
          errors={ formState.phone.validation?.errors }
          customHandlers={ contactMethodErrorHandlers }
        />
        <Button
          variant="primary"
          type="submit"
          form="registration-form"
          busy={ busy }
        >
          <FormattedMessage
            description="Label for submit button used in user-registration form."
            defaultMessage="Register"
          />
        </Button>
      </Stack>
    </StyledForm>
  );
};
