import React, { FunctionComponent } from 'react';
import { Divider } from '@mui/material';

import { DividerContainer } from './style';

interface Props {
  label: string;
}

export const LabelledDivider: FunctionComponent<Props> = ({
  label,
}) => {
  return (
    <DividerContainer>
      <Divider />
      {
        label && <span>{label}</span>
      }
    </DividerContainer>
  );
}
