export class RichTextComparer {
  public static htmlStringsAreEquivalent = (
      htmlA: string,
      htmlB: string,
      emptyValue: string,
  ): boolean => {
    const blankInputValues = [emptyValue, ''];

    return (blankInputValues.includes(htmlA) && blankInputValues.includes(htmlB))
      || htmlA.replaceAll('\n', '') === htmlB.replaceAll('\n', '')
  };
}
