import { Context, useContext } from 'react';

export const useContextOrThrow = <T>(context: Context<T>): NonNullable<T> => {
  const result = useContext(context);

  if (!result) {
    throw new Error(`${ context.displayName || 'Context' } used outside of provider.`);
  }

  return result ;
};
