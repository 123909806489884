import { FC, useCallback } from 'react';

import { SingleContentCard, LiveComponentProps } from '../../../../Content/Model';
import { TextRequest as TextRequestModel } from '../../../Model/ContentTypes';
import { TextRequest } from '../../Content';
import { ValidationMerger } from '../../../../Common/Utility/ValidationMerger';
import { ValidationTree, Validator } from '../../../../Common/Model';
import { Validate } from '../../../../Common/Utility';
import { useContextOrThrow } from '../../../../Core/Hook';
import { FormNavigationContext } from '../../../Provider/FormNavigationProvider';
import { DraftTextResponse } from '../../../Service';

export const LiveTextRequest: FC<LiveComponentProps<TextRequestModel, DraftTextResponse>> = ({
  card,
  response,
  onResponseChange,
  onResponseClear,
  validation,
  onValidationChange,
}) => {
  const { displayingValidation } = useContextOrThrow(FormNavigationContext);
  const whenTextChanged = useCallback((text: string) => {
    if (!text) {
      onResponseClear();
      onValidationChange({
        errors: [],
        children: {},
      });
      return;
    }

    const response: DraftTextResponse = {
      contentId: card.content.id,
      contentType: 'textRequest',
      text,
    };
    const newValidation = validateResponse(card, response);

    onResponseChange(response);
    onValidationChange(
      validation
        ? ValidationMerger.overwriteMerge(validation, newValidation)
        : newValidation
    );
  }, [card, onResponseChange, onResponseClear, onValidationChange, validation]);

  return (
    <TextRequest
      content={ card.content }
      mandatory={ card.mandatory }
      value={ response?.text || '' }
      onChange={ whenTextChanged }
      validation={ validation }
      displayValidation={ displayingValidation }
    />
  );
};

const validateResponse = (card: SingleContentCard<TextRequestModel>, response: DraftTextResponse): ValidationTree<DraftTextResponse> => ({
  errors: [],
  children: {
    text: {
      errors: new Validator<string>([
        text => Validate.maxLength(text, card.content.maxLength),
        text => Validate.minLength(text, card.content.minLength),
      ]).validate(response.text),
      children: {},
    },
  },
});
