import { useCallback, useMemo, useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';

import { UploadUrls, useMounted } from '../../Common/Hook';
import { useApi } from '../../Core/Hook';

export const useOpenUpload = (): [(uploadId: string, onError?: (error: AxiosError<unknown>) => void) => void, boolean] => {
  const mounted = useMounted();
  const api = useApi();
  const [opening, setOpening] = useState<boolean>(false);

  const whenFetchUrlsSuccessful = useCallback((response: AxiosResponse<UploadUrls>) => {
    setOpening(false);
    window.open(response.data.original, '_blank');
  }, []);

  const fetchUrls = useCallback((uploadId: string, onError?: (error: AxiosError<unknown>) => void) => {
    api.get<UploadUrls>(`/uploads/${ uploadId }/urls`)
      .then(response => {
        if (!mounted.current) {
          return;
        }

        setOpening(false);
        whenFetchUrlsSuccessful(response);
      })
      .catch((error: AxiosError) => {
        if (!mounted.current) {
          return;
        }

        if (!onError) {
          throw error;
        }

        onError(error)
      });
  }, [api, mounted, whenFetchUrlsSuccessful]);

  return useMemo(() => [
    (uploadId, onError) => {
      setOpening(true);
      fetchUrls(uploadId, onError);
    },
    opening,
  ], [fetchUrls, opening]);
};
