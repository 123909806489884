import { FC, PropsWithChildren } from 'react';

import { AudienceConditionDefinitionRegistryContext } from '../Context';
import { AudienceConditionDefinition} from '../Model';
import {
  AudienceConditionDefinitionRegistry,
  EveryoneConditionDefinition,
  NobodyConditionDefinition,
  PersonConditionDefinition,
  RoleConditionDefinition,
  StatusConditionDefinition,
  TagConditionDefinition
} from '../Service';

/** @deprecated Used in old audience builder */
export const AudienceConditionDefinitionRegistryProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const registry = new AudienceConditionDefinitionRegistry([
    new EveryoneConditionDefinition() as unknown as AudienceConditionDefinition,
    new NobodyConditionDefinition() as unknown as AudienceConditionDefinition,
    new PersonConditionDefinition() as unknown as AudienceConditionDefinition,
    new RoleConditionDefinition() as unknown as AudienceConditionDefinition,
    new StatusConditionDefinition() as unknown as AudienceConditionDefinition,
    new TagConditionDefinition() as unknown as AudienceConditionDefinition,
  ]);

  return (
    <AudienceConditionDefinitionRegistryContext.Provider value={ registry }>
      { children }
    </AudienceConditionDefinitionRegistryContext.Provider>
  );
};
