import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@mui/material';
import { Heading } from '@ourpeople/shared/Core/Component/Content';

import { ImportDetail } from '../../Model';
import { Box, Flex, FlexPullRight, Notice, NoticeProps, VerticallySpaced } from '../../../Common/Component';

type SummaryTableProps = {
  importDetail: ImportDetail;
  onImportButtonClicked: () => void;
}

export type SummaryTable = FunctionComponent<SummaryTableProps>;

type Props = SummaryTableProps & {
  additionalNotices?: (NoticeProps & { key: string })[];
  onReUploadButtonClicked: () => void;
  SummaryTable: SummaryTable;
}

export const SummaryStep: FunctionComponent<Props> = ({
  importDetail,
  additionalNotices = [],
  onReUploadButtonClicked,
  onImportButtonClicked,
  SummaryTable,
}) => (
  <Box margin={ false}>
    <VerticallySpaced gap={ 2 }>
      <Heading>
        <Flex>
          <FormattedMessage
            id="imports.summary"
            description="Title displayed over the summary step of imports."
            defaultMessage="Summary"
          />
          <FlexPullRight gap={ 2 }>
            <Button
              color="primary"
              variant="outlined"
              onClick={ onReUploadButtonClicked }
            >
              <FormattedMessage
                id="imports.re-upload"
                description="Label for re-upload button."
                defaultMessage="Re-upload"
              />
            </Button>
          </FlexPullRight>
        </Flex>
      </Heading>
      { !!additionalNotices.length && additionalNotices.map(noticeProps => <Notice { ...noticeProps }/>) }
      <SummaryTable
        importDetail={ importDetail }
        onImportButtonClicked={ onImportButtonClicked }
      />
    </VerticallySpaced>
  </Box>
);
