import { ContextCreator } from '@ourpeople/shared/Core/Utility/ContextCreator';
import { FC, PropsWithChildren, useMemo } from 'react';
import { useIntl } from 'react-intl';

import {
  AudienceConditionDefinitionRegistry
} from '../Service/AudienceConditionDefinitionRegistry/AudienceConditionDefinitionRegistry';
import { EveryoneConditionDefinition } from '../Service/ConditionDefinitions/EveryoneConditionDefinition';
import { NobodyConditionDefinition } from '../Service/ConditionDefinitions/NobodyConditionDefinition';
import { PersonConditionDefinition } from '../Service/ConditionDefinitions/PersonConditionDefinition';
import { RoleConditionDefinition } from '../Service/ConditionDefinitions/RoleConditionDefinition';
import { StatusConditionDefinition } from '../Service/ConditionDefinitions/StatusConditionDefinition';
import { RegionConditionDefinition } from '../Service/ConditionDefinitions/RegionConditionDefinition';
import { TeamConditionDefinition } from '../Service/ConditionDefinitions/TeamConditionDefinition';
import { DepartmentConditionDefinition } from '../Service/ConditionDefinitions/DepartmentConditionDefinition';
import { SkillConditionDefinition } from '../Service/ConditionDefinitions/SkillConditionDefinition';
import { JobTitleConditionDefinition } from '../Service/ConditionDefinitions/JobTitleConditionDefinition';

export const AudienceConditionDefinitionRegistryContext = ContextCreator.withDisplayName<AudienceConditionDefinitionRegistry>(
  'AudienceConditionDefinitionRegistryContext',
  null,
);

export const AudienceConditionDefinitionRegistryProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const intl = useIntl();
  const registry = useMemo(() => (
    new AudienceConditionDefinitionRegistry([
      new EveryoneConditionDefinition(intl),
      new NobodyConditionDefinition(intl),
      new PersonConditionDefinition(intl),
      new RoleConditionDefinition(intl),
      new StatusConditionDefinition(intl),
      new RegionConditionDefinition(intl),
      new DepartmentConditionDefinition(intl),
      new TeamConditionDefinition(intl),
      new SkillConditionDefinition(intl),
      new JobTitleConditionDefinition(intl),
    ])
  ), [intl]);

  return (
    <AudienceConditionDefinitionRegistryContext.Provider value={ registry }>
      { children }
    </AudienceConditionDefinitionRegistryContext.Provider>
  );
};
