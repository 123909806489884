import { ComponentProps, FC, SVGProps } from 'react';

import { PresentationIcon } from '../../../lib/components/PresentationIcon/PresentationIcon';
import { FeaturedGraphic } from '../../../lib/components/FeaturedGraphic/FeaturedGraphic';
import { ThemeSystemPaletteKey } from '../../../lib/types/Theme/Theme';

type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  colour?: ThemeSystemPaletteKey;
  IconComponent: FC<SVGProps<SVGSVGElement>>;
  variant?: Exclude<ComponentProps<typeof FeaturedGraphic>['variant'], 'modern-borderless'>;
};

export const FeaturedIcon: FC<Props> = ({
  size = 'sm',
  colour = 'primary',
  IconComponent,
  variant = 'modern',
}) => (
  <FeaturedGraphic
    variant={ variant }
    size={ size }
    colour={ colour }
  >
    <PresentationIcon
      IconComponent={ IconComponent }
      size={ size === 'sm' ? 4 : size === 'md' ? 5 : size === 'lg' ? 6 : 8 }
      palette={ {
        colour: variant === 'modern' ? 'grey' : colour,
        intensity: 600,
      } }
    />
  </FeaturedGraphic>
);
