import { addSeconds, formatISO, startOfDay, startOfWeek } from 'date-fns';

import { DateOffset, FixedDateOffset, RelativeDateOffset, RoundTo } from '../Model/DateOffset';

type DateOffsetParts = [DateOffset['type'], string] | ['relative', string, RoundTo, string];

export class DateOffsetParser {
  public static toIsoString = (dateOffset: DateOffset): string => {
    if (dateOffset.type === 'fixed') {
      return dateOffset.date;
    }

    const offsetDate = addSeconds(new Date(), dateOffset.offsetInSeconds);

    if (dateOffset.round === null) {
      return formatISO(offsetDate);
    }

    return formatISO(
      addSeconds(
        dateOffset.round.to === 'startOfDay'
          ? startOfDay(offsetDate)
          : startOfWeek(offsetDate, { weekStartsOn: 1 }),
        dateOffset.round.offsetInSeconds,
      ),
    );
  };

  public static fromString = (dateOffsetString: string): DateOffset => {
    const parts = dateOffsetString.split('_') as DateOffsetParts;
    return parts[0] === 'fixed'
      ? {
        type: parts[0],
        date: parts[1],
      }
      : {
        type: parts[0],
        offsetInSeconds: +parts[1],
        round: parts.length === 4
          ? {
            to: parts[2],
            offsetInSeconds: +parts[3],
          }
          : null,
      }
  };

  public static toString = (dateOffset: DateOffset): string => (
    `${ dateOffset.type }_${
      dateOffset.type === 'fixed'
        ? dateOffset.date
        : `${ dateOffset.offsetInSeconds }${
          dateOffset.round
            ? `_${ dateOffset.round.to }_${ dateOffset.round.offsetInSeconds }`
            : ''
        }`
    }`
  );

  public static fixed = (relativeDateOffset: RelativeDateOffset): FixedDateOffset => ({
    type: 'fixed',
    date: DateOffsetParser.toIsoString(relativeDateOffset),
  });
}
