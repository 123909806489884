import { useCallback, useEffect, useMemo, useState } from 'react';

export const useInfiniteLoadContainerRef = (callback: () => void, threshold: number = 500, cooldownMs: number = 1000) => {
  const [
    scrollThresholdCrossed,
    setScrollThresholdCrossed,
  ] = useState<boolean>(false);
  const [locked, setLocked] = useState<boolean>(false);

  const scrollContainerRef = useCallback((node: HTMLDivElement) => {
    if (!node) {
      return;
    }

    const onScroll = (event: Event) => {
      const eventTarget = event.currentTarget as HTMLDivElement | null;

      if (!eventTarget) {
        return;
      }

      const scrollBottom = eventTarget.scrollHeight - (
        eventTarget.scrollTop + eventTarget.clientHeight
      );

      setScrollThresholdCrossed(
        eventTarget.scrollHeight > eventTarget.clientHeight
        && scrollBottom < threshold,
      );
    };

    node.addEventListener('scroll', onScroll);
  }, [threshold]);

  useEffect(() => {
    if (!scrollThresholdCrossed || locked) {
      return;
    }

    setLocked(true);
    const timeout = setTimeout(() => setLocked(false), cooldownMs);
    callback();

    return () => {
      clearTimeout(timeout);
      setLocked(false);
    };
  }, [callback, scrollThresholdCrossed]);

  return useMemo(() => scrollContainerRef, [scrollContainerRef]);
};
