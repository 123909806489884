import styled from '@emotion/styled';

export const StyledSubmittedUploadRequest = styled.div`
  display: flex;
  gap: ${ props => props.theme.spacing(2) };
  flex-wrap: wrap;
  justify-content: flex-start;

  button {
    flex: 0 1 45%;

    span {
      height: 100%;
      width: 100%;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
`;
