import { FunctionComponent } from 'react';
import { AutocompleteCommonProps, AutocompleteSelectionChanged } from '@ourpeople/shared/Core/Component/Input/Autocomplete/Autocomplete';

import { Tag, TagType } from '../../Model';
import { TagAutocomplete } from '..';

interface Props extends AutocompleteCommonProps {
  initialValues?: Tag<TagType.Team>[];
  teamIds: string[];
  onSelectionChanged: AutocompleteSelectionChanged<Tag<TagType.Team>>;
}

export const TeamAutocomplete: FunctionComponent<Props> = ({
  initialValues,
  teamIds,
  onSelectionChanged,
  ...props
}) => {
  return (
    <TagAutocomplete
      type={ TagType.Team }
      selectedIds={ teamIds }
      onSelectionChanged={ onSelectionChanged }
      { ...props }
    />
  );
};
