import { FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { parseISO } from 'date-fns';

import { BroadcastContentFooter, StackNavigationProps } from '../../Footer/BroadcastContentFooter';
import { RespondButton, RespondButtonProps } from '../../RespondButton/RespondButton';
import { CardTitle, ScrollableContent } from '../../../../../Broadcasts/Component/Content';
import { ContentPadding, NudgeBanner } from '../..';
import { Signature } from '..';
import { VerticallySpaced } from '../../../../../Common/Component';
import { BroadcastNudge } from '../../../../../Broadcasts/Model';
import { AbsoluteBackButtonContainer } from '../../AbsoluteBackButtonContainer/AbsoluteBackButtonContainer';

type Props = {
  title: string;
  signature: string;
  signedAt: Date | null;
  submitProps: RespondButtonProps;
  onSignedAtChanged: (signedAt: Date | null) => void;
  responded?: boolean;
  navigationProps: StackNavigationProps;
  nudge?: BroadcastNudge;
  backButton: ReactNode | undefined;
};

export const SignBroadcastContent: FC<Props> = ({
  title,
  signature,
  signedAt,
  responded,
  submitProps,
  navigationProps,
  onSignedAtChanged,
  nudge,
  backButton,
}) => {
  const intl = useIntl();

  return (
    <>
      { backButton && <AbsoluteBackButtonContainer>{ backButton }</AbsoluteBackButtonContainer> }
      <ScrollableContent>
        {
          nudge && (
            <NudgeBanner
              message={ nudge.message }
              nudgedAt={ parseISO(nudge.at) }
            />
          )
        }
        <ContentPadding>
          <VerticallySpaced gap={ 6 }>
            <CardTitle>{ title }</CardTitle>
            <Signature
              signedAt={ signedAt }
              signature={ signature }
              onChange={ onSignedAtChanged }
              disabled={ responded }
            />
          </VerticallySpaced>
        </ContentPadding>
      </ScrollableContent>
      <BroadcastContentFooter { ...navigationProps }>
        { signedAt && (
          <RespondButton
            variant="primary"
            { ...submitProps }
            notRespondedLabel={ intl.formatMessage({
              id: 'content.broadcast.sign.notResponded',
              description: 'Label for sign card submit button when not responded.',
              defaultMessage: 'Submit',
            }) }
            respondedLabel={ intl.formatMessage({
              id: 'content.broadcast.sign.responded',
              description: 'Label for sign card submit button when responded.',
              defaultMessage: 'Submitted',
            }) }
            rejectedLabel={ intl.formatMessage({
              id: 'content.broadcast.sign.rejected',
              description: 'Label for sign card submit button when response rejected.',
              defaultMessage: 'Try again',
            }) }
          />
        ) }
      </BroadcastContentFooter>
    </>
  );
};
