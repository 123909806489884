import { useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { PermissionChecker } from '../Utility';
import { AuthContext } from '../../Contexts';
import { useContextOrThrow } from '../../Core/Hook';
import { LoginStateContext } from '../../Core/Provider/LoginStateProvider';
import {ELEVATE_PATH} from "../../Components";

interface PermissionsResult {
  permissionsGranted: (permissions: string[]) => boolean;
  permissionGranted: (permission: string) => boolean;
  permissionsRestricted: (permissions: string[]) => boolean;
  permissionRestricted: (permission: string) => boolean;
  permissionsAvailable: (permissions: string[]) => boolean;
  permissionAvailable: (permission: string) => boolean;
  anyPermissionAvailable: (permissions: string[]) => boolean;
  permissionPresent: (permission: string, excludePermissions: string[]) => boolean;
  guardedCallback: <R>(
    callback: () => R,
    permissions: string[],
  ) => (...args: unknown[]) => R|null;
}

export const usePermissions = (): PermissionsResult => {
  const { authDescription } = useContext(AuthContext);
  const { setLoginTarget } = useContextOrThrow(LoginStateContext);
  const history = useHistory();

  return useMemo(
    () => ({
      permissionsGranted: (permissions) => !!authDescription && PermissionChecker.permissionsSatisfied(
        permissions,
        authDescription.permissions.granted,
      ),
      permissionGranted: (permission) => !!authDescription && PermissionChecker.permissionSatisfied(
        permission,
        authDescription.permissions.granted,
      ),
      permissionsRestricted: (permissions) => !!authDescription && PermissionChecker.permissionsSatisfied(
        permissions,
        authDescription.permissions.restricted,
      ),
      permissionRestricted: (permission) => !!authDescription && PermissionChecker.permissionSatisfied(
        permission,
        authDescription.permissions.restricted,
      ),
      permissionsAvailable: (permissions) => !!authDescription && PermissionChecker.permissionsSatisfied(
        permissions,
        authDescription.permissions.granted.concat(authDescription.permissions.restricted),
      ),
      permissionAvailable: (permission) => !!authDescription && PermissionChecker.permissionSatisfied(
        permission,
        authDescription.permissions.granted.concat(authDescription.permissions.restricted),
      ),
      anyPermissionAvailable: permissions => !!authDescription && PermissionChecker.anyPermissionSatisfied(
        permissions,
        authDescription.permissions.granted.concat(authDescription.permissions.restricted),
      ),
      permissionPresent: (permission, excludePermissions: string[] = []) => !!authDescription && PermissionChecker.permissionPresent(
        permission,
        authDescription.permissions.granted.concat(authDescription.permissions.restricted),
        excludePermissions,
      ),
      guardedCallback: (callback, permissions) => {
        if (
          !authDescription
          || !PermissionChecker.permissionsSatisfied(
            permissions,
            authDescription.permissions.granted.concat(authDescription.permissions.restricted),
          )
        ) {
          return () => null;
        }

        if (PermissionChecker.permissionsSatisfied(permissions, authDescription.permissions.granted)) {
          return callback;
        }

        return () => {
          setLoginTarget(history.location.pathname, new URLSearchParams(history.location.search));
          console.debug(`Hook redirect to ${ELEVATE_PATH} due to missing permission.`, {permissions});
          history.push(ELEVATE_PATH);
          return null;
        };
      }
    }),
    [authDescription, history, setLoginTarget],
  );
};
