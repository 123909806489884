import { ReactNode } from 'react';
import {IntlShape} from 'react-intl';

import {ValidationErrorDefinition} from './ValidationErrorDefinition';
import {ValidationError} from '../ValidationError';

export class ValidationErrorDefinitionRegistry<R extends ReactNode | string = string | ReactNode> {
  constructor(
    public readonly errorDefinitions: ValidationErrorDefinition<any, R>[] = [],
  ) {}

  public formatMessage = (intl: IntlShape, error: ValidationError): undefined | R => (
    this.get(error.type)?.formatMessage(intl, error)
  );

  private get = (type: string): ValidationErrorDefinition<any, R> | undefined => (
    this.errorDefinitions.find(definition => definition.type === type)
  );
}
