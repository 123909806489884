import * as React from 'react';
import { FunctionComponent, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { TabBar } from 'op-storybook/stories/components/TabBar/TabBar';
import { BreakpointContext } from 'op-storybook/lib/providers/BreakpointProvider/BreakpointProvider';
import { StackEnd } from 'op-storybook/lib/components/StackEnd/StackEnd';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { Button } from 'op-storybook/stories/components/Button/Button';

import { TagsPermission, TagType } from '../../Model';
import { TextFormatter } from '../../../Utility';
import { TagParser } from '../../Utility';
import { NonTeamTagTable, TagTable } from '..';
import { useEnvironmentSettings } from '../../../Common/Hook';
import { CsvExportButtonWithDefaultErrorHandling } from '../../../Common/Component';
import { LocationHelper } from '../../../Common/Utility/LocationHelper';
import { useContextOrThrow } from '../../../Core/Hook';
import { ImportsPermission } from '../../../Imports/Model';
import { GuardedLink } from '../../../Security/Component';
import { usePermissions } from '../../../Security/Hook';

interface Props {
  type: TagType;
}

type TabData = {
  value: string;
  label: string;
};

export const TabbedTagsTables: FunctionComponent<Props> = ({
  type,
}) => {
  const intl = useIntl();
  const history = useHistory();
  const { url } = useRouteMatch();
  const { maxAllowedTeams } = useEnvironmentSettings();
  const screenWidth = useContextOrThrow(BreakpointContext);
  const { permissionsAvailable } = usePermissions();

  const getLocalisedTabTitle = (type: TagType): string => {
    return TextFormatter.capitalise(TagParser.localisedTagTypeFromInternalTagType(type, intl, 2));
  };

  const whenTabChanged = (tab: string): void => {
    history.push(`${ url }?type=${ tab }`);
  };

  const getTabDataForTagType = useCallback((tagType: TagType): TabData => ({
    value: tagType,
    label: getLocalisedTabTitle(tagType),
  }), [getLocalisedTabTitle]);

  const tabData = useMemo<TabData[]>(() => (
    (['region', 'team', 'department', 'jobtitle', 'skill'] as TagType[]).map(getTabDataForTagType)
  ), [getTabDataForTagType]);

  const getTabProps = useCallback((tabData: TabData) => ({
    css: { ':hover': { textDecoration: 'none' } },
    component: Link,
    to: LocationHelper.replaceSearchKey(history.location, 'type', tabData.value),
  }), [history.location]);

  return (
    <>
      <Stack { ...screenWidth.lessThan.sm ? { direction: 'column-reverse' } : {} }>
        <TabBar
          value={ type }
          onChange={ whenTabChanged }
          context="section"
          tabData={ tabData }
          getTabProps={ getTabProps }
          fitContent={ !screenWidth.lessThan.sm }
          spaceEvenly={ screenWidth.lessThan.sm }
        />
        <StackEnd>
          <Stack>
            { permissionsAvailable([ImportsPermission.ALL, TagsPermission.IMPORT]) && (
              <Button
                component={ GuardedLink }
                variant="secondary"
                to="/settings/tags/import"
                permissions={ [ImportsPermission.ALL, TagsPermission.IMPORT] }
              >
                <FormattedMessage
                  id="settings.tags.importCta"
                  defaultMessage="Import tags"
                />
              </Button>
            ) }
            <Button
              variant="primary"
              color="primary"
              component={ Link }
              to={ `/settings/tags/create?type=${ type }` }
            >
              <FormattedMessage
                id="settings.tags.cta"
                defaultMessage="Add tag"
              />
            </Button>
          </Stack>
        </StackEnd>
      </Stack>
      { type === 'region' && (
        <NonTeamTagTable>
          <TagTable
            type="region"
            childType="team"
          />
        </NonTeamTagTable>
      ) }
      { type === 'team' && (
        <TagTable
          maximumTags={ maxAllowedTeams }
          type="team"
          parentType="region"
          childType="department"
        />
      ) }
      { type === 'department' && (
        <NonTeamTagTable>
          <TagTable
            type="department"
            parentType="team"
            childType="jobtitle"
          />
        </NonTeamTagTable>
      ) }
      { type === 'jobtitle' && (
        <NonTeamTagTable>
          <TagTable
            type="jobtitle"
            parentType="department"
            childType="skill"
          />
        </NonTeamTagTable>
      ) }
      { type === 'skill' && (
        <NonTeamTagTable>
          <TagTable
            type="skill"
            parentType="jobtitle"
          />
        </NonTeamTagTable>
      ) }
      <CsvExportButtonWithDefaultErrorHandling
        endpoint="/team/tags/export"
        fileNamePrefix={ intl.formatMessage({
          id: 'tags.csvFileNamePrefix',
          defaultMessage: 'tag_export',
        }) }
      />
    </>
  )
};
