export class PromiseHelper {
  public static minimumDuration = <T>(promise: Promise<T>, minimumDurationMs: number = 500): Promise<T> => (
    Promise.allSettled([
      promise,
      new Promise(resolve => setTimeout(resolve, minimumDurationMs)),
    ])
      .then(([settleResult]) => {
        if (settleResult.status === 'rejected') {
          throw settleResult.reason;
        }

        return settleResult.value;
      })
  );
}
