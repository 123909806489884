import { ComponentProps, HTMLAttributes, JSX, useCallback, useMemo } from 'react';

import { Select } from './Select';
import { SvgComponent } from '../../../lib/model/SvgComponent';
import { PresentationIcon } from '../../../lib/components/PresentationIcon/PresentationIcon';
import { Stack } from '../../../lib/components/Stack/Stack';
import { LabelledFormField } from '../../../lib/components/input/LabelledFormField/LabelledFormField';

type Props<Option, Value> = {
  value: Value;
  options: Option[];
  onChange: (value: Value) => void;
  getOptionLabel?: (option: Option) => string;
  getOptionValue?: (option: Option) => Value;
  getOptionIcon?: (options: Option) => SvgComponent;
  getValueKey?: (value: Value) => string;
  buttonProps?: HTMLAttributes<HTMLButtonElement>;
} & ComponentProps<typeof LabelledFormField>;

export const SingleSelect = <Option, Value>({
  value,
  options,
  onChange,
  getOptionLabel = option => String(option),
  getOptionValue = option => option as unknown as Value,
  getValueKey = value => String(value),
  getOptionIcon,
  buttonProps,
  ...labelledFormFieldProps
}: Props<Option, Value>): JSX.Element => {
  const selectedOption = useMemo(() => (
    options.find(option => getValueKey(getOptionValue(option)) === getValueKey(value))
  ), [getOptionValue, getValueKey, options, value]);
  const selectedOptionArray = useMemo(() => selectedOption ? [selectedOption] : [], [selectedOption]);
  const buttonLabel = useMemo(() => getOptionLabel(selectedOption), [selectedOption, getOptionLabel]);

  const whenChanged = useCallback((selectedOptions: Option[]) => {
    !!selectedOptions.length && onChange(
      getOptionValue(selectedOptions[1] || selectedOptions[0])
    );
  }, [getOptionValue, onChange]);

  const buttonContents = useMemo(() => (
    <Stack
      fillParent
      gap={ 2 }
    >
      { getOptionIcon && (
        <PresentationIcon
          IconComponent={ getOptionIcon(selectedOption) }
          size={ 4 }
        />
      ) }
      { buttonLabel }
    </Stack>
  ), [buttonLabel, selectedOption, getOptionIcon]);

  return (
    <Select
      selectedItems={ selectedOptionArray }
      onChange={ whenChanged }
      options={ options }
      getOptionValue={ option => getValueKey(getOptionValue(option)) }
      getOptionLabel={ getOptionLabel }
      getOptionIcon={ getOptionIcon }
      buttonContents={ buttonContents }
      buttonProps={ buttonProps }
      { ...labelledFormFieldProps }
    />
  );
};
