import { Button } from '@mui/material';
import { FunctionComponent, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { DebouncedSearchInput } from '@ourpeople/shared/Core/Component/Input/DebouncedSearchInput/DebouncedSearchInput';

import { RequestState } from '../../../Models';
import CloseIcon from '../../../Assets/img/icons/monochrome/close.svg';
import { FilterOption } from '../../../Models/FilterOption';
import { ImportFilters, ImportFilterType } from '../ImportTable/ImportTable';
import { PickerFilter } from '../../../Components/Input/Filters/PickerFilter';
import { FilterChip } from '../../../Models/FilterChip';
import {
  FilterContainer,
  FilterRow,
  Flex,
  FlexPullRight,
  SelectionDescription,
  StyledChip
} from '../../../Common/Component';
import { Tag, TagType } from '../../../Tags/Model';
import { TagPicker } from '../../../Tags/Component';

type Props = {
  imported?: boolean,
  onFiltersReset: () => void,
  onAllowActionClicked: () => void,
  onClearSelectionClicked: () => void,
  onDenyActionClicked: () => void,
  onExpandSelectionClicked: () => void,
  onSearchQueryChanged: (searchQuery: string) => void,
  onActiveFiltersChanged: (activeFilters: ImportFilters) => void,
  pageNum?: number,
  pageSize?: number,
  resultCount?: number,
  searchQuery?: string,
  activeFilters: ImportFilters,
  selectedUserCount?: number,
  totalUserCount?: number,
  userRequestState?: RequestState,
}

export const ImportTableFilters: FunctionComponent<Props> = ({
  onFiltersReset,
  imported,
  onAllowActionClicked,
  onClearSelectionClicked,
  onDenyActionClicked,
  onExpandSelectionClicked,
  onSearchQueryChanged,
  onActiveFiltersChanged,
  pageNum = 1,
  pageSize = 0,
  resultCount = 0,
  searchQuery= '',
  activeFilters,
  selectedUserCount = 0,
  totalUserCount = 0,
  userRequestState,
}) => {
  const intl = useIntl();
  const [regions, setRegions] = useState<Tag<'region'>[]>([]);
  const [teams, setTeams] = useState<Tag<'team'>[]>([]);
  const [departments, setDepartments] = useState<Tag<'department'>[]>([]);
  const [jobTitles, setJobTitles] = useState<Tag<'jobtitle'>[]>([]);
  const [skills, setSkills] = useState<Tag<'skill'>[]>([]);
  const [activeFilterChips, setActiveFilterChips] = useState<FilterChip<ImportFilterType>[]>([]);
  const accessFilters: FilterOption[] = [
    {
      value: 'allow',
      label: intl.formatMessage({
        id: 'thirdPartyImport.filters.accessAllowed',
        description: 'Post-colon text in access allowed chip when filter is added',
        defaultMessage: 'Allowed',
      }),
    },
    {
      value: 'deny',
      label: intl.formatMessage({
        id: 'thirdPartyImport.filters.accessDenied',
        description: 'Post-colon text in access denied chip when filter is added',
        defaultMessage: 'Denied',
      }),
    },
  ];

  const whenActiveFiltersChanged = useCallback((type: ImportFilterType, filterOptions: FilterOption[]) => {
    const filterValues = filterOptions.map(filterOptions => filterOptions.value);
    const chipValuesOfType = activeFilterChips
      .filter(chip => chip.type === type)
      .map(chip => chip.filterOption.value);

    const chips = activeFilterChips
      .filter((chip) => chip.type !== type || filterValues.indexOf(chip.filterOption.value) !== -1)
      .concat(
        filterOptions
          .filter(filterOption => chipValuesOfType.indexOf(filterOption.value) === -1)
          .map(filterOption => ({type, filterOption}))
      );

    onActiveFiltersChanged({
      ...activeFilters,
      [type]: filterOptions,
    });

    setActiveFilterChips(chips);
  }, [activeFilterChips, activeFilters, onActiveFiltersChanged]);

  const getChipLabel = (chip: FilterChip<ImportFilterType>): string => {
    switch (chip.type) {
      case ImportFilterType.REGIONS:
        return intl.formatMessage(
          {
            description: 'Label for region filter chip in third-party import table.',
            defaultMessage: 'Region: { region }',
          },
          {
            region: chip.filterOption.label,
          },
        );
      case ImportFilterType.TEAMS:
        return intl.formatMessage(
          {
            id: 'import.filter.team',
            defaultMessage: 'Team: {team}',
          },
          {
            team: chip.filterOption.label,
          },
        );
      case ImportFilterType.DEPARTMENTS:
        return intl.formatMessage(
          {
            id: 'import.filter.department',
            defaultMessage: 'Department: {department}'
          },
          {
            department: chip.filterOption.label,
          },
        );
      case ImportFilterType.JOB_TITLES:
        return intl.formatMessage(
          {
            id: 'import.filter.jobTitle',
            defaultMessage: 'Job Title: {jobTitle}'
          },
          {
            jobTitle: chip.filterOption.label,
          },
        );
      case ImportFilterType.SKILLS:
        return intl.formatMessage(
          {
            description: 'Label for skill filter chip in third-party import table.',
            defaultMessage: 'Skill: { skill }',
          },
          {
            skill: chip.filterOption.label,
          },
        );
      case ImportFilterType.ACCESS:
        return intl.formatMessage(
          {
            id: 'import.filter.access',
            defaultMessage: 'Access: {access}'
          },
          {
            access: chip.filterOption.label,
          },
        );
      default:
        return '';
    }
  }

  const tagChipRemoved = (tagFilterChip: FilterChip<ImportFilterType>) => {
    const setTags = <T extends TagType>(tags: Tag<T>[]): Tag<T>[] => tags.filter(tag => tag.id !== tagFilterChip.filterOption.value);
    switch(tagFilterChip.type) {
      case ImportFilterType.TEAMS:
        setTeams(setTags);
        break;
      case ImportFilterType.DEPARTMENTS:
        setDepartments(setTags);
        break;
      case ImportFilterType.JOB_TITLES:
        setJobTitles(setTags);
        break;
      case ImportFilterType.SKILLS:
        setSkills(setTags);
        break;
      case ImportFilterType.REGIONS:
        setRegions(setTags);
        break;
    }
  }

  const removeChip = (chip: FilterChip<ImportFilterType>): void => {
    const chipRepresentsTag = [
      ImportFilterType.TEAMS,
      ImportFilterType.DEPARTMENTS,
      ImportFilterType.JOB_TITLES,
      ImportFilterType.SKILLS,
      ImportFilterType.REGIONS,
    ].indexOf(chip.type) !== -1;
    if (chipRepresentsTag) {
      tagChipRemoved(chip);
    }

    whenActiveFiltersChanged(
      chip.type,
      activeFilters[chip.type].filter((filter) => filter.value !== chip.filterOption.value),
    );
  }

  const whenClearButtonClicked = (): void => {
    onFiltersReset();
    setRegions([]);
    setDepartments([]);
    setTeams([]);
    setJobTitles([]);
    setSkills([]);
    setActiveFilterChips([]);
  };

  const whenTagFilterChanged = useCallback((importFilterType: ImportFilterType, tags: Tag<TagType>[]): void => {
    whenActiveFiltersChanged(
      importFilterType,
      tags.map((tag) => ({
        value: tag.id,
        label: tag.name,
      })),
    );
  }, [whenActiveFiltersChanged]);

  const whenJobTitlesChanged = (jobTitles: Tag<'jobtitle'>[]): void => {
    setJobTitles(jobTitles);
    whenTagFilterChanged(
      ImportFilterType.JOB_TITLES,
      jobTitles,
    );
  };

  const whenDepartmentsChanged = (departments: Tag<'department'>[]): void => {
    setDepartments(departments);
    whenTagFilterChanged(
      ImportFilterType.DEPARTMENTS,
      departments,
    );
  };

  const whenTeamsChanged = (teams: Tag<'team'>[]): void => {
    setTeams(teams);
    whenTagFilterChanged(
      ImportFilterType.TEAMS,
      teams,
    );
  };

  const whenRegionsChanged = useCallback((regions: Tag<'region'>[]) => {
    setRegions(regions);
    whenTagFilterChanged(
      ImportFilterType.REGIONS,
      regions,
    );
  }, [whenTagFilterChanged]);

  const whenSkillsChanged = useCallback((skills: Tag<'skill'>[]) => {
    setSkills(skills);
    whenTagFilterChanged(
      ImportFilterType.SKILLS,
      skills,
    );
  }, [whenTagFilterChanged]);

  return (
    <FilterContainer>
      <Flex gap={ 1 }>
        {
          !!selectedUserCount
            ? (
              <>
                <div>
                  <FormattedMessage
                    id="importTable.actionsLabel"
                    description="Text preceding bulk actions in import table"
                    defaultMessage="Actions"
                  />
                </div>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={onAllowActionClicked}
                >
                  <FormattedMessage
                    id="importTable.allowActionText"
                    description="Text in allow bulk action button"
                    defaultMessage="Allow"
                  />
                </Button>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={onDenyActionClicked}
                >
                  <FormattedMessage
                    id="importTable.denyActionText"
                    description="Text in deny bulk action button"
                    defaultMessage="Deny"
                  />
                </Button>
              </>
            )
            : (
              <>
                <div>
                  <FormattedMessage
                    id="importTable.filterResultsLabel"
                    description="Text preceding filters in import table"
                    defaultMessage="Filter results"
                  />
                </div>
                <>
                  <TagPicker
                    selectedTags={ regions }
                    onSelectedTagsChanged={ whenRegionsChanged }
                    type="region"
                  />
                  <TagPicker
                    selectedTags={teams}
                    onSelectedTagsChanged={whenTeamsChanged}
                    type="team"
                  />
                  <TagPicker
                    selectedTags={departments}
                    onSelectedTagsChanged={whenDepartmentsChanged}
                    type="department"
                  />
                  <TagPicker
                    selectedTags={jobTitles}
                    onSelectedTagsChanged={whenJobTitlesChanged}
                    type="jobtitle"
                  />
                  <TagPicker
                    selectedTags={ skills }
                    onSelectedTagsChanged={ whenSkillsChanged }
                    type="skill"
                  />
                </>
                {
                  imported && (
                    <PickerFilter
                      filterOptions={accessFilters}
                      activeFilterOptions={activeFilters[ImportFilterType.ACCESS]}
                      onActiveFilterOptionsChanged={(filterOptions) => whenActiveFiltersChanged(ImportFilterType.ACCESS, filterOptions)}
                      label={intl.formatMessage({
                        id: 'thirdPartyImport.filters.access',
                        defaultMessage: 'Allow access',
                        description: 'Label for access filter dropdown',
                      }) }
                    />
                  )
                }
              </>
            )
        }
        <FlexPullRight>
          <DebouncedSearchInput
            onChange={onSearchQueryChanged}
            value={searchQuery}
          />
        </FlexPullRight>
      </Flex>
      {
        !!selectedUserCount && (
          <FilterRow>
            <SelectionDescription
              selectedCount={selectedUserCount}
              totalCount={totalUserCount}
              resultFiltered={!!activeFilterChips.length}
              currentPageCount={pageSize}
            />
            {
              selectedUserCount === totalUserCount
                ? (
                  <Button
                    color="primary"
                    onClick={onClearSelectionClicked}
                  >
                    <FormattedMessage
                      id="thirdPartyImport.filters.clearSelection"
                      description="Label for button to clear bulk action selection"
                      defaultMessage="Clear selection"
                    />
                  </Button>
                )
                : (
                  <Button
                    color="primary"
                    onClick={onExpandSelectionClicked}
                  >
                    <FormattedMessage
                      id="thirdPartyImport.filters.expandSelection"
                      description="Label for button to expand bulk action selection"
                      defaultMessage="Select all {totalUserCount} people on this list"
                      values={{
                        totalUserCount,
                      }}
                    />
                  </Button>
                )
            }
          </FilterRow>
        )
      }
      {
        !selectedUserCount && !!activeFilterChips.length && (
          <FilterRow>
            {
              userRequestState === RequestState.COMPLETE && (
                <strong>
                  <FormattedMessage
                    id="thirdPartyImport.filters.resultCount"
                    description="Message indicating number of results"
                    defaultMessage="{totalUserCount, plural, =0 {0 results} one {1 of # result} other {{startRecord}-{endRecord} of # results}}"
                    values={{
                      startRecord: (((pageNum - 1) * pageSize) + 1),
                      endRecord: (((pageNum - 1) * pageSize) + resultCount),
                      totalUserCount,
                    }}
                  />
                </strong>
              )
            }
            <strong>
              <FormattedMessage
                id="thirdPartyImport.filters.activeFilters"
                description="Label for filter row"
                defaultMessage="Active filters"
              />
            </strong>
            {
              activeFilterChips.map((filterChip) => <StyledChip
                clickable={ false }
                onDelete={ () => removeChip(filterChip) }
                variant="outlined"
                key={ `${filterChip.type}_${filterChip.filterOption.value}` }
                label={ getChipLabel(filterChip) }
                deleteIcon={ <CloseIcon/> }
              />)
            }
            <Button
              color="primary"
              onClick={ whenClearButtonClicked }
            >
              <FormattedMessage
                id="thirdPartyImport.filters.clear"
                defaultMessage="Clear all filters"
                description="Label for button that clears all filter chips on the third party import screen"
              />
            </Button>
          </FilterRow>
        )
      }
    </FilterContainer>
  )
};
