import { FunctionComponent, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { ImportSteps, SummaryTable, UploadStep } from '..';
import { LocalisedString } from '../../../Common/Model';
import { ImportErrorPresentationComponent } from '../../Model';
import { NoticeProps, StepperHeader, StepperItem } from '../../../Common/Component';

export type DropzoneComponent = FunctionComponent<{ onComplete: (importId: string) => void }>;

export type ImportProps = {
  importId?: string;
  Dropzone: DropzoneComponent;
  additionalNotices?: (NoticeProps & { key: string })[];
  SummaryTable: SummaryTable;
  ColumnMapErrors: ImportErrorPresentationComponent;
  ColumnErrors: ImportErrorPresentationComponent;
  PrepareErrors: ImportErrorPresentationComponent;
  RecordErrors: ImportErrorPresentationComponent;
  recordTypes: LocalisedString[];
};

export const Import: FunctionComponent<ImportProps> = ({
  importId,
  Dropzone,
  additionalNotices = [],
  SummaryTable,
  ColumnMapErrors,
  ColumnErrors,
  PrepareErrors,
  RecordErrors,
  recordTypes,
}) => {
  const { url } = useRouteMatch();
  const intl = useIntl();
  const history = useHistory();
  const steps = useMemo<StepperItem[]>(() => [
    {
      link: `${ url }`,
      title: intl.formatMessage({
        id: 'import.undeterminedSteps.upload',
        description: 'Upload step label in import stepper.',
        defaultMessage: 'Upload',
      }),
      render: () => (
        <UploadStep>
          <Dropzone
            onComplete={ (importId: string) => history.replace(`${ url }/${ importId }`) }
          />
        </UploadStep>
      ),
    },
    {
      link: `${ url }/map-columns`,
      title: intl.formatMessage({
        id: 'import.undeterminedSteps.mapColumns',
        description: 'Map columns step label in import stepper.',
        defaultMessage: 'Map columns',
      }),
      disabled: true,
      render: () => null,
    },
    {
      link: `${ url }/review`,
      title: intl.formatMessage({
        id: 'import.undeterminedSteps.review',
        description: 'Review step label in import stepper.',
        defaultMessage: 'Review',
      }),
      disabled: true,
      render: () => null,
    },
    {
      link: `${ url }/summary`,
      title: intl.formatMessage({
        id: 'import.undeterminedSteps.summary',
        description: 'Summary step label in import stepper.',
        defaultMessage: 'Summary',
      }),
      disabled: true,
      render: () => null,
    },
    {
      link: `${ url }/complete`,
      title: intl.formatMessage({
        id: 'import.stepper.complete',
        description: 'Complete step label in import stepper.',
        defaultMessage: 'Complete',
      }),
      disabled: true,
      render: () => null,
    },
  ], [Dropzone, history, intl, url]);

  return (
    importId
      ? (
        <ImportSteps
          importId={ importId }
          Dropzone={ Dropzone }
          additionalNotices={ additionalNotices }
          SummaryTable={ SummaryTable }
          ColumnMapErrors={ ColumnMapErrors }
          ColumnErrors={ ColumnErrors }
          PrepareErrors={ PrepareErrors }
          RecordErrors={ RecordErrors }
          recordTypes={ recordTypes }
        />
      )
      : (
        <StepperHeader
          steps={ steps }
        />
      )
  );
}
