import { FC } from 'react';

import { Stack } from '../../../lib/components/Stack/Stack';
import { PresentationIcon } from '../../../lib/components/PresentationIcon/PresentationIcon';
import CheckIcon from '../../../lib/assets/checks/check-filled.svg?react';
import { Typography } from '../Typography/Typography';

type Props = {
  colour: 'primary' | 'grey' | 'success';
  text: string;
};

export const CheckItemText: FC<Props> = ({
  colour,
  text,
}) => (
  <Stack gap={ 2 }>
    <PresentationIcon
      IconComponent={ CheckIcon }
      palette={ {
        colour,
        intensity: colour === 'grey' ? 300 : 600,
      } }
      size={ 5 }
    />
    <Typography
      palette={ {
        colour: 'grey',
        intensity: 600,
      } }
      weight="medium"
    >
      { text }
    </Typography>
  </Stack>
);
