import { useEffect } from 'react';
import { parseISO } from 'date-fns';

import { SubmittedComponent } from '../../../../Content/Model';
import { DateRequest as DateRequestModel } from '../../../Model/ContentTypes';
import { DateResponse } from '../../../Service';
import { DateRequest } from '../../Content';
import { NoResponseMessage } from '../NoResponseMessage/NoResponseMessage';
import { DateTimeFormatter } from '../../../../Utility';

export const SubmittedDateRequest: SubmittedComponent<DateResponse, DateRequestModel> = ({
  card,
  response,
  onFinish,
}) => {
  useEffect(() => {
    if (!onFinish) {
      return;
    }

    onFinish();
  }, [onFinish]);

  return (
    response
      ? (
        <DateRequest
          content={ card.content }
          value={
            card.content.includeTime
              ? DateTimeFormatter.dateTimeInput(parseISO(response.date.dt))
              : DateTimeFormatter.dateInput(parseISO(response.date.dt))
          }
          readOnly
        />
      )
      : <NoResponseMessage/>
  );
};
