import { ComponentProps, FC, forwardRef } from 'react';
import * as RadForm from '@radix-ui/react-form';

export const Form: FC<ComponentProps<typeof RadForm.Root>> = forwardRef(({
  children,
  ...props
}, ref) => (
  <RadForm.Root
    ref={ ref }
    { ...props }
  >
    { children }
  </RadForm.Root>
));
