import styled from '@emotion/styled';

export const StyledMainMenu = styled.div`
  padding: ${ props => props.theme.spacing(1.5) } 0 ${ props => props.theme.spacing(2.5) };
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const StyledNavigationItems = styled.ul`
  list-style: none;
  margin: 0;
  padding: ${ props => props.theme.spacing(2) } 0;
`;

export const StyledMenuItemDivider = styled.hr`
  background-color: ${ props => props.theme.palette.grey[300] };
  margin: 0 ${ props => props.theme.spacing(2) };
  color: transparent;
  border-bottom: none;
  border-left: none;
  border-right: none;
`;

export const StyledFooterContainer = styled.div`
  margin-top: auto;
`;
