import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Typography } from '@ourpeople/shared/Core/Component/Content/Typography/Typography';
import { FeaturedIcon } from 'op-storybook/stories/components/FeaturedIcon/FeaturedIcon';
import EmailIcon from '@ourpeople/shared/Asset/Icon/figma/mail-01.svg';

import { AnonymousSettings } from '../../Hook/useFetchAnonymousSettings';
import { RegistrationConfirmationTemplate } from '../RegistrationConfirmationTemplate/RegistrationConfirmationTemplate';

type Props = {
  anonymousSettings: AnonymousSettings;
  emailAddress: string;
};

export const EmailRegistrationConfirmation: FC<Props> = ({
  anonymousSettings,
  emailAddress,
}) => {
  const intl = useIntl();

  return (
    <RegistrationConfirmationTemplate
      anonymousSettings={ anonymousSettings }
      title={ intl.formatMessage({
        description: 'Title for registration confirmation page when approval is automatic and email was provided',
        defaultMessage: 'Check your email',
      }) }
      icon={
        <FeaturedIcon
          colour="grey"
          size="xl"
          variant="modern"
          IconComponent={ EmailIcon }
        />
      }
    >
      <Typography align="center">
        <FormattedMessage
          description="Registration confirmation page body when approval is automatic and email was provided"
          defaultMessage="We sent a welcome email with everything you need to get started to <strong>{ emailAddress }</strong>"
          values={ {
            emailAddress,
          } }
        />
      </Typography>
    </RegistrationConfirmationTemplate>
  );
};
