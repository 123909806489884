import { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { ResolvedAudienceSnippet } from '../../../Audiences/Model';
import { StyledMember, StyledMemberList, StyledTooltipMemberList } from './style';
import { PersonParser } from '../../../Utility';
import { DeprecatedTooltip } from '../../../Common/Component';
import { PersonAvatar } from '../../../Common/Component/PersonAvatar/PersonAvatar';

export type ShareAudienceProps = {
  audienceSnippet: ResolvedAudienceSnippet;
  authenticatedPersonId?: string;
};

export const ShareAudience: FC<ShareAudienceProps> = ({
  audienceSnippet,
  authenticatedPersonId,
}) => {
  const intl = useIntl();
  const nonUserPeople = useMemo(
    () => audienceSnippet.people.filter(person => person.id !== authenticatedPersonId),
    [audienceSnippet.people, authenticatedPersonId],
  );
  const visiblePeople = useMemo(
    () => nonUserPeople.slice(0, VISIBLE_AUDIENCE_MEMBER_COUNT),
    [nonUserPeople],
  );
  const userRemoved = nonUserPeople.length < audienceSnippet.audienceSize;
  const audienceSize = audienceSnippet.audienceSize - +userRemoved;

  return (
    <DeprecatedTooltip
      title={
        <>
          { intl.formatMessage({
            id: 'shareAudience.tooltip',
            description: 'Message prepended to audience member names in tooltip in shared audience component.',
            defaultMessage: 'Shared with { memberCount, plural, one {# person} other {# people} }:',
          }, {
            memberCount: audienceSize,
          }) }
          <StyledTooltipMemberList>
            { visiblePeople.map(person => (
              <li key={ person.id }>{ PersonParser.fullName(person) }</li>
            )) }
            { audienceSize > VISIBLE_AUDIENCE_MEMBER_COUNT && (
              <li>
                { intl.formatMessage({
                  id: 'shareAudience.tooltip.more',
                  description: 'Message at bottom of tooltip to indicate number of additional members',
                  defaultMessage: '+{moreMemberCount} more',
                }, {
                  moreMemberCount: audienceSize - VISIBLE_AUDIENCE_MEMBER_COUNT,
                }) }
              </li>
            ) }
          </StyledTooltipMemberList>
        </>
      }
    >
      <StyledMemberList>
        { visiblePeople.map(person => (
          <li
            css={ { width: 0, overflow: 'visible' } }
          >
            <PersonAvatar
              css={ {
                marginLeft: '-22px',
              } }
              person={ person }
              size="md"
            />
          </li>
        )) }
        { audienceSize > VISIBLE_AUDIENCE_MEMBER_COUNT && (
          <StyledMember>
            <div>
              +{ audienceSize - VISIBLE_AUDIENCE_MEMBER_COUNT }
            </div>
          </StyledMember>
        ) }
        { visiblePeople.length < VISIBLE_AUDIENCE_MEMBER_COUNT && Array.from(
          { length: VISIBLE_AUDIENCE_MEMBER_COUNT - visiblePeople.length },
          (_value, index) => (
            <li key={ index }/>
          )
        ) }
      </StyledMemberList>
    </DeprecatedTooltip>
  );
};

const VISIBLE_AUDIENCE_MEMBER_COUNT = 5;
