import styled from '@emotion/styled';

import { Box } from '../../../../Common/Component';

export const StyledAutocomplete = styled.div`
  width: 13.5rem;
`;

export const StyledBox = styled(Box)`
  overflow: hidden;
`;
