import { ComponentProps, FC, useMemo } from 'react';
import { Collapse } from '@mui/material';
import { NavItem } from '@ourpeople/shared/Core/Component/Navigation/NavItem/NavItem';

import { StyledNavItemGroup } from './style';
import { SvgComponent } from '../../../Model/SvgComponent';
import { useLocalStorageHookContext } from '../../../Hook/useLocalStorageHookContext';

export type NavGroup = {
  id: string;
  label: string;
  IconComponent: SvgComponent;
  children: ComponentProps<typeof NavItem>[];
};

type Props = {
  navGroup: NavGroup;
  iconOnly: boolean;
  onToggleSubMenu: () => void;
};

export const NavItemGroup: FC<Props> = ({
  navGroup,
  iconOnly,
  onToggleSubMenu,
}) => {
  const getStorageHook = useLocalStorageHookContext();
  const useNavGroupExpanded = getStorageHook<boolean>(`navigation.${ navGroup.id }.expanded`, true);
  const [expanded, setExpanded] = useNavGroupExpanded();
  const current = useMemo(() => (
    !!navGroup.children.find(child => child.current)
  ), [navGroup.children]);
  const count = useMemo<number | undefined>(() => (
    navGroup.children.reduce<number>(
      (total, child) => (
        total + (child.count || 0)
      ),
      0,
    ) || undefined
  ), [navGroup.children]);
  const contracted = !expanded || iconOnly;

  return (
    <StyledNavItemGroup
      expanded={ !contracted }
    >
      <NavItem
        count={ count }
        iconOnly={ iconOnly }
        labelTooltip={ iconOnly }
        active={ current }
        label={ navGroup.label }
        IconComponent={ navGroup.IconComponent }
        dropdownState={ contracted ? 'contracted' : 'expanded' }
        onClick={ () => {
          if (iconOnly) {
            return onToggleSubMenu();
          }

          setExpanded(expanded => !expanded);
        } }
      />
      <Collapse
        in={ !contracted }
      >
        <ul>
          { navGroup.children.map(child => (
            <li key={ child.id }>
              <NavItem { ...child } inset/>
            </li>
          )) }
        </ul>
      </Collapse>
    </StyledNavItemGroup>
  );
};
