import { ChangeEvent, FunctionComponent, useContext, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { AxiosError } from 'axios';

import { useFetchTagConnections } from '../../Hook';
import { ApiContext } from '../../../Contexts';
import { TagConnection } from '../../Model';
import { Tag } from '../../../Types';
import { ApiError, RequestState } from '../../../Models';
import { DeprecatedButton } from '../../../Components';
import { ConnectionList } from '..';
import { DeletionErrorMessage, LoadingSpinnerContainer, StyledTagDeletionDialog } from './styles';
import { LoadingSpinner } from '../../../Components';

interface Props {
  tag: Tag;
  onDelete: () => void;
  onDismiss: () => void;
}

export const TagDeletionDialog: FunctionComponent<Props> = ({
  tag,
  onDelete,
  onDismiss,
}) => {
  const intl = useIntl();
  const api = useContext(ApiContext);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [confirmationInputText, setConfirmationInputText] = useState<string>('');
  const [confirmationErrorVisible, setConfirmationErrorVisible] = useState<boolean>(false);
  const [deletionError, setDeletionError] = useState<string | null>(null);
  const [fetchTagConnectionsResponse, fetchTagConnectionsState] = useFetchTagConnections(tag.id);
  const connections = useMemo(
    () => {
      const connections = fetchTagConnectionsResponse?.content?.connections || [];
      return connections.reduce<TagConnection[]>((activeConnections, connection) => (
        connection.count
          ? activeConnections.concat(connection)
          : activeConnections
      ), [])
    },
    [fetchTagConnectionsResponse?.content?.connections],
  );

  const whenConfirmationInputChanged = (event: ChangeEvent<HTMLInputElement>): void => {
    setConfirmationInputText(event.target.value || '');
    setConfirmationErrorVisible(false);
  };

  const whenDeleteConfirmed = (): void => {
    if (!api) {
      return;
    }

    if (confirmationInputText !== getConfirmationString()) {
      setConfirmationErrorVisible(true);
      return;
    }

    setDeleting(true);
    setDeletionError(null);
    api.delete(`/tags/${ tag.id }`)
      .then(() => {
        setDeleting(false);
        onDelete();
      })
      .catch((error: AxiosError<ApiError>) => {
        setDeleting(false);
        setDeletionError(error.response?.data.error?.code || 'unknown');
      });
  };

  const getConfirmationString = (): string => {
    return intl.formatMessage({
      id: 'tag.delete.confirmationString',
      defaultMessage: 'permanently delete',
    });
  };

  const whenDismissed = (): void => {
    if (!deleting) {
      onDismiss();
    }
  };

  return (
    <StyledTagDeletionDialog
      open={ true }
      onBackdropClick={ whenDismissed }
    >
      <DialogTitle>
        <FormattedMessage
          id="tag.deleteModal.heading"
          defaultMessage="Delete Tag"
        />
      </DialogTitle>
      <DialogContent>
        {
          !!connections.length && (
            <FormattedMessage
              id="tags.deleteModal.body"
              defaultMessage="<strong>{tagName}</strong> can't be deleted because it has the following active connections.{connections}"
              values={ {
                tagName: tag.name,
                connections: <ConnectionList connections={ connections }/>,
              } }
            />
          )
        }
        {
          fetchTagConnectionsState === RequestState.FAILED && (
            <FormattedMessage
              id="tags.deleteModal.obtainTagConnectionsFailed"
              defaultMessage="{tagName} can't be deleted because it's active connections could not be determined."
              values={ {
                tagName: <strong>{ tag.name }</strong>,
              } }
            />
          )
        }
        {
          !connections.length && fetchTagConnectionsState === RequestState.COMPLETE && !deleting && (
            <>
              <FormattedMessage
                id="tags.deleteModal.deleteBody"
                description="Message in modal when tag has no active connections and can be deleted."
                defaultMessage={ `
                  <p>Deleting { tagName } is irreversible. It will be removed from any reports that have previously used it.</p>
                  <p>Any links to other tags will be automatically removed.</p>
                  <p>Please type { confirmationString } to confirm your deletion.</p>
                ` }
                values={ {
                  tagName: <strong>{ tag.name }</strong>,
                  confirmationString: <em>{ getConfirmationString() }</em>,
                } }
              />
              <TextField
                error={ confirmationErrorVisible }
                size="small"
                color="primary"
                variant="outlined"
                value={ confirmationInputText }
                onChange={ whenConfirmationInputChanged }
                helperText={
                  confirmationErrorVisible && (
                    <FormattedMessage
                      id="tags.deleteModal.confirmationError"
                      description="Error message when the users confirmation input doesn't match the expected text"
                      defaultMessage="The confirmation message did not match."
                    />
                  )
                }
              />
              {
                deletionError && (
                  <DeletionErrorMessage>
                    {
                      deletionError === 'TAG_HAS_CONNECTIONS'
                        ? (
                          <FormattedMessage
                            id="tags.deleteModal.tagHasConnectionsDeletionError"
                            description="Error message when the deletion fails due to active connections"
                            defaultMessage="{tagName} could not be deleted because it has one or more active connections"
                            values={ {
                              tagName: tag.name,
                            } }
                          />
                        )
                        : (
                          <FormattedMessage
                            id="tags.deleteModal.unknownDeletionError"
                            description="Error message when the deletion fails for an unknown reason"
                            defaultMessage="{tagName} could not be deleted."
                            values={ {
                              tagName: tag.name,
                            } }
                          />
                        )
                    }
                  </DeletionErrorMessage>
                )
              }
            </>
          )
        }
        {
          (fetchTagConnectionsState < RequestState.COMPLETE || deleting) && (
            <LoadingSpinnerContainer>
              <LoadingSpinner/>
            </LoadingSpinnerContainer>
          )
        }
      </DialogContent>
      <DialogActions>
        {
          (connections.length || fetchTagConnectionsState === RequestState.FAILED) && (
            <DeprecatedButton onClick={ onDismiss }>
              <FormattedMessage
                id="tags.deleteModal.dismiss"
                description="Dismiss message when tag can't be deleted or connections could not be obtained"
                defaultMessage="Okay"
              />
            </DeprecatedButton>
          )
        }
        {
          !connections.length && fetchTagConnectionsState === RequestState.COMPLETE && (
            <>
              <DeprecatedButton
                onClick={ whenDismissed }
                disabled={ deleting }
              >
                <FormattedMessage
                  id="tags.deleteModal.cancel"
                  defaultMessage="Cancel"
                />
              </DeprecatedButton>
              <DeprecatedButton
                bordered={ true }
                disabled={ deleting }
                onClick={ whenDeleteConfirmed }
              >
                {
                  !deletionError
                    ? (
                      <FormattedMessage
                        id="tags.deleteModal.confirm"
                        defaultMessage="Delete"
                      />
                    )
                    : (
                      <FormattedMessage
                        id="tags.deleteModal.retry"
                        description="Delete CTA label after failure"
                        defaultMessage="Try again"
                      />
                    )
                }
              </DeprecatedButton>
            </>
          )
        }
      </DialogActions>
    </StyledTagDeletionDialog>
  )
};
