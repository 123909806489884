import styled from '@emotion/styled';

export const StyledSubCategoryRoot = styled.div`
  margin-bottom: ${props => props.theme.spacing(4)};
`;

export const StyledSettingRoot = styled.div`
  margin-bottom: ${props => props.theme.spacing(4)};
  display: grid;
  grid-template-columns: 3fr 1.5fr;
  grid-template-rows: auto;
  grid-template-areas:
    "details default-value";
  column-gap: ${props => props.theme.spacing(2)};
`;

export const StyledSettingDetails = styled.div`
  grid-area: details;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: auto;
  grid-template-areas:
    "name input"
    ". description";
  column-gap: ${props => props.theme.spacing(2)};
`;

export const StyledSettingName = styled.div`
  text-align: right;
  grid-area: name;
`;

export const StyledSettingInput = styled.div`
  grid-area: input;
`;

export const StyledSettingDescription = styled.div`
  color: ${props => props.theme.palette.grey[500]};
  grid-area: description;
`;

export const StyledSettingDefaultValue = styled.div`
  border-left: 2px solid ${props => props.theme.palette.grey[300]};
  padding-left: ${props => props.theme.spacing(2)};
  grid-area: default-value;
`;

export const StyledResetButton = styled.div`
  display: block;
`;
