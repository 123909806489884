import { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { Username } from 'op-storybook/stories/components/UsernameField/UsernameField';

type Props = {
  username?: Username;
};

export const OtpLoginLink: FC<Props> = ({ username }) => {
  const to = useMemo(() => ({
    pathname: '/login/otp',
    state: {
      username,
    },
  }), [username]);

  return (
    <Stack
      direction="column"
      align="center"
    >
      <Link
        to={ to }
      >
        <FormattedMessage
          defaultMessage="Log in with a code instead"
        />
      </Link>
    </Stack>
  );
}
