import styled from '@emotion/styled';
import { SpeedDial } from '@mui/material';

export const StyledSpeedDial = styled(SpeedDial)`
  position: absolute;
  right: ${ props => props.theme.spacing(2) };
  bottom: ${ props => props.theme.spacing(2) };

  .MuiSpeedDialAction-staticTooltipLabel {
    white-space: nowrap;
  }

  .MuiFab-primary > .MuiFab-label {
    transition: transform 0.2s;
    backface-visibility: hidden;

    ${ props => props.open && `
      transform: rotate(45deg) translateZ(0);
    ` };
  }

  ${ props => props.open && 'pointer-events: all;' }
`;

export const StyledFabWithBackdrop = styled.div<{ position: 'fixed' | 'absolute' }>`
  width: 100%;
  height: 100%;
  position: ${ props => props.position };
  z-index: 2;
  bottom: 0;
  left: 0;
  pointer-events: none;
`;
