import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';

import { PhoneNumberFieldValue } from '../Component/Input/PhoneNumberField/PhoneNumberField';
import { Validator } from '../../../src/react/Common/Model/Validator';
import { ValidationError } from '../../../src/react/Common/Model/ValidationError';

export class PhoneNumberValidator {
  public static validate = (phoneNumberFieldValue: PhoneNumberFieldValue): ValidationError[] => (
    phoneNumberFieldValue.phoneNumber ? phoneNumberValidator.validate(phoneNumberFieldValue) : []
  );
}

const phoneNumberValidator = new Validator<PhoneNumberFieldValue>([
  phoneNumberFieldValue => {
    try {
      parsePhoneNumber(phoneNumberFieldValue.phoneNumber, phoneNumberFieldValue.countryCode as CountryCode)
    } catch (error) {
      return {
        type: 'invalidPhoneNumberForRegion',
        metadata: {
          region: phoneNumberFieldValue.countryCode,
        },
      }
    }
  },
]);
