import { FC, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';

import { broadcastPaths } from '../broadcastPaths';
import { importRetry } from '../../../../Core/Utility';
import { FullPageLayoutTemplate } from '../../../Common/Component/Layout/FullPageLayoutTemplate';

const BroadcastsFeedPage = lazy(() => importRetry(() => import('../Page/BroadcastsFeedPage')));
const BroadcastsAdminLayout = lazy(() => importRetry(() => import('../Layout/BroadcastsAdminLayout')));

export const BroadcastsRouter: FC = () => (
  <Switch>
    <Route
      path={ broadcastPaths.USER_FEED }
      children={
        <FullPageLayoutTemplate>
          <BroadcastsFeedPage/>
        </FullPageLayoutTemplate>
      }
    />
    <Route
      path={ broadcastPaths.ADMIN_LIST }
      component={ BroadcastsAdminLayout }
    />
  </Switch>
);
