import { FC, PropsWithChildren, ReactNode } from 'react';

import { Card } from '../Card/Card';
import { CardContent } from '../CardContent/CardContent';
import { CardHeader } from '../CardHeader/CardHeader';
import { CardFooter } from '../CardFooter/CardFooter';
import { SizeKey } from '../../types/Theme/Theme';

type Props = {
  headerContent?: ReactNode;
  footerContent?: ReactNode;
  inset?: boolean;
  elevation?: SizeKey;
  seamless?: boolean;
  className?: string;
};

export const ModularCard: FC<PropsWithChildren<Props>> = ({
  headerContent,
  footerContent,
  inset = false,
  elevation = 'md',
  seamless,
  className,
  children,
}) => (
  <Card
    elevation={ elevation }
    className={ className }
  >
    { headerContent && (
      <CardHeader seamless={ seamless }>
        { headerContent }
      </CardHeader>
    ) }
    {
      inset
        ? (
          <CardContent insetTop insetBottom>
            { children }
          </CardContent>
        )
        : children
    }
    { footerContent && (
      <CardFooter seamless={ seamless }>
        { footerContent }
      </CardFooter>
    ) }
  </Card>
);
