import styled from '@emotion/styled';

export const StyledCount = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 4ch 1fr;
`;

export const StyledCountPercentage = styled.div`
  margin-right: 0.5ch;
  height: 1rem;
  font-size: 0.9rem;
  line-height: 1rem;
  vertical-align: middle;
`;

export const StyledCountGraph = styled.div`
  .MuiLinearProgress-root {
    height: 1rem;
    border-radius: 3px;
  }
  margin-right: 2ch;
`;
