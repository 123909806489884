import { IntlShape } from 'react-intl';

import { AudienceConditionDefinition, ConditionRestriction } from '../../Model/AudienceConditionDefinition';
import { Condition, EveryoneCondition } from '../../../../Audiences/Model';
import { EveryoneConditionComponent } from '../../Component/EveryoneConditionComponent';

export class EveryoneConditionDefinition implements AudienceConditionDefinition<EveryoneCondition> {
  public readonly TYPE = 'everyone';
  public readonly restriction = ConditionRestriction.NONE;
  public readonly name;

  constructor(intl: IntlShape) {
    this.name = intl.formatMessage({
      description: 'Name for everyone condition, used in audience builder.',
      defaultMessage: 'Everyone',
    });
  }

  public definesCondition(condition: Condition): condition is EveryoneCondition {
    return condition.type === this.TYPE;
  }

  public Component = EveryoneConditionComponent;

  isValid(): boolean {
    return true;
  }
}
