import styled from '@emotion/styled';

export const StyledPagingMenuListItem = styled.li`
  width: 100%;
`;

export const StyledPagingMenuListItemButton = styled.button`
  background: transparent;
  border: none;
  display: flex;
  gap: ${ props => props.theme.spacing(2) };
  padding: ${ props => `${ props.theme.spacing(1) } ${ props.theme.spacing(2) }` };
  width: 100%;
`;
