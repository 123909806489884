import { FC, SVGProps } from 'react';
import { defaultTheme } from 'op-storybook';

import { StyledIconContainer } from './style';

type SvgComponent = FC<SVGProps<SVGSVGElement>>;

type PresentationalProps = {
  role: 'presentation';
  label?: never;
};

type NonPresentationalProps = {
  role?: never;
  label: string;
};

export type BaseIconProps = {
  IconComponent: SvgComponent;
  size?: keyof typeof defaultTheme.new['spacing'];
  className?: string;
  color?: string;
}

type Props = (PresentationalProps | NonPresentationalProps) & BaseIconProps;

/** @deprecated Prefer Icon from op-storybook project */
export const DeprecatedIcon: FC<Props> = ({
  IconComponent,
  label,
  size = 6,
  color,
  role,
  className,
}) => (
  <StyledIconContainer
    color={ color }
    size={ size }
    className={ className }
  >
    <IconComponent
      {
        ...role === 'presentation'
          ? { role: 'presentation' }
          : { 'aria-label': label }
      }
    />
  </StyledIconContainer>
);
