import { FC, ReactEventHandler, useCallback, useState } from 'react';

import { UploadUrls, useFetchUploadUrls } from '../../Hook';

type Props = {
  uploadId: string;
  renderImage: (uploadUrls: UploadUrls, isLoaded: boolean, onLoad: ReactEventHandler<HTMLImageElement>) => JSX.Element;
  renderError?: (error: unknown, reload: () => void) => JSX.Element;
  loadingElement?: JSX.Element;
};

export const ImageUpload: FC<Props> = ({
  uploadId,
  renderImage,
  renderError,
  loadingElement = null,
}) => {
  const [fetchUploadUrlsResponse, , reload] = useFetchUploadUrls(uploadId);
  const [isLoaded, setIsLoaded] = useState(false);
  const onLoad = useCallback(() => setIsLoaded(true), []);

  return fetchUploadUrlsResponse
    ? fetchUploadUrlsResponse?.content
      ? <>
        {!isLoaded && loadingElement}
        {renderImage(fetchUploadUrlsResponse.content, isLoaded, onLoad)}
      </>
      : fetchUploadUrlsResponse?.error && renderError ? renderError(fetchUploadUrlsResponse.error, reload) : null
    : loadingElement;
};
