import { IntlShape } from 'react-intl';

import { ValidationError } from '../ValidationError';
import { ValidationErrorDefinition } from './ValidationErrorDefinition';

export interface BlankValidationError extends ValidationError {
  type: 'blank',
  metadata: {};
}

export class BlankValidationErrorDefinition implements ValidationErrorDefinition<BlankValidationError> {
  public static TYPE = 'blank' as const;
  public readonly type = BlankValidationErrorDefinition.TYPE;
  public readonly formatMessage = (intl: IntlShape, error: BlankValidationError) => intl.formatMessage({
    description: 'Validation error message when value is blank.',
    defaultMessage: 'Value is blank',
  });

  constructor(formatMessage?: ValidationErrorDefinition<BlankValidationError>['formatMessage']) {
    if (!formatMessage) {
      return;
    }

    this.formatMessage = formatMessage;
  }
}
