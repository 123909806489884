import { FC, useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getSelection, BaseSelection, COMMAND_PRIORITY_LOW, SELECTION_CHANGE_COMMAND } from 'lexical';

type Props = {
  onSelectionChange: (selection: BaseSelection | null) => void;
};

export const OnSelectionChangePlugin: FC<Props> = ({ onSelectionChange }) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      () => {
        const selection = $getSelection();
        onSelectionChange(selection);
        return true;
      },
      COMMAND_PRIORITY_LOW,
    );
  }, [editor, onSelectionChange]);

  return null;
};
