import { FC } from 'react';

import { LiveComponentProps } from '../../../../Content/Model';
import { Training } from '../../../../Broadcasts/Service';
import { AcceptResponse, DeclineResponse } from '../../../Model';
import { BroadcastContentContext } from '../../../../Broadcasts/Model';
import { LiveEventContent } from '..';
import { TrainingBroadcastContent } from '../../../../Content/Component';

export const LiveTrainingContent: FC<LiveComponentProps<Training, AcceptResponse | DeclineResponse, BroadcastContentContext>> = ({
  card,
  onChange,
  response,
  context,
  onResponseChange,
  onReloadRequired,
  onValidationChange,
  onResponseClear,
}) => (
  <LiveEventContent
    card={ card }
    response={ response }
    onChange={ onChange }
    context={ context }
    onResponseChange={ onResponseChange }
    onResponseClear={ onResponseClear }
    onReloadRequired={ onReloadRequired }
    PresentationComponent={ TrainingBroadcastContent }
    respondEndpointBroadcastType="trainings"
    feedback={ card.content.result?.feedback }
    nudge={ context.nudge }
    onValidationChange={ onValidationChange }
  />
);
