import { FetchResponse, useGet, useTransformedFetchResponse } from '../../Hooks';
import { FormAndMultiContentCards, SingleContentFormAndCards } from '../Model';
import { FormTransformer } from '../Utility';

export const useFetchForm = (formId: string): FetchResponse<SingleContentFormAndCards> => (
  useTransformedFetchResponse(
    useGet<FormAndMultiContentCards>(`/forms/${ formId }`),
    FormTransformer.consolidateFormAndCardsContent,
  )
);
