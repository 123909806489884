import styled from '@emotion/styled';

export const StyledContentFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-left: 1px solid ${props => props.theme.palette.grey[300]};
  padding: 1.5rem;
  overflow: hidden;
`;

export const StyledContentHeader = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto auto;
  column-gap: 0.5rem;
  margin: -0.5rem -0.5rem 0 0;

  ${ props => props.theme.breakpoints.down('lg') } {
    grid-template-columns: auto auto 1fr auto auto;
    margin: -0.5rem -0.5rem 1rem -0.5rem;
  }

  >svg,
  >span {
    margin-top: 0.5rem;
  }

  >span {
    -webkit-line-clamp: 2;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
  }
`;
