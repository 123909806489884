import { FC } from 'react';
import { useIntl } from 'react-intl';
import {
  useLocalisedValidationErrors
} from 'op-storybook/lib/hooks/useLocalisedValidationHandlers/useLocalisedValidationHandlers';
import { TextField } from 'op-storybook/stories/components/TextField/TextField';

import { ValidationError } from '../../../../../src/react/Common/Model';
import {
  useDefaultValidationErrorHandlers
} from '../../../../../src/react/Common/Hook/useDefaultValidationErrorHandlers';

type Props = {
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  errors?: ValidationError[];
};

export const FirstNameField: FC<Props> = ({
  value,
  onChange,
  disabled = false,
  errors,
}) => {
  const intl = useIntl();
  const fieldName = intl.formatMessage({
    description: 'Label for first name field used in account registration and updating.',
    defaultMessage: 'First name',
  });
  const errorHandlers = useDefaultValidationErrorHandlers(fieldName);
  const localisedErrors = useLocalisedValidationErrors(errorHandlers, errors || []);

  return (
    <TextField
      name="first-name"
      label={ fieldName }
      value={ value }
      errorMessages={ localisedErrors.map(localisedError => localisedError.localisation) }
      onChange={ onChange }
      disabled={ disabled }
      inputProps={ {
        autoComplete: 'given-name',
        autoFocus: true,
      } }
    />
  );
};
