import { ComponentProps, FC } from 'react';
import * as RadCheckbox from '@radix-ui/react-checkbox';

import { StyledCheckbox, StyledIndicator, StyledRoot } from './style';
import CheckedIcon from '../../../lib/assets/icon/figma/check.svg?react';
import Indeterminate from '../../../lib/assets/icon/figma/minus.svg?react';
import { PresentationIcon } from '../../../lib/components/PresentationIcon/PresentationIcon';
import { LabelledFormField } from '../../../lib/components/input/LabelledFormField/LabelledFormField';

type Props = ComponentProps<typeof LabelledFormField> & RadCheckbox.CheckboxProps;

export const Checkbox: FC<Props> = ({
  label,
  name,
  className,
  tip,
  errorMessages,
  variant,
  id,
  size,
  ...checkboxProps
}) => (
  <LabelledFormField
    id={ id }
    label={ label }
    name={ name }
    className={ className }
    tip={ tip }
    errorMessages={ errorMessages }
    variant={ variant }
    size={ size }
  >
    <StyledRoot
      { ...checkboxProps }
    >
      <StyledCheckbox id={ id }>
        <StyledIndicator>
          {
            checkboxProps.checked === true
              ? (
                <PresentationIcon
                  IconComponent={ CheckedIcon }
                  size={ 4 }
                />
              )
              : (
                <PresentationIcon
                  IconComponent={ Indeterminate }
                  size={ 4 }
                />
              )
          }
        </StyledIndicator>
      </StyledCheckbox>
    </StyledRoot>
  </LabelledFormField>
);
