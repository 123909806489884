import styled from '@emotion/styled';

export const StyledSubMenu = styled.div`
  width: 360px;
  z-index: -1;
  background: ${ props => props.theme.new.palette.teal[800].main };
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  padding-left: 80px;
  box-shadow: ${ props => props.theme.new.shadow.md };
  display: flex;
  flex-direction: column;
  gap: ${ props => props.theme.new.spacing[4] };
`;
