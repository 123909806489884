import { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { ContentPreviewProps } from '../../../../Content/Model';
import { MeetingEditorContent } from '../../../Service';
import { MeetingAndTrainingPreview } from '..';

export const MeetingPreview: FC<ContentPreviewProps<MeetingEditorContent>> = ({
  editorContent,
  onReady,
}) => {
  const intl = useIntl();

  useEffect(() => {
    onReady();
  }, [onReady]);

  return (
    <MeetingAndTrainingPreview
      editorContent={ editorContent }
      localisedContentType={ intl.formatMessage({
        id: 'broadcasts.content.preview.meeting',
        description: 'Label for meeting type used in preview.',
        defaultMessage: 'Meeting',
      }) }
    />
  );
};
