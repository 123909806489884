import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Heading } from '@ourpeople/shared/Core/Component/Content';

import { StyledDefaultValueHeading, StyledSubCategoryHeader } from './style';

export const SettingsSubCategoryHeader: FC = ({
  children,
}) => (
  <StyledSubCategoryHeader>
    <Heading type="h2">
      { children }
    </Heading>
    <StyledDefaultValueHeading>
      <FormattedMessage
        id="settings.defaultValue"
        description="Default setting value heading"
        defaultMessage="Default"
      />
    </StyledDefaultValueHeading>
  </StyledSubCategoryHeader>
)
