import { FC, useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Heading } from '@ourpeople/shared/Core/Component/Content';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';

import { StyledSubmitFormCard } from './style';
import { useContextOrThrow } from '../../../Core/Hook';
import { FormNavigationContext } from '../../Provider/FormNavigationProvider';
import { RequestState } from '../../../Models';

type Props = {
  formName: string;
  submitState: RequestState;
  onSubmitButtonClicked: () => void;
};

export const SubmitFormCard: FC<Props> = ({
  formName,
  submitState,
  onSubmitButtonClicked,
}) => {
  const { setFooterContents } = useContextOrThrow(FormNavigationContext);
  const [hasUnresolvedError, setHasUnresolvedError] = useState<boolean>(false);

  useEffect(() => {
    if (submitState === RequestState.FAILED) {
      setHasUnresolvedError(true);
    } else if (submitState === RequestState.COMPLETE) {
      setHasUnresolvedError(false);
    }
  }, [submitState]);

  useEffect(() => {
    setFooterContents(
      <SubmitFormCardFooterContents
        submitState={ submitState }
        onSubmitButtonClicked={ onSubmitButtonClicked }
      />
    );

    return () => setFooterContents(undefined);
  }, [onSubmitButtonClicked, setFooterContents, submitState]);

  return (
    <StyledSubmitFormCard>
      {
        submitState === RequestState.COMPLETE
          ? (
            <>
              <Heading type="h2">
                <FormattedMessage
                  description="Heading for form submit card when submitted successfully."
                  defaultMessage="Thank you!"
                />
              </Heading>
              <span>
                <FormattedMessage
                  description="Body for form submit card when submitted successfully."
                  defaultMessage="Your form has been successfully submitted."
                />
              </span>
            </>
          )
          : submitState === RequestState.FAILED || hasUnresolvedError
            ? (
              <>
                <Heading type="h2">
                  <FormattedMessage
                    description="Heading for form submit card when submitted unsuccessfully."
                    defaultMessage="Oh no!"
                  />
                </Heading>
                <span>
                  <FormattedMessage
                    description="Body for form submit card when submitted unsuccessfully."
                    defaultMessage="We couldn't submit your form right now, please check your connection and try again."
                  />
                </span>
              </>
            )
            : (
              <>
                <Heading type="h2">
                  <FormattedMessage
                    description="Heading for form submit card prior to submission."
                    defaultMessage="Ready to submit?"
                  />
                </Heading>
                <span>
                  <FormattedMessage
                    description="Body for form submit card prior to submission."
                    defaultMessage="You've reached the end of <strong>{ formName }</strong>. You can click the button below to submit your responses."
                    values={{ formName }}
                  />
                </span>
              </>
            )
      }
    </StyledSubmitFormCard>
  );
};

type FooterProps = {
  submitState: RequestState;
  onSubmitButtonClicked: () => void;
};

const SubmitFormCardFooterContents: FC<FooterProps> = ({
  submitState,
  onSubmitButtonClicked,
}) => {
  const history = useHistory();

  const whenCloseClicked = useCallback(() => (
    history.replace({
      ...history.location,
      pathname: '/my-forms',
    })
  ), [history]);

  return (
    <Button
      variant="primary"
      onClick={ submitState === RequestState.COMPLETE ? whenCloseClicked : onSubmitButtonClicked }
      busy={ submitState === RequestState.FETCHING }
    >
      {
        submitState === RequestState.COMPLETE
          ? (
            <FormattedMessage
              description="Label for close button after submitting form"
              defaultMessage="Close"
            />
          )
          : submitState === RequestState.FAILED
          ? (
            <FormattedMessage
              description="Label for failed form response resubmit button"
              defaultMessage="Try again"
            />
          )
          : (
            <FormattedMessage
              description="Label for form response submit button"
              defaultMessage="Submit"
            />
          )
      }
    </Button>
  );
};
