import { FC, useCallback } from 'react';

import { useDescendantErrors } from '../../../../Common/Hook';
import { useLocalisedResponseErrors } from '../../../Hook';
import { FormResponseErrors } from '../../FormResponseErrors/FormResponseErrors';
import { ValidationTree } from '../../../../Common/Model';
import { DraftSignResponse } from '../../../Service/ContentDefinitions/SignRequestDefinition';
import { Signature } from '../../../../Content/Component';

type Props = {
  signed: {
    at: Date;
    by: string;
  } | null;
};

type ReadOnlyProps = Props & {
  readOnly: true;
  onInputClick?: never;
  validation?: never;
  displayValidation?: false;
};

type EditableProps = Props & {
  readOnly?: false;
  onInputClick: () => void;
  validation?: ValidationTree<DraftSignResponse>;
  displayValidation?: boolean;
};

export const SignRequest: FC<ReadOnlyProps | EditableProps> = ({
  signed,
  onInputClick,
  validation,
  displayValidation,
  readOnly,
}) => {
  const errors = useDescendantErrors(validation);
  const localisedErrors = useLocalisedResponseErrors(errors);

  const whenInputClick = useCallback(() => {
    !readOnly && onInputClick()
  }, [onInputClick, readOnly]);

  return (
    <>
      <Signature
        onChange={ () => whenInputClick() }
        signature={ signed?.by || '' }
        signedAt={ signed && signed.at }
      />
      { displayValidation && <FormResponseErrors localisedErrors={ localisedErrors }/> }
    </>
  );
};
