import { useIntl } from 'react-intl';
import { FC } from 'react';
import { Tooltip } from 'op-storybook/stories/components/Tooltip/Tooltip';
import { FeaturedIcon } from 'op-storybook/stories/components/FeaturedIcon/FeaturedIcon';
import MandatoryIcon from 'op-storybook/lib/assets/icon/figma/flag-02.svg';

export const MandatoryIndicator: FC = () => {
  const intl = useIntl();

  return (
    <Tooltip
      contents={ intl.formatMessage({
        description: 'Tooltip for mandatory indicator icon used on inbox page.',
        defaultMessage: 'This is a mandatory broadcast',
      }) }
    >
      <div>
        <FeaturedIcon
          IconComponent={ MandatoryIcon }
          variant="light-circle"
          colour="primary"
        />
      </div>
    </Tooltip>
  );
};
