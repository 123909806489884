import { IntlShape } from 'react-intl';

import { Condition, StatusCondition } from '../../../../Audiences/Model';
import { AudienceConditionDefinition, ConditionRestriction } from '../../Model/AudienceConditionDefinition';
import { StatusList } from '../../Component/StatusCondition/StatusList';
import { StatusConditionComponent } from '../../Component/StatusCondition/StatusConditionComponent';

export class StatusConditionDefinition implements AudienceConditionDefinition<StatusCondition> {
  public readonly TYPE = 'status';
  public readonly restriction = ConditionRestriction.SOME;
  public readonly name;

  constructor(intl: IntlShape) {
    this.name = intl.formatMessage({
      description: 'Name for status condition, used in audience builder.',
      defaultMessage: 'Status',
    });
  }
  public definesCondition(condition: Condition): condition is StatusCondition {
    return condition.type === this.TYPE;
  }

  isValid(condition: StatusCondition): boolean {
    return !!condition.statuses.length;
  }

  public Component = StatusConditionComponent;

  public build = {
    Component: StatusList,
    initialise: () => ({
      type: 'status',
      statuses: [],
      not: false,
      new: true,
    }) as StatusCondition,
  };
}
