import * as React from 'react';
import { ChangeEventHandler, FunctionComponent, useCallback, useMemo } from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { useIntl } from 'react-intl';

import SearchIcon from '../../../../Asset/Icon/Streamline/search.svg';
import CrossIcon from '../../../../Asset/Icon/Streamline/close.svg';
import { DeprecatedIcon } from '../../Content/Icon/DeprecatedIcon';
import { DeprecatedPresentationIcon } from '../../Content/PresentationIcon/DeprecatedPresentationIcon';

interface Props {
  value?: string;
  onChange: (value: string) => void;
  onClear: () => void;
  placeholder?: string;
  autofocus?: boolean;
  fullWidth?: boolean;
}

export const SearchInput: FunctionComponent<Props> = ({
  value = '',
  onChange,
  onClear,
  placeholder = '',
  autofocus = false,
  fullWidth = false,
}) => {
  const intl = useIntl();

  const whenChanged: ChangeEventHandler<HTMLInputElement> = useCallback(event => (
    onChange(event.currentTarget.value)
  ), [onChange]);

  const inputProps = useMemo(() => ({
    startAdornment: (
      <InputAdornment position="start">
        <DeprecatedPresentationIcon IconComponent={ SearchIcon }/>
      </InputAdornment>
    ),
    endAdornment: (
      <InputAdornment
        position="end"
        style={
          value
            ? undefined
            : {
              opacity: 0,
              pointerEvents: 'none',
            }
        }
      >
        <IconButton
          size="small"
          onClick={ onClear }
        >
          <DeprecatedIcon
            label={ intl.formatMessage({
              description: 'Label for search input clear button.',
              defaultMessage: 'Clear input',
            }) }
            IconComponent={ CrossIcon }
          />
        </IconButton>
      </InputAdornment>
    ),
    role: 'search',
    placeholder: placeholder || intl.formatMessage({
      description: 'Generic search input placeholder',
      defaultMessage: 'Search',
    }),
  }), [intl, onClear, placeholder, value]);

  return (
    <TextField
      size="small"
      variant="outlined"
      fullWidth={ fullWidth }
      type="text"
      margin="none"
      autoFocus={ autofocus }
      onChange={ whenChanged }
      value={ value }
      InputProps={ inputProps }
    />
  );
};
