import { FC, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { IconButton } from '../IconButton/IconButton';
import { SvgComponent } from '../../Model';
import { useMounted } from '../../Hook';
import { useContextOrThrow } from '../../../Core/Hook';
import { ToastContext } from '../../../Core/Context';

type Props = {
  value: string;
  IconComponent: SvgComponent;
  tooltip: string;
  onCopy?: () => void;
};

export const ClipboardIconButton: FC<Props> = ({
  value,
  IconComponent,
  tooltip,
  onCopy,
}) => {
  const intl = useIntl();
  const mounted = useMounted();
  const { addSuccessToast, addErrorToast } = useContextOrThrow(ToastContext);

  const whenClicked = useCallback(() => (
    navigator.clipboard.writeText(value)
      .then(() => {
        if (!mounted.current) {
          return;
        }

        onCopy && onCopy();
        addSuccessToast(
          intl.formatMessage({
            description: 'Toast message when url is copied to clipboard.',
            defaultMessage: 'Link copied to clipboard',
          })
        );
      })
      .catch(() => {
        addErrorToast(
          intl.formatMessage({
            description: 'Toast message when url fails to copy to clipboard.',
            defaultMessage: 'Could not copy link to clipboard',
          })
        );
      })
  ), [addErrorToast, addSuccessToast, intl, mounted, onCopy, value]);

  return (
    <IconButton
      color="primary.main"
      IconComponent={ IconComponent }
      onClick={ whenClicked }
      label={ tooltip }
      showTooltip
      size="small"
    />
  );
};
