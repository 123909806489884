import { default as React, FC, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { ContentEditorProps } from '../../../../Content/Model';
import {
  CHOICE_OPTIONS_MAX_COUNT,
  CHOICE_OPTIONS_MIN_COUNT,
  CHOICE_TITLE_MAX_LENGTH,
  CHOICE_TITLE_MIN_LENGTH,
  ChoiceEditorContent,
  DraftChoiceOption,
  ChoiceRequestValidator
} from '../../../Service';
import { FieldValidationErrors, NoMarginTextField, VerticallySpaced } from '../../../../Common/Component';
import { CharacterCount } from '../../../../Content/Component';
import { useInputChangeEventHandler } from '../../../../Common/Hook';
import { ChoiceRequestOptionEditor, OptionsEditor } from '..';
import { ValidationTree } from '../../../../Common/Model';
import { Switch } from '../../../../Components';
import { useCheckboxChangeEventHandler } from '../../../../Common/Hook/useCheckboxChangeEventHandler';
import { UniqueIdGenerator } from '../../../../Common/Utility';

export const ChoiceRequestEditor: FC<ContentEditorProps<ChoiceEditorContent>> = ({
  editorContent,
  onEditorContentChanged,
  validation,
  onValidationChanged,
}) => {
  const intl = useIntl();
  const titleFieldLabel = intl.formatMessage({
    id: 'broadcasts.content.choice.fields.title.label',
    description: 'Label for title field in choice card editor.',
    defaultMessage: 'Question',
  });
  const allowMultipleChoicesFieldLabel = intl.formatMessage({
    id: 'broadcasts.content.choice.fields.multipleChoice.label',
    description: 'Label for multiple choice toggle in choice card editor.',
    defaultMessage: 'Allow multiple selections',
  });

  const whenTitleChanged = useInputChangeEventHandler(useCallback((title: string) => (
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          title,
        },
      },
    })
  ), [editorContent, onEditorContentChanged]));

  const createOption = (): DraftChoiceOption => ({
    id: UniqueIdGenerator.generate(),
    type: 'choiceoption',
    text: '',
  });

  const whenTitleBlurred = useCallback(() => (
    onValidationChanged({
      errors: validation?.errors || [],
      children: {
        content: {
          errors: validation?.children.content?.errors || [],
          children: {
            ...validation?.children.content?.children,
            title: ChoiceRequestValidator.validateTitle(editorContent.card.content.title),
          },
        },
      },
    })
  ), [validation, onValidationChanged, editorContent]);

  const whenAllowMultipleChoicesChanged = useCheckboxChangeEventHandler(useCallback((checked: boolean) => (
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          type: checked ? 'multiplechoicequestion' : 'singlechoicequestion',
        },
      },
    })
  ), [editorContent, onEditorContentChanged]));

  const whenOptionsChanged = useCallback((options: DraftChoiceOption[]) => (
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          options,
        },
      },
    })
  ), [editorContent, onEditorContentChanged]);

  const whenOptionsValidationChanged = useCallback((optionsValidation: ValidationTree<DraftChoiceOption[]>) => (
    onValidationChanged({
      errors: validation?.errors || [],
      children: {
        content: {
          errors: validation?.children.content?.errors || [],
          children: {
            ...validation?.children.content?.children,
            options: optionsValidation,
          },
        },
      },
    })
  ), [validation, onValidationChanged]);

  return (
    <VerticallySpaced gap={ 2 }>
      <VerticallySpaced gap={ 1 }>
        <h4>{ titleFieldLabel }</h4>
        <NoMarginTextField
          fullWidth
          value={ editorContent.card.content.title }
          onChange={ whenTitleChanged }
          onBlur={ whenTitleBlurred }
        />
        <CharacterCount
          current={ editorContent.card.content.title.length || 0 }
          minimum={ CHOICE_TITLE_MIN_LENGTH }
          maximum={ CHOICE_TITLE_MAX_LENGTH }
        />
        <FieldValidationErrors
          fieldName={ titleFieldLabel }
          validationErrors={ validation?.children.content?.children.title?.errors || [] }
        />
      </VerticallySpaced>
      <VerticallySpaced gap={ 1 }>
        <h4>{ allowMultipleChoicesFieldLabel }</h4>
        <Switch
          checked={ editorContent.card.content.type === 'multiplechoicequestion' }
          onChange={ whenAllowMultipleChoicesChanged }
        />
      </VerticallySpaced>
      <OptionsEditor
        options={ editorContent.card.content.options }
        onChange={ whenOptionsChanged }
        validation={ validation?.children.content?.children.options }
        onValidationChange={ whenOptionsValidationChanged }
        OptionEditorComponent={ ChoiceRequestOptionEditor }
        minOptions={ CHOICE_OPTIONS_MIN_COUNT }
        maxOptions={ CHOICE_OPTIONS_MAX_COUNT }
        createOption={ createOption }
        localisedFieldName={ intl.formatMessage({
          id: 'broadcasts.content.choice.fields.options.label',
          description: 'Label for options in choice request',
          defaultMessage: 'Choices',
        }) }
        localisedButtonLabel={ intl.formatMessage({
          id: 'broadcasts.content.choice.fields.addOption.label',
          description: 'Label for add option button in choice request',
          defaultMessage: 'Add choice',
        }) }
      />
    </VerticallySpaced>
  );
}
