import { FC, PropsWithChildren } from 'react';

import { StreamConnectionProvider } from '../StreamConnectionProvider/StreamConnectionProvider';
import { useEnvironmentSettings } from '../../../Common/Hook';

export const ConditionalStreamConnectionProvider: FC<PropsWithChildren> = ({ children }) => {
  const { streamChatEnabled } = useEnvironmentSettings();

  return (
    streamChatEnabled
      ? (
        <StreamConnectionProvider>
          { children }
        </StreamConnectionProvider>
      )
      : <>{ children }</>
  );
};
