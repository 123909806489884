import { DraftUploadRequest } from '../../ContentDefinition';
import { ValidationTree, Validator } from '../../../../Common/Model';
import { Validate } from '../../../../Common/Utility';

export class UploadRequestValidator {
  public static validate = (content: DraftUploadRequest): ValidationTree<DraftUploadRequest> => ({
    errors: [],
    children: {
      title: UploadRequestValidator.validateTitle(content.title),
      description: UploadRequestValidator.validateDescription(content.description),
    },
  });

  public static validateTitle = (title: string): ValidationTree<string> => ({
    errors: titleValidator.validate(title),
    children: {},
  });

  public static validateDescription = (description: string): ValidationTree<string> => ({
    errors: descriptionValidator.validate(description),
    children: {},
  });
}

export const UPLOAD_TITLE_MIN_LENGTH = 1;
export const UPLOAD_TITLE_MAX_LENGTH = 140;
export const UPLOAD_DESCRIPTION_MIN_LENGTH = 1;
export const UPLOAD_DESCRIPTION_MAX_LENGTH = 150;

const titleValidator = new Validator<string>([
  Validate.notBlank,
  title => Validate.minLength(title, UPLOAD_TITLE_MIN_LENGTH),
  title => Validate.maxLength(title, UPLOAD_TITLE_MAX_LENGTH),
]);

const descriptionValidator = new Validator<string>([
  Validate.notBlank,
  description => Validate.minLength(description, UPLOAD_DESCRIPTION_MIN_LENGTH),
  description => Validate.maxLength(description, UPLOAD_DESCRIPTION_MAX_LENGTH),
]);

