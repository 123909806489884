import { useEffect } from 'react';

import { SubmittedComponent } from '../../../../Content/Model';
import { NoResponseMessage } from '../NoResponseMessage/NoResponseMessage';
import { SignRequest } from '../../Content/SignRequest/SignRequest';
import { SignRequest as SignRequestModel } from '../../../Model/ContentTypes/SignRequest';
import { SignResponse } from '../../../Service/ContentDefinitions/SignRequestDefinition';

export const SubmittedSignRequest: SubmittedComponent<SignResponse, SignRequestModel> = ({
  response,
  onFinish,
  submissionDate,
}) => {
  useEffect(() => {
    if (!onFinish) {
      return;
    }

    onFinish();
  }, [onFinish]);

  return (
    response
      ? (
        <SignRequest
          signed={
            response
              ? {
                at: submissionDate,
                by: response.signature,
              }
              : null
          }
          readOnly
        />
      )
      : <NoResponseMessage/>
  );
};
