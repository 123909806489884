import { FC } from 'react';

import { StyledSubmittedText } from './style';

interface Props {
  text: string;
}

export const SubmittedText: FC<Props> = ({
  text,
}) => {
  return (
    <StyledSubmittedText>
      { text }
    </StyledSubmittedText>
  );
};
