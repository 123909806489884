import { DraftRevealContent, DraftRevealOption } from '../../ContentDefinition';
import { ValidationTree, Validator } from '../../../../Common/Model';
import { Validate } from '../../../../Common/Utility';

export class RevealContentValidator {
  public static validate = (content: DraftRevealContent): ValidationTree<DraftRevealContent> => ({
    errors: [],
    children: {
      title: RevealContentValidator.validateTitle(content.title),
      text: RevealContentValidator.validateText(content.text),
      options: {
        errors: optionsValidator.validate(content.options),
        children: content.options.map(option => ({
          errors: [],
          children: {
            preview_text: RevealContentValidator.validateOptionTitle(option.preview_text),
            text: RevealContentValidator.validateOptionText(option.text),
          },
        })),
      },
    },
  });

  public static validateTitle = (title: string): ValidationTree<string> => ({
    errors: titleValidator.validate(title),
    children: {},
  });

  public static validateText = (text: string): ValidationTree<string> => ({
    errors: textValidator.validate(text),
    children: {},
  });

  public static validateOptionTitle = (optionTitle: string): ValidationTree<string> => ({
    errors: optionTitleValidator.validate(optionTitle),
    children: {},
  });

  public static validateOptionText = (optionText: string): ValidationTree<string> => ({
    errors: optionTextValidator.validate(optionText),
    children: {},
  });
}

export const REVEAL_TITLE_MIN_LENGTH = 1;
export const REVEAL_TITLE_MAX_LENGTH = 100;
export const REVEAL_TEXT_MIN_LENGTH = 1;
export const REVEAL_TEXT_MAX_LENGTH = 190;
export const REVEAL_OPTION_TITLE_MIN_LENGTH = 1;
export const REVEAL_OPTION_TITLE_MAX_LENGTH = 100;
export const REVEAL_OPTION_TEXT_MIN_LENGTH = 1;
export const REVEAL_OPTION_TEXT_MAX_LENGTH = 300;
export const REVEAL_OPTIONS_MIN_COUNT = 1;
export const REVEAL_OPTIONS_MAX_COUNT = 10;

const titleValidator = new Validator<string>([
  Validate.notBlank,
  title => Validate.minLength(title, REVEAL_TITLE_MIN_LENGTH),
  title => Validate.maxLength(title, REVEAL_TITLE_MAX_LENGTH),
]);

const textValidator = new Validator<string>([
  Validate.notBlank,
  text => Validate.minLength(text, REVEAL_TEXT_MIN_LENGTH),
  text => Validate.maxLength(text, REVEAL_TEXT_MAX_LENGTH),
]);

const optionsValidator = new Validator<DraftRevealOption[]>([
  options => Validate.countGreaterThanEq(options, REVEAL_OPTIONS_MIN_COUNT),
  options => Validate.countLessThanEq(options, REVEAL_OPTIONS_MAX_COUNT),
]);

const optionTitleValidator = new Validator<string>([
  Validate.notBlank,
  optionTitle => Validate.minLength(optionTitle, REVEAL_OPTION_TITLE_MIN_LENGTH),
  optionTitle => Validate.maxLength(optionTitle, REVEAL_OPTION_TITLE_MAX_LENGTH),
]);

const optionTextValidator = new Validator<string>([
  Validate.notBlank,
  optionText => Validate.minLength(optionText, REVEAL_OPTION_TEXT_MIN_LENGTH),
  optionText => Validate.maxLength(optionText, REVEAL_OPTION_TEXT_MAX_LENGTH),
]);
