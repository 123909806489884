import { IntlShape } from 'react-intl';

import { TagType, Tag } from '../Model';
import { ArrayHelper } from '../../Common/Utility';

const recognisedTypes: TagType[] = [
  'region',
  'team',
  'department',
  'jobtitle',
  'skill',
];

export class TagParser {
  public static localisedTagTypeFromInternalTagType = (tagType: TagType, intl: IntlShape, count = 1): string => {
    switch (tagType) {
      case 'region':
        return intl.formatMessage({
          id: 'tag.type.region',
          description: 'Region tag type',
          defaultMessage: '{count, plural, one {region} other {regions}}',
        }, {
          count,
        });
      case 'team':
        return intl.formatMessage({
          id: 'tag.type.location',
          description: 'Location tag type',
          defaultMessage: '{count, plural, one {team} other {teams}}',
        }, {
          count,
        });
      case 'department':
        return intl.formatMessage({
          id: 'tag.type.department',
          description: 'Department tag type',
          defaultMessage: '{count, plural, one {department} other {departments}}',
        }, {
          count,
        });
      case 'jobtitle':
        return intl.formatMessage({
          id: 'tag.type.jobtitle',
          description: 'Job title tag type',
          defaultMessage: '{count, plural, one {job title} other {job titles}}',
        }, {
          count,
        });
      case 'skill':
        return intl.formatMessage({
          id: 'tag.type.skill',
          description: 'Skill tag type',
          defaultMessage: '{count, plural, one {skill} other {skills}}',
        }, {
          count,
        });
      default:
        return '';
    }
  }

  public static localisedTagType = (tag: Tag<TagType>, intl: IntlShape): string => {
    return TagParser.localisedTagTypeFromInternalTagType(tag.type, intl);
  }

  public static isRecognisedTagType = ArrayHelper.createTypeGuard(recognisedTypes);

  public static isRecognisedTag = (tag: Tag<TagType>): boolean => {
    return TagParser.isRecognisedTagType(tag.type);
  }

  public static determineParentType = (tagType: TagType): TagType | undefined => {
    const typeIndex = recognisedTypes.indexOf(tagType);
    return typeIndex !== -1
      ? recognisedTypes[typeIndex - 1]
      : undefined;
  }

  public static determineChildType = (tagType: TagType): TagType | undefined => {
    const typeIndex = recognisedTypes.indexOf(tagType);
    return typeIndex !== -1
      ? recognisedTypes[typeIndex + 1]
      : undefined;
  }

  public static tagIsTeamTag = (tag: Tag<TagType>): tag is Tag<'team'> => {
    return (tag as Tag<'team'>).type === 'team';
  }
}
