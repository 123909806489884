import { FC, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { CardTitle, ScrollableContent } from '../../../../Broadcasts/Component/Content';
import { BroadcastContentFooter, ContentPadding, StackNavigationProps } from '..';
import { SpaceThemeProvider } from '../../../../Broadcasts/Provider';
import { StyledDivider, StyledStackLogo } from '../../../../Broadcasts/Component/Content/TimelinePreview/style';
import defaultStackLogo from '../../../../Assets/img/icons/coloured/stack.svg?url';
import { useEnvironmentSettings } from '../../../../Common/Hook';
import { StyledTimelineBroadcastContent, StyledTimelineBroadcastContentContainer } from './style';
import { AbsoluteBackButtonContainer } from '../AbsoluteBackButtonContainer/AbsoluteBackButtonContainer';

type Props = {
  title: string;
  cardCount: number;
  navigationProps: StackNavigationProps;
  backButton: ReactNode | undefined;
}

export const TimelineBroadcastContent: FC<Props> = ({
  title,
  cardCount,
  navigationProps,
  backButton,
}) => {
  const { stackLogoUrl } = useEnvironmentSettings();
  return (
    <SpaceThemeProvider>
      <StyledTimelineBroadcastContent>
        { backButton && <AbsoluteBackButtonContainer>{ backButton }</AbsoluteBackButtonContainer> }
        <ScrollableContent>
          <ContentPadding>
            <StyledTimelineBroadcastContentContainer>
              <CardTitle>{ title }</CardTitle>
              <StyledDivider/>
              <span>
                <FormattedMessage
                  id="broadcasts.content.preview.timeline.cardCount"
                  description="Card count for timeline card preview"
                  defaultMessage="{ cardCount, plural, one { # card } other { # cards } }"
                  values={ { cardCount } }
                />
              </span>
              <StyledStackLogo src={ stackLogoUrl || defaultStackLogo }/>
            </StyledTimelineBroadcastContentContainer>
          </ContentPadding>
        </ScrollableContent>
        <BroadcastContentFooter color="inherit" { ...navigationProps }/>
      </StyledTimelineBroadcastContent>
    </SpaceThemeProvider>
  );
};
