import { FunctionComponent, useCallback } from 'react';

import { ContentWithOptions, ContentOption } from '../../../../../Models';
import { ContentReader } from '../../../../../Readers';
import { CardReport, RespondedOptionCount } from '../../Hooks/useFetchBroadcastReportSummary';
import { useNotRespondedLabelForContentType } from '../../Hooks/useNotRespondedLabelForContentType';
import { DeliveryReportTab, DeliveryReportTabs, DeliveryReportTabContainer, DeliveryReportTabContent } from './';
import { NotRespondedTable } from '../Tables/NotRespondedTable';
import { RespondedTable } from '../Tables/RespondedTable';
import { StyledCorrectIcon, StyledIncorrectIcon } from './styles';
import CorrectIcon from '../../../../../Assets/img/icons/monochrome/success.svg';
import IncorrectIcon from '../../../../../Assets/img/icons/monochrome/close.svg';

interface Props {
  broadcastId: string;
  deliveryId: string;
  card: CardReport;
  pendingCount: number;
  initialSearch?: string;
}

export const ContentWithOptionsReportTabs: FunctionComponent<Props> = ({
  card,
  broadcastId,
  deliveryId,
  pendingCount,
  initialSearch,
}) => {
  const labelForOption = useCallback((option: ContentOption): JSX.Element => {
    if (ContentReader.contentTypeFromString(card.content.type) === 'quiz') {
      return (
        <>
          {
            option.correct_option
            ? <StyledCorrectIcon><CorrectIcon /></StyledCorrectIcon>
            : <StyledIncorrectIcon><IncorrectIcon /></StyledIncorrectIcon>
          }
          {option.text}
        </>
      );
    }
    return <>{option.text}</>;
  }, [card.content]);

  return (
    <DeliveryReportTabContainer
      tabs={
        <DeliveryReportTabs>
          {
            (card.content as ContentWithOptions).options.map((option) => (
              <DeliveryReportTab
                key={`tabOption${option.id}`}
                label={labelForOption(option)}
                value={
                  ((card.counts.responded as RespondedOptionCount).options || []).find(
                    (respondedOption) => respondedOption.id === option.id
                  )?.count || 0
                }
                />
            ))
          }
          <DeliveryReportTab
            label={useNotRespondedLabelForContentType(
              ContentReader.contentTypeFromString(card.content.type)
            )}
            value={(card.counts.notResponded?.total || 0) - pendingCount}
            lowLight={true}
          />
        </DeliveryReportTabs>
      }>
      {
        (card.content as ContentWithOptions).options.map((option) => (
          <DeliveryReportTabContent key={`tableOption${option.id}`}>
            <RespondedTable
              deliveryId={deliveryId}
              broadcastId={broadcastId}
              optionId={option.id}
              contentId={card.content.id}
              responseType="responded"
              initialSearch={ initialSearch }
            />
          </DeliveryReportTabContent>
        ))
      }
      <DeliveryReportTabContent>
        <NotRespondedTable
          deliveryId={deliveryId}
          broadcastId={broadcastId}
          contentId={card.content.id}
          responseType="notResponded"
          initialSearch={ initialSearch }
        />
      </DeliveryReportTabContent>
    </DeliveryReportTabContainer>
  );
};
