import { FunctionComponent, PropsWithChildren } from 'react';

import { Flex } from '..';

export const FilterRow: FunctionComponent<PropsWithChildren> = ({ children }) => (
  <Flex
    gap={ 2 }
  >
    { children }
  </Flex>
);
