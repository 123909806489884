import { Condition } from '../Model';
import { ConditionIdentifier } from '.';

export class ConditionValidator {
  public static validate = (condition: Condition): boolean => {
    if (ConditionIdentifier.conditionIsRoleCondition(condition)) {
      return !!condition.roleIds.length;
    }

    if (ConditionIdentifier.conditionIsTagCondition(condition)) {
      return !!condition.tagIds.length;
    }

    if (ConditionIdentifier.conditionIsPersonCondition(condition)) {
      return !!condition.personIds.length;
    }

    if (ConditionIdentifier.conditionIsStatusCondition(condition)) {
      return !!condition.statuses.length;
    }

    if (ConditionIdentifier.conditionIsNestCondition(condition)) {
      return !!condition.audienceIds.length;
    }

    return true;
  };
}
