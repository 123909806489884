import { ComponentProps, FC } from 'react';

import { StyledTextFieldTrailingButton } from '../TextFieldTrailingButton/style';
import { Typography } from '../../Content/Typography/Typography';

export const TextFieldTrailingButton: FC<ComponentProps<'button'>> = ({
  children,
  ...props
}) => (
  <StyledTextFieldTrailingButton { ...props }>
    <Typography
      color={
        props.disabled
          ? 'grey.300.main'
          : 'inherit'
      }
    >
      { children }
    </Typography>
  </StyledTextFieldTrailingButton >
);
