import styled from '@emotion/styled';

export const StyledLoadingContainer = styled.div<{ visible: boolean }>`
  ${ props => !props.visible && 'opacity: 0' };
  transition: opacity 0.3s;
  padding: ${ props => props.theme.spacing(2) };
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: ${ props => props.theme.spacing(2) };
  width: 100%;
  text-align: center;
  user-select: none;
`;
