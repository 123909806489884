import styled from '@emotion/styled';

export const StyledLabel = styled.label<{ fullWidth: boolean }>`
  ${ props => props.fullWidth && 'width: 100%;' };

  .MuiInputBase-root {
    overflow: hidden;
    display: flex;
    gap: ${ props => props.theme.new.spacing[1] };
  }

  .MuiInputAdornment-root {
    gap: ${ props => props.theme.new.spacing[1] };
  }
`;
