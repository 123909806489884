import styled from '@emotion/styled';

export const StyledLoadingIndicator = styled.li`
  display: flex;
  flex-direction: column;
  gap: ${ props => props.theme.spacing(2) };
  padding: ${ props => props.theme.spacing(2) };
  justify-content: center;
  align-items: center;
`;
