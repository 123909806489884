import { FC } from 'react';

import { BaseIconProps, DeprecatedIcon } from '../Icon/DeprecatedIcon';

/** @deprecated Prefer PresentationIcon from op-storybook project */
export const DeprecatedPresentationIcon: FC<BaseIconProps> = props => (
  <DeprecatedIcon
    { ...props }
    role="presentation"
  />
);
