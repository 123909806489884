import { FC, PropsWithChildren } from 'react';

import { StyledCardContent } from './style';

type Props = {
  className?: string;
  insetBottom?: boolean;
  insetTop?: boolean;
};

export const CardContent: FC<PropsWithChildren<Props>> = ({
  className,
  insetBottom,
  insetTop,
  children,
}) => (
  <StyledCardContent
    className={ className }
    insetBottom={ insetBottom }
    insetTop={ insetTop }
  >
    { children }
  </StyledCardContent>
);
