import { FC, useCallback, useState } from 'react';

import { ViewResponse } from '../../../Model';
import { VideoContent } from '../../../../Broadcasts/Service';
import { VideoBroadcastContent, VideoThumbnail } from '../../../../Content/Component';
import { BroadcastContentContext } from '../../../../Broadcasts/Model';
import { LiveComponentProps } from '../../../../Content/Model';
import { LegacyLiveVideoContent } from '..';
import { useSubmitResponse } from '../../../Hook/useSubmitResponse';
import { VideoUploadDialog } from '../../../../Common/Component';
import { useRecallErrorHandler } from '../../../Hook';

type Props = LiveComponentProps<VideoContent, ViewResponse, BroadcastContentContext>;

export const LiveVideoContent: FC<Props> = ({
  onResponseChange,
  onReloadRequired,
  response,
  card,
  ...props
}) => {
  const [videoDialogOpen, setVideoDialogOpen] = useState<boolean>(false);
  const [videoDialogMounted, setVideoDialogMounted] = useState<boolean>(false);

  const [submitResponse] = useSubmitResponse(
    card.content.id.toString(),
    onResponseChange,
    useRecallErrorHandler(onReloadRequired),
  );

  const whenPlayVideoClicked = useCallback(() => {
    setVideoDialogOpen(true);
    setVideoDialogMounted(true);

    if (response) {
      return;
    }

    submitResponse({
      type: 'view',
      contentId: card.content.id.toString(),
      deliveryId: props.context.deliveryId,
      createdAt: new Date().toISOString(),
    });
  }, [card.content.id, props.context.deliveryId, response, submitResponse]);

  return (
    card.content.thumbnailKey
      ? (
        <>
          <VideoBroadcastContent
            backButton={ props.context.closeButton }
            media={ card.content.media }
            text={ card.content.text }
            navigationProps={ props.context.navigationProps }
            thumbnail={
              <VideoThumbnail
                uploadId={ card.content.media.id.toString() }
                thumbnailKey={ card.content.thumbnailKey }
                busy={ videoDialogOpen }
                duration={ card.content.media.video?.duration || undefined }
                onClick={ () => whenPlayVideoClicked() }
              />
            }
            description={ card.content.description || undefined }
          />
          { videoDialogMounted && (
            <VideoUploadDialog
              TransitionProps={ {
                onExited: () => setVideoDialogMounted(false),
              } }
              open={ videoDialogOpen }
              uploadId={ card.content.media.id.toString() }
              onClose={ () => setVideoDialogOpen(false) }
            />
          ) }
        </>
      )
      : (
        <LegacyLiveVideoContent
          onResponseChange={ onResponseChange }
          response={ response }
          card={ card }
          onReloadRequired={ onReloadRequired }
          { ...props }
        />
      )
  );
};
