import { FunctionComponent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { InputLabel } from '@mui/material';
import { AutocompleteSelectionChanged } from '@ourpeople/shared/Core/Component/Input/Autocomplete/Autocomplete';

import { RoleAutocomplete } from '../../../../People/Component';
import { Role, RoleId } from '../../../../Security/Model';
import { StyledRoleConditionContainer } from './style';
import { LabelModifier } from '../..';
import { RoleCondition } from '../../../Model';
import { ValidationTree } from '../../../../Common/Model';
import { FieldValidationErrors } from '../../../../Common/Component';
import { useDescendantErrors } from '../../../../Common/Hook';
import { ArrayHelper } from '../../../../Common/Utility';

interface Props {
  condition: RoleCondition;
  onChange: (condition: RoleCondition) => void;
  validation?: ValidationTree<RoleCondition>;
  readonly?: boolean;
  autoFocus?: boolean;
}

export const RoleConditionBuilder: FunctionComponent<Props> = ({
  condition,
  onChange,
  validation,
  readonly = false,
  autoFocus,
}) => {
  const intl = useIntl();
  const roleErrors = useDescendantErrors(validation);
  const allowedRoleIds = [
    'ROLE_APP_BROADCAST',
    'ROLE_APP_ADMIN',
    'ROLE_BROADCAST_ADMIN',
    'ROLE_ADMIN',
    'ROLE_SUPER_ADMIN',
  ];
  const modifierOptions = [
    intl.formatMessage({
      id: 'audiences.builder.roleCondition.modifier.anyOf',
      defaultMessage: 'any of',
    }),
    intl.formatMessage({
      id: 'audiences.builder.roleCondition.modifier.noneOf',
      defaultMessage: 'none of',
    }),
  ];

  const whenRolesChanged: AutocompleteSelectionChanged<Role> = (selection) => {
    const newRoleIds = [
      ...selection.options.map((role) => role.id),
      ...selection.unknownIds as RoleId[],
    ];

    if (ArrayHelper.containSameValues<RoleId>(newRoleIds, condition.roleIds) === false) {
      onChange({
        ...condition,
        roleIds: newRoleIds,
      });
    }
  };

  const whenModifierChanged = (modifierIndex: number): void => {
    onChange({
      ...condition,
      not: modifierIndex === 1,
    });
  };

  return <StyledRoleConditionContainer>
    <InputLabel>
      <FormattedMessage
        id="audiences.builder.roleCondition.label"
        description="Label for the role condition dropdown"
        defaultMessage="People with"
      />
      <LabelModifier
        options={modifierOptions}
        activeIndex={condition.not ? 1 : 0}
        onChange={whenModifierChanged}
        readonly={ readonly }
      />
    </InputLabel>
    <RoleAutocomplete
      multiple={true}
      selectedIds={condition.roleIds}
      autoFocus={ autoFocus }
      listStyle="grid"
      allowedIds={[
        ...condition.roleIds,
        ...allowedRoleIds,
      ]}
      onSelectionChanged={whenRolesChanged}
      label={''}
      dense
      readonly={ readonly }
    />
    <FieldValidationErrors
      fieldName={ intl.formatMessage({
        id: 'audiences.builder.fields.roleCondition.name',
        description: 'Field name for role condition builder',
        defaultMessage: 'role condition'
      }) }
      validationErrors={ roleErrors }
      selectionSingular={ intl.formatMessage({
        id: 'audiences.builder.fields.roleCondition.singular',
        description: 'Singular selection name in role condition builder',
        defaultMessage: 'role'
      }) }
      selectionPlural={ intl.formatMessage({
        id: 'audiences.builder.fields.roleCondition.plural',
        description: 'Plural selection name in role condition builder',
        defaultMessage: 'roles'
      }) }
    />
  </StyledRoleConditionContainer>;
};
