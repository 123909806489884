import { FC, HTMLAttributes, ReactNode, useCallback, useEffect, useState } from 'react';

type Props = {
  resolveUrl: () => Promise<string>;
  loadingFacade: ReactNode;
  placeholder?: ReactNode;
  renderImage: (
    url: string,
    onLoad: HTMLAttributes<HTMLImageElement>['onLoad'],
  ) => ReactNode;
  renderResolutionError: (resolutionError: unknown, retry: () => void) => ReactNode;
}

export const ImageWithLoadingFacade: FC<Props> = ({
  resolveUrl,
  loadingFacade,
  placeholder,
  renderImage,
  renderResolutionError,
}) => {
  const [resolvedUrl, setResolvedUrl] = useState<string>();
  const [resolutionError, setResolutionError] = useState<unknown>();
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);

  const resolveAndSetUrl = useCallback(() => {
    setResolvedUrl(undefined);
    resolveUrl()
      .then(url => {
        setResolvedUrl(url);
      })
      .catch(setResolutionError)
  }, [resolveUrl]);

  useEffect(() => {
    resolveAndSetUrl();
  }, [resolveAndSetUrl]);

  const onLoad = useCallback(() => {
    setImageLoaded(true);
  }, []);

  return (
    <>
      {
        resolutionError
          ? renderResolutionError(resolutionError, resolveAndSetUrl)
          : (
            <>
              { !imageLoaded && placeholder }
              { resolvedUrl && renderImage(resolvedUrl, onLoad) }
              { (!resolvedUrl || !imageLoaded) && loadingFacade }
            </>
          )
      }
    </>
  )
};
