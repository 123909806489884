import { ChangeEventHandler, FunctionComponent, useCallback } from 'react';
import { useIntl } from 'react-intl';

import {
  DraftUploadRequest,
  UPLOAD_REQUEST_MAX_UPLOADS_MAX,
  UPLOAD_REQUEST_MAX_UPLOADS_MIN,
  UPLOAD_REQUEST_MIN_UPLOADS_MIN
} from '../../../Model/ContentTypes';
import { RequestFields } from '..';
import { FieldValidationErrors, NumberInput } from '../../../../Common/Component';
import { UploadRequestValidator } from '../../../Utility/Validation';
import { ContentEditorProps } from '../../../../Content/Model';
import { ValidationTree } from '../../../../Common/Model';
import { CardValidationMerger } from '../../../../Content/Utility';
import { UploadRequestEditorContent } from '../../../Service';

export const UploadRequestFields: FunctionComponent<ContentEditorProps<UploadRequestEditorContent>> = ({
  editorContent,
  onEditorContentChanged,
  validation,
  onValidationChanged,
}) => {
  const intl = useIntl();
  const minUploadsErrors = validation?.children.content?.children.minUploads?.errors || [];
  const maxUploadsErrors = validation?.children.content?.children.maxUploads?.errors || [];

  const whenMinUploadsChanged: ChangeEventHandler<HTMLInputElement> = (event) => {
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          minUploads: +event.target.value,
        },
      },
    });
  };

  const whenMaxUploadsChanged: ChangeEventHandler<HTMLInputElement> = (event) => {
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          maxUploads: +event.target.value,
        },
      },
    });
  };

  const whenMinUploadsBlurred = () => whenContentChildrenValidationChanged({
    minUploads: UploadRequestValidator.validateMinUploads(editorContent.card.content),
  });
  const whenMaxUploadsBlurred = () => whenContentChildrenValidationChanged({
    maxUploads: UploadRequestValidator.validateMaxUploads(editorContent.card.content),
  });

  const whenContentChildrenValidationChanged = useCallback(
    (children: ValidationTree<DraftUploadRequest>['children']): void => {
      onValidationChanged(
        CardValidationMerger.addContentValidationChildrenToCardValidation(children, validation)
      );
    },
    [validation, onValidationChanged],
  );

  return (
    <>
      <RequestFields
        editorContent={ editorContent }
        onEditorContentChanged={ onEditorContentChanged }
        validation={ validation }
        onValidationChanged={ onValidationChanged }
      />
      <NumberInput
        id="minUploads"
        inputProps={{
          min: UPLOAD_REQUEST_MIN_UPLOADS_MIN,
          max: Math.min(editorContent.card.content.maxUploads, UPLOAD_REQUEST_MAX_UPLOADS_MAX),
        }}
        label={intl.formatMessage({
          id: 'form.fields.uploadRequest.minUploads.label',
          defaultMessage: 'Min uploads *',
        })}
        value={ editorContent.card.content.minUploads }
        onChange={ whenMinUploadsChanged }
        onBlur={ whenMinUploadsBlurred }
        error={ !!minUploadsErrors.length }
        fullWidth
      />
      <FieldValidationErrors
        fieldName={intl.formatMessage({
          id: 'form.fields.uploadRequest.minUploads.name',
          defaultMessage: 'min uploads',
        })}
        validationErrors={ minUploadsErrors }
      />
      <NumberInput
        id="maxUploads"
        inputProps={{
          min: Math.max(editorContent.card.content.minUploads, UPLOAD_REQUEST_MAX_UPLOADS_MIN),
          max: UPLOAD_REQUEST_MAX_UPLOADS_MAX,
        }}
        label={intl.formatMessage({
          id: 'form.fields.uploadRequest.maxUploads.label',
          defaultMessage: 'Max uploads *',
        })}
        value={ editorContent.card.content.maxUploads }
        onChange={ whenMaxUploadsChanged }
        onBlur={ whenMaxUploadsBlurred }
        error={ !!maxUploadsErrors.length }
        fullWidth
      />
      <FieldValidationErrors
        fieldName={intl.formatMessage({
          id: 'form.fields.uploadRequest.maxUploads.name',
          defaultMessage: 'max uploads',
        })}
        validationErrors={ maxUploadsErrors }
      />
    </>
  )
};
