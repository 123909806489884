import React, { PropsWithChildren } from 'react';
import { useIntl } from 'react-intl';
import { BreakpointContext } from 'op-storybook/lib/providers/BreakpointProvider/BreakpointProvider';

import { StyledContentFields, StyledContentHeader } from './style';
import { ValidationTree } from '../../../Common/Model';
import { IconButton } from '../../../Common/Component';
import BackIcon from '../../../Assets/img/icons/streamline/navigation-left-circle-1.svg';
import DeleteIcon from '../../../Assets/img/icons/streamline/bin-1.svg';
import DuplicateIcon from '../../../Assets/img/icons/streamline/layers-back.svg';
import { EditorContent } from '../../Model';
import { useContentDefinitionRegistry } from '../../Hook';
import { useContextOrThrow } from '../../../Core/Hook';

interface Props<T extends EditorContent> {
  content: T;
  onContentChanged: (content: T) => void;
  validation?: ValidationTree<T['card']>;
  onValidationChanged: (children: ValidationTree<T['card']>) => void;
  onBackButtonClicked: () => void;
  onDuplicateButtonClicked: () => void;
  duplicateButtonDisabled?: boolean;
  onRemoveButtonClicked: () => void;
  removeButtonDisabled?: boolean;
}

export const ContentFields = <T extends EditorContent>({
  content,
  onContentChanged,
  validation,
  onValidationChanged,
  onBackButtonClicked,
  onDuplicateButtonClicked,
  duplicateButtonDisabled = false,
  onRemoveButtonClicked,
  removeButtonDisabled,
}: PropsWithChildren<Props<T>>): JSX.Element => {
  const intl = useIntl();
  const { getEditorComponent, getIconComponent, getDraftContentTitle } = useContentDefinitionRegistry();
  const screenWidth = useContextOrThrow(BreakpointContext);
  const EditorComponent = getEditorComponent(content);
  const IconComponent = getIconComponent(content.card.content.type);

  return (
    <StyledContentFields key={ content.id }>
      <StyledContentHeader>
        { screenWidth.lessThan.md && (
          <IconButton
            color="secondary"
            IconComponent={ BackIcon }
            label={ intl.formatMessage({
              id: 'content.back',
              description: 'Screen-reader label for back icon button.',
              defaultMessage: 'Go back',
            }) }
            onClick={ onBackButtonClicked }
            size="small"
          />
        ) }
        <IconComponent/>
        <span>{ getDraftContentTitle(intl, content.card.content) }</span>
        <IconButton
          IconComponent={ DuplicateIcon }
          label={ intl.formatMessage({
            id: 'content.duplicate',
            description: 'Screen-reader label for duplicate content icon button.',
            defaultMessage: 'Duplicate content',
          }) }
          disabled={ duplicateButtonDisabled }
          onClick={ onDuplicateButtonClicked }
          size="small"
        />
        <IconButton
          IconComponent={ DeleteIcon }
          label={ intl.formatMessage({
            id: 'content.remove',
            description: 'Screen-reader label for remove content icon button.',
            defaultMessage: 'Remove content',
          }) }
          disabled={ removeButtonDisabled }
          onClick={ onRemoveButtonClicked }
          size="small"
        />
      </StyledContentHeader>
      <EditorComponent
        editorContent={ content }
        onEditorContentChanged={ onContentChanged }
        validation={ validation }
        onValidationChanged={ onValidationChanged }
      />
    </StyledContentFields>
  );
};
