import { FunctionComponent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ChartData } from 'chart.js';
import { useTheme } from '@emotion/react';

import { Metric } from '../../Model';
import { DoughnutChartMetric } from '../DoughnutChartMetric/DoughnutChartMetric';
import { MetricTransformer } from '../../Utility';
import { MetricContainer } from '../MetricContainer/MetricContainer';
import { MissingDataMessage } from '../MissingDataMessage/MissingDataMessage';

interface Props {
  previousMetric?: Metric;
  currentMetric: Metric;
}

export const FormSubmissionsByStatusMetric: FunctionComponent<Props> = ({
  previousMetric,
  currentMetric,
}) => {
  const intl = useIntl();
  const theme = useTheme();
  const currentPeriodDataPoints = consolidateDatasets(currentMetric);
  const previousPeriodDataPoints = previousMetric ? consolidateDatasets(previousMetric) : [];
  const currentTotal = sumDataPoints(currentPeriodDataPoints);
  const previousTotal = sumDataPoints(previousPeriodDataPoints);
  const chartData: Required<ChartData<'doughnut'>> = {
    datasets: [{
      data: currentPeriodDataPoints,
      backgroundColor: [
        theme.palette.secondary.dark,
        theme.palette.secondary.main,
        theme.palette.secondary.light,
      ],
      borderWidth: 0,
    }],
    labels: [
      intl.formatMessage({
        id: 'dashboard.formSubmissionsByStatus.closedLabel',
        description: 'Label for closed submissions in dashboard metric',
        defaultMessage: 'Closed',
      }),
      intl.formatMessage({
        id: 'dashboard.formSubmissionsByStatus.openLabel',
        description: 'Label for open submissions in dashboard metric',
        defaultMessage: 'Open',
      }),
      intl.formatMessage({
        id: 'dashboard.formSubmissionsByStatus.noneLabel',
        description: 'Label for submissions with no status in dashboard metric',
        defaultMessage: 'No status',
      }),
    ],
  };

  return (
    <MetricContainer
      title={ intl.formatMessage({
        id: 'dashboard.formSubmissionsByStatus.heading',
        description: 'Heading for form submissions by status stat block on dashboard.',
        defaultMessage: 'Smart Form submissions',
      }) }
    >
      {
        currentTotal
          ? (
            <DoughnutChartMetric
              previousValue={ previousTotal }
              currentValue={ currentTotal }
              chartData={ chartData }
            />
          )
          : (
            <MissingDataMessage>
              <FormattedMessage
                id="dashboard.formSubmissionsByStatus.noData"
                defaultMessage="<p>No smart form submissions were made in this period.</p>"
              />
            </MissingDataMessage>
          )
      }
    </MetricContainer>
  );
}

const sumDataPoints = (dataPoints: number[]): number => dataPoints.reduce(
  (sum, value) => sum + value,
  0,
);

const consolidateDatasets = (metric: Metric): number[] => MetricTransformer.sumDatasets(
  metric,
  [
    'closed',
    'open',
    'none',
  ],
);
