import { FC } from 'react';

import { TagCondition } from '../../../../Audiences/Model';
import { TagType } from '../../../../Tags/Model';
import { TagCloud } from '../TagCloud';

type Props = {
  condition: TagCondition<TagType.Region>;
  onChange?: (condition: TagCondition<TagType.Region>) => void;
};

export const RegionCloud: FC<Props> = ({
  condition,
  onChange,
}) => (
  <TagCloud
    condition={ condition }
    onChange={ onChange }
    tagType={ TagType.Region }
  />
);
