import { FC } from 'react';

import { StyledDivider } from './style';
import { PaletteLookup } from '../../../lib/model/PaletteLookup/PaletteLookup';

type Props = {
  palette?: PaletteLookup;
};

export const Divider: FC<Props> = ({
  palette = {
    colour: 'grey',
    intensity: 200,
  },
}) => (
  <StyledDivider
    palette={ palette }
  />
);
