import { FC } from 'react';
import { StyleBuilder } from 'op-storybook/lib/model/StyleBuilder/StyleBuilder';

import { RichTextContentPreview } from '../../../Content/Component';

type Props = {
  text: string;
};

export const AuthLayoutRichText: FC<Props> = ({ text }) => {
  const styles = buildStyles();

  return (
    <RichTextContentPreview
      css={ styles.richText }
      text={ text }
    />
  );
};

const buildStyles: StyleBuilder = () => ({
  richText: theme => ({
    display: 'flex',
    flexDirection: 'column',

    '*': {
      textAlign: 'center',
      margin: 0,
    },

    'h1, h2, h3, h4, h5, h6': {
      fontSize: theme.new.typography.display.size.sm.fontSize,
      lineHeight: theme.new.typography.display.size.sm.lineHeight,
      fontWeight: theme.new.typography.display.weight.semibold,
      color: theme.new.palette.grey[900].main,
    },

    'p, span': {
      fontSize: theme.new.typography.text.size.md.fontSize,
      lineHeight: theme.new.typography.text.size.md.lineHeight,
      fontWeight: theme.new.typography.text.weight.medium,
      color: theme.new.palette.grey[500].main,
    },
  }),
});
