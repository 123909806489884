import { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { ContentPreviewProps } from '../../../../Content/Model';
import { CommentEditorContent } from '../../../Service';
import { CardTitle, ContentWithType, ScrollableContent } from '..';
import { VerticallySpaced } from '../../../../Common/Component';
import { StyledTextArea } from './style';

export const CommentRequestPreview: FC<ContentPreviewProps<CommentEditorContent>> = ({
  editorContent,
  onReady,
}) => {
  const intl = useIntl();

  useEffect(() => {
    onReady();
  }, [onReady]);

  return (
    <ScrollableContent>
      <ContentWithType
        localisedContentType={ intl.formatMessage({
          id: 'broadcasts.content.preview.comment',
          description: 'Label for comment request type used in preview.',
          defaultMessage: 'Comment',
        }) }
      >
        <VerticallySpaced gap={ 2 }>
          <CardTitle>{ editorContent.card.content.title }</CardTitle>
          <StyledTextArea
            placeholder={ intl.formatMessage({
              id: 'broadcasts.content.preview.comment.placeholder',
              description: 'Placeholder for response input in comment request preview.',
              defaultMessage: 'Tap to type…',
            }) }
          />
        </VerticallySpaced>
      </ContentWithType>
    </ScrollableContent>
  );
}
