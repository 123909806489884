import {ComponentProps, FC, FormEventHandler, ReactNode, useCallback, useRef} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { AuthHeader } from 'op-storybook/lib/components/AuthHeader/AuthHeader';
import { Typography } from 'op-storybook/stories/components/Typography/Typography';
import EmailIcon from 'op-storybook/lib/assets/icon/figma/mail-01.svg';
import SmsIcon from 'op-storybook/lib/assets/icon/figma/message-dots-square.svg';
import { Form } from 'op-storybook/lib/components/Form/Form';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { ButtonBase } from 'op-storybook/stories/components/ButtonBase/ButtonBase';
import { Username } from 'op-storybook/stories/components/UsernameField/UsernameField';
import { ApiRequest } from '@ourpeople/shared/Core/Model/ApiRequest';

import { ResponsiveOtpField } from '../ResponsiveOtpField/ResponsiveOtpField';
import { RequestState } from '../../../Models';
import { CodeRateLimitMessage } from '../CodeRateLimitMessage/CodeRateLimitMessage';
import { LoginErrorResponse } from '../../Hook/useLoginRequest';
import {PasswordLoginSubmitButton} from '../../../Login/Component/PasswordLoginSubmitButton/PasswordLoginSubmitButton';

type Props = {
  username: Username;
  code: string;
  onChange: (code: string) => void;
  remainingDurationInSeconds: number | null;
  onSubmit: (code: string, rememberMe: boolean) => void;
  submitRequest: ApiRequest<unknown, LoginErrorResponse>;
  onResendCodeClicked: () => void;
  returnLinkProps: ComponentProps<typeof Link>;
  showRememberMe?: boolean;
  rememberMe?: boolean;
  onRememberMeChange?: (rememberMe: boolean) => void;
};

export const CodeEntryForm: FC<Props> = ({
  username,
  code,
  onChange,
  remainingDurationInSeconds,
  onSubmit,
  submitRequest,
  onResendCodeClicked,
  returnLinkProps,
  rememberMe = false,
  showRememberMe,
  onRememberMeChange,
}) => {
  const intl = useIntl();
  const previousCode = useRef<string>();

  const whenChanged = useCallback((code: string) => {
    onChange(code);

    if (code.length < 6 || (code.slice(0, 5) !== previousCode.current && previousCode.current !== undefined)) {
      previousCode.current = code;
      return;
    }

    previousCode.current = code;
    onSubmit(code, rememberMe);
  }, [onChange, onSubmit, rememberMe]);

  const whenRememberMeChange = useCallback((newRememberMe: boolean) => {
    if (!onRememberMeChange) {
      return;
    }
    onRememberMeChange(newRememberMe);
  }, [onRememberMeChange]);

  const whenSubmitted: FormEventHandler = useCallback(event => {
    event.preventDefault();

    onSubmit(code, rememberMe);
  }, [onSubmit, code, rememberMe]);

  return (
    <>
      <AuthHeader
        header={
          username.parsedPhone?.e164Value
            ? intl.formatMessage({
              description: 'Heading for security token submission form once code has been sent to users phone number',
              defaultMessage: 'Check your messages',
            })
            : intl.formatMessage({
              description: 'Heading for security token submission form once code has been sent to users email address',
              defaultMessage: 'Check your email',
            })
        }
        supportingText={
          intl.formatMessage({
            description: 'Supporting text for security token submission form once code has been sent',
            defaultMessage: 'We sent a code to <strong>{ username }</strong>',
          }, {
            username: username.value,
            strong: (chunks: ReactNode) => (
              <Typography
                weight="semibold"
                palette={ {
                  colour: 'grey',
                  intensity: 500,
                } }
              >
                { chunks }
              </Typography>
            ),
          })
        }
        graphic={ {
          type: 'icon',
          props: {
            IconComponent: username.parsedPhone?.e164Value ? SmsIcon : EmailIcon,
            size: 'lg',
          },
        } }
      />
      <Typography
        align="center"
        palette={ {
          colour: 'grey',
          intensity: 500,
        } }
      >
        <FormattedMessage
          description="Code expiry help text on security token submission form"
          defaultMessage="Codes are valid for <strong>10 minutes</strong>"
          values={ {
            strong: chunks => (
              <Typography
                weight="bold"
                palette={ {
                  colour: 'grey',
                  intensity: 500,
                } }
              >
                { chunks }
              </Typography>
            ),
          } }
        />
      </Typography>
      <Form
        onSubmit={ whenSubmitted }
      >
        <Stack
          direction="column"
          gap={ 4 }
        >
          <Stack
            direction="column"
            align="center"
          >
            <ResponsiveOtpField
              value={ code }
              onChange={ whenChanged }
              autoFocus
            />
          </Stack>
          <PasswordLoginSubmitButton
            showRememberMe={ showRememberMe }
            rememberMe={rememberMe}
            onRememberMeChange={whenRememberMeChange}
            disabled={ code.length < 6 }
            submitting={ submitRequest.state === RequestState.FETCHING }
            error={ false }
            label={ intl.formatMessage({
              description: 'Label for submit button on security token submission form',
              defaultMessage: 'Verify code'
            }) }
          />
          { submitRequest.state === RequestState.FAILED && (
            <Typography
              palette={ {
                colour: 'error',
                intensity: 500,
              } }
              size="sm"
              weight="regular"
              align="center"
            >
              {
                submitRequest.result.error === 'INVALID'
                  ? (
                    <FormattedMessage
                      description="Error message when failing to submit with invalid OTP code on security token submission form."
                      defaultMessage="Code is invalid."
                    />
                  )
                  : (
                    <FormattedMessage
                      description="Generic error message when failing to submit code on security token submission form."
                      defaultMessage="Could not verify code, please try again."
                    />
                  )
              }
            </Typography>
          ) }
        </Stack>
      </Form>
      <Stack
        direction="column"
        gap={ 1 }
      >
        <Stack
          gap={ 2 }
          justify="center"
        >
          <FormattedMessage
            description="Label for resend code text on OTP entry page."
            defaultMessage="Didn’t receive the code? <button>Send another</button>"
            values={ {
              button: chunks => (
                <ButtonBase
                  variant="tertiary-dark"
                  onClick={ onResendCodeClicked }
                  disabled={ remainingDurationInSeconds !== null }
                >
                  { chunks }
                </ButtonBase>
              )
            } }
          />
        </Stack>
        { remainingDurationInSeconds !== null && (
          <CodeRateLimitMessage
            remainingDurationInSeconds={ remainingDurationInSeconds }
          />
        ) }
      </Stack>
      <Stack
        direction="column"
        align="center"
      >
        <Link { ...returnLinkProps } />
      </Stack>
    </>
  );
}
