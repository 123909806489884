import styled from '@emotion/styled';

export const StyledNotificationEngagementChart = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;

  > div {
    width: 100%;
    height: 100%;
    max-height: 400px;
    overflow: hidden;
  }
`;
