import styled from '@emotion/styled';
import { rgba } from 'polished';

export const ContentSummaryHeading = styled.div`
  display: grid;
  grid-template-columns: 5vw 1fr;
  padding: 1rem;
`;

interface StyledImageContentPreviewProps {
  imageUrl: string;
}

export const StyledImageContentPreview = styled.div<StyledImageContentPreviewProps>`
  background-image: url(${props => props.imageUrl});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
  display: flex;
  padding: 10px;
  align-items: flex-end;
  justify-content: flex-start;
  font-size: 1rem;

  @media (max-width: 600px) {
    font-size: 0.5rem;
  }

  @media (max-width: 1024px) {
    font-size: 0.7rem;
  }
`;

export const StyledImageContentText = styled.p`
  margin: 1rem;
`;

export const StyledTextContentPreview = styled.div`
  width: 30vw;
  word-break: break-word;
  overflow-wrap: break-word;
  overflow-wrap: anywhere;

  h1 {
    text-align: center;
    font-size: 2.4rem;
  }
  h2 {
    text-align: center;
    font-size: 2.2rem;
  }
  h3 {
    text-align: center;
    font-size: 2rem;
  }
  h4 {
    text-align: center;
    font-size: 1.8rem;
  }
  h5 {
    text-align: center;
    font-size: 1.6rem;
  }
  h6 {
    text-align: center;
    font-size: 1.4rem;
  }
`;

interface StyledImageContentCaptionProps {
  backgroundColor?: string;
  color?: string;
  size: 'small' | 'medium' | 'large';
}

export const StyledImageContentCaption = styled.div<StyledImageContentCaptionProps>`
  background-color: ${props => rgba(props.backgroundColor || props.theme.palette.primary.main, 0.5)};
  border-radius: 8px;
  color: ${props => props.color || props.theme.palette.primary.contrastText};
  font-weight: 400;
  padding: 16px;
  transform: rotate(-1deg);

  ${props => props.size === 'large' && `
    max-width: 60%;
    font-size: 5em;
  `}

  ${props => props.size === 'medium' && `
    max-width: 70%;
    font-size: 3em;
    line-height: 1.7;
  `}

  ${props => props.size === 'small' && `
    max-width: 85%;
    font-size: 2em;
    line-height: 2.1;
  `}
`;

export const StyledImageContentLoading = styled.div`
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledEventHeading = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

export const StyledEventDetails = styled.dl`
  display: flex;
  align-items: baseline;
  margin: .5rem 0 0;
  flex-wrap: wrap;
  gap: ${ props => props.theme.spacing(3) };

  dt {
    color: ${props => props.theme.palette.primary.main};
  }

  dd {
    margin-bottom: 0;
    margin-left: 0;
    padding: 0;
  }
`;
