import styled from '@emotion/styled';
import { CSSProperties } from 'react';

type StyledLineClampProps = {
  lines: number;
  minWidth?: number;
  wordBreak?: CSSProperties['wordBreak'];
};

export const StyledLineClamp = styled.div<StyledLineClampProps>`
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: ${ props => props.lines };
  -webkit-box-orient: vertical;
  text-align: left;
  line-height: 1.25;
  ${ props => props.wordBreak && `word-break: ${ props.wordBreak };` };

  ${ props => props.minWidth && `
    min-width: ${ props.minWidth }px;
  ` }
`;
