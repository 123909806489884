import { FunctionComponent, ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme } from '@emotion/react';

import { TopActiveTeamsDatasetTeam, TopActiveTeamsMetric } from '../../Model/TopActiveTeams';
import { BarChartMetric, MetricContainer, MissingDataMessage } from '..';
import { DatasetOptionsWithKey, MetricTransformer } from '../../Utility';
import { DeprecatedLink } from '../../../Common/Component';
import {useEnvironmentSettings} from '../../../Common/Hook';

interface Props {
  metric: TopActiveTeamsMetric;
}

export const MostActiveLocationsMetric: FunctionComponent<Props> = ({
  metric,
}) => {
  const intl = useIntl();
  const theme = useTheme();
  const {
    eventsEnabled,
    broadcastsV1EventsEnabled,
    broadcastsV2Enabled,
  } = useEnvironmentSettings();
  const teams = metric.datasets.reduce<TopActiveTeamsDatasetTeam[]>((teams, dataset) => {
    const currentTeam = dataset.metadata.team;
    const index = teams.findIndex(team => team.id === currentTeam.id);

    if (index !== -1) {
      return teams;
    }

    return teams.concat(currentTeam);
  }, []);

  const datasetOptions: DatasetOptionsWithKey[] = [
    {
      key: 'contentBroadcastsSent',
      label: intl.formatMessage({
        id: 'dashboard.mostActiveLocations.broadcastsSent',
        description: 'Label for broadcasts sent dataset in most active locations graph',
        defaultMessage: 'Broadcasts sent',
      }),
      backgroundColor: theme.palette.secondary.main,
    },
    ...(
      eventsEnabled
      && (
        !broadcastsV2Enabled
        || !!broadcastsV1EventsEnabled
      )
    )
      ? [{
        key: 'eventsSent',
        label: intl.formatMessage({
          id: 'dashboard.mostActiveLocations.eventsSent',
          description: 'Label for events sent dataset in most active locations graph',
          defaultMessage: 'Events sent',
        }),
        backgroundColor: theme.palette.secondary.dark,
      }]
      : [],
    {
      key: 'chatMessagesSent',
      label: intl.formatMessage({
        id: 'dashboard.mostActiveLocations.chatMessagesSent',
        description: 'Label for chat messages sent dataset in most active locations graph',
        defaultMessage: 'Chat messages sent',
      }),
      backgroundColor: theme.palette.secondary.light,
    },
  ];

  const chartData = MetricTransformer.consolidateDatasets<'bar', TopActiveTeamsDatasetTeam>(
    metric,
    datasetOptions,
    teams,
    team => team.name,
  );

  return (
    <MetricContainer
      title={ intl.formatMessage({
        id: 'dashboard.mostActiveTeams.heading',
        defaultMessage: 'Most active teams'
      }) }
    >
      {
        metric.datasets.length
          ? (
            <BarChartMetric
              chartData={ chartData }
            />
          )
          : (
            <MissingDataMessage>
              <FormattedMessage
                id="dashboard.mostActiveTeams.noData"
                defaultMessage="<p>You only have one team!</p><p>Why not <a>add some more</a>.</p>"
                values={{
                  a: (chunks: ReactNode) => (
                    <DeprecatedLink to="/settings/tags/create?type=team">
                      { chunks }
                    </DeprecatedLink>
                  ),
                }}
              />
            </MissingDataMessage>
          )
      }
    </MetricContainer>
  );
};
