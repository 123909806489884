import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDropzone } from 'react-dropzone';

import { DeprecatedButton } from '../Button';
import { StyledDropzone, StyledLinearProgress, StyledUploadingDropzone, StyledErrorIcon, StyledSuccessIcon } from './styles';
import cloudUpload from '../../Assets/img/icons/cloud-upload.svg?url';
import uploadIcon from '../../Assets/img/icons/circle/upload.svg?url';

interface UploadingDropzoneProps {
  progress: number;
}

interface ResettableDropzoneProps {
  onReset?: () => void;
}

interface ErrorDropzoneProps extends ResettableDropzoneProps {
  message?: string;
}

const ProcessingDropzone: FunctionComponent = () => {
  const intl = useIntl();

  return (
    <StyledUploadingDropzone>
      <img
        src={uploadIcon}
        alt={intl.formatMessage({
          id: 'upload.uploadIconAltText',
          description: 'Upload icon alt text',
          defaultMessage: 'Icon with an upwards arrow inside a circle'
        })}
        />
      <FormattedMessage
        id="upload.processing"
        description="Generic message for uploads being processed"
        defaultMessage="Processing..."
        />
      <StyledLinearProgress />
    </StyledUploadingDropzone>
  );
};

const UploadingDropzone: FunctionComponent<UploadingDropzoneProps> = ({ progress }) => {
  const intl = useIntl();

  return (
    <StyledUploadingDropzone>
      <img
        src={uploadIcon}
        alt={intl.formatMessage({
          id: 'upload.uploadIconAltText',
          description: 'Upload icon alt text',
          defaultMessage: 'Icon with an upwards arrow inside a circle'
        })}
        />
      <FormattedMessage
        id="upload.uploading"
        description="Generic message for uploads in progress"
        defaultMessage="Uploading..."
        />
      <StyledLinearProgress variant="determinate" value={progress} />
    </StyledUploadingDropzone>
  );
};

const ErrorDropzone: FunctionComponent<ErrorDropzoneProps> = (
  { message, onReset }
) => (
  <StyledUploadingDropzone>
    <StyledErrorIcon role="presentation"/>
    {
      message ?? (
        <FormattedMessage
          id="upload.error"
          description="Generic error message for uploads"
          defaultMessage="Upload failed! Please try again."
          />
      )
    }
    <StyledLinearProgress variant="determinate" value={0} />
    <DeprecatedButton bordered={false} onClick={onReset}>
      <FormattedMessage
        id="upload.startAgain"
        description="Generic error message for restarting an upload"
        defaultMessage="Start again"
        />
    </DeprecatedButton>
  </StyledUploadingDropzone>
);

const SuccessDropzone: FunctionComponent<ResettableDropzoneProps> = ({ onReset }) => (
  <StyledUploadingDropzone>
    <StyledSuccessIcon role="presentation" />
    <FormattedMessage
      id="upload.success"
      description="Generic error message for uploads"
      defaultMessage="Upload successful!"
      />
    <StyledLinearProgress variant="determinate" value={100} />
    <DeprecatedButton bordered={false} onClick={onReset}>
      <FormattedMessage
        id="upload.startAgain"
        description="Generic error message for restarting an upload"
        defaultMessage="Start again"
        />
    </DeprecatedButton>
  </StyledUploadingDropzone>
);

export interface DropzoneProps {
  accept?: string | string[];
  disabled?: boolean;
  maxSize?: number;
  minSize?: number;
  multiple?: boolean;
  instruction?: string;
  isProcessing?: boolean;
  onDrop?: (
    files: File[],
    onUploadProgress: (progressEvent: ProgressEvent) => void
  ) => Promise<void>;
  onReset?: () => void;
}

/**
 * @deprecated Prefer Dropzone component
 */
export const LegacyDropzone: FunctionComponent<DropzoneProps> = (
  { accept, disabled, maxSize, multiple, minSize, instruction, isProcessing, onDrop, onReset }
) => {
  const [error, setError] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [uploading, setUploading] = useState<boolean>(false);
  const [isUploaded, setIsUploaded] = useState<boolean>(false);
  const [cancelled, setCancelled] = useState<boolean>(false);
  const intl = useIntl();

  const onInternalReset = () => {
    setError(false);
    setProgress(0);
    setUploading(false);
    setIsUploaded(false);
    onReset && onReset();
  }

  const onUploadProgress = (progressEvent: ProgressEvent) => {
    setProgress(
      Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      )
    );
  };

  useEffect(() => {
    return () => setCancelled(true);
  }, []);

  const onInternalDrop = useCallback((acceptedFiles: File[]) => {
    if (onDrop) {
      setUploading(true);
      onDrop(acceptedFiles, onUploadProgress)
        .then(() => {
          if (cancelled) {
            return;
          }

          setUploading(false);
          setIsUploaded(true);
        })
        .catch(() => {
          if (cancelled) {
            return;
          }

          setUploading(false);
          setError(true);
        });
    }
  }, [cancelled, onDrop]);

  // Disable the next rule as it's not relevant in function components
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open
  } = useDropzone({
    accept,
    disabled,
    maxSize,
    minSize,
    multiple,
    noClick: true,
    onDrop: onInternalDrop
  });

  if (error) {
    return <ErrorDropzone onReset={onInternalReset} />;
  }

  if (uploading) {
    return <UploadingDropzone progress={progress} />;
  }

  if (isUploaded) {
    return (
      isProcessing
      ? <ProcessingDropzone />
      : <SuccessDropzone onReset={onInternalReset} />
    );
  }

  return (
    <StyledDropzone
      {...getRootProps({isDragActive})}
      >
      <input {...getInputProps()} />
      <img
        src={cloudUpload}
        alt={intl.formatMessage({
          id: 'upload.iconAltText',
          description: 'Cloud upload icon alt text',
          defaultMessage: 'Icon with an upwards arrow towards a cloud shape'
        })}
        />
      {
        isDragActive
        ? (
          <FormattedMessage
            id="upload.dropInstruction"
            description="Instruction on dropping onto an upload form"
            defaultMessage="Drop your file(s) here..."
            />
        )
        : (
          instruction ?? (
            <FormattedMessage
              id="upload.instruction"
              description="Instruction on drag-n-drop upload form"
              defaultMessage="Drag &amp; drop your file(s) here..."
              />
          )
        )
      }
      <DeprecatedButton primary={true} disabled={isDragActive} onClick={open}>
        <FormattedMessage
          id="upload.browse"
          description="Copy for browse button on upload form"
          defaultMessage="Browse to upload"
          />
      </DeprecatedButton>
    </StyledDropzone>
  );
};
