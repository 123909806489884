import { FormattedList, FormattedMessage } from 'react-intl';
import { ReactNode } from 'react';

import { ValidationError } from '../../../Common/Model';
import { Notice } from '../../../Common/Component';
import { MissingPeopleMappingTypesError } from '../../Model';
import { useLocalisedPeopleRecordTypes } from '../../Hook';
import { ImportErrorPresentationComponent } from '../../../Imports/Model';

export const PeopleImportColumnMapErrors: ImportErrorPresentationComponent = ({ errors }) => {
  const localisedPeopleRecordTypes = useLocalisedPeopleRecordTypes();

  const getErrorMessage = (error: ValidationError): ReactNode => {
    switch (error.type) {
      case 'missingMappingTypes':
        return (
          <FormattedMessage
            id="people.import.missingMappingTypesError"
            description="Error when import CSV is missing mapping types."
            defaultMessage="{ missingTypeCount, plural, one { A corresponding column } other { Corresponding columns } } for { recordTypeList } could not be determined. Please make sure your import data contains { missingTypeCount, plural, one { a column for { recordTypeList } and that it has } other { columns for the missing tag types and that they have } } been correctly assigned below."
            values={ {
              missingTypeCount: (error as MissingPeopleMappingTypesError).metadata.missingTypes.length,
              recordTypeList: (
                <FormattedList
                  type="conjunction"
                  value={ (error as MissingPeopleMappingTypesError).metadata.missingTypes.map(recordType => (
                    localisedPeopleRecordTypes.find(localisedPeopleRecordType => localisedPeopleRecordType.id === recordType)?.localisation
                  )) }
                />
              )
            } }
          />
        );
      default:
        return (
          <FormattedMessage
            id="people.import.fallbackColumnMapError"
            description="Fallback column map error when error can not be identified."
            defaultMessage="There is a problem with your import data."
          />
        );
    }
  }

  return (
    <>
      { errors.map(error => (
        <Notice
          key={ error.type }
          variant="outlined"
          feedback={ {
            severity: 'error',
            message: getErrorMessage(error),
          } }
        />
      )) }
    </>
  );
};
