import { getApp, initializeApp, FirebaseApp } from 'firebase/app';

import { firebaseConfig } from '../../../../config/firebase/config';

const initFirebaseApp = () => {
  let app: FirebaseApp;

  try {
    app = getApp();
  } catch (error) {
    app = initializeApp(firebaseConfig);
  }

  return app;
};

const firebaseApp = initFirebaseApp();

export const useFirebase = (): FirebaseApp => firebaseApp;
