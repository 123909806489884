import { ImportUser } from '../../People/Model';
import { TextFormatter } from '..';
import { Tag } from '../../Types';

export class ImportUserParser {
  public static fullName = (user: ImportUser): string => {
    return TextFormatter.spaceSeparated(user.pulledUser.data.firstName, user.pulledUser.data.lastName || '')
  }

  public static pulledIds = (user: ImportUser): string[] => {
    return user.pulledUser.ids;
  }

  public static cleanPulledIds = (user: ImportUser): string[] => {
    return ImportUserParser.pulledIds(user).map(
      (id) => id.substring(
        0,
        id.lastIndexOf(':'),
      )
    );
  }

  public static tags = (user: ImportUser, tagType: string): string => {
    const tagNames = user.pulledUser.data.profiles.reduce(
      (accumulatedTagNames: string[], profile) => {
        const profileTags: Tag[] = profile.tags.filter(
          (tag) => tag.type === tagType,
        );
        return accumulatedTagNames.concat(
          profileTags.map((tag) => tag.name),
        );
      },
      [],
    );
    return tagNames.join(', ');
  }
}
