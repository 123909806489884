import { IntlShape } from 'react-intl';
import VideoBlockIcon from 'op-storybook/lib/assets/icon/figma/video-recorder.svg';
import {
  BlankValidationErrorDefinition
} from 'op-storybook/lib/model/Validation/Definition/BlankValidationErrorDefinition';

import { BlockErrorDefinitions, BroadcastBlockDefinition, PreviewSlot } from '../../Model/BroadcastBlockDefinition';
import { BroadcastBlock } from '../../Model/Broadcast';
import { UniqueIdGenerator } from '../../../../Common/Utility';
import { VideoBlockInlineForm } from '../../Component/Blocks/VideoBlock/VideoBlockInlineForm';
import { VideoBlockForm } from '../../Component/Blocks/VideoBlock/VideoBlockForm';
import { VideoBlockPreview } from '../../Component/Blocks/VideoBlock/VideoBlockPreview';

export type VideoBlock = {
  localId: string;
  kind: 'media';
  attributes: {
    uploadId: string;
    videoThumbnailKey?: string;
  };
};

export class VideoBlockDefinition implements BroadcastBlockDefinition<VideoBlock> {
  public readonly prioritise = false;
  public readonly kind = 'media';
  public readonly localisedKind: string;

  constructor(intl: IntlShape) {
    this.localisedKind = intl.formatMessage({
      description: 'Name for media block used in broadcast editor',
      defaultMessage: 'Media',
    });
  }

  public definesBlock(block: BroadcastBlock): block is VideoBlock {
    return block.kind === 'media';
  }

  public initialiseBlock(): VideoBlock {
    return {
      localId: UniqueIdGenerator.generate(),
      kind: 'media',
      attributes: {
        uploadId: '',
      },
    };
  }

  public prepareBlockForSubmission(block: VideoBlock): VideoBlock {
    return {
      ...block,
      attributes: {
        uploadId: block.attributes.uploadId,
        ...block.attributes.videoThumbnailKey ? { videoThumbnailKey: block.attributes.videoThumbnailKey } : {},
      },
    };
  }

  public IconComponent = VideoBlockIcon;
  public ExpandedPreviewComponent = VideoBlockPreview;
  public InlineFormComponent = VideoBlockInlineForm;
  public FormComponent = VideoBlockForm;
  public errorDefinitions: BlockErrorDefinitions<VideoBlock> = {
    uploadId: [
      new BlankValidationErrorDefinition(intl => intl.formatMessage({
        description: 'Error message when broadcast video block upload input is left blank.',
        defaultMessage: 'No video selected.',
      })),
    ],
    videoThumbnailKey: [],
  };
  public preview = {
    slot: PreviewSlot.MEDIA,
    Component: VideoBlockPreview,
  };
}
