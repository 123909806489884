import React, { FunctionComponent } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';

import { DraftSingleContentCard, EditorContent } from '../../Model';
import { ValidationTree } from '../../../Common/Model';
import { StyledContentNav, StyledDroppable, StyledNavDivider } from './style';
import { ContentNavItem, DraggableContentNavItem } from '..';

interface Props {
  contents: EditorContent[];
  validation?: ValidationTree<Record<number, DraftSingleContentCard>>;
  onContentAtIndexClicked: (index: number) => void;
  onIndexMoved: (source: number, destination: number) => void;
  activeCardIndex?: number;
  lockFirstIndex: boolean;
}

export const ContentNav: FunctionComponent<Props> = ({
  contents,
  validation,
  onContentAtIndexClicked,
  onIndexMoved,
  activeCardIndex,
  lockFirstIndex,
}) => {
  const whenDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    onIndexMoved(result.source.index, result.destination.index);
  };

  return (
    <DragDropContext onDragEnd={ whenDragEnd }>
      <StyledContentNav>
        { lockFirstIndex && (
          <>
            <ContentNavItem
              content={ contents[0] }
              index={ 0 }
              active={ activeCardIndex === 0 }
              onContentAtIndexClicked={ () => onContentAtIndexClicked(0) }
              validation={ validation?.children[0] }
            />
            <StyledNavDivider/>
          </>
        ) }
        <Droppable droppableId="content">
          { droppable => (
            <StyledDroppable
              ref={ droppable.innerRef }
              { ...droppable.droppableProps }
            >
              { (lockFirstIndex ? contents.slice(1) : contents).map((editorContent, index) => {
                const offsetIndex = index + (lockFirstIndex ? 1 : 0);
                return (
                  <DraggableContentNavItem
                    id={ editorContent.id }
                    key={ editorContent.id }
                    active={ activeCardIndex === offsetIndex }
                    content={ editorContent }
                    index={ offsetIndex }
                    onContentAtIndexClicked={ onContentAtIndexClicked }
                    validation={ validation?.children[offsetIndex] }
                  />
                )
              }) }
              { droppable.placeholder }
            </StyledDroppable>
          ) }
        </Droppable>
      </StyledContentNav>
    </DragDropContext>
  );
};
