import { ChangeEventHandler, FC, useCallback, useMemo } from 'react';
import { MenuItem } from '@mui/material';
import { formatISO, parseISO, startOfDay } from 'date-fns';
import { FormattedMessage } from 'react-intl';

import { DateTimeFormatter } from '../../../../../src/react/Utility/DateTimeFormatter';
import { Flex } from '../../../../../src/react/Common/Component/Flex/Flex';
import { StyledSelect } from './style';
import { NoMarginTextField } from '../../../../../src/react/Common/Component/NoMarginTextField/NoMarginTextField';
import { DateOffset } from '../../../Model/DateOffset';
import { DateOffsetParser } from '../../../../Core/Utility/DateOffsetParser';

export type DateRangeOption = {
  label: string;
  value: DateOffset | null;
};

export type DateOffsetInputProps = {
  options: DateRangeOption[];
  value: DateOffset | null;
  onChange: (value: DateOffset | null) => void;
  customOption?: {
    enabled: boolean;
    optionLabel?: string;
    inputLabel?: string;
    initialValue?: DateOffset;
    min?: string;
    max?: string;
  };
};

export const DateOffsetInput: FC<DateOffsetInputProps> = ({
  options,
  value,
  onChange,
  customOption,
}) => {
  const dateString = useMemo<string>(
    () => value && value.type === 'fixed'
      ? value.date
      : customOption?.initialValue
        ? DateOffsetParser.toIsoString(customOption.initialValue)
        : formatISO(startOfDay(new Date())),
    [customOption?.initialValue, value],
  );
  const valueString = useMemo<string>(() => {
    const offsetString = value ? DateOffsetParser.toString(value) : '';
    const valueIsOption = !!options.find(option => option.value
      ? DateOffsetParser.toString(option.value) === offsetString
      : offsetString === ''
    );

    return valueIsOption
      ? offsetString
      : customOption?.enabled
        ? 'custom'
        : '';
  }, [customOption?.enabled, options, value]);

  const whenChanged: ChangeEventHandler<{ name?: string | unknown; value: unknown }> = useCallback(event => {
    if (typeof event.target.value !== 'string') {
      return;
    }

    onChange(
      event.target.value === 'custom'
        ? {
          type: 'fixed',
          date: dateString,
        }
        : event.target.value
          ? DateOffsetParser.fromString(event.target.value)
          : null
    );
  }, [dateString, onChange]);

  const whenCustomDateChanged: ChangeEventHandler<HTMLInputElement> = useCallback(event => (
    onChange({
      type: 'fixed',
      date: formatISO(parseISO(event.currentTarget.value)),
    })
  ), [onChange]);

  return (
    <Flex gap={ 2 }>
      <StyledSelect
        value={ valueString }
        onChange={ whenChanged }
        variant="outlined"
        margin="dense"
        displayEmpty
      >
        { options.map(option => {
          const value = option.value && DateOffsetParser.toString(option.value);
          return (
            <MenuItem
              key={ value }
              value={ value || '' }
            >
              { option.label }
            </MenuItem>
          );
        }) }
        { customOption?.enabled && (
          <MenuItem
            key="custom"
            value="custom"
          >
            { customOption.optionLabel || (
              <FormattedMessage
                description="Label for custom option in duration input."
                defaultMessage="Custom"
              />
            ) }
          </MenuItem>
        ) }
      </StyledSelect>
      { value?.type === 'fixed' && (
        <NoMarginTextField
          type="datetime-local"
          label={ customOption?.inputLabel }
          inputProps={ {
            min: customOption?.min,
            max: customOption?.max,
          } }
          value={ DateTimeFormatter.dateTimeInput(parseISO(value.date)) }
          onChange={ whenCustomDateChanged }
          variant="outlined"
        />
      ) }
    </Flex>
  );
};
