import { PreviewComponent } from '../../../Model/BroadcastBlockDefinition';
import { TextBlock } from '../../../Service/Blocks/TextBlockDefinition';
import { SafeRichTextContent } from '../../../../../Common/Component/SafeRichTextContent/SafeRichTextContent';

export const TextBlockExpandedPreview: PreviewComponent<TextBlock> = ({ block }) => {

  return (
    <div
      css={ theme => ({
        'p, h3, ul, li': {
          marginBottom: theme.new.spacing[1],
        },

        'h3': {
          fontSize: '18px',
          fontWeight: 600,
        },

        'p': {
          lineHeight: '1',
          fontSize: '16px',
        },

        'strong': {
          fontWeight: 500,
        },
      }) }
    >
      <SafeRichTextContent
        text={ block.attributes.html }
      />
    </div>
  );
};
