import { ValidationError } from '../../../src/react/Common/Model/ValidationError';
import { Validate } from '../../../src/react/Common/Utility/Validate';
import { Validator } from '../../../src/react/Common/Model/Validator';

export class LastNameValidator {
  public static validate = (lastName: string): ValidationError[] => lastNameValidator.validate(lastName);
}

const lastNameValidator = new Validator<string>([
  lastName => Validate.maxLength(lastName, 191),
]);
