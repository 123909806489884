import React, { FC, MouseEventHandler, useMemo, useState } from 'react';
import { CircularProgress, Menu, MenuItem } from '@mui/material';
import { useIntl } from 'react-intl';
import { StyleBuilder } from 'op-storybook/lib/model/StyleBuilder/StyleBuilder';
import MenuIcon from 'op-storybook/lib/assets/icon/figma/dots-horizontal.svg';

import { ConditionalTooltip, Flex, IconButton, IconButtonProps, PresentationIcon } from '..';
import { PopOverState } from '../../Model';

export type ContextMenuAction = (IconButtonProps & {
  id: string;
});

type Props = {
  id: string;
  actions: ContextMenuAction[];
  IconComponent?: IconButtonProps['IconComponent'];
  iconColor?: IconButtonProps['color'];
};

export const ContextMenu: FC<Props> = ({
  id,
  actions,
  IconComponent = MenuIcon,
  iconColor,
}) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<Element | null>(null);
  const [menuState, setMenuState] = useState<PopOverState>(PopOverState.CLOSED);
  const menuDisabled = actions.every(action => action.disabled);
  const menuBusy = !!actions.find(action => action.busy);
  const intl = useIntl();
  const styles = useMemo(buildStyles, []);

  const whenMenuButtonClicked: MouseEventHandler<HTMLButtonElement> = event => {
    if (menuDisabled) {
      return;
    }

    setMenuState(PopOverState.OPEN);
    setMenuAnchorEl(event.currentTarget);
  };

  return <>
    <div css={ styles.root }>
      <IconButton
        disabled={ menuDisabled }
        busy={ menuBusy }
        showTooltip
        color={ iconColor }
        aria-label="more"
        aria-controls={ id }
        aria-haspopup="true"
        onClick={ whenMenuButtonClicked }
        IconComponent={ IconComponent }
        label={ intl.formatMessage({
          description: 'Label for more button in context menu.',
          defaultMessage: 'More',
        }) }
        size="small"
      />
    </div>
    { menuState !== PopOverState.CLOSED && (
      <Menu
        id={ id }
        open={ menuState === PopOverState.OPEN }
        onClose={ () => setMenuState(PopOverState.WILL_CLOSE) }
        onClick={ () => setMenuState(PopOverState.WILL_CLOSE) }
        TransitionProps={ {
          onExited: () => {
            setMenuAnchorEl(null);
            setMenuState(PopOverState.CLOSED);
          },
        } }
        anchorEl={ menuAnchorEl }
        anchorOrigin={ {
          horizontal: 'center',
          vertical: 'bottom',
        } }
        transformOrigin={ {
          horizontal: 'center',
          vertical: 'top',
        } }
      >
        { actions.map(action => (
          <ConditionalTooltip
            key={ action.id }
            title={ action.disabledTooltip || '' }
            active={ !!(action.disabled || action.showTooltip) }
          >
            <MenuItem
              { ...action }
              disabled={ action.disabled }
              { ...action.onClick ? { onClick: action.onClick as MouseEventHandler<HTMLLIElement> } : {} }
            >
              <Flex gap={ 2 }>
                {
                  action.busy
                    ? (
                      <CircularProgress
                        color="secondary"
                        size={ 24 }
                      />
                    )
                    : (
                      <PresentationIcon
                        IconComponent={ action.IconComponent }
                        size={ 2.5 }
                      />
                    )
                }
                { action.label }
              </Flex>
            </MenuItem>
          </ConditionalTooltip>
        )) }
      </Menu>
    ) }
  </>;
};

const buildStyles: StyleBuilder = () => ({
  root: {
    display: 'inline-block',
  },
});
