import { FunctionComponent, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@mui/material';
import { LoadingCard } from '@ourpeople/shared/Core/Component/Feedback';

import {
  AnimatedNumber,
  Box,
  DateTime,
  LabeledInformation,
  ReportSummary,
} from '../../../Common/Component';
import { SafeRichTextContent } from '../../../Common/Component/SafeRichTextContent/SafeRichTextContent';
import { useFetchInvitationReport } from '../../Hook';
import { InvitationStatusChip, PreviewDialog } from '..';
import { PersonParser } from '../../../Utility';

type Props = {
  invitationId: string;
}

export const InvitationReportSummary: FunctionComponent<Props> = ({ invitationId }) => {
  const intl = useIntl();
  const [fetchInvitationReportResult] = useFetchInvitationReport(invitationId);
  const report = fetchInvitationReportResult?.content;
  const [emailPreviewOpen, setEmailPreviewOpen] = useState<boolean>(false);
  const [smsPreviewOpen, setSmsPreviewOpen] = useState<boolean>(false);
  const emailBody = useMemo(
    () => {
      const pattern = /<body[^>]*>((.|[\n\r])*)<\/body>/im;
      const matches = pattern.exec(report?.email?.body.html || '');
      return (matches || [])[1] || undefined;
    },
    [report],
  );

  return (
    <Box padded={ false }>
      {
        !!report
          ? (
            <ReportSummary>
              <div>
                <LabeledInformation
                  id="recipients"
                  label={ intl.formatMessage({
                    id: 'people.invitationReport.recipients',
                    description: 'Label for recipients figure in invitation report summary',
                    defaultMessage: 'Recipients'
                  }) }
                >
                  <AnimatedNumber
                    targetValue={ report.counts.recipients }
                  />
                </LabeledInformation>
              </div>
              <div>
                <LabeledInformation
                  id="deliveryStatus"
                  label={ intl.formatMessage({
                    id: 'people.invitationReport.deliveryStatus',
                    description: 'Label for delivery status in invitation report summary',
                    defaultMessage: 'Delivery status'
                  }) }
                >
                  <div><InvitationStatusChip status={ report.invitation.status }/></div>
                </LabeledInformation>
                <LabeledInformation
                  id="createdBy"
                  label={ intl.formatMessage({
                    id: 'people.invitationReport.createdBy',
                    description: 'Label for created by in invitation report summary',
                    defaultMessage: 'Created by'
                  }) }
                >
                  { PersonParser.fullName(report.invitation.created.by) }
                </LabeledInformation>
              </div>
              <div>
                <LabeledInformation
                  id="sms"
                  label={ intl.formatMessage({
                    id: 'people.invitationReport.sms',
                    description: 'Label for sms preview in invitation report summary',
                    defaultMessage: 'SMS'
                  }) }
                >
                  {
                    report.sms
                      ? (
                        <Button
                          color="secondary"
                          onClick={ () => setSmsPreviewOpen(true) }
                        >
                          <FormattedMessage
                            id="people.invitationReport.viewSms"
                            description="CTA for view SMS link in invitation report summary"
                            defaultMessage="View"
                          />
                        </Button>
                      )
                      : (
                        <FormattedMessage
                          id="people.invitationReport.smsNotSent"
                          description="Label in invitation report summary when sms invite was not sent"
                          defaultMessage="Not sent"
                        />
                      )
                  }
                </LabeledInformation>
                <LabeledInformation
                  id="email"
                  label={ intl.formatMessage({
                    id: 'people.invitationReport.email',
                    description: 'Label for email preview in invitation report summary',
                    defaultMessage: 'Email'
                  }) }
                >
                  {
                    report.email
                      ? (
                        <Button
                          color="secondary"
                          onClick={ () => setEmailPreviewOpen(true) }
                        >
                          <FormattedMessage
                            id="people.invitationReport.viewEmail"
                            description="CTA for view email link in invitation report summary"
                            defaultMessage="View"
                          />
                        </Button>
                      )
                      : (
                        <FormattedMessage
                          id="people.invitationReport.emailNotSent"
                          description="Label in invitation report summary when email invite was not sent"
                          defaultMessage="Not sent"
                        />
                      )
                  }
                </LabeledInformation>
              </div>
              <div>
                <LabeledInformation
                  id="createdAt"
                  label={ intl.formatMessage({
                    id: 'people.invitationReport.createdAt',
                    description: 'Label for created at date in invitation report summary',
                    defaultMessage: 'Created'
                  }) }
                >
                  <DateTime dateTime={ report.invitation.created.at }/>
                </LabeledInformation>
                {
                  report.invitation.sentAt
                    ? (
                      <LabeledInformation
                        id="sentAt"
                        label={ intl.formatMessage({
                          id: 'people.invitationReport.sentAt',
                          description: 'Label for sent at date in invitation report summary',
                          defaultMessage: 'Sent date'
                        }) }
                      >
                        <DateTime dateTime={report.invitation.sentAt}/>
                      </LabeledInformation>
                    )
                    : (
                      report.invitation.sendAt && (
                        <LabeledInformation
                          id="sendAt"
                          label={ intl.formatMessage({
                            id: 'people.invitationReport.sendAt',
                            description: 'Label for send at date in invitation report summary',
                            defaultMessage: 'Send date'
                          }) }
                        >
                          <DateTime dateTime={report.invitation.sendAt?.dt}/>
                        </LabeledInformation>
                      )
                    )
                }
              </div>
              { report.email && (
                <PreviewDialog
                  open={ emailPreviewOpen }
                  onDismiss={ () => setEmailPreviewOpen(false) }
                >
                  <SafeRichTextContent text={ emailBody || '' }/>
                </PreviewDialog>
              ) }
              { report.sms && (
                <PreviewDialog
                  open={ smsPreviewOpen }
                  onDismiss={ () => setSmsPreviewOpen(false) }
                >
                  { report.sms.message }
                </PreviewDialog>
              ) }
            </ReportSummary>
          )
          : <LoadingCard/>
      }
    </Box>
  )
}
