import { MappedSettingTypes } from '../../Types';

type Props = {
  value: MappedSettingTypes['url']['value'],
};

export const UrlSettingValue = ({
  value,
}: Props): JSX.Element => {
  return (
    <>
      {
        value !== null
          && (
            <a
              href={value}
              rel="noreferrer"
              target="_blank"
            >
              {value}
            </a>
        )
      }
    </>
  );
};
