import { UploadState } from '../Context';
import { FileUploadError } from '../Component';
import { Upload } from '../../Types';

interface FailedUploadState extends UploadState {
  error: FileUploadError;
}

interface CompletedUploadState extends UploadState {
  upload: Upload;
}

interface InProgressUploadState extends UploadState {
  upload: never;
  error: never;
}

interface ScanningUploadState extends UploadState {
  upload: never;
  error: never;
  progress: 1;
}

interface ProcessingUploadState extends UploadState {
  upload: never;
  error: never;
  progress: 0;
}

export enum UploadStatus {
  PROCESSING,
  IN_PROGRESS,
  SCANNING,
  COMPLETE,
  FAILED,
}

export class UploadStateHelper {
  public static uploadIsComplete = (uploadState: UploadState): uploadState is CompletedUploadState => (
    !!uploadState.upload
  );

  public static uploadIsFailed = (uploadState: UploadState): uploadState is FailedUploadState => (
    !!uploadState.error
  );

  public static uploadIsScanning = (uploadState: UploadState): uploadState is ScanningUploadState => (
    UploadStateHelper.uploadIsOngoing(uploadState) && uploadState.progress === 1
  );

  public static uploadIsProcessing = (uploadState: UploadState): uploadState is ScanningUploadState => (
    UploadStateHelper.uploadIsOngoing(uploadState) && uploadState.progress === 0
  );

  public static uploadIsInProgress = (uploadState: UploadState): uploadState is InProgressUploadState => (
    UploadStateHelper.uploadIsOngoing(uploadState) && uploadState.progress !== 1 && uploadState.progress !== 0
  );

  public static determineUploadStatus = (uploadState: UploadState): UploadStatus => (
    UploadStateHelper.uploadIsComplete(uploadState)
      ? UploadStatus.COMPLETE
      : UploadStateHelper.uploadIsFailed(uploadState)
        ? UploadStatus.FAILED
        : UploadStateHelper.uploadIsScanning(uploadState)
          ? UploadStatus.SCANNING
          : UploadStateHelper.uploadIsProcessing(uploadState)
            ? UploadStatus.PROCESSING
            : UploadStatus.IN_PROGRESS
  );

  public static uploadIsOngoing = (
    uploadState: UploadState
  ): uploadState is InProgressUploadState | ScanningUploadState | ProcessingUploadState => (
    !(UploadStateHelper.uploadIsComplete(uploadState) || UploadStateHelper.uploadIsFailed(uploadState))
  );
}
