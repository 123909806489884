import { ComponentProps, FC, ReactNode, useCallback, useRef } from 'react';
import * as RadForm from '@radix-ui/react-form';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';

import { StyledFieldContainer, StyledFieldInner, StyledFormControl } from './style';
import { TextFieldLeadingText } from '../TextFieldLeadingText/TextFieldLeadingText';
import { TextFieldTrailingButton } from '../TextFieldTrailingButton/TextFieldTrailingButton';
import { Typography } from '../../Content/Typography/Typography';

type Props = {
  name: string;
  label?: string;
  value: string;
  onChange?: (value: string) => void;
  leadingText?: string;
  trailingButton?: {
    content: ReactNode;
    onClick: () => void;
  };
  errorMessages?: string[];
  tip?: string;
  disabled?: boolean;
  placeholder?: string;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  inputProps?: ComponentProps<'input'>;
};

export const TextField: FC<Props> = ({
  name,
  label,
  value,
  leadingText,
  trailingButton,
  errorMessages = [],
  tip,
  disabled,
  onChange,
  placeholder,
  startAdornment,
  endAdornment,
  inputProps,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const focusInput = useCallback(() => (
    inputRef.current?.focus()
  ), []);

  return (
    <RadForm.Field
      name={ name }
    >
      <Stack
        direction="column"
        gap={ 1 }
      >
        { label && (
          <RadForm.Label>
            <Typography
              size="sm"
              weight="medium"
              color="grey.900.main"
            >
              { label }
            </Typography>
          </RadForm.Label>
        ) }
        <StyledFieldContainer>
          { leadingText && (
            <TextFieldLeadingText
              onClick={ focusInput }
              disabled={ disabled }
            >
              { leadingText }
            </TextFieldLeadingText>
          ) }
          <StyledFieldInner
            error={ !!errorMessages.length }
            onClick={ focusInput }
            roundStart={ !leadingText }
            roundEnd={ !trailingButton }
            disabled={ !!disabled }
            padStart={ !!startAdornment }
            padEnd={ !!endAdornment }
          >
            { startAdornment }
            <StyledFormControl
              { ...inputProps }
              value={ value || '' }
              onChange={ event => onChange && onChange(event.currentTarget.value) }
              ref={ inputRef }
              disabled={ disabled }
              placeholder={ placeholder }
            />
            { endAdornment }
          </StyledFieldInner>
          { trailingButton && (
            <TextFieldTrailingButton
              onClick={ trailingButton.onClick }
              type="button"
            >
              { trailingButton.content }
            </TextFieldTrailingButton>
          ) }
        </StyledFieldContainer>
        { tip && !errorMessages.length && (
          <RadForm.Message>
            <Typography
              size="sm"
              weight="regular"
              color="grey.600.main"
            >
              { tip }
            </Typography>
          </RadForm.Message>
        ) }
        { errorMessages.map((errorMessage, index) => (
          <Typography
            key={ index }
            color="error.500.main"
            size="sm"
            weight="regular"
          >
            { errorMessage }
          </Typography>
        )) }
      </Stack>
    </RadForm.Field>
  );
};
