import {
  FormAndMultiContentCards,
  MinimalFormWithMultiContentCards,
  MinimalFormWithSingleContentCards,
  SingleContentFormAndCards,
} from '../Model';
import { FormCardTransformer } from './FormCardTransformer';

export class FormTransformer {
  public static consolidateMinimalFormContent = (form: MinimalFormWithMultiContentCards): MinimalFormWithSingleContentCards => ({
    ...form,
    cards: form.cards.map(FormCardTransformer.consolidateCardContent),
  });

  public static consolidateFormAndCardsContent = ({ form, cards }: FormAndMultiContentCards): SingleContentFormAndCards => ({
    form,
    cards: cards.map(FormCardTransformer.consolidateCardContent),
  });
}
