import { FC, ReactNode } from 'react';

import { StyledPadded } from './styles';

type Props = {
  useNewTheme?: boolean;
  children?: ReactNode;
};

export const Padded: FC<Props> = ({
  useNewTheme = false,
  children,
}) => {
  return (
    <StyledPadded useNewTheme={ useNewTheme }>
      {children}
    </StyledPadded>
  );
};
