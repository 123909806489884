import { parseISO } from 'date-fns';
import { createContext, FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { LoadingCard } from '@ourpeople/shared/Core/Component/Feedback';
import { Heading } from '@ourpeople/shared/Core/Component/Content';

import { Refresh, useQueryAsState } from '../../../../Hooks';
import { useFetchBroadcastReport } from '../Hooks/useFetchBroadcastReport';
import {
  BroadcastReportSummary,
  CardReport,
  useFetchBroadcastReportSummary
} from '../Hooks/useFetchBroadcastReportSummary';
import { BroadcastSummary } from './BroadcastSummary';
import { ContentSummary } from './ContentSummary';
import { DeliverySummary } from './DeliverySummary';
import { Padded, PaddedLoading } from '../../../../Components';
import {
  Box,
  CsvExportButtonWithDefaultErrorHandling,
  DateTime,
  Flex,
  NotFoundError,
  PageHeader,
  VerticallySpaced
} from '../../../../Common/Component';
import { ContentSummaryTable } from './Tables/ContentSummaryTable';
import { EventSummaryTable } from './Tables/EventSummaryTable';
import { ContentReader } from '../../../../Readers';
import {
  StyledContentTableContainer,
  StyledDeliveryPicker,
  StyledDeliverySelectionLabel,
  StyledDeliverySelectionRow,
  StyledProgressiveSelect
} from './styles';
import { ChannelReport, RecalledLabel } from '../../../../Broadcasts/Component';
import { FetchDeliveriesParams, useFetchDeliveries } from '../../../../Broadcasts/Hook';
import { DateTimeFormatter, PersonParser } from '../../../../Utility';
import { Delivery, ListedDelivery } from '../../../../Models';

interface Params {
  broadcastId: string;
}

const cardContainsEvent = (card: CardReport): boolean => {
  return ['cover', 'training', 'meeting'].indexOf(ContentReader.contentTypeFromString(card.content.type)) !== -1;
};

export const RefreshDeliverySummaryContext = createContext<Refresh | null>(null);

export const BroadcastReportPage: FunctionComponent = () => {
  const intl = useIntl();
  const { broadcastId } = useParams<Params>();
  const history = useHistory();
  const [fetchBroadcastReportResult] = useFetchBroadcastReport(broadcastId);
  const broadcastReport = useMemo(
    () => fetchBroadcastReportResult?.content,
    [fetchBroadcastReportResult?.content],
  );
  const [query, setQuery] = useQueryAsState();
  const initialSearch = query.initialSearch;
  const deliveryId: string | undefined = query.delivery;
  const deliverySelection = useMemo(() => deliveryId ? [deliveryId] : [], [deliveryId]);
  const [pageNum, setPageNum] = useState<number>(1);
  const fetchDeliveriesParams = useMemo<FetchDeliveriesParams>(() => ({
    pageNum,
    statuses: 'delivered,recalled',
    sort: 'coalesced_delivered_at_desc',
    broadcastIds: broadcastId,
  }), [broadcastId, pageNum]);
  const [collatedDeliveries, setCollatedDeliveries] = useState<ListedDelivery[]>([]);
  const [fetchDeliveriesResult] = useFetchDeliveries(fetchDeliveriesParams);
  const allDeliveriesLoaded = pageNum === fetchDeliveriesResult?.content?.pagination.pageCount;
  const [fetchBroadcastReportSummaryResult, , reloadBroadcastReportSummary] = useFetchBroadcastReportSummary(
    broadcastId,
    deliveryId || '',
  );
  const [broadcastReportSummary, setBroadcastReportSummary] = useState<BroadcastReportSummary>();
  const collatedDeliveriesWithSelection = useMemo<Delivery[]>(() => {
    if (!broadcastReportSummary) {
      return collatedDeliveries;
    }

    if (collatedDeliveries.find(delivery => delivery.id === broadcastReportSummary.delivery.id)) {
      return collatedDeliveries;
    }

    return [broadcastReportSummary.delivery].concat(collatedDeliveries);
  }, [broadcastReportSummary, collatedDeliveries]);
  const broadcastContainsEvent = useMemo(() => {
    return (broadcastReportSummary?.cards || []).find(cardContainsEvent)
  }, [broadcastReportSummary?.cards]);
  const broadcastError = useMemo(() => (
    fetchBroadcastReportSummaryResult?.isError() || fetchBroadcastReportResult?.isError()
  ), [fetchBroadcastReportSummaryResult, fetchBroadcastReportResult]);
  const broadcastLoading = useMemo(() => (
    !fetchBroadcastReportResult || !fetchBroadcastReportSummaryResult
  ), [fetchBroadcastReportResult, fetchBroadcastReportSummaryResult]);
  const showLoading = useMemo(() => (
    !(fetchBroadcastReportResult && fetchDeliveriesResult && fetchBroadcastReportSummaryResult)
  ), [fetchBroadcastReportResult, fetchBroadcastReportSummaryResult, fetchDeliveriesResult]);

  useEffect(() => {
    if (!fetchDeliveriesResult?.content) {
      return;
    }
    const newDeliveries = fetchDeliveriesResult?.content.deliveries;
    setCollatedDeliveries(collatedDeliveries => collatedDeliveries.concat(newDeliveries));
  }, [fetchDeliveriesResult?.content]);

  useEffect(() => {
    if (!fetchDeliveriesResult?.content?.deliveries.length || deliveryId) {
      return;
    }

    const redirectDeliveryId = fetchDeliveriesResult.content.deliveries[0].id;
    setQuery({ delivery: redirectDeliveryId });
  }, [broadcastId, deliveryId, fetchDeliveriesResult?.content?.deliveries, history, setQuery]);

  useEffect(() => {
    if (!fetchBroadcastReportSummaryResult?.content) {
      return;
    }

    setBroadcastReportSummary(fetchBroadcastReportSummaryResult.content);
  }, [fetchBroadcastReportSummaryResult?.content]);

  return (
    <Padded>
      <PageHeader
        items={ [
          {
            link: '/broadcasts',
            title: intl.formatMessage({
              id: 'section.broadcasts',
              description: 'Heading label for Broadcasts',
              defaultMessage: 'Broadcasts',
            })
          },
          {
            title: intl.formatMessage({
              id: 'sections.broadcasts.report',
              description: 'Title displayed over the broadcast report page',
              defaultMessage: 'Report'
            }),
          },
          ...(
            broadcastReportSummary
              ? [
                {
                  title: broadcastReportSummary.broadcast.name,
                  component: (
                    <Flex gap={ 2 }>
                      { broadcastReportSummary.delivery.status === 'recalled' && <RecalledLabel/> }
                      { broadcastReportSummary.broadcast.name }
                    </Flex>
                  ),
                }
              ]
              : []
          )
        ] }
      />
      { !broadcastLoading && (
        broadcastError
          ? (
            <Box>
              <NotFoundError>
                <FormattedMessage
                  id="broadcast.report.notFound"
                  defaultMessage="Sorry, the broadcast you requested could not be found!"
                />
              </NotFoundError>
            </Box>
          )
          : (
            <>
              {
                broadcastReport && broadcastReport.broadcast.recurrence && !!collatedDeliveries.length && (
                  <>
                    <BroadcastSummary
                      report={ broadcastReport }
                      deliveries={ collatedDeliveries }
                    />
                    <StyledDeliverySelectionRow>
                      <StyledDeliveryPicker>
                        <StyledDeliverySelectionLabel>
                          <FormattedMessage
                            id="broadcast.report.showDelivery"
                            defaultMessage="Show delivery"
                          />
                        </StyledDeliverySelectionLabel>
                        <StyledProgressiveSelect
                          options={ collatedDeliveriesWithSelection }
                          renderOption={ delivery => (
                            <Flex gap={ 1 }>
                              { DateTimeFormatter.standard(parseISO(delivery.deliveredAt || '')) }
                              { delivery.status === 'recalled' && <RecalledLabel/> }
                            </Flex>
                          ) }
                          getOptionId={ delivery => delivery.id }
                          selection={ deliverySelection }
                          canLoadMore={ !allDeliveriesLoaded }
                          onLoadMoreTriggered={ () => setPageNum(pageNum => pageNum + 1) }
                          onSelect={ deliveryIds => setQuery({ delivery: deliveryIds[0] }) }
                        />
                      </StyledDeliveryPicker>
                      { deliveryId && (
                        <CsvExportButtonWithDefaultErrorHandling
                          cta={ intl.formatMessage({
                            id: 'broadcast.responses.exportCta',
                            defaultMessage: 'Export responses',
                          }) }
                          endpoint={ `/broadcasts/${ broadcastId }/export-responses?deliveryId=${ deliveryId }` }
                          fileNamePrefix={ intl.formatMessage({
                            id: 'broadcast.responses.csvFileNamePrefix',
                            defaultMessage: 'broadcast_responses',
                          }) }
                        />
                      ) }
                    </StyledDeliverySelectionRow>
                  </>
                )
              }
              { !deliveryId && !showLoading && (
                <Box>
                  <NotFoundError>
                    <FormattedMessage
                      description="Error message on broadcast report when there are no deliveries"
                      defaultMessage="Sorry, the broadcast you requested has no deliveries!"
                    />
                  </NotFoundError>
                </Box>
              ) }
              {
                deliveryId && broadcastReportSummary && (
                  <RefreshDeliverySummaryContext.Provider value={ reloadBroadcastReportSummary }>
                    <DeliverySummary
                      summary={ broadcastReportSummary }
                      deliveryId={ deliveryId }
                      showEngagement={ !broadcastContainsEvent }
                      onChange={ setBroadcastReportSummary }
                      initialSearch={ initialSearch }
                    />
                    <ChannelReport
                      notificationCounts={ broadcastReportSummary.counts.broadcastReceivedNotifications }
                      respondedCounts={ broadcastReportSummary.counts.responded }
                    />
                    { broadcastReportSummary.delivery.recall && (
                      <Box>
                        <VerticallySpaced gap={ 2 }>
                          <Flex gap={ 2 }>
                            <RecalledLabel/>
                            <FormattedMessage
                              defaultMessage="{ date }, { name }"
                              description="Recall date and recaller name in broadcast report"
                              values={ {
                                date: <DateTime dateTime={ broadcastReportSummary.delivery.recall.interaction.at }/>,
                                name: PersonParser.fullName(broadcastReportSummary.delivery.recall.interaction.by),
                              } }
                            />
                          </Flex>
                          { broadcastReportSummary.delivery.recall.reason }
                        </VerticallySpaced>
                      </Box>
                    ) }
                    <Box>
                      <Heading type="h1">
                        <FormattedMessage
                          id="broadcast.report.broadcastContentSummary"
                          defaultMessage="Broadcast content summary"
                        />
                      </Heading>
                      <StyledContentTableContainer>
                        {
                          broadcastContainsEvent
                            ? (
                              <EventSummaryTable
                                summary={ broadcastReportSummary }
                              />
                            )
                            : (
                              <ContentSummaryTable
                                summary={ broadcastReportSummary }
                              />
                            )
                        }
                      </StyledContentTableContainer>
                    </Box>
                    {
                      broadcastReportSummary.cards.filter(
                        card => ContentReader.contentTypeFromString(card.content.type) !== 'stack'
                      )
                        .map((card) => (
                          !!card.content.id && (
                            <ContentSummary
                              key={ `content${ card.content.id }` }
                              broadcastId={ broadcastReportSummary.broadcast.id }
                              deliveryId={ deliveryId }
                              pendingCount={ broadcastReportSummary.counts.recipients.pending }
                              card={ card }
                              initialSearch={ initialSearch }
                            />
                          )
                        ))
                    }
                    <CsvExportButtonWithDefaultErrorHandling
                      cta={ intl.formatMessage({
                        id: 'broadcast.responses.exportCta',
                        defaultMessage: 'Export responses',
                      }) }
                      endpoint={ `/broadcasts/${ broadcastId }/export-responses?deliveryId=${ deliveryId }` }
                      fileNamePrefix={ intl.formatMessage({
                        id: 'broadcast.responses.csvFileNamePrefix',
                        defaultMessage: 'broadcast_responses',
                      }) }
                    />
                  </RefreshDeliverySummaryContext.Provider>
                )
              }
            </>
          )
      ) }
      { showLoading && (
        <PaddedLoading>
          <LoadingCard/>
        </PaddedLoading>
      ) }
    </Padded>
  );
};
