import { FunctionComponent, useState } from 'react';

import { ContentPreviewProps } from '../../../../Content/Model';
import { DateRequestEditorContent } from '../../../Service';
import { DateRequest } from '..';

export const DateRequestPreview: FunctionComponent<ContentPreviewProps<DateRequestEditorContent>> = ({
  editorContent,
}) => {
  const [value, setValue] = useState<string>('');

  const whenChanged = (date: string | undefined) => {
    setValue(date || '');
  };

  return (
    <DateRequest
      value={ value }
      onChange={ whenChanged }
      content={ editorContent.card.content }
    />
  );
};
