import { FC, ReactNode } from 'react';
import { PopperProps } from '@mui/material';
import { ModularCard } from 'op-storybook/lib/components/ModularCard/ModularCard';

import { StyledPopper } from './style';

type Props = PopperProps & {
  headerContent?: ReactNode;
  footerContent?: ReactNode;
}

export const Menu: FC<Props> = ({
  headerContent,
  footerContent,
  children,
  ...popperProps
}) => (
  <StyledPopper
    { ...popperProps }
  >
    <ModularCard
      headerContent={ headerContent }
      footerContent={ footerContent }
    >
      { children }
    </ModularCard>
  </StyledPopper>
);
