import { ChangeEvent, ChangeEventHandler, FunctionComponent, useCallback, useContext, useState } from 'react';
import { Button, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';

import { ApiContext } from '../../../Contexts';
import { useInputChangeEventHandler } from '../../../Common/Hook';
import { InputAndLabelContainer, LoadingButton, NumberInput, ValidationErrorMessage } from '../../../Common/Component';
import { StyledTransactionDialog } from './style';
import { CharacterCount } from '../../../Content/Component';
import { Switch } from '../../../Components';
import { Api } from '../../../Services';
import { constrain } from '../../../Common/Utility/constrain';
import { useContextOrThrow } from '../../../Core/Hook';
import { ToastContext } from '../../../Core/Context';

type CreditRequestData = {
  credits: number;
  note?: string;
}

type Props = {
  open: boolean;
  onClosed: () => void;
  onSaved: () => void;
}

export const TransactionDialog: FunctionComponent<Props> = ({ open, onSaved, onClosed }) => {
  const intl = useIntl();
  const api = useContext(ApiContext);
  const [saving, setSaving] = useState<boolean>(false);
  const [creditAmount, setCreditAmount] = useState<string>('1');
  const [note, setNote] = useState<string>('');
  const [refund, setRefund] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const { addSuccessToast } = useContextOrThrow(ToastContext);
  const whenNoteChanged = useInputChangeEventHandler(setNote);

  const whenRefundChanged = (event: ChangeEvent<HTMLInputElement>, checked: boolean): void => {
    setRefund(checked);
  }

  const whenSaveClicked = useCallback(() => {
    if (!api) {
      return;
    }

    if (note.length > NOTE_MAX_CHARS) {
      setError(true);
      return;
    }

    setSaving(true);
    setError(false);

    const requestData: CreditRequestData = {
      credits: +creditAmount,
      ...(
        note
          ? { note }
          : {}
      ),
    };

    (
      refund
        ? refundCredit(api, requestData)
        : purchaseCredit(api, requestData)
    )
      .then(() => {
        addSuccessToast(
          intl.formatMessage({
            description: 'New transaction success message.',
            defaultMessage: 'Transaction successful.',
          })
        );
        onSaved();
        onClosed();
        resetForm();
      })
      .catch(() => {
        setError(true);
        setSaving(false);
      });
  }, [addSuccessToast, intl, api, creditAmount, note, refund, onSaved, onClosed]);

  const resetForm = (): void => {
    setRefund(false);
    setSaving(false);
    setError(false);
    setNote('');
    setCreditAmount('1');
  };

  const whenCreditAmountChanged: ChangeEventHandler<HTMLInputElement> = (event): void => {
    setCreditAmount(Math.round(constrain(+event.currentTarget.value, MIN_CREDIT, MAX_CREDIT)).toString());
  }

  return (
    <StyledTransactionDialog
      open={ open }
      onClose={ onClosed }
      TransitionProps={ {
        onExited: resetForm,
      } }
    >
      <DialogTitle>
        <FormattedMessage
          id="credit.newTransaction.header"
          description="New transaction dialog header"
          defaultMessage="New transaction"
        />
      </DialogTitle>
      <DialogContent>
        <form>
          <InputAndLabelContainer htmlFor="credit-amount">
            <FormattedMessage
              id="credit.newTransaction.creditAmount.label"
              description="Credit amount input label"
              defaultMessage="Credit amount"
            />
            <NumberInput
              id="credit-amount"
              variant="outlined"
              margin="dense"
              inputProps={ {
                min: MIN_CREDIT,
                max: MAX_CREDIT,
                step: CREDIT_STEP,
              } }
              value={ creditAmount }
              error={ error }
              onChange={ whenCreditAmountChanged }
            />
          </InputAndLabelContainer>
          <InputAndLabelContainer htmlFor="note">
            <FormattedMessage
              id="credit.newTransaction.note.label"
              description="Transaction note input label"
              defaultMessage="Note"
            />
            <TextField
              id="note"
              variant="outlined"
              margin="dense"
              value={ note }
              error={ error }
              onChange={ whenNoteChanged }
            />
            <CharacterCount
              maximum={ NOTE_MAX_CHARS }
              current={ note.length }
            />
          </InputAndLabelContainer>
          <InputAndLabelContainer htmlFor="refund">
            <FormattedMessage
              id="credit.newTransaction.refund.label"
              description="Transaction refund state input label"
              defaultMessage="Refund"
            />
            <Switch
              id="refund"
              checked={ refund }
              onChange={ whenRefundChanged }
            />
          </InputAndLabelContainer>
        </form>

        {
          error && (
            <ValidationErrorMessage>
              <FormattedMessage
                id="credit.newTransaction.error.unknown"
                description="Generic error message when saving a transaction"
                defaultMessage="Could not save transaction."
              />
            </ValidationErrorMessage>
          )
        }
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={ onClosed }
        >
          <FormattedMessage
            id="credit.newTransaction.cancel"
            description="Create transaction dialog cancel button cta"
            defaultMessage="Cancel"
          />
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          disableElevation
          busy={ saving }
          onClick={ whenSaveClicked }
        >
          <FormattedMessage
            id="credit.newTransaction.save"
            description="Create transaction dialog save button cta"
            defaultMessage="Save"
          />
        </LoadingButton>
      </DialogActions>
    </StyledTransactionDialog>
  );
};

const refundCredit = (api: Api, data: CreditRequestData): Promise<unknown> => api.post('/sms/refund-credits', data);
const purchaseCredit = (api: Api, data: CreditRequestData): Promise<unknown> => api.post('/sms/purchase-credits', data);
const NOTE_MAX_CHARS = 250;
const MAX_CREDIT = 500000;
const MIN_CREDIT = 1;
const CREDIT_STEP = 1;
