import { IntlShape } from 'react-intl';

import { SingleContentCard, EditorContent } from '../../../../Content/Model';
import CommentIcon from '../../../../Assets/img/icons/monochrome/content/cover.svg';
import { CoverEditor, CoverPreview } from '../../../Component';
import { CoverValidator } from '../../Validators';
import { UniqueIdGenerator } from '../../../../Common/Utility';
import { CardTransformer } from '../../../../Content/Utility';
import { Tag } from '../../../../Tags/Model';
import { BroadcastContentDefinition, DraftEvent, Event, PersonalStatus } from '../../../Model';
import { EventCreator, EventTransformer } from '../../../Utility';
import { AcceptResponse, DeclineResponse } from '../../../../Inbox/Model';
import { LiveCoverContent } from '../../../../Inbox/Component';
import { EventParser } from '../../../Utility/EventParser';
import { EnvironmentSettings } from '../../../../Models';

export type AssignedStatus = {
  status: 'assigned';
  metadata: {
    assignedToSelf: boolean;
  };
};

export type CoverStatus = AssignedStatus | PersonalStatus;

export type Cover = Event<'shiftcontent'> & {
  comment: string;
  personalStatus?: CoverStatus;
};

export type DraftCover = DraftEvent<'cover'> & {
  comment?: string;
};

export type CoverEditorContent = EditorContent<DraftCover> & {
  location: Tag<'team'> | null;
};

export class CoverDefinition implements BroadcastContentDefinition<Cover, CoverEditorContent, AcceptResponse | DeclineResponse> {
  public readonly id = 'cover';
  public readonly availableToCreate = true;
  public readonly new = false;
  public readonly richText = false;
  public readonly contentTypes = ['shiftcontent', 'covercontent'];
  public readonly categoryId = 'event';
  public readonly IconComponent = CommentIcon;
  public readonly EditorComponent = CoverEditor;
  public readonly PreviewComponent = CoverPreview;
  public readonly LiveComponent = LiveCoverContent;
  public readonly SubmittedComponent = (): null => null;
  public validate = CoverValidator.validate;

  public definesContentType = (type: string): boolean => (
    this.contentTypes.concat('cover').includes(type)
  );

  public getContentTitle = (intl: IntlShape, content: Cover): string => EventParser.title(content);

  public getLocalisedType = (intl: IntlShape): string => (
    intl.formatMessage({
      id: 'broadcasts.content.cover.type',
      description: 'Localised name for cover content type.',
      defaultMessage: 'Cover',
    })
  );

  public getDraftContentTitle = (intl: IntlShape, draftContent: DraftCover): string => EventParser.title(draftContent);

  public hasRequiredFeatureFlags = (environmentSettings: EnvironmentSettings): boolean => (
    !!environmentSettings.eventsEnabled
  );

  public createEditorContent = (): CoverEditorContent => ({
    id: UniqueIdGenerator.generate(),
    card: {
      content: {
        ...EventCreator.createEventWithType('cover'),
        comment: '',
      },
      mandatory: false,
      image: null,
    },
    location: null,
  });

  public transformCard = (card: SingleContentCard<Cover>): CoverEditorContent => ({
    id: card.id,
    card: CardTransformer.transformCard(card, {
      ...EventTransformer.toDraft(card.content),
      type: 'cover',
      comment: card.content.comment,
    }),
    location: null,
  });
}
