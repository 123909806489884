import { FC } from 'react';
import { Redirect } from 'react-router-dom';
import { UsernameParser } from 'op-storybook/lib/utility/UsernameParser/UsernameParser';
import { AuthPaneLayout } from 'op-storybook/lib/components/AuthPaneLayout/AuthPaneLayout';
import { LoadingCard } from '@ourpeople/shared/Core/Component/Feedback';

import { AnonymousSettings } from '../../Hook/useFetchAnonymousSettings';
import { IdentifiedUserLoginForm } from '../../../Login/Component/IdentifiedUserLoginForm/IdentifiedUserLoginForm';
import { useContextOrThrow } from '../../../Core/Hook';
import { IdentityContext } from '../../../Core/Provider/IdentityProvider/IdentityProvider';
import { RequestState } from '../../../Models';
import {
  UnidentifiedUserLoginForm
} from '../../../Login/Component/UnidentifiedUserLoginForm/UnidentifiedUserLoginForm';
import { Fullscreen } from '../../../Components';
import { IdentityReader } from '../../../Core/Utility/IdentityReader/IdentityReader';

type Props = {
  anonymousSettings: AnonymousSettings;
  loginTargetUrl: string;
};

export const LoginPage: FC<Props> = ({
  anonymousSettings,
  loginTargetUrl,
}) => {
  const { identityRequest } = useContextOrThrow(IdentityContext);

  return (
    <AuthPaneLayout>
      {
        identityRequest.state === RequestState.COMPLETE && IdentityReader.identityHasIdentityAuthentication(identityRequest.result) && identityRequest.result.authentication.method.name === 'USERNAME'
          ? identityRequest.result.person.hasPassword
            ? (
              <IdentifiedUserLoginForm
                anonymousSettings={ anonymousSettings }
                identity={ identityRequest.result }
              />
            )
            : (
              <Redirect
                to={ {
                  pathname: '/login/otp',
                  state: {
                    username: {
                      type: UsernameParser.usernameValueIsEmailAddress(identityRequest.result.authentication.method.username) ? 'email' : 'phone',
                      value: identityRequest.result.authentication.method.username,
                    },
                  },
                } }
              />
            )
          : identityRequest.state === RequestState.PENDING
            ? <Fullscreen><LoadingCard/></Fullscreen>
            : (
              <UnidentifiedUserLoginForm
                anonymousSettings={ anonymousSettings }
                loginTargetUrl={ loginTargetUrl }
              />
            )
      }
    </AuthPaneLayout>
  );
};
