import { FC, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { DraftRatingRequest, RatingRequest as RatingRequestModel } from '../../../Model/ContentTypes';
import { HelpText } from '../HelpText/HelpText';
import { StarRating } from '../../../../Content/Component';
import { FormResponseErrors } from '../../FormResponseErrors/FormResponseErrors';
import { useDescendantErrors } from '../../../../Common/Hook';
import { ValidationTree } from '../../../../Common/Model';
import { DraftRatingResponse } from '../../../Service';
import { useLocalisedResponseErrors } from '../../../Hook';

type Props = {
  content: DraftRatingRequest | RatingRequestModel;
  value: number | null;
};

type ReadOnlyProps = Props & {
  readOnly: true;
  onChange?: never;
  validation?: never;
  displayValidation?: false;
};

type EditableProps = Props & {
  readOnly?: false;
  onChange: (value: number | null) => void;
  validation?: ValidationTree<DraftRatingResponse>;
  displayValidation?: boolean;
};

export const RatingRequest: FC<ReadOnlyProps | EditableProps> = ({
  content,
  value,
  readOnly,
  onChange,
  validation,
  displayValidation,
}) => {
  const errors = useDescendantErrors(validation);
  const localisedErrors = useLocalisedResponseErrors(errors);

  const whenChange = useCallback((value: number | null) => {
    !readOnly && onChange(value);
  }, [onChange, readOnly]);

  return (
    <>
      <HelpText>
        <FormattedMessage
          defaultMessage="Select a rating out of {max}"
          values={ {
            max: content.maxRating,
          } }
        />
      </HelpText>
      <StarRating
        disabled={ readOnly }
        maxRating={ content.maxRating }
        value={ value }
        onValueChanged={ whenChange }
        {
          ...readOnly
            ? {}
            : { onValueCleared: () => whenChange(null) }
        }
        showValues
      />
      { displayValidation && <FormResponseErrors localisedErrors={ localisedErrors }/> }
    </>
  );
};
