import { Dispatch, FunctionComponent, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { AutocompleteSelectionChanged } from '@ourpeople/shared/Core/Component/Input/Autocomplete/Autocomplete';
import { DebouncedSearchInput } from '@ourpeople/shared/Core/Component/Input/DebouncedSearchInput/DebouncedSearchInput';
import { LocalisedString } from 'op-storybook/lib/model/LocalisedString/LocalisedString';

import { TeamAutocomplete } from '../../../Tags/Component';
import { CoverStatusAutocomplete } from '..';
import { FilterContainer, FilterRow, FlexPullRight, Chip, FilterChips, } from '../../../Common/Component';
import { Tag } from '../../../Tags/Model';
import { EventsDateRangePicker } from '../EventsDateRangePicker/EventsDateRangePicker';
import { ArrayHelper, ChipFactory, dateOffsetFromIsValid } from '../../../Common/Utility';
import { DateRangePickerValue } from '../../../Common/Model/DateRangePicker';
import { StyledAutocompleteContainer } from './styles';
import { PersonAutocomplete } from '../../../People/Component';
import { Person } from '../../../Models';
import { PersonParser } from '../../../Utility';

interface Props {
  query: Record<string, string>;
  setQuery: Dispatch<SetStateAction<Record<string, string>>>;
}

export const CoversTableFilters: FunctionComponent<Props> = ({
  query,
  setQuery,
}) => {
  const intl = useIntl();
  const [search, setSearch] = useState<string>(query.search);
  const [chips, setChips] = useState<Chip[]>([]);
  const teamIds = useMemo(() => query.teamIds?.split(',') || [], [query.teamIds]);
  const assigneeIds = query.coverAssignedToIds ? query.coverAssignedToIds.split(',') : [];
  const coverStatusIds = query.statuses ? query.statuses.split(',') : [];
  const dateRangeValue = useMemo<DateRangePickerValue>(() => {
    if (query.start && query.end) {
      return {
        type: 'range',
        start: query.start,
        end: query.end,
      };
    }

    return {
      type: 'offset',
      from: query.from && dateOffsetFromIsValid(query.from) ? query.from : 'start',
      offset: null,
    };
  }, [query]);

  useEffect(() => {
    setQuery((query) => {
      const { search: querySearch, ...existingQuery } = query;
      if (querySearch === search) {
        return query;
      }
      return search
        ? {
          ...existingQuery,
          search: search,
          pageNum: '1',
        }
        : existingQuery
    });
  }, [setQuery, search]);

  const whenTeamsChanged: AutocompleteSelectionChanged<Tag<'team'>> = useCallback((selection) => {
    setQuery(({ teamIds: currentTeamIds, ...query }) => {
      const newTeamIds = selection.options.map(team => team.id).concat(selection.unknownIds).join(',');
      return newTeamIds
        ? {
          ...query,
          teamIds: selection.options.map(team => team.id).concat(selection.unknownIds).join(','),
          pageNum: '1',
        }
        : query;
    });
    setChips(chips => [
      ...chips.filter(chip => chip.type !== 'team'),
      ...selection.options.map(team => ChipFactory.createChipFromTeam(team, intl)),
    ]);
  }, [intl, setQuery]);

  const whenCoverStatusesChanged: AutocompleteSelectionChanged<LocalisedString> = (selection) => {
    setQuery(({ statuses: currentStatuses, ...query }) => {
      const newStatuses = selection.options.map(status => status.id).concat(selection.unknownIds).join(',');
      return newStatuses
        ? {
          ...query,
          statuses: newStatuses,
          pageNum: '1',
        }
        : query;
    });
    setChips(chips => [
      ...chips.filter(chip => chip.type !== 'coverStatus'),
      ...selection.options.map(status => ({
        id: status.id,
        type: 'coverStatus',
        label: intl.formatMessage({
          id: 'chips.coverStatus.label',
          description: 'Label for coverStatus status chip',
          defaultMessage: 'Status: {status}',
        }, {
          status: status.localisation,
        }),
      })),
    ]);
  };

  const whenAssigneeIdsChanged: AutocompleteSelectionChanged<Person> = (selection) => {
    setQuery(({ coverAssignedToIds: currentCoverAssignedToIds, ...query }) => {
      const newAssignees = selection.options.map(assignee => assignee.id).concat(selection.unknownIds).join(',');
      return newAssignees
        ? {
          ...query,
          coverAssignedToIds: newAssignees,
          pageNum: '1',
        }
        : query;
    });
    setChips(chips => [
      ...chips.filter(chip => chip.type !== 'person'),
      ...selection.options.map(person => ({
        id: person.id,
        type: 'person',
        label: intl.formatMessage({
          description: 'Label for person chip used in cover table',
          defaultMessage: 'Assigned to: {name}',
        }, {
          name: PersonParser.fullName(person),
        }),
      })),
    ]);
  };

  const removeTeamWithId = (id: string): void => {
    setQuery(({ teamIds: currentTeamIds, ...query }) => {
      const teamIds: string[] = currentTeamIds.split(',');
      const index = teamIds.indexOf(id);
      const newTeamIds = ArrayHelper.remove(teamIds, index).join(',');
      return newTeamIds
        ? {
          ...query,
          teamIds: newTeamIds,
          pageNum: '1',
        }
        : query;
    });
  };

  const removeStatusWithId = (id: string): void => {
    setQuery(({ statuses: currentStatuses, ...query }) => {
      const statuses: string[] = currentStatuses.split(',');
      const index = statuses.indexOf(id);
      const newStatuses = ArrayHelper.remove(statuses, index).join(',');
      return newStatuses
        ? {
          ...query,
          statuses: newStatuses,
          pageNum: '1',
        }
        : query;
    });
  };

  const whenRemoveChipClicked = (chip: Chip): void => {
    if (chip.type === 'team') {
      removeTeamWithId(chip.id);
    }

    if (chip.type === 'coverStatus') {
      removeStatusWithId(chip.id);
    }

    setChips(chips => {
      const index = chips.findIndex(existingChip => existingChip.id === chip.id);
      return ArrayHelper.remove(chips, index)
    });
  };

  const whenClearFiltersClicked = (): void => {
    setQuery(({ statuses, teamIds, coverAssignedToIds, ...query }) => ({
      ...query,
    }));

    setChips([]);
  };

  const whenDateRangeChanged = useCallback((value: DateRangePickerValue): void => {
    setQuery(({ start, end, from, ...query }) => {
      if (value.type === 'range') {
        return {
          ...query,
          start: value.start,
          end: value.end,
          pageNum: '1',
        };
      }

      return {
        ...query,
        sort: value.from === 'start' ? 'start_at_asc' : 'start_at_desc',
        from: value.from,
        pageNum: '1',
      };
    });
  }, [setQuery]);

  return (
    <FilterContainer>
      <FilterRow>
        <StyledAutocompleteContainer>
          <TeamAutocomplete
            teamIds={ teamIds }
            onSelectionChanged={ whenTeamsChanged }
            showChips={ false }
            multiple
            dense
            label={
              intl.formatMessage({
                id: 'coversTable.teamsFilter.label',
                defaultMessage: 'Teams',
              })
            }
            placeholder={
              intl.formatMessage({
                id: 'coversTable.teamsFilter.placeholder',
                defaultMessage: 'Search...',
              })
            }
          />
        </StyledAutocompleteContainer>
        <StyledAutocompleteContainer>
          <CoverStatusAutocomplete
            selectedIds={ coverStatusIds }
            onSelectionChanged={ whenCoverStatusesChanged }
            showChips={ false }
            multiple
            dense
            label={
              intl.formatMessage({
                id: 'coversTable.statusFilter.label',
                defaultMessage: 'Status',
              })
            }
            placeholder={
              intl.formatMessage({
                id: 'coversTable.statusFilter.placeholder',
                defaultMessage: 'Search...',
              })
            }
          />
        </StyledAutocompleteContainer>
        <StyledAutocompleteContainer>
          <PersonAutocomplete
            id="assignees"
            selectedIds={ assigneeIds }
            onSelectionChanged={ whenAssigneeIdsChanged }
            showChips={ false }
            dense
            multiple
            label={
              intl.formatMessage({
                description: 'Label for assignee filter in cover table.',
                defaultMessage: 'Assigned to',
              })
            }
            placeholder={
              intl.formatMessage({
                description: 'Placeholder for assignee filter in cover table.',
                defaultMessage: 'Search...',
              })
            }
          />
        </StyledAutocompleteContainer>
        <EventsDateRangePicker
          value={ dateRangeValue }
          onChange={ whenDateRangeChanged }
        />
        <FlexPullRight>
          <DebouncedSearchInput
            value={ search }
            onChange={ setSearch }
          />
        </FlexPullRight>
      </FilterRow>
      <FilterChips
        chips={ chips }
        onRemoveChipClicked={ whenRemoveChipClicked }
        onClearFiltersClicked={ whenClearFiltersClicked }
      />
    </FilterContainer>
  );
};
