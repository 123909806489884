import { FC } from 'react';
import { useIntl } from 'react-intl';
import { useDateTimeFormatter } from '@ourpeople/shared/Core/Hook/useDateFormatter';

import { UsagePeriod as UsagePeriodModel } from '../../Model/UsagePeriod';
import { Usage } from '../Usage/Usage';

type Props = {
  usagePeriod: UsagePeriodModel;
  previousPeriod?: UsagePeriodModel;
};

export const UsagePeriod: FC<Props> = ({
  usagePeriod,
  previousPeriod,
}) => {
  const intl = useIntl();
  const { minimalDate } = useDateTimeFormatter();

  const heading = intl.formatMessage({
    description: 'Heading for SMS usage statistics over a period.',
    defaultMessage: '{ startDate } to { endDate }',
  }, {
    startDate: minimalDate(usagePeriod.startAt),
    endDate: minimalDate(usagePeriod.endAt),
  });

  return (
    <Usage
      heading={ heading }
      usage={ usagePeriod.usage }
      previousUsage={ previousPeriod?.usage }
    />
  );
};
