import styled from '@emotion/styled';

export const StyledProfile = styled.div`
  border: 1px solid ${props => props.theme.palette.grey[300]};
  padding: 1rem;
  margin-bottom: 1rem;

  h3 {
    font-size: 1rem;
    font-weight: 500;
    text-transform: capitalize;
  }
`;
