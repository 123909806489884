import { ValidationTree, Validator } from '../../../Common/Model';
import { Validate } from '../../../Common/Utility';
import { CONTENT_TITLE_MAX_LENGTH, DraftFormContent } from '../../Model';

export class ContentValidator {
  public static validateTitle = (content: DraftFormContent): ValidationTree<string> => ({
    errors: titleValidator.validate(content.title),
    children: {},
  });

  public static validate = <T extends DraftFormContent>(content: T): ValidationTree<T> => ({
    errors: [],
    children: {
      title: ContentValidator.validateTitle(content),
    },
  });
}

const titleValidator = new Validator<string>([
  Validate.notBlank,
  title => Validate.maxLength(title, CONTENT_TITLE_MAX_LENGTH),
]);
