import { FunctionComponent } from 'react';

import { FileDownloadButton } from '../Button';
import { RequestState } from '../../Models';
import ErrorIcon from '../../Assets/img/icons/circle/error.svg';
import { useFetchUploadUrls } from '../../Common/Hook';

interface Props {
  uploadId: string;
  onFinish?: () => void;
}

export const UploadThumbnail: FunctionComponent<Props> = ({
  uploadId,
  onFinish,
}) => {
  const [urls, requestState] = useFetchUploadUrls(uploadId);

  return (
    <FileDownloadButton uploadId={ uploadId }>
      { requestState === RequestState.COMPLETE && (
        <img
          src={ urls?.content?.thumbnail }
          alt=""
          onLoad={ onFinish }
          onError={ onFinish }
        />
      ) }
      { requestState === RequestState.FAILED && <ErrorIcon/> }
    </FileDownloadButton>
  );
};
