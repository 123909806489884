import { useIntl } from 'react-intl';
import { ReactNode, useCallback, useMemo } from 'react';

import {
  IdNotFoundValidationErrorDefinition
} from '../model/Validation/Definition/IdNotFoundValidationErrorDefinition';
import { ValidationErrorDefinitionRegistry } from '../model/Validation/Definition/ValidationErrorDefinitionRegistry';
import { ValidationError } from '../model/Validation/ValidationError';
import { ValidationErrorDefinition } from '../model/Validation/Definition/ValidationErrorDefinition';
import { ValidationTree } from '../model/Validation/ValidationTree';
import { ValidationTreeHelper } from '../utility/ValidationTreeHelper';
import { getDescendantErrors } from '../utility/getDescendantErrors';

export const useValidationErrors = <
  R extends string | ReactNode = string | ReactNode
>(
  customRegistry?: ValidationErrorDefinitionRegistry<R>
) => {
  const intl = useIntl();

  const formatErrorMessage = (
    error: ValidationError,
  ) => customRegistry?.formatMessage(intl, error)
    ?? commonRegistry.formatMessage(intl, error)
    ?? error.type;

  const formatValidationTreeWithDefinitions = useCallback((
    validationTree: ValidationTree<unknown> = ValidationTreeHelper.require(),
    definitions: ValidationErrorDefinition<any>[] = [],
  ): string[] => {
    const errors = getDescendantErrors(validationTree);
    const registry = new ValidationErrorDefinitionRegistry(definitions);
    return errors.map(error => registry.formatMessage(intl, error));
  }, [intl]);

  return useMemo(() => ({
    formatErrorMessage,
    formatErrorMessages: (errors: ValidationError[]) => errors.map(formatErrorMessage),
    formatValidationTreeWithDefinitions,
  }), [formatErrorMessage, formatValidationTreeWithDefinitions]);
}

const commonDefinitions = [
  new IdNotFoundValidationErrorDefinition(),
];

const commonRegistry = new ValidationErrorDefinitionRegistry(commonDefinitions);
