import { FC, useCallback } from 'react';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';

import { ArrayHelper } from '../../../../Common/Utility/ArrayHelper';
import { ValidationTree } from '../../../../Common/Model/ValidationTree';
import { VerticallySpaced } from '../../../../Common/Component/VerticallySpaced/VerticallySpaced';
import { FieldValidationErrors } from '../../../../Common/Component/FieldValidationErrors/FieldValidationErrors';
import { StyledOptions, StyledOption, StyledOptionIndex } from './style';

export type OptionEditorComponentProps<O> = {
  option: O;
  validation?: ValidationTree<O>;
  onChange: (option: O) => void;
  onValidationChange: (validation: ValidationTree<O>) => void;
  removeDisabled: boolean;
  onRemoveClicked: () => void;
};

export type OptionEditorComponent<O> = FC<OptionEditorComponentProps<O>>;

type Props<O> = {
  options: O[];
  onChange: (options: O[]) => void;
  validation?: ValidationTree<O[]>;
  onValidationChange: (validation: ValidationTree<O[]>) => void;
  OptionEditorComponent: OptionEditorComponent<O>;
  minOptions: number;
  maxOptions: number;
  createOption: () => O;
  localisedFieldName: string;
  localisedButtonLabel: string;
};

export const OptionsEditor = <O,>({
  options,
  onChange,
  validation,
  onValidationChange,
  OptionEditorComponent,
  minOptions,
  maxOptions,
  createOption,
  localisedFieldName,
  localisedButtonLabel,
}: Props<O>): JSX.Element => {
  const whenChange = useCallback((index: number, option: O) => (
    onChange(ArrayHelper.replace(options, index, option))
  ), [onChange, options]);

  const whenValidationChange = useCallback((index: number, optionValidation: ValidationTree<O>) => (
    onValidationChange({
      errors: validation?.errors || [],
      children: ArrayHelper.replace(
        Object.values(validation?.children || {}),
        index,
        optionValidation,
      ),
    })
  ), [onValidationChange, validation]);

  const whenRemoveClicked = useCallback((index: number) => {
    onChange(ArrayHelper.remove(options, index));
    onValidationChange({
      errors: validation?.errors || [],
      children: ArrayHelper.remove(
        Object.values(validation?.children || {}),
        index,
      ),
    });
  }, [onChange, onValidationChange, options, validation]);

  const whenAddClicked = useCallback(() => (
    onChange(options.concat(createOption()))
  ), [createOption, onChange, options]);

  return (
    <VerticallySpaced gap={ 1 }>
      <h4>{ localisedFieldName }</h4>
      <StyledOptions>
        { options.map((option, index) => (
          <StyledOption key={ index }>
            <StyledOptionIndex>
              <div>{ index + 1 }</div>
            </StyledOptionIndex>
            <OptionEditorComponent
              option={ option }
              onChange={ option => whenChange(index, option) }
              validation={ validation?.children[index] }
              onValidationChange={ optionValidation => whenValidationChange(index, optionValidation) }
              removeDisabled={ options.length <= minOptions }
              onRemoveClicked={ () => whenRemoveClicked(index) }
            />
          </StyledOption>
        )) }
      </StyledOptions>
      <FieldValidationErrors
        fieldName={ localisedFieldName }
        validationErrors={ validation?.errors || [] }
      />
      <Button
        disabled={ options.length >= maxOptions }
        onClick={ whenAddClicked }
      >
        { localisedButtonLabel }
      </Button>
    </VerticallySpaced>
  );
}
