import styled from '@emotion/styled';

import { PresentationIcon } from '../../../Common/Component';

export const StyledSuccessIcon = styled(PresentationIcon)`
  color: ${ props => props.theme.palette.success.main };
`;

export const StyledFailedIcon = styled(PresentationIcon)`
  color: ${ props => props.theme.palette.error.main };
`;
