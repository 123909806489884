import { useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { BroadcastContent, BroadcastContentContext, BroadcastContentDefinition } from '../../../Broadcasts/Model';
import { ContentResponse, PersonalContent, SeenResponse } from '../../Model';
import { useContentDefinitionRegistry } from '../../../Content/Hook';
import { SingleContentCard, EditorContent } from '../../../Content/Model';
import { useSubmitResponse } from '../../Hook/useSubmitResponse';
import { useRecallErrorHandler } from '../../Hook';

type Props<C extends BroadcastContent, R extends ContentResponse> = {
  content: PersonalContent<C, R>;
  deliveryId: string;
  onChange: (content: PersonalContent<C, R>) => void;
  context: BroadcastContentContext;
  onReloadRequired: () => void;
};

export const Content = <C extends BroadcastContent, R extends ContentResponse>({
  content,
  deliveryId,
  onChange,
  context,
  onReloadRequired,
}: Props<C, R>): JSX.Element => {
  const { getContentDefinition } = useContentDefinitionRegistry();
  const contentDefinition = getContentDefinition(content.content.type) as unknown as BroadcastContentDefinition<C, EditorContent, R>;

  const card = useMemo<SingleContentCard<C>>(() => ({
    id: '',
    image: null,
    index: 0,
    mandatory: false,
    content: content.content,
  }), [content]);

  useEffect(() => {
    if (!contentDefinition) {
      throw new Error(`Encountered unrecognised content type: ${ content.content.type }`);
    }
  }, [content.content.type, contentDefinition]);

  const whenResponded = useCallback((response: R) => {
    onChange({
      ...content,
      response,
    })
  }, [content, onChange]);

  const whenSeenResponseAccepted = useCallback((response: SeenResponse) => {
    onChange({
      ...content,
      seenAt: response.createdAt,
    });
  }, [content, onChange]);

  const [submitResponse] = useSubmitResponse(
    content.content.id.toString(),
    whenSeenResponseAccepted,
    useRecallErrorHandler(onReloadRequired),
  );

  const whenContentChanged = useCallback((card: SingleContentCard<C>) => {
    onChange({
      ...content,
      content: card.content,
    })
  }, [content, onChange]);

  useEffect(() => {
    !content.seenAt && submitResponse({
      type: 'seen',
      deliveryId: deliveryId,
      createdAt: new Date().toISOString(),
      contentId: content.content.id.toString(),
    });
  }, [content.content.id, content.seenAt, deliveryId, submitResponse]);

  return contentDefinition
    ? (
      <contentDefinition.LiveComponent
        key={ content.content.id }
        onChange={ whenContentChanged }
        onReloadRequired={ onReloadRequired }
        card={ card }
        response={ content.response || undefined }
        onResponseChange={ whenResponded }
        onResponseClear={ () => null }
        context={ context }
        validation={ undefined }
        onValidationChange={ () => null }
      />
    )
    : (
      <p>
        <FormattedMessage
          id="inbox.content.notSupported"
          description="Message displayed when content type is unrecognised."
          defaultMessage="Content not supported."
        />
      </p>
    );
};
