import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { PresentationIcon, Flex } from '../../../Common/Component';
import InsufficientCreditIcon from '../../../Assets/img/icons/streamline/messages-bubble-remove.svg';
import { StyledLabel } from './style';

type Props = {
  requiredCredit: number;
};

export const InsufficientCreditIndicator: FC<Props> = ({
  requiredCredit,
}) => (
  <Flex gap={ 1 }>
    <PresentationIcon
      IconComponent={ InsufficientCreditIcon }
      size={ 2.5 }
      color="error.main"
    />
    <StyledLabel>
      <FormattedMessage
        description="Insufficient credit notice on scheduled broadcasts."
        defaultMessage="{ requiredCredit, plural, =1 {# credit} other {# credits} } required"
        values={ { requiredCredit } }
      />
    </StyledLabel>
  </Flex>
);
