import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { AxiosError } from 'axios';
import { CenteredMessage } from '@ourpeople/shared/Core/Component/Content';

import { FormCard } from '../FormCard/FormCard';
import {
  DraftMultiContentCardSubmission,
  DraftResponse,
  DraftSingleContentCardSubmission,
  FormContent as FormContentModel,
  FormRequest,
  Response
} from '../../Model';
import { LiveComponentProps, SingleContentCard } from '../../../Content/Model';
import { useContentDefinitionRegistry } from '../../../Content/Hook';
import { ArrayHelper } from '../../../Common/Utility';
import { FormFooter } from '../FormFooter/FormFooter';
import { FormContent } from '../FormContent/FormContent';
import { SubmitFormCard } from '../SubmitFormCard/SubmitFormCard';
import { ValidationTree } from '../../../Common/Model';
import { ValidationMerger } from '../../../Common/Utility/ValidationMerger';
import { EmptyCard } from '../../../Content/Component';
import { useApi, useContextOrThrow } from '../../../Core/Hook';
import { useFileUploader, useMounted } from '../../../Common/Hook';
import { RequestState } from '../../../Models';
import { FormNavigationContext } from '../../Provider/FormNavigationProvider';
import { ToastContext } from '../../../Core/Context';
import { ConfirmNavigationDialog } from '../../../Common/Component';

export type DraftFormSubmission = {
  cards: DraftSingleContentCardSubmission[];
};

type Props = {
  cards: SingleContentCard<FormContentModel>[];
  anonymousSubmissionsEnabled: boolean;
  formName: string;
  formId: string;
};

export const FormCards: FC<Props> = ({
  cards,
  anonymousSubmissionsEnabled,
  formName,
  formId,
}) => {
  const intl = useIntl();
  const api = useApi();
  const { remove, uploadStates } = useFileUploader();
  const mounted = useMounted();
  const { addSuccessToast, addErrorToast } = useContextOrThrow(ToastContext);
  const [validation, setValidation] = useState<ValidationTree<DraftFormSubmission>>({
    errors: [],
    children: {},
  });
  const { setDisplayingValidation, setProgressBlocked } = useContextOrThrow(FormNavigationContext);
  const { getContentDefinition } = useContentDefinitionRegistry();
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const activeCard = useMemo(() => cards[currentIndex], [cards, currentIndex]);
  const LiveContentComponent = useMemo(() => activeCard && getContentDefinition(activeCard.content.type).LiveComponent, [
    activeCard,
    getContentDefinition,
  ]) as unknown as FC<LiveComponentProps<FormContentModel, DraftResponse>>;
  const [submissions, setSubmissions] = useState<DraftSingleContentCardSubmission[]>(cards.map(card => ({ cardId: card.id })));
  const activeCardResponse = useMemo(
    () => activeCard && submissions.find(submission => submission.cardId === activeCard.id)?.response,
    [activeCard, submissions],
  );
  const [submitState, setSubmitState] = useState<RequestState>(RequestState.PENDING);
  const pendingResponseCount = useMemo(() => (
    submissions.filter(submission => !!submission.response).length
  ), [submissions]);

  useEffect(() => {
    if (!activeCard || !activeCard.mandatory || activeCardResponse) {
      return;
    }

    setProgressBlocked(true);
    return () => setProgressBlocked(false);
  }, [activeCard, activeCardResponse, currentIndex, setProgressBlocked]);

  const submit = useCallback(() => {
    setSubmitState(RequestState.FETCHING);
    const draftMultiContentCardSubmissions = submissions.reduce<DraftMultiContentCardSubmission[]>(
      (submissions, submission) => {
        const cardContentId = cards.find(card => card.id === submission.cardId)?.content.id;

        return cardContentId
          ? submissions.concat({
            ...submission,
            cardContentId,
          })
          : submissions;
      },
      [],
    );
    api.post(
      `/forms/${ formId }/submissions`,
      { cards: draftMultiContentCardSubmissions },
    )
      .then(() => {
        if (!mounted.current) {
          return;
        }

        const uploadRefsPendingDeletion = cards.reduce<string[]>(
          (uploadRefsPendingDeletion, card) => {
            const contentUploadRefsPendingDeletion = Array.from(uploadStates.entries())
              .filter(([ref]) => ref.includes(card.content.id))
              .map(([ref]) => ref);

            return uploadRefsPendingDeletion.concat(contentUploadRefsPendingDeletion);
          },
          []
        );
        uploadRefsPendingDeletion.forEach(uploadRef => remove(uploadRef));

        setSubmitState(RequestState.COMPLETE);
        addSuccessToast(
          intl.formatMessage({
            description: 'Success message when submitting form responses',
            defaultMessage: 'Form submitted successfully',
          })
        );
      })
      .catch((error: AxiosError) => {
        if (!mounted.current) {
          return;
        }

        const responseData = error?.response?.data as ValidationTree<DraftFormSubmission> | undefined;

        if (responseData?.children) {
          setValidation(responseData);
          setDisplayingValidation(true);
        }

        setSubmitState(RequestState.FAILED);
        addErrorToast(
          intl.formatMessage({
            description: 'Error message when submitting form responses',
            defaultMessage: 'Form could not be submitted',
          })
        );
      });
  }, [submissions, api, formId, cards, mounted, addSuccessToast, intl, addErrorToast, setDisplayingValidation]);

  const updateSubmission = useCallback((cardId: string, updatedSubmission: DraftSingleContentCardSubmission) => (
    setSubmissions(submissions => {
      const submissionIndex = submissions.findIndex(submission => submission.cardId === cardId);
      return submissionIndex === -1
        ? submissions.concat(updatedSubmission)
        : ArrayHelper.replace(
          submissions,
          submissionIndex,
          updatedSubmission,
        );
    })
  ), []);

  const whenResponseChanged = useCallback((cardId: string, updatedResponse: DraftResponse) => (
    updateSubmission(cardId, {
      cardId,
      response: updatedResponse
    })
  ), [updateSubmission]);

  const whenResponseCleared = useCallback((cardId: string) => (
    updateSubmission(cardId, {
      cardId,
    })
  ), [updateSubmission]);

  const whenValidationChanged = useCallback((index: number, responseValidation: ValidationTree<Response>) => {
    const newValidation: ValidationTree<DraftFormSubmission> = {
      errors: [],
      children: {
        cards: {
          errors: [],
          children: {
            [`${ index }`]: {
              errors: [],
              children: {
                cardId: {
                  errors: [],
                  children: {},
                },
                response: responseValidation,
              },
            },
          },
        },
      },
    };
    setValidation(validation => ValidationMerger.overwriteMerge(validation, newValidation));
  }, []);

  return (
    <>
      <FormCard
        formName={ formName }
      >
        {
          activeCard
            ? (
              <FormContent
                key={ activeCard.id }
                currentIndex={ currentIndex }
                cardCount={ cards.length }
                contentTitle={ activeCard.content.title }
                contentDescription={ (activeCard.content as FormRequest).text || undefined }
                imageFit={ activeCard.image?.fit }
                imageId={ activeCard.image?.uploadId }
                anonymousSubmissionsEnabled={ anonymousSubmissionsEnabled }
              >
                <LiveContentComponent
                  card={ activeCard }
                  context={ undefined }
                  onChange={ () => null }
                  onReloadRequired={ () => null }
                  onResponseChange={ response => whenResponseChanged(activeCard.id, response) }
                  onResponseClear={ () => whenResponseCleared(activeCard.id) }
                  response={ activeCardResponse }
                  validation={ validation.children.cards?.children[`${ currentIndex }`]?.children.response }
                  onValidationChange={ responseValidation => whenValidationChanged(currentIndex, responseValidation) }
                />
              </FormContent>
            )
            : currentIndex === cards.length
              ? (
                <SubmitFormCard
                  formName={ formName }
                  submitState={ submitState }
                  onSubmitButtonClicked={ submit }
                />
              )
              : (
                <EmptyCard>
                  <CenteredMessage
                    heading={ intl.formatMessage({
                      description: 'Heading when an unexpected error occurs when loading form contents.',
                      defaultMessage: 'Something went wrong!'
                    }) }
                    body={ intl.formatMessage({
                      description: 'Message when an unexpected error occurs when loading form contents.',
                      defaultMessage: 'Please try again or select another form.'
                    }) }
                  />
                </EmptyCard>
              )
        }
        <FormFooter
          cardCount={ cards.length }
          currentIndex={ currentIndex }
          onIndexChanged={ setCurrentIndex }
          validation={ validation }
          submitted={ submitState === RequestState.COMPLETE }
        />
      </FormCard>
      <ConfirmNavigationDialog
        active={ submitState !== RequestState.COMPLETE && !!pendingResponseCount }
        description={ intl.formatMessage({
          description: 'Body for forms navigation confirmation modal.',
          defaultMessage: 'You have not submitted your response. To continue and submit your response use cancel. If you wish to discard and delete your responses please use discard.',
        }) }
        confirmCta={ intl.formatMessage({
          description: 'Label for button to discard responses in forms navigation confirmation modal.',
          defaultMessage: 'Discard',
        }) }
        title={ intl.formatMessage({
          description: 'Title for forms navigation confirmation modal.',
          defaultMessage: 'Are you sure you want to discard your responses?',
        }) }
      />
    </>
  );
};
