import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { DialogProps } from '@mui/material';

import { Dialog } from '../Dialog/Dialog';

export type ConfirmationDialogProps = {
  description: string;
  onCancel?: () => void;
  onClose?: (event: unknown, reason: 'backdropClick' | 'escapeKeyDown') => void;
  onConfirm?: () => void;
  confirmVariant?: 'primary' | 'secondary';
  open: boolean;
  title: string;
  confirmCta?: string;
  TransitionProps?: DialogProps['TransitionProps'];
};

export const ConfirmationDialog: FunctionComponent<ConfirmationDialogProps> = ({
  description,
  onCancel,
  onClose,
  onConfirm,
  confirmVariant = 'secondary',
  open,
  title,
  confirmCta,
  TransitionProps,
}) => {
  const handleCancel = () => {
    onCancel && onCancel();
  };

  const handleConfirm = () => {
    onConfirm && onConfirm();
  };

  return (
    <Dialog
      title={ title }
      description={ description }
      actions={ [
        {
          onClick: handleCancel,
          children: (
            <FormattedMessage
              description="Label for cancel button in confirmation dialog used throughout console"
              defaultMessage="Cancel"
            />
          )
        },
        {
          onClick: handleConfirm,
          variant: confirmVariant,
          children: confirmCta || (
            <FormattedMessage
              id="dialog.confirm"
              defaultMessage="Confirm"
            />
          ),
        },
      ] }
      TransitionProps={ TransitionProps }
      open={ open }
      onClose={ () => {
        onClose
          ? onClose(new Event('click'), 'backdropClick')
          : handleCancel()
      } }
    />
  );
};
