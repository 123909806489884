import { FunctionComponent, Component } from 'react';

import { ImmediateBroadcastPublishedConfirmationDialog } from './ImmediateBroadcastPublishedConfirmationDialog';
import { ScheduledBroadcastPublishedConfirmationDialog } from './ScheduledBroadcastPublishedConfirmationDialog';
import { RecurringBroadcastPublishedConfirmationDialog } from './RecurringBroadcastPublishedConfirmationDialog';
import { SchedulingValue } from '../../../../Broadcasts/Model';

interface Props {
  container?: Element | Component | (() => Element | Component | null) | null;
  open?: boolean
  onDismissed?: () => void;
  schedule?: SchedulingValue;
  audienceCount?: number;
}

export const BroadcastPublishedConfirmationDialog: FunctionComponent<Props> = ({
  onDismissed,
  open,
  schedule,
  audienceCount,
  ...other
}) => {
  return (
    <>
      {
        schedule && onDismissed
        && (
          <>
            {
              schedule.when === 'now' && audienceCount !== undefined
              && <ImmediateBroadcastPublishedConfirmationDialog
                audienceCount={ audienceCount }
                open={ !!open }
                onDismissed={ onDismissed }
                {...other}
              />
            }
            {
              schedule.when === 'later' && schedule.scheduleFor
              && <ScheduledBroadcastPublishedConfirmationDialog
                scheduleFor={ schedule.scheduleFor }
                open={ !!open }
                onDismissed={ onDismissed }
                {...other}
              />
            }
            {
              schedule.when !== 'later' && schedule.when !== 'now' && schedule.recurrenceRule
              && <RecurringBroadcastPublishedConfirmationDialog
                recurrenceRule={ schedule.recurrenceRule }
                open={ !!open }
                onDismissed={ onDismissed }
                {...other}
              />
            }
          </>
        )
      }
    </>
  );
}
