import * as React from 'react';
import { PropsWithChildren, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@mui/material';
import { LoadingCard } from '@ourpeople/shared/Core/Component/Feedback';

import { Pagination as PaginationModel, RequestState } from '../../Models';
import { Pagination } from '../index';
import { SimpleTable } from './SimpleTable';
import {
  LoadingContainer,
  NoResultsContainer,
  NoResultsError,
  VerticallySpaced
} from '../../Common/Component';

interface Props<RowDataType> {
  rows: RowDataType[];
  headerRow: React.ReactNode;
  rowRender: (rowData: RowDataType, index: number) => React.ReactNode;
  pageNum: number;
  onPageChanged: (page: number) => void;
  requestState: RequestState;
  onRetryClicked: () => void;
  pagination: PaginationModel | undefined;
  renderEmptyState?: (pageNum: number, onPageChanged: (pageNum: number) => void) => JSX.Element;
}

export const PaginatedTable = <RowDataType, >({
  rows,
  headerRow,
  rowRender,
  pageNum,
  onPageChanged,
  requestState,
  onRetryClicked,
  pagination,
  renderEmptyState,
}: PropsWithChildren<Props<RowDataType>>): JSX.Element => {
  const renderDefaultEmptyState = useCallback((pageNum: number, onPageChanged: (pageNum: number) => void) => (
    pageNum > 1
      ? (
        <NoResultsContainer>
          <span>
            <FormattedMessage
              id="paginatedTable.noResultsOnPage"
              description="Message in a table when there are no results and the user is not on the first page."
              defaultMessage="There are no results on this page."
            />
          </span>
          <Button
            color="primary"
            onClick={ () => onPageChanged(1) }
          >
            <FormattedMessage
              id="paginatedTable.returnToFirstPage"
              description="Cta in a table to return to page one when there are no results."
              defaultMessage="Return to first page"
            />
          </Button>
        </NoResultsContainer>
      )
      : (
        <NoResultsContainer>
          <span>
            <FormattedMessage
              id="paginatedTable.noResults"
              defaultMessage="There are no results"
            />
          </span>
        </NoResultsContainer>
      )
  ), []);

  const renderSuccessfulTable = useCallback(() => {
    return rows.length === 0 || !pagination
      ? (
        <>
          <SimpleTable
            rowRender={ () => null }
            rows={ [] }
            headerRow={ headerRow }
          />
          { (renderEmptyState ? renderEmptyState : renderDefaultEmptyState)(pageNum, onPageChanged) }
        </>
      )
      : (
        <VerticallySpaced gap={ 2 }>
          <SimpleTable
            rows={ rows }
            rowRender={ rowRender }
            headerRow={ headerRow }
          />
          <Pagination
            pagination={ pagination }
            onPageChanged={ onPageChanged }
            pageNum={ pageNum }
          />
        </VerticallySpaced>
      )
  }, [headerRow, onPageChanged, pageNum, pagination, renderDefaultEmptyState, renderEmptyState, rowRender, rows]);

  const renderLoadedTable = () => {
    return requestState === RequestState.FAILED
      ? (
        <NoResultsError onRetry={ onRetryClicked }>
          <FormattedMessage
            id="paginatedTable.error"
            defaultMessage="Failed to load results"
          />
        </NoResultsError>
      )
      : renderSuccessfulTable();
  };

  return (
    <>
      {
        requestState < RequestState.COMPLETE
          ? (
            <LoadingContainer>
              <LoadingCard/>
            </LoadingContainer>
          )
          : renderLoadedTable()
      }
    </>
  );
};
