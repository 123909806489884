import { SubmittedComponent } from '../../../../Content/Model';
import { VideoContent } from '../../../Model/ContentTypes/VideoContent';
import { ClickableVideoThumbnail } from '../../Content/ClickableVideoThumbnail/ClickableVideoThumbnail';

export const SubmittedVideoContent: SubmittedComponent<undefined, VideoContent> = ({
  card,
  onFinish,
}) => (
  <ClickableVideoThumbnail
    duration={ card.content.upload.video?.duration || 0 }
    onReady={ onFinish }
    thumbnailKey={ card.content.thumbnailKey }
    uploadId={ String(card.content.upload.id) }
  />
);
