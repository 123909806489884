import * as React from 'react';
import { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import { CircularProgress, FormControl } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { LoadingCard } from '@ourpeople/shared/Core/Component/Feedback';
import { useDebounce } from '@ourpeople/shared/Core/Hook/useDebounce';

import {
  Checkbox,
  LoadingSpinner,
  Pagination,
  StyledFormControlLabel,
  StyledRadio,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '../../../Components';
import { DeprecatedLink, TruncatingContent, VerticallySpaced } from '../../../Common/Component';
import { Paginated, Pagination as PaginationModel, RequestState } from '../../../Models';
import {
  AccessCell,
  ImportStatus,
  InlineRadioGroup,
  RequestStateCell,
  TableAndFilterContainer,
  TableContainer
} from './styles';
import { AccessOption, ImportUser } from '../../Model';
import { ImportTableFilters } from '../ImportTableFilters/ImportTableFilters';
import { ApiContext } from '../../../Contexts';
import { Api } from '../../../Services';
import { ImportUserParser } from '../../../Utility';
import { ImmutableMap } from '../../../Models/ImmutableMap';
import {
  StyledLoadingCardContainer,
  StyledNoResultsContainer,
  StyledSendingProgress
} from '../../../Sections/Broadcasts/List/styles';
import { FilterOption } from '../../../Models/FilterOption';

type PulledUserStatus = 'live' | 'deactivated' | 'rejected' | 'missing';
type SyncDecision = 'allow' | 'deny' | 'pending';

interface UsersQuery {
  pageNum: number;
  opDepartmentIds?: string | null;
  opJobTitleIds?: string | null;
  opTeamIds?: string | null;
  opRegionIds?: string | null;
  opSkillIds?: string | null;
  search?: string | null;
  sort?: 'created_at_desc' | 'created_at_asc';
  pulledStatuses?: string | null;
  syncDecisions?: string | null;
}

interface UpdateSyncDecisionBody {
  syncDecision: SyncDecision;
  query: {
    opDepartmentIds?: string[] | null;
    opJobTitleIds?: string[] | null;
    opTeamIds?: string[] | null;
    opRegionIds?: string[] | null;
    opSkillIds?: string[] | null;
    search?: string | null;
    ids?: string[] | null;
    pulledStatuses?: PulledUserStatus[] | null;
    syncDecisions?: SyncDecision[] | null;
  };
}

type Props = {
  bulkActionCompletedAt?: Date|null,
  imported?: boolean,
  showIntegrationSpinner?: boolean,
  onBulkActionCompleted?: () => void,
  onRequestStateChanged: (requestState: RequestState) => void,
  onUsersCounted?: (pendingCount: number) => void,
}

export enum ImportFilterType {
  TEAMS,
  DEPARTMENTS,
  JOB_TITLES,
  ACCESS,
  SKILLS,
  REGIONS,
}

export type ImportFilters = Record<ImportFilterType, FilterOption[]>;

export const ImportTable: FunctionComponent<Props> = ({
  bulkActionCompletedAt,
  imported = false,
  showIntegrationSpinner = false,
  onBulkActionCompleted,
  onRequestStateChanged,
  onUsersCounted,
}) => {
  const api = useContext(ApiContext);
  const [requestState, setRequestState] = useState<RequestState>(RequestState.PENDING);
  const [activeFilters, setActiveFilters] = useState<ImportFilters>(initialFilterOptions)
  const [selectedUsers, setSelectedUsers] = useState<ImmutableMap<string, ImportUser>>(ImmutableMap.create());
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [importParams, setImportParams] = useState<UsersQuery>({
    pageNum: 1,
    pulledStatuses: 'live,deactivated',
    syncDecisions: imported ? 'allow,deny' : 'pending',
  });
  const [users, setUsers] = useState<ImportUser[]>([]);
  const [pagination, setPagination] = useState<PaginationModel | null>(null);
  const [deniedUsers, setDeniedUsers] = useState<string[]>([]);
  const [allowedUsers, setAllowedUsers] = useState<string[]>([]);
  const [userRequestStates, setUserRequestStates] = useState<ImmutableMap<string, RequestState>>(ImmutableMap.create());
  const debouncedFilters = useDebounce<ImportFilters>(activeFilters, 300);

  const getSyncDecisionParamsFromImportParams = (
    importQueryParams: UsersQuery,
    syncDecision: 'allow' | 'deny',
    userIds: string[] | null = null,
  ): UpdateSyncDecisionBody => {
    return {
      syncDecision,
      query: {
        search: importQueryParams.search,
        ids: userIds,
        opTeamIds: importQueryParams.opTeamIds?.split(',') || null,
        opDepartmentIds: importQueryParams.opDepartmentIds?.split(',') || null,
        opJobTitleIds: importQueryParams.opJobTitleIds?.split(',') || null,
        opRegionIds: importQueryParams.opRegionIds?.split(',') || null,
        opSkillIds: importQueryParams.opSkillIds?.split(',') || null,
        pulledStatuses: importQueryParams.pulledStatuses?.split(',') as PulledUserStatus[] || null,
        syncDecisions: importQueryParams.syncDecisions?.split(',') as SyncDecision[] || null,
      },
    }
  }

  useEffect(() => {
    setRequestState(RequestState.PENDING);
    const accessFilter = debouncedFilters[ImportFilterType.ACCESS][0];
    let accessParams: Partial<UsersQuery> = {
      syncDecisions: imported ? 'allow,deny' : 'pending',
    };
    if (accessFilter) {
      accessParams = {
        syncDecisions: accessFilter.value,
      }
    }

    setImportParams((previous) => ({
      ...previous,
      ...accessParams,
      pageNum: 1,
      opTeamIds: debouncedFilters[ImportFilterType.TEAMS].map(filter => filter.value).join() || null,
      opDepartmentIds: debouncedFilters[ImportFilterType.DEPARTMENTS].map(filter => filter.value).join() || null,
      opJobTitleIds: debouncedFilters[ImportFilterType.JOB_TITLES].map(filter => filter.value).join() || null,
      opRegionIds: debouncedFilters[ImportFilterType.REGIONS].map(filter => filter.value).join() || null,
      opSkillIds: debouncedFilters[ImportFilterType.SKILLS].map(filter => filter.value).join() || null,
    }));
  }, [debouncedFilters, imported]);

  useEffect(() => {
    setImportParams((previous) => ({
      ...previous,
      pageNum: 1,
      search: searchQuery || null,
    }))
  }, [searchQuery]);

  useEffect(() => {
    setRequestState(RequestState.PENDING);
  }, [searchQuery, activeFilters]);

  const fetchPeople = useCallback((api: Api): Promise<Paginated<'users', ImportUser>> => {
    return api.get<Paginated<'users', ImportUser>>(
      '/remote-integration/users',
      { params: importParams },
    )
      .then((response) => {
        return response.data;
      });
  }, [importParams]);

  useEffect(() => {
    if (onUsersCounted && pagination) {
      onUsersCounted(pagination.itemCount);
    }
  }, [onUsersCounted, pagination]);

  useEffect(() => {
    onRequestStateChanged(requestState);
  }, [requestState, onRequestStateChanged]);

  const reloadTable = useCallback(() => {
    if (api) {
      setRequestState(RequestState.FETCHING);
      fetchPeople(api)
        .then((result) => {
          setPagination(result.pagination);
          setUsers(result.users);
          setRequestState(RequestState.COMPLETE);
        })
        .catch(() => {
          setRequestState(RequestState.FAILED);
        });
    }
  }, [api, fetchPeople]);

  useEffect(() => {
    reloadTable();
  }, [reloadTable, bulkActionCompletedAt, showIntegrationSpinner]);

  const whenSelectAllClicked = (): void => {
    if (allSelected || !!selectedUsers.count) {
      clearSelection();
    } else {
      setSelectedUsers(ImmutableMap.fromArray(
        users.map(user => [user.id, user]),
      ));
    }
  }

  const whenPageChanged = (newPage: number) => {
    setRequestState(RequestState.PENDING);
    setImportParams((previousFilter) => ({
      ...previousFilter,
      pageNum: newPage
    }));
  }

  const updateLocalAccess = (access: AccessOption, userIds: string[]): void => {
    if (access === AccessOption.ALLOW) {
      setDeniedUsers(deniedUsers.filter((id) => userIds.indexOf(id) === -1))
      setAllowedUsers(Array.from(new Set(allowedUsers.concat(userIds))));
    } else if (access === AccessOption.DENY) {
      setAllowedUsers(allowedUsers.filter((id) => userIds.indexOf(id) === -1))
      setDeniedUsers(Array.from(new Set(deniedUsers.concat(userIds))));
    }
  }

  const clearSelection = () => {
    setAllSelected(false);
    setSelectedUsers(selectedUsers.clear());
  }

  const updateUserAccess = (access: AccessOption, userIds?: string[]): void => {
    if (api) {
      if (!userIds) {
        setRequestState(RequestState.PENDING);
      } else {
        if (userIds.length === 1) {
          setUserRequestStates(userRequestStates.set(userIds[0], RequestState.FETCHING));
        }
        updateLocalAccess(access, userIds);
      }
      api.post(
        '/remote-integration/users/sync-decision',
        getSyncDecisionParamsFromImportParams(
          importParams,
          access === AccessOption.ALLOW ? 'allow' : 'deny',
          userIds || null,
        ),
      )
        .then(() => {
          clearSelection();
          if (!userIds || userIds.length > 1) {
            reloadTable();
            onBulkActionCompleted && onBulkActionCompleted();
          } else if (userIds.length === 1) {
            setUserRequestStates(userRequestStates.set(userIds[0], RequestState.COMPLETE));
          }
        })
        .catch(() => {
          if (userIds && userIds.length === 1) {
            setUserRequestStates(userRequestStates.set(userIds[0], RequestState.FAILED));
          }
        });
    }
  }

  const getRadioChangeHandler = (access: AccessOption, personId: string): () => void => {
    return () => updateUserAccess(access, [personId]);
  }

  const whenExpandSelectionClicked = (): void => {
    setSelectedUsers(selectedUsers.clear());
    setAllSelected(true);
  }

  const whenClearSelectionClicked = (): void => {
    clearSelection();
  }

  const getSelectHandler = (user: ImportUser): (event: React.ChangeEvent<HTMLInputElement>, selected: boolean) => void => {
    return (event: React.ChangeEvent<HTMLInputElement>, selected: boolean) => {
      if (selected) {
        setSelectedUsers(selectedUsers.set(user.id, user));
      } else {
        if (allSelected) {
          const otherUsers = users.reduce(
            (userArray: [string, ImportUser][], otherUser) => {
              if (otherUser.id !== user.id) {
                return userArray.concat([[otherUser.id, otherUser]]);
              }
              return userArray;
            },
            [],
          );
          setSelectedUsers(ImmutableMap.fromArray(otherUsers));
          setAllSelected(false);
        } else {
          setSelectedUsers(selectedUsers.remove(user.id));
        }
      }
    }
  }

  const whenAllowActionClicked = (): void => {
    if ((!pagination || selectedUsers.count < pagination.itemCount) && !allSelected) {
      updateUserAccess(AccessOption.ALLOW, selectedUsers.values.map((user) => user.id));
    } else {
      updateUserAccess(AccessOption.ALLOW);
    }
  }

  const whenDenyActionClicked = (): void => {
    if ((!pagination || selectedUsers.count < pagination.itemCount) && !allSelected) {
      updateUserAccess(AccessOption.DENY, selectedUsers.values.map((user) => user.id));
    } else {
      updateUserAccess(AccessOption.DENY);
    }
  }

  const whenFiltersReset = () => {
    setActiveFilters(initialFilterOptions);
  }

  return (
    <>
      {
        pagination && (
          <TableAndFilterContainer>
            {
              showIntegrationSpinner && (
                <ImportStatus>
                  <div>
                    <LoadingSpinner size="large" />
                    <div>
                      <FormattedMessage
                        id="thirdPartyImport.manage.importingMessage"
                        description="Loading message when import is ongoing"
                        defaultMessage="<p>Your integration was successful. We're just collecting your employee list.</p><p>This table will update when the import is complete so you can confirm who needs access to OurPeople.</p>"
                      />
                    </div>
                  </div>
                </ImportStatus>
              )
            }
            <ImportTableFilters
              onFiltersReset={ whenFiltersReset }
              imported={imported}
              onAllowActionClicked={whenAllowActionClicked}
              onClearSelectionClicked={ whenClearSelectionClicked }
              onDenyActionClicked={whenDenyActionClicked}
              onExpandSelectionClicked={ whenExpandSelectionClicked }
              onSearchQueryChanged={ setSearchQuery }
              onActiveFiltersChanged={ setActiveFilters }
              pageNum={importParams.pageNum}
              pageSize={pagination.pageSize}
              resultCount={ users.length }
              searchQuery={ searchQuery }
              activeFilters={ activeFilters }
              selectedUserCount={ allSelected ? pagination.itemCount : selectedUsers.count }
              totalUserCount={ pagination.itemCount }
              userRequestState={requestState}
            />
            {
              requestState < RequestState.COMPLETE && <StyledLoadingCardContainer>
                <LoadingCard/>
              </StyledLoadingCardContainer>
            }
            {
              requestState === RequestState.COMPLETE && (
                users.length
                  ? (
                    <VerticallySpaced gap={ 2 }>
                      <TableContainer>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={ selectedUsers.count >= pagination.pageSize || allSelected }
                                  indeterminate={ selectedUsers.count !== 0 && selectedUsers.count < pagination.pageSize }
                                  onChange={ whenSelectAllClicked }
                                />
                              </TableCell>
                              <TableCell>
                                <FormattedMessage
                                  id="thirdPartyImport.table.id"
                                  description="Employee IDs header in table"
                                  defaultMessage="IDs"
                                />
                              </TableCell>
                              <TableCell>
                                <FormattedMessage
                                  id="thirdPartyImport.table.name"
                                  description="Employee name header in table"
                                  defaultMessage="Name"
                                />
                              </TableCell>
                              <TableCell>
                                <FormattedMessage
                                  description="Status header in third-party import table."
                                  defaultMessage="Status"
                                />
                              </TableCell>
                              <TableCell>
                                <FormattedMessage
                                  description="Regions header in third-party import table"
                                  defaultMessage="Regions"
                                />
                              </TableCell>
                              <TableCell>
                                <FormattedMessage
                                  description="Departments header in table"
                                  defaultMessage="Departments"
                                />
                              </TableCell>
                              <TableCell>
                                <FormattedMessage
                                  description="Teams header in table"
                                  defaultMessage="Teams"
                                />
                              </TableCell>
                              <TableCell>
                                <FormattedMessage
                                  description="Job titles header in table"
                                  defaultMessage="Job titles"
                                />
                              </TableCell>
                              <TableCell>
                                <FormattedMessage
                                  description="Skill header in third-party import table"
                                  defaultMessage="Skills"
                                />
                              </TableCell>
                              <TableCell colSpan={2}>
                                <FormattedMessage
                                  id="thirdPartyImport.table.access"
                                  description="Access header in table"
                                  defaultMessage="Confirm access"
                                />
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {
                              users.map((user) => (
                                <TableRow key={ user.id }>
                                  <TableCell padding="checkbox">
                                    <Checkbox
                                      color="primary"
                                      checked={selectedUsers.contains(user.id) || allSelected}
                                      onChange={ getSelectHandler(user) }
                                    />
                                  </TableCell>
                                  <TableCell>
                                    { ImportUserParser.cleanPulledIds(user).join(', ') }
                                  </TableCell>
                                  <TableCell>
                                    <TruncatingContent minWidth={ 150 }>
                                      {
                                        user.pushedUser
                                          ? (
                                                <DeprecatedLink to={`/people/${user.pushedUser.opId}`}>
                                                  { ImportUserParser.fullName(user) }
                                                </DeprecatedLink>
                                            )
                                          : ImportUserParser.fullName(user)
                                      }
                                    </TruncatingContent>
                                  </TableCell>
                                  <TableCell>
                                    {
                                      user.deactivated
                                        ? (
                                          <FormattedMessage
                                            description="Status for deactivated user in integration."
                                            defaultMessage="Deactivated"
                                          />
                                        )
                                        : (
                                          <FormattedMessage
                                            description="Status for live user in integration."
                                            defaultMessage="Live"
                                          />
                                        )
                                    }
                                  </TableCell>
                                  <TableCell>
                                    <TruncatingContent minWidth={ 150 }>
                                      { ImportUserParser.tags(user, 'region') }
                                    </TruncatingContent>
                                  </TableCell>
                                  <TableCell>
                                    <TruncatingContent minWidth={ 150 }>
                                      { ImportUserParser.tags(user, 'department') }
                                    </TruncatingContent>
                                  </TableCell>
                                  <TableCell>
                                    <TruncatingContent minWidth={ 150 }>
                                      { ImportUserParser.tags(user, 'team') }
                                    </TruncatingContent>
                                  </TableCell>
                                  <TableCell>
                                    <TruncatingContent minWidth={ 150 }>
                                      { ImportUserParser.tags(user, 'jobtitle') }
                                    </TruncatingContent>
                                  </TableCell>
                                  <TableCell>
                                    <TruncatingContent minWidth={ 150 }>
                                      { ImportUserParser.tags(user, 'skill') }
                                    </TruncatingContent>
                                  </TableCell>
                                  <AccessCell>
                                    <FormControl component="fieldset">
                                      <InlineRadioGroup name="access">
                                        <StyledFormControlLabel
                                          value="allow"
                                          control={
                                            <StyledRadio
                                              checked={allowedUsers.indexOf(user.id) !== -1 || (deniedUsers.indexOf(user.id) === -1 && user.syncDecision === 'allow')}
                                              onChange={ getRadioChangeHandler(AccessOption.ALLOW, user.id) }
                                              color="primary"
                                            />
                                          }
                                          label="Allow"
                                        />
                                        <StyledFormControlLabel
                                          value="deny"
                                          control={
                                            <StyledRadio
                                              checked={deniedUsers.indexOf(user.id) !== -1 || (allowedUsers.indexOf(user.id) === -1 && user.syncDecision === 'deny')}
                                              onChange={ getRadioChangeHandler(AccessOption.DENY, user.id) }
                                              color="primary"
                                            />
                                          }
                                          label="Deny"
                                        />
                                      </InlineRadioGroup>
                                    </FormControl>
                                  </AccessCell>
                                  <RequestStateCell>
                                    {
                                      userRequestStates.contains(user.id) && (
                                        <>
                                          {
                                            userRequestStates.get(user.id) === RequestState.FETCHING && (
                                              <StyledSendingProgress>
                                                <CircularProgress size="1.5rem" color="secondary"/>
                                                <FormattedMessage
                                                  id="thirdPartyImport.table.savingAccess"
                                                  defaultMessage="Saving&hellip;"
                                                  description="Message when saving access"
                                                />
                                              </StyledSendingProgress>
                                            )
                                          }
                                          {
                                            userRequestStates.get(user.id) === RequestState.COMPLETE && (
                                              <StyledSendingProgress>
                                                <FormattedMessage
                                                  id="thirdPartyImport.table.savedAccess"
                                                  defaultMessage="Saved"
                                                  description="Message when user access has been saved"
                                                />
                                              </StyledSendingProgress>
                                            )
                                          }
                                          {
                                            userRequestStates.get(user.id) === RequestState.FAILED && (
                                              <StyledSendingProgress>
                                                <FormattedMessage
                                                  id="thirdPartyImport.table.failedAccess"
                                                  defaultMessage="Failed to save"
                                                  description="Message when user access does not save successfully"
                                                />
                                              </StyledSendingProgress>
                                            )
                                          }
                                        </>
                                      )
                                    }
                                  </RequestStateCell>
                                </TableRow>
                              ))
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Pagination
                        pagination={ pagination }
                        pageNum={ importParams.pageNum }
                        onPageChanged={ whenPageChanged }
                      />
                    </VerticallySpaced>
                  )
                  : (
                    <StyledNoResultsContainer>
                      <span>
                        <FormattedMessage
                          id="broadcasts.list.noDeliveries"
                          defaultMessage="There are no people"
                        />
                      </span>
                    </StyledNoResultsContainer>
                  )
              )
            }
          </TableAndFilterContainer>
        )
      }
    </>
  );
};

const initialFilterOptions = {
  [ImportFilterType.TEAMS]: [],
  [ImportFilterType.DEPARTMENTS]: [],
  [ImportFilterType.JOB_TITLES]: [],
  [ImportFilterType.ACCESS]: [],
  [ImportFilterType.REGIONS]: [],
  [ImportFilterType.SKILLS]: [],
};
