import { FC } from 'react';
import { useIntl } from 'react-intl';

import BackArrowIcon from '../../../../Asset/Icon/Streamline/arrow-left-1.svg';
import { StyledIconButton } from './style';

type Props = {
  onClick: () => void;
};

export const PagingMenuBackButton: FC<Props> = ({
  onClick,
}) => {
  const intl = useIntl();

  return (
    <StyledIconButton
      onClick={ onClick }
      IconComponent={ BackArrowIcon }
      label={ intl.formatMessage({
        description: 'Label for back button used inside paging menus.',
        defaultMessage: 'Return to previous step',
      }) }
      color="primary.main"
    />
  );
};
