import { IntlShape } from 'react-intl';
import TextBlockIcon from 'op-storybook/lib/assets/icon/figma/type-square.svg';
import {
  BlankValidationErrorDefinition
} from 'op-storybook/lib/model/Validation/Definition/BlankValidationErrorDefinition';

import { BlockErrorDefinitions, BroadcastBlockDefinition, PreviewSlot } from '../../Model/BroadcastBlockDefinition';
import { BroadcastBlock } from '../../Model/Broadcast';
import { TextBlockExpandedPreview } from '../../Component/Blocks/TextBlock/TextBlockExpandedPreview';
import { TextBlockInlineForm } from '../../Component/Blocks/TextBlock/TextBlockInlineForm';
import { UniqueIdGenerator } from '../../../../Common/Utility';
import { TextBlockPreview } from '../../Component/Blocks/TextBlock/TextBlockPreview';

export type TextBlock = {
  localId: string;
  kind: 'text';
  attributes: {
    html: string;
  };
};

export class TextBlockDefinition implements BroadcastBlockDefinition<TextBlock> {
  public readonly prioritise = true;
  public readonly kind = 'text';
  public readonly localisedKind: string;
  public readonly errorDefinitions: BlockErrorDefinitions<TextBlock>;

  constructor(intl: IntlShape) {
    this.localisedKind = intl.formatMessage({
      description: 'Name for text block used in broadcast editor',
      defaultMessage: 'Text',
    });

    this.errorDefinitions = {
      html: [
        new BlankValidationErrorDefinition(intl => intl.formatMessage({
          description: 'Error message when broadcast text block HTML input is left blank.',
          defaultMessage: 'Text block content can not be blank.',
        })),
      ],
    };
  }

  public definesBlock(block: BroadcastBlock): block is TextBlock {
    return block.kind === 'text';
  }

  public initialiseBlock(): TextBlock {
    return {
      localId: UniqueIdGenerator.generate(),
      kind: 'text',
      attributes: {
        html: '',
      },
    };
  }

  public IconComponent = TextBlockIcon;
  public ExpandedPreviewComponent = TextBlockExpandedPreview;
  public InlineFormComponent = TextBlockInlineForm;
  public FormComponent = null;
  public preview = {
    slot: PreviewSlot.TEXT,
    Component: TextBlockPreview,
  };
}
