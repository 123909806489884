import { IntlShape } from 'react-intl';

import { FormContentDefinition } from '../../Model';
import VideoIcon from '../../../Assets/img/icons/monochrome/content/video.svg';
import { DraftVideoContent, VideoContent } from '../../Model/ContentTypes/VideoContent';
import { EditorContent, SingleContentCard } from '../../../Content/Model';
import { VideoContentFields } from '../../Component/Content/VideoContentFields/VideoContentFields';
import { VideoContentValidator } from '../../Utility/Validation/VideoContentValidator';
import { EnvironmentSettings } from '../../../Models';
import { UniqueIdGenerator } from '../../../Common/Utility';
import { CardFactory, CardTransformer } from '../../../Content/Utility';
import { Upload } from '../../../Types';
import { VideoContentPreview } from '../../Component/Content/VideoContentPreview/VideoContentPreview';
import { SubmittedVideoContent } from '../../Component/SubmittedContent/SubmittedVideoContent/SubmittedVideoContent';
import { LiveVideoContent } from '../../Component/LiveContent/LiveVideoContent/LiveVideoContent';

export type VideoEditorContent = EditorContent<DraftVideoContent> & {
  prepareStatus: string | null;
  upload: Upload | null;
};

const contentType = 'videoContent';
export class VideoContentDefinition implements FormContentDefinition<VideoContent, VideoEditorContent> {
  public readonly id = 'video';
  public readonly new = true;
  public readonly richText = false;
  public readonly contentTypes = [contentType];
  public readonly categoryId = 'content';
  public readonly IconComponent = VideoIcon;
  public readonly EditorComponent = VideoContentFields;
  public readonly PreviewComponent = VideoContentPreview;
  public readonly LiveComponent = LiveVideoContent;
  public readonly SubmittedComponent = SubmittedVideoContent;
  public readonly availableToCreate: boolean = true;
  public validate = VideoContentValidator.validate;

  public definesContentType = (type: string): boolean => (
    this.contentTypes.includes(type)
  );

  public getContentTitle = (intl: IntlShape, content: VideoContent): string => (
    content.title || this.getDefaultTitle(intl)
  );

  public getLocalisedType = (intl: IntlShape): string => (
    intl.formatMessage({
      description: 'Localised name for video form content type.',
      defaultMessage: 'Video',
    })
  );

  public getDraftContentTitle = (intl: IntlShape, draftContent: DraftVideoContent): string => (
    draftContent.title || this.getDefaultTitle(intl)
  );

  public hasRequiredFeatureFlags = (environmentSettings: EnvironmentSettings): boolean => (
    !!environmentSettings.videoTypeEnabled
  );

  public createEditorContent = (): VideoEditorContent => ({
    id: UniqueIdGenerator.generate(),
    card: CardFactory.fromDraftContent({
      id: undefined,
      type: 'videoContent',
      title: '',
      text: '',
      thumbnailKey: null,
      uploadId: null,
    }),
    upload: null,
    prepareStatus: null,
  });

  public transformCard = (card: SingleContentCard<VideoContent>): VideoEditorContent => ({
    id: card.id,
    card: CardTransformer.transformCard(card, {
      id: card.content.id,
      type: card.content.type,
      title: card.content.title,
      text: card.content.text,
      mandatory: card.mandatory,
      thumbnailKey: card.content.thumbnailKey,
      uploadId: String(card.content.upload.id),
    }),
    upload: card.content.upload,
    prepareStatus: 'complete',
  });

  private getDefaultTitle = (intl: IntlShape): string => (
    intl.formatMessage({
      description: 'Default title for video content.',
      defaultMessage: 'Untitled video card',
    })
  );
}
