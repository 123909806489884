import { FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Slide } from '@mui/material';

import { StyledBackdrop, StyledMenuSlide, StyledPopover } from './style';
import { Button } from 'op-storybook/stories/components/Button/Button';

export type Page = {
  key: string;
  render: () => JSX.Element;
  onExited?: () => void;
  maxWidth?: number;
};

export enum PagingDirection {
  FORWARDS,
  BACKWARDS
}

type Props = {
  open: boolean;
  onToggle: (open: boolean) => void;
  pageKey: string;
  direction: PagingDirection;
  pages: Page[];
  buttonLabel: ReactNode;
  anchor?: 'right' | 'left' | 'center';
  mobile?: boolean;
  onExited?: () => void;
};

export const PagingMenu: FC<Props> = ({
  open,
  onToggle,
  pageKey,
  direction,
  pages,
  buttonLabel,
  anchor = 'center',
  mobile = false,
  onExited,
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const containerRef = useRef(null);
  const [nextPage, setNextPage] = useState<Page | undefined>(undefined);
  const [currentPageKey, setCurrentPageKey] = useState<string>(pageKey);
  const currentPage = useMemo(() => (
    pages.find(page => page.key === currentPageKey)
  ), [currentPageKey, pages]);

  const transitionDirection = nextPage
    ? direction === PagingDirection.BACKWARDS
      ? 'left'
      : 'right'
    : direction === PagingDirection.BACKWARDS
      ? 'right'
      : 'left';

  // Queue transition to desired page when changed from outside component
  useEffect(() => {
    if (pageKey === currentPage?.key) {
      return;
    }

    setNextPage(pages.find(page => page.key === pageKey));
  }, [currentPage?.key, nextPage?.key, pageKey, pages]);

  const switchToNextPage = useCallback(() => {
    nextPage && setCurrentPageKey(nextPage.key);
    setNextPage(undefined);
    currentPage?.onExited && currentPage.onExited();
  }, [currentPage, nextPage]);

  // If menu is closed and not on desired page, switch immediately without transition
  useEffect(() => {
    if (open || currentPage?.key === pageKey) {
      return;
    }

    setCurrentPageKey(pageKey);
    setNextPage(undefined);
  }, [currentPage?.key, nextPage?.key, open, pageKey]);

  return (
    <div>
      <StyledBackdrop
        open={ open }
        onClick={ () => onToggle(false) }
      />
      <Button
        onClick={ () => onToggle(!open) }
        ref={ buttonRef }
        variant="secondary"
        css={ {
          ...(mobile && open ? { zIndex: 1300 } : {}),
        } }
      >
        { buttonLabel }
      </Button>
      <div ref={ containerRef }>
        <StyledPopover
          open={ open }
          onClose={ () => onToggle(false) }
          container={ containerRef.current }
          TransitionProps={ {
            onExited,
          } }
          {
            ...mobile
              ? {
                anchorOrigin: {
                  horizontal: 'center',
                  vertical: 'center',
                },
                transformOrigin: {
                  horizontal: 'center',
                  vertical: 'center',
                },
              }
              : {
                anchorEl: buttonRef.current,
                anchorOrigin: {
                  horizontal: anchor,
                  vertical: 'bottom',
                },
                transformOrigin: {
                  horizontal: anchor,
                  vertical: 'top',
                },
                marginThreshold: 0,
              }
          }
          align={ mobile ? 'center' : 'flex-start' }
        >
          { currentPage && (
            <Slide
              direction={ transitionDirection }
              in={ !nextPage }
              onExited={ switchToNextPage }
              timeout={ 150 }
            >
              <StyledMenuSlide
                { ...currentPage.maxWidth ? { maxWidth: currentPage.maxWidth } : {} }
                anchor={ mobile ? 'center' : anchor }
              >
                { currentPage.render() }
              </StyledMenuSlide>
            </Slide>
          ) }
        </StyledPopover>
      </div>
    </div>
  );
};
