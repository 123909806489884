import { Form } from 'op-storybook/lib/components/Form/Form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { FC, HTMLAttributes, useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'op-storybook/stories/components/Button/Button';
import { readableColor } from 'polished';
import { defaultTheme } from 'op-storybook';
import { ThemeContext } from '@emotion/react';
import { RoundedTextarea } from '@ourpeople/shared/Core/Component/Input/RoundedTextarea/RoundedTextarea';
import { FieldLabel } from 'op-storybook/lib/components/FieldLabel/FieldLabel';
import { ProgressBar } from 'op-storybook/stories/components/ProgressBar/ProgressBar';
import { useValidationErrors } from 'op-storybook/lib/hooks/useValidationErrors';

import { FormComponent } from '../../../Model/BroadcastBlockDefinition';
import { ImageBlock } from '../../../Service/Blocks/ImageBlockDefinition';
import { MinimumInset } from '../../../../Common/Component/Layout/MinimumInset';
import { ImageAsset } from '../../../../../Common/Hook';
import { useContextOrThrow } from '../../../../../Core/Hook';
import { FileUploaderContext } from '../../../../../Common/Context';
import { ImageEditSheet } from '../../../../Common/Component/Input/ImageEditSheet';
import { UnmodifiedImageSrcContext } from '../../../../../Common/Provider/UnmodifiedImageSrcProvider';
import { FileAndSrc } from '../../../../../Common/Model/FileAndSrc';
import { ArrayHelper } from '../../../../../Common/Utility';
import { ColourPickerButton } from '../../../../Common/Component/Input/ColourPickerButton';
import { ImageLibrarySheet } from '../../../../Common/Component/Input/ImageLibrarySheet';
import { ImageUrlTransformer } from '../../../../../Common/Utility/ImageUrlTransformer';
import { BroadcastBlockDefinitionRegistryContext } from '../../../Context/BroadcastBlockDefinitionRegistry';
import { BroadcastEditorErrors } from '../../BroadcastEditorErrors';
import { LocalisedFileUploadErrors } from '../../../../../Common/Component';

export const ImageBlockForm: FormComponent<ImageBlock> = ({
  block,
  validationTree,
  onChange,
  attachments,
  onAttachmentsChange,
  isTemporaryBlock,
}) => {
  const intl = useIntl();
  const [imageLibraryOpen, setImageLibraryOpen] = useState<boolean>(isTemporaryBlock);
  const [cropperOpen, setCropperOpen] = useState<boolean>(false);
  const { upload, uploadStates, remove } = useContextOrThrow(FileUploaderContext);
  const uploadState = useMemo(() => {
    return uploadStates.get(block.localId);
  }, [block.localId, uploadStates]);
  const transformedUploadedSrc = uploadState?.upload
    ? `/api/uploads/${ uploadState.upload.uuid }/download`
    : null;
  const { assign, get, revoke } = useContextOrThrow(UnmodifiedImageSrcContext);
  const theme = useContextOrThrow(ThemeContext);
  const unmodifiedUploadedSrc = useMemo(() => get(block.localId), [block.localId, get]);
  const [unmodifiedConfirmedFileAndSrc, setUnmodifiedConfirmedFileAndSrc] = useState<FileAndSrc>();
  const cropperSrc = unmodifiedConfirmedFileAndSrc?.src || unmodifiedUploadedSrc || transformedUploadedSrc;
  const { getBlockErrorDefinitions } = useContextOrThrow(BroadcastBlockDefinitionRegistryContext);
  const blockErrorDefinitions = useMemo(() => getBlockErrorDefinitions(block), [block, getBlockErrorDefinitions]);
  const { formatValidationTreeWithDefinitions } = useValidationErrors();
  const formattedCaptionBackgroundColourErrors = useMemo(() => formatValidationTreeWithDefinitions(
    validationTree.children.attributes?.children.caption?.children.backgroundColour,
    blockErrorDefinitions.caption.backgroundColour,
  ), [
    blockErrorDefinitions.caption.backgroundColour,
    formatValidationTreeWithDefinitions,
    validationTree.children.attributes?.children.caption?.children.backgroundColour
  ]);
  const formattedUploadErrors = useMemo(() => formatValidationTreeWithDefinitions(
    validationTree.children.attributes?.children.uploadId,
    blockErrorDefinitions.uploadId,
  ), [
    blockErrorDefinitions.uploadId,
    formatValidationTreeWithDefinitions,
    validationTree.children.attributes?.children.uploadId
  ]);

  const whenFileSelected = useCallback((file: File) => {
    const selectedFileAndSrc = {
      file,
      src: URL.createObjectURL(file),
    };

    setUnmodifiedConfirmedFileAndSrc(selectedFileAndSrc);
    upload(block.localId, 'broadcasts/authorise-upload', file);
  }, [block.localId, upload]);

  const whenFileTransformed = useCallback((fileAndSrc: FileAndSrc) => {
    setCropperOpen(false);
    upload(block.localId, 'broadcasts/authorise-upload', fileAndSrc.file);
  }, [block.localId, upload]);

  // When upload complete
  useEffect(() => {
    if (!uploadState?.upload || uploadState.upload.uuid === block.attributes.uploadId) {
      return;
    }

    if (unmodifiedConfirmedFileAndSrc) {
      assign(block.localId, unmodifiedConfirmedFileAndSrc.file);
    }

    onChange({
      ...block,
      attributes: {
        ...block.attributes,
        uploadId: uploadState.upload.uuid,
      },
    });
    onAttachmentsChange({
      ...attachments,
      uploads: [
        ...attachments.uploads,
        uploadState.upload,
      ],
    });
  }, [assign, attachments, block, onAttachmentsChange, onChange, unmodifiedConfirmedFileAndSrc, uploadState?.upload]);

  const whenRemoveClicked = useCallback(() => {
    onAttachmentsChange({
      ...attachments,
      uploads: ArrayHelper.findAndRemove(
        attachments.uploads,
        upload => upload.uuid === block.attributes.uploadId,
      ),
    });
    onChange({
      ...block,
      attributes: {
        ...block.attributes,
        uploadId: '',
      },
    });
    revoke(block.localId);
    remove(block.localId);
  }, [attachments, block, onAttachmentsChange, onChange, remove, revoke]);

  const whenCaptionChanged = useCallback((caption: ImageBlock['attributes']['caption']) => {
    onChange({
      ...block,
      attributes: {
        ...block.attributes,
        caption,
      },
    });
  }, [block, onChange]);

  const whenCaptionTextChanged = useCallback((text: string) => {
    whenCaptionChanged({
      ...block.attributes.caption || {
        backgroundColour: '#E80D6D',
        textColour: '#FFFFFF',
        position: 'bottomCenter',
      },
      text,
    });
  }, [block.attributes.caption, whenCaptionChanged]);

  const whenBackgroundColorChanged = useCallback((backgroundColour: string) => {
    whenCaptionChanged({
      ...block.attributes.caption || {
        position: 'bottomCenter',
        text: '',
      },
      backgroundColour,
      textColour: readableColor(
        backgroundColour,
        (theme as typeof defaultTheme).new.palette.grey[900].main,
        undefined,
        false,
      ),
    });
  }, [block.attributes.caption, theme, whenCaptionChanged]);

  const whenCaptionPositionChanged = useCallback((position: NonNullable<ImageBlock['attributes']['caption']>['position']) => {
    whenCaptionChanged({
      ...block.attributes.caption || {
        text: '',
        backgroundColour: '#E80D6D',
        textColour: '#FFFFFF',
      },
      position,
    });
  }, [block.attributes.caption, whenCaptionChanged]);

  const whenImageSelected = useCallback((selection: ImageAsset | FileAndSrc) => {
    if ((selection as ImageAsset).id) {
      const imageAsset = selection as ImageAsset;
      ImageUrlTransformer.toFile(
        imageAsset.urls.thumbs.l.url,
        imageAsset.fileName,
        'anonymous',
      )
        .then(file => {
          whenFileSelected(file);
        })
        .catch(() => {
          throw {
            code: 'PRE_PROCESSING_FAILED',
            metadata: undefined,
          };
        });
    } else {
      whenFileSelected((selection as FileAndSrc).file);
    }
  }, [whenFileSelected]);

  return (
    <>
      <MinimumInset
        left={ 2 }
        right={ 2 }
      >
        <Form>
          <Stack
            direction="column"
            gap={ 2 }
          >
            <FieldLabel>
              <FormattedMessage
                description="Label for image upload field in image broadcast block form."
                defaultMessage="Image"
              />
            </FieldLabel>
            {
              uploadState
                ? (
                  <>
                    {
                      uploadState.upload
                        ? (
                          <div
                            css={ theme => ({
                              display: 'grid',
                              gridTemplateColumns: '1fr 1fr',
                              gap: theme.new.spacing[2],
                            }) }
                          >
                            <Button
                              variant="secondary"
                              type="button"
                              onClick={ () => setCropperOpen(true) }
                            >
                              <FormattedMessage
                                description="Label for edit image button in image block form"
                                defaultMessage="Edit image"
                              />
                            </Button>
                            <Button
                              variant="secondary"
                              type="button"
                              onClick={ whenRemoveClicked }
                            >
                              <FormattedMessage
                                description="Label for remove upload button in image block form"
                                defaultMessage="Clear image"
                              />
                            </Button>
                          </div>
                        )
                        : uploadState.error
                          ? (
                            <>
                              <LocalisedFileUploadErrors errors={ [uploadState.error] }/>
                              <Button
                                variant="secondary"
                                type="button"
                                onClick={ () => remove(block.localId) }
                              >
                                <FormattedMessage
                                  description="Label for reset upload button in image block form"
                                  defaultMessage="Reset"
                                />
                              </Button>
                            </>
                          )
                          : (
                            <>
                              <ProgressBar
                                progress={ uploadState.progress }
                                showNumericValue={ false }
                              />
                              <Button
                                variant="secondary"
                                type="button"
                                onClick={ () => remove(block.localId) }
                              >
                                <FormattedMessage
                                  description="Label for abort upload button in image block form"
                                  defaultMessage="Cancel"
                                />
                              </Button>
                            </>
                          )
                    }
                  </>
                )
                : (
                  <div
                    css={ theme => ({
                      display: 'grid',
                      gridTemplateColumns: '1fr',
                      gap: theme.new.spacing[2],
                    }) }
                  >
                    <Button
                      variant="secondary"
                      type="button"
                      onClick={ () => setImageLibraryOpen(true) }
                    >
                      <FormattedMessage
                        description="Label for choose image button in image block form"
                        defaultMessage="Choose image"
                      />
                    </Button>
                  </div>
                )
            }
            { !!formattedUploadErrors.length && <BroadcastEditorErrors errors={ formattedUploadErrors }/> }
            <Stack direction="column">
              <FieldLabel>
                <FormattedMessage
                  description="Label for caption text input in image broadcast block"
                  defaultMessage="Caption text"
                />
              </FieldLabel>
              <RoundedTextarea
                value={ block.attributes.caption?.text || '' }
                onChange={ event => whenCaptionTextChanged(event.currentTarget.value) }
                placeholder={ intl.formatMessage({
                  description: 'Placeholder for image block caption input in form sheet.',
                  defaultMessage: 'Add caption…',
                }) }
              />
            </Stack>
            <ColourPickerButton
              value={ block.attributes.caption?.backgroundColour || '#E80D6D' }
              onChange={ whenBackgroundColorChanged }
            />
            { !!formattedCaptionBackgroundColourErrors.length &&
              <BroadcastEditorErrors errors={ formattedCaptionBackgroundColourErrors }/> }
            <Stack direction="column">
              <FieldLabel>
                <FormattedMessage
                  description="Label for caption position select in image broadcast block"
                  defaultMessage="Caption position"
                />
              </FieldLabel>
              <div
                css={ theme => ({
                  height: '90px',
                  overflow: 'hidden',
                  display: 'grid',
                  gridTemplateRows: '1fr 1fr',
                  gridTemplateColumns: '1fr 1fr 1fr',
                  border: theme.new.border.standard,
                  borderRadius: theme.new.borderRadius.standard,
                  boxShadow: theme.new.shadow.xs,

                  'button': {
                    borderRight: '1px solid rgba(0,0,0,0.05)',
                  },

                  'button:nth-child(-n+3)': {
                    borderBottom: '1px solid rgba(0,0,0,0.05)',
                  },

                  'button:nth-child(3n)': {
                    borderRight: '1px solid rgba(0,0,0,0.05)',
                  },

                  'button:nth-child(1)': {
                    borderTopLeftRadius: theme.new.borderRadius.standard,
                  },

                  'button:nth-child(3)': {
                    borderTopRightRadius: theme.new.borderRadius.standard,
                  },

                  'button:nth-child(4)': {
                    borderBottomLeftRadius: theme.new.borderRadius.standard,
                  },

                  'button:nth-child(6)': {
                    borderBottomRightRadius: theme.new.borderRadius.standard,
                  },
                }) }
              >
                <PositionButton
                  label={ intl.formatMessage({
                    description: 'Option for top-left caption position in broadcast editor image block.',
                    defaultMessage: 'Top left',
                  }) }
                  onClick={ () => whenCaptionPositionChanged('topLeft') }
                  active={ block.attributes.caption?.position === 'topLeft' }
                />
                <PositionButton
                  label={ intl.formatMessage({
                    description: 'Option for top-center caption position in broadcast editor image block.',
                    defaultMessage: 'Top center',
                  }) }
                  onClick={ () => whenCaptionPositionChanged('topCenter') }
                  active={ block.attributes.caption?.position === 'topCenter' }
                />
                <PositionButton
                  label={ intl.formatMessage({
                    description: 'Option for top-right caption position in broadcast editor image block.',
                    defaultMessage: 'Top right',
                  }) }
                  onClick={ () => whenCaptionPositionChanged('topRight') }
                  active={ block.attributes.caption?.position === 'topRight' }
                />
                <PositionButton
                  label={ intl.formatMessage({
                    description: 'Option for bottom-left caption position in broadcast editor image block.',
                    defaultMessage: 'Bottom left',
                  }) }
                  onClick={ () => whenCaptionPositionChanged('bottomLeft') }
                  active={ block.attributes.caption?.position === 'bottomLeft' }
                />
                <PositionButton
                  label={ intl.formatMessage({
                    description: 'Option for bottom-center caption position in broadcast editor image block.',
                    defaultMessage: 'Bottom center',
                  }) }
                  onClick={ () => whenCaptionPositionChanged('bottomCenter') }
                  active={ block.attributes.caption?.position === 'bottomCenter' }
                />
                <PositionButton
                  label={ intl.formatMessage({
                    description: 'Option for bottom-right caption position in broadcast editor image block.',
                    defaultMessage: 'Bottom right',
                  }) }
                  onClick={ () => whenCaptionPositionChanged('bottomRight') }
                  active={ block.attributes.caption?.position === 'bottomRight' }
                />
              </div>
            </Stack>
          </Stack>
        </Form>
      </MinimumInset>
      { cropperSrc && (
        <ImageEditSheet
          open={ cropperOpen }
          onOpenChange={ setCropperOpen }
          src={ cropperSrc }
          onSubmit={ whenFileTransformed }
        />
      ) }
      <ImageLibrarySheet
        open={ imageLibraryOpen }
        onOpenChange={ setImageLibraryOpen }
        initialSelectionId={ uploadState?.upload?.libraryId || undefined }
        onSubmit={ whenImageSelected }
        upload={ uploadState?.upload }
        blockId={ block.localId }
      />
    </>
  );
};

type PositionButtonProps = {
  label: string;
  active: boolean;
  onClick: HTMLAttributes<HTMLButtonElement>['onClick'];
  className?: string;
};

const PositionButton: FC<PositionButtonProps> = ({
  label,
  active,
  onClick,
  className
}) => (
  <button
    className={ className }
    onClick={ onClick }
    css={ theme => ({
      '&:before': {
        display: 'block',
        background: 'green',
        width: '100%',
        height: '100%',
      },

      border: 'none',
      margin: 0,
      padding: 0,
      background: 'transparent',
      height: 'calc(100%)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 'calc(100% + 1px)',
      boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0) inset, 0px 0px 0px 0px rgba(0, 0, 0, 0) inset',
      transition: 'color 300ms, box-shadow 300ms, background-color 300ms',

      ...active
        ? {
          color: theme.new.palette.primary[700].main,
          boxShadow: '0px 1px 2px 0px rgba(16,24,40,0.06) inset, 0px 1px 3px 0px rgba(16,24,40,0.1) inset',
          backgroundColor: theme.new.palette.grey[25].main,
        }
        : {},
    }) }
    type="button"
  >
    { label }
  </button>
);
