import { FormattedMessage } from 'react-intl';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'op-storybook/stories/components/Button/Button';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';

import { FormComponent } from '../../../Model/BroadcastBlockDefinition';
import { useFileSelect } from '../../../../../Common/Hook';
import { useContextOrThrow } from '../../../../../Core/Hook';
import { FileUploaderContext } from '../../../../../Common/Context';
import { ArrayHelper } from '../../../../../Common/Utility';
import { MinimumInset } from '../../../../Common/Component/Layout/MinimumInset';
import { VideoBlock } from '../../../Service/Blocks/VideoBlockDefinition';
import { ApiContext } from '../../../../../Contexts';
import { TranscodingProgressContext } from '../../../../Common/Provider/TranscodingProcessProvider';
import { VideoThumbnailSheet } from './VideoThumbnailSheet';
import { VideoProgressBar } from './VideoProgressBar';

export const VideoBlockForm: FormComponent<VideoBlock> = ({
  block,
  validationTree,
  onChange,
  attachments,
  onAttachmentsChange,
}) => {
  const selectFile = useFileSelect('video/*,audio/*');
  const { upload, uploadStates, remove } = useContextOrThrow(FileUploaderContext);
  const uploadState = useMemo(() => {
    return uploadStates.get(block.localId);
  }, [block.localId, uploadStates]);
  const api = useContextOrThrow(ApiContext);
  const [thumbnailSheetOpen, setThumbnailSheetOpen] = useState<boolean>(false);
  const { add, getStatus } = useContextOrThrow(TranscodingProgressContext);
  const progress = (uploadState?.progress || 0);
  const status = useMemo(() => uploadState?.upload ? getStatus([String(uploadState.upload.id)]) : 'complete', [
    getStatus,
    uploadState?.upload
  ]);
  const uploadIsVideo = useMemo(() => {
    const mimeType = uploadState?.upload?.mimeType;

    if (!mimeType) {
      return false;
    }

    return !!/^video\//.exec(mimeType);
  }, [uploadState?.upload?.mimeType]);
  const uploadInProgress = useMemo(() => !!uploadState && (!uploadState.upload || status !== 'complete'), [
    uploadState,
    status
  ]);

  const whenFileSelected = useCallback((file: File) => {
    upload(
      block.localId,
      'broadcasts/authorise-upload',
      file,
    );
  }, [block.localId, upload]);

  const whenUploadClicked = useCallback(() => {
    selectFile(
      event => {
        if (!event.currentTarget.files?.length) {
          return;
        }

        whenFileSelected(event.currentTarget.files[0]);
      },
    );
  }, [selectFile, whenFileSelected]);

  // When upload complete
  useEffect(() => {
    if (!uploadState?.upload || uploadState.upload.uuid === block.attributes.uploadId) {
      return;
    }

    onChange({
      ...block,
      attributes: {
        ...block.attributes,
        uploadId: uploadState.upload.uuid,
      },
    });
    add(String(uploadState.upload.id));

    onAttachmentsChange({
      ...attachments,
      uploads: [
        ...attachments.uploads,
        uploadState.upload,
      ],
    });
  }, [add, api, attachments, block, onAttachmentsChange, onChange, uploadState?.upload]);

  const whenRemoveClicked = useCallback(() => {
    onAttachmentsChange({
      ...attachments,
      uploads: ArrayHelper.findAndRemove(
        attachments.uploads,
        upload => upload.uuid === block.attributes.uploadId,
      ),
    });
    onChange({
      ...block,
      attributes: {
        ...block.attributes,
        uploadId: '',
      },
    });
    remove(block.localId);
  }, [attachments, block, onAttachmentsChange, onChange, remove]);

  const whenThumbnailKeyChanged = useCallback((videoThumbnailKey: string | null) => {
    setThumbnailSheetOpen(false);
    const { videoThumbnailKey: _videoThumbnailKey, ...attributes } = block.attributes;
    onChange({
      ...block,
      attributes: {
        ...attributes,
        ...videoThumbnailKey ? { videoThumbnailKey } : {},
      },
    });
  }, [block, onChange]);

  return (
    <MinimumInset
      left={ 2 }
      right={ 2 }
    >
      <Stack direction="column" gap={ 2 }>
        { uploadInProgress && (
          <>
            <VideoProgressBar progress={ progress }/>
            <Button
              variant="secondary"
              type="button"
              onClick={ () => remove(block.localId) }
              css={ {
                width: '100%',
              } }
            >
              <FormattedMessage
                description="Label for abort upload button in video block form"
                defaultMessage="Cancel"
              />
            </Button>
          </>
        ) }
        {
          uploadState
            ? uploadState.upload && (
            <div>
              <Button
                variant="secondary"
                type="button"
                onClick={ whenRemoveClicked }
                css={ {
                  width: '100%',
                } }
              >
                <FormattedMessage
                  description="Label for remove upload button in video block form"
                  defaultMessage="Remove media"
                />
              </Button>
            </div>
          )
            : (
              <Button
                variant="secondary"
                type="button"
                onClick={ whenUploadClicked }
                css={ {
                  width: '100%',
                } }
              >
                <FormattedMessage
                  description="Label for upload image button in image block form"
                  defaultMessage="Upload"
                />
              </Button>
            )
        }
        { uploadIsVideo && (
          status === 'complete' && uploadState?.upload
            ? (
              <>
                <Button
                  variant="secondary"
                  type="button"
                  onClick={ () => setThumbnailSheetOpen(true) }
                  css={ {
                    width: '100%',
                  } }
                >
                  <FormattedMessage
                    description="Label for select thumbnail button in video block form"
                    defaultMessage="Select thumbnail"
                  />
                </Button>
                <VideoThumbnailSheet
                  videoThumbnailKey={ block.attributes.videoThumbnailKey || null }
                  onSubmit={ whenThumbnailKeyChanged }
                  open={ thumbnailSheetOpen }
                  onOpenChange={ setThumbnailSheetOpen }
                  uploadId={ String(uploadState.upload.id) }
                />
              </>
            )
            : (
              <FormattedMessage
                description="Placeholder for thumbnail selection when video processing is not complete."
                defaultMessage="You can select a thumbnail here when video processing is complete."
              />
            )
        ) }
      </Stack>
    </MinimumInset>
  );
};
