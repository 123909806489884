import styled from '@emotion/styled';

import { Table } from '../../../../Components';
import { StyledTableCell } from '../../../../Components/Content/styles';
import { FilterContainer, HelpTooltip, ProgressiveSelect, ProgressiveSelectProps } from '../../../../Common/Component';
import { StyledAnimatedNumber } from '../../../../Common/Component/AnimatedNumber/style';
import { StyledBox } from '../../../../Common/Component/Box/style';

export const StyledBroadcastSummaryRoot = styled.div`
  display: grid;
  margin-bottom: ${props => props.theme.spacing(2)};
  grid-gap: ${props => props.theme.spacing(2)};
  grid-template:
    [row1-start] "schedule-summary recent-deliveries" [row1-end]
    / auto 70%;

  ${StyledBox} {
    height: 100%;
    margin: 0;
  }

  h2 {
    margin-bottom: ${props => props.theme.spacing(2)};
  }
`;

export const StyledScheduleSummaryRoot = styled.div`
  grid-area: schedule-summary;
`;

export const StyledRecentDeliveriesRoot = styled.div`
  grid-area: recent-deliveries;
`;

export const StyledDeliveryCount = styled.p`
  color: ${props => props.theme.palette.secondary.main};
  font-size: 3rem;
  font-weight: 500;
`;

export const StyledRecentDeliveriesTable = styled(Table)`
  ${StyledTableCell} {
    padding: ${props => props.theme.spacing(1.5)};
  }
`;

export const StyledContentTableContainer = styled.div`
  margin: 1rem 0;
`;

export const StyledInvalidNumber = styled.div`
  color: ${props => props.theme.palette.grey[500]};
  font-size: 4rem;
  line-height: 1;
  font-weight: 500;
  margin-top: 0.5rem;
`;

interface StyledDeliveryReportTabProps {
  lowLight?: boolean;
  active: boolean;
}

export const StyledTooltip = styled(HelpTooltip)`
  color: ${ props => props.theme.palette.grey[500] };
`;

export const StyledDeliveryReportTab = styled.button<StyledDeliveryReportTabProps>`
  display: grid;
  border: none;
  background: inherit;
  grid-template-rows: auto 1fr;
  row-gap: 0.5rem;
  padding: 1rem;
  cursor: default;
  user-select: none;
  text-align: left;
  transition: background-color 100ms;

  &:focus {
    outline: none;
  }

  &:active {
    background-color: ${props => props.theme.palette.grey[200]};
  }

  ${props => (
    props.active
      ? `background-color: ${props.theme.palette.grey[100]};`
      : `
        &:focus,
        &:hover {
          background-color: ${props.theme.palette.grey[50]};
        }
      `
  )}

  ${StyledAnimatedNumber} {
    ${ props => props.lowLight && `color: ${props.theme.palette.secondary.light};` }
    font-size: 2.5rem;
    margin: 0;
  }

  ${StyledInvalidNumber} {
    color: ${props => props.theme.palette.secondary.light};
    font-size: 2.5rem;
    margin: 0;
  }
`;

export const DeliveryReportTabLabelRow = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 1rem;
`;

export const StyledDeliveryReportTabs = styled.div`
  display: flex;
  flex-flow: row wrap;

  ${StyledDeliveryReportTab} {
    flex: 1 1 0;

    border-top: 1px solid ${props => props.theme.palette.grey[300]};
    min-width: 10vw;

    &:not(:last-child) {
      border-right: 1px solid ${props => props.theme.palette.grey[300]};
    }
  }
`;

export const StyledDeliverySelectionRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledDeliveryPicker = styled.div`
  display: flex;
  align-items: center;

  .MuiSelect-root {
    background-color: ${ props => props.theme.palette.background.paper };
  }
`;

export const StyledProgressiveSelect = styled(ProgressiveSelect)`
  .MuiList-root {
    max-height: 400px;
  }
` as <T>(props: ProgressiveSelectProps<T>) => JSX.Element;

export const StyledDeliverySelectionLabel = styled.div`
  padding: 1rem 1rem 1rem 0;
`;

export const StyledResponseTable = styled.div`
  .MuiTableCell-root {
    padding: 0.5rem 2rem;
  }

  ${FilterContainer} {
    border-left: none;
    border-right: none;
    border-radius: 0;
    padding: 0.75rem 1rem;
    border-bottom: 1px solid ${props => props.theme.palette.grey[300]};
  }
`;
