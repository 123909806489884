import { ComponentProps, FC, RefObject, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { PrimaryInformation } from 'op-storybook/stories/components/Typography/PrimaryInformation';
import { SecondaryInformation } from 'op-storybook/stories/components/Typography/SecondaryInformation';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { LineClamp } from 'op-storybook/stories/components/LineClamp/LineClamp';
import { PresentationIcon } from 'op-storybook/lib/components/PresentationIcon/PresentationIcon';
import FolderIcon from 'op-storybook/lib/assets/icon/figma/folder.svg';
import FileIcon from 'op-storybook/lib/assets/icon/figma/file-06.svg';

import { useContextOrThrow } from '../../../../../Core/Hook';
import { ApiContext } from '../../../../../Contexts';
import { ComboBox } from '../../../../Common/Component/Input/ComboBox';
import { Paginated } from '../../../../../Models';
import { NodeAndAncestors } from '../../../../../Files/Model';

type Props = {
  value: NodeAndAncestors | null;
  onChange: (value: NodeAndAncestors) => void;
  containerRef?: RefObject<HTMLElement>;
};

export const StorageNodeCombobox: FC<Props> = ({
  value,
  onChange,
  containerRef,
}) => {
  const api = useContextOrThrow(ApiContext);
  const intl = useIntl();

  const queryFn: ComponentProps<typeof ComboBox<NodeAndAncestors>>['queryFn'] = useCallback(async (filters) => {
    const response = await api.get<Paginated<'items', NodeAndAncestors>>('/files/nodes', filters.search ? { params: { search: filters.search } } : undefined);

    return response.data;
  }, [api]);

  return (
    <ComboBox
      queryKey={ [queryKey] }
      queryFn={ queryFn }
      renderItemContents={ nodeAndAncestors => (
        <Stack align="flex-start">
          <PresentationIcon
            size={ 5 }
            IconComponent={
              nodeAndAncestors.node.type === 'folder'
                ? FolderIcon
                : FileIcon
            }
          />
          <Stack
            direction="column"
            align="flex-start"
          >
            <PrimaryInformation>
              <LineClamp lines={ 1 }>
                { nodeAndAncestors.node.name }
              </LineClamp>
            </PrimaryInformation>
            { nodeAndAncestors.ancestors.length > 1 && (
              <SecondaryInformation css={ { textAlign: 'left' } }>
                <LineClamp lines={ 1 }>
                  {
                    nodeAndAncestors.ancestors
                      .slice(0, -1)
                      .reverse()
                      .map(ancestor => ancestor.name)
                      .join('/')
                  }
                </LineClamp>
              </SecondaryInformation>
            ) }
          </Stack>
        </Stack>
      ) }
      value={ value }
      onChange={ onChange }
      getItemLabel={ nodeAndAncestors => nodeAndAncestors.node.name }
      itemsAreEquivalent={ (nodeAndAncestorsA, nodeAndAncestorsB) => nodeAndAncestorsA.node.id === nodeAndAncestorsB.node.id }
      label={ intl.formatMessage({
        description: 'Label for file autocomplete',
        defaultMessage: 'File',
      }) }
      placeholder={ intl.formatMessage({
        description: 'Placeholder for file autocomplete',
        defaultMessage: 'Search for file…',
      }) }
      containerRef={ containerRef }
    />
  );
};

const queryKey = 'my-files';
