import { FunctionComponent, MouseEvent, ReactNode } from 'react';

import {
  StyledButton
} from './styles';

interface ButtonProps {
  type?: 'button' | 'submit' | 'reset';
  autoFocus?: boolean;
  bordered?: boolean;
  primary?: boolean;
  disabled?: boolean;
  onClick?: (event: MouseEvent) => void;
  children?: ReactNode;
}

/** @deprecated Prefer Button **/
export const DeprecatedButton: FunctionComponent<ButtonProps> = (props) => {
  const {
    autoFocus,
    children,
    bordered = true,
    primary,
    disabled,
    onClick,
    type
  } = props;

  const localOnClick = (event: MouseEvent): void => {
    if (!disabled && onClick && typeof onClick === 'function') {
      onClick(event);
    }
  };

  return (
    <StyledButton
      autoFocus={autoFocus || false}
      type={type || 'button'}
      disabled={disabled || false}
      primary={primary ? 'true' : null}
      bordered={bordered ? 'true' : null}
      onClick={localOnClick}
      >
        {children}
    </StyledButton>
  );
}
