import React, {FC, useMemo} from 'react';

import {IconButton} from '..';
import {StyledContextMenu} from './style';
import {ContextMenu, ContextMenuAction} from '../ContextMenu/ContextMenu';
import MoreVertIcon from '../../../Assets/img/icons/monochrome/more-vertical.svg';

type Props = {
  id: string;
  actions: ContextMenuAction[];
  inlineActionCount?: number;
  hideInlineActionsInMenu?: boolean;
};

export const TableRowContextMenu: FC<Props> = ({
  id,
  actions,
  inlineActionCount = 2,
  hideInlineActionsInMenu = false,
}) => {
  const inlineActions = useMemo(() => actions.slice(0, inlineActionCount), [actions, inlineActionCount]);
  const menuActions = useMemo(() => actions.slice(inlineActionCount, actions.length), [actions, inlineActionCount]);

  return <>
    <StyledContextMenu>
      { inlineActions.map(action => (
        <IconButton
          key={ action.id }
          showTooltip
          { ...action }
          color="secondary.main"
          size="small"
        />
      )) }
      { !!(hideInlineActionsInMenu ? menuActions : actions).length && (
        <ContextMenu
          id={id}
          actions={hideInlineActionsInMenu ? menuActions : actions}
          IconComponent={MoreVertIcon}
          iconColor="secondary.main"
        />
      ) }
    </StyledContextMenu>
  </>;
};
