import styled from '@emotion/styled';

export const StyledCategoryForm = styled.div`
  padding: 1rem;

  width: max(50%, min(40rem, 100%));

  h4 {
    margin-bottom: 0.75rem;
  }
`;
