import { IntlShape } from 'react-intl';

import { DateRequest, DraftDateRequest } from '../../Model/ContentTypes';
import { DateRequestFields, DateRequestPreview } from '../../Component/Content';
import { ValidationTree } from '../../../Common/Model';
import { RequestValidator } from '../../Utility/Validation';
import { SingleContentCard, EditorContent } from '../../../Content/Model';
import DateRequestIcon from '../../../Assets/img/icons/monochrome/content/date.svg';
import { UniqueIdGenerator } from '../../../Common/Utility';
import { CardFactory, CardTransformer } from '../../../Content/Utility';
import { DraftResponse, FormContentDefinition, Response } from '../../Model';
import { EnvironmentSettings } from '../../../Models';
import { ScheduledDate } from '../../../Models/ScheduledDate';
import { LiveDateRequest } from '../../Component/LiveContent/LiveDateRequest/LiveDateRequest';
import { SubmittedDateRequest } from '../../Component/SubmittedContent';

export type DateRequestEditorContent = EditorContent<DraftDateRequest>;

export type DraftDateResponse = DraftResponse<{
  contentType: 'dateRequest';
  date: string;
  timezone: string;
}>;
export type DateResponse = Response<{
  contentType: 'dateRequest';
  date: ScheduledDate;
}>;

export class DateRequestDefinition implements FormContentDefinition<
  DateRequest,
  DateRequestEditorContent,
  DraftDateResponse,
  DateResponse
> {
  public readonly id = 'dateRequest';
  public readonly availableToCreate = true;
  public readonly new = false;
  public readonly richText = false;
  public readonly contentTypes = ['dateRequest'];
  public readonly EditorComponent = DateRequestFields;
  public readonly PreviewComponent = DateRequestPreview;
  public readonly LiveComponent = LiveDateRequest;
  public readonly SubmittedComponent = SubmittedDateRequest;
  public readonly IconComponent = DateRequestIcon;
  public readonly categoryId = 'input';

  public definesContentType = (type: string): boolean => (
    this.contentTypes.includes(type)
  );

  public createEditorContent = (): DateRequestEditorContent => ({
    id: UniqueIdGenerator.generate(),
    card: CardFactory.fromDraftContent({
      type: 'dateRequest',
      title: '',
      text: null,
      includeTime: true,
    }),
  });

  public getContentTitle = (intl: IntlShape, content: DateRequest): string => (
    content.title || this.getDefaultTitle(intl)
  );

  public getDraftContentTitle = (intl: IntlShape, draftContent: DraftDateRequest): string => (
    draftContent.title || this.getDefaultTitle(intl)
  );

  public hasRequiredFeatureFlags = (environmentSettings: EnvironmentSettings): boolean => (
    !!environmentSettings.formsEnabled
  );

  public getLocalisedType = (intl: IntlShape): string => intl.formatMessage({
    id: 'forms.content.dateRequest.type',
    description: 'Localised name for date request type.',
    defaultMessage: 'Date',
  });

  public transformCard = (card: SingleContentCard<DateRequest>): DateRequestEditorContent => ({
    id: card.id,
    card: CardTransformer.transformCard(card, card.content),
  });

  public validate = (draftContent: DraftDateRequest): ValidationTree<DraftDateRequest> =>
    RequestValidator.validate(draftContent);

  private getDefaultTitle = (intl: IntlShape): string => intl.formatMessage({
    id: 'forms.content.dateRequest.defaultTitle',
    description: 'Default title for date request.',
    defaultMessage: 'Untitled date',
  });
}
