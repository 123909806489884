import { Component, FunctionComponent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { CenteredConfirmationDialog } from '../../../../Components/Dialog';

type Props = {
  container?: Element | Component | (() => Element | Component | null) | null;
  open: boolean;
  onDismissed: () => void;
  audienceCount: number;
}

export const ImmediateBroadcastPublishedConfirmationDialog: FunctionComponent<Props> = ({
  open,
  onDismissed,
  audienceCount,
  ...other
}) => {
  const intl = useIntl();

  return (
    <CenteredConfirmationDialog
      heading={<>&#128079;</>}
      cta={
        intl.formatMessage({
          id: 'deliveryConfirmation.now.cta',
          defaultMessage: 'Go to recent broadcasts',
        })
      }
      open={open}
      onConfirmed={onDismissed}
      onDismissed={onDismissed}
      {...other}
    >
      <FormattedMessage
        id="deliveryConfirmation.now.body"
        defaultMessage="Your broadcast is now being sent to <b>{audienceCount, plural, =0 {nobody} one {# person} other {# people}}</b>."
        values={{
          audienceCount,
        }}
      />
    </CenteredConfirmationDialog>
  )
}
