import { IntlShape } from 'react-intl';

import { Audience, AudienceConditionDefinition, AudienceContents, ConditionContentsSummary } from '../../Model';

/** @deprecated Used in old audience builder */
export class AudienceConditionDefinitionRegistry {
  constructor(
    public readonly definitions: AudienceConditionDefinition[],
  ) {}

  public audienceContentsSummary(
    intl: IntlShape,
    audience: Audience,
    audienceContents: AudienceContents,
  ): string {
    const conditionSummaries: ConditionContentsSummary[][] = audience.segments.map(
      (segment, segmentIndex) => segment.conditions.map(
        (condition, conditionIndex) => {
          const conditionContents = audienceContents.segments[segmentIndex]?.conditions[conditionIndex]?.contents;
          const conditionDefinition = this.getConditionDefinition(condition.type);
          return conditionDefinition.contentsSummary(intl, condition, conditionContents ?? null);
        }
      )
    );

    const segmentSummaries: string[] = conditionSummaries
      .map(
        (segmentConditionSummaries) => {
          if (segmentConditionSummaries.length === 1) {
            return segmentConditionSummaries[0].soloSummary ?? '';
          }

          const exclusiveSegmentConditionSummaries = segmentConditionSummaries
            .filter((conditionSummary) => conditionSummary.exclusive);

          return (
            exclusiveSegmentConditionSummaries.length > 0
              ? exclusiveSegmentConditionSummaries
              : segmentConditionSummaries
          )
            .map((conditionSummary) => conditionSummary.andSummary)
            .filter((andSummary) => !!andSummary)
            .join(', ');
        }
      )
      .filter((segmentSummary) => !!segmentSummary);

    return segmentSummaries.length > 0
      ? segmentSummaries.join(
        ' ' + intl.formatMessage({
          id: 'audience.conditionDefinition.audienceContentsSummary.segmentGlue',
          defaultMessage: 'or',
        }) + ' '
      )
      : intl.formatMessage({
        id: 'audience.conditionDefinition.audienceContentsSummary.unknown',
        defaultMessage: 'Unknown audience',
      });
  }

  public getConditionDefinition = (conditionType: string): AudienceConditionDefinition => {
    const definition = this.definitions.find(definition => definition.definesConditionType(conditionType));

    if (!definition) {
      throw new Error(`Audience condition definition not registered. ${ conditionType }`);
    }

    return definition;
  };
}
