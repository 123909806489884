import { ComponentProps, FC } from 'react';

import { PersonUuidAvatar } from '../PersonUuidAvatar/PersonUuidAvatar';
import { MinimalPerson } from '../../../Models';
import { PersonParser } from '../../../Utility';

type Props = {
  person: MinimalPerson | null;
} & Omit<ComponentProps<typeof PersonUuidAvatar>, 'personUuid' | 'personName' | 'versionString'>;

export const PersonAvatar: FC<Props> = ({
  person,
  ...avatarProps
}) => {
  const waitForSrc = !person || !!person.avatar;

  return (
    <PersonUuidAvatar
      personUuid={ person ? person.uuid : null }
      personName={ person ? PersonParser.fullName(person) : null }
      waitForSrc={ waitForSrc }
      versionString={ person?.avatar?.uploadId }
      { ...avatarProps }
    />
  );
}