import qs from 'qs';

import { FetchResponse, useGet } from '../../Hooks';
import { Paginated } from '../../Models';
import { ListedBroadcast } from '../Model';

export type FetchBroadcastsParams = {
  pageNum: number;
  pageSize?: number;
  sort?: string;
  noDrafts?: 1;
  noArchived?: 1;
  noUnarchived?: 1;
  noScheduled?: 1;
  noPublished?: 1;
  noDelivered?: 1;
  noFinished?: 1;
  search?: string | null;
  ids?: string | null;
  tagIds?: string | null;
  createdByIds?: string | null;
  contentTypes?: string | null;
  types?: string | null;
}

export const useFetchBroadcasts = (params: FetchBroadcastsParams): FetchResponse<Paginated<'broadcasts', ListedBroadcast>> => {
  return useGet('/broadcasts', params, qs.stringify);
};
