import { FunctionComponent, useCallback } from 'react';

import { RequestFields } from '..';
import { ContentEditorProps } from '../../../../Content/Model';
import { ValidationTree } from '../../../../Common/Model';
import { DraftFormRequest } from '../../../Model';
import { GeolocationRequestEditorContent } from '../../../Service';

export const GeolocationRequestFields: FunctionComponent<ContentEditorProps<GeolocationRequestEditorContent>> = ({
  editorContent,
  onEditorContentChanged,
  validation,
  onValidationChanged,
}) => {
  const whenContentValidationChanged = useCallback(
    (contentValidation: ValidationTree<DraftFormRequest>): void => {
      onValidationChanged({
        errors: validation?.errors || [],
        children: {
          ...validation?.children,
          content: contentValidation,
        },
      });
    },
    [validation, onValidationChanged],
  );

  return (
    <RequestFields
      editorContent={ editorContent }
      onEditorContentChanged={ onEditorContentChanged }
      validation={ validation }
      onValidationChanged={ whenContentValidationChanged }
    />
  );
}
