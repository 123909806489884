import { useCallback, useMemo } from 'react';
import { readableColor } from 'polished';
import { ThemeContext } from '@emotion/react';
import { defaultTheme } from 'op-storybook';
import { TextField } from 'op-storybook/stories/components/TextField/TextField';
import { Form } from 'op-storybook/lib/components/Form/Form';
import { useIntl } from 'react-intl';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { useValidationErrors } from 'op-storybook/lib/hooks/useValidationErrors';

import { FormComponent } from '../../../Model/BroadcastBlockDefinition';
import { ButtonBlock } from '../../../Service/Blocks/ButtonBlockDefinition';
import { useContextOrThrow } from '../../../../../Core/Hook';
import { MinimumInset } from '../../../../Common/Component/Layout/MinimumInset';
import { ColourPickerButton } from '../../../../Common/Component/Input/ColourPickerButton';
import { BroadcastBlockDefinitionRegistryContext } from '../../../Context/BroadcastBlockDefinitionRegistry';

export const ButtonBlockForm: FormComponent<ButtonBlock> = ({
  block,
  validationTree,
  onChange,
}) => {
  const intl = useIntl();
  const theme = useContextOrThrow(ThemeContext);
  const { getBlockErrorDefinitions } = useContextOrThrow(BroadcastBlockDefinitionRegistryContext);
  const blockErrorDefinitions = useMemo(() => getBlockErrorDefinitions(block), [block, getBlockErrorDefinitions]);
  const { formatValidationTreeWithDefinitions } = useValidationErrors();
  const formattedLabelErrors = useMemo(() => formatValidationTreeWithDefinitions(
    validationTree.children.attributes?.children.label,
    blockErrorDefinitions.label,
  ), [blockErrorDefinitions.label, formatValidationTreeWithDefinitions, validationTree.children.attributes?.children.label]);
  const formattedUrlErrors = useMemo(() => formatValidationTreeWithDefinitions(
    validationTree.children.attributes?.children.url,
    blockErrorDefinitions.url,
  ), [blockErrorDefinitions.url, formatValidationTreeWithDefinitions, validationTree.children.attributes?.children.url]);

  const whenLabelChanged = useCallback((label: string) => {
    onChange({
      ...block,
      attributes: {
        ...block.attributes,
        label,
      },
    })
  }, [block, onChange]);

  const whenColorChanged = useCallback((color: string) => {
    onChange({
      ...block,
      attributes: {
        ...block.attributes,
        backgroundColour: color,
        textColour: readableColor(
          color,
          (theme as typeof defaultTheme).new.palette.grey[900].main,
          undefined,
          false,
        ),
      },
    })
  }, [block, onChange, theme]);

  const whenUrlChanged = useCallback((url: string) => {
    onChange({
      ...block,
      attributes: {
        ...block.attributes,
        url,
      },
    })
  }, [block, onChange]);

  return (
    <MinimumInset
      left={ 2 }
      right={ 2 }
    >
      <Form>
        <Stack
          direction="column"
          gap={ 2 }
        >
          <TextField
            value={ block.attributes.label }
            onChange={ value => whenLabelChanged(value) }
            label={ intl.formatMessage({
              description: 'Label for broadcast button block label input.',
              defaultMessage: 'Label',
            }) }
            errorMessages={ formattedLabelErrors }
            placeholder={ intl.formatMessage({
              description: 'Placeholder for broadcast button block label input.',
              defaultMessage: 'Add label…',
            }) }
          />
          <TextField
            value={ block.attributes.url }
            onChange={ value => whenUrlChanged(value) }
            label={ intl.formatMessage({
              description: 'Label for broadcast button block link input.',
              defaultMessage: 'Link',
            }) }
            placeholder={ intl.formatMessage({
              description: 'Placeholder for broadcast button block label input.',
              defaultMessage: 'Add link…',
            }) }
            errorMessages={ formattedUrlErrors }
            trailingButton={{
              content: intl.formatMessage({
                description: 'Label for button to open the link in a new tab.',
                defaultMessage: 'Visit',
              }),
              onClick: () => { window.open(block.attributes.url, '_blank') },
            }}
          />
          <ColourPickerButton
            value={ block.attributes.backgroundColour ?? '#E80D6D' }
            onChange={ whenColorChanged }
          />
        </Stack>
      </Form>
    </MinimumInset>
  );
};
