import { Form as RadixForm } from 'op-storybook/lib/components/Form/Form';
import { useCallback, useMemo, useRef } from 'react';

import { FormComponent } from '../../../Model/BroadcastBlockDefinition';
import { StorageNodeCombobox } from './StorageNodeCombobox';
import { StorageNodeBlock } from '../../../Service/Blocks/StorageNodeBlockDefinition';
import { NodeAndAncestors } from '../../../../../Files/Model';
import { ArrayHelper } from '../../../../../Common/Utility';

export const StorageNodeBlockForm: FormComponent<StorageNodeBlock> = ({
  block,
  onChange,
  attachments,
  onAttachmentsChange,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const nodeAndAncestors = useMemo<NodeAndAncestors | null>(() => {
    if (!block.attributes.nodeId) {
      return null;
    }

    return attachments.storageNodes.find(nodeAndAncestors => nodeAndAncestors.node.id === block.attributes.nodeId) || null;
  }, [attachments.storageNodes, block.attributes.nodeId]);

  const whenNodeChanged = useCallback((newNodeAndAncestors: NodeAndAncestors) => {
    onChange({
      ...block,
      attributes: {
        ...block.attributes,
        nodeId: newNodeAndAncestors.node.id,
      },
    });

    onAttachmentsChange({
      ...attachments,
      storageNodes: ArrayHelper.findAndReplace(
        attachments.storageNodes,
          nodeAndAncestors => nodeAndAncestors.node.id === newNodeAndAncestors.node.id,
        newNodeAndAncestors,
        true,
      ),
    })
  }, [attachments, block, onAttachmentsChange, onChange]);

  return (
    <div
      css={ theme => ({
        padding: `0 ${ theme.new.spacing[2] }`,
      }) }
      ref={ containerRef }
    >
      <RadixForm>
        <StorageNodeCombobox
          value={ nodeAndAncestors }
          onChange={ whenNodeChanged }
          containerRef={ containerRef }
        />
      </RadixForm>
    </div>
  );
};
