import { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { Metric } from '../../../lib/components/Metric/Metric';
import { Icon } from '../../../lib/components/Icon/Icon';
import UpwardTrendIcon from '../../../lib/assets/icon/figma/trend-up-01.svg?react';
import DownwardTrendIcon from '../../../lib/assets/icon/figma/trend-down-01.svg?react';
import ArrowRightIcon from '../../../lib/assets/icon/figma/arrow-right.svg?react';
import { PaletteLookup } from '../../../lib/model/PaletteLookup/PaletteLookup';
import { Stack } from '../../../lib/components/Stack/Stack';
import { Badge } from '../Badge/Badge';

type Props = {
  label: string;
  value: number;
  previousValue?: number;
};

export const PercentageChangeMetric: FC<Props> = ({
  label,
  value,
  previousValue,
}) => {
  const intl = useIntl();
  const fraction = value / (previousValue || 1);
  const percentChange = Math.floor(fraction < 1 ? (1 - fraction) * 100 : (fraction * 100) - 100);
  const trendIcon = useMemo(() => (
    previousValue === value
      ? (
        <Icon
          size={ 4 }
          label={ intl.formatMessage({
            description: 'Label for no change in trend icon in percentage change metric.',
            defaultMessage: 'No change in trend',
          }) }
          IconComponent={ ArrowRightIcon }
          palette={ {
            colour: 'teal',
            intensity: 700,
          } }
        />
      )
      : fraction >= 1
        ? (
          <Icon
            size={ 4 }
            label={ intl.formatMessage({
              description: 'Label for upward trend icon in percentage change metric.',
              defaultMessage: 'Upward trend',
            }) }
            IconComponent={ UpwardTrendIcon }
            palette={ upwardTrendPalette }
          />
        )
        : (
          <Icon
            size={ 4 }
            label={ intl.formatMessage({
              description: 'Label for downward trend icon in percentage change metric.',
              defaultMessage: 'Downward trend',
            }) }
            IconComponent={ DownwardTrendIcon }
            palette={ downwardTrendPalette }
          />
        )
  ), [previousValue, value, fraction]);

  const iconWithPercentageChange = useMemo(() => intl.formatMessage({
    description: 'Text used to indicate percentage change of a metric over time, with an icon representing the trend.',
    defaultMessage: '{ icon }{ percentChange }%',
  }, {
    icon: trendIcon,
    percentChange,
  }), [percentChange, intl]);

  return (
    <Metric
      label={ label }
      value={ value }
      {
        ...previousValue !== undefined
          ? {
            change: (
              <Badge
                variant="pill-colour"
                colour={ value === previousValue ? 'teal' : fraction > 1 ? 'success' : 'error' }
                label={
                  <Stack gap={ 0 }>
                    {
                      value === previousValue || previousValue === 0
                        ? trendIcon
                        : iconWithPercentageChange
                    }
                  </Stack>
                }
              />
            )
          }
          : {}
      }
    />
  );
};

const upwardTrendPalette: PaletteLookup = {
  colour: 'success',
  intensity: 700,
};

const downwardTrendPalette: PaletteLookup = {
  colour: 'error',
  intensity: 700,
};
