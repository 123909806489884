import styled from '@emotion/styled';

export const StyledPlayButton = styled.div`
  border-radius: 0.25rem;
  padding: ${ props => `${ props.theme.spacing(0.25) } ${ props.theme.spacing(0.5) }` };
  background-color: ${ props => props.theme.palette.primary.main };
  color: ${ props => props.theme.palette.primary.contrastText };
  display: flex;
  flex-wrap: nowrap;
  gap: ${ props => props.theme.spacing(1) };
  align-items: center;
`;
