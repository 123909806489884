import { FC, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { MappedSettingTypes } from '../../../Types';
import { IconButton, ImageUploadWithLoadingIndicator } from '../../../../../../Common/Component';
import { StyledImageContainer, StyledImagePlaceholder } from './style';
import { Upload } from '../../../../../../Types';
import { useApi } from '../../../../../../Core/Hook';
import { useMounted } from '../../../../../../Common/Hook';
import ReloadIcon from '../../../../../../Assets/img/icons/streamline/button-refresh-arrow.svg';

type Props = {
  value: MappedSettingTypes['image']['value'],
};

export const ImageSettingValue: FC<Props> = ({
  value,
}) => {
  const intl = useIntl();
  const api = useApi();
  const [transformedUpload, setTransformedUpload] = useState<Upload>();
  const [error, setError] = useState<boolean>(false);
  const mounted = useMounted();

  const reloadImage = useCallback(() => {
    if (!value || typeof value === 'string' || !value.uuid) {
      setTransformedUpload(undefined);
      return;
    }

    setError(false);
    api.get<Upload>(`/uploads/${ value.uuid }`)
      .then((response) => {
        if (!mounted.current) {
          return;
        }

        setTransformedUpload(response.data);
      })
      .catch(() => {
        if (!mounted.current) {
          return;
        }

        setError(true);
      });
  }, [api, mounted, value]);

  useEffect(() => {
    reloadImage();
  }, [api, mounted, reloadImage, value]);

  return (
    <StyledImageContainer fit="contain">
      {
        !error
          ? (
            typeof value === 'string'
              ? (
                <img
                  src={ value }
                  alt={ intl.formatMessage({
                    description: 'Alt text for uploaded image setting',
                    defaultMessage: 'User image',
                  }) }
                />
              )
              : (
                transformedUpload
                  ? (
                    <ImageUploadWithLoadingIndicator
                      upload={ transformedUpload }
                    />
                  )
                  : <StyledImagePlaceholder/>
              )
          )
          : (
            <IconButton
              IconComponent={ ReloadIcon }
              label={ intl.formatMessage({
                description: 'Label for reload button when image setting value fails to load.',
                defaultMessage: 'Reload image',
              }) }
              onClick={ reloadImage }
              size="small"
            />
          )
      }
    </StyledImageContainer>
  );
};
