import { ComponentProps, Dispatch, FC, SetStateAction, useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { ContentEditor } from '../../../Content/Component';
import { DraftSingleContentCard, EditorContent } from '../../../Content/Model';
import { ArrayHelper } from '../../../Common/Utility';
import { ValidationTree } from '../../../Common/Model';
import { useContentDefinitionRegistry } from '../../../Content/Hook';
import { BroadcastContentPreview, BroadcastEditorContentMenu, DefinitionCategoryConfig } from '..';
import { StyledBroadcastEditor } from './style';
import {
  BROADCAST_CONTENT_MAX_COUNT,
  TIMELINE_TEXT_MAX_LENGTH,
  TimelineDefinition,
  TimelineEditorContent
} from '../../Service';
import { SpaceThemeProvider } from '../../Provider';
import { useLocalStorageHookContext } from '../../../Common/Hook';

type State = {
  hideTemplates?: boolean;
};

type Props = {
  contents: EditorContent[];
  setContents: Dispatch<SetStateAction<EditorContent[]>>;
  validation?: ValidationTree<DraftSingleContentCard[]>;
  onValidationChanged: (validation: ValidationTree<DraftSingleContentCard[]>) => void;
  eventCategoryConfig?: DefinitionCategoryConfig;
  broadcastTitle: string;
  preview?: ComponentProps<typeof ContentEditor>['preview'];
};

export const BroadcastEditor: FC<Props> = ({
  contents,
  setContents,
  validation,
  onValidationChanged,
  eventCategoryConfig,
  broadcastTitle,
  preview,
}) => {
  const { validate: validateContent } = useContentDefinitionRegistry();
  const location = useLocation<State>();
  const getStorageHook = useLocalStorageHookContext();
  const useShowModalWhenCreating = getStorageHook<boolean>('templates.showModalWhenCreating', false);
  const [showModalWhenCreating] = useShowModalWhenCreating();
  const [activeContentIndex, setActiveContentIndex] = useState(0);

  const whenContentAdded = useCallback((content: EditorContent, atIndex: number) => {
    const newContents = contents.length === 1
      ? [
        createTimelineEditorContentWithTitle(broadcastTitle),
        ...contents.slice(0, atIndex),
        content,
        ...contents.slice(atIndex, contents.length),
      ]
      : ArrayHelper.insert(contents, atIndex, content);
    const newActiveContentIndex = contents.length === 1 ? atIndex + 1 : atIndex;
    setContents(newContents);
    onValidationChanged({
      errors: [],
      children: newContents.map(
        (content, contentIndex) => contentIndex === newActiveContentIndex
          ? {errors: [], children: {}}
          : validateContent(content),
      ),
    });
    setActiveContentIndex(newActiveContentIndex);
  }, [
    broadcastTitle,
    contents,
    onValidationChanged,
    setContents,
    setActiveContentIndex,
    validateContent,
  ]);

  const whenContentRemoved = useCallback(
    (atIndex: number) => {
      const removeTimelineContent = contents.length === 3;
      const newContents = removeTimelineContent
        ? ArrayHelper.remove(contents.slice(1), atIndex - 1)
        : ArrayHelper.remove(contents, atIndex);
      setContents(newContents);
      if (activeContentIndex > atIndex) {
        setActiveContentIndex(Math.min(Math.max(0, activeContentIndex - 1), newContents.length - 1));
      } else {
        setActiveContentIndex(Math.min(activeContentIndex, newContents.length - 1));
      }
      onValidationChanged({
        errors: validation?.errors || [],
        children: ArrayHelper.remove(
          removeTimelineContent
            ? Object.values(validation?.children || {}).slice(1)
            : Object.values(validation?.children || {}),
          removeTimelineContent ? atIndex - 1 : atIndex,
        ),
      });
    },
    [
      contents,
      onValidationChanged,
      setContents,
      validation?.children,
      validation?.errors,
      activeContentIndex,
      setActiveContentIndex,
    ]
  );

  const whenIndexMoved = useCallback((sourceIndex: number, destinationIndex: number) => {
    setContents(contents => ArrayHelper.move(contents, sourceIndex, destinationIndex));
    onValidationChanged({
      errors: validation?.errors || [],
      children: ArrayHelper.move(
        Object.values(validation?.children || {}),
        sourceIndex,
        destinationIndex,
      ),
    });
    if (activeContentIndex === sourceIndex) {
      setActiveContentIndex(destinationIndex);
    } else if (sourceIndex < activeContentIndex && destinationIndex >= activeContentIndex) {
      setActiveContentIndex(activeCardIndex => activeCardIndex - 1);
    } else if (sourceIndex > activeContentIndex && destinationIndex <= activeContentIndex) {
      setActiveContentIndex(activeCardIndex => activeCardIndex + 1);
    }
  }, [activeContentIndex, onValidationChanged, setContents, validation?.children, validation?.errors]);

  const whenContentUpdated = (content: EditorContent, atIndex: number) => {
    setContents(contents => ArrayHelper.replace(contents, atIndex, content));
  };

  const whenContentSelected = useCallback(
    (selectedIndex: number) => {
      setActiveContentIndex(selectedIndex);

      const previouslyActiveContent = contents[activeContentIndex];
      if (!previouslyActiveContent) {
        return;
      }
      onValidationChanged({
        errors: validation?.errors || [],
        children: ArrayHelper.replace(
          Object.values(validation?.children || {}),
          activeContentIndex,
          validateContent(previouslyActiveContent),
        ),
      });
    },
    [setActiveContentIndex, onValidationChanged, activeContentIndex, validation, contents, validateContent],
  );

  const lockFirstIndex = useMemo(() => {
    const timelineDefinition = new TimelineDefinition();
    return timelineDefinition.definesContentType(contents[0]?.card.content.type || '');
  }, [contents]);

  const contentMenuInitiallyOpen = useMemo(() => (
    !location.state?.hideTemplates ? !showModalWhenCreating && !contents.length : !contents.length
  ), [contents.length, location.state?.hideTemplates, showModalWhenCreating]);

  return (
    <StyledBroadcastEditor>
      <ContentEditor
        activeContentIndex={ activeContentIndex }
        editorContents={ contents }
        onContentAdded={ whenContentAdded }
        onContentUpdated={ whenContentUpdated }
        onContentMoved={ whenIndexMoved }
        lockFirstIndex={ lockFirstIndex }
        onContentRemoved={ whenContentRemoved }
        onContentSelected={ whenContentSelected }
        validation={ validation }
        onValidationChanged={ onValidationChanged }
        maxContentCount={ BROADCAST_CONTENT_MAX_COUNT }
        contentMenuInitiallyOpen={ contentMenuInitiallyOpen }
        ContentMenu={ ({ onContentCreated }) => (
          <BroadcastEditorContentMenu
            eventCategoryConfig={ eventCategoryConfig }
            onContentCreated={ onContentCreated }
          />
        ) }
        preview={ preview }
        renderContent={ (activeContentIndex) => {
          const activeContent = contents[activeContentIndex];

          if (!activeContent) {
            return <></>;
          }

          const activeContentIsTimelineCard = new TimelineDefinition().definesContentType(activeContent.card.content.type);
          const preview = (
            <BroadcastContentPreview
              content={ contents[activeContentIndex] }
              onPreviousClicked={ () => setActiveContentIndex(activeContentIndex => Math.max(0, activeContentIndex - 1)) }
              onNextClicked={ () => setActiveContentIndex(activeContentIndex => Math.min(contents.length - 1, activeContentIndex + 1)) }
              previousButtonHidden={ activeContentIndex <= 0 }
              nextButtonHidden={ activeContentIndex >= contents.length - 1 }
              cardCount={ contents.length }
              { ...(
                activeContentIsTimelineCard
                  ? { buttonColor: 'inherit' }
                  : {}
              ) }
            />
          );

          return activeContentIsTimelineCard
            ? (
              <SpaceThemeProvider>
                { preview }
              </SpaceThemeProvider>
            )
            : preview;
        } }
        renderTooFewError={ useCallback(min => (
          <FormattedMessage
            id="broadcastEditor.tooFew"
            description="Error in broadcast editor when broadcast contains too few cards."
            defaultMessage="Broadcasts must contain at least { min, plural, one {# card} other {# cards} }."
            values={ { min } }
          />
        ), []) }
        renderTooManyError={ useCallback(max => (
          <FormattedMessage
            id="broadcastEditor.tooMany"
            description="Error in broadcast editor when broadcast contains too many cards."
            defaultMessage="Broadcasts must not contain more than { max, plural, one {# card} other {# cards} }."
            values={ { max } }
          />
        ), []) }
      />
    </StyledBroadcastEditor>
  );
};

const createTimelineEditorContentWithTitle = (title: string): TimelineEditorContent => {
  const editorContent = new TimelineDefinition().createEditorContent();
  return {
    ...editorContent,
    card: {
      ...editorContent.card,
      content: {
        ...editorContent.card.content,
        text: title.length > TIMELINE_TEXT_MAX_LENGTH
          ? title.slice(0, TIMELINE_TEXT_MAX_LENGTH - 1).concat('…')
          : title,
      },
    },
  };
};
