import { ComponentProps, FC, useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ProgressBar } from 'op-storybook/stories/components/ProgressBar/ProgressBar';
import { Button } from 'op-storybook/stories/components/Button/Button';

import {
  StyledBroadcastContentFooter,
  StyledButtonContainer,
  StyledCardActionContainer,
  StyledNavigationButtonContainer,
  StyledNextIcon,
  StyledPreviousIcon
} from './style';
import { Flex } from '../../../../Common/Component';
import ChevronIcon from '../../../../Assets/img/icons/monochrome/chevron.svg';
import { RespondButtonProps } from '..';
import { RequestState } from '../../../../Models';

export type StackNavigationProps = {
  previousButtonProps: ComponentProps<typeof Button> | undefined;
  onNextButtonClicked: () => void;
  nextButtonDisabled: boolean;
  nextButtonHidden: boolean;
  responded?: boolean;
  respondProps?: RespondButtonProps;
  progress?: number;
  color?: 'primary' | 'secondary' | 'inherit';
};

export const BroadcastContentFooter: FC<StackNavigationProps> = ({
  color = 'primary',
  previousButtonProps,
  onNextButtonClicked,
  nextButtonDisabled,
  nextButtonHidden,
  responded,
  respondProps,
  progress,
  children,
}) => {
  const [willProceed, setWillProceed] = useState<boolean>(false);

  const whenNextButtonClicked = useCallback(() => {
    if (!respondProps || responded) {
      onNextButtonClicked();
      return;
    }

    setWillProceed(true);
    respondProps.onClick();
  }, [onNextButtonClicked, respondProps, responded]);

  useEffect(() => {
    if (!responded || !willProceed) {
      return;
    }

    setWillProceed(false);
    onNextButtonClicked();
  }, [onNextButtonClicked, responded, willProceed]);

  return (
    <StyledBroadcastContentFooter>
      <StyledButtonContainer>
        <StyledNavigationButtonContainer notVisible={ previousButtonProps === undefined }>
          <Button
            variant="tertiary"
            css={ theme => ({
              textAlign: 'left',
              width: '100%',
              justifyContent: 'flex-start',
              ...(children ? { padding: `${ theme.new.spacing[2] }` } : { padding: `${ theme.new.spacing[2] } ${ theme.new.spacing[3] }` }),
              ...(color === 'inherit' ? { color: 'inherit' } : {}),
            }) }
            { ...previousButtonProps }
          >
            <Flex gap={ 1 }>
              <StyledPreviousIcon>
                <ChevronIcon/>
              </StyledPreviousIcon>
              { !children && (
                <FormattedMessage
                  id="content.broadcastContentFooter.previous"
                  description="Label for previous button in broadcast content"
                  defaultMessage="Previous"
                />
              ) }
            </Flex>
          </Button>
        </StyledNavigationButtonContainer>
        <StyledCardActionContainer>
          { children }
        </StyledCardActionContainer>
        <StyledNavigationButtonContainer notVisible={ nextButtonHidden }>
          <Button
            variant="tertiary"
            disabled={ nextButtonDisabled }
            css={ theme => ({
              width: '100%',
              justifyContent: 'flex-end',
              ...(children ? { padding: `${ theme.new.spacing[2] }` } : { padding: `${ theme.new.spacing[2] } ${ theme.new.spacing[3] }` }),
              ...(color === 'inherit' ? { color: 'inherit' } : {}),
            }) }
            busy={ respondProps?.state === RequestState.FETCHING }
            onClick={ whenNextButtonClicked }
          >
            <Flex gap={ 1 }>
              { !children && (
                <FormattedMessage
                  id="content.broadcastContentFooter.next"
                  description="Label for next button in broadcast content"
                  defaultMessage="Next"
                />
              ) }
              <StyledNextIcon>
                <ChevronIcon/>
              </StyledNextIcon>
            </Flex>
          </Button>
        </StyledNavigationButtonContainer>
      </StyledButtonContainer>
      { progress !== undefined && (
        <ProgressBar
          progress={ progress }
          showNumericValue={ false }
        />
      ) }
    </StyledBroadcastContentFooter>
  );
}
