import { ChangeEventHandler, FunctionComponent } from 'react';
import { FormControlLabel, Grid, Radio } from '@mui/material';

import { RoleDescription } from '..';
import { useUserRoles } from '../../../Common/Hook';
import { RoleId } from '../../../Security/Model';
import { RoleReader } from '../../../Readers';
import { useLocalisedRoleNames } from '../../../Security/Hook';
import {
  StyledGrid,
  StyledGridDescription,
  StyledRadioGroup,
  StyledRoleLabel,
  StyledRoleDescription,
} from './styles';

type Props = {
  selectedRoleId: RoleId;
  onRoleChange: ChangeEventHandler<HTMLInputElement>;
}

export const RoleFormFields: FunctionComponent<Props> = ({
  selectedRoleId,
  onRoleChange
}) => {
  const { mostPermissiveRole, userIsDeveloper, userIsSuperAdmin } = useUserRoles();
  const { getRoleById } = useLocalisedRoleNames();

  const visibleRoles: RoleId[] = [
    ...(userIsDeveloper ? ['ROLE_DEVELOPER'] : []),
    'ROLE_SUPER_ADMIN',
    'ROLE_ADMIN',
    'ROLE_BROADCAST_ADMIN',
    'ROLE_APP_ADMIN',
    'ROLE_APP_BROADCAST',
    'ROLE_USER',
  ] as RoleId[];

  return (
    <Grid container spacing={ 3 }>
      <StyledGrid item xs={ 12 } sm={ 4 }>
        <StyledRadioGroup name="roleId" value={ selectedRoleId } onChange={ onRoleChange }>
          { visibleRoles.map(visibleRoleId => (
            <FormControlLabel
              value={ visibleRoleId }
              key={ visibleRoleId }
              disabled={
                !userIsSuperAdmin
                && (
                  RoleReader.priority(mostPermissiveRole) <= RoleReader.priority(selectedRoleId)
                   || RoleReader.priority(mostPermissiveRole) <= RoleReader.priority(visibleRoleId)
                )
              }
              control={ <Radio/> }
              label={ (
                <StyledRoleLabel>
                  { getRoleById(visibleRoleId)?.name }
                  {
                    getRoleById(visibleRoleId)?.description && (
                      <StyledRoleDescription>
                        { getRoleById(visibleRoleId)?.description }
                      </StyledRoleDescription>
                    )
                  }
                </StyledRoleLabel>
              ) }
            />
          )) }
        </StyledRadioGroup>
      </StyledGrid>
      <StyledGridDescription item sm={ 8 }>
        { selectedRoleId && <RoleDescription roleId={ selectedRoleId }/> }
      </StyledGridDescription>
    </Grid>
  );
};
