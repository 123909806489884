import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import { DebouncedSearchInput } from '@ourpeople/shared/Core/Component/Input/DebouncedSearchInput/DebouncedSearchInput';

import {
  CenteredCell,
  PaginatedTable,
  RightAlignedCell,
  SortableHeaderCell,
  TableCell,
  TableRow
} from '../../../Components';
import { FetchFormsParams, FetchFormsSort, useFetchForms } from '../../Hook';
import { CategoryAutocomplete, FormCategory, FormName, NonWrappingCell } from '..';
import { Category, PublishStatus } from '../../Model';
import { OpenedHeaderCell } from './styles';
import {
  FilterContainer,
  FilterRow,
  Flex,
  FlexPullRight,
  PresentationIcon,
  StyledChip
} from '../../../Common/Component';
import { FormsTableMenu } from '../FormsTableMenu/FormsTableMenu';
import { ListFormsQuery } from '../../Routing';
import LockIcon from '../../../Assets/img/icons/streamline/shield-lock.svg';
import SettingsIcon from '../../../Assets/img/icons/streamline/task-list-settings.svg';
import StatusTrackerIcon from '../../../Assets/img/icons/streamline/app-window-password-correct.svg';

export interface FormsTableParams {
  categoryIds: string[];
  publishStatuses: PublishStatus[];
  pageNum: number;
  search: string;
  sort: FetchFormsSort;
}

export interface FormsTableProps extends FormsTableParams {
  onParamChange: (params: ListFormsQuery) => void;
}

export const FormsTable: FunctionComponent<FormsTableProps> = ({
  categoryIds,
  publishStatuses,
  onParamChange,
  pageNum,
  search,
  sort,
}) => {
  const intl = useIntl();
  const [localSearch, setLocalSearch] = useState<string>(search || '');
  const [localPageNum, setLocalPageNum] = useState<number>(pageNum || 1);
  const [localSort, setLocalSort] = useState<FetchFormsSort>(sort);
  const [selectedCategories, setSelectedCategories] = useState<Category[]>([]);
  const categoryIdString = useMemo(() => categoryIds.join(','), [categoryIds]);
  const publishStatusesString = useMemo(() => publishStatuses.join(','), [publishStatuses]);
  const fetchFormParams = useMemo<FetchFormsParams>(() => ({
      pageNum,
      sort,
      publishStatuses: publishStatusesString || null,
      search: search || null,
      categoryIds: categoryIdString || null,
    }),
    [categoryIdString, pageNum, sort, publishStatusesString, search],
  );
  const [
    fetchFormsResponse,
    fetchFormsRequestState,
    reloadForms,
  ] = useFetchForms(fetchFormParams);
  const pagination = useMemo(() => fetchFormsResponse?.content?.pagination, [fetchFormsResponse]);
  const formsAndSubmissions = useMemo(() => fetchFormsResponse?.content?.forms, [fetchFormsResponse]);

  const removeCategory = (categoryToRemove: Category): void => {
    setSelectedCategories(categories => categories.filter(category => category.id !== categoryToRemove.id));
  };

  useEffect(() => {
    if (
      (!idsEqual(categoryIds, selectedCategories.map(category => category.id)))
      || pageNum !== localPageNum
      || search !== localSearch
      || sort !== localSort
    ) {
      onParamChange({
        publishStatus: publishStatuses.join(','),
        ...(selectedCategories.length ? { categoryIds: selectedCategories.map(category => category.id).join(',') } : {}),
        pageNum: `${ localPageNum }`,
        ...(localSearch ? { search: localSearch } : {}),
        sort: localSort,
      });
    }
  }, [
    onParamChange,
    localSort,
    sort,
    localPageNum,
    pageNum,
    categoryIds,
    selectedCategories,
    search,
    localSearch,
    publishStatuses
  ]);

  return (
    <>
      <FilterContainer>
        <FilterRow>
          <CategoryAutocomplete
            selectedIds={ categoryIds }
            onSelectionChanged={ selection => setSelectedCategories(selection.options) }
            showChips={ false }
            multiple={ true }
          />
          <FlexPullRight>
            <DebouncedSearchInput
              onChange={ setLocalSearch }
              value={ localSearch }
            />
          </FlexPullRight>
        </FilterRow>
        { !!selectedCategories.length && (
          <FilterRow>
            {
              selectedCategories.map(category => (
                <StyledChip
                  key={ category.id }
                  onDelete={ () => removeCategory(category) }
                  label={ intl.formatMessage(
                    {
                      id: 'forms.filters.category',
                      description: 'Category name when displayed as chip.',
                      defaultMessage: 'Category: {name}',
                    },
                    {
                      name: category.name,
                    },
                  ) }
                />
              ))
            }
          </FilterRow>
        ) }
      </FilterContainer>
      <PaginatedTable
        rows={ formsAndSubmissions || [] }
        rowRender={ (formAndSubmissions) => (
          <TableRow key={ formAndSubmissions.form.id }>
            <NonWrappingCell>
              <Flex
                gap={ 1 }
                noWrap
              >
                { formAndSubmissions.form.statusTrackerEnabled && (
                  <Tooltip
                    title={ intl.formatMessage({
                      id: 'formsTable.icon.statusTrackerEnabled',
                      description: 'Tooltip for icon in form table indicating that status tracker is enabled.',
                      defaultMessage: 'Status tracker enabled',
                    }) }
                  >
                    <span>
                      <PresentationIcon
                        IconComponent={ StatusTrackerIcon }
                      />
                    </span>
                  </Tooltip>
                ) }
                { formAndSubmissions.form.anonymousSubmissionsEnabled && (
                  <Tooltip
                    title={ intl.formatMessage({
                      id: 'formsTable.icon.anonymousSubmissionsEnabled',
                      description: 'Tooltip for icon in form table indicating that anonymous submissions are enabled.',
                      defaultMessage: 'Anonymous responses',
                    }) }
                  >
                    <span>
                      <PresentationIcon
                        IconComponent={ LockIcon }
                      />
                    </span>
                  </Tooltip>
                ) }
              </Flex>
            </NonWrappingCell>
            <TableCell>
              {
                publishStatusesString !== 'draft'
                  ?                  <Link to={ `/forms/${ formAndSubmissions.form.id }/report` }><FormName form={ formAndSubmissions.form }/></Link>
                  : formAndSubmissions.permissions.includes('forms.update')
                    ?                    <Link to={ `/forms/${ formAndSubmissions.form.id }/edit` }><FormName form={ formAndSubmissions.form }/></Link>
                    : <FormName form={ formAndSubmissions.form }/>
              }
            </TableCell>
            <TableCell>
              <FormCategory form={ formAndSubmissions.form }/>
            </TableCell>
            { publishStatusesString !== 'draft' && (
              <>
                <CenteredCell>{ formAndSubmissions.submissionCounts.pastSevenDays.opened }</CenteredCell>
                <CenteredCell>{ formAndSubmissions.submissionCounts.open }</CenteredCell>
              </>
            ) }
            {
              (publishStatusesString !== 'draft' || formAndSubmissions.permissions.includes('forms.update')) && (
                <RightAlignedCell>
                  <FormsTableMenu
                    reloadForms={ reloadForms }
                    form={ formAndSubmissions.form }
                    publishStatusesString={ publishStatusesString }
                    userCanEditForm={ formAndSubmissions.permissions.includes('forms.update') }
                  />
                </RightAlignedCell>
              )
            }
          </TableRow>
        ) }
        pageNum={ pageNum }
        onPageChanged={ setLocalPageNum }
        headerRow={ (
          <TableRow>
            <TableCell>
              <Tooltip
                title={ intl.formatMessage({
                  id: 'formsTable.icon.heading',
                  description: 'Tooltip for column heading for column containing hint icons in forms table.',
                  defaultMessage: 'Smart form settings',
                }) }
              >
                <span>
                  <PresentationIcon
                    IconComponent={ SettingsIcon }
                  />
                </span>
              </Tooltip>
            </TableCell>
            <SortableHeaderCell
              sort={ sort }
              ascValue="name_asc"
              descValue="name_desc"
              onSort={ setLocalSort }
            >
              <FormattedMessage
                id="forms.tableHeader.name"
                defaultMessage="Name"
              />
            </SortableHeaderCell>
            <SortableHeaderCell
              sort={ sort }
              ascValue="category_name_asc"
              descValue="category_name_desc"
              onSort={ setLocalSort }
            >
              <FormattedMessage
                id="forms.tableHeader.category"
                defaultMessage="Category"
              />
            </SortableHeaderCell>
            { publishStatusesString !== 'draft' && (
              <>
                <OpenedHeaderCell>
                  <span>
                    <FormattedMessage
                      id="forms.tableHeader.opened"
                      defaultMessage="Opened"
                    />
                  </span>
                  <span>
                    <FormattedMessage
                      id="forms.tableHeader.lastSevenDays"
                      defaultMessage="(last 7 days)"
                    />
                  </span>
                </OpenedHeaderCell>
                <CenteredCell>
                  <FormattedMessage
                    id="forms.tableHeader.totalOpen"
                    defaultMessage="Total open"
                  />
                </CenteredCell>
              </>
            ) }
            <TableCell/>
          </TableRow>
        ) }
        requestState={ fetchFormsRequestState }
        onRetryClicked={ reloadForms }
        pagination={ pagination }
      />
    </>
  );
};

const idsEqual = (a: string[], b: string[]): boolean => {
  return a.sort().join(',') === b.sort().join(',');
};
