import { IntlShape } from 'react-intl';
import { parseISO } from 'date-fns';

import { FixedDateOffset } from '../Model/DateOffset';
import { DateTimeFormatter } from '../../../src/react/Utility/DateTimeFormatter';

export type FixedDateOffsetRange = {
  start: FixedDateOffset;
  end: FixedDateOffset;
};

export class DateOffsetRangeParser {
  public static fixedOffsetRangeToReadableString = (
    fixedDateOffsetRange: FixedDateOffsetRange,
    intl: IntlShape,
  ): string => (
    intl.formatMessage({
      description: 'Format for date range used in date filter chips.',
      defaultMessage: '{ start } - { end }',
    }, {
      start: DateTimeFormatter.short(parseISO(fixedDateOffsetRange.start.date), intl.locale),
      end: DateTimeFormatter.short(parseISO(fixedDateOffsetRange.end.date), intl.locale),
    })
  );
}
