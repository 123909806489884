import React, { FC } from 'react';
import { ConfirmationDialog } from '@ourpeople/shared/Core/Component/Feedback';

import { useConfirmNavigation } from '../../Hook';

type Props = {
  active: boolean;
  title: string;
  description: string;
  confirmCta: string;
  ignorePattern?: RegExp;
};

/** @deprecated Prefer NavigationConfirmationDialog unless old theme is required */
export const ConfirmNavigationDialog: FC<Props> = ({
  active,
  title,
  description,
  confirmCta,
  ignorePattern,
}) => {
  const {
    blockedNavigationEvent,
    resumeNavigation,
    resetNavigation,
  } = useConfirmNavigation(active, ignorePattern);

  return (
    <ConfirmationDialog
      description={ description }
      open={ !!blockedNavigationEvent }
      confirmCta={ confirmCta }
      title={ title }
      onCancel={ resetNavigation }
      onClose={ resetNavigation }
      onConfirm={ resumeNavigation }
    />
  );
};
