import { FC } from 'react';
import { StackEnd } from 'op-storybook/lib/components/StackEnd/StackEnd';
import { Slide } from '@mui/material';

import { StyledSubMenu } from './style';
import { FooterVariant, SidebarFooter } from '../SidebarFooter/SidebarFooter';
import { NavGroup } from '../NavItemGroup/NavItemGroup';
import { SubMenuNavGroup } from '../SubMenuNavGroup/SubMenuNavGroup';

type Props = {
  open: boolean;
  navGroup?: NavGroup;
  userUuid: string;
  userName: string;
  accountPath: string;
  logoutPath: string;
  userHasAvatar: boolean;
  userLastDescribed: number;
};

export const SubMenu: FC<Props> = ({
  open,
  navGroup,
  userUuid,
  userName,
  accountPath,
  logoutPath,
  userHasAvatar,
  userLastDescribed,
}) => (
  <Slide
    in={ open }
    unmountOnExit
    direction="right"
  >
    <StyledSubMenu>
      { navGroup && (
        <SubMenuNavGroup navGroup={ navGroup }/>
      ) }
      <StackEnd direction="column">
        <SidebarFooter
          userUuid={ userUuid }
          userName={ userName }
          accountPath={ accountPath }
          logoutPath={ logoutPath }
          variant={ FooterVariant.NO_AVATAR }
          onAvatarClick={ () => null }
          userHasAvatar={ userHasAvatar }
          userLastDescribed={ userLastDescribed }
        />
      </StackEnd>
    </StyledSubMenu>
  </Slide>
);
