import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { AutocompleteSelectionChanged } from '@ourpeople/shared/Core/Component/Input/Autocomplete/Autocomplete';

import { TeamAutocomplete } from '../../../../Tags/Component';
import { ApiContext } from '../../../../Contexts';
import { Tag } from '../../../../Tags/Model';
import { Paginated } from '../../../../Models';

type Props = {
  locationName: string | null;
  location: Tag<'team'> | null;
  onChange: (location: Tag<'team'> | null) => void;
  onBlur: () => void;
};

export const LocationInput: FC<Props> = ({
  locationName,
  location,
  onChange,
  onBlur,
}) => {
  const api = useContext(ApiContext);
  const intl = useIntl();
  const [matchingLocation, setMatchingLocation] = useState<Tag<'team'> | null>(null);
  const locationIds = useMemo(() => location ? [location.id] : matchingLocation ? [matchingLocation.id] : [], [location, matchingLocation]);

  useEffect(() => {
    if (!api || !locationName || location) {
      return;
    }

    api.get<Paginated<'tags', Tag<'team'>>>('/tags', {
      params: {
        type: 'team',
        search: locationName,
        sort: 'name_asc,id_asc',
      }
    })
      .then(response => {
        setMatchingLocation(findMatchingLocation(locationName, response.data.tags));
      })
      .catch(() => {
        // Do nothing, user can select a new location
      });
  }, [api, location, locationName]);

  useEffect(() => {
    if (!location?.name || locationName === location.name) {
      return;
    }

    onChange(location);
  }, [location, locationName, onChange]);

  const whenSelectionChanged: AutocompleteSelectionChanged<Tag<'team'>> = (selection) => {
    onChange(selection.options[0] || null);
  };

  return (
    <TeamAutocomplete
      teamIds={ locationIds }
      onSelectionChanged={ whenSelectionChanged }
      multiple={ false }
      onBlur={ onBlur }
      placeholder={ intl.formatMessage({
        id: 'broadcasts.locationInput.placeholder',
        description: 'Placeholder for cover card location input',
        defaultMessage: 'Search for location',
      }) }
      variant="standard"
      noMargin
    />
  );
};

const findMatchingLocation = (locationName: string, locations: Tag<'team'>[]): Tag<'team'> | null => (
  locations.find(location => location.name === locationName) || null
);
