import styled from '@emotion/styled';

import { CardContent } from '../CardContent/CardContent';

export const StyledCardFooter = styled.div<{ seamless: boolean }>`
  position: sticky;
  left: 0;

  ${ props => !props.seamless && `
    border-top: 1px solid ${ props.theme.new.palette.grey[200].main };
  ` }
`;

export const StyledCardContent = styled(CardContent)`
  padding-top: ${ props => props.theme.new.spacing[4] };
  padding-bottom: ${ props => props.theme.new.spacing[4] };
`;
