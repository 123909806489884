import styled from '@emotion/styled';

export const StyledEventInformationRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: ${ props => props.theme.spacing(2) };
  color: ${ props => props.theme.palette.none.main };
  padding: 0 ${ props => props.theme.spacing(6) };
`;

export const StyledEventInformationLabel = styled.span`
  text-transform: uppercase;
  font-size: 0.8rem;
`;

export const StyledEventInformationValue = styled.span`
  color: ${ props => props.theme.palette.text.primary };
`;
