import styled from '@emotion/styled';

export const StyledNavigationButton = styled.button`
  background-color: ${ props => props.theme.palette.transparent.main };
  color: ${ props => props.theme.palette.navy.main };
  transition: background-color 0.2s;
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  text-align: left;

  &:active {
    background-color: ${ props => props.theme.palette.transparent.active };
  }
`;
