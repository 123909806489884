import { ComponentProps, FC, ReactNode } from 'react';

import { Typography } from '../../../stories/components/Typography/Typography';
import { BaseAuthHeader } from '../BaseAuthHeader/BaseAuthHeader';

type Props = ComponentProps<typeof BaseAuthHeader> & {
  header: string;
  supportingText?: ReactNode;
};

export const AuthHeader: FC<Props> = ({
  header,
  supportingText,
  ...props
}) => (
  <BaseAuthHeader { ...props }>
    <Typography
      type="display"
      size="sm"
      weight="semibold"
      align="center"
    >
      { header }
    </Typography>
    { supportingText && (
      <Typography
        align="center"
        palette={ {
          colour: 'grey',
          intensity: 500,
        } }
      >
        { supportingText }
      </Typography>
    ) }
  </BaseAuthHeader>
);
