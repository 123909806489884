import { ChangeEvent, Dispatch, ReactNode, SetStateAction, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { parse } from 'date-fns';

import { InterFieldText, WeekdaySelect } from '..';
import { NoMarginTextField } from '../../../Common/Component';
import { StyledScheduleDailySettings } from './style';
import { DateTimeFormatter } from '../../../Utility/DateTimeFormatter';
import { DailySchedule, DayIndex, WeeklySchedule } from '../../Model/BroadcastSchedule';
import { BroadcastScheduleIdentifier } from '../../Utility/BroadcastScheduleIdentifier';

type Props<S extends DailySchedule | WeeklySchedule> = {
  schedule: S;
  setSchedule: Dispatch<SetStateAction<S>>;
};

export const ScheduleDailySettings = <S extends DailySchedule | WeeklySchedule>({
  schedule,
  setSchedule,
}: Props<S>): JSX.Element => {
  const time = useMemo(() => (
    DateTimeFormatter.timeInput(
      new Date(
        0,
        0,
        1,
        schedule.recurrence.repetition.localHour,
        schedule.recurrence.repetition.minutes,
        schedule.recurrence.repetition.seconds,
        0,
      ),
    )
  ), [
    schedule.recurrence.repetition.localHour,
    schedule.recurrence.repetition.minutes,
    schedule.recurrence.repetition.seconds
  ]);

  const whenDayIndicesChanged = useCallback((dayIndices: DayIndex[]) => (
    setSchedule(schedule => ({
      ...schedule,
      recurrence: {
        ...schedule.recurrence,
        repetition: {
          ...schedule.recurrence.repetition,
          ...(BroadcastScheduleIdentifier.scheduleIsDaily(schedule) ? { localDayIndices: dayIndices } : { localDayIndex: dayIndices[0] })
        },
      },
    }))
  ), [setSchedule]);

  const whenTimeChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const time = event.currentTarget.value;

    if (!time) {
      return;
    }

    const date = parse(time, 'HH:mm', new Date());

    setSchedule(schedule => ({
      ...schedule,
      recurrence: {
        ...schedule.recurrence,
        repetition: {
          ...schedule.recurrence.repetition,
          localHour: date.getHours(),
          minutes: date.getMinutes(),
          seconds: date.getSeconds(),
        },
      },
    }));
  }, [setSchedule]);

  return (
    <StyledScheduleDailySettings>
      <FormattedMessage
        description="Layout of form fields for daily and weekly recurring broadcast schedule."
        defaultMessage="<text>repeating on</text>{day}<text>at</text>{time}"
        values={ {
          day: (
            <WeekdaySelect
              multiple={ BroadcastScheduleIdentifier.scheduleIsDaily(schedule) }
              dayIndices={
                BroadcastScheduleIdentifier.scheduleIsDaily(schedule)
                  ? schedule.recurrence.repetition.localDayIndices
                  : [schedule.recurrence.repetition.localDayIndex]
              }
              onChange={ whenDayIndicesChanged }
              margin="dense"
            />
          ),
          time: (
            <NoMarginTextField
              id="broadcast-repeating-at"
              variant="outlined"
              type="time"
              name="scheduledTime"
              required
              onChange={ whenTimeChanged }
              value={ time }
            />
          ),
          text: (chunks: (string | ReactNode)[]) => <InterFieldText>{ chunks }</InterFieldText>,
        } }
      />
    </StyledScheduleDailySettings>
  );
};
