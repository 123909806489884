import { FunctionComponent } from 'react';
import { ChartData, ChartOptions } from 'chart.js';
import { Line } from 'react-chartjs-2';

import { MetricTransformer } from '../../Utility';
import { LineChartLegend, StyledLineChartMetric } from './style';

interface Props {
  chartData: ChartData<'line'>;
}

export const LineChartMetric: FunctionComponent<Props> = ({
  chartData,
}) => {
  const options: ChartOptions<'line'> = {
    plugins: {
      legend: {
        display: false
      },
    },
    maintainAspectRatio: true,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value) => {
            if (+value % 1 === 0) {
              return value;
            }
          }
        }
      },
      x: {
        grid: {
          display: false,
        }
      }
    },
  };

  return (
    <StyledLineChartMetric>
      <Line
        data={ chartData }
        options={ options }
      />
      <LineChartLegend>
        {
          chartData.datasets.map(dataset => (
            <div
              key={ dataset.label }
            >
              <p
                style={ {
                  fontSize: '2rem',
                  fontWeight: 500,
                  margin: 0,
                  color: dataset.backgroundColor as string,
                } }
              >{ MetricTransformer.sumDataPoints(dataset.data as number[]) }</p>
              { dataset.label }
            </div>
          ))
        }
      </LineChartLegend>
    </StyledLineChartMetric>
  )
}
