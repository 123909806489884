import {
  BlankError,
  FileTooLargeError,
  NotAllowedError,
  NotEmailAddressError,
  NotHexColourError,
  NotNumberError,
  NotPhoneNumberError,
  NotUniqueError,
  NotUrlError,
  NotUtf8Error,
  RateLimitError,
  TooEarlyError,
  TooFewError,
  TooLargeError,
  TooLateError,
  TooLongError,
  TooManyError,
  TooShortError,
  TooSmallError,
  TranscodingError,
  ValidationError
} from '../Model/ValidationError';

export class ValidationErrorIdentifier {
  public static isBlankError = (error: ValidationError): error is BlankError => error.type === 'blank';
  public static isFileTooLargeError = (error: ValidationError): error is FileTooLargeError => error.type === 'fileTooLarge';
  public static isNotNumberError = (error: ValidationError): error is NotNumberError => error.type === 'notNumber';
  public static isNotPhoneNumberError = (error: ValidationError): error is NotPhoneNumberError => error.type === 'notPhoneNumber';
  public static isNotEmailAddressError = (error: ValidationError): error is NotEmailAddressError => error.type === 'notEmailAddress';
  public static isTooEarlyError = (error: ValidationError): error is TooEarlyError => error.type === 'tooEarly';
  public static isTooFewError = (error: ValidationError): error is TooFewError => error.type === 'tooFew';
  public static isTooLargeError = (error: ValidationError): error is TooLargeError => error.type === 'tooLarge';
  public static isTooLateError = (error: ValidationError): error is TooLateError => error.type === 'tooLate';
  public static isTooLongError = (error: ValidationError): error is TooLongError => error.type === 'tooLong';
  public static isTooManyError = (error: ValidationError): error is TooManyError => error.type === 'tooMany';
  public static isTooShortError = (error: ValidationError): error is TooShortError => error.type === 'tooShort';
  public static isTooSmallError = (error: ValidationError): error is TooSmallError => error.type === 'tooSmall';
  public static isNotUrlError = (error: ValidationError): error is NotUrlError => error.type === 'notUrl';
  public static isNotHexColourError = (error: ValidationError): error is NotHexColourError => error.type === 'notHexColour';
  public static isTranscodingError = (error: ValidationError): error is TranscodingError => error.type === 'transcodingFailed';
  public static isNotAllowedError = (error: ValidationError): error is NotAllowedError => error.type === 'notAllowed';
  public static isNotUniqueError = (error: ValidationError): error is NotUniqueError => error.type === 'notUnique';
  public static isNotUtf8Error = (error: ValidationError): error is NotUtf8Error => error.type === 'notUtf8';
  public static isRateLimitError = (error: ValidationError): error is RateLimitError => error.type === 'rateLimit';
}
