import { FC, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CircularProgress } from '@mui/material';

import { WhenId } from '..';
import { Notice } from '../../../Common/Component';

type Props = {
  recipientCount: number | null;
  when: WhenId;
};

export const BroadcastScheduleNotice: FC<Props> = ({
  recipientCount,
  when,
}) => {
  const intl = useIntl();

  const message = useMemo(() => {
    if (recipientCount === null) {
      return (
        <FormattedMessage
          id="scheduling.loadingCounts"
          description="Message displayed when loading recipient counts."
          defaultMessage="Calculating audience size..."
        />
      );
    }
    switch (when) {
      case 'now':
        return intl.formatMessage({
          id: 'scheduling.once.notice',
          description: 'Notice when broadcast will be sent immediately.',
          defaultMessage: 'This broadcast will be sent to <strong>{ recipientCount, plural, one {# person} other {# people} }</strong> ASAP.'
        }, {
          recipientCount,
        });
      case 'later':
        return intl.formatMessage({
          id: 'scheduling.later.notice',
          description: 'Notice when broadcast will be sent later.',
          defaultMessage: 'Current audience size is <strong>{ recipientCount, plural, one {# person} other {# people} }</strong>. This will be updated at the time your broadcast is sent.'
        }, {
          recipientCount,
        });
      default:
        return intl.formatMessage({
          id: 'scheduling.later.recurring',
          description: 'Notice when broadcast is recurring.',
          defaultMessage: 'Current audience size is <strong>{ recipientCount, plural, one {# person} other {# people} }</strong>. This will be updated before each delivery.'
        }, {
          recipientCount,
        });
    }
  }, [intl, recipientCount, when]);

  return (
    <Notice
      { ...recipientCount === null ? { icon: <CircularProgress size={ 21 } variant="indeterminate"/> } : {} }
      variant="outlined"
      feedback={ {
        severity: 'info',
        message,
      } }
    />
  );
};
