import { PreviewComponent } from '../../../Model/BroadcastBlockDefinition';
import { StorageNodeBlock as StorageNodeBlockComponent } from './StorageNodeBlock';
import { StorageNodeBlock } from '../../../Service/Blocks/StorageNodeBlockDefinition';

export const StorageNodeBlockExpandedPreview: PreviewComponent<StorageNodeBlock> = props => {
  return (
    <StorageNodeBlockComponent
      { ...props }
      functionalLink
    />
  );
};
