import { useMemo } from 'react';

import { FetchResponse, useGet } from '../../../../Hooks';

interface UseFetchReportStatusCountsResponse {
  counts: {
    total: number;
    relativeStatus: {
      active: number;
      inactive: number;
      pending: number;
      frozen: number;
    }
  }
}

export const useFetchReportStatusCounts = (
  broadcastId: string,
  responseType: string,
  contentId?: string,
  deliveryId?: string,
  search?: string,
): FetchResponse<UseFetchReportStatusCountsResponse> => {
  const endpoint = `/broadcasts/${broadcastId}/report-recipients/count`;
  const params = useMemo(() => ({
    deliveryId: deliveryId || null,
    contentId: contentId || null,
    responseType,
    search: search || null,
  }), [contentId, deliveryId, responseType, search]);

  return useGet<UseFetchReportStatusCountsResponse>(endpoint, params);
}
