import { ApiRequest } from '@ourpeople/shared/Core/Model/ApiRequest';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { ViewResponse } from '../../../Model';
import {
  LegacyImageContent,
  StyledImageContent,
} from '../../../../Broadcasts/Service';
import { useRecallErrorHandler, useRespondButtonProps } from '../../../Hook';
import { ImageBroadcastContent } from '../../../../Content/Component';
import { BroadcastContentContext } from '../../../../Broadcasts/Model';
import { LiveComponentProps } from '../../../../Content/Model';
import { ContentTransformer } from '../../../../Broadcasts/Utility';
import { FileEntry } from '../../../../Files/Model/FileEntry';
import { RequestState } from '../../../../Models';
import { useLinkProps } from '../../../../Content/Hook/useLinkProps';
import { ErrorResponseReader } from '../../../../Common/Utility';
import { useMounted } from '../../../../Common/Hook';
import { useContextOrThrow } from '../../../../Core/Hook';
import { ApiContext } from '../../../../Contexts';

export const LiveImageContent: FC<LiveComponentProps<StyledImageContent | LegacyImageContent, ViewResponse, BroadcastContentContext>> = ({
  card: { content },
  response,
  context,
  onResponseChange,
  onReloadRequired,
}) => {
  const api = useContextOrThrow(ApiContext);
  const transformedContent = useMemo<StyledImageContent>(
    () => {
      if (content.type === 'styled_image') {
        return content;
      }
      return ContentTransformer.legacyImageToStyledImage(content);
    },
    [content],
  );
  const [fetchFileEntryResult, setFetchFileEntryResult] = useState<ApiRequest<FileEntry>>({
    result: null,
    state: RequestState.PENDING,
  });
  const linkProps = useLinkProps(
    (content as StyledImageContent).link || null,
    (content as StyledImageContent).link?.type === 'fileEntry' && fetchFileEntryResult.state < RequestState.COMPLETE,
    fetchFileEntryResult.state === RequestState.FAILED && ErrorResponseReader.isApiError(fetchFileEntryResult.result) && fetchFileEntryResult.result.response.status === 403,
    fetchFileEntryResult.state === RequestState.COMPLETE ? fetchFileEntryResult.result : null,
    false,
  );
  const mounted = useMounted();

  const fetchFileEntry = useCallback((fileId: string) => {
    setFetchFileEntryResult({
      result: null,
      state: RequestState.FETCHING,
    });
    api.get<FileEntry>(`/files/${ fileId }`)
      .then(response => response.data)
      .then(fileEntry => {
        if (!mounted.current) {
          return;
        }

        setFetchFileEntryResult({
          result: fileEntry,
          state: RequestState.COMPLETE,
        });
      })
      .catch(error => {
        if (!mounted.current) {
          return;
        }

        setFetchFileEntryResult({
          result: error,
          state: RequestState.FAILED,
        });
      });
  }, [api, mounted]);

  useEffect(() => {
    const link = (content as StyledImageContent).link;
    if (link?.type !== 'fileEntry' || !link?.fileEntry?.id) {
      return;
    }

    fetchFileEntry(link.fileEntry.id);
  }, [content, fetchFileEntry]);

  return (
    <ImageBroadcastContent
      backButton={ context.closeButton }
      image={ transformedContent.upload || undefined }
      imageHeight={ transformedContent.imageHeight }
      caption={ transformedContent.caption || undefined }
      text={ transformedContent.text || undefined }
      backgroundColour={ transformedContent.backgroundColour }
      imageFit={ transformedContent.imageFit }
      viewProps={ useRespondButtonProps(
        {
          contentId: transformedContent.id.toString(),
          deliveryId: context.deliveryId,
          type: 'view',
        },
        onResponseChange,
        useRecallErrorHandler(onReloadRequired),
        response,
      ) }
      navigationProps={ context.navigationProps }
      responded={ !!response }
      hideRespondButton={ context.contentCount > 1 }
      nudge={ context.nudge }
      linkProps={ linkProps }
    />
  );
};
