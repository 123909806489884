import { ValidationTree } from '../Model';

export class ValidationMerger {
  public static overwriteMerge = <T>(previousTree: ValidationTree<T>, newTree: ValidationTree<T>): ValidationTree<T> => {
    const childArray = Array.from(Object.entries(newTree.children));
    const children = childArray
      .reduce(
        (newChildren, [key, newChild]) => ({
          ...newChildren,
          [key]: (previousTree as ValidationTree<Record<string, unknown>>).children[key]
            ? ValidationMerger.overwriteMerge(
              (previousTree as ValidationTree<Record<string, unknown>>).children[key] as ValidationTree<Record<string, unknown>>,
              newChild as ValidationTree<Record<string, unknown>>,
            )
            : newChild,
        }),
        {
          ...previousTree.children,
        },
      );

    return {
      errors: newTree.errors,
      children: {
        ...(childArray.length ? {
            ...previousTree.children,
            ...children,
          } : {})
      },
    };
  };
}
