import { CircularProgress, TextField } from '@mui/material';
import { Autocomplete } from '@mui/material';
import * as React from 'react';
import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { AxiosError } from 'axios';

import { MappedSettingTypes } from '../../Types';
import { useApi } from '../../../../../Core/Hook';
import { FetchResult } from '../../../../../Models';
import { Flex, ValidationErrorMessage } from '../../../../../Common/Component';

type Props = {
  value: MappedSettingTypes['timezone']['value'],
  config: MappedSettingTypes['timezone']['config'],
  onValueChanged: (value: MappedSettingTypes['timezone']['value']) => void,
  label?: string,
  focusId?: string,
};

type Timezone = {
  identifier: string;
  currentOffset: number;
};

export const TimezoneSettingInput: FC<Props> = ({
  value,
  config,
  onValueChanged,
  label,
  focusId,
}) => {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const [fetchTimezonesResult, setFetchTimezonesResult] = useState<FetchResult<Timezone[]> | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [initialised, setInitialised] = useState<boolean>(false);
  const fetching = initialised && fetchTimezonesResult === null;
  const api = useApi();

  useEffect(() => {
    const error = validate(value, config, intl);
    setError(error);
  }, [value, config, intl]);

  const fetchTimezones = useCallback(() => {
    setFetchTimezonesResult(null);
    api.get<{ timezones: Timezone[] }>('/space/timezones')
      .then(response => (
        setFetchTimezonesResult(FetchResult.fromContent(response.data.timezones))
      ))
      .catch((error: AxiosError) => {
        setFetchTimezonesResult(FetchResult.fromError(error))
      });
  }, [api]);

  const whenChanged = useCallback((
    _event: ChangeEvent<Record<string, unknown>>,
    value: string | null,
  ) => onValueChanged(value), [onValueChanged]);

  useEffect(() => {
    if (!open || fetchTimezonesResult?.content || initialised) {
      return;
    }

    setInitialised(true);
    fetchTimezones();
  }, [fetchTimezones, fetchTimezonesResult, initialised, open]);

  return (
    <div>
      <Autocomplete
        disableClearable={ !config.nullable }
        aria-label={ label }
        value={ value }
        onChange={ whenChanged }
        open={ open }
        onOpen={ () => setOpen(true) }
        onClose={ () => {
          if (fetchTimezonesResult?.error) {
            setInitialised(false);
          }

          setOpen(false);
        } }
        isOptionEqualToValue={ (option, value) => option === value }
        options={ fetchTimezonesResult?.content?.map(timezone => timezone.identifier) || [] }
        loading={ fetching }
        loadingText={ intl.formatMessage({
          description: 'Loading text in timezone dropdown when options are being fetched.',
          defaultMessage: 'Loading timezones…'
        }) }
        noOptionsText={ intl.formatMessage({
          description: 'Error text in timezone dropdown when options could not be fetched.',
          defaultMessage: 'Could not get timezones. Check your connection and try again.'
        }) }
        renderInput={ params => (
          <TextField
            { ...params }
            variant="outlined"
            InputProps={ {
              ...params.InputProps,
              required: !config.nullable,
              id: focusId,
              margin: 'dense',
              endAdornment: (
                <Flex gap={ 1 }>
                  { fetching ? <CircularProgress color="secondary" size={ 20 }/> : null }
                  { params.InputProps.endAdornment }
                </Flex>
              ),
            } }
          />
        ) }
      />
      { error && <ValidationErrorMessage>{ error }</ValidationErrorMessage> }
    </div>
  );
};

const validate = (
  value: MappedSettingTypes['timezone']['value'],
  config: MappedSettingTypes['timezone']['config'],
  intl: IntlShape,
): string | null => {
  if (value === null && config.nullable) {
    return null;
  }

  if (value === null) {
    return intl.formatMessage({
      id: 'settings.timezoneSetting.nullError',
      defaultMessage: 'Missing value',
    });
  }

  return null;
};
