import React, { FC, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { Flex, VerticallySpaced } from '../../../Common/Component';
import { SwitchWithIcon } from '../SwitchWithIcon/SwitchWithIcon';
import NotificationIcon from '../../../Assets/img/icons/streamline/volume-control-full.svg';
import { MinimalNotificationChannelSettings } from '../../Model';

type Props = {
  notificationSettings: MinimalNotificationChannelSettings;
  onChange: (notificationSettings: MinimalNotificationChannelSettings) => void;
};

export const PushInput: FC<Props> = ({
  notificationSettings,
  onChange,
}) => {
  const whenSendChanged = useCallback((send: boolean) => (
    onChange({
      ...notificationSettings,
      send,
    })
  ), [notificationSettings, onChange]);

  return (
    <VerticallySpaced gap={ 2 }>
      <Flex gap={ 2 }>
        <SwitchWithIcon
          IconComponent={ NotificationIcon }
          checked={ notificationSettings.send }
          onChange={ whenSendChanged }
        />
        <span>
          <FormattedMessage
            description="Label for sendPush input."
            defaultMessage="Notify everyone via push"
          />
        </span>
      </Flex>
    </VerticallySpaced>
  );
};
