import styled from '@emotion/styled';

export const StyledSubCategoryHeader = styled.div`
  border-bottom: 2px solid ${props => props.theme.palette.grey[300]};
  padding-bottom: ${props => props.theme.spacing(1)};
  margin-bottom: ${props => props.theme.spacing(3)};
  display: grid;
  grid-template-columns: 3fr 1.5fr;
  grid-template-rows: auto;
  grid-template-areas:
    "category-name default-heading";
  column-gap: ${props => props.theme.spacing(2)};
`;

export const StyledDefaultValueHeading = styled.div`
  align-self: end;
  padding-left: ${props => props.theme.spacing(2)};
  font-weight: bold;
  grid-area: default-heading;
`;
