import { FunctionComponent } from 'react';
import { Heading } from '@ourpeople/shared/Core/Component/Content';

import { Content } from '../../../../../Models';

interface Props {
  content: Content;
}

export const RevealContentPreview: FunctionComponent<Props> = ({
  content
}) => (
  <div>
    <Heading type="h3">{content.title || ''}</Heading>
    <p>{content.text}</p>
  </div>
);
