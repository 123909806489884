export class PermissionChecker {
  public static permissionsSatisfied = (desiredPermissions: string[], givenPermissions: string[]): boolean => {
    for (const desiredPermission of desiredPermissions) {
      if (! PermissionChecker.permissionSatisfied(desiredPermission, givenPermissions)) {
        return false;
      }
    }
    return true;
  };

  public static permissionSatisfied = (desiredPermission: string, givenPermissions: string[]): boolean => {
    if (givenPermissions.indexOf(desiredPermission) > -1) {
      return true;
    }

    const permissionLevels = desiredPermission.split('.');
    let permissionKey = '';
    for (const permissionLevel of permissionLevels) {
      permissionKey = permissionKey + permissionLevel;
      if (givenPermissions.indexOf(permissionKey) > -1) {
        return true;
      }
      permissionKey = permissionKey + '.';
    }
    return false;
  };

  public static anyPermissionSatisfied = (
    desiredPermissions: string[],
    givenPermissions: string[],
  ): boolean => (
    !!desiredPermissions.find(
      desiredPermission => PermissionChecker.permissionSatisfied(
        desiredPermission,
        givenPermissions
      )
    )
  );

  /**
   * @returns boolean True when the desired permission is satisfied, or appears in the left substrings of the given permissions
   */
  public static permissionPresent = (desiredPermission: string, givenPermissions: string[], excludePermissions: string[] = []): boolean => {
    if (desiredPermission.trim() === '') {
      return false;
    }

    if (givenPermissions.indexOf(desiredPermission) > -1) {
      return true;
    }

    const givenPermissionsWithoutExcluded = givenPermissions.filter(
      givenPermission => !excludePermissions.includes(givenPermission)
    );

    if (PermissionChecker.permissionSatisfied(desiredPermission, givenPermissionsWithoutExcluded)) {
      return true;
    }

    for (const givenPermission of givenPermissionsWithoutExcluded) {
      if (
        givenPermission.substring(0, desiredPermission.length) === desiredPermission
      ) {
        return true;
      }
    }
    return false;
  }
}
