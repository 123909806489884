import { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';

import DownloadIcon from '../../../Assets/img/icons/monochrome/download.svg';
import { ButtonContainer } from '../../../Components/Button/ButtonContainer';
import { CsvDownloader } from '../../../Utility/CsvDownloader';
import { usePermissions } from '../../../Security/Hook';
import { useApi } from '../../../Core/Hook';

export type CsvExportButtonProps = {
  endpoint: string;
  fileNamePrefix?: string;
  cta?: string;
  params?: Record<string, unknown>;
  permissions?: string[];
  onError: (error: unknown) => void;
  className?: string;
}

export const CsvExportButton: FC<CsvExportButtonProps> = ({
  fileNamePrefix,
  endpoint,
  cta,
  params,
  permissions = [],
  onError,
  className,
}) => {
  const api = useApi();
  const [downloading, setDownloading] = useState<boolean>(false);
  const { guardedCallback } = usePermissions();

  const whenDownloadButtonClicked = () => {
    setDownloading(true);
    api.get<string>(endpoint, { params })
      .then(response => response.data)
      .then(csv => {
        CsvDownloader.download(csv, fileNamePrefix || '');
        setDownloading(false);
      })
      .catch(error => {
        onError && onError(error);
        setDownloading(false);
      });
  };

  return (
    <ButtonContainer>
      <Button
        className={ className }
        busy={ downloading }
        startIcon={ <DownloadIcon/> }
        onClick={ guardedCallback(whenDownloadButtonClicked, permissions) }
      >
        { cta || (
          <FormattedMessage
            description="Generic CTA for CSV download button"
            defaultMessage="Export to CSV"
          />
        ) }
      </Button>
    </ButtonContainer>
  );
};
