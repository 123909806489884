import { FormattedMessage } from 'react-intl';
import { Tooltip } from '@mui/material';
import React, { FunctionComponent, PropsWithChildren } from 'react';

export const PublishTooltip: FunctionComponent<PropsWithChildren<unknown>> = ({ children }) => (
  <Tooltip
    title={
      <FormattedMessage
        id="form.publish.missingFields"
        description="Tooltip displayed when trying to publish a form without a name or category"
        defaultMessage="Forms require a name and category before they can be published"
      />
    }
  >
    <div>{ children }</div>
  </Tooltip>
);
