import {createContext} from "react";

export interface DeviceFingerprintContextValue {
  ready: boolean;
  deviceToken?: string;
  platform?: string;
  manufacturer?: string;
}

export const DeviceFingerprintContext = createContext<DeviceFingerprintContextValue>({
  ready: false,
});
