import { FC, useCallback } from 'react';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { useLocation } from 'react-router-dom';
import { PresentationIcon } from 'op-storybook/lib/components/PresentationIcon/PresentationIcon';
import { SecondaryInformation } from 'op-storybook/stories/components/Typography/SecondaryInformation';

import { SvgComponent } from '../../../../Common/Model';
import { LayoutLink } from './LayoutLink';

type Props = {
  label: string;
  IconComponent: SvgComponent;
  onRepeatClick?: () => void,
} & (
  {
    pathname: string;
    onClick?: never;
  } | {
    pathname?: never;
    onClick: () => void;
  }
);

export const Tab: FC<Props> = ({
  label,
  pathname,
  IconComponent,
  onClick,
  onRepeatClick,
}) => {
  const { pathname: currentPathname } = useLocation();
  const renderContents = useCallback((active: boolean) => (
    <Stack
      { ...active && onRepeatClick ? { onClick: onRepeatClick } : {} }
      direction="column"
      align="center"
      gap={ 0 }
      css={ theme => ({
        width: '57px',
        ...active
          ? { color: theme.new.palette.primary[700].main }
          : { color: theme.new.palette.grey[500].main },
      }) }
    >
      <PresentationIcon
        size={ 6 }
        IconComponent={ IconComponent }
      />
      <SecondaryInformation
        css={{
          fontWeight: 500,
        }}
        {
          ...active
            ? {
              palette: 'inherit',
            }
            : {}
        }
      >
        { label }
      </SecondaryInformation>
    </Stack>
  ), [IconComponent, label, onRepeatClick]);

  return (
    pathname
      ? (
        <LayoutLink
          to={ pathname }
        >
          { renderContents(currentPathname.startsWith(pathname)) }
        </LayoutLink>
      )
      : (
        <button
          onClick={ onClick }
          css={ {
            background: 'transparent',
            border: 0,
            outline: 0,
            margin: 0,
            padding: 0,
          } }
        >
          { renderContents(false) }
        </button>
      )
  );
};
