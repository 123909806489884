import { ChartData, ChartOptions } from 'chart.js';
import { FunctionComponent } from 'react';
import { Bar } from 'react-chartjs-2';

import { Flex, FlexPullRight } from '../../../Common/Component';
import { Legend } from '..';
import { StyledBarChartMetric } from './style';

interface Props {
  chartData: Required<ChartData<'bar'>>;
  ungroupBars?: boolean;
  horizontal?: boolean;
  ignoreAspectRatio?: boolean;
}

export const BarChartMetric: FunctionComponent<Props> = ({
  chartData,
  ungroupBars = false,
  horizontal = false,
  ignoreAspectRatio = false,
}) => {
  const options: ChartOptions<'bar'> = {
    plugins: {
      legend: {
        display: false
      },
    },
    maintainAspectRatio: !ignoreAspectRatio,
    indexAxis: horizontal ? 'y' : 'x',
    scales: {
      y: {
        stacked: !ungroupBars,
        beginAtZero: true,
        grid: {
          display: !horizontal,
        },
        ...(
          !horizontal
            ? {
              ticks: {
                callback: (value) => {
                  if (+value % 1 === 0) {
                    return value;
                  }
                }
              },
            }
            : {}
        ),
      },
      x: {
        stacked: !ungroupBars,
        grid: {
          display: horizontal,
        },
      }
    },
  };

  return (
    <StyledBarChartMetric>
      <Flex>
        <FlexPullRight>
          <Legend
            data={ chartData }
            useDatasets={ true }
          />
        </FlexPullRight>
      </Flex>
      <div>
        <Bar
          data={ chartData }
          options={ options }
        />
      </div>
    </StyledBarChartMetric>
  );
};
