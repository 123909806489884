import styled from '@emotion/styled';

export const StyledProgressBar = styled.div`
  display: flex;
  gap: ${ props => props.theme.new.spacing[2] };
  width: 100%;
  align-items: center;
  position: relative;
  height: 8px;
`;
