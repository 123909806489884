import styled from '@emotion/styled';

export const StyledCardHeader = styled.div<{ seamless: boolean }>`
  position: sticky;
  left: 0;
    
  ${ props => !props.seamless && `
    border-bottom: 1px solid ${ props.theme.new.palette.grey[200].main };
  ` }
`;
