import { FC, ReactNode } from 'react';
import { Theme } from '@emotion/react';

import { StyledAdornment, StyledBadge } from './style';
import { Typography } from '../Typography/Typography';

export type BadgeProps = {
  variant: 'pill-outline' | 'pill-colour' | 'badge-colour' | 'badge-modern';
  colour: keyof Theme['new']['palette'];
  label?: ReactNode;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  className?: string;
};

export const Badge: FC<BadgeProps> = ({
  variant = 'badge-colour',
  colour = 'grey',
  label,
  startAdornment,
  endAdornment,
  className,
}) => (
  <StyledBadge
    variant={ variant }
    colour={ colour }
    noLabel={ !label }
    className={ className }
  >
    { startAdornment && (
      <StyledAdornment colour={ colour }>
        { startAdornment }
      </StyledAdornment>
    ) }
    { label && (
      <Typography
        size="xs"
        weight="medium"
      >
        { label }
      </Typography>
    ) }
    { endAdornment && (
      <StyledAdornment colour={ colour }>
        { endAdornment }
      </StyledAdornment>
    ) }
  </StyledBadge>
);
