import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Tabs } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { TabContext } from '@mui/lab';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';

import {
  Dropzone,
  Flex,
  FlexPullRight,
  IconButton,
  ImageAssetPicker,
  PresentationIcon,
  ValidationErrorMessage,
} from '..';
import CloseIcon from '../../../Assets/img/icons/streamline/close.svg';
import { Tab } from '../../../Components';
import { UploadState } from '../../Context';
import { StyledDialog, StyledDropzoneContainer, StyledImageAssetPickerContainer, StyledTabContainer } from './style';
import { useApi } from '../../../Core/Hook';
import ErrorIcon from '../../../Assets/img/icons/circle/error.svg';
import { ImageAsset } from '../../Hook';
import { ImageUrlTransformer } from '../../Utility/ImageUrlTransformer';

enum AddImageError {
  SELECT,
}

type Props = {
  open: boolean;
  uploadState: UploadState | undefined;
  onCancel: () => void;
  onConfirm: (file: File) => void;
  onSelect: (file: File, imageAsset: ImageAsset) => void;
  onReset: () => void;
};

export const AddImageDialog: FC<Props> = ({
  open,
  uploadState,
  onCancel,
  onConfirm,
  onSelect,
  onReset,
}) => {
  const intl = useIntl();
  const api = useApi();
  const [errors, setErrors] = useState<Set<AddImageError>>(new Set());
  const [tab, setTab] = useState<string>('search');
  const [pendingFile, setPendingFile] = useState<File>();
  const [pendingImageAsset, setPendingImageAsset] = useState<ImageAsset>();
  const [selectingImage, setSelectingImage] = useState<boolean>(false);
  const pendingUploadState = useMemo<UploadState | undefined>(() => pendingFile && ({
    ref: (uploadState?.ref ?? '') + '-AddImageDialogPending',
    progress: 0,
    file: pendingFile,
  }), [pendingFile, uploadState]);

  const cancelMessage = intl.formatMessage({
    id: 'addImageDialog.cancel',
    description: 'Label for cancel button in add image dialog.',
    defaultMessage: 'Cancel',
  });

  useEffect(() => {
    if (tab !== 'search') {
      setPendingImageAsset(undefined);
    }
  }, [tab]);

  const whenTabChanged = useCallback((_event: unknown, value: string) => setTab(value), []);

  const whenConfirmClicked = useCallback(() => {
    if (!pendingFile) {
      return;
    }

    onConfirm(pendingFile);
    setPendingFile(undefined);
  }, [onConfirm, pendingFile]);

  const whenDropped = useCallback((files: File[]) => {
    if (!files.length) {
      return;
    }

    setPendingFile(files[0]);
  }, []);

  const whenCancelled = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const whenImageAssetSaved = useCallback(() => {
    if (!pendingImageAsset) {
      return;
    }

    setSelectingImage(true);

    ImageUrlTransformer.toFile(
      pendingImageAsset.urls.thumbs.l.url,
      pendingImageAsset.fileName,
      'anonymous',
    )
      .then(file => {
        onSelect(file, pendingImageAsset);
        setPendingImageAsset(undefined);
        setSelectingImage(false);
      })
      .catch(() => {
        setErrors(errors => new Set(errors.add(AddImageError.SELECT)));
        setSelectingImage(false);
      });
  }, [api, onSelect, pendingImageAsset]);

  return (
    <Dialog
      open={ open }
      TransitionProps={ {
        onExited: () => {
          setPendingImageAsset(undefined);
          setPendingFile(undefined);
          setErrors(new Set());
        },
      } }
      onClose={ whenCancelled }
      maxWidth="lg"
      fullWidth
      PaperComponent={ StyledDialog }
    >
      <DialogTitle>
        <Flex gap={ 2 }>
          <FormattedMessage
            id="addImageDialog.title"
            description="Title for add image dialog."
            defaultMessage="Add an image"
          />
          <FlexPullRight>
            <IconButton
              IconComponent={ CloseIcon }
              label={ cancelMessage }
              onClick={ whenCancelled }
              size="small"
            />
          </FlexPullRight>
        </Flex>
      </DialogTitle>
      <DialogContent>
        <TabContext value={ tab }>
          <StyledTabContainer>
            { !!errors.size && (
              <Flex gap={ 1 }>
                <PresentationIcon IconComponent={ ErrorIcon } color="error.main"/>
                { Array.from(errors.values()).map(error => (
                  <ValidationErrorMessage key={ error }>
                    { localiseError(error) }
                  </ValidationErrorMessage>
                )) }
              </Flex>
            ) }
            <Tabs
              value={ tab }
              onChange={ whenTabChanged }
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Search" value="search"/>
              <Tab label="Upload" value="upload"/>
            </Tabs>
            { tab === 'search' && (
              <StyledImageAssetPickerContainer>
                <ImageAssetPicker
                  onImageAssetSelected={ setPendingImageAsset }
                />
              </StyledImageAssetPickerContainer>
            ) }
            { tab === 'upload' && (
              <StyledDropzoneContainer>
                <Dropzone
                  uploadState={ pendingUploadState || uploadState }
                  accept="image/*"
                  onDrop={ whenDropped }
                  onReset={ onReset }
                />
              </StyledDropzoneContainer>
            ) }
          </StyledTabContainer>
        </TabContext>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={ onCancel }
        >
          { cancelMessage }
        </Button>
        { tab === 'search' && (
          <Button
            onClick={ whenImageAssetSaved }
            disabled={ !pendingImageAsset }
            variant="primary"
            busy={ selectingImage }
          >
            <FormattedMessage
              id="addImageDialog.select"
              description="Label for select button in add image dialog."
              defaultMessage="Select image"
            />
          </Button>
        ) }
        { tab === 'upload' && (
          <Button
            onClick={ whenConfirmClicked }
            disabled={ !pendingFile }
            variant="primary"
          >
            <FormattedMessage
              id="addImageDialog.upload"
              description="Label for upload button in add image dialog."
              defaultMessage="Upload"
            />
          </Button>
        ) }
      </DialogActions>
    </Dialog>
  );
};

const localiseError = (error: AddImageError): JSX.Element => {
  switch (error) {
    case AddImageError.SELECT:
      return (
        <FormattedMessage
          id="addImageDialog.selectError"
          description="Label for error when selecting unsplash image in add image dialog."
          defaultMessage="There was a problem with your selected image, please try again or select another image."
        />
      );
    default:
      return (
        <FormattedMessage
          id="addImageDialog.genericError"
          description="Label for generic error in add image dialog."
          defaultMessage="Something went wrong, please try again or select another image."
        />
      );
  }
};
