import styled from '@emotion/styled';

export const StyledUploadList = styled.ol`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: ${ props => props.theme.spacing(1) };
`;
