import { FC, ReactNode } from 'react';
import { parseISO } from 'date-fns';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';

import { BroadcastContentFooter, ContentPadding, NudgeBanner } from '..';
import { MediaContentPreviewWithoutButton, ScrollableContent } from '../../../../Broadcasts/Component/Content';
import { MediaPresentationComponentProps } from '../../../Model';
import { SvgComponent } from '../../../../Common/Model';
import { AbsoluteBackButtonContainer } from '../AbsoluteBackButtonContainer/AbsoluteBackButtonContainer';

type Props = MediaPresentationComponentProps & {
  IconComponent: SvgComponent;
  openFileLabel: string;
  backButton: ReactNode | undefined;
};

export const MediaBroadcastContent: FC<Props> = ({
  text,
  media,
  navigationProps,
  IconComponent,
  onOpenFileClicked,
  openFileLabel,
  nudge,
  backButton,
}) => (
  <>
    { backButton && <AbsoluteBackButtonContainer>{ backButton }</AbsoluteBackButtonContainer>}
    <ScrollableContent>
      {
        nudge && (
          <NudgeBanner
            message={ nudge.message }
            nudgedAt={ parseISO(nudge.at) }
          />
        )
      }
      <ContentPadding>
        <MediaContentPreviewWithoutButton
          text={ text }
          media={ media }
          IconComponent={ IconComponent }
        />
      </ContentPadding>
    </ScrollableContent>
    <BroadcastContentFooter { ...navigationProps }>
      <Button
        variant="primary"
        onClick={ onOpenFileClicked }
      >
        { openFileLabel }
      </Button>
    </BroadcastContentFooter>
  </>
);
