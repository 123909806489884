import { FC, forwardRef, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { CountryCode, getCountries, getCountryCallingCode } from 'libphonenumber-js';

import { DropdownItem } from '../../../stories/components/DropdownItem/DropdownItem';
import { TextFieldLeadingButton } from '../TextFieldLeadingButton/TextFieldLeadingButton';
import { PresentationIcon } from '../PresentationIcon/PresentationIcon';
import { CountryInformation, Username } from '../../../stories/components/UsernameField/UsernameField';
import EmailIcon from '../../assets/icon/figma/mail-01.svg?react';
import { DropdownItemCheck } from '../../../stories/components/DropdownItemCheck/DropdownItemCheck';
import DownArrowIcon from '../../assets/icon/figma/chevron-down.svg?react';
import { Typography } from '../../../stories/components/Typography/Typography';
import { ToggledPopover } from '../../../stories/components/content/ToggledPopover';

type UsernameOption = {
  type: 'email',
} | {
  type: 'countryInformation',
  value: CountryInformation,
};

type Props = {
  username: Username;
  onChange: (selectedType: Username['selectedType']) => void;
  defaultCountryCode: CountryCode;
  excludeEmail?: boolean;
};

export const UsernameSelect: FC<Props> = ({
  username,
  onChange,
  defaultCountryCode,
  excludeEmail = false,
}) => {
  const intl = useIntl();
  const countryInformation = useMemo<CountryInformation[]>(() => {
    const displayNames = new Intl.DisplayNames(intl.locale, { type: 'region' });
    const getCountryInformation = (countryCode: CountryCode): CountryInformation | undefined => {
      let callingCode: string;

      try {
        callingCode = getCountryCallingCode(countryCode)
      } catch {
        return;
      }

      return ({
        code: countryCode,
        callingCode,
        name: displayNames.of(countryCode) || countryCode,
      });
    };

    const countries = getCountries();
    const defaultCountryInformation = getCountryInformation(defaultCountryCode);
    return (defaultCountryInformation ? [defaultCountryInformation] : [])
      .concat(
        countries
          .map(getCountryInformation)
          .sort(sortCountriesByName)
          .filter(countryInformation => countryInformation.code !== defaultCountryCode)
      );
  }, [defaultCountryCode, intl.locale]);

  const options: UsernameOption[] = useMemo(() => [
    ...excludeEmail ? [] : [{ type: 'email' } as const],
    ...countryInformation.map(countryInformation => ({
      type: 'countryInformation',
      value: countryInformation
    } as UsernameOption)),
  ], [countryInformation, excludeEmail]);

  const selectedOption: UsernameOption = useMemo(() => {
    if (username.selectedType !== undefined && username.selectedType !== 'email') {
      return options.find(
        option => option.type === 'countryInformation'
          && option.value.code === username.selectedType
      );
    }
    if (username.parsedPhone?.determinedCountryCode) {
      return options.find(
        option => option.type === 'countryInformation'
          && option.value.code === username.parsedPhone.determinedCountryCode
      );
    }
    return excludeEmail ? options[0] : options.find(option => option.type === 'email');
  }, [username.selectedType, username.parsedPhone?.determinedCountryCode, excludeEmail, options]);

  const whenSelectionChanged = useCallback((option: UsernameOption) => {
    onChange(
      option.type === 'email'
        ? 'email'
        : option.value.code
    );
  }, [onChange]);

  const buttonValue = useMemo(() => (
    selectedOption.type === 'email'
      ? (
        <PresentationIcon
          IconComponent={ EmailIcon }
          palette={ {
            colour: 'grey',
            intensity: 400,
          } }
        />
      )
      : `+${ selectedOption.value.callingCode }`
  ), [selectedOption]);

  const ToggleComponent = useMemo(() => forwardRef((props, ref) => (
    <TextFieldLeadingButton
      ref={ ref }
      css={ { position: 'relative' } }
      { ...props }
    >
      <Typography>
        { buttonValue }
      </Typography>
      <PresentationIcon
        IconComponent={ DownArrowIcon }
        size={ 3 }
        css={ {
          right: 0,
          transition: 'transform 0.1s',
        } }
      />
    </TextFieldLeadingButton>
  )), [buttonValue]);

  const ContentComponent = useMemo(() => forwardRef((props, ref) => (
    <menu
      ref={ ref }
      { ...props }
      css={ theme => ({
        boxShadow: theme.new.shadow.md,
        border: theme.new.border.standard,
        borderRadius: theme.new.borderRadius.standard,
        listStyle: 'none',
      }) }
    >
      { options.map(option => {
        const selected = option.type === 'email'
          ? selectedOption.type === 'email'
          : selectedOption.type !== 'email' && option.value === selectedOption.value;

        return (
          <li
            key={ option.type === 'email' ? 'email' : option.value.code }
            onClick={ () => whenSelectionChanged(option) }
          >
            {
              option.type === 'email'
                ? (
                  <DropdownItem
                    startAdornment={
                      <PresentationIcon
                        IconComponent={ EmailIcon }
                        palette={ {
                          colour: 'grey',
                          intensity: 400,
                        } }
                      />
                    }
                    text={ intl.formatMessage({
                      description: 'Label for email address option in username field dropdown',
                      defaultMessage: 'Email address',
                    }) }
                    { ...selected ? { endAdornment: <DropdownItemCheck/> } : {} }
                  />
                )
                : (
                  <DropdownItem
                    text={ option.value.name }
                    supportingText={ `+${ option.value.callingCode }` }
                    { ...selected ? { endAdornment: <DropdownItemCheck/> } : {} }
                  />
                )
            }
          </li>
        );
      }) }
    </menu>
  )), [intl, options, selectedOption, whenSelectionChanged]);

  return (
    <ToggledPopover
      ToggleComponent={ ToggleComponent }
      ContentComponent={ ContentComponent }
    />
  );
};

const sortCountriesByName = (
  countryA: CountryInformation,
  countryB: CountryInformation,
) => (
  countryA.name < countryB.name
    ? -1
    : countryA.name === countryB.name
      ? 0
      : 1
);
