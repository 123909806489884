import styled from '@emotion/styled';
import { Dialog } from '@mui/material';

import { StyledProfile } from '../../PersonProfile/styles';

export const StyledProfileSummaryDialog = styled(Dialog)`
  .MuiDialogContent-root {
    max-height: 500px;
  }
`;

export const StyledProfileList = styled.ul`
  padding: 0;

  li {
    display: flex;
    gap: 0.25rem;
    flex-direction: column;
    align-items: flex-start;

    ${ StyledProfile } {
      width: 100%;
    }
  }
`;
