import styled from '@emotion/styled';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';

export const StyledCountryCodeNote = styled.div`
  margin: 1rem 0;
`;

export const StyledDetailsForm = styled.div`
  padding: 0 1rem 1rem;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;

  ${({ theme }) => theme.breakpoints.down('md')} {
    grid-template-columns: 1fr;
  }

  .PhoneInput {
    /* This is done to stretch the contents of this component. */
    display: flex;
    align-items: center;
  }

  .PhoneInputInput {
    /* The phone number input stretches to fill all empty space */
    flex: 1;
    /* The phone number input should shrink
      to make room for the extension input */
    min-width: 0;
    padding: 10.5px 14px;
  }

  .PhoneInputCountryIcon {
    width: 1.5em;
    height: 1em;
  }

  .PhoneInputCountryIcon--square {
    width: 1em;
  }

  .PhoneInputCountryIcon--border {
    background-color: rgba(0,0,0,0.1);
    box-shadow: 0 0 0 1px rgba(0,0,0,0.5),
      inset 0 0 0 1px rgba(0,0,0,0.5);
  }

  .PhoneInputCountryIconImg {
    display: block;
    width: 100%;
    height: 100%;
  }

  .PhoneInputInternationalIconPhone {
    opacity: 0.8;
  }

  .PhoneInputInternationalIconGlobe {
    opacity: 0.65;
  }

  .PhoneInputCountry {
    position: relative;
    align-self: stretch;
    display: flex;
    align-items: center;
    margin-right: 0.35em;
  }

  .PhoneInputCountrySelect {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    border: 0;
    opacity: 0;
    cursor: pointer;
  }

  .PhoneInputCountrySelect[disabled] {
    cursor: default;
  }

  .PhoneInputCountrySelectArrow {
    display: block;
    content: '';
    width: 0.3em;
    height: 0.3em;
    margin-left: 0.35em;
    border-style: solid;
    border-color: inherit;
    border-top-width: 0;
    border-bottom-width: 1px;
    border-left-width: 0;
    border-right-width: 1px;
    transform: rotate(45deg);
    opacity: 0.45;
  }

  .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
    opacity: 1;
    color: #03b2cb;
  }

  .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
    box-shadow: 0 0 0 1px #03b2cb,
      inset 0 0 0 1px #03b2cb;
  }

  .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
    opacity: 1;
    color: #03b2cb;
  }
`;

export const NoNumberWarning = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column: 1 / span 2;
  padding: 0.5rem;
  border-radius: 0.35rem;
  column-gap: 0.5rem;
  align-items: center;
  margin-top: 0.5rem;
  color: ${ props => props.theme.palette.secondary.main };

  span {
    color: ${ props => props.theme.palette.text.primary };
  }
`;

export const StyledVerifyButton = styled(Button)`
  white-space: nowrap;
`;
