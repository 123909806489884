import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { FormControlLabel, Switch } from '@mui/material';

import { PersonForCreating } from '../../Model';
import { StyledFormRow } from './styles';

type Props = {
  person: PersonForCreating;
  onFlagChange: React.ChangeEventHandler<HTMLInputElement>;
}

export const FlagsFormFields: FunctionComponent<Props> = ({
  person,
  onFlagChange
}) => {
  return (
    <>
      <StyledFormRow>
        <FormControlLabel
          control={
            <Switch
              name="fake"
              checked={ person.fake }
              onChange={ onFlagChange }
            />
          }
          label={
            <FormattedMessage
              id="people.edit.fake"
              description="Label for toggling fake user status"
              defaultMessage="Fake user"
            />
          }
        />
      </StyledFormRow>
      <StyledFormRow>
        <FormControlLabel
          control={
            <Switch
              name="secret"
              checked={ person.secret }
              onChange={ onFlagChange }
            />
          }
          label={
            <FormattedMessage
              id="people.edit.secret"
              description="Label for toggling secret user status"
              defaultMessage="Secret user"
            />
          }
        />
      </StyledFormRow>
    </>
  );
}

