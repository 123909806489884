import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';

import { ChatAudienceMemberList } from '..';
import { Audience } from '../../../Audiences/Model';

type Props = {
  chatId: string;
  onClose: () => void;
  audience?: Audience;
};

export const ChatAudienceMemberDialog: FC<Props> = ({
  chatId,
  onClose,
  audience,
}) => (
  <Dialog
    fullWidth
    maxWidth="lg"
    open={ true }
    onClose={ onClose }
  >
    <DialogContent>
      <ChatAudienceMemberList
        chatId={ chatId }
        audience={ audience }
        initialQuery={{
          pageNum: 1,
          sort: ['first_name_asc'],
          chatStatuses: ['joined'],
        }}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={ onClose }>
        <FormattedMessage
          id="audienceMemberDialog.close"
          description="Label for close button in audience member dialog."
          defaultMessage="Close"
        />
      </Button>
    </DialogActions>
  </Dialog>
);
