import { ChangeEventHandler, FunctionComponent, useEffect, useState } from 'react';
import { MenuItem } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { endOfDay, format, isSameDay, parseISO, subDays } from 'date-fns';
import { useDebounce } from '@ourpeople/shared/Core/Hook/useDebounce';

import { StyledSelect, StyledTextField } from './styles';
import { DateRangePickerOffset, DateRangePickerOption, DateRangePickerValue } from '../../Model/DateRangePicker';

interface Props {
  value: DateRangePickerValue;
  onChange: (value: DateRangePickerValue) => void;
  options: DateRangePickerOption[];
  allowCustom?: boolean;
}

/** @deprecated Prefer DateOffsetRangeInput */
export const DateRangePicker: FunctionComponent<Props> = ({
  value,
  onChange,
  options,
  allowCustom,
}) => {
  const [pickingCustomRange, setPickingCustomRange] = useState<boolean>(value.type === 'range');
  const valueId = getDateRangePickerValueId(value);
  const [start, setStart] = useState<string>(value.type === 'range' ? value.start : dateOnly(subDays(new Date(), 1)));
  const [end, setEnd] = useState<string>(value.type === 'range' ? format(parseISO(value.end), 'yyyy-MM-dd') : dateOnly(new Date()));
  const debouncedStart = useDebounce(start, 200);
  const debouncedEnd = useDebounce(end, 200);

  useEffect(() => {
    if (
      pickingCustomRange
      && (
        value.type !== 'range'
        || (debouncedStart !== value.start || !isSameDay(parseISO(value.end), parseISO(debouncedEnd)))
      )
    ) {
      const parsedEnd = parseISO(debouncedEnd);
      const endDate = endOfDay(parsedEnd);

      onChange({
        type: 'range',
        start: debouncedStart,
        end: endDate.toISOString(),
      });
    }
  }, [pickingCustomRange, onChange, value, debouncedStart, debouncedEnd]);

  const whenStartChanged: ChangeEventHandler<HTMLInputElement> = (event) => {
    setStart(event.currentTarget.value);
  }

  const whenEndChanged: ChangeEventHandler<HTMLInputElement> = (event) => {
    setEnd(event.currentTarget.value);
  }

  const whenCustomRangeSelected = () => {
    const now = new Date();
    const start = dateOnly(subDays(new Date(), 1));
    const end = dateOnly(now);
    onChange({
      type: 'range',
      start,
      end,
    });
    setPickingCustomRange(true);
  };

  const whenOffsetSelected = (value: DateRangePickerOffset) => {
    onChange(value);
    setPickingCustomRange(false);
  }

  return (
    <>
      <StyledSelect
        value={ valueId }
        variant="outlined"
        margin="dense"
      >
        {
          options.map(({ label, ...option }) => {
            const id = getDateRangePickerValueId(option);
            return (
              <MenuItem
                key={ id }
                value={ id }
                onClick={ () => whenOffsetSelected(option) }
              >
                { label }
              </MenuItem>
            )
          })
        }
        {
          allowCustom && (
            <MenuItem
              onClick={ whenCustomRangeSelected }
              value="range"
            >
              <FormattedMessage
                id="dateRangePicker.customRangeOption"
                description="Label for custom date range option in date range picker"
                defaultMessage="Custom"
              />
            </MenuItem>
          )
        }
      </StyledSelect>
      {
        pickingCustomRange && (
          <>
            <StyledTextField
              variant="outlined"
              type="date"
              inputProps={ {
                role: 'textbox'
              } }
              margin="dense"
              onChange={ whenStartChanged }
              value={ start }
            />
            <StyledTextField
              variant="outlined"
              type="date"
              inputProps={ {
                role: 'textbox'
              } }
              margin="dense"
              onChange={ whenEndChanged }
              value={ end }
            />
          </>
        )
      }
    </>
  );
}

const dateOnly = (date: Date): string => {
  return date.toISOString().split('T')[0];
}

const getDateRangePickerValueId = (value: DateRangePickerValue): string => {
  return value.type === 'offset'
    ? `offset_${ value.from }${ value.offset ? `_${ value.offset }` : '' }`
    : 'range';
}

