import { ComponentProps, HTMLAttributes, JSX, PropsWithChildren, ReactNode, useCallback } from 'react';

import { DropdownItem } from '../DropdownItem/DropdownItem';
import { DropdownItemCheck } from '../DropdownItemCheck/DropdownItemCheck';
import { ButtonToggledPopover } from '../content/ButtonToggledPopover';
import { SvgComponent } from '../../../lib/model/SvgComponent';
import { PresentationIcon } from '../../../lib/components/PresentationIcon/PresentationIcon';
import { LabelledFormField } from '../../../lib/components/input/LabelledFormField/LabelledFormField';

type Props<T> = {
  buttonContents: ReactNode;
  selectedItems: T[];
  onChange: (selectedItems: T[]) => void;
  options: T[];
  getOptionValue: (option: T) => string;
  getOptionLabel: (option: T) => string;
  getOptionIcon: (option: T) => SvgComponent;
  buttonProps?: HTMLAttributes<HTMLButtonElement>;
} & ComponentProps<typeof LabelledFormField>;

export const Select = <T, >({
  buttonContents,
  selectedItems,
  onChange,
  options,
  getOptionValue,
  getOptionLabel,
  getOptionIcon,
  buttonProps,
  ...labelledFormFieldProps
}: PropsWithChildren<Props<T>>): JSX.Element => {
  const optionsAreEquivalent = useCallback((optionA: T, optionB: T) => (
    getOptionValue(optionA) === getOptionValue(optionB)
  ), [getOptionValue]);
  const whenCheckedChanged = useCallback((item: T, checked: boolean) => (
    onChange(
      checked
        ? selectedItems.concat(item)
        : selectedItems.filter(selectedItem => !optionsAreEquivalent(selectedItem, item))
    )
  ), [optionsAreEquivalent, onChange, selectedItems]);

  const renderItem = useCallback((option: T) => {
    const selected = !!selectedItems.find(selectedItem => optionsAreEquivalent(selectedItem, option));
    return (
      <DropdownItem
        key={ getOptionValue(option) }
        text={ getOptionLabel(option) }
        onClick={ () => {
          whenCheckedChanged(
            option,
            !selected,
          )
        } }
        {
          ...selected
            ? { endAdornment: <DropdownItemCheck/> }
            : {}
        }
        {
          ...getOptionIcon
            ? {
              startAdornment: (
                <PresentationIcon
                  IconComponent={ getOptionIcon(option) }
                  size={ 4 }
                />
              )
            }
            : {}
        }
      />
    )
  }, [getOptionIcon, getOptionLabel, getOptionValue, optionsAreEquivalent, selectedItems, whenCheckedChanged]);

  return (
    <LabelledFormField
      { ...labelledFormFieldProps }
    >
      <ButtonToggledPopover
        buttonContents={ buttonContents }
        buttonVariant="secondary"
        items={ options }
        renderItem={ renderItem }
        getItemKey={ getOptionValue }
        buttonProps={ buttonProps }
      />
    </LabelledFormField>
  );
};
