import { FC, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import { ButtonProps } from '@ourpeople/shared/Core/Component/Input/Button/Button';

import { useBroadcastTemplateGoogleEvents, useFetchBroadcastTemplates } from '../../../Hook';
import { TemplateDialog } from '../../../../Common/Component';
import { useLocalStorageHookContext } from '../../../../Common/Hook';
import { BroadcastTemplatePreviewPane } from '..';
import { BroadcastTransformer } from '../../../Utility';
import { useContentDefinitionRegistry } from '../../../../Content/Hook';

type Props = {
  open: boolean;
  onClose: () => void;
};

export const BroadcastTemplateDialog: FC<Props> = ({
  open,
  onClose,
}) => {
  const { trackPreview, trackRate, trackUse } = useBroadcastTemplateGoogleEvents();
  const contentDefinitionRegistry = useContentDefinitionRegistry();
  const intl = useIntl();
  const getStorageHook = useLocalStorageHookContext();
  const useShowModalWhenCreating = getStorageHook<boolean>('broadcastTemplates.showModalWhenCreating', false);
  const [showModalWhenCreating, setShowModalWhenCreating] = useShowModalWhenCreating();
  const location = useLocation();
  const onCreatePage = useMemo(() => location.pathname.includes('/broadcasts/create'), [location.pathname]);

  const createBlankBroadcastLabel = (
    <FormattedMessage
      id="templateSelectDialog.creatButton"
      description="Create broadcast button label in template modal."
      defaultMessage="Create blank broadcast"
    />
  );

  return (
    <TemplateDialog
      open={ open }
      onClose={ onClose }
      useFetchTemplates={ useFetchBroadcastTemplates }
      getUseButtonProps={ templateWithContent => {
        const [draftBroadcast] = BroadcastTransformer.templateBroadcastToDraft(templateWithContent.broadcast, contentDefinitionRegistry);

        return {
          component: Link,
          to: {
            pathname: '/broadcasts/build-template',
            state: { template: { ...draftBroadcast, templateId: templateWithContent.template.id } }
          },
          onClick: () => trackUse(templateWithContent.template.name),
        };
      } }
      renderPreviewPane={ templateWithContent => (
        <BroadcastTemplatePreviewPane
          broadcast={ templateWithContent.broadcast }
        />
      ) }
      showWhenCreatingLabel={ intl.formatMessage({
        id: 'templateSelectDialog.showModalWhenCreating',
        description: 'Label for checkbox which shows/hides template dialog when creating a broadcast.',
        defaultMessage: 'Show templates when creating a broadcast.',
      }) }
      showWhenCreating={ showModalWhenCreating }
      onShowWhenCreatingChange={ setShowModalWhenCreating }
      createButtonProps={
        onCreatePage
          ? {
            onClick: onClose,
            children: createBlankBroadcastLabel,
          }
          : {
            component: Link,
            to: {
              pathname: '/broadcasts/create',
              state: { hideTemplates: true }
            },
            children: createBlankBroadcastLabel,
          } as ButtonProps<typeof Link>
      }
      feedbackUrl="https://forms.gle/HLNier1AopATJ84G6"
      onPreviewViewed={ broadcastTemplate => trackPreview(broadcastTemplate.template.name) }
      onPositiveFeedbackReceived={ broadcastTemplate => trackRate(broadcastTemplate.template.name, '👍') }
      onNegativeFeedbackReceived={ broadcastTemplate => trackRate(broadcastTemplate.template.name, '👎') }
    />
  );
};
