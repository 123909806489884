export enum BroadcastsPermission {
  ALL = 'broadcasts',
  CREATE = 'broadcasts.create',
  UPDATE = 'broadcasts.update',
  DELETE = 'broadcasts.delete',
  READ = 'broadcasts.read',
  REPORT = 'broadcasts.report',
  ME = 'broadcasts.me',
  METRICS = 'broadcasts.metrics',
  RECALL = 'broadcasts.recall',
  RECALL_OWN = 'broadcasts.recall.own',
  RECALL_ALL = 'broadcasts.recall.all',
  CATEGORIES = 'broadcasts.categories',
  CATEGORIES_ASSIGN = 'broadcasts.categories.assign',
  CATEGORIES_ASSIGN_OWN = 'broadcasts.categories.assign.own',
  CATEGORIES_ASSIGN_ALL = 'broadcasts.categories.assign.all',
  CATEGORIES_CREATE = 'broadcasts.categories.create',
  CATEGORIES_DELETE = 'broadcasts.categories.delete',
  CATEGORIES_READ = 'broadcasts.categories.read',
  CATEGORIES_UPDATE = 'broadcasts.categories.update',
}
