import styled from '@emotion/styled';
import { FormControlLabel } from '@mui/material';

export const StyledFormControlLabel = styled(FormControlLabel)`
  margin-left: 0;
  display: flex;
  align-items: flex-start;

  .MuiFormControlLabel-label {
    font-weight: 500;
  }
`;
