import { FC, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { applyV2GlobalStyles } from '../Utility/applyV2GlobalStyles';
import { BroadcastsRouter } from '../../Broadcasts/Routing/Router/BroadcastsRouter';

export const Root: FC = () => {

  useEffect(applyV2GlobalStyles);

  return (
    <QueryClientProvider client={ queryClient }>
      <main
        css={ {
          height: '100%',
          flexShrink: 1,
          flexGrow: 1,
          minWidth: 0,
        } }
      >
        <BroadcastsRouter/>
      </main>
    </QueryClientProvider>
  );
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
