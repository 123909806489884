import { forwardRef, PropsWithChildren } from 'react';
import { BreakpointContext } from 'op-storybook/lib/providers/BreakpointProvider/BreakpointProvider';

import { useContextOrThrow } from '../../../../Core/Hook';
import { PageLayoutContext } from '../../../Core/Provider/PageLayoutProvider';

type Props = {
  className?: string;
}

export const ScrollArea = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(({ children, className }, ref) => {
  const { controlOverflow, modal } = useContextOrThrow(PageLayoutContext);
  const screenWidth = useContextOrThrow(BreakpointContext);

  return (
    <div
      ref={ ref }
      className={ className }
      css={ (
        (!screenWidth.lessThan.sm && controlOverflow) || modal
          ? {
            height: '100%',
            overflowY: 'auto',
          }
          : {}
      ) }
    >
      { children }
    </div>
  );
});
