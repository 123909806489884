import { FunctionComponent, ImgHTMLAttributes, useCallback, useState } from 'react';
import { LoadingCard } from '@ourpeople/shared/Core/Component/Feedback';

import { Upload } from '../../../Types';
import { ImageUpload, OrientationString } from '..';
import { StyledImagePlaceholder, StyledImageUpload } from './style';

type Props = {
  upload: Upload;
  urlKey?: 'original' | 'thumbnail';
  imgProps?: Omit<ImgHTMLAttributes<HTMLImageElement>, 'src'>;
  onReady?: () => void;
  className?: string;
};

export const ImageUploadWithLoadingIndicator: FunctionComponent<Props> = ({
  upload,
  urlKey = 'original',
  imgProps,
  onReady,
  className,
}) => {
  const orientation: OrientationString = upload.image && upload.image.height > upload.image.width ? 'portrait' : 'landscape';
  const [containerWidth, setContainerWidth] = useState<number>();
  const [containerHeight, setContainerHeight] = useState<number>();

  const containerRef = useCallback((node: HTMLDivElement | null) => {
    if (!node) {
      return;
    }

    setContainerWidth(+node.clientWidth);
    setContainerHeight(+node.clientHeight);
  }, []);

  return (
    <StyledImageUpload
      key={ upload.id }
      orientation={ orientation }
      imageWidth={ upload.image?.width || 0 }
      imageHeight={ upload.image?.height || 0 }
      containerWidth={ containerWidth }
      containerHeight={ containerHeight }
      className={ className }
      ref={ containerRef }
    >
      <ImageUpload
        uploadId={ `${ upload.id }` }
        renderImage={ (imageUrls, isLoaded, onLoad) => (
          <img
            { ...imgProps }
            alt={ imgProps?.alt || '' }
            src={ imageUrls[urlKey] }
            style={ isLoaded ? {} : { display: 'none' } }
            onLoad={ (event) => {
              onLoad(event);
              onReady && onReady();
            } }
          />
        ) }
        loadingElement={ <StyledImagePlaceholder><LoadingCard/></StyledImagePlaceholder> }
      />
    </StyledImageUpload>
  );
};
