import styled from '@emotion/styled';

import { TableCell } from '../../../Components/Content';

export const OpenedHeaderCell = styled(TableCell)`
  display: flex;
  flex-direction: column;
  align-items: center;

  >span {
    line-height: 1.25rem;

    &:last-of-type {
      font-weight: 400;
    }
  }
`;
