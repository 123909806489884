import { Checkbox, InputBase, Radio } from '@mui/material';
import styled from '@emotion/styled';

import ChevronIcon from '../../../../Assets/img/icons/monochrome/chevron.svg';

export const AppFormInput = styled(InputBase)`
  border: 2px solid ${ props => props.theme.palette.grey[300] };
  border-radius: 0.35rem;
  width: 100%;
  margin-top: 0.5rem;

  .MuiSelect-root {
    padding-left: 0.35rem;
  }
`;

export const StyledRadio = styled(Radio)`
  padding: 0 0.5rem 0 0;
`;

export const StyledCheckbox = styled(Checkbox)`
  padding: 0 0.5rem 0 0;
`;

export const DropdownChevronIcon = styled(ChevronIcon)`
  height: 24px;
`;
