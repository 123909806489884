import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { AutocompleteCommonProps, AutocompleteFetchRequest, AutocompleteSelectionChanged } from '@ourpeople/shared/Core/Component/Input/Autocomplete/Autocomplete';
import { LocalisedString } from 'op-storybook/lib/model/LocalisedString/LocalisedString';

import { InvitationStatus, useInvitationStatuses } from '../../Hook';
import { DebouncedAutocomplete } from '../../../Common/Component';

export interface InvitationStatusAutocompleteProps extends AutocompleteCommonProps {
  selectedIds: string[];
  allowedIds?: string[];
  onSelectionChanged: AutocompleteSelectionChanged<InvitationStatusOption>;
}

type InvitationStatusOption = LocalisedString<InvitationStatus>;

export const InvitationStatusAutocomplete = ({
  selectedIds,
  allowedIds,
  onSelectionChanged,
  ...props
}: InvitationStatusAutocompleteProps): JSX.Element => {
  const intl = useIntl();
  const availableStatuses = useInvitationStatuses();

  const whenFetch: AutocompleteFetchRequest<InvitationStatusOption> = () => {
    return Promise.resolve({
      options: availableStatuses
        .filter(status => !allowedIds || allowedIds.indexOf(status.id) > -1),
      pageCount: 1,
    });
  }

  return <DebouncedAutocomplete<InvitationStatusOption>
    {...props}
    getOptionLabel={option => option.localisation}
    fetchOptions={useCallback(whenFetch, [allowedIds, availableStatuses])}
    selectedIds={selectedIds}
    onSelectionChanged={onSelectionChanged}
    label={
      props.label !== undefined
        ? props.label
        : intl.formatMessage(
          {
            id: 'invitationStatusAutocomplete.label',
            defaultMessage: 'Select {limit, plural, one {status} other {statuses}}'
          },
          {
            limit: props.multiple ? Infinity : 1,
          }
        )
    }
    placeholder={
      props.placeholder ||
      intl.formatMessage(
        {
          id: 'invitationStatusAutocomplete.placeholder',
          defaultMessage: 'Search for {limit, plural, one {status} other {statuses}}'
        },
        {
          limit: props.multiple ? Infinity : 1,
        }
      )
    }
  />;
};
