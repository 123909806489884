import { FC, useCallback } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';

import { useCancellableGet } from '../../../Hooks';
import { UploadUrls } from '../../../Common/Hook';

type Props = {
  fileId?: string;
  variant?: 'primary' | 'secondary' | 'tertiary';
  onError?: (error: AxiosError<unknown>) => void;
  busy?: boolean;
};

export const OpenFileButton: FC<Props> = ({
  fileId,
  variant = 'tertiary',
  onError,
  busy = false,
  children,
}) => {
  const whenFetchUrlsSuccessful = useCallback((response: AxiosResponse<UploadUrls>) => {
    window.open(response.data.original, '_blank');
  }, []);

  const whenFetchUrlsFailed = useCallback((error: AxiosError<unknown>) => (
    onError && onError(error)
  ), [onError]);

  const [openFile, openingFile] = useCancellableGet<UploadUrls>(
    `/uploads/${fileId || ''}/urls`,
    whenFetchUrlsSuccessful,
    whenFetchUrlsFailed,
  );

  const whenClicked = () => {
    if (fileId) {
      openFile();
    }
  };

  return (
    <Button
      onClick={ whenClicked }
      variant={ variant }
      disabled={ !fileId }
      busy={ openingFile || busy }
    >
      { children }
    </Button>
  );
};
