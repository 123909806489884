import styled from '@emotion/styled';

export const MissingDataMessage = styled.div`
  text-align: center;
  height: 100%;
  min-height: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  a {
    color: ${ props => props.theme.palette.secondary.main };
  }
`;
