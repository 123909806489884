import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';

import { BroadcastContentFooter, StackNavigationProps } from '../../../Content/Component';
import { VerticallySpaced } from '../../../Common/Component';
import { ContentPadding } from '../../../Content/Component';
import { useMobileViewActive } from '../../../Navigation/Hook';

type Props = {
  navigationProps: StackNavigationProps;
};

export const StackEndCard: FC<Props> = ({
  navigationProps,
}) => {
  const mobileViewActive = useMobileViewActive();
  const history = useHistory();
  const whenFinishButtonClicked = () => history.push(`/inbox${ history.location.search }`);

  return (
    <>
      <ContentPadding>
        <VerticallySpaced
          gap={ 2 }
          align="center"
        >
          <h1>
            <FormattedMessage
              id="inbox.stackEndCard.heading"
              description="heading for end of stack card."
              defaultMessage="That's it!"
            />
          </h1>
          <p>
            <FormattedMessage
              id="inbox.stackEndCard.text"
              description="Text for end of stack card."
              defaultMessage="You've reached the end of this stack."
            />
          </p>
          { mobileViewActive && (
            <Button
              variant="primary"
              onClick={ whenFinishButtonClicked }
            >
              <FormattedMessage
                id="inbox.stackEndCard.finish"
                description="Label for finish button at end of stack"
                defaultMessage="Finish"
              />
            </Button>
          ) }
        </VerticallySpaced>
      </ContentPadding>
      <BroadcastContentFooter { ...navigationProps }/>
    </>
  );
};
