import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { LineClamp } from 'op-storybook/stories/components/LineClamp/LineClamp';

import { MenuSectionDivider, SpaceIcon } from '..';
import {
  StyledAccountInfo,
  StyledAccountSwitchButton,
  StyledAvatarContainer,
  StyledEditProfileButton,
  StyledMainMenuHeader,
  StyledSpaceIconContainer,
  StyledSpaceName,
  StyledUserName,
  StyledSwitchAccountIcon,
} from './style';
import { PersonUuidAvatar } from '../../../../Common/Component/PersonUuidAvatar/PersonUuidAvatar';

type Props = {
  personUuid: string;
  spaceLogoSrc: string;
  spaceName: string;
  userName: string;
  onSwitchAccountClicked: () => void;
  onEditProfileClicked: () => void;
  userHasAvatar: boolean;
};

export const MainMenuHeader: FC<Props> = ({
  userHasAvatar,
  personUuid,
  spaceLogoSrc,
  userName,
  spaceName,
  onSwitchAccountClicked,
  onEditProfileClicked,
}) => (
  <>
    <StyledMainMenuHeader>
      <StyledAvatarContainer>
        <PersonUuidAvatar
          personUuid={ personUuid }
          personName={ userName }
          waitForSrc={ userHasAvatar }
          size="md"
        />
        <StyledSpaceIconContainer>
          <SpaceIcon
            spaceName={ spaceName }
            path={ spaceLogoSrc }
          />
        </StyledSpaceIconContainer>
      </StyledAvatarContainer>
      <StyledAccountInfo>
        <StyledUserName><LineClamp lines={ 2 }>{ userName }</LineClamp></StyledUserName>
        <StyledSpaceName><LineClamp lines={ 2 }>{ spaceName }</LineClamp></StyledSpaceName>
        <StyledEditProfileButton onClick={ onEditProfileClicked }>
          <FormattedMessage
            id="MainMenu.Component.MainMenuHeader.editProfile"
            description="Label for edit profile button in menu."
            defaultMessage="Edit profile"
          />
        </StyledEditProfileButton>
      </StyledAccountInfo>
      <div/>
      <div>
        <StyledAccountSwitchButton onClick={ onSwitchAccountClicked }>
          <StyledSwitchAccountIcon/>
          <span>
            <FormattedMessage
              id="MainMenu.Component.MainMenuHeader.switchAccounts"
              description="Label for switch accounts button in menu."
              defaultMessage="Switch accounts"
            />
          </span>
        </StyledAccountSwitchButton>
      </div>
    </StyledMainMenuHeader>
    <MenuSectionDivider/>
  </>
);
