import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { CenteredMessageWithButton, CenteredMessageWithLoadingCard } from '@ourpeople/shared/Core/Component/Content';

import { useFetchPersonalForm } from '../../Hook';
import { EmptyCard } from '../../../Content/Component';
import { FormNavigationProvider } from '../../Provider/FormNavigationProvider';
import { FormCards } from '../FormCards/FormCards';

type Props = {
  formId: string;
};

export const LiveForm: FC<Props> = ({
  formId,
}) => {
  const intl = useIntl();
  const [fetchFormResult, , reloadForm] = useFetchPersonalForm(formId);

  return (
    fetchFormResult?.content
      ? (
        <FormNavigationProvider>
          <FormCards
            anonymousSubmissionsEnabled={ !!fetchFormResult.content.form.anonymousSubmissionsEnabled }
            cards={ fetchFormResult.content.cards }
            formName={ fetchFormResult.content.form.name }
            formId={ formId }
          />
        </FormNavigationProvider>
      )
      : fetchFormResult?.error
        ? (
          <EmptyCard>
            <CenteredMessageWithButton
              heading={ intl.formatMessage({
                description: 'Heading when an unexpected error occurs when loading form contents.',
                defaultMessage: 'Something went wrong!'
              }) }
              body={ intl.formatMessage({
                description: 'Message when an unexpected error occurs when loading form contents.',
                defaultMessage: 'Please try again or select another form.'
              }) }
              buttonLabel={ intl.formatMessage({
                description: 'Label for retry button error occurs when loading form contents.',
                defaultMessage: 'Try again'
              }) }
              onButtonClicked={ reloadForm }
            />
          </EmptyCard>
        )
        : (
          <EmptyCard>
            <CenteredMessageWithLoadingCard
              heading={ intl.formatMessage({
                description: 'Message heading when form or content are loading.',
                defaultMessage: 'It\'s a big one!',
              }) }
              body={ intl.formatMessage({
                description: 'Message body when form or content are loading.',
                defaultMessage: 'Just making sure we\'ve got it all.',
              }) }
            />
          </EmptyCard>
        )
  );
};
