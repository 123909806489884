import {forwardRef, PropsWithChildren, ReactNode, useMemo} from 'react';
import {StyleBuilder} from 'op-storybook/lib/model/StyleBuilder/StyleBuilder';

import {ScrollArea} from './ScrollArea';

type Props = {
  clear?: ('top' | 'bottom')[];
  children?: ReactNode;
  className?: string
  fillParent?: boolean;
  scrollable?: boolean;
}

export const ToolbarClearance = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(({
  clear = ['top', 'bottom'],
  children,
  className,
  fillParent,
  scrollable,
}, ref) => {
  const styles = useMemo(() => buildStyles({clear, fillParent}), [clear, fillParent]);

  if (scrollable) {
    return (
      <ScrollArea
        ref={ ref }
        css={styles.root}
        className={className}
      >
        {children}
      </ScrollArea>
    );
  }

  return (
    <div
      ref={ ref }
      css={styles.root}
      className={className}
    >
      {children}
    </div>
  );
});

const buildStyles: StyleBuilder<Props> = ({
  clear,
  fillParent,
}) => ({
  root: theme => ({
    position: 'relative',
    ...clear?.includes('top')
      ? {paddingTop: theme.new.toolbar.offset.top}
      : {},
    ...clear?.includes('bottom')
      ? {paddingBottom: theme.new.toolbar.offset.bottom}
      : {},
    ...fillParent ? {height: '100%',} : {},
  }),
});
