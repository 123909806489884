import { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { ContentPreviewProps } from '../../../../Content/Model';
import { FileEditorContent } from '../../../Service';
import { MediaContentPreview } from '..';
import FileIcon from '../../../../Assets/img/icons/monochrome/content/file.svg';

export const FileContentPreview: FC<ContentPreviewProps<FileEditorContent>> = ({
  editorContent,
  onReady,
}) => {
  const intl = useIntl();

  useEffect(() => {
    onReady();
  }, [onReady]);

  return (
    <MediaContentPreview
      editorContent={ editorContent }
      IconComponent={ FileIcon }
      localisedContentType={ intl.formatMessage({
        id: 'broadcasts.content.preview.file.type',
        description: 'Label for file content type used in preview.',
        defaultMessage: 'File',
      }) }
      localisedButtonLabel={ intl.formatMessage({
        id: 'broadcasts.content.preview.file.openLabel',
        description: 'Label for open file button used in file preview.',
        defaultMessage: 'Open file',
      }) }
    />
  );
};
