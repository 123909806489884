import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { InnerNavigationButton, NavigationButton } from '..';
import { StyledMainMenuFooter } from './style';
import MuteIcon from '../../../../Assets/img/icons/streamline/volume-control-mute.svg';
import SupportIcon from '../../../../Assets/img/icons/streamline/question-help-message.svg';
import SettingsIcon from '../../../../Assets/img/icons/streamline/cog.svg';

type Props = {
  onSnoozeNotificationsClicked: () => void;
  onSupportClicked: () => void;
  onSettingsClicked: () => void;
};

export const MainMenuFooter: FC<Props> = ({
  onSnoozeNotificationsClicked,
  onSupportClicked,
  onSettingsClicked,
}) => (
  <StyledMainMenuFooter>
    <NavigationButton onClick={ onSnoozeNotificationsClicked }>
      <InnerNavigationButton
        Icon={ MuteIcon }
      >
        <FormattedMessage
          id="MainMenu.Component.MainMenuFooter.snooze"
          description="Label for snooze button in side menu"
          defaultMessage="Snooze notifications"
        />
      </InnerNavigationButton>
    </NavigationButton>
    <NavigationButton onClick={ onSupportClicked }>
      <InnerNavigationButton
        Icon={ SupportIcon }
      >
        <FormattedMessage
          id="MainMenu.Component.MainMenuFooter.support"
          description="Label for support button in side menu"
          defaultMessage="Support"
        />
      </InnerNavigationButton>
    </NavigationButton>
    <NavigationButton onClick={ onSettingsClicked }>
      <InnerNavigationButton
        Icon={ SettingsIcon }
      >
        <FormattedMessage
          id="MainMenu.Component.MainMenuFooter.settings"
          description="Label for settings button in side menu"
          defaultMessage="Settings"
        />
      </InnerNavigationButton>
    </NavigationButton>
  </StyledMainMenuFooter>
);
