import { FunctionComponent, useState } from 'react';
import { DialogContent, DialogTitle, IconButton } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { Flex, FlexPullRight, StatusChip } from '../../../../Common/Component';
import { PersonParser } from '../../../../Utility';
import CloseIcon from '../../../../Assets/img/icons/streamline/cross.svg';
import { PersonProfile } from '../..';
import { PeopleImportOperation } from '../../../Hook';
import { StyledProfileList, StyledProfileSummaryDialog } from './style';

type Props = {
  importOperation?: PeopleImportOperation;
  onClose: () => void;
}

export const ProfileSummaryDialog: FunctionComponent<Props> = ({
  importOperation,
  onClose,
}) => {
  const [closing, setClosing] = useState<boolean>(false);

  return (
    <StyledProfileSummaryDialog
      open={ !!importOperation && !closing }
      onClose={ () => setClosing(true) }
      TransitionProps={ {
        onExited: () => {
          setClosing(false);
          onClose();
        },
      } }
      fullWidth
      scroll="body"
    >
      <DialogTitle>
        <Flex>
          <FormattedMessage
            id="people.import.profileDetail"
            description="Profile detail modal total on summary step of people import."
            defaultMessage="{name}'s profiles"
            values={ {
              name: importOperation && PersonParser.fullName(importOperation.person),
            } }
          />
          <FlexPullRight>
            <IconButton aria-label="close" onClick={ () => setClosing(true) } size="large">
              <CloseIcon/>
            </IconButton>
          </FlexPullRight>
        </Flex>
      </DialogTitle>
      <DialogContent>
        <StyledProfileList>
          {
            importOperation?.profiles.map(profileOperation => (
              <li
                key={ profileOperation.profileId }
              >
                <StatusChip
                  size="small"
                  status={ profileOperation.operationType === 'create' ? 'success' : 'warning' }
                  label={
                    profileOperation.operationType === 'create'
                      ? (
                        <FormattedMessage
                          id="people.import.profileCreated"
                          description="Label for new profiles in profile summary dialog."
                          defaultMessage="New"
                        />
                      )
                      : (
                        <FormattedMessage
                          id="people.import.profileUpdated"
                          description="Label for updated profiles in profile summary dialog."
                          defaultMessage="Updated"
                        />
                      )
                  }
                />
                <PersonProfile
                  tags={ profileOperation.tags }
                />
              </li>
            ))
          }
        </StyledProfileList>
      </DialogContent>
    </StyledProfileSummaryDialog>
  );
}
