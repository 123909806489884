import { MediaContent, MediaEditorContent } from '../Model';
import { UniqueIdGenerator } from '../../Common/Utility';
import { CardTransformer } from '../../Content/Utility';
import { SingleContentCard } from '../../Content/Model';

export class MediaContentHelper {
  public static createEditorContent = <T extends string>(type: T): MediaEditorContent<T> => ({
    id: UniqueIdGenerator.generate(),
    media: null,
    card: {
      content: {
        type,
        text: '',
        mandatory: false,
        media: '',
      },
      mandatory: false,
      image: null,
    },
  });

  public static transformCard = <T extends string>(type: T, card: SingleContentCard<MediaContent<T>>): MediaEditorContent<T> => ({
    id: card.id,
    media: card.content.media,
    card: CardTransformer.transformCard(card, {
      type,
      text: card.content.text,
      media: card.content.media?.id.toString(),
      mandatory: card.mandatory,
    }),
  });
}
