import { FunctionComponent, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@mui/material';

import { TableCell, TableRow } from '../../../Components';
import { ApiClient } from '../../Model';
import { useFetchApiClients } from '../../Hook';
import { AsyncTable } from '../../../Common/Component';
import { StyledHeading } from './style';

type Props = {
  addedClients: ApiClient[];
  onAddButtonClicked: () => void;
}

export const ApiClientsTable: FunctionComponent<Props> = ({ addedClients, onAddButtonClicked }) => {
  const [fetchApiClientsResponse, fetchApiClientsRequestState, retryFetchApiClients] = useFetchApiClients();
  const apiClients = useMemo<ApiClient[]>(() => {
    const clients = fetchApiClientsResponse?.content;

    if (!clients) {
      return addedClients;
    }

    return clients.concat(addedClients.filter(addedClient => !clients.find(client => addedClient.id === client.id)));
  }, [fetchApiClientsResponse, addedClients]);

  return (
    <>
      <StyledHeading>
        <FormattedMessage
          id="settings.apiClients.heading"
          description="Heading that appears above the list of API clients"
          defaultMessage="API Clients"
        />
      </StyledHeading>
      <AsyncTable
        rows={ apiClients }
        rowRender={ apiClient => (
          <TableRow key={ apiClient.id }>
            <TableCell>{ apiClient.name }</TableCell>
            <TableCell>{ apiClient.id }</TableCell>
          </TableRow>
        ) }
        headerRow={
          <TableRow>
            <TableCell>
              <FormattedMessage
                id="apiClients.list.name"
                defaultMessage="Name"
              />
            </TableCell>
            <TableCell width="35%">
              <FormattedMessage
                id="apiClients.list.clientId"
                defaultMessage="Client ID"
              />
            </TableCell>
          </TableRow>
        }
        requestState={ fetchApiClientsRequestState }
        onRetryClicked={ retryFetchApiClients }
        noResultsMessage={
          <>
            <span>
              <FormattedMessage
                id="apiClients.list.noResults"
                defaultMessage="You have no API clients!"
              />
            </span>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              onClick={ onAddButtonClicked }
            >
              <FormattedMessage
                id="apiClients.list.create"
                defaultMessage="Create One"
              />
            </Button>
          </>
        }
      />
    </>
  );
};
