import styled from '@emotion/styled';
import { List } from '@mui/material';

export const StyledList = styled(List)`
  overflow-y: auto;
  max-height: 500px;
  cursor: pointer;

  .MuiListItem-root:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;
