import styled from '@emotion/styled';
import * as RadCheckbox from '@radix-ui/react-checkbox';

export const StyledCheckbox = styled.div`
  background-color: ${ props => props.theme.new.basePalette.white.main };
  border: ${ props => props.theme.new.border.standard };
  border-radius: ${ props => props.theme.new.borderRadius.small };
  width: ${ props => props.theme.new.spacing[5] };
  height: ${ props => props.theme.new.spacing[5] };
  display: flex;
  justify-content: center;
  align-items: center;
  transition: border-color 0.1s,
    background-color 0.1s,
    box-shadow 0.2s ${ props => props.theme.new.animationEasing.elastic };
`;

export const StyledRoot = styled(RadCheckbox.Root)`
  background: initial;
  border: initial;
  width: min-content;
  height: min-content;
  padding: ${ props => props.theme.new.spacing[4] };
  margin: -${ props => props.theme.new.spacing[4] };
  color: ${ props => props.theme.new.palette.primary[600].main };

  >div {
    ${ props => !!props.checked && `
      background-color: ${ props.theme.new.palette.primary[50].main };
      border-color: ${ props.theme.new.palette.primary[600].main };
    ` };
  }

  &:hover >div {
    background-color: ${ props => props.theme.new.palette.primary[100].main };
    border-color: ${ props => props.theme.new.palette.primary[600].main };
  }

  &:focus >div {
    border-color: ${ props => props.checked
      ? props.theme.new.palette.primary[600].main
      : props.theme.new.palette.primary[300].main
    };
    box-shadow: ${ props => props.theme.new.shadow.focusRing };
  }

  &:disabled >div {
    background-color: ${ props => props.theme.new.palette.grey[100].main };
    border-color: ${ props => props.theme.new.palette.grey[300].main };
    color: ${ props => props.theme.new.palette.grey[300].main };
  }

  &:focus-visible {
    outline: none;
  }
`;

export const StyledIndicator = styled(RadCheckbox.Indicator)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
`;
