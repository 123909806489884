import { FC, ReactNode, useCallback, useMemo } from 'react';

import { PushMessageDefinitionRegistryContext, PushMessageDefinitionRegistryContextValue } from '../Context/PushMessageDefinitionRegistryContext';
import { MessagePayload, PushMessageDefinition } from '../Model/PushMessage';

type Props = {
  pushMessageDefinitions: PushMessageDefinition[];
  children?: ReactNode;
};

export const PushMessageDefinitionRegistryProvider: FC<Props> = ({
  pushMessageDefinitions,
  children,
}) => {
  const getMessageDefinition = useCallback((message: MessagePayload): PushMessageDefinition | undefined => (
    pushMessageDefinitions.find(definition => (
      definition.defines(message)
    ))
  ), [pushMessageDefinitions]);

  const onClick = useCallback((message: MessagePayload, onDismiss: () => void) => {
    const definition = getMessageDefinition(message);

    if (!definition?.onClick) {
      return;
    }

    definition.onClick(message, onDismiss);
  }, [getMessageDefinition]);

  const onReceive = useCallback((message: MessagePayload, onDismiss: () => void) => {
    const definition = getMessageDefinition(message);

    if (!definition?.onReceive) {
      return;
    }

    definition.onReceive(message, onDismiss);
  }, [getMessageDefinition]);

  const discard = useCallback((message: MessagePayload): boolean => {
    const definition = getMessageDefinition(message);

    if (!definition?.discard) {
      return false;
    }

    return definition.discard(message);
  }, [getMessageDefinition]);

  const contextValue = useMemo<PushMessageDefinitionRegistryContextValue>(() => ({
    onClick,
    onReceive,
    discard,
  }), [discard, onClick, onReceive]);

  return (
    <PushMessageDefinitionRegistryContext.Provider value={ contextValue }>
      { children }
    </PushMessageDefinitionRegistryContext.Provider>
  );
};
