import React, { FunctionComponent, ReactChild, SVGProps } from 'react';
import { CircularProgress } from '@mui/material';
import { IconButtonProps as MuiButtonProps, IconButtonTypeMap } from '@mui/material/IconButton/IconButton';

import { ConditionalTooltip } from '../ConditionalTooltip/ConditionalTooltip';
import { PresentationIcon } from '../PresentationIcon/PresentationIcon';
import { StyledIconButton } from './style';

export type IconButtonProps<
  D extends React.ElementType = IconButtonTypeMap['defaultComponent'],
  P = Record<string, unknown>
> = Omit<MuiButtonProps<D, P>, 'color'> & {
  disabled?: boolean;
  disabledTooltip?: ReactChild;
  IconComponent: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string }>;
  label: string;
  color?: string;
  busy?: boolean;
  showTooltip?: boolean;
  size?: 'xs' | 'small' | 'medium' | 'large';
};

/** @deprecated Prefer IconButton from op-storybook project */
export const IconButton: FunctionComponent<IconButtonProps> = ({
  IconComponent,
  busy = false,
  label,
  disabled = false,
  disabledTooltip,
  color,
  showTooltip = false,
  size,
  ...props
}) => (
  <ConditionalTooltip
    active={ showTooltip || !!(disabled && disabledTooltip) }
    title={ disabled && disabledTooltip ? disabledTooltip : label }
  >
    <div>
      <StyledIconButton
        aria-label={ label }
        disabled={ disabled }
        { ...props }
      >
        {
          busy
            ? (
              <CircularProgress
                color="secondary"
                size={ 24 }
              />
            )
            : (
              <PresentationIcon
                IconComponent={ IconComponent }
                color={ disabled ? 'none.main' : color }
                size={ size }
              />
            )
        }
      </StyledIconButton>
    </div>
  </ConditionalTooltip>
);
