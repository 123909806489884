import { FunctionComponent, useCallback, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { CircularProgress, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { useIntl } from 'react-intl';

import { Form, FormsPermission } from '../../Model';
import { TableMenu } from '../../../Components/Content/TableMenu';
import TrendingUpIcon from '../../../Assets/img/icons/streamline/graph-stats-ascend.svg';
import EditIcon from '../../../Assets/img/icons/streamline/common-file-edit.svg';
import PublishIcon from '../../../Assets/img/icons/streamline/button-play-1.svg';
import UnpublishIcon from '../../../Assets/img/icons/streamline/button-stop.svg';
import DeleteIcon from '../../../Assets/img/icons/streamline/bin-1.svg';
import DuplicateIcon from '../../../Assets/img/icons/streamline/layers-back.svg';
import { PublishTooltip } from '..';
import { useApi, useContextOrThrow } from '../../../Core/Hook';
import { useMounted } from '../../../Common/Hook';
import { usePermissions } from '../../../Security/Hook';
import { DeletePrompt } from '../../../Common/Component';
import { ToastContext } from '../../../Core/Context';

interface Props {
  form: Form;
  reloadForms: () => void;
  publishStatusesString: string;
  userCanEditForm: boolean;
}

export const FormsTableMenu: FunctionComponent<Props> = ({
  reloadForms,
  form,
  publishStatusesString,
  userCanEditForm,
}) => {
  const history = useHistory();
  const [busy, setBusy] = useState<boolean>(false);
  const api = useApi();
  const intl = useIntl();
  const formName = form.name || intl.formatMessage({
    id: 'form.genericName',
    defaultMessage: 'Form',
  });
  const formCanBePublished = !!(form.name && form.category);
  const mounted = useMounted();
  const { addErrorToast, addSuccessToast } = useContextOrThrow(ToastContext);
  const { permissionAvailable, guardedCallback } = usePermissions();
  const [deleteConfirmMounted, setDeleteConfirmMounted] = useState<boolean>(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);

  const whenPublishClicked = (): void => {
    setBusy(true);
    api.post(`/forms/${ form.id }/publish`)
      .then(() => {
        setTimeout(() => {
          if (!mounted.current) {
            return;
          }

          setBusy(false);
          addSuccessToast(
            intl.formatMessage({
              id: 'forms.publish.successMessage',
              defaultMessage: '{formName} published successfully.',
            }, {
              formName,
            })
          );
          reloadForms();
        }, 50);
      })
      .catch(() => {
        if (!mounted.current) {
          return;
        }

        setBusy(false);
        addErrorToast(
          intl.formatMessage({
            id: 'forms.publish.failureMessage',
            defaultMessage: '{formName} could not be published.',
          }, {
            formName,
          })
        );
      });
  };

  const whenDeleteConfirmed = (): void => {
    setDeleteConfirmOpen(false);
    setBusy(true);
    api.delete(`/forms/${ form.id }`)
      .then(() => {
        setTimeout(() => {
          if (!mounted.current) {
            return;
          }

          setBusy(false);
          addSuccessToast(
            intl.formatMessage({
              id: 'forms.delete.successMessage',
              defaultMessage: '{formName} deleted successfully.',
            }, {
              formName,
            })
          );
          reloadForms();
        }, 50);
      })
      .catch(() => {
        if (!mounted.current) {
          return;
        }

        setBusy(false);
        addErrorToast(
          intl.formatMessage({
            id: 'forms.delete.failureMessage',
            defaultMessage: '{formName} could not be deleted.',
          }, {
            formName,
          })
        );
      });
  };

  const whenUnpublishClicked = (): void => {
    setBusy(true);
    api.post(`/forms/${ form.id }/unpublish`)
      .then(() => {
        setTimeout(() => {
          if (!mounted.current) {
            return;
          }

          setBusy(false);
          addSuccessToast(
            intl.formatMessage({
              id: 'forms.unpublish.successMessage',
              defaultMessage: '{formName} unpublished successfully.',
            }, {
              formName,
            })
          );
          reloadForms();
        }, 50);
      })
      .catch(() => {
        if (!mounted.current) {
          return;
        }

        setBusy(false);
        addErrorToast(
          intl.formatMessage({
            id: 'forms.unpublish.failureMessage',
            defaultMessage: '{formName} could not be unpublished.',
          }, {
            formName,
          })
        );
      });
  };

  const whenDuplicateClicked = useCallback(() => {
    setBusy(true);
    api.post(`/forms/${ form.id }/duplicate`)
      .then(() => {
        setTimeout(() => {
          if (!mounted.current) {
            return;
          }

          setBusy(false);
          addSuccessToast(
            intl.formatMessage({
              id: 'forms.duplicate.successMessage',
              description: 'Toast message when form is duplicated successfully from forms list.',
              defaultMessage: '{formName} copied successfully.',
            }, {
              formName,
            })
          );
          history.location.search.includes('publishStatus=draft')
            ? reloadForms()
            : history.push('/forms?publishStatus=draft');
        }, 50);
      })
      .catch(() => {
        if (!mounted.current) {
          return;
        }

        setBusy(false);
        addErrorToast(
          intl.formatMessage({
            id: 'forms.duplicate.failureMessage',
            description: 'Toast message when form could not be duplicated from forms list.',
            defaultMessage: '{formName} could not be copied.',
          }, {
            formName,
          })
        );
      });
  }, [addErrorToast, addSuccessToast, api, form.id, formName, history, intl, mounted, reloadForms]);

  const publishMenuItem = (
    <MenuItem
      disabled={ !formCanBePublished }
      style={ !formCanBePublished ? { pointerEvents: 'none' } : undefined }
      onClick={ whenPublishClicked }
    >
      <ListItemIcon><PublishIcon/></ListItemIcon>
      <ListItemText
        primary={
          intl.formatMessage({
            id: 'forms.list.publish',
            defaultMessage: 'Publish',
          })
        }
      />
    </MenuItem>
  );

  const whenDeleteClicked = useCallback(() => {
    setDeleteConfirmOpen(true);
    setDeleteConfirmMounted(true);
  }, []);

  return (
    <>
      { busy
        ? (
          <CircularProgress
            variant="indeterminate"
            color="secondary"
            size={ 24 }
          />
        )
        : (
          <TableMenu rowKey={ form.id }>
            { publishStatusesString !== 'draft' && (
              <MenuItem
                component={ Link }
                to={ `/forms/${ form.id }/report` }
              >
                <ListItemIcon><TrendingUpIcon/></ListItemIcon>
                <ListItemText
                  primary={
                    intl.formatMessage({
                      id: 'forms.list.viewReport',
                      defaultMessage: 'View report',
                    })
                  }
                />
              </MenuItem>
            ) }
            { userCanEditForm && (
              <MenuItem
                component={ Link }
                to={ `/forms/${ form.id }/edit` }
              >
                <ListItemIcon><EditIcon/></ListItemIcon>
                <ListItemText
                  primary={
                    intl.formatMessage({
                      id: 'forms.list.edit',
                      defaultMessage: 'Edit',
                    })
                  }
                />
              </MenuItem>
            ) }
            { permissionAvailable(FormsPermission.CREATE) && (
              <MenuItem
                onClick={ guardedCallback(whenDuplicateClicked, [FormsPermission.CREATE]) }
              >
                <ListItemIcon><DuplicateIcon/></ListItemIcon>
                <ListItemText
                  primary={
                    intl.formatMessage({
                      id: 'forms.list.duplicate',
                      description: 'Label for duplicate form menu item in forms list.',
                      defaultMessage: 'Make a copy',
                    })
                  }
                />
              </MenuItem>
            ) }
            { userCanEditForm && publishStatusesString !== 'published' && (
              formCanBePublished
                ? publishMenuItem
                : (
                  <PublishTooltip>{ publishMenuItem }</PublishTooltip>
                )
            ) }
            { userCanEditForm && publishStatusesString === 'published' && (
              <MenuItem onClick={ whenUnpublishClicked }>
                <ListItemIcon><UnpublishIcon/></ListItemIcon>
                <ListItemText
                  primary={
                    intl.formatMessage({
                      id: 'forms.list.unpublish',
                      defaultMessage: 'Unpublish',
                    })
                  }
                />
              </MenuItem>
            ) }
            { userCanEditForm && (
              <MenuItem onClick={ whenDeleteClicked }>
                <ListItemIcon><DeleteIcon/></ListItemIcon>
                <ListItemText
                  primary={
                    intl.formatMessage({
                      id: 'forms.list.delete',
                      defaultMessage: 'Delete',
                    })
                  }
                />
              </MenuItem>
            ) }
          </TableMenu>
        )
      }
      { deleteConfirmMounted && (
        <DeletePrompt
          TransitionProps={ {
            onExited: () => setDeleteConfirmMounted(false),
          } }
          open={ deleteConfirmOpen }
          onCancel={ () => setDeleteConfirmOpen(false) }
          onConfirm={ whenDeleteConfirmed }
        />
      ) }
    </>
  );
};
