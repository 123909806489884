import { FC, Fragment, JSX, JSXElementConstructor, useCallback, useMemo, } from 'react';
import { Link } from 'react-router-dom';
import { ProgressCircle } from 'op-storybook/stories/components/ProgressCircle/ProgressCircle';
import { PresentationIcon } from 'op-storybook/lib/components/PresentationIcon/PresentationIcon';
import HomeIcon from 'op-storybook/lib/assets/icon/figma/home-line.svg';
import ChevronIcon from 'op-storybook/lib/assets/icon/figma/chevron-right.svg';
import { ButtonBase, ButtonBaseProps } from 'op-storybook/stories/components/ButtonBase/ButtonBase';

import { BreadcrumbItem } from '../PageHeader/PageHeader';

type Props = {
  items: (BreadcrumbItem | null)[];
  includeBackground?: boolean;
};

export const Breadcrumb: FC<Props> = ({
  items,
  includeBackground = false,
}) => {
  const reversedItems = useMemo(() => {
    return items.slice()
      .reverse();
  }, [items]);
  const renderButton = useCallback((item: BreadcrumbItem | null, final: boolean, index: number) => {
    const key = getItemKey(item, index);
    const additionalProps = getButtonProps(item);

    return (
      <Fragment key={ key }>
        <BreadcrumbButton
          { ...additionalProps }
        />
        { !final && <Chevron/> }
      </Fragment>
    );
  }, []);

  const inlineBreadcrumbItems = useMemo(() => (
    reversedItems.map((item, itemIndex) => (
      renderButton(
        item,
        itemIndex >= reversedItems.length - 1,
        itemIndex,
      )
    ))
  ), [renderButton, reversedItems]);

  return (
    <div
      css={ theme => ({
        '--breadcrumb-background': includeBackground ? theme.new.palette.grey[25].main : theme.new.basePalette.white.main,
        backgroundColor: 'var(--breadcrumb-background)',
        borderRadius: theme.new.borderRadius.standard,
        padding: theme.new.spacing[1],
        display: 'inline-flex',
        flexDirection: 'row-reverse',
        maxWidth: '100%',
        gap: 4,
        overflow: 'auto',
        width: 'max-content'
      }) }
    >
      { inlineBreadcrumbItems }
      <div
        css={ {
          display: 'flex',
          flexDirection: 'row-reverse',
          position: 'sticky',
          left: -6,
          background: 'linear-gradient(90deg, var(--breadcrumb-background) 0%, var(--breadcrumb-background) 90%, transparent 100%)',
        } }
      >
        <Chevron/>
        <BreadcrumbButton
          padding="uniform"
          { ...homeButtonProps }
        >
          <PresentationIcon
            IconComponent={ HomeIcon }
            size={ 5 }
          />
        </BreadcrumbButton>
      </div>
    </div>
  );
};

const getItemKey = (item: BreadcrumbItem | null, index: number) => item ? item.link || item.title : String(index);
const getButtonProps = (item: BreadcrumbItem | null) => (
  item === null
    ? {
      component: 'div',
      children: <ProgressCircle size="xs"/>,
    }
    : {
      children: item.title,
      ...(
        item.link
          ? {
            component: Link,
            to: item.link,
          }
          : {}
      ),
    }
);

const homeButtonProps = {
  component: Link,
  to: '/',
};

type BaseComponent = keyof JSX.IntrinsicElements | JSXElementConstructor<never>;

const BreadcrumbButton = <C extends BaseComponent = 'button'>({
  padding = 'standard',
  ...props
}: ButtonBaseProps<C>): JSX.Element => (
  <ButtonBase
    padding={ padding }
    size="sm"
    variant="tertiary-dark"
    colour="grey"
    css={ {
      whiteSpace: 'nowrap',
      fontSize: '14px',
    } }
    { ...props }
  />
);

const Chevron: FC = () => (
  <PresentationIcon
    IconComponent={ ChevronIcon }
    size={ 4 }
    palette={ {
      colour: 'grey',
      intensity: 300,
    } }
  />
);
