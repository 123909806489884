import { FC, useCallback, useState } from 'react';

import { ImagePlaceholder } from './ImagePlaceholder';

type Props = {
  src: string;
  imageDimensions: {
    width?: number;
    height?: number;
  };
};

export const ImageLoader: FC<Props> = ({
  src,
  imageDimensions,
}) => {
  const [ready, setReady] = useState<boolean>(false);
  const [cached, setCached] = useState<boolean>(false);
  const imageContainerRef = useCallback((node: HTMLDivElement) => {
    if (!node) {
      return;
    }

    const aspectRatio = imageDimensions.width && imageDimensions.height
      ? imageDimensions.width / imageDimensions.height
      : undefined;

    node.style.height = aspectRatio
      ? `${ parseInt(window.getComputedStyle(node).width) / aspectRatio }px`
      : 'auto';
  }, [imageDimensions]);

  const imageRef = useCallback((node: HTMLImageElement) => {
    if (!node?.complete) {
      return;
    }

    setCached(true);
  }, []);

  return (
    <div
      ref={ imageContainerRef }
      css={ {
        position: 'relative',
      } }
    >
      <img
        ref={ imageRef }
        src={ src }
        css={ {
          width: '100%',
          height: '100%',
        } }
        onLoad={ () => setReady(true) }
      />
      <ImagePlaceholder
        loading={ true }
        css={ {
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          opacity: 1,
          ...cached
            ? { }
            : { transition: 'opacity 200ms' },
          ...ready
            ? { opacity: 0, pointerEvents: 'none' }
            : {},
        } }
      />
    </div>
  );
};
