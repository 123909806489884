import styled from '@emotion/styled';

export const StyledBadge = styled.span<{ inverted: boolean }>`
  ${ props => props.inverted
    ? `
      background: ${ props.theme.palette.secondary.contrastText };
      color: ${ props.theme.palette.secondary.main };
    `
    : `
      background: ${ props.theme.palette.secondary.main };
      color: ${ props.theme.palette.secondary.contrastText };
    `
  }
  padding: ${ props => `${ props.theme.spacing(0.25) } ${ props.theme.spacing(0.75) }` };
  font-size: ${ props => props.theme.fontSize.xs };
  font-weight: 500;
  border-radius: ${ props => props.theme.borderRadius.max };
  line-height: ${ props => props.theme.spacing(2) };
`;
