import styled from '@emotion/styled';

export const ScrollArea = styled.div<{ center: boolean }>`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  padding: 0.5rem;
  overflow: hidden;
  position: relative;
  grid-area: content;
  overflow-y: auto;

  ${ props => props.center && `
    margin: auto 0;
  ` }
`;

export const Progress = styled.span`
  font-size: 1rem;
  color: ${props => props.theme.palette.grey[400]};
  font-weight: 500;
  text-align: left;
`;

export const Title = styled.div`
  font-size: 1.25rem;
  font-weight: 500;
`;

export const Description = styled.div`
  font-size: 1.15rem;
  word-break: break-word;
`;
