import {FC, useCallback, useEffect, useRef, useState} from 'react';
import { AxiosResponse } from 'axios';
import { useIntl } from 'react-intl';

import { ViewResponse } from '../../../Model';
import { BroadcastContentContext, MediaContent } from '../../../../Broadcasts/Model';
import { useRecallErrorHandler, useRespondButtonProps } from '../../../Hook';
import { RequestState } from '../../../../Models';
import { LiveComponentProps, MediaPresentationComponentProps } from '../../../../Content/Model';
import { useCancellableGet } from '../../../../Hooks';
import { UploadUrls } from '../../../../Common/Hook';
import { useContextOrThrow } from '../../../../Core/Hook';
import { ToastContext } from '../../../../Core/Context';

type Props<C extends MediaContent<string>> = LiveComponentProps<C, ViewResponse, BroadcastContentContext> & {
  PresentationComponent: FC<MediaPresentationComponentProps>;
};

export const LiveMediaContent = <C extends MediaContent<string>>({
  PresentationComponent,
  card: { content },
  response,
  context,
  onResponseChange,
  onReloadRequired,
}: Props<C>): JSX.Element => {
  const intl = useIntl();
  const { addErrorToast } = useContextOrThrow(ToastContext);
  const { onClick: respond, state } = useRespondButtonProps({
      contentId: content.id.toString(),
      deliveryId: context.deliveryId,
      type: 'view',
    },
    onResponseChange,
    useRecallErrorHandler(onReloadRequired),
    response,
  );
  const responding = state === RequestState.FETCHING;
  const [willOpenFile, setWillOpenFile] = useState<boolean>(false);
  const openFileWindow = useRef<Window|null>();

  const whenOpenFileFailed = useCallback(() => {
    addErrorToast(
      intl.formatMessage({
        id: 'inbox.content.media.couldNotOpen',
        description: 'Error message when media card file could not be opened.',
        defaultMessage: 'File could not be opened.',
      })
    );
    openFileWindow.current?.close();
  }, [addErrorToast, intl]);

  const whenFetchUrlsSuccessful = useCallback((response: AxiosResponse<UploadUrls>) => {
    if (!openFileWindow.current) {
      return;
    }
    openFileWindow.current.location = response.data.original;
  }, []);

  const [openFile, openingFile] = useCancellableGet<UploadUrls>(
    `/uploads/${ content.media.id }/urls`,
    whenFetchUrlsSuccessful,
    whenOpenFileFailed,
  );

  const eagerlyOpenFile = () => {
    openFileWindow.current = window.open();
    openFile();
  };

  const whenOpenFileClicked = useCallback(() => {
    if (state === RequestState.COMPLETE) {
      eagerlyOpenFile();
    } else {
      setWillOpenFile(true);
      respond();
    }
  }, [openFile, respond, state]);

  useEffect(() => {
    if (state === RequestState.COMPLETE && willOpenFile) {
      eagerlyOpenFile();
      setWillOpenFile(false);
    } else if (state === RequestState.FAILED) {
      whenOpenFileFailed();
    }
  }, [openFile, state, whenOpenFileFailed, willOpenFile]);

  return (
    <PresentationComponent
      backButton={ context.closeButton }
      media={ content.media }
      text={ content.text }
      navigationProps={ context.navigationProps }
      onOpenFileClicked={ whenOpenFileClicked }
      openFileBusy={ openingFile || responding }
      nudge={ context.nudge }
    />
  );
};
