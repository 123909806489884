export enum ChatsPermission {
  ALL = 'chats',
  TOPICS = 'chats.topics',
  TOPICS_CREATE = 'chats.topics.create',
  TOPICS_UPDATE = 'chats.topics.update',
  TOPICS_DELETE = 'chats.topics.delete',
  TOPICS_READ = 'chats.topics.read',
  ME = 'chats.me',
  METRICS = 'chats.metrics',
}
