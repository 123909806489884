import { MultiContentSubmission, SingleContentCardSubmission, SingleContentSubmission } from '../Model';

export class SubmissionTransformer {
  public static consolidateSubmissionContent = ({ formSubmission, cardSubmissions }: MultiContentSubmission): SingleContentSubmission => ({
    cardSubmissions: cardSubmissions.reduce<SingleContentCardSubmission[]>(
      (partialCardSubmissions, cardSubmission) => (
        cardSubmission.contentSubmissions.length
          ? partialCardSubmissions.concat({
            id: cardSubmission.cardId,
            cardId: cardSubmission.cardId,
            response: cardSubmission.contentSubmissions[0].response,
          })
          : partialCardSubmissions
      ),
      [],
    ),
    formSubmission,
  });
}
