import { ComponentProps, FC, useEffect, useMemo } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $generateHtmlFromNodes } from '@lexical/html';

import { EditorNodeInserter } from '../../../../../../src/react/Common/Utility/EditorNodeInserter';
import { RichTextSanitiser } from '../../../../../../src/react/Common/Utility/RichTextSanitiser';
import { RichTextComparer } from '../../../../Utility/RichTextComparer';
import { Editor } from '../Editor/Editor';

type Props = {
  enabled: boolean;
  value: string;
  emptyValue: string;
  mode?: ComponentProps<typeof Editor>['mode'];
};

export const ValueReconciliationPlugin: FC<Props> = ({
  value,
  emptyValue,
  mode,
  enabled,
}) => {
  const [editor] = useLexicalComposerContext();
  const parser = useMemo(() => new DOMParser(), []);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    editor.getEditorState().read(() => {
      const html = $generateHtmlFromNodes(editor);
      const currentValue = mode === 'richText'
        ? RichTextSanitiser.sanitise(html)
        : RichTextSanitiser.stripTags(html);

      if (
        mode === 'richText'
          ? RichTextComparer.htmlStringsAreEquivalent(currentValue, value, emptyValue)
          : currentValue === value
      ) {
        return;
      }

      editor.update(() => {
        const dom = parser.parseFromString(value, 'text/html');
        EditorNodeInserter.insertNodesFromDocument(editor, dom);
      });
    });
  }, [editor, emptyValue, mode, parser, value, enabled]);

  return null;
};
