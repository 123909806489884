import { FC, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AxiosResponse } from 'axios';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { BreakpointContext } from 'op-storybook/lib/providers/BreakpointProvider/BreakpointProvider';

import StarsIcon from 'op-storybook/lib/assets/icon/figma/stars-02.svg';
import { DeprecatedPresentationIcon } from '../PresentationIcon/DeprecatedPresentationIcon';
import { AiPromptMenu, Prompt } from '../AiPromptMenu/AiPromptMenu';
import { useContextOrThrow } from '../../../../../src/react/Core/Hook/useContextOrThrow';
import { useGoogleAnalytics } from '../../../../../src/react/Core/Hook/useGoogleAnalytics';
import { ApiContext } from '../../../../../src/react/Contexts';
import { TemplatePrompt } from '../AiUserPrompt/AiUserPrompt';

type GenerationResult = {
  choices: { text: string }[];
};

type Props = {
  open: boolean;
  onToggle: (open: boolean) => void;
  beginOnUserPrompt: boolean;
  input: string;
  onChange: (value: string) => void;
  maxOutputLength: number;
  baseAnalyticsEvent: { category: string };
};

export const OurPeopleAiPromptMenu: FC<Props> = ({
  open,
  onToggle,
  beginOnUserPrompt,
  maxOutputLength,
  baseAnalyticsEvent,
  ...props
}) => {
  const api = useContextOrThrow(ApiContext);
  const intl = useIntl();
  const screenWidth = useContextOrThrow(BreakpointContext);
  const mobile = screenWidth.lessThan.sm;
  const { trackEvent } = useGoogleAnalytics();

  const extractGenerationText = useCallback((
    generationResponse: AxiosResponse<GenerationResult>,
  ): string => {
    const choices = generationResponse.data.choices;

    if (!choices.length) {
      throw new Error('No generation results.');
    }

    return choices[0].text;
  }, []);

  const prompts = useMemo<Prompt[]>(() => [
    {
      value: 'REPHRASE',
      label: intl.formatMessage({
        description: 'Label for rephrase OurPeople AI command.',
        defaultMessage: 'Rephrase this',
      }),
      type: 'edit',
      perform: input => {
        trackEvent({
          ...baseAnalyticsEvent,
          action: 'Edit',
          label: 'Instruction: REPHRASE',
        });
        return api.post<GenerationResult>('/ai/text/edit', {
          instruction: 'REPHRASE',
          text: input,
          maximumLength: Math.min(maxOutputLength, AI_OUTPUT_MAX_LENGTH),
        })
          .then(extractGenerationText)
      },
    },
    {
      value: 'FRIENDLY',
      label: intl.formatMessage({
        description: 'Label for friendly tone OurPeople AI command.',
        defaultMessage: 'Friendly',
      }),
      type: 'edit',
      perform: input => {
        trackEvent({
          ...baseAnalyticsEvent,
          action: 'Edit',
          label: 'Instruction: FRIENDLY',
        });
        return api.post<GenerationResult>('/ai/text/edit', {
          instruction: 'FRIENDLY',
          text: input,
          maximumLength: Math.min(maxOutputLength, AI_OUTPUT_MAX_LENGTH),
        })
          .then(extractGenerationText)
      },
    },
    {
      value: 'FORMAL',
      label: intl.formatMessage({
        description: 'Label for formal tone OurPeople AI command.',
        defaultMessage: 'Formal',
      }),
      type: 'edit',
      perform: input => {
        trackEvent({
          ...baseAnalyticsEvent,
          action: 'Edit',
          label: 'Instruction: FORMAL',
        });
        return api.post<GenerationResult>('/ai/text/edit', {
          instruction: 'FORMAL',
          text: input,
          maximumLength: Math.min(maxOutputLength, AI_OUTPUT_MAX_LENGTH),
        })
          .then(extractGenerationText)
      },
    },
    {
      value: 'SHORTEN',
      label: intl.formatMessage({
        description: 'Label for shorten OurPeople AI command.',
        defaultMessage: 'Shorten',
      }),
      type: 'edit',
      perform: input => {
        trackEvent({
          ...baseAnalyticsEvent,
          action: 'Edit',
          label: 'Instruction: SHORTEN',
        });
        return api.post<GenerationResult>('/ai/text/edit', {
          instruction: 'SHORTEN',
          text: input || '',
          maximumLength: Math.min(maxOutputLength, input ? input.length : AI_OUTPUT_MAX_LENGTH),
        })
          .then(extractGenerationText)
      },
    },
    {
      value: 'LENGTHEN',
      label: intl.formatMessage({
        description: 'Label for lengthen OurPeople AI command.',
        defaultMessage: 'Lengthen',
      }),
      type: 'edit',
      perform: input => {
        trackEvent({
          ...baseAnalyticsEvent,
          action: 'Edit',
          label: 'Instruction: LENGTHEN',
        });
        return api.post<GenerationResult>('/ai/text/edit', {
          instruction: 'LENGTHEN',
          text: input,
          ...(input ? { minimumLength: input.length } : {}),
          maximumLength: Math.min(maxOutputLength, AI_OUTPUT_MAX_LENGTH),
        })
          .then(extractGenerationText);
      },
    },
    {
      value: 'TRANSLATE_SPANISH',
      label: intl.formatMessage({
        description: 'Label for translate to Spanish OurPeople AI command.',
        defaultMessage: 'Translate to Spanish',
      }),
      type: 'edit',
      perform: input => {
        trackEvent({
          ...baseAnalyticsEvent,
          action: 'Edit',
          label: 'Instruction: TRANSLATE_SPANISH',
        });
        return api.post<GenerationResult>('/ai/text/edit', {
          instruction: 'TRANSLATE_SPANISH',
          text: input,
          maximumLength: Math.min(maxOutputLength, AI_OUTPUT_MAX_LENGTH),
        })
            .then(extractGenerationText)
      },
    } as Prompt,
  ], [api, baseAnalyticsEvent, extractGenerationText, intl, maxOutputLength, trackEvent]);

  const userPrompt: Prompt = useMemo(() => ({
    value: 'user-prompt',
    label: intl.formatMessage({
      description: 'Title for user prompt sub-menu in OurPeople AI menu',
      defaultMessage: 'Ask OurPeople AI',
    }),
    type: 'generate',
    perform: input => {
      trackEvent({
        ...baseAnalyticsEvent,
        action: 'Generate',
      });
      return api.post<GenerationResult>('/ai/text/generate', {
        instruction: input,
        maximumLength: Math.min(maxOutputLength, AI_OUTPUT_MAX_LENGTH),
      })
        .then(extractGenerationText)
    },
  }), [api, baseAnalyticsEvent, extractGenerationText, intl, maxOutputLength, trackEvent]);

  const templateOptions: TemplatePrompt[] = useMemo(() => [
    {
      key: 'staff-welcome',
      label: intl.formatMessage({
        description: 'Label for new staff welcome AI prompt template.',
        defaultMessage: 'New staff welcome',
      }),
      input: intl.formatMessage({
        description: 'New staff welcome AI prompt template.',
        defaultMessage: 'Write me a message encouraging my team to welcome a new staff-member. Use a placeholder for the staff-member\'s name.',
      }),
    },
    {
      key: 'event-reminder',
      label: intl.formatMessage({
        description: 'Label for event reminder AI prompt template.',
        defaultMessage: 'Event reminder',
      }),
      input: intl.formatMessage({
        description: 'Event reminder AI prompt template.',
        defaultMessage: 'Write me a message reminding my team of an upcoming event. Use placeholders for event details.',
      }),
    },
    {
      key: 'gathering',
      label: intl.formatMessage({
        description: 'Label for organise a gathering AI prompt template.',
        defaultMessage: 'Organise a gathering',
      }),
      input: intl.formatMessage({
        description: 'Organise a gathering AI prompt template.',
        defaultMessage: 'Write me a message I can send to my team to organise a gathering. Use placeholders for gathering details.',
      }),
    },
    {
      key: 'praise-individual',
      label: intl.formatMessage({
        description: 'Label for praise individual AI prompt template.',
        defaultMessage: 'Praise someone',
      }),
      input: intl.formatMessage({
        description: 'Praise individual AI prompt template.',
        defaultMessage: 'Write me a message I can send to my team to praise an individual on my team. Use a placeholder for the individual\'s name.',
      }),
    },
    {
      key: 'birthday-message',
      label: intl.formatMessage({
        description: 'Label for birthday message individual AI prompt template.',
        defaultMessage: 'Send a birthday message',
      }),
      input: intl.formatMessage({
        description: 'Birthday message AI prompt template.',
        defaultMessage: 'Write me a message I can send to my team to celebrate the birthday of a team member. Use a placeholder for the team member\'s name.',
      }),
    },
  ], [intl]);

  return (
    <AiPromptMenu
      open={ open }
      onToggle={ onToggle }
      beginOnUserPrompt={ beginOnUserPrompt }
      headerText={ intl.formatMessage({
        description: 'Title for first sub-menu in OurPeople AI menu',
        defaultMessage: 'OurPeople AI',
      }) }
      templateOptions={ templateOptions }
      userPrompt={ userPrompt }
      prompts={ prompts }
      mobile={ mobile }
      buttonLabel={
        <Stack>
          <span>
            <FormattedMessage
              description="Label for OurPeople AI button"
              defaultMessage="OurPeople AI"
            />
          </span>
          <DeprecatedPresentationIcon IconComponent={ StarsIcon }/>
        </Stack>
      }
      onTemplatePromptSelected={ templatePrompt => trackEvent({
        ...baseAnalyticsEvent,
        action: 'Template selected',
        label: `Template: ${ templatePrompt.label }`,
      }) }
      onUseClicked={ () => trackEvent({
        ...baseAnalyticsEvent,
        action: 'Result applied',
      }) }
      onNavigate={ target => trackEvent({
        ...baseAnalyticsEvent,
        action: 'Menu navigation',
        label: `Target: ${ getReadableNavigationTarget(target) }`,
      }) }
      { ...props }
    />
  );
};

const AI_OUTPUT_MAX_LENGTH = 4000;
const getReadableNavigationTarget = (target: string): string => {
  switch (target) {
    case 'prompts':
      return 'Prompt selection';
    case 'result':
      return 'Result';
    case 'userPrompt':
      return 'Custom prompt';
    case 'templates':
      return 'Templates';
    case 'disclaimer':
      return 'Beta disclaimer';
    default:
      return 'Unknown'
  }
};
