import { useIntl } from 'react-intl';
import { FC } from 'react';
import { LoadingState } from 'op-storybook/stories/components/LoadingState/LoadingState';

import { EmptyCard } from '../../../Content/Component';

export const InboxLoadingCard: FC = () => {
  const intl = useIntl();

  return (
    <EmptyCard>
      <LoadingState
        text={ intl.formatMessage({
          description: 'Message heading when delivery or content are loading.',
          defaultMessage: 'It\'s a big one!',
        }) }
        css={ { margin: 'auto' } }
        pad
      />
    </EmptyCard>
  );
}
