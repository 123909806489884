import { IntlShape } from 'react-intl';

import {
  AudienceConditionDefinition,
  ConditionContentsSummary,
  PersonCondition,
  PersonConditionContents
} from '../../Model';
import { PersonParser } from '../../../Utility';

/** @deprecated Used in old audience builder */
export class PersonConditionDefinition implements AudienceConditionDefinition<
  PersonCondition,
  PersonConditionContents
> {
  public readonly TYPE = 'person';

  public definesConditionType(conditionType: string): boolean {
    return conditionType === this.TYPE;
  }

  public contentsSummary(
    intl: IntlShape,
    condition: PersonCondition,
    conditionContents: PersonConditionContents,
  ): ConditionContentsSummary {
    const nameSummary = intl.formatMessage(
      {
        id: 'audience.conditionDefinition.person.contentsSummary.name',
        defaultMessage: '{ not, select, true {not } other {} }{name}',
      },
      {
        not: condition.not,
        name: conditionContents.people.length > 0
          ? PersonParser.fullName(conditionContents.people[0])
          : '',
      }
    );

    const countSummary = intl.formatMessage(
      {
        id: 'audience.conditionDefinition.person.contentsSummary.count',
        defaultMessage: '{ not, select, true {not } other {} }{ count, plural, one {# person} other {# people} }',
      },
      {
        not: condition.not,
        count: conditionContents.people.length,
      }
    );

    const preferredSummary = conditionContents.people.length > 0
      ? (
        conditionContents.people.length === 1
          ? nameSummary
          : countSummary
      )
      : null;

    return {
      exclusive: false,
      soloSummary: preferredSummary,
      andSummary: preferredSummary,
    };
  }
}
