import { FunctionComponent, PropsWithChildren, useContext } from 'react';

import { AuthContext } from '../../Contexts';

export const LoggedInAuthProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const authContext = useContext(AuthContext);

  return (
    !!authContext.authDescription
      ? (
        <AuthContext.Provider value={authContext}>
          {children}
        </AuthContext.Provider>
      )
      : <></>
  );
};
