import { $getRoot, $insertNodes, LexicalEditor, ParagraphNode } from 'lexical';
import { $generateNodesFromDOM } from '@lexical/html';

export class EditorNodeInserter {
  public static insertNodesFromDocument = (editor: LexicalEditor, document: Document): void => {
    const nodes = $generateNodesFromDOM(editor, document);

    const wrappedNodes = nodes.map(node => {
      if (node.getType() !== 'text') {
        return node;
      }

      const paragraphNode = new ParagraphNode();
      paragraphNode.append(node);
      return paragraphNode;
    });

    $getRoot().clear();
    $getRoot().select();
    $insertNodes(wrappedNodes);
  }
}
