import { FC, useEffect, useRef, useState } from 'react';
import { InterpolationPrimitive } from '@emotion/serialize';

import opIcon from '../../assets/op-icon.svg?url';

export const AuthImagePane: FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [offset, setOffset] = useState<{ x: number, y: number }>({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    const onMouseMove = (event: MouseEvent) => {
      setOffset({
        x: (((Math.cos(Math.PI * event.clientX / window.innerWidth) + 1) / 2) - 0.5) * ((containerRef.current?.clientWidth || 0) / 100),
        y: (((Math.cos(Math.PI * event.clientY / window.innerHeight) + 1) / 2) - 0.5) * ((containerRef.current?.clientHeight || 0) / 100),
      });
    };

    document.addEventListener('mousemove', onMouseMove);

    return () => document.removeEventListener('mousemove', onMouseMove);
  }, []);

  return (
    <div
      css={ authImagePaneCss }
      ref={ containerRef }
    >
      <div css={ gradientCss }/>
      <img
        alt=""
        style={ {
          '--offsetX': `${ offset.x }px`,
          '--offsetY': `${ offset.y }px`,
        } }
        css={ imageCss }
        src={ opIcon }
      />
    </div>
  );
};

const authImagePaneCss: InterpolationPrimitive = {
  position: 'relative',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
};

const gradientCss: InterpolationPrimitive = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundImage: 'linear-gradient(155deg, rgba(255,211,160,1) 0%, rgba(253,101,133,1) 100%)',
};

const imageCss: InterpolationPrimitive = {
  mixBlendMode: 'color-burn',
  width: '100%',
  height: '100%',
  transform: 'rotate(-15deg) translate(var(--offsetX), var(--offsetY)) scale(150%)',
  position: 'absolute',
  opacity: 0.3,
  top: 0,
  left: 0,
};
