import { FetchResponse, useGet, useTransformedFetchResponse } from '../../Hooks';
import { MultiContentSubmission, SingleContentSubmission } from '../Model';
import { SubmissionTransformer } from '../Utility';

export const useFetchSubmission = (
  submissionId: string,
): FetchResponse<{ submission: SingleContentSubmission }> => (
  useTransformedFetchResponse(
    useGet<{ submission: MultiContentSubmission }>(`/forms/submissions/${ submissionId }`),
    ({ submission }) => ({
      submission: SubmissionTransformer.consolidateSubmissionContent(submission)
    })
  )
);
