import { ChangeEventHandler, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { Heading } from '@ourpeople/shared/Core/Component/Content';

import { FieldValidationErrors, ImageFitButtons } from '../../../../Common/Component';
import { Upload } from '../../../../Types';
import { LoadingSpinner } from '../../../../Components';
import { ContentEditorProps, EditorContent } from '../../../../Content/Model';
import { DraftFormContent } from '../../../Model';
import { ContentValidator } from '../../../Utility/Validation';
import { useApi } from '../../../../Core/Hook';
import { useFileUploader, useMounted } from '../../../../Common/Hook';
import { ImageUploadField } from '../../../../Common/Component/ImageUploadField/ImageUploadField';

export const ContentFields = <T extends EditorContent<DraftFormContent>>({
  editorContent,
  onEditorContentChanged,
  validation,
  onValidationChanged,
}: PropsWithChildren<ContentEditorProps<T>>): JSX.Element => {
  const intl = useIntl();
  const api = useApi();
  const titleErrors = validation?.children.content?.children.title?.errors || [];
  const [sourceUpload, setSourceUpload] = useState<Upload>();
  const mounted = useMounted();
  const { addCompletedUpload } = useFileUploader();

  useEffect(() => {
    if (!editorContent.card.image?.uploadId) {
      return;
    }

    void api.get<Upload>(`uploads/${ editorContent.card.image.uploadId }`)
      .then(response => {
        if (!mounted) {
          return;
        }

        addCompletedUpload(editorContent.id, response.data);
        setSourceUpload(response.data);
      });
  }, [addCompletedUpload, api, editorContent.card.image?.uploadId, editorContent.id, mounted]);

  const whenUploadChanged = useCallback((upload?: Upload): void => {
    setSourceUpload(upload);
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        image: upload
          ? {
            uploadId: upload ? upload.id.toString() : null,
            fit: 'cover',
          }
          : null,
      },
    });
  }, [editorContent, onEditorContentChanged]);

  const whenTitleChanged: ChangeEventHandler<HTMLInputElement> = (event) => {
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          title: event.target.value,
        },
      },
    });
  };

  const whenTransformed = useCallback((upload: Upload) => {
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        image: {
          uploadId: upload ? upload.id.toString() : null,
          fit: editorContent.card.image?.fit || 'cover',
        },
      },
    });
  }, [editorContent, onEditorContentChanged]);

  const whenImageFitChanged = useCallback((imageFit: 'cover' | 'contain') => {
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        image: {
          uploadId: editorContent.card.image?.uploadId || null,
          fit: imageFit,
        },
      },
    });
  }, [editorContent, onEditorContentChanged]);

  const whenTitleBlurred = useCallback((): void => {
    onValidationChanged({
      errors: validation?.errors || [],
      children: {
        content: {
          errors: [
            ...ContentValidator.validate(editorContent.card.content).errors,
          ],
          children: {
            ...validation?.children.content?.children,
            ...ContentValidator.validate(editorContent.card.content).children,
          }
        },
      },
    });
  }, [onValidationChanged, validation, editorContent.card.content]);

  return (
    <>
      <FieldValidationErrors
        fieldName={ intl.formatMessage({
          id: 'form.fields.shared.contentType.name',
          description: 'Name used in general content errors.',
          defaultMessage: 'Content type',
        }) }
        validationErrors={ validation?.children.content?.children.type?.errors || [] }
      />
      <TextField
        id="title"
        label={ intl.formatMessage({
          id: 'form.fields.shared.title.label',
          defaultMessage: 'Title *',
        }) }
        value={ editorContent.card.content.title }
        onChange={ whenTitleChanged }
        onBlur={ whenTitleBlurred }
        error={ !!titleErrors.length }
        fullWidth
      />
      <FieldValidationErrors
        fieldName={ intl.formatMessage({
          id: 'form.fields.shared.title.name',
          defaultMessage: 'title',
        }) }
        validationErrors={ titleErrors }
      />
      {
        sourceUpload || !editorContent.card.image?.uploadId
          ? (
            <ImageUploadField
              imageKey={ editorContent.id }
              upload={ editorContent.card.image?.uploadId ? { uuid: editorContent.card.image.uploadId } : null }
              mode="default"
              outputDimensions={ undefined }
              onComplete={ whenUploadChanged }
              onRemoveClicked={ () => whenUploadChanged(undefined) }
              authoriseEndpoint="forms/authorise-upload"
              imageLibraryEnabled
              previewSize="sm"
            />
          )
          : <LoadingSpinner/>
      }
      { editorContent.card.image && (
        <>
          <Heading type="h4">
            <FormattedMessage
              id="form.fields.shared.imageFit.name"
              description="Label for image fit field in form editor."
              defaultMessage="Image fit"
            />
          </Heading>
          <ImageFitButtons imageFit={ editorContent.card.image.fit } onChange={ whenImageFitChanged }/>
        </>
      ) }
    </>
  )
};
