import { FC } from 'react';

import CloseIcon from '../../assets/icon/figma/x-close.svg?react';
import { PresentationIcon } from '../PresentationIcon/PresentationIcon';
import { ButtonBase } from '../../../stories/components/ButtonBase/ButtonBase';

type Props = {
  onClick?: () => void;
};

export const BadgeCloseX: FC<Props> = ({
  onClick
}) => (
  <ButtonBase
    variant="tertiary-dark"
    colour="grey"
    padding="uniform"
    size="sm"
    onClick={ onClick }
    type="button"
  >
    <PresentationIcon
      IconComponent={ CloseIcon }
      size={ 4 }
    />
  </ButtonBase>
);
