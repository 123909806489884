import { PreviewComponent } from '../../../Model/BroadcastBlockDefinition';
import { ImageBlock } from '../../../Service/Blocks/ImageBlockDefinition';
import { ImageBlockImage } from './ImageBlockImage';

export const ImageBlockExpandedPreview: PreviewComponent<ImageBlock> = ({
  block,
  attachments,
}) => (
  <ImageBlockImage
    block={ block }
    attachments={ attachments }
    roundCorners={ true }
  />
);
