import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { Dispatch, FunctionComponent, SetStateAction, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  FailureToast,
  LoadingSpinner,
  PaginatedTable,
  SortableHeaderCell,
  SuccessToast,
  TableCell,
  TableRow,
  ToastMessage
} from '../../../Components';
import { ContentIcon } from '../../../Sections/Broadcasts/Common/ContentIcon';
import { ContentReader } from '../../../Readers';
import { TextFormatter } from '../../../Utility';
import { DateTime, TimeSpan } from '../../../Common/Component';
import { EventStatus } from '../';
import { TableMenu } from '../../../Components/Content/TableMenu';
import StopIcon from '../../../Assets/img/icons/monochrome/stop.svg';
import { ContentWithEvent, Pagination, RequestState } from '../../../Models';
import { FetchEventsSortString, sortStringIsValid } from '../../Hook';
import { Event } from '../../Model';

interface Props {
  events: Event[];
  pagination?: Pagination;
  requestState: RequestState;
  query: Record<string, string>;
  setQuery: Dispatch<SetStateAction<Record<string, string>>>;
  addToastMessage: (message: ToastMessage) => void;
  reload: () => void;
  cancel: (id: string, type: string) => Promise<unknown>;
}

export const EventsTable: FunctionComponent<Props> = ({
  events,
  pagination,
  requestState,
  query,
  setQuery,
  addToastMessage,
  reload,
  cancel,
}) => {
  const intl = useIntl();
  const pageNum = +query.pageNum || 1;
  const [cancellingEvent, setCancellingEvent] = useState<ContentWithEvent>();
  const sort: FetchEventsSortString = sortStringIsValid(query.sort) ? query.sort : 'start_at_asc';

  const whenSortChanged = (sort: FetchEventsSortString) => {
    setQuery(query => ({
      ...query,
      sort,
    }));
  };

  const whenPageChanged = (pageNum: number) => {
    setQuery(query => ({
      ...query,
      pageNum: pageNum.toString(),
    }));
  };

  useEffect(() => {
    if (cancellingEvent && cancellingEvent.id) {
      let cancelled = false;
      cancel(cancellingEvent.id, cancellingEvent.type)
        .then(() => {
          setTimeout(() => {
            if (!cancelled) {
              const successString = intl.formatMessage({
                id: 'events.table.cancelSucceeded',
                description: 'Toast message when cancelling event succeeds.',
                defaultMessage: '{eventTitle} cancelled successfully.',
              }, {
                eventTitle: cancellingEvent.text,
              });
              setCancellingEvent(undefined);
              addToastMessage(new SuccessToast(successString));
              reload();
            }
          }, 50);
        })
        .catch(() => {
          if (!cancelled) {
            const failString = intl.formatMessage({
              id: 'events.table.cancelFailed',
              description: 'Toast message when cancelling event fails.',
              defaultMessage: 'Could not cancel {eventTitle}.',
            }, {
              eventTitle: cancellingEvent.text,
            });
            setCancellingEvent(undefined);
            addToastMessage(new FailureToast(failString));
          }
        });
      return () => {
        cancelled = true;
      }
    }
  }, [addToastMessage, cancel, cancellingEvent, intl, reload]);

  return (
    <PaginatedTable
      headerRow={
        <TableRow>
          <TableCell>
            <FormattedMessage
              id="events.table.typeHeading"
              description="Events table type column heading."
              defaultMessage="Type"
            />
          </TableCell>
          <TableCell>
            <FormattedMessage
              id="events.table.nameHeading"
              description="Events table name column heading."
              defaultMessage="Name"
            />
          </TableCell>
          <TableCell>
            <FormattedMessage
              id="events.table.locationHeading"
              description="Events table location column heading."
              defaultMessage="Location"
            />
          </TableCell>
          <TableCell>
            <FormattedMessage
              id="events.table.createdByHeading"
              description="Events table created by column heading."
              defaultMessage="Created by"
            />
          </TableCell>
          <SortableHeaderCell
            sort={ sort }
            ascValue="start_at_asc"
            descValue="start_at_desc"
            onSort={ whenSortChanged }
          >
            <FormattedMessage
              id="events.table.dateHeading"
              description="Events table date column heading."
              defaultMessage="Date"
            />
          </SortableHeaderCell>
          <TableCell>
            <FormattedMessage
              id="events.table.timeHeading"
              description="Events table time column heading."
              defaultMessage="Time"
            />
          </TableCell>
          <TableCell>
            <FormattedMessage
              id="events.table.statusHeading"
              description="Events table status column heading."
              defaultMessage="Status"
            />
          </TableCell>
          <TableCell/>
        </TableRow>
      }
      rows={ events }
      rowRender={ event => (
        <TableRow key={ event.event.id }>
          <TableCell>
            <ContentIcon
              contentType={ ContentReader.contentTypeFromString(event.event.type) }
            />
          </TableCell>
          <TableCell>
            <Link to={`/broadcasts/${event.lastDelivery.broadcastId}/report?delivery=${event.lastDelivery.id}#content${event.event.id || ''}`}>
              { event.event.text }
            </Link>
          </TableCell>
          <TableCell>
            { event.event.location }
          </TableCell>
          <TableCell>
            { TextFormatter.spaceSeparated(event.event.created_by.forename, event.event.created_by.lastname || '') }
          </TableCell>
          <TableCell>
            <DateTime
              dateTime={ event.event.start_at.dt }
              dateOnly
              timezone={
                event.event.start_at.tz !== Intl.DateTimeFormat().resolvedOptions().timeZone
                  ? event.event.start_at.tz
                  : undefined
              }
            />
          </TableCell>
          <TableCell>
            <TimeSpan
              start={ event.event.start_at.dt }
              end={ event.event.end_at.dt }
            />
          </TableCell>
          <TableCell>
            <EventStatus
              contentType={ ContentReader.contentTypeFromString(event.event.type) }
              status={ event }
              withName
            />
          </TableCell>
          <TableCell>
            {
              cancellingEvent?.id === event.event.id
                ? (
                  <LoadingSpinner />
                )
                : (
                  <TableMenu
                    rowKey={ event.event.id || '' }
                  >
                    <MenuItem
                      disabled={ event.event.is_cancelled || !!cancellingEvent }
                      onClick={ () => setCancellingEvent(event.event) }
                    >
                      <ListItemIcon><StopIcon/></ListItemIcon>
                      <ListItemText primary={
                        intl.formatMessage({
                          id: 'events.table.cancel',
                          defaultMessage: 'Cancel',
                        })
                      }
                      />
                    </MenuItem>
                  </TableMenu>
                )
            }
          </TableCell>
        </TableRow>
      ) }
      pagination={ pagination }
      pageNum={ pageNum }
      onPageChanged={ whenPageChanged }
      requestState={ requestState }
      onRetryClicked={ reload }
    />
  )
}
