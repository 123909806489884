import { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { ContentPreviewProps } from '../../../../Content/Model';
import { MediaContentPreview } from '..';
import AudioIcon from '../../../../Assets/img/icons/monochrome/content/audio.svg';
import { AudioEditorContent } from '../../../Service';

export const AudioContentPreview: FC<ContentPreviewProps<AudioEditorContent>> = ({
  editorContent,
  onReady,
}) => {
  const intl = useIntl();

  useEffect(() => {
    onReady();
  }, [onReady]);

  return (
    <MediaContentPreview
      editorContent={ editorContent }
      IconComponent={ AudioIcon }
      localisedContentType={ intl.formatMessage({
        id: 'broadcasts.content.preview.audio.type',
        description: 'Label for audio content type used in preview.',
        defaultMessage: 'Audio',
      }) }
      localisedButtonLabel={ intl.formatMessage({
        id: 'broadcasts.content.preview.audio.openLabel',
        description: 'Label for open file button used in audio preview.',
        defaultMessage: 'Play audio',
      }) }
    />
  );
};
