import styled from '@emotion/styled';

export const StyledBarChartMetric = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  row-gap: 0.5rem;
  height: 100%;
  width: 100%;
  overflow: hidden;

  >div:last-child {
    height: 100%;
    overflow: hidden;
  }

  canvas {
    width: 100% !important;
  }
`;
