import styled from '@emotion/styled';
import { lighten } from 'polished';

export const StyledMemberList = styled.ul`
  display: flex;
  margin: 0;
  list-style: none;
  padding: 0 22px;
  justify-content: space-between;
  user-select: none;
  cursor: default;
`;

export const StyledTooltipMemberList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const StyledMember = styled.li`
  overflow: visible;
  position: relative;
  height: ${ props => props.theme.spacing(5) };

  > div {
    transition: background-color 0.3s;
    background-color: ${ props => props.theme.palette.secondary.main };
    border: 2px solid white;
    border-radius: 999rem;
    width: ${ props => props.theme.spacing(5) };
    min-width: ${ props => props.theme.spacing(5) };
    height: ${ props => props.theme.spacing(5) };
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 0.9rem;
    position: absolute;

    &:hover {
      background-color: ${ props => lighten(0.03, props.theme.palette.secondary.main) };
    }
  }
`;
