import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { IntlShape } from 'react-intl/src/types';

import { MappedSettingTypes } from '../../Types';
import { ColorPicker } from '../../../../../Components';
import { ValidationErrorMessage } from '../../../../../Common/Component';

type Props = {
  value: MappedSettingTypes['colour']['value'],
  config: MappedSettingTypes['colour']['config'],
  onValueChanged: (value: MappedSettingTypes['colour']['value']) => void,
  focusId?: string,
};

export const ColourSettingInput = ({
  value,
  config,
  onValueChanged,
  focusId,
}: Props): JSX.Element => {
  const intl = useIntl();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const error = validate(value, config, intl);
    setError(error);
  }, [value, config, intl]);

  const whenValueChanged = (value: string): void => {
    onValueChanged(value);
  }

  return (
    <div>
      <ColorPicker
        value={ value || '' }
        onChange={whenValueChanged}
        focusId={focusId}
      />
      {
        error
        && (
          <ValidationErrorMessage>{error}</ValidationErrorMessage>
        )
      }
    </div>
  );
};

const validate = (
  value: MappedSettingTypes['colour']['value'],
  config: MappedSettingTypes['colour']['config'],
  intl: IntlShape,
): string | null => {
  if (value === null && config.nullable) {
    return null;
  }

  if (value === null) {
    return intl.formatMessage({
      id: 'settings.colourSetting.nullError',
      defaultMessage: 'Missing value',
    });
  }

  if (!(/^#([0-9A-F]{3}){1,2}$/i.test(value))) {
    return intl.formatMessage(
      {
        id: 'settings.colourSetting.invalidFormat',
        defaultMessage: 'Must be a valid hex colour',
      },
    );
  }

  return null;
}
