import { MappedSettingTypes } from '../../Types';

type Props = {
  value: MappedSettingTypes['timezone']['value'],
};

export const TimezoneSettingValue = ({
  value,
}: Props): JSX.Element => {
  return (
    <p>{value}</p>
  );
};
