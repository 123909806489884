import { FunctionComponent, ReactNode, useMemo, useState } from 'react';
import { Fade } from '@mui/material';
import { Button, ButtonProps } from '@ourpeople/shared/Core/Component/Input/Button/Button';

import AlertIcon from '../../../Assets/img/icons/monochrome/alert.svg';
import InfoIcon from '../../../Assets/img/icons/streamline/information-circle.svg';
import SuccessIcon from '../../../Assets/img/icons/monochrome/success.svg';
import { SeverityIconContainer, StyledMessage, StyledNotice, StyledDismissButtonContainer } from './style';
import { CloseButton, Flex } from '..';

export type Feedback = {
  message: ReactNode;
  severity: FeedbackSeverity;
}

export type FeedbackSeverity = 'error' | 'warning' | 'info' | 'success' | 'none';

export type NoticeProps = {
  feedback: Feedback;
  dismissing?: boolean;
  onDismiss?: () => void;
  variant?: 'contained' | 'outlined';
  icon?: ReactNode;
  buttons?: {
    id: string;
    props: ButtonProps;
    label: string;
  }[];
  align?: 'flex-start' | 'center';
};

export const Notice: FunctionComponent<NoticeProps> = ({
  feedback,
  onDismiss,
  variant = 'contained',
  icon,
  buttons = [],
  align = 'center',
}) => {
  const [dismissing, setDismissing] = useState<boolean>(false);

  const severityIcon = useMemo(() => {
    switch (feedback.severity) {
      case 'success':
        return <SuccessIcon/>;
      case 'info':
        return <InfoIcon/>
      default:
        return <AlertIcon/>;
    }
  }, [feedback.severity]);

  return (
    <Fade
      appear={ false }
      in={ !dismissing }
      onExited={ onDismiss }
    >
      <StyledNotice
        severity={ feedback.severity }
        variant={ variant }
        role="status"
        align={ align }
      >
        <SeverityIconContainer>{ icon || severityIcon }</SeverityIconContainer>
        <StyledMessage>
          { feedback.message }
        </StyledMessage>
        <Flex gap={ 1 }>
          { buttons.map(button => (
            <Button
              key={ button.id }
              { ...button.props }
            >
              { button.label }
            </Button>
          )) }
          { onDismiss && (
            <StyledDismissButtonContainer>
              <CloseButton
                onClick={ () => setDismissing(true) }
                color={ `${ feedback.severity }.contrastText` }
              />
            </StyledDismissButtonContainer>
          ) }
        </Flex>
      </StyledNotice>
    </Fade>
  );
}
