import styled from '@emotion/styled';

export const StyledFullscreenError = styled.div`
  text-align: center;
  font-size: 1.2rem;

  p {
    margin-bottom: 1.5rem;
  }
`;
