import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { parseISO } from 'date-fns/parseISO';
import { isValid } from 'date-fns/isValid';
import { isSameDay } from 'date-fns/isSameDay';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { PresentationIcon } from 'op-storybook/lib/components/PresentationIcon/PresentationIcon';
import CalendarIcon from 'op-storybook/lib/assets/icon/figma/calendar.svg';
import LocationIcon from 'op-storybook/lib/assets/icon/figma/marker-pin-01.svg';

import { PreviewComponent } from '../../../Model/BroadcastBlockDefinition';
import { EventBlock } from '../../../Service/Blocks/EventBlockDefinition';
import { DateTimeFormatter } from '../../../../../Utility';
import { Paragraph } from '../../../../../Components';
import { AddToCalendarButton } from '../../../../Common/Component/Content/AddToCalendarButton';

export const EventBlockExpandedPreview: PreviewComponent<EventBlock> = ({
  block
}) => {
  const intl = useIntl();
  const startDate = useMemo<Date | undefined>(
    () => block.attributes.date.start ? parseISO(block.attributes.date.start) : undefined,
    [block.attributes.date.start],
  );
  const endDate = useMemo<Date | undefined>(
    () => block.attributes.date.end ? parseISO(block.attributes.date.end) : undefined,
    [block.attributes.date.end],
  );
  const formattedDate = useMemo(
    () => {
      let formattedDate = '';
      if (startDate && isValid(startDate)) {
        formattedDate = DateTimeFormatter.internationalised(startDate, intl.locale);
        if (endDate && isValid(endDate)) {
          if (isSameDay(startDate, endDate)) {
            formattedDate += ` - ${ DateTimeFormatter.timeInput(endDate) }`;
          } else {
            formattedDate += ` - ${ DateTimeFormatter.internationalised(endDate, intl.locale) }`;
          }
        }
      }
      return formattedDate;
    },
    [startDate, endDate, intl]
  );

  return (
    <Stack
      direction="column"
      css={ { width: '100%' } }
    >
      {
        block.attributes.name
          ? <h4 css={ { margin: 0 } }>{ block.attributes.name }</h4>
          : (
            <div
              css={ theme => ({
                margin: `${ theme.new.spacing[1] } 0`,
                flexGrow: 1,
                background: theme.new.palette.grey[200].main,
                borderRadius: theme.new.borderRadius.small,
                height: theme.new.spacing[4],
              }) }
            />
          )
      }
      { block.attributes.description && (
        <Paragraph css={ { margin: 0 } }>{ block.attributes.description }</Paragraph>
      ) }
      <Stack>
        <PresentationIcon IconComponent={ CalendarIcon }/>
        { formattedDate || (
          <div
            css={ theme => ({
              flexGrow: 1,
              background: theme.new.palette.grey[200].main,
              borderRadius: theme.new.borderRadius.small,
              height: theme.new.spacing[2],
            }) }
          />
        ) }
      </Stack>
      { block.attributes.location && (
        <Stack>
          <PresentationIcon IconComponent={ LocationIcon }/>
          { block.attributes.location }
        </Stack>
      ) }
      { startDate && endDate && (
        <AddToCalendarButton
          title={ block.attributes.name }
          description={ block.attributes.description }
          location={ block.attributes.location }
          startDate={ startDate }
          endDate={ endDate }
        />
      ) }
    </Stack>
  )
};
