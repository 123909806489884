import { AxiosError } from 'axios';
import { useCallback, useMemo, useState } from 'react';

import { ContentResponse, DraftResponse } from '../Model';
import { useCancellablePost } from '../../Hooks';
import { RequestState } from '../../Models';
import { ResponseTransformer } from '../Utility/ResponseTransformer';

export const useSubmitResponse = <R extends ContentResponse>(
  contentId: string,
  onSuccess: (response: R) => void,
  onError: (error: AxiosError) => void,
  transformResponse?: (response: R) => DraftResponse<R['type']>,
): [(response: R) => void, RequestState] => {
  const [state, setState] = useState<RequestState>(RequestState.PENDING);
  const [pendingResponse, setPendingResponse] = useState<R | null>(null);

  const whenResponseAccepted = useCallback(() => {
    if (!pendingResponse) {
      return;
    }

    setState(RequestState.COMPLETE);
    onSuccess(pendingResponse);
    setPendingResponse(null);
  }, [onSuccess, pendingResponse]);

  const whenResponseRejected = useCallback((error: AxiosError) => {
    setState(RequestState.FAILED);
    setPendingResponse(null);
    onError(error);
  }, [onError]);

  const [submitResponse] = useCancellablePost(
    `/team/broadcasts/contents/${ contentId }/respond`,
    whenResponseAccepted,
    whenResponseRejected,
  );

  const whenResponseSubmitted = useCallback((response: R) => {
    setState(RequestState.FETCHING);
    setPendingResponse(response);
    submitResponse(transformResponse ? transformResponse(response) : ResponseTransformer.transformForSubmission(response));
  }, [submitResponse, transformResponse]);

  return useMemo(() => [whenResponseSubmitted, state], [state, whenResponseSubmitted]);
};
