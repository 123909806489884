import styled from '@emotion/styled';

export const StyledProfileForm = styled.div<{ modified: boolean; error: boolean }>`
  border: 1px solid ${ props => props.theme.palette.grey[200] };
  padding: ${ props => props.theme.spacing(2) };
  border-radius: ${ props => props.theme.borderRadius.xs };
  ${ props => props.error
    ? `
      border-color: ${ props.theme.palette.error.main };
    `
    : props.modified && `
      border-color: ${ props.theme.palette.secondary.main };
    `
  }
`;

export const StyledHeaderRow = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: flex-start;
  gap: ${ props => props.theme.spacing(2) };

  > *:last-child {
    margin-top: ${ props => props.theme.spacing(3) };
  }
`;
