import styled from '@emotion/styled';
import { PaletteColor } from '@mui/material/styles';
import { Theme } from '@emotion/react';

import { FeedbackSeverity } from './Notice';

type StyledNoticeProps = {
  severity: FeedbackSeverity;
  variant: 'contained' | 'outlined';
  align: 'flex-start' | 'center';
}

export const SeverityIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.75rem;
  width: 1.75rem;

  svg:not(.MuiCircularProgress-svg) {
    width: 100%;
    height: 100%;
  }
`;

export const StyledMessage = styled.div`
  p {
    margin: 0;
  }
`;

const getNoticeColours = (paletteColour: PaletteColor) => `
  background-color: ${ paletteColour.main };
  color: ${ paletteColour.contrastText };
`;

const getSeverityPaletteColour = (theme: Theme, severity: FeedbackSeverity): PaletteColor => {
  switch (severity) {
    case 'error':
      return theme.palette.error;
    case 'warning':
      return theme.palette.warning;
    case 'success':
      return theme.palette.success;
    default:
      return theme.palette.secondary;
  }
};

export const StyledNotice = styled.div<StyledNoticeProps>`
  ${ props => props.variant === 'contained' && getNoticeColours(getSeverityPaletteColour(props.theme, props.severity)) }

  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: ${ props => props.theme.spacing(2) };
  align-items: ${ props => props.align };
  border-radius: ${ props => props.theme.borderRadius.sm };
  padding: ${ props => props.theme.spacing(2) };

  ${ props => props.variant === 'outlined' && `
    border: 1px solid ${ props.theme.palette.grey[300] };
    background-color: ${ props.theme.palette.grey[100] };

    > div:first-of-type {
      color: ${ getSeverityPaletteColour(props.theme, props.severity).main };
    }
  ` }

  div:nth-of-type(2) {
    ${ props => props.variant === 'contained'
      ? `
        a {
          color: inherit;
          text-decoration: underline;
        }
      `
      : `
        a {
          color: ${ props.theme.palette.secondary.main };
        }

        strong {
          color: ${ props.theme.palette.primary.main };
        }
      `
    }
  }
`;

export const StyledDismissButtonContainer = styled.div`
  margin: ${ props => props.theme.spacing(-1) };
`;
