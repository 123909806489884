import { ComponentProps, FC, forwardRef } from 'react';

import { StyleBuilder } from '../../model/StyleBuilder/StyleBuilder';

export const TextFieldLeadingButton: FC<ComponentProps<'button'>> = forwardRef(({
  children,
  ...props
}, ref) => {
  const styles = buildStyles();

  return (
    <button
      css={ styles.button }
      type="button"
      ref={ ref }
      { ...props }
    >
      { children }
    </button>
  );
});

const buildStyles: StyleBuilder = () => ({
  button: theme => ({
    borderStyle: 'none',
    marginInlineStart: `-${ theme.new.spacing[3] }`,
    background: 'transparent',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: `${ theme.new.spacing[2] } ${ theme.new.spacing[3] }`,
    color: theme.new.palette.grey[700].main,
    borderBottomLeftRadius: theme.new.borderRadius.standard,
    borderTopLeftRadius: theme.new.borderRadius.standard,

    '&:hover': {
      backgroundColor: theme.new.palette.grey[50].main,
      color: theme.new.palette.grey[800].main,
    },

    '&:focus': {
      backgroundColor: theme.new.palette.grey[50].main,
    },

    '&:focusVisible': {
      outline: 'none',
    },
  }),
});
