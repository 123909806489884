import styled from '@emotion/styled';

export const StyledMenuDrawer = styled.div`
  width: 80%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background-color: ${ props => props.theme.palette.background.paper };
  box-shadow: ${ props => props.theme.shadows[1] };
`;

export const StyledOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,0.2);
`;
