import { useEffect } from 'react';

export const useScrollLock = (shouldLock: boolean) => {
  useEffect(() => {
    let lockedElement: HTMLElement;

    const unlock = () => {
      if (!lockedElement) {
        return;
      }

      lockedElement.style.overflow = '';
    };

    const lock = () => {
      const scrollingElement = document.scrollingElement as HTMLElement | null;

      if (!scrollingElement) {
        return;
      }

      scrollingElement.style.overflow = 'hidden';
      lockedElement = scrollingElement;
      return;
    }

    shouldLock
      ? lock()
      : unlock()

    return unlock;
  }, [shouldLock]);
};
