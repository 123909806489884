import { ImportStatus } from '../Model';

export class ImportDetailParser {
  public static determineMaxStepIndex = (status: ImportStatus): number => {
    switch (status) {
      case 'pending':
      case 'invalid_mapping':
        return 1;
      case 'invalid_records':
        return 2;
      case 'valid':
        return 3;
      default:
        return 4;
    }
  };
}
