import { FC } from 'react';
import { useIntl } from 'react-intl';
import { format, toZonedTime } from 'date-fns-tz';

import { VerticallySpaced } from '../../../../../Common/Component';
import { SvgComponent } from '../../../../../Common/Model';
import { StyledEventInformationLabel, StyledEventInformationRow, StyledEventInformationValue } from './style';
import CalendarIcon from '../../../../../Assets/img/icons/monochrome/calendar.svg';
import ClockIcon from '../../../../../Assets/img/icons/monochrome/clock.svg';
import PinIcon from '../../../../../Assets/img/icons/monochrome/pin.svg';
import { ScheduledDate } from '../../../../../Models/ScheduledDate';

type Props = {
  startAt: ScheduledDate;
  endAt: ScheduledDate;
  location: string;
  showTimezone?: boolean;
};

export const EventInformation: FC<Props> = ({
  startAt,
  endAt,
  location,
  showTimezone = false,
}) => {
  const intl = useIntl();
  const startDate = new Date(startAt.dt).toLocaleDateString(navigator.language, {
    weekday: 'short',
    day: 'numeric',
    month: 'short'
  });
  const timeRange = `
    ${ new Date(startAt.dt).toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' }) }
    -
    ${ new Date(endAt.dt).toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' }) }
  `;
  const timezoneAbbreviation = format(toZonedTime(startAt.dt, startAt.tz), 'zzz', { timeZone: startAt.tz });
  const timeRangeWithTimezone = intl.formatMessage({
    id: 'broadcastContent.event.timeRange',
    description: 'Event time range with timezone.',
    defaultMessage: '{ timeRange } ({ timezoneAbbreviation })',
  }, {
    timeRange,
    timezoneAbbreviation,
  });

  return (
    <VerticallySpaced gap={ 2 }>
      <EventInformationRow
        IconComponent={ CalendarIcon }
        label={ intl.formatMessage({
          id: 'broadcastContent.event.date',
          description: 'Label for date in event cards.',
          defaultMessage: 'date',
        }) }
        value={ startDate }
      />
      <EventInformationRow
        IconComponent={ ClockIcon }
        label={ intl.formatMessage({
          id: 'broadcastContent.event.time',
          description: 'Label for time range in event cards.',
          defaultMessage: 'time',
        }) }
        value={ showTimezone ? timeRangeWithTimezone : timeRange }
      />
      <EventInformationRow
        IconComponent={ PinIcon }
        label={ intl.formatMessage({
          id: 'broadcastContent.event.location',
          description: 'Label for location in event cards.',
          defaultMessage: 'location',
        }) }
        value={ location }
      />
    </VerticallySpaced>
  );
};

type EventInformationRowProps = {
  IconComponent: SvgComponent;
  label: string;
  value: string;
};

const EventInformationRow: FC<EventInformationRowProps> = ({
  IconComponent,
  label,
  value,
}) => (
  <StyledEventInformationRow>
    <IconComponent role="presentation"/>
    <VerticallySpaced>
      <StyledEventInformationValue aria-labelledby={ `event-${ label }` }>{ value }</StyledEventInformationValue>
      <StyledEventInformationLabel id={ `event-${ label }` }>
        { label }
      </StyledEventInformationLabel>
    </VerticallySpaced>
  </StyledEventInformationRow>
);
