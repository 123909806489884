import { CHOICE_OPTION_TITLE_MAX_LENGTH, DraftChoiceOption } from '../../Model/ContentTypes';
import { ValidationTree, Validator } from '../../../Common/Model';
import { Validate } from '../../../Common/Utility';

export class ChoiceOptionValidator {
  public static validateTitle = (option: DraftChoiceOption): ValidationTree<string> => ({
    errors: titleValidator.validate(option.title),
    children: {},
  });

  public static validate = (option: DraftChoiceOption): ValidationTree<DraftChoiceOption> => ({
    errors: [],
    children: {
      title: ChoiceOptionValidator.validateTitle(option),
    },
  });
}

const titleValidator = new Validator<string>([
  Validate.notBlank,
  title => Validate.maxLength(title, CHOICE_OPTION_TITLE_MAX_LENGTH),
]);
