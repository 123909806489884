import { ComponentProps, FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { RegistrationTemplate } from '../RegistrationTemplate/RegistrationTemplate';

export const RegistrationConfirmationTemplate: FC<ComponentProps<typeof RegistrationTemplate>> = ({
  children,
  ...props
}) => (
  <RegistrationTemplate
    { ...props }
  >
    { children }
    <FormattedMessage
      description="String used on registration confirmation page to confirm that the registration process is complete."
      defaultMessage="You may now close this window."
    />
  </RegistrationTemplate>
);
