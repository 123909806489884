import { FunctionComponent } from 'react';

import { TabbedSettings } from '../../../../Settings/Component';
import { SystemPermission } from '../../../../System/Model';

export const SystemPage: FunctionComponent = () => (
  <TabbedSettings
    path="/settings/system"
    updatePermission={ SystemPermission.SETTINGS_SECRET }
  />
);
