import { FC, useCallback } from 'react';

import { LiveComponentProps } from '../../../../Content/Model';
import { DateRequest as DateRequestModel } from '../../../Model/ContentTypes';
import { DraftDateResponse } from '../../../Service';
import { DateRequest } from '../../Content/DateRequest/DateRequest';
import { useContextOrThrow } from '../../../../Core/Hook';
import { FormNavigationContext } from '../../../Provider/FormNavigationProvider';

export const LiveDateRequest: FC<LiveComponentProps<DateRequestModel, DraftDateResponse>> = ({
  card,
  response,
  onResponseChange,
  onResponseClear,
  onValidationChange,
  validation,
}) => {
  const { displayingValidation } = useContextOrThrow(FormNavigationContext);
  const whenDateChanged = useCallback((date: string | undefined) => {
    if (!date) {
      onResponseClear();
      onValidationChange({
        errors: [],
        children: {},
      });
      return;
    }

    const response: DraftDateResponse = {
      contentId: card.content.id,
      contentType: 'dateRequest',
      date,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    onResponseChange(response);
    onValidationChange({
      errors: [],
      children: {},
    })
  }, [card, onResponseChange, onResponseClear, onValidationChange]);

  return (
    <DateRequest
      content={ card.content }
      onChange={ whenDateChanged }
      value={ response?.date }
      validation={ validation }
      displayValidation={ displayingValidation }
    />
  );
};
