import {
  ValidationErrorHandler
} from 'op-storybook/lib/utility/ValidationErrorHandlerFactory/ValidationErrorHandlerFactory';

import { useDefaultValidationErrorHandlers } from './useDefaultValidationErrorHandlers';

export const useValidationErrorHandlers = (
  subjectName: string,
  selectionSingular?: string,
  selectionPlural?: string,
  handlers: ValidationErrorHandler<any>[] = [], // eslint-disable-line @typescript-eslint/no-explicit-any
): ValidationErrorHandler<any>[] => { // eslint-disable-line @typescript-eslint/no-explicit-any
  const capitalisedSubjectName = subjectName.slice(0, 1).toLocaleUpperCase().concat(subjectName.slice(1));
  const defaultHandlers = useDefaultValidationErrorHandlers(capitalisedSubjectName, selectionSingular, selectionPlural);
  return [
    ...handlers,
    ...defaultHandlers,
  ];
};
