import { Fragment, FunctionComponent, useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Grid, Menu, MenuItem } from '@mui/material';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';

import { Condition, Segment } from '../../../Model';
import { ConditionBuilder } from '../../Builder/ConditionBuilder/ConditionBuilder';
import {
  StyledCondition,
  StyledEveryoneCondition,
  StyledGlobeIcon,
  StyledConditionShortcuts,
  StyledNobodyCondition,
} from './style';
import RemoveIcon from '../../../../Assets/img/icons/monochrome/close.svg';
import { ValidationTree } from '../../../../Common/Model';
import { DropdownButton, Flex, PresentationIcon, VerticallySpaced } from '../../../../Common/Component';
import { ArrayHelper } from '../../../../Common/Utility';
import { useEnvironmentSettings } from '../../../../Common/Hook';
import NobodyIcon from '../../../../Assets/img/icons/streamline/smiley-sad-1.svg';
import { ConditionIdentifier } from '../../../Utility';
import { LabelledDivider } from '../LabelledDivider/LabelledDivider';

type Filter = {
  label: string;
  condition: Condition;
};

interface Props {
  segment: Segment;
  onChange: (segment: Segment) => void;
  validation?: ValidationTree<Segment>;
  readonly?: boolean;
  onRemove?: () => void;
  removable?: boolean;
}

export const SegmentBuilder: FunctionComponent<Props> = ({
  segment,
  onChange,
  validation,
  readonly = false,
  removable = false,
  onRemove,
}) => {
  const intl = useIntl();
  const { nonTeamTagsEnabled } = useEnvironmentSettings();
  const [queuedCondition, setQueuedCondition] = useState<Condition>();
  const [latestConditionIndex, setLatestConditionIndex] = useState<number>();
  const [moreFilterAnchor, setMoreFilterAnchor] = useState<HTMLButtonElement | null>(null);
  const nonNestConditions = useMemo(() => (
    segment.conditions.reduce<Condition[]>(
      (nonNestConditions, condition) => (
        ConditionIdentifier.conditionIsNestCondition(condition)
          ? nonNestConditions
          : nonNestConditions.concat(condition)
      ),
      [],
    )
  ), [segment.conditions]);
  const filters = useMemo<Filter[]>(() => [
    ...(nonTeamTagsEnabled ? [
        {
          label: intl.formatMessage({
            id: 'audiences.builder.regionFilter',
            description: 'Label for region filter in audience builder.',
            defaultMessage: 'Region',
          }),
          condition: {
            type: 'tag',
            new: true,
            tagType: 'region',
            tagIds: [],
          },
        }
      ] : []),
    {
      label: intl.formatMessage({
        id: 'audiences.builder.teamFilter',
        description: 'Label for team filter in audience builder.',
        defaultMessage: 'Team',
      }),
      condition: {
        type: 'tag',
        new: true,
        tagType: 'team',
        tagIds: [],
      },
    },
    ...(nonTeamTagsEnabled ? [
        {
          label: intl.formatMessage({
            id: 'audiences.builder.departmentFilter',
            description: 'Label for department filter in audience builder.',
            defaultMessage: 'Department',
          }),
          condition: {
            type: 'tag',
            new: true,
            tagType: 'department',
            tagIds: [],
          },
        },
        {
          label: intl.formatMessage({
            id: 'audiences.builder.jobTitleFilter',
            description: 'Label for job title filter in audience builder.',
            defaultMessage: 'Job title',
          }),
          condition: {
            type: 'tag',
            new: true,
            tagType: 'jobtitle',
            tagIds: [],
          },
        },
        {
          label: intl.formatMessage({
            id: 'audiences.builder.skillFilter',
            description: 'Label for skill filter in audience builder.',
            defaultMessage: 'Skill',
          }),
          condition: {
            type: 'tag',
            new: true,
            tagType: 'skill',
            tagIds: [],
          },
        },
      ] : []),
    {
      label: intl.formatMessage({
        id: 'audiences.builder.personFilter',
        description: 'Label for person filter in audience builder.',
        defaultMessage: 'Person',
      }),
      condition: {
        type: 'person',
        new: true,
        personIds: [],
      },
    },
  ], [intl, nonTeamTagsEnabled]);
  const moreFilters = useMemo(() => [
    {
      label: intl.formatMessage({
        id: 'audiences.builder.statusFilter',
        description: 'Label for status filter in audience builder.',
        defaultMessage: 'Status',
      }),
      condition: {
        type: 'status',
        new: true,
        statuses: [],
      },
    },
    {
      label: intl.formatMessage({
        id: 'audiences.builder.roleFilter',
        description: 'Label for role filter in audience builder.',
        defaultMessage: 'User role',
      }),
      condition: {
        type: 'role',
        new: true,
        roleIds: [],
      },
    },
  ], [intl]);

  const whenConditionChanged = (index: number, condition: Condition): void => {
    onChange({
      ...segment,
      conditions: ArrayHelper.replace(nonNestConditions, index, condition),
    });
  };

  const whenAddConditionClicked = useCallback((condition: Condition) => {
    const nobodyIndex = nonNestConditions.findIndex(condition => condition.type === 'nobody');

    setLatestConditionIndex(nonNestConditions.length);
    return (
      onChange({
        ...segment,
        conditions: [
          ...ArrayHelper.replace(nonNestConditions, nobodyIndex, { type: 'everyone' }),
          condition,
        ],
      })
    );
  }, [nonNestConditions, onChange, segment]);

  const whenRemoveConditionClicked = (index: number): void => {
    onChange({
      ...segment,
      conditions: nonNestConditions.length === 1
        ? [{ type: 'everyone' }]
        : ArrayHelper.remove(nonNestConditions, index),
    });
  };

  const onMoreFiltersMenuExit = useCallback(() => {
    if (!queuedCondition) {
      return;
    }

    whenAddConditionClicked(queuedCondition);
    setQueuedCondition(undefined);
  }, [queuedCondition, whenAddConditionClicked]);

  return (
    <VerticallySpaced gap={ 3 }>
      {
        nonNestConditions.map((condition, index) => (
          <Fragment key={ index }>
            {
              condition.type === 'everyone'
                ? nonNestConditions.length === 1 && (
                <StyledEveryoneCondition>
                  <StyledGlobeIcon
                    role="presentation"
                    width="24px"
                    height="24px"
                  />
                  <div>
                    <FormattedMessage
                      id="audiences.builder.everyone"
                      description="Label for everyone audience condition"
                      defaultMessage="Everyone"
                    />
                  </div>
                </StyledEveryoneCondition>
              )
                : condition.type === 'nobody'
                  ? nonNestConditions.length === 1 && (
                  <StyledNobodyCondition>
                    <Flex gap={ 2 }>
                      <PresentationIcon
                        IconComponent={ NobodyIcon }
                        color="secondary.main"
                      />
                      <div>
                        <FormattedMessage
                          id="audiences.builder.nobody"
                          description="Label for nobody audience condition"
                          defaultMessage="Nobody"
                        />
                      </div>
                    </Flex>
                    { !readonly && (
                      <Button
                        onClick={ () => whenRemoveConditionClicked(index) }
                      >
                        <RemoveIcon/>
                      </Button>
                    ) }
                  </StyledNobodyCondition>
                )
                  : (
                    <>
                      { index !== 0 && !(index === 1 && nonNestConditions[0].type === 'everyone') && (
                        <LabelledDivider
                          label={ intl.formatMessage({
                            id: 'audiences.builder.divider.and',
                            defaultMessage: 'and',
                          }) }
                        />
                      ) }
                      <StyledCondition>
                        <ConditionBuilder
                          autoFocus={ latestConditionIndex === index }
                          condition={ condition }
                          onChange={ (condition) => whenConditionChanged(index, condition) }
                          validation={ validation?.children.conditions?.children[index] }
                          readonly={ readonly }
                        />
                        { !readonly && (
                          <Button
                            onClick={ () => whenRemoveConditionClicked(index) }
                          >
                            <RemoveIcon/>
                          </Button>
                        ) }
                      </StyledCondition>
                    </>
                  )
            }
          </Fragment>
        ))
      }
      { !readonly && (
        <>
          <LabelledDivider label=""/>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <StyledConditionShortcuts>
                <strong>
                  <FormattedMessage
                    id="audiences.builder.filterBy"
                    description="Label for filters in audience builder."
                    defaultMessage="Filter by:"
                  />
                </strong>
                { filters.map(filter => (
                  <Button
                    key={ filter.label }
                    onClick={ () => whenAddConditionClicked(filter.condition) }
                  >
                    { filter.label }
                  </Button>
                )) }
                <DropdownButton
                  onClick={ event => setMoreFilterAnchor(event.currentTarget) }
                >
                  <FormattedMessage
                    id="audiences.builder.moreFilters"
                    description="Label for additional filter dropdown in audience builder."
                    defaultMessage="More"
                  />
                </DropdownButton>
                <Menu
                  open={ Boolean(moreFilterAnchor) }
                  anchorEl={ moreFilterAnchor }
                  onClose={ () => setMoreFilterAnchor(null) }
                  TransitionProps={ {
                    onExit: onMoreFiltersMenuExit,
                  } }
                >
                  { moreFilters.map(filter => (
                    <MenuItem
                      key={ filter.label }
                      onClick={ () => {
                        setMoreFilterAnchor(null);
                        setQueuedCondition(filter.condition)
                      } }
                    >
                      { filter.label }
                    </MenuItem>
                  )) }
                </Menu>
              </StyledConditionShortcuts>
            </Grid>
            { removable && (
              <Grid item>
                <Button
                  onClick={ onRemove }
                  IconComponent={ RemoveIcon }
                >
                  <FormattedMessage
                    id="audiences.builder.removeSegment"
                    defaultMessage="Remove segment"
                  />
                </Button>
              </Grid>
            ) }
          </Grid>
        </>
      ) }
    </VerticallySpaced>
  );
};
