import { useCallback, useMemo, useState } from 'react';
import { AxiosError } from 'axios';
import { ApiRequest } from '@ourpeople/shared/Core/Model/ApiRequest';

import { useContextOrThrow } from '../../Core/Hook/useContextOrThrow';
import { AuthContext } from '../../Contexts';
import { RequestState } from '../../Models/RequestState';
import { useMounted } from '../../Common/Hook';
import {useApi} from "../../Core/Hook";

type Response = {
  login: (details: LoginDetails, rememberMe?: boolean) => void;
  loginRequest: ApiRequest<void, LoginErrorResponse>;
};

type LoginError = 'GENERIC' | 'INVALID' | 'RATE_LIMIT' | 'PHONE_NUMBER_NOT_FOUND' | 'EMAIL_ADDRESS_NOT_FOUND';

export type LoginErrorResponse = {
  error: LoginError;
  retryAfter?: number;
};

type EmailTokenLoginDetails = {
  emailAddress: string;
  mobileNumber?: never;
  token: string;
  password?: never;
  invitationRecipientId?: never;
};

type EmailPasswordLoginDetails = {
  emailAddress: string;
  mobileNumber?: never;
  password: string;
  token?: never;
  invitationRecipientId?: never;
};

type MobileNumberTokenLoginDetails = {
  mobileNumber: string;
  emailAddress?: never;
  token: string;
  password?: never;
  invitationRecipientId?: never;
};

type MobileNumberPasswordLoginDetails = {
  mobileNumber: string;
  emailAddress?: never;
  password: string;
  token?: never;
  invitationRecipientId?: never;
};

type InvitationLoginDetails = {
  invitationRecipientId: string;
  mobileNumber?: never;
  emailAddress?: never;
  password?: never;
  token?: never;
};

type LoginDetails = EmailTokenLoginDetails | EmailPasswordLoginDetails | MobileNumberTokenLoginDetails | MobileNumberPasswordLoginDetails | InvitationLoginDetails;

export const useLoginRequest = (): Response => {
  const { refreshAuthDescription } = useContextOrThrow(AuthContext);
  const api = useApi();
  const [loginRequest, setLoginRequest] = useState<ApiRequest<void, LoginErrorResponse>>({
    state: RequestState.PENDING,
    result: null,
  });
  const mounted = useMounted();

  const login = useCallback(
    (details: LoginDetails, rememberMe?: boolean) => {
      setLoginRequest({
        state: RequestState.FETCHING,
        result: null,
      });

      const loginMethod = details.invitationRecipientId
        ? 'INVITATION'
        : details.mobileNumber
          ? details.password
            ? 'MOBILE_NUMBER_AND_PASSWORD'
            : 'MOBILE_NUMBER_AND_SECURITY_TOKEN'
          : details.password
            ? 'EMAIL_ADDRESS_AND_PASSWORD'
            : 'EMAIL_ADDRESS_AND_SECURITY_TOKEN';

      const loginDetailsWithMethod = {
        method: loginMethod,
        ...(rememberMe !== undefined ? { rememberMe } : {}),
        ...details,
      };

      api.post(
        '/auth/session',
        loginDetailsWithMethod,
      )
        .then(() => {
          if (!mounted.current) {
            return;
          }

          refreshAuthDescription();
          setLoginRequest({
            state: RequestState.COMPLETE,
            result: undefined,
          });
        })
        .catch((error: AxiosError<unknown>) => {
          if (!mounted.current) {
            return;
          }

          switch (error.response?.status || 0) {
            case 401:
              setLoginRequest({
                state: RequestState.FAILED,
                result: {
                  error: 'INVALID'
                },
              });
              break;
            case 429:
              const headers = error.response?.headers as Record<string, string>;
              const retryAfterHeader = headers && headers['retry-after'];
              setLoginRequest({
                state: RequestState.FAILED,
                result: {
                  error: 'RATE_LIMIT',
                  retryAfter: isNaN(+retryAfterHeader) ? 0 : +retryAfterHeader,
                },
              });
              break;
            default:
              setLoginRequest({
                state: RequestState.FAILED,
                result: {
                  error: 'GENERIC',
                },
              });
          }
        });
    },
    [api, mounted, refreshAuthDescription],
  );

  return useMemo(() => ({
    login,
    loginRequest,
  }), [loginRequest, login]);
};
