import { useMemo } from 'react';

import { FetchResponseTransformer } from '../Forms/Utility';
import { FetchResponse } from './useRequest';

export const useTransformedFetchResponse = <T, S>(
  response: FetchResponse<T>,
  transformer: (content: T) => S,
): FetchResponse<S> => (
  useMemo(() => (
    FetchResponseTransformer.transform(response, transformer)
  ), [response, transformer])
);
