import { RichTextPlaceholderDefinition } from '../../Model/RichTextPlaceholderDefinition';
import { AuthDescription } from '../../../Models';
import { IntlShape } from 'react-intl';
import { TextFormatter } from '../../../Utility';

export class FullNameRichTextPlaceholderDefinition implements RichTextPlaceholderDefinition {
  public static readonly placeholderString: string = 'recipient.fullName';
  public readonly placeholderString: string = FullNameRichTextPlaceholderDefinition.placeholderString;
  public readonly localisedString: string = '';
  public readonly substitution: string = '';

  constructor(
    authDescription: AuthDescription,
    intl: IntlShape,
    public readonly substituteOnly: boolean = false,
  ) {
    this.localisedString = intl.formatMessage({
      description: 'Label for recipient full name placeholder in rich text editor.',
      defaultMessage: 'Recipient full name',
    });
    this.substitution = TextFormatter.spaceSeparated(authDescription.user.forename, authDescription.user.lastname || '');
  }
}
