import { Validator } from '../../../src/react/Common/Model/Validator';
import { Validate } from '../../../src/react/Common/Utility/Validate';
import { ValidationError } from '../../../src/react/Common/Model/ValidationError';

export class ContactMethodValidator {
  public static validate = (contactMethods: string[]): ValidationError[] => (
    contactMethodValidator.validate(contactMethods)
  );
}

const contactMethodValidator = new Validator<string[]>([
  contactMethods => {
    const blankErrors = contactMethods.map(Validate.notBlank)
      .filter(Boolean);

    return blankErrors.length === contactMethods.length
      ? {
        type: 'missingOneOf',
        metadata: {
          fields: [],
        },
      }
      : undefined
  },
]);
