import { FC, useEffect, useMemo, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { useIntl } from 'react-intl';

import { StyledImage, StyledThumbnailContainer, StyledThumbnailOverlay } from './style';
import { VideoPlayButton } from '..';
import { useFetchUploadUrls } from '../../../../../Common/Hook';

type Props = {
  uploadId: string;
  thumbnailKey: string;
  duration: number | undefined;
  busy: boolean;
  onClick: () => void;
  onReady?: () => void;
};

export const VideoThumbnail: FC<Props> = ({
  uploadId,
  thumbnailKey,
  duration,
  busy,
  onClick,
  onReady,
}) => {
  const intl = useIntl();
  const fetchUrlsParams = useMemo(() => ({ videoThumbnailKey: thumbnailKey }), [thumbnailKey]);
  const [fetchUrlsResult] = useFetchUploadUrls(uploadId, fetchUrlsParams);
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!loaded || !onReady) {
      return;
    }

    onReady();
  }, [loaded, onReady]);

  return (
    <div>
      { fetchUrlsResult && fetchUrlsResult.content?.thumbnail && (
        <StyledThumbnailContainer
          role="button"
          aria-label={ intl.formatMessage({
            id: 'videoThumbnail.play.label',
            description: 'Label for play button in video card',
            defaultMessage: 'Play video',
          }) }
          loaded={ loaded }
          onClick={ onClick }
        >
          <StyledImage
            onLoad={ () => setLoaded(true) }
            src={ fetchUrlsResult.content.thumbnail }
          />
          <StyledThumbnailOverlay>
            <VideoPlayButton
              duration={ duration }
              busy={ busy }
            />
          </StyledThumbnailOverlay>
        </StyledThumbnailContainer>
      ) }
      { !loaded && (
        <CircularProgress variant="indeterminate" size={ 30 }/>
      ) }
    </div>
  );
}
