export class TextFormatter {
  public static capitalise = (str: string): string => {
    return str.toUpperCase()
      .substr(0, 1)
      .concat(
        str.substr(1, str.length)
          .toLowerCase(),
      );
  }

  public static titleCase = (str: string): string => {
    return str.split(' ')
      .map(TextFormatter.capitalise)
      .join(' ');
  }

  public static spaceSeparated = (...words: string[]): string => {
    return words.map((word) => word.trim()).join(' ').trim();
  }
}
