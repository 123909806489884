import { FC } from 'react';

import { StyledContentWithType, StyledContentType, StyledContent } from './style';

type Props = {
  localisedContentType: string;
};

export const ContentWithType: FC<Props> = ({ localisedContentType, children }) => (
  <StyledContentWithType>
    <StyledContentType>{ localisedContentType }</StyledContentType>
    <StyledContent>
      { children }
    </StyledContent>
  </StyledContentWithType>
);
