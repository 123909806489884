import { FunctionComponent, ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Dataset } from '../../Model';
import { MetricContainer, MetricTooltip, MissingDataMessage, NumericMetric } from '..';
import { DeprecatedLink } from '../../../Common/Component';

interface Props {
  previousDataset?: Dataset;
  currentDataset?: Dataset;
}

export const ActivePeopleMetric: FunctionComponent<Props> = ({ previousDataset, currentDataset }) => {
  const intl = useIntl();
  const previousValue = previousDataset?.data[0]?.value || 0;
  const currentValue = currentDataset?.data[0]?.value || 0;

  return (
    <MetricContainer
      title={ intl.formatMessage({
        id: 'dashboard.activePeople.heading',
        description: 'Heading for active people stat block on dashboard.',
        defaultMessage: 'Active people',
      }) }
      span={ 2 }
      actions={ (currentValue || previousValue) && (
        <MetricTooltip title={ intl.formatMessage({
          id: 'dashboard.activePeople.tooltip',
          description: 'Tooltip for active people stat block on dashboard.',
          defaultMessage: 'The number of active people.',
        }) }/>
      ) }
    >
      {
        currentValue > 1
          ? (
            <NumericMetric
              viewMoreCta={ intl.formatMessage({
                id: 'dashboard.activePeople.viewMoreCta',
                description: 'Link to active people table.',
                defaultMessage: 'View people',
              }) }
              viewMoreLink="/people?statuses=active"
              displayChangePercent={ false }
              previousValue={ previousValue }
              currentValue={ currentValue }
            />
          )
          : (
            <MissingDataMessage>
              <FormattedMessage
                id="dashboard.activePeople.noData"
                defaultMessage="<p>Are you a bit lonely there?</p><p>Our People is better when you <a>invite people</a>.</p>"
                values={{
                  a: (chunks: ReactNode) => (
                    <DeprecatedLink to="/people/create">
                      { chunks }
                    </DeprecatedLink>
                  )
                }}
              />
            </MissingDataMessage>
          )
      }
    </MetricContainer>
  );
};
