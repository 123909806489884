import { FormattedMessage, useIntl } from 'react-intl';
import { useCallback, useMemo, useState } from 'react';
import { DialogProps } from '@mui/material';

import { useActionWithConfirmation, useMounted } from '../../Common/Hook';
import { useApi, useContextOrThrow } from '../../Core/Hook';
import { ToastContext } from '../../Core/Context';
import { RequestState } from '../../Models';
import { DeletePrompt } from '../../Common/Component';
import DeleteIcon from '../../Assets/img/icons/streamline/bin-1.svg';
import { ContextMenuAction } from '../../Common/Component/ContextMenu/ContextMenu';
import {FolderNode, Node} from '../Model';

export const useDeleteNodeAction = (
  node: Node,
  onReloadRequired: () => void,
  performingOtherAction: boolean,
): [JSX.Element, ContextMenuAction, RequestState] => {
  const [requestState, setRequestState] = useState<RequestState>(RequestState.PENDING);
  const { addSuccessToast, addErrorToast } = useContextOrThrow(ToastContext);
  const mounted = useMounted();
  const intl = useIntl();
  const api = useApi();
  const isFolderWithContent = node.type === 'folder' && !!(node as FolderNode).childrenCount;

  const deleteFolder = useCallback(() => {
    setRequestState(RequestState.FETCHING);
    api.delete(
      `/files/folders/${ node.id }`,
    )
      .then(() => {
        setTimeout(() => {
          addSuccessToast(intl.formatMessage({
            description: 'Toast message displayed on files page when a folder is deleted successfully.',
            defaultMessage: 'Folder deleted',
          }));

          if (!mounted.current) {
            return;
          }

          setRequestState(RequestState.COMPLETE);
          onReloadRequired();
        }, 50);
      })
      .catch(() => {
        addErrorToast(intl.formatMessage({
          description: 'Toast message displayed on files page when a folder cannot be updated.',
          defaultMessage: 'Folder could not be deleted',
        }));

        if (!mounted.current) {
          return;
        }

        setRequestState(RequestState.FAILED);
      });
  }, [addErrorToast, addSuccessToast, api, intl, mounted, node.id, onReloadRequired]);

  const deleteFile = useCallback(() => {
    setRequestState(RequestState.FETCHING);
    api.delete(
      `/files/files/${ node.id }`,
    )
      .then(() => {
        setTimeout(() => {
          addSuccessToast(intl.formatMessage({
            description: 'Toast message displayed on files page when a file is deleted successfully.',
            defaultMessage: 'File deleted',
          }));

          if (!mounted.current) {
            return;
          }

          setRequestState(RequestState.COMPLETE);
          onReloadRequired();
        }, 50);
      })
      .catch(() => {
        addErrorToast(intl.formatMessage({
          description: 'Toast message displayed on files page when a file cannot be updated.',
          defaultMessage: 'File could not be deleted',
        }));

        if (!mounted.current) {
          return;
        }

        setRequestState(RequestState.FAILED);
      });
  }, [addErrorToast, addSuccessToast, api, intl, mounted, node.id, onReloadRequired]);

  const deleteNode = useCallback(() => (
    node.type === 'file'
      ? deleteFile()
      : deleteFolder()
  ), [deleteFile, deleteFolder, node.type]);

  const renderConfirmationDialog = useCallback(({ open, onSubmit, onClose, TransitionProps }: DialogProps) => (
    <DeletePrompt
      open={ open }
      onConfirm={ onSubmit as () => void }
      onCancel={ onClose as () => void }
      TransitionProps={ TransitionProps }
      busy={ requestState === RequestState.FETCHING }
      renderContent={ confirmationString => (
        <FormattedMessage
          description="Text in dialog to confirm deleting a file or folder on the files page."
          defaultMessage="Type <strong>{ confirmationString }</strong> to delete <strong>{ nodeName }</strong>.{ folderWarning }"
          values={ {
            confirmationString,
            nodeName: node.name,
            folderWarning: isFolderWithContent
              ? ' Deleting this folder will also delete all files and folders within.'
              : ''
          } }
        />
      ) }
    />
  ), [requestState, node.name]);

  const [confirmationDialog, action] = useActionWithConfirmation(
    'delete',
    DeleteIcon,
    intl.formatMessage({
      description: 'Label for delete action on files page.',
      defaultMessage: 'Delete',
    }),
    renderConfirmationDialog,
    requestState,
    performingOtherAction,
    deleteNode,
  );

  return useMemo(() => [
    confirmationDialog,
    action,
    requestState,
  ], [action, confirmationDialog, requestState]);
};
