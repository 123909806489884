import { Dispatch, SetStateAction } from 'react';

import { useLocalStorageHookContext } from '../../Common/Hook';

type FcmTokenRegistration = {
  created: {
    at: string;
  };
  fcmToken: string;
};

type FcmTokenRegistrationState = [
  FcmTokenRegistration | null,
  Dispatch<SetStateAction<FcmTokenRegistration | null>>,
  boolean,
];

export const useFcmTokenRegistrationState = (): FcmTokenRegistrationState => {
  const getStorageHook = useLocalStorageHookContext();
  const useStoredFcmTokenRegistration = getStorageHook<FcmTokenRegistration | null>(
    'fcmTokenRegistration',
    null,
  );

  return useStoredFcmTokenRegistration();
};
