import { FunctionComponent, useContext, useState } from 'react';
import { Button, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { InputAndLabelContainer, LoadingButton, ValidationErrorMessage } from '../../../Common/Component';
import { StyledCreateDialog } from './style';
import { ApiContext } from '../../../Contexts';
import { useInputChangeEventHandler } from '../../../Common/Hook';
import { ApiClientWithSecret } from '../../Model';

type Props = {
  open: boolean;
  onClosed: () => void;
  onSaved: (client: ApiClientWithSecret) => void;
}

export const CreateDialog: FunctionComponent<Props> = ({ open, onSaved, onClosed }) => {
  const api = useContext(ApiContext);
  const [saving, setSaving] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [error, setError] = useState<'BLANK' | 'UNKNOWN'>();
  const whenNameChanged = useInputChangeEventHandler(setName);

  const whenSaveClicked = () => {
    if (!api) {
      return;
    }

    if (!name) {
      setError('BLANK');
      return;
    }

    setError(undefined);
    setSaving(true);
    api.post<ApiClientWithSecret>('/clients', { name })
      .then(response => {
        onSaved(response.data);
        setSaving(false);
        onClosed();
      })
      .catch(() => {
        setError('UNKNOWN');
        setSaving(false);
      });
  }

  return (
    <StyledCreateDialog
      open={ open }
      onClose={ onClosed }
    >
      <DialogTitle>
        <FormattedMessage
          id="apiClients.addClient.header"
          description="Add client dialog header"
          defaultMessage="Add client"
        />
      </DialogTitle>
      <DialogContent>
        <InputAndLabelContainer htmlFor="client-name">
          <FormattedMessage
            id="apiClients.addClient.clientName.label"
            description="Client name input label"
            defaultMessage="Client name"
          />
          <TextField
            id="client-name"
            variant="outlined"
            margin="dense"
            value={ name }
            error={ !!error }
            onChange={ whenNameChanged }
          />
        </InputAndLabelContainer>
        {
          error && (
            <ValidationErrorMessage>
              {
                error === 'UNKNOWN' && (
                  <FormattedMessage
                    id="apiClients.addClient.error.unknown"
                    description="Generic error message"
                    defaultMessage="Client name is invalid."
                  />
                )
              }
              {
                error === 'BLANK' && (
                  <FormattedMessage
                    id="apiClients.addClient.error.blank"
                    description="Blank client name error message"
                    defaultMessage="Client name can not be blank."
                  />
                )
              }
            </ValidationErrorMessage>
          )
        }
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={ onClosed }
        >
          <FormattedMessage
            id="apiClients.addClient.cancel"
            description="Add client dialog cancel button cta"
            defaultMessage="Cancel"
          />
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          disableElevation
          busy={ saving }
          onClick={ whenSaveClicked }
        >
          <FormattedMessage
            id="apiClients.addClient.save"
            description="Add client dialog save button cta"
            defaultMessage="Save"
          />
        </LoadingButton>
      </DialogActions>
    </StyledCreateDialog>
  );
};
