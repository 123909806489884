import { FC } from 'react';

import { NavigationIcon } from '..';
import { StyledTabBar, StyledTabButton } from './style';
import { ConfiguredNavigationItem } from '../../../Model';
import { NavigationItemIdentifier } from '../../../Utility';
import { UnlockedItemCircle } from '../../UnlockedItemCircle/UnlockedItemCircle';

type Props = {
  navigationItems: ConfiguredNavigationItem[];
};

export const TabBar: FC<Props> = ({
  navigationItems,
}) => (
  <StyledTabBar>
    { navigationItems.map(navigationItem => (
      NavigationItemIdentifier.navigationItemIsButton(navigationItem) && (
        <li
          onClick={ navigationItem.onClick }
          key={ navigationItem.ref }
        >
          <StyledTabButton active={ navigationItem.active }>
            {
              navigationItem.disabled
                ? <div/>
                : (
                  <>
                    <NavigationIcon
                      Icon={ navigationItem.Icon }
                      showLock={ navigationItem.disabled }
                    />
                    <span>{ navigationItem.shortLabel || navigationItem.label }</span>
                  </>
                )
            }
            { navigationItem.unlockedIndex !== null && <UnlockedItemCircle index={ navigationItem.unlockedIndex }/> }
          </StyledTabButton>
        </li>
      )
    )) }
  </StyledTabBar>
);
