import styled from '@emotion/styled';

export const StyledFormContent = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-areas: 'header' 'content';
  overflow: hidden;
`;

export const AnonymousMessageHint = styled.div `
  background: ${props => props.theme.palette.grey[300]};
  padding: 0.25rem 0.5rem;
  margin: 0 0.5rem;
  display: flex;
  border-radius: 0.5rem;
  align-self: flex-start;
  grid-area: header;

  svg {
    margin-top: 0.5rem;
    margin-right: 0.5rem;
    color: ${props => props.theme.palette.primary.main};
    width: 2rem;
  }

  div {
    font-weight: bold;
    font-size: 1rem;
  }

  div:last-child {
    font-weight: normal;
    font-size: 0.9rem;
  }
`;
