import styled from '@emotion/styled';

export const StyledChoiceRequestOptions = styled.ol`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: ${ props => props.theme.spacing(2) };
`;

export const StyledChoiceRequestOption = styled.li`
  label {
    border: 1px solid ${ props => props.theme.palette.text.primary };
    border-radius: ${ props => props.theme.spacing(0.5) };
    width: 100%;
    padding: ${ props => props.theme.spacing(1) };
    display: flex;
    background-color: ${ props => props.theme.palette.background.paper };

    &:hover {
      background-color: ${ props => props.theme.palette.grey[100] };
    }
  }

  input {
    width: 0;
    height: 0;
    opacity: 0;
  }
`;

export const StyledCheckbox = styled.div<{ $checked: boolean }>`
  border: 1px solid ${ props => props.theme.palette.text.primary };
  border-radius: 999rem;
  width: ${ props => props.theme.spacing(3) };
  height: ${ props => props.theme.spacing(3) };
  display: flex;
  justify-content: center;
  align-items: center;

  >div {
    border-radius: 999rem;
    width: ${ props => props.theme.spacing(2) };
    height: ${ props => props.theme.spacing(2) };
    transition: background-color 0.2s;

    ${ props => props.$checked && `
      background-color: ${ props.theme.palette.secondary.main };
    ` }
  }
`;

export const StyledHelpText = styled.p`
  margin: 0;
  text-align: center;
`;
