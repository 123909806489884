import { ReactNode, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { ConfirmationDialog } from '@ourpeople/shared/Core/Component/Feedback';

import { ContextMenuAction } from '../../../Common/Component/ContextMenu/ContextMenu';
import { PopOverState } from '../../../Common/Model';
import { useApi, useContextOrThrow } from '../../../Core/Hook';
import { useMounted } from '../../../Common/Hook';
import { ToastContext } from '../../../Core/Context';
import ArchiveIcon from '../../../Assets/img/icons/streamline/archive-content.svg';
import { BroadcastsPermission } from '../../Model';
import { usePermissions } from '../../../Security/Hook';

type ReturnValue = {
  archiveConfirmationDialog: ReactNode;
  archiveAction: ContextMenuAction;
  archiving: boolean;
};

export const useArchiveBroadcastAction = (
  broadcastId: string,
  resourceLockMessage: string | null,
  performingOtherAction: boolean,
): ReturnValue => {
  const intl = useIntl();
  const api = useApi();
  const [archiving, setArchiving] = useState<boolean>(false);
  const [archiveDialogState, setArchiveDialogState] = useState<PopOverState>(PopOverState.CLOSED);
  const mounted = useMounted();
  const { addSuccessToast, addErrorToast } = useContextOrThrow(ToastContext);
  const history = useHistory();
  const { guardedCallback } = usePermissions();

  const whenArchiveConfirmed = useCallback(() => {
    setArchiveDialogState(PopOverState.WILL_CLOSE);
    setArchiving(true);
    api.post(`/broadcasts/${ broadcastId }/archive`)
      .then(() => (
        setTimeout(() => {
          if (!mounted.current) {
            return;
          }

          addSuccessToast(
            intl.formatMessage({
              description: 'Toast message when broadcast is archived successfully.',
              defaultMessage: 'Broadcast archived successfully.',
            })
          );
          setArchiving(false);
          history.push('/broadcasts?tab=archived&pageNum=1');
        }, 50)
      ))
      .catch(() => {
        if (!mounted.current) {
          return;
        }

        addErrorToast(
          intl.formatMessage({
            description: 'Toast message when broadcast cannot be archived.',
            defaultMessage: 'Broadcast could not be archived.',
          })
        );
        setArchiving(false);
      })
  }, [api, broadcastId, mounted, addSuccessToast, intl, history, addErrorToast]);

  const archiveConfirmationDialog = useMemo(() => (
    <>
      { archiveDialogState !== PopOverState.CLOSED && (
        <ConfirmationDialog
          open={ archiveDialogState === PopOverState.OPEN }
          TransitionProps={ {
            onExited: () => setArchiveDialogState(PopOverState.CLOSED),
          } }
          title={
            intl.formatMessage({
              description: 'Confirmation dialog title when archiving broadcast.',
              defaultMessage: 'Archive broadcast',
            })
          }
          description={
            intl.formatMessage({
              description: 'Confirmation dialog message when archiving broadcast.',
              defaultMessage: 'Do you really want to archive this broadcast?',
            })
          }
          onCancel={ () => setArchiveDialogState(PopOverState.WILL_CLOSE) }
          onClose={ () => setArchiveDialogState(PopOverState.WILL_CLOSE) }
          onConfirm={ whenArchiveConfirmed }
        />
      ) }
    </>
  ), [archiveDialogState, intl, whenArchiveConfirmed]);

  return useMemo(() => ({
    archiveAction: {
      id: 'archive',
      IconComponent: ArchiveIcon,
      label: intl.formatMessage({
        description: 'Label for archive action in recent deliveries action menu.',
        defaultMessage: 'Archive',
      }),
      busy: archiving,
      ...(resourceLockMessage ? {
          disabled: true,
          disabledTooltip: resourceLockMessage,
        } : {
        disabled: performingOtherAction,
        }),
      onClick: guardedCallback(() => setArchiveDialogState(PopOverState.OPEN), [BroadcastsPermission.UPDATE]),
    },
    archiveConfirmationDialog,
    archiving,
  }), [archiveConfirmationDialog, archiving, guardedCallback, intl, performingOtherAction, resourceLockMessage]);
};
