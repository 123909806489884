import { FC, ReactNode } from 'react';
import { Heading } from '@ourpeople/shared/Core/Component/Content';

import { Feedback, VerticallySpaced } from '../../../../../Common/Component';
import { StyledEventStatus } from './style';
import { BackButton } from '@ourpeople/shared/Core/Component/Input/BackButton/BackButton';

type Props = {
  title: string;
  status: Feedback;
  type: string;
  backButton: ReactNode | undefined;
};

export const EventStatus: FC<Props> = ({
  title,
  status,
  type,
  backButton,
}) => (
  <StyledEventStatus color={ status.severity }>
    <div css={ { display: 'flex', alignItems: 'center' } }>{ backButton }</div>
    <VerticallySpaced>
      <Heading type="h2">{ title }</Heading>
      <span>{ status.message } | { type }</span>
    </VerticallySpaced>
  </StyledEventStatus>
);
