import styled from '@emotion/styled';
import { RadioGroup, TableCell } from '@mui/material';

export const InlineRadioGroup = styled(RadioGroup)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;

  .MuiFormControlLabel-root {
    margin: 0;
  }
`;

export const RequestStateCell = styled(TableCell)`
  width: 8.5rem;
`;

export const AccessCell = styled(TableCell)`
  width: 0;
  padding: 0.5rem;
`;

export const TableAndFilterContainer = styled.div`
  position: relative;
  min-height: 30rem;
`;

export const TableContainer = styled.div`
  overflow-y: scroll;
`;

export const ImportStatus = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  background-color: rgba(200, 200, 200, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;

  >div {
    background-color: ${props => props.theme.palette.background.paper };
    width: 30rem;
    padding: 2rem;
    display: grid;
    grid-template-rows: auto auto;
    justify-items: center;
    row-gap: 2rem;

    p {
      text-align: center;
    }
  }
`;
