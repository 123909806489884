import styled from '@emotion/styled';

export const StyledMediaContentPreviewWithoutButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: ${ props => props.theme.spacing(2) };
`;

export const StyledIconAndSize = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  column-gap: ${ props => props.theme.spacing(1) };

  svg {
    width: ${ props => props.theme.spacing(8) };
    height: ${ props => props.theme.spacing(8) };
  }

  >*:first-child {
    grid-column-start: 2;
  }
`;

export const StyledFileSize = styled.span`
  color: ${ props => props.theme.palette.secondary.main };
  grid-column-start: 3;
`;

export const StyledFileName = styled.span`
  text-align: center;
  color: ${ props => props.theme.palette.secondary.main };
  line-break: anywhere;
`;
