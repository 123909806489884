import styled from '@emotion/styled';
import { defaultTheme } from 'op-storybook';

import { ThemeHelper } from '../../../Utility/ThemeHelper';

export const StyledIconContainer = styled.div<{ size: keyof typeof defaultTheme.new['spacing'], color?: string }>`
  line-height: 0;
  color: ${ props => ThemeHelper.lookUpColour(props.color || 'inherit', props.theme.new) };
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: ${ props => props.theme.new.spacing[props.size] };
    height: ${ props => props.theme.new.spacing[props.size] };
    pointer-events: none;
  }
`;
