import { FunctionComponent, useCallback, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AxiosError } from 'axios';
import { FormControlLabel, Switch } from '@mui/material';
import { Heading } from '@ourpeople/shared/Core/Component/Content';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';

import { PersonWithSensitiveData, RequestState } from '../../../Models';
import { PersonForCreating } from '../../Model';
import { PersonTypeConverter } from '../../Utility';
import { SharedPersonFormFields } from '..';
import { useMounted } from '../../../Common/Hook';
import { Flex, FlexPullRight } from '../../../Common/Component';
import { ValidationTree } from '../../../Common/Model';
import { useApi, useContextOrThrow } from '../../../Core/Hook';
import { ErrorResponseReader } from '../../../Common/Utility';
import { ToastContext } from '../../../Core/Context';
import { CustomField } from '../../Hook';
import { StyledDivider, StyledFormSection } from '../SharedPersonFormFields/styles';
import { StyledFormRow } from '../FlagsFormFields/styles';
import { ProfileAndMinimalTags } from '../../../Profiles/Model';
import { DraftProfile } from '../ProfileForm/ProfileForm';

interface SavePersonResponse {
  person: PersonWithSensitiveData;
  profiles: ProfileAndMinimalTags[],
}

type Props = {
  initialPerson?: PersonWithSensitiveData;
  onSave?: (person: PersonWithSensitiveData) => void;
  initialCustomFields?: CustomField[];
  initialProfiles?: DraftProfile[];
  initialAdministratedTeamIds?: string[];
};

export const EditForm: FunctionComponent<Props> = ({
  initialPerson,
  onSave,
  initialCustomFields = [],
  initialProfiles = [],
  initialAdministratedTeamIds = [],
}) => {
  const intl = useIntl();
  const editFormRef = useRef<HTMLDivElement>(null);
  const [customFields, setCustomFields] = useState<CustomField[]>(initialCustomFields);
  const nonBlankCustomFields = useMemo(() => (
    customFields.reduce<CustomField[]>(
      (partialNonBlankCustomFields, customField) => (
        customField.value
          ? partialNonBlankCustomFields.concat(customField)
          : partialNonBlankCustomFields
      ),
      [],
    )
  ), [customFields]);
  const [sendInvitation, setSendInvitation] = useState<boolean>(true);
  const [savePersonState, setSavePersonState] = useState<RequestState>(RequestState.PENDING);
  const { addSuccessToast, addErrorToast } = useContextOrThrow(ToastContext);
  const mounted = useMounted();
  const api = useApi();
  const getUnmodifiedPerson = useCallback((person?: PersonWithSensitiveData) => (
    person
      ? {
        ...PersonTypeConverter.convertPersonWithSensitiveDataToPersonForEditing(person),
        administratedTeamIds: initialAdministratedTeamIds?.length
          ? initialAdministratedTeamIds
          : defaultPerson.administratedTeamIds,
      }
      : defaultPerson
  ), [initialAdministratedTeamIds]);
  const [person, setPerson] = useState<PersonForCreating>(getUnmodifiedPerson(initialPerson));
  const [profiles, setProfiles] = useState<DraftProfile[]>(initialProfiles);
  const [personId, setPersonId] = useState<string | undefined>(
    initialPerson
      ? `${ initialPerson.id }`
      : undefined
  );
  const [personValidation, setPersonValidation] = useState<ValidationTree<PersonForCreating>>();

  const createPerson = useCallback(() => {
    return api.post<SavePersonResponse>(
      '/people',
      {
        ...person,
        profiles: profiles.map(profile => ({
          id: profile.id,
          tagIds: profile.tags.map(tag => tag.id),
        })),
        customFields: nonBlankCustomFields,
        sendInvitation,
        emailAddresses: person.emailAddresses.map(verifiableEmailAddress => verifiableEmailAddress.emailAddress)
          .filter(Boolean),
      },
    )
  }, [api, nonBlankCustomFields, person, sendInvitation, profiles]);

  const updatePerson = useCallback((personId: string) => {
    return api.post<SavePersonResponse>(
      `/people/${personId}`,
      {
        ...person,
        profiles: profiles.map(profile => ({
          id: profile.id,
          tagIds: profile.tags.map(tag => tag.id),
        })),
        customFields: nonBlankCustomFields,
        emailAddresses: person.emailAddresses.map(verifiableEmailAddress => verifiableEmailAddress.emailAddress)
          .filter(Boolean),
      },
    )
  }, [api, nonBlankCustomFields, person, profiles]);

  const savePerson = useCallback((personId?: string): Promise<string | undefined> => {
    return (
      personId
        ? updatePerson(personId)
        : createPerson()
    )
      .then(response => response.data)
      .then(personResponse => {
        if (!mounted.current) {
          return undefined;
        }

        const savedPerson = personResponse.person;
        onSave && onSave(savedPerson);
        setPerson({
          ...getUnmodifiedPerson(savedPerson),
          administratedTeamIds: person.administratedTeamIds,
        });
        setPersonId(`${savedPerson.id}`);
        setSavePersonState(RequestState.COMPLETE);
        setPersonValidation(undefined);
        setProfiles(personResponse.profiles.map(profileAndTags => ({
          id: profileAndTags.profile.id.toString(),
          externallyManaged: profileAndTags.profile.externallyManaged,
          tags: profileAndTags.tags,
        })));
        return savedPerson.id;
      })
      .catch(error => {
        if (!mounted.current) {
          return undefined;
        }

        setSavePersonState(RequestState.FAILED);
        const axiosError = error as AxiosError;
        if (axiosError.response && ErrorResponseReader.isValidationErrorResponse<PersonForCreating>(axiosError.response)) {
          const validationTree = axiosError.response.data.error.data.root;
          setPersonValidation(validationTree);
        }
        throw error;
      })
  }, [createPerson, getUnmodifiedPerson, mounted, onSave, person.administratedTeamIds, updatePerson]);

  const resetForm = useCallback(() => {
    setPerson(defaultPerson);
    setPersonId(undefined);
    setSavePersonState(RequestState.PENDING);
    setProfiles([]);
  }, []);

  const save = useCallback(() => {
    savePerson(personId)
      .then(() => {
        addSuccessToast(intl.formatMessage({
          description: 'Success message when saving person.',
          defaultMessage: 'Person saved successfully.',
        }));

        if (!initialPerson) {
          resetForm();
          editFormRef.current?.scrollIntoView();
        }
      })
      .catch(() => {
        addErrorToast(intl.formatMessage({
          description: 'Error message when saving person.',
          defaultMessage: 'Person could not be saved.',
        }))
      })
  }, [
    addErrorToast,
    addSuccessToast,
    initialPerson,
    intl,
    personId,
    resetForm,
    savePerson,
  ]);

  const whenSaveButtonClicked = useCallback(() => {
    save();
  }, [save]);

  const saving = savePersonState === RequestState.FETCHING;

  return (
    <>
      <div ref={ editFormRef }/>
      <SharedPersonFormFields
        person={ person }
        onPersonChange={ setPerson }
        customFields={ customFields }
        onCustomFieldsChange={ setCustomFields }
        validation={ personValidation }
        profiles={ profiles }
        onProfilesChanged={ setProfiles }
      />
      { !personId && (
        <>
          <StyledDivider/>
          <Heading type="h2">
            <FormattedMessage
              description="Heading for notification section in person invite form"
              defaultMessage="Notification"
            />
          </Heading>
          <StyledFormSection>
            <StyledFormRow>
              <FormControlLabel
                control={
                  <Switch
                    name="sendInvitation"
                    checked={ sendInvitation }
                    onChange={ (_event, checked) => setSendInvitation(checked) }
                  />
                }
                label={
                  <FormattedMessage
                    description="Label for toggling invitation sms "
                    defaultMessage="Send invitation"
                  />
                }
              />
            </StyledFormRow>
          </StyledFormSection>
        </>
      ) }
      <Flex>
        <FlexPullRight>
          <Button
            variant="primary"
            busy={ saving }
            onClick={ whenSaveButtonClicked }
          >
            <FormattedMessage
              description="Account edit form save button label"
              defaultMessage="Save"
            />
          </Button>
        </FlexPullRight>
      </Flex>
    </>
  );
};

const defaultPerson: PersonForCreating = {
  firstName: '',
  lastName: null,
  roleId: 'ROLE_APP_BROADCAST',
  emailAddresses: [],
  timezone: null,
  mobileNumber: null,
  fake: false,
  secret: false,
  administratedTeamIds: []
};
