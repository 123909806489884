import { FC, useCallback, useState } from 'react';

import { ViewResponse } from '../../../Model';
import { BroadcastContentContext } from '../../../../Broadcasts/Model';
import { LiveComponentProps } from '../../../../Content/Model';
import { LegacyVideoBroadcastContent } from '../../../../Content/Component';
import { VideoContent } from '../../../../Broadcasts/Service';
import { VideoUploadDialog } from '../../../../Common/Component';
import { useSubmitResponse } from '../../../Hook/useSubmitResponse';
import { useRecallErrorHandler } from '../../../Hook';

export const LegacyLiveVideoContent: FC<LiveComponentProps<VideoContent, ViewResponse, BroadcastContentContext>> = ({
  context,
  card: { content },
  response,
  onResponseChange,
  onReloadRequired,
}) => {
  const [videoDialogOpen, setVideoDialogOpen] = useState<boolean>(false);
  const [videoDialogMounted, setVideoDialogMounted] = useState<boolean>(false);

  const [submitResponse] = useSubmitResponse(
    content.id.toString(),
    onResponseChange,
    useRecallErrorHandler(onReloadRequired),
  );

  const whenPlayVideoClicked = useCallback(() => {
    setVideoDialogOpen(true);
    setVideoDialogMounted(true);

    if (response) {
      return;
    }

    submitResponse({
      type: 'view',
      contentId: content.id.toString(),
      deliveryId: context.deliveryId,
      createdAt: new Date().toISOString(),
    });
  }, [content.id, context.deliveryId, response, submitResponse]);

  return (
    <>
      <LegacyVideoBroadcastContent
        media={ content.media }
        navigationProps={ context.navigationProps }
        onOpenFileClicked={ whenPlayVideoClicked }
        openFileBusy={ videoDialogOpen }
        text={ content.text }
        nudge={ context.nudge }
      />
      { videoDialogMounted && (
        <VideoUploadDialog
          TransitionProps={ {
            onExited: () => setVideoDialogMounted(false),
          } }
          open={ videoDialogOpen }
          uploadId={ content.media.id.toString() }
          onClose={ () => setVideoDialogOpen(false) }
        />
      ) }
    </>
  );
};
