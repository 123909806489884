import { FC, useCallback, useState } from 'react';

import { LiveComponentProps } from '../../../../Content/Model';
import { ChoiceRequest } from '../../../../Broadcasts/Service';
import { ChoiceResponse } from '../../../Model';
import { BroadcastContentContext } from '../../../../Broadcasts/Model';
import { ChoiceBroadcastContent } from '../../../../Content/Component';
import { useRecallErrorHandler, useRespondButtonProps } from '../../../Hook';

export const LiveChoiceContent: FC<LiveComponentProps<ChoiceRequest, ChoiceResponse, BroadcastContentContext>> = ({
  card: { content },
  response,
  context,
  onResponseChange,
  onReloadRequired,
}) => {
  const [partialResponse, setPartialResponse] = useState<Omit<ChoiceResponse, 'createdAt'>>({
    type: 'choice',
    input: {
      optionIds: response?.input.optionIds || [],
    },
    deliveryId: context.deliveryId,
    contentId: content.id.toString(),
  });
  const submitButtonProps = useRespondButtonProps(
    partialResponse,
    onResponseChange,
    useRecallErrorHandler(onReloadRequired),
    response,
    (response: ChoiceResponse) => ({
      type: 'choice',
      option_ids: response.input.optionIds,
      time_created: response.createdAt,
      deliveryId: response.deliveryId,
      content: response.contentId,
    }),
  );

  const whenChoiceIdsChanged = useCallback((optionIds: string[]) => (
    setPartialResponse({
      ...partialResponse,
      input: {
        optionIds,
      },
    })
  ), [partialResponse]);

  return (
    <ChoiceBroadcastContent
      backButton={ context.closeButton }
      title={ content.title }
      selectedOptionIds={ partialResponse.input.optionIds }
      onSelectedOptionIdsChanged={ whenChoiceIdsChanged }
      options={ content.options }
      multipleChoice={ content.type === 'multiplechoicequestion' }
      submitProps={ submitButtonProps }
      responded={ !!response }
      navigationProps={ context.navigationProps }
      nudge={ context.nudge }
    />
  );
};
