import {FunctionComponent, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {Link} from 'react-router-dom';

import {Person} from '../../../Models';
import {useUserRoles} from '../../../Common/Hook';
import {TableRowContextMenu} from '../../../Common/Component/TableRowContextMenu/TableRowContextMenu';
import {ContextMenuAction} from '../../../Common/Component/ContextMenu/ContextMenu';
import {RoleReader} from '../../../Readers';
import EditIcon from '../../../Assets/img/icons/streamline/common-file-edit.svg';
import FreezeIcon from '../../../Assets/img/icons/streamline/single-neutral-actions-block.svg';
import DefrostIcon from '../../../Assets/img/icons/streamline/single-neutral-actions-check-1.svg';
import DeleteIcon from '../../../Assets/img/icons/streamline/bin-1.svg';
import PersonIcon from '../../../Assets/img/icons/streamline/single-neutral.svg';
import {usePermissions} from '../../../Security/Hook';
import {PeoplePermission} from '../../Model';

type Props = {
  person: Person;
  deleted: boolean;
  onFreezeClicked: (person: Person) => void;
  togglingFrozen: boolean;
  onDeleteClicked: (person: Person) => void;
  deleting: boolean;
}

export const PeopleTableMenu: FunctionComponent<Props> = ({
  person,
  deleted,
  onFreezeClicked,
  togglingFrozen,
  onDeleteClicked,
  deleting,
}) => {
  const intl = useIntl();
  const { userIsSuperAdmin } = useUserRoles();
  const { permissionAvailable, guardedCallback } = usePermissions();

  const authedUserCanFreezePerson = useMemo(() => {
    if (userIsSuperAdmin) {
      return true;
    }

    return !RoleReader.isSuperAdmin(person.roleIds);
  }, [person.roleIds, userIsSuperAdmin]);

  const menuActions = useMemo<ContextMenuAction[]>(() => [
    {
      id: 'edit',
      label: intl.formatMessage({
        description: 'Label for edit person button in people table menu',
        defaultMessage: 'Edit',
      }),
      IconComponent: EditIcon,
      component: Link,
      to: `/people/${ person.id }/edit`,
      disabled: deleted,
    },
    {
      id: 'view',
      label: intl.formatMessage({
        description: 'Label for view person button in people table menu',
        defaultMessage: 'View account',
      }),
      IconComponent: PersonIcon,
      component: Link,
      to: `/people/${ person.id }/`,
      disabled: deleted,
    },
    ...(authedUserCanFreezePerson ? [
        ...(person.status === 'frozen' ? [
            {
              id: 'unfreeze',
              label: intl.formatMessage({
                description: 'Label for unfreeze action in people table context menu.',
                defaultMessage: 'Unfreeze',
              }),
              IconComponent: DefrostIcon,
              onClick: () => onFreezeClicked(person),
              busy: togglingFrozen,
              disabled: deleted || person.externallyManaged,
            }
          ] : [
            {
              id: 'freeze',
              label: intl.formatMessage({
                description: 'Label for freeze action in people table context menu.',
                defaultMessage: 'Freeze',
              }),
              IconComponent: FreezeIcon,
              onClick: () => onFreezeClicked(person),
              busy: togglingFrozen,
              disabled: deleted || person.externallyManaged,
            }
          ])
      ] : []),
    ...(permissionAvailable(PeoplePermission.DELETE) ? [
        {
          id: 'delete',
          label: intl.formatMessage({
            description: 'Label for delete action in people table context menu.',
            defaultMessage: 'Delete',
          }),
          IconComponent: DeleteIcon,
          busy: deleting,
          onClick: guardedCallback(() => onDeleteClicked(person), [PeoplePermission.DELETE]),
          disabled: deleted,
        }
      ] : []),
  ], [
    authedUserCanFreezePerson,
    deleted,
    deleting,
    guardedCallback,
    intl,
    onDeleteClicked,
    onFreezeClicked,
    permissionAvailable,
    person,
    togglingFrozen
  ]);

  return (
    <TableRowContextMenu
      id="people"
      actions={ menuActions }
      inlineActionCount={ 1 }
    />
  )
};
