import styled from '@emotion/styled';
import { darken } from 'polished';

export const StyledButton = styled.div<{ inline: boolean; fullWidth?: boolean }>`
  position: relative;

  ${ props => props.fullWidth && `
    width: 100%;
  ` }

  ${ props => props.inline && `
    display: inline;

    button {
      font-weight: 300;
      margin: ${ props.theme.spacing(-0.5) };
      text-align: left;
    }
  ` }

  .MuiButton-root {
    min-width: initial;
    ${ props => props.inline && `
      padding: 0 ${ props.theme.spacing(0.5) };
      font-size: inherit;
  ` };
  }

  ${ props => props.color === 'error' && `
    .MuiButton-contained, .MuiButton-containedPrimary {
      background-color: ${ props.theme.palette.error.main };
      color: ${ props.theme.palette.error.contrastText };

      &:hover {
        background-color: ${darken(0.04, props.theme.palette.error.main)};
      }
    }
  ` }
`;

export const ProgressContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
