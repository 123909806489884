import { FC } from 'react';
import { useIntl } from 'react-intl';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';

import { EventPresentationComponentProps } from '../../../Model';
import { EventStatusAndDetails } from '../Event';
import { BroadcastContentFooter } from '..';
import { StyledFeedback } from './style';

export const TrainingBroadcastContent: FC<EventPresentationComponentProps> = ({
  title,
  startAt,
  endAt,
  location,
  personalStatus,
  navigationProps,
  requestButtonProps,
  requestHidden = false,
  declineButtonProps,
  declineHidden = false,
  feedback,
  nudge,
  backButton,
}) => {
  const intl = useIntl();

  return (
    <>
      <EventStatusAndDetails
        backButton={ backButton }
        startAt={ startAt }
        endAt={ endAt }
        location={ location }
        title={ title }
        personalStatus={ personalStatus }
        type={ intl.formatMessage({
          id: 'content.training.type',
          description: 'Training type in inbox and editor preview.',
          defaultMessage: 'Training',
        }) }
        nudge={ nudge }
      >
        { feedback && <StyledFeedback>{ feedback }</StyledFeedback> }
      </EventStatusAndDetails>
      <BroadcastContentFooter
        { ...navigationProps }
      >
        { !declineHidden && (
          <Button variant="secondary" { ...declineButtonProps }>
            {
              ['waiting', 'attending'].includes(personalStatus.status)
                ? intl.formatMessage({
                  id: 'content.training.cancel',
                  description: 'Meeting decline button label when user is attending or on waiting list.',
                  defaultMessage: 'Cancel attendance',
                })
                : intl.formatMessage({
                  id: 'content.training.decline',
                  description: 'Meeting decline button label when user is not attending or on waiting list.',
                  defaultMessage: 'Decline',
                })
            }
          </Button>
        ) }
        { !requestHidden && (
          <Button variant="primary" { ...requestButtonProps }>
            {
              personalStatus.status === 'full'
                ? intl.formatMessage({
                  id: 'content.meeting.joinWaitingList',
                  description: 'Meeting attend button label when full.',
                  defaultMessage: 'Join waiting list',
                })
                : intl.formatMessage({
                  id: 'content.meeting.attend',
                  description: 'Meeting attend button label when not full.',
                  defaultMessage: 'Attend',
                })
            }
          </Button>
        ) }
      </BroadcastContentFooter>
    </>
  );
};
