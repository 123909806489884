import { useIntl } from 'react-intl';
import { useMemo } from 'react';
import { LocalisedString } from 'op-storybook/lib/model/LocalisedString/LocalisedString';

import { PersonStatus } from '../../Models';

export const useLocalisedStatuses = (): LocalisedString<PersonStatus>[] => {
  const intl = useIntl();
  return useMemo(
    () => [
      {
        id: 'active',
        localisation: intl.formatMessage({
          id: 'status.active',
          defaultMessage: 'Active',
        }),
      },
      {
        id: 'inactive',
        localisation: intl.formatMessage({
          id: 'status.inactive',
          defaultMessage: 'Inactive',
        }),
      },
      {
        id: 'pending',
        localisation: intl.formatMessage({
          id: 'status.pending',
          defaultMessage: 'Invited',
        }),
      },
      {
        id: 'added',
        localisation: intl.formatMessage({
          id: 'status.added',
          defaultMessage: 'Not invited',
        }),
      },
      {
        id: 'frozen',
        localisation: intl.formatMessage({
          id: 'status.frozen',
          defaultMessage: 'Frozen',
        }),
      },
      {
        id: 'deleted',
        localisation: intl.formatMessage({
          id: 'status.deleted',
          defaultMessage: 'Deleted',
        }),
      },
    ],
    [intl],
  );
}
