import { FC } from 'react';

import { LiveComponentProps } from '../../../../Content/Model';
import { Meeting } from '../../../../Broadcasts/Service';
import { AcceptResponse, DeclineResponse } from '../../../Model';
import { BroadcastContentContext } from '../../../../Broadcasts/Model';
import { LiveEventContent } from '..';
import { MeetingBroadcastContent } from '../../../../Content/Component';

export const LiveMeetingContent: FC<LiveComponentProps<Meeting, AcceptResponse | DeclineResponse, BroadcastContentContext>> = ({
  card,
  onChange,
  response,
  context,
  onResponseChange,
  onResponseClear,
  onReloadRequired,
  onValidationChange,
}) => (
  <LiveEventContent
    card={ card }
    response={ response }
    onChange={ onChange }
    context={ context }
    onResponseChange={ onResponseChange }
    onResponseClear={ onResponseClear }
    onReloadRequired={ onReloadRequired }
    PresentationComponent={ MeetingBroadcastContent }
    respondEndpointBroadcastType="meetings"
    nudge={ context.nudge }
    onValidationChange={ onValidationChange }
  />
);
