import { Audience } from '../../Model';

export class AudienceProvider {
  public static everyone = (): Audience => ({
    segments: [
      {
        conditions: [{
          type: 'everyone',
          not: false,
        }]
      }
    ]
  })
}
