import { FunctionComponent } from 'react';
import styled from '@emotion/styled';

import {
  StyledTableProps,
  StyledTable,
  StyledTableBody,
  StyledTableCell,
  StyledTableCellProps,
  StyledTableContainer,
  StyledTableHead,
  StyledTableRow,
  StyledTableRowProps
} from './styles';

export const Table: FunctionComponent<StyledTableProps> = ({ children, ...props }) => (
  <StyledTable {...props}>{children}</StyledTable>
);

export const TableBody: FunctionComponent = ({ children, ...props }) => (
  <StyledTableBody {...props}>{children}</StyledTableBody>
);

export const TableCell: FunctionComponent<StyledTableCellProps> = ({ children, ...props }) => (
  <StyledTableCell {...props}>
    {
      props.ellipsisOverflow && typeof children === 'string'
      ? children.replace(/ /g, '\u00a0')
      : children
    }
  </StyledTableCell>
);

export const RightAlignedCell = styled(TableCell)`
  display: flex;
  justify-content: flex-end;
`;

export const CenteredCell = styled(TableCell)`
  text-align: center;
`;

export const TableContainer: FunctionComponent = ({ children, ...props }) => (
  <StyledTableContainer {...props}>{children}</StyledTableContainer>
);

export const TableHead: FunctionComponent = ({ children, ...props }) => (
  <StyledTableHead {...props}>{children}</StyledTableHead>
);

export const TableRow: FunctionComponent<StyledTableRowProps> = ({ children, ...props }) => (
  <StyledTableRow {...props}>{children}</StyledTableRow>
);
