import { ChangeEventHandler, FC, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { TextField } from '@mui/material';
import {
  ValidationErrorHandlerFactory
} from 'op-storybook/lib/utility/ValidationErrorHandlerFactory/ValidationErrorHandlerFactory';
import {
  useLocalisedValidationErrors
} from 'op-storybook/lib/hooks/useLocalisedValidationHandlers/useLocalisedValidationHandlers';

import { ValidationTree } from '../../../../Common/Model';
import { useDescendantErrors, useValidationErrorHandlers } from '../../../../Common/Hook';
import { ValidationErrorIdentifier } from '../../../../Common/Utility/ValidationErrorIdentifier';
import { DraftNumberResponse } from '../../../Service';
import { FormResponseErrors } from '../..';

type Props = {
  value: number | undefined;
};

type ReadOnlyProps = Props & {
  readOnly: true;
  onChange?: never;
  validation?: never;
  displayValidation?: false;
};

type EditableProps = Props & {
  readOnly?: false;
  onChange: (value: number | undefined) => void;
  validation?: ValidationTree<DraftNumberResponse>;
  displayValidation?: boolean;
};

export const NumberRequest: FC<ReadOnlyProps | EditableProps> = ({
  value,
  onChange,
  validation,
  displayValidation,
  readOnly,
}) => {
  const intl = useIntl();
  const errors = useDescendantErrors(validation);
  const localisedErrors = useLocalisedValidationErrors(
    useValidationErrorHandlers(
      intl.formatMessage({
        description: 'Form number request input name used in error messages.',
        defaultMessage: 'your response',
      }),
      undefined,
      undefined,
      [
        ValidationErrorHandlerFactory.createHandler(
          ValidationErrorIdentifier.isTooSmallError,
          error => intl.formatMessage({
            description: 'Error when number request response in form is under min value.',
            defaultMessage: 'Must be a value no less than { min }.',
          }, { min: error.metadata.min }),
        ),
        ValidationErrorHandlerFactory.createHandler(
          ValidationErrorIdentifier.isTooLargeError,
          error => intl.formatMessage({
            description: 'Error when number request response in form exceeds max value.',
            defaultMessage: 'Must be a value no greater than { max }.',
          }, { max: error.metadata.max }),
        ),
      ],
    ),
    errors,
  );
  const inputPlaceholder = useMemo(() => intl.formatMessage({
    description: 'Form text request placeholder.',
    defaultMessage: 'Type your answer here…',
  }), [intl]);
  const [localValue, setLocalValue] = useState<string>(value !== undefined ? `${ value }` : '');

  const whenChanged: ChangeEventHandler<HTMLInputElement> = useCallback(event => {
    if (readOnly) {
      return;
    }

    onChange(
      event.currentTarget.value
        ? isNaN(+event.currentTarget.value)
          ? value
          : +event.currentTarget.value
        : undefined
    );

    setLocalValue(event.currentTarget.value);
  }, [readOnly, onChange, value]);

  const whenBlurred = useCallback(() => {
    setLocalValue(value !== undefined ? `${ value }` : '');
  }, [value]);

  return (
    <div>
      <TextField
        color="secondary"
        error={ displayValidation && !!errors.length }
        type="text"
        disabled={ readOnly }
        placeholder={ inputPlaceholder }
        value={ localValue }
        onChange={ whenChanged }
        onBlur={ whenBlurred }
        fullWidth
      />
      { displayValidation && <FormResponseErrors localisedErrors={ localisedErrors }/> }
    </div>
  );
};
