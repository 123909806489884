import { FetchResponse, useGet } from '../../Hooks';
import { Paginated } from '../../Models';
import { Invitation } from '../Model';

export type InvitationStatus = 'complete' | 'pending' | 'queued' | 'cancelled';

export type InvitationAndRecipientCount = {
  invitation: Invitation;
  recipientCount: number;
};

export type FetchInvitationsParams = {
  ids?: string;
  statuses?: string;
  createdByIds?: string;
  noSms?: 0 | 1;
  noEmail?: 0 | 1;
  pageNum: number;
  sort?: string;
}

export const useFetchInvitations = (params: FetchInvitationsParams): FetchResponse<Paginated<'invitations', InvitationAndRecipientCount>> => useGet('/invitations', params);
