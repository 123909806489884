import { PropsWithChildren } from 'react';
import * as React from 'react';

import { Table, TableBody, TableHead } from './Table';
import { StyledTableContainer } from './styles';

interface Props<RowDataType> {
  rowRender: (rowData: RowDataType, index: number) => React.ReactNode;
  rows: RowDataType[];
  headerRow: React.ReactNode;
}

export const SimpleTable = <RowDataType,>({
  rowRender,
  rows,
  headerRow,
}: PropsWithChildren<Props<RowDataType>>): JSX.Element => {
  return (
    <StyledTableContainer>
      <Table>
        <TableHead>
          {headerRow}
        </TableHead>
        <TableBody>
          {rows ? rows.map(rowRender) : []}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};
