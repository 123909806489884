import { Form as RadixForm } from 'op-storybook/lib/components/Form/Form';
import { useCallback, useMemo, useRef } from 'react';

import { FormComponent } from '../../../Model/BroadcastBlockDefinition';
import { FormBlock } from '../../../Service/Blocks/FormBlockDefinition';
import { Form } from '../../../../../Forms/Model';
import { ArrayHelper } from '../../../../../Common/Utility';
import { FormComboBox } from './FormCombobox';

export const FormBlockForm: FormComponent<FormBlock> = ({
  block,
  onChange,
  attachments,
  onAttachmentsChange,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const form = useMemo(() => {
    if (!block.attributes.formId) {
      return null;
    }

    return attachments.forms.find(form => form.id === block.attributes.formId) || null;
  }, [attachments.forms, block.attributes.formId]);

  const whenFormChanged = useCallback((newForm: Form) => {
    onChange({
      ...block,
      attributes: {
        ...block.attributes,
        formId: newForm.id,
      },
    });

    onAttachmentsChange({
      ...attachments,
      forms: ArrayHelper.findAndReplace(attachments.forms, form => form.id === newForm.id, newForm, true),
    })
  }, [attachments, block, onAttachmentsChange, onChange]);

  return (
    <div
      css={ theme => ({
        padding: `0 ${ theme.new.spacing[2] }`,
      }) }
      ref={ containerRef }
    >
      <RadixForm>
        <FormComboBox
          value={ form }
          onChange={ whenFormChanged }
          containerRef={ containerRef }
        />
      </RadixForm>
    </div>
  );
};
