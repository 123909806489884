import styled from '@emotion/styled';

export const StyledButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: ${ props => props.theme.spacing(1) };
`;

export const StyledBroadcastContentFooter = styled.div`
  background-color: ${ props => props.theme.palette.background.paper };
  width: 100%;
  padding: ${ props => props.theme.spacing(1) };
  position: absolute;
  bottom: 0;
  left: 0;
  height: 72px;
  display: flex;
  flex-direction: column;
  gap: ${ props => props.theme.new.spacing[1] };
  justify-content: center;

  ${ props => props.theme.breakpoints.down('md') } {
    position: fixed;
  }
`;

export const StyledCardActionContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: ${ props => props.theme.spacing(1) };
    
  button.MuiButtonBase-root {
    padding: ${ props => props.theme.new.spacing[2] } ${ props => props.theme.new.spacing[3] };
  }
`;

export const StyledPreviousIcon = styled.span`
  transform: rotate(90deg);
`;

export const StyledNextIcon = styled.span`
  transform: rotate(-90deg);
`;

export const StyledNavigationButtonContainer = styled.div<{ notVisible: boolean }>`
  ${ props => props.notVisible && `
    opacity: 0;
    pointer-events: none;
  ` }
`;
