import { FormattedMessage } from 'react-intl';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';

import { EditorContent } from '../../../../Content/Model';
import { DraftMeeting } from '../../../Service';
import { CardTitle, ContentWithType, EventDateAndLocation, ScrollableContent } from '..';
import { Flex, VerticallySpaced } from '../../../../Common/Component';
import { StyledSpaces } from './style';

type Props<E extends EditorContent<DraftMeeting<string>>> = {
  editorContent: E;
  localisedContentType: string;
};

export const MeetingAndTrainingPreview = <E extends EditorContent<DraftMeeting<string>>>({
  editorContent,
  localisedContentType,
}: Props<E>): JSX.Element => (
  <ScrollableContent>
    <ContentWithType
      localisedContentType={ localisedContentType }
    >
      <VerticallySpaced gap={ 2 }>
        <CardTitle>{ editorContent.card.content.text }</CardTitle>
        <EventDateAndLocation event={ editorContent.card.content }/>
        <StyledSpaces>
          <FormattedMessage
            id="broadcasts.content.preview.meetingAndTraining.remainingSpaces"
            description="Message with remaining spaces."
            defaultMessage="Quick! There's only { spaces, plural, one { # space } other { # spaces } } left. ⚡️"
            values={ { spaces: editorContent.card.content.max_attendees } }
          />
        </StyledSpaces>
        <VerticallySpaced gap={ 0 } align="center">
          <Flex gap={ 2 }>
            <Button>
              <FormattedMessage
                id="broadcasts.content.preview.meetingAndTraining.decline"
                description="Decline button label on meeting and training cards."
                defaultMessage="Decline"
              />
            </Button>
            <Button variant="primary">
              <FormattedMessage
                id="broadcasts.content.preview.meetingAndTraining.attend"
                description="Attend button label on meeting and training cards."
                defaultMessage="Attend"
              />
            </Button>
          </Flex>
        </VerticallySpaced>
      </VerticallySpaced>
    </ContentWithType>
  </ScrollableContent>
);
