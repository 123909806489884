import { FC, useCallback, useEffect, useState } from 'react';
import { captureException, ErrorBoundary } from '@sentry/react';
import { Scope } from '@sentry/browser';
import {
  CenteredGenericEmptyMessage,
  CenteredGenericErrorMessage,
  CenteredGenericLoadingMessage
} from '@ourpeople/shared/Core/Component/Content';
import { DebouncedSearchInput } from '@ourpeople/shared/Core/Component/Input/DebouncedSearchInput/DebouncedSearchInput';

import { FetchImageAssetsParams, useFetchImageAssets } from '../../Hook';
import { Pagination } from '../../../Components';
import { StyledImageAssetList, StyledImageAssetPicker, } from './style';
import { ImageAsset as ImageAssetModel } from '../../Hook/useFetchImages';
import { ImageAsset } from '../ImageAsset/ImageAsset';

type Props = {
  onImageAssetSelected: (imageAsset: ImageAssetModel) => void;
};

export const ImageAssetPicker: FC<Props> = ({
  onImageAssetSelected,
}) => {
  const [selectedAsset, setSelectedAsset] = useState<ImageAssetModel | undefined>();
  const [fetchImageAssetsParams, setFetchImageAssetsParams] = useState<FetchImageAssetsParams>({ pageNum: 1 });
  const [fetchImageAssetsResult, , reloadImageAssets] = useFetchImageAssets(fetchImageAssetsParams);
  const imageAssets = fetchImageAssetsResult?.content?.images || [];
  const pagination = fetchImageAssetsResult?.content?.pagination;
  const [exceptionCaptured, setExceptionCaptured] = useState<boolean>(false);

  const whenSearchChanged = useCallback(
    (search: string) => (
      setFetchImageAssetsParams(
        ({ search: previousSearch, ...fetchImageAssetsParams }) => ({
          ...fetchImageAssetsParams,
          pageNum: 1,
          ...(search ? { search } : {}),
        })
      )),
    [],
  );

  useEffect(() => {
    if (!fetchImageAssetsResult || !fetchImageAssetsResult.error) {
      return;
    }

    setExceptionCaptured(false);
  }, [fetchImageAssetsResult, fetchImageAssetsResult?.error]);

  useEffect(() => {
    if (exceptionCaptured || !fetchImageAssetsResult || !fetchImageAssetsResult.error) {
      return;
    }

    setExceptionCaptured(true);
    captureException(fetchImageAssetsResult?.error, {
      tags: {
        searchTerm: fetchImageAssetsParams.search,
        pageNum: fetchImageAssetsParams.pageNum,
      },
    });
  }, [exceptionCaptured, fetchImageAssetsParams, fetchImageAssetsResult]);

  const whenPageNumChanged = useCallback(
    (pageNum: number) => (
      setFetchImageAssetsParams(
        fetchImageAssetsParams => ({
          ...fetchImageAssetsParams,
          pageNum,
        })
      )),
    [],
  );

  useEffect(() => {
    if (!selectedAsset) {
      return;
    }

    onImageAssetSelected(selectedAsset);
  }, [onImageAssetSelected, selectedAsset]);

  return (
    <StyledImageAssetPicker>
      <DebouncedSearchInput
        value={ fetchImageAssetsParams.search || '' }
        onChange={ whenSearchChanged }
      />
      {
        pagination
          ? (
            <>
              {
                imageAssets.length
                  ? (
                    <StyledImageAssetList>
                      { imageAssets.map(({ image: imageAsset }) => (
                        <ErrorBoundary
                          key={ imageAsset.id }
                          beforeCapture={ (scope: Scope) => {
                            scope.setTag('image', JSON.stringify(imageAsset));
                          } }
                        >
                          <ImageAsset
                            imageAsset={ imageAsset }
                            onImageAssetSelected={ () => setSelectedAsset(imageAsset) }
                            selected={ selectedAsset?.id === imageAsset.id }
                          />
                        </ErrorBoundary>
                      )) }
                    </StyledImageAssetList>
                  )
                  : <CenteredGenericEmptyMessage/>
              }
              <Pagination
                pagination={ pagination }
                pageNum={ fetchImageAssetsParams.pageNum || 1 }
                onPageChanged={ whenPageNumChanged }
                noPadding={ true }
              />
            </>
          )
          : fetchImageAssetsResult?.error
            ? <CenteredGenericErrorMessage onRetryClicked={ reloadImageAssets }/>
            : <CenteredGenericLoadingMessage/>
      }
    </StyledImageAssetPicker>
  );
};
