import { IntlShape } from 'react-intl';
import { createElement, ReactNode } from 'react';

import { Upload } from '../../../../Types';
import { DraftContent, EditorContent, SingleContentCard } from '../../../../Content/Model';
import { ImageContentEditor, ImageContentPreview } from '../../../Component';
import ImageIcon from '../../../../Assets/img/icons/monochrome/content/image.svg';
import { RichTextParser, UniqueIdGenerator } from '../../../../Common/Utility';
import { CardTransformer } from '../../../../Content/Utility';
import { ImageContentValidator } from '../../Validators';
import { ViewResponse } from '../../../../Inbox/Model';
import { LiveImageContent } from '../../../../Inbox/Component';
import { BroadcastContent, BroadcastContentDefinition } from '../../../Model';
import { ContentTransformer } from '../../../Utility';
import { EnvironmentSettings } from '../../../../Models';
import { DraftLink } from '../../../Component/Content/LinkEditor/LinkEditor';
import { Form } from '../../../../Forms/Model';
import { ContentLink } from '../../../Model/ContentLink';
import { MinimalFileEntry } from '../../../../Files/Model/FileEntry';
import { SafeRichTextContent } from '../../../../Common/Component/SafeRichTextContent/SafeRichTextContent';

export type ImageCaptionPosition =
  'bottom_left'
  | 'bottom_middle'
  | 'bottom_right'
  | 'top_left'
  | 'top_middle'
  | 'top_right';

export type ImageCaption = {
  backgroundColour: string;
  position: ImageCaptionPosition;
  text: string;
  textColour: string;
};

export type ImageFit = 'cover' | 'contain';
export type ImageHeight = 'full' | 'majority' | 'half';

export type DraftImageContent = DraftContent & DraftLink & {
  type: 'styled_image';
  backgroundColour: string;
  caption: ImageCaption | null;
  downloadable: boolean;
  imageFit: ImageFit;
  imageHeight: ImageHeight;
  imageId: string;
  text: string | null;
};

export type StyledImageContent = BroadcastContent & {
  type: 'styled_image';
  upload: Upload | null;
  backgroundColour: string;
  caption: ImageCaption | null;
  downloadable: boolean;
  imageFit: ImageFit;
  imageHeight: ImageHeight;
  text: string | null;
  link: ContentLink | null;
};

export type LegacyImageContent = BroadcastContent & {
  type: 'imagecontent';
  text?: string;
  image: Upload;
};

export type ImageContent = StyledImageContent | LegacyImageContent;

export type ImageEditorContent = EditorContent<DraftImageContent> & {
  fileEntry: MinimalFileEntry | null;
  form: Form | null;
  upload: Upload | null;
};

export class ImageContentDefinition implements BroadcastContentDefinition<ImageContent, ImageEditorContent, ViewResponse> {
  public readonly id = 'image';
  public readonly availableToCreate = true;
  public readonly new = false;
  public readonly richText = true;
  public readonly contentTypes = ['styledimagecontent', 'imagecontent', 'styled_image'];
  public readonly IconComponent = ImageIcon;
  public readonly PreviewComponent = ImageContentPreview;
  public readonly LiveComponent = LiveImageContent;
  public readonly EditorComponent = ImageContentEditor;
  public readonly SubmittedComponent = (): null => null;
  public readonly categoryId = 'content';
  public validate = ImageContentValidator.validate;

  public definesContentType = (type: string): boolean => (
    this.contentTypes.concat('styled_image').includes(type)
  );

  public getContentTitle = (intl: IntlShape, content: ImageContent): ReactNode => (
    content.type === 'styled_image'
      ? content.caption?.text || (
        content.text
          ? createElement(SafeRichTextContent, { placeholderBehaviour: 'badge', text: RichTextParser.getFirstNonEmptyNodeTextContent(content.text, true) || '' })
          : this.getDefaultTitle(intl)
      )
      : content.text || this.getDefaultTitle(intl)
  );

  public getLocalisedType = (intl: IntlShape): string => (
    intl.formatMessage({
      id: 'broadcasts.content.image.type',
      description: 'Localised name for image content type.',
      defaultMessage: 'Image',
    })
  );

  public getDraftContentTitle = (intl: IntlShape, draftContent: DraftImageContent): string => (
    draftContent.caption?.text || RichTextParser.getFirstNonEmptyNodeTextContent(draftContent.text || '') || this.getDefaultTitle(intl)
  );

  public hasRequiredFeatureFlags = (environmentSettings: EnvironmentSettings): boolean => (
    !!environmentSettings.imageTypeEnabled
  );

  public createEditorContent = (): ImageEditorContent => ({
    id: UniqueIdGenerator.generate(),
    fileEntry: null,
    form: null,
    upload: null,
    card: {
      content: {
        type: 'styled_image',
        backgroundColour: '#FFFFFF',
        caption: null,
        imageFit: 'cover',
        downloadable: true,
        imageHeight: 'full',
        imageId: '',
        text: null,
      },
      mandatory: false,
      image: null,
    },
  });

  public transformCard = (card: SingleContentCard<ImageContent>): ImageEditorContent => {
    const content = card.content.type === 'styled_image'
      ? card.content
      : ContentTransformer.legacyImageToStyledImage(card.content);

    return {
      id: card.id,
      fileEntry: content.link?.fileEntry || null,
      form: null,
      upload: content.upload,
      card: CardTransformer.transformCard(card, {
        type: 'styled_image',
        backgroundColour: content.backgroundColour,
        caption: content.caption,
        imageFit: content.imageFit,
        downloadable: content.downloadable,
        imageHeight: content.imageHeight,
        imageId: content.upload?.id.toString() || '',
        text: content.text,
        ...(
          content.link
            ? {
              linkLabel: content.link.label,
              linkType: content.link.type,
              linkTarget: content.link.type === 'url'
                ? content.link.url
                : content.link.type === 'form'
                  ? content.link.formId
                  : content.link.fileEntry?.id,
            }
            : {}
        ),
      }),
    };
  };

  private getDefaultTitle = (intl: IntlShape): string => (
    intl.formatMessage({
      id: 'broadcasts.content.image.defaultTitle',
      description: 'Default title for image content.',
      defaultMessage: 'Untitled image card',
    })
  );
}

export const IMAGE_CAPTION_MIN_LENGTH = 1;
export const IMAGE_CAPTION_MAX_LENGTH = 90;
export const IMAGE_TEXT_MIN_LENGTH = 1;
export const IMAGE_TEXT_MAX_LENGTH = 700;
