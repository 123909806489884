import { FC, PropsWithChildren } from 'react';

import { StyledNavigationButton } from './style';

type Props = {
  onClick: () => void;
};

export const NavigationButton: FC<PropsWithChildren<Props>> = ({
  onClick,
  children,
}) => (
  <StyledNavigationButton onClick={ onClick }>
    { children }
  </StyledNavigationButton>
);
