import { useIntl } from 'react-intl';

export const useUneditableBroadcastMessage = (): string => {
  const intl = useIntl();

  return intl.formatMessage({
    description: 'Message used in errors and tooltips when a broadcast is not editable',
    defaultMessage: 'Delivered broadcasts can not be edited.',
  });
};
