import styled from '@emotion/styled';

export const StyledTitleInputRow = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  margin: 0 -${ props => props.theme.spacing(1) } 0 0;
  gap: ${ props => props.theme.spacing(1) };

  button {
    margin-top: ${ props => props.theme.spacing(1.5) };
  }
`;
