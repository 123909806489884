import { ValidationTree, Validator } from '../../../Common/Model';
import { Validate } from '../../../Common/Utility';
import {
  DraftTextRequest,
  TEXT_REQUEST_MAX_LENGTH_MAX,
  TEXT_REQUEST_MAX_LENGTH_MIN,
  TEXT_REQUEST_MIN_LENGTH_MAX,
  TEXT_REQUEST_MIN_LENGTH_MIN
} from '../../Model/ContentTypes';
import { RequestValidator } from '.';

export class TextRequestValidator {
  public static validateMinLength = (content: DraftTextRequest): ValidationTree<number> => ({
    errors: new Validator<number>([
      Validate.isNumeric,
      minLength => Validate.greaterThanEq(minLength, TEXT_REQUEST_MIN_LENGTH_MIN),
      minLength => Validate.lessThanEq(minLength, Math.min(content.maxLength, TEXT_REQUEST_MIN_LENGTH_MAX)),
    ]).validate(content.minLength),
    children: {},
  });

  public static validateMaxLength = (content: DraftTextRequest): ValidationTree<number> => ({
    errors: new Validator<number>([
      Validate.isNumeric,
      maxLength => Validate.greaterThanEq(maxLength, Math.max(content.minLength, TEXT_REQUEST_MAX_LENGTH_MIN)),
      maxLength => Validate.lessThanEq(maxLength, TEXT_REQUEST_MAX_LENGTH_MAX),
    ]).validate(content.maxLength),
    children: {},
  });

  public static validate = (content: DraftTextRequest): ValidationTree<DraftTextRequest> => ({
    errors: [
      ...RequestValidator.validate(content).errors,
    ],
    children: {
      ...RequestValidator.validate(content).children,
      minLength: TextRequestValidator.validateMinLength(content),
      maxLength: TextRequestValidator.validateMaxLength(content),
    }
  });
}
