import { FunctionComponent } from 'react';

import { ContentType } from '../../../Models';
import AudioIcon from '../../../Assets/img/icons/monochrome/content/audio.svg';
import ChecklistIcon from '../../../Assets/img/icons/monochrome/content/checklist.svg';
import ChoiceIcon from '../../../Assets/img/icons/monochrome/content/choice.svg';
import CommentIcon from '../../../Assets/img/icons/monochrome/content/comment.svg';
import CoverIcon from '../../../Assets/img/icons/monochrome/content/cover.svg';
import FileIcon from '../../../Assets/img/icons/monochrome/content/file.svg';
import ImageIcon from '../../../Assets/img/icons/monochrome/content/image.svg';
import MeetingIcon from '../../../Assets/img/icons/monochrome/content/meeting.svg';
import QuizIcon from '../../../Assets/img/icons/monochrome/content/quiz.svg';
import RatingIcon from '../../../Assets/img/icons/monochrome/content/rating.svg';
import RevealIcon from '../../../Assets/img/icons/monochrome/content/reveal.svg';
import SignatureIcon from '../../../Assets/img/icons/monochrome/content/sign.svg';
import StackIcon from '../../../Assets/img/icons/monochrome/content/stack.svg';
import TextIcon from '../../../Assets/img/icons/monochrome/content/text.svg';
import TrainingIcon from '../../../Assets/img/icons/monochrome/content/training.svg';
import UploadImageIcon from '../../../Assets/img/icons/monochrome/content/upload-image.svg';
import VideoIcon from '../../../Assets/img/icons/monochrome/content/video.svg';
import AlertIcon from '../../../Assets/img/icons/monochrome/content/alert.svg';
import { StyledContentIcon } from './styles';

interface ContentIconProps {
  contentType: ContentType;
  size?: string;
}

export const ContentIcon: FunctionComponent<ContentIconProps> = ({contentType, size}) => {
  let icon;
  switch (contentType) {
    case 'audio':
      icon = <AudioIcon />;
      break;
    case 'multiplechoice':
      icon = <ChecklistIcon />;
      break;
    case 'singlechoice':
      icon = <ChoiceIcon />;
      break;
    case 'comment':
      icon = <CommentIcon />;
      break;
    case 'cover':
      icon = <CoverIcon />;
      break;
    case 'file':
      icon = <FileIcon />;
      break;
    case 'image':
    case 'styled_image':
      icon = <ImageIcon />;
      break;
    case 'meeting':
      icon = <MeetingIcon />;
      break;
    case 'quiz':
      icon = <QuizIcon />;
      break;
    case 'rating':
      icon = <RatingIcon />;
      break;
    case 'reveal':
      icon = <RevealIcon />;
      break;
    case 'sign':
      icon = <SignatureIcon />;
      break;
    case 'stack':
      icon = <StackIcon />;
      break;
    case 'text':
      icon = <TextIcon />;
      break;
    case 'training':
      icon = <TrainingIcon />;
      break;
    case 'upload_image':
      icon = <UploadImageIcon />;
      break;
    case 'video':
      icon = <VideoIcon />;
      break;
    default:
      icon = <AlertIcon />;
      break;
  }
  return icon
    ? (
      <StyledContentIcon size={size}>{icon}</StyledContentIcon>
    )
    : null;
}
