import { DraftFormRequest, FormRequest } from '../Content';

export const NUMBER_REQUEST_MIN_MIN = -999999;
export const NUMBER_REQUEST_MIN_MAX = 999999;
export const NUMBER_REQUEST_MIN_DEFAULT = 0;
export const NUMBER_REQUEST_MAX_MIN = -999999;
export const NUMBER_REQUEST_MAX_MAX = 999999;
export const NUMBER_REQUEST_MAX_DEFAULT = 100

export type NumberRequest = FormRequest & {
  type: 'numberRequest';
  precision: number | null;
  step: number | null;
  min: number;
  max: number;
};

export type DraftNumberRequest = DraftFormRequest & {
  type: 'numberRequest';
  precision: number | null;
  step: number | null;
  min: number;
  max: number;
};
