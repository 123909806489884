import { FC, ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, ButtonProps } from '@ourpeople/shared/Core/Component/Input/Button/Button';

import { EventStatusAndDetails } from '../Event';
import { ScheduledDate } from '../../../../Models/ScheduledDate';
import { StyledComment } from './style';
import { BroadcastContentFooter, StackNavigationProps } from '..';
import { CoverStatus } from '../../../../Broadcasts/Service';
import { BroadcastNudge } from '../../../../Broadcasts/Model';

type Props = {
  title: string;
  startAt: ScheduledDate;
  endAt: ScheduledDate;
  location: string;
  comment?: string;
  cancelled?: boolean;
  showTimezone?: boolean;
  personalStatus: CoverStatus;
  navigationProps: StackNavigationProps;
  requestButtonProps: Omit<ButtonProps, 'variant' | 'children'>;
  requestHidden?: boolean;
  declineButtonProps: Omit<ButtonProps, 'variant' | 'children'>;
  declineHidden?: boolean;
  nudge?: BroadcastNudge;
  backButton: ReactNode | undefined;
};

export const CoverBroadcastContent: FC<Props> = ({
  title,
  startAt,
  endAt,
  location,
  comment,
  showTimezone = false,
  personalStatus,
  navigationProps,
  requestButtonProps,
  requestHidden = false,
  declineButtonProps,
  declineHidden = false,
  nudge,
  backButton,
}) => {
  const intl = useIntl();

  return (
    <>
      <EventStatusAndDetails
        startAt={ startAt }
        endAt={ endAt }
        location={ location }
        title={ title }
        personalStatus={ personalStatus }
        type={ intl.formatMessage({
          id: 'content.cover.type',
          description: 'Cover type in inbox and editor preview.',
          defaultMessage: 'Cover',
        }) }
        showTimezone={ showTimezone }
        nudge={ nudge }
        backButton={ backButton }
      >
        { comment && <StyledComment>{ comment }</StyledComment> }
      </EventStatusAndDetails>
      <BroadcastContentFooter { ...navigationProps }>
        { !declineHidden && (
          <Button variant="secondary" { ...declineButtonProps }>
            <FormattedMessage
              id="content.cover.decline"
              description="Cover decline button label"
              defaultMessage="Decline"
            />
          </Button>
        ) }
        { !requestHidden && (
          <Button variant="primary" { ...requestButtonProps }>
            <FormattedMessage
              id="content.cover.request"
              description="Cover request button label"
              defaultMessage="Request"
            />
          </Button>
        ) }
      </BroadcastContentFooter>
    </>
  );
};
