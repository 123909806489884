import { ComponentProps, default as React, FC, useMemo } from 'react';

import { ContentPreviewProps } from '../../../../Content/Model';
import { ImageEditorContent } from '../../../Service';
import { StyledButtonUnderlay } from './style';
import { ImageContentWithoutFooter } from '../../../../Content/Component';
import { LinkContent } from '../../../../Content/Component/BroadcastContent/LinkContent/LinkContent';

export const ImageContentPreview: FC<ContentPreviewProps<ImageEditorContent>> = ({
  editorContent,
  onReady,
}) => {
  const { card } = useMemo(() => editorContent, [editorContent]);
  const linkProps = useMemo<ComponentProps<typeof LinkContent> | null>(() => (
    editorContent.card.content.linkTarget
      ? {
        onFileLinkClick: () => { /* Do nothing */ },
        fileEntry: editorContent.fileEntry || undefined,
        linkType: card.content.linkType,
        linkLabel: card.content.linkLabel,
        linkTarget: card.content.linkTarget,
        disableFilePath: true,
      }
      : null
  ), [card.content.linkLabel, card.content.linkTarget, card.content.linkType, editorContent.card.content.linkTarget, editorContent.fileEntry]);

  return (
    <>
      <div/>
      <ImageContentWithoutFooter
        imageHeight={ editorContent.card.content.imageHeight }
        backgroundColour={ editorContent.card.content.backgroundColour }
        imageFit={ editorContent.card.content.imageFit }
        image={ editorContent.upload || undefined }
        caption={ editorContent.card.content.caption || undefined }
        text={ editorContent.card.content.text || undefined }
        onReady={ onReady }
        linkProps={ linkProps }
        backButton={ null }
      />
      <StyledButtonUnderlay fullHeight={ editorContent.card.content.imageHeight === 'full' }/>
    </>
  );
};
