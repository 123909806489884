import { Popper } from '@mui/material';
import styled from '@emotion/styled';

export const StyledPopper = styled(Popper)`
  .MuiPaper-root {
    background: transparent;
    overflow: visible;
    box-shadow: none;
  }
`;
