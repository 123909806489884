import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { AutocompleteCommonProps, AutocompleteFetchRequest, AutocompleteSelectionChanged } from '@ourpeople/shared/Core/Component/Input/Autocomplete/Autocomplete';
import { LocalisedString } from 'op-storybook/lib/model/LocalisedString/LocalisedString';

import { DebouncedAutocomplete } from '../../../Common/Component';

interface EventTypeAutocompleteProps extends AutocompleteCommonProps {
  selectedIds: string[];
  allowedIds?: string[];
  onSelectionChanged: AutocompleteSelectionChanged<LocalisedString>;
}

export const EventTypeAutocomplete = ({
  selectedIds,
  allowedIds,
  onSelectionChanged,
  ...props
}: EventTypeAutocompleteProps): JSX.Element => {
  const intl = useIntl();
  const availableTypes: LocalisedString[] = useMemo(
    () => [
      {
        id: 'meetingcontent',
        localisation: intl.formatMessage({
          id: 'eventTypeAutocomplete.typeMeeting',
          defaultMessage: 'Meeting',
        }),
      },
      {
        id: 'trainingcontent',
        localisation: intl.formatMessage({
          id: 'eventTypeAutocomplete.typeTraining',
          defaultMessage: 'Training',
        }),
      },
    ],
    [intl],
  );

  const whenFetch: AutocompleteFetchRequest<LocalisedString> = () => {
    return Promise.resolve({
      options: availableTypes
        .filter(type => !allowedIds || allowedIds.indexOf(type.id) > -1),
      pageCount: 1,
    });
  }

  return (
    <DebouncedAutocomplete<LocalisedString>
      { ...props }
      getOptionLabel={ type => type.localisation }
      fetchOptions={ useCallback(whenFetch, [allowedIds, availableTypes]) }
      selectedIds={ selectedIds }
      onSelectionChanged={ onSelectionChanged }
      label={
        props.label !== undefined
          ? props.label
          : intl.formatMessage({
              id: 'eventTypeAutocomplete.label',
              defaultMessage: 'Select {limit, plural, one {type} other {types}}'
            },
            {
              limit: props.multiple ? Infinity : 1,
            })
      }
      placeholder={
        props.placeholder ||
        intl.formatMessage(
          {
            id: 'eventTypeAutocomplete.placeholder',
            defaultMessage: 'Search for {limit, plural, one {type} other {types}}'
          },
          {
            limit: props.multiple ? Infinity : 1,
          }
        )
      }
    />
  );
};
