import { forwardRef, JSX, JSXElementConstructor, MouseEventHandler, ReactNode, Ref, useMemo } from 'react';
import { rgba } from 'polished';

import { PaletteKey } from '../../../lib/types/Theme/Theme';
import { StyleBuilder } from '../../../lib/model/StyleBuilder/StyleBuilder';
import { ElementOrComponent } from '../../../lib/types/ElementOrComponent';
import { OverrideComponentProps } from '../../../lib/types/OverrideComponentProps';

export type ButtonBaseVariant =
  'filled-light'
  | 'filled-dark'
  | 'secondary-grey'
  | 'secondary-colour'
  | 'tertiary-light'
  | 'tertiary-dark';

type BaseProps<C extends ElementOrComponent = 'button'> = {
  colour?: PaletteKey;
  fillParent?: boolean;
  variant?: ButtonBaseVariant;
  active?: boolean;
  padding?: 'none' | 'standard' | 'uniform';
  ref?: Ref<HTMLElement>;
  disabled?: boolean;
  size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  onClick?: MouseEventHandler<HTMLButtonElement>;
  component?: C;
  className?: string;
  children?: ReactNode;
}

export type ButtonBaseProps<C extends ElementOrComponent = 'button'> = OverrideComponentProps<C, BaseProps<C>>;

// Type becomes too complex for the compiler when replacing this with an unknown
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ButtonBase = forwardRef(<C extends ElementOrComponent = 'button'>({
  colour = 'primary',
  variant = 'filled-light',
  fillParent = false,
  active = false,
  padding = 'standard',
  component,
  disabled = false,
  size = 'lg',
  className,
  ...componentProps
}: ButtonBaseProps<C>, ref: Ref<HTMLButtonElement>) => {
  const sharedProps = useMemo(() => ({
    colour,
    variant,
    fillParent,
    active,
    padding,
    disabled,
    size,
  }), [active, colour, fillParent, padding, variant, disabled, size]);
  const RequiredComponent = component || 'button';
  const requiredComponentProps = useMemo(() => {
      const {
          active,
          fillParent,
          ...rest
      } = {...(componentProps || {}), ...sharedProps};
      return rest;
  }, [componentProps, sharedProps]);
  const styles = useMemo(() => buildStyles(sharedProps), [sharedProps]);

  return (
    <RequiredComponent
      { ...requiredComponentProps }
      className={ className }
      css={ styles.button }
      ref={ ref }
    />
  );
});

type StyleProps = {
  colour: PaletteKey;
  fillParent: boolean;
  variant: ButtonBaseVariant;
  active: boolean;
  padding: 'standard' | 'uniform' | 'none';
  size: ButtonBaseProps['size'];
  disabled: boolean;
};

const buildStyles: StyleBuilder<StyleProps> = props => {
  const verticalSpacing = props.size === 'sm'
    ? 1
    : props.size === 'md'
      ? 1
      : props.size === 'lg'
        ? 2
        : props.size === 'xl'
          ? 3
          : 4;
  const horizontalSpacing = props.padding === 'uniform'
    ? verticalSpacing
    : props.size === 'lg'
      ? 4
      : props.size === 'xl'
        ? 5
        : props.size === '2xl'
          ? 6
          : props.size === 'md'
            ? 3
            : 2;
  return {
    button: theme => ({
      fontSize: theme.new.spacing[4],
      borderRadius: theme.new.borderRadius.standard,
      textAlign: 'center',
      justifyContent: 'center',
      textDecoration: 'none',
      position: 'relative',
      padding: props.padding === 'none'
        ? '0'
        : `
          ${
            props.padding === 'uniform'
              ? theme.new.spacing[horizontalSpacing]
              : theme.new.spacing[verticalSpacing]
          } ${ theme.new.spacing[horizontalSpacing] }
        `,
      transition: 'box-shadow 0.2s, background-color 0.2s, border 0.2s, color 0.2s, padding 0.2s',
      width: props.fillParent ? '100%' : 'auto',
      display: 'flex',
      gap: theme.new.spacing[2],
      backgroundColor: 'transparent',
      border: 'none',
      ...props.padding === 'uniform'
        ? {
          justifyContent: 'center',
          alignItems: 'center',
        }
        : {},

      '&:focus-visible': {
        outline: 'none',
      },

      '&:hover': {
        textDecoration: 'none',
      },

      ...props.variant === 'secondary-grey'
        ? {
          backgroundColor: theme.new.basePalette.white.main,
          border: props.disabled
            ? theme.new.border.light
            : theme.new.border.standard,
          boxShadow: theme.new.shadow.xs,
          color: props.disabled
            ? theme.new.palette.grey[300].main
            : theme.new.palette.grey[700].main,
          ...props.disabled
            ? {}
            : {
              '&:focus-visible': {
                boxShadow: theme.new.shadow.focusRingGrey,
                outline: 'none',
              },

              '&:hover, &:active': {
                backgroundColor: theme.new.palette.grey[50].main,
              }
            },
        }
        : {},

      ...props.variant === 'secondary-colour'
        ? {
          backgroundColor: theme.new.basePalette.white.main,
          border: props.disabled
            ? theme.new.border.light
            : `1px solid ${ theme.new.palette[props.colour][300].main }`,
          boxShadow: theme.new.shadow.xs,
          color: props.disabled
            ? theme.new.palette.grey[300].main
            : theme.new.palette[props.colour][700].main,

          ...props.disabled
            ? {}
            : {
              '&:focus-visible': {
                boxShadow: `${ theme.new.shadow.focusRingNoColour } ${ theme.new.palette[props.colour][100].main }`,
                outline: 'none',
              },

              '&:hover, &:active': {
                backgroundColor: theme.new.palette[props.colour][50].main,
              }
            },
        }
        : {},

      ...props.variant === 'tertiary-light'
        ? {
          backgroundColor: 'transparent',
          color: props.disabled
            ? theme.new.palette.grey[300].main
            : theme.new.palette[props.colour][300].main,

          ...props.disabled
            ? {}
            : {
              '&:focus-visible': {
                boxShadow: `${ theme.new.shadow.focusRingNoColour } ${ rgba(theme.new.palette[props.colour][300].main, 0.2) }`,
                outline: 'none',
              },

              '&:hover, &:active': {
                backgroundColor: rgba(theme.new.palette[props.colour][300].main, 0.2),
              },
            },
        }
        : {},

      ...props.variant === 'tertiary-dark'
        ? {
          backgroundColor: 'transparent',
          color: props.disabled
            ? theme.new.palette.grey[300].main
            : theme.new.palette[props.colour][600].main,

          ...props.disabled
            ? {}
            : {
              '&:focus-visible': {
                boxShadow: `${ theme.new.shadow.focusRingNoColour } ${ rgba(theme.new.palette[props.colour][600].main, 0.1) }`,
                outline: 'none',
              },

              '&:hover, &:active': {
                backgroundColor: rgba(theme.new.palette[props.colour][600].main, 0.1),
              },
            },
        }
        : {},

      ...props.variant === 'filled-light'
        ? {
          backgroundColor: props.disabled
            ? theme.new.palette[props.colour][200].main
            : theme.new.palette[props.colour][600].main,
          color: theme.new.palette[props.colour][600].contrast,
          border: `1px solid ${
            props.disabled
              ? theme.new.palette[props.colour][200].main
              : theme.new.palette[props.colour][600].main
          }`,

          ...props.disabled
            ? {}
            : {
              '&:focus-visible': {
                boxShadow: `${ theme.new.shadow.focusRingNoColour } ${ theme.new.palette[props.colour][100].main }`,
                outline: 'none',
              },

              '&:hover, &:active': {
                backgroundColor: theme.new.palette[props.colour][700].main,
                borderColor: theme.new.palette[props.colour][700].main,
              },
            },
        }
        : {},

      ...props.variant === 'filled-dark'
        ? {
          backgroundColor: theme.new.palette[props.colour][700].main,
          color: props.disabled ? theme.new.palette[props.colour][600].main : theme.new.palette[props.colour][100].main,
          border: `1px solid ${ theme.new.palette[props.colour][700].main }`,

          ...props.disabled
            ? {
              pointerEvents: 'none',
            }
            : {
              '&:focus-visible': {
                boxShadow: `${ theme.new.shadow.focusRingNoColour } ${ theme.new.palette[props.colour][500].main }`,
                outline: 'none',
              },

              '&:hover, &:active': {
                backgroundColor: theme.new.palette[props.colour][600].main,
                borderColor: theme.new.palette[props.colour][600].main,
                color: theme.new.palette[props.colour][600].contrast,
              },
            },

          ...props.active
            ? {
              backgroundColor: theme.new.palette[props.colour][600].main,
              borderColor: theme.new.palette[props.colour][600].main,
              color: theme.new.palette[props.colour][600].contrast,
            }
            : {},
        }
        : {},
    }),
  };
};
