import { FC, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { StackEnd } from 'op-storybook/lib/components/StackEnd/StackEnd';
import { IconButton } from 'op-storybook/stories/components/IconButton/IconButton';
import EditIcon from 'op-storybook/lib/assets/icon/figma/pencil-01.svg';

import { RoleCondition } from '../../../../Audiences/Model';
import { RoleCloud } from './RoleCloud';
import { ConditionToggleButton } from '../ConditionToggleButton';

type Props = {
  condition: RoleCondition;
  onChange: (condition: RoleCondition) => void;
  onEditClicked: () => void;
};

export const RoleConditionComponent: FC<Props> = ({
  condition,
  onChange,
  onEditClicked,
}) => {
  return (
    <div>
      <Stack>
        <FormattedMessage
          description="Summary text for role tag audience condition."
          defaultMessage="{ roleCount, plural, one {<toggle>{ not, select, true {Doesn't have} other {Has} }</toggle> the following role} other {Has <toggle>{ not, select, true {none} other {any} }</toggle> of the following roles} }"
          values={ {
            toggle: (parts: ReactNode[]) => (
              <ConditionToggleButton
                onClick={ () => onChange({
                  ...condition,
                  not: !condition.not,
                }) }
              >
                { parts }
              </ConditionToggleButton>
            ),
            roleCount: condition.roleIds.length,
            not: condition.not,
          } }
        />
        <StackEnd>
          <IconButton
            variant="tertiary-grey"
            onClick={ onEditClicked }
            IconComponent={ EditIcon }
          />
        </StackEnd>
      </Stack>
      <RoleCloud condition={ condition }/>
    </div>
  );
};
