import { useMemo } from 'react';
import { useValidationErrors } from 'op-storybook/lib/hooks/useValidationErrors';

import { InlineFormComponent } from '../../../Model/BroadcastBlockDefinition';
import { ImageBlock } from '../../../Service/Blocks/ImageBlockDefinition';
import { useContextOrThrow } from '../../../../../Core/Hook';
import { FileUploaderContext } from '../../../../../Common/Context';
import { ImagePlaceholder } from './ImagePlaceholder';
import { ImageLoader } from './ImageLoader';
import { BroadcastBlockDefinitionRegistryContext } from '../../../Context/BroadcastBlockDefinitionRegistry';
import { BroadcastEditorErrors } from '../../BroadcastEditorErrors';

export const ImageBlockInlineForm: InlineFormComponent<ImageBlock> = ({
  block,
  validationTree,
  attachments,
  activateBlock,
}) => {
  const { uploadStates } = useContextOrThrow(FileUploaderContext);
  const uploadState = useMemo(() => {
    return uploadStates.get(block.localId);
  }, [block.localId, uploadStates]);
  const upload = uploadState?.upload ?? attachments.uploads.find(upload => upload.uuid === block.attributes.uploadId);
  const { getBlockErrorDefinitions } = useContextOrThrow(BroadcastBlockDefinitionRegistryContext);
  const blockErrorDefinitions = useMemo(() => getBlockErrorDefinitions(block), [block, getBlockErrorDefinitions]);
  const { formatValidationTreeWithDefinitions } = useValidationErrors();
  const formattedCaptionBackgroundColourErrors = useMemo(() => formatValidationTreeWithDefinitions(
    validationTree.children.attributes?.children.caption?.children.backgroundColour,
    blockErrorDefinitions.caption.backgroundColour,
  ), [
    blockErrorDefinitions.caption.backgroundColour,
    formatValidationTreeWithDefinitions,
    validationTree.children.attributes?.children.caption?.children.backgroundColour
  ]);
  const formattedUploadErrors = useMemo(() => formatValidationTreeWithDefinitions(
    validationTree.children.attributes?.children.uploadId,
    blockErrorDefinitions.uploadId,
  ), [
    blockErrorDefinitions.uploadId,
    formatValidationTreeWithDefinitions,
    validationTree.children.attributes?.children.uploadId
  ]);
  const combinedErrors = useMemo(() => [
    ...formattedUploadErrors,
    ...formattedCaptionBackgroundColourErrors,
  ], [formattedCaptionBackgroundColourErrors, formattedUploadErrors]);

  return (
    <div>
      <div
        onClick={ activateBlock }
        css={ theme => ({
          borderRadius: theme.new.borderRadius.standard,
          overflow: 'hidden',
          position: 'relative',
        }) }
      >
        {
          !!upload
            ? (
              <ImageLoader
                key={ upload.uuid }
                src={ `/api/uploads/${ upload.uuid }/download` }
                imageDimensions={ {
                  width: uploadState?.file.width ?? upload.image?.width ?? undefined,
                  height: uploadState?.file.height ?? upload.image?.height ?? undefined,
                } }
              />
            )
            : <ImagePlaceholder loading={ false }/>
        }
      </div>
      { !!combinedErrors.length && <BroadcastEditorErrors errors={ combinedErrors }/> }
    </div>
  );
};
