import { FunctionComponent, useCallback } from 'react';
import { Button } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';

import { FileDownloadButton, LinearCellPercentageGraph, SimpleTable, TableCell, TableRow } from '../../../../../Components';
import { CardReport, BroadcastReportSummary } from '../../Hooks/useFetchBroadcastReportSummary';
import { ContentIcon } from '../../../Common/ContentIcon';
import { ContentReader } from '../../../../../Readers';
import { Content, ContentType, ContentWithMedia, ContentWithUpload, ExternalTextContentLink, TextContent } from '../../../../../Models';
import { StyledImageContent } from '../../../../../Broadcasts/Service';
import { useContentDefinitionRegistry } from '../../../../../Content/Hook';

interface Props {
  summary: BroadcastReportSummary;
}

export const ContentSummaryTable: FunctionComponent<Props> = ({
  summary
}) => {
  const intl = useIntl();
  const { getContentTitle } = useContentDefinitionRegistry();

  const viewMediaMessageForContentType = useCallback((contentType: ContentType) => {
    switch (contentType) {
      case 'image':
      case 'styled_image':
        return intl.formatMessage({
          id: 'broadcast.report.viewImage',
          defaultMessage: 'View Image',
        });
      case 'file':
        return intl.formatMessage({
          id: 'broadcast.report.viewFile',
          defaultMessage: 'View File',
        });
      case 'audio':
        return intl.formatMessage({
          id: 'broadcast.report.viewAudio',
          defaultMessage: 'Listen',
        });
      case 'text':
        return intl.formatMessage({
          id: 'broadcast.report.viewTextLink',
          defaultMessage: 'Open Link',
        });
      case 'video':
        return intl.formatMessage({
          id: 'broadcast.report.viewVideo',
          defaultMessage: 'Watch',
        });
      default:
        return intl.formatMessage({
          id: 'broadcast.report.downloadMedia',
          defaultMessage: 'Download Media',
        });
    }
  }, [intl]);

  return (
    <SimpleTable
      headerRow={(
        <TableRow>
          <TableCell width="5%">
            <FormattedMessage
              id="broadcast.report.card"
              defaultMessage="Card"
              />
          </TableCell>
          <TableCell>
            <FormattedMessage
              id="broadcast.report.content"
              defaultMessage="Content"
              />
          </TableCell>
          <TableCell width="12%">
            <FormattedMessage
              id="broadcast.report.seen"
              defaultMessage="Seen"
              />
          </TableCell>
          <TableCell width="12%">
            <FormattedMessage
              id="broadcast.report.engagement"
              defaultMessage="Engagement"
              />
          </TableCell>
          <TableCell width="10%">
            <FormattedMessage
              id="broadcast.report.media"
              defaultMessage="Media"
              />
          </TableCell>
          <TableCell width="10%">
            <FormattedMessage
              id="broadcast.report.views"
              defaultMessage="Views"
              />
          </TableCell>
          <TableCell width="10%"></TableCell>
        </TableRow>
      )}
      rowRender={ (card: CardReport) => {
        const recipients = (
          (card.counts.seen?.total || 0) + (card.counts.notSeen?.total || 0)
        );
        return (
          <TableRow key={ card.content.id }>
            <TableCell>
              <ContentIcon
                contentType={
                  ContentReader.contentTypeFromString(card.content.type)
                }
              />
            </TableCell>
            <TableCell maxwidth="0" ellipsisOverflow={true}>
              { getContentTitle(intl, card.content) }
            </TableCell>
            <TableCell>
              <LinearCellPercentageGraph
                percentage={
                  recipients > 0
                  ? (card.counts.seen?.total || 0) / recipients
                  : 0
                }
                />
            </TableCell>
            <TableCell>
              <LinearCellPercentageGraph
                percentage={
                  recipients > 0
                  ? (card.counts.responded?.total || 0) / recipients
                  : 0
                }
                />
            </TableCell>
            <TableCell>
              {
                contentHasMedia(card.content)
                ? (
                  <FileDownloadButton
                    uploadId={card.content.media.id}
                    buttonProps={{
                      color: 'secondary'
                    }}
                    >{
                      viewMediaMessageForContentType(
                        ContentReader.contentTypeFromString(card.content.type)
                      )
                    }</FileDownloadButton>
                )
                : (
                  contentHasUpload(card.content)
                  ? (
                    <FileDownloadButton
                      uploadId={card.content.upload.id}
                      buttonProps={{
                        color: 'secondary'
                      }}
                      >{
                        viewMediaMessageForContentType(
                          ContentReader.contentTypeFromString(card.content.type)
                        )
                      }</FileDownloadButton>
                  )
                  : <></>
                )
              }
              {/* TODO: Need to include fileEntry - we can't navigate there yet */}
              {
                (card.content as TextContent | StyledImageContent).link
                && ((card.content as TextContent).link as ExternalTextContentLink).type === 'url'
                && (
                  <Button
                    color="secondary"
                    href={ ((card.content as TextContent).link as ExternalTextContentLink).url }
                  >
                    <FormattedMessage
                      description="Label for button to open link in broadcast submission report."
                      defaultMessage="Open link"
                    />
                  </Button>
                )
              }
            </TableCell>
            <TableCell>
              {
                (contentHasMedia(card.content) || contentHasUpload(card.content)) && (
                  card.counts.responded?.total || 0
                )
              }
            </TableCell>
            <TableCell>
              {
                card.content.id && (
                  <Button
                    color="secondary"
                    href={`#content${card.content.id}`}
                    >
                      <FormattedMessage
                        id="broadcast.report.viewCard"
                        defaultMessage="View Card"
                        />
                    </Button>
                )
              }
            </TableCell>
          </TableRow>
        );
      }}
      rows={summary.cards.filter(card => ContentReader.contentTypeFromString(card.content.type) !== 'stack')}
      />
  );
};

const contentHasMedia = (content: Content): content is ContentWithMedia => (
  (content as ContentWithMedia).media !== undefined
);

const contentHasUpload = (content: Content): content is ContentWithUpload => (
  (content as ContentWithUpload).upload !== undefined
);
