import { FunctionComponent, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { DateRangePicker } from '../../../Common/Component';
import { DateRangePickerOption, DateRangePickerValue } from '../../../Common/Model/DateRangePicker';

interface Props {
  value: DateRangePickerValue;
  onChange: (value: DateRangePickerValue) => void;
}

export const EventsDateRangePicker: FunctionComponent<Props> = ({
  value,
  onChange,
}) => {
  const intl = useIntl();
  const options = useMemo<DateRangePickerOption[]>(() => [
    {
      type: 'offset',
      from: 'end',
      offset: null,
      label: intl.formatMessage({
        id: 'eventsDateRangePicker.past',
        description: 'Label for events occurring in the past/',
        defaultMessage: 'Past'
      }),
    },
    {
      type: 'offset',
      from: 'start',
      offset: null,
      label: intl.formatMessage({
        id: 'eventsDateRangePicker.future',
        description: 'Label for events occurring in the future.',
        defaultMessage: 'Future'
      }),
    }
  ], [intl]);

  return (
    <DateRangePicker
      value={ value }
      onChange={ onChange }
      options={options}
      allowCustom
    />
  )
}
