import { FunctionComponent, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from '@mui/material';

import { DateTime, StatusChip } from '../../../Common/Component';
import { UserInteraction } from '../../../Models/UserInteraction';
import { PersonParser } from '../../../Utility';

type Props = {
  closed: UserInteraction | null;
};

export const SubmissionStatus: FunctionComponent<Props> = ({ closed }) => {
  const chip = useMemo(() => (
    <StatusChip
      label={ !!closed ? closedStatus : openStatus }
      status={ !!closed ? 'success' : 'warning' }
    />
  ), [closed]);

  return (
    closed
      ? (
        <Tooltip
          title={
            <FormattedMessage
              id="submissionStatus.tooltip"
              description="Tooltip displayed on mouseover when form submission status is closed."
              defaultMessage="Closed by { closedBy } on { closedAt }"
              values={ {
                closedBy: PersonParser.fullName(closed.by),
                closedAt: <DateTime dateTime={ closed.at } />
              } }
            />
          }
          placement="bottom"
        >
          <div>
            { chip }
          </div>
        </Tooltip>
      )
      : chip
  );
}

const openStatus = (
  <FormattedMessage
    id="forms.report.openSubmissionStatus"
    description="Status displayed in form report table for open submissions."
    defaultMessage="Open"
  />
);

const closedStatus = (
  <FormattedMessage
    id="forms.report.closedSubmissionStatus"
    description="Status displayed in form report table for closed submissions."
    defaultMessage="Closed"
  />
);
