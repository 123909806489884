import { IntlShape } from 'react-intl';
import { createElement, ReactNode } from 'react';

import { DraftContent, DraftSingleContentCard, EditorContent, SingleContentCard } from '../../../../Content/Model';
import TextRequestIcon from '../../../../Assets/img/icons/monochrome/content/text.svg';
import { TextContentEditor, TextContentPreview } from '../../../Component';
import { RichTextParser, UniqueIdGenerator } from '../../../../Common/Utility';
import { TextContentValidator } from '../..';
import { CardTransformer } from '../../../../Content/Utility';
import { LiveTextContent } from '../../../../Inbox/Component';
import { ReadResponse } from '../../../../Inbox/Model';
import { BroadcastContent, BroadcastContentDefinition } from '../../../Model';
import { Form } from '../../../../Forms/Model';
import { EnvironmentSettings } from '../../../../Models';
import { DraftLink } from '../../../Component/Content/LinkEditor/LinkEditor';
import { ContentLink } from '../../../Model/ContentLink';
import { MinimalFileEntry } from '../../../../Files/Model/FileEntry';
import { SafeRichTextContent } from '../../../../Common/Component/SafeRichTextContent/SafeRichTextContent';

export type TextContent = BroadcastContent & {
  type: 'textcontent';
  text: string;
  link: ContentLink | null;
};

export type DraftTextContent = DraftContent & DraftLink & {
  type: 'textcontent';
  text: string;
};

export type TextEditorContent = EditorContent & {
  fileEntry: MinimalFileEntry | null;
  form: Form | null;
  card: DraftSingleContentCard<DraftTextContent>;
};

export class TextContentDefinition implements BroadcastContentDefinition<TextContent, TextEditorContent, ReadResponse> {
  public readonly id = 'text';
  public readonly availableToCreate = true;
  public readonly new = false;
  public readonly richText = true;
  public readonly contentTypes = ['textcontent'];
  public readonly categoryId = 'content';
  public readonly IconComponent = TextRequestIcon;
  public readonly EditorComponent = TextContentEditor;
  public readonly PreviewComponent = TextContentPreview;
  public readonly LiveComponent = LiveTextContent;
  public readonly SubmittedComponent = (): null => null;
  public validate = TextContentValidator.validate;

  public definesContentType = (type: string): boolean => (
    this.contentTypes.includes(type)
  );

  public getContentTitle = (intl: IntlShape, content: TextContent): ReactNode => {
    const text = RichTextParser.getFirstNonEmptyNodeTextContent(content.text, true);
    return text
      ? createElement(SafeRichTextContent, { placeholderBehaviour: 'badge', text })
      : this.getDefaultTitle(intl)
  };

  public getLocalisedType = (intl: IntlShape): string => (
    intl.formatMessage({
      id: 'broadcasts.content.text.type',
      description: 'Localised name for text content type.',
      defaultMessage: 'Text',
    })
  );

  public getDraftContentTitle = (intl: IntlShape, draftContent: DraftTextContent): string => (
    RichTextParser.getFirstNonEmptyNodeTextContent(draftContent.text) || this.getDefaultTitle(intl)
  );

  public hasRequiredFeatureFlags = (environmentSettings: EnvironmentSettings): boolean => (
    !!environmentSettings.textTypeEnabled
  );

  public createEditorContent = (): TextEditorContent => ({
    id: UniqueIdGenerator.generate(),
    fileEntry: null,
    form: null,
    card: {
      content: {
        type: 'textcontent',
        text: '',
      },
      mandatory: false,
      image: null,
    },
  });

  public transformCard = (card: SingleContentCard<TextContent>): TextEditorContent => ({
    id: card.id,
    fileEntry: card.content.link?.fileEntry || null,
    form: null,
    card: CardTransformer.transformCard(card, {
      type: 'textcontent',
      text: card.content.text?.replace(card.content.link?.placeholderText || '', '') || '',
      ...(
        card.content.link
          ? {
            linkLabel: card.content.link.label,
            linkType: card.content.link.type,
            linkTarget: card.content.link.type === 'url'
              ? card.content.link.url
              : card.content.link.type === 'form'
                ? card.content.link.formId
                : card.content.link.fileEntry?.id,
          }
          : {}
      ),
    })
  });

  private getDefaultTitle = (intl: IntlShape): string => (
    intl.formatMessage({
      id: 'broadcasts.content.text.defaultTitle',
      description: 'Default title for text content.',
      defaultMessage: 'Untitled text card',
    })
  );
}
