import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FeaturedIcon } from 'op-storybook/stories/components/FeaturedIcon/FeaturedIcon';
import PendingIcon from '@ourpeople/shared/Asset/Icon/figma/hourglass-01.svg';
import { Typography } from '@ourpeople/shared/Core/Component/Content/Typography/Typography';

import { AnonymousSettings } from '../../Hook/useFetchAnonymousSettings';
import { RegistrationConfirmationTemplate } from '../RegistrationConfirmationTemplate/RegistrationConfirmationTemplate';

type Props = {
  anonymousSettings: AnonymousSettings;
};

export const PendingRegistrationConfirmation: FC<Props> = ({
  anonymousSettings
}) => {
  const intl = useIntl();

  return (
    <RegistrationConfirmationTemplate
      anonymousSettings={ anonymousSettings }
      title={ intl.formatMessage({
        description: 'Title for registration confirmation page when manual approval is required',
        defaultMessage: 'Registration pending',
      }) }
      icon={
        <FeaturedIcon
          colour="warning"
          size="xl"
          variant="light-circle"
          IconComponent={ PendingIcon }
        />
      }
    >
      <Typography>
        <FormattedMessage
          description="Registration confirmation page body when manual approvial and phone number was provided"
          defaultMessage="<p>Thank you for registering.</p><p>Your registration is now pending review. We'll send you an invitation once your registration has been approved.</p>"
        />
      </Typography>
    </RegistrationConfirmationTemplate>
  );
};
