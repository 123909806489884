import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Heading } from '@ourpeople/shared/Core/Component/Content';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';

import { Audience } from '../../../Audiences/Model';
import { Box, Flex, FlexPullRight, PresentationIcon, VerticallySpaced } from '../../../Common/Component';
import { ValidationTree } from '../../../Common/Model';
import { AudienceEditor } from '../../../Audiences/Component/AudienceEditor/AudienceEditor';
import { useResponsiveSpacing } from '../../../Common/Hook';
import LockIcon from '../../../Assets/img/icons/streamline/shield-lock.svg';

interface Props {
  audienceSize?: number;
  audienceSizeLinkUrl: string;
  audience: Audience;
  onChange: (audience: Audience) => void;
  validation?: ValidationTree<Audience>;
  onNextButtonClicked: () => void;
  editing: boolean;
  onEditButtonClicked: () => void;
  onRevertButtonClicked: () => void;
  anonymous: boolean;
  readonly?: boolean;
  alwaysEdit?: boolean;
}

export const ViewAudienceEditor: FunctionComponent<Props> = ({
  audienceSize,
  audienceSizeLinkUrl,
  audience,
  onChange,
  validation,
  onNextButtonClicked,
  editing,
  onEditButtonClicked,
  onRevertButtonClicked,
  anonymous,
  children,
  readonly,
  alwaysEdit = false,
}) => (
  <VerticallySpaced gap={ useResponsiveSpacing() }>
    <Box margin={ false }>
      <VerticallySpaced gap={ 2 }>
        <Heading type="h2">
          <FormattedMessage
            id="forms.audience.heading"
            description="Heading shown on the audience step of the form builder"
            defaultMessage="Audience Criteria"
          />
        </Heading>
        { anonymous && (
          <Flex gap={ 1 }>
            <PresentationIcon
              color="primary.main"
              size="medium"
              IconComponent={ LockIcon }
            />
            <FormattedMessage
              id="forms.audience.anonymousNotice"
              description="Audience criteria notice displayed when form is anonymous."
              defaultMessage="<span><strong>Important notice</strong>: Audience criteria for anonymous forms <strong>cannot be edited after first publish</strong>.</span>"
            />
          </Flex>
        ) }
        <AudienceEditor
          audienceSize={ audienceSize }
          audienceSizeLinkUrl={ audienceSizeLinkUrl }
          readonly={ readonly }
          multipleSegments
          audience={ audience }
          onChange={ onChange }
          validation={ validation }
          editing={ editing || alwaysEdit }
          showReach={ !alwaysEdit }
          onEditButtonClicked={ onEditButtonClicked }
          onRevertButtonClicked={ onRevertButtonClicked }
          alwaysEdit={ alwaysEdit }
        />
      </VerticallySpaced>
    </Box>
    <Box margin={ false }>
      { children }
      <Flex>
        <FlexPullRight>
          <Button
            variant="primary"
            onClick={ onNextButtonClicked }
          >
            <FormattedMessage
              id="forms.create.next"
              description="Label for button to advance to next section of form creation"
              defaultMessage="Next"
            />
          </Button>
        </FlexPullRight>
      </Flex>
    </Box>
  </VerticallySpaced>
);
