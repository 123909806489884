import { LinkProps as RouterLinkProps, Link as RouterLink } from 'react-router-dom';
import { MouseEventHandler , forwardRef} from 'react';
import * as H from 'history';

export type LinkProps<S = H.LocationState> = RouterLinkProps<S> & {
  disabled?: boolean;
}

/** @deprecated Prefer our non-deprecated Link component */
// This component existed to simplify linking between the multiple routers we had for a time. It's no longer relevant
// and should be phased out.
export const DeprecatedLink = forwardRef<HTMLAnchorElement, LinkProps>(({
  children,
  className,
  to,
  disabled = false,
  onClick,
  ...props
}, ref) => {
  const pathName = typeof to === 'string'
    ? to
    : typeof to === 'object'
      ? to.pathname || ''
      : '';
  const absolutePath = RegExp('^(?:[a-z]+:)?//', 'i').test(pathName);

  const whenLinkClicked: MouseEventHandler<HTMLAnchorElement> = (event) => {
    if (disabled) {
      event.preventDefault();
    }

    if (onClick) {
      onClick(event);
    }
  };

  return absolutePath
    ? (
      <a
        className={ className }
        href={ pathName }
        ref={ref}
        { ...(
          absolutePath
            ? {
              target: props.target ?? '_blank',
              rel: 'noreferrer',
            }
            : {}
        ) }
        onClick={ whenLinkClicked }
      >
        { children }
      </a>
    )
    : (
      <RouterLink
        className={ className }
        to={ to }
        ref={ref}
        { ...props }
        onClick={ whenLinkClicked }
      >
        { children }
      </RouterLink>
    )
});
