import { ComponentProps, FC, FormEventHandler, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { PasswordField } from 'op-storybook/stories/components/PasswordField/PasswordField';
import { Button } from 'op-storybook/stories/components/Button/Button';
import { Form } from 'op-storybook/lib/components/Form/Form';
import { CheckItemText } from 'op-storybook/stories/components/CheckItemText/CheckItemText';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import LockIcon from 'op-storybook/lib/assets/icon/figma/lock-01.svg';
import { AuthHeader } from 'op-storybook/lib/components/AuthHeader/AuthHeader';
import { Link, useHistory } from 'react-router-dom';
import { LocationDescriptor } from 'history';

import { RequestState } from '../../../Models';
import { useUpdatePasswordRequest } from '../../Hook/useUpdatePasswordRequest';
import { useLocalisedPasswordErrors } from '../../Hook/useLocalisedPasswordErrors';

type Props = {
  password: string;
  onChange: (password: string) => void;
  onSubmit: FormEventHandler;
  updatePasswordRequest: ReturnType<typeof useUpdatePasswordRequest>['updatePasswordRequest'];
  codeExpiryLink: LocationDescriptor;
  returnLinkProps: ComponentProps<typeof Link>;
};

export const PasswordUpdateForm: FC<Props> = ({
  password,
  onChange,
  onSubmit,
  updatePasswordRequest,
  returnLinkProps,
}) => {
  const intl = useIntl();
  const history = useHistory();
  const passwordChecks = useMemo(() => (
    updatePasswordRequest.state === RequestState.FAILED
      ? updatePasswordRequest.result.checks || []
      : []
  ), [updatePasswordRequest]);
  const localisedPasswordErrors = useLocalisedPasswordErrors(passwordChecks);
  const nonCheckError = updatePasswordRequest.state === RequestState.FAILED && updatePasswordRequest.result.error !== 'FAILED_CHECKS'
    ? updatePasswordRequest.result.error
    : undefined;

  useEffect(() => {
    if (nonCheckError !== 'ACCESS_DENIED') {
      return;
    }

    history.replace(updatePasswordRequest);
  }, [history, nonCheckError, updatePasswordRequest]);

  return (
    <>
      <AuthHeader
        header={ intl.formatMessage({
          description: 'Heading for update password page.',
          defaultMessage: 'Set new password',
        }) }
        graphic={ {
          type: 'icon',
          props: {
            IconComponent: LockIcon,
            size: 'lg',
          },
        } }
      />
      <Form onSubmit={ onSubmit }>
        <Stack
          direction="column"
          gap={ 2 }
        >
          <PasswordField
            value={ password }
            onChange={ onChange }
            label={ intl.formatMessage({
              description: 'label for new password field when updating user password.',
              defaultMessage: 'New password',
            }) }
            {
              ...nonCheckError && nonCheckError !== 'ACCESS_DENIED'
                ? {
                  errorMessages: [intl.formatMessage({
                    description: 'Generic error when attempting to update password.',
                    defaultMessage: 'Something went wrong, please try again.',
                  })],
                }
                : {}
            }
          />
          <Button
            variant="primary"
            type="submit"
            disabled={ !password }
            busy={ updatePasswordRequest.state === RequestState.FETCHING }
          >
            <FormattedMessage
              description="Label for submit button on update password form."
              defaultMessage="Set password"
            />
          </Button>
        </Stack>
      </Form>
      { !!localisedPasswordErrors.length && (
        <Stack
          direction="column"
          gap={ 1 }
        >
          { localisedPasswordErrors.map(localisedPasswordError => (
            <CheckItemText
              key={ localisedPasswordError.message }
              colour={ localisedPasswordError.passed ? 'success' : 'grey' }
              text={ localisedPasswordError.message }
            />
          )) }
        </Stack>
      ) }
      <Stack
        direction="column"
        align="center"
      >
        <Link { ...returnLinkProps } />
      </Stack>
    </>
  );
};
