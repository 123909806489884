import { FunctionComponent, ReactNode, useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { ListItemText, MenuItem } from '@mui/material';

import { RefreshDeliverySummaryContext } from '../BroadcastReportPage';
import { Dialog } from '../../../../../Components';
import { TableMenu } from '../../../../../Components/Content/TableMenu';
import { ApiContext } from '../../../../../Contexts';
import { ContentWithEvent } from '../../../../../Models';
import { PersonParser } from '../../../../../Utility';
import { RefreshContext, ReportRecipient } from '../../../../../Broadcasts/Component';

interface Props {
  content: ContentWithEvent;
  recipient: ReportRecipient<Record<string, unknown>>;
  showRemoveAttendance: boolean;
}

export const MeetingTableMenu: FunctionComponent<Props> = ({
  content,
  recipient,
  showRemoveAttendance,
}) => {
  const intl = useIntl();
  const api = useContext(ApiContext);
  const reload = useContext(RefreshContext);
  const refreshDeliverySummary = useContext(RefreshDeliverySummaryContext);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [actionHeading, setActionHeading] = useState<string|ReactNode>();
  const [actionDescription, setActionDescription] = useState<string|ReactNode>();
  const [action, setAction] = useState<'markAttended' | 'removeAttendance'>();
  const onMarkAttended = () => {
    setActionHeading(
      intl.formatMessage({
        id: 'markAttended.heading',
        defaultMessage: 'Mark as Attended'
      })
    );
    setActionDescription(
      intl.formatMessage({
        id: 'markAttended.description',
        defaultMessage: 'Are you sure you want to mark <b>{name}</b> as attended?'
      }, {
        name: PersonParser.fullName(recipient.user),
      })
    );
    setAction('markAttended');
    setConfirmDialogOpen(true);
  };

  const onRemoveAttendance = () => {
    setActionHeading(
      intl.formatMessage({
        id: 'removeAttendance.heading',
        defaultMessage: 'Remove Attendance'
      })
    );
    setActionDescription(
      intl.formatMessage({
        id: 'removeAttendance.description',
        defaultMessage: 'Are you sure you want to remove the attendance of <b>{name}</b>?'
      }, {
        name: PersonParser.fullName(recipient.user),
      })
    );
    setAction('removeAttendance');
    setConfirmDialogOpen(true);
  };

  const performAction = () => {
    if (api) {
      switch (action) {
        case 'removeAttendance':
          return api.put(
            `/meetings/attendees/${recipient.metadata.attendeeId as string}/remove`
          );
        case 'markAttended':
          if (content.id) {
            return api.post(
              `/meetings/${content.id}/register`,
              {
                userId: recipient.user.id
              }
            );
          }
      }
    }
    return Promise.reject();
  }

  const onActionConfirmed = () => {
    if (api) {
      performAction()
      .then(() => {
        setConfirmDialogOpen(false);
        reload && reload();
        refreshDeliverySummary && refreshDeliverySummary();
      }).catch(() => {
        // do nothing
      });
    }
  };

  return (
    <>
      <TableMenu
        rowKey={ content.id || ''}
      >
        <MenuItem
          onClick={onMarkAttended}
        >
          <ListItemText primary={
            intl.formatMessage({
              id: 'event.markAttended',
              defaultMessage: 'Mark attended',
            })
          }
          />
        </MenuItem>
        {
          showRemoveAttendance && (
            <MenuItem
              onClick={onRemoveAttendance}
            >
              <ListItemText primary={
                intl.formatMessage({
                  id: 'event.removeAttendance',
                  defaultMessage: 'Remove attendance',
                })
              }
              />
            </MenuItem>
          )
        }
      </TableMenu>
      <Dialog
        cta={
          intl.formatMessage({
            id: 'meetingConfirmDialog.cta',
            defaultMessage: 'Yep',
          })
        }
        cancel={
          intl.formatMessage({
            id: 'meetingConfirmDialog.cancel',
            defaultMessage: 'Nope',
          })
        }
        headingSize="small"
        headingTextAlign="left"
        open={confirmDialogOpen}
        onConfirmed={onActionConfirmed}
        onDismissed={() => setConfirmDialogOpen(false)}
        heading={actionHeading}
        >
          {actionDescription}
        </Dialog>
    </>
  )
}
