import styled from '@emotion/styled';

export const StyledConditionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr;
  column-gap: ${props => props.theme.spacing(2)};
`;

export const StyledFilterTypeSelect = styled.div`
  .MuiInputBase-root {
    width: 100%;
  }
`;
