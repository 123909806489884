import { ChangeEvent, FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Pagination as MaterialPagination } from '@mui/material';

import { StyledPaginationContainer, StyledPaginationContainerProps } from './styles';
import { Pagination as PaginationModel } from '../../../Models/Paginated'

type Props = StyledPaginationContainerProps & {
  pagination: PaginationModel;
  pageNum: number;
  onPageChanged: (page: number) => void;
  siblingCount?: number;
  boundaryCount?: number;
}

export const Pagination: FunctionComponent<Props> = ({
  pagination,
  pageNum,
  onPageChanged,
  siblingCount,
  boundaryCount,
  ...props
}) => {
  const whenPageChanged = (
    event: ChangeEvent<unknown>,
    page: number,
  ) => {
    onPageChanged(page);
  };

  const currentPageStartIndex = (pageNum - 1) * pagination.pageSize;

  const currentPageSize = Math.max(
    0,
    Math.min(
      pagination.itemCount - currentPageStartIndex,
      pagination.pageSize,
    ),
  );

  const startRecord = currentPageSize !== 0
    ? ( currentPageStartIndex + 1)
    : 0;

  const endRecord = currentPageStartIndex + currentPageSize;

  return (
    <StyledPaginationContainer {...props}>
      <FormattedMessage
        id="broadcasts.list.paginationSummary"
        defaultMessage="Showing {startRecord, number} {endRecord, plural, one {} other {to #}} of {resultCount, number}"
        values={ {
          resultCount: pagination.itemCount,
          startRecord,
          endRecord,
        } }
      />
      <MaterialPagination
        count={ pagination.pageCount }
        color="primary"
        size="large"
        page={ pageNum }
        onChange={ whenPageChanged }
        siblingCount={ siblingCount }
        boundaryCount={ boundaryCount }
      />
    </StyledPaginationContainer>
  )
};
