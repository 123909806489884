import styled from '@emotion/styled';

export const StyledTimelinePreview = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${ props => props.theme.palette.background.paper };
  color: ${ props => props.theme.palette.text.primary };
  text-align: center;
`;

export const StyledStackLogo = styled.img`
  width: 40%;
  margin: 0 auto;
`;

export const StyledDivider = styled.hr`
  background-color: ${ props => props.theme.palette.text.primary };
  margin: 0;
  border: none;
  width: 100%;
  height: 1px;
`;
