import { DraftCover } from '../../ContentDefinition';
import { ValidationTree, Validator } from '../../../../Common/Model';
import { Validate } from '../../../../Common/Utility';
import { EventValidator } from '../EventValidator/EventValidator';

export class CoverValidator {
  public static validate = (cover: DraftCover): ValidationTree<DraftCover> => ({
    errors: [],
    children: {
      ...EventValidator.validate(cover, COVER_TEXT_MIN_LENGTH, COVER_TEXT_MAX_LENGTH).children,
      comment: CoverValidator.validateComment(cover.comment || ''),
      location: CoverValidator.validateLocation(cover.location),
    },
  });

  public static validateComment = (comment: string): ValidationTree<string> => ({
    errors: commentValidator.validate(comment),
    children: {},
  });

  public static validateLocation = (location: string): ValidationTree<string> => ({
    errors: locationValidator.validate(location),
    children: {},
  });
}

export const COVER_TEXT_MIN_LENGTH = 1;
export const COVER_TEXT_MAX_LENGTH = 90;
export const COVER_COMMENT_MIN_LENGTH = 1;
export const COVER_COMMENT_MAX_LENGTH = 140;

const commentValidator = new Validator<string>([
  comment => Validate.minLength(comment, COVER_COMMENT_MIN_LENGTH),
  comment => Validate.maxLength(comment, COVER_COMMENT_MAX_LENGTH),
]);

const locationValidator = new Validator<string>([
  Validate.notBlank,
]);
