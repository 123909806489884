import styled from '@emotion/styled';

export const StyledDeviceOutline = styled.div`
  width: 350px;
  height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  border-radius: ${ props => props.theme.borderRadius.xl };
  position: relative;
  overflow: hidden;

  ::after {
    content: '';
    display: block;
    transform: rotate(-20deg);
    width: 400px;
    height: 400px;
    background: linear-gradient(0deg, rgba(255,255,255, 0) 0%, rgb(255,255,255) 100%);
    opacity: 0.25;
    position: absolute;
    top: -100px;
    right: -225px;
  }
`;

export const StyledDeviceInner = styled.div`
  z-index: 0;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  background-color: ${ props => props.theme.palette.background.paper };
  display: grid;
  grid-template-rows: auto 1fr auto;
  border-radius: ${ props => props.theme.borderRadius.lg };
  position: relative;
  overflow: hidden;
`;

export const StyledDeviceHeader = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledDeviceHeaderNotch = styled.div`
  width: 40%;
  height: 25px;
  background-color: #000000;
  border-bottom-left-radius: ${ props => props.theme.borderRadius.md };
  border-bottom-right-radius: ${ props => props.theme.borderRadius.md };
  z-index: 999;
`;

export const StyledDeviceFooter = styled.div`
  display: flex;
  justify-content: center;
  padding: ${ props => props.theme.spacing(2) };
  margin-top: auto;
`;

export const StyledDeviceFooterNotch = styled.div`
  height: 5px;
  width: 40%;
  background-color: #000000;
  border-radius: ${ props => props.theme.borderRadius.max };
  z-index: 999;
`;
