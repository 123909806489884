import { useIntl } from 'react-intl';
import { FC, useCallback, useContext } from 'react';
import { AutocompleteCommonProps, AutocompleteFetchRequest, AutocompleteSelectionChanged } from '@ourpeople/shared/Core/Component/Input/Autocomplete/Autocomplete';

import { Tag } from '../../../../Tags/Model';
import { ApiContext } from '../../../../Contexts';
import { Paginated } from '../../../../Models';
import { DebouncedAutocomplete } from '../../../../Common/Component';

export type SuccessTag = Tag<'skill'> | Tag<'jobtitle'>

type Props = AutocompleteCommonProps & {
  selectedIds: string[];
  onSelectionChanged: AutocompleteSelectionChanged<SuccessTag>;
};

export const TrainingSuccessTagInput: FC<Props> = ({
  selectedIds,
  onSelectionChanged,
  ...props
}) => {
  const intl = useIntl();
  const api = useContext(ApiContext);

  const whenFetch: AutocompleteFetchRequest<SuccessTag> = async (
    ids: string[],
    search: string,
    pageNum: number,
  ) => {
    if (!api) {
      throw new Error('api not ready');
    }

    const params = {
      types: 'skill,jobtitle',
      sort: 'name_asc,id_asc',
      pageNum,
      ids: ids.join(',') || null,
      search: search.trim() || null,
    };
    const response = await api.get<Paginated<'tags', SuccessTag>>('/tags', { params });
    return {
      options: response.data.tags,
      pageCount: response.data.pagination.pageCount,
    };
  }

  return <DebouncedAutocomplete<SuccessTag>
    { ...props }
    getOptionLabel={ tag => tag.name }
    fetchOptions={ useCallback(whenFetch, [api]) }
    selectedIds={ selectedIds }
    onSelectionChanged={ onSelectionChanged }
    label={
      props.label !== undefined
        ? props.label
        : intl.formatMessage({
          id: 'trainingSuccessTagInput.label',
          defaultMessage: 'Select tags',
        })
    }
    multiple
    noMargin
    variant="standard"
    placeholder={ props.placeholder || intl.formatMessage({
      id: 'trainingSuccessTagInput.placeholder',
      defaultMessage: 'Search for tag',
    }) }
  />;
};
