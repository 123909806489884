import styled from '@emotion/styled';
import { DialogContent, Paper } from '@mui/material';

import { StyledDropzone } from '../MultiDropzone/style';

export const StyledDialog = styled(Paper)`
  min-height: 60%;

  .MuiDialogContent-root {
    padding-top: 0;
    padding-bottom: 0;
  }

  ${props => props.theme.breakpoints.down('sm')} {
    width: 96%;
    margin: 0;
  }
`;

export const StyledDialogContainer = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  gap: ${props => props.theme.spacing(2)};
`;

export const StyledDropzoneContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;

  > ${StyledDropzone} {
    width: 50%;
    height: 75%;
    justify-content: center;

    ${props => props.theme.breakpoints.down('lg')} {
      width: 100%;
    }
  }
`;

export const StyledFileRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 1rem;
  padding: 0.3rem 0;
  border-bottom: 1px solid ${props => props.theme.palette.grey[300]};
`;

export const StyledFileName = styled.span`
  font-size: 1rem;
  color: ${props => props.theme.palette.secondary.main};
`;

export const StyledDialogContent = styled(DialogContent)`
  display: flex;
`;
