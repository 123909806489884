import styled from '@emotion/styled';

export const StyledNavItemGroup = styled.div<{ expanded: boolean }>`
  width: 100%;
  transition: gap 0.2s;
  display: flex;
  flex-direction: column;
  gap: 0;

  ${ props => props.expanded && `
    gap: ${ props.theme.new.spacing[2] };
  ` }

  ul {
    list-style: none;
    margin: 0 0 ${ props => props.theme.new.spacing[2] } 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: ${ props => props.theme.new.spacing[2] };
  }
`;
