import { FunctionComponent } from 'react';
import { ListItem, ListItemText } from '@mui/material';

import { Picker } from '../Picker/Picker';
import { FilterOption } from '../../../Models/FilterOption';

type Props = {
  filterOptions: FilterOption[];
  activeFilterOptions: FilterOption[];
  onActiveFilterOptionsChanged: (activeFilterOptions: FilterOption[]) => void;
  label: string;
}

export const PickerFilter: FunctionComponent<Props> = ({
  filterOptions,
  activeFilterOptions,
  onActiveFilterOptionsChanged,
  label,
}) => {
  return (
    <>
      <Picker
        closeOnSelect={ true }
        buttonLabel={ label }
        items={ filterOptions }
        selectedItems={ activeFilterOptions }
        itemRenderFn={ (filterOption, selected) => (
          <ListItem
            dense
            key={ filterOption.value }
            selected={ selected }
            onClick={ () => onActiveFilterOptionsChanged([filterOption]) }
          >
            <ListItemText
              primary={ filterOption.label }
            />
          </ListItem>
        ) }
        itemCompareFn={ (filterOptionA, filterOptionB) => filterOptionA.value === filterOptionB.value }
      />
    </>
  )
}
