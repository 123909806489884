import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { ButtonProps, Button } from '@mui/material';
import { DebouncedSearchInput } from '@ourpeople/shared/Core/Component/Input/DebouncedSearchInput/DebouncedSearchInput';

import { Chip, FilterChips, Flex, FlexPullRight, SelectionDescription } from '..';
import { StyledFilterLabel, StyledTableFilters } from './style';
import { PropsWithKey } from '../../Model';

type Props = {
  chips?: Chip[];
  searchValue?: string;
  onSearchChanged?: (searchValue: string) => void;
  onClearFiltersClicked: () => void;
  selectedRows?: number;
  currentPageRows?: number;
  totalRows?: number | null;
  actions?: PropsWithKey<ButtonProps>[];
  onClearSelectionClicked?: () => void;
  onExpandSelectionClicked?: () => void;
  onRemoveChipClicked?: (chip: Chip) => void;
};

export const TableFilters: FC<Props> = ({
  searchValue,
  onSearchChanged,
  chips,
  onClearFiltersClicked,
  actions = [],
  selectedRows = 0,
  currentPageRows = 0,
  totalRows = null,
  onClearSelectionClicked,
  onExpandSelectionClicked,
  onRemoveChipClicked,
  children,
}) => (
  <StyledTableFilters
    direction="column"
    gap={ 1 }
    align="stretch"
  >
    <Flex gap={ 2 }>
      {
        !!selectedRows
          ? (
            <>
              <StyledFilterLabel>
                <FormattedMessage
                  id="tableFilters.actions"
                  description="Actions label in table filters"
                  defaultMessage="Actions"
                />
              </StyledFilterLabel>
              { actions.map(action => <Button {...action}/>) }
            </>
          )
          : children && (
            <>
              <StyledFilterLabel>
                <FormattedMessage
                  id="tableFilters.filterResults"
                  description="Filter label in table filters"
                  defaultMessage="Filter results"
                />
              </StyledFilterLabel>
              { children }
            </>
          )
      }
      { onSearchChanged && searchValue !== undefined && (
        <FlexPullRight>
          <DebouncedSearchInput
            value={ searchValue }
            onChange={ onSearchChanged }
          />
        </FlexPullRight>
      ) }
    </Flex>
    {
      selectedRows
        ? (
          <Flex gap={ 1 }>
            <SelectionDescription
              selectedCount={ selectedRows }
              totalCount={ totalRows !== null ? totalRows : Infinity }
              currentPageCount={ currentPageRows }
              resultFiltered={ !!chips?.length }
            />
            {
              selectedRows === totalRows
                ? (
                  <Button
                    color="primary"
                    onClick={ onClearSelectionClicked }
                  >
                    <FormattedMessage
                      id="tableFilters.clearSelection"
                      description="Label for button to clear bulk action selection"
                      defaultMessage="Clear selection"
                    />
                  </Button>
                )
                : (
                  <Button
                    color="primary"
                    onClick={ onExpandSelectionClicked }
                  >
                    <FormattedMessage
                      id="tableFilters.expandSelection"
                      description="Label for button to expand bulk action selection"
                      defaultMessage="Select all {totalRowsLoaded, select, false {} other {{totalRows}}} available rows"
                      values={ {
                        totalRows,
                        totalRowsLoaded: totalRows !== null,
                      } }
                    />
                  </Button>
                )
            }
          </Flex>
        )
        : !!(chips && chips.length && onRemoveChipClicked) && (
          <FilterChips
            chips={ chips }
            onClearFiltersClicked={ onClearFiltersClicked }
            onRemoveChipClicked={ onRemoveChipClicked }
          />
        )
    }
  </StyledTableFilters>
);
