import { FunctionComponent, ReactNode } from 'react';
import VerifiedIcon from 'op-storybook/lib/assets/icon/figma/mail-check.svg';
import UnverifiedIcon from 'op-storybook/lib/assets/icon/figma/mail-x.svg';
import { AuthFullscreenLayout } from 'op-storybook/lib/components/AuthFullscreenLayout/AuthFullscreenLayout';
import { AuthHeader } from 'op-storybook/lib/components/AuthHeader/AuthHeader';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

interface Props {
  success: boolean;
}

export const VerifyEmailResultPage: FunctionComponent<Props> = ({
  success,
}) => {
  const intl = useIntl();

  return (
    <AuthFullscreenLayout>
      <AuthHeader
        graphic={ {
          type: 'icon',
          props: {
            IconComponent: success
              ? VerifiedIcon
              : UnverifiedIcon,
            variant: 'light-circle-outline',
            size: 'lg',
            colour: success ? 'success' : 'error',
          },
        } }
        header={
          success
            ? intl.formatMessage({
              description: 'Header for email verification success page.',
              defaultMessage: 'Email verification successful',
            })
            : intl.formatMessage({
              description: 'Header for email verification failed page.',
              defaultMessage: 'Email verification failed',
            })
        }
        supportingText={
          success
            ? intl.formatMessage({
              description: 'Supporting text for email verification success page.',
              defaultMessage: 'You may now close this window.',
            })
            : intl.formatMessage({
              description: 'Supporting text for email verification failed page.',
              defaultMessage: 'Please try again later or request a new verification link from the <account-link>account page</account-link>.',
            }, {
              'account-link': (chunks: ReactNode[]) => (
                <Link to="/account/details">{ chunks }</Link>
              ),
            })
        }
      />
    </AuthFullscreenLayout>
  );
};
