import { FunctionComponent, ReactNode } from 'react';
import * as React from 'react';
import Button from '@mui/material/Button';

import { StyledContent, StyledHeading } from './styles';
import { CenteredDialog } from './CenteredDialog';

interface Props {
  container?: Element | React.Component | (() => Element | React.Component | null) | null;
  cta: string;
  cancel?: string;
  heading?: JSX.Element | string | ReactNode;
  headingSize?: 'large' | 'medium' | 'small';
  textAlign?: 'left' | 'center' | 'right' | 'justify';
  open: boolean;
  onConfirmed: () => void;
  onDismissed: () => void;
}

export const CenteredConfirmationDialog: FunctionComponent<Props> = ({
  children,
  onConfirmed,
  onDismissed,
  cancel,
  cta,
  heading,
  headingSize = 'large',
  textAlign = 'center',
  open,
  ...other
}) => {
  const handleConfirmed = (): void => {
    onConfirmed();
  };
  const handleDismissed = (): void => {
    onDismissed();
  };

  return (
    <CenteredDialog
      open={open}
      onDismiss={onDismissed}
      content={
        <>
          {
            heading && <StyledHeading size={headingSize}>{heading}</StyledHeading>
          }
          <StyledContent textAlign={textAlign}>{ children }</StyledContent>
        </>
      }
      actions={
        <>
          {
            cancel && <Button
              autoFocus
              color="secondary"
              onClick={ handleDismissed }
            >
              { cancel }
            </Button>
          }
          <Button
            autoFocus
            color="primary"
            onClick={ handleConfirmed }
          >
            { cta }
          </Button>
        </>
      }
      {...other}
    />
  );
}
