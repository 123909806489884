import { FC, ReactNode } from 'react';
import { BadgeCloseX } from 'op-storybook/lib/components/BadgeCloseX/BadgeCloseX';

import { Badge } from '../../Content/Badge/Badge';

export enum ChipState {
  EDITABLE,
  READ_ONLY,
  LOCKED,
}

type BaseProps = {
  startAdornment?: ReactNode;
  label: string;
}

type EditableProps = {
  state?: ChipState.EDITABLE;
  onRemoveClicked: () => void;
};

type NonEditableProps = {
  state: ChipState.READ_ONLY | ChipState.LOCKED;
  onRemoveClicked?: never;
}

type Props = BaseProps & (EditableProps | NonEditableProps);

export const Chip: FC<Props> = ({
  startAdornment,
  onRemoveClicked,
  state = ChipState.EDITABLE,
  label,
}) => (
  <Badge
    variant={ state === ChipState.LOCKED ? 'badge-colour' : 'badge-modern' }
    colour="grey"
    label={ label }
    startAdornment={ startAdornment }
    {
      ...state === ChipState.EDITABLE && onRemoveClicked
        ? {
          endAdornment: (
            <BadgeCloseX
              onClick={ onRemoveClicked }
            />
          ),
        }
        : {}
    }
  />
);
