import { ArrayHelper } from './ArrayHelper';

export type QueryWithKeys<T extends string> = {
  [K in T]?: string;
};

type QueryWithKey<T extends string> = { [key in T]?: string };

export class QueryParser {
  public static getCsvParseFn = <K extends string, V extends string>(key: K, filterOrValidValues: V[] | ((values: string[]) => V[])): (query: QueryWithKey<K>) => V[] => {
    const filter = Array.isArray(filterOrValidValues)
      ? ArrayHelper.createTypeFilter(ArrayHelper.createTypeGuard(filterOrValidValues))
      : filterOrValidValues;

    return (query: QueryWithKey<K>) => {
      const values = query[key]?.split(',') || [];
      return query[key] ? filter(values) : [];
    };
  };

  public static parseNumber = <T extends string>(query: { [key in T]?: string }, key: T, defaultValue: number): number => (
    query[key] && !isNaN(+query[key])
      ? +query[key]
      : defaultValue
  );

  public static getValueParser = <K extends string, V extends string>(key: K, validValues: V[], defaultValue: V): (query: QueryWithKey<K>) => V => {
    const typeGuard = ArrayHelper.createTypeGuard(validValues);

    return (query: QueryWithKey<K>) => {
      const value = query[key] as string;

      return value && typeGuard(value)
        ? value
        : defaultValue
    };
  };

  public static pageNum = (query: { pageNum?: string }, defaultValue = 1): number => (
    QueryParser.parseNumber(query, 'pageNum', defaultValue)
  );

  public static csvValueRemover = (key: string, valueToRemove: string): (query: Record<string, string>) => Record<string, string> => (
    ({ [key]: previousValues, ...query}) => {
      const values = previousValues?.split(',').filter(value => value !== valueToRemove) || [];
      return {
        ...query,
        ...(values.length ? { [key]: values.join(',') } : {}),
      };
    }
  );

  public static csvValueUpdater = (key: string, newValues: string[]): (query: Record<string, string>) => Record<string, string> => (
    query => {
      const { [key]: previousValues, ...remainingQuery } = query;
      const sortedNewValues = newValues.sort().join(',');
      const newQuery = {
        ...remainingQuery,
        ...(newValues.length ? { [key]: sortedNewValues } : {}),
      };

      if (!previousValues) {
        return newQuery;
      }

      const sortedPreviousValues = previousValues.split(',').sort().join(',');

      return sortedNewValues === sortedPreviousValues
        ? query
        : {
          ...remainingQuery,
          ...(newValues.length ? { [key]: sortedNewValues } : {}),
        };
    }
  )
}
