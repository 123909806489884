import { parseISO } from 'date-fns';
import { format, toZonedTime } from 'date-fns-tz';

export class DateTimeFormatter {
  public static formatInTimezone = (date: Date, dateFormat: string, timezone: string): string => (
    format(
      toZonedTime(
        parseISO(date.toISOString()),
        timezone
      ),
      dateFormat,
      { timeZone: timezone }
    )
  );
}
