import { FC, useMemo } from 'react';

import {
  StyledInnerNavigationButton,
  StyledNavigationGroup,
  StyledNavigationSubItem,
} from './style';
import { ConfiguredNavigationItem } from '../../../Model';
import { NavigationButton } from '../NavigationButton/NavigationButton';
import { NavigationItemIdentifier } from '../../../Utility';

type Props = {
  navigationItem: ConfiguredNavigationItem;
};

export const NavigationItem: FC<Props> = ({
  navigationItem,
}) => {
  const innerNavigationItem = useMemo(() => (
    <StyledInnerNavigationButton
      Icon={ navigationItem.Icon }
      unlockedIndex={ navigationItem.unlockedIndex }
      active={ NavigationItemIdentifier.navigationItemIsButton(navigationItem) && navigationItem.active }
      hide={ navigationItem.disabled }
    >
      { !navigationItem.disabled && navigationItem.label }
    </StyledInnerNavigationButton>
  ), [navigationItem]);

  return (
    NavigationItemIdentifier.navigationItemIsGroup(navigationItem)
      ? (
        <StyledNavigationGroup>
          { innerNavigationItem }
          <ul>
            { navigationItem.subItems.map((subItem, index) => (
              <li key={ index }>
                <NavigationButton onClick={ subItem.onClick }>
                  <StyledNavigationSubItem
                    active={ subItem.active }
                  >
                    { subItem.label }
                  </StyledNavigationSubItem>
                </NavigationButton>
              </li>
            )) }
          </ul>
        </StyledNavigationGroup>
      )
      : (
        <NavigationButton onClick={ navigationItem.onClick }>
          { innerNavigationItem }
        </NavigationButton>
      )
  );
};
