import * as React from 'react';
import { FC, PropsWithChildren } from 'react';
import { useDropzone } from 'react-dropzone';
import { FormattedMessage } from 'react-intl';
import { Heading } from '@ourpeople/shared/Core/Component/Content';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';
import { BreakpointContext } from 'op-storybook/lib/providers/BreakpointProvider/BreakpointProvider';

import UploadFileIcon from '../../../Assets/img/icons/streamline/upload-file.svg';
import { StyledDropzone } from './style';
import { Flex, PresentationIcon } from '..';
import { useContextOrThrow } from '../../../Core/Hook';

export type MultiDropzoneProps = {
  disabled?: boolean;
  maxSize?: number;
  prompt?: string;
  onDrop: (files: File[]) => void;
};

export const MultiDropzone: FC<PropsWithChildren<MultiDropzoneProps>> = ({
  maxSize = Infinity,
  disabled = false,
  onDrop,
  children,
}) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open,
  } = useDropzone({
    disabled,
    maxSize,
    multiple: true,
    noClick: true,
    onDrop,
  });
  const { lessThan } = useContextOrThrow(BreakpointContext);
  const isDialogEmpty = React.Children.count(children) === 0;
  const dialogHeading = () => {
    if (!isDialogEmpty && lessThan.xs) {
      return <></>;
    }

    if (lessThan.xs) {
      return (<Flex direction="row">
        <PresentationIcon
          IconComponent={UploadFileIcon}
          size="small"
          color="primary"
        />
        <Heading type="h4">
          <FormattedMessage
            id="dropzone.drop"
            description="Drop prompt while dragging in dropzone component."
            defaultMessage="Drop your file(s) here..." />
        </Heading>
      </Flex>)
    }

    return (<>
      <PresentationIcon
        IconComponent={UploadFileIcon}
        size="large"
        color="primary"
      />
      <FormattedMessage
        id="dropzone.drop"
        description="Drop prompt while dragging in dropzone component."
        defaultMessage="Drop your file(s) here..."
      />
    </>)
  }

  return (
    <StyledDropzone
      {...getRootProps({ isDragActive })}
    >
      <input {...getInputProps()} />
      <>
        {dialogHeading()}
        <>{children}</>
        <Button
          variant="primary"
          onClick={open}
        >
          <FormattedMessage
            id="dropzone.browse"
            description="Copy for browse button in file dropzone"
            defaultMessage="Browse to upload"
          />
        </Button>
      </>
    </StyledDropzone>
  );
};

