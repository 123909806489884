import { FormattedMessage } from 'react-intl';
import { FC } from 'react';

import { Flex, PresentationIcon } from '../../../Common/Component';
import { Switch } from '../../../Components';
import LockIcon from '../../../Assets/img/icons/streamline/shield-lock.svg';

type Props = {
  disabled?: boolean;
  checked: boolean;
  onChange: (checked: boolean) => void;
};

export const AnonymousResponsesSwitch: FC<Props> = ({
  disabled,
  checked,
  onChange,
}) => (
  <Flex gap={ 1 }>
    <PresentationIcon
      IconComponent={ LockIcon }
      size="medium"
      color="grey.500"
    />
    {
      disabled
        ? (
          <FormattedMessage
            id="form.fields.shared.anonymous.published"
            description="Label for published anonymous form."
            defaultMessage="{ checked, select, true {Collecting} other {Not collecting} } anonymous responses"
            values={ { checked } }
          />
        )
        : (
          <label htmlFor="anonymousSubmissionsEnabled">
            <Flex gap={ 1 }>
              <Switch
                id="anonymousSubmissionsEnabled"
                checked={ checked }
                onChange={ (_event, checked: boolean) => onChange(checked) }
              />
              <FormattedMessage
                id="form.fields.shared.anonymous.draft"
                description="Label for switch that toggles form anonymity."
                defaultMessage="Collect anonymous responses"
              />
            </Flex>
          </label>
        )
    }
  </Flex>
);
