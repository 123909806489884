import { ComponentProps, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  CircleStencil,
  Coordinates,
  Cropper as DefaultCropper,
  CropperRef,
  FixedCropper,
  FixedCropperRef,
  ImageRestriction,
  RectangleStencil,
} from 'react-advanced-cropper';
import 'react-advanced-cropper/dist/style.css';
import 'react-advanced-cropper/dist/themes/corners.css';
import { getAbsoluteZoom, getZoomFactor } from 'advanced-cropper/extensions/absolute-zoom';

type Dimensions = {
  height: number;
  width?: never;
  aspectRatio: number;
} | {
  height?: never;
  width: number;
  aspectRatio: number;
} | {
  height: number;
  width: number;
  aspectRatio?: never;
};

export type CropperTransforms = {
  rotate: number;
  flip: {
    horizontal: boolean;
    vertical: boolean;
  };
  coordinates: Coordinates
};

type Props = {
  src: string;
  mode?: 'fixed-circle' | 'fixed-square' | 'default';
  imageDimensions?: {
    width: number;
    height: number;
  };
  zoom?: {
    value: number;
    onChange: (value: number) => void;
  };
  flipHorizontal?: boolean;
  flipVertical?: boolean;
  rotations?: number;
  outputDimensions?: Dimensions;
  className?: string;
  onChange: (
    canvas: HTMLCanvasElement,
    transforms: CropperTransforms,
  ) => void;
};

export const Cropper: FC<Props> = ({
  src,
  mode = 'default',
  zoom,
  flipHorizontal = false,
  flipVertical = false,
  rotations,
  outputDimensions,
  className,
  onChange,
}) => {
  const fixed = mode === 'fixed-square' || mode === 'fixed-circle';
  const debounceTimeout = useRef<number | null>(null);
  const cropperRef = useRef<FixedCropperRef>(null);
  const [flipPerformed, setFlipPerformed] = useState<boolean>(true);
  const stencilProps = {
    handlers: mode === 'default',
    lines: mode === 'default',
    movable: mode === 'default',
    resizable: mode === 'default',
    grid: mode === 'default',
    ...(outputDimensions ? {
        aspectRatio: outputDimensions.aspectRatio === undefined
          ? outputDimensions.width / outputDimensions.height
          : outputDimensions.aspectRatio,
      } : {}),
  };

  useEffect(() => {
    setFlipPerformed(false);
  }, [flipVertical, flipHorizontal]);

  useEffect(() => {
    if (!cropperRef.current || rotations === undefined) {
      return;
    }

    const transforms = cropperRef.current.getTransforms();
    const targetAngle = rotations * 90;
    const shouldRotate = transforms.rotate !== targetAngle;

    if (!shouldRotate) {
      return;
    }

    cropperRef.current.rotateImage(targetAngle - transforms.rotate);
  }, [cropperRef.current, rotations]);

  useEffect(() => {
    if (!cropperRef.current || flipPerformed) {
      return;
    }

    const transforms = cropperRef.current.getTransforms();
    const perpendicular = transforms.rotate % 180 === 90;
    const shouldFlipHorizontal = (perpendicular ? transforms.flip.vertical : transforms.flip.horizontal) !== flipHorizontal;
    const shouldFlipVertical = (perpendicular ? transforms.flip.horizontal : transforms.flip.vertical) !== flipVertical;
    cropperRef.current.getTransitions();

    if (!shouldFlipHorizontal && !shouldFlipVertical) {
      return;
    }

    setFlipPerformed(true);
    cropperRef.current.flipImage(shouldFlipHorizontal, shouldFlipVertical);
  }, [cropperRef.current, flipHorizontal, flipVertical, flipPerformed]);

  useEffect(() => {
    if (!cropperRef.current || zoom?.value === undefined) {
      return;
    }

    const state = cropperRef.current.getState();
    const settings = cropperRef.current.getSettings();

    cropperRef.current.zoomImage(getZoomFactor(state, settings, zoom.value));
  }, [cropperRef.current, zoom?.value]);

  const whenTransformComplete = useCallback((cropperRef: CropperRef) => {
    if (!cropperRef || !zoom) {
      return;
    }

    const state = cropperRef.getState();
    const settings = cropperRef.getSettings();
    const currentZoom = getAbsoluteZoom(state, settings);

    zoom.onChange(currentZoom);
  }, [zoom?.onChange]);

  const whenChanged: ComponentProps<typeof DefaultCropper>['onChange'] = useCallback((cropper: CropperRef) => {
    debounceTimeout.current && window.clearTimeout(debounceTimeout.current);
    debounceTimeout.current = window.setTimeout(() => {
      const width = outputDimensions
        ? outputDimensions.width === undefined
          ? outputDimensions.height * outputDimensions.aspectRatio
          : outputDimensions.width
        : undefined;
      const height = outputDimensions
        ? outputDimensions.height === undefined
          ? outputDimensions.width * outputDimensions.aspectRatio
          : outputDimensions.height
        : undefined;

      const canvas = cropper.getCanvas(
        width && height
          ? {
            width,
            height,
          }
          : {},
      );

      if (!canvas) {
        return;
      }

      const coordinates = cropper.getState()?.coordinates;

      if (!coordinates) {
        throw new Error('Missing crop region coordinates, these should always be defined.');
      }

      const transforms: CropperTransforms = {
        ...cropper.getTransforms(),
        coordinates,
      };

      onChange(canvas, transforms);
    }, 300)
  }, [onChange, outputDimensions]);

  // Emit initial transformation for cases where the user confirms the initial bounding box.
  useEffect(() => {
    if (!cropperRef.current) {
      return;
    }

    whenChanged(cropperRef.current);
  }, [cropperRef, whenChanged]);

  const commonProps = useMemo(() => ({
    className,
    src,
    stencilProps,
    imageRestriction: fixed ? ImageRestriction.stencil : ImageRestriction.fitArea,
    onChange: whenChanged,
    onTransformImage: whenTransformComplete,
  }), [stencilProps, whenChanged, className, src, mode, whenTransformComplete]);

  return (
    fixed
      ? (
        <FixedCropper
          ref={ cropperRef }
          stencilSize={ {
            width: 512,
            height: 512,
          } }
          stencilComponent={
            mode === 'fixed-circle'
              ? CircleStencil
              : RectangleStencil
          }
          { ...commonProps }
        />
      )
      : (
        <DefaultCropper
          ref={ cropperRef }
          { ...commonProps }
        />
      )
  );
};
