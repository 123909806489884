import styled from '@emotion/styled';

export const StyledFormAndCategoryInput = styled.div`
  h3 {
    font-weight: 400;
    font-size: 1.35rem;
    line-height: 1;
  }

  h4 {
    font-weight: 500;
    font-size: 1rem;
  }

  p {
    color: ${ props => props.theme.palette.primary.main };
    margin-top: 0.5rem;
    cursor: pointer;
    font-size: 0.9rem;
  }

  .MuiTextField-root {
    margin-bottom: 0.5rem;
  }
`;
