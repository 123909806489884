import { FunctionComponent, ReactNode } from 'react';
import * as React from 'react';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { StyledCenteredDialog } from './styles';

type Props = {
  container?: Element | React.Component | (() => Element | React.Component | null) | null;
  actions: JSX.Element,
  title?: string,
  content: string | ReactNode,
  open: boolean,
  onDismiss: () => void,
}

export const CenteredDialog: FunctionComponent<Props> = ({
  title,
  content,
  actions,
  open,
  onDismiss,
  ...other
}) => {
  return (
    <StyledCenteredDialog
      open={ open }
      onClose={ onDismiss }
      {...other}
    >
      { title && (
        <DialogTitle>
          {title}
        </DialogTitle>
      )}
      <DialogContent>
        {content}
      </DialogContent>
      <DialogActions>
        { actions }
      </DialogActions>
    </StyledCenteredDialog>
  );
}
