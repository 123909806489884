import { ComponentProps, PropsWithChildren } from 'react';
import styled from '@emotion/styled';

import { Typography } from './Typography';
import { ThemeHelper } from '../../../Utility/ThemeHelper';

export const StyledTypography = styled.span<PropsWithChildren<Omit<Required<ComponentProps<typeof Typography>>, 'children'>>>`
  font-family: ${ props => props.theme.new.typography[props.type].family };
  font-weight: ${ props => props.theme.new.typography[props.type].weight[props.weight] };
  font-size: ${ props => props.theme.new.typography[props.type].size[props.size].fontSize };
  letter-spacing: ${ props => props.theme.new.typography[props.type].size[props.size].tracking };
  line-height: ${ props => props.theme.new.typography[props.type].size[props.size].lineHeight };
  color: ${ props => ThemeHelper.lookUpColour(props.color, props.theme.new) };
  ${ props => props.transition && `
    transition: color 0.2s;
  ` };
  text-align: ${ props => props.align };
  
  strong {
    font-weight: ${ props => props.theme.new.typography[props.type].weight[
      props.weight === 'regular'
        ? 'medium'
        : props.weight === 'medium'
          ? 'semibold'
          : 'bold'
    ] };
  }
`;
