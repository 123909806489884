import { FunctionComponent, ReactNode, useEffect, useMemo, useState } from 'react';
import { Alert as MaterialAlert } from '@mui/material';
import { Color } from '@mui/lab';
import { Snackbar } from '@mui/material';

import { PopOverState } from '../../Common/Model';

export class SuccessToast implements ToastMessage {
  readonly severity = 'success';
  constructor(readonly content: ReactNode) {}
}

export class FailureToast implements ToastMessage {
  readonly severity = 'error';
  constructor(readonly content: ReactNode) {}
}

export type ToastMessage = {
  severity: Color;
  content: ReactNode;
}

type Props = {
  messages: ToastMessage[];
  onMessagesChanged: (messages: ToastMessage[]) => void;
};

export const Toast: FunctionComponent<Props> = ({
  messages,
  onMessagesChanged,
}) => {
  const [dialogState, setDialogState] = useState<PopOverState>(PopOverState.CLOSED);
  const latestMessage = useMemo(() => messages[messages.length - 1], [messages])

  useEffect(() => {
    if (!latestMessage) {
      return;
    }

    setDialogState(PopOverState.OPEN);
  }, [latestMessage, messages]);

  return (
    <>
      { dialogState !== PopOverState.CLOSED && (
        <Snackbar
          anchorOrigin={ { vertical: 'bottom', horizontal: 'left' } }
          open={ dialogState === PopOverState.OPEN }
          autoHideDuration={ 3000 }
          onClose={ () => setDialogState(PopOverState.WILL_CLOSE) }
          TransitionProps={ {
            onExited: () => {
              setDialogState(PopOverState.CLOSED);
              onMessagesChanged(messages.filter(message => message !== latestMessage));
            },
          } }
        >
          <MaterialAlert
            variant="filled"
            severity={ latestMessage.severity }
          >
            { latestMessage.content }
          </MaterialAlert>
        </Snackbar>
      ) }
    </>
  );
};
