import styled from '@emotion/styled';

export const StyledLoadingFacade = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${ props => props.theme.palette.navy.main };
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
