import { useIntl } from 'react-intl';
import { useMemo } from 'react';

import HomeIcon from '../../Assets/img/icons/streamline/house-chimney-2.svg';
import EventsIcon from '../../Assets/img/icons/streamline/calendar-3.svg';
import InboxIcon from '../../Assets/img/icons/streamline/envelope.svg';
import BroadcastIcon from '../../Assets/img/icons/streamline/layers.svg';
import ChatIcon from '../../Assets/img/icons/streamline/messages-bubble.svg';
import FilesIcon from '../../Assets/img/icons/streamline/folder-empty.svg';
import FormsIcon from '../../Assets/img/icons/streamline/task-checklist-check.svg';
import { DisabledBehaviour, NavigationItem } from '../Model';

export const useAppNavigationItems = (): NavigationItem[] => {
  const intl = useIntl();
  return useMemo<NavigationItem[]>(() => ([
    {
      ref: 'home',
      label: intl.formatMessage({
        description: 'Label for home navigation item in app.',
        defaultMessage: 'Home',
      }),
      Icon: HomeIcon,
      onClick: () => null,
      active: true,
      count: null,
      disabledBehaviour: DisabledBehaviour.DISABLE,
      shortLabel: null,
    },
    {
      ref: 'events',
      label: intl.formatMessage({
        description: 'Label for events navigation item in app.',
        defaultMessage: 'Events',
      }),
      Icon: EventsIcon,
      onClick: () => null,
      active: false,
      count: null,
      disabledBehaviour: DisabledBehaviour.ALLOW,
      shortLabel: null,
    },
    {
      ref: 'cards',
      label: intl.formatMessage({
        description: 'Label for inbox navigation item in app.',
        defaultMessage: 'Inbox',
      }),
      Icon: InboxIcon,
      onClick: () => null,
      active: false,
      count: null,
      disabledBehaviour: DisabledBehaviour.DISABLE,
      shortLabel: null,
    },
    {
      ref: 'chat',
      label: intl.formatMessage({
        description: 'Label for chat navigation item in app.',
        defaultMessage: 'Chat',
      }),
      Icon: ChatIcon,
      onClick: () => null,
      active: false,
      count: null,
      disabledBehaviour: DisabledBehaviour.ALLOW,
      shortLabel: null,
    },
    {
      ref: 'files',
      label: intl.formatMessage({
        description: 'Label for files navigation item in app.',
        defaultMessage: 'Files',
      }),
      Icon: FilesIcon,
      onClick: () => null,
      active: false,
      count: null,
      disabledBehaviour: DisabledBehaviour.ALLOW,
      shortLabel: null,
    },
    {
      ref: 'broadcasts',
      subItems: [
        {
          label: 'Create Broadcast',
          onClick: () => null,
          active: false,
          count: null,
        },
        {
          label: 'Sent broadcasts',
          onClick: () => null,
          active: false,
          count: null,
        },
      ],
      label: 'Broadcasts',
      disabledBehaviour: DisabledBehaviour.DISABLE,
      Icon: BroadcastIcon,
      shortLabel: null,
    },
    {
      ref: 'forms',
      label: intl.formatMessage({
        description: 'Label for forms navigation item in app when displayed in side menu.',
        defaultMessage: 'Smart Forms',
      }),
      Icon: FormsIcon,
      onClick: () => null,
      active: false,
      count: null,
      disabledBehaviour: DisabledBehaviour.DISABLE,
      shortLabel: intl.formatMessage({
        description: 'Label for forms navigation item in app when displayed in tab bar.',
        defaultMessage: 'Forms',
      }),
    },
  ]), [intl]);
};
