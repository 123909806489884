import { FetchResponse, useGet } from '../../Hooks';
import { Invitation } from '../Model';

type DeliverableCounts = {
  recipients: number;
  delivered: number;
  failed: number;
  pending: number;
}

export type InvitationReport = {
  invitation: Invitation;
  counts: {
    recipients: number;
    included: number;
    ignored: number;
    pending: number;
    email: DeliverableCounts;
    sms: DeliverableCounts;
  };
  email: {
    id: string;
    subject: string;
    body: {
      plain: string;
      html: string;
    }
  } | null;
  sms: {
    id: string;
    message: string;
  } | null;
}

export const useFetchInvitationReport = (invitationId: string): FetchResponse<InvitationReport> => useGet(`/invitations/${invitationId}/report`);
