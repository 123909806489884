import { ChangeEventHandler, FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { TextField } from '@mui/material';
import { useIntl } from 'react-intl';

import { StyledFormAndCategoryInput } from './style';
import { Category, DraftSingleContentForm, PublishStatus } from '../../Model';
import { AnonymousResponsesSwitch, CategoryAutocomplete } from '..';
import { ValidationTree } from '../../../Common/Model';
import { FieldValidationErrors, Flex } from '../../../Common/Component';
import { FormValidator } from '../../Utility/Validation/FormValidator';

interface Props {
  form: DraftSingleContentForm;
  onChange: (form: DraftSingleContentForm) => void;
  publishStatus: PublishStatus;
  validation: ValidationTree<DraftSingleContentForm>
  onValidationChanged: (validation: ValidationTree<DraftSingleContentForm>) => void;
}

export const NameAndCategoryInput: FunctionComponent<Props> = ({
  form,
  onChange,
  publishStatus,
  validation,
  onValidationChanged,
}) => {
  const intl = useIntl();
  const formName = form.name || '';
  const categoryId = form.categoryId;
  const [inputFormName, setInputFormName] = useState<string>(formName || '');
  const categorySelection = useMemo<string[]>(
    () => categoryId ? [categoryId] : [],
    [categoryId],
  );
  const nameErrors = validation.children.name?.errors || [];
  const categoryErrors = validation.children.categoryId?.errors || [];
  const whenCategoryChanged = useCallback((category?: Category) => {
    if (!category || category.id === categoryId) {
      return;
    }

    onChange({
      ...form,
      categoryId: category.id,
    });
  }, [categoryId, form, onChange]);

  useEffect(() => {
    if (inputFormName === formName) {
      return;
    }

    onChange({
      ...form,
      name: inputFormName || null
    });
  }, [formName, onChange, inputFormName, form]);

  const whenFormNameInputChanged: ChangeEventHandler<HTMLInputElement> = (event) => {
    setInputFormName(event.currentTarget.value || '');
  };

  const whenNameBlurred = () => whenValidationChanged({
    name: FormValidator.validateName(form),
  });

  const whenCategoryBlurred = () => whenValidationChanged({
    categoryId: FormValidator.validateCategory(form),
  });

  const whenValidationChanged = useCallback(
    (children: ValidationTree<DraftSingleContentForm>['children']): void => {
      if (publishStatus === 'published') {
        onValidationChanged({
          errors: validation.errors,
          children: {
            ...validation.children,
            ...children,
          }
        });
      }
    },
    [validation, onValidationChanged, publishStatus],
  );

  const whenAnonymousToggled = useCallback((checked: boolean) => (
    onChange({
      ...form,
      anonymousSubmissionsEnabled: checked,
    })
  ), [form, onChange]);

  return (
    <StyledFormAndCategoryInput>
      <>
        <TextField
          size="small"
          color="primary"
          label={ intl.formatMessage({
            id: 'form.fields.shared.formName.label',
            defaultMessage: 'Form name',
          }) }
          value={ inputFormName }
          error={ !!nameErrors.length }
          onChange={ whenFormNameInputChanged }
          onBlur={ whenNameBlurred }
        />
        <FieldValidationErrors
          fieldName={ intl.formatMessage({
            id: 'form.fields.shared.formName.name',
            defaultMessage: 'form name',
            description: 'Name used in errors for "form name" field when creating a form'
          }) }
          validationErrors={ nameErrors }
        />
      </>
      <Flex gap={ 2 }>
        <div>
          <CategoryAutocomplete
            disableClearable={ true }
            selectedIds={ categorySelection }
            onSelectionChanged={ selection => whenCategoryChanged(selection.options[0]) }
            multiple={ false }
            error={ !!categoryErrors.length }
            onBlur={ whenCategoryBlurred }
          />
          <FieldValidationErrors
            fieldName={ intl.formatMessage({
              id: 'form.fields.shared.category.name',
              defaultMessage: 'category',
              description: 'Name used in errors for "category" field when creating a form'
            }) }
            validationErrors={ categoryErrors }
          />
        </div>
        { (publishStatus === 'draft' || form.anonymousSubmissionsEnabled) && (
          <AnonymousResponsesSwitch
            disabled={ publishStatus !== 'draft' }
            checked={ form.anonymousSubmissionsEnabled }
            onChange={ whenAnonymousToggled }
          />
        ) }
      </Flex>
    </StyledFormAndCategoryInput>
  );
};

