import { ComponentProps, FC } from 'react';
import { OtpField } from 'op-storybook/stories/components/OtpField/OtpField';
import { BreakpointContext } from 'op-storybook/lib/providers/BreakpointProvider/BreakpointProvider';

import { useContextOrThrow } from '../../../Core/Hook';

type Props = Omit<ComponentProps<typeof OtpField>, 'size'>;

export const ResponsiveOtpField: FC<Props> = props => {
  const screenWidth = useContextOrThrow(BreakpointContext);

  return (
    <OtpField
      size={ screenWidth.lessThan.md ? screenWidth.lessThan.sm ? 'xs' : 'sm' : 'md' }
      { ...props }
    />
  );
}
