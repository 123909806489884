import { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { TooLongError, ValidationError } from '../../../Common/Model';
import { InsufficientRoleError, NotUniqueError, UnexpectedStringError } from '../../Model';
import { ImportErrorPresentationComponent } from '../../../Imports/Model';
import { UserRole } from '../../../Components';
import { useLocalisedPeopleRecordTypes } from '../../Hook';
import { VerticallySpaced } from '../../../Common/Component';

export const PeopleImportRecordErrors: ImportErrorPresentationComponent = ({ errors, columnMapping }) => {
  const intl = useIntl();
  const recordTypes = useLocalisedPeopleRecordTypes();
  const localisedMappingType = columnMapping?.mapping?.type && recordTypes.find(recordType => recordType.id === columnMapping?.mapping?.type)?.localisation;

  const getErrorMessage = (error: ValidationError): ReactNode => {
    switch (error.type) {
      case 'blank':
        return (
          <FormattedMessage
            id="people.import.blankError"
            description="Error when a required column is blank."
            defaultMessage="{ type, select, undefined {This column} other {{type}} } is required."
            values={{
              type: localisedMappingType,
            }}
          />
        );
      case 'missingTeam':
        return (
          <FormattedMessage
            id="people.import.missingTeamError"
            description="Error when a column requires a team."
            defaultMessage="Profiles must have at least one team."
          />
        );
      case 'idNotFound':
        switch (columnMapping?.mapping?.type) {
          case 'id':
            return (
              <FormattedMessage
                id="people.import.personIdNotFoundError"
                description="Error when person ID was not found."
                defaultMessage="A person with this ID could not be found."
              />
            );
          case 'profileId':
            return (
              <FormattedMessage
                id="people.import.profileIdNotFoundError"
                description="Error when profile ID was not found."
                defaultMessage="A profile with this ID could not be found."
              />
            );
          default:
            return (
              <FormattedMessage
                id="people.import.idNotFoundError"
                description="Error when ID was not found."
                defaultMessage="This ID could not be found."
              />
            );
        }
      case 'notPhoneNumber':
        return (
          <FormattedMessage
            id="people.import.notPhoneNumberError"
            description="Error when phone number is invalid."
            defaultMessage="Phone number must be a valid E.164 number."
          />
        );
      case 'notEmailAddress':
        return (
          <FormattedMessage
            id="people.import.notEmailAddressError"
            description="Error when email address is invalid."
            defaultMessage="Invalid email address."
          />
        );
      case 'unexpectedString':
        switch (columnMapping?.mapping?.type) {
          case 'role':
            return (
              <FormattedMessage
                id="people.import.unexpectedRoleError"
                description="Error when a role is not in the list of valid roles."
                defaultMessage="{ actual } is not a valid role, please make sure your role is { expected }."
                values={ {
                  actual: (error as UnexpectedStringError).metadata.actual,
                  expected: intl.formatList((error as UnexpectedStringError).metadata.expected, { type: 'disjunction' }),
                } }
              />
            );
          case 'jobtitle':
            return (
              <FormattedMessage
                id="people.import.unexpectedJobTitleError"
                description="Error when a job title is not already present in the system."
                defaultMessage="{ actual } is not a valid job title. Job titles must be created prior to import."
                values={ {
                  actual: (error as UnexpectedStringError).metadata.actual,
                } }
              />
            );
          case 'skill':
            return (
              <FormattedMessage
                id="people.import.unexpectedSkillError"
                description="Error when a skill is not already present in the system."
                defaultMessage="{ actual } is not a valid skill. Skills must be created prior to import."
                values={ {
                  actual: (error as UnexpectedStringError).metadata.actual,
                } }
              />
            );
          case 'team':
            return (
              <FormattedMessage
                id="people.import.unexpectedTeamError"
                description="Error when a team is not already present in the system."
                defaultMessage="{ actual } is not a valid team. Teams must be created prior to import."
                values={ {
                  actual: (error as UnexpectedStringError).metadata.actual,
                } }
              />
            );
          default:
            return (
              <FormattedMessage
                id="people.import.unexpectedStringError"
                description="Error when a value is not valid."
                defaultMessage="{ actual } is not valid{ hasExpectedValues, select, true {, expected { expected }.} other {.}}"
                values={ {
                  actual: (error as UnexpectedStringError).metadata.actual,
                  hasExpectedValues: !!(error as UnexpectedStringError).metadata.expected.length,
                  expected: intl.formatList((error as UnexpectedStringError).metadata.expected, { type: 'disjunction' }),
                } }
              />
            );
        }
      case 'notUnique':
        switch (columnMapping?.mapping?.type) {
          case 'emailAddress':
            return (
              <FormattedMessage
                id="people.import.notUniqueEmailError"
                description="Error when an email address is not unique."
                defaultMessage="Multiple people can not share an email address, but { index, select, unknown {this email address is already in use by another person} other {the person at row {index} also has this email address}}."
                values={ { index: (error as NotUniqueError).metadata?.otherRecordIndex || 'unknown' } }
              />
            );
          case 'mobileNumber':
            return (
              <FormattedMessage
                id="people.import.notUniquePhoneNumberError"
                description="Error when a phone number is not unique."
                defaultMessage="Multiple people can not share a phone number, but { index, select, unknown {this phone number is already in use by another person} other {the person at row {index} also has this phone number}}."
                values={ { index: (error as NotUniqueError).metadata?.otherRecordIndex || 'unknown' } }
              />
            );
          default:
            return (
              <FormattedMessage
                id="people.import.notUniqueError"
                description="Error when a record value is not unique."
                defaultMessage="A unique value was expected but { index, select, unknown {another person} other {row {index}}} also has this value."
                values={ { index: (error as NotUniqueError).metadata?.otherRecordIndex || 'unknown' } }
              />
            );
        }
      case 'tooLong':
        return (
          <FormattedMessage
            id="people.import.tooLongError"
            description="Error when value is too long."
            defaultMessage="Value must be under { maxLength } characters."
            values={ (error as TooLongError).metadata }
          />
        );
      case 'insufficientRole':
        return (
          <FormattedMessage
            id="people.import.insufficientRoleError"
            description="Error when user does not have required role."
            defaultMessage="{ requiredRole } role required."
            values={ {
              requiredRole: <UserRole role={(error as InsufficientRoleError).metadata.requiredRole}/>,
            } }
          />
        );
      default:
        return (
          <FormattedMessage
            id="people.import.fallbackPrepareError"
            description="Fallback prepare error when error can not be identified."
            defaultMessage="There is a problem with this record."
          />
        );
    }
  };

  return (
    <VerticallySpaced gap={ 1 }>
      { errors.map(error => (
        <div key={ error.type }>
          { getErrorMessage(error) }
        </div>
      )) }
    </VerticallySpaced>
  );
};
