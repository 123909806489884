import {ValidationError} from "../model/Validation/ValidationError";
import {ValidationErrorDefinition} from "../model/Validation/Definition/ValidationErrorDefinition";

export class ValidationErrorDefinitionFactory
{
  public static build<E extends ValidationError = ValidationError>(
      type: E['type'],
      formatMessage: ValidationErrorDefinition<E>['formatMessage'],
  ): ValidationErrorDefinition<E> {
    return {
      type,
      formatMessage,
    };
  }
}
