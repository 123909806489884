import { ComponentProps, FC } from 'react';
import { AsynchronousAvatar } from 'op-storybook/stories/components/AsynchronousAvatar/AsynchronousAvatar';

type Props = {
  personUuid: string | null;
  personName: string | null;
  versionString?: string;
} & Omit<ComponentProps<typeof AsynchronousAvatar>, 'name' | 'src' | 'shouldWait' | 'fallbackSeed'>

export const PersonUuidAvatar: FC<Props> = ({
  personUuid,
  personName,
  versionString,
  ...avatarProps
}) => (
  <AsynchronousAvatar
    name={ personName }
    src={ personUuid ? `/api/people/${ personUuid }/avatar/redirect${ versionString ? `?version=${ versionString }` : '' }` : null }
    fallbackSeed={ personUuid || undefined }
    { ...avatarProps }
  />
);
