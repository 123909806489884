import { FC, FormEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { SsoButtons } from 'op-storybook/lib/components/SsoButtons/SsoButtons';
import { Form } from 'op-storybook/lib/components/Form/Form';
import { AuthHeader } from 'op-storybook/lib/components/AuthHeader/AuthHeader';
import { PasswordField } from 'op-storybook/stories/components/PasswordField/PasswordField';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { Username, UsernameField } from 'op-storybook/stories/components/UsernameField/UsernameField';
import { UsernameValidator } from 'op-storybook/lib/utility/UsernameValidator/UsernameValidator';
import { useUsernameValidationErrors } from 'op-storybook/lib/hooks/useUsernameValidationErrors';
import { ValidationError } from 'op-storybook/lib/model/Validation/ValidationError';
import { PolicyLinks } from 'op-storybook/lib/components/PolicyLinks/PolicyLinks';

import { AnonymousSettings } from '../../../Security/Hook';
import { useLoginRequest } from '../../../Security/Hook/useLoginRequest';
import { RequestState } from '../../../Models';
import { PasswordLoginSubmitButton } from '../PasswordLoginSubmitButton/PasswordLoginSubmitButton';
import { OtpLoginLink } from '../OtpLoginLink/OtpLoginLink';

type Props = {
  anonymousSettings: AnonymousSettings;
  loginTargetUrl: string;
};

export const UnidentifiedUserLoginForm: FC<Props> = ({
  anonymousSettings,
  loginTargetUrl,
}) => {
  const intl = useIntl();
  const { login, loginRequest } = useLoginRequest();
  const [username, setUsername] = useState<Username>({ value: '' });
  const [password, setPassword] = useState<string>('');
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const ssoProviders = useMemo(() => (
    anonymousSettings.ssoOptions.map(ssoOption => ({
      issuer: ssoOption.issuer,
      url: `${ ssoOption.authUrl }&targetUri=${ loginTargetUrl }`,
    }))
  ), [anonymousSettings.ssoOptions, loginTargetUrl]);
  const [usernameErrors, setUsernameErrors] = useState<ValidationError[]>([]);
  const localisedUsernameErrors = useUsernameValidationErrors().formatErrorMessages(usernameErrors);

  // Clear errors when username is changed
  useEffect(() => {
    setUsernameErrors([]);
  }, [username]);

  const whenSubmitted: FormEventHandler<HTMLFormElement> = useCallback(event => {
    event.preventDefault();

    const usernameErrors = UsernameValidator.validate(username);

    if (usernameErrors.length) {
      setUsernameErrors(usernameErrors);
      return;
    }

    login(
      username.parsedPhone?.e164Value
        ? {
          mobileNumber: username.parsedPhone.e164Value,
          password,
        }
        : {
          emailAddress: username.value,
          password,
        },
      rememberMe,
    );
  }, [username, login, password, rememberMe]);

  return (
    <>
      <AuthHeader
        header={ intl.formatMessage({
          description: 'Heading text on login page where user is not identified.',
          defaultMessage: 'Log in to { spaceName }',
        }, {
          spaceName: anonymousSettings.name,
        }) }
        supportingText={ intl.formatMessage({
          description: 'Supporting text on login page where user is not identified.',
          defaultMessage: 'Welcome! Please enter your email address or phone number.',
        }) }
        graphic={ {
          type: 'logo',
          props: {
            src: anonymousSettings.smallLogoUrl,
            alt: anonymousSettings.name,
            backgroundColour: 'teal',
          },
        } }
      />
      <Form onSubmit={ whenSubmitted }>
        <Stack
          direction="column"
          gap={ 4 }
        >
          <UsernameField
            username={ username }
            onChange={ setUsername }
            defaultCountryCode={ anonymousSettings.defaultCountryCode }
            errorMessages={ localisedUsernameErrors }
          />
          <PasswordField
            value={ password }
            onChange={ setPassword }
          />
          <PasswordLoginSubmitButton
            disabled={ !username.value || !password || !!usernameErrors.length }
            submitting={ loginRequest.state === RequestState.FETCHING }
            showRememberMe={true}
            rememberMe={ rememberMe }
            onRememberMeChange={ setRememberMe }
            error={ loginRequest.state === RequestState.FAILED }
            forgotPasswordLink={ {
              enabled: true,
              username,
            } }
          />
        </Stack>
      </Form>
      { !!ssoProviders.length && <SsoButtons providers={ ssoProviders }/> }
      <OtpLoginLink username={ username }/>
      <PolicyLinks
        policyLinks={ anonymousSettings.appUsagePolicyLinks }
        spaceName={ anonymousSettings.name }
      />
    </>
  );
};
