import { FC, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Box as MaterialBox, Button as MuiButton } from '@mui/material';
import {
  CenteredGenericErrorMessage,
  CenteredMessage,
  CenteredMessageWithLoadingCard
} from '@ourpeople/shared/Core/Component/Content';
import { BreakpointContext } from 'op-storybook/lib/providers/BreakpointProvider/BreakpointProvider';
import { Button } from 'op-storybook/stories/components/Button/Button';

import { Box, FlexPullRight, PageHeader, PresentationIcon } from '../../../Common/Component';
import { Pagination } from '../../../Components';
import { StyledCategoryName, StyledFormButton, StyledFormList, StyledMyFormsPage } from './style';
import { FetchMyFormsParams, useFetchPersonalForms } from '../../Hook';
import { PublishedForm } from '../../Model';
import { ArrayHelper, QueryParser, QueryWithKeys } from '../../../Common/Utility';
import ChevronIcon from '../../../Assets/img/icons/streamline/arrow-right-1.svg';
import AnonymousIcon from '../../../Assets/img/icons/streamline/shield-lock.svg';
import { useQueryAsState } from '../../../Hooks';
import { FormContainer } from '../../Component';
import { useContextOrThrow } from '../../../Core/Hook';

type CategoryWithForms = {
  id: string;
  name: string;
  forms: PublishedForm[];
};

type Params = {
  formId?: string;
};

type Query = QueryWithKeys<'pageNum'>;

export const PersonalFormsPage: FC = () => {
  const intl = useIntl();
  const [query, setQuery] = useQueryAsState<Query>();
  const { formId } = useParams<Params>();
  const { search } = useLocation();
  const screenWidth = useContextOrThrow(BreakpointContext);
  const params = useMemo<FetchMyFormsParams>(() => ({
    pageNum: QueryParser.pageNum(query),
    sort: 'category_name_asc,name_asc',
  }), [query]);
  const [fetchFormsResult, , reloadForms] = useFetchPersonalForms(params);
  const pagination = fetchFormsResult?.content?.pagination;
  const categoriesWithForms = useMemo<CategoryWithForms[]>(() => (
    (fetchFormsResult?.content?.forms || [])
      .reduce<CategoryWithForms[]>(
        (categoriesWithForms, form) => {
          const categoryIndex = categoriesWithForms.findIndex(categoryWithForms => categoryWithForms.id === form.category.id);
          const category = categoriesWithForms[categoryIndex] || {
            id: form.category.id,
            name: form.category.name,
            forms: [],
          };
          const updatedCategory = {
            ...category,
            forms: category.forms.concat(form),
          };

          return categoryIndex !== -1
            ? ArrayHelper.replace(categoriesWithForms, categoryIndex, updatedCategory)
            : categoriesWithForms.concat(updatedCategory);
        },
        [],
      )
  ), [fetchFormsResult?.content?.forms]);

  const whenPageNumChanged = useCallback((pageNum: number) => (
    setQuery({
      pageNum: `${ pageNum }`,
    })
  ), [setQuery]);

  return (
    <Box
      padded={ false }
      margin={ false }
      css={ {
        height: '100%',
      } }
    >
      <StyledMyFormsPage>
        { (!screenWidth.lessThan.sm || !formId) && (
          <StyledFormList
            data-testid="forms-list"
          >
            <div>
              <PageHeader
                items={ [
                  {
                    link: '/my-forms',
                    title: intl.formatMessage({
                      description: 'Heading label for My forms',
                      defaultMessage: 'My forms'
                    }),
                  },
                ] }
                css={ theme => ({
                  margin: 0,
                  padding: theme.new.spacing[2],
                }) }
              />
              <ul>
                {
                  fetchFormsResult?.content
                    ? categoriesWithForms.length
                      ? categoriesWithForms.map(category => (
                        <li key={ category.id }>
                          <StyledCategoryName>
                            <div>
                              { category.name }
                            </div>
                          </StyledCategoryName>
                          <ul>
                            { category.forms.map(form => (
                              <StyledFormButton
                                active={ form.id === formId }
                                key={ form.id }
                              >
                                <MuiButton
                                  color="inherit"
                                  component={ Link }
                                  to={ {
                                    pathname: `/my-forms/${ form.id }`,
                                    search,
                                  } }
                                >
                                  <span>
                                    { form.name }
                                    <FlexPullRight gap={ 2 }>
                                      { form.anonymousSubmissionsEnabled && (
                                        <MaterialBox color="primary.main">
                                          <AnonymousIcon
                                            aria-label={ intl.formatMessage({
                                              description: 'Label for anonymous icon in personal forms list.',
                                              defaultMessage: 'Anonymous',
                                            }) }
                                          />
                                        </MaterialBox>
                                      ) }
                                      <PresentationIcon
                                        IconComponent={ ChevronIcon }
                                        size="xs"
                                      />
                                    </FlexPullRight>
                                  </span>
                                </MuiButton>
                              </StyledFormButton>
                            )) }
                          </ul>
                        </li>
                      ))
                      : (
                        <CenteredMessage
                          heading={ intl.formatMessage({
                            description: 'Error message heading when personal forms list is empty.',
                            defaultMessage: 'No forms available',
                          }) }
                          body={ intl.formatMessage({
                            description: 'Error message body when personal forms list is empty.',
                            defaultMessage: 'You don\'t have access to any published forms today.',
                          }) }
                        />
                      )
                    : fetchFormsResult?.error
                      ? <CenteredGenericErrorMessage onRetryClicked={ reloadForms }/>
                      : (
                        <CenteredMessageWithLoadingCard
                          heading={ intl.formatMessage({
                            description: 'Heading for message when personal forms list is loading.',
                            defaultMessage: 'Your forms are coming!',
                          }) }
                          body={ intl.formatMessage({
                            description: 'Body for message when personal forms list is loading.',
                            defaultMessage: 'Watch this space.',
                          }) }
                        />
                      )
                }
              </ul>
            </div>
            { pagination && (
              <div css={ theme => ({ padding: theme.new.spacing[2] }) }>
                <Pagination
                  pagination={ pagination }
                  pageNum={ +(query.pageNum || '1') }
                  onPageChanged={ whenPageNumChanged }
                  siblingCount={ 0 }
                  boundaryCount={ 1 }
                />
              </div>
            ) }
          </StyledFormList>
        ) }
        { (!screenWidth.lessThan.sm || formId) && (
          <div
            css={ {
              paddingTop: 'var(--header-height)',
              display: 'grid',
              gridTemplateRows: 'auto 1fr',
              height: '100%',
            } }
          >
            <div css={ theme => ({ height: '56px', padding: theme.new.spacing[2] }) }>
              { formId && (
                <Button
                  variant="tertiary"
                  component={ Link }
                  to="/my-forms"
                  css={ {
                    width: 'max-content',
                  } }
                >
                  Back
                </Button>
              ) }
            </div>
            <FormContainer
              formId={ formId }
              data-testid="forms-preview"
            />
          </div>
        ) }
      </StyledMyFormsPage>
    </Box>
  );
};
