import styled from '@emotion/styled';

import { Menu } from '../Menu/Menu';

export const StyledAutocompletePopper = styled(Menu)`
  .MuiAutocomplete-option, .MuiAutocomplete-listbox, .MuiAutocomplete-option {
    padding: 0;
    background-color: ${ props => props.theme.new.basePalette.white.main };
  }

  .MuiPaper-root {
    margin: 0;
    background-color: ${ props => props.theme.new.basePalette.white.main };
  }
`;
