import { useCallback } from 'react';

import { Upload } from '../../Types';
import { useMounted } from '.';
import { useApi } from '../../Core/Hook';
import { FileUploadError } from '../Component';
import { authoriseAndUpload } from '../../Core/Utility/authoriseAndUpload';

export type AuthoriseData = { libraryRef: string } | { editedUploadId: string };

type InitiateUploadFunction = (
  authoriseEndpoint: string,
  file: File,
  onComplete: (upload: Upload) => void,
  onError: (error: FileUploadError) => void,
  onProgress: (progress: number) => void,
  authoriseData?: AuthoriseData,
) => () => void;

export const useAuthoriseAndUpload = (): InitiateUploadFunction => {
  const mounted = useMounted();
  const api = useApi();

  return useCallback((
    authoriseEndpoint,
    file,
    onComplete,
    onError,
    onProgress,
    authoriseData,
  ) => {
    return authoriseAndUpload(
      api,
      authoriseEndpoint,
      file,
      upload => {
        if (!mounted.current) {
          return;
        }

        onComplete(upload);
      },
      error => {
        if (!mounted.current) {
          return;
        }

        onError(error as FileUploadError);
      },
      onProgress,
      authoriseData,
    );
  }, [api, mounted]);
};
