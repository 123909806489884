import { FC, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { StackEnd } from 'op-storybook/lib/components/StackEnd/StackEnd';
import { IconButton } from 'op-storybook/stories/components/IconButton/IconButton';
import EditIcon from 'op-storybook/lib/assets/icon/figma/pencil-01.svg';

import { StatusCondition } from '../../../../Audiences/Model';
import { StatusCloud } from './StatusCloud';
import { ConditionToggleButton } from '../ConditionToggleButton';

type Props = {
  condition: StatusCondition;
  onChange: (condition: StatusCondition) => void;
  onEditClicked: () => void;
};

export const StatusConditionComponent: FC<Props> = ({
  condition,
  onChange,
  onEditClicked,
}) => {
  return (
    <div>
      <Stack>
        <FormattedMessage
          description="Summary text for status tag audience condition."
          defaultMessage="{ statusCount, plural, one {<toggle>{ not, select, true {Doesn't have} other {Has} }</toggle> the following status} other {Has <toggle>{ not, select, true {none} other {any} }</toggle> of the following statuses} }"
          values={ {
            toggle: (parts: ReactNode[]) => (
              <ConditionToggleButton
                onClick={ () => onChange({
                  ...condition,
                  not: !condition.not,
                }) }
              >
                { parts }
              </ConditionToggleButton>
            ),
            statusCount: condition.statuses.length,
            not: condition.not,
          } }
        />
        <StackEnd>
          <IconButton
            variant="tertiary-grey"
            onClick={ onEditClicked }
            IconComponent={ EditIcon }
          />
        </StackEnd>
      </Stack>
      <StatusCloud
        condition={ condition }
      />
    </div>
  );
};
