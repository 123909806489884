import { FC, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import * as ReactGA from 'react-ga';
import { InitializeOptions } from 'react-ga';
import { useLocation } from 'react-router-dom';

import { GoogleAnalyticsContext, SpaceContext } from '../Context';

interface  Props {
  trackingId?: string;
  options?: InitializeOptions;
  children?: ReactNode;
}

export const GoogleAnalyticsProvider: FC<Props> = ({
  children,
  trackingId,
  options,
}) => {
  const location = useLocation();
  const previousUrl = useRef<string|null>(null);
  const { spaceId } = useContext(SpaceContext);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!trackingId) {
      return;
    }

    ReactGA.initialize(trackingId, options);
    setInitialized(true);
  }, [trackingId, options]);

  useEffect(() => {
    if (!initialized) {
      return;
    }

    ReactGA.set({
      dimension1: spaceId,
    });
  }, [spaceId, initialized]);

  useEffect(() => {
    const url = location.pathname + location.search;
    if (!initialized || url === previousUrl.current) {
      return;
    }

    ReactGA.pageview(url);
    previousUrl.current = url;
  }, [location, previousUrl, initialized]);

  return (
    <GoogleAnalyticsContext.Provider value={{
      ga: ReactGA,
    }}>
      { children }
    </GoogleAnalyticsContext.Provider>
  );
};
