import { FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { parseISO } from 'date-fns';

import {
  BroadcastContentFooter,
  ContentPadding,
  NudgeBanner,
  RespondButton,
  StackNavigationProps,
  StarRating
} from '../..';
import { RespondButtonProps } from '..';
import { RATING_REQUEST_MAX_RATING } from '../../../../Broadcasts/Service';
import { CardTitle, ScrollableContent } from '../../../../Broadcasts/Component/Content';
import { VerticallySpaced } from '../../../../Common/Component';
import { BroadcastNudge } from '../../../../Broadcasts/Model';
import { AbsoluteBackButtonContainer } from '../AbsoluteBackButtonContainer/AbsoluteBackButtonContainer';

type Props = {
  title: string;
  rating: number | null;
  responded?: boolean;
  onRate: (rating: number) => void;
  submitButtonProps: RespondButtonProps;
  submitHidden?: boolean;
  navigationProps: StackNavigationProps;
  nudge?: BroadcastNudge;
  backButton: ReactNode | undefined;
};

export const RatingBroadcastContent: FC<Props> = ({
  title,
  rating,
  responded,
  onRate,
  submitButtonProps,
  submitHidden,
  navigationProps,
  nudge,
  backButton,
}) => {
  const intl = useIntl();

  return (
    <>
      { backButton && <AbsoluteBackButtonContainer>{ backButton }</AbsoluteBackButtonContainer> }
      <ScrollableContent>
        {
          nudge && (
            <NudgeBanner
              message={ nudge.message }
              nudgedAt={ parseISO(nudge.at) }
            />
          )
        }
        <ContentPadding>
          <VerticallySpaced gap={ 6 }>
            <CardTitle>{ title }</CardTitle>
            <StarRating
              maxRating={ RATING_REQUEST_MAX_RATING }
              value={ rating }
              onValueChanged={ onRate }
              disabled={ responded }
            />
          </VerticallySpaced>
        </ContentPadding>
      </ScrollableContent>
      <BroadcastContentFooter { ...navigationProps }>
        { !submitHidden && (
          <RespondButton
            variant="primary"
            respondedLabel={ intl.formatMessage({
              id: 'content.rating.responded',
              description: 'Label for respond button in rating content when response has been submitted.',
              defaultMessage: 'Rated',
            }) }
            notRespondedLabel={ intl.formatMessage({
              id: 'content.rating.notResponded',
              description: 'Label for respond button in rating content when response has not been submitted.',
              defaultMessage: 'Submit',
            }) }
            rejectedLabel={ intl.formatMessage({
              id: 'content.rating.rejected',
              description: 'Label for respond button in rating content when response has been rejected.',
              defaultMessage: 'Try again',
            }) }
            { ...submitButtonProps }
          />
        ) }
      </BroadcastContentFooter>
    </>
  );
};
