import { format, isThisYear } from 'date-fns';

export class DateTimeFormatter {
  static standard = (date: Date): string => {
    return format(date, 'EEE dd MMM yyyy, h:mm') + format(date, 'a').toLowerCase();
  };

  static internationalised = (date: Date, locale: string): string => {
    return (new Intl.DateTimeFormat(locale, {
      weekday: 'short',
      day: 'numeric',
      month: 'short',
      hour: 'numeric',
      minute: 'numeric',
      ...(isThisYear(date) ? {} : {
          year: 'numeric'
        }),
    })).format(date);
  };

  static short = (date: Date, locale: string): string => {
    return (new Intl.DateTimeFormat(locale, {
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    })).format(date)
  };

  static internationalisedDate = (date: Date, locale: string): string => {
    return (new Intl.DateTimeFormat(locale, {
      weekday: 'short',
      day: 'numeric',
      month: 'short',
      ...(isThisYear(date) ? {} : {
          year: 'numeric'
        }),
    })).format(date);
  };

  static dateTimeInput = (date: Date): string => format(date, 'yyyy-MM-dd HH:mm');

  static dateInput = (date: Date): string => format(date, 'yyyy-MM-dd');

  static timeInput = (date: Date): string => format(date, 'HH:mm');
}
