import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

import { AuthorisedUpload, Upload } from '../Types';

export type OnUploadProgress = (progress: number) => void;

export class Uploader {
  public uploadFile (
    authorisedUpload: AuthorisedUpload,
    file: File,
    onProgress?: OnUploadProgress,
    abortObserver?: Observable<void>,
  ): Promise<Upload> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = () => reject();
      reader.readAsArrayBuffer(file);
    })
      .then(fileData => this.uploadData(
        authorisedUpload,
        file.name,
        fileData,
        onProgress,
        abortObserver,
      ));
  }

  public uploadData(
    authorisedUpload: AuthorisedUpload,
    fileName: string,
    fileData: string|ArrayBuffer,
    onProgress?: OnUploadProgress,
    abortObserver?: Observable<void>,
  ): Promise<Upload> {
    const formData = new FormData();
    Object.keys(authorisedUpload.request.fields)
      .forEach(key => formData.append(key, authorisedUpload.request.fields[key]));

    const jsonBlob = new Blob([fileData]);
    formData.append(authorisedUpload.request.fileFieldKey, jsonBlob, fileName);

    /* eslint-disable @typescript-eslint/no-unsafe-return */
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open(authorisedUpload.request.method, authorisedUpload.request.url);
      xhr.onload = (event) => {
        if ((event.target as XMLHttpRequest).status >= 400) {
          reject(event.target);
        } else {
          resolve(event.target);
        }
      };
      xhr.onerror = reject;
      if (onProgress) {
        xhr.upload.onprogress = event => onProgress(event.loaded / event.total);
      }
      if (abortObserver) {
        abortObserver
          .pipe(
            first(),
          )
          .subscribe(() => xhr.abort());
      }
      xhr.send(formData);
    })
      .then(response => JSON.parse((response as XMLHttpRequest).responseText));
  }
}
