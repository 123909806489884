import styled from '@emotion/styled';

export const StyledUnlockedItemCircle = styled.div`
  width: ${ props => props.theme.spacing(7) };
  height: ${ props => props.theme.spacing(7) };
  border-radius: ${ props => props.theme.borderRadius.max };
  border: 2px solid ${ props => props.theme.palette.primary.main };
  position: absolute;
`;

export const StyledUnlockedItemIndex = styled.div`
  width: ${ props => props.theme.spacing(2.5) };
  height: ${ props => props.theme.spacing(2.5) };
  border-radius: ${ props => props.theme.borderRadius.max };
  color: ${ props => props.theme.palette.primary.contrastText };
  background-color: ${ props => props.theme.palette.primary.main };
  position: absolute;
  top: ${ props => props.theme.spacing(-0.5) };
  left: ${ props => props.theme.spacing(-0.5) };
  display: flex;
  justify-content: center;
  align-items: center;
`;
