import { FC } from 'react';

import { LiveComponentProps } from '../../../../Content/Model';
import { VideoContent } from '../../../Model/ContentTypes/VideoContent';
import { ClickableVideoThumbnail } from '../../Content/ClickableVideoThumbnail/ClickableVideoThumbnail';

export const LiveVideoContent: FC<LiveComponentProps<VideoContent>> = ({
  card,
}) => (
  <ClickableVideoThumbnail
    duration={ card.content.upload.video?.duration || 0 }
    thumbnailKey={ card.content.thumbnailKey }
    uploadId={ String(card.content.upload.id) }
  />
);
