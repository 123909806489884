import styled from '@emotion/styled';

export const StyledVideoContentPreview = styled.div`
  padding: ${ props => `${ props.theme.spacing(6) } ${ props.theme.spacing(2) }` };
  width: 100%;
`;

export const StyledThumbnailPlaceholder = styled.div`
  height: 180px;
  width: 100%;
  background-color: #000000;
  display: flex;
  align-items: flex-start;
  padding: ${ props => props.theme.spacing(1) };
`;
