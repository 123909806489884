import { PropsWithChildren, ReactNode } from 'react';

import { SimpleTable } from '../../../Components';

interface Props<RowDataType> {
  rowRender: (rowData: RowDataType) => ReactNode;
  rows: RowDataType[];
  headerRow: ReactNode;
}

export const TableMetric = <RowDataType, >({
  headerRow,
  rows,
  rowRender,
}: PropsWithChildren<Props<RowDataType>>): JSX.Element => (
  <SimpleTable
    headerRow={ headerRow }
    rows={ rows }
    rowRender={ rowRender }
  />
);
