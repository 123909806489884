import { FC, PropsWithChildren } from 'react';

import { StyledBadge } from './style';

type Props = {
  className?: string;
  inverted?: boolean;
};

/** @deprecated Prefer ../Badge/Badge.tsx where new design is appropriate */
export const Badge: FC<PropsWithChildren<Props>> = ({
  className,
  inverted = false,
  children,
}) => (
  <StyledBadge
    inverted={ inverted }
    className={ className }
  >
    { children }
  </StyledBadge>
);
