import { ComponentProps, FC } from 'react';

import { StyledTextContentWithoutFooter } from './style';
import { RichTextContentPreview } from '../../..';
import { LinkContent } from '../../LinkContent/LinkContent';

type Props = {
  html: string;
  placeholderText?: string;
  linkProps: ComponentProps<typeof LinkContent> | null;
};

export const TextContentWithoutFooter: FC<Props> = ({
  html,
  placeholderText,
  linkProps,
}) => (
  <StyledTextContentWithoutFooter>
    <RichTextContentPreview
      text={ placeholderText ? html.replace(placeholderText, '') : html }
    />
    { linkProps && (
      <LinkContent
        { ...linkProps }
      />
    ) }
  </StyledTextContentWithoutFooter>
);
