import styled from '@emotion/styled';
import { darken } from 'polished';

import { ButtonBase } from '../../../stories/components/ButtonBase/ButtonBase';

type Props = {
  color?: string;
};

export const StyledSsoButton = styled(ButtonBase)<Props>`
  svg {
    height: ${ props => props.theme.new.spacing[6] };
    width: auto;
  }
  
  ${ props => props.color && `
    background-color: ${ props.color };
    color: ${ props.theme.new.basePalette.white.main };
    border: none;
    
    &:active, &:hover {
      background-color: ${ darken(0.05, props.color) };
    }
  ` };

  ${ props => props.disabled && `
    opacity: 0.75;
  ` };
`;
