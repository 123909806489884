import { FC } from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';

import { GuardedRoute } from '../../../Common/Component';
import { InboxListPage } from '..';
import { BroadcastsPermission } from '../../../Broadcasts/Model';

export const InboxRouter: FC = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <GuardedRoute
        path={ `${ match.path }/:deliveryId?` }
        redirect="/"
        permissions={[
          BroadcastsPermission.ME,
        ]}
      >
        <InboxListPage/>
      </GuardedRoute>
    </Switch>
  );
};
