import { useEffect } from 'react';

import { SubmittedComponent } from '../../../../Content/Model';
import { RatingRequest as RatingRequestModel } from '../../../Model/ContentTypes';
import { RatingResponse } from '../../../Service';
import { RatingRequest } from '../../Content';
import { NoResponseMessage } from '../NoResponseMessage/NoResponseMessage';

export const SubmittedRatingRequest: SubmittedComponent<RatingResponse, RatingRequestModel> = ({
  card,
  response,
  onFinish,
}) => {
  useEffect(() => {
    if (!onFinish) {
      return;
    }

    onFinish();
  }, [onFinish]);

  return (
    response
      ? (
        <RatingRequest
          content={ card.content }
          value={ response.rating }
          readOnly
        />
      )
      : <NoResponseMessage/>
  );
};
