import { IntlShape } from 'react-intl';

import { AudienceConditionDefinition, ConditionContentsSummary, EveryoneCondition } from '../../Model';

/** @deprecated Used in old audience builder */
export class EveryoneConditionDefinition implements AudienceConditionDefinition<
  EveryoneCondition,
  null
> {
  public readonly TYPE = 'everyone';

  public definesConditionType(conditionType: string): boolean {
    return conditionType === this.TYPE;
  }

  public contentsSummary(
    intl: IntlShape,
    condition: EveryoneCondition,
    conditionContents: null,
  ): ConditionContentsSummary {
    return {
      exclusive: false,
      soloSummary: intl.formatMessage({
        id: 'audience.conditionDefinition.everyone.contentsSummary.solo',
        defaultMessage: 'Everyone',
      }),
      andSummary: null,
    };
  }
}
