import { TextField } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { IntlShape } from 'react-intl/src/types';

import { MappedSettingTypes } from '../../Types';
import { ValidationErrorMessage } from '../../../../../Common/Component';

type Props = {
  value: MappedSettingTypes['url']['value'],
  config: MappedSettingTypes['url']['config'],
  onValueChanged: (value: MappedSettingTypes['url']['value']) => void,
  label?: string,
  focusId?: string,
};

export const UrlSettingInput = ({
  value,
  config,
  onValueChanged,
  label,
  focusId,
}: Props): JSX.Element => {
  const intl = useIntl();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const error = validate(value, config, intl);
    setError(error);
  }, [value, config, intl]);

  const whenValueChanged = (event: ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    onValueChanged(value);
  }

  return (
    <div>
      <TextField
        id={focusId}
        type="url"
        variant="outlined"
        fullWidth={true}
        error={error !== null}
        value={value || ''}
        label={label}
        onChange={whenValueChanged}
      />
      {
        error
        && (
          <ValidationErrorMessage>{error}</ValidationErrorMessage>
        )
      }
    </div>
  );
};

const validate = (
  value: MappedSettingTypes['url']['value'],
  config: MappedSettingTypes['url']['config'],
  intl: IntlShape,
): string | null => {
  if (value === null && config.nullable) {
    return null;
  }

  if (value === null) {
    return intl.formatMessage({
      id: 'settings.urlSetting.nullError',
      defaultMessage: 'Missing value',
    });
  }

  try {
    new URL(value);
  } catch (error) {
    return intl.formatMessage(
      {
        id: 'settings.urlSetting.invalidUrl',
        defaultMessage: 'Must be a valid URL',
      },
    );
  }

  if (
    !config.allowInsecure
    && value.substr(0, 6) !== 'https:'
  ) {
    return intl.formatMessage(
      {
        id: 'settings.urlSetting.insecureError',
        defaultMessage: 'Must be a secure URL',
      },
    );
  }

  return null;
}
