import styled from '@emotion/styled';
import { TabPanel } from '@mui/lab';
import { DialogContent } from '@mui/material';

export const StyledTabPanel = styled(TabPanel)`
  padding: 0;
`;

export const StyledDialogContent = styled(DialogContent)`
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  padding: ${ props => props.theme.spacing(2) };
`;
