import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@mui/material';

import { Audience } from '../../Model';
import { AudienceBuilder } from '../Builder/AudienceBuilder/AudienceBuilder';
import { ValidationTree } from '../../../Common/Model';
import { Flex, FlexPullRight, VerticallySpaced } from '../../../Common/Component';
import { HotReachSegment } from '../HotReachSegment/HotReachSegment';

type Props = {
  audienceSize?: number;
  audienceSizeLinkUrl?: string;
  audience: Audience;
  onChange?: (audience: Audience) => void;
  validation: ValidationTree<Audience> | undefined;
  editing: boolean;
  showReach: boolean;
  onEditButtonClicked: () => void;
  onRevertButtonClicked: () => void;
  multipleSegments?: boolean;
  readonly?: boolean;
  alwaysEdit: boolean;
};

export const AudienceEditor: FunctionComponent<Props> = ({
  audienceSize,
  audienceSizeLinkUrl,
  audience,
  onChange,
  validation,
  editing,
  showReach,
  onEditButtonClicked,
  onRevertButtonClicked,
  multipleSegments = false,
  readonly,
  alwaysEdit,
}) => (
  <VerticallySpaced gap={ 2 }>
    { editing && showReach && <HotReachSegment/> }
    <AudienceBuilder
      audienceSize={ audienceSize }
      audienceSizeLinkUrl={ audienceSizeLinkUrl }
      audience={ audience }
      onChange={ audience => onChange && onChange(audience) }
      validation={ validation }
      multipleSegments={ multipleSegments }
      readonly={ readonly || !editing }
    />
    { !alwaysEdit && (
      <Flex>
        <FlexPullRight>
          { !editing
            ? (
              <Button
                color="primary"
                variant="outlined"
                onClick={ onEditButtonClicked }
              >
                <FormattedMessage
                  id="audiences.audienceBuilder.enable"
                  description="Label for button to enable editing of audience."
                  defaultMessage="Edit audience"
                />
              </Button>
            )
            : (
              <Button
                color="primary"
                onClick={ onRevertButtonClicked }
              >
                <FormattedMessage
                  id="audiences.audienceBuilder.cancel"
                  description="Label for button to cancel editing of audience."
                  defaultMessage="Cancel editing"
                />
              </Button>
            ) }
        </FlexPullRight>
      </Flex>
    ) }
  </VerticallySpaced>
);
