import { useIntl } from 'react-intl';
import { useMemo } from 'react';

import { MinimalForm } from '../Model';

type ReturnValue = {
  name: string,
};

export const useFormReader = (form: MinimalForm | null): ReturnValue => {
  const intl = useIntl();

  return useMemo<ReturnValue>(() => ({
    name: form?.name || intl.formatMessage({
      id: 'form.name.untitled',
      description: 'Fallback name for untitled forms',
      defaultMessage: 'Untitled form',
    }),
  }), [form?.name, intl]);
};
