import { IntlShape } from 'react-intl';

import { SingleContentCard } from '../../../../Content/Model';
import FileIcon from '../../../../Assets/img/icons/monochrome/content/file.svg';
import { FileContentEditor, FileContentPreview } from '../../../Component';
import { MediaContentValidator } from '../../Validators';
import { BroadcastContentDefinition, DraftMediaContent, MediaContent, MediaEditorContent } from '../../../Model';
import { MediaContentHelper } from '../../../Utility';
import { ViewResponse } from '../../../../Inbox/Model';
import { LiveFileContent } from '../../../../Inbox/Component';
import { EnvironmentSettings } from '../../../../Models';

export type DraftFileContent = DraftMediaContent<'filecontent'>;
export type FileContent = MediaContent<'filecontent'>;
export type FileEditorContent = MediaEditorContent<'filecontent'>

const contentType = 'filecontent';
export class FileContentDefinition implements BroadcastContentDefinition<FileContent, FileEditorContent, ViewResponse> {
  public readonly id = 'file';
  public readonly availableToCreate = true;
  public readonly new = false;
  public readonly richText = false;
  public readonly contentTypes = [contentType];
  public readonly categoryId = 'content';
  public readonly IconComponent = FileIcon;
  public readonly EditorComponent = FileContentEditor;
  public readonly PreviewComponent = FileContentPreview;
  public readonly LiveComponent = LiveFileContent;
  public readonly SubmittedComponent = (): null => null;
  public validate = MediaContentValidator.validate;

  public definesContentType = (type: string): boolean => (
    this.contentTypes.includes(type)
  );

  public getContentTitle = (intl: IntlShape, content: FileContent): string => (
    content.text || this.getDefaultTitle(intl)
  );

  public getLocalisedType = (intl: IntlShape): string => (
    intl.formatMessage({
      id: 'broadcasts.content.file.type',
      description: 'Localised name for file content type.',
      defaultMessage: 'File',
    })
  );

  public getDraftContentTitle = (intl: IntlShape, draftContent: DraftFileContent): string => (
    draftContent.text || this.getDefaultTitle(intl)
  );

  public hasRequiredFeatureFlags = (environmentSettings: EnvironmentSettings): boolean => (
    !!environmentSettings.fileTypeEnabled
  );

  public createEditorContent = (): FileEditorContent => MediaContentHelper.createEditorContent(contentType);

  public transformCard = (card: SingleContentCard<FileContent>): FileEditorContent => MediaContentHelper.transformCard(contentType, card);

  private getDefaultTitle = (intl: IntlShape): string => (
    intl.formatMessage({
      id: 'broadcasts.content.file.defaultTitle',
      description: 'Default title for file content.',
      defaultMessage: 'Untitled file card',
    })
  );
}
