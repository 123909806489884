import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { differenceInDays, parseISO } from 'date-fns';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { ConfirmationDialog } from '@ourpeople/shared/Core/Component/Feedback';

import { TableCell, TableRow } from '../../../Components';
import { DateTime, StickyCell } from '../../../Common/Component';
import { PersonParser } from '../../../Utility';
import { FormContent, SingleContentSubmission } from '../../Model';
import { ResponseCell, SubmissionStatus } from '..';
import { useCancellableDelete, useCancellablePost } from '../../../Hooks';
import DeleteIcon from '../../../Assets/img/icons/streamline/bin-1.svg';
import CloseIcon from '../../../Assets/img/icons/streamline/cross.svg';
import OpenIcon from '../../../Assets/img/icons/streamline/success.svg';
import ExpandIcon from '../../../Assets/img/icons/streamline/expand-3.svg';
import { SingleContentCard } from '../../../Content/Model';
import { TableRowContextMenu } from '../../../Common/Component/TableRowContextMenu/TableRowContextMenu';
import { useContextOrThrow } from '../../../Core/Hook';
import { ToastContext } from '../../../Core/Context';

interface Props {
  cards: SingleContentCard<FormContent>[];
  submission: SingleContentSubmission;
  statusTrackerEnabled?: boolean;
  onActionComplete: () => void;
  disableActions?: boolean;
  hideActions?: boolean;
}

export const SubmissionTableRow: FunctionComponent<Props> = ({
  cards,
  submission,
  statusTrackerEnabled,
  onActionComplete,
  disableActions,
  hideActions,
}) => {
  const intl = useIntl();
  const { addErrorToast, addSuccessToast } = useContextOrThrow(ToastContext);
  const [willCallSuccessCallback, setWillCallSuccessCallback] = useState<boolean>(false);
  const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = useState<boolean>(false);

  const whenActionSuccessful = useCallback(() => {
    setWillCallSuccessCallback(true);
  }, []);

  const whenActionFailed = useCallback(() => (
    addErrorToast(
      intl.formatMessage({
        description: 'Error message when a submission action fails.',
        defaultMessage: 'Submission could not be updated, please try again.',
      })
    )
  ), [addErrorToast, intl]);

  useEffect(() => {
    if (!willCallSuccessCallback) {
      return;
    }

    let cancelled = false;

    setTimeout(() => {
      if (cancelled) {
        return;
      }

      addSuccessToast(
        intl.formatMessage({
          description: 'Error message when a submission action succeeds.',
          defaultMessage: 'Submission updated successfully.',
        })
      );
      setWillCallSuccessCallback(false);
      onActionComplete();
    }, 100);

    return () => {
      cancelled = true;
    };
  });

  const [whenShouldClose, closing] = useCancellablePost(
    `/forms/submissions/${ submission.formSubmission.id }/close`,
    whenActionSuccessful,
    whenActionFailed,
  );

  const [whenShouldOpen, opening] = useCancellablePost(
    `/forms/submissions/${ submission.formSubmission.id }/open`,
    whenActionSuccessful,
    whenActionFailed,
  );

  const [whenShouldDelete, deleting] = useCancellableDelete(
    `/forms/submissions/${ submission.formSubmission.id }`,
    whenActionSuccessful,
    whenActionFailed,
  );

  const whenCloseClicked = useCallback(() => {
    if (disableActions) {
      return;
    }

    whenShouldClose();
  }, [disableActions, whenShouldClose]);

  const whenOpenClicked = useCallback(() => {
    if (disableActions) {
      return;
    }

    whenShouldOpen();
  }, [disableActions, whenShouldOpen]);

  const whenDeleteClicked = useCallback(() => {
    if (disableActions) {
      return;
    }

    setDeleteConfirmationDialogOpen(true);
  }, [disableActions]);

  const whenDeleteConfirmed = useCallback(() => {
    setDeleteConfirmationDialogOpen(false);
    whenShouldDelete();
  }, [whenShouldDelete]);

  const whenDeleteCancelled = useCallback(() => {
    setDeleteConfirmationDialogOpen(false);
  }, []);

  const actions = useMemo(() => [
    ...[
      {
        id: 'view',
        IconComponent: ExpandIcon,
        ...(disableActions ? {
            onClick: (): null => null,
          } : {
            component: Link,
            to: `report/${ submission.formSubmission.id }`,
          }),
        label: intl.formatMessage({
          description: 'Label for view action in form submission table.',
          defaultMessage: 'View submission',
        }),
      }
    ],
    ...(statusTrackerEnabled ? [
        ...(submission.formSubmission.closed ? [
            {
              id: 'open',
              IconComponent: OpenIcon,
              onClick: whenOpenClicked,
              label: intl.formatMessage({
                description: 'Label for open action in form submission table.',
                defaultMessage: 'Open submission',
              }),
              busy: opening,
            }
          ] : [
            {
              id: 'close',
              IconComponent: CloseIcon,
              onClick: whenCloseClicked,
              label: intl.formatMessage({
                description: 'Label for close action in form submission table.',
                defaultMessage: 'Close submission',
              }),
              busy: closing,
            }
          ]),
      ] : []),
    {
      id: 'delete',
      IconComponent: DeleteIcon,
      onClick: whenDeleteClicked,
      label: intl.formatMessage({
        description: 'Label for delete action in form submission table.',
        defaultMessage: 'Delete',
      }),
      busy: deleting,
    }
  ], [
    closing,
    deleting,
    disableActions,
    intl,
    opening,
    statusTrackerEnabled,
    submission.formSubmission.closed,
    submission.formSubmission.id,
    whenCloseClicked,
    whenDeleteClicked,
    whenOpenClicked
  ]);

  return (
    <TableRow key={ submission.formSubmission.id }>
      { !submission.formSubmission.anonymous && (
        <>
          <StickyCell
            minwidth="10rem"
            hideBorder
          >
            { PersonParser.fullName(submission.formSubmission.created.by) }
          </StickyCell>
          <StickyCell
            minwidth="12rem"
            offset="10rem"
            hideBorder={ statusTrackerEnabled }
          >
            <DateTime dateTime={ submission.formSubmission.created.at }/>
          </StickyCell>
        </>
      ) }
      { statusTrackerEnabled && (
        <>
          <StickyCell
            minwidth="6rem"
            offset={ submission.formSubmission.anonymous ? undefined : '22rem' }
          >
            <SubmissionStatus closed={ submission.formSubmission.closed }/>
          </StickyCell>
          { !submission.formSubmission.anonymous && (
            <TableCell>
              { differenceInDays(
                submission.formSubmission.closed
                  ? parseISO(submission.formSubmission.closed.at)
                  : new Date(),
                parseISO(submission.formSubmission.created.at)
              ) }
            </TableCell>
          ) }
        </>
      ) }
      {
        cards.map((card, index) => {
          const cardSubmission = submission.cardSubmissions.find(cardSubmission => cardSubmission.cardId === card.id);
          return (
            cardSubmission && cardSubmission.response
              ? (
                <ResponseCell
                  key={ card.id }
                  response={ cardSubmission.response }
                />
              )
              : <TableCell key={ index }/>
          )
        })
      }
      { statusTrackerEnabled && (
        submission.formSubmission.closed
          ? (
            <>
              <TableCell>
                { PersonParser.fullName(submission.formSubmission.closed.by) }
              </TableCell>
              <TableCell>
                <DateTime dateTime={ submission.formSubmission.closed.at }/>
              </TableCell>
            </>
          )
          : <><TableCell/><TableCell/></>
      ) }
      { !hideActions && (
        <StickyCell justify="end">
          <TableRowContextMenu
            id={ submission.formSubmission.id }
            actions={ actions }
            inlineActionCount={ 1 }
          />
          <ConfirmationDialog
            open={ deleteConfirmationDialogOpen }
            title={
              intl.formatMessage({
                id: 'form.report.deleteConfirmationTitle',
                description: 'Confirmation dialog title when attempting to delete a form submission.',
                defaultMessage: 'Delete submission',
              })
            }
            description={
              intl.formatMessage({
                id: 'form.report.deleteConfirmation',
                description: 'Confirmation dialog description when attempting to delete a form submission.',
                defaultMessage: 'Are you sure you want to delete this submission? This action can not be undone.',
              })
            }
            onCancel={ whenDeleteCancelled }
            onClose={ whenDeleteCancelled }
            onConfirm={ whenDeleteConfirmed }
            confirmCta={
              intl.formatMessage({
                id: 'form.report.deleteConfirmationCta',
                description: 'Confirmation dialog confirm option CTA.',
                defaultMessage: 'Delete',
              })
            }
          />
        </StickyCell>
      ) }
    </TableRow>
  );
}
