import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { SearchField } from '@ourpeople/shared/Core/Component/Input/SearchField/SearchField';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { ProgressCircle } from 'op-storybook/stories/components/ProgressCircle/ProgressCircle';
import { EmptyState } from 'op-storybook/stories/components/EmptyState/EmptyState';
import ImageIcon from 'op-storybook/lib/assets/icon/figma/image-01.svg';
import { PresentationIcon } from 'op-storybook/lib/components/PresentationIcon/PresentationIcon';
import UploadIcon from 'op-storybook/lib/assets/icon/figma/image-up.svg';

import { TitleBar } from '../Navigation/TitleBar';
import { MinimumInset } from '../Layout/MinimumInset';
import { ToolbarClearance } from '../Layout/ToolbarClearance';
import { useContextOrThrow } from '../../../../Core/Hook';
import { ApiContext } from '../../../../Contexts';
import { ImageAsset, useFileSelect } from '../../../../Common/Hook';
import { Paginated } from '../../../../Models';
import { ContextMenuItemInlineMode } from '../Content/ContextMenu';
import { Upload } from '../../../../Types';
import { FileAndSrc } from '../../../../Common/Model/FileAndSrc';
import { InfiniteList, InfiniteListProps } from '../Content/InfiniteList';
import { Sheet } from '../Layout/Sheet';

type Props = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  initialSelectionId: string | undefined;
  onSubmit: (selection: ImageAsset | FileAndSrc) => void;
  upload?: Upload;
}

type LibraryImage = {
  image: ImageAsset
};

type ImageLibraryFilters = {
  search?: string;
};

export const ImageLibrarySheet: FC<Props> = ({
  open,
  onOpenChange,
  initialSelectionId,
  onSubmit,
  upload,
}) => {
  const intl = useIntl();
  const api = useContextOrThrow(ApiContext);
  const scrollAreaRef = useRef(null);
  const [selectingFile, setSelectingFile] = useState<boolean>(false);
  const [userUploadReady, setUserUploadReady] = useState<boolean>(false);
  const [selection, setSelection] = useState<ImageAsset>();
  const [selectedId, setSelectedId] = useState<string | undefined>(initialSelectionId);
  const [searchValue, setSearchValue] = useState<string>('');
  const queryKey = useMemo(() => ['unsplash', { searchValue }], [searchValue]);
  const queryFilters = useMemo<ImageLibraryFilters>(() => ({ search: searchValue }), [searchValue]);
  const selectFile = useFileSelect('image/*');
  const [localFileAndSrc, setLocalFileAndSrc] = useState<FileAndSrc>();
  const uploadSrc = localFileAndSrc?.src || (upload && `/api/uploads/${ upload.uuid }/download`);

  const queryFn: InfiniteListProps<LibraryImage, ImageLibraryFilters>['queryFn'] = useCallback(async (filters, page) => {
    const response = await api.get<Paginated<'images', LibraryImage>>(
      '/assets/unsplash/images',
      {
        params: {
          search: filters.search,
          pageNum: page,
        },
      },
    )
    return {
      ...response.data,
      items: response.data.images,
    };
  }, [api]);

  useEffect(() => {
    if (!selection) {
      setSelectedId(undefined);
      return;
    }

    setSelectedId(selection.id);
  }, [selection]);

  const whenSubmitted = useCallback(() => {
    if (!(selection || localFileAndSrc)) {
      return;
    }

    onOpenChange(false);
    onSubmit(selection || localFileAndSrc!);
  }, [onOpenChange, onSubmit, selection, localFileAndSrc]);

  const whenFileSelected = useCallback((file: File) => {
    setUserUploadReady(false);
    const selectedFileAndSrc = {
      file,
      src: URL.createObjectURL(file),
    };

    setLocalFileAndSrc(selectedFileAndSrc);
    onOpenChange(false);
    onSubmit(selectedFileAndSrc);
  }, [onOpenChange, onSubmit]);

  const whenUploadClicked = useCallback(() => {
    setSelectingFile(true)
    selectFile(
      event => {
        setSelectingFile(false);
        if (!event.currentTarget.files?.length) {
          return;
        }

        whenFileSelected(event.currentTarget.files[0]);
      },
      () => setSelectingFile(false),
    );
  }, [selectFile, whenFileSelected]);

  return (
    <Sheet
      open={ open }
      onOpenChange={ onOpenChange }
    >
      <ToolbarClearance
        ref={scrollAreaRef}
        scrollable={true}
        clear={ ['top'] }
      >
        <Stack
          gap={ 0 }
          direction="column"
        >
          <MinimumInset
            right={ 2 }
            bottom={ 2 }
            left={ 2 }
          >
            <div
              css={ theme => ({
                paddingTop: theme.new.spacing[2],
              }) }
            >
              <SearchField
                value={ searchValue }
                onChange={ setSearchValue }
              />
            </div>
          </MinimumInset>
          <InfiniteList
            css={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
            }}
            scrollAreaRef={scrollAreaRef}
            filters={queryFilters}
            queryKey={queryKey}
            queryFn={queryFn}
            renderLeadingItems={() => (
              <>
                <button
                  css={ theme => ({
                    outline: 'none',
                    border: 'none',
                    padding: 0,
                    margin: 0,
                    background: theme.new.palette.grey[300].main,
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    color: theme.new.palette.teal[900].main,
                  }) }
                  type="button"
                  onClick={ whenUploadClicked }
                >
                  {
                    selectingFile
                      ? (
                        <div
                          css={ {
                            position: 'relative',
                            width: '24px',
                            height: '24px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          } }
                        >
                          <ProgressCircle size="xs"/>
                        </div>
                      )
                      : (
                        <PresentationIcon
                          IconComponent={ UploadIcon }
                        />
                      )
                  }
                  <FormattedMessage
                    description="Label for upload image button in image block form"
                    defaultMessage="Upload"
                  />
                </button>
                { uploadSrc && (
                  <div
                    css={ theme => ({
                      position: 'relative',
                      transition: 'transform 100ms, box-shadow 100ms, border-radius 100ms',
                      ...!selection
                        ? {
                          transform: 'scale(1.05)',
                          outline: `3px solid ${ theme.new.palette.primary['400'].main }`,
                          borderRadius: theme.new.borderRadius.small,
                          boxShadow: `${ theme.new.shadow.lg }, ${ theme.new.shadow.md }, ${ theme.new.shadow.sm }`,
                        }
                        : {},
                    }) }
                    onClick={ () => {
                      setSelection(undefined);
                    } }
                  >
                    <img
                      src={ uploadSrc }
                      css={ {
                        width: '100%',
                        objectFit: 'cover',
                        aspectRatio: '1',
                      } }
                      onLoad={ () => setUserUploadReady(true) }
                    />
                    <div
                      css={ theme => ({
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        transition: 'opacity 200ms',
                        ...userUploadReady
                          ? { opacity: 0 }
                          : { opacity: 1 },
                        backgroundColor: theme.new.palette.grey[300].main,
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        color: theme.new.palette.teal[900].main,
                      }) }
                    >
                      <ProgressCircle size="xs"/>
                    </div>
                  </div>
                ) }
              </>
            )}
            renderItem={(item) => (
              <img
                key={item.image.id}
                onClick={() => {
                  setSelection(item.image);
                }}
                src={item.image.urls.thumbs.s.url}
                css={theme => ({
                  width: '100%',
                  objectFit: 'cover',
                  aspectRatio: '1',
                  transition: 'transform 100ms, box-shadow 100ms, border-radius 100ms',
                  ...selectedId === item.image.id
                    ? {
                      transform: 'scale(1.05)',
                      outline: `3px solid ${theme.new.palette.primary['400'].main}`,
                      borderRadius: theme.new.borderRadius.small,
                      boxShadow: `${theme.new.shadow.lg}, ${theme.new.shadow.md}, ${theme.new.shadow.sm}`,
                    }
                    : {},
                })}
              />
            )}
            renderEmptyState={() => (
              <EmptyState
                breakpoint="mobile"
                IconComponent={ImageIcon}
                buttonProps={[]}
                text={intl.formatMessage({
                  description: 'Error message when initial request for unsplash images succeeds with no results.',
                  defaultMessage: 'No images found',
                })}
                pad={true}
              />
            )}
            renderErrorState={(refetch) => (
              <EmptyState
                breakpoint="mobile"
                IconComponent={ImageIcon}
                buttonProps={[
                  {
                    variant: 'secondary',
                    children: intl.formatMessage({
                      description: 'Label for retry button when initial request for unsplash images fail to load.',
                      defaultMessage: 'Retry',
                    }),
                    onClick: () => refetch(),
                  },
                ]}
                text={intl.formatMessage({
                  description: 'Error message when initial request for unsplash images fail to load.',
                  defaultMessage: 'Could not load images',
                })}
                pad={true}
              />
            )}
          />
        </Stack>
      </ToolbarClearance>
      <TitleBar
        title={intl.formatMessage({
          description: 'Title of image library select sheet.',
          defaultMessage: 'Select image',
        })}
        onClose={() => onOpenChange(false)}
        contextMenuProps={{
          actions: [
            {
              id: 'image-asset-submit',
              disabled: !selection && !uploadSrc,
              onClick: whenSubmitted,
              label: intl.formatMessage({
                description: 'Label for submit button in image library selection sheet.',
                defaultMessage: 'Select',
              }),
              inline: {
                mode: ContextMenuItemInlineMode.LABEL_ONLY,
                variant: 'primary',
              },
            },
          ],
          maxInlineActionCount: 1,
        }}
      />
    </Sheet>
  );
};
