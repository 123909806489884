import { FC } from 'react';
import { LocalisedString } from 'op-storybook/lib/model/LocalisedString/LocalisedString';

import { ValidationErrorMessage } from '../ValidationErrorMessage/ValidationErrorMessage';

type Props = {
  localisedErrors: LocalisedString[];
};

export const LocalisedErrors: FC<Props> = ({
  localisedErrors,
}) => (
  <>
    { localisedErrors.map(error => (
      <ValidationErrorMessage key={ error.id }>
        { error.localisation }
      </ValidationErrorMessage>
    )) }
  </>
);
