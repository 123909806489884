import { useIntl } from 'react-intl';

import { PersonParser } from '../../Utility';
import { SharedDelivery } from './useFetchSharedDeliveries';
import { useLoggedInAuthDescription } from '../../Core/Hook';

export const useResourceLockMessage = (
  delivery: SharedDelivery,
): string | null => {
  const { user: { id } } = useLoggedInAuthDescription();
  const intl = useIntl();
  return delivery.resourceLock && delivery.resourceLock.created.by.id !== String(id)
    ? intl.formatMessage({
      description: 'Tooltip displayed when broadcast editing is disabled because the resource is locked.',
      defaultMessage: '<span>Broadcasts can only be edited by one person at a time.</span> <span>{ name } is editing this broadcast.</span>'
    }, {
      name: PersonParser.fullName(delivery.resourceLock.created.by)
    })
    : null;
};
