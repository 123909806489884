import { useCallback, useMemo } from 'react';
import { AxiosError } from 'axios';

import { ContentResponse, DraftResponse } from '../Model';
import { RespondButtonProps } from '../../Content/Component';
import { useSubmitResponse } from './useSubmitResponse';
import { RequestState } from '../../Models';

export const useRespondButtonProps = <R extends ContentResponse>(
  partialResponse: Omit<R, 'createdAt'>,
  onSuccess: (response: R) => void,
  onError: (error: AxiosError) => void,
  savedResponse?: R,
  transform?: (response: R) => DraftResponse,
): RespondButtonProps => {
  const [submit, submitState] = useSubmitResponse(
    partialResponse.contentId,
    onSuccess,
    onError,
    transform,
  );

  const whenRespondClicked = useCallback(() => (
    submit({
      ...partialResponse,
      createdAt: new Date().toISOString(),
    } as R)
  ), [partialResponse, submit]);

  return useMemo(() => ({
    state: savedResponse ? RequestState.COMPLETE : submitState,
    onClick: whenRespondClicked,
  }), [savedResponse, submitState, whenRespondClicked]);
};
