import { useMemo } from 'react';

import { Audience, TagCondition } from '../Model';

type TagIdKey = 'teamIds' | 'jobTitleIds' | 'skillIds';

type TagIds = Record<TagIdKey, string[]>;

export const useAudienceTagIds = (audience: Audience): TagIds => {
  const tagConditions = useMemo(
    () => audience.segments.reduce<TagCondition[]>(
      (
        accumulatedTagConditions,
        segment,
      ) => (
        accumulatedTagConditions.concat(
          segment.conditions.filter(condition => condition.type === 'tag')
            .map(condition => condition as TagCondition)
        )
      ),
      [],
    ),
    [audience],
  );

  return useMemo(
    () => tagConditions.reduce<TagIds>(
      addConditionTagIds,
      {
        teamIds: [],
        jobTitleIds: [],
        skillIds: [],
      },
    ),
    [tagConditions],
  );
};

const addConditionTagIds = (accumulatedTagIds: TagIds, tagCondition: TagCondition) => {
  switch (tagCondition.tagType) {
    case 'team':
      return addTagIdsToKey(accumulatedTagIds, 'teamIds', tagCondition.tagIds);
    case 'jobtitle':
      return addTagIdsToKey(accumulatedTagIds, 'jobTitleIds', tagCondition.tagIds);
    case 'skill':
      return addTagIdsToKey(accumulatedTagIds, 'skillIds', tagCondition.tagIds);
    default:
      return accumulatedTagIds;
  }
};

const addTagIdsToKey = (tagIds: TagIds, key: TagIdKey, idsToAdd: string[]) => ({
  ...tagIds,
  [key]: tagIds[key]
    .filter(tagId => !idsToAdd.includes(tagId))
    .concat(idsToAdd),
})
