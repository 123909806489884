import { Audience } from '../Model';
import { AudienceCleaner } from './AudienceCleaner';

export class AudienceComparer {
  public static cleanedAudiencesAreEqual = (
    audienceA: Audience,
    audienceB: Audience,
  ): boolean => {
    const cleanAudienceA = AudienceCleaner.cleanAudience(audienceA);
    const cleanAudienceB = AudienceCleaner.cleanAudience(audienceB);

    return JSON.stringify(cleanAudienceA) === JSON.stringify(cleanAudienceB);
  };
}
