import { FunctionComponent } from 'react';

import { CoverTableMenu } from './CoverTableMenu';
import { RespondedTable } from './RespondedTable';
import { Content } from '../../../../../Models';
import { ReportRecipient } from '../../../../../Broadcasts/Component';

interface Props {
  broadcastId: string;
  optionId?: string;
  deliveryId?: string;
  content: Content;
  showMenu: boolean;
  optionType: 'accepted' | 'declined';
  initialSearch?: string;
}

export const CoverRespondedTable: FunctionComponent<Props> = ({
  broadcastId,
  optionId,
  deliveryId,
  content,
  showMenu,
  optionType,
  initialSearch,
}) => {
  const menuRender = (recipient: ReportRecipient<Record<string, unknown>>) => (
    <CoverTableMenu
      content={ content }
      recipient={ recipient }
    />
  );

  return (
    <RespondedTable
      broadcastId={ broadcastId }
      responseType="responded"
      optionId={ optionId }
      deliveryId={ deliveryId }
      contentId={ content.id }
      initialSearch={ initialSearch }
      { ...(
        showMenu && optionType !== 'declined'
          ? { menuRender }
          : {}
      ) }
    />
  );
}
