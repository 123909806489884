import { Validator } from '../../../src/react/Common/Model/Validator';
import { ValidationError } from '../../../src/react/Common/Model/ValidationError';

export class EmailValidator {
  public static validate = (email: string): ValidationError[] => emailValidator.validate(email);
}

const emailValidator = new Validator<string>([
  email => (
    !email || /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.exec(email)?.length
      ? undefined
      : {
        type: 'notEmailAddress',
        metadata: null,
      }
  ),
]);
