import { FunctionComponent, ReactNode } from 'react';
import * as React from 'react';
import Button from '@mui/material/Button';
import { DialogContentText } from '@mui/material';

import { CenteredDialog } from './CenteredDialog';
import { StyledContent, StyledHeading } from './styles';

interface Props {
  container?: Element | React.Component | (() => Element | React.Component | null) | null;
  dismissCta: string,
  onDismiss: () => void,
  open: boolean,
  heading?: string | JSX.Element | ReactNode,
  content: string | ReactNode,
}

export const CenteredNoticeDialog: FunctionComponent<Props> = ({
  dismissCta,
  onDismiss,
  open,
  heading,
  content,
  ...other
}) => {
  return (
    <CenteredDialog
      open={open}
      onDismiss={onDismiss}
      content={
        <>
          {
            heading && <StyledHeading size="small">{heading}</StyledHeading>
          }
          <StyledContent textAlign="center">
            <DialogContentText>{ content }</DialogContentText>
          </StyledContent>
        </>
      }
      actions={
        <Button
          autoFocus
          color="primary"
          onClick={ onDismiss }
        >
          { dismissCta }
        </Button>
      }
      {...other}
    />
  );
}
