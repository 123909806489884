import { useIntl } from 'react-intl';
import { LocalisedString } from 'op-storybook/lib/model/LocalisedString/LocalisedString';

import { InvitationStatus } from './useFetchInvitations';

export const useInvitationStatuses = (): LocalisedString<InvitationStatus>[] => {
  const intl = useIntl();
  return [
    {
      id: 'pending',
      localisation: intl.formatMessage({
        id: 'people.invitations.pendingStatus',
        defaultMessage: 'Scheduled',
      }),
    },
    {
      id: 'complete',
      localisation: intl.formatMessage({
        id: 'people.invitations.completeStatus',
        defaultMessage: 'Sent',
      }),
    },
    {
      id: 'queued',
      localisation: intl.formatMessage({
        id: 'people.invitations.queuedStatus',
        defaultMessage: 'Sending',
      }),
    },
    {
      id: 'cancelled',
      localisation: intl.formatMessage({
        id: 'people.invitations.cancelledStatus',
        defaultMessage: 'Cancelled',
      }),
    },
  ];
};
