import { ContextCreator } from '@ourpeople/shared/Core/Utility/ContextCreator';

import { MessagePayload } from '../Model/PushMessage';

export interface PushMessageDefinitionRegistryContextValue {
  onClick: (message: MessagePayload, onDismiss: () => void) => void;
  onReceive: (message: MessagePayload, onDismiss: () => void) => void;
  discard: (message: MessagePayload) => boolean;
}

export const PushMessageDefinitionRegistryContext = ContextCreator.withDisplayName<PushMessageDefinitionRegistryContextValue>(
  'PushMessageDefinitionRegistryContext',
  null,
);
