import styled from '@emotion/styled';

export const StyledColourSettingValue = styled.div`
  .MuiInputBase-root,
  fieldset {
    border: none;
  }

  input[type="text"] {
    padding-left: 0;
  }
`;

export const StyledLinkSettingInput = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-template-areas:
    "label"
    "url";
  row-gap: ${props => props.theme.spacing(2)};
`;

export const StyledDurationSettingInput = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto;
  grid-template-areas:
    "amount unit";
  column-gap: ${props => props.theme.spacing(1)};

  .MuiFormControl-root {
    margin-bottom: ${props => props.theme.spacing(1)};
  }
`;

export const StyledCollectionValueItem = styled.div`
  margin-bottom: ${props => props.theme.spacing(1)};
`;

export const StyledCollectionItem = styled.div`
  padding-bottom: ${props => props.theme.spacing(1)};
  margin-bottom: ${props => props.theme.spacing(2)};
  border-bottom: 1px solid ${props => props.theme.palette.grey[300]};
`;

export const StyledCollectionItemInput = styled.div`
  margin-bottom: ${props => props.theme.spacing(1)};
`;
