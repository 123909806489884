import styled from '@emotion/styled';

export const StyledTemplatePreview = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${ props => props.theme.spacing(2) };

  ${ props => props.theme.breakpoints.down('md') } {
    grid-template-columns: 1fr;
  }
`;

export const StyledDetailPane = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: ${ props => props.theme.spacing(6) };
  padding-bottom: ${ props => props.theme.spacing(6) };

  p {
    margin: 0;
  }

  a:not([role=button]) {
    color: ${ props => props.theme.palette.primary.main };
  }
`;
