import { FunctionComponent } from 'react';
import {
  CheckboxProps,
} from '@mui/material';

import UncheckedIcon from '../../Assets/img/icons/monochrome/checkbox-unchecked.svg';
import CheckedIcon from '../../Assets/img/icons/monochrome/checkbox-checked.svg';
import IndeterminateIcon from '../../Assets/img/icons/monochrome/checkbox-indeterminate.svg';
import { StyledCheckbox } from './styles';

/** @deprecated Prefer Checkbox from op-storybook project */
export const Checkbox: FunctionComponent<CheckboxProps> = ({ ...props }) => {
  return <StyledCheckbox
    checkedIcon={<CheckedIcon/>}
    icon={<UncheckedIcon/>}
    indeterminateIcon={<IndeterminateIcon/>}
    {...props}
  />;
}
