import { FetchResponse, useGet } from '../../Hooks';
import { Paginated } from '../../Models';
import { Attribution } from '../../Types';

export type FetchImageAssetsParams = {
  search?: string;
  pageNum?: number;
};

export type ImageAsset = {
  id: string;
  fileName: string;
  description: string;
  width: number;
  height: number;
  urls: {
    original: {
      url: string;
    };
    thumbs: {
      s: {
        url: string;
      };
      m: {
        url: string;
      };
      l: {
        url: string;
      };
    };
  },
  attributions: Attribution[];
};
type FetchImageAssetsResponse = Paginated<'images', { image: ImageAsset }>;

export const useFetchImageAssets = (params: FetchImageAssetsParams): FetchResponse<FetchImageAssetsResponse> => (
  useGet('/assets/unsplash/images', params)
);
