import styled from '@emotion/styled';

export const StyledSchedulingInputs = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    'when first-row'
    'second-row second-row';
  column-gap: ${ props => `${ props.theme.spacing(2) }` };
  row-gap: ${ props => `${ props.theme.spacing(2) }` };
`;

export const StyledWhenInput = styled.div`
  grid-area: when;
  display: flex;
  align-items: center;
  gap: ${ props => props.theme.spacing(2) };

  label {
    font-weight: 500;
    font-size: ${ props => props.theme.spacing(2.25) };
  }
`;

export const StyledEventNotice = styled.div`
  margin: ${ props => `${ props.theme.spacing(1) }` };
`;
