import { FC, ReactNode } from 'react';

import { StyledParagraph } from './styles';

type Props = {
  children?: ReactNode;
  className?: string;
};

export const Paragraph: FC<Props> = ({ children, className }) => {
  return (
    <StyledParagraph
      className={ className }
    >
      { children }
    </StyledParagraph>
  );
};
