import { createContext } from 'react';

import { ToastMessage } from '../../Components';

export interface ToastContextValue {
  addSuccessToast: (message: string) => void;
  addErrorToast: (message: string) => void;
  addToast: (toast: ToastMessage) => void;
}

export const ToastContext = createContext<ToastContextValue | null>(null);
