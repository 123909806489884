import styled from '@emotion/styled';

import { StyledPlayButton } from '../VideoPlayButton/style';

export const StyledThumbnailContainer = styled.div<{ loaded: boolean }>`
  position: relative;

  ${ props => !props.loaded && `
    opacity: 0;
    user-events: none;
  ` }
`;

export const StyledThumbnailOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  color: ${ props => props.theme.palette.primary.contrastText };
  padding: ${ props => props.theme.spacing(1) };
  display: flex;
  align-items: flex-start;

  &:hover {
    ${ StyledPlayButton } {
      background-color: ${ props => props.theme.palette.primary.light };
    }
  }
`;

export const StyledImage = styled.img`
  max-width: 100%;
  max-height: 350px;
`;
