import styled from '@emotion/styled';

export const StyledToolbarContainer = styled.div`
  border: 1px solid ${ props => props.theme.palette.grey[400] };
  border-bottom: none;
  border-top-left-radius: ${ props => props.theme.borderRadius.xs };
  border-top-right-radius: ${ props => props.theme.borderRadius.xs };
  padding: 0 ${ props => props.theme.spacing(1) };
`;

export const StyledDivider = styled.div`
  width: 1px;
  height: ${ props => props.theme.spacing(2) };
  background-color: ${ props => props.theme.palette.grey[200] };
`;
