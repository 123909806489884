import { FC, useCallback } from 'react';

import { SingleContentCard, LiveComponentProps } from '../../../../Content/Model';
import { NumberRequest as NumberRequestModel } from '../../../Model/ContentTypes';
import { NumberRequest } from '../../Content';
import { ValidationMerger } from '../../../../Common/Utility/ValidationMerger';
import { ValidationTree, Validator } from '../../../../Common/Model';
import { Validate } from '../../../../Common/Utility';
import { useContextOrThrow } from '../../../../Core/Hook';
import { FormNavigationContext } from '../../../Provider/FormNavigationProvider';
import { DraftNumberResponse } from '../../../Service';

export const LiveNumberRequest: FC<LiveComponentProps<NumberRequestModel, DraftNumberResponse>> = ({
  card,
  response,
  onResponseChange,
  onResponseClear,
  validation,
  onValidationChange,
}) => {
  const { displayingValidation } = useContextOrThrow(FormNavigationContext);
  const whenNumberChanged = useCallback((number: number | undefined) => {
    if (number === undefined) {
      onResponseClear();
      onValidationChange({
        errors: [],
        children: {},
      });
      return;
    }

    const response: DraftNumberResponse = {
      contentId: card.content.id,
      contentType: 'numberRequest',
      number: number,
    };
    const newValidation = validateResponse(card, response);

    onResponseChange(response);
    onValidationChange(
      validation
        ? ValidationMerger.overwriteMerge(validation, newValidation)
        : newValidation
    );
  }, [card, onResponseChange, onResponseClear, onValidationChange, validation]);

  return (
    <NumberRequest
      value={ response?.number }
      onChange={ whenNumberChanged }
      validation={ validation }
      displayValidation={ displayingValidation }
    />
  );
};

const validateResponse = (card: SingleContentCard<NumberRequestModel>, response: DraftNumberResponse): ValidationTree<DraftNumberResponse> => ({
  errors: [],
  children: {
    number: {
      errors: new Validator<number>([
        Validate.isNumeric,
        number => Validate.lessThanEq(number, card.content.max),
        number => Validate.greaterThanEq(number, card.content.min),
      ]).validate(response.number),
      children: {},
    },
  },
});
