import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { LocalisedString } from 'op-storybook/lib/model/LocalisedString/LocalisedString';

import { BasicAutocomplete, BasicAutocompleteProps } from '../../../Common/Component';

export type SubmissionStatusString = 'noClosed' | 'noOpen';

type Props = Omit<BasicAutocompleteProps<SubmissionStatusString>, 'options'>;

export const SubmissionStatusAutocomplete: FunctionComponent<Props> = props => {
  const intl = useIntl();
  const options: LocalisedString<SubmissionStatusString>[] = [
    {
      id: 'noOpen',
      localisation: intl.formatMessage({
        id: 'form.report.noOpenSubmissionStatusFilterOption',
        description: 'Label for region in tag import type autocomplete',
        defaultMessage: 'Hide open',
      }),
    },
    {
      id: 'noClosed',
      localisation: intl.formatMessage({
        id: 'form.report.noClosedSubmissionStatusFilterOption',
        description: 'Label for department in tag import type autocomplete',
        defaultMessage: 'Hide closed',
      }),
    },
  ];

  return (
    <BasicAutocomplete
      options={ options }
      label={ intl.formatMessage({
        id: 'form.report.submissionStatusFilterLabel',
        description: 'Label for submission status autocomplete.',
        defaultMessage: 'Status',
      }) }
      { ...props }
    />
  );
};
