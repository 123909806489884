import { FC, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { onMessage } from 'firebase/messaging';

import { PushMessageContext, PushMessageContextValue } from '../Context/PushMessageContext';
import { useContextOrThrow } from '../Hook/useContextOrThrow';
import { MessageLogEntry, MessagePayload, MessageReceivedState } from '../Model/PushMessage';
import { PushMessageDefinitionRegistryContext } from '../Context/PushMessageDefinitionRegistryContext';
import { PushDeviceRegistrationContext } from '../Context/PushDeviceRegistrationContext';

export const PushMessageProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const { firebaseMessaging } = useContextOrThrow(PushDeviceRegistrationContext);
  const { onClick, onReceive, discard } = useContextOrThrow(PushMessageDefinitionRegistryContext);
  const [messageHistory, setMessageHistory] = useState<MessageLogEntry[]>([]);

  const dismissMessages = useCallback((predicate: (messageLogEntry: MessageLogEntry) => boolean) => {
    setMessageHistory(messageHistory => messageHistory.filter(message => !predicate(message)));
  }, []);

  const whenMessageReceived = useCallback((
    messagePayload: MessagePayload,
    messageReceivedState: MessageReceivedState,
  ) => {
    if (discard(messagePayload)) {
      return;
    }

    setMessageHistory(messageHistory => [
      {
        payload: messagePayload,
        received: {
          state: messageReceivedState,
          at: new Date().toISOString(),
        },
      }
    ].concat(messageHistory));

    if (messageReceivedState !== MessageReceivedState.BACKGROUND) {
      void firebaseMessaging?.swRegistration?.showNotification(messagePayload.notification?.title || '', {
        ...messagePayload.notification,
      })
        .then(() => {
          onReceive(
            messagePayload,
            () => dismissMessages(message => message.payload.messageId === messagePayload.messageId),
          );
        })
    } else {
      onReceive(
        messagePayload,
        () => dismissMessages(message => message.payload.messageId === messagePayload.messageId),
      );
    }
  }, [discard, dismissMessages, onReceive, firebaseMessaging?.swRegistration]);

  const whenMessageClicked = useCallback((
    messagePayload: MessagePayload
  ) => {
    onClick(
      messagePayload,
      () => dismissMessages(message => message.payload.messageId === messagePayload.messageId),
    )
  }, [dismissMessages, onClick]);

  useEffect(() => {
    if (!firebaseMessaging) {
      return;
    }

    onMessage(
      firebaseMessaging,
      (messagePayload) => (
        whenMessageReceived(
          messagePayload as unknown as MessagePayload,
          document.hasFocus()
            ? MessageReceivedState.FOREGROUND
            : MessageReceivedState.BLURRED,
        )
      )
    );
  }, [firebaseMessaging, whenMessageReceived]);

  useEffect(() => {
    const onServiceWorkerMessageReceived = (event: MessageEvent) => {
      if (messageEventIsBackgroundMessageReceivedEvent(event)) {
        return whenMessageReceived(event.data.value.payload, MessageReceivedState.BACKGROUND);
      } else if (messageEventIsMessageClickedEvent(event)) {
        return whenMessageClicked(event.data.value.payload);
      }
    };

    navigator.serviceWorker?.addEventListener('message', onServiceWorkerMessageReceived);

    return () => navigator.serviceWorker?.removeEventListener('message', onServiceWorkerMessageReceived);
  }, [whenMessageClicked, whenMessageReceived]);

  const contextValue = useMemo<PushMessageContextValue>(() => ({
    messageHistory,
    dismissMessages,
  }), [dismissMessages, messageHistory]);

  return (
    <PushMessageContext.Provider
      value={ contextValue }
    >
      { children }
    </PushMessageContext.Provider>
  );
};

type BackgroundMessageReceivedMessage = {
  type: 'BACKGROUND_MESSAGE_RECEIVED';
  value: {
    payload: MessagePayload;
  };
};

type BackgroundMessageReceivedEvent = Omit<MessageEvent, 'data'> & { data: BackgroundMessageReceivedMessage };

const messageEventIsBackgroundMessageReceivedEvent = (event: MessageEvent): event is BackgroundMessageReceivedEvent => (
  (event as BackgroundMessageReceivedEvent).data.type === 'BACKGROUND_MESSAGE_RECEIVED'
);

type MessageClickedMessage = {
  type: 'MESSAGE_CLICKED';
  value: {
    payload: MessagePayload;
  };
};

type MessageClickedEvent = Omit<MessageEvent, 'data'> & { data: MessageClickedMessage };

const messageEventIsMessageClickedEvent = (event: MessageEvent): event is MessageClickedEvent => (
  (event as MessageClickedEvent).data.type === 'MESSAGE_CLICKED'
);

