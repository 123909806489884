import { FC, useState } from 'react';

import { ContentPreviewProps } from '../../../../Content/Model';
import { SignRequestEditorContent } from '../../../Service/ContentDefinitions/SignRequestDefinition';
import { SignRequest } from '../SignRequest/SignRequest';
import { useLoggedInAuthDescription } from '../../../../Core/Hook';
import { TextFormatter } from '../../../../Utility';

export const SignRequestPreview: FC<ContentPreviewProps<SignRequestEditorContent>> = () => {
  const { user } = useLoggedInAuthDescription();
  const [signed, setSigned] = useState<{
    at: Date;
    by: string;
  } | null>(null);

  return (
    <SignRequest
      signed={ signed }
      onInputClick={ () => setSigned({
        by: TextFormatter.spaceSeparated(user.forename, user.lastname || ''),
        at: new Date(),
      }) }
    />
  );
};
