import { CSSProperties, FC, PropsWithChildren, useMemo } from 'react';
import { Theme } from '@emotion/react';
import { ArrayInterpolation } from '@emotion/styled';

import { defaultTheme } from '../../../lib/const/defaultTheme';
import { PaletteLookup } from '../../../lib/model/PaletteLookup/PaletteLookup';

type Props = {
  type?: keyof Theme['new']['typography'];
  size?: keyof Theme['new']['typography']['display']['size'];
  weight?: keyof Theme['new']['typography']['display']['weight'];
  palette?: PaletteLookup;
  transition?: boolean;
  align?: CSSProperties['textAlign'];
  className?: string;
};

export const Typography: FC<PropsWithChildren<Props>> = ({
  type = 'text',
  size = 'md',
  weight = 'medium',
  palette,
  transition = false,
  align = 'left',
  className,
  children,
}) => {
  const typographyCss: ArrayInterpolation<typeof defaultTheme> = useMemo(() => [
    theme => ({
      fontFamily: theme.new.typography[type].family,
      fontWeight: theme.new.typography[type].weight[weight],
      fontSize: theme.new.typography[type].size[size].fontSize,
      letterSpacing: theme.new.typography[type].size[size].tracking,
      lineHeight: theme.new.typography[type].size[size].lineHeight,
      ...palette
        ? { color: theme.new.palette[palette.colour][palette.intensity || 600][palette.contrast ? 'contrast' : 'main'] }
        : {},
      ...transition
        ? { transition: 'color 0.2s' }
        : {},
      textAlign: align,
    }),
  ], [align, palette, size, transition, type, weight]);

  return (
    <span
      className={ className }
      css={ typographyCss }
    >
      { children }
    </span>
  );
}
