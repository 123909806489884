import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { StyledSelectionDescription } from './style';

type Props = {
  selectedCount: number,
  totalCount: number,
  resultFiltered: boolean,
  currentPageCount: number,
}

export const SelectionDescription: FunctionComponent<Props> = ({
  selectedCount,
  totalCount,
  resultFiltered,
  currentPageCount,
}) => (
  <StyledSelectionDescription>
    {
      selectedCount === totalCount
        ? (
          resultFiltered
            ? (
              <FormattedMessage
                id="table.filters.totalFilteredSelection"
                description="Message shown when all rows are selected whilst filters are active"
                defaultMessage="All <strong>{selectedCount, plural, one {# row} other {# rows}}</strong> that match your filters {selectedCount, plural, one {is} other {are}} selected"
                values={ {
                  selectedCount,
                } }
              />
            )
            : (
              <FormattedMessage
                id="table.filters.totalSelection"
                description="Message shown when all rows are selected whilst no filters are active"
                defaultMessage="All <strong>{selectedCount, plural, one {# row} other {# rows}}</strong> in this list {selectedCount, plural, one {is} other {are}} selected"
                values={ {
                  selectedCount,
                } }
              />
            )
        )
        : (
          selectedCount === currentPageCount
            ? (
              <FormattedMessage
                id="table.filters.totalPageSelection"
                description="Message shown when only a subset of rows are selected whilst filters are active"
                defaultMessage="All <strong>{selectedCount, plural, one {# row} other {# rows}}</strong> on this page {selectedCount, plural, one {is} other {are}} selected"
                values={ {
                  selectedCount,
                } }
              />
            )
            : (
              <FormattedMessage
                id="table.filters.partialPageSelection"
                description="Message shown when only a subset of rows are selected whilst no filters are active"
                defaultMessage="<strong>{selectedCount, plural, one {# row} other {# rows}}</strong> on this page {selectedCount, plural, one {is} other {are}} selected"
                values={ {
                  selectedCount,
                } }
              />
            )
        )
    }
  </StyledSelectionDescription>
);
