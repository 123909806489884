import { SetStateAction, useCallback, useContext, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom'

import { PageLayoutContext } from '../New/Core/Provider/PageLayoutProvider';
import { useLocationQueryObject } from '../New/Common/Hook/useLocationQueryObject';
import { QueryObjectHelper } from '../New/Common/Utility/QueryObjectHelper';
import { ObjectHelper } from '../Common/Utility/ObjectHelper';

// This was stolen from https://github.com/baruchiro/use-route-as-state
// I don't trust the versioning of this repo, so I've just reproduced the bit
// we need here.

type UpdateQuery<T extends Record<string, string | undefined>> = (
  query: SetStateAction<Partial<T>>,
  pushHistory?: boolean,
) => void;

const objectToQueryParams = (obj: Record<string, string>) => '?' + Object.keys(obj)
  .filter((key) => obj[key] !== undefined)
  .map((key) => `${ key }=${ obj[key] }`)
  .join('&');

const encodeValues = <T extends Record<string, string | undefined>>(obj: Partial<T>) => Object.keys(ObjectHelper.clean(obj))
  .reduce((acc, key) => {
    const value = obj[key];
    return {
      ...acc,
      [key]: value && encodeURIComponent(value)
    };
  }, {});

export const useQueryAsState = <T extends Record<string, string>>(defaultValues?: Partial<T>): [Partial<T>, UpdateQuery<T>] => {
  const pageLayoutContextValue = useContext(PageLayoutContext);
  const queryPrefix = pageLayoutContextValue?.queryPrefix;
  const search = useLocationQueryObject();
  const { pathname } = useLocation();

  const unprefixedSearch = useMemo(() => (
    queryPrefix
      ? QueryObjectHelper.unprefixKeys(search, queryPrefix)
      : search
  ), [queryPrefix, search]);
  const typedSearch = search as Partial<T>;
  const history = useHistory();

  const updateQuery: UpdateQuery<T> = useCallback((query, pushHistory) => {
    const irrelevantQuery = queryPrefix
      ? QueryObjectHelper.getUnprefixedKeys(typedSearch, queryPrefix)
      : {};
    const relevantQuery = queryPrefix
      ? QueryObjectHelper.getPrefixedKeys(typedSearch, queryPrefix)
      : typedSearch;
    const updatedParams = typeof query === 'function' ? query(relevantQuery) : query;

    if (Object.is(updatedParams, relevantQuery)) {
      return;
    }

    const prefixedParams = queryPrefix ? QueryObjectHelper.prefixKeys(updatedParams, queryPrefix) : updatedParams;

    const params = objectToQueryParams({
      ...irrelevantQuery,
      ...encodeValues(prefixedParams),
    });

    if (pushHistory) {
      history.push(pathname + params, history.location.state);
    } else {
      history.replace(pathname + params, history.location.state);
    }
  }, [typedSearch, queryPrefix, history, pathname]);

  const queryWithDefault = useMemo<Partial<T>>(() => ({
    ...(defaultValues || {}),
    ...unprefixedSearch,
  } as Partial<T>), [defaultValues, unprefixedSearch]);

  return [queryWithDefault, updateQuery]
};
