import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { LiveComponentProps } from '../../../../Content/Model';
import { UploadRequest } from '../../../../Broadcasts/Service';
import { UploadResponse } from '../../../Model';
import { BroadcastContentContext } from '../../../../Broadcasts/Model';
import { useFileUploader } from '../../../../Common/Hook';
import { UniqueIdGenerator } from '../../../../Common/Utility';
import { useFileSelect } from '../../../../Common/Hook/useFileSelect';
import { useRecallErrorHandler, useRespondButtonProps } from '../../../Hook';
import { UploadState } from '../../../../Common/Context';
import { useApi } from '../../../../Core/Hook';
import { Upload } from '../../../../Types';
import { UploadBroadcastContent } from '../../../../Content/Component/BroadcastContent/Upload';

export const LiveUploadContent: FC<LiveComponentProps<UploadRequest, UploadResponse, BroadcastContentContext>> = ({
  card: { content },
  context,
  onResponseChange,
  onReloadRequired,
  response,
}) => {
  const api = useApi();
  const intl = useIntl();
  const { upload, remove, uploadStates } = useFileUploader();
  const selectFile = useFileSelect('image/*');
  const [contentUploadStates, setContentUploadStates] = useState<Map<string, UploadState>>(new Map());
  const [error, setError] = useState<string>();

  const completedUploadIds = useMemo(
    () => Array.from(contentUploadStates.values()).reduce<string[]>(
      (uploadIds, uploadState) => uploadState.upload?.id
        ? uploadIds.concat(uploadState.upload.id.toString())
        : uploadIds,
      [],
    ),
    [contentUploadStates],
  );

  const submitButtonProps = useRespondButtonProps(
    {
      type: 'upload_image',
      contentId: content.id.toString(),
      deliveryId: context.deliveryId,
      input: {
        uploadIds: completedUploadIds,
      },
    },
    onResponseChange,
    useRecallErrorHandler(onReloadRequired),
    response,
  );

  useEffect(() => {
    if (!response || contentUploadStates.size) {
      return;
    }

    let cancelled = false;
    const fetchUploads = response.input.uploadIds.reduce<Promise<Upload[]>>(
      async (lastPromise, uploadId) => {
        const uploads = await lastPromise;
        const response = await api.get<Upload>(`/uploads/${uploadId}`);
        return uploads.concat(response.data);
      },
      Promise.resolve([]),
    );

    fetchUploads
      .then(uploads => {
        if (cancelled) {
          return;
        }

        const uploadStateEntries = uploads.map<[string, UploadState]>(upload => ([
          `${content.id}_${upload.id}`,
          {
            ref: 'LiveUploadContent',
            upload,
            progress: 1,
            file: {
              name: upload.name,
              size: upload.size,
            },
          },
        ]));

        setContentUploadStates(new Map(uploadStateEntries));
      })
      .catch(() => {
        if (cancelled) {
          return;
        }

        throw new Error(`Failed to fetch uploads: ${response.input.uploadIds.join(', ')}.`);
      });

    return () => {
      cancelled = true;
    };
  }, [api, content.id, contentUploadStates.size, response]);

  useEffect(() => (
    setContentUploadStates(
      new Map(
        Array.from(uploadStates.entries())
          .filter(([id]) => id.includes(content.id.toString()))
      )
    )
  ), [content.id, uploadStates]);

  const whenFilesValidated = useCallback((files: File[]) => (
    files.forEach(file => (
      upload(
        `${content.id}-${UniqueIdGenerator.generate()}`,
        'broadcasts/responses/authorise-upload',
        file,
      )
    ))
  ), [content.id, upload]);

  const whenFilesSelected = useCallback((files: FileList | null) => {
    if (!files) {
      return;
    }

    setError(undefined);

    if (files.length + contentUploadStates.size > content.upload_limit) {
      setError(intl.formatMessage({
        id: 'inbox.upload.maxFileCountExceeded',
        description: 'Error message when max number of images is exceeded in upload card.',
        defaultMessage: 'Up to { max } files can be uploaded.',
      }, {
        max: content.upload_limit,
      }));
      return;
    }

    whenFilesValidated(Array.from(files));
  }, [content.upload_limit, contentUploadStates.size, intl, whenFilesValidated]);

  const whenUploadClicked = useCallback(() => (
    selectFile(event => whenFilesSelected(event.currentTarget.files))
  ), [selectFile, whenFilesSelected]);

  return (
    <UploadBroadcastContent
      backButton={ context.closeButton }
      error={error}
      title={content.title}
      description={content.description}
      navigationProps={context.navigationProps}
      uploadStates={contentUploadStates}
      onUploadClicked={whenUploadClicked}
      responseSaved={!!response}
      uploadHidden={!!response || contentUploadStates.size >= content.upload_limit}
      submitButtonProps={submitButtonProps}
      submitHidden={!!response || !completedUploadIds.length}
      submitDisabled={contentUploadStates.size !== completedUploadIds.length}
      onRemoveClicked={remove}
      nudge={context.nudge}
    />
  );
};
