import { useContext } from 'react';

import { ContentDefinitionRegistryContext } from '../Context';
import { ContentDefinitionRegistry } from '../Service';

export const useContentDefinitionRegistry = (): ContentDefinitionRegistry => {
  const contentDefinitionRegistry = useContext(ContentDefinitionRegistryContext);

  if (!contentDefinitionRegistry) {
    throw new Error('Content definition context used without provider.');
  }

  return contentDefinitionRegistry;
};
