import styled from '@emotion/styled';

import { ConditionalTooltip } from '../../../../../src/react/Common/Component/ConditionalTooltip/ConditionalTooltip';

export const StyledIconContainer = styled.div`
  position: relative;
`;

export const StyledIconOnlyBadgeContainer = styled.div`
  position: absolute;
  top: 0;
  right: -${ props => props.theme.new.spacing[2] };
`;

export const StyledIconSpacer = styled.div`
  width: ${ props => props.theme.new.spacing[6] };
`;

export const StyledConditionalTooltip = styled(ConditionalTooltip)`
  width: 100%;
`;
