import { EventHandler, StreamChat } from 'stream-chat';
import { createContext, FC, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { Chat } from 'stream-chat-react';
import { OpStreamChatGenerics } from '@ourpeople/shared/Chat/Model/Stream';
import { OpStreamChat } from '@ourpeople/shared/Chat/Model/ChatContext';
import * as Sentry from '@sentry/browser';
import { LoadingState } from 'op-storybook/stories/components/LoadingState/LoadingState';

import { AuthDescription } from '../../../Models/AuthDescription';
import { useMounted } from '../../../Common/Hook/useMounted';
import { useLoggedInAuthDescription } from '../../../Core/Hook/useLoggedInAuthDescription';
import { TextFormatter } from '../../../Utility/TextFormatter/TextFormatter';

export enum ConnectionError {
  GENERIC,
  CONNECTION,
}

export enum ConnectionState {
  CONNECTING,
  CONNECTED,
  FAILED,
}

type FailedConnectionContextValue = {
  reconnect: () => void;
  connectionState: ConnectionState.FAILED;
  connectionError: ConnectionError;
  notificationCount: number | undefined;
}

type NonFailedConnectionContextValue = {
  reconnect: () => void;
  connectionState: Exclude<ConnectionState, ConnectionState.FAILED>;
  connectionError: null;
  notificationCount: number | undefined;
};

type StreamConnectionContextValue = FailedConnectionContextValue | NonFailedConnectionContextValue;

export const StreamConnectionContext = createContext<StreamConnectionContextValue | null>(null);
StreamConnectionContext.displayName = 'StreamConnectionContext';

export const StreamConnectionProvider: FC<PropsWithChildren> = ({ children }) => {
  const mounted = useMounted();
  const authDescription = useLoggedInAuthDescription();
  const [streamClient, setStreamClient] = useState<OpStreamChat | null>(null);
  const [notificationCount, setNotificationCount] = useState<number | undefined>(undefined);
  const [connectionState, setConnectionState] = useState<ConnectionState>(ConnectionState.CONNECTING);
  const [connectionError, setConnectionError] = useState<ConnectionError | null>(null);

  const handleClientError = useCallback((error: unknown) => {
    if (!mounted.current) {
      return;
    }

    const errorMessage = (error as Error)?.message || error;
    let errorType: ConnectionError = ConnectionError.GENERIC;
    if (typeof errorMessage === 'string') {
      try {
        const parsedError = JSON.parse(errorMessage) as Record<string, unknown>;
        if (parsedError.isWSFailure) {
          errorType = ConnectionError.CONNECTION;
        }
      } catch { /* Keep ConnectionError.GENERIC */
      }
    }

    setConnectionError(errorType);
    setConnectionState(ConnectionState.FAILED);
    Sentry.captureException(
      error,
      {
        tags: {
          streamUserId: authDescription.streamChat.userId,
        },
      },
    );
  }, [authDescription, mounted]);

  const connectAccount = useCallback(
    async () => {
      if (streamClient) {
        return;
      }

      try {
        setConnectionState(ConnectionState.CONNECTING);
        setConnectionError(null);
        const newStreamClient = await createStreamClient(authDescription);
        setStreamClient(newStreamClient);
        setNotificationCount(newStreamClient.user.unread_channels as number || undefined);
        const whenNotificationCountChange: EventHandler<OpStreamChatGenerics> = (event) => {
          setNotificationCount(event.unread_channels || undefined);
        };
        newStreamClient.on('message.new', whenNotificationCountChange);
        newStreamClient.on('notification.mark_read', whenNotificationCountChange);
        setConnectionState(ConnectionState.CONNECTED);
      } catch (error) {
        handleClientError(error);
      }
    },
    [authDescription, handleClientError, streamClient],
  );

  const disconnect = useCallback(async (): Promise<void> => {
    if (!streamClient) {
      return;
    }

    await streamClient.disconnectUser();
    setConnectionState(ConnectionState.CONNECTING);
    setStreamClient(null);
    setNotificationCount(undefined);
  }, [streamClient]);

  const disconnectCurrentClientAndConnectAccount = useCallback(async () => {
    await disconnect();
    await connectAccount();
  }, [connectAccount, disconnect]);

  useEffect(
    () => {
      if (streamClient) {
        return
      }

      void connectAccount();
    },
    [
      streamClient,
      connectAccount,
      disconnectCurrentClientAndConnectAccount,
    ],
  );

  const reconnect = useCallback(
    () => disconnectCurrentClientAndConnectAccount(),
    [disconnectCurrentClientAndConnectAccount],
  );

  const contextValue = useMemo<StreamConnectionContextValue>(() => ({
    reconnect,
    notificationCount,
    ...(connectionState === ConnectionState.FAILED ? {
        connectionError: connectionError || ConnectionError.GENERIC,
        connectionState,
      } : {
        connectionState,
        connectionError: null,
      })
  }), [connectionError, connectionState, notificationCount, reconnect]);

  useEffect(() => {

  })

  return (
    <StreamConnectionContext.Provider value={ contextValue }>
      {
        connectionState === ConnectionState.FAILED
          ? children
          : streamClient
            ? (
              <Chat client={ streamClient }>
                { children }
              </Chat>
            )
            : <LoadingState pad/>
      }
    </StreamConnectionContext.Provider>
  );
};

const createStreamClient = async (
  authDescription: AuthDescription,
): Promise<OpStreamChat> => {
  const apiKey = authDescription.streamChat.apiKey;
  const userId = authDescription.streamChat.userId;
  const userToken = authDescription.streamChat.userToken;
  const user = {
    id: userId,
    name: TextFormatter.spaceSeparated(authDescription.user.forename, authDescription.user.lastname || ''),
    opPersonId: authDescription.user.uuid,
  };

  const newStreamClient = new StreamChat<OpStreamChatGenerics>(apiKey, { timeout: 5000 });
  await newStreamClient.connectUser(user, userToken);
  return newStreamClient as OpStreamChat;
};
