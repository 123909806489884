import { DraftSingleContentCard } from '../../Content/Model';
import { Audience } from '../../Audiences/Model';
import { DraftFormContent } from './Content';

export const FORM_NAME_MAX_LENGTH = 255;

interface Form {
  name: string | null;
  viewAudience: Audience;
  adminAudiencePersonIds: string[] | null;
  reportingAudiencePersonIds: string[] | null;
  notificationAudiencePersonIds: string[] | null;
  categoryId: string | null;
  statusTrackerEnabled: boolean;
  anonymousSubmissionsEnabled: boolean;
}

export interface DraftSingleContentForm extends Form {
  cards: DraftSingleContentCard<DraftFormContent>[];
}
