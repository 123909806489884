import { FC, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Doughnut } from 'react-chartjs-2';
import { ChartData, ChartOptions } from 'chart.js';
import { useTheme } from '@emotion/react';
import { Heading } from '@ourpeople/shared/Core/Component/Content';

import { RespondedCounts } from '../../../../Sections/Broadcasts/Report/Hooks/useFetchBroadcastReportSummary';
import { Legend } from '../../../../Reporting/Component';
import { StyledChannelEngagementChart } from './style';
import { Flex, FlexPullRight } from '../../../../Common/Component';

type Props = {
  respondedCounts: RespondedCounts;
};

export const ChannelEngagementChart: FC<Props> = ({
  respondedCounts,
}) => {
  const intl = useIntl();
  const theme = useTheme();
  const localisedLabels = useMemo(() => new Map([
    [
      'web', intl.formatMessage({
      id: 'broadcasts.report.channelEngagement.web',
      description: 'Label for web response source in channel engagement chart.',
      defaultMessage: 'Online portal',
    })
    ],
    [
      'app', intl.formatMessage({
      id: 'broadcasts.report.channelEngagement.app',
      description: 'Label for app response source in channel engagement chart.',
      defaultMessage: 'App',
    })
    ],
  ]), [intl]);

  const options: ChartOptions<'doughnut'> = {
    plugins: {
      legend: {
        display: false,
      },
    },
    maintainAspectRatio: false,
    cutout: '50%',
    responsive: true,
  };

  const colours = [theme.palette.secondary.main, theme.palette.secondary.light];
  const chartData: Required<ChartData<'doughnut'>> = {
    datasets: [
      {
        data: respondedCounts.sources.map(source => source.total),
        backgroundColor: colours,
      }
    ],
    labels: respondedCounts.sources.map(source => localisedLabels.get(source.source) || source.source)
  };

  return (
    <StyledChannelEngagementChart>
      <Heading type="h3">
        <FormattedMessage
          id="broadcasts.report.serviceEngagement.heading"
          description="Heading for service engagement report."
          defaultMessage="Engagement by service"
        />
      </Heading>
      <Flex><FlexPullRight><Legend data={ chartData }/></FlexPullRight></Flex>
      <div>
        <Doughnut
          data={ chartData }
          options={ options }
        />
      </div>
    </StyledChannelEngagementChart>
  );
};
