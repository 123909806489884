import { FetchResponse, useGet } from '../../Hooks';
import { ListedTopic } from '../Model';
import { Paginated } from '../../Models';

export type FetchTopicsSort = 'name_asc' | 'name_desc' | 'created_at_asc' | 'created_at_desc';

export type FetchTopicsParams = {
  pageNum: number;
  sort: FetchTopicsSort;
  search?: string;
  ids?: string;
};

export const useFetchTopics = (params: FetchTopicsParams): FetchResponse<Paginated<'topics', ListedTopic>> => useGet('/chats/topics', params);
