import { FC, useCallback, useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { TransitionProps } from '@mui/material/transitions';
import { CenteredGenericErrorMessage, CenteredMessageWithLoadingCard, Heading } from '@ourpeople/shared/Core/Component/Content';
import { AutocompleteSelectionChanged } from '@ourpeople/shared/Core/Component/Input/Autocomplete/Autocomplete';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';

import { PersonAutocomplete } from '../../../People/Component';
import { Person } from '../../../Models';
import {
  Flex,
  FlexPullRight,
  IconButton
} from '../../../Common/Component';
import CloseIcon from '../../../Assets/img/icons/streamline/close.svg';
import { StyledTitle } from './style';

type Props = {
  title: string;
  open: boolean;
  personIds: string[] | undefined;
  onShare: (personIds: string[]) => void;
  onCancel: () => void;
  onReloadClicked?: () => void;
  TransitionProps?: TransitionProps;
  error?: boolean;
};

export const ShareDraftDialog: FC<Props> = ({
  title,
  open,
  personIds,
  onShare,
  onCancel,
  onReloadClicked,
  TransitionProps,
  error,
}) => {
  const intl = useIntl();
  const [temporaryPersonIds, setTemporaryPersonIds] = useState<string[]>([]);

  useEffect(() => {
    if (!personIds) {
      return;
    }

    setTemporaryPersonIds(personIds);
  }, [personIds]);

  const whenSelectionChanged: AutocompleteSelectionChanged<Person> = selection => (
    setTemporaryPersonIds(selection.options.map(person => person.id).concat(selection.unknownIds))
  );

  const whenShareClicked = useCallback(() => onShare(temporaryPersonIds), [temporaryPersonIds, onShare]);

  const cancelButtonLabel = intl.formatMessage({
    id: 'shareDraftDialog.cancel',
    description: 'Label for cancel button in share draft dialog.',
    defaultMessage: 'Cancel',
  });

  return (
    <Dialog
      open={ open }
      onClose={ onCancel }
      TransitionProps={ TransitionProps }
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        <Flex gap={ 2 } noWrap>
          <StyledTitle>{ title }</StyledTitle>
          <FlexPullRight>
            <IconButton
              IconComponent={ CloseIcon }
              label={ cancelButtonLabel }
              onClick={ onCancel }
              size="small"
/>
          </FlexPullRight>
        </Flex>
      </DialogTitle>
      <DialogContent>
        <Heading type="h4">
          <FormattedMessage
            id="shareDraftDialog.subheading"
            description="Subheading in share draft dialog."
            defaultMessage="Save and share draft with"
          />
        </Heading>
        {
          personIds
            ? (
              <PersonAutocomplete
                label={ intl.formatMessage({
                  id: 'shareDraftDialog.label',
                  description: 'Label for input in share draft dialog.',
                  defaultMessage: 'Select editors',
                }) }
                placeholder={ intl.formatMessage({
                  id: 'shareDraftDialog.placeholder',
                  description: 'Placeholder for input in share draft dialog.',
                  defaultMessage: 'Search for editors…',
                }) }
                selectedIds={ temporaryPersonIds }
                onSelectionChanged={ whenSelectionChanged }
                multiple
                baseQuery={ personAutocompleteQuery }
              />
            )
            : error
              ? <CenteredGenericErrorMessage onRetryClicked={ onReloadClicked }/>
              : (
                <CenteredMessageWithLoadingCard
                  heading={ intl.formatMessage({
                    id: 'shareDraftDialog.loading',
                    description: 'Message displayed when loading editors in share draft dialog.',
                    defaultMessage: 'Fetching editors…',
                  }) }
                />
              )
        }
      </DialogContent>
      <DialogActions>
        <Button
          onClick={ onCancel }
        >
          { cancelButtonLabel }
        </Button>
        <Button
          variant="primary"
          onClick={ whenShareClicked }
        >
          <FormattedMessage
            id="shareDraftDialog.share"
            description="Label for share button in share draft dialog."
            defaultMessage="Share"
          />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const personAutocompleteQuery = {
  sort: ['first_name_asc'],
  roleIds: ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_BROADCAST_ADMIN'],
};
