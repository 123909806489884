import styled from '@emotion/styled';

import ChevronIcon from '../../../../Assets/img/icons/monochrome/chevron.svg';

export const StyledBackArrow = styled(ChevronIcon)`
  transform: rotate(90deg);
`;

export const StyledTemplateGallery = styled.div`
  min-height: 410px;
  display: flex;
  align-items: center;
  width: 100%;

  >div {
    width: 100%;
  }
`;
