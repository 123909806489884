import {Paginated} from '../../Models';
import {FormAndSubmissionCounts} from '../Model';
import {FetchResponse, useGet} from '../../Hooks';

export type FetchFormsSort = 'created_at_asc'
  | 'created_at_desc'
  | 'name_asc'
  | 'name_desc'
  | 'category_name_asc'
  | 'category_name_desc';

export type FetchFormsParams = {
  pageNum: number;
  sort: FetchFormsSort;
  ids?: string;
  categoryIds?: string | null;
  search?: string | null;
  publishStatuses?: string | null;
}

export const useFetchForms = (params: FetchFormsParams): FetchResponse<Paginated<'forms', FormAndSubmissionCounts>> => (
  useGet<Paginated<'forms', FormAndSubmissionCounts>>('/forms', params)
);
