import { TextField } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { IntlShape } from 'react-intl/src/types';

import { MappedSettingTypes } from '../../Types';
import { ValidationErrorMessage } from '../../../../../Common/Component';

type Props = {
  config: MappedSettingTypes['number']['config'],
  value: MappedSettingTypes['number']['value'],
  onValueChanged: (value: MappedSettingTypes['number']['value']) => void,
  focusId?: string,
};

export const NumberSettingInput = ({
  config,
  value,
  onValueChanged,
  focusId,
}: Props): JSX.Element => {
  const intl = useIntl();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const error = validate(value, config, intl);
    setError(error);
  }, [value, config, intl]);

  const whenValueChanged = (event: ChangeEvent<HTMLInputElement>): void => {
    const value = +event.target.value;
    onValueChanged(value);
  }

  return (
    <div>
      <TextField
        type="number"
        id={focusId}
        variant="outlined"
        fullWidth={true}
        error={error !== null}
        value={String(value)}
        onChange={whenValueChanged}
        inputProps={{
          step: config.step,
          min: config.minNumber,
          max: config.maxNumber,
        }}
      />
      {
        error
        && (
          <ValidationErrorMessage>{error}</ValidationErrorMessage>
        )
      }
    </div>
  );
};

const validate = (
  value: MappedSettingTypes['number']['value'],
  config: MappedSettingTypes['number']['config'],
  intl: IntlShape,
): string | null => {
  if (value === null && config.nullable) {
    return null;
  }

  if (value === null) {
    return intl.formatMessage({
      id: 'settings.numberSetting.nullError',
      defaultMessage: 'Missing value',
    });
  }

  if (config.minNumber !== null && value < config.minNumber) {
    return intl.formatMessage(
      {
        id: 'settings.numberSetting.minNumberError',
        defaultMessage: 'Must be no smaller than {minNumber}',
      },
      {
        minNumber: config.minNumber,
      }
    );
  }

  if (config.maxNumber !== null && value > config.maxNumber) {
    return intl.formatMessage(
      {
        id: 'settings.numberSetting.maxNumberError',
        defaultMessage: 'Must be no larger than {maxNumber}',
      },
      {
        maxNumber: config.maxNumber,
      }
    );
  }

  return null;
}
