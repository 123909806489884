import { EditorPreview } from '@ourpeople/shared/Core/Component/Input/RichTextEditor/EditorPreview/EditorPreview';

import { MappedSettingTypes } from '../../Types';
import { SafeRichTextContent } from '../../../../../Common/Component/SafeRichTextContent/SafeRichTextContent';

type Props = {
  value: MappedSettingTypes['html']['value'],
};

export const HtmlSettingValue = ({
  value,
}: Props): JSX.Element => {
  return (
    <EditorPreview>
      <SafeRichTextContent
        text={ value || '' }
      />
    </EditorPreview>
  );
};
