import { IntlShape } from 'react-intl';

import SignRequestIcon from '../../../Assets/img/icons/monochrome/content/sign.svg';
import { EditorContent, SingleContentCard } from '../../../Content/Model';
import { UniqueIdGenerator } from '../../../Common/Utility';
import { CardFactory, CardTransformer } from '../../../Content/Utility';
import { DraftResponse, FormContentDefinition, Response } from '../../Model';
import { EnvironmentSettings } from '../../../Models';
import { DraftSignRequest, SignRequest } from '../../Model/ContentTypes/SignRequest';
import { ValidationTree } from '../../../Common/Model';
import { RequestValidator } from '../../Utility/Validation';
import { SignRequestFields } from '../../Component/Content/SignRequestFields/SignRequestFields';
import { SignRequestPreview } from '../../Component/Content/SignRequestPreview/SignRequestPreview';
import { LiveSignRequest } from '../../Component/LiveContent/LiveSignRequest/LiveSignRequest';
import { SubmittedSignRequest } from '../../Component/SubmittedContent/SubmittedSignRequest/SubmittedSignRequest';

export type SignRequestEditorContent = EditorContent<DraftSignRequest>;
type BaseRatingResponse = {
  contentType: 'signatureRequest';
  signature: string;
};
export type DraftSignResponse = DraftResponse<BaseRatingResponse>;
export type SignResponse = Response<BaseRatingResponse>;

export class SignRequestDefinition implements FormContentDefinition<
  SignRequest,
  SignRequestEditorContent,
  DraftSignResponse,
  SignResponse
> {
  public readonly availableToCreate = true;
  public readonly id = 'signatureRequest';
  public readonly new = true;
  public readonly richText = false;
  public readonly contentTypes = ['signatureRequest'];
  public readonly EditorComponent = SignRequestFields;
  public readonly PreviewComponent = SignRequestPreview;
  public readonly LiveComponent = LiveSignRequest;
  public readonly SubmittedComponent = SubmittedSignRequest;
  public readonly IconComponent = SignRequestIcon;
  public readonly categoryId = 'input';
  public readonly identifiesUser = true;

  public definesContentType = (type: string): boolean => (
    this.contentTypes.includes(type)
  );

  public createEditorContent = (): SignRequestEditorContent => ({
    id: UniqueIdGenerator.generate(),
    card: CardFactory.fromDraftContent({
      type: 'signatureRequest',
      title: '',
      text: '',
      maxSignature: null,
      minSignature: null,
    }),
  });

  public getContentTitle = (intl: IntlShape, content: SignRequest): string => (
    content.title || this.getDefaultTitle(intl)
  );

  public getDraftContentTitle = (intl: IntlShape, draftContent: DraftSignRequest): string => (
    draftContent.title || this.getDefaultTitle(intl)
  );

  public hasRequiredFeatureFlags = (environmentSettings: EnvironmentSettings): boolean => (
    !!environmentSettings.formsEnabled
  );

  public getLocalisedType = (intl: IntlShape): string => intl.formatMessage({
    description: 'Localised name for sign request type.',
    defaultMessage: 'Sign',
  });

  public transformCard = (card: SingleContentCard<SignRequest>): SignRequestEditorContent => ({
    id: card.id,
    card: CardTransformer.transformCard(card, card.content),
  });

  public validate = (draftContent: DraftSignRequest): ValidationTree<DraftSignRequest> =>
    RequestValidator.validate(draftContent);

  private getDefaultTitle = (intl: IntlShape): string => intl.formatMessage({
    description: 'Default title for sign request.',
    defaultMessage: 'Untitled sign',
  });
}
