import styled from '@emotion/styled';
import { FormLabel, FormLabelProps } from '@mui/material';

export const StyledFormControl = styled.div`
  fieldset {
    align-items: flex-start;
    margin-top: 1rem;

    > .MuiButtonBase-root {
      margin-top: 1rem;
    }
  }
`;

export const StyledFormLabel = styled((props: FormLabelProps<'legend'>) => (
  <FormLabel
    component="legend"
    {...props }
  />
))`
  margin-top: 2rem;
  font-weight: 500;
  color: ${ props => props.theme.palette.text.primary };

  &.Mui-focused {
    color: ${ props => props.theme.palette.text.primary };
  }
`;
