import { FunctionComponent } from 'react';

import { Broadcast, } from '../../Model';
import { BroadcastTypeHelper } from '../../Utility';
import { ContentIcon } from '../../../Sections/Broadcasts/Common/ContentIcon';

type BroadcastIconProps = {
  broadcast: Broadcast;
};

export const BroadcastIcon: FunctionComponent<BroadcastIconProps> = ({ broadcast }) => {
  return <ContentIcon contentType={BroadcastTypeHelper.typeFromContents(broadcast.contents || [])} />
};
