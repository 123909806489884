import { FC, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { defaultPlaceholders, Editor } from '@ourpeople/shared/Core/Component/Input/RichTextEditor/Editor/Editor';

import { ContentEditorProps } from '../../../../Content/Model';
import {
  MEDIA_TEXT_MAX_LENGTH,
  MEDIA_TEXT_MIN_LENGTH,
  MediaContentValidator,
  TEXT_CONTENT_HTML_MAX_LENGTH,
  VIDEO_DESCRIPTION_MAX_LENGTH,
  VIDEO_DESCRIPTION_MIN_LENGTH,
  VideoContentValidator,
  VideoEditorContent,
} from '../../../Service';
import { Upload } from '../../../../Types';
import { useInputChangeEventHandler } from '../../../../Common/Hook';
import {
  FieldValidationErrors,
  Flex,
  FlexPullRight,
  NoMarginTextField,
  VerticallySpaced
} from '../../../../Common/Component';
import { FileUploadInput } from '../../../../Files/Component';
import { CharacterCount } from '../../../../Content/Component';
import { RichTextSanitiser } from '../../../../Common/Utility';
import { ThumbnailSelectorField } from '../../../../Content/Component/ThumbnailSelectorField/ThumbnailSelectorField';

export const VideoContentEditor: FC<ContentEditorProps<VideoEditorContent>> = ({
  editorContent,
  onEditorContentChanged,
  validation,
  onValidationChanged,
}) => {
  const intl = useIntl();

  const whenFileUploaded = useCallback((upload: Upload) => {
    onEditorContentChanged({
      ...editorContent,
      media: upload,
      prepareStatus: 'pending',
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          media: upload.id.toString(),
        },
      },
    });
    onValidationChanged({
      errors: [],
      children: {
        content: {
          errors: [],
          children: {
            ...validation?.children.content?.children,
            media: MediaContentValidator.validateMedia(upload.id.toString()),
          },
        },
      },
    })
  }, [editorContent, onEditorContentChanged, onValidationChanged, validation?.children.content?.children]);

  const whenFileRemoved = useCallback(() => (
    onEditorContentChanged({
      ...editorContent,
      media: null,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          media: '',
        },
      },
    })
  ), [editorContent, onEditorContentChanged]);

  const whenTextChanged = useInputChangeEventHandler(useCallback(text => (
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          text,
        },
      },
    })
  ), [editorContent, onEditorContentChanged]));

  const whenTextBlurred = useCallback(() => {
    onValidationChanged({
      errors: [],
      children: {
        content: {
          errors: [],
          children: {
            ...validation?.children.content?.children,
            text: MediaContentValidator.validateText(editorContent.card.content.text),
          },
        },
      },
    })
  }, [editorContent.card.content.text, onValidationChanged, validation?.children.content?.children]);

  const textFieldLabel = intl.formatMessage({
    id: 'broadcasts.content.video.fields.text.label',
    description: 'Label for text field of broadcasts file content editor.',
    defaultMessage: 'Title',
  });

  const fileUploadLabel = intl.formatMessage({
    id: 'broadcasts.content.video.fields.upload.label',
    description: 'Label for upload field of broadcasts file content editor.',
    defaultMessage: 'File',
  });

  const whenThumbnailKeyChanged = useCallback((thumbnailKey: string) => {
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          thumbnailKey,
        },
      },
    });

    onValidationChanged({
      errors: [],
      children: {
        content: {
          errors: [],
          children: {
            ...validation?.children.content?.children,
            thumbnailKey: VideoContentValidator.validateThumbnailKey(thumbnailKey),
          },
        },
      },
    });
  }, [editorContent, onEditorContentChanged, onValidationChanged, validation?.children.content?.children]);

  const whenDescriptionChanged = useCallback((description: string) => {
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          description: description || null,
        },
      },
    })
  }, [editorContent, onEditorContentChanged]);

  const whenDescriptionBlurred = useCallback(() => {
    onValidationChanged({
      errors: [],
      children: {
        content: {
          errors: [],
          children: {
            ...validation?.children.content?.children,
            ...(editorContent.card.content.description ? {
                description: VideoContentValidator.validateDescription(editorContent.card.content.description),
              } : {}),
          },
        },
      },
    });
  }, [editorContent.card.content.description, onValidationChanged, validation?.children.content?.children]);

  return (
    <>
      <VerticallySpaced gap={ 1 }>
        <h4>{ textFieldLabel }</h4>
        <NoMarginTextField
          fullWidth
          value={ editorContent.card.content.text }
          onChange={ whenTextChanged }
          onBlur={ whenTextBlurred }
        />
        <CharacterCount
          current={ editorContent.card.content.text.length || 0 }
          minimum={ MEDIA_TEXT_MIN_LENGTH }
          maximum={ MEDIA_TEXT_MAX_LENGTH }
        />
        <FieldValidationErrors
          fieldName={ textFieldLabel }
          validationErrors={ validation?.children.content?.children.text?.errors || [] }
        />
      </VerticallySpaced>
      <VerticallySpaced gap={ 1 }>
        <h4>{ fileUploadLabel }</h4>
        <FileUploadInput
          id={ editorContent.id }
          mimeType="video/*"
          fileUpload={ editorContent.media || undefined }
          onFileUploaded={ whenFileUploaded }
          onFileRemoved={ whenFileRemoved }
        />
        <FieldValidationErrors
          fieldName={ fileUploadLabel }
          validationErrors={ validation?.children.content?.children.media?.errors || [] }
        />
      </VerticallySpaced>
      <ThumbnailSelectorField
        busy={ editorContent.prepareStatus === 'running' }
        errors={ validation?.children.content?.children.thumbnailKey?.errors || [] }
        onChange={ whenThumbnailKeyChanged }
        selectedKey={ editorContent.card.content.thumbnailKey || undefined }
        uploadId={ editorContent.media?.id ? String(editorContent.media.id) : undefined }
      />
      <VerticallySpaced gap={ 1 }>
        <Flex>
          <Flex gap={ 1 }>
            <h4>
              <FormattedMessage
                id="broadcasts.content.image.fields.description.label"
                description="Label for description input in broadcasts video content."
                defaultMessage="Body copy"
              />
            </h4>
            <FormattedMessage
              id="broadcasts.content.image.fields.description.optional"
              description="Optional marker for video description header."
              defaultMessage="(optional)"
            />
          </Flex>
          <FlexPullRight>
            <CharacterCount
              minimum={ VIDEO_DESCRIPTION_MIN_LENGTH }
              maximum={ VIDEO_DESCRIPTION_MAX_LENGTH }
              current={
                editorContent.card.content.description
                  ? RichTextSanitiser.stripTags(editorContent.card.content.description).length
                  : 0
              }
            />
          </FlexPullRight>
        </Flex>
        <Editor
          availablePlaceholders={ defaultPlaceholders }
          value={ editorContent.card.content.description || '' }
          onChange={ whenDescriptionChanged }
          onBlur={ whenDescriptionBlurred }
          analyticsContext="Broadcast video content"
          aiPromptOptions={ {
            maxLength: TEXT_CONTENT_HTML_MAX_LENGTH,
          } }
        />
        <FieldValidationErrors
          fieldName={ intl.formatMessage({
            id: 'broadcasts.content.video.fields.description.name',
            defaultMessage: 'Body copy',
          }) }
          validationErrors={ validation?.children.content?.children.description?.errors || [] }
        />
      </VerticallySpaced>
    </>
  );
};
