import { Dialog } from '@mui/material';
import styled from '@emotion/styled';

export const StyledTagDeletionDialog = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    min-width: 25rem;
    max-width: 50rem;
    width: 40vw;
  }

  .MuiTextField-root {
    width: 50%;
    min-width: 20rem;
  }
`;

export const DeletionErrorMessage = styled.p`
  color: ${props => props.theme.palette.error.main};
`;

export const LoadingSpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10rem;
`;
