import { FC, PropsWithChildren } from 'react';

import { StyledTextFieldLeadingText } from './style';
import { Typography } from '../../../stories/components/Typography/Typography';

type Props = {
  onClick?: () => void;
  disabled?: boolean;
};

export const TextFieldLeadingText: FC<PropsWithChildren<Props>> = ({
  onClick,
  disabled,
  children,
}) => (
  <StyledTextFieldLeadingText
    onClick={ onClick }
    disabled={ !!disabled }
  >
    <Typography
      palette={ {
        colour: 'grey',
        intensity: 600,
      } }
      weight="regular"
    >
      { children }
    </Typography>
  </StyledTextFieldLeadingText>
);
