import { FC, FormEventHandler, useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { AuthHeader } from 'op-storybook/lib/components/AuthHeader/AuthHeader';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { Form } from 'op-storybook/lib/components/Form/Form';
import { PasswordField } from 'op-storybook/stories/components/PasswordField/PasswordField';
import { UsernameParser } from 'op-storybook/lib/utility/UsernameParser/UsernameParser';
import { Username } from 'op-storybook/stories/components/UsernameField/UsernameField';
import { PolicyLinks } from 'op-storybook/lib/components/PolicyLinks/PolicyLinks';

import { IdentityAuthenticationIdentity } from '../../../Core/Model/Identity/Identity';
import { AnonymousSettings } from '../../../Security/Hook';
import { PasswordLoginSubmitButton } from '../PasswordLoginSubmitButton/PasswordLoginSubmitButton';
import { useLoginRequest } from '../../../Security/Hook/useLoginRequest';
import { RequestState } from '../../../Models';
import { OtpLoginLink } from '../OtpLoginLink/OtpLoginLink';
import { IdentityReader } from '../../../Core/Utility/IdentityReader/IdentityReader';
import { UserIdentity } from '../../../Forms/Component/UserIdentity/UserIdentity';

type Props = {
  identity: IdentityAuthenticationIdentity;
  anonymousSettings: AnonymousSettings;
};

export const IdentifiedUserLoginForm: FC<Props> = ({
  identity,
  anonymousSettings,
}) => {
  const intl = useIntl();
  const { login, loginRequest } = useLoginRequest();
  const [password, setPassword] = useState<string>('');
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const username = useMemo<Username | undefined>(() => (
    IdentityReader.identityHasIdentityAuthentication(identity)
      ? UsernameParser.parseUsername(
        {
          value: identity.authentication.method.username
        },
        anonymousSettings.defaultCountryCode,
      )
      : undefined
  ), [anonymousSettings.defaultCountryCode, identity]);

  const whenSubmitted: FormEventHandler<HTMLFormElement> = useCallback(event => {
    event.preventDefault();
    const loginDetails = UsernameParser.usernameValueIsEmailAddress(identity.authentication.method.username)
      ? {
        emailAddress: identity.authentication.method.username,
        password,
      }
      : {
        mobileNumber: identity.authentication.method.username,
        password,
      };

    login(
      loginDetails,
      rememberMe,
    );
  }, [identity.authentication.method.username, password, login, rememberMe]);

  return (
    <>
      <AuthHeader
        header={ intl.formatMessage({
          description: 'Heading used on login page when user has been identified.',
          defaultMessage: 'Welcome to { spaceName }',
        }, {
          spaceName: anonymousSettings.name,
        }) }
        supportingText={ intl.formatMessage({
          description: 'Supporting text used on login page when user has been identified.',
          defaultMessage: 'Enter your password to finish logging in',
        }) }
        graphic={ {
          type: 'logo',
          props: {
            src: anonymousSettings.smallLogoUrl,
            alt: anonymousSettings.name,
          },
        } }
      />
      <Stack
        direction="column"
        align="center"
      >
        <UserIdentity
          person={ identity.person }
          username={ identity.authentication.method.username }
          css={ {
            margin: '0 auto',
          } }
        />
        <Link
          to={ '/logout' }
          replace={ true }
          css={ { margin: '0 auto' } }
        >
          <FormattedMessage
            description="Link on login page when user has been identified to return to unidentified login"
            defaultMessage="Not this user? Go back"
          />
        </Link>
      </Stack>
      <Form onSubmit={ whenSubmitted }>
        <Stack
          direction="column"
          gap={ 4 }
        >
          <PasswordField
            value={ password }
            onChange={ setPassword }
          />
          <PasswordLoginSubmitButton
            disabled={ !password }
            submitting={ loginRequest.state === RequestState.FETCHING }
            showRememberMe={true}
            rememberMe={ rememberMe }
            onRememberMeChange={ setRememberMe }
            error={ loginRequest.state === RequestState.FAILED }
            forgotPasswordLink={ {
              enabled: true,
              username,
            } }
          />
        </Stack>
      </Form>
      <OtpLoginLink username={ username }/>
      <PolicyLinks
        policyLinks={ anonymousSettings.appUsagePolicyLinks }
        spaceName={ anonymousSettings.name }
      />
    </>
  );
};
