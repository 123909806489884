import { FC, FormEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { CountryCode } from 'libphonenumber-js';
import { Username } from 'op-storybook/stories/components/UsernameField/UsernameField';
import { AuthFullscreenLayout } from 'op-storybook/lib/components/AuthFullscreenLayout/AuthFullscreenLayout';
import { useCountdown } from 'op-storybook/lib/hooks/useCountdown';
import { UsernameValidator } from 'op-storybook/lib/utility/UsernameValidator/UsernameValidator';
import { useUsernameValidationErrors } from 'op-storybook/lib/hooks/useUsernameValidationErrors';
import { UsernameParser } from 'op-storybook/lib/utility/UsernameParser/UsernameParser';

import { useLoginRequest } from '../../Hook/useLoginRequest';
import { RequestState } from '../../../Models';
import { useTokenRequest } from '../../Hook/useSecurityTokenRequest';
import { CodeRequestForm } from '../../Component/CodeRequestForm/CodeRequestForm';
import { CodeEntryForm } from '../../Component/CodeEntryForm/CodeEntryForm';
import { ValidationError } from '../../../Common/Model';
import { IdentifiedUserOtpRequestPage } from '../IdentifiedUserOtpRequestPage/IdentifiedUserOtpRequestPage';
import { GuardedRoute } from '../../../Common/Component';
import { AnonymousSettings } from '../../Hook';
import { useContextOrThrow } from '../../../Core/Hook';
import { LoginStateContext } from '../../../Core/Provider/LoginStateProvider';
import { IdentityAuthenticationIdentity } from '../../../Core/Model/Identity/Identity';
import { PhoneNumberHelper } from '../../Utility';

type LocationState = {
  username?: Username;
  identity?: IdentityAuthenticationIdentity;
};

type Props = {
  defaultCountryCode: CountryCode;
  loginTargetUrl: string;
  anonymousSettings: AnonymousSettings;
};

export const LoginOtpPage: FC<Props> = ({
  defaultCountryCode,
  loginTargetUrl = '/',
  anonymousSettings,
}) => {
  const intl = useIntl();
  const { loggedIn } = useContextOrThrow(LoginStateContext);
  const history = useHistory<LocationState | undefined>();
  const [otp, setOtp] = useState<string>('');
  const [username, setUsername] = useState<Username>(
    history.location.state?.identity
      ? {
        value: history.location.state.identity.authentication.method.username,
        ...(UsernameParser.usernameValueIsEmailAddress(history.location.state.identity.authentication.method.username) ? {
            type: 'email'
          } : {
            selectedType: PhoneNumberHelper.getCountryFromPhoneNumber(history.location.state.identity.authentication.method.username)?.code ?? 'US',
            parsedPhone: {
              e164Value: history.location.state.identity.authentication.method.username,
              nationalValue: history.location.state.identity.authentication.method.username,
              determinedCountryCode: PhoneNumberHelper.getCountryFromPhoneNumber(history.location.state.identity.authentication.method.username)?.code ?? 'US',
            },
          }),
      }
      : history.location.state?.username || { value: '' }
  );
  const identity = useMemo(() => history.location.state?.identity, [history.location.state?.identity]);
  const { login, loginRequest } = useLoginRequest();
  const { requestToken, tokenRequest } = useTokenRequest();
  const remainingDurationInSeconds = useCountdown(
    (tokenRequest.state === RequestState.FAILED && tokenRequest.result.retryAfter) || 0
  );
  const [usernameErrors, setUsernameErrors] = useState<ValidationError[]>([]);
  const localisedUsernameErrors = useUsernameValidationErrors().formatErrorMessages(usernameErrors);
  const [rememberMe, setRememberMe] = useState<boolean>(!!identity);

  // Clear username errors when field is changed
  useEffect(() => {
    setUsernameErrors([]);
  }, [username]);

  const submitUsername = useCallback((username: Username) => {
    const usernameErrors = UsernameValidator.validate(username);

    if (usernameErrors.length) {
      setUsernameErrors(usernameErrors);
      return;
    }

    const data = username.parsedPhone?.e164Value
      ? {
        method: 'MOBILE_NUMBER',
        mobileNumber: username.parsedPhone.e164Value,
      }
      : {
        method: 'EMAIL_ADDRESS',
        emailAddress: username.value,
      };

    requestToken(data);
  }, [requestToken]);

  const whenUsernameSubmitted: FormEventHandler = useCallback((event) => {
    event.preventDefault();

    submitUsername(username);
  }, [submitUsername, username]);

  const submitOtp = useCallback((otp: string, rememberMeEnabled: boolean) => {
    setOtp(otp);
    const details = username.parsedPhone?.e164Value
      ? {
        mobileNumber: username.parsedPhone.e164Value,
        token: otp,
      }
      : {
        emailAddress: username.value,
        token: otp,
      };

    login(details, rememberMeEnabled);
  }, [username, login]);

  // Move to code entry step when code has been requested. This state is maintained so that the user
  // isn't returned to the first step if a code is re-requested from the second step.
  useEffect(() => {
    if (tokenRequest.state !== RequestState.COMPLETE) {
      return;
    }

    history.push('/login/otp/submit');
  }, [tokenRequest, history]);

  const returnLinkProps = useMemo(() => ({
    children: intl.formatMessage({
      description: 'Label for link to password login screen when logging in via OTP.',
      defaultMessage: 'Return to password login',
    }),
    to: '/logout',
  }), [intl]);

  return (
    <AuthFullscreenLayout>
      <Switch>
        <GuardedRoute
          path="/login/otp/request"
          condition={ !!identity && !loggedIn }
          redirect={ loginTargetUrl || '/' }
        >
          {
            identity
              ? (
                <IdentifiedUserOtpRequestPage
                  identity={ identity }
                  tokenRequest={ tokenRequest }
                  anonymousSettings={ anonymousSettings }
                  onSubmit={ requestToken }
                  remainingDurationInSeconds={ remainingDurationInSeconds }
                  rememberMe={ rememberMe }
                  onRememberMeChange={ setRememberMe }
                />
              )
              : <Redirect to="/login"/>
          }
        </GuardedRoute>
        <Route
          path="/login/otp"
          exact
        >
          <CodeRequestForm
            defaultCountryCode={ defaultCountryCode }
            username={ username }
            localisedUsernameErrors={ localisedUsernameErrors }
            onChange={ setUsername }
            onSubmit={ whenUsernameSubmitted }
            header={ intl.formatMessage({
              description: 'Heading text used on OTP login page',
              defaultMessage: 'Log in with a one time passcode',
            }) }
            supportingText={ intl.formatMessage({
              description: 'Supporting text used on OTP login page when user has been identified.',
              defaultMessage: 'Enter your email address or phone number and we’ll send you a one time passcode that you can use to log in.',
            }) }
            tokenRequest={ tokenRequest }
            remainingDurationInSeconds={ remainingDurationInSeconds }
            returnLinkProps={ returnLinkProps }
          />
        </Route>
        <Route
          path="/login/otp/submit"
          exact
        >
          <CodeEntryForm
            code={ otp }
            onChange={ setOtp }
            onResendCodeClicked={ () => submitUsername(username) }
            onSubmit={ submitOtp }
            remainingDurationInSeconds={ remainingDurationInSeconds }
            submitRequest={ loginRequest }
            username={ username }
            returnLinkProps={ returnLinkProps }
            showRememberMe={ true }
            rememberMe={ rememberMe }
            onRememberMeChange={ setRememberMe }
          />
        </Route>
        <Redirect to="/login"/>
      </Switch>
    </AuthFullscreenLayout>
  );
};
