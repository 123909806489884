import React, { FC } from 'react';
import MailIcon from 'op-storybook/lib/assets/icon/figma/mail-01.svg';
import { EmptyState } from 'op-storybook/stories/components/EmptyState/EmptyState';
import { useIntl } from 'react-intl';

export const CardError: FC = () => {
  const intl = useIntl();

  return (
    <div css={ { height: '100%', display: 'flex' } }>
      <EmptyState
        css={ {
          margin: 'auto',
        } }
        text={ intl.formatMessage({
          description: 'Heading when an unexpected error occurs when loading delivery contents.',
          defaultMessage: 'Something went wrong!'
        }) }
        supportingText={ intl.formatMessage({
          description: 'Message when an unexpected error occurs when loading delivery contents.',
          defaultMessage: 'Please try again or select another message.'
        }) }
        buttonProps={ [] }
        IconComponent={ MailIcon }
        breakpoint="desktop"
        pad
      />
    </div>
  )
};
