import { default as React } from 'react';
import { useIntl } from 'react-intl';

import { ChoiceOptionEditor, OptionEditorComponent } from '..';
import {
  CHOICE_OPTION_TEXT_MAX_LENGTH,
  CHOICE_OPTION_TEXT_MIN_LENGTH,
  ChoiceRequestValidator,
  DraftChoiceOption
} from '../../../Service';

export const ChoiceRequestOptionEditor: OptionEditorComponent<DraftChoiceOption> = ({
  option,
  validation,
  onChange,
  onValidationChange,
  removeDisabled,
  onRemoveClicked,
}) => {
  const intl = useIntl();

  return (
    <ChoiceOptionEditor
      option={ option }
      onChange={ onChange }
      validation={ validation }
      onValidationChange={ onValidationChange }
      removeDisabled={ removeDisabled }
      onRemoveClicked={ onRemoveClicked }
      localisedTextFieldLabel={ intl.formatMessage({
        id: 'broadcasts.content.choice.fields.optionText.label',
        description: 'Label for choice content option text',
        defaultMessage: 'Choice',
      }) }
      localisedDeleteButtonLabel={ intl.formatMessage({
        id: 'broadcasts.content.choice.deleteOptionLabel',
        description: 'Delete choice option button label visible only to screen-readers.',
        defaultMessage: 'Delete choice',
      }) }
      validateText={ ChoiceRequestValidator.validateOptionText }
      minLength={ CHOICE_OPTION_TEXT_MIN_LENGTH }
      maxLength={ CHOICE_OPTION_TEXT_MAX_LENGTH }
    />
  );
}
