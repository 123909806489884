import { FunctionComponent } from 'react';
import { CircularProgress } from '@mui/material';

type Props = {
  size?: 'large' | 'small';
}

export const LoadingSpinner: FunctionComponent<Props> = ({
  size = 'small',
}) => {
  return (
    <CircularProgress
      color="secondary"
      thickness={size === 'large' ? 4 : undefined}
      size={size === 'large' ? 45 : undefined }
    />
  );
};
