import { FetchResponse, useGet } from '../../Hooks';
import { MinimalPerson, Paginated } from '../../Models';
import { ImportOperation } from '../../Imports/Model';
import { MinimalTag, TagType } from '../../Tags/Model';

type ProfileOperation = {
  profileId: string;
  operationType: 'update' | 'create';
  tags: MinimalTag<TagType>[]
}

export type PeopleImportOperation = ImportOperation & {
  recordType: 'person';
  person: MinimalPerson;
  administratedTeams: MinimalTag<'team'>[];
  profiles: ProfileOperation[];
};

export type FetchPeopleImportOperationsParams = {
  pageNum: number;
  recordTypes?: string;
  operationTypes?: string;
  search?: string;
}

export const useFetchPeopleImportOperations = (importId: string, params: FetchPeopleImportOperationsParams): FetchResponse<Paginated<'operations', PeopleImportOperation>> => useGet(
  `/imports/${ importId }/operations`,
  params,
);
