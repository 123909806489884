import { FC, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { CropperWithControls } from '../../../../Common/Component/CropperWithControls/CropperWithControls';
import { CropperTransforms } from '../../../../Common/Component/Cropper/Cropper';
import { ToolbarClearance } from '../Layout/ToolbarClearance';
import { TitleBar } from '../Navigation/TitleBar';
import { MinimumInset } from '../Layout/MinimumInset';
import { FileAndSrc } from '../../../../Common/Model/FileAndSrc';
import { FileNameHelper } from '../../../../Common/Utility/FileNameHelper';
import { Sheet } from '../Layout/Sheet';

type Props = {
  fileName?: string;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  src: string;
  onSubmit: (fileAndSrc: FileAndSrc) => void;
};

export const ImageEditSheet: FC<Props> = ({
  fileName,
  open,
  onOpenChange,
  src,
  onSubmit,
}) => {
  const intl = useIntl();
  const [transforming, setTransforming] = useState<boolean>(false);
  const [state, setState] = useState<{ canvas: HTMLCanvasElement; transforms: CropperTransforms }>();

  const whenFileTransformed = useCallback((file: File) => {
    setTransforming(false);
    onSubmit({
      file,
      src: URL.createObjectURL(file),
    });
  }, [onSubmit]);

  const whenTransformConfirmed = useCallback(() => {
    if (!state?.canvas) {
      return;
    }

    setTransforming(true);
    const validFileName = FileNameHelper.createValidFileNameForUpload('.jpeg', fileName);
    try {
      state.canvas.toBlob(
        blob => {
          if (!blob) {
            return;
          }

          const file = new File([blob], validFileName, { type: 'image/jpeg' });
          whenFileTransformed(file);
          setTransforming(false);
        },
        'image/jpeg',
        0.7,
      );
    } catch {
      setTransforming(false);
    }
  }, [fileName, state?.canvas, whenFileTransformed]);

  return (
    <Sheet
      open={ open }
      onOpenChange={ onOpenChange }
    >
      <ToolbarClearance>
        <MinimumInset
          left={ 2 }
          right={ 2 }
        >
          <div
            css={ theme => ({
              paddingTop: theme.new.spacing[2],
            }) }
          >
            <CropperWithControls
              src={ src }
              onChange={ (canvas, transforms) => {
                setState({
                  canvas,
                  transforms,
                });
              } }
            />
          </div>
        </MinimumInset>
      </ToolbarClearance>
      <TitleBar
        title={ intl.formatMessage({
          description: 'Title for image cropper sheet.',
          defaultMessage: 'Edit image',
        }) }
        onClose={ () => onOpenChange(false) }
        contextMenuProps={ {
          actions: [
            {
              id: 'cropper-confirm',
              label: intl.formatMessage({
                description: 'Label for confirm button in image cropper sheet.',
                defaultMessage: 'Confirm',
              }),
              disabled: !state,
              onClick: whenTransformConfirmed,
              busy: transforming,
            },
          ],
        } }
      />
    </Sheet>
  );
};
