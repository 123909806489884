import styled from '@emotion/styled';

export const StyledLabeledInformation = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;

  h4 {
    color: ${props => props.theme.palette.grey[400]};
    font-weight: 400;
    font-size: 0.85rem;
    margin: 0;
  }

  > div {
    display: flex;

    >* {
      margin-right: 0.5rem;
    }
  }
`;
