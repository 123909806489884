import { IntlShape } from 'react-intl';

import { SingleContentCard, DraftContent, EditorContent } from '../../../../Content/Model';
import RatingRequestIcon from '../../../../Assets/img/icons/monochrome/content/rating.svg';
import { UniqueIdGenerator } from '../../../../Common/Utility';
import { CardTransformer } from '../../../../Content/Utility';
import { RatingRequestValidator } from '../../Validators';
import { RatingRequestEditor, RatingRequestPreview } from '../../../Component';
import { BroadcastContent, BroadcastContentDefinition } from '../../../Model';
import { LiveRatingContent } from '../../../../Inbox/Component';
import { RatingResponse } from '../../../../Inbox/Model';
import { EnvironmentSettings } from '../../../../Models';

export type RatingRequest = BroadcastContent & {
  type: 'ratingquestion';
  title: string;
};

export type DraftRatingRequest = DraftContent & {
  type: 'ratingquestion';
  title: string;
};

export type RatingEditorContent = EditorContent<DraftRatingRequest>;

export class RatingRequestDefinition implements BroadcastContentDefinition<RatingRequest, RatingEditorContent, RatingResponse> {
  public readonly id = 'rating';
  public readonly availableToCreate = true;
  public readonly new = false;
  public readonly richText = false;
  public readonly contentTypes = ['ratingquestion'];
  public readonly categoryId = 'feedback';
  public readonly IconComponent = RatingRequestIcon;
  public readonly EditorComponent = RatingRequestEditor;
  public readonly PreviewComponent = RatingRequestPreview;
  public readonly LiveComponent = LiveRatingContent;
  public readonly SubmittedComponent = (): null => null;
  public validate = RatingRequestValidator.validate;

  public definesContentType = (type: string): boolean => (
    this.contentTypes.includes(type)
  );

  public getContentTitle = (intl: IntlShape, content: RatingRequest): string => (
    content.title || this.getDefaultTitle(intl)
  );

  public getLocalisedType = (intl: IntlShape): string => (
    intl.formatMessage({
      id: 'broadcasts.content.rating.type',
      description: 'Localised name for rating content type.',
      defaultMessage: 'Rating',
    })
  );

  public getDraftContentTitle = (intl: IntlShape, draftContent: DraftRatingRequest): string => (
    draftContent.title || this.getDefaultTitle(intl)
  );

  public hasRequiredFeatureFlags = (environmentSettings: EnvironmentSettings): boolean => (
    !!environmentSettings.ratingTypeEnabled
  );

  public createEditorContent = (): RatingEditorContent => ({
    id: UniqueIdGenerator.generate(),
    card: {
      content: {
        type: 'ratingquestion',
        title: '',
      },
      mandatory: false,
      image: null,
    },
  });

  public transformCard = (card: SingleContentCard<RatingRequest>): RatingEditorContent => ({
    id: card.id,
    card: CardTransformer.transformCard(card, {
      type: 'ratingquestion',
      title: card.content.title,
    }),
  });

  private getDefaultTitle = (intl: IntlShape): string => (
    intl.formatMessage({
      id: 'broadcasts.content.rating.defaultTitle',
      description: 'Default title for rating content.',
      defaultMessage: 'Untitled rating card',
    })
  );
}

export const RATING_REQUEST_MAX_RATING = 5;
