import { IntlShape } from 'react-intl';
import ImageBlockIcon from 'op-storybook/lib/assets/icon/figma/image-01.svg';
import {
  BlankValidationErrorDefinition
} from 'op-storybook/lib/model/Validation/Definition/BlankValidationErrorDefinition';

import { BlockErrorDefinitions, BroadcastBlockDefinition, PreviewSlot } from '../../Model/BroadcastBlockDefinition';
import { BroadcastBlock } from '../../Model/Broadcast';
import { UniqueIdGenerator } from '../../../../Common/Utility';
import { ImageBlockInlineForm } from '../../Component/Blocks/ImageBlock/ImageBlockInlineForm';
import { ImageBlockForm } from '../../Component/Blocks/ImageBlock/ImageBlockForm';
import { ImageBlockPreview } from '../../Component/Blocks/ImageBlock/ImageBlockPreview';
import { ImageBlockExpandedPreview } from '../../Component/Blocks/ImageBlock/ImageBlockExpandedPreview';

export type ImageBlock = {
  localId: string;
  kind: 'image';
  attributes: {
    uploadId: string;
    caption: {
      text: string;
      position: 'topLeft' | 'topCenter' | 'topRight' | 'bottomLeft' | 'bottomCenter' | 'bottomRight';
      backgroundColour: string;
      textColour: string;
    } | null;
  };
};

export class ImageBlockDefinition implements BroadcastBlockDefinition<ImageBlock> {
  public readonly prioritise = true;
  public readonly kind = 'image';
  public readonly localisedKind: string;

  constructor(intl: IntlShape) {
    this.localisedKind = intl.formatMessage({
      description: 'Name for image block used in broadcast editor',
      defaultMessage: 'Image',
    });
  }

  public definesBlock(block: BroadcastBlock): block is ImageBlock {
    return block.kind === 'image';
  }

  public initialiseBlock(): ImageBlock {
    return {
      localId: UniqueIdGenerator.generate(),
      kind: 'image',
      attributes: {
        uploadId: '',
        caption: {
          text: '',
          backgroundColour: '#E80D6D',
          textColour: '#FFFFFF',
          position: 'bottomCenter',
        },
      },
    };
  }

  public prepareBlockForSubmission(block: ImageBlock): ImageBlock {
    return {
      ...block,
      attributes: {
        ...block.attributes,
        caption: block.attributes.caption?.text
          ? block.attributes.caption
          : null,
      },
    };
  }

  public IconComponent = ImageBlockIcon;
  public ExpandedPreviewComponent = ImageBlockExpandedPreview;
  public InlineFormComponent = ImageBlockInlineForm;
  public FormComponent = ImageBlockForm;
  public errorDefinitions: BlockErrorDefinitions<ImageBlock> = {
    uploadId: [
      new BlankValidationErrorDefinition(intl => intl.formatMessage({
        description: 'Error message when broadcast image block upload input is left blank.',
        defaultMessage: 'No image selected.',
      })),
    ],
    caption: {
      text: [],
      position: [],
      backgroundColour: [
        {
          type: 'notHexColour',
          formatMessage: intl => intl.formatMessage({
            description: 'Error message when broadcast image block caption background color is formatted incorrectly.',
            defaultMessage: 'Color is formatted incorrectly.',
          }),
        }
      ],
      textColour: []
    },
  };
  public preview = {
    slot: PreviewSlot.MEDIA,
    Component: ImageBlockPreview,
  };
}
