import { FunctionComponent, ReactNode, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Heading } from '@ourpeople/shared/Core/Component/Content';

import { StyledBreadcrumb, StyledBreadcrumbSubPage, StyledPageHeader } from './style';
import { Flex, FlexPullRight } from '..';
import { useContextOrThrow } from '../../../Core/Hook';
import { PushMessageContext } from '../../../Core/Context/PushMessageContext';
import { MessageReceivedState } from '../../../Core/Model/PushMessage';

export type BreadcrumbItem = {
  link?: string;
  title: string;
  component?: ReactNode;
};

type Props = {
  items: BreadcrumbItem[];
  useNewTheme?: boolean;
  children?: ReactNode;
  className?: string;
}

export const PageHeader: FunctionComponent<Props> = ({
  items,
  useNewTheme = false,
  children,
  className,
}) => {
  const { messageHistory } = useContextOrThrow(PushMessageContext);
  const backgroundMessageCount = useMemo(
    () => messageHistory.filter(messageLog => messageLog.received.state !== MessageReceivedState.FOREGROUND).length,
    [messageHistory],
  );

  useEffect(() => {
    document.title = `${ backgroundMessageCount ? `(${ backgroundMessageCount }) ` : '' }${ items.map(item => item.title).join(' | ') }`;
  }, [backgroundMessageCount, items]);

  return (
    <StyledPageHeader className={className} useNewTheme={ useNewTheme }>
      <Flex align="flex-start">
        <StyledBreadcrumb>
          {
            items.map((item, index) => (
              index === 0
                ? (
                  item.link
                    ? <Link key={ `breadcrumb_${ index }` } to={ item.link }><Heading>{ item.title }</Heading></Link>
                    : <Heading key={ `breadcrumb_${ index }` }>{ item.title }</Heading>
                )
                : (
                  <StyledBreadcrumbSubPage
                    key={ `breadcrumb_${ index }` }
                    title={ item.title }
                    last={ items.length === (index + 1) }
                  >
                    {
                      item.link
                        ? <Link to={ item.link }><Heading>{ item.component || item.title }</Heading></Link>
                        : <Heading>{ item.component || item.title }</Heading>
                    }
                  </StyledBreadcrumbSubPage>
                )
            ))
          }
        </StyledBreadcrumb>
        <FlexPullRight gap={ 2 }>
          { children }
        </FlexPullRight>
      </Flex>
    </StyledPageHeader>
  )
}
