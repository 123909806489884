import { FunctionComponent, ReactNode, useMemo } from 'react';
import { ChartData } from 'chart.js';
import { useTheme } from '@emotion/react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Metric } from '../../Model';
import { MetricTransformer } from '../../Utility';
import { LineChartMetric, MetricContainer } from '..';
import { DeprecatedLink } from '../../../Common/Component';
import { MissingDataMessage } from '../MissingDataMessage/MissingDataMessage';

interface Props {
  metric: Metric;
}

export const CoverByStatusMetric: FunctionComponent<Props> = ({
  metric,
}) => {
  const theme = useTheme();
  const intl = useIntl();
  const chartData = useMemo<ChartData<'line'>>(() => (MetricTransformer.transformToChartData(
    metric,
    [
      {
        key: 'assigned',
        label: intl.formatMessage({
          id: 'dashboard.coverByStatus.assignedLabel',
          defaultMessage: 'Assigned',
        }),
        backgroundColor: theme.palette.success.main,
        borderColor: theme.palette.success.main,
      },
      {
        key: 'requested',
        label: intl.formatMessage({
          id: 'dashboard.coverByStatus.requestedLabel',
          defaultMessage: 'Requests pending',
        }),
        backgroundColor: theme.palette.warning.main,
        borderColor: theme.palette.warning.main,
      },
      {
        key: 'pending',
        label: intl.formatMessage({
          id: 'dashboard.coverByStatus.pendingLabel',
          defaultMessage: 'No requests',
        }),
        backgroundColor: theme.palette.warning.dark,
        borderColor: theme.palette.warning.dark,
      },
      {
        key: 'cancelled',
        label: intl.formatMessage({
          id: 'dashboard.coverByStatus.cancelledLabel',
          defaultMessage: 'Cancelled',
        }),
        backgroundColor: theme.palette.error.main,
        borderColor: theme.palette.error.main,
      },
      {
        key: 'expired',
        label: intl.formatMessage({
          id: 'dashboard.coverByStatus.expiredLabel',
          defaultMessage: 'Expired',
        }),
        backgroundColor: theme.palette.grey[500],
        borderColor: theme.palette.grey[500],
      },
    ],
  )), [theme, intl, metric]);

  return (
    <MetricContainer
      title={
        <FormattedMessage
          id="dashboard.coverByStatus.heading"
          defaultMessage="Cover by status"
        />
      }
    >
      {
        metric.datasets.length
          ? <LineChartMetric chartData={ chartData }/>
          : (
            <MissingDataMessage>
              <FormattedMessage
                id="dashboard.coverByStatus.noData"
                defaultMessage="<p>There was no cover sent in this period.</p><p><a>Learn more about cover.</a></p>"
                values={ {
                  a: (chunks: ReactNode) => (
                    <DeprecatedLink to={ intl.formatMessage({
                      id: 'dashboard.coverByStatus.helpDeskLink',
                      defaultMessage: 'https://get.ourpeople.help/hc/articles/6801183484061-Managing-Cover',
                    }) }>
                      { chunks }
                    </DeprecatedLink>
                  )
                } }
              />
            </MissingDataMessage>
          )
      }
    </MetricContainer>
  );
};
