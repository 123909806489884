import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { ProgressBar } from 'op-storybook/stories/components/ProgressBar/ProgressBar';

type Props = {
  progress: number;
}

export const VideoProgressBar: FC<Props> = ({
  progress,
}) => (
  <ProgressBar
    progress={ progress < 1 ? progress : 'indeterminate' }
    showNumericValue={ false }
  >
    {
      progress < 1
        ? `${ Math.round(progress * 100) }%`
        : (
          <FormattedMessage
            description="Label for loading bar when video transcoding is in progress in video block"
            defaultMessage="Processing…"
          />
        )
    }
  </ProgressBar>
);
