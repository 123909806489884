import { FunctionComponent, ReactNode, useCallback, useEffect, useState } from 'react';
import * as React from 'react';
import { TextField } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';

import { CharacterCount, Dialog } from '../../../../../Components';
import {
  StyledFormGrid,
  StyledFormGridCell,
} from './styles';

type Props = {
  cta: string;
  heading: string | JSX.Element | ReactNode;
  description: string | JSX.Element | ReactNode;
  open: boolean;
  onConfirmed: () => void;
  onDismissed: () => void;
  onFeedbackChanged: (smsText: string) => void;
  feedback?: string;
}

export const TrainingFeedbackDialog: FunctionComponent<Props> = ({
  cta,
  heading,
  description,
  open,
  onConfirmed,
  onDismissed,
  onFeedbackChanged,
  feedback,
}) => {
  const intl = useIntl();
  const maxCharacters = 500;
  const [remainingCharacters, setRemainingCharacters] = useState<number>();
  const handleFeedbackChange = useCallback((event: React.ChangeEvent<{ value: unknown }>) => {
    onFeedbackChanged(event.target.value as string);
  }, [onFeedbackChanged]);

  useEffect(() => {
    setRemainingCharacters(
      maxCharacters - (feedback || '').trim().length
    );
  }, [feedback]);

  return (
    <Dialog
      heading={heading}
      headingSize="small"
      headingTextAlign="left"
      cta={cta}
      ctaDisabled={(remainingCharacters || -1) < 0}
      cancel={
        intl.formatMessage({
          id: 'trainingFeedbackDialog.cancel',
          defaultMessage: 'Cancel',
        })
      }
      open={open}
      onConfirmed={onConfirmed}
      onDismissed={onDismissed}
    >
      {description}
      <StyledFormGrid>
        <StyledFormGridCell>
          <FormattedMessage
            id="trainingFeedbackDialog.feedback.label"
            defaultMessage="Feedback"
          />
        </StyledFormGridCell>
        <StyledFormGridCell fullWidth={true}>
          <TextField
            id="training-feedback"
            variant="outlined"
            rows={4}
            multiline
            name="feedback"
            onChange={ handleFeedbackChange }
            value={ feedback }
          />
          <CharacterCount value={remainingCharacters} />
        </StyledFormGridCell>
      </StyledFormGrid>
    </Dialog>
  );
};
