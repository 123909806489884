import { FunctionComponent } from 'react';
import { Button, DialogActions, DialogContent } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { StyledReUploadDialog } from './style';

type Props = {
  open: boolean;
  onDismiss: () => void;
  Dropzone: FunctionComponent<{ onComplete: (importId: string) => void }>;
}

export const ReUploadDialog: FunctionComponent<Props> = ({ open, Dropzone, onDismiss }) => (
  <StyledReUploadDialog
    open={ open }
    onBackdropClick={ onDismiss }
  >
    <DialogContent>
      <Dropzone onComplete={ onDismiss } />
    </DialogContent>
    <DialogActions>
      <Button
        color="primary"
        onClick={ onDismiss }
      >
        <FormattedMessage
          id="tags.re-upload.cancel"
          description="Cancel button in re-upload dialog."
          defaultMessage="Cancel"
        />
      </Button>
    </DialogActions>
  </StyledReUploadDialog>
);
