import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { HeadingNode } from '@lexical/rich-text';
import { ListItemNode, ListNode } from '@lexical/list';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { LexicalEditor, TextNode } from 'lexical';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { StackEnd } from 'op-storybook/lib/components/StackEnd/StackEnd';
import {
  FixedTextNode,
  FixedTextNodePlugin
} from '@ourpeople/shared/Core/Component/Input/RichTextEditor/Plugins/FixedTextNodePlugin';
import { OnFocusPlugin } from '@ourpeople/shared/Core/Component/Input/RichTextEditor/Plugins/OnFocusPlugin';
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin';
import { EditablePlugin } from '@ourpeople/shared/Core/Component/Input/RichTextEditor/Plugins/EditablePlugin';
import { OnPastePlugin } from '@ourpeople/shared/Core/Component/Input/RichTextEditor/Plugins/OnPastePlugin';
import he from 'he';

import { OurPeopleAiPromptMenu } from '../../../Content/OurPeopleAiPromptMenu/OurPeopleAiPromptMenu';
import { StyledContentEditable, StyledEditorContainer, StyledStack } from './style';
import { EditorNodeInserter } from '../../../../../../src/react/Common/Utility/EditorNodeInserter';
import { RichTextParser } from '../../../../../../src/react/Common/Utility/RichTextParser';
import { ToolbarPlugin } from '../Plugins/ToolbarPlugin';
import { OnChangePlugin } from '../Plugins/OnChangePlugin';
import { ValueReconciliationPlugin } from '../Plugins/ValueReconciliationPlugin';
import { OnBlurPlugin } from '../Plugins/OnBlurPlugin';
import { useGoogleAnalytics } from '../../../../../../src/react/Core/Hook';
import { PlaceholderNode, PlaceholderNodePlugin } from '../Plugins/PlaceholderNodePlugin';
import {
  FirstNameRichTextPlaceholderDefinition
} from '../../../../../../src/react/Common/Service/RichTextPlaceholderDefinitions/FirstNameRichTextPlaceholderDefinition';
import {
  FullNameRichTextPlaceholderDefinition
} from '../../../../../../src/react/Common/Service/RichTextPlaceholderDefinitions/FullNameRichTextPlaceholderDefinition';

type Props = {
  value: string;
  placeholder?: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  onFocus?: (selectionOffset: number | null) => void;
  disabled?: boolean;
  autofocus?: boolean;
  aiPromptOptions?: {
    maxLength: number;
  };
  hideToolbar?: boolean;
  className?: string;
  fixedHeight?: boolean;
  analyticsContext?: string;
  availablePlaceholders?: string[];
  mode?: 'richText' | 'plainText';
};

const EMPTY_EDITOR_HTML_STRING = '<p><br></p>';

export const Editor: FC<Props> = ({
  value,
  onChange,
  placeholder,
  onBlur,
  onFocus,
  disabled = false,
  autofocus = false,
  aiPromptOptions,
  hideToolbar = false,
  className,
  fixedHeight = false,
  analyticsContext,
  availablePlaceholders,
  mode = 'richText',
}) => {
  const [aiMenuOpen, setAiMenuOpen] = useState<boolean>(false);
  const [beginOnUserPrompt, setBeginOnUserPrompt] = useState<boolean>(false);
  const toolbarRef = useRef<HTMLDivElement>(null);
  const { trackEvent } = useGoogleAnalytics();
  const editorValueRef = useRef<string>();

  const whenEditorValueChanged = useCallback(
    (newValue: string) => {
      editorValueRef.current = newValue;
      onChange(newValue);
    },
    [onChange, editorValueRef],
  );

  const [initialConfig] = useState({
    namespace: '',
    onError: () => null,
    nodes: [
      PlaceholderNode,
      FixedTextNode,
      HeadingNode,
      ListNode,
      ListItemNode,
      {
        replace: HeadingNode,
        with: (node: HeadingNode) => {
          const clonedNode = HeadingNode.clone(node);
          clonedNode.__tag = node.getTag() === 'h1'
            ? 'h1'
            : 'h2';
          return clonedNode;
        },
      },
      {
        replace: TextNode,
        with: (node: TextNode) => {
          return node.getParent()
            ? node
            : new TextNode(node.getTextContent());
        },
      },
    ],
    theme: editorTheme,
    editorState: (editor: LexicalEditor) => {
      const dom = RichTextParser.getDocumentFromString(value || EMPTY_EDITOR_HTML_STRING);
      EditorNodeInserter.insertNodesFromDocument(editor, dom);
    },
  });

  useEffect(() => {
    if (aiMenuOpen) {
      return;
    }

    setBeginOnUserPrompt(false);
  }, [aiMenuOpen]);

  const whenAiMenuToggle = useCallback((open: boolean) => {
    open
      ? trackEvent({
        ...baseAnalyticsEvent,
        action: 'Open menu',
        label: `Source: ${ analyticsContext || 'unspecified' } button`,
      })
      : trackEvent({
        ...baseAnalyticsEvent,
        action: 'Close menu',
      });
    setAiMenuOpen(open);
  }, [analyticsContext, trackEvent]);

  const whenBlurred = useCallback((event: FocusEvent) => {
    if (event.relatedTarget && toolbarRef?.current?.contains(event.relatedTarget as HTMLElement)) {
      event.preventDefault();
      return;
    }

    onBlur && onBlur();
  }, [onBlur, toolbarRef]);

  const whenEditorValuePasted = useCallback(
    (pastedValue: string): string => he.decode(pastedValue),
    [],
  );

  return (
    <StyledStack
      direction="column"
      fixedHeight={ fixedHeight }
    >
      <StyledEditorContainer fixedHeight={ fixedHeight }>
        <LexicalComposer
          initialConfig={ initialConfig }
        >
          { !hideToolbar && (
            <ToolbarPlugin
              mode={ mode }
              availablePlaceholders={ availablePlaceholders || [] }
              innerRef={ toolbarRef }
            />
          ) }
          <EditablePlugin editable={ !disabled }/>
          <PlaceholderNodePlugin />
          <FixedTextNodePlugin/>
          <OnPastePlugin onPaste={ whenEditorValuePasted } />
          {
            mode === 'richText'
              ? (
                <div
                  css={ {
                    position: 'relative',
                  } }
                >
                  <RichTextPlugin
                    contentEditable={
                      <StyledContentEditable
                        disabled={ disabled }
                        className={ className }
                        hideToolbar={ hideToolbar }
                        fixedHeight={ fixedHeight }
                      />
                    }
                    placeholder={ (
                      <div
                        css={ theme => ({
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          padding: theme.new.spacing[2],
                          color: theme.new.palette.grey[400].main,
                          zIndex: -1,
                          pointerEvents: 'none',
                          fontWeight: 300,
                        }) }
                      >
                        { placeholder }
                      </div>
                    ) }
                    ErrorBoundary={ LexicalErrorBoundary }
                  />
                </div>
              )
              : (
                <PlainTextPlugin
                  contentEditable={
                    <StyledContentEditable
                      disabled={ disabled }
                      className={ className }
                      hideToolbar={ hideToolbar }
                      fixedHeight={ fixedHeight }
                    />
                  }
                  placeholder={ <div>{ placeholder }</div> }
                  ErrorBoundary={ LexicalErrorBoundary }
                />
              )
          }
          <OnChangePlugin
            value={ value }
            onChange={ whenEditorValueChanged }
            emptyValue={ mode === 'richText' ? EMPTY_EDITOR_HTML_STRING : '' }
            mode={ mode }
          />
          <HistoryPlugin/>
          { autofocus && <AutoFocusPlugin/> }
          <ValueReconciliationPlugin
            enabled={ editorValueRef.current !== value }
            value={ value }
            emptyValue={ mode === 'richText' ? EMPTY_EDITOR_HTML_STRING : '' }
            mode={ mode }
          />
          <ListPlugin/>
          <OnBlurPlugin
            onBlur={ whenBlurred }
          />
          { onFocus && (
            <OnFocusPlugin
              onFocus={ onFocus }
            />
          ) }
        </LexicalComposer>
      </StyledEditorContainer>
      { aiPromptOptions && (
        <Stack>
          <StackEnd>
            <OurPeopleAiPromptMenu
              open={ aiMenuOpen }
              onToggle={ whenAiMenuToggle }
              beginOnUserPrompt={ beginOnUserPrompt }
              input={ value }
              onChange={ value => onChange(`<p>${ value }</p>`) }
              maxOutputLength={ aiPromptOptions.maxLength }
              baseAnalyticsEvent={ baseAnalyticsEvent }
            />
          </StackEnd>
        </Stack>
      ) }
    </StyledStack>
  );
};

const editorTheme = {
  text: {
    bold: 'editor-text-bold',
    italic: 'editor-text-italic',
  },
};

const baseAnalyticsEvent = {
  category: 'OurPeople AI',
};

export const defaultPlaceholders = [
  FirstNameRichTextPlaceholderDefinition.placeholderString,
  FullNameRichTextPlaceholderDefinition.placeholderString
];
