import styled from '@emotion/styled';

import { ValidationErrorMessage } from '../../../../../Common/Component';

export const StyledTextContentWithoutFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${ props => props.theme.spacing(6) };

  ${ ValidationErrorMessage } {
    text-align: center;
  }
`;
