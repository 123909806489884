import { useMemo } from 'react';

import { FetchResponse, useGet } from '../../Hooks';
import { Paginated, Person } from '../../Models';

export type PeopleRequestParams = {
  pageNum?: number;
  sort?: string[];
  search?: string;
  ids?: string[];
  teamIds?: string[];
  roleIds?: string[];
  statuses?: string[];
  noWithProfile?: boolean;
  noWithoutProfile?: boolean;
}

export type PeopleRequestQuery = {
  pageNum?: number;
  sort?: string;
  search?: string;
  ids?: string;
  teamIds?: string;
  roleIds?: string;
  statuses?: string;
  noWithProfile?: 1 | 0;
  noWithoutProfile?: 1 | 0;
}

export const useFetchPeople = (
  params: PeopleRequestParams,
  disabled?: boolean
): FetchResponse<Paginated<'people', Person>> => {
  const endpoint = '/people';
  const query = useMemo<PeopleRequestQuery>(
    () => buildPeopleRequestQuery(params),
    [params],
  );
  return useGet<Paginated<'people', Person>>(endpoint, query, undefined, disabled);
}

export const buildPeopleRequestQuery = (params: PeopleRequestParams): PeopleRequestQuery => ({
  ...(params.pageNum !== undefined ? {pageNum: params.pageNum} : {}),
  ...(params.sort !== undefined ? {sort: params.sort.join(',')} : {}),
  ...(params.search !== undefined ? {search: params.search} : {}),
  ...(params.ids !== undefined ? {ids: params.ids.join(',')} : {}),
  ...(params.teamIds !== undefined ? {teamIds: params.teamIds.join(',')} : {}),
  ...(params.roleIds !== undefined ? {roleIds: params.roleIds.join(',')} : {}),
  ...(params.statuses !== undefined ? {statuses: params.statuses.join(',')} : {}),
  ...(params.noWithProfile !== undefined ? {noWithProfile: params.noWithProfile ? 1 : 0} : {}),
  ...(params.noWithoutProfile !== undefined ? {noWithoutProfile: params.noWithoutProfile ? 1 : 0} : {}),
});
