import { FunctionComponent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Condition, Segment } from '../../Model';
import { ConditionIdentifier } from '../../Utility';
import { Flex, Notice } from '../../../Common/Component';
import LockIcon from '../../../Assets/img/icons/streamline/lock.svg';
import { TeamAutocomplete } from '../../../Tags/Component';
import { StyledReadonlyTeams } from './style';

type Props = {
  segment: Segment;
};

export const ReachSegment: FunctionComponent<Props> = ({ segment }) => {
  const intl = useIntl();
  const teamCondition = segment.conditions.filter(conditionIsPositiveTeamCondition)[0];
  const reachIsEveryone = segment.conditions.find(ConditionIdentifier.conditionIsEveryoneCondition);

  return (
    <>
      { !reachIsEveryone && teamCondition && (
        <Notice
          align="flex-start"
          feedback={ {
            severity: 'info',
            message: (
              <Flex
                direction="column"
                align="stretch"
              >
                { intl.formatMessage({
                  id: 'audiences.reach.limitingNotice',
                  description: 'Notice explaining how the user\'s reach will limit an audience.',
                  defaultMessage: 'The audience has been limited according to your permissions',
                }) }
                <StyledReadonlyTeams>
                  <span>
                    <FormattedMessage
                      id="audiences.reach.teams"
                      description="Description of reach team condition."
                      defaultMessage="People in any of the teams"
                    />
                  </span>
                  <TeamAutocomplete
                    teamIds={ teamCondition.tagIds }
                    onSelectionChanged={ () => null }
                    multiple
                    fullWidth
                    readonly
                  />
                </StyledReadonlyTeams>
              </Flex>
            )
          } }
          variant="outlined"
          icon={ <LockIcon/> }
        />
      ) }
    </>
  );
};

type PositiveTeamCondition = {
  type: 'tag';
  tagType: 'team';
  tagIds: string[];
  not?: false;
};

const conditionIsPositiveTeamCondition = (condition: Condition): condition is PositiveTeamCondition => {
  return ConditionIdentifier.conditionIsTagCondition(condition)
    && condition.tagType === 'team'
    && condition.not !== true
};
