import styled from '@emotion/styled';

export const ReportSummary = styled.div`
  display: grid;
  padding: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  column-gap: 1rem;

  > div {
    display: grid;
    grid-auto-rows: 1fr;
    row-gap: 1rem;

    > div {
      > div {
        flex-wrap: wrap;
      }
    }
  }
`;
