import { ComponentProps, JSX, ReactNode, RefObject, useMemo, useRef, useState } from 'react';
import { TextField } from 'op-storybook/stories/components/TextField/TextField';
import * as Popover from '@radix-ui/react-popover';
import { useDebounce } from '@ourpeople/shared/Core/Hook/useDebounce';
import { DropdownItemCheck } from 'op-storybook/stories/components/DropdownItemCheck/DropdownItemCheck';
import { ProgressCircle } from 'op-storybook/stories/components/ProgressCircle/ProgressCircle';
import { Badge } from 'op-storybook/stories/components/Badge/Badge';
import { MenuItem } from 'op-storybook/stories/components/MenuItem/MenuItem';

import { InfiniteList } from '../Content/InfiniteList';

type SearchFilters = {
  search?: string;
};

type Props<I> = {
  value: I | null;
  onChange: (value: I) => void;
  queryFn: ComponentProps<typeof InfiniteList<I, SearchFilters>>['queryFn'];
  queryKey: ComponentProps<typeof InfiniteList<I, SearchFilters>>['queryKey'];
  renderItemContents: (item: I) => ReactNode;
  getItemLabel: (item: I) => string;
  itemsAreEquivalent?: (itemA: I, itemB: I) => boolean;
  placeholder?: string;
  label?: string;
  containerRef?: RefObject<HTMLElement>;
};

export const ComboBox = <T, >({
  value,
  onChange,
  queryFn,
  queryKey,
  renderItemContents,
  getItemLabel,
  itemsAreEquivalent = (itemA, itemB) => itemA === itemB,
  placeholder,
  label,
  containerRef,
}: Props<T>): JSX.Element => {
  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce(search, 300);
  const [open, setOpen] = useState<boolean>(false);
  const filters = useMemo(() => ({ search: debouncedSearch }), [debouncedSearch]);
  const scrollElementRef = useRef<HTMLDivElement>(null);

  return (
    <Popover.Root
      open={ open }
      modal={ true }
    >
      <div
        css={ {
          position: 'relative',
        } }
      >
        <div
          css={ {
            position: 'absolute',
            width: '100%',
          } }
        >
          <Popover.Anchor asChild>
            <div
              css={ {
                position: 'absolute',
                height: '100%',
                width: '100%',
                pointerEvents: 'none',
              } }
            />
          </Popover.Anchor>
          <Popover.Portal container={ containerRef?.current }>
            <Popover.Content
              forceMount
              side="bottom"
              onOpenAutoFocus={ event => event.preventDefault() }
            >
              <div
                css={ theme => ({
                  marginTop: `${ theme.new.spacing[1] }`,
                  width: 'var(--radix-popper-anchor-width)',
                  border: theme.new.border.standard,
                  borderRadius: theme.new.borderRadius.standard,
                  pointerEvents: 'all',
                  height: '200px',
                  overflowY: 'auto',
                  boxShadow: theme.new.shadow.md,
                }) }
                ref={ scrollElementRef }
              >
                <InfiniteList
                  filters={ filters }
                  queryKey={ queryKey }
                  queryFn={ queryFn }
                  renderItem={ item => (
                    <MenuItem
                      {
                        ...!!value && itemsAreEquivalent(item, value)
                          ? { endAdornment: <DropdownItemCheck/> }
                          : {}
                      }
                      onClick={ () => {
                        onChange(item);
                        setOpen(false);
                      } }
                    >
                      { renderItemContents(item) }
                    </MenuItem>
                  ) }
                  renderLoadingState={ () => (
                    <div
                      css={ {
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      } }
                    >
                      <ProgressCircle
                        palette={ {
                          colour: 'teal',
                          intensity: 600,
                        } }
                      />
                    </div>
                  ) }
                  scrollAreaRef={ scrollElementRef }
                />
              </div>
            </Popover.Content>
          </Popover.Portal>
          <div>
            <TextField
              value={ search }
              onChange={ setSearch }
              placeholder={ placeholder }
              label={ label }
              inputProps={ {
                onFocus: () => {
                  setOpen(true);
                },
                onInput: () => {
                  setOpen(true);
                },
                onClick: () => {
                  setOpen(true);
                },
              } }
              {
                ...value
                  ? {
                    startAdornment: (
                      <div
                        css={ theme => ({
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          paddingRight: theme.new.spacing[2],
                        }) }
                      >
                        <Badge variant="badge-modern" colour="grey" label={ getItemLabel(value) }/>
                      </div>
                    )
                  }
                  : {}
              }
              onBlur={ (event) => {
                if (scrollElementRef.current && event.relatedTarget && scrollElementRef.current.contains(event.relatedTarget)) {
                  event.preventDefault();
                  return;
                }

                setOpen(false);
              } }
            />
          </div>
        </div>
      </div>
    </Popover.Root>
  );
};
