import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { CenteredMessage } from '@ourpeople/shared/Core/Component/Content';

import { EmptyCard, LiveCardContainer, LiveCardContainerBackground } from '../../../Content/Component';
import { LiveForm } from '..';

type Props = {
  formId?: string;
};

export const FormContainer: FC<Props> = ({
  formId,
}) => {
  const intl = useIntl();

  return (
    <LiveCardContainerBackground>
      <LiveCardContainer>
        {
          formId
            ? <LiveForm formId={ formId }/>
            : (
              <EmptyCard>
                <CenteredMessage
                  heading={ intl.formatMessage({
                    description: 'Heading for card when no form is selected in personal form page.',
                    defaultMessage: 'No form selected',
                  }) }
                  body={ intl.formatMessage({
                    description: 'Body for card when no form is selected in personal form page.',
                    defaultMessage: 'Select a form when you\'re ready to begin.',
                  }) }
                />
              </EmptyCard>
            )
        }
      </LiveCardContainer>
    </LiveCardContainerBackground>
  );
};
