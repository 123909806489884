import { ChangeEvent, useCallback } from 'react';

type CheckboxChangeEventHandler<T extends HTMLInputElement = HTMLInputElement> = (
  event: ChangeEvent<T>,
  checked: boolean,
) => void;

export const useCheckboxChangeEventHandler = <T extends HTMLInputElement = HTMLInputElement>(
  handler: (checked: boolean) => void,
): CheckboxChangeEventHandler<T> => (
  useCallback((event, checked) => {
    return handler(checked)
  }, [handler])
);
