import { FC } from 'react';

import { MainMenuFooter, MainMenuHeader, MenuSectionDivider, NavigationItem } from '..';
import { StyledFooterContainer, StyledMainMenu, StyledMenuItemDivider, StyledNavigationItems } from './style';
import { ConfiguredNavigationItem } from '../../../Model';

type Props = {
  personUuid: string;
  userHasAvatar: boolean;
  spaceName: string;
  userName: string;
  navigationItems: ConfiguredNavigationItem[];
  spaceLogoSrc: string;
  onEditProfileClicked: () => void;
  onSnoozeNotificationsClicked: () => void;
  onSupportClicked: () => void;
  onSettingsClicked: () => void;
  onOpenAccountMenuClicked: () => void;
};

export const MainMenu: FC<Props> = ({
  personUuid,
  userHasAvatar,
  spaceName,
  userName,
  navigationItems,
  spaceLogoSrc,
  onEditProfileClicked,
  onSnoozeNotificationsClicked,
  onSupportClicked,
  onSettingsClicked,
  onOpenAccountMenuClicked,
}) => (
  <StyledMainMenu>
    <MainMenuHeader
      userHasAvatar={ userHasAvatar }
      personUuid={ personUuid }
      spaceLogoSrc={ spaceLogoSrc }
      spaceName={ spaceName }
      userName={ userName }
      onSwitchAccountClicked={ onOpenAccountMenuClicked }
      onEditProfileClicked={ onEditProfileClicked }
    />
    <StyledNavigationItems>
      { navigationItems.map(navigationItem => (
        <li key={ navigationItem.ref }>
          <NavigationItem
            navigationItem={ navigationItem }
          />
          <StyledMenuItemDivider/>
        </li>
      )) }
    </StyledNavigationItems>
    <StyledFooterContainer>
      <MenuSectionDivider/>
      <MainMenuFooter
        onSnoozeNotificationsClicked={ onSnoozeNotificationsClicked }
        onSupportClicked={ onSupportClicked }
        onSettingsClicked={ onSettingsClicked }
      />
    </StyledFooterContainer>
  </StyledMainMenu>
);
