import { useState, useEffect, FunctionComponent, ReactNode, PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';
import * as Sentry from '@sentry/react';

import { ExternalLinkButton } from '../Button/ExternalLinkButton';
import { FullscreenError } from '../Error/FullscreenError';
import { Space } from '../../Models/Space';
import { SpaceResolver } from '../../Services/SpaceResolver';
import { SpaceContext } from '../../Core/Context/SpaceContext';
import { SpaceDeducer } from '../../Core/Utility/SpaceDeducer';

const spaceId = SpaceDeducer.deduceSpaceId();

export const SpaceProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [space, setSpace] = useState<Space | null>(null);
  const [error, setError] = useState<boolean>(false);

  // Get the space information
  useEffect(() => {
    if (error) {
      return;
    }
    let cancelled = false;
    const spaceResolver = new SpaceResolver();
    spaceResolver.resolve(spaceId).then((newSpace: Space) => {
      Sentry.setTag('environment', newSpace.environment);
      !cancelled && setSpace(newSpace);
    }).catch(() => {
      !cancelled && setError(true);
    });
    return () => {
      cancelled = true;
    };
  }, [error]);

  return error
    ? (
      <FullscreenError onCtaClicked={ () => setError(false) }>
        <p>
          <FormattedMessage
            id="error.spaceProvider"
            description="Error message that appears when a space fails to resolve"
            defaultMessage="We're having some trouble locating your space."
          />
        </p>
        <p>
          <FormattedMessage
            id="error.contactCustomerSuccess"
            description="Explanation to contact customer success following an error"
            defaultMessage="If the problem persists, <cta>try contacting customer success</cta>."
            values={ {
              cta: (chunks: (string | ReactNode)[]) => (
                <ExternalLinkButton bordered={ false } href="https://get.ourpeople.help">
                  { chunks }
                </ExternalLinkButton>
              )
            } }
          />
        </p>
      </FullscreenError>
    )
    : (
      <SpaceContext.Provider value={ { spaceId, space } }>
        { children }
      </SpaceContext.Provider>
    );
};
