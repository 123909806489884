import { FunctionComponent, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { RequestFields } from '..';
import { LabelledSwitch } from '../../../../Common/Component';
import { ContentEditorProps } from '../../../../Content/Model';
import { ValidationTree } from '../../../../Common/Model';
import { DraftFormRequest } from '../../../Model';
import { DateRequestEditorContent } from '../../../Service';

export const DateRequestFields: FunctionComponent<ContentEditorProps<DateRequestEditorContent>> = ({
  editorContent,
  onEditorContentChanged,
  validation,
  onValidationChanged,
}) => {
  const intl = useIntl();

  const whenIncludeTimeChanged = (checked: boolean): void => {
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          includeTime: checked,
        },
      },
    });
  };

  const whenContentValidationChanged = useCallback(
    (contentValidation: ValidationTree<DraftFormRequest>): void => {
      onValidationChanged({
        errors: validation?.errors || [],
        children: {
          ...validation?.children,
          content: contentValidation,
        },
      });
    },
    [validation, onValidationChanged],
  );

  return (
    <>
      <RequestFields
        editorContent={ editorContent }
        onEditorContentChanged={ onEditorContentChanged }
        validation={ validation }
        onValidationChanged={ whenContentValidationChanged }
      />
      <LabelledSwitch
        id="includeTime"
        checked={ editorContent.card.content.includeTime }
        onChange={ whenIncludeTimeChanged }
        label={ intl.formatMessage({
          id: 'form.fields.dateRequest.includeTime.label',
          defaultMessage: 'Include time',
        }) }
      />
    </>
  );
};
