import { IntlShape } from 'react-intl';

import { Tag } from '../../Tags/Model';
import { Chip } from '../Component';

export class ChipFactory {
  public static createChipFromTeam = (team: Tag<'team'>, intl: IntlShape, type: string = 'team'): Chip => ({
    id: team.id,
    type,
    label: intl.formatMessage({
      id: 'chips.team.label',
      description: 'Label for team chip',
      defaultMessage: 'Team: {teamName}',
    }, {
      teamName: team.name,
    }),
  });
}
