import { DraftContent, DraftSingleContentCard, SingleContentCard } from '../Model';

export class CardTransformer {
  public static transformCard = <D extends DraftContent>(card: SingleContentCard, draftContent: D): DraftSingleContentCard<D> => ({
    id: card.id,
    mandatory: card.mandatory,
    image: card.image
      ? {
        uploadId: card.image.uploadId,
        fit: card.image?.fit || 'cover',
      }
      : null,
    content: draftContent,
  });
}
