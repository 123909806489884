import styled from '@emotion/styled';

export const StyledSubmittedText = styled.p`
  color: ${ props => props.theme.new.palette.grey[400].main };
  border: 2px solid ${ props => props.theme.new.palette.grey[200].main };
  padding: ${ props => props.theme.spacing(1) };
  border-radius: ${ props => props.theme.new.borderRadius.standard };
  font-size: ${ props => props.theme.new.typography.text.size.md.fontSize };
  height: auto;
  width: 100%;
  word-break: break-word;
`;
