import { ComponentProps, FC, ReactNode } from 'react';
import { ThemeContext } from '@emotion/react';
import { defaultTheme } from 'op-storybook';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { StackEnd } from 'op-storybook/lib/components/StackEnd/StackEnd';

import { SvgComponent } from '../../../../../src/react/Common/Model';
import { DeprecatedPresentationIcon } from '../../Content/PresentationIcon/DeprecatedPresentationIcon';
import { Badge } from '../../Content/Badge/Badge';
import { ButtonBase } from '../../Input/ButtonBase/ButtonBase';
import ChevronDownIcon from '../../../../Asset/Icon/figma/chevron-down.svg';
import ChevronUpIcon from '../../../../Asset/Icon/figma/chevron-up.svg';
import LockedIcon from '../../../../Asset/Icon/figma/lock-01.svg';
import { StyledConditionalTooltip, StyledIconContainer, StyledIconOnlyBadgeContainer, StyledIconSpacer } from './style';
import { useContextOrThrow } from '../../../../../src/react/Core/Hook/useContextOrThrow';

type Props = {
  id?: string;
  iconOnly?: boolean;
  current?: boolean;
  label: string;
  labelTooltip?: boolean;
  count?: number;
  dropdownState?: 'none' | 'expanded' | 'contracted';
  disabledFeatureTooltip?: string;
  inset?: boolean;
  IconComponent: SvgComponent;
  variant?: 'transparent' | 'filled';
  children?: ReactNode;
} & Omit<ComponentProps<typeof ButtonBase>, 'children' | 'id' | 'variant'>;

export const NavItem: FC<Props> = ({
  current = false,
  label,
  labelTooltip = false,
  count,
  dropdownState = 'none',
  disabledFeatureTooltip,
  inset,
  IconComponent,
  iconOnly = false,
  variant = 'filled',
  ...props
}) => {
  const theme = useContextOrThrow(ThemeContext) as typeof defaultTheme;

  return (
    <StyledConditionalTooltip
      title={ props.disabled ? disabledFeatureTooltip || '' : label }
      active={ (labelTooltip && iconOnly) || !!props.disabled }
      placement="right"
      PopperProps={ {
        style: { marginLeft: theme.new.spacing[8] },
      } }
    >
      <ButtonBase
        colour="teal"
        variant={ variant === 'filled' ? 'filled-dark' : 'tertiary-light' }
        active={ current }
        fillParent={ !iconOnly }
        padding={ iconOnly ? 'uniform' : 'standard' }
        { ...props }
      >
        <Stack
          gap={ 2 }
          noWrap
          fillParent
          justify={ iconOnly ? 'center' : 'flex-start' }
        >
          { inset && !iconOnly && (
            <StyledIconSpacer/>
          ) }
          <StyledIconContainer>
            <DeprecatedPresentationIcon
              IconComponent={ IconComponent }
            />
            { iconOnly && count !== undefined && (
              <StyledIconOnlyBadgeContainer>
                <Badge
                  variant="pill-colour"
                  colour="teal"
                  label=""
                />
              </StyledIconOnlyBadgeContainer>
            ) }
          </StyledIconContainer>
          { !iconOnly && (
            <Stack
              gap={ 2 }
              noWrap
              fillParent
            >
              { label }
              <StackEnd>
                <Stack
                  gap={ 2 }
                  noWrap
                >
                  {
                    props.disabled
                      ? (
                        <DeprecatedPresentationIcon
                          IconComponent={ LockedIcon }
                        />
                      )
                      : count !== undefined && (
                        <Badge
                          variant="pill-colour"
                          colour="teal"
                          label={ String(count) }
                        />
                      )
                  }
                  { dropdownState !== 'none' && (
                    <DeprecatedPresentationIcon
                      IconComponent={ dropdownState === 'expanded' ? ChevronUpIcon : ChevronDownIcon }
                      color="teal.300.main"
                    />
                  ) }
                </Stack>
              </StackEnd>
            </Stack>
          ) }
        </Stack>
      </ButtonBase>
    </StyledConditionalTooltip>
  );
};
