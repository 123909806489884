import styled from '@emotion/styled';

import { InnerNavigationButton } from '..';

export const StyledNavigationGroup = styled.div`
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
`;

export const StyledNavigationSubItem = styled.div<{ active: boolean }>`
  font-size: ${ props => props.theme.fontSize.md };
  padding: ${ props => `${ props.theme.spacing(2) } ${ props.theme.spacing(8) }` };
  transition: color 0.1s;
  display: flex;
  width: 100%;

  ${ props => props.active && `
    color: ${ props.theme.palette.primary.main };
  ` }
`;

export const StyledInnerNavigationButton = styled(InnerNavigationButton)`
  font-weight: 500;
  width: 100%;
  display: flex;
`;
