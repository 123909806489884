import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { add, parseISO } from 'date-fns';

import { BroadcastReportSummary } from '../Hooks/useFetchBroadcastReportSummary';
import {
  AnimatedNumber,
  Box,
  DateTime,
  ExpandIcon,
  Flex,
  IconButton,
  LabeledInformation,
  ReportSummary, DeprecatedTooltip,
  VerticallySpaced,
} from '../../../../Common/Component';
import { TextFormatter } from '../../../../Utility';
import { DeliveryReportTab, DeliveryReportTabContainer, DeliveryReportTabContent, DeliveryReportTabs } from './Tabs';
import { RespondedTable } from './Tables/RespondedTable';
import { NotRespondedTable } from './Tables/NotRespondedTable';
import { FetchAudienceDialog } from './FetchAudienceDialog/FetchAudienceDialog';
import {
  CategoryInput,
  EmailNotificationIcon,
  NudgeIcon,
  PushNotificationIcon,
  SmsNotificationIcon
} from '../../../../Broadcasts/Component';
import { MinimalBroadcastCategory } from '../../../../Broadcasts/Model';
import { useLoggedInAuthDescription } from '../../../../Core/Hook';
import { useNotificationSettingLocaliser } from '../../../../Broadcasts/Hook/useNotificationSettingLocaliser';
import { NotificationsDialog } from './';
import {useEnvironmentSettings} from "../../../../Common/Hook";

interface Props {
  deliveryId?: string;
  summary: BroadcastReportSummary;
  onChange: (summary: BroadcastReportSummary) => void;
  showEngagement: boolean;
  initialSearch?: string;
}

export const DeliverySummary: FunctionComponent<Props> = ({
  summary,
  showEngagement,
  onChange,
  deliveryId,
  initialSearch,
}) => {
  const intl = useIntl();
  const { broadcastDeliverySuccessStatsEnabled } = useEnvironmentSettings();
  const engagement = summary.counts.responded.total / (summary.counts.recipients.active + summary.counts.recipients.inactive);
  const deliverySuccessPercentage = summary.counts.recipients.notificationDelivered / summary.counts.recipients.notificationAttempted;
  const notDeliveredPercentage = 1 - deliverySuccessPercentage;
  const recipientAudienceId = useMemo(() => summary.broadcast.recipientAudienceId, [summary.broadcast.recipientAudienceId]);
  const [viewAudienceMounted, setViewAudienceMounted] = useState<boolean>(false);
  const [viewAudienceOpen, setViewAudienceOpen] = useState<boolean>(false);
  const [viewNotificationOpen, setViewNotificationOpen] = useState<boolean>(false);
  const { user: { id: authedUserId } } = useLoggedInAuthDescription();
  const { getLocalisedNotificationChannelFiltersWithoutCounts, getLocalisedRecipientStatusSummaryWithoutCounts } = useNotificationSettingLocaliser();
  const notificationSent = summary.broadcast.notification.push.send
    || summary.broadcast.notification.email.send
    || summary.broadcast.notification.sms.send;

  const whenCategoryChanged = useCallback((category: MinimalBroadcastCategory) => (
    onChange({
      ...summary,
      broadcast: {
        ...summary.broadcast,
        category,
      },
    })
  ), [onChange, summary]);

  const defaultNotificationMessage = useMemo(() => (
    intl.formatMessage({
      description: 'Message displayed for a notification channel in a broadcast report when the notification was sent using the default message.',
      defaultMessage: 'This notification was sent using the default message.',
    })
  ), [intl]);

  return (
    <Box padded={false}>
      <ReportSummary>
        <div>
          <LabeledInformation
            id="recipients"
            label={ intl.formatMessage({
              id: 'broadcasts.report.recipients',
              description: 'Label for recipients figure in summary',
              defaultMessage: 'Recipients'
            }) }
          >
            <VerticallySpaced>
              <AnimatedNumber
                targetValue={ summary.counts.recipients.active + summary.counts.recipients.inactive }
              />
              <Flex gap={ 1 }>
                <FormattedMessage
                  description="Label for view audience button in delivery summary of broadcast report."
                  defaultMessage="View audience"
                />
                <IconButton
                  size="xs"
                  color="primary.main"
                  IconComponent={ ExpandIcon }
                  onClick={ () => {
                    setViewAudienceOpen(true);
                    setViewAudienceMounted(true);
                  } }
                  label={ intl.formatMessage({
                    description: 'Label for opening the view audience modal',
                    defaultMessage: 'Open',
                  }) }
                />
              </Flex>
            </VerticallySpaced>
            { viewAudienceMounted && (
              <FetchAudienceDialog
                open={ viewAudienceOpen }
                onClose={ () => setViewAudienceOpen(false) }
                TransitionProps={ {
                  onExited: () => setViewAudienceMounted(false),
                } }
                recipientAudienceId={ recipientAudienceId }
              />
            ) }
          </LabeledInformation>
        </div>
        <div>
          <LabeledInformation
            id="category"
            label={ intl.formatMessage({
              description: 'Label for category in broadcast report summary',
              defaultMessage: 'Category'
            }) }
          >
            <CategoryInput
              containerActive
              broadcastId={ summary.broadcast.id }
              selectedCategory={ summary.broadcast.category }
              onSelectedCategoryChange={ whenCategoryChanged }
              userCreatedBroadcast={ `${ summary.broadcast.createdBy.id }` === `${ authedUserId }` }
            />
          </LabeledInformation>
          <LabeledInformation
            id="notification"
            label={intl.formatMessage({
              description: 'Label for notification state in summary',
              defaultMessage: 'Notifications'
            })}
          >
            <Flex gap={ 1 }>
              { summary.broadcast.notification.push.send && (
                <DeprecatedTooltip
                  title={
                    <Flex direction="column" gap={ 2 }>
                      <FormattedMessage
                        description="Tooltip for push notification icon in broadcast report summary."
                        defaultMessage="Push notification: sent to everyone with push enabled."
                        values={ {
                          recipientActivity: getLocalisedRecipientStatusSummaryWithoutCounts(),
                        } }
                      />
                      <span>{ defaultNotificationMessage }</span>
                    </Flex>
                  }
                >
                  <span>
                    <PushNotificationIcon />
                  </span>
                </DeprecatedTooltip>
              ) }
              { summary.broadcast.notification.sms.send && (
                <DeprecatedTooltip
                  title={
                    <Flex direction="column" gap={ 2 }>
                      <FormattedMessage
                        description="Tooltip for SMS notification icon in broadcast report summary."
                        defaultMessage="SMS notification: sent to { recipientActivity } { sentToEveryone, select, true {} other {people} } with SMS enabled."
                        values={ {
                          recipientActivity: getLocalisedNotificationChannelFiltersWithoutCounts(),
                          sentToEveryone: true,
                        } }
                      />
                      <span>{ defaultNotificationMessage }</span>
                    </Flex>
                  }
                >
                  <span>
                    <SmsNotificationIcon />
                  </span>
                </DeprecatedTooltip>
              ) }
              { summary.broadcast.notification.email.send && (
                <DeprecatedTooltip
                  title={
                    <Flex direction="column" gap={ 2 }>
                      <FormattedMessage
                        description="Tooltip for email notification icon in broadcast report summary."
                        defaultMessage="Email notification: sent to { recipientActivity } { sentToEveryone, select, true {} other {people} } with email enabled."
                        values={ {
                          recipientActivity: getLocalisedNotificationChannelFiltersWithoutCounts(),
                          sentToEveryone: true,
                        } }
                      />
                      <span>{ defaultNotificationMessage }</span>
                    </Flex>
                  }
                >
                  <span>
                    <EmailNotificationIcon />
                  </span>
                </DeprecatedTooltip>
              ) }
              { notificationSent && (
                <IconButton
                  size="xs"
                  color="primary.main"
                  IconComponent={ ExpandIcon }
                  onClick={ () => {
                    setViewNotificationOpen(true);
                  } }
                  label={ intl.formatMessage({
                    description: 'Label for opening the view notification modal on broadcast delivery summary',
                    defaultMessage: 'Open',
                  }) }
                />
              ) }
            </Flex>
            <NotificationsDialog
              open={ viewNotificationOpen }
              onClose={ () => setViewNotificationOpen(false) }
              TransitionProps={ {} }
              notificationSettings={ summary.broadcast.notification }
            />
          </LabeledInformation>
        </div>
        <div>
          <LabeledInformation
            id="delivered"
            label={intl.formatMessage({
              id: 'broadcasts.report.delivered',
              description: 'Label for delivery date in summary',
              defaultMessage: 'Delivery'
            })}
          >
            <DateTime dateTime={summary.broadcast.timeSent} />
          </LabeledInformation>
          <LabeledInformation
            id="nudge"
            label={intl.formatMessage({
              id: 'broadcasts.report.nudge',
              description: 'Label for nudge date in summary',
              defaultMessage: 'Nudge'
            })}
          >
            <NudgeIcon />
            <DateTime dateTime={
                summary.broadcast.timeSent && summary.broadcast.nudge
                ? add(
                  parseISO(summary.broadcast.timeSent),
                  {seconds: summary.broadcast.nudge.delay}
                )
                : null
              } />
          </LabeledInformation>
        </div>
        <div>
          <LabeledInformation
            id="created"
            label={intl.formatMessage({
              id: 'broadcasts.report.created',
              description: 'Label for created date in summary',
              defaultMessage: 'Created'
            })}
          >
            <DateTime dateTime={summary.broadcast.timeCreated} />
          </LabeledInformation>
          <LabeledInformation
            id="author"
            label={intl.formatMessage({
              id: 'broadcasts.report.author',
              description: 'Label for author in summary',
              defaultMessage: 'Sent by'
            })}
          >
            {
              TextFormatter.spaceSeparated(
                summary.broadcast.published?.by.firstName || '',
                summary.broadcast.published?.by.lastName || '',
              )
            }
          </LabeledInformation>
        </div>
      </ReportSummary>
      <DeliveryReportTabContainer
        tabs={
          <DeliveryReportTabs>
            {
              showEngagement && broadcastDeliverySuccessStatsEnabled && (
                <DeliveryReportTab
                  label={intl.formatMessage({
                    id: 'deliveryReport.deliverySuccessTab.label',
                    description: 'Label for delivery success tab in delivery report summary',
                    defaultMessage: 'Delivered'
                  })}
                  zeroValue="-"
                  value={deliverySuccessPercentage}
                  valueFormattingOptions={{
                    style: 'percent',
                  }}
                  tooltip={intl.formatMessage({
                    id: 'deliveryReport.deliverySuccessTab.tooltip',
                    description: 'Tooltip for deliverySuccessTab in delivery report summary',
                    defaultMessage: 'The percentage of people who were sent an email or text message successfully. This number may increase for a while after a broadcast is sent as deliveries are completed.'
                  })}
                />
              )
            }
            {
              showEngagement && broadcastDeliverySuccessStatsEnabled && (
                <DeliveryReportTab
                  label={intl.formatMessage({
                    id: 'deliveryReport.notDeliveredTab.label',
                    description: 'Label for not delivered tab in delivery report summary',
                    defaultMessage: 'Not delivered'
                  })}
                  value={notDeliveredPercentage}
                  valueFormattingOptions={{
                    style: 'percent',
                  }}
                  tooltip={intl.formatMessage({
                    id: 'deliveryReport.notDeliveredTab.tooltip',
                    description: 'Tooltip for notDeliveredTab in delivery report summary',
                    defaultMessage: 'The percentage of people who were sent an email or text message but have yet to receive either.'
                  })}
                />
              )
            }
            <DeliveryReportTab
              label={intl.formatMessage({
                id: 'deliveryReport.seenTab.label',
                description: 'Label for seen tab in delivery report summary',
                defaultMessage: 'Seen'
              })}
              value={summary.counts.seen.total}
              tooltip={intl.formatMessage({
                id: 'deliveryReport.seenTab.tooltip',
                description: 'Tooltip for seen tab in delivery report summary',
                defaultMessage: 'Number of people who have had this broadcast at the top of their queue'
              })}
            />
            <DeliveryReportTab
              label={intl.formatMessage({
                id: 'deliveryReport.notSeenTab.label',
                description: 'Label for not seen tab in delivery report summary',
                defaultMessage: 'Not seen'
              })}
              value={summary.counts.notSeen.total - summary.counts.recipients.pending}
              tooltip={intl.formatMessage({
                id: 'deliveryReport.notSeenTab.tooltip',
                description: 'Tooltip for not seen tab in delivery report summary',
                defaultMessage: 'Number of people who have not had this broadcast at the top of their queue'
              })}
              lowLight={true}
            />
            {
              showEngagement
                ? (
                  !broadcastDeliverySuccessStatsEnabled
                    ? (
                      <DeliveryReportTab
                        label={intl.formatMessage({
                          id: 'deliveryReport.engagementTab.label',
                          description: 'Label for engagement tab in delivery report summary',
                          defaultMessage: 'Engagement'
                        })}
                        value={engagement}
                        valueFormattingOptions={{
                          style: 'percent',
                        }}
                        tooltip={intl.formatMessage({
                          id: 'deliveryReport.engagementTab.tooltip',
                          description: 'Tooltip for engagement tab in delivery report summary',
                          defaultMessage: 'Percentage of people who have engaged with at least one card in the broadcast'
                        })}
                      />
                    )
                    : <></>
                )
                : (
                  <DeliveryReportTab
                    label={intl.formatMessage({
                      id: 'deliveryReport.respondedTab.label',
                      description: 'Label for responded tab in delivery report summary',
                      defaultMessage: 'Responded'
                    })}
                    value={summary.counts.responded.total}
                    tooltip={intl.formatMessage({
                      id: 'deliveryReport.respondedTab.tooltip',
                      description: 'Tooltip for responded tab in delivery report summary',
                      defaultMessage: 'Number of people who have responded to at least one card in the broadcast'
                    })}
                  />
                )
            }
            {
              !broadcastDeliverySuccessStatsEnabled && (
                <DeliveryReportTab
                  label={intl.formatMessage({
                    id: 'deliveryReport.notRespondedTab.label',
                    description: 'Label for not responded tab in delivery report summary',
                    defaultMessage: 'Not responded'
                  })}
                  value={summary.counts.notResponded.total - summary.counts.recipients.pending}
                  tooltip={intl.formatMessage({
                    id: 'deliveryReport.notRespondedTab.tooltip',
                    description: 'Tooltip for not responded tab in delivery report summary',
                    defaultMessage: 'Number of people who have not responded to any cards in the broadcast'
                  })}
                  lowLight={true}
                />
              )
            }
          </DeliveryReportTabs>
        }
      >
        { showEngagement && broadcastDeliverySuccessStatsEnabled && (
          <DeliveryReportTabContent>
            <RespondedTable
              deliveryId={deliveryId}
              broadcastId={summary.broadcast.id}
              responseType="notificationDelivered"
              initialSearch={ initialSearch }
            />
          </DeliveryReportTabContent>
        ) }
        { showEngagement && broadcastDeliverySuccessStatsEnabled && (
          <DeliveryReportTabContent>
            <RespondedTable
              deliveryId={deliveryId}
              broadcastId={summary.broadcast.id}
              responseType="notificationNotDelivered"
              initialSearch={ initialSearch }
            />
          </DeliveryReportTabContent>
        ) }
        <DeliveryReportTabContent>
          <RespondedTable
            deliveryId={deliveryId}
            broadcastId={summary.broadcast.id}
            responseType="seen"
            initialSearch={ initialSearch }
          />
        </DeliveryReportTabContent>
        <DeliveryReportTabContent>
          <NotRespondedTable
            deliveryId={deliveryId}
            broadcastId={summary.broadcast.id}
            responseType="notSeen"
            initialSearch={ initialSearch }
          />
        </DeliveryReportTabContent>
        <DeliveryReportTabContent>
          <RespondedTable
            deliveryId={deliveryId}
            broadcastId={summary.broadcast.id}
            responseType="responded"
            initialSearch={ initialSearch }
          />
        </DeliveryReportTabContent>
        {
          !broadcastDeliverySuccessStatsEnabled && (
            <DeliveryReportTabContent>
              <NotRespondedTable
                deliveryId={deliveryId}
                broadcastId={summary.broadcast.id}
                responseType="notResponded"
                initialSearch={ initialSearch }
              />
            </DeliveryReportTabContent>
          )
        }
      </DeliveryReportTabContainer>
    </Box>
  );
};
