import { Dispatch, FC, SetStateAction, useMemo } from 'react';
import { addSeconds, isAfter, parseISO } from 'date-fns';
import { ApiRequest } from '@ourpeople/shared/Core/Model/ApiRequest';

import { Box } from '../../../Common/Component';
import { ValidationTree } from '../../../Common/Model';
import { DraftBroadcast } from '../../Model';
import { DeliveryForm } from '../DeliveryForm/DeliveryForm';
import { Schedule } from '../../Model/BroadcastSchedule';
import { SmsPreview } from '../../Model/SmsPreview';
import { FetchCalculatedRecipientsResponse } from '../BroadcastEditForm/BroadcastEditForm';

type Props = {
  broadcast: DraftBroadcast;
  setBroadcast: Dispatch<SetStateAction<DraftBroadcast>>;
  fetchCalculatedRecipientsResponse: FetchCalculatedRecipientsResponse;
  validation: ValidationTree<DraftBroadcast>;
  setValidation: Dispatch<SetStateAction<ValidationTree<DraftBroadcast>>>;
  containsCover: boolean;
  firstEventDate: Date | null;
  schedule: Schedule;
  setSchedule: Dispatch<SetStateAction<Schedule>>;
  smsPreviewRequest: ApiRequest<SmsPreview>;
  onRetrySmsPreviewRequestClicked: () => void;
  valid: boolean;
};

export const BroadcastDeliveryStep: FC<Props> = ({
  broadcast,
  setBroadcast,
  fetchCalculatedRecipientsResponse,
  validation,
  setValidation,
  containsCover,
  firstEventDate,
  schedule,
  setSchedule,
  smsPreviewRequest,
  onRetrySmsPreviewRequestClicked,
  valid,
}) => {
  const showNudgeEventWarning = useMemo<boolean>(() => {
    if (!broadcast.nudgeDelay || !firstEventDate) {
      return false;
    }

    const broadcastSendDate = broadcast.scheduleFor ? parseISO(broadcast.scheduleFor) : new Date();
    return isAfter(addSeconds(broadcastSendDate, broadcast.nudgeDelay), firstEventDate);
  }, [broadcast.nudgeDelay, broadcast.scheduleFor, firstEventDate]);

  return (
    <Box margin={ false }>
      <DeliveryForm
        validation={ validation }
        setValidation={ setValidation }
        broadcast={ broadcast }
        setBroadcast={ setBroadcast }
        containsEvent={ firstEventDate !== null }
        containsCover={ containsCover }
        showNudgeEventWarning={ showNudgeEventWarning }
        fetchCalculatedRecipientsResponse={ fetchCalculatedRecipientsResponse }
        smsPreviewRequest={ smsPreviewRequest }
        onRetrySmsPreviewRequestClicked={ onRetrySmsPreviewRequestClicked }
        schedule={ schedule }
        setSchedule={ setSchedule }
        valid={ valid }
      />
    </Box>
  );
};
