import React, {FunctionComponent} from 'react';
import { Button, ButtonProps } from '@ourpeople/shared/Core/Component/Input/Button/Button';

import { StyledDropdownArrow } from './style';
import { Flex } from '..';

interface Props extends ButtonProps {
  showArrow?: boolean;
}

export const DropdownButton: FunctionComponent<Props> = ({
  showArrow = true,
  children,
  ...buttonProps
}) => {
  return (
    <Button { ...buttonProps }>
      <Flex gap={ 1 }>
        { children }
        { showArrow && (
          <StyledDropdownArrow role="presentation" />
        ) }
      </Flex>
    </Button>
  )
};
