import { ComponentProps, FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';

import {
  BroadcastContentFooter,
  ImageContentWithoutFooter,
  RespondButton,
  RespondButtonProps,
  StackNavigationProps
} from '../..';
import { Upload } from '../../../../../Types';
import { ImageCaption } from '../../../../../Broadcasts/Service';
import { BroadcastNudge } from '../../../../../Broadcasts/Model';
import { LinkContent } from '../../LinkContent/LinkContent';

type Props = {
  imageHeight: 'half' | 'majority' | 'full';
  backgroundColour: string;
  imageFit: 'cover' | 'contain';
  caption?: ImageCaption;
  text?: string;
  image?: Upload;
  viewProps: RespondButtonProps;
  navigationProps: StackNavigationProps;
  responded: boolean;
  hideRespondButton: boolean;
  nudge?: BroadcastNudge;
  linkProps: ComponentProps<typeof LinkContent> | null;
  backButton: ReactNode | undefined;
};

export const ImageBroadcastContent: FC<Props> = ({
  imageHeight,
  backgroundColour,
  imageFit,
  caption,
  text,
  image,
  viewProps,
  navigationProps,
  responded,
  hideRespondButton = false,
  nudge,
  linkProps,
  backButton,
}) => {
  const intl = useIntl();

  return (
    <>
      <ImageContentWithoutFooter
        backButton={ backButton }
        imageHeight={ imageHeight }
        backgroundColour={ backgroundColour }
        imageFit={ imageFit }
        image={ image }
        caption={ caption }
        text={ text }
        nudge={ nudge }
        linkProps={ linkProps }
      />
      <BroadcastContentFooter
        { ...navigationProps }
        respondProps={ viewProps }
        responded={ responded }
      >
        { !hideRespondButton && (
          <RespondButton
            { ...viewProps }
            variant="primary"
            notRespondedLabel={ intl.formatMessage({
              id: 'content.broadcast.image.notResponded',
              description: 'Label for view button when not responded.',
              defaultMessage: 'Mark as viewed',
            }) }
            respondedLabel={ intl.formatMessage({
              id: 'content.broadcast.image.responded',
              description: 'Label for view button when responded.',
              defaultMessage: 'Viewed',
            }) }
            rejectedLabel={ intl.formatMessage({
              id: 'content.broadcast.image.rejected',
              description: 'Label for view button when response was rejected.',
              defaultMessage: 'Try again',
            }) }
          />
        ) }
      </BroadcastContentFooter>
    </>
  );
};
