import { useEffect } from 'react';

import { SubmittedComponent } from '../../../../Content/Model';
import { GeolocationRequest as GeolocationRequestModel } from '../../../Model/ContentTypes';
import { GeolocationResponse } from '../../../Service';
import { GeolocationRequest } from '../../Content';
import { NoResponseMessage } from '../NoResponseMessage/NoResponseMessage';

export const SubmittedGeolocationRequest: SubmittedComponent<GeolocationResponse, GeolocationRequestModel> = ({
  response,
  onFinish,
}) => {
  useEffect(() => {
    if (!onFinish) {
      return;
    }

    onFinish();
  }, [onFinish]);

  return (
    response
      ? (
        <GeolocationRequest
          value={ `${ response.latitude }, ${ response.longitude }` }
          readOnly
        />
      )
      : <NoResponseMessage/>
  );
};
