import { FC } from 'react';

import { MappedSettingTypes } from '../../Types';
import { VerticallySpaced } from '../../../../../Common/Component';
import { StyledAggregateSettingLabel } from './AggregateSettingInput/style';
import { MappedSettingValue } from './MappedSettingValue';

type Props = {
  config: MappedSettingTypes['aggregate']['config'];
  value: MappedSettingTypes['aggregate']['value'];
};

export const AggregateSettingValue: FC<Props> = ({
  config,
  value,
}) => (
  <VerticallySpaced gap={ 2 }>
    { value && Array.from(Object.entries(value)).map(([settingKey, value]) => {
      const setting = config.settings[settingKey];
      return (
        <VerticallySpaced
          key={ settingKey }
          gap={ 1 }
        >
          <StyledAggregateSettingLabel>
            { setting.name }
          </StyledAggregateSettingLabel>
          <MappedSettingValue
            type={ setting.type }
            value={ value }
            config={ setting.config }
          />
        </VerticallySpaced>
      );
    }) }
  </VerticallySpaced>
);
