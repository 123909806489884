import { FunctionComponent } from 'react';

import { ErrorWithRetry, NoResultsContainer } from '..';

type Props = {
  onRetry: () => void;
}

export const NoResultsError: FunctionComponent<Props> = ({ children, onRetry }) => (
  <NoResultsContainer>
    <ErrorWithRetry onRetry={onRetry}>
      { children }
    </ErrorWithRetry>
  </NoResultsContainer>
);
