import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';
import { CenteredMessage } from '@ourpeople/shared/Core/Component/Content';
import { BreakpointContext } from 'op-storybook/lib/providers/BreakpointProvider/BreakpointProvider';
import { TabBar } from 'op-storybook/stories/components/TabBar/TabBar';
import {
  useInfiniteLoadContainerRef
} from 'op-storybook/lib/hooks/useInfiniteLoadContainerRef/useInfiniteLoadContainerRef';
import { ApiRequest } from '@ourpeople/shared/Core/Model/ApiRequest';
import { useDebounce } from '@ourpeople/shared/Core/Hook/useDebounce';

import { DeliveryContainer, HeaderWithCard, PersonalDeliveryList } from '../../Component';
import { EmptyCard } from '../../../Content/Component';
import { BroadcastContentDefinitionRegistryProvider } from '../../../Broadcasts/Provider';
import { StyledHeaderAndCardContainer } from './style';
import { ArrayHelper, QueryParser } from '../../../Common/Utility';
import { useQueryAsState } from '../../../Hooks';
import { PersonalDelivery } from '../../Model';
import { FetchPersonalDeliveriesParams } from '../../Hook';
import { useContextOrThrow } from '../../../Core/Hook';
import { PushMessageContext } from '../../../Core/Context/PushMessageContext';
import {
  BroadcastReceivedPushMessageData
} from '../../../Notifications/Service/BroadcastReceivedPushMessageDefinition';
import { LocationHelper } from '../../../Common/Utility/LocationHelper';
import { ResponsivePageHeader } from '../../../Common/Component/ResponsivePageHeader/ResponsivePageHeader';
import { ApiContext } from '../../../Contexts';
import { Paginated, RequestState } from '../../../Models';
import { CardError } from '../../Component/CardError/CardError';

type Query = {
  status?: string;
};

type Params = {
  deliveryId?: string;
};

type FetchDeliveriesResult = Paginated<'deliveries', PersonalDelivery>;

export const InboxListPage: FC = () => {
  const intl = useIntl();
  const location = useLocation();
  const [query] = useQueryAsState<Query>(defaultQuery);
  const [pageNum, setPageNum] = useState(1);
  const screenWidth = useContextOrThrow(BreakpointContext);
  const tab = parseTab(query);
  const { deliveryId } = useParams<Params>();
  const { dismissMessages } = useContextOrThrow(PushMessageContext);
  const params = useMemo<FetchPersonalDeliveriesParams>(() => ({
    pageNum,
    recipientStatuses: tab === 'all' ? 'pending,completed,responded,seen' : 'pending,responded,seen',
    sort: 'coalesced_bumped_at_desc',
    noPreview: 1,
    noDrafts: 1,
    ...(tab === 'unread' ? { statuses: 'delivered' } : {}),
  }), [tab, pageNum]);
  const debouncedParams = useDebounce(params, 100);
  const [deliveries, setDeliveries] = useState<PersonalDelivery[] | undefined>(undefined);
  const [selectedDelivery, setSelectedDelivery] = useState<PersonalDelivery>();
  const history = useHistory();
  const api = useContextOrThrow(ApiContext);
  const [deliveryRequest, setDeliveryRequest] = useState<ApiRequest<FetchDeliveriesResult>>({
    state: RequestState.PENDING,
    result: null,
  });

  const fetchDeliveries = useCallback(() => {
    setDeliveryRequest({
      state: RequestState.FETCHING,
      result: null,
    });

    api.get<FetchDeliveriesResult>('/me/broadcasts/deliveries', { params: debouncedParams })
      .then(response => response.data)
      .then(result => {
        setDeliveryRequest({
          state: RequestState.COMPLETE,
          result,
        });
      })
      .catch(error => {
        setDeliveryRequest({
          state: RequestState.FAILED,
          result: error,
        });
      });
  }, [api, debouncedParams]);

  // Reset pagination when tab changes
  useEffect(() => {
    setPageNum(1);
    setDeliveryRequest({
      result: null,
      state: RequestState.FETCHING,
    });
    setDeliveries(undefined);
  }, [tab]);

  useEffect(() => {
    fetchDeliveries();
  }, [fetchDeliveries]);

  // Update deliveries with latest page of deliveries from API
  useEffect(() => {
    if (deliveryRequest.state !== RequestState.COMPLETE) {
      return;
    }

    setDeliveries(
      deliveries => deliveries === undefined
        ? deliveryRequest.result.deliveries
        : deliveries.concat(deliveryRequest.result.deliveries)
    );
  }, [deliveryRequest]);

  const whenInfiniteLoadThresholdCrossed = useCallback(() => {
    if (
      deliveryRequest.state === RequestState.FETCHING
      || deliveryRequest.state === RequestState.FAILED
      || (deliveryRequest.state === RequestState.COMPLETE && deliveryRequest.result.pagination.pageCount <= pageNum)
    ) {
      return;
    }

    setPageNum(pageNum => pageNum + 1);
  }, [deliveryRequest, pageNum]);
  const infiniteLoaderContainerRef = useInfiniteLoadContainerRef(whenInfiniteLoadThresholdCrossed, 1000);

  const whenDeliveryChanged = useCallback((modifiedDelivery: PersonalDelivery) => (
    setDeliveries(deliveries => {
      if (deliveries === undefined) {
        return deliveries;
      }

      const index = deliveries.findIndex(delivery => delivery.delivery.id === modifiedDelivery.delivery.id);

      if (index === -1) {
        return deliveries;
      }

      return ArrayHelper.replace(deliveries, index, modifiedDelivery);
    })
  ), []);

  const whenDeliverySelected = useCallback((delivery: PersonalDelivery) => {
    history.push(`/inbox/${ delivery.delivery.id }${ history.location.search }`);
    setSelectedDelivery(delivery);
    dismissMessages(message => {
      const broadcastReceivedPushMessageData = message.payload.data as unknown as BroadcastReceivedPushMessageData;
      return broadcastReceivedPushMessageData.opType === 'broadcastReceived' && broadcastReceivedPushMessageData.opBroadcastDeliveryId === delivery.delivery.id;
    });
  }, [dismissMessages, history]);

  const pageTitle = intl.formatMessage({
    description: 'Title for inbox page.',
    defaultMessage: 'Inbox',
  });

  const whenReloadRequired = useCallback(() => setPageNum(1), []);

  return (
    <div
      css={ {
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        height: '100%',
        overflow: 'hidden',
      } }
    >
      <BroadcastContentDefinitionRegistryProvider>
        <ResponsivePageHeader
          title={ pageTitle }
          variant="simple"
          breadcrumbItems={ [
            {
              title: pageTitle,
              link: '/inbox'
            },
          ] }
        />
        <div
          css={ theme => ({
            display: 'grid',
            gridTemplateColumns: '1fr 2fr',
            height: '100%',
            overflow: 'hidden',

            [theme.new.breakpoints.sm.down]: {
              gridTemplateColumns: '1fr',
            },
          }) }
        >
          <div
            css={ theme => ({
              display: 'grid',
              gridTemplateRows: 'auto 1fr',
              height: '100%',
              overflow: 'hidden',
              [theme.new.breakpoints.sm.up]: {
                minWidth: '380px'
              },
              ...(deliveryId ? {
                  [theme.new.breakpoints.sm.down]: {
                    display: 'none',
                  },
                } : {}),
            }) }
          >
            <div
              css={ theme => ({
                padding: screenWidth.lessThan.sm
                  ? `0 ${ theme.new.spacing[2] } ${ theme.new.spacing[2] }`
                  : `0 ${ theme.new.spacing[4] } ${ theme.new.spacing[4] }`
              }) }
            >
              <TabBar
                spaceEvenly
                tabData={ [
                  {
                    value: 'all',
                    label: intl.formatMessage({
                      description: 'Label for "all" tab on inbox page.',
                      defaultMessage: 'All',
                    }),
                  },
                  {
                    value: 'unread',
                    label: intl.formatMessage({
                      description: 'Label for "unread" tab on inbox page.',
                      defaultMessage: 'Unread',
                    }),
                  },
                ] }
                context="page"
                value={ tab }
                getTabProps={ tabData => ({
                  component: Link,
                  to: LocationHelper.replaceSearchKey(location, 'status', tabData.value),
                  css: { '&:hover': { textDecoration: 'none' } }
                }) }
              />
            </div>
            <div
              ref={ infiniteLoaderContainerRef }
              css={ { overflowY: 'auto', height: '100%' } }
            >
              <PersonalDeliveryList
                deliveries={ deliveries }
                onDeliverySelected={ whenDeliverySelected }
                requestState={ deliveryRequest.state }
                onRetryClicked={ fetchDeliveries }
              />
            </div>
          </div>
          { (!screenWidth.lessThan.sm || deliveryId) && (
            <div
              css={ {
                ...(screenWidth.lessThan.sm
                    ? {
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                    }
                    : { overflow: 'hidden' }
                )
              } }
            >
              <div css={ theme => ({ height: '100%', background: theme.new.gradients[18] }) }>
                <StyledHeaderAndCardContainer>
                  {
                    deliveryId
                      ? (
                        <ErrorBoundary
                          key={ deliveryId }
                          fallback={
                            <HeaderWithCard>
                              <CardError/>
                            </HeaderWithCard>
                          }
                        >
                          <DeliveryContainer
                            deliveryId={ deliveryId }
                            selectedDelivery={ selectedDelivery }
                            onChange={ whenDeliveryChanged }
                            onReloadRequired={ whenReloadRequired }
                          />
                        </ErrorBoundary>
                      )
                      : (
                        <HeaderWithCard>
                          <EmptyCard>
                            <CenteredMessage
                              heading={ intl.formatMessage({
                                id: 'inbox.noSelection.heading',
                                description: 'Heading for inbox card when no delivery is selected.',
                                defaultMessage: 'No message selected',
                              }) }
                              body={ intl.formatMessage({
                                id: 'inbox.noSelection.message',
                                description: 'Message for inbox card when no delivery is selected.',
                                defaultMessage: 'Select a message when you\'re ready to begin.',
                              }) }
                            />
                          </EmptyCard>
                        </HeaderWithCard>
                      )
                  }
                </StyledHeaderAndCardContainer>
              </div>
            </div>
          ) }
        </div>
      </BroadcastContentDefinitionRegistryProvider>
    </div>
  );
};

const defaultQuery = {
  status: 'all'
};
const parseTab = QueryParser.getValueParser('status', ['all', 'unread'], 'all');
