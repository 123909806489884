import { FC } from 'react';

import { LiveComponentProps } from '../../../../Content/Model';
import { AudioContent } from '../../../../Broadcasts/Service';
import { ViewResponse } from '../../../Model';
import { BroadcastContentContext } from '../../../../Broadcasts/Model';
import { LiveMediaContent } from '..';
import { AudioBroadcastContent } from '../../../../Content/Component';

export const LiveAudioContent: FC<LiveComponentProps<AudioContent, ViewResponse, BroadcastContentContext>> = (props) => (
  <LiveMediaContent
    PresentationComponent={ AudioBroadcastContent }
    { ...props }
  />
);
