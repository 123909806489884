import { FunctionComponent, MouseEvent, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { DeprecatedButton } from '../Button/DeprecatedButton';
import { Fullscreen } from '../Layout/Fullscreen';
import { StyledFullscreenError } from './styles';

export interface FullscreenErrorProps {
  onCtaClicked?: (event: MouseEvent) => void;
  cta?: ReactNode;
  children?: ReactNode;
}

export const FullscreenError: FunctionComponent<FullscreenErrorProps> = ({ children, onCtaClicked, cta }) => (
  <Fullscreen>
    <StyledFullscreenError>
      {typeof children === 'string' ? <p>{children}</p> : children}
      {onCtaClicked && (
        <DeprecatedButton primary={true} onClick={onCtaClicked}>
          {cta || (
            <FormattedMessage
              id="action.tryAgain"
              description="Label for button retrying an action"
              defaultMessage="Try again"
              />
          )}
        </DeprecatedButton>
      )}
    </StyledFullscreenError>
  </Fullscreen>
);
