import { Dispatch, FunctionComponent, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { AutocompleteSelectionChanged } from '@ourpeople/shared/Core/Component/Input/Autocomplete/Autocomplete';
import { DebouncedSearchInput } from '@ourpeople/shared/Core/Component/Input/DebouncedSearchInput/DebouncedSearchInput';
import { LocalisedString } from 'op-storybook/lib/model/LocalisedString/LocalisedString';

import { TeamAutocomplete } from '../../../Tags/Component';
import { EventsDateRangePicker, EventTypeAutocomplete, MeetingsAndTrainingsStatusAutocomplete } from '..';
import { Chip, FilterChips, FilterContainer, FilterRow, FlexPullRight, } from '../../../Common/Component';
import { Tag } from '../../../Tags/Model';
import { ArrayHelper, ChipFactory, dateOffsetFromIsValid } from '../../../Common/Utility';
import { DateRangePickerValue } from '../../../Common/Model/DateRangePicker';
import { StyledAutocompleteContainer } from './styles';

interface Props {
  query: Record<string, string>;
  setQuery: Dispatch<SetStateAction<Record<string, string>>>;
}

export const MeetingsAndTrainingsTableFilters: FunctionComponent<Props> = ({
  query,
  setQuery,
}) => {
  const intl = useIntl();
  const [search, setSearch] = useState<string>(query.search);
  const [chips, setChips] = useState<Chip[]>([]);
  const typeIds = query.types ? query.types.split(',') : [];
  const teamIds = useMemo(() => query.teamIds?.split(',') || [], [query.teamIds]);
  const eventStatusIds = query.statuses ? query.statuses.split(',') : [];
  const dateRangeValue = useMemo<DateRangePickerValue>(() => {
    if (query.start && query.end) {
      return {
        type: 'range',
        start: query.start,
        end: query.end,
      };
    }

    return {
      type: 'offset',
      from: query.from && dateOffsetFromIsValid(query.from) ? query.from : 'start',
      offset: null,
    };
  }, [query]);

  useEffect(() => {
    setQuery((query) => {
      const { search: querySearch, ...existingQuery } = query;
      if (querySearch === search) {
        return query;
      }
      return search
        ? {
          ...existingQuery,
          search,
          pageNum: '1',
        }
        : existingQuery
    });
  }, [setQuery, search]);

  const whenTeamsChanged: AutocompleteSelectionChanged<Tag<'team'>> = useCallback((selection) => {
    setQuery(({ teamIds: currentTeamIds, ...query }) => {
      const newTeamIds = selection.options.map(team => team.id).concat(selection.unknownIds).join(',');
      return newTeamIds
        ? {
          ...query,
          teamIds: selection.options.map(team => team.id).concat(selection.unknownIds).join(','),
          pageNum: '1',
        }
        : query;
    });
    setChips(chips => [
      ...chips.filter(chip => chip.type !== 'team'),
      ...selection.options.map(team => ChipFactory.createChipFromTeam(team, intl)),
    ]);
  }, [intl, setQuery]);

  const whenEventStatusesChanged: AutocompleteSelectionChanged<LocalisedString> = (selection) => {
    setQuery(({ statuses: currentStatuses, ...query }) => {
      const newStatuses = selection.options.map(status => status.id).concat(selection.unknownIds).join(',');
      return newStatuses
        ? {
          ...query,
          statuses: newStatuses,
          pageNum: '1',
        }
        : query;
    });
    setChips(chips => [
      ...chips.filter(chip => chip.type !== 'meetingAndTrainingStatus'),
      ...selection.options.map(status => ({
        id: status.id,
        type: 'meetingAndTrainingStatus',
        data: status,
        label: intl.formatMessage({
          id: 'chips.meetingAndTrainingStatus.label',
          description: 'Label for meeting and training status chip',
          defaultMessage: 'Status: {status}',
        }, {
          status: status.localisation,
        }),
      })),
    ]);
  };

  const whenEventTypesChanged: AutocompleteSelectionChanged<LocalisedString> = (selection) => {
    setQuery(({ types: currentStatuses, ...query }) => {
      const newTypes = selection.options.map(type => type.id).concat(selection.unknownIds).join(',');
      return newTypes
        ? {
          ...query,
          types: newTypes,
          pageNum: '1',
        }
        : query;
    });
    setChips(chips => [
      ...chips.filter(chip => chip.type !== 'eventType'),
      ...selection.options.map(type => ({
        id: type.id,
        type: 'eventType',
        label: intl.formatMessage({
          id: 'chips.eventType.label',
          description: 'Label for event type chip',
          defaultMessage: 'Type: {type}',
        }, {
          type: type.localisation,
        }),
      })),
    ]);
  };

  const removeTeamWithId = (id: string): void => {
    setQuery(({ teamIds: currentTeamIds, ...query }) => {
      const teamIds: string[] = currentTeamIds.split(',');
      const index = teamIds.indexOf(id);
      const newTeamIds = ArrayHelper.remove(teamIds, index).join(',');
      return !newTeamIds
        ? query
        : {
          ...query,
          teamIds: newTeamIds,
          pageNum: '1',
        };
    });
  };

  const removeStatusWithId = (id: string): void => {
    setQuery(({ statuses: currentStatuses, ...query }) => {
      const statuses: string[] = currentStatuses.split(',');
      const index = statuses.indexOf(id);
      const newStatuses = ArrayHelper.remove(statuses, index).join(',');
      return !newStatuses
        ? query
        : {
          ...query,
          statuses: newStatuses,
          pageNum: '1',
        };
    });
  };

  const removeTypeWithId = (id: string): void => {
    setQuery(({ types: currentTypes, ...query }) => {
      const types: string[] = currentTypes.split(',');
      const index = types.indexOf(id);
      const newTypes = ArrayHelper.remove(types, index).join(',');
      return !newTypes
        ? query
        : {
          ...query,
          types: newTypes,
          pageNum: '1',
        };
    });
  };

  const whenRemoveChipClicked = (chip: Chip): void => {
    if (chip.type === 'team') {
      removeTeamWithId(chip.id);
    }

    if (chip.type === 'meetingAndTrainingStatus') {
      removeStatusWithId(chip.id);
    }

    if (chip.type === 'eventType') {
      removeTypeWithId(chip.id);
    }

    setChips(chips => {
      const index = chips.findIndex(existingChip => existingChip.id === chip.id);
      return ArrayHelper.remove(chips, index)
    });
  };

  const whenClearFiltersClicked = (): void => {
    setSearch('');
    setQuery(({ search, types, statuses, teamIds, ...query }) => ({
      ...query,
    }));

    setChips([]);
  };

  const whenDateRangeChanged = useCallback((value: DateRangePickerValue): void => {
    setQuery(({ start, end, from, ...query }) => {
      if (value.type === 'range') {
        return {
          ...query,
          start: value.start,
          end: value.end,
          pageNum: '1',
        };
      }

      return {
        ...query,
        sort: value.from === 'start' ? 'start_at_asc' : 'start_at_desc',
        from: value.from,
        pageNum: '1',
      };
    });
  }, [setQuery]);

  return (
    <FilterContainer>
      <FilterRow>
        <StyledAutocompleteContainer>
          <EventTypeAutocomplete
            selectedIds={ typeIds }
            onSelectionChanged={ whenEventTypesChanged }
            showChips={ false }
            multiple
            dense
            label={
              intl.formatMessage({
                id: 'meetingsAndTrainingsTable.typeFilter.label',
                defaultMessage: 'Type',
              })
            }
            placeholder={
              intl.formatMessage({
                id: 'meetingsAndTrainingsTable.typeFilter.placeholder',
                defaultMessage: 'Search...',
              })
            }
          />
        </StyledAutocompleteContainer>
        <StyledAutocompleteContainer>
          <TeamAutocomplete
            teamIds={ teamIds }
            onSelectionChanged={ whenTeamsChanged }
            showChips={ false }
            multiple
            dense
            label={
              intl.formatMessage({
                id: 'meetingsAndTrainingsTable.teamsFilter.label',
                defaultMessage: 'Teams',
              })
            }
            placeholder={
              intl.formatMessage({
                id: 'meetingsAndTrainingsTable.teamsFilter.placeholder',
                defaultMessage: 'Search...',
              })
            }
          />
        </StyledAutocompleteContainer>
        <StyledAutocompleteContainer>
          <MeetingsAndTrainingsStatusAutocomplete
            selectedIds={ eventStatusIds }
            onSelectionChanged={ whenEventStatusesChanged }
            showChips={ false }
            multiple
            dense
            label={
              intl.formatMessage({
                id: 'meetingsAndTrainingsTable.statusFilter.label',
                defaultMessage: 'Status',
              })
            }
            placeholder={
              intl.formatMessage({
                id: 'meetingsAndTrainingsTable.statusFilter.placeholder',
                defaultMessage: 'Search...',
              })
            }
          />
        </StyledAutocompleteContainer>
        <EventsDateRangePicker
          value={ dateRangeValue }
          onChange={ whenDateRangeChanged }
        />
        <FlexPullRight>
          <DebouncedSearchInput
            value={ search }
            onChange={ setSearch }
          />
        </FlexPullRight>
      </FilterRow>
      <FilterChips
        chips={ chips }
        onRemoveChipClicked={ whenRemoveChipClicked }
        onClearFiltersClicked={ whenClearFiltersClicked }
      />
    </FilterContainer>
  )
}
