import styled from '@emotion/styled';

export const StyledTextRequest = styled.div`
  textarea {
    resize: none;
    border: none;
    width: 100%;
    padding: 0.175rem 0;

    ::placeholder {
      font-size: 1.125rem;
      color: ${props => props.theme.palette.grey[400]};
    }
  }
`;
