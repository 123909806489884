import { ChangeEventHandler, FunctionComponent, useCallback } from 'react';
import { useIntl } from 'react-intl';

import {
  DraftNumberRequest,
  NUMBER_REQUEST_MAX_MAX,
  NUMBER_REQUEST_MAX_MIN,
  NUMBER_REQUEST_MIN_MAX,
  NUMBER_REQUEST_MIN_MIN
} from '../../../Model/ContentTypes';
import { RequestFields } from '..';
import { FieldValidationErrors, NumberInput } from '../../../../Common/Component';
import { ValidationTree } from '../../../../Common/Model';
import { NumberRequestValidator } from '../../../Utility/Validation';
import { ContentEditorProps } from '../../../../Content/Model';
import { CardValidationMerger } from '../../../../Content/Utility';
import { NumberRequestEditorContent } from '../../../Service';

export const NumberRequestFields: FunctionComponent<ContentEditorProps<NumberRequestEditorContent>> = ({
  editorContent,
  onEditorContentChanged,
  validation,
  onValidationChanged,
}) => {
  const intl = useIntl();
  const minErrors = validation?.children.content?.children.min?.errors || [];
  const maxErrors = validation?.children.content?.children.max?.errors || [];

  const whenMinChanged: ChangeEventHandler<HTMLInputElement> = (event) => {
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          min: +event.target.value,
        },
      },
    });
  };

  const whenMaxChanged: ChangeEventHandler<HTMLInputElement> = (event) => {
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          max: +event.target.value,
        },
      },
    });
  };

  const whenMinBlurred = () => whenContentChildrenValidationChanged({
    min: NumberRequestValidator.validateMin(editorContent.card.content),
  });
  const whenMaxBlurred = () => whenContentChildrenValidationChanged({
    max: NumberRequestValidator.validateMax(editorContent.card.content),
  });

  const whenContentChildrenValidationChanged = useCallback(
    (children: ValidationTree<DraftNumberRequest>['children']): void => {
      onValidationChanged(
        CardValidationMerger.addContentValidationChildrenToCardValidation(children, validation)
      );
    },
    [validation, onValidationChanged],
  );

  return (
    <>
      <RequestFields
        editorContent={ editorContent }
        onEditorContentChanged={ onEditorContentChanged }
        validation={ validation }
        onValidationChanged={ onValidationChanged }
      />
      <NumberInput
        id="min"
        inputProps={ {
          min: NUMBER_REQUEST_MIN_MIN,
          max: Math.min(editorContent.card.content.max, NUMBER_REQUEST_MIN_MAX),
        } }
        label={ intl.formatMessage({
          id: 'form.fields.numberRequest.min.label',
          defaultMessage: 'Min value *',
        }) }
        value={ editorContent.card.content.min }
        onChange={ whenMinChanged }
        onBlur={ whenMinBlurred }
        error={ !!minErrors.length }
        fullWidth
      />
      <FieldValidationErrors
        fieldName={ intl.formatMessage({
          id: 'form.fields.numberRequest.min.name',
          defaultMessage: 'min',
        }) }
        validationErrors={ minErrors }
      />
      <NumberInput
        id="max"
        inputProps={ {
          min: Math.max(editorContent.card.content.min, NUMBER_REQUEST_MAX_MIN),
          max: NUMBER_REQUEST_MAX_MAX,
        } }
        label={ intl.formatMessage({
          id: 'form.fields.numberRequest.max.label',
          defaultMessage: 'Max value *',
        }) }
        value={ editorContent.card.content.max }
        onChange={ whenMaxChanged }
        onBlur={ whenMaxBlurred }
        error={ !!maxErrors.length }
        fullWidth
      />
      <FieldValidationErrors
        fieldName={ intl.formatMessage({
          id: 'form.fields.numberRequest.max.name',
          defaultMessage: 'max',
        }) }
        validationErrors={ maxErrors }
      />
    </>
  )
};
