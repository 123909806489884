import { DraftEvent, Event } from '../Model';
import { DateTransformer } from './DateTransformer';

export class EventTransformer {
  public static toDraft = <T extends string>({ id, mandatory, ...event }: Event<T>): DraftEvent<T> => ({
    ...event,
    start_at: DateTransformer.transformDate(event.start_at),
    end_at: DateTransformer.transformDate(event.end_at),
  });
}
