import styled from '@emotion/styled';

export const StyledRichTextContentPreview = styled.div`
  word-wrap: break-word;
  line-height: 1.25;

  p {
    display: block;
    margin-block-end: ${ props => props.theme.spacing(1) };
  }

  h1 {
    display: block;
    font-size: 1.17rem;
    font-weight: bold;
    margin-block-start: 0;
    margin-block-end: ${ props => props.theme.spacing(2) };
  }
`;
