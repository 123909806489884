import { DraftFormRequest, FormRequest } from '../Content';

export const RATING_REQUEST_MAX_RATING_MIN = 3;
export const RATING_REQUEST_MAX_RATING_MAX = 10;
export const RATING_REQUEST_MAX_RATING_DEFAULT = 5;

export type RatingRequest = FormRequest & {
  type: 'ratingRequest';
  maxRating: number;
};

export type DraftRatingRequest = DraftFormRequest & {
  type: 'ratingRequest';
  maxRating: number;
};
