import styled from '@emotion/styled';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';

export const StyledNavGroupChildren = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: ${ props => props.theme.new.spacing[2] };
`;

export const StyledNavGroup = styled(Stack)`
  padding: ${ props => props.theme.new.spacing[4] };
`;
