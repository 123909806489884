import { Button } from '@mui/material';
import styled from '@emotion/styled';

import { FlexPullRight } from '../../../Common/Component';

export const StyledIconButton = styled(Button)`
  .MuiButton-startIcon {
    width: 1.5rem;
    height: 1.5rem;
    margin-left: auto;
  }
`;

export const ButtonContainer = styled(FlexPullRight)`
  > * {
    margin-left: 1rem;
  }
`;

export const StyledTabContent = styled.div`
  padding: ${ props => props.theme.spacing(3) };
`;
