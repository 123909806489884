import { FunctionComponent, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { CsvExportButtonWithDefaultErrorHandling, Flex } from '../../../Common/Component';
import { CoverExportDateRangePicker } from '..';
import { StyledCoverExport } from './style';
import { DateRangePickerValue } from '../../../Common/Model/DateRangePicker';
import { transformOffsetToRange } from '../../../Common/Utility';

type ExportCoverParams = {
  end_before: string;
  start_after: string;
  tags: number[][];
  tz: string;
}

export const CoverExport: FunctionComponent = () => {
  const intl = useIntl();
  const [exportDateRangeValue, setExportDateRangeValue] = useState<DateRangePickerValue>({
    type: 'offset',
    offset: 90,
    from: 'start',
  });
  const exportParams = useMemo<ExportCoverParams | undefined>(() => {
    if (exportDateRangeValue.type !== 'offset') {
      return;
    }

    const range = transformOffsetToRange(exportDateRangeValue);
    return {
      start_after: range.start,
      end_before: range.end,
      tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      tags: [],
    };
  }, [exportDateRangeValue]);

  return (
    <Flex>
      <StyledCoverExport className="testing">
        <CoverExportDateRangePicker
          value={ exportDateRangeValue }
          onChange={ setExportDateRangeValue }
        />
        <CsvExportButtonWithDefaultErrorHandling
          endpoint="/covers/export"
          fileNamePrefix={ intl.formatMessage({
            id: 'events.covers.exportPrefix',
            defaultMessage: 'cover',
          }) }
          params={ exportParams }
        />
      </StyledCoverExport>
    </Flex>
  )
};
