import { Fragment, FunctionComponent, ReactNode, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';

import { Audience, Segment } from '../../../Model';
import { LabelledDivider, SegmentBuilder } from '../..';
import { PopOverState, ValidationTree } from '../../../../Common/Model';
import { ArrayHelper } from '../../../../Common/Utility';
import AddIcon from '../../../../Assets/img/icons/streamline/add-circle.svg';
import { Flex, FlexPullRight, VerticallySpaced, VideoDialog } from '../../../../Common/Component';
import { StyledAudienceSize, StyledSegmentContainer, StyledBulbIconContainer } from './style';
import BulbIcon from '../../../../Assets/img/icons/streamline/bulb-1.svg';

interface Props {
  audienceSize?: number;
  audienceSizeLinkUrl?: string;
  audience: Audience;
  validation?: ValidationTree<Audience>;
  multipleSegments?: boolean;
  readonly?: boolean;
  onChange?: (audience: Audience) => void;
}

const onlyHasEveryoneCondition = (audience: Audience): boolean => (
  audience.segments.length === 1
  && audience.segments[0].conditions.length === 1
  && audience.segments[0].conditions[0].type === 'everyone'
);

export const AudienceBuilder: FunctionComponent<Props> = ({
  audienceSize,
  audienceSizeLinkUrl,
  audience,
  onChange,
  validation,
  readonly = false,
  multipleSegments = false,
}) => {
  const [helpVideoDialogState, setHelpVideoDialogState] = useState<PopOverState>(PopOverState.CLOSED);
  const intl = useIntl();

  const defaultSegment = {
    conditions: [
      { type: 'everyone' },
    ],
  };

  const whenSegmentChanged = (index: number, segment: Segment): void => {
    const newSegments = ArrayHelper.replace(audience.segments, index, segment);
    onChange && onChange({
      ...audience,
      segments: newSegments.length === 0 ? [{...defaultSegment}] : newSegments,
    });
  };

  const whenAddSegmentClicked = (): void => {
    onChange && onChange({
      ...audience,
      segments: [
        ...audience.segments,
        {
          ...defaultSegment,
        }
      ],
    });
  };

  const whenRemoveSegmentClicked = (index: number): void => {
    const newSegments = ArrayHelper.remove(audience.segments, index);
    onChange && onChange({
      ...audience,
      segments: newSegments.length === 0 ? [{...defaultSegment}] : newSegments,
    });
  };

  const segments = audience.segments.length
    ? audience.segments
    : [defaultSegment];

  const isEveryone = onlyHasEveryoneCondition(audience);

  return (
    <VerticallySpaced gap={ 1 }>
      <VerticallySpaced gap={ 3 }>
        { audienceSizeLinkUrl && (
          <StyledAudienceSize>
            <FormattedMessage
              id="audienceBuilder.audienceSize"
              description="Text above audience builder with audience size."
              defaultMessage="Current audience size: <a>{ calculating, select, true {calculating…} other {{ audienceSize, plural, one {# person} other {# people} }}}</a>"
              values={ {
                a: (chunks: ReactNode) => <a href={ audienceSizeLinkUrl }>{ chunks }</a>,
                audienceSize: audienceSize || 0,
                calculating: audienceSize === undefined,
              } }
            />
          </StyledAudienceSize>
        ) }
        {
          segments.map((segment, index) => {
            const segmentBuilder = (
              <>
                <SegmentBuilder
                  key={ index }
                  segment={ segment }
                  onChange={ (segment) => whenSegmentChanged(index, segment) }
                  validation={ validation?.children.segments?.children[0] }
                  readonly={ readonly }
                  removable={ !readonly && !isEveryone && multipleSegments }
                  onRemove={ () => whenRemoveSegmentClicked(index) }
                />
              </>
            );
            return multipleSegments
              ? (
                <Fragment key={ index }>
                  { index !== 0 && (
                    <LabelledDivider
                      label={ intl.formatMessage({
                        id: 'audiences.builder.divider.or',
                        defaultMessage: 'or',
                      }) }
                    />
                  ) }
                  <StyledSegmentContainer key={ index }>
                    { segmentBuilder }
                  </StyledSegmentContainer>
                </Fragment>
              )
              : (
                <Fragment key={ index }>
                  { segmentBuilder }
                </Fragment>
              );
          })
        }
      </VerticallySpaced>
      { !readonly && multipleSegments && (
        <>
          <Flex gap={ 2 }>
            <Flex gap={ 2 }>
              <StyledBulbIconContainer>
                <BulbIcon />
              </StyledBulbIconContainer>
              <Flex gap={ 1 }>
                <FormattedMessage
                  defaultMessage="'Add segment' allows you to build more than one audience. { videoLink }"
                  description="Add segment hint in audience builder"
                  values={ {
                    videoLink: (
                      <Button
                        inline
                        onClick={ () => setHelpVideoDialogState(PopOverState.OPEN) }
                        color="secondary"
                      >
                        <FormattedMessage
                          defaultMessage="Show me"
                          description="Link top open add segment hint video in audience builder"
                        />
                      </Button>
                    ),
                  } }
                />
              </Flex>
            </Flex>
            <FlexPullRight>
              <Button
                onClick={ whenAddSegmentClicked }
                IconComponent={ AddIcon }
                variant="secondary"
              >
                <FormattedMessage
                  id="audiences.builder.addSegment"
                  defaultMessage="Add segment"
                />
              </Button>
            </FlexPullRight>
          </Flex>
          { helpVideoDialogState !== PopOverState.CLOSED && (
            <VideoDialog
              open={ helpVideoDialogState === PopOverState.OPEN }
              url="https://player.vimeo.com/video/751333985"
              onClose={ () => setHelpVideoDialogState(PopOverState.WILL_CLOSE) }
              TransitionProps={ {
                onExited: () => setHelpVideoDialogState(PopOverState.CLOSED),
              } }
            />
          ) }
        </>
      ) }
    </VerticallySpaced>
  );
};
