import { FC, useState, PropsWithChildren } from 'react';
import { useIntl } from 'react-intl';
import { Button } from '@mui/material';

import {
  PreviewPane,
  StyledAspectRatioControls,
  StyledButtonGroup,
  StyledTooltip,
  StyledBackgroundContainer,
  StyledStickyContainer
} from './style';
import TooltipIcon from '../../../Assets/img/icons/streamline/question-circle.svg';
import { ContentPreview } from '../ContentPreview/ContentPreview';

type LocalisedAspectRatio = {
  value: number;
  label: string;
};

export const AspectRatioPreview: FC<PropsWithChildren> = ({ children }) => {
  const intl = useIntl();
  const [activeAspectRatio, setActiveAspectRatio] = useState<number>(16 / 9);
  const aspectRatios: LocalisedAspectRatio[] = [
    {
      value: 16 / 9,
      label: intl.formatMessage({
        id: 'content.aspectRatio.16:9',
        defaultMessage: '9:16'
      }),
    },
    {
      value: 3 / 2,
      label: intl.formatMessage({
        id: 'content.aspectRatio.2:3',
        defaultMessage: '2:3'
      }),
    },
    {
      value: 10 / 8,
      label: intl.formatMessage({
        id: 'content.aspectRatio.8:10',
        defaultMessage: '8:10'
      }),
    },
  ];

  return (
    <PreviewPane>
      <StyledBackgroundContainer/>
      <StyledStickyContainer>
        <ContentPreview aspectRatio={ activeAspectRatio }>
          { children }
        </ContentPreview>
        <StyledAspectRatioControls>
          <StyledButtonGroup
            variant="text"
            color="inherit"
          >
            {
              aspectRatios.map(aspectRatio => (
                <Button
                  color={ aspectRatio.value === activeAspectRatio ? 'secondary' : 'inherit' }
                  key={ aspectRatio.value }
                  onClick={ () => setActiveAspectRatio(aspectRatio.value) }
                >
                  { aspectRatio.label }
                </Button>
              ))
            }
          </StyledButtonGroup>
          <StyledTooltip
            title={
              intl.formatMessage({
                id: 'content.preview.tooltip',
                description: 'Tooltip explaining the function of the aspect ratio controls.',
                defaultMessage: 'Preview what your content will look like at different device aspect-ratios.'
              }, {
                ratio: aspectRatios.find(aspectRatio => aspectRatio.value === activeAspectRatio)?.label || '',
              })
            }
          >
            <div>
              <TooltipIcon/>
            </div>
          </StyledTooltip>
        </StyledAspectRatioControls>
      </StyledStickyContainer>
    </PreviewPane>
  );
};
