import styled from '@emotion/styled';

export const StyledTemplates = styled.ul`
  display: grid;
  margin: 0;
  padding: 0 0 ${ props => props.theme.spacing(2) } 0;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: ${ props => props.theme.spacing(4) };
  row-gap: ${ props => props.theme.spacing(4) };

  ${ props => props.theme.breakpoints.down('md') } {
    grid-template-columns: 1fr 1fr;
    column-gap: ${ props => props.theme.spacing(2) };
    row-gap: ${ props => props.theme.spacing(2) };
  }

  ${ props => props.theme.breakpoints.down('sm') } {
    grid-template-columns: 1fr;
  }
`;
