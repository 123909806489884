import { useCallback, useContext } from 'react';

import { GoogleAnalyticsContext } from '../Context';

interface GoogleAnalyticsEvent {
  category: string;
  action: string;
  label?: string;
  value?: number;
  nonInteraction?: boolean;
}

interface GoogleAnalytics {
  trackEvent: (event: GoogleAnalyticsEvent) => void;
  setUserId: (userId: string) => void;
}

export const useGoogleAnalytics = (): GoogleAnalytics => {
  const { ga } = useContext(GoogleAnalyticsContext);

  const trackEvent = useCallback((event: GoogleAnalyticsEvent) => {
    ga.event(event);
  }, [ga]);

  const setUserId = useCallback((userId: string) => ga.set({ userId }), [ga]);

  return {
    trackEvent,
    setUserId,
  };
};
