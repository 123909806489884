export class ImmutableMap<KeyType, ValueType> {
  private readonly map: Map<KeyType, ValueType>;

  private constructor(entries?: [KeyType, ValueType][]) {
    this.map = new Map<KeyType, ValueType>(entries);
  }

  public get count(): number {
    return this.map.size;
  }

  public get values(): ValueType[] {
    return Array.from(this.map.values());
  }

  public get entries(): [KeyType, ValueType][] {
    return Array.from(this.map.entries());
  }

  public get(key: KeyType): ValueType | undefined {
    return this.map.get(key);
  }

  public contains(key: KeyType): boolean {
    return this.map.has(key);
  }

  public clear(): ImmutableMap<KeyType, ValueType> {
    return ImmutableMap.create();
  }

  public set(key: KeyType, value: ValueType): ImmutableMap<KeyType, ValueType> {
    const map = new Map(this.map);
    return new ImmutableMap(
      Array.from(
        map
          .set(key, value)
          .entries(),
      ),
    );
  }

  public remove(key: KeyType): ImmutableMap<KeyType, ValueType> {
    const map = new Map(this.map)
    map.delete(key);
    return new ImmutableMap(
      Array.from(
        map.entries(),
      ),
    );
  }

  public filter = (fn: (key: KeyType, value: ValueType) => boolean): ImmutableMap<KeyType, ValueType> => {
    const filteredEntries = this.entries.filter(entry => {
      return fn(entry[0], entry[1]);
    });
    return new ImmutableMap<KeyType, ValueType>(filteredEntries);
  }

  public static create<KeyType, ValueType>(): ImmutableMap<KeyType, ValueType> {
    return new ImmutableMap();
  }

  public static fromArray<KeyType, ValueType>(array: [KeyType, ValueType][]): ImmutableMap<KeyType, ValueType> {
    return new ImmutableMap(array);
  }
}
