import { FC, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { useContextOrThrow } from '../../../Core/Hook';
import { ToastContext } from '../../../Core/Context';
import { CsvExportButton, CsvExportButtonProps } from '../CsvExportButton/CsvExportButton';

type Props = Omit<CsvExportButtonProps, 'onError'>;

export const CsvExportButtonWithDefaultErrorHandling: FC<Props> = props => {
  const intl = useIntl();
  const { addErrorToast } = useContextOrThrow(ToastContext);

  const whenErrored = useCallback(() => (
    addErrorToast(intl.formatMessage({
      description: 'Default generic CSV export error message used throughout console.',
      defaultMessage: 'Export failed',
    })
  )), [addErrorToast, intl]);

  return (
    <CsvExportButton
      { ...props }
      onError={ whenErrored }
    />
  );
};
