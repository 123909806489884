import { ComponentProps, FC, ReactNode, useMemo } from 'react';
import { BreakpointContext } from 'op-storybook/lib/providers/BreakpointProvider/BreakpointProvider';
import { StyleBuilder } from 'op-storybook/lib/model/StyleBuilder/StyleBuilder';

import { ModalDialog } from './ModalDialog';
import { PageLayoutProvider } from '../../../Core/Provider/PageLayoutProvider';
import { useContextOrThrow } from '../../../../Core/Hook';

type Props = {
  open: boolean;
  onOpenChange?: (open: boolean) => void;
  children?: ReactNode;
  transitionHandlers?: ComponentProps<typeof ModalDialog>['transitionHandlers'];
  fitContent?: boolean;
  secondaryPage?: {
    node: ReactNode;
    active: boolean;
  };
};

export const Sheet: FC<Props> = ({
  open,
  onOpenChange,
  transitionHandlers,
  fitContent = false,
  secondaryPage,
  children,
}) => {
  const screenWidth = useContextOrThrow(BreakpointContext);
  const styles = useMemo(() => buildStyles({
    fitContent,
    secondaryPagePresent: !!secondaryPage,
    secondaryPageActive: !!secondaryPage?.active,
  }), [fitContent, secondaryPage]);

  return (
    <ModalDialog
      open={ open }
      transitionHandlers={ transitionHandlers }
      onOpenChange={ onOpenChange }
    >
      <div
        css={ styles.sheetContainer }
      >
        <div
          css={ styles.pageSwitcher }
        >
          <div css={ styles.pageContainer }>
            <PageLayoutProvider
              desktopWidth="400px"
              controlOverflow={ true }
              active={ false }
              modal={ true }
              viewportBoundaries={ {
                top: false,
                left: screenWidth.lessThan.sm,
                right: screenWidth.lessThan.sm,
                bottom: screenWidth.lessThan.sm,
              } }
            >
              { children }
            </PageLayoutProvider>
          </div>
          { secondaryPage && (
            <div css={ styles.pageContainer }>
              <PageLayoutProvider
                desktopWidth="400px"
                controlOverflow={ true }
                active={ false }
                modal={ true }
                viewportBoundaries={ {
                  top: false,
                  left: screenWidth.lessThan.sm,
                  right: screenWidth.lessThan.sm,
                  bottom: screenWidth.lessThan.sm,
                } }
              >
                { secondaryPage.node }
              </PageLayoutProvider>
            </div>
          ) }
        </div>
      </div>
    </ModalDialog>
  );
}

const buildStyles: StyleBuilder<{
  fitContent: boolean;
  secondaryPagePresent: boolean;
  secondaryPageActive: boolean;
}> = ({ fitContent, secondaryPagePresent, secondaryPageActive }) => ({
  sheetContainer: theme => ({
    '--toolbar-width': '100%',
    '--toolbar-offset': '0px',
    backgroundColor: 'white',
    width: '400px',
    borderTopLeftRadius: theme.new.borderRadius.large,
    borderTopRightRadius: theme.new.borderRadius.large,
    boxShadow: `${ theme.new.shadow.lg }, ${ theme.new.shadow.sm }`,
    position: 'relative',
    overflow: 'hidden',
    pointerEvents: 'all',
    touchAction: 'pan-y',
    zIndex: 0,
    willChange: 'transform',
    margin: 'auto',
    maxHeight: '700px',
    borderBottomLeftRadius: theme.new.borderRadius.large,
    borderBottomRightRadius: theme.new.borderRadius.large,
    height: fitContent
      ? 'max-content'
      : `calc(100dvh - ${ theme.new.toolbar.offset.top } - ${ theme.new.toolbar.offset.bottom } - ${ theme.new.spacing[4] })`,

    [theme.new.breakpoints.sm.down]: {
      width: '100%',
      margin: 'auto auto 0',
      maxHeight: 'initial',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      height: fitContent
        ? 'max-content'
        : [
          `calc(100% - (${ theme.new.toolbar.height } - 16px))`,
          `calc(100dvh - (${ theme.new.toolbar.height } - 16px))`
        ],
    },
  }),
  pageSwitcher: {
    display: 'grid',
    gridTemplateColumns: secondaryPagePresent ? '100% 100%' : '1fr',
    height: '100%',
    transition: 'transform 100ms',
    transform: `translateX(${ secondaryPageActive ? '-100%' : '0%' })`,
  },
  pageContainer: {
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
  },
});
