import { FunctionComponent, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { ListItem } from '@mui/material';

import { Picker } from '../../../../Components/Input/Picker/Picker';

export type NotificationMethodFilterValue = null | 'push' | 'email' | 'sms';

interface Props {
  value: NotificationMethodFilterValue,
  onChanged: (value: NotificationMethodFilterValue) => void,
}

export const NotificationMethodFilter: FunctionComponent<Props> = ({
  value,
  onChanged,
}) => {
  const intl = useIntl();
  const options: NotificationMethodFilterValue[] = [null, 'push', 'email', 'sms'];

  const localiseValue = useCallback(
    (value: NotificationMethodFilterValue): string => {
      switch (value) {
        case null:
          return intl.formatMessage({
            id: 'broadcastReport.notificationMethod.all',
            description: 'Default value for notification method filter in report table',
            defaultMessage: 'All notification channels',
          });
        case 'push':
          return intl.formatMessage({
            id: 'broadcastReport.notificationMethod.push',
            description: 'Value for push notification method filter in report table',
            defaultMessage: 'Push message',
          });
        case 'email':
          return intl.formatMessage({
            id: 'broadcastReport.notificationMethod.email',
            description: 'Value for email notification method filter in report table',
            defaultMessage: 'Email',
          });
        case 'sms':
          return intl.formatMessage({
            id: 'broadcastReport.notificationMethod.sms',
            description: 'Value for SMS notification method filter in report table',
            defaultMessage: 'SMS',
          });
      }
      return '';
    },
    [
      intl,
    ],
  );

  return (
    <Picker
      buttonLabel={ localiseValue(value) }
      items={ options }
      selectedItems={ [value] }
      closeOnSelect
      allowEmptySelection={ false }
      itemRenderFn={ (value, selected) => (
        <ListItem
          dense
          key={ value || 'all' }
          selected={ selected }
          onClick={ () => onChanged(value) }
        >
          { localiseValue(value) }
        </ListItem>
      ) }
      itemCompareFn={ (valueA, valueB) => valueA === valueB }
    />
  );
};
