import { FC, useMemo } from 'react';
import FormIcon from 'op-storybook/lib/assets/icon/figma/file-06.svg';
import { FeaturedIcon } from 'op-storybook/stories/components/FeaturedIcon/FeaturedIcon';
import { SecondaryInformation } from 'op-storybook/stories/components/Typography/SecondaryInformation';
import { PrimaryInformation } from 'op-storybook/stories/components/Typography/PrimaryInformation';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { FormattedMessage } from 'react-intl';

import { FormBlock as FormBlockModel } from '../../../Service/Blocks/FormBlockDefinition';
import { BroadcastAttachments } from '../../../Model/BroadcastAttachments';

type Props = {
  block: FormBlockModel;
  attachments: BroadcastAttachments;
} & (
  {
    functionalLink: true;
    onClick?: never;
  } |
  {
    functionalLink?: false | never;
    onClick: () => void;
  }
)

export const FormBlock: FC<Props> = ({
  block,
  attachments,
  functionalLink = false,
  onClick,
}) => {
  const form = useMemo(() => {
    if (!block.attributes.formId) {
      return null;
    }

    return attachments.forms.find(form => form.id === block.attributes.formId) || null;
  }, [attachments.forms, block.attributes.formId]);

  return (
    <a
      onClick={ onClick }
      {
        ...form && functionalLink
          ? {
            href: `/my-forms/${ form.id }`,
            target: '_blank',
            rel: 'noreferrer',
          }
          : {}
      }
      css={ theme => ({
        cursor: 'pointer',
        userSelect: 'none',
        width: '100%',
        display: 'flex',
        boxShadow: theme.new.shadow.xs,
        borderRadius: theme.new.borderRadius.standard,
        background: theme.new.basePalette.white.main,
        textAlign: 'left',
        margin: 0,
        padding: 0,
        border: theme.new.border.standard,
        position: 'relative',
      }) }
    >
      <div
        css={ {
          width: '64px',
          height: '64px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        } }
      >
        <FeaturedIcon
          IconComponent={ FormIcon }
          size="lg"
          variant="light-circle-outline"
          colour="primary"
        />
      </div>
      <Stack
        direction="column"
        gap={ 1 }
        justify="center"
        css={ theme => ({
          padding: `${ theme.new.spacing[2] } ${ theme.new.spacing[2] } ${ theme.new.spacing[2] } 0`,
        }) }
      >
        <PrimaryInformation>{ form?.name }</PrimaryInformation>
        <SecondaryInformation>
          <FormattedMessage
            description="Supporting text in form link button"
            defaultMessage="{ touchDevice, select, true {Tap} other {Click} } to view form"
            values={ {
              touchDevice: window.matchMedia('(hover: none)').matches,
            } }
          />
        </SecondaryInformation>
      </Stack>
    </a>
  );
};
