import { FC, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';

import { TemplateDialog } from '../../../../Common/Component';
import { useLocalStorageHookContext } from '../../../../Common/Hook';
import { useFetchFormsTemplates, useFormTemplatesGoogleEvents } from '../../../Hook';
import { FormTemplatePreviewPane } from '..';
import { FormFactory } from '../../../Utility';
import { useContentDefinitionRegistry } from '../../../../Content/Hook';
import { DraftSingleContentForm, MinimalFormWithSingleContentCards } from '../../../Model';

type Props = {
  open: boolean;
  onClose: () => void;
};

export const FormTemplateDialog: FC<Props> = ({
  open,
  onClose,
}) => {
  const contentDefinitionRegistry = useContentDefinitionRegistry();
  const intl = useIntl();
  const { trackPreview, trackRate, trackUse } = useFormTemplatesGoogleEvents();
  const getStorageHook = useLocalStorageHookContext();
  const useShowModalWhenCreating = getStorageHook<boolean>('formTemplates.showModalWhenCreating', false);
  const [showModalWhenCreating, setShowModalWhenCreating] = useShowModalWhenCreating();
  const location = useLocation();
  const onCreatePage = useMemo<boolean>(() => location.pathname.includes('/forms/create'), [location.pathname]);

  const createBlankFormLabel = (
    <FormattedMessage
      id="formTemplatesDialog.createButton"
      description="Create form button label in forms template modal."
      defaultMessage="Create blank form"
    />
  );

  const transformFormToDraft = useCallback((
    form: MinimalFormWithSingleContentCards,
  ): DraftSingleContentForm => (
    FormFactory.createDraftFormFromMinimalForm(contentDefinitionRegistry, form)
  ), [contentDefinitionRegistry]);

  return (
    <TemplateDialog
      open={ open }
      onClose={ onClose }
      useFetchTemplates={ useFetchFormsTemplates }
      getUseButtonProps={ templateWithContent => {
        const draftForm = transformFormToDraft(templateWithContent.form);

        return {
          component: Link,
          to: {
            pathname: '/forms/build-template',
            state: { template: { ...draftForm, templateId: templateWithContent.template.id } },
          },
          onClick: () => trackUse(templateWithContent.template.name),
        };
      } }
      renderPreviewPane={ templateWithContent => {
        const draftForm = transformFormToDraft(templateWithContent.form);
        return <FormTemplatePreviewPane form={ draftForm }/>
      } }
      showWhenCreatingLabel={ intl.formatMessage({
        id: 'formTemplatesDialog.showModalWhenCreating',
        description: 'Label for checkbox which shows/hides template dialog when creating a form.',
        defaultMessage: 'Show templates when creating a form.',
      }) }
      showWhenCreating={ showModalWhenCreating }
      onShowWhenCreatingChange={ setShowModalWhenCreating }
      createButtonProps={
        onCreatePage
          ? {
            onClick: onClose,
            children: createBlankFormLabel,
          }
          : {
            component: Link,
            to: {
              pathname: '/forms/create',
              state: { hideTemplates: true }
            },
            children: createBlankFormLabel,
          }
      }
      feedbackUrl="https://forms.gle/e5RoFQnW5v9hf8J88"
      onPreviewViewed={ broadcastTemplate => trackPreview(broadcastTemplate.template.name) }
      onPositiveFeedbackReceived={ broadcastTemplate => trackRate(broadcastTemplate.template.name, '👍') }
      onNegativeFeedbackReceived={ broadcastTemplate => trackRate(broadcastTemplate.template.name, '👎') }
    />
  );
};
