export enum PeoplePermission {
  ALL = 'people',
  CREATE = 'people.create',
  UPDATE = 'people.update',
  DELETE = 'people.delete',
  READ = 'people.read',
  READ_SENSITIVE = 'people.readSensitive',
  IMPORT = 'people.import',
  REPORT = 'people.report',
  PROFILES = 'people.profiles',
  PROFILES_READ = 'people.profiles.read',
  PROFILES_UPDATE = 'people.profiles.update',
  PROFILES_DELETE = 'people.profiles.delete',
  INVITATIONS = 'people.invitations',
  INVITATIONS_CREATE = 'people.invitations.create',
  INVITATIONS_UPDATE = 'people.invitations.update',
  INVITATIONS_READ = 'people.invitations.read',
  AVATAR_READ = 'people.avatar.read',
  METRICS = 'people.metrics',
}
