import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDateTimeFormatter } from '@ourpeople/shared/Core/Hook/useDateFormatter';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { ErrorBoundary } from '@sentry/react';
import { useParams } from 'react-router-dom';
import { parseISO } from 'date-fns';

import { useQuery } from '../../../Hooks/useQuery';
import { AuthDescription, Content } from '../../../Models';
import {
  StyledFooter,
  StyledHeader,
  StyledMain,
  StyledOurPeopleLogo,
  StyledSpaceLogo,
  StyledSubmission,
  StyledSubmissionContent,
  StyledTitle
} from './style';
import {
  FormAndMultiContentCards,
  FormRequest,
  MultiContentSubmission,
  Response,
  SingleContentFormAndCards,
  SingleContentSubmission
} from '../../Model';
import { PersonParser } from '../../../Utility';
import { FormTransformer, SubmissionTransformer } from '../../Utility';
import { FormContentDefinitionRegistryProvider } from '../../Provider';
import { FormContentDetail } from '../FormContentDetail/FormContentDetail';
import { SubmittedComponent } from '../../../Content/Model';
import { ContentDefinitionRegistryContext } from '../../../Content/Context';
import { useContextOrThrow } from '../../../Core/Hook';
import { ApiContext, AuthContext } from '../../../Contexts';
import { ContentDefinitionRegistry } from '../../../Content/Service';
import {
  RichTextPlaceholderDefinitionRegistryProvider
} from '../../../Common/Provider/RichTextPlaceholderDefinitionRegistryProvider';

type FormAndSubmission = {
  formAndCards: SingleContentFormAndCards;
  submission: SingleContentSubmission;
};

type ErrorWithContext = {
  context: string;
  error: unknown;
};

type Params = {
  formId?: string;
};

type ResponseReadyState = {
  contentReady: boolean;
  responseReady: boolean;
};

type ResponseReadyStates = Record<string, ResponseReadyState>;

export const FormSubmissionsForPdfExport: FC = () => {
  const { uniformDateTime } = useDateTimeFormatter();
  const { formId } = useParams<Params>();
  const api = useContextOrThrow(ApiContext);
  const intl = useIntl();
  const query = useQuery();
  const token = query.get('authToken');
  const [logoReady, setLogoReady] = useState<boolean>(false);
  const [responseReadyStates, setResponseReadyStates] = useState<ResponseReadyStates>({});
  const submissionId = (query.get('formSubmissionIds') || '').split(',')[0];
  const [authDescription, setAuthDescription] = useState<AuthDescription>();
  const [formAndSubmissions, setFormAndSubmissions] = useState<FormAndSubmission[]>([]);
  const [error, setError] = useState<ErrorWithContext>();
  const envSettings = useMemo<null | AuthDescription['env_settings']>(
    () => authDescription?.env_settings ?? null,
    [authDescription],
  );

  if (!token || !submissionId || !formId) {
    throw new Error('Missing parameters');
  }

  useEffect(() => {
    if (!formAndSubmissions.length || !logoReady) {
      return;
    }

    const totalResponseCount = formAndSubmissions.reduce<number>(
      (runningTotal, formAndSubmissions) => (
        runningTotal + formAndSubmissions.submission.cardSubmissions.length
      ),
      0,
    );

    if (Object.entries(responseReadyStates).length !== totalResponseCount) {
      return;
    }

    const allSubmissionsReady = formAndSubmissions.every(
      (formAndSubmissions) => (
        formAndSubmissions.submission.cardSubmissions.every(submission => {
          const responseReadyState = responseReadyStates[submission.id];

          return responseReadyState?.responseReady && responseReadyState?.contentReady;
        })
      ),
    );

    if (!allSubmissionsReady) {
      return;
    }

    console.debug('Dispatching opContentReady event');
    const contentReadyEvent = new CustomEvent('opContentReady');
    document.dispatchEvent(contentReadyEvent);
  }, [formAndSubmissions, logoReady, responseReadyStates]);

  useEffect(() => {
    api.setJwtToken(token);

    return () => api.setJwtToken(null);
  }, [api, token]);

  useEffect(() => {
    if (!api.authorized) {
      return;
    }

    api.get<AuthDescription>('/me/describe')
      .then(response => response.data)
      .then(description => setAuthDescription(description))
      .catch((error: unknown) => {
        setError({
          error,
          context: 'Error fetching auth description:'
        });
      });

    let errorContext: string;

    void Promise.all([
      api.get<FormAndMultiContentCards>(`/forms/${ formId }`)
        .then(response => response.data)
        .catch((error: unknown) => {
          errorContext = `Error fetching form with ID ${ formId }:`;
          throw error;
        }),
      api.get<{ submission: MultiContentSubmission }>(`/forms/submissions/${ submissionId }`)
        .then(response => response.data.submission)
        .catch((error: unknown) => {
          errorContext = `Error fetching submission with ID ${ submissionId }:`;
          throw error;
        })
    ])
      .then(([formAndMultiContentCards, multiContentSubmission]) => {
        setFormAndSubmissions([
          {
            formAndCards: FormTransformer.consolidateFormAndCardsContent(formAndMultiContentCards),
            submission: SubmissionTransformer.consolidateSubmissionContent(multiContentSubmission)
          }
        ]);
      })
      .catch((error: unknown) => setError({
        context: errorContext,
        error,
      }));
  }, [api, formId, submissionId, token]);

  const getErrorOutput = useCallback((error: ErrorWithContext) => (
    <pre id="error">
      { `${ error.context }\n` }
      {
        error.error instanceof Error
          ? `${ error.error.toString() }\n${ error.error.stack || '' }`
          : JSON.stringify(error)
      }
    </pre>
  ), []);

  const getFormContentDefinitionContextConsumer = useCallback((submission: SingleContentSubmission, formAndCards: SingleContentFormAndCards) => {
    return (contentDefinitionRegistry: ContentDefinitionRegistry | null) => {
      return contentDefinitionRegistry && formAndCards.cards.map((card, cardIndex) => {
        const { getContentDefinition } = contentDefinitionRegistry;
        const contentDefinition = getContentDefinition(card.content.type);
        const SubmittedComponent = contentDefinition.SubmittedComponent as SubmittedComponent<Response, Content>;
        const cardSubmission = submission.cardSubmissions.find(
          submission => submission.cardId === card.id,
        );
        const response = cardSubmission?.response;

        if (!contentDefinition) {
          return null;
        }

        const onResponseFinished = () => cardSubmission && setResponseReadyStates(responseReadyStates => ({
          ...responseReadyStates,
          [cardSubmission.id]: {
            contentReady: responseReadyStates[cardSubmission.id]?.contentReady || false,
            responseReady: true,
          },
        }));

        const onContentFinished = () => cardSubmission && setResponseReadyStates(responseReadyStates => ({
          ...responseReadyStates,
          [cardSubmission.id]: {
            contentReady: true,
            responseReady: responseReadyStates[cardSubmission.id]?.responseReady || false,
          },
        }));

        return (
          <StyledSubmissionContent key={ card.id }>
            <FormContentDetail
              currentIndex={ cardIndex }
              cardCount={ formAndCards.cards.length }
              contentTitle={ contentDefinition.getContentTitle(intl, card.content) }
              contentDescription={ (card.content as FormRequest).text || undefined }
              imageId={ card.image?.uploadId }
              imageFit={ card.image?.fit }
              useThumbnail={ true }
              onFinish={ onContentFinished }
            >
              <SubmittedComponent
                card={ card }
                response={ response }
                onFinish={ onResponseFinished }
                submissionDate={ submission.formSubmission.created?.at ? parseISO(submission.formSubmission.created.at) : new Date() }
              />
            </FormContentDetail>
          </StyledSubmissionContent>
        );
      });
    }
  }, [intl]);

  const submissions = useMemo(() => (
    formAndSubmissions.map(({ formAndCards, submission }) => {
      const contextConsumer = getFormContentDefinitionContextConsumer(submission, formAndCards);

      return (
        <StyledSubmission key={ formAndCards.form.id }>
          <StyledHeader>
            <StyledSpaceLogo
              src={ envSettings?.smallLogoUrl }
              alt={ `${ envSettings?.name || '' } logo` }
              onLoad={ () => setLogoReady(true) }
            />
            <StyledTitle>
              <h1>
                { formAndCards.form.name }
              </h1>
              { submission.formSubmission.created && (
                <h2>
                  <FormattedMessage
                    defaultMessage="Submitted by { name } on { date }"
                    description=""
                    values={ {
                      name: PersonParser.fullName(submission.formSubmission.created.by),
                      date: uniformDateTime(submission.formSubmission.created.at),
                    } }
                  />
                </h2>
              ) }
            </StyledTitle>
          </StyledHeader>
          <table>
            <thead>
            <tr>
              <td>&nbsp;</td>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>
                <FormContentDefinitionRegistryProvider>
                  <ContentDefinitionRegistryContext.Consumer>
                    { contextConsumer }
                  </ContentDefinitionRegistryContext.Consumer>
                </FormContentDefinitionRegistryProvider>
              </td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
              <td>&nbsp;</td>
            </tr>
            </tfoot>
          </table>
          <StyledFooter>
            <>
              <StyledOurPeopleLogo/>
              <Stack
                direction="column"
                gap={ 1 }
                align="center"
              >
                { submission.formSubmission.created && (
                  <span>
                    <FormattedMessage
                      description="Form name and submission date in footer in pdf version of form submission"
                      defaultMessage="{ formName } - { submissionDate }"
                      values={ {
                        formName: formAndCards.form.name,
                        submissionDate: uniformDateTime(submission.formSubmission.created.at),
                      } }
                    />
                  </span>
                ) }
                <span>
                  <FormattedMessage
                    description="Form name and submission date in footer in pdf version of form submission"
                    defaultMessage="©{ year } { spaceName }, ©{ year } OurPeople"
                    values={ {
                      year: new Date().toLocaleString(intl.locale, { year: 'numeric' }),
                      spaceName: envSettings?.name,
                    } }
                  />
                </span>
              </Stack>
            </>
          </StyledFooter>
        </StyledSubmission>
      );
    })
  ), [
    envSettings?.name,
    envSettings?.smallLogoUrl,
    formAndSubmissions,
    getFormContentDefinitionContextConsumer,
    intl.locale,
    uniformDateTime
  ]);

  return (
    error
      ? getErrorOutput(error)
      : api.authorized && !!authDescription
        ? (
          <ErrorBoundary
            beforeCapture={ (_scope, error) => setError({
              context: 'Error rendering <FormSubmissionsForPdfGeneration/>:',
              error,
            }) }
            fallback={ error && getErrorOutput(error) }
          >
            <AuthContext.Provider
              value={ {
                authDescription,
                refreshAuthDescription: () => { /* noop */ },
                lastDescribed: 0,
              } }
            >
              <RichTextPlaceholderDefinitionRegistryProvider>
                <StyledMain>
                  { submissions }
                </StyledMain>
              </RichTextPlaceholderDefinitionRegistryProvider>
            </AuthContext.Provider>
          </ErrorBoundary>
        )
        : null
  );
};
