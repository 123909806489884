import { FC } from 'react';
import { parseISO } from 'date-fns';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { StackEnd } from 'op-storybook/lib/components/StackEnd/StackEnd';
import { LineClamp } from 'op-storybook/stories/components/LineClamp/LineClamp';
import { Typography } from 'op-storybook/stories/components/Typography/Typography';
import { Avatar } from 'op-storybook/stories/components/Avatar/Avatar';

import { DeliveryDate, MandatoryIndicator, NudgeIndicator } from '..';
import { PersonalDelivery } from '../../Model';
import { PersonParser } from '../../../Utility';
import { PersonAvatar } from '../../../Common/Component/PersonAvatar/PersonAvatar';

type Props = {
  delivery: PersonalDelivery;
};

export const DeliveryHeader: FC<Props> = ({
  delivery,
}) => {
  const deliveryDate = parseISO(delivery.bumpedAt ?? delivery.receivedAt);
  const unseen = !delivery.completedAt && delivery.delivery.status !== 'recalled';

  return (
    <Stack
      gap={ 2 }
      noWrap
    >
      <PersonAvatar
        size="md"
        person={ delivery.delivery.createdBy }
      />
      <Stack
        direction="column"
        gap={ 0 }
        css={ { whiteSpace: 'normal' } }
      >
        <LineClamp lines={ 1 }>
          <Typography weight="medium">
            { PersonParser.fullName(delivery.delivery.createdBy) }
          </Typography>
        </LineClamp>
        <LineClamp lines={ 1 }>
          <Typography weight="regular">
            { delivery.broadcast.name }
          </Typography>
        </LineClamp>
      </Stack>
      <StackEnd>
        <Stack gap={ 2 }>
          { (delivery.nudge || delivery.broadcast.mandatory) && (
            <StackEnd>
              <Stack>
                { delivery.nudge && <NudgeIndicator nudge={ delivery.nudge }/> }
                { delivery.broadcast.mandatory && <MandatoryIndicator/> }
              </Stack>
            </StackEnd>
          ) }
          <DeliveryDate date={ deliveryDate } unseen={ unseen }/>
        </Stack>
      </StackEnd>
    </Stack>
  );
};
