import styled from '@emotion/styled';

import { Flex } from '../../../Common/Component';

export const StyledTimezoneMessage = styled.div`
  display: block;
  padding-top: 0.5rem;
  font-size: 1rem;
`;

export const StyledResetTextWithCharacterCount = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const StyledFormGrid = styled.div`
  display: grid;
  align-items: flex-start;
  grid-template-columns: 10ch auto;
  column-gap: 2rem;
  row-gap: 2rem;
  margin: 1rem 0;

  ${ Flex } .MuiFormControl-root:not(:first-of-type) {
    margin-left: 1rem;
  }
`;
