import styled from '@emotion/styled';
import { RadioGroup } from '@mui/material';

export const StyledRadioGroup = styled(RadioGroup)`
  .MuiFormControlLabel-root {
    margin: 0;
  }

  .Mui-disabled {
    color: inherit;
  }
`;

export const StyledRadioIcon = styled.div`
  .MuiBox-root {
    display: flex;
    padding: 2px;
  }
`;
