import { FC, ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { parseISO } from 'date-fns';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';

import { BroadcastContentFooter, StackNavigationProps } from '../../Footer/BroadcastContentFooter';
import { UploadState } from '../../../../../Common/Context';
import { ContentPadding, NudgeBanner, RespondButton, RespondButtonProps } from '../..';
import { ValidationErrorMessage, VerticallySpaced } from '../../../../../Common/Component';
import { Upload } from '..';
import { StyledUploads } from './style';
import { CardDescription, CardTitle, ScrollableContent } from '../../../../../Broadcasts/Component/Content';
import { BroadcastNudge } from '../../../../../Broadcasts/Model';
import { AbsoluteBackButtonContainer } from '../../AbsoluteBackButtonContainer/AbsoluteBackButtonContainer';

type Props = {
  title: string;
  description: string;
  navigationProps: StackNavigationProps;
  uploadStates: Map<string, UploadState>;
  onUploadClicked: () => void;
  responseSaved?: boolean;
  uploadHidden?: boolean;
  submitButtonProps: RespondButtonProps;
  submitHidden?: boolean;
  submitDisabled?: boolean;
  onRemoveClicked: (id: string) => void;
  error?: string;
  nudge?: BroadcastNudge;
  backButton: ReactNode | undefined;
};

export const UploadBroadcastContent: FC<Props> = ({
  title,
  description,
  uploadHidden = false,
  uploadStates,
  onUploadClicked,
  responseSaved = false,
  submitButtonProps,
  submitHidden = false,
  navigationProps,
  submitDisabled,
  onRemoveClicked,
  error,
  nudge,
  backButton,
}) => {
  const intl = useIntl();

  return (
    <>
      { backButton && <AbsoluteBackButtonContainer>{ backButton }</AbsoluteBackButtonContainer> }
      <ScrollableContent>
        {
          nudge && (
            <NudgeBanner
              message={ nudge.message }
              nudgedAt={ parseISO(nudge.at) }
            />
          )
        }
        <ContentPadding>
          <VerticallySpaced gap={ 2 }>
            <CardTitle>{ title }</CardTitle>
            <CardDescription>{ description }</CardDescription>
            <StyledUploads>
              { Array.from(uploadStates.entries()).map(([id, uploadState]) => (
                <Upload
                  key={ id }
                  uploadState={ uploadState }
                  onRemoveClicked={ () => onRemoveClicked(id) }
                  saved={ responseSaved }
                />
              )) }
            </StyledUploads>
            <ValidationErrorMessage>
              { error }
            </ValidationErrorMessage>
          </VerticallySpaced>
        </ContentPadding>
      </ScrollableContent>
      <BroadcastContentFooter
        { ...navigationProps }
      >
        { !uploadHidden && (
          <Button variant={ submitHidden ? 'primary' : 'secondary' } onClick={ onUploadClicked }>
            <FormattedMessage
              id="content.upload.upload"
              description="Label for upload card upload button."
              defaultMessage="Upload"
            />
          </Button>
        ) }
        { !submitHidden && (
          <RespondButton
            { ...submitButtonProps }
            variant="primary"
            notRespondedLabel={ intl.formatMessage({
              id: 'content.upload.notResponded',
              description: 'Upload card submit button label when not responded.',
              defaultMessage: 'Submit',
            }) }
            respondedLabel={ intl.formatMessage({
              id: 'content.upload.responded',
              description: 'Upload card submit button label when responded.',
              defaultMessage: 'Submitted',
            }) }
            rejectedLabel={ intl.formatMessage({
              id: 'content.upload.rejected',
              description: 'Upload card submit button label when submit failed.',
              defaultMessage: 'Try again',
            }) }
            disabled={ submitDisabled }
          />
        ) }
      </BroadcastContentFooter>
    </>
  );
};
