import { FormattedMessage, useIntl } from 'react-intl';
import { FunctionComponent, ReactNode, useMemo } from 'react';

import { DateTime, TruncatingContent } from '../../../Common/Component';
import { FileDownloadButton, TableCell } from '../../../Components';
import { Response, } from '../../Model';
import { NonWrappingCell } from '..';
import { StyledAnonymousResponse } from './style';
import { ContentIdentifier } from '../../Utility';

interface Props {
  response: Response
}

export const ResponseCell: FunctionComponent<Props> = ({
  response,
}) => {
  const intl = useIntl();
  const cellShouldTruncate = useMemo<boolean>(
    () => (
      ContentIdentifier.responseIsTextResponse(response)
      || ContentIdentifier.responseIsChoiceResponse(response)
      || ContentIdentifier.responseIsUploadResponse(response)
    ),
    [response],
  );

  const renderResponse = (response: Response): ReactNode => {
    return <>
      { response.contentType === 'anonymous' && (
        <StyledAnonymousResponse aria-hidden="true">
          { Array.from({ length: Math.floor((Math.random() * 20) + 2) }, () =>
            'abcdefghijklmnopqrstuvwxyz          '[Math.floor(Math.random() * 36)],
          ).join('') }
        </StyledAnonymousResponse>
      ) }
      {
        ContentIdentifier.responseIsDateResponse(response) && (
          <DateTime dateTime={ response.date.dt }/>
        )
      }
      {
        ContentIdentifier.responseIsNumberResponse(response) && (
          response.number
        )
      }
      {
        ContentIdentifier.responseIsTextResponse(response) && (
          response.text
        )
      }
      {
        ContentIdentifier.responseIsGeolocationResponse(response) && (
          `${ response.latitude }, ${ response.longitude }`
        )
      }
      {
        ContentIdentifier.responseIsChoiceResponse(response)
        && intl.formatList(response.selections.map(selection => selection.title))
      }
      {
        ContentIdentifier.responseIsRatingResponse(response) && (
          response.rating
        )
      }
      { ContentIdentifier.responseIsSignResponse(response) && response.signature }
      {
        ContentIdentifier.responseIsUploadResponse(response) && (
          response.uploads.map((uploadId, index) => (
            <FileDownloadButton
              key={ uploadId }
              uploadId={ uploadId }
              buttonProps={ {
                color: 'primary',
              } }
            >
              <FormattedMessage
                id="forms.submissions.image"
                description="link text for image responses in submission table"
                defaultMessage="Image {index}"
                values={ {
                  index: index + 1,
                } }
              />
            </FileDownloadButton>
          ))
        )
      }
    </>
  };

  return cellShouldTruncate
    ? (
      <TableCell maxwidth="20rem">
        <TruncatingContent>
          { renderResponse(response) }
        </TruncatingContent>
      </TableCell>
    )
    : (
      <NonWrappingCell>
        { renderResponse(response) }
      </NonWrappingCell>
    )
};
