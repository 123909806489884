import { useEffect, useState, useRef } from 'react';

export const useCountdown = (fromSeconds: number | null) => {
  const [remainingDurationInSeconds, setRemainingDurationInSeconds] = useState<number | null>(null);
  const remainingDurationRef = useRef<number | null>(null);

  useEffect(() => {
    let countdownStarted: number | undefined;
    let animationFrame: number;

    const updateRemainingDuration = (timeInMs: number) => {
      if (countdownStarted === undefined) {
        countdownStarted = timeInMs;
      }
      const elapsedDurationInMs = timeInMs - countdownStarted;
      const elapsedDurationInSeconds = Math.floor(elapsedDurationInMs / 1000);
      const newRemainingDurationInSeconds = fromSeconds - elapsedDurationInSeconds;

      if (newRemainingDurationInSeconds <= 0) {
        cancelAnimationFrame(animationFrame);
        setRemainingDurationInSeconds(null);
        return;
      }

      if (remainingDurationRef.current !== newRemainingDurationInSeconds) {
        setRemainingDurationInSeconds(newRemainingDurationInSeconds);
        remainingDurationRef.current = newRemainingDurationInSeconds;
      }

      animationFrame = requestAnimationFrame(updateRemainingDuration);
    }

    animationFrame = requestAnimationFrame(updateRemainingDuration);

    return () => {
      animationFrame && cancelAnimationFrame(animationFrame);
    };
  }, [fromSeconds]);

  return remainingDurationInSeconds;
}
