import { FC, ReactNode } from 'react';
import {
  BreakpointContext
} from 'op-storybook/lib/providers/BreakpointProvider/BreakpointProvider';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';

import { StyledTitleBar } from './style';
import { LiveCardContainer } from '../../../Content/Component';
import { useContextOrThrow } from '../../../Core/Hook';

type Props = {
  headerContents?: ReactNode;
  children?: ReactNode;
};

export const HeaderWithCard: FC<Props> = ({
  headerContents,
  children,
}) => {
  const screenWidth = useContextOrThrow(BreakpointContext);

  return (
    <Stack
      gap={ 0 }
      direction="column"
      css={ {
        height: screenWidth.lessThan.sm ? 'calc(100% - 72px)' : '100%',
        paddingTop: 'var(--header-height)',
      } }
    >
      { !screenWidth.lessThan.sm && (
        <StyledTitleBar
          data-testid="inbox-preview-header"
        >
          { headerContents }
        </StyledTitleBar>
      ) }
      <LiveCardContainer>
        { children }
      </LiveCardContainer>
    </Stack>
  );
}
