import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { IconButton } from '@mui/material';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';

import {
  FileUploadError,
  Flex,
  FlexPullRight,
  LocalisedFileUploadErrors,
  VerticallySpaced
} from '../../../Common/Component';
import DeleteIcon from '../../../Assets/img/icons/monochrome/trash.svg';
import { StyledProgress } from './style';
import { OpenFileButton } from '..';
import { useFileSelect } from '../../../Common/Hook/useFileSelect';
import { useFileUploader } from '../../../Common/Hook';
import { UploadState } from '../../../Common/Context';
import { Upload } from '../../../Types';

type Props = {
  id: string;
  fileUpload?: Upload;
  mimeType: string;
  onFileUploaded: (upload: Upload) => void;
  onFileRemoved: () => void;
  onUploadStarted?: () => void;
  optional?: boolean;
};

export const FileUploadInput: FC<Props> = ({
  id,
  fileUpload,
  mimeType,
  onFileUploaded,
  onFileRemoved,
  onUploadStarted,
  optional = false,
}) => {
  const selectFile = useFileSelect(mimeType);
  const { upload, uploadStates, remove } = useFileUploader();
  const fileUploadState = useMemo<UploadState | undefined>(() => (
    fileUpload
      ? {
        ref: fileUpload.id.toString(),
        file: {
          name: fileUpload.name,
          size: fileUpload.size,
        },
        progress: 1,
        upload: fileUpload,
      }
      : Array.from(uploadStates).find(([currentId]) => currentId === id)?.[1]
  ), [fileUpload, id, uploadStates]);
  const [uploadErrors, setUploadErrors] = useState<FileUploadError[]>([]);

  useEffect(() => {
    if (!fileUploadState?.error) {
      return;
    }

    setUploadErrors([fileUploadState.error]);
  }, [fileUploadState?.error, onFileUploaded]);

  const whenFileSelected = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files?.[0];
    if (!file) {
      return;
    }

    remove(id);
    setUploadErrors([]);
    onFileRemoved && onFileRemoved();
    onUploadStarted && onUploadStarted();
    upload(id, 'broadcasts/authorise-upload', file);
  }, [id, onFileRemoved, onUploadStarted, remove, upload]);

  const whenUploadClicked = useCallback(() => selectFile(whenFileSelected), [selectFile, whenFileSelected]);

  useEffect(() => {
    if (!fileUploadState?.upload || fileUploadState.upload.id === fileUpload?.id) {
      return;
    }

    onFileUploaded(fileUploadState.upload);
  }, [fileUpload?.id, fileUploadState?.upload, onFileUploaded]);

  const whenFileRemoved = useCallback((): void => {
    remove(id);
    onFileRemoved()
  }, [id, onFileRemoved, remove]);

  return (
    <VerticallySpaced gap={1}>
      <Flex>
        <Button variant="primary" onClick={whenUploadClicked}>
          {
            fileUploadState?.upload
              ? (
                <FormattedMessage
                  id="files.upload.replace.label"
                  description="Label for file upload replace button"
                  defaultMessage="Replace file"
                />
              )
              : (
                <FormattedMessage
                  id="files.upload.upload.label"
                  description="Label for file upload upload button"
                  defaultMessage="Upload file"
                />
              )
          }
        </Button>
        {fileUpload && (
          <FlexPullRight gap={2}>
            <OpenFileButton fileId={fileUpload.id.toString()}>
              <FormattedMessage
                id="files.upload.viewFile.label"
                description="Label for view file button."
                defaultMessage="Open file"
              />
            </OpenFileButton>
            {optional && (
              <IconButton onClick={whenFileRemoved} size="large">
                <DeleteIcon />
              </IconButton>
            )}
          </FlexPullRight>
        )}
      </Flex>
      {
        fileUploadState?.file.name && (
          <Flex gap={1}>
            <span>{fileUploadState.file.name}</span>
            <StyledProgress>
              {
                !fileUploadState.upload
                  ? (
                    fileUploadState.progress === 1
                      ? (
                        <FormattedMessage
                          id="files.upload.processing"
                          description="Label for an upload once in the processing state"
                          defaultMessage="Processing..."
                        />
                      )
                      : (
                        <FormattedNumber
                          value={fileUploadState.progress}
                          // eslint-disable-next-line react/style-prop-object
                          style="percent"
                        />
                      )
                  )
                  : (
                    <FormattedMessage
                      id="files.upload.uploaded"
                      description="Label for an upload once it's complete"
                      defaultMessage="Uploaded"
                    />
                  )
              }
            </StyledProgress>
          </Flex>
        )
      }
      {!!uploadErrors.length && <LocalisedFileUploadErrors errors={uploadErrors} />}
    </VerticallySpaced>
  );
};
