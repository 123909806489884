import { FunctionComponent, ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme } from '@emotion/react';

import { TopActiveAdminsMetric } from '../../Model';
import { DatasetOptionsWithKey, MetricTransformer } from '../../Utility';
import { BarChartMetric, MetricContainer, MissingDataMessage } from '..';
import { MinimalPerson } from '../../../Models';
import { PersonParser } from '../../../Utility';
import { DeprecatedLink } from '../../../Common/Component';
import { BroadcastsPermission } from '../../../Broadcasts/Model';
import { GuardedLink } from '../../../Security/Component';
import {useEnvironmentSettings} from '../../../Common/Hook';

interface Props {
  metric: TopActiveAdminsMetric;
}

export const MostActiveTeamAdminsMetric: FunctionComponent<Props> = ({
  metric,
}) => {
  const intl = useIntl();
  const theme = useTheme();
  const {
    eventsEnabled,
    broadcastsV1EventsEnabled,
    broadcastsV2Enabled,
  } = useEnvironmentSettings();
  const people = metric.datasets.reduce<MinimalPerson[]>((people, dataset) => {
    const currentPerson = dataset.metadata.person;
    const index = people.findIndex(person => person.id === currentPerson.id);

    if (index !== -1) {
      return people;
    }

    return people.concat(currentPerson);
  }, []);

  const datasetOptions: DatasetOptionsWithKey[] = [
    {
      key: 'contentBroadcastsSent',
      label: intl.formatMessage({
        id: 'dashboard.mostActiveTeamAdmins.broadcastsSent',
        description: 'Label for broadcasts sent dataset in most active team admins graph',
        defaultMessage: 'Broadcasts sent',
      }),
      backgroundColor: theme.palette.secondary.main,
    },
    ...(
      eventsEnabled
      && (
        !broadcastsV2Enabled
        || !!broadcastsV1EventsEnabled
      )
    )
      ? [{
        key: 'eventsSent',
        label: intl.formatMessage({
          id: 'dashboard.mostActiveTeamAdmins.eventsSent',
          description: 'Label for events sent dataset in most active team admins graph',
          defaultMessage: 'Events sent',
        }),
        backgroundColor: theme.palette.secondary.dark,
      }]
      : [],
    {
      key: 'chatMessagesSent',
      label: intl.formatMessage({
        id: 'dashboard.mostActiveTeamAdmins.chatMessagesSent',
        description: 'Label for chat messages sent dataset in most active team admins graph',
        defaultMessage: 'Chat messages sent',
      }),
      backgroundColor: theme.palette.secondary.light,
    },
  ];

  const chartData = MetricTransformer.consolidateDatasets<'bar', MinimalPerson>(
    metric,
    datasetOptions,
    people,
    (person) => PersonParser.fullName(person),
  );

  return (
    <MetricContainer
      title={intl.formatMessage({
        id: 'dashboard.mostActiveTeamAdmins.heading',
        defaultMessage: 'Most active team admins'
      })}
    >
      {
        metric.datasets.length
          ? (
            <BarChartMetric
              chartData={ chartData }
            />
          )
          : (
            <MissingDataMessage>
              <FormattedMessage
                id="dashboard.mostActiveTeamAdmins.noData"
                defaultMessage="<p>No team admins were active in this period.</p><p>Try <BroadcastsLink>sending more broadcasts</BroadcastsLink> or <InviteLink>inviting more people</InviteLink>.</p>"
                values={{
                  BroadcastsLink: (chunks: ReactNode) => (
                    <GuardedLink
                      permissions={[BroadcastsPermission.CREATE]}
                      to="/broadcasts/create"
                    >
                      { chunks }
                    </GuardedLink>
                  ),
                  InviteLink: (chunks: ReactNode) => (
                    <DeprecatedLink to="/people/create">
                      { chunks }
                    </DeprecatedLink>
                  ),
                }}
              />
            </MissingDataMessage>
          )
      }
    </MetricContainer>
  );
};
