import { FC, ReactNode } from 'react';

type Props = {
  className?: string;
  children?: ReactNode;
};

export const ViewportHeading: FC<Props> = ({ className, children }) => (
  <h1
    className={ className }
    css={ {
      fontSize: '18px',
      fontWeight: '500',
      margin: 0,
      textAlign: 'center',
      lineHeight: '20px',
    } }
  >
    { children }
  </h1>
);
