import styled from '@emotion/styled';

export const StyledTableContainer = styled.div`
  width: 100%;
`;

export const StyledColumnMappingType = styled.div`
  font-size: 0.9rem;
  font-weight: 400;
`;
