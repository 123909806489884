import { FC, HTMLAttributes, ReactNode } from 'react';

type Props = {
  palette?: 'inherit' | 'default';
  children?: ReactNode;
} & HTMLAttributes<HTMLSpanElement>;

export const PrimaryInformation: FC<Props> = ({
  palette = 'default',
  children,
  ...spanProps
}) => (
  <div
    css={ theme => ({
      color: palette === 'inherit' ? 'inherit' : theme.new.palette.grey[900].main,
      fontSize: '17px',
      fontWeight: '400',
      lineHeight: '20px',
    }) }
    { ...spanProps }
  >
    { children }
  </div>
);
