import { useCallback, useMemo, useState } from 'react';
import axios, { AxiosError } from 'axios';
import { ApiRequest } from '@ourpeople/shared/Core/Model/ApiRequest';

import { RequestState } from '../../Models/RequestState';
import { useMounted } from '../../Common/Hook';

type Response = {
  requestToken: (details: TokenRequestDetails) => void;
  tokenRequest: ApiRequest<void, ErrorResponse>;
};

type TokenError = 'GENERIC' | 'PHONE_NUMBER_NOT_FOUND' | 'EMAIL_ADDRESS_NOT_FOUND' | 'RATE_LIMIT';

type ErrorResponse = {
  error: TokenError;
  retryAfter?: number;
};

type MobileNumberTokenRequestDetails = {
  mobileNumber: string;
  emailAddress?: never;
};

type EmailTokenRequestDetails = {
  mobileNumber?: never;
  emailAddress: string;
};

export type TokenRequestDetails = EmailTokenRequestDetails | MobileNumberTokenRequestDetails;

export const useTokenRequest = (): Response => {
  const [tokenRequest, setTokenRequest] = useState<ApiRequest<void, ErrorResponse>>({
    state: RequestState.PENDING,
    result: null,
  });
  const mounted = useMounted();

  const requestToken = useCallback(
    (details: TokenRequestDetails) => {
      setTokenRequest({
        state: RequestState.FETCHING,
        result: null,
      });

      const requestMethod = details.mobileNumber
        ? 'MOBILE_NUMBER'
        : 'EMAIL_ADDRESS'

      const tokenRequestDetailsWithMethod = {
        method: requestMethod,
        ...details,
      };

      axios.post(
        '/api/auth/send-security-token',
        tokenRequestDetailsWithMethod,
      )
        .then(() => {
          if (!mounted.current) {
            return;
          }

          setTokenRequest({
            state: RequestState.COMPLETE,
            result: undefined,
          });
        })
        .catch((error: AxiosError<unknown>) => {
          if (!mounted.current) {
            return;
          }

          let errorResponse: ErrorResponse;
          switch (error.response?.status || 0) {
            case 429:
              const headers = error.response?.headers as Record<string, string>;
              const retryAfterHeader = headers && headers['retry-after'];
              errorResponse = {
                error: 'RATE_LIMIT',
                retryAfter: !isNaN(+retryAfterHeader) ? +retryAfterHeader : 0,
              };
              break;
            case 404:
              if (requestMethod === 'MOBILE_NUMBER') {
                errorResponse = {
                  error: 'PHONE_NUMBER_NOT_FOUND',
                };
              } else {
                errorResponse = {
                  error: 'EMAIL_ADDRESS_NOT_FOUND',
                };
              }
              break;
            default:
              errorResponse = {
                error: 'GENERIC',
              };
          }

          setTokenRequest({
            result: errorResponse,
            state: RequestState.FAILED,
          });
        });
    },
    [mounted],
  );

  return useMemo(() => ({
    requestToken,
    tokenRequest,
  }), [requestToken, tokenRequest]);
};
