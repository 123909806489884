import { FC } from 'react';

import { PresentationIcon } from '../../../lib/components/PresentationIcon/PresentationIcon';
import CheckIcon from '../../../lib/assets/icon/figma/check.svg?react';

type Props = {
  disabled?: boolean;
};

export const DropdownItemCheck: FC<Props> = ({
  disabled,
}) => (
  <PresentationIcon
    IconComponent={ CheckIcon }
    size={ 5 }
    palette={ {
      colour: disabled ? 'grey' : 'primary',
      intensity: 600,
    } }
  />
);
