import { FC, useMemo } from 'react';
import { rgba } from 'polished';

import { ImageLoader } from './ImageLoader';
import { ImagePlaceholder } from './ImagePlaceholder';
import { ImageCaptionContainer } from './ImageCaptionContainer';
import { BroadcastAttachments } from '../../../Model/BroadcastAttachments';
import { ImageBlock } from '../../../Service/Blocks/ImageBlockDefinition';

type Props = {
  block: ImageBlock;
  attachments: BroadcastAttachments;
  roundCorners: boolean;
};

export const ImageBlockImage: FC<Props> = ({
  block,
  attachments,
  roundCorners,
}) => {
  const upload = useMemo(() => (
      attachments.uploads.find(upload => upload.uuid === block.attributes.uploadId)
    ),
    [attachments.uploads, block.attributes.uploadId],
  );

  return (
    <div
      css={ theme => ({
        ...roundCorners ? { borderRadius: theme.new.borderRadius.standard } : {},
        overflow: 'hidden',
        position: 'relative',
        width: '100%',
      }) }
    >
      {
        upload
          ? (
            <ImageLoader
              src={ `/api/uploads/${ upload.uuid }/download` }
              imageDimensions={ {
                width: upload.image?.width || undefined,
                height: upload.image?.height || undefined,
              } }
            />
          )
          : (
            <ImagePlaceholder
              loading={ false }
            />
          )
      }
      { block.attributes.caption?.text && (
        <ImageCaptionContainer caption={ block.attributes.caption }>
          <p
            css={ theme => (
              block.attributes.caption
                ? {
                  fontWeight: 500,
                  background: rgba(block.attributes.caption.backgroundColour, 0.7),
                  color: block.attributes.caption.textColour,
                  backdropFilter: 'blur(2px)',
                  width: 'max-content',
                  margin: 0,
                  padding: `${ theme.new.spacing[1] } ${ theme.new.spacing[2] }`,
                  borderRadius: theme.new.borderRadius.small,
                }
                : {}
            ) }
          >
            { block.attributes.caption.text }
          </p>
        </ImageCaptionContainer>
      ) }
    </div>
  )
};
