import { ComponentProps, FC, FormEventHandler, useEffect, useCallback, useState, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AuthHeader } from 'op-storybook/lib/components/AuthHeader/AuthHeader';
import KeyIcon from 'op-storybook/lib/assets/icon/figma/key-01.svg';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { Form } from 'op-storybook/lib/components/Form/Form';
import { Username, UsernameField } from 'op-storybook/stories/components/UsernameField/UsernameField';
import { Button } from 'op-storybook/stories/components/Button/Button';
import { Typography } from 'op-storybook/stories/components/Typography/Typography';
import { Link, useLocation } from 'react-router-dom';
import { CountryCode } from 'libphonenumber-js';

import { RequestState } from '../../../Models';
import { useTokenRequest } from '../../Hook/useSecurityTokenRequest';
import { CodeRateLimitMessage } from '../CodeRateLimitMessage/CodeRateLimitMessage';

type Props = {
  header: string;
  supportingText: string;
  defaultCountryCode: CountryCode;
  tokenRequest: ReturnType<typeof useTokenRequest>['tokenRequest'];
  username: Username;
  localisedUsernameErrors: string[];
  onChange: (username: Username) => void;
  onSubmit: FormEventHandler;
  remainingDurationInSeconds: number | null;
  returnLinkProps: ComponentProps<typeof Link>;
};

type LocationState = {
  codeExpired?: boolean;
};

export const CodeRequestForm: FC<Props> = ({
  header,
  supportingText,
  defaultCountryCode,
  tokenRequest,
  username,
  localisedUsernameErrors,
  onChange,
  onSubmit,
  remainingDurationInSeconds,
  returnLinkProps,
}) => {
  const intl = useIntl();
  const location = useLocation<LocationState | undefined>();
  const [requestUpdated, setRequestUpdated] = useState<boolean>(false);
  const errorMessages = useMemo(
    () => [
      ...localisedUsernameErrors,
      ...(tokenRequest.result?.error === 'EMAIL_ADDRESS_NOT_FOUND' ? [
            intl.formatMessage({
                description: 'Error message when failing to send OTP code to unrecognised email address.',
                defaultMessage: 'Email address not recognised.',
            }),
        ] : []),
      ...(tokenRequest.result?.error === 'PHONE_NUMBER_NOT_FOUND' ? [
            intl.formatMessage({
                description: 'Error message when failing to send OTP code to unrecognised phone number.',
                defaultMessage: 'Phone number not recognised.',
            }),
        ] : []),
    ],
    [localisedUsernameErrors, tokenRequest.result?.error, intl],
  );
  const [submittedUsernameValue, setSubmittedUsernameValue] = useState<string>();

  useEffect(() => {
    if (!location.state?.codeExpired) {
      return;
    }

    setRequestUpdated(false);
  }, [location.state]);

  useEffect(() => {
    setRequestUpdated(true);
  }, [tokenRequest]);

  const whenSubmit = useCallback<FormEventHandler>(
    (event) => {
      setSubmittedUsernameValue(username.value);
      onSubmit(event);
    },
    [username, onSubmit],
  );

  return (
    <>
      <AuthHeader
        header={ header }
        supportingText={ supportingText }
        graphic={ {
          type: 'icon',
          props: {
            IconComponent: KeyIcon,
            size: 'lg',
          },
        } }
      />
      <Stack
        direction="column"
        gap={ 4 }
      >
        <Form onSubmit={ whenSubmit }>
          <Stack
            direction="column"
            gap={ 4 }
          >
            <UsernameField
              username={ username }
              onChange={ onChange }
              defaultCountryCode={ defaultCountryCode }
              errorMessages={ errorMessages }
            />
            <Button
              variant="primary"
              type="submit"
              fillParent
              disabled={
                username.value.length < 1
                  || remainingDurationInSeconds !== null
                  || (
                    (
                      errorMessages.length > 0
                      || tokenRequest.state === RequestState.FAILED
                    )
                    && username.value === submittedUsernameValue
                  )
              }
              busy={ tokenRequest.state === RequestState.FETCHING }
            >
              <FormattedMessage
                description="Label for submit button on OTP request form"
                defaultMessage="Send code"
              />
            </Button>
            { tokenRequest.state === RequestState.FAILED && tokenRequest.result.error === 'GENERIC' && (
              <Typography
                palette={ {
                  colour: 'error',
                  intensity: 500,
                } }
                size="sm"
                weight="regular"
                align="center"
              >
                <FormattedMessage
                  description="Generic error message when failing to send OTP code."
                  defaultMessage="Could not send code, please try again."
                />
              </Typography>
            ) }
          </Stack>
        </Form>
        { !requestUpdated && location.state?.codeExpired && (
          <Typography
            palette={ {
              colour: 'error',
              intensity: 500,
            } }
            size="sm"
            weight="regular"
            align="center"
          >
            <FormattedMessage
              description="Generic error message when failing to update password after code has expired."
              defaultMessage="Code expired, please try again."
            />
          </Typography>
        ) }
        { remainingDurationInSeconds !== null && <CodeRateLimitMessage remainingDurationInSeconds={ remainingDurationInSeconds }/> }
      </Stack>
      <Stack
        direction="column"
        align="center"
      >
        <Link { ...returnLinkProps } />
      </Stack>
    </>
  );
};
