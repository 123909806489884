import { IntlShape } from 'react-intl';

import {
  AudienceConditionDefinition,
  ConditionContentsSummary,
  TagCondition,
  TagConditionContents
} from '../../Model';
import { TagType } from '../../../Tags/Model';
import { TagParser } from '../../../Tags/Utility';

/** @deprecated Used in old audience builder */
export class TagConditionDefinition implements AudienceConditionDefinition<
  TagCondition,
  TagConditionContents<TagType>
> {
  public readonly TYPE = 'tag';

  public definesConditionType(conditionType: string): boolean {
    return conditionType === this.TYPE;
  }

  public contentsSummary(
    intl: IntlShape,
    condition: TagCondition,
    conditionContents: TagConditionContents<TagType>,
  ): ConditionContentsSummary {
    const nameSummary = intl.formatMessage(
      {
        id: 'audience.conditionDefinition.tag.contentsSummary.name',
        defaultMessage: '{ not, select, true {not } other {} }{name}',
      },
      {
        not: condition.not,
        name: conditionContents.tags.length > 0
          ? conditionContents.tags[0].name
          : '',
      }
    );

    const countSummary = intl.formatMessage(
      {
        id: 'audience.conditionDefinition.tag.contentsSummary.count',
        defaultMessage: '{ not, select, true {not } other {} }{count} {type}',
      },
      {
        not: condition.not,
        count: conditionContents.tags.length,
        type: TagParser.localisedTagTypeFromInternalTagType(
          condition.tagType,
          intl,
          conditionContents.tags.length,
        ),
      }
    );

    const preferredSummary = conditionContents.tags.length > 0
      ? (
        conditionContents.tags.length === 1
          ? nameSummary
          : countSummary
      )
      : null;

    return {
      exclusive: false,
      soloSummary: preferredSummary,
      andSummary: preferredSummary,
    };
  }
}
