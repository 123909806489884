import { FunctionComponent, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { SortableHeaderCell, TableCell, TableRow } from '../../../../../Components';
import { DateTime, FilterContainer, FilterRow } from '../../../../../Common/Component';
import { StyledResponseTable } from '../styles';
import { ContentWithEvent } from '../../../../../Models';
import { StandardReportTableInput } from './StandardReportTableInput';
import { ReportRecipient, ResponseTable } from '../../../../../Broadcasts/Component';

export interface TrainingAttendedTableProps {
  broadcastId: string;
  deliveryId?: string;
  content: ContentWithEvent;
  initialSearch?: string;
}

export const TrainingAttendedTable: FunctionComponent<TrainingAttendedTableProps> = ({
  broadcastId,
  deliveryId,
  content,
  initialSearch = '',
}) => {
  const [sort, setSort] = useState<string>('firstNameAsc');
  const [search, setSearch] = useState<string>(initialSearch);
  const params = useMemo(() => ({
    deliveryId: deliveryId || null,
    contentId: content.id || null,
    optionId: null,
    rating: null,
    responseType: 'responded.attended',
    sort,
    relativeStatus: 'active,inactive,frozen',
    search: search || null,
  }), [content.id, search, deliveryId, sort]);

  return (
    <StyledResponseTable>
      <FilterContainer>
        <FilterRow>
          <StandardReportTableInput
            searchValue={search}
            onSearchChanged={setSearch}
          />
        </FilterRow>
      </FilterContainer>
      <ResponseTable
        reportRecipientsParams={params}
        broadcastId={ broadcastId }
        headerRow={
          <TableRow>
            <SortableHeaderCell
              sort={sort}
              ascValue="firstNameAsc"
              descValue="firstNameDesc"
              onSort={setSort}
            >
              <FormattedMessage
                id="broadcast.report.recipient.firstName"
                defaultMessage="First name"
                />
            </SortableHeaderCell>
            <SortableHeaderCell
              sort={sort}
              ascValue="lastNameAsc"
              descValue="lastNameDesc"
              onSort={setSort}
            >
              <FormattedMessage
                id="broadcast.report.recipient.lastName"
                defaultMessage="Last name"
                />
            </SortableHeaderCell>
            <SortableHeaderCell
              sort={sort}
              ascValue="dateAsc"
              descValue="dateDesc"
              onSort={setSort}
            >
              <FormattedMessage
                id="broadcast.report.recipient.date"
                defaultMessage="Date"
                />
            </SortableHeaderCell>
            <TableCell>
              <FormattedMessage
                id="broadcast.report.recipient.result"
                defaultMessage="Result"
                />
            </TableCell>
            <TableCell>
              <FormattedMessage
                id="broadcast.report.recipient.feedback"
                defaultMessage="Feedback"
                />
            </TableCell>
          </TableRow>
        }
        rowRender={ (recipient: ReportRecipient<Record<string, unknown>>) => (
          <TableRow key={ recipient.id }>
            <TableCell>
              <Link to={`/people/${ recipient.user.id }/account`}>
                { recipient.user.firstName }
              </Link>
            </TableCell>
            <TableCell>
              <Link to={`/people/${ recipient.user.id }/account`}>
                { recipient.user.lastName }
              </Link>
            </TableCell>
            <TableCell>
              <DateTime dateTime={ recipient.date }/>
            </TableCell>
            <TableCell>
              {
                (recipient.metadata.result as string || '') === 'passed'
                ? (
                  <FormattedMessage
                    id="broadcast.report.recipient.passed"
                    defaultMessage="Passed"
                    />
                )
                : (
                  <FormattedMessage
                    id="broadcast.report.recipient.failed"
                    defaultMessage="Failed"
                    />
                )
              }
            </TableCell>
            <TableCell>
              { recipient.metadata.feedback as string || '' }
            </TableCell>
          </TableRow>
        ) }
      />
    </StyledResponseTable>
  );
}
