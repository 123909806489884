import { MessagePayload as FirebaseMessagePayload } from 'firebase/messaging';

export enum MessageReceivedState {
  BACKGROUND,
  BLURRED,
  FOREGROUND,
}

export type MessageLogEntry = {
  payload: MessagePayload;
  received: {
    state: MessageReceivedState;
    at: string;
  };
};

export interface PushMessageDefinition<T extends MessagePayload = MessagePayload> {
  defines: (message: MessagePayload) => message is T;
  onReceive?: (message: T, dismiss: () => void) => void;
  onClick?: (message: T, dismiss: () => void) => void;
  discard?: (message: T) => boolean;
}

export interface OpPushMessageData {
  opType: string;
  notification_foreground: boolean;
  opSpaceId: string;
}

export type MessagePayload<T extends OpPushMessageData = OpPushMessageData> = Omit<FirebaseMessagePayload, 'data'> & {
  data: T;
};
