export enum TagType {
  Region = 'region',
  Team = 'team',
  Department = 'department',
  JobTitle = 'jobtitle',
  Skill = 'skill',
}

export type Tag<T extends TagType> = T extends TagType.Team ? TeamTag : FullTag<T>;

interface TeamTag extends FullTag<TagType.Team> {
  administratorIds: string[];
}

export interface MinimalTag<T extends TagType = TagType> {
  id: string;
  name: string;
  type: T;
}

interface FullTag<T extends TagType> extends MinimalTag<T> {
  externallyManaged: boolean;
  parentTagIds: string[];
  childTagIds: string[];
  createdAt: string;
}
