import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';

import { MetricContainer, MissingBroadcastMetricMessage, NumericMetric } from '..';
import { Dataset } from '../../Model';
import { HelpTooltip } from '../../../Common/Component';

interface Props {
  previousDataset?: Dataset;
  currentDataset?: Dataset;
  span?: number;
}

export const BroadcastsSentMetric: FunctionComponent<Props> = ({ previousDataset, currentDataset, span = 2 }) => {
  const intl = useIntl();
  const previousValue = previousDataset?.data[0]?.value || 0;
  const currentValue = currentDataset?.data[0]?.value || 0;

  return (
    <MetricContainer
      title={ intl.formatMessage({
        id: 'dashboard.broadcastsSent.heading',
        description: 'Heading for broadcasts sent stat block on dashboard.',
        defaultMessage: 'Broadcasts sent',
      }) }
      actions={
        <HelpTooltip title={ intl.formatMessage({
          id: 'dashboard.broadcastsSent.tooltip',
          description: 'Tooltip for broadcasts sent stat block on dashboard.',
          defaultMessage: 'The number of broadcasts sent over the selected period.',
        }) }/>
      }
      span={ span }
    >
      {
        previousValue || currentValue
          ? (
            <NumericMetric
              viewMoreCta={ intl.formatMessage({
                id: 'dashboard.broadcastsSent.viewMoreCta',
                description: 'Link to recent broadcasts table.',
                defaultMessage: 'View recent broadcasts',
              }) }
              viewMoreLink="/broadcasts?tab=recent"
              previousValue={ previousValue }
              currentValue={ currentValue }
            />
          )
          : <MissingBroadcastMetricMessage/>
      }
    </MetricContainer>
  );
};
