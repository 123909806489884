import { ComponentProps, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Dialog, DialogContent, DialogTitle, FormControlLabel } from '@mui/material';

import {
  CenteredGenericEmptyMessage,
  CenteredGenericErrorMessage,
  CenteredGenericLoadingMessage
} from '@ourpeople/shared/Core/Component/Content';
import { Button } from 'op-storybook/stories/components/Button/Button';

import { TemplateGallery, TemplatePreview } from '..';
import { FetchResponse } from '../../../../Hooks';
import { TemplateWithContent } from '../../../Model';
import { Paginated } from '../../../../Models';
import {
  CloseButton,
  Flex,
  FlexPullRight,
  PresentationIcon,
  VerticallySpaced
} from '../..';
import { StyledBackArrow, StyledTemplateGallery } from './style';
import { Checkbox } from '../../../../Components';

type Props<T extends TemplateWithContent<string, Record<string, unknown>>> = {
  open: boolean;
  onClose: () => void;
  useFetchTemplates: (disabled?: boolean) => FetchResponse<Paginated<'templates', T>>;
  getUseButtonProps: (templateWithContent: T) => ComponentProps<typeof Button>;
  renderPreviewPane: (templateWithContent: T) => ReactNode;
  showWhenCreatingLabel: string;
  showWhenCreating: boolean;
  onShowWhenCreatingChange: (showWhenCreating: boolean) => void;
  createButtonProps: ComponentProps<typeof Button>;
  feedbackUrl: string;
  onPreviewViewed: (templateWithContent: T) => void;
  onPositiveFeedbackReceived: (templateWithContent: T) => void;
  onNegativeFeedbackReceived: (templateWithContent: T) => void;
};

export const TemplateDialog = <T extends TemplateWithContent<string, Record<string, unknown>>>({
  open,
  onClose,
  useFetchTemplates,
  getUseButtonProps,
  renderPreviewPane,
  showWhenCreatingLabel,
  showWhenCreating,
  onShowWhenCreatingChange,
  createButtonProps,
  feedbackUrl,
  onPreviewViewed,
  onPositiveFeedbackReceived,
  onNegativeFeedbackReceived,
}: Props<T>): JSX.Element => {
  const [fetchTemplatesResult, , reloadTemplates] = useFetchTemplates(!open);
  const templatesWithContent = useMemo(
    () => fetchTemplatesResult?.content?.templates || [],
    [fetchTemplatesResult?.content?.templates],
  );
  const templates = useMemo(
    () => templatesWithContent.map(templateWithContent => templateWithContent.template) || [],
    [templatesWithContent],
  );
  const [previewingTemplateId, setPreviewingTemplateId] = useState<string>();
  const previewingTemplateWithContent = useMemo(() => {
    if (!previewingTemplateId) {
      return;
    }

    return templatesWithContent.find(templateWithContent => templateWithContent.template.id === previewingTemplateId);
  }, [previewingTemplateId, templatesWithContent]);

  useEffect(() => {
    if (!previewingTemplateWithContent) {
      return;
    }

    onPreviewViewed(previewingTemplateWithContent);
  }, [onPreviewViewed, previewingTemplateWithContent]);

  const getUseButtonPropsFromTemplateId = useCallback((templateId: string) => {
    const templateWithContent = templatesWithContent.find(templateWithContent => templateWithContent.template.id === templateId);

    if (!templateWithContent) {
      return {};
    }

    return getUseButtonProps(templateWithContent);
  }, [getUseButtonProps, templatesWithContent]);

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={ open }
      onClose={ onClose }
      TransitionProps={ {
        onExited: () => {
          setPreviewingTemplateId(undefined);
        },
      } }
    >
      <DialogTitle>
        <Flex gap={ 2 }>
          { previewingTemplateId && (
            <Button
              color="primary"
              onClick={ () => setPreviewingTemplateId(undefined) }
            >
              <Flex gap={ 1 }>
                <PresentationIcon IconComponent={ StyledBackArrow }/>
                <FormattedMessage
                  id="templateSelectDialog.backButton"
                  description="Back button label in template modal when previewing a template."
                  defaultMessage="Back to templates"
                />
              </Flex>
            </Button>
          ) }
          <FlexPullRight>
            <CloseButton
              onClick={ onClose }
            />
          </FlexPullRight>
        </Flex>
      </DialogTitle>
      <DialogContent>
        <VerticallySpaced gap={ 2 }>
          {
            previewingTemplateWithContent
              ? (
                <TemplatePreview
                  template={ previewingTemplateWithContent.template }
                  useButtonProps={ getUseButtonProps(previewingTemplateWithContent) }
                  previewPane={ renderPreviewPane(previewingTemplateWithContent) }
                />
              )
              : (
                <>
                  <Flex>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={ showWhenCreating }
                          onChange={ (_event, checked) => onShowWhenCreatingChange(checked) }
                        />
                      }
                      label={ showWhenCreatingLabel }
                    />
                    <FlexPullRight>
                      <Button
                        variant="primary"
                        { ...createButtonProps }
                      />
                    </FlexPullRight>
                  </Flex>
                  <StyledTemplateGallery>
                    {
                      fetchTemplatesResult
                        ? (
                          templates.length
                            ? (
                              <TemplateGallery
                                templates={ templates }
                                getUseButtonProps={ getUseButtonPropsFromTemplateId }
                                onPreviewClick={ setPreviewingTemplateId }
                              />
                            )
                            : fetchTemplatesResult.error
                              ? (
                                <CenteredGenericErrorMessage onRetryClicked={ reloadTemplates }/>
                              )
                              : (
                                <CenteredGenericEmptyMessage/>
                              )
                        )
                        : <CenteredGenericLoadingMessage/>
                    }
                  </StyledTemplateGallery>
                </>
              )
          }
        </VerticallySpaced>
      </DialogContent>
    </Dialog>
  );
};
