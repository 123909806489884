import { useEffect } from 'react';

import { useUserRoles } from '../../../src/react/Common/Hook/useUserRoles';

export const useBeamer = (): void => {
  const { userIsSuperAdmin } = useUserRoles();

  useEffect(() => {
    if (!userIsSuperAdmin) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    global.beamer_config = {
      product_id : 'YNeVVzHq58300',
      selector : 'beamer',
      button: false,
    };

    const script = document.createElement('script');
    script.src = 'https://app.getbeamer.com/js/beamer-embed.js';
    script.type = 'text/javascript';
    script.defer = true;

    setTimeout(() => {
      document.body.appendChild(script);
    }, 300);

    return () => {
      document.body.contains(script) && document.body.removeChild(script);
    };
  }, [userIsSuperAdmin]);
};
