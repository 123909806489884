import { FC, useMemo } from 'react';

import { useContentDefinitionRegistry } from '../../../Content/Hook/useContentDefinitionRegistry';
import { UniqueIdGenerator } from '../../../Common/Utility/UniqueIdGenerator';
import { Content } from '../../../Models/Content/Content';
import { StyledContentForExport } from './style';

type Props = {
  content: Content;
  onReady: () => void;
};

export const ContentForExport: FC<Props> = ({
  content,
  onReady,
}) => {
  const { getPreviewComponent, transformCard } = useContentDefinitionRegistry();
  const editorContent = useMemo(() => (
    transformCard({
      id: UniqueIdGenerator.generate(),
      content,
      image: null,
      index: 0,
      mandatory: false,
    })
  ), [content, transformCard]);
  const PreviewComponent = getPreviewComponent(editorContent);

  return (
    <StyledContentForExport>
      <PreviewComponent
        editorContent={ editorContent }
        context={ { cardCount: 0 } }
        onReady={ onReady }
      />
      <div
        css={ {
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          backdropFilter: 'blur(5px)',
        } }
      ></div>
    </StyledContentForExport>
  );
};
