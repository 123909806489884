import { Audience, Segment } from '../Model';
import { ConditionValidator } from '.';

export class AudienceCleaner {
  public static cleanAudience = (audience: Audience): Audience => {
    return AudienceCleaner.removeInvalidSegments(audience);
  }

  public static removeInvalidConditions = (audience: Audience): Audience => {
    return AudienceCleaner.removeInvalidSegments(audience);
  };

  private static removeInvalidSegments = (audience: Audience): Audience => {
    const segments = audience.segments.map(AudienceCleaner.removeInvalidConditionsFromSegment);
    return {
      ...audience,
      segments: segments.filter(segment => segment.conditions.length),
    };
  };

  private static removeInvalidConditionsFromSegment = (segment: Segment): Segment => {
    return {
      ...segment,
      conditions: segment.conditions.filter(ConditionValidator.validate),
    };
  };
}
