import { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { ContentPreviewProps } from '../../../../Content/Model';
import { TrainingEditorContent } from '../../../Service';
import { MeetingAndTrainingPreview } from '..';

export const TrainingPreview: FC<ContentPreviewProps<TrainingEditorContent>> = ({
  editorContent,
  onReady,
}) => {
  const intl = useIntl();

  useEffect(() => {
    onReady();
  }, [onReady]);

  return (
    <MeetingAndTrainingPreview
      editorContent={ editorContent }
      localisedContentType={ intl.formatMessage({
        id: 'broadcasts.content.preview.training',
        description: 'Label for training type used in preview.',
        defaultMessage: 'Training',
      }) }
    />
  );
};
