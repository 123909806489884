import { IntlShape } from 'react-intl';

import { SingleContentCard, DraftContent, EditorContent } from '../../../../Content/Model';
import TimelineIcon from '../../../../Assets/img/icons/monochrome/content/stack.svg';
import { UniqueIdGenerator } from '../../../../Common/Utility';
import { CardTransformer } from '../../../../Content/Utility';
import { TimelineValidator } from '../../Validators';
import { TimelineEditor } from '../../../Component';
import { TimelinePreview } from '../../../Component/Content/TimelinePreview/TimelinePreview';
import { LiveTimelineContent } from '../../../../Inbox/Component';
import { BroadcastContent, BroadcastContentDefinition } from '../../../Model';

export type Timeline = BroadcastContent & {
  type: 'timelinecontent';
  text: string;
};

export type DraftTimeline = DraftContent & {
  type: 'timeline';
  text: string;
};

export type TimelineEditorContent = EditorContent<DraftTimeline>;

export class TimelineDefinition implements BroadcastContentDefinition<Timeline, TimelineEditorContent, undefined> {
  public readonly id = 'stack';
  public readonly availableToCreate = true;
  public readonly new = false;
  public readonly richText = false;
  public readonly contentTypes = ['timelinecontent'];
  public readonly categoryId = null;
  public readonly IconComponent = TimelineIcon;
  public readonly EditorComponent = TimelineEditor;
  public readonly PreviewComponent = TimelinePreview;
  public readonly LiveComponent = LiveTimelineContent;
  public readonly SubmittedComponent = (): null => null;
  public readonly cloningDisabled = true;
  public validate = TimelineValidator.validate;

  public definesContentType = (type: string): boolean => (
    this.contentTypes.concat('timeline').includes(type)
  );

  public getContentTitle = (intl: IntlShape, content: Timeline): string => (
    content.text || this.getDefaultTitle(intl)
  );

  public getLocalisedType = (intl: IntlShape): string => (
    intl.formatMessage({
      id: 'broadcasts.content.timeline.type',
      description: 'Localised name for timeline content type.',
      defaultMessage: 'Stack',
    })
  );

  public getDraftContentTitle = (intl: IntlShape, draftContent: DraftTimeline): string => (
    draftContent.text || this.getDefaultTitle(intl)
  );

  public hasRequiredFeatureFlags = (): boolean => true;

  public createEditorContent = (): TimelineEditorContent => ({
    id: UniqueIdGenerator.generate(),
    card: {
      content: {
        type: 'timeline',
        text: '',
      },
      mandatory: false,
      image: null,
    },
  });

  public transformCard = (card: SingleContentCard<Timeline>): TimelineEditorContent => ({
    id: card.id,
    card: CardTransformer.transformCard(card, {
      type: 'timeline',
      text: card.content.text,
    }),
  });

  private getDefaultTitle = (intl: IntlShape): string => (
    intl.formatMessage({
      id: 'broadcasts.content.timeline.defaultTitle',
      description: 'Default title for timeline card.',
      defaultMessage: 'Untitled stack',
    })
  );
}
