import { ValidationTree, Validator } from '../../../Common/Model';
import { RichTextSanitiser, Validate } from '../../../Common/Utility';
import { DraftTextContent, TEXT_CONTENT_HTML_MAX_LENGTH } from '../../Model/ContentTypes';
import { ContentValidator } from './ContentValidator';

export class TextContentValidator {
  public static validateHtml = (content: DraftTextContent): ValidationTree<string> => ({
    errors: htmlValidator.validate(content.html),
    children: {},
  });

  public static validate = (content: DraftTextContent): ValidationTree<DraftTextContent> => ({
    errors: [
      ...ContentValidator.validate(content).errors,
    ],
    children: {
      ...ContentValidator.validate(content).children,
      html: TextContentValidator.validateHtml(content),
    }
  });
}

const htmlValidator = new Validator<string>([
  Validate.utf8,
  html => Validate.notBlank(RichTextSanitiser.stripTags(html)),
  html => Validate.maxLength(html, TEXT_CONTENT_HTML_MAX_LENGTH),
]);
