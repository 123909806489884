import { FetchResult } from '../../Models';
import { FetchResponse } from '../../Hooks';

export class FetchResponseTransformer {
  public static transform = <T, S>(
    [result, state, reload]: FetchResponse<T>,
    transformFunction: (content: T) => S,
  ): FetchResponse<S> => [
    result?.content
      ? FetchResult.fromContent(transformFunction(result.content))
      : result?.error
        ? FetchResult.fromError(result.error)
        : null,
    state,
    reload,
  ];
}
