import styled from '@emotion/styled';
import { Theme } from '@emotion/react';

import { BadgeProps } from './Badge';

type StyledBadgeProps = {
  variant: BadgeProps['variant'];
  colour: keyof Theme['new']['palette'];
  noLabel: boolean;
};

type StyledAdornmentProps = {
  colour: keyof Theme['new']['palette'];
};

export const StyledAdornment = styled.div<StyledAdornmentProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${ props => props.theme.new.palette[props.colour][500].main }
`;

export const StyledBadge = styled.div<StyledBadgeProps>`
  line-height: 1;
  display: flex;
  gap: ${ props => props.theme.new.spacing[1] };
  width: min-content;
  white-space: nowrap;

  ${ props => ['badge-colour', 'pill-colour'].includes(props.variant) && `
    border: 1px solid ${ props.theme.new.palette[props.colour][200].main };
    background-color: ${ props.theme.new.palette[props.colour][50].main };
  ` };

  ${ props => ['badge-colour', 'pill-colour', 'pill-outline'].includes(props.variant) && `
    color: ${ props.theme.new.palette[props.colour][700].main };
  ` };

  ${ props => ['pill-colour', 'pill-outline'].includes(props.variant) && `
    border-radius: ${ props.theme.new.borderRadius.max };
    padding: 2px ${ props.noLabel ? '2px' : '8px' };
  ` };

  ${ props => ['badge-modern', 'badge-colour'].includes(props.variant) && `
    background-color: ${ props.theme.new.basePalette.white.main };
    color: ${ props.theme.new.palette.grey['900'].main };
    border-radius: ${ props.theme.new.borderRadius.standard };
    padding: 2px ${ props.noLabel ? '2px' : '6px' };
  ` };

  ${ props => props.variant === 'pill-outline' && `
    border: 2px solid ${ props.theme.new.palette[props.colour][600].main };
  ` };

  ${ props => props.variant === 'badge-modern' && `
    border: 1px solid ${ props.theme.new.palette.grey[300].main };
    box-shadow: ${ props.theme.new.shadow.xs };
    background-color: ${ props.theme.new.basePalette.white.main };
  ` };
`;
