import styled from '@emotion/styled';

import { Flex } from '../../../Common/Component';

type StyledImportStatusProps = {
  failed?: boolean;
}

export const StyledImportStatus = styled(Flex)<StyledImportStatusProps>`
  padding: 5rem;
  color: ${
  props => props.failed
    ? props.theme.palette.error.main
    : props.theme.palette.success.main
};

  svg {
    width: 128px;
    height: 128px;
  }

  p {
    margin: 0;
    font-size: 2rem;
  }
`;
