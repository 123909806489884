import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { AccessRequestsTable, ApiClientsTable, ClipboardButton } from '..';
import { Alert } from '../../../Components';
import { Box } from '../../../Common/Component';
import { useUserRoles } from '../../../Common/Hook';
import { ApiClientWithSecret } from '../../Model';
import { StyledSectionContainer } from './style';

type Props = {
  addedClients: ApiClientWithSecret[];
  onAddButtonClicked: () => void;
}

export const ListTables: FunctionComponent<Props> = ({ addedClients, onAddButtonClicked }) => {
  const latestAddedClient: ApiClientWithSecret | undefined = addedClients[addedClients.length - 1];
  const { userIsDeveloper } = useUserRoles();

  return (
    <>
      <Box>
        { latestAddedClient && (
          <Alert severity="success">
            <FormattedMessage
              id="apiClients.addClient.successMessage"
              description="Add client dialog header"
              defaultMessage="{ clientName } was added. Secret is { secret }. Please copy this secret, as it will not be visible again!"
              values={{
                clientName: latestAddedClient.name,
                secret: <ClipboardButton value={latestAddedClient.secret} />,
              }}
            />
          </Alert>
        ) }
        <StyledSectionContainer>
          <AccessRequestsTable/>
        </StyledSectionContainer>
        {
          userIsDeveloper && (
            <StyledSectionContainer>
              <ApiClientsTable
                addedClients={ addedClients }
                onAddButtonClicked={ onAddButtonClicked }
              />
            </StyledSectionContainer>
          )
        }
      </Box>
    </>
  );
};
