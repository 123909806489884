export class FileSizeFormatter {
  public static format = (sizeInBytes: number): string => (
    sizeInBytes > 1000000000
      ? `${ Math.floor(sizeInBytes / 1000000000) } GB`
      : FileSizeFormatter.formatSizeUnderOneGB(sizeInBytes)
  )

  private static formatSizeUnderOneGB = (sizeInBytes: number): string => (
    sizeInBytes > 1000000
      ? `${ Math.floor(sizeInBytes / 1000000) } MB`
      : FileSizeFormatter.formatSizeUnderOneMB(sizeInBytes)
  );

  private static formatSizeUnderOneMB = (sizeInBytes: number): string => (
    sizeInBytes > 1000
      ? `${ Math.floor(sizeInBytes / 1000) } KB`
      : `${ Math.floor(sizeInBytes) } B`
  )
}
