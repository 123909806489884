import {ChangeEvent, FunctionComponent, ReactNode, useCallback, useMemo, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {addMinutes, isBefore, isToday, parse, roundToNearestMinutes} from 'date-fns';
import {format, fromZonedTime} from 'date-fns-tz';
import {Button, TextField} from '@mui/material';
import {Editor} from '@ourpeople/shared/Core/Component/Input/RichTextEditor/Editor/Editor';

import {useEnvironmentSettings} from '../../../Common/Hook';
import {DateTimeFormatter} from '../../../Common/Utility';
import {Dialog} from '../../../Components';
import {StyledFormGrid, StyledResetTextWithCharacterCount, StyledTimezoneMessage} from './styles';
import {Flex, FlexPullRight, ValidationErrorMessage} from '../../../Common/Component';

export interface InviteRequestBody {
  sendAt?: string;
  sendSms: boolean;
  sendEmail: boolean;
  emailMessage?: string;
}

interface Props {
  open: boolean;
  onConfirmed: (inviteRequestBody: InviteRequestBody) => void;
  onDismissed: () => void;
  heading: ReactNode;
  retryAt?: Date,
  error?: ReactNode;
}

export const InviteDialog: FunctionComponent<Props> = ({
  open,
  onConfirmed,
  onDismissed,
  heading,
  retryAt,
  error,
}) => {
  const intl = useIntl();
  const {
    invitationEmailMessage = '',
    timezone: defaultTimezone
  } = useEnvironmentSettings();
  const [emailMessage, setEmailMessage] = useState<string>(invitationEmailMessage);
  const timezone = defaultTimezone || 'UTC';
  const initialDateUtc = useMemo(() => roundToNearestMinutes(addMinutes(new Date(), 5), { nearestTo: 5 }), []);
  const [sendAtUtc, setSendAtUtc] = useState<Date>(initialDateUtc);
  const minTime = useMemo(
    () => (
      isToday(sendAtUtc)
        ? DateTimeFormatter.formatInTimezone(new Date(), 'HH:mm', timezone)
        : ''
    ),
    [sendAtUtc, timezone],
  );
  const minDate = DateTimeFormatter.formatInTimezone(new Date(), 'yyyy-MM-dd', timezone);
  const dateInputValue = useMemo<string>(() => sendAtUtc ? DateTimeFormatter.formatInTimezone(sendAtUtc, 'yyyy-MM-dd', timezone) : '', [sendAtUtc, timezone]);
  const timeInputValue = useMemo<string>(() => sendAtUtc ? DateTimeFormatter.formatInTimezone(sendAtUtc, 'HH:mm', timezone) : '', [sendAtUtc, timezone]);

  const whenDateChanged = useCallback((event: ChangeEvent<{ value: string, valueAsDate?: Date | null }>) => {
    const inputDate = parse(event.currentTarget.value, 'yyyy-MM-dd', new Date());

    setSendAtUtc(sendAtUtc => {
      const environmentTime = DateTimeFormatter.formatInTimezone(sendAtUtc, 'HH:mm', timezone);
      const environmentDate = format(inputDate, 'yyyy-MM-dd');
      return fromZonedTime(`${ environmentDate } ${ environmentTime }:00`, timezone);
    });
  }, [timezone]);

  const whenTimeChanged = useCallback((event: ChangeEvent<{ value: string, valueAsDate?: Date | null }>) => {
    const inputDate = parse(event.currentTarget.value, 'HH:mm', new Date());

    setSendAtUtc(sendAtUtc => {
      const environmentTime = format(inputDate, 'HH:mm');
      const environmentDate = DateTimeFormatter.formatInTimezone(sendAtUtc, 'yyyy-MM-dd', timezone);
      return fromZonedTime(`${ environmentDate } ${ environmentTime }:00`, timezone);
    });
  }, [timezone]);

  const disableSubmit = useMemo(() => (
    !emailMessage
    || !sendAtUtc
    || isBefore(sendAtUtc, new Date())
  ), [emailMessage, sendAtUtc]);

  const whenEmailMessageReset = (): void => {
    setEmailMessage(invitationEmailMessage);
  };

  const whenConfirmButtonClicked = useCallback(() => {
    const inviteRequestBody: InviteRequestBody = {
      sendSms: true,
      sendEmail: true,
      ...(
        sendAtUtc
          ? {
            sendAt: DateTimeFormatter.formatInTimezone(
              sendAtUtc,
              'yyyy-MM-dd\'T\'HH:mm:ssXXXX',
              timezone,
            ),
          }
          : {}
      ),
      ...(emailMessage === invitationEmailMessage ? {} : { emailMessage }),
    };

    onConfirmed(inviteRequestBody);
  }, [
    emailMessage,
    onConfirmed,
    invitationEmailMessage,
    sendAtUtc,
    timezone,
  ]);

  return (
    <Dialog
      disableEnforceFocus
      heading={ heading }
      headingSize="small"
      headingTextAlign="left"
      cta={
        intl.formatMessage({
          id: 'invitePeopleDialog.cta',
          defaultMessage: 'Schedule invitation',
        })
      }
      ctaDisabled={ disableSubmit }
      cancel={
        intl.formatMessage({
          id: 'invitePeopleDialog.cancel',
          defaultMessage: 'Cancel',
        })
      }
      open={ open }
      onConfirmed={ whenConfirmButtonClicked }
      onDismissed={ onDismissed }
    >
      <div>
        <StyledFormGrid>
          <Flex>
            <Flex>
              <FormattedMessage
                id="invitePeopleDialog.when.label"
                defaultMessage="When"
              />
            </Flex>
          </Flex>
          <div>
            <Flex>
              <TextField
                id="invite-people-on"
                variant="outlined"
                type="date"
                name="scheduledDate"
                InputProps={ {
                  inputProps: {
                    min: minDate
                  }
                } }
                onChange={ whenDateChanged }
                value={ dateInputValue }
              />
              <TextField
                id="invite-people-at"
                variant="outlined"
                type="time"
                inputProps={ {
                  min: minTime,
                  step: 300,
                } }
                name="scheduledTime"
                onChange={ whenTimeChanged }
                value={ timeInputValue }
              />
            </Flex>
            { timezone && (
              <StyledTimezoneMessage>
                <FormattedMessage
                  id="invitePeopleDialog.timezoneMessage"
                  defaultMessage="Timezone: {timezone}"
                  values={ {
                    timezone
                  } }
                />
              </StyledTimezoneMessage>
            ) }
          </div>
          <>
            <div>
              <Flex>
                <FormattedMessage
                  id="invitePeopleDialog.email.label"
                  defaultMessage="Email"
                />
                <div></div>
              </Flex>
            </div>
            <div>
              <Editor
                onChange={ setEmailMessage }
                value={ emailMessage }
              />
              <StyledResetTextWithCharacterCount>
                <Button
                  color="secondary"
                  onClick={ whenEmailMessageReset }
                >
                  <FormattedMessage
                    id="invitePeopleDialog.resetEmailMessage"
                    defaultMessage="Reset"
                  />
                </Button>
              </StyledResetTextWithCharacterCount>
            </div>
          </>
        </StyledFormGrid>
      </div>
      <>
        {
          error && (
            <Flex>
              <FlexPullRight>
                <ValidationErrorMessage>{ error }</ValidationErrorMessage>
              </FlexPullRight>
            </Flex>
          )
        }
        {
          retryAt && retryAt > new Date() && (
            <Flex>
              <FlexPullRight>
                <ValidationErrorMessage>
                  <FormattedMessage
                    id="invitePeopleDialog.retryAfterError"
                    defaultMessage="Too many invites, try again later."
                  />
                </ValidationErrorMessage>
              </FlexPullRight>
            </Flex>
          )
        }
      </>
    </Dialog>
  );
};
