const ENV = process.env.NODE_ENV;

export class Logger {
  public static log(...args: unknown[]): void {
    if (console.debug && ENV === 'development') {
      console.debug(...args);
    }
  }

  public static success(message: string, ...rest: unknown[]): void {
    Logger.log(`%c${message}`, 'color: white; background-color: green;', ...rest);
  }

  public static error(message: string, ...rest: unknown[]): void {
    Logger.log(`%c${message}`, 'color: white; background-color: red;', ...rest);
  }

  public static warn (message: string, ...rest: unknown[]): void {
    Logger.log(`%c${message}`, 'color: white; background-color: orange;', ...rest);
  }
}
