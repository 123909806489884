import { DraftTopic } from '../Model';
import { ValidationTree, Validator } from '../../Common/Model';
import { Validate } from '../../Common/Utility';

export class TopicValidator {
  public static validate = (topic: DraftTopic): ValidationTree<DraftTopic> => ({
    errors: [],
    children: {
      name: TopicValidator.validateName(topic.name),
      ...(topic.description ? { description: TopicValidator.validateDescription(topic.description) } : {}),
    },
  });

  public static validateName = (name: string): ValidationTree<string> => ({
    errors: nameValidator.validate(name),
    children: {},
  });

  public static validateDescription = (description: string): ValidationTree<string> => ({
    errors: descriptionValidator.validate(description),
    children: {},
  });
}

const nameValidator = new Validator<string>([
  Validate.notBlank,
  name => Validate.minLength(name, NAME_MIN_LENGTH),
  name => Validate.maxLength(name, NAME_MAX_LENGTH),
]);

const descriptionValidator = new Validator<string>([
  description => Validate.maxLength(description, DESCRIPTION_MAX_LENGTH),
]);

export const NAME_MIN_LENGTH = 1;
export const NAME_MAX_LENGTH = 191;
export const DESCRIPTION_MAX_LENGTH = 191;
