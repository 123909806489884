import { ComponentProps, FC, useCallback, useEffect, useMemo, useState } from 'react';

import { PersonCondition } from '../../../../Audiences/Model';
import { useContextOrThrow } from '../../../../Core/Hook';
import { ApiContext } from '../../../../Contexts';
import { AsyncBadgeCloud } from '../AsyncBadgeCloud';
import { MinimalPerson, Paginated } from '../../../../Models';
import { PersonParser } from '../../../../Utility';
import { PersonAvatar } from '../../../../Common/Component/PersonAvatar/PersonAvatar';
import { InvalidConditionError } from '../InvalidConditionError';
import { BadgeCloud } from '../BadgeCloud';

type Props = {
  condition: PersonCondition;
  onChange?: (condition: PersonCondition) => void;
};

export const PersonCloud: FC<Props> = ({
  condition,
  onChange,
}) => {
  const api = useContextOrThrow(ApiContext);
  const filters = useMemo(() => ({ ids: condition.personIds.join(',') }), [condition.personIds]);
  const [missingOnlyTag, setMissingOnlyTag] = useState<boolean>();

  const queryFn = useCallback<ComponentProps<typeof AsyncBadgeCloud>['queryFn']>(async ({ queryKey: [_key, filters] }) => {
    const response = await api.get<Paginated<'people', MinimalPerson>>('/people', { params: filters });

    return {
      pagination: response.data.pagination,
      items: response.data.people.map(person => ({
        id: person.id,
        startAdornment: <PersonAvatar size="sm" person={ person }/>,
        label: PersonParser.fullName(person),
      })),
    };
  }, [api]);

  useEffect(() => {
    setMissingOnlyTag(false);
  }, [condition]);

  const whenRemoveClicked = useCallback<NonNullable<ComponentProps<typeof BadgeCloud>['onRemoveClicked']>>(badgeProps => {
    onChange && onChange({
      ...condition,
      roleIds: condition.personIds.filter(personId => personId !== badgeProps.id),
    });
  }, [condition, onChange]);

  return (
    missingOnlyTag
      ? <InvalidConditionError/>
      : (
        <AsyncBadgeCloud
          queryFn={ queryFn }
          queryKey={ ['people', filters] }
          onIdsMissing={ () => {
            if (condition.personIds.length > 1) {
              return;
            }

            setMissingOnlyTag(true);
          } }
          { ...onChange ? { onRemoveClicked: whenRemoveClicked } : {} }
        />
      )
  );
};
