import { FetchResponse, useGet } from '../../Hooks';
import { FetchDeliveriesParams } from './useFetchDeliveries';
import { Delivery, DeliveryReport, Paginated } from '../../Models';
import { Broadcast, MinimalBroadcastCategory } from '../Model';
import { ResourceLock } from '../../Common/Model';
import { ResolvedAudienceSnippet } from '../../Audiences/Model';

export type SharedDelivery = {
  delivery: Delivery;
  category: MinimalBroadcastCategory;
  broadcast: Broadcast;
  resourceLock: ResourceLock | null;
  resolvedShareAudienceSnippet: ResolvedAudienceSnippet | null;
  report: DeliveryReport;
};

export const useFetchSharedDeliveries = (params: FetchDeliveriesParams): FetchResponse<Paginated<'deliveries', SharedDelivery>> => {
  return useGet('/broadcasts/deliveries/shared', params);
};
