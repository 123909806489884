import { FC, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { ConfirmationDialog } from '@ourpeople/shared/Core/Component/Feedback';

import { LiveComponentProps } from '../../../../Content/Model';
import { AcceptResponse, DeclineResponse } from '../../../Model';
import { BroadcastContentContext } from '../../../../Broadcasts/Model';
import { CoverBroadcastContent } from '../../../../Content/Component';
import { Cover } from '../../../../Broadcasts/Service';
import { useSubmitResponse } from '../../../Hook/useSubmitResponse';
import { RequestState } from '../../../../Models';
import { useRecallErrorHandler } from '../../../Hook';

export const LiveCoverContent: FC<LiveComponentProps<Cover, AcceptResponse | DeclineResponse, BroadcastContentContext>> = ({
  card,
  response,
  onChange,
  context,
  onResponseChange,
  onReloadRequired,
}) => {
  const intl = useIntl();
  const [confirmingResponse, setConfirmingResponse] = useState<boolean>(false);
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timezoneIsRelevant = card.content.start_at.tz !== userTimezone;
  const [responded, setResponded] = useState<boolean>(false);

  if (!card.content.personalStatus) {
    throw new Error(`Status missing for cover with ID ${ card.content.id }.`);
  }

  const whenResponded = useCallback((response: AcceptResponse | DeclineResponse) => {
    onResponseChange(response);
    setResponded(true);
  }, [onResponseChange]);

  useEffect(() => {
    if (!response || !responded) {
      return;
    }

    const status = response?.type === 'accept'
      ? 'requested'
      : 'declined';

    if (status === card.content.personalStatus?.status) {
      return;
    }

    onChange({
      ...card,
      content: {
        ...card.content,
        personalStatus: {
          status,
          metadata: {},
        },
      },
    });
  }, [card, onChange, responded, response, response?.type]);

  const [respond, responseState] = useSubmitResponse(
    card.content.id.toString(),
    whenResponded,
    useRecallErrorHandler(onReloadRequired),
  );
  const userCanRespond = [
    'available',
    'requested',
    'declined'
  ].includes(card.content.personalStatus.status);

  const whenDeclineClicked = useCallback(() => {
    respond({
      type: 'decline',
      contentId: card.content.id.toString(),
      createdAt: new Date().toISOString(),
      deliveryId: context.deliveryId,
    });
  }, [card.content.id, context.deliveryId, respond]);

  const requestCover = useCallback(() => (
    respond({
      type: 'accept',
      contentId: card.content.id.toString(),
      createdAt: new Date().toISOString(),
      deliveryId: context.deliveryId
    })
  ), [card.content.id, context.deliveryId, respond]);

  const whenRequestConfirmed = useCallback(() => {
    requestCover();
    setConfirmingResponse(false);
  }, [requestCover]);

  const whenRequestClicked = useCallback(() => (
    timezoneIsRelevant
      ? setConfirmingResponse(true)
      : requestCover()
  ), [requestCover, timezoneIsRelevant]);

  return (
    <>
      <CoverBroadcastContent
        backButton={ context.closeButton }
        startAt={ card.content.start_at }
        endAt={ card.content.end_at }
        location={ card.content.location }
        comment={ card.content.comment }
        title={ card.content.text }
        navigationProps={ context.navigationProps }
        personalStatus={ card.content.personalStatus }
        declineButtonProps={ {
          onClick: whenDeclineClicked,
          busy: responseState === RequestState.FETCHING,
        } }
        declineHidden={ !userCanRespond || (response && response.type === 'decline') }
        requestButtonProps={ {
          onClick: whenRequestClicked,
          busy: responseState === RequestState.FETCHING,
        } }
        requestHidden={ !userCanRespond || (response && response.type === 'accept') }
        showTimezone={ timezoneIsRelevant }
        nudge={ context.nudge }
      />
      <ConfirmationDialog
        title={ intl.formatMessage({
          id: 'content.cover.confirmResponseHeading',
          description: 'Title for modal asking user to confirm response when cover is in a different timezone.',
          defaultMessage: 'Are you sure?',
        }) }
        description={ intl.formatMessage({
          id: 'content.cover.confirmResponseBody',
          description: 'Body for modal asking user to confirm response when cover is in a different timezone.',
          defaultMessage: 'This cover is outside of your current timezone, would you still like to request?',
        }) }
        open={ confirmingResponse }
        confirmCta={ intl.formatMessage({
          id: 'content.cover.confirmResponseYes',
          description: 'Button label to confirm requesting cover outside of user\'s current timezone.',
          defaultMessage: 'Yes',
        }) }
        onConfirm={ whenRequestConfirmed }
        confirmVariant="primary"
        onCancel={ () => setConfirmingResponse(false) }
        onClose={ () => setConfirmingResponse(false) }
      />
    </>
  );
};
