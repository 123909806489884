import styled from '@emotion/styled';
import OurPeopleLogo from '@ourpeople/shared/Asset/logo.svg';

export const StyledMain = styled.main`
  @page {
    size: A4 portrait;
    margin: ${ props => props.theme.spacing(2) };

    @bottom-left {
      content: counter(page);
    }
  }

  thead td, header {
    height: 60px;
    box-sizing: content-box;
  }

  tfoot td, footer {
    height: 40px;
    box-sizing: content-box;
  }

  table {
    width: 50%;
    display: block;
  }

  thead td {
    border-bottom: solid ${ props => props.theme.spacing(2) } transparent;
  }

  tfoot td {
    border-top: solid ${ props => props.theme.spacing(4) } transparent;
  }

  tbody td {
    display: flex;
    flex-direction: column;
    gap: ${ props => props.theme.spacing(6) };
  }
`;

export const StyledSubmission = styled.section`
  break-after: page;
  display: flex;
  justify-content: center;
`;

export const StyledOurPeopleLogo = styled(OurPeopleLogo)`
  width: 40px;
  height: 40px;
`;

export const StyledSpaceLogo = styled.img`
  width: 60px;
  height: 60px;
  border-radius: ${ props => props.theme.borderRadius.sm };
`;

export const StyledHeader = styled.header`
  display: grid;
  width: 100%;
  align-items: center;
  grid-template-columns: 60px 1fr 60px;
  position: fixed;
  top: 0;
`;

export const StyledTitle = styled.div`
  text-align: center;

  h1, h2 {
    margin: 0;
  }

  h1 {
    font-size: ${ props => props.theme.fontSize.xl };
  }

  h2 {
    font-size: ${ props => props.theme.fontSize.md };
    font-weight: normal;
  }
`;

export const StyledSubmissionContent = styled.div`
  width: 50vw;
  break-inside: avoid;
`;

export const StyledFooter = styled.footer`
  display: grid;
  grid-template-columns: 40px 1fr 40px;
  width: 100%;
  align-items: flex-end;
  position: fixed;
  bottom: 0;
  gap: ${ props => props.theme.spacing(1) };

  span {
    line-height: 1;
  }
`;
