import styled from '@emotion/styled';

import { Flex, FlexProps } from '..';

export const FilterContainer = styled((props: FlexProps) => (
  <Flex
    direction="column"
    gap={ 2 }
    align="stretch"
    {...props}
  />
))`
  padding: 1rem;
  border: 1px solid ${props => props.theme.palette.grey[300]};
  border-bottom: none;
  border-radius: 0.25rem 0.25rem 0 0;
`;
