import styled from '@emotion/styled';

export const StyledTableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  border-top: 1px solid rgba(224, 224, 224, 1);
  border-bottom: 1px solid rgba(224, 224, 224, 1);

  th {
    background: ${props => props.theme.palette.grey[200]};
    position: sticky;
    min-width: 10rem;
    z-index: 100;
    left: 0px;
    vertical-align: top;
  }

  td {
    .MuiInputBase-root {
      width: 100%;
    }
    border-right: 1px solid rgba(224, 224, 224, 1);
  }

  tr:first-of-type {
    th, td {
      border-bottom: none;
    }
  }

  tr:nth-child(n+3) {
    td {
      border-bottom: none;
    }
  }
`;

export const StyledArrowContainer = styled.div`
  color: ${props => props.theme.palette.secondary.main};
`;

export const StyledColumnHeader = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .5rem 0;
  min-width: 10rem;
  flex-direction: column;
`;
