import { useCallback, useMemo, useState } from 'react';
import { DialogProps } from '@mui/material';

import { PopOverState, SvgComponent } from '../Model';
import { usePermissions } from '../../Security/Hook';
import { BroadcastsPermission } from '../../Broadcasts/Model';
import { RequestState } from '../../Models';
import { ContextMenuAction } from '../Component/ContextMenu/ContextMenu';

export const useActionWithConfirmation = (
  actionId: string,
  IconComponent: SvgComponent,
  label: string,
  renderConfirmation: (dialogProps: DialogProps) => JSX.Element,
  actionState: RequestState,
  performingOtherAction: boolean,
  performAction: () => void,
): [JSX.Element, ContextMenuAction] => {
  const [archiveDialogState, setArchiveDialogState] = useState<PopOverState>(PopOverState.CLOSED);
  const { guardedCallback } = usePermissions();

  const whenActionConfirmed = useCallback(() => {
    setArchiveDialogState(PopOverState.WILL_CLOSE);
    performAction();
  }, [performAction]);

  const confirmationDialog = useMemo(() => (
    <>
      { archiveDialogState !== PopOverState.CLOSED && (
        renderConfirmation({
          open: archiveDialogState === PopOverState.OPEN,
          TransitionProps: {
            onExited: () => setArchiveDialogState(PopOverState.CLOSED),
          },
          onClose: () => setArchiveDialogState(PopOverState.WILL_CLOSE),
          onSubmit: whenActionConfirmed,
        })
      ) }
    </>
  ), [archiveDialogState, renderConfirmation, whenActionConfirmed]);

  return useMemo(() => ([
    confirmationDialog,
    {
      id: actionId,
      IconComponent,
      label,
      busy: actionState === RequestState.FETCHING,
      disabled: performingOtherAction,
      onClick: guardedCallback(() => setArchiveDialogState(PopOverState.OPEN), [BroadcastsPermission.UPDATE]),
    },
  ]), [IconComponent, actionId, actionState, confirmationDialog, guardedCallback, label, performingOtherAction]);
};
