import { FC } from 'react';
import { Typography } from 'op-storybook/stories/components/Typography/Typography';
import { useDateTimeFormatter } from '@ourpeople/shared/Core/Hook/useDateFormatter';

type Props = {
  date: Date;
  unseen: boolean;
};

export const DeliveryDate: FC<Props> = ({
  date,
  unseen,
}) => {
  const { minimalDateTime } = useDateTimeFormatter();
  const localisedDate = minimalDateTime(date.toISOString());

  return (
    <Typography
      palette={ { colour: 'teal', intensity: 900 } }
      weight={ unseen ? 'semibold' : 'medium' }
      css={ { whiteSpace: 'nowrap' } }
    >
      { localisedDate }
    </Typography>
  );
};
