import { PreviewComponent } from '../../../Model/BroadcastBlockDefinition';
import { ImageBlock } from '../../../Service/Blocks/ImageBlockDefinition';
import { ImageBlockImage } from './ImageBlockImage';

export const ImageBlockPreview: PreviewComponent<ImageBlock> = ({
  attachments,
  block,
}) => (
  <ImageBlockImage
    block={ block }
    attachments={ attachments }
    roundCorners={ false }
  />
);
