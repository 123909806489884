import { FC, PropsWithChildren } from 'react';

import { StyledCard } from './style';
import { CardContent } from '../CardContent/CardContent';
import { SizeKey } from '../../types/Theme/Theme';

type Props = {
  elevation?: SizeKey;
  inset?: boolean;
  className?: string;
};

export const Card: FC<PropsWithChildren<Props>> = ({
  elevation = 'md',
  inset = false,
  className,
  children,
}) => (
  <StyledCard
    elevation={ elevation }
    className={ className }
  >
    {
      inset
        ? (
          <CardContent>
            { children }
          </CardContent>
        )
        : children
    }
  </StyledCard>
);
