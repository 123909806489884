import { FunctionComponent, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { DateRangePicker } from '../../../Common/Component';
import { DateRangePickerOption, DateRangePickerValue } from '../../../Common/Model/DateRangePicker';

interface Props {
  value: DateRangePickerValue;
  onChange: (value: DateRangePickerValue) => void;
}

export const CoverExportDateRangePicker: FunctionComponent<Props> = ({
  value,
  onChange,
}) => {
  const intl = useIntl();
  const options = useMemo<DateRangePickerOption[]>(() => [
    {
      type: 'offset',
      from: 'end',
      offset: 90,
      label: intl.formatMessage({
        id: 'coverExportDateRangePicker.past',
        description: 'Label for previous 90 days of data in cover export',
        defaultMessage: 'Previous 90 days'
      }),
    },
    {
      type: 'offset',
      from: 'start',
      offset: 90,
      label: intl.formatMessage({
        id: 'coverExportDateRangePicker.future',
        description: 'Label for next 90 days of data in cover export',
        defaultMessage: 'Next 90 days'
      }),
    }
  ], [intl]);

  return (
    <DateRangePicker
      value={ value }
      onChange={ onChange }
      options={options}
    />
  )
}
