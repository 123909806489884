import { Button, CircularProgress } from '@mui/material';
import styled from '@emotion/styled';

export const SaveButton = styled(Button)`
  position: relative;
  min-width: 5rem;

  .MuiButton-label > svg {
    margin-right: 0.5rem;
  }
`;

export const ButtonProgress = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -10px;
  margin-left: -10px;
`;
ButtonProgress.defaultProps = { color: 'primary', size: 20 };
