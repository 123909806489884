import { ChangeEventHandler, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { IntlShape } from 'react-intl/src/types';
import { isAfter, isBefore, parseISO } from 'date-fns';

import { MappedSettingTypes } from '../../Types';
import { NoMarginTextField, ValidationErrorMessage, VerticallySpaced } from '../../../../../Common/Component';
import { DateTimeFormatter } from '../../../../../Utility/DateTimeFormatter';

type Props = {
  config: MappedSettingTypes['datetime']['config'];
  value: MappedSettingTypes['datetime']['value'];
  onValueChanged: (value: MappedSettingTypes['datetime']['value']) => void;
  focusId?: string;
};

export const DateTimeSettingInput: FC<Props> = ({
  config,
  value,
  onValueChanged,
  focusId,
}) => {
  const intl = useIntl();
  const [error, setError] = useState<string | null>(null);
  const inputValue = useMemo<string>(() => (
    value ? DateTimeFormatter.dateTimeInput(parseISO(value)) : ''
  ), [value]);

  useEffect(() => {
    const error = validate(value, config, intl);
    setError(error);
  }, [value, config, intl]);

  const whenValueChanged: ChangeEventHandler<{ value: unknown }> = useCallback(event => {
    const value = event.target.value;

    if (typeof value !== 'string') {
      return;
    }

    onValueChanged(value);
  }, [onValueChanged]);

  return (
    <VerticallySpaced gap={ 1 }>
      <NoMarginTextField
        id={ focusId }
        type="datetime-local"
        { ...config.minDate ? { min: DateTimeFormatter.dateTimeInput(parseISO(config.minDate)) } : {} }
        { ...config.maxDate ? { max: DateTimeFormatter.dateTimeInput(parseISO(config.maxDate)) } : {} }
        value={ inputValue }
        required={ !config.nullable }
        onChange={ whenValueChanged }
        variant="outlined"
      />
      { error && <ValidationErrorMessage>{ error }</ValidationErrorMessage> }
    </VerticallySpaced>
  );
};

const validate = (
  value: MappedSettingTypes['datetime']['value'],
  config: MappedSettingTypes['datetime']['config'],
  intl: IntlShape,
): string | null => {
  if (value === null && config.nullable) {
    return null;
  }

  if (value === null) {
    return intl.formatMessage({
      defaultMessage: 'Missing value',
    });
  }

  let date: Date;

  try {
    date = parseISO(value);
  } catch {
    return intl.formatMessage({
      defaultMessage: 'Invalid date',
    });
  }

  if (config.minDate) {
    const minDate = parseISO(config.minDate);
    if (isBefore(date, minDate)) {
      return intl.formatMessage({
        defaultMessage: 'Date must be before { minDate }',
      }, {
        minDate: minDate.toLocaleDateString(),
      });
    }
  }

  if (config.maxDate) {
    const maxDate = parseISO(config.maxDate);
    if (isAfter(date, maxDate)) {
      return intl.formatMessage({
        defaultMessage: 'Date must be before { maxDate }',
      }, {
        maxDate: maxDate.toLocaleDateString(),
      });
    }
  }

  return null;
}
