import { FC, useState } from 'react';

import { StyledOptionContent, StyledOptionHeading, StyledRevealOption } from './style';

type Props = {
  previewText: string;
  text: string;
};

export const RevealContentPreviewOption: FC<Props> = ({
  previewText,
  text,
}) => {
  const [revealed, setRevealed] = useState<boolean>(false);

  return (
    <StyledRevealOption
      revealed={ revealed }
      onClick={ () => setRevealed(revealed => !revealed) }
    >
      <StyledOptionHeading>{ previewText }</StyledOptionHeading>
      <StyledOptionContent
        revealed={ revealed }
      >
        { text }
      </StyledOptionContent>
    </StyledRevealOption>
  );
}
