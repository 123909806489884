import { FormPermission, FormAndMultiContentCards, SingleContentFormAndCards, SubmissionCounts } from '../Model';
import { FetchResponse, useGet, useTransformedFetchResponse } from '../../Hooks';
import { FormTransformer } from '../Utility';

type Report = {
  report: {
    submissions: {
      counts: SubmissionCounts;
      averageOpenDuration: number;
    };
  };
  permissions: FormPermission[];
};

type MultiContentFormAndCardsAndReport = FormAndMultiContentCards & Report;
type SingleContentFormAndCardsAndReport = SingleContentFormAndCards & Report

export const useFetchFormReport = (formId: string): FetchResponse<SingleContentFormAndCardsAndReport> => (
  useTransformedFetchResponse(
    useGet<MultiContentFormAndCardsAndReport>(`/forms/${ formId }/report`),
    ({ report, permissions, ...formAndCards }) => ({
      report,
      permissions,
      ...FormTransformer.consolidateFormAndCardsContent(formAndCards),
    })
  )
)
