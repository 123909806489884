import { FunctionComponent, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useFetchInvitationRecipients } from '../../Hook';
import { PaginatedTable, TableCell, TableRow } from '../../../Components';
import { PersonParser } from '../../../Utility';
import { InvitationDeliverableRecipientStatusChip } from '..';

type Props = {
  invitationId: string;
}

export const InvitationRecipientsTable: FunctionComponent<Props> = ({ invitationId }) => {
  const [pageNum, setPageNum] = useState<number>(1);
  const [
    fetchInvitationRecipientsResponse,
    fetchInvitationRecipientsRequestState,
    refreshInvitationRecipients,
  ] = useFetchInvitationRecipients(invitationId, pageNum);
  const recipients = fetchInvitationRecipientsResponse?.content?.recipients || [];
  const pagination = fetchInvitationRecipientsResponse?.content?.pagination;

  return (
    <PaginatedTable
      headerRow={
        <TableRow>
          <TableCell>
            <FormattedMessage
              id="invitationReport.delivery.recipient"
              defaultMessage="Recipient"
            />
          </TableCell>
          <TableCell>
            <FormattedMessage
              id="invitationReport.delivery.smsStatus"
              defaultMessage="SMS status"
            />
          </TableCell>
          <TableCell>
            <FormattedMessage
              id="invitationReport.delivery.emailStatus"
              defaultMessage="Email status"
            />
          </TableCell>
        </TableRow>
      }
      rows={ recipients }
      rowRender={ recipient => (
        <TableRow key={ recipient.invitationRecipient.id }>
          <TableCell>{ PersonParser.fullName(recipient.invitationRecipient.person)}</TableCell>
          <TableCell>{ recipient.smsRecipient && <InvitationDeliverableRecipientStatusChip status={recipient.smsRecipient.status}/> }</TableCell>
          <TableCell>{ recipient.emailRecipient && <InvitationDeliverableRecipientStatusChip status={recipient.emailRecipient.status}/> }</TableCell>
        </TableRow>
      ) }
      pageNum={ pageNum }
      onPageChanged={ setPageNum }
      requestState={ fetchInvitationRecipientsRequestState }
      onRetryClicked={ refreshInvitationRecipients }
      pagination={ pagination }
    />
  );
};
