import { Button } from '@mui/material';
import styled from '@emotion/styled';

export const StyledHeading = styled.h4`
  margin: 0;
  padding: ${ props => props.theme.spacing(1) };
  font-weight: 500;
`;

export const StyledButton = styled(Button)`
  width: 100%;
  text-align: left;

  .MuiButton-label {
    width: 100%;
    display: flex;
    gap: ${ props => props.theme.spacing(1) };
    justify-content: flex-start;
  }
`;
StyledButton.defaultProps = { color: 'inherit' };

export const StyledChildren = styled.div`
  padding: ${ props => props.theme.spacing(1) } ${ props => props.theme.spacing(1) } 0;
  width: 100%;
`;
