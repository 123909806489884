import { FileNameHelper } from './FileNameHelper';

export class ImageUrlTransformer {
  public static toFile = (url: string, fileName?: string, crossOrigin: 'anonymous' | 'use-credentials' = 'use-credentials'): Promise<File> => {
    const image = document.createElement('img');
    image.crossOrigin = crossOrigin;
    image.src = url;

    return new Promise((resolve, reject) => {
      image.onload = (event) => {
        const canvas = document.createElement('canvas');
        const canvasContext = canvas.getContext('2d');

        if (!canvasContext) {
          return reject(new Error('Could not obtain canvas context.'));
        }

        const loadedImage = event.target as HTMLImageElement;
        canvas.width = loadedImage.width;
        canvas.height = loadedImage.height;
        canvasContext.drawImage(
          loadedImage,
          0,
          0,
        );

        try {
          canvas.toBlob(
            blob => {
              if (!blob) {
                return;
              }

              const validFileName = FileNameHelper.createValidFileNameForUpload('.jpeg', fileName);
              const file = new File([blob], validFileName, { type: 'image/jpeg' });
              resolve(file);
            },
            'image/jpeg',
            0.7,
          );
        } catch (error) {
          reject(error);
        }
      };
    });
  };
}
