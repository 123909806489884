import { FunctionComponent, SVGProps } from 'react';

import { StyledPresentationIcon } from './style';

type Props = {
  IconComponent: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string }>;
  size?: 'small' | 'medium' | 'large' | 'xs' | number;
  color?: string;
  className?: string;
}

/** @deprecated Prefer PresentationIcon under op-shared */
export const PresentationIcon: FunctionComponent<Props> = ({
  IconComponent,
  size = 'small',
  color,
  className,
}) => {
  const spacingMultiplier = size === 'small'
    ? 3
    : size === 'xs'
      ? 2
      : size === 'large'
        ? 10
        : size === 'medium'
          ? 4
          : size;

  return (
    <StyledPresentationIcon
      color={ color }
      spacingMultiplier={ spacingMultiplier }
    >
      <IconComponent
        role="presentation"
        className={ className }
      />
    </StyledPresentationIcon>
  );
};
