import { useIntl } from 'react-intl';
import { useCallback } from 'react';
import { AutocompleteCommonProps, AutocompleteFetchRequest, AutocompleteSelectionChanged } from '@ourpeople/shared/Core/Component/Input/Autocomplete/Autocomplete';

import { Paginated } from '../../../../Models';
import { useApi } from '../../../../Core/Hook';
import { BroadcastCategory, MinimalBroadcastCategory } from '../../../Model';
import { FetchBroadcastCategoriesParams } from '../../../Hook';
import { DebouncedAutocomplete } from '../../../../Common/Component';

export interface CategoryAutocompleteProps extends AutocompleteCommonProps {
  selectedIds: string[];
  onSelectionChanged: AutocompleteSelectionChanged<MinimalBroadcastCategory>;
  excludeIds?: string[];
  initialSelection?: MinimalBroadcastCategory[];
}

export const CategoryAutocomplete = ({
  selectedIds,
  onSelectionChanged,
  excludeIds,
  initialSelection = [],
  ...props
}: CategoryAutocompleteProps): JSX.Element => {
  const intl = useIntl();
  const api = useApi();

  const whenFetch: AutocompleteFetchRequest<MinimalBroadcastCategory> = useCallback(async (
    ids: string[],
    search: string,
    pageNum: number,
  ) => {
    const params: FetchBroadcastCategoriesParams = {
      sort: 'name_asc',
      pageNum,
      noUnpublished: 1,
      ...(ids.length ? { ids: ids.join(',') } : {}),
      ...(search ? { searchTerm: search } : {}),
    };
    const response = await api.get<Paginated<'categories', BroadcastCategory>>('/broadcasts/categories', { params });
    return {
      options: response.data.categories.filter(category => !excludeIds || !excludeIds.includes(category.id)),
      pageCount: response.data.pagination.pageCount,
    };
  }, [api, excludeIds]);

  return (
    <DebouncedAutocomplete<MinimalBroadcastCategory>
      { ...props }
      prefetchedOptions={ initialSelection }
      getOptionLabel={ category => category.name }
      fetchOptions={ whenFetch }
      selectedIds={ selectedIds }
      onSelectionChanged={ onSelectionChanged }
      dense
      label={
        props.label !== undefined
          ? props.label
          : intl.formatMessage(
            {
              defaultMessage: 'Select {limit, plural, one {category} other {categories}}'
            },
            {
              limit: props.multiple ? Infinity : 1,
            }
          )
      }
      placeholder={
        props.placeholder ||
        intl.formatMessage(
          {
            defaultMessage: 'Search for {limit, plural, one {category} other {categories}}'
          },
          {
            limit: props.multiple ? Infinity : 1,
          }
        )
      }
    />
  );
};
