import { Children, isValidElement, cloneElement, FunctionComponent, useMemo } from 'react';

import { StyledDeliveryReportTabs } from '../styles';

interface Props {
  onTabClicked?: (index: number) => void;
  activeTabIndex?: number | null;
}

export const DeliveryReportTabs: FunctionComponent<Props> = ({
  children,
  onTabClicked,
  activeTabIndex,
}) => {
  const newTabs = useMemo(() => {
    return Children.map(children, (tab, index) => {
      if (!isValidElement(tab)) {
        return null;
      }

      return cloneElement(tab, {
        active: activeTabIndex === index,
        onClick: () => {
          onTabClicked && onTabClicked(index);
        },
      });
    });
  }, [activeTabIndex, children, onTabClicked]);

  return (
    <StyledDeliveryReportTabs>
      { newTabs }
    </StyledDeliveryReportTabs>
  );
}
