import { FC } from 'react';
import { TextFieldProps } from '@mui/material';

import { StyledNoMarginTextField } from './style';

type Props = Omit<TextFieldProps, 'margin'> & {
  useNewTheme?: boolean;
};

export const NoMarginTextField: FC<Props> = ({ useNewTheme = false, ...props }) => (
  <StyledNoMarginTextField
    margin="dense"
    useNewTheme={ useNewTheme }
    { ...props }
  />
);
