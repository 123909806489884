import { FC, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { CircularProgress, Collapse } from '@mui/material';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { Button } from 'op-storybook/stories/components/Button/Button';

import { PagingMenuHeader } from '../PagingMenuHeader/PagingMenuHeader';
import { StyledBetaButton, StyledButtonContainer, StyledInputLabel, StyledLoadingOverlay } from './style';
import { PagingMenuBackButton } from '../PagingMenuBackButton/PagingMenuBackButton';
import {
  ValidationErrorMessage
} from '../../../../../src/react/Common/Component/ValidationErrorMessage/ValidationErrorMessage';
import { PromptWithState } from '../AiPromptMenu/AiPromptMenu';
import { PagingMenuContent } from '../PagingMenuContent/PagingMenuContent';
import { AiPromptTextareaContainer } from '../AiPromptTextareaContainer/AiPromptTextareaContainer';
import { Editor } from '../../Input/RichTextEditor/Editor/Editor';
import { ResponsiveButtonContainer } from '../../Layout/ResponsiveButtonContainer/ResponsiveButtonContainer';

type Props = {
  promptWithState?: PromptWithState;
  onBackClicked: () => void;
  onRetryClicked: () => void;
  onBetaButtonClicked: () => void;
  onUseClicked: (value: string) => void;
};

export const AiPromptResult: FC<Props> = ({
  promptWithState,
  onBackClicked,
  onRetryClicked,
  onBetaButtonClicked,
  onUseClicked,
}) => {
  const [inputValue, setInputValue] = useState<string>('');

  useEffect(() => {
    if (!promptWithState?.fetchResult?.content) {
      return;
    }

    setInputValue(promptWithState.fetchResult.content)
  }, [promptWithState?.fetchResult?.content]);

  return (
    <div>
      <PagingMenuHeader>
        <Stack gap={ 4 }>
          <PagingMenuBackButton
            onClick={ onBackClicked }
          />
          { promptWithState?.prompt.label }
        </Stack>
      </PagingMenuHeader>
      <PagingMenuContent>
        <StyledInputLabel htmlFor="prompt-result">
          <Stack direction="column">
            <FormattedMessage
              defaultMessage="Result"
            />
            <AiPromptTextareaContainer>
              <Editor
                value={ inputValue }
                onChange={ setInputValue }
                hideToolbar
                fixedHeight
              />
              { !promptWithState?.fetchResult && (
                <StyledLoadingOverlay>
                  <CircularProgress
                    size={ 24 }
                    color="secondary"
                  />
                </StyledLoadingOverlay>
              ) }
              { promptWithState?.fetchResult?.error && (
                <StyledLoadingOverlay>
                  <ValidationErrorMessage>
                    <FormattedMessage
                      description="Error message when OurPeople AI prompt fails for unknown reason."
                      defaultMessage="Something went wrong."
                    />
                  </ValidationErrorMessage>
                </StyledLoadingOverlay>
              ) }
            </AiPromptTextareaContainer>
          </Stack>
        </StyledInputLabel>
        <StyledButtonContainer>
          <Collapse
            in={ !!promptWithState?.fetchResult?.error }
          >
            <ResponsiveButtonContainer>
              <Button
                variant="primary"
                onClick={ onRetryClicked }
              >
                <FormattedMessage
                  description="Label for button to retry failed result of OurPeople AI prompt."
                  defaultMessage="Retry"
                />
              </Button>
            </ResponsiveButtonContainer>
          </Collapse>
          <Collapse
            in={ !!promptWithState?.fetchResult?.content }
          >
            <ResponsiveButtonContainer>
              <Button
                variant="secondary"
                onClick={ onRetryClicked }
              >
                <FormattedMessage
                  description="Label for button to regenerate result of OurPeople AI prompt."
                  defaultMessage="Regenerate"
                />
              </Button>
              <Button
                variant="primary"
                onClick={ () => onUseClicked(inputValue) }
              >
                <FormattedMessage
                  description="Label for button to use result of OurPeople AI prompt."
                  defaultMessage="Use this"
                />
              </Button>
            </ResponsiveButtonContainer>
          </Collapse>
        </StyledButtonContainer>
        <StyledBetaButton
          onClick={ onBetaButtonClicked }
        />
      </PagingMenuContent>
    </div>
  );
};
