import { FunctionComponent } from 'react';

interface ReloadRedirectProps {
  href: string;
  replace?: boolean;
}

export const ReloadRedirect: FunctionComponent<ReloadRedirectProps> = ({ href, replace = true }) => {
  (
    replace
    ? window.location.replace(href)
    : window.location.href = href
  );

  return null;
};
