import { FetchResponse, useGet } from '../../Hooks';
import { Broadcast } from '../Model';
import { Audience } from '../../Audiences/Model';
import { Content, Delivery } from '../../Models';

export interface FetchDraftBroadcastResponse {
  broadcast: Broadcast;
  contents: Content[],
  delivery: Delivery,
  recipientAudience: Audience;
}

export const useFetchDraftBroadcast = (broadcastId: string): FetchResponse<FetchDraftBroadcastResponse> => (
  useGet(`/broadcasts/drafts/${ broadcastId }`)
);
