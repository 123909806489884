import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AxiosError } from 'axios';
import { matchPath, useHistory, useRouteMatch } from 'react-router-dom';
import { Heading } from '@ourpeople/shared/Core/Component/Content';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';
import { getDescendantErrors } from 'op-storybook/lib/utility/getDescendantErrors';

import { ApiContext } from '../../../Contexts';
import {
  DraftFormContent,
  DraftSingleContentForm,
  FormAndMultiContentCards,
  PublishStatus,
  SingleContentFormAndCards,
} from '../../Model';
import { useDescendantErrors, useUserRoles } from '../../../Common/Hook';
import { NotAllowedError, ValidationTree } from '../../../Common/Model';
import { Box, Flex, PresentationIcon, StepperHeader, StepperItem, VerticallySpaced, } from '../../../Common/Component';
import {
  FormContentPreview,
  FormEditorContentMenu,
  NameAndCategoryInput,
  PublishTooltip,
  ReportingSettings,
  ViewAudienceEditor,
} from '..';
import SaveIcon from '../../../Assets/img/icons/monochrome/save.svg';
import LockIcon from '../../../Assets/img/icons/streamline/shield-lock.svg';
import { ButtonProgress, SaveButton } from './style';
import { Audience } from '../../../Audiences/Model';
import { Api } from '../../../Services';
import { ArrayHelper, ErrorResponseReader } from '../../../Common/Utility';
import { FormValidator } from '../../Utility/Validation/FormValidator';
import { useContextOrThrow, useLoggedInAuthDescription } from '../../../Core/Hook';
import {
  FetchAudienceMembersParams,
  FetchAudienceMembersQuery,
  FetchAudienceMembersSort,
  useFetchAudienceMembers,
} from '../../../Audiences/Hook';
import { useContentDefinitionRegistry } from '../../../Content/Hook';
import { DraftSingleContentCard, EditorContent } from '../../../Content/Model';
import { ContentEditor } from '../../../Content/Component';
import { AudienceMemberList } from '../../../Audiences/Component';
import { AudienceCleaner } from '../../../Audiences/Utility';
import { ToastContext } from '../../../Core/Context';
import { FormTransformer } from '../../Utility';
import { MinimalUploadWithStatus, useUploadStatusPolling } from '../../../Content/Hook/useUploadStatusPolling';
import { VideoContentDefinition, VideoEditorContent } from '../../Service/ContentDefinitions/VideoContentDefinition';
import { RequestState } from '../../../Models';
import { TranscodingProgress } from '../../../Broadcasts/Component/TranscodingProgress/TranscodingProgress';
import { DraftVideoContent } from '../../Model/ContentTypes/VideoContent';

interface Props {
  id?: string;
  publishStatus: PublishStatus;
  editorContents: EditorContent<DraftFormContent>[];
  setEditorContents: Dispatch<SetStateAction<EditorContent<DraftFormContent>[]>>;
  form: DraftSingleContentForm;
  setForm: Dispatch<SetStateAction<DraftSingleContentForm>>;
  onSaveComplete: () => void;
  creating?: boolean;
}

export const FormEditor: FunctionComponent<Props> = ({
  id,
  form,
  editorContents,
  setEditorContents,
  setForm,
  publishStatus,
  onSaveComplete,
  creating = false,
}) => {
  const intl = useIntl();
  const { user } = useLoggedInAuthDescription();
  const api = useContext(ApiContext);
  const [lastStep, setLastStep] = useState<number>();
  const contentDefinitionRegistry = useContentDefinitionRegistry();
  const history = useHistory();
  const { url } = useRouteMatch();
  const [formValidation, setFormValidation] = useState<ValidationTree<DraftSingleContentForm>>({
    children: {},
    errors: [],
  });
  const [saving, setSaving] = useState<boolean>(false);
  const [runFormValidation, setRunFormValidation] = useState<boolean>(false);
  const { addSuccessToast, addErrorToast } = useContextOrThrow(ToastContext);
  const [uneditedViewAudience, setUneditedViewAudience] = useState<Audience>();
  const { userIsSuperAdmin } = useUserRoles();
  const alwaysEditAudience = creating || userIsSuperAdmin;
  const [editing, setEditing] = useState<boolean>(false);
  const formName = form.name || intl.formatMessage({
    defaultMessage: 'Form',
  });
  const saveAndPublishString = <FormattedMessage
    description="Label for button to save and publish a form"
    defaultMessage="Save &amp; publish"
  />;
  const canPublishForm = form.name && form.categoryId;
  const formHasErrors = useDescendantErrors(formValidation).length > 0;
  const [activeContentIndex, setActiveContentIndex] = useState(0);
  const [audienceMemberSearch, setAudienceMemberSearch] = useState<string>('');
  const [audienceMemberQuery, setAudienceMemberQuery] = useState<FetchAudienceMembersQuery>({
    pageNum: 1,
    search: null,
    sort: ['first_name_asc'],
  });
  const fetchAudienceMembersParams = useMemo<FetchAudienceMembersParams>(
    () => ({
      audience: AudienceCleaner.removeInvalidConditions(form.viewAudience),
      context: 'live',
      query: audienceMemberQuery,
      applyReach: editing || alwaysEditAudience,
    }),
    [audienceMemberQuery, editing, alwaysEditAudience, form.viewAudience],
  );
  const [
    fetchAudienceMembersResult,
    fetchAudienceMembersRequestState,
    refreshFetchAudienceMembers,
  ] = useFetchAudienceMembers(fetchAudienceMembersParams);
  const recipientCount = useMemo(
    () => fetchAudienceMembersResult?.content?.audienceSize,
    [fetchAudienceMembersResult?.content?.audienceSize],
  );
  const insufficientRecipientCount = form.anonymousSubmissionsEnabled && (recipientCount || 0) < MIN_ANONYMOUS_RECIPIENTS;
  const [showAnonymousRecipientCountNotice, setShowAnonymousRecipientCountNotice] = useState<boolean>(false);

  useEffect(() => {
    if (recipientCount === undefined) {
      return;
    }

    setShowAnonymousRecipientCountNotice(insufficientRecipientCount);
  }, [insufficientRecipientCount, recipientCount]);

  useEffect(() => {
    const searchHasChanged = (audienceMemberSearch || null) !== audienceMemberQuery.search;
    if (searchHasChanged) {
      setAudienceMemberQuery(query => ({
        ...query,
        search: audienceMemberSearch || null,
        pageNum: 1,
      }));
    }
  }, [audienceMemberQuery.search, audienceMemberSearch]);

  const whenAudienceMemberSortChanged = useCallback((sort: FetchAudienceMembersSort): void => {
    setAudienceMemberQuery(query => ({
      ...query,
      sort: [sort],
    }));
  }, []);

  const whenAudienceMemberPageNumChanged = useCallback((pageNum: number): void => {
    setAudienceMemberQuery(query => ({
      ...query,
      pageNum,
    }));
  }, []);

  const saveForm = useCallback((
    api: Api,
  ): Promise<SingleContentFormAndCards | void> => {
    setFormValidation({
      children: {},
      errors: [],
    });
    const adminAudiencePersonIds = form.adminAudiencePersonIds || [];
    const reportingAudiencePersonIds = form.reportingAudiencePersonIds || [];
    const notificationAudiencePersonIds = form.notificationAudiencePersonIds || [];
    const formWithUserInAudiences: DraftSingleContentForm = {
      ...form,
      adminAudiencePersonIds: adminAudiencePersonIds.includes(user.id.toString())
        ? adminAudiencePersonIds
        : adminAudiencePersonIds.concat(user.id.toString()),
      reportingAudiencePersonIds: reportingAudiencePersonIds.length
        ? reportingAudiencePersonIds
        : null,
      notificationAudiencePersonIds: notificationAudiencePersonIds.length
        ? notificationAudiencePersonIds
        : null,
      cards: editorContents.map(editorContent => editorContent.card),
      ...(
        editing
          ? {
            viewAudience: {
              segments: [
                {
                  conditions: [
                    ...form.viewAudience.segments[0].conditions,
                  ],
                }
              ],
            }
          }
          : {}
      ),
    };

    return api.post<FormAndMultiContentCards>(`/forms${ id ? `/${ id }` : '' }`, formWithUserInAudiences)
      .then(response => {
        onSaveComplete();
        return FormTransformer.consolidateFormAndCardsContent(response.data);
      })
      .catch((error: AxiosError<unknown>) => {
        if (error.response && ErrorResponseReader.isValidationErrorResponse<DraftSingleContentForm>(error.response)) {
          const validationTree = error.response.data.error.data.root;
          setFormValidation(validationTree);
        }
        throw error;
      });
  }, [editing, editorContents, form, id, onSaveComplete, user.id]);

  const whenEditViewAudienceButtonClicked = useCallback(() => {
    setUneditedViewAudience(form.viewAudience);
    setEditing(true);
  }, [form.viewAudience]);

  const whenRevertViewAudienceButtonClicked = useCallback(() => {
    if (uneditedViewAudience) {
      setForm(form => ({
        ...form,
        viewAudience: uneditedViewAudience,
      }));
    }

    setEditing(false);
  }, [setForm, uneditedViewAudience]);

  const navigateToEdit = (formId: string) => {
    history.replace(history.location.pathname.replace('create', `${ formId }/edit`));
  };

  const whenSaveButtonClicked = (): void => {
    if (api) {
      setSaving(true);
      saveForm(api)
        .then((formAndCards) => {
          const successMessage = intl.formatMessage({
            id: 'form.successMessage',
            defaultMessage: '{formName} saved successfully.'
          }, {
            formName,
          });
          addSuccessToast(successMessage);
          if (formAndCards) {
            navigateToEdit(formAndCards.form.id);
          }
          setSaving(false);
        })
        .catch(() => {
          const failureMessage = intl.formatMessage({
            id: 'form.failureMessage',
            defaultMessage: '{formName} could not be saved.'
          }, {
            formName,
          });
          addErrorToast(failureMessage);
          setSaving(false);
        });
    }
  };

  const whenPublishFailed = () => {
    const failureMessage = intl.formatMessage({
      id: 'form.failureMessage',
      defaultMessage: '{formName} could not be published.'
    }, {
      formName: form.name,
    });
    addErrorToast(failureMessage);
    setSaving(false);
  };

  const whenSaveAndPublishButtonClicked = () => {
    if (api) {
      setSaving(true);
      saveForm(api)
        .then((formAndCards) => {
          if (!formAndCards) {
            throw new Error('No form to publish.');
          }

          return api.post(`/forms/${ formAndCards.form.id }/publish`)
            .then(() => {
              const successMessage = intl.formatMessage({
                id: 'form.successMessage',
                defaultMessage: '{formName} published successfully.'
              }, {
                formName,
              });
              addSuccessToast(successMessage);

              setTimeout(() => {
                history.push('/forms?publishStatus=published');
                setSaving(false);
              }, 50);
            })
            .catch(() => {
              navigateToEdit(formAndCards.form.id);
              whenPublishFailed();
            });
        })
        .catch(whenPublishFailed);
    }
  };

  useEffect((): void => {
    if (!contentDefinitionRegistry || !runFormValidation) {
      return;
    }

    setRunFormValidation(false);
    setFormValidation((validation) => ({
      errors: validation.errors,
      children: {
        ...validation.children,
        ...(publishStatus === 'published' ? FormValidator.validate(form) : {}).children,
        cards: {
          errors: [],
          children: ArrayHelper.objectify(
            editorContents.map(editorContent => {
              const contentValidation = contentDefinitionRegistry.validate(editorContent);
              const contentIdentifiesUser = !!contentDefinitionRegistry.getContentDefinition(editorContent.card.content.type)?.identifiesUser;
              return {
                errors: contentValidation.errors,
                children: {
                  ...contentValidation.children,
                  content: {
                    errors: contentValidation.children.content?.errors || [],
                    children: {
                      ...contentValidation.children.content?.children,
                      type: {
                        errors: (contentValidation.children.content?.children.type?.errors || []).concat([
                          ...(form.anonymousSubmissionsEnabled && contentIdentifiesUser
                            ? [
                              {
                                type: 'notAllowed',
                                metadata: {
                                  reason: 'Not allowed with anonymous submissions',
                                },
                              } as NotAllowedError,
                            ]
                            : []
                          )
                        ]),
                        children: {},
                      },
                    },
                  },
                },
              };
            })
          ),
        },
      },
    }));
  }, [publishStatus, contentDefinitionRegistry, form, runFormValidation, editorContents, setFormValidation]);

  const addContentAtIndex = useCallback((content: EditorContent<DraftFormContent>, index: number): void => {
    const newContents = ArrayHelper.insert(editorContents, index, content);
    setEditorContents(newContents);
    setFormValidation({
      ...formValidation,
      children: {
        ...(formValidation.children || {}),
        cards: {
          errors: [],
          children: ArrayHelper.objectify(
            newContents.map(
              (content, contentIndex) => contentIndex === index
                ? { errors: [], children: {} }
                : contentDefinitionRegistry.validate(content),
            )
          ),
        }
      }
    });
    setActiveContentIndex(index);
  }, [
    setEditorContents,
    setActiveContentIndex,
    editorContents,
    contentDefinitionRegistry,
    formValidation,
    setFormValidation,
  ]);

  const whenCardValidationChanged = (cardValidation: ValidationTree<DraftSingleContentCard[]>) => {
    setFormValidation(validation => ({
      ...validation,
      children: {
        ...validation.children,
        cards: cardValidation,
      },
    }));
  };

  const replaceContentAtIndex = useCallback((content: EditorContent<DraftFormContent>, index: number): void => (
    setEditorContents(contents => ArrayHelper.replace(contents, index, content))
  ), [setEditorContents]);

  const removeContentAtIndex = useCallback((index: number): void => {
    const newContents = ArrayHelper.remove(editorContents, index);
    setEditorContents(newContents);
    if (activeContentIndex > index) {
      setActiveContentIndex(Math.max(0, activeContentIndex - 1));
    } else {
      setActiveContentIndex(Math.min(activeContentIndex, newContents.length - 1));
    }

    setFormValidation((validation) => {
      const cardChildren: ValidationTree<DraftSingleContentCard>['children'] = {
        ...validation.children?.cards?.children
      };
      delete (cardChildren as Record<string, unknown>)[`${ index }`];
      return ({
        ...validation,
        children: {
          ...validation.children,
          cards: {
            errors: validation.children?.cards?.errors || [],
            children: cardChildren,
          },
        },
      });
    });
  }, [editorContents, setEditorContents, setActiveContentIndex, activeContentIndex, setFormValidation]);

  const whenContentSelected = useCallback(
    (selectedIndex: number) => {
      setActiveContentIndex(selectedIndex);

      const previouslyActiveContent = editorContents[activeContentIndex];
      if (!previouslyActiveContent) {
        return;
      }
      setFormValidation((validation) => ({
        ...validation,
        children: {
          ...validation.children,
          cards: {
            errors: validation.children?.cards?.errors || [],
            children: {
              ...validation.children?.cards?.children,
              [activeContentIndex]: contentDefinitionRegistry.validate(previouslyActiveContent),
            },
          },
        },
      }));
    },
    [setActiveContentIndex, setFormValidation, activeContentIndex, editorContents, contentDefinitionRegistry],
  );

  const moveIndex = useCallback((sourceIndex: number, destinationIndex: number) => {
    setFormValidation(validation => ({
      ...validation,
      children: {
        ...validation.children,
        cards: {
          ...(
            validation.children.cards || {
              children: [],
              errors: [],
            }
          ),
          children: ArrayHelper.objectify(
            ArrayHelper.move(
              Object.values(validation.children.cards?.children || {}),
              sourceIndex,
              destinationIndex,
            )
          ),
        }
      },
      errors: [],
    }));

    setEditorContents(contents => ArrayHelper.move(contents, sourceIndex, destinationIndex))
    if (activeContentIndex === sourceIndex) {
      setActiveContentIndex(destinationIndex);
    } else if (sourceIndex < activeContentIndex && destinationIndex >= activeContentIndex) {
      setActiveContentIndex(activeCardIndex => activeCardIndex - 1);
    } else if (sourceIndex > activeContentIndex && destinationIndex <= activeContentIndex) {
      setActiveContentIndex(activeCardIndex => activeCardIndex + 1);
    }
  }, [setEditorContents, setActiveContentIndex, activeContentIndex]);

  const saveAndPublishUnpublishedFormButton = (
    <Button
      variant="primary"
      disabled={ saving || !canPublishForm || formHasErrors || insufficientRecipientCount }
      onClick={ whenSaveAndPublishButtonClicked }
    >
      {
        saving
          ? <ButtonProgress/>
          : saveAndPublishString
      }
    </Button>
  );

  const saveAndPublishPublishedFormButton = (
    <SaveButton
      onClick={ whenSaveAndPublishButtonClicked }
      disabled={ saving || !canPublishForm || formHasErrors || insufficientRecipientCount }
      color="primary"
    >
      {
        saving
          ? (
            <ButtonProgress/>
          )
          : (
            <>
              <SaveIcon/> { saveAndPublishString }
            </>
          )
      }
    </SaveButton>
  );

  const whenViewAudienceChanged = useCallback((viewAudience: Audience) => {
    setForm(form => ({
      ...form,
      viewAudience,
    }));
  }, [setForm]);

  const buildStep = useMemo(() => ({
    link: `${ url }`,
    title: intl.formatMessage({
      id: 'forms.create.buildStep',
      description: 'Heading for form content creation',
      defaultMessage: 'Build form',
    }),
    error: !!(formValidation?.children.cards && getDescendantErrors(formValidation.children.cards).length > 0),
    render: () => (
      <ContentEditor
        activeContentIndex={ activeContentIndex }
        editorContents={ editorContents }
        onContentAdded={ addContentAtIndex }
        onContentUpdated={ replaceContentAtIndex }
        onContentMoved={ moveIndex }
        onContentRemoved={ removeContentAtIndex }
        onContentSelected={ whenContentSelected }
        validation={ formValidation.children.cards }
        onValidationChanged={ whenCardValidationChanged }
        ContentMenu={ ({ onContentCreated }) => (
          <FormEditorContentMenu
            onContentCreated={ onContentCreated }
            anonymous={ form.anonymousSubmissionsEnabled }
          />
        ) }
        lockFirstIndex={ true }
        renderContent={ (activeContentIndex) => (
          editorContents[activeContentIndex] && (
            <FormContentPreview
              name={ form.name || '' }
              content={ editorContents[activeContentIndex] }
              contentCount={ editorContents.length }
              onPreviousClicked={ () => setActiveContentIndex(activeContentIndex => Math.max(0, activeContentIndex - 1)) }
              onNextClicked={ () => setActiveContentIndex(activeContentIndex => Math.min(editorContents.length - 1, activeContentIndex + 1)) }
              previousButtonDisabled={ activeContentIndex <= 0 }
              nextButtonDisabled={ activeContentIndex >= editorContents.length - 1 }
              startButtonHidden={ activeContentIndex !== 0 }
              currentContentIndex={ activeContentIndex }
              anonymousSubmissionsEnabled={ form.anonymousSubmissionsEnabled }
            />
          )
        ) }
        renderTooFewError={ min => (
          <FormattedMessage
            id="formEditor.tooFew"
            description="Error in form editor when form contains too few cards."
            defaultMessage="Forms must contain at least { min, plural, one { # card } other { # cards } }."
            values={ { min } }
          />
        ) }
        renderTooManyError={ max => (
          <FormattedMessage
            id="formEditor.tooMany"
            description="Error in form editor when form contains too many cards."
            defaultMessage="Forms must not contain more than { max, plural, one { # card } other { # cards } }."
            values={ { max } }
          />
        ) }
      />
    ),
  }), [
    url,
    intl,
    formValidation.children.cards,
    activeContentIndex,
    editorContents,
    addContentAtIndex,
    replaceContentAtIndex,
    moveIndex,
    removeContentAtIndex,
    whenContentSelected,
    form.anonymousSubmissionsEnabled,
    form.name
  ]);

  const whenAudienceNextButtonClicked = useCallback(() => {
    history.push(`${ url }/reporting`);
  }, [history, url]);

  const audienceStep = useMemo(() => ({
    link: `${ url }/audience`,
    title: intl.formatMessage({
      id: 'forms.create.audienceStep',
      description: 'Heading for form audience definitions',
      defaultMessage: 'Define audience',
    }),
    render: () => (
      <ViewAudienceEditor
        audienceSize={ fetchAudienceMembersResult?.content?.audienceSize }
        audienceSizeLinkUrl="#recipients"
        anonymous={ form.anonymousSubmissionsEnabled }
        audience={ form.viewAudience }
        onChange={ whenViewAudienceChanged }
        validation={ formValidation?.children.viewAudience }
        onNextButtonClicked={ whenAudienceNextButtonClicked }
        editing={ editing }
        alwaysEdit={ alwaysEditAudience }
        readonly={ publishStatus !== 'draft' && form.anonymousSubmissionsEnabled }
        onEditButtonClicked={ whenEditViewAudienceButtonClicked }
        onRevertButtonClicked={ whenRevertViewAudienceButtonClicked }
      >
        { fetchAudienceMembersResult?.content && (
          <AudienceMemberList
            id="recipients"
            showStatus
            audienceMembersResult={ fetchAudienceMembersResult.content }
            search={ audienceMemberSearch }
            query={ audienceMemberQuery }
            onSortChanged={ whenAudienceMemberSortChanged }
            onSearchChanged={ setAudienceMemberSearch }
            onPageChanged={ whenAudienceMemberPageNumChanged }
            onRetryClicked={ refreshFetchAudienceMembers }
            requestState={ fetchAudienceMembersRequestState }
          />
        ) }
      </ViewAudienceEditor>
    ),
  }), [
    url,
    intl,
    fetchAudienceMembersResult?.content,
    form.anonymousSubmissionsEnabled,
    form.viewAudience,
    whenViewAudienceChanged,
    formValidation?.children.viewAudience,
    whenAudienceNextButtonClicked,
    editing,
    alwaysEditAudience,
    publishStatus,
    whenEditViewAudienceButtonClicked,
    whenRevertViewAudienceButtonClicked,
    audienceMemberSearch,
    audienceMemberQuery,
    whenAudienceMemberSortChanged,
    whenAudienceMemberPageNumChanged,
    refreshFetchAudienceMembers,
    fetchAudienceMembersRequestState
  ]);

  const reportingStep = useMemo(() => ({
    link: `${ url }/reporting`,
    title: intl.formatMessage({
      id: 'forms.create.reportingStep',
      description: 'Heading for form reporting & notification settings',
      defaultMessage: 'Settings',
    }),
    render: () => (
      <ReportingSettings
        publishStatus={ publishStatus }
        form={ form }
        setForm={ setForm }
      />
    ),
  }), [form, intl, setForm, url, publishStatus]);

  const steps = useMemo<StepperItem[]>(() => [
    buildStep,
    audienceStep,
    reportingStep,
  ], [audienceStep, buildStep, reportingStep]);

  const activeStepIndex = useMemo(() => (
    steps.findIndex(
      step => !!matchPath(step.link, { path: history.location.pathname, exact: true })
    )
  ), [history.location.pathname, steps]);

  const whenNextButtonClicked = useCallback(
    () => {
      history.push(steps[activeStepIndex + 1].link);
    },
    [activeStepIndex, history, steps],
  );

  useEffect(() => {
    if (lastStep === undefined) {
      setLastStep(activeStepIndex)
      return;
    }

    if (lastStep === activeStepIndex) {
      return;
    }

    setLastStep(activeStepIndex);
    setRunFormValidation(true);
  }, [activeStepIndex, lastStep]);

  const whenFormChanged = useCallback((changedForm: DraftSingleContentForm) => {
    if (form.anonymousSubmissionsEnabled !== changedForm.anonymousSubmissionsEnabled) {
      setRunFormValidation(true);
    }

    setForm(changedForm);
  }, [form.anonymousSubmissionsEnabled, setForm]);

  const videoEditorContents = useMemo<VideoEditorContent[]>(() => {
    const videoContentDefinition = new VideoContentDefinition();
    return editorContents.filter(
      editorContent => videoContentDefinition.definesContentType(editorContent.card.content.type)
    ) as VideoEditorContent[];
  }, [editorContents]);

  const uploads = useMemo<MinimalUploadWithStatus[]>(() => {
    const pendingVideoEditorContents = videoEditorContents.filter(
      videoEditorContent => videoEditorContent.upload && [
        'pending',
        'running'
      ].includes(videoEditorContent.prepareStatus || '')
    );

    return pendingVideoEditorContents.map(
      pendingVideoEditorContent => ({
        id: String(pendingVideoEditorContent.upload?.id || ''),
        prepareStatus: pendingVideoEditorContent.prepareStatus,
      } as MinimalUploadWithStatus),
    );
  }, [videoEditorContents]);

  const videoContentWithoutThumbnail = useMemo<number>(() => (
    videoEditorContents.filter(videoEditorContent => !videoEditorContent.card.content.thumbnailKey).length
  ), [videoEditorContents]);

  const addTranscodingErrorsToCards = useCallback((cardValidations: ValidationTree<DraftSingleContentCard[]>, failedUploadIds: string[]) => ({
    errors: cardValidations.errors,
    children: editorContents.map((editorContent, index) => {
      if (editorContent.card.content.type !== 'videoContent' || !(editorContent as VideoEditorContent).upload) {
        return cardValidations.children[index] || {
          errors: [],
          children: {},
        };
      }

      const videoContent = editorContent as VideoEditorContent;
      const videoContentValidation: ValidationTree<DraftSingleContentCard<DraftVideoContent>> = cardValidations.children[index] || {
        errors: [],
        children: {},
      };

      return {
        errors: videoContentValidation.errors || [],
        children: {
          ...videoContentValidation.children,
          content: {
            errors: videoContentValidation.children.content?.errors || [],
            children: {
              ...videoContentValidation.children.content?.children,
              uploadId: {
                errors: failedUploadIds.includes((videoContent.upload?.id || '').toString())
                  ? (videoContentValidation.children.content?.children.uploadId?.errors || []).filter(error => error.type !== 'transcodingFailed').concat({
                    type: 'transcodingFailed',
                    metadata: null,
                  })
                  : (videoContentValidation.children.content?.children.uploadId?.errors || []).filter(error => error.type !== 'transcodingFailed'),
                children: {},
              },
            },
          },
        },
      } as ValidationTree<DraftSingleContentCard<DraftVideoContent>>;
    })
  }), [editorContents]);

  const whenUploadsChanged = useCallback((uploads: MinimalUploadWithStatus[]) => {
    const videoContentDefinition = new VideoContentDefinition();

    setEditorContents(editorContents => (
      editorContents.map(editorContent => {
        if (!videoContentDefinition.definesContentType(editorContent.card.content.type)) {
          return editorContent;
        }

        const videoEditorContent = editorContent as VideoEditorContent;

        if (!videoEditorContent.upload) {
          return videoEditorContent;
        }

        const correspondingUpload = uploads.find(upload => upload.id === videoEditorContent.upload?.id);

        if (!correspondingUpload) {
          return videoEditorContent;
        }

        return {
          ...videoEditorContent,
          prepareStatus: correspondingUpload.prepareStatus,
        };
      })
    ));
  }, [setEditorContents]);

  // Update validation with video transcoding errors
  useEffect(() => {
    const failedUploadIds = videoEditorContents.filter(
      videoEditorContent => videoEditorContent.prepareStatus === 'failed' && videoEditorContent.upload,
    ).map(videoEditorContent => String(videoEditorContent.upload?.id || ''));

    if (!failedUploadIds.length) {
      return;
    }

    setFormValidation(formValidation => ({
      errors: formValidation.errors,
      children: {
        ...formValidation.children,
        cards: addTranscodingErrorsToCards(
          formValidation.children.cards as ValidationTree<DraftSingleContentCard[]>,
          failedUploadIds,
        ) as ValidationTree<DraftSingleContentCard[]>,
      },
    }));
  }, [videoEditorContents, addTranscodingErrorsToCards]);

  const videoProcessingState = useMemo<RequestState>(() => (
    !videoEditorContents.length
      ? RequestState.PENDING
      : videoEditorContents.find(videoEditorContent => videoEditorContent.prepareStatus === 'failed')
        ? RequestState.FAILED
        : videoEditorContents.find(videoEditorContent => [
          'pending',
          'running'
        ].includes(videoEditorContent.prepareStatus || ''))
          ? RequestState.FETCHING
          : videoEditorContents.every(videoEditorContent => videoEditorContent.prepareStatus === 'complete')
            ? RequestState.COMPLETE
            : RequestState.PENDING
  ), [videoEditorContents]);

  useUploadStatusPolling(uploads, whenUploadsChanged);

  return (
    <StepperHeader
      title={
        <NameAndCategoryInput
          form={ form }
          onChange={ whenFormChanged }
          validation={ formValidation || null }
          onValidationChanged={ setFormValidation }
          publishStatus={ publishStatus }
        />
      }
      preStep={
        <>
          <TranscodingProgress
            videosMissingThumbnails={ videoContentWithoutThumbnail }
            state={ videoProcessingState }
          />
          { form.anonymousSubmissionsEnabled && activeStepIndex === 0 && (
            <Box margin={ false }>
              <Flex
                align="flex-start"
                gap={ 2 }
                noWrap
              >
                <PresentationIcon
                  size="medium"
                  color="grey.500"
                  IconComponent={ LockIcon }
                />
                <VerticallySpaced>
                  <Heading type="h4">
                    <FormattedMessage
                      description="Header for notice that is visible when form is anonymous."
                      defaultMessage="Anonymous responses will be collected"
                    />
                  </Heading>
                  <span>
                    <FormattedMessage
                      description="Body for notice that is visible when form is anonymous."
                      defaultMessage="Please do not ask for personally identifiable information to ensure anonymity of respondents. The location card is disabled for this reason."
                    />
                  </span>
                </VerticallySpaced>
              </Flex>
            </Box>
          ) }
          { showAnonymousRecipientCountNotice && (
            <Box margin={ false }>
              <Flex gap={ 1 }>
                <PresentationIcon
                  color="error.main"
                  size="medium"
                  IconComponent={ LockIcon }
                />
                <FormattedMessage
                  description="Notice displayed when anonymous form doesn't have enough recipients."
                  defaultMessage="<span><strong>Anonymous responses</strong> require a minimum of { min, plural, one { # recipient } other { # recipients } }.</span>"
                  values={ { min: MIN_ANONYMOUS_RECIPIENTS } }
                />
              </Flex>
            </Box>
          ) }
        </>
      }
      actions={
        <>
          {
            publishStatus === 'published'
              ? (
                canPublishForm
                  ? saveAndPublishPublishedFormButton
                  : <PublishTooltip>{ saveAndPublishPublishedFormButton }</PublishTooltip>
              )
              : (
                <SaveButton
                  onClick={ whenSaveButtonClicked }
                  disabled={ saving || formHasErrors || insufficientRecipientCount }
                  color="primary"
                >
                  {
                    saving
                      ? (
                        <ButtonProgress/>
                      )
                      : (
                        <>
                          <SaveIcon/>
                          <FormattedMessage
                            description="Label for button to save form"
                            defaultMessage="Save"
                          />
                        </>
                      )
                  }
                </SaveButton>
              )
          }
          {
            activeStepIndex < steps.length - 1 && (
              <Button
                variant="primary"
                onClick={ whenNextButtonClicked }
              >
                <FormattedMessage
                  description="Label for button to advance to next section of form creation"
                  defaultMessage="Next"
                />
              </Button>
            )
          }
          {
            activeStepIndex === steps.length - 1 && publishStatus !== 'published' && (
              canPublishForm
                ? saveAndPublishUnpublishedFormButton
                : (
                  <PublishTooltip>
                    { saveAndPublishUnpublishedFormButton }
                  </PublishTooltip>
                )
            )
          }
        </>
      }
      steps={ steps }
    />
  );
};

const MIN_ANONYMOUS_RECIPIENTS = 5;
