import {
  DraftUploadRequest,
  UPLOAD_REQUEST_MAX_UPLOADS_MAX,
  UPLOAD_REQUEST_MAX_UPLOADS_MIN,
  UPLOAD_REQUEST_MIN_UPLOADS_MAX,
  UPLOAD_REQUEST_MIN_UPLOADS_MIN
} from '../../Model/ContentTypes';
import { ValidationTree, Validator } from '../../../Common/Model';
import { Validate } from '../../../Common/Utility';
import { RequestValidator } from './RequestValidator';

export class UploadRequestValidator {
  public static validateMinUploads = (content: DraftUploadRequest): ValidationTree<number> => ({
    errors: new Validator<number>([
      Validate.isNumeric,
      minUploads => Validate.greaterThanEq(minUploads, UPLOAD_REQUEST_MIN_UPLOADS_MIN),
      minUploads => Validate.lessThanEq(minUploads, Math.min(content.maxUploads, UPLOAD_REQUEST_MIN_UPLOADS_MAX)),
    ]).validate(content.minUploads),
    children: {},
  });

  public static validateMaxUploads = (content: DraftUploadRequest): ValidationTree<number> => ({
    errors: new Validator<number>([
      Validate.isNumeric,
      maxUploads => Validate.greaterThanEq(maxUploads, Math.max(content.minUploads, UPLOAD_REQUEST_MAX_UPLOADS_MIN)),
      maxUploads => Validate.lessThanEq(maxUploads, UPLOAD_REQUEST_MAX_UPLOADS_MAX),
    ]).validate(content.maxUploads),
    children: {},
  });

  public static validate = (content: DraftUploadRequest): ValidationTree<DraftUploadRequest> => ({
    errors: [
      ...RequestValidator.validate(content).errors,
    ],
    children: {
      ...RequestValidator.validate(content).children,
      minUploads: UploadRequestValidator.validateMinUploads(content),
      maxUploads: UploadRequestValidator.validateMaxUploads(content),
    },
  });
}
