import { ChangeEventHandler, FC, useCallback, useMemo } from 'react';
import { formatISO, parseISO } from 'date-fns';

import { DateOffsetParser } from '../../../Utility/DateOffsetParser';
import { DateOffsetProvider } from '../../../Utility/DateOffsetProvider';
import { DateOffsetInput, DateOffsetInputProps } from '../DateOffsetInput/DateOffsetInput';
import { Flex } from '../../../../../src/react/Common/Component/Flex/Flex';
import { DateTimeFormatter } from '../../../../../src/react/Utility/DateTimeFormatter';
import { NoMarginTextField } from '../../../../../src/react/Common/Component/NoMarginTextField/NoMarginTextField';
import { DateOffset } from '../../../../Core/Model/DateOffset';

export type NonNullableDateOffsetRange = {
  start: NonNullable<DateOffsetRange['start']>;
  end: NonNullable<DateOffsetRange['end']>;
};

export type DateOffsetRange = {
  start: DateOffset | null;
  end: DateOffset | null;
};

export type DateOffsetRangeInputProps = {
  options: DateOffsetInputProps['options'];
  value: DateOffsetRange;
  onChange: (value: DateOffsetRange) => void;
  customOption: DateOffsetInputProps['customOption'];
  endDate: {
    optionLabel?: string;
    inputLabel?: string;
    initialValue: DateOffset;
    min?: string;
    max?: string;
  };
};

export const DateOffsetRangeInput: FC<DateOffsetRangeInputProps> = ({
  options,
  value,
  onChange,
  customOption,
  endDate,
}) => {
  const offsetInputValue = useMemo(() => value.start, [value]);

  const whenOffsetInputChanged = useCallback((dateOffset: DateOffset | null) => (
    onChange({
      start: dateOffset,
      end: dateOffset
        ? dateOffset.type === 'fixed'
          ? value.end?.type === 'relative'
            ? DateOffsetParser.fixed(value.end)
            : value.end?.type === 'fixed'
              ? value.end
              : DateOffsetParser.fixed(DateOffsetProvider.now())
          : DateOffsetProvider.now()
        : null,
    })
  ), [onChange, value]);

  const whenEndDateChanged: ChangeEventHandler<HTMLInputElement> = useCallback(event => (
    onChange({
      start: value.start,
      end: {
        type: 'fixed',
        date: formatISO(parseISO(event.currentTarget.value)),
      },
    })
  ), [onChange, value]);

  return (
    <Flex gap={ 2 }>
      <DateOffsetInput
        options={ options }
        value={ offsetInputValue }
        onChange={ whenOffsetInputChanged }
        customOption={ customOption }
      />
      { value.start?.type === 'fixed' && (
        <NoMarginTextField
          type="datetime-local"
          label={ endDate?.inputLabel }
          inputProps={ {
            min: endDate?.min,
            max: endDate?.max,
          } }
          value={
            value.end?.type === 'fixed'
              ? DateTimeFormatter.dateTimeInput(parseISO(value.end.date))
              : DateTimeFormatter.dateTimeInput(parseISO(DateOffsetParser.toIsoString(endDate.initialValue)))
          }
          onChange={ whenEndDateChanged }
          variant="outlined"
        />
      ) }
    </Flex>
  );
};
