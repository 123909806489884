import { FunctionComponent, useState } from 'react';
import { Button } from '@mui/material';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { FormattedMessage } from 'react-intl';

import { Box, FlexPullRight } from '../../../Common/Component';
import { CreditSummary, TransactionTable } from '../../Component';
import { TransactionDialog } from '../../Component/TransactionDialog/TransactionDialog';

export const CreditPage: FunctionComponent = () => {
  const [transactionDialogOpen, setTransactionDialogOpen] = useState<boolean>(false);
  const [transactionSavedAt, setTransactionSavedAt] = useState<number>(0);

  const whenTransactionSaved = (): void => {
    setTransactionSavedAt(Date.now());
  }

  return (
    <Stack direction="column">
      <FlexPullRight>
        <Button
          color="primary"
          variant="contained"
          disableElevation
          onClick={ () => setTransactionDialogOpen(true) }
        >
          <FormattedMessage
            id="credit.newTransaction"
            description="New transaction button cta"
            defaultMessage="New transaction"
          />
        </Button>
      </FlexPullRight>
      <Box>
        <CreditSummary transactionSavedAt={ transactionSavedAt }/>
        <TransactionTable
          transactionSavedAt={ transactionSavedAt }
        />
      </Box>
      <TransactionDialog
        open={ transactionDialogOpen }
        onClosed={ () => setTransactionDialogOpen(false) }
        onSaved={ whenTransactionSaved }
      />
    </Stack>
  );
};
