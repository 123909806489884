import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';

import { Dataset } from '../../Model';
import { MetricContainer, MetricTooltip, MissingBroadcastMetricMessage, NumericMetric } from '..';

interface Props {
  previousDataset?: Dataset;
  currentDataset?: Dataset;
  span?: number;
}

export const BroadcastDeliverySuccessMetric: FunctionComponent<Props> = ({ previousDataset, currentDataset, span = 2 }) => {
  const intl = useIntl();
  const previousValue = previousDataset?.data[0]?.value || 0;
  const currentValue = currentDataset?.data[0]?.value || 0;

  return (
    <MetricContainer
      title={ intl.formatMessage({
        id: 'dashboard.broadcastDeliverySuccess.heading',
        description: 'Heading for broadcast delivery success stat block on dashboard.',
        defaultMessage: 'Broadcast delivery',
      }) }
      actions={
        <MetricTooltip title={ intl.formatMessage({
          id: 'dashboard.broadcastDeliverySuccess.tooltip',
          description: 'Tooltip for broadcasts delivery success stat block on dashboard.',
          defaultMessage: 'The average broadcast delivery success over the selected period.',
        }) }/>
      }
      span={ span }
    >
      {
        previousValue || currentValue
          ? (
            <NumericMetric
              previousValue={ previousValue }
              currentValue={ currentValue }
              numberFormattingOptions={ {
                style: 'percent',
                maximumFractionDigits: 0,
              } }
            />
          )
          : <MissingBroadcastMetricMessage/>
      }
    </MetricContainer>
  );
};
