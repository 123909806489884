import { useEffect, useMemo, useState } from 'react';

import { useContextOrThrow } from '../../Core/Hook';
import { NoticeContext } from '../../Core/Context';
import { Notices, ThresholdState } from '../../Common/Model';
import { useMounted } from '../../Common/Hook';

export const useUpcomingSmsSpendThresholdExceeded = (): boolean => {
  const mounted = useMounted();
  const [notices, setNotices] = useState<Notices>();
  const { getNotices } = useContextOrThrow(NoticeContext);
  const thresholdState = useMemo<ThresholdState | undefined>(() => (
    notices?.smsCreditThreshold?.thresholdState
  ), [notices?.smsCreditThreshold?.thresholdState]);

  useEffect(() => {
    getNotices()
      .then(notices => {
        if (!mounted.current) {
          return;
        }

        setNotices(notices);
      })
      .catch(() => { /* Don't check broadcast credit */ });
  }, [getNotices, mounted]);

  return !!(
    thresholdState
    && thresholdState.upcomingSpendCount > 0
    && thresholdState.availableCount + thresholdState.upcomingSpendCount < thresholdState.thresholdCount
  );
};
