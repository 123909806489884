import styled from '@emotion/styled';

export const StyledNotFoundError = styled.div`
  h1 {
    margin-bottom: 1rem;
  }
  p {
    margin-bottom: 1.5rem;
  }
`;
