import { Audience, Condition } from '../Model';

export class AudienceCreator {
  public static createAudienceWithCondition = (condition: Condition): Audience => {
    return {
      segments: [
        {
          conditions: [
            condition,
          ],
        },
      ],
    };
  };

  public static createBlankAudience = (): Audience => AudienceCreator.createAudienceWithCondition({ type: 'everyone' });
}
