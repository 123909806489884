import styled from '@emotion/styled';

import { FeedbackSeverity } from '../../../../../Common/Component';

export const StyledEventStatus = styled.div<{ color: FeedbackSeverity }>`
  display: flex;
  flex-wrap: nowrap;
  gap: ${ props => props.theme.spacing(1) };
  border-bottom: 1px solid ${ props => props.theme.palette.grey[200] };

  ::before {
    content: '';
    display: block;
    width: 6px;
    background-color: ${ props => props.theme.palette[props.color].main };
  }

  >* {
    padding: ${ props => props.theme.spacing(1) };
  }
`;
