import { ComponentProps, FC, useMemo, useState } from 'react';
import MenuIcon from 'op-storybook/lib/assets/icon/figma/dots-vertical.svg';
import { IconButton } from 'op-storybook/stories/components/IconButton/IconButton';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { PresentationIcon } from 'op-storybook/lib/components/PresentationIcon/PresentationIcon';
import { Stack } from '@mui/material';
import { Button } from 'op-storybook/stories/components/Button/Button';
import { ButtonBase } from 'op-storybook/stories/components/ButtonBase/ButtonBase';
import { LocationDescriptor } from 'history';
import { Link } from 'react-router-dom';
import { PrimaryInformation } from 'op-storybook/stories/components/Typography/PrimaryInformation';

import { SvgComponent } from '../../../../Common/Model';

export enum ContextMenuItemInlineMode {
  ICON_ONLY,
  LABEL_ONLY,
  ICON_AND_LABEL,
}

type LinkProps = { location: LocationDescriptor; onClick?: never; type?: never; formId?: never };
type ButtonProps = { onClick: () => void; location?: never; type?: 'button'; formId?: never };
type SubmitButtonProps = { onClick?: never; location?: never; formId?: string; type: 'submit' }

export type ContextMenuAction = {
  id: string;
  busy?: boolean;
  disabled?: boolean;
  attention?: boolean;
  label: string;
  IconComponent?: SvgComponent;
  inline?: {
    mode: ContextMenuItemInlineMode,
    variant?: ComponentProps<typeof Button>['variant'],
  };
} & (LinkProps | ButtonProps | SubmitButtonProps);

type Props = {
  actions: ContextMenuAction[];
  maxInlineActionCount?: number;
  alwaysUseMenu?: boolean;
  side?: ComponentProps<typeof DropdownMenu.Content>['side'];
};

export const ContextMenu: FC<Props> = ({
  actions,
  maxInlineActionCount = 0,
  alwaysUseMenu = false,
  side,
}) => {
  const inlineActionCount = actions.length > 1 ? Math.min(maxInlineActionCount, actions.length) : alwaysUseMenu ? 0 : 1;
  const inlineActions = useMemo(() => actions.slice(0, inlineActionCount), [actions, inlineActionCount]);
  const menuActions = useMemo(() => actions.slice(inlineActionCount, actions.length), [actions, inlineActionCount]);
  const menuTriggerBusy = useMemo(() => !!menuActions.find(action => action.busy), [menuActions]);
  const menuTriggerAttention = useMemo(() => !!menuActions.find(action => action.attention), [menuActions]);
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Stack gap={ 2 }>
      { inlineActions.map(inlineActionProps => (
        <Button
          key={ inlineActionProps.id }
          padding="uniform"
          busy={ inlineActionProps.busy }
          disabled={ inlineActionProps.disabled }
          attention={ inlineActionProps.attention }
          variant={ inlineActionProps.inline?.variant || 'tertiary' }
          { ...(
            inlineActionProps.location
              ? {
                component: Link,
                to: inlineActionProps.location,
              }
              : {
                component: 'button',
                onClick: inlineActionProps.onClick,
                type: inlineActionProps.type || 'button',
                form: inlineActionProps.formId,
              }
          ) }
        >
          { inlineActionProps.inline?.mode !== ContextMenuItemInlineMode.LABEL_ONLY && inlineActionProps.IconComponent && (
            <PresentationIcon
              size={ 5 }
              IconComponent={ inlineActionProps.IconComponent }
            />
          ) }
          { inlineActionProps.inline?.mode !== ContextMenuItemInlineMode.ICON_ONLY && (
            inlineActionProps.label
          ) }
        </Button>
      )) }
      { !!menuActions.length && (
        <DropdownMenu.Root open={ open } onOpenChange={ setOpen }>
          <DropdownMenu.Trigger asChild>
            <IconButton
              IconComponent={ MenuIcon }
              variant="tertiary-grey"
              label="actions"
              busy={ menuTriggerBusy }
              attention={ menuTriggerAttention }
            />
          </DropdownMenu.Trigger>
          <DropdownMenu.Portal>
            <DropdownMenu.Content
              asChild
              collisionPadding={ 8 }
              side={ side }
            >
              <div
                css={ theme => ({
                  padding: theme.new.spacing[2],
                  filter: 'drop-shadow( 0 2px 5px hsla(0, 0%, 0%, 0.05)) drop-shadow( 0 0 1px hsla(0, 0%, 0%, 0.1))',
                  borderRadius: theme.new.borderRadius.standard,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: theme.new.spacing[2],
                  backgroundColor: theme.new.basePalette.white.main,

                  'span > svg': {
                    height: theme.new.spacing[2],
                    width: theme.new.spacing[2],
                    fill: theme.new.basePalette.white.main,
                  },
                }) }
              >
                <ul
                  css={ {
                    listStyle: 'none',
                    margin: 0,
                    padding: 0,
                  } }
                >
                  { menuActions.map(({ id, IconComponent, label, onClick }) => (
                    <DropdownMenu.Item
                      key={ id }
                      asChild
                      textValue={ label }
                    >
                      <li>
                        <ButtonBase
                          color="primary"
                          variant="tertiary-dark"
                          onClick={ event => {
                            onClick && onClick();
                            event.preventDefault();
                            setOpen(false);
                          } }
                          fillParent={ true }
                          css={ theme => ({
                            justifyContent: 'start',
                          }) }
                        >
                          <Stack gap={ 1 } direction="row">
                            { IconComponent && (
                              <PresentationIcon
                                IconComponent={ IconComponent }
                                size={ 5 }
                                palette={ {
                                  colour: 'grey',
                                  intensity: 600,
                                } }
                              />
                            ) }
                            <PrimaryInformation>{ label }</PrimaryInformation>
                          </Stack>
                        </ButtonBase>
                      </li>
                    </DropdownMenu.Item>
                  )) }
                </ul>
                <DropdownMenu.Arrow/>
              </div>
            </DropdownMenu.Content>
          </DropdownMenu.Portal>
        </DropdownMenu.Root>
      ) }
    </Stack>
  );
};
