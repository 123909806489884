import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';

import { MinimalTag, TagType } from '../../../Tags/Model';
import { TagParser } from '../../../Tags/Utility';
import { StyledProfile } from './styles';
import { Flex, FlexPullRight } from '../../../Common/Component';
import { IntegrationProfileTooltip } from '..';

interface Props {
  tags: MinimalTag<TagType>[];
  externallyManaged?: boolean;
}

const tagTypes: TagType[] = ['team', 'jobtitle', 'skill'];

export const PersonProfile: FunctionComponent<Props> = ({ tags, externallyManaged = true }) => {
  const intl = useIntl();

  const summary = tagTypes.map((type) => {
    const filteredTags = tags.filter((tag) => tag.type === type);
    return filteredTags.length > 0
      ? {
        type,
        title: TagParser.localisedTagTypeFromInternalTagType(
          type,
          intl,
          2
        ),
        tags: filteredTags.map((tag) => tag.name.replace(/\s/g, '\u00A0')).sort(),
      }
      : false;
  }).filter(type => type !== false) as { type: TagType, title: string, tags: string[] }[];

  return (
    <StyledProfile>
      {
        summary.map((section) => (
          <div key={ section.type }>
            <Flex gap={ 2 }>
              <h3>{ section.title }</h3>
              { section.type === 'team' && externallyManaged && <FlexPullRight><IntegrationProfileTooltip/></FlexPullRight> }
            </Flex>
            <p>
              { intl.formatList(
                section.tags,
                {
                  type: 'conjunction',
                },
              ) }
            </p>
          </div>
        ))
      }
    </StyledProfile>
  );
};
