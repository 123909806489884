import { ChangeEventHandler, FocusEventHandler, FunctionComponent, useState } from 'react';
import { TextField, TextFieldProps } from '@mui/material';

export const NumberInput: FunctionComponent<TextFieldProps> = ({
  onBlur,
  onChange,
  value,
  ...props
}) => {
  const valueAsString = `${ value as string || 0 }`;
  const [localValue, setLocalValue] = useState<string>(valueAsString);

  const whenValueChanged: ChangeEventHandler<HTMLInputElement> = (event) => {
    setLocalValue(event.target.value);

    if (!isNaN(+event.target.value) && onChange) {
      onChange(event);
    }
  };

  const whenBlurred: FocusEventHandler<HTMLInputElement> = (event) => {
    setLocalValue(valueAsString);
    onBlur && onBlur(event);
  };

  return (
    <TextField
      { ...props }
      type="number"
      value={ localValue }
      onBlur={ whenBlurred }
      onChange={ whenValueChanged }
    />
  );
};
