import { FC, FunctionComponent, SVGProps } from 'react';

import { Flex, PresentationIcon } from '../../../Common/Component';
import { Switch } from '../../../Components';
import { StyledLabel } from './style';
import { useCheckboxChangeEventHandler } from '../../../Common/Hook/useCheckboxChangeEventHandler';

type Props = {
  IconComponent: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string }>;
  label?: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
};

export const SwitchWithIcon: FC<Props> = ({
  IconComponent,
  label,
  checked,
  onChange,
  disabled = false,
}) => {
  const whenSendPushChanged = useCheckboxChangeEventHandler(onChange);

  return (
    <Flex gap={ 2 }>
      <PresentationIcon
        IconComponent={ IconComponent }
      />
      <StyledLabel>
        <Flex gap={ 1 }>
          <Switch
            checked={ checked }
            onChange={ whenSendPushChanged }
            disabled={ disabled }
          />
          { label && <span>{ label }</span> }
        </Flex>
      </StyledLabel>
    </Flex>
  );
};
