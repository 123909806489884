import { FunctionComponent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Box as MaterialBox } from '@mui/material';

import { ImportTable } from '..';
import { Box } from '../../../Common/Component';
import { RequestState } from '../../../Models/RequestState';
import { H1 } from '../../../Components/Layout/headings';

type Props = {
  bulkActionCompletedAt?: Date|null,
  onRequestStateChanged: (requestState: RequestState) => void;
}

export const UpdateSection: FunctionComponent<Props> = ({
  bulkActionCompletedAt,
  onRequestStateChanged,
}) => {
  const [userCount, setUserCount] = useState<number>(0);

  const whenUsersCounted = (userCount: number): void => {
    setUserCount((previous) => Math.max(userCount, previous));
  }

  return (
    <MaterialBox hidden={!userCount}>
      <Box>
        <H1>
          <FormattedMessage
            id="thirdPartyImport.update.heading"
            description="Heading label for update section of third party import"
            defaultMessage="Update OurPeople access"
          />
        </H1>
        <p>
          <FormattedMessage
            id="thirdPartyImport.update.description"
            description="Description for update section of third party import"
            defaultMessage="This list includes people that have been allowed and denied access to OurPeople. People's access can be updated at any time below."
          />
        </p>
        <ImportTable
          bulkActionCompletedAt={bulkActionCompletedAt}
          imported={true}
          onRequestStateChanged={onRequestStateChanged}
          onUsersCounted={whenUsersCounted}
        />
      </Box>
    </MaterialBox>
  );
};
