import { useMemo } from 'react';
import { AxiosRequestConfig } from 'axios';

import { useRequest, FetchResponse } from './useRequest';

export const useGet = <ResultType>(
  endpoint: string,
  params?: Record<string, unknown>,
  serializer?: (params: unknown) => string,
  disabled?: boolean,
): FetchResponse<ResultType> => {
  const config = useMemo<AxiosRequestConfig>(() => ({
    method: 'get',
    url: endpoint,
    params,
    paramsSerializer: serializer,
  }), [endpoint, params, serializer]);

  return useRequest(
    config,
    disabled,
  );
};
