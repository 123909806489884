import styled from '@emotion/styled';
import { ReactNode } from 'react';

export type FlexAlign = 'center' | 'flex-start' | 'flex-end' | 'stretch';

export type FlexProps = {
  align?: FlexAlign;
  direction?: 'column' | 'row';
  gap?: number;
  inline?: boolean;
  noWrap?: boolean;
  fullWidth?: boolean;
  children?: ReactNode;
};

export const Flex = styled.div<FlexProps>`
  display: ${ props => props.inline ? 'inline-flex' : 'flex' };
  align-items: ${ props => props.align || 'center' };
  ${ props => props.fullWidth && 'width: 100%' };
  flex-direction: ${ props => props.direction || 'row' };
  ${ props => props.gap && `gap: ${ props.theme.spacing(props.gap) };` }
  flex-wrap: ${ props => props.noWrap ? 'nowrap' : 'wrap' };
`;
