import { ComponentProps, FC, PropsWithChildren, ReactNode, useMemo, useState } from 'react';
import * as RadTooltip from '@radix-ui/react-tooltip';
import { useLongPress } from 'use-long-press';

type Props = {
  contents: ReactNode;
  light?: boolean;
  delay?: number;
  className?: string;
  side?: ComponentProps<typeof RadTooltip.Content>['side'];
  noBackdrop?: boolean;
}

export const Tooltip: FC<PropsWithChildren<Props>> = ({
  contents,
  light = false,
  delay = 800,
  side = 'bottom',
  className,
  noBackdrop,
  children,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const longPressBindings = useLongPress(() => setOpen(true));
  const noHover = useMemo(() => window.matchMedia('(hover: none)').matches, []);

  return (
    <RadTooltip.Provider delayDuration={ delay }>
      <RadTooltip.Root
        open={ open }
        onOpenChange={ setOpen }
      >
        <RadTooltip.Trigger
          asChild
          className={ className }
          {
          ...noHover
            ? {
              ...longPressBindings(),
              onContextMenu: (event) => { event.preventDefault(); return false },
            }
            : {}
        }
        >
          { children }
        </RadTooltip.Trigger>
        <RadTooltip.Portal>
          <RadTooltip.Content
            side={ side }
            css={ theme => ({
              zIndex: 999, // Appear in front of main navigation
              color: light ? theme.new.basePalette.white.main : theme.new.palette.grey[900].main,
              padding: `${ theme.new.spacing[2] } ${ theme.new.spacing[4] }`,
              ...noBackdrop
                ? { }
                : {
                  backgroundColor: 'currentcolor',
                  boxShadow: `${ theme.new.shadow.lg }, ${ theme.new.shadow.xl }`,
                  borderRadius: theme.new.borderRadius.standard,
                }
            }) }
          >
            <div
              css={ theme => ({
                color: light
                  ? theme.new.palette.grey[500].main
                  : theme.new.palette.grey[900].contrast,
              }) }
            >
              { contents }
            </div>
            { !noBackdrop && (
              <RadTooltip.Arrow
                css={ {
                  fill: 'currentcolor',
                  width: '10px',
                  height: '8px',
                } }
              />
            ) }
          </RadTooltip.Content>
        </RadTooltip.Portal>
      </RadTooltip.Root>
    </RadTooltip.Provider>
  );
}
