import { CircularProgress } from '@mui/material';
import { FunctionComponent } from 'react';

import { StyledCircularPercentage } from './style';

type Props = {
  value: number;
}

export const CircularPercentage: FunctionComponent<Props> = ({ value }) => {
  return (
    <StyledCircularPercentage>
      <CircularProgress variant="determinate" value={ 100 }/>
      <CircularProgress variant="determinate" value={ value }/>
    </StyledCircularPercentage>
  )
}
