import { ValidationTree, Validator } from '../../../Common/Model';
import { Validate } from '../../../Common/Utility';
import { ContentValidator } from '.';
import { DraftFormRequest, REQUEST_TEXT_MAX_LENGTH } from '../../Model';

export class RequestValidator {
  public static validateText = (request: DraftFormRequest): ValidationTree<string> => ({
    errors: textValidator.validate(request.text),
    children: {},
  });

  public static validate = (request: DraftFormRequest): ValidationTree<DraftFormRequest> => ({
    errors: [
      ...ContentValidator.validate(request).errors,
    ],
    children: {
      ...ContentValidator.validate(request).children,
      text: RequestValidator.validateText(request),
    },
  });
}

const textValidator = new Validator<string>([
  text => Validate.maxLength(text, REQUEST_TEXT_MAX_LENGTH),
]);
