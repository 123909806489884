import styled from '@emotion/styled';

export const StyledMessage = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: ${ props => props.theme.spacing(2) };
  padding: 0 ${ props => props.theme.spacing(2) };
  text-align: center;

  h3 {
    font-size: 1.3rem;
  }

  p {
    font-size: 1.15rem;
    margin: 0;
  }
`;
