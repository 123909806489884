import { ReactNode, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { ConfirmationDialog } from '@ourpeople/shared/Core/Component/Feedback';

import { ContextMenuAction } from '../../../Common/Component/ContextMenu/ContextMenu';
import { PopOverState } from '../../../Common/Model';
import { useApi, useContextOrThrow } from '../../../Core/Hook';
import { useMounted } from '../../../Common/Hook';
import { ToastContext } from '../../../Core/Context';
import DeleteIcon from '../../../Assets/img/icons/streamline/bin-1.svg';
import { BroadcastsPermission } from '../../Model';
import { usePermissions } from '../../../Security/Hook';

type ReturnValue = {
  deleteConfirmationDialog: ReactNode;
  deleteAction: ContextMenuAction;
  deleting: boolean;
};

export const useDeleteBroadcastAction = (
  broadcastId: string,
  resourceLockMessage: string | null,
  performingOtherAction: boolean,
  onReloadRequired: () => void,
): ReturnValue => {
  const intl = useIntl();
  const api = useApi();
  const [deleting, setDeleting] = useState<boolean>(false);
  const [deleteDialogState, setDeleteDialogState] = useState<PopOverState>(PopOverState.CLOSED);
  const mounted = useMounted();
  const { addSuccessToast, addErrorToast } = useContextOrThrow(ToastContext);
  const { guardedCallback } = usePermissions();

  const whenDeleteConfirmed = useCallback(() => {
    setDeleteDialogState(PopOverState.WILL_CLOSE);
    setDeleting(true);
    api.delete(`/broadcasts/${ broadcastId }`)
      .then(() => (
        setTimeout(() => {
          if (!mounted) {
            return;
          }

          addSuccessToast(
            intl.formatMessage({
              id: 'draftsTable.delete.success',
              description: 'Toast message when broadcast is deleted successfully.',
              defaultMessage: 'Broadcast archived successfully.',
            })
          );
          setDeleting(false);
          onReloadRequired();
        }, 50)
      ))
      .catch(() => {
        if (!mounted) {
          return;
        }

        addErrorToast(
          intl.formatMessage({
            id: 'draftsTable.delete.error',
            description: 'Toast message when broadcast is cannot be deleted.',
            defaultMessage: 'Broadcast could not be deleted.',
          })
        );
        setDeleting(false);
      });
  }, [api, broadcastId, mounted, addSuccessToast, intl, onReloadRequired, addErrorToast]);

  const deleteConfirmationDialog = useMemo(() => (
    <>
      { deleteDialogState !== PopOverState.CLOSED && (
        <ConfirmationDialog
          open={ deleteDialogState === PopOverState.OPEN }
          title={
            intl.formatMessage({
              id: 'draftsTable.delete.confirmTitle',
              defaultMessage: 'Delete broadcast',
            })
          }
          description={
            intl.formatMessage({
              id: 'draftsTable.delete.confirmDescription',
              defaultMessage: 'Do you really want to delete this broadcast?',
            })
          }
          onCancel={ () => setDeleteDialogState(PopOverState.WILL_CLOSE) }
          onClose={ () => setDeleteDialogState(PopOverState.WILL_CLOSE) }
          onConfirm={ whenDeleteConfirmed }
        />
      ) }
    </>
  ), [deleteDialogState, intl, whenDeleteConfirmed]);

  return useMemo(() => ({
    deleteAction: {
      id: 'delete',
      IconComponent: DeleteIcon,
      label: intl.formatMessage({
        description: 'Label for delete action in recent deliveries action menu.',
        defaultMessage: 'Delete',
      }),
      busy: deleting,
      ...(resourceLockMessage ? {
          disabled: true,
          disabledTooltip: resourceLockMessage,
        } : {
          disabled: performingOtherAction,
        }),
      onClick: guardedCallback(() => setDeleteDialogState(PopOverState.OPEN), [BroadcastsPermission.UPDATE]),
    },
    deleteConfirmationDialog,
    deleting,
  }), [deleteConfirmationDialog, deleting, guardedCallback, intl, performingOtherAction, resourceLockMessage]);
};
