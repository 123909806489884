import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { ComponentProps, FC, Ref, useCallback, useEffect, useState } from 'react';
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  FORMAT_TEXT_COMMAND,
  REDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  UNDO_COMMAND
} from 'lexical';
import { $wrapNodes } from '@lexical/selection';
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils';
import {
  $isListNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
  REMOVE_LIST_COMMAND
} from '@lexical/list';
import { $createHeadingNode, $isHeadingNode } from '@lexical/rich-text';
import { useIntl } from 'react-intl';

import { Flex } from '../../../../../../src/react/Common/Component/Flex/Flex';
import { BlockFormattingOptionSelect } from '../BlockFormattingOptionSelect/BlockFormattingOptionSelect';
import { BlockType, useLocalisedBlockFormattingOptions } from '../Hook/useLocalisedBlockFormattingOptions';
import { StyledDivider, StyledToolbarContainer } from './style';
import UndoIcon from '../../../../../../src/react/Assets/img/icons/streamline/undo.svg';
import RedoIcon from '../../../../../../src/react/Assets/img/icons/streamline/redo.svg';
import BoldIcon from '../../../../../../src/react/Assets/img/icons/streamline/text-bold.svg';
import ItalicIcon from '../../../../../../src/react/Assets/img/icons/streamline/text-italic.svg';
import { FlexPullRight } from '../../../../../../src/react/Common/Component/FlexPullRight/FlexPullRight';
import { ToolbarButton } from '../ToolbarButton/ToolbarButton';
import { ADD_PLACEHOLDER_COMMAND } from '../Plugins/PlaceholderNodePlugin';
import { RichTextPlaceholderDefinition } from '../../../../../../src/react/Common/Model/RichTextPlaceholderDefinition';
import { Editor } from '../Editor/Editor';
import { PlaceholderMenu } from '../../../../../../src/react/New/Common/Component/Content/PlaceholderMenu';
import { Form } from 'op-storybook/lib/components/Form/Form';

type Props = {
  availablePlaceholders: string[];
  mode?: ComponentProps<typeof Editor>['mode'];
  innerRef?: Ref<HTMLDivElement>;
};

export const ToolbarPlugin: FC<Props> = ({
  availablePlaceholders,
  mode,
  innerRef,
}) => {
  const [editor] = useLexicalComposerContext();
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const localisedBlockFormattingOptions = useLocalisedBlockFormattingOptions();
  const [blockType, setBlockType] = useState<BlockType>('paragraph');
  const intl = useIntl();
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);

  const whenBlockTypeChanged = useCallback((blockType: string) => {
    const supportedType = (
      localisedBlockFormattingOptions.find(option => option.id === blockType) ||
      localisedBlockFormattingOptions[0]
    );

    setBlockType(supportedType.id);
  }, [localisedBlockFormattingOptions]);

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element = anchorNode.getKey() === 'root'
        ? anchorNode
        : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);

      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          whenBlockTypeChanged(type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          whenBlockTypeChanged(type);
        }
      }

      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
    }
  }, [editor, whenBlockTypeChanged]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateToolbar();
          return false;
        },
        LOW_PRIORITY,
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        LOW_PRIORITY,
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return false;
        },
        LOW_PRIORITY,
      )
    );
  }, [editor, updateToolbar]);

  const formatParagraph = useCallback(() => {
    if (blockType !== 'paragraph') {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createParagraphNode());
        }
      });
      setBlockType('paragraph');
    }
  }, [blockType, editor]);

  const formatLargeHeading = useCallback(() => {
    if (blockType !== 'h1') {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode('h1'));
        }
      });
      setBlockType('h1');
    }
  }, [blockType, editor]);

  const formatSubHeading = useCallback(() => {
    if (blockType !== 'h2') {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode('h2'));
        }
      });
      setBlockType('h2');
    }
  }, [blockType, editor]);

  const formatBulletList = useCallback(() => {
    if (blockType === 'ul') {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
      setBlockType('paragraph');
    } else {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
      setBlockType('ul');
    }
  }, [blockType, editor]);

  const formatNumberedList = useCallback(() => {
    if (blockType === 'ol') {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
      setBlockType('paragraph');
    } else {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
      setBlockType('ol');
    }
  }, [blockType, editor]);

  const whenBlockTypeChange = useCallback((newBlockType: BlockType) => {
    if (newBlockType === 'h1') {
      formatLargeHeading();
      return;
    }

    if (newBlockType === 'h2') {
      formatSubHeading();
      return;
    }

    if (newBlockType === 'ol') {
      formatNumberedList();
      return;
    }

    if (newBlockType === 'ul') {
      formatBulletList();
      return;
    }

    formatParagraph();
  }, [formatBulletList, formatLargeHeading, formatNumberedList, formatParagraph, formatSubHeading]);

  const whenInsertPlaceholderClicked = useCallback((placeholder: RichTextPlaceholderDefinition) => {
    editor.dispatchCommand(ADD_PLACEHOLDER_COMMAND, placeholder);
  }, [editor]);

  return (
    <StyledToolbarContainer
      { ...innerRef ? { ref: innerRef } : {} }
    >
      <Form>
        <Flex gap={ 1 }>
          { mode === 'richText' && (
            <>
              <BlockFormattingOptionSelect
                value={ blockType }
                onChange={ whenBlockTypeChange }
              />
              <StyledDivider/>
              <Flex gap={ 1 } noWrap>
                <ToolbarButton
                  IconComponent={ BoldIcon }
                  label={ intl.formatMessage({
                    description: 'Label for bold button in rich text editor.',
                    defaultMessage: 'Bold',
                  }) }
                  onClick={ () => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
                  } }
                  active={ isBold }
                />
                <ToolbarButton
                  IconComponent={ ItalicIcon }
                  label={ intl.formatMessage({
                    description: 'Label for italic button in rich text editor.',
                    defaultMessage: 'Italic',
                  }) }
                  onClick={ () => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
                  } }
                  active={ isItalic }
                />
              </Flex>
            </>
          ) }
          <FlexPullRight gap={ 1 } noWrap>
            { !!availablePlaceholders.length && (
              <PlaceholderMenu
                availablePlaceholders={ availablePlaceholders }
                onPlaceholderClicked={ whenInsertPlaceholderClicked }
              />
            ) }
            <ToolbarButton
              IconComponent={ UndoIcon }
              label={ intl.formatMessage({
                description: 'Label for undo button in rich text editor.',
                defaultMessage: 'Undo',
              }) }
              onClick={ () => {
                editor.dispatchCommand(UNDO_COMMAND, undefined);
              } }
              disabled={ !canUndo }
            />
            <ToolbarButton
              IconComponent={ RedoIcon }
              label={ intl.formatMessage({
                description: 'Label for redo button in rich text editor.',
                defaultMessage: 'Redo',
              }) }
              onClick={ () => {
                editor.dispatchCommand(REDO_COMMAND, undefined);
              } }
              disabled={ !canRedo }
            />
          </FlexPullRight>
        </Flex>
      </Form>
    </StyledToolbarContainer>
  );
};

const LOW_PRIORITY = 1;
