import { ComponentProps, FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  ImageWithDefaultLoadingFacade
} from '@ourpeople/shared/Core/Component/Content/ImageWithDefaultLoadingFacade/ImageWithDefaultLoadingFacade';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { StyleBuilder } from 'op-storybook/lib/model/StyleBuilder/StyleBuilder';
import { Button } from 'op-storybook/stories/components/Button/Button';
import { IconButton } from 'op-storybook/stories/components/IconButton/IconButton';
import CropIcon from 'op-storybook/lib/assets/icon/figma/crop-01.svg';
import DeleteIcon from 'op-storybook/lib/assets/icon/figma/trash-01.svg';
import ChooseImageIcon from 'op-storybook/lib/assets/icon/figma/image-plus.svg';
import { Modal } from 'op-storybook/stories/components/Modal/Modal';
import { ProgressBar } from 'op-storybook/stories/components/ProgressBar/ProgressBar';
import { StackEnd } from 'op-storybook/lib/components/StackEnd/StackEnd';
import { PresentationIcon } from 'op-storybook/lib/components/PresentationIcon/PresentationIcon';
import { FormattedMessage, useIntl } from 'react-intl';
import { rgba } from 'polished';
import { Typography } from 'op-storybook/stories/components/Typography/Typography';
import { LineClamp } from 'op-storybook/stories/components/LineClamp/LineClamp';

import { Upload } from '../../../Types';
import { CropperWithControls } from '../CropperWithControls/CropperWithControls';
import { ImageAsset, useFileSelect, useFileUploader } from '../../Hook';
import { FileAndSrc } from '../../Model/FileAndSrc';
import { useContextOrThrow } from '../../../Core/Hook';
import { UnmodifiedImageSrcContext } from '../../Provider/UnmodifiedImageSrcProvider';
import { FileSizeFormatter } from '../../Model';
import { AddImageDialog } from '../AddImageDialog/AddImageDialog';
import { FileNameHelper } from '../../Utility/FileNameHelper';

type Props = {
  imageKey: string;
  upload: { uuid: string } | null;
  previewSize: 'md' | 'sm';
  uploadedFileName?: string;
  uploadedFileSizeInBytes?: number;
  mode: ComponentProps<typeof CropperWithControls>['mode'];
  outputDimensions: ComponentProps<typeof CropperWithControls>['outputDimensions'];
  onComplete: (upload: Upload) => void;
  authoriseEndpoint: string;
  onRemoveClicked?: () => void;
  cropRequired?: boolean;
  imageLibraryEnabled?: boolean;
};

export const ImageUploadField: FC<Props> = ({
  imageKey,
  upload,
  previewSize,
  uploadedFileName,
  uploadedFileSizeInBytes,
  mode,
  outputDimensions,
  onComplete,
  authoriseEndpoint,
  onRemoveClicked,
  cropRequired,
  imageLibraryEnabled,
}) => {
  const transformedUploadedSrc = upload
    ? `/api/uploads/${ upload.uuid }/download`
    : null;
  const intl = useIntl();
  const styles = useMemo(() => buildStyles({ previewSize }), [previewSize]);
  const { upload: startUpload, uploadStates, remove } = useFileUploader();
  const uploadState = useMemo(() => uploadStates.get(imageKey), [uploadStates]);
  const selectFile = useFileSelect('image/*');
  const { assign, get, revoke } = useContextOrThrow(UnmodifiedImageSrcContext);
  const [canvas, setCanvas] = useState<HTMLCanvasElement>();
  const [cropModalOpen, setCropModalOpen] = useState<boolean>(false);
  const [selectingFiles, setSelectingFiles] = useState<boolean>(false);
  const unmodifiedUploadedSrc = useMemo(() => get(imageKey), [get]);
  const [unmodifiedUnconfirmedFileAndSrc, setUnmodifiedUnconfirmedFileAndSrc] = useState<FileAndSrc>();
  const [unmodifiedConfirmedFileAndSrc, setUnmodifiedConfirmedFileAndSrc] = useState<FileAndSrc>();
  const [transforming, setTransforming] = useState<boolean>(false);
  const [transformedConfirmedFileAndSrc, setTransformedConfirmedFileAndSrc] = useState<FileAndSrc>();
  const previewSrc = transformedConfirmedFileAndSrc?.src || transformedUploadedSrc;
  const cropperSrc = unmodifiedUnconfirmedFileAndSrc?.src || unmodifiedUnconfirmedFileAndSrc?.src || unmodifiedUploadedSrc || transformedUploadedSrc;
  const progressFileName = uploadState?.file.name || uploadedFileName;
  const progressFileSizeInBytes = uploadState?.file.size || uploadedFileSizeInBytes;
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [uploadStarted, setUploadStarted] = useState(false);
  const [error, setError] = useState<boolean>();
  const [resolvePreviewImageUrl, setResolvePreviewImageUrl] = useState<(() => Promise<string>) | undefined>(undefined);

  useEffect(() => {
    if (!previewSrc) {
      return;
    }

    setResolvePreviewImageUrl(() => () => Promise.resolve(previewSrc));
  }, [previewSrc]);

  // Set state used to hide upload progress bar after a short delay.
  useEffect(() => {
    if (uploadState?.progress !== 1) {
      return;
    }

    const timeout = setTimeout(() => {
      setUploadComplete(true);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [uploadState]);

  // Handle completed upload
  useEffect(() => {
    if (!uploadState?.upload) {
      return;
    }

    if (unmodifiedConfirmedFileAndSrc) {
      assign(imageKey, unmodifiedConfirmedFileAndSrc.file);
    }

    if (!uploadStarted) {
      return;
    }

    onComplete(uploadState.upload);
  }, [uploadState?.upload, unmodifiedConfirmedFileAndSrc, uploadStarted]);

  useEffect(() => {
    if (!uploadState?.error) {
      return;
    }

    setError(true);
  }, [uploadState?.error]);

  const whenTransformComplete = useCallback(() => {
    if (!transformedConfirmedFileAndSrc) {
      return;
    }

    setError(false);
    setUploadStarted(true);
    setUploadComplete(false);
    startUpload(
      imageKey,
      authoriseEndpoint,
      transformedConfirmedFileAndSrc.file,
      transformedConfirmedFileAndSrc?.imageAsset
        ? { libraryRef: `unsplash:${ transformedConfirmedFileAndSrc.imageAsset.id }` }
        : upload?.uuid
          ? { editedUploadId: upload.uuid }
          : undefined
    );
  }, [imageKey, authoriseEndpoint, transformedConfirmedFileAndSrc]);

  // Upload transformed file once updated
  useEffect(() => {
    whenTransformComplete();
  }, [whenTransformComplete]);

  const whenFileSelected = useCallback((file: File, imageAsset?: ImageAsset) => {
    const selectedFileAndSrc = {
      file,
      src: URL.createObjectURL(file),
      ...(imageAsset ? { imageAsset } : {}),
    };
    setSelectingFiles(false);
    setUploadModalOpen(false);

    if (!cropRequired) {
      setUnmodifiedConfirmedFileAndSrc(selectedFileAndSrc);
      setTransformedConfirmedFileAndSrc(selectedFileAndSrc)
    }

    setUnmodifiedUnconfirmedFileAndSrc(selectedFileAndSrc);

    if (cropRequired) {
      setCropModalOpen(true);
    }
  }, [cropRequired]);

  const openFilePrompt = useCallback(() => {
    setSelectingFiles(true);
    try {
      selectFile(
        event => {
          const file = event.target.files?.[0];

          if (!file) {
            return;
          }

          whenFileSelected(file);
          setSelectingFiles(false);
        },
        () => {
          setSelectingFiles(false);
        }
      );
    } catch {
      setSelectingFiles(false);
    }
  }, []);

  const whenSelectFileClicked = useCallback(() => {
    if (imageLibraryEnabled) {
      setSelectingFiles(true);
      setUploadModalOpen(true);
      return;
    }

    openFilePrompt();
  }, [selectFile, imageLibraryEnabled, openFilePrompt]);

  const whenFileTransformed = useCallback((file: File) => {
    setTransformedConfirmedFileAndSrc({
      file,
      src: URL.createObjectURL(file),
    });
  }, []);

  const whenTransformConfirmed = useCallback(() => {
    if (!canvas) {
      return;
    }

    setTransforming(true);
    const validFileName = FileNameHelper.createValidFileNameForUpload('.jpeg', unmodifiedUnconfirmedFileAndSrc?.file.name || uploadedFileName);
    try {
      canvas.toBlob(
        blob => {
          if (!blob) {
            return;
          }

          const file = new File([blob], validFileName, { type: 'image/jpeg' });
          whenFileTransformed(file);
          setTransforming(false);
          setCropModalOpen(false);
        },
        'image/jpeg',
        0.7,
      );
    } catch {
      setTransforming(false);
    }
  }, [unmodifiedUnconfirmedFileAndSrc, canvas, whenFileTransformed, uploadedFileName]);

  const whenRemoveClicked = useCallback(() => {
    setUnmodifiedUnconfirmedFileAndSrc(undefined);
    setUnmodifiedConfirmedFileAndSrc(undefined);
    setTransformedConfirmedFileAndSrc(undefined);
    remove(imageKey);
    revoke(imageKey);

    onRemoveClicked && onRemoveClicked();
  }, [onRemoveClicked]);

  const selectImageLabel = intl.formatMessage({
    description: 'Label for choose image button in image upload fields.',
    defaultMessage: 'Choose image',
  });

  const buttons = useMemo(() => (
    <Stack align="flex-start">
      {
        cropperSrc
          ? (
            <>
              <IconButton
                variant="secondary"
                size="md"
                IconComponent={ ChooseImageIcon }
                label={ selectImageLabel }
                busy={ selectingFiles }
                onClick={ whenSelectFileClicked }
              />
              <IconButton
                variant="secondary"
                size="md"
                IconComponent={ CropIcon }
                label="Crop"
                onClick={ () => setCropModalOpen(true) }
              />
            </>
          )
          : (
            <Button
              variant="secondary"
              busy={ selectingFiles }
              onClick={ whenSelectFileClicked }
            >
              <Stack>
                <PresentationIcon
                  IconComponent={ ChooseImageIcon }
                />
                <span>
                  { selectImageLabel }
                </span>
              </Stack>
            </Button>
          )
      }
      { !!onRemoveClicked && !!previewSrc && (
        <IconButton
          variant="secondary"
          size="md"
          IconComponent={ DeleteIcon }
          label="Delete"
          onClick={ whenRemoveClicked }
        />
      ) }
    </Stack>
  ), [cropperSrc, selectingFiles, onRemoveClicked, previewSrc, selectImageLabel, whenSelectFileClicked]);

  return (
    <Stack align="flex-start">
      { resolvePreviewImageUrl && (
        <div css={ styles.previewContainer }>
          <ImageWithDefaultLoadingFacade
            fit="cover"
            resolveUrl={ resolvePreviewImageUrl }
          />
          <div css={ styles.previewOutline }/>
        </div>
      ) }
      <Stack
        direction="column"
        gap={ 0 }
        css={ { width: '100%' } }
      >
        <Stack
          fillParent
          align="flex-start"
        >
          { (progressFileName || progressFileSizeInBytes) && (
            <Stack direction="column" gap={ 0 }>
              { progressFileName && (
                <LineClamp
                  lines={ 2 }
                  wordBreak="break-all"
                >
                  <span>{ progressFileName }</span>
                </LineClamp>
              ) }
              { progressFileSizeInBytes && (
                <Typography
                  palette={ {
                    colour: 'grey',
                    intensity: 400,
                  } }
                  size="sm"
                  weight="regular"
                >
                  { FileSizeFormatter.format(progressFileSizeInBytes) }
                </Typography>
              ) }
            </Stack>
          ) }
          {
            previewSrc
              ? (
                <StackEnd
                  css={ theme => ({
                    marginBottom: theme.new.spacing[1],
                  }) }
                >
                  { buttons }
                </StackEnd>
              )
              : buttons
          }
        </Stack>
        { uploadStarted && !error && (
          <ProgressBar
            key={ previewSrc }
            css={ {
              transition: 'opacity 200ms',
              ...(uploadComplete ? { opacity: 0 } : {}),
            } }
            progress={ uploadState?.progress || 0 }
            showNumericValue
          />
        ) }
        { error && (
          <Stack
            align="flex-end"
          >
            <Typography
              palette={ {
                colour: 'error',
                intensity: 600,
              } }
              size="sm"
            >
              <FormattedMessage
                description="Error message when upload fails in image upload field."
                defaultMessage="Upload failed"
              />
            </Typography>
            <Button
              variant="secondary"
              attention
              onClick={ whenTransformComplete }
            >
              <FormattedMessage
                description="Label for button to retry failed upload in image upload field."
                defaultMessage="Try again"
              />
            </Button>
          </Stack>
        ) }
      </Stack>
      { cropperSrc && (
        <Modal
          open={ cropModalOpen }
          onClose={ () => setCropModalOpen(false) }
          title={ intl.formatMessage({
            description: 'Title for edit image modal in image upload field.',
            defaultMessage: 'Edit image',
          }) }
          onExited={ () => {
            if (cropRequired && !transformedConfirmedFileAndSrc) {
              setUnmodifiedConfirmedFileAndSrc(undefined);
              setUnmodifiedUnconfirmedFileAndSrc(undefined);
            }
          } }
          footerContent={
            <>
              <Button
                variant="secondary"
                onClick={ () => setCropModalOpen(false) }
                busy={ transforming }
              >
                <FormattedMessage
                  description="Label for cancel crop button in image upload field"
                  defaultMessage="Cancel"
                />
              </Button>
              <Button
                onClick={ whenTransformConfirmed }
                busy={ transforming }
              >
                <FormattedMessage
                  description="Label for confirm crop button in image upload field"
                  defaultMessage="Confirm"
                />
              </Button>
            </>
          }
        >
          <CropperWithControls
            src={ cropperSrc }
            mode={ mode }
            outputDimensions={ outputDimensions }
            onChange={ setCanvas }
          />
        </Modal>
      ) }
      { !!imageLibraryEnabled && (
        <AddImageDialog
          open={ uploadModalOpen }
          onCancel={ () => {
            setUploadModalOpen(false);
            setSelectingFiles(false);
          } }
          onConfirm={ whenFileSelected }
          uploadState={ uploadState }
          onReset={ () => null }
          onSelect={ whenFileSelected }
        />
      ) }
    </Stack>
  );
};

const buildStyles: StyleBuilder<{ previewSize: Props['previewSize'] }> = ({ previewSize }) => {
  const previewDimensions = previewSize === 'md' ? '100px' : '80px';

  return {
    previewContainer: theme => ({
      flexShrink: 0,
      width: previewDimensions,
      height: previewDimensions,
      position: 'relative',
      borderRadius: theme.new.borderRadius.standard,
      overflow: 'hidden',
    }),
    previewOutline: theme => ({
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: theme.new.borderRadius.standard,
      border: `1px solid ${ rgba(theme.new.palette.grey[600].main, 0.1) }`,
    }),
  };
};

