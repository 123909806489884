import { BroadcastNotificationSettings } from '../Model';
import { EnvironmentSettings } from '../../Models';

export class BroadcastNotificationSettingsFactory {
  public static fromSpaceSettings = (
    {
      broadcastReceivedNotificationEmailDefaultSend = false,
      broadcastReceivedNotificationSmsDefaultSend = false,
    }: EnvironmentSettings,
  ): BroadcastNotificationSettings => {
    return {
      push: {
        send: true,
      },
      sms: {
        send: broadcastReceivedNotificationSmsDefaultSend,
      },
      email: {
        send: broadcastReceivedNotificationEmailDefaultSend,
      },
    };
  };
}
