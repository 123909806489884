import styled from '@emotion/styled';

export const StyledCoverExport = styled.div`
  display: flex;
  margin-left: auto;

  .MuiInputBase-root {
    background-color: ${ props => props.theme.palette.background.paper };
  }

  > * {
    margin-left: 1rem;
  }
`;
