import * as React from 'react';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';

import { SearchInput } from '../SearchInput/SearchInput';
import { useDebounce } from '../../../Hook/useDebounce';

interface Props {
  onChange: (value: string) => void;
  value?: string;
  placeholder?: string;
  autofocus?: boolean;
  fullWidth?: boolean;
  delay?: number;
}

export const DebouncedSearchInput: FunctionComponent<Props> = ({
  onChange,
  value= '',
  placeholder,
  autofocus = false,
  fullWidth = false,
  delay = 250,
}) => {
  const [localValue, setLocalValue] = useState<string>(value);
  const debouncedValue = useDebounce(localValue, delay);
  const [awaitingDebouncedValue, setAwaitingDebouncedValue] = useState<boolean>(false);

  useEffect(() => {
    if (debouncedValue !== value) {
      if (awaitingDebouncedValue) {
        setAwaitingDebouncedValue(false);
        onChange(debouncedValue);
      } else {
        setLocalValue(value);
      }
    }
  }, [awaitingDebouncedValue, debouncedValue, onChange, value]);

  const whenChanged = useCallback((value: string) => {
    setAwaitingDebouncedValue(true);
    setLocalValue(value);
  }, [setLocalValue]);

  return (
    <SearchInput
      value={ localValue }
      onChange={ whenChanged }
      onClear={ () => whenChanged('') }
      placeholder={ placeholder }
      fullWidth={ fullWidth }
      autofocus={ autofocus }
    />
  );
};
