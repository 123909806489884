import { PropsWithChildren, createContext, useMemo, useState, ReactNode } from 'react';

import { Refresh } from '../../../Hooks';
import { PaginatedTable } from '../../../Components';
import {
  FetchReportRecipientsParams,
  useFetchReportRecipients
} from '../../../Sections/Broadcasts/Report/Hooks/useFetchReportRecipients';
import { MinimalPerson } from '../../../Models';
import { StyledResponseTable } from './style';

export const RefreshContext = createContext<Refresh|null>(null);

export interface ReportRecipient<MetadataType extends Record<string, unknown>> extends Record<string, unknown> {
  id: string;
  user: MinimalPerson;
  date: string | null;
  relativeStatus: 'inactive' | 'active' | 'frozen';
  metadata: MetadataType;
  broadcastReceivedNotifications: ReportRecipientNotification[];
}

export interface ReportRecipientNotification {
  service: string;
  status: 'unknown' | 'pending' | 'submitted' | 'delivered' | 'failed' | 'rejected' | 'error';
  statusReason: null|string;
}

interface Props<MetadataType extends Record<string, unknown>> {
  broadcastId: string;
  rowRender: (recipient: ReportRecipient<MetadataType>) => ReactNode;
  headerRow: ReactNode;
  reportRecipientsParams: FetchReportRecipientsParams;
}

export const ResponseTable = <MetadataType extends Record<string, unknown>>({
  broadcastId,
  rowRender,
  headerRow,
  reportRecipientsParams,
}: PropsWithChildren<Props<MetadataType>>): JSX.Element => {
  const [pageNum, setPageNum] = useState<number>(1);
  const [fetchReportRecipientsResult, fetchState, reload] = useFetchReportRecipients<MetadataType>(
    broadcastId,
    pageNum,
    reportRecipientsParams,
  );
  const recipients = useMemo(
    () => fetchReportRecipientsResult?.content?.items || [],
    [fetchReportRecipientsResult?.content?.items],
  );
  const pagination = useMemo(
    () => fetchReportRecipientsResult?.content?.pagination,
    [fetchReportRecipientsResult?.content?.pagination],
  );

  return (
    <RefreshContext.Provider value={reload}>
      <StyledResponseTable>
        <PaginatedTable
          requestState={fetchState}
          headerRow={headerRow}
          onPageChanged={setPageNum}
          pageNum={pageNum}
          pagination={pagination}
          rowRender={rowRender}
          rows={recipients}
          onRetryClicked={reload}
        />
      </StyledResponseTable>
    </RefreshContext.Provider>
  );
};
