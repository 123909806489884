import { FunctionComponent } from 'react';
import { Button, ButtonProps, CircularProgress } from '@mui/material';

import { StyledLoadingButton, ProgressContainer } from './style';

interface Props extends ButtonProps {
  busy: boolean;
}

/** @deprecated prefer Button with busy prop */
export const LoadingButton: FunctionComponent<Props> = ({
  busy,
  className,
  children,
  ...props
}) => (
  <StyledLoadingButton className={ className }>
    <Button
      { ...props }
      disabled={ busy || props.disabled }
    >
      { children }
    </Button>
    { busy && (
      <ProgressContainer>
        <CircularProgress size={ 24 }/>
      </ProgressContainer>
    ) }
  </StyledLoadingButton>
);
