import { FunctionComponent, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Heading } from '@ourpeople/shared/Core/Component/Content';

import { Content, ContentType, ContentWithMedia } from '../../../../../Models';
import { ContentReader } from '../../../../../Readers';
import { FileDownloadButton } from '../../../../../Components';

interface Props {
  content: Content;
}

export const FileContentPreview: FunctionComponent<Props> = ({
  content
}) => {
  const intl = useIntl();

  const viewMediaMessageForContentType = useCallback((contentType: ContentType) => {
    switch (contentType) {
      case 'file':
        return intl.formatMessage({
          id: 'broadcast.report.viewFile',
          defaultMessage: 'View File',
        });
      case 'audio':
        return intl.formatMessage({
          id: 'broadcast.report.viewAudio',
          defaultMessage: 'Listen',
        });
      case 'video':
        return intl.formatMessage({
          id: 'broadcast.report.viewVideo',
          defaultMessage: 'Watch',
        });
      default:
        return intl.formatMessage({
          id: 'broadcast.report.downloadMedia',
          defaultMessage: 'Download Media',
        });
    }
  }, [intl]);

  return (
    <div>
      <Heading type="h3">{ContentReader.titleForContent(content) || ''}</Heading>
      {
        (content as ContentWithMedia).media && (
          <FileDownloadButton
            buttonProps={{
              color: 'secondary'
            }}
            uploadId={(content as ContentWithMedia).media.id}
            >{
              viewMediaMessageForContentType(
                ContentReader.contentTypeFromString(content.type)
              )
            }</FileDownloadButton>
        )
      }
    </div>
  );
};
