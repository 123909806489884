import { useIntl } from 'react-intl';

import { ContentType } from '../../../../Models';

export const useRespondedLabelForContentType = (contentType: ContentType): string => {
  const intl = useIntl();
  switch (contentType) {
    case 'image':
    case 'file':
    case 'styled_image':
      return intl.formatMessage({
        id: 'contentReport.respondedTab.fileContent.label',
        description: 'Label for file/image content responded tab in content report summary',
        defaultMessage: 'Viewed'
      });
    case 'audio':
      return intl.formatMessage({
        id: 'contentReport.respondedTab.audioContent.label',
        description: 'Label for audio content responded tab in content report summary',
        defaultMessage: 'Listened'
      });
    case 'video':
      return intl.formatMessage({
        id: 'contentReport.respondedTab.videoContent.label',
        description: 'Label for video content responded tab in content report summary',
        defaultMessage: 'Watched'
      });
    case 'text':
      return intl.formatMessage({
        id: 'contentReport.respondedTab.textContent.label',
        description: 'Label for text content responded tab in content report summary',
        defaultMessage: 'Read'
      });
    case 'sign':
      return intl.formatMessage({
        id: 'contentReport.respondedTab.signContent.label',
        description: 'Label for sign content responded tab in content report summary',
        defaultMessage: 'Signed'
      });
    default:
      return intl.formatMessage({
        id: 'contentReport.respondedTab.label',
        description: 'Default label for responded tab in content report summary',
        defaultMessage: 'Responded'
      });
  }
};
