import { FC, useCallback, useEffect, useState } from 'react';
import { ApiRequest } from '@ourpeople/shared/Core/Model/ApiRequest';

import { TextBroadcastContent } from '../../../../Content/Component';
import { ReadResponse } from '../../../Model';
import { useRecallErrorHandler, useRespondButtonProps } from '../../../Hook';
import { RequestState } from '../../../../Models';
import { TextContent } from '../../../../Broadcasts/Service';
import { BroadcastContentContext } from '../../../../Broadcasts/Model';
import { LiveComponentProps } from '../../../../Content/Model';
import { FileEntry } from '../../../../Files/Model/FileEntry';
import { useLinkProps } from '../../../../Content/Hook/useLinkProps';
import { ErrorResponseReader } from '../../../../Common/Utility';
import { useContextOrThrow } from '../../../../Core/Hook';
import { ApiContext } from '../../../../Contexts';
import { useMounted } from '../../../../Common/Hook';

export const LiveTextContent: FC<LiveComponentProps<TextContent, ReadResponse, BroadcastContentContext>> = ({
  card: { content },
  response,
  context,
  onReloadRequired,
  onResponseChange,
}) => {
  const api = useContextOrThrow(ApiContext);
  const markAsReadProps = useRespondButtonProps({
      contentId: content.id.toString(),
      deliveryId: context.deliveryId,
      type: 'read',
    },
    onResponseChange,
    useRecallErrorHandler(onReloadRequired),
    response,
  );
  const [fetchFileEntryResult, setFetchFileEntryResult] = useState<ApiRequest<FileEntry>>({
    result: null,
    state: RequestState.PENDING,
  });
  const linkProps = useLinkProps(
    content.link,
    content.link?.type === 'fileEntry' && fetchFileEntryResult.state < RequestState.COMPLETE,
    fetchFileEntryResult.state === RequestState.FAILED && ErrorResponseReader.isApiError(fetchFileEntryResult.result) && fetchFileEntryResult.result.response.status === 403,
    fetchFileEntryResult.state === RequestState.COMPLETE ? fetchFileEntryResult.result : null,
    false,
  );
  const mounted = useMounted();

  const fetchFileEntry = useCallback((fileId: string) => {
    setFetchFileEntryResult({
      result: null,
      state: RequestState.FETCHING,
    });
    api.get<FileEntry>(`/files/${ fileId }`)
      .then(response => response.data)
      .then(fileEntry => {
        if (!mounted.current) {
          return;
        }

        setFetchFileEntryResult({
          result: fileEntry,
          state: RequestState.COMPLETE,
        });
      })
      .catch(error => {
        if (!mounted.current) {
          return;
        }

        setFetchFileEntryResult({
          result: error,
          state: RequestState.FAILED,
        });
      });
  }, [api, mounted]);

  useEffect(() => {
    if (content.link?.type !== 'fileEntry' || !content.link?.fileEntry?.id) {
      return;
    }

    fetchFileEntry(content.link.fileEntry.id);
  }, [content.link?.fileEntry?.id, content.link?.type, fetchFileEntry]);

  return (
    <TextBroadcastContent
      backButton={ context.closeButton }
      html={ content.text }
      placeholderText={ content.link?.placeholderText }
      linkProps={ linkProps }
      markAsReadProps={ markAsReadProps }
      navigationProps={ context.navigationProps }
      responded={ !!response }
      hideRespondButton={ context.contentCount > 1 }
      nudge={ context.nudge }
    />
  );
};
