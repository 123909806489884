import { FunctionComponent, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { SortableHeaderCell, TableCell, TableRow } from '../../../../../Components';
import { DateTime, FilterContainer, FilterRow } from '../../../../../Common/Component';
import { StyledResponseTable } from '../styles';
import { StandardReportTableInput } from './StandardReportTableInput';
import {
  RecipientNotifications
} from '../../../../../Broadcasts/Component/Reporting/RecipientNotifications/RecipientNotifications';
import {
  NotificationMethodFilter,
  NotificationMethodFilterValue
} from '../../../../../Broadcasts/Component/Reporting/NotificationMethodFilter/NotificationMethodFilter';
import { ReportRecipient, ResponseTable } from '../../../../../Broadcasts/Component';

export type ResponseType = 'seen' | 'responded';
export type RespondedType = 'attended' | 'attending' | 'waiting' | 'declined';
export interface RespondedTableProps {
  broadcastId: string;
  responseType: ResponseType;
  respondedType?: RespondedType;
  deliveryId?: string;
  contentId?: string;
  optionId?: string;
  rating?: number;
  menuRender?: (recipient: ReportRecipient<Record<string, unknown>>) => JSX.Element;
  initialSearch?: string;
}

export const RespondedTable: FunctionComponent<RespondedTableProps> = ({
  broadcastId,
  responseType,
  respondedType,
  deliveryId,
  contentId,
  optionId,
  rating,
  menuRender,
  initialSearch = '',
}) => {
  const [sort, setSort] = useState<string>('firstNameAsc');
  const [search, setSearch] = useState<string>(initialSearch);
  const [notificationMethodFilter, setNotificationMethodFilter] = useState<NotificationMethodFilterValue>(null);
  const composedResponseType = useMemo(() => (
    responseType === 'responded' && respondedType !== undefined
    ? `${responseType}.${respondedType}`
    : responseType
  ), [responseType, respondedType]);
  const params = useMemo(() => ({
    deliveryId: deliveryId || null,
    contentId: contentId || null,
    optionId: optionId || null,
    rating: rating || null,
    responseType: composedResponseType,
    sort,
    relativeStatus: 'active,inactive,frozen',
    broadcastReceivedNotificationMethods: notificationMethodFilter,
    search: search || null,
  }), [contentId, search, deliveryId, optionId, rating, composedResponseType, sort, notificationMethodFilter]);

  return (
    <StyledResponseTable>
      <FilterContainer>
        <FilterRow>
          <NotificationMethodFilter value={notificationMethodFilter} onChanged={setNotificationMethodFilter} />
          <StandardReportTableInput
            searchValue={search}
            onSearchChanged={setSearch}
          />
        </FilterRow>
      </FilterContainer>
      <ResponseTable
        reportRecipientsParams={params}
        broadcastId={ broadcastId }
        headerRow={
          <TableRow>
            <SortableHeaderCell
              sort={sort}
              ascValue="firstNameAsc"
              descValue="firstNameDesc"
              onSort={setSort}
            >
              <FormattedMessage
                id="broadcastReport.recipientsTable.headers.firstName"
                defaultMessage="First name"
                />
            </SortableHeaderCell>
            <SortableHeaderCell
              sort={sort}
              ascValue="lastNameAsc"
              descValue="lastNameDesc"
              onSort={setSort}
            >
              <FormattedMessage
                id="broadcastReport.recipientsTable.headers.lastName"
                defaultMessage="Last name"
                />
            </SortableHeaderCell>
            <SortableHeaderCell
              sort={sort}
              ascValue="dateAsc"
              descValue="dateDesc"
              onSort={setSort}
            >
              <FormattedMessage
                id="broadcastReport.recipientsTable.headers.date"
                defaultMessage="Date"
                />
            </SortableHeaderCell>
            <TableCell>
              <FormattedMessage
                id="broadcastReport.recipientsTable.headers.notifications"
                defaultMessage="Notification channel"
              />
            </TableCell>
            { menuRender && <TableCell/> }
          </TableRow>
        }
        rowRender={ (recipient: ReportRecipient<Record<string, unknown>>) => (
          <TableRow key={ recipient.id }>
            <TableCell>
              <Link to={`/people/${ recipient.user.id }/account`}>
                { recipient.user.firstName }
              </Link>
            </TableCell>
            <TableCell>
              <Link to={`/people/${ recipient.user.id }/account`}>
                { recipient.user.lastName }
              </Link>
            </TableCell>
            <TableCell>
              <DateTime dateTime={ recipient.date }/>
            </TableCell>
            <TableCell>
              <RecipientNotifications notifications={recipient.broadcastReceivedNotifications} />
            </TableCell>
            {
              menuRender && <TableCell>{menuRender(recipient)}</TableCell>
            }
          </TableRow>
        ) }
      />
    </StyledResponseTable>
  );
}
