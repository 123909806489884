import styled from '@emotion/styled';

import { ValidationErrorMessage } from '../../../../Common/Component';

export const StyledFileName = styled.div`
  margin: 0;
  line-height: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const StyledFilePath = styled.div`
  font-size: 0.8rem;
  color: ${ props => props.theme.palette.grey[500] };
  -webkit-line-clamp: 2;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
`;

export const StyledFileNameRow = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: ${ props => props.theme.spacing(1) };
  align-items: flex-end;
`;

export const StyledInlineButton = styled.button`
  display: inline;
  border: none;
  font-size: inherit;
  background: none;
  padding: 0;
  color: ${ props => props.theme.palette.primary.main };
  margin-left: auto;
  flex-shrink: 0;
`;

export const StyledErrorMessage = styled(ValidationErrorMessage)`
  display: flex;
  gap: ${ props => props.theme.spacing(4) };
  align-items: flex-start;
`;
