import { FC } from 'react';
import { Typography } from '@ourpeople/shared/Core/Component/Content/Typography/Typography';
import { NavItem } from '@ourpeople/shared/Core/Component/Navigation/NavItem/NavItem';

import { StyledNavGroup, StyledNavGroupChildren } from './style';
import { NavGroup } from '../NavItemGroup/NavItemGroup';

type Props = {
  navGroup: NavGroup;
};

export const SubMenuNavGroup: FC<Props> = ({
  navGroup,
}) => (
  <StyledNavGroup gap={ 3 } direction="column">
    <Typography
      size="lg"
      weight="semibold"
      color="teal.100.main"
    >
      { navGroup.label }
    </Typography>
    <StyledNavGroupChildren>
      { navGroup.children.map(child => (
        <li key={ child.id }>
          <NavItem { ...child } inset={ false }/>
        </li>
      )) }
    </StyledNavGroupChildren>
  </StyledNavGroup>
);
