import { JSX, useCallback, useMemo, useState } from 'react';
import { SearchField } from '@ourpeople/shared/Core/Component/Input/SearchField/SearchField';
import { DropdownItemCheck } from 'op-storybook/stories/components/DropdownItemCheck/DropdownItemCheck';

import { RoleCondition } from '../../../../Audiences/Model';
import { useLocalisedRoleNames } from '../../../../Security/Hook';
import { Role } from '../../../../Security/Model';
import { StandardListItem } from '../../../Common/Component/Content/StandardListItem';
import { MinimumInset } from '../../../Common/Component/Layout/MinimumInset';

type Props = {
  condition: RoleCondition;
  onChange: (condition: RoleCondition) => void;
};

export const RoleList = ({
  condition,
  onChange,
}: Props): JSX.Element => {
  const [search, setSearch] = useState<string>('');
  const filters = useMemo(() => ({
    ...search ? { search } : {},
  }), [search]);
  const roleNames = useLocalisedRoleNames();
  const filteredRoles = useMemo(() => {
    return roleNames.nonInternalRoles.filter(role => (
      !filters.search || role.name.toUpperCase().includes(filters.search.toUpperCase())
    ));
  }, [roleNames, filters]);

  const whenRoleClicked = useCallback((role: Role) => {
    const selected = condition.roleIds.includes(role.id);
    onChange({
      ...condition,
      roleIds: selected
        ? condition.roleIds.filter(roleId => role.id !== roleId)
        : condition.roleIds.concat(role.id),
    })
  }, [condition, onChange]);

  const renderItem = useCallback((role: Role) => {
    return (
      <StandardListItem
        key={ role.id }
        text={ role.name }
        onClick={ event => {
          event.stopPropagation();
          event.preventDefault();
          whenRoleClicked(role);
        } }
        selected={ condition.roleIds.includes(role.id) }
        { ...condition.roleIds.includes(role.id) ? { endAdornment: <DropdownItemCheck/> } : {} }
      />
    )
  }, [condition.roleIds, whenRoleClicked]);

  return (
    <>
      <MinimumInset
        top={ 2 }
        right={ 1 }
        bottom={ 2 }
        left={ 1 }
      >
        <SearchField
          value={ filters.search || '' }
          onChange={ setSearch }
        />
      </MinimumInset>
      <ul
        css={ {
          margin: 0,
          padding: 0,
          listStyle: 'none',
        } }
      >
        { filteredRoles.map(renderItem) }
      </ul>
    </>
  );
};
