import { TextField } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { IntlShape } from 'react-intl/src/types';

import { MappedSettingTypes } from '../../Types';
import { Flex, ValidationErrorMessage } from '../../../../../Common/Component';
import { StyledCharacterCountContainer } from './HtmlSettingInput/style';
import { CharacterCount } from '../../../../../Content/Component';
import { RichTextSanitiser } from '../../../../../Common/Utility';

type Props = {
  config: MappedSettingTypes['text']['config'],
  value: MappedSettingTypes['text']['value'],
  onValueChanged: (value: MappedSettingTypes['text']['value']) => void,
  label?: string,
  focusId?: string,
};

export const TextSettingInput = ({
  config,
  value,
  onValueChanged,
  label,
  focusId,
}: Props): JSX.Element => {
  const intl = useIntl();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const error = validate(value, config, intl);
    setError(error);
  }, [value, config, intl]);

  const whenValueChanged = (event: ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    onValueChanged(value);
  }

  return (
    <div>
      { config.maxLength && (
        <Flex>
          <StyledCharacterCountContainer>
            <CharacterCount
              { ...config.minLength ? { minimum: config.minLength } : {} }
              maximum={ config.maxLength }
              current={ RichTextSanitiser.stripTags(value || '').length }
            />
          </StyledCharacterCountContainer>
        </Flex>
      ) }
      {
        config.multiline
          ? (
            <TextField
              id={ focusId }
              multiline
              rows="5"
              variant="outlined"
              fullWidth={ true }
              error={ error !== null }
              value={ value || '' }
              label={ label }
              onChange={ whenValueChanged }
              inputProps={ {
                minLength: config.minLength,
                maxLength: config.maxLength,
              } }
            />
          )
          : (
            <TextField
              id={ focusId }
              variant="outlined"
              fullWidth={ true }
              error={ error !== null }
              value={ value || '' }
              label={ label }
              onChange={ whenValueChanged }
              inputProps={ {
                minLength: config.minLength,
                maxLength: config.maxLength,
              } }
            />
          )
      }
      { error
        && (
          <ValidationErrorMessage>{ error }</ValidationErrorMessage>
        ) }
    </div>
  );
};

const validate = (
  value: MappedSettingTypes['text']['value'],
  config: MappedSettingTypes['text']['config'],
  intl: IntlShape,
): string | null => {
  if (value === null && config.nullable) {
    return null;
  }

  if (value === null) {
    return intl.formatMessage({
      id: 'settings.textSetting.nullError',
      defaultMessage: 'Missing value',
    });
  }

  if (
    config.minLength !== null
    && value.length < config.minLength
  ) {
    return intl.formatMessage(
      {
        id: 'settings.textSetting.minLengthError',
        defaultMessage: 'Must be no less than {minLength} characters',
      },
      {
        minLength: config.minLength,
      }
    );
  }

  if (
    config.maxLength !== null
    && value.length > config.maxLength
  ) {
    return intl.formatMessage(
      {
        id: 'settings.textSetting.maxLengthError',
        defaultMessage: 'Must be no more than {maxLength} characters',
      },
      {
        maxLength: config.maxLength,
      }
    );
  }

  return null;
}
