import { Dispatch, FunctionComponent, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@mui/material';
import { AutocompleteSelectionChanged } from '@ourpeople/shared/Core/Component/Input/Autocomplete/Autocomplete';
import { DebouncedSearchInput } from '@ourpeople/shared/Core/Component/Input/DebouncedSearchInput/DebouncedSearchInput';
import { LocalisedString } from 'op-storybook/lib/model/LocalisedString/LocalisedString';

import { Tag } from '../../../Tags/Model';
import {
  FilterContainer,
  FilterRow,
  Flex,
  FlexPullRight,
  SelectionDescription,
  StyledChip
} from '../../../Common/Component';
import { TeamAutocomplete } from '../../../Tags/Component';
import { PersonStatusAutocomplete, ProfileFilter, ProfileFilterOption, RoleAutocomplete } from '..';
import { Role, RoleId } from '../../../Security/Model';
import { StyledAutocompleteFilter } from './styles';
import { PersonStatus } from '../../../Models';

type Props = {
  filterSelection: PeopleTableFilterSelection,
  setFilterSelection: Dispatch<SetStateAction<PeopleTableFilterSelection>>,
  onClearFiltersClicked: () => void;
  actions: JSX.Element;
  showActions: boolean;
  selectedPersonCount: number;
  totalPersonCount: number;
  onClearSelectionClicked: () => void;
  onExpandSelectionClicked: () => void;
  currentPageSize: number;
}

enum PeopleFilterType {
  PROFILES,
  ROLE,
  STATUS,
  TEAM,
}

export interface PeopleTableFilterSelection {
  search: string;
  teamIds: string[];
  roleIds: string[];
  statusIds: PersonStatus[];
  profiles: string;
}

interface Chip {
  type: PeopleFilterType;
  value: string;
  label: string;
}

const allowedRoleIds: RoleId[] = [
  'ROLE_ADMIN',
  'ROLE_SUPER_ADMIN',
  'ROLE_BROADCAST_ADMIN',
  'ROLE_APP_ADMIN',
  'ROLE_APP_BROADCAST',
];

const allowedStatusIds: PersonStatus[] = [
  'active',
  'inactive',
  'added',
  'pending',
  'frozen',
];

export const PeopleTableFilters: FunctionComponent<Props> = ({
  filterSelection,
  setFilterSelection,
  onClearFiltersClicked,
  showActions,
  actions,
  selectedPersonCount,
  totalPersonCount,
  onClearSelectionClicked,
  onExpandSelectionClicked,
  currentPageSize,
}) => {
  const intl = useIntl();
  const [chips, setChips] = useState<Chip[]>([]);
  const search = useMemo(() => filterSelection.search, [filterSelection.search]);

  useEffect(
    () => {
      setChips(
        (chips) => chips.filter((chip) => chipAppearsInSelection(chip, filterSelection))
      );
    },
    [filterSelection],
  );

  const whenRolesChanged: AutocompleteSelectionChanged<Role> = (roleSelection) => {
    setChips((chips) => ([
      ...chips
        .filter((chip) => chip.type !== PeopleFilterType.ROLE),
      ...roleSelection.options
        .map((option) => ({
          type: PeopleFilterType.ROLE,
          value: option.id,
          label: intl.formatMessage(
            {
              id: 'people.filter.role',
              defaultMessage: 'Role: {role}',
            },
            {
              role: option.name,
            },
          ),
        })),
    ]));

    const selectedRoleIds = roleSelection.options.map((option) => option.id);

    setFilterSelection(filterSelection => ({
      ...filterSelection,
      roleIds: [
        ...selectedRoleIds,
        ...(
          selectedRoleIds.indexOf('ROLE_SUPER_ADMIN') > -1
            ? ['ROLE_DEVELOPER']
            : []
        ),
      ],
    }));
  };

  const whenStatusesChanged = (statusSelection: LocalisedString<PersonStatus>[]) => {
    setChips((chips) => ([
      ...chips
        .filter((chip) => chip.type !== PeopleFilterType.STATUS),
      ...statusSelection
        .map((option) => ({
          type: PeopleFilterType.STATUS,
          value: option.id,
          label: intl.formatMessage(
            {
              id: 'people.filter.status',
              defaultMessage: 'Status: {status}',
            },
            {
              status: option.localisation,
            },
          ),
        })),
    ]));

    setFilterSelection(filterSelection => ({
      ...filterSelection,
      statusIds: statusSelection.map(option => option.id),
    }));
  };

  const whenTeamsChanged: AutocompleteSelectionChanged<Tag<'team'>> = (teamSelection) => {
    setChips((chips) => ([
      ...chips
        .filter((chip) => chip.type !== PeopleFilterType.TEAM),
      ...teamSelection.options
        .map((option) => ({
          type: PeopleFilterType.TEAM,
          value: option.id,
          label: intl.formatMessage(
            {
              id: 'people.filter.team',
              defaultMessage: 'Team: {team}'
            },
            {
              team: option.name,
            },
          ),
        })),
    ]));
    setFilterSelection(filterSelection => ({
      ...filterSelection,
      teamIds: teamSelection.options.map((option) => option.id),
    }));
  };

  const whenProfilesChanged = (profileSelection: ProfileFilterOption | undefined): void => {
    setChips((chips) => ([
      ...chips
        .filter((chip) => chip.type !== PeopleFilterType.PROFILES),
      ...(
        profileSelection
          ? [
            {
              type: PeopleFilterType.PROFILES,
              value: profileSelection.value,
              label: intl.formatMessage(
                {
                  id: 'people.filter.profiles',
                  defaultMessage: 'Profiles: {value}'
                },
                {
                  value: profileSelection.label,
                },
              ),
            },
          ]
          : []
      ),
    ]));
    setFilterSelection(filterSelection => ({
      ...filterSelection,
      profiles: profileSelection ? profileSelection.value : '',
    }));
  }

  const whenSearchQueryChanged = useCallback((searchQuery: string): void => {
    setFilterSelection(filterSelection => ({
      ...filterSelection,
      search: searchQuery,
    }));
  }, [setFilterSelection]);

  const whenChipRemoved = (chip: Chip): void => {
    if (chip.type === PeopleFilterType.TEAM) {
      setFilterSelection(filterSelection => ({
        ...filterSelection,
        teamIds: filterSelection.teamIds.filter((teamId) => teamId !== chip.value),
      }));
    }

    if (chip.type === PeopleFilterType.ROLE) {
      setFilterSelection(filterSelection => ({
        ...filterSelection,
        roleIds: filterSelection.roleIds.filter((roleId) => roleId !== chip.value),
      }));
    }

    if (chip.type === PeopleFilterType.STATUS) {
      setFilterSelection(filterSelection => ({
        ...filterSelection,
        statusIds: filterSelection.statusIds.filter((statusId) => statusId !== chip.value),
      }));
    }

    if (chip.type === PeopleFilterType.PROFILES) {
      setFilterSelection(filterSelection => ({
        ...filterSelection,
        profiles: '',
      }));
    }
  }

  const whenClearButtonClicked = (): void => {
    onClearFiltersClicked();
  }

  return (
    <FilterContainer>
      <FilterRow>
        <>
          {
            showActions
            ? actions
            : (
              <>
                <span>
                  <FormattedMessage
                    id="importTable.filterResultsLabel"
                    description="Text preceding filters in import table"
                    defaultMessage="Filter results"
                  />
                </span>
                <StyledAutocompleteFilter>
                  <TeamAutocomplete
                    teamIds={ filterSelection.teamIds }
                    onSelectionChanged={ whenTeamsChanged }
                    showChips={false}
                    dense
                    multiple
                    label={
                      intl.formatMessage({
                        id: 'peopleTable.teamsFilter.label',
                        defaultMessage: 'Teams',
                      })
                    }
                    placeholder={
                      intl.formatMessage({
                        id: 'peopleTable.teamsFilter.placeholder',
                        defaultMessage: 'Search...',
                      })
                    }
                  />
                </StyledAutocompleteFilter>
                <StyledAutocompleteFilter>
                  <RoleAutocomplete
                    selectedIds={ filterSelection.roleIds }
                    onSelectionChanged={ whenRolesChanged }
                    allowedIds={allowedRoleIds}
                    showChips={false}
                    dense
                    multiple
                    label={
                      intl.formatMessage({
                        id: 'peopleTable.rolesFilter.label',
                        defaultMessage: 'Roles',
                      })
                    }
                    placeholder={
                      intl.formatMessage({
                        id: 'peopleTable.rolesFilter.placeholder',
                        defaultMessage: 'Search...',
                      })
                    }
                  />
                </StyledAutocompleteFilter>
                <StyledAutocompleteFilter>
                  <PersonStatusAutocomplete
                    selectedIds={ filterSelection.statusIds }
                    onSelectionChanged={ whenStatusesChanged }
                    allowedIds={allowedStatusIds}
                    showChips={false}
                    dense
                    multiple
                    label={
                      intl.formatMessage({
                        id: 'peopleTable.statusesFilter.label',
                        defaultMessage: 'Statuses',
                      })
                    }
                    placeholder={
                      intl.formatMessage({
                        id: 'peopleTable.statusesFilter.placeholder',
                        defaultMessage: 'Search...',
                      })
                    }
                  />
                </StyledAutocompleteFilter>
                <ProfileFilter
                  selection={ filterSelection.profiles }
                  onSelectionChanged={ whenProfilesChanged }
                />
              </>
            )
          }
        </>
        <FlexPullRight>
          <DebouncedSearchInput
            onChange={ whenSearchQueryChanged }
            value={ search }
          />
        </FlexPullRight>
      </FilterRow>
      {
        !!selectedPersonCount && (
          <Flex gap={ 1 }>
            <SelectionDescription
              selectedCount={selectedPersonCount}
              totalCount={totalPersonCount}
              resultFiltered={!!chips.length}
              currentPageCount={currentPageSize}
            />
            {
              selectedPersonCount === totalPersonCount
                ? (
                  <Button
                    color="primary"
                    onClick={onClearSelectionClicked}
                  >
                    <FormattedMessage
                      id="peopleTable.filters.clearSelection"
                      description="Label for button to clear bulk action selection"
                      defaultMessage="Clear selection"
                    />
                  </Button>
                )
                : (
                  <Button
                    color="primary"
                    onClick={onExpandSelectionClicked}
                  >
                    <FormattedMessage
                      id="peopleTable.filters.expandSelection"
                      description="Label for button to expand bulk action selection"
                      defaultMessage="Select all {totalPersonCount} people on this list"
                      values={{
                        totalPersonCount,
                      }}
                    />
                  </Button>
                )
            }
          </Flex>
        )
      }
      {
        !selectedPersonCount && !!chips.length && (
          <FilterRow>
            <strong>
              <FormattedMessage
                id="peopleTable.filters.activeFilters"
                description="Label for filter row"
                defaultMessage="Active filters"
              />
            </strong>
            {
              chips.map((chip) => (
                <StyledChip
                  onDelete={ () => whenChipRemoved(chip) }
                  key={ `${chip.type}_${chip.value}` }
                  label={ chip.label }
                />
              ))
            }
            <Button
              color="primary"
              onClick={ whenClearButtonClicked }
            >
              <FormattedMessage
                id="peopleTable.filters.clear"
                defaultMessage="Clear all filters"
                description="Label for button that clears all filter chips on the third party import screen"
              />
            </Button>
          </FilterRow>
        )
      }
    </FilterContainer>
  )
};

const chipAppearsInSelection = (chip: Chip, filterSelection: PeopleTableFilterSelection): boolean => {
  switch (chip.type) {
    case PeopleFilterType.TEAM:
      return filterSelection.teamIds.indexOf(chip.value) > -1;
    case PeopleFilterType.ROLE:
      return filterSelection.roleIds.indexOf(chip.value) > -1;
    case PeopleFilterType.STATUS:
      return filterSelection.statusIds.indexOf(chip.value as PersonStatus) > -1;
    case PeopleFilterType.PROFILES:
      return filterSelection.profiles === chip.value;
    default:
      return false;
  }
};
