import * as React from 'react';
import { FunctionComponent } from 'react';
import { RRule } from 'rrule';
import { FormattedMessage, useIntl } from 'react-intl';
import { addMinutes } from 'date-fns';

import { DateTimeFormatter } from '../../../../Utility/DateTimeFormatter';
import { CenteredConfirmationDialog } from '../../../../Components';

type Props = {
  container?: Element | React.Component | (() => Element | React.Component | null) | null;
  recurrenceRule: string;
  open: boolean;
  onDismissed: () => void;
}

export const RecurringBroadcastPublishedConfirmationDialog: FunctionComponent<Props> = ({
  recurrenceRule,
  open,
  onDismissed,
  ...other
}) => {
  const intl = useIntl();

  const getFormattedFirstOccurrenceDate = (): string => {
    const rRule = RRule.fromString(recurrenceRule);
    const correctedDate = addMinutes(rRule.options.dtstart, rRule.options.dtstart.getTimezoneOffset());
    return DateTimeFormatter.internationalised(correctedDate, intl.locale);
  };

  return (
    <CenteredConfirmationDialog
      heading={<>&#128079;</>}
      cta={
        intl.formatMessage({
          id: 'deliveryConfirmation.scheduled.cta',
          defaultMessage: 'Go to scheduled broadcasts',
        })
      }
      open={open}
      onConfirmed={onDismissed}
      onDismissed={onDismissed}
      {...other}
    >
      <FormattedMessage
        id="deliveryConfirmation.recurring.body"
        defaultMessage="Your broadcast has been successfully scheduled. The first delivery will be sent on{date}"
        values={{
          date: <><br/><strong>{ getFormattedFirstOccurrenceDate() }</strong></>,
        }}
      />
    </CenteredConfirmationDialog>
  )
}
