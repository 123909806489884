import { ComponentProps, FC } from 'react';

import { Stack } from '../../../lib/components/Stack/Stack';
import { FeaturedIcon } from '../FeaturedIcon/FeaturedIcon';
import { SvgComponent } from '../../../lib/model/SvgComponent';
import { Button } from '../Button/Button';
import { DecorativePattern } from '../DecorativePattern/DecorativePattern';
import { Typography } from '../Typography/Typography';

type Props = {
  text: string;
  supportingText?: string;
  IconComponent: SvgComponent;
  buttonProps?: ComponentProps<typeof Button>[];
  pad: boolean;
  breakpoint: 'mobile' | 'desktop';
  className?: string;
};

export const EmptyState: FC<Props> = ({
  text,
  supportingText,
  IconComponent,
  buttonProps,
  pad,
  breakpoint,
  className,
}) => (
  <Stack
    direction="column"
    gap={ 6 }
    {
      ...pad
        ? {
          css: theme => ({
            height: '500px',
            justifyContent: 'center',
            alignItems: 'center',
            padding: theme.new.spacing[4]
          })
        }
        : {}
    }
    className={ className }
  >
    <Stack direction="column" align="center">
      <div
        css={ {
          position: 'relative',
          zIndex: -1,
          width: 'min-content',
        } }
      >
        <FeaturedIcon
          IconComponent={ IconComponent }
          size="lg"
        />
        <DecorativePattern
          pattern="circles"
          size="sm"
          css={ {
            zIndex: -2,
            transform: 'translate(-50%, -50%)',
          } }
        />
      </div>
    </Stack>
    <Stack
      direction="column"
      align="center"
      gap={ 1 }
    >
      <Typography
        size="md"
        weight="semibold"
      >
        { text }
      </Typography>
      { supportingText && (
        <Typography
          size="sm"
          weight="regular"
          align="center"
        >
          { supportingText }
        </Typography>
      ) }
    </Stack>
    { buttonProps?.length > 0 && (
      <div { ...breakpoint === 'mobile' ? { css: { width: '100%' } } : {} }>
        <Stack
          justify="center"
          direction={ breakpoint === 'desktop' ? 'row' : 'column-reverse' }
        >
          { buttonProps.map(props => <Button { ...props }/>) }
        </Stack>
      </div>
    ) }
  </Stack>
);
