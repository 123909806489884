import { FC, useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { BLUR_COMMAND, COMMAND_PRIORITY_LOW } from 'lexical';

type Props = {
  onBlur: (event: FocusEvent) => void;
};

export const OnBlurPlugin: FC<Props> = ({ onBlur }) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerCommand(
      BLUR_COMMAND,
      (event) => {
        onBlur(event);
        return true;
      },
      COMMAND_PRIORITY_LOW,
    );
  }, [editor, onBlur]);

  return null;
};
