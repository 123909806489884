import { FC, ReactNode } from 'react';
import { defaultTheme } from 'op-storybook/lib/const/defaultTheme';

import { useContextOrThrow } from '../../../../Core/Hook';
import { PageLayoutContext } from '../../../Core/Provider/PageLayoutProvider';

type SpacingKey = keyof typeof defaultTheme.new.spacing;

type Props = {
  top?: SpacingKey;
  right?: SpacingKey;
  bottom?: SpacingKey;
  left?: SpacingKey;
  children?: ReactNode;
  fillParent?: boolean;
};

export const MinimumInset: FC<Props> = ({
  top,
  right,
  bottom,
  left,
  children,
  fillParent,
}) => {
  const { viewportBoundaries } = useContextOrThrow(PageLayoutContext);

  return (
    <div
      css={ theme => ({
        ...top !== undefined ? { paddingTop: viewportBoundaries.top ? `max(env(safe-area-inset-top, 0), ${ theme.new.spacing[top] })` : theme.new.spacing[top] } : {},
        ...right !== undefined ? { paddingRight: viewportBoundaries.right ? `max(env(safe-area-inset-right, 0), ${ theme.new.spacing[right] })` : theme.new.spacing[right] } : {},
        ...bottom !== undefined ? { paddingBottom: viewportBoundaries.bottom ? `max(env(safe-area-inset-bottom, 0), ${ theme.new.spacing[bottom] })` : theme.new.spacing[bottom] } : {},
        ...left !== undefined ? { paddingLeft: viewportBoundaries.left ? `max(env(safe-area-inset-left, 0), ${ theme.new.spacing[left] })` : theme.new.spacing[left] } : {},
        ...fillParent ? { height: '100%', } : {},
      }) }
    >
      { children }
    </div>
  );
};
