import { FC } from 'react';
import * as RadDialog from '@radix-ui/react-dialog';

import { Stack } from '../Stack/Stack';
import { DecorativePattern } from '../../../stories/components/DecorativePattern/DecorativePattern';
import { FeaturedIcon } from '../../../stories/components/FeaturedIcon/FeaturedIcon';
import { StackEnd } from '../StackEnd/StackEnd';
import { SvgComponent } from '../../model/SvgComponent';
import { Typography } from '../../../stories/components/Typography/Typography';
import { BadgeCloseX } from '../BadgeCloseX/BadgeCloseX';

type Props = {
  title: string;
  IconComponent?: SvgComponent;
  description?: string;
  onClose?: () => void;
};

export const ModalHeader: FC<Props> = ({
  title,
  IconComponent,
  description,
  onClose,
}) => (
  <Stack
    gap={ 2 }
    align="flex-start"
  >
    <Stack
      direction="column"
    >
      { IconComponent && (
        <div
          css={ {
            position: 'relative',
            width: 'min-content',
          } }
        >
          <DecorativePattern
            pattern="circles"
            size="sm"
            css={ {
              transform: 'translate(-50%, -50%)',
            } }
          />
          <FeaturedIcon
            IconComponent={ IconComponent }
            size="lg"
          />
        </div>
      ) }
      <RadDialog.Title asChild>
        <Typography
          size="lg"
          palette={ {
            colour: 'grey',
            intensity: 900,
          } }
          weight="semibold"
        >
          { title }
        </Typography>
      </RadDialog.Title>
      { description && (
        <RadDialog.Description asChild>
          <Typography
            size="sm"
            palette={ {
              colour: 'grey',
              intensity: 600,
            } }
            weight="regular"
          >
            { description }
          </Typography>
        </RadDialog.Description>
      ) }
    </Stack>
    { onClose && (
      <StackEnd>
        <RadDialog.Close asChild>
          <BadgeCloseX
            onClick={ onClose }
          />
        </RadDialog.Close>
      </StackEnd>
    ) }
  </Stack>
);
