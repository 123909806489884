import * as RadForm from '@radix-ui/react-form';
import styled from '@emotion/styled';

type FormControlProps = {
  hasStartAdornment: boolean;
  hasEndAdornment: boolean;
};

export const StyledFormControl = styled(RadForm.Control)<FormControlProps>`
  border: none;
  background: transparent;
  line-height: 1;
  width: 100%;
  font-size: ${ props => props.theme.new.typography.text.size.md.fontSize };
  padding: ${ props => `
    0
    ${ props.hasEndAdornment ? 0 : props.theme.new.spacing[4] }
    0
    ${ props.hasStartAdornment ? 0 : props.theme.new.spacing[4] }
  ` };

  &:focus-visible {
    outline: none;
  }
`;

export const StyledFieldContainer = styled.div`
  border: ${ props => props.theme.new.border.standard };
  border-radius: ${ props => props.theme.new.borderRadius.standard };
  box-shadow: ${ props => props.theme.new.shadow.xs };
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: ${ props => props.theme.new.basePalette.white.main };
  min-height: 43px;
`;

export const StyledFieldInner = styled.div<{
  roundStart: boolean;
  roundEnd: boolean;
  error: boolean;
  disabled: boolean;
  padStart: boolean;
  padEnd: boolean;
}>`
  display: flex;
  flex-wrap: nowrap;
  border-color: transparent;
  transition: border-color 0.2s, box-shadow 0.2s;
  width: calc(100% + 2px); // Combined with -1px margin this ensures the border appears over parent border

  ${ props => props.padStart && `padding-inline-start: ${ props.theme.new.spacing[3] }` };
  ${ props => props.padEnd && `padding-inline-end: ${ props.theme.new.spacing[3] }` };

  ${ props => props.roundStart && `
    border-top-left-radius: ${ props.theme.new.borderRadius.standard };
    border-bottom-left-radius: ${ props.theme.new.borderRadius.standard };
  ` }

  ${ props => props.roundEnd && `
    border-top-right-radius: ${ props.theme.new.borderRadius.standard };
    border-bottom-right-radius: ${ props.theme.new.borderRadius.standard };
  ` }

  ${
    props => props.disabled
      ? `background-color: ${ props.theme.new.palette.grey[50].main };`
      : props.error
        ? `
          border: 1px solid ${ props.theme.new.palette.error[300].main };
          margin: -1px;

          &:focus-within {
            box-shadow: ${ props.theme.new.shadow.focusRingNoColour } ${ props.theme.new.palette.error[100].main };
          }
        `
        : `
          &:focus-within {
            box-shadow: ${ props.theme.new.shadow.focusRing };
            border: ${ props.theme.new.border.focus };
            margin: -1px;
          }
        `
  }
`;
