import { ChangeEvent, Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormControl, ListSubheader, MenuItem, Select } from '@mui/material';
import { Frequency } from 'rrule';
import { LocalisedString } from 'op-storybook/lib/model/LocalisedString/LocalisedString';

import { DraftBroadcast } from '../../Model';
import { ValidationTree } from '../../../Common/Model';
import { StyledEventNotice, StyledSchedulingInputs, StyledWhenInput } from './style';
import {
  BroadcastScheduleNotice,
  ScheduleDailySettings,
  ScheduleMonthlySettings,
  ScheduleOnceSettings,
  SchedulePeriod
} from '..';
import { Notice, ValidationErrorMessage } from '../../../Common/Component';
import { useLocalisedDateErrors } from '../../../Common/Hook/useLocalisedDateErrors';
import { BroadcastValidator } from '../../Service';
import {
  AnnualSchedule,
  DailySchedule,
  MonthlySchedule,
  RecurringSchedule,
  Schedule,
  WeeklySchedule
} from '../../Model/BroadcastSchedule';
import { ScheduleTransformer } from '../../Utility/ScheduleTransformer';
import { BroadcastScheduleIdentifier } from '../../Utility/BroadcastScheduleIdentifier';
import { ScheduleAnnuallySettings } from '../ScheduleAnnuallySettings/ScheduleAnnuallySettings';

type Props = {
  schedule: Schedule;
  setSchedule: Dispatch<SetStateAction<Schedule>>;
  recipientCount: number | null;
  validation?: ValidationTree<DraftBroadcast>;
  setValidation: Dispatch<SetStateAction<ValidationTree<DraftBroadcast>>>;
  containsEvent: boolean;
};

export const SchedulingSettings: FC<Props> = ({
  schedule,
  setSchedule,
  recipientCount,
  validation,
  setValidation,
  containsEvent,
}) => {
  const intl = useIntl();
  const scheduleForErrors = useLocalisedDateErrors(
    validation?.children.scheduleFor?.errors || [],
    intl.formatMessage({
      id: 'schedulingSettings.scheduleForMinDate',
      description: 'Label for broadcast schedule later option minimum date when referenced in validation errors.',
      defaultMessage: 'the current date',
    }),
    undefined,
    intl.formatMessage({
      id: 'schedulingSettings.scheduleForFieldName',
      description: 'Label for broadcast schedule later option when referenced in validation errors.',
      defaultMessage: 'Scheduled date',
    }),
  );

  const when = schedule.recurrence
    ? schedule.recurrence.repetition.frequency
    : schedule.localStartDate === null
      ? 'now'
      : 'later';
  const whenOptions = useMemo<LocalisedString<WhenId>[]>(() => ([
    {
      id: 'once',
      localisation: intl.formatMessage({
        id: 'broadcasts.scheduling.once',
        description: 'Label for "send once" option in "when" select.',
        defaultMessage: 'Send once',
      }),
    },
    {
      id: 'now',
      localisation: intl.formatMessage({
        id: 'broadcasts.scheduling.now',
        description: 'Label for "now" option in "when" select.',
        defaultMessage: 'Now',
      }),
    },
    {
      id: 'later',
      localisation: intl.formatMessage({
        id: 'broadcasts.scheduling.later',
        description: 'Label for "later" option in "when" select.',
        defaultMessage: 'Later',
      }),
    },
    {
      id: 'recurring',
      localisation: intl.formatMessage({
        id: 'broadcasts.scheduling.recurring',
        description: 'Label for "recurring" option in "when" select.',
        defaultMessage: 'Recurring',
      }),
    },
    {
      id: Frequency.DAILY,
      localisation: intl.formatMessage({
        id: 'broadcasts.scheduling.daily',
        description: 'Label for "daily" option in "when" select.',
        defaultMessage: 'Daily',
      }),
    },
    {
      id: Frequency.WEEKLY,
      localisation: intl.formatMessage({
        id: 'broadcasts.scheduling.weekly',
        description: 'Label for "weekly" option in "when" select.',
        defaultMessage: 'Weekly',
      }),
    },
    {
      id: Frequency.MONTHLY,
      localisation: intl.formatMessage({
        id: 'broadcasts.scheduling.monthly',
        description: 'Label for "monthly" option in "when" select.',
        defaultMessage: 'Monthly',
      }),
    },
    {
      id: Frequency.YEARLY,
      localisation: intl.formatMessage({
        description: 'Label for "yearly" option in "when" select.',
        defaultMessage: 'Yearly',
      }),
    },
  ]), [intl]);

  /**
   * Validate reset scheduleFor and recurrenceRule validation when schedule is updated.
   */
  useEffect(() => {
    setValidation(validation => ({
      ...validation,
      children: {
        ...validation.children,
        scheduleFor: schedule.localStartDate && !schedule.recurrence
          ? BroadcastValidator.validateScheduleFor(schedule.localStartDate)
          : {
            errors: [],
            children: {},
          },
        recurrenceRule: {
          errors: [],
          children: {},
        },
      },
    }));
  }, [schedule, setValidation]);

  const whenWhenChanged = useCallback((event: ChangeEvent<{ value: unknown }>) => {
    const when = event.target.value as WhenId;

    switch (when) {
      case 'now':
        return setSchedule({
          localStartDate: null,
          recurrence: null,
        });
      case 'later':
        return setSchedule(ScheduleTransformer.toOneTime);
      case Frequency.MONTHLY:
        return setSchedule(ScheduleTransformer.toMonthly);
      case Frequency.WEEKLY:
        return setSchedule(ScheduleTransformer.toWeekly);
      case Frequency.YEARLY:
        return setSchedule(ScheduleTransformer.toAnnual);
      default:
        return setSchedule(ScheduleTransformer.toDaily);
    }
  }, [setSchedule]);

  return (
    <>
      <StyledSchedulingInputs>
        <StyledWhenInput>
          <label>{ whenLabel }</label>
          <FormControl variant="outlined">
            <Select
              id="broadcasts-scheduling-when"
              labelId="broadcasts-scheduling-when-label"
              margin="dense"
              MenuProps={ {
                anchorOrigin: {
                  horizontal: 'left',
                  vertical: 'bottom',
                },
                style: {
                  maxWidth: 500,
                },
              } }
              name="when"
              variant="outlined"
              value={ when }
              onChange={ whenWhenChanged }
            >
              { whenOptions.map(whenOption => (
                (['once', 'recurring'] as WhenId[]).includes(whenOption.id)
                  ? (
                    <ListSubheader key={ whenOption.id }>
                      { whenOption.localisation }
                    </ListSubheader>
                  )
                  : (
                    <MenuItem
                      key={ whenOption.id }
                      value={ whenOption.id }
                      disabled={ (['recurring', Frequency.WEEKLY, Frequency.DAILY, Frequency.MONTHLY, Frequency.YEARLY] as WhenId[]).includes(whenOption.id) && containsEvent }
                    >
                      { whenOption.localisation }
                    </MenuItem>
                  )
              )) }
              { containsEvent && (
                <StyledEventNotice>
                  <Notice
                    feedback={ {
                      message: intl.formatMessage({
                        id: 'broadcasts.form.recurringUnavailable',
                        description: 'Tooltip shown when events are present in the broadcast.',
                        defaultMessage: 'Recurring delivery settings are not available for broadcasts containing event cards.',
                      }),
                      severity: 'info',
                    } }
                    variant="outlined"
                  />
                </StyledEventNotice>
              ) }
            </Select>
          </FormControl>
        </StyledWhenInput>
        { !schedule.recurrence && schedule.localStartDate && (
          <ScheduleOnceSettings
            localStartDate={ schedule.localStartDate }
            setSchedule={ setSchedule }
          />
        ) }
        { BroadcastScheduleIdentifier.scheduleIsMonthly(schedule) && (
          <ScheduleMonthlySettings
            schedule={ schedule }
            setSchedule={ setSchedule as Dispatch<SetStateAction<MonthlySchedule>> }
          />
        ) }
        { BroadcastScheduleIdentifier.scheduleIsAnnual(schedule) && (
          <ScheduleAnnuallySettings
            schedule={ schedule }
            setSchedule={ setSchedule as Dispatch<SetStateAction<AnnualSchedule>> }
          />
        ) }
        { (BroadcastScheduleIdentifier.scheduleIsDaily(schedule) || BroadcastScheduleIdentifier.scheduleIsWeekly(schedule)) && (
          <ScheduleDailySettings
            schedule={ schedule }
            setSchedule={ setSchedule as Dispatch<SetStateAction<DailySchedule | WeeklySchedule>> }
          />
        ) }
        { schedule.recurrence && (
          <SchedulePeriod
            schedule={ schedule }
            setSchedule={ setSchedule as Dispatch<SetStateAction<RecurringSchedule>> }
          />
        ) }
      </StyledSchedulingInputs>
      { !!scheduleForErrors.length && scheduleForErrors.map(error => (
        <ValidationErrorMessage key={ error }>
          { error }
        </ValidationErrorMessage>
      )) }
      <BroadcastScheduleNotice
        when={ when }
        recipientCount={ recipientCount }
      />
    </>
  );
};

export type WhenId = 'once' | 'now' | 'later' | 'recurring' | Frequency;

const whenLabel = (
  <FormattedMessage
    id="broadcasts.scheduling.when"
    description={ 'Label for "when" field on broadcast schedule form.' }
    defaultMessage="Send"
  />
);
