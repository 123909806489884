import styled from '@emotion/styled';
import { IconButton } from '@mui/material';
import { keyframes, css as ReactCss } from '@emotion/react';

const ratingStarFilled = keyframes`
  0% {
    transform: scale(0.85);
  }

  50% {
    transform: scale(1.25);
  }

  100% {
    transform: scale(1);
  }
`;

const ratingStarEmptied = keyframes`
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.85);
  }
`;

const filledAnimationStyle = ReactCss`
  svg {
    animation: ${ ratingStarFilled } 0.25s ease;
  }
`;

export const StyledRatingIconButton = styled(IconButton, { shouldForwardProp: propName => propName !== 'filled' })<{ filled: boolean }>`
  color: ${ props => props.theme.palette.warning.main };
  padding: 0;
  width: 100%;
  border-radius: 0.35rem;

  svg {
    animation: ${ ratingStarEmptied } 0.25s ease;
    transform: scale(0.85);
    width: 65%;
    height: 100%;
  }

  ${ props => props.filled && filledAnimationStyle }
`;

export const StyledRatingContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));

  .Mui-disabled {
    color: ${ props => props.theme.palette.warning.main };
  }

  > div {
    display: flex;
    justify-content: center;
  }
`;

export const StyledValue = styled.span`
  color: ${ props => props.theme.palette.grey[400] };
`;
