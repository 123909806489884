import { ChangeEventHandler, FC, useCallback, useMemo } from 'react';
import { FormControl, MenuItem, Select } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';
import {
  ValidationErrorHandlerFactory
} from 'op-storybook/lib/utility/ValidationErrorHandlerFactory/ValidationErrorHandlerFactory';
import {
  useLocalisedValidationErrors
} from 'op-storybook/lib/hooks/useLocalisedValidationHandlers/useLocalisedValidationHandlers';

import { ValidationTree } from '../../../../Common/Model';
import { DraftNumberResponse } from '../../../Service';
import { useDescendantErrors, useValidationErrorHandlers } from '../../../../Common/Hook';
import { FormResponseErrors } from '../../FormResponseErrors/FormResponseErrors';
import { SelectionRange } from '../SelectionRange/SelectionRange';
import { ChoiceContentCheckboxes } from '../../../../Content/Component';
import { AppFormInput, DropdownChevronIcon } from '../ChoiceRequestPreview/style';
import { ChoiceRequest as ChoiceRequestModel, DraftChoiceRequest } from '../../../Model/ContentTypes';
import { ValidationErrorIdentifier } from '../../../../Common/Utility/ValidationErrorIdentifier';
import { Flex, FlexPullRight, VerticallySpaced } from '../../../../Common/Component';

type Props = {
  selection?: string[];
  content: DraftChoiceRequest | ChoiceRequestModel;
};

type ReadOnlyProps = Props & {
  readOnly: true;
  onChange?: never;
  validation?: never;
  displayValidation?: false;
};

type EditableProps = Props & {
  readOnly?: false;
  onChange: (optionIds: string[]) => void;
  validation?: ValidationTree<DraftNumberResponse>;
  displayValidation?: boolean;
};

export const ChoiceRequest: FC<ReadOnlyProps | EditableProps> = ({
  selection = [],
  content,
  onChange,
  validation,
  displayValidation,
  readOnly,
}) => {
  const singleChoiceSelection = useMemo<string>(() => selection[0] || '', [selection]);
  const intl = useIntl();
  const errors = useDescendantErrors(validation);
  const localisedErrors = useLocalisedValidationErrors(
    useValidationErrorHandlers(
      intl.formatMessage({
        description: 'Form number request input name used in error messages.',
        defaultMessage: 'your response',
      }),
      undefined,
      undefined,
      [
        ValidationErrorHandlerFactory.createHandler(
          ValidationErrorIdentifier.isTooFewError,
          error => intl.formatMessage({
            description: 'Error when choice request response in form is under min option count.',
            defaultMessage: 'Please select at least { min } options.',
          }, { min: error.metadata.minCount }),
        ),
        ValidationErrorHandlerFactory.createHandler(
          ValidationErrorIdentifier.isTooManyError,
          error => intl.formatMessage({
            description: 'Error when choice request response in form exceeds max option count.',
            defaultMessage: 'Please select no more than { max } options.',
          }, { max: error.metadata.maxCount }),
        ),
      ],
    ),
    errors,
  );

  const whenSingleChoiceSelectionChanged: ChangeEventHandler<{ value: unknown }> = useCallback(event => {
    const optionId = ['string', 'number'].includes(typeof event.target.value)
      ? `${ event.target.value as string | number }`
      : undefined;
    !readOnly && onChange(optionId ? [optionId] : []);
  }, [readOnly, onChange]);

  return (
    <VerticallySpaced gap={ 1 }>
      {
        content.style === 'checkbox'
          ? (
            <>
              { content.maxSelections !== 1 && (
                <SelectionRange
                  min={ content.minSelections > 1 ? content.minSelections : null }
                  max={ content.maxSelections }
                  singular={
                    <FormattedMessage
                      id="form.fields.choice.options.singular"
                      defaultMessage="option"
                    />
                  }
                  plural={
                    <FormattedMessage
                      id="form.fields.choice.options.plural"
                      defaultMessage="options"
                    />
                  }
                />
              ) }
              <ChoiceContentCheckboxes
                maxSelections={ content.maxSelections }
                selectedIds={ selection }
                onChange={ optionIds => !readOnly && onChange(optionIds) }
                options={ content.options }
                disabled={ readOnly }
              />
            </>
          )
          : (
            <FormControl
              variant="outlined"
              fullWidth
            >
              <Select
                value={ singleChoiceSelection }
                onChange={ whenSingleChoiceSelectionChanged }
                displayEmpty
                input={ <AppFormInput/> }
                disabled={ readOnly }
                IconComponent={ DropdownChevronIcon }
              >
                <MenuItem value="" disabled>
                  <FormattedMessage
                    description="Placeholder message for form choice request dropdown."
                    defaultMessage="Select an option"
                  />
                </MenuItem>
                { content.options.map((choice, index) => (
                  <MenuItem
                    key={ choice.id || index }
                    value={ choice.id || index }
                  >
                    { choice.title }
                  </MenuItem>
                )) }
              </Select>
            </FormControl>
          )
      }
      { displayValidation && <FormResponseErrors localisedErrors={ localisedErrors }/> }
      { !readOnly && (content.style === 'dropdown' || content.maxSelections === 1) && (
        <Flex>
          <FlexPullRight>
            <Button onClick={ () => onChange([]) }>
              <FormattedMessage
                description="Clear selection button label in choice form card."
                defaultMessage="Clear selection"
              />
            </Button>
          </FlexPullRight>
        </Flex>
      ) }
    </VerticallySpaced>
  );
};
