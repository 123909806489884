import { FetchResponse, useGet } from '../../Hooks';

export type NavigationItemConfiguration = {
  ref: string;
  position: number;
  defaultPosition: number;
  locked: boolean;
  disabled: boolean;
};

export const useFetchAppNavigationSettings = (): FetchResponse<{ items: NavigationItemConfiguration[] }> => (
  useGet('/settings/app-navigation')
);
