import { FC } from 'react';

import { PresentationIcon } from '../../../../../../src/react/Common/Component/PresentationIcon/PresentationIcon';
import { SvgComponent } from '../../../../../../src/react/Common/Model';
import { StyledButton } from './style';
import { ConditionalTooltip } from '../../../../../../src/react/Common/Component/ConditionalTooltip/ConditionalTooltip';

type Props = {
  IconComponent: SvgComponent;
  label: string;
  onClick: () => void;
  disabled?: boolean;
  active?: boolean;
}

export const ToolbarButton: FC<Props> = ({
  IconComponent,
  label,
  onClick,
  disabled = false,
  active = false,
}) => (
  <ConditionalTooltip
    title={ label }
    enterDelay={ 1000 }
    placement="top"
    active={ !disabled }
  >
    <StyledButton
      disabled={ disabled }
      active={ active }
      onClick={ onClick }
      type="button"
    >
      <PresentationIcon
        IconComponent={ IconComponent }
        size="xs"
      />
    </StyledButton>
  </ConditionalTooltip>
);
