import { FC, MouseEventHandler, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { CircularProgress, InputAdornment, TextField } from '@mui/material';

import { StyledLocationInput } from './style';
import PinIcon from '../../../../Assets/img/icons/monochrome/pin.svg';
import CloseIcon from '../../../../Assets/img/icons/streamline/close.svg';
import { IconButton } from '../../../../Common/Component';
import { useDescendantErrors } from '../../../../Common/Hook';
import { useLocalisedResponseErrors } from '../../../Hook';
import { FormResponseErrors } from '../../FormResponseErrors/FormResponseErrors';
import { ValidationTree } from '../../../../Common/Model';
import { DraftGeolocationResponse } from '../../../Service';

type Props = {
  value: string | null;
};

type ReadOnlyProps = Props & {
  readOnly: true;
  onInputClick?: never;
  busy?: never;
  onClear?: never;
  validation?: never;
  displayValidation?: false;
};

type EditableProps = Props & {
  readOnly?: false;
  onInputClick: () => void;
  busy: boolean;
  onClear: () => void;
  validation?: ValidationTree<DraftGeolocationResponse>;
  displayValidation?: boolean;
};

export const GeolocationRequest: FC<ReadOnlyProps | EditableProps> = ({
  value,
  onInputClick,
  busy,
  onClear,
  validation,
  displayValidation,
  readOnly,
}) => {
  const errors = useDescendantErrors(validation);
  const localisedErrors = useLocalisedResponseErrors(errors);
  const intl = useIntl();
  const inputPlaceholder = useMemo(() => {
    return intl.formatMessage({
      id: 'form.geolocationRequest.preview.input.placeholder',
      defaultMessage: 'Tap to give your location',
    })
  }, [intl]);

  const whenClearButtonClicked: MouseEventHandler = useCallback(event => {
    event.stopPropagation();
    !readOnly && onClear();
  }, [readOnly, onClear]);

  const whenInputClick = useCallback(() => {
    !readOnly && onInputClick()
  }, [onInputClick, readOnly]);

  return (
    <>
      <StyledLocationInput onClick={ whenInputClick }>
        <TextField
          color="secondary"
          type="location"
          disabled
          placeholder={ inputPlaceholder }
          value={ value || '' }
          variant={ 'outlined' }
          margin={ 'dense' }
          fullWidth
          InputProps={ {
            endAdornment: (
              <InputAdornment position="end">
                { !!value && !readOnly && (
                  <IconButton
                    IconComponent={ CloseIcon }
                    size="xs"
                    label={ intl.formatMessage({
                      description: 'Form geolocation input clear button label',
                      defaultMessage: 'Clear',
                    }) }
                    onClick={ whenClearButtonClicked }
                  />
                ) }
                {
                  busy
                    ? (
                      <CircularProgress
                        color="secondary"
                        size={ 20 }
                        variant="indeterminate"
                      />
                    )
                    : <PinIcon/>
                }
              </InputAdornment>
            ),
          } }
        />
      </StyledLocationInput>
      { displayValidation && <FormResponseErrors localisedErrors={ localisedErrors }/> }
    </>
  );
};
