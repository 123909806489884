import { ChangeEvent, FunctionComponent, useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, TextField } from '@mui/material';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Heading } from '@ourpeople/shared/Core/Component/Content';

import { ApiContext } from '../../../Contexts';
import { LoadingSpinner } from '../../../Components';
import { Category, DraftCategory } from '../../Model';
import { Box, Flex, FlexPullRight } from '../../../Common/Component';
import { StyledCategoryForm } from './style';
import { useContextOrThrow } from '../../../Core/Hook';
import { ToastContext } from '../../../Core/Context';

interface Props {
  category: Category | DraftCategory;
  onCategoryChanged: (category: Category | DraftCategory) => void;
}

export const FormCategoryEditor: FunctionComponent<Props> = ({ category, onCategoryChanged }) => {
  const api = useContext(ApiContext);
  const intl = useIntl();
  const history = useHistory();
  const { url } = useRouteMatch();
  const [saving, setSaving] = useState<boolean>(false);
  const { addSuccessToast, addErrorToast } = useContextOrThrow(ToastContext);

  const isCategory = (category: Category | DraftCategory): category is Category => (
    !!(category as Category).id
  );

  const whenNameChanged = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    const name = event.target.value;
    if (name !== category.name) {
      onCategoryChanged({
        ...category,
        name,
      });
    }
  };

  const navigateToEdit = (categoryId: string) => {
    history.push(url.replace('/create', `/${categoryId}/edit`));
  };

  const whenSaveButtonClicked = (): void => {
    if (api) {
      const isEditing = isCategory(category);
      setSaving(true);
      saveCategory()
        .then((category) => {
          const successMessage = intl.formatMessage({
            id: 'form.category.successMessage',
            defaultMessage: '{name} saved successfully.'
          }, {
            name: category.name,
          });
          addSuccessToast(successMessage);
          if (category && !isEditing) {
            navigateToEdit(category.id);
          }
          setSaving(false);
        })
        .catch(() => {
          const failureMessage = intl.formatMessage({
            id: 'form.category.failureMessage',
            defaultMessage: '{name} could not be saved.'
          }, {
            name: category.name,
          });
          addErrorToast(failureMessage);
          setSaving(false);
        });
    }
  };

  const saveCategory = (): Promise<Category> => {
    setSaving(true);
    if (!api) {
      throw new Error();
    }
    return api.post<Category>(`/forms/categories${ isCategory(category) ? `/${ category.id }` : '' }`, category)
      .then(response => response.data)
  };

  const valid = !!category.name;

  return (
    <Box>
      <StyledCategoryForm>
        <Heading type="h4">
          <FormattedMessage
            id="category.form.nameHeading"
            defaultMessage="Category name"
          />
        </Heading>
        <TextField
          variant="outlined"
          size="small"
          fullWidth={true}
          onChange={ whenNameChanged }
          value={ category.name }
        />
      </StyledCategoryForm>
      <Flex>
        <FlexPullRight>
          {
            saving
              ? (
                <>
                  <FormattedMessage
                    id="forms.categories.editor.saving"
                    defaultMessage="Saving"
                  />
                  <LoadingSpinner/>
                </>
              )
              : (
                <Button
                  color="primary"
                  variant="contained"
                  disableElevation
                  disabled={!valid}
                  onClick={whenSaveButtonClicked}
                >

                  <FormattedMessage
                    id="tags.categories.editor.saveCta"
                    defaultMessage="Save"
                  />
                </Button>
              )
          }
        </FlexPullRight>
      </Flex>
    </Box>
  )
};
