import { ComponentProps, FC, ReactNode, useCallback } from 'react';

import { StyledImage } from './style';
import { ImageWithLoadingFacade } from '../ImageWithLoadingFacade/ImageWithLoadingFacade';
import { LoadingFacade } from '../../Feedback/LoadingFacade/LoadingFacade';

type Props = {
  resolveUrl: () => Promise<string>;
  placeholder?: ReactNode;
  fit: 'cover' | 'contain';
};

export const ImageWithDefaultLoadingFacade: FC<Props> = ({
  resolveUrl,
  placeholder,
  fit,
}) => {
  const renderImage = useCallback<ComponentProps<typeof ImageWithLoadingFacade>['renderImage']>((url, onLoad) => (
    <StyledImage
      fit={ fit }
      draggable={ false }
      src={ url }
      onLoad={ onLoad }
    />
  ), [fit]);

  return (
    <ImageWithLoadingFacade
      resolveUrl={ resolveUrl }
      placeholder={ placeholder }
      loadingFacade={ <LoadingFacade /> }
      renderImage={ renderImage }
      renderResolutionError={ () => null }
    />
  );
};
