import { FC, useCallback, useState } from 'react';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';

import { PopOverState } from '../../../../Common/Model';
import { VideoThumbnail } from '../../../../Content/Component';
import { VideoUploadDialog } from '../../../../Common/Component';

type Props = {
  uploadId: string;
  thumbnailKey: string;
  duration: number;
  onReady?: () => void;
}

export const ClickableVideoThumbnail: FC<Props> = ({
  uploadId,
  thumbnailKey,
  duration,
  onReady,
}) => {
  const [videoDialogState, setVideoDialogState] = useState<PopOverState>(PopOverState.CLOSED);

  const whenPlayVideoClicked = useCallback(() => {
    setVideoDialogState(PopOverState.OPEN);
  }, []);

  return (
    <Stack
      direction="column"
      align="center"
    >
      <VideoThumbnail
        uploadId={ uploadId }
        thumbnailKey={ thumbnailKey }
        busy={ videoDialogState !== PopOverState.CLOSED }
        duration={ duration }
        onClick={ () => whenPlayVideoClicked() }
        onReady={ onReady }
      />
      { videoDialogState !== PopOverState.CLOSED && (
        <VideoUploadDialog
          TransitionProps={ {
            onExited: () => setVideoDialogState(PopOverState.CLOSED),
          } }
          open={ videoDialogState === PopOverState.OPEN }
          uploadId={ uploadId }
          onClose={ () => setVideoDialogState(PopOverState.WILL_CLOSE) }
        />
      ) }
    </Stack>
  );
};
