import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AutocompleteSelectionChanged } from '@ourpeople/shared/Core/Component/Input/Autocomplete/Autocomplete';
import { LocalisedString } from 'op-storybook/lib/model/LocalisedString/LocalisedString';

import { ContentTypeAutocomplete, ScheduledTableRow } from '..';
import { PaginatedTable, SortableHeaderCell, TableCell, TableRow } from '../../../Components';
import { TableProps } from '../../Model';
import { useQueryAsState } from '../../../Hooks';
import { useContentDefinitionRegistry } from '../../../Content/Hook';
import { FetchDeliveriesParams, FetchDeliveriesSort } from '../../Hook';
import { QueryParser, QueryWithKeys } from '../../../Common/Utility';
import { Chip, TableFilters } from '../../../Common/Component';
import { Tag } from '../../../Tags/Model';
import { StyledInputContainer } from '../RecentTable/style';
import { TeamAutocomplete } from '../../../Tags/Component';
import { useUpcomingSmsSpendThresholdExceeded } from '../../../Credit/Hook';
import { useApi } from '../../../Core/Hook';
import { useMounted } from '../../../Common/Hook';
import { UpcomingSpend } from '../../../Credit/Model';
import { SharedDelivery, useFetchSharedDeliveries } from '../../Hook/useFetchSharedDeliveries';

type Query = QueryWithKeys<'teamIds' | 'publishedByIds' | 'contentTypes' | 'search' | 'pageNum' | 'sort' | 'tab'>;

export const ScheduledTable: FC<TableProps> = () => {
  const [query, setQuery] = useQueryAsState<Query>();
  const api = useApi();
  const upcomingSmsSpendThresholdExceeded = useUpcomingSmsSpendThresholdExceeded();
  const intl = useIntl();
  const { contentDefinitions } = useContentDefinitionRegistry();
  const mounted = useMounted();
  const [upcomingSpends, setUpcomingSpends] = useState<UpcomingSpend[]>([]);
  const params = useMemo<FetchDeliveriesParams>(
    () => ({
      pageNum: QueryParser.pageNum(query),
      noDrafts: 1,
      noArchived: 1,
      noFinished: 1,
      ...(query.publishedByIds ? { publishedByIds: query.publishedByIds } : {}),
      ...(query.search ? { search: query.search } : {}),
      ...(query.contentTypes ? {
          contentTypes: query.contentTypes
            .split(',')
            .map(contentType => contentDefinitions.find(definition => definition.id === contentType)?.contentTypes || [])
            .join(',')
        } : {}),
      sort: parseSort(query),
      ...(query.teamIds ? { tagIds: query.teamIds } : {}),
      statuses: 'pending,queued,failed,recalled',
    }),
    [contentDefinitions, query],
  );
  const teamIds = useMemo(() => query.teamIds?.split(',') || [], [query.teamIds]);

  const [
    fetchDeliveriesResult,
    fetchDeliveriesState,
    reloadDeliveries,
  ] = useFetchSharedDeliveries(params);
  const pagination = useMemo(
    () => fetchDeliveriesResult?.content?.pagination,
    [fetchDeliveriesResult?.content?.pagination],
  );
  const [deliveries, setDeliveries] = useState<SharedDelivery[]>([]);
  const [chips, setChips] = useState<Chip[]>([]);
  const [calculatingCredit, setCalculatingCredit] = useState<boolean>(false);

  const broadcastWithSmsIds = useMemo(() => (
    upcomingSmsSpendThresholdExceeded
      ? deliveries.map(delivery => delivery.broadcast)
        .reduce<string[]>((ids, broadcast) => (
          broadcast.notification.sms.send
            ? ids.concat(broadcast.id)
            : ids
        ), [])
      : []
  ), [deliveries, upcomingSmsSpendThresholdExceeded]);

  const whenContentTypesChanged = useCallback((contentTypes: LocalisedString[]) => {
    const newChips = contentTypes.map(contentType => ({
      id: contentType.id,
      type: 'contentTypes',
      label: intl.formatMessage({
        id: 'recentBroadcasts.type.chip',
        description: 'Chip label in recent broadcasts table when filtered by type.',
        defaultMessage: 'Type: { type }',
      }, {
        type: contentType.localisation,
      }),
    }));
    setChips(chips => chips.filter(chip => chip.type !== 'contentTypes').concat(newChips));
    setQuery(QueryParser.csvValueUpdater('contentTypes', contentTypes.map(contentType => contentType.id)));
  }, [intl, setQuery]);

  const whenTeamsChanged: AutocompleteSelectionChanged<Tag<'team'>> = useCallback(selection => {
    const newChips = selection.options.map(team => ({
      id: team.id,
      type: 'teamIds',
      label: intl.formatMessage({
        id: 'recentBroadcasts.team.chip',
        description: 'Chip label in recent broadcasts table when filtered by team.',
        defaultMessage: 'Team: { team }',
      }, {
        team: team.name,
      }),
    }));
    setChips(chips => chips.filter(chip => chip.type !== 'teamIds').concat(newChips));
    setQuery(QueryParser.csvValueUpdater('teamIds', selection.options.map(option => option.id).concat(selection.badIds)));
  }, [intl, setQuery]);

  const resetFilters = useCallback(() => {
    setChips([]);
    setQuery(({ tab }) => ({
      ...(tab ? { tab } : {}),
    }));
  }, [setQuery]);

  const whenChipRemoved = useCallback((chipToRemove: Chip) => {
    setChips(chips => chips.filter(chip => chip.type !== chipToRemove.type || chip.id !== chipToRemove.id));
    setQuery(QueryParser.csvValueRemover(chipToRemove.type, chipToRemove.id));
  }, [setQuery]);

  const whenPageNumChanged = useCallback((pageNum: number) => {
    setQuery(query => ({
      ...query,
      pageNum: `${pageNum}`,
    }))
  }, [setQuery]);

  useEffect(() => {
    setDeliveries(fetchDeliveriesResult?.content?.deliveries || []);
  }, [fetchDeliveriesResult?.content?.deliveries]);

  const whenSearchChanged = useCallback((search: string) => {
    setQuery(({ search: previousSearch, ...query }) => ({
      ...query,
      ...(search ? { search } : {}),
    }));
  }, [setQuery]);

  const whenSortChanged = useCallback((sort: string) => {
    setQuery(query => ({
      ...query,
      sort,
    }));
  }, [setQuery]);

  const calculateCredit = useCallback(() => {
    if (!broadcastWithSmsIds.length) {
      return;
    }

    setCalculatingCredit(true);
    api.get<{ upcomingSpends: UpcomingSpend[] }>(
      '/sms/upcoming-spends',
      {
        params: {
          type: 'broadcast',
          ids: broadcastWithSmsIds.join(','),
        },
      },
    )
      .then((response) => {
        if (!mounted.current) {
          return;
        }

        setUpcomingSpends(response.data.upcomingSpends);
        setCalculatingCredit(false);
      })
      .catch(() => {
        if (!mounted.current) {
          return;
        }

        setCalculatingCredit(false);
      });
  }, [api, broadcastWithSmsIds, mounted]);

  useEffect(() => {
    calculateCredit();
  }, [calculateCredit]);

  return (
    <div>
      <TableFilters
        onClearFiltersClicked={ resetFilters }
        chips={ chips }
        onRemoveChipClicked={ whenChipRemoved }
        searchValue={ query.search || '' }
        onSearchChanged={ whenSearchChanged }
      >
        <StyledInputContainer>
          <ContentTypeAutocomplete
            selectedIds={ query.contentTypes?.split(',') || [] }
            onSelectionChanged={ whenContentTypesChanged }
            multiple
            dense
            fullWidth
          />
        </StyledInputContainer>
        <StyledInputContainer>
          <TeamAutocomplete
            teamIds={ teamIds }
            onSelectionChanged={ whenTeamsChanged }
            multiple
            dense
            fullWidth
            showChips={ false }
            label={ intl.formatMessage({
              id: 'broadcasts.sentToTeams.label',
              description: 'Placeholder for sent to teams filter',
              defaultMessage: 'Sent to team',
            }) }
            placeholder={ intl.formatMessage({
              id: 'broadcasts.sentToTeams.placeholder',
              description: 'Placeholder for sent to teams filter',
              defaultMessage: 'Search…',
            }) }
          />
        </StyledInputContainer>
      </TableFilters>
      <PaginatedTable
        headerRow={
          <TableRow>
            <TableCell>
              <FormattedMessage
                description="Heading for 'type' column in scheduled broadcasts table."
                defaultMessage="Type"
              />
            </TableCell>
            <TableCell minwidth="11rem">
              <FormattedMessage
                description="Heading for 'broadcast title' column in scheduled broadcasts table."
                defaultMessage="Title"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                description="Heading for 'Shared with' column in scheduled broadcasts table."
                defaultMessage="Shared with"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                description="Heading for 'scheduled by' column in scheduled broadcasts table."
                defaultMessage="Scheduled by"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                description="Heading for 'schedule' column in scheduled broadcasts table."
                defaultMessage="Schedule"
              />
            </TableCell>
            <SortableHeaderCell
              ascValue="scheduled_at_asc"
              descValue="scheduled_at_desc"
              sort={ query.sort || 'scheduled_at_asc' }
              onSort={ whenSortChanged }
            >
              <FormattedMessage
                description="Heading for 'next delivery' column in scheduled broadcasts table."
                defaultMessage="Next delivery"
              />
            </SortableHeaderCell>
            <TableCell/>
          </TableRow>
        }
        requestState={ fetchDeliveriesState }
        onRetryClicked={ reloadDeliveries }
        pagination={ pagination }
        onPageChanged={ whenPageNumChanged }
        rows={ deliveries }
        rowRender={ delivery => (
          <ScheduledTableRow
            key={ delivery.broadcast.id }
            delivery={ delivery }
            calculatingCredit={ calculatingCredit && broadcastWithSmsIds.includes(delivery.broadcast.id) }
            upcomingSpend={ upcomingSpends.find(
              upcomingSpend => (
                upcomingSpend.spend.spender.type === 'broadcast'
                && upcomingSpend.spend.spender.id === delivery.broadcast.id
              )
            ) }
            onReloadRequired={ reloadDeliveries }
          />
        ) }
        pageNum={ params.pageNum }
      />
    </div>
  );
};

const parseSort = QueryParser.getValueParser<'sort', FetchDeliveriesSort>(
  'sort',
  ['coalesced_delivered_at_asc', 'coalesced_delivered_at_desc'],
  'coalesced_delivered_at_desc',
);
