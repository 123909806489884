import { FetchResponse, useGet } from '../../Hooks';
import { Paginated } from '../../Models';
import { PublishedForm } from '../Model';

export type FetchMyFormsParams = {
  pageNum?: number;
  sort?: string;
};

export const useFetchPersonalForms = (
  params?: FetchMyFormsParams,
): FetchResponse<Paginated<'forms', PublishedForm>> => (
  useGet<Paginated<'forms', PublishedForm>>('/me/forms', params)
);
