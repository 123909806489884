import { FunctionComponent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { FetchSubmissionsSort } from '../../Hook';
import { TableCell, TableRow } from '../../../Components';
import { SortableHeaderButton, StickyCell } from '../../../Common/Component';
import { SingleContentCard } from '../../../Content/Model';
import { useContentDefinitionRegistry } from '../../../Content/Hook';
import { FormContent } from '../../Model';

type Props = {
  cards?: SingleContentCard<FormContent>[];
  sort: FetchSubmissionsSort;
  onSort: (sort: FetchSubmissionsSort) => void;
  statusTrackerEnabled?: boolean;
  anonymous?: boolean;
  hideActions?: boolean;
}

export const SubmissionTableHeaderRow: FunctionComponent<Props> = ({
  cards = [],
  sort,
  onSort,
  statusTrackerEnabled = false,
  anonymous,
  hideActions,
}) => {
  const intl = useIntl();
  const { getContentTitle } = useContentDefinitionRegistry();

  return (
    <TableRow>
      { !anonymous && (
        <>
          <StickyCell
            minwidth="10rem"
            hideBorder
          >
            <SortableHeaderButton
              sort={ sort }
              ascValue="created_by_first_name_asc"
              descValue="created_by_first_name_desc"
              onSort={ onSort }
            >
              <FormattedMessage
                id="forms.reportTableHeader.createdBy"
                defaultMessage="Created by"
              />
            </SortableHeaderButton>
          </StickyCell>
          <StickyCell
            minwidth="12rem"
            offset="10rem"
            hideBorder={ statusTrackerEnabled }
          >
            <SortableHeaderButton
              sort={ sort }
              ascValue="created_at_asc"
              descValue="created_at_desc"
              onSort={ onSort }
            >
              <FormattedMessage
                id="forms.reportTableHeader.createdAt"
                defaultMessage="Created"
              />
            </SortableHeaderButton>
          </StickyCell>
        </>
      ) }
      {
        statusTrackerEnabled && (
          <>
            <StickyCell
              minwidth="6rem"
              offset={ anonymous ? undefined : '22rem' }
            >
              <FormattedMessage
                id="forms.reportTableHeader.status"
                defaultMessage="Status"
              />
            </StickyCell>
            { !anonymous && (
              <TableCell>
                <FormattedMessage
                  id="forms.reportTableHeader.daysOpen"
                  defaultMessage="Days open"
                />
              </TableCell>
            ) }
          </>
        )
      }
      {
        cards.map(card => (
          <TableCell
            key={ card.id }
            maxwidth="20rem"
            ellipsisOverflow={ true }
          >
            { getContentTitle(intl, card.content) }
          </TableCell>
        ))
      }
      {
        statusTrackerEnabled && (
          <>
            <TableCell>
              <FormattedMessage
                id="forms.reportTableHeader.closedBy"
                defaultMessage="Closed by"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                id="forms.reportTableHeader.closedAt"
                defaultMessage="Closed"
              />
            </TableCell>
          </>
        )
      }
      { !hideActions && (
        <StickyCell
          justify="end"
        />
      ) }
    </TableRow>
  );
};
