import { FC, ReactNode } from 'react';
import { BadgeCloseX } from 'op-storybook/lib/components/BadgeCloseX/BadgeCloseX';
import { Badge } from 'op-storybook/stories/components/Badge/Badge';

type BadgeProps = {
  id: string;
  label: string;
  startAdornment?: ReactNode
};

type Props = {
  value: BadgeProps[];
  onRemoveClicked?: (badgeProps: BadgeProps) => void;
}

export const BadgeCloud: FC<Props> = ({
  value,
  onRemoveClicked,
}) => {

  return (
    <div
      css={ theme => ({
        display: 'flex',
        gap: theme.new.spacing[1],
        flexWrap: 'wrap',
      }) }
    >
      { value.map(badgeProps => (
        <Badge
          key={ badgeProps.id }
          variant="badge-modern"
          label={ badgeProps.label }
          colour="grey"
          startAdornment={ badgeProps.startAdornment }
          {
            ...onRemoveClicked
              ? {
                endAdornment: (
                  <BadgeCloseX onClick={ () => onRemoveClicked(badgeProps) }/>
                ),
              }
              : {}
          }
        />
      )) }
    </div>
  )
};
