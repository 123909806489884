import { FunctionComponent, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { InputLabel } from '@mui/material';
import { AutocompleteSelectionChanged } from '@ourpeople/shared/Core/Component/Input/Autocomplete/Autocomplete';

import { StyledTagConditionContainer } from './style';
import { LabelModifier } from '../..';
import { TagAutocomplete, TagTypeSelect } from '../../../../Tags/Component';
import { Tag, TagType } from '../../../../Tags/Model';
import { TagCondition } from '../../../Model';
import { FieldValidationErrors } from '../../../../Common/Component';
import { TagParser } from '../../../../Tags/Utility';
import { ValidationTree } from '../../../../Common/Model';
import { useDescendantErrors, useEnvironmentSettings, useUserRoles } from '../../../../Common/Hook';
import { useAuthDescription } from '../../../../Core/Hook';
import { ArrayHelper } from '../../../../Common/Utility';

interface Props {
  condition: TagCondition;
  onChange: (condition: TagCondition) => void;
  validation?: ValidationTree<TagCondition>;
  readonly?: boolean;
  autoFocus?: boolean;
}

const allTagTypes: TagType[] = ['region', 'team', 'department', 'jobtitle', 'skill'];

export const TagConditionBuilder: FunctionComponent<Props> = ({
  condition,
  onChange,
  validation,
  readonly = false,
  autoFocus,
}) => {
  const authDescription = useAuthDescription();
  const { nonTeamTagsEnabled } = useEnvironmentSettings();
  const { userIsSuperAdmin } = useUserRoles();
  const intl = useIntl();
  const tagErrors = useDescendantErrors(validation);
  const modifierOptions = [
    intl.formatMessage({
      id: 'audiences.builder.tagCondition.modifier.anyOf',
      defaultMessage: 'any of',
    }),
    intl.formatMessage({
      id: 'audiences.builder.tagCondition.modifier.noneOf',
      defaultMessage: 'none of',
    }),
  ];

  const allowedIds = useMemo<string[]|undefined>(
    () => {
      if (condition.tagType !== 'team' || userIsSuperAdmin) {
        return undefined;
      }

      return (authDescription?.administratedTags.map(id => id.toString()) || [])
        .concat(condition.new ? [] : condition.tagIds)
        .filter((id, index, self) => self.indexOf(id) === index);
    },
    [condition, userIsSuperAdmin, authDescription],
  );

  const availableTagTypes: TagType[] = useMemo<TagType[]>(
    () => {
      return nonTeamTagsEnabled
        ? allTagTypes
        : ['team'] as TagType[];
    },
    [nonTeamTagsEnabled],
  );

  const whenTagsChanged: AutocompleteSelectionChanged<Tag<TagType>> = (selection) => {
    const newTagIds = [
      ...selection.options.map((tag) => tag.id),
      ...selection.unknownIds,
    ];

    if (!ArrayHelper.containSameValues<string>(newTagIds, condition.tagIds)) {
      onChange({
        ...condition,
        tagIds: newTagIds,
      });
    }
  };

  const whenTypeChanged = (tagType: TagType): void => {
    onChange({
      ...condition,
      tagType,
      tagIds: [],
    });
  };

  const whenModifierChanged = (modifierIndex: number): void => {
    onChange({
      ...condition,
      not: modifierIndex === 1,
    });
  };

  return <StyledTagConditionContainer>
    <div>
      <InputLabel>
        <FormattedMessage
          id="audiences.builder.tagCondition.typeLabel"
          description="Label for the tag condition type select"
          defaultMessage="Type"
        />
      </InputLabel>
      <TagTypeSelect
        availableTypes={ availableTagTypes }
        tagType={condition.tagType}
        onChange={whenTypeChanged}
        readonly={ readonly }
      />
    </div>
    <div>
      <InputLabel>
        <FormattedMessage
          id="audiences.builder.tagCondition.tagsLabel"
          description="Label for the tag condition tags select"
          defaultMessage="People with"
        />
        <LabelModifier
          options={modifierOptions}
          activeIndex={condition.not ? 1 : 0}
          onChange={whenModifierChanged}
          readonly={ readonly }
        />
      </InputLabel>
      <TagAutocomplete
        type={condition.tagType}
        multiple={true}
        selectedIds={condition.tagIds}
        allowedIds={allowedIds}
        onSelectionChanged={whenTagsChanged}
        autoFocus={ autoFocus }
        label={''}
        dense
        readonly={ readonly }
        listStyle="grid"
      />
      <FieldValidationErrors
        fieldName={ intl.formatMessage({
          id: 'audiences.builder.fields.tagCondition.name',
          description: 'Field name for tag condition builder',
          defaultMessage: 'tag condition'
        }) }
        validationErrors={ tagErrors }
        selectionSingular={ TagParser.localisedTagTypeFromInternalTagType(condition.tagType, intl, 1) }
        selectionPlural={ TagParser.localisedTagTypeFromInternalTagType(condition.tagType, intl, Infinity) }
      />
    </div>
  </StyledTagConditionContainer>;
};
