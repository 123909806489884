import { FC, ReactNode, useMemo } from 'react';

import { Stack } from '../../../lib/components/Stack/Stack';
import { Typography } from '../Typography/Typography';
import { StackEnd } from '../../../lib/components/StackEnd/StackEnd';
import { StyleBuilder } from '../../../lib/model/StyleBuilder/StyleBuilder';

type Props = {
  Avatar?: FC<{ size: 'xl' | 'lg' | 'md'; elevate: boolean }>;
  variant: 'banner-avatar' | 'simple';
  breakpoint: 'mobile' | 'desktop';
  title: string;
  supportingText?: string;
  actions?: ReactNode;
  breadcrumb?: ReactNode;
  children?: ReactNode;
};

export const PageHeader: FC<Props> = ({
  Avatar,
  variant = 'simple',
  breakpoint = 'desktop',
  title,
  supportingText,
  actions,
  breadcrumb,
  children,
}) => {
  const styles = useMemo(() => buildStyles({ breakpoint, variant }), [breakpoint, variant]);

  return (
    <Stack
      direction="column"
      gap={ 0 }
      css={ {
        paddingTop: 'var(--header-height)',
      } }
    >
      { variant !== 'simple' && (
        <div
          css={ styles.gradient }
        >
          { breadcrumb }
        </div>
      ) }
      <Stack
        css={ styles.content }
        align="flex-start"
        gap={ breakpoint === 'mobile' ? 0 : 8 }
      >
        { variant !== 'simple' && (
          <div css={ { height: 0 } }>
            <div
              css={ styles.avatarContainer }
            >
              <Avatar
                elevate
                size={ breakpoint === 'mobile' ? 'lg' : 'xl' }
              />
            </div>
          </div>
        ) }
        <Stack
          direction="column"
          css={ { width: '100%' } }
        >
          { variant === 'simple' && breadcrumb }
          <Stack
            align="flex-start"
            gap={ 4 }
            { ...breakpoint === 'mobile' ? { direction: 'column' } : {} }
          >
            <Stack
              direction="column"
              { ...variant === 'banner-avatar' && breakpoint === 'desktop' ? { css: { minHeight: '90px' } } : {} }
            >
              { variant !== 'simple' && (
                <Typography
                  type="display"
                  size={ breakpoint === 'desktop' ? 'sm' : 'xs' }
                  weight="semibold"
                >
                  { title }
                </Typography>
              ) }
              { supportingText && (
                <Typography
                  type="text"
                  size="md"
                  palette={ {
                    colour: 'grey',
                    intensity: 600,
                  } }
                >
                  { supportingText }
                </Typography>
              ) }
              { children && (
                <div>
                  { children }
                </div>
              ) }
            </Stack>
            { actions && (
              <StackEnd { ...breakpoint === 'mobile' ? { direction: 'column' } : {} }>
                { actions }
              </StackEnd>
            ) }
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

type StyleProps = {
  breakpoint: Props['breakpoint'],
  variant: Props['variant'],
};

const buildStyles: StyleBuilder<StyleProps> = ({ breakpoint, variant }) => ({
  gradient: theme => ({
    padding: breakpoint === 'mobile'
      ? theme.new.spacing[2]
      : theme.new.spacing[6],
    background: 'linear-gradient(180deg, #A5C0EE 0%, #FBC5EC 100%)',
    height: breakpoint === 'desktop' ? '240px' : '160px',
  }),
  avatarContainer: {
    flexShrink: 0,
    transform: 'translateY(-50%)',
    ...breakpoint === 'mobile' ? { position: 'absolute', top: 0 } : {},
  },
  content: theme => ({
    position: 'relative',
    padding: breakpoint === 'mobile'
      ? variant === 'simple'
        ? `${ theme.new.spacing[2] } ${ theme.new.spacing[2] } 0`
        : `${ theme.new.spacing[16] } ${ theme.new.spacing[4] } ${ theme.new.spacing[8] }`
      : `${ theme.new.spacing[6] } ${ theme.new.spacing[6] } ${ theme.new.spacing[3] }`
  }),
});
