import styled from '@emotion/styled';

export const StyledContainer = styled.div`
  display: grid;
  grid-gap: ${ props => props.theme.spacing(1) };
  grid-template-columns: auto auto;
`;

export const LoadingSpinnerContainer = styled.div`
  padding: 1rem;
`;

export const StyledItem = styled.div`
  display: grid;
  grid-template-rows: auto auto;
`;

export const StyledItemDetails = styled.span`
  color: ${ props => props.theme.palette.grey['500'] };
  font-size: ${ props => props.theme.fontSize.sm };
`;
