import { useIntl } from 'react-intl';
import { useMemo } from 'react';

import { DateRangeOption } from '../../Core/Component/Input/DateOffsetInput/DateOffsetInput';

type NonNullableDateRangeOption = Omit<DateRangeOption, 'value'> & {
  value: NonNullable<DateRangeOption['value']>;
};

export const useReportDateRangeInputOptions = <T extends boolean | undefined>(displayEmpty: T): T extends true ? DateRangeOption[] : NonNullableDateRangeOption[] => {
  const intl = useIntl();
  const displayEmptyOption: DateRangeOption = useMemo(() => ({
    label: intl.formatMessage({
      description: 'Label for all previous dates option in reports filter.',
      defaultMessage: 'All time',
    }),
    value: null,
  }), [intl]);

  return useMemo(() => [
    ...(displayEmpty ? [displayEmptyOption] : []),
    {
      label: intl.formatMessage({
        description: 'Label for previous 7 days option in reports filter.',
        defaultMessage: 'Last 7 days',
      }),
      value: {
        type: 'relative',
        offsetInSeconds: -SEVEN_DAYS_IN_SECONDS,
        round: {
          to: 'startOfDay',
          offsetInSeconds: 0,
        },
      },
    },
    {
      label: intl.formatMessage({
        description: 'Label for previous 14 days option in reports filter.',
        defaultMessage: 'Last 14 days',
      }),
      value: {
        type: 'relative',
        offsetInSeconds: -FOURTEEN_DAYS_IN_SECONDS,
        round: {
          to: 'startOfDay',
          offsetInSeconds: 0,
        },
      },
    },
    {
      label: intl.formatMessage({
        description: 'Label for previous 28 days option in reports filter.',
        defaultMessage: 'Last 28 days',
      }),
      value: {
        type: 'relative',
        offsetInSeconds: -TWENTY_EIGHT_DAYS_IN_SECONDS,
        round: {
          to: 'startOfDay',
          offsetInSeconds: 0,
        },
      },
    },
  ], [displayEmpty, displayEmptyOption, intl]) as T extends true ? DateRangeOption[] : NonNullableDateRangeOption[];
};

const SEVEN_DAYS_IN_SECONDS = 604800;
const FOURTEEN_DAYS_IN_SECONDS = 1.21e6;
const TWENTY_EIGHT_DAYS_IN_SECONDS = 2.419e6;
