import { FC } from 'react';

import { AnonymousSettings } from '../../Hook/useFetchAnonymousSettings';
import { PendingRegistrationConfirmation } from '../PendingRegistrationConfirmation/PendingRegistrationConfirmation';
import {
  SmsAndEmailRegistrationConfirmation
} from '../SmsAndEmailRegistrationConfirmation/SmsAndEmailRegistrationConfirmation';
import { EmailRegistrationConfirmation } from '../EmailRegistrationConfirmation/EmailRegistrationConfirmation';
import { SmsRegistrationConfirmation } from '../SmsRegistrationConfirmation/SmsRegistrationConfirmation';

type Props = {
  pending: boolean;
  emailAddress?: string;
  phoneNumber?: string;
  anonymousSettings: AnonymousSettings;
};

export const RegistrationConfirmation: FC<Props> = ({
  anonymousSettings,
  pending,
  emailAddress,
  phoneNumber,
}) => (
  <>
    {
      pending
        ? <PendingRegistrationConfirmation anonymousSettings={ anonymousSettings }/>
        : emailAddress
          ? phoneNumber
            ? (
              <SmsAndEmailRegistrationConfirmation
                anonymousSettings={ anonymousSettings }
                emailAddress={ emailAddress }
                phoneNumber={ phoneNumber }
              />
            )
            : (
              <EmailRegistrationConfirmation
                anonymousSettings={ anonymousSettings }
                emailAddress={ emailAddress }
              />
            )
          : phoneNumber && (
            <SmsRegistrationConfirmation
              anonymousSettings={ anonymousSettings }
              phoneNumber={ phoneNumber }
            />
          )
    }
  </>
);
