import { FC, MouseEventHandler } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';

import { EditorContent } from '../../../Content/Model';
import { Flex } from '../../../Common/Component';
import { useContentDefinitionRegistry } from '../../../Content/Hook';
import { StyledBroadcastContentPreview, StyledButtonContainer, StyledNextIcon, StyledPreviousIcon } from './style';
import ChevronIcon from '../../../Assets/img/icons/monochrome/chevron.svg';

type Props = {
  content: EditorContent;
  onPreviousClicked: MouseEventHandler;
  onNextClicked: MouseEventHandler;
  previousButtonHidden?: boolean;
  nextButtonHidden?: boolean;
  cardCount: number;
  buttonColor?: 'inherit' | 'primary';
};

export const BroadcastContentPreview: FC<Props> = ({
  content,
  onPreviousClicked,
  onNextClicked,
  previousButtonHidden,
  nextButtonHidden,
  cardCount,
  buttonColor = 'primary',
}) => {
  const { getPreviewComponent } = useContentDefinitionRegistry();
  const PreviewComponent = getPreviewComponent(content);
  return (
    <StyledBroadcastContentPreview>
      <PreviewComponent
        editorContent={ content }
        context={ { cardCount } }
        onReady={ () => null }
      />
      <StyledButtonContainer>
        <div>
          { !previousButtonHidden && (
            <Button
              onClick={ onPreviousClicked }
              color={ buttonColor }
            >
              <Flex
                gap={ 2 }
              >
                <StyledPreviousIcon>
                  <ChevronIcon/>
                </StyledPreviousIcon>
                <FormattedMessage
                  id="broadcasts.content.preview.previous"
                  description="Previous card button label"
                  defaultMessage="Previous"
                />
              </Flex>
            </Button>
          ) }
        </div>
        <div>
          { !nextButtonHidden && (
            <Button
              onClick={ onNextClicked }
              color={ buttonColor }
            >
              <Flex
                gap={ 2 }
              >
                <FormattedMessage
                  id="broadcasts.content.preview.next"
                  description="Next card button label"
                  defaultMessage="Next"
                />
                <StyledNextIcon>
                  <ChevronIcon/>
                </StyledNextIcon>
              </Flex>
            </Button>
          ) }
        </div>
      </StyledButtonContainer>
    </StyledBroadcastContentPreview>
  );
};
