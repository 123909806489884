import styled from '@emotion/styled';
import {Button} from '@mui/material';

export const StyledFormContentPreview = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
`;

export const ScrollArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.5rem;
  overflow: hidden;
  position: relative;

  > div {
    overflow-y: auto;
  }
`;

export const Progress = styled.div`
  font-size: 1rem;
  color: ${props => props.theme.palette.grey[400]};
  font-weight: 500;
  text-align: left;
  margin-bottom: 0.25rem;
`;

export const Title = styled.div`
  font-size: 1.25rem;
  font-weight: 500;
`;

export const Description = styled.div`
  font-size: 1.15rem;
  word-break: break-word;
  white-space: pre-wrap;
`;

export const ButtonRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0.5rem;

  > div:last-child {
    display: flex;
  }
`;

export const PreviousButton = styled(Button)`
  svg {
    transform: rotate(90deg);
    margin-right: 0.5rem;
  }
`;

export const NextButton = styled(Button)`
  margin-left: auto;

  svg {
    transform: rotate(-90deg);
    margin-left: 0.5rem;
  }
`;

export const StartButton = styled(Button)`
  margin: auto auto;
  display: block;
`;

export const StyledFormHeader = styled.div`
  padding: ${ props => props.theme.spacing(1) };
`;

export const StyledPager = styled.div`
  margin: ${ props => props.theme.spacing(1) };
`;

export const AnonymousMessageHint = styled.div `
  background: ${props => props.theme.palette.grey[300]};
  padding: 0.25rem 0.5rem;
  display: flex;
  border-radius: 0.5rem;

  svg {
    margin-top: 0.5rem;
    margin-right: 0.5rem;
    color: ${props => props.theme.palette.primary.main};
    width: 2rem;
  }

  div {
    font-weight: bold;
    font-size: 1rem;
  }

  div:last-child {
    font-weight: normal;
    font-size: 0.9rem;
  }
`;
