import { FC } from 'react';
import { Redirect, Switch, useRouteMatch } from 'react-router-dom';

import { GuardedRoute } from '../../../Common/Component';
import { BroadcastReportPage } from '../../../Sections/Broadcasts/Report/Components';
import {
  BuildTemplatePage,
  CreateBroadcastPage,
  CreateCategoryPage,
  EditBroadcastPage,
  EditCategoryPage,
  ListCategoriesPage,
  ListPage
} from '..';
import { BroadcastContentDefinitionRegistryProvider } from '../../Provider';
import { BroadcastsPermission } from '../../Model';

export const BroadcastsRouter: FC = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <GuardedRoute
        path={ `${ match.path }/categories/create` }
        permissions={ [BroadcastsPermission.CATEGORIES_CREATE] }
        redirect={ match.path }
      >
        <CreateCategoryPage/>
      </GuardedRoute>
      <GuardedRoute
        path={ `${ match.path }/categories/:categoryId/edit` }
        permissions={ [BroadcastsPermission.CATEGORIES_UPDATE] }
        redirect={ match.path }
      >
        <EditCategoryPage/>
      </GuardedRoute>
      <Redirect
        from={ `${ match.path }/categories/:categoryId` }
        to={ `${ match.path }/categories/:categoryId/edit` }
        exact
      />
      <GuardedRoute
        path={ `${ match.path }/categories` }
        permissions={ [BroadcastsPermission.CATEGORIES_CREATE] }
        redirect={ match.path }
      >
        <ListCategoriesPage/>
      </GuardedRoute>
      <BroadcastContentDefinitionRegistryProvider>
        <GuardedRoute
          path={ `${ match.path }/:broadcastId/report` }
          redirect="/broadcasts"
          permissions={ [
            BroadcastsPermission.REPORT,
          ] }
        >
          <BroadcastReportPage/>
        </GuardedRoute>
        <GuardedRoute
          exact
          path={ match.path }
          permissions={ [
            BroadcastsPermission.UPDATE,
          ] }
        >
          <ListPage/>
        </GuardedRoute>
        <GuardedRoute
          path={ `${ match.path }/create` }
          redirect="/broadcasts"
          permissions={ [
            BroadcastsPermission.UPDATE,
          ] }
        >
          <CreateBroadcastPage/>
        </GuardedRoute>
        <GuardedRoute
          path={ `${ match.path }/:broadcastId/edit` }
          redirect="/broadcasts"
          permissions={ [
            BroadcastsPermission.READ,
            BroadcastsPermission.UPDATE,
          ] }
        >
          <EditBroadcastPage/>
        </GuardedRoute>
        <GuardedRoute
          path={ `${ match.path }/build-template` }
          redirect="/broadcasts"
          permissions={ [
            BroadcastsPermission.UPDATE,
          ] }
        >
          <BuildTemplatePage/>
        </GuardedRoute>
      </BroadcastContentDefinitionRegistryProvider>
    </Switch>
  );
};
