import { Content, DraftContent } from '../../Content/Model';
import { UserInteraction } from '../../Models/UserInteraction';

export type FormContent = Content & {
  id: string;
  created: UserInteraction;
  title: string;
};

export type FormRequest = FormContent & {
  text: string | null;
};

export const CONTENT_TITLE_MAX_LENGTH = 512;
export const CONTENT_TITLE_MIN_LENGTH = 1;

export const REQUEST_TEXT_MAX_LENGTH = 4096;

export type DraftFormContent = DraftContent & {
  id?: string;
  title: string;
};

export type DraftFormRequest = DraftFormContent & {
  text: string | null;
};

