import { FunctionComponent, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { useIntl } from 'react-intl';

import { Category } from '../../Model';
import { LoadingSpinner } from '../../../Components';
import { TableMenu } from '../../../Components/Content/TableMenu';
import EditIcon from '../../../Assets/img/icons/monochrome/edit.svg';
import DeleteIcon from '../../../Assets/img/icons/monochrome/trash.svg';
import { ToastMessage } from '../../../Components/Feedback/Toast';
import { ApiContext } from '../../../Contexts';
import { useUserRoles } from '../../../Common/Hook';

interface Props {
  category: Category;
  onActionPerformed: (reload: boolean, toastMessage: ToastMessage) => void;
}

export const FormCategoriesTableMenu: FunctionComponent<Props> = ({
  category,
  onActionPerformed,
}) => {
  const [busy, setBusy] = useState<boolean>(false);
  const api = useContext(ApiContext);
  const intl = useIntl();
  const { userIsSuperAdmin } = useUserRoles();

  const whenDeleteClicked = (): void => {
    if (api) {
      setBusy(true);
      api.delete(`/forms/categories/${ category.id }`)
        .then(() => {
          setTimeout(() => {
            setBusy(false);
            onActionPerformed(true, {
              severity: 'success',
              content: intl.formatMessage({
                id: 'forms.delete.successMessage',
                defaultMessage: '{name} deleted successfully.',
              }, {
                name: category.name,
              }),
            });
          }, 50);
        })
        .catch(() => {
          setBusy(false);
          onActionPerformed(false, {
            severity: 'error',
            content: intl.formatMessage({
              id: 'forms.delete.failureMessage',
              defaultMessage: '{name} could not be deleted.',
            }, {
              name: category.name,
            }),
          });
        });
    }
  };

  return (
    <>
      {
        busy
          ? <LoadingSpinner/>
          : (
            <TableMenu rowKey={ category.id }>
              {
                userIsSuperAdmin
                  && <MenuItem
                    component={ Link }
                    to={ `/forms/categories/${ category.id }/edit` }
                  >
                    <ListItemIcon><EditIcon/></ListItemIcon>
                    <ListItemText
                      primary={
                        intl.formatMessage({
                          id: 'forms.list.edit',
                          defaultMessage: 'Edit',
                        })
                      }
                    />
                  </MenuItem>
              }
              {
                userIsSuperAdmin
                  && <MenuItem onClick={ whenDeleteClicked }>
                    <ListItemIcon><DeleteIcon/></ListItemIcon>
                    <ListItemText
                      primary={
                        intl.formatMessage({
                          id: 'forms.list.delete',
                          defaultMessage: 'Delete',
                        })
                      }
                    />
                  </MenuItem>
              }
            </TableMenu>
          )
      }
    </>
  );
};
