import { createContext } from 'react';
import * as ReactGA from 'react-ga';

export interface GoogleAnalyticsContextValue {
  ga: typeof ReactGA;
}

export const GoogleAnalyticsContext = createContext<GoogleAnalyticsContextValue>({
  ga: ReactGA,
});
