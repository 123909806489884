import {MinimalPerson, Paginated} from '../../../Models';
import {Api} from '../../../Services';
import {ArrayHelper} from '../../../Common/Utility';

export type Response = Paginated<'people', MinimalPerson>;

export enum SortKey {
  RelevanceAsc = 'relevance_asc',
  RelevanceDesc = 'relevance_desc',

  IdAsc = 'id_asc',
  IdDesc = 'id_desc',

  FirstNameAsc = 'first_name_asc',
  FirstNameDesc = 'first_name_desc',

  LastNameAsc = 'last_name_asc',
  LastNameDesc = 'last_name_desc',

  CreatedAtAsc = 'created_at_asc',
  CreatedAtDesc = 'created_at_desc',
}

export type Options = {
  pageNum?: number;
  search?: string;
  ids?: string[];
  sort?: SortKey[];
}

export type Query = {
  pageNum?: number;
  search?: string;
  sort?: string;
  ids?: string;
}

export const optionsToQuery = (
  options: Options
): Query => ({
  pageNum: options.pageNum,
  search: options.search,
  sort: ArrayHelper.coalesceEmpty(options.sort ?? [], undefined)?.join(','),
});

export const path = '/people';

export const send = (api: Api, options: Options) =>
  api.get<Response>(path, {
    params: optionsToQuery(options),
  });
