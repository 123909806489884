import { Validate } from '../../../Common/Utility';
import { ValidationTree, Validator } from '../../../Common/Model';
import { RequestValidator } from './RequestValidator';
import {
  DraftNumberRequest,
  NUMBER_REQUEST_MAX_MAX,
  NUMBER_REQUEST_MAX_MIN,
  NUMBER_REQUEST_MIN_MAX,
  NUMBER_REQUEST_MIN_MIN
} from '../../Model/ContentTypes';

export class NumberRequestValidator {
  public static validateMin = (content: DraftNumberRequest): ValidationTree<number> => ({
    errors: new Validator<number>([
      Validate.isNumeric,
      min => Validate.greaterThanEq(min, NUMBER_REQUEST_MIN_MIN),
      min => Validate.lessThanEq(min, Math.min(content.max - 1, NUMBER_REQUEST_MIN_MAX)),
    ]).validate(content.min),
    children: {},
  });

  public static validateMax = (content: DraftNumberRequest): ValidationTree<number> => ({
    errors: new Validator<number>([
      Validate.isNumeric,
      max => Validate.greaterThanEq(max, Math.max(content.min + 1, NUMBER_REQUEST_MAX_MIN)),
      max => Validate.lessThanEq(max, NUMBER_REQUEST_MAX_MAX),
    ]).validate(content.max),
    children: {},
  });

  public static validate = (content: DraftNumberRequest): ValidationTree<DraftNumberRequest> => ({
    errors: [
      ...RequestValidator.validate(content).errors,
    ],
    children: {
      ...RequestValidator.validate(content).children,
      min: NumberRequestValidator.validateMin(content),
      max: NumberRequestValidator.validateMax(content),
    }
  });
}
