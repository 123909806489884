import styled from '@emotion/styled';

export const StyledButtonContainer = styled.div<{ align: 'start' | 'end' }>`
  display: flex;
  flex-direction: row;
  gap: ${ props => props.theme.spacing(1) };

  ${ props => props.align === 'end'
    ? 'margin-inline-start: auto;'
    : 'margin-inline-end: auto;'
  }

  ${ props => props.theme.breakpoints.down('md') } {
    flex-direction: column;
    margin-inline-start: initial;
    width: 100%;
  }
`;

export const StyledButtonRow = styled.div`
  display: flex;
`;
