import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Heading } from '@ourpeople/shared/Core/Component/Content';
import { DebouncedSearchInput } from '@ourpeople/shared/Core/Component/Input/DebouncedSearchInput/DebouncedSearchInput';

import { PaginatedTable, SortableHeaderCell, TableCell, TableRow, UserStatus } from '../../../Components';
import { FetchAudienceMembersQuery, FetchAudienceMembersResult, FetchAudienceMembersSort } from '../../Hook';
import { FlexPullRight, VerticallySpaced, Flex } from '../../../Common/Component';
import { FilteredCount } from './style';
import { RequestState } from '../../../Models';

interface Props {
  id?: string;
  audienceMembersResult?: FetchAudienceMembersResult;
  query?: FetchAudienceMembersQuery,
  search?: string;
  onSortChanged: (sort: FetchAudienceMembersSort) => void,
  onSearchChanged: (search: string) => void,
  onPageChanged: (page: number) => void;
  onRetryClicked: () => void;
  requestState: RequestState;
  showStatus?: boolean;
}

export const AudienceMemberList: FunctionComponent<Props> = ({
  id,
  audienceMembersResult,
  query,
  search,
  onSearchChanged,
  onSortChanged,
  onPageChanged,
  onRetryClicked,
  requestState,
  showStatus = true,
}) => {
  const activeSort = query?.sort ? query.sort[0] : '';
  const memberList = audienceMembersResult?.members || [];
  const pagination = audienceMembersResult?.pagination;
  const audienceSize = audienceMembersResult?.audienceSize || 0;
  const filteredMemberCount = (audienceMembersResult?.audienceSize || 0) - (pagination?.itemCount || 0);

  return (
    <VerticallySpaced
      gap={ 1 }
    >
      <Flex align="flex-end">
        <div id={ id }/>
        <Heading type="h2">
          <FormattedMessage
            id="audiences.audienceMemberTable.heading"
            description="Audience member table heading with current audience member count."
            defaultMessage="Current audience size: { memberCount }"
            values={ {
              memberCount: audienceSize,
            } }
          />
          {
            !!filteredMemberCount && (
              <FilteredCount>
                <FormattedMessage
                  id="audiences.audienceMemberTable.filteredCount"
                  description="Section of audience member heading denoting how many members matching the audience are currently filtered from the view"
                  defaultMessage="{ filteredMemberCount } filtered from view"
                  values={ {
                    filteredMemberCount,
                  } }
                />
              </FilteredCount>
            )
          }
        </Heading>
        <FlexPullRight>
          <DebouncedSearchInput
            value={ search || '' }
            onChange={ onSearchChanged }
          />
        </FlexPullRight>
      </Flex>
      <PaginatedTable
        headerRow={
          <TableRow>
            <SortableHeaderCell
              sort={ activeSort }
              ascValue="first_name_asc"
              descValue="first_name_desc"
              onSort={ onSortChanged }
            >
              <FormattedMessage
                id="audiences.audienceMemberTable.firstName"
                description="Employee first name column header"
                defaultMessage="First name"
              />
            </SortableHeaderCell>
            <SortableHeaderCell
              sort={ activeSort }
              ascValue="last_name_asc"
              descValue="last_name_desc"
              onSort={ onSortChanged }
            >
              <FormattedMessage
                id="audiences.audienceMemberTable.lastName"
                description="Employee last name column header"
                defaultMessage="Last name"
              />
            </SortableHeaderCell>
            { showStatus && (
              <TableCell>
                <FormattedMessage
                  id="audiences.audienceMemberTable.status"
                  description="Status column header"
                  defaultMessage="Status"
                />
              </TableCell>
            ) }
          </TableRow>
        }
        rows={ memberList }
        rowRender={ member => (
          <TableRow key={ member.person.id }>
            <TableCell>
              { member.person.firstName }
            </TableCell>
            <TableCell>
              { member.person.lastName }
            </TableCell>
            { showStatus && (
              <TableCell>
                <UserStatus status={ member.person.status }/>
              </TableCell>
            ) }
          </TableRow>
        ) }
        pageNum={ query?.pageNum || 1 }
        onPageChanged={ onPageChanged }
        requestState={ requestState }
        onRetryClicked={ onRetryClicked }
        pagination={ pagination }
      />
    </VerticallySpaced>
  );
};
