import { FunctionComponent, ReactNode } from 'react';

import { StyledMetricContainer } from './style';
import { MetricHeading } from '..';
import { Flex, FlexPullRight } from '../../../Common/Component';

type Props = {
  title: ReactNode;
  span?: number;
  actions?: ReactNode;
  className?: string;
}

export const MetricContainer: FunctionComponent<Props> = ({
  title,
  span,
  actions,
  className,
  children,
}) => (
  <StyledMetricContainer
    span={ span }
    className={ className }
  >
    <Flex align="flex-start">
      <MetricHeading>{ title }</MetricHeading>{ !!actions && <FlexPullRight>{ actions }</FlexPullRight> }
    </Flex>
    { children }
  </StyledMetricContainer>
);
