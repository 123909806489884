import { IntlShape } from 'react-intl';
import {
  BlankValidationErrorDefinition
} from 'op-storybook/lib/model/Validation/Definition/BlankValidationErrorDefinition';
import FormBlockIcon from 'op-storybook/lib/assets/icon/figma/file-06.svg';

import { BlockErrorDefinitions, BroadcastBlockDefinition } from '../../Model/BroadcastBlockDefinition';
import { BroadcastBlock } from '../../Model/Broadcast';
import { UniqueIdGenerator } from '../../../../Common/Utility';
import { FormBlockExpandedPreview } from '../../Component/Blocks/FormBlock/FormBlockExpandedPreview';
import { FormBlockInlineForm } from '../../Component/Blocks/FormBlock/FormBlockInlineForm';
import { FormBlockForm } from '../../Component/Blocks/FormBlock/FormBlockForm';

export type FormBlock = {
  localId: string;
  kind: 'form';
  attributes: {
    formId: string;
  };
};

export class FormBlockDefinition implements BroadcastBlockDefinition<FormBlock> {
  public readonly prioritise = false;
  public readonly kind = 'form';
  public readonly localisedKind: string;
  public readonly errorDefinitions: BlockErrorDefinitions<FormBlock>;

  constructor(intl: IntlShape) {
    this.localisedKind = intl.formatMessage({
      description: 'Name for form block used in broadcast editor',
      defaultMessage: 'Form',
    });

    this.errorDefinitions = {
      formId: [
        new BlankValidationErrorDefinition(intl => intl.formatMessage({
          description: 'Error message when broadcast form block form ID is left blank.',
          defaultMessage: 'No form selected.',
        })),
      ],
    };
  }

  public definesBlock(block: BroadcastBlock): block is FormBlock {
    return block.kind === 'form';
  }

  public initialiseBlock(): FormBlock {
    return {
      localId: UniqueIdGenerator.generate(),
      kind: 'form',
      attributes: {
        formId: '',
      },
    };
  }

  public IconComponent = FormBlockIcon;
  public ExpandedPreviewComponent = FormBlockExpandedPreview;
  public InlineFormComponent = FormBlockInlineForm;
  public FormComponent = FormBlockForm;
}
