import { useIntl } from 'react-intl';
import { useMemo } from 'react';

import { Audience } from '../Model';
import { ConditionIdentifier } from '../Utility';

export const useAudienceDescription = (audience: Audience): string => {
  const intl = useIntl();

  return useMemo(() => {
    const conditions = audience.segments[0]?.conditions || [];
    const containsEveryoneCondition = !!conditions.find(ConditionIdentifier.conditionIsEveryoneCondition);

    return conditions.length === 1 && containsEveryoneCondition
      ? intl.formatMessage({
        id: 'audiences.everyoneDescription',
        description: 'Description of an audience containing everyone.',
        defaultMessage: 'Everyone',
      })
      : intl.formatMessage({
        id: 'audiences.description',
        description: 'Description of a given audience.',
        defaultMessage: '{ conditionCount, plural, one { # condition } other { # conditions } }',
      }, {
        conditionCount: containsEveryoneCondition ? conditions.length - 1 : conditions.length,
      })
  }, [intl, audience]);
};
