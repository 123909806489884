import { useMemo } from 'react';

import { FetchEventsParams, useFetchEvents } from '.';
import { FetchResponse } from '../../Hooks';
import { Paginated } from '../../Models';
import { Cover } from '../Model';

export type FetchCoversParams = Omit<FetchEventsParams, 'types'>;

export const useFetchCovers = (params: FetchCoversParams): FetchResponse<Paginated<'events', Cover>> => {
  const eventsParams = useMemo<FetchEventsParams>(() => ({
    ...params,
    types: 'covercontent',
  }), [params]);

  return useFetchEvents(eventsParams) as FetchResponse<Paginated<'events', Cover>>;
}
