import { FC } from 'react';
import { FunctionInterpolation } from '@emotion/react';

import grid from '../../../lib/assets/DecorativePattern/grid.svg?url';
import gridDot from '../../../lib/assets/DecorativePattern/grid-dot.svg?url';
import circles from '../../../lib/assets/DecorativePattern/circle.svg?url';
import squares from '../../../lib/assets/DecorativePattern/square.svg?url';
import { MaskedBackgroundImage } from '../../../lib/components/MaskedBackgroundImage/MaskedBackgroundImage';
import { defaultTheme } from '../../../lib/const/defaultTheme';

type Props = {
  pattern: 'grid' | 'grid-dots' | 'circles' | 'squares';
  size: 'sm' | 'md' | 'lg';
  className?: string;
};

export const DecorativePattern: FC<Props> = ({
  pattern,
  size,
  className,
}) => {
  const sideLength = size === 'sm' ? '336px' : size === 'md' ? '480px' : '768px';
  const imageUrl = pattern === 'grid'
    ? grid
    : pattern === 'grid-dots'
      ? gridDot
      : pattern === 'circles'
        ? circles
        : squares;

  return (
    <MaskedBackgroundImage
      className={ className }
      style={ {
        '--width': `${ sideLength }`,
        '--height': `${ sideLength }`,
      } }
      css={ backgroundCss }
      imageUrl={ imageUrl }
      mask="radial"
    />
  );
}

const backgroundCss: FunctionInterpolation<typeof defaultTheme> = () => ({
  width: 'var(--width)',
  height: 'var(--height)',
  pointerEvents: 'none',
});
