import { IntlShape } from 'react-intl';

import { BroadcastContentDefinition, DraftMediaContent, MediaContent } from '../../../Model';
import { SingleContentCard, EditorContent } from '../../../../Content/Model';
import VideoIcon from '../../../../Assets/img/icons/monochrome/content/video.svg';
import { VideoContentEditor, VideoContentPreview } from '../../../Component';
import { MediaContentHelper } from '../../../Utility';
import { ViewResponse } from '../../../../Inbox/Model';
import { LiveVideoContent } from '../../../../Inbox/Component';
import { PrepareStatus } from '../../../../Files/Hook';
import { Upload } from '../../../../Types';
import { CardTransformer } from '../../../../Content/Utility';
import { VideoContentValidator } from '../../Validators';
import { EnvironmentSettings } from '../../../../Models';

export type DraftVideoContent = DraftMediaContent<'videocontent'> & {
  description: string | null;
  thumbnailKey: string | null;
};
export type VideoContent = MediaContent<'videocontent'> & {
  description: string | null;
  thumbnailKey: string | null;
};
export type VideoEditorContent = EditorContent<DraftVideoContent> & {
  prepareStatus: PrepareStatus;
  media: Upload | null;
};

const contentType = 'videocontent';
export class VideoContentDefinition implements BroadcastContentDefinition<VideoContent, VideoEditorContent, ViewResponse> {
  public readonly id = 'video';
  public readonly availableToCreate = true;
  public readonly new = false;
  public readonly richText = true;
  public readonly contentTypes = [contentType];
  public readonly categoryId = 'content';
  public readonly IconComponent = VideoIcon;
  public readonly EditorComponent = VideoContentEditor;
  public readonly PreviewComponent = VideoContentPreview;
  public readonly LiveComponent = LiveVideoContent;
  public readonly SubmittedComponent = (): null => null;
  public validate = VideoContentValidator.validate;

  public definesContentType = (type: string): boolean => (
    this.contentTypes.includes(type)
  );

  public getContentTitle = (intl: IntlShape, content: VideoContent): string => (
    content.text || this.getDefaultTitle(intl)
  );

  public getLocalisedType = (intl: IntlShape): string => (
    intl.formatMessage({
      id: 'broadcasts.content.video.type',
      description: 'Localised name for video content type.',
      defaultMessage: 'Video',
    })
  );

  public getDraftContentTitle = (intl: IntlShape, draftContent: DraftVideoContent): string => (
    draftContent.text || this.getDefaultTitle(intl)
  );

  public hasRequiredFeatureFlags = (environmentSettings: EnvironmentSettings): boolean => (
    !!environmentSettings.videoTypeEnabled
  );

  public createEditorContent = (): VideoEditorContent => {
    const mediaContent = MediaContentHelper.createEditorContent(contentType);
    return {
      ...mediaContent,
      card: {
        ...mediaContent.card,
        content: {
          ...mediaContent.card.content,
          description: null,
          thumbnailKey: null,
        },
      },
      prepareStatus: 'pending',
    };
  };

  public transformCard = (card: SingleContentCard<VideoContent>): VideoEditorContent => ({
    id: card.id,
    media: card.content.media,
    card: CardTransformer.transformCard(card, {
      type: card.content.type,
      text: card.content.text,
      media: card.content.media?.id.toString(),
      mandatory: card.mandatory,
      description: card.content.description,
      thumbnailKey: card.content.thumbnailKey,
    }),
    prepareStatus: 'complete',
  });

  private getDefaultTitle = (intl: IntlShape): string => (
    intl.formatMessage({
      id: 'broadcasts.content.video.defaultTitle',
      description: 'Default title for video content.',
      defaultMessage: 'Untitled video card',
    })
  );
}
