import styled from '@emotion/styled';

import { StyledLegend } from '../Legend/style';

export const StyledDoughnutChartMetric = styled.div`
  display: grid;
  height: 100%;
  grid-template-columns: 1fr 1.35fr;

  canvas {
    width: 100% !important;
  }
`;

export const ChartContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const FigureContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${ StyledLegend } {
    margin-top: auto;
  }
`;
