import { Dispatch, FunctionComponent, SetStateAction, useCallback, useContext, useMemo } from 'react';

import { ToastMessage } from '../../../Components';
import { FetchEventsParams, sortStringIsValid, useFetchEvents } from '../../Hook';
import { EventsTable, MeetingsAndTrainingsTableFilters } from '..';
import { ApiContext } from '../../../Contexts';
import { dateOffsetFromIsValid, transformOffsetToRange } from '../../../Common/Utility';

interface Props {
  query: Record<string, string>;
  setQuery: Dispatch<SetStateAction<Record<string, string>>>;
  addToastMessage: (message: ToastMessage) => void;
}

export const MeetingsAndTrainingsTable: FunctionComponent<Props> = ({
  query,
  setQuery,
  addToastMessage,
}) => {
  const api = useContext(ApiContext);
  const fetchMeetingsAndTrainingsParams = useMemo<FetchEventsParams>(() => {
    const dateRange = query.start
      ? {
        start: query.start,
        end: query.end,
      }
      : transformOffsetToRange({
        type: 'offset',
        from: dateOffsetFromIsValid(query.from) ? query.from : 'start',
        offset: null,
      });
    return {
      pageNum: +query.pageNum || 1,
      sort: sortStringIsValid(query.sort) ? query.sort : (dateRange.end ? 'start_at_desc' : 'start_at_asc'),
      statuses: query.statuses,
      teamIds: query.teamIds,
      types: query.types || 'meetingcontent,trainingcontent',
      search: query.search,
      ...(
        dateRange.start && dateRange.end
          ? {
            startSince: dateRange.start,
            endUntil: dateRange.end,
          }
          : (
            (dateOffsetFromIsValid(query.from) ? query.from : 'start') === 'start'
              ? { endSince: 'now' }
              : { endUntil: 'now' }
          )
      ),
    }
  }, [query]);

  const [
    fetchMeetingsAndTrainingsResult,
    fetchMeetingsAndTrainingsRequestState,
    retryFetchMeetingsAndTrainings,
  ] = useFetchEvents(fetchMeetingsAndTrainingsParams);
  const meetingsAndTrainings = useMemo(
    () => fetchMeetingsAndTrainingsResult?.content?.events || [],
    [fetchMeetingsAndTrainingsResult?.content],
  );
  const pagination = fetchMeetingsAndTrainingsResult?.content?.pagination;

  const cancelMeetingOrTraining = useCallback(
    (id: string, type: string) => {
      if (!api) {
        return Promise.reject();
      }
      return type === 'meetingcontent' || type === 'eventcontent'
        ? api.put(`/meetings/${ id }/cancel`)
        : api.put(`/trainings/${ id }/cancel`);
    },
    [api],
  );

  return (
    <>
      <MeetingsAndTrainingsTableFilters
        query={ query }
        setQuery={ setQuery }
      />
      <EventsTable
        events={ meetingsAndTrainings }
        pagination={ pagination }
        requestState={ fetchMeetingsAndTrainingsRequestState }
        query={ query }
        setQuery={ setQuery }
        addToastMessage={ addToastMessage }
        reload={ retryFetchMeetingsAndTrainings }
        cancel={ cancelMeetingOrTraining }
      />
    </>
  );
};
