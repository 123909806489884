import styled from '@emotion/styled';

export const StyledDialogContent = styled.div`
  padding: 0 1.5rem 1.5rem 1.5rem;

  .MuiInputLabel-root {
     font-size: 0.9rem;
     font-weight: normal;
     margin-bottom: 0.75rem;
     color: ${props => props.theme.palette.secondary.dark};
  }

  .MuiDialogTitle-root {
    padding: 1.6rem 0;
  }
`;
