import { IntlShape } from 'react-intl';

import { TextRequestFields, TextRequestPreview } from '../../Component/Content';
import {
  DraftTextRequest,
  TEXT_REQUEST_MAX_LENGTH_MAX,
  TEXT_REQUEST_MIN_LENGTH_MIN,
  TextRequest
} from '../../Model/ContentTypes';
import { TextRequestValidator } from '../../Utility/Validation';
import { SingleContentCard, EditorContent } from '../../../Content/Model';
import TextRequestIcon from '../../../Assets/img/icons/monochrome/content/text.svg';
import { UniqueIdGenerator } from '../../../Common/Utility';
import { CardFactory, CardTransformer } from '../../../Content/Utility';
import { DraftResponse, FormContentDefinition, Response } from '../../Model';
import { EnvironmentSettings } from '../../../Models';
import { LiveTextRequest } from '../../Component';
import { SubmittedTextRequest } from '../../Component/SubmittedContent';

export type TextRequestEditorContent = EditorContent<DraftTextRequest>;

type BaseTextResponse = {
  contentType: 'textRequest';
  text: string;
};
export type DraftTextResponse = DraftResponse<BaseTextResponse>;
export type TextResponse = Response<BaseTextResponse>;

export class TextRequestDefinition implements FormContentDefinition<
  TextRequest,
  TextRequestEditorContent,
  DraftTextResponse,
  TextResponse
> {
  public readonly id = 'textRequest';
  public readonly availableToCreate = true;
  public readonly new = false;
  public readonly richText = false;
  public readonly contentTypes = ['textRequest'];
  public readonly EditorComponent = TextRequestFields;
  public readonly PreviewComponent = TextRequestPreview;
  public readonly LiveComponent = LiveTextRequest;
  public readonly SubmittedComponent = SubmittedTextRequest;
  public readonly IconComponent = TextRequestIcon;
  public readonly categoryId = 'input';

  public definesContentType = (type: string): boolean => (
    this.contentTypes.includes(type)
  );

  public createEditorContent = (): TextRequestEditorContent => ({
    id: UniqueIdGenerator.generate(),
    card: CardFactory.fromDraftContent({
      type: 'textRequest',
      title: '',
      multiline: false,
      text: null,
      minLength: TEXT_REQUEST_MIN_LENGTH_MIN,
      maxLength: TEXT_REQUEST_MAX_LENGTH_MAX,
    }),
  });

  public getContentTitle = (intl: IntlShape, content: TextRequest): string => (
    content.title || this.getDefaultTitle(intl)
  );

  public getDraftContentTitle = (intl: IntlShape, draftContent: DraftTextRequest): string => (
    draftContent.title || this.getDefaultTitle(intl)
  );

  public hasRequiredFeatureFlags = (environmentSettings: EnvironmentSettings): boolean => (
    !!environmentSettings.formsEnabled
  );

  public getLocalisedType = (intl: IntlShape): string => intl.formatMessage({
    id: 'forms.content.TextRequest.type',
    description: 'Localised name for text request type.',
    defaultMessage: 'Text',
  });

  public transformCard = (card: SingleContentCard<TextRequest>): TextRequestEditorContent => ({
    id: card.id,
    card: CardTransformer.transformCard(card, card.content),
  });

  public validate = TextRequestValidator.validate;

  private getDefaultTitle = (intl: IntlShape): string => intl.formatMessage({
    id: 'forms.content.TextRequest.defaultTitle',
    description: 'Default title for text request.',
    defaultMessage: 'Untitled text',
  });
}
