import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { ImportErrorPresentationComponent } from '../../../Imports/Model';
import { ValidationError } from '../../../Common/Model';
import { TagLicenseLimitReachedError } from '../../../Tags/Model';
import { Notice } from '../../../Common/Component';
import { PeopleLicenseLimitReachedError } from '../../Model';

export const PeopleImportPrepareErrors: ImportErrorPresentationComponent = ({ errors }) => {
  const getErrorMessage = (error: ValidationError): ReactNode => {
    switch (error.type) {
      case 'licenseLimitReached':
        return (
          <FormattedMessage
            id="people.import.licenseLimitReachedError"
            description="Error when import would put the environment over the people limit."
            defaultMessage="This import exceeds your people limit by { over }. Please make sure your import contains { available, plural, =0 {no new people} one {no more than # new person} other {no more than # new people} }."
            values={ {
              available: (error as PeopleLicenseLimitReachedError).metadata.available,
              over: (error as PeopleLicenseLimitReachedError).metadata.needed - (error as TagLicenseLimitReachedError).metadata.available,
            } }
          />
        );
      default:
        return (
          <FormattedMessage
            id="people.import.fallbackPrepareError"
            description="Fallback prepare error when error can not be identified."
            defaultMessage="There is a problem with your import data."
          />
        );
    }
  };

  return (
    <>
      { errors.map(error => (
        <Notice
          key={ error.type }
          variant="outlined"
          feedback={ {
            severity: 'error',
            message: getErrorMessage(error),
          } }
        />
      )) }
    </>
  );
};
