import { default as React, FC, useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CircularProgress } from '@mui/material';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';

import { useFetchThumbnails } from '../../../Hook';
import { StyledThumbnail, StyledThumbnailList, StyledThumbnailSelector } from './style';
import { Flex } from '../../../../Common/Component';

type Props = {
  uploadId: string;
  onChange: (thumbnailKey: string) => void;
  selectedThumbnailKey?: string;
};

export const ThumbnailSelector: FC<Props> = ({
  uploadId,
  onChange,
  selectedThumbnailKey,
}) => {
  const intl = useIntl();
  const [fetchResult, , reload] = useFetchThumbnails(uploadId);
  const thumbnails = useMemo(
    () => fetchResult?.content?.thumbnails.map(thumbnail => thumbnail.thumbnail) || [],
    [fetchResult?.content?.thumbnails],
  );
  const [loadedThumbnailCount, setLoadedThumbnailCount] = useState<number>(0);
  const allThumbnailsLoaded = useMemo(() => thumbnails.length === loadedThumbnailCount, [
    loadedThumbnailCount,
    thumbnails.length
  ]);

  const selectedThumbnailRef = useCallback((selectedThumbnail: HTMLLIElement) => {
    if (selectedThumbnail !== null) {
      selectedThumbnail.scrollIntoView({ block: 'nearest', inline: 'nearest' });
    }
  }, []);

  const whenThumbnailLoaded = useCallback(() => {
    setLoadedThumbnailCount(loadedThumbnailCount => loadedThumbnailCount + 1);
  }, []);

  return (
    <StyledThumbnailSelector>
      {
        fetchResult?.content
          ? (
            <StyledThumbnailList loaded={ allThumbnailsLoaded }>
              { thumbnails.map((thumbnail, index) => {
                const totalSeconds = index * 5;
                const minutes = Math.floor(totalSeconds / 60);
                const seconds = totalSeconds % 60;

                return (
                  <StyledThumbnail
                    key={ thumbnail.key }
                    role="button"
                    onClick={ () => onChange(thumbnail.key) }
                    selected={ selectedThumbnailKey === thumbnail.key }
                    ref={ selectedThumbnailKey === thumbnail.key && allThumbnailsLoaded ? selectedThumbnailRef : null }
                  >
                    <img
                      src={ thumbnail.url }
                      alt={ intl.formatMessage({
                        id: 'thumbnailSelector.alt',
                        description: 'Alt text for generated thumbnails.',
                        defaultMessage: 'Thumbnail generated from user-provided video at { minutes, plural, =0 {} one {# minute and} other {# minutes and} } { seconds, plural, one {# second} other {# seconds} }.'
                      }, {
                        minutes,
                        seconds,
                      }) }
                      onLoad={ whenThumbnailLoaded }
                    />
                  </StyledThumbnail>
                );
              }) }
            </StyledThumbnailList>
          )
          : fetchResult?.error && (
            <Flex gap={ 1 }>
              <FormattedMessage
                id="thumbnailSelector.error"
                description="Message when thumbnail request fails."
                defaultMessage="Could not retrieve video thumbnails"
              />
              <Button
                inline
                onClick={ reload }
              >
                <FormattedMessage
                  id="thumbnailSelector.retry"
                  description="Label for retry button when fetching thumbnails fails"
                  defaultMessage="Retry"
                />
              </Button>
            </Flex>
          )
      }
      { (!fetchResult || (fetchResult.content && !allThumbnailsLoaded)) && (
        <Flex noWrap gap={ 2 }>
          <CircularProgress
            variant="indeterminate"
            size={ 24 }
          />
          <FormattedMessage
            id="thumbnailSelector.loading"
            description="Loading message for thumbnail selector."
            defaultMessage="Fetching thumbnails…"
          />
        </Flex>
      ) }
    </StyledThumbnailSelector>
  );
};
