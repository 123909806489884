import { IntlShape } from 'react-intl';

import { Condition, PersonCondition } from '../../../../Audiences/Model';
import { AudienceConditionDefinition, ConditionRestriction } from '../../Model/AudienceConditionDefinition';
import { PersonList } from '../../Component/PersonCondition/PersonList';
import { PersonConditionComponent } from '../../Component/PersonCondition/PersonConditionComponent';

export class PersonConditionDefinition implements AudienceConditionDefinition<PersonCondition> {
  public readonly TYPE = 'person';
  public readonly restriction = ConditionRestriction.SOME;
  public readonly name;

  constructor(intl: IntlShape) {
    this.name = intl.formatMessage({
      description: 'Name for person condition, used in audience builder.',
      defaultMessage: 'Person',
    });
  }
  public definesCondition(condition: Condition): condition is PersonCondition {
    return condition.type === this.TYPE;
  }

  isValid(condition: PersonCondition): boolean {
    return !!condition.personIds.length;
  }

  public Component = PersonConditionComponent;

  public build = {
    Component: PersonList,
    initialise: () => ({
      type: 'person',
      personIds: [],
      not: false,
      new: true,
    }) as PersonCondition,
  };
}
