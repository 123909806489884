import { ComponentProps, FC, SVGProps } from 'react';

import { PresentationIcon } from '../PresentationIcon/PresentationIcon';
import { Tooltip } from '../../../stories/components/Tooltip/Tooltip';

type Props = {
  IconComponent: FC<SVGProps<SVGSVGElement>>;
  label: string;
} & Omit<ComponentProps<'button'>, 'children'>;

export const TextFieldIconButton: FC<Props> = ({
  IconComponent,
  label,
  children,
  ...props
}) => (
  <Tooltip
    contents={ label }
  >
    <button
      { ...props }
      css={ theme => ({
        background: 'transparent',
        borderRadius: theme.new.borderRadius.max,
        border: 'none',
        width: theme.new.spacing[10],
        height: theme.new.spacing[10],
        color: theme.new.palette.grey[400].main,
        padding: 0,
        marginInlineStart: `-${ theme.new.spacing[2] }`,

        ':hover, :active': {
          background: theme.new.palette.grey[50].main,
        },
      }) }
    >
      <PresentationIcon
        IconComponent={ IconComponent }
      />
    </button>
  </Tooltip>
)
