import { useMemo } from 'react';
import { useValidationErrors } from 'op-storybook/lib/hooks/useValidationErrors';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { ButtonBase } from 'op-storybook/stories/components/ButtonBase/ButtonBase';
import { darken } from 'polished';

import { InlineFormComponent } from '../../../Model/BroadcastBlockDefinition';
import { ButtonBlock } from '../../../Service/Blocks/ButtonBlockDefinition';
import { useContextOrThrow } from '../../../../../Core/Hook';
import { BroadcastBlockDefinitionRegistryContext } from '../../../Context/BroadcastBlockDefinitionRegistry';
import { BroadcastEditorErrors } from '../../BroadcastEditorErrors';

export const ButtonBlockInlineForm: InlineFormComponent<ButtonBlock> = ({
  block,
  validationTree,
  activateBlock,
}) => {
  const { getBlockErrorDefinitions } = useContextOrThrow(BroadcastBlockDefinitionRegistryContext);
  const blockErrorDefinitions = useMemo(() => getBlockErrorDefinitions(block), [block, getBlockErrorDefinitions]);
  const { formatValidationTreeWithDefinitions } = useValidationErrors();
  const formattedLabelErrors = useMemo(() => formatValidationTreeWithDefinitions(
    validationTree.children.attributes?.children.label,
    blockErrorDefinitions.label,
  ), [
    blockErrorDefinitions.label,
    formatValidationTreeWithDefinitions,
    validationTree.children.attributes?.children.label
  ]);
  const formattedUrlErrors = useMemo(() => formatValidationTreeWithDefinitions(
    validationTree.children.attributes?.children.url,
    blockErrorDefinitions.url,
  ), [
    blockErrorDefinitions.url,
    formatValidationTreeWithDefinitions,
    validationTree.children.attributes?.children.url
  ]);
  const errors = useMemo(() => [...formattedLabelErrors, ...formattedUrlErrors], [
    formattedLabelErrors,
    formattedUrlErrors
  ])

  const {
    label,
    backgroundColour,
    textColour,
  } = block.attributes;

  return (
    <Stack direction="column" gap={ 1 }>
      <ButtonBase
        css={ theme => ({
          backgroundColor: backgroundColour,
          color: textColour,
          border: 'none',
          minHeight: '40px',
          fontWeight: 500,
          boxShadow: theme.new.shadow.sm,
          width: '100%',

          '&:active, &:hover': {
            backgroundColor: backgroundColour ? darken(0.05, backgroundColour) : undefined,
          },
        }) }
        onClick={ activateBlock }
        rel="no-referrer"
      >
        { label || ' ' }
      </ButtonBase>
      <BroadcastEditorErrors errors={ errors }/>
    </Stack>
  );
};
