import { FC, PropsWithChildren } from 'react';

import { StyledCardContent, StyledCardFooter } from './style';

type Props = {
  seamless?: boolean;
};

export const CardFooter: FC<PropsWithChildren<Props>> = ({ seamless = false, children }) => (
  <StyledCardFooter seamless={ seamless }>
    <StyledCardContent insetTop={ !seamless } insetBottom>
      { children }
    </StyledCardContent>
  </StyledCardFooter>
);
