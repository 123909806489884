import { FunctionComponent } from 'react';
import { DebouncedSearchInput } from '@ourpeople/shared/Core/Component/Input/DebouncedSearchInput/DebouncedSearchInput';

import { FlexPullRight } from '../../../../../Common/Component';

interface Props {
  searchValue?: string;
  onSearchChanged: (value: string) => void;
}

export const StandardReportTableInput: FunctionComponent<Props> = ({
  searchValue,
  onSearchChanged,
}) => {
  return (
    <FlexPullRight>
      <DebouncedSearchInput
        onChange={ onSearchChanged }
        value={ searchValue }
      />
    </FlexPullRight>
  )
};
