import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useMemo } from 'react';

import { useCancellableRequest, UseCancellableRequestReturnValue } from '.';

export const useCancellablePut = <T, U = Record<string, unknown>>(
  endpoint: string,
  onSuccess?: (response: AxiosResponse<T>) => void,
  onFail?: (error: unknown) => void,
): UseCancellableRequestReturnValue<U> => {
  const requestConfig = useMemo<AxiosRequestConfig>(() => ({
    url: endpoint,
    method: 'PUT',
  }), [endpoint]);

  return useCancellableRequest<T, U>(
    requestConfig,
    onSuccess,
    onFail,
  );
};
