import { Subject } from 'rxjs';

import { Api, Uploader } from '../../Services';
import { AuthorisedUpload, Upload } from '../../Types';
import { ErrorResponseReader } from '../../Common/Utility';
import { FileUploadError } from '../../Common/Component';

export const authoriseAndUpload = (
  api: Api,
  authoriseEndpoint: string,
  file: File,
  onComplete: (upload: Upload) => void,
  onError: (error: FileUploadError) => void,
  onProgress: (progress: number) => void,
  authoriseData?: Record<string, unknown>,
) => {
  const abortSubject = new Subject<void>();
  const abort = () => {
    abortSubject.next();
  };

  api.post<AuthorisedUpload>(
    authoriseEndpoint,
    {
      fileName: file.name,
      ...authoriseData,
    },
  )
    .then(response => {
      if (file.size > response.data.maxSize) {
        return onError({
          code: 'FILE_TOO_LARGE',
          metadata: {
            maxSize: response.data.maxSize,
            fileSize: file.size,
          },
        });
      }

      const uploader = new Uploader();
      return uploader.uploadFile(
        response.data,
        file,
        onProgress,
        abortSubject.asObservable(),
      )
        .then(upload => {
          onComplete(upload)
        })
    })
    .catch((error: unknown) => {
      onError(ErrorResponseReader.determineFileUploadError(error));
    });

  return abort;
};
