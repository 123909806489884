import { FC, useEffect, useMemo, useRef, ComponentProps } from 'react';

import { Stack } from '../../../lib/components/Stack/Stack';
import { FieldLabel } from '../../../lib/components/FieldLabel/FieldLabel';
import { StyleBuilder } from '../../../lib/model/StyleBuilder/StyleBuilder';
import { TextField } from '../TextField/TextField';

type Props = {
  size: 'xs' | 'sm' | 'md' | 'lg';
  label?: string;
  value: string;
  onChange: (value: string) => void;
  autoFocus?: boolean;
};

export const OtpField: FC<Props> = ({
  size,
  label,
  value,
  onChange,
  autoFocus,
}) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const styles = useMemo(() => buildStyles({ size }), [size]);

  /**
   * Listen for an OTP credential request and fill the field automatically
   */
  useEffect(() => {
    if ('OTPCredential' in window) {
      const ac = new AbortController();
      navigator.credentials.get({
        otp: { transport: ['sms'] },
        signal: ac.signal
      })
        .then(otp => onChange(otp.code))
        .catch(error => {
          // do nothing, the user can still enter the code manually
          console.error(error);
        });
      return () => ac.abort();
    }
  }, [onChange]);

  return (
    <Stack
      direction="column"
      gap={ 1 }
    >
      { label && (
        <FieldLabel>
          { label }
        </FieldLabel>
      ) }
      <Stack
        ref={ parentRef }
        gap={ 2 }
        css={ styles.fieldContainer }
      >
        <TextField
          inputProps={ {
            inputMode: 'numeric',
            pattern: '[0-9]{6}',
            autoComplete: 'one-time-code',
            autoFocus,
            css: { textAlign: 'center' },
          } }
          placeholder="000000"
          onChange={ onChange }
          value={ value }
        />
      </Stack>
    </Stack>
  );
};

const buildStyles: StyleBuilder<{ size: ComponentProps<typeof OtpField>['size'] }> = ({ size }) => ({
  fieldContainer: theme => ({
    '> span': {
      color: theme.new.palette.grey[300].main,
      fontSize: theme.new.typography.display.size[size].fontSize,
    },
  }),
});
