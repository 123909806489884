import styled from '@emotion/styled';

export const StyledNameAndCategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: ${ props => props.theme.spacing(1) };
  width: 100%;

  ${ props => props.theme.breakpoints.up('sm') } {
    width: 17rem;
  }
`;
