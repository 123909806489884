import { useEffect, useState } from 'react';

import { SubmittedComponent } from '../../../../Content/Model';
import { UploadRequest as UploadRequestModel } from '../../../Model/ContentTypes';
import { UploadResponse } from '../../../Service';
import { NoResponseMessage } from '../NoResponseMessage/NoResponseMessage';
import { UploadThumbnail } from '../../../../Components';
import { StyledSubmittedUploadRequest } from './style';

export const SubmittedUploadRequest: SubmittedComponent<UploadResponse, UploadRequestModel> = ({
  response,
  onFinish,
}) => {
  const [finishedThumbnailIds, setFinishedThumbnailIds] = useState<string[]>([]);

  useEffect(() => {
    if (!onFinish || finishedThumbnailIds.length !== (response?.uploads.length ?? 0)) {
      return;
    }

    if (finishedThumbnailIds.sort().join(',') !== (response?.uploads || []).sort().join(',')) {
      return;
    }

    onFinish();
  }, [finishedThumbnailIds, onFinish, response?.uploads]);

  return response
    ? (
      <StyledSubmittedUploadRequest>
        { response.uploads.map(upload => (
          <UploadThumbnail
            key={ upload }
            uploadId={ upload }
            onFinish={ () => setFinishedThumbnailIds(
              finishedThumbnailIds => finishedThumbnailIds.concat(upload)
            ) }
          />
        )) }
      </StyledSubmittedUploadRequest>
    )
    : <NoResponseMessage/>;
};
