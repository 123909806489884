import { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { ContentPreviewProps } from '../../../../Content/Model';
import { RevealEditorContent } from '../../../Service';
import { CardTitle, ContentWithType, RevealContentPreviewOption, ScrollableContent } from '..';
import { VerticallySpaced } from '../../../../Common/Component';
import { StyledText } from './style';

export const RevealContentPreview: FC<ContentPreviewProps<RevealEditorContent>> = ({
  editorContent,
  onReady,
}) => {
  const intl = useIntl();

  useEffect(() => {
    onReady();
  }, [onReady]);

  return (
    <ScrollableContent>
      <ContentWithType
        localisedContentType={ intl.formatMessage({
          id: 'broadcasts.content.preview.reveal',
          description: 'Label for reveal content type used in preview.',
          defaultMessage: 'Reveal',
        }) }
      >
        <VerticallySpaced gap={ 2 }>
          <CardTitle>{ editorContent.card.content.title }</CardTitle>
          <StyledText>{ editorContent.card.content.text }</StyledText>
          { editorContent.card.content.options.map(option => (
            <RevealContentPreviewOption
              key={ option.id }
              previewText={ option.preview_text }
              text={ option.text }
            />
          )) }
        </VerticallySpaced>
      </ContentWithType>
    </ScrollableContent>
  );
}
