import { FC } from 'react';

import { SafeRichTextContent, SafeRichTextContentProps } from '../../../Common/Component/SafeRichTextContent/SafeRichTextContent';
import { StyledRichTextContentPreview } from './style';

export const RichTextContentPreview: FC<SafeRichTextContentProps & { className?: string }> = ({
  className,
  ...props
}) => (
  <StyledRichTextContentPreview className={ className }>
    <SafeRichTextContent { ...props }/>
  </StyledRichTextContentPreview>
);
