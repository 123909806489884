import { FormattedMessage } from 'react-intl';
import { ReactNode } from 'react';

import { ValidationError } from '../../../Common/Model';
import { ValidationErrorMessage } from '../../../Common/Component';
import { ImportErrorPresentationComponent } from '../../../Imports/Model';
import { TooManyMappedTypeError } from '../../Model';
import { useLocalisedPeopleRecordTypes } from '../../Hook';

export const PeopleImportColumnErrors: ImportErrorPresentationComponent = ({ errors }) => {
  const recordTypes = useLocalisedPeopleRecordTypes();

  const getErrorMessage = (error: ValidationError): ReactNode => {
    switch (error.type) {
      case 'notMapped':
        return (
          <FormattedMessage
            id="people.import.notMappedError"
            description="Error when import column could not be mapped to a person or profile field."
            defaultMessage="A type could not be determined for this column, please update it manually."
          />
        );
      case 'tooManyMappedType':
        return (
          <FormattedMessage
            id="people.import.tooManyMappedTypeError"
            description="Error when import contains too many instances of a record type."
            defaultMessage="Imports should contain no more than { max, plural, one {# column} other {# columns} } with { type, select, unknown {this type} other {type {type}}}."
            values={ {
              max: (error as TooManyMappedTypeError).metadata.max,
              type: recordTypes.find(recordType => recordType.id === (error as TooManyMappedTypeError).metadata.type)?.localisation || 'unknown',
            } }
          />
        );
      default:
        return (
          <FormattedMessage
            id="people.import.fallbackColumnError"
            description="Fallback column error when error can not be identified."
            defaultMessage="There is a problem with this column."
          />
        );
    }
  };

  return (
    <>
      { errors.map(error => (
        <ValidationErrorMessage
          key={ error.type }
        >
          { getErrorMessage(error) }
        </ValidationErrorMessage>
      )) }
    </>
  );
};
