import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { Role, RoleId } from '../Model';
import { RoleLocaliser } from '../Utility';

interface AvailableRoleNames {
  nonInternalRoles: Role[];
  availableRoles: Role[];
  getRoleById: (roleId: RoleId) => Role;
}

export const useLocalisedRoleNames = (): AvailableRoleNames => {
  const intl = useIntl();

  const userRole = useMemo<Role>(() => ({
    id: 'ROLE_USER',
    name: RoleLocaliser.localiseRoleId(intl, 'ROLE_USER'),
  }), [intl]);

  const availableRoles: Role[] = useMemo(
    () => [
      {
        id: 'ROLE_FAKE_USER',
        name: RoleLocaliser.localiseRoleId(intl, 'ROLE_FAKE_USER'),
        internal: true,
      },
      userRole,
      {
        id: 'ROLE_APP_BROADCAST',
        name: RoleLocaliser.localiseRoleId(intl, 'ROLE_APP_BROADCAST'),
        description: intl.formatMessage({
          id: 'roleDescription.roleAppBroadcast',
          defaultMessage: 'Can send cover',
        }),
      },
      {
        id: 'ROLE_APP_ADMIN',
        name: RoleLocaliser.localiseRoleId(intl, 'ROLE_APP_ADMIN'),
        description: intl.formatMessage({
          id: 'roleDescription.roleAppAdmin',
          defaultMessage: 'Can assign cover',
        }),
      },
      {
        id: 'ROLE_BROADCAST_ADMIN',
        name: RoleLocaliser.localiseRoleId(intl, 'ROLE_BROADCAST_ADMIN'),
        description: intl.formatMessage({
          id: 'roleDescription.roleBroadcastAdmin',
          defaultMessage: 'Can send broadcasts',
        }),
      },
      {
        id: 'ROLE_ADMIN',
        name: RoleLocaliser.localiseRoleId(intl, 'ROLE_ADMIN'),
        description: intl.formatMessage({
          id: 'roleDescription.roleAdmin',
          defaultMessage: 'Can manage people',
        }),
      },
      {
        id: 'ROLE_SUPER_ADMIN',
        name: RoleLocaliser.localiseRoleId(intl, 'ROLE_SUPER_ADMIN'),
        description: intl.formatMessage({
          id: 'roleDescription.roleSuperAdmin',
          defaultMessage: 'Has unrestricted access',
        }),
      },
      {
        id: 'ROLE_DEVELOPER',
        name: RoleLocaliser.localiseRoleId(intl, 'ROLE_DEVELOPER'),
        description: intl.formatMessage({
          id: 'roleName.roleDeveloper',
          defaultMessage: 'Only for OurPeople staff',
        }),
        internal: true,
      },
      {
        id: 'ROLE_API',
        name: RoleLocaliser.localiseRoleId(intl, 'ROLE_API'),
        internal: true,
      },
      {
        id: 'ROLE_SYSTEM',
        name: RoleLocaliser.localiseRoleId(intl, 'ROLE_SYSTEM'),
        internal: true,
      },
    ],
    [intl, userRole],
  );

  const nonInternalRoles = useMemo(
    () => availableRoles.filter(role => !role.internal),
    [availableRoles],
  );

  return {
    nonInternalRoles,
    availableRoles,
    getRoleById: (roleId: RoleId): Role => (
      availableRoles.find(role => role.id === roleId) || userRole
    ),
  };
};
