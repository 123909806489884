import styled from '@emotion/styled';

export const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${ props => props.theme.spacing(1) };
  flex-wrap: nowrap;
  width: 50%;

  ${ props => props.theme.breakpoints.down('md') } {
    width: 100%;
  }
`;
