import {FC, useEffect} from 'react';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {COMMAND_PRIORITY_EDITOR, KEY_BACKSPACE_COMMAND} from 'lexical';

type Props = {
  onBackspace: () => void;
};

export const OnEmptyBackspacePlugin: FC<Props> = ({
  onBackspace,
}) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    editor.registerCommand<KeyboardEvent>(
      KEY_BACKSPACE_COMMAND,
      (event) => {
        const text = (event.target as HTMLElement).innerText.trim();

        if (text.length) {
          return false;
        }

        onBackspace();

        return false;
      },
      COMMAND_PRIORITY_EDITOR,
    );
  }, [editor, onBackspace]);

  return null;
};
