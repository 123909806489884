import { FC, ReactNode } from 'react';

import { StyledFormCard } from './style';
import { StyledFormHeading } from './style'

type Props = {
  formName: string;
  children?: ReactNode;
};

export const FormCard: FC<Props> = ({
  formName,
  children,
}) => {
  return (
    <StyledFormCard>
      <StyledFormHeading>
        <h3>{ formName }</h3>
      </StyledFormHeading>
      { children }
    </StyledFormCard>
  );
};
