import { MessagePayload, OpPushMessageData, PushMessageDefinition } from '../../Core/Model/PushMessage';

export interface ChatMessageReceivedPushMessageData extends OpPushMessageData {
  channel_id: string;
  opChatId: string;
  opStreamChannelId: string;
  opStreamMessageId: string;
  sender: string;
}

export class ChatMessageReceivedPushMessageDefinition implements PushMessageDefinition<MessagePayload<ChatMessageReceivedPushMessageData>> {
  public defines = (message: MessagePayload): message is MessagePayload<ChatMessageReceivedPushMessageData> => (
    definedTypes.includes(message.data.opType)
  );

  public discard = (message: MessagePayload<ChatMessageReceivedPushMessageData>): boolean => {
    return window.location.pathname.includes(`/chat/${ message.data.opStreamChannelId }`);
  };

  public onClick: PushMessageDefinition<MessagePayload<ChatMessageReceivedPushMessageData>>['onClick'] = (message, onDismiss) => {
    onDismiss();
    window.location.href = `/me/chat/${ message.data.opStreamChannelId }`;
  };
}

const definedTypes = [
  'chatMessageReceived',
  'chatReactionReceived',
  'chatMentionReceived',
];
