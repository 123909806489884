import { useEffect } from 'react';

import { SubmittedComponent } from '../../../../Content/Model';
import { TextRequest as TextRequestModel } from '../../../Model/ContentTypes';
import { TextResponse } from '../../../Service';
import { NoResponseMessage } from '../NoResponseMessage/NoResponseMessage';
import { SubmittedText } from '../SubmittedText/SubmittedText';

export const SubmittedTextRequest: SubmittedComponent<TextResponse, TextRequestModel> = ({
  response,
  onFinish,
}) => {
  useEffect(() => {
    if (!onFinish) {
      return;
    }

    onFinish();
  }, [onFinish]);

  return (
    response
      ? <SubmittedText text={ response.text }/>
      : <NoResponseMessage/>
  );
};
