import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

interface NotificationSettingLocaliser {
  getLocalisedRecipientStatusSummaryWithoutCounts: () => string;
  getLocalisedNotificationChannelFiltersWithoutCounts: () => string;
}

export const useNotificationSettingLocaliser = (): NotificationSettingLocaliser => {
  const intl = useIntl();

  const localisations = useMemo<Record<'everyone', RecipientStatusLocaliser>>(() => ({
    everyone: {
      withCount: count => intl.formatMessage({
        description: 'Label for broadcast notification recipients with any status.',
        defaultMessage: 'all { count }',
      }, {
        count,
      }),
      withoutCount: () => intl.formatMessage({
        description: 'Label for broadcast notification recipients with any status.',
        defaultMessage: 'everyone',
      }),
    },
  }), [intl]);

  const getLocalisedRecipientStatusSummaryWithoutCounts = useCallback(
    () => localisations.everyone.withoutCount(),
    [localisations],
  );

  const getLocalisedNotificationChannelFiltersWithoutCounts = useCallback(
    () => localisations.everyone.withoutCount(),
    [localisations],
  );

  return useMemo(() => ({
    getLocalisedRecipientStatusSummaryWithoutCounts,
    getLocalisedNotificationChannelFiltersWithoutCounts,
  }), [
    getLocalisedRecipientStatusSummaryWithoutCounts,
    getLocalisedNotificationChannelFiltersWithoutCounts,
  ]);
};

interface RecipientStatusLocaliser {
  withCount: (count: number) => string;
  withoutCount: () => string;
}

