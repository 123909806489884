import { useState, useEffect } from 'react';

export const useDistinct = <T>(
  value: T,
  compare: (oldVal: T, newVal: T) => boolean
): T => {
  const [distinctValue, setDistinctValue] = useState<T>(value);

  useEffect(
    () => {
      const equal = compare(distinctValue, value);
      if (!equal) {
        setDistinctValue(value);
      }
    },
    [value, compare, distinctValue]
  );

  return distinctValue;
}
