import { FC, PropsWithChildren } from 'react';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { BreakpointContext } from 'op-storybook/lib/providers/BreakpointProvider/BreakpointProvider';

import { useContextOrThrow } from '../../../Core/Hook';

export const ResponsivePageContent: FC<PropsWithChildren> = ({ children }) => {
  const screenWidth = useContextOrThrow(BreakpointContext);
  return (
    <div
      css={ theme => ({
        padding: `0 ${ theme.new.spacing[6] } ${ theme.new.spacing[6] }`,

        [theme.new.breakpoints.sm.down]: {
          padding: `0 ${ theme.new.spacing[2] } ${ theme.new.spacing[2] }`,
        },
      }) }
    >
      <Stack
        direction="column"
        gap={ screenWidth.lessThan.sm ? 3 : 5 }
      >
        { children }
      </Stack>
    </div>
  );
}
