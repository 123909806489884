import { TextField } from '@mui/material';
import { ChangeEventHandler, PropsWithChildren, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { ContentFields } from '..';
import { FieldValidationErrors, LabelledSwitch } from '../../../../Common/Component';
import { ValidationTree } from '../../../../Common/Model';
import { ContentEditorProps, EditorContent } from '../../../../Content/Model';
import { DraftFormRequest } from '../../../Model';
import { RequestValidator } from '../../../Utility/Validation';

export const RequestFields = <T extends DraftFormRequest>({
  editorContent,
  onEditorContentChanged,
  validation,
  onValidationChanged,
}: PropsWithChildren<ContentEditorProps<EditorContent<T>>>): JSX.Element => {
  const intl = useIntl();
  const textErrors = validation?.children.content?.children.text?.errors || [];
  const descriptionInputValue = useMemo<string>(() => editorContent.card.content.text || '', [editorContent.card.content.text]);

  const whenMandatoryChanged = (checked: boolean): void => {
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        mandatory: checked,
      },
    });
  };

  const whenTextChanged: ChangeEventHandler<HTMLInputElement> = (event) => {
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          text: event.target.value || null,
        },
      },
    });
  };

  const whenTextBlurred = (): void => {
    whenValidationChanged({
      text: RequestValidator.validateText(editorContent.card.content),
    });
  };

  const whenValidationChanged = (children: ValidationTree<T>['children']): void => {
    onValidationChanged({
      errors: validation?.errors || [],
      children: {
        content: {
          errors: [],
          children: {
            ...validation?.children.content?.children,
            ...children,
          },
        },
      },
    });
  };

  return (
    <>
      <ContentFields
        editorContent={ editorContent }
        onEditorContentChanged={ onEditorContentChanged }
        validation={ validation }
        onValidationChanged={ onValidationChanged }
      />
      <TextField
        id="description"
        label={intl.formatMessage({
          id: 'form.fields.shared.description.label',
          defaultMessage: 'Description',
        })}
        value={ descriptionInputValue }
        onChange={ whenTextChanged }
        onBlur={ whenTextBlurred }
        error={ !!textErrors.length }
        fullWidth
        multiline
      />
      <FieldValidationErrors
        fieldName={intl.formatMessage({
          id: 'form.fields.shared.description.name',
          defaultMessage: 'description',
        })}
        validationErrors={ textErrors }
      />
      <LabelledSwitch
        id="required"
        checked={ editorContent.card.mandatory }
        onChange={ whenMandatoryChanged }
        label={intl.formatMessage({
          id: 'form.fields.shared.required.label',
          defaultMessage: 'Required',
        })}
      />
    </>
  );
};
