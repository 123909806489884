import { ContextCreator } from '@ourpeople/shared/Core/Utility/ContextCreator';
import { MessagingService } from '@firebase/messaging/dist/src/messaging-service';

export interface PushDeviceRegistrationContextValue {
  refreshToken: () => void;
  firebaseMessaging: MessagingService | undefined;
}

export const PushDeviceRegistrationContext = ContextCreator.withDisplayName<PushDeviceRegistrationContextValue>(
  'PushDeviceRegistrationContext',
  null,
);
