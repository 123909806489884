import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { FormattedList, FormattedMessage, useIntl } from 'react-intl';
import { IconButton } from '@mui/material';
import { LocalisedString } from 'op-storybook/lib/model/LocalisedString/LocalisedString';

import { ImportDetail, ImportOperationTypeString } from '../../../Imports/Model';
import { ArrayHelper, ChipHelper, QueryParser, QueryWithKeys } from '../../../Common/Utility';
import { Chip, Flex, FlexPullRight, TableFilters } from '../../../Common/Component';
import { ImportOperationAutocomplete, ImportOperationChip } from '../../../Imports/Component';
import { PaginatedTable, TableCell, TableRow } from '../../../Components';
import { FetchPeopleImportOperationsParams, PeopleImportOperation, useFetchPeopleImportOperations } from '../../Hook';
import { PersonParser } from '../../../Utility';
import { StyledViewIcon } from './style';
import { PeopleOperationSummaryNotice, ProfileSummaryDialog } from '..';
import { useQueryAsState } from '../../../Hooks';

type Props = {
  importDetail: ImportDetail;
  onImportButtonClicked: () => void;
};

type Query = QueryWithKeys<'search' | 'pageNum' | 'actions'>;

export const PeopleImportSummaryTable: FunctionComponent<Props> = ({
  importDetail,
  onImportButtonClicked,
}) => {
  const intl = useIntl();
  const [query, setQuery] = useQueryAsState<Query>();
  const importOperationTypes = useMemo(() => parseOperationTypes(query), [query]);
  const fetchPeopleImportParams = useMemo<FetchPeopleImportOperationsParams>(() => ({
    pageNum: QueryParser.pageNum(query),
    ...(query.search ? { search: query.search } : {}),
    ...(importOperationTypes.length ? { operationTypes: importOperationTypes.join(',') } : {}),
  }), [importOperationTypes, query]);
  const [
    fetchPeopleImportOperationsResult,
    fetchPeopleImportOperationsState,
    reloadFetchPeopleImportOperations,
  ] = useFetchPeopleImportOperations(importDetail.id, fetchPeopleImportParams);
  const operations = fetchPeopleImportOperationsResult?.content?.operations || [];
  const pagination = fetchPeopleImportOperationsResult?.content?.pagination;
  const [chips, setChips] = useState<Chip[]>([]);
  const [viewingOperationDetail, setViewingOperationDetail] = useState<PeopleImportOperation>();

  const whenImportOperationTypesChanged = (selection: LocalisedString<ImportOperationTypeString>[]) => {
    setQuery(({ actions, ...query }) => ({
      ...query,
      ...(selection.length ? { actions: selection.map(option => option.id).join(',') } : {}),
      pageNum: '1',
    }));

    setChips(chips => (
      ChipHelper.updateChipsWithBasicAutocompleteSelection(
        'importOperation',
        chips,
        selection,
        option => intl.formatMessage({
          id: 'tags.import.importOperationChip',
          description: 'Label for import operation chip.',
          defaultMessage: 'Action: { operation }',
        }, {
          operation: option.localisation,
        }),
      )
    ));
  };

  const whenRemoveChipClicked = (chipToRemove: Chip): void => {
    switch (chipToRemove.type) {
      case 'importOperation':
        setChips(chips => chips.filter(chip => chip.type !== 'importOperation' || chipToRemove.id !== chip.id));
        setQuery(({ actions, ...query }) => {
          const newTypes = importOperationTypes.filter(type => type !== chipToRemove.id);
          return {
            ...query,
            ...(newTypes.length ? { actions: newTypes.join(',') } : {}),
            pageNum: '1',
          };
        });
        break;
    }
  };

  const whenClearFiltersClicked = (): void => {
    setChips([]);
    setQuery({ pageNum: '1' });
  };

  const whenSearchChanged = useCallback((search: string) => {
    setQuery(({ search: oldSearch, ...query }) => ({
      ...query,
      ...(search ? { search } : {}),
      pageNum: '1',
    }));
  }, [setQuery]);

  const whenPageNumChanged = useCallback((pageNum: number) => {
    setQuery(query => ({
      ...query,
      pageNum: `${ pageNum }`,
    }));
  }, [setQuery]);

  const getProfileOperationSummary = (operation: PeopleImportOperation) => {
    const createProfileOperations = operation.profiles.filter(profileOperation => profileOperation.operationType === 'create').length;
    const updateProfileOperations = operation.profiles.filter(profileOperation => profileOperation.operationType === 'update').length;
    return !operation.profiles.length
      ? (
        <FormattedMessage
          id="people.import.noProfiles"
          description="People import profile summary in summary table when imported person includes no profile changes"
          defaultMessage="No profiles"
        />
      )
      : (
        /* eslint-disable react/style-prop-object */
        <FormattedList
          type="conjunction"
          style="narrow"
          value={ [
            ...(
              createProfileOperations
                ? [
                  <FormattedMessage
                    key="new"
                    id="people.import.newProfiles"
                    description="People import new profile summary in summary table"
                    defaultMessage="{ count } new"
                    values={ {
                      count: createProfileOperations,
                    } }
                  />
                ]
                : []
            ),
            ...(
              updateProfileOperations
                ? [
                  <FormattedMessage
                    key="updated"
                    id="people.import.updatedProfiles"
                    description="People import updated profile summary in summary table"
                    defaultMessage="{ count } updated"
                    values={ {
                      count: updateProfileOperations,
                    } }
                  />
                ]
                : []
            ),
          ] }
        />
        /* eslint-enable react/style-prop-object */
      )
  };

  return <>
    { importDetail.parseResult?.prepareResult && importDetail.status !== 'completed' && (
      <PeopleOperationSummaryNotice
        operations={ importDetail.parseResult.prepareResult.operations }
        onImportButtonClicked={ onImportButtonClicked }
      />
    ) }
    <div>
      <TableFilters
        searchValue={ query.search || '' }
        onSearchChanged={ whenSearchChanged }
        chips={ chips }
        onRemoveChipClicked={ whenRemoveChipClicked }
        onClearFiltersClicked={ whenClearFiltersClicked }
      >
        <ImportOperationAutocomplete
          selectedIds={ importOperationTypes }
          onSelectionChanged={ whenImportOperationTypesChanged }
        />
      </TableFilters>
      <PaginatedTable
        rows={ operations }
        headerRow={
          <TableRow>
            <TableCell>
              <FormattedMessage
                id="people.import.nameColumn"
                description="Name column in people import summary table."
                defaultMessage="Name"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                id="people.import.actionColumn"
                description="Action column in people import summary table."
                defaultMessage="Action"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                id="people.import.administratedTeamsColumn"
                description="Administrated teams column in people import summary table."
                defaultMessage="Administrated teams"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                id="people.import.profilesColumn"
                description="Profiles column in people import summary table."
                defaultMessage="Profiles"
              />
            </TableCell>
          </TableRow>
        }
        rowRender={ operation => (
          <TableRow key={ operation.id }>
            <TableCell>
              { PersonParser.fullName(operation.person) }
            </TableCell>
            <TableCell>
              <ImportOperationChip operationType={ operation.operationType }/>
            </TableCell>
            <TableCell>
              <FormattedList
                type="conjunction"
                value={ operation.administratedTeams.map(team => team.name) }
              />
            </TableCell>
            <TableCell>
              <Flex>
                { getProfileOperationSummary(operation) }
                { !!operation.profiles.length && (
                  <FlexPullRight>
                    <IconButton
                      color="secondary"
                      aria-label={ intl.formatMessage({
                        id: 'people.import.viewProfiles',
                        description: 'Label for view profiles icon button',
                        defaultMessage: 'View profiles',
                      }) }
                      onClick={ () => setViewingOperationDetail(operation) }
                      size="large">
                      <StyledViewIcon
                        role="presentation"
                      />
                    </IconButton>
                  </FlexPullRight>
                ) }
              </Flex>
            </TableCell>
          </TableRow>
        ) }
        pageNum={ fetchPeopleImportParams.pageNum }
        onPageChanged={ whenPageNumChanged }
        requestState={ fetchPeopleImportOperationsState }
        onRetryClicked={ reloadFetchPeopleImportOperations }
        pagination={ pagination }
      />
    </div>
    <ProfileSummaryDialog
      importOperation={ viewingOperationDetail }
      onClose={ () => setViewingOperationDetail(undefined) }
    />
  </>;
};

const valueIsOperationType = ArrayHelper.createTypeGuard<ImportOperationTypeString>(['create', 'update']);
const operationTypeFilter = ArrayHelper.createTypeFilter(valueIsOperationType);
const parseOperationTypes = QueryParser.getCsvParseFn('actions', operationTypeFilter);
