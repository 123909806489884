import { FC, useCallback } from 'react';

import { MappedSettingTypes, Setting, SettingType } from '../../../Types';
import { MappedSettingInput } from '../MappedSettingInput';
import { VerticallySpaced } from '../../../../../../Common/Component';
import { StyledAggregateSettingLabel, StyledAggregateSettingInput } from './style';

type Props = {
  config: MappedSettingTypes['aggregate']['config'];
  value: MappedSettingTypes['aggregate']['value'];
  onValueChanged: (value: MappedSettingTypes['aggregate']['value']) => void;
  focusId: string;
};

export const AggregateSettingInput: FC<Props> = ({
  config,
  value,
  onValueChanged,
  focusId,
}) => {
  const whenValueForSettingKeyChange = useCallback(
    (settingKey: string, newValue: Setting<Exclude<SettingType, 'aggregate'>>['value']) => (
      onValueChanged({
        ...value,
        [settingKey]: newValue,
      })
    ),
    [onValueChanged, value],
  );

  return (
    <StyledAggregateSettingInput>
      { Object.entries(config.settings).map(([settingKey, { type, config, name }], index) => (
        <VerticallySpaced
          key={ settingKey }
          gap={ 1 }
        >
          <StyledAggregateSettingLabel>{ name }</StyledAggregateSettingLabel>
          <MappedSettingInput
            focusId={ index === 0 ? focusId : `${ focusId }_${ index }` }
            type={ type }
            value={ value?.[settingKey] || null }
            config={ config }
            onValueChanged={ value => whenValueForSettingKeyChange(settingKey, value) }
          />
        </VerticallySpaced>
      )) }
    </StyledAggregateSettingInput>
  )
}
