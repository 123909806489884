import { ChangeEvent, FunctionComponent, useCallback } from 'react';

import { ArrayHelper } from '../../../Common/Utility';
import { ReviewCell, RowDetail } from '..';
import { ErrorCell, StickyCell } from '../../../Common/Component';
import { StyledReviewTableRow } from './style';
import { ImportErrorPresentationComponent } from '../../Model';
import { Switch } from '../../../Components';
import { StyledSwitchContainer } from './style';

type Props = {
  row: RowDetail;
  modifiedValues?: string[];
  onChange: (rowId: string, modifiedValues: string[]) => void;
  checked: boolean;
  onChecked: (rowId: string, checked: boolean) => void;
  maxRowLength: number;
  RecordErrorComponent: ImportErrorPresentationComponent;
}

export const ReviewTableRow: FunctionComponent<Props> = ({
  row,
  modifiedValues,
  onChange,
  checked,
  onChecked,
  maxRowLength,
  RecordErrorComponent,
}) => {
  const whenChecked = (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    onChecked(row.id, checked);
  };

  const whenChanged = useCallback((index: number, value: string) => {
    onChange(
      row.id,
      ArrayHelper.replace(
        modifiedValues || row.values,
        index,
        value,
      )
    );
  }, [onChange, modifiedValues, row]);

  return (
    <StyledReviewTableRow
      key={ row.id }
      $error={ !!row.errors?.errors.length }
      $skipped={ row.ignored }
    >
      <ErrorCell
        errors={ row.errors?.errors || [] }
        ErrorComponent={ RecordErrorComponent }
        minwidth="4rem"
      >
        { row.index.toString() }
      </ErrorCell>
      {
        row.cells.map(cell => {
          const modifiedValue = modifiedValues?.[cell.mappedColumn.index];

          return (
            <ReviewCell
              key={ cell.mappedColumn.index }
              index={ cell.mappedColumn.index }
              errors={ row.errors?.children[cell.mappedColumn.index]?.errors || [] }
              columnMapping={ cell.mappedColumn }
              ErrorComponent={ RecordErrorComponent }
              value={ modifiedValue !== undefined ? modifiedValue : cell.value }
              edited={ modifiedValue !== undefined && modifiedValue !== cell.value }
              onChange={ whenChanged }
            />
          );
        })
      }
      {
        Array.from({ length: maxRowLength - row.cells.length }, (_value, index) => (
          <ReviewCell
            key={ index }
            index={ index }
            errors={ [] }
            ErrorComponent={ RecordErrorComponent }
            value={ '' }
            onChange={ () => null }
          />
        ))
      }
      <StickyCell justify="end">
        <StyledSwitchContainer modified={ checked !== row.ignored }>
          <Switch
            checked={ checked }
            onChange={ whenChecked }
          >
          </Switch>
        </StyledSwitchContainer>
      </StickyCell>
    </StyledReviewTableRow>
  );
};
