import { FunctionComponent, ReactNode } from 'react';
import * as React from 'react';
import MuiDialogActions from '@mui/material/DialogActions';
import { DialogContent, DialogProps } from '@mui/material';

import { DeprecatedButton } from '../Button';
import { StyledDialog, StyledContent, StyledHeading } from './styles';

interface Props extends DialogProps {
  cta: string;
  cancel?: string;
  container?: Element | React.Component | (() => Element | React.Component | null) | null;
  ctaDisabled?: boolean;
  heading?: JSX.Element | string | ReactNode;
  headingSize?: 'large' | 'medium' | 'small';
  headingTextAlign?: 'left' | 'center' | 'right' | 'justify';
  textAlign?: 'left' | 'center' | 'right' | 'justify';
  open: boolean;
  onConfirmed: () => void;
  onDismissed: () => void;
}

export const Dialog: FunctionComponent<Props> = ({
  children,
  onConfirmed,
  onDismissed,
  cancel,
  cta,
  ctaDisabled = false,
  heading,
  headingSize = 'large',
  headingTextAlign = 'center',
  textAlign = 'justify',
  open,
  ...other
}) => {
  const handleConfirmed = (): void => {
    onConfirmed();
  };
  const handleDismissed = (): void => {
    onDismissed();
  };

  return (
    <StyledDialog
      open={ open }
      onBackdropClick={ handleDismissed }
      {...other}
    >
      <DialogContent>
        {
          heading && <StyledHeading textAlign={headingTextAlign} size={headingSize}>{heading}</StyledHeading>
        }
        <StyledContent textAlign={textAlign}>{ children }</StyledContent>
      </DialogContent>
      <MuiDialogActions>
        {
          cancel && <DeprecatedButton
            onClick={ handleDismissed }
          >
            { cancel }
          </DeprecatedButton>
        }
        <DeprecatedButton
          autoFocus
          disabled={ctaDisabled}
          primary={true}
          onClick={ handleConfirmed }
        >
          { cta }
        </DeprecatedButton>
      </MuiDialogActions>
    </StyledDialog>
  );
}
