import { FunctionComponent } from 'react';

import { StyledAlert, StyledAlertIcon, StyledAlertSpinner, StyledAlertText, StyledAlertButton } from './styles';
import Exclamation from '../../Assets/img/icons/monochrome/alert.svg';
import Lock from '../../Assets/img/icons/monochrome/lock.svg';
import Success from '../../Assets/img/icons/monochrome/success.svg';
import { LoadingSpinner } from '../Loading';

type AlertProps = {
  severity?: 'security' | 'error' | 'warning' | 'info' | 'success';
  role?: 'alert' | 'status';
  icon?: JSX.Element;
  size?: 'small' | 'medium';
  showIcon?: boolean;
  showSpinner?: boolean;
  variant?: 'contained' | 'uncontained';
  button?: JSX.Element;
}

export const Alert: FunctionComponent<AlertProps> = (
  {
    children,
    severity = 'info',
    size = 'medium',
    variant = 'contained',
    showIcon = true,
    showSpinner = false,
    role = 'status',
    icon,
    button,
  }
) => {
  let resolvedIcon = icon;

  if (!resolvedIcon) {
    switch(severity) {
      case 'security':
        resolvedIcon = <Lock />;
        break;
      case 'success':
        resolvedIcon = <Success />;
        break;
      default:
        resolvedIcon = <Exclamation />;
        break;
    }
  }

  const resolvedSeverity = severity === 'security' ? 'error' : severity;

  return (
    <StyledAlert severity={resolvedSeverity} variant={variant} role={role}>
      {
        showIcon
          && <StyledAlertIcon severity={resolvedSeverity}>
            {resolvedIcon}
          </StyledAlertIcon>
      }
      {
        showSpinner
          && <StyledAlertSpinner>
            <LoadingSpinner />
          </StyledAlertSpinner>
      }
      <StyledAlertText size={size}>
        {children}
      </StyledAlertText>
      {
        !!button && (
          <StyledAlertButton>
            { button }
          </StyledAlertButton>
        )
      }
    </StyledAlert>
  );
};
