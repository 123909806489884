import { ValidationTree, Validator } from '../../../../Common/Model';
import { MediaContentValidator } from '../MediaContentValidator/MediaContentValidator';
import { DraftVideoContent } from '../../ContentDefinition';
import { RichTextSanitiser, Validate } from '../../../../Common/Utility';

export class VideoContentValidator {
  public static validate = (content: DraftVideoContent): ValidationTree<DraftVideoContent> => ({
    errors: [],
    children: {
      text: VideoContentValidator.validateText(content.text),
      media: VideoContentValidator.validateMedia(content.media),
      ...(content.description ? {
          description: VideoContentValidator.validateDescription(content.description),
        } : {}),
      thumbnailKey: VideoContentValidator.validateThumbnailKey(content.thumbnailKey || ''),
    },
  });

  public static validateText = MediaContentValidator.validateText;
  public static validateMedia = MediaContentValidator.validateMedia;

  public static validateDescription = (description: string): ValidationTree<string> => ({
    errors: descriptionValidator.validate(description),
    children: {},
  });

  public static validateThumbnailKey = (thumbnailKey: string): ValidationTree<string> => ({
    errors: thumbnailKeyValidator.validate(thumbnailKey),
    children: {},
  });
}

export const VIDEO_DESCRIPTION_MIN_LENGTH = 0;
export const VIDEO_DESCRIPTION_MAX_LENGTH = 700;

const descriptionValidator = new Validator<string>([
  Validate.utf8,
  description => Validate.minLength(RichTextSanitiser.stripTags(description), VIDEO_DESCRIPTION_MIN_LENGTH),
  description => Validate.maxLength(RichTextSanitiser.stripTags(description), VIDEO_DESCRIPTION_MAX_LENGTH),
]);

const thumbnailKeyValidator = new Validator<string>([
  Validate.notBlank,
]);
