import { ComponentProps, forwardRef, JSX } from 'react';

import { ButtonBase } from '../ButtonBase/ButtonBase';
import { SvgComponent } from '../../../lib/model/SvgComponent';
import { PresentationIcon } from '../../../lib/components/PresentationIcon/PresentationIcon';
import { ProgressCircle } from '../ProgressCircle/ProgressCircle';
import { ElementOrComponent } from '../../../lib/types/ElementOrComponent';

type Props<C extends ElementOrComponent = 'button'> = {
  variant?: 'secondary' | 'primary' | 'secondary-destructive' | 'primary-destructive' | 'tertiary' | 'tertiary-grey';
  busy?: boolean;
  IconComponent: SvgComponent;
  label: string;
  attention?: boolean;
} & Omit<ComponentProps<typeof ButtonBase<C>>, 'variant' | 'padding' | 'children'>;

export const IconButton = forwardRef(<C extends ElementOrComponent = 'button'>({
  variant,
  busy,
  label,
  IconComponent,
  ...props
}: Props<C>, ref): JSX.Element => (
  <ButtonBase<C>
    ref={ ref }
    {
      ...variant === 'secondary'
        ? {
          colour: 'grey',
          variant: 'secondary-grey',
        }
        : {}
    }
    {
      ...variant === 'secondary-destructive'
        ? {
          colour: 'error',
          variant: 'secondary-colour',
        }
        : {}
    }
    {
      ...variant === 'primary'
        ? {
          colour: 'primary',
          variant: 'filled-light',
        }
        : {}
    }
    {
      ...variant === 'primary-destructive'
        ? {
          colour: 'error',
          variant: 'filled-light',
        }
        : {}
    }
    {
      ...variant === 'tertiary'
        ? {
          colour: 'primary',
          variant: 'tertiary-dark',
        }
        : {}
    }
    {
      ...variant === 'tertiary-grey'
        ? {
          colour: 'grey',
          variant: 'tertiary-dark',
        }
        : {}
    }
    padding="uniform"
    children={
      <>
        { busy && (
          <div
            css={ {
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            } }
          >

            <ProgressCircle
              size="xs"
              palette={ {
                colour: variant === 'secondary'
                  ? 'grey'
                  : ['secondary-destructive', 'primary-destructive'].includes(variant)
                    ? 'error'
                    : 'primary',
                intensity: 600,
                contrast: ['primary', 'primary-destructive'].includes(variant),
              } }
            />
          </div>
        ) }
        <div { ...busy ? { css: { opacity: 0 } } : {} }>
          <PresentationIcon
            size={ props.size === 'xl' ? 8 : props.size === 'lg' ? 6 : props.size === 'md' ? 5 : 4 }
            IconComponent={ IconComponent }
          />
        </div>
      </>
    }
    aria-label={ label }
    { ...props as ComponentProps<typeof ButtonBase<C>> }
    size={ props.size === 'xl' ? '2xl' : props.size === 'lg' ? 'xl' : props.size === 'md' ? 'lg' : props.size === 'sm' ? 'md' : 'lg' }
  />
));
