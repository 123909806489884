import { MessagePayload, PushMessageDefinition } from '../../Core/Model/PushMessage';

export class FallbackPushMessageDefinition implements PushMessageDefinition {
  public defines = (_message: MessagePayload): _message is MessagePayload => {
    return true;
  };

  public discard = (): boolean => false;

  public onClick: PushMessageDefinition['onClick'] = (_message, onDismiss) => {
    onDismiss();
  };
}
