import { ReactNode, useCallback, useMemo, useState } from 'react';
import { ConfirmationDialog, ConfirmationDialogProps } from '@ourpeople/shared/Core/Component/Feedback/ConfirmationDialog/ConfirmationDialog';

type ReturnType<A extends unknown[]> = [
  (...args: A) => void,
  ReactNode,
];

export const useConfirmAction = <A extends unknown[]>(
  action: (...args: A) => void,
  dialogProps: Omit<ConfirmationDialogProps, 'open' | 'onCancel' | 'onConfirm' | 'onClose'>,
  confirmationRequired: ((...args: A) => boolean) | boolean = false,
): ReturnType<A> => {
  const [onConfirm, setOnConfirm] = useState<() => void>();

  const performAction = useCallback((...args: A) => {
    if (typeof confirmationRequired === 'function' ? !confirmationRequired(...args) : !confirmationRequired) {
      return action(...args);
    }

    setOnConfirm(() => () => action(...args));
  }, [confirmationRequired, action]);

  const whenCancelled = useCallback(() => {
    setOnConfirm(undefined);
  }, []);

  const whenConfirmed = useCallback(() => {
    if (!onConfirm) {
      return;
    }

    onConfirm();
    setOnConfirm(undefined);
  }, [onConfirm]);

  const dialog = useMemo<ReactNode>(() => (
    <ConfirmationDialog
      open={ !!onConfirm }
      onCancel={ whenCancelled }
      onClose={ whenCancelled }
      onConfirm={ whenConfirmed }
      { ...dialogProps }
    />
  ), [onConfirm, whenCancelled, whenConfirmed, dialogProps]);

  return useMemo(() => [
    performAction,
    dialog,
  ], [dialog, performAction]);
};
