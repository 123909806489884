import { FormattedMessage, useIntl } from 'react-intl';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { StackEnd } from 'op-storybook/lib/components/StackEnd/StackEnd';
import { useCallback } from 'react';

import { CreateContentButton, StyledFormCreateMenu } from './style';
import { useContentDefinitionRegistry } from '../../../Content/Hook';
import { ContentDefinition, EditorContent } from '../../../Content/Model';
import { ConditionalTooltip } from '../../../Common/Component';
import { AiFeatureBadge } from '../../../Common/Component/AiFeatureBadge/AiFeatureBadge';
import { NewFeatureBadge } from '../../../Common/Component/NewFeatureBadge/NewFeatureBadge';

type Props<T extends EditorContent> = {
  onContentCreated: (editorContent: T) => void;
  anonymous?: boolean;
};

export const FormEditorContentMenu = <T extends EditorContent>({
  onContentCreated,
  anonymous,
}: Props<T>): JSX.Element => {
  const intl = useIntl();
  const { contentDefinitions: uncategorisedContentDefinitions } = useContentDefinitionRegistry();
  const inputDefinitions = uncategorisedContentDefinitions.filter(
    uncategorisedContentDefinition => (
      uncategorisedContentDefinition.categoryId === 'input' && uncategorisedContentDefinition.availableToCreate
    ),
  );
  const contentDefinitions = uncategorisedContentDefinitions.filter(
    uncategorisedContentDefinition => (
      uncategorisedContentDefinition.categoryId === 'content' && uncategorisedContentDefinition.availableToCreate
    ),
  );

  const renderContent = useCallback((contentDefinition: ContentDefinition) => (
    <ConditionalTooltip
      key={ contentDefinition.id }
      active={ !!(anonymous && contentDefinition.identifiesUser) }
      title={ intl.formatMessage({
        description: 'Tooltip displayed for disabled content when form is anonymous and content identifies the user.',
        defaultMessage: 'Content that identifies the user cannot be used while responses are being collected anonymously.'
      }) }
    >
      <CreateContentButton
        onClick={ () => onContentCreated(contentDefinition.createEditorContent() as T) }
        disabled={ anonymous && contentDefinition.identifiesUser }
      >
        <Stack
          gap={ 2 }
          fillParent
        >
          <contentDefinition.IconComponent/>
          <span>{ contentDefinition.getLocalisedType(intl) }</span>
          { contentDefinition.new
            ? (
              <StackEnd>
                <NewFeatureBadge/>
              </StackEnd>
            )
            : contentDefinition.richText && (
              <StackEnd>
                <AiFeatureBadge/>
              </StackEnd>
            )
          }
        </Stack>
      </CreateContentButton>
    </ConditionalTooltip>
  ), [anonymous, intl, onContentCreated]);

  return (
    <StyledFormCreateMenu>
      <div role="menuitem">
        <h4>
          <FormattedMessage
            id="forms.editor.createMenu.contentHeading"
            description="Heading for 'content' category in forms content create menu."
            defaultMessage="Content"
          />
        </h4>
        { contentDefinitions.map(renderContent) }
      </div>
      <div role="menuitem">
        <h4>
          <FormattedMessage
            id="forms.editor.createMenu.inputHeading"
            description="Heading for 'input' category in forms content create menu."
            defaultMessage="Input"
          />
        </h4>
        { inputDefinitions.map(renderContent) }
      </div>
    </StyledFormCreateMenu>
  );
};
