import { FC, useMemo } from 'react';

import {
  ChoiceRequestDefinition,
  DateRequestDefinition,
  GeolocationRequestDefinition,
  NumberRequestDefinition,
  RatingRequestDefinition,
  TextContentDefinition,
  TextRequestDefinition,
  UploadRequestDefinition,
} from '../Service';
import { ContentDefinition } from '../../Content/Model';
import { ContentDefinitionRegistryProvider } from '../../Content/Provider';
import { SignRequestDefinition } from '../Service/ContentDefinitions/SignRequestDefinition';
import { VideoContentDefinition } from '../Service/ContentDefinitions/VideoContentDefinition';

export const FormContentDefinitionRegistryProvider: FC = ({
  children,
}) => {
  const definitions = useMemo<ContentDefinition[]>(
    () => [
      new TextRequestDefinition() as unknown as ContentDefinition,
      new TextContentDefinition() as unknown as ContentDefinition,
      new VideoContentDefinition() as unknown as ContentDefinition,
      new SignRequestDefinition() as unknown as ContentDefinition,
      new ChoiceRequestDefinition() as unknown as ContentDefinition,
      new NumberRequestDefinition() as unknown as ContentDefinition,
      new DateRequestDefinition() as unknown as ContentDefinition,
      new RatingRequestDefinition() as unknown as ContentDefinition,
      new UploadRequestDefinition() as unknown as ContentDefinition,
      new GeolocationRequestDefinition() as unknown as ContentDefinition,
    ],
    [],
  );

  return (
    <ContentDefinitionRegistryProvider
      definitions={definitions}
    >
      { children }
    </ContentDefinitionRegistryProvider>
  );
};
