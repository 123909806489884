import { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ContentPreviewProps } from '../../../../Content/Model';
import { UPLOAD_IMAGE_LIMIT, UploadEditorContent } from '../../../Service';
import { CardTitle, ContentWithType, ScrollableContent } from '..';
import { PresentationIcon, VerticallySpaced } from '../../../../Common/Component';
import DogImage from '../../../../Assets/img/sample/dog.jpg';
import CatImage from '../../../../Assets/img/sample/cat.jpg';
import FrogImage from '../../../../Assets/img/sample/frog.jpg';
import { ArrayHelper } from '../../../../Common/Utility';
import { UploadPreview } from '../../../../Content/Component';
import {
  StyledAddIcon,
  StyledCenteredButton,
  StyledDescription,
  StyledHelpText,
  StyledSquareUploadButton
} from './style';

type SampleImage = {
  src: string;
  alt: string;
};

const sampleImages: SampleImage[] = [
  {
    src: DogImage,
    alt: 'Dog wearing sun glasses',
  },
  {
    src: CatImage,
    alt: 'Cat looking over its shoulder',
  },
  {
    src: FrogImage,
    alt: 'Frog on a leaf',
  },
];

export const UploadRequestPreview: FC<ContentPreviewProps<UploadEditorContent>> = ({
  editorContent,
  onReady,
}) => {
  const intl = useIntl();
  const [uploads, setUploads] = useState<SampleImage[]>([]);

  useEffect(() => {
    onReady();
  }, [onReady]);

  const whenAddImagesButtonClicked = (): void => {
    setUploads([
      ...uploads,
      sampleImages[Math.floor(Math.random() * sampleImages.length)],
    ]);
  };

  const whenRemoveUploadButtonClicked = (index: number): void => {
    const updatedUploads = ArrayHelper.remove(uploads, index);
    setUploads(updatedUploads);
  };

  const uploadImagesLabel = intl.formatMessage({
    id: 'broadcasts.content.upload.preview.addImages',
    description: 'Add images button label in upload card preview.',
    defaultMessage: 'Add images',
  });

  return (
    <ScrollableContent>
      <ContentWithType
        localisedContentType={ intl.formatMessage({
          id: 'broadcasts.content.upload.text',
          description: 'Label for upload content type used in preview.',
          defaultMessage: 'Upload',
        }) }
      >
        <VerticallySpaced gap={ 2 }>
          <CardTitle>{ editorContent.card.content.title }</CardTitle>
          <StyledDescription>{ editorContent.card.content.description }</StyledDescription>
          { uploads.map((upload, index) => (
            <UploadPreview
              key={ index }
              src={ upload.src }
              alt={ upload.alt }
              onRemoveClicked={ () => whenRemoveUploadButtonClicked(index) }
            />
          )) }
          { uploads.length < UPLOAD_IMAGE_LIMIT && (
            uploads.length > 0
              ? (
                <StyledSquareUploadButton
                  variant="secondary"
                  onClick={ whenAddImagesButtonClicked }
                >
                  <VerticallySpaced
                    gap={ 1 }
                    align="center"
                  >
                    <PresentationIcon IconComponent={ StyledAddIcon }/>
                    { uploadImagesLabel }
                  </VerticallySpaced>
                </StyledSquareUploadButton>
              )
              : (
                <StyledCenteredButton
                  IconComponent={ StyledAddIcon }
                  variant="secondary"
                  onClick={ whenAddImagesButtonClicked }
                >
                  { uploadImagesLabel }
                </StyledCenteredButton>
              )
          ) }
          { uploads.length > 0 && (
            <StyledCenteredButton variant="primary">
              <FormattedMessage
                id="broadcasts.content.upload.submitLabel"
                description="Label for submit button in upload request card."
                defaultMessage="Submit"
              />
            </StyledCenteredButton>
          ) }
          <StyledHelpText>
            <FormattedMessage
              id="broadcasts.content.upload.helpText"
              description="Image limit help text in upload request card."
              defaultMessage="Add up to { max } images"
              values={ { max: UPLOAD_IMAGE_LIMIT } }
            />
          </StyledHelpText>
        </VerticallySpaced>
      </ContentWithType>
    </ScrollableContent>
  );
};
