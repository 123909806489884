import { ValidationTree, Validator } from '../../../../Common/Model';
import { Validate } from '../../../../Common/Utility';
import { DraftLink } from '../../../Component/Content/LinkEditor/LinkEditor';

export class LinkValidator {
  public static validate = (draftLink: DraftLink): { [P in keyof DraftLink]?: ValidationTree<string> } => {
    return {
      ...(
        draftLink.linkType && draftLink.linkTarget !== undefined
          ? {
            linkTarget: draftLink.linkType === 'url'
              ? LinkValidator.validateUrl(draftLink.linkTarget || '')
              : LinkValidator.validateId(draftLink.linkTarget || ''),
          }
          : {}
      ),
      ...(
        draftLink.linkLabel || draftLink.linkType || draftLink.linkTarget
          ? {
            linkLabel: LinkValidator.validateButtonLabel(draftLink.linkLabel || ''),
          }
          : {}
      ),
    };
  };
  public static validateUrl = (url: string): ValidationTree<string> => ({
    errors: urlValidator.validate(url),
    children: {},
  });

  public static validateId = (id: string): ValidationTree<string> => ({
    errors: idValidator.validate(id),
    children: {},
  });

  public static validateButtonLabel = (buttonLabel: string): ValidationTree<string> => ({
    errors: buttonLabelValidator.validate(buttonLabel),
    children: {},
  });
}

const urlValidator = new Validator<string>([
  Validate.notBlank,
  Validate.url,
]);

const idValidator = new Validator<string>([
  Validate.notBlank,
]);

const buttonLabelValidator = new Validator<string>([
  Validate.notBlank,
  label => Validate.maxLength(label, LINK_LABEL_MAX_LENGTH),
]);

export const LINK_LABEL_MIN_LENGTH = 1;
export const LINK_LABEL_MAX_LENGTH = 80;
