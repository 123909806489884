import { FC } from 'react';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { LineClamp } from 'op-storybook/stories/components/LineClamp/LineClamp';
import { Typography } from 'op-storybook/stories/components/Typography/Typography';

import { MinimalPerson } from '../../../Models';
import { PersonAvatar } from '../../../Common/Component/PersonAvatar/PersonAvatar';
import { PersonParser } from '../../../Utility';

type Props = {
  person: MinimalPerson;
  username: string;
  className?: string;
};

export const UserIdentity: FC<Props> = ({
  person,
  username,
  className,
}) => {
  return (
    <Stack
      gap={ 4 }
      css={ {
        maxWidth: '275px',
      } }
      className={ className }
    >
      <PersonAvatar
        person={ person }
        size="md"
      />
      <Stack
        direction="column"
        gap={ 1 }
      >
        <LineClamp lines={ 2 }>
          <Typography
            weight="semibold"
            size="lg"
            palette={ {
              colour: 'grey',
              intensity: 700,
            } }
          >
            { PersonParser.fullName(person) }
          </Typography>
        </LineClamp>
        <LineClamp lines={ 1 }>
          <Typography
            weight="regular"
            size="md"
            palette={ {
              colour: 'grey',
              intensity: 600,
            } }
          >
            { username }
          </Typography>
        </LineClamp>
      </Stack>
    </Stack>
  );
};
