import { FunctionComponent, useMemo } from 'react';
import { Link, Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { TabBar } from 'op-storybook/stories/components/TabBar/TabBar';
import { BreakpointContext } from 'op-storybook/lib/providers/BreakpointProvider/BreakpointProvider';

import { StyledTab, StyledTabs } from '../../../Components/Navigation/styles';
import { RouterItem } from '../../Model';
import { GuardedLink } from '../../../Security/Component';
import { useContextOrThrow } from '../../../Core/Hook';

type Props = {
  tabs: RouterItem[];
  useNewThemeVariant?: 'page' | 'section';
};

export const RouterTabs: FunctionComponent<Props> = ({
  tabs,
  useNewThemeVariant,
}) => {
  const { pathname } = useLocation();
  const { url } = useRouteMatch();
  const screenWidth = useContextOrThrow(BreakpointContext);

  const matchedPathSegment = useMemo<string | undefined>(() => {
    return (
      tabs.find(tab => pathname.startsWith(tab.link))?.link || undefined
    )
  }, [tabs, pathname]);

  return (
    <>
      {
        matchedPathSegment
          ? (
            <>
              {
                useNewThemeVariant
                  ? (
                    <TabBar
                      tabData={ tabs.map(tab => ({
                        value: tab.link,
                        label: tab.title,
                      })) }
                      context={ useNewThemeVariant }
                      value={ matchedPathSegment }
                      getTabProps={ tabData => ({
                        component: Link,
                        to: tabData.value,
                        css: { '&:hover': { textDecoration: 'none' } }
                      }) }
                      spaceEvenly={ screenWidth.lessThan.sm }
                      fitContent={ useNewThemeVariant === 'section' && !screenWidth.lessThan.sm }
                    />
                  )
                  : (
                    <StyledTabs
                      value={ pathname }
                      indicatorColor="primary"
                      textColor="primary"
                    >
                      { tabs.map(tab => (
                        <StyledTab
                          key={ tab.link }
                          value={ tab.link }
                          label={ tab.title }
                          component={ GuardedLink }
                          to={ tab.link }
                          permissions={ tab.permissions }
                          hidden={ tab.condition !== undefined && !tab.condition }
                        />
                      )) }
                    </StyledTabs>
                  )
              }
              <Switch>
                { tabs.map(tab => (
                  <Route
                    key={ tab.link }
                    path={ tab.link }
                    render={
                      tab.condition === undefined || tab.condition
                        ? tab.render
                        : () => <Redirect to={ tab.redirect || url }/>
                    }
                  />
                )) }
              </Switch>
            </>
          )
          : tabs.length
            ? <Redirect to={ tabs[0].link }/>
            : <Redirect to={ url }/>
      }
    </>
  );
};
