import { FunctionComponent, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { LegacyDropzone } from '../../../Components';
import { useAuthoriseAndUpload } from '../../../Common/Hook';

type Props = {
  authoriseEndpoint: string;
  onComplete: (importId: string) => void;
};

export const CsvDropzone: FunctionComponent<Props> = ({
  authoriseEndpoint,
  onComplete,
}) => {
  const intl = useIntl();
  const [isProcessing, setIsProcessing] = useState<boolean>(true);
  const authoriseAndUpload = useAuthoriseAndUpload();

  const onDrop = useCallback((
    files: File[],
    onUploadProgress: (progressEvent: ProgressEvent) => void
  ): Promise<void> => new Promise((resolve, reject) => {
    const file = files[0];

    if (!file) {
      reject();
    }

    authoriseAndUpload(
      authoriseEndpoint,
      files[0],
      upload => onComplete(`${ upload.id }`),
      error => reject(error),
      progress => onUploadProgress(new ProgressEvent('progress', { total: file.size, loaded: file.size * progress })),
    );
  }), [authoriseAndUpload, authoriseEndpoint, onComplete]);

  return (
    <LegacyDropzone
      accept=".csv, text/csv"
      multiple={ false }
      instruction={
        intl.formatMessage({
          id: 'tags.import.uploadInstruction',
          description: 'Instruction on people import drag-n-drop upload form',
          defaultMessage: 'Drag & drop your CSV file',
        })
      }
      isProcessing={ isProcessing }
      onDrop={ onDrop }
      onReset={ () => setIsProcessing(true) }
    />
  );
};
