import styled from '@emotion/styled';
import {
  Step,
  Stepper,
  StepButton,
  StepButtonProps,
  StepConnector,
  StepConnectorProps,
  StepLabel,
  StepLabelProps,
  StepIconProps,
  Tabs,
  Tab,
} from '@mui/material';
import { ExtendButtonBase } from '@mui/material/ButtonBase';
import { TabTypeMap } from '@mui/material/Tab/Tab';

import { css } from '../../Styling/css';

export const StyledStepper = styled(Stepper)`
  margin: 1rem 0;
  padding: 1rem;
`;

export const StyledStep = styled(Step)`

`;

export interface StyledStepLabelProps extends StepLabelProps {
  active?: boolean;
  completed?: boolean;
  error?: boolean;
  valid?: boolean;
}

export interface StyledStepButtonProps extends StepButtonProps {
  active?: boolean;
  completed?: boolean;
  error?: boolean;
  valid?: boolean;
}

const BaseStepLabel = css<StyledStepLabelProps | StyledStepButtonProps>`
  .MuiStepLabel-label {
    margin-top: 0.5rem;
    font-size: 1.1rem;
    color: ${props => props.theme.palette.grey[400]};

    ${ props => props.active && `
      color: ${props.theme.palette.secondary.main};
    ` }

    ${props => props.error && `
      color: ${props.theme.palette.error.main};
    `}

    ${props => props.valid && `
      color: ${props.theme.palette.success.main};
    `}
  }
`;

export const StyledStepLabel = styled(
  ({valid, error, ...props}: StyledStepLabelProps) => (
    <StepLabel {...props} />
  )
)`
  ${BaseStepLabel}
`;

export const StyledStepButton = styled(
  ({valid, error, ...props}: StyledStepButtonProps) => (
    <StepButton {...props} />
  )
)`
  ${BaseStepLabel}
  &:focus {
    outline: none;
  }
`;

export interface StyledStepConnectorProps extends StepConnectorProps {
  active?: boolean;
  completed?: boolean;
  $error?: boolean;
  previousStepActive?: boolean;
  previousStepError?: boolean;
  previousStepValid?: boolean;
  previousStepCompleted?: boolean;
}

export const StyledStepConnector = styled(
  ({
    previousStepActive,
    previousStepError,
    previousStepValid,
    previousStepCompleted,
    $error,
    ...props
  }: StyledStepConnectorProps) => (
    <StepConnector {...props} />
  )
)`
  top: 18px;
  top: calc(1.2rem - 2px);
  --active-step-colour: ${ props =>
    props.$error
      ? props.theme.palette.error.main
      : props.completed
        ? props.theme.palette.secondary.main
        : props.theme.palette.grey[500]
  };
  --previous-step-colour: ${ props =>
    props.previousStepError
      ? props.theme.palette.error.main
      : props.previousStepCompleted
        ? props.theme.palette.secondary.main
        : props.theme.palette.grey[500]
  };

  .MuiStepConnector-line {
    height: 5px;
    border: 0;
    background: linear-gradient(
      to right,
      var(--previous-step-colour),
      var(--previous-step-colour) 50%,
      var(--active-step-colour) 50%,
      var(--active-step-colour)
    );
  }
`;

export interface StyledStepIconProps extends StepIconProps {
  valid?: boolean;
}

export const StyledStepIcon = styled.div<StyledStepIconProps>`
  width: 2.5rem;
  height: 2.5rem;
  font-size: 1.2rem;
  border-radius: 50%;
  background-color: ${props => props.theme.palette.grey[500]};
  z-index: 1;
  color: ${props => props.theme.palette.common.white};
  display: flex;
  justify-content: center;
  align-items: center;

  ${props => (props.active || props.completed) && `
    background-color: ${props.theme.palette.secondary.main};
  `}

  ${props => (props.error) && `
    background-color: ${props.theme.palette.error.main};
  `}

  ${props => (props.valid) && `
    background-color: ${props.theme.palette.success.main};
  `}
`;

export const StyledTabs = styled(Tabs)`
  border-bottom: 1px solid ${props => props.theme.palette.grey[200]};
`;

export const StyledTab = styled(Tab)`
  font-size: 1.2rem;
  padding: 0.75rem 4rem;
  min-height: 2rem;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;

  &.Mui-selected {
    .MuiTab-wrapper {
      svg {
        fill: ${props => props.theme.palette.primary.main};
        rect {
          stroke: ${props => props.theme.palette.primary.main};
        }
      }
    }
  }

  &:hover {
    background-color: ${props => props.theme.palette.grey[100]};
  }
` as ExtendButtonBase<TabTypeMap>;
