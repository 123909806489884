import { FC, useMemo } from 'react';
import { TransitionProps } from '@mui/material/transitions';

import { useFetchUploadUrls } from '../../Hook';
import { VideoDialog } from '../VideoDialog/VideoDialog';

type Props = {
  open: boolean;
  uploadId: string;
  onClose: () => void;
  TransitionProps?: TransitionProps;
};

export const VideoUploadDialog: FC<Props> = ({
  open,
  uploadId,
  onClose,
  TransitionProps,
}) => {
  const [fetchUrlsResult] = useFetchUploadUrls(uploadId);

  const preferredUrl = useMemo<string | undefined>(
    () =>
      [
        ...(fetchUrlsResult?.content?.streamable || [])
          .sort((sourceA, sourceB) => (
            prioritisedStreamingSourceNames.indexOf(sourceB.name) - prioritisedStreamingSourceNames.indexOf(sourceA.name)
          ))
          .map(source => source.url),
        ...(fetchUrlsResult?.content?.original ? [fetchUrlsResult.content.original] : []),
      ][0],
    [fetchUrlsResult?.content],
  );

  return (
    <>
      { preferredUrl && (
        <VideoDialog
          open={ open }
          url={ preferredUrl }
          onClose={ onClose }
          TransitionProps={ TransitionProps }
        />
      ) }
    </>
  );
};

const prioritisedStreamingSourceNames = [
  'HLS',
  'MP4',
];
