import {Paginated} from '../../Models';
import {FetchResponse, useGet} from '../../Hooks';
import {UserInteraction} from '../../Models/UserInteraction';

type Transaction = {
  id: string;
  creditCount: number;
  refund: boolean;
  note: string;
  created: UserInteraction;
}

type FetchCreditTransactionsParams = {
  pageNum?: number;
};

export const useFetchCreditTransactions = (params: FetchCreditTransactionsParams): FetchResponse<Paginated<'transactions', Transaction>> => useGet('/sms/credit-transactions', params);
