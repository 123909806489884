import { FC, useMemo } from 'react';
import * as RadSlider from '@radix-ui/react-slider';
import { StyleBuilder } from '../../../lib/model/StyleBuilder/StyleBuilder';

type Props = {
  min: number;
  max: number;
  value: number;
  step: number;
  onChange: (value: number) => void;
};

export const Slider: FC<Props> = ({
  min,
  max,
  value,
  step,
  onChange,
}) => {
  const styles = useMemo(() => buildStyles(), []);

  return (
    <RadSlider.Root
      value={ [value] }
      min={ min }
      max={ max }
      step={ step }
      onValueChange={ values => onChange(values[0]) }
      css={ styles.root }
    >
      <RadSlider.Track
        css={ styles.track }
      >
        <RadSlider.Range
          css={ styles.range }
        />
      </RadSlider.Track>
      <RadSlider.Thumb
        css={ styles.thumb }
      />
    </RadSlider.Root>
  );
};

const buildStyles: StyleBuilder = () => ({
  root: theme => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: theme.new.spacing[6],
  }),
  track: theme => ({
    position: 'relative',
    flexGrow: 1,
    backgroundColor: theme.new.palette.grey[200].main,
    borderRadius: theme.new.borderRadius.max,
    height: theme.new.spacing[2],
  }),
  range: theme => ({
    position: 'absolute',
    backgroundColor: theme.new.palette.primary[600].main,
    borderRadius: theme.new.borderRadius.max,
    height: '100%',
  }),
  thumb: theme => ({
    display: 'block',
    width: theme.new.spacing[6],
    height: theme.new.spacing[6],
    backgroundColor: 'white',
    boxShadow: '0px 2px 4px -2px #1018280F, 0px 4px 8px -2px #1018281A',
    borderRadius: theme.new.borderRadius.max,
    border: `1.5px solid ${ theme.new.palette.primary[600].main }`,
  }),
});
