import styled from '@emotion/styled';

export const StyledLocationInput = styled.div`
  input {
    pointer-events: none;
  }

  svg {
    color: ${ props => props.theme.palette.grey[500] };
  }

  .MuiButtonBase-root.MuiIconButton-root {
    width: ${ props => props.theme.spacing(4) };
    height: ${ props => props.theme.spacing(4) };
    margin: 0;
  }
`;
