import { FC, useMemo } from 'react';
import { ChartData } from 'chart.js';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme } from '@emotion/react';
import { Heading } from '@ourpeople/shared/Core/Component/Content';

import { BarChartMetric } from '../../../../Reporting/Component';
import {
  BroadcastReceivedNotification
} from '../../../../Sections/Broadcasts/Report/Hooks/useFetchBroadcastReportSummary';
import { StyledNotificationEngagementChart } from './style';

type Props = {
  notificationCounts: BroadcastReceivedNotification[];
};

export const NotificationEngagementChart: FC<Props> = ({
  notificationCounts,
}) => {
  const intl = useIntl();
  const theme = useTheme();
  const localisedLabels = useMemo(() => new Map([
    [
      'push', intl.formatMessage({
      id: 'broadcasts.report.notificationChannel.push',
      description: 'Label for push notification channel in notification engagement chart.',
      defaultMessage: 'Push notification',
    })
    ],
    [
      'sms', intl.formatMessage({
      id: 'broadcasts.report.notificationChannel.sms',
      description: 'Label for SMS notification channel in notification engagement chart.',
      defaultMessage: 'SMS',
    })
    ],
    [
      'email', intl.formatMessage({
      id: 'broadcasts.report.notificationChannel.email',
      description: 'Label for email notification channel in notification engagement chart.',
      defaultMessage: 'Email',
    })
    ],
  ]), [intl]);

  const chartData = useMemo<Required<ChartData<'bar'>>>(() => notificationCounts.reduce<Required<ChartData<'bar'>>>(
    (accumulatedChartData: Required<ChartData<'bar'>>, notificationChannelCounts) => ({
      datasets: [
        {
          ...accumulatedChartData.datasets[0],
          data: accumulatedChartData.datasets[0].data.concat(notificationChannelCounts.responded)
        }, {
          ...accumulatedChartData.datasets[1],
          data: accumulatedChartData.datasets[1].data.concat(notificationChannelCounts.total - notificationChannelCounts.responded)
        }
      ],
      labels: accumulatedChartData.labels.concat(localisedLabels.get(notificationChannelCounts.service) || notificationChannelCounts.service),
    }),
    {
      datasets: [
        {
          label: intl.formatMessage({
            id: 'broadcasts.report.notificationChannel.responded',
            description: 'Label for responded count in notification engagement chart.',
            defaultMessage: 'Responded',
          }),
          barThickness: 20,
          backgroundColor: theme.palette.secondary.main,
          data: [],
        },
        {
          label: intl.formatMessage({
            id: 'broadcasts.report.notificationChannel.notResponded',
            description: 'Label for not responded count in notification engagement chart.',
            defaultMessage: 'Not responded',
          }),
          barThickness: 20,
          backgroundColor: theme.palette.secondary.light,
          data: [],
        },
      ],
      labels: [],
    },
  ), [intl, localisedLabels, notificationCounts, theme.palette.secondary.light, theme.palette.secondary.main]);

  return (
    <StyledNotificationEngagementChart>
      <Heading type="h3">
        <FormattedMessage
          id="broadcasts.report.notificationChannel.heading"
          description="Heading for notification channel report."
          defaultMessage="Engagement by notification channel"
        />
      </Heading>
      <div>
        <BarChartMetric
          chartData={ chartData }
          ungroupBars
          horizontal
          ignoreAspectRatio
        />
      </div>
    </StyledNotificationEngagementChart>
  );
};
