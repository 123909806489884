import styled from '@emotion/styled';

export const StyledFormCard = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
`;

export const StyledFormHeading = styled.div`
  padding: ${ props => props.theme.spacing(1) };

  h3 {
    margin: 0;
  }
`;
