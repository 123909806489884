import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { Button } from 'op-storybook/stories/components/Button/Button';
import { ChangeEventHandler, FC, useCallback, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
  value: string;
  onChange: (value: string) => void;
}

export const ColourPickerButton: FC<Props> = ({
  value,
  onChange,
}) => {
  const labelRef = useRef<HTMLLabelElement>(null);
  const timeoutRef = useRef<number>();
  const whenChanged = useCallback<ChangeEventHandler<HTMLInputElement>>(event => {
    const newValue = event.currentTarget.value
    timeoutRef.current && clearTimeout(timeoutRef.current);
    timeoutRef.current = window.setTimeout(() => {
      onChange(newValue);
    }, 10);
  }, [onChange])

  const whenClicked = useCallback(() => {
    labelRef.current?.click();
  }, []);

  return (
    <div>
      <Button
        variant="secondary"
        padding="uniform"
        type="button"
        onClick={ whenClicked }
        css={ {
          width: '100%',
        } }
      >
        <Stack gap={ 2 }>
          <div
            css={ theme => ({
              borderRadius: theme.new.borderRadius.small,
              transition: 'background-color 100ms',
              backgroundColor: value,
              width: '24px',
              height: '24px',
              boxShadow: `${ theme.new.shadow.md } inset, ${ theme.new.shadow.sm } inset`,
            }) }
          />
          <FormattedMessage
            description="Label for colour picker button"
            defaultMessage="Choose color"
          />
        </Stack>
      </Button>
      {/*
        We must use a label so that we can programmatically open the colour picker on iOS.
        See https://stackoverflow.com/questions/74511391/open-input-color-programatically-in-ios/74512068
      */}
      <label ref={labelRef}>
        <input
          type="color"
          value={ value }
          onChange={ whenChanged }
          css={ {
            opacity: 0,
            pointerEvents: 'none',
            height: 0,
            width: 0,
            overflow: 'hidden',
            position: 'absolute',
          } }
        />
      </label>
    </div>
  );
};
