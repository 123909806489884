import { FC } from 'react';

import { Heading } from '../Heading/Heading';
import { StyledMessage } from './style';

export type MessageProps = {
  heading: string;
  body?: string;
};

export const Message: FC<MessageProps> = ({
  heading,
  body,
}) => (
  <StyledMessage>
    <Heading type="h3">
      { heading }
    </Heading>
    { body && <p>{ body }</p> }
  </StyledMessage>
);
