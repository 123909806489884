import { FC, PropsWithChildren } from 'react';

import { PrimaryInformation } from './PrimaryInformation';

export const SectionHeading: FC<PropsWithChildren> = ({ children }) => (
  <PrimaryInformation
    css={ {
      fontWeight: '500',
    } }
  >
    { children }
  </PrimaryInformation>
);
