import { FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { TextField } from '@mui/material';
import { parseISO } from 'date-fns';
import { Heading } from '@ourpeople/shared/Core/Component/Content';

import { ScrollableContent } from '../../../../Broadcasts/Component/Content';
import {
  BroadcastContentFooter,
  ContentPadding,
  NudgeBanner,
  RespondButton,
  RespondButtonProps,
  StackNavigationProps
} from '..';
import { useInputChangeEventHandler } from '../../../../Common/Hook';
import { VerticallySpaced } from '../../../../Common/Component';
import { CharacterCount } from '../..';
import { BroadcastNudge } from '../../../../Broadcasts/Model';
import { AbsoluteBackButtonContainer } from '../AbsoluteBackButtonContainer/AbsoluteBackButtonContainer';

type Props = {
  question: string;
  submitted?: boolean;
  submitProps: RespondButtonProps;
  navigationProps: StackNavigationProps;
  input: string;
  onChange: (input: string) => void;
  nudge?: BroadcastNudge;
  backButton: ReactNode | undefined;
};

export const CommentBroadcastContent: FC<Props> = ({
  question,
  submitted = false,
  submitProps,
  navigationProps,
  input,
  onChange,
  nudge,
  backButton,
}) => {
  const intl = useIntl();
  const whenChanged = useInputChangeEventHandler(onChange);

  return (
    <>
      { backButton && <AbsoluteBackButtonContainer>{ backButton }</AbsoluteBackButtonContainer> }
      <ScrollableContent>
        {
          nudge && (
            <NudgeBanner
              message={ nudge.message }
              nudgedAt={ parseISO(nudge.at) }
            />
          )
        }
        <ContentPadding>
          <VerticallySpaced gap={ 2 }>
            <Heading type="h3">
              { question }
            </Heading>
            <TextField
              fullWidth
              disabled={ submitted }
              multiline
              inputProps={ {
                maxLength: 280,
              } }
              value={ input }
              onChange={ whenChanged }
            />
            { !submitted && (
              <CharacterCount
                current={ input.length }
                maximum={ 280 }
                minimum={ 1 }
              />
            ) }
          </VerticallySpaced>
        </ContentPadding>
      </ScrollableContent>
      <BroadcastContentFooter { ...navigationProps }>
        <RespondButton
          { ...submitProps }
          variant="primary"
          notRespondedLabel={ intl.formatMessage({
            id: 'content.broadcast.comment.notResponded',
            description: 'Label for submit button when not responded.',
            defaultMessage: 'Submit',
          }) }
          respondedLabel={ intl.formatMessage({
            id: 'content.broadcast.comment.responded',
            description: 'Label for submit button when responded.',
            defaultMessage: 'Submitted',
          }) }
          rejectedLabel={ intl.formatMessage({
            id: 'content.broadcast.comment.rejected',
            description: 'Label for submit button when response was rejected.',
            defaultMessage: 'Try again',
          }) }
        />
      </BroadcastContentFooter>
    </>
  );
};
