import { FunctionComponent } from 'react';
import { FormattedList, FormattedMessage, useIntl } from 'react-intl';
import RRule, { Frequency, WeekdayStr } from 'rrule';
import { addMinutes } from 'date-fns';

import { RRuleParser } from '../../../../Utility/RRuleParser';

interface LocalisedDay {
  id: WeekdayStr,
  name: string,
  index: number,
}

type Props = {
  recurrenceRule?: string | null;
}

export const ReadableRecurrenceRule: FunctionComponent<Props> = (props) => {
  const intl = useIntl();
  const localisedDays: LocalisedDay[] = new Array(7)
    .fill(null)
    .map((emptyValue: null, index: number) => {
      // Offset index by 5 to begin at first monday of epoch.
      const weekday = new Date(1970, 0, index + 5);
      return {
        id: weekday.toLocaleString('en', { weekday: 'short' })
          .substr(0, 2)
          .toUpperCase() as WeekdayStr,
        name: intl.formatDate(weekday, { weekday: 'long' }),
        index,
      };
    });

  const renderUnrecognisedSchedule = (): JSX.Element => {
    return <FormattedMessage
      id="broadcasts.recurrence.unrecognisedSchedule"
      defaultMessage="Schedule not recognised"
    />
  };

  const renderWeekdays = (rRule: RRule): JSX.Element | string => {
    const nonWeekendWeekdays = [0, 1, 2, 3, 4];
    const nonWeekendOnly = nonWeekendWeekdays.every(weekday => rRule.options.byweekday.includes(weekday));

    if (nonWeekendOnly) {
      return (
        <FormattedMessage
          description="Short name for days monday - friday used in broadcast recurrence schedule summary."
          defaultMessage="weekdays"
        />
      )
    }

    const localisedWeekdays = rRule.options.byweekday.map((weekdayIndex: number) => {
      return localisedDays[weekdayIndex].name;
    });

    if (localisedWeekdays.length === 1) {
      return localisedWeekdays[0];
    }

    return (
      <FormattedList
        value={ localisedWeekdays }
        type="conjunction"
      />
    );
  };

  const renderOrdinal = (rRule: RRule): JSX.Element => {
    switch (rRule.options.bysetpos[0]) {
      case -1:
        return <FormattedMessage
          id="broadcasts.recurrence.scheduledLast"
          defaultMessage="last"
        />;
      case 1:
        return <FormattedMessage
          id="broadcasts.recurrence.scheduledFirst"
          defaultMessage="first"
        />;
      case 2:
        return <FormattedMessage
          id="broadcasts.recurrence.scheduledSecond"
          defaultMessage="second"
        />;
      case 3:
        return <FormattedMessage
          id="broadcasts.recurrence.scheduledThird"
          defaultMessage="third"
        />;
      default:
        return <FormattedMessage
          id="broadcasts.recurrence.scheduledFourth"
          defaultMessage="fourth"
        />;
    }
  };

  const renderDailySchedule = (rRule: RRule): JSX.Element => {
    if (rRule.options.byweekday && rRule.options.byweekday.length) {
      if (rRule.options.byweekday.length === 7) {
        return (
          <FormattedMessage
            description="Summary for broadcasts with daily schedules for every day"
            defaultMessage="Every day"
          />
        );
      }

      return <FormattedMessage
        id="broadcasts.recurrence.scheduleDaily"
        description="Summary for broadcasts with daily recurring broadcast schedules"
        defaultMessage="Daily on {weekdays}"
        values={ {
          weekdays: renderWeekdays(rRule),
        } }
      />
    }
    return renderUnrecognisedSchedule();
  };

  const renderWeeklySchedule = (rRule: RRule): JSX.Element => {
    if (rRule.options.byweekday && rRule.options.byweekday.length) {
      return <FormattedMessage
        id="broadcasts.recurrence.scheduleWeekly"
        description="Summary for broadcasts with weekly recurring broadcast schedules"
        defaultMessage="Weekly on {weekday}"
        values={ {
          weekday: renderWeekdays(rRule),
        } }
      />
    }
    return renderUnrecognisedSchedule();
  };

  const renderMonthlySchedule = (rRule: RRule): JSX.Element => {
    const hasWeekdays = rRule.options.byweekday && rRule.options.byweekday.length;
    const hasOrdinal = rRule.options.bysetpos && rRule.options.bysetpos.length;
    if (hasWeekdays && hasOrdinal) {
      return <FormattedMessage
        id="broadcasts.recurrence.scheduleMonthly"
        description="Summary for broadcasts with monthly recurring broadcast schedules"
        defaultMessage="Monthly, {ordinal} {weekday}"
        values={ {
          ordinal: renderOrdinal(rRule),
          weekday: renderWeekdays(rRule),
        } }
      />
    }
    return renderUnrecognisedSchedule();
  };

  const renderYearlySchedule = (rRule: RRule) => {
    const correctedUtcStart = addMinutes(rRule.options.dtstart, RRuleParser.localeOffsetInMinutes(rRule, intl.locale));
    const localeStart = addMinutes(correctedUtcStart, new Date().getTimezoneOffset());
    return (
      <FormattedMessage
        description="Summary for broadcasts with yearly recurring broadcast schedules"
        defaultMessage="Yearly on { date }"
        values={ {
          date: localeStart
            .toLocaleDateString(intl.locale, { month: 'long', day: 'numeric' }),
        } }
      />
    );
  }

  const renderSchedule = (): JSX.Element => {
    if (props.recurrenceRule) {
      const rRule = RRule.fromString(props.recurrenceRule);
      if (rRule.options && typeof rRule.options.freq === 'number') {
        switch (rRule.options.freq) {
          case Frequency.DAILY:
            return renderDailySchedule(rRule);
          case Frequency.WEEKLY:
            return renderWeeklySchedule(rRule);
          case Frequency.MONTHLY:
            return renderMonthlySchedule(rRule);
          case Frequency.YEARLY:
            return renderYearlySchedule(rRule);
        }
      }

      return renderUnrecognisedSchedule();
    }
    return <FormattedMessage
      id="broadcasts.recurrence.scheduleOnce"
      defaultMessage="Once"
    />;
  };

  return (
    <>
      { renderSchedule() }
    </>
  );
};
