import styled from '@emotion/styled';

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50%;
  height: 50vh;
  gap: 1rem;
`;
