import { FC, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';

import { ContentPreviewProps } from '../../../../Content/Model';
import { TimelineEditorContent } from '../../../Service';
import { CardTitle, ContentWithType, ScrollableContent } from '..';
import { VerticallySpaced } from '../../../../Common/Component';
import { useEnvironmentSettings } from '../../../../Common/Hook';
import { StyledDivider, StyledStackLogo } from './style';
import defaultStackLogo from '../../../../Assets/img/icons/coloured/stack.svg?url';

export const TimelinePreview: FC<ContentPreviewProps<TimelineEditorContent>> = ({
  editorContent,
  context,
  onReady,
}) => {
  const intl = useIntl();
  const { stackLogoUrl } = useEnvironmentSettings();

  useEffect(() => {
    onReady();
  }, [onReady]);

  return (
    <>
      <ScrollableContent>
        <ContentWithType
          localisedContentType={ intl.formatMessage({
            id: 'broadcasts.content.preview.timeline',
            description: 'Label for timeline card used in preview.',
            defaultMessage: 'Stack',
          }) }
        >
          <VerticallySpaced gap={ 2 } align="center">
            <CardTitle>{ editorContent.card.content.text }</CardTitle>
            <StyledDivider/>
            <span>
              <FormattedMessage
                id="broadcasts.content.preview.timeline.cardCount"
                description="Card count for timeline card preview"
                defaultMessage="{ cardCount, plural, one { # card } other { # cards } }"
                values={ { cardCount: (context?.cardCount || 2) - 1 } }
              />
            </span>
            <Button variant="primary">
              <FormattedMessage
                id="broadcasts.content.preview.timeline.openLabel"
                description="Open button label for timeline card preview"
                defaultMessage="Open"
              />
            </Button>
            <StyledStackLogo src={ stackLogoUrl || defaultStackLogo }/>
          </VerticallySpaced>
        </ContentWithType>
      </ScrollableContent>
      <div/>
    </>
  );
}
