import styled from '@emotion/styled';

export const StyledLineChartMetric = styled.div`
  display: grid;
  grid-template-rows: repeat(3, auto);
  row-gap: 1rem;

  canvas {
    width: 100% !important;
  }
`;

export const LineChartLegend = styled.div`
  display: flex;
  flex-wrap: wrap;

  >div {
    flex-grow: 1;
    flex-basis: 7rem;
  }
`;
