import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Alert } from '../../../Components';
import { useFetchRemoteIntegration } from '../../../Hooks';
import { RemoteIntegration } from '../../../Types';

type Props = {
  onSyncComplete: () => void,
}

export const IntegrationSyncingStatus: FunctionComponent<Props> = (
  {
    onSyncComplete,
  }
) => {
  const [fetchedIntegration, , refreshIntegration] = useFetchRemoteIntegration();
  const [integration, setIntegration] = useState<RemoteIntegration|null>(null);
  const [syncCompletedCallbackCalled, setSyncCompletedCallbackCalled] = useState<boolean>(false);
  const receivedCountRef = useRef<number>(0);

  useEffect(() => {
    setIntegration(
      fetchedIntegration && fetchedIntegration.content
        ? fetchedIntegration.content
        : null
    );
  }, [fetchedIntegration]);

  useEffect(() => {
    receivedCountRef.current = receivedCountRef.current + (integration ? 1 : 0);
    if ((!integration || !integration.integration.state.lastFullSyncFinishedAt) && !fetchedIntegration?.error) {
      const timeout = setTimeout(
        () => refreshIntegration(),
        8000,
      );
      return () => clearTimeout(timeout);
    }
    if (integration && integration.integration.state.lastFullSyncFinishedAt && receivedCountRef.current > 1 && !syncCompletedCallbackCalled) {
      onSyncComplete();
      setSyncCompletedCallbackCalled(true);
    }
  }, [fetchedIntegration?.error, integration, onSyncComplete, refreshIntegration, syncCompletedCallbackCalled]);

  return (
    <>
      {
        integration && !integration.integration.state.lastFullSyncFinishedAt
          && <Alert showSpinner={true} showIcon={false}>
            <span>
              <FormattedMessage
                id="people.notice.integrationSyncing"
                defaultMessage="<strong>Your third party import is in progress.</strong> This may take a little while, once completed your new people will be added to the list below."
              />
            </span>
        </Alert>
      }
    </>
  );
};
