import { MappedSettingTypes, SettingType } from '../../Types';
import {
  BooleanSettingValue,
  ColourSettingValue,
  CollectionSettingValue,
  DurationSettingValue,
  HtmlSettingValue,
  ImageSettingValue,
  LinkSettingValue,
  NumberSettingValue,
  TextSettingValue,
  TimezoneSettingValue,
  UrlSettingValue,
  ChoiceSettingValue,
} from '.';
import { AggregateSettingValue } from './AggregateSettingValue';
import { DateTimeSettingValue } from './DateTimeSettingValue';

type Props<T extends SettingType> = {
  type: T,
  value: MappedSettingTypes[T]['value'],
  config: MappedSettingTypes[T]['config'],
};

export const MappedSettingValue = <T extends SettingType>({
  type,
  value,
  config,
}: Props<T>): JSX.Element => {
  return (
    <>
      {
        type === 'boolean'
        && (
          <BooleanSettingValue value={value as MappedSettingTypes['boolean']['value']} />
        )
      }
      {
        type === 'collection'
        && (
          <CollectionSettingValue
            config={config as MappedSettingTypes['collection']['config']}
            value={value as MappedSettingTypes['collection']['value']}
          />
        )
      }
      {
        type === 'colour'
        && (
          <ColourSettingValue value={value as MappedSettingTypes['colour']['value']} />
        )
      }
      {
        type === 'duration'
        && (
          <DurationSettingValue value={value as MappedSettingTypes['duration']['value']} />
        )
      }
      {
        type === 'html'
        && (
          <HtmlSettingValue value={value as MappedSettingTypes['html']['value']} />
        )
      }
      {
        type === 'image'
        && (
          <ImageSettingValue value={value as MappedSettingTypes['image']['value']} />
        )
      }
      {
        type === 'link'
        && (
          <LinkSettingValue value={value as MappedSettingTypes['link']['value']} />
        )
      }
      {
        type === 'number'
        && (
          <NumberSettingValue value={value as MappedSettingTypes['number']['value']} />
        )
      }
      {
        type === 'text'
        && (
          <TextSettingValue value={value as MappedSettingTypes['text']['value']} />
        )
      }
      {
        type === 'timezone'
        && (
          <TimezoneSettingValue value={value as MappedSettingTypes['timezone']['value']} />
        )
      }
      {
        type === 'url'
        && (
          <UrlSettingValue value={value as MappedSettingTypes['url']['value']} />
        )
      }
      { type === 'choice' && (
        <ChoiceSettingValue
          config={ config as MappedSettingTypes['choice']['config'] }
          value={ value as MappedSettingTypes['choice']['value'] }
        />
      ) }
      { type === 'datetime' && (
        <DateTimeSettingValue
          value={ value as MappedSettingTypes['datetime']['value'] }
        />
      ) }
      { type === 'aggregate' && (
        <AggregateSettingValue
          config={ config as MappedSettingTypes['aggregate']['config'] }
          value={ value as MappedSettingTypes['aggregate']['value'] }
        />
      ) }
    </>
  );
};
