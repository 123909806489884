import { FC, ReactNode, useCallback, useMemo, useRef, useState } from 'react';

import { NoticeContext } from '../Context';
import { useApi } from '../Hook';
import { Notices } from '../../Common/Model';
import { useMounted } from '../../Common/Hook';

type Props = {
  maxAge?: number;
  children?: ReactNode;
};

export const NoticeProvider: FC<Props> = ({
  maxAge = 60000,
  children,
}) => {
  const api = useApi();
  const [requestResolvedAt, setRequestResolvedAt] = useState<number>(0);
  const cachedRequest = useRef<Promise<Notices>>();
  const mounted = useMounted();

  const getNotices = useCallback((): Promise<Notices> => {
    const responseAge = Date.now() - requestResolvedAt;
    let request = cachedRequest.current;
    if ((responseAge > maxAge) || !request) {
      request = api.get<Notices>('/space/notices')
        .then(response => {
          if (mounted.current) {
            setRequestResolvedAt(Date.now());
          }

          return response.data;
        });
      cachedRequest.current = request;
    }

    return request;
  }, [api, cachedRequest, maxAge, mounted, requestResolvedAt]);

  const value = useMemo(() => ({
    getNotices,
  }), [getNotices]);

  return (
    <NoticeContext.Provider value={ value }>
      { children }
    </NoticeContext.Provider>
  );
};
