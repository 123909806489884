import { isAfter, isBefore } from 'date-fns';

import {
  BlankError, NotHexColourError,
  NotNumberError, NotUrlError, NotUtf8Error,
  TooEarlyError,
  TooFewError,
  TooLargeError,
  TooLateError,
  TooLongError,
  TooManyError,
  TooShortError,
  TooSmallError
} from '../Model';

export class Validate {
  public static notBlank = (value: unknown): BlankError | void => {
    if (!value || (typeof value === 'string' && value.trim() === '')) {
      return {
        type: 'blank',
        metadata: null,
      }
    }
  };

  public static hexColour = (value: string): NotHexColourError | void => {
    if (value && !/^#[0-9A-Fa-f]{6}$/.test(value)) {
      return {
        type: 'notHexColour',
        metadata: null,
      };
    }
  };

  public static utf8 = (value: string): NotUtf8Error | void => {
    if (!value.includes('�')) {
      return;
    }

    return {
      type: 'notUtf8',
      metadata: null,
    };
  };

  public static url = (url: string): NotUrlError | void => {
    try {
      new URL(url);
    } catch {
      return {
        type: 'notUrl',
        metadata: null,
      };
    }
  };

  public static minLength = (text: string, minLength: number): TooShortError | void => {
    if (text && text.length < minLength) {
      return {
        type: 'tooShort',
        metadata: {
          actualLength: text.length,
          minLength,
        },
      };
    }
  };

  public static maxLength = (text: string, maxLength: number): TooLongError | void => {
    if (text.length > maxLength) {
      return {
        type: 'tooLong',
        metadata: {
          actualLength: text.length,
          maxLength,
        },
      };
    }
  };

  public static isNumeric = (text: string | number): NotNumberError | void => {
    if (!/^-?\d+(\.\d+)?$/.test(text.toString())) {
      return {
        type: 'notNumber',
        metadata: null,
      };
    }
  };

  public static countGreaterThanEq = (arr: unknown[], minCount: number): TooFewError | void => {
    if (arr.length < minCount) {
      return {
        type: 'tooFew',
        metadata: {
          actualCount: arr.length,
          minCount,
        },
      };
    }
  };

  public static countLessThanEq = (arr: unknown[], maxCount: number): TooManyError | void => {
    if (arr.length > maxCount) {
      return {
        type: 'tooMany',
        metadata: {
          actualCount: arr.length,
          maxCount,
        },
      };
    }
  };

  public static greaterThanEq = (value: number, min: number): TooSmallError | void => {
    if (value < min) {
      return {
        type: 'tooSmall',
        metadata: {
          actual: value,
          min,
        },
      };
    }
  };

  public static lessThanEq = (value: number, max: number): TooLargeError | void => {
    if (value > max) {
      return {
        type: 'tooLarge',
        metadata: {
          actual: value,
          max,
        },
      };
    }
  };

  public static dateBefore = (date: Date, maxDate: Date): TooLateError | void => {
    if (isAfter(date, maxDate)) {
      return {
        type: 'tooLate',
        metadata: {
          actualDate: date.valueOf() / 1000,
          maxDate: maxDate.valueOf() / 1000,
        },
      };
    }
  };

  public static dateAfter = (date: Date, minDate: Date): TooEarlyError | void => {
    if (isBefore(date, minDate)) {
      return {
        type: 'tooEarly',
        metadata: {
          actualDate: date.valueOf() / 1000,
          minDate: minDate.valueOf() / 1000,
        },
      };
    }
  };
}
