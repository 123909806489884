import { IntlShape } from 'react-intl';

import { SingleContentCard, DraftContent, EditorContent } from '../../../../Content/Model';
import SignRequestIcon from '../../../../Assets/img/icons/monochrome/content/sign.svg';
import { UniqueIdGenerator } from '../../../../Common/Utility';
import { CardTransformer } from '../../../../Content/Utility';
import { SignRequestValidator } from '../../Validators';
import { SignRequestEditor, SignRequestPreview } from '../../../Component';
import { BroadcastContent, BroadcastContentDefinition } from '../../../Model';
import { LiveSignContent } from '../../../../Inbox/Component';
import { SignResponse } from '../../../../Inbox/Model';
import { EnvironmentSettings } from '../../../../Models';

export type SignRequest = BroadcastContent & {
  type: 'signcontent';
  text: string;
  mandatory: true;
};

export type DraftSignRequest = DraftContent & {
  type: 'signcontent';
  text: string;
};

export type SignEditorContent = EditorContent<DraftSignRequest>;

export class SignRequestDefinition implements BroadcastContentDefinition<SignRequest, SignEditorContent, SignResponse> {
  public readonly id = 'sign';
  public readonly availableToCreate = true;
  public readonly new = false;
  public readonly richText = false;
  public readonly contentTypes = ['signcontent'];
  public readonly categoryId = 'feedback';
  public readonly IconComponent = SignRequestIcon;
  public readonly EditorComponent = SignRequestEditor;
  public readonly PreviewComponent = SignRequestPreview;
  public readonly LiveComponent = LiveSignContent;
  public readonly SubmittedComponent = (): null => null;
  public validate = SignRequestValidator.validate;

  public definesContentType = (type: string): boolean => (
    this.contentTypes.includes(type)
  );

  public getContentTitle = (intl: IntlShape, content: SignRequest): string => (
    content.text || this.getDefaultTitle(intl)
  );

  public getLocalisedType = (intl: IntlShape): string => (
    intl.formatMessage({
      id: 'broadcasts.content.sign.type',
      description: 'Localised name for sign content type.',
      defaultMessage: 'Sign',
    })
  );

  public getDraftContentTitle = (intl: IntlShape, draftContent: DraftSignRequest): string => (
    draftContent.text || this.getDefaultTitle(intl)
  );

  public hasRequiredFeatureFlags = (environmentSettings: EnvironmentSettings): boolean => (
    !!environmentSettings.signTypeEnabled
  );

  public createEditorContent = (): SignEditorContent => ({
    id: UniqueIdGenerator.generate(),
    card: {
      content: {
        type: 'signcontent',
        text: '',
      },
      mandatory: true,
      image: null,
    },
  });

  public transformCard = (card: SingleContentCard<SignRequest>): SignEditorContent => ({
    id: card.id,
    card: CardTransformer.transformCard(card, {
      type: 'signcontent',
      text: card.content.text,
    }),
  });

  private getDefaultTitle = (intl: IntlShape): string => (
    intl.formatMessage({
      id: 'broadcasts.content.sign.defaultTitle',
      description: 'Default title for sign request.',
      defaultMessage: 'Untitled sign card',
    })
  );
}
