import { FC, useMemo, useState } from 'react';
import { LoadingCard } from '@ourpeople/shared/Core/Component/Feedback';

import { BroadcastContentPreview } from '../../BroadcastContentPreview/BroadcastContentPreview';
import { TemplateBroadcast } from '../../../Hook';
import { TimelineDefinition } from '../../../Service';
import { AspectRatioPreview } from '../../../../Content/Component';
import { StyledLoadingContainer } from './style';
import { SpaceThemeProvider } from '../../../Provider';
import { useContentDefinitionRegistry } from '../../../../Content/Hook';
import { BroadcastTransformer } from '../../../Utility';

type Props = {
  broadcast: TemplateBroadcast;
};

export const BroadcastTemplatePreviewPane: FC<Props> = ({ broadcast }) => {
  const contentDefinitionRegistry = useContentDefinitionRegistry();
  const [activeContentIndex, setActiveContentIndex] = useState<number>(0);
  const [, editorContents] = useMemo(() => BroadcastTransformer.templateBroadcastToDraft(broadcast, contentDefinitionRegistry), [broadcast, contentDefinitionRegistry]);

  const activeContent = useMemo(() => {
    if (!editorContents[activeContentIndex]) {
      throw new Error('Content at index does not exist in template.');
    }

    return editorContents[activeContentIndex];
  }, [activeContentIndex, editorContents]);
  const activeContentIsTimelineCard = useMemo(
    () => activeContent !== null ? new TimelineDefinition().definesContentType(activeContent.card.content.type) : false,
    [activeContent],
  );

  const contentPreview = useMemo(() => (
    activeContent && (
      <BroadcastContentPreview
        content={ activeContent }
        onPreviousClicked={ () => setActiveContentIndex(activeContentIndex => Math.max(0, activeContentIndex - 1)) }
        onNextClicked={ () => setActiveContentIndex(activeContentIndex => Math.min(editorContents.length - 1, activeContentIndex + 1)) }
        previousButtonHidden={ activeContentIndex <= 0 }
        nextButtonHidden={ activeContentIndex >= editorContents.length - 1 }
        cardCount={ editorContents.length }
        { ...(
          activeContentIsTimelineCard
            ? { buttonColor: 'inherit' }
            : {}
        ) }
      />
    )
  ), [activeContent, activeContentIndex, activeContentIsTimelineCard, editorContents.length]);

  return (
    <AspectRatioPreview>
      {
        activeContent
          ? (
            activeContentIsTimelineCard
              ? (
                <SpaceThemeProvider>
                  { contentPreview }
                </SpaceThemeProvider>
              )
              : contentPreview
          )
          : <StyledLoadingContainer><LoadingCard/></StyledLoadingContainer>
      }
    </AspectRatioPreview>
  );
};
