import { FC, useCallback, useState } from 'react';

import { LiveComponentProps } from '../../../../Content/Model';
import { QuizRequest } from '../../../../Broadcasts/Service';
import { ChoiceResponse } from '../../../Model';
import { BroadcastContentContext } from '../../../../Broadcasts/Model';
import { useRecallErrorHandler, useRespondButtonProps } from '../../../Hook';
import { QuizBroadcastContent } from '../../../../Content/Component';

export const LiveQuizContent: FC<LiveComponentProps<QuizRequest, ChoiceResponse, BroadcastContentContext>> = ({
  card: { content },
  response,
  context,
  onResponseChange,
  onReloadRequired,
}) => {
  const [partialResponse, setPartialResponse] = useState<Omit<ChoiceResponse, 'createdAt'>>({
    type: 'choice',
    input: {
      optionIds: response?.input.optionIds || [],
    },
    deliveryId: context.deliveryId,
    contentId: content.id.toString(),
  });
  const submitButtonProps = useRespondButtonProps(
    partialResponse,
    onResponseChange,
    useRecallErrorHandler(onReloadRequired),
    response,
    (response: ChoiceResponse) => ({
      type: 'choice',
      option_ids: response.input.optionIds,
      time_created: response.createdAt,
      deliveryId: response.deliveryId,
      content: response.contentId,
    }),
  );

  const whenAnswerIdChanged = useCallback((answerId: string) => (
    setPartialResponse({
      ...partialResponse,
      input: {
        optionIds: [answerId],
      },
    })
  ), [partialResponse]);

  return (
    <QuizBroadcastContent
      backButton={ context.closeButton }
      title={ content.title }
      answerId={ partialResponse.input.optionIds[0] || null }
      onAnswerIdChanged={ whenAnswerIdChanged }
      options={ content.options }
      submitProps={ submitButtonProps }
      responded={ !!response }
      navigationProps={ context.navigationProps }
      nudge={ context.nudge }
    />
  );
};
