import styled from '@emotion/styled';
import { rgba } from 'polished';

export const ScrollableContent = styled.div`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-color: ${ props => rgba(props.theme.palette.grey[500], 0.5) } transparent;

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 10px;
    height: 10px;
    position: absolute;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${ props => rgba(props.theme.palette.grey[500], 0.5) };
    border-radius: 100px;
    height: 10px;
    width: 10px;
    background-clip: padding-box;
    border: 2px solid transparent;
    border-right: 2px solid transparent;
    border-left: 2px solid transparent;
  }
`;
