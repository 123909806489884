import { parseISO } from 'date-fns';
import { ChartData, ChartDataset, ChartTypeRegistry } from 'chart.js';

import { DataPoint, Dataset, Metric } from '../Model';
import { MetricReader } from '.';

export type DatasetOptionsWithKey = Omit<ChartDataset, 'data'> & { key: string };

export class MetricTransformer {
  public static sumDataPoints = (dataPoints: number[]): number => {
    return dataPoints.reduce((runningTotal, dataPoint) => runningTotal + dataPoint, 0);
  };

  public static sumDatasets = (metric: Metric, datasetKeys: string[]): number[] => {
    return datasetKeys.reduce<number[]>(
      (consolidatedData, datasetKey) => {
        const dataset = MetricReader.getDataset(
          metric.datasets,
          datasetKey,
        ) || createDataset(datasetKey);

        const sum = dataset.data.reduce((total, dataPoint) => total + dataPoint.value, 0);
        return consolidatedData.concat(sum);
      },
      [],
    );
  };

  public static transformToChartData = <T extends keyof ChartTypeRegistry>(metric: Metric, datasetOptions: DatasetOptionsWithKey[]): ChartData<T> => {
    const start = Math.floor(parseISO(metric.config.range.start).getTime() / 1000);
    const end = Math.floor(parseISO(metric.config.range.end).getTime() / 1000);
    const timestamps = calculateTimestamps(start, end, metric.config.interval || 86400);

    const chartDatasets = datasetOptions.reduce<ChartDataset<T>[]>(
      (accumulatedChartDatasets, { key, ...options }) => {
        const dataset = MetricReader.getDataset(metric.datasets, key) || createDataset(key);
        const completedDataset = completeDataset(dataset, timestamps);

        return accumulatedChartDatasets.concat({
          ...options,
          data: completedDataset.data.map(dataPoint => dataPoint.value),
        } as ChartDataset<T>);
      },
      [],
    );

    return {
      labels: localiseTimestamps(timestamps),
      datasets: chartDatasets,
    }
  };

  public static consolidateDatasets = <T extends keyof ChartTypeRegistry, I extends { id: string; }>(
    metric: Metric,
    datasetOptions: DatasetOptionsWithKey[],
    xAxisItems: I[],
    getItemLabel: (item: I) => string,
  ): Required<ChartData<T>> => {

    const dataPointGroups: number[][] = datasetOptions.map(datasetOptions => {
      return xAxisItems.map((item) => MetricReader.getDataset(metric.datasets, `${item.id}:${datasetOptions.key}`)?.data[0]?.value || 0);
    });

    return {
      datasets: datasetOptions.map(({key, ...options}, index) => ({
        ...options,
        data: dataPointGroups[index],
      } as ChartDataset<T>)),
      labels: xAxisItems.map(getItemLabel),
    };
  };
}

const createDataset = (key: string): Dataset => ({
  key,
  data: [],
  metadata: {},
});

const createDatapoint = (timestamp: number, value = 0): DataPoint => ({
  timestamp,
  value,
});

const localiseTimestamps = (timestamps: number[]): string[] => {
  return timestamps.map(timestamp => {
    return new Date(timestamp * 1000)
      .toLocaleDateString(
        undefined,
        { month: 'short', day: 'numeric' },
      );
  });
}

const completeDataset = (dataset: Dataset, timestamps: number[]): Dataset => {
  const dataPoints = timestamps.map(timestamp => {
    return dataset.data.find(dataPoint => dataPoint.timestamp === timestamp) || createDatapoint(timestamp);
  });

  return {
    ...dataset,
    data: dataPoints,
  }
}

const calculateTimestamps = (start: number, end: number, interval: number): number[] => {
  return timestampsBetween([start], end, interval);
}

const timestampsBetween = (timestamps: number[], end: number, interval: number): number[] => {
  const latest = timestamps[timestamps.length - 1];
  if (latest + interval <= end) {
    return timestampsBetween(timestamps.concat(latest + interval), end, interval);
  }

  return timestamps;
}
