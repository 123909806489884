import { PreviewComponent } from '../../../Model/BroadcastBlockDefinition';
import { TextBlock } from '../../../Service/Blocks/TextBlockDefinition';
import { RichTextSanitiser } from '../../../../../Common/Utility';

export const TextBlockPreview: PreviewComponent<TextBlock> = ({
  block,
}) => {

  return (
    <span>
      { RichTextSanitiser.stripTags(block.attributes.html) }
    </span>
  );
};
