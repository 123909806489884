import { MappedSettingTypes, SettingType } from '../../Types';
import {
  AggregateSettingInput,
  BooleanSettingInput,
  ChoiceSettingInput,
  CollectionSettingInput,
  ColourSettingInput,
  DateTimeSettingInput,
  DurationSettingInput,
  HtmlSettingInput,
  ImageSettingInput,
  LinkSettingInput,
  NumberSettingInput,
  TextSettingInput,
  TimezoneSettingInput,
  UrlSettingInput,
} from '.';

interface Props<T extends SettingType> {
  type: T,
  value: MappedSettingTypes[T]['value'],
  config: MappedSettingTypes[T]['config'],
  onValueChanged: (value: MappedSettingTypes[T]['value']) => void;
  focusId: string;
}

export const MappedSettingInput = <T extends SettingType>({
  type,
  value,
  config,
  onValueChanged,
  focusId,
}: Props<T>): JSX.Element => {
  return (
    <>
      {
        type === 'boolean'
        && (
          <BooleanSettingInput
            value={value as MappedSettingTypes['boolean']['value']}
            onValueChanged={onValueChanged}
            focusId={focusId}
          />
        )
      }
      {
        type === 'collection'
        && (
          <CollectionSettingInput
            config={config as MappedSettingTypes['collection']['config']}
            value={value as MappedSettingTypes['collection']['value']}
            onValueChanged={onValueChanged}
            focusId={focusId}
          />
        )
      }
      {
        type === 'colour'
        && (
          <ColourSettingInput
            config={config as MappedSettingTypes['colour']['config']}
            value={value as MappedSettingTypes['colour']['value']}
            onValueChanged={onValueChanged}
            focusId={focusId}
          />
        )
      }
      {
        type === 'duration'
        && (
          <DurationSettingInput
            config={config as MappedSettingTypes['duration']['config']}
            value={value as MappedSettingTypes['duration']['value']}
            onValueChanged={onValueChanged}
            focusId={focusId}
          />
        )
      }
      {
        type === 'html'
        && (
          <HtmlSettingInput
            config={config as MappedSettingTypes['html']['config']}
            value={value as MappedSettingTypes['html']['value']}
            onValueChanged={onValueChanged}
          />
        )
      }
      {
        type === 'image'
        && (
          <ImageSettingInput
            config={config as MappedSettingTypes['image']['config']}
            value={value as MappedSettingTypes['image']['value']}
            onValueChanged={onValueChanged}
            focusId={focusId}
          />
        )
      }
      {
        type === 'link'
        && (
          <LinkSettingInput
            config={config as MappedSettingTypes['link']['config']}
            value={value as MappedSettingTypes['link']['value']}
            onValueChanged={onValueChanged}
            focusId={focusId}
          />
        )
      }
      {
        type === 'number'
        && (
          <NumberSettingInput
            config={config as MappedSettingTypes['number']['config']}
            value={value as MappedSettingTypes['number']['value']}
            onValueChanged={onValueChanged}
            focusId={focusId}
          />
        )
      }
      {
        type === 'text'
          && (
            <TextSettingInput
              config={config as MappedSettingTypes['text']['config']}
              value={value as MappedSettingTypes['text']['value']}
              onValueChanged={onValueChanged}
              focusId={focusId}
            />
          )
      }
      {
        type === 'timezone'
        && (
          <TimezoneSettingInput
            config={config as MappedSettingTypes['timezone']['config']}
            value={value as MappedSettingTypes['timezone']['value']}
            onValueChanged={onValueChanged}
            focusId={focusId}
          />
        )
      }
      {
        type === 'url'
        && (
          <UrlSettingInput
            config={config as MappedSettingTypes['url']['config']}
            value={value as MappedSettingTypes['url']['value']}
            onValueChanged={onValueChanged}
            focusId={focusId}
          />
        )
      }
      { type === 'choice' && (
        <ChoiceSettingInput
          value={ value as MappedSettingTypes['choice']['value'] }
          config={ config as MappedSettingTypes['choice']['config'] }
          onValueChanged={ onValueChanged }
          focusId={ focusId }
        />
      ) }
      { type === 'datetime' && (
        <DateTimeSettingInput
          value={ value as MappedSettingTypes['datetime']['value'] }
          config={ config as MappedSettingTypes['datetime']['config'] }
          onValueChanged={ onValueChanged }
          focusId={ focusId }
        />
      )}
      { type === 'aggregate' && (
        <AggregateSettingInput
          value={ value as MappedSettingTypes['aggregate']['value'] }
          config={ config as MappedSettingTypes['aggregate']['config'] }
          onValueChanged={ onValueChanged }
          focusId={ focusId }
        />
      ) }
    </>
  );
};
