import { FC } from 'react';

import { StyledIconContainer, StyledLockIconContainer } from './style';
import LockIcon from '../../../../Assets/img/icons/streamline/lock.svg';

type Props = {
  Icon: FC;
  showLock?: boolean;
};

export const NavigationIcon: FC<Props> = ({
  Icon,
  showLock = false,
}) => (
  <StyledIconContainer>
    <Icon/>
    { showLock && (
      <StyledLockIconContainer>
        <LockIcon />
      </StyledLockIconContainer>
    ) }
  </StyledIconContainer>
);
