import styled from '@emotion/styled';
import { FormControlLabel } from '@mui/material';

export const StyledFormControlLabel = styled(FormControlLabel)`
  margin-bottom: 0;
  margin-right: 0;
  white-space: nowrap;
`;

export const StyledTextFieldContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: ${ props => props.theme.spacing(4) };

  ${ props => props.theme.breakpoints.down('md') } {
    width: 100%;
  }
`;
