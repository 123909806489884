import { FormattedMessage, useIntl } from 'react-intl';
import { FormControl, MenuItem, Select } from '@mui/material';
import { Dispatch, FC, ReactNode, SetStateAction, useCallback, useMemo } from 'react';
import { parse } from 'date-fns';
import { LocalisedString } from 'op-storybook/lib/model/LocalisedString/LocalisedString';

import { WeekdaySelect } from '../WeekdaySelect/WeekdaySelect';
import { InterFieldText } from '../InterFieldText/InterFieldText';
import { RRuleOrdinal } from '../../../Utility/RRuleParser';
import { useInputChangeEventHandler } from '../../../Common/Hook/useInputChangeEventHandler';
import { useSelectChangeEventHandler } from '../../../Common/Hook/useSelectChangeEventHandler';
import { StyledScheduleMonthlySettings } from './style';
import { NoMarginTextField } from '../../../Common/Component/NoMarginTextField/NoMarginTextField';
import { DateTimeFormatter } from '../../../Utility/DateTimeFormatter';
import { DayIndex, MonthlySchedule } from '../../Model/BroadcastSchedule';

type Props = {
  schedule: MonthlySchedule;
  setSchedule: Dispatch<SetStateAction<MonthlySchedule>>;
};

export const ScheduleMonthlySettings: FC<Props> = ({
  schedule,
  setSchedule,
}) => {
  const intl = useIntl();
  const time = useMemo(() => (
    DateTimeFormatter.timeInput(
      new Date(
        0,
        0,
        1,
        schedule.recurrence.repetition.localHour,
        schedule.recurrence.repetition.minutes,
        schedule.recurrence.repetition.seconds,
        0,
      ),
    )
  ), [
    schedule.recurrence.repetition.localHour,
    schedule.recurrence.repetition.minutes,
    schedule.recurrence.repetition.seconds
  ]);

  const ordinals = useMemo<LocalisedString<RRuleOrdinal>[]>(() => ([
    {
      id: -1,
      localisation: intl.formatMessage({
        id: 'rRule.oridinal.last',
        defaultMessage: 'Last',
      }),
    },
    {
      id: 1,
      localisation: intl.formatMessage({
        id: 'rRule.oridinal.first',
        defaultMessage: 'First',
      }),
    },
    {
      id: 2,
      localisation: intl.formatMessage({
        id: 'rRule.oridinal.second',
        defaultMessage: 'Second',
      }),
    },
    {
      id: 3,
      localisation: intl.formatMessage({
        id: 'rRule.oridinal.third',
        defaultMessage: 'Third',
      }),
    },
    {
      id: 4,
      localisation: intl.formatMessage({
        id: 'rRule.oridinal.fourth',
        defaultMessage: 'Fourth',
      }),
    }
  ]), [intl]);

  const whenOrdinalChanged = useSelectChangeEventHandler(
    (ordinal: RRuleOrdinal) => {
      return (
        setSchedule(schedule => ({
          ...schedule,
          recurrence: {
            ...schedule.recurrence,
            repetition: {
              ...schedule.recurrence.repetition,
              ordinal,
            },
          },
        }))
      );
    },
    ordinals,
  );

  const whenTimeChanged = useInputChangeEventHandler(useCallback((time: string) => {
    if (!time) {
      return;
    }

    const date = parse(time, 'HH:mm', new Date());

    setSchedule(schedule => ({
      ...schedule,
      recurrence: {
        ...schedule.recurrence,
        repetition: {
          ...schedule.recurrence.repetition,
          localHour: date.getHours(),
          minutes: date.getMinutes(),
          seconds: date.getSeconds(),
        },
      },
    }));
  }, [setSchedule]));

  const whenWeekdayChanged = useCallback((weekdays: DayIndex[]) => {
    setSchedule(schedule => ({
      ...schedule,
      recurrence: {
        ...schedule.recurrence,
        repetition: {
          ...schedule.recurrence.repetition,
          localDayIndex: weekdays[0],
        },
      },
    }));
  }, [setSchedule]);

  return (
    <StyledScheduleMonthlySettings>
      <FormattedMessage
        id="scheduleRecurring.fields"
        description="Layout of form fields for daily and weekly recurring broadcast schedule."
        defaultMessage="<text>repeating on the</text>{ordinal}{weekday}<text>of the month, at</text>{time}"
        values={ {
          ordinal: (
            <FormControl variant="outlined">
              <Select
                id="broadcast-repeating-on"
                value={ schedule.recurrence.repetition.ordinal }
                autoWidth={ true }
                margin="dense"
                required
                onChange={ whenOrdinalChanged }
                MenuProps={ {
                  anchorOrigin: {
                    horizontal: 'left',
                    vertical: 'bottom',
                  },
                } }
              >
                { ordinals.map(ordinal => (
                  <MenuItem
                    key={ ordinal.id }
                    value={ ordinal.id }
                  >
                    { ordinal.localisation }
                  </MenuItem>
                )) }
              </Select>
            </FormControl>
          ),
          weekday: (
            <WeekdaySelect
              dayIndices={ [schedule.recurrence.repetition.localDayIndex] }
              onChange={ whenWeekdayChanged }
              margin="dense"
            />
          ),
          time: (
            <NoMarginTextField
              id="broadcast-repeating-at"
              variant="outlined"
              type="time"
              required
              name="scheduledTime"
              onChange={ whenTimeChanged }
              value={ time }
            />
          ),
          text: (chunks: (string | ReactNode)[]) => (
            <InterFieldText>{ chunks }</InterFieldText>
          )
        } }
      />
    </StyledScheduleMonthlySettings>
  );
};
