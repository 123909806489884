import { FC, useCallback, useMemo, useRef, useState } from 'react';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { EmptyState } from 'op-storybook/stories/components/EmptyState/EmptyState';
import ImageIcon from 'op-storybook/lib/assets/icon/figma/image-01.svg';
import { useIntl } from 'react-intl';

import { TitleBar } from '../../../../Common/Component/Navigation/TitleBar';
import { ContextMenuItemInlineMode } from '../../../../Common/Component/Content/ContextMenu';
import { ToolbarClearance } from '../../../../Common/Component/Layout/ToolbarClearance';
import { Paginated } from '../../../../../Models';
import { useContextOrThrow } from '../../../../../Core/Hook';
import { ApiContext } from '../../../../../Contexts';
import { InfiniteList, InfiniteListProps } from '../../../../Common/Component/Content/InfiniteList';
import { Sheet } from '../../../../Common/Component/Layout/Sheet';

type Thumbnail = {
  thumbnail: {
    key: string;
    url: string;
  };
};

type Props = {
  uploadId: string;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  videoThumbnailKey: string | null;
  onSubmit: (videoThumbnailKey: string | null) => void;
}

export const VideoThumbnailSheet: FC<Props> = ({
  uploadId,
  open,
  onOpenChange,
  videoThumbnailKey,
  onSubmit,
}) => {
  const api = useContextOrThrow(ApiContext);
  const intl = useIntl();
  const scrollAreaRef = useRef(null);
  const [localSelectedThumbnailKey, setLocalSelectedThumbnailKey] = useState<string | null>(videoThumbnailKey);
  const queryKey = useMemo(() => ['VideoThumbnails', uploadId], [uploadId]);

  const queryFn: InfiniteListProps<Thumbnail>['queryFn'] = useCallback(async (_, page) => {
    const response = await api.get<Paginated<'thumbnails', Thumbnail>>(
      `/uploads/videos/${ uploadId }/thumbnails`,
      {
        params: {
          pageNum: page,
        },
      },
    )
    return {
      ...response.data,
      items: response.data.thumbnails,
    };
  }, [api, uploadId]);

  return (
    <Sheet
      open={ open }
      onOpenChange={ onOpenChange }
    >
      <ToolbarClearance
        ref={scrollAreaRef}
        scrollable={true}
        clear={ ['top'] }
      >
        <Stack
          gap={ 0 }
          direction="column"
        >
          <InfiniteList
            css={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
            }}
            scrollAreaRef={scrollAreaRef}
            filters={queryFilters}
            queryKey={queryKey}
            queryFn={queryFn}
            renderItem={(item) => (
              <img
                key={item.thumbnail.key}
                onClick={() => {
                  setLocalSelectedThumbnailKey(item.thumbnail.key);
                }}
                src={item.thumbnail.url}
                css={theme => ({
                  width: '100%',
                  backgroundColor: theme.new.palette.grey[900].main,
                  objectFit: 'contain',
                  aspectRatio: '1',
                  border: `1px solid ${theme.new.palette.grey[25].main}`,
                  transition: 'transform 100ms, box-shadow 100ms, border-radius 100ms',
                  ...localSelectedThumbnailKey === item.thumbnail.key
                    ? {
                      transform: 'scale(1.05)',
                      outline: `3px solid ${theme.new.palette.primary['400'].main}`,
                      borderRadius: theme.new.borderRadius.small,
                      boxShadow: `${theme.new.shadow.lg}, ${theme.new.shadow.md}, ${theme.new.shadow.sm}`,
                    }
                    : {},
                })}
              />
            )}
            renderEmptyState={() => (
              <EmptyState
                breakpoint="mobile"
                IconComponent={ImageIcon}
                buttonProps={[]}
                text={intl.formatMessage({
                  description: 'Error message when initial request for unsplash images succeeds with no results.',
                  defaultMessage: 'No images found',
                })}
                pad={true}
              />
            )}
            renderErrorState={(refetch) => (
              <EmptyState
                breakpoint="mobile"
                IconComponent={ImageIcon}
                buttonProps={[
                  {
                    variant: 'secondary',
                    children: intl.formatMessage({
                      description: 'Label for retry button when initial request for unsplash images fail to load.',
                      defaultMessage: 'Retry',
                    }),
                    onClick: () => refetch(),
                  },
                ]}
                text={intl.formatMessage({
                  description: 'Error message when initial request for unsplash images fail to load.',
                  defaultMessage: 'Could not load images',
                })}
                pad={true}
              />
            )}
          />
        </Stack>
      </ToolbarClearance>
      <TitleBar
        title={ intl.formatMessage({
          description: 'Title of video thumbnail select sheet.',
          defaultMessage: 'Select thumbnail',
        }) }
        onClose={ () => onOpenChange(false) }
        contextMenuProps={ {
          actions: [
            {
              id: 'video-thumbnail-submit',
              onClick: () => onSubmit(localSelectedThumbnailKey),
              label: intl.formatMessage({
                description: 'Label for submit button in video thumbnail selection sheet.',
                defaultMessage: 'Select',
              }),
              inline: {
                mode: ContextMenuItemInlineMode.LABEL_ONLY,
                variant: 'primary',
              },
            },
          ],
          maxInlineActionCount: 1,
        } }
      />
    </Sheet>
  );
};

const queryFilters = {};
