import { FC, useCallback, useMemo } from 'react';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material';
import { ThemeProvider, useTheme } from '@emotion/react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';

import { SettingsSubCategoryFormProps } from '../DefaultSettingsSubCategoryForm/DefaultSettingsSubCategoryForm';
import { DeviceOutline } from '../DeviceOutline/DeviceOutline';
import { CategorySetting } from '../../../Sections/Settings/System/Components';
import {
  StyledBrandingSettingsSubCategoryForm,
  StyledBrandPreview,
  StyledDeviceContainer,
  StyledDivider,
  StyledPreviewHeader,
  StyledSettingColumn,
  StyledBrandImage,
} from './style';
import { SettingsSubCategoryHeader } from '../SettingsSubCategoryHeader/SettingsSubCategoryHeader';
import { Setting } from '../../../Sections/Settings/System/Types';
import { ImageUpload, VerticallySpaced } from '../../../Common/Component';
import defaultStackIconSrc from '../../../Assets/img/icons/stack_filled.svg?url';

type BrandingSubCategory = {
  key: 'broadcastStackCover';
  name: string;
  settings: [
    Setting<'image'>,
    Setting<'colour'>,
    Setting<'colour'>,
    Setting<'colour'>,
    Setting<'colour'>,
  ];
};

export const BrandingSettingsSubCategoryForm: FC<SettingsSubCategoryFormProps> = ({
  subCategory,
  changedSettings,
  onChange,
  onReset,
}) => {
  const brandingSubCategory = subCategory as BrandingSubCategory;

  const getLatestSettingValue = useCallback((settingKey: string): string => (
    (changedSettings.get(settingKey) as Setting<'colour'> | undefined)?.value
    || brandingSubCategory.settings.find(setting => setting.key === settingKey)?.value as Setting<'colour'>['value'] | undefined
    || '#ffffff'
  ), [brandingSubCategory.settings, changedSettings]);

  const brandBackgroundColour = getLatestSettingValue('stackBackgroundColour');
  const brandForegroundColour = getLatestSettingValue('stackTextColour');
  const brandCtaBackgroundColour = getLatestSettingValue('stackCtaBackgroundColour');
  const brandCtaForegroundColour = getLatestSettingValue('stackCtaTextColour');
  const uploadId = useMemo<string | undefined>(() => {
    const changedUploadSetting = changedSettings.get('stackLogo') as Setting<'image'> | undefined;
    const originalUploadSetting = brandingSubCategory.settings.find(setting => setting.key === 'stackLogo') as Setting<'image'> | undefined;

    if (changedUploadSetting?.value === null) {
      return undefined;
    }

    const changedUploadId = changedUploadSetting
      && changedUploadSetting.value
      && typeof changedUploadSetting.value !== 'string'
      && changedUploadSetting.value.uuid;

    const originalUploadId = originalUploadSetting
      && originalUploadSetting.value
      && typeof originalUploadSetting.value !== 'string'
      && originalUploadSetting.value.uuid;

    return changedUploadId
      ? changedUploadId
      : originalUploadId || undefined;
  }, [brandingSubCategory.settings, changedSettings]);

  const consoleTheme = useTheme();
  const brandTheme = createTheme({
    ...consoleTheme,
    palette: {
      ...consoleTheme.palette,
      primary: {
        main: brandCtaBackgroundColour || consoleTheme.palette.primary.main,
        contrastText: brandCtaForegroundColour || consoleTheme.palette.primary.contrastText,
      },
      secondary: {
        main: brandCtaBackgroundColour || consoleTheme.palette.secondary.contrastText,
        contrastText: brandCtaForegroundColour || consoleTheme.palette.secondary.main,
      },
      background: {
        paper: brandBackgroundColour || consoleTheme.palette.secondary.main,
      },
      text: {
        primary: brandForegroundColour || consoleTheme.palette.secondary.contrastText,
      },
    },
  });

  return (
    <StyledBrandingSettingsSubCategoryForm>
      <StyledSettingColumn>
        <SettingsSubCategoryHeader>
          { brandingSubCategory.name }
        </SettingsSubCategoryHeader>
        { brandingSubCategory.settings.map(setting => (
          <CategorySetting
            key={ setting.key }
            setting={ changedSettings.get(setting.key) || setting }
            onValueChanged={ value => onChange(setting, value) }
            onValueReset={ () => onReset(setting) }
          />
        )) }
      </StyledSettingColumn>
      <StyledDeviceContainer>
        <DeviceOutline>
          <MuiThemeProvider theme={ brandTheme }>
            <ThemeProvider theme={ brandTheme }>
              <StyledBrandPreview>
                <StyledPreviewHeader>
                  <FormattedMessage
                    description="Placeholder title for branding setting preview."
                    defaultMessage="Broadcast title"
                  />
                </StyledPreviewHeader>
                <StyledDivider/>
                <VerticallySpaced gap={ 3 } align="center">
                  <Button variant="primary">
                    <FormattedMessage
                      description="Placeholder button label for branding setting preview."
                      defaultMessage="Open"
                    />
                  </Button>
                  {
                    uploadId
                      ? (
                        <ImageUpload
                          uploadId={ uploadId }
                          renderImage={ uploadUrls => <StyledBrandImage src={ uploadUrls.original }/> }
                        />
                      )
                      : (
                        <StyledBrandImage
                          src={ defaultStackIconSrc }
                        />
                      )
                  }
                </VerticallySpaced>
              </StyledBrandPreview>
            </ThemeProvider>
          </MuiThemeProvider>
        </DeviceOutline>
      </StyledDeviceContainer>
    </StyledBrandingSettingsSubCategoryForm>
  );
};
