import styled from '@emotion/styled';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';

import FileIcon from '../../../../Assets/img/icons/streamline/common-file-empty.svg';
import FolderIcon from '../../../../Assets/img/icons/streamline/folder-empty-1.svg';

export const StyledCenteredButton = styled(Button)`
  margin: 0 auto;
`;

export const StyledFilePath = styled.div`
  padding: 0 ${ props => props.theme.spacing(4) };
  display: flex;
  flex-direction: column;
  gap: ${ props => props.theme.spacing(2) };
`;

export const StyledExpandedIcon = styled.span<{ $showing: boolean }>`
  ${ props => props.$showing && 'transform: rotate(180deg);' }
`;

export const StyledFileIcon = styled(FileIcon)`
  flex-shrink: 0;
`;

export const StyledFolderIcon = styled(FolderIcon)`
  flex-shrink: 0;
`;

export const StyledPathSegment = styled.span`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: ${ props => props.theme.spacing(2) };

  > span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const StyledLoadingIndicator = styled.div`
  display: flex;
  justify-content: center;
`;
