import styled from '@emotion/styled';

import { Box } from '../../../Common/Component';
import { PropsWithTheme } from '../../../Styling/PropsWithTheme';

type StyledMetricContainerProps = {
  span?: number;
  className?: string;
}

export const StyledMetricContainer = styled(({
  children,
  span,
  ...props
}: PropsWithTheme<StyledMetricContainerProps>) => (
  <Box { ...props }>{ children }</Box>
))`
  margin: 0;
  padding: 1.5rem;
  display: grid;
  grid-template-rows: auto 1fr;
  row-gap: 1rem;
  flex-grow: 1;
  min-width: 250px;

  ${ props => props.theme.breakpoints.up('lg') } {
    grid-column-end: span ${ props => props.span || 3 };
  }

  ${ props => props.theme.breakpoints.down('lg') } {
    grid-column-end: span 3;
  }

  ${ props => props.theme.breakpoints.down('md') } {
    grid-column-end: span 6;
  }

  a {
    color: ${ props => props.theme.palette.secondary.main };
  }
`;
