import { useContext } from 'react';

import { AuthContext } from '../../Contexts';
import { AuthDescription } from '../../Models';

export const useAuthDescription = (): AuthDescription|null => {
  const { authDescription } = useContext(AuthContext)

  return authDescription;
}
