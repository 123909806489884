import { PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react';
import { Button } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { AutocompleteSelectionChanged } from '@ourpeople/shared/Core/Component/Input/Autocomplete/Autocomplete';

import { TagParser } from '../../Utility';
import { Tag, TagType } from '../../Model';
import { LoadingSpinner } from '../../../Components';
import { Person, RequestState } from '../../../Models';
import { ApiContext } from '../../../Contexts';
import { SharedFormFields, StyledTagForm } from '..';
import { Flex, FlexPullRight } from '../../../Common/Component';
import { useContextOrThrow } from '../../../Core/Hook';
import { ToastContext } from '../../../Core/Context';

interface Props<T extends TagType> {
  tag: Tag<T>;
  onSaved: (tag: Tag<T>) => void;
}

export const EditForm = <T extends TagType>({
  tag,
  onSaved,
}: PropsWithChildren<Props<T>>): JSX.Element => {
  const api = useContext(ApiContext);
  const intl = useIntl();
  const initialTag = useMemo(() => {
    return {...tag};
  }, [tag]);
  const [tagToSave, setTagToSave] = useState<Tag<T>>(initialTag);
  const parentType = useMemo(() => TagParser.determineParentType(tagToSave.type), [tagToSave.type]);
  const childType = useMemo(() => TagParser.determineChildType(tagToSave.type), [tagToSave.type]);
  const [saveState, setSaveState] = useState<RequestState>(RequestState.PENDING);
  const { addErrorToast } = useContextOrThrow(ToastContext);

  const whenTagNameChanged = (name: string): void => {
    setTagToSave((prev) => ({
      ...prev,
      name,
    }));
  };

  const whenChildTagSelectionChanged: AutocompleteSelectionChanged<Tag<TagType>> = (selection) => {
    const childTagIds = [
      ...selection.unknownIds,
      ...selection.options.map((tag) => tag.id),
    ];
    setTagToSave((prev) => ({
      ...prev,
      childTagIds,
    }));
  };

  const whenParentTagSelectionChanged: AutocompleteSelectionChanged<Tag<TagType>> = (selection) => {
    const parentTagIds = [
      ...selection.unknownIds,
      ...selection.options.map((tag) => tag.id),
    ];
    setTagToSave((prev) => ({
      ...prev,
      parentTagIds,
    }));
  };

  const whenAdminSelectionChanged: AutocompleteSelectionChanged<Person> = (selection) => {
    setTagToSave((prev) => {
      const administratorIds = [
        ...selection.options.map(administrator => administrator.id),
        ...selection.unknownIds,
      ];
      return TagParser.tagIsTeamTag(prev)
        ? {
          ...prev,
          administratorIds,
        }
        : prev;
    });
  };

  const valid = useMemo(() => {
    return !!tagToSave.name;
  }, [tagToSave.name]);

  const save = async () => {
    if (api) {
      setSaveState(RequestState.FETCHING);
      try {
        const savedTag = (await api.post<Tag<T>>(`/tags/${tag.id}`, tagToSave)).data;
        setSaveState(RequestState.COMPLETE);
        onSaved(savedTag);
      } catch (error) {
        addErrorToast(
          intl.formatMessage({
            id: 'tag.edit.saveFailedMessage',
            defaultMessage: 'Failed to save "{tagName}".'
          }, {
            tagName: tagToSave.name,
          })
        );
        setSaveState(RequestState.FAILED);
      }
    }
  };

  return (
    <div>
      <StyledTagForm>
        <SharedFormFields
          onNameChanged={whenTagNameChanged}
          tagToSave={tagToSave}
          parentType={parentType}
          childType={childType}
          onParentTagSelectionChanged={useCallback(whenParentTagSelectionChanged, [setTagToSave])}
          onChildTagSelectionChanged={useCallback(whenChildTagSelectionChanged, [setTagToSave])}
          onAdminSelectionChanged={useCallback(whenAdminSelectionChanged, [setTagToSave])}
        />
      </StyledTagForm>
      <Flex>
        <FlexPullRight>
          {
            saveState === RequestState.FETCHING
              ? (
                <>
                  <FormattedMessage
                    id="tags.edit.saving"
                    defaultMessage="Saving"
                  />
                  <LoadingSpinner/>
                </>
              )
              : (
                <Button
                  color="primary"
                  variant="contained"
                  disableElevation
                  disabled={!valid}
                  onClick={ () => { void save() } }
                >
                  <FormattedMessage
                    id="tags.edit.saveCta"
                    defaultMessage="Save"
                  />
                </Button>
              )
          }
        </FlexPullRight>
      </Flex>
    </div>
  )
}
