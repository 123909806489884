import { LocalisedString } from 'op-storybook/lib/model/LocalisedString/LocalisedString';

import { Chip } from '../Component';

export class ChipHelper {
  public static updateChipsWithBasicAutocompleteSelection = <T extends string, S extends string>(
    chipType: S,
    chips: Chip[],
    selection: LocalisedString<T>[],
    getChipLabel: (option: LocalisedString<T>) => string,
  ): Chip[] => {
    const chipTypeMatchesOption = (chip: Chip) => chip.type === chipType;
    const unModifiedChips = chips.filter(chip => !chipTypeMatchesOption(chip) || selection.find(selectedOption => selectedOption.id === chip.id));
    const newChips = selection.filter(selectedOption => !chips.find(chip => chipTypeMatchesOption(chip) && chip.id === selectedOption.id))
      .map(selectedOption => ({
        id: selectedOption.id,
        type: chipType,
        label: getChipLabel(selectedOption),
      }));

    return [
      ...unModifiedChips,
      ...newChips,
    ];
  };
}
