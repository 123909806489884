import { ChangeEvent, PropsWithChildren } from 'react';
import { TextField } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Heading } from '@ourpeople/shared/Core/Component/Content';
import { AutocompleteSelectionChanged } from '@ourpeople/shared/Core/Component/Input/Autocomplete/Autocomplete';

import { Tag, TagType } from '../../Model';
import { AdminAutocomplete } from '../../../People/Component';
import { TagAutocomplete } from '..';
import { Person } from '../../../Models';

interface Props<T extends TagType, C extends TagType, P extends TagType> {
  childType?: C;
  parentType?: P;
  tagToSave: Tag<T>;
  onChildTagSelectionChanged: AutocompleteSelectionChanged<Tag<C>>;
  onParentTagSelectionChanged: AutocompleteSelectionChanged<Tag<P>>;
  onAdminSelectionChanged: AutocompleteSelectionChanged<Person>;
  onNameChanged: (name: string) => void;
}

export const SharedFormFields = <T extends TagType, C extends TagType, P extends TagType>({
  childType,
  parentType,
  tagToSave,
  onChildTagSelectionChanged,
  onParentTagSelectionChanged,
  onAdminSelectionChanged,
  onNameChanged,
}: PropsWithChildren<Props<T, C, P>>): JSX.Element => {
  const whenTagNameChanged = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    onNameChanged(event.target.value);
  };

  return (
    <div>
      <Heading type="h4">
        <FormattedMessage
          id="tags.edit.nameHeading"
          defaultMessage="Tag name"
        />
      </Heading>
      <TextField
        variant="outlined"
        size="small"
        fullWidth={true}
        disabled={tagToSave.externallyManaged}
        onChange={ whenTagNameChanged }
        value={ tagToSave.name }
      />
      {
        parentType && (
          <>
            <Heading type="h4">
              <FormattedMessage
                id="tags.edit.parentTagHeading"
                defaultMessage="Available to"
              />
            </Heading>
            <TagAutocomplete
              disabled={tagToSave.externallyManaged}
              type={parentType}
              selectedIds={tagToSave.parentTagIds}
              onSelectionChanged={onParentTagSelectionChanged}
              multiple={true}
            />
          </>
        )
      }
      {
        childType && (
          <>
            <Heading type="h4">
              <FormattedMessage
                id="tags.edit.childTagHeading"
                defaultMessage="Associated with"
              />
            </Heading>
            <TagAutocomplete
              disabled={tagToSave.externallyManaged}
              type={childType}
              selectedIds={tagToSave.childTagIds}
              onSelectionChanged={onChildTagSelectionChanged}
              multiple={true}
            />
          </>
        )
      }
      {
        tagToSave.type === 'team' && (
          <>
            <Heading type="h4">
              <FormattedMessage
                id="tags.edit.adminHeading"
                defaultMessage="Team administrators"
              />
            </Heading>
            <AdminAutocomplete
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              selectedIds={ tagToSave.administratorIds }
              excludeGlobalAdmin
              onSelectionChanged={ onAdminSelectionChanged }
              multiple={ true }
            />
            <FormattedMessage
              id="tags.edit.adminNote"
              description="Notice underneath administrator picker"
              defaultMessage="<b>Note</b>: If you can't see the user you are looking for please check they have team admin permissions. Global admins are always admins, so are not selectable in this list."
            />
          </>
        )
      }
    </div>
  )
}
