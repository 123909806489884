import { default as React, FC, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ContentEditorProps } from '../../../../Content/Model';
import {
  COMMENT_TITLE_MAX_LENGTH,
  COMMENT_TITLE_MIN_LENGTH,
  CommentEditorContent,
  CommentRequestValidator,
} from '../../../Service';
import { useInputChangeEventHandler } from '../../../../Common/Hook';
import { FieldValidationErrors, NoMarginTextField, VerticallySpaced } from '../../../../Common/Component';
import { CharacterCount } from '../../../../Content/Component';
import { Switch } from '../../../../Components';
import { useCheckboxChangeEventHandler } from '../../../../Common/Hook/useCheckboxChangeEventHandler';

export const CommentRequestEditor: FC<ContentEditorProps<CommentEditorContent>> = ({
  editorContent,
  onEditorContentChanged,
  validation,
  onValidationChanged,
}) => {
  const intl = useIntl();

  const titleFieldLabel = intl.formatMessage({
    id: 'broadcasts.content.comment.fields.title.label',
    description: 'Label for title field in comment card editor.',
    defaultMessage: 'Title',
  });

  const whenTitleChanged = useInputChangeEventHandler(useCallback((title: string) => (
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          title,
        },
      },
    })
  ), [editorContent, onEditorContentChanged]));

  const whenMandatoryChanged = useCheckboxChangeEventHandler(useCallback(checked => (
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        mandatory: checked,
      },
    })
  ), [editorContent, onEditorContentChanged]));

  const whenTitleBlurred = useCallback(() => (
    onValidationChanged({
      errors: validation?.errors || [],
      children: {
        content: {
          errors: validation?.children.content?.errors || [],
          children: {
            ...validation?.children.content?.children,
            title: CommentRequestValidator.validateTitle(editorContent.card.content.title),
          },
        },
      },
    })
  ), [validation, onValidationChanged, editorContent]);

  return (
    <VerticallySpaced gap={ 2 }>
      <VerticallySpaced gap={ 1 }>
        <h4>{ titleFieldLabel }</h4>
        <NoMarginTextField
          fullWidth
          value={ editorContent.card.content.title }
          onChange={ whenTitleChanged }
          onBlur={ whenTitleBlurred }
        />
        <CharacterCount
          current={ editorContent.card.content.title.length || 0 }
          minimum={ COMMENT_TITLE_MIN_LENGTH }
          maximum={ COMMENT_TITLE_MAX_LENGTH }
        />
        <FieldValidationErrors
          fieldName={ titleFieldLabel }
          validationErrors={ validation?.children.content?.children.title?.errors || [] }
        />
      </VerticallySpaced>
      <VerticallySpaced gap={ 1 }>
        <h4>
          <FormattedMessage
            id="broadcasts.content.comment.fields.mandatory.label"
            description="Label for comment mandatory checkbox in broadcast editor."
            defaultMessage="Mandatory"
          />
        </h4>
        <Switch
          checked={ editorContent.card.mandatory }
          onChange={ whenMandatoryChanged }
        />
      </VerticallySpaced>
    </VerticallySpaced>
  );
}
