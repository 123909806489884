import { PropsWithChildren, ReactNode, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { LoadingCard } from '@ourpeople/shared/Core/Component/Feedback';

import { SimpleTable } from '../../../Components';
import { NoResultsError } from '../../../Common/Component';
import { RequestState } from '../../../Models';
import { LoadingContainer } from '../LoadingContainer';
import { NoResultsContainer } from '../NoResultsContainer';

type Props<RowDataType> = {
  rows: RowDataType[];
  rowRender: (rowData: RowDataType) => ReactNode;
  headerRow: ReactNode;
  requestState: RequestState;
  onRetryClicked: () => void;
  noResultsMessage?: ReactNode;
}
export const AsyncTable = <RowDataType,>({
  rows,
  rowRender,
  headerRow,
  requestState,
  onRetryClicked,
  noResultsMessage,
}: PropsWithChildren<Props<RowDataType>>): JSX.Element => {
  const successfulTable = useMemo(() => {
    return rows.length === 0
      ? (
        <NoResultsContainer>
          {
            noResultsMessage || (
              <span>
                <FormattedMessage
                  id="paginatedTable.noResults"
                  defaultMessage="There are no results"
                />
              </span>
            )
          }
        </NoResultsContainer>
      )
      : (
        <>
          <SimpleTable
            rows={rows}
            rowRender={rowRender}
            headerRow={headerRow}
          />
        </>
      )
  }, [headerRow, noResultsMessage, rowRender, rows]);

  const loadedTable = useMemo(() => (
    requestState === RequestState.FAILED
      ? (
        <NoResultsError onRetry={ onRetryClicked }>
          <FormattedMessage
            id="paginatedTable.error"
            defaultMessage="Failed to load results"
          />
        </NoResultsError>
      )
      : successfulTable
  ), [onRetryClicked, requestState, successfulTable]);

  return (
    <>
      {
        requestState < RequestState.COMPLETE
          ? (
            <LoadingContainer>
              <LoadingCard/>
            </LoadingContainer>
          )
          : loadedTable
      }
    </>
  );
};
