import { default as React, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { DraftSingleContentCard, EditorContent } from '../../../../Content/Model';
import { ValidationTree } from '../../../../Common/Model';
import {
  DraftMeeting,
  MEETING_LOCATION_MAX_LENGTH,
  MEETING_LOCATION_MIN_LENGTH,
  MEETING_TEXT_MAX_LENGTH,
  MEETING_TEXT_MIN_LENGTH,
  MeetingValidator
} from '../../../Service';
import { SharedEventFields } from '..';
import { FieldValidationErrors, NoMarginTextField, NumberInput, VerticallySpaced } from '../../../../Common/Component';
import { useInputChangeEventHandler } from '../../../../Common/Hook';
import { CharacterCount } from '../../../../Content/Component';

type Props<D extends DraftMeeting<string>, E extends EditorContent<D>> = {
  editorContent: E;
  onEditorContentChanged: (editorContent: E) => void;
  validation?: ValidationTree<DraftSingleContentCard<D>>;
  onValidationChanged: (validation: ValidationTree<DraftSingleContentCard<D>>) => void;
};

export const SharedMeetingAndTrainingFields = <D extends DraftMeeting<string>, E extends EditorContent<D>>({
  editorContent,
  onEditorContentChanged,
  validation,
  onValidationChanged,
}: Props<D, E>): JSX.Element => {
  const intl = useIntl();
  const maxAttendeesFieldLabel = intl.formatMessage({
    id: 'broadcasts.content.meetingAndTraining.fields.maxAttendees.label',
    description: 'Label for maxAttendees field of broadcasts meeting and training editors.',
    defaultMessage: 'Spaces',
  });
  const locationFieldLabel = intl.formatMessage({
    id: 'broadcasts.content.meetingAndTraining.fields.location.label',
    description: 'Label for location field of broadcasts meeting and training editors.',
    defaultMessage: 'Location',
  });

  const whenLocationChanged = useInputChangeEventHandler(useCallback((location: string) => (
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          location: location,
        },
      },
    })
  ), [editorContent, onEditorContentChanged]));

  const whenLocationBlurred = useCallback(() => (
    onValidationChanged({
      errors: [],
      children: {
        content: {
          errors: [],
          children: {
            ...validation?.children.content?.children,
            location: MeetingValidator.validateLocation(editorContent.card.content.location),
          } as ValidationTree<D>['children'],
        },
      },
    })
  ), [editorContent.card.content.location, onValidationChanged, validation?.children.content?.children]);

  const whenMaxAttendeesChanged = useInputChangeEventHandler(useCallback(maxAttendees => (
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          max_attendees: maxAttendees,
        },
      },
    })
  ), [editorContent, onEditorContentChanged]));

  const whenMaxAttendeesBlurred = useCallback(() => (
    onValidationChanged({
      errors: [],
      children: {
        content: {
          errors: [],
          children: {
            ...validation?.children.content?.children,
            max_attendees: MeetingValidator.validateMaxAttendees(editorContent.card.content.max_attendees),
          } as ValidationTree<D>['children'],
        },
      },
    })
  ), [editorContent.card.content.max_attendees, onValidationChanged, validation?.children.content?.children]);

  return (
    <>
      <SharedEventFields
        editorContent={ editorContent }
        onEditorContentChanged={ onEditorContentChanged }
        validation={ validation }
        onValidationChanged={ onValidationChanged }
        minTitleLength={ MEETING_TEXT_MIN_LENGTH }
        maxTitleLength={ MEETING_TEXT_MAX_LENGTH }
      />
      <VerticallySpaced gap={ 1 }>
        <h4>{ locationFieldLabel }</h4>
        <NoMarginTextField
          value={ editorContent.card.content.location }
          onChange={ whenLocationChanged }
          onBlur={ whenLocationBlurred }
        />
        <CharacterCount
          current={ editorContent.card.content.location.length || 0 }
          minimum={ MEETING_LOCATION_MIN_LENGTH }
          maximum={ MEETING_LOCATION_MAX_LENGTH }
        />
        <FieldValidationErrors
          fieldName={ locationFieldLabel }
          validationErrors={ validation?.children.content?.children.location?.errors || [] }
        />
      </VerticallySpaced>
      <VerticallySpaced gap={ 1 }>
        <h4>{ maxAttendeesFieldLabel }</h4>
        <NumberInput
          fullWidth
          value={ editorContent.card.content.max_attendees }
          onChange={ whenMaxAttendeesChanged }
          multiline
          onBlur={ whenMaxAttendeesBlurred }
        />
        <FieldValidationErrors
          fieldName={ maxAttendeesFieldLabel }
          validationErrors={ validation?.children.content?.children.max_attendees?.errors || [] }
        />
      </VerticallySpaced>
    </>
  )
}
