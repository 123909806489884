import {FC, PropsWithChildren} from 'react';
import { TooltipProps } from '@mui/material';

import { DeprecatedTooltip } from '../Tooltip/DeprecatedTooltip';

type Props = TooltipProps & {
  active: boolean;
};

export const ConditionalTooltip: FC<PropsWithChildren<Props>> = ({
  active,
  children,
  ...tooltipProps
}) => (
  active
    ? (
      <DeprecatedTooltip { ...tooltipProps }>
        <div>{ children }</div>
      </DeprecatedTooltip>
    )
    : children
);
