import { CSSProperties, FC, forwardRef, PropsWithChildren, ReactNode } from 'react';

import { StyledStack } from './style';
import { defaultTheme } from '../../const/defaultTheme';

type Props = {
  direction?: CSSProperties['flexDirection'];
  align?: CSSProperties['alignItems'];
  justify?: CSSProperties['justifyContent'];
  gap?: keyof typeof defaultTheme['new']['spacing'] | 0;
  noWrap?: boolean;
  flexWrap?: CSSProperties['flexWrap'];
  fillParent?: boolean;
  className?: string;
  inline?: boolean;
  children?: ReactNode;
};

export const Stack = forwardRef<unknown, Props>((props, ref) => {
  const {
    direction = 'row',
    justify = 'flex-start',
    flexWrap = 'nowrap',
    gap = 2,
    align = (props.direction || 'row') === 'row' ? 'center' : 'stretch',
    noWrap = false,
    fillParent = false,
    inline = false,
    ...unmodifiedProps
  } = props;

  return (
    <StyledStack
      ref={ ref }
      inline={ inline }
      fillParent={ fillParent }
      direction={ direction }
      justify={ justify }
      gap={ gap }
      align={ align }
      noWrap={ noWrap }
      flexWrap={ flexWrap }
      { ...unmodifiedProps }
    />
  );
});
