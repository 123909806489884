import { FC, useCallback } from 'react';

import { SignRequest } from '../../Content/SignRequest/SignRequest';
import { LiveComponentProps } from '../../../../Content/Model';
import { DraftSignResponse } from '../../../Service/ContentDefinitions/SignRequestDefinition';
import { SignRequest as SignRequestModel } from '../../../Model/ContentTypes/SignRequest';
import { useContextOrThrow, useLoggedInAuthDescription } from '../../../../Core/Hook';
import { FormNavigationContext } from '../../../Provider/FormNavigationProvider';
import { TextFormatter } from '../../../../Utility';

export const LiveSignRequest: FC<LiveComponentProps<SignRequestModel, DraftSignResponse>> = ({
  card,
  response,
  onResponseChange,
  onValidationChange,
  validation,
}) => {
  const { user } = useLoggedInAuthDescription();
  const userName = TextFormatter.spaceSeparated(user.forename, user.lastname || '');
  const { displayingValidation } = useContextOrThrow(FormNavigationContext);
  const whenSignClicked = useCallback(() => {
    const response: DraftSignResponse = {
      contentId: card.content.id,
      contentType: 'signatureRequest',
      signature: userName.slice(0, card.content.maxSignature || undefined),
    };

    onResponseChange(response);
    onValidationChange({
      errors: [],
      children: {},
    });
  }, [card.content.id, card.content.maxSignature, onResponseChange, onValidationChange, userName]);

  return (
    <SignRequest
      signed={
        response
          ? {
            at: new Date(),
            by: userName,
          }
          : null
      }
      onInputClick={ whenSignClicked }
      validation={ validation }
      displayValidation={ displayingValidation }
    />
  );
};
