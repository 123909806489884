import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useDebounce } from '@ourpeople/shared/Core/Hook/useDebounce';

import { Audience } from '../../Model';
import {
  FetchAudienceMembersParams,
  FetchAudienceMembersQuery,
  FetchAudienceMembersSort,
  useFetchAudienceMembers
} from '../../Hook';
import { AudienceCleaner } from '../../Utility';
import { AudienceMemberList } from '../AudienceMemberList/AudienceMemberList';

interface Props {
  id?: string;
  audience: Audience;
  context: string;
  showStatus?: boolean;
  onSizeCalculated?: (audienceSize: number) => void;
  applyReach: boolean;
}

export const HotAudienceMemberList: FunctionComponent<Props> = ({
  id,
  audience,
  context,
  showStatus = true,
  onSizeCalculated,
  applyReach,
}) => {
  const [search, setSearch] = useState<string>('');
  const [query, setQuery] = useState<FetchAudienceMembersQuery>({
    pageNum: 1,
    search: null,
    sort: ['first_name_asc'],
  });
  const fetchAudienceMembersParams = useMemo<FetchAudienceMembersParams>(
    () => ({
      audience: AudienceCleaner.removeInvalidConditions(audience),
      context,
      query,
      applyReach,
    }),
    [audience, context, query, applyReach],
  );
  const debouncedFetchAudienceMembersParams = useDebounce(fetchAudienceMembersParams, 1000);
  const [
    fetchAudienceMembersResult,
    fetchAudienceMembersRequestState,
    refreshFetchAudienceMembers,
  ] = useFetchAudienceMembers(debouncedFetchAudienceMembersParams);

  useEffect(() => {
    if (fetchAudienceMembersResult?.content?.audienceSize === undefined || !onSizeCalculated) {
      return;
    }

    onSizeCalculated(fetchAudienceMembersResult.content.audienceSize);
  }, [fetchAudienceMembersResult?.content?.audienceSize, onSizeCalculated]);

  useEffect(() => {
    const searchHasChanged = (search || null) !== query.search;
    if (searchHasChanged) {
      setQuery(query => ({
        ...query,
        search: search || null,
        pageNum: 1,
      }));
    }
  }, [query.search, search]);

  const whenSortChanged = (sort: FetchAudienceMembersSort): void => {
    setQuery(query => ({
      ...query,
      sort: [sort],
    }));
  };

  const whenPageNumChanged = (pageNum: number): void => {
    setQuery(query => ({
      ...query,
      pageNum,
    }));
  };

  return (
    <AudienceMemberList
      id={ id }
      query={ query }
      search={ search }
      audienceMembersResult={ fetchAudienceMembersResult?.content }
      onSortChanged={ whenSortChanged }
      onSearchChanged={ setSearch }
      onPageChanged={ whenPageNumChanged }
      onRetryClicked={ refreshFetchAudienceMembers }
      requestState={ fetchAudienceMembersRequestState }
      showStatus={ showStatus }
    />
  );
};
