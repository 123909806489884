import { FC, useMemo, useState } from 'react';
import { LoadingCard } from '@ourpeople/shared/Core/Component/Feedback';

import { FormContentPreview } from '../../../../Forms/Component';
import { AspectRatioPreview } from '../../../../Content/Component';
import { DraftSingleContentForm } from '../../../Model';
import { EditorContent } from '../../../../Content/Model';
import { UniqueIdGenerator } from '../../../../Common/Utility';

type Props = {
  form: DraftSingleContentForm;
};

export const FormTemplatePreviewPane: FC<Props> = ({ form }) => {
  const [activeContentIndex, setActiveContentIndex] = useState<number>(0);
  const editorContents = useMemo<EditorContent[]>(
    () => form.cards.map(card => ({ id: UniqueIdGenerator.generate(), card })),
    [form.cards],
  );

  const activeContent = useMemo(() => {
    if (!editorContents[activeContentIndex]) {
      throw new Error('Content at index does not exist in template.');
    }

    return editorContents[activeContentIndex];
  }, [activeContentIndex, editorContents]);

  return (
    <AspectRatioPreview>
      {
        activeContent
          ? (
            <FormContentPreview
              content={ activeContent }
              onPreviousClicked={ () => setActiveContentIndex(activeContentIndex => Math.max(0, activeContentIndex - 1)) }
              onNextClicked={ () => setActiveContentIndex(activeContentIndex => Math.min(editorContents.length - 1, activeContentIndex + 1)) }
              contentCount={ editorContents.length }
              currentContentIndex={ activeContentIndex }
              name={ form.name || '' }
              previousButtonDisabled={ activeContentIndex === 0 }
              nextButtonDisabled={ activeContentIndex >= editorContents.length - 1 }
            />
          )
          : <><LoadingCard/></>
      }
    </AspectRatioPreview>
  );
};
