import { StrictMode } from 'react';
import * as Sentry from '@sentry/react';
import { ArcElement, BarElement, CategoryScale, Chart, LinearScale, LineElement, PointElement } from 'chart.js';
import { createRoot } from 'react-dom/client';

import { App } from './Components/App/App';
import './fonts.css';
import './svg.css';
import './sentry-feedback.css'

const SENTRY_DSN = process.env.OP_SENTRY_DSN;

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    release: (process.env.OP_VERSION || '').replace(/^v/, ''),
  });
  Sentry.setTag('platfom', 'react');
}

const mountApp = (): void => {
  Chart.register([
    ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
  ]);

  const rootElement = document.getElementById('root');

  if (!rootElement) {
    throw new Error('Could not locate root component.');
  }

  const rootComponent = createRoot(rootElement);

  rootComponent.render(
    <StrictMode>
      <App />
    </StrictMode>
  );
}

mountApp();
