import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AuthFullscreenLayout } from 'op-storybook/lib/components/AuthFullscreenLayout/AuthFullscreenLayout';
import { AuthHeader } from 'op-storybook/lib/components/AuthHeader/AuthHeader';
import QrIcon from 'op-storybook/lib/assets/icon/figma/qr-code-01.svg';
import { ModalActions } from 'op-storybook/lib/components/ModalActions/ModalActions';
import { Button } from 'op-storybook/stories/components/Button/Button';
import { Link } from 'react-router-dom';

export const DeactivatedCodePage: FC = () => {
  const intl = useIntl();

  return (
    <AuthFullscreenLayout>
      <AuthHeader
        graphic={ {
          type: 'icon',
          props: {
            IconComponent: QrIcon,
            size: 'lg',
          },
        } }
        header={ intl.formatMessage({
          description: 'Header for email verification success page.',
          defaultMessage: 'This QR code has been deactivated',
        }) }
        supportingText={ intl.formatMessage({
          description: 'Supporting text for email verification success page.',
          defaultMessage: 'You may not be assigned to the correct team after registering. Get in touch with your manager to let them know there\'s an issue with this code.',
        }) }
      />
      <div css={ { display: 'flex', flexDirection: 'column', alignItems: 'center' } }>
        <ModalActions>
          <Button
            variant="primary"
            component={ Link }
            to="/register"
          >
            <FormattedMessage
              description="Label for button that links to register page from deactivated registration code page."
              defaultMessage="Register anyway"
            />
          </Button>
        </ModalActions>
      </div>
    </AuthFullscreenLayout>
  );
};
