import { FC, ReactNode } from 'react';
import { parseISO } from 'date-fns';

import { VideoContentWithoutFooter } from '..';
import { ScrollableContent } from '../../../../../Broadcasts/Component/Content';
import { ContentPadding, NudgeBanner } from '../..';
import { BroadcastContentFooter } from '../../Footer/BroadcastContentFooter';
import { MediaPresentationComponentProps } from '../../../../Model';
import { AbsoluteBackButtonContainer } from '../../AbsoluteBackButtonContainer/AbsoluteBackButtonContainer';

type Props = Omit<MediaPresentationComponentProps, 'onOpenFileClicked' | 'openFileBusy'> & {
  description?: string;
  thumbnail: ReactNode;
  backButton: ReactNode | undefined;
};

export const VideoBroadcastContent: FC<Props> = ({
  text,
  description,
  thumbnail,
  navigationProps,
  nudge,
  backButton,
}) => (
  <>
    { backButton && <AbsoluteBackButtonContainer>{ backButton } </AbsoluteBackButtonContainer> }
    <ScrollableContent>
      {
        nudge && (
          <NudgeBanner
            message={ nudge.message }
            nudgedAt={ parseISO(nudge.at) }
          />
        )
      }
      <ContentPadding>
        <VideoContentWithoutFooter
          title={ text }
          description={ description }
          thumbnail={ thumbnail }
        />
      </ContentPadding>
    </ScrollableContent>
    <BroadcastContentFooter { ...navigationProps }/>
  </>
)
