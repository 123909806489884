import styled from '@emotion/styled';

export const CenteredContents = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${ props => props.theme.spacing(2) };
  align-items: center;
  justify-content: center;
`;
