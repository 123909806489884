import { Dispatch, FunctionComponent, SetStateAction, useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, ButtonProps } from '@mui/material';
import { AutocompleteSelection, AutocompleteSelectionChanged } from '@ourpeople/shared/Core/Component/Input/Autocomplete/Autocomplete';

import { TagAutocomplete, TeamAutocomplete } from '../../../Tags/Component';
import { Audience, Condition, TagCondition } from '../../Model';
import { useAudienceDescription, useAudienceTagIds } from '../../Hook';
import { AudienceCleaner, AudienceComparer, AudienceCreator, AudienceManipulator } from '../../Utility';
import { Tag, TagType } from '../../../Tags/Model';
import { AudienceModal } from '../../../Audiences/Component';
import { PropsWithKey } from '../../../Common/Model';
import { StyledAutocompleteContainer, StyledTagType } from './style';
import { Chip, Flex, TableFilters } from '../../../Common/Component';
import { TagParser } from '../../../Tags/Utility';
import FilterIcon from '../../../Assets/img/icons/streamline/filter-text.svg';

type Props = {
  audience: Audience;
  setAudience: Dispatch<SetStateAction<Audience>>;
  searchValue: string;
  onSearchChanged: (searchValue: string) => void;
  selectedRows?: number;
  currentPageRows?: number;
  totalRows: number | null;
  actions?: PropsWithKey<ButtonProps>[];
  onClearSelectionClicked?: () => void;
  onExpandSelectionClicked?: () => void;
};

export const AudienceFilters: FunctionComponent<Props> = ({
  audience,
  setAudience,
  searchValue,
  onSearchChanged,
  selectedRows = 0,
  currentPageRows = 0,
  totalRows,
  actions = [],
  onClearSelectionClicked,
  onExpandSelectionClicked,
}) => {
  const intl = useIntl();
  const {
    teamIds,
    jobTitleIds,
    skillIds,
  } = useAudienceTagIds(audience);
  const [advancedMode, setAdvancedMode] = useState<boolean>(false);
  const [advancedFiltersOpen, setAdvancedFiltersOpen] = useState<boolean>(false);
  const [chips, setChips] = useState<Chip[]>([]);
  const audienceDescription = useAudienceDescription(audience);
  const audienceChip = useMemo<Chip[]>(() => [
    {
      id: 'audienceChip',
      type: 'audience',
      label: intl.formatMessage({
        id: 'audiences.filters.audienceChip',
        description: 'Audience chip label.',
        defaultMessage: 'Audience: { audienceDescription }',
      }, {
        audienceDescription,
      }),
      data: null,
    },
  ], [audienceDescription, intl]);

  const addTagConditionFromAutocompleteSelection = useCallback(
    <T extends 'team' | 'jobtitle' | 'skill'>(selection: AutocompleteSelection<Tag<T>>, tagType: T) => {
      if (advancedMode) {
        return;
      }

      const newCondition: TagCondition = {
        type: 'tag',
        tagType,
        tagIds: selection.options.map(option => option.id).concat(selection.unknownIds),
      };

      setAudience(audience => {
        const newAudience = AudienceCleaner.cleanAudience({
          segments: [
            {
              conditions: [
                ...audience.segments[0].conditions.filter(
                  condition => !conditionIsTagCondition(condition) || condition.tagType !== tagType,
                ),
                newCondition,
              ],
            }
          ],
        });

        if (AudienceComparer.cleanedAudiencesAreEqual(audience, newAudience)) {
          return audience;
        }

        return AudienceCleaner.cleanAudience(newAudience);
      });

      const newChips: Chip[] = selection.options.map(option => ({
        id: option.id,
        type: option.type,
        label: intl.formatMessage({
          id: 'audiences.tagChip',
          description: 'Chip representing tag quick filter.',
          defaultMessage: '{ tagType }: { tagName}',
        }, {
          tagType: <StyledTagType>{ TagParser.localisedTagTypeFromInternalTagType(tagType, intl, 1) }</StyledTagType>,
          tagName: option.name,
        }) as string,
      }));

      setChips(chips => [
        ...chips.filter(
          chip => chip.type !== tagType || !!newChips.find(newChip => newChip.id === chip.id),
        ),
        ...newChips.filter(newChip => !chips.find(chip => chip.id === newChip.id)),
      ]);
    },
    [advancedMode, intl, setAudience],
  );

  const whenTeamSelectionChanged: AutocompleteSelectionChanged<Tag<'team'>> = useCallback(selection => {
    addTagConditionFromAutocompleteSelection(selection, 'team');
  }, [addTagConditionFromAutocompleteSelection]);

  const whenJobTitleSelectionChanged: AutocompleteSelectionChanged<Tag<'jobtitle'>> = selection => {
    addTagConditionFromAutocompleteSelection(selection, 'jobtitle');
  };

  const whenSkillSelectionChanged: AutocompleteSelectionChanged<Tag<'skill'>> = selection => {
    addTagConditionFromAutocompleteSelection(selection, 'skill');
  };

  const whenAudienceSaved = (audience: Audience) => {
    setAudience(audience);
    setAdvancedMode(true);
  };

  const whenClearFiltersClicked = useCallback(() => {
    setAudience(initialAudience);
    setChips([]);
    setAdvancedMode(false);
  }, [setAudience]);

  const removeAudienceTag = useCallback((tagId: string, tagType: TagType) => (
    setAudience(audience => {
      const newAudience = AudienceCleaner.cleanAudience(
        AudienceManipulator.removeTagId(audience, tagType, tagId)
      );

      return AudienceComparer.cleanedAudiencesAreEqual(audience, newAudience)
        ? audience
        : newAudience;
    })
  ), [setAudience]);

  const whenRemoveChipClicked = useCallback((chipToRemove: Chip) => {
    switch (chipToRemove.type) {
      case 'audience':
        return whenClearFiltersClicked();
      case 'team':
      case 'jobtitle':
      case 'skill':
        removeAudienceTag(chipToRemove.id, chipToRemove.type);
        break;
      default:
        return;
    }

    setChips(chips => [
      ...chips.filter(chip => chip.type !== chipToRemove.type || chip.id !== chipToRemove.id),
    ]);
  }, [removeAudienceTag, whenClearFiltersClicked]);

  return (
    <>
      <TableFilters
        onClearFiltersClicked={ whenClearFiltersClicked }
        chips={ advancedMode ? audienceChip : chips }
        onRemoveChipClicked={ whenRemoveChipClicked }
        searchValue={ searchValue }
        onSearchChanged={ onSearchChanged }
        selectedRows={ selectedRows }
        currentPageRows={ currentPageRows }
        actions={ actions }
        totalRows={ totalRows }
        onClearSelectionClicked={ onClearSelectionClicked }
        onExpandSelectionClicked={ onExpandSelectionClicked }
      >
        <StyledAutocompleteContainer>
          <TeamAutocomplete
            teamIds={ teamIds }
            onSelectionChanged={ whenTeamSelectionChanged }
            disabled={ advancedMode }
            showChips={ false }
            placeholder={ intl.formatMessage({
              id: 'audiences.teamAutocomplete.placeholder',
              description: 'Placeholder for team autocomplete in audience filters.',
              defaultMessage: 'Search…',
            }) }
            label={ intl.formatMessage({
              id: 'audiences.teamAutocomplete.label',
              description: 'Label for team autocomplete in audience filters.',
              defaultMessage: 'Teams',
            }) }
            dense
            multiple
          />
        </StyledAutocompleteContainer>
        <StyledAutocompleteContainer>
          <TagAutocomplete
            type="jobtitle"
            selectedIds={ jobTitleIds }
            onSelectionChanged={ whenJobTitleSelectionChanged }
            disabled={ advancedMode }
            showChips={ false }
            placeholder={ intl.formatMessage({
              id: 'audiences.jobTitleAutocomplete.placeholder',
              description: 'Placeholder for job title autocomplete in audience filters.',
              defaultMessage: 'Search…',
            }) }
            label={ intl.formatMessage({
              id: 'audiences.jobTitleAutocomplete.label',
              description: 'Label for job title autocomplete in audience filters.',
              defaultMessage: 'Job titles',
            }) }
            dense
            multiple
          />
        </StyledAutocompleteContainer>
        <StyledAutocompleteContainer>
          <TagAutocomplete
            type="skill"
            selectedIds={ skillIds }
            onSelectionChanged={ whenSkillSelectionChanged }
            disabled={ advancedMode }
            showChips={ false }
            placeholder={ intl.formatMessage({
              id: 'audiences.skillAutocomplete.placeholder',
              description: 'Placeholder for skill autocomplete in audience filters.',
              defaultMessage: 'Search…',
            }) }
            label={ intl.formatMessage({
              id: 'audiences.skillAutocomplete.label',
              description: 'Label for skill autocomplete in audience filters.',
              defaultMessage: 'Skills',
            }) }
            dense
            multiple
          />
        </StyledAutocompleteContainer>
        <Button
          color="primary"
          onClick={ () => setAdvancedFiltersOpen(true) }
        >
          <Flex gap={ 1 }>
            <FilterIcon
              width="24px"
              height="24px"
            />
            <span>
              <FormattedMessage
                id="audiences.filter.advanced"
                description="Label for advanced audience filters button."
                defaultMessage="Advanced filters"
              />
            </span>
          </Flex>
        </Button>
      </TableFilters>
      <AudienceModal
        open={ advancedFiltersOpen }
        audience={ audience }
        onAudienceSaved={ whenAudienceSaved }
        onDismissed={ () => setAdvancedFiltersOpen(false) }
      />
    </>
  );
};

const conditionIsTagCondition = (condition: Condition): condition is TagCondition => condition.type === 'tag';
const initialAudience = AudienceCreator.createBlankAudience();
