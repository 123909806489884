import React, { FC, FormEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AxiosError } from 'axios';
import { Heading } from '@ourpeople/shared/Core/Component/Content';
import { AutocompleteSelectionChanged } from '@ourpeople/shared/Core/Component/Input/Autocomplete/Autocomplete';
import { Button } from 'op-storybook/stories/components/Button/Button';

import {
  Flex,
  FlexPullRight,
  IconButton,
  ValidationErrorMessage,
  VerticallySpaced
} from '../../../../Common/Component';
import { FetchBroadcastCategoriesParams, useFetchBroadcastCategories } from '../../../Hook';
import { CategoryAutocomplete } from '../CategoriesAutocomplete/CategoryAutocomplete';
import { MinimalBroadcastCategory } from '../../../Model';
import { StyledAutocomplete, StyledBox } from './style';
import { useApi, useContextOrThrow } from '../../../../Core/Hook';
import { ErrorResponseReader } from '../../../../Common/Utility';
import { ValidationError, ValidationTree } from '../../../../Common/Model';
import DownArrowIcon from '../../../../Assets/img/icons/streamline/arrow-down-1.svg';
import UpArrowIcon from '../../../../Assets/img/icons/streamline/arrow-up-1.svg';
import { ValidationErrorIdentifier } from '../../../../Common/Utility/ValidationErrorIdentifier';
import { useMounted } from '../../../../Common/Hook';
import { ToastContext } from '../../../../Core/Context';

type UpdateDefaultsData = {
  eventBroadcastsCategoryId: string;
  contentBroadcastsCategoryId: string;
};

type Props = {
  expanded: boolean;
  onSave: () => void;
  onToggle: () => void;
};

export const DefaultCategoriesForm: FC<Props> = ({
  expanded,
  onSave,
  onToggle,
}) => {
  const api = useApi();
  const intl = useIntl();
  const mounted = useMounted();
  const fetchCategoriesParams = useMemo<FetchBroadcastCategoriesParams>(() => ({
    sort: 'name_asc',
    pageNum: 1,
    noNotDefault: 1,
  }), []);
  const [fetchCategoriesResult] = useFetchBroadcastCategories(fetchCategoriesParams, !expanded);
  const [defaultEventCategory, setDefaultEventCategory] = useState<MinimalBroadcastCategory>();
  const [defaultNonEventCategory, setDefaultNonEventCategory] = useState<MinimalBroadcastCategory>();
  const [saving, setSaving] = useState<boolean>(false);
  const { addSuccessToast, addErrorToast } = useContextOrThrow(ToastContext);
  const [validation, setValidation] = useState<ValidationTree<UpdateDefaultsData>>({
    errors: [],
    children: {},
  });
  const selectedDefaultEventCategoryIds = useMemo(
    () => defaultEventCategory?.id ? [defaultEventCategory.id] : [],
    [defaultEventCategory?.id],
  );
  const selectedDefaultNonEventCategoryIds = useMemo(
    () => defaultNonEventCategory?.id ? [defaultNonEventCategory.id] : [],
    [defaultNonEventCategory?.id],
  );

  useEffect(() => {
    if (!fetchCategoriesResult?.content) {
      return;
    }

    const defaultCategories = fetchCategoriesResult.content.categories;
    const defaultEventCategory = defaultCategories.find(category => category.eventBroadcastDefault);
    const defaultNonEventCategory = defaultCategories.find(category => category.contentBroadcastDefault);

    defaultEventCategory && setDefaultEventCategory(defaultEventCategory);
    defaultNonEventCategory && setDefaultNonEventCategory(defaultNonEventCategory);
  }, [fetchCategoriesResult?.content]);

  const whenDefaultEventCategorySelected: AutocompleteSelectionChanged<MinimalBroadcastCategory> = useCallback(
    selection => {
      if (!selection.options.length) {
        return;
      }

      setValidation(validation => ({
        errors: [],
        children: {
          ...validation.children,
          eventBroadcastsCategoryId: {
            errors: [],
            children: {},
          },
        },
      }));
      setDefaultEventCategory(selection.options[0]);
    },
    [],
  );

  const whenDefaultNonEventCategorySelected: AutocompleteSelectionChanged<MinimalBroadcastCategory> = useCallback(
    selection => {
      if (!selection.options.length) {
        return;
      }

      setValidation(validation => ({
        errors: [],
        children: {
          ...validation.children,
          contentBroadcastsCategoryId: {
            errors: [],
            children: {},
          },
        },
      }));
      setDefaultNonEventCategory(selection.options[0]);
    },
    [],
  );

  const whenSubmitted: FormEventHandler = useCallback(event => {
    event.preventDefault();

    if (!defaultEventCategory || !defaultNonEventCategory) {
      return;
    }

    setSaving(true);
    api.post(
      '/broadcasts/categories/defaults',
      {
        eventBroadcastsCategoryId: defaultEventCategory?.id,
        contentBroadcastsCategoryId: defaultNonEventCategory?.id,
      },
    )
      .then(() => {
        if (!mounted.current) {
          return;
        }

        setSaving(false);
        setValidation({
          errors: [],
          children: {},
        });
        onSave();
        addSuccessToast(
          intl.formatMessage({
            id: 'defaultCategoriesForm.save.success',
            description: 'Message when default categories are updated successfully.',
            defaultMessage: 'Default categories updated successfully',
          })
        );
      })
      .catch((error: AxiosError) => {
        if (!mounted.current) {
          return;
        }

        setSaving(false);
        error.response
        && ErrorResponseReader.isApiError(error)
        && ErrorResponseReader.isValidationErrorResponse<UpdateDefaultsData>(error.response)
        && setValidation(error.response.data.error.data.root);
        addErrorToast(
          intl.formatMessage({
            id: 'defaultCategoriesForm.save.error',
            description: 'Message when default categories are not updated successfully.',
            defaultMessage: 'Default categories could not be updated',
          })
        );
      });
  }, [defaultEventCategory, defaultNonEventCategory, api, mounted, onSave, addSuccessToast, intl, addErrorToast]);

  return (
    <StyledBox>
      <form onSubmit={ whenSubmitted }>
        <VerticallySpaced gap={ 4 }>
          <VerticallySpaced gap={ 2 }>
            <Flex noWrap>
              <Heading type="h2">
                <FormattedMessage
                  id="broadcastCategories.manageDefault.heading"
                  description="Heading for manage defaults section on broadcast categories page."
                  defaultMessage="Manage default categories"
                />
              </Heading>
              <FlexPullRight>
                <IconButton
                  color="primary.main"
                  IconComponent={ expanded ? UpArrowIcon : DownArrowIcon }
                  label={ intl.formatMessage({
                    id: 'broadcastCategories.manageDefaults.toggle',
                    description: 'Label for button that toggles default categories form.',
                    defaultMessage: 'Toggle',
                  }) }
                  onClick={ onToggle }
                  size="small"
                />
              </FlexPullRight>
            </Flex>
            { expanded && (
              <span>
                <FormattedMessage
                  id="broadcastCategories.manageDefault.body"
                  description="Body for manage defaults section on broadcast categories page."
                  defaultMessage="
                    Selecting a category is mandatory when creating a broadcast. Default categories auto populate the field
                    reducing the number of steps when creating a broadcast. Admins will have the option to edit the category
                    before and after sending a broadcast.
                  "
                />
              </span>
            ) }
          </VerticallySpaced>
          { expanded && (
            <>
              <VerticallySpaced gap={ 2 }>
                <VerticallySpaced gap={ 1 }>
                  <Flex gap={ 2 } noWrap>
                    <FormattedMessage
                      id="listCategoriesPage.eventDefault.label"
                      description="Label for default event category input"
                      defaultMessage="{ input } is the default category for any broadcast with at least one event card"
                      values={ {
                        input: (
                          <StyledAutocomplete>
                            <CategoryAutocomplete
                              fullWidth
                              disableClearable
                              selectedIds={ selectedDefaultEventCategoryIds }
                              onSelectionChanged={ whenDefaultEventCategorySelected }
                            />
                          </StyledAutocomplete>
                        ),
                      } }
                    />
                  </Flex>
                  <ValidationErrors errors={ validation.children.eventBroadcastsCategoryId?.errors || [] }/>
                </VerticallySpaced>
                <VerticallySpaced gap={ 1 }>
                  <Flex gap={ 2 } noWrap>
                    <FormattedMessage
                      id="listCategoriesPage.nonEventDefault.label"
                      description="Label for default non-event category input"
                      defaultMessage="{ input } is the default category for any broadcast with no event cards"
                      values={ {
                        input: (
                          <StyledAutocomplete>
                            <CategoryAutocomplete
                              fullWidth
                              disableClearable
                              selectedIds={ selectedDefaultNonEventCategoryIds }
                              onSelectionChanged={ whenDefaultNonEventCategorySelected }
                            />
                          </StyledAutocomplete>
                        ),
                      } }
                    />
                  </Flex>
                  <ValidationErrors errors={ validation.children.contentBroadcastsCategoryId?.errors || [] }/>
                </VerticallySpaced>
              </VerticallySpaced>
              <Flex>
                <FlexPullRight>
                  <Button
                    variant="primary"
                    type="submit"
                    busy={ saving }
                    disabled={ !defaultEventCategory || !defaultNonEventCategory }
                  >
                    <FormattedMessage
                      id="defaultCategoriesForm.save"
                      description="Label for save button in default broadcast categories form."
                      defaultMessage="Save"
                    />
                  </Button>
                </FlexPullRight>
              </Flex>
            </>
          ) }
        </VerticallySpaced>
      </form>
    </StyledBox>
  );
};

const ValidationErrors: FC<{ errors: ValidationError[] }> = ({ errors }) => (
  <>
    { errors.map(error => (
      <ValidationErrorMessage key={ error.type }>
        {
          ValidationErrorIdentifier.isBlankError(error) || error.type === 'missing'
            ? (
              <FormattedMessage
                id="defaultCategoriesForm.input.blankError"
                description="Error message when default event category is blank."
                defaultMessage="Default category cannot be blank"
              />
            )
            : ValidationErrorIdentifier.isNotAllowedError(error) && error.metadata.reason === 'Not published'
              ? (
                <FormattedMessage
                  id="defaultCategoriesForm.input.unpublishedError"
                  description="Error message when default event category is unpublished."
                  defaultMessage="Default category must be published"
                />
              )
              : (
                <FormattedMessage
                  id="defaultCategoriesForm.input.generalError"
                  description="General error message for default category inputs."
                  defaultMessage="There is a problem with the selected category"
                />
              )
        }
      </ValidationErrorMessage>
    )) }
  </>
);
