import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { LoadingCard } from '@ourpeople/shared/Core/Component/Feedback';

import { StyledLoadingText } from './styles';
import { Fullscreen } from '../../../Components';
import { ErrorWithRetry } from '../../../Common/Component';
import { useApi } from '../../../Core/Hook';
import { DeprecatedBroadcast, DraftBroadcast } from '../../Model';

interface RouteState {
  template?: DraftBroadcast;
}

export const BuildTemplatePage: FC = () => {
  const history = useHistory();
  const api = useApi();
  const [error, setError] = useState(false);
  const [template, setTemplate] = useState(null as DraftBroadcast | null);
  const [noBroadcastPresent, setNoBroadcastPresent] = useState(false);

  useEffect(() => {
    const state = (history.location.state || {}) as unknown as RouteState;
    const stateTemplate = state.template ?? null;
    if (!stateTemplate) {
      setNoBroadcastPresent(true);
      return;
    }
    setTemplate(stateTemplate);
  }, [history.location.state]);

  useEffect(() => {
    if (noBroadcastPresent) {
      history.replace('/broadcasts/create');
    }
  }, [history, noBroadcastPresent])

  useEffect(
    () => {
      if (error || !template) {
        return;
      }

      let cancelled = false;

      api.post<DeprecatedBroadcast>('broadcasts', template)
        .then((response) => response.data.id)
        .then((broadcastId) => {
          if (cancelled) {
            return;
          }
          history.replace(`/broadcasts/${ broadcastId }/edit`);
        })
        .catch(() => {
          setError(true);
        });

      return () => {
        cancelled = true;
      };
    },
    [history, api, error, setError, template],
  );

  const whenRetryClicked = () => {
    setError(false);
  }

  return (
    <>
      <Fullscreen>
        {
          error && (
            <ErrorWithRetry onRetry={ whenRetryClicked }>
              <FormattedMessage
                id="broadcasts.createDraftPage.errorText"
                description="Error when unable to build a broadcast from a template"
                defaultMessage="We were unable to build your broadcast"
              />
            </ErrorWithRetry>
          )
        }
        {
          !error && (
            <>
              <StyledLoadingText>
                <FormattedMessage
                  id="broadcasts.createDraftPage.loadingText"
                  description="Message shown whilst a draft is being created"
                  defaultMessage="Building your broadcast..."
                />
              </StyledLoadingText>
              <LoadingCard />
            </>
          )
        }
      </Fullscreen>
    </>
  );
};
