import { FC, useMemo } from 'react';

import { Flex, PresentationIcon } from '../../../../Common/Component';
import { UniqueIdGenerator } from '../../../../Common/Utility';
import { StyledLabel } from './style';
import { SvgComponent } from '../../../../Common/Model';

type Props = {
  label: string;
  IconComponent: SvgComponent;
};

export const EventInformation: FC<Props> = ({
  label,
  IconComponent,
  children,
}) => {
  const id = useMemo(() => UniqueIdGenerator.generate(), []);
  return (
    <Flex gap={ 2 }>
      <PresentationIcon
        IconComponent={ IconComponent }
        size="medium"
      />
      <div>
        <div aria-labelledby={ id }>{ children }</div>
        <StyledLabel id={ id }>{ label }</StyledLabel>
      </div>
    </Flex>
  );
}
