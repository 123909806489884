import { FormattedMessage } from 'react-intl';
import { FunctionComponent, PropsWithChildren, ReactNode } from 'react';
import { Heading } from '@ourpeople/shared/Core/Component/Content';

import { useEnvironmentSettings } from '../../../Common/Hook';
import { StyledNoResultsContainer } from '../../../Sections/Broadcasts/List/styles';

export const NonTeamTagTable: FunctionComponent<PropsWithChildren<unknown>> = ({ children }) => {
  const { nonTeamTagsEnabled } = useEnvironmentSettings();

  return (
    <>
      {
        nonTeamTagsEnabled === true
         ? children
          : (
            <StyledNoResultsContainer>
              <Heading type="h2">
                <FormattedMessage
                  id="tags.disabledTagType.heading"
                  defaultMessage="Your current plan is limited to the use of team tags"
                />
              </Heading>
              <Heading type="h4">
                <FormattedMessage
                  id="tags.disabledTagType.body"
                  defaultMessage="Don't worry, you can message our <a>support team</a> if you'd like to get access."
                  values={{
                    a: (chunks: (string|ReactNode)[]) => (
                      <a href="mailto:support@ourpeople.com">
                        { chunks }
                      </a>
                    )
                  }}
                />
              </Heading>
            </StyledNoResultsContainer>
          )
      }
    </>
  )
}
