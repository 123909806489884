import { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ValidationError } from 'op-storybook/lib/model/Validation/ValidationError';

import { TagParser } from '../../Utility';
import { MissingParentTagError } from '../../Model';
import { ImportErrorPresentationComponent } from '../../../Imports/Model';
import { Flex } from '../../../Common/Component';
import { TooLongError } from '../../../Common/Model';

export const TagImportRecordErrors: ImportErrorPresentationComponent = ({ errors }) => {
  const intl = useIntl();

  const getErrorMessage = (error: ValidationError): ReactNode => {
    switch (error.type) {
      case 'tooLong':
        return (
          <FormattedMessage
            id="tags.import.tooLongError"
            description="Error when tag name is too long."
            defaultMessage="Tag names must be under { maxLength } characters."
            values={ (error as TooLongError).metadata }
          />
        );
      case 'missingParentTag':
        return (
          <FormattedMessage
            id="tags.import.missingParentTagError"
            description="Error when column has a tag type not available on users plan."
            defaultMessage="A parent of type { parentTag } is required for { tag }."
            values={ {
              tag: TagParser.localisedTagTypeFromInternalTagType((error as MissingParentTagError).metadata.type, intl, Infinity),
              parentTag: TagParser.localisedTagTypeFromInternalTagType((error as MissingParentTagError).metadata.parentType, intl, 1),
            } }
          />
        );
      default:
        return (
          <FormattedMessage
            id="tags.import.fallbackPrepareError"
            description="Fallback prepare error when error can not be identified."
            defaultMessage="There is a problem with this record."
          />
        );
    }
  };

  return (
    <Flex
      direction="column"
      align="flex-start"
      gap={ 1 }
    >
      { errors.map(error => (
        <div key={ error.type }>
          { getErrorMessage(error) }
        </div>
      )) }
    </Flex>
  );
}
