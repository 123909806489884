import { CircularProgress, Dialog, DialogActions, DialogProps, DialogTitle } from '@mui/material';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { TabContext } from '@mui/lab';
import { Button } from '@ourpeople/shared/Core/Component/Input/Button/Button';
import { CenteredGenericErrorMessage, CenteredMessageWithLoadingCard } from '@ourpeople/shared/Core/Component/Content';
import { useDebounce } from '@ourpeople/shared/Core/Hook/useDebounce';
import { AxiosError } from 'axios';

import { AudienceMemberList } from '../../../Audiences/Component';
import {
  FetchAudienceMembersParams,
  FetchAudienceMembersResult,
  FetchAudienceMembersSort,
  useFetchAudience
} from '../../../Audiences/Hook';
import { AudienceCleaner } from '../../../Audiences/Utility';
import { FetchResponse } from '../../../Hooks';
import {
  CloseButton,
  Flex,
  FlexPullRight,
  IconButton,
  LoadingContainer,
  PresentationIcon,
  VerticallySpaced
} from '../../../Common/Component';
import LockIcon from '../../../Assets/img/icons/streamline/lock.svg';
import DirectoryTreeIcon from '../../../Assets/img/icons/streamline/folder-connect.svg';
import ArrowIcon from '../../../Assets/img/icons/streamline/arrow-left-1.svg';
import { Audience } from '../../../Audiences/Model';
import { Tab } from '../../../Components';
import { StyledTabs } from '../../../Components/Navigation/styles';
import { StyledDialogContent, StyledTabPanel } from './style';
import { FetchResult, RequestState } from '../../../Models';
import { useApi } from '../../../Core/Hook';
import { useMounted, useUserRoles } from '../../../Common/Hook';
import { FolderNode, Node } from '../../Model';
import { ReadonlyNodeAudience } from '../ReadonlyNodeAudience/ReadonlyNodeAudience';
import { AudienceEditor } from '../../../Audiences/Component/AudienceEditor/AudienceEditor';

type Props = {
  dialogProps: DialogProps;
  containingNode: FolderNode | undefined;
  node: Node;
  onSaveClicked: (audience: Audience) => void;
};

export const NodePermissionsDialog: FC<Props> = ({
  dialogProps,
  containingNode,
  node,
  onSaveClicked,
}) => {
  const api = useApi();
  const intl = useIntl();
  const [tab, setTab] = useState<'filters' | 'preview'>('filters');
  const [audience, setAudience] = useState<Audience>();
  const [uneditedAudience, setUneditedAudience] = useState<Audience>();
  const [audienceMemberSort, setAudienceMemberSort] = useState<FetchAudienceMembersSort>('first_name_asc');
  const [audienceMemberSearch, setAudienceMemberSearch] = useState<string>();
  const [audienceMemberPageNum, setAudienceMemberPageNum] = useState<number>(1);
  const [fetchAudienceResult, , reloadAudience] = useFetchAudience(node.viewAudience.id);
  const { userIsSuperAdmin } = useUserRoles();
  const alwaysEditAudience = userIsSuperAdmin;
  const [editing, setEditing] = useState<boolean>(alwaysEditAudience);
  const fetchAudienceMembersParams = useMemo<FetchAudienceMembersParams | undefined>(
    () => (
      audience && {
        audience: AudienceCleaner.removeInvalidConditions(audience),
        context: 'default',
        applyReach: editing || alwaysEditAudience,
        query: {
          ...(audienceMemberSearch ? { search: audienceMemberSearch } : {}),
          pageNum: audienceMemberPageNum,
          sort: [audienceMemberSort],
        },
      }
    ),
    [audience, audienceMemberPageNum, audienceMemberSearch, audienceMemberSort, editing, alwaysEditAudience],
  );
  const debouncedFetchAudienceMembersParams = useDebounce(fetchAudienceMembersParams, 500);
  const [
    [
      fetchAudienceMembersResult,
      fetchAudienceMemberState,
      reloadAudienceMembers,
    ], setFetchAudienceMembersResponse
  ] = useState<FetchResponse<FetchAudienceMembersResult>>([
    null,
    RequestState.PENDING,
    () => null,
  ]);
  const [viewingParentAudience, setViewingParentAudience] = useState<boolean>(false);
  const mounted = useMounted();

  useEffect(() => {
    setAudienceMemberSearch('');
    setAudienceMemberPageNum(1);
  }, [audience]);

  const fetchAudienceMembers = useCallback(() => {
    setFetchAudienceMembersResponse([
      null,
      RequestState.FETCHING,
      fetchAudienceMembers,
    ]);

    api.post<FetchAudienceMembersResult>(
      '/audiences/list-members',
      fetchAudienceMembersParams,
    )
      .then(response => {
        if (!mounted.current) {
          return;
        }

        setFetchAudienceMembersResponse([
          FetchResult.fromContent(response.data),
          RequestState.COMPLETE,
          fetchAudienceMembers,
        ]);
      })
      .catch((error: AxiosError) => {
        if (!mounted.current) {
          return;
        }

        setFetchAudienceMembersResponse([
          FetchResult.fromError(error),
          RequestState.FAILED,
          fetchAudienceMembers,
        ]);
      });
  }, [api, fetchAudienceMembersParams, mounted]);

  useEffect(() => (
    setAudience(fetchAudienceResult?.content)
  ), [fetchAudienceResult?.content]);

  useEffect(() => {
    if (!fetchAudienceMembersParams) {
      return;
    }

    fetchAudienceMembers();
  }, [fetchAudienceMembers, fetchAudienceMembersParams]);

  const whenEditViewAudienceButtonClicked = useCallback(() => {
    setUneditedAudience(audience);
    setEditing(true);
  }, [audience]);

  const whenRevertViewAudienceButtonClicked = useCallback(() => {
    setAudience(uneditedAudience);
    setEditing(false);
  }, [uneditedAudience]);

  return (
    <Dialog
      { ...dialogProps }
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle>
        <Flex gap={ 2 }>
          {
            viewingParentAudience
              ? (
                <IconButton
                  IconComponent={ ArrowIcon }
                  label={ intl.formatMessage({
                    description: 'Label for back button in directory permissions dialog when viewing parent directory permissions.',
                    defaultMessage: 'Back',
                  }) }
                  onClick={ () => setViewingParentAudience(false) }
                  size="small"
                />
              )
              : (
                <PresentationIcon
                  IconComponent={ LockIcon }
                />
              )
          }
          <FormattedMessage
            description="Permissions dialog title"
            defaultMessage="Permissions: { directoryName }"
            values={ {
              directoryName: viewingParentAudience && containingNode ? containingNode.name : node.name,
            } }
          />
          { dialogProps.onClose && (
            <FlexPullRight>
              <CloseButton
                onClick={ () => dialogProps.onClose && dialogProps.onClose({}, 'backdropClick') }
              />
            </FlexPullRight>
          ) }
        </Flex>
      </DialogTitle>
      {
        viewingParentAudience && containingNode
          ? (
            <StyledDialogContent>
              <ReadonlyNodeAudience node={ containingNode }/>
            </StyledDialogContent>
          )
          : (
            <>
              <TabContext value={ tab }>
                <StyledTabs
                  value={ tab }
                  scrollButtons="auto"
                  variant="scrollable"
                  onChange={ (_event, tab) => setTab(tab as 'filters' | 'preview') }
                  indicatorColor="primary"
                  textColor="primary"
                >
                  <Tab
                    value="filters"
                    label={ intl.formatMessage({
                      description: 'Filters tab label in directory permissions dialog.',
                      defaultMessage: 'Audience filters',
                    }) }
                  />
                  <Tab
                    value="preview"
                    label={ intl.formatMessage({
                      description: 'Preview tab label in directory permissions dialog.',
                      defaultMessage: 'Audience preview',
                    }) }
                  />
                </StyledTabs>
                <StyledTabPanel value="filters">
                  <StyledDialogContent>
                    <VerticallySpaced gap={ 3 }>
                      { containingNode && (
                        <Flex gap={ 2 }>
                          <PresentationIcon
                            IconComponent={ DirectoryTreeIcon }
                            color="secondary.main"
                          />
                          <span>
                            <FormattedMessage
                              defaultMessage="Important note: The audience reach is limited by the parent folder <a>{ parentName }</a>."
                              description="Folder permission reach notice."
                              values={ {
                                parentName: containingNode.name,
                                a: chunks => (
                                  <Button
                                    inline
                                    color="secondary"
                                    onClick={ () => setViewingParentAudience(true) }
                                  >
                                    { chunks }
                                  </Button>
                                ),
                              } }
                            />
                          </span>
                        </Flex>
                      ) }
                      {
                        fetchAudienceResult
                          ? fetchAudienceResult.content && audience
                            ? (
                              <VerticallySpaced gap={ 1 }>
                                {
                                  fetchAudienceMembersResult
                                    ? (
                                      <span>
                                        <FormattedMessage
                                          description="Audience size listed above builder in directory permissions dialog."
                                          defaultMessage="Current audience size: <a>{ audienceSize, plural, =1 {# person} other {# people} }</a>"
                                          values={ {
                                            audienceSize: fetchAudienceMembersResult?.content?.audienceSize || 0,
                                            a: chunks => (
                                              <Button
                                                inline
                                                color="secondary"
                                                onClick={ () => setTab('preview') }
                                              >
                                                { chunks }
                                              </Button>
                                            ),
                                          } }
                                        />
                                      </span>
                                    )
                                    : (
                                      <Flex>
                                        <CircularProgress size={ 20 } color="secondary"/>
                                        <span>
                                          <FormattedMessage
                                            description="Loading message above audience builder in directory permissions dialog when calculating audience size."
                                            defaultMessage="Calculating audience size…"
                                          />
                                        </span>
                                      </Flex>
                                    )
                                }
                                <AudienceEditor
                                  audience={ audience }
                                  validation={ undefined }
                                  editing={ editing }
                                  showReach={ !alwaysEditAudience }
                                  onChange={ setAudience }
                                  onEditButtonClicked={ whenEditViewAudienceButtonClicked }
                                  onRevertButtonClicked={ whenRevertViewAudienceButtonClicked }
                                  alwaysEdit={ alwaysEditAudience }
                                  multipleSegments={ true }
                                />
                              </VerticallySpaced>
                            )
                            : (
                              <LoadingContainer>
                                <CenteredGenericErrorMessage onRetryClicked={ reloadAudience }/>
                              </LoadingContainer>
                            )
                          : (
                            <LoadingContainer>
                              <CenteredMessageWithLoadingCard
                                heading={ intl.formatMessage({
                                  description: 'Loading message for directory permissions audience.',
                                  defaultMessage: 'Loading permissions for { directoryName }…',
                                }, {
                                  directoryName: node.name,
                                }) }
                              />
                            </LoadingContainer>
                          )
                      }
                    </VerticallySpaced>
                  </StyledDialogContent>
                  <DialogActions>
                    <Button
                      variant="primary"
                      disabled={ !audience }
                      onClick={ () => audience && onSaveClicked(audience) }
                    >
                      <FormattedMessage
                        description="Label for save button in directory permissions dialog."
                        defaultMessage="Save"
                      />
                    </Button>
                  </DialogActions>
                </StyledTabPanel>
                <StyledTabPanel value="preview">
                  <StyledDialogContent>
                    {
                      fetchAudienceResult && fetchAudienceMembersResult
                        ? fetchAudienceMembersResult.content && debouncedFetchAudienceMembersParams
                          ? (
                            <AudienceMemberList
                              audienceMembersResult={ fetchAudienceMembersResult.content }
                              query={ debouncedFetchAudienceMembersParams.query }
                              search={ fetchAudienceMembersParams?.query.search || '' }
                              onSortChanged={ setAudienceMemberSort }
                              onSearchChanged={ setAudienceMemberSearch }
                              onPageChanged={ setAudienceMemberPageNum }
                              onRetryClicked={ reloadAudienceMembers }
                              requestState={ fetchAudienceMemberState }
                            />
                          )
                          : (
                            <LoadingContainer>
                              <CenteredGenericErrorMessage onRetryClicked={ reloadAudience }/>
                            </LoadingContainer>
                          )
                        : (
                          <LoadingContainer>
                            <CenteredMessageWithLoadingCard
                              heading={ intl.formatMessage({
                                description: 'Loading message for directory permissions audience members.',
                                defaultMessage: 'Loading audience members for { directoryName }…',
                              }, {
                                directoryName: node.name,
                              }) }
                            />
                          </LoadingContainer>
                        )
                    }
                  </StyledDialogContent>
                </StyledTabPanel>
              </TabContext>
            </>
          )
      }
    </Dialog>
  );
};
