import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';

import { BasicAutocomplete, BasicAutocompleteProps } from '../../../Common/Component';
import { LocalisedString } from '../../../Common/Model';

export type SkippedStatus = 'noIgnored';

type Props = Omit<BasicAutocompleteProps<SkippedStatus>, 'options'>;

export const SkippedStatusAutocomplete: FunctionComponent<Props> = props => {
  const intl = useIntl();
  const options: LocalisedString<SkippedStatus>[] = [
    {
      id: 'noIgnored',
      localisation: intl.formatMessage({
        id: 'import.skippedStatusAutocomplete.noWithout',
        description: 'Label for hide skipped rows in skipped status autocomplete',
        defaultMessage: 'Hide',
      }),
    },
  ];

  return (
    <BasicAutocomplete
      options={ options }
      label={ intl.formatMessage({
        id: 'import.skippedStatusAutocomplete.label',
        description: 'Label for import skipped status autocomplete.',
        defaultMessage: 'Skipped rows',
      }) }
      { ...props }
    />
  );
};
