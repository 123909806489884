import { default as React, FC, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { ContentEditorProps } from '../../../../Content/Model';
import {
  UPLOAD_DESCRIPTION_MAX_LENGTH,
  UPLOAD_DESCRIPTION_MIN_LENGTH,
  UPLOAD_TITLE_MAX_LENGTH,
  UPLOAD_TITLE_MIN_LENGTH,
  UploadEditorContent,
  UploadRequestValidator
} from '../../../Service';
import { useInputChangeEventHandler } from '../../../../Common/Hook';
import { FieldValidationErrors, NoMarginTextField, VerticallySpaced } from '../../../../Common/Component';
import { CharacterCount } from '../../../../Content/Component';

export const UploadRequestEditor: FC<ContentEditorProps<UploadEditorContent>> = ({
  editorContent,
  onEditorContentChanged,
  validation,
  onValidationChanged,
}) => {
  const intl = useIntl();
  const descriptionFieldLabel = intl.formatMessage({
    id: 'broadcasts.content.upload.fields.description.label',
    description: 'Label for description field in upload card editor.',
    defaultMessage: 'Description',
  });
  const titleFieldLabel = intl.formatMessage({
    id: 'broadcasts.content.upload.fields.title.label',
    description: 'Label for title field in upload card editor.',
    defaultMessage: 'Title',
  });

  const whenTitleChanged = useInputChangeEventHandler(useCallback((title: string) => (
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          title,
        },
      },
    })
  ), [editorContent, onEditorContentChanged]));

  const whenDescriptionChanged = useInputChangeEventHandler(useCallback((description: string) => (
    onEditorContentChanged({
      ...editorContent,
      card: {
        ...editorContent.card,
        content: {
          ...editorContent.card.content,
          description,
        },
      },
    })
  ), [editorContent, onEditorContentChanged]));

  const whenTitleBlurred = useCallback(() => (
    onValidationChanged({
      errors: validation?.errors || [],
      children: {
        content: {
          errors: validation?.children.content?.errors || [],
          children: {
            ...validation?.children.content?.children,
            title: UploadRequestValidator.validateTitle(editorContent.card.content.title),
          },
        },
      },
    })
  ), [validation, onValidationChanged, editorContent]);

  const whenDescriptionBlurred = useCallback(() => (
    onValidationChanged({
      errors: validation?.errors || [],
      children: {
        content: {
          errors: validation?.children.content?.errors || [],
          children: {
            ...validation?.children.content?.children,
            description: UploadRequestValidator.validateTitle(editorContent.card.content.description),
          },
        },
      },
    })
  ), [validation, onValidationChanged, editorContent]);

  return (
    <VerticallySpaced gap={ 2 }>
      <VerticallySpaced gap={ 1 }>
        <h4>{ titleFieldLabel }</h4>
        <NoMarginTextField
          fullWidth
          value={ editorContent.card.content.title }
          onChange={ whenTitleChanged }
          onBlur={ whenTitleBlurred }
        />
        <CharacterCount
          current={ editorContent.card.content.title.length || 0 }
          minimum={ UPLOAD_TITLE_MIN_LENGTH }
          maximum={ UPLOAD_TITLE_MAX_LENGTH }
        />
        <FieldValidationErrors
          fieldName={ titleFieldLabel }
          validationErrors={ validation?.children.content?.children.title?.errors || [] }
        />
      </VerticallySpaced>
      <VerticallySpaced gap={ 1 }>
        <h4>{ descriptionFieldLabel }</h4>
        <NoMarginTextField
          fullWidth
          value={ editorContent.card.content.description }
          onChange={ whenDescriptionChanged }
          onBlur={ whenDescriptionBlurred }
        />
        <CharacterCount
          current={ editorContent.card.content.description.length || 0 }
          minimum={ UPLOAD_DESCRIPTION_MIN_LENGTH }
          maximum={ UPLOAD_DESCRIPTION_MAX_LENGTH }
        />
        <FieldValidationErrors
          fieldName={ descriptionFieldLabel }
          validationErrors={ validation?.children.content?.children.description?.errors || [] }
        />
      </VerticallySpaced>
    </VerticallySpaced>
  );
}
