import { FC, useCallback } from 'react';

import { LiveComponentProps } from '../../../../Content/Model';
import { RatingRequest as RatingRequestModel } from '../../../Model/ContentTypes';
import { RatingRequest } from '../../Content';
import { DraftRatingResponse } from '../../../Service';
import { useContextOrThrow } from '../../../../Core/Hook';
import { FormNavigationContext } from '../../../Provider/FormNavigationProvider';

export const LiveRatingRequest: FC<LiveComponentProps<RatingRequestModel, DraftRatingResponse>> = ({
  card,
  response,
  onResponseChange,
  onResponseClear,
  onValidationChange,
  validation,
}) => {
  const { displayingValidation } = useContextOrThrow(FormNavigationContext);
  const whenRatingChanged = useCallback((rating: number | null) => {
    if (rating === null) {
      onResponseClear();
      onValidationChange({
        errors: [],
        children: {},
      });
      return;
    }

    const response: DraftRatingResponse = {
      contentId: card.content.id,
      contentType: 'ratingRequest',
      rating,
    };

    onResponseChange(response);
    onValidationChange({
      errors: [],
      children: {},
    });
  }, [card.content.id, onResponseChange, onResponseClear, onValidationChange]);

  return (
    <RatingRequest
      content={ card.content }
      value={ response?.rating === undefined ? null : response.rating }
      onChange={ whenRatingChanged }
      validation={ validation }
      displayValidation={ displayingValidation }
    />
  );
};
