import { FC, MouseEventHandler, useCallback, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { TextField } from '../TextField/TextField';
import HideIcon from '../../../lib/assets/icon/figma/eye-off.svg?react';
import RevealIcon from '../../../lib/assets/icon/figma/eye.svg?react';
import { TextFieldIconButton } from '../../../lib/components/TextFieldIconButton/TextFieldIconButton';

type Props = {
  value: string;
  onChange: (value: string) => void;
  errorMessages?: string[];
  label?: string;
};

export const PasswordField: FC<Props> = ({
  value,
  onChange,
  errorMessages,
  label,
}) => {
  const intl = useIntl();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [visible, setVisible] = useState<boolean>(false);
  const inputProps = useMemo(() => ({
    type: visible ? 'text' : 'password',
    autofill: 'current-password',
  }), [visible]);

  const toggleVisibility: MouseEventHandler<HTMLButtonElement> = useCallback(() => (
    setVisible(visible => !visible)
  ), []);

  const whenChanged = useCallback((value: string) => {
    setVisible(false);
    onChange(value);
  }, [onChange]);

  return (
    <TextField
      name="password"
      onBlur={ () => setVisible(false) }
      label={ label || intl.formatMessage({
        description: 'Label for password input used throughout web app.',
        defaultMessage: 'Password',
      }) }
      errorMessages={ errorMessages }
      value={ value }
      onChange={ whenChanged }
      inputProps={ inputProps }
      startAdornment={ (
        <TextFieldIconButton
          ref={ buttonRef }
          type="button"
          tabIndex={ -1 }
          onClick={ toggleVisibility }
          IconComponent={
            visible
              ? HideIcon
              : RevealIcon
          }
          label={
            visible
              ? intl.formatMessage({
                description: 'Label for hide password button in password field.',
                defaultMessage: 'Hide password',
              })
              : intl.formatMessage({
                description: 'Label for show password button in password field.',
                defaultMessage: 'Show password',
              })
          }
        />
      ) }
    />
  )
}
