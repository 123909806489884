import { useIntl } from 'react-intl';
import { ComponentProps, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { NavItem } from '@ourpeople/shared/Core/Component/Navigation/NavItem/NavItem';

import { usePermissions } from '../../Security/Hook/usePermissions';
import { useEnvironmentSettings } from './useEnvironmentSettings';
import { useUserRoles } from './useUserRoles';
import { RoleReader } from '../../Readers/RoleReader';
import { NavItemConfiguration, useNavItemConfigurations } from './useNavItemConfigurations';
import { NavGroup } from '../Component/Navigation/NavItemGroup/NavItemGroup';
import { EnvironmentSettings } from '../../Models';

type Props = NavGroup | Omit<ComponentProps<typeof NavItem>, 'iconOnly'>;

type ReturnValue = {
  mainItems: Props[];
  footerItems: Props[];
};

export const useSidebarConfiguration = (): ReturnValue => {
  const intl = useIntl();
  const { permissionAvailable, permissionPresent } = usePermissions();
  const environmentSettings = useEnvironmentSettings();
  const location = useLocation();
  const { mostPermissiveRole } = useUserRoles();
  const { mainItems, footerItems } = useNavItemConfigurations();

  const navItemConfigurationToNavItemProps = useCallback((
    {
      requirePermissionPresent = [],
      requirePermissionAvailable = [],
      requireRole,
      requireEnvironmentSettingsToDisplay = [],
      requireEnvironmentSettingsToEnable = [],
      disallow,
      path,
      children,
      ...props
    }: NavItemConfiguration,
  ): Props[] => {
    const checkPermission = !!requirePermissionAvailable.length || !!requirePermissionPresent.length;
    const relevantPermissionCheckFailed = checkPermission && !(
      requirePermissionPresent.find(({ permission, exclude }) => permissionPresent(permission, exclude))
      || requirePermissionAvailable.find(permission => permissionAvailable(permission))
    );

    const missingEnvironmentSettings = !requireEnvironmentSettingsToDisplay.every(requiredEnvironmentSetting => (
      typeof requiredEnvironmentSetting === 'string'
        ? !!environmentSettings[requiredEnvironmentSetting]
        : (Array.from(Object.keys(requiredEnvironmentSetting)) as (keyof EnvironmentSettings)[])
          .every(requiredEnvironmentSettingKey => environmentSettings[requiredEnvironmentSettingKey] === requiredEnvironmentSetting[requiredEnvironmentSettingKey])
    ));
    const roleInsufficient = requireRole && RoleReader.priority(requireRole) > RoleReader.priority(mostPermissiveRole);
    const allowed = !disallow || !disallow(environmentSettings);

    if (missingEnvironmentSettings || relevantPermissionCheckFailed || roleInsufficient || !allowed) {
      return [];
    }

    const absoluteLink = !!(path && /^https:\/\//.exec(path));

    const childItems = children && children.reduce<ComponentProps<typeof NavItem>[]>(
      (accumulatedChildProps, childConfiguration) => {
        const childProps = navItemConfigurationToNavItemProps(childConfiguration)[0];

        if (!childProps || childProps.children) {
          return accumulatedChildProps;
        }

        const navItemProps = childProps as ComponentProps<typeof NavItem>;

        return accumulatedChildProps.concat(navItemProps);
      },
      [],
    );

    return path
      ? [
        {
          ...(absoluteLink ? {
              component: 'a',
              anchorProps: {
                href: path,
                rel: 'norefferer',
                target: '_blank',
              },
            } : {
              component: 'link',
              linkProps: {
                to: path || '',
              },
            }),
          disabledFeatureTooltip: intl.formatMessage({
            description: 'Tooltip displayed when a navigation item in the sidebar menu is locked',
            defaultMessage: 'This feature isn\'t available on your current plan. Contact customer support to upgrade.'
          }),
          count: undefined,
          disabled: !requireEnvironmentSettingsToEnable.every(requiredEnvironmentSetting => !!environmentSettings[requiredEnvironmentSetting]),
          labelTooltip: true,
          current: !!new RegExp(`^${ path }`).exec(location.pathname),
          ...props,
        }
      ]
      : childItems
        ? childItems.length
          ? [
            {
              children: childItems,
              ...props,
            }
          ]
          : []
        : [{ ...props }];
  }, [
    environmentSettings,
    intl,
    location.pathname,
    mostPermissiveRole,
    permissionAvailable,
    permissionPresent
  ]);

  const reduceNavigationItems = useCallback((accumulatedNavProps: Props[], navigationItem: NavItemConfiguration): Props[] => {
    const itemProps = navItemConfigurationToNavItemProps(navigationItem);

    return [
      ...accumulatedNavProps,
      ...itemProps,
    ];
  }, [navItemConfigurationToNavItemProps]);

  const mainItemProps = useMemo<Props[]>(() => mainItems.reduce<Props[]>(
    reduceNavigationItems,
    [],
  ), [mainItems, reduceNavigationItems]);

  const footerItemProps = useMemo<Props[]>(() => footerItems.reduce<Props[]>(
    reduceNavigationItems,
    [],
  ), [footerItems, reduceNavigationItems]);

  return useMemo(() => ({
    mainItems: mainItemProps,
    footerItems: footerItemProps,
  }), [footerItemProps, mainItemProps]);
};
