import { ComponentProps, FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { IntlProvider, MessageFormatElement, ResolvedIntlConfig } from 'react-intl';

type Props = Partial<ResolvedIntlConfig> & {
  loadingElement?: ReactNode;
  children?: ReactNode;
};

export const ConfiguredIntlProvider: FunctionComponent<Props> = ({ loadingElement, children, ...props }) => {
  const [messages, setMessages] = useState<Record<string, MessageFormatElement[]>>();
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    let mounted = true;
    const importMessages = () => {
      const languages = navigator.languages;
      let languageIndex = 0;

      while (languageIndex < languages.length) {
        if (languages[languageIndex] === DEFAULT_LOCALE) {
          if (mounted) {
            setLoaded(true);
          }
          return Promise.resolve();
        }

        try {
          if (mounted) {
            setMessages({});
            setLoaded(true);
          }
          return Promise.resolve();
        } catch {
          languageIndex += 1;
        }
      }

      setLoaded(true);
      return Promise.resolve();
    };

    importMessages()
      .catch(() => {
        /* Fall back to default locale */
        setLoaded(true);
      });

    return () => { mounted = false; };
  }, []);

  return (
    <IntlProvider
      locale={ navigator.language }
      messages={ messages }
      defaultLocale={ DEFAULT_LOCALE }
      defaultRichTextElements={ defaultRichTextElements }
      { ...props }
    >
      {
        loaded
        ? children
        : loadingElement ?? <></>
      }
    </IntlProvider>
  )
};

export const DEFAULT_LOCALE = 'en-GB';
export const defaultRichTextElements: ComponentProps<typeof IntlProvider>['defaultRichTextElements'] = {
  strong: chunks => <strong>{ chunks }</strong>,
  b: chunks => <strong>{ chunks }</strong>,
  em: chunks => <em>{ chunks }</em>,
  p: chunks => <p>{ chunks }</p>,
  span: chunks => <span>{ chunks }</span>,
  li: chunks => <li>{ chunks }</li>,
};
