import styled from '@emotion/styled';

export const StyledNudgeBanner = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    ". message"
    ". date";
  column-gap: ${props => props.theme.spacing(1)};
  padding: ${props => props.theme.spacing(1)};
  border-left: 6px solid ${props => props.theme.palette.error.main};
  border-bottom: 1px solid ${props => props.theme.palette.grey[200]};
`;

export const StyledDate = styled.span`
  grid-area: date;
  font-size: 0.8rem;
  color: ${props => props.theme.palette.grey[500] };
`;

export const StyledMessage = styled.span`
  grid-area: message;
  font-size: 0.9rem;
`;
