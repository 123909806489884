import { IntlShape } from 'react-intl';
import EventBlockIcon from 'op-storybook/lib/assets/icon/figma/calendar.svg';
import {
  BlankValidationErrorDefinition
} from 'op-storybook/lib/model/Validation/Definition/BlankValidationErrorDefinition';

import { BlockErrorDefinitions, BroadcastBlockDefinition } from '../../Model/BroadcastBlockDefinition';
import { BroadcastBlock } from '../../Model/Broadcast';
import { UniqueIdGenerator } from '../../../../Common/Utility';
import { EventBlockExpandedPreview } from '../../Component/Blocks/EventBlock/EventBlockExpandedPreview';
import { EventBlockInlineForm } from '../../Component/Blocks/EventBlock/EventBlockInlineForm';
import { EventBlockForm } from '../../Component/Blocks/EventBlock/EventBlockForm';

export type EventBlock = {
  localId: string;
  kind: 'event';
  attributes: {
    name: string;
    description?: string;
    location?: string;
    date: {
      start: string;
      end: string;
    };
  };
};

export class EventBlockDefinition implements BroadcastBlockDefinition<EventBlock> {
  public readonly prioritise = false;
  public readonly kind = 'event';
  public readonly localisedKind: string;
  public readonly errorDefinitions: BlockErrorDefinitions<EventBlock>;

  constructor(intl: IntlShape) {
    this.localisedKind = intl.formatMessage({
      description: 'Name for event block used in broadcast editor',
      defaultMessage: 'Event',
    });

    this.errorDefinitions = {
      name: [
        new BlankValidationErrorDefinition(intl => intl.formatMessage({
          description: 'Error message when broadcast event block name input is left blank.',
          defaultMessage: 'Name can not be blank.',
        })),
      ],
      date: {
        start: [],
        end: [],
      },
    };
  }

  public definesBlock(block: BroadcastBlock): block is EventBlock {
    return block.kind === 'event';
  }

  public initialiseBlock(): EventBlock {
    return {
      localId: UniqueIdGenerator.generate(),
      kind: 'event',
      attributes: {
        name: '',
        date: {
          start: '',
          end: '',
        },
      },
    };
  }

  public IconComponent = EventBlockIcon;
  public ExpandedPreviewComponent = EventBlockExpandedPreview;
  public InlineFormComponent = EventBlockInlineForm;
  public FormComponent = EventBlockForm;
}
