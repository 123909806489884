import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Heading } from '@ourpeople/shared/Core/Component/Content';

import { Paragraph } from '../../../../Components';
import { Box } from '../../../../Common/Component';
import { Label } from '../../../../Components/Content/Label';
import {
  StyledBroadcastSummaryRoot,
  StyledDeliveryCount,
  StyledRecentDeliveriesRoot,
  StyledScheduleSummaryRoot
} from './styles';
import { RecentDeliveriesTable } from './RecentDeliveriesTable';
import { BroadcastReport, ListedDelivery } from '../../../../Models';
import { ReadableRecurrenceRule } from '../../Common/ReadableRecurrenceRule/ReadableRecurrenceRule';

type Props = {
  report: BroadcastReport,
  deliveries: ListedDelivery[],
};

export const BroadcastSummary: FunctionComponent<Props> = ({
  report,
  deliveries,
}) => {
  return (
    <>
      {
        report
        && (
          <StyledBroadcastSummaryRoot>
            <StyledScheduleSummaryRoot>
              <Box>
                <Heading type="h2">
                  <FormattedMessage
                    id="broadcasts.report.scheduleSummary.heading"
                    description="Heading for broadcast report schedule summary"
                    defaultMessage="Schedule summary"
                  />
                </Heading>

                <Label>Schedule</Label>
                <Paragraph>
                  <ReadableRecurrenceRule recurrenceRule={report.broadcast.recurrence?.rule} />
                </Paragraph>

                <Label>Deliveries</Label>
                <StyledDeliveryCount>{report.counts.deliveries.delivered}</StyledDeliveryCount>
              </Box>
            </StyledScheduleSummaryRoot>
            <StyledRecentDeliveriesRoot>
              <Box>
                <Heading type="h2">
                  <FormattedMessage
                    id="broadcasts.report.recentDeliveries.heading"
                    description="Heading for broadcast report recent deliveries"
                    defaultMessage="Recent deliveries"
                  />
                </Heading>
                <RecentDeliveriesTable deliveries={ deliveries.slice(0, 4) } />
              </Box>
            </StyledRecentDeliveriesRoot>
          </StyledBroadcastSummaryRoot>
        )
      }
    </>
  );
};
