import { FC } from 'react';

import { DraftTextContent } from '../../../Model/ContentTypes';
import { ContentPreviewProps, EditorContent } from '../../../../Content/Model';
import { RichTextContentPreview } from '../../../../Content/Component';

export const TextContentPreview: FC<ContentPreviewProps<EditorContent<DraftTextContent>>> = ({
  editorContent,
}) => (
  <RichTextContentPreview text={ editorContent.card.content.html }/>
);
