import { Api } from './Api';
import { UploadUrls } from '../Common/Hook';

export class Downloader {
  constructor(private api: Api) {
  }

  public getDownloadUrls(uploadId: string): Promise<UploadUrls> {
    return this.api.get<UploadUrls>(`/uploads/${ uploadId }/urls`)
      .then(response => response.data);
  }
}
