import { FunctionComponent, ReactNode, useMemo } from 'react';
import { FormattedList, FormattedMessage, useIntl } from 'react-intl';

import { OperationSummary } from '../../../Imports/Model';
import { Notice } from '../../../Common/Component';
import { StyledOperationList } from './style';

type Props = {
  operations: OperationSummary[];
  onImportButtonClicked: () => void;
}

export const PeopleOperationSummaryNotice: FunctionComponent<Props> = ({
  operations,
  onImportButtonClicked,
}) => {
  const intl = useIntl();
  const [creationStrings, updateStrings] = useMemo(() => {
    const localiseOperation = (operation: OperationSummary): ReactNode => (
      operation.recordType === 'profile'
        ? (
          <FormattedMessage
            key={ `${ operation.operationType }_${ operation.recordType }` }
            id="peopleOperationSummary.profilesWithCount"
            description="Profiles with count, listed in creation and update summaries."
            defaultMessage="{ count, plural, one { # profile } other {# profiles }}"
            values={ {
              count: operation.count,
            } }
          />
        )
        : (
          <FormattedMessage
            key={ `${ operation.operationType }_${ operation.recordType }` }
            id="peopleOperationSummary.peopleWithCount"
            description="People with count, listed in creation and update summaries."
            defaultMessage="{ count, plural, one { # person } other {# people }}"
            values={ {
              count: operation.count,
            } }
          />
        )
    );

    return [
      operations.filter(operation => operation.operationType === 'create')
        .map(localiseOperation),
      operations.filter(operation => operation.operationType === 'update')
        .map(localiseOperation),
    ];
  }, [operations]);

  return (
    <Notice
      feedback={ {
        message: (
          <FormattedMessage
            id="people.import.summary"
            description="Import summary message above table in summary step of people import."
            defaultMessage="This import will: <ul>{ creations }{ updates }</ul>"
            values={ {
              ul: (chunks: ReactNode[]) => <StyledOperationList>{ chunks }</StyledOperationList>,
              li: (chunks: ReactNode[]) => <li>{ chunks }</li>,
              creations: !!creationStrings.length && (
                <li>
                  <FormattedMessage
                    id="people.import.creationSummary"
                    description="Import summary creation bullet point."
                    defaultMessage="<strong>Create</strong> { creationList }"
                    values={ {
                      creationList: <FormattedList value={ creationStrings }/>,
                    } }
                  />
                </li>
              ),
              updates: !!updateStrings.length && (
                <li>
                  <FormattedMessage
                    id="people.import.updateSummary"
                    description="Import summary update bullet point."
                    defaultMessage="<strong>Update</strong> { updateList }"
                    values={ {
                      updateList: <FormattedList value={ updateStrings }/>,
                    } }
                  />
                </li>
              )
            } }
          />
        ),
        severity: 'info',
      } }
      buttons={ [
        {
          id: 'perform',
          props: {
            variant: 'primary',
            onClick: onImportButtonClicked,
          },
          label: intl.formatMessage({
            id: 'people.import.summary.preformImport',
            description: 'Preform import button in operation summary of summary step of people import.',
            defaultMessage: 'Start import',
          }),
        }
      ] }
      variant="outlined"
    />
  );
};
