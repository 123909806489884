import styled from '@emotion/styled';

import { css } from '../../Styling/css';

interface BaseProps {
  disabled: boolean;
  bordered: string|null;
  primary: string|null;
}

const base = css<BaseProps>`
  font-family: ${props => props.theme.typography.fontFamily};
  font-weight: 500;
  line-height: 1;

  display: inline-block;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  ${props => props.disabled && `
    pointer-events: none;
  `}
  border: none;

  &, &:hover {
    color: ${ props => props.theme.palette.primary.main };

    background-color: transparent;
    text-decoration: none;

    ${props => props.primary && `
      color: ${props.theme.palette.primary.contrastText};
    `}

    ${props => props.disabled && `
      opacity: 0.5;
    `}
  }

  &, &:hover {
    ${props => props.primary && props.bordered && `
      background-color: ${props.theme.palette.primary.main};
      ${
        props.disabled
          ? ''
          : `
            &:hover {
              background-color: ${props.theme.palette.primary.light};
            }
          `
      }
    `}
  }

  ${ props => props.bordered && `
    padding: 0.7rem 1rem 0.5rem 1rem;
    border-radius: 5px;
    border: 0.1rem solid ${ props.theme.palette.primary.main };
    ${
      props.disabled
        ? ''
        : `
          &:hover {
            border-color: ${ props.theme.palette.primary.light }
          }
        `
    }
  ` }
`;

export const StyledExternalLink = styled.a<BaseProps>`
  ${base}
  ${props => !props.bordered && `
    &:hover {
      text-decoration: underline;
    }
  `}
`;
export const StyledButton = styled.button<BaseProps>`${base}`;
