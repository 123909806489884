import { useIntl } from 'react-intl';

import { MappedSettingTypes } from '../../Types';
import { DurationParser } from '../../../../../Utility/DurationParser';

type Props = {
  value: MappedSettingTypes['duration']['value'],
};

export const DurationSettingValue = ({
  value,
}: Props): JSX.Element => {
  const intl = useIntl();

  return (
    <>
      { value !== null && intl && (
        <p>
          { DurationParser.localise(value, intl) }
        </p>
      ) }
    </>
  );
};
