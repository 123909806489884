import React, {FC, useCallback, useEffect, useState} from 'react';
import {CircularProgress} from '@mui/material';
import {FormattedMessage} from 'react-intl';

import {useFetchPersonalDelivery} from '../../Hook';
import {PersonalDelivery} from '../../Model';
import {ContentsContainer, DeliveryHeader, HeaderWithCard, InboxLoadingCard} from '..';
import {StyledRecalledCard} from './style';
import {CardError} from '../CardError/CardError';

type Props = {
  deliveryId: string;
  selectedDelivery?: PersonalDelivery;
  onChange: (delivery: PersonalDelivery) => void;
  onReloadRequired: () => void;
};

export const DeliveryContainer: FC<Props> = ({
  deliveryId,
  selectedDelivery,
  onChange,
  onReloadRequired,
}) => {
  const [delivery, setDelivery] = useState<PersonalDelivery | undefined>(selectedDelivery);
  const [fetchPersonalDeliveryResult, , reload] = useFetchPersonalDelivery(deliveryId);

  useEffect(() => {
    if (!fetchPersonalDeliveryResult?.content) {
      return;
    }

    setDelivery(fetchPersonalDeliveryResult.content);
  }, [fetchPersonalDeliveryResult]);

  useEffect(() => (
    delivery && onChange(delivery)
  ), [delivery, onChange]);

  const whenReloadRequired = useCallback(() => {
    onReloadRequired();
    reload();
  }, [onReloadRequired, reload]);

  return (
    <HeaderWithCard
      headerContents={
        delivery
          ? (
            <DeliveryHeader
              delivery={ delivery }
            />
          )
          : (
            <CircularProgress
              variant="indeterminate"
              color="secondary"
              size={ 32 }
            />
          )
      }
    >
      {
        delivery
          ? delivery.delivery.recall
            ? (
              <StyledRecalledCard>
                <h2><FormattedMessage
                  id="broadcasts.recall.message"
                  description="Message shown when a broadcast has been recalled"
                  defaultMessage="This broadcast is no longer available"
                /></h2>
                <span>{ delivery.delivery.recall.reason }</span>
              </StyledRecalledCard>
            )
            : (
              <ContentsContainer
                key={ delivery.delivery.id }
                delivery={ delivery }
                onChange={ setDelivery }
                onReloadRequired={ whenReloadRequired }
              />
            )
          : fetchPersonalDeliveryResult && fetchPersonalDeliveryResult.isError()
            ? <CardError/>
            : <InboxLoadingCard/>
      }
    </HeaderWithCard>
  );
};
