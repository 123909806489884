import { FC } from 'react';

import { Stack } from '../../../lib/components/Stack/Stack';
import { ProgressCircle } from '../ProgressCircle/ProgressCircle';
import { Typography } from '../Typography/Typography';

type Props = {
  pad: boolean;
  text?: string;
  className?: string;
};

export const LoadingState: FC<Props> = ({
  pad,
  text,
  className,
}) => (
  <Stack
    direction="column"
    align="center"
    gap={ 6 }
    {
      ...pad
        ? {
          css: theme => ({
            height: '500px',
            justifyContent: 'center',
            alignItems: 'center',
            padding: theme.new.spacing[4]
          })
        }
        : {}
    }
    className={ className }
  >
    <ProgressCircle
      size="lg"
      palette={ {
        colour: 'primary',
        intensity: 600,
      } }
    />
    { text && (
      <Typography
        size="lg"
        weight="medium"
      >
        { text }
      </Typography>
    ) }
  </Stack>
);
