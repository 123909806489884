import styled from '@emotion/styled';

export const StyledContentNavItem = styled.div`
  user-select: none;
  touch-action: none;
  display: grid;
  grid-template-columns: 1fr auto;
  position: relative;
`;

interface ContentNavButtonProps {
  error: boolean;
  active: boolean;
}

export const StyledContentNavButton = styled.div<ContentNavButtonProps>`
  display: block;
  border-radius: ${ props => `${ props.theme.spacing(1) }` };
  padding: ${ props => `${ props.theme.spacing(1) }` };
  border: 1px solid ${ props => props.theme.palette.grey[300] };
  ${ props => props.active && `
    border-color: ${ props.theme.palette.secondary.main };
  ` }
  background-color: ${ props => props.theme.palette.background.paper };
  text-align: left;
  ${props => props.error && `color: ${ props.theme.palette.error.main };`}
  display: flex;
  gap: ${ props => props.theme.spacing(1) };

  > svg {
    flex-shrink: 0;
  }

  > span {
    flex-shrink: 1;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
  }

  .MuiButton-label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
