import styled from '@emotion/styled';

export const StyledBroadcastContentPreview = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;
  color: ${ props => props.theme.palette.text.primary };
  background: ${ props => props.theme.palette.background.paper };
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${ props => props.theme.spacing(1) };
`;

export const StyledPreviousIcon = styled.span`
  transform: rotate(90deg);
`;

export const StyledNextIcon = styled.span`
  transform: rotate(-90deg);
`;
