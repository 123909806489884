import { FunctionComponent } from 'react';
import { LinearProgress } from '@mui/material';

import { StyledCount, StyledCountPercentage, StyledCountGraph } from './styles';

interface Props {
  label: string;
  percentage: number;
}

export const LinearCellGraph: FunctionComponent<Props> = ({
  label, percentage
}): JSX.Element => {
  return (
    <StyledCount>
      <StyledCountPercentage>
        {label}
      </StyledCountPercentage>
      <StyledCountGraph>
        <LinearProgress variant="determinate" value={percentage * 100} color="secondary" />
      </StyledCountGraph>
    </StyledCount>
  );
};
