import { MappedSettingTypes } from '../../Types';
import { Checkbox } from '../../../../../Components';

type Props = {
  value: MappedSettingTypes['boolean']['value'],
};

export const BooleanSettingValue = ({
  value,
}: Props): JSX.Element => {
  return (
    <>
      <Checkbox
        checked={value || false}
        disabled={true}
      />
    </>
  );
};
