import styled from '@emotion/styled';

export const StyledTextFieldTrailingButton = styled.button`
  border-style: none;
  border-left: ${ props => props.theme.new.border.light };
  margin: 0;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${ props => `${ props.theme.new.spacing[2] } ${ props.theme.new.spacing[3] }` };
  color: ${ props => props.theme.new.palette.grey[700].main };
  border-bottom-right-radius: ${ props => props.theme.new.borderRadius.standard };
  border-top-right-radius: ${ props => props.theme.new.borderRadius.standard };

  &:hover {
    background-color: ${ props => props.theme.new.palette.grey[50].main };
    color: ${ props => props.theme.new.palette.grey[800].main };
  }

  &:focus {
    background-color: ${ props => props.theme.new.palette.grey[50].main };
  }

  &:focus-visible {
    outline: none;
  }
`;
