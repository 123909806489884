import { FC } from 'react';
import { ThemeProvider, useTheme } from '@emotion/react';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material';

import { useEnvironmentSettings } from '../../Common/Hook';

export const SpaceThemeProvider: FC = ({ children }) => {
  const {
    stackBackgroundColour,
    stackCtaBackgroundColour,
    stackCtaTextColour,
    stackTextColour,
  } = useEnvironmentSettings();
  const consoleTheme = useTheme();
  const userTheme = createTheme({
    ...consoleTheme,
    palette: {
      ...consoleTheme.palette,
      primary: {
        main: stackCtaBackgroundColour || consoleTheme.palette.primary.main,
        contrastText: stackCtaTextColour || consoleTheme.palette.primary.contrastText,
      },
      secondary: {
        main: stackCtaBackgroundColour || consoleTheme.palette.secondary.contrastText,
        contrastText: stackCtaTextColour || consoleTheme.palette.secondary.main,
      },
      background: {
        paper: stackBackgroundColour || consoleTheme.palette.secondary.main,
      },
      text: {
        primary: stackTextColour || consoleTheme.palette.secondary.contrastText,
      },
    },
  });

  return (
    <MuiThemeProvider theme={ userTheme }>
      <ThemeProvider theme={ userTheme }>
        { children }
      </ThemeProvider>
    </MuiThemeProvider>
  );
}
