import { IntlShape } from 'react-intl';

import { Condition, TagCondition } from '../../../../Audiences/Model';
import { AudienceConditionDefinition, ConditionRestriction } from '../../Model/AudienceConditionDefinition';
import { TagType } from '../../../../Tags/Model';
import { JobTitleConditionComponent } from '../../Component/JobTitleCondition/JobTiteConditionComponent';
import { JobTitleList } from '../../Component/JobTitleCondition/JobTitleList';

export class JobTitleConditionDefinition implements AudienceConditionDefinition<TagCondition<TagType.JobTitle>> {
  public readonly TYPE = 'tag';
  public readonly restriction = ConditionRestriction.SOME;
  public readonly name;

  constructor(intl: IntlShape) {
    this.name = intl.formatMessage({
      description: 'Name for job title condition, used in audience builder.',
      defaultMessage: 'Job title',
    });
  }

  public definesCondition(condition: Condition): condition is TagCondition<TagType.JobTitle> {
    return condition.type === this.TYPE && (condition as TagCondition).tagType === TagType.JobTitle;
  }

  isValid(condition: TagCondition<TagType.JobTitle>): boolean {
    return !!condition.tagIds.length;
  }

  public Component = JobTitleConditionComponent;

  public build = {
    Component: JobTitleList,
    initialise: () => ({
      type: 'tag' as const,
      tagType: TagType.JobTitle,
      tagIds: [],
      not: false,
      new: true,
    }) as TagCondition<TagType.JobTitle>,
  };
}
