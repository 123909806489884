import styled from '@emotion/styled';

import PlusIcon from '../../../../../Assets/img/icons/streamline/add.svg';

export const StyledSignature = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: ${ props => props.theme.spacing(2) };
  align-items: flex-start;
  overflow: hidden;
  flex-shrink: 0;

  hr {
    margin: 0;
  }
`;

export const StyledCrossIcon = styled(PlusIcon)`
  margin-top: ${ props => props.theme.spacing(0.5) };
  transform: rotate(45deg);
`;

export const StyledSignatureName = styled.div<{ disabled: boolean }>`
  font-size: 1.5rem;
  padding: 0 0.5rem;
  min-height: 2.25rem;
  transition: background-color 0.1s;

  ${ props => !props.disabled && `
    &:hover {
      background-color: ${ props.theme.palette.grey[100] };
    }
  ` }
`;

export const StyledSignatureDate = styled.span`
  font-size: 0.95rem;
`;
