import { DraftSingleContentForm, FORM_NAME_MAX_LENGTH } from '../../Model';
import { ValidationTree, Validator } from '../../../Common/Model';
import { Validate } from '../../../Common/Utility';

export class FormValidator {
  public static validateName = (form: DraftSingleContentForm): ValidationTree<string> => ({
    children: {},
    errors: nameValidator.validate(form.name || ''),
  });

  public static validateCategory = (form: DraftSingleContentForm): ValidationTree<string> => ({
    children: {},
    errors: categoryValidator.validate(form.categoryId || ''),
  });

  public static validate = (form: DraftSingleContentForm): ValidationTree<DraftSingleContentForm> => ({
    errors: [],
    children: {
      name: FormValidator.validateName(form),
      categoryId: FormValidator.validateCategory(form),
    },
  });
}

const nameValidator = new Validator<string>([
  Validate.notBlank,
  Validate.utf8,
  name => Validate.maxLength(name, FORM_NAME_MAX_LENGTH),
]);

const categoryValidator = new Validator<string>([
  Validate.notBlank,
]);
