import { IntlShape } from 'react-intl';

import { Condition, RoleCondition } from '../../../../Audiences/Model';
import { AudienceConditionDefinition, ConditionRestriction } from '../../Model/AudienceConditionDefinition';
import { RoleList } from '../../Component/RoleCondition/RoleList';
import { RoleConditionComponent } from '../../Component/RoleCondition/RoleConditionComponent';

export class RoleConditionDefinition implements AudienceConditionDefinition<RoleCondition> {
  public readonly TYPE = 'role';
  public readonly restriction = ConditionRestriction.SOME;
  public readonly name;

  constructor(intl: IntlShape) {
    this.name = intl.formatMessage({
      description: 'Name for role condition, used in audience builder.',
      defaultMessage: 'Role',
    });
  }
  public definesCondition(condition: Condition): condition is RoleCondition {
    return condition.type === this.TYPE;
  }

  isValid(condition: RoleCondition): boolean {
    return !!condition.roleIds.length;
  }

  public Component = RoleConditionComponent;

  public build = {
    Component: RoleList,
    initialise: () => ({
      type: 'role',
      roleIds: [],
      not: false,
      new: true,
    }) as RoleCondition,
  };
}
