import { ComponentProps, FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { parseISO } from 'date-fns';

import {
  BroadcastContentFooter,
  ContentPadding,
  NudgeBanner,
  RespondButton,
  RespondButtonProps,
  StackNavigationProps,
  TextContentWithoutFooter
} from '../..';
import { StyledTextBroadcastContent } from './style';
import { ScrollableContent } from '../../../../../Broadcasts/Component';
import { BroadcastNudge } from '../../../../../Broadcasts/Model';
import { LinkContent } from '../../LinkContent/LinkContent';
import { AbsoluteBackButtonContainer } from '../../AbsoluteBackButtonContainer/AbsoluteBackButtonContainer';

type Props = {
  html: string;
  placeholderText?: string;
  markAsReadProps: RespondButtonProps;
  navigationProps: StackNavigationProps;
  responded: boolean;
  hideRespondButton: boolean;
  nudge?: BroadcastNudge;
  linkProps: ComponentProps<typeof LinkContent> | null;
  backButton: ReactNode | undefined;
};

export const TextBroadcastContent: FC<Props> = ({
  html,
  placeholderText,
  markAsReadProps,
  navigationProps,
  responded,
  hideRespondButton = false,
  nudge,
  linkProps,
  backButton,
}) => {
  const intl = useIntl();

  return (
    <StyledTextBroadcastContent>
      { backButton && <AbsoluteBackButtonContainer>{ backButton }</AbsoluteBackButtonContainer> }
      <ScrollableContent>
        {
          nudge && (
            <NudgeBanner
              message={ nudge.message }
              nudgedAt={ parseISO(nudge.at) }
            />
          )
        }
        <ContentPadding>
          <TextContentWithoutFooter
            html={ html }
            placeholderText={ placeholderText }
            linkProps={ linkProps }
          />
        </ContentPadding>
      </ScrollableContent>
      <BroadcastContentFooter
        { ...navigationProps }
        responded={ responded }
        respondProps={ markAsReadProps }
      >
        { !hideRespondButton && (
          <RespondButton
            { ...markAsReadProps }
            variant="primary"
            notRespondedLabel={ intl.formatMessage({
              id: 'content.broadcast.text.notResponded',
              description: 'Label for mark as read button when unread.',
              defaultMessage: 'Mark as read',
            }) }
            respondedLabel={ intl.formatMessage({
              id: 'content.broadcast.text.responded',
              description: 'Label for mark as read button when unread.',
              defaultMessage: 'Read',
            }) }
            rejectedLabel={ intl.formatMessage({
              id: 'content.broadcast.text.rejected',
              description: 'Label for mark as read button when response was rejected.',
              defaultMessage: 'Try again',
            }) }
          />
        ) }
      </BroadcastContentFooter>
    </StyledTextBroadcastContent>
  );
};
