import { FunctionComponent } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { BreakpointProvider } from 'op-storybook/lib/providers/BreakpointProvider/BreakpointProvider';

import { ApiProvider } from './ApiProvider';
import { AuthProvider } from './AuthProvider';
import { SpaceProvider } from './SpaceProvider';
import { ThemeProvider } from './ThemeProvider';
import { ConfiguredIntlProvider } from '../../Core/Component';
import { GoogleAnalyticsProvider, NoticeProvider, ToastProvider, WebsocketProvider } from '../../Core/Provider';
import { AuthorisedLayout } from './AuthorisedLayout/AuthorisedLayout';
import {
  ConditionalStreamConnectionProvider
} from '../../Chat/Provider/ConditionalStreamConnectionProvider/ConditionalStreamConnectionProvider';
import { LoginRouter } from '../../Core/Routing/LoginRouter/LoginRouter';
import { AnonymousRouter } from '../../Core/Routing/AnonymousRouter/AnonymousRouter';
import { LoginStateProvider } from '../../Core/Provider/LoginStateProvider';
import { LoggedInAuthProvider } from '../../Security/Provider';
import { IdentityProvider } from '../../Core/Provider/IdentityProvider/IdentityProvider';
import { DeviceFingerprintProvider } from '../../Core/Provider/DeviceFingerprintProvider';

interface Props {
  locale?: string;
  gaTrackingId?: string;
}

const GA_TRACKING_ID = process.env.OP_GA_TRACKING_ID;
const NODE_ENV = process.env.NODE_ENV;

export const App: FunctionComponent<Props> = ({
  locale,
  gaTrackingId,
}) => {
  return (
    <ConfiguredIntlProvider locale={ locale || navigator.language }>
      <ThemeProvider>
        <SpaceProvider>
          <Router>
            <ToastProvider>
              <DeviceFingerprintProvider>
                <ApiProvider>
                  <AnonymousRouter>
                    <GoogleAnalyticsProvider
                      trackingId={ gaTrackingId ?? GA_TRACKING_ID }
                      options={ {
                        ...(
                          NODE_ENV === 'test'
                            ? {
                              testMode: true,
                            }
                            : {}
                        )
                      } }
                    >
                      <LoginStateProvider>
                        <BreakpointProvider>
                          <IdentityProvider>
                            <AuthProvider>
                              <LoginRouter>
                                <LoggedInAuthProvider>
                                  <WebsocketProvider>
                                    <NoticeProvider>
                                      <ConditionalStreamConnectionProvider>
                                        <AuthorisedLayout />
                                      </ConditionalStreamConnectionProvider>
                                    </NoticeProvider>
                                  </WebsocketProvider>
                                </LoggedInAuthProvider>
                              </LoginRouter>
                            </AuthProvider>
                          </IdentityProvider>
                        </BreakpointProvider>
                      </LoginStateProvider>
                    </GoogleAnalyticsProvider>
                  </AnonymousRouter>
                </ApiProvider>
              </DeviceFingerprintProvider>
            </ToastProvider>
          </Router>
        </SpaceProvider>
      </ThemeProvider>
    </ConfiguredIntlProvider>
  );
};
