import { FormattedMessage } from 'react-intl';
import { FunctionComponent, ReactNode } from 'react';

import { HelpText } from '..';

interface Props {
  min: number | null;
  max: number | null;
  singular: ReactNode;
  plural: ReactNode;
}

export const SelectionRange: FunctionComponent<Props> = ({
  min,
  max,
  singular,
  plural,
}) => {
  return (
    <HelpText>
      {
        min !== null
          ? max !== null
            ? min === max
              ? (
                <FormattedMessage
                  id="form.fields.choice.options.exact"
                  defaultMessage="Please select {max, plural, one {# {singular}} other {# {plural}}}"
                  values={{
                    max,
                    singular,
                    plural,
                  }}
                />
              )
              : (
                <FormattedMessage
                  id="form.fields.choice.options.between"
                  defaultMessage="Please select between {min} and {max, plural, one {# {singular}} other {# {plural}}}"
                  values={{
                    min,
                    max,
                    singular,
                    plural,
                  }}
                />
              )
            : (
              <FormattedMessage
                id="form.fields.choice.options.atLeast"
                defaultMessage="Please select at least {min, plural, one {# {singular}} other {# {plural}}}"
                values={{
                  min,
                  singular,
                  plural,
                }}
              />
            )
          : max !== null
            ? (
              <FormattedMessage
                id="form.fields.choice.options.atMost"
                defaultMessage="Please select up to {max, plural, one {# {singular}} other {# {plural}}}"
                values={{
                  max,
                  singular,
                  plural,
                }}
              />
            )
            : (
              <FormattedMessage
                id="form.fields.choice.options.any"
                defaultMessage="Please select as many { plural } as you would like"
                values={{
                  plural,
                }}
              />
            )
      }
    </HelpText>
  )
};
