import * as React from 'react';

import { MappedSettingTypes } from '../../Types';
import { Checkbox } from '../../../../../Components';

type Props = {
  value: MappedSettingTypes['boolean']['value'],
  onValueChanged: (value: MappedSettingTypes['boolean']['value']) => void,
  focusId?: string,
};

export const BooleanSettingInput = ({
  value,
  onValueChanged,
  focusId,
}: Props): JSX.Element => {
  const whenValueChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    onValueChanged(value);
  }

  return (
    <div>
      {
        <Checkbox
          id={focusId}
          checked={value || false}
          onChange={whenValueChanged}
        />
      }
    </div>
  );
};
