import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import { css } from '../../../Styling/css';

export const broadcastLabelBase = css`
  display: block;
  padding: 0.1rem 0.2rem;
  border-radius: 0.1rem;
  font-size: 0.9rem;
`;

export const StyledRecurringLabel = styled.span`
  ${ broadcastLabelBase };
  background: ${props => props.theme.palette.navy.light};
  color: ${props => props.theme.palette.navy.main};
`;

export const StyledSendingProgress = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 0.75rem;
  align-items: center;
  color: ${props => props.theme.palette.secondary.main};
`;

export const StyledAlertContainer = styled.div`
  padding: 0 1rem;
`;

export const StyledLoadingCardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50%;
  height: 50vh;
`;

export const StyledNoResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.2rem;
  width: 100%;
  height: 50%;
  height: 50vh;
`;

export const StyledDialogProgressContainer = styled.div`
  padding: 2rem 0 1rem;
`;

export const StyledBroadcastTitle = styled(Link)`
  color: ${props => props.theme.palette.text.primary};
  text-decoration: none;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledSeenCount = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledSeenCountPercentage = styled.div`
  width: 4ch;
  margin-right: 0.5ch;
`;

export const StyledSeenCountGraph = styled.div`
  width: 6ch;
`;
