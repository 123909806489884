import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

export const InvalidConditionError: FC = () => (
  <div
    css={ theme => ({
      color: theme.new.palette.error[600].main,
    }) }
  >
    <FormattedMessage
      description="Missing tag error in audience builder"
      defaultMessage="This condition is no longer valid and may behave unexpectedly. Edit or remove the condition to fix it."
    />
  </div>
);
