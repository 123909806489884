import { FC } from 'react';
import { useIntl } from 'react-intl';

import LoadingAnimation from '../../../../../src/react/Assets/img/loading/loading.svg';
import { StyledLoadingCard } from './style';

export const LoadingCard: FC = () => {
  const intl = useIntl();

  return (
    <StyledLoadingCard
      role="status"
      aria-label={ intl.formatMessage({
        id: 'loading',
        description: 'Assistive label for loading card',
        defaultMessage: 'Loading'
      }) }
    >
      <LoadingAnimation/>
    </StyledLoadingCard>
  );
};
