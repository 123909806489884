import { FC } from 'react';
import { StackEnd } from 'op-storybook/lib/components/StackEnd/StackEnd';

import { StyledPagingMenuListItem, StyledPagingMenuListItemButton } from './style';
import RightArrowIcon from '../../../../Asset/Icon/Streamline/arrow-right-1.svg';
import { DeprecatedPresentationIcon } from '../PresentationIcon/DeprecatedPresentationIcon';

type Props = {
  label: string;
  onClick: () => void;
};

export const PagingMenuListItem: FC<Props> = ({
  label,
  onClick,
}) => (
  <StyledPagingMenuListItem>
    <StyledPagingMenuListItemButton onClick={ onClick }>
      <span>{ label }</span>
      <StackEnd>
        <DeprecatedPresentationIcon
          IconComponent={ RightArrowIcon }
          size={ 3 }
        />
      </StackEnd>
    </StyledPagingMenuListItemButton>
  </StyledPagingMenuListItem>
);
