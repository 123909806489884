import { FC } from 'react';
import { SingleSelect } from 'op-storybook/stories/components/input/SingleSelect';

import { BlockType, useLocalisedBlockFormattingOptions } from '../Hook/useLocalisedBlockFormattingOptions';

type Props = {
  value: BlockType;
  onChange: (value: BlockType) => void;
};

export const BlockFormattingOptionSelect: FC<Props> = ({
  value,
  onChange,
}) => {
  const options = useLocalisedBlockFormattingOptions();

  return (
    <SingleSelect
      buttonProps={ {
        css: theme => ({
          margin: theme.new.spacing[1],
          maxWidth: '200px',
        }),
      } }
      value={ value }
      onChange={ onChange }
      options={ options }
      getOptionValue={ option => option.id }
      getOptionLabel={ option => option.localisation }
      getOptionIcon={ option => option.IconComponent }
    />
  );
};
