import React, { FunctionComponent } from 'react';

import { StyledLabelModifier } from './style';

interface Props {
  activeIndex: number;
  options: string[];
  onChange: (index: number) => void;
  readonly?: boolean;
}

export const LabelModifier: FunctionComponent<Props> = ({
  activeIndex,
  options,
  onChange,
  readonly = false,
}) => {
  const whenClicked = (): void => {
    if (readonly) {
      return;
    }

    const nextIndex = (activeIndex + 1) % options.length;
    onChange(nextIndex);
  }

  return (
    <StyledLabelModifier
      onClick={whenClicked}
      $readonly={ readonly }
    >
      {options[activeIndex]}
    </StyledLabelModifier>
  );
}
