import { FC, RefObject } from 'react';

import { Condition } from '../../../Audiences/Model';

export enum ConditionRestriction {
  NONE,
  SOME,
  FULL,
}

export type ConditionListComponent<C extends Condition = Condition> = FC<{
  condition: C;
  onChange: (condition: C) => void;
  scrollAreaRef: RefObject<HTMLDivElement>;
}>;

export interface AudienceConditionDefinition<C extends Condition = Condition> {
  name: string;
  definesCondition: (condition: Condition) => condition is C;
  restriction: ConditionRestriction;
  isValid: (condition: C) => boolean;
  Component: FC<{ condition: C; onEditClicked: () => void; onChange: (condition: C) => void }>;
  build?: {
    Component: ConditionListComponent<C>;
    initialise: () => C;
  };
}
