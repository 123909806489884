import { DraftTextContent } from '../../ContentDefinition';
import { ValidationTree, Validator } from '../../../../Common/Model';
import { RichTextSanitiser, Validate } from '../../../../Common/Utility';
import { LinkValidator } from '../LinkValidator/LinkValidator';

export class TextContentValidator {
  public static validate = (content: DraftTextContent): ValidationTree<DraftTextContent> => ({
    errors: [],
    children: {
      text: TextContentValidator.validateText(content.text),
      ...LinkValidator.validate(content),
    },
  });

  public static validateText = (text: string): ValidationTree<string> => ({
    errors: textValidator.validate(text),
    children: {},
  });
}

export const TEXT_CONTENT_HTML_MAX_LENGTH = 700;

const textValidator = new Validator<string>([
  html => Validate.notBlank(RichTextSanitiser.stripTags(html)),
  html => Validate.maxLength(RichTextSanitiser.stripTags(html), TEXT_CONTENT_HTML_MAX_LENGTH),
  Validate.utf8,
]);
