import { FunctionComponent, useCallback, useContext } from 'react';
import { useIntl } from 'react-intl';
import { AutocompleteCommonProps, AutocompleteFetchRequest, AutocompleteSelectionChanged } from '@ourpeople/shared/Core/Component/Input/Autocomplete/Autocomplete';

import { ApiContext } from '../../../Contexts';
import { Paginated } from '../../../Models';
import { FetchCategoriesParams } from '../../Hook';
import { Category } from '../../Model';
import { StyledCategoryAutoComplete } from './style';
import { DebouncedAutocomplete } from '../../../Common/Component';

export interface CategoryAutocompleteProps extends AutocompleteCommonProps {
  selectedIds: string[];
  onSelectionChanged: AutocompleteSelectionChanged<Category>;
  baseQuery?: FetchCategoriesParams,
}

export const CategoryAutocomplete: FunctionComponent<CategoryAutocompleteProps> = ({
  selectedIds,
  onSelectionChanged,
  baseQuery,
  ...props
}) => {
  const intl = useIntl();
  const api = useContext(ApiContext);

  const whenFetch: AutocompleteFetchRequest<Category> = async (
    ids: string[],
    search: string,
    pageNum: number,
  ) => {
    if (!api) {
      throw new Error('api not ready');
    }

    const params: FetchCategoriesParams = {
      sort: 'name_asc',
      ...baseQuery,
      pageNum,
      ids: ids.join(',') || null,
      search: search.trim() || null,
    };
    const response = await api.get<Paginated<'categories', Category>>('/forms/categories', { params });
    return {
      options: response.data.categories,
      pageCount: response.data.pagination.pageCount,
    };
  };

  return <StyledCategoryAutoComplete>
    <DebouncedAutocomplete<Category>
      { ...props }
      getOptionLabel={ category => category.name }
      fetchOptions={ useCallback(whenFetch, [api, baseQuery]) }
      selectedIds={ selectedIds }
      onSelectionChanged={ onSelectionChanged }
      dense={ true }
      label={
        props.label || (
          props.multiple && selectedIds.length
            ? intl.formatMessage(
              {
                id: 'categoryAutoComplete.activeSelectionLabel',
                description: 'Category autocomplete label when a selection is active',
                defaultMessage: '{count, plural, one {# category} other {# categories}} selected'
              },
              {
                count: selectedIds.length,
              }
            )
            : intl.formatMessage(
              {
                id: 'categoryAutoComplete.label',
                defaultMessage: 'Select {limit, plural, one {category} other {categories}}'
              },
              {
                limit: props.multiple ? Infinity : 1,
              }
            )
        )
      }
      placeholder={
        props.placeholder ||
        intl.formatMessage(
          {
            id: 'categoryAutoComplete.placeholder',
            defaultMessage: 'Search for {limit, plural, one {category} other {categories}}'
          },
          {
            limit: props.multiple ? Infinity : 1,
          }
        )
      }
    />
  </StyledCategoryAutoComplete>
};
