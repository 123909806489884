import { FC } from 'react';

import { CardDivider, CardTitle } from '..';
import { StyledFileName, StyledFileSize, StyledIconAndSize, StyledMediaContentPreviewWithoutButton } from './style';
import { FileSizeFormatter, SvgComponent } from '../../../../Common/Model';
import { Upload } from '../../../../Types';

type Props = {
  text: string;
  media?: Upload;
  IconComponent: SvgComponent;
};

export const MediaContentPreviewWithoutButton: FC<Props> = ({
  text,
  media,
  IconComponent,
}) => (
  <StyledMediaContentPreviewWithoutButton>
    <CardTitle>{ text }</CardTitle>
    <CardDivider/>
    <StyledIconAndSize>
      <IconComponent/>
      <StyledFileSize>{ FileSizeFormatter.format(media?.size || 0) }</StyledFileSize>
    </StyledIconAndSize>
    <StyledFileName>{ media?.name }</StyledFileName>
  </StyledMediaContentPreviewWithoutButton>
);
