import { FC } from 'react';

import { StyledHeading, StyledMetric, StyledMetricCategory, StyledMetricContainer } from './style';
import { ReportsDatapointCategory } from '../../Hook';
import { MetricTooltip } from '../MetricTooltip/MetricTooltip';
import { NumericMetric } from '../NumericMetric/NumericMetric';

type Props = {
  category: ReportsDatapointCategory;
};

export const MetricCategory: FC<Props> = ({
  category,
}) => (
  <StyledMetricCategory itemCount={ category.datapoints.length }>
    <StyledHeading>
      { category.label }
    </StyledHeading>
    <StyledMetricContainer
      data-testid="metric-container"
    >
      { category.datapoints.map(datapoint => (
        <StyledMetric
          key={ datapoint.key }
          title={ datapoint.label }
          span={ 2 }
          actions={ (
            <MetricTooltip title={ datapoint.helpText }/>
          ) }
        >
          <NumericMetric
            previousValue={ 0 }
            currentValue={ datapoint.value }
            displayChangePercent={ false }
            viewMoreCta={ datapoint.link?.text }
            viewMoreLink={ datapoint.link?.to }
          />
        </StyledMetric>
      )) }
    </StyledMetricContainer>
  </StyledMetricCategory>
);
