import { ReactNode } from 'react';
import { FormattedList, FormattedMessage, useIntl } from 'react-intl';

import { Notice } from '../../../Common/Component';
import { MissingTagMappingTypesError } from '../../Model';
import { TagParser } from '../../Utility';
import { ValidationError } from '../../../Common/Model';
import { ImportErrorPresentationComponent } from '../../../Imports/Model';

export const TagImportColumnMapErrors: ImportErrorPresentationComponent = ({ errors }) => {
  const intl = useIntl();

  const getErrorMessage = (error: ValidationError): ReactNode => {
    switch (error.type) {
      case 'missingMappingTypes':
        return (
          <FormattedMessage
            id="tags.import.missingMappingTypesError"
            description="Error when import CSV is missing mapping types."
            defaultMessage="{ missingTypeCount, plural, one { A corresponding column } other { Corresponding columns } } for { tagTypeList } could not be determined. Please make sure your import data contains { missingTypeCount, plural, one { a column for { tagTypeList } and that it has } other { columns for the missing tag types and that they have } } been correctly assigned below."
            values={ {
              missingTypeCount: (error as MissingTagMappingTypesError).metadata.missingTypes.length,
              tagTypeList: (
                <FormattedList
                  type="conjunction"
                  value={ (error as MissingTagMappingTypesError).metadata.missingTypes.map(tagType => (
                    TagParser.localisedTagTypeFromInternalTagType(tagType, intl, Infinity)
                  )) }
                />
              )
            } }
          />
        );
      default:
        return (
          <FormattedMessage
            id="tags.import.fallbackColumnMapError"
            description="Fallback column map error when error can not be identified."
            defaultMessage="There is a problem with your import data."
          />
        );
    }
  }

  return (
    <>
      { errors.map(error => (
        <Notice
          key={ error.type }
          variant="outlined"
          feedback={ {
            severity: 'error',
            message: getErrorMessage(error),
          } }
        />
      )) }
    </>
  );
};
