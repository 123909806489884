import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CircularProgress } from '@mui/material';
import { ApiRequest } from '@ourpeople/shared/Core/Model/ApiRequest';
import { Typography } from 'op-storybook/stories/components/Typography/Typography';
import { Stack } from 'op-storybook/lib/components/Stack/Stack';
import { defaultPlaceholders, Editor } from '@ourpeople/shared/Core/Component/Input/RichTextEditor/Editor/Editor';
import { PlaceholderFinder } from '@ourpeople/shared/Core/Utility/PlaceholderFinder';
import { Button } from 'op-storybook/stories/components/Button/Button';
import { BreakpointContext } from 'op-storybook/lib/providers/BreakpointProvider/BreakpointProvider';
import EditIcon from 'op-storybook/lib/assets/icon/figma/pencil-01.svg';
import RevertIcon from 'op-storybook/lib/assets/icon/figma/refresh-ccw-01.svg';
import PreviewIcon from 'op-storybook/lib/assets/icon/figma/eye.svg';
import { PresentationIcon } from 'op-storybook/lib/components/PresentationIcon/PresentationIcon';
import { StackEnd } from 'op-storybook/lib/components/StackEnd/StackEnd';

import { FieldValidationErrors, Flex, Notice, VerticallySpaced } from '../../../Common/Component';
import { SwitchWithIcon } from '../SwitchWithIcon/SwitchWithIcon';
import SmsIcon from '../../../Assets/img/icons/streamline/messages-bubble.svg';
import { RequestState } from '../../../Models';
import { BroadcastNotificationSettings } from '../../Model';
import { SmsPreview } from '../../Model/SmsPreview';
import { StyledPreviewOverlay } from './style';
import { useEnvironmentSettings } from '../../../Common/Hook';
import { useContextOrThrow } from '../../../Core/Hook';
import { ErrorResponseReader } from '../../../Common/Utility';
import { SmsPreviewRequestData } from '../EditBroadcastSteps/EditBroadcastSteps';

type Props = {
  scheduled: boolean;
  notificationSettings: BroadcastNotificationSettings['sms'];
  onChange: (notificationSettings: BroadcastNotificationSettings['sms']) => void;
  smsPreviewRequest: ApiRequest<SmsPreview>;
  onRetrySmsPreviewRequestClicked: () => void;
  valid: boolean;
  placeholders: Record<string, string>;
  template: string;
};

export const SmsInput: FC<Props> = ({
  scheduled,
  notificationSettings,
  onChange,
  smsPreviewRequest,
  onRetrySmsPreviewRequestClicked,
  valid,
  placeholders,
  template,
}) => {
  const intl = useIntl();
  const screenWidth = useContextOrThrow(BreakpointContext);
  const { unlimitedSmsEnabled = false } = useEnvironmentSettings();
  const smsContent = useMemo(() => notificationSettings.customMessage || placeholders[CONTENT_PLACEHOLDER], [
    notificationSettings.customMessage,
    placeholders
  ]);
  const readonlyInputValue = useMemo(() => {
    const contentPlaceholderIndex = template.indexOf(CONTENT_PLACEHOLDER);
    const contentPlaceholderLength = CONTENT_PLACEHOLDER.length;
    const parts = [
      `{%${ PlaceholderFinder.replace(template.slice(0, contentPlaceholderIndex), placeholders) }%}`,
      smsContent,
      `{%${ PlaceholderFinder.replace(template.slice(contentPlaceholderIndex + contentPlaceholderLength), placeholders) }%}`,
    ];
    return parts.join('');
  }, [placeholders, smsContent, template]);
  const [editorActive, setEditorActive] = useState<boolean>(false);
  const [originalValue, setOriginalValue] = useState<string>('');
  const modified = useMemo(() => smsContent !== originalValue, [originalValue, smsContent]);

  useEffect(() => {
    if (smsPreviewRequest.state !== RequestState.COMPLETE || notificationSettings.customMessage) {
      return;
    }

    setOriginalValue(smsPreviewRequest.result.sms.placeholders[CONTENT_PLACEHOLDER]);
  }, [smsPreviewRequest, notificationSettings.customMessage])

  const whenSendChanged = useCallback((send: boolean) => {
    if (!send) {
      setEditorActive(false);
    }
    onChange({
      ...notificationSettings,
      send,
    });
  }, [notificationSettings, onChange]);

  const whenChanged = useCallback((value: string) => {
    onChange({
      ...notificationSettings,
      customMessage: value,
    });
  }, [notificationSettings, onChange]);

  const errorMessage = useMemo(() => {
    if (smsPreviewRequest.state !== RequestState.FAILED) {
      return null;
    }

    const validationTree = ErrorResponseReader.getValidationErrorRoot<SmsPreviewRequestData>(smsPreviewRequest.result);
    const customMessageErrors = validationTree?.children.customMessage?.errors || [];
    return (
      !!customMessageErrors.length
        ? (
          <FieldValidationErrors
            fieldName={ intl.formatMessage({
              description: 'Name for broadcast custom SMS notification message field when referenced by error messages.',
              defaultMessage: 'SMS notification message',
            }) }
            validationErrors={ customMessageErrors }
          />
        )
        : (
          <FormattedMessage
            description="Generic error when there is an issue with custom broadcast notification SMS message and a more precise error can't be determined."
            defaultMessage="There is a problem with your SMS notification message."
          />
        )
    );
  }, [smsPreviewRequest, intl]);

  return <>
    <VerticallySpaced gap={ 2 }>
      <Flex gap={ 2 }>
        <SwitchWithIcon
          IconComponent={ SmsIcon }
          checked={ notificationSettings.send }
          onChange={ whenSendChanged }
        />
        <FormattedMessage
          description="Message beside SMS switch in delivery settings."
          defaultMessage="Send an SMS notification to recipients with SMS enabled"
        />
      </Flex>
    </VerticallySpaced>
    { notificationSettings.send && (
      <>
        <Stack
          gap={ 2 }
          direction="column"
        >
          <Stack>
            <label htmlFor="sms-preview">
              <Typography
                size="sm"
                weight="semibold"
                palette={ {
                  colour: 'grey',
                  intensity: 700,
                } }
              >
                <FormattedMessage
                  description="Label for SMS notification input on broadcast delivery step"
                  defaultMessage="SMS notification"
                />
              </Typography>
            </label>
            <StackEnd>

            </StackEnd>
          </Stack>
          <div>
            {
              !valid
                ? (
                  <StyledPreviewOverlay>
                    <Typography
                      palette={ { colour: 'teal' } }
                      align="center"
                    >
                      <FormattedMessage
                        description="Message displayed in broadcast SMS preview input when preview can not be obtained."
                        defaultMessage="An SMS preview will display here when your broadcast is valid."
                      />
                    </Typography>
                  </StyledPreviewOverlay>
                )
                : smsPreviewRequest.state === RequestState.COMPLETE || editorActive
                  ? (
                    <div
                      css={ theme => ({
                        display: 'grid',
                        gridTemplateColumns: screenWidth.lessThan.sm ? '1fr' : '1fr 1fr',
                        gap: theme.new.spacing[2],
                        alignItems: 'flex-start',
                      }) }
                    >
                      <div css={ { width: '100% ' } }>
                        {
                          editorActive
                            ? (
                              <Editor
                                key="focused"
                                availablePlaceholders={ defaultPlaceholders }
                                mode="plainText"
                                value={ smsContent }
                                onChange={ whenChanged }
                                css={ {
                                  width: '100%',
                                } }
                              />
                            )
                            : (
                              <Editor
                                key="blurred"
                                disabled
                                hideToolbar
                                availablePlaceholders={ defaultPlaceholders }
                                mode="plainText"
                                onChange={ () => null }
                                value={ readonlyInputValue }
                                css={ {
                                  width: '100%',
                                } }
                              />
                            )
                        }
                      </div>
                      <div
                        css={ theme => ({
                          display: 'grid',
                          gridTemplateColumns: screenWidth.lessThan.sm ? '1fr 1fr' : '1fr',
                          ...(!screenWidth.lessThan.sm ? { width: 'min-content' } : {}),
                          gap: theme.new.spacing[2],
                        }) }
                      >
                        {
                          editorActive
                            ? (
                              <Button
                                variant="secondary"
                                onClick={ () => setEditorActive(false) }
                              >
                                <Stack>
                                  <PresentationIcon
                                    IconComponent={ PreviewIcon }
                                    size={ 5 }
                                  />
                                  <FormattedMessage
                                    description="Label for button to preview broadcast SMS summary"
                                    defaultMessage="Preview"
                                  />
                                </Stack>
                              </Button>
                            )
                            : (
                              <Button
                                variant="secondary"
                                onClick={ () => setEditorActive(true) }
                              >
                                <Stack>
                                  <PresentationIcon
                                    IconComponent={ EditIcon }
                                    size={ 5 }
                                  />
                                  <FormattedMessage
                                    description="Label for button to edit broadcast SMS summary"
                                    defaultMessage="Edit"
                                  />
                                </Stack>
                              </Button>
                            )
                        }
                        { modified && (
                          <Button
                            variant="secondary"
                            onClick={ () => {
                              setEditorActive(false);
                              onChange({
                                send: notificationSettings.send,
                              })
                            } }
                          >
                            <Stack>
                              <PresentationIcon
                                IconComponent={ RevertIcon }
                                size={ 5 }
                              />
                              <FormattedMessage
                                description="Label for button to revert broadcast SMS summary"
                                defaultMessage="Revert"
                              />
                            </Stack>
                          </Button>
                        ) }
                      </div>
                      { errorMessage }
                    </div>
                  )
                  : (
                    <StyledPreviewOverlay>
                      <CircularProgress
                        size={ 24 }
                        color="secondary"
                      />
                    </StyledPreviewOverlay>
                  )
            }
          </div>
        </Stack>
        { !unlimitedSmsEnabled && notificationSettings.send && (
          !valid
            ? (
              <Notice
                feedback={ {
                  severity: 'error',
                  message: intl.formatMessage<ReactNode>({
                    description: 'Message when credits can\'t be calculated due to invalid broadcast content.',
                    defaultMessage: 'Make sure your broadcast is valid to calculate SMS credits.',
                  }),
                } }
                variant="outlined"
              />
            )
            : smsPreviewRequest.state === RequestState.COMPLETE
              ? (smsPreviewRequest.result.smsCredits.required <= smsPreviewRequest.result.smsCredits.available) || scheduled
                ? (
                  <Notice
                    feedback={ {
                      severity: 'success',
                      message: (
                        <VerticallySpaced>
                          <span>
                            <FormattedMessage
                              description="Message when user has sufficient credits to send SMS message or broadcast is scheduled for a future date."
                              defaultMessage="{ scheduled, select, true {Scheduling} other {Sending} } this broadcast will use <strong>{ cost }</strong> of your <strong>{ available, plural, one {# SMS credit} other {# SMS credits} }</strong>."
                              values={ {
                                cost: smsPreviewRequest.result.smsCredits.required,
                                available: smsPreviewRequest.result.smsCredits.available,
                                scheduled,
                              } }
                            />
                          </span>
                          { scheduled && (
                            <span>
                              <FormattedMessage
                                description="Message explaining credit requirements for scheduled broadcasts."
                                defaultMessage="If there are insufficient credits at the time of sending, the broadcast will be sent and SMS messages will be processed until credits run out."
                              />
                            </span>
                          ) }
                        </VerticallySpaced>
                      ),
                    } }
                    variant="outlined"
                  />
                )
                : (
                  <Notice
                    feedback={ {
                      severity: 'error',
                      message: intl.formatMessage<ReactNode>({
                        description: 'Message when user has insufficient credits to send SMS message.',
                        defaultMessage: 'Sending this message requires { cost, plural, one {# credit} other {# credits} } but { available, plural, =0 {none are} one {only # is} other {only # are} } available. <a>Contact support to add more.</a>',
                      }, {
                        a: chunks => (
                          <a
                            href="mailto:support@ourpeople.com"
                            target="_blank"
                            rel="noreferrer"
                          >
                            { chunks }
                          </a>
                        ),
                        cost: smsPreviewRequest.result.smsCredits.required,
                        available: smsPreviewRequest.result.smsCredits.available,
                      }),
                    } }
                    variant="outlined"
                  />
                )
              : smsPreviewRequest.state === RequestState.FAILED
                ? (
                  <Notice
                    feedback={ {
                      severity: 'error',
                      message: intl.formatMessage({
                        description: 'Message when request to calculate credit cost of sms message fails.',
                        defaultMessage: 'Could not calculate remaining SMS credits.',
                      }),
                    } }
                    variant="outlined"
                    buttons={ [
                      {
                        id: 'retryButton',
                        label: intl.formatMessage({
                          description: 'Retry button label when request to calculate credit cost of sms message fails.',
                          defaultMessage: 'Try again',
                        }),
                        props: {
                          onClick: onRetrySmsPreviewRequestClicked,
                        },
                      },
                    ] }
                  />
                )
                : (
                  <Notice
                    icon={ <CircularProgress size={ 20 } variant="indeterminate"/> }
                    variant="outlined"
                    feedback={ {
                      severity: 'info',
                      message: intl.formatMessage({
                        description: 'Message when SMS message credit cost is being calculated.',
                        defaultMessage: 'Calculating remaining SMS credits...',
                      }),
                    } }
                  />
                )
        ) }
      </>
    ) }
  </>;
};

const CONTENT_PLACEHOLDER = '[broadcast.message]';
