import { Button, ButtonProps } from '@mui/material';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Downloader } from '../../Services/Downloader';
import { ApiContext } from '../../Contexts';

interface Props {
  uploadId: string;
  buttonProps?: ButtonProps;
}

export const FileDownloadButton: FunctionComponent<Props> = ({
  buttonProps = {},
  children,
  uploadId,
}) => {
  const api = useContext(ApiContext);
  const [downloadStartTime, setDownloadStartTime] = useState<Date>();

  const whenDownloadButtonClicked = () => {
    setDownloadStartTime(new Date());
  };

  useEffect(() => {
    if (!api || !uploadId || !downloadStartTime) {
      return;
    }
    let cancelled = false;
    const downloader = new Downloader(api);
    downloader.getDownloadUrls(uploadId)
      .then((urls) => {
        if (cancelled) {
          return;
        }
        window.open(urls.original);
      })
      .catch(() => {
        if (cancelled) {
          return;
        }
      });
    return () => { cancelled = true; };
  }, [api, downloadStartTime, uploadId]);

  return (
    <Button
      {...buttonProps}
      onClick={whenDownloadButtonClicked}
    >
      { children || (
        <FormattedMessage
          id="fileDownloadButton.cta"
          description="Generic CTA for file download button"
          defaultMessage="Download file"
        />
      )}
    </Button>
  )
}
