import styled from '@emotion/styled';

export const StyledChoiceOptionEditor = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: ${ props => props.theme.spacing(1) };
  margin-right: -${ props => props.theme.spacing(1) };

  > button {
    margin-top: ${ props => props.theme.spacing(2) };
  }
`;
