import { FunctionComponent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { InputLabel } from '@mui/material';
import { LocalisedString } from 'op-storybook/lib/model/LocalisedString/LocalisedString';

import { ValidationTree } from '../../../../Common/Model';
import { useDescendantErrors } from '../../../../Common/Hook';
import { PersonStatus } from '../../../../Models';
import { StyledPersonConditionContainer } from '../PersonConditionBuilder/style';
import { LabelModifier } from '../LabelModifier/LabelModifier';
import { FieldValidationErrors } from '../../../../Common/Component';
import { StatusCondition } from '../../../Model';
import { PersonStatusAutocomplete } from '../../../../People/Component';
import { ArrayHelper } from '../../../../Common/Utility';

interface Props {
  condition: StatusCondition;
  onChange: (condition: StatusCondition) => void;
  validation?: ValidationTree<StatusCondition>;
  readonly?: boolean;
  autoFocus?: boolean;
}

export const StatusConditionBuilder: FunctionComponent<Props> = ({
  condition,
  onChange,
  validation,
  readonly = false,
  autoFocus,
}) => {
  const intl = useIntl();
  const statusErrors = useDescendantErrors(validation);
  const modifierOptions = [
    intl.formatMessage({
      id: 'audiences.builder.statusCondition.modifier.is',
      defaultMessage: 'any of',
    }),
    intl.formatMessage({
      id: 'audiences.builder.statusCondition.modifier.isNot',
      defaultMessage: 'none of',
    }),
  ];

  const whenStatusChanged = (selection: LocalisedString<PersonStatus>[]) => {
    const newStatuses = selection.map(({ id }) => id);

    if (ArrayHelper.containSameValues<string>(newStatuses, condition.statuses) === false) {
      onChange({
        ...condition,
        statuses: newStatuses,
      });
    }
  };

  const whenModifierChanged = (modifierIndex: number): void => {
    onChange({
      ...condition,
      not: modifierIndex === 1,
    });
  };

  return <StyledPersonConditionContainer>
    <InputLabel>
      <FormattedMessage
        id="audiences.builder.statusCondition.statusLabel"
        description="Label for the status condition dropdown"
        defaultMessage="People who are"
      />
      <LabelModifier
        options={modifierOptions}
        activeIndex={condition.not ? 1 : 0}
        onChange={whenModifierChanged}
        readonly={ readonly }
      />
    </InputLabel>
    <PersonStatusAutocomplete
      autoFocus={ autoFocus }
      selectedIds={ condition.statuses }
      onSelectionChanged={ whenStatusChanged }
      showChips
      fullWidth
      readonly={ readonly }
      label=""
      listStyle="grid"
    />
    <FieldValidationErrors
      fieldName={ intl.formatMessage({
        id: 'audiences.builder.fields.statusCondition.name',
        description: 'Field name for person condition builder',
        defaultMessage: 'status condition'
      }) }
      validationErrors={ statusErrors }
      selectionSingular={ intl.formatMessage({
        id: 'audiences.builder.fields.statusCondition.singular',
        description: 'Singular selection name in status condition builder',
        defaultMessage: 'status'
      }) }
      selectionPlural={ intl.formatMessage({
        id: 'audiences.builder.fields.statusCondition.plural',
        description: 'Plural selection name in status condition builder',
        defaultMessage: 'statuses'
      }) }
    />
  </StyledPersonConditionContainer>;
};
