import { FC, ReactElement, useEffect } from 'react';
import {
  $getSelection, BLUR_COMMAND,
  createCommand,
  DecoratorNode,
  EditorConfig,
  LexicalCommand,
  LexicalEditor, RangeSelection,
  SerializedLexicalNode,
  TextNode
} from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

interface SerialisedFixedTextNode extends SerializedLexicalNode {
  type: 'fixed-text';
  version: 1;
  value: string;
}

export const FixedTextNodePlugin: FC = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerCommand(
      ADD_FIXED_TEXT_COMMAND,
      ({ text, anchor }) => {
        editor.update(() => {
          const selection = $getSelection();
          selection?.insertNodes([new FixedTextNode(text, anchor)]);
        });

        return true;
      },
      1,
    );
  }, [editor]);

  useEffect(() => {
    return editor.registerNodeTransform(TextNode, textNode => {
      const regex = /\{%([^}]+)%}/gm;
      const match = regex.exec(textNode.getTextContent());

      if (!match) {
        return;
      }

      const [matchString, captureString] = match;
      const originalTextContent = textNode.getTextContent();
      const matchIndex = textNode.getTextContent().indexOf(matchString);
      textNode.spliceText(matchIndex, matchString.length, '', true);
      const [firstTextNode] = textNode.splitText(matchIndex);
      const anchor = matchIndex < originalTextContent.length ? 'start' : 'end';

      if (firstTextNode) {
        const fixedTextNode = new FixedTextNode(captureString, anchor);
        if (matchIndex === 0) {
          firstTextNode.insertBefore(fixedTextNode, true);
        } else {
          firstTextNode.insertAfter(fixedTextNode, true);
        }
        fixedTextNode.selectNext(0, 0);
      } else {
        editor.dispatchCommand(ADD_FIXED_TEXT_COMMAND, { text: captureString, anchor });
      }
    });
  }, [editor]);

  return null;
};

export class FixedTextNode extends DecoratorNode<ReactElement> {
  static type = 'fixed-text';

  private readonly __text: string;
  private readonly __anchor: 'start' | 'end';

  constructor(text: string, anchor: 'start' | 'end') {
    super();
    this.__text = text;
    this.__anchor = anchor;
  }

  static getType(): string {
    return FixedTextNode.type;
  }

  static clone(node: FixedTextNode): FixedTextNode {
    return new FixedTextNode(node.getText(), node.getAnchor());
  }

  createDOM(config: EditorConfig, editor: LexicalEditor): HTMLElement {
    const element = document.createElement('span');

    element.style.userSelect = 'none';
    element.style.backgroundColor = 'rgba(0, 0, 0, 0.05)';
    element.style.color = 'rgba(0, 0, 0, 0.5)';
    return element;
  }

  updateDOM(
    prevNode: FixedTextNode,
    dom: HTMLElement,
    config: EditorConfig,
  ): boolean {
    const isUpdated = super.updateDOM(prevNode, dom, config);
    dom.style.userSelect = 'none';
    dom.style.backgroundColor = 'rgba(0, 0, 0, 0.05)';
    dom.style.color = 'rgba(0, 0, 0, 0.5)';
    return isUpdated;
  }

  decorate(editor: LexicalEditor, config: EditorConfig): ReactElement {
    return (
      <span>
        { this.getText() }
      </span>
    );
  }

  remove(preserveEmptyParent?: boolean) {
    return false;
  }

  isKeyboardSelectable(): boolean {
    return false;
  }

  isIsolated(): boolean {
    return false;
  }

  public getText() {
    const self = this.getLatest();
    return self.__text;
  }

  public getAnchor() {
    const self = this.getLatest();
    return self.__anchor;
  }
}

export const ADD_FIXED_TEXT_COMMAND: LexicalCommand<{ text: string, anchor: 'start' | 'end' }> = createCommand('ADD_FIXED_TEXT_COMMAND');
