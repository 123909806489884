import { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import HomeIcon from '@ourpeople/shared/Asset/Icon/figma/home-smile.svg';
import BroadcastsIcon from '@ourpeople/shared/Asset/Icon/figma/layers-three-02.svg';
import EventsIcon from '@ourpeople/shared/Asset/Icon/figma/calendar.svg';
import FormsIcon from '@ourpeople/shared/Asset/Icon/figma/file-06.svg';
import FilesIcon from 'op-storybook/lib/assets/icon/figma/folder.svg';
import AdminFilesIcon from 'op-storybook/lib/assets/icon/figma/folder-lock.svg';
import TopicsIcon from '@ourpeople/shared/Asset/Icon/figma/message-chat-square.svg';
import PeopleIcon from '@ourpeople/shared/Asset/Icon/figma/users-01.svg';
import ReportsIcon from 'op-storybook/lib/assets/icon/figma/line-chart-up-02.svg';
import DashboardIcon from '@ourpeople/shared/Asset/Icon/figma/bar-chart-square-02.svg';
import MyFormsIcon from '@ourpeople/shared/Asset/Icon/figma/file-check-02.svg';
import ChatIcon from '@ourpeople/shared/Asset/Icon/figma/message-circle-01.svg';
import HelpdeskIcon from '@ourpeople/shared/Asset/Icon/figma/life-buoy-01.svg';
import SettingsIcon from '@ourpeople/shared/Asset/Icon/figma/settings-01.svg';
import AdminIcon from '@ourpeople/shared/Asset/Icon/figma/dots-grid.svg';
import OnboardingIcon from 'op-storybook/lib/assets/icon/figma/user-plus-01.svg';
import AnnouncementIcon from 'op-storybook/lib/assets/icon/figma/announcement-02.svg';

import { MetricsPermission } from '../../Reporting/Model/MetricsPermission';
import { BroadcastsPermission } from '../../Broadcasts/Model/BroadcastsPermission';
import { EventsPermission } from '../../Events/Model/EventsPermission';
import { FormsPermission } from '../../Forms/Model/FormsPermission';
import { RoleId } from '../../Security/Model/Role';
import { EnvironmentSettings } from '../../Models/AuthDescription';
import { SvgComponent } from '../Model/SvgComponent';
import { FilesPermission } from '../../Files/Model/FilesPermission';
import { ChatsPermission } from '../../Chat/Model/ChatsPermission';
import { PeoplePermission } from '../../People/Model/PeoplePermission';
import { ReportsPermission } from '../../Reporting/Model/ReportsPermission';
import { SystemPermission } from '../../System/Model/SystemPermission';
import { IntegrationsPermission } from '../../ApiClients/Model/IntegrationsPermission';
import { SmsPermission } from '../../Credit/Model/SmsPermission';
import { StreamConnectionContext } from '../../Chat/Provider/StreamConnectionProvider/StreamConnectionProvider';
import { OnboardingPermission } from '../../Onboarding/Model/OnboardingPermission';

type EnvironmentSettingCondition = (keyof EnvironmentSettings) | Partial<{ [K in keyof EnvironmentSettings]: EnvironmentSettings[K] }>;

interface BaseNavItem {
  id: string;
  requirePermissionPresent?: { permission: string, exclude: string[] }[];
  requirePermissionAvailable?: string[],
  requireRole?: RoleId;
  requireEnvironmentSettingsToDisplay?: EnvironmentSettingCondition[];
  requireEnvironmentSettingsToEnable?: EnvironmentSettingCondition[];
  disallow?: (environmentSettings: EnvironmentSettings) => boolean;
  label: string;
  IconComponent: SvgComponent;
}

interface NavLinkConfiguration extends BaseNavItem {
  path?: string;
  count: number | undefined;
  children?: never;
}

interface NavGroupConfiguration extends BaseNavItem {
  children: NavLinkConfiguration[];
  path?: never;
}

export type NavItemConfiguration = NavLinkConfiguration | NavGroupConfiguration;

type ReturnValue = {
  mainItems: NavItemConfiguration[];
  footerItems: NavItemConfiguration[];
};

export const useNavItemConfigurations = (): ReturnValue => {
  const intl = useIntl();
  const streamConnectionContextValue = useContext(StreamConnectionContext);
  const notificationCount = streamConnectionContextValue?.notificationCount;

  return useMemo<ReturnValue>(() => ({
    mainItems: [
      {
        id: 'home',
        label: intl.formatMessage({
          description: 'Label for Home navigation item in sidebar.',
          defaultMessage: 'Home',
        }),
        path: '/inbox',
        IconComponent: HomeIcon,
        requirePermissionAvailable: [BroadcastsPermission.ME],
        count: undefined,
      },
      {
        id: 'my-forms',
        label: intl.formatMessage({
          description: 'Label for My forms navigation item in sidebar.',
          defaultMessage: 'My forms',
        }),
        path: '/my-forms',
        IconComponent: MyFormsIcon,
        requirePermissionAvailable: [FormsPermission.ME],
        requireEnvironmentSettingsToDisplay: ['formsEnabled'],
        count: undefined,
      },
      {
        id: 'files',
        label: intl.formatMessage({
          description: 'Label for files navigation item in sidebar.',
          defaultMessage: 'Files',
        }),
        path: '/me/files',
        IconComponent: FilesIcon,
        requirePermissionAvailable: [FilesPermission.ME],
        requireEnvironmentSettingsToDisplay: ['filesEnabled'],
        count: undefined,
      },
      {
        id: 'chat',
        label: intl.formatMessage({
          description: 'Label for Chat navigation item in sidebar.',
          defaultMessage: 'Chat',
        }),
        path: '/me/chat',
        IconComponent: ChatIcon,
        requirePermissionAvailable: [ChatsPermission.ME],
        requireEnvironmentSettingsToDisplay: ['streamChatEnabled'],
        count: notificationCount,
      },
      {
        id: 'admin',
        label: intl.formatMessage({
          description: 'Label for Admin navigation group in sidebar.',
          defaultMessage: 'Admin',
        }),
        IconComponent: AdminIcon,
        children: [
          {
            id: 'dashboard',
            label: intl.formatMessage({
              description: 'Label for Dashboard navigation item in sidebar.',
              defaultMessage: 'Dashboard',
            }),
            path: '/dashboard',
            IconComponent: DashboardIcon,
            requirePermissionAvailable: [MetricsPermission.ALL],
            count: undefined,
          },
          {
            id: 'broadcasts',
            label: intl.formatMessage({
              description: 'Label for Broadcasts navigation item in sidebar.',
              defaultMessage: 'Broadcasts',
            }),
            path: '/broadcasts',
            IconComponent: BroadcastsIcon,
            requirePermissionPresent: [{ permission: BroadcastsPermission.ALL, exclude: [BroadcastsPermission.ME] }],
            requireRole: 'ROLE_BROADCAST_ADMIN',
            count: undefined,
          },
          {
            id: 'forms',
            label: intl.formatMessage({
              description: 'Label for Forms navigation item in sidebar.',
              defaultMessage: 'Forms',
            }),
            path: '/forms',
            IconComponent: FormsIcon,
            requirePermissionPresent: [
              {
                permission: FormsPermission.ALL,
                exclude: [FormsPermission.READ, FormsPermission.ME, FormsPermission.METRICS]
              },
            ],
            requireEnvironmentSettingsToEnable: ['formsEnabled'],
            count: undefined,
          },
          {
            id: 'admin-files',
            label: intl.formatMessage({
              description: 'Label for Admin Files navigation item in sidebar.',
              defaultMessage: 'Files',
            }),
            path: '/files',
            IconComponent: AdminFilesIcon,
            requirePermissionPresent: [
              {
                permission: FilesPermission.CREATE,
                exclude: []
              },
            ],
            requireEnvironmentSettingsToEnable: ['filesEnabled'],
            count: undefined,
          },
          {
            id: 'topics',
            label: intl.formatMessage({
              description: 'Label for Topics navigation item in sidebar.',
              defaultMessage: 'Topics',
            }),
            path: '/chats/topics',
            IconComponent: TopicsIcon,
            requirePermissionPresent: [
              {
                permission: ChatsPermission.TOPICS,
                exclude: [ChatsPermission.TOPICS_READ],
              },
            ],
            requireEnvironmentSettingsToEnable: ['streamChatEnabled'],
            count: undefined,
          },
          {
            id: 'people',
            label: intl.formatMessage({
              description: 'Label for People navigation item in sidebar.',
              defaultMessage: 'People',
            }),
            path: '/people',
            IconComponent: PeopleIcon,
            requirePermissionPresent: [
              {
                permission: PeoplePermission.ALL,
                exclude: [PeoplePermission.READ, PeoplePermission.PROFILES_READ, PeoplePermission.AVATAR_READ]
              },
            ],
            count: undefined,
          },
          {
            id: 'onboarding',
            label: intl.formatMessage({
              description: 'Label for Onboarding navigation item in sidebar.',
              defaultMessage: 'Onboarding',
            }),
            path: '/onboarding',
            IconComponent: OnboardingIcon,
            requirePermissionAvailable: [OnboardingPermission.PEOPLE_READ],
            requireEnvironmentSettingsToDisplay: [{ broadcastsV2Enabled: false }],
            count: undefined,
          },
          {
            id: 'events',
            label: intl.formatMessage({
              description: 'Label for Events navigation item in sidebar.',
              defaultMessage: 'Events',
            }),
            path: '/events',
            IconComponent: EventsIcon,
            requireRole: 'ROLE_BROADCAST_ADMIN',
            requirePermissionPresent: [{ permission: EventsPermission.ALL, exclude: [] }],
            requireEnvironmentSettingsToEnable: ['eventsEnabled'],
            disallow: (environmentSettings) => !environmentSettings.eventsEnabled
              || (
                !!environmentSettings.broadcastsV2Enabled
                && !environmentSettings.broadcastsV1EventsEnabled
              ),
            count: undefined,
          },
          {
            id: 'reports',
            label: intl.formatMessage({
              description: 'Label for Reports navigation item in sidebar.',
              defaultMessage: 'Reports',
            }),
            path: '/reports',
            IconComponent: ReportsIcon,
            requirePermissionPresent: [
              {
                permission: ReportsPermission.ALL,
                exclude: []
              },
            ],
            count: undefined,
          },
          {
            id: 'settings',
            label: intl.formatMessage({
              description: 'Label for Settings navigation item in sidebar.',
              defaultMessage: 'Settings',
            }),
            path: '/settings',
            IconComponent: SettingsIcon,
            requirePermissionAvailable: [SystemPermission.SETTINGS_UPDATE],
            requirePermissionPresent: [
              { permission: IntegrationsPermission.ACCESS_REQUESTS, exclude: [] },
              { permission: IntegrationsPermission.CLIENTS, exclude: [] },
              { permission: SmsPermission.CREDITS, exclude: [] },
            ],
            count: undefined,
          },
        ],
      },
    ],
    footerItems: [
      {
        id: 'beamer',
        label: intl.formatMessage({
          description: 'Label for Announcements item in sidebar.',
          defaultMessage: 'Announcements',
        }),
        IconComponent: AnnouncementIcon,
        requireRole: 'ROLE_SUPER_ADMIN',
        count: undefined,
      },
      {
        id: 'support',
        label: intl.formatMessage({
          description: 'Label for Support navigation item in sidebar.',
          defaultMessage: 'Support',
        }),
        path: 'https://get.ourpeople.help',
        IconComponent: HelpdeskIcon,
        count: undefined,
      },
    ]
  }), [intl, notificationCount]);
};
